<template>
  <ul class="nested-table-section">
    <li v-show="!menuItems.length && (!menuPermissions.ADD_RESOURCE || activeMenuType)">
      <div class="row sub-row">
        <span class="has-text-grey pad-y-sm">No Items</span>
        <span />
        <span />
      </div>
    </li>
    <draggable
      v-show="menuItems.length"
      tag="ul"
      :group="`category-${category.id}-items`"
      animation="200"
      ghost-class="ghost"
      handle=".drag-handle"
      draggable=".draggable"
      :value="menuItems"
      :force-fallback="true"
      @change="handleItemSortOrderChange($event)"
    >
      <template v-if="menuItems.length" #header>
        <li class="nested-table-section-title">Menu Items</li>
      </template>

      <menu-item-row
        v-for="menuItem in menuItems"
        :key="`item-${menuItem.id}-category-${category.id}`"
        :menu-item="menuItem"
        :category="category"
      />
    </draggable>
    <li v-if="!activeMenuType && $can('create', 'MenuItem') && menuPermissions.ADD_RESOURCE" class="row sub-row">
      <span>
        <b-button
          class="is-transparent"
          icon-left="plus"
          type="is-primary"
          inverted
          @click="openItemModal()"
        >
          Item
        </b-button>
      </span>
    </li>
  </ul>
</template>

<script>
  import draggable from 'vuedraggable';
  import { mapActions, mapState } from 'vuex';

  import menuResourceTypes from '@/constants/menuResourceTypes';

  import Item from '@/store/classes/Item';

  import AddEditItemModal from '../main-menu/add-edit-item-modal/index.vue';
  import MenuItemRow from './menu-item-row.vue';


  export default {
    name: 'MenuItemsContainer',

    components: { draggable, MenuItemRow },

    props: {
      category: {
        type: Object,
        required: true
      }
    },

    computed: {
      ...mapState('mainMenu', ['activeMenuType', 'menuPermissions', 'draggableAttributes']),

      menuItems() {
        const items = Item.query()
          .whereHas('menuCategories', (query) => {
            query.where('id', this.category.id);
          })
          .with('menuItemModifierGroups')
          .with('menuItemModifierGroupItems')
          .get();

        return items?.sort((a, b) => ((a.sortOrderByCategory(this.category.id) < b.sortOrderByCategory(this.category.id)) ? -1 : 1)) || [];
      }
    },

    methods: {
      ...mapActions('mainMenu', ['setMenuResourceOpenState']),

      async handleItemSortOrderChange({ moved }) {
        const items = this.menuItems.map((item) => {
          item.sortOrder = item.sortOrderByCategory(this.category.id);
          return item;
        });

        try {
          await Item.updateSortOrders({
            categoryId: this.category.id,
            items,
            oldIndex: moved.oldIndex,
            newIndex: moved.newIndex
          });

          this.$_onRequestSuccess({
            toastOptions: {
              message: `<b>${moved.element.displayName}</b> moved from sort order <b>${moved.oldIndex + 1}</b> to <b>${moved.newIndex + 1}</b>`
            }
          });
        }

        catch (error) {
          this.$_onRequestError({
            toastOptions: {
              message: 'Unable to update sort orders'
            },
            error
          });
        }
      },

      async reSearchMenu() { // This should also move to the VuexStore
        console.log('reSearchMenu');
      },

      async openItemModal() {
        this.$buefy.modal.open({
          parent: this,
          component: AddEditItemModal,
          hasModalCard: true,
          trapFocus: true,
          canCancel: false,
          events: {
            'main-menu:add-item': this.toggleMenuItem
          },
          props: {
            category: this.category,
            mode: 'create'
          }
        });
      },

      toggleMenuItem({ id }) {
        this.setMenuResourceOpenState({
          resourceType: menuResourceTypes.MENU_ITEM,
          resourceId: id,
          value: true
        });
      }
    }
  };
</script>

