var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('panel',{attrs:{"title":"Modifier Groups","no-padding":""}},[(_vm.isFetching)?_c('b-loading',{attrs:{"active":_vm.isFetching,"is-full-page":false}}):_vm._e(),_c('div',{staticClass:"pad-x-md has-border-bottom has-border-grey-lighter is-flex gap-sm"},[_c('validated-text-input',{staticClass:"flex-grow mar-b",attrs:{"type":"text","icon-right-clickable":!!_vm.query,"icon-right":_vm.query ? 'times-circle' : '',"placeholder":"Search by Modifier Group Name","icon":"search","label-position":"on-border","label":"Search","name":"query-input","expanded":"","output-string":""},on:{"icon-right-click":function($event){_vm.query = ''}},nativeOn:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"esc",27,$event.key,["Esc","Escape"])){ return null; }_vm.query = ''}},model:{value:(_vm.query),callback:function ($$v) {_vm.query=$$v},expression:"query"}}),(_vm.$_selectedMerchant.allowModifierGroupAssociation && _vm.$can('crud', 'MenuItemModifierGroupItem'))?_c('b-button',{attrs:{"type":"is-primary","icon-left":"plus","data-test-id":"add-modifier-group-button"},on:{"click":function($event){return _vm.openModifierGroupModal()}}},[_vm._v(" Modifier Group ")]):_vm._e()],1),_c('b-table',{staticClass:"is-middle-aligned",attrs:{"data":_vm.isFetching ? [] : _vm.modifierGroups,"mobile-cards":false,"scrollable":"","backend-pagination":"","paginated":"","total":_vm.total,"current-page":_vm.page,"per-page":25,"data-test-id":"modifier-groups-table","default-sort":['name', 'asc']},on:{"page-change":_vm.handlePageChange},scopedSlots:_vm._u([{key:"empty",fn:function(){return [_c('empty-table-loader',{attrs:{"message":"No Modifier Groups Found...","loading":_vm.isFetching}})]},proxy:true}])},[_c('b-table-column',{attrs:{"field":"name","label":"Modifier Group","width":"600"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('span',{staticClass:"link",on:{"click":function($event){return _vm.openModifierGroupModal(row)}}},[_vm._v(" "+_vm._s(_vm._f("capitalize")(row.displayName))+" ")])]}}])}),_c('b-table-column',{attrs:{"centered":"","field":"modifiersCount","label":"Modifiers"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('span',[_vm._v(" "+_vm._s(row.modifiersCount)+" ")])]}}])}),_c('b-table-column',{attrs:{"centered":"","field":"sharedMenuItemCount","label":"Items"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('span',[_vm._v(" "+_vm._s(row.sharedMenuItemCount)+" ")])]}}])}),_c('b-table-column',{attrs:{"centered":"","cell-class":"actions","label":"Actions","width":"1","field":"Actions"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('dropdown-menu',{attrs:{"position":"bottom-end","mobile-modal":false}},[_c('b-button',{staticClass:"is-transparent",attrs:{"slot":"trigger","type":"is-white"},slot:"trigger"},[_c('b-icon',{attrs:{"icon":"ellipsis-v","pack":"far","size":"is-small"}})],1),(_vm.$can('crud', 'MenuItemModifierGroupItem'))?[_c('b-dropdown-item',{on:{"click":function($event){return _vm.openModifierGroupModal(row)}}},[_c('b-icon',{staticClass:"mar-r-sm",attrs:{"icon":"pencil","size":"is-small"}}),_vm._v(" Edit Group ")],1),_c('hr',{staticClass:"dropdown-divider"}),_c('b-dropdown-item',{on:{"click":function($event){return _vm.confirmDeleteModifierGroup(row)}}},[_c('b-icon',{staticClass:"mar-r-sm",attrs:{"icon":"trash","size":"is-small","type":"is-danger"}}),_vm._v(" Delete Group ")],1)]:_c('b-dropdown-item',{on:{"click":function($event){return _vm.openModifierGroupModal(row)}}},[_c('b-icon',{staticClass:"mar-r-sm",attrs:{"icon":"eye","size":"is-small"}}),_vm._v(" View Group ")],1)],2)]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }