<template>
  <div
    class="pad has-background-white-bis has-border has-border-grey-lightest has-radius"
    :data-test-id="`${criteria.defaultCriteriaKey}-criteria-card`"
    data-test-class="criteria-card"
  >
    <validation-observer
      v-slot="{ errors }"
      :name="criteria.display"
      :vid="criteria.defaultCriteriaKey"
    >
      <label class="label" :for="criteria.defaultCriteriaKey">
        <div class="is-flex justify-between align-center">
          <span>
            {{ criteria.display }}
            <b-icon
              v-if="Object.values(errors).flat().length"
              icon="exclamation-circle"
              size="is-small"
              type="is-danger"
            />

            <span>
              <b-icon
                v-if="criteria.tooltip"
                v-tippy="criteria.tooltip"
                icon="info-square"
                size="is-small"
                type="is-grey"
              />
            </span>
          </span>
          <div>
            <slot name="buttons" />
            <b-button
              v-if="!hideRemoveButton"
              type="is-danger is-light"
              size="is-small"
              icon-right="trash"
              data-test-class="remove-criteria-button"
              @click="$emit('remove-criteria', criteria.criteriaKeys)"
            />
          </div>
        </div>
      </label>

      <slot />

    </validation-observer>
  </div>
</template>

<script>
  export default {
    name: 'CriteriaCard',

    props: {
      criteria: {
        type: Object,
        required: true
      },
      hideRemoveButton: {
        type: Boolean,
        required: false
      }
    }
  };
</script>
