var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.isChanged)?[_c('span',{staticClass:"is-inline-block has-text-weight-semibold",class:['has-text-danger', _vm.movedUp ? 'is-down' : 'is-up']},[_vm._v(" "+_vm._s(_vm.oldSortOrder)+" ")]),_c('b-icon',{staticClass:"has-text-grey-dark",class:_vm.movedDown ? 'is-arrow-down' : 'is-arrow-up',attrs:{"icon":"long-arrow-alt-right","pack":"far","size":"is-small"}})]:_vm._e(),_c('span',{class:[
      'is-inline-block',
      {
        'is-down': _vm.movedDown,
        'is-up': _vm.movedUp,
        'is-same': _vm.isPendingChanges && !_vm.isChanged,
        'has-text-weight-semibold': _vm.isPendingChanges,
        'has-text-success': _vm.isChanged
      }
    ]},[_vm._v(" "+_vm._s(_vm.newSortOrder)+" ")])],2)}
var staticRenderFns = []

export { render, staticRenderFns }