<template>
  <div class="is-relative">
    <validated-form
      ref="itemAvailabilityForm"
      form-id="itemAvailabilityForm"
      :disabled="!($can('update', 'MenuItem') && $_menuPermissions.EDIT_RESOURCE) || !($can('update', 'MenuItemSchedule') && $_menuPermissions.MANAGE_DAYPART_SCHEDULE)"
    >
      <fieldset>
        <p class="subtitle is-5 is-marginless">
          Availability
        </p>
        <p class="is-size-7 has-text-grey mar-b-md">
          Optional dates to limit availability of this menu item
        </p>

        <validation-observer class="is-grid col-2 gap-x">
          <validated-input name="availabilityBeginDate" label="Start Date">
            <b-datepicker
              v-model="startDate"
              :years-range="dys_ranges.startDate"
              placeholder="Now"
              icon="calendar-alt"
              class="has-extra-shadow"
              :disabled="!$can('update', 'MenuItem') || !$_menuPermissions.EDIT_RESOURCE"
              :events="endDate ? [{ date: endDate, type: 'is-danger' }] : []"
              indicators="bars"
              :focused-date="endDate"
              @change-year="(year) => $_handleYearChange({ year, type: 'startDate' })"
              @input="emitInput"
            >
              <div class="buttons is-right">
                <b-button @click="clearDate('availabilityBeginDate')">Clear</b-button>
              </div>
            </b-datepicker>
          </validated-input>

          <validated-input
            name="availabilityEndDate"
            rules="dateTimeIsAfter:@availabilityBeginDate"
            label="End Date"
          >
            <b-datepicker
              v-model="endDate"
              :years-range="dys_ranges.endDate"
              placeholder="Forever"
              icon="calendar-alt"
              class="has-extra-shadow"
              position="is-bottom-left"
              :disabled="!$can('update', 'MenuItem') || !$_menuPermissions.EDIT_RESOURCE"
              :events="startDate ? [{ date: startDate, type: 'is-success' }] : []"
              indicators="bars"
              :focused-date="startDate"
              @change-year="(year) => $_handleYearChange({ year, type: 'endDate' })"
              @input="emitInput"
            >
              <div class="buttons is-right">
                <b-button @click="clearDate('availabilityEndDate')">Clear</b-button>
              </div>
            </b-datepicker>
          </validated-input>
        </validation-observer>
      </fieldset>

      <fieldset
        v-if="$can('read', 'MenuItemSchedule')"
        :disabled="!$can('update', 'MenuItemSchedule')"
      >
        <hr>

        <p class="subtitle is-5 is-marginless">
          Daypart Schedule
        </p>
        <p class="is-size-7 has-text-grey mar-b-md">
          Optional times to limit availability of this menu item
        </p>

        <daypart-schedule-inputs
          v-model="dayparts_form"
          :invalid-days="dayparts_invalidDays"
          :read-only="!($can('update', 'MenuItemSchedule') && $_menuPermissions.MANAGE_DAYPART_SCHEDULE)"
          can-hide-hours
        />
      </fieldset>

    </validated-form>
  </div>
</template>

<script>
  import moment from 'moment-timezone';

  import filterObjectKeys from '@/helpers/filter-object-keys';

  import daypartsMixin from '@/mixins/dayparts';
  import dynamicYearSelectMixin from '@/mixins/dynamic-year-select';
  import merchantMixin from '@/mixins/merchant';
  import multiFormChildProvider from '@/mixins/multiFormMixin/multiFormChildProvider';

  import Item from '@/store/classes/Item';
  import ItemSchedule from '@/store/classes/ItemSchedule';

  import { emitNativeInput } from '@/services/emit-input';

  export default {
    name: 'ItemAvailabilityForm',

    mixins: [multiFormChildProvider, merchantMixin, dynamicYearSelectMixin, daypartsMixin],

    props: {
      item: {
        type: Object,
        required: true
      },

      formRef: {
        type: String,
        required: true
      },

      mode: {
        type: String,
        default: 'create',
        validator(value) {
          return ['create', 'read', 'update'].includes(value);
        }
      }
    },

    data() {
      return {
        moment,
        form: null
      };
    },

    computed: {
      itemSchedules() {
        return ItemSchedule
          .query()
          .where('menuItemId', this.item.id)
          .get();
      },

      startDate: {
        get() {
          const dateString = moment.utc(this.form.availabilityBeginDate).format('MM-DD-YYYY');
          return this.form.availabilityBeginDate ? moment(dateString, 'MM-DD-YYYY').startOf('day').toDate() : null;
        },
        set(val) {
          const dateString = moment.utc(val).format('MM-DD-YYYY');
          this.form.availabilityBeginDate = moment.utc(dateString, 'MM-DD-YYYY').startOf('day').toISOString();
        }
      },

      endDate: {
        get() {
          const dateString = moment.utc(this.form.availabilityEndDate).format('MM-DD-YYYY');
          return this.form.availabilityEndDate ? moment(dateString, 'MM-DD-YYYY').endOf('day').toDate() : null;
        },
        set(val) {
          const dateString = moment.utc(val).format('MM-DD-YYYY');
          this.form.availabilityEndDate = moment.utc(dateString, 'MM-DD-YYYY').endOf('day').toISOString();
        }
      },

      isFetching() {
        return ItemSchedule.$state().fetching;
      }
    },

    async mounted() {
      await this.onMounted();
    },

    created() {
      this.onCreated();
    },

    methods: {
      onCreated() {
        this.form = this.$clone(this.item);
      },

      emitInput() {
        const formElement = this.$refs.itemAvailabilityForm.$refs.formElement;
        emitNativeInput(formElement);
      },

      clearDate(dateType) {
        this.form[dateType] = null;
        this.emitInput();
      },

      async onMounted() {
        if (this.mode === 'update' && this.$can('read', 'MenuItemSchedule')) {
          await this.fetchItemSchedules();
        }
        this.$_setDaypartsForm(this.itemSchedules);
      },

      async fetchItemSchedules() {
        try {
          await ItemSchedule.fetchSchedules(this.item.id);
        }
        catch (error) {
          this.$_onRequestError({
            error,
            toastOptions: { message: 'Unable to fetch Item Schedules' }
          });
        }
      },

      async handleSubmit(itemId = this.item.id) {
        try {
          await Promise.all([this.updateItemAvailability(itemId), this.handleScheduleSubmit(itemId)]);
          return itemId;
        }
        catch (error) {
          throw error;
        }
      },

      async updateItemAvailability(itemId) {
        try {
          this.form.id = itemId;

          const whitelistedKeys = [
            'id',
            'availabilityBeginDate',
            'availabilityEndDate'
          ];

          await Item.updateItem(filterObjectKeys(this.form, whitelistedKeys));

          this.$emit('item-updated');
        }

        catch (error) {
          this.$_onRequestError({
            toastOptions: { message: 'An error occured while updating your item availability' },
            error
          });
        }
      },

      async handleScheduleSubmit(itemId) {
        if (this.$_validateDayparts()) {
          try {
            const { added, removed, updated } = this.$_getDaypartsToUpdate({
              currentDayparts: this.itemSchedules
            });

            await Promise.all([
              ...added.map(a => ItemSchedule.createSchedule({ schedule: a, itemId })),
              ...updated.map(u => ItemSchedule.updateSchedule(u)),
              ...removed.map(r => ItemSchedule.deleteSchedule(r.id))
            ]);

            await Item.fetchItem(itemId);
          }

          catch (error) {
            this.$_onRequestError({
              error,
              toastOptions: { message: 'Unable to update Daypart Schedule' }
            });
            throw error;
          }
        }
        else {
          throw new Error('dayparts error');
        }
      }
    }
  };
</script>
