/* eslint-disable import/no-cycle */
import { Model } from '@vuex-orm/core';
import http from '@/services/http';
import filterObjectKeys from '@/helpers/filter-object-keys';



export default class StoreModifier extends Model {
  static entity = 'storeModifiers'

  static fields() {
    return {
      id: this.attr(''),
      price: this.attr('', val => (val ? parseFloat(val).toFixed(2) : val)),
      menuId: this.attr(''),
      isDisabled: this.attr(false),
      isManuallyDisabled: this.attr(false),
      menuItemModifierId: this.attr(''),
      createdAt: this.attr(null),
      updatedAt: this.attr(null),
      createdSource: this.attr(null),
      modifiedSource: this.attr(null),
      suspendUntilDate: this.attr(null),
      storeMenuItemModifierPriceOverrides: this.attr([])
    };
  }

  // STATE //////////////////////
  static state() {
    return {
      fetching: false,
      submitting: false
    };
  }

  static $state() {
    return this.store().state.entities.storeModifiers;
  }



  // ACTIONS //////////////////////

  static async fetchStoreModifiersByStoreId(storeId, { modifierGroupIds } = {}) {
    try {
      this.commit((state) => {
        state.fetching = true;
      });

      const response = await http.get(`stores/${storeId}/store_menu_item_modifiers`, {
        params: {
          modifier_group_ids: modifierGroupIds
        }
      });

      this.insert({
        data: response.data.storeMenuItemModifiers
      });
    }
    catch (error) {
      throw error;
    }
    finally {
      this.commit((state) => {
        state.fetching = false;
      });
    }
  }


  static async createOrUpdateStoreModifier({ modifierId, storeMenuItemModifier }) {
    if (!storeMenuItemModifier.price) {
      storeMenuItemModifier.price = '0.00';
    }

    try {
      this.commit((state) => {
        state.submitting = true;
      });

      const acceptedKeys = ['price', 'menuId', 'isDisabled', 'isManuallyDisabled', 'menuItemModifierId', 'suspendUntilDate'];

      const response = await http.post(`menu_item_modifiers/${modifierId}/store_menu_item_modifiers/upsert`, {
        storeMenuItemModifier: filterObjectKeys(storeMenuItemModifier, acceptedKeys)
      });

      this.insert({ data: response.data.storeMenuItemModifier });
    }

    catch (error) {
      throw error;
    }

    finally {
      this.commit((state) => {
        state.submitting = false;
      });
    }
  }


  static async addStoreModifier({ modifierId, storeMenuItemModifier }) {
    if (!storeMenuItemModifier.price) {
      storeMenuItemModifier.price = '0.00';
    }

    try {
      this.commit((state) => {
        state.submitting = true;
      });

      const acceptedKeys = ['price', 'menuId', 'isDisabled', 'isManuallyDisabled', 'menuItemModifierId', 'suspendUntilDate'];

      const response = await http.post(`menu_item_modifiers/${modifierId}/store_menu_item_modifiers`, {
        storeMenuItemModifier: filterObjectKeys(storeMenuItemModifier, acceptedKeys)
      });

      this.insert({ data: response.data.storeMenuItemModifier });
    }

    catch (error) {
      throw error;
    }

    finally {
      this.commit((state) => {
        state.submitting = false;
      });
    }
  }


  static async updateStoreModifier(storeMenuItemModifier) {
    if (!storeMenuItemModifier.price) {
      storeMenuItemModifier.price = '0.00';
    }

    try {
      this.commit((state) => {
        state.submitting = true;
      });
      const acceptedKeys = ['price', 'isDisabled', 'isManuallyDisabled', 'suspendUntilDate'];

      const response = await http.put(
        `store_menu_item_modifiers/${storeMenuItemModifier.id}`,
        { storeMenuItemModifier: filterObjectKeys(storeMenuItemModifier, acceptedKeys) }
      );

      this.update({ data: response.data.storeMenuItemModifier });
    }

    catch (error) {
      throw error;
    }

    finally {
      this.commit((state) => {
        state.submitting = false;
      });
    }
  }
}
