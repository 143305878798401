import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';
import events from '@/services/events';

const http = axios.create({
  timeout: 30000,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json'
  },
  baseURL: '/api/v1/'
});

export function handleResponseError(error) {
  const { config, response } = error;
  // check if the URL path begins with 'session'
  const isSessionsEndpoint = /^sessions/.test(config.url);

  /**
  * collect all the unauthorized requests NOT related to sessions
  * and re-request all of them once a user has been authorized
  */
  if (response?.status === 401 && !isSessionsEndpoint) {
    return new Promise((resolve) => {
      events.$emit('unauthorized');
      events.$once(
        'authorized',
        () => resolve(http({ ...config }))
      );
    });
  }
  return Promise.reject(response);
}

export function detectAppVersionChange(response) {
  const { headers } = response;
  if (
    (typeof process.env.VUE_APP_CLIENT_VERSION !== 'undefined' || process.env.VUE_APP_CLIENT_VERSION !== 'unknown')
    && (typeof headers['x-app-version'] !== 'undefined' || headers['x-app-version'] !== 'unknown')
    && process.env.NODE_ENV !== 'development') {
    if (process.env.VUE_APP_CLIENT_VERSION !== headers['x-app-version']) {
      events.$emit('client-version-mismatch');
    }
  }

  return response;
}

export function injectCorrelationId(config) {
  config.headers['x-correlation-id'] = uuidv4();
  return config;
}

// we only need to wrap this in an if block bc the http.test.js file will have failures
if (http.interceptors && http.interceptors.request) {
  http.interceptors.request.use(injectCorrelationId);
}

http.interceptors.response.use(
  detectAppVersionChange,
  handleResponseError
);


export default http;
