<template>
  <review-row
    :title="display.title"
    :subtitle="display.subtitle"
    :hide-bottom-border="hideBottomBorder"
  />
</template>

<script>
  import http from '@/services/http';
  import { itemAttributes, itemGroupTypes } from '@/constants/items';

  import merchantMixin from '@/mixins/merchant';

  export default {
    name: 'ItemGroupReviewRow',

    mixins: [merchantMixin],

    props: {
      itemGroup: {
        type: Object,
        default: () => ({})
      },
      minimumQualifyingCount: {
        type: Number,
        default: 0
      },
      hideBottomBorder: {
        type: Boolean,
        default: false
      }
    },

    data() {
      return {
        availableModifiers: []
      };
    },

    computed: {
      display() {
        return this.minimumQualifyingCount ? this.itemGroupDisplayWithMinimumQualifyingCount : this.itemGroupDisplay;
      },

      itemGroupDisplay() {
        let title;
        let subtitle;

        switch (this.itemGroup.itemGroupType) {
          case itemGroupTypes.ALL_ITEMS:
            title = 'Applicable Items';
            subtitle = `Any items ${this.excludedItemAttributeDisplay}`.trim();
            break;
          case itemGroupTypes.MENU_ITEMS: {
            const missingMenuItemsSubtitle = this.itemGroup.missingMenuItems().map(mi => ` Unknown Menu Resource - ${mi}`).join(', ');
            const addComma = this.itemGroup.missingMenuItems().length;
            title = 'Applicable Items';
            subtitle = `${this.itemGroup.menuItems.map(mi => mi.displayName).join(', ')}${addComma ? ', ' : ''}${missingMenuItemsSubtitle} ${this.modifierRestrictionDisplay}`.trim();
            break;
          }
          case itemGroupTypes.CATEGORIES: {
            const missingMenuCategoriesSubtitle = this.itemGroup.missingCategories().map(mc => ` Unknown Menu Resource - ${mc}`).join(', ');
            const addComma = this.itemGroup.missingCategories().length;
            title = 'Applicable Categories';
            subtitle = `${this.itemGroup.categoryObjects.map(co => co.displayName).join(', ')}${addComma ? ', ' : ''}${missingMenuCategoriesSubtitle} ${(`${this.modifierRestrictionDisplay} ${this.excludedItemAttributeDisplay}`).trim()}`.trim();
            break;
          }
          default:
            title = 'Applicable Items';
            subtitle = 'No Items Selected';
        }
        return { title, subtitle };
      },

      itemGroupDisplayWithMinimumQualifyingCount() {
        const display = { title: 'Item Constraint' };
        switch (this.itemGroup.itemGroupType) {
          case itemGroupTypes.ALL_ITEMS:
            display.subtitle = `Any ${this.minimumQualifyingCount} ${this.minimumQualifyingCount > 1 ? 'items' : 'item'} ${this.excludedItemAttributeDisplay}`.trim();
            break;
          case itemGroupTypes.MENU_ITEMS: {
            const missingMenuItemsSubtitle = this.itemGroup.missingMenuItems().map(mi => ` Unknown Menu Resource - ${mi}`).join(', ');
            const addComma = this.itemGroup.missingMenuItems().length;
            display.subtitle = `${this.minimumQualifyingCount} ${this.itemGroup.menuItems.length > 1 ? 'of the following:' : ''} ${this.itemGroup.menuItems.map(mi => mi.displayName).join(', ')}${addComma ? ', ' : ''}${missingMenuItemsSubtitle} ${this.modifierRestrictionDisplay}`.trim();
            break;
          }
          case itemGroupTypes.CATEGORIES: {
            const missingMenuCategoriesSubtitle = this.itemGroup.missingCategories().map(mc => ` Unknown Menu Resource - ${mc}`).join(', ');
            const addComma = this.itemGroup.missingCategories().length;
            display.subtitle = `Any ${this.minimumQualifyingCount} ${this.itemGroup.categoryObjects.map(co => co.displayName).join(', ')}${addComma ? ', ' : ''}${missingMenuCategoriesSubtitle} ${(`${this.modifierRestrictionDisplay} ${this.excludedItemAttributeDisplay}`).trim()}`.trim();
            break;
          }
          default:
            display.subtitle = 'No Items Selected';
            break;
        }
        return display;
      },

      modifierRestrictionDisplay() {
        const selectedModifierIds = this.itemGroup.requiredModifications?.[0]?.qualifyingModifierMenuItemIds || [];
        const modifiers = selectedModifierIds.map(id => this.availableModifiers.find(mod => mod.id === id));
        const modifierNames = modifiers.filter(mod => mod).map(mod => mod.displayName).join(', ');
        return modifierNames ? `with modifiers: ${modifierNames}`.trim() : '';
      },

      excludedItemAttributeDisplay() {
        const excludedItemAttributes = this.itemGroup?.excludedItemAttributes;
        if (excludedItemAttributes.length) {
          const itemAttributeEntries = Object.values(itemAttributes).reduce((acc, value) => ({ ...acc, [value.id]: value.display }), {});
          const attributes = excludedItemAttributes.map(attribute => itemAttributeEntries[attribute]).join(', ');
          return `(excludes ${attributes})`;
        }
        else {
          return '';
        }
      }
    },

    watch: {
      'itemGroup.requiredModifications': {
        immediate: true,
        deep: true,
        handler: 'fetchModifiers'
      }
    },

    methods: {
      async fetchModifiers() {
        const itemType = this.itemGroup.itemGroupType === itemGroupTypes.CATEGORIES ? 'category' : 'item';
        const resources = this.itemGroup.itemGroupType === itemGroupTypes.CATEGORIES
          ? this.itemGroup.categoryObjects
          : this.itemGroup.menuItems;

        if (this.itemGroup.requiredModifications?.length && resources.length) {
          try {
            const searchableIds = resources.map(resource => `menu_${itemType}_ids[]=${resource.id}`).join('&');
            const { data } = await http.get(
              `merchants/${this.$_selectedMerchantId}/menu_item_modifier_groups?${searchableIds}`
            );
            this.availableModifiers = data.menuItemModifierGroups.reduce((acc, itemModiferGroup) => (
              [
                ...acc, ...itemModiferGroup.menuItemModifiers.map(modifier => (
                  {
                    id: modifier.menuItemId,
                    displayName: modifier.displayName
                  }
                ))
              ]
            ), []);
          }
          catch (error) {
            this.$_onRequestError({
              toastOptions: {
                message: 'There was an error fetching the modifiers'
              },
              error
            });
          }
        }
      }
    }
  };
</script>
