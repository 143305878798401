import { Model } from '@vuex-orm/core';
import http from '@/services/http';


export default class LocationType extends Model {
  static entity = 'locationTypes'



  // FIELDS //////////////////////
  static fields() {
    return {
      description: this.attr(''),
      id: this.attr(''),
      name: this.attr('')
    };
  }

  // STATE //////////////////////
  static state() {
    return {
      fetching: false
    };
  }

  static $state() {
    return this.store().state.entities.locationTypes;
  }


  // ACTIONS //////////////////////
  static async fetchLocationsTypes() {
    try {
      this.commit((state) => {
        state.fetching = true;
      });

      const { data } = await http.get('/location_types');

      this.create({
        data: data.locationTypes
      });
    }
    catch (error) {
      throw error;
    }
    finally {
      this.commit((state) => {
        state.fetching = false;
      });
    }
  }
}
