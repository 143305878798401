<template>
  <div>
    <tabs-page
      v-if="$_selectedMerchant"
      :tabs="tabs"
      :keep-alive="false"
      title="Payment Transactions"
      show-single-tab
    />
  </div>
</template>

<script>
  import merchantMixin from '@/mixins/merchant';
  import paymentTransactionList from './payment-transactions-list.vue';

  export default {
    name: 'PaymentTransactions',

    mixins: [merchantMixin],

    computed: {
      tabs() {
        return [
          {
            display: 'Payment Transactions',
            name: 'search',
            component: paymentTransactionList
          }
        ];
      }
    }
  };
</script>

<style lang="sass" scoped>

  @media (min-width: $fullhd)
    ::v-deep
      .container
        max-width: 100% !important
</style>
