<template>
  <section-wrapper title="Application Settings">
    <b-table
      :data="merchantAppSettings"
      class="is-middle-aligned flex-grow pad-x-xs"
      hoverable
    >
      <template #empty>
        <p class="has-text-centered pad is-size-5 has-text-grey-light">
          No Applications Configured
        </p>
      </template>

      <b-table-column v-slot="{ row }" sortable field="paymentGateway" label="Name">
        {{ row.appCodeName }}
      </b-table-column>

      <b-table-column
        v-slot="{ row }"
        centered
        sortable
        field="systemStatusCodeId"
        label="System Status Code"
      >
        {{ row.systemStatusCodeId }}
      </b-table-column>

      <b-table-column
        v-slot="{ row }"
        centered
        sortable
        field="currentVersion"
        label="Current Version"
      >
        {{ row.currentVersion }}
      </b-table-column>

      <b-table-column
        v-slot="{ row }"
        centered
        sortable
        field="minimumVersion"
        label="Minimum Version"
      >
        {{ row.minimumVersion }}
      </b-table-column>

      <b-table-column
        v-slot="{ row }"
        centered
        field="hasUniversalLink"
        label="Universal Links"
      >
        <b-icon
          v-if="row.hasUniversalLink !== 'N/A'"
          :icon="row.hasUniversalLink ? 'check' : 'times'"
          :type="row.hasUniversalLink ? 'is-success' : 'is-danger'"
          size="is-small"
        />
        <span v-else class="has-text-grey-light">
          N/A
        </span>
      </b-table-column>

      <b-table-column
        v-slot="{ row }"
        centered
        cell-class="actions"
        label="Actions"
        width="1"
      >
        <b-button class="is-transparent" type="is-white" @click="openApplicationModal(row)">
          <b-icon icon="pencil" size="is-small" />
        </b-button>
      </b-table-column>
    </b-table>
    <div class="pad-x pad-y-sm has-border-top has-border-grey-lighter">
      <b-button
        inverted
        class="is-transparent"
        type="is-primary"
        icon-left="plus"
        :disabled="!canAddApplicationSetting"
        @click="openApplicationModal()"
      >
        Application Setting
      </b-button>
    </div>
  </section-wrapper>
</template>

<script>
  import addEditApplicationSettingModal from './add-edit-application-setting-modal.vue';
  import MerchantAppSetting from '@/store/classes/MerchantAppSetting';
  import AppCodeType from '@/store/classes/AppCodeType';


  export default {
    name: 'MerchantAppSettingConfigs',

    props: {
      merchant: {
        type: Object,
        required: true
      }
    },

    computed: {
      merchantAppSettings() {
        return MerchantAppSetting.all();
      },

      allApplicationTypes() {
        return AppCodeType.all();
      },

      canAddApplicationSetting() {
        return this.allApplicationTypes.length !== this.merchantAppSettings.length;
      }
    },

    methods: {
      openApplicationModal(merchantAppSetting) {
        const merchantApplicationTypeOptions = this.allApplicationTypes.filter(at => !this.merchantAppSettings.find(map => map.appCodeTypeId === at.id));

        this.$buefy.modal.open({
          parent: this,
          component: addEditApplicationSettingModal,
          hasModalCard: true,
          trapFocus: true,
          canCancel: false,
          customClass: 'full-width',
          props: {
            merchantApplicationTypeOptions,
            merchantAppSetting: merchantAppSetting || new MerchantAppSetting({ merchantId: this.merchant.id })
          }
        });
      }
    }
  };
</script>
