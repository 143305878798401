<template>
  <validated-form
    ref="merchantOptionsForm"
    form-id="merchantOptions"
    @valid-submit="handleSubmit(form)"
  >
    <sticky-save-container
      :loading="is.loading"
      :saving="is.submitting"
      title="Merchant Options"
    >
      <template #header>
        <b-tabs
          v-model="selectedMerchantOptionTable"
          type="is-toggle"
          animation="tab-change-alt"
          class="mar-b-sm mar-t-lg"
          @input="handleTabChange"
        >
          <b-tab-item
            v-for="tableName in Object.keys(typesByTable)"
            :key="tableName"
            :value="tableName"
            :data-id="`${tableName}-tab`"
            :label="getTabName(tableName)"
          />
        </b-tabs>
        <b-dropdown
          v-model="selectedMerchantOptionTypeId"
          class="mar-b-lg"
          scrollable
          max-height="25vh"
          :style="{ width: '25rem' }"
        >
          <b-input
            slot="trigger"
            v-model="searchTerm"
            class="dropdown-trigger"
            placeholder="Search for a merchant option type..."
            icon="magnifying-glass"
            :icon-right="searchTerm.length ? 'times-circle' : ''"
            icon-right-clickable
            @icon-right-click="searchTerm = ''"
          />
          <b-dropdown-item
            v-for="moType in filteredMOTypes"
            :key="moType.id"
            :value="moType.id"
            :class="{ 'selected-merchant-type-option': selectedMerchantOptionTypeId === moType.id }"
          >
            {{ capitalCase(moType.name) }}
          </b-dropdown-item>
        </b-dropdown>
      </template>
      <template #default>
        <div
          v-for="(table, tableName) in typesByTable"
          v-show="selectedMerchantOptionTable === tableName"
          :key="tableName"
          class="is-grid row-height-auto col-min-400 gap-lg pad-t-sm"
        >
          <template v-for="({id, name, type}, index) in table">
            <!-- Checkbox -->
            <check-button
              v-if="type === 'bool'"
              :key="name"
              v-model="form[tableName][name]"
              :data-id="`${name}-${id}`"
              data-test-type="bool"
              :disabled="form[tableName][name] === undefined"
              :label="capitalCase(name)"
              class="animated tdFadeInDown"
              :style="{'animation-delay': `${index * (333 / table.length)}ms`}"
            />

            <!-- Text -->
            <b-field
              v-else-if="type === 'string'"
              :key="name"
              :data-id="`${name}-${id}`"
              data-test-type="string"
              :label="capitalCase(name)"
              label-position="on-border"
              class="is-full-height animated tdFadeInDown"
              :style="{'animation-delay': `${index * (333 / table.length)}ms`}"
            >
              <b-input
                v-model="form[tableName][name]"
                :disabled="form[tableName][name] === undefined"
                custom-class="is-full-height"
                :placeholder="capitalCase(name)"
              />
            </b-field>

            <!-- Number -->
            <validated-text-input
              v-else-if="type === 'number'"
              :key="name"
              v-model="form[tableName][name]"
              :data-id="`${name}-${id}`"
              data-test-type="number"
              :disabled="form[tableName][name] === undefined"
              :label="capitalCase(name)"
              :name="name"
              label-position="on-border"
              class="is-full-height animated tdFadeInDown"
              :style="{'animation-delay': `${index * (333 / table.length)}ms`}"
              :placeholder="capitalCase(name)"
              type="number"
              monospaced
            />

            <!-- Text Area -->
            <b-field
              v-else-if="type === 'object'"
              :key="name"
              :data-id="`${name}-${id}`"
              data-test-type="object"
              :label="capitalCase(name)"
              label-position="on-border"
              class="text-area-input animated tdFadeInDown"
              :style="{'animation-delay': `${index * (333 / table.length)}ms`}"
              :type="invalidJsonFields[name] && invalidJsonFields[name].isInvalid ? 'is-danger' : ''"
              :message="invalidJsonFields[name] && invalidJsonFields[name].isInvalid ? invalidJsonFields[name].errorMessage : ''"
            >
              <b-input
                type="textarea"
                :custom-class="jsonTypes.includes(name) ? 'is-monospaced' : ''"
                :placeholder="capitalCase(name)"
                :disabled="form[tableName][name] === undefined"
                :value="form[tableName][name]"
                @input="handleTextAreaInput(name, tableName, $event)"
              />
            </b-field>

            <!-- Fallback -->
            <div
              v-else
              :key="name"
              :data-id="`${name}-${id}`"
              class="is-flex align-center pad-x-sm has-border has-radius has-border-grey-lighter animated tdFadeInDown"
              :style="{'animation-delay': `${index * (333 / table.length)}ms`}"
            >
              <p>Missing input for <b>{{ name }}</b> field</p>
            </div>
          </template>
        </div>
      </template>
    </sticky-save-container>
  </validated-form>
</template>

<script>
  import MerchantOption from '@/store/classes/MerchantOption';
  import MerchantOptionsType from '@/store/classes/MerchantOptionsType';
  import MerchantOptionsCheckout from '@/store/classes/MerchantOptionsCheckout';
  import MerchantOptionsEmv from '@/store/classes/MerchantOptionsEmv';
  import MerchantOptionsLocation from '@/store/classes/MerchantOptionsLocation';
  import MerchantOptionsLoyalty from '@/store/classes/MerchantOptionsLoyalty';
  import MerchantOptionsOpd from '@/store/classes/MerchantOptionsOpd';
  import beautify from 'json-beautify';
  import { capitalCase } from 'change-case';


  export default {
    name: 'MerchantOptions',

    props: {
      merchantId: {
        type: Number,
        required: true
      }
    },

    data() {
      return {
        selectedMerchantOptionTable: 'merchantOptions',
        is: { submitting: false, loading: false },
        form: {
          merchantOptions: {},
          merchantOptionsCheckout: {},
          merchantOptionsEmv: {},
          merchantOptionsLocation: {},
          merchantOptionsLoyalty: {},
          merchantOptionsOpd: {}
        },
        invalidJsonFields: {},
        searchTerm: '',
        selectedMerchantOptionTypeId: null,
        loadedTables: new Set()
      };
    },

    computed: {
      moTypes() {
        return MerchantOptionsType.query().orderBy('name').get();
      },

      filteredMOTypes() {
        return this.moTypes.filter(x => x.name.toLowerCase().includes(this.searchTerm.replace(/\s/g, '').toLowerCase()));
      },

      jsonTypes() {
        return MerchantOptionsType.jsonTypes().map(t => t.name);
      },

      typesByTable() {
        return MerchantOptionsType.typesByTable();
      },

      mo() {
        return MerchantOption.query().where('merchantId', this.merchantId).first();
      },

      moCheckout() {
        return MerchantOptionsCheckout.query().where('merchantId', this.merchantId).first();
      },

      moEmv() {
        return MerchantOptionsEmv.query().where('merchantId', this.merchantId).first();
      },

      moLocation() {
        return MerchantOptionsLocation.query().where('merchantId', this.merchantId).first();
      },

      moLoyalty() {
        return MerchantOptionsLoyalty.query().where('merchantId', this.merchantId).first();
      },

      moOpd() {
        return MerchantOptionsOpd.query().where('merchantId', this.merchantId).first();
      },

      tableClassMap() {
        return {
          merchantOptions: { update: o => MerchantOption.updateMerchantOption(o), create: () => {} },
          merchantOptionsCheckout: {
            create: o => MerchantOptionsCheckout.addMerchantOptionsCheckout(o),
            update: o => MerchantOptionsCheckout.updateMerchantOptionsCheckout(o)
          },
          merchantOptionsEmv: {
            create: o => MerchantOptionsEmv.addMerchantOptionsEmv(o),
            update: o => MerchantOptionsEmv.updateMerchantOptionsEmv(o)
          },
          merchantOptionsLocation: {
            create: o => MerchantOptionsLocation.addMerchantOptionsLocation(o),
            update: o => MerchantOptionsLocation.updateMerchantOptionsLocation(o)
          },
          merchantOptionsLoyalty: {
            create: o => MerchantOptionsLoyalty.addMerchantOptionsLoyalty(o),
            update: o => MerchantOptionsLoyalty.updateMerchantOptionsLoyalty(o)
          },
          merchantOptionsOpd: {
            create: o => MerchantOptionsOpd.addMerchantOptionsOpd(o),
            update: o => MerchantOptionsOpd.updateMerchantOptionsOpd(o)
          }
        };
      }
    },

    watch: {
      selectedMerchantOptionTypeId: 'handleTypeSelection'
    },

    created() {
      this.onCreated();
    },

    methods: {
      capitalCase,
      beautify,

      async handleTabChange(tableName) {
        this.selectedMerchantOptionTable = tableName;
        this.searchTerm = '';
        this.selectedMerchantOptionTypeId = null;

        if (!this.loadedTables.has(tableName)) {
          await this.loadTableData(tableName);
        }
      },

      async handleTypeSelection(typeId) {
        if (typeId) {
          const type = this.moTypes.find(x => x.id === typeId);
          if (!this.loadedTables.has(type.tableName)) {
            await this.loadTableData(type.tableName);
          }
          this.selectedMerchantOptionTable = type.tableName;
          const selectedOptionElement = this.$el.querySelector(`[data-id="${type.name}-${typeId}"]`);
          this.$nextTick(() => {
            if (selectedOptionElement) {
              selectedOptionElement.classList.add('selected-merchant-type-option');
              selectedOptionElement.scrollIntoView({ block: 'center', behavior: 'smooth' });
              selectedOptionElement.focus();
              this.searchTerm = '';
              this.selectedMerchantOptionTypeId = null;
              setTimeout(() => {
                selectedOptionElement.classList.remove('selected-merchant-type-option');
              }, 3 * 1000);
            }
          });
        }
      },

      async loadTableData(tableName) {
        try {
          this.$set(this.is, 'loading', true);
          let data;
          switch (tableName) {
            case 'merchantOptions':
              data = await MerchantOption.fetchMerchantOption(this.merchantId);
              break;
            case 'merchantOptionsCheckout':
              data = await MerchantOptionsCheckout.fetchMerchantOptionsCheckouts(this.merchantId);
              break;
            case 'merchantOptionsEmv':
              data = await MerchantOptionsEmv.fetchMerchantOptionsEmvs(this.merchantId);
              break;
            case 'merchantOptionsLocation':
              data = await MerchantOptionsLocation.fetchMerchantOptionsLocations(this.merchantId);
              break;
            case 'merchantOptionsLoyalty':
              data = await MerchantOptionsLoyalty.fetchMerchantOptionsLoyalties(this.merchantId);
              break;
            case 'merchantOptionsOpd':
              data = await MerchantOptionsOpd.fetchMerchantOptionsOpds(this.merchantId);
              break;
            default:
              break;
          }
          const parsedData = this.parseMerchantOption(tableName === 'merchantOptions' ? data : data.filter(o => o.merchantId !== 0)[0]);
          this.setFormData(parsedData, tableName);
          this.loadedTables.add(tableName);
        }
        catch (error) {
          this.$_onRequestError({
            error,
            toastOptions: {
              message: `There was an issue loading ${this.getTabName(tableName)}`
            }
          });
        }
        finally {
          this.$set(this.is, 'loading', false);
        }
      },

      async onCreated() {
        try {
          this.$set(this.is, 'loading', true);
          // we need to fetch and return the raw network response data here in case new
          // merchant option fields are added that we have not defined model fields for
          await MerchantOptionsType.fetchMerchantOptionsTypes(this.merchantId);

          const mo = await MerchantOption.fetchMerchantOption(this.merchantId);
          const moForm = this.parseMerchantOption(mo);
          this.setFormData(moForm, 'merchantOptions');

          this.loadedTables.add('merchantOptions');
          if (this.$route.params?.selectedMerchantOptionTable) {
            await this.handleTabChange(this.$route.params.selectedMerchantOptionTable);
            this.selectedMerchantOptionTypeId = this.$route.params.selectedMerchantOptionTypeId;
          }
        }
        catch (error) {
          this.$_onRequestError({
            error,
            toastOptions: {
              message: 'There was an issue loading Merchant Options'
            }
          });
        }
        finally {
          this.$set(this.is, 'loading', false);
        }
      },

      parseMerchantOption(option) {
        return Object.entries(option).reduce((obj, [key, value]) => {
          if (this.jsonTypes.includes(key)) {
            obj[key] = JSON.parse(value);
          }
          else {
            obj[key] = value;
          }
          return obj;
        }, {});
      },

      setFormData(newMerchantOption, table) {
        const newForm = {
          ...this.form[table],
          ...newMerchantOption
        };

        this.jsonTypes.forEach((field) => {
          try {
            if (newForm[field]) {
              newForm[field] = beautify(newForm[field], null, 2, 0);
            }
          }
          catch (e) {
            this.$set(this.invalidJsonFields, field, { isInvalid: true, errorMessage: 'Invalid JSON' });
          }
        });

        this.form[table] = newForm;
      },

      getTabName(tableName) {
        switch (tableName) {
          case 'merchantOptions':
            return 'Miscellaneous';
          case 'merchantOptionsOpd':
            return 'Payment Dashboard';
          case 'merchantOptionsCheckout':
          case 'merchantOptionsEmv':
          case 'merchantOptionsLocation':
          case 'merchantOptionsLoyalty':
            return tableName.split('merchantOptions')[1];
          default:
            return 'N/A';
        }
      },

      handleTextAreaInput(field, table, value) {
        if (!value) {
          this.form[table][field] = null;
          this.$set(this.invalidJsonFields, field, { isInvalid: false, errorMessage: null });
        }
        else {
          this.form[table][field] = value;
          if (this.jsonTypes.includes(field)) {
            try {
              if (JSON.parse(value)) {
                this.$set(this.invalidJsonFields, field, { isInvalid: false, errorMessage: null });
              }
            }
            catch {
              this.$set(this.invalidJsonFields, field, { isInvalid: true, errorMessage: 'Invalid JSON' });
            }
          }
        }
      },

      async handleSubmit(formData) {
        try {
          this.$set(this.is, 'submitting', true);
          const promises = [];

          Object.entries(formData).forEach(([tableName, value]) => {
            if (this.loadedTables.has(tableName)) {
              const _form = Object.keys(value).reduce((obj, key) => {
                if (this.jsonTypes.includes(key)) {
                  obj[key] = value[key] && beautify(JSON.parse(value[key]), null, 0, 0);
                }
                else {
                  obj[key] = value[key];
                }
                return obj;
              }, {});
              if (this.form[tableName].id) promises.push(this.tableClassMap[tableName].update(_form));
              else promises.push(this.tableClassMap[tableName].create(_form));
            }
          });

          await Promise.all(promises);

          [
            { updated: this.mo, table: 'merchantOptions' },
            { updated: this.moCheckout, table: 'merchantOptionsCheckout' },
            { updated: this.moEmv, table: 'merchantOptionsEmv' },
            { updated: this.moLocation, table: 'merchantOptionsLocation' },
            { updated: this.moLoyalty, table: 'merchantOptionsLoyalty' },
            { updated: this.moOpd, table: 'merchantOptionsOpd' }
          ].forEach((option) => {
            if (this.loadedTables.has(option.table)) {
              this.setFormData(option.updated, option.table);
            }
          });

          this.$_onRequestSuccess({
            toastOptions: { message: 'Successfully updated Merchant Options' }
          });
        }
        catch (error) {
          this.$_onRequestError({
            error,
            toastOptions: {
              message: 'There was an issue updating Merchant Options'
            }
          });
        }
        finally {
          this.$set(this.is, 'submitting', false);
        }
      }
    }
  };
</script>

<style lang="sass" scoped>
  .selected-merchant-type-option
    box-shadow: 0px 0px 10px -2px $primary

  .dropdown-trigger
    ::v-deep.input
      width: 25rem

  ::v-deep
    .text-area-input
      grid-row: span 3

      .help
        text-align: right
        padding: $size-extra-small / 2 $size-extra-small
        margin: 0
        transform: translateY(-100%)

      .textarea
        resize: none
        height: 100%

    .tabs
      a
        width: fit-content

      .is-active
        a
          background-color: $dark !important
          border-color: $dark !important
          font-weight: 600

    .tab-content
      padding: $size-large 0 0 0 !important

    .is-floating-label .label
      font-size: 0.9rem

    .control,
    .input-wrapper,
    .input
      height: 100% !important
</style>
