<template>
  <div class="container">
    <div class="card">
      <h3 class="title is-4 pad-x pad-t">Gift Cards</h3>
      <div class="is-flex justify-end pad-x pad-b">
        <b-field class="control" label="Show Inactive Gift Cards" horizontal>
          <b-switch v-model="showInactive" />
        </b-field>
      </div>
      <b-table
        :data="isFetchingGiftCards ? [] : giftCards"
        class="is-middle-aligned"
        paginated
        scrollable
        :per-page="25"
        pagination-position="bottom"
        :mobile-cards="false"
        :default-sort="['balanceRefreshDate', 'desc']"
      >
        <template v-slot:empty>
          <empty-table-loader
            :loading="isFetchingGiftCards"
            :has-icon="false"
            message="No Gift Cards found for this account"
          />
        </template>
        <b-table-column
          v-slot="{ row }"
          sortable
          field="cardNumber"
          label="Card Number"
          cell-class="no-wrap-text"
        >
          {{ formatGiftCard(row) }}
        </b-table-column>
        <b-table-column
          v-slot="{ row }"
          centered
          sortable
          field="balance"
          label="Balance"
          cell-class="no-wrap-text"
        >
          {{ row.balance | dollars }}
        </b-table-column>
        <b-table-column
          v-slot="{ row }"
          centered
          sortable
          field="createdAt"
          label="Date Added"
          cell-class="no-wrap-text"
        >
          {{ row.createdAt | moment('MM/DD/YYYY') }}
        </b-table-column>
        <b-table-column
          v-slot="{ row }"
          centered
          sortable
          field="balanceRefreshDate"
          label="Balance Last Updated"
          cell-class="no-wrap-text"
        >
          {{ row.balanceRefreshDate | moment('MM/DD/YYYY') }}
        </b-table-column>
        <b-table-column
          v-slot="{ row }"
          centered
          sortable
          field="isVirtual"
          label="Type"
          cell-class="no-wrap-text"
        >
          {{ row.isVirtual ? 'Virtual' : 'Physical' }}
        </b-table-column>
        <b-table-column
          v-slot="{ row }"
          centered
          sortable
          field="isActive"
          label="Active"
          cell-class="no-wrap-text"
        >
          <b-icon
            size="is-small"
            class="is-size-7"
            :type="row.isActive ? 'is-success' : 'is-danger'"
            icon="circle"
          />
        </b-table-column>
        <b-table-column
          v-if="$can('update', 'GiftCard')"
          v-slot="{ row }"
          centered
          numeric
          cell-class="actions"
          label="Actions"
          width="1"
        >
          <dropdown-menu
            v-if="$can('update', 'GiftCard')"
            position="bottom-end"
          >
            <b-button
              slot="trigger"
              class="is-transparent"
              type="is-white"
            >
              <b-icon
                icon="ellipsis-v"
                pack="far"
                size="is-small"
              />
            </b-button>
            <template v-if="row.isActive">
              <b-dropdown-item
                v-if="$_selectedMerchant.allowInternalFundingPayment"
                @click="openGiftCardModal({ giftCard: row, action: giftCardActions.ADD_FUNDS })"
              >
                <b-icon
                  icon="money-from-bracket"
                  class="mar-r-xs"
                  size="is-small"
                />
                Add Funds
              </b-dropdown-item>
              <b-dropdown-item
                v-if="giftCardBalanceMergeEnabled"
                @click="openGiftCardModal({ giftCard: row, action: giftCardActions.MERGE })"
              >
                <b-icon
                  icon="money-bill-transfer"
                  class="mar-r-xs"
                  size="is-small"
                />
                Merge
              </b-dropdown-item>
            </template>
            <b-dropdown-item
              v-if="!row.isActive || row.isActive && parseFloat(row.balance) > 0"
              @click="confirmToggleGiftCardActiveState(row)"
            >
              <b-icon
                icon="bolt"
                :pack="row.isActive ? 'far' : 'fas'"
                class="mar-r-xs"
                size="is-small"
              />
              {{ row.isActive ? 'Deactivate' : 'Activate' }}
            </b-dropdown-item>
            <b-dropdown-item
              v-if="row.isActive && (row.isVirtual && parseFloat(row.balance) === 0) || !row.isVirtual"
              @click="confirmUnregisterGiftCard(row)"
            >
              <b-icon
                icon="trash-can-xmark"
                class="mar-r-xs"
                size="is-small"
              />
              Unregister
            </b-dropdown-item>
            <b-dropdown-item v-if="row.isActive && row.balance > 0" class="pad-r-sm">
              <router-link
                v-tippy="{
                  content: 'Visit Gift Card Management to refund purchase'
                }"
                class="is-inline-block has-hover-text-underline is-full-width"
                :to="{
                  name: 'giftCardTransactions',
                  query: { param: 'cardNumberLastFour', query: row.cardNumber.slice(-4) }
                }"
              >
                <b-icon
                  icon="arrow-up-right-from-square"
                  class="mar-r-xs"
                  size="is-small"
                />
                Refund
              </router-link>
            </b-dropdown-item>
          </dropdown-menu>
        </b-table-column>
      </b-table>
    </div>
  </div>
</template>

<script>
  import AlertModal from '@/components/globals/alert-modal.vue';

  import capitalCase from '@/helpers/capitalCase';

  import featurePermissionsMixin from '@/mixins/featurePermissions';
  import merchantMixn from '@/mixins/merchant';

  import GiftCard from '@/store/classes/GiftCard';

  import AddGiftCardFundsModal from '../modals/add-gift-card-funds-modal.vue';
  import MergeGiftCardsModal from '../modals/merge-gift-cards-modal.vue';


  export default {
    name: 'RegisteredGuestGiftCardsTable',

    mixins: [featurePermissionsMixin, merchantMixn],

    props: {
      registeredGuestId: {
        type: Number,
        required: true
      }
    },

    data: () => ({
      giftCardActions: {
        ADD_FUNDS: 'addFunds',
        MERGE: 'merge'
      },
      showInactive: false
    }),

    computed: {
      isFetchingGiftCards() {
        return GiftCard.$state().fetching;
      },

      giftCards() {
        let query = GiftCard.query().where('accountId', this.registeredGuestId);

        if (!this.showInactive) {
          query = query.where('isActive', true);
        }

        return query.get();
      },

      showFullGcNumber() {
        return this.$_featurePermissions?.SHOW_FULL_GC_NUMBER;
      },

      giftCardBalanceMergeEnabled() {
        return this.$_selectedMerchant?.merchantGiftCardConfigurations?.[0]?.allowBalanceMerge;
      }
    },

    created() {
      this.onCreated();
    },

    methods: {
      async onCreated() {
        await this.fetchGiftCardsForAccount();
      },

      async fetchGiftCardsForAccount({ ids = [] } = {}) {
        try {
          await GiftCard.fetchGiftCardsForAccount({ accountId: this.registeredGuestId, ids });
        }
        catch (error) {
          this.$_onRequestError({
            toastOptions: {
              message: 'There was an issue fetching this account\'s gift cards'
            },
            error
          });
        }
      },

      buildGiftCardModalArgs({ giftCard, action }) {
        const modalArgs = {
          component: null,
          props: {
            giftCard,
            showFullGcNumber: this.showFullGcNumber
          },
          events: {}
        };

        if (action === this.giftCardActions.ADD_FUNDS) {
          modalArgs.component = AddGiftCardFundsModal;
        }
        else if (action === this.giftCardActions.MERGE) {
          modalArgs.component = MergeGiftCardsModal;
          modalArgs.props.registeredGuestId = this.registeredGuestId;
          modalArgs.events['fetch-gift-cards'] = ids => this.fetchGiftCardsForAccount({ ids });
        }

        return modalArgs;
      },

      openGiftCardModal({ giftCard, action }) {
        const giftCardModalArgs = this.buildGiftCardModalArgs({ giftCard, action });

        this.$buefy.modal.open({
          parent: this,
          component: giftCardModalArgs.component,
          hasModalCard: true,
          trapFocus: true,
          canCancel: ['escape', 'outside'],
          props: { ...giftCardModalArgs.props },
          events: { ...giftCardModalArgs.events }
        });
      },

      confirmToggleGiftCardActiveState(giftCard) {
        const action = giftCard.isActive ? 'deactivate' : 'activate';

        let message = `This action will <b>${action}</b> Gift Card ${this.formatGiftCard(giftCard)}.`;
        if (giftCard.isActive) {
          message += ' This will take the gift card\'s balance down to $0 and take it off the payment processor.';
        }

        this.$buefy.modal.open({
          parent: this,
          component: AlertModal,
          hasModalCard: true,
          trapFocus: true,
          canCancel: ['escape', 'outside'],
          customClass: 'auto-width',
          props: {
            title: `${capitalCase(action)} Gift Card`,
            message,
            secondaryMessage: 'Are you sure you would like to proceed?',
            horizontal: true,
            showCloseButton: false,
            icon: 'bolt',
            pack: giftCard.isActive ? 'far' : 'fas',
            type: giftCard.isActive ? 'is-danger' : 'is-success',
            buttons: [
              { text: 'Cancel' },
              {
                text: `${capitalCase(action)}`,
                primary: true,
                onClick: () => this.toggleGiftCardActiveStatus({ giftCard, action })
              }
            ]
          }
        });
      },


      async unregisterGiftCard(giftCard) {
        try {
          await GiftCard.unregister(giftCard.id);

          await this.fetchGiftCardsForAccount({ ids: [giftCard.id] });

          this.$_onRequestSuccess({
            toastOptions: { message: `Successfully unregistered gift card ${this.formatGiftCard(giftCard)}` }
          });
        }

        catch (error) {
          this.$_onRequestError({
            error,
            toastOptions: { message: `There was an error unregistering gift card ${this.formatGiftCard(giftCard)}` }
          });
        }
      },

      confirmUnregisterGiftCard(giftCard) {
        this.$buefy.modal.open({
          parent: this,
          component: AlertModal,
          hasModalCard: true,
          trapFocus: true,
          canCancel: ['escape', 'outside'],
          customClass: 'auto-width',
          props: {
            title: 'Unregister Gift Card',
            message: 'This action will unregister this Gift Card making it inactive to a user',
            secondaryMessage: 'Are you sure you would like to proceed?',
            horizontal: true,
            showCloseButton: false,
            icon: 'trash-can-xmark',
            type: 'is-danger',
            buttons: [
              { text: 'Cancel' },
              {
                text: 'Unregister',
                primary: true,
                onClick: () => this.unregisterGiftCard(giftCard)
              }
            ]
          }
        });
      },

      async toggleGiftCardActiveStatus({ giftCard, action }) {
        try {
          await GiftCard.updateActiveStatus({ giftCardId: giftCard.id, action });

          this.$_onRequestSuccess({
            toastOptions: { message: `Successfully ${giftCard.isActive ? 'deactivated' : 'activated'} gift card ${this.formatGiftCard(giftCard)}` }
          });
        }

        catch (error) {
          this.$_onRequestError({
            error,
            toastOptions: { message: `There was an error ${giftCard.isActive ? 'deactivating' : 'activating'} gift card ${this.formatGiftCard(giftCard)}` }
          });
        }
      },

      formatGiftCard(giftCard) {
        return this.showFullGcNumber ? giftCard.cardNumber : `•••• ${giftCard.cardNumber.slice(-4)}`;
      }
    }
  };
</script>
