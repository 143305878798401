<template>
  <div class="dialog">
    <div class="modal-card no-overflow" :class="autoWidth && 'auto-width'" @keydown.esc="handleClose">
      <header class="modal-card-head">
        <div class="modal-card-title is-flex-column">
          <p>{{ title }}</p>
          <p v-if="subtitle" class="subtitle is-6 has-text-grey mar-t-xs">{{ subtitle }}</p>
        </div>
        <button v-if="showCloseButton" class="button is-transparent mar-l-md" @click="handleClose">
          <b-icon icon="times" size="is-small" />
        </button>
      </header>
      <div
        :class="[
          'alert-modal modal-card-body no-overflow pad-md',
          { 'is-horizontal': horizontal }
        ]"
      >
        <div
          v-if="imagePath || icon"
          class="alert-modal-graphic"
          :class="{
            'align-graphic-start': iconPosition === 'top',
            'align-graphic-center': iconPosition === 'middle',
            'align-graphic-end': iconPosition === 'bottom',
          }"
        >
          <img
            v-if="imagePath"
            :src="imagePath"
            :class="['alert-modal-image', {'is-small': horizontal}]"
            alt="banner icon"
          >
          <b-icon
            v-else-if="icon"
            class="alert-modal-icon"
            :pack="iconPack"
            :icon="icon"
            :type="type"
            :custom-size="horizontal ? 'fa-3x' : 'fa-5x'"
          />
        </div>

        <div :class="['alert-message', autoWidth && 'auto-width']">
          <slot name="message">
            <p
              class="is-size-6"
              :class="{'has-text-centered': !horizontal}"
              v-html="message"
            />
          </slot>
        </div>
      </div>
      <div
        v-if="buttons || secondaryMessage"
        :class="[
          'modal-card-foot',
          { 'has-background-white-bis has-border-top has-border-grey-lightest': twoToned || secondaryMessage || $slots['secondary-message'] }
        ]"
      >
        <div>
          <div
            v-if="secondaryMessage || $slots['secondary-message']"
            class="secondary-message mar-b"
            :class="autoWidth && 'auto-width'"
          >
            <slot name="secondary-message">
              <p class="has-text-centered" v-html="secondaryMessage" />
            </slot>
          </div>
          <div v-if="buttons" class="buttons all-bold is-centered">
            <b-button
              v-for="(button, index) in buttons"
              :key="index"
              :ref="`button-${index + 1}`"
              :data-focused="buttons.length === index + 1"
              rounded
              :outlined="button.secondary"
              :icon-right="button.icon"
              :icon-pack="button.iconPack"
              :type="button.primary || button.secondary ? `${type}${button.secondary ? ' is-light' : ''}` : ''"
              :loading="loadingButtonIndex === index"
              @click="handleClick(button.onClick, index)"
            >
              {{ button.text }}
            </b-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import confirmModalCloseMixin from '@/mixins/confirm-modal-close';

  export default {
    name: 'AlertModal',

    mixins: [confirmModalCloseMixin],

    props: {
      autoWidth: {
        type: Boolean,
        default: false
      },
      buttons: {
        type: Array,
        default: null
      },
      forceClose: {
        type: Boolean,
        default: false
      },
      horizontal: {
        type: Boolean,
        default: false
      },
      icon: {
        type: String,
        default: ''
      },
      iconPack: {
        type: String,
        default: 'fa'
      },
      iconPosition: {
        type: String,
        default: 'middle'
      },
      imagePath: {
        type: String,
        default: ''
      },
      message: {
        type: String,
        default: 'Sample message text'
      },
      onClose: {
        type: Function,
        default: null
      },
      secondaryMessage: {
        type: String,
        default: null
      },
      showCloseButton: {
        type: Boolean,
        default: true
      },
      subtitle: {
        type: String,
        default: ''
      },
      title: {
        type: String,
        default: 'Sample Title'
      },
      twoToned: {
        type: Boolean,
        default: false
      },
      type: {
        type: String,
        default: 'is-primary'
      }
    },

    data() {
      return {
        loadingButtonIndex: null
      };
    },

    mounted() {
      this.onMounted();
    },

    methods: {
      onMounted() {
        if (this.buttons) {
          this.$nextTick(() => {
            this.$el.querySelector('[data-focused]').focus();
          });
        }
      },

      setLoadingButtonIndex(index) {
        this.loadingButtonIndex = index;
      },

      async handleClick(callback, index) {
        if (callback) {
          try {
            this.setLoadingButtonIndex(index);
            await callback();
            this.handleClose();
          }
          catch (err) {
            throw err;
          }
          finally {
            this.setLoadingButtonIndex(null);
          }
        }
        else {
          this.handleClose();
        }
      },

      handleClose() {
        if (this.onClose) {
          this.onClose();
        }
        this.$_confirmCloseModal({ programmatic: true, forceClose: this.forceClose });
      }
    }
  };
</script>

<style lang="sass" scoped>
  .modal-card
    margin: 0 auto !important
    &:not(.auto-width)
      max-width: 500px !important

  .alert-modal-graphic
    margin: 0 0 $size-normal 0

    .is-horizontal &
      margin: 0 $size-normal 0 0
      padding: 0 $size-small

      &.align-graphic-start
        align-self: flex-start
      &.align-graphic-center
        align-self: center
      &.align-graphic-end
        align-self: flex-end

  .subtitle
    white-space: pre-wrap

  .alert-modal
    display: flex
    align-items: center
    max-width: $tablet
    flex-direction: column

    &.is-horizontal
      flex-direction: row

    .alert-modal-image
      width: 150px
      height: 150px

      &.is-small
        width: 64px
        height: 64px

  .alert-message
    text-align: left

  .alert-message,
  .secondary-message
    &:not(.auto-width)
      max-width: 22rem

  .alert-modal-content
    margin-left: 1.5rem

  .alert-modal-icon
    width: unset
    height: unset

  @media (max-width: $mobile)
    .alert-modal
      flex-direction: column
      text-align: center

      .buttons
        justify-content: center

    .alert-modal-content
      margin-top: 1.5rem
      margin-left: 0

    .alert-modal-graphic
      align-self: center !important

    .is-horizontal .alert-message
      text-align: center
      margin-left: 0
      margin-top: $size-normal
</style>
