<template>
  <b-collapse
    class="card"
    animation="slide"
    :open="startOpen"
  >
    <template #trigger="props">
      <div
        class="card-header-sticky is-clickable"
        data-test-id="header"
      >
        <div class="is-flex gap-xs align-center panel-title is-full-width">
          <b-icon
            v-if="icon"
            :icon="icon"
            type="is-primary"
          />
          <p
            :id="paramCase(title)"
            class="link-inverted is-inline-block no-wrap-text mar-b-none"
          >
            <span class="has-text-weight-bold is-size-5">
              {{ title }}
            </span>
            <span
              v-if="subtitle"
              class="has-text-grey is-size-5"
            >
              {{ subtitle }}
            </span>
          </p>
          <b-icon
            class="mar-l-auto"
            :icon="props.open ? 'angle-down' : 'angle-right'"
          />
        </div>
      </div>
    </template>
    <div class="card-content pad-t-none dist-y">
      <b-loading
        v-if="!isPrinting"
        :active="isLoading"
        :is-full-page="false"
      />
      <slot />
    </div>
  </b-collapse>
</template>

<script>
  import { paramCase } from 'change-case';

  export default {

    name: 'ExpoOrderDetailsCard',

    props: {
      title: {
        type: String,
        required: true
      },

      subtitle: {
        type: String,
        default: null
      },

      icon: {
        type: String,
        default: null
      },

      startOpen: {
        type: Boolean,
        default: false
      },

      isLoading: {
        type: Boolean,
        default: false
      },

      isPrinting: {
        type: Boolean,
        default: false
      }
    },

    data() {
      return {
        paramCase
      };
    }
  };
</script>
