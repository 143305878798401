<template>
  <dl>
    <div class="is-flex justify-between">
      <dt class="has-text-grey">Subtotal</dt>
      <dd>{{ orderSubtotal | dollars }}</dd>
    </div>

    <div
      v-if="order.surcharge"
      data-test-id="surcharge-line-item"
      class="is-flex justify-between"
    >
      <dt class="has-text-grey">Surcharge</dt>
      <dd>{{ order.surcharge | dollars }}</dd>
    </div>

    <order-fees
      v-if="order.orderFees.length"
      :order-fees="order.orderFees"
      custom-classes="mar-b-none"
    />

    <div v-if="order.shipmentFee" class="is-flex justify-between">
      <dt class="has-text-grey">Shipping Fee</dt>
      <dd>{{ order.shipmentFee | dollars }}</dd>
    </div>

    <template v-if="order.offers && filteredOffers.length">
      <p class="has-text-grey">
        Discount
      </p>
      <div v-for="(offer, index) in filteredOffers" :key="offer.id + index" class="pad-l is-flex justify-between has-border-left-lg has-border-grey-lighter">
        <dt class="has-text-grey">
          {{ offer.name }}
        </dt>
        <dd class="has-text-primary"> -{{ offer.totalDiscountAmount | dollars }}</dd>
      </div>
    </template>

    <div v-if="order.externalPaymentAmount" class="is-flex justify-between">
      <dt class="has-text-grey">Payment Applied</dt>
      <dd class="has-text-primary">-{{ order.externalPaymentAmount | dollars }}</dd>
    </div>


    <div class="is-flex justify-between">
      <dt class="has-text-grey">Tax</dt>
      <dd>{{ order.tax | dollars }}</dd>
    </div>
    <div v-if="order.serviceCharges" class="is-flex justify-between">
      <dt class="has-text-grey">Service Charges</dt>
      <dd>{{ order.serviceCharges | dollars }}</dd>
    </div>

    <div class="is-flex justify-between">
      <dt class="has-text-grey">Tip</dt>
      <dd>{{ order.tip | dollars }}</dd>
    </div>

    <div v-if="order.delivery || order.deliveryCharges" class="mar-t-xs">
      <template v-if="order.delivery">
        <div class="is-flex justify-between">
          <dt class="has-text-grey">Delivery Charge</dt>
          <dd>{{ order.delivery.fee || 0 | dollars }}</dd>
        </div>
        <div class="is-flex justify-between">
          <dt class="has-text-grey">Delivery Tip</dt>
          <dd>{{ order.delivery.tip || 0 | dollars }}</dd>
        </div>
      </template>
      <template v-else>
        <div class="is-flex justify-between">
          <dt class="has-text-grey">Delivery Charge</dt>
          <dd>{{ order.deliveryCharges | dollars }}</dd>
        </div>
        <div class="is-flex justify-between">
          <dt class="has-text-grey">Delivery Tip</dt>
          <dd>{{ 0 | dollars }}</dd>
        </div>
      </template>
    </div>

    <div class="is-flex justify-between align-center mar-t-sm has-text-weight-bold">
      <dt class="has-text-grey">Total</dt>
      <dd class="is-size-4">{{ order.orderTotalWithExternalPayment | dollars }}</dd>
    </div>
  </dl>
</template>

<script>
  import orderTypes from '@/constants/orderTypes';
  import orderFees from '@/components/globals/order-fees.vue';

  import currency from 'currency.js';



  export default {
    name: 'OrderPriceBreakdown',

    components: { orderFees },

    props: {
      order: {
        type: Object,
        required: true
      }
    },

    computed: {
      filteredOffers() {
        return this.order.offers.filter(o => !!o.isApplied);
      },

      orderSubtotal() {
        if ([orderTypes.PAY_AND_FULFILL, orderTypes.ORDER_AND_PAY].includes(this.order.orderType)) {
          return currency(this.order.subtotal).add(currency(this.order.discount))?.value;
        }
        return this.order.orderItemTotal;
      }
    }
  };
</script>
