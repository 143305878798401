<template>
  <validated-form
    ref="form"
    form-id="guestSettingsForm"
    @valid-submit="handleSubmit"
  >
    <panel
      title="Guest Settings"
      subtitle="Customize how your guests complete their orders"
      collapsible
    >
      <template #buttons>
        <b-button
          rounded
          class="is-bold"
          size="is-medium"
          native-type="submit"
          type="is-primary"
          :disabled="hasUnchangedValue"
          :loading="isSubmitting"
        >
          Save
        </b-button>
      </template>

      <validated-input
        v-if="$_selectedMerchant.orderAheadEnabled || $_selectedMerchant.oatEnabled"
        class="align-labels-left"
        label="Order Instructions"
        tooltip="Allow guests to add instructions while placing their orders"
        horizontal
        name="allowOrderInstruction"
      >
        <b-switch v-model="orderInstructionsEnabled" />
      </validated-input>
      <!-- <b-field horizontal class="align-labels-left" label-for="paymentAddress" label="Payment Billing Address">
        <div class="radio-group">
          <b-radio
            v-model="requireFullAddress"
            name="paymentAddress"
            :native-value="true"
          >
            Full Address
          </b-radio>
          <b-radio
            v-model="requireFullAddress"
            name="paymentAddress"
            :native-value="false"
          >
            ZIP Code Only
          </b-radio>
        </div>
      </b-field> -->
    </panel>
  </validated-form>
</template>

<script>
  import merchantMixin from '@/mixins/merchant';
  import MerchantOptionsCheckout from '@/store/classes/MerchantOptionsCheckout';

  export default {
    name: 'ContentGuestSettingsForm',

    mixins: [merchantMixin],

    data() {
      return {
        isSubmitting: false,
        orderInstructionsEnabled: false
        // requireFullAddress: false
      };
    },

    computed: {
      hasUnchangedValue() {
        return this.orderInstructionsEnabled === this.$_selectedMerchant.merchantOptionsCheckout.orderInstructionsEnabled;
      }
    },

    created() {
      this.onCreated();
    },

    methods: {
      onCreated() {
        this.orderInstructionsEnabled = this.$_selectedMerchant.merchantOptionsCheckout.orderInstructionsEnabled;
      },

      async handleSubmit() {
        this.isSubmitting = true;

        try {
          await MerchantOptionsCheckout.updateMerchantOptionsCheckout({
            id: this.$_selectedMerchant.merchantOptionsCheckout.id,
            orderInstructionsEnabled: this.orderInstructionsEnabled
          });

          this.$_onRequestSuccess({
            toastOptions: {
              message: 'Guest Settings have been successfully updated!'
            }
          });
        }

        catch (error) {
          this.$_onRequestError({
            toastOptions: {
              message: 'Unable to update your Guest Settings'
            },
            error
          });
        }

        finally {
          this.isSubmitting = false;
        }
      }
    }
  };
</script>
