import http from '@/services/http';
import { Model } from '@vuex-orm/core';


export default class AppCodeType extends Model {
  static entity = 'appCodeTypes'


  // FIELDS //////////////////////
  static fields() {
    return {
      id: this.attr(''),
      name: this.attr('')
    };
  }



  // STATE //////////////////////
  static state() {
    return {
      fetching: false
    };
  }

  static $state() {
    return this.store().state.entities.appCodeTypes;
  }



  // ACTIONS //////////////////////
  static async fetchAppCodeTypes() {
    try {
      this.commit((state) => {
        state.fetching = true;
      });

      const { data } = await http.get('app_code_types');
      this.create({ data: data.appCodeTypes });
    }

    catch (error) {
      throw error;
    }

    finally {
      this.commit((state) => {
        state.fetching = false;
      });
    }
  }
}
