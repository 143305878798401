<template>
  <validated-form
    v-slot="{ dirty }"
    ref="storeDeliveryServiceSettingsModal"
    form-id="storeDeliveryServiceSettingsModal"
    @valid-submit="handleSubmit"
  >
    <modal-card :title="`${capitalCase(storeDeliveryService.name)} Settings`">
      <div class="dist-y">
        <b-collapse
          v-if="IN_HOUSE_DELIVERY_SERVICE_ID === storeDeliveryService.deliveryServiceId"
          animation="slide"
        >
          <div
            slot="trigger"
            slot-scope="props"
            role="button"
            class="is-flex align-center justify-between has-cursor-pointer"
          >
            <h4 class="subtitle is-5 is-marginless">
              Delivery
            </h4>
            <b-icon class="open-indicator" :icon="props.open ? 'angle-down' : 'angle-right'" />
          </div>
          <div class="is-grid col-2 gap-x mar-y">
            <validated-text-input
              v-model="storeDeliveryServiceForm.deliveryDistanceInMiles"
              :disabled="!$can('crud', 'StoreDeliveryService')"
              name="deliveryRadius"
              label-position="on-border"
              label="Delivery Distance Radius"
              sub-label="(in miles)"
              sub-label-on-side
              placehold="Enter distance"
              type="number"
              :rules="{ min_value: 0 }"
            />
            <validated-text-input
              v-model="estimatedDeliveryTime"
              :disabled="!$can('crud', 'StoreDeliveryService')"
              name="estimatedDeliveryTime"
              label-position="on-border"
              label="Estimated Delivery Time"
              sub-label="(in minutes)"
              sub-label-on-side
              placeholder="Entire time"
              type="number"
            />
          </div>

          <label class="has-text-weight-bold">
            Delivery Schedule
            <b-icon
              v-tippy="{ maxWidth: 270 }"
              :content="`If a schedule is not set for a given day, there will be no ${capitalCase(storeDeliveryService.name)} delivery available on that day.`"
              pack="far"
              icon="info-circle"
              class="is-size-6"
              type="is-grey"
            />
          </label>
          <div class="hours-container">
            <template v-for="(dayData, dayCode) in daysConfig">
              <div :key="dayCode" class="day-container">
                <div class="is-flex align-center has-background-white-bis has-border-right has-border-grey-lighter">
                  <p class="title is-6">{{ dayData.label }}</p>
                </div>
                <div class="day-hours">
                  <div class="dist-y">
                    <div v-for="({ start, end, id }, index) in hoursForm[dayCode]" :key="`${dayCode}-${id}`" class="hours">
                      <validated-input
                        :rules="{required: hoursForm[dayCode].length}"
                        label-position="on-border"
                        label="Start"
                        :name="`${dayCode}-start-${index}`"
                        class="mar-none"
                      >
                        <b-timepicker
                          :increment-minutes="1"
                          :default-minutes="0"
                          :value="start"
                          size="is-small"
                          inline
                          :disabled="!$can('crud', 'StoreDeliveryServiceSchedule')"
                          @input="updateHours({ value: $event, day: dayCode, index, type: 'start' })"
                        />
                      </validated-input>
                      <validated-input
                        :rules="{required: hoursForm[dayCode].length}"
                        label-position="on-border"
                        label="End"
                        :name="`${dayCode}-end-${index}`"
                        class="mar-none"
                      >
                        <b-timepicker
                          :increment-minutes="1"
                          :default-minutes="0"
                          :value="end"
                          size="is-small"
                          inline
                          :disabled="!$can('crud', 'StoreDeliveryServiceSchedule')"
                          @input="updateHours({ value: $event, day: dayCode, index, type: 'end' })"
                        />
                      </validated-input>
                      <b-button
                        class="align-self-center"
                        icon-left="minus"
                        outlined
                        size="is-small"
                        type="is-danger is-light"
                        :disabled="!$can('crud', 'StoreDeliveryServiceSchedule')"
                        @click="handleDelete(dayCode, index)"
                      />
                    </div>
                  </div>
                  <validation-provider
                    v-slot="{ errors }"
                    :name="`overlapErrors-${dayCode}`"
                    :rules="{ required: invalidDays.includes(dayCode) }"
                  >
                    <b-checkbox :value="!invalidDays.includes(dayCode) || null" :class="['is-hidden', { 'invalid': errors.length }]" />
                    <b-message
                      v-if="invalidDays.includes(dayCode)"
                      :key="`${dayCode}-invalid`"
                      type="is-danger"
                      size="is-small"
                      class="is-compact has-shadow mar-t-sm is-wrap"
                      has-icon
                      icon="exclamation-circle"
                    >
                      {{ overlapErrorMessage }}
                    </b-message>
                  </validation-provider>
                </div>

                <div class="is-flex align-start">
                  <b-button
                    icon-left="plus"
                    class="mar-t-sm"
                    size="is-small"
                    type="is-primary is-light"
                    :disabled="!$can('crud', 'StoreDeliveryServiceSchedule')"
                    @click="addHoursRow(dayCode)"
                  />
                </div>
              </div>
            </template>
          </div>
        </b-collapse>

        <hr v-if="IN_HOUSE_DELIVERY_SERVICE_ID === storeDeliveryService.deliveryServiceId">

        <b-collapse
          class="padding"
          animation="slide"
        >
          <div
            slot="trigger"
            slot-scope="props"
            role="button"
            class="is-flex align-center justify-between has-cursor-pointer"
          >
            <h4 class="subtitle is-5 is-marginless">
              Fees
            </h4>
            <b-icon class="open-indicator" :icon="props.open ? 'angle-down' : 'angle-right'" />
          </div>
          <div class="is-grid col-2 gap-x gap-y-lg mar-t">
            <validated-input
              name="deliveryFeeType"
              label="Delivery Fee Type"
              label-position="on-border"
              :disabled="!$can('crud', 'StoreDeliveryService')"
            >
              <b-select
                v-model="storeDeliveryServiceForm.feeTypeId"
                placeholder="Select a fee type..."
                expanded
                @input="storeDeliveryServiceForm.feeAmount = null"
              >
                <option :value="null">-</option>
                <option
                  v-for="{ id, name } in deliveryFeeTypes"
                  :key="id"
                  :value="id"
                >
                  {{ capitalCase(name) }}
                </option>
              </b-select>
            </validated-input>
            <div>
              <transition name="fade-right" mode="out-in">
                <validated-text-input
                  v-if="storeDeliveryServiceForm.feeTypeId === 3"
                  :key="storeDeliveryServiceForm.feeTypeId"
                  v-model="feeAmount"
                  placeholder="Set a fee percentage..."
                  class="percent"
                  name="feeAmount"
                  label="Fee Percentage"
                  label-position="on-border"
                  type="float"
                  :rules="{
                    min_value: 0.01,
                    max_value: 100,
                    required: storeDeliveryServiceForm.feeTypeId !== null
                  }"
                  :mask-options="{ numeralDecimalScale: 2 }"
                  :disabled="!$can('crud', 'StoreDeliveryService')"
                />
                <validated-text-input
                  v-else-if="isDeliveryFeeAmountType"
                  :key="storeDeliveryServiceForm.feeTypeId"
                  v-model="feeAmount"
                  placeholder="Set a fee amount..."
                  name="feeAmount"
                  label="Fee Amount"
                  label-position="on-border"
                  type="dollars"
                  :rules="{
                    min_value: 0.01,
                    required: storeDeliveryServiceForm.feeTypeId !== null
                  }"
                  :disabled="!$can('crud', 'StoreDeliveryService')"
                />
              </transition>
            </div>

            <validated-input
              name="deliveryServiceChargeType"
              label="Service Charge Type"
              label-position="on-border"
            >
              <b-select
                v-model="storeDeliveryServiceForm.deliveryServiceChargeTypeId"
                placeholder="Choose charge type..."
                expanded
                :disabled="!$can('crud', 'StoreDeliveryService')"
                @input="storeDeliveryServiceForm.deliveryServiceChargeAmount = null"
              >
                <option :value="null">-</option>
                <option
                  v-for="{ id, name } in deliveryServiceChargeTypes"
                  :key="id"
                  :value="id"
                >
                  {{ capitalCase(name) }}
                </option>
              </b-select>
            </validated-input>
            <div>
              <transition name="fade-right" mode="out-in">
                <validated-text-input
                  v-if="isDeliveryServiceChargePercentType"
                  :key="storeDeliveryServiceForm.deliveryServiceChargeTypeId"
                  v-model="deliveryServiceChargeAmount"
                  class="percent"
                  name="deliveryServiceChargeAmount"
                  label="Service Charge Percentage"
                  label-position="on-border"
                  placeholder="Set a service charge percentage..."
                  type="float"
                  :rules="{
                    min_value: 0.01,
                    max_value: 100,
                    required: storeDeliveryServiceForm.deliveryServiceChargeTypeId !== null
                  }"
                  :mask-options="{ numeralDecimalScale: 2 }"
                  :disabled="!$can('crud', 'StoreDeliveryService')"
                />
                <validated-text-input
                  v-else-if="isDeliveryServiceChargeAmountType"
                  :key="storeDeliveryServiceForm.deliveryServiceChargeTypeId"
                  v-model="deliveryServiceChargeAmount"
                  name="deliveryServiceChargeAmount"
                  label="Service Charge Amount"
                  label-position="on-border"
                  placeholder="Set a service charge amount..."
                  type="dollars"
                  :rules="{
                    min_value: 0.01,
                    required: storeDeliveryServiceForm.deliveryServiceChargeTypeId !== null
                  }"
                  :disabled="!$can('crud', 'StoreDeliveryService')"
                />
              </transition>
            </div>
            <validated-text-input
              v-model="deliveryUpcharge"
              placeholder="Set a delivery upcharge percent..."
              class="percent"
              name="deliveryUpcharge"
              label="Delivery Upcharge"
              label-position="on-border"
              type="float"
              :rules="{ min_value: 0.01, max_value: 100 }"
              :mask-options="{ numeralDecimalScale: 2 }"
              :disabled="!$can('crud', 'StoreDeliveryService')"
            />
          </div>
        </b-collapse>

        <hr v-if="isCardfreeAdmin">

        <b-collapse
          v-if="isCardfreeAdmin"
          class="padding"
          animation="slide"
        >
          <div
            slot="trigger"
            slot-scope="props"
            role="button"
            class="is-flex align-center justify-between has-cursor-pointer"
          >
            <h4 class="subtitle is-5 is-marginless">
              API
            </h4>
            <b-icon class="open-indicator" :icon="props.open ? 'angle-down' : 'angle-right'" />
          </div>
          <validated-text-input
            v-model="storeDeliveryServiceForm.apiConfiguration"
            class="mar-t"
            :has-icon="false"
            label="Configuration"
            label-position="on-border"
            name="deliveryServiceConfig"
            type="textarea"
            rows="10"
            :spellcheck="false"
            maxlength="8000"
            rules="validJSON"
          />
        </b-collapse>
      </div>

      <template #footer>
        <div class="buttons all-bold">
          <b-button rounded @click="$_confirmCloseModal({ programmatic: true })">
            Close
          </b-button>
          <b-button
            v-tabbable
            rounded
            :loading="isLoading"
            native-type="submit"
            type="is-primary"
            :class="['is-bold', dirty && 'pulse-slow']"
            @click="validateHours"
          >
            Save
          </b-button>
        </div>
      </template>
    </modal-card>
  </validated-form>
</template>

<script>
  import { capitalCase } from 'change-case';
  import beautify from 'json-beautify';
  import moment from 'moment-timezone';
  import { mapGetters } from 'vuex';

  import confirmModalCloseMixin from '@/mixins/confirm-modal-close';

  import DeliveryFeeType from '@/store/classes/DeliveryFeeType';
  import DeliveryServiceChargeType from '@/store/classes/DeliveryServiceChargeType';
  import StoreDeliveryService from '@/store/classes/StoreDeliveryService';
  import StoreDeliveryServiceSchedule from '@/store/classes/StoreDeliveryServiceSchedule';


  export default {
    name: 'StoreDeliveryServiceSettingsModal',


    mixins: [confirmModalCloseMixin],

    props: {
      storeDeliveryService: {
        type: Object,
        required: true
      }
    },

    data: () => ({
      capitalCase,
      // Move this to a constants file if we end up using more delivery service ids like this
      IN_HOUSE_DELIVERY_SERVICE_ID: 8,
      isLoading: false,
      overlapErrorMessage: 'Start & end hours cannot overlap each other within the same day',
      storeDeliveryServiceForm: {},
      hoursForm: {
        sun: [], mon: [], tue: [], wed: [], thu: [], fri: [], sat: []
      },
      daysConfig: {
        sun: { label: 'Sunday' },
        mon: { label: 'Monday' },
        tue: { label: 'Tuesday' },
        wed: { label: 'Wednesday' },
        thu: { label: 'Thursday' },
        fri: { label: 'Friday' },
        sat: { label: 'Saturday' }
      },
      invalidDays: []
    }),

    computed: {
      ...mapGetters('session', ['isCardfreeAdmin']),

      estimatedDeliveryTime: {
        get() {
          const duration = moment.duration(this.storeDeliveryServiceForm.estimatedDeliveryTime);
          return duration.asMinutes();
        },
        set(value) {
          const timeString = moment.utc().startOf('day').add(value, 'minutes').format('HH:mm:ss');
          this.storeDeliveryServiceForm.estimatedDeliveryTime = timeString;
        }
      },

      deliveryFeeTypes() {
        return DeliveryFeeType.all();
      },

      isDeliveryFeePercentType() {
        return this.storeDeliveryServiceForm.feeTypeId === 3;
      },

      isDeliveryFeeAmountType() {
        return [2, 4].includes(this.storeDeliveryServiceForm.feeTypeId);
      },

      feeAmount: {
        get() {
          if (this.isDeliveryFeePercentType) {
            return parseFloat((this.storeDeliveryServiceForm.feeAmount * 100).toPrecision(4));
          }
          else if (this.isDeliveryFeeAmountType) {
            return this.storeDeliveryServiceForm.feeAmount;
          }
          else {
            return null;
          }
        },
        set(value) {
          let newAmount;
          if (this.isDeliveryFeePercentType) {
            newAmount = parseFloat((value / 100).toPrecision(4));
          }
          else if (this.isDeliveryFeeAmountType) {
            newAmount = value;
          }
          else {
            newAmount = null;
          }
          this.storeDeliveryServiceForm.feeAmount = newAmount;
        }
      },

      deliveryServiceChargeTypes() {
        return DeliveryServiceChargeType.all();
      },

      isDeliveryServiceChargePercentType() {
        return this.storeDeliveryServiceForm.deliveryServiceChargeTypeId === 1;
      },

      isDeliveryServiceChargeAmountType() {
        return this.storeDeliveryServiceForm.deliveryServiceChargeTypeId === 2;
      },

      deliveryServiceChargeAmount: {
        get() {
          if (this.isDeliveryServiceChargePercentType) {
            return parseFloat((this.storeDeliveryServiceForm.deliveryServiceChargeAmount * 100).toPrecision(4));
          }
          else if (this.isDeliveryServiceChargeAmountType) {
            return this.storeDeliveryServiceForm.deliveryServiceChargeAmount;
          }
          else {
            return null;
          }
        },
        set(value) {
          let newAmount;
          if (this.isDeliveryServiceChargePercentType) {
            newAmount = parseFloat((value / 100).toPrecision(4));
          }
          else if (this.isDeliveryServiceChargeAmountType) {
            newAmount = value;
          }
          else {
            newAmount = null;
          }
          this.storeDeliveryServiceForm.deliveryServiceChargeAmount = newAmount;
        }
      },

      deliveryUpcharge: {
        get() {
          return this.storeDeliveryServiceForm.deliveryUpcharge
            ? parseFloat((this.storeDeliveryServiceForm.deliveryUpcharge * 100).toPrecision(4))
            : null;
        },
        set(value) {
          this.storeDeliveryServiceForm.deliveryUpcharge = value
            ? parseFloat((value / 100).toPrecision(4))
            : null;
        }
      },

      apiConfiguration: {
        get() {
          return this.storeDeliveryServiceForm.apiConfiguration
            ? beautify(this.storeDeliveryServiceForm.apiConfiguration, null, 2, 0)
            : '{}';
        },
        set(value) {
          this.storeDeliveryServiceForm.apiConfiguration = beautify(JSON.parse(value), null, 0);
        }
      }
    },

    created() {
      this.onCreated();
    },

    methods: {
      async onCreated() {
        this.storeDeliveryServiceForm = this.$clone(this.storeDeliveryService);
        this.setHours();
        await Promise.all([
          this.fetchDeliveryFeeTypes(),
          this.fetchDeliveryServiceChargeTypes()
        ]);
      },

      setHours() {
        const days = ['sun', 'mon', 'tue', 'wed', 'thu', 'fri', 'sat'];
        const newHours = (existing, id, start, end) => [
          ...existing,
          { id, start, end }
        ].sort((a, b) => (a.start < b.start ? -1 : 1));

        this.hoursForm = this.storeDeliveryService.storeDeliveryServiceSchedules.reduce((obj, schedule) => {
          days.forEach((day) => {
            const startTimeString = schedule[`${day}Start`];
            const endTimeString = schedule[`${day}End`];

            if (startTimeString) {
              const startTime = moment(startTimeString, 'HH:mm').toDate();
              const endTime = moment(endTimeString, 'HH:mm').toDate();
              obj[day] = newHours(obj[day], schedule.id, startTime, endTime);
            }
          });

          return obj;
        }, { ...this.hoursForm }); /* eslint-disable-line */
      },

      async fetchDeliveryFeeTypes() {
        try {
          await DeliveryFeeType.fetchDeliveryFeeTypes();
        }
        catch (error) {
          this.$_onRequestError({
            toastOptions: { message: 'There was an error fetching the delivery fee types' },
            error
          });
        }
      },

      async fetchDeliveryServiceChargeTypes() {
        try {
          await DeliveryServiceChargeType.fetchDeliveryServiceChargeTypes();
        }
        catch (error) {
          this.$_onRequestError({
            toastOptions: { message: 'There was an error fetching the delivery service charge types' },
            error
          });
        }
      },

      updateHours({ value, day, index, type }) {
        this.hoursForm[day][index][type] = value;
      },

      addHoursRow(day) {
        this.hoursForm[day].push({ start: null, end: null, id: `temp-${Date.now()}` });
      },

      handleDelete(day, index) {
        this.hoursForm[day].splice(index, 1);
      },

      validateHours() {
        let allHoursValid = true;
        this.invalidDays = [];

        Object.entries(this.$clone(this.hoursForm)).forEach(([day, dayHours]) => {
          dayHours
            .sort((a, b) => (a.start < b.start ? -1 : 1))
            .forEach((hours, index) => {
              const currentEnd = hours.end;
              const nextStart = dayHours[index + 1]?.start;

              // verify that the end time for the current hours range
              // is before the start time for the next hours range
              if (nextStart && currentEnd >= nextStart) {
                allHoursValid = false;
                this.invalidDays.push(day);
              }
            });
        });
        return allHoursValid;
      },

      formatHoursPayload() {
        const scheduleIds = this.storeDeliveryService.storeDeliveryServiceSchedules.map(schedule => schedule.id);
        const schedules = { updated: [], added: [], deletedIds: [] };

        // get the number of schedules we have total
        let scheduleCount = Object.values(this.$clone(this.hoursForm)).sort((a, b) => (a.length < b.length ? 1 : -1))[0].length;
        // build arrays of new and updated schedules
        while (scheduleCount > 0) {
          const index = scheduleCount - 1;
          const schedule = { id: scheduleIds[index] };

          Object.entries(this.$clone(this.hoursForm)).forEach(([day, hours]) => {
            schedule[`${day}Start`] = hours[index]?.start ? moment(hours[index].start).format('HH:mm') : null;

            schedule[`${day}End`] = hours[index]?.end ? moment(hours[index].end).format('HH:mm') : null;
          });

          scheduleCount -= 1;

          if (schedule.id) {
            schedules.updated.push(schedule);
          }
          else {
            schedules.added.push(schedule);
          }
        }

        // check to see if there are any existing schedule IDs that no longer have hours associated with them
        schedules.deletedIds = scheduleIds.filter(id => !schedules.updated.find(schedule => schedule.id === id));

        return schedules;
      },

      async handleSubmit() {
        try {
          this.isLoading = true;

          let storeDeliveryServiceId = this.storeDeliveryService.id;

          if (!this.storeDeliveryService.isSaved) {
            const storeDeliveryService = await StoreDeliveryService.addStoreDeliveryService(this.storeDeliveryServiceForm);
            storeDeliveryServiceId = storeDeliveryService.id;
          }
          else {
            await StoreDeliveryService.updateStoreDeliveryService(this.storeDeliveryServiceForm);
          }

          const { added, updated, deletedIds } = this.formatHoursPayload();

          await Promise.all([
            ...added.map(schedule => StoreDeliveryServiceSchedule.addSchedule({ storeDeliveryServiceId, storeDeliveryServiceSchedule: schedule })),
            ...updated.map(schedule => StoreDeliveryServiceSchedule.updateSchedule(schedule)),
            ...deletedIds.map(id => StoreDeliveryServiceSchedule.deleteSchedule(id))
          ]);

          this.$parent.close();
        }
        catch (error) {
          this.$_onRequestError({
            error,
            toastOptions: {
              message: 'There was an error saving your delivery settings'
            }
          });
        }
        finally {
          this.isLoading = false;
        }
      }
    }
  };
</script>

<style lang='sass' scoped>
  .hours-container
    border: 1px solid $grey-lighter
    border-radius: $radius
    overflow: hidden

  .day-container
    display: grid
    grid-template-columns: 7rem 350px auto
    gap: $size-normal
    transition: 333ms

    &:not(:last-child)
      border-bottom: 1px solid $grey-lighter

    > *
      padding-top: $size-small
      padding-bottom: $size-small
      &:first-child
        padding-left: $size-small
      &:last-child
        padding-right: $size-small

  .day
    position: relative
    display: flex
    flex-direction: column
    justify-content: flex-start
    background-color: $white-bis
    border-right: 1px solid $grey-lighter

  .day-hours
    transition: 333ms

  .day-actions
    position: absolute
    top: 42px
    right: 0
    transform: translateY(-40%)

  .hours
    display: grid
    grid-template-columns: repeat(2, minmax(10rem, min-content)) min-content
    align-items: start

    ::v-deep
      .label,
      .field
        margin: 0 !important
</style>
