<template>
  <footer class="footer">
    <div class="section">
      <div class="container">
        <div class="footer-section">
          <p>© {{ new Date().getFullYear() }} CardFree - All rights reserved</p>
          <a class="link" target="_blank" href="https://cardfree.com/cfwip/privacy-policy/">
            Privacy Policy
          </a>
          <a class="link" target="_blank" href="/portal-terms-and-conditions.pdf">
            Terms & Conditions
          </a>
        </div>
        <div class="footer-section">
          <span>Need Help?</span>
          <span class="contact-link">
            <b-icon size="is-small" class="mar-r-xs" icon="envelope" />
            <a class="link" href="mailto:support@cardfree.com">support@cardfree.com</a>
          </span>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
  export default {
    name: 'Footer'
  };
</script>

<style lang="sass" scoped>
  .footer
    border-top: 1px solid $grey-lightest
    background-color: #f5f5f5
    background-image: url("/images/stripe-background.png")
    background-size: 2200px
    color: $grey
    font-size: 14px
    position: relative

    &::after
      pointer-events: none
      content: ''
      position: absolute
      top: 0
      bottom: 0
      left: 0
      right: 0
      box-shadow: inset 0px 3px 5px rgba(0, 0, 0, 0.05)

    .section
      padding: 2rem 1.5rem

    .container
      display: flex
      justify-content: space-between
      gap: 1rem

  .footer-section
    display: flex
    flex-wrap: wrap
    justify-content: center
    gap: 0.15rem 2rem

  .link
    transition: color 100ms
    color: $grey
    &:hover
      color: $black

  @media (max-width: $widescreen)
    .container
      flex-direction: column-reverse
      justify-content: center
      align-items: center

</style>
