<template>
  <div v-if="$_selectedMerchant">
    <div class="is-grid col-2 col-1-tablet gap-xl">
      <fieldset>
        <h4 class="title is-4">Header</h4>
        <div
          class="header-preview is-flex align-center justify-center pad mar-b-lg is-size-3 has-text-weight-bold"
          :style="{
            backgroundColor: value.headerBackgroundColor,
            color: value.headerDarkBackground ? 'white' : 'black'
          }"
        >
          Preview
        </div>

        <image-upload
          v-slot="{imagePath}"
          :accepted-types="['png', 'svg']"
          :image-size-warning-height="104"
          :image-size-warning-width="452"
          :image-url="$_selectedMerchant.merchantOption && $_selectedMerchant.merchantOption.logoUrl"
          label="Merchant Logo"
          :value="value.logoImageFile"
          show-clear-button
          restrict-file-size
          @input="handleInput('logoImageFile', $event)"
        >
          <div :style="{backgroundColor: value.headerBackgroundColor, padding: '0.5rem'}">
            <img
              v-if="imagePath"
              style="max-width: 400px"
              :src="imagePath"
              alt="merchant logo"
            >
          </div>
        </image-upload>

        <div class="is-flex dist-x-xl">

          <validation-provider
            v-slot="{ errors, classes }"
            slim
            name="headerBackgroundColor"
            rules="required|validHexColor"
          >
            <b-field
              label-for="headerBackgroundColor"
              :message="errors"
              :type="{ 'is-danger': !!errors.length }"
              label="Background Color"
              :class="['mar-none', classes]"
            >
              <div class="color-picker">
                <swatches
                  :value="value.headerBackgroundColor"
                  show-fallback
                  fallback-input-type="color"
                  swatches="text-advanced"
                  @input="handleInput('headerBackgroundColor', $event)"
                >
                  <template #trigger>
                    <div class="swatch" :style="{ backgroundColor: value.headerBackgroundColor }" />
                  </template>
                </swatches>
                <b-input
                  id="headerBackgroundColor"
                  :value="value.headerBackgroundColor"
                  maxlength="7"
                  class="hex-input"
                  :has-counter="false"
                  type="text"
                  @input="handleInput('headerBackgroundColor', $event)"
                />
              </div>
            </b-field>
          </validation-provider>

          <validation-provider v-slot="{ classes }" slim>
            <b-field :class="classes" label-for="headerDarkBackground" label="Text Color">
              <toggle-buttons
                :value="value.headerDarkBackground"
                label-right="White"
                :value-right="true"
                label-left="Black"
                :value-left="false"
                @input="handleInput('headerDarkBackground', $event)"
              />
            </b-field>
          </validation-provider>
        </div>
      </fieldset>

      <fieldset>
        <h4 class="title is-4">App Colors</h4>
        <b-field>
          <b-message class="is-inline-block has-shadow is-compact" size="is-small" type="is-primary">
            Applies to various text, buttons, icons, and other UI elements
          </b-message>
        </b-field>
        <validation-provider
          v-slot="{ errors, classes }"
          slim
          name="primaryColor"
          rules="notWhite|validHexColor|required"
        >
          <b-field
            label="Primary Color"
            label-for="primaryColor"
            :message="!!errors.length ? errors : getContrastWarning(value.primaryColor)"
            :type="{ 'is-danger': !!errors.length }"
            :class="classes"
          >
            <div class="color-picker">
              <swatches
                :value="value.primaryColor"
                show-fallback
                fallback-input-type="color"
                swatches="text-advanced"
                @input="handleInput('primaryColor', $event)"
              >
                <template #trigger>
                  <div class="swatch" :style="{ backgroundColor: value.primaryColor }" />
                </template>
              </swatches>
              <b-input
                id="primaryColor"
                :value="value.primaryColor"
                maxlength="7"
                class="hex-input"
                :has-counter="false"
                type="text"
                @input="handleInput('primaryColor', $event)"
              />
            </div>
          </b-field>
        </validation-provider>

        <validation-provider
          v-slot="{ errors, classes }"
          slim
          name="secondaryColor"
          rules="notWhite|validHexColor|required"
        >
          <b-field
            label="Secondary Color"
            label-for="secondaryColor"
            :message="!!errors.length ? errors : getContrastWarning(value.secondaryColor)"
            :type="{ 'is-danger': !!errors.length }"
            :class="classes"
          >
            <div class="color-picker">
              <swatches
                :value="value.secondaryColor"
                show-fallback
                fallback-input-type="color"
                swatches="text-advanced"
                @input="handleInput('secondaryColor', $event)"
              >
                <template #trigger>
                  <div class="swatch" :style="{ backgroundColor: value.secondaryColor }" />
                </template>
              </swatches>
              <b-input
                id="secondaryColor"
                :value="value.secondaryColor"
                maxlength="7"
                class="hex-input"
                :has-counter="false"
                type="text"
                @input="handleInput('secondaryColor', $event)"
              />
            </div>
          </b-field>
        </validation-provider>

        <validation-provider
          v-if="$_selectedMerchant.kioskEnabled"
          v-slot="{ errors, classes }"
          slim
          name="tertiaryColor"
          rules="notWhite|validHexColor|required"
        >
          <b-field
            label="Tertiary Color"
            label-for="tertiaryColor"
            :message="!!errors.length ? errors : getContrastWarning(value.tertiaryColor)"
            :type="{ 'is-danger': !!errors.length }"
            :class="classes"
          >
            <div class="color-picker">
              <swatches
                :value="value.tertiaryColor"
                show-fallback
                fallback-input-type="color"
                swatches="text-advanced"
                @input="handleInput('tertiaryColor', $event)"
              >
                <template #trigger>
                  <div class="swatch" :style="{ backgroundColor: value.tertiaryColor }" />
                </template>
              </swatches>
              <b-input
                id="tertiaryColor"
                :value="value.tertiaryColor"
                maxlength="7"
                class="hex-input"
                :has-counter="false"
                type="text"
                @input="handleInput('tertiaryColor', $event)"
              />
            </div>
          </b-field>
        </validation-provider>
      </fieldset>
    </div>
  </div>
</template>

<script>
  import merchantMixin from '@/mixins/merchant';
  import Swatches from 'vue-swatches';
  import chroma from 'chroma-js';

  export default {

    name: 'WebAppConfigurationInputs',

    components: {
      Swatches
    },

    mixins: [merchantMixin],

    props: {
      value: {
        type: Object,
        required: true
      }
    },

    data() {
      return {
        imagePreviewPath: null,
        showImageSizeWarning: false
      };
    },

    methods: {
      getContrastWarning(hexValue) {
        if (chroma.valid(hexValue) && chroma.contrast('#fff', hexValue) < 2) {
          return `The selected color has a contrast ratio of ${chroma.contrast('#fff', hexValue).toFixed(1)} against white, which could cause some text to be difficult to read. We recommend a darker color with at least a 2.0 contrast ratio.`;
        }
        return null;
      },

      handleInput(key, value) {
        this.$emit('input', { ...this.value, [key]: value });
      }
    }
  };
</script>

<style lang="sass" scoped>
  .header-preview
    width: 100%
    border-radius: $radius-large
    border: 1px solid #eee

  .color-picker
    display: flex

    .hex-input
      min-width: 7rem

    .swatch
      width: 40px
      height: 40px
      margin-right: 0.75rem
      border: 1px solid #eee
      border-radius: 4px

      &:hover
        cursor: pointer
        opacity: 0.7
</style>
