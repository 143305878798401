<template>
  <transition name="fade-down">
    <validated-form
      v-if="form"
      v-slot="{ dirty }"
      form-id="orderModeForm"
      class="dist-y-lg"
      :disabled="!canEdit('leadPickupTime')"
      @valid-submit="handleSubmit"
    >
      <panel title="Lead Times" collapsible start-open :loading="isFetching">
        <template #buttons>
          <b-button
            native-type="submit"
            :loading="isSubmitting"
            type="is-primary"
            rounded
            size="is-medium"
            :class="['is-bold', dirty && 'pulse-slow']"
          >
            Save
          </b-button>
        </template>

        <div class="is-grid col-2 col-1-tablet gap-lg">
          <template v-if="supportsLeadPickupTime">
            <validated-input
              rules="required"
              name="leadPickupTime"
              label="Pickup Lead Time"
              sub-label="Amount of time orders take to be prepared for pickup"
            >
              <b-select
                v-model="form.leadPickupTime"
                expanded
                placeholder="Select a pickup lead time..."
              >
                <option v-for="{ display, value } in timeOffsets" :key="value" :value="value">
                  + {{ display }}
                </option>
                <option disabled>──────</option>
                <option value="custom">Custom</option>
              </b-select>
              <transition name="fade-right">
                <validated-text-input
                  v-if="isCustomTime(form.leadPickupTime)"
                  v-model="customInputs.leadPickupTime"
                  type="time"
                  :class="['control', { 'mar-l-xs': !canEdit('leadPickupTime') }]"
                  name="leadPickupTime"
                  label="Custom Lead Pickup Time"
                  placeholder="hh:mm:ss"
                  hide-label
                  status-icon
                  rules="required"
                />
              </transition>
            </validated-input>
          </template>

          <template v-if="store.roomServiceEnabled">
            <validated-input
              rules="required"
              name="roomServiceLeadTime"
              label="Room Service Lead Time"
              sub-label="Amount of time orders take to be prepared for room service"
            >
              <b-select
                v-model="form.roomServiceLeadTime"
                expanded
                placeholder="Select a room service lead time..."
              >
                <option v-for="{ display, value } in timeOffsets" :key="value" :value="value">
                  + {{ display }}
                </option>
                <option disabled>──────</option>
                <option value="custom">Custom</option>
              </b-select>
              <transition name="fade-right">
                <validated-text-input
                  v-if="isCustomTime(form.roomServiceLeadTime)"
                  v-model="customInputs.roomServiceLeadTime"
                  type="time"
                  :class="['control', { 'mar-l-xs': !canEdit('roomServiceLeadTime') }]"
                  name="roomServiceLeadTime"
                  label="Room Service Lead Time"
                  placeholder="hh:mm:ss"
                  hide-label
                  status-icon
                  rules="required"
                />
              </transition>
            </validated-input>
          </template>

          <template v-if="store.cateringEnabled">
            <validated-input
              rules="required"
              name="cateringLeadTime"
              label="Catering Lead Time"
              sub-label="Amount of time orders take to be prepared for catering"
            >
              <b-select
                v-model="form.cateringLeadTime"
                expanded
                placeholder="Select a catering lead time..."
              >
                <option v-for="{ display, value } in timeOffsets" :key="value" :value="value">
                  + {{ display }}
                </option>
                <option disabled>──────</option>
                <option value="custom">Custom</option>
              </b-select>
              <transition name="fade-right">
                <validated-text-input
                  v-if="isCustomTime(form.cateringLeadTime)"
                  v-model="customInputs.cateringLeadTime"
                  type="time"
                  :class="['control', { 'mar-l-xs': !canEdit('cateringLeadTime') }]"
                  name="cateringLeadTime"
                  label="Custom Catering Lead Time"
                  placeholder="hh:mm:ss"
                  hide-label
                  status-icon
                  rules="required"
                />
              </transition>
            </validated-input>
          </template>
        </div>

      </panel>
    </validated-form>
  </transition>
</template>

<script>
  import Store from '@/store/classes/Store';

  export default {
    name: 'StoreLeadTimes',

    props: {
      store: {
        type: Object,
        required: true
      }
    },

    data() {
      return {
        form: {},
        customInputs: {
          leadPickupTime: null,
          roomServiceLeadTime: null,
          cateringLeadTime: null
        },
        timeOffsets: [
          { display: '0 minutes', value: '00:00:00' },
          { display: '5 minutes', value: '00:05:00' },
          { display: '10 minutes', value: '00:10:00' },
          { display: '15 minutes', value: '00:15:00' },
          { display: '20 minutes', value: '00:20:00' },
          { display: '25 minutes', value: '00:25:00' },
          { display: '30 minutes', value: '00:30:00' }
        ],
        isSubmitting: false
      };
    },

    computed: {
      supportsLeadPickupTime() {
        return this.store.posType?.metaData['StoreMapping.supportsLeadPickupTime'];
      },

      isFetching() {
        return Store.$state().fetchingAll;
      }
    },

    created() {
      this.onCreated();
    },

    methods: {
      onCreated() {
        this.form = this.$clone(this.store);
        this.setCustomInputs();
      },

      toggleLoading(value) {
        this.isSubmitting = value;
      },

      canEdit(field) {
        return this.$can('update', 'StoreMapping', field);
      },

      isCustomTime(value) {
        return !!value && !this.timeOffsets.find(timeOffset => timeOffset.value === value);
      },

      setCustomInputs() {
        if (this.isCustomTime(this.store.leadPickupTime)) {
          this.form.leadPickupTime = 'custom';
          this.customInputs.leadPickupTime = this.store.leadPickupTime;
        }
        if (this.isCustomTime(this.store.roomServiceLeadTime)) {
          this.form.roomServiceLeadTime = 'custom';
          this.customInputs.roomServiceLeadTime = this.store.roomServiceLeadTime;
        }
        if (this.isCustomTime(this.store.cateringLeadTime)) {
          this.form.cateringLeadTime = 'custom';
          this.customInputs.cateringLeadTime = this.store.cateringLeadTime;
        }
      },

      handleCustomInputsForRequest() {
        if (this.form.leadPickupTime === 'custom') {
          this.form.leadPickupTime = this.customInputs.leadPickupTime;
        }
        if (this.form.roomServiceLeadTime === 'custom') {
          this.form.roomServiceLeadTime = this.customInputs.roomServiceLeadTime;
        }
        if (this.form.cateringLeadTime === 'custom') {
          this.form.cateringLeadTime = this.customInputs.cateringLeadTime;
        }
      },

      async handleSubmit() {
        try {
          this.toggleLoading(true);

          this.handleCustomInputsForRequest();
          await Store.updateStore(this.form);
          this.setCustomInputs();

          this.$_onRequestSuccess({
            toastOptions: {
              message: 'Lead times successfully updated!'
            }
          });
        }
        catch (error) {
          this.$_onRequestError({
            toastOptions: {
              message: 'There was an issue updating your lead times'
            },
            error
          });
        }

        finally {
          this.toggleLoading(false);
        }
      }
    }
  };
</script>
