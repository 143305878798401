
const tippingOptionMethodTypes = {
  DEFAULT: 0,
  BUTTON: 1,
  SLIDER: 2,
  DOLLAR_AMOUNT: 3
};

const tippingOptionTypes = {
  ALL_APPLICATONS: 0,
  ORDER_AHEAD: 1,
  PAY_AT_TABLE: 2,
  TEXT_TO_PAY: 3,
  ORDER_AT_TABLE: 4,
  KIOSK: 5,
  EXTERNAL_DEVICE: 6,
  TIP_ONLY: 7
};

const tippingTypes = {
  MERCHANT: 1,
  MOBILE: 2
};

export {
  tippingOptionMethodTypes,
  tippingOptionTypes,
  tippingTypes
};
