<template>
  <div>
    <review-row
      v-for="(row, idx) in detailsRows"
      :key="`/review-row-${idx}`"
      :title="row.title"
      :subtitle="row.subtitle"
      :hide-bottom-border="idx === detailsRows.length - 1"
    />
  </div>
</template>

<script>
  import Currency from '@/store/classes/Currency';

  export default {
    name: 'DetailsCard',

    props: {
      value: {
        type: Object,
        required: true
      }
    },

    computed: {
      detailsRows() {
        return [
          { title: 'Name', subtitle: this.value.title },
          { title: 'Description', subtitle: this.value.description || 'N/A' },
          { title: 'Terms & Conditions', subtitle: this.value.longDescription || 'N/A' },
          { title: 'Processing Type', subtitle: this.value.challengeProcessingType === Currency.challengeProcessingTypes.AUTOMATIC_AWARD_CHALLENGE ? 'Automatic' : 'Scheduled' }
        ];
      }
    }
  };
</script>
