<template>
  <div class="empty-state-card-container">
    <b-message :type="type" class="box is-paddingless" :style="{'max-width': maxWidth}">
      <div class="empty-state-card">
        <img
          v-if="imagePath"
          :src="imagePath"
          class="empty-state-card-image"
          alt="banner icon"
          :width="imageWidth"
          :height="imageHeight"
        >
        <b-icon
          v-else-if="icon"
          class="empty-state-card-icon"
          :pack="iconPack"
          :icon="icon"
          :custom-size="iconSize"
        />

        <div class="empty-state-card-content">
          <slot name="title">
            <h3 class="title is-4 mar-b-xs">
              {{ title }}
            </h3>
          </slot>

          <div v-if="message || $slots.message" :class="$slots.buttons && 'mar-b'">
            <slot name="message">
              <p class="is-size-6">{{ message }}</p>
            </slot>
          </div>

          <slot name="buttons" />
        </div>
      </div>
    </b-message>
  </div>
</template>

<script>
  export default {
    name: 'EmptyStateCard',

    props: {
      type: {
        type: String,
        default: ''
      },

      imagePath: {
        type: String,
        default: ''
      },

      imageWidth: {
        type: Number,
        default: 64
      },

      imageHeight: {
        type: Number,
        default: 64
      },

      icon: {
        type: String,
        default: ''
      },

      iconPack: {
        type: String,
        default: 'fa'
      },

      iconSize: {
        type: String,
        default: 'fa-5x'
      },

      title: {
        type: String,
        default: 'Sample Title'
      },

      message: {
        type: String,
        default: 'Sample message text'
      },

      maxWidth: {
        type: String,
        default: '666px'
      }
    }
  };
</script>

<style lang="sass" scoped>
  .empty-state-card-container
    display: flex
    justify-content: center

    ::v-deep.media-content
      overflow-x: unset

  .empty-state-card
    display: inline-flex
    max-width: $tablet
    padding: 0

  .empty-state-card-content
    margin-left: 1.5rem

  .empty-state-card-image
    align-self: center

  .empty-state-card-icon
    width: unset
    height: unset

  @media (max-width: $tablet)
    .empty-state-card
      flex-direction: column
      text-align: center

      .buttons
        justify-content: center

    .empty-state-card-content
      margin-top: 1.5rem
      margin-left: 0
</style>
