<template>
  <table class="is-full-width">
    <tbody>
      <tr v-for="transaction in filteredTransactions" :key="transaction.paymentTransactionId">
        <td class="pad-t-xs">
          {{ transaction.paymentTransaction.transactionDateTime | moment('M/D/YY @ h:mm A', timeZone) }}
        </td>
        <td class="pad-t-xs">
          {{ capitalCase(transaction.paymentTransaction.paymentTransactionType) }}
        </td>
        <td :class="['has-text-weight-semibold has-text-right pad-t-xs', transaction.paymentTransaction.paymentTransactionType === 'Refund' ? 'has-text-danger' : 'has-text-dark']">
          {{ transaction.paymentTransaction.paymentTransactionType === 'Refund' ? `-${transaction.paymentTransaction.amount}` : transaction.paymentTransaction.amount | dollars }}
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script>
  import { capitalCase } from 'change-case';



  export default {
    name: 'PaymentTransactionList',

    props: {
      payment: {
        type: Object,
        required: true
      },
      timeZone: {
        type: String,
        required: true
      }
    },

    data() {
      return {
        capitalCase
      };
    },

    computed: {
      filteredTransactions() {
        return this.payment.transactions.filter(this.showTransaction);
      }
    },

    methods: {
      showTransaction(transaction) {
        const isSuccessful = transaction.paymentTransaction?.paymentTransactionStatus === 'Succeeded';
        const isValidType = [
          'Authorize',
          'Capture',
          'Refund',
          'Sale',
          'TipAdjustment',
          'Void'
        ].includes(transaction.paymentTransaction?.paymentTransactionType);

        return isSuccessful && isValidType;
      }
    }
  };
</script>
