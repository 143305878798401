import Merchant from '@/store/classes/Merchant';
import posTypes from '@/constants/posTypes';


const merchant = {
  computed: {
    $_selectedMerchantId() {
      return Merchant.$state().selectedMerchantId;
    },

    $_selectedMerchant() {
      return Merchant.query().with('*').find(this.$_selectedMerchantId);
    },

    $_menuPermissions() {
      const isCardfreePOS = this.$_hasPosType(posTypes.Cardfree);
      const isConnectedPOS = !this.$_hasPosType(posTypes.Cardfree);
      const isPermittedForAllPosTypes = isCardfreePOS || isConnectedPOS;
      const hasConnectedPosInventory = this.$_selectedMerchant?.features.connectedPosInventoryManagement;
      const hasMenuPricing = this.$_selectedMerchant?.features.menuPrices;
      const hasUnstructuredMenu = this.$_selectedMerchant?.hasUnstructuredMenu;

      const permissions = {
        ADD_RESOURCE: hasUnstructuredMenu || isCardfreePOS,
        EDIT_RESOURCE: isPermittedForAllPosTypes,
        REMOVE_RESOURCE: isPermittedForAllPosTypes,
        SORT_RESOURCE: isPermittedForAllPosTypes,
        CLONE_ITEM: isPermittedForAllPosTypes,
        MANAGE_DAYPART_SCHEDULE: isPermittedForAllPosTypes,
        SET_DEFAULT_MODIFIER: isPermittedForAllPosTypes,
        UPDATE_PRICE_TAX: isPermittedForAllPosTypes,
        READ_PRICE_TAX: hasMenuPricing && isPermittedForAllPosTypes,
        UPDATE_INVENTORY: hasConnectedPosInventory || isCardfreePOS,
        UPDATE_VISIBILITY: isPermittedForAllPosTypes,
        MANAGE_MENU_TYPE: isPermittedForAllPosTypes
      };

      if (this.$can('manage', 'all')) {
        Object.keys(permissions).forEach((key) => {
          permissions[key] = true;
        });
      }

      return permissions;
    }
  },

  methods: {
    p_merchantLoaded() {
      if (this.$_selectedMerchant && this.onMerchantLoad) {
        this.onMerchantLoad(this.$_selectedMerchant);
      }
    },

    $_hasPosType(typeId) {
      return this.$_selectedMerchant?.posConfigurations
        .some(posConfig => posConfig.posType.id === typeId);
    }
  },

  watch: {
    '$_selectedMerchant.id': {
      handler: 'p_merchantLoaded',
      immediate: true
    }
  }
};



export default merchant;
