var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('validation-provider',{attrs:{"slim":"","bails":_vm.bails,"name":_vm.label,"vid":_vm.name,"rules":_vm.rules,"custom-messages":_vm.customMessages},scopedSlots:_vm._u([{key:"default",fn:function(ref){
  var classes = ref.classes;
  var dirty = ref.dirty;
  var errors = ref.errors;
  var required = ref.required;
return [_c('b-field',{class:Object.assign({}, classes, {'show-edited': _vm.showEdited}),attrs:{"expanded":_vm.expanded,"horizontal":_vm.horizontal,"autocomplete":_vm.autocompleteSetting,"label":_vm.hideLabel ? '' : _vm.label,"label-position":_vm.labelPosition,"label-for":_vm.name,"type":{ 'is-danger': !!errors.concat( _vm.backendValidationErrors).length },"message":_vm._f("capitalize")(_vm.getMessage(errors))},scopedSlots:_vm._u([(!_vm.hideLabel)?{key:"label",fn:function(){return [_c('div',{class:_vm.subLabelOnSide && 'sub-label-side'},[_c('div',{staticClass:"is-relative dist-x-xs"},[(_vm.showEdited && dirty)?_c('b-icon',{directives:[{name:"tippy",rawName:"v-tippy",value:({
              content: 'This field has been modified',
              maxWidth: 300,
              delay: [500, 0],
              placement: 'top-start'
            }),expression:"{\n              content: 'This field has been modified',\n              maxWidth: 300,\n              delay: [500, 0],\n              placement: 'top-start'\n            }"}],staticClass:"edited-indicator",attrs:{"icon":"circle"}}):_vm._e(),_c('span',{class:{ 'required-indicator': required && !_vm.hideRequiredIndicator }},[_vm._v(_vm._s(_vm.label))]),(_vm.tooltip)?_c('b-icon',{directives:[{name:"tippy",rawName:"v-tippy",value:({
              content: _vm.tooltip,
              placement: _vm.tooltipPlacement,
              maxWidth: 300,
              delay: [150, 0]
            }),expression:"{\n              content: tooltip,\n              placement: tooltipPlacement,\n              maxWidth: 300,\n              delay: [150, 0]\n            }"}],attrs:{"icon":"info-square","size":"is-small","type":"is-grey"}}):_vm._e(),(!!errors.concat( _vm.backendValidationErrors).length)?_c('b-icon',{attrs:{"icon":"exclamation-circle","size":"is-small","type":"is-danger"}}):_vm._e()],1),_vm._t("subLabel",[(_vm.subLabel)?_c('p',{staticClass:"sub-label is-size-7 has-text-weight-normal has-text-grey",domProps:{"innerHTML":_vm._s(_vm.subLabel)}}):_vm._e()])],2)]},proxy:true}:null],null,true)},[_vm._t("default")],2)]}}],null,true)})}
var staticRenderFns = []

export { render, staticRenderFns }