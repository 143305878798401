<template>
  <div>
    <div class="card">
      <div class="table-top is-flex-wrap justify-between border-bottom has-border-grey-lightest" style="padding: 10px">
        <div class="mar-xs">
          <b-field
            v-if="hasStoreSpecificPosMenu"
            label-position="on-border"
            label="Location"
            class="is-marginless"
          >
            <b-select v-model="selectedStoreId">
              <option
                v-for="store in importedPosStores"
                :key="store.storeId"
                :value="store.storeId"
              >
                {{ store.description }}
              </option>
            </b-select>
          </b-field>
        </div>
        <div class="is-flex dist-x mar-xs">
          <div v-tippy="{ content: 'Collapse All' }">
            <b-button
              :disabled="!isAnyMenuSectionOpen"
              @click="isOpen = {}"
            >
              <b-icon icon="compress-alt" size="is-medium" />
            </b-button>
          </div>
          <b-button
            class="is-bold icon-spin-once"
            type="is-primary is-light"
            outlined
            icon-left="redo"
            @click="openLocationImportModal"
          >
            Reimport POS Menu
          </b-button>

          <b-button
            class="is-bold"
            type="is-danger"
            outlined
            icon-left="trash-alt"
            @click="openClearDataModal"
          >
            Clear Data
          </b-button>

          <b-button
            class="is-bold icon-shift"
            type="is-primary"
            icon-right="arrow-right"
            @click="createMenu"
          >
            Create Menu
          </b-button>
        </div>
      </div>
      <transition mode="out-in" name="fade-zoom">
        <div :key="selectedStoreId" class="is-relative">
          <table class="table is-fullwidth">
            <thead class="thead">
              <tr>
                <th>Name</th>
                <th>PLU#</th>
                <th class="has-text-centered">Item Count</th>
                <th class="pad-r-xs pad-t-none pad-b-xs">
                  <div class="no-wrap-text is-flex justify-end">
                    <dropdown-menu
                      position="bottom-end"
                    >
                      <b-button slot="trigger" class="is-transparent" type="is-white">
                        <b-icon icon="ellipsis-v" pack="far" />
                      </b-button>

                      <b-dropdown-item
                        class="is-flex align-center pad-r has-text-primary"
                        aria-role="listitem"
                        @click="toggleAllExcludeValues(true)"
                      >
                        <b-icon custom-size="fa-lg" class="mar-r" icon="square" pack="far" />
                        <span>Unselect Full Menu</span>
                      </b-dropdown-item>

                      <b-dropdown-item
                        class="is-flex align-center pad-r has-text-primary"
                        aria-role="listitem"
                        @click="toggleAllExcludeValues(false)"
                      >
                        <b-icon custom-size="fa-lg" class="mar-r" icon="check-square" />
                        <span>Select Full Menu</span>
                      </b-dropdown-item>

                      <b-dropdown-item
                        class="is-flex align-center pad-r"
                        aria-role="listitem"
                        @click="toggleAll(true)"
                      >
                        <b-icon custom-size="fa-lg" class="mar-r" icon="square" pack="far" />
                        <span>Unselect All Categories</span>
                      </b-dropdown-item>

                      <b-dropdown-item
                        class="is-flex align-center pad-r"
                        aria-role="listitem"
                        @click="toggleAll(false)"
                      >
                        <b-icon custom-size="fa-lg" class="mar-r" icon="check-square" />
                        <span>Select All Categories</span>
                      </b-dropdown-item>

                    </dropdown-menu>
                  </div>
                </th>
              </tr>
            </thead>

            <tbody>
              <!-- CATEGORY -->
              <template v-for="(category, categoryIndex) in posMenu">
                <tr :key="category.id" class="category">
                  <td>
                    <span :class="['is-flex align-center', {link: category.menuItemCount}]" @click="category.menuItemCount && toggleRow(category.id, category.id)">
                      <b-icon v-if="fetchingChildrenId === category.id" icon="spinner-third" class="is-size-7 spin" />
                      <b-icon v-else-if="category.menuItemCount" :icon="isOpen[category.id] ? 'chevron-down' : 'chevron-right'" class="is-size-7" />
                      <b-icon v-else class="is-size-7" />
                      <span class="mar-l-xs">{{ category.name }}</span>
                    </span>
                  </td>
                  <td class="is-monospaced">{{ category.posItemId }}</td>
                  <td class="has-text-centered">{{ category.menuItemCount }}</td>
                  <td>
                    <div class="is-flex justify-end dist-x align-center">
                      <b-dropdown
                        aria-role="list"
                        :position="shouldPositionTop(categoryIndex, categoryIndex, posMenu.length) ? 'is-top-left' : 'is-bottom-left'"
                        :mobile-modal="false"
                      >
                        <b-button slot="trigger" class="is-transparent" type="is-white">
                          <b-icon icon="ellipsis-v" pack="far" />
                        </b-button>
                        <b-dropdown-item :disabled="!category.menuItemCount" class="is-flex align-center" @click="setChildExcludeValues(category.id, true)">
                          <b-icon icon="square" pack="far" class="mar-r" custom-size="fa-lg" />
                          Unselect all Items
                        </b-dropdown-item>
                        <b-dropdown-item :disabled="!category.menuItemCount" class="is-flex align-center" @click="setChildExcludeValues(category.id, false)">
                          <b-icon icon="check-square" class="mar-r" custom-size="fa-lg" />
                          Select all Items
                        </b-dropdown-item>
                      </b-dropdown>
                      <b-checkbox
                        class="no-label"
                        :value="getExcludeValue(category.id)"
                        @input="setExcludeValue(category.id, !$event)"
                      />
                    </div>
                  </td>
                </tr>
                <template v-if="isOpen[category.id]">
                  <!-- ITEM GROUP -->
                  <template v-if="hasItemGroupChildren(category.id)">
                    <template v-for="(itemGroup, itemGroupIndex) in getChildren(category.id)">
                      <tr
                        :key="`${category.id}${itemGroup.id}`"
                        :class="['level-2', { 'has-text-grey': excludeValues[category.id].value }]"
                      >
                        <td class="animated animated-slow tdFadeInRight">
                          <p
                            :class="['is-inline-block', { link: itemGroup.childrenCount, 'link-grey': excludeValues[category.id].value }]"
                            @click="itemGroup.childrenCount && toggleRow(itemGroup.id, `${category.id}${itemGroup.id}`)"
                          >
                            <b-icon v-if="fetchingChildrenId === `${category.id}${itemGroup.id}`" icon="spinner-third" class="is-size-7 spin" />
                            <b-icon v-else-if="itemGroup.childrenCount" :icon="isOpen[`${category.id}${itemGroup.id}`] ? 'chevron-down' : 'chevron-right'" class="is-size-7" />
                            <b-icon v-else class="is-size-7" />
                            <span class="mar-l-xs">{{ itemGroup.name }}</span>
                            <b-icon
                              v-if="!itemGroup.mappedToCardfree"
                              v-tippy="{ content: 'Missing Item Mapping', placement: 'right' }"
                              class="mar-l"
                              size="is-small"
                              icon="exclamation-triangle"
                              type="is-danger"
                            />
                          </p>
                        </td>
                        <td class="is-monospaced">{{ itemGroup.posItemId }}</td>
                        <td class="has-text-centered">{{ itemGroup.childrenCount }}</td>
                        <td>
                          <div class="is-flex justify-end dist-x align-center">
                            <b-dropdown
                              aria-role="list"
                              :position="shouldPositionTop(categoryIndex, itemGroupIndex, category.menuItemCount) ? 'is-top-left' : 'is-bottom-left'"
                              :mobile-modal="false"
                            >
                              <b-button slot="trigger" class="is-transparent" type="is-white">
                                <b-icon icon="ellipsis-v" pack="far" />
                              </b-button>
                              <b-dropdown-item :disabled="!itemGroup.childrenCount" class="is-flex align-center" @click="setChildExcludeValues(itemGroup.id, true)">
                                <b-icon icon="square" pack="far" class="mar-r" custom-size="fa-lg" />
                                Unselect all Items
                              </b-dropdown-item>
                              <b-dropdown-item :disabled="!itemGroup.childrenCount" class="is-flex align-center" @click="setChildExcludeValues(itemGroup.id, false)">
                                <b-icon icon="check-square" class="mar-r" custom-size="fa-lg" />
                                Select all Items
                              </b-dropdown-item>
                            </b-dropdown>
                            <b-checkbox
                              :type="excludeValues[category.id].value ? 'is-grey' : 'is-primary'"
                              :disabled="excludeValues[category.id].value"
                              class="no-label"
                              :value="getExcludeValue(itemGroup.id)"
                              @input="setExcludeValue(itemGroup.id, !$event)"
                            />
                          </div>
                        </td>
                      </tr>
                      <template v-if="isOpen[`${category.id}${itemGroup.id}`]">
                        <!-- ITEM -->
                        <template v-for="(item, itemIndex) in getChildren(itemGroup.id)">
                          <tr
                            :key="`${category.id}${itemGroup.id}${item.id}`"
                            :class="['level-3', { 'has-text-grey': excludeValues[category.id].value || excludeValues[itemGroup.id].value }]"
                          >
                            <td class="animated animated-slow tdFadeInRight">
                              <p
                                :class="['is-inline-block', { link: item.childrenCount, 'link-grey': excludeValues[category.id].value || excludeValues[itemGroup.id].value }]"
                                @click="item.childrenCount && toggleRow(item.id, `${category.id}${itemGroup.id}${item.id}`)"
                              >
                                <b-icon v-if="fetchingChildrenId === `${category.id}${itemGroup.id}${item.id}`" icon="spinner-third" class="is-size-7 spin" />
                                <b-icon v-else-if="item.childrenCount" :icon="isOpen[`${category.id}${itemGroup.id}${item.id}`] ? 'chevron-down' : 'chevron-right'" class="is-size-7" />
                                <b-icon v-else class="is-size-7" />
                                <span class="mar-l-xs">{{ item.name }}</span>
                                <b-icon
                                  v-if="!item.mappedToCardfree"
                                  v-tippy="{ content: 'Missing Item Mapping', placement: 'right' }"
                                  class="mar-l"
                                  size="is-small"
                                  icon="exclamation-triangle"
                                  type="is-danger"
                                />
                              </p>
                              <p class="description">
                                {{ item.description }}
                              </p>
                            </td>
                            <td class="is-monospaced">{{ item.posItemId }}</td>
                            <td class="has-text-centered">{{ item.childrenCount }}</td>
                            <td>
                              <div class="is-flex justify-end dist-x align-center">
                                <b-dropdown
                                  aria-role="list"
                                  :position="shouldPositionTop(categoryIndex, itemIndex, itemGroup.childrenCount) ? 'is-top-left' : 'is-bottom-left'"
                                  :mobile-modal="false"
                                >
                                  <b-button slot="trigger" class="is-transparent" type="is-white">
                                    <b-icon icon="ellipsis-v" pack="far" />
                                  </b-button>
                                  <b-dropdown-item :disabled="!item.childrenCount" class="is-flex align-center" @click="setChildExcludeValues(item.id, true)">
                                    <b-icon icon="square" pack="far" class="mar-r" custom-size="fa-lg" />
                                    Unselect all Modifier Groups
                                  </b-dropdown-item>
                                  <b-dropdown-item :disabled="!item.childrenCount" class="is-flex align-center" @click="setChildExcludeValues(item.id, false)">
                                    <b-icon icon="check-square" class="mar-r" custom-size="fa-lg" />
                                    Select all Modifier Groups
                                  </b-dropdown-item>
                                </b-dropdown>
                                <b-checkbox
                                  :type="excludeValues[category.id].value || excludeValues[itemGroup.id].value ? 'is-grey' : 'is-primary'"
                                  :disabled="excludeValues[category.id].value || excludeValues[itemGroup.id].value"
                                  class="no-label"
                                  :value="getExcludeValue(item.id)"
                                  @input="setExcludeValue(item.id, !$event)"
                                />
                              </div>
                            </td>
                          </tr>

                          <template v-if="isOpen[`${category.id}${itemGroup.id}${item.id}`]">
                            <!-- MODIFIER GROUP -->
                            <template v-for="(modGroup, modGroupIndex) in getChildren(item.id)">
                              <tr
                                :key="`${category.id}${itemGroup.id}${item.id}${modGroup.id}`"
                                :class="['level-4', { 'has-text-grey': excludeValues[category.id].value || excludeValues[itemGroup.id].value || excludeValues[item.id].value }]"
                              >
                                <td class="animated animated-slow tdFadeInRight">
                                  <span
                                    :class="{ link: modGroup.childrenCount, 'link-grey': excludeValues[category.id].value || excludeValues[itemGroup.id].value || excludeValues[item.id].value }"
                                    @click="modGroup.childrenCount && toggleRow(modGroup.id, `${category.id}${itemGroup.id}${item.id}${modGroup.id}`)"
                                  >
                                    <b-icon v-if="fetchingChildrenId === `${category.id}${itemGroup.id}${item.id}${modGroup.id}`" icon="spinner-third" class="is-size-7 spin" />
                                    <b-icon v-else-if="modGroup.childrenCount" :icon="isOpen[`${category.id}${itemGroup.id}${item.id}${modGroup.id}`] ? 'chevron-down' : 'chevron-right'" class="is-size-7" />
                                    <b-icon v-else class="is-size-7" />
                                    <span class="mar-l-xs">{{ modGroup.name }}</span>
                                  </span>
                                </td>
                                <td class="is-monospaced">{{ modGroup.posItemId }}</td>
                                <td class="has-text-centered">{{ modGroup.childrenCount }}</td>
                                <td>
                                  <div class="is-flex justify-end dist-x align-center">
                                    <b-dropdown
                                      aria-role="list"
                                      :position="shouldPositionTop(categoryIndex, modGroupIndex, item.childrenCount) ? 'is-top-left' : 'is-bottom-left'"
                                      :mobile-modal="false"
                                    >
                                      <b-button slot="trigger" class="is-transparent" type="is-white">
                                        <b-icon icon="ellipsis-v" pack="far" />
                                      </b-button>
                                      <b-dropdown-item :disabled="!modGroup.childrenCount" class="is-flex align-center" @click="setChildExcludeValues(modGroup.id, true)">
                                        <b-icon icon="square" pack="far" class="mar-r" custom-size="fa-lg" />
                                        Unselect all Modifiers
                                      </b-dropdown-item>
                                      <b-dropdown-item :disabled="!modGroup.childrenCount" class="is-flex align-center" @click="setChildExcludeValues(modGroup.id, false)">
                                        <b-icon icon="check-square" class="mar-r" custom-size="fa-lg" />
                                        Select all Modifiers
                                      </b-dropdown-item>
                                    </b-dropdown>
                                    <b-checkbox
                                      :type="excludeValues[category.id].value || excludeValues[itemGroup.id].value || excludeValues[item.id].value ? 'is-grey' : 'is-primary'"
                                      :disabled="excludeValues[category.id].value || excludeValues[itemGroup.id].value || excludeValues[item.id].value"
                                      class="no-label"
                                      :value="getExcludeValue(modGroup.id)"
                                      @input="setExcludeValue(modGroup.id, !$event)"
                                    />
                                  </div>
                                </td>
                              </tr>

                              <template v-if="isOpen[`${category.id}${itemGroup.id}${item.id}${modGroup.id}`]">
                                <!-- MODIFIER -->
                                <template v-for="(modifier, modIndex) in getChildren(modGroup.id)">
                                  <tr
                                    :key="`${category.id}${itemGroup.id}${item.id}${modGroup.id}${modifier.id}`"
                                    :class="['level-5', { 'has-text-grey': excludeValues[category.id].value || excludeValues[itemGroup.id].value || excludeValues[item.id].value || excludeValues[modGroup.id].value }]"
                                  >
                                    <td class="animated animated-slow tdFadeInRight">
                                      <span
                                        :class="{ link: modifier.childrenCount, 'link-grey': excludeValues[category.id].value || excludeValues[itemGroup.id].value || excludeValues[item.id].value || excludeValues[modGroup.id].value }"
                                        @click="modifier.childrenCount && toggleRow(modifier.id, `${category.id}${itemGroup.id}${item.id}${modGroup.id}${modifier.id}`)"
                                      >
                                        <b-icon v-if="fetchingChildrenId === `${category.id}${itemGroup.id}${item.id}${modGroup.id}${modifier.id}`" icon="spinner-third" class="is-size-7 spin" />
                                        <b-icon v-else-if="modifier.childrenCount" :icon="isOpen[`${category.id}${itemGroup.id}${item.id}${modGroup.id}${modifier.id}`] ? 'chevron-down' : 'chevron-right'" class="is-size-7" />
                                        <b-icon v-else class="is-size-7" />
                                        <span class="mar-l-xs">{{ modifier.name }}</span>
                                      </span>
                                      <b-icon
                                        v-if="!modifier.mappedToCardfree"
                                        v-tippy="{ content: 'Missing Item Mapping', placement: 'right' }"
                                        class="mar-l"
                                        size="is-small"
                                        icon="exclamation-triangle"
                                        type="is-danger"
                                      />
                                    </td>
                                    <td class="is-monospaced">{{ modifier.posItemId }}</td>
                                    <td class="has-text-centered">{{ modifier.childrenCount }}</td>
                                    <td>
                                      <div class="is-flex justify-end dist-x align-center">
                                        <b-dropdown
                                          aria-role="list"
                                          :position="shouldPositionTop(categoryIndex, modIndex, modGroup.childrenCount) ? 'is-top-left' : 'is-bottom-left'"
                                          :mobile-modal="false"
                                        >
                                          <b-button slot="trigger" class="is-transparent" type="is-white">
                                            <b-icon icon="ellipsis-v" pack="far" />
                                          </b-button>
                                          <b-dropdown-item :disabled="!modifier.childrenCount" class="is-flex align-center" @click="setChildExcludeValues(modifier.id, true)">
                                            <b-icon icon="square" pack="far" class="mar-r" custom-size="fa-lg" />
                                            Unselect all Nested Modifier Groups
                                          </b-dropdown-item>
                                          <b-dropdown-item :disabled="!modifier.childrenCount" class="is-flex align-center" @click="setChildExcludeValues(modifier.id, false)">
                                            <b-icon icon="check-square" class="mar-r" custom-size="fa-lg" />
                                            Select all Nested Modifier Groups
                                          </b-dropdown-item>
                                        </b-dropdown>
                                        <b-checkbox
                                          :disabled="excludeValues[category.id].value || excludeValues[itemGroup.id].value || excludeValues[item.id].value || excludeValues[modGroup.id].value"
                                          :type="excludeValues[category.id].value || excludeValues[itemGroup.id].value || excludeValues[item.id].value || excludeValues[modGroup.id].value ? 'is-grey' : 'is-primary'"
                                          class="no-label"
                                          :value="getExcludeValue(modifier.id)"
                                          @input="setExcludeValue(modifier.id, !$event)"
                                        />
                                      </div>
                                    </td>
                                  </tr>

                                  <!-- NESTED MODIFIER GROUP -->
                                  <template v-if="isOpen[`${category.id}${itemGroup.id}${item.id}${modGroup.id}${modifier.id}`]">
                                    <template v-for="(nestedModGroup, nestedModGroupIndex) in getChildren(modifier.id)">
                                      <tr
                                        :key="`${category.id}${itemGroup.id}${item.id}${modGroup.id}${modifier.id}${nestedModGroup.id}`"
                                        :class="['level-6', { 'has-text-grey': excludeValues[category.id].value || excludeValues[itemGroup.id].value || excludeValues[item.id].value || excludeValues[modGroup.id].value || excludeValues[modifier.id].value }]"
                                      >
                                        <td class="animated animated-slow tdFadeInRight">
                                          <span
                                            :class="{ link: nestedModGroup.childrenCount, 'link-grey': excludeValues[category.id].value || excludeValues[itemGroup.id].value || excludeValues[item.id].value || excludeValues[modGroup.id].value || excludeValues[modifier.id].value }"
                                            @click="nestedModGroup.childrenCount && toggleRow(nestedModGroup.id, `${category.id}${itemGroup.id}${item.id}${modGroup.id}${modifier.id}${nestedModGroup.id}`)"
                                          >
                                            <b-icon v-if="fetchingChildrenId === `${category.id}${itemGroup.id}${item.id}${modGroup.id}${modifier.id}${nestedModGroup.id}`" icon="spinner-third" class="is-size-7 spin" />
                                            <b-icon v-else-if="nestedModGroup.childrenCount" :icon="isOpen[`${category.id}${itemGroup.id}${item.id}${modGroup.id}${modifier.id}${nestedModGroup.id}`] ? 'chevron-down' : 'chevron-right'" class="is-size-7" />
                                            <b-icon v-else class="is-size-7" />
                                            <span class="mar-l-xs">{{ nestedModGroup.name }}</span>
                                          </span>
                                        </td>
                                        <td class="is-monospaced">{{ nestedModGroup.posItemId }}</td>
                                        <td class="has-text-centered">{{ nestedModGroup.childrenCount }}</td>
                                        <td>
                                          <div class="is-flex justify-end dist-x align-center">
                                            <b-dropdown
                                              aria-role="list"
                                              :position="shouldPositionTop(categoryIndex, nestedModGroupIndex, modifier.childrenCount) ? 'is-top-left' : 'is-bottom-left'"
                                              :mobile-modal="false"
                                            >
                                              <b-button slot="trigger" class="is-transparent" type="is-white">
                                                <b-icon icon="ellipsis-v" pack="far" />
                                              </b-button>
                                              <b-dropdown-item :disabled="!nestedModGroup.childrenCount" class="is-flex align-center" @click="setChildExcludeValues(nestedModGroup.id, true)">
                                                <b-icon icon="square" pack="far" class="mar-r" custom-size="fa-lg" />
                                                Unselect all Nested Modifiers
                                              </b-dropdown-item>
                                              <b-dropdown-item :disabled="!nestedModGroup.childrenCount" class="is-flex align-center" @click="setChildExcludeValues(nestedModGroup.id, false)">
                                                <b-icon icon="check-square" class="mar-r" custom-size="fa-lg" />
                                                Select all Nested Modifiers
                                              </b-dropdown-item>
                                            </b-dropdown>
                                            <b-checkbox
                                              :disabled="excludeValues[category.id].value || excludeValues[itemGroup.id].value || excludeValues[item.id].value || excludeValues[modGroup.id].value || excludeValues[modifier.id].value"
                                              :type="excludeValues[category.id].value || excludeValues[itemGroup.id].value || excludeValues[item.id].value || excludeValues[modGroup.id].value || excludeValues[modifier.id].value ? 'is-grey' : 'is-primary'"
                                              class="no-label"
                                              :value="getExcludeValue(nestedModGroup.id)"
                                              @input="setExcludeValue(nestedModGroup.id, !$event)"
                                            />
                                          </div>
                                        </td>
                                      </tr>

                                      <!-- NESTED MODIFIERS -->
                                      <template v-if="isOpen[`${category.id}${itemGroup.id}${item.id}${modGroup.id}${modifier.id}${nestedModGroup.id}`]">
                                        <template v-for="(nestedMod) in getChildren(nestedModGroup.id)">
                                          <tr
                                            :key="`${category.id}${itemGroup.id}${item.id}${modGroup.id}${modifier.id}${nestedModGroup.id}${nestedMod.id}`"
                                            :class="['level-7', { 'has-text-grey': excludeValues[category.id].value || excludeValues[itemGroup.id].value || excludeValues[item.id].value || excludeValues[modGroup.id].value || excludeValues[modifier.id].value || excludeValues[nestedModGroup.id].value }]"
                                          >
                                            <td class="animated animated-slow tdFadeInRight">
                                              <span
                                                :class="{ link: false, 'link-grey': excludeValues[category.id].value || excludeValues[itemGroup.id].value || excludeValues[item.id].value || excludeValues[modGroup.id].value || excludeValues[modifier.id].value || excludeValues[nestedModGroup.id].value }"
                                              >
                                                <b-icon class="is-size-7" />
                                                <span class="mar-l-xs">{{ nestedMod.name }}</span>
                                              </span>
                                              <b-icon
                                                v-if="!nestedMod.mappedToCardfree"
                                                v-tippy="{ content: 'Missing Item Mapping', placement: 'right' }"
                                                class="mar-l"
                                                size="is-small"
                                                icon="exclamation-triangle"
                                                type="is-danger"
                                              />
                                            </td>
                                            <td class="is-monospaced">{{ nestedMod.posItemId }}</td>
                                            <td class="has-text-centered has-text-grey-light">–</td>
                                            <td>
                                              <div class="is-flex justify-end">
                                                <b-checkbox
                                                  :disabled="excludeValues[category.id].value || excludeValues[itemGroup.id].value || excludeValues[item.id].value || excludeValues[modGroup.id].value || excludeValues[modifier.id].value || excludeValues[nestedModGroup.id].value"
                                                  :type="excludeValues[category.id].value || excludeValues[itemGroup.id].value || excludeValues[item.id].value || excludeValues[modGroup.id].value || excludeValues[modifier.id].value || excludeValues[nestedModGroup.id].value ? 'is-grey' : 'is-primary'"
                                                  class="no-label"
                                                  :value="getExcludeValue(nestedMod.id)"
                                                  @input="setExcludeValue(nestedMod.id, !$event)"
                                                />
                                              </div>
                                            </td>
                                          </tr>
                                        </template>
                                      </template>
                                    </template>
                                  </template>
                                </template>
                              </template>
                            </template>
                          </template>
                        </template>
                      </template>
                    </template>
                  </template>
                  <template v-else>
                    <!--        ITEM            -->
                    <template v-for="(item, itemIndex) in getChildren(category.id)">
                      <tr
                        :key="`${category.id}${item.id}`"
                        :class="['level-2', { 'has-text-grey': excludeValues[category.id].value }]"
                      >
                        <td class="animated animated-slow tdFadeInRight">
                          <p
                            :class="['is-inline-block', { link: item.childrenCount, 'link-grey': excludeValues[category.id].value }]"
                            @click="item.childrenCount && toggleRow(item.id, `${category.id}${item.id}`)"
                          >
                            <b-icon v-if="fetchingChildrenId === `${category.id}${item.id}`" icon="spinner-third" class="is-size-7 spin" />
                            <b-icon v-else-if="item.childrenCount" :icon="isOpen[`${category.id}${item.id}`] ? 'chevron-down' : 'chevron-right'" class="is-size-7" />
                            <b-icon v-else class="is-size-7" />
                            <span class="mar-l-xs">{{ item.name }}</span>
                            <b-icon
                              v-if="!item.mappedToCardfree"
                              v-tippy="{ content: 'Missing Item Mapping', placement: 'right' }"
                              class="mar-l"
                              size="is-small"
                              icon="exclamation-triangle"
                              type="is-danger"
                            />
                          </p>
                          <p class="description">
                            {{ item.description }}
                          </p>
                        </td>
                        <td class="is-monospaced">{{ item.posItemId }}</td>
                        <td class="has-text-centered">{{ item.childrenCount }}</td>
                        <td>
                          <div class="is-flex justify-end dist-x align-center">
                            <b-dropdown
                              aria-role="list"
                              :position="shouldPositionTop(categoryIndex, itemIndex, category.menuItemCount) ? 'is-top-left' : 'is-bottom-left'"
                              :mobile-modal="false"
                            >
                              <b-button slot="trigger" class="is-transparent" type="is-white">
                                <b-icon icon="ellipsis-v" pack="far" />
                              </b-button>
                              <b-dropdown-item :disabled="!item.childrenCount" class="is-flex align-center" @click="setChildExcludeValues(item.id, true)">
                                <b-icon icon="square" pack="far" class="mar-r" custom-size="fa-lg" />
                                Unselect all Modifier Groups
                              </b-dropdown-item>
                              <b-dropdown-item :disabled="!item.childrenCount" class="is-flex align-center" @click="setChildExcludeValues(item.id, false)">
                                <b-icon icon="check-square" class="mar-r" custom-size="fa-lg" />
                                Select all Modifier Groups
                              </b-dropdown-item>
                            </b-dropdown>
                            <b-checkbox
                              :type="excludeValues[category.id].value ? 'is-grey' : 'is-primary'"
                              :disabled="excludeValues[category.id].value"
                              class="no-label"
                              :value="getExcludeValue(item.id)"
                              @input="setExcludeValue(item.id, !$event)"
                            />
                          </div>
                        </td>
                      </tr>

                      <!-- MODIFIER GROUP -->
                      <template v-if="isOpen[`${category.id}${item.id}`]">
                        <template v-for="(modGroup, modGroupIndex) in getChildren(item.id)">
                          <tr
                            :key="`${category.id}${item.id}${modGroup.id}`"
                            :class="['level-3', { 'has-text-grey': excludeValues[category.id].value || excludeValues[item.id].value }]"
                          >
                            <td class="animated animated-slow tdFadeInRight">
                              <span
                                :class="{link: modGroup.childrenCount, 'link-grey': excludeValues[category.id].value || excludeValues[item.id].value}"
                                @click="modGroup.childrenCount && toggleRow(modGroup.id, `${category.id}${item.id}${modGroup.id}`)"
                              >
                                <b-icon v-if="fetchingChildrenId === `${category.id}${item.id}${modGroup.id}`" icon="spinner-third" class="is-size-7 spin" />
                                <b-icon v-else-if="modGroup.childrenCount" :icon="isOpen[`${category.id}${item.id}${modGroup.id}`] ? 'chevron-down' : 'chevron-right'" class="is-size-7" />
                                <b-icon v-else class="is-size-7" />
                                <span class="mar-l-xs">{{ modGroup.name }}</span>
                              </span>
                            </td>
                            <td class="is-monospaced">{{ modGroup.posItemId }}</td>
                            <td class="has-text-centered">{{ modGroup.childrenCount }}</td>
                            <td>
                              <div class="is-flex justify-end dist-x align-center">
                                <b-dropdown
                                  aria-role="list"
                                  :position="shouldPositionTop(categoryIndex, modGroupIndex, item.childrenCount) ? 'is-top-left' : 'is-bottom-left'"
                                  :mobile-modal="false"
                                >
                                  <b-button slot="trigger" class="is-transparent" type="is-white">
                                    <b-icon icon="ellipsis-v" pack="far" />
                                  </b-button>
                                  <b-dropdown-item :disabled="!modGroup.childrenCount" class="is-flex align-center" @click="setChildExcludeValues(modGroup.id, true)">
                                    <b-icon icon="square" pack="far" class="mar-r" custom-size="fa-lg" />
                                    Unselect all Modifiers
                                  </b-dropdown-item>
                                  <b-dropdown-item :disabled="!modGroup.childrenCount" class="is-flex align-center" @click="setChildExcludeValues(modGroup.id, false)">
                                    <b-icon icon="check-square" class="mar-r" custom-size="fa-lg" />
                                    Select all Modifiers
                                  </b-dropdown-item>
                                </b-dropdown>
                                <b-checkbox
                                  :disabled="excludeValues[category.id].value || excludeValues[item.id].value"
                                  :type="excludeValues[category.id].value || excludeValues[item.id].value ? 'is-grey' : 'is-primary'"
                                  class="no-label"
                                  :value="getExcludeValue(modGroup.id)"
                                  @input="setExcludeValue(modGroup.id, !$event)"
                                />
                              </div>
                            </td>
                          </tr>

                          <!-- MODIFIER -->
                          <template v-if="isOpen[`${category.id}${item.id}${modGroup.id}`]">
                            <template v-for="(modifier, modIndex) in getChildren(modGroup.id)">
                              <tr
                                :key="
                                  `${category.id}${item.id}${modGroup.id}${modifier.id}`"
                                :class="['level-4', { 'has-text-grey': excludeValues[category.id].value || excludeValues[item.id].value || excludeValues[modGroup.id].value }]"
                              >
                                <td class="animated animated-slow tdFadeInRight">
                                  <span
                                    :class="{link: modifier.childrenCount, 'link-grey': excludeValues[category.id].value || excludeValues[item.id].value || excludeValues[modGroup.id].value}"
                                    @click="modifier.childrenCount && toggleRow(modifier.id, `${category.id}${item.id}${modGroup.id}${modifier.id}`)"
                                  >
                                    <b-icon v-if="fetchingChildrenId === `${category.id}${item.id}${modGroup.id}${modifier.id}`" icon="spinner-third" class="is-size-7 spin" />
                                    <b-icon v-else-if="modifier.childrenCount" :icon="isOpen[`${category.id}${item.id}${modGroup.id}${modifier.id}`] ? 'chevron-down' : 'chevron-right'" class="is-size-7" />
                                    <b-icon v-else class="is-size-7" />
                                    <span class="mar-l-xs">{{ modifier.name }}</span>
                                  </span>
                                  <b-icon
                                    v-if="!modifier.mappedToCardfree"
                                    v-tippy="{ content: 'Missing Item Mapping', placement: 'right' }"
                                    class="mar-l"
                                    size="is-small"
                                    icon="exclamation-triangle"
                                    type="is-danger"
                                  />
                                </td>
                                <td class="is-monospaced">{{ modifier.posItemId }}</td>
                                <td class="has-text-centered">{{ modifier.childrenCount }}</td>
                                <td>
                                  <div class="is-flex justify-end dist-x align-center">
                                    <b-dropdown
                                      aria-role="list"
                                      :position="shouldPositionTop(categoryIndex, modIndex, modGroup.childrenCount) ? 'is-top-left' : 'is-bottom-left'"
                                      :mobile-modal="false"
                                    >
                                      <b-button slot="trigger" class="is-transparent" type="is-white">
                                        <b-icon icon="ellipsis-v" pack="far" />
                                      </b-button>
                                      <b-dropdown-item :disabled="!modifier.childrenCount" class="is-flex align-center" @click="setChildExcludeValues(modifier.id, true)">
                                        <b-icon icon="square" pack="far" class="mar-r" custom-size="fa-lg" />
                                        Unselect all Nested Modifier Groups
                                      </b-dropdown-item>
                                      <b-dropdown-item :disabled="!modifier.childrenCount" class="is-flex align-center" @click="setChildExcludeValues(modifier.id, false)">
                                        <b-icon icon="check-square" class="mar-r" custom-size="fa-lg" />
                                        Select all Nested Modifier Groups
                                      </b-dropdown-item>
                                    </b-dropdown>
                                    <b-checkbox
                                      :disabled="excludeValues[category.id].value || excludeValues[item.id].value || excludeValues[modGroup.id].value"
                                      :type="excludeValues[category.id].value || excludeValues[item.id].value || excludeValues[modGroup.id].value ? 'is-grey' : 'is-primary'"
                                      class="no-label"
                                      :value="getExcludeValue(modifier.id)"
                                      @input="setExcludeValue(modifier.id, !$event)"
                                    />
                                  </div>
                                </td>
                              </tr>

                              <!-- NESTED MODIFIER GROUP -->
                              <template v-if="isOpen[`${category.id}${item.id}${modGroup.id}${modifier.id}`]">
                                <template v-for="(nestedModGroup, nestedModGroupIndex) in getChildren(modifier.id)">
                                  <tr
                                    :key="`${category.id}${item.id}${modGroup.id}${modifier.id}${nestedModGroup.id}`"
                                    :class="['level-5', { 'has-text-grey': excludeValues[category.id].value || excludeValues[item.id].value || excludeValues[modGroup.id].value || excludeValues[modifier.id].value }]"
                                  >
                                    <td class="animated animated-slow tdFadeInRight">
                                      <span
                                        :class="{link: nestedModGroup.childrenCount, 'link-grey': excludeValues[category.id].value || excludeValues[item.id].value || excludeValues[modGroup.id].value}"
                                        @click="nestedModGroup.childrenCount && toggleRow(nestedModGroup.id, `${category.id}${item.id}${modGroup.id}${modifier.id}${nestedModGroup.id}`)"
                                      >
                                        <b-icon v-if="fetchingChildrenId === `${category.id}${item.id}${modGroup.id}${modifier.id}${nestedModGroup.id}`" icon="spinner-third" class="is-size-7 spin" />
                                        <b-icon v-else-if="nestedModGroup.childrenCount" :icon="isOpen[`${category.id}${item.id}${modGroup.id}${modifier.id}${nestedModGroup.id}`] ? 'chevron-down' : 'chevron-right'" class="is-size-7" />
                                        <b-icon v-else class="is-size-7" />
                                        <span class="mar-l-xs">{{ nestedModGroup.name }}</span>
                                      </span>
                                    </td>
                                    <td class="is-monospaced">{{ nestedModGroup.posItemId }}</td>
                                    <td class="has-text-centered">{{ nestedModGroup.childrenCount }}</td>
                                    <td>
                                      <div class="is-flex justify-end dist-x align-center">
                                        <b-dropdown
                                          aria-role="list"
                                          :position="shouldPositionTop(categoryIndex, nestedModGroupIndex, modifier.childrenCount) ? 'is-top-left' : 'is-bottom-left'"
                                          :mobile-modal="false"
                                        >
                                          <b-button slot="trigger" class="is-transparent" type="is-white">
                                            <b-icon icon="ellipsis-v" pack="far" />
                                          </b-button>
                                          <b-dropdown-item :disabled="!nestedModGroup.childrenCount" class="is-flex align-center" @click="setChildExcludeValues(nestedModGroup.id, true)">
                                            <b-icon icon="square" pack="far" class="mar-r" custom-size="fa-lg" />
                                            Unselect all Nested Modifiers
                                          </b-dropdown-item>
                                          <b-dropdown-item :disabled="!nestedModGroup.childrenCount" class="is-flex align-center" @click="setChildExcludeValues(nestedModGroup.id, false)">
                                            <b-icon icon="check-square" class="mar-r" custom-size="fa-lg" />
                                            Select all Nested Modifiers
                                          </b-dropdown-item>
                                        </b-dropdown>
                                        <b-checkbox
                                          :disabled="excludeValues[category.id].value || excludeValues[item.id].value || excludeValues[modGroup.id].value || excludeValues[modifier.id].value"
                                          :type="excludeValues[category.id].value || excludeValues[item.id].value || excludeValues[modGroup.id].value || excludeValues[modifier.id].value ? 'is-grey' : 'is-primary'"
                                          class="no-label"
                                          :value="getExcludeValue(nestedModGroup.id)"
                                          @input="setExcludeValue(nestedModGroup.id, !$event)"
                                        />
                                      </div>
                                    </td>
                                  </tr>

                                  <!-- NESTED MODIFIERS -->
                                  <template v-if="isOpen[`${category.id}${item.id}${modGroup.id}${modifier.id}${nestedModGroup.id}`]">
                                    <template v-for="(nestedMod) in getChildren(nestedModGroup.id)">
                                      <tr
                                        :key="`${category.id}${item.id}${modGroup.id}${modifier.id}${nestedModGroup.id}${nestedMod.id}`"
                                        :class="['level-6', { 'has-text-grey': excludeValues[category.id].value || excludeValues[item.id].value || excludeValues[modGroup.id].value || excludeValues[modifier.id].value || excludeValues[nestedModGroup.id].value }]"
                                      >
                                        <td class="animated animated-slow tdFadeInRight">
                                          <span
                                            :class="{link: nestedMod.childrenCount, 'link-grey': excludeValues[category.id].value || excludeValues[item.id].value || excludeValues[modGroup.id].value || excludeValues[nestedModGroup.id].value}"
                                            @click="nestedMod.childrenCount && toggleRow(nestedMod.id, `${category.id}${item.id}${modGroup.id}${modifier.id}${nestedModGroup.id}${nestedMod.id}}`)"
                                          >
                                            <b-icon v-if="fetchingChildrenId === `${category.id}${item.id}${modGroup.id}${modifier.id}${nestedModGroup.id}${nestedMod.id}`" icon="spinner-third" class="is-size-7 spin" />
                                            <b-icon v-else-if="nestedMod.childrenCount" :icon="isOpen[`${category.id}${item.id}${modGroup.id}${modifier.id}${nestedModGroup.id}${nestedMod.id}`] ? 'chevron-down' : 'chevron-right'" class="is-size-7" />
                                            <b-icon v-else class="is-size-7" />
                                            <span class="mar-l-xs">{{ nestedMod.name }}</span>
                                          </span>
                                          <b-icon
                                            v-if="!nestedMod.mappedToCardfree"
                                            v-tippy="{ content: 'Missing Item Mapping', placement: 'right' }"
                                            class="mar-l"
                                            size="is-small"
                                            icon="exclamation-triangle"
                                            type="is-danger"
                                          />
                                        </td>
                                        <td class="is-monospaced">{{ nestedMod.posItemId }}</td>
                                        <td class="has-text-centered has-text-grey-light">–</td>
                                        <td>
                                          <div class="is-flex justify-end">
                                            <b-checkbox
                                              :disabled="excludeValues[category.id].value || excludeValues[item.id].value || excludeValues[modGroup.id].value || excludeValues[modifier.id].value || excludeValues[nestedModGroup.id].value"
                                              :type="excludeValues[category.id].value || excludeValues[item.id].value || excludeValues[modGroup.id].value || excludeValues[modifier.id].value || excludeValues[nestedModGroup.id].value ? 'is-grey' : 'is-primary'"
                                              class="no-label"
                                              :value="getExcludeValue(nestedMod.id)"
                                              @input="setExcludeValue(nestedMod.id, !$event)"
                                            />
                                          </div>
                                        </td>
                                      </tr>
                                    </template>
                                  </template>
                                </template>
                              </template>
                            </template>
                          </template>
                        </template>
                      </template>
                    </template>
                  </template>
                </template>
              </template>
            </tbody>
          </table>
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
  import logger from '@/services/logger';
  import merchantMixin from '@/mixins/merchant';
  import scrollIndicatorMixin from '@/mixins/scroll-container-indicator';

  import Store from '@/store/classes/Store';
  import PosMenuCategory from '@/store/classes/PosMenuCategory';
  import PosMenuItem from '@/store/classes/PosMenuItem';
  import menuHelpModal from './menu-help-modal.vue';
  import alertModal from '@/components/globals/alert-modal.vue';

  export default {
    name: 'PosMenuList',

    mixins: [merchantMixin, scrollIndicatorMixin],

    props: {
      openLocationImportModal: {
        type: Function,
        required: true
      },
      openClearDataModal: {
        type: Function,
        required: true
      }
    },

    data() {
      return {
        isOpen: {},
        excludeValues: {},
        fetchingChildrenId: null,
        selectedStoreId: null
      };
    },

    computed: {
      posMenuCategories() {
        return PosMenuCategory.all();
      },

      posMenuCategoriesByStore() {
        return PosMenuCategory.query().where('storeId', this.selectedStoreId).get();
      },

      posMenu() {
        return this.hasStoreSpecificPosMenu
          ? this.posMenuCategoriesByStore
          : this.posMenuCategories;
      },

      importedPosStoreIds() {
        const storeIds = new Set();
        this.posMenuCategories.forEach((posCategory) => {
          if (posCategory.storeId) {
            storeIds.add(posCategory.storeId);
          }
        });

        return Array.from(storeIds);
      },

      importedPosStores() {
        return Store
          .orderByName()
          .whereIdIn(this.importedPosStoreIds)
          .get();
      },

      hasStoreSpecificPosMenu() {
        return !!this.importedPosStoreIds.length;
      },

      isAnyMenuSectionOpen() {
        return Object.values(this.isOpen).some(row => row);
      },

      hasAllSelected() {
        return this.posMenu.every(cat => !this.excludeValues[cat.id]?.value);
      }
    },

    mounted() {
      this.onMounted();
    },

    destroyed() {
      this.onDestroyed();
    },

    methods: {
      onMounted() {
        this.$_initScrollIndicatorSmart({
          contentSelector: '.table'
        });

        this.posMenuCategories.forEach((cat) => {
          this.setExcludeValue(cat.id, cat.excludeFromImport);
        });

        if (this.hasStoreSpecificPosMenu) {
          this.selectedStoreId = this.importedPosStoreIds[0];
        }
      },

      onDestroyed() {
        // delete all posMenuItems so they can be fetched
        // fetch next time this component is mounted
        PosMenuItem.deleteAll();
      },

      async toggleAllExcludeValues(excludeFromImport) {
        try {
          await PosMenuItem.bulkUpdateExcludeValues({ storeId: this.selectedStoreId, excludeFromImport });

          Object.keys(this.excludeValues).forEach((key) => {
            this.setExcludeValue(key, excludeFromImport);
          });

          this.$_onRequestSuccess({
            toastOptions: { message: 'Your POS Menu data has been updated' }
          });
        }
        catch (error) {
          this.$_onRequestError({
            error,
            toastOptions: { message: 'There was an error updating your POS Menu data' }
          });
        }
      },

      async fetchChildren(resourceId) {
        try {
          const children = await PosMenuItem.fetchChildren(resourceId);
          children.forEach((c) => {
            if (!(c.id in this.excludeValues)) {
              this.setExcludeValue(c.id, c.excludeFromImport);
            }
          });

          return children;
        }

        catch (error) {
          this.$_onRequestError({
            error,
            toastOptions: { message: 'There was an error fetching your POS Menu data' }
          });
        }
      },

      async toggleRow(resourceId, compositeId) {
        const hasNoChildren = !this.getChildren(resourceId).length;
        const isCurrentlyClosed = !this.isOpen[compositeId];

        try {
          if (isCurrentlyClosed && hasNoChildren) {
            this.fetchingChildrenId = compositeId;

            await this.fetchChildren(resourceId);
          }

          this.$set(
            this.isOpen,
            compositeId,
            !this.isOpen[compositeId]
          );
        }

        catch (error) {
          logger.error(error);
        }

        finally {
          this.fetchingChildrenId = null;
        }
      },

      getExcludeValue(resourceId) {
        return !this.excludeValues[resourceId]?.value;
      },

      setExcludeValue(id, value) {
        const hasExistingValue = id in this.excludeValues;

        this.$set(
          this.excludeValues,
          id,
          { value, hasChanged: hasExistingValue }
        );
      },

      async setChildExcludeValues(id, value) {
        let children = this.getChildren(id);

        if (!children.length) {
          children = await this.fetchChildren(id);
        }

        children.forEach((c) => {
          this.setExcludeValue(c.id, value);
        });
      },

      getChildren(parentId) {
        return PosMenuItem.query().where('parentId', parentId).get();
      },

      hasItemGroupChildren(parentId) {
        return this.getChildren(parentId)?.some(child => child.posMenuItemTypeId === 6);
      },

      shouldPositionTop(categoryIndex, resourceIndex, resourcesLength) {
        return resourceIndex === resourcesLength - 1 && categoryIndex === this.posMenu.length - 1;
      },

      openImportFailureEmail() {
        window.location.assign('mailto:support@cardfree.com?subject=POS Master Menu Creation Failure');
      },

      openMenuHelpModal() {
        this.$buefy.modal.open({
          parent: this,
          component: menuHelpModal,
          hasModalCard: true,
          trapFocus: true,
          canCancel: false,
          customClass: 'auto-width'
        });
      },

      openMenuCreationErrorModal() {
        this.$buefy.modal.open({
          parent: this,
          component: alertModal,
          hasModalCard: true,
          trapFocus: true,
          canCancel: false,
          customClass: 'auto-width',
          props: {
            buttons: [
              { text: 'Contact Support', onClick: this.openImportFailureEmail },
              { text: 'Okay', primary: true }
            ],
            icon: 'exclamation-triangle',
            iconPack: 'far',
            title: 'Master Menu Creation Failed',
            message: 'Sorry, it seems that something went wrong while creating your Master Menu. Please try again or contact support.',
            type: 'is-danger'
          }
        });
      },

      async createMenu() {
        const creatingMenuSnackbar = this.$buefy.snackbar.open({
          message: '<i class="fa fa-spinner-third spin mar-r-sm"></i> Creating Master Menu...',
          queue: false,
          indefinite: true,
          actionText: 'Ok'
        });

        try {
          const updatedPosMenuItems = Object.entries(this.excludeValues)
            .filter(arr => arr[1].hasChanged)
            .map(([key, { value }]) => ({
              id: Number(key),
              excludeFromImport: value
            }));

          if (updatedPosMenuItems.length) {
            await PosMenuItem.updateExcludeValues(updatedPosMenuItems);
          }

          await PosMenuCategory.generateMasterMenu(this.selectedStoreId);

          this.$router.push({ name: 'menuManagement' });
          this.openMenuHelpModal();
        }

        catch (error) {
          this.openMenuCreationErrorModal();
        }

        finally {
          creatingMenuSnackbar.close();
        }
      },

      toggleAll(excludeValue) {
        this.posMenu.forEach((cat) => {
          this.excludeValues[cat.id].value = excludeValue;
          this.excludeValues[cat.id].hasChanged = true;
        });
      }
    }
  };
</script>

<style lang="sass" scoped>
  tr
    &:nth-child(odd) td
      background-color: $white-bis

  td
    height: 4rem
    vertical-align: middle
    white-space: nowrap

  .category
    td:first-child
      padding-left: 0.5rem

  .level-2
    td:first-child
      padding-right: 3rem
      padding-left: 2rem

    .description
      font-size: 0.8rem
      white-space: normal
      margin-left: 30px
      &:hover
        overflow: visible
        -webkit-line-clamp: unset

  .level-3
    td:first-child
      padding-left: 3.5rem

  .level-4
    td:first-child
      padding-left: 6.5rem

  .level-5
    td:first-child
      padding-left: 9.5rem

  .level-6
    td:first-child
      padding-left: 12.5rem

  .level-7
    td:first-child
      padding-left: 14.5rem



  // .table-container
  //   overflow-x: auto

  //   &::before,
  //   &::after
  //     content: ''
  //     position: absolute
  //     top: 0
  //     bottom: 0
  //     opacity: 0
  //     width: 40px
  //     pointer-events: none
  //     transition: opacity 200ms
  //   &::before
  //     left: 0
  //     box-shadow: 30px 0px 25px -20px inset rgba(0, 0, 0, 0.125), 9px 0px 10px -10px inset rgba(0, 0, 0, 0.25)
  //   &::after
  //     right: 0
  //     box-shadow: -30px 0px 25px -20px inset rgba(0, 0, 0, 0.125), -9px 0px 10px -10px inset rgba(0, 0, 0, 0.25)

  //   &.overflow-left::before
  //     opacity: 1
  //   &.overflow-right::after
  //     opacity: 1
  //   &.overflow-both
  //     &::before,
  //     &::after
  //       opacity: 1

</style>
