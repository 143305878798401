<template>
  <validated-form
    ref="form"
    form-id="addEditOfferNotification"
    auto-focus
    @valid-submit="handleSubmit"
  >
    <panel
      title="Offer Expiration Notifications"
      subtitle="Configure when and how many times guests are notified about expiring offers"
      :loading="isFetching"
    >
      <template #buttons>
        <b-button
          rounded
          class="is-bold"
          size="is-medium"
          native-type="submit"
          type="is-primary"
          :loading="isSubmitting"
        >
          Save
        </b-button>
      </template>

      <p class="label">
        How many days ahead should guests be notified of expiring offers?
      </p>

      <b-message
        v-if="!form.length"
        type="is-primary"
        icon="info-square"
        class="is-compact has-shadow is-inline-block"
        has-icon
      >
        With no notifications configured guests will not be notified of expiring offers
      </b-message>

      <div v-else class="is-flex-column gap-sm mar-b-lg">
        <validation-provider
          v-for="(offerNotification, index) in form"
          v-slot="{ errors }"
          :key="offerNotification.guid"
          :vid="offerNotification.guid"
          name="Days"
          class="is-flex gap-sm"
          :rules="{
            required: true,
            max_value: 999,
            min_value: 1,
            excluded: [
              ...form.slice(0, index),
              ...form.slice(index + 1)
            ].map(x => moment.duration(x.expirationLeadPeriod).asDays())
          }"
          :custom-messages="{
            excluded: 'The Days field must be unique'
          }"
        >
          <b-field
            :message="errors.length ? errors : null"
            :type="{ 'is-danger': !!errors.length }"
            class="is-marginless"
          >
            <b-input
              :value="form[index].expirationLeadPeriod && moment.duration(form[index].expirationLeadPeriod).asDays()"
              type="number"
              placeholder="0"
              style="z-index: 1"
              @input="(day) => updateExpirationLeadPeriod(day, index)"
            />
            <p class="control">
              <span class="has-text-weight-bold button is-static">Days</span>
            </p>
          </b-field>
          <b-button class="is-transparent" @click="removeNotification(index)">
            <b-icon icon="trash-alt" type="is-danger" />
          </b-button>
        </validation-provider>
      </div>

      <div class="buttons">
        <b-button
          icon-left="plus"
          type="is-primary is-light"
          @click="addNotification"
        >
          Notification
        </b-button>
        <b-button
          v-if="form.length"
          icon-left="trash-alt"
          type="is-danger is-light"
          outlined
          @click="removeAllNotifications"
        >
          Clear All Notifications
        </b-button>
      </div>
    </panel>
  </validated-form>
</template>

<script>
  import OfferNotification from '@/store/classes/OfferNotification';
  import getChangedResources from '@/helpers/get-changed-resources';
  import moment from 'moment-timezone';

  export default {
    name: 'OfferConfiguration',
    data() {
      return {
        form: [],
        moment
      };
    },

    computed: {
      isFetching() {
        return OfferNotification.$state().fetching;
      },

      isSubmitting() {
        return OfferNotification.$state().submitting || OfferNotification.$state().deleting;
      },

      offerNotifications() {
        return OfferNotification.query()
          .orderBy('parsedExpirationLeadPeriod', 'desc')
          .get();
      }
    },

    created() {
      this.onCreated();
    },

    methods: {
      async onCreated() {
        await OfferNotification.getOfferNotifications();
        this.setForm();
      },

      setForm() {
        this.form = this.$clone(this.offerNotifications);
      },

      addNotification() {
        this.form.push(new OfferNotification());
      },

      removeNotification(index) {
        this.form.splice(index, 1);
      },

      removeAllNotifications() {
        this.form = [];
      },

      updateExpirationLeadPeriod(days, index) {
        this.form[index].expirationLeadPeriod = days ? moment.duration(days, 'days').toISOString() : '';
      },

      async handleSubmit() {
        try {
          const { added, updated, removed } = getChangedResources({
            oldArray: this.offerNotifications,
            newArray: this.form,
            comparisonKey: 'guid',
            updatedComparisonFn: (n, o) => n.expirationLeadPeriod !== o.expirationLeadPeriod
          });

          await Promise.all([
            ...added.map(a => OfferNotification.createOfferNotification(a)),
            ...updated.map(u => OfferNotification.updateOfferNotification(u)),
            ...removed.map(r => OfferNotification.deleteOfferNotification(r))
          ]);

          this.setForm();

          this.$_onRequestSuccess({
            toastOptions: {
              message: 'Offer notifications successfully updated!'
            }
          });
        }
        catch (error) {
          this.$_onRequestError({
            toastOptions: { message: 'There was an issue updating your offer notifications' },
            error
          });
        }
      }
    }
  };
</script>
