<template>
  <transition-group name="fade-down-slow" class="dist-y-sm">
    <criteria-card
      v-if="criteriaIsActiveFor(criteriaCardMetadata.MOST_VISITED_STORE.criteriaKeys)"
      :key="`${criteriaCardMetadata.MOST_VISITED_STORE.defaultCriteriaKey}-criteria-card`"
      :criteria="criteriaCardMetadata.MOST_VISITED_STORE"
      @remove-criteria="$emit('remove-criteria', $event)"
    >
      <validated-input
        label="Most Visited Store"
        name="mostVisitedStore"
        hide-label
        rules="required"
      >
        <b-dropdown
          :value="value.mostVisitedStoreId"
          position="is-top-right"
          expanded
          max-height="436"
          class="has-extra-shadow"
          scrollable
          aria-role="list"
          @input="handleInput({ mostVisitedStoreId: $event })"
        >
          <dropdown-button
            slot="trigger"
            placeholder="Select Location"
            :value="mostVisitedLocation"
          />
          <div
            v-for="(regionStores, regionAbbr, i) in storesByRegion"
            :key="regionAbbr"
          >
            <hr v-if="i !== 0" class="dropdown-divider">

            <h2 class="title mar-b-none pad-x pad-y-xs is-5">{{ stateProvinceNamesByAbbr[regionAbbr] }}</h2>

            <b-dropdown-item
              v-for="store in regionStores"
              :key="store.storeId"
              class="pad-l-lg"
              aria-role="listitem"
              :value="store.storeId"
            >
              {{ store.description }}
            </b-dropdown-item>
          </div>
        </b-dropdown>
      </validated-input>
    </criteria-card>

    <criteria-card
      v-if="criteriaIsActiveFor(criteriaCardMetadata.LAST_VISITED_STORE.criteriaKeys)"
      :key="`${criteriaCardMetadata.LAST_VISITED_STORE.defaultCriteriaKey}-criteria-card`"
      :criteria="criteriaCardMetadata.LAST_VISITED_STORE"
      @remove-criteria="$emit('remove-criteria', $event)"
    >
      <validated-input
        label="Last Visited Store"
        name="lastVisitedStore"
        hide-label
        rules="required"
      >
        <b-dropdown
          :value="value.lastVisitedStoreId"
          position="is-top-right"
          expanded
          max-height="436"
          class="has-extra-shadow"
          scrollable
          aria-role="list"
          @input="handleInput({ lastVisitedStoreId: $event })"
        >
          <dropdown-button
            slot="trigger"
            placeholder="Select Location"
            :value="lastVisitedLocation"
          />
          <div
            v-for="(regionStores, regionAbbr, i) in storesByRegion"
            :key="regionAbbr"
          >
            <hr v-if="i !== 0" class="dropdown-divider">

            <h2 class="title mar-b-none pad-x pad-y-xs is-5">{{ stateProvinceNamesByAbbr[regionAbbr] }}</h2>

            <b-dropdown-item
              v-for="store in regionStores"
              :key="store.storeId"
              class="pad-l-lg"
              aria-role="listitem"
              :value="store.storeId"
            >
              {{ store.description }}
            </b-dropdown-item>
          </div>
        </b-dropdown>
      </validated-input>
    </criteria-card>

    <criteria-card
      v-if="criteriaIsActiveFor(criteriaCardMetadata.FAVORITE_STORE.criteriaKeys)"
      :key="`${criteriaCardMetadata.FAVORITE_STORE.defaultCriteriaKey}-criteria-card`"
      :criteria="criteriaCardMetadata.FAVORITE_STORE"
      @remove-criteria="$emit('remove-criteria', $event)"
    >
      <validated-input
        label="Favorite Store"
        name="favoriteStore"
        hide-label
        rules="required"
      >
        <b-dropdown
          :value="value.favoriteStoreId"
          position="is-top-right"
          expanded
          max-height="436"
          class="has-extra-shadow"
          scrollable
          aria-role="list"
          @input="handleInput({ favoriteStoreId: $event })"
        >
          <dropdown-button
            slot="trigger"
            placeholder="Select Location"
            :value="favoriteLocation"
          />

          <template v-if="favoritedStoreIds.length === 0">
            <b-dropdown-item disabled>There are no favorite stores yet.</b-dropdown-item>
          </template>

          <template v-else>
            <div
              v-for="(regionStores, regionAbbr, i) in storesByRegion"
              :key="regionAbbr"
            >
              <hr v-if="i !== 0" class="dropdown-divider">

              <h2 class="title mar-b-none pad-x pad-y-xs is-5">{{ stateProvinceNamesByAbbr[regionAbbr] }}</h2>

              <b-dropdown-item
                v-for="store in regionStores"
                :key="store.storeId"
                class="pad-l-lg"
                aria-role="listitem"
                :value="store.storeId"
                :disabled="!favoritedStoreIds.includes(store.storeId)"
              >
                {{ store.description }}
              </b-dropdown-item>
            </div>
          </template>
        </b-dropdown>
      </validated-input>
    </criteria-card>

    <criteria-card
      v-if="criteriaIsActiveFor([criteriaCardMetadata.REGISTERED_DATE_RANGE.criteriaKeys])"
      :key="`${criteriaCardMetadata.REGISTERED_DATE_RANGE.defaultCriteriaKey}-criteria-card`"
      :criteria="criteriaCardMetadata.REGISTERED_DATE_RANGE"
      @remove-criteria="$emit('remove-criteria', $event)"
    >
      <div class="is-flex align-center gap-sm">
        Guest registered between
        <validated-input
          name="startDate"
          label="Start Date"
          hide-label
          :rules="{ required: true }"
          class="mar-none"
        >
          <b-datepicker
            v-model="registrationBeginDate"
            :years-range="dys_ranges.registrationBeginDate"
            placeholder="Start Date"
            icon="calendar-alt"
            :nearby-selectable-month-days="true"
            :mobile-native="false"
            position="is-top-right"
            @change-year="(year) => $_handleYearChange({ year, type: 'registrationBeginDate' })"
          />
        </validated-input>
        and
        <validated-input
          name="endDate"
          label="End Date"
          hide-label
          :rules="{
            required: true,
            greaterThan: '@startDate'
          }"
          :custom-messages="{
            greaterThan: 'End Date must come after Start Date'
          }"
          class="mar-none"
        >
          <b-datepicker
            v-model="registrationEndDate"
            placeholder="End Date"
            :years-range="dys_ranges.registrationEndDate"
            icon="calendar-alt"
            :nearby-selectable-month-days="true"
            :mobile-native="false"
            position="is-top-right"
            @change-year="(year) => $_handleYearChange({ year, type: 'registrationEndDate' })"
          />
        </validated-input>
      </div>
    </criteria-card>

    <criteria-card
      v-if="criteriaIsActiveFor([criteriaCardMetadata.BIRTHDAY_DATE_RANGE.criteriaKeys])"
      :key="`${criteriaCardMetadata.BIRTHDAY_DATE_RANGE.defaultCriteriaKey}-criteria-card`"
      :criteria="criteriaCardMetadata.BIRTHDAY_DATE_RANGE"
      @remove-criteria="$emit('remove-criteria', $event)"
    >
      <div class="is-flex align-center gap-sm">
        Guest's birthday is between
        <validated-input
          name="beginMonthNumber"
          label="Start Month"
          hide-label
          :rules="{ required: true }"
          class="mar-none"
        >
          <b-select
            v-model="birthdayMonthBeginNumber"
          >
            <option
              v-for="month in Object.values(monthsWithDays)"
              :key="month.value"
              :value="month.value"
            >
              {{ month.display }}
            </option>
          </b-select>
        </validated-input>
        <validated-input
          name="beginDayNumber"
          label="Start Day"
          hide-label
          :rules="{ required: true }"
          class="mar-none"
        >
          <b-select
            :value="value.birthdayDateRange.beginDayNumber"
            @input="handleInput({ birthdayDateRange: { ...value.birthdayDateRange, beginDayNumber: $event } })"
          >
            <option
              v-for="day in monthsWithDays[value.birthdayDateRange.beginMonthNumber].days"
              :key="day"
              :value="day"
            >
              {{ day }}
            </option>
          </b-select>
        </validated-input>
        and
        <validated-input
          name="endMonthNumber"
          label="End Month"
          hide-label
          :rules="{ required: true }"
          class="mar-none"
        >
          <b-select
            v-model="birthdayMonthEndNumber"
          >
            <option
              v-for="month in Object.values(monthsWithDays)"
              :key="month.value"
              :value="month.value"
            >
              {{ month.display }}
            </option>
          </b-select>
        </validated-input>
        <validated-input
          name="endDayNumber"
          label="End Day"
          hide-label
          :rules="{ required: true }"
          class="mar-none"
        >
          <b-select
            :value="value.birthdayDateRange.endDayNumber"
            @input="handleInput({ birthdayDateRange: { ...value.birthdayDateRange, endDayNumber: $event } })"
          >
            <option
              v-for="day in monthsWithDays[value.birthdayDateRange.endMonthNumber].days"
              :key="day"
              :value="day"
            >
              {{ day }}
            </option>
          </b-select>
        </validated-input>
      </div>
    </criteria-card>

    <criteria-card
      v-if="criteriaIsActiveFor([criteriaCardMetadata.DAYS_SINCE_REGISTERED.criteriaKeys])"
      :key="`${criteriaCardMetadata.DAYS_SINCE_REGISTERED.defaultCriteriaKey}-criteria-card`"
      :criteria="criteriaCardMetadata.DAYS_SINCE_REGISTERED"
      @remove-criteria="(event) => {
        $emit('remove-criteria', event);
      }"
    >
      <div class="is-flex align-center gap-sm">
        Guest registered
        <validated-input
          :rules="{
            required: true,
            min_value: 1
          }"
          name="daysSinceRegistered"
          label="Duration"
          hide-label
          class="mar-none"
        >
          <b-numberinput
            :value="daysSinceRegisteredDuration"
            controls-position="compact"
            min="1"
            data-test-id="duration-input"
            @input="handleDurationChange({ duration: $event, criteriaKey: 'daysSinceRegistered' })"
          />
        </validated-input>
        <b-select
          :value="daysSinceRegisteredDurationType"
          data-test-id="duration-type-select"
          @input="($event) => handleDurationTypeChange({ durationType: $event, criteriaKey: 'daysSinceRegistered'})"
        >
          <option
            v-for="dt in getDurationTypes(daysSinceRegisteredDuration)"
            :key="dt.value"
            :value="dt.value"
          >
            {{ dt.display }}
          </option>
        </b-select>
        ago
        <span v-if="daysSinceRegisteredDurationType !== 'day'" class="has-text-grey is-size-7">
          ({{ value.daysSinceRegistered }} Days)
        </span>
      </div>
    </criteria-card>

    <criteria-card
      v-if="criteriaIsActiveFor([criteriaCardMetadata.DAYS_SINCE_LAST_LOGIN.criteriaKeys])"
      :key="`${criteriaCardMetadata.DAYS_SINCE_LAST_LOGIN.defaultCriteriaKey}-criteria-card`"
      :criteria="criteriaCardMetadata.DAYS_SINCE_LAST_LOGIN"
      @remove-criteria="(event) => {
        $emit('remove-criteria', event);
      }"
    >
      <div class="is-flex align-center gap-sm">
        Guest last logged in
        <validated-input
          :rules="{
            required: true,
            min_value: 1
          }"
          name="daysSinceLastLogin"
          label="Duration"
          hide-label
          class="mar-none"
        >
          <b-numberinput
            :value="daysSinceLastLoginDuration"
            controls-position="compact"
            min="1"
            data-test-id="duration-input"
            @input="handleDurationChange({ duration: $event, criteriaKey: 'daysSinceLastLogin'})"
          />
        </validated-input>
        <b-select
          :value="daysSinceLastLoginDurationType"
          data-test-id="duration-type-select"
          @input="($event) => handleDurationTypeChange({ durationType: $event, criteriaKey: 'daysSinceLastLogin'})"
        >
          <option
            v-for="dt in getDurationTypes(daysSinceLastLoginDuration)"
            :key="dt.value"
            :value="dt.value"
          >
            {{ dt.display }}
          </option>
        </b-select>
        ago
        <span v-if="daysSinceLastLoginDurationType !== 'day'" class="has-text-grey is-size-7">
          ({{ value.daysSinceLastLogin }} Days)
        </span>
      </div>
    </criteria-card>

    <criteria-card
      v-if="criteriaIsActiveFor(criteriaCardMetadata.ORDER_PLACED_AT_STORE.criteriaKeys)"
      :key="`${criteriaCardMetadata.ORDER_PLACED_AT_STORE.defaultCriteriaKey}-criteria-card`"
      :criteria="criteriaCardMetadata.ORDER_PLACED_AT_STORE"
      @remove-criteria="$emit('remove-criteria', $event)"
    >
      <div class="is-flex align-center gap-sm mar-b-lg">
        Guest has visited
        <validated-input
          label="Visited Store"
          name="visitedStore"
          hide-label
          class="mar-none"
        >
          <regional-location-dropdown
            v-model="hasVisitedStoreIds"
            :stores="stores"
            sub-label="Select location(s)"
            select-all-mode="includeAll"
            hide-label
            required
          />
        </validated-input>
        during the
        <validated-input
          :rules="{ required: true }"
          hide-label
          name="orderPlacedAtStoreDaysBackType"
          label="Time Frame"
          class="mar-none"
        >
          <b-select
            v-model="daysBackType"
            data-test-id="order-placed-at-store-days-back-type"
            placeholder="Select a time frame..."
            class="mar-none"
            icon="calendar-days"
          >
            <option :value="null" disabled>-</option>
            <option v-for="dbType in Object.values(daysBackTypeExcludingLifetime)" :key="dbType.value" :value="dbType.value">
              {{ dbType.display }}
            </option>
          </b-select>
        </validated-input>
      </div>
    </criteria-card>

    <criteria-card
      v-if="criteriaIsActiveFor(criteriaCardMetadata.OPERATING_SYSTEM.criteriaKeys)"
      :key="`${criteriaCardMetadata.OPERATING_SYSTEM.defaultCriteriaKey}-criteria-card`"
      :criteria="criteriaCardMetadata.OPERATING_SYSTEM"
      @remove-criteria="$emit('remove-criteria', $event)"
    >
      <validated-input
        label="Mobile Operating System"
        name="operatingSystem"
        hide-label
        rules="required"
        :custom-messages="{
          required: 'At least one mobile operating system is required'
        }"
      >
        <div class="is-flex align-center gap-sm">
          Guest uses the app on an
          <check-button
            v-for="option in operatingSystemOptions"
            :key="option"
            v-model="selectedOperatingSystem"
            :native-value="option"
          >
            {{ option }}
          </check-button>
          device
        </div>
      </validated-input>
    </criteria-card>
  </transition-group>
</template>

<script>
  import moment from 'moment-timezone';

  import dynamicYearSelectMixin from '@/mixins/dynamic-year-select';
  import { criteriaCardMetadata, daysBackTypes } from '@/constants/segmentations';
  import caProvinces from '@/helpers/ca-provinces';
  import usStates from '@/helpers/us-states';

  import CriteriaCard from './criteria-card.vue';
  import Store from '@/store/classes/Store';
  import { monthsWithDays } from '@/helpers/dates';
  import MerchantAppSetting from '@/store/classes/MerchantAppSetting';


  export default {
    name: 'AccountCriteriaForm',

    components: { CriteriaCard },

    mixins: [dynamicYearSelectMixin],

    props: {
      value: {
        type: Object,
        required: true
      }
    },

    data() {
      return {
        criteriaCardMetadata,
        daysBackTypes,
        daysSinceRegisteredDurationType: 'day',
        daysSinceLastLoginDurationType: 'day',
        daysSinceLastLoginDuration: 1,
        daysSinceRegisteredDuration: 1,
        monthsWithDays
      };
    },

    computed: {
      birthdayMonthBeginNumber: {
        get() {
          return this.value.birthdayDateRange.beginMonthNumber;
        },
        set(val) {
          const beginDayNumber = this.monthsWithDays[val]?.days.includes(this.value.birthdayDateRange.beginDayNumber)
            ? this.value.birthdayDateRange.beginDayNumber
            : 1;

          this.handleInput({
            birthdayDateRange: {
              ...this.value.birthdayDateRange,
              beginDayNumber,
              beginMonthNumber: val
            }
          });
        }
      },

      birthdayMonthEndNumber: {
        get() {
          return this.value.birthdayDateRange.endMonthNumber;
        },
        set(val) {
          const endDayNumber = this.monthsWithDays[val]?.days.includes(this.value.birthdayDateRange.endDayNumber)
            ? this.value.birthdayDateRange.endDayNumber
            : 1;

          this.handleInput({
            birthdayDateRange: {
              ...this.value.birthdayDateRange,
              endDayNumber,
              endMonthNumber: val
            }
          });
        }
      },

      mostVisitedLocation() {
        return this.value.mostVisitedStoreId && `${this.stores.find(s => s.storeId === this.value.mostVisitedStoreId)?.description}`;
      },

      lastVisitedLocation() {
        return this.value.lastVisitedStoreId && `${this.stores.find(s => s.storeId === this.value.lastVisitedStoreId)?.description}`;
      },

      favoriteLocation() {
        return this.value.favoriteStoreId && `${this.stores.find(s => s.storeId === this.value.favoriteStoreId)?.description}`;
      },

      favoritedStoreIds() {
        return Store.favoritedStores().get()?.map(s => s.storeId);
      },

      daysBackTypeExcludingLifetime() {
        const { LIFETIME, ...remaining } = daysBackTypes;
        return remaining;
      },

      hasVisitedStoreIds: {
        get() {
          return this.value.orderPlacedAtStore.storeIds;
        },
        set(val) {
          return this.handleInput({
            orderPlacedAtStore: {
              ...this.value.orderPlacedAtStore,
              storeIds: val
            }
          });
        }
      },

      daysBackType: {
        get() {
          return this.value.orderPlacedAtStore.daysBackType;
        },

        set(val) {
          return this.handleInput({
            orderPlacedAtStore: {
              ...this.value.orderPlacedAtStore,
              daysBackType: val
            }
          });
        }
      },

      registrationBeginDate: {
        get() {
          if (this.value.registeredDateRange?.beginDate) {
            const dateString = moment.utc(this.value.registeredDateRange.beginDate).format('MM-DD-YYYY');
            return moment(dateString, 'MM-DD-YYYY').startOf('day').toDate();
          }
          else {
            return null;
          }
        },
        set(val) {
          this.handleInput({
            registeredDateRange: {
              ...this.value.registeredDateRange,
              beginDate: this.getISOStringDates({ begin: val }).begin
            }
          });
        }
      },

      registrationEndDate: {
        get() {
          if (this.value.registeredDateRange?.endDate) {
            const dateString = moment.utc(this.value.registeredDateRange.endDate).format('MM-DD-YYYY');
            return moment(dateString, 'MM-DD-YYYY').endOf('day').toDate();
          }
          else {
            return null;
          }
        },
        set(val) {
          this.handleInput({
            registeredDateRange: {
              ...this.value.registeredDateRange,
              endDate: this.getISOStringDates({ end: val }).end
            }
          });
        }
      },

      stateProvinceNamesByAbbr() {
        return [...usStates, ...caProvinces].reduce((acc, region) => {
          acc[region.abbr] = region.name;
          return acc;
        }, {});
      },

      stores() {
        return Store.orderByName().get();
      },

      storesByRegion() {
        return this.stores.reduce((acc, store) => {
          if (acc[store.region]) acc[store.region].push(store);
          else acc[store.region] = [store];
          return acc;
        }, {});
      },

      selectedOperatingSystem: {
        get() {
          if (!this.value.operatingSystem) return [];

          return this.value.operatingSystem === 'IosAndAndroid'
            ? ['Ios', 'Android']
            : [this.value.operatingSystem];
        },
        set(newValue) {
          if (newValue.length) {
            this.handleInput({
              operatingSystem: newValue.length === 2 ? 'IosAndAndroid' : newValue[0]
            });
          }
          else {
            this.handleInput({ operatingSystem: false });
          }
        }
      },

      operatingSystemOptions() {
        return MerchantAppSetting.configuredMobileAppTypesByName();
      }
    },

    created() {
      this.onCreated();
    },

    methods: {
      onCreated() {
        if (this.value.daysSinceRegistered) {
          this.daysSinceRegisteredDuration = this.value.daysSinceRegistered;
        }

        if (this.value.daysSinceLastLogin) {
          this.daysSinceLastLoginDuration = this.value.daysSinceLastLogin;
        }
      },

      handleInput(payload) {
        this.$emit('handle-input', payload);
      },

      getDurationTypes(duration) {
        return [
          { value: 'day', display: 'Day' },
          { value: 'week', display: 'Week' },
          { value: 'month', display: 'Month' },
          { value: 'year', display: 'Year' }
        ].map(type => ({
          ...type,
          display: `${type.display}${duration > 1 ? 's' : ''}`
        }));
      },

      criteriaIsActiveFor(criteriaKeys) {
        return criteriaKeys.some(key => this.value[key] !== undefined && this.value[key] !== null); // `false` could be a valid value
      },

      getISOStringDates({ begin, end }) {
        const beginDateString = begin && moment.utc(begin).format('MM-DD-YYYY');
        const endDateString = end && moment.utc(end).format('MM-DD-YYYY');
        return {
          begin: begin && moment.utc(beginDateString, 'MM-DD-YYYY').startOf('day').toISOString(),
          end: end && moment.utc(endDateString, 'MM-DD-YYYY').endOf('day').toISOString()
        };
      },

      handleDurationChange({ duration, criteriaKey }) {
        let computed;
        const durationType = this[`${criteriaKey}DurationType`];

        switch (durationType) {
          case 'year':
            computed = duration * 365;
            break;
          case 'month':
            computed = duration * 30;
            break;
          case 'week':
            computed = duration * 7;
            break;
          default:
            computed = duration;
            break;
        }

        this.handleInput({ [criteriaKey]: computed });
        this[`${criteriaKey}Duration`] = duration;
      },

      handleDurationTypeChange({ criteriaKey, durationType }) {
        const duration = this[`${criteriaKey}Duration`];
        const durationTypeKey = `${criteriaKey}DurationType`;
        this[durationTypeKey] = durationType;
        this.handleDurationChange({ duration, durationType, criteriaKey });
      }
    }
  };
</script>
