<template>
  <modal-card
    :title="`Add ${modifierGroup.displayName} Modifier Group to Categories & Items`"
    subtitle="Select the Categories and Items add this Modifier Group to"
    show-small-subtitle
    modal-card-body-class="pad-none"
    class="is-full-height"
  >
    <menu-resource-select
      :selected-item-ids="selectedItemIds"
      @item-selected="itemIds => selectedItemIds = itemIds"
      @select="updateSelectedCounts"
    />

    <template #footer>
      <p class="mar-r-xl">
        <span class="has-text-weight-bold mar-r-xs">Selected:</span>
        {{ selectedCategoryCount }} Categories
        <span class="mar-x-sm has-text-grey-light has-text-weight-bold">|</span>
        {{ selectedItemCount }} Items
      </p>

      <div class="buttons all-bold">
        <b-button
          rounded
          @click="$parent.close()"
        >
          Cancel
        </b-button>
        <b-button
          rounded
          type="is-primary"
          :loading="submitting"
          :disabled="!selectedItemIds.length"
          @click="handleItemSelection"
        >
          Update
        </b-button>
      </div>
    </template>
  </modal-card>
</template>

<script>
  import capitalCase from '@/helpers/capitalCase';
  import getChangedResources from '@/helpers/get-changed-resources';

  import MenuItemModifierGroupItem from '@/store/classes/MenuItemModifierGroupItem';

  export default {
    name: 'BulkManageModifierGroupItemAssociationModal',

    props: {
      modifierGroup: {
        type: Object,
        required: true
      }
    },

    data() {
      return {
        selectedCategoryCount: '',
        selectedItemCount: '',
        selectedItemIds: [],
        capitalCase
      };
    },

    computed: {
      submitting() {
        return MenuItemModifierGroupItem.$state().submitting;
      }
    },

    created() {
      this.onCreated();
    },

    methods: {
      onCreated() {
        this.selectedItemIds = this.modifierGroup.menuItemModifierGroupItems.map(menuItemModifierGroupItem => menuItemModifierGroupItem.menuItemId);
      },

      updateSelectedCounts(counts) {
        this.selectedCategoryCount = counts.categories;
        this.selectedItemCount = counts.items;
      },

      async handleItemSelection() {
        const { added, removed } = getChangedResources({
          oldArray: this.modifierGroup.menuItemModifierGroupItems.map(menuItemModifierGroupItem => menuItemModifierGroupItem.menuItemId),
          newArray: this.selectedItemIds
        });

        const promises = [];

        if (added.length) promises.push(this.addModifierGroupToItems(added));
        if (removed.length) promises.push(this.removeModifierGroupFromItems(removed));

        try {
          let message = '';
          if (added.length && removed.length) {
            message = `Successfully added ${added.length} item${added.length > 1 ? 's' : ''} and removed ${removed.length} item${removed.length > 1 ? 's' : ''} from`;
          }
          else if (added.length) {
            message = `Successfully added ${added.length} item${added.length > 1 ? 's' : ''} to`;
          }
          else {
            message = `Successfully removed ${removed.length} item${removed.length > 1 ? 's' : ''} from`;
          }

          await Promise.all(promises);

          this.$_onRequestSuccess({
            toastOptions: { message: `${message} your ${this.modifierGroup.displayName} Modifier Group` },
            options: { closeParent: true }
          });
        }
        catch (error) {
          this.$_onRequestError({
            error,
            toastOptions: {
              message: error
            }
          });
        }
      },

      async addModifierGroupToItems(newItemIds) {
        try {
          const menuItemModifierGroupItems = newItemIds.map(menuItemId => ({
            menuItemId,
            menuItemModifierGroupId: this.modifierGroup.id
          }));
          return MenuItemModifierGroupItem.createMenuItemModifierGroupItems(menuItemModifierGroupItems);
        }
        catch (error) {
          throw new Error(`There was an error adding items to your ${this.modifierGroup.displayName} Menu`);
        }
      },

      async removeModifierGroupFromItems(removedItemIds) {
        const menuItemModifierGroupItemIdsToDelete = this.modifierGroup.menuItemModifierGroupItems
          .filter(menuItemModifierGroupItem => removedItemIds.includes(menuItemModifierGroupItem.menuItemId))
          .map(menuItemModifierGroupItem => menuItemModifierGroupItem.id);

        try {
          return MenuItemModifierGroupItem.deleteMenuItemModifierGroupItems(menuItemModifierGroupItemIdsToDelete);
        }

        catch (error) {
          throw new Error(`There was an error removing the ${this.modifierGroup.displayName} modifier group from ${menuItemModifierGroupItemIdsToDelete.length} items`);
        }
      }
    }
  };
</script>
