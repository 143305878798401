<template>
  <div class="is-grid gap col-2">
    <div>
      <validation-provider
        v-slot="{ errors }"
        name="discountedItems"
        rules="required"
      >
        <b-checkbox :value="selectedResources.length >= 1 || null" :class="['is-hidden', { 'invalid': errors.length }]" />
        <b-field
          :message="errors.length ? 'You must have at least 1 menu item selected' : ''"
          :type="errors.length ? 'is-danger' : ''"
        >
          <menu-search-input
            :read-only="readOnly"
            resource-type="menuItems"
            :selected-resources="selectedResources"
            multiple
            @add-resource="$emit('add-resource', merchantSupportsMultiPos ? $event : { type: 'menuItems', resource: $event })"
            @remove-resource="$emit('remove-resource', merchantSupportsMultiPos ? $event : { type: 'menuItems', resource: $event })"
          />
        </b-field>
      </validation-provider>
      <modifier-restrictions-search
        v-if="enableModifierRestrictions"
        :read-only="readOnly"
        :selected-resources="selectedResources"
        :required-modifications="requiredModifications"
        @update-required-modifications="$emit('update-required-modifications', $event)"
      />
    </div>
    <div class="is-flex-wrap start-aligned align-content-start gap">
      <div
        v-for="resource in selectedResources"
        :key="generateResourceKey(resource)"
        class="tag is-primary is-light auto-height mar-none pad-y-xs pad-x-sm"
      >
        <div>
          <p class="has-text-weight-bold">{{ resource.displayName }}</p>
          <p v-if="merchantSupportsMultiPos" class="has-text-primary-dark">
            {{ resource.id }}-{{ resource.posType }}
            <!-- resource.posItemIds comes from the processed results, not the original API response -->
            <b-icon
              v-if="resource.posItemIds && resource.posItemIds.length > 1"
              v-tippy="{
                content: resource.posItemIds.join(', '),
                maxWidth: 250,
                delay: [150, 0]
              }"
              icon="tags"
              size="is-small"
              class="mar-l-xs"
            />
          </p>
          <p v-else-if="resource.posItems && resource.posItems.length">
            {{ resource.posItems[0].id }}-{{ posTypesById[resource.posItems[0].posTypeId] }}
            <b-icon
              v-if="resource.posItems.length > 1"
              v-tippy="{
                content: resource.posItems.map(posItem => posItem.id).join(', '),
                maxWidth: 250,
                delay: [150, 0]
              }"
              icon="tags"
              size="is-small"
              class="mar-l-xs"
            />
          </p>
        </div>
        <button
          v-if="!readOnly"
          type="button"
          class="delete mar-none mar-l-sm has-background-primary-dark has-hover-background-primary-medium"
          @click="handleRemoveResource(resource)"
        />
      </div>

      <div
        v-for="resourceId in missingResources"
        :key="resourceId"
        class="tag is-danger is-flex-align-center is-light auto-height mar-none pad-y-sm pad-x-sm"
      >
        <div>
          <p
            v-tippy="{
              content: 'This menu item cannot be found',
              maxWidth: 250,
              delay: [150, 0]
            }"
            class="align-self-start has-text-weight-bold"
          >
            Unknown menu resource
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { posTypesById } from '@/constants/posTypes';
  import merchantMixin from '@/mixins/merchant';

  export default {
    name: 'ItemOfferCard',

    mixins: [merchantMixin],

    props: {
      selectedResources: {
        type: Array,
        default: () => []
      },

      requiredModifications: {
        type: Array,
        default: () => []
      },

      readOnly: {
        type: Boolean,
        required: true
      },

      enableModifierRestrictions: {
        type: Boolean,
        required: true
      },

      missingResources: {
        type: Array,
        default: () => []
      }
    },

    data() {
      return {
        posTypesById
      };
    },

    computed: {
      merchantSupportsMultiPos() {
        return this.$_selectedMerchant.features.supportsMultiPosConstraintsAndValuePropositions;
      }
    },

    methods: {
      generateResourceKey(resource) {
        if (this.merchantSupportsMultiPos) {
          return `${resource.menuItemId}_${resource.posTypeId}_${resource.id}`;
        }
        return resource.id;
      },

      handleRemoveResource(resource) {
        if (this.merchantSupportsMultiPos) {
          this.$emit('remove-resource', resource);
        }
        else {
          this.$emit('remove-resource', { type: 'menuItems', resource });
        }
      }
    }
  };
</script>
