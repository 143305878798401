<template>
  <div>
    <review-row
      v-for="(row, idx) in timeFrameRows"
      :key="`reviewRow${idx}`"
      :title="row.title"
      :subtitle="row.subtitle"
      :hide-bottom-border="idx === timeFrameRows.length - 1"
    />
  </div>
</template>

<script>
  import moment from 'moment-timezone';

  import daysOfWeek from '@/constants/daysOfWeek';
  import { constraintModels } from '@/constants/earnRules';

  export default {
    name: 'TimeFrameCard',

    props: {
      value: {
        type: Object,
        required: true
      }
    },

    computed: {
      timeFrameRows() {
        return [
          { title: 'Earn Rule Runs', subtitle: this.timeFrameDisplay },
          { title: 'Valid On', subtitle: this.dayOfWeekDisplay },
          { title: 'Time of Day', subtitle: this.timeOfDayDisplay }
        ];
      },

      timeFrameDisplay() {
        const {
          startDate,
          endDate
        } = this.value;

        const formattedStartDate = startDate && moment(startDate).format('MMM DD, YYYY');
        if (endDate) {
          const formattedEndDate = moment(endDate).format('MMM DD, YYYY');
          return !startDate ? '<Not set>' : `From ${formattedStartDate} to ${formattedEndDate}`;
        }
        else {
          return !startDate ? '<Not set>' : `Starting ${formattedStartDate}`;
        }
      },

      dayOfWeekConstraint() {
        return this.getConstraintByType(constraintModels.DAY_OF_WEEK.type);
      },

      timeOfDayConstraint() {
        return this.getConstraintByType(constraintModels.TIME_OF_DAY.type);
      },

      dayOfWeekDisplay() {
        if (this.dayOfWeekConstraint) {
          const days = [];
          Object.keys(daysOfWeek).forEach((key) => {
            if (this.dayOfWeekConstraint.validDays.includes(daysOfWeek[key])) {
              days.push(key);
            }
          });
          return days.join(', ');
        }
        else {
          return 'Every Day';
        }
      },

      timeOfDayDisplay() {
        if (this.timeOfDayConstraint?.startTime && this.timeOfDayConstraint?.endTime) {
          const formatTime = time => moment(time, 'hh:mm').format('h:mm A');
          return `${formatTime(this.timeOfDayConstraint.startTime)} - ${formatTime(this.timeOfDayConstraint.endTime)}`;
        }
        else {
          return 'All Day';
        }
      }
    },

    methods: {
      getConstraintByType(constraintType) {
        return this.value.earnRuleConstraints.find(c => c.constraintType === constraintType);
      }
    }
  };
</script>
