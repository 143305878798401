<template>
  <div class="is-grid gap-lg pad">
    <validated-text-input
      v-model="earnRuleConstraint.minimumQualifyingCount"
      class="minimum-qualifying-count"
      icon="hashtag"
      label="Minimum Quantity"
      tooltip="Total amount of these items that must be purchased for this offer to be valid"
      :name="`minimumQualifyingCount${earnRuleConstraint.count}}`"
      type="number"
      :disabled="readOnly"
      :rules="{ required: true, min_value: 1 }"
      data-test-id="minimum-qualifying-count-input"
      @input="$emit('update-constraint', { ...earnRuleConstraint, minimumQualifyingCount: Number($event) })"
    />
    <menu-search-form
      v-if="earnRuleConstraint.itemGroup"
      :item-group="earnRuleConstraint.itemGroup"
      :read-only="readOnly"
      :form-id="earnRuleConstraint.formId"
    />
  </div>
</template>

<script>
  import { constraintTypes } from '@/constants/offers';
  import { itemAttributes, itemGroupTypes, itemResourceTypes } from '@/constants/items';


  export default {
    name: 'SpecifiedItemGroupCard',

    props: {
      earnRuleConstraint: {
        type: Object,
        required: true
      },

      readOnly: {
        type: Boolean,
        default: false
      }
    },

    data() {
      return {
        constraintTypes,
        itemAttributes,
        itemGroupTypes,
        itemResourceTypes
      };
    }
  };
</script>

<style lang="sass" scoped>
  .minimum-qualifying-count
    width: 12.6em
</style>
