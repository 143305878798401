<template>
  <div v-if="$_selectedMerchant">
    <hero-banner>
      <h1 class="title is-2">Campaigns</h1>
      <template #right>
        <b-button
          v-if="campaigns.length"
          rounded
          inverted
          size="is-medium"
          type="is-primary"
          icon-left="plus"
          @click="openCampaignModal()"
        >
          New Campaign
        </b-button>
      </template>
    </hero-banner>

    <div class="section">
      <div class="container">
        <div v-if="campaigns.length" class="card">
          <b-loading v-if="isFetching || isDeleting" :active="isFetching || isDeleting" />
          <searchable-table
            v-slot="{ filteredData }"
            :table-data="campaigns"
            :search="{
              placeholder: 'Search by Campaign Name',
              keys: ['internalName']
            }"
            :filters="tableFilters"
          >
            <b-table
              :data="isFetching ? [] : filteredData"
              :paginated="campaigns.length > pageLimit"
              class="is-middle-aligned"
              pagination-simple
              :per-page="pageLimit"
              :mobile-cards="false"
              scrollable
              :default-sort="['updatedAt', 'desc']"
              @sort="handleSort"
            >

              <b-table-column
                v-slot="{ row }"
                sortable
                field="internalName"
                label="Name"
                cell-class="no-wrap-text"
              >
                <span class="link" @click="openCampaignModal(row)">
                  {{ row.internalName || 'N/A' }}
                </span>
                <div class="table-cell-text-overflow">
                  <p :class="['is-size-7', {'has-text-grey-light': !row.description}]">
                    {{ row.description || 'N/A' }}
                  </p>
                </div>
              </b-table-column>

              <!-- <b-table-column v-slot="{ row: { segmentation } }" field="segmentation" label="Segmentation">
                {{ segmentation || 'N/A' }}
              </b-table-column> -->

              <b-table-column
                sortable
                field="startDate"
                :custom-sort="sortByStartDate"
                cell-class="no-wrap-text"
              >
                <template #header>
                  Schedule <span class="has-text-weight-normal has-text-grey">({{ moment.tz.zone($_selectedMerchant.features.campaignsIanaTimezoneId).abbr(Date.now()) }})</span>
                  <b-icon v-if="sortField === 'startDate'" icon="arrow-up" :class="sortDirection === 'desc' && 'flip-arrow'" />
                </template>
                <template v-slot="{ row: { startDate, deliveryType, state, updatedAt } }">
                  <template v-if="deliveryType === campaignDeliveryTypes.IMMEDIATE">
                    <span v-if="state === campaignStates.COMPLETE">
                      <b>Sent</b> on
                      {{ updatedAt | moment('MM/DD/YYYY @ hh:mm A z', $_selectedMerchant.features.campaignsIanaTimezoneId) }}
                    </span>
                    <span v-else>
                      <b>Sends</b> Immediately
                    </span>
                  </template>
                  <p v-else-if="startDate" :class="state === campaignStates.CANCELLED && 'has-text-strikethrough'">
                    <b>{{ state === campaignStates.COMPLETE ? 'Sent' : 'Sends' }}</b> on
                    <span
                      v-tippy="{
                        content: moment.tz(startDate, moment.tz.guess()).format('MM/DD/YYYY @ hh:mm A z'),
                        delay: [1000, 0]
                      }"
                    >
                      {{ startDate | moment('MM/DD/YYYY @ hh:mm A z', $_selectedMerchant.features.campaignsIanaTimezoneId) }}
                    </span>
                  </p>
                  <span v-else class="has-text-grey">N/A</span>
                </template>
              </b-table-column>

              <b-table-column
                sortable
                field="updatedAt"
                label="Last Updated"
                cell-class="no-wrap-text"
              >
                <!-- <template #header>
                  Last Updated <span class="has-text-weight-normal has-text-grey">({{ moment.tz.zone($_selectedMerchant.features.campaignsIanaTimezoneId).abbr(Date.now()) }})</span>
                  <b-icon v-if="sortField === 'updatedAt'" icon="arrow-up" :class="sortDirection === 'desc' && 'flip-arrow'" />
                </template> -->
                <template v-slot="{ row: { updatedAt } }">
                  <span v-if="updatedAt">
                    {{ updatedAt | moment('MM/DD/YYYY @ hh:mm A') }}
                  </span>
                  <span v-else class="has-text-grey">N/A</span>
                </template>
              </b-table-column>

              <b-table-column
                field="guid"
                label="ID"
                cell-class="no-wrap-text is-monospaced"
                sortable
              >
                <template v-slot="{ row: { guid } }">
                  {{ guid }}
                </template>
              </b-table-column>

              <b-table-column
                v-slot="{ row: { state } }"
                sortable
                centered
                :custom-sort="sortByState"
                field="state"
                label="Status"
                width="1"
              >
                <b-tag :class="state">
                  {{ state | capitalize }}
                </b-tag>
              </b-table-column>
              <b-table-column
                v-slot="{ row }"
                centered
                cell-class="actions"
                label="Actions"
                width="1"
                field="Actions"
              >
                <dropdown-menu position="bottom-end" :mobile-modal="false">
                  <b-button slot="trigger" class="is-transparent" type="is-white">
                    <b-icon icon="ellipsis-v" pack="far" size="is-small" />
                  </b-button>

                  <b-dropdown-item @click="openCampaignModal(row)">
                    <b-icon :icon="row.state === campaignStates.DRAFT ? 'pencil' : 'eye'" class="mar-r-sm" size="is-small" />
                    {{ row.state === campaignStates.DRAFT ? 'Edit' : 'View' }} Campaign
                  </b-dropdown-item>
                  <hr class="dropdown-divider">
                  <b-dropdown-item :disabled="![campaignStates.SCHEDULED, campaignStates.LIVE].includes(row.state)" class="is-danger" @click="confirmCancel(row)">
                    <b-icon icon="calendar-times" class="mar-r-sm" size="is-small" />
                    Cancel Campaign
                  </b-dropdown-item>
                  <b-dropdown-item
                    :disabled="!isCampaignDeletable(row.state)"
                    class="is-danger"
                    @click="openConfirmCampaignDeletion(row)"
                  >
                    <b-icon
                      size="is-small"
                      type="is-danger"
                      class="mar-r-sm"
                      icon="trash-alt"
                    />
                    Delete Campaign
                  </b-dropdown-item>
                </dropdown-menu>
              </b-table-column>

              <template slot="empty">
                <empty-table-loader
                  icon="paper-plane"
                  message="No Campaigns Found..."
                  :loading="isFetching"
                />
              </template>
            </b-table>
          </searchable-table>
        </div>
        <empty-state-card
          v-else
          type="is-primary"
          title="Create Campaign"
          message="Create a new campaign to engage with your customers"
          icon="paper-plane"
        >
          <template #buttons>
            <b-button
              type="is-primary"
              rounded
              icon-left="plus"
              @click="openCampaignModal()"
            >
              New Campaign
            </b-button>
          </template>
        </empty-state-card>
      </div>
    </div>
  </div>
</template>

<script>
  import merchantMixin from '@/mixins/merchant';
  import Campaign from '@/store/classes/Campaign';
  import campaignModal from '@/components/pages/campaigns/campaign-modal.vue';
  import { campaignDeliveryTypes, campaignStates, campaignSegments } from '@/constants/campaigns';
  import capitalCase from '@/helpers/capitalCase';
  import moment from 'moment-timezone';
  import Offer from '@/store/classes/Offer';
  import Segmentation from '@/store/classes/Segmentation';
  import alertModal from '@/components/globals/alert-modal.vue';
  import scrollIndicatorMixin from '@/mixins/scroll-container-indicator';

  export default {
    name: 'CampaignList',


    mixins: [merchantMixin, scrollIndicatorMixin],

    data() {
      return {
        sortField: null,
        sortDirection: null,
        pageLimit: 20,
        campaignStates,
        campaignDeliveryTypes,
        campaignSegments,
        moment
      };
    },

    computed: {
      campaigns() {
        return Campaign
          .query()
          .where('merchantId', this.$_selectedMerchantId)
          .with('campaignsOffers')
          .get();
      },

      tableFilters() {
        return [
          {
            placeholder: 'Status',
            key: 'state',
            options: Object.values(campaignStates).map(state => ({
              name: capitalCase(state),
              value: state
            }))
          }
        ];
      },

      isFetching() {
        return Campaign.$state().fetching
          || Campaign.$state().fetchingTemplates
          || Campaign.$state().fetchingLinks
          || Offer.$state().fetching
          || Segmentation.$state().fetching;
      },

      isDeleting() {
        return Campaign.$state().deleting;
      }
    },

    methods: {
      async onMerchantLoad() {
        await Promise.all([
          this.fetchCampaigns(),
          this.fetchSegmentations(),
          this.fetchOffers()
        ]);
        if (this.campaigns.length) {
          this.$_initScrollIndicatorSmart({
            contentSelector: '.table'
          });
        }
      },

      handleSort(field, direction) {
        this.sortField = field;
        this.sortDirection = direction;
      },

      sortByStartDate(a, b, isAsc) {
        const sort = (a.startDate < b.startDate || (!a.startDate)) ? -1 : 1;
        return isAsc ? sort : (sort * -1);
      },

      confirmCancel(campaign) {
        const { campaignsIanaTimezoneId } = this.$_selectedMerchant.features;
        const scheduledDate = moment.tz(campaign.startDate, campaignsIanaTimezoneId).format('MMM DD, YYYY');
        const scheduledTime = moment.tz(campaign.startDate, campaignsIanaTimezoneId).format('h:mm A (z)');

        this.$buefy.modal.open({
          parent: this,
          component: alertModal,
          hasModalCard: true,
          trapFocus: true,
          canCancel: ['escape', 'outside'],
          props: {
            icon: 'calendar-times',
            title: 'Cancel Campaign',
            message: `The <b>${campaign.internalName}</b> campaign is scheduled to be delivered on <b>${scheduledDate}</b> at <b>${scheduledTime}</b>`,
            secondaryMessage: 'Are you sure you\'d like to cancel this campaign?',
            type: 'is-danger',
            horizontal: true,
            showCloseButton: false,
            buttons: [
              { text: 'No' },
              { text: 'Yes, Cancel Campaign', primary: true, onClick: () => this.cancelCampaign(campaign.guid) }
            ]
          }
        });
      },

      openConfirmCampaignDeletion(campaign) {
        this.$buefy.dialog.confirm({
          title: 'Delete Campaign',
          message: `Are you sure you want to delete ${campaign.internalName ? `the <b>${campaign.internalName}</b>` : 'this'} campaign?`,
          type: 'is-danger',
          hasIcon: true,
          icon: 'trash-alt',
          confirmText: 'Delete',
          cancelText: 'Cancel',
          onConfirm: () => this.deleteCampaign(campaign.guid)
        });
      },

      async cancelCampaign(campaignGuid) {
        try {
          await Campaign.cancelCampaign(campaignGuid);

          this.$_onRequestSuccess({
            toastOptions: {
              message: 'Successfully cancelled your campaign!'
            }
          });
        }

        catch (error) {
          this.$_onRequestError({
            toastOptions: {
              message: 'An error occured while cancelling your campaign'
            },
            error
          });
        }
      },

      async deleteCampaign(campaignGuid) {
        try {
          await Campaign.deleteCampaign(campaignGuid);

          this.$_onRequestSuccess({
            toastOptions: {
              message: 'Successfully deleted campaign!'
            }
          });
        }

        catch (error) {
          this.$_onRequestError({
            toastOptions: {
              message: 'An error occured while deleting your campaign'
            },
            error
          });
        }
      },

      isCampaignDeletable(campaignState) {
        return ['draft', 'complete', 'cancelled'].includes(campaignState);
      },

      sortByState(a, b, isAsc) {
        const states = Object.values(campaignStates);
        const sortOrders = states.reduce((acc, type, i) => {
          acc[type] = i + 1;
          return acc;
        }, {});
        if (isAsc) {
          return sortOrders[a.state] < sortOrders[b.state] ? -1 : 1;
        }
        return sortOrders[a.state] < sortOrders[b.state] ? 1 : -1;
      },

      async fetchCampaigns() {
        try {
          await Campaign.fetchCampaigns();
        }
        catch (error) {
          this.$_onRequestError({
            error,
            toastOptions: {
              message: 'There was an error fetching your campaigns'
            }
          });
        }
      },

      async fetchCampaign(campaignId) {
        try {
          await Campaign.fetchCampaign(campaignId);
        }
        catch (error) {
          this.$_onRequestError({
            error,
            toastOptions: {
              message: 'There was an error fetching your campaign'
            }
          });
        }
      },

      async fetchSegmentations() {
        try {
          await Segmentation.fetchSegmentations();
        }
        catch (error) {
          this.$_onRequestError({
            error,
            toastOptions: {
              message: 'There was an error fetching your segmentations'
            }
          });
        }
      },

      async fetchOffers() {
        try {
          await Offer.fetchOffers();
        }
        catch (error) {
          this.$_onRequestError({
            error,
            toastOptions: {
              message: 'There was an error fetching your offers'
            }
          });
        }
      },

      async openCampaignModal(campaign) {
        if (campaign) {
          await this.fetchCampaign(campaign.guid);
        }


        this.$buefy.modal.open({
          parent: this,
          component: campaignModal,
          hasModalCard: true,
          trapFocus: true,
          canCancel: false,
          fullScreen: true,
          props: { campaignGuid: campaign?.guid }
        });
      }
    }
  };
</script>

<style lang="sass" scoped>
  .table-cell-text-overflow
    max-width: 300px
    min-width: 100%
    overflow: hidden
    white-space: nowrap

    p
      overflow: hidden
      text-overflow: ellipsis

  .flip-arrow
    transform: rotate(-180deg)

  ::v-deep
    .tag
      width: 6rem
      font-weight: 700

      &.draft
        background-color: lighten($grey, 43)
        color: darken($grey, 40)
      &.scheduled
        background-color: lighten($warning, 22)
        color: darken($warning, 50)
      &.live
        background-color: lighten($success, 37)
        color: darken($success, 40)
      &.complete
        background-color: lighten($primary, 43)
        color: darken($primary, 40)
      &.cancelled
        background-color: lighten($danger, 33)
        color: darken($danger, 40)
</style>
