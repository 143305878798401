import { Model } from '@vuex-orm/core';


import http from '@/services/http';

export default class MerchantTicketNumberInstruction extends Model {
  static entity = 'merchantTicketNumberInstructions'


  static primaryKey = 'merchantId'


  // FIELDS //////////////////////
  static fields() {
    return {
      id: this.attr(''),
      merchantId: this.attr(''),
      title: this.attr(''),
      description: this.attr('')
    };
  }


  // STATE //////////////////////
  static state() {
    return {
      fetching: false
    };
  }

  static $state() { // TEST ?
    return this.store().state.entities.merchantTicketNumberInstructions;
  }


  // ACTIONS //////////////////////
  static async fetchMerchantTicketNumberInstruction(merchantId) {
    try {
      this.commit((state) => {
        state.fetching = true;
      });

      const response = await http.get(`merchants/${merchantId}/merchant_ticket_number_instructions`);

      this.create({ data: response.data.merchantTicketNumberInstructions });
    }

    catch (error) {
      throw error;
    }

    finally {
      this.commit((state) => {
        state.fetching = false;
      });
    }
  }

  static async updateMerchantTicketNumberInstruction(merchantTicketNumberInstruction) {
    try {
      const response = await http.put(`merchant_ticket_number_instructions/${merchantTicketNumberInstruction.id}`, { merchantTicketNumberInstruction });

      this.update({ data: response.data.merchantTicketNumberInstruction });
    }

    catch (error) {
      throw error;
    }
  }

  static async postMerchantTicketNumberInstruction(merchantTicketNumberInstruction) {
    try {
      const response = await http.post(`merchants/${merchantTicketNumberInstruction.merchantId}/merchant_ticket_number_instructions`, { merchantTicketNumberInstruction });

      this.insert({ data: response.data.merchantTicketNumberInstruction });
    }

    catch (error) {
      throw error;
    }
  }
}
