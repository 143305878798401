export default {
  HOME: 1,
  ORDER_HISTORY: 2,
  FAVORITES: 3,
  GIFT_CARD: 4,
  REWARDS: 5,
  MENU: 6,
  PICK_UP_ORDER_MODE: 7,
  SPECIFIC_ITEM: 8,
  DELIVERY_ORDER_MODE: 9,
  CATERING_ORDER_MODE: 10,
  SHIPPING_ORDER_MODE: 11
};
