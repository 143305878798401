var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('panel',{attrs:{"title":("History (" + (_vm.orderSummaryMetaData.count || 0) + ")"),"collapsible":"","start-open":"","loading":_vm.isFetchingOrderSummaries},scopedSlots:_vm._u([{key:"buttons",fn:function(){return [_c('b-button',{staticClass:"is-bold",attrs:{"type":"is-primary","rounded":"","size":"is-medium"},on:{"click":_vm.openClaimOrderModal}},[_vm._v(" Claim Order ")])]},proxy:true}])},[_c('b-table',{class:[
      'is-middle-aligned no-header-wrap row-clickable'
    ],attrs:{"data":_vm.isFetchingOrderSummaries ? [] : _vm.orderSummaries,"hoverable":"","paginated":"","scrollable":"","total":_vm.orderSummaryMetaData.count,"current-page":_vm.page,"per-page":50,"backend-pagination":"","pagination-position":"bottom","mobile-cards":false,"backend-sorting":true,"default-sort":['order_date', 'desc']},on:{"cellclick":_vm.openOrderModal,"page-change":_vm.handlePageChange,"sort":_vm.handleSort}},[_c('template',{slot:"empty"},[_c('empty-table-loader',{attrs:{"loading":_vm.isFetchingOrderSummaries,"has-icon":false,"message":"No Orders found for this account"}})],1),_c('b-table-column',{attrs:{"sortable":"","field":"order_date","label":"Date","cell-class":"no-wrap-text"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
    var row = ref.row;
return [_vm._v(" "+_vm._s(_vm._f("moment")(row.orderDate,'MMM Do, YYYY @ h:mm A z', row.storeTimezone))+" ")]}}])}),_c('b-table-column',{attrs:{"centered":"","label":"Order Number","cell-class":"no-wrap-text"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
    var row = ref.row;
return [(row.ticketNumber)?[_vm._v(" "+_vm._s(row.ticketNumber)+" ")]:_c('span',{staticClass:"has-text-grey-light"},[_vm._v(" N/A ")])]}}])}),_c('b-table-column',{attrs:{"centered":"","label":"Order Status","cell-class":"no-wrap-text"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
    var row = ref.row;
return [_c('b-tag',{class:_vm.getOrderStatus(row.orderStatus).toLowerCase()},[_vm._v(" "+_vm._s(_vm.getOrderStatus(row.orderStatus))+" ")])]}}])})],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }