<template>
  <div>
    <fieldset v-if="!$_selectedMerchant.isSundry">
      <h4 class="title is-4">Provider Details</h4>
      <div class="is-grid gap-lg col-min-300">
        <validated-input
          label="Point of Sale"
          name="posProvider"
          rules="required"
        >
          <b-select
            v-model="selectedPosType"
            expanded
            placeholder="Select a Point of Sale Provider..."
            :disabled="hasSquarePOS || !canEdit('posTypeId')"
          >
            <option v-if="!merchantPosProviders.length && !storePosProviders.length" disabled :value="null">
              No POS Providers Configured
            </option>
            <template v-else>
              <optgroup v-if="merchantPosProviders.length" label="Merchant POS Providers">
                <option v-for="{ posType, id } in merchantPosProviders" :key="id" :value="posType">
                  {{ posType.name }}
                </option>
              </optgroup>
              <optgroup v-if="storePosProviders.length" label="Location POS Providers">
                <option v-for="{ posType, id } in storePosProviders" :key="id" :value="posType">
                  {{ posType.name }}
                </option>
              </optgroup>
            </template>
          </b-select>
        </validated-input>

        <validated-input
          v-if="showSquareStoreMapping"
          label="Square Location"
          name="squareStore"
          rules="required"
        >
          <b-select
            :value="mode === 'create' && loadingExternalStores ? null : value.selectedExternalStoreId"
            expanded
            :placeholder="loadingExternalStores ? 'Loading Locations...' : 'Select a location'"
            :loading="loadingExternalStores"
            :disabled="hasSquarePOS || !canEdit('posLocationId')"
            @input="handleExternalStoreSelection"
          >
            <option :value="null">–</option>
            <option v-for="{ posLocationId, paymentLocationId, description } in externalStores" :key="posLocationId || paymentLocationId" :value="posLocationId || paymentLocationId">
              {{ `${description} - ${posLocationId || paymentLocationId}` }}
            </option>
          </b-select>
        </validated-input>

        <validated-text-input
          v-if="showPosLocationIdInput"
          :value="value.posLocationId"
          monospaced
          type="text"
          label="POS Location Id"
          name="posLocationId"
          rules="required"
          :disabled="hasSquarePOS || !canEdit('posLocationId')"
          @input="handleInput('posLocationId', $event)"
        />
      </div>
      <hr class="is-large">
    </fieldset>


    <fieldset>
      <h4 class="title is-4">Location Info & Address</h4>

      <div class="is-grid gap-y-xl">
        <div class="is-grid col-2 gap-x-lg">
          <validated-text-input
            :value="value.description"
            label="Location Name"
            name="description"
            rules="required"
            type="text"
            :disabled="hasSquarePOS || !canEdit('description')"
            @input="handleInput('description', $event)"
          />
          <validated-text-input
            :value="value.displayName"
            label="Location Display Name"
            name="displayName"
            type="text"
            :disabled="hasSquarePOS || !canEdit('displayName')"
            tooltip="Optional name to display to your guests"
            @input="handleInput('displayName', $event)"
          />
        </div>
        <div class="is-grid col-2 gap-x-lg">
          <validated-text-input
            :value="value.businessLocationId"
            label="Location Id"
            name="locationId"
            tooltip="E.g. Store Number"
            maxlength="64"
            type="text"
            monospaced
            :disabled="hasSquarePOS || !canEdit('businessLocationId')"
            @input="handleInput('businessLocationId', $event)"
          />
          <validated-text-input
            :value="value.storeId"
            label="CardFree Location Id"
            name="storeId"
            type="text"
            monospaced
            disabled
          />
        </div>
        <div class="is-grid gap-x-lg gap-y-sm col-2 col-1-tablet">
          <validated-text-input
            :value="value.addressLine1"
            :has-icon="false"
            label="Address Line 1"
            name="addressLine1"
            type="text"
            rules="required"
            :disabled="hasSquarePOS || !canEdit('addressLine1')"
            disable-autocomplete
            @input="handleInput('addressLine1', $event)"
          />
          <validated-text-input
            :value="value.addressLine2"
            :has-icon="false"
            label="Address Line 2"
            name="addressLine2"
            type="text"
            :disabled="hasSquarePOS || !canEdit('addressLine2')"
            disable-autocomplete
            @input="handleInput('addressLine2', $event)"
          />
          <validated-text-input
            :value="value.city"
            :has-icon="false"
            label="City"
            name="city"
            type="text"
            rules="required"
            expanded
            class="mar-none"
            :disabled="hasSquarePOS || !canEdit('city')"
            disable-autocomplete
            @input="handleInput('city', $event)"
          />
          <div class="is-grid gap-x-lg gap-y-sm col-2">
            <validated-input
              :label="regionLabel"
              name="region"
              rules="required"
              expanded
              disable-autocomplete
              class="mar-none"
            >
              <b-select
                :value="value.region"
                expanded
                :disabled="hasSquarePOS || !canEdit('region')"
                disable-autocomplete
                @input="handleInput('region', $event)"
              >
                <option :value="null">–</option>
                <template v-if="!selectedCountry.code">
                  <optgroup label="U.S. States">
                    <option v-for="option in usStates" :key="option.abbr" :value="option.abbr">
                      {{ option.name }}
                    </option>
                  </optgroup>
                  <optgroup label="Canadian Provinces">
                    <option v-for="option in caProvinces" :key="option.abbr" :value="option.abbr">
                      {{ option.name }}
                    </option>
                  </optgroup>
                </template>
                <template v-else>
                  <option v-for="option in filteredRegions" :key="option.abbr" :value="option.abbr">
                    {{ option.name }}
                  </option>
                </template>
              </b-select>
            </validated-input>
            <validated-text-input
              :value="value.postalCode"
              :has-icon="false"
              :label="value.countryCode === 'CA' ? 'Postal Code' : 'Zip Code'"
              name="postalCode"
              :type="value.countryCode === 'CA' ? 'postalCode' : 'zipCode'"
              rules="required"
              expanded
              :disabled="hasSquarePOS || !canEdit('postalCode')"
              disable-autocomplete
              @input="handleInput('postalCode', $event)"
            />
          </div>
          <div /> <!-- Empty div to push the country selector to the right -->
          <validated-input
            label="Country"
            name="countryCode"
            expanded
            disable-autocomplete
            class="mar-none"
          >
            <b-dropdown
              :value="value.countryCode"
              aria-role="list"
              :mobile-modal="false"
              :disabled="hasSquarePOS || !canEdit('countryCode')"
              class="has-border has-border-grey-lighter has-radius is-full-width"
              @input="handleInput('countryCode', $event)"
            >
              <template #trigger>
                <b-button
                  class="is-transparent is-full-width"
                  :icon-right="hasSquarePOS || !canEdit('countryCode') ? '' : 'chevron-down'"
                >
                  <span v-if="selectedCountry.code">
                    {{ selectedCountry.name }}
                    <span class="has-text-grey-light is-size-7">({{ selectedCountry.code }})</span>
                  </span>
                  <span v-else class="has-text-grey-light">–</span>
                </b-button>
              </template>

              <b-dropdown-item
                v-for="country in countries"
                :key="country.code"
                :value="country.code"
                class="is-full-width"
              >
                {{ country.name }} <span v-if="country.code" class="has-text-grey is-size-7">({{ country.code }})</span>
              </b-dropdown-item>
            </b-dropdown>
          </validated-input>
        </div>
        <div class="is-grid col-3 col-1-tablet gap-lg">
          <validated-input rules="required" name="timeZone" label="Time Zone">
            <dropdown-menu
              :value="value.ianaTimezoneId"
              :disabled="hasSquarePOS || !canEdit('ianaTimezoneId')"
              expanded
              @input="handleInput('ianaTimezoneId', $event)"
            >
              <dropdown-button
                slot="trigger"
                placeholder="Select a time zone..."
                :disabled="hasSquarePOS || !canEdit('ianaTimezoneId')"
              >
                <div v-if="value.ianaTimezoneId" class="is-flex align-center">
                  <span class="mar-r-sm">{{ timeZones[value.ianaTimezoneId] }}</span>
                </div>
              </dropdown-button>
              <b-dropdown-item v-for="(display, tmzValue) in timeZones" :key="tmzValue" :value="tmzValue" class="pad-y-sm is-flex align-center">
                <span class="mar-r-sm">{{ display }}</span>
              </b-dropdown-item>
            </dropdown-menu>
          </validated-input>
          <validated-text-input
            :value="value.latitude"
            :class="hasFetchedCoordinateData && 'fetched-coordinates'"
            label="Latitude"
            name="latitude"
            type="float"
            rules="required|validLatitude"
            expanded
            output-string
            :disabled="hasSquarePOS || !canEdit('latitude')"
            @input="handleInput('latitude', $event)"
          />
          <div class="is-flex">
            <validated-text-input
              :value="value.longitude"
              :class="hasFetchedCoordinateData && 'fetched-coordinates'"
              class="flex-grow mar-none"
              label="Longitude"
              name="longitude"
              type="float"
              rules="required|validLongitude"
              expanded
              output-string
              :disabled="hasSquarePOS || !canEdit('longitude')"
              @input="handleInput('longitude', $event)"
            />
            <b-button
              v-tippy="{ content: 'Get Coordinates', placement: 'left', size: 'large' }"
              :disabled="!hasMinimumAddressData"
              :loading="isFetchingCoordinates"
              type="is-primary is-light"
              icon-left="location"
              class="coordinate-button mar-l-sm"
              @click="handleGetCoordinates"
            />
          </div>
          <validated-text-input
            :value="value.mapsUrlOverride"
            label="Map URL Override"
            name="mapsUrlOverride"
            type="text"
            rules="max:255|validUrl"
            :disabled="!canEdit('mapsUrlOverride')"
            class="col-span-3 col-span-1-tablet"
            @input="handleInput('mapsUrlOverride', $event)"
          />
        </div>
      </div>

    </fieldset>

    <hr class="is-large">

    <fieldset>
      <h4 class="title is-4">Contact Information</h4>
      <p class="subtitle is-6 has-text-grey">Displayed within the ordering applications</p>
      <div class="is-grid col-2 gap-lg">
        <validated-text-input
          :value="value.emailAddress"
          :has-icon="false"
          label="Email"
          name="email"
          type="email"
          expanded
          :disabled="!canEdit('emailAddress')"
          @input="handleInput('emailAddress', $event)"
        />
        <validated-text-input
          :value="value.phoneNumber"
          :has-icon="false"
          label="Phone"
          name="phone"
          type="phone"
          rules="validPhoneNumber"
          expanded
          :disabled="!canEdit('phoneNumber')"
          @input="handleInput('phoneNumber', $event)"
        />
      </div>
    </fieldset>

    <hr class="is-large">

    <fieldset>
      <h4 class="title is-4">Off Hours Ordering</h4>

      <div class="is-grid gap col-2">
        <radio-button
          :value="value.allowAfterHoursOrdering"
          name="allowAfterHoursOrdering"
          :native-value="true"
          :disabled="!canEdit('allowAfterHoursOrdering')"
          @input="handleInput('allowAfterHoursOrdering', $event)"
        >
          <p>Enabled</p>
          <p slot="sublabel">Guests can place future orders while this location is closed</p>
        </radio-button>
        <radio-button
          :value="value.allowAfterHoursOrdering"
          name="allowAfterHoursOrdering"
          :native-value="false"
          :disabled="!canEdit('allowAfterHoursOrdering')"
          @input="handleInput('allowAfterHoursOrdering', $event)"
        >
          <p>Disabled</p>
          <p slot="sublabel">Guests can only view the menu while this location is closed</p>
        </radio-button>
      </div>
    </fieldset>

    <hr class="is-large">

    <fieldset>
      <h4 class="title is-4">Default Fulfillment Time Selection</h4>

      <b-message
        v-if="$_selectedMerchant.asapPickupOnlyEnabled && !!requirePickUpTimeSelection"
        type="is-warning"
        icon="exclamation-triangle"
        class="is-compact has-shadow"
        size="is-small"
        has-icon
      >
        Changing the default fulfillment time selection will not impact this location's applications because this merchant has ASAP Pickup Only enabled.
      </b-message>

      <div class="is-grid gap col-2">
        <radio-button
          v-model="requirePickUpTimeSelection"
          name="requirePickUpTimeSelection"
          :native-value="false"
          :disabled="!canEditRequirePickupTimeSelectionAttribute"
        >
          <p>Earliest Available</p>
          <p slot="sublabel">The earliest available time slot is preselected for guests</p>
        </radio-button>
        <radio-button
          v-model="requirePickUpTimeSelection"
          name="requirePickUpTimeSelection"
          :native-value="true"
          :disabled="!canEditRequirePickupTimeSelectionAttribute"
        >
          <p>No Selection</p>
          <p slot="sublabel">No time slot is preselected, guests must make a selection</p>
        </radio-button>
      </div>
    </fieldset>

    <template v-if="showOtherSection">
      <hr class="is-large">

      <h4 class="title is-4">Other Information</h4>

      <div class="is-grid col-2 col-1-tablet gap-lg">
        <validated-text-input
          v-if="showTaxSection"
          v-model="value.totalSalesTaxRate"
          label="Default Sales Tax Rate"
          name="totalSalesTaxRate"
          type="float"
          :rules="{
            between: [0, 100]
          }"
          class="percent"
          inputmode="decimal"
          :disabled="hasSquarePOS || !canEdit('totalSalesTaxRate')"
          @input="handleInput('totalSalesTaxRate', $event)"
        />

        <validated-text-input
          v-if="showLoyaltySection"
          v-model="value.geofenceOfferRadiusInFeet"
          label="Geofence Radius for Member Proximity Earn Rule (ft)"
          name="geofenceOfferRadiusInFeet"
          type="number"
          rules="min:0"
          :disabled="!canEdit('geofenceOfferRadiusInFeet')"
          placeholder="Enter amount of feet..."
          @input="handleInput('geofenceOfferRadiusInFeet', $event)"
        />

        <validated-text-input
          v-if="showExternalDeviceMasterPasscodeSection"
          v-model="value.externalDeviceMasterPasscode"
          label="External Device Master Passcode"
          name="externalDeviceMasterPasscode"
          type="text"
          rules="max:255"
          :disabled="!canEdit('externalDeviceMasterPasscode')"
          @input="handleInput('externalDeviceMasterPasscode', $event)"
        />

        <validated-text-input
            v-if="showExternalDeviceMasterPasscodeSection"
            v-model="value.externalDeviceSecondaryPasscode"
            label="External Device Secondary Passcode"
            name="externalDeviceSecondaryPasscode"
            type="text"
            rules="max:255"
            :disabled="!canEdit('externalDeviceMasterPasscode')"
            @input="handleInput('externalDeviceSecondaryPasscode', $event)"
        />

      </div>
    </template>
  </div>
</template>

<script>
  import geocode from '@/services/geocode';
  import caProvinces from '@/helpers/ca-provinces';
  import usStates from '@/helpers/us-states';
  import merchantMixin from '@/mixins/merchant';
  import { mapGetters } from 'vuex';
  import posTypes from '@/constants/posTypes';
  import timeZones from '@/constants/ianaTimezones';
  import storeMappingAttributeConstants from '@/constants/storeMappingAttributes';
  import { subject } from '@casl/ability';
  import StoreAttribute from '@/store/classes/StoreAttribute';
  import http from '@/services/http';
  import MerchantLoyaltyProviderConfiguration from '@/store/classes/MerchantLoyaltyProviderConfiguration';
  import MerchantAppSetting from '@/store/classes/MerchantAppSetting';

  export default { // TEST ALL
    name: 'StoreFormInputs',

    mixins: [merchantMixin],

    props: {
      value: {
        type: Object,
        required: true
      },

      mode: {
        type: String,
        default: 'create',
        validator(value) {
          return ['create', 'read', 'update'].includes(value);
        }
      }
    },

    data() {
      return {
        caProvinces,
        usStates,
        timeZones,
        loadingExternalStores: false,
        isFetchingCoordinates: false,
        hasFetchedCoordinateData: false,
        externalStores: [],
        countries: [
          { name: '–', code: null },
          { name: 'United States', code: 'US' },
          { name: 'Canada', code: 'CA' }
        ]
      };
    },

    computed: {
      ...mapGetters('session', ['isCardfreeAdmin']),

      locationManagementSettings() {
        return this.$_selectedMerchant?.locationManagementSettings;
      },

      hasSquarePOS() {
        return this.value.posTypeId === posTypes.Square;
      },

      showSquareStoreMapping() {
        const { locationManagementSettings, isAuthorizedWithSquare } = this.$_selectedMerchant;
        return locationManagementSettings.requiresPosLocationId && this.isCardfreeAdmin && isAuthorizedWithSquare;
      },

      showPosLocationIdInput() {
        const { locationManagementSettings, isAuthorizedWithSquare } = this.$_selectedMerchant;
        return locationManagementSettings.requiresPosLocationId && this.isCardfreeAdmin && !isAuthorizedWithSquare;
      },

      storePosProviders() {
        return this.$_selectedMerchant.posConfigurations.filter(pos => pos.storeId === this.value.storeId);
      },

      merchantPosProviders() {
        return this.$_selectedMerchant.posConfigurations.filter(pos => pos.storeId === -1);
      },

      selectedPosType: {
        get() {
          return this.$_selectedMerchant.posConfigurations.find(mpp => mpp.posType.id === this.value.posTypeId)?.posType;
        },

        set(value) {
          this.handleInput('posTypeId', value?.id);
        }
      },

      showDefaultTaxRate() {
        const hasTaxDefinedOnPos = this.value?.posType?.metaData?.supportsTax;
        const hasMenuPricesEnabled = this.$_selectedMerchant.features.menuPrices;

        return hasMenuPricesEnabled && !hasTaxDefinedOnPos;
      },

      selectedCountry() {
        return this.countries.find(country => country.code === this.value.countryCode) || this.countries[0];
      },

      filteredRegions() {
        return this.selectedCountry.code === 'CA' ? this.caProvinces : this.usStates;
      },

      regionLabel() {
        if (!this.selectedCountry.code) return 'State/Province';
        return this.selectedCountry?.code === 'CA' ? 'Province' : 'State';
      },

      hasMinimumAddressData() {
        return [
          this.value.addressLine1?.split(' ').length > 1,
          this.value.city?.length > 2,
          this.value.region,
          this.value.postalCode?.length > 4
        ].every(x => x);
      },

      requirePickupTimeSelectionStoreMappingAttribute() {
        return this.value.storeMappingAttributes?.find(sma => sma.code === storeMappingAttributeConstants.REQUIRE_PICK_UP_TIME_SELECTION.code);
      },

      requirePickUpTimeSelection: {
        get() {
          return !!this.requirePickupTimeSelectionStoreMappingAttribute?.isActive;
        },

        set(val) {
          const index = this.value.storeMappingAttributes?.findIndex(sma => sma.code === storeMappingAttributeConstants.REQUIRE_PICK_UP_TIME_SELECTION.code);
          if (index < 0) this.initializeRequirePickupTimeSelection(val);
          else {
            const updated = this.$clone(this.value.storeMappingAttributes);
            updated[index].isActive = val;
            this.handleInput('storeMappingAttributes', updated);
          }
        }
      },

      requirePickupTimeSelectionAttribute() {
        return StoreAttribute.query().where('code', [storeMappingAttributeConstants.REQUIRE_PICK_UP_TIME_SELECTION.code]).first();
      },

      canEditRequirePickupTimeSelectionAttribute() {
        return this.$can(
          this.mode,
          subject(
            'StoreMappingAttribute',
            { storeId: this.value?.storeId, storeAttributeId: this.requirePickupTimeSelectionAttribute?.id }
          )
        ) || this.$can('create', 'StoreMapping');
      },

      showLoyaltySection() {
        return MerchantLoyaltyProviderConfiguration.hasCardfreeLoyalty();
      },

      showTaxSection() {
        return this.mode !== 'create' && this.showDefaultTaxRate;
      },

      showExternalDeviceMasterPasscodeSection() {
        return this.$can('read', 'StoreMapping', 'externalDeviceMasterPasscode')
          && MerchantAppSetting.hasExternalDevice();
      },

      showOtherSection() {
        return this.showLoyaltySection || this.showTaxSection || this.showExternalDeviceMasterPasscodeSection;
      }
    },

    watch: {
      'value.region': 'handleRegionChange',
      'value.countryCode': 'handleCountryCodeChange'
    },

    created() {
      if (this.showSquareStoreMapping) {
        this.fetchExternalStores();
      }
    },

    methods: {
      async fetchExternalStores() {
        try {
          this.loadingExternalStores = true;
          const merchantId = this.$_selectedMerchantId;
          const response = await http.get(`merchants/${merchantId}/external_stores`);

          this.externalStores = response.data
            .sort((a, b) => ((a.description?.toLowerCase() < b.description?.toLowerCase()) ? -1 : 1));
        }
        catch (error) {
          this.$_onRequestError({ error, toastOptions: { message: 'Unable to fetch external stores' } });
        }
        finally {
          this.loadingExternalStores = false;
        }
      },

      initializeRequirePickupTimeSelection(isActive) {
        const { name, code } = storeMappingAttributeConstants.REQUIRE_PICK_UP_TIME_SELECTION;
        const updated = this.$clone(this.value.storeMappingAttributes);
        updated.push({
          name,
          code,
          isActive,
          storeId: this.$route.params.storeId ? Number(this.$route.params.storeId) : null,
          storeAttributeId: this.requirePickupTimeSelectionAttribute?.id
        });
        this.handleInput('storeMappingAttributes', updated);
      },

      handleRegionChange(newRegion, oldRegion) {
        if (!oldRegion) return;

        const isUsState = this.usStates.some(option => option.abbr === newRegion);
        const wasUsState = this.usStates.some(option => option.abbr === oldRegion);

        if ((!isUsState && wasUsState) || (isUsState && !wasUsState)) {
          this.value.postalCode = '';
        }
      },

      handleCountryCodeChange(newCountryCode, oldCountryCode) {
        const isChangingCountries = (newCountryCode === 'CA' && oldCountryCode === 'US') ||
                                    (newCountryCode === 'US' && oldCountryCode === 'CA');

        if (isChangingCountries) {
          this.value.region = null;
        }
        else if (!oldCountryCode) {
          this.handleInitialCountrySelection(newCountryCode);
        }
      },

      handleInitialCountrySelection(newCountryCode) {
        const regionList = newCountryCode === 'CA' ? this.caProvinces : this.usStates;
        const isValidRegion = regionList.some(region => region.abbr === this.value.region);

        if (!isValidRegion) {
          this.value.region = null;
        }
      },

      async handleGetCoordinates() {
        try {
          this.isFetchingCoordinates = true;

          const { lat, lng } = await geocode({
            addressLine1: this.value.addressLine1,
            addressLine2: this.value.addressLine2,
            city: this.value.city,
            region: this.value.region,
            postalCode: this.value.postalCode
          });

          this.value.latitude = lat;
          this.value.longitude = lng;

          this.trimLatitudeAndLongitude();

          this.hasFetchedCoordinateData = true;
          setTimeout(() => {
            this.hasFetchedCoordinateData = false;
          }, 2000);
        }

        catch (error) {
          this.$_onRequestError({ error, toastOptions: { message: 'Unable to retrieve coordinates' } });
        }

        finally {
          this.isFetchingCoordinates = false;
        }
      },

      canEdit(field) {
        return this.$can(this.mode, 'StoreMapping', field);
      },

      handleExternalStoreSelection(posLocationIdOrPaymentLocationId) {
        // from the externalStores API call, find the one that matches this one so that we can set address data
        const externalStore = this.externalStores.find(store => store.posLocationId === posLocationIdOrPaymentLocationId || store.paymentLocationId === posLocationIdOrPaymentLocationId);

        const updatedForm = this.$clone(this.value);
        updatedForm.paymentLocationId = posLocationIdOrPaymentLocationId;
        updatedForm.posLocationId = posLocationIdOrPaymentLocationId;
        updatedForm.merchantLocationId = posLocationIdOrPaymentLocationId;
        updatedForm.selectedExternalStoreId = posLocationIdOrPaymentLocationId;
        updatedForm.description = externalStore.description;
        updatedForm.addressLine1 = externalStore.address.line1;
        updatedForm.city = externalStore.address.city;
        updatedForm.region = externalStore.address.state;
        updatedForm.postalCode = externalStore.address.postalCode.substring(0, 5);
        this.$emit('input', updatedForm);
      },

      trimLatitudeAndLongitude() {
        const { latitude, longitude } = this.value;
        const latitudeArray = latitude.toString().split('.');
        const longitudeArray = longitude.toString().split('.');
        const shouldTrimLatitude = latitudeArray.length > 1 && latitudeArray[1].length > 6;
        const shouldTrimLongitude = longitudeArray.length > 1 && longitudeArray[1].length > 6;

        const clonedForm = this.$clone(this.value);
        if (shouldTrimLatitude) {
          const trimmedLatDecimals = latitudeArray[1].substring(0, 6);
          const updatedLat = `${latitudeArray[0]}.${trimmedLatDecimals}`;
          clonedForm.latitude = updatedLat;
        }

        if (shouldTrimLongitude) {
          const trimmedLongDecimals = longitudeArray[1].substring(0, 6);
          const updatedLong = `${longitudeArray[0]}.${trimmedLongDecimals}`;
          clonedForm.longitude = updatedLong;
        }
        this.$emit('input', clonedForm);
      },

      handleInput(key, value) {
        this.$emit('input', { ...this.value, [key]: value });
      }
    }
  };
</script>
<style lang="sass" scoped>
  ::v-deep
    .dropdown-menu, .dropdown-trigger
      width: 100%
      justify-content: flex-start
    .dropdown .button .icon
      color: $primary
      margin-left: auto

  .store-hours
    margin-bottom: 2rem
    position: relative

    ::v-deep.field-label
      flex-grow: 0
      min-width: 150px
      display: flex
      align-items: center
      padding: 0

      .label
        min-width: 0

    ::v-deep .help
      position: absolute

  .time-pickers
    > *
      transition: 333ms
    &.is-closed
      > *
        transform: scale(0.975)
        opacity: 0.5

  .coordinate-button
    margin-top: 2rem

  .fetched-coordinates ::v-deep.input
    animation-name: glow-pulse
    animation-duration: 1s
    animation-iteration-count: 2
    animation-fill-mode: forwards

  @keyframes glow-pulse
    0%
      background-color: inherit
    50%
      background-color: $primary-lighter
    100%
      background-color: inherit
</style>
