<template>
  <panel :loading="isFetchingMembershipActivities" title="Rewards Activities" collapsible start-open>
    <b-message v-if="!isFetchingMembershipActivities && !filteredActivities.length" type="is-warning">
      There are no Rewards Activities for this account.
    </b-message>
    <b-table
      v-else
      :data="isFetchingMembershipActivities ? [] : filteredActivities"
      class="is-middle-aligned"
    >
      <b-table-column v-slot="{ row }" field="leftDetails">
        <div>
          <h3 class="has-text-weight-bold">{{ row.title }}</h3>
          <div v-for="(detail, index) in row.leftDetails" :key="`L-${index}`" :class="{'text-red': startsWithExpires(detail.content)}">
            {{ detail.content }}
          </div>
        </div>
      </b-table-column>
      <b-table-column v-slot="{ row }" field="rightDetails">
        <div class="right-content" :class="{ 'full-height': !row.primaryAchievement }">
          <div v-if="row.primaryAchievement" class="balance-info">
            {{ formatPoints(row.primaryAchievement.content) }}
          </div>
          <div v-for="(detail, index) in row.rightDetails" :key="`R-${index}`">
            {{ formatPoints(detail.content) }}
          </div>
        </div>
      </b-table-column>

    </b-table>
    <div class="is-flex justify-center pad pad-x has-border-top">
      <b-button :loading="isFetchingMembershipActivities" :disabled="offset === 0" @click="changePage(-1)">Previous</b-button>
      <b-button :loading="isFetchingMembershipActivities" :disabled="!hasMore" @click="changePage(1)">Next</b-button>
    </div>
  </panel>
</template>

<script>
  import RegisteredGuest from '@/store/classes/RegisteredGuest';

  export default {
    name: 'RegisteredGuestMembershipActivities',
    props: {
      registeredGuest: {
        type: Object,
        required: true
      }
    },
    data() {
      return {
        activities: [],
        hasMore: false,
        offset: 0,
        limit: 25,
        currentPage: 1
      };
    },
    computed: {
      isFetchingMembershipActivities() {
        return RegisteredGuest.$state().fetchingMembershipActivities;
      },

      filteredActivities() {
        return this.activities.filter(activity => activity.layoutType === 'TwoColumn');
      }
    },

    created() {
      this.fetchActivities();
    },

    methods: {
      async fetchActivities() {
        try {
          const response = await RegisteredGuest.fetchMembershipActivities(this.registeredGuest.id, this.limit, this.offset);
          const data = response.loyaltyMembershipActivities;
          this.activities = this.$clone(data.activities);
          this.hasMore = data.hasMore;
        }
        catch (error) {
          this.$_onRequestError({
            error,
            toastOptions: {
              message: 'There was an error fetching reward activities'
            }
          });
        }
      },

      startsWithExpires(text) {
        return text.startsWith('Expires:');
      },

      changePage(direction) {
        this.offset += this.limit * direction;
        this.currentPage += direction;
        this.fetchActivities();
      },

      formatPoints(content) {
        // regex to match numbers (including decimals) followed by "pts", "pt", or "points"
        const pointsRegex = /(-?\d+(?:\.\d+)?)\s*(pts?|points)/i;
        return content.replace(pointsRegex, (match, number, unit) => {
          const formattedNumber = this.$options.filters.formatDecimal(parseFloat(number));
          return `${formattedNumber} ${unit}`;
        });
      }
    }
  };
</script>

<style lang="sass" scoped>

.right-content
  text-align: right

.text-red
  color: $danger

.balance-info
  color: $success
</style>
