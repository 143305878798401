/* eslint-disable import/no-cycle */
import { Model } from '@vuex-orm/core';
import http from '@/services/http';
import filterObjectKeys from '@/helpers/filter-object-keys';



export default class SegmentationSignature extends Model {
  static entity = 'segmentationSignatures'

  static acceptedCriteriaKeys = [
    'averageOrderTotalRange',
    'averageOrderTotalRange',
    'daysSinceLastOrder',
    'daysSinceRegistered',
    'daysSinceLastLogin',
    'enrolledInLoyalty',
    'hasNoOrders',
    'lifetimeOrderTotalEquality',
    'lifetimeOrderTotalRange',
    'loyaltyPointBalance',
    'mostVisitedStoreId',
    'lastVisitedStoreId',
    'favoriteStoreId',
    'offersRedeemed',
    'orderAverages',
    'orderCounts',
    'orderItemCategory',
    'orderTypeIds',
    'registeredDateRange',
    'birthdayDateRange',
    'loyaltyProgramTier',
    'favoriteItem',
    'spendPercentile',
    'orderPlacedAtStore',
    'orderTotals',
    'orderItemAttribute',
    'lapsedVisits',
    'giftCardPurchaseCount',
    'operatingSystem'
  ]

  static fields() {
    return {
      id: this.attr(''),
      totalAccountCount: this.attr(''),
      totalAccountCountForCriteria: this.attr(''),
      accountSignatures: this.attr('')
    };
  }
  // EXAMPLE SEGMENTATION SIGNATURE
  // {
  //   "id": 2,
  //   "totalAccountCount": 246,
  //   "totalAccountCountForCriteria": 1,
  //   "accountSignatures": {
  //       "data": [
  //           {
  //               "accountId": 104092,
  //               "firstName": "Wade ",
  //               "lastName": "Rohrbach",
  //               "email": "wrohrbach@cardfree.com"
  //           }
  //       ],
  //       "paging": {
  //           "totalPages": 1
  //       }
  //   }
  // }


  // STATE //////////////////////
  static state() {
    return {
      fetching: false
    };
  }

  static $state() {
    return this.store().state.entities.segmentationSignatures;
  }


  // Actions
  static async fetchSegmentationSignature(segmentation) {
    try {
      this.commit((state) => {
        state.fetching = true;
      });
      const merchantId = this.store().state.entities.merchants.selectedMerchantId;
      const acceptedKeys = [
        'id',
        'name',
        'merchantId',
        'criteria'
      ];

      const criteriaJson = filterObjectKeys(segmentation.criteria, this.acceptedCriteriaKeys);
      segmentation.criteria = criteriaJson;

      const response = await http.post(`merchants/${merchantId}/segmentations/signatures`, {
        segmentation: filterObjectKeys(segmentation, acceptedKeys)
      });
      return response.data;
    }
    catch (error) {
      throw error;
    }
    finally {
      this.commit((state) => {
        state.fetching = false;
      });
    }
  }
}
