<template>
  <validated-input
    :name="`offer-${resourceId}`"
    :label="label"
    rules="required"
    data-test-class="offer-select"
  >
    <dropdown-menu
      :value="value"
      data-test-id="offer-input"
      expanded
      :disabled="readOnly"
      @input="$emit('input', $event)"
    >
      <dropdown-button
        slot="trigger"
        placeholder="Select an offer..."
        :value="getSelectedOfferName()"
        :disabled="readOnly"
      />
      <b-dropdown-item
        v-for="( offer, key ) in liveOffers"
        :key="key"
        :value="offer.guid"
        class="offer-select-item"
      >
        <p>
          {{ offer.name }}
        </p>
        <p v-if="offer.description" class="is-size-7 has-text-weight-normal text-ellipsis">
          {{ offer.description }}
        </p>
        <p class="is-size-7 has-text-weight-normal">
          Redemptions
          <!-- starts in future -->
          <template v-if="moment().isBefore(moment(offer.redemptionsAllowedStartDate))">
            <!-- has an end date -->
            <template v-if="!!offer.redemptionsAllowedEndDate">
              allowed from {{ offer.redemptionsAllowedStartDate | moment('MMM DD, YYYY') }} through {{ offer.redemptionsAllowedEndDate | moment('MMM DD, YYYY') }}
            </template>
            <!-- has no end date -->
            <template v-else>
              allowed starting {{ offer.redemptionsAllowedStartDate | moment('MMM DD, YYYY') }} and have no end date
            </template>
          </template>
          <!-- has already started -->
          <template v-else>
            <!-- has en end date -->
            <template v-if="!!offer.redemptionsAllowedEndDate">
              allowed until {{ offer.redemptionsAllowedEndDate | moment('MMM DD, YYYY') }}
            </template>
            <!-- has no end date -->
            <template v-else>
              have no end date
            </template>
          </template>
        </p>
      </b-dropdown-item>
    </dropdown-menu>
  </validated-input>
</template>

<script>
  import moment from 'moment-timezone';

  import Offer from '@/store/classes/Offer';

  export default {
    name: 'LiveOffersSelect',

    props: {
      value: {
        type: String,
        default: null
      },
      // Since there can be multiple offer inputs on a page, we need to differentiate them
      resourceId: {
        type: String,
        required: true
      },

      readOnly: {
        type: Boolean,
        default: false
      },

      label: {
        type: String,
        default: 'Offer'
      }
    },

    data: () => ({
      moment
    }),

    computed: {
      liveOffers() {
        return Offer.liveOffers();
      }
    },

    methods: {
      getSelectedOfferName() {
        return this.value
          ? Offer.getOfferName(this.value)
          : null;
      }
    }
  };
</script>

<style lang="sass" scoped>
  .offer-select-item
    width: 550px
</style>
