/* eslint-disable import/no-cycle */
import { Model } from '@vuex-orm/core';
import http from '@/services/http';
import Item from './Item';


export default class CategoryItem extends Model {
  static entity = 'categoryItem'

  static primaryKey = ['menuCategoryId', 'menuItemId']

  static fields() {
    return {
      menuItemId: this.attr(null),
      menuCategoryId: this.attr(null)
    };
  }



  // ACTIONS //////////////////////

  // Plural add
  static async addItemToCategories({ menuCategoryIds, menuItemId }) {
    try {
      await Promise.all(
        menuCategoryIds.map(menuCategoryId => http.post(
          'menu_items_menu_categories',
          { menuItemsMenuCategory: { menuCategoryId, menuItemId } }
        ))
      );

      this.insert({
        data: menuCategoryIds.map(menuCategoryId => ({ menuCategoryId, menuItemId }))
      });
    }

    catch (error) {
      throw error;
    }
  }

  // Single add
  static async addItemToCategory({ menuCategoryId, menuItemId }) {
    try {
      await http.post(
        'menu_items_menu_categories',
        { menuItemsMenuCategory: { menuCategoryId, menuItemId } }
      );

      this.insert({ data: { menuCategoryId, menuItemId } });
    }

    catch (error) {
      throw error;
    }
  }

  // Plural delete
  static async deleteItemFromCategories({ menuCategoryIds, menuItemId }) {
    try {
      await Promise.all(
        menuCategoryIds.map(menuCategoryId => this.deleteItemFromCategory(
          { menuCategoryId, menuItemId }
        ))
      );
    }

    catch (error) {
      throw error;
    }
  }

  // Single delete
  static async deleteItemFromCategory({ menuCategoryId, menuItemId }) {
    try {
      await http.delete('menu_items_menu_categories', {
        data: { menuItemsMenuCategory: { menuCategoryId, menuItemId } }
      });

      this.delete([menuCategoryId, menuItemId]);

      await Item.fetchItemsByCategoryId({
        categoryId: menuCategoryId,
        includeMenuItemModifierGroupItems: true,
        options: { forceFetch: true }
      }); /* * * * * * * * * *
      * Refetch all items in the category to get their updated sort orders  *
      * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */
    }

    catch (error) {
      throw error;
    }
  }
}
