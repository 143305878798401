import { Model } from '@vuex-orm/core';
import http from '@/services/http';

export default class FetchModel extends Model {
  static async fetchData({
    endpoint,
    options = {},
    transformData = data => data,
    ormMethod = 'create',
    fetchingState = 'fetching',
    fetchingStateValue = true,
    customQuery
  }) {
    try {
      this.commit((state) => {
        state[fetchingState] = fetchingStateValue;
      });

      let alreadyFetched;
      if (customQuery) {
        alreadyFetched = customQuery.exists();
      }
      else {
        alreadyFetched = this.exists();
      }

      if (!alreadyFetched || options.forceFetch) {
        const { data } = await http.get(endpoint);
        const transformedData = transformData(data);
        const ormOptions = { data: transformedData, ...options };
        this[ormMethod](ormOptions);
        return transformedData;
      }
      else {
        return customQuery?.get() || this.all();
      }
    }
    catch (error) {
      throw error;
    }
    finally {
      this.commit((state) => {
        state[fetchingState] = false;
      });
    }
  }
}
