<template>
  <div class="field has-addons">
    <p class="control">
      <b-button
        icon-left="minus"
        class="minus-button has-text-danger has-background-white"
        :disabled="disabled || !canDecrement"
        @click.prevent="decrement"
      />
    </p>
    <p class="control">
      <input
        type="text"
        :value="displayValue"
        :default-value="defaultValue"
        class="input has-text-centered"
        :disabled="disabled"
        :expanded="false"
        @input="handleInput"
        @blur="handleBlur"
      >
    </p>
    <p class="control">
      <b-button
        icon-left="plus"
        class="plus-button has-text-primary has-background-white"
        :disabled="disabled || !canIncrement"
        @click.prevent="increment"
      />
    </p>
  </div>
</template>

<script>
  export default {
    name: 'NumberinputFractional',

    props: {
      value: {
        type: Number,
        default: null
      },
      min: {
        type: Number,
        default: null
      },
      max: {
        type: Number,
        default: Infinity
      },
      step: {
        type: Number,
        default: 1
      },
      disabled: {
        type: Boolean,
        default: false
      },
      precision: {
        type: Number,
        default: 2
      },
      defaultValue: {
        type: Number,
        default: null
      }
    },

    data() {
      return {
        displayValue: this.formatValue(this.value)
      };
    },

    computed: {
      canDecrement() {
        return (this.value || 0) - this.step >= this.min;
      },
      canIncrement() {
        return (this.value || 0) + this.step <= this.max;
      }
    },

    methods: {
      increment() {
        this.updateValue(Math.min(this.max, (this.value || 0) + this.step));
      },

      decrement() {
        const newValue = (this.value || 0) - this.step;
        this.updateValue(this.min !== null ? Math.max(this.min, newValue) : newValue);
      },

      handleInput(event) {
        const inputValue = event.target.value;
        if (this.isValidNumberInput(inputValue)) {
          this.displayValue = inputValue;
          const constrainedValue = this.constrainValue(inputValue);

          if (constrainedValue !== null) {
            this.$emit('input', constrainedValue);
          }
        }
        else {
          event.target.value = this.displayValue;
        }
      },

      handleBlur() {
        let newValue = this.displayValue === '' ? null : this.constrainValue(this.displayValue);
        if (newValue === null && this.defaultValue !== null) {
          newValue = this.defaultValue;
        }
        else if (newValue === null) {
          newValue = this.min;
        }
        this.updateValue(newValue);
      },

      updateValue(newValue) {
        const roundedValue = newValue !== null ? Number(newValue.toFixed(this.precision)) : null;
        this.$emit('input', roundedValue);
        this.displayValue = this.formatValue(roundedValue);
      },

      isValidNumberInput(input) {
        return /^-?\d*\.?\d*$/.test(input);
      },

      constrainValue(value) {
        const floatValue = parseFloat(value);
        return !Number.isNaN(floatValue) ? Math.min(this.max, floatValue) : null;
      },

      formatValue(val) {
        if (val === null || val === undefined || val === '') return '';
        return Number.isInteger(val) ? val.toString() : parseFloat(val.toFixed(this.precision)).toString();
      }
    }
  };
</script>

<style lang="sass" scoped>
  .input
    font-size: 16px
    height: 40px
    width: 72px

  .control
    .minus-button, .plus-button
      border-radius: 50%
      &:hover
        border-color: $grey-lighter

    .minus-button
      &:hover
        background-color: $danger-light !important

    .plus-button
      &:hover
        background-color: $primary-lighter !important
</style>
