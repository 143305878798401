var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(!_vm.storeId)?_c('b-message',{staticClass:"is-compact has-shadow mar-b",attrs:{"type":"is-primary","has-icon":"","icon":"info-square"}},[_vm._v(" Hours are consistent across all location time zones ")]):_vm._e(),_c('div',{staticClass:"card"},[_c('b-loading',{attrs:{"active":_vm.isFetchingStoreHoursOverrides,"is-full-page":false}}),_c('div',{staticClass:"card-header-sticky"},[_c('div',[_c('h3',{staticClass:"title is-4"},[_vm._v(_vm._s(_vm.storeId ? 'Special Hours' : 'Global Special Hours'))]),_c('h4',{staticClass:"subtitle is-6 has-text-grey"},[_vm._v(" "+_vm._s(_vm.storeId ? 'Add special hours to override your normal business hours' : 'Add special hours to override business hours across all locations')+" ")])])]),(_vm.combinedOverrides.length)?_c('b-table',{staticClass:"special-hours-table is-middle-aligned",attrs:{"data":_vm.combinedOverrides,"default-sort":['date', 'asc'],"row-class":_vm.getRowClass,"mobile-cards":false}},[_c('b-table-column',{attrs:{"sortable":"","field":"name","label":"Reason"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [(_vm.$can('update', 'StoreMapping') && _vm.$can('update', 'Capi::StoreHoursOverride') && _vm.isTodayOrAfter(row) && (row.storeId && _vm.storeId || (!row.storeId && !_vm.storeId)))?_c('span',{staticClass:"link",on:{"click":function($event){return _vm.openAddStoreHoursOverrideModal({ mode: 'update', storeHoursOverride: row })}}},[_vm._v(" "+_vm._s(row.name)+" ")]):_c('span',[_vm._v(" "+_vm._s(row.name)+" ")])]}}],null,false,3147104332)}),_c('b-table-column',{attrs:{"sortable":"","field":"date","label":"Date"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var date = ref.row.date;
return [_vm._v(" "+_vm._s(_vm.moment(date, 'YYYY-MM-DD').format('MM/DD/YY'))+" ")]}}],null,false,2652682710)}),_c('b-table-column',{attrs:{"label":"Hours"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var ref_row = ref.row;
var openTime = ref_row.openTime;
var closeTime = ref_row.closeTime;
var isOpen = ref_row.isOpen;
return [(isOpen)?[_vm._v(" "+_vm._s(_vm.moment(openTime, 'HH:mm:ss').format(_vm.showOpenAmPm({ openTime: openTime, closeTime: closeTime }) ? 'h:mm A' : 'h:mm'))+" - "+_vm._s(_vm.moment(closeTime, 'HH:mm:ss').format('h:mm A'))+" "),(_vm.getHoursMessage(openTime, closeTime))?_c('span',{staticClass:"is-size-7"},[_vm._v("("+_vm._s(_vm.getHoursMessage(openTime, closeTime))+")")]):_vm._e()]:[_vm._v(" Closed ")]]}}],null,false,2691444963)}),(_vm.$can('update', 'StoreMapping') && _vm.$can('update', 'Capi::StoreHoursOverride'))?_c('b-table-column',{attrs:{"numeric":"","cell-class":"actions","label":"Actions"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [[(!row.storeId && _vm.storeId)?_c('b-tag',{staticClass:"has-text-weight-bold mar-r-sm",attrs:{"type":"is-grey"}},[_vm._v("Global Special Hours")]):(_vm.isTodayOrAfter(row))?[_c('b-button',{staticClass:"is-transparent mar-r-sm",on:{"click":function($event){return _vm.openAddStoreHoursOverrideModal({ mode: 'update', storeHoursOverride: row })}}},[_c('b-icon',{attrs:{"icon":"pencil","size":"is-small"}})],1),_c('b-button',{staticClass:"is-transparent",on:{"click":function($event){return _vm.openDeleteConfirmation(row)}}},[_c('b-icon',{class:_vm.deletingOverrideId === row.id && 'spin',attrs:{"icon":_vm.deletingOverrideId === row.id ? 'spinner-third' : 'trash-alt',"type":"is-danger","size":"is-small"}})],1)]:_vm._e()]]}}],null,false,171922084)}):_vm._e()],1):_vm._e(),(_vm.$can('update', 'StoreMapping') && _vm.$can('create', 'Capi::StoreHoursOverride'))?_c('div',{staticClass:"pad-x pad-y-sm has-border-top has-border-grey-lighter"},[_c('b-button',{staticClass:"is-transparent",attrs:{"icon-left":"plus","type":"is-primary","inverted":""},on:{"click":function($event){return _vm.openAddStoreHoursOverrideModal({ mode: 'create' })}}},[_vm._v(" Special Hours ")])],1):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }