const itemAttributes = {
  // "excludedItemAttributes": [1, 2, 3, 4, 5]
  // 1 = Gift Card, 2 = Alcohol, 3 = Merchandise, 4 = Donation, 5 = Tip/Gratuity (empty array or null for none)
  GIFT_CARD: {
    id: 1,
    display: 'Gift Cards'
  },
  ALCOHOL: {
    id: 2,
    display: 'Alcohol'
  },
  MERCHANDISE: {
    id: 3,
    display: 'Merchandise'
  },
  DONATION: {
    id: 4,
    display: 'Donations'
  },
  TIP_GRATUITY: {
    id: 5,
    display: 'Tip/Gratuity'
  }
};

const itemResourceTypes = {
  ITEM: 'Items',
  CATEGORY: 'Categories'
  // To be implemented in a future ticket
  // ITEM_GROUP: 'Item Group'
};

const itemGroupTypes = {
  ALL_ITEMS: 'AllItemsItemGroupModel',
  CATEGORIES: 'ItemCategoryItemGroupModel',
  MENU_ITEMS: 'MenuItemIdItemGroupModel'
};

export {
  itemAttributes,
  itemResourceTypes,
  itemGroupTypes
};
