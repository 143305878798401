<template>
  <section-wrapper title="Merchant Location Services" :loading="loading">
    <b-table
      :data="merchantLocationServices"
      class="is-middle-aligned flex-grow"
      hoverable
      :mobile-cards="false"
    >
      <template #empty>
        <p class="has-text-centered pad is-size-5 has-text-grey-light">
          No Merchant Location Services Configured
        </p>
      </template>

      <b-table-column v-slot="{ row }" sortable field="name" label="Location Service Type">
        {{ row.name }}
      </b-table-column>

      <b-table-column
        v-slot="{ row }"
        centered
        cell-class="actions"
        label="Actions"
        width="1"
      >
        <div class="is-flex dist-x-xs">
          <b-button class="is-transparent" type="is-white" @click="openMerchantLocationServiceModal(row)">
            <b-icon icon="pencil" size="is-small" />
          </b-button>
          <b-button
            class="is-transparent"
            type="is-white"
            @click="openRemoveMerchantLocationServiceModal(row)"
          >
            <b-icon icon="trash-alt" type="is-danger" size="is-small" />
          </b-button>
        </div>
      </b-table-column>
    </b-table>
    <div class="pad-x pad-y-sm has-border-top has-border-grey-lighter">
      <b-button
        inverted
        class="is-transparent"
        type="is-primary"
        icon-left="plus"
        :loading="submitting"
        :disabled="!canAddLocationService"
        @click="openMerchantLocationServiceModal(null)"
      >
        New Location Service
      </b-button>
    </div>
  </section-wrapper>
</template>

<script>
  import merchantLocationServiceModal from './merchant-location-service-modal.vue';
  import MerchantLocationService from '@/store/classes/MerchantLocationService';
  import LocationServiceType from '@/store/classes/LocationServiceType';
  import alertModal from '@/components/globals/alert-modal.vue';

  export default {
    name: 'MerchantLocationServices',

    props: {
      merchantId: {
        type: Number,
        required: true
      }
    },

    computed: {
      submitting() {
        return MerchantLocationService.$state().submitting;
      },

      loading() {
        return MerchantLocationService.$state().fetching || LocationServiceType.$state().fetching;
      },

      locationServiceTypes() {
        return LocationServiceType.all();
      },

      canAddLocationService() {
        return this.locationServiceTypes.length !== this.merchantLocationServices.length;
      },

      merchantLocationServices() {
        return MerchantLocationService.all();
      }
    },

    created() {
      this.onCreated();
    },

    methods: {
      async onCreated() {
        try {
          await Promise.all([
            MerchantLocationService.fetchMerchantLocationServices(this.merchantId),
            LocationServiceType.fetchLocationServiceTypes()
          ]);
        }
        catch (error) {
          this.$_onRequestError({
            error,
            toastOptions: {
              message: 'There was an error fetching your merchant location services'
            }
          });
        }
      },

      async removeMerchantLocationService(id) {
        try {
          await MerchantLocationService.removeMerchantLocationService(id);
          this.$_onRequestSuccess({
            toastOptions: {
              message: 'Successfully removed your location service!'
            }
          });
        }
        catch (error) {
          this.$_onRequestError({
            error,
            toastOptions: {
              message: 'There was an error removing your location service'
            }
          });
        }
      },

      openRemoveMerchantLocationServiceModal({ id, name }) {
        this.$buefy.modal.open({
          parent: this,
          component: alertModal,
          hasModalCard: true,
          trapFocus: true,
          canCancel: ['escape', 'outside'],
          props: {
            title: 'Delete Location Service',
            message: `Are you sure you want to delete the ${name} location service?`,
            showCloseButton: false,
            horizontal: true,
            icon: 'trash-alt',
            type: 'is-danger',
            buttons: [
              { text: 'Cancel' },
              { text: 'Delete', primary: true, onClick: () => this.removeMerchantLocationService(id) }
            ]
          }
        });
      },

      openMerchantLocationServiceModal(mls) {
        const merchantLocationService = mls || new MerchantLocationService({ merchantId: this.merchantId });
        this.$buefy.modal.open({
          parent: this,
          component: merchantLocationServiceModal,
          hasModalCard: true,
          trapFocus: true,
          canCancel: false,
          customClass: 'full-width',
          props: {
            merchantLocationService
          }
        });
      }
    }
  };
</script>
