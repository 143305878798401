const conversionThresholdAwardPolicyTypes = {
  EXCHANGE_RATE: {
    type: 'ExchangeRatePointConversionThresholdAwardPolicyModel',
    defaultData: {}
  },
  OFFER_CONVERSION: {
    type: 'OfferConversionThresholdAwardPolicyModel',
    defaultData: {
      cardfreeOfferPublicId: null
    }
  },
  POINT_CONVERSION: {
    type: 'FixedPointConversionThresholdAwardPolicyModel',
    defaultData: currencyPublicId => ({
      points: 1,
      currencyPublicId
    })
  },
  RANDOM_AWARD: {
    type: 'RandomAwardConversionThresholdAwardPolicyModel',
    defaultData: {
      exchangeRate: 1,
      currencyPublicId: ''
    }
  },
  GIFT_CARD: {
    type: 'GiftCardConversionThresholdAwardPolicyModel',
    defaultData: {
      amount: 5
    }
  }
};

const conversionThresholdRunLimitPolicyTypes = {
  UNLIMITED: 'UnlimitedRunLimitPolicyModel',
  FIXED: 'FixedRunLimitPolicyModel'
};

export {
  conversionThresholdAwardPolicyTypes,
  conversionThresholdRunLimitPolicyTypes
};
