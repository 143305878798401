export default {
  CARDFREE_ADMIN: 'Cardfree Admin',
  CARDFREE_OPERATIONS: 'Cardfree Operations',
  MERCHANT_ADMIN: 'Merchant Admin',
  GUEST_SUPPORT: 'Guest Support',
  LOCATION_OPERATOR: 'Location Operator',
  LOCATION_MANAGER: 'Location Manager',
  ANALYTICS: 'Analytics',
  MARKETING_MANAGER: 'Marketing Manager',
  MENU_MANAGER: 'Menu Manager',
  MERCHANT_ADMIN_LITE: 'Merchant Admin Lite'
};
