<template>
  <div class="card">
    <div class="card-content">
      <merchant-form
        v-slot="{ mode }"
        :merchant="_merchant"
        mode="update"
      >
        <merchant-application-inputs :mode="mode" />
      </merchant-form>
    </div>
  </div>
</template>

<script>
  import merchantMixin from '@/mixins/merchant';
  import merchantForm from '../form/merchant-form.vue';
  import merchantApplicationInputs from '../form/merchant-application-inputs.vue';

  export default { // TEST
    name: 'MerchantApplicationsTab',

    components: { merchantForm, merchantApplicationInputs },

    mixins: [merchantMixin],

    props: {
      merchant: {
        type: Object,
        default: null
      }
    },

    computed: {
      _merchant() {
        return this.merchant || this.$_selectedMerchant;
      }
    }
  };
</script>
