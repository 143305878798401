<template>
  <div class="card">
    <div class="card-content">
      <div class="is-flex justify-between">
        <p class="subtitle is-4">
          Time Frame & Limits
        </p>
        <b-button
          v-if="!offerReadOnly"
          type="is-primary"
          inverted
          @click="$emit('navigate-to-step', 2)"
        >
          Edit
        </b-button>
      </div>
      <div class="is-grid col-2 pad-sm has-border-bottom has-border-grey-lighter">
        <p class="title is-6 mar-none">Promotional Period:</p>
        <p class="subtitle is-6 mar-none">
          {{ promotionalPeriodDisplay }}
        </p>
      </div>
      <div class="is-grid col-2 pad-sm has-border-bottom has-border-grey-lighter">
        <p class="title is-6 mar-none">Offer Valid:</p>
        <p class="subtitle is-6 mar-none">
          {{ datesAvailableDisplay }}
        </p>
      </div>
      <div class="is-grid col-2 pad-sm has-border-bottom has-border-grey-lighter">
        <p class="title is-6 mar-none">Valid On:</p>
        <p class="subtitle is-6 mar-none">
          {{ dayOfWeekDisplay }}
        </p>
      </div>
      <div class="is-grid col-2 pad-sm">
        <p class="title is-6 mar-none">Time of Day:</p>
        <p class="subtitle is-6 mar-none">
          {{ timeOfDayDisplay }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
  import moment from 'moment-timezone';
  import { constraintTypes } from '@/constants/offers';
  import daysOfWeek from '@/constants/daysOfWeek';

  export default {
    name: 'TimeFrameCard',

    props: {
      offer: {
        type: Object,
        required: true
      },

      offerReadOnly: {
        type: Boolean,
        required: true
      }
    },

    computed: {
      promotionalPeriodDisplay() {
        if (this.offer.dynamicExpirationPeriod) {
          const daysAfter = parseInt(this.offer.dynamicExpirationPeriod.replace(/p|d/gi, ''), 0);
          return `Dynamic, ${daysAfter} day${daysAfter > 1 ? 's' : ''} after delivery`;
        }
        else {
          return 'Fixed';
        }
      },

      datesAvailableDisplay() {
        const format = 'MMM D, YYYY';
        const startDate = moment(this.offer.redemptionsAllowedStartDate).format(format);
        const endDate = this.offer.redemptionsAllowedEndDate ? moment(this.offer.redemptionsAllowedEndDate).format(format) : null;
        return endDate ? `${startDate} - ${endDate}` : `Starting ${startDate}`;
      },

      dayOfWeekConstraint() {
        return this.getConstraintByType(constraintTypes.DAY_OF_WEEK);
      },

      timeOfDayConstraint() {
        return this.getConstraintByType(constraintTypes.TIME_OF_DAY);
      },

      dayOfWeekDisplay() {
        if (this.dayOfWeekConstraint) {
          const days = [];
          Object.keys(daysOfWeek).forEach((key) => {
            if (this.dayOfWeekConstraint.validDays.includes(daysOfWeek[key])) {
              days.push(key);
            }
          });
          return days.join(', ');
        }
        else {
          return 'Every Day';
        }
      },

      timeOfDayDisplay() {
        if (this.timeOfDayConstraint) {
          const formatTime = time => moment(time, 'hh:mm').format('h:mm A');
          return `${formatTime(this.timeOfDayConstraint.startTime)} - ${formatTime(this.timeOfDayConstraint.endTime)}`;
        }
        else {
          return 'All Day';
        }
      }
    },

    methods: {
      getConstraintByType(constraintType) {
        return this.offer.constraints.find(c => c.constraintType === constraintType);
      }
    }
  };
</script>
