import globals from '@/globals';
import main from '@/components/globals/main.vue';
import router from '@/router';
import store from '@/store';
import Vue from 'vue';
import cloneDeep from 'lodash.clonedeep';
import confetti from 'canvas-confetti';
import * as changeCase from 'change-case';



// global vue configurations:

Vue.config.productionTip = false;



// initialize global vue plugins, components, directives, filters, and mixins:

globals.initialize();

window.APP_VERSION_GIT_SHA = process.env.VUE_APP_CLIENT_VERSION;
window.APP_RELEASE_VERSION = process.env.VUE_APP_RELEASE_VERSION;

Vue.prototype.$changeCase = changeCase;
Vue.prototype.$clone = cloneDeep;

const canvas = document.querySelector('canvas');
Vue.prototype.$confetti = confetti.create(canvas, { resize: true, useWorker: true });


const tableauScript = document.createElement('script');
const libraryUrl = 'https://10az.online.tableau.com/javascripts/api/tableau.embedding.3.latest.min.js';
tableauScript.setAttribute('src', libraryUrl);
tableauScript.setAttribute('type', 'module');
document.head.appendChild(tableauScript);

// confgiure vue instance and router:

const app = new Vue({
  router,
  render(createElement) {
    return createElement(main);
  },
  store
});

app.$mount('#app');



export default app;
