const questionTypes = {
  RADIO_LIST: 'RadioList',
  TEXTAREA: 'Textarea',
  INPUT: 'Input',
  STAR_RATING: 'StarRating',
  MULTIPLE_SELECT: 'MultipleSelect'

  // Not using these types in the WAP:

  // DATE_PICKER: 'DatePicker',
  // SELECT: 'Select',
  // CHECKBOX: 'Checkbox',
  // CHECKLIST: 'Checklist'
};

const answerTypes = {
  BOOLEAN: 'Boolean',
  NUMBER: 'Number',
  TEXT: 'Text',
  DATE: 'Date'
};

const questionAnswerMap = {
  [questionTypes.RADIO_LIST]: answerTypes.TEXT,
  [questionTypes.TEXTAREA]: answerTypes.TEXT,
  [questionTypes.INPUT]: answerTypes.TEXT,
  [questionTypes.STAR_RATING]: answerTypes.NUMBER,
  [questionTypes.MULTIPLE_SELECT]: answerTypes.TEXT

  // Not using these types in the WAP:

  // [questionTypes.DATE_PICKER]: answerTypes.DATE,
  // [questionTypes.SELECT]: answerTypes.TEXT,
  // [questionTypes.CHECKBOX]: answerTypes.BOOLEAN,
  // [questionTypes.CHECKLIST]: answerTypes.TEXT
};

export {
  questionTypes,
  answerTypes,
  questionAnswerMap
};
