<template>
  <alert-modal
    title="Welcome To Your Master Menu"
    :buttons="[{ text: 'Okay', primary: true}]"
    :show-close-button="false"
    class="menu-help-modal"
    auto-width
  >
    <template #message>
      <div class="is-flex-align-start">
        <div class="pad-l-xs is-size-6 mar-b has-text-grey mar-b-lg">
          <p>Your point of sale menu is now ready to use in CardFree!</p>
          <p>Here are some things you should do to update your master menu:</p>
        </div>
        <div class="pad-x-sm dist-y-lg">
          <div class="is-flex align-center">
            <b-icon
              class="pad mar-r-md"
              type="is-grey-light"
              pack="fa"
              icon="pencil"
              size="is-large"
            />
            <div class="is-flex-column is-flex align-start mar-r">
              <h3 class="title is-spaced mar-b-xs is-5">Update Labels</h3>
              <p class="subtitle has-text-grey is-6">Create user friendly names & descriptions for your menu resources</p>
            </div>
          </div>
          <div class="is-flex align-center">
            <b-icon
              class="pad mar-r-md"
              type="is-grey-light"
              pack="fa"
              icon="camera"
              size="is-large"
            />
            <div class="is-flex-column is-flex align-start mar-r">
              <h3 class="title is-spaced mar-b-xs is-5">Add Item Images</h3>
              <p class="subtitle has-text-grey is-6">Upload item images for your customers to see</p>
            </div>
          </div>
          <div class="is-flex align-center">
            <b-icon
              class="pad mar-r-md"
              type="is-grey-light"
              pack="fa"
              size="is-large"
              icon="cog"
            />
            <div class="is-flex-column is-flex align-start mar-r">
              <h3 class="title is-spaced mar-b-xs is-5">Configure Details</h3>
              <p class="subtitle has-text-grey is-6">Set availability dates, quantity restrictions, default selected modifiers, and more!</p>
            </div>
          </div>
        </div>
      </div>
    </template>
  </alert-modal>
</template>

<script>
  import alertModal from '@/components/globals/alert-modal.vue';

  export default {
    name: 'MenuHelpModal',

    components: { alertModal }
  };
</script>

<style scoped lang="sass">
  .menu-help-modal
    max-width: 550px
</style>
