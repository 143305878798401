import { Model } from '@vuex-orm/core';


import http from '@/services/http';
import filterObjectKeys from '@/helpers/filter-object-keys';

export default class MerchantContact extends Model {
  static entity = 'merchantContacts'


  // FIELDS //////////////////////
  static fields() {
    return {
      id: this.attr(''),
      merchantId: this.attr(''),
      title: this.attr(''),
      description: this.attr(''),
      email: this.attr(''),
      phoneNumber: this.attr(''),
      externalUrl: this.attr('')
    };
  }

  // STATE //////////////////////
  static state() {
    return {
      fetching: false
    };
  }

  static $state() {
    return this.store().state.entities.merchantContacts;
  }

  // ACTIONS //////////////////////
  static async fetchMerchantContact(merchantId) {
    try {
      this.commit((state) => {
        state.fetching = true;
      });

      const response = await http.get(`merchants/${merchantId}/merchant_contacts`);

      this.create({ data: response.data.merchantContacts });
    }

    catch (error) {
      throw error;
    }

    finally {
      this.commit((state) => {
        state.fetching = false;
      });
    }
  }

  static async updateMerchantContact(merchantContact) {
    try {
      const acceptedKeys = [
        'title',
        'description',
        'email',
        'phoneNumber',
        'externalUrl'
      ];
      const response = await http.put(`merchant_contacts/${merchantContact.id}`, { merchantContact: filterObjectKeys(merchantContact, acceptedKeys) });

      this.update({ data: response.data.merchantContact });
    }

    catch (error) {
      throw error;
    }
  }

  static async addMerchantContact({ merchantId, merchantContact }) {
    try {
      const acceptedKeys = [
        'title',
        'description',
        'email',
        'phoneNumber',
        'externalUrl'
      ];
      const response = await http.post(`merchants/${merchantId}/merchant_contacts`, { merchantContact: filterObjectKeys(merchantContact, acceptedKeys) });

      this.insert({ data: response.data.merchantContact });
    }

    catch (error) {
      throw error;
    }
  }
}
