<template>
  <div :class="['image-upload', { 'mobile-centered': isMobileCentered, 'crop-image': showImageCropper }]">
    <div>
      <validation-provider
        v-slot="{ errors, classes }"
        slim
        :name="label"
        :rules="{ required, fileType: acceptedTypes, size: showFileSizeError && restrictFileSize ? maximumFileSizeInMb * 1000 : false }"
        :custom-messages="{
          size: restrictFileSize ? `File must be less than ${fileSizeDisplay(maximumFileSizeInMb)}` : '',
          required: 'You must select an image'
        }"
        :mode="validationMode"
      >
        <b-field
          :class="['image-upload-label', classes]"
          :message="errors"
          :type="{ 'is-danger': !!errors.length }"
        >
          <template v-if="!hideLabel" #label>
            <div>
              <span>{{ label }}</span><b-icon v-if="!!errors.length" icon="exclamation-circle" type="is-danger" class="mar-l-xs" />
              <span v-if="tooltip && Object.keys(tooltip).length" v-tippy="tooltip" style="margin-left: 0.5rem;" data-test-id="image-upload-tooltip">
                <b-icon
                  icon="info-square"
                  size="is-small"
                  type="is-grey"
                />
              </span>
              <span v-if="!required" class="sub-label"> (Optional)</span>
            </div>
            <div v-if="!disabled && restrictFileSize" class="is-size-7 has-text-weight-normal has-text-grey">
              Maximum file size: {{ fileSizeDisplay(maximumFileSizeInMb) }}
            </div>
            <template v-if="!disabled">
              <div
                v-for="hintMessage in hintMessages"
                :key="hintMessage"
                class="is-size-7 has-text-weight-normal has-text-grey"
              >
                {{ hintMessage }}
              </div>
            </template>
          </template>
          <div :class="['is-inline-block', {'is-full-width': isFullWidth}]">
            <transition name="fade-up">
              <b-message v-if="showFileSizeError" class="mar-y has-shadow is-compact" type="is-danger">
                <p>Selected Image: {{ fileSizeDisplay(fileSizeInMb) }}</p>
                <p>Required: {{ fileSizeDisplay(maximumFileSizeInMb) }} or less</p>
              </b-message>
            </transition>
            <transition name="fade-up">
              <b-message v-if="showImageSizeExactWarning" class="mar-y has-shadow is-compact" type="is-warning">
                <p>
                  Selected Image:
                  <b :class="imageSizeWarningWidth !== imageWidth && 'has-text-danger'">{{ imageWidth }}</b>
                  x
                  <b :class="imageSizeWarningHeight !== imageWidth && 'has-text-danger'">{{ imageHeight }}</b> pixels
                </p>
                <p>Recommended: <b>{{ imageSizeWarningWidth }}</b> x <b>{{ imageSizeWarningHeight }}</b> pixels</p>
              </b-message>
            </transition>
            <transition name="fade-up">
              <b-message v-if="showImageSizeMinimumWarning" class="box is-paddingless mar-y" type="is-warning">
                <p>
                  Selected Image:
                  <b :class="minWidth > imageWidth && 'has-text-danger'">{{ imageWidth }}</b>
                  x
                  <b :class="minHeight > imageHeight && 'has-text-danger'">{{ imageHeight }}</b> pixels
                </p>
                <p>Min. Recommended: <b>{{ minWidth }}</b> x <b>{{ minHeight }}</b> pixels</p>
              </b-message>
            </transition>
            <b-upload
              v-model="imageFile"
              :loading="loading"
              :disabled="(showImageCropper && !!_imagePreviewPath) || disabled"
              drag-drop
              class="with-preview mar-b-sm"
              @input="onFileSelected"
            >
              <div v-if="!!_imagePreviewPath" class="image-preview-container">
                <slot :imagePath="_imagePreviewPath" />
              </div>
              <div v-else class="has-text-grey-light pad has-text-centered">
                <b-icon icon="camera" size="is-medium" />
                <p>Drop your file here or click to upload</p>
              </div>
            </b-upload>
            <transition name="fade-down" mode="out-in">
              <div v-if="showConfirmDelete && imageUrl" key="confirm-delete" class="confirm-delete-container">
                <p style="line-height: 1.2" class="is-size-7 has-text-dark mar-r-sm">
                  Are you sure?<br>
                  This action can't be undone.
                </p>
                <div class="buttons">
                  <b-button
                    size="is-small"
                    type="is-light"
                    @click="showConfirmDelete = !showConfirmDelete"
                  >
                    Cancel
                  </b-button>
                  <b-button
                    class="is-light"
                    size="is-small"
                    type="is-danger"
                    @click="deleteImage"
                  >
                    Confirm
                  </b-button>
                </div>
              </div>
              <div v-else-if="!disabled" key="action-buttons" class="buttons">
                <template v-if="clearOnly">
                  <b-button
                    v-if="value || imageUrl"
                    size="is-small"
                    type="is-light"
                    icon-left="undo"
                    @click="onClearFileSelected"
                  >
                    {{ clearText }}
                  </b-button>
                </template>
                <template v-else>
                  <b-button
                    v-if="showDeleteButton && imageUrl && !value"
                    size="is-small"
                    type="is-danger"
                    class="is-light"
                    icon-left="trash-alt"
                    @click="handleDeleteImage"
                  >
                    {{ deleteText }}
                  </b-button>
                  <b-button
                    v-if="(showClearButton || showDeleteButton) && value"
                    size="is-small"
                    type="is-light"
                    icon-left="undo"
                    @click="onClearFileSelected"
                  >
                    {{ clearText }}
                  </b-button>
                </template>
              </div>
            </transition>
          </div>
        </b-field>
      </validation-provider>
    </div>
  </div>
</template>

<script>
  import { mapActions } from 'vuex';
  import imageCropper from './image-cropper.vue';

  export default {
    name: 'ImageUpload',
    props: {
      label: {
        type: String,
        required: true
      },
      tooltip: {
        type: Object,
        default: () => {}
      },
      disabled: {
        type: Boolean,
        default: false
      },
      hideLabel: {
        type: Boolean,
        default: false
      },
      restrictFileSize: {
        type: Boolean,
        default: false
      },
      maximumFileSizeInMb: {
        type: Number,
        default: 1
      },
      imageSizeWarningHeight: {
        type: Number,
        default: null
      },
      imageSizeWarningWidth: {
        type: Number,
        default: null
      },
      minWidth: {
        type: Number,
        default: null
      },
      minHeight: {
        type: Number,
        default: null
      },
      imageUrl: {
        type: String,
        default: null
      },
      acceptedTypes: {
        type: Array,
        required: true
      },
      deleteText: {
        type: String,
        default: 'Delete'
      },
      clearText: {
        type: String,
        default: 'Revert'
      },
      confirmDelete: {
        type: Boolean,
        default: true
      },
      showClearButton: {
        type: Boolean,
        default: false
      },
      showDeleteButton: {
        type: Boolean,
        default: false
      },
      loading: {
        type: Boolean,
        default: false
      },
      isMobileCentered: {
        type: Boolean,
        default: false
      },
      isFullWidth: {
        type: Boolean,
        default: false
      },
      isRequired: {
        type: Boolean,
        default: false
      },
      value: {
        type: [File, String],
        default: null
      },
      clearOnly: {
        type: Boolean,
        default: false
      },
      showImageCropper: {
        type: Boolean,
        default: false
      },
      required: {
        type: Boolean,
        default: false
      },
      validationMode: {
        type: String,
        default: undefined
      }
    },
    data() {
      return {
        imagePreviewPath: null,
        showConfirmDelete: false,
        imageFile: null,
        imageWidth: null,
        imageHeight: null,
        fileSizeInMb: null,
        hasExistingImage: !!this.imageUrl
      };
    },
    computed: {
      _imagePreviewPath() {
        return this.imagePreviewPath || this.imageUrl;
      },
      showFileSizeError() {
        return Boolean(this.imageFile && this.restrictFileSize)
          && this.fileSizeInMb >= this.maximumFileSizeInMb;
      },
      showImageSizeExactWarning() {
        return Boolean(this.imageSizeWarningWidth && this.imageSizeWarningHeight && this.imageFile)
          && (
            this.imageWidth !== this.imageSizeWarningWidth
            || this.imageHeight !== this.imageSizeWarningHeight
          );
      },
      showImageSizeMinimumWarning() {
        return Boolean(this.minWidth && this.minHeight && this.imageFile)
          && (
            this.imageWidth <= this.minWidth
            || this.imageHeight <= this.minHeight
          );
      },
      hintMessages() {
        const fileTypeMessage = this.acceptedTypes.length
          ? `Accepted file types: ${this.acceptedTypes.map(type => `.${type}`).join(', ')}`
          : null;
        const minResolutionMessage = this.minWidth && this.minHeight
          ? `Minimum recommended resolution: ${this.minWidth} x ${this.minHeight} pixels`
          : null;
        const maxResolutionMessage = this.imageSizeWarningWidth && this.imageSizeWarningHeight
          ? `Recommended resolution: ${this.imageSizeWarningWidth} x ${this.imageSizeWarningHeight} pixels`
          : null;


        return [fileTypeMessage, minResolutionMessage, maxResolutionMessage].filter(Boolean);
      }
    },
    watch: {
      imageUrl: 'clearLocalImageFile',
      imageFile(newValue) {
        this.setFormChanged({ hasChanged: !!newValue });
      }
    },
    methods: {
      ...mapActions('formStore', [
        'setFormChanged'
      ]),
      clearLocalImageFile() {
        this.imageFile = null;
      },
      onFileSelected(file) {
        if (this.showImageCropper) {
          const previewUrl = URL.createObjectURL(file);
          this.$buefy.modal.open({
            parent: this,
            component: imageCropper,
            hasModalCard: true,
            trapFocus: true,
            canCancel: false,
            customClass: 'auto-width',
            props: {
              imageUrl: previewUrl,
              imageType: this.imageFile && this.imageFile.type
            },
            events: {
              'crop-image': value => this.processFile(value),
              'cancel-crop': this.clearLocalImageFile
            }
          });
        }
        else {
          this.processFile(file);
        }
      },
      processFile(file) {
        const previewUrl = URL.createObjectURL(file);
        this.imagePreviewPath = previewUrl;
        const img = new Image();
        img.onload = () => {
          this.imageWidth = img.width;
          this.imageHeight = img.height;
          const bytesToMegaBytes = bytes => bytes / (1024 * 1024);
          this.fileSizeInMb = parseFloat(bytesToMegaBytes(file.size).toFixed(2));
        };
        img.src = previewUrl;
        this.$emit('input', file);
      },
      onClearFileSelected() {
        URL.revokeObjectURL(this.imagePreviewPath);
        this.imagePreviewPath = null;
        this.imageFile = null;
        if (this.hasExistingImage) {
          this.setFormChanged({ hasChanged: true });
        }
        this.$emit('input', null);
      },
      deleteImage() {
        this.imagePreviewPath = null;
        this.$emit('input', null);
        this.$emit('delete-image');
        this.showConfirmDelete = false;
      },
      handleDeleteImage() {
        if (this.confirmDelete) {
          this.showConfirmDelete = true;
        }
        else {
          this.deleteImage();
        }
      },
      fileSizeDisplay(fileSize) {
        return fileSize < 1 ? `${fileSize * 1000} KB` : `${fileSize} MB`;
      }
    }
  };
</script>

<style lang="sass" scoped>
::v-deep .upload-draggable.is-disabled
  opacity: 1 !important
.is-full-width
  width: 100%
.image-upload
  display: flex
  flex-direction: column
  margin-bottom: 1rem
  &:last-child
    margin-bottom: 0
  .image-preview-container
    background-image: url('/images/transp_bg.png')
    border: 1px solid #eee
    align-self: flex-start
    ::v-deep img
      display: block
      width: 100%
  &.crop-image
    ::v-deep.upload-draggable
      height: 150px !important
      width: 150px !important
      border-radius: 50% !important
      .image-preview-container
        height: 150px !important
        width: 150px !important
        border-radius: 50% !important
        position: absolute
        right: 0
        bottom: 0
        .cropped-image
          height: 150px !important
          width: 150px !important
          border-radius: 50% !important
  .preview-image
    display: block
  .confirm-delete-container
    width: 100%
    display: flex
    align-items: center
    justify-content: space-between
@media (max-width: $widescreen)
  .image-upload.mobile-centered
    flex-direction: column
    align-items: center
    .image-preview-container
      align-self: center
    .image-preview-image
      max-height: 300px
    .preview-image
      max-height: 300px
    .image-upload-label
      text-align: center
    .buttons
      justify-content: center
</style>
