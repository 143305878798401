<template>
  <div>
    <div
      v-for="modifierGroup in searchResults"
      :key="`modifier-group-${modifierGroup.id}`"
      :data-modifier-group-id="modifierGroup.id"
      :class="{
        'show-inventory': $_menuPermissions.UPDATE_INVENTORY,
        'hide-prices':!$_selectedMerchant.features.menuPrices
      }"
    >
      <div class="row sub-row">
        <span class="pad-y-sm">
          <b-icon
            v-if="$_featurePermissions.NESTED_MODIFIERS && modifierGroup.type"
            type="is-primary"
            class="mar-r-xs"
            pack="fad"
            :icon="modifierGroupIcons[modifierGroup.type]"
          />
          <span class="has-text-weight-bold is-size-6">{{ modifierGroup.displayName }}</span>
        </span>
        <span v-if="$_selectedMerchant.features.menuPrices" />
        <span v-if="$_menuPermissions.UPDATE_INVENTORY" />
        <span />
      </div>
    </div>
  </div>
</template>

<script>
  import merchantMixin from '@/mixins/merchant';
  import featurePermissionsMixin from '@/mixins/featurePermissions';
  import { modifierGroupTypes, modifierGroupPortionTemplates } from '@/constants/modifierGroups';

  export default {
    name: 'ModifierGroupSearchRow',

    mixins: [merchantMixin, featurePermissionsMixin],

    props: {
      searchResults: {
        type: Array,
        default: () => []
      },

      visibleModifierGroupIds: {
        type: Array,
        required: true
      }
    },

    data: () => ({
      modifierGroupIcons: {
        [modifierGroupTypes.PORTION]: 'chart-pie-alt',
        [modifierGroupTypes.COMBO]: 'layer-group'
      },
      modifierGroupTypes,
      modifierGroupPortionTemplates
    }),

    methods: {
      emitSharedModGroupsSearchAction(args) {
        this.$emit('shared-mod-group-search-action', args);
      },

      getModifierGroupDisabled(modifierGroup) {
        return !this.visibleModifierGroupIds.includes(modifierGroup.id);
      }
    }
  };
</script>

<style lang="sass" scoped>
  .row
    grid-template-columns: minmax(200px, 1fr) minmax(75px, 0.2fr) minmax(100px, 0.25fr)

    .show-inventory &
      grid-template-columns: minmax(200px, 1fr) minmax(75px, 0.2fr) minmax(145px, 200px) minmax(100px, 0.25fr)

    .hide-prices &
      grid-template-columns: minmax(200px, 1fr) minmax(145px, 200px) minmax(100px, 0.25fr)
</style>
