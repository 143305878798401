<template>
  <div class="is-flex-column gap-lg pad has-background-white-bis has-border has-border-grey-lightest has-radius">
    <div class="is-flex justify-between align-baseline">
      <p class="title is-6 mar-none">Minimum Purchase</p>
      <b-button
        v-if="!readOnly"
        outlined
        icon-left="trash-alt"
        type="is-danger is-light"
        @click="$emit('remove-constraint', minimumPurchaseConstraint)"
      />
    </div>
    <validated-text-input
      v-model="minimumPurchaseAmount"
      :disabled="readOnly"
      label="Minimum Purchase Amount"
      label-position="on-border"
      name="minimumPurchase"
      type="dollars"
      rules="required|min_value:0.01"
      class="mar-b-none"
      placeholder="Set a minimum purchase amount..."
      :mask-options="{ numeralDecimalScale: 2, numeralPositiveOnly: true }"
    />
    <excluded-item-attributes-dropdown
      v-model="minimumPurchaseConstraint.excludedItemAttributes"
      :read-only="readOnly"
      form-name="minimumPurhcaseConstraint"
    />
  </div>
</template>

<script>
  import { constraintTypes } from '@/constants/offers';

  export default {
    name: 'MinimumPurchaseConstraintCard',

    props: {
      minimumPurchaseConstraint: {
        type: Object,
        required: true
      },
      readOnly: {
        type: Boolean,
        default: false
      }
    },

    data() {
      return {
        constraintTypes
      };
    },

    computed: {
      minimumPurchaseAmount: {
        get() {
          return this.minimumPurchaseConstraint.minimum;
        },
        set(value) {
          this.updateConstraint({ minimum: Number(value) });
        }
      }
    },

    methods: {
      updateConstraint(newVals) {
        this.$emit('update-constraint', {
          ...this.minimumPurchaseConstraint,
          ...newVals
        });
      }
    }
  };
</script>
