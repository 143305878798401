<template>
  <div class="hours-container">
    <div v-for="day in daysOfWeek" :key="day" class="day-container is-open">
      <div class="day">
        {{ day }}
      </div>
      <div class="day-hours">
        <template v-if="filteredHoursByDestroy[day].length">
          <template v-for="({ open, close, destroy }, index) in form[day]">
            <div v-if="!destroy" :key="`${day}-${index}`" class="hours">
              <div class="is-flex hours-inputs">
                <div class="is-flex">
                  <validated-input
                    label-position="on-border"
                    label="From"
                    rules="required"
                    :name="`${day.toLowerCase()}-open-${index}`"
                    class="mar-none"
                  >
                    <b-timepicker
                      v-model="form[day][index].open"
                      :increment-minutes="1"
                      :default-minutes="0"
                      :value="open"
                      size="is-small"
                      inline
                      class="mar-r-lg"
                    />
                  </validated-input>
                  <validated-input
                    label-position="on-border"
                    :rules="{
                      required: true,
                      dateTimeIsAfter: `@${day.toLowerCase()}-open-${index}`
                    }"
                    label="To"
                    :name="`${day.toLowerCase()}-close-${index}`"
                    class="mar-none"
                  >
                    <b-timepicker
                      v-model="form[day][index].close"
                      :increment-minutes="1"
                      :default-minutes="0"
                      :min-time="open ? moment(open).add(1, 'minute').toDate() : null"
                      :value="close"
                      size="is-small"
                      inline
                      class="mar-r-lg"
                    />
                  </validated-input>
                </div>
                <numberinput-switch
                  v-model="form[day][index].orderLimit"
                  switch-label="No Limit"
                  :true-value="0"
                  :false-value="1"
                  class="numberinput-switch"
                />
              </div>
              <b-button
                v-if="$can('update','StoreOrderLimit')"
                class="mar-t-sm"
                icon-left="minus"
                outlined
                size="is-small"
                type="is-danger is-light"
                @click="handleDelete(day, index)"
              />
            </div>
          </template>
        </template>
        <template v-else><p class="is-flex align-center">No Schedules Configured for {{ day }}</p></template>
        <b-message
          v-if="invalidDays.includes(day)"
          key="sun-invalid"
          type="is-danger"
          size="is-small"
          class="is-compact has-shadow justify-self-start"
        >
          <p class="is-flex align-center">
            <b-icon custom-size="fa-lg" icon="exclamation-circle" type="is-danger" class="mar-r-xs" />
            {{ overlapErrorMessage }}
          </p>
        </b-message>
      </div>
      <div class="add-hours">
        <b-button
          v-if="$can('update','StoreOrderLimit')"
          icon-left="plus"
          size="is-small"
          type="is-primary is-light"
          :class="[{ 'mar-t-sm': filteredHoursByDestroy[day].length }]"
          @click="addHoursRow(day)"
        />
      </div>
    </div>
  </div>
</template>

<script>
  import moment from 'moment-timezone';
  import StoreOrderLimitSchedule from '@/store/classes/StoreOrderLimitSchedule';

  export default {
    name: 'StoreOrderLimitSchedules',

    props: {
      storeOrderLimitId: {
        type: Number,
        default: null
      }
    },

    data() {
      return {
        form: null,
        invalidDays: [],
        overlapErrorMessage: 'Scheduled start and end hours cannot overlap each other within the same day',
        daysOfWeek: ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday']
      };
    },

    computed: {
      storeOrderLimitSchedules() {
        return StoreOrderLimitSchedule.query().where('storeOrderLimitId', this.storeOrderLimitId).get();
      },

      formattedSchedule() {
        if (this.form) {
          const formValues = Object.values(this.$clone(this.form)).flatMap(schedule => schedule);
          return formValues.map(val => ({
            ...val,
            startTime: val.open ? moment(val.open).format('HH:mm') : null,
            endTime: val.close ? moment(val.close).format('HH:mm') : null
          }));
        }
        return [];
      },

      schedulesByDay() {
        const newHours = (existing, id, open, close, orderLimit, day) => [
          ...existing,
          { id, open, close, orderLimit, day }
        ].sort((a, b) => (a.open < b.open ? -1 : 1));

        return this.$clone(this.storeOrderLimitSchedules).reduce((obj, schedule) => {
          const { id, day, startTime, endTime, orderLimit } = schedule;
          if (startTime) {
            obj[day] = newHours(obj[day], id, startTime, endTime, orderLimit, day);
          }

          return obj;
        }, { Sunday: [], Monday: [], Tuesday: [], Wednesday: [], Thursday: [], Friday: [], Saturday: [] }); /* eslint-disable-line */
      },

      filteredHoursByDestroy() {
        const copy = this.$clone(this.form);
        Object.entries(this.form).forEach(([day, hours]) => {
          hours.forEach((h, i) => {
            if (h.destroy) {
              copy[day].splice(i, 1);
            }
          });
        });
        return copy;
      }
    },

    watch: {
      form: {
        deep: true,
        handler: 'handleFormChange',
        immediate: false
      },

      storeOrderLimitSchedules: {
        immediate: false,
        deep: true,
        handler: 'setForm'
      },

      invalidDays: {
        immediate: false,
        handler: 'handleInvalidDaysChange'
      }
    },

    created() {
      this.onCreated();
    },

    methods: {
      moment,

      onCreated() {
        this.setForm();
      },

      handleInvalidDaysChange(newVal) {
        this.$emit('invalid-days-validation', !!newVal.length);
      },

      setForm() {
        this.form = this.createForm(this.schedulesByDay);
      },

      handleFormChange() {
        if (this.form) {
          this.validateHours();
          this.$emit('schedule-updated', this.formattedSchedule);
        }
      },

      createForm(dayHours) {
        const obj = {};

        Object.entries(dayHours).forEach(([day, hours]) => {
          obj[day] = hours.map(({ open, close, id, orderLimit }) => ({
            id,
            open: moment(open, 'HH:mm').toDate(),
            close: moment(close, 'HH:mm').toDate(),
            orderLimit,
            day
          }));
        });

        return obj;
      },

      addHoursRow(day) {
        const storeOrderLimitSchedule = new StoreOrderLimitSchedule({ day, storeOrderLimitId: this.storeOrderLimitId });
        this.form[day].push(storeOrderLimitSchedule);
      },

      handleDelete(day, index) {
        if (this.form[day][index].id) {
          this.$set(this.form[day][index], 'destroy', true);
        }
        else {
          this.form[day].splice(index, 1);
        }
      },

      validateHours() {
        let allHoursValid = true;
        this.invalidDays = [];

        Object.entries(this.$clone(this.filteredHoursByDestroy)).forEach(([day, dayHours]) => {
          dayHours
            .sort((a, b) => (a.open < b.open ? -1 : 1))
            .forEach((hours, index) => {
              const currentClose = hours.close;
              const nextOpen = dayHours[index + 1]?.open;

              // verify that the close time for the current hours range
              // is before the open time for the next hours range
              if (nextOpen && currentClose >= nextOpen) {
                allHoursValid = false;
                this.invalidDays.push(day);
              }
            });
        });

        return allHoursValid;
      }
    }
  };
</script>

<style lang='sass' scoped>
  .hours-container
    border: 1px solid $grey-lighter
    border-radius: $radius
    overflow: hidden

  .day-container
    display: grid
    grid-template-columns: 11rem 1fr auto
    gap: $size-large
    transition: 333ms

    &:not(:last-child)
      border-bottom: 1px solid $grey-lighter

    > *
      padding-top: $size-normal
      padding-bottom: $size-normal
      &:first-child
        padding-left: $size-normal
      &:last-child
        padding-right: $size-normal

  .day
    background-color: $white-bis
    border-right: 1px solid $grey-lighter
    font-weight: bold

  .day-hours
    display: grid
    gap: $size-medium
    transition: 333ms
    opacity: 0.5

    .is-open &
      opacity: 1

  .hours
    display: grid
    grid-template-columns: 1fr min-content
    gap: $size-small
    align-items: start

    ::v-deep
      .label,
      .field
        margin: 0 !important

  @media (max-width: 1400px)
    .day-hours
      gap: $size-extra-large
    .hours-inputs
      flex-direction: column
    .numberinput-switch
      margin-top: $size-medium
</style>
