<template>
  <div class="user password-page">
    <div class="card">
      <div class="card-content">
        <user-password-form />
      </div>
    </div>
  </div>
</template>

<script>
  import userPasswordForm from './user-password-form.vue';



  export default {
    name: 'UserPassword',

    components: {
      userPasswordForm
    }
  };
</script>
