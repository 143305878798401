import logger from '@/services/logger';



function numberDisplay(value) {
  if (Number.isNaN(Number(value))) {
    logger.warn(`The value ${JSON.stringify(value)} is not a number`);
    return value;
  }

  return new Intl.NumberFormat().format(value);
}



export default numberDisplay;
