<template>
  <div class="card">
    <div class="card-content">
      <validated-input
        label="Offer Type"
        name="offerType"
        sub-label="Select what type of discount to apply to an order"
      >
        <toggle-buttons
          v-model="offerType"
          label-left="Check"
          sub-label-left="Reduce the entire check amount"
          :value-left="valuePropositionTypes.CHECK.FE_TYPE"
          label-right="Item"
          sub-label-right="Reduce the cost of a menu item"
          :value-right="valuePropositionTypes.ITEM.FE_TYPE"
          :disabled="offerReadOnly"
        />
      </validated-input>
    </div>

    <div class="card-content">
      <div class="is-flex-column gap-y-lg">
        <div class="is-flex-wrap gap-x-xl">
          <validation-observer v-slot="{ errors }">
            <b-field
              :disabled="offerReadOnly"
              label="Amount"
              custom-class="required-indicator"
            >
              <validated-text-input
                v-model="discountAmountValue"
                :placeholder="`Set a ${discountAmountType === discountAmountTypes.OVERRIDE ? 'override' : 'discount'} amount...`"
                label="Amount"
                hide-label
                name="amount"
                :class="['control', { 'percent': discountAmountType === discountAmountTypes.PERCENT }]"
                :rules="discountAmountType === discountAmountTypes.PERCENT ? { between: [1, 100], required: true } : { min_value: 0.01, required: true }"
                :type="discountAmountType === discountAmountTypes.PERCENT ? 'number' : 'dollars'"
                :mask-options="{ numeralDecimalScale: 2, numeralPositiveOnly: true }"
                :errors="errors.discountAmount"
                :disabled="offerReadOnly"
              />
              <b-select v-model="discountAmountType" :disabled="offerReadOnly">
                <option :value="discountAmountTypes.DOLLAR">$ Off</option>
                <option :value="discountAmountTypes.PERCENT">% Off</option>
                <option v-if="offerType === valuePropositionTypes.ITEM.FE_TYPE" :value="discountAmountTypes.OVERRIDE">Price Override</option>
              </b-select>
            </b-field>
            <validated-text-input
              v-if="value.valueProposition.valuePropositionType === valuePropositionTypes.CHECK.PERCENT"
              v-model="value.valueProposition.maximumDiscount"
              name="maxDiscount"
              label="Max Discount"
              tooltip="Enter the maximum dollar amount this offer can be redeemed for"
              type="dollars"
              :rules="{ min_value: 0.01 }"
              has-icon
              icon-right="times-circle"
              icon-right-clickable
              :disabled="offerReadOnly"
              @icon-right-click="value.valueProposition.maximumDiscount = null"
            />
          </validation-observer>

          <excluded-item-attributes-dropdown
            v-if="offerType === valuePropositionTypes.CHECK.FE_TYPE"
            v-model="value.valueProposition.excludedItemAttributes"
            :read-only="offerReadOnly"
            form-name="checkOffer"
          />

          <validated-input
            v-else
            class="dropdown-field"
            name="itemType"
            label="Item Type"
            tooltip="Select items, categories, or item groups that apply to this offer"
            rules="required"
          >
            <b-select
              v-model="itemOfferType"
              placeholder="Select an item type..."
              :disabled="offerReadOnly"
              expanded
            >
              <option
                v-for="( type, key ) in itemResourceTypes"
                :key="key"
                :value="type"
              >
                {{ type }}
              </option>
            </b-select>
          </validated-input>
        </div>

        <template v-if="offerType === valuePropositionTypes.ITEM.FE_TYPE">
          <div class="is-flex-column gap-lg pad has-background-white-bis has-border has-border-grey-lightest has-radius">
            <template v-if="itemOfferType === itemResourceTypes.ITEM">
              <b-field label="Offer applies to...">
                <radio-button
                  v-model="itemGroupType"
                  name="valuePropositionItemGroupType"
                  :disabled="offerReadOnly"
                  :native-value="itemGroupTypes.ALL_ITEMS"
                >
                  Any Item
                </radio-button>
                <radio-button
                  v-model="itemGroupType"
                  name="valuePropositionItemGroupType"
                  :disabled="offerReadOnly"
                  :native-value="itemGroupTypes.MENU_ITEMS"
                >
                  Specific Items
                </radio-button>
              </b-field>

              <excluded-item-attributes-dropdown
                v-if="itemGroupType === itemGroupTypes.ALL_ITEMS"
                v-model="value.valueProposition.itemGroup.excludedItemAttributes"
                :read-only="offerReadOnly"
                form-name="allItemsOffer"
              />
              <item-offer-card
                v-else
                v-bind="itemOfferProps(itemResourceTypes.ITEM)"
                v-on="itemOfferHandlers(itemResourceTypes.ITEM)"
              />
            </template>

            <category-offer-card
              v-if="itemOfferType === itemResourceTypes.CATEGORY"
              v-model="value.valueProposition.itemGroup"
              v-bind="itemOfferProps(itemResourceTypes.CATEGORY)"
              v-on="itemOfferHandlers(itemResourceTypes.CATEGORY)"
            />
            <item-group-offer-card
              v-if="itemOfferType === itemResourceTypes.ITEM_GROUP"
              v-bind="itemOfferProps(itemResourceTypes.ITEM_GROUP)"
              v-on="itemOfferHandlers(itemResourceTypes.ITEM_GROUP)"
            />
          </div>
          <div class="is-flex-wrap gap-x-xl">
            <validated-input
              label="Max Quantity"
              tooltip="Set the maximum quantity of items to be discounted on this offer"
              name="maxQuantity"
              rules="required"
            >
              <b-numberinput
                v-model="value.valueProposition.maximumDiscountedQuantity"
                :disabled="offerReadOnly"
                :editable="!offerReadOnly"
                controls-position="compact"
                min="1"
              />
            </validated-input>
            <validated-input
              v-if="offerType === valuePropositionTypes.ITEM.FE_TYPE"
              class="dropdown-field"
              name="applyDiscountTo"
              label="Apply Discount To..."
              tooltip="Define the item discount basis for your offer"
              rules="required"
              :custom-messages="{ required: invalidDiscountBasisMessage }"
            >
              <b-checkbox
                class="is-hidden"
                :value="validDiscountBasis || null"
                :disabled="offerReadOnly"
              />
              <b-select
                v-model="value.valueProposition.itemDiscountBasis"
                placeholder="Select a discount basis..."
                :disabled="offerReadOnly"
              >
                <option v-for="{ id, display } in selectableItemDiscountBases" :key="`basis${id}`" :value="id">{{ display }}</option>
              </b-select>
            </validated-input>
            <template v-if="showDiscountedUnitPrecedence">
              <b-field label="Discount for...">
                <radio-button
                  v-model="value.valueProposition.discountedUnitPrecedenceType"
                  name="discountedUnitPrecedenceType"
                  :disabled="offerReadOnly"
                  :native-value="discountedUnitPrecedenceTypes.LOWEST_UNIT_PRICE_FIRST"
                >
                  Lowest Priced Item
                </radio-button>
                <radio-button
                  v-model="value.valueProposition.discountedUnitPrecedenceType"
                  name="discountedUnitPrecedenceType"
                  :disabled="offerReadOnly"
                  :native-value="discountedUnitPrecedenceTypes.HIGHEST_UNIT_PRICE_FIRST"
                >
                  Highest Priced Item
                </radio-button>
              </b-field>
            </template>
          </div>
        </template>
      </div>
    </div>

    <div class="card-content">
      <p class="title is-6">Constraints</p>
      <p class="subtitle is-7 has-text-grey mar-b">Define limitations about orders this offer can be applied to</p>
      <dropdown-menu :disabled="offerReadOnly || !unselectedConstraints.length">
        <b-button
          slot="trigger"
          type="is-primary is-light"
          icon-left="plus"
          :disabled="offerReadOnly"
          class="mar-b"
        >
          Constraint
        </b-button>

        <b-dropdown-item
          v-for="constraintType in unselectedConstraints"
          :key="constraintType"
          class="pad-r-sm is-flex justify-between"
          aria-role="listitem"
          @click="addConstraint(constraintType)"
        >
          <span class="mar-r-sm">{{ getConstraintTypeDisplay(constraintType) }}</span>
          <b-icon icon="arrow-right" />
        </b-dropdown-item>
      </dropdown-menu>

      <div v-if="value.constraints.length" class="is-grid gap-lg col-1">
        <item-offer-constraint-card
          v-for="(itemOfferConstraint, idx) in itemOfferConstraints"
          :key="`itemOfferConstraint${idx}`"
          :read-only="offerReadOnly"
          :item-offer-constraint="itemOfferConstraint"
          :count="idx + 1"
          v-on="constraintHandlers"
        />
        <minimum-purchase-constraint-card
          v-if="minimumPurchaseConstraint"
          :read-only="offerReadOnly"
          :minimum-purchase-constraint="minimumPurchaseConstraint"
          v-on="constraintHandlers"
        />
        <fulfillment-type-constraint-card
          v-if="fulfillmentTypeConstraint"
          :read-only="offerReadOnly"
          :fulfillment-type-constraint="fulfillmentTypeConstraint"
          v-on="constraintHandlers"
        />
        <platform-type-constraint-card
          v-if="platformTypeConstraint"
          :read-only="offerReadOnly"
          :platform-type-constraint="platformTypeConstraint"
          v-on="constraintHandlers"
        />

        <location-constraint-card
          v-if="locationConstraint"
          :read-only="offerReadOnly"
          :location-constraint="locationConstraint"
          :pos-discount-code="posDisCountCode"
          v-on="constraintHandlers"
        />

        <menu-type-constraint-card
          v-if="menuTypeConstraint"
          :read-only="offerReadOnly"
          :menu-type-constraint="menuTypeConstraint"
          v-on="constraintHandlers"
        />
      </div>
    </div>

    <div class="card-content">
      <validated-input
        rules="required"
        label="POS Discount Code"
        name="posDiscountCode"
        tooltip="The POS discount code associated with the offer"
        tooltip-placement="right"
        :custom-messages="{ required: 'You must select a POS discount code to be associated with your offer' }"
      >
        <b-checkbox
          class="is-hidden"
          :value="value.cardfreePosDiscountConfigurationPublicId"
        />
        <div v-if="value.cardfreePosDiscountConfigurationPublicId" class="is-flex align-center">
          <p>{{ posDiscountCodeName || 'NO NAME' }}</p>
          <b-button
            v-if="!offerReadOnly"
            class="mar-l"
            icon-left="minus"
            outlined
            size="is-small"
            type="is-danger is-light"
            @click="handlePosDiscountCodeChange()"
          />
        </div>
        <b-button
          v-else-if="posDiscountCodes.length"
          :disabled="!posDiscountCodes.length"
          type="is-primary is-light"
          icon-left="plus"
          @click="openAddPosDiscountCodeModal"
        >
          POS Discount Code
        </b-button>
        <b-message
          v-else-if="value && !value.cardfreePosDiscountConfigurationPublicId"
          type="is-warning"
          has-icon
          class="is-compact has-shadow mar-t-xs"
        >
          There is no POS Discount Code configured for this merchant
        </b-message>
      </validated-input>
    </div>

    <div v-if="$_selectedMerchant.hasAutoApplicableOffers" class="card-content">
      <validated-input
        label="Auto Applicable"
        name="autoApplicable"
        rules="required"
        tooltip="This offer will be applied to the next eligible transaction automatically"
        :custom-messages="{ required: 'The maximum number of auto-applicable offers of 3 has already been reached' }"
      >
        <b-checkbox
          class="is-hidden"
          :value="canSetAutoApplicable || (!canSetAutoApplicable && !value.autoApplicable) || null"
        />
        <b-switch
          v-model="value.autoApplicable"
          :disabled="offerReadOnly"
          class="has-text-weight-bold"
        />
      </validated-input>
    </div>
  </div>
</template>

<script>
  import { valuePropositionTypes, discountAmountTypes, constraintTypes, itemDiscountBases, discountedUnitPrecedenceTypes } from '@/constants/offers';
  import { itemAttributes, itemGroupTypes, itemResourceTypes } from '@/constants/items';
  import merchantMixin from '@/mixins/merchant';
  import { constantCase } from 'change-case';
  import searchSelectModal from '@/components/globals/search-select-modal.vue';
  import PosDiscountConfiguration from '@/store/classes/PosDiscountConfiguration';
  import itemOfferCard from '@/components/pages/offers/modal-steps/item-offer-types/item-offer-card.vue';
  import categoryOfferCard from '@/components/pages/offers/modal-steps/item-offer-types/category-offer-card.vue';
  import itemGroupOfferCard from '@/components/pages/offers/modal-steps/item-offer-types/item-group-offer-card.vue';
  import itemOfferConstraintCard from '@/components/pages/offers/modal-steps/constraints/item-offer-constraint-card.vue';
  import minimumPurchaseConstraintCard from '@/components/pages/offers/modal-steps/constraints/minimum-purchase-constraint-card.vue';
  import fulfillmentTypeConstraintCard from '@/components/pages/offers/modal-steps/constraints/fulfillment-type-constraint-card.vue';
  import platformTypeConstraintCard from '@/components/pages/offers/modal-steps/constraints/platform-type-constraint-card.vue';
  import locationConstraintCard from '@/components/pages/offers/modal-steps/constraints/location-constraint-card.vue';
  import menuTypeConstraintCard from '@/components/pages/offers/modal-steps/constraints/menu-type-constraint-card.vue';
  import ItemGroup from '@/helpers/classes/ItemGroup';
  import Offer from '@/store/classes/Offer';

  export default {
    name: 'OfferTypeStep',
    components: {
      itemOfferCard,
      categoryOfferCard,
      itemGroupOfferCard,
      itemOfferConstraintCard,
      minimumPurchaseConstraintCard,
      fulfillmentTypeConstraintCard,
      platformTypeConstraintCard,
      locationConstraintCard,
      menuTypeConstraintCard
    },

    mixins: [merchantMixin],

    props: {
      value: {
        type: Object,
        required: true
      },

      offerReadOnly: {
        type: Boolean,
        required: true
      }
    },

    data() {
      return {
        // constants
        activeConstraintTypes: [
          constraintTypes.ITEM_OFFER,
          constraintTypes.MINIMUM_PURCHASE,
          constraintTypes.FULFILLMENT_TYPE,
          constraintTypes.PLATFORM_TYPE,
          constraintTypes.LOCATION,
          constraintTypes.MENU_TYPE
        ],
        valuePropositionTypes,
        discountAmountTypes,
        constraintTypes,
        itemAttributes,
        itemGroupTypes,
        itemResourceTypes,
        itemDiscountBases,
        discountedUnitPrecedenceTypes,

        // component data
        selectedItemOfferType: itemResourceTypes.ITEM
      };
    },

    computed: {
      canSetAutoApplicable() {
        return Offer.canSetAutoApplicable();
      },

      merchantSupportsMultiPos() {
        return this.$_selectedMerchant.features.supportsMultiPosConstraintsAndValuePropositions;
      },

      offerType: {
        get() {
          return [
            valuePropositionTypes.ITEM.DOLLAR,
            valuePropositionTypes.ITEM.PERCENT,
            valuePropositionTypes.ITEM.OVERRIDE
          ].some(type => this.value.valueProposition?.valuePropositionType === type) ? valuePropositionTypes.ITEM.FE_TYPE : valuePropositionTypes.CHECK.FE_TYPE;
        },

        set(value) {
          this.handleOfferTypeChange(value);
        }
      },

      itemOfferType: {
        get() {
          if (!this.value.valueProposition.itemGroup) return null;
          switch (this.value.valueProposition.itemGroup.itemGroupType) {
            case itemGroupTypes.ALL_ITEMS:
            case itemGroupTypes.MENU_ITEMS:
              return itemResourceTypes.ITEM;
            case itemGroupTypes.CATEGORIES:
              return itemResourceTypes.CATEGORY;
            default:
              return itemResourceTypes.ITEM_GROUP;
          }
        },

        set(itemOfferType) {
          const itemGroupType = itemOfferType === itemResourceTypes.ITEM ? itemGroupTypes.ALL_ITEMS : itemGroupTypes.CATEGORIES;
          this.value.valueProposition.itemGroup.updateType(itemGroupType);
          let itemDiscountBasis;
          if (itemOfferType === itemResourceTypes.ITEM) {
            itemDiscountBasis = [itemDiscountBases.MAIN_MENU_ITEM.id, itemDiscountBases.MAIN_MENU_ITEM_ALL_MODS.id].includes(this.value.valueProposition.itemDiscountBasis)
              ? this.value.valueProposition.itemDiscountBasis
              : itemDiscountBases.MAIN_MENU_ITEM.id;
          }
          else if (itemOfferType === itemResourceTypes.CATEGORY) {
            itemDiscountBasis = [itemDiscountBases.MAIN_MENU_ITEM.id, itemDiscountBases.MAIN_MENU_ITEM_ALL_MODS.id].includes(this.value.valueProposition.itemDiscountBasis)
              ? this.value.valueProposition.itemDiscountBasis
              : itemDiscountBases.MAIN_MENU_ITEM.id;
          }
          this.handleValuePropositionInput({ itemDiscountBasis });
        }
      },

      discountAmountValue: {
        get() {
          const { percentDiscount, dollarDiscount, discountedPricePerUnit } = this.value.valueProposition;
          if (!percentDiscount && !dollarDiscount && !discountedPricePerUnit) {
            return null;
          }
          switch (this.discountAmountType) {
            case discountAmountTypes.OVERRIDE:
              return discountedPricePerUnit;
            case discountAmountTypes.DOLLAR:
              return dollarDiscount;
            case discountAmountTypes.PERCENT:
              return parseFloat(percentDiscount * 100);
            default:
              return null;
          }
        },

        set(value) {
          let payload = {};
          switch (this.discountAmountType) {
            case discountAmountTypes.OVERRIDE:
              payload = { discountedPricePerUnit: Number(value) };
              break;
            case discountAmountTypes.DOLLAR:
              payload = { dollarDiscount: Number(value) };
              break;
            case discountAmountTypes.PERCENT:
              payload = { percentDiscount: Number(parseFloat(value / 100).toFixed(2)) };
              break;
            default:
              payload = {};
          }
          this.handleValuePropositionInput(payload);
        }
      },

      discountAmountType: {
        get() {
          switch (this.value.valueProposition?.valuePropositionType) {
            case valuePropositionTypes.ITEM.OVERRIDE:
              return discountAmountTypes.OVERRIDE;
            case valuePropositionTypes.CHECK.DOLLAR:
            case valuePropositionTypes.ITEM.DOLLAR:
              return discountAmountTypes.DOLLAR;
            case valuePropositionTypes.CHECK.PERCENT:
            case valuePropositionTypes.ITEM.PERCENT:
            default:
              return discountAmountTypes.PERCENT;
          }
        },

        set(value) {
          const payload = {
            percentDiscount: null,
            dollarDiscount: null,
            maximumDiscount: null,
            discountedPricePerUnit: null
          };
          const isCheckType = this.offerType === valuePropositionTypes.CHECK.FE_TYPE;
          switch (value) {
            case discountAmountTypes.OVERRIDE:
              payload.valuePropositionType = valuePropositionTypes.ITEM.OVERRIDE;
              payload.itemDiscountBasis = this.itemGroupType === itemGroupTypes.MENU_ITEMS
                ? Math.max(this.value.valueProposition.itemDiscountBasis, itemDiscountBases.MAIN_MENU_ITEM_MATCHING_MODS.id)
                : Math.min(this.value.valueProposition.itemDiscountBasis, itemDiscountBases.MAIN_MENU_ITEM_ALL_MODS.id);
              break;
            case discountAmountTypes.DOLLAR:
              payload.valuePropositionType = isCheckType ? valuePropositionTypes.CHECK.DOLLAR : valuePropositionTypes.ITEM.DOLLAR;
              break;
            case discountAmountTypes.PERCENT:
            default:
              payload.valuePropositionType = isCheckType ? valuePropositionTypes.CHECK.PERCENT : valuePropositionTypes.ITEM.PERCENT;
              break;
          }

          this.handleValuePropositionInput(payload);
        }
      },

      itemGroupType: {
        get() {
          return this.value.valueProposition.itemGroup?.itemGroupType;
        },
        set(itemGroupType) {
          const excludedItemAttributes = itemGroupType === itemGroupTypes.MENU_ITEMS ? [] : this.allItemAttrs;
          this.value.valueProposition.itemGroup.updateType(itemGroupType, excludedItemAttributes);

          let itemDiscountBasis;
          if (this.discountAmountType === discountAmountTypes.OVERRIDE) {
            itemDiscountBasis = itemGroupType === itemGroupTypes.MENU_ITEMS
              ? Math.max(this.value.valueProposition.itemDiscountBasis, itemDiscountBases.MAIN_MENU_ITEM_MATCHING_MODS.id)
              : Math.min(this.value.valueProposition.itemDiscountBasis, itemDiscountBases.MAIN_MENU_ITEM_ALL_MODS.id);
          }
          else {
            itemDiscountBasis = itemGroupType === itemGroupTypes.MENU_ITEMS
              ? this.value.valueProposition.itemDiscountBasis || itemDiscountBases.MAIN_MENU_ITEM.id
              : Math.min(this.value.valueProposition.itemDiscountBasis, itemDiscountBases.MAIN_MENU_ITEM_ALL_MODS.id);
          }

          this.handleValuePropositionInput({ itemDiscountBasis });
        }
      },

      missingResources() {
        switch (this.itemOfferType) {
          case itemResourceTypes.ITEM:
            return this.merchantSupportsMultiPos
              ? this.value.valueProposition.itemGroup.missingPosItems()
              : this.value.valueProposition.itemGroup.missingMenuItems();
          case itemResourceTypes.CATEGORY:
            return this.value.valueProposition.itemGroup.missingCategories();
          case itemResourceTypes.ITEM_GROUP:
          default:
            return [];
        }
      },

      constraintHandlers() {
        return {
          'remove-constraint': this.removeConstraint,
          'update-constraint': this.updateConstraint
        };
      },

      minimumPurchaseConstraint() {
        return this.getConstraintsByType(constraintTypes.MINIMUM_PURCHASE)[0];
      },

      itemOfferConstraints() {
        return this.getConstraintsByType(constraintTypes.ITEM_OFFER);
      },

      fulfillmentTypeConstraint() {
        return this.getConstraintsByType(constraintTypes.FULFILLMENT_TYPE)[0];
      },

      platformTypeConstraint() {
        return this.getConstraintsByType(constraintTypes.PLATFORM_TYPE)[0];
      },

      locationConstraint() {
        return this.getConstraintsByType(constraintTypes.LOCATION)[0];
      },

      menuTypeConstraint() {
        return this.getConstraintsByType(constraintTypes.MENU_TYPE)[0];
      },

      posDiscountCodes() {
        return PosDiscountConfiguration.query().with('posDiscountStoreConfigurations').get();
      },

      posDisCountCode() {
        return this.posDiscountCodes.find(pdc => pdc.publicId === this.value.cardfreePosDiscountConfigurationPublicId);
      },

      posDiscountCodeName() {
        return this.posDisCountCode?.name;
      },

      unselectedConstraints() {
        const selectedConstraintTypes = this.value.constraints.map(constraint => constraint.constraintType);
        return this.activeConstraintTypes.filter(
          // Users can add multiple ITEM_OFFER constraints, so it should always be allowed
          constraintType => !selectedConstraintTypes.includes(constraintType) || constraintType === constraintTypes.ITEM_OFFER
        );
      },

      selectableItemDiscountBases() {
        const isOverrideType = this.discountAmountType === discountAmountTypes.OVERRIDE;
        const isSpecificItems = this.itemGroupType === itemGroupTypes.MENU_ITEMS;

        if (isOverrideType && isSpecificItems) {
          return itemDiscountBases;
        }


        if (this.discountAmountType !== discountAmountTypes.OVERRIDE) {
          if (this.itemGroupType === itemGroupTypes.ALL_ITEMS) {
            return Object.fromEntries(
              Object.entries(itemDiscountBases).filter(([key]) => ['MAIN_MENU_ITEM', 'MAIN_MENU_ITEM_ALL_MODS'].includes(key))
            );
          }
          else {
            return itemDiscountBases;
          }
        }

        const filterKeys = this.itemGroupType === itemGroupTypes.MENU_ITEMS
          ? ['MAIN_MENU_ITEM_MATCHING_MODS', 'MATCHING_MODS']
          : ['MAIN_MENU_ITEM', 'MAIN_MENU_ITEM_ALL_MODS'];

        return Object.fromEntries(
          Object.entries(itemDiscountBases).filter(([key]) => filterKeys.includes(key))
        );
      },

      validDiscountBasis() {
        switch (this.value.valueProposition?.itemDiscountBasis) {
          case itemDiscountBases.MAIN_MENU_ITEM.id:
          case itemDiscountBases.MAIN_MENU_ITEM_ALL_MODS.id:
            return !this.value.valueProposition.itemGroup?.requiredModifications?.length;
          case itemDiscountBases.MAIN_MENU_ITEM_MATCHING_MODS.id:
            // The UI currently supports only one modifier being selected, but in the future mulitple will be allowed
            return this.value.valueProposition.itemGroup?.requiredModifications?.length > 0;
          case itemDiscountBases.MATCHING_MODS.id:
            return this.value.valueProposition.itemGroup?.requiredModifications?.length === 1;
          default:
            return true;
        }
      },

      invalidDiscountBasisMessage() {
        const messageStem = 'The offer discount';
        if (!this.validDiscountBasis) {
          if (this.value.valueProposition.itemDiscountBasis === itemDiscountBases.MAIN_MENU_ITEM.id) {
            return `${messageStem} only applies to the main menu item. Please remove any selected modifiers.`;
          }
          else if (this.value.valueProposition.itemDiscountBasis === itemDiscountBases.MAIN_MENU_ITEM_ALL_MODS.id) {
            return `${messageStem} applies to all modifiers. Please remove any selected modifiers.`;
          }
          else {
            return `${messageStem} requires a modifier to be selected.`;
          }
        }
        else {
          return '';
        }
      },

      allItemAttrs() {
        return Object.values(itemAttributes).map(itemAttr => itemAttr.id);
      },

      showDiscountedUnitPrecedence() {
        const isDollarType = this.value.valueProposition.valuePropositionType === valuePropositionTypes.ITEM.DOLLAR;
        const isPercentType = this.value.valueProposition.valuePropositionType === valuePropositionTypes.ITEM.PERCENT;
        const isOverrideType = this.value.valueProposition.valuePropositionType === valuePropositionTypes.ITEM.OVERRIDE;

        return isDollarType || isPercentType || isOverrideType;
      },

      showModifierRestriction() {
        return this.value.valueProposition.itemDiscountBasis === itemDiscountBases.MAIN_MENU_ITEM_MATCHING_MODS.id
          || this.value.valueProposition.itemDiscountBasis === itemDiscountBases.MATCHING_MODS.id;
      }
    },

    watch: {
      showModifierRestriction(newValue) {
        if (!newValue && this.value.valueProposition.itemGroup) {
          const updatedValueProposition = this.$clone(this.value.valueProposition);
          updatedValueProposition.itemGroup.requiredModifications = [];
          this.handleInput('valueProposition', updatedValueProposition);
        }
      }
    },

    methods: {
      onCreated() {
        this.discountAmountType = [
          valuePropositionTypes.ITEM.DOLLAR,
          valuePropositionTypes.CHECK.DOLLAR
        ].some(type => this.value.valueProposition?.valuePropositionType === type) ? 'dollar' : 'percent';

        if (!this.value.valueProposition.discountedUnitPrecedenceType) {
          this.$set(this.value.valueProposition, 'discountedUnitPrecedenceType', this.discountedUnitPrecedenceTypes.HIGHEST_UNIT_PRICE_FIRST);
        }
      },

      itemOfferProps(offerType) {
        const props = {
          'selected-resources': [],
          'missing-resources': [],
          'read-only': this.offerReadOnly
        };

        if (offerType === itemResourceTypes.ITEM) {
          props['selected-resources'] = this.merchantSupportsMultiPos
            ? this.value.valueProposition.itemGroup.posItems
            : this.value.valueProposition.itemGroup.menuItems;
          props['enable-modifier-restrictions'] = this.itemGroupType === itemGroupTypes.MENU_ITEMS && this.showModifierRestriction;
        }
        else if (offerType === itemResourceTypes.CATEGORY) {
          props['selected-resources'] = this.value.valueProposition.itemGroup.categoryObjects;
          props['enable-modifier-restrictions'] = this.discountAmountType !== discountAmountTypes.OVERRIDE && this.showModifierRestriction;
        }
        if (offerType === itemResourceTypes.ITEM || offerType === itemResourceTypes.CATEGORY) {
          props['required-modifications'] = this.value.valueProposition.itemGroup.requiredModifications;
          props['missing-resources'] = this.missingResources;
        }

        return props;
      },


      itemOfferHandlers(offerType) {
        const events = {
          'add-resource': this.merchantSupportsMultiPos && offerType === itemResourceTypes.ITEM ? this.addPosSpecificValuePropResource : this.addValuePropResource,
          'remove-resource': this.merchantSupportsMultiPos && offerType === itemResourceTypes.ITEM ? this.removePosSpecificValuePropResource : this.removeValuePropResource
        };
        if (offerType === itemResourceTypes.ITEM || offerType === itemResourceTypes.CATEGORY) {
          events['update-required-modifications'] = $event => this.value.valueProposition.itemGroup.updateRequiredModifications($event);
        }
        return events;
      },

      // ------------------- POS SPECIFIC VALUE PROPOSITION RESOURCES -------------------

      addPosSpecificValuePropResource(resource) {
        const { itemGroup } = this.$clone(this.value.valueProposition);
        // Each menu item should map to at least one POS item, but in the case of bad data,
        // we need to handle menu items with and without POS items
        const menuItem = resource.posItems?.length ? resource.menuItem : resource;

        if (resource.posItems?.length) {
          const { posItem } = resource;
          const posItemResources = [...itemGroup.posItems];
          posItemResources.push(posItem);
          const updatedIds = [...itemGroup.posItemIds, posItem.id];
          this.value.valueProposition.itemGroup.updateResources('posItems', posItemResources, updatedIds);
        }

        // Since multiple pos items may be selected for a single menu item, we need to add the menu item
        // to the item group only if it's not already present
        if (!itemGroup.menuItemIds.find(id => id === menuItem.id)) {
          this.addValuePropResource({ type: 'menuItems', resource: menuItem });
        }
      },

      removePosSpecificValuePropResource(posItem) {
        const { itemGroup } = this.$clone(this.value.valueProposition);
        let posItemResources = itemGroup.posItems;
        posItemResources = posItemResources.filter(posResource => posResource.id !== posItem.id);
        const updatedIds = itemGroup.posItemIds.filter(id => id !== posItem.id);
        this.value.valueProposition.itemGroup.updateResources('posItems', posItemResources, updatedIds);

        // only remove the menu item if no posItems are left for it in the item group
        if (!posItemResources.find(posResource => posResource.menuItemId === posItem.menuItemId)) {
          this.value.valueProposition.itemGroup.menuItemIds = itemGroup.menuItemIds.filter(id => posItem.menuItemId !== id);
          this.value.valueProposition.itemGroup.menuItems = itemGroup.menuItems.filter(item => posItem.menuItemId !== item.id);
        }
      },

      // ------------------------- VALUE PROPOSITION RESOURCES -------------------------

      addValuePropResource({ type, resource }) {
        const { itemGroup } = this.$clone(this.value.valueProposition);
        const resources = itemGroup[type];
        resources.push(resource);
        const updatedIds = type === 'menuItems' ? [...itemGroup.menuItemIds, resource.id] : [...itemGroup.categoryIds, resource.id];
        this.value.valueProposition.itemGroup.updateResources(type, resources, updatedIds);
      },

      removeValuePropResource({ type, resource }) {
        const { itemGroup } = this.$clone(this.value.valueProposition);
        let resources = itemGroup[type];
        resources = resources.filter(valuePropResource => valuePropResource.id !== resource.id);
        const updatedIds = type === 'menuItems'
          ? itemGroup.menuItemIds.filter(id => id !== resource.id)
          : itemGroup.categoryIds.filter(id => id !== resource.id);
        this.value.valueProposition.itemGroup.updateResources(type, resources, updatedIds);
      },

      updateConstraint(updatedConstraint) {
        const compareFn = (compare, constraint) => (compare ? updatedConstraint : constraint);
        const updatedConstraints = this.value.constraints
          .map((c) => {
            switch (c.constraintType) {
              case constraintTypes.ITEM_OFFER:
                return compareFn(c.itemGroup.publicId === updatedConstraint.itemGroup?.publicId, c);
              default:
                return compareFn(c.constraintType === updatedConstraint.constraintType, c);
            }
          });
        this.handleInput('constraints', updatedConstraints);
      },

      getConstraintsByType(constraintType) {
        return this.value.constraints.filter(c => c.constraintType === constraintType);
      },

      addConstraint(constraintType) {
        let constraint;
        switch (constraintType) {
          case constraintTypes.ITEM_OFFER:
            constraint = {
              itemGroup: new ItemGroup(),
              minimumQualifyingCount: 1,
              constraintType
            };
            break;
          case constraintTypes.MINIMUM_PURCHASE:
            constraint = {
              minimum: 1,
              excludedItemAttributes: this.allItemAttrs,
              constraintType
            };
            break;
          case constraintTypes.FULFILLMENT_TYPE:
            constraint = {
              fulfillmentTypes: [],
              constraintType
            };
            break;
          case constraintTypes.PLATFORM_TYPE:
            constraint = {
              platformTypes: [],
              constraintType
            };
            break;
          case constraintTypes.LOCATION:
            constraint = {
              storeIds: [],
              constraintType
            };
            break;
          case constraintTypes.MENU_TYPE:
            constraint = {
              menuTypes: [],
              constraintType
            };
            break;
          default:
            break;
        }
        this.handleInput('constraints', [...this.value.constraints, constraint]);
      },

      removeConstraint(constraintToRemove) {
        const updatedConstraints = this.$clone(this.value.constraints).filter((constraint) => {
          if (constraintToRemove.constraintType === constraintTypes.ITEM_OFFER) {
            return constraint.itemGroup?.publicId !== constraintToRemove.itemGroup.publicId;
          }
          else {
            return constraint.constraintType !== constraintToRemove.constraintType;
          }
        });
        this.handleInput('constraints', updatedConstraints);
      },

      getConstraintTypeDisplay(constraintType) {
        switch (constraintType) {
          case constraintTypes.ITEM_OFFER:
            return 'Item Purchase';
          case constraintTypes.MINIMUM_PURCHASE:
            return 'Minimum Purchase';
          case constraintTypes.FULFILLMENT_TYPE:
            return 'Order Mode';
          case constraintTypes.PLATFORM_TYPE:
            return 'Platform Type';
          case constraintTypes.LOCATION:
            return 'Location ';
          case constraintTypes.MENU_TYPE:
            return 'Menu Type';
          default:
            return '';
        }
      },

      handleOfferTypeChange(offerType) {
        const isPercentType = this.discountAmountType === discountAmountTypes.PERCENT;
        const isDollarType = this.discountAmountType === discountAmountTypes.DOLLAR;
        const isOverrideType = this.discountAmountType === discountAmountTypes.OVERRIDE;

        switch (offerType) {
          case valuePropositionTypes.CHECK.FE_TYPE:
            this.handleValuePropositionInput({
              percentDiscount: isPercentType && this.value.valueProposition?.percentDiscount,
              dollarDiscount: isDollarType && this.value.valueProposition?.dollarDiscount,
              discountedPricePerUnit: isOverrideType && this.value.valueProposition?.discountedPricePerUnit,
              maximumDiscount: null,
              maximumDiscountedQuantity: null,
              valuePropositionType: valuePropositionTypes.CHECK[constantCase(this.discountAmountType)],
              itemDiscountBasis: null,
              excludedItemAttributes: this.allItemAttrs,
              itemGroup: null
            });
            break;
          case valuePropositionTypes.ITEM.FE_TYPE:
            this.handleValuePropositionInput({
              percentDiscount: isPercentType && this.value.valueProposition?.percentDiscount,
              dollarDiscount: isDollarType && this.value.valueProposition?.dollarDiscount,
              discountedPricePerUnit: isOverrideType && this.value.valueProposition?.discountedPricePerUnit,
              maximumDiscount: null,
              maximumDiscountedQuantity: 1,
              valuePropositionType: valuePropositionTypes.ITEM[constantCase(this.discountAmountType)],
              itemDiscountBasis: itemDiscountBases.MAIN_MENU_ITEM.id,
              excludedItemAttributes: null,
              itemGroup: new ItemGroup()
            });
            break;
          default:
            break;
        }
      },

      handleValuePropositionInput(keyValuePairs) {
        const updatedValueProposition = this.$clone(this.value.valueProposition);
        Object.entries(keyValuePairs).forEach(([key, value]) => {
          updatedValueProposition[key] = value;
        });
        this.handleInput('valueProposition', updatedValueProposition);
      },

      handleInput(key, value) {
        this.$emit('input', { ...this.value, [key]: value });
      },

      openAddPosDiscountCodeModal() {
        const posDiscountCodeList = this.posDiscountCodes
          .map(p => ({
            id: p.publicId,
            name: p.name
          }));

        this.$buefy.modal.open({
          parent: this,
          component: searchSelectModal,
          hasModalCard: true,
          trapFocus: true,
          canCancel: false,
          customClass: 'auto-width',
          props: {
            title: 'Add POS Discount Code',
            subtitle: 'Select a POS discount code to attach to your offer',
            confirmText: 'Add',
            placeholderText: 'Search for a POS discount code...',
            data: posDiscountCodeList,
            isSingleSelection: true,
            onSubmit: this.handlePosDiscountCodeChange
          }
        });
      },

      handlePosDiscountCodeChange(posDiscountPublicId) {
        this.handleInput('cardfreePosDiscountConfigurationPublicId', posDiscountPublicId);
      }
    }
  };
</script>

<style lang="sass" scoped>
  .card-content:nth-child(even)
    border-top: 1px solid $grey-lightest
    background-color: $white-ter !important

    &:not(:last-child)
      border-bottom: 1px solid $grey-lightest

  ::v-deep.dropdown-menu
    padding-top: 0 !important
    width: 100% !important

  .apply-discount-to
    width: 375px
</style>
