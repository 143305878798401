<template>
  <validated-form
    ref="storeItemScheduleForm"
    form-id="storeItemScheduleForm"
    auto-focus
    :manual-validation="$_validateDayparts"
    :disabled="!($can('update', 'StoreMenuItemSchedule') && $_menuPermissions.MANAGE_DAYPART_SCHEDULE)"
  >
    <daypart-schedule-inputs
      v-model="dayparts_form"
      :invalid-days="dayparts_invalidDays"
      :read-only="!($can('update', 'StoreMenuItemSchedule') && $_menuPermissions.MANAGE_DAYPART_SCHEDULE)"
      can-hide-hours
    />
  </validated-form>
</template>

<script>
  import StoreMenuItemSchedule from '@/store/classes/StoreMenuItemSchedule';
  import daypartsMixin from '@/mixins/dayparts';
  import multiFormChildProvider from '@/mixins/multiFormMixin/multiFormChildProvider';
  import merchantMixin from '@/mixins/merchant';


  export default {
    name: 'StoreItemScheduleForm',

    mixins: [merchantMixin, daypartsMixin, multiFormChildProvider],

    props: {
      storeId: {
        type: Number,
        required: true
      },

      itemId: {
        type: Number,
        required: true
      },

      formRef: {
        type: String,
        required: true
      }
    },

    computed: {
      storeMenuItemSchedules() {
        return StoreMenuItemSchedule
          .query()
          .where('menuItemId', this.itemId)
          .where('storeId', this.storeId)
          .get();
      }
    },

    created() {
      this.onCreated();
    },

    methods: {
      onCreated() {
        this.$_setDaypartsForm(this.storeMenuItemSchedules);
      },

      async handleSubmit() {
        try {
          const { added, removed, updated } = this.$_getDaypartsToUpdate({
            currentDayparts: this.storeMenuItemSchedules
          });

          await Promise.all([
            ...added.map(a => StoreMenuItemSchedule.createStoreMenuItemSchedule({ ...a, menuItemId: this.itemId, storeId: this.storeId })),
            ...updated.map(u => StoreMenuItemSchedule.updateStoreMenuItemSchedule(u)),
            ...removed.map(r => StoreMenuItemSchedule.deleteStoreMenuItemSchedule(r.id))
          ]);
        }

        catch (error) {
          this.$_onRequestError({
            error,
            toastOptions: { message: 'Unable to update Daypart Schedule' }
          });
          throw error;
        }
      }
    }
  };
</script>
