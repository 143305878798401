<template>
  <validated-form
    auto-focus
    form-id="modifierGroupSettingsTab"
  >
    <modifier-group-settings-form v-model="modifierGroup" :read-only="readOnly" />
  </validated-form>
</template>

<script>
  import ModifierGroupSettingsForm from '../modifier-group-settings-form.vue';

  export default {
    name: 'ModifierGroupSettingsTab',

    components: { ModifierGroupSettingsForm },

    props: {
      modifierGroup: {
        type: Object,
        required: true
      },

      readOnly: {
        type: Boolean,
        default: false
      }
    }
  };
</script>
