import http from '@/services/http';



export async function fetchTippingOptionTypes() {
  try {
    const { data } = await http.get('tipping_option_types');
    return data.tippingOptionTypes;
  }
  catch (error) {
    throw error;
  }
}

export async function fetchTippingMethodTypes() {
  try {
    const { data } = await http.get('tipping_method_types');
    return data.tippingMethodTypes;
  }
  catch (error) {
    throw error;
  }
}



export default {
  fetchTippingOptionTypes,
  fetchTippingMethodTypes
};
