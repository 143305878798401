<template>
  <modal-card title="Select Menu Type">
    <div class="pad-b-md">
      <b-dropdown v-model="selectedMenuTypeId" class="is-buttons" inline expanded>
        <b-dropdown-item
          v-for="menuType in $_selectedMerchant.supportedMenuTypes"
          :key="menuType.id"
          :value="menuType.id"
          :class="['pad-sm', {'has-border-warning': missingMenuTypeIds.includes(menuType.id)}]"
          :disabled="existingMenuTypeIds.includes(menuType.id)"
        >
          <span>{{ menuType.displayName }}</span>
          <b-icon
            v-if="missingMenuTypeIds.includes(menuType.id)"
            type="is-warning"
            class="mar-l-xs is-size-6"
            icon="exclamation-triangle"
          />
        </b-dropdown-item>
      </b-dropdown>
      <b-message v-if="missingMenuTypeIds.length" type="is-warning" class="is-compact has-shadow mar-t-sm">
        <p>Required to support Locations with</p>
        <p>associated Order Modes enabled</p>
      </b-message>
    </div>
    <template #footer>
      <div class="buttons all-bold">
        <b-button
          rounded
          @click="$parent.close()"
        >
          Cancel
        </b-button>
        <b-button
          rounded
          type="is-primary"
          :disabled="!selectedMenuType"
          @click="handleSelectMenuType"
        >
          Proceed
        </b-button>
      </div>
    </template>
  </modal-card>
</template>

<script>
  import merchantMixin from '@/mixins/merchant';


  export default {
    name: 'SelectMenuTypeModal',

    mixins: [merchantMixin],

    props: {
      existingMenuTypeIds: {
        type: Array,
        required: true
      },
      missingMenuTypeIds: {
        type: Array,
        required: true
      }
    },

    data() {
      return {
        selectedMenuTypeId: null
      };
    },

    computed: {
      selectedMenuType() {
        return this.$_selectedMerchant.supportedMenuTypes.find(menuType => menuType.id === this.selectedMenuTypeId);
      }
    },

    methods: {
      handleSelectMenuType() {
        this.$emit('menu-type-selected', this.selectedMenuType);
        this.$parent.close();
      }
    }
  };
</script>
