<template>
  <div class="is-flex-column gap-lg pad has-background-white-bis has-border has-border-grey-lightest has-radius">
    <b-loading :active="isFetching" :is-full-page="false" />
    <template v-if="!isFetching">
      <b-message v-if="!supportedMenuTypes.length" type="is-warning">
        There are no available menu types. Please configure menu types before making a constraint.
      </b-message>
      <validated-input
        v-else
        name="menuType"
        :disabled="readOnly"
        label="Menu Type"
        hide-label
        rules="required|min:1"
      >
        <div class="is-grid col-min-300 gap-md">
          <check-button
            v-for="menuType in configuredMenuTypes"
            :key="menuType.name"
            v-model="selectedMenuTypes"
            :name="menuType.name"
            :native-value="menuType.name"
            :disabled="readOnly"
          >
            {{ capitalCase(menuType.name) }}
            <b-icon
              v-if="missingMenuTypeNames.includes(capitalCase(menuType.name))"
              icon="exclamation-triangle"
              type="is-warning"
            />
          </check-button>
        </div>
      </validated-input>
      <b-message
        v-if="supportedMenuTypes.length && missingMenuTypes.length"
        type="is-warning"
        has-icon
        class="is-compact has-shadow mar-t-xs is-full-width"
      >
        No master menu configured for {{ missingMenuTypeNames.join(', ') }}
      </b-message>
    </template>
  </div>
</template>

<script>
  import Category from '@/store/classes/Category';
  import merchantMixin from '@/mixins/merchant';
  import { capitalCase } from 'change-case';
  import MerchantMenuTypeConfiguration from '@/store/classes/MerchantMenuTypeConfiguration';


  export default {
    name: 'MenuTypeConstraintCard',

    mixins: [merchantMixin],
    props: {
      earnRuleConstraint: {
        type: Object,
        required: true
      },
      readOnly: {
        type: Boolean,
        default: false
      }
    },

    computed: {
      configuredMenuTypes() {
        return this.$_selectedMerchant.supportedMenuTypes;
      },

      isFetching() {
        return Category.$state().fetching || MerchantMenuTypeConfiguration.$state().fetching;
      },

      missingMenuTypes() {
        const supportedMenuTypeIds = this.supportedMenuTypes.map(menuType => menuType.id);
        return this.configuredMenuTypes.filter(menuType => !supportedMenuTypeIds.includes(menuType.id));
      },

      missingMenuTypeNames() {
        return this.missingMenuTypes.map(menuType => capitalCase(menuType.name));
      },

      supportedMenuTypes() {
        const existingCategoryMenuTypeIds = Category.all().flatMap(category => category.menuTypes.map(menuType => menuType.id));
        return this.configuredMenuTypes.filter(menuType => [...new Set(existingCategoryMenuTypeIds)].includes(menuType.id));
      },

      selectedMenuTypes: {
        get() {
          return this.earnRuleConstraint.validMenuTypes || [];
        },
        set(value) {
          this.$emit('update-constraint', {
            ...this.earnRuleConstraint,
            validMenuTypes: value
          });
        }
      }
    },

    async created() {
      await this.onCreated();
    },

    methods: {
      capitalCase,

      async onCreated() {
        await Promise.all([
          this.fetchCategories(),
          this.fetchMerchantMenuTypeConfigurations()
        ]);

        if (!this.selectedMenuTypes.length) {
          this.selectedMenuTypes = this.configuredMenuTypes.map(menuType => menuType.name);
        }
      },

      async fetchCategories() {
        if (!Category.exists()) {
          try {
            await Category.fetchCategories();
          }
          catch (error) {
            this.$_onRequestError({ toastOptions: { message: 'Unable to fetch categories' }, error });
          }
        }
      },

      async fetchMerchantMenuTypeConfigurations() {
        if (!MerchantMenuTypeConfiguration.exists()) {
          try {
            await MerchantMenuTypeConfiguration.fetchMerchantMenuTypeConfigurations();
          }
          catch (error) {
            this.$_onRequestError({
              toastOptions: { message: 'Unable to fetch merchant menu type configurations' },
              error
            });
          }
        }
      }
    }
  };
</script>
