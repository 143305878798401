<template>
  <div class="card" data-test-id="details-step">
    <div class="card-content">
      <b-message
        v-if="currencyErrors.loyaltyPublicId"
        type="is-danger"
        size="is-small"
        class="pad-none"
      >
        Currency already created.
      </b-message>
      <validated-text-input
        :value="value.currency.title"
        data-test-id="title-input"
        name="title"
        type="text"
        rules="required|max:255"
        :custom-messages="{ required: 'You must give your Challenge a name' }"
        label="Name"
        :disabled="!value.currency.isDraft"
        :backend-validation-errors="currencyErrors.title"
        @input="handleInput({ key: 'title', value: $event })"
      />
      <validated-text-input
        :value="value.currency.description"
        data-test-id="description-input"
        name="description"
        type="text"
        rules="required|max:255"
        label="Description"
        :disabled="!value.currency.isDraft"
        :backend-validation-errors="currencyErrors.description"
        @input="handleInput({ key: 'description', value: $event })"
      />
      <validated-text-input
        :value="value.currency.longDescription"
        data-test-id="terms-conditions-input"
        name="terms-conditions"
        type="textarea"
        rules="required|max:1280"
        placeholder="Enter terms and conditions..."
        label="Terms & Conditions"
        :disabled="!value.currency.isDraft"
        :backend-validation-errors="currencyErrors.longDescription"
        @input="handleInput({ key: 'longDescription', value: $event })"
      />
    </div>

    <div class="card-content">
      <validated-input
        class="processing-type"
        name="processingType-inputs"
        rules="required"
        :custom-messages="{ required: 'You must select a processing type' }"
        label="Processing Type"
        :backend-validation-errors="currencyErrors.challengeProcessingType"
      >
        <div class="is-grid col-2 gap-x">
          <radio-button
            name="processingType"
            :value="value.currency.challengeProcessingType"
            :native-value="processingTypes.AUTOMATIC_AWARD_CHALLENGE"
            :disabled="readOnly || !value.currency.isDraft"
            @input="handleProcessingTypeChange"
          >
            Automatic
            <template #sublabel>Automatically process earnings into a reward</template>
          </radio-button>
          <radio-button
            name="processingType"
            :value="value.currency.challengeProcessingType"
            :disabled="readOnly || !value.currency.isDraft"
            :native-value="processingTypes.SCHEDULED_PROCESSING_CHALLENGE"
            @input="handleProcessingTypeChange"
          >
            Scheduled
            <template #sublabel>Process earnings into a reward on a scheduled date</template>
          </radio-button>
        </div>
      </validated-input>
    </div>
  </div>
</template>

<script>
  import moment from 'moment-timezone';

  import Currency from '@/store/classes/Currency';

  import { conversionThresholdRunLimitPolicyTypes } from '@/constants/conversionThresholds';

  export default {
    name: 'DetailsStep',

    props: {
      value: {
        type: Object,
        required: true
      },

      readOnly: {
        type: Boolean,
        default: false
      },

      currencyErrors: {
        type: Object,
        required: true
      }
    },

    data() {
      return {
        processingTypes: Currency.challengeProcessingTypes
      };
    },

    methods: {
      handleInput({ key, value }) {
        let emitValue = {
          ...this.value,
          currency: {
            ...this.value.currency,
            [key]: value
          }
        };
        if (key === 'title') {
          // We need to keep the earn rule name in sync with the currency title
          emitValue = {
            ...emitValue,
            earnRule: {
              ...this.value.earnRule,
              name: value
            }
          };
        }

        this.$emit('input', emitValue);
      },

      handleProcessingTypeChange(value) {
        const emitValue = {
          ...this.value,
          currency: {
            ...this.value.currency,
            challengeProcessingType: value
          }
        };

        // AUTOMATIC processing types cannot have conversion schedules; clear if not SCHEDULED or no currency end date.
        const conversionSchedules = this.value.currency.endDate && value === this.processingTypes.SCHEDULED_PROCESSING_CHALLENGE
          ? [moment.utc(this.value.currency.endDate).add(1, 'day').format('YYYY-MM-DD HH:mm:ss')] // datetime per loyalty docs
          : null;
        emitValue.currency.conversionSchedules = conversionSchedules;

        const conversionThresholds = this.value.conversionThresholds.map(ct => ({
          ...ct,
          autoConvert: value === this.processingTypes.AUTOMATIC_AWARD_CHALLENGE,
          runLimitPolicy: {
            runLimitPolicyType: conversionThresholdRunLimitPolicyTypes.UNLIMITED
          }
        }));
        // AUTOMATIC processing types may only have on conversion thresholds; SCHEDULED may have multiple.
        emitValue.conversionThresholds = value === this.processingTypes.AUTOMATIC_AWARD_CHALLENGE
          ? conversionThresholds.slice(0, 1)
          : conversionThresholds;

        this.$emit('input', emitValue);
      }
    }
  };
</script>

<style lang="sass" scoped>
  .card-content:nth-child(even)
    border-top: 1px solid $grey-lightest
    background-color: $white-ter !important

    &:not(:last-child)
      border-bottom: 1px solid $grey-lightest
</style>
