<template>
  <validated-form
    ref="form"
    auto-focus
    form-id="addEditApplicationSettingForm"
    @valid-submit="handleSubmit"
  >
    <modal-card
      :title="`${!merchantAppSetting.id ? 'Create' : 'Update'} Application Setting`"
      :subtitle="merchantAppSetting.id && merchantAppSetting.appCodeName"
    >
      <section class="is-grid col-2 gap col-1-tablet">
        <validated-input
          :disabled="!!merchantAppSetting.id"
          class="mar-b-lg"
          name="applicationCodeId"
          rules="required"
          label="Application Code"
        >
          <dropdown-menu
            v-model="form.appCodeTypeId"
            :disabled="!!merchantAppSetting.id"
            expanded
          >
            <dropdown-button
              slot="trigger"
              placeholder="Select an Application Type..."
              :value="form.appCodeName"
              :disabled="!!merchantAppSetting.id"
            />
            <b-dropdown-item
              v-for="option in merchantApplicationTypeOptions"
              :key="option.id"
              :value="option.id"
            >
              {{ option.name }}
            </b-dropdown-item>
          </dropdown-menu>
        </validated-input>

        <validated-text-input
          v-model="form.systemStatusCodeId"
          :name="'systemStatusCodeId'"
          label="System Status Code"
          type="float"
          rules="required"
        />

        <validated-text-input
          v-model="form.currentVersion"
          :name="'currentVersion'"
          label="Current Version"
          type="float"
          rules="required"
        />

        <validated-text-input
          v-model="form.minimumVersion"
          :name="'minimumVersion'"
          label="Minimum Version"
          type="float"
          rules="required"
        />
      </section>


      <transition v-if="form.appCodeTypeId === appSettingsById.IOS || form.appCodeTypeId ===appSettingsById.ANDROID" name="fade-down">
        <div>
          <hr>
          <h3 class="is-size-5 has-text-weight-bold mar-r-lg">Universal Links</h3>
          <h4 class="subtitle is-6 has-text-grey">All fields are Comma-delimited strings</h4>

          <template v-if="form.appCodeTypeId === appSettingsById.IOS">
            <validated-text-input
              v-model="form.appIds"
              name="appIds"
              label="App Ids"
              type="textarea"
            />

            <validated-text-input
              v-model="form.appClipIds"
              name="appClipIds"
              label="App Clip Ids"
              type="textarea"
            />

            <validated-text-input
              v-model="form.paths"
              name="paths"
              label="Paths"
              type="textarea"
            />
          </template>

          <template v-if="form.appCodeTypeId === appSettingsById.ANDROID">
            <validated-text-input
              v-model="form.appIds"
              name="appIds"
              label="App Ids"
              type="textarea"
            />
            <validated-text-input
              v-model="form.fingerprints"
              name="fingerprints"
              label="Fingerprints"
              type="textarea"
            />
          </template>
        </div>
      </transition>

      <template #footer>
        <div class="buttons all-bold">
          <b-button
            rounded
            @click="$_confirmCloseModal({ programmatic: true })"
          >
            Cancel
          </b-button>
          <b-button
            v-tabbable
            rounded
            native-type="submit"
            type="is-primary"
            :loading="isSubmitting"
          >
            Save
          </b-button>
        </div>
      </template>
    </modal-card>
  </validated-form>
</template>


<script>
  import confirmModalCloseMixin from '@/mixins/confirm-modal-close';
  import MerchantAppSetting from '@/store/classes/MerchantAppSetting';

  export default {
    name: 'AddEditApplicationSettingModal',

    mixins: [confirmModalCloseMixin],

    props: {
      merchantAppSetting: {
        type: Object,
        required: true
      },

      merchantApplicationTypeOptions: {
        type: Array,
        required: true
      }
    },

    data() {
      return {
        form: null,
        appSettingsById: MerchantAppSetting.typesById()
      };
    },

    computed: {
      isSubmitting() {
        return MerchantAppSetting.$state().submitting;
      }
    },

    async created() {
      await this.onCreated();
    },

    methods: {
      async onCreated() {
        this.form = new MerchantAppSetting({ ...this.merchantAppSetting });
      },

      async handleSubmit() {
        try {
          const formWithModifiedUniversalLinks = this.$clone(this.form);
          const universalLinksProperties = ['appIds', 'appClipIds', 'paths', 'fingerprints'];
          universalLinksProperties.forEach((property) => {
            if (this.form[property]) {
              formWithModifiedUniversalLinks[property] = this.form[property].split(',');
            }
            else {
              formWithModifiedUniversalLinks[property] = [];
            }
          });

          if (this.merchantAppSetting.id) {
            await MerchantAppSetting.updateMerchantAppSetting(formWithModifiedUniversalLinks);
          }
          else {
            await MerchantAppSetting.createMerchantAppSetting(formWithModifiedUniversalLinks);
          }

          this.$_onRequestSuccess({
            toastOptions: { message: `Successfully ${this.merchantAppSetting.id ? 'updated' : 'added'} new Merchant App Setting!` },
            options: { closeParent: true }
          });
        }

        catch (error) {
          this.$_onRequestError({
            toastOptions: { message: `An error occured while ${this.merchantAppSetting.id ? 'updating' : 'adding'} your new Merchant App Setting` },
            error
          });
        }
      }
    }
  };
</script>
