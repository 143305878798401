<template>
  <div>
    <p class="pad-l-sm nested-table-section-title">
      {{ activeMenuResource.display }}
    </p>
    <ul
      v-for="(searchResult, index) in searchResults"
      :key="index"
      class="no-bullets"
    >
      <component
        :is="activeMenuResource.component"
        v-bind="mapSearchResultPropToComponent(searchResult).props"
        @re-search-menu="$emit('re-search-menu')"
      />
    </ul>
  </div>
</template>

<script>
  import capitalCase from '@/helpers/capitalCase';

  import CategoriesRow from '../menu-components/category-row.vue';
  import MenuItemsRow from '../menu-components/menu-item-row.vue';
  import ModifierGroupRow from '../menu-components/modifier-group-row.vue';
  import ModifiersRow from '../menu-components/modifier-row.vue';


  export default {
    name: 'MenuSearchV2',

    props: {
      searchResults: {
        type: Array,
        default: () => []
      },

      menuType: {
        type: Object,
        default: null
      },

      resourceType: {
        type: String,
        required: true,
        validator: value => ['categories', 'items', 'modifierGroups', 'modifiers'].includes(value)
      }
    },

    computed: {
      activeMenuResource() {
        switch (this.resourceType) {
          case 'categories':
            return {
              display: 'Categories',
              component: CategoriesRow
            };
          case 'items':
            return {
              display: 'Menu Items',
              component: MenuItemsRow
            };
          case 'modifierGroups':
            return {
              display: 'Modifier Groups',
              component: ModifierGroupRow
            };
          case 'modifiers':
            return {
              display: 'Modifiers',
              component: ModifiersRow
            };
          default:
            return {};
        }
      }
    },

    methods: {
      capitalCase,

      mapSearchResultPropToComponent(searchResult) {
        switch (this.resourceType) {
          case 'categories':
            return {
              props: {
                category: searchResult,
                isSearchRow: true
              }
            };
          case 'items':
            return {
              props: {
                menuItem: searchResult,
                isSearchRow: true
              }
            };
          case 'modifierGroups':
            return {
              props: {
                modifierGroup: searchResult,
                isSearchRow: true
              }
            };
          case 'modifiers':
            return {
              props: {
                modifier: searchResult,
                modifierGroup: searchResult.menuItemModifierGroup,
                isSearchRow: true
              }
            };
          default:
            return {};
        }
      }
    }
  };
</script>

<style lang='sass' scoped>
  ::v-deep
    .nested-table-section-title
      background-color: $white-ter
      border-color: darken($white-ter, 10%)

  .no-bullets
    list-style-type: none
</style>
