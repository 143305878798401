<template>
  <div class="card" data-test-id="details-step">
    <div class="card-content">
      <p class="title is-4 has-text-weight-normal">Time Frame</p>
      <p class="subtitle is-6 has-text-grey mar-b">Define when this earn rule can be applied</p>
      <div class="is-grid col-2 gap-x">
        <validated-input
          name="startDate"
          label="Start Date"
          rules="required"
        >
          <b-datepicker
            v-model="startDate"
            data-test-id="start-date-input"
            :years-range="dys_ranges.startDate"
            placeholder="Start Date"
            icon="calendar-alt"
            class="has-extra-shadow"
            :events="endDate ? [{ date: endDate, type: 'is-danger' }] : []"
            indicators="bars"
            :focused-date="endDate"
            :min-date="moment().add(1, 'day').startOf('day').toDate()"
            :max-date="maxDate"
            :disabled="readOnly || !value.currency.isDraft"
            @change-year="(year) => $_handleYearChange({ year, type: 'startDate', yearsInPast: 10, yearsInFuture: 30 })"
          >
            <div class="buttons is-right">
              <b-button @click="setStartDate(null)">Clear</b-button>
            </div>
          </b-datepicker>
        </validated-input>

        <validated-input
          name="endDate"
          label="End Date"
          rules="required"
        >
          <b-datepicker
            v-model="endDate"
            data-test-id="end-date-input"
            :years-range="dys_ranges.endDate"
            placeholder="End Date"
            icon="calendar-alt"
            class="has-extra-shadow"
            position="is-bottom-left"
            :min-date="moment().isAfter(moment(startDate))
              ? moment().startOf('day').toDate()
              : moment(startDate).startOf('day').toDate()
            "
            :max-date="maxDate"
            :events="startDate ? [{ date: startDate, type: 'is-success' }] : []"
            indicators="bars"
            :focused-date="startDate"
            :disabled="readOnly || !value.currency.isDraft"
            @change-year="(year) => $_handleYearChange({ year, type: 'endDate', yearsInPast: 10, yearsInFuture: 30 })"
          >
            <div class="buttons is-right">
              <b-button @click="setEndDate(null)">Clear</b-button>
            </div>
          </b-datepicker>
        </validated-input>
      </div>
      <b-message
        v-if="errorMessages.length"
        type="is-danger"
        size="is-small"
        class="mar-t-sm"
      >
        {{ errorMessages.join(', ') }}
      </b-message>

      <hr>

      <div class="is-grid dist-y-md">
        <div class="is-flex justify-start align-center">
          <label class="label mar-none mar-r-xl">Guests can earn towards this challenge...</label>
          <b-select v-model="dayTimeConstraints" :disabled="readOnly || !value.currency.isDraft">
            <option :value="false">All day, every day</option>
            <option :value="true">During specific times</option>
          </b-select>
        </div>
        <template v-if="dayTimeConstraints">
          <div>
            <transition name="fade-down">
              <validated-input
                data-test-id="days-of-week-input"
                name="selectedValidDays"
                label="Days of the Week"
                :custom-messages="{ required: 'At least one day must be selected' }"
                :rules="{ required: !selectedValidDays.length }"
              >
                <div class="is-grid gap col-7">
                  <check-button
                    v-for="(dayValue, day) in daysOfWeek"
                    :key="`${dayValue}-${day}`"
                    v-model="selectedValidDays"
                    :native-value="dayValue"
                    :disabled="readOnly || !value.currency.isDraft"
                    :label="day"
                  />
                </div>
              </validated-input>
            </transition>
          </div>
          <transition name="fade-down">
            <div data-test-id="time-of-day-radio-buttons">
              <label key="timeOfDayLabel" class="label">Time of Day</label>
              <div key="timeOfDay" class="is-flex-start-aligned">
                <radio-button
                  v-model="timeOfDayRange"
                  name="timeOfDayRange"
                  :native-value="false"
                  :disabled="readOnly || !value.currency.isDraft"
                >
                  All Day
                </radio-button>
                <radio-button
                  v-model="timeOfDayRange"
                  name="timeOfDayRange"
                  :native-value="true"
                  class="mar-l-md"
                  :disabled="readOnly || !value.currency.isDraft"
                >
                  Time Range
                </radio-button>
              </div>
            </div>
          </transition>
          <transition name="fade-down">
            <div
              v-if="timeOfDayRange"
              key="startEndTime"
              data-test-id="time-of-day-range-input"
              class="is-flex-start-aligned"
            >
              <validated-input
                rules="required"
                name="startTime"
                label="From"
              >
                <b-timepicker
                  v-model="startTime"
                  size="is-small"
                  :default-minutes="0"
                  hour-format="12"
                  inline
                  :disabled="readOnly || !value.currency.isDraft"
                />
              </validated-input>
              <validated-input
                :rules="{
                  required: true,
                  dateTimeIsAfter: '@startTime'
                }"
                name="endTime"
                label="To"
                class="mar-l-md"
              >
                <b-timepicker
                  v-model="endTime"
                  size="is-small"
                  :default-minutes="0"
                  hour-format="12"
                  inline
                  :disabled="readOnly || !value.currency.isDraft"
                />
              </validated-input>
            </div>
          </transition>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
  import moment from 'moment-timezone';

  import Currency from '@/store/classes/Currency';

  import { constraintModels } from '@/constants/earnRules';
  import daysOfWeek from '@/constants/daysOfWeek';

  import dynamicYearSelectMixin from '@/mixins/dynamic-year-select';

  export default {
    name: 'TimeFrameStep',

    mixins: [dynamicYearSelectMixin],

    props: {
      value: {
        type: Object,
        required: true
      },

      readOnly: {
        type: Boolean,
        default: false
      },
      resourceErrors: {
        type: Object,
        required: true
      }
    },

    data() {
      return {
        moment,
        daysOfWeek
      };
    },

    computed: {
      errorMessages() {
        const errors = {};

        const pushErrorIfExists = (errorObject, errorKey, type) => {
          if (errorObject?.[errorKey]) {
            if (!errors[type]) {
              errors[type] = [`${errorKey} - ${errorObject[errorKey]}`];
            }
            else {
              errors[type].push(`${errorKey} - ${errorObject[errorKey]}`);
            }
          }
        };

        ['startDate', 'endDate', 'conversionSchedules'].forEach((key) => {
          pushErrorIfExists(this.resourceErrors.currency, key, 'Currency');
        });

        ['startDate', 'endDate'].forEach((key) => {
          pushErrorIfExists(this.resourceErrors.earnRule, key, 'Earn Rule');
        });

        Object.entries(this.resourceErrors.conversionThresholds).reduce((acc, [id, conversionThreshold]) => {
          ['startDate', 'endDate'].forEach((key) => {
            pushErrorIfExists(conversionThreshold, key, `Conversion Threshold ${id}`);
          });
          return acc;
        }, errors);

        const errorMessages = [];
        Object.entries(errors).forEach(([type, errorsOfType]) => {
          errorMessages.push(`${type}: ${errorsOfType.join(', ')}`);
        });

        return errorMessages;
      },

      isAutomaticProcessingType() {
        return this.value.currency.challengeProcessingType === Currency.challengeProcessingTypes.AUTOMATIC_AWARD_CHALLENGE;
      },

      maxDate() {
        // NOTE: This correlates our abirtrary end date used for delete
        return moment(new Date('12/31/2049'))
          .startOf('day')
          .toDate();
      },

      startDate: {
        get() {
          return this.value.earnRule.startDate && moment(this.value.earnRule.startDate).toDate();
        },
        set(val) {
          const startDate = moment.utc(val).format('YYYY-MM-DD');
          this.setStartDate(startDate);
        }
      },

      endDate: {
        get() {
          return this.value.earnRule.endDate && moment(this.value.earnRule.endDate).toDate();
        },
        set(val) {
          const endDate = moment.utc(val).format('YYYY-MM-DD'); // date per loyalty docs
          this.setEndDate(endDate);
        }
      },

      dayOfWeekConstraint() {
        return this.getConstraintByType(constraintModels.DAY_OF_WEEK.type);
      },

      timeOfDayConstraint() {
        return this.getConstraintByType(constraintModels.TIME_OF_DAY.type);
      },


      dayTimeConstraints: {
        get() {
          return !!(this.dayOfWeekConstraint || this.timeOfDayConstraint);
        },
        set(value) {
          if (value) {
            this.addConstraint(constraintModels.DAY_OF_WEEK);
          }
          else {
            const updatedConstraints = this.value.earnRule.earnRuleConstraints.filter((constraint) => {
              const isDayOfWeekConstraint = constraint.constraintType === constraintModels.DAY_OF_WEEK.type;
              const isTimeOfDayConstraint = constraint.constraintType === constraintModels.TIME_OF_DAY.type;
              return !(isDayOfWeekConstraint || isTimeOfDayConstraint);
            });
            this.setEarnRuleConstraints(updatedConstraints);
          }
        }
      },

      selectedValidDays: {
        get() {
          return this.dayOfWeekConstraint?.validDays || [];
        },
        set(validDays) {
          this.updateConstraint({ constraintType: constraintModels.DAY_OF_WEEK.type, payload: { validDays } });
        }
      },

      timeOfDayRange: {
        get() {
          return !!this.timeOfDayConstraint;
        },
        set(value) {
          if (value) {
            this.addConstraint(constraintModels.TIME_OF_DAY);
          }
          else {
            const updatedConstraints = this.value.earnRule.earnRuleConstraints.filter(constraint => !(constraint.constraintType === constraintModels.TIME_OF_DAY.type));
            this.setEarnRuleConstraints(updatedConstraints);
          }
        }
      },

      startTime: {
        get() {
          return this.timeOfDayConstraint ? moment(this.timeOfDayConstraint.startTime, 'HH:mm').toDate() : null;
        },
        set(value) {
          this.updateConstraint({
            constraintType: constraintModels.TIME_OF_DAY.type,
            payload: { startTime: this.formatTime(value) }
          });
        }
      },

      endTime: {
        get() {
          return this.timeOfDayConstraint ? moment(this.timeOfDayConstraint.endTime, 'HH:mm').toDate() : null;
        },
        set(value) {
          this.updateConstraint({
            constraintType: constraintModels.TIME_OF_DAY.type,
            payload: { endTime: this.formatTime(value) }
          });
        }
      }
    },

    methods: {
      formatTime: value => moment(value).format('HH:mm'),

      setStartDate(startDate) {
        const emitValue = {
          ...this.value,
          currency: {
            ...this.value.currency,
            startDate
          },
          earnRule: {
            ...this.value.earnRule,
            startDate
          },
          conversionThresholds: this.value.conversionThresholds.map(threshold => ({
            ...threshold,
            startDate
          }))
        };
        this.$emit('input', emitValue);
      },

      setEndDate(endDate) {
        const conversionScheduleEndDate = this.isAutomaticProcessingType
          ? endDate
          : moment.utc(endDate).add(1, 'day').format('YYYY-MM-DD HH:mm:ss'); // datetime per loyalty docs
        const conversionThresholdEndDate = this.isAutomaticProcessingType
          ? endDate
          : moment.utc(endDate).add(3, 'day').format('YYYY-MM-DD'); // date per loyalty docs

        const emitValue = {
          ...this.value,
          currency: {
            ...this.value.currency,
            endDate,
            conversionSchedules: this.isAutomaticProcessingType || !endDate
              ? null
              : [{ targetExecutionOffsetDateTime: conversionScheduleEndDate }]
          },
          earnRule: {
            ...this.value.earnRule,
            endDate
          },
          conversionThresholds: this.value.conversionThresholds.map(threshold => ({
            ...threshold,
            endDate: conversionThresholdEndDate
          }))
        };

        this.$emit('input', emitValue);
      },

      getConstraintByType(constraintType) {
        return this.value.earnRule.earnRuleConstraints.find(c => c.constraintType === constraintType);
      },

      setEarnRuleConstraints(earnRuleConstraints) {
        const emitValue = {
          ...this.value,
          earnRule: {
            ...this.value.earnRule,
            earnRuleConstraints
          }
        };
        this.$emit('input', emitValue);
      },

      addConstraint(constraint) {
        const newConstraint = {
          constraintType: constraint.type,
          ...constraint.defaultData
        };

        this.setEarnRuleConstraints([...this.value.earnRule.earnRuleConstraints, newConstraint]);
      },

      updateConstraint({ constraintType, payload }) {
        const updatedConstraints = this.$clone(this.value.earnRule.earnRuleConstraints).map((c) => {
          if (c.constraintType === constraintType) {
            return { ...c, ...payload };
          }
          return c;
        });

        this.setEarnRuleConstraints(updatedConstraints);
      }
    }
  };
</script>

<style lang="sass" scoped>
  .card-content:nth-child(even)
    border-top: 1px solid $grey-lightest
    background-color: $white-ter !important

    &:not(:last-child)
      border-bottom: 1px solid $grey-lightest
</style>
