<template>
  <validated-input
    class="mar-t-md modifier-restriction-input"
    label="Modifier Restriction"
    name="modifierRestriction"
    tooltip="Optional: Restrict the guest’s selection to a specific modifier(s). If no modifier is selected, all combinations will be discounted."
    tooltip-placement="right"
    :disabled="!availableModifierGroups.length || readOnly"
  >
    <div>
      <b-button
        :disabled="!availableModifierGroups.length || readOnly"
        type="is-primary is-light"
        icon-left="plus"
        :loading="loadingModifiers"
        class="mar-b-md"
        @click="openModifierRestrictionModal"
      >
        Add Modifiers
      </b-button>
    </div>
    <div v-if="selectedModifiers.length" class="is-grid col-2 gap">
      <div v-for="modifier in selectedModifiers" :key="modifier.id" class="tag is-primary is-light auto-height mar-none pad-y-xs pad-x-sm">
        <div>
          <p class="has-text-weight-bold text-ellipsis tag-max">{{ modifier.displayName }}</p>
          <p class="has-text-primary-dark">{{ modifier.id }}</p>
        </div>
        <button
          v-if="!readOnly"
          type="button"
          class="delete mar-none mar-l-sm has-background-primary-dark has-hover-background-primary-medium"
          @click.stop="removeModifier(modifier.id)"
        />
      </div>
    </div>
    <div class="is-flex-wrap align-center">
      <div
        v-tippy="{
          content: `Add ${ isCategorySearch ? 'categories' : 'items' } with shared modifiers to create a modifier restriction`,
          onShow: () => !availableModifierGroups.length
        }"
      />
      <b-message
        v-if="!availableModifierGroups.length && selectedResources.length && !loadingModifiers"
        type="is-warning"
        class="is-compact has-shadow mar-none align-self-start"
        size="is-small"
      >
        {{ noModifiersMessage }}
      </b-message>
    </div>
  </validated-input>
</template>

<script>
  import searchByGroupSelectModal from '@/components/globals/search-by-group-select-modal.vue';
  import merchantMixin from '@/mixins/merchant';
  import ModifierGroup from '@/store/classes/ModifierGroup';

  export default {
    name: 'ModifierRestrictionsSearch',

    mixins: [merchantMixin],

    props: {
      selectedResources: {
        type: Array,
        default: () => []
      },

      requiredModifications: {
        type: Array,
        default: () => []
      },

      readOnly: {
        type: Boolean,
        required: true
      },

      isCategorySearch: {
        type: Boolean,
        default: false
      }
    },

    data() {
      return {
        availableModifierGroups: [],
        loadingModifiers: false
      };
    },

    computed: {
      selectedModifierIds() {
        return this.requiredModifications?.[0]?.qualifyingModifierMenuItemIds || [];
      },

      flattenedModifiers() {
        return this.availableModifierGroups.flatMap(modifierGroup => modifierGroup.items);
      },

      selectedModifiers() {
        return this.selectedModifierIds.map((id) => {
          const modifier = this.flattenedModifiers.find(mod => mod.id === id);
          return modifier ? { ...modifier, exists: true } : { displayName: 'Unknown Menu Resource', exists: false };
        });
      },

      noModifiersMessage() {
        if (this.selectedResources.length > 1) {
          return `No shared modifiers available for the selected ${this.isCategorySearch ? 'categories' : 'items'}`;
        }
        else {
          return `No modifiers available for the selected ${this.isCategorySearch ? 'category' : 'item'}`;
        }
      }
    },

    watch: {
      selectedResources: {
        immediate: true,
        handler: 'fetchModifiers'
      }
    },

    methods: {
      async fetchModifiers(newVal, oldVal) {
        // If the selected resources haven't changed, don't make a request or clear out the available modifiers
        if (JSON.stringify(newVal) === JSON.stringify(oldVal)) return;

        if (this.selectedResources.length) {
          this.loadingModifiers = true;
          try {
            const itemType = this.isCategorySearch ? 'category' : 'item';
            const searchableIds = this.selectedResources.map(resource => resource.id);
            const { menuItemModifierGroups } = await ModifierGroup.fetchModifierGroups({ [`menu_${itemType}_ids`]: searchableIds });
            this.availableModifierGroups = menuItemModifierGroups.map(itemModifierGroup => (
              {
                id: itemModifierGroup.modifierGroupId,
                displayName: itemModifierGroup.displayName,
                items: itemModifierGroup.menuItemModifiers.map(modifier => (
                  {
                    id: modifier.menuItemId,
                    displayName: modifier.displayName
                  }
                ))
              }
            ));
          }
          catch (error) {
            this.$_onRequestError({
              toastOptions: {
                message: 'There was an error fetching the modifiers'
              },
              error
            });
          }
          this.loadingModifiers = false;
        }
        else {
          this.availableModifierGroups = [];
        }
      },

      openModifierRestrictionModal() {
        this.$buefy.modal.open({
          parent: this,
          component: searchByGroupSelectModal,
          hasModalCard: true,
          trapFocus: true,
          canCancel: false,
          customClass: 'auto-width',
          props: {
            title: 'Modifier Restriction',
            subtitle: 'Only modifiers shared by all selected items are available',
            confirmText: 'Add',
            placeholderText: 'Search for a modifier...',
            groups: this.availableModifierGroups,
            qualifyingModifierMenuItemIds: this.selectedModifierIds,
            onSubmit: modifierIds => this.updateModifierMenuIds(modifierIds)
          }
        });
      },

      updateModifierMenuIds(modifierIds) {
        const payload = modifierIds.length ? [{ qualifyingModifierMenuItemIds: modifierIds }] : [];
        this.$emit('update-required-modifications', payload);
      },

      removeModifier(modifierId) {
        const newModifierIds = this.selectedModifierIds.filter(id => id !== modifierId);
        this.updateModifierMenuIds(newModifierIds);
      }
    }
  };
</script>

<style lang="sass" scoped>
  .tag
    display: flex
    align-items: center
    justify-content: space-between
    padding: 0.5rem
    width: 240px

  .tag-max
    max-width: 180px

  .modifier-restriction-input
    ::v-deep
      .field.has-addons
        flex-direction: column
</style>
