const offerAllocationStatuses = {
  UNKNOWN: 'Unknown',
  FUTURE: 'Future',
  REDEEMABLE: 'Redeemable',
  REDEMPTION_LIMIT_REACHED: 'Redemption Limit Reached',
  EXPIRED: 'Expired',
  PENDING_REDEMPTION_CODE: 'Pending Redemption Code',
  REVOKED: 'Revoked'
};

export default offerAllocationStatuses;
