<template>
  <validated-form
    ref="form"
    form-id="programDetails"
    :disabled="!$can('update', 'Loyalty::Program')"
    @valid-submit="submit"
  >
    <panel
      title="Program Details"
      collapsible
      :loading="fetching"
    >
      <template #buttons>
        <b-button
          v-if="$can('update', 'Loyalty::Program')"
          rounded
          class="is-bold"
          size="is-medium"
          native-type="submit"
          type="is-primary"
          data-test-id="submit-button"
          :loading="submitting"
        >
          {{ form.publicId ? 'Save' : 'Create' }}
        </b-button>
      </template>

      <b-message
        v-if="!loyaltyProgram"
        type="is-warning"
        class="is-compact has-shadow"
      >
        Once <b>Program Name</b> is saved, it cannot be edited
      </b-message>
      <validated-text-input
        v-model="form.name"
        name="name"
        type="text"
        maxlength="63"
        label="Program Name"
        placeholder="Enter program name"
        has-counter
        rules="required"
        data-test-id="program-name-input"
        :disabled="loyaltyProgram && !!loyaltyProgram.publicId"
      />
    </panel>
  </validated-form>
</template>

<script>
  import LoyaltyProgram from '@/store/classes/LoyaltyProgram';


  export default {
    name: 'ProgramDetails',

    data: () => ({
      form: {}
    }),

    computed: {
      fetching() {
        return LoyaltyProgram.$state().fetching;
      },

      submitting() {
        return LoyaltyProgram.$state().submitting;
      },

      loyaltyProgram() {
        return LoyaltyProgram.query().first();
      }
    },

    watch: {
      loyaltyProgram: {
        immediate: true,
        handler(loyaltyProgram) {
          if (loyaltyProgram) this.form = loyaltyProgram;
        }
      }
    },

    methods: {
      async submit() {
        try {
          if (this.form.publicId) await LoyaltyProgram.updateProgram(this.form);
          else await LoyaltyProgram.createProgram(this.form);

          this.$_onRequestSuccess({
            toastOptions: {
              message: 'Loyalty Program successfully updated!'
            }
          });
        }

        catch (error) {
          this.$_onRequestError({
            error,
            toastOptions: {
              message: 'There was an error updating your Loyalty Program'
            }
          });
        }
      }
    }
  };
</script>
