<template>
  <div>
    <b-loading :is-full-page="false" :active="isFetching" />

    <div v-if="selectedStore.storeId" class="card-group">
      <mobile-tipping-configuration :tipping-options="tippingOptionsWithoutDepartmentOptions" />
      <div>
        <div class="is-flex align-center mar-y-lg">
          <h1 class="title is-primary mar-none mar-r is-4">{{ selectedStore.description }}</h1>
          <b-button
            v-if="stores.length > 1"
            size="is-small"
            type="is-primary"
            class="has-border has-border-primary has-background-light has-text-primary has-hover-background-grey-lightest"
            rounded
            @click="openLocationSelectModal"
          >
            Change Location
          </b-button>
        </div>
        <departments-configuration :store="selectedStore" :merchant-tipping-options="merchantTippingOptions" />
      </div>
      <employees-configuration :store="selectedStore" />
    </div>
  </div>
</template>

<script>
  import Store from '@/store/classes/Store';
  import MerchantTippingOption from '@/store/classes/MerchantTippingOption';
  import searchSelectModal from '@/components/globals/search-select-modal.vue';
  import storage from '@/services/storage';
  import { tippingTypes } from '@/constants/merchantTippingOptions';
  import Department from '@/store/classes/Department';
  import Employee from '@/store/classes/Employee';

  import DepartmentsConfiguration from './configurations/departments-configuration.vue';
  import EmployeesConfiguration from './configurations/employees-configuration.vue';
  import MobileTippingConfiguration from './configurations/mobile-tipping-configuration.vue';

  export default {
    name: 'ConfigurationsTab',

    components: {
      MobileTippingConfiguration,
      DepartmentsConfiguration,
      EmployeesConfiguration
    },

    props: {
      merchant: {
        type: Object,
        required: true
      }
    },

    data() {
      return {
        selectedStore: {}
      };
    },

    computed: {
      isFetching() {
        return Store.$state().fetchingAll || Department.$state().fetching || MerchantTippingOption.$state().fetching || Employee.$state().fetching;
      },

      stores() {
        return Store.orderByName().get();
      },

      tippingOptionsWithoutDepartmentOptions() {
        return this.tippingOptions.filter(to => !to.departmentId);
      },

      merchantTippingOptions() {
        const merchantSpecificTippingOptions = this.tippingOptions.filter(to => to.merchantId === this.merchant.id);
        return merchantSpecificTippingOptions.length ? merchantSpecificTippingOptions : this.defaultMerchantTippingOptions;
      },

      defaultMerchantTippingOptions() {
        return this.tippingOptions.filter(to => to.merchantId === 0);
      },

      tippingOptions() {
        return MerchantTippingOption.query().where('tippingTypeId', tippingTypes.MOBILE).get();
      }
    },

    created() {
      this.onCreated();
    },

    methods: {
      async onCreated() {
        await Promise.all([this.fetchStores(), this.fetchDepartments(), this.fetchMerchantTippingOptions()]);

        this.setStoreId();

        await this.fetchEmployees(this.selectedStore.storeId);
      },

      setStoreId() {
        const storedStoreId = storage.local.get(`${this.merchant.id}-selectedStoreIdHotelTipping`);
        if (storedStoreId) {
          this.selectedStore = this.stores.find(s => s.storeId === storedStoreId);
        }
        else {
          this.selectedStore = this.stores[0];
        }
      },

      async fetchMerchantTippingOptions() {
        try {
          await MerchantTippingOption.fetchMerchantTippingOptions(this.merchant.id);
        }
        catch (error) {
          this.$_onRequestError({
            toastOptions: {
              message: 'Unable to fetch tipping options'
            },
            error
          });
        }
      },

      async fetchDepartments() {
        try {
          await Department.fetchDepartments();
        }
        catch (error) {
          this.$_onRequestError({
            toastOptions: {
              message: 'Unable to fetch departments'
            },
            error
          });
        }
      },

      async fetchStores() {
        try {
          await Store.fetchAll();
        }
        catch (error) {
          this.$_onRequestError({
            toastOptions: {
              message: 'Unable to fetch stores'
            },
            error
          });
        }
      },

      openLocationSelectModal() {
        const storeList = this.$clone(this.stores).map(s => ({ id: s.storeId, name: s.description }));
        this.$buefy.modal.open({
          parent: this,
          component: searchSelectModal,
          hasModalCard: true,
          trapFocus: true,
          canCancel: false,
          customClass: 'auto-width',
          props: {
            title: 'Select Location',
            confirmText: 'Select',
            placeholderText: 'Search for a location...',
            data: storeList,
            isSingleSelection: true,
            onSubmit: this.selectLocation
          }
        });
      },


      async fetchEmployees(storeId) {
        try {
          await Employee.fetchEmployees(storeId);
        }
        catch (error) {
          this.$_onRequestError({
            toastOptions: {
              message: 'Unable to fetch employees'
            },
            error
          });
        }
      },

      async selectLocation(storeId) {
        await this.fetchEmployees(storeId);
        storage.local.set(`${this.$_selectedMerchantId}-selectedStoreIdHotelTipping`, storeId);
        this.selectedStore = this.$clone(this.stores).find(s => s.storeId === storeId);
      }
    }
  };
</script>
