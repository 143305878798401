<template>
  <validated-form
    v-slot="{ dirty }"
    form-id="orderLimitsForm"
    :disabled="!$can('update','StoreMapping') || !$can('update','StoreOrderLimit')"
    @valid-submit="handleSubmit"
  >
    <panel start-open title="Order Throttling" collapsible :loading="isFetching">
      <template #buttons>
        <b-button
          native-type="submit"
          :loading="isSubmitting"
          type="is-primary"
          rounded
          size="is-medium"
          :class="['is-bold', dirty && !invalidDaysValidation && 'pulse-slow']"
          :disabled="invalidDaysValidation"
        >
          Save
        </b-button>
      </template>

      <div class="is-grid col-2 col-1-tablet gap-lg mar-b-lg">
        <validated-input
          rules="required"
          name="pickupTimesIntervalMinutes"
          label="Pickup Time Interval"
          sub-label="Minutes between available pickup time slots"
          class="mar-b-lg"
        >
          <b-select
            v-model="pickupTimesIntervalMinutes"
            expanded
          >
            <option v-for="{ display, value } in orderingSchedules" :key="value" :value="value">
              {{ display }}
            </option>
            <option disabled>──────</option>
            <option value="custom">Custom</option>
          </b-select>
          <transition name="fade-right">
            <validated-text-input
              v-if="isCustomInterval(pickupTimesIntervalMinutes)"
              v-model="customInputs.pickupTimesIntervalMinutes"
              class="control"
              type="number"
              rules="min_value:1|required"
              :custom-messages="{ min_value: 'You must select a time interval of 1 or greater' }"
              name="customPickupTimeInterval"
              label="Custom Pickup Time Interval"
              placeholder="mm"
              output-string
              hide-label
              status-icon
            />
          </transition>
        </validated-input>
      </div>

      <template v-if="Object.keys(form).length">
        <b-field>
          <template #label>
            <p>General Order Limit</p>
            <p class="sub-label">Maximum number of orders accepted for each pickup interval</p>
          </template>
          <div class="is-flex gap">
            <numberinput-switch
              v-model="form.null.null.orderLimit"
              switch-label="No Limit"
              :disabled="!$can('update', 'StoreOrderLimit')"
              :true-value="0"
              :false-value="1"
            />
            <b-button
              v-if="form.null.null.platformTypeId && $can('update','StoreOrderLimit')"
              outlined
              icon-left="trash-alt"
              type="is-danger is-light"
              @click="removeOrderLimit({ menuTypeId: form.null.null.menuTypeId, platformTypeId: form.null.null.platformTypeId })"
            />
          </div>
        </b-field>
        <collapsible-section :start-open="!!(storeOrderLimitSchedules && storeOrderLimitSchedules.length)" title="Schedule" class="mar-t">
          <b-message type="is-primary" class="is-compact has-shadow is-inline-block" has-icon icon="info-circle">
            <p>Order Limit Schedules can be configured for each day. Multiple order limits can be scheduled per day.</p>
            <p>Any schedule-specific order limits will override the general limit.</p>
          </b-message>
          <store-order-limit-schedules :store-order-limit-id="form.null.null.id" @schedule-updated="updateSchedules" @invalid-days-validation="(val) => invalidDaysValidation = val" />
        </collapsible-section>

        <collapsible-section title="Advanced Options" class="mar-t">
          <b-message type="is-primary" class="is-compact has-shadow is-inline-block" has-icon icon="info-circle">
            <p>Order Limit Overrides can be configured for specific menus or applied to all menus for a platform.</p>
            <p>Any menu-specific order limits will be in addition to the limit for all menus.</p>
          </b-message>
          <div class="advanced-store-order-limits">
            <section v-for="(platformType) in supportedPlatformTypes" :key="platformType.id">
              <p class="subtitle is-5 is-flex align-center mar-none">
                <span class="mar-r-sm">
                  {{ platformType.displayName }} Order Limit Overrides
                </span>
                <dropdown-menu position="right-start" :disabled="Object.keys(form[platformType.id]).length === menuTypeOptions.length">
                  <b-button
                    slot="trigger"
                    type="is-primary is-light"
                    rounded
                    :disabled="Object.keys(form[platformType.id]).length === menuTypeOptions.length"
                    icon-left="plus"
                    size="is-small"
                    class="pad-x-md"
                  />
                  <b-dropdown-item
                    v-for="menuType in menuTypeOptions"
                    :key="menuType.id"
                    :disabled="!!form[platformType.id][menuType.id]"
                    class="is-flex justify-between pad-r-sm"
                    @click="addStoreOrderLimit({ menuTypeId: menuType.id, platformTypeId: platformType.id })"
                  >
                    {{ menuType.displayName }}
                    <b-icon icon="arrow-right" />
                  </b-dropdown-item>
                </dropdown-menu>
              </p>
              <transition-group name="fade-down-slow" :class="['is-flex-wrap gap', { 'mar-t': Object.keys(form[platformType.id]).length }]">
                <template v-for="menuType in menuTypeOptions">
                  <div
                    v-if="form[platformType.id][menuType.id]"
                    :key="`${platformType.id}-${menuType.id}`"
                    class="pad-x pad-b pad-t-sm has-background-white-bis has-border has-border-grey-lightest has-radius"
                  >
                    <p class="label">
                      {{ menuType.displayName }}
                    </p>
                    <div class="is-flex gap">
                      <numberinput-switch
                        v-model="form[platformType.id][menuType.id].orderLimit"
                        switch-label="No Limit"
                        :disabled="!$can('update', 'StoreOrderLimit')"
                        :true-value="0"
                        :false-value="1"
                      />
                      <b-button
                        v-if="platformType.id && $can('update','StoreOrderLimit')"
                        outlined
                        icon-left="trash-alt"
                        type="is-danger is-light"
                        @click="removeOrderLimit({ menuTypeId: menuType.id, platformTypeId: platformType.id })"
                      />
                    </div>
                  </div>
                </template>
              </transition-group>
            </section>
          </div>
        </collapsible-section>
      </template>
    </panel>
  </validated-form>
</template>

<script>
  import merchantMixin from '@/mixins/merchant';
  import StoreOrderLimit from '@/store/classes/StoreOrderLimit';
  import PlatformType from '@/store/classes/PlatformType';
  import MenuType from '@/store/classes/MenuType';
  import MerchantMenuTypeConfiguration from '@/store/classes/MerchantMenuTypeConfiguration';
  import storeOrderLimitSchedules from './store-order-limit-schedules.vue';
  import getChangedResources from '@/helpers/get-changed-resources';
  import Store from '@/store/classes/Store';

  export default {
    name: 'StoreOrderLimits',

    components: { storeOrderLimitSchedules },

    mixins: [merchantMixin],

    props: {
      store: {
        type: Object,
        required: true
      }
    },

    data() {
      return {
        form: {},
        schedulesForm: [],
        pickupTimesIntervalMinutes: null,
        customInputs: {
          pickupTimesIntervalMinutes: null
        },
        orderingSchedules: [
          { display: '5 minutes', value: 5 },
          { display: '10 minutes', value: 10 },
          { display: '15 minutes', value: 15 },
          { display: '20 minutes', value: 20 },
          { display: '25 minutes', value: 25 },
          { display: '30 minutes', value: 30 }
        ],
        isFetching: false,
        isSubmitting: false,
        invalidDaysValidation: false
      };
    },

    computed: {
      storeOrderLimits() {
        return StoreOrderLimit.query().where('storeId', this.store.storeId).with('storeOrderLimitSchedules').get();
      },

      storeOrderLimitSchedules() {
        return this.storeOrderLimits.find(s => !s.menuTypeId && !s.platformTypeId)?.storeOrderLimitSchedules;
      },

      groupedStoreOrderLimits() {
        const platformTypeIdObj = this.platformTypes.reduce((acc, pt) => {
          acc[pt.id] = {};
          return acc;
        }, { null: {} });

        return this.storeOrderLimits.reduce((acc, sol) => {
          acc[sol.platformTypeId][sol.menuTypeId] = sol;
          return acc;
        }, platformTypeIdObj);
      },

      menuTypeOptions() {
        return [
          { id: null, displayName: 'All Menus' },
          ...this.store.supportedMenuTypes
        ];
      },

      supportedPlatformTypes() {
        return this.platformTypes.filter((pt) => {
          const storeMappingAttrString = `${pt.name.toLowerCase()}OrderingEnabled`;
          return this.store[storeMappingAttrString];
        });
      },

      platformTypes() {
        return PlatformType.all();
      }
    },

    created() {
      this.onCreated();
    },

    methods: {
      toggleLoading(field, value) {
        this[field] = value;
      },

      async onCreated() {
        this.toggleLoading('isFetching', true);

        await Promise.all([
          this.fetchStoreOrderLimits(),
          this.fetchMenuTypes(),
          this.fetchPlatformTypes(),
          this.fetchMerchantMenuTypes()
        ]);

        this.form = this.$clone(this.groupedStoreOrderLimits);
        this.pickupTimesIntervalMinutes = this.store.pickupTimesIntervalMinutes;
        this.setCustomInputs();

        if (!this.form.null.null) {
          this.addStoreOrderLimit({ platformTypeId: null, menuTypeId: null, orderLimit: 0 });
        }

        this.toggleLoading('isFetching', false);
      },

      updateSchedules(updatedSchedules) {
        this.form.null.null.storeOrderLimitSchedules = updatedSchedules;
      },

      isCustomInterval(value) {
        return !!value && !this.orderingSchedules.find(orderingSchedule => orderingSchedule.value === value);
      },

      setCustomInputs() {
        if (this.isCustomInterval(this.store.pickupTimesIntervalMinutes)) {
          this.pickupTimesIntervalMinutes = 'custom';
          this.customInputs.pickupTimesIntervalMinutes = Number(this.store.pickupTimesIntervalMinutes);
        }
      },

      handleCustomInputsForRequest() {
        if (this.pickupTimesIntervalMinutes === 'custom') {
          this.pickupTimesIntervalMinutes = Number(this.customInputs.pickupTimesIntervalMinutes);
        }
      },

      async fetchStoreOrderLimits() {
        try {
          await StoreOrderLimit.fetchStoreOrderLimits(this.store.storeId);
        }
        catch (error) {
          this.$_onRequestError({
            toastOptions: {
              message: 'Failed to fetch store order limits'
            },
            error
          });
        }
      },

      async fetchMerchantMenuTypes() {
        try {
          await MerchantMenuTypeConfiguration.fetchMerchantMenuTypeConfigurations(this.$_selectedMerchantId);
        }

        catch (error) {
          this.$_onRequestError({
            error,
            toastOptions: {
              message: 'There was an error fetching the merchant menu types'
            }
          });
        }
      },

      async fetchMenuTypes() {
        try {
          await MenuType.fetchMenuTypes();
        }

        catch (error) {
          this.$_onRequestError({
            error,
            toastOptions: {
              message: 'There was an issue fetching the menu types'
            }
          });
        }
      },

      async fetchPlatformTypes() {
        try {
          await PlatformType.fetchPlatformTypes();
        }

        catch (error) {
          this.$_onRequestError({
            error,
            toastOptions: {
              message: 'There was an issue fetching the platform types'
            }
          });
        }
      },

      addStoreOrderLimit({ menuTypeId, platformTypeId, orderLimit = 1 }) {
        const newStoreOrderLimit = new StoreOrderLimit({
          merchantId: this.$_selectedMerchantId,
          storeId: this.store.storeId,
          orderLimit,
          menuTypeId,
          platformTypeId
        });
        this.$set(this.form[platformTypeId], menuTypeId, newStoreOrderLimit);
      },

      removeOrderLimit({ menuTypeId, platformTypeId }) {
        const updatedOrderLimitsForPlatformType = this.$clone(this.form[platformTypeId]);
        delete updatedOrderLimitsForPlatformType[menuTypeId];
        this.$set(this.form, platformTypeId, updatedOrderLimitsForPlatformType);
      },

      async handleSubmit() {
        try {
          this.toggleLoading('isSubmitting', true);

          this.handleCustomInputsForRequest();
          await Store.updateStore({
            storeId: this.store.storeId,
            pickupTimesIntervalMinutes: this.pickupTimesIntervalMinutes
          });
          this.setCustomInputs();

          const updatedStoreOrderLimits = Object.values(this.form).flatMap(x => Object.values(x));
          const { added, removed, updated } = getChangedResources({
            oldArray: this.storeOrderLimits,
            newArray: updatedStoreOrderLimits,
            comparisonKey: 'id',
            updatedComparisonFn: (n, o) => n.orderLimit !== o.orderLimit
          });

          const defaultOrderLimit = this.storeOrderLimits.find(s => !s.platformTypeId && !s.menuTypeId);
          const isDefaultLimitUpdated = updated.find(u => !u.platformTypeId && !u.menuTypeId);
          const shouldNotCreateDefault = !defaultOrderLimit && this.form.null.null.storeOrderLimitSchedules.length === 0 && this.form.null.null.orderLimit === 0;
          // NOTE: No record and 0 order limit are the same thing, so do not add unnecessary records if no schedules are being created
          if (shouldNotCreateDefault) {
            const indexToRemove = added.findIndex(s => !s.platformTypeId && !s.menuTypeId);
            added.splice(indexToRemove, 1);
          }
          // NOTE: getChangedResources() will not catch if nested schedules are updated, so always add default to be updated as a precaution
          else if (defaultOrderLimit && !isDefaultLimitUpdated) {
            updated.push(this.form.null.null);
          }

          await Promise.all([
            ...removed.map(r => StoreOrderLimit.removeStoreOrderLimit(r.id)),
            ...added.map(a => StoreOrderLimit.addStoreOrderLimit(a)),
            ...updated.map(u => StoreOrderLimit.updateStoreOrderLimit(u))
          ]);
          this.form = this.$clone(this.groupedStoreOrderLimits);

          this.$_onRequestSuccess({
            toastOptions: {
              message: 'Order throttling successfully updated!'
            }
          });
        }
        catch (error) {
          this.$_onRequestError({
            toastOptions: {
              message: 'There was an issue updating your Order Throttling'
            },
            error
          });
        }

        finally {
          this.toggleLoading('isSubmitting', false);
        }
      }
    }
  };
</script>

<style lang="sass" scoped>
  .advanced-store-order-limits > *:not(:last-child)
    margin-bottom: $size-large
    padding-bottom: $size-large
    border-bottom: 2px solid $white-ter
</style>
