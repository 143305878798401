<template>
  <validated-form
    ref="form"
    form-id="storePosSettings"
    @valid-submit="updateStore"
  >
    <sticky-save-container
      :saving="submittingStore"
      :loading="isFetchingMerchant"
      title="Core Configurations"
    >
      <div class="is-grid col-2 gap-lg">
        <validated-input label="POS Type" name="posProvider" rules="required">
          <b-select v-model="selectedPosType" expanded placeholder="Select a Point of Sale Provider...">
            <option v-if="!merchantPosProviders.length && !storePosProviders.length" disabled :value="null">
              No POS Providers Configured
            </option>
            <template v-else>
              <optgroup v-if="merchantPosProviders.length" label="Merchant POS Providers">
                <option v-for="{ posType, id } in merchantPosProviders" :key="id" :value="posType">
                  {{ posType.name }}
                </option>
              </optgroup>
              <optgroup v-if="storePosProviders.length" label="Location POS Providers">
                <option v-for="{ posType, id } in storePosProviders" :key="id" :value="posType">
                  {{ posType.name }}
                </option>
              </optgroup>
            </template>
          </b-select>
        </validated-input>
        <validated-text-input
          v-model="form.merchantLocationId"
          label="Merchant Location ID"
          monospaced
          name="merchantLocationId"
          type="text"
        />
        <validated-text-input
          v-model="form.paymentLocationId"
          label="Payment Location ID"
          monospaced
          name="paymentLocationId"
          type="text"
        />
        <validated-text-input
          v-model="form.posLocationId"
          label="POS Location ID"
          monospaced
          name="posLocationId"
          type="text"
        />
        <validated-text-input
          v-model="form.posEmployeeId"
          label="POS Employee ID"
          monospaced
          name="posEmployeeId"
          type="text"
        />
        <validated-text-input v-model="form.posRevenueCenter" label="POS Revenue Center" name="posRevenueCenter" type="text" />
        <validated-text-input
          v-model="form.posThirdPartyTenderTypeId"
          label="POS Third Party Tender Type Id"
          monospaced
          name="posThirdPartyTenderTypeId"
          type="text"
        />
        <validated-text-input
          v-model="form.loyaltyLocationId"
          label="Loyalty Location ID"
          monospaced
          name="loyaltyLocationId"
          type="text"
        />
        <validated-text-input
          v-model="form.loyaltyStoreNumber"
          label="Loyalty Store Number"
          monospaced
          name="loyaltyStoreNumber"
          type="text"
          tooltip="Store number for the location used with the loyalty provider."
        />
        <validated-text-input
          v-model="form.loyaltyShortIdentifierCode"
          label="Loyalty Short Identifier Code"
          monospaced
          name="loyaltyShortIdentifierCode"
          type="text"
        />
        <validated-text-input
          v-model="form.loyaltyApiKey"
          label="Loyalty API Key"
          monospaced
          name="loyaltyApiKey"
          type="text"
        />
        <validated-text-input
          v-model="form.expoLargeOrderItemTotal"
          label="Large Order Threshold"
          monospaced
          name="expoLargeOrderItemTotal"
          type="dollars"
          rules="required|greaterThan:0"
          tooltip="Large order threshold is calculated based on the item total before taxes/fees/discounts/etc…"
        />
        <validated-text-input
            v-if="textToPayEnabled"
            v-model="form.textToPayTimeoutInHours"
            label="Text To Pay Timeout In Hours"
            monospaced
            name="textToPayTimeoutInHours"
            type="number"
            rules="greaterThan:0"
        />
        <check-button
          v-model="form.posPriceSyncEnabled"
          type="is-success"
          class="mar-none"
        >
          Automated Price Import
        </check-button>
      </div>
    </sticky-save-container>
  </validated-form>
</template>

<script>
  import merchantMixin from '@/mixins/merchant';
  import Store from '@/store/classes/Store';
  import Merchant from '@/store/classes/Merchant';
  import filterObjectKeys from '@/helpers/filter-object-keys';

  export default {
    name: 'StoreCoreConfigurations',

    mixins: [merchantMixin],

    props: {
      store: {
        type: Object,
        required: true
      }
    },

    data() {
      return {
        form: null
      };
    },

    computed: {
      isFetchingMerchant() {
        return Merchant.$state().fetchingSelectedMerchant;
      },

      submittingStore() {
        return Store.$state().submitting;
      },

      merchantPosProviders() {
        return this.$_selectedMerchant.posConfigurations.filter(mpos => mpos.storeId === -1);
      },

      textToPayEnabled() {
        return this.$_selectedMerchant.textToPayEnabled;
      },

      storePosProviders() {
        return this.$_selectedMerchant.posConfigurations.filter(mpos => mpos.storeId === this.store.storeId);
      },

      selectedPosType: {
        get() {
          return this.$_selectedMerchant.posConfigurations.find(mpp => mpp.posType.id === this.form.posTypeId)?.posType;
        },

        set(value) {
          this.form.posTypeId = value?.id;
        }
      }
    },

    watch: {
      'store.posType.id': 'setNewPosTypeId'
    },

    methods: {
      onMerchantLoad() {
        this.form = this.$clone(this.store);
        this.highlightSelectedAttribute();
      },

      setNewPosTypeId(newId) {
        this.form.posTypeId = newId;
      },

      async updateStore() {
        try {
          const acceptedKeys = [
            'id',
            'storeId',
            'posTypeId',
            'merchantLocationId',
            'paymentLocationId',
            'posLocationId',
            'posEmployeeId',
            'posRevenueCenter',
            'posThirdPartyTenderTypeId',
            'posPriceSyncEnabled',
            'expoLargeOrderItemTotal',
            'loyaltyLocationId',
            'loyaltyStoreNumber',
            'loyaltyShortIdentifierCode',
            'loyaltyApiKey',
            'textToPayTimeoutInHours'
          ];

          const updatedStorePayload = filterObjectKeys(this.form, acceptedKeys);

          await Store.updateStore(updatedStorePayload);

          this.$_onRequestSuccess({
            toastOptions: { message: `Configurations for <b>${this.form.description}</b> have been successfully updated!` }
          });
        }
        catch (error) {
          this.$_onRequestError({ error, toastOptions: { message: `There was an issue updating the configurations for <b>${this.form.description}</b>` } });
        }
      },

      highlightSelectedAttribute() {
        const selectedElement = this.$route.query.highlighted;
        this.$nextTick(() => {
          const element = this.$el.querySelector(`[data-id="${selectedElement}"]`);

          if (element) {
            element.scrollIntoView({ block: 'center', behavior: 'smooth' });
            element.classList.add('highlight-element');
            element.focus();

            setTimeout(() => {
              element.classList.remove('highlight-element');
            }, 3000);
          }
        });
      }
    }
  };
</script>
