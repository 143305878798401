<template>
  <div class="has-radius has-shadow has-background-white has-border-grey-darker is-clipped">
    <img
      v-if="imageUrl"
      :src="imageUrl"
      alt="app message image"
      style="width: 100%"
    >
    <div v-else class="has-background-grey-lighter pad-md is-flex align-center justify-center">
      <p class="mar-md">App Message Image</p>
    </div>

    <div class="is-flex-column align-center pad">
      <span class="title is-5">{{ inAppMessage.title || 'In-App Message Title Here' }}</span>
      <p class="is-size-6">{{ inAppMessage.bodyContent || 'In-App Message Content Here' }}</p>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'InAppMessagePreview',

    props: {
      inAppMessage: {
        type: Object,
        required: true
      },

      tempImageFile: {
        type: File,
        default: null
      }
    },

    computed: {
      imageUrl() {
        if (this.tempImageFile) {
          return URL.createObjectURL(this.tempImageFile);
        }
        else if (this.inAppMessage.primaryImageUrl) {
          return this.inAppMessage.primaryImageUrl;
        }
        else {
          return null;
        }
      }
    }
  };
</script>
