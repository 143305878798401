import findCloseableParent from '@/helpers/find-closeable-parent';
import { mapActions, mapState } from 'vuex';


const confirmModalClose = {
  data() {
    return {
      cmc_isModalOpen: false,
      p_cmc_programmatic: false
    };
  },

  computed: {
    ...mapState(
      'formStore', [
        'formChanged'
      ]
    )
  },

  methods: {
    ...mapActions('formStore', [
      'setFormChanged'
    ]),

    $_toggleModal() {
      this.cmc_isModalOpen = !this.cmc_isModalOpen;
    },

    p_closeModal() {
      this.setFormChanged({ hasChanged: false });
      if (this.p_cmc_programmatic) {
        const closeableParent = findCloseableParent(this);
        if (closeableParent) {
          closeableParent.close();
        }
      }
      else {
        this.$_toggleModal();
      }
    },

    async $_confirmCloseModal(options = {}) {
      this.p_cmc_programmatic = options.programmatic;

      if (this.formChanged && !options.forceClose) {
        const { result } = await this.$buefy.dialog.confirm({
          title: 'Unsaved Changes',
          message: 'Are you sure you want to close this form?<br>Any unsaved changes will be lost.',
          type: 'is-danger',
          trapFocus: true,
          onConfirm: this.p_closeModal,
          canCancel: ['button'],
          confirmText: 'Yes',
          cancelText: 'No'
        });
        return result;
      }
      else {
        this.p_closeModal();
        return true;
      }
    }
  }
};



export default confirmModalClose;
