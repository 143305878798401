/* eslint-disable import/no-cycle */
import http from '@/services/http';
import FetchModel from '@/store/classes/FetchModel';


export default class MenuItemPlatformType extends FetchModel {
  static entity = 'menuItemPlatformTypes'

  static primaryKey = ['platformTypeId', 'menuItemId']

  static fields() {
    return {
      platformTypeId: this.attr(''),
      menuItemId: this.attr('')
    };
  }


  // STATE //////////////////////
  static state() {
    return {
      fetching: false,
      submitting: false
    };
  }

  static $state() {
    return this.store().state.entities.menuItemPlatformTypes;
  }

  static getAllPlatformTypesByMenuItemId(menuItemId) {
    return this.query().where('menuItemId', menuItemId).get();
  }


  static async fetchItemPlatformTypes(menuItemId, options = {}) {
    await this.fetchData({
      endpoint: `menu_items/${menuItemId}/platform_types`,
      options,
      transformData: data => data.menuItemPlatformTypes,
      ormMethod: 'insert',
      customQuery: this.query().where('menuItemId', menuItemId)
    });
  }

  // ACTIONS //////////////////////
  static async updateItemPlatformTypes(menuItemId, platformTypeIds) {
    try {
      this.commit((state) => {
        state.submitting = true;
      });

      const { data } = await http.put(`menu_items/${menuItemId}/platform_types`, { platformTypeIds });

      // NOTE: Since this request can be both a create and destroy,
      // we need to delete all existing platform types for the menuItemId
      this.getAllPlatformTypesByMenuItemId(menuItemId)
        .forEach(mipt => this.delete([mipt.platformTypeId, menuItemId]));

      this.insert({
        data: data.menuItemPlatformTypes
      });
    }

    catch (error) {
      throw error;
    }

    finally {
      this.commit((state) => {
        state.submitting = false;
      });
    }
  }
}
