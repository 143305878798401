import { Model } from '@vuex-orm/core';
import http from '@/services/http';
import filterObjectKeys from '@/helpers/filter-object-keys';
import { setUpdatedSortOrders } from '@/helpers/set-new-sort-orders';


export default class MerchantLink extends Model {
  static entity = 'merchantLinks'



  // FIELDS //////////////////////
  static fields() {
    return {
      id: this.attr(''),
      displayName: this.attr(''),
      link: this.attr(''),
      opensNewTab: this.attr(false),
      sortOrder: this.number(''),
      merchantLinkHeaderId: this.attr('')
    };
  }



  // STATE //////////////////////
  static state() {
    return {
      fetchingId: false,
      submitting: false,
      deletingId: false,
      sortingParentId: false
    };
  }

  static $state() {
    return this.store().state.entities.merchantLinks;
  }


  // ACTIONS //////////////////////
  static async fetchMerchantLinks(merchantLinkHeaderId) {
    try {
      this.commit((state) => {
        state.fetchingId = merchantLinkHeaderId;
      });

      const { data } = await http.get(`merchant_link_headers/${merchantLinkHeaderId}/merchant_links`);

      this.insert({
        data: data.merchantLinks
      });
    }

    catch (error) {
      throw error;
    }

    finally {
      this.commit((state) => {
        state.fetchingId = false;
      });
    }
  }

  static async createMerchantLink(merchantLink) {
    try {
      this.commit((state) => {
        state.submitting = true;
      });

      const response = await http.post(`merchant_link_headers/${merchantLink.merchantLinkHeaderId}/merchant_links`, { merchantLink });

      this.insert({
        data: response.data.merchantLink
      });

      return response.data.merchantLink;
    }

    catch (error) {
      throw error;
    }

    finally {
      this.commit((state) => {
        state.submitting = false;
      });
    }
  }

  static async updateMerchantLink(merchantLink) {
    try {
      this.commit((state) => {
        state.submitting = true;
      });

      const acceptedKeys = [
        'displayName',
        'link',
        'opensNewTab',
        'sortOrder'
      ];

      const response = await http.put(`merchant_link_headers/${merchantLink.merchantLinkHeaderId}/merchant_links/${merchantLink.id}`, {
        merchantLink: filterObjectKeys(merchantLink, acceptedKeys)
      });

      this.update({
        data: response.data.merchantLink
      });
    }

    catch (error) {
      throw error;
    }

    finally {
      this.commit((state) => {
        state.submitting = false;
      });
    }
  }

  static async removeMerchantLink(merchantLinkId) {
    try {
      this.commit((state) => {
        state.deletingId = merchantLinkId;
      });

      const { data } = await http.delete(`merchant_links/${merchantLinkId}`);

      this.delete(merchantLinkId);

      return data.merchantLinks;
    }

    catch (error) {
      throw error;
    }

    finally {
      this.commit((state) => {
        state.deletingId = false;
      });
    }
  }

  static async sortMerchantLinks({ fromSortOrder, toSortOrder, array }) {
    const merchantLinkHeaderId = array[0].merchantLinkHeaderId;
    try {
      this.commit((state) => {
        state.sortingParentId = merchantLinkHeaderId;
      });

      const newMerchantLinks = setUpdatedSortOrders({ fromIndex: fromSortOrder, toIndex: toSortOrder, array });

      this.insert({ data: newMerchantLinks });

      await http.put('merchant_links/sort_order', {
        merchantLinks: newMerchantLinks.map(obj => filterObjectKeys(obj, ['id', 'sortOrder']))
      });
    }

    catch (error) {
      this.insert({ data: array });
      throw error;
    }

    finally {
      this.commit((state) => {
        state.sortingParentId = false;
      });
    }
  }
}
