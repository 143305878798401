const valuePropositionTypes = {
  CHECK: {
    FE_TYPE: 'CheckHeader',
    PERCENT: 'CheckHeaderPercentageDiscountModel',
    DOLLAR: 'CheckHeaderDollarDiscountModel'
  },
  ITEM: {
    FE_TYPE: 'SpecifiedItem',
    PERCENT: 'SpecifiedItemPercentageDiscountModel',
    DOLLAR: 'SpecifiedItemDollarDiscountModel',
    OVERRIDE: 'SpecifiedItemPriceOverrideDiscountModel'
  }
};

const discountAmountTypes = {
  DOLLAR: 'dollar',
  PERCENT: 'percent',
  OVERRIDE: 'override'
};

const discountedUnitPrecedenceTypes = {
  UNKNOWN: 'Unknown',
  HIGHEST_UNIT_PRICE_FIRST: 'HighestUnitPriceFirst',
  LOWEST_UNIT_PRICE_FIRST: 'LowestUnitPriceFirst'
};

const constraintTypes = {
  ITEM_OFFER: 'SpecifiedItemOfferConstraintModel',
  MINIMUM_PURCHASE: 'MinimumPurchaseOfferConstraintModel',
  // 'fulfillment type' === 'order mode'
  FULFILLMENT_TYPE: 'FulfillmentTypeOfferConstraintModel',
  DAY_OF_WEEK: 'DayOfWeekOfferConstraintModel',
  TIME_OF_DAY: 'TimeOfDayOfferConstraintModel',
  PLATFORM_TYPE: 'PlatformTypeOfferConstraintModel',
  LOCATION: 'LocationOfferConstraintModel',
  MENU_TYPE: 'MenuTypeOfferConstraintModel'
};

const offerStates = {
  DRAFT: 'draft',
  LIVE: 'live',
  EXPIRED: 'expired'
};

const itemDiscountBases = {
  MAIN_MENU_ITEM: {
    id: 1,
    display: 'Main Menu Item'
  },
  MAIN_MENU_ITEM_ALL_MODS: {
    id: 2,
    display: 'Main Menu Item and All Modifiers'
  },
  MAIN_MENU_ITEM_MATCHING_MODS: {
    id: 3,
    display: 'Main Menu Item and Matching Modifiers'
  },
  MATCHING_MODS: {
    id: 4,
    display: 'Matching Modifiers Only'
  }
};

export {
  valuePropositionTypes,
  discountAmountTypes,
  constraintTypes,
  offerStates,
  itemDiscountBases,
  discountedUnitPrecedenceTypes
};

