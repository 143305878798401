<template>
  <div class="card">
    <div class="card-content">
      <fieldset>
        <p class="title is-6">
          Redemption Period
        </p>
        <p class="subtitle is-7 has-text-grey mar-b-lg">
          Define when this offer is available to be redeemed
        </p>

        <div class="is-grid col-2 gap-x">
          <radio-button
            v-model="dynamicExpirationPeriod"
            name="redemptionType"
            :native-value="null"
            :disabled="offerReadOnly"
          >
            Fixed
            <template #sublabel>Expires on a specific date</template>
          </radio-button>
          <radio-button
            v-model="dynamicExpirationPeriod"
            name="redemptionType"
            :disabled="offerReadOnly"
            :native-value="dynamicExpirationPeriodValid ? parseDynamicExpirationPeriodValue(value.dynamicExpirationPeriod) : 1"
          >
            Dynamic
            <template #sublabel>Expires after a set number of days</template>
          </radio-button>
        </div>

        <hr>

        <validation-observer class="is-grid col-2 gap-x">
          <validated-input
            name="redemptionsAllowedStartDate"
            rules="required"
            label="Start Date"
          >
            <b-datepicker
              v-model="startDate"
              :years-range="dys_ranges.startDate"
              placeholder="Start Date"
              icon="calendar-alt"
              class="has-extra-shadow"
              :events="endDate ? [{ date: endDate, type: 'is-danger' }] : []"
              indicators="bars"
              :focused-date="endDate"
              :min-date="minDate"
              :disabled="offerReadOnly"
              @change-year="(year) => $_handleYearChange({ year, type: 'startDate', yearsInPast: 10, yearsInFuture: 30 })"
            >
              <div class="buttons is-right">
                <b-button @click="handleInput('redemptionsAllowedStartDate', null)">Clear</b-button>
              </div>
            </b-datepicker>
          </validated-input>

          <validated-input
            name="redemptionsAllowedEndDate"
            :rules="{ required: !dynamicExpirationPeriodValid }"
            label="End Date"
            :sub-label="dynamicExpirationPeriodValid ? '(Optional)' : ''"
            sub-label-on-side
            :tooltip="dynamicExpirationPeriodValid ? 'Offer will expire when the dynamic period ends or the end date is reached, whichever comes first.' : ''"
          >
            <b-datepicker
              v-model="endDate"
              :years-range="dys_ranges.endDate"
              placeholder="End Date"
              icon="calendar-alt"
              class="has-extra-shadow"
              position="is-bottom-left"
              :min-date="minDate"
              :events="startDate ? [{ date: startDate, type: 'is-success' }] : []"
              indicators="bars"
              :focused-date="startDate"
              :disabled="offerReadOnly"
              @change-year="(year) => $_handleYearChange({ year, type: 'endDate', yearsInPast: 10, yearsInFuture: 30 })"
            >
              <div class="buttons is-right">
                <b-button @click="handleInput('redemptionsAllowedEndDate', null)">Clear</b-button>
              </div>
            </b-datepicker>
          </validated-input>

          <div>
            <validated-input
              v-if="dynamicExpirationPeriodValid"
              label="# of Days Valid After Delivery"
              tooltip="Ex: If an offer is issued today, and the dynamic expiration period is set to 7, the offer will automatically expire in 7 days."
              name="dynamicExpirationPeriod"
              class="mar-t-md"
              :rules="{ required: true }"
            >
              <numberinput-switch
                v-model="dynamicExpirationPeriod"
                switch-label="Same Day Only"
                :disabled="offerReadOnly"
                :min="1"
                :true-value="0"
                :false-value="1"
              />
            </validated-input>
          </div>
        </validation-observer>

        <hr>

        <div class="is-grid dist-y-md">
          <div class="is-flex justify-start align-center">
            <label class="label mar-none mar-r-xl">Offer can be used...</label>
            <b-select v-model="dayTimeConstraints" :disabled="offerReadOnly">
              <option :value="false">All day, every day</option>
              <option :value="true">During specific times</option>
            </b-select>
          </div>
          <template v-if="dayTimeConstraints">
            <div>
              <transition name="fade-down">
                <validated-input
                  slim
                  name="selectedValidDays"
                  label="Days of the Week"
                  :custom-messages="{ required: 'At least one day must be selected' }"
                  :rules="{ required: !selectedValidDays.length }"
                >
                  <div class="is-grid gap col-7">
                    <check-button
                      v-for="(dayValue, day) in daysOfWeek"
                      :key="`${dayValue}-${day}`"
                      v-model="selectedValidDays"
                      :native-value="dayValue"
                      :disabled="offerReadOnly"
                      :label="day"
                    />
                  </div>
                </validated-input>
              </transition>
            </div>
            <transition name="fade-down">
              <div>
                <label key="timeOfDayLabel" class="label">Time of Day</label>
                <div key="timeOfDay" class="is-flex-start-aligned">
                  <radio-button
                    v-model="timeOfDayRange"
                    name="timeOfDayRange"
                    :native-value="false"
                    :disabled="offerReadOnly"
                  >
                    All Day
                  </radio-button>
                  <radio-button
                    v-model="timeOfDayRange"
                    name="timeOfDayRange"
                    :native-value="true"
                    class="mar-l-md"
                    :disabled="offerReadOnly"
                  >
                    Time Range
                  </radio-button>
                </div>
              </div>
            </transition>
            <transition name="fade-down">
              <div
                v-if="timeOfDayRange"
                key="startEndTime"
                class="is-flex-start-aligned"
              >
                <validated-input
                  rules="required"
                  name="startTime"
                  label="From"
                >
                  <b-timepicker
                    v-model="startTime"
                    size="is-small"
                    :default-minutes="0"
                    hour-format="12"
                    inline
                    :disabled="offerReadOnly"
                  />
                </validated-input>
                <validated-input
                  rules="required|dateTimeIsAfter:@startTime"
                  name="endTime"
                  label="To"
                  class="mar-l-md"
                >
                  <b-timepicker
                    v-model="endTime"
                    size="is-small"
                    :default-minutes="0"
                    hour-format="12"
                    inline
                    :disabled="offerReadOnly"
                  />
                </validated-input>
              </div>
            </transition>
          </template>
        </div>
      </fieldset>
    </div>
  </div>
</template>

<script>
  import moment from 'moment-timezone';
  import { constraintTypes } from '@/constants/offers';
  import daysOfWeek from '@/constants/daysOfWeek';
  import dynamicYearSelectMixin from '@/mixins/dynamic-year-select';

  export default {
    name: 'OfferTimeframeStep',

    mixins: [dynamicYearSelectMixin],

    props: {
      value: {
        type: Object,
        required: true
      },

      offerReadOnly: {
        type: Boolean,
        required: true
      }
    },

    data() {
      return {
        daysOfWeek
      };
    },

    computed: {
      dynamicExpirationPeriod: {
        get() {
          return this.parseDynamicExpirationPeriodValue(this.value.dynamicExpirationPeriod);
        },
        set(value) {
          const periodLength = (value !== undefined && value !== null) ? `P${value}D` : null;
          this.handleInput('dynamicExpirationPeriod', periodLength);
        }
      },

      dynamicExpirationPeriodValid() {
        return this.dynamicExpirationPeriod !== undefined && this.dynamicExpirationPeriod !== null;
      },

      minDate() {
        return moment()
          .startOf('day')
          .toDate();
      },

      startDate: {
        get() {
          return this.value.redemptionsAllowedStartDate && moment(this.value.redemptionsAllowedStartDate).toDate();
        },
        set(val) {
          const dateString = moment.utc(val).format('YYYY-MM-DD');
          this.handleInput('redemptionsAllowedStartDate', dateString);
        }
      },

      endDate: {
        get() {
          return this.value.redemptionsAllowedEndDate && moment(this.value.redemptionsAllowedEndDate).toDate();
        },
        set(val) {
          const dateString = moment.utc(val).format('YYYY-MM-DD');
          this.handleInput('redemptionsAllowedEndDate', dateString);
        }
      },

      dayOfWeekConstraint() {
        return this.getConstraintByType(constraintTypes.DAY_OF_WEEK);
      },

      timeOfDayConstraint() {
        return this.getConstraintByType(constraintTypes.TIME_OF_DAY);
      },

      dayTimeConstraints: {
        get() {
          return !!(this.dayOfWeekConstraint || this.timeOfDayConstraint);
        },
        set(value) {
          if (value) {
            const dayOfWeekConstraint = {
              validDays: Object.values(this.daysOfWeek),
              constraintType: constraintTypes.DAY_OF_WEEK
            };
            this.handleInput('constraints', [...this.value.constraints, dayOfWeekConstraint]);
          }
          else {
            const updatedConstraints = this.value.constraints.filter((constraint) => {
              const isDayOfWeekConstraint = constraint.constraintType === constraintTypes.DAY_OF_WEEK;
              const isTimeOfDayConstraint = constraint.constraintType === constraintTypes.TIME_OF_DAY;
              return !(isDayOfWeekConstraint || isTimeOfDayConstraint);
            });
            this.handleInput('constraints', updatedConstraints);
          }
        }
      },

      selectedValidDays: {
        get() {
          return this.dayOfWeekConstraint?.validDays || [];
        },
        set(validDays) {
          this.updateConstraint({ constraintType: constraintTypes.DAY_OF_WEEK, payload: { validDays } });
        }
      },

      timeOfDayRange: {
        get() {
          return !!this.timeOfDayConstraint;
        },
        set(value) {
          if (value) {
            const timeOfDayConstraint = {
              startTime: null,
              endTime: null,
              constraintType: constraintTypes.TIME_OF_DAY
            };
            this.handleInput('constraints', [...this.value.constraints, timeOfDayConstraint]);
          }
          else {
            const updatedConstraints = this.value.constraints.filter(constraint => !(constraint.constraintType === constraintTypes.TIME_OF_DAY));
            this.handleInput('constraints', updatedConstraints);
          }
        }
      },

      startTime: {
        get() {
          return this.timeOfDayConstraint ? moment(this.timeOfDayConstraint.startTime, 'HH:mm').toDate() : null;
        },
        set(value) {
          this.updateConstraint({
            constraintType: constraintTypes.TIME_OF_DAY,
            payload: { startTime: this.formatTime(value) }
          });
        }
      },

      endTime: {
        get() {
          return this.timeOfDayConstraint ? moment(this.timeOfDayConstraint.endTime, 'HH:mm').toDate() : null;
        },
        set(value) {
          this.updateConstraint({
            constraintType: constraintTypes.TIME_OF_DAY,
            payload: { endTime: this.formatTime(value) }
          });
        }
      }
    },

    methods: {
      formatTime: value => moment(value).format('HH:mm'),

      getConstraintByType(constraintType) {
        return this.value.constraints.find(c => c.constraintType === constraintType);
      },

      handleInput(key, value) {
        this.$emit('input', { ...this.value, [key]: value });
      },

      parseDynamicExpirationPeriodValue(dynamicExpirationPeriod) {
        return dynamicExpirationPeriod ? parseInt(dynamicExpirationPeriod.replace(/p|d/gi, ''), 0) : null;
      },

      updateConstraint({ constraintType, payload }) {
        const updatedConstraints = this.$clone(this.value.constraints).map((c) => {
          if (c.constraintType === constraintType) {
            return { ...c, ...payload };
          }
          return c;
        });
        this.handleInput('constraints', updatedConstraints);
      }
    }
  };
</script>
