import { Model } from '@vuex-orm/core';
import moment from 'moment';
import http from '@/services/http';
import filterObjectKeys from '@/helpers/filter-object-keys';

export default class OfferDistribution extends Model {
  static entity = 'offerDistributions';

  static primaryKey = 'publicId';


  // FIELDS //////////////////////
  static fields() {
    return {
      publicId: this.attr(''),
      name: this.attr(''),
      offerPublicId: this.attr(''),
      generatedCodeTargetCount: this.attr(null),
      claimStyle: this.attr(null),
      claimableStartDate: this.attr({}),
      claimableEndDate: this.attr({}),
      formattedStartDate: this.attr(''),
      formattedEndDate: this.attr(''),
      createdDateTime: this.attr(''),
      claimLimitPerAccount: this.attr(null),
      claimLimitPerDistributionCode: this.attr(null),
      status: this.attr('')
    };
  }

  // STATE //////////////////////
  static state() {
    return {
      fetching: false,
      submitting: false
    };
  }

  static $state() {
    return this.store().state.entities.offerDistributions;
  }

  // STATIC METHODS //////////////////////
  static formatDate(dateObj) {
    return moment({ year: dateObj.year, month: dateObj.month - 1, day: dateObj.day }).format('M/D/YY');
  }


  // ACTIONS //////////////////////
  static async fetchOfferDistributions() {
    try {
      this.commit((state) => {
        state.fetching = true;
      });

      const merchantId = this.store().state.entities.merchants.selectedMerchantId;

      const { data } = await http.get(`merchants/${merchantId}/offer_distributions`);

      this.create({
        data: data.offerDistributions.map((offerDistribution) => {
          offerDistribution.formattedStartDate = this.formatDate(offerDistribution.claimableStartDate);
          offerDistribution.formattedEndDate = this.formatDate(offerDistribution.claimableEndDate);
          return offerDistribution;
        })
      });
    }

    catch (error) {
      throw error;
    }

    finally {
      this.commit((state) => {
        state.fetching = false;
      });
    }
  }

  static async addOfferDistribution({ offerDistribution }) {
    try {
      this.commit((state) => {
        state.submitting = true;
      });

      const acceptedKeys = [
        'name',
        'offerPublicId',
        'generatedCodeTargetCount',
        'claimLimitPerAccount',
        'claimLimitPerDistributionCode',
        'claimStyle',
        'claimableStartDate',
        'claimableEndDate'
      ];

      const merchantId = this.store().state.entities.merchants.selectedMerchantId;
      const response = await http.post(`merchants/${merchantId}/offer_distributions`, {
        offerDistribution: filterObjectKeys(offerDistribution, acceptedKeys)
      });

      this.insert({ data: response.data.offerDistribution });
      return response.data.offerDistribution.publicId;
    }

    catch (error) {
      throw error;
    }

    finally {
      this.commit((state) => {
        state.submitting = false;
      });
    }
  }

  // NOTE: Updating an offer distribution has not been implemented by the BE / Loyalty team.
  // This is a placeholder for the future implementation.
  // static async updateOfferDistribution({ offerDistribution }) {
  //   try {
  //     this.commit((state) => {
  //       state.submitting = true;
  //     });

  //     const { publicId } = offerDistribution;

  //     const acceptedKeys = [
  //       'name',
  //       'offerPublicId',
  //       'generatedCodeTargetCount',
  //       'claimLimitPerAccount',
  //       'claimLimitPerDistributionCode',
  //       'claimStyle',
  //       'claimableStartDate',
  //       'claimableEndDate'
  //     ];

  //     const merchantId = this.store().state.entities.merchants.selectedMerchantId;
  //     const response = await http.put(`merchants/${merchantId}/offer_distributions/${publicId}`, {
  //       offerDistribution: filterObjectKeys(offerDistribution, acceptedKeys)
  //     });

  //     this.update({ data: response.data.offerDistribution });
  //   }

  //   catch (error) {
  //     throw error;
  //   }

  //   finally {
  //     this.commit((state) => {
  //       state.submitting = false;
  //     });
  //   }
  // }
}

