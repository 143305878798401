<template>
  <div class="card-content">
    <div class="hours-container">
      <template v-for="(dayData, dayCode) in daysConfig">
        <div :key="dayCode" :class="['day-container', {'is-open': value[`${dayCode}IsOpen`]}]">
          <div class="day">
            <div class="mar-t-sm is-flex align-center">
              <validated-input :name="`${dayCode}IsOpen`" :label="`${dayCode}IsOpen`" hide-label>
                <b-checkbox
                  :value="value[`${dayCode}IsOpen`]"
                  class="label mar-none pad-y-xs align-start"
                  @input="updateIsOpen(`${dayCode}IsOpen`, $event)"
                >
                  <p>{{ dayData.label }}</p>
                  <p v-if="value[`${dayCode}IsOpen`]" class="has-text-grey help has-text-weight-normal">
                    <span v-if="closesNextDay(value.hoursForm[dayCode])">(Closes Next Day)</span>
                    <span v-if="openAllDay(value.hoursForm[dayCode])">(Open All Day)</span>
                  </p>
                </b-checkbox>
              </validated-input>

              <dropdown-menu
                v-if="value[`${dayCode}IsOpen`]"
                :mobile-modal="false"
                :class="['day-actions mar-r-xs']"
              >
                <b-button slot="trigger" class="is-transparent" type="is-white" size="is-small">
                  <b-icon icon="ellipsis-v" pack="far" size="is-medium" />
                </b-button>

                <b-dropdown-item @click="applyToAllDays(dayCode)">
                  <b-icon icon="copy" size="is-small" class="mar-r-sm" pack="far" />
                  Apply to all days
                </b-dropdown-item>

                <b-dropdown-item @click="setOpenAllDay(dayCode)">
                  <b-icon icon="clock" size="is-small" class="mar-r-sm" pack="fad" />
                  Open all day
                </b-dropdown-item>
              </dropdown-menu>
            </div>
          </div>

          <div class="day-hours">
            <div v-for="({ open, close, id }, index) in value.hoursForm[dayCode]" :key="`${dayCode}-${id}`" class="hours">
              <validated-input
                :rules="{required: value[`${dayCode}IsOpen`]}"
                label-position="on-border"
                label="Open"
                :name="`${dayCode}-open-${index}`"
                class="mar-none"
              >
                <b-timepicker
                  :increment-minutes="1"
                  :default-minutes="0"
                  :value="open"
                  size="is-small"
                  inline
                  :disabled="!value[`${dayCode}IsOpen`]"
                  @input="updateHours({ value: $event, day: dayCode, index, type: 'open' })"
                />
              </validated-input>
              <validated-input
                :rules="{required: value[`${dayCode}IsOpen`]}"
                label-position="on-border"
                label="Close"
                :name="`${dayCode}-close-${index}`"
                class="mar-none"
              >
                <b-timepicker
                  :increment-minutes="1"
                  :default-minutes="0"
                  :value="close"
                  size="is-small"
                  inline
                  :disabled="!value[`${dayCode}IsOpen`]"
                  @input="updateHours({ value: $event, day: dayCode, index, type: 'close' })"
                />
              </validated-input>
              <b-button
                :class="[value.hoursForm[dayCode].length === 1 && 'is-invisible', 'mar-t-sm']"
                icon-left="minus"
                outlined
                size="is-small"
                type="is-danger is-light"
                :disabled="!value[`${dayCode}IsOpen`]"
                @click="handleDelete(dayCode, index)"
              />
            </div>
            <validation-provider
              v-slot="{ errors }"
              :name="`overlapErrors-${dayCode}`"
              :rules="{ required: invalidDays.includes(dayCode) }"
            >
              <b-checkbox :value="!invalidDays.includes(dayCode) || null" :class="['is-hidden', { 'invalid': errors.length }]" />
              <b-message
                v-if="invalidDays.includes(dayCode)"
                :key="`${dayCode}-invalid`"
                type="is-danger"
                size="is-small"
                class="is-compact has-shadow justify-self-start"
              >
                <p class="is-flex align-center">
                  <b-icon custom-size="fa-lg" icon="exclamation-circle" type="is-danger" class="mar-r-xs" />
                  {{ overlapErrorMessage }}
                </p>
              </b-message>
            </validation-provider>
          </div>

          <div class="add-hours">
            <b-button
              :disabled="!value[`${dayCode}IsOpen`]"
              class="mar-t-sm"
              icon-left="plus"
              size="is-small"
              type="is-primary is-light"
              @click="addHoursRow(dayCode)"
            />
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
  import moment from 'moment-timezone';

  export default {
    name: 'StoreStandardHoursInputs',

    props: {
      value: {
        type: Object,
        required: true
      },

      invalidDays: {
        type: Array,
        required: true
      }
    },

    data() {
      return {
        overlapErrorMessage: 'Open & close hours cannot overlap each other within the same day',
        daysConfig: {
          sun: { label: 'Sunday' },
          mon: { label: 'Monday' },
          tue: { label: 'Tuesday' },
          wed: { label: 'Wednesday' },
          thu: { label: 'Thursday' },
          fri: { label: 'Friday' },
          sat: { label: 'Saturday' }
        }
      };
    },

    methods: {
      updateHours({ value, day, index, type }) {
        const copy = this.$clone(this.value.hoursForm);
        copy[day][index][type] = value;
        this.$emit('input', { ...this.value, hoursForm: copy });
      },

      updateIsOpen(day, isOpen) {
        const copy = this.$clone(this.value);
        copy[day] = isOpen;
        this.$emit('input', copy);
      },

      addHoursRow(day) {
        const copy = this.$clone(this.value.hoursForm);
        copy[day].push({ open: null, close: null, id: `temp-${Date.now()}` });
        this.$emit('input', { ...this.value, hoursForm: copy });
      },

      handleDelete(day, index) {
        const copy = this.$clone(this.value.hoursForm);
        copy[day].splice(index, 1);
        this.$emit('input', { ...this.value, hoursForm: copy });
      },

      closesNextDay(dayHours) {
        return dayHours.some(({ open, close }) => open && close && moment(open).isAfter(moment(close), 'minutes'));
      },

      openAllDay(dayHours) {
        return dayHours.length === 1
          && !!(dayHours[0].open && dayHours[0].close)
          && moment(dayHours[0].open).isSame(moment(dayHours[0].close), 'minutes');
      },

      applyToAllDays(sourceDay) {
        const days = Object.keys(this.daysConfig);
        const copy = this.$clone(this.value);

        days.forEach((day) => {
          if (day !== sourceDay) {
            copy.hoursForm[day] = this.$clone(copy.hoursForm[sourceDay]);
            copy[`${day}IsOpen`] = true;
          }
        });

        this.$emit('input', copy);
      },

      setOpenAllDay(day) {
        const hoursForm = this.$clone(this.value.hoursForm);
        const midnight = new Date();
        midnight.setHours(0, 0, 0);

        hoursForm[day] = [{
          open: midnight,
          close: midnight,
          id: `temp-${Date.now()}`
        }];

        this.$emit('input', { ...this.value, hoursForm });
      }
    }
  };
</script>

<style lang='sass' scoped>
  .hours-container
    border: 1px solid $grey-lighter
    border-radius: $radius
    overflow: hidden

  .day-container
    display: grid
    grid-template-columns: 11rem 1fr auto
    gap: $size-large
    transition: 333ms

    &:not(:last-child)
      border-bottom: 1px solid $grey-lighter

    > *
      padding-top: $size-normal
      padding-bottom: $size-normal
      &:first-child
        padding-left: $size-normal
      &:last-child
        padding-right: $size-normal

  .day
    position: relative
    display: flex
    flex-direction: column
    justify-content: flex-start
    background-color: $white-bis
    border-right: 1px solid $grey-lighter

  .day-hours
    display: grid
    gap: $size-medium
    transition: 333ms
    opacity: 0.5

    .is-open &
      opacity: 1

  .day-actions
    position: absolute
    top: 42px
    right: 0
    transform: translateY(-40%)

  .hours
    display: grid
    grid-template-columns: repeat(2, minmax(10rem, min-content)) min-content
    gap: $size-medium
    align-items: start

    ::v-deep
      .label,
      .field
        margin: 0 !important
</style>
