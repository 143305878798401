var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('panel',{attrs:{"title":"In-App Messages","no-padding":""}},[_c('searchable-table',{attrs:{"table-data":_vm.inAppMessages,"search":{
      placeholder: 'Search by In-App Message name',
      keys: ['name']
    },"filters":[
      {
        placeholder: 'Type',
        key: 'inAppMessageTypeId',
        options: _vm.inAppMessageTypes.map(function (type) { return ({
          name: type.name,
          value: type.id
        }); })
      },
      {
        placeholder: 'Status',
        key: 'status',
        multiple: true,
        default: [_vm.statuses.LIVE, _vm.statuses.SCHEDULED, _vm.statuses.DRAFT],
        options: Object.values(_vm.statuses).map(function (status) { return ({
          name: _vm.capitalize(status),
          value: status
        }); })
      } ]},scopedSlots:_vm._u([{key:"optional-actions",fn:function(){return [_c('b-button',{attrs:{"type":"is-primary","icon-left":"plus"},on:{"click":function($event){return _vm.openInAppMessageModal()}}},[_vm._v(" In-App Message ")])]},proxy:true},{key:"default",fn:function(ref){
    var filteredData = ref.filteredData;
return [_c('b-table',{staticClass:"is-middle-aligned",attrs:{"data":_vm.isFetching ? [] : filteredData,"mobile-cards":false,"custom-detail-row":"","scrollable":"","detail-key":"publicId","data-test-id":"in-app-messages-table","default-sort":['messageType', 'desc']}},[_c('template',{slot:"empty"},[_c('empty-table-loader',{attrs:{"message":"No In-App Messages Found...","loading":_vm.isFetching}})],1),_c('b-table-column',{attrs:{"sortable":"","field":"title","label":"Name","cell-class":"no-wrap-text"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
    var row = ref.row;
return [_c('span',{staticClass:"link",on:{"click":function($event){return _vm.openInAppMessageModal(row)}}},[_vm._v(" "+_vm._s(row.title)+" ")])]}}],null,true)}),_c('b-table-column',{attrs:{"sortable":"","field":"bodyContent","label":"Description"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
    var row = ref.row;
return [_vm._v(" "+_vm._s(row.bodyContent)+" ")]}}],null,true)}),_c('b-table-column',{attrs:{"sortable":"","field":"messageType","label":"Message Type","centered":"","header-class":"no-wrap-text"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
    var row = ref.row;
return [_vm._v(" "+_vm._s(row.messageTypeName)+" ")]}}],null,true)}),_c('b-table-column',{attrs:{"sortable":"","field":"status","label":"Status","centered":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
    var row = ref.row;
return [_c('b-tag',{staticClass:"is-outlined is-light",attrs:{"type":_vm.tagTypes[row.status]}},[_vm._v(" "+_vm._s(_vm.$changeCase.capitalCase(row.status))+" ")])]}}],null,true)}),_c('b-table-column',{attrs:{"centered":"","cell-class":"actions","label":"Actions","width":"1","field":"Actions"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
    var row = ref.row;
return [_c('dropdown-menu',{attrs:{"position":"bottom-end","mobile-modal":false}},[_c('b-button',{staticClass:"is-transparent",attrs:{"slot":"trigger","type":"is-white"},slot:"trigger"},[_c('b-icon',{attrs:{"icon":"ellipsis-v","pack":"far","size":"is-small"}})],1),_c('b-dropdown-item',{on:{"click":function($event){return _vm.openInAppMessageModal(row)}}},[_c('b-icon',{staticClass:"mar-r-sm",attrs:{"icon":row.status === _vm.statuses.EXPIRED ? 'eye' : 'pencil',"size":"is-small"}}),_vm._v(" "+_vm._s(row.status === _vm.statuses.EXPIRED ? 'View' : 'Edit')+" Message ")],1),(row.status !== _vm.statuses.EXPIRED)?_c('b-dropdown-item',{on:{"click":function($event){return _vm.confirmTogglePublish(row)}}},[_c('b-icon',{staticClass:"mar-r-sm",attrs:{"icon":row.status === _vm.statuses.DRAFT ? 'message' : 'message-slash',"size":"is-small"}}),_vm._v(" "+_vm._s(row.status === _vm.statuses.DRAFT ? 'Publish' : 'Unpublish')+" Message ")],1):_vm._e(),_c('hr',{staticClass:"dropdown-divider"}),_c('b-dropdown-item',{staticClass:"is-danger",on:{"click":function($event){return _vm.confirmDelete(row)}}},[_c('b-icon',{staticClass:"mar-r-sm",attrs:{"type":"is-danger","icon":"trash-alt","size":"is-small"}}),_vm._v(" "+_vm._s(row.status === _vm.statuses.SCHEDULED ? 'Cancel' : 'Delete')+" Message ")],1)],1)]}}],null,true)})],2)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }