<template>
  <validated-form
    :ref="formRef"
    :form-id="formRef"
  >
    <div>
      <div class="mar-b-sm">
        <p class="subtitle is-5 mar-none">
          POS Mappings
        </p>
        <p v-if="$_selectedMerchant.hasUnstructuredMenu" class="sub-label mar-none">
          Map this item to a POS resource
        </p>
      </div>

      <b-message v-if="!$_selectedMerchant.hasUnstructuredMenu && !selectedPosItems.length" type="is-warning" size="is-small" class="is-compact has-shadow">
        No pos mappings are set for this item
      </b-message>

      <pos-item-search
        v-if="$_selectedMerchant.hasUnstructuredMenu"
        :selected-pos-item-ids="selectedPosItemIds"
        @select="selectPosItem($event);"
      />

      <validation-provider
        v-slot="{ errors }"
        slim
        name="selectedPosItems"
        :custom-messages="{ required: 'Sub mappings require a selected primary POS mapping' }"
        :rules="{ required: !!selectedSubMappings.length }"
      >
        <b-checkbox :value="selectedPosItems" class="is-hidden" />
        <p v-if="errors.length" class="is-size-7 has-text-danger mar-t-xs">
          <b-icon icon="exclamation-circle" type="is-danger" /> {{ errors[0] }}
        </p>
      </validation-provider>

      <template v-if="$_selectedMerchant.hasUnstructuredMenu">
        <b-tag
          v-for="({ id, posItemId, name, isOrphan }, i) in selectedPosItems"
          :key="`posItem-${id}-${i}`"
          attached
          size="is-medium"
          :type="isOrphan ? 'is-danger' : 'is-primary'"
          :close-type="isOrphan ? 'is-danger' : 'is-primary'"
          class="mar-t"
          :closable="$_selectedMerchant.hasUnstructuredMenu"
          @close="removePosItem(posItemId)"
        >
          <div v-if="!isOrphan" class="mar-r-xs">
            <span class="has-text-weight-bold">{{ name }} - </span>
            <span class="is-monospaced">{{ posItemId }}</span>
          </div>
          <p v-else class="pad-l-xs mar-r-xs">
            <b-icon
              v-tippy="{ content: 'POS mapping does not exist in POS Menu', placement: 'top-start' }"
              size="is-small"
              icon="exclamation-triangle"
              pack="fas"
              type="is-white"
              class="mar-r"
            />
            <span class="is-monospaced">{{ posItemId }}</span>
          </p>
        </b-tag>
      </template>

      <template v-else>
        <ul>
          <li
            v-for="({ id, posItemId, name, isOrphan, store }, i) in sortedByLocation(selectedPosItems)"
            :key="`posItem-${id}-${i}`"
            :type="isOrphan ? 'is-danger' : 'is-primary'"
            :close-type="isOrphan ? 'is-danger' : 'is-primary'"
            :class="['mar-t', { 'has-text-danger': isOrphan }]"
          >
            <div v-if="!isOrphan" class="mar-r-xs">
              <span class="has-text-weight-bold">{{ name }} - </span>
              <span class="is-monospaced">{{ posItemId }}</span>
              <p v-if="store" class="is-size-6">{{ store.description }}</p>
            </div>
            <p v-else class="pad-l-xs mar-r-xs">
              <b-icon
                v-tippy="{ content: 'POS mapping does not exist in POS Menu', placement: 'top-start' }"
                size="is-small"
                icon="exclamation-triangle"
                pack="fas"
                type="is-white"
                class="mar-r"
              />
              <span class="is-monospaced">{{ posItemId }}</span>
            </p>
          </li>
        </ul>
      </template>

      <template v-if="$_selectedMerchant.hasUnstructuredMenu">
        <hr>

        <div class="is-flex justify-between mar-b-sm">
          <div>
            <p class="subtitle is-5 mar-none">
              Sub Mappings <span v-if="$_selectedMerchant.hasUnstructuredMenu" class="is-size-7">(Optional)</span>
            </p>
            <p v-if="$_selectedMerchant.hasUnstructuredMenu" class="sub-label mar-none">
              Select up to 3 additional POS resources to map this item to
            </p>
          </div>
          <b-button
            v-if="selectedSubMappings.length && $_selectedMerchant.hasUnstructuredMenu"
            v-tippy="{content: 'Remove all sub mappings', placement: 'left'}"
            icon-left="trash-alt"
            class="is-danger is-light"
            @click="selectedSubMappings = []; emitInput()"
          />
        </div>

        <b-message v-if="!selectedSubMappings.length" type="is-warning" size="is-small" class="is-compact has-shadow">
          No sub mappings are set for this item
        </b-message>

        <pos-item-search
          :selected-pos-item-ids="selectedPosItemIds"
          :disabled="selectedSubMappings.length === 3"
          @select="selectSubMapping"
        />

        <draggable
          v-if="selectedSubMappings.length"
          v-model="selectedSubMappings"
          tag="ul"
          class="is-flex-column gap-xs mar-t"
          v-bind="{
            animation: '200',
            ghostClass: 'ghost',
            handle: '.drag-handle'
          }"
          :force-fallback="true"
          @change="emitInput"
        >
          <li
            v-for="(selectedSubMapping, i) in selectedSubMappings"
            :key="selectedSubMapping.posItemId"
            class="is-flex align-center gap-sm"
          >
            <b-icon
              icon="grip-vertical"
              size="is-small"
              class="drag-handle"
            />
            <b-taglist attached>
              <b-tag size="is-medium" class="has-border has-border-grey-lighter">
                <span class="is-uppercase is-size-7 has-text-weight-bold">
                  Level {{ i + 1 }}
                </span>
              </b-tag>
              <b-tag
                class="has-border-y has-border-grey-lighter"
                attached
                size="is-medium"
                type="is-grey is-light"
              >
                <span v-if="selectedSubMapping.name" class="has-text-weight-bold">{{ selectedSubMapping.name }} - </span>
                <span class="is-monospaced">{{ selectedSubMapping.posItemId }}</span>
              </b-tag>
              <b-tag
                size="is-medium"
                type="is-grey is-light"
                class="is-clickable has-border-y has-border-right has-border-grey-lighter has-hover-background-grey-lighter"
                @click.native="removeSubMapping(i)"
              >
                <b-icon icon="times" pack="fal" />
              </b-tag>
            </b-taglist>
          </li>
        </draggable>
      </template>
    </div>
  </validated-form>
</template>

<script>
  import draggable from 'vuedraggable';

  import getChangedResources from '@/helpers/get-changed-resources';

  import merchantMixin from '@/mixins/merchant';
  import multiFormChildProvider from '@/mixins/multiFormMixin/multiFormChildProvider';

  import PosMenuItem from '@/store/classes/PosMenuItem';

  import { emitNativeInput } from '@/services/emit-input';

  import Store from '@/store/classes/Store';


  export default {
    name: 'ItemPosMapping',

    components: { draggable },

    mixins: [multiFormChildProvider, merchantMixin],

    props: {
      item: {
        type: Object,
        required: true
      },

      formRef: {
        type: String,
        required: true
      }
    },

    data: () => ({
      selectedPosItems: [],
      selectedSubMappings: []
    }),

    computed: {
      selectedPosItemIds() {
        return [
          ...this.selectedPosItems.map(posItem => posItem?.posItemId),
          ...this.selectedSubMappings.map(subMapping => subMapping.posItemId)
        ].filter(Boolean);
      },

      stores() {
        return Store.all();
      }
    },

    watch: {
      'item.posMenuItemMappings': {
        immediate: true,
        handler: 'handleExistingMappings'
      }
    },

    methods: {
      emitInput() {
        const formElement = this.$refs.itemPosMappingForm.$refs.formElement;
        emitNativeInput(formElement);
      },

      sortedByLocation(posMappings) {
        return this.$clone(posMappings).sort((a, b) => (a.store?.description < b.store?.description ? -1 : 1));
      },

      handleExistingMappings(newVal, oldVal) {
        // The submappings should be the same for all POS menu item mappings.
        // Given that, we can use the submappings from the first mapping to set the data.
        if (newVal?.length && !oldVal) {
          // NOTE: if the mapping is orphaned, it will not have a posMenuItem, so create a temporary one with posItemId and isOrphan set, in order to render the correct template
          this.selectedPosItems = newVal
            .map(itemMapping => (itemMapping.posMenuItem
              ? {
                ...itemMapping.posMenuItem,
                isOrphan: false,
                store: this.getStore(itemMapping.posMenuItem?.storeId)
              }
              : { posItemId: itemMapping.posMenuItemId, isOrphan: true }
            ));
          this.selectedSubMappings = [
            newVal[0].posModifier1Id && {
              posItemId: newVal[0].posModifier1Id,
              name: newVal[0].posModifier1Name
            },
            newVal[0].posModifier2Id && {
              posItemId: newVal[0].posModifier2Id,
              name: newVal[0].posModifier2Name
            },
            newVal[0].posModifier3Id && {
              posItemId: newVal[0].posModifier3Id,
              name: newVal[0].posModifier3Name
            }
          ].filter(Boolean);
        }
      },

      getStore(storeId) {
        if (!storeId) return null;
        return this.stores.find(store => store.storeId === storeId);
      },

      selectPosItem(posMenuItem) {
        this.emitInput();
        this.selectedPosItems.push(posMenuItem);
      },

      removePosItem(posItemId) {
        this.emitInput();
        this.selectedPosItems = this.selectedPosItems.filter(posItem => posItem.posItemId !== posItemId);
      },

      selectSubMapping({ posItemId, name }) {
        this.emitInput();
        this.selectedSubMappings.push({ posItemId, name });
      },

      removeSubMapping(index) {
        this.emitInput();
        this.selectedSubMappings.splice(index, 1);
      },

      findPosMenuItemMapping(posMenuItemId) {
        return this.item.posMenuItemMappings.find(itemMapping => itemMapping.posMenuItemId === posMenuItemId);
      },

      async handleSubmit(itemId = this.item.id) {
        if (!this.$_selectedMerchant?.hasUnstructuredMenu) return itemId;

        try {
          const oldPosItemIds = this.item.posMenuItemMappings.map(itemMapping => itemMapping.posMenuItemId);
          const newPosItemIds = this.selectedPosItems.map(itemMapping => itemMapping.posItemId);
          const allPosItemIds = Array.from(new Set([...oldPosItemIds, ...newPosItemIds]));

          const { added, removed } = getChangedResources({ newArray: newPosItemIds, oldArray: oldPosItemIds });

          const paddedSubMappingArray = [...this.selectedSubMappings, ...Array(3 - this.selectedSubMappings.length)];

          const posModifierIds = paddedSubMappingArray.reduce((obj, item, index) => {
            obj[`posModifier${index + 1}Id`] = item?.posItemId || null;
            return obj;
          }, {});

          const posItemPromises = allPosItemIds.map((posItemId) => {
            if (added.includes(posItemId)) {
              const posItem = this.selectedPosItems.find(selectedPosItem => selectedPosItem.posItemId === posItemId);
              return PosMenuItem.addPosMapping({
                itemId,
                posItemId: posItem.id,
                ...posModifierIds
              });
            }
            else {
              const posMenuItemMapping = this.findPosMenuItemMapping(posItemId);
              if (removed.includes(posItemId)) {
                return PosMenuItem.deletePosMapping({ itemId, id: posMenuItemMapping.id });
              }
              else {
                return PosMenuItem.updatePosMapping({ id: posMenuItemMapping.id, ...posModifierIds });
              }
            }
          });

          await Promise.all(posItemPromises);

          return itemId;
        }

        catch (error) {
          this.$_onRequestError({
            toastOptions: { message: 'An error occurred while updating your POS Mappings' },
            error
          });
        }
      }
    }
  };
</script>
