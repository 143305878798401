import http from '@/services/http';
import { camelCase } from 'change-case';

export async function provisionUberEatsStores(storeIds) {
  try {
    await http.put('stores/provision_with_uber_eats', { storeIds });
  }
  catch (error) {
    throw error;
  }
}

export async function fetchMerchantOptionFields() {
  try {
    const excludedFields = ['MerchantId', 'LogoUrl', 'AvatarUrl', 'OGImageUrl'];
    const params = [
      'for_model=MerchantOption',
      ...excludedFields.map(field => `exclude[]=${field}`)
    ].join('&');

    const { data } = await http.get(`input_types?${params}`);

    return data.inputTypes
      .map(inputType => ({ ...inputType, columnName: camelCase(inputType.columnName) }))
      .sort((a, b) => {
        if (a.inputType === b.inputType) return a.columnName < b.columnName ? -1 : 1;
        else return a.inputType > b.inputType ? -1 : 1;
      });
  }
  catch (error) {
    throw error;
  }
}

export async function fetchAuditLogs(merchantId, params) {
  try {
    const { data } = await http.get(`merchants/${merchantId}/logs`, { params });
    return data.versions;
  }
  catch (error) {
    throw error;
  }
}


export default {
  provisionUberEatsStores,
  fetchMerchantOptionFields,
  fetchAuditLogs
};
