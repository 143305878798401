<template>
  <panel title="Designs" collapsible start-open :loading="isFetching || isSorting">
    <template #buttons>
      <b-button
        rounded
        class="is-bold"
        size="is-medium"
        type="is-primary"
        icon-left="add"
        :loading="isSubmitting"
        @click="addNewGiftCardDesign()"
      >
        New Design
      </b-button>
    </template>
    <section class="dist-y pad-t-none">
      <div class="pad has-background-white-bis has-border has-border-grey-lightest has-radius" data-test-id="active-designs">
        <h2 class="subtitle is-4 mar-none">Active</h2>
        <draggable
          v-if="sortedActiveGiftCardDesigns.length"
          :list="sortedActiveGiftCardDesigns"
          tag="ul"
          animation="200"
          ghost-class="ghost"
          handle=".drag-handle"
          class="is-grid justify-center gap mar-t card-design-container"
          group="gift-card-designs"
          :force-fallback="true"
          @change="handleGiftCardDesignSortOrderChange"
        >
          <gift-card-design-list-item
            v-for="activeDesign in sortedActiveGiftCardDesigns"
            :key="`activeDesign${activeDesign.id}`"
            :design="activeDesign"
            @open-gift-card-design-modal="openGiftCardDesignModal"
          />
        </draggable>
      </div>
      <div class="pad has-background-white-bis has-border has-border-grey-lightest has-radius" data-test-id="inactive-designs">
        <h2 class="subtitle is-4 mar-none">Inactive</h2>
        <draggable
          v-if="sortedInactiveGiftCardDesigns.length"
          :list="sortedInactiveGiftCardDesigns"
          tag="ul"
          animation="200"
          ghost-class="ghost"
          handle=".drag-handle"
          group="gift-card-designs"
          class="is-grid justify-center gap mar-t card-design-container"
          :force-fallback="true"
          :sort="false"
          @change="handleGiftCardDesignSortOrderChange"
        >
          <gift-card-design-list-item
            v-for="inactiveDesign in sortedInactiveGiftCardDesigns"
            :key="`inactiveDesign${inactiveDesign.id}`"
            :design="inactiveDesign"
            @open-gift-card-design-modal="openGiftCardDesignModal"
          />
        </draggable>
      </div>
    </section>
  </panel>
</template>

<script>
  import draggable from 'vuedraggable';

  import MerchantGiftCardDesign from '@/store/classes/MerchantGiftCardDesign';

  import AddEditGiftCardDesignModal from './add-edit-gift-card-design-modal.vue';
  import GiftCardDesignListItem from './gift-card-design-list-item.vue';


  export default {
    name: 'GiftCardsDesignsForm',

    components: { draggable, GiftCardDesignListItem },

    props: {
      merchant: {
        type: Object,
        default: null
      }
    },

    data() {
      return {
        sortedActiveGiftCardDesigns: [],
        sortedInactiveGiftCardDesigns: []
      };
    },

    computed: {
      activeGiftCardDesigns() {
        return MerchantGiftCardDesign.query().where('isActive', true).orderBy('sortOrder').get();
      },

      inactiveGiftCardDesigns() {
        return MerchantGiftCardDesign.query().where('isActive', false).orderBy('sortOrder').get();
      },

      isFetching() {
        return MerchantGiftCardDesign.$state().fetching;
      },

      isSorting() {
        return MerchantGiftCardDesign.$state().sorting;
      },

      isSubmitting() {
        return MerchantGiftCardDesign.$state().submitting;
      },

      lastActiveGiftCardDesign() {
        return this.sortedActiveGiftCardDesigns[this.sortedActiveGiftCardDesigns.length - 1];
      }
    },

    watch: {
      activeGiftCardDesigns: 'setLocalGiftCardDesigns',
      inactiveGiftCardDesigns: 'setLocalGiftCardDesigns'
    },

    created() {
      this.onCreated();
    },

    methods: {
      async onCreated() {
        await this.fetchGiftCardDesigns();
      },

      async fetchGiftCardDesigns() {
        try {
          await MerchantGiftCardDesign.fetchMerchantGiftCardDesigns(this.merchant.id);
        }
        catch (error) {
          this.$_onRequestError({
            toastOptions: { message: 'There was an error getting your Gift Card Designs' },
            error
          });
        }
      },

      setLocalGiftCardDesigns() {
        this.sortedActiveGiftCardDesigns = this.activeGiftCardDesigns;
        this.sortedInactiveGiftCardDesigns = this.inactiveGiftCardDesigns;
      },

      addNewGiftCardDesign() {
        const isFirstGiftCardDesign = !MerchantGiftCardDesign.query().all().length;
        const newGiftCard = new MerchantGiftCardDesign({
          merchantId: this.merchant.id,
          isDefault: isFirstGiftCardDesign
        });
        this.openGiftCardDesignModal(newGiftCard);
      },

      openGiftCardDesignModal(giftCardDesign) {
        this.$buefy.modal.open({
          parent: this,
          component: AddEditGiftCardDesignModal,
          hasModalCard: true,
          trapFocus: true,
          canCancel: false,
          props: { giftCardDesign }
        });
      },

      async handleGiftCardDesignSortOrderChange({ moved, added }) {
        if (moved || added) {
          // 'moved' and 'added' never both exist during the same call
          // 'moved' is populated when an item stays in the same list; 'added' when it changes lists
          const isChangingActiveStatus = !!added;
          const designBeingSorted = moved || added;
          const originIsActive = designBeingSorted.element.isActive;

          const merchantGiftCardDesigns = [...this.sortedActiveGiftCardDesigns, ...this.sortedInactiveGiftCardDesigns];

          const sortedMerchantGiftCardDesigns = merchantGiftCardDesigns.map((design, i) => ({
            ...design,
            sortOrder: i + 1
          }));

          await this.updateMerchantGiftCardDesignsSortOrder({ merchantId: this.merchant.id, merchantGiftCardDesigns: sortedMerchantGiftCardDesigns });

          if (isChangingActiveStatus) {
            // Edit the design that's in memory so Vue updates the DOM properly
            designBeingSorted.element.isActive = !designBeingSorted.element.isActive;
            if (designBeingSorted.element.isDefault && !designBeingSorted.element.isActive) {
              designBeingSorted.element.isDefault = false;
              const firstActiveDesign = merchantGiftCardDesigns[0];
              firstActiveDesign.isDefault = true;

              await this.updateMerchantGiftCardDesign(firstActiveDesign);
            }

            await this.updateMerchantGiftCardDesign({ ...designBeingSorted.element });
          }
          let sortMessage;
          if (!isChangingActiveStatus) {
            sortMessage = `moved to position ${designBeingSorted.newIndex + 1}`;
          }
          else if (originIsActive) {
            sortMessage = 'successfully deactivated';
          }
          else {
            sortMessage = `successfully activated in position ${designBeingSorted.newIndex + 1}`;
          }

          this.$_onRequestSuccess({
            toastOptions: {
              message: `<b>${designBeingSorted.element.name}</b> has been ${sortMessage}`
            }
          });
        }
      },

      async updateMerchantGiftCardDesignsSortOrder(giftCardDesign) {
        try {
          await MerchantGiftCardDesign.updateSortOrder(giftCardDesign);
        }
        catch (error) {
          this.$_onRequestError({
            toastOptions: { message: `There was an error updating design <b>${giftCardDesign.name}</b>` },
            error
          });
        }
      },

      async updateMerchantGiftCardDesign(giftCardDesign) {
        try {
          await MerchantGiftCardDesign.updateMerchantGiftCardDesign(giftCardDesign);
        }
        catch (error) {
          this.$_onRequestError({
            toastOptions: { message: 'There was an error updating the sort order of your gift card designs' },
            error
          });
        }
      }
    }
  };
</script>

<style scoped lang="sass">
  .card-design-container
    grid-template-columns: repeat(auto-fill, minmax(277px, 1fr))

</style>
