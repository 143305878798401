<template>
  <modal-card
    title="Manage Modifier Group Associations"
    :subtitle="`${menuItem.displayName}`"
    modal-card-body-class="pad-none"
    class="is-full-height"
  >
    <div class="pad-x-md pad-t-md has-border-bottom has-border-grey-lighter is-flex gap-sm">
      <validated-text-input
        v-model="query"
        type="text"
        :icon-right-clickable="!!query"
        :icon-right="query ? 'times-circle' : ''"
        placeholder="Search by Modifier Group Name"
        icon="search"
        class="flex-grow mar-b"
        label-position="on-border"
        label="Search"
        name="query-input"
        expanded
        output-string
        @icon-right-click="query = ''"
        @keydown.native.esc="query = ''"
      />
    </div>

    <b-table
      :data="isFetching ? [] : modifierGroups"
      class="is-middle-aligned"
      :mobile-cards="false"
      scrollable
      backend-pagination
      paginated
      :total="total"
      :current-page="page"
      :per-page="25"
      data-test-id="modifier-groups-table"
      :default-sort="['name', 'asc']"
      @page-change="handlePageChange"
    >
      <template #empty>
        <empty-table-loader
          message="No Modifier Groups Found..."
          :loading="isFetching"
        />
      </template>

      <b-table-column
        v-slot="{ row }"
        field="name"
        label="Modifier Group"
        width="600"
      >
        {{ row.displayName | capitalize }}
      </b-table-column>

      <b-table-column
        v-slot="{ row }"
        centered
        field="modifiersCount"
        label="Modifiers"
      >
        <span>
          {{ row.modifiersCount }}
        </span>
      </b-table-column>

      <b-table-column
        v-slot="{ row }"
        centered
        field="sharedMenuItemCount"
        label="Items"
      >
        <span>
          {{ row.sharedMenuItemCount }}
        </span>
      </b-table-column>
      <b-table-column
        v-slot="{ row }"
        centered
        field="selected"
        label="Select"
      >
        <b-checkbox
          :key="row.id"
          class="no-label"
          :value="selectedModifierGroupIds.includes(row.id)"
          @input="handleSelectModifierGroup($event, row)"
        />
      </b-table-column>
    </b-table>

    <!-- Add list of currently selected modifier groups so user knows what they've chosen while navigating through paginated list -->

    <template #footer>
      <div class="mar-r-auto flex-grow dist-x-xs dist-y-xs">
        <b-tag
          v-for="modifierGroup in selectedModifierGroups"
          :key="`modifier-group-${modifierGroup.id}`"
          type="is-primary"
          closable
          @close="handleSelectModifierGroup(false, modifierGroup)"
        >
          {{ modifierGroup.displayName }}
        </b-tag>
      </div>
      <div class="buttons all-bold no-flex-shrink">
        <b-button
          rounded
          @click="$parent.close()"
        >
          Cancel
        </b-button>
        <b-button
          rounded
          type="is-primary"
          :loading="submitting"
          @click="addModifierGroupsToItem"
        >
          Update
        </b-button>
      </div>
    </template>
  </modal-card>
</template>

<script>
  import debounce from 'lodash.debounce';

  import getChangedResources from '@/helpers/get-changed-resources';

  import MenuItemModifierGroupItem from '@/store/classes/MenuItemModifierGroupItem';
  import ModifierGroup from '@/store/classes/ModifierGroup';

  export default {
    name: 'AddModifierGroupsToItemModal',

    props: {
      menuItem: {
        type: Object,
        required: true
      },

      menuItemModifierId: {
        type: Number,
        default: null
      }
    },

    data() {
      return {
        selectedModifierGroups: [],
        page: 1,
        query: '',
        total: 0,
        modifierGroups: []
      };
    },

    computed: {
      submitting() {
        return MenuItemModifierGroupItem.$state().submitting;
      },

      isFetching() {
        return ModifierGroup.$state().fetching || ModifierGroup.$state().fetchingModifierGroupId;
      },

      selectedModifierGroupIds() {
        return this.selectedModifierGroups.map(modifierGroup => modifierGroup.id);
      }
    },

    watch: {
      query: debounce(function () { // eslint-disable-line func-names
        this.fetchModifierGroups();
      }, 666)
    },

    created() {
      this.onCreated();
    },

    methods: {
      async onCreated() {
        this.selectedModifierGroups = this.menuItem.menuItemModifierGroupItems.map(menuItemModifierGroupItem => menuItemModifierGroupItem.modifierGroup);
        await this.fetchModifierGroups();
      },

      handlePageChange(page) {
        this.page = page;
        this.fetchModifierGroups();
      },

      async fetchModifierGroups() {
        try {
          const params = { page: this.page };
          if (this.query) {
            params.search_parameter = 'display_name';
            params.search_term = `${encodeURIComponent(this.query)}`;
          }
          const { menuItemModifierGroups, meta } = await ModifierGroup.fetchModifierGroups(params);

          this.modifierGroups = menuItemModifierGroups;
          this.total = meta.count;
        }
        catch (error) {
          this.$_onRequestError({
            error,
            toastOptions: {
              message: 'There was an error fetching modifier groups'
            }
          });
        }
      },

      handleSelectModifierGroup(adding, modifierGroup) {
        if (adding) {
          this.selectedModifierGroups.push(modifierGroup);
        }
        else {
          this.selectedModifierGroups = this.selectedModifierGroups.filter(selectedModifierGroup => selectedModifierGroup.id !== modifierGroup.id);
        }
      },

      async addModifierGroupsToItem() {
        const { added, removed } = getChangedResources({
          oldArray: this.menuItem.menuItemModifierGroupItems.map(menuItemModifierGroupItem => menuItemModifierGroupItem.modifierGroup.id),
          newArray: this.selectedModifierGroupIds
        });

        const promises = [];

        if (added.length) promises.push(this.addModifierGroupToItems(added));
        if (removed.length) promises.push(this.removeModifierGroupFromItems(removed));

        try {
          let message = '';
          if (added.length && removed.length) {
            message = `Successfully added ${added.length} modifier group${added.length > 1 ? 's' : ''} and removed ${removed.length} modifier group${removed.length > 1 ? 's' : ''} from`;
          }
          else if (added.length) {
            message = `Successfully added ${added.length} modifier group${added.length > 1 ? 's' : ''} to`;
          }
          else {
            message = `Successfully removed ${removed.length} modifier group${removed.length > 1 ? 's' : ''} from`;
          }

          await Promise.all(promises);

          this.$_onRequestSuccess({
            toastOptions: { message: `${message} ${this.menuItem.displayName}` },
            options: { closeParent: true }
          });
          this.$emit('items-updated');
        }
        catch (error) {
          this.$_onRequestError({
            error,
            toastOptions: {
              message: error
            }
          });
        }
      },

      async addModifierGroupToItems(newModifierGroupIds) {
        try {
          const menuItemModifierGroupItems = newModifierGroupIds.map(modifierGroupId => ({
            menuItemId: this.menuItem.id,
            menuItemModifierGroupId: modifierGroupId,
            menuItemModifierId: this.menuItemModifierId
          }));
          return MenuItemModifierGroupItem.createMenuItemModifierGroupItems(menuItemModifierGroupItems);
        }
        catch (error) {
          throw new Error(`There was an error adding items to your ${this.modifierGroup.displayName} Menu`);
        }
      },

      async removeModifierGroupFromItems(removedItemIds) {
        const menuItemModifierGroupItemIdsToDelete = this.menuItem.menuItemModifierGroupItems
          .filter(menuItemModifierGroupItem => removedItemIds.includes(menuItemModifierGroupItem.modifierGroup.id))
          .map(menuItemModifierGroupItem => menuItemModifierGroupItem.id);

        try {
          return MenuItemModifierGroupItem.deleteMenuItemModifierGroupItems(menuItemModifierGroupItemIdsToDelete);
        }

        catch (error) {
          throw new Error(`There was an error removing the ${this.modifierGroup.displayName} modifier group from ${menuItemModifierGroupItemIdsToDelete.length} items`);
        }
      }
    }
  };
</script>
