<template>
  <div class="card">
    <div class="card-content">
      <template v-if="mode === 'create'">
        <toggle-buttons
          v-model="form.isTemplate"
          :label-left="`Use Our ${formTypeDisplay} Template`"
          label-right="Start From Scratch"
          sub-label-right="BLANK TEMPLATE"
        >
          <template #subLabelLeft>
            <p v-if="templateQuestions" class="is-size-7" :class="form.isTemplate ? 'has-text-primary-dark' : 'has-text-grey'">
              {{ templateQuestions.length }} QUESTIONS
              <span class="link is-size-7 has-text-weight-semibold mar-l-xs" @click.prevent.stop="showTemplatePreview">PREVIEW</span>
            </p>
          </template>
        </toggle-buttons>
        <hr>
      </template>

      <validated-text-input
        v-model="form.name"
        name="name"
        label="Survey Name"
        sub-label="The internal name of your survey"
        rules="required"
        type="text"
      />

      <template v-if="!isMobileTippingSurvey">
        <hr>

        <validated-text-input
          v-model="form.title"
          name="title"
          label="Title"
          sub-label="Title that the customer sees"
          rules="required"
          type="text"
        />

        <validated-text-input
          v-model="form.description"
          :has-icon="false"
          label="Description"
          name="description"
          type="textarea"
          maxlength="250"
          rows="6"
        />
      </template>
    </div>
  </div>
</template>

<script>
  import surveyFormTypes from '@/constants/surveyFormTypes';
  import surveyPreview from './survey-preview.vue';

  export default {
    name: 'SurveyDetailsInputs',

    props: {
      handleFormChange: {
        type: Function,
        required: true
      },

      formType: {
        type: String,
        required: true
      },

      mode: {
        type: String,
        default: 'create',
        validator(value) {
          return ['create', 'read', 'update'].includes(value);
        }
      },

      isTemplate: {
        type: Boolean,
        default: false
      },

      name: {
        type: String,
        default: null
      },

      title: {
        type: String,
        default: null
      },

      description: {
        type: String,
        default: null
      },

      templateQuestions: {
        type: Array,
        default: null
      }
    },

    data() {
      return {
        form: {
          isTemplate: false,
          name: null,
          title: null,
          description: null
        }
      };
    },

    computed: {
      formTypeDisplay() {
        switch (this.formType) {
          case surveyFormTypes.PAY_AT_TABLE_SURVEY:
            return 'P@T';
          case surveyFormTypes.ORDER_AHEAD_SURVEY:
            return 'Order Ahead';
          case surveyFormTypes.ORDER_AT_TABLE_SURVEY:
            return 'O@T';
          case surveyFormTypes.ORDER_SURVEY:
            return 'Order';
          case surveyFormTypes.MOBILE_TIPPING_SURVEY:
            return 'Mobile Tipping';
          default:
            return '';
        }
      },
      isMobileTippingSurvey() {
        return this.formType === surveyFormTypes.MOBILE_TIPPING_SURVEY;
      }
    },

    watch: {
      form: {
        deep: true,
        handler: 'onFormChange'
      }
    },

    created() {
      this.onCreated();
    },

    methods: {
      onCreated() {
        this.form = {
          name: this.name,
          title: this.title,
          description: this.description,
          isTemplate: this.isTemplate
        };
      },

      onFormChange() {
        this.handleFormChange(this.form);
      },

      showTemplatePreview() {
        this.$buefy.modal.open({
          parent: this,
          component: surveyPreview,
          trapFocus: true,
          customClass: 'auto-width',
          props: {
            survey: {
              title: `${this.formTypeDisplay} Survey Template`,
              questions: this.templateQuestions
            }
          }
        });
      }
    }
  };
</script>
