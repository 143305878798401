<template>
  <validated-form
    ref="form"
    v-slot="{ errors }"
    auto-focus
    form-id="addDeliveryService"
    @valid-submit="addDeliveryService"
  >
    <modal-card title="Add Delivery Service" show-overflow>
      <validated-input
        class="mar-b-lg"
        name="selectedDeliveryServices"
        rules="required"
        label="Delivery Services(s)"
      >
        <b-dropdown
          v-model="form.selectedDeliveryServiceIds"
          expanded
          multiple
          class="has-extra-shadow"
        >
          <dropdown-button
            slot="trigger"
            placeholder="Select Delivery Service..."
            :value="!!form.selectedDeliveryServiceIds.length && `Selected (${form.selectedDeliveryServiceIds.length})`"
            class="wrap-text auto-height"
            :has-errors="!!(errors.selectedDeliveryServices && errors.selectedDeliveryServices.length)"
          />

          <b-dropdown-item
            v-for="deliveryService in filteredDeliveryServiceOptions"
            :key="deliveryService.id"
            :value="deliveryService.id"
          >
            {{ deliveryService.name }}
          </b-dropdown-item>
        </b-dropdown>
      </validated-input>

      <template #footer>
        <div class="buttons all-bold">
          <b-button
            rounded
            @click="$_confirmCloseModal({ programmatic: true })"
          >
            Cancel
          </b-button>
          <b-button
            v-tabbable
            rounded
            native-type="submit"
            type="is-primary"
            :loading="isSubmitting"
          >
            Assign
          </b-button>
        </div>
      </template>
    </modal-card>
  </validated-form>
</template>

<script>
  import confirmModalCloseMixin from '@/mixins/confirm-modal-close';
  import DeliveryService from '@/store/classes/DeliveryService';
  import MerchantDeliveryService from '@/store/classes/MerchantDeliveryService';

  export default {
    name: 'AddDeliveryServiceModal',

    mixins: [confirmModalCloseMixin],

    props: {
      merchantDeliveryServices: {
        type: Array,
        required: true
      },
      merchantId: {
        type: Number,
        required: true
      }
    },

    data() {
      return {
        form: {
          selectedDeliveryServiceIds: []
        }
      };
    },

    computed: {
      isSubmitting() {
        return MerchantDeliveryService.$state().submitting;
      },

      allDeliveryServices() {
        return DeliveryService.all();
      },

      filteredDeliveryServiceOptions() {
        return this.allDeliveryServices.filter(ds => !this.merchantDeliveryServices.some(mds => mds.deliveryServiceId === ds.id));
      },

      selectedDeliveryService() {
        return this.filteredDeliveryServiceOptions.find(option => option.id === this.form.selectedDeliveryServiceIds[0]);
      }
    },

    methods: {
      async addDeliveryService() {
        try {
          const requests = JSON.parse(JSON.stringify(this.form.selectedDeliveryServiceIds)).map(id => MerchantDeliveryService.addMerchantDeliveryService({
            merchantId: this.merchantId,
            merchantDeliveryService: { apiConfiguration: {}, deliveryServiceId: id }
          }));
          await Promise.all(requests);
          this.$_onRequestSuccess({
            toastOptions: { message: 'Successfully added delivery service!' },
            options: { closeParent: true, emit: { name: 'delivery-service-added', arg: this.form.selectedDeliveryServiceIds[0] } }
          });
        }

        catch (error) {
          this.$_onRequestError({
            toastOptions: { message: 'An error occured while adding your new delivery services' },
            error
          });
        }
      }
    }
  };
</script>

<style lang="sass" scoped>
  .modal-card
    overflow: visible

    .modal-card-body
      overflow: visible
</style>
