<template>
  <li :id="design.id" data-test-id="gift-card-design" class="gift-card-design-card draggable has-border has-border-grey-lighter pad-none">
    <div class="is-relative">
      <span
        v-if="design.isDefault"
        class="default-tag tag is-dark has-text-weight-bold"
      >
        DEFAULT
      </span>
      <b-button class="edit-button is-small has-radius-full" @click="$emit('open-gift-card-design-modal', design)">
        <b-icon class="is-primary" icon="pencil" size="is-small" />
      </b-button>
      <b-image
        data-test-id="gift-card-design-image"
        class="gift-card-design-image"
        :src="design.fullUrl"
        :alt="`gift card design ${design.id}`"
      />
    </div>
    <div
      data-test-id="gift-card-design-name"
      :class="['is-flex gap-xs pad-xs', !design.isActive && 'has-text-grey-light']"
    >
      <b-icon
        icon="grip-lines"
        size="is-small"
        pack="far"
        class="drag-handle pad-sm"
      />
      <div class="gift-card-design-name">
        <b ref="text">{{ design.name }}</b>
      </div>
    </div>
  </li>
</template>

<script>
  export default {
    name: 'GiftCardDesignListItem',

    props: {
      design: {
        type: Object,
        required: true
      }
    },

    watch: {
      'design.name': {
        immediate: true,
        handler() {
          this.$nextTick(() => {
            const text = this.$refs.text;
            const container = text.parentElement;
            const hasContainedText = text.offsetWidth < container.offsetWidth;

            if (hasContainedText) {
              const grabberWidth = 30;
              const remainingSpace = Math.round(container.offsetWidth - text.offsetWidth);
              const offsetAmount = Math.min(remainingSpace, grabberWidth) / 2;

              text.style.display = 'inline-block';
              text.style.transform = `translateX(-${offsetAmount}px)`;
            }
            else {
              text.style.display = null;
              text.style.transform = null;
            }
          });
        }
      }
    }
  };
</script>

<style lang="sass" scoped>
.gift-card-design-card
  width: 277px
  border-radius: 12px
  overflow: hidden
  background: $white

.gift-card-design-image
  border-radius: 12px
  height: 165px
  overflow: hidden
  box-shadow: 0px 1px 2px $grey-lighter

.gift-card-design-name
  white-space: nowrap
  overflow: hidden
  text-overflow: ellipsis
  text-align: center
  flex: 1

.default-tag
  position: absolute
  top: 0
  left: 20px
  z-index: 1
  border-top-left-radius: 0
  border-top-right-radius: 0

.edit-button
  position: absolute
  z-index: 1
  right: 0.5rem
  top: 0.5rem
</style>
