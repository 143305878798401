<template>
  <takeover>
    <sign-in-form />
  </takeover>
</template>



<script>
  import { mapState } from 'vuex';

  export default {
    name: 'SignIn',

    metaInfo() {
      return { title: 'Sign In' };
    },

    computed: {
      ...mapState('session', ['isSignedIn'])
    },

    watch: {
      isSignedIn: 'handleSignInChange'
    },

    methods: {
      handleSignInChange(isSignedIn) {
        if (isSignedIn) this.$router.replace({ name: 'home' });
      }
    }
  };
</script>
