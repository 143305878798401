<template>
  <div class="card-content">
    <p class="title is-4 has-text-weight-normal">Run Limit</p>
    <p class="subtitle is-6 has-text-grey mar-b">{{ subtitle }}</p>
    <b-message
      v-if="!hideMessage && !value.length"
      type="is-info"
      class="mar-none is-compact has-shadow"
    >
      By default, Earn Rules have no run limit and may be earned an unlimited amount of times. To change that behavior, add <b>Run Limits</b> below.
    </b-message>
    <div class="dist-y">
      <div v-if="readOnly && !value.length">
        Unlimited
      </div>
      <div
        v-for="(runLimitPolicy, idx) in value"
        :key="`runLimitPolicy-${idx}`"
        data-test-class="run-limit-policy"
        :data-test-id="`run-limit-policy-${idx}`"
        class="is-grid gap col-2"
      >
        <div class="is-flex gap">
          <validated-input
            :name="`runLimitPolicy${idx}`"
            label="Run limit policy type"
            hide-label
            rules="required"
            class="flex-grow mar-none"
            :custom-messages="{ required: 'Please select a run limit policy option' }"
          >
            <b-select
              v-model="value[idx].runLimitPolicyType"
              placeholder="Select a run limit policy option"
              expanded
            >
              <option :value="undefined" disabled>
                -
              </option>
              <option
                v-for="runLimitPolicyType in availableRunLimitPolicyTypeOptions"
                :key="`runLimitPolicyType-${runLimitPolicyType.label}-${idx}`"
                :value="runLimitPolicyType.value"
                :disabled="selectedRunLimitPolicyTypes.includes(runLimitPolicyType.value)"
              >
                {{ runLimitPolicyType.label }}
              </option>
            </b-select>
          </validated-input>
          <validated-input
            v-if="runLimitPolicy.runLimitPolicyType === runLimitPolicyTypes.WEEK.type"
            name="weekStartDay"
            label="Week starts on"
            label-position="on-border"
            rules="required"
          >
            <b-select
              v-model="value[idx].firstDayOfWeek"
              placeholder="Select a day..."
              expanded
            >
              <option :value="null" disabled>
                Week starts on...
              </option>
              <option
                v-for="(dayValue, day) in daysOfWeek"
                :key="`weekStartsOn-${dayValue}-${day}`"
                :value="dayValue"
              >
                {{ day }}
              </option>
            </b-select>
          </validated-input>
        </div>
        <div class="is-flex gap">
          <validated-input
            :label="`runLimitPolicyType-maximumRuns-${idx}`"
            :name="`runLimitPolicyType-maximumRuns-${idx}`"
            hide-label
            class="mar-none"
            :rules="{
              required: true,
              min_value: 1
            }"
            :custom-messages="{
              required: 'Please enter a maximum number of runs',
              min_value: 'Must be at least 1'
            }"
          >
            <b-numberinput
              v-model="value[idx].maximumRuns"
              controls-position="compact"
              class="mar-none"
            />
          </validated-input>
          <b-button
            v-if="!readOnly"
            class="is-transparent"
            type="is-white"
            @click="removeRunLimitPolicy(idx)"
          >
            <b-icon icon="trash-alt" type="is-danger" />
          </b-button>
        </div>
      </div>
    </div>
    <div v-if="!readOnly" class="mar-t">
      <b-button
        type="is-primary is-light"
        icon-left="plus"
        :disabled="readOnly || availableRunLimitPolicyTypeOptions.length === selectedRunLimitPolicyTypes.length"
        data-test-id="add-run-limit-button"
        @click="addRunLimitPolicy()"
      >
        Run Limit
      </b-button>
      <b-button
        v-if="value.length > 1"
        type="is-danger is-light"
        icon-left="trash"
        data-test-id="reset-run-limits-button"
        class="mar-l-md"
        @click="resetRunLimitPolicies()"
      >
        Clear All Run Limits
      </b-button>
    </div>
  </div>
</template>

<script>
  import daysOfWeek from '@/constants/daysOfWeek';
  import { runLimitPolicyTypes } from '@/constants/earnRules';


  export default {
    name: 'EarnRuleRunLimitInputs',

    props: {

      value: {
        type: Array,
        required: true
      },

      readOnly: {
        type: Boolean,
        default: false
      },

      subtitle: {
        type: String,
        default: 'Define how many times the earn rule can grant an award to a guest'
      },

      hideMessage: {
        type: Boolean,
        default: false
      }
    },

    data() {
      return {
        daysOfWeek,
        runLimitPolicyTypes
      };
    },

    computed: {
      availableRunLimitPolicyTypeOptions() {
        return Object.values(runLimitPolicyTypes).map(runLimitPolicyType => ({ label: runLimitPolicyType.display, value: runLimitPolicyType.type }));
      },

      selectedRunLimitPolicyTypes() {
        return this.value.map(runLimitPolicy => runLimitPolicy.runLimitPolicyType);
      }
    },

    methods: {
      resetRunLimitPolicies() {
        this.handleInput([]);
      },

      addRunLimitPolicy() {
        this.handleInput([
          ...this.value,
          { runLimitPolicyType: null, maximumRuns: 1 }
        ]);
      },

      removeRunLimitPolicy(idx) {
        const updatedEarnRuleRunLimitPolicies = this.$clone(this.value).filter((runLimitPolicy, runLimitPolicyidx) => runLimitPolicyidx !== idx);
        this.handleInput(updatedEarnRuleRunLimitPolicies);
      },

      handleInput(payload) {
        this.$emit('input', payload);
      }
    }
  };
</script>
