<template>
  <div>
    <validated-form
      ref="itemDetailsForm"
      :disabled="!$can('update', 'MenuItem') || !$_menuPermissions.EDIT_RESOURCE"
      auto-focus
      form-id="itemDetailsForm"
    >
      <validated-text-input
        v-model="form.displayName"
        :has-icon="false"
        label="Name"
        name="displayName"
        rules="required"
        type="text"
        maxlength="255"
        :has-counter="true"
      />

      <validated-text-input
        v-if="$_featurePermissions.MENU_ITEM_UPC"
        v-model="form.universalProductCode"
        :has-icon="false"
        label="UPC"
        name="universalProductCode"
        type="text"
        maxlength="12"
        :rules="{
          length: 12,
          integer: true
        }"
        :custom-messages="{ length: 'The upc field must be 12 digits' }"
        has-counter
        tooltip="Scan code with a barcode scanner or enter manually"
        highlight-on-focus
      />

      <validated-text-input
        v-model="form.descriptionText"
        :has-icon="false"
        label="Description"
        name="description"
        type="textarea"
        maxlength="2000"
        :has-counter="true"
      />

      <validated-text-input
        v-if="$_selectedMerchant && $_selectedMerchant.readOnlyMenuEnabled"
        v-model="form.modifierSummary"
        :has-icon="false"
        label="Sub-Description"
        name="subDescription"
        type="text"
        placeholder="Ex: Choice of white, wheat, or rye bread"
        tooltip="Describe the options a guest can choose for this item. Shown in Digital Menu. Optional."
      />

      <validated-input
        label="Calories"
        name="caloriesLabel"
        tooltip="If there is only one value, use the “low” field"
        tooltip-placement="right"
      />
      <div class="is-grid col-3 gap-md">
        <validated-text-input
          v-model="form.caloriesLow"
          label="Low"
          name="caloriesLow"
          type="number"
          placeholder="Ex: 20"
          :rules="{
            required: !!form.caloriesHigh,
            ...(form.caloriesHigh ? { lessThan: { target: form.caloriesHigh } } : {})
          }"
          label-position="on-border"
        />

        <validated-text-input
          v-model="form.caloriesHigh"
          label="High"
          name="caloriesHigh"
          type="number"
          placeholder="Ex: 100"
          :rules="{
            ...(form.caloriesLow ? { greaterThan: { target: form.caloriesLow } } : {})
          }"
          label-position="on-border"
        />
        <validated-text-input
          v-model="form.nutritionalUnits"
          label="Units"
          name="nutritionalUnits"
          type="text"
          placeholder="Ex: calories"
          :rules="{ required: !!form.caloriesLow || !!form.caloriesHigh }"
          label-position="on-border"
        />
      </div>

      <hr>

      <p class="subtitle is-5 mar-b">Images</p>

      <image-upload
        v-slot="{imagePath}"
        v-model="thumbnailImage.imageFile"
        :accepted-types="['png', 'webp', 'jpeg', 'jpg', 'svg']"
        :image-size-warning-width="640"
        :image-size-warning-height="472"
        :image-url="thumbnailImage && thumbnailImage.fullUrl"
        :loading="isFetchingImages"
        :disabled="!$can('update', 'MenuItem')"
        delete-text="Delete Image"
        label="Menu Image"
        :maximum-file-size-in-mb="1"
        restrict-file-size
        show-delete-button
        show-clear-button
        is-full-width
        @delete-image="deleteImage(thumbnailImage.id)"
      >
        <img v-if="imagePath" :src="imagePath" alt="Thumbnail Image">
      </image-upload>

      <image-upload
        v-slot="{imagePath}"
        v-model="bannerImage.imageFile"
        :accepted-types="['png', 'webp', 'jpeg', 'jpg', 'svg']"
        :image-size-warning-width="1440"
        :image-size-warning-height="600"
        :image-url="bannerImage && bannerImage.fullUrl"
        :loading="isFetchingImages"
        :disabled="!$can('update', 'MenuItem')"
        delete-text="Delete Image"
        label="Item Details Image"
        :maximum-file-size-in-mb="1"
        restrict-file-size
        show-delete-button
        show-clear-button
        is-full-width
        @delete-image="deleteImage(bannerImage.id)"
      >
        <img v-if="imagePath" :src="imagePath" alt="Banner Image">
      </image-upload>

      <b-message
        v-if="$can('update', 'MenuItem') && (bannerImage.id || thumbnailImage.id)"
        size="is-small"
      >
        You can replace an image above by dragging a new image over or clicking on the old one
      </b-message>
    </validated-form>
  </div>
</template>

<script>
  import filterObjectKeys from '@/helpers/filter-object-keys';

  import merchantMixin from '@/mixins/merchant';
  import featurePermissionsMixin from '@/mixins/featurePermissions';
  import multiFormChildProvider from '@/mixins/multiFormMixin/multiFormChildProvider';

  import Item from '@/store/classes/Item';
  import ItemImage from '@/store/classes/ItemImage';

  export default {
    name: 'ItemDetailsForm',

    mixins: [merchantMixin, featurePermissionsMixin, multiFormChildProvider],

    props: {
      category: {
        type: Object,
        required: true
      },

      item: {
        type: Object,
        required: true
      },

      formRef: {
        type: String,
        required: true
      },

      mode: {
        type: String,
        default: 'create',
        validator(value) {
          return ['create', 'read', 'update'].includes(value);
        }
      }
    },

    data() {
      return {
        form: null,
        bannerImage: {},
        thumbnailImage: {}
      };
    },

    computed: {
      _bannerImage() {
        if (this.mode === 'create') {
          return new ItemImage();
        }

        return ItemImage
          .query()
          .where('menuItemId', Number(this.item.id))
          .where('label', 'itemDetails')
          .first() || new ItemImage();
      },

      _thumbnailImage() {
        if (this.mode === 'create') {
          return new ItemImage();
        }

        return ItemImage
          .query()
          .where('menuItemId', Number(this.item.id))
          .where('label', 'menu')
          .first() || new ItemImage();
      },

      isFetchingImages() {
        return ItemImage.$state().fetching;
      }
    },

    watch: {
      item: {
        immediate: true,
        handler(updatedItem) {
          this.form = this.$clone(updatedItem);
        }
      }
    },

    async created() {
      await this.onCreated();
    },

    methods: {
      async onCreated() {
        if (this.mode === 'update') {
          await this.fetchItemImages(this.item.id);
        }
        this.setImagesOnForm();
      },

      setImagesOnForm() {
        this.bannerImage = this.$clone(this._bannerImage);
        this.thumbnailImage = this.$clone(this._thumbnailImage);
      },

      async fetchItemImages(itemId) {
        try {
          await ItemImage.fetchItemImages(itemId);
        }

        catch (error) {
          this.$_onRequestError({
            toastOptions: {
              message: 'Unable to get item image'
            },
            error
          });
        }
      },

      async saveBannerImage(itemId) {
        const { imageFile } = this.bannerImage;
        if (imageFile) {
          return ItemImage.addItemImage({ itemId, imageFile, imageType: 'itemDetails' });
        }
        return Promise.resolve();
      },

      async saveThumbnailImage(itemId) {
        const { imageFile } = this.thumbnailImage;
        if (imageFile) {
          return ItemImage.addItemImage({ itemId, imageFile, imageType: 'menu' });
        }
        return Promise.resolve();
      },

      async saveImages(itemId) {
        try {
          await Promise.all([
            this.saveBannerImage(itemId),
            this.saveThumbnailImage(itemId)
          ]);
        }
        catch (error) {
          throw error;
        }
      },

      async deleteImage(imageId) {
        try {
          await ItemImage.deleteImage(imageId);

          this.setImagesOnForm();

          this.$_onRequestSuccess({
            toastOptions: {
              message: 'Successfully deleted image!'
            }
          });
        }

        catch (error) {
          this.$_onRequestError({
            toastOptions: {
              message: 'An error occured while deleting your images'
            },
            error
          });
        }
      },

      async addItem() {
        try {
          const { id } = await Item.addItemToCategory({
            menuItem: this.form,
            categoryId: this.category.id
          });

          await this.saveImages(id);

          return id;
        }

        catch (error) {
          let message = 'An error occured while adding your new item';

          if (error.config.url.split('/').includes('menu_item_images')) {
            message += ' images';
          }

          this.$_onRequestError({
            toastOptions: { message },
            error
          });
        }
      },

      async updateItem() {
        try {
          const whitelistedKeys = [
            'id',
            'displayName',
            'universalProductCode',
            'descriptionText',
            'modifierSummary',
            'caloriesLow',
            'caloriesHigh',
            'nutritionalUnits'
          ];

          await Promise.all([
            Item.updateItem(filterObjectKeys(this.form, whitelistedKeys)),
            this.saveImages(this.form.id)
          ]);
        }

        catch (error) {
          let message = 'An error occured while updating your item';

          if (error?.config?.url.split('/').includes('menu_item_images')) {
            message += ' images';
          }

          this.$_onRequestError({
            toastOptions: { message },
            error
          });
        }
      },

      async handleSubmit() {
        let newItemId;

        switch (this.mode) {
          case 'create':
            newItemId = await this.addItem();
            return newItemId;

          case 'update':
            await this.updateItem();
            break;

          default:
            break;
        }
      }
    }
  };
</script>

<style lang="sass" scoped>
  ::v-deep
    .upload.control,
    .upload-draggable
      width: 100%
</style>
