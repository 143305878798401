<template>
  <div>
    <p
      v-for="orderFee in orderFees"
      :key="`${orderFee.name}-${orderFee.amount}`"
      :class="`is-6 is-flex-space-between ${customClasses}`"
    >
      <span class="has-text-grey has-text-weight-normal">
        {{ orderFee.name | capitalize }}
        <b-icon
          v-if="orderFee.description"
          v-tippy="{
            content: orderFee.description,
            maxWidth: 300,
            delay: [333, 0]
          }"
          icon="info-circle"
          size="is-small"
          type="is-grey"
          pack="far"
        />
        {{ showColon ? ':' : '' }}
      </span>
      <span>{{ orderFee.amount | dollars }}</span>
    </p>
  </div>
</template>

<script>
  export default {
    name: 'OrderFees',

    props: {
      orderFees: {
        type: Array,
        required: true
      },
      customClasses: {
        type: String,
        default: ''
      },
      showColon: {
        type: Boolean,
        required: false
      }
    }
  };
</script>
