<template>
  <div>

    <!-- SEARCH/FILTERS -->
    <div class="search-section pad-x pad-t">
      <b-field
        class="search-inputs"
        label-position="on-border"
        label="Criteria"
      >
        <div class="control">
          <b-select v-model="param">
            <option value="merchantReference">Reference #</option>
            <option value="cardholderName">Card Holder</option>
            <option value="accountNumberLastFour">Card # Last 4</option>
            <option value="amount">Amount</option>
          </b-select>
        </div>
        <div class="control is-expanded mar-r">
          <validated-text-input
            v-model="query"
            :icon-right-clickable="!!query"
            :icon-right="query ? 'times-circle': ''"
            placeholder="Search"
            :type="queryInputType"
            class="query-input"
            icon="search"
            label-position="on-border"
            label="Search"
            hide-label
            :mask-options="maskOptions"
            :rules="paramRules"
            :maxlength="param === 'accountNumberLastFour' ? '4' : null"
            name="query-input"
            expanded
            output-string
            @icon-right-click="query = ''"
            @keydown.native.esc="query = ''"
          />
        </div>
      </b-field>

      <div class="filters is-flex-wrap">
        <b-field label-position="on-border" label="Date Range" style="min-width: 230px">
          <b-datepicker
            v-model="dateRange"
            class="date-picker-input"
            icon="calendar-alt"
            range
            :min-date="ninetyDaysAgo"
            :max-date="today"
            :focused-date="focusedDate"
            :nearby-selectable-month-days="true"
            :class="['has-extra-shadow', isTodayDateRange && 'today-selected']"
            :mobile-native="false"
          >
            <div class="date-range-shortcuts">
              <b-button
                size="is-small"
                :type="isTodayDateRange ? 'is-primary' : ''"
                @click="setDateRange('today')"
              >
                Today
              </b-button>
              <b-button
                size="is-small"
                :type="isLastSevenDaysDateRange ? 'is-primary' : ''"
                @click="setDateRange('last-seven-days')"
              >
                Past 7 Days
              </b-button>
              <b-button
                size="is-small"
                :type="isLastThirtyDays ? 'is-primary' : ''"
                @click="setDateRange('last-thirty-days')"
              >
                Past 30 days
              </b-button>
              <b-button
                size="is-small"
                :type="isLastNinetyDaysRange ? 'is-primary' : ''"
                @click="setDateRange('last-ninety-days')"
              >
                Past 90 days
              </b-button>

              <dropdown-menu
                :value="timezone"
                expanded
                @input="timezone = $event"
              >
                <dropdown-button
                  slot="trigger"
                  placeholder="Select a time zone..."
                >
                  <div v-if="timezone" class="is-flex align-center">
                    <span class="mar-r-sm has-text-weight-normal">{{ timeZones[timezone] }}</span>
                  </div>

                </dropdown-button>
                <b-dropdown-item v-for="(display, tmzValue) in timeZones" :key="tmzValue" :value="tmzValue" class="pad-y-sm is-flex align-center">
                  <span class="mar-r-sm">{{ display }}</span>
                </b-dropdown-item>
              </dropdown-menu>
            </div>
          </b-datepicker>
        </b-field>

        <b-field
          v-if="stores.length > 1"
          label-position="on-border"
          label="Location"
        >
          <b-dropdown
            v-model="storeId"
            :mobile-modal="false"
            aria-role="list"
            scrollable
            max-height="325px"
            class="has-extra-shadow"
          >
            <dropdown-button slot="trigger" :value="storeId ? selectedStore.description : 'All'" />
            <b-dropdown-item :class="!storeId && 'is-active'" :value="null" aria-role="listitem">
              All
            </b-dropdown-item>
            <hr class="dropdown-divider">
            <b-dropdown-item
              v-for="store in stores"
              :key="store.storeId"
              :value="store.storeId"
              aria-role="listitem"
            >
              {{ store.description }}
            </b-dropdown-item>
          </b-dropdown>
        </b-field>

        <b-field
          label-position="on-border"
          label="Status"
        >
          <b-dropdown
            v-model="paymentTransactionStatus"
            :mobile-modal="false"
            aria-role="list"
            scrollable
            max-height="325px"
            class="has-extra-shadow"
          >
            <dropdown-button slot="trigger" :value="paymentTransactionStatusDisplay" />
            <b-dropdown-item :class="!paymentTransactionStatus && 'is-active'" :value="null" aria-role="listitem">
              All
            </b-dropdown-item>
            <hr class="dropdown-divider">
            <b-dropdown-item
              v-for="status in Object.values(paymentTransactionStatusTypes)"
              :key="status.value"
              :value="status.value"
              aria-role="listitem"
            >
              {{ status.display }}
            </b-dropdown-item>
          </b-dropdown>
        </b-field>

        <b-field
          label-position="on-border"
          label="Type"
        >
          <b-dropdown
            v-model="paymentTransactionType"
            :mobile-modal="false"
            aria-role="list"
            scrollable
            max-height="325px"
            class="has-extra-shadow"
          >
            <dropdown-button slot="trigger" :value="paymentTransactionTypeDisplay" />
            <b-dropdown-item :class="!paymentTransactionType && 'is-active'" :value="null" aria-role="listitem">
              All
            </b-dropdown-item>
            <hr class="dropdown-divider">
            <b-dropdown-item
              v-for="t in paymentTransactionTypes"
              :key="t.value"
              :value="t.value"
              aria-role="listitem"
            >
              {{ t.display }}
            </b-dropdown-item>
          </b-dropdown>
        </b-field>

        <b-field
          label-position="on-border"
          label="Method"
        >
          <b-dropdown
            v-model="paymentMethod"
            :mobile-modal="false"
            aria-role="list"
            scrollable
            max-height="1000px"
            class="has-extra-shadow"
          >
            <dropdown-button slot="trigger" :value="paymentMethodDisplay" />
            <b-dropdown-item :class="!paymentMethod && 'is-active'" :value="null" aria-role="listitem">
              All
            </b-dropdown-item>
            <hr class="dropdown-divider">
            <b-dropdown-item
              v-for="method in merchantPaymentMethods"
              :key="method"
              :value="method"
              aria-role="listitem"
            >
              {{ getPaymentMethodDisplay(method) }}
            </b-dropdown-item>
          </b-dropdown>
        </b-field>

        <b-field
          label-position="on-border"
          label="Additional Options"
        >
          <b-dropdown
            :mobile-modal="false"
            aria-role="list"
            scrollable
            max-height="325px"
            class="has-extra-shadow"
          >
            <dropdown-button
              slot="trigger"
              :value="additionalOptionsDisplay"
            />
            <b-dropdown-item
              aria-role="listitem"
              :class="!excludeOrderPayments && 'is-active'"
              @click="excludeOrderPayments = !excludeOrderPayments"
            >
              Include Order Payments
            </b-dropdown-item>
          </b-dropdown>
        </b-field>
      </div>
    </div>
    <div :class="['pad-x result-count is-flex justify-between align-center', { 'is-invisible': isLoadingPaymentTransactions || !paymentTransactionMetadata.count }]">
      <div>
        <p v-if="paymentTransactionMetadata.count < 100">
          <span class="has-text-weight-bold has-text-black">
            {{ paymentTransactionMetadata.count }}
          </span>
          Matching Order{{ paymentTransactionMetadata.count > 1 ? 's' : '' }}
        </p>
        <template v-else>
          <p>
            Displaying
            <span class="has-text-weight-bold has-text-black">
              {{ paymentTransactionMetadata.count }}
            </span>
            best results
          </p>
          <p class="is-size-7 has-text-grey">
            If you don’t see the transaction that you're looking for, please enter a more specific search
          </p>
        </template>
      </div>

      <b-dropdown position="is-bottom-left" class="has-extra-shadow">
        <template #trigger>
          <b-button icon-left="columns">
            Columns
            <template v-if="hiddenColumns.length !== hidableColumnCount">
              ({{ visibleColumnCount }}/{{ hidableColumnCount }})
            </template>
          </b-button>
        </template>

        <b-dropdown-item custom>
          <div class="is-flex-column gap-sm">
            <b-checkbox
              v-for="[columnName, column] in Object.entries(hidableColumns)"
              :key="columnName + column.display"
              v-model="column.isVisible"
            >
              {{ column.display }}
            </b-checkbox>
            <b-button
              class="mar-t-sm"
              type="is-light is-primary"
              @click="Object.values(hidableColumns).forEach(c => c.isVisible = true)"
            >
              Select All
            </b-button>
          </div>
        </b-dropdown-item>
      </b-dropdown>
    </div>

    <!-- TABLE -->
    <div class="has-border-top has-border-grey-lighter card">
      <b-table
        ref="paymentTransactionsTable"
        :data="isLoadingPaymentTransactions ? [] : paymentSummaries"
        paginated
        :total="paymentTransactionMetadata.count"
        :current-page="page"
        :class="[
          'is-middle-aligned no-header-wrap',
          {[`overflow-x-custom-${$_scrollableDirectionX}`]: $_scrollableDirectionX}
        ]"
        scrollable
        :mobile-cards="false"
        :backend-pagination="true"
        :backend-sorting="true"
        hoverable
        detailed
        :show-detail-icon="false"
        custom-detail-row
        :default-sort="['TransactionDateTime', 'desc']"
        :per-page="perPage"
        detail-transition="fade-down"
        detail-key="id"
        :opened-detailed.sync="openedTransactionRowIds"
        @page-change="handlePageChange"
        @sort="handleSort"
      >
        <template slot="empty">
          <empty-table-loader
            :loading="isLoadingPaymentTransactions"
            :has-icon="false"
            :message="hasMadeInitialSearch ? 'No transactions found' : 'Enter search criteria and adjust filters to find transactions'"
          />
        </template>

        <b-table-column
          v-slot="{ row, toggleDetails }"
          field="TransactionNumber"
          sortable
          label="Transaction #"
          cell-class="no-wrap-text"
          width="1"
          sticky
          sticky-header
        >
          <span class="link align-center" @click="toggleRow({ toggle: toggleDetails, row })">
            <b-icon
              :class="[
                'open-indicator-arrow',
                openedTransactionRowIds.includes(row.id) && 'is-open',
                {
                  'spin': fetchingId === row.id
                }
              ]"
              :icon="fetchingId === row.id ? 'spinner-third' : 'angle-right'"
            />
            {{ row.id }}
          </span>
        </b-table-column>

        <b-table-column
          v-slot="{ row: { merchantReference } }"
          field="MerchantReference"
          sortable
          label="Reference #"
          cell-class="no-wrap-text"
          :visible="hidableColumns.merchantReference.isVisible"
        >
          <span>
            {{ merchantReference || 'N/A' }}
          </span>
        </b-table-column>

        <b-table-column
          field="paymentTransactionType"
          label="Type"
          cell-class="no-wrap-text"
          centered
          :visible="hidableColumns.paymentTransactionType.isVisible"
        >
          --
        </b-table-column>

        <b-table-column
          v-slot="{ row: { paymentState } }"
          field="PaymentState"
          label="Status"
          cell-class="no-wrap-text"
          centered
          :visible="hidableColumns.paymentState.isVisible"
        >
          <span>
            {{ paymentState || '--' }}
          </span>
        </b-table-column>

        <b-table-column
          v-slot="{ row: transaction }"
          field="TransactionDateTime"
          sortable
          label="Date"
          :visible="hidableColumns.transactionDateTime.isVisible"
        >
          <template v-if="transaction.storeId && getStore(transaction.storeId)">
            <div class="no-wrap-text">{{ transaction.transactionDateTime | moment('M/D/YYYY', getStore(transaction.storeId).ianaTimezoneId) }}</div>
            <div class="no-wrap-text">{{ transaction.transactionDateTime | moment('h:mm A z', getStore(transaction.storeId).ianaTimezoneId) }}</div>
          </template>
          <span
            v-else
            v-tippy="{
              content: 'This transaction is missing a location and timezone',
              placement: 'left',
              delay: [150, 0]
            }"
          >
            <div class="no-wrap-text">{{ transaction.transactionDateTime | moment('M/D/YYYY', null, true) }}</div>
            <div class="no-wrap-text">{{ transaction.transactionDateTime | moment('h:mm A z', null, true) }}</div>
          </span>
        </b-table-column>

        <b-table-column
          v-slot="{ row: { storeId: sId } }"
          field="storeId"
          label="Location"
          cell-class="no-wrap-text"
          :visible="hidableColumns.storeId.isVisible"
        >
          <span v-if="sId && getStore(sId)">
            {{ getStore(sId).description || 'N/A' }}
          </span>
          <span v-else class="has-text-grey-light">
            N/A
          </span>
        </b-table-column>

        <b-table-column
          v-slot="{ row: { paymentMethod: paymentMethodType } }"
          field="paymentMethod"
          label="Method"
          cell-class="no-wrap-text"
          :visible="hidableColumns.paymentMethod.isVisible"
        >
          <span :class="{'has-text-grey-light': !paymentMethodType}">
            {{ getPaymentMethodDisplay(paymentMethodType) || 'N/A' }}
          </span>
        </b-table-column>

        <b-table-column
          v-slot="{ row: { paymentGatewayType } }"
          field="paymentGatewayType"
          label="Gateway"
          cell-class="no-wrap-text"
          :visible="hidableColumns.paymentGatewayType.isVisible"
        >
          <span :class="{'has-text-grey-light': !paymentGatewayType}">
            {{ capitalCase(paymentGatewayType) || 'N/A' }}
          </span>
        </b-table-column>

        <b-table-column
          v-slot="{ row: { cardHolderName } }"
          field="cardholderName"
          label="Card Holder"
          cell-class="no-wrap-text"
          :visible="hidableColumns.cardholderName.isVisible"
        >
          <span :class="{'has-text-grey-light': !cardHolderName}">
            {{ cardHolderName || 'N/A' }}
          </span>
        </b-table-column>

        <b-table-column
          v-slot="{ row: { accountNumberLastFour } }"
          field="accountNumberLastFour"
          label="Card # Last 4"
          centered
          cell-class="no-wrap-text"
          :visible="hidableColumns.accountNumberLastFour.isVisible"
        >
          <span v-if="accountNumberLastFour">
            {{ `.... ${accountNumberLastFour}` }}
          </span>
          <span v-else class="has-text-grey-light">
            N/A
          </span>
        </b-table-column>

        <b-table-column
          v-slot="{ row: { cardType } }"
          field="cardType"
          label="Card Type"
          centered
          cell-class="no-wrap-text"
          :visible="hidableColumns.cardType.isVisible"
        >
          <span :class="{'has-text-grey-light': !cardType}">
            {{ cardType || 'N/A' }}
          </span>
        </b-table-column>

        <b-table-column
          v-slot="{ row: { amount } }"
          field="amount"
          label="Amount"
          sortable
          centered
          numeric
          cell-class="no-wrap-text"
        >
          <span v-if="!amount" class="has-text-grey-light">
            N/A
          </span>
          <span v-else>
            {{ amount | dollars }}
          </span>
        </b-table-column>

        <!-- NESTED DETAIL ROWS -->
        <template slot="detail" slot-scope="props">
          <tr
            v-for="(t, i) in props.row.transactions"
            :key="`${t.id}-${i}`"
            class="row-pointer has-text-grey has-hover-background-grey-lightest"
            @click="handleTransactionRowClick({ transaction: t, summary: props.row })"
          >
            <td :key="`${t.id}-id-${i}`" class="is-sticky">
              <b-icon
                icon="angle-right"
                style="visibility: hidden"
              />
              {{ t.id }}
            </td>
            <td v-show="hidableColumns.merchantReference.isVisible" :key="`${t.id}-merchantReference-${i}`">
              <span>
                {{ t.merchantReference || 'N/A' }}
              </span>
            </td>
            <td v-show="hidableColumns.paymentTransactionType.isVisible" :key="`${t.id}-paymentTransactionType-${i}`" class="has-text-align-center">
              <span :class="{'has-text-grey-light': !t.paymentTransactionType}">
                {{ getPaymentTransactionTypeDisplay(t.paymentTransactionType) || 'N/A' }}
              </span>
            </td>
            <td v-show="hidableColumns.paymentState.isVisible" :key="`${t.id}-paymentTransactionStatus-${i}`" class="has-text-align-center">
              <span :class="{'has-text-grey-light': !t.paymentTransactionStatus}">
                {{ getPaymentTransactionStatusDisplay(t.paymentTransactionStatus) || 'N/A' }}
              </span>
            </td>
            <td v-show="hidableColumns.transactionDateTime.isVisible" :key="`${t.id}-transactionDateTime-${i}`">
              <template v-if="t.storeId && getStore(t.storeId)">
                <div class="no-wrap-text">{{ t.transactionDateTime | moment('M/D/YYYY', getStore(t.storeId).ianaTimezoneId) }}</div>
                <div class="no-wrap-text">{{ t.transactionDateTime | moment('h:mm A z', getStore(t.storeId).ianaTimezoneId) }}</div>
              </template>
              <span
                v-else
                v-tippy="{
                  content: 'This transaction is missing a location and timezone',
                  placement: 'left',
                  delay: [150, 0]
                }"
              >
                <div class="no-wrap-text">{{ t.transactionDateTime | moment('M/D/YYYY', null, true) }}</div>
                <div class="no-wrap-text">{{ t.transactionDateTime | moment('h:mm A z', null, true) }}</div>
              </span>
            </td>
            <td v-show="hidableColumns.storeId.isVisible" :key="`${t.id}-store-${i}`">
              <span v-if="t.storeId && getStore(t.storeId)">
                {{ getStore(t.storeId).description || 'N/A' }}
              </span>
              <span v-else class="has-text-grey-light">
                N/A
              </span>
            </td>
            <td v-show="hidableColumns.paymentMethod.isVisible" :key="`${t.id}-paymentMethod-${i}`">
              <span :class="{'has-text-grey-light': !t.paymentMethod}">
                {{ getPaymentMethodDisplay(t.paymentMethod) || 'N/A' }}
              </span>
            </td>
            <td v-show="hidableColumns.paymentGatewayType.isVisible" :key="`${t.id}-paymentGatewayType-${i}`">
              <span :class="{'has-text-grey-light': !t.paymentGatewayType}">
                {{ capitalCase(t.paymentGatewayType) || 'N/A' }}
              </span>
            </td>
            <td v-show="hidableColumns.cardholderName.isVisible" :key="`${t.id}-cardHolderName-${i}`">
              <span :class="{'has-text-grey-light': !t.cardHolderName}">
                {{ capitalCase(t.cardHolderName) || 'N/A' }}
              </span>
            </td>
            <td v-show="hidableColumns.accountNumberLastFour.isVisible" :key="`${t.id}-accountNumberLastFour-${i}`" class="has-text-centered">
              <span v-if="t.accountNumberLastFour">
                {{ `.... ${t.accountNumberLastFour}` }}
              </span>
              <span v-else class="has-text-grey-light">
                N/A
              </span>
            </td>
            <td v-show="hidableColumns.cardType.isVisible" :key="`${t.id}-amount-${i}`" class="has-text-centered">
              <span :class="{'has-text-grey-light': !t.cardType}">
                {{ t.cardType || 'N/A' }}
              </span>
            </td>
            <td :key="`${t.id}--${i}`" class="has-text-centered">
              <span v-if="!t.amount" class="has-text-grey-light">
                N/A
              </span>
              <span v-else>
                {{ t.amount | dollars }}
              </span>
            </td>
          </tr>
        </template>

        <!-- FOOTER -->
        <template #bottom-left>
          <div class="left-side is-flex">
            <page-number-select
              :page="page"
              :per-page="Number(perPage)"
              :total-count="ordersTotalCount"
              @input="handlePageChange"
            />
            <b-field label-for="perPage" label-position="on-border" label="Per Page" class="mar-l mar-none">
              <b-select id="perPage" v-model="perPage">
                <option value="10">10</option>
                <option value="20">20</option>
                <option value="50">50</option>
                <option value="100">100</option>
              </b-select>
            </b-field>
          </div>
        </template>
      </b-table>
    </div>
  </div>
</template>

<script>
  import PaymentTransaction from '@/store/classes/PaymentTransaction';
  import Store from '@/store/classes/Store';
  import scrollIndicatorMixin from '@/mixins/scroll-container-indicator';
  import debounce from 'lodash.debounce';
  import moment from 'moment-timezone';
  import merchantMixin from '@/mixins/merchant';
  import paymentMethods from '@/constants/paymentMethods';
  import capitalCase from '@/helpers/capitalCase';
  import storage from '@/services/storage';
  import timeZones from '@/constants/ianaTimezones';
  import paymentTransactionModal from './payment-transaction-modal.vue';
  import { mapGetters } from 'vuex';


  export default {
    name: 'PaymentTransactionList',

    mixins: [scrollIndicatorMixin, merchantMixin],

    data() {
      return {
        paymentSummaries: [],
        openedTransactionRowIds: [],
        page: 1,
        perPage: 10,
        param: 'merchantReference',
        query: '',
        sortDirection: 'desc',
        dateRange: [],
        storeId: null,
        paymentTransactionType: null,
        paymentMethod: null,
        paymentTransactionStatus: null,
        timezone: 'America/New_York',
        sortOrder: 'TransactionDateTimeDesc',
        focusedDate: null,
        excludeOrderPayments: true,
        paymentTransactionTypes: [
          { value: 'Authorize', display: 'Authorize' },
          { value: 'Capture', display: 'Capture' },
          { value: 'Refund', display: 'Refund' },
          { value: 'Sale', display: 'Sale' },
          { value: 'TipAdjustment', display: 'Tip Adjustment' },
          { value: 'Void', display: 'Void' }
        ],
        paymentTransactionStatusTypes: [
          { value: 'Succeeded', display: 'Success' },
          { value: 'Failed', display: 'Failed' }
        ],
        hidableColumns: {
          merchantReference: { isVisible: true, display: 'Reference #' },
          transactionDateTime: { isVisible: true, display: 'Date' },
          storeId: { isVisible: true, display: 'Location' },
          paymentTransactionType: { isVisible: true, display: 'Type' },
          paymentState: { isVisible: true, display: 'Status' },
          paymentMethod: { isVisible: true, display: 'Method' },
          paymentGatewayType: { isVisible: true, display: 'Gateway' },
          cardholderName: { isVisible: true, display: 'Card Holder' },
          accountNumberLastFour: { isVisible: true, display: 'Card # Last 4' },
          cardType: { isVisible: true, display: 'Card Type' }
        },
        timeZones,
        hasMadeInitialSearch: false
      };
    },

    computed: {
      ...mapGetters('session', ['isLocationBasedUser']),

      merchantPaymentMethods() {
        return this.$_selectedMerchant.allPaymentMethodsForMerchantGatewaysByName;
      },

      isLoadingPaymentTransactions() {
        return PaymentTransaction.$state().searching;
      },

      fetchingId() {
        return PaymentTransaction.$state().fetchingId;
      },


      paymentTransactionMetadata() {
        return PaymentTransaction.$state().paymentTransactionMetadata;
      },

      ordersTotalCount() {
        return this.paymentTransactionMetadata.count;
      },

      stores() {
        return Store.orderByName().get();
      },

      selectedStore() {
        return this.stores.find(s => s.storeId === this.storeId);
      },

      paymentTransactionTypeDisplay() {
        return this.paymentTransactionType
          ? this.getPaymentTransactionTypeDisplay(this.paymentTransactionType)
          : 'All';
      },

      paymentMethodDisplay() {
        return this.paymentMethod
          ? this.getPaymentMethodDisplay(this.paymentMethod)
          : 'All';
      },

      paymentTransactionStatusDisplay() {
        return this.paymentTransactionStatus
          ? this.getPaymentTransactionStatusDisplay(this.paymentTransactionStatus)
          : 'All';
      },

      visibleColumnCount() {
        return this.hidableColumnCount - this.hiddenColumns.length;
      },

      hidableColumnCount() {
        return Object.keys(this.hidableColumns).length;
      },

      hiddenColumns() {
        return this.$clone(Object.values(this.hidableColumns)).filter(c => !c.isVisible);
      },

      isTodayDateRange() {
        return this.dateRange.every(date => date.toDateString() === this.today.toDateString());
      },

      isLastSevenDaysDateRange() {
        const [startDate, endDate] = this.dateRange;

        return (startDate && endDate)
          && startDate.toDateString() === this.sevenDaysAgo.toDateString()
          && endDate.toDateString() === this.today.toDateString();
      },

      isLastThirtyDays() {
        const [startDate, endDate] = this.dateRange;

        return (startDate && endDate)
          && startDate.toDateString() === this.thirtyDaysAgo.toDateString()
          && endDate.toDateString() === this.today.toDateString();
      },

      isLastNinetyDaysRange() {
        const [startDate, endDate] = this.dateRange;

        return (startDate && endDate)
          && startDate.toDateString() === this.ninetyDaysAgo.toDateString()
          && endDate.toDateString() === this.today.toDateString();
      },

      today() {
        return moment.utc(moment().startOf('day')).toDate();
      },

      sevenDaysAgo() {
        return moment.utc(moment().subtract(7, 'd').startOf('day')).toDate();
      },

      thirtyDaysAgo() {
        return moment.utc(moment().subtract(30, 'd').startOf('day')).toDate();
      },

      ninetyDaysAgo() {
        return moment.utc(moment().subtract(90, 'd').startOf('day')).toDate();
      },

      queryInputType() {
        switch (this.param) {
          case 'amount':
            return 'float';
          case 'paymentTransactionId':
          case 'accountNumberLastFour':
          case 'merchantReference':
          case 'cardholderName':
            return 'text';

          default:
            return 'text';
        }
      },

      maskOptions() {
        switch (this.param) {
          case 'accountNumberLastFour':
            return { stripLeadingZeroes: false };

          case 'amount':
            return { numeralDecimalScale: 2 };

          default:
            return null;
        }
      },

      paramRules() {
        switch (this.param) {
          case 'amount':
            return { min_value: 0 };
          default:
            return null;
        }
      },

      storeIdsDefault() {
        // NOTE: there are transactions with no storeId and we DO NOT need to include those
        // in the search results for location-based users
        // So default the 'All' filter to the user's available stores when location based
        // Otherwise, 'All' shows all transactions, including those with no storeId
        if (this.storeId) {
          return [this.storeId];
        }
        return this.isLocationBasedUser ? this.stores.map(s => s.storeId) : null;
      },

      additionalOptionsDisplay() {
        if (!this.excludeOrderPayments) return 'Include Order Payments';
        return 'Select an option...';
      }
    },

    watch: {
      param: 'handleParamChange',
      query: 'debouncedHandleQueryChange',
      storeId: 'handleStoreIdChange',
      paymentTransactionType: 'handlePaymentTransactionTypeChange',
      paymentMethod: 'handlePaymentMethodChange',
      paymentTransactionStatus: 'handlePaymentTransactionStatusChange',
      timezone: 'handleTimezoneChange',
      excludeOrderPayments: 'handleExcludeOrderPaymentsChange',
      hidableColumns: {
        deep: true,
        handler: 'handleHideColumn'
      },
      perPage: 'handlePerPageChange',
      dateRange: {
        deep: true,
        handler: 'handleDateRangeChange'
      }
    },

    mounted() {
      this.onMounted();
    },

    created() {
      this.onCreated();
    },

    methods: {
      moment,
      capitalCase,

      async onCreated() {
        await Store.fetchAll();
        this.dateRange = [this.sevenDaysAgo, this.today];
        this.hidableColumns = {
          ...this.hidableColumns,
          ...storage.local.get('payment-transaction-columns') || {}
        };
      },

      async onMounted() {
        this.$nextTick(() => {
          this.$_initScrollIndicatorSmart({
            contentSelector: '.table',
            autoStyles: false
          });
        });

        PaymentTransaction.resetState();
      },

      async toggleRow({ toggle, row }) {
        if (!this.openedTransactionRowIds.includes(row.id)) {
          const paymentDetails = await this.getPaymentDetails(row.id);
          this.paymentSummaries = this.$clone(this.paymentSummaries).map((summary) => {
            if (summary.id === row.id) {
              summary = { ...summary, ...paymentDetails };
              // NOTE: sometimes CAPI doesn't return us all of the data, so this is a workaround to not blow up
              summary.transactions = paymentDetails.transactions.map(t => new PaymentTransaction(t)).sort((a, b) => (a.transactionDateTime > b.transactionDateTime ? -1 : 1));
            }
            return summary;
          });
        }
        toggle(row);
      },

      setDateRange(range) {
        switch (range) {
          case 'today': {
            this.dateRange = [this.today, this.today];
            break;
          }
          case 'last-seven-days': {
            this.dateRange = [this.sevenDaysAgo, this.today];
            break;
          }
          case 'last-thirty-days': {
            this.dateRange = [this.thirtyDaysAgo, this.today];
            break;
          }
          case 'last-ninety-days': {
            this.dateRange = [this.ninetyDaysAgo, this.today];
            break;
          }
          default:
            break;
        }
      },

      getPaymentMethodDisplay(paymentMethodName) {
        return Object.values(paymentMethods).find(value => value.type === paymentMethodName)?.display || capitalCase(paymentMethodName);
      },

      getPaymentTransactionStatusDisplay(paymentTransactionStatus) {
        return this.paymentTransactionStatusTypes.find(status => status.value === paymentTransactionStatus)?.display || capitalCase(paymentTransactionStatus);
      },

      getPaymentTransactionTypeDisplay(paymentTransactionStatus, isSummaryTransaction) {
        if (isSummaryTransaction) {
          switch (paymentTransactionStatus) {
            case 'Authorized':
              return 'Authorize';
            case 'Captured':
              return 'Capture';
            case 'Refunded':
              return 'Refund';
            case 'Voided':
              return 'Void';
            case 'Unknown':
              return '--';
            default:
              return capitalCase(paymentTransactionStatus);
          }
        }
        return this.paymentTransactionTypes.find(t => t.value === this.paymentTransactionType)?.display || capitalCase(paymentTransactionStatus);
      },

      getStore(storeId) {
        return this.stores.find(s => s.storeId === storeId);
      },

      clearQuery() {
        this.query = '';
      },

      // eslint-disable-next-line
      debouncedHandleQueryChange: debounce(function (query) {
        this.handleQueryChange(query);
      }, 666),

      handleHideColumn(obj) {
        storage.local.set('payment-transaction-columns', obj);
        this.$nextTick(() => {
          this.$_setOverflowAttribute();
        });
      },

      handleDateRangeChange(dateRange, oldVal) {
        if (oldVal.length) {
          this.page = 1;
          this.searchPaymentTransactions({ fromDate: dateRange[0], toDate: dateRange[1] });
          this.focusedDate = null;
          this.$nextTick(() => {
            this.focusedDate = dateRange[1];
          });
        }
      },

      handlePerPageChange(perPage) {
        this.page = 1;
        this.searchPaymentTransactions({ perPage });
      },

      handleParamChange(param) {
        if (this.query) {
          this.page = 1;
          this.query = '';
          this.searchPaymentTransactions({ param });
        }
      },

      handleQueryChange(query) {
        this.page = 1;
        this.searchPaymentTransactions({ query });
      },

      handleStoreIdChange(storeId) {
        const storeIds = storeId ? [storeId] : this.storeIdsDefault;
        this.page = 1;
        this.searchPaymentTransactions({ storeIds });
      },

      handlePaymentTransactionTypeChange(paymentTransactionType) {
        this.page = 1;
        this.searchPaymentTransactions({ paymentTransactionType });
      },

      handlePaymentMethodChange(paymentMethod) {
        this.page = 1;
        this.searchPaymentTransactions({ paymentMethod });
      },

      handlePaymentTransactionStatusChange(paymentTransactionStatus) {
        this.page = 1;
        this.searchPaymentTransactions({ paymentTransactionStatus });
      },

      handleTimezoneChange(timezone) {
        this.page = 1;
        this.searchPaymentTransactions({ timezone });
      },

      handleExcludeOrderPaymentsChange(excludeOrderPayments) {
        this.page = 1;
        this.searchPaymentTransactions({ excludeOrderPayments });
      },

      handleSort(sortField, sortDirection) {
        // SORT PARAMS:
        // TransactionNumber
        // TransactionNumberDesc
        // MerchantReference
        // MerchantReferenceDesc
        // TransactionDateTime
        // TransactionDateTimeDesc
        // Amount
        // AmountDesc
        if (sortDirection === 'desc') {
          this.sortOrder = `${sortField}Desc`;
        }
        else {
          this.sortOrder = sortField;
        }
        this.sortDirection = sortDirection;
        this.page = 1;
        this.searchPaymentTransactions({ sortOrder: this.sortOrder, sortDirection });
      },

      handlePageChange(page) {
        this.page = page;
        this.searchPaymentTransactions({ page });
      },

      async searchPaymentTransactions({
        param = this.param,
        query = this.query,
        page = this.page,
        perPage = this.perPage,
        fromDate = this.dateRange[0],
        toDate = this.dateRange[1],
        storeIds = this.storeIdsDefault,
        sortOrder = this.sortOrder,
        paymentTransactionType = this.paymentTransactionType,
        paymentMethod = this.paymentMethod,
        paymentTransactionStatus = this.paymentTransactionStatus,
        timezone = this.timezone,
        excludeOrderPayments = this.excludeOrderPayments
      } = {}) {
        try {
          const updatedQuery = param === 'cardholderName' && query ? query.replace(/\s+/g, '+') : query;
          this.paymentSummaries = await PaymentTransaction.searchPaymentTransactions({
            page,
            perPage,
            fromDate: fromDate && moment(fromDate).format('YYYY-MM-DD'),
            toDate: toDate && moment(toDate).format('YYYY-MM-DD'),
            storeIds,
            paymentTransactionType,
            paymentMethod,
            paymentTransactionStatus,
            sortOrder,
            timezone,
            excludeOrderPayments,
            [param]: updatedQuery
          });

          if (!this.hasMadeInitialSearch) {
            this.hasMadeInitialSearch = true;
          }
        }
        catch (error) {
          this.$_onRequestError({
            error,
            toastOptions: { message: 'There was an error loading transactions' }
          });
        }
      },

      async getPaymentDetails(id) {
        try {
          return await PaymentTransaction.getPaymentDetails(id);
        }
        catch (error) {
          this.$_onRequestError({
            error,
            toastOptions: { message: 'There was an error loading the transactions' }
          });
        }
      },

      async getPaymentTransactionById(id) {
        try {
          await PaymentTransaction.getPaymentTransactionById(id);
        }
        catch (error) {
          this.$_onRequestError({
            error,
            toastOptions: { message: 'There was an error loading the transaction' }
          });
        }
      },

      async handleTransactionRowClick({ transaction, summary }) {
        await this.getPaymentTransactionById(transaction.id);
        this.$buefy.modal.open({
          parent: this,
          component: paymentTransactionModal,
          hasModalCard: true,
          trapFocus: true,
          canCancel: false,
          customClass: 'auto-width',
          props: {
            transactionId: transaction.id,
            summary
          },
          events: {
            'successful-refund-void-capture': this.onSuccessfulRefundVoidCapture
          }
        });
      },

      onSuccessfulRefundVoidCapture() {
        this.openedTransactionRowIds = [];
        this.$nextTick(() => {
          this.searchPaymentTransactions();
        });
      }
    }
  };
</script>

<style lang="sass" scoped>
  .has-text-align-center
    text-align: center !important

  ::v-deep .control
    margin-right: 0 !important

  .date-picker-input
    position: relative

    &.today-selected
      // hides actual input text and instead displays "Today"
      ::v-deep.dropdown-trigger .input
        color: transparent

      &::after
        content: 'Today'
        color: $grey-darker
        font-size: 1rem
        padding: 7px 40px
        pointer-events: none
        position: absolute
        top: 0
        right: 0
        bottom: 0
        left: 0

  .date-range-shortcuts
    display: grid
    grid-template-columns: 1fr 1fr
    gap: 0.5rem
    grid-gap: 0.5rem

    .button
      font-weight: 700

  ::v-deep
    tr.row-pointer:hover
      cursor: pointer

  .search-section
    display: flex
    flex-direction: column
    gap: $size-small
    flex-wrap: wrap-reverse

    .filters, .search-inputs
      display: flex
      gap: $size-normal
      > *
        margin: 0

    ::v-deep.dropdown-trigger
      .button
        min-width: 7rem
        justify-content: space-between

        .icon
          color: $primary

  .result-count
    margin: $size-small 0
    padding: 0.5rem 0

  .query-input,
  .search-inputs
    flex: 1 1

  ::v-deep
    th.is-sticky
      background: $white !important

    tr:hover
      td.is-sticky
        background: inherit

    .table-wrapper
      position: static

    .is-sticky
      position: relative

    .is-sticky::before,
    .table-container::after
      content: ''
      position: absolute
      top: 0
      opacity: 0
      width: 40px
      pointer-events: none
      transition: opacity 200ms

    .is-sticky::before
      bottom: 0
      left: 100%
      box-shadow: 30px 0px 25px -20px inset rgba(0, 0, 0, 0.125), 9px 0px 10px -10px inset rgba(0, 0, 0, 0.25)

    .table-container::after
      bottom: 73px
      right: 0
      box-shadow: -30px 0px 25px -20px inset rgba(0, 0, 0, 0.125), -9px 0px 10px -10px inset rgba(0, 0, 0, 0.25)

    .overflow-x-custom-left .is-sticky::before
      opacity: 1

    .overflow-x-custom-right .table-container::after
      opacity: 1

    .overflow-x-custom-both
      .is-sticky::before,
      .table-container::after
        opacity: 1

  .left-side
    display: flex
    gap: 1rem

  @media (max-width: $widescreen)
    .query-input
      margin-top: 0 !important
      width: 100%

    .left-side
      justify-content: center
</style>
