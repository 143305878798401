<template>
  <validated-form
    ref="form"
    auto-focus
    form-id="addGiftCardFundsForm"
    @valid-submit="handleSubmit"
  >
    <modal-card
      title="Add Funds to Gift Card"
    >
      <b-message type="is-primary" class="is-compact has-shadow">
        <p>
          How much money would you like to add to gift card {{ showFullGcNumber ? giftCard.cardNumber : `•••• ${giftCard.cardNumber.slice(-4)}` }}?
        </p>
      </b-message>

      <div class="is-flex-column align-center">
        <validated-input
          hide-label
          label="Funding Amount"
          name="fundingAmount"
          :rules="{ min_value: 0.01 }"
          :custom-messages="{ min_value: 'The funding amount must be greater than zero' }"
        >
          <b-numberinput
            v-model="fundingAmount"
            controls-position="compact"
            size="is-medium"
            class="has-five-digits justify-center"
            :min-step="0.01"
            :default-value="1"
          />
        </validated-input>
        <div class="has-text-centered">
          <p class="is-size-5">
            Original Balance:
            <span class="has-text-weight-bold">{{ giftCard.balance | dollars }}</span>
            <template v-if="fundingAmount > 0">
              <b-icon class="is-size-6 mar-x-xs" icon="long-arrow-right" />
              New Balance:
              <span class="has-text-weight-bold has-text-success">{{ newBalance | dollars }}</span>
            </template>
          </p>
        </div>
      </div>

      <template #footer>
        <div class="buttons all-bold">
          <b-button
            rounded
            @click="$_confirmCloseModal({ programmatic: true })"
          >
            Cancel
          </b-button>
          <b-button
            v-tabbable
            rounded
            :disabled="!fundingAmount"
            native-type="submit"
            type="is-primary"
            :loading="isSubmitting"
          >
            Adjust Balance
          </b-button>
        </div>
      </template>
    </modal-card>
  </validated-form>
</template>

<script>
  import confirmModalCloseMixin from '@/mixins/confirm-modal-close';

  import GiftCard from '@/store/classes/GiftCard';

  export default {
    name: 'AddGiftCardFundsModal',

    mixins: [confirmModalCloseMixin],

    props: {
      giftCard: {
        type: Object,
        required: true
      },

      showFullGcNumber: {
        type: Boolean,
        default: false
      }
    },

    data: () => ({
      fundingAmount: 0
    }),

    computed: {
      newBalance() {
        return parseFloat(this.giftCard.balance) + this.fundingAmount;
      },

      isSubmitting() {
        return GiftCard.$state().updating;
      }
    },

    methods: {
      async handleSubmit() {
        try {
          await GiftCard.addFunds({
            giftCardId: this.giftCard.id,
            amount: this.fundingAmount
          });

          this.$_onRequestSuccess({
            toastOptions: {
              message: 'Successfully added funds to gift card'
            },
            options: {
              closeParent: true
            }
          });
        }

        catch (error) {
          this.$_onRequestError({
            error,
            toastOptions: {
              message: 'There was an error adding funds to the gift card'
            }
          });
        }
      }
    }
  };
</script>
