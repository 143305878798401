<template>
  <modal-card
    :title="`Gift Card Transaction #${transaction.giftCardTransactionId || 'N/A'}`"
    modal-card-body-class="is-paddingless"
  >
    <!-- Transaction Info -->
    <b-collapse
      class="pad-md has-border-bottom has-border-grey-lighter"
      animation="slide"
    >
      <div
        slot="trigger"
        slot-scope="props"
        role="button"
        class="section-header"
      >
        <h4 class="subtitle is-5 is-marginless">
          Transaction Info
        </h4>
        <b-icon class="open-indicator" :icon="props.open ? 'angle-down' : 'angle-right'" />
      </div>
      <dl class="details-container mar-t-md">
        <div class="is-flex-wrap dist-y-md mar-b-md justify-between">
          <div class="flex-grow">
            <dt class="has-text-grey">Transaction Type:</dt>
            <dd class="has-text-weight-semibold">
              {{ capitalCase(transaction.transactionType) }}
            </dd>
          </div>
          <div class="right-column">
            <dt class="has-text-grey">Purchaser:</dt>
            <template v-if="gift && gift.sender">
              <dd class="has-text-weight-semibold text-wrap">{{ gift.sender.fullName }}</dd>
              <dd class="text-wrap">{{ gift.sender.email || gift.sender.primarySmsNumber }}</dd>
            </template>
            <template v-else-if="account">
              <dd class="has-text-weight-semibold text-wrap">{{ accountFullName || 'N/A' }}</dd>
              <dd class="text-wrap">{{ account.email || account.primarySmsNumber }}</dd>
            </template>
            <dd v-else class="has-text-weight-semibold">N/A</dd>
          </div>
        </div>
        <div class="is-flex-wrap dist-y-md">
          <div class="flex-grow">
            <dt class="has-text-grey">Timestamp:</dt>
            <dd class="has-text-weight-semibold">
              {{ formatDateTime(transaction.transactionDateTime) }}
            </dd>
          </div>
        </div>
      </dl>
    </b-collapse>

    <!-- Gift Card Info -->
    <b-collapse
      v-if="giftCard"
      class="pad-md has-border-bottom has-border-grey-lighter"
      animation="slide"
    >
      <div
        slot="trigger"
        slot-scope="props"
        role="button"
        class="section-header"
      >
        <h4 class="subtitle is-5 is-marginless">
          Gift Card Info
        </h4>
        <b-icon class="open-indicator" :icon="props.open ? 'angle-down' : 'angle-right'" />
      </div>
      <dl class="details-container mar-t-md">
        <div class="is-flex-wrap dist-y-md mar-b-md">
          <div class="flex-grow">
            <dt class="has-text-grey">Card Number:</dt>
            <dd class="has-text-weight-semibold">
              {{ giftCardNumberDisplay }}
            </dd>
          </div>
          <div v-if="isAutoReload" style="width: 50%">
            <dt class="has-text-grey">Auto-Reload Threshold:</dt>
            <dd class="has-text-weight-semibold">{{ formatCurrency(giftCard.autoReloadThreshold) }}</dd>
          </div>
        </div>
        <div class="is-flex-wrap justify-between">
          <div v-if="showBeginningBalance">
            <dt class="has-text-grey">Beginning<br>Balance:</dt>
            <dd class="has-text-weight-semibold text-wrap">{{ formatCurrency(transaction.beginningBalance) }}</dd>
          </div>
          <div v-if="showAmount">
            <dt class="has-text-grey">Transaction<br>Amount:</dt>
            <dd class="has-text-weight-semibold text-wrap">{{ formatCurrency(transaction.amount) }}</dd>
          </div>
          <div v-if="showEndingBalance" class="mar-r-md">
            <dt class="has-text-grey">Final<br>Balance:</dt>
            <dd class="has-text-weight-semibold text-wrap">{{ formatCurrency(transaction.endingBalance) }}</dd>
          </div>
        </div>
      </dl>
    </b-collapse>

    <!-- Gifting Info (for Gift Purchase only) -->
    <b-collapse
      v-if="isGiftPurchase"
      class="pad-md has-border-bottom has-border-grey-lighter"
      animation="slide"
    >
      <div
        slot="trigger"
        slot-scope="props"
        role="button"
        class="section-header"
      >
        <h4 class="subtitle is-5 is-marginless">
          Gifting Info
        </h4>
        <b-icon class="open-indicator" :icon="props.open ? 'angle-down' : 'angle-right'" />
      </div>
      <dl class="details-container mar-t-md">
        <div class="is-flex-wrap dist-y-md justify-between">
          <div class="left-column">
            <dt class="has-text-grey">Sender:</dt>
            <template v-if="gift && gift.sender">
              <dd class="has-text-weight-semibold text-wrap">{{ gift.sender.fullName }}</dd>
              <dd class="text-wrap">{{ gift.sender.email || gift.sender.primarySmsNumber }}</dd>
            </template>
            <dd v-else class="has-text-weight-semibold">N/A</dd>
          </div>
          <div class="right-column">
            <dt class="has-text-grey">Recipient:</dt>
            <template v-if="gift && gift.recipient">
              <dd class="has-text-weight-semibold text-wrap">{{ gift.recipient.fullName }}</dd>
              <dd class="text-wrap">{{ gift.recipient.email || gift.recipient.primarySmsNumber }}</dd>
            </template>
            <dd v-else class="has-text-weight-semibold">N/A</dd>
          </div>
        </div>
        <div class="is-flex-wrap dist-y-md">
          <div class="flex-grow">
            <dt class="has-text-grey">Delivered On:</dt>
            <dd class="has-text-weight-semibold text-wrap">
              <p v-html="formatDateTime(deliveredOn, true)" />
            </dd>
          </div>
          <div class="right-column">
            <dt class="has-text-grey">Redeemed On:</dt>
            <template v-if="gift && gift.redemptionDate">
              <dd class="has-text-weight-semibold text-wrap">
                <p v-html="formatDateTime(gift.redemptionDate, true)" />
              </dd>
            </template>
            <dd v-else class="has-text-weight-semibold">N/A</dd>
          </div>
        </div>
      </dl>
    </b-collapse>

    <!-- Additional Info -->
    <b-collapse
      v-if="!isLocationBasedUser || $_featurePermissions.ACCOUNT_MANAGEMENT"
      class="pad-md has-border-bottom has-border-grey-lighter"
      animation="slide"
    >
      <b-loading :is-full-page="false" :active="isFetching" />
      <div
        slot="trigger"
        slot-scope="props"
        role="button"
        class="section-header"
      >
        <h4 class="subtitle is-5 is-marginless">
          Additional Info
        </h4>
        <b-icon class="open-indicator" :icon="props.open ? 'angle-down' : 'angle-right'" />
      </div>
      <div class="details-container mar-t-md">
        <b-message
          v-if="!isLocationBasedUser && transaction.transactionType === 'Purchase'"
          type="is-info"
          class="is-compact has-shadow mar-b"
          size="is-small"
          has-icon
          icon="info-square"
        >
          Manage gift card purchase <b>refunds</b> by clicking on <b>View Payment Details</b>
        </b-message>
        <div class="mar-b-sm">
          <b-button
            v-if="!isLocationBasedUser"
            :disabled="!paymentTransaction"
            :type="`${!paymentTransaction ? 'is-disabled' : 'is-primary is-light'}`"
            icon-left="credit-card"
            class="mar-r-sm"
            @click="openPaymentTransactionModal"
          >
            View Payment Details
          </b-button>

          <b-button
            v-if="$_featurePermissions.ACCOUNT_MANAGEMENT"
            :disabled="!account"
            :type="`${!account ? 'is-disabled' : 'is-primary is-light'}`"
            icon-right="external-link-alt"
            tag="a"
            :href="`${account ? `/registered-guests/${account.accountId}/payment-methods` : '#'}`"
            target="_blank"
            rel="noopener noreferrer"
          >
            View Account Gift Cards
          </b-button>
        </div>
      </div>
    </b-collapse>

    <!-- Admin Details -->
    <b-collapse
      v-if="isCardfreeAdmin"
      class="pad-md"
      animation="slide"
    >
      <div
        slot="trigger"
        slot-scope="props"
        role="button"
        class="section-header"
      >
        <h4 class="subtitle is-5 is-marginless">
          Admin Details
        </h4>
        <b-icon class="open-indicator" :icon="props.open ? 'angle-down' : 'angle-right'" />
      </div>
      <dl class="details-container mar-t-md">
        <div
          v-for="(info, index) in adminDetails"
          :key="index"
          class="is-flex justify-between align-baseline mar-b-xs"
        >
          <dt class="has-text-grey">{{ info.label }}:</dt>
          <dd class="has-text-weight-semibold text-wrap mar-l-sm">
            {{ info.value }}
          </dd>
        </div>
      </dl>
    </b-collapse>

    <template #footer>
      <div class="buttons all-bold">
        <b-button rounded @click="$_confirmCloseModal({ programmatic: true })">
          Close
        </b-button>
      </div>
    </template>
  </modal-card>
</template>

<script>
  import moment from 'moment-timezone';
  import { mapGetters } from 'vuex';

  import GiftCard from '@/store/classes/GiftCard';

  import AlertModal from '@/components/globals/alert-modal.vue';
  import PaymentTransactionModal from '@/components/pages/payment-transactions/payment-transaction-modal.vue';

  import capitalCase from '@/helpers/capitalCase';

  import confirmModalCloseMixin from '@/mixins/confirm-modal-close';
  import featurePermissionsMixin from '@/mixins/featurePermissions';
  import merchantMixin from '@/mixins/merchant';

  import PaymentTransaction from '@/store/classes/PaymentTransaction';

  export default {
    name: 'GiftCardTransactionModal',

    mixins: [confirmModalCloseMixin, featurePermissionsMixin, merchantMixin],

    props: {
      transaction: {
        type: Object,
        required: true
      },
      giftCardId: {
        type: String,
        default: null
      }
    },

    computed: {
      ...mapGetters('session', ['isCardfreeAdmin', 'isLocationBasedUser']),

      showFullGcNumber() {
        return this.$_featurePermissions?.SHOW_FULL_GC_NUMBER;
      },

      giftCardNumberDisplay() {
        return this.showFullGcNumber ? this.giftCard?.cardNumber : `•••• ${this.giftCard.cardNumberLastFour}`;
      },

      adminDetails() {
        return [
          { label: 'Payment Transaction ID', value: this.paymentTransaction?.id || 'N/A' },
          { label: 'Payment Type', value: this.paymentTransaction?.paymentTransactionType || 'N/A' },
          { label: 'Result Code', value: this.paymentTransaction?.gatewayResultCode || 'N/A' },
          { label: 'Result Message', value: this.paymentTransaction?.gatewayResultMessage || 'N/A' },
          { label: 'Client Correlation ID', value: this.paymentTransaction?.clientCorrelationId || 'N/A' }
        ];
      },

      account() {
        return this.transaction.account;
      },

      gift() {
        return this.transaction.gift;
      },

      giftCard() {
        return this.transaction.giftCard;
      },

      paymentTransaction() {
        return this.transaction.paymentTransaction;
      },

      accountFullName() {
        return this.account.firstName ? `${this.account.firstName} ${this.account.lastName}` : null;
      },

      isFetching() {
        return PaymentTransaction.$state().fetchingId === this.paymentTransaction?.id;
      },

      isAutoReload() {
        return this.transaction.transactionType === 'AutoReload';
      },

      isGiftPurchase() {
        return this.transaction.transactionType === 'GiftPurchase';
      },

      deliveredOn() {
        return this.gift?.deliveredDate || this.gift?.deliveryDate || this.gift?.createdDate;
      },

      showAmount() {
        return [
          'Purchase',
          'Reload',
          'AutoReload',
          'GiftPurchase',
          'MergeBalance'
        ].includes(this.transaction.transactionType);
      },

      showBeginningBalance() {
        return [
          'Reload',
          'AutoReload',
          'MergeBalance'
        ].includes(this.transaction.transactionType);
      },

      showEndingBalance() {
        return [
          'BalanceInquiry',
          'Reload',
          'AutoReload',
          'MergeBalance'
        ].includes(this.transaction.transactionType);
      }
    },

    methods: {
      capitalCase,

      async getPaymentTransactionById(id) {
        try {
          await PaymentTransaction.getPaymentTransactionById(id);
        }
        catch (error) {
          this.$_onRequestError({
            error,
            toastOptions: { message: 'There was an error loading the transaction' }
          });
        }
      },

      async getPaymentDetails(id) {
        try {
          return await PaymentTransaction.getPaymentDetails(id);
        }
        catch (error) {
          this.$_onRequestError({
            error,
            toastOptions: { message: 'There was an error loading the transaction details' }
          });
        }
      },

      async openPaymentTransactionModal() {
        const [summary] = await Promise.all([
          this.getPaymentDetails(this.paymentTransaction.id),
          this.getPaymentTransactionById(this.paymentTransaction.id)
        ]);

        this.$buefy.modal.open({
          parent: this,
          component: PaymentTransactionModal,
          hasModalCard: true,
          trapFocus: true,
          canCancel: false,
          customClass: 'auto-width',
          props: {
            transactionId: this.paymentTransaction.id,
            summary
          },
          events: {
            // This event is already fired from the PaymentTransactionModal when a refund is
            // successful. We need an event to key off of to prompt a user to unregister the
            // gift card after refunding the purchase, so we'll use the existing one as there
            // is no way to "know" in that modal if the transaction is for a gift card purchase
            // other than it being leveraged by this modal.
            'successful-refund-void-capture': this.confirmDeactivateGiftCard
          }
        });
      },

      formatDateTime(dateTime, includeLineBreak = false) {
        const date = moment(dateTime);
        const timezone = this.$_selectedMerchant?.defaultUserTimezone || 'UTC';
        return date.tz(timezone)
          .format(`MMMM D, YYYY${includeLineBreak ? '<br>' : ' '}@ h:mm A z`);
      },

      formatCurrency(amount) {
        if (amount == null) return 'N/A';
        const currencyCode = this.transaction.currencyCode || this.giftCard?.currencyCode || 'USD';
        return new Intl.NumberFormat('en-US', { style: 'currency', currency: currencyCode }).format(amount);
      },

      openTransactionJSON() {
        const file = new Blob(
          [JSON.stringify(this.transaction, null, 2)],
          { type: 'application/json' }
        );
        const fileURL = URL.createObjectURL(file);
        window.open(fileURL);
      },

      confirmDeactivateGiftCard(giftCard) {
        const message = `
          You just refunded the purchase of Gift Card ${this.giftCardNumberDisplay}.
          Would you also like to deactivate that card now? This will take the card's
          balance to $0 and take it off the payment processor.
        `;
        this.$buefy.modal.open({
          parent: this,
          component: AlertModal,
          hasModalCard: true,
          trapFocus: true,
          canCancel: ['escape', 'outside'],
          customClass: 'auto-width',
          props: {
            title: 'Deactivate Gift Card',
            message,
            secondaryMessage: 'Are you sure you would like to proceed?',
            horizontal: true,
            showCloseButton: false,
            icon: 'bolt',
            pack: 'far',
            type: 'is-danger',
            buttons: [
              { text: 'Cancel' },
              {
                text: 'Deactivate',
                primary: true,
                onClick: () => this.deactivateGiftCard(giftCard)
              }
            ]
          }
        });
      },

      async deactivateGiftCard() {
        try {
          await GiftCard.updateActiveStatus({ giftCardId: this.transaction.giftCardId, action: 'deactivate' });

          this.$_onRequestSuccess({
            toastOptions: { message: `Successfully deactivated gift card ${this.giftCardNumberDisplay}` }
          });
        }

        catch (error) {
          this.$_onRequestError({
            error,
            toastOptions: { message: `There was an error deactivating gift card ${this.giftCardNumberDisplay}` }
          });
        }
      }
    }
  };
</script>

<style lang='sass' scoped>
  ::v-deep
    .button
      span
        display: flex
        align-items: center
        justify-content: center

  .modal-card-body
    > *:nth-child(even)
      background: $white-bis

    > *:last-child
      margin-bottom: 0

  .section-header
    display: flex
    align-items: center
    justify-content: space-between

    > *
      color: $grey-dark
      transition: 100ms

    &:hover > *
      color: $primary

  .left-column
    min-width: 200px
    width: 40%
    max-width: 260px


  .right-column
    min-width: 200px
    width: 50%
    max-width: 260px
    margin-right: 0.2rem

  .text-wrap
    white-space: normal
    word-break: break-word
    overflow-wrap: break-word
</style>
