<template>
  <modal-card :title="`Add ${criteriaType} Criteria` | capitalize">
    <div class="is-grid col-2 gap">
      <check-button
        v-for="criteria in availableCriteria"
        :key="criteria.criteriaKey"
        v-model="selectedCriteriaKeys"
        :native-value="criteria.defaultCriteriaKey"
        left-label
        :icon="criteria.icon"
        :icon-pack="criteria.iconPack"
      >
        {{ criteria.display }}
      </check-button>
    </div>
    <template #footer>
      <div class="buttons all-bold">
        <b-button rounded @click="$_confirmCloseModal({ programmatic: true })">
          Cancel
        </b-button>
        <b-button
          v-tabbable
          rounded
          type="is-primary"
          data-test-id="submit-add-criteria-button"
          :disabled="!selectedCriteriaKeys.length"
          @click="addCriteria"
        >
          Add Criteria
        </b-button>
      </div>
    </template>
  </modal-card>
</template>

<script>
  import confirmModalCloseMixin from '@/mixins/confirm-modal-close';

  export default {
    name: 'AddCriteriaModal',

    mixins: [confirmModalCloseMixin],

    props: {
      criteriaType: {
        type: String,
        required: true
      },

      availableCriteria: {
        type: Array,
        required: true
      }
    },

    data() {
      return {
        selectedCriteriaKeys: []
      };
    },

    methods: {
      addCriteria() {
        const selectedCriteria = this.availableCriteria.filter(criteria => this.selectedCriteriaKeys.includes(criteria.defaultCriteriaKey));
        this.$emit('add-selected-criteria', selectedCriteria);
        this.$parent.close();
      }
    }
  };
</script>
