<template>
  <div class="tabs-page">
    <div class="hero is-textured-dark is-primary" :class="`${paramCase(title, {stripRegexp: /[^A-Z]/gi})}-hero-tabs`">

      <template v-if="showHero">
        <div class="hero-body has-text-centered-mobile">
          <div :class="!isFullWidth && 'container'">
            <template v-if="hasSlot('header-action')">
              <div class="level">
                <div class="level-left">
                  <div>
                    <slot name="above-title-action" />
                    <h1 class="title is-2">{{ title }}</h1>
                    <h2 v-if="subtitle" class="subtitle has-text-weight-semibold">{{ subtitle }}</h2>
                  </div>
                </div>
                <div class="level-right">
                  <slot name="header-action" />
                </div>
              </div>
            </template>

            <template v-else>
              <slot name="above-title-action" />
              <h1 class="title is-2">{{ title }}</h1>
              <h2 v-if="subtitle" class="subtitle has-text-weight-semibold">{{ subtitle }}</h2>
            </template>
          </div>
        </div>
      </template>

      <div
        v-show="showTabs"
        :class="['hero-foot', { 'mar-t-lg': !showHero }]"
      >
        <div :class="!isFullWidth && 'container'">
          <div ref="scrollTabsContainer" class="tabs is-boxed" :class="`overflow-${overflowDirection}`">
            <b-icon icon="angle-left" class="overflow-indicator left" @click.native="shiftTabs('left')" />
            <ul ref="scrollTabsContent">
              <li
                v-for="tab in filteredTabs"
                :key="tab.name"
                :class="{
                  'is-active': activeTab.name === tab.name,
                  'is-disabled': tab.isDisabled,
                }"
              >
                <router-link
                  :ref="`tab-${tab.name}`"
                  tabindex="0"
                  class="tab"
                  :to="{ params: { tabName: tab.name } }"
                  :event="tab.isDisabled ? '' : 'click'"
                >
                  <b-icon
                    v-if="tab.icon && tab.iconPosition === 'left'"
                    :icon="tab.icon"
                    :size="tab.iconSize || 'is-small'"
                    :pack="tab.iconPack || 'fas'"
                  />
                  <span>{{ tab.display }}</span>
                  <b-icon
                    v-if="tab.icon && tab.iconPosition === 'right'"
                    :icon="tab.icon"
                    :size="tab.iconSize || 'is-small'"
                    :pack="tab.iconPack || 'fas'"
                  />
                </router-link>
              </li>
            </ul>
            <b-icon icon="angle-right" class="overflow-indicator right" @click.native="shiftTabs('right')" />
          </div>
        </div>
      </div>
    </div>

    <div class="section">
      <div :class="!isFullWidth && 'container'">
        <transition name="tab-change">
          <keep-alive v-if="keepAlive">
            <component :is="activeTab.component" :key="activeTab.name" v-bind="activeTab.props" />
          </keep-alive>
          <component :is="activeTab.component" v-else :key="activeTab.name" v-bind="activeTab.props" />
        </transition>
      </div>
    </div>
  </div>
</template>

<script>
  import { paramCase } from 'change-case';


  export default {
    name: 'TabsPage',

    metaInfo() {
      return {
        title: this.activeTab.display
      };
    },

    props: {
      keepAlive: {
        type: Boolean,
        default: true
      },

      showSingleTab: {
        type: Boolean,
        default: false
      },

      subtitle: {
        type: String,
        default: null
      },

      /*  example tab object:

      {
        display: 'Example Tab Name',  <- REQUIRED: the text displayed in the tab
        name: 'example-tab-name',     <- REQUIRED: the string used for the route param
        component: exampleComponent,  <- REQUIRED: the component to be rendered when the tab is active
        isHidden: Boolean             <- OPTIONAL: an expression that evaluates to a boolean to detirmine if the tab should be rendered or not
        isDisabled: Boolean           <- OPTIONAL: indicates if the tab should be styled as disabled and made unclickable
        icon: 'utensils',             <- OPTIONAL: icon name to show next to tab name (default left)
        iconPack: 'icon pack name'    <- OPTIONAL: icon pack (default to fas)
        iconPosition: 'right'         <- OPTIONAL: icon position (required if icon is present), options include: 'right'/'left'
        iconSize: 'is-medium'         <- OPTIONAL: icon size (default to 'is-small')
        props: {                      <- OPTIONAL: props bound to the rendered component
          propOne: true,
          propTwo: 'example prop',
          propThree: ['an', 'array', 'of', 'stuff']
        }
      }

      */
      tabs: {
        type: Array,
        required: true
      },

      title: {
        type: String,
        required: true
      },

      isFullWidth: {
        type: Boolean,
        default: false
      },

      showHero: {
        type: Boolean,
        default: true
      }
    },

    data() {
      return {
        activeTabIndex: 0,
        overflowDirection: 'none'
      };
    },

    computed: {
      filteredTabs() {
        return this.tabs.filter(tab => !tab.isHidden);
      },

      activeTab() {
        return this.filteredTabs[this.activeTabIndex] || { display: '', name: '' };
      },

      showTabs() {
        return this.showSingleTab || this.filteredTabs.length > 1;
      }
    },

    watch: {
      $route: { handler: 'handleParamChange', immediate: true },
      activeTabIndex: 'focusTab',
      filteredTabs: 'handleFilteredTabsChange'
    },

    created() {
      this.onCreated();
    },

    mounted() {
      this.onMounted();
    },

    methods: {
      paramCase,

      onCreated() {
        if (!this.$route.params.tabName && (this.tabs.length > 1 || this.showSingleTab)) {
          this.$router.replace({
            params: { tabName: this.activeTab.name },
            query: { ...this.$route.query }
          });
        }
      },

      onMounted() {
        this.initScrollBehavior();
      },

      handleFilteredTabsChange(newTabs, oldTabs) {
        if (newTabs.length !== oldTabs.length) {
          const tabName = this.$route.params.tabName;
          const index = newTabs.findIndex(tab => tab.name === tabName);
          this.activeTabIndex = index > 0 ? index : 0;
        }
      },

      focusTab() {
        const activeTabName = this.activeTab?.name;
        const activeTabElement = this.$refs[`tab-${activeTabName}`]?.[0]?.$el;

        if (activeTabElement) {
          activeTabElement.focus();
          this.scrollTabIntoView(activeTabElement);
        }
      },

      handleParamChange(to) {
        const newActiveTabIndex = this.filteredTabs.findIndex(({ name }) => name === to.params.tabName);
        const noTabFound = newActiveTabIndex === -1;
        this.activeTabIndex = noTabFound ? 0 : newActiveTabIndex;
      },

      initScrollBehavior() { // TEST_EVENTUALLY ?
        const { scrollTabsContainer, scrollTabsContent } = this.$refs;
        this.setOverflowAttribute(scrollTabsContent, scrollTabsContainer);

        let ticking = false;
        scrollTabsContainer.addEventListener('scroll', () => {
          if (!ticking) {
            window.requestAnimationFrame(() => {
              this.setOverflowAttribute(scrollTabsContent, scrollTabsContainer);
              ticking = false;
            });
          }
          ticking = true;
        });
      },

      scrollTabIntoView(tab) { // TEST_EVENTUALLY ?
        const { scrollTabsContainer } = this.$refs;

        const containerWidth = scrollTabsContainer.clientWidth;
        const padding = 48;

        const left = Math.ceil(tab.getBoundingClientRect().left);
        const right = Math.ceil(tab.getBoundingClientRect().right);

        const isOffLeft = left - padding < 0;
        const isOffRight = right + padding > containerWidth;

        let amountOff;

        if (isOffLeft) {
          amountOff = scrollTabsContainer.scrollLeft - padding + left;
        }
        if (isOffRight) {
          amountOff = scrollTabsContainer.scrollLeft + padding + right - containerWidth;
        }
        scrollTabsContainer.scroll({ behavior: 'smooth', left: amountOff });
      },

      setOverflowAttribute(content, container) { // TEST_EVENTUALLY ?
        const containerMetrics = container.getBoundingClientRect();
        const containerMetricsRight = Math.floor(containerMetrics.right);
        const containerMetricsLeft = Math.floor(containerMetrics.left);

        const contentMetrics = content.getBoundingClientRect();
        const contentMetricsRight = Math.floor(contentMetrics.right);
        const contentMetricsLeft = Math.floor(contentMetrics.left);

        const overflowLeft = containerMetricsLeft > contentMetricsLeft;
        const overflowRight = containerMetricsRight < contentMetricsRight;

        if (overflowLeft && overflowRight) {
          this.overflowDirection = 'both';
        }
        else if (overflowLeft) {
          this.overflowDirection = 'left';
        }
        else if (overflowRight) {
          this.overflowDirection = 'right';
        }
        else {
          this.overflowDirection = 'none';
        }
      },

      shiftTabs(direction) { // TEST_EVENTUALLY ?
        const { scrollTabsContainer } = this.$refs;
        const amount = direction === 'left'
          ? scrollTabsContainer.scrollLeft - scrollTabsContainer.clientWidth
          : scrollTabsContainer.scrollLeft + scrollTabsContainer.clientWidth;

        scrollTabsContainer.scroll({ left: amount, behavior: 'smooth' });
      },

      hasSlot(slotName) {
        return Boolean(this.$slots[slotName]);
      }
    }
  };
</script>

<style lang="sass" scoped>
  .hero.is-primary
    // fix Safari bug with active tab text color within hero
    .tabs.is-boxed
      .is-active .tab
        color: $primary !important

  .tabs-page
    display: flex
    flex-direction: column
    height: 100%

    > .section
      position: relative
      flex: 1

      > .container
        position: static
        height: 100%

  .tab:focus
    outline: none
    background-color: rgba(10, 10, 10, 0.1)

  .overflow-indicator
    position: absolute
    height: 100%
    z-index: 21
    display: none
    transition: transform 200ms
    padding: 0 1.5rem !important
    margin: 0 !important
    text-shadow: 0 2px 6px rgba(#000, 0.4)
    &:hover
      cursor: pointer
    &:active
      transform: scale(1.2)
    &.left
      left: 0
    &.right
      right: 0

  .tabs
    &::before, &::after
      pointer-events: none
      transition: opacity 200ms
      z-index: 20
      content: ''
      position: absolute
      top: 0
      bottom: 0
      width: 2.5rem
      opacity: 0

    &.overflow-both,
    &.overflow-left
      .overflow-indicator.left
        display: flex
      &::before
        opacity: 1

    &.overflow-both,
    &.overflow-right
      .overflow-indicator.right
        display: flex
      &::after
        opacity: 1

    &::before
      left: 0
      background: linear-gradient( to left, rgba($primary, 0), $primary 80%)
    &::after
      right: 0
      background: linear-gradient( to right, rgba($primary, 0), $primary 80%)

  @media (min-width: $desktop)
    .hero-foot
      padding: 0 1.5rem
</style>
