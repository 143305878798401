import logger from '@/services/logger';
import asyncPipe from '@/helpers/async-pipe';

export default {
  namespaced: true,

  state: {
    invalidForms: [], // NOTE: Contains any invalid forms
    isSubmittingForms: false,
    formChanged: false,
    submitStatus: {
      success: null,
      pending: null,
      error: null
    },
    isSynchronous: true, // NOTE: default to true, explicitly set to false in component if order of requests does not matter
    validationHandlers: [], // NOTE: Contains the form validation methods
    submitHandlers: [], // NOTE: Contains the form submit methods
    argToPassOnSuccess: null,
    changedFormIds: []
  },

  actions: {
    async submitForms({ commit, state, dispatch }) {
      try {
        commit('SET_SUBMITTING_STATE', true);
        commit('SET_SUBMIT_STATUS', { type: 'pending', status: true });
        const { isSynchronous, submitHandlers, changedFormIds } = state;

        if (isSynchronous && submitHandlers.length > 1) {
          const submitHandlersByChangedForm = submitHandlers
            .filter(sh => changedFormIds.includes(sh.formId))
            .map(sh => sh.handleSubmit);

          if (submitHandlersByChangedForm.length) {
            const firstRequest = submitHandlersByChangedForm[0];
            const firstResponse = await firstRequest();

            if (submitHandlersByChangedForm.length > 1) {
              const otherRequests = submitHandlersByChangedForm.slice(1);
              const response = await asyncPipe(otherRequests)(firstResponse);
              commit('SET_ARG_TO_PASS_ON_SUCCESS', response);
            }
            else {
              commit('SET_ARG_TO_PASS_ON_SUCCESS', firstResponse);
            }
          }
        }

        else if (submitHandlers.length === 1) {
          const response = await submitHandlers[0]?.handleSubmit();
          commit('SET_ARG_TO_PASS_ON_SUCCESS', response);
        }

        else {
          await Promise.all(submitHandlers.map(handler => handler.handleSubmit()));
        }

        commit('SET_SUBMIT_STATUS', { type: 'success', status: true });

        dispatch('clearFormStore');
        dispatch('setFormChanged', { hasChanged: false });
      }
      catch (error) {
        commit('SET_SUBMIT_STATUS', { type: 'pending', status: false });
        commit('SET_SUBMIT_STATUS', { type: 'error', status: true });
        logger.error(error);
      }
      finally {
        commit('SET_SUBMITTING_STATE', false);
      }
    },

    setArgToPassOnSuccess({ commit }) {
      commit('SET_ARG_TO_PASS_ON_SUCCESS');
    },

    setFormChanged({ commit, state }, { hasChanged, formId }) {
      commit('SET_FORM_CHANGED', hasChanged);

      if (formId) {
        // NOTE: Only add formId to changedFormIds if it is not already in the array since this is called on every input change
        const formIsNotSet = !state.changedFormIds.includes(formId);
        if (formIsNotSet) {
          commit('SET_CHANGED_FORM_IDS', formId);
        }
      }
    },

    clearChangedFormIds({ commit }) {
      commit('CLEAR_CHANGED_FORM_IDS');
    },

    resetSubmitStatus({ commit }) {
      commit('RESET_SUBMIT_STATUS');
    },

    addSubmitHandler({ commit }, submitHandler) {
      commit('ADD_SUBMIT_HANDLER', submitHandler);
    },

    setSynchronous({ commit }, isSynchronous) {
      commit('SET_SYNCHRONOUS', isSynchronous);
    },

    addValidationHandler({ commit }, validationHandler) {
      commit('ADD_VALIDATION_HANDLER', validationHandler);
    },

    clearFormStore({ commit }) {
      commit('CLEAR_VALIDATION_HANDLERS');
      commit('CLEAR_SUBMIT_HANDLERS');
      commit('SET_SYNCHRONOUS', true);
      commit('SET_INVALID_FORMS', []);
    },


    async validateForms({ commit, dispatch, state }) {
      const { validationHandlers } = state;
      const promises = validationHandlers.map(h => h.validate());
      const validationResponses = await Promise.allSettled(promises);

      const groupedResponses = validationResponses.reduce((acc, response, index) => {
        if (response.status === 'fulfilled') {
          acc.validForms.push(validationHandlers[index]);
        }
        if (response.status === 'rejected') {
          acc.invalidForms.push(validationHandlers[index]);
        }

        return acc;
      }, {
        validForms: [],
        invalidForms: []
      });

      const { validForms, invalidForms } = groupedResponses;

      if (invalidForms.length) {
        commit('SET_INVALID_FORMS', invalidForms.map(invalidForm => invalidForm.formRef));
        invalidForms[0].focusFirstInvalid();
      }

      else {
        validForms.forEach((vf) => {
          vf.handleSuccessfulValidation();
        });
        commit('SET_INVALID_FORMS', []);
        dispatch('submitForms');
      }
    }
  },

  mutations: {
    SET_FORM_CHANGED(state, hasChanged) {
      state.formChanged = hasChanged;
    },

    CLEAR_CHANGED_FORM_IDS(state) {
      state.changedFormIds = [];
    },

    SET_CHANGED_FORM_IDS(state, changedFormId) {
      state.changedFormIds = [...state.changedFormIds, changedFormId];
    },

    SET_ARG_TO_PASS_ON_SUCCESS(state, argToPassOnSuccess) {
      state.argToPassOnSuccess = argToPassOnSuccess;
    },

    SET_SUBMITTING_STATE(state, isSubmittingForms) {
      state.isSubmittingForms = isSubmittingForms;
    },

    SET_SUBMIT_STATUS(state, { type, status }) {
      state.submitStatus[type] = status;
    },

    SET_SYNCHRONOUS(state, isSynchronous) {
      state.isSynchronous = isSynchronous;
    },

    CLEAR_VALIDATION_HANDLERS(state) {
      state.validationHandlers = [];
    },

    CLEAR_SUBMIT_HANDLERS(state) {
      state.submitHandlers = [];
    },

    RESET_SUBMIT_STATUS(state) {
      state.submitStatus = {
        success: null,
        pending: null,
        error: null
      };
    },

    SET_INVALID_FORMS(state, invalidForms) {
      state.invalidForms = invalidForms;
    },

    ADD_SUBMIT_HANDLER(state, handler) {
      state.submitHandlers = [...state.submitHandlers, handler];
    },

    ADD_VALIDATION_HANDLER(state, handler) {
      state.validationHandlers = [...state.validationHandlers, handler];
    }
  }
};
