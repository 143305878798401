<template>
  <div class="is-grid gap col-2">
    <div>
      <validation-provider
        v-slot="{ errors }"
        name="discountedCategories"
        rules="required"
      >
        <b-checkbox :value="selectedResources.length >= 1 || null" :class="['is-hidden', { 'invalid': errors.length }]" />
        <b-field
          :message="errors.length ? 'You must have at least 1 category selected' : ''"
          :type="errors.length ? 'is-danger' : ''"
        >
          <menu-search-input
            :read-only="readOnly"
            resource-type="menuCategories"
            :selected-resources="selectedResources"
            multiple
            @add-resource="$emit('add-resource', { type: 'categoryObjects', resource: $event})"
            @remove-resource="$emit('remove-resource', { type: 'categoryObjects', resource: $event})"
          />
        </b-field>
      </validation-provider>
      <div class="mar-t-lg">
        <excluded-item-attributes-dropdown
          v-model="value.excludedItemAttributes"
          :read-only="readOnly"
          form-name="categoryOffer"
        />
        <modifier-restrictions-search
          v-if="enableModifierRestrictions"
          :read-only="readOnly"
          :selected-resources="selectedResources"
          is-category-search
          :required-modifications="requiredModifications"
          @update-required-modifications="$emit('update-required-modifications', $event)"
        />
      </div>
    </div>
    <div class="is-flex-wrap start-aligned align-content-start gap">
      <div
        v-for="category in selectedResources"
        :key="category.id"
        class="tag is-primary is-light auto-height mar-none pad-y-xs pad-x-sm"
      >
        <div>
          <p class="has-text-weight-bold">{{ category.displayName }}</p>
          <p class="has-text-primary-dark">{{ category.description }}</p>
        </div>
        <button
          v-if="!readOnly"
          type="button"
          class="delete mar-none mar-l-sm has-background-primary-dark has-hover-background-primary-medium"
          @click="$emit('remove-resource', { type: 'categoryObjects', resource: category })"
        />
      </div>
      <div
        v-for="resourceId in missingResources"
        :key="resourceId"
        class="tag is-danger is-flex-align-center is-light auto-height mar-none pad-y-sm pad-x-sm"
      >
        <div>
          <p
            v-tippy="{
              content: 'This menu resource cannot be found',
              maxWidth: 250,
              delay: [150, 0]
            }"
            class="align-self-start has-text-weight-bold"
          >
            Unknown menu resource
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'CategoryOfferCard',

    props: {
      selectedResources: {
        type: Array,
        default: () => []
      },

      requiredModifications: {
        type: Array,
        default: () => []
      },

      readOnly: {
        type: Boolean,
        required: true
      },

      value: {
        type: Object,
        required: true
      },

      enableModifierRestrictions: {
        type: Boolean,
        required: true
      },

      missingResources: {
        type: Array,
        default: () => []
      }
    }
  };
</script>
