<template>
  <div
    class="is-grid col-2 pad-sm"
    :class="{ 'has-border-bottom has-border-grey-lighter': !hideBottomBorder }"
    data-test-class="review-row"
  >
    <p class="title is-6 mar-none" data-test-id="title">
      Customer Must Visit Location{{ stores.length ? 's' : '' }}
    </p>
    <div>
      <p
        v-if="allStoresSelected || stores.length === 0"
        class="subtitle is-6 mar-none"
        data-test-id="subtitle"
      >
        {{ allStoresSelected ? 'All Locations' : 'No locations selected yet' }}
      </p>
      <div v-else class="is-flex-wrap gap-xs">
        <b-tag
          v-for="store in stores"
          :key="store.id"
        >
          {{ store.description }}
        </b-tag>
      </div>
      <div
        v-if="allowActiveOrderAtAnyTargetLocation"
        class="mar-t-sm"
      >
        Allow guest with a pending order to earn
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'LocationRow',

    props: {
      stores: {
        type: Array,
        default: () => []
      },

      allStoresSelected: {
        type: Boolean,
        default: false
      },

      hideBottomBorder: {
        type: Boolean,
        default: false
      },

      allowActiveOrderAtAnyTargetLocation: {
        type: Boolean,
        default: false
      }
    }
  };
</script>
