<template>
  <div data-test-id="earn-rule-offer-inputs">
    <validation-provider
      v-slot="{ errors }"
      :rules="{ required: !awardPolicyOffer || !offerIsValidForTimeFrame }"
      name="awardPolicyOffer"
      :custom-messages="{ required: errorMessage }"
    >
      <template v-if="awardPolicyOffer">
        <b-checkbox
          :class="['is-hidden', { 'invalid': errors.length }]"
          :value="offerIsValidForTimeFrame || null"
        />
        <div class="is-flex align-center">
          <b-field
            :type="{ 'is-danger': !!errors.length }"
            :message="errors | capitalize"
          >
            <offer-preview style="max-width: 500px" :offer="awardPolicyOffer" />
          </b-field>
          <b-button
            v-if="!readOnly"
            class="mar-l-lg"
            icon-left="minus"
            outlined
            size="is-small"
            type="is-danger is-light"
            @click="updateOffer(null)"
          />
        </div>
      </template>
      <template v-else>
        <b-checkbox
          :class="['is-hidden', { 'invalid': errors.length }]"
          :value="!!awardPolicyOffer || null"
        />
        <b-field
          :type="{ 'is-danger': !!errors.length }"
          :message="errors"
        >
          <b-button
            :disabled="!allOffers.length"
            type="is-primary is-light"
            icon-left="plus"
            @click="openAddOfferModal"
          >
            Offer
          </b-button>
        </b-field>
      </template>
    </validation-provider>
  </div>
</template>

<script>
  import moment from 'moment-timezone';

  import OfferPreview from '@/components/pages/campaigns/components/offer-preview.vue';
  import SearchSelectModal from '@/components/globals/search-select-modal.vue';

  import { offerStates } from '@/constants/offers';

  import Offer from '@/store/classes/Offer';

  import merchantMixin from '@/mixins/merchant';

  export default {
    name: 'EarnRuleOfferInputs',

    components: { OfferPreview },

    mixins: [merchantMixin],

    props: {
      value: {
        type: Object,
        required: true
      },
      readOnly: {
        type: Boolean,
        default: false
      }
    },

    computed: {
      allOffers() {
        return Offer.query()
          .orderBy('state', 'desc')
          .orderBy(({ name }) => name.toLowerCase())
          .get();
      },

      awardPolicyOffer() {
        return this.value.awardPolicy?.cardfreeOfferPublicId
        ? this.allOffers.find(offer => offer.guid === this.value.awardPolicy.cardfreeOfferPublicId)
        : null;
      },

      offerIsValidForTimeFrame() {
        const offerEndDate = this.awardPolicyOffer?.redemptionsAllowedEndDate;
        const earnRuleEndDate = this.value.endDate;

        if (offerEndDate) {
          return moment(earnRuleEndDate).isSameOrBefore(moment(offerEndDate));
        }
        else {
          return true;
        }
      },

      errorMessage() {
        if (!this.awardPolicyOffer) {
          return 'An offer is required for an reward-based earn rules.';
        }
        else if (!this.offerIsValidForTimeFrame) {
          return 'Offer end date must be null or after the earn rule threshold end date. Please select a new offer or change the earn rule end date.';
        }
        else {
          return '';
        }
      }
    },

    methods: {
      formatOffersForModal() {
        return this.allOffers
          .filter(offer => [offerStates.LIVE].includes(offer.state))
          .map((offer) => {
            const { campaignsIanaTimezoneId } = this.$_selectedMerchant.features;
            const formatDate = date => moment.tz(date, campaignsIanaTimezoneId).format('MMM DD, YYYY');
            const isFuture = moment().isBefore(offer.redemptionsAllowedStartDate);

            let footer = 'Redemptions ';

            if (offer.redemptionsAllowedEndDate) {
              footer += 'allowed ';
              footer += `${isFuture ? `from ${formatDate(offer.redemptionsAllowedStartDate)} through` : 'until'} ${formatDate(offer.redemptionsAllowedEndDate)}`;
            }
            // If offer has no end date
            else {
              footer += 'have no end date';
            }

            return {
              description: offer.description,
              footer,
              icon: offer.state === offerStates.LIVE && 'check-circle',
              iconType: offer.state === offerStates.LIVE && 'is-success',
              id: offer.guid,
              name: offer.name
            };
          });
      },

      openAddOfferModal() {
        const offerList = this.formatOffersForModal();

        this.$buefy.modal.open({
          parent: this,
          component: SearchSelectModal,
          hasModalCard: true,
          trapFocus: true,
          canCancel: false,
          props: {
            title: 'Add Offer To The Earn Rule',
            subtitle: 'Select an offer to send with your earn rule',
            confirmText: 'Add',
            placeholderText: 'Search for an offer...',
            data: offerList,
            isSingleSelection: true,
            onSubmit: this.updateOffer
          }
        });
      },

      updateOffer(offerGuid) {
        this.$emit('update-offer', offerGuid);
      }
    }
  };
</script>
