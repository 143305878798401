// classes
import { Model } from '@vuex-orm/core';
import http from '@/services/http';
import { parseJson } from '@/helpers/misc';
import filterObjectKeys from '@/helpers/filter-object-keys';


export default class MerchantOptionsCheckout extends Model {
  static entity = 'merchantOptionsCheckouts'

  // FIELDS //////////////////////
  static fields() {
    return {
      id: this.attr(''),
      merchantId: this.attr(''),
      calculateTipAfterTax: this.attr(''),
      confirmPickupLocationOnCheckout: this.attr(''),
      curbsideCheckInOptions: this.attr(null, parseJson),
      deliveryOnlyEnabled: this.attr(''),
      driveThroughCheckInOptions: this.attr(null, parseJson),
      emailHiddenOnCheckout: this.attr(''),
      emailOptionalOnCheckout: this.attr(''),
      hideAsapPickupTimeLabel: this.attr(''),
      highlightPickUpTimesGreaterThanOneHour: this.attr(''),
      insideCheckInOptions: this.attr(null, parseJson),
      itemLevelInstructionsEnabled: this.attr(''),
      kioskPickupTimesEnabled: this.attr(''),
      maxGuestCountForSplitting: this.attr(''),
      medicalLicenseNumberEnabled: this.attr(''),
      memberAccountNumberEnabled: this.attr(''),
      orderInstructionsEnabled: this.attr(''),
      payWithGiftCardInStoreOrDriveThru: this.attr(''),
      phoneRequiredOnCheckout: this.attr(''),
      scanToPayEnabled: this.attr(''),
      sendSmsOrderReceiptEnabled: this.attr(''),
      showAppDownloadButtonsOnEmailReceipt: this.attr(''),
      showAppDownloadButtonsOnReceipt: this.attr(''),
      showDynamicQrCodeOnEmvReceiptEnabled: this.attr(''),
      showOrderQrCode: this.attr(''),
      showSmsReceiptOption: this.attr(''),
      tippingEnabled: this.attr('')
    };
  }

  // STATE //////////////////////
  static state() {
    return {
      fetching: false,
      submitting: false
    };
  }

  static $state() {
    return this.store().state.entities.merchantOptionsCheckouts;
  }

  static blackListedKeys = ['id', '$id', 'merchantId'];


  // ACTIONS //////////////////////
  static async fetchMerchantOptionsCheckouts(merchantId) {
    try {
      this.commit((state) => {
        state.fetching = true;
      });

      const _merchantId = merchantId || this.store().state.entities.merchants.selectedMerchantId;
      const response = await http.get(`merchants/${_merchantId}/merchant_options_checkouts`);

      this.insert({
        data: response.data.merchantOptionsCheckouts
      });
      return response.data.merchantOptionsCheckouts;
    }
    catch (error) {
      throw error;
    }
    finally {
      this.commit((state) => {
        state.fetching = false;
      });
    }
  }

  static async addMerchantOptionsCheckout(merchantOptionsCheckout) {
    try {
      this.commit((state) => {
        state.submitting = true;
      });

      const response = await http.post(
        `merchants/${merchantOptionsCheckout.merchantId}/merchant_options_checkouts`,
        { merchantOptionsCheckout: filterObjectKeys(merchantOptionsCheckout, this.blackListedKeys, true) }
      );

      this.insert({
        data: response.data.merchantOptionsCheckout
      });
    }
    catch (error) {
      throw error;
    }
    finally {
      this.commit((state) => {
        state.submitting = false;
      });
    }
  }

  static async updateMerchantOptionsCheckout(merchantOptionsCheckout) {
    try {
      this.commit((state) => {
        state.submitting = true;
      });

      const response = await http.put(
        `merchant_options_checkouts/${merchantOptionsCheckout.id}`,
        { merchantOptionsCheckout: filterObjectKeys(merchantOptionsCheckout, this.blackListedKeys, true) }
      );

      this.update({
        data: response.data.merchantOptionsCheckout
      });
    }
    catch (error) {
      throw error;
    }
    finally {
      this.commit((state) => {
        state.submitting = false;
      });
    }
  }
}
