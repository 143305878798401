<template>
  <div class="is-grid gap-lg col-min-300">
    <div>
      <validated-text-input
        :value="earnRuleConstraint.minimumPaymentAmountFromSelectedMethods"
        data-test-id="minimum-amount"
        :disabled="readOnly"
        label="Minimum Amount"
        name="minimumPurchase"
        type="dollars"
        :rules="{
          required: true,
          min_value: 0.01
        }"
        placeholder="Set a minimum purchase amount..."
        :mask-options="{ numeralDecimalScale: 2, numeralPositiveOnly: true }"
        @input="handleInput({ minimumPaymentAmountFromSelectedMethods: Number($event) })"
      />
      <b-checkbox
        v-show="giftCardPaymentTypeSelected"
        :value="earnRuleConstraint.waiveMinimumAmountIfZeroingGiftCardBalance"
        data-test-id="waive-for-insufficient-balance"
        name="waiveMinimumAmountIfZeroingGiftCardBalance"
        :disabled="readOnly"
        @input="handleInput({ waiveMinimumAmountIfZeroingGiftCardBalance: $event })"
      >
        Waive for Insufficient Gift Card Balance
        <b-icon
          v-tippy="{
            content: 'Waive the minimum amount required when a gift card’s balance is insufficient',
            maxWidth: 300,
            delay: [150, 0]
          }"
          class="mar-b-none pad-b-none"
          icon="info-square"
          size="is-small"
          type="is-grey"
        />
      </b-checkbox>
    </div>
    <validated-input
      name="paymentMethods"
      label="Payment Methods"
      data-test-id="payment-methods"
      :rules="{ required: true }"
      :custom-messages="{
        required: 'Please select at least one payment method'
      }"
    >
      <b-dropdown
        :value="earnRuleConstraint.paymentMethods"
        :mobile-modal="false"
        aria-role="list"
        multiple
        inline
        :disabled="readOnly"
        @input="handleInput({ paymentMethods: $event })"
      >
        <b-dropdown-item
          v-for="paymentMethod in merchantPaymentMethods"
          :key="paymentMethod.value"
          :value="paymentMethod.value"
          aria-role="listitem"
        >
          {{ paymentMethod.display }}
        </b-dropdown-item>
      </b-dropdown>
    </validated-input>
  </div>
</template>

<script>
  import MerchantPaymentGateway from '@/store/classes/MerchantPaymentGateway';
  import capitalCase from '@/helpers/capitalCase';

  export default {
    name: 'PaymentMethodCard',

    props: {
      earnRuleConstraint: {
        type: Object,
        required: true
      },

      readOnly: {
        type: Boolean,
        default: false
      }
    },

    computed: {
      merchantPaymentGateways() {
        return MerchantPaymentGateway.all();
      },

      merchantPaymentMethods() {
        const uniquePaymentMethods = new Set();

        this.merchantPaymentGateways.forEach(({ availablePaymentMethods }) => {
          availablePaymentMethods.forEach(method => uniquePaymentMethods.add(method.name));
        });
        return Array.from(uniquePaymentMethods).map(paymentMethod => ({ display: capitalCase(paymentMethod), value: paymentMethod }));
      },

      giftCardPaymentTypeSelected() {
        return this.earnRuleConstraint.paymentMethods.includes('GiftCard');
      }
    },

    methods: {
      handleInput(payload) {
        this.$emit('update-constraint', { ...this.earnRuleConstraint, ...payload });
      }
    }
  };
</script>
