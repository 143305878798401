const getChangedResources = ({ newArray, oldArray, comparisonKey, updatedComparisonFn }) => {
  if (comparisonKey) {
    const returnValues = {};

    returnValues.added = newArray.filter(n => !oldArray.some(o => o[comparisonKey] === n[comparisonKey]));
    returnValues.removed = oldArray.filter(o => !newArray.some(n => n[comparisonKey] === o[comparisonKey]));

    if (updatedComparisonFn) {
      returnValues.updated = newArray.filter(n => oldArray.some(o => n[comparisonKey] === o[comparisonKey] && updatedComparisonFn(n, o)));
    }

    return returnValues;
  }

  return {
    added: newArray.filter(n => !oldArray.includes(n)),
    removed: oldArray.filter(o => !newArray.includes(o))
  };
};

export default getChangedResources;
