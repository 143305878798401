/* eslint-disable import/no-cycle */
import { Model } from '@vuex-orm/core';

import filterObjectKeys from '@/helpers/filter-object-keys';
import http from '@/services/http';
import { parseJson } from '@/helpers/misc';


export default class MerchantOption extends Model {
  static entity = 'merchantOptions'



  // FIELDS //////////////////////
  static fields() {
    return {
      id: this.attr(''),
      autoLoadStoreId: this.attr(''),
      avatarUrl: this.attr(''),
      baseImageUrl: this.attr(''),
      calculateTipAfterTax: this.attr(false),
      curbsideCheckInOptions: this.attr(null, parseJson),
      disableContactUsLink: this.attr(false),
      disableReset: this.attr(false),
      driveThroughCheckInOptions: this.attr(null, parseJson),
      emailTemplateOptions: this.attr(null, parseJson),
      faviconUrl: this.attr(''),
      hideAsapPickupTimeLabel: this.attr(false),
      insideCheckInOptions: this.attr(null, parseJson),
      inStoreRedemptionOptions: this.attr(null, parseJson),
      logoUrl: this.attr(''),
      walletPassLogoUrl: this.attr(''),
      maxGuestCountForSplitting: this.attr(''),
      merchantId: this.attr(''),
      notificationsEnabled: this.attr(false),
      ogImageUrl: this.attr(''),
      orderInstructionsEnabled: this.attr(false),
      patTipping: this.attr(false),
      phoneRequiredOnCheckout: this.attr(false),
      providerLogoUrl: this.attr(''),
      providerName: this.attr(''),
      providerUrl: this.attr(''),
      showAllStores: this.attr(false),
      showOrderQrCode: this.attr(false),
      signUpEnabled: this.attr(false),
      supportEmailAddress: this.attr(''),
      userValidationsId: this.attr(''),
      skinOptions: this.attr('', (value) => {
        const defaultSkinOptions = {
          avatarType: null,
          cssFile: null,
          headerBackgroundColor: null,
          headerBackgroundImage: null,
          mainContentBackgroundColor: null,
          primaryColor: null,
          secondaryColor: null,
          tertiaryColor: null,
          sectionPrimaryDarkBackground: null,
          sectionSecondaryDarkBackground: null,
          svgLogo: null,
          type: null,
          headerDarkBackground: null
        };

        return value ? parseJson(value) : defaultSkinOptions;
      })
    };
  }

  get lastModified() {
    return this.updatedAt || this.createdAt;
  }

  // STATE //////////////////////
  static state() {
    return {
      submitting: false,
      deleting: false
    };
  }

  static $state() {
    return this.store().state.entities.merchantOptions;
  }



  // ACTIONS //////////////////////
  static async fetchMerchantOption(merchantId) {
    try {
      if (!merchantId) merchantId = this.store().state.entities.merchants.selectedMerchantId;

      const { data } = await http.get(`merchants/${merchantId}/merchant_options`);

      this.insert({ data: data.merchantOption });
      return data.merchantOption;
    }
    catch (error) {
      throw error;
    }
  }

  static async updateMerchantOption(merchantOption) {
    try {
      const blacklistedKeys = ['id', '$id', 'logoUrl', 'avatarUrl', 'ogImageUrl', 'walletPassLogoUrl'];

      const response = await http.put(`merchant_options/${merchantOption.id}`, {
        merchantOption: filterObjectKeys(merchantOption, blacklistedKeys, true)
      });

      this.update({
        data: response.data.merchantOption
      });
    }
    catch (error) {
      throw error;
    }
  }

  static async updateMerchantLogo({ id, logoImageFile }) {
    try {
      const formData = new FormData();
      formData.append('merchantOption[logo]', logoImageFile);

      const response = await http.put(
        `merchant_options/${id}/update_logo`,
        formData,
        { headers: { 'Content-Type': 'multipart/form-data' } }
      );

      this.update({
        data: response.data.merchantOption
      });
    }
    catch (error) {
      throw error;
    }
  }

  static async updateMerchantAvatar({ id, avatarImageFile }) {
    try {
      this.commit((state) => {
        state.submitting = true;
      });

      const formData = new FormData();
      formData.append('merchantOption[avatar]', avatarImageFile);

      const response = await http.put(
        `merchant_options/${id}/update_avatar`,
        formData,
        { headers: { 'Content-Type': 'multipart/form-data' } }
      );

      this.update({
        data: response.data.merchantOption
      });
    }
    catch (error) {
      throw error;
    }

    finally {
      this.commit((state) => {
        state.submitting = false;
      });
    }
  }

  static async deleteMerchantAvatar(merchantOptionId) {
    try {
      this.commit((state) => {
        state.submitting = true;
      });

      const response = await http.delete(`merchant_options/${merchantOptionId}/avatar`);

      this.update({
        data: response.data.merchantOption
      });

      return response.data.merchantOption;
    }
    catch (error) {
      throw error;
    }

    finally {
      this.commit((state) => {
        state.submitting = false;
      });
    }
  }

  static async updateWalletPassLogo(id, logoImageFile) {
    try {
      const formData = new FormData();
      formData.append('merchantOption[walletPassLogo]', logoImageFile);

      const response = await http.put(
        `merchant_options/${id}/update_wallet_pass_logo`,
        formData,
        { headers: { 'Content-Type': 'multipart/form-data' } }
      );

      this.update({
        data: response.data.merchantOption
      });
    }
    catch (error) {
      throw error;
    }
  }

  static async deleteWalletPassLogo(merchantOptionId) {
    try {
      this.commit((state) => {
        state.deleting = true;
      });

      const response = await http.delete(`merchant_options/${merchantOptionId}/wallet_pass_logo`);

      this.update({
        data: response.data.merchantOption
      });

      return response.data.merchantOption;
    }
    catch (error) {
      throw error;
    }

    finally {
      this.commit((state) => {
        state.deleting = false;
      });
    }
  }
}
