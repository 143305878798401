import { Model } from '@vuex-orm/core';
import http from '@/services/http';
import moment from 'moment-timezone';

export default class OfferDistributionCode extends Model {
  static entity = 'offerDistributionCodes';

  static primaryKey = 'publicId';


  // FIELDS //////////////////////
  static fields() {
    return {
      publicId: this.attr(''),
      offerDistributionPublicId: this.attr(''),
      promotionCode: this.attr(''),
      usageCount: this.attr(0),
      invalidatedDateTime: this.attr('')
    };
  }



  // STATE //////////////////////
  static state() {
    return {
      fetching: false,
      submitting: false
    };
  }

  static $state() {
    return this.store().state.entities.offerDistributionCodes;
  }



  // ACTIONS //////////////////////
  static async fetchOfferDistributionCodes(offerDistributionPublicId) {
    try {
      this.commit((state) => {
        state.fetching = true;
      });

      const merchantId = this.store().state.entities.merchants.selectedMerchantId;

      const { data } = await http.get(`merchants/${merchantId}/offer_distribution_codes?offerDistributionPublicId=${offerDistributionPublicId}`);

      this.create({
        data: data.offerDistributionCodes
      });

      return data.offerDistributionCodes;
    }

    catch (error) {
      throw error;
    }

    finally {
      this.commit((state) => {
        state.fetching = false;
      });
    }
  }

  static async addOfferDistributionCode(offerDistributionCode) {
    const { offerDistributionPublicId, promotionCode } = offerDistributionCode;
    try {
      this.commit((state) => {
        state.submitting = true;
      });

      const merchantId = this.store().state.entities.merchants.selectedMerchantId;
      const response = await http.post(`merchants/${merchantId}/offer_distribution_codes`, {
        offerDistributionCode: {
          offerDistributionPublicId,
          promotionCode
        }
      });

      this.insert({ data: response.data.offerDistributionCode });
      return response.data.offerDistributionCode.publicId;
    }

    catch (error) {
      throw error;
    }

    finally {
      this.commit((state) => {
        state.submitting = false;
      });
    }
  }

  static async invalidateOfferDistributionCode(offerDistributionCode) {
    try {
      this.commit((state) => {
        state.submitting = true;
      });

      const merchantId = this.store().state.entities.merchants.selectedMerchantId;
      await http.post(`merchants/${merchantId}/offer_distribution_codes/invalidate`, {
        offerDistributionCode
      });

      this.update({
        data: { invalidatedDateTime: moment().toISOString() },
        where: offerDistributionCode.publicId
      });
    }

    catch (error) {
      throw error;
    }

    finally {
      this.commit((state) => {
        state.submitting = false;
      });
    }
  }
}
