<template>
  <validated-form
    ref="form"
    form-id="paymentMethodsForm"
    @valid-submit="updatePaymentMethods"
  >
    <sticky-save-container
      :loading="isFetchingMerchantPaymentGateways"
      :saving="isSubmitting"
      title="Payment Methods"
      :disabled="!storePaymentGateways.length"
    >
      <validation-provider
        v-slot="{errors: paymentGateWayErrors}"
        name="paymentGateways"
        rules="required"
      >
        <b-checkbox :value="!invalidStorePaymentGateways.length || null" :class="['is-hidden', { 'invalid': paymentGateWayErrors.length }]" />

        <b-field
          :message="paymentGateWayErrors.length && invalidStorePaymentGateways.length ? `You must have at least 1 Payment Method selected for ${invalidStorePaymentGateways.join(' and ')}`: ''"
          :type="paymentGateWayErrors.length ? 'is-danger' : ''"
        >
          <b-message
            v-if="!storePaymentGateways.length"
            type="is-warning"
            class="is-compact has-shadow"
          >
            No Store Payment Provider
          </b-message>
          <div v-else class="is-grid gap-xl col-min-200">
            <div v-for="{ paymentGateway, availablePaymentMethods, id } in storePaymentGateways" :key="id">
              <p class="subtitle is-5 mar-b">
                {{ paymentGateway.name }}
              </p>

              <div v-if="availablePaymentMethods.length" class="is-grid gap-sm">
                <check-button
                  v-for="paymentMethod in availablePaymentMethods"
                  :key="paymentMethod.id"
                  v-model="selectedPaymentMethodIds"
                  compact
                  :label="getPaymentMethodDisplay(paymentMethod.name)"
                  :native-value="`${id}-${paymentMethod.id}`"
                  :disabled="getDisabledForPaymentMethod({ methodName: paymentMethod.name, gatewayName: paymentGateway.name }) || availablePaymentMethods.length === 1"
                />
              </div>
              <b-message v-else class="is-compact has-shadow is-inline-block">
                No Payment Methods Available
              </b-message>
            </div>
          </div>
        </b-field>
      </validation-provider>
    </sticky-save-container>
  </validated-form>
</template>



<script>
  import MerchantPaymentGateway from '@/store/classes/MerchantPaymentGateway';
  import paymentMethods from '@/constants/paymentMethods';
  import capitalCase from '@/helpers/capitalCase';

  export default {
    name: 'StorePaymentMethods',

    props: {
      storeId: {
        type: Number,
        required: true
      }
    },

    data() {
      return {
        selectedPaymentMethodIds: []
      };
    },

    computed: {
      isFetchingMerchantPaymentGateways() {
        return MerchantPaymentGateway.$state().fetching;
      },

      isSubmitting() {
        return MerchantPaymentGateway.$state().submitting;
      },

      storePaymentGateways() {
        return MerchantPaymentGateway.query().where('storeId', this.storeId).get() || [];
      },

      invalidStorePaymentGateways() {
        const invalidStorePaymentGateways = this.storePaymentGateways.reduce((acc, spg) => {
          const gatewayPaymentMethod = this.selectedPaymentMethodIds.find((id) => {
            const gatewayId = Number(id.split('-')[0]);
            return gatewayId === spg.id;
          });
          if (!gatewayPaymentMethod) {
            acc.push(spg.paymentGateway.name);
          }
          return acc;
        }, []);
        return invalidStorePaymentGateways;
      }
    },

    watch: {
      storePaymentGateways: {
        immediate: true,
        handler: 'handleStorePaymentGatewayChange'
      }
    },

    methods: {
      handleStorePaymentGatewayChange(newStorePaymentGateways, oldStorePaymentGateways) {
        if (
          !oldStorePaymentGateways
          || newStorePaymentGateways.length !== oldStorePaymentGateways.length
        ) {
          this.selectedPaymentMethodIds = [];
          newStorePaymentGateways.forEach((spg) => {
            spg.paymentMethods.forEach((pm) => {
              this.selectedPaymentMethodIds.push(`${spg.id}-${pm.id}`);
            });
          });
        }
      },

      getDisabledForPaymentMethod({ methodName, gatewayName }) {
        // If Square --> Nonce at min should be selected. User should not be able to unselect this.
        if (gatewayName === 'Square' && methodName === paymentMethods.NONCE.type) {
          return true;
        }
        return false;
      },

      getPaymentMethodDisplay(method) {
        const constant = Object.keys(paymentMethods).find(pm => paymentMethods[pm].type === method);
        return paymentMethods[constant]?.display || capitalCase(method);
      },

      async updatePaymentMethods() {
        try {
          const requests = this.storePaymentGateways.map((spg) => {
            const selectedMethodIds = this.selectedPaymentMethodIds.reduce((acc, id) => {
              const gatewayId = Number(id.split('-')[0]);
              const methodId = Number(id.split('-')[1]);
              if (gatewayId === spg.id) {
                acc.push(methodId);
              }
              return acc;
            }, []);
            return MerchantPaymentGateway.updatePaymentGateway(spg, selectedMethodIds);
          });


          await Promise.all(requests);

          this.$_onRequestSuccess({
            toastOptions: {
              message: 'Successfully updated the payment methods!'
            }
          });
        }
        catch (error) {
          this.$_onRequestError({
            toastOptions: {
              message: 'There was an error while updating the payment methods'
            },
            error
          });
        }
      }
    }
  };
</script>
