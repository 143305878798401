import { isPossiblePhoneNumber } from 'libphonenumber-js';

// MODES

const passiveAggressive = (ctx) => {
  const { validated } = ctx.flags;
  if (validated) {
    return { on: ['input', 'change', 'blur'] };
  }
  return { on: ['change'] };
};



// RULES

const validJSON = {
  message: '{_field_} must be valid JSON',
  async validate(value) {
    try {
      await JSON.parse(value);
      return true;
    }
    catch (error) {
      return false;
    }
  }
};

const fileType = {
  params: ['extensions'],
  validate: (file, { extensions }) => {
    if (typeof extensions === 'string') {
      extensions = [extensions];
    }
    const regex = new RegExp(`.(${extensions.join('|')})$`, 'i');
    return regex.test(file.name) || `Must select a file with one of the following extensions: ${extensions.join(', ')}`;
  }
};

const greaterThan = {
  params: ['target'],
  message: '{_field_} must be greater than {target}',
  validate: (value, { target }) => Number(value) > Number(target)
};

const lessThan = {
  params: ['target'],
  message: '{_field_} must be less than {target}',
  validate: (value, { target }) => Number(value) < Number(target)
};

const dateTimeIsAfter = {
  params: ['target'],
  message: '{_field_} must be after {target}',
  validate: (value, { target }) => value > target
};

const confirmPassword = {
  validate: (password, { passwordConfirmation }) => password === passwordConfirmation,
  message: 'You must enter matching passwords',
  params: [{ name: 'passwordConfirmation', isTarget: true }]
};

const minLength = {
  params: ['min'],
  message: 'You must select at least {min} {_field_}',
  validate(array, { min }) {
    return array.length >= min;
  }
};

const maxLength = {
  params: ['max'],
  message: 'You may only select up to {max} {_field_}',
  validate(array, { max }) {
    return array.length <= max;
  }
};

const notWhite = {
  message: 'The color white is not allowed, please choose a darker color to allow more contrast',
  validate(hexValue) {
    const isWhite = /#([f]{6}$|[f]{3})$/i;
    return !isWhite.test(hexValue);
  }
};

const postalCode = {
  message: 'Must enter a valid postal code',
  validate(value) {
    /* check to see if the value matches any of our supported postal code formats */
    const regexPatterns = [
      /* US ZIP Code */
      '^\\b\\d{5}\\b(?:[- ]{1}\\d{4})?$',

      /* Canadian Postal Code - https://stackoverflow.com/a/46761018 */
      '^[ABCEGHJ-NPRSTVXY]\\d[ABCEGHJ-NPRSTV-Z][ -]?\\d[ABCEGHJ-NPRSTV-Z]\\d$'
    ];

    return regexPatterns.some(regex => new RegExp(regex).test(value));
  }
};

const validHexColor = {
  message: 'Must be a valid hexidecimal color value',
  validate(hexValue) {
    const validHex = /^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/igm;
    return validHex.test(hexValue);
  }
};

const validLatitude = {
  message: 'Must be a valid latitude value (-90 to 90)',
  validate(value) {
    const isValidLatitude = /^(\+|-)?(?:90(?:(?:\.0{1,10})?)|(?:[0-9]|[1-8][0-9])(?:(?:\.[0-9]{1,10})?))$/;
    return isValidLatitude.test(value);
  }
};

const validLongitude = {
  message: 'Must be a valid longitude value (-180 to 180)',
  validate(value) {
    const isValidLongitude = /^(\+|-)?(?:180(?:(?:\.0{1,10})?)|(?:[0-9]|[1-9][0-9]|1[0-7][0-9])(?:(?:\.[0-9]{1,10})?))$/;
    return isValidLongitude.test(value);
  }
};

const validUrl = {
  message: 'Must be a valid URL',
  validate(url) {
    try {
      return Boolean(new URL(url));
    }
    catch (e) {
      return false;
    }
  }
};

const specialChars = {
  validate: (password) => {
    const regex = /[!@#$%^&*(),.?":{}|<>]/g;
    return regex.test(password);
  },
  message: 'Your password must have at least one special character'
};

const uriFriendly = {
  message: 'Must contain only lowercase letters, numbers, and hyphens',
  validate(value) {
    const isValidMerchantName = /^[a-z0-9-]+$/;
    return isValidMerchantName.test(value);
  }
};

const maxByteSize = {
  params: ['max'],
  message: (fieldName, { max }) => `The ${fieldName} field must be ${new Intl.NumberFormat().format(max)} bytes or less`,
  validate: (value, { max }) => new Blob([value]).size <= max
};

const validPhoneNumber = {
  message: 'Must be a valid phone number',
  validate(phoneNumber) {
    return isPossiblePhoneNumber(phoneNumber, 'US');
  }
};

const uniqueStringCaseInsensitive = {
  params: ['strings'],
  message: 'The {_field_} field must be unique',
  validate(value, { strings = [] }) {
    // vee-validate seems to pass an array of a single string as just a string
    // so we need to check if its a string and put it back in an array
    let stringsArray = strings;
    if (typeof strings === 'string') {
      stringsArray = [strings];
    }
    return !stringsArray.some(string => string.toLowerCase().trim() === value.toLowerCase().trim());
  }
};

const validEmailAddress = {
  message: 'Must be a valid email address',
  validate(email) {
    const emailRegex = /^[\w!#$%&'*+/=?`{|}~^-]+(?:\.[\w!#$%&'*+/=?`{|}~^-]+)*@(?:[A-Z0-9-]+\.)+[A-Z]{2,6}$/i;
    return emailRegex.test(email);
  }
};

export const modes = { passiveAggressive };
export const rules = {
  confirmPassword,
  dateTimeIsAfter,
  fileType,
  greaterThan,
  lessThan,
  maxByteSize,
  maxLength,
  minLength,
  notWhite,
  postalCode,
  specialChars,
  uniqueStringCaseInsensitive,
  uriFriendly,
  validEmailAddress,
  validHexColor,
  validJSON,
  validLatitude,
  validLongitude,
  validPhoneNumber,
  validUrl
};
