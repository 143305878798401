<template>
  <panel collapsible no-padding title="Employees">
    <b-loading :active="isLoadingEmployees" :is-full-page="false" />
    <searchable-table
      :table-data="allEmployees"
      :search="{
        placeholder: 'Search by Name, Department or ID',
        keys: ['fullName', 'departmentName', 'internalId']
      }"
    >
      <template #optional-actions>
        <b-button
          type="is-primary"
          icon-left="plus"
          :disabled="!$can('create', 'Employee')"
          @click="openEmployeeModal()"
        >
          New Employee
        </b-button>
      </template>
      <template v-slot="{ filteredData }">
        <b-table
          :data="isLoadingEmployees ? [] : filteredData"
          :paginated="allEmployees.length > pageLimit"
          class="is-middle-aligned"
          pagination-simple
          :mobile-cards="false"
          :per-page="pageLimit"
        >
          <b-table-column
            v-slot="{ row }"
            sortable
            field="fullName"
            label="Employee Name"
            cell-class="is-flex-align-center "
          >
            <img
              v-if="row.photoUrl"
              :src="row.photoUrl"
              alt="employee image"
              class="image is-inline is-32x32 employee-photo"
            >
            <b-icon
              v-else
              icon="user-circle"
              size="is-large"
              class="is-inline"
              :style="{ height: '32px', width: '32px' }"
            />
            <span class="mar-l link-inverted" @click="openEmployeeModal(row)">{{ row.fullName }}</span>
          </b-table-column>

          <b-table-column
            v-slot="{ row: { departmentName } }"
            sortable
            centered
            field="departmentName"
            label="Department"
          >
            {{ departmentName }}
          </b-table-column>

          <b-table-column
            v-slot="{ row: { internalId } }"
            class="no-wrap-text is-monospaced"
            sortable
            centered
            numeric
            field="internalId"
            label="Employee Id"
          >
            <span v-if="internalId">{{ internalId }}</span>
            <span v-else class="has-text-grey-light">N/A</span>
          </b-table-column>

          <b-table-column
            v-slot="{ row }"
            centered
            cell-class="actions"
            label="Actions"
            width="1"
          >
            <b-dropdown aria-role="list" position="is-bottom-left">
              <b-button slot="trigger" class="is-transparent" type="is-white">
                <b-icon icon="ellipsis-v" pack="far" size="is-small" />
              </b-button>
              <b-dropdown-item @click="openShareQRCodeModal(row)">
                <b-icon icon="qrcode" class="mar-r-sm" size="is-small" />
                View & Share QR
              </b-dropdown-item>
              <b-dropdown-item @click="openEmployeeModal(row)">
                <b-icon icon="pencil" class="mar-r-sm" size="is-small" />Edit Employee
              </b-dropdown-item>
              <hr class="dropdown-divider">
              <b-dropdown-item @click="openConfirmDelete(row)">
                <b-icon icon="trash-alt" type="is-danger" class="mar-r-sm" size="is-small" />Delete Employee
              </b-dropdown-item>
            </b-dropdown>
          </b-table-column>

          <template slot="empty">
            <empty-table-loader :loading="isLoadingEmployees" icon="users" message="No Employees Found" />
          </template>
        </b-table>
      </template>
    </searchable-table>
  </panel>
</template>

<script>
  import Employee from '@/store/classes/Employee';
  import addEditEmployeeModal from './add-edit-employee-modal.vue';
  import alertModal from '@/components/globals/alert-modal.vue';
  import shareEmployeeDepartmentModal from './share-employee-department-modal.vue';

  export default {
    name: 'EmployeesConfiguration',

    props: {
      store: {
        type: Object,
        required: true
      }
    },

    data() {
      return {
        pageLimit: 20
      };
    },

    computed: {
      isLoadingEmployees() {
        return Employee.$state().fetching;
      },

      allEmployees() {
        return Employee.query().with('department').get().map(employee => ({ departmentName: employee?.department?.name, ...employee }));
      }
    },

    methods: {
      openEmployeeModal(employee) {
        const employeeToEdit = employee || new Employee();
        this.$buefy.modal.open({
          parent: this,
          component: addEditEmployeeModal,
          hasModalCard: true,
          trapFocus: true,
          canCancel: false,
          customClass: 'auto-width',
          props: { employee: employeeToEdit, storeId: this.store.storeId }
        });
      },

      openShareQRCodeModal(employee) {
        this.$buefy.modal.open({
          parent: this,
          component: shareEmployeeDepartmentModal,
          hasModalCard: true,
          trapFocus: true,
          canCancel: false,
          customClass: 'auto-width',
          props: { resource: employee, type: 'employee' }
        });
      },

      openConfirmDelete(employee) {
        this.$buefy.modal.open({
          parent: this,
          component: alertModal,
          hasModalCard: true,
          trapFocus: true,
          canCancel: ['escape', 'outside'],
          props: {
            icon: 'trash-alt',
            title: 'Delete Employee',
            message: `Are you sure you want to delete <b>${employee.fullName}</b> from the <b>${employee.department.name}</b> department at the <b>${this.store.description}</b> location? this CANNOT be undone.`,
            type: 'is-danger',
            horizontal: true,
            showCloseButton: false,
            buttons: [
              { text: 'Cancel' },
              {
                text: 'Delete',
                primary: true,
                onClick: () => this.deleteEmployee(employee.id)
              }
            ]
          }
        });
      },
      async deleteEmployee(employeeId) {
        try {
          await Employee.deleteEmployee(employeeId);
        }
        catch (error) {
          this.$_onRequestError({
            toastOptions: {
              message: 'There was an issue deleting your employee'
            },
            error
          });
        }
      }
    }
  };

</script>

<style scoped lang="sass">
  .employee-photo
    border-radius: 50%
</style>
