<template>
  <div class="is-relative is-full-height">
    <tabs-page
      title="Offer Management"
      :tabs="tabs"
      :keep-alive="false"
    />
  </div>
</template>

<script>
  // tabs
  import offerList from './tabs/offer-list.vue';
  import offerConfiguration from './tabs/offer-configuration.vue';

  export default {
    name: 'OfferTabs',

    computed: {
      tabs() {
        return [
          {
            display: 'Offers',
            name: 'offer-list',
            component: offerList
          },
          {
            display: 'Configurations',
            name: 'configurations',
            component: offerConfiguration
          }
        ];
      }
    }
  };
</script>
