/* eslint-disable import/no-cycle */
import http from '@/services/http';
import MenuItemAttribute from '@/store/classes/MenuItemAttribute';
import MenuAttributeType from '@/store/classes/MenuAttributeType';
import MerchantMenuAttribute from '@/store/classes/MerchantMenuAttribute';
import FetchModel from '@/store/classes/FetchModel';

export default class MenuAttribute extends FetchModel {
  static entity = 'menuAttributes'

  static fields() {
    return {
      id: this.uid(''),
      name: this.attr(null),
      imageUrl: this.attr(null),
      menuAttributeTypeId: this.attr(null),
      menuAttributeType: this.belongsTo(MenuAttributeType, 'menuAttributeTypeId'),
      menuItemAttributes: this.hasMany(MenuItemAttribute, 'menuAttributeId'),
      merchantMenuAttribute: this.hasOne(MerchantMenuAttribute, 'menuAttributeId')
    };
  }

  static state() {
    return {
      fetching: false,
      submitting: false,
      deleting: false
    };
  }

  static $state() {
    return this.store().state.entities.menuAttributes;
  }

  get itemCount() {
    return this.menuItemAttributes.length;
  }

  static async fetchMenuAttributesForMerchant(options = {}) {
    const merchantId = this.store().state.entities.merchants.selectedMerchantId;
    await this.fetchData({
      endpoint: `merchants/${merchantId}/menu_attributes`,
      options,
      transformData: data => data.menuAttributes,
      ormMethod: 'insertOrUpdate'
    });
  }

  static async fetchMenuAttribute(id) {
    try {
      this.commit((state) => {
        state.fetching = true;
      });

      const { data: { menuAttribute } } = await http.get(`menu_attributes/${id}`);

      this.insertOrUpdate({
        data: menuAttribute
      });
    }
    catch (error) {
      throw error;
    }
    finally {
      this.commit((state) => {
        state.fetching = false;
      });
    }
  }

  static async createMenuAttributes(menuAttributes) {
    try {
      this.commit((state) => {
        state.submitting = true;
      });

      const merchantId = this.store().state.entities.merchants.selectedMerchantId;
      const { data: { menuAttributes: ma } } = await http.post(`merchants/${merchantId}/menu_attributes/bulk_create`, {
        menuAttributes
      });
      this.insert({
        data: ma
      });
      return ma;
    }
    catch (error) {
      throw error;
    }
    finally {
      this.commit((state) => {
        state.submitting = false;
      });
    }
  }

  static async updateMenuAttribute(menuAttribute) {
    try {
      this.commit((state) => {
        state.submitting = true;
      });

      const { data: { menuAttribute: ma } } = await http.put(`menu_attributes/${menuAttribute.id}`, { menuAttribute });

      this.update({
        data: ma
      });
    }
    catch (error) {
      throw error;
    }
    finally {
      this.commit((state) => {
        state.submitting = false;
      });
    }
  }

  static async deleteMenuAttribute({ id, merchantMenuAttributeId }) {
    try {
      this.commit((state) => {
        state.deleting = true;
      });

      await http.delete(`menu_attributes/${id}`);
      this.delete(id);
      MerchantMenuAttribute.delete(merchantMenuAttributeId);
      MenuItemAttribute.delete(mia => mia.menuAttributeId === id);
    }
    catch (error) {
      throw error;
    }
    finally {
      this.commit((state) => {
        state.deleting = false;
      });
    }
  }
}
