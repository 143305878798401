<template>
  <div class="store-menu is-full-height">
    <!-- LOADING MENU -->
    <!-- -- - -- - -- -->
    <div v-if="isLoadingMenu" class="card is-relative is-full-height">
      <b-loading :is-full-page="false" active />
    </div>

    <!-- UNSUPPORTED WARNING -->
    <!-- -- - -- - -- - -- - -->
    <empty-state-card
      v-else-if="!stores.length"
      type="is-warning"
      icon="map-marker-alt"
      icon-size="fa-4x"
      title="No Valid Locations"
      message="Please add a location and setup a related menu type before managing your location menus"
    >
      <template #buttons>
        <div class="buttons is-mobile-centered">
          <router-link
            tag="button"
            class="button is-dark is-rounded mar-r"
            rounded
            :to="{ name: 'storeList' }"
          >
            Manage Locations
          </router-link>

          <router-link
            tag="button"
            class="button is-dark is-rounded"
            rounded
            :to="{ name: 'menuManagement' }"
          >
            Manage Menus
          </router-link>
        </div>
      </template>
    </empty-state-card>

    <template v-else>
      <div class="card">
        <!-- TABLE CONTROLS -->
        <!-- -- - -- - -- - -->
        <div class="is-flex-column pad border-bottom has-border-grey-lightest">
          <div class="is-flex gap justify-between mar-b-lg">
            <div class="is-flex-wrap align-center flex-grow gap">
              <b-autocomplete
                v-if="stores.length > 1"
                :data="filteredStores"
                :value="selectedStore && selectedStore.description"
                :loading="storeLoading"
                class="store-select"
                field="description"
                icon-right="angle-down"
                open-on-focus
                max-height="325"
                placeholder="Select a location..."
                @select="handleStoreSelect"
                @typing="filterStoreOptions"
              >
                <template #empty>
                  No locations found
                </template>
                <template slot-scope="props">
                  <div :class="['store-option', {'selected': selectedStoreId === props.option.storeId}]">
                    {{ props.option.description }}
                    <b-icon v-if="selectedStoreId === props.option.storeId" size="is-small" type="is-primary" icon="check" />
                  </div>
                </template>
              </b-autocomplete>
              <transition name="fade-right" mode="out-in">
                <div :key="selectedStoreId" class="is-flex gap">
                  <b-dropdown
                    v-for="menuType in selectedStore.supportedMenuTypes"
                    :key="menuType.id"
                    :mobile-modal="false"
                    :triggers="['hover']"
                    class="mar-none"
                  >
                    <b-button
                      slot="trigger"
                      size="is-small"
                      :icon-pack="storeFullySupportsMenuType(menuType.id) ? 'far' : 'fa'"
                      :icon-right="storeFullySupportsMenuType(menuType.id)
                        ? (storeMenuTypeHasSchedule(menuType.id) ? 'calendar' : '')
                        : 'exclamation'"
                      :type="storeFullySupportsMenuType(menuType.id) ? 'is-primary is-light' : 'is-danger is-light'"
                      style="border: none"
                    >
                      {{ menuType.displayName }}
                    </b-button>
                    <template v-if="storeFullySupportsMenuType(menuType.id)">
                      <b-dropdown-item v-if="storeMenuTypeHasSchedule(menuType.id)" class="no-wrap-text" custom>
                        <b>{{ menuType.displayName }}</b> has availability restrictions
                      </b-dropdown-item>
                      <b-dropdown-item v-if="$can('read', 'StoreMenuTypeSchedule') && $_menuPermissions.MANAGE_DAYPART_SCHEDULE" custom>
                        <b-button
                          icon-left="calendar"
                          icon-pack="far"
                          type="is-primary is-light"
                          size="is-small"
                          expanded
                          @click="openStoreMenuTypeScheduleModal(menuType)"
                        >
                          Availability
                        </b-button>
                      </b-dropdown-item>
                    </template>
                    <b-dropdown-item v-else class="no-wrap-text has-text-danger" custom>
                      No master menu exists for <b>{{ menuType.displayName }}</b>
                    </b-dropdown-item>
                  </b-dropdown>
                </div>
              </transition>
            </div>
            <div class="is-flex-wrap justify-end gap">
              <div v-tippy="{ content: 'Collapse All' }">
                <b-button :disabled="!isAnyMenuSectionOpen" @click="collapseAll">
                  <b-icon icon="compress-alt" size="is-medium" />
                </b-button>
              </div>
              <b-button
                v-if="!selectedStore.isCardfreePOS"
                type="is-primary"
                icon-left="sync-alt"
                @click="refreshPricesForLocation"
              >
                Prices
              </b-button>
            </div>
          </div>
          <b-field
            label="Search By"
            label-position="on-border"
            class="search-field is-marginless"
          >
            <template #message>
              <p v-if="searchQuery.length && searchQuery.length < 3">
                Enter at least 3 characters
              </p>
              <p v-else-if="searchResults">
                Found <b>{{ $_featurePermissions.SHARED_MODIFIER_GROUPS ? searchResults.length : totalResultsCount }}</b> results
              </p>
            </template>
            <b-select v-if="$_featurePermissions.SHARED_MODIFIER_GROUPS" v-model="searchResource">
              <option value="categories">Categories</option>
              <option value="items">Items</option>
              <option value="modifierGroups">Modifier Groups</option>
              <option value="modifiers">Modifiers</option>
            </b-select>
            <b-select v-model="searchType" :disabled="!allCategories.length">
              <option value="name">Name</option>
              <option value="pos">PLU#</option>
            </b-select>
            <b-input
              v-model="searchQuery"
              expanded
              placeholder="Search"
              :icon-right="searchQuery ? 'times-circle' : ''"
              icon-right-clickable
              icon="search"
              :disabled="!allCategories.length"
              @icon-right-click="searchQuery = ''"
            />
          </b-field>
        </div>

        <!-- EMPTY TABLE PLACEHOLDER -->
        <!-- - - - - - - - - - - - - -->
        <empty-table-loader
          v-if="isLoadingSearchResults || (searchResults && !searchResults.length)"
          :loading="isLoadingSearchResults"
          :has-icon="false"
          message="No Results Found"
        />

        <!-- TABLE CONTENT -->
        <!-- - - - - - - - -->
        <transition v-else name="fade-down" mode="out-in">
          <div
            :key="selectedStoreId"
            class="nested-table"
            :class="{
              'show-inventory': $_menuPermissions.UPDATE_INVENTORY,
              'hide-prices':!$_selectedMerchant.features.menuPrices
            }"
          >
            <div v-show="categories.length" class="table-heading">
              <div class="row">
                <span>Name</span>
                <span v-if="$_selectedMerchant.features.menuPrices">Price</span>
                <span v-if="$_menuPermissions.UPDATE_INVENTORY" class="justify-center">Inventory</span>
                <span class="align-right">
                  <b-button
                    v-if="$can('update', 'StoreMenuItem', 'isDisabled') && $_menuPermissions.UPDATE_VISIBILITY"
                    v-tippy="{
                      content: `${allResourcesDisabled ? 'Show' : 'Hide'} All`,
                      placement: 'left'
                    }"
                    class="is-transparent"
                    @click="confirmToggleAllDisabled"
                  >
                    <b-icon
                      v-if="visibilityLoading.all"
                      icon="spinner-third"
                      class="spin"
                      size="is-small"
                    />
                    <b-icon
                      v-else-if="!($_featurePermissions.SHARED_MODIFIER_GROUPS && searchResults && searchResults.length)"
                      size="is-small"
                      :icon="allResourcesDisabled ? 'eye-slash' : 'eye'"
                      :class="{'has-text-danger': allResourcesDisabled}"
                    />
                  </b-button>
                </span>
              </div>
            </div>

            <ul>
              <!-- LOCATION HAS NO ORDER MODES ENABLED -->
              <!-- - - - - - - - - - - - - - - - - - - -->
              <li v-if="!supportedMenuTypeIds.length" class="pad-x pad-y-xl">
                <empty-state-card
                  type="is-warning"
                  icon="utensils"
                  icon-size="fa-4x"
                  title="No Order Modes Enabled"
                >
                  <template #message>
                    <p v-if="$can('update', 'StoreMapping')">Please enable at least one Order Mode for this location.</p>
                    <p v-else>This location does not have any Order Modes enabled.</p>
                  </template>
                  <template v-if="$can('update', 'StoreMapping')" #buttons>
                    <div class="buttons is-mobile-centered">
                      <router-link
                        class="button is-dark is-rounded"
                        :to="{ name: 'storeConfiguration', params: { storeId: selectedStore.storeId, tabName: 'ordering-modes' } }"
                      >
                        Manage Order Modes
                      </router-link>
                    </div>
                  </template>
                </empty-state-card>
              </li>

              <!-- NO MENUS EXIST FOR THIS LOCATIONS ENABLED ORDER MODES -->
              <!-- - - - - - - - - - - - - - - - - - - - - - - - - - - - -->
              <li v-else-if="!allCategories.length" class="pad-x pad-y-xl">
                <empty-state-card
                  type="is-warning"
                  icon="utensils"
                  icon-size="fa-4x"
                  title="No Supported Menus"
                >
                  <template #message>
                    <p>There are no menus set up for this location's enabled Order Modes.</p>
                    <p>{{ getNoMenuErrorMessage() }}</p>
                  </template>
                  <template v-if="isCardfreeAdmin || hasRoleType(roleTypes.MERCHANT_ADMIN)" #buttons>
                    <div class="buttons is-mobile-centered">
                      <router-link
                        class="button is-dark is-rounded"
                        :to="{ name: 'menuManagement' }"
                      >
                        Manage Menu
                      </router-link>
                    </div>
                  </template>
                </empty-state-card>
              </li>

              <template v-else>
                <!-- MENU SEARCH V2 - SHARED MOD GROUPS -->
                <store-menu-search-v2
                  v-if="$_featurePermissions.SHARED_MODIFIER_GROUPS && searchResults && searchResults.length"
                  :search-results="searchResults"
                  :resource-type="searchResource"
                  for-store
                  :selected-store-id="selectedStoreId"
                  :selected-store-items="selectedStoreItems"
                  :store-menu-category-schedules="storeMenuCategorySchedules"
                  :visible-category-ids="visibleCategoryIds"
                  :store-items-with-changed-inventories="storeItemsWithChangedInventories"
                  :store-menu-item-schedules="storeMenuItemSchedules"
                  :visible-modifier-group-ids="visibleModifierGroupIds"
                  :store-modifiers-for-selected-store="storeModifiersForSelectedStore"
                  @shared-mod-group-search-action="handleSharedModGroupSearchAction"
                />

                <!-- --------------------------- -->
                <!-- CATEGORY ------------------ -->
                <!-- --------------------------- -->
                <template v-else>
                  <li
                    v-for="category in categories"
                    :key="`category-${category.id}`"
                    :class="{'is-open': isOpen.category[category.id]}"
                    :data-category-id="category.id"
                  >
                    <div class="row">
                      <span>
                        <span class="link-inverted" @click="toggleCategory(category.id)">
                          <span v-if="!searchResults" class="has-text-weight-bold">{{ category.displayName }}</span>
                          <template v-else>
                            <span v-for="chunk in highlightSearchTerm(category.displayName)" :key="chunk.key" :class="chunk.match && 'search-term'">{{ chunk.text }}</span>
                          </template>
                          <b-icon
                            size="is-small"
                            pack="far"
                            :icon="isFetchingItems(category.id) || isFetchingStoreItemSchedules(category.id) ? 'spinner-third' : 'angle-right'"
                            :class="[
                              'open-indicator',
                              {
                                'is-open': isOpen.category[category.id],
                                'spin': isFetchingItems(category.id) || isFetchingStoreItemSchedules(category.id)
                              }
                            ]"
                          />
                        </span>
                      </span>
                      <span v-if="$_selectedMerchant.features.menuPrices" />
                      <span v-if="$_menuPermissions.UPDATE_INVENTORY" />
                      <span class="align-right">
                        <b-button
                          v-if="$can('read', 'StoreMenuCategorySchedule') && hasStoreCategorySchedule(category.id)"
                          v-tippy="{ content: 'Restricted Availability', placement: 'left', delay: [333, 0] }"
                          class="is-transparent"
                          @click="openCategoryDayPartScheduleModal(category)"
                        >
                          <b-icon icon="calendar" pack="far" />
                        </b-button>
                        <b-button class="is-transparent" @click="openCategoryDayPartScheduleModal(category)">
                          <b-icon v-if="$can('update', 'StoreMenuCategorySchedule') && $_menuPermissions.MANAGE_DAYPART_SCHEDULE" icon="pencil" size="is-small" />
                          <span v-else>View</span>
                        </b-button>
                        <b-button
                          v-if="$can('update', 'StoreMenuItem', 'isDisabled') && $_menuPermissions.UPDATE_VISIBILITY"
                          class="is-transparent"
                          @click="toggleCategoryDisabled(category)"
                        >
                          <b-icon
                            v-if="visibilityLoading.category[category.id] || visibilityLoading.all"
                            icon="spinner-third"
                            class="spin"
                            size="is-small"
                          />
                          <b-icon
                            v-else
                            :icon="getCategoryDisabled(category) ? 'eye-slash' : 'eye'"
                            :class="{'has-text-danger': getCategoryDisabled(category)}"
                            size="is-small"
                          />
                        </b-button>
                        <span v-else />
                      </span>
                    </div>
                    <template v-if="isOpen.category[category.id]">
                      <ul class="nested-table-section">
                        <!-- --------------------------- -->
                        <!-- ITEM ---------------------- -->
                        <!-- --------------------------- -->
                        <li v-if="!sortedAvailableMenuItems({ categoryId: category.id, menuItems: category.menuItems }).length">
                          <div class="row sub-row">
                            <span class="has-text-grey">No Items</span>
                            <span v-if="$_selectedMerchant.features.menuPrices" />
                            <span />
                          </div>
                        </li>
                        <template v-else>
                          <li class="nested-table-section-title">Menu Items</li>
                          <li
                            v-for="item in sortedAvailableMenuItems({ categoryId: category.id, menuItems: category.menuItems })"

                            :key="`item-${`${category.id}-${item.id}`}`"
                            :class="{'is-open': isOpen.item[`${category.id}-${item.id}`]}"
                            :data-item-id="item.id"
                          >
                            <div class="row sub-row">
                              <span>
                                <span class="link-inverted" @click="toggleItem(`${category.id}-${item.id}`)">
                                  <span v-if="!searchResults" class="has-text-weight-bold">{{ item.displayName }}</span>
                                  <template v-else>
                                    <span v-for="chunk in highlightSearchTerm(item.displayName)" :key="chunk.key" :class="(chunk.match || isMatchedPlu(item)) && 'search-term'">{{ chunk.text }}</span>
                                  </template>
                                  <b-icon
                                    size="is-small"
                                    pack="far"
                                    :icon="isFetchingModifierGroups(`${category.id}-${item.id}`) ? 'spinner-third' : 'angle-right'"
                                    :class="[
                                      'open-indicator',
                                      {
                                        'is-open': isOpen.item[`${category.id}-${item.id}`],
                                        'spin': isFetchingModifierGroups(`${category.id}-${item.id}`)
                                      }
                                    ]"
                                  />
                                </span>
                              </span>
                              <span v-if="$_selectedMerchant.features.menuPrices">
                                <template v-if="getStoreItemPrice(item)">
                                  {{ getStoreItemPrice(item) | dollars }}
                                </template>
                                <span v-else class="has-text-grey">N/A</span>
                              </span>
                              <span v-if="$_menuPermissions.UPDATE_INVENTORY" class="justify-center">
                                <template v-if="getStoreItem(item)">
                                  <b-numberinput
                                    v-if="$can('update', 'StoreMenuItem')"
                                    controls-position="compact"
                                    placeholder="∞"
                                    :class="['has-three-digits', {'infinite-inventory': getStoreItemInventory(item) === null}]"
                                    max="999"
                                    :min="getStoreItemInventory(item) === null ? 0 : -1 /* * * *
                                  * allows a user to go down to -1 to specify 'no value' but *
                                  * once there is no value, the minimum value becomes 0 so   *
                                  * that the minus button is disabled                        *
                                  * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */"
                                    :value="getStoreItemInventory(item)"
                                    @input="handleInventoryChange(item, $event)"
                                  />
                                  <p v-else>
                                    {{ getStoreItem(item).inventoryCount }}
                                  </p>
                                </template>
                              </span>
                              <span class="align-right is-flex">
                                <b-icon
                                  v-if="showSuspendedIconForItem(item)"
                                  v-tippy="{ content: `Unavailable until ${adjustDateToStoreTimezone(getStoreItemSuspendUntilDate(item)).format('MM/DD/YY h:mm A')} ${timeZoneAbbr}`, placement: 'left', delay: [333, 0] }"
                                  icon="alarm-clock"
                                  size="is-small"
                                  class="mar-r-xs"
                                />
                                <b-button
                                  v-if="storeItemHasSchedule(item.id) && $can('read', 'StoreMenuItemSchedule') && $_menuPermissions.MANAGE_DAYPART_SCHEDULE"
                                  v-tippy="{ content: 'Restricted Availability', placement: 'left', delay: [333, 0] }"
                                  class="is-transparent"
                                  @click="openStoreItemModal({ item, storeId: selectedStoreId, defaultTabIndex: 1 })"
                                >
                                  <b-icon icon="calendar" pack="far" />
                                </b-button>
                                <b-button
                                  v-if="(($can('read', 'StoreMenuItem', 'price') || $can('read', 'StoreMenuItem', 'totalSalesTaxRate'))
                                    && ($_menuPermissions.READ_PRICE_TAX || $_menuPermissions.UPDATE_INVENTORY))
                                    || ($can('read', 'StoreMenuItemSchedule') && $_menuPermissions.MANAGE_DAYPART_SCHEDULE)"
                                  class="is-transparent"
                                  @click="openStoreItemModal({ item, storeId: selectedStoreId })"
                                >
                                  <b-icon
                                    v-if="(($can('update', 'StoreMenuItem', 'price') || $can('update', 'StoreMenuItem', 'totalSalesTaxRate'))
                                      && ($_menuPermissions.UPDATE_PRICE_TAX || $_menuPermissions.UPDATE_INVENTORY))
                                      || ($can('update', 'StoreMenuItemSchedule') && $_menuPermissions.MANAGE_DAYPART_SCHEDULE)"
                                    icon="pencil"
                                    size="is-small"
                                  />
                                  <span v-else>View</span>
                                </b-button>
                                <b-button
                                  v-if="$can('update', 'StoreMenuItem', 'isDisabled') && $_menuPermissions.UPDATE_VISIBILITY"
                                  class="is-transparent"
                                  @click="toggleStoreItemDisabled(item)"
                                >
                                  <b-icon
                                    v-if="visibilityLoading.item[item.id] || visibilityLoading.all"
                                    icon="spinner-third"
                                    class="spin"
                                    size="is-small"
                                  />
                                  <b-icon
                                    v-else
                                    v-tippy="{ content: getVisibilityTooltip(getStoreItem(item)), placement: 'left', onShow: () => !!getStoreItem(item) }"
                                    :icon="getStoreItemDisabled(item) ? 'eye-slash' : 'eye'"
                                    :class="{'has-text-danger': getStoreItemDisabled(item)}"
                                    size="is-small"
                                  />
                                </b-button>
                                <span v-else />
                              </span>
                            </div>
                            <template v-if="isOpen.item[`${category.id}-${item.id}`]">
                              <!-- Keep until all merchants' data has been migrated to use the MenuItemModifierGroupItem table -->
                              <ul v-if="!$_featurePermissions.SHARED_MODIFIER_GROUPS" class="nested-table-section">
                                <!-- --------------------------- -->
                                <!-- MODIFIER GROUP ------------ -->
                                <!-- --------------------------- -->
                                <li v-if="!item.menuItemModifierGroups.length">
                                  <div class="row sub-row">
                                    <span class="has-text-grey">No Modifier Groups</span>
                                    <span v-if="$_selectedMerchant.features.menuPrices" />
                                    <span />
                                  </div>
                                </li>
                                <template v-else>
                                  <li class="nested-table-section-title">{{ $_featurePermissions.NESTED_MODIFIERS ? 'Groups' : 'Modifier Groups' }}</li>
                                  <li
                                    v-for="modifierGroup in bySortOrder(item.menuItemModifierGroups)"
                                    :key="`modifierGroup-${modifierGroup.id}`"
                                    :class="{'is-open': isOpen.modifierGroup[modifierGroup.id]}"
                                    :data-modifier-group-id="modifierGroup.id"
                                  >
                                    <div class="row sub-row">
                                      <span>
                                        <span class="link-inverted is-flex align-center" @click="toggleModifierGroup(modifierGroup.id)">
                                          <b-icon
                                            v-if="$_featurePermissions.NESTED_MODIFIERS && modifierGroup.type"
                                            type="is-primary"
                                            class="mar-r-xs"
                                            pack="fad"
                                            :icon="modifierGroupIcons[modifierGroup.type]"
                                          />
                                          <span v-if="!searchResults">{{ modifierGroup.displayName }}</span>
                                          <span v-else>
                                            <span v-for="chunk in highlightSearchTerm(modifierGroup.displayName)" :key="chunk.key" :class="chunk.match && 'search-term'">{{ chunk.text }}</span>
                                          </span>
                                          <b-icon
                                            size="is-small"
                                            pack="far"
                                            :icon="isFetchingModifiers(modifierGroup.id) ? 'spinner-third' : 'angle-right'"
                                            :class="[
                                              'open-indicator',
                                              {
                                                'is-open': isOpen.modifierGroup[modifierGroup.id],
                                                'spin': isFetchingModifiers(modifierGroup.id)
                                              }
                                            ]"
                                          />
                                        </span>
                                      </span>
                                      <span v-if="$_selectedMerchant.features.menuPrices" />
                                      <span v-if="$_menuPermissions.UPDATE_INVENTORY" />
                                      <span class="align-right">
                                        <b-button
                                          v-if="$can('update', 'StoreMenuItemModifier', 'isDisabled') && $_menuPermissions.UPDATE_VISIBILITY"
                                          :disabled="getStoreItemDisabled(item)"
                                          class="is-transparent"
                                          @click="toggleModifierGroupDisabled(modifierGroup)"
                                        >
                                          <b-icon
                                            v-if="visibilityLoading.modifierGroup[modifierGroup.id] || visibilityLoading.all"
                                            icon="spinner-third"
                                            class="spin"
                                            size="is-small"
                                          />
                                          <b-icon
                                            v-else
                                            :icon="getModifierGroupDisabled(modifierGroup) || getStoreItemDisabled(item) ? 'eye-slash' : 'eye'"
                                            size="is-small"
                                            :class="{
                                              'has-text-danger': getModifierGroupDisabled(modifierGroup),
                                              'has-text-grey': getStoreItemDisabled(item) && !getModifierGroupDisabled(modifierGroup)
                                            }"
                                          />
                                        </b-button>
                                      </span>
                                    </div>
                                    <template v-if="isOpen.modifierGroup[modifierGroup.id]">
                                      <!-- --------------------------- -->
                                      <!-- MODIFIER ------------------ -->
                                      <!-- --------------------------- -->
                                      <ul class="nested-table-section">
                                        <li v-if="!filterByAvailability(modifierGroup.menuItemModifiers).length">
                                          <div class="row sub-row">
                                            <span class="has-text-grey">{{ modifierGroup.type === modifierGroupTypes.COMBO ? 'No Combo Items' : 'No Modifiers' }}</span>
                                            <span v-if="$_selectedMerchant.features.menuPrices" />
                                            <span />
                                          </div>
                                        </li>
                                        <template v-else>
                                          <template v-if="$_featurePermissions.NESTED_MODIFIERS && modifierGroup.type">
                                            <li v-if="modifierGroup.type === modifierGroupTypes.PORTION" class="nested-table-section-title">Portion Types</li>
                                            <li v-else-if="modifierGroup.type === modifierGroupTypes.COMBO" class="nested-table-section-title">Combo Items</li>
                                          </template>
                                          <li v-else class="nested-table-section-title">Modifiers</li>
                                          <li
                                            v-for="modifier in bySortOrder(filterByAvailability(modifierGroup.menuItemModifiers))"
                                            :key="`modifier-${modifier.id}`"
                                            :class="{'is-open': isOpen.modifier[modifier.id]}"
                                            :data-modifier-id="modifier.id"
                                          >
                                            <div class="row sub-row">
                                              <span>
                                                <img
                                                  v-if="$_featurePermissions.NESTED_MODIFIERS && modifierGroupTypes.PORTION"
                                                  :class="['mar-r-sm']"
                                                  :style="{ transform: `rotate(${modifier.displayName === 'Right Half' ? 180 : 0}deg)` }"
                                                  :src="getPortionTemplateTypeIcon(modifier.modifierTemplate)"
                                                >
                                                <span
                                                  :class="$_featurePermissions.NESTED_MODIFIERS && modifier.canHaveNestedMenuItemModifierGroups && 'link-inverted'"
                                                  @click="$_featurePermissions.NESTED_MODIFIERS && modifier.canHaveNestedMenuItemModifierGroups && toggleOpenState({ id: modifier.id, type: 'modifier' })"
                                                >
                                                  <span v-if="!searchResults">{{ modifier.displayName }}</span>
                                                  <span v-else>
                                                    <span v-for="chunk in highlightSearchTerm(modifier.displayName)" :key="chunk.key" :class="(chunk.match || isMatchedPlu(modifier)) && 'search-term'">{{ chunk.text }}</span>
                                                  </span>
                                                  <b-icon
                                                    v-if="$_featurePermissions.NESTED_MODIFIERS && modifier.canHaveNestedMenuItemModifierGroups"
                                                    size="is-small"
                                                    pack="far"
                                                    icon="angle-right"
                                                    :class="[
                                                      'open-indicator',
                                                      { 'is-open': isOpen.modifier[modifier.id] }
                                                    ]"
                                                  />
                                                </span>
                                              </span>
                                              <span v-if="$_selectedMerchant.features.menuPrices">
                                                <template v-if="!$_featurePermissions.NESTED_MODIFIERS || modifierGroup.type !== modifierGroupTypes.PORTION">
                                                  <template v-if="getStoreModifierPrice(modifier)">
                                                    <div class="is-flex-align-center">
                                                      <span>{{ getStoreModifierPrice(modifier) | dollars }}</span>
                                                      <b-icon
                                                        v-if="getModifierPriceOverrides(modifier).length"
                                                        v-tippy="{
                                                          content: getPriceOverridesTippyContent(modifier),
                                                          placement: 'left'
                                                        }"
                                                        icon="circle-dollar"
                                                        size="is-small"
                                                        class="has-text-info mar-l-xs"
                                                      />
                                                    </div>
                                                  </template>
                                                  <span v-else class="has-text-grey">N/A</span>
                                                </template>
                                              </span>
                                              <span v-if="$_menuPermissions.UPDATE_INVENTORY" />
                                              <span class="align-right">
                                                <b-icon
                                                  v-if="showSuspendedIconForModifier(modifier)"
                                                  v-tippy="{
                                                    content: `Unavailable until ${adjustDateToStoreTimezone(getStoreModifierSuspendUntilDate(modifier)).format('MM/DD/YY h:mm A')} ${timeZoneAbbr}`,
                                                    placement: 'left',
                                                    delay: [333, 0]
                                                  }"
                                                  icon="alarm-clock"
                                                  size="is-small"
                                                />
                                                <b-button
                                                  v-if="!$_featurePermissions.NESTED_MODIFIERS || modifierGroup.type !== modifierGroupTypes.PORTION"
                                                  class="is-transparent"
                                                  @click="openStoreModifierModal({ modifier, storeId: selectedStoreId })"
                                                >
                                                  <b-icon
                                                    v-if="($can('update', 'StoreMenuItemModifier', 'price') || $can('update', 'StoreMenuItemModifier', 'totalSalesTaxRate'))
                                                      && $_menuPermissions.UPDATE_PRICE_TAX"
                                                    icon="pencil"
                                                    size="is-small"
                                                  />
                                                  <span v-else>View</span>
                                                </b-button>
                                                <b-button
                                                  v-if="$can('update', 'StoreMenuItemModifier', 'isDisabled') && $_menuPermissions.UPDATE_VISIBILITY"
                                                  class="is-transparent"
                                                  :disabled="getStoreItemDisabled(item)"
                                                  @click="toggleStoreModifierDisabled(modifier)"
                                                >
                                                  <b-icon
                                                    v-if="visibilityLoading.modifier[modifier.id] || visibilityLoading.all"
                                                    icon="spinner-third"
                                                    class="spin"
                                                    size="is-small"
                                                  />
                                                  <b-icon
                                                    v-else
                                                    v-tippy="{ content: getVisibilityTooltip(getStoreModifier(modifier)), placement: 'left', onShow: () => !!getStoreModifier(modifier) }"
                                                    :icon="getStoreModifierDisabled(modifier) || getStoreItemDisabled(item) ? 'eye-slash' : 'eye'"
                                                    size="is-small"
                                                    :class="{
                                                      'has-text-danger': getStoreModifierDisabled(modifier),
                                                      'has-text-grey': getStoreItemDisabled(item) && !getStoreModifierDisabled(modifier)
                                                    }"
                                                  />
                                                </b-button>
                                                <span v-else />
                                              </span>
                                            </div>

                                            <template v-if="isOpen.modifier[modifier.id]">
                                              <!-- --------------------------- -->
                                              <!-- SUB MODIFIER GROUP -------- -->
                                              <!-- --------------------------- -->
                                              <ul class="nested-table-section">
                                                <li v-if="!filterByAvailability(modifier.menuItemModifierGroups).length">
                                                  <div class="row sub-row">
                                                    <span class="has-text-grey">No Modifier Groups</span>
                                                    <span v-if="$_selectedMerchant.features.menuPrices" />
                                                    <span />
                                                  </div>
                                                </li>
                                                <template v-else>
                                                  <li class="nested-table-section-title">Modifier Groups</li>
                                                  <li
                                                    v-for="subModifierGroup in bySortOrder(modifier.menuItemModifierGroups)"
                                                    :key="`sub-modifier-group-${subModifierGroup.id}`"
                                                    :class="{'is-open': isOpen.modifierGroup[subModifierGroup.id]}"
                                                    :data-sub-modifier-group-id="subModifierGroup.id"
                                                  >
                                                    <div class="row sub-row">
                                                      <span>
                                                        <span class="link-inverted" @click="toggleModifierGroup(subModifierGroup.id)">
                                                          <span v-if="!searchResults">{{ subModifierGroup.displayName }}</span>
                                                          <span v-else>
                                                            <span v-for="chunk in highlightSearchTerm(subModifierGroup.displayName)" :key="chunk.key" :class="(chunk.match || isMatchedPlu(modifier)) && 'search-term'">{{ chunk.text }}</span>
                                                          </span>
                                                          <b-icon
                                                            size="is-small"
                                                            pack="far"
                                                            :icon="isFetchingModifiers(subModifierGroup.id) ? 'spinner-third' : 'angle-right'"
                                                            :class="[
                                                              'open-indicator',
                                                              {
                                                                'is-open': isOpen.modifierGroup[subModifierGroup.id],
                                                                'spin': isFetchingModifiers(subModifierGroup.id)
                                                              }
                                                            ]"
                                                          />
                                                        </span>
                                                      </span>
                                                      <span v-if="$_selectedMerchant.features.menuPrices" />
                                                      <span v-if="$_menuPermissions.UPDATE_INVENTORY" />
                                                      <span class="align-right">
                                                        <b-button
                                                          v-if="$can('update', 'StoreMenuItemModifier', 'isDisabled') && $_menuPermissions.UPDATE_VISIBILITY"
                                                          class="is-transparent"
                                                          :disabled="getStoreModifierDisabled(modifier) || getStoreItemDisabled(item)"
                                                          @click="toggleModifierGroupDisabled(subModifierGroup)"
                                                        >
                                                          <b-icon
                                                            v-if="visibilityLoading.modifierGroup[subModifierGroup.id] || visibilityLoading.all"
                                                            icon="spinner-third"
                                                            class="spin"
                                                            size="is-small"
                                                          />
                                                          <b-icon
                                                            v-else
                                                            :icon="getModifierGroupDisabled(subModifierGroup) || getStoreModifierDisabled(modifier) || getStoreItemDisabled(item) ? 'eye-slash' : 'eye'"
                                                            size="is-small"
                                                            :class="{
                                                              'has-text-danger': getModifierGroupDisabled(subModifierGroup),
                                                              'has-text-grey': (getStoreModifierDisabled(modifier) || getStoreItemDisabled(item)) && !getModifierGroupDisabled(subModifierGroup)
                                                            }"
                                                          />
                                                        </b-button>
                                                      </span>
                                                    </div>

                                                    <template v-if="isOpen.modifierGroup[subModifierGroup.id]">
                                                      <!-- --------------------------- -->
                                                      <!-- SUB MODIFIERS ------------- -->
                                                      <!-- --------------------------- -->
                                                      <ul class="nested-table-section">
                                                        <li v-if="!filterByAvailability(subModifierGroup.menuItemModifiers).length">
                                                          <div class="row sub-row">
                                                            <span class="has-text-grey">No Modifiers</span>
                                                            <span v-if="$_selectedMerchant.features.menuPrices" />
                                                            <span />
                                                          </div>
                                                        </li>
                                                        <template v-else>
                                                          <li class="nested-table-section-title">Modifiers</li>
                                                          <li
                                                            v-for="subModifier in bySortOrder(subModifierGroup.menuItemModifiers)"
                                                            :key="`sub-modifier-${subModifier.id}`"
                                                            :class="{'is-open': isOpen.modifier[subModifier.id]}"
                                                            :data-sub-modifier-id="subModifier.id"
                                                          >
                                                            <div class="row sub-row">
                                                              <span>
                                                                <template v-if="!searchResults">{{ subModifier.displayName }}</template>
                                                                <span v-else>
                                                                  <span v-for="chunk in highlightSearchTerm(subModifier.displayName)" :key="chunk.key" :class="(chunk.match || isMatchedPlu(modifier)) && 'search-term'">{{ chunk.text }}</span>
                                                                </span>
                                                              </span>
                                                              <span v-if="$_selectedMerchant.features.menuPrices">
                                                                <template v-if="getStoreModifierPrice(subModifier)">
                                                                  <div class="is-flex-align-center">
                                                                    <span>{{ getStoreModifierPrice(subModifier) | dollars }}</span>
                                                                    <b-icon
                                                                      v-if="getModifierPriceOverrides(subModifier).length"
                                                                      v-tippy="{
                                                                        content: getPriceOverridesTippyContent(subModifier),
                                                                        placement: 'left'
                                                                      }"
                                                                      icon="circle-dollar"
                                                                      size="is-small"
                                                                      class="has-text-info mar-l-xs"
                                                                    />
                                                                  </div>
                                                                </template>
                                                                <span v-else class="has-text-grey">N/A</span>
                                                              </span>
                                                              <span v-if="$_menuPermissions.UPDATE_INVENTORY" />
                                                              <span class="align-right">
                                                                <b-icon
                                                                  v-if="showSuspendedIconForModifier(subModifier)"
                                                                  v-tippy="{
                                                                    content: `Unavailable until ${adjustDateToStoreTimezone(getStoreModifierSuspendUntilDate(subModifier))} ${timeZoneAbbr}`,
                                                                    placement: 'left',
                                                                    delay: [333, 0]
                                                                  }"
                                                                  icon="alarm-clock"
                                                                  size="is-small"
                                                                  class="mar-r-xs"
                                                                />
                                                                <b-button
                                                                  v-if="($can('read', 'StoreMenuItemModifier', 'price') || $can('read', 'StoreMenuItemModifier', 'totalSalesTaxRate'))
                                                                    && $_menuPermissions.READ_PRICE_TAX"
                                                                  class="is-transparent"
                                                                  @click="openStoreModifierModal({ modifier: subModifier, storeId: selectedStoreId })"
                                                                >
                                                                  <b-icon
                                                                    v-if="($can('update', 'StoreMenuItemModifier', 'price') || $can('update', 'StoreMenuItemModifier', 'totalSalesTaxRate'))
                                                                      && $_menuPermissions.UPDATE_PRICE_TAX"
                                                                    icon="pencil"
                                                                    size="is-small"
                                                                  />
                                                                  <span v-else>View</span>
                                                                </b-button>
                                                                <span v-else />
                                                                <b-button
                                                                  v-if="$can('update', 'StoreMenuItemModifier', 'isDisabled') && $_menuPermissions.UPDATE_VISIBILITY"
                                                                  class="is-transparent"
                                                                  :disabled="getStoreItemDisabled(item) || getStoreModifierDisabled(modifier)"
                                                                  @click="toggleStoreModifierDisabled(subModifier)"
                                                                >
                                                                  <b-icon
                                                                    v-if="visibilityLoading.modifier[subModifier.id] || visibilityLoading.all"
                                                                    icon="spinner-third"
                                                                    class="spin"
                                                                    size="is-small"
                                                                  />
                                                                  <b-icon
                                                                    v-else
                                                                    v-tippy="{ content: getVisibilityTooltip(getStoreModifier(subModifier)), placement: 'left', onShow: () => !!getStoreModifier(subModifier)}"
                                                                    :icon="getStoreModifierDisabled(subModifier) || getStoreModifierDisabled(modifier) || getStoreItemDisabled(item) ? 'eye-slash' : 'eye'"
                                                                    size="is-small"
                                                                    :class="{
                                                                      'has-text-danger': getStoreModifierDisabled(subModifier),
                                                                      'has-text-grey': (getStoreModifierDisabled(modifier) || getStoreItemDisabled(item)) && !getStoreModifierDisabled(subModifier)
                                                                    }"
                                                                  />
                                                                </b-button>
                                                                <span v-else />
                                                              </span>
                                                            </div>
                                                          </li>
                                                        </template>
                                                      </ul>
                                                    </template>

                                                  </li>
                                                </template>
                                              </ul>
                                            </template>

                                          </li>
                                        </template>
                                      </ul>
                                    </template>
                                  </li>
                                </template>
                              </ul>

                              <ul v-if="$_featurePermissions.SHARED_MODIFIER_GROUPS" class="nested-table-section">
                                <!-- --------------------------- -->
                                <!-- MODIFIER GROUP ------------ -->
                                <!-- --------------------------- -->
                                <li v-if="!item.menuItemModifierGroupItems.length">
                                  <div class="row sub-row">
                                    <span class="has-text-grey">No Modifier Groups</span>
                                    <span v-if="$_selectedMerchant.features.menuPrices" />
                                    <span />
                                  </div>
                                </li>
                                <template v-else>
                                  <li class="nested-table-section-title">{{ $_featurePermissions.NESTED_MODIFIERS ? 'Groups' : 'Modifier Groups' }}</li>
                                  <li
                                    v-for="menuItemModifierGroupItem in bySortOrder(item.menuItemModifierGroupItems)"
                                    :key="`modifierGroup-${menuItemModifierGroupItem.modifierGroup.id}`"
                                    :class="{'is-open': isOpen.modifierGroup[`${menuItemModifierGroupItem.modifierGroupId}-${menuItemModifierGroupItem.id}`]}"
                                    :data-modifier-group-id="menuItemModifierGroupItem.modifierGroupId"
                                  >
                                    <div class="row sub-row">
                                      <span>
                                        <span
                                          class="link-inverted is-flex align-center"
                                          @click="toggleModifierGroup(
                                            menuItemModifierGroupItem.modifierGroup.id,
                                            menuItemModifierGroupItem.id,
                                            menuItemModifierGroupItem.menuItemId
                                          )"
                                        >
                                          <b-icon
                                            v-if="$_featurePermissions.NESTED_MODIFIERS && menuItemModifierGroupItem.modifierGroup.type"
                                            type="is-primary"
                                            class="mar-r-xs"
                                            pack="fad"
                                            :icon="modifierGroupIcons[menuItemModifierGroupItem.modifierGroup.type]"
                                          />
                                          <span v-if="!searchResults">{{ menuItemModifierGroupItem.modifierGroup.displayName }}</span>
                                          <span v-else>
                                            <span v-for="chunk in highlightSearchTerm(menuItemModifierGroupItem.modifierGroup.displayName)" :key="chunk.key" :class="chunk.match && 'search-term'">{{ chunk.text }}</span>
                                          </span>
                                          <b-icon
                                            size="is-small"
                                            pack="far"
                                            :icon="isFetchingModifiers(menuItemModifierGroupItem.modifierGroup.id) ? 'spinner-third' : 'angle-right'"
                                            :class="[
                                              'open-indicator',
                                              {
                                                'is-open': isOpen.modifierGroup[`${menuItemModifierGroupItem.modifierGroupId}-${menuItemModifierGroupItem.id}`],
                                                'spin': isFetchingModifiers(menuItemModifierGroupItem.modifierGroup.id)
                                              }
                                            ]"
                                          />
                                        </span>
                                      </span>
                                      <span v-if="$_selectedMerchant.features.menuPrices" />
                                      <span v-if="$_menuPermissions.UPDATE_INVENTORY" />
                                      <span class="align-right">
                                        <b-button
                                          v-if="$can('update', 'StoreMenuItemModifier', 'isDisabled') && $_menuPermissions.UPDATE_VISIBILITY"
                                          :disabled="getStoreItemDisabled(item)"
                                          class="is-transparent"
                                          @click="toggleModifierGroupDisabled(menuItemModifierGroupItem.modifierGroup)"
                                        >
                                          <b-icon
                                            v-if="visibilityLoading.modifierGroup[menuItemModifierGroupItem.modifierGroup.id] || visibilityLoading.all"
                                            icon="spinner-third"
                                            class="spin"
                                            size="is-small"
                                          />
                                          <b-icon
                                            v-else
                                            :icon="getModifierGroupDisabled(menuItemModifierGroupItem.modifierGroup) || getStoreItemDisabled(item) ? 'eye-slash' : 'eye'"
                                            size="is-small"
                                            :class="{
                                              'has-text-danger': getModifierGroupDisabled(menuItemModifierGroupItem.modifierGroup),
                                              'has-text-grey': getStoreItemDisabled(item) && !getModifierGroupDisabled(menuItemModifierGroupItem.modifierGroup)
                                            }"
                                          />
                                        </b-button>
                                      </span>
                                    </div>
                                    <template v-if="isOpen.modifierGroup[`${menuItemModifierGroupItem.modifierGroupId}-${menuItemModifierGroupItem.id}`]">
                                      <!-- --------------------------- -->
                                      <!-- MODIFIER ------------------ -->
                                      <!-- --------------------------- -->
                                      <ul class="nested-table-section">
                                        <li v-if="!filterByAvailability(menuItemModifierGroupItem.modifierGroup.menuItemModifiers).length">
                                          <div class="row sub-row">
                                            <span class="has-text-grey">{{ menuItemModifierGroupItem.modifierGroup.type === modifierGroupTypes.COMBO ? 'No Combo Items' : 'No Modifiers' }}</span>
                                            <span v-if="$_selectedMerchant.features.menuPrices" />
                                            <span />
                                          </div>
                                        </li>
                                        <template v-else>
                                          <template v-if="$_featurePermissions.NESTED_MODIFIERS && menuItemModifierGroupItem.modifierGroup.type">
                                            <li v-if="menuItemModifierGroupItem.modifierGroup.type === modifierGroupTypes.PORTION" class="nested-table-section-title">Portion Types</li>
                                            <li v-else-if="menuItemModifierGroupItem.modifierGroup.type === modifierGroupTypes.COMBO" class="nested-table-section-title">Combo Items</li>
                                          </template>
                                          <li v-else class="nested-table-section-title">Modifiers</li>
                                          <li
                                            v-for="modifier in bySortOrder(filterByAvailability(menuItemModifierGroupItem.modifierGroup.menuItemModifiers))"
                                            :key="`modifier-${modifier.id}-${menuItemModifierGroupItem.id}`"
                                            :class="{'is-open': isOpen.modifier[`${modifier.id}-${menuItemModifierGroupItem.id}`]}"
                                            :data-modifier-id="modifier.id"
                                          >
                                            <div class="row sub-row">
                                              <span>
                                                <img
                                                  v-if="$_featurePermissions.NESTED_MODIFIERS && modifierGroupTypes.PORTION"
                                                  :class="['mar-r-sm']"
                                                  :style="{ transform: `rotate(${modifier.displayName === 'Right Half' ? 180 : 0}deg)` }"
                                                  :src="getPortionTemplateTypeIcon(modifier.modifierTemplate)"
                                                >
                                                <span
                                                  :class="$_featurePermissions.NESTED_MODIFIERS && modifier.canHaveNestedMenuItemModifierGroups && 'link-inverted'"
                                                  @click="$_featurePermissions.NESTED_MODIFIERS && modifier.canHaveNestedMenuItemModifierGroups && toggleOpenState({ id: `${modifier.id}-${menuItemModifierGroupItem.id}`, type: 'modifier' })"
                                                >
                                                  <span v-if="!searchResults">{{ modifier.displayName }}</span>
                                                  <span v-else>
                                                    <span v-for="chunk in highlightSearchTerm(modifier.displayName)" :key="chunk.key" :class="(chunk.match || isMatchedPlu(modifier)) && 'search-term'">{{ chunk.text }}</span>
                                                  </span>
                                                  <b-icon
                                                    v-if="$_featurePermissions.NESTED_MODIFIERS && modifier.canHaveNestedMenuItemModifierGroups"
                                                    size="is-small"
                                                    pack="far"
                                                    icon="angle-right"
                                                    :class="[
                                                      'open-indicator',
                                                      { 'is-open': isOpen.modifier[`${modifier.id}-${menuItemModifierGroupItem.id}`] }
                                                    ]"
                                                  />
                                                </span>
                                              </span>
                                              <span v-if="$_selectedMerchant.features.menuPrices">
                                                <template v-if="!$_featurePermissions.NESTED_MODIFIERS || menuItemModifierGroupItem.modifierGroup.type !== modifierGroupTypes.PORTION">
                                                  <template v-if="getStoreModifierPrice(modifier)">
                                                    <div class="is-flex-align-center">
                                                      <span>{{ getStoreModifierPrice(modifier) | dollars }}</span>
                                                      <b-icon
                                                        v-if="getModifierPriceOverrides(modifier).length"
                                                        v-tippy="{
                                                          content: getPriceOverridesTippyContent(modifier),
                                                          placement: 'left'
                                                        }"
                                                        icon="circle-dollar"
                                                        size="is-small"
                                                        class="has-text-info mar-l-xs"
                                                      />
                                                    </div>
                                                  </template>
                                                  <span v-else class="has-text-grey">N/A</span>
                                                </template>
                                              </span>
                                              <span v-if="$_menuPermissions.UPDATE_INVENTORY" />
                                              <span class="align-right">
                                                <b-icon
                                                  v-if="getStoreModifierSuspendUntilDate(modifier)"
                                                  v-tippy="{
                                                    content: `Unavailable until ${adjustDateToStoreTimezone(getStoreModifierSuspendUntilDate(modifier))} ${timeZoneAbbr}`,
                                                    placement: 'left',
                                                    delay: [333, 0]
                                                  }"
                                                  icon="alarm-clock"
                                                  size="is-small"
                                                />
                                                <b-button
                                                  v-if="(!$_featurePermissions.NESTED_MODIFIERS || menuItemModifierGroupItem.modifierGroup.type !== modifierGroupTypes.PORTION)"
                                                  class="is-transparent"
                                                  @click="openStoreModifierModal({ modifier, storeId: selectedStoreId })"
                                                >
                                                  <b-icon
                                                    v-if="($can('update', 'StoreMenuItemModifier', 'price') || $can('update', 'StoreMenuItemModifier', 'totalSalesTaxRate'))
                                                      && $_menuPermissions.UPDATE_PRICE_TAX"
                                                    icon="pencil"
                                                    size="is-small"
                                                  />
                                                  <span v-else>View</span>
                                                </b-button>
                                                <b-button
                                                  v-if="$can('update', 'StoreMenuItemModifier', 'isDisabled') && $_menuPermissions.UPDATE_VISIBILITY"
                                                  class="is-transparent"
                                                  :disabled="getStoreItemDisabled(item)"
                                                  @click="toggleStoreModifierDisabled(modifier)"
                                                >
                                                  <b-icon
                                                    v-if="visibilityLoading.modifier[modifier.id] || visibilityLoading.all"
                                                    icon="spinner-third"
                                                    class="spin"
                                                    size="is-small"
                                                  />
                                                  <b-icon
                                                    v-else
                                                    v-tippy="{ content: getVisibilityTooltip(getStoreModifier(modifier)), placement: 'left', onShow: () => !!getStoreModifier(modifier) }"
                                                    :icon="getStoreModifierDisabled(modifier) || getStoreItemDisabled(item) ? 'eye-slash' : 'eye'"
                                                    size="is-small"
                                                    :class="{
                                                      'has-text-danger': getStoreModifierDisabled(modifier),
                                                      'has-text-grey': getStoreItemDisabled(item) && !getStoreModifierDisabled(modifier)
                                                    }"
                                                  />
                                                </b-button>
                                                <span v-else />
                                              </span>
                                            </div>

                                            <template v-if="isOpen.modifier[`${modifier.id}-${menuItemModifierGroupItem.id}`]">
                                              <!-- --------------------------- -->
                                              <!-- SUB MODIFIER GROUP -------- -->
                                              <!-- --------------------------- -->
                                              <ul class="nested-table-section">
                                                <li v-if="!filterByAvailability(filterMenuItemModifierGroupItemsByItemId(modifier.menuItemModifierGroupItems, item.id)).length">
                                                  <div class="row sub-row">
                                                    <span class="has-text-grey">No Modifier Groups</span>
                                                    <span v-if="$_selectedMerchant.features.menuPrices" />
                                                    <span />
                                                  </div>
                                                </li>
                                                <template v-else>
                                                  <li class="nested-table-section-title">Modifier Groups</li>
                                                  <li
                                                    v-for="subMenuItemModifierGroupItem in bySortOrder(filterMenuItemModifierGroupItemsByItemId(modifier.menuItemModifierGroupItems, item.id))"
                                                    :key="`sub-modifier-group-${subMenuItemModifierGroupItem.id}`"
                                                    :class="{'is-open': isOpen.modifierGroup[`${subMenuItemModifierGroupItem.modifierGroupId}-${subMenuItemModifierGroupItem.id}`]}"
                                                    :data-sub-modifier-group-id="subMenuItemModifierGroupItem.modifierGroupId"
                                                  >
                                                    <div class="row sub-row">
                                                      <span>
                                                        <span
                                                          class="link-inverted"
                                                          @click="toggleModifierGroup(
                                                            subMenuItemModifierGroupItem.modifierGroupId,
                                                            subMenuItemModifierGroupItem.id,
                                                            subMenuItemModifierGroupItem.menuItemId
                                                          )"
                                                        >
                                                          <span v-if="!searchResults">{{ subMenuItemModifierGroupItem.modifierGroup.displayName }}</span>
                                                          <span v-else>
                                                            <span v-for="chunk in highlightSearchTerm(subMenuItemModifierGroupItem.modifierGroup.displayName)" :key="chunk.key" :class="(chunk.match || isMatchedPlu(modifier)) && 'search-term'">{{ chunk.text }}</span>
                                                          </span>
                                                          <b-icon
                                                            size="is-small"
                                                            pack="far"
                                                            :icon="isFetchingModifiers(subMenuItemModifierGroupItem.modifierGroup.id) ? 'spinner-third' : 'angle-right'"
                                                            :class="[
                                                              'open-indicator',
                                                              {
                                                                'is-open': isOpen.modifierGroup[`${subMenuItemModifierGroupItem.modifierGroupId}-${subMenuItemModifierGroupItem.id}`],
                                                                'spin': isFetchingModifiers(subMenuItemModifierGroupItem.modifierGroupId)
                                                              }
                                                            ]"
                                                          />
                                                        </span>
                                                      </span>
                                                      <span v-if="$_selectedMerchant.features.menuPrices" />
                                                      <span v-if="$_menuPermissions.UPDATE_INVENTORY" />
                                                      <span class="align-right">
                                                        <b-button
                                                          v-if="$can('update', 'StoreMenuItemModifier', 'isDisabled') && $_menuPermissions.UPDATE_VISIBILITY"
                                                          class="is-transparent"
                                                          :disabled="getStoreModifierDisabled(modifier) || getStoreItemDisabled(item)"
                                                          @click="toggleModifierGroupDisabled(subMenuItemModifierGroupItem.modifierGroup)"
                                                        >
                                                          <b-icon
                                                            v-if="visibilityLoading.modifierGroup[subMenuItemModifierGroupItem.modifierGroup.id] || visibilityLoading.all"
                                                            icon="spinner-third"
                                                            class="spin"
                                                            size="is-small"
                                                          />
                                                          <b-icon
                                                            v-else
                                                            :icon="getModifierGroupDisabled(subMenuItemModifierGroupItem.modifierGroup) || getStoreModifierDisabled(modifier) || getStoreItemDisabled(item) ? 'eye-slash' : 'eye'"
                                                            size="is-small"
                                                            :class="{
                                                              'has-text-danger': getModifierGroupDisabled(subMenuItemModifierGroupItem.modifierGroup),
                                                              'has-text-grey': (getStoreModifierDisabled(modifier) || getStoreItemDisabled(item)) && !getModifierGroupDisabled(subMenuItemModifierGroupItem.modifierGroup)
                                                            }"
                                                          />
                                                        </b-button>
                                                      </span>
                                                    </div>
                                                    <template v-if="isOpen.modifierGroup[`${subMenuItemModifierGroupItem.modifierGroupId}-${subMenuItemModifierGroupItem.id}`]">
                                                      <!-- --------------------------- -->
                                                      <!-- SUB MODIFIERS ------------- -->
                                                      <!-- --------------------------- -->
                                                      <ul class="nested-table-section">
                                                        <li v-if="!filterByAvailability(subMenuItemModifierGroupItem.modifierGroup.menuItemModifiers).length">
                                                          <div class="row sub-row">
                                                            <span class="has-text-grey">No Modifiers</span>
                                                            <span v-if="$_selectedMerchant.features.menuPrices" />
                                                            <span />
                                                          </div>
                                                        </li>
                                                        <template v-else>
                                                          <li class="nested-table-section-title">Modifiers</li>
                                                          <li
                                                            v-for="subModifier in bySortOrder(subMenuItemModifierGroupItem.modifierGroup.menuItemModifiers)"
                                                            :key="`sub-modifier-${subModifier.id}`"
                                                            :class="{'is-open': isOpen.modifier[subModifier.id]}"
                                                            :data-sub-modifier-id="subModifier.id"
                                                          >
                                                            <div class="row sub-row">
                                                              <span>
                                                                <template v-if="!searchResults">{{ subModifier.displayName }}</template>
                                                                <span v-else>
                                                                  <span v-for="chunk in highlightSearchTerm(subModifier.displayName)" :key="chunk.key" :class="(chunk.match || isMatchedPlu(modifier)) && 'search-term'">{{ chunk.text }}</span>
                                                                </span>
                                                              </span>
                                                              <span v-if="$_selectedMerchant.features.menuPrices">
                                                                <template v-if="getStoreModifierPrice(subModifier)">
                                                                  <div class="is-flex-align-center">
                                                                    <span>{{ getStoreModifierPrice(subModifier) | dollars }}</span>
                                                                    <b-icon
                                                                      v-if="getModifierPriceOverrides(subModifier).length"
                                                                      v-tippy="{
                                                                        content: getPriceOverridesTippyContent(subModifier),
                                                                        placement: 'left'
                                                                      }"
                                                                      icon="circle-dollar"
                                                                      size="is-small"
                                                                      class="has-text-info mar-l-xs"
                                                                    />
                                                                  </div>
                                                                </template>
                                                                <span v-else class="has-text-grey">N/A</span>
                                                              </span>
                                                              <span v-if="$_menuPermissions.UPDATE_INVENTORY" />
                                                              <span class="align-right">
                                                                <b-icon
                                                                  v-if="getStoreModifierSuspendUntilDate(subModifier)"
                                                                  v-tippy="{
                                                                    content: `Unavailable until ${adjustDateToStoreTimezone(getStoreModifierSuspendUntilDate(subModifier)).format('MM/DD/YY h:mm A')} ${timeZoneAbbr}`,
                                                                    placement: 'left',
                                                                    delay: [333, 0]
                                                                  }"
                                                                  icon="alarm-clock"
                                                                  size="is-small"
                                                                  class="mar-r-xs"
                                                                />
                                                                <b-button
                                                                  v-if="($can('read', 'StoreMenuItemModifier', 'price') || $can('read', 'StoreMenuItemModifier', 'totalSalesTaxRate'))
                                                                    && $_menuPermissions.READ_PRICE_TAX"
                                                                  class="is-transparent"
                                                                  @click="openStoreModifierModal({ modifier: subModifier, storeId: selectedStoreId })"
                                                                >
                                                                  <b-icon
                                                                    v-if="($can('update', 'StoreMenuItemModifier', 'price') || $can('update', 'StoreMenuItemModifier', 'totalSalesTaxRate'))
                                                                      && $_menuPermissions.UPDATE_PRICE_TAX"
                                                                    icon="pencil"
                                                                    size="is-small"
                                                                  />
                                                                  <span v-else>View</span>
                                                                </b-button>
                                                                <span v-else />
                                                                <b-button
                                                                  v-if="$can('update', 'StoreMenuItemModifier', 'isDisabled') && $_menuPermissions.UPDATE_VISIBILITY"
                                                                  class="is-transparent"
                                                                  :disabled="getStoreItemDisabled(item) || getStoreModifierDisabled(modifier)"
                                                                  @click="toggleStoreModifierDisabled(subModifier)"
                                                                >
                                                                  <b-icon
                                                                    v-if="visibilityLoading.modifier[subModifier.id] || visibilityLoading.all"
                                                                    icon="spinner-third"
                                                                    class="spin"
                                                                    size="is-small"
                                                                  />
                                                                  <b-icon
                                                                    v-else
                                                                    v-tippy="{ content: getVisibilityTooltip(getStoreModifier(subModifier)), placement: 'left', onShow: () => !!getStoreModifier(subModifier)}"
                                                                    :icon="getStoreModifierDisabled(subModifier) || getStoreModifierDisabled(modifier) || getStoreItemDisabled(item) ? 'eye-slash' : 'eye'"
                                                                    size="is-small"
                                                                    :class="{
                                                                      'has-text-danger': getStoreModifierDisabled(subModifier),
                                                                      'has-text-grey': (getStoreModifierDisabled(modifier) || getStoreItemDisabled(item)) && !getStoreModifierDisabled(subModifier)
                                                                    }"
                                                                  />
                                                                </b-button>
                                                                <span v-else />
                                                              </span>
                                                            </div>
                                                          </li>
                                                        </template>
                                                      </ul>
                                                    </template>

                                                  </li>
                                                </template>
                                              </ul>
                                            </template>

                                          </li>
                                        </template>
                                      </ul>
                                    </template>
                                  </li>
                                </template>
                              </ul>
                            </template>
                          </li>
                        </template>
                      </ul>
                    </template>
                  </li>
                </template>
              </template>
            </ul>
          </div>
        </transition>
      </div>
    </template>
  </div>
</template>

<script>
  // classes
  import Category from '@/store/classes/Category';
  import Item from '@/store/classes/Item';
  import Store from '@/store/classes/Store';
  import StoreMenuCategorySchedule from '@/store/classes/StoreMenuCategorySchedule';
  import ModifierGroup from '@/store/classes/ModifierGroup';
  import MenuItemModifierGroupItem from '@/store/classes/MenuItemModifierGroupItem';
  import StoreModifier from '@/store/classes/StoreModifier';
  import StoreItem from '@/store/classes/StoreItem';
  import StoreMenuItemSchedule from '@/store/classes/StoreMenuItemSchedule';
  import Modifier from '@/store/classes/Modifier';
  import StoreMenuTypeSchedule from '@/store/classes/StoreMenuTypeSchedule';

  import { refreshPrices, searchMenu, searchMenuV2 } from '@/api/menu';
  import { mapGetters } from 'vuex';

  // modals
  import alertModal from '@/components/globals/alert-modal.vue';
  import storeItemModal from './store-item-modal/index.vue';
  import storeModifierModal from './store-modifier-modal.vue';
  import storeMenuTypeScheduleModal from './store-menu-type-schedule-modal.vue';
  import storeCategoryDaypartScheduleModal from './store-category-daypart-schedule-modal.vue';
  import storeMenuSearchV2 from '../menu-search-v2/store-menu-search-v2.vue';

  // mixins
  import merchantMixin from '@/mixins/merchant';
  import featurePermissionsMixin from '@/mixins/featurePermissions';

  // constants
  import roleTypes from '@/constants/roleTypes';
  import { modifierGroupTypes, modifierGroupPortionTemplates } from '@/constants/modifierGroups';

  import highlightWords from 'highlight-words';
  import debounce from 'lodash.debounce';
  import logger from '@/services/logger';
  import moment from 'moment-timezone';



  export default {
    name: 'StoreMenu',

    components: { storeMenuSearchV2 },

    mixins: [merchantMixin, featurePermissionsMixin],

    props: {
      storeId: {
        type: Number,
        default: null
      }
    },

    data() {
      return {
        filteredStores: [],
        isLoadingSearchResults: false,
        isOpen: {
          category: {},
          item: {},
          modifierGroup: {},
          modifier: {}
        },
        fetchedModifierGroups: {},
        itemFetchingModGroupsId: null,
        isLoadingMenu: false,
        oldSearchQuery: '',
        roleTypes,
        searchQuery: (this.$route.query?.searchQuery && decodeURIComponent(this.$route.query.searchQuery)) || '',
        searchResults: null,
        searchType: (this.$route.query?.searchType && decodeURIComponent(this.$route.query.searchType)) || 'name',
        searchResource: (this.$route.query?.searchResource && decodeURIComponent(this.$route.query.searchResource)) || 'categories',
        resourceTypes: ['categories', 'items', 'modifierGroups', 'modifiers'],
        selectedStoreId: null,
        storeItemsWithChangedInventories: {},
        storeLoading: false,
        validSearchLength: 3,
        visibilityLoading: {
          all: false,
          category: {},
          item: {},
          modifierGroup: {},
          modifier: {}
        },
        visibleCategoryIds: [],
        visibleModifierGroupIds: [],
        modifierGroupTypes,
        modifierGroupIcons: {
          [modifierGroupTypes.PORTION]: 'chart-pie-alt',
          [modifierGroupTypes.COMBO]: 'layer-group'
        }
      };
    },


    computed: {
      ...mapGetters('session', ['currentUser', 'isCardfreeAdmin']),

      stores() {
        return Store.orderByName().get();
      },

      selectedStore() {
        return this.stores.find(store => store.storeId === this.selectedStoreId);
      },

      storeMenuTypeSchedules() {
        return StoreMenuTypeSchedule.query().where('storeId', this.selectedStoreId).get();
      },

      storeMenuCategorySchedules() {
        return StoreMenuCategorySchedule
          .query()
          .where('storeId', this.selectedStoreId)
          .get();
      },

      timeZoneAbbr() {
        return moment.tz(this.selectedStore.ianaTimezoneId).zoneAbbr();
      },

      existingMenuTypeIds() {
        return Category.all().reduce((acc, category) => {
          category.menuTypes.forEach((mt) => {
            if (!acc.includes(mt.id)) {
              acc.push(mt.id);
            }
          });

          return acc;
        }, []);
      },

      sortedSearchResults() {
        return JSON.parse(JSON.stringify(this.searchResults)).sort((a, b) => ((a.sortOrder < b.sortOrder) ? -1 : 1));
      },

      totalResultsCount() {
        let total = 0;

        const incrementMatchCount = (resources) => {
          resources.forEach((resource) => {
            const foundKey = Object.keys(resource).find(key => ['menuItems', 'menuItemModifierGroups', 'menuItemModifiers'].includes(key));
            if (foundKey) incrementMatchCount(resource[foundKey]);

            const isMatch = this.isMatchedName(resource) || this.isMatchedPlu(resource);
            if (isMatch) total++;
          });
        };

        incrementMatchCount(this.searchResults);

        return total;
      },

      categories() {
        const searchResultsExist = this.searchQuery.length >= this.validSearchLength && !!this.searchResults;
        return searchResultsExist
          ? this.sortedSearchResults
          : this.allCategories;
      },

      allCategories() {
        return Category
          .query()
          .withAllRecursive()
          .with('menuItems', query => query.where(this.filterItemsByMenuType))
          .with('menuItems.menuItemModifierGroups', query => query.where(modGroup => !modGroup.menuItemModifierId).withAllRecursive())
          .where(this.filterCategoriesByMenuType)
          .with('menuItems.menuItemModifierGroupItems', query => query.where(menuItemModifierGroupItem => !menuItemModifierGroupItem.menuItemModifierId)) // For Shared Modifier Groups
          .with('menuItems.menuItemModifierGroupItems.modifierGroup.menuItemModifiers.menuItemModifierGroupItems', query => query.where(menuItemModifierGroupItem => !!menuItemModifierGroupItem.menuItemModifierId)) // For Shared Modifier Groups
          .with('menuItems.menuItemModifierGroupItems.modifierGroup.menuItemModifiers.menuItemModifierGroupItems.modifierGroup') // For Shared Modifier Groups
          .with('menuItems.menuItemModifierGroupItems.modifierGroup.menuItemModifiers.menuItemModifierGroupItems.modifierGroup.menuItemModifiers') // For Shared Modifier Groups
          .orderBy('sortOrder')
          .get();
      },

      selectedStoreItems() {
        return StoreItem.query().where('storeId', this.selectedStoreId).get();
      },

      storeModifiersForSelectedStore() {
        return StoreModifier.query().where('menuId', this.selectedStoreId).get();
      },

      allResourcesDisabled() {
        return ![...this.visibleCategoryIds, ...this.visibleModifierGroupIds].length;
      },

      supportedMenuTypeIds() {
        return this.selectedStore.supportedMenuTypes.map(mt => mt.id);
      },

      isAnyMenuSectionOpen() {
        return Object.values(this.isOpen).some(section => Object.values(section).some(row => row));
      },

      storeMenuItemSchedules() {
        return StoreMenuItemSchedule
          .query()
          .where('storeId', this.selectedStoreId)
          .get();
      }
    },

    watch: {
      selectedStoreId: {
        handler(newVal, oldVal) {
          if (newVal) {
            this.handleStoreChange(newVal, oldVal);
          }
        }
      },
      searchQuery: 'onSearchQueryChange',
      searchType: 'reSearchMenu',
      searchResource: 'reSearchMenu',
      storeItemsWithChangedInventories: 'debouncedHandleInventoryChanges'
    },

    async created() {
      await this.onCreated();
    },

    methods: {
      async onCreated() {
        this.selectedStoreId = this.storeId || this.stores[0]?.storeId;
        this.filteredStores = this.stores;

        const { searchQuery, searchType, searchResource } = this.$route.query;
        if (this.$_featurePermissions.SHARED_MODIFIER_GROUPS) {
          const hasSearchHistory = searchQuery && searchType && searchResource;
          if (hasSearchHistory) {
            await this.searchMenuV2({
              searchQuery: decodeURIComponent(searchQuery),
              searchType: decodeURIComponent(searchType),
              searchResource: decodeURIComponent(searchResource)
            });
          }
        }
        else {
          const hasSearchHistory = searchQuery && searchType;

          if (hasSearchHistory) {
            await this.searchMenu({
              searchQuery: decodeURIComponent(searchQuery),
              searchType: decodeURIComponent(searchType)
            });
          }
        }
      },

      getModifierPriceOverrides(modifier) {
        const storeModifier = this.getStoreModifier(modifier);
        return storeModifier?.storeMenuItemModifierPriceOverrides || [];
      },

      getPriceOverridesTippyContent(modifier) {
        const overrides = this.getModifierPriceOverrides(modifier);
        const overridePrices = overrides.map(o => o.price).sort((a, b) => a - b);
        return overridePrices.map(p => this.$options.filters.dollars(p)).join(' / ');
      },

      handleSharedModGroupSearchAction({ actionName, args }) {
        const mapping = {
          'open-category-day-part-schedule-modal': this.openCategoryDayPartScheduleModal,
          'toggle-category-disabled': this.toggleCategoryDisabled,
          'handle-inventory-change': () => this.handleInventoryChange(args.item, args.count),
          'open-store-item-modal': this.openStoreItemModal,
          'toggle-store-item-disabled': this.toggleStoreItemDisabled,
          'toggle-modifier-group-disabled': this.toggleModifierGroupDisabled,
          'open-store-modifier-modal': this.openStoreModifierModal,
          'toggle-store-modifier-disabled': this.toggleStoreModifierDisabled
        };
        mapping[actionName](args);
      },

      hasStoreCategorySchedule(categoryId) {
        return !!this.storeMenuCategorySchedules.find(schedule => schedule.menuCategoryId === categoryId);
      },

      async onMerchantLoad() {
        this.isLoadingMenu = true;
        try {
          await Category.fetchCategories();
        }
        catch (error) {
          logger.error(error);
        }
        finally {
          this.isLoadingMenu = false;
        }
      },

      getVisibilityTooltip(storeMenuResource) {
        if (storeMenuResource) {
          const { createdAt, createdSource, updatedAt, modifiedSource } = storeMenuResource;
          const date = !updatedAt
            ? moment.tz(createdAt, this.selectedStore.ianaTimezoneId).format('MM/DD/YY h:mm A z')
            : moment.tz(updatedAt, this.selectedStore.ianaTimezoneId).format('MM/DD/YY h:mm A z');
          const source = !updatedAt ? createdSource?.split('-')[2] : modifiedSource?.split('-')[2];
          const sourceString = source ? `<p>by ${source}</p>` : '';
          return `<p>${updatedAt ? 'Updated' : 'Created'} ${date}</p>${sourceString}`;
        }
      },

      clearSearch() {
        this.collapseAll();
        this.searchResults = null;
        this.oldSearchQuery = '';
        this.$router.replace({ query: null });
      },

      onSearchQueryChange(newQuery, oldQuery = '') {
        // when backspacing characters in the search input
        // to the point where we revert back to the full menu
        const isGoingFromValidToInvalidSearch = oldQuery.length > newQuery.length && newQuery.length === this.validSearchLength - 1;

        // when search input is cleared after a successful search
        // (prevents collapsing opened resources before a search is made)
        const isSearchCleared = !newQuery.length && this.searchResults;

        if (isGoingFromValidToInvalidSearch || isSearchCleared) {
          this.clearSearch();
        }

        this.debouncedSearchMenu(newQuery);
      },

      // eslint-disable-next-line
      debouncedSearchMenu: debounce(async function (newQuery) {
        if (newQuery.length >= this.validSearchLength) {
          if (this.$_featurePermissions.SHARED_MODIFIER_GROUPS) {
            await this.searchMenuV2({
              searchQuery: newQuery,
              searchType: this.searchType,
              searchResource: this.searchResource
            });
          }
          else {
            await this.searchMenu({
              searchQuery: newQuery,
              searchType: this.searchType
            });
          }
        }
      }, 888),

      async searchMenuV2({ searchQuery, searchType, searchResource }) {
        try {
          this.isLoadingSearchResults = true;
          const results = await searchMenuV2({
            merchantId: this.$_selectedMerchantId,
            searchQuery,
            searchType,
            searchResource,
            menuTypeIds: this.supportedMenuTypeIds,
            forStore: true
          });
          this.oldSearchQuery = searchQuery;

          this.updateRoute({
            newCriteria: searchType,
            newQuery: searchQuery,
            newSearchResource: searchResource
          });

          if (searchResource === 'modifiers') {
            await this.insertStoreModifierSearchResultsIntoGlobalState(results);
          }

          this.searchResults = results;
        }

        catch (error) {
          this.$_onRequestError({
            toastOptions: {
              message: 'There was an issue with your search'
            },
            error
          });
        }

        finally {
          this.isLoadingSearchResults = false;
        }
      },

      async searchMenu({ searchQuery, searchType }) {
        try {
          this.isLoadingSearchResults = true;
          const menuCategories = await searchMenu({
            merchantId: this.$_selectedMerchantId,
            searchQuery,
            searchType,
            menuTypeIds: this.supportedMenuTypeIds,
            forStore: true
          });
          this.oldSearchQuery = searchQuery;

          this.updateRoute({
            newCriteria: searchType,
            newQuery: searchQuery
          });

          this.searchResults = menuCategories;
          await this.insertStoreModifierSearchResultsIntoGlobalState(menuCategories);

          this.expandSearchResults();
        }

        catch (error) {
          this.$_onRequestError({
            toastOptions: {
              message: 'There was an issue with your search'
            },
            error
          });
        }

        finally {
          this.isLoadingSearchResults = false;
        }
      },

      async expandSearchResults() {
        const storeMenuItemSchedulesPromises = [];
        this.searchResults.forEach((category) => {
          if (category.menuItems.length) {
            storeMenuItemSchedulesPromises.push(this.fetchStoreMenuItemSchedules(category.id));
            this.toggleOpenState({ id: category.id, type: 'category', isOpen: true });
          }
          category.menuItems.forEach((item) => {
            if (item.menuItemModifierGroups.length) {
              this.toggleOpenState({ id: `${category.id}-${item.id}`, type: 'item', isOpen: true });
            }
            item.menuItemModifierGroups.forEach((modifierGroup) => {
              if (modifierGroup.menuItemModifiers.length) {
                this.toggleOpenState({ id: modifierGroup.id, type: 'modifierGroup', isOpen: true });
              }
              modifierGroup.menuItemModifiers.forEach((modifier) => {
                if (modifier.menuItemModifierGroups.length) {
                  this.toggleOpenState({ id: modifier.id, type: 'modifier', isOpen: true });
                }
                modifier.menuItemModifierGroups.forEach((subModifierGroup) => {
                  if (subModifierGroup.menuItemModifiers.length) {
                    this.toggleOpenState({ id: subModifierGroup.id, type: 'modifierGroup', isOpen: true });
                  }
                });
              });
            });
          });
        });
        await Promise.all(storeMenuItemSchedulesPromises);
      },

      async insertStoreModifierSearchResultsIntoGlobalState(searchResults) {
        const storeMenuItemModifiersForSearch = this.extractStoreMenuItemModifiersFromSearchData(searchResults);
        await StoreModifier.insertOrUpdate({ data: storeMenuItemModifiersForSearch });
      },

      updateRoute({ newCriteria, newQuery, newSearchResource }) {
        const { searchQuery, searchType, searchResource } = this.$route.query;
        const hasSearchChanged = searchType !== newCriteria || searchQuery !== newQuery || searchResource !== newSearchResource;
        if (hasSearchChanged) {
          if (this.$_featurePermissions.SHARED_MODIFIER_GROUPS) {
            this.$router.replace({
              query: {
                searchType: encodeURIComponent(newCriteria),
                searchQuery: encodeURIComponent(newQuery),
                searchResource: encodeURIComponent(newSearchResource)
              }
            });
          }
          else {
            this.$router.replace({
              query: {
                searchType: encodeURIComponent(newCriteria),
                searchQuery: encodeURIComponent(newQuery)
              }
            });
          }
        }
      },

      async reSearchMenu() {
        if (this.searchQuery.length >= this.validSearchLength) {
          const { searchQuery, searchType, searchResource } = this;
          if (this.$_featurePermissions.SHARED_MODIFIER_GROUPS) {
            await this.searchMenuV2({ searchQuery, searchType, searchResource });
          }
          else {
            await this.searchMenu({ searchQuery, searchType });
          }
        }
      },

      highlightSearchTerm(string) {
        return highlightWords({ text: string, query: this.oldSearchQuery, matchExactly: true });
      },

      isMatchedResult(value) {
        return new RegExp(this.searchQuery, 'ig').test(value);
      },

      isMatchedName({ displayName }) {
        return this.isMatchedResult(displayName);
      },

      isMatchedPlu({ posItemId, posItemIds }) {
        // items will have a key of posItemIds with an array of potentially many ids,
        // and modifiers will have a key of posItemId with an array of always a single id
        const hasMatchingModifierPlu = posItemId && this.isMatchedResult(posItemId[0]);
        const hasMatchingItemPlu = posItemIds && posItemIds.some(id => this.isMatchedResult(id));
        return hasMatchingItemPlu || hasMatchingModifierPlu;
      },

      storeFullySupportsMenuType(menuTypeId) {
        return this.supportedMenuTypeIds.includes(menuTypeId) && this.existingMenuTypeIds.includes(menuTypeId);
      },

      storeMenuTypeHasSchedule(menuTypeId) {
        return !!this.storeMenuTypeSchedules.find(x => x.menuTypeId === menuTypeId);
      },

      filterCategoriesByMenuType(category) {
        const categoryMenuTypeIds = category.menuTypes.map(mt => mt.id);
        return categoryMenuTypeIds.some(id => this.supportedMenuTypeIds.includes(id));
      },

      filterItemsByMenuType(item) {
        const itemMenuTypeIds = item.menuTypes.map(mt => mt.id);
        return itemMenuTypeIds.some(id => this.supportedMenuTypeIds.includes(id));
      },

      hasRoleType(roleType) {
        return this.currentUser?.role?.name === roleType;
      },

      getNoMenuErrorMessage() {
        return this.isCardfreeAdmin || this.hasRoleType(this.roleTypes.MERCHANT_ADMIN)
          ? 'Please create the missing menus in the Master Menu.'
          : 'Please contact your administrator to create one. Until then, this location will not be displayed in the app.';
      },

      async fetchStoreMenuResources(storeId = this.selectedStoreId) {
        try {
          const openModifierGroupIds = Object.entries(this.isOpen.modifierGroup)
            .reduce((ids, [modGroupId, isOpen]) => (isOpen ? [...ids, Number(modGroupId)] : ids), []);

          await Promise.all([
            StoreItem.fetchStoreMenuItemsByStoreId(storeId),
            StoreMenuCategorySchedule.fetchSchedules(storeId),
            StoreMenuTypeSchedule.fetchStoreMenuTypeSchedules(storeId),
            openModifierGroupIds.length && StoreModifier.fetchStoreModifiersByStoreId(storeId, { modifierGroupIds: openModifierGroupIds })
          ]);
        }
        catch (error) {
          this.$_onRequestError({
            toastOptions: { message: 'There was an error loading your store menu' },
            error
          });
        }
      },

      async fetchStoreMenuVisibility(storeId = this.selectedStoreId) {
        try {
          const [visibleCategoryIds, visibleModifierGroupIds] = await Promise.all([
            Category.fetchVisibleCategoriesByStoreId(storeId),
            ModifierGroup.fetchVisibleModifierGroupsByStoreId(storeId)
          ]);

          this.visibleCategoryIds = visibleCategoryIds || [];
          this.visibleModifierGroupIds = visibleModifierGroupIds || [];
        }
        catch (error) {
          this.$_onRequestError({
            toastOptions: { message: 'There was an error loading menu visibility' },
            error
          });
        }
      },

      async handleStoreChange(newStoreId) {
        this.storeLoading = true;
        if (this.storeId !== newStoreId) {
          this.$router.replace({ params: { storeId: newStoreId } });
        }
        await this.fetchStoreMenuResources(newStoreId);
        await this.fetchStoreMenuVisibility(newStoreId);
        this.storeLoading = false;
      },

      handleStoreSelect(store) {
        if (store) {
          this.selectedStoreId = store.storeId;
          this.filteredStores = this.stores;
        }
      },

      filterStoreOptions(query) {
        this.filteredStores = this.stores.filter(store => store.description.toLowerCase().includes(query.toLowerCase()));
      },

      bySortOrder(resources) {
        return resources?.sort((a, b) => ((a.sortOrder < b.sortOrder) ? -1 : 1));
      },

      filterByAvailability(resources) {
        return resources.filter((resource) => {
          const startDate = resource.availabilityBeginDate;
          const endDate = resource.availabilityEndDate;
          const today = new Date();

          return (!startDate || new Date(startDate) < today) && (!endDate || new Date(endDate) > today);
        });
      },

      toggleVisibilityLoading(type, id, isLoading) {
        this.$set(this.visibilityLoading[type], id, isLoading);
      },
      confirmToggleAllDisabled() {
        const allDisabled = this.allResourcesDisabled;
        this.$buefy.modal.open({
          parent: this,
          component: alertModal,
          hasModalCard: true,
          canCancel: ['escape', 'outside'],
          props: {
            title: `${allDisabled ? 'Show' : 'Hide'} All`,
            message: `Are you sure you want to make all items ${allDisabled ? 'available' : 'unavailable'} at this location?`,
            type: 'is-danger',
            horizontal: true,
            showCloseButton: false,
            buttons: [
              { text: 'No' },
              {
                text: 'Yes',
                primary: true,
                onClick: this.toggleAllDisabled
              }
            ]
          }
        });
      },

      async toggleAllDisabled() {
        try {
          const allDisabled = this.allResourcesDisabled;
          this.visibilityLoading.all = true;
          await Store.toggleMenuResourcesDisabled({ storeId: this.selectedStoreId, isDisabled: !allDisabled });
          await this.fetchStoreMenuVisibility();

          this.$_onRequestSuccess({
            toastOptions: {
              message: `All <b>${this.selectedStore.description}</b> menu resources have been ${allDisabled ? 'made visible' : 'hidden'}`
            }
          });
        }

        catch (error) {
          this.$_onRequestError({
            toastOptions: {
              message: 'There was an error updating the visibility of your menu'
            },
            error
          });
        }

        finally {
          this.visibilityLoading.all = false;
        }
      },

      async refreshPricesForLocation() {
        try {
          await refreshPrices({ merchantId: this.$_selectedMerchantId, storeId: this.selectedStoreId });

          this.$buefy.dialog.confirm({
            title: 'Refreshing Prices',
            message: `The prices for <b>${this.selectedStore.description}</b> are being refreshed. This may take a few moments.`,
            icon: 'sync-alt',
            hasIcon: true,
            type: 'is-primary',
            canCancel: false
          });
        }
        catch (error) {
          this.$_onRequestError({
            error,
            toastOptions: {
              message: `There was an error refreshing your prices for ${this.selectedStore.description}`
            }
          });
        }
      },

      async fetchStoreMenuItemSchedules(categoryId) {
        try {
          await StoreMenuItemSchedule.fetchStoreMenuItemSchedules({ storeId: this.selectedStoreId, categoryId });
        }
        catch (error) {
          this.$_onRequestError({
            error,
            toastOptions: {
              message: 'There was an error fetching the schedules'
            }
          });
        }
      },


      // NESTING LEVEL OPEN STATES
      // //////////////////////////////////

      toggleOpenState({ id, type, isOpen }) {
        const openState = isOpen !== undefined ? isOpen : !this.isOpen[type][id];
        this.$set(this.isOpen[type], id, openState);
      },

      collapseAll() {
        this.isOpen.category = {};
        this.isOpen.item = {};
        this.isOpen.modifierGroup = {};
        this.isOpen.modifier = {};
      },


      // CATEGORY
      // //////////////////////////////////

      wasCategoryOpen(categoryId) {
        return categoryId in this.isOpen.category;
      },

      async toggleCategory(categoryId) {
        if (!this.wasCategoryOpen(categoryId)) {
          if (!this.searchQuery) {
            await this.fetchItemsByCategoryId(categoryId);
          }
          await this.fetchStoreMenuItemSchedules(categoryId);
        }

        this.toggleOpenState({ id: categoryId, type: 'category' });
      },

      async toggleCategoryDisabled(category) {
        this.toggleVisibilityLoading('category', category.id, true);

        const isDisabled = !this.getCategoryDisabled(category);

        try {
          await Category.toggleCategoryVisibility({
            menuCategoryId: category.id,
            isDisabled,
            storeId: this.selectedStoreId
          });
          this.visibleCategoryIds = await Category.fetchVisibleCategoriesByStoreId(this.selectedStoreId);
        }

        catch (error) {
          this.$_onRequestError({
            toastOptions: {
              message: `There was an error updating the visibility of <b>${category.displayName}</b>`
            },
            error
          });
        }

        finally {
          this.toggleVisibilityLoading('category', category.id, false);
        }
      },

      categoryHasStoreItems(category) {
        return category.menuItems.length && !!category.menuItems.find(item => !!this.getStoreItem(item));
      },

      getCategoryDisabled(category) {
        return !this.visibleCategoryIds.includes(category.id);
      },

      openCategoryDayPartScheduleModal(category) {
        this.$buefy.modal.open({
          parent: this,
          component: storeCategoryDaypartScheduleModal,
          hasModalCard: true,
          trapFocus: true,
          canCancel: false,
          customClass: 'auto-width',
          props: {
            category, storeId: this.storeId
          }
        });
      },



      // ITEM
      // //////////////////////////////////

      async toggleItem(compositeId) {
        const itemId = Number(compositeId.split('-')[1]);
        const wasNeverOpen = !Object.keys(this.isOpen.item).some((_compositeId) => {
          const _itemId = Number(_compositeId.split('-')[1]);
          return itemId === _itemId;
        });

        if (wasNeverOpen) {
          if (this.$_featurePermissions.SHARED_MODIFIER_GROUPS) {
            const menuItemModifierGroupItems = MenuItemModifierGroupItem.query()
              .where('menuItemId', Number(itemId))
              .get();

            const unfetchedModifierGroupIds = menuItemModifierGroupItems
              .map(mg => mg.modifierGroupId)
              .filter(modifierGroupId => !this.wasModifierGroupFetched(modifierGroupId));

            if (unfetchedModifierGroupIds.length) {
              this.itemFetchingModGroupsId = compositeId;
              await ModifierGroup.fetchModifierGroups({ ids: unfetchedModifierGroupIds });
              this.itemFetchingModGroupsId = null;
            }
          }
          else {
            const modifierGroupsExist = ModifierGroup.query().where('menuItemId', itemId).exists();
            if (!modifierGroupsExist) {
              await this.fetchModifierGroupsByItemId(compositeId);
            }
          }
        }

        this.toggleOpenState({ id: compositeId, type: 'item' });
      },

      async fetchModifiersByModifierGroupId(modifierGroupId, parentMenuItemId) {
        try {
          await Modifier.fetchByModifierGroupId({
            modifierGroupId,
            includeStoreModifiers: true,
            menuId: this.selectedStoreId,
            parentMenuItemId
          });
        }
        catch (error) {
          this.$_onRequestError({
            toastOptions: {
              message: 'Unable to fetch modifiers'
            },
            error
          });
        }
      },

      async fetchModifierGroupsByItemId(compositeId) {
        try {
          this.itemFetchingModGroupsId = compositeId;
          const itemId = Number(compositeId.split('-')[1]);

          await ModifierGroup.fetchByMenuItemId({ itemId });
        }
        catch (error) {
          this.$_onRequestError({
            toastOptions: {
              message: 'Unable to fetch modifier groups'
            },
            error
          });
        }
        finally {
          this.itemFetchingModGroupsId = null;
        }
      },

      async fetchItemsByCategoryId(categoryId) {
        try {
          await Item.fetchItemsByCategoryId({
            categoryId,
            includeMenuItemModifierGroupItems: true,
            options: { forceFetch: true }
          });
        }
        catch (error) {
          this.$_onRequestError({
            toastOptions: {
              message: 'Unable to fetch items'
            },
            error
          });
        }
      },

      sortedAvailableMenuItems({ categoryId, menuItems }) {
        if (this.searchResults) {
          return menuItems;
        }
        return menuItems
          && this.filterByAvailability(menuItems)
            .sort((a, b) => ((a.sortOrderByCategory(categoryId) < b.sortOrderByCategory(categoryId)) ? -1 : 1));
      },

      getStoreItem(item) {
        return this.selectedStoreItems.find(storeItem => storeItem.menuItemId === item.id);
      },

      getStoreItemPrice(item) {
        return this.getStoreItem(item)?.price;
      },

      getStoreItemDisabled(item) {
        const storeItem = this.getStoreItem(item);
        return !storeItem || storeItem.isDisabled;
      },

      getStoreItemSuspendUntilDate(item) {
        const storeItem = this.getStoreItem(item);
        return storeItem?.suspendUntilDate;
      },

      showSuspendedIconForItem(item) {
        const suspendedUntilDate = this.getStoreItemSuspendUntilDate(item);
        if (!suspendedUntilDate) {
          return false;
        }

        const dateInStoreTimezone = this.adjustDateToStoreTimezone(suspendedUntilDate);

        const nowInStoreTimezone = moment().tz(this.selectedStore.ianaTimezoneId);
        return nowInStoreTimezone.isBefore(dateInStoreTimezone);
      },

      adjustDateToStoreTimezone(date) {
        return moment.utc(date).tz(this.selectedStore.ianaTimezoneId);
      },

      async toggleStoreItemDisabled(item) {
        const storeItem = this.getStoreItem(item);

        this.toggleVisibilityLoading('item', item.id, true);

        try {
          if (storeItem) {
            await StoreItem.updateStoreItem({
              ...storeItem,
              isDisabled: !storeItem.isDisabled,
              isManuallyDisabled: !storeItem.isDisabled
            });
          }
          else {
            const newStoreItem = new StoreItem({
              menuId: this.selectedStoreId,
              menuItemId: item.id,
              isDisabled: false,
              isManuallyDisabled: false
            });
            await StoreItem.addStoreItem(newStoreItem);
          }

          this.visibleCategoryIds = await Category.fetchVisibleCategoriesByStoreId(this.selectedStoreId);
        }

        catch (error) {
          this.$_onRequestError({
            toastOptions: {
              message: `There was an error updating the visibility of <b>${item.displayName}</b>`
            },
            error
          });
        }

        finally {
          this.toggleVisibilityLoading('item', item.id, false);
        }
      },

      getStoreItemInventory(item) {
        const storeItem = this.getStoreItem(item);
        return !this.storeItemsWithChangedInventories[storeItem.id]
          ? storeItem.inventoryCount
          : this.storeItemsWithChangedInventories[storeItem.id].inventoryCount;
      },

      handleInventoryChange(item, count) {
        const storeItem = this.getStoreItem(item);
        const inventoryCount = count < 0 ? null : count;

        this.storeItemsWithChangedInventories = {
          ...this.storeItemsWithChangedInventories,
          [storeItem.id]: { ...storeItem, inventoryCount }
        };
      },

      // eslint-disable-next-line
      debouncedHandleInventoryChanges: debounce(async function (updatedStoreItemsObj) {
        const updatedStoreItems = Object.values(updatedStoreItemsObj);
        if (updatedStoreItems.length) {
          try {
            await this.updateStoreItemInventories(updatedStoreItems);
            this.storeItemsWithChangedInventories = {};
          }
          catch (error) {
            logger.error(error);
          }
        }
      }, 1500),

      async updateStoreItemInventories(updatedStoreItems) {
        try {
          await Promise.all(updatedStoreItems.map(storeItem => StoreItem.updateStoreItem(storeItem)));

          this.$_onRequestSuccess({
            toastOptions: {
              message: `${updatedStoreItems.length > 1 ? 'Inventories' : 'Inventory'} updated!`,
              duration: 1250
            }
          });
        }

        catch (error) {
          this.$_onRequestError({
            toastOptions: {
              message: 'There was an issue updating inventory'
            },
            error
          });
          throw error;
        }
      },

      openStoreItemModal({ item, storeId, defaultTabIndex }) {
        this.$buefy.modal.open({
          parent: this,
          component: storeItemModal,
          hasModalCard: true,
          trapFocus: true,
          canCancel: false,
          customClass: 'auto-width',
          props: {
            item,
            storeItem: this.getStoreItem(item),
            storeId,
            defaultTabIndex
          }
        });
      },

      isFetchingItems(categoryId) {
        return Item.$state().fetchingCategoryId === categoryId;
      },

      isFetchingStoreItemSchedules(categoryId) {
        return StoreMenuItemSchedule.$state().fetchingCategoryId === categoryId;
      },

      storeItemHasSchedule(itemId) {
        return !!this.storeMenuItemSchedules.find(schedule => schedule.menuItemId === itemId);
      },

      // MODIFIER
      // //////////////////////////////////

      getStoreModifier(modifier) {
        return this.storeModifiersForSelectedStore.find(storeModifier => storeModifier.menuItemModifierId === modifier.id);
      },

      getStoreModifierPrice(modifier) {
        return this.getStoreModifier(modifier)?.price;
      },

      getStoreModifierDisabled(modifier) {
        const storeModifier = this.getStoreModifier(modifier);
        return !storeModifier ? false : storeModifier.isDisabled;
      },

      getStoreModifierSuspendUntilDate(modifier) {
        const storeModifier = this.getStoreModifier(modifier);
        return storeModifier?.suspendUntilDate;
      },

      showSuspendedIconForModifier(modifier) {
        const suspendedUntilDate = this.getStoreModifierSuspendUntilDate(modifier);
        if (!suspendedUntilDate) {
          return false;
        }

        const dateInStoreTimezone = this.adjustDateToStoreTimezone(suspendedUntilDate);

        const nowInStoreTimezone = moment().tz(this.selectedStore.ianaTimezoneId);
        return nowInStoreTimezone.isBefore(dateInStoreTimezone);
      },

      getPortionTemplateTypeIcon(modifierTemplateType) {
        switch (modifierTemplateType) {
          case modifierGroupPortionTemplates.FULL_GROUP_TEMPLATE.constant:
            return '/images/whole-portion.svg';
          case modifierGroupPortionTemplates.HALF_GROUP_TEMPLATE.constant:
            return '/images/half-portion.svg';
          default:
            return '';
        }
      },

      async toggleStoreModifierDisabled(modifier) {
        const existingStoreModifier = this.getStoreModifier(modifier);

        this.toggleVisibilityLoading('modifier', modifier.id, true);

        try {
          const storeMenuItemModifier = existingStoreModifier
            ? {
              ...existingStoreModifier,
              isDisabled: !existingStoreModifier.isDisabled,
              isManuallyDisabled: !existingStoreModifier.isDisabled
            }
            // If a store modifier doesn't already exist the only action a user could be taking
            // is hiding the modifier, so we set isDisabled and isManuallyDisabled to true
            : new StoreModifier({
              menuId: this.selectedStoreId,
              isDisabled: true,
              isManuallyDisabled: true
            });
          await StoreModifier.createOrUpdateStoreModifier({ modifierId: modifier.id, storeMenuItemModifier });

          this.visibleModifierGroupIds = await ModifierGroup.fetchVisibleModifierGroupsByStoreId(this.selectedStoreId);
        }

        catch (error) {
          this.$_onRequestError({
            toastOptions: {
              message: `There was an error updating the visibility of <b>${modifier.displayName}</b>`
            },
            error
          });
        }

        finally {
          this.toggleVisibilityLoading('modifier', modifier.id, false);
        }
      },

      openStoreModifierModal({ modifier, storeId }) {
        this.$buefy.modal.open({
          parent: this,
          component: storeModifierModal,
          hasModalCard: true,
          trapFocus: true,
          canCancel: false,
          customClass: 'auto-width',
          props: {
            modifier,
            storeModifier: this.getStoreModifier(modifier),
            storeId
          }
        });
      },

      isFetchingModifiers(modifierGroupId) {
        return Modifier.$state().fetchingModifierGroupId === modifierGroupId;
      },



      // MODIFIER GROUP
      // //////////////////////////////////

      wasModifierGroupFetched(modifierGroupId) {
        return this.fetchedModifierGroups[modifierGroupId];
      },

      async toggleModifierGroup(modifierGroupId, menuItemModifierGroupItemId, parentMenuItemId) {
        if (!this.wasModifierGroupFetched(modifierGroupId)) {
          await this.fetchModifiersByModifierGroupId(modifierGroupId, parentMenuItemId);
          this.fetchedModifierGroups[modifierGroupId] = true;
        }

        // Merchants with shared modifier groups need to leverage the menuItemModifierGroupItemId to determine if the modifier group is open
        const openStateId = this.$_featurePermissions.SHARED_MODIFIER_GROUPS ? `${modifierGroupId}-${menuItemModifierGroupItemId}` : modifierGroupId;

        this.toggleOpenState({ id: openStateId, type: 'modifierGroup' });
      },

      getModifierGroupDisabled(modifierGroup) {
        return !this.visibleModifierGroupIds.includes(modifierGroup.id);
      },

      modifierGroupHasStoreModifiers(modifierGroup) {
        return modifierGroup.menuItemModifiers.length && !!modifierGroup.menuItemModifiers.find(modifier => !!this.getStoreModifier(modifier));
      },

      async toggleModifierGroupDisabled(modifierGroup) {
        this.toggleVisibilityLoading('modifierGroup', modifierGroup.id, true);

        let isDisabled;
        if (this.modifierGroupHasStoreModifiers(modifierGroup)) {
          isDisabled = !this.getModifierGroupDisabled(modifierGroup);
        }
        else {
          isDisabled = this.visibleModifierGroupIds.includes(modifierGroup.id);
        }

        try {
          await ModifierGroup.toggleModifierGroupVisibility({
            menuItemModifierGroupId: modifierGroup.id,
            isDisabled,
            storeId: this.selectedStoreId
          });
          this.visibleModifierGroupIds = await ModifierGroup.fetchVisibleModifierGroupsByStoreId(this.selectedStoreId);
        }

        catch (error) {
          this.$_onRequestError({
            toastOptions: {
              message: `There was an error updating the visibility of <b>${modifierGroup.displayName}</b>`
            },
            error
          });
        }

        finally {
          this.toggleVisibilityLoading('modifierGroup', modifierGroup.id, false);
        }
      },

      isFetchingModifierGroups(compositeId) {
        return compositeId === this.itemFetchingModGroupsId;
      },

      openStoreMenuTypeScheduleModal(menuType) {
        this.$buefy.modal.open({
          parent: this,
          component: storeMenuTypeScheduleModal,
          hasModalCard: true,
          trapFocus: true,
          canCancel: false,
          customClass: 'auto-width',
          props: { menuType, store: this.selectedStore }
        });
      },

      extractStoreMenuItemModifiersFromSearchData(searchData) {
        if (this.$_featurePermissions.SHARED_MODIFIER_GROUPS) {
          return searchData.flatMap(mod => mod.storeMenuItemModifiers);
        }
        else {
          const modifiers = [];
          searchData.forEach((category) => {
            category.menuItems.forEach((item) => {
              item.menuItemModifierGroups.forEach((modifierGroup) => {
                modifierGroup.menuItemModifiers.forEach((modifier) => {
                  modifiers.push(...modifier.storeMenuItemModifiers);
                });
              });
            });
          });

          return modifiers;
        }
      },

      // MENU ITEM MODIFIER GROUP ITEM
      filterMenuItemModifierGroupItemsByItemId(menuItemModifierGroupItems, itemId) {
        return menuItemModifierGroupItems.filter(menuItemModifierGroupItem => menuItemModifierGroupItem.menuItemId === itemId);
      }
    }
  };
</script>

<style lang="sass" scoped>
  .supported-menu-types
    border: 1px solid $grey-lighter
    border-radius: $radius
    height: 2.5rem

  .row
    grid-template-columns: minmax(200px, 1fr) minmax(75px, 0.2fr) minmax(100px, 0.25fr)

    .show-inventory &
      grid-template-columns: minmax(200px, 1fr) minmax(75px, 0.2fr) minmax(145px, 200px) minmax(100px, 0.25fr)

    .hide-prices &
      grid-template-columns: minmax(200px, 1fr) minmax(145px, 200px) minmax(100px, 0.25fr)

  .store-select
    flex-grow: 0
    flex-shrink: 1
    flex-basis: 350px
    ::v-deep
      input
        font-weight: bold
      .icon
        color: $primary
    &:hover
      ::v-deep .icon
        color: $dark

  .store-option
    display: flex
    justify-content: space-between

    &.selected
      color: $primary
      font-weight: 600

    .icon
      margin-right: -2rem

  .infinite-inventory
    ::v-deep input
      font-size: 1.5rem
      height: 2.5rem

  @media (max-width: $tablet)
    .top-section
      flex-direction: column-reverse

    .store-select
      flex-basis: auto

</style>
