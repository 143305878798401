<template>
  <validated-form
    ref="form"
    v-slot="{}"
    form-id="userPasswordForm"
    @valid-submit="updateUserPassword"
  >
    <fieldset class="mar-b-lg">
      <validated-text-input
        v-model="form.currentPassword"
        :has-icon="false"
        label="Current Password"
        name="currentPassword"
        type="password"
        rules="required|min:8"
        password-reveal
        class="mar-b-xl"
      />

      <validation-observer>
        <validated-text-input
          v-model="form.password"
          :bails="!form.password"
          type="password"
          :has-icon="false"
          label="New Password"
          name="password"
          :rules="{
            required: true,
            min: 8,
            specialChars: true,
            confirmPassword: 'passwordConfirmation'
          }"
          password-reveal
        />

        <validated-text-input
          v-model="form.passwordConfirmation"
          :has-icon="false"
          label="Confirm New Password"
          name="passwordConfirmation"
          type="password"
          password-reveal
        />
      </validation-observer>
    </fieldset>

    <hr>

    <div class="buttons is-centered">
      <b-button
        rounded
        size="is-medium"
        native-type="submit"
        type="is-primary"
        :loading="isLoading"
        class="is-bold"
      >
        Save
      </b-button>
    </div>
  </validated-form>
</template>

<script>
  import { mapGetters } from 'vuex';
  import User from '@/store/classes/User';

  export default {
    name: 'UserPasswordForm',

    data() {
      return {
        form: {
          currentPassword: null,
          password: null,
          passwordConfirmation: null
        }
      };
    },

    computed: {
      ...mapGetters('session', ['currentUser']),

      isLoading() {
        return User.$state().submitting;
      }
    },

    methods: {
      resetForm() {
        this.form.currentPassword = null;
        this.form.password = null;
        this.form.passwordConfirmation = null;
      },

      async updateUserPassword() {
        try {
          await User.updateUserPassword(this.form, this.currentUser.id);
          this.$_onRequestSuccess({
            toastOptions: { message: 'Password has been successfully updated!' },
            options: { resetForm: true }
          });
          this.resetForm();
        }
        catch (error) {
          this.$_onRequestError({
            toastOptions: { message: 'Unable to update password' },
            options: { backendError: error.data.message },
            error
          });
        }
      }
    }
  };
</script>
