import actionCable from 'actioncable';

let consumer = null;

const createConsumer = () => {
  if (!consumer) {
    consumer = actionCable.createConsumer();
  }
  return consumer;
};

export const subscribeToChannel = (channel, callbacks) => {
  const currentConsumer = createConsumer();
  return currentConsumer.subscriptions.create(channel, callbacks);
};

export const unsubscribeFromChannel = (subscription) => {
  subscription.unsubscribe();
};

export default {
  subscribeToChannel,
  unsubscribeFromChannel
};
