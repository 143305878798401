<template>
  <div class="mar-t-lg">
    <b-loading :is-full-page="false" :active="sortingFaqSection || isSubmitting" />

    <b-message
      v-if="!selectedMerchantFaqHeader && !isSubmitting"
      type="is-warning"
      class="is-compact has-shadow mar-b"
      has-icon
      icon="info-square"
    >
      The default FAQs are being used for this application type. To create custom FAQs, please update the FAQ Page Title.
    </b-message>

    <b-message
      v-if="!merchantFaqHeader.merchantFaqSections.length"
      type="is-danger"
      class="is-compact has-shadow mar-b"
      has-icon
      icon="exclamation-triangle"
    >
      Your FAQ Page will not function correctly until at least one Section has been added
    </b-message>

    <div class="card mar-b-lg">
      <div class="card-content">
        <validated-form
          ref="form"
          form-id="faqHeader"
          @valid-submit="createOrUpdateMerchantFaqHeader"
        >
          <div class="faq-header-title-form">
            <validated-text-input
              v-model="form.headerTitle"
              label="FAQ Page Title"
              name="headerTitle"
              type="text"
              rules="required"
              expanded
            />
            <b-button
              native-type="submit"
              type="is-primary"
              rounded
              :loading="isSubmitting"
              class="mar-l-sm"
            >
              Save
            </b-button>
          </div>
        </validated-form>
      </div>
    </div>

    <div v-if="merchantFaqHeader.merchantFaqSections.length" class="card is-clipped">
      <div class="nested-table">
        <div class="table-heading">
          <div class="row">
            <div class="pad-l-lg">FAQs</div>
            <div class="align-center">Actions</div>
          </div>
        </div>
        <draggable
          tag="ul"
          group="section"
          v-bind="draggableAttributes"
          :value="merchantFaqHeader.merchantFaqSections"
          :force-fallback="true"
          :disabled="!selectedMerchantFaqHeader"
          @change="handleSectionSortOrderChange($event, merchantFaqHeader.merchantFaqSections)"
        >
          <li
            v-for="section in merchantFaqHeader.merchantFaqSections"
            :key="`section-${section.id}`"
            :class="{'is-open': isOpen[section.id]}"
          >
            <div class="row">
              <div>
                <b-icon
                  v-if="!!selectedMerchantFaqHeader"
                  icon="grip-lines"
                  size="is-small"
                  pack="far"
                  class="drag-handle"
                />
                <div :class="['link-inverted has-text-weight-bold', { 'pad-y-sm': !selectedMerchantFaqHeader }]" @click="toggleFaqSection(section.id)">
                  {{ section.sectionHeader }}
                  <b-icon
                    size="is-small"
                    pack="far"
                    :icon="(fetchingFaqContentsId || sortingParentContentId) === section.id ? 'spinner-third' : 'angle-right'"
                    :class="[
                      'open-indicator',
                      {
                        'is-open': isOpen[section.id],
                        'spin': (fetchingFaqContentsId || sortingParentContentId) === section.id
                      }
                    ]"
                  />
                </div>
              </div>
              <div v-if="!!selectedMerchantFaqHeader" class="align-center">
                <b-button class="is-transparent" @click="openSectionModal(section)">
                  <b-icon class="is-primary" icon="pencil" size="is-small" />
                </b-button>
                <b-button slot="trigger" type="is-white" class="is-transparent" @click="openDeleteFaqSectionConfirm(section.id)">
                  <b-icon
                    :icon="deletingFaqSectionId === section.id ? 'spinner-third' : 'trash-alt'"
                    :class="deletingFaqSectionId === section.id && 'spin'"
                    type="is-danger"
                    size="is-small"
                  />
                </b-button>
              </div>
              <div v-else />
            </div>

            <ul v-if="isOpen[section.id]" class="nested-table-section">
              <draggable
                tag="ul"
                :group="`section-${section.id}-contents`"
                v-bind="draggableAttributes"
                :value="section.merchantFaqContents"
                :force-fallback="true"
                @change="handleContentSortOrderChange($event, section.merchantFaqContents)"
              >
                <li
                  v-for="content in section.merchantFaqContents"
                  :key="`content-${content.id}`"
                  :class="{'is-open': isOpen[section.id]}"
                >
                  <div class="row sub-row">
                    <div>
                      <b-icon
                        v-if="!!selectedMerchantFaqHeader"
                        icon="grip-lines"
                        size="is-small"
                        pack="far"
                        class="drag-handle"
                      />
                      <div>
                        <p class="title is-6 mar-b-xs">{{ content.question }}</p>
                        <p class="answer">{{ content.answer }}</p>
                      </div>
                    </div>
                    <div v-if="!!selectedMerchantFaqHeader" class="align-center">
                      <b-button class="is-transparent" @click="openContentModal({ content })">
                        <b-icon class="is-primary" icon="pencil" size="is-small" />
                      </b-button>
                      <b-button
                        v-if="!!selectedMerchantFaqHeader"
                        slot="trigger"
                        type="is-white"
                        class="is-transparent"
                        @click="openDeleteFaqContentConfirm(content.id)"
                      >
                        <b-icon
                          :icon="deletingFaqContentId === content.id ? 'spinner-third' : 'trash-alt'"
                          :class="deletingFaqContentId === content.id && 'spin'"
                          type="is-danger"
                          size="is-small"
                        />
                      </b-button>
                    </div>
                    <div v-else />
                  </div>
                </li>
              </draggable>
              <li v-if="!!selectedMerchantFaqHeader" class="row sub-row">
                <div>
                  <b-button
                    class="is-transparent"
                    icon-left="plus"
                    type="is-primary"
                    inverted
                    @click="openContentModal({ sectionId: section.id })"
                  >
                    Question
                  </b-button>
                </div>
                <div />
              </li>
            </ul>
          </li>
        </draggable>

        <div v-if="!!selectedMerchantFaqHeader" class="pad-x pad-y-sm">
          <b-button
            class="is-transparent"
            inverted
            type="is-primary"
            icon-left="plus"
            @click="openSectionModal()"
          >
            Section
          </b-button>
        </div>
      </div>
    </div>
    <empty-state-card
      v-else
      type="is-primary"
      title="Create FAQ Section"
      message="Create your first FAQ Section in order to add Questions and Answers"
      image-path="/icons/new-category.svg"
    >
      <template #buttons>
        <b-button
          type="is-primary"
          rounded
          size="is-medium"
          @click="openSectionModal()"
        >
          Create Section
        </b-button>
      </template>
    </empty-state-card>
  </div>
</template>

<script>
  import merchantMixin from '@/mixins/merchant';
  import draggable from 'vuedraggable';
  import MerchantFaqHeader from '@/store/classes/MerchantFaqHeader';
  import MerchantFaqSection from '@/store/classes/MerchantFaqSection';
  import MerchantFaqContent from '@/store/classes/MerchantFaqContent';
  import addEditFaqSectionModal from './modal/add-edit-faq-section-modal.vue';
  import addEditFaqContentModal from './modal/add-edit-faq-content-modal.vue';

  export default {
    name: 'ContentFaqList',

    components: { draggable },

    mixins: [merchantMixin],

    props: {
      merchantFaqServiceTypeId: {
        type: Number,
        required: true
      }
    },

    data() {
      return {
        draggableAttributes: {
          animation: '200',
          ghostClass: 'ghost',
          handle: '.drag-handle'
        },
        isOpen: {},
        form: {}
      };
    },

    computed: {
      defaultMerchantFaqHeader() {
        return MerchantFaqHeader.query()
          .where('merchantId', 0)
          .where('merchantFaqServiceTypeId', this.merchantFaqServiceTypeId)
          .with('merchantFaqSections', query => query.orderBy('sortOrder'))
          .with('merchantFaqSections.merchantFaqContents', query => query.orderBy('sortOrder'))
          .first();
      },

      selectedMerchantFaqHeader() {
        return MerchantFaqHeader.query()
          .where('merchantId', this.$_selectedMerchantId)
          .where('merchantFaqServiceTypeId', this.merchantFaqServiceTypeId)
          .with('merchantFaqSections', query => query.orderBy('sortOrder'))
          .with('merchantFaqSections.merchantFaqContents', query => query.orderBy('sortOrder'))
          .first();
      },

      merchantFaqHeader() {
        return this.selectedMerchantFaqHeader || this.defaultMerchantFaqHeader;
      },

      isSubmitting() {
        return MerchantFaqHeader.$state().submitting;
      },

      sortingParentContentId() {
        return MerchantFaqContent.$state().sortingParentId;
      },

      sortingFaqSection() {
        return MerchantFaqSection.$state().sorting;
      },

      deletingFaqContentId() {
        return MerchantFaqContent.$state().deletingId;
      },

      deletingFaqSectionId() {
        return MerchantFaqSection.$state().deletingId;
      },

      fetchingFaqContentsId() {
        return MerchantFaqContent.$state().fetchingParentId;
      }
    },

    created() {
      this.onCreated();
    },

    methods: {
      onCreated() {
        this.resetForm();
      },

      resetForm() {
        this.form = this.$clone(this.merchantFaqHeader);
      },

      async openSectionModal(section) {
        this.$buefy.modal.open({
          parent: this,
          component: addEditFaqSectionModal,
          hasModalCard: true,
          trapFocus: true,
          canCancel: false,
          customClass: 'auto-width',
          events: { 'faq-section:added': this.onAddSection, 'faq-section:updated': this.resetForm },
          props: {
            section: section || new MerchantFaqSection({ merchantFaqHeaderId: this.form.id })
          }
        });
      },

      openContentModal({ content, sectionId }) {
        this.$buefy.modal.open({
          parent: this,
          component: addEditFaqContentModal,
          hasModalCard: true,
          trapFocus: true,
          canCancel: false,
          customClass: 'auto-width',
          events: { 'faq-content:updated': this.resetForm },
          props: {
            content: content || new MerchantFaqContent({ merchantFaqSectionId: sectionId })
          }
        });
      },

      onAddSection(sectionId) {
        this.toggleSectionOpenState(sectionId, true);
      },

      async toggleFaqSection(sectionId) {
        const wasNeverOpen = this.isOpen[sectionId] === undefined;

        if (wasNeverOpen) {
          await this.fetchMerchantFaqContents(sectionId);
        }

        this.toggleSectionOpenState(sectionId);
      },

      async toggleSectionOpenState(sectionId, isOpen) {
        const openState = isOpen !== undefined ? isOpen : !this.isOpen[sectionId];
        this.$set(this.isOpen, sectionId, openState);
      },

      async handleSectionSortOrderChange({ moved }, array) {
        try {
          await MerchantFaqSection.sortMerchantFaqSection({ fromSortOrder: moved.oldIndex, toSortOrder: moved.newIndex, array });

          this.$_onRequestSuccess({
            toastOptions: {
              message: `<b>${moved.element.sectionHeader}</b> moved from sort order <b>${moved.oldIndex + 1}</b> to <b>${moved.newIndex + 1}</b>`
            }
          });
        }

        catch (error) {
          this.$_onRequestError({
            toastOptions: { message: 'There was an error updating the sort order of your faq sections' },
            error
          });
        }
      },

      async handleContentSortOrderChange({ moved }, array) {
        try {
          await MerchantFaqContent.sortMerchantFaqContent({ fromSortOrder: moved.oldIndex, toSortOrder: moved.newIndex, array });

          this.$_onRequestSuccess({
            toastOptions: {
              message: 'FAQ sort order has been successfully updated!'
            }
          });
        }

        catch (error) {
          this.$_onRequestError({
            toastOptions: { message: 'There was an error updating the sort order of your FAQs' },
            error
          });
        }
      },

      async createOrUpdateMerchantFaqHeader() {
        let wasSaved = false;

        try {
          if (this.selectedMerchantFaqHeader) {
            await MerchantFaqHeader.updateMerchantFaqHeader(this.form);
            wasSaved = true;
          }

          else {
            const { result } = await this.$buefy.dialog.confirm({
              title: 'Update Page Title',
              message: 'Updating the Page Title will remove your ability to use defaults for this application type. Are you sure you want to update this Page Title?',
              hasIcon: true,
              confirmText: 'Update',
              icon: 'exclamation-triangle',
              type: 'is-warning',
              onConfirm: async () => {
                await MerchantFaqHeader.createMerchantFaqHeader({ merchantId: this.$_selectedMerchantId, merchantFaqHeader: this.form });
                this.resetForm();
              }
            });
            wasSaved = result;
          }

          if (wasSaved) {
            this.$_onRequestSuccess({
              toastOptions: {
                message: 'FAQ Page Title successfully updated!'
              }
            });
          }

          this.resetForm();
        }

        catch (error) {
          this.$_onRequestError({
            error,
            toastOptions: {
              message: 'There was an error updating your FAQ Page Title'
            }
          });
        }
      },

      async fetchMerchantFaqContents(sectionId) {
        try {
          await MerchantFaqContent.fetchMerchantFaqContents(sectionId);
        }

        catch (error) {
          this.$_onRequestError({
            error,
            toastOptions: {
              message: 'There was an error fetching the FAQs for this section'
            }
          });
        }
      },

      openDeleteFaqSectionConfirm(sectionId) {
        this.$buefy.dialog.confirm({
          title: 'Delete FAQ Section',
          message: 'Are you sure you want to delete this section?',
          hasIcon: true,
          icon: 'trash-alt',
          type: 'is-danger',
          onConfirm: () => this.deleteFaqMerchantSection(sectionId)
        });
      },

      openDeleteFaqContentConfirm(contentId) {
        this.$buefy.dialog.confirm({
          title: 'Delete FAQ Content',
          message: 'Are you sure you want to delete this content?',
          hasIcon: true,
          icon: 'trash-alt',
          type: 'is-danger',
          onConfirm: () => this.deleteFaqMerchantContent(contentId)
        });
      },

      async deleteFaqMerchantSection(merchantSectionId) {
        try {
          await MerchantFaqSection.deleteMerchantFaqSection(merchantSectionId);
        }

        catch (error) {
          this.$_onRequestError({
            error,
            toastOptions: {
              message: 'There was an error deleting this FAQ section'
            }
          });
        }
      },

      async deleteFaqMerchantContent(contentId) {
        try {
          await MerchantFaqContent.deleteMerchantFaqContent(contentId);
        }

        catch (error) {
          this.$_onRequestError({
            error,
            toastOptions: {
              message: 'There was an error deleting this FAQ content'
            }
          });
        }
      }
    }
  };
</script>

<style lang="sass" scoped>
  .faq-header-title-form
    display: flex
    > .field
      flex: 1
    > .button
      margin-top: 2rem


  .row
    grid-template-columns: 1fr 120px

  .answer
    white-space: pre-wrap

</style>
