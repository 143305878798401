<template>
  <div>
    <!-- This constraint requires no inputs. If a merchant adds the card it applies -->
  </div>
</template>

<script>
  export default {
    name: 'NoInputConstraintCard',

    props: {
      earnRuleConstraint: {
        type: Object,
        required: true
      }
    }
  };
</script>
