<template>
  <validated-form
    v-slot="{ dirty }"
    form-id="maintenanceMessagingForm"
    class="dist-y-lg"
    @valid-submit="handleSubmit"
  >
    <panel title="Maintenance Messaging" collapsible start-open :loading="isFetching">
      <template #buttons>
        <b-button
          native-type="submit"
          :loading="isSubmitting"
          type="is-primary"
          rounded
          size="is-medium"
          :class="['is-bold', dirty && 'pulse-slow']"
        >
          Save
        </b-button>
      </template>


      <template v-if="form && Object.keys(form).length">
        <section v-for="(appCodeType, i) in appCodeTypes" :key="appCodeType.id">
          <p class="title is-5">
            {{ appCodeType.name }}
          </p>

          <b-message
            v-if="!hasMerchantAppCodeType(appCodeType.id)"
            type="is-warning"
            has-icon
            class="is-compact has-shadow is-inline-block"
          >
            Configure this merchant application setting
            <router-link
              :to="{
                name: 'merchantConfiguration',
                params: { tabName: 'configurations'},
                hash: '#app-setting-configs'
              }"
            >
              <span class="has-text-weight-bold">here</span>
            </router-link>
            to manage the maintenance messaging
          </b-message>

          <template v-else>
            <div class="is-grid col-2 gap">
              <template v-for="maintenanceCode in maintenanceCodes">
                <maintenance-message
                  v-if="form[appCodeType.id][maintenanceCode.id]"
                  :key="`${appCodeType.id}-${maintenanceCode.id}-1`"
                  v-model="form[appCodeType.id][maintenanceCode.id]"
                  @removed="handleRemoveMaintenanceMessage({
                    appCodeTypeId: appCodeType.id,
                    maintenanceCodeId: maintenanceCode.id
                  })"
                />
              </template>
            </div>
            <b-dropdown
              :key="`${appCodeType.id}-2`"
              :class="{ 'mar-t-lg': Object.keys(form[appCodeType.id]).length }"
              :triggers="['click']"
              aria-role="list"
              :disabled="!availableMaintenanceCodesByAppType[appCodeType.id].length"
            >
              <b-button
                slot="trigger"
                type="is-primary"
                icon-left="plus"
                :disabled="!availableMaintenanceCodesByAppType[appCodeType.id].length"
              >
                {{ appCodeType.name }} Messaging
              </b-button>
              <b-dropdown-item
                v-for="maintenanceCode in availableMaintenanceCodesByAppType[appCodeType.id]"
                :key="maintenanceCode.id"
                class="is-flex justify-between pad-r-sm"
                aria-role="listitem"
                @click="addMerchantMaintenanceInfo({
                  appCodeTypeId: appCodeType.id,
                  maintenanceCodeId: maintenanceCode.id })"
              >
                {{ maintenanceCode.displayName }}
                <b-icon icon="arrow-right" />
              </b-dropdown-item>
            </b-dropdown>
          </template>
          <hr v-if="i < appCodeTypes.length - 1" :key="`${appCodeType.id}-3`">
        </section>
      </template>
    </panel>
  </validated-form>
</template>

<script>
  import merchantMixin from '@/mixins/merchant';
  import MaintenanceCode from '@/store/classes/MaintenanceCode';
  import MerchantMaintenanceInfo from '@/store/classes/MerchantMaintenanceInfo';
  import MerchantAppSetting from '@/store/classes/MerchantAppSetting';
  import AppCodeType from '@/store/classes/AppCodeType';
  import maintenanceMessage from './maintenance-message.vue';
  import { fetchAppCodeTypesByMerchant } from '@/api/merchant';
  import getChangedResources from '@/helpers/get-changed-resources';

  export default {
    name: 'MaintenanceMessaging',

    components: { maintenanceMessage },

    mixins: [merchantMixin],

    data() {
      return {
        form: null,
        merchantAppCodeTypes: null,
        isFetching: false,
        isSubmitting: false
      };
    },

    computed: {
      merchantMaintenanceInfoByAppType() {
        return this.$clone(this.appCodeTypes).reduce((acc, appCodeType) => {
          const merchantAppSettingPerAppCode = this.getMerchantAppSettingByAppCode(appCodeType.id);
          if (merchantAppSettingPerAppCode) {
            const merchantMaintenanceInfoByAppCode = this.merchantMaintenanceInfos.filter(maintenanceInfo => maintenanceInfo.merchantAppSettingsId === merchantAppSettingPerAppCode.id);
            acc[appCodeType.id] = merchantMaintenanceInfoByAppCode.reduce((maintenanceAcc, maintenanceInfo) => {
              maintenanceAcc[maintenanceInfo.maintenanceCodeId] = maintenanceInfo;
              return maintenanceAcc;
            }, {});
          }
          else {
            acc[appCodeType.id] = {};
          }
          return acc;
        }, {});
      },

      availableMaintenanceCodesByAppType() {
        return this.$clone(this.appCodeTypes).reduce((acc, appCodeType) => {
          const maintenanceCodesByAppType = Object.keys(this.form[appCodeType.id]).map(typeId => Number(typeId));
          if (appCodeType.id === 1) {
            // NOTE: If app code type is Web, only allow MaintenanceMode maintenance code type
            acc[appCodeType.id] = this.maintenanceCodes.filter(code => !maintenanceCodesByAppType.includes(code.id) && code.id === 1);
          }
          else {
            acc[appCodeType.id] = this.maintenanceCodes.filter(code => !maintenanceCodesByAppType.includes(code.id));
          }
          return acc;
        }, {});
      },

      merchantMaintenanceInfos() {
        return MerchantMaintenanceInfo.query().withAllRecursive().get();
      },

      maintenanceCodes() {
        return MaintenanceCode.all();
      },

      appCodeTypes() {
        return AppCodeType.all();
      },

      merchantAppSettings() {
        return MerchantAppSetting.all();
      }
    },

    created() {
      this.onCreated();
    },

    methods: {
      async onCreated() {
        try {
          this.toggleLoading('isFetching', true);

          await Promise.all([
            MerchantAppSetting.fetchMerchantAppSettings(this.$_selectedMerchantId),
            AppCodeType.fetchAppCodeTypes(),
            MerchantMaintenanceInfo.fetchMerchantMaintenanceInfos(),
            MaintenanceCode.fetchMaintenanceCodes()
          ]);
          this.merchantAppCodeTypes = await fetchAppCodeTypesByMerchant(this.$_selectedMerchantId);
          this.setForm();
        }
        catch (error) {
          this.$_onRequestError({
            toastOptions: {
              message: 'There was an error getting data for maintenance codes'
            },
            error
          });
        }
        finally {
          this.toggleLoading('isFetching', false);
        }
      },

      setForm() {
        this.form = this.$clone(this.merchantMaintenanceInfoByAppType);
      },

      toggleLoading(type, value) {
        this[type] = value;
      },

      hasMerchantAppCodeType(appTypeId) {
        return !!this.merchantAppCodeTypes.find(type => type.id === appTypeId);
      },

      getMerchantAppSettingByAppCode(appCodeTypeId) {
        return this.merchantAppSettings.find(setting => setting.appCodeTypeId === appCodeTypeId);
      },

      addMerchantMaintenanceInfo({ appCodeTypeId, maintenanceCodeId }) {
        const newMerchantMaintenanceInfo = new MerchantMaintenanceInfo({
          merchantAppSettingsId: this.getMerchantAppSettingByAppCode(appCodeTypeId)?.id,
          maintenanceCodeId
        });
        this.$set(this.form[appCodeTypeId], maintenanceCodeId, newMerchantMaintenanceInfo);
      },

      handleRemoveMaintenanceMessage({ appCodeTypeId, maintenanceCodeId }) {
        const merchantMaintenanceInfo = this.merchantMaintenanceInfoByAppType[appCodeTypeId][maintenanceCodeId];
        if (merchantMaintenanceInfo) {
          const appCodeTypeName = this.appCodeTypes.find(act => act.id === appCodeTypeId).name;
          this.$buefy.dialog.confirm({
            title: 'Delete Maintenance Message',
            message: `Are you sure you want to delete <b>${appCodeTypeName} — ${merchantMaintenanceInfo.maintenanceCode.displayName}</b>?`,
            onConfirm: () => this.removeMaintenanceMessage(merchantMaintenanceInfo.id),
            confirmText: 'Delete',
            type: 'is-danger',
            hasIcon: true,
            icon: 'trash-alt'
          });
        }
        else {
          const updatedMaintenanceInfo = this.$clone(this.form[appCodeTypeId]);
          delete updatedMaintenanceInfo[maintenanceCodeId];
          this.$set(this.form, appCodeTypeId, updatedMaintenanceInfo);
        }
      },

      async removeMaintenanceMessage(maintenanceMessageId) {
        try {
          this.toggleLoading('isSubmitting', true);

          await MerchantMaintenanceInfo.removeMerchantMaintenanceInfo(maintenanceMessageId);

          this.$_onRequestSuccess({
            toastOptions: {
              message: 'Maintenance messaging successfully updated!'
            }
          });

          this.setForm();
        }
        catch (error) {
          this.$_onRequestError({
            toastOptions: {
              message: 'There was an issue removing your maintenance messaging'
            },
            error
          });
        }
        finally {
          this.toggleLoading('isSubmitting', false);
        }
      },

      async handleSubmit() {
        try {
          this.toggleLoading('isSubmitting', true);

          const updatedMaintenanceInfo = Object.values(this.form).flatMap(x => Object.values(x));
          const { added, updated } = getChangedResources({
            oldArray: this.merchantMaintenanceInfos,
            newArray: updatedMaintenanceInfo,
            comparisonKey: 'id',
            updatedComparisonFn: (n, o) => ['title', 'url', 'message'].some(key => n[key] !== o[key])
          });

          await Promise.all([
            ...added.map(a => MerchantMaintenanceInfo.addMerchantMaintenanceInfo(a)),
            ...updated.map(u => MerchantMaintenanceInfo.updateMerchantMaintenanceInfo(u))
          ]);

          this.$_onRequestSuccess({
            toastOptions: {
              message: 'Maintenance messaging successfully updated!'
            }
          });

          this.setForm();
        }
        catch (error) {
          this.$_onRequestError({
            toastOptions: {
              message: 'There was an issue updating your maintenance messaging'
            },
            error
          });
        }

        finally {
          this.toggleLoading('isSubmitting', false);
        }
      }
    }
  };
</script>
