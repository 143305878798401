<template>
  <div class="card-group">
    <registered-guest-loyalty-info
      id="registered-guest-loyalty-info"
      :loyalty-reward="registeredGuest.loyaltyReward"
      :cf-loyalty-enabled="cfLoyaltyEnabled"
    />
    <registered-guest-offers
      v-if="showOffersPanel"
      :registered-guest="registeredGuest"
    />
    <registered-guest-membership-activities
      v-if="cfLoyaltyEnabled"
      :registered-guest="registeredGuest"
    />
    <registered-guest-challenge-progress v-if="cfChallengesEnabled" :registered-guest="registeredGuest" />
  </div>
</template>

<script>
  import MerchantLoyaltyMembership from '@/store/classes/MerchantLoyaltyMembership';
  import registeredGuestLoyaltyInfo from './registered-guest-loyalty-info.vue';
  import registeredGuestMembershipActivities from './registered-guest-membership-activities.vue';
  import RegisteredGuestOffers from './registered-guest-offers.vue';
  import RegisteredGuestChallengeProgress from './registered-guest-challenge-progress.vue';
  import merchantMixin from '@/mixins/merchant';

  export default {
    name: 'RegisteredGuestLoyalty',

    components: {
      registeredGuestLoyaltyInfo,
      registeredGuestMembershipActivities,
      RegisteredGuestOffers,
      RegisteredGuestChallengeProgress
    },

    mixins: [merchantMixin],

    props: {
      registeredGuest: {
        type: Object,
        required: true
      }
    },

    computed: {
      loyaltyMembership() {
        return MerchantLoyaltyMembership.find(this.$_selectedMerchantId);
      },
      showOffersPanel() {
        return this.loyaltyMembership?.accountOffersEnabled && this.loyaltyMembership?.cfOffersEnabled;
      },
      cfLoyaltyEnabled() {
        return this.loyaltyMembership?.cfLoyaltyEnabled;
      },
      cfChallengesEnabled() {
        return this.$_selectedMerchant?.features.challenges;
      }
    }
  };
</script>
