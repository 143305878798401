import { Model } from '@vuex-orm/core';
import http from '@/services/http';
import { subject } from '@casl/ability';
import filterObjectKeys from '@/helpers/filter-object-keys';


import StoreCommunication from './StoreCommunication';
import StoreDeliveryService from './StoreDeliveryService';
import StoreItem from './StoreItem';
import StoreModifier from './StoreModifier';
import RegionMenu from './RegionMenu'; // eslint-disable-line import/no-cycle
import StorePickupInstruction from './StorePickupInstruction';
import MerchantMenuTypeConfiguration from './MerchantMenuTypeConfiguration';
import StoreOrderLimit from './StoreOrderLimit';

import storeMappingAttributes from '@/constants/storeMappingAttributes';
import posTypes from '@/constants/posTypes';



export default class Store extends Model {
  static entity = 'stores';

  static primaryKey = 'storeId'

  // FIELDS //////////////////////
  static fields() {
    return {
      storeId: this.attr(''),
      businessLocationId: this.attr(''),
      description: this.attr(''),
      displayName: this.attr(''),
      merchantId: this.attr(''),
      errors: this.attr(''),
      externalDeviceMasterPasscode: this.attr(''),
      externalDeviceSecondaryPasscode: this.attr(''),
      hasBeenFavorited: this.attr(''),
      expoLargeOrderItemTotal: this.number(100),

      // Authorizations
      cloverOauthV2: this.attr(''),
      cloverOauthV3: this.attr(''),
      cloverAuthorizeV2: this.attr(''),
      hasApprovedClover: this.attr(false),
      hasActiveCloverAuthApproval: this.attr(false),
      needsFullCloverReauth: this.attr(false),

      // POS Type
      merchantLocationId: this.attr(null),
      posLocationId: this.attr(''),
      paymentLocationId: this.attr(''),
      posTypeId: this.attr(null),
      posType: this.attr('', (posType) => {
        try {
          const parsedMetaData = JSON.parse(posType.metaData);

          // NOTE: With POS type = 0 (default stores), metadata will be null,
          // so set default to {} in order not to break accessors to metadata properties
          return {
            ...posType,
            metaData: parsedMetaData || {}
          };
        }

        catch {
          return posType;
        }
      }),
      posEmployeeId: this.attr(''),
      posRevenueCenter: this.attr(''),
      posThirdPartyTenderTypeId: this.attr(''),
      posSyncErrors: this.attr({}),
      textToPayTimeoutInHours: this.attr(null),
      useStorePosMapping: this.attr(''),

      dataSource: this.attr(''),
      ianaTimezoneId: this.attr(null),
      // POST property (Number)
      totalSalesTaxRate: this.attr(''),
      storeMappingAttributes: this.attr([]),
      posPriceSyncEnabled: this.attr(true),

      // open / close
      sunIsOpen: this.attr(false),
      monIsOpen: this.attr(false),
      tueIsOpen: this.attr(false),
      wedIsOpen: this.attr(false),
      thuIsOpen: this.attr(false),
      friIsOpen: this.attr(false),
      satIsOpen: this.attr(false),
      storeMappingHours: this.attr([]),
      storeMappingPrinters: this.attr(null),

      // pickup / offset times / pickup instructions
      leadPickupTime: this.attr('00:15:00'),
      openPickupTimeOffset: this.attr(null),
      closePickupTimeOffset: this.attr(null),
      maximumOrderAheadDays: this.attr(7),
      roomServiceLeadTime: this.attr('00:15:00'),
      cateringLeadTime: this.attr('00:15:00'),

      // ordering schedule
      pickupTimesIntervalMinutes: this.attr(15),

      // contact info
      emailAddress: this.attr(''),
      phoneNumber: this.attr(''),

      // store status
      isActive: this.attr(true),
      isVirtual: this.attr(false),
      isSystem: this.attr(false),

      // address / location
      addressLine1: this.attr(''),
      addressLine2: this.attr(''),
      city: this.attr(''),
      region: this.attr(''),
      postalCode: this.attr(''),
      countryCode: this.attr(''),
      latitude: this.attr(''),
      longitude: this.attr(''),
      mapsUrlOverride: this.attr(''),

      // notifications
      onSiteNotificationEnabled: this.attr(false),
      onSiteCurbsideNotificationEnabled: this.attr(false),
      onSiteDriveThroughNotificationEnabled: this.attr(false),
      emailOrderReceiptEnabled: this.attr(false),
      smsOrderReceiptEnabled: this.attr(false),
      orderPaidNotificationEnabled: this.attr(false),
      orderCompleteCustomerNotificationEnabled: this.attr(false),
      orderInProgressCustomerNotificationEnabled: this.attr(false),
      orderNewCustomerNotificationEnabled: this.attr(false),
      orderCanceledCustomerNotificationEnabled: this.attr(false),
      onSiteTextToPayNotificationEnabled: this.attr(false),
      cateringNotificationEnabled: this.attr(false),

      allowAfterHoursOrdering: this.attr(true),
      canBeIntegratedWithUberEats: this.attr(false),
      menuRegionId: this.attr(null),

      // loyalty
      geofenceOfferRadiusInFeet: this.attr(null),
      loyaltyLocationId: this.attr(''),
      loyaltyStoreNumber: this.attr(''),
      loyaltyShortIdentifierCode: this.attr(''),
      loyaltyApiKey: this.attr(''),

      // relationships
      storeDeliveryServices: this.hasMany(StoreDeliveryService, 'storeId'),
      storeMappingCommunications: this.hasMany(StoreCommunication, 'storeId'),
      storePickupInstructions: this.hasMany(StorePickupInstruction, 'storeId'),
      storeItems: this.hasMany(StoreItem, 'storeId'),
      regionMenu: this.belongsTo(RegionMenu, 'menuRegionId'),
      orderLimits: this.hasMany(StoreOrderLimit, 'storeId')
    };
  }

  get fullAddress() {
    return `${this.addressLine1}, ${this.city} ${this.region} ${this.postalCode}`;
  }

  get curbsidePickupEnabled() {
    return !!this.storeMappingAttributes.find(attr => attr.code === storeMappingAttributes.CURBSIDE.code)?.isActive;
  }

  get dineInEnabled() {
    return !!this.storeMappingAttributes.find(attr => attr.code === storeMappingAttributes.DINE_IN.code)?.isActive;
  }

  get takeOutEnabled() {
    return !!this.storeMappingAttributes.find(attr => attr.code === storeMappingAttributes.TAKE_OUT.code)?.isActive;
  }

  get cateringEnabled() {
    return !!this.storeMappingAttributes.find(attr => attr.code === storeMappingAttributes.CATERING_ORDERING.code)?.isActive;
  }

  get deliveryEnabled() {
    return !!this.storeMappingAttributes.find(attr => attr.code === storeMappingAttributes.DELIVERY.code)?.isActive;
  }

  get roomServiceEnabled() {
    return !!this.storeMappingAttributes.find(attr => attr.code === storeMappingAttributes.ROOM_SERVICE.code)?.isActive;
  }

  get mobileOrderingEnabled() {
    return !!this.storeMappingAttributes.find(attr => attr.code === storeMappingAttributes.MOBILE_ORDERING.code)?.isActive;
  }

  get webOrderingEnabled() {
    return !!this.storeMappingAttributes.find(attr => attr.code === storeMappingAttributes.WEB_ORDERING.code)?.isActive;
  }

  get kioskOrderingEnabled() {
    return !!this.storeMappingAttributes.find(attr => attr.code === storeMappingAttributes.KIOSK_ORDERING.code)?.isActive;
  }

  get wineEnabled() {
    return !!this.storeMappingAttributes.find(attr => attr.code === storeMappingAttributes.WINE.code)?.isActive;
  }

  get barEnabled() {
    return !!this.storeMappingAttributes.find(attr => attr.code === storeMappingAttributes.BAR.code)?.isActive;
  }

  get onPremiseDeliveryEnabled() {
    return !!this.storeMappingAttributes.find(attr => attr.code === storeMappingAttributes.ON_PREMISE_DELIVERY.code)?.isActive;
  }

  get requirePickUpTimeSelectionEnabled() {
    return !!this.storeMappingAttributes.find(attr => attr.code === storeMappingAttributes.REQUIRE_PICK_UP_TIME_SELECTION.code)?.isActive;
  }

  get tippingEnabled() {
    return !!this.storeMappingAttributes.find(attr => attr.code === storeMappingAttributes.TIPPING.code)?.isActive;
  }

  get payAtTableEnabled() {
    return !!this.storeMappingAttributes.find(attr => attr.code === storeMappingAttributes.PAY_AT_TABLE.code)?.isActive;
  }

  get orderAheadEnabled() {
    return !!this.storeMappingAttributes.find(attr => attr.code === storeMappingAttributes.ORDER_AHEAD.code)?.isActive;
  }

  get asapPickupOnlyEnabled() {
    return !!this.storeMappingAttributes.find(attr => attr.code === storeMappingAttributes.ASAP_PICKUP_ONLY.code)?.isActive;
  }

  get supportedMenuTypes() {
    const menuTypes = MerchantMenuTypeConfiguration.getMenuTypes(this.merchantId);

    const supportMap = {
      // [storeMappingAttribute.code]: [menuType.name]
      DineIn: 'DineIn',
      DriveThrough: 'DineIn',
      Delivery: 'Delivery',
      TakeOut: 'TakeOut',
      Curbside: 'TakeOut',
      CateringOrdering: 'Catering',
      RoomService: 'RoomService',
      Shipping: 'Shipping',
      KioskOrdering: 'Kiosk',
      WebOrdering: 'Web',
      MobileOrdering: 'Mobile',
      Wine: 'Wine',
      Bar: 'Bar',
      OnPremDelivery: 'OnPremiseDelivery'
    };

    return menuTypes.filter(menuType => this.storeMappingAttributes.some((attr) => {
      const { code, isActive } = attr;
      return isActive && supportMap[code] === menuType.name;
    }));
  }

  get isCardfreePOS() {
    return this.posType?.id === posTypes.Cardfree;
  }

  get isReadOnlyLocationMenu() {
    return this.posType?.metaData?.readOnlyLocationMenu;
  }

  get hasPrinters() {
    return this.storeMappingPrinters.some(printer => printer.isActive);
  }



  // QUERIES //////////////////////
  static orderByName() {
    return this.query().orderBy(store => store.description.toLowerCase());
  }

  static favoritedStores() {
    return this.query().where('hasBeenFavorited', true);
  }

  // STATE //////////////////////
  static state() {
    return {
      fetchingAll: false,
      submitting: false
    };
  }

  static $state() {
    return this.store().state.entities.stores;
  }


  // ACTIONS //////////////////////
  static async fetchAll(merchantId, options = {}) {
    try {
      this.commit((state) => {
        state.fetchingAll = true;
      });

      const id = merchantId || this.store().state.entities.merchants.selectedMerchantId;

      const alreadyFetched = this.query().where('merchantId', id).exists();

      const queryParams = [];

      if (options.posSync) {
        queryParams.push('posSync=true');
      }
      if (options.favoritedStores) {
        queryParams.push('favoritedStores=true');
      }

      const queryString = queryParams.length > 0 ? `?${queryParams.join('&')}` : '';

      if (!alreadyFetched || options.forceFetch) {
        const response = await http.get(`merchants/${id}/stores${queryString}`);

        this.create({
          data: response.data.stores
        });

        return response.data.stores;
      }
    }

    catch (error) {
      throw error;
    }

    finally {
      this.commit((state) => {
        state.fetchingAll = false;
      });
    }
  }

  static async fetchById(storeId) {
    try {
      this.commit((state) => {
        state.fetchingAll = true;
      });
      const merchantId = this.store().state.entities.merchants.selectedMerchantId;

      const response = await http.get(`merchants/${merchantId}/stores/${storeId}`);
      this.insert({
        data: response.data.store
      });
    }

    catch (error) {
      throw error;
    }

    finally {
      this.commit((state) => {
        state.fetchingAll = false;
      });
    }
  }

  static async updateStores(stores) {
    try {
      this.commit((state) => {
        state.submitting = true;
      });

      const acceptedKeys = ['storeId', 'menuRegionId'];
      const { data } = await http.put('stores/bulk_update', {
        stores: stores.map(store => filterObjectKeys(store, acceptedKeys))
      });
      this.update({ data: data.stores });
    }
    catch (error) {
      throw error;
    }
    finally {
      this.commit((state) => {
        state.submitting = false;
      });
    }
  }

  static async updateStore(_storeData) {
    try {
      this.commit((state) => {
        state.submitting = true;
      });
      const merchantId = this.store().state.entities.merchants.selectedMerchantId;
      const storeData = JSON.parse(JSON.stringify(_storeData));
      const { ability } = this.store().state.session;

      if (storeData.storeMappingAttributes) {
        const filteredStoreMappingAttrs = JSON.parse(JSON.stringify(storeData.storeMappingAttributes)).filter(
          storeMappingAttribute => ability.can(
            'update',
            subject('StoreMappingAttribute', storeMappingAttribute)
          )
        );
        storeData.storeMappingAttributes = filteredStoreMappingAttrs;
      }

      const response = await http.put(`merchants/${merchantId}/stores/${storeData.storeId}`, {
        store: storeData
      });

      this.insert({
        data: response.data.store
      });
    }

    catch (error) {
      throw error;
    }

    finally {
      this.commit((state) => {
        state.submitting = false;
      });
    }
  }

  static async addStore(storeData, refetchUser) {
    try {
      this.commit((state) => {
        state.submitting = true;
      });

      const merchantId = this.store().state.entities.merchants.selectedMerchantId;

      const { data } = await http.post(`merchants/${merchantId}/stores`, {
        store: storeData
      });

      this.insert({
        data: data.store
      });

      if (refetchUser) { // TEST
        await this.store().dispatch('session/initializeSession');
      }

      return data.store.storeId;
    }

    catch (error) {
      throw error;
    }

    finally {
      this.commit((state) => {
        state.submitting = false;
      });
    }
  }

  static async toggleMenuResourcesDisabled({ storeId, isDisabled }) {
    try {
      const merchantId = this.store().state.entities.merchants.selectedMerchantId;

      const {
        data: { storeMenuItems, storeMenuItemModifiers }
      } = await http.put(`merchants/${merchantId}/store_menus/${storeId}/${isDisabled ? 'hide_items' : 'show_items'}`);

      StoreItem.update({ data: storeMenuItems });
      StoreModifier.update({ data: storeMenuItemModifiers });
    }

    catch (error) {
      throw error;
    }
  }

  static async addStoreMappingHour({ storeId, storeMappingHour }) {
    try {
      await http.post(`stores/${storeId}/store_mapping_hours`, { storeMappingHour });
    }
    catch (error) {
      throw error;
    }
  }

  static async updateStoreMappingHour(storeMappingHour) {
    try {
      await http.put(`store_mapping_hours/${storeMappingHour.id}`, { storeMappingHour });
    }
    catch (error) {
      throw error;
    }
  }

  static async deleteStoreMappingHour(storeMappingHourId) {
    try {
      await http.delete(`store_mapping_hours/${storeMappingHourId}`);
    }
    catch (error) {
      throw error;
    }
  }

  static async refreshCloverAuth(storeId) {
    try {
      const response = await http.post(`stores/${storeId}/refresh_clover_v2`);

      // NOTE: we need to update hasActiveCloverAuthApproval
      this.insertOrUpdate({
        data: response.data.store
      });
    }
    catch (error) {
      throw error;
    }
  }
}
