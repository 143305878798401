<template>
  <div
    :class="[
      'hero',
      type,
      { [`is-textured-${textureBrightness}`]: isTextured }
    ]"
  >
    <div class="hero-body has-text-centered-mobile">
      <div class="container">

        <template v-if="hasSlot('right')">
          <div class="level">
            <div class="level-left">
              <div>
                <slot />
              </div>
            </div>
            <div class="level-right">
              <slot name="right" />
            </div>
          </div>
        </template>

        <template v-else>
          <slot />
        </template>

      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'HeroBanner',

    props: {
      isTextured: {
        type: Boolean,
        default: true
      },

      textureBrightness: {
        type: String,
        default: 'dark',
        validator(val) {
          return ['dark', 'darker', 'light', 'lighter'].includes(val);
        }
      },

      type: {
        type: String,
        default: 'is-primary'
      }
    },

    methods: {
      hasSlot(slotName) {
        return Boolean(this.$slots[slotName]);
      }
    }
  };
</script>
