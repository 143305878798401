var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('panel',{attrs:{"title":"Challenges Progress","no-padding":""}},[(_vm.isFetching)?_c('b-loading',{attrs:{"active":_vm.isFetching,"is-full-page":false}}):_vm._e(),_c('searchable-table',{attrs:{"table-data":_vm.challenges,"search":{
      placeholder: 'Search by Challenge Name',
      keys: ['name']
    },"filters":[
      {
        placeholder: 'Status',
        key: 'portalDerivedStatus',
        multiple: true,
        default: [
          _vm.earnRuleStatuses.ACTIVE,
          _vm.earnRuleStatuses.EXPIRING,
          _vm.earnRuleStatuses.EXPIRED
        ],
        options: Object.keys(_vm.tagTypes).map(function (key) { return ({
          name: _vm.$changeCase.capitalCase(key),
          value: key
        }); })
      } ]},scopedSlots:_vm._u([{key:"default",fn:function(ref){
    var filteredData = ref.filteredData;
return [_c('b-table',{staticClass:"is-middle-aligned",attrs:{"data":_vm.isFetching ? [] : filteredData,"mobile-cards":false,"scrollable":"","per-page":_vm.perPage,"paginated":"","current-page":_vm.page,"detail-key":"publicId","data-test-id":"challenges-table","default-sort":['portalDerivedStatus', 'asc']},on:{"page-change":function($event){_vm.page = $event}},scopedSlots:_vm._u([{key:"empty",fn:function(){return [_c('empty-table-loader',{attrs:{"message":"No Challenges Progress Found...","loading":_vm.isFetching}})]},proxy:true},{key:"bottom-left",fn:function(){return [_c('div',{staticClass:"left-side is-grid col-2 gap-x"},[_c('page-number-select',{attrs:{"page":_vm.page,"per-page":Number(_vm.perPage),"total-count":filteredData.length},on:{"input":function($event){_vm.page = $event}}}),_c('b-field',{attrs:{"label-for":"perPage","label-position":"on-border","label":"Per Page"}},[_c('b-select',{attrs:{"id":"perPage"},model:{value:(_vm.perPage),callback:function ($$v) {_vm.perPage=$$v},expression:"perPage"}},[_c('option',{attrs:{"value":"10"}},[_vm._v("10")]),_c('option',{attrs:{"value":"20"}},[_vm._v("20")]),_c('option',{attrs:{"value":"50"}},[_vm._v("50")]),_c('option',{attrs:{"value":"100"}},[_vm._v("100")])])],1)],1)]},proxy:true}],null,true)},[_c('b-table-column',{attrs:{"sortable":"","field":"name","label":"Name","cell-class":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
    var row = ref.row;
return [_c('span',{staticClass:"link",on:{"click":function($event){return _vm.openChallengeModal(row)}}},[_vm._v(" "+_vm._s(row.name)+" ")]),_c('p',{staticClass:"is-size-7"},[_vm._v(" "+_vm._s(row.description)+" ")])]}}],null,true)}),_c('b-table-column',{attrs:{"sortable":"","field":"portalDerivedStatus","label":"Status","centered":"","width":"150px","cell-class":"no-wrap-text","custom-sort":_vm.sortStatus},scopedSlots:_vm._u([{key:"default",fn:function(ref){
    var row = ref.row;
return [_c('b-tag',{staticClass:"is-outlined is-light",attrs:{"type":_vm.tagTypes[row.portalDerivedStatus]}},[_vm._v(" "+_vm._s(_vm.$changeCase.capitalCase(row.portalDerivedStatus))+" ")])]}}],null,true)}),_c('b-table-column',{attrs:{"label":"Progress To Next Award"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
    var row = ref.row;
return [(row.isInProgress)?_c('div',{staticClass:"mar-l-lg"},[_c('span',{staticClass:"mar-r-sm"},[_vm._v(_vm._s(row.challengeProgress.currentBalance)+" / "+_vm._s(row.challengeProgress.cost))]),_c('span',[_vm._v(_vm._s(row.pluralName))])]):_c('div',{staticClass:"mar-l-lg"},[_c('span',{staticClass:"mar-r-sm"},[_vm._v("0 / "+_vm._s(_vm.getRowConversionThresholdCost(row.conversionThresholds)))]),_c('span',[_vm._v(_vm._s(row.pluralName))])])]}}],null,true)}),_c('b-table-column',{attrs:{"centered":"","label":"Rewards Issued"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
    var row = ref.row;
return [(row.isInProgress)?_c('span',[_vm._v(" "+_vm._s(row.challengeProgress.rewardsIssuedCount)+" ")]):_c('span',[_vm._v(" 0 ")])]}}],null,true)}),_c('b-table-column',{attrs:{"centered":"","cell-class":"actions","label":"Actions","width":"1","field":"Actions"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
    var row = ref.row;
return [_c('dropdown-menu',{attrs:{"position":"bottom-end","mobile-modal":false}},[_c('b-button',{staticClass:"is-transparent",attrs:{"slot":"trigger","type":"is-white"},slot:"trigger"},[_c('b-icon',{attrs:{"icon":"ellipsis-v","pack":"far","size":"is-small"}})],1),_c('b-dropdown-item',{on:{"click":function($event){return _vm.openChallengeModal(row)}}},[_c('b-icon',{staticClass:"mar-r-sm",attrs:{"icon":"eye","size":"is-small"}}),_vm._v(" View Challenge ")],1),(_vm.$can('update', 'Account') && row.portalDerivedStatus === _vm.earnRuleStatuses.ACTIVE)?_c('b-dropdown-item',{on:{"click":function($event){return _vm.openAdjustProgressModal(row)}}},[_c('b-icon',{staticClass:"mar-r-sm",attrs:{"icon":"bars-progress","size":"is-small"}}),_vm._v(" Adjust Progress ")],1):_vm._e()],1)]}}],null,true)})],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }