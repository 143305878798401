<template>
  <div class="card-group">
    <!-- Guest Information -->
    <validated-form
      ref="guest-info-form"
      form-id="guest-info"
      :disabled="!$can('update', 'Account')"
      @valid-submit="handleGuestInfoSubmit"
    >
      <panel title="Guest Information" :loading="isLoading.validations" hide-content-while-loading>
        <template v-if="$can('update', 'Account')" #buttons>
          <div class="buttons all-bold">
            <b-button
              rounded
              class="is-bold"
              native-type="submit"
              type="is-primary"
              :loading="isLoading.guestInfoForm"
            >
              Save
            </b-button>
          </div>
        </template>

        <div class="is-grid col-min-400 gap-x-xl gap-y">
          <validated-text-input
            v-if="showGuestInfoForFields(['firstName', 'fullName'])"
            v-model="form.firstName"
            type="text"
            name="firstName"
            label="First Name"
            allow-empty-string
            :rules="{
              required: ['firstName', 'fullName'].some(field => requiredFields.includes(field))
            }"
            data-test-id="first-name-input"
          />

          <validated-text-input
            v-if="showGuestInfoForFields(['lastName', 'fullName'])"
            v-model="form.lastName"
            type="text"
            name="lastName"
            label="Last Name"
            allow-empty-string
            :rules="{
              required: ['lastName', 'fullName'].some(field => requiredFields.includes(field))
            }"
            data-test-id="last-name-input"
          />

          <validated-text-input
            v-if="showGuestInfoForFields(['primarySmsNumber'])"
            v-model="form.primarySmsNumber"
            :disabled="primarySmsNumberDisabled"
            type="phone"
            name="primarySmsNumber"
            label="Phone Number"
            allow-empty-string
            :rules="{
              required: requiredFields.includes('primarySmsNumber')
            }"
            data-test-id="phone-input"
          />

          <validated-text-input
            v-if="showGuestInfoForFields(['email'])"
            v-model="form.email"
            type="email"
            name="email"
            label="Email"
            allow-empty-string
            :rules="{
              required: requiredFields.includes('email')
            }"
            data-test-id="email-input"
          />

          <validation-observer
            v-if="showGuestInfoForFields(['birthdayInfo'])"
            v-slot="{errors: allErrors}"
            slim
            data-test-id="birthday-info-input"
          >
            <b-field expanded>
              <template #label>
                <span>Date of Birth</span>
                <b-icon
                  v-if="Object.values(allErrors).flat().length"
                  icon="exclamation-circle"
                  size="is-small"
                  type="is-danger"
                  class="mar-l-xs"
                />
              </template>
              <!-- Month -->
              <validation-provider
                v-slot="{errors}"
                slim
                name="Month"
                vid="birthMonth"
                :rules="{ required : $can('update', 'Account') && requiredFields.includes('birthdayInfo') }"
              >
                <b-field
                  :type="errors.length ? 'is-danger' : ''"
                  :style="errors.length && 'z-index: 10'"
                  :message="errors.length ? 'Month Is Required' : ''"
                  expanded
                  class="control"
                  :disabled="!$can('update', 'Account')"
                >
                  <b-select v-model="form.birthMonth" placeholder="Month" expanded>
                    <option v-for="(month, i) in months" :key="i" :value="i + 1">
                      {{ month }}
                    </option>
                  </b-select>
                </b-field>
              </validation-provider>
              <!-- Day -->
              <validation-provider
                v-slot="{errors}"
                slim
                name="Day"
                vid="birthDay"
                :rules="{ required : $can('update', 'Account') && requiredFields.includes('birthdayInfo') }"
              >
                <b-field
                  :type="errors.length ? 'is-danger' : ''"
                  :style="errors.length && 'z-index: 10'"
                  :message="errors.length ? 'Day Is Required' : ''"
                  expanded
                  class="control"
                  :disabled="!$can('update', 'Account')"
                >
                  <b-select v-model="form.birthDay" placeholder="Day" expanded>
                    <option v-for="day in daysInMonth" :key="day" :value="day">
                      {{ day }}
                    </option>
                  </b-select>
                </b-field>
              </validation-provider>
              <!-- Year -->
              <validation-provider
                v-if="showGuestInfoForFields(['birthdayInfo.year'])"
                v-slot="{errors}"
                slim
                name="Year"
                vid="birthYear"
                :rules="{ required : $can('update', 'Account') && requiredFields.includes('birthdayInfo.year') }"
              >
                <b-field
                  :type="errors.length ? 'is-danger' : ''"
                  :style="errors.length && 'z-index: 10'"
                  :message="errors.length ? 'Year Is Required' : ''"
                  expanded
                  class="control"
                  :disabled="!$can('update', 'Account')"
                >
                  <b-select v-model="form.birthYear" placeholder="Year" expanded>
                    <option v-for="n in 100" :key="n" :value="new Date().getFullYear() - n + 1">
                      {{ new Date().getFullYear() - n + 1 }}
                    </option>
                  </b-select>
                </b-field>
              </validation-provider>
            </b-field>
          </validation-observer>

          <b-field :label="`Registration Date (${renderedTz()})`">
            <b-input :value="convertDateToTz(registeredGuest.createdAt)" disabled />
          </b-field>

          <validated-text-input
              v-if="showGuestInfoForFields(['zip'])"
              v-model="form.zip"
              type="text"
              name="postalCode"
              label="Postal Code"
              allow-empty-string
              :rules="{
              required: requiredFields.includes('zip'),
              postalCode: true
            }"
              data-test-id="postal-code-input"
          />
        </div>

        <b-message v-if="registeredGuest.loyaltyReward" class="is-compact has-shadow mar-t-md" type="is-primary">
          Please ensure that updates made here are also made in linked loyalty providers or other external systems if applicable.
        </b-message>
      </panel>
    </validated-form>

    <div class="is-grid col-min-500 gap-lg">
      <!-- Marketing Preferences -->
      <validated-form
        ref="marketing-preferences-form"
        form-id="marketing-preferences"
        :disabled="!$can('update', 'Account')"
        @valid-submit="handleMarketingPreferencesSubmit"
      >
        <panel title="Marketing Preferences" class="is-full-height">
          <template v-if="$can('update', 'Account')" #buttons>
            <b-button
              rounded
              class="is-bold"
              native-type="submit"
              type="is-primary"
              :loading="isLoading.marketingForm"
            >
              Save
            </b-button>
          </template>

          <b-field label="Marketing Opt-In" class="align-labels-left mar-b-lg" horizontal>
            <b-switch
              v-model="form.accountNotificationPreference.marketingOptIn"
              :disabled="false"
            />
          </b-field>

          <b-field label="Notification Preferences">
            <div class="checkbox-group">
              <b-checkbox
                v-model="form.accountNotificationPreference.sms"
                :disabled="!form.accountNotificationPreference.marketingOptIn"
              >
                SMS
              </b-checkbox>
              <b-checkbox
                v-model="form.accountNotificationPreference.email"
                :disabled="!form.accountNotificationPreference.marketingOptIn"
              >
                Email
              </b-checkbox>
              <b-checkbox
                v-model="form.accountNotificationPreference.push"
                :disabled="!form.accountNotificationPreference.marketingOptIn"
              >
                Push
              </b-checkbox>
            </div>
          </b-field>
        </panel>
      </validated-form>

      <!-- Account Actions -->
      <panel
        v-if="$can('request_password_reset', 'Account') || $can('destroy', 'Account') || $can('update', 'Account')"
        title="Account Actions"
      >
        <div class="is-flex-column gap-md">
          <div v-if="$can('request_password_reset', 'Account')" class="is-grid col-min-300 gap-y-xs gap-x-lg align-center">
            <div
              v-tippy="{
                content: 'This password can not be reset because a 3rd party login was used to create the account',
                onShow: () => !canResetPassword
              }"
            >
              <b-button
                type="is-primary"
                rounded
                outlined
                class="is-full-width"
                :disabled="!canResetPassword"
                data-test-id="send-reset-password-link-button"
                @click="openResetPasswordConfirmationModal"
              >
                Send Reset Password Link
              </b-button>
            </div>
            <p class="help mar-none">
              Send a password reset link to the guest’s email address.
            </p>
          </div>
          <div v-if="$can('update', 'Account')" class="is-grid col-min-300 gap-y-xs gap-x-lg align-center">
            <div
              v-tippy="{
                content: 'This password can not be reset because a 3rd party login was used to create the account',
                onShow: () => !canResetPassword
              }"
            >
              <b-button
                type="is-primary"
                rounded
                outlined
                class="is-full-width"
                :disabled="!canResetPassword"
                data-test-id="require-password-reset-button"
                @click="openRequirePasswordResetModal"
              >
                Require Password Reset
              </b-button>
            </div>
            <p class="help mar-none">
              Invalidate the current password to prevent login and require the guest to reset it to regain access.
            </p>
          </div>
          <div v-if="$can('destroy', 'Account')" class="is-grid col-min-300 gap-y-xs gap-x-lg align-center">
            <b-button
              type="is-danger"
              rounded
              outlined
              @click="openAccountDeletionConfirmationModal"
            >
              Delete Account
            </b-button>
            <p class="help mar-none">
              Delete the guest’s account and all of its associated data.
            </p>
          </div>
        </div>
      </panel>
    </div>
  </div>
</template>

<script>
  import moment from 'moment-timezone';
  import RegisteredGuest from '@/store/classes/RegisteredGuest';
  import UserValidation from '@/store/classes/UserValidation';
  import alertModal from '@/components/globals/alert-modal.vue';
  import filterObjectKeys from '@/helpers/filter-object-keys';
  import merchantMixin from '@/mixins/merchant';


  export default {
    name: 'RegisteredGuestInfo',

    mixins: [merchantMixin],

    props: {
      previousSearch: {
        type: Object,
        default: () => ({})
      },
      registeredGuest: {
        type: Object,
        required: true
      }
    },

    data: () => ({
      form: {},
      requiredFields: [],
      optionalFields: [],
      isLoading: {
        marketingForm: false,
        guestInfoForm: false,
        validations: false
      }
    }),

    computed: {
      months() {
        return moment.months();
      },

      daysInMonth() {
        return moment().set('month', this.form.birthMonth - 1).daysInMonth();
      },

      primarySmsNumberDisabled() {
        return this.$_selectedMerchant.allowLoginByPhoneNumber && this.signupTypeIncludesPhone;
      },

      signupTypeIncludesPhone() {
        return ['Phone', 'EmailAndPhone'].includes(this.$_selectedMerchant.signupType.name);
      },

      canResetPassword() {
        /*
          If the user has any connected accounts that can be used for authentication, they can't reset their password
          in our app. This is because the password is managed by the third party and we don't have access to it.
        */
        return !this.registeredGuest.accountConnectedAccounts.some(
          connectedAccount => connectedAccount.connectedAccountType.canUseForAuthentication
        );
      }
    },

    created() {
      this.setForm();
      this.setValidations();
    },

    methods: {
      setForm() {
        this.form = this.$clone(this.registeredGuest);

        if (!this.form.accountNotificationPreference) {
          this.form.accountNotificationPreference = {};
        }
      },

      renderedTz() {
        return this.$_selectedMerchant.defaultOfferRedemptionOrDefaultUserTimezoneString;
      },

      merchantTz() {
        return this.$_selectedMerchant.defaultOfferRedemptionOrDefaultUserTimezone;
      },

      convertDateToTz(date) {
        return moment.tz(date, this.merchantTz()).format('MMMM Do, YYYY');
      },

      async setValidations() {
        if (this.$can('read', 'UserValidation')) {
          try {
            this.isLoading.validations = true;

            await UserValidation.fetchUserValidations();

            const merchantUserValidation = UserValidation.find(this.$_selectedMerchant.merchantOption.userValidationsId);
            const parsedFields = merchantUserValidation.validationsJsonBlob
              .reduce((obj, { property, isRequired }) => {
                if (property === 'primarySmsNumber') {
                  if (!isRequired || this.primarySmsNumberDisabled) {
                    obj.optional.push(property);
                  }
                  else {
                    obj.required.push(property);
                  }
                }
                else if (!isRequired) obj.optional.push(property);
                else obj.required.push(property);
                return obj;
              }, { required: [], optional: [] });
            this.requiredFields = parsedFields.required;
            this.optionalFields = parsedFields.optional;
          }
          catch (error) {
            this.$_onRequestError({
              error,
              toastOptions: {
                message: 'There was an error fetching user validations'
              }
            });
          }
          finally {
            this.isLoading.validations = false;
          }
        }
      },

      showGuestInfoForFields(fields) {
        return fields.some(field => this.requiredFields.includes(field) || this.optionalFields.includes(field));
      },

      /* account action confirmations & handlers */
      /* *************************************** */
      openResetPasswordConfirmationModal() {
        this.$buefy.modal.open({
          parent: this,
          component: alertModal,
          hasModalCard: true,
          trapFocus: true,
          canCancel: ['escape', 'outside'],
          customClass: 'auto-width',
          props: {
            title: 'Reset Password',
            message: `Are you sure you want to reset the password for <b>${this.registeredGuest.fullName}</b>? A password reset link will be sent to their email address.`,
            horizontal: true,
            showCloseButton: false,
            icon: 'key',
            type: 'is-primary',
            buttons: [
              { text: 'Cancel' },
              { text: 'Send Password Reset', primary: true, onClick: this.resetAccountPassword }
            ]
          }
        });
      },

      async resetAccountPassword() {
        try {
          await RegisteredGuest.resetAccountPassword(this.registeredGuest.id);

          this.$_onRequestSuccess({
            toastOptions: { message: 'Password reset email was sent!' }
          });
        }

        catch (error) {
          this.$_onRequestError({
            error,
            toastOptions: {
              message: 'There was an error resetting the account password'
            }
          });
        }
      },

      openRequirePasswordResetModal() {
        this.$buefy.modal.open({
          parent: this,
          component: alertModal,
          hasModalCard: true,
          trapFocus: true,
          canCancel: ['escape', 'outside'],
          customClass: 'auto-width',
          props: {
            title: 'Require Password Reset',
            message: `You're about to require a password reset for the account belonging to <b>${this.registeredGuest.fullName}</b>. This <b>CANNOT</b> be undone.`,
            secondaryMessage: 'Are you sure you would like to proceed?',
            horizontal: true,
            showCloseButton: false,
            icon: 'exclamation-triangle',
            type: 'is-primary',
            buttons: [
              { text: 'Cancel' },
              { text: 'Require Password Reset', primary: true, onClick: this.requirePasswordReset }
            ]
          }
        });
      },

      async requirePasswordReset() {
        try {
          await RegisteredGuest.invalidateAccountPassword(this.registeredGuest.id);

          this.$_onRequestSuccess({
            toastOptions: { message: 'Sucessfully invalidated account password!' }
          });
        }

        catch (error) {
          this.$_onRequestError({
            error,
            toastOptions: {
              message: 'There was an error attempting to require password reset'
            }
          });
        }
      },

      openAccountDeletionConfirmationModal() {
        this.$buefy.modal.open({
          parent: this,
          component: alertModal,
          hasModalCard: true,
          trapFocus: true,
          canCancel: ['escape', 'outside'],
          customClass: 'auto-width',
          props: {
            title: 'Delete Account',
            message: `Warning! You are about to delete the account belonging to <b>${this.registeredGuest.fullName}</b>. This <b>CANNOT</b> be undone.`,
            secondaryMessage: 'Are you sure you would like to proceed?',
            horizontal: true,
            showCloseButton: false,
            icon: 'trash-alt',
            type: 'is-danger',
            buttons: [
              { text: 'Cancel' },
              { text: 'Delete Account', primary: true, onClick: this.deleteAccount }
            ]
          }
        });
      },

      async deleteAccount() {
        try {
          await RegisteredGuest.deleteAccount(this.registeredGuest.id);

          this.$_onRequestSuccess({
            toastOptions: { message: 'Account deletion successful' },
            options: {
              redirectTo: {
                name: 'registeredGuestList',
                query: this.previousSearch.param && this.previousSearch.query
                  ? { param: this.previousSearch.param, query: this.previousSearch.query }
                  : null
              }
            }
          });
        }

        catch (error) {
          this.$_onRequestError({
            error,
            toastOptions: {
              message: 'There was an error deleting the account'
            }
          });
        }
      },

      /* form submit handlers */
      /* ******************** */
      async handleGuestInfoSubmit() {
        try {
          this.isLoading.guestInfoForm = true;

          let whitelistedKeys = [
            'birthDay',
            'birthMonth',
            'birthYear',
            'email',
            'firstName',
            'lastName',
            'zip',
            'primarySmsNumber'
          ];

          if (this.primarySmsNumberDisabled) {
            whitelistedKeys = whitelistedKeys.filter(key => key !== 'primarySmsNumber');
          }

          await RegisteredGuest.updateRegisteredGuest({
            id: this.registeredGuest.id,
            ...filterObjectKeys(this.form, whitelistedKeys)
          });

          this.$_onRequestSuccess({
            toastOptions: { message: 'Account info sucessfully updated!' }
          });
        }
        catch (error) {
          if (error.data?.errors) {
            const errors = error.data.errors.reduce((obj, { code }) => {
              if (code === 'account-update-failure-duplicate-email') {
                obj.email = 'is already in use by another account';
              }
              if (code === 'account-update-failure-duplicate-phone') {
                obj.primarySmsNumber = 'is already in use by another account';
              }
              return obj;
            }, {});

            this.$refs['guest-info-form'].handleErrors(errors);
          }

          this.$_onRequestError({
            error,
            toastOptions: {
              message: 'There was an error updating account info'
            }
          });
        }
        finally {
          this.isLoading.guestInfoForm = false;
        }
      },

      async handleMarketingPreferencesSubmit() {
        try {
          this.isLoading.marketingForm = true;

          await RegisteredGuest.updateRegisteredGuest({
            id: this.registeredGuest.id,
            ...filterObjectKeys(this.form, ['accountNotificationPreference'])
          });

          this.$_onRequestSuccess({
            toastOptions: { message: 'Marketing preferences sucessfully updated!' }
          });
        }
        catch (error) {
          this.$_onRequestError({
            error,
            toastOptions: {
              message: 'There was an error updating marketing preferences'
            }
          });
        }
        finally {
          this.isLoading.marketingForm = false;
        }
      }
    }
  };
</script>
