var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"is-grid gap-lg col-min-300"},[_c('div',[_c('validated-text-input',{attrs:{"value":_vm.earnRuleConstraint.minimumPaymentAmountFromSelectedMethods,"data-test-id":"minimum-amount","disabled":_vm.readOnly,"label":"Minimum Amount","name":"minimumPurchase","type":"dollars","rules":{
        required: true,
        min_value: 0.01
      },"placeholder":"Set a minimum purchase amount...","mask-options":{ numeralDecimalScale: 2, numeralPositiveOnly: true }},on:{"input":function($event){_vm.handleInput({ minimumPaymentAmountFromSelectedMethods: Number($event) })}}}),_c('b-checkbox',{directives:[{name:"show",rawName:"v-show",value:(_vm.giftCardPaymentTypeSelected),expression:"giftCardPaymentTypeSelected"}],attrs:{"value":_vm.earnRuleConstraint.waiveMinimumAmountIfZeroingGiftCardBalance,"data-test-id":"waive-for-insufficient-balance","name":"waiveMinimumAmountIfZeroingGiftCardBalance","disabled":_vm.readOnly},on:{"input":function($event){return _vm.handleInput({ waiveMinimumAmountIfZeroingGiftCardBalance: $event })}}},[_vm._v(" Waive for Insufficient Gift Card Balance "),_c('b-icon',{directives:[{name:"tippy",rawName:"v-tippy",value:({
          content: 'Waive the minimum amount required when a gift card’s balance is insufficient',
          maxWidth: 300,
          delay: [150, 0]
        }),expression:"{\n          content: 'Waive the minimum amount required when a gift card’s balance is insufficient',\n          maxWidth: 300,\n          delay: [150, 0]\n        }"}],staticClass:"mar-b-none pad-b-none",attrs:{"icon":"info-square","size":"is-small","type":"is-grey"}})],1)],1),_c('validated-input',{attrs:{"name":"paymentMethods","label":"Payment Methods","data-test-id":"payment-methods","rules":{ required: true },"custom-messages":{
      required: 'Please select at least one payment method'
    }}},[_c('b-dropdown',{attrs:{"value":_vm.earnRuleConstraint.paymentMethods,"mobile-modal":false,"aria-role":"list","multiple":"","inline":"","disabled":_vm.readOnly},on:{"input":function($event){return _vm.handleInput({ paymentMethods: $event })}}},_vm._l((_vm.merchantPaymentMethods),function(paymentMethod){return _c('b-dropdown-item',{key:paymentMethod.value,attrs:{"value":paymentMethod.value,"aria-role":"listitem"}},[_vm._v(" "+_vm._s(paymentMethod.display)+" ")])}),1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }