import { Model } from '@vuex-orm/core';


export default class StoreOrderLimit extends Model {
  static entity = 'storeOrderLimitSchedules'


  // FIELDS //////////////////////
  static fields() {
    return {
      id: this.attr(null),
      storeOrderLimitId: this.attr(null),
      day: this.attr(null),
      startTime: this.attr(null),
      endTime: this.attr(null),
      orderLimit: this.number(1)
    };
  }
}
