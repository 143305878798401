import { Model } from '@vuex-orm/core';
import http from '@/services/http';
import capitalCase from '@/helpers/capitalCase';


export default class MaintenanceCode extends Model {
  static entity = 'maintenanceCodes'



  // FIELDS //////////////////////
  static fields() {
    return {
      id: this.attr(''),
      code: this.attr(''),
      description: this.attr('')
    };
  }

  get displayName() {
    return capitalCase(this.code);
  }


  // STATE //////////////////////
  static state() {
    return {
      fetching: false
    };
  }

  static $state() {
    return this.store().state.entities.maintenanceCodes;
  }


  // ACTIONS //////////////////////
  static async fetchMaintenanceCodes() {
    try {
      this.commit((state) => {
        state.fetching = true;
      });

      const { data } = await http.get('maintenance_codes');

      this.create({
        data: data.maintenanceCodes
      });
    }

    catch (error) {
      throw error;
    }

    finally {
      this.commit((state) => {
        state.fetching = false;
      });
    }
  }
}
