<template>
  <modal-card
    :title="`${ mode === 'create' ? 'Create' : 'Add Categories & Items to'} ${menuType.displayName} Menu`"
    :subtitle="mode === 'create' && 'Select the Categories and Items to add to this menu'"
    show-small-subtitle
    modal-card-body-class="pad-none"
    class="is-full-height"
  >
    <menu-resource-select
      :menu-type-id="menuType.id"
      :selected-item-ids="selectedItemIds"
      @item-selected="itemIds => selectedItemIds = itemIds"
      @select="updateSelectedCounts"
    />

    <template #footer>
      <p v-if="mode === 'create'" class="mar-r-xl">
        <span class="has-text-weight-bold mar-r-xs">Selected:</span>
        {{ selectedCategoryCount }} Categories
        <span class="mar-x-sm has-text-grey-light has-text-weight-bold">|</span>
        {{ selectedItemCount }} Items
      </p>

      <div class="buttons all-bold">
        <b-button
          rounded
          @click="$parent.close()"
        >
          Cancel
        </b-button>
        <b-button
          rounded
          type="is-primary"
          :loading="addingToMenuType"
          :disabled="!selectedItemIds.length"
          @click="addToMenuType"
        >
          {{ mode === 'create' ? 'Create Menu' : `Add to ${capitalCase(menuType.name)} Menu` }}
        </b-button>
      </div>
    </template>
  </modal-card>
</template>

<script>
  import merchantMixin from '@/mixins/merchant';
  import Item from '@/store/classes/Item';
  import { paramCase } from 'change-case';
  import capitalCase from '@/helpers/capitalCase';


  export default {
    name: 'AddToMenuTypeModal',

    mixins: [merchantMixin],

    props: {
      menuType: {
        type: Object,
        required: true,
        validator(menuType) {
          const keys = Object.keys(menuType);
          return ['id', 'name'].every(key => keys.includes(key));
        }
      },

      mode: {
        type: String,
        default: 'create',
        validator(value) {
          return ['create', 'update'].includes(value);
        }
      }
    },

    data() {
      return {
        selectedCategoryCount: '',
        selectedItemCount: '',
        addingToMenuType: false,
        selectedItemIds: [],
        capitalCase
      };
    },

    methods: {
      updateSelectedCounts(counts) {
        this.selectedCategoryCount = counts.categories;
        this.selectedItemCount = counts.items;
      },

      async addToMenuType() {
        try {
          this.addingToMenuType = true;
          await Item.bulkAssignMenuType({
            menuType: this.menuType,
            menuItemIds: this.selectedItemIds
          });

          let message;

          if (this.mode === 'create') {
            message = `Successfully created your new ${this.menuType.name} Menu`;
          }
          else {
            message = `Successfully added ${this.selectedItemIds.length} item${this.selectedItemIds.length > 1 ? 's' : ''} to your ${this.menuType.name} Menu`;
          }

          this.$_onRequestSuccess({
            toastOptions: { message },
            options: { closeParent: true }
          });

          if (this.mode === 'create') {
            this.$router.replace({ params: { tabName: paramCase(this.menuType.name) } });
          }
        }
        catch (error) {
          this.$_onRequestError({
            error,
            toastOptions: {
              message: `There was an error adding items to your ${this.menuType.name} Menu`
            }
          });
        }
        finally {
          this.addingToMenuType = false;
        }
      }
    }
  };
</script>
