var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"merchant-list-page"},[_c('hero-banner',{scopedSlots:_vm._u([(_vm.$can('create', 'Merchant'))?{key:"right",fn:function(){return [_c('b-button',{attrs:{"rounded":"","inverted":"","size":"is-medium","type":"is-primary","icon-left":"plus"},on:{"click":_vm.openMerchantModal}},[_vm._v(" New Merchant ")])]},proxy:true}:null],null,true)},[_c('h1',{staticClass:"title is-2"},[_vm._v("Merchants")])]),_c('div',{staticClass:"section"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"card"},[_c('searchable-table',{attrs:{"auto-focus-search":"","table-data":_vm.allMerchants,"search":{
            placeholder: 'Search by Merchant Name or ID',
            keys: ['name', 'id']
          }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var filteredData = ref.filteredData;
return [_c('b-table',{staticClass:"is-middle-aligned",attrs:{"data":_vm.isLoadingMerchants ? [] : filteredData,"paginated":_vm.allMerchants.length > 20,"per-page":"20","pagination-simple":"","hoverable":"","row-class":function (ref) {
                    var id = ref.id;

                    return _vm.merchants.length && _vm.recentMerchantIds.includes(id) && 'recent-merchant';
          }}},[_c('b-table-column',{attrs:{"sortable":"","field":"name","label":"Name"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var ref_row = ref.row;
          var id = ref_row.id;
          var name = ref_row.name;
return [_c('span',{staticClass:"link merchant-name",on:{"click":function($event){return _vm.selectMerchant(id)}}},[_c('span',[_vm._v(_vm._s(name))]),(_vm.merchants.length && _vm.recentMerchantIds.includes(id))?_c('b-icon',{staticClass:"mar-l-xs star",attrs:{"icon":"star","size":"is-small"}}):_vm._e(),_c('b-icon',{staticClass:"arrow mar-l-xs",attrs:{"icon":"arrow-right"}})],1)]}}],null,true)}),_c('b-table-column',{attrs:{"centered":"","label":"Active","width":"200","sortable":"","field":"isActive"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var ref_row = ref.row;
          var isActive = ref_row.isActive;
          var id = ref_row.id;
return [(_vm.isUpdatingActivationStatus(id))?_c('b-icon',{staticClass:"spin",attrs:{"icon":"spinner-third"}}):_c('b-icon',{attrs:{"icon":isActive ? 'check' : 'times',"type":isActive ? 'is-success' : 'is-danger',"size":"is-small"}})]}}],null,true)}),_c('b-table-column',{attrs:{"centered":"","cell-class":"actions","label":"Actions","width":"1"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var row = ref.row;
return [_c('b-dropdown',{attrs:{"aria-role":"list","position":"is-bottom-left","mobile-modal":false}},[_c('b-button',{staticClass:"is-transparent",attrs:{"slot":"trigger","type":"is-white"},slot:"trigger"},[_c('b-icon',{attrs:{"icon":"ellipsis-v","pack":"far","size":"is-small"}})],1),_c('b-dropdown-item',{attrs:{"has-link":""}},[_c('router-link',{attrs:{"to":{ name: 'merchantDetail', params: { merchantId: row.id } }}},[_c('b-icon',{attrs:{"icon":"wrench"}}),_vm._v(" Configure ")],1)],1),(_vm.$can('manage', 'all'))?[_c('hr',{staticClass:"dropdown-divider"}),_c('b-dropdown-item',{class:row.isActive ? 'is-danger' : 'is-success',on:{"click":function($event){return _vm.$_confirmUpdateMerchantActivation(row)}}},[_c('b-icon',{attrs:{"icon":row.isActive ? 'ban' :'bolt'}}),_vm._v(" "+_vm._s(row.isActive ? 'Deactivate' : 'Activate')+" ")],1)]:_vm._e()],2)]}}],null,true)}),_c('template',{slot:"empty"},[_c('empty-table-loader',{attrs:{"loading":_vm.isLoadingMerchants}})],1)],2)]}}])})],1)])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }