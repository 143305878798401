<template>
  <validated-form
    ref="form"
    form-id="receiptInstructions"
    @valid-submit="handleSubmit"
  >
    <panel
      title="Receipt Instructions"
      subtitle="Personalize the message a guest will see on their receipt"
      collapsible
      :loading="isFetching"
    >
      <template #buttons>
        <b-button
          rounded
          class="is-bold"
          size="is-medium"
          type="is-primary is-light"
          :loading="isResettingDefault"
          :disabled="hasDefaultValues"
          @click="resetToDefault"
        >
          Reset to Defaults
        </b-button>
        <b-button
          rounded
          class="is-bold"
          size="is-medium"
          native-type="submit"
          type="is-primary"
          :loading="isLoading"
        >
          Save
        </b-button>
      </template>
      <div class="is-grid col-2 col-1-desktop gap-x-lg gap-y-sm">
        <validated-text-input
          v-model="form.qrInstructions"
          label="QR Code"
          sub-label="For P@T orders placed using a QR code"
          type="textarea"
          name="qrInstruction"
          rules="required"
          maxlength="255"
          rows="3"
        />

        <validated-text-input
          v-model="form.payInstructions"
          label="Text To Pay"
          sub-label="For orders paid using Text to Pay"
          type="textarea"
          name="payInstruction"
          rules="required"
          maxlength="255"
          rows="3"
        />
      </div>
    </panel>
  </validated-form>
</template>

<script>
  import merchantMixin from '@/mixins/merchant';

  // classes
  import MerchantReceiptInstruction from '@/store/classes/MerchantReceiptInstruction';

  export default {
    name: 'ContentReceiptInstructionForm',

    mixins: [merchantMixin],

    data() {
      return {
        form: {
          qrInstructions: '',
          payInstructions: ''
        },
        isLoading: false,
        isResettingDefault: false,
        qrChannelTypeIds: [0, 1, 2, 3, 4, 5, 6, 9],
        payChannelTypeIds: [7, 8]
      };
    },

    computed: {
      selectedReceiptInstructions() {
        return MerchantReceiptInstruction.query().where('merchantId', this.$_selectedMerchantId).get();
      },

      defaultReceiptInstructions() {
        return MerchantReceiptInstruction.query().where('merchantId', 0).get();
      },

      receiptInstructions() {
        return this.selectedReceiptInstructions.length ? this.selectedReceiptInstructions : this.defaultReceiptInstructions;
      },

      hasDefaultValues() {
        return this.selectedReceiptInstructions.every((selectedInstruction) => {
          const matchedDefault = this.defaultReceiptInstructions.find(defaultInstruction => selectedInstruction.channelType.id === defaultInstruction.channelType.id);

          return selectedInstruction.instructions === matchedDefault.instructions;
        });
      },

      isFetching() {
        return MerchantReceiptInstruction.$state().fetching;
      }
    },

    created() {
      this.onCreated();
    },

    methods: {
      async onCreated() {
        await this.fetchMerchantReceiptInstructions();
        this.setFormData();
      },

      setFormData() {
        this.form.qrInstructions = this.receiptInstructions.find(instruction => instruction.channelType.id === this.qrChannelTypeIds[0]).instructions;
        this.form.payInstructions = this.receiptInstructions.find(instruction => instruction.channelType.id === this.payChannelTypeIds[0]).instructions;
      },

      async handleSubmit() {
        this.isLoading = true;

        const payload = this.receiptInstructions.map(instruction => ({
          id: instruction.id,
          channelTypeId: instruction.channelType.id,
          instructions: this.qrChannelTypeIds.includes(instruction.channelType.id) ? this.form.qrInstructions : this.form.payInstructions
        }));

        try {
          if (this.selectedReceiptInstructions.length) {
            await MerchantReceiptInstruction.updateMerchantReceiptInstructions({
              merchantId: this.$_selectedMerchantId,
              merchantReceiptInstructions: payload
            });
          }

          else {
            await MerchantReceiptInstruction.createMerchantReceiptInstructions({
              merchantId: this.$_selectedMerchantId,
              merchantReceiptInstructions: payload
            });
          }

          this.$_onRequestSuccess({
            toastOptions: {
              message: 'Receipt instructions have been successfully updated!'
            }
          });
        }

        catch (error) {
          this.$_onRequestError({
            error,
            toastOptions: {
              message: 'There was an error updating your receipt instructions'
            }
          });
        }

        this.isLoading = false;
      },

      async resetToDefault() {
        this.isResettingDefault = true;

        try {
          const payload = this.receiptInstructions.map(instruction => ({
            id: instruction.id,
            channelTypeId: instruction.channelType.id,
            instructions: this.defaultReceiptInstructions.find(defaultInstruction => defaultInstruction.channelType.id === instruction.channelType.id).instructions
          }));

          await MerchantReceiptInstruction.updateMerchantReceiptInstructions({
            merchantId: this.$_selectedMerchantId,
            merchantReceiptInstructions: payload
          });

          this.setFormData();

          this.$_onRequestSuccess({
            toastOptions: {
              message: 'Receipt instructions have been successfully reset!'
            }
          });
        }

        catch (error) {
          this.$_onRequestError({
            error,
            toastOptions: {
              message: 'There was an error resetting your receipt instructions'
            }
          });
        }

        this.isResettingDefault = false;
      },

      async fetchMerchantReceiptInstructions() {
        try {
          await MerchantReceiptInstruction.fetchMerchantReceiptInstructions(this.$_selectedMerchantId);
        }

        catch (error) {
          this.$_onRequestError({
            error,
            toastOptions: {
              message: 'There was an error fetching your receipt instructions'
            }
          });
        }
      }
    }
  };
</script>

