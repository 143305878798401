<template>
  <validated-form
    ref="form"
    form-id="claimOrderModal"
    auto-focus
    @valid-submit="confirmClaimOrder"
  >
    <modal-card
      title="Claim Order"
      modal-card-body-class="pad-b-none"
    >
      <b-field
        class="search-inputs mar-b"
        label-position="on-border"
        label="Criteria"
      >
        <b-select
          v-model="searchParam"
          placeholder="Select Search Criteria"
        >
          <option value="receiptToken">Printed Receipt Barcode</option>
          <option value="ticketNumber">Order Number</option>
          <option value="fullName">Name</option>
          <option value="email">Email Address</option>
          <option value="phone">Phone Number</option>
        </b-select>
        <b-input
          v-model="searchQuery"
          :icon-right-clickable="!!searchQuery"
          :icon-right="searchQuery ? 'times-circle': ''"
          placeholder="Search orders..."
          :type="queryInputType"
          class="query-input"
          icon="search"
          expanded
          @icon-right-click="searchQuery = ''"
          @keydown.native.esc="searchQuery = ''"
          @keydown.native.enter.prevent="handleSearchButtonClick"
        />
        <div class="control">
          <b-button
            v-tabbable
            :disabled="!searchQuery"
            class="has-text-weight-bold flex-small"
            :loading="isLoadingOrders"
            data-test-id="search-button"
            type="is-primary"
            @click="handleSearchButtonClick"
          >
            Search
          </b-button>
        </div>

        <div :class="['is-flex align-center mar-l', { 'is-invisible': isLoadingOrders || !ordersTotalCount }]">
          <p v-if="ordersTotalCount < 100">
            <span class="has-text-weight-bold has-text-black">
              {{ ordersTotalCount }}
            </span>
            Matching Order{{ ordersTotalCount > 1 ? 's' : '' }}
          </p>
          <div v-else>
            <p>
              Displaying
              <span class="has-text-weight-bold has-text-black">
                {{ ordersTotalCount }}
              </span>
              best results
            </p>
            <p class="is-size-7 has-text-weight-bold has-text-grey">
              If you don’t see the order that you're looking for, please enter a more specific search
            </p>
          </div>
        </div>
      </b-field>

      <b-table
        :data="isLoadingOrders ? [] : allOrders"
        class="is-middle-aligned no-header-wrap row-clickable"
        hoverable
        scrollable
        :total="ordersTotalCount"
        :current-page="page"
        :per-page="5"
        pagination-position="bottom"
        :paginated="ordersTotalCount > 5"
        :mobile-cards="false"
        :selected.sync="selectedOrder"
        focusable
        :is-row-selectable="canOrderBeClaimed"
        :row-class="getRowClass"
        @page-change="page = $event"
      >
        <template slot="empty">
          <empty-table-loader
            :loading="isLoadingOrders"
            :has-icon="false"
            message="No Matching Orders Found"
          />
        </template>

        <b-table-column
          width="1"
          label="Order #"
          sortable
          field="orderId"
        >
          <template v-if="$can('manage', 'all')" #header>
            <span @click="showOrderId = !showOrderId">
              Order {{ showOrderId ? 'ID' : '#' }}
            </span>
          </template>
          <template v-slot:default="{ row: order }">
            <span v-if="showOrderId">{{ order.orderId }}</span>
            <template v-else>
              <span v-if="order.ticketNumber">{{ order.ticketNumber }}</span>
              <span v-else>N/A</span>
            </template>
          </template>
        </b-table-column>

        <b-table-column
          v-slot="{ row: order }"
          field="storeName"
          label="Location"
          sortable
        >
          <div class="store-name">
            {{ order.storeName }}
          </div>
        </b-table-column>

        <b-table-column
          v-slot="{ row: order }"
          label="Guest Name"
          field="guestName"
          sortable
        >
          <span v-if="order.guestName && order.guestName.trim()">
            {{ order.guestName }}
          </span>
          <span v-else class="has-text-grey-light">N/A</span>
        </b-table-column>

        <b-table-column
          v-slot="{ row: order }"
          label="Guest Info"
          field="info"
        >
          <div class="no-wrap-text">
            <b-icon size="is-small" pack="far" class="has-text-grey mar-r-sm" icon="envelope" />
            <span v-if="order.email">{{ order.email }}</span>
            <span v-else class="has-text-grey-light">N/A</span>
          </div>
          <div>
            <b-icon size="is-small" pack="far" class="has-text-grey mar-r-sm" icon="phone" />
            <span v-if="order.phoneNumber">{{ order.phoneNumber | phoneNumber }}</span>
            <span v-else class="has-text-grey-light">N/A</span>
          </div>
        </b-table-column>

        <b-table-column
          v-slot="{ row: order }"
          numeric
          sortable
          field="total"
          label="Total"
        >
          <template v-if="hasTipAdjustedTransaction(order)">
            <span
              v-tippy="{
                content: 'This order\'s tip was adjusted',
                placement: 'left',
                delay: [150, 0]
              }"
              class="is-flex justify-end"
            >
              <b-icon icon="hand-holding-usd" type="is-primary" class="align-baseline mar-r-sm" />
              <span class="has-text-weight-bold has-text-primary">
                {{ order.total | dollars }}
              </span>
            </span>
          </template>
          <span v-else-if="order.total" class="'has-text-weight-bold">
            {{ order.total | dollars }}
          </span>
          <span v-else class="has-text-grey-light">N/A</span>
        </b-table-column>

        <b-table-column
          v-slot="{ row: order }"
          sortable
          field="orderDate"
          label="Order Date"
        >
          <div class="no-wrap-text">{{ order.orderDate | moment('M/D/YYYY', order.storeTimezone) }}</div>
          <div class="no-wrap-text">{{ order.orderDate | moment('h:mm A z', order.storeTimezone) }}</div>
        </b-table-column>

        <template #bottom-left>
          <div class="left-side">
            <page-number-select
              v-if="ordersTotalCount > 5"
              :page="page"
              :per-page="5"
              :total-count="ordersTotalCount"
              @input="page = $event"
            />
          </div>
        </template>
      </b-table>

      <template v-if="ordersTotalCount > 0">
        <hr class="mar-y">
        <b-message
          v-if="ordersTotalCount > 0"
          size="is-small"
          type="is-warning"
          class="is-compact has-shadow mar-b"
        >
          Orders that are greyed out are already claimed
        </b-message>
      </template>

      <template #footer>
        <div class="buttons all-bold">
          <b-button
            rounded
            @click="$_confirmCloseModal({ programmatic: true })"
          >
            Cancel
          </b-button>
          <b-button
            v-tabbable
            rounded
            native-type="submit"
            type="is-primary"
            :loading="isSubmitting"
            :disabled="isSubmitting || isLoadingOrders || !selectedOrder"
          >
            Claim Order
          </b-button>
        </div>
      </template>
    </modal-card>
  </validated-form>
</template>

<script>
  import merchantMixin from '@/mixins/merchant';
  import confirmModalCloseMixin from '@/mixins/confirm-modal-close';
  import RawExternalOrder from '@/store/classes/RawExternalOrder';
  import RegisteredGuest from '@/store/classes/RegisteredGuest';
  import Order from '@/store/classes/Order';
  import Store from '@/store/classes/Store';


  export default {
    name: 'ClaimOrderModal',

    mixins: [merchantMixin, confirmModalCloseMixin],

    props: {
      account: {
        type: Object,
        required: true
      }
    },

    data() {
      return {
        selectedOrder: null,
        searchInput: null,
        searchQuery: '',
        searchParam: 'receiptToken',
        page: 1,
        showOrderId: false
      };
    },

    computed: {
      rawExternalOrders() {
        return RawExternalOrder.all();
      },

      orders() {
        return Order.query().orderBy('sortOrder').get();
      },

      allOrders() {
        const combinedOrders = [...this.rawExternalOrders, ...this.orders];
        if (combinedOrders.length) {
          return combinedOrders.map(o => this.normalizeOrder(this.$clone(o)));
        }
        return [];
      },

      ordersTotalCount() {
        return this.orders.length + this.rawExternalOrders.length;
      },

      isSubmitting() {
        return RegisteredGuest.$state().submitting;
      },

      isLoadingOrders() {
        return RawExternalOrder.$state().fetching || Order.$state().fetching;
      },

      claimOrderType() {
        if (!this.selectedOrder) {
          return null;
        }
        return this.selectedOrder.payments ? 'cf' : 'pos';
      },

      receiptToken() {
        if (!this.selectedOrder) {
          return null;
        }
        const { payments, receiptToken } = this.selectedOrder;
        return payments ? payments.find(p => p.receiptToken).receiptToken : receiptToken;
      },

      stores() {
        return Store.all();
      },

      queryInputType() {
        switch (this.searchParam) {
          case 'fullName':
          case 'receiptToken':
          case 'ticketNumber':
            return 'text';

          case 'email':
            return 'email';

          case 'phone':
            return 'phone';

          default:
            return 'text';
        }
      }
    },

    created() {
      this.onCreated();
    },

    methods: {
      async onCreated() {
        await this.fetchStores();
        await this.clearOrderStore();
      },

      getStore(storeId) {
        return this.stores.find(s => s.storeId === storeId);
      },

      normalizeOrder(order) {
        const store = order.store || this.getStore(order.storeId);
        const receiptToken = order.payments ? order.payments.find(p => p.receiptToken)?.receiptToken : order.receiptToken;
        return {
          orderId: order.orderId,
          ticketNumber: order.ticketNumber,
          storeName: store.description,
          storeTimezone: store.timeZone || store.ianaTimezoneId,
          guestName: order.guestFullName,
          email: order.email || order.orderDetailsJsonBody?.email,
          phoneNumber: order.phoneNumber || order.orderDetailsJsonBody?.phoneNumber,
          total: order.orderTotalWithExternalPayment || order.totalWithTipAndCharges || order.orderDetailsJsonBody?.total,
          orderDate: order.orderDate || order.createdAt,
          payments: order.payments,
          isGuest: order.isGuest,
          receiptToken
        };
      },

      async handleSearchButtonClick() {
        try {
          this.page = 1;
          this.selectedOrder = null;
          this.clearOrderStore();

          if (this.searchQuery) {
            const query = this.searchQuery && this.searchParam === 'phone'
              ? this.searchQuery
                .split('')
                .filter(char => /[0-9]/.test(char))
                .join('')
              : this.searchQuery;

            const searchExternalOrders = ['ticketNumber', 'receiptToken'].includes(this.searchParam);
            const searchOrders = this.searchParam !== 'receiptToken';
            await Promise.all([searchOrders && this.searchOrders({ query }), searchExternalOrders && this.searchRawExternalOrders()]);
          }
        }

        catch (error) {
          this.$_onRequestError({
            error,
            toastOptions: { message: 'There was an error loading your orders' }
          });
        }
      },

      clearSearch() {
        this.searchQuery = '';
        this.clearOrderStore();
        this.selectedOrder = null;
      },

      clearOrderStore() {
        RawExternalOrder.deleteAll();
        Order.deleteAll();
      },

      handleClick(order) {
        this.selectedOrder = order;
      },

      hasTipAdjustedTransaction(order) {
        return order.payments?.some(p => p.transactions
            .some(t => t.paymentTransaction.paymentTransactionType === 'TipAdjustment' && t.paymentTransaction.paymentTransactionStatus === 'Succeeded'));
      },

      canOrderBeClaimed(order) {
        const isCFOrder = !!order.payments;
        return isCFOrder ? !!order.isGuest : !order.orderId;
      },

      getRowClass(row) {
        return !this.canOrderBeClaimed(row) ? 'disabled-row' : '';
      },

      async searchOrders({
        param = this.searchParam,
        query = this.searchQuery
      } = {}) {
        try {
          await Order.searchOrders({ param, query, perPage: 100, sortBy: 'OrderDate-Desc' });
        }

        catch (error) {
          throw error;
        }
      },

      async searchRawExternalOrders() {
        try {
          await RawExternalOrder.searchRawExternalOrders({ [this.searchParam]: this.searchQuery });
        }
        catch (error) {
          throw error;
        }
      },

      async fetchStores() {
        try {
          await Store.fetchAll(this.$_selectedMerchantId);
        }
        catch (error) {
          this.$_onRequestError({ toastOptions: { message: 'Unable to fetch stores' }, error });
        }
      },

      confirmClaimOrder() {
        this.$buefy.dialog.confirm({
          title: 'Claim Order',
          message: `Are you sure you want to associate <strong>Order #${this.selectedOrder.ticketNumber}</strong> to <strong>${this.account.fullName}</strong>? This cannot be undone.`,
          type: 'is-warning',
          hasIcon: true,
          icon: 'exclamation-triangle',
          confirmText: 'Yes, Claim',
          cancelText: 'No',
          onConfirm: this.handleSubmit
        });
      },

      async handleSubmit() {
        try {
          await RegisteredGuest.claimOrder({
            type: this.claimOrderType,
            receiptToken: this.receiptToken,
            accountId: this.account.id
          });
          this.$_onRequestSuccess({
            toastOptions: {
              message: 'Refetch the account order summaries to show the newly claimed order there'
            },
            options: { closeParent: true }
          });
        }
        catch (error) {
          this.$_onRequestError({
            error,
            toastOptions: {
              message: 'There was an error claiming the order'
            }
          });
        }
      }
    }
  };
</script>

<style lang="sass" scoped>
  .control
    ::v-deep
      .button
        border-radius: 0 4px 4px 0 !important

  ::v-deep

    .left-side
      display: grid
      grid-template-columns: 1fr 1fr
      grid-gap: 1rem

    .disabled-row
      background-color: $grey-lightest
      cursor: not-allowed !important

      &:hover
        background-color: $grey-lightest !important
</style>
