/* eslint-disable import/no-cycle */
import { Model } from '@vuex-orm/core';
import http from '@/services/http';
import Department from '@/store/classes/Department';


export default class Employee extends Model {
  static entity = 'employees'

  static fields() {
    return {
      id: this.attr(''),
      emailAddress: this.attr(''),
      fullName: this.attr(''),
      firstName: this.attr(''),
      lastName: this.attr(''),
      phoneNumber: this.attr(''),
      internalId: this.attr(''),
      publicId: this.attr(''),
      photoUrl: this.attr(null),
      departmentId: this.attr(null),
      createdAt: this.attr(''),
      updatedAt: this.attr(''),

      department: this.belongsTo(Department, 'departmentId')
    };
  }

  // STATE //////////////////////
  static state() {
    return {
      fetching: false,
      submitting: false

    };
  }

  static $state() {
    return this.store().state.entities.employees;
  }

  // ACTIONS //////////////////////
  static async fetchEmployees(storeId) {
    try {
      this.commit((state) => {
        state.fetching = true;
      });

      const merchantId = this.store().state.entities.merchants.selectedMerchantId;

      const response = storeId
        ? await http.get(`stores/${storeId}/employees`)
        : await http.get(`merchants/${merchantId}/employees`);

      this.create({ data: response.data.employees });
    }
    catch (error) {
      throw error;
    }
    finally {
      this.commit((state) => {
        state.fetching = false;
      });
    }
  }

  static async createEmployee(employee) {
    try {
      this.commit((state) => {
        state.submitting = true;
      });

      const merchantId = this.store().state.entities.merchants.selectedMerchantId;

      const response = await http.post(`merchants/${merchantId}/employees`, { employee });

      this.insert({
        data: response.data.employee
      });

      return response.data.employee;
    }

    catch (error) {
      throw error;
    }

    finally {
      this.commit((state) => {
        state.submitting = false;
      });
    }
  }

  static async updateEmployee(employee) {
    try {
      this.commit((state) => {
        state.submitting = true;
      });

      const response = await http.put(`employees/${employee.id}`, { employee });

      this.insert({
        data: response.data.employee
      });

      return response.data.employee;
    }

    catch (error) {
      throw error;
    }

    finally {
      this.commit((state) => {
        state.submitting = false;
      });
    }
  }

  static async deleteEmployee(employeeId) {
    try {
      this.commit((state) => {
        state.submitting = true;
      });
      await http.delete(`employees/${employeeId}`);

      this.delete(employeeId);
    }
    catch (error) {
      throw error;
    }
    finally {
      this.commit((state) => {
        state.submitting = false;
      });
    }
  }

  static async uploadImage(employeeImage) {
    try {
      this.commit((state) => {
        state.submitting = true;
      });

      const merchantId = this.store().state.entities.merchants.selectedMerchantId;

      const formData = new FormData();
      formData.append('employeeImage[file]', employeeImage);

      const response = await http.post(
        `merchants/${merchantId}/employee_images`,
        formData,
        { headers: { 'Content-Type': 'multipart/form-data' } }
      );

      return response.data.employeeImageUrl;
    }

    catch (error) {
      throw error;
    }

    finally {
      this.commit((state) => {
        state.submitting = false;
      });
    }
  }
}
