var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ul',{staticClass:"nested-table-section"},[(!_vm.modifierGroups.length && (!_vm.menuPermissions.ADD_RESOURCE || _vm.activeMenuType))?_c('li',[_vm._m(0)]):_vm._e(),_c('draggable',_vm._b({attrs:{"tag":"ul","group":("item-" + (_vm.menuItem.id) + "-modifier-groups"),"value":_vm.modifierGroups,"force-fallback":true},on:{"change":_vm.handleModifierGroupSort},scopedSlots:_vm._u([(_vm.modifierGroups.length)?{key:"header",fn:function(){return [_c('li',{staticClass:"nested-table-section-title"},[_vm._v(_vm._s(_vm.featurePermissions.NESTED_MODIFIERS ? 'Groups' : 'Modifier Groups'))])]},proxy:true}:null],null,true)},'draggable',_vm.draggableAttributes,false),_vm._l((_vm.modifierGroups),function(modifierGroup){return _c('nested-modifier-group-row',{key:("modifier-group-" + (modifierGroup[_vm.modifierGroupIdKey]) + "-item-" + (_vm.menuItem.id)),attrs:{"modifier-group":_vm.featurePermissions.SHARED_MODIFIER_GROUPS ? modifierGroup.modifierGroup : modifierGroup,"menu-item-modifier-group-item":_vm.featurePermissions.SHARED_MODIFIER_GROUPS ? modifierGroup : null,"menu-item":_vm.menuItem,"parent-modifier":_vm.parentModifier}})}),1),(
      !_vm.activeMenuType
        && _vm.$can('create', 'MenuItemModifierGroup')
        && _vm.menuPermissions.ADD_RESOURCE
        && (_vm.featurePermissions.SHARED_MODIFIER_GROUPS || _vm.featurePermissions.NESTED_MODIFIERS)
    )?_c('li',{staticClass:"row sub-row"},[_c('span',[_c('dropdown-menu',{attrs:{"position":"bottom-start"},scopedSlots:_vm._u([{key:"trigger",fn:function(){return [_c('div',{directives:[{name:"tippy",rawName:"v-tippy",value:({
              content: 'A default group cannot have nested modifiers',
              placement: 'right',
              onShow: function () { return _vm.isModifierDefault; }
            }),expression:"{\n              content: 'A default group cannot have nested modifiers',\n              placement: 'right',\n              onShow: () => isModifierDefault\n            }"}],staticClass:"is-flex align-center"},[_c('b-button',{staticClass:"is-transparent",attrs:{"type":"is-primary","inverted":"","icon-left":"plus"}},[_vm._v(" Group ")])],1)]},proxy:true}],null,false,1587476347)},_vm._l((_vm.modifierGroupTypeOptions),function(ref){
            var display = ref.display;
            var value = ref.value;
return _c('b-dropdown-item',{key:value,staticClass:"is-flex justify-between pad-r-sm",attrs:{"aria-role":"listitem","disabled":_vm.modifierGroupTypeOptionDisabled(value)},on:{"click":function($event){_vm.handleOpenModifierGroupModal({ type: Object.values(_vm.modifierGroupTypes).includes(value) ? value : '' })}}},[_vm._v(" "+_vm._s(display)+" "),_c('b-icon',{attrs:{"icon":"arrow-right"}})],1)}),1)],1),_c('span'),_c('span')]):_vm._e()],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"row sub-row"},[_c('span',{staticClass:"has-text-grey pad-y-md"},[_vm._v("No Modifier Groups")]),_c('span'),_c('span')])}]

export { render, staticRenderFns }