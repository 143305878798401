<template>
  <div class="card">
    <div class="card-content is-flex-wrap gap justify-center">
      <div class="flex-grow">
        <validated-text-input
          :value="value.name"
          data-test-id="criteria-name-input"
          name="name"
          type="text"
          rules="required|max:255"
          label="Segmentation Name"
          placeholder="Enter an internal name..."
          @input="handleInput({ name: $event })"
        />

        <hr>

        <div>
          <validation-provider v-slot="{ errors }" name="minCriteria" :rules="{ required: value.isCustomCriteria }">
            <b-checkbox :value="hasAnyCriteria || null" class="is-hidden" />
            <b-field
              label="Segmentation Criteria"
              :message="errors.length && value.isCustomCriteria ? 'You must select at least one criteria to define your target audience' : ''"
              :type="errors && errors.length && value.isCustomCriteria ? 'is-danger' : ''"
            >
              <radio-button
                v-model="value.isCustomCriteria"
                name="allRegisteredGuests"
                type="is-dark"
                :native-value="false"
                class="flex-grow"
                data-test-id="all-registered-guests-radio"
                icon="users"
                icon-pack="far"
                left-label
                justify-between
              >
                All Registered Guests
              </radio-button>
              <radio-button
                v-model="value.isCustomCriteria"
                name="customCriteria"
                type="is-dark"
                :native-value="true"
                class="flex-grow"
                data-test-id="custom-criteria-radio"
                icon="sliders"
                icon-pack="fas"
                left-label
                justify-between
              >
                Custom
              </radio-button>
            </b-field>
          </validation-provider>

          <transition name="fade-down">
            <div v-if="value.isCustomCriteria" class="is-flex-column gap-y-lg mar-t-lg">
              <criteria-form-wrapper
                :criteria-type="criteriaTypes.ORDERING"
                :disable-add-button="!value.criteria.orderTypeIds || !value.criteria.orderTypeIds.length"
                :show-add-button="!!filterAvailableCriteriaForType(criteriaTypes.ORDERING).length"
                v-on="wrapperHandlers"
              >
                <ordering-criteria-form
                  :value="value.criteria"
                  data-test-id="ordering-criteria-form"
                  v-on="formHandlers"
                />
              </criteria-form-wrapper>
              <criteria-form-wrapper
                :criteria-type="criteriaTypes.ACCOUNT"
                :show-add-button="!!filterAvailableCriteriaForType(criteriaTypes.ACCOUNT).length"
                v-on="wrapperHandlers"
              >
                <account-criteria-form
                  v-model="value.criteria"
                  data-test-id="account-criteria-form"
                  v-on="formHandlers"
                />
              </criteria-form-wrapper>
              <criteria-form-wrapper
                :criteria-type="criteriaTypes.LOYALTY"
                :show-add-button="!!filterAvailableCriteriaForType(criteriaTypes.LOYALTY).length"
                v-on="wrapperHandlers"
              >
                <loyalty-criteria-form
                  v-model="value.criteria"
                  data-test-id="loyalty-criteria-form"
                  v-on="formHandlers"
                />
              </criteria-form-wrapper>
            </div>
          </transition>
        </div>
      </div>

      <div class="is-flex-column align-center align-self-center justify-self-center gap-y">
        <vue-ellipse-progress
          :progress="progress"
          legend
          :legend-value="signature ? signature.totalAccountCountForCriteria : 0"
          :loading="isFetchingSignature"
          color="#0099ff"
          thickness="6.5%"
        >
          <template v-slot:default="{ counterTick }">
            <div class="is-flex-column">
              <span class="has-text-weight-bold is-size-5">
                {{ counterTick.currentValue }}/{{ signature.totalAccountCount || 0 }}
              </span>
              <span>
                Target Audience
              </span>
            </div>
          </template>
        </vue-ellipse-progress>
        <p class="text-align-center has-text-grey" style="width: 190px">Dates for criteria such as "Registered Date Range" are calculated in {{ merchantTimezoneText }}.</p>
      </div>
    </div>
  </div>
</template>

<script>
  import debounce from 'lodash.debounce';
  import merchantMixin from '@/mixins/merchant';
  import SegmentationSignature from '@/store/classes/SegmentationSignature';
  import { criteria, criteriaTypes, criteriaCardMetadata } from '@/constants/segmentations';

  import AddCriteriaModal from './criteria-forms/add-criteria-modal.vue';
  import LoyaltyCriteriaForm from './criteria-forms/loyalty-criteria-form.vue';
  import OrderingCriteriaForm from './criteria-forms/ordering-criteria-form.vue';
  import AccountCriteriaForm from './criteria-forms/account-criteria-form.vue';
  import CriteriaFormWrapper from './criteria-forms/criteria-form-wrapper.vue';
  import LoyaltyProgramTier from '@/store/classes/LoyaltyProgramTier';
  import MerchantAppSetting from '@/store/classes/MerchantAppSetting';


  export default {
    name: 'SegmentationConfiguration',

    components: {
      LoyaltyCriteriaForm,
      OrderingCriteriaForm,
      AccountCriteriaForm,
      CriteriaFormWrapper
    },

    mixins: [merchantMixin],

    props: {
      value: {
        type: Object,
        required: true
      },

      mode: {
        type: String,
        default: 'create',
        validator(value) {
          return ['create', 'read', 'update'].includes(value);
        }
      }
    },

    data() {
      return {
        criteriaTypes,
        signature: {}
      };
    },

    computed: {
      wrapperHandlers() {
        return {
          'clear-criteria': this.clearCriteria,
          'add-criteria': this.openAddCriteriaModal
        };
      },

      formHandlers() {
        return {
          'handle-input': this.handleCriteriaInput,
          'remove-criteria': this.removeCriteria
        };
      },

      hasAnyCriteria() {
        return [
          criteria.AVERAGE_ORDER_TOTAL_RANGE,
          criteria.LIFETIME_ORDER_TOTAL_EQUALITY,
          criteria.LIFETIME_ORDER_TOTAL_RANGE,
          criteria.DAYS_SINCE_LAST_ORDER,
          criteria.DAYS_SINCE_LAST_LOGIN,
          criteria.REGISTERED_DATE_RANGE,
          criteria.BIRTHDAY_DATE_RANGE,
          criteria.DAYS_SINCE_REGISTERED,
          criteria.MOST_VISITED_STORE,
          criteria.LAST_VISITED_STORE,
          criteria.ENROLLED_IN_LOYALTY,
          criteria.OFFERS_REDEEMED,
          criteria.ORDER_AVERAGES,
          criteria.ORDER_COUNTS,
          criteria.ORDER_ITEM_CATEGORY,
          criteria.LOYALTY_PROGRAM_TIER,
          criteria.SPEND_PERCENTILE,
          criteria.ORDER_PLACED_AT_STORE,
          criteria.ORDER_TOTALS,
          criteria.ORDER_ITEM_ATTRIBUTE,
          criteria.FAVORITE_ITEM,
          criteria.ORDER_ITEM_ATTRIBUTE,
          criteria.FAVORITE_STORE,
          criteria.LAPSED_VISITS,
          criteria.GIFT_CARD_PURCHASE_COUNT,
          criteria.OPERATING_SYSTEM
        ].some(
          criteriaType => (this.value.criteria[criteriaType] !== undefined && this.value.criteria[criteriaType] !== null) // `false` could be a valid value
        );
      },

      merchantTimezoneText() {
        return this.$_selectedMerchant.campaignsIanaTimezoneString;
      },

      progress() {
        if (!this.signature?.totalAccountCount) {
          return 0;
        }
        return (this.signature.totalAccountCountForCriteria / this.signature.totalAccountCount) * 100;
      },

      isFetchingSignature() {
        return SegmentationSignature.$state().fetching;
      },

      merchantPermissionsByCriteria() {
        return {
          ...Object.values(criteria).reduce((acc, key) => ({ ...acc, [key]: true }), {}),
          [criteria.LOYALTY_PROGRAM_TIER]: !!LoyaltyProgramTier.all().length,
          [criteria.LAPSED_VISITS]: this.$_selectedMerchant.features.supportsLapsedUsersSegmentation,
          [criteria.OPERATING_SYSTEM]: MerchantAppSetting.hasIos() || MerchantAppSetting.hasAndroid()
        };
      }
    },

    watch: {
      value: {
        handler: 'handleCriteriaChange',
        deep: true
      }
    },

    created() {
      this.fetchAndSetSegmentationSignature();
    },

    methods: {

      async handleCriteriaChange(newVal, oldVal) {
        if (newVal.name === oldVal.name) { // if something other than the name has changed
          this.debouncedFetchSegmentationSignature(newVal);
        }

        if (!newVal.isCustomCriteria && oldVal.isCustomCriteria) {
          this.resetCriteria();
        }
      },

      filterAvailableCriteriaForType(criteriaType) {
        return this.$clone(Object.values(criteriaCardMetadata))
          .filter(({ defaultCriteriaKey, type }) => {
            const criteriaValue = this.value.criteria[defaultCriteriaKey];
            const isMerchantPermitted = this.merchantPermissionsByCriteria[defaultCriteriaKey];
            return type === criteriaType && (criteriaValue === undefined || criteriaValue === null) && isMerchantPermitted;
          });
      },

      openAddCriteriaModal(criteriaType) {
        const availableCriteria = this.filterAvailableCriteriaForType(criteriaType);
        this.$buefy.modal.open({
          parent: this,
          component: AddCriteriaModal,
          hasModalCard: true,
          trapFocus: true,
          canCancel: false,
          props: {
            criteriaType,
            availableCriteria
          },
          events: {
            'add-selected-criteria': this.addSelectedCriteria
          }
        });
      },

      addSelectedCriteria(selectedCriteria) {
        const payload = selectedCriteria.reduce((acc, selectedCrit) => ({ ...acc, [selectedCrit.defaultCriteriaKey]: selectedCrit.defaultData }), {});
        this.handleCriteriaInput(payload);
      },

      removeCriteria(criteriaKeys) {
        const payload = criteriaKeys.reduce((acc, criteriaKey) => ({ ...acc, [criteriaKey]: undefined }), {});
        this.handleCriteriaInput(payload);
      },

      clearCriteria(type) {
        switch (type) {
          case 'ordering':
            this.$emit('input', {
              ...this.value,
              criteria: {
                ...this.value.criteria,
                orderTypeIds: undefined,
                daysSinceLastOrder: undefined,
                hasNoOrders: undefined,
                averageOrderTotalRange: undefined,
                lifetimeOrderTotalEquality: undefined,
                lifetimeOrderTotalRange: undefined,
                orderAverages: undefined,
                orderCounts: undefined,
                orderItemCategory: undefined,
                spendPercentile: undefined,
                orderPlacedAtStore: undefined,
                orderTotals: undefined,
                orderItemAttribute: undefined,
                favoriteStoreId: undefined,
                favoriteItem: undefined,
                lapsedVisits: undefined,
                giftCardPurchaseCount: undefined
              }
            });
            break;

          case 'account':
            this.handleCriteriaInput({
              registeredDateRange: undefined,
              mostVisitedStoreId: undefined,
              lastVisitedStoreId: undefined,
              favoriteStoreId: undefined,
              daysSinceRegistered: undefined,
              birthdayDateRange: undefined,
              daysSinceLastLogin: undefined,
              operatingSystem: undefined
            });
            break;

          case 'loyalty':
            this.handleCriteriaInput({
              enrolledInLoyalty: undefined,
              loyaltyPointBalance: undefined,
              offersRedeemed: undefined,
              loyaltyProgramTier: undefined
            });
            break;

          default:
            break;
        }
      },

      resetCriteria() {
        const resetValue = this.$clone(this.value);
        criteria.forEach((key) => {
          resetValue.criteria[key] = undefined;
        });

        this.fetchAndSetSegmentationSignature(resetValue);
        this.$emit('input', resetValue);
      },

      // eslint-disable-next-line
      debouncedFetchSegmentationSignature: debounce(function (segmentation = this.value) { // test?
        this.fetchAndSetSegmentationSignature(segmentation);
      }, 1250),

      async fetchAndSetSegmentationSignature(segmentation = this.value) {
        try {
          const clonedSegmentation = this.$clone(segmentation);
          if (clonedSegmentation.criteria.operatingSystem === false) {
            // CAPI expects an enum value for operatingSystem, so false would trigger a 500 error
            delete clonedSegmentation.criteria.operatingSystem;
          }
          this.signature = await SegmentationSignature.fetchSegmentationSignature(clonedSegmentation);
        }
        catch (error) {
          this.$_onRequestError({
            toastOptions: {
              message: 'There was an error fetching Target Audience data'
            },
            error
          });
        }
      },


      handleCriteriaInput(payload) {
        this.$emit('input', {
          ...this.value,
          criteria: {
            ...this.value.criteria,
            ...payload
          }
        });
      },

      handleInput(payload) {
        this.$emit('input', { ...this.value, ...payload });
      }
    }
  };
</script>

<style lang="sass" scoped>
.b-radio
  flex-basis: 400px
</style>
