import http from '@/services/http';
import FetchModel from '@/store/classes/FetchModel';

export default class MerchantConnectedAccountType extends FetchModel {
  static entity = 'merchantConnectedAccountTypes';

  // FIELDS //////////////////////
  static fields() {
    return {
      merchantId: this.attr(null),
      connectedAccountTypeId: this.attr(null)
    };
  }


  // STATE //////////////////////
  static state() {
    return {
      fetching: false,
      submitting: false
    };
  }

  static $state() {
    return this.store().state.entities.merchantConnectedAccountTypes;
  }


  // ACTIONS //////////////////////
  static async fetchMerchantConnectedAccountTypes(options = {}) {
    const merchantId = this.store().state.entities.merchants.selectedMerchantId;

    await this.fetchData({
      endpoint: `merchants/${merchantId}/merchant_connected_account_types`,
      options,
      transformData: data => data.merchantConnectedAccountTypes
    });
  }

  static async updateMerchantConnectedAccountTypes(selectedAccountTypeIds) {
    try {
      this.commit((state) => {
        state.submitting = true;
      });

      const merchantId = this.store().state.entities.merchants.selectedMerchantId;
      const merchantConnectedAccountTypes = selectedAccountTypeIds.map(connectedAccountTypeId => ({
        connectedAccountTypeId,
        merchantId
      }));

      const { data } = await http.post(`merchants/${merchantId}/merchant_connected_account_types/upsert`, { merchantConnectedAccountTypes });

      this.create({ data: data.merchantConnectedAccountTypes });
    }
    catch (error) {
      throw error;
    }
    finally {
      this.commit((state) => {
        state.submitting = false;
      });
    }
  }
}

