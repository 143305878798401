import { Model } from '@vuex-orm/core';
import http from '@/services/http';

export default class RegisteredGuest extends Model {
  static entity = 'registeredGuests';

  // FIELDS //////////////////////
  static fields() {
    return {
      id: this.attr(null),
      accountConnectedAccounts: this.attr([]),
      birthDay: this.attr(''),
      birthMonth: this.attr(''),
      birthYear: this.attr(''),
      createdAt: this.attr(''),
      email: this.attr(''),
      firstName: this.attr(''),
      lastName: this.attr(''),
      loyaltyNumber: this.attr(''),
      loyaltyPoint: this.attr(''),
      primarySmsNumber: this.attr(''),
      accountNotificationPreference: this.attr(() => ({})),
      loyaltyReward: this.attr('', value => ({
        loyaltyStatus: value?.loyaltyStatus,
        loyaltyNumber: value?.loyaltyNumber,
        enrollmentDate: value?.enrollmentDate,
        memberId: value?.memberId,
        publicId: value?.publicId,
        currentPointTotal: value?.currentPointTotal,
        currency: value?.currency,
        currencyId: value?.currencyId,
        primaryActiveTier: {
          publicId: value?.primaryActiveTier?.publicId,
          memberPublicId: value?.primaryActiveTier?.memberPublicId,
          programTier: {
            publicId: value?.primaryActiveTier?.programTier?.publicId,
            programPublicId: value?.primaryActiveTier?.programTier?.programPublicId,
            displayName: value?.primaryActiveTier?.programTier?.displayName,
            attainmentSequenceNumber: value?.primaryActiveTier?.programTier?.attainmentSequenceNumber,
            isDefault: value?.primaryActiveTier?.programTier?.isDefault
          },
          isActive: value?.primaryActiveTier?.isActive,
          attainmentTransactionDateTimeOffset: value?.primaryActiveTier?.attainmentTransactionDateTimeOffset,
          attainmentProcessedDateTimeOffset: value?.primaryActiveTier?.attainmentProcessedDateTimeOffset,
          earliestExpirationDateTimeOffset: value?.primaryActiveTier?.earliestExpirationDateTimeOffset
        }
      })),
      hasDeviceId: this.attr(false),

      // used "internally" for retrieving Orders in the
      // same order they were received from the endpoint
      sortOrder: this.attr(''),
      zip: this.attr('')
    };
  }



  // GETTERS //////////////////////
  get fullName() {
    return [this.firstName, this.lastName].filter(Boolean).join(' ');
  }



  // STATE //////////////////////
  static state() {
    return {
      fetching: false,
      submitting: false,
      exporting: false,
      fetchingOfferAllocations: false,
      fetchingMembershipActivities: false,
      fetchingChallengeProgress: false,
      registeredGuestMetadata: {}
    };
  }

  static $state() {
    return this.store().state.entities.registeredGuests;
  }



  // ACTIONS //////////////////////
  static resetRegisteredGuests() {
    this.deleteAll();

    this.commit((state) => {
      state.registeredGuestMetadata = {};
    });
  }

  static async fetchRegisteredGuests({
    sortField,
    sortDirection,
    page,
    param,
    query
  }) {
    try {
      this.commit((state) => {
        state.fetching = true;
      });

      this.deleteAll();

      const queries = [
        page && `page=${page}`,
        sortField && `sort_field=${sortField}`,
        sortDirection && `sort_direction=${sortDirection}`,
        param && query && `search_field=${param}`,
        query && `search_term=${encodeURIComponent(query)}`
      ].filter(q => q);

      const queryString = queries.length ? `?${queries.join('&')}` : '';
      const merchantId = this.store().state.entities.merchants.selectedMerchantId;

      const {
        data: { accounts, meta }
      } = await http.get(`merchants/${merchantId}/accounts${queryString}`);

      this.commit((state) => {
        state.registeredGuestMetadata = meta;
      });

      this.create({
        data: accounts.map((guest, index) => {
          // assigns a "sort order" to guests using the array index to retain
          // its sort position as it was when it was returned from the endpoint
          guest.sortOrder = index;
          return guest;
        })
      });
    }

    catch (error) {
      throw error;
    }

    finally {
      this.commit((state) => {
        state.fetching = false;
      });
    }
  }

  static async fetchRegisteredGuest(accountId) {
    try {
      this.commit((state) => {
        state.fetching = true;
      });

      const { data: { account } } = await http.get(`accounts/${accountId}`);
      const loyaltyReward = await this.fetchLoyaltyReward(accountId);

      this.insert({ data: { ...account, loyaltyReward } });
    }
    catch (error) {
      throw error;
    }
    finally {
      this.commit((state) => {
        state.fetching = false;
      });
    }
  }

  static async updateRegisteredGuest({ id, ...registeredGuestData }) {
    try {
      await http.put(`accounts/${id}`, { account: registeredGuestData });

      this.update({
        where: id,
        data: registeredGuestData
      });
    }
    catch (error) {
      throw error;
    }
  }

  static async resetAccountPassword(accountId) {
    try {
      this.commit((state) => {
        state.submitting = true;
      });

      await http.post(`accounts/${accountId}/request_reset`);
    }

    catch (error) {
      throw error;
    }

    finally {
      this.commit((state) => {
        state.submitting = false;
      });
    }
  }

  static async invalidateAccountPassword(accountId) {
    try {
      await http.put(`accounts/${accountId}/invalidate_password`);
    }

    catch (error) {
      throw error;
    }
  }

  static async deleteAccount(accountId) {
    try {
      this.commit((state) => {
        state.submitting = true;
      });

      await http.delete(`accounts/${accountId}`);

      this.delete(accountId);
    }

    catch (error) {
      throw error;
    }

    finally {
      this.commit((state) => {
        state.submitting = false;
      });
    }
  }

  static async fetchLoyaltyReward(accountId) {
    try {
      const { data: { loyaltyReward } } = await http.get(`accounts/${accountId}/loyalty_reward`);
      return loyaltyReward;
    }

    catch (error) {
      throw error;
    }
  }

  static async fetchMembershipActivities(accountId, limit, offset) {
    try {
      this.commit((state) => {
        state.fetchingMembershipActivities = true;
      });
      const { data } = await http.get(`accounts/${accountId}/loyalty_membership_activities?limit=${limit}&offset=${offset}`);
      return data;
    }

    catch (error) {
      throw error;
    }

    finally {
      this.commit((state) => {
        state.fetchingMembershipActivities = false;
      });
    }
  }

  static async fetchChallengeProgress({ accountId, memberId }) {
    try {
      this.commit((state) => {
        state.fetchingChallengeProgress = true;
      });
      const { data } = await http.get(`accounts/${accountId}/challenge_progress/${memberId}`);
      return data.accountChallengeProgress;
    }

    catch (error) {
      throw error;
    }

    finally {
      this.commit((state) => {
        state.fetchingChallengeProgress = false;
      });
    }
  }

  static async updateLoyaltyBalance(accountId, { points, currencyId, memberId }) {
    try {
      await http.put(`accounts/${accountId}/loyalty_reward`, { points, currencyId, memberId });
      const loyaltyReward = await this.fetchLoyaltyReward(accountId);

      this.update({
        where: accountId,
        data: { loyaltyReward }
      });
    }
    catch (error) {
      throw error;
    }
  }

  static async claimOrder({ type, receiptToken, accountId }) {
    try {
      this.commit((state) => {
        state.submitting = true;
      });
      const paramsKey = type === 'pos' ? 'posReceiptToken' : 'cfReceiptToken';
      await http.post(`accounts/${accountId}/retroactive_claims/claim_order`, {
        [paramsKey]: receiptToken
      });
    }

    catch (error) {
      throw error;
    }

    finally {
      this.commit((state) => {
        state.submitting = false;
      });
    }
  }

  static resetState() {
    this.commit((state) => {
      state.registeredGuestMetadata = {};
      state.fetching = false;
    });
    this.deleteAll();
  }
}
