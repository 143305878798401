<template>
  <div class="card" data-test-id="configuration-step">
    <div class="card-content">
      <p class="is-4 subtitle mar-b-xs">Settings</p>
      <validated-input
        label="Message Type"
        name="inAppMessageTypeId"
        rules="required"
        tooltip="Only one message per type can be live at a time"
        data-test-id="message-type-input"
      >
        <dropdown-menu
          v-model="inAppMessageTypeId"
          expanded
        >
          <dropdown-button
            slot="trigger"
            :value="inAppMessageTypeId ? `${selectedInAppMessageType.name} - ${selectedInAppMessageType.subtitle}` : null"
            placeholder="Select a message type..."
            :is-full-width="false"
            data-test-id="selected-message-type-button"
          />
          <b-dropdown-item
            v-for="messageType in inAppMessageTypes"
            :key="`${messageType}${messageType.id}`"
            :value="messageType"
          >
            {{ messageType.name }} - <span class="has-text-grey">{{ messageType.subtitle }}</span>
          </b-dropdown-item>
        </dropdown-menu>
      </validated-input>
    </div>
    <div class="card-content">
      <p class="is-4 subtitle mar-b-xs">Schedule</p>
      <p class="is-6 subtitle has-text-grey">Determine when this message should display</p>
      <div class="is-grid col-2 gap-x">
        <validated-input
          name="startDate"
          rules="required"
          label="Start Date"
        >
          <b-datepicker
            v-model="startDate"
            data-test-id="start-date-input"
            :years-range="dys_ranges.startDate"
            placeholder="Start Date"
            icon="calendar-alt"
            class="has-extra-shadow"
            :events="endDate ? [{ date: endDate, type: 'is-danger' }] : []"
            indicators="bars"
            :focused-date="endDate"
            :min-date="minDate"
            :disabled="readOnly"
            @change-year="(year) => $_handleYearChange({ year, type: 'startDate', yearsInPast: 10, yearsInFuture: 30 })"
          >
            <div class="buttons is-right">
              <b-button @click="handleInput({ startDate: null })">Clear</b-button>
            </div>
          </b-datepicker>
        </validated-input>

        <validated-input
          name="endDate"
          label="End Date"
          sub-label="(Optional)"
          sub-label-on-side
        >
          <b-datepicker
            v-model="endDate"
            data-test-id="end-date-input"
            :years-range="dys_ranges.endDate"
            placeholder="End Date"
            icon="calendar-alt"
            class="has-extra-shadow"
            position="is-bottom-left"
            :min-date="minDate"
            :events="startDate ? [{ date: startDate, type: 'is-success' }] : []"
            indicators="bars"
            :focused-date="startDate"
            :disabled="readOnly"
            @change-year="(year) => $_handleYearChange({ year, type: 'endDate', yearsInPast: 10, yearsInFuture: 30 })"
          >
            <div class="buttons is-right">
              <b-button @click="handleInput({ endDate: null })">Clear</b-button>
            </div>
          </b-datepicker>
        </validated-input>
      </div>

      <hr>

      <div class="is-grid dist-y-md">
        <div class="is-flex justify-start align-center">
          <label class="label mar-none mar-r-xl">Applies...</label>
          <b-select v-model="scheduledTime" :disabled="readOnly">
            <option :value="false">All day, every day</option>
            <option :value="true">During specific times</option>
          </b-select>
        </div>
        <transition v-if="scheduledTime" name="fade-down">
          <div
            key="startEndTime"
            data-test-id="time-of-day-range-input"
            class="is-flex-start-aligned"
          >
            <validated-input
              rules="required"
              name="startTime"
              label="From"
            >
              <b-timepicker
                v-model="startTime"
                size="is-small"
                :default-minutes="0"
                hour-format="12"
                inline
                :disabled="readOnly"
              />
            </validated-input>
            <validated-input
              rules="required|dateTimeIsAfter:@startTime"
              name="endTime"
              label="To"
              class="mar-l-md"
            >
              <b-timepicker
                v-model="endTime"
                size="is-small"
                :default-minutes="0"
                hour-format="12"
                inline
                :disabled="readOnly"
              />
            </validated-input>
          </div>
        </transition>
      </div>
    </div>
  </div>
</template>

  <script>
  import moment from 'moment-timezone';

  import dynamicYearSelectMixin from '@/mixins/dynamic-year-select';

  import InAppMessageType from '@/store/classes/InAppMessageType';

  import { inAppMessageTypeNames } from '@/constants/merchantInAppMessages';


  export default {
    name: 'ConfigurationStep',

    mixins: [dynamicYearSelectMixin],

    props: {
      value: {
        type: Object,
        required: true
      },

      readOnly: {
        type: Boolean,
        default: false
      },

      merchantHasMobileAppConfigured: {
        type: Boolean,
        default: false
      }
    },

    data() {
      return {
        inAppMessageTypeNames
      };
    },

    computed: {
      inAppMessageTypes() {
        return InAppMessageType.all().map(type => ({
          id: type.id,
          name: type.name,
          subtitle: this.getMessageTypeSubtitle(type.name),
          ...InAppMessageType.metadata(type.name)
        })).filter(type => (
          this.merchantHasMobileAppConfigured ? true : !type.mobileOnly
        ));
      },

      inAppMessageTypeId: {
        get() {
          return this.value.inAppMessageTypeId;
        },
        set(inAppMessageType) {
          const payload = { inAppMessageTypeId: inAppMessageType.id };
          if (inAppMessageType.name === inAppMessageTypeNames.APP_LOAD) {
            payload.alwaysShow = false;
          }
          this.handleInput(payload);
        }
      },

      minDate() {
        return moment()
          .startOf('day')
          .toDate();
      },

      startDate: {
        get() {
          return this.value.startDate && moment(this.value.startDate).toDate();
        },
        set(val) {
          const dateString = moment.utc(val).format('YYYY-MM-DD');
          this.handleInput({ startDate: dateString });
        }
      },

      endDate: {
        get() {
          return (this.value.endDate && moment(this.value.endDate).toDate());
        },
        set(val) {
          const dateString = moment.utc(val).format('YYYY-MM-DD');
          this.handleInput({ endDate: dateString });
        }
      },

      startTime: {
        get() {
          return this.value ? moment(this.value.startTime, 'HH:mm').toDate() : null;
        },
        set(value) {
          this.handleInput({ startTime: this.formatTime(value) });
        }
      },

      endTime: {
        get() {
          return this.value ? moment(this.value.endTime, 'HH:mm').toDate() : null;
        },
        set(value) {
          this.handleInput({ endTime: this.formatTime(value) });
        }
      },

      scheduledTime: {
        get() {
          return !!this.value.startTime || !!this.value.endTime;
        },
        set(value) {
          if (!value) {
            this.handleInput({ startTime: null, endTime: null });
          }
          else {
            this.handleInput({ startTime: '06:00', endTime: '18:00' });
          }
        }
      },

      selectedInAppMessageType() {
        return this.inAppMessageTypeId
          ? this.inAppMessageTypes.find(type => type.id === this.inAppMessageTypeId)
          : null;
      }
    },

    methods: {
      formatTime: value => moment(value).format('HH:mm'),

      handleInput(payload) {
        this.$emit('input', { ...this.value, ...payload });
      },

      getMessageTypeSubtitle(messageTypeName) {
        switch (messageTypeName) {
          case inAppMessageTypeNames.APP_LOAD:
            return this.merchantHasMobileAppConfigured
              ? 'Mobile only. Shows on inital app load, then never again.'
              : '';
          case inAppMessageTypeNames.HOME:
            return this.merchantHasMobileAppConfigured
              ? 'Mobile only'
              : '';
          case inAppMessageTypeNames.LOYALTY:
          case inAppMessageTypeNames.MENU:
          case inAppMessageTypeNames.RECEIPT:
          case inAppMessageTypeNames.CLAIM_CONFIRMATION:
            return this.merchantHasMobileAppConfigured
              ? 'Mobile and web apps'
              : 'Web app';
          default:
            return '';
        }
      }
    }
  };
</script>

<style lang="sass" scoped>
  .card-content:nth-child(even)
    border-top: 1px solid $grey-lightest
    background-color: $white-ter !important

    &:not(:last-child)
      border-bottom: 1px solid $grey-lightest

    hr
      background-color: $grey-lighter
</style>
