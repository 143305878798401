<template>
  <div class="detail-page is-flex-column">
    <hero-banner>
      <h1 class="title is-2">POS Discounts</h1>
      <template #right>
        <b-button
          v-if="posDiscountCodes.length"
          rounded
          inverted
          size="is-medium"
          type="is-primary"
          icon-left="plus"
          @click="openPosDiscountModal()"
        >
          New POS Discount
        </b-button>
      </template>
    </hero-banner>

    <div class="flex-grow is-relative">
      <div class="section">
        <div class="container">
          <div v-if="posDiscountCodes.length" class="card">
            <searchable-table
              v-slot="{ filteredData }"
              :table-data="posDiscountCodes"
              :search="{
                placeholder: 'Search by Internal Name',
                keys: ['name']
              }"
            >
              <b-table
                :data="isFetchingPosDiscounts ? [] : filteredData"
                class="is-middle-aligned"
                :mobile-cards="false"
                :default-sort="['name', 'asc']"
                scrollable
              >
                <template slot="empty">
                  <empty-table-loader
                    :loading="isFetchingPosDiscounts"
                    :has-icon="false"
                    message="No Matching POS Discount codes found"
                  />
                </template>

                <b-table-column
                  v-slot="{ row: posDiscountCode }"
                  cell-class="no-wrap-text"
                  field="name"
                  sortable
                  label="Internal Name"
                >
                  <span v-if="posDiscountCode.name" class="link" @click="openPosDiscountModal(posDiscountCode)">{{ posDiscountCode.name }}</span>
                  <span v-else class="has-text-grey-light">N/A</span>
                </b-table-column>

                <b-table-column
                  v-slot="{ row: { displayName } }"
                  cell-class="no-wrap-text"
                  sortable
                  label="Display Name"
                  field="displayName"
                >
                  <span v-if="displayName">{{ displayName }}</span>
                  <span v-else class="has-text-grey-light">N/A</span>
                </b-table-column>


                <b-table-column
                  v-slot="{ row: { publicId } }"
                  cell-class="no-wrap-text is-monospaced"
                  label="PublicId"
                  field="PublicId"
                >
                  <span v-if="publicId">{{ publicId }}</span>
                  <span v-else class="has-text-grey-light">N/A</span>
                </b-table-column>

                <b-table-column
                  v-slot="{ row: posDiscountCode }"
                  cell-class="actions"
                  numeric
                  label="Actions"
                >
                  <dropdown-menu position="bottom-end" :mobile-modal="false">
                    <b-button slot="trigger" class="is-transparent" type="is-white">
                      <b-icon icon="ellipsis-v" pack="far" size="is-small" />
                    </b-button>

                    <b-dropdown-item @click="openPosDiscountModal(posDiscountCode)">
                      <b-icon icon="pencil" class="mar-r-sm" size="is-small" />
                      Edit Configuration
                    </b-dropdown-item>
                  </dropdown-menu>
                </b-table-column>
              </b-table>
            </searchable-table>
          </div>

          <empty-state-card
            v-else
            type="is-primary"
            title="Create POS Discount"
            message="Define POS Discount codes"
            icon="tags"
          >
            <template #buttons>
              <b-button
                type="is-primary"
                rounded
                icon-left="plus"
                @click="openPosDiscountModal()"
              >
                New POS Discount
              </b-button>
            </template>
          </empty-state-card>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import PosDiscountConfiguration from '@/store/classes/PosDiscountConfiguration';
  import Store from '@/store/classes/Store';
  import PosDiscountModal from '@/components/pages/pos-discount/pos-discount-modal.vue';



  export default {
    name: 'PosDiscountList',

    computed: {
      isFetchingPosDiscounts() {
        return PosDiscountConfiguration.$state().fetching;
      },

      posDiscountCodes() {
        return PosDiscountConfiguration.all();
      }
    },

    created() {
      this.onCreated();
    },

    methods: {
      async onCreated() {
        try {
          await Promise.all([
            PosDiscountConfiguration.fetchPosDiscounts(),
            Store.fetchAll()
          ]);
        }
        catch (error) {
          this.$_onRequestError({
            error,
            toastOptions: {
              message: 'There was an error fetching pos discount codes'
            }
          });
        }
      },

      openPosDiscountModal(posDiscountConfig) {
        const posDiscountConfigurationId = posDiscountConfig?.id;

        this.$buefy.modal.open({
          parent: this,
          component: PosDiscountModal,
          hasModalCard: true,
          trapFocus: true,
          canCancel: false,
          fullScreen: false,
          customClass: 'auto-width',
          props: {
            posDiscountConfigurationId,
            unavailableNames: this.posDiscountCodes.filter(pdc => pdc.id !== posDiscountConfigurationId).map(x => x.name)
          }
        });
      }
    }
  };
</script>
