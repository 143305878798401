<template>
  <div class="is-flex-column gap-sm pad has-background-white-bis has-border has-border-grey-lightest has-radius">
    <div class="is-flex justify-between align-baseline">
      <div>
        <p class="title is-6">Location</p>
        <p class="subtitle is-7 has-text-grey">Select which location(s) this offer can be redeemed at. Offer will not be valid at any other locations.</p>
      </div>
      <b-button
        v-if="!readOnly"
        outlined
        icon-left="trash-alt"
        type="is-danger is-light"
        @click="$emit('remove-constraint', locationConstraint)"
      />
    </div>
    <div v-if="isFetching" class="align-self-center">
      <b-icon icon="spinner-third" class="spin" />
    </div>
    <template v-else>
      <b-message v-if="!stores.length" type="is-warning">
        There are no available stores. Please add a store before making a constraint.
      </b-message>
      <div v-else class="is-grid col-min-300">
        <regional-location-dropdown
          v-model="selectedStoreIds"
          hide-label
          select-all-mode="includeAll"
          :stores="stores"
          :disabled="readOnly"
          required
        />
      </div>
      <validation-provider
        v-slot="{ errors }"
        name="posDiscountError"
        :rules="{ required: posDiscountCode && locationConstraint && !posDiscountIncludesLocations }"
      >
        <b-checkbox :value="posDiscountCode && locationConstraint && posDiscountIncludesLocations || null" :class="['is-hidden', { 'invalid': errors.length }]" />
        <b-message
          v-if="errors.length"
          key="timeframeInvalid"
          type="is-danger"
          size="is-small"
          class="is-compact has-shadow justify-self-start"
        >
          <p class="is-flex align-center">
            <b-icon custom-size="fa-lg" icon="exclamation-circle" type="is-danger" class="mar-r-xs" />
            The Selected POS Discount does not include all selected stores.
          </p>
        </b-message>
      </validation-provider>
    </template>
  </div>
</template>

<script>

  import { constraintTypes } from '@/constants/offers';
  import Store from '@/store/classes/Store';

  export default {
    name: 'LocationConstraintCard',

    props: {
      locationConstraint: {
        type: Object,
        required: true
      },

      readOnly: {
        type: Boolean,
        default: false
      },
      posDiscountCode: {
        type: Object,
        default: undefined
      }
    },

    data() {
      return {
        constraintTypes
      };
    },

    computed: {
      stores() {
        return Store.all();
      },

      isFetching() {
        return Store.$state().fetching;
      },

      selectedStoreIds: {
        get() {
          return this.locationConstraint.storeIds;
        },
        set(value) {
          this.$emit('update-constraint', {
            ...this.locationConstraint,
            storeIds: value
          });
        }
      },

      posDiscountStoreIds() {
        return this.posDiscountCode.posDiscountStoreConfigurations.map(pdsc => pdsc.storeId);
      },

      posDiscountIncludesLocations() {
        if (this.posDiscountCode?.posDiscountStoreConfigurations?.some(config => config.storeId === -1)) {
          return true;
        }
        else if (this.selectedStoreIds.every(storeId => this.posDiscountStoreIds.includes(storeId))) {
          return true;
        }
        else {
          return false;
        }
      }
    },

    created() {
      this.onCreated();
    },

    methods: {

      async onCreated() {
        await this.fetchStores();
      },

      async fetchStores() {
        try {
          await Store.fetchAll();
        }
        catch (error) {
          this.$_onRequestError({ toastOptions: { message: 'Unable to fetch stores' }, error });
        }
      }
    }
  };
</script>
