// classes
import { Model } from '@vuex-orm/core';
import http from '@/services/http';
import filterObjectKeys from '@/helpers/filter-object-keys';


export default class MerchantOptionsOpd extends Model {
  static entity = 'merchantOptionsOpds'

  // FIELDS //////////////////////
  static fields() {
    return {
      id: this.attr(''),
      merchantId: this.attr(''),
      opdSyncCacheEnabled: this.attr(''),
      opdSyncCacheInvalidSeconds: this.attr(''),
      opdSyncCacheSeconds: this.attr('')
    };
  }

  // STATE //////////////////////
  static state() {
    return {
      fetching: false,
      submitting: false
    };
  }

  static $state() {
    return this.store().state.entities.merchantOptionsOpds;
  }

  static blackListedKeys = ['id', '$id', 'merchantId'];


  // ACTIONS //////////////////////
  static async fetchMerchantOptionsOpds(merchantId) {
    try {
      this.commit((state) => {
        state.fetching = true;
      });

      const _merchantId = merchantId || this.store().state.entities.merchants.selectedMerchantId;
      const response = await http.get(`merchants/${_merchantId}/merchant_options_opds`);

      this.insert({
        data: response.data.merchantOptionsOpds
      });
      return response.data.merchantOptionsOpds;
    }
    catch (error) {
      throw error;
    }
    finally {
      this.commit((state) => {
        state.fetching = false;
      });
    }
  }

  static async addMerchantOptionsOpd(merchantOptionsOpd) {
    try {
      this.commit((state) => {
        state.submitting = true;
      });

      const response = await http.post(
        `merchants/${merchantOptionsOpd.merchantId}/merchant_options_opds`,
        { merchantOptionsOpd: filterObjectKeys(merchantOptionsOpd, this.blackListedKeys, true) }
      );

      this.insert({
        data: response.data.merchantOptionsOpd
      });
    }
    catch (error) {
      throw error;
    }
    finally {
      this.commit((state) => {
        state.submitting = false;
      });
    }
  }

  static async updateMerchantOptionsOpd(merchantOptionsOpd) {
    try {
      this.commit((state) => {
        state.submitting = true;
      });

      const response = await http.put(
        `merchant_options_opds/${merchantOptionsOpd.id}`,
        { merchantOptionsOpd: filterObjectKeys(merchantOptionsOpd, this.blackListedKeys, true) }
      );

      this.update({
        data: response.data.merchantOptionsOpd
      });
    }
    catch (error) {
      throw error;
    }
    finally {
      this.commit((state) => {
        state.submitting = false;
      });
    }
  }
}
