<template>
  <div class="card" data-test-id="details-step">
    <div class="card-content">
      <validated-text-input
        :value="value.name"
        data-test-id="name-input"
        name="name"
        type="text"
        rules="required|max:255"
        :custom-messages="{ required: 'You must give your Earn Rule a name' }"
        label="Name"
        sub-label="Internal name used for searching and sorting your earn rules"
        @input="handleInput({ name: $event })"
      />
      <validated-text-input
        :value="value.description"
        data-test-id="description-input"
        name="description"
        type="text"
        maxlength="255"
        label="Description"
        sub-label="Internal description used for searching and sorting your earn rules — Optional"
        @input="handleInput({ description: $event })"
      />
      <div v-if="value.publicId" class="mar-t-sm has-text-grey is-monospaced">
        ID: {{ value.publicId }}
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'DetailsStep',

    props: {
      value: {
        type: Object,
        required: true
      },

      readOnly: {
        type: Boolean,
        default: false
      }
    },

    methods: {
      handleInput(payload) {
        this.$emit('input', { ...this.value, ...payload });
      }
    }
  };
</script>
