<template>
  <div class="card">
    <tipping-option-form :merchant="merchant" />
  </div>
</template>

<script>
  import tippingOptionForm from '@/components/globals/tipping/tipping-option-form.vue';

  export default {
    name: 'MerchantTippingTab',

    components: { tippingOptionForm },

    props: {
      merchant: {
        type: Object,
        required: true
      }
    }
  };
</script>
