import { Model } from '@vuex-orm/core';

// classes
import http from '@/services/http';



export default class StoreDeliveryServiceSchedule extends Model {
  static entity = 'storeDeliveryServiceSchedules';


  // FIELDS ////////////////////
  static fields() {
    return {
      ...super.fields(),
      friEnd: this.attr(null),
      friStart: this.attr(null),
      id: this.attr(null),
      monEnd: this.attr(null),
      monStart: this.attr(null),
      satEnd: this.attr(null),
      satStart: this.attr(null),
      storeDeliveryServiceId: this.attr(null),
      sunEnd: this.attr(null),
      sunStart: this.attr(null),
      thuEnd: this.attr(null),
      thuStart: this.attr(null),
      tueEnd: this.attr(null),
      tueStart: this.attr(null),
      wedEnd: this.attr(null),
      wedStart: this.attr(null)
    };
  }



  // STATE ////////////////////
  static state() {
    return {
      fetching: false,
      updating: false
    };
  }

  static $state() {
    return this.store().state.entities.storeDeliveryServices;
  }


  // ACTIONS ////////////////////
  static async getSchedules(storeDeliveryServiceId) {
    try {
      this.commit((state) => {
        state.fetching = true;
      });

      const { data } = await http.get(`store_delivery_services/${storeDeliveryServiceId}/store_delivery_service_schedules`);

      this.insert({
        data: data.storeDeliveryServiceSchedules
      });
    }

    catch (error) {
      throw error;
    }

    finally {
      this.commit((state) => {
        state.fetching = false;
      });
    }
  }

  static async addSchedule({ storeDeliveryServiceId, storeDeliveryServiceSchedule }) {
    try {
      this.commit((state) => {
        state.updating = true;
      });
      const { data } = await http.post(`store_delivery_services/${storeDeliveryServiceId}/store_delivery_service_schedules`, { storeDeliveryServiceSchedule });

      this.insert({
        data: data.storeDeliveryServiceSchedule
      });
    }

    catch (error) {
      throw error;
    }

    finally {
      this.commit((state) => {
        state.updating = false;
      });
    }
  }

  static async updateSchedule(storeDeliveryServiceSchedule) {
    try {
      this.commit((state) => {
        state.updating = true;
      });

      const { data } = await http.put(`store_delivery_service_schedules/${storeDeliveryServiceSchedule.id}`, { storeDeliveryServiceSchedule });

      this.update({
        data: data.storeDeliveryServiceSchedule
      });
    }

    catch (error) {
      throw error;
    }

    finally {
      this.commit((state) => {
        state.updating = false;
      });
    }
  }

  static async deleteSchedule(storeDeliveryServiceScheduleId) {
    try {
      this.commit((state) => {
        state.updating = true;
      });

      await http.delete(`store_delivery_service_schedules/${storeDeliveryServiceScheduleId}`);

      this.delete(storeDeliveryServiceScheduleId);
    }

    catch (error) {
      throw error;
    }

    finally {
      this.commit((state) => {
        state.updating = false;
      });
    }
  }
}
