// classes
import { Model } from '@vuex-orm/core';
import http from '@/services/http';


export default class PushProviderType extends Model {
  static entity = 'pushProviderTypes'

  // FIELDS //////////////////////
  static fields() {
    return {
      id: this.attr(''),
      name: this.attr(''),
      isActive: this.attr(null)
    };
  }

  // STATE //////////////////////
  static state() {
    return {
      fetching: false
    };
  }

  static $state() {
    return this.store().state.entities.pushProviderTypes;
  }

  static getName(id) {
    return this.find(id)?.name;
  }

  // ACTIONS //////////////////////
  static async fetchPushProviderTypes() {
    if (this.exists()) return;

    try {
      this.commit((state) => {
        state.fetching = true;
      });

      const response = await http.get('push_provider_types');
      this.create({
        data: response.data.pushProviderTypes
      });
    }
    catch (error) {
      throw error;
    }
    finally {
      this.commit((state) => {
        state.fetching = false;
      });
    }
  }
}
