import { Model } from '@vuex-orm/core';
import http from '@/services/http';



export default class OrderDashboardColumn extends Model {
  static entity = 'orderDashboardColumns'



  // FIELDS //////////////////////
  static fields() {
    return {
      id: this.attr(''),
      displayName: this.attr(''),
      preparationStatus: this.attr(''),
      sortOrder: this.number(1)
    };
  }


  // STATE //////////////////////
  static state() {
    return {
      fetching: false
    };
  }

  static $state() {
    return this.store().state.entities.orderDashboardColumns;
  }

  get tagType() {
    const tagTypeMap = {
      New: 'is-dark',
      InPrep: 'is-primary',
      Completed: 'is-success',
      PickedUp: 'is-warning',
      Cancelled: 'is-danger'
    };
    return tagTypeMap[this.preparationStatus];
  }


  // ACTIONS //////////////////////
  static async fetchOrderDashboardColumns(options = {}) {
    try {
      this.commit((state) => {
        state.fetching = true;
      });
      const alreadyFetched = this.exists();
      if (!alreadyFetched || options.forceFetch) {
        const { data: { orderDashboardColumns } } = await http.get('features/order_dashboard_columns');

        this.create({
          data: orderDashboardColumns
        });
      }
    }

    catch (error) {
      throw error;
    }

    finally {
      this.commit((state) => {
        state.fetching = false;
      });
    }
  }
}
