<template>
  <div
    :id="'opd-order-' + order.orderId"
    class="card"
    :class="{
      'is-open': isOpen,
      'payment-failed': isFailed,
      'partial-payment': isPartialPayment
    }"
  >

    <b-loading :is-full-page="false" :active.sync="isLoading" :can-cancel="false" />

    <div
      v-tabbable
      class="card-header"
      role="button"
      @click="toggleOpen"
      @keypress.enter="toggleOpen"
    >
      <div class="card-header-title is-block">
        <div
          :class="[
            'card-header-content is-mobile is-size-5 no-wrap-text',
            {
              'with-table-number': displayTableNumber,
              'with-name': displayEmployeeName,
            }
          ]"
        >
          <p>
            <span class="has-text-weight-bold">
              {{ order.orderDate | moment('h:mm A', order.store.timeZone) }}
            </span>
            <span class="has-text-grey">
              {{ order.orderDate | moment('z', order.store.timeZone) }}
            </span>
          </p>
          <p v-if="displayTableNumber" class="has-text-centered">
            <template v-if="order.tableNumberDisplayName">
              {{ order.tableNumberDisplayName }}
            </template>
            <span v-else class="has-text-grey-light">
              N/A
            </span>
          </p>
          <p v-if="displayEmployeeName" ref="nameContainer" class="text-ellipsis">
            <span ref="name" v-tippy="{ content: order.employeeName, onShow: () => showTooltip, }">
              <template v-if="order.employeeName">
                {{ order.employeeName }}
              </template>
              <span v-else class="has-text-grey-light">
                N/A
              </span>
            </span>
          </p>
          <p class="has-text-centered">{{ order.ticketNumber || order.orderId }}</p>
          <p class="has-text-centered">
            <template v-if="order.merchantOrderReference">
              {{ order.merchantOrderReference }}
            </template>
            <span v-else class="has-text-grey-light">
              N/A
            </span>
          </p>
          <p class="has-text-right">
            <b-icon v-if="isPartialPayment" icon="exclamation-triangle" class="mar-r-xs" />
            <b-icon v-if="isFailed" icon="exclamation-circle" class="mar-r-xs" />
            {{ order.totalWithTipAndCharges | dollars }}
          </p>
          <b-icon class="open-indicator" :icon="isOpen ? 'angle-down' : 'angle-right'" />
        </div>
      </div>
    </div>

    <div v-show="isOpen" class="card-content">
      <!--== Payment Warning Message ==-->
      <b-message
        v-if="isFailed || isPartialPayment"
        class="has-shadow mar-b-lg mar-t-sm has-text-weight-bold is-compact"
        :type="isFailed ? 'is-danger' : 'is-warning'"
      >
        <p class="is-flex align-center">
          <b-icon
            :icon="isFailed ? 'exclamation-circle' : 'exclamation-triangle'"
            custom-size="fa-lg"
            class="mar-r-sm"
          />
          <span>{{ isFailed ? 'Payment Failed' : 'Partial Payment' }}</span>
        </p>
      </b-message>

      <!--== Customer Info ==-->
      <div v-if="order.guestName || order.email || order.phoneNumber" class="card-section card-section--expandable">
        <div
          v-tabbable
          class="card-section-header"
          role="button"
          @click="toggleOpenCustomerInfo"
          @keypress.enter="toggleOpenCustomerInfo"
        >
          <div>
            <b-icon icon="user" type="is-grey" size="is-small" class="mar-r-sm" />
            <span class="title is-5">{{ order.guestName || 'No Name' }}</span>
          </div>

          <b-icon :icon="customerInfoIsOpen ? 'angle-down' : 'angle-right'" type="is-grey" />
        </div>

        <div v-show="customerInfoIsOpen" class="card-section-content">
          <div class="customer-info">

            <p class="email">
              <b-icon class="mar-r-sm" icon="envelope" type="is-grey" size="is-small" />
              <a v-if="order.email" class="link" :href="'mailto:' + order.email">{{ order.email }}</a>
              <span v-else>N/A</span>
            </p>

            <p class="phone">
              <b-icon class="mar-r-sm" icon="phone" type="is-grey" size="is-small" />
              <a v-if="order.phoneNumber" class="link no-wrap-text" :href="'tel:1-' + order.phoneNumber">{{ order.phoneNumber | phoneNumber }}</a>
              <span v-else>N/A</span>
            </p>

          </div>
        </div>
      </div>

      <!--== Order File Attachment ==-->
      <div v-if="order.attachmentUrl" class="card-section">
        <p class="attachment">
          <b-icon class="mar-r-sm" icon="paperclip" type="is-grey" size="is-small" />
          <a :href="order.attachmentUrl" target="_blank">
            <span>View Attachment</span>
            <b-icon class="mar-l-xs" icon="external-link" size="is-small" />
          </a>
        </p>
      </div>

      <!--== Order Items ==-->
      <div v-if="order.orderItems.length > 0" class="card-section card-section--expandable">
        <div
          v-tabbable
          class="card-section-header"
          role="button"
          @click="toggleOrderItems"
          @keypress.enter="toggleOrderItems"
        >
          <div class="level is-mobile is-marginless">
            <b-icon icon="list-alt" type="is-grey" size="is-small" class="mar-r-sm" />
            <p class="title is-5 is-marginless">Order Items</p>
          </div>

          <b-icon :icon="showOrderItems ? 'angle-down' : 'angle-right'" type="is-grey" />
        </div>
        <div v-if="showOrderItems" class="card-section-content">
          <order-details-items :order="order" is-larger-size />
        </div>
      </div>

      <!--== Cost Breakdown ==-->
      <div class="card-section">
        <div class="order-totals">
          <p class="title is-6 is-flex-space-between">
            <span class="has-text-grey has-text-weight-normal">Subtotal:</span>
            <span>{{ order.subtotal | dollars }}</span>
          </p>

          <order-fees
            v-if="order.orderFees.length"
            :order-fees="order.orderFees"
            custom-classes="title mar-b-sm"
            show-colon
          />

          <p class="title is-6 is-flex-space-between">
            <span class="has-text-grey has-text-weight-normal">Tax:</span>
            <span>{{ order.tax | dollars }}</span>
          </p>

          <p class="title is-6 is-flex-space-between">
            <span class="has-text-grey has-text-weight-normal">Discount:</span>
            <span>{{ order.discount | dollars }}</span>
          </p>

          <p class="title is-6 is-flex-space-between">
            <span class="has-text-grey has-text-weight-normal">Tip:</span>
            <span>{{ order.tip | dollars }}</span>
          </p>

          <p v-if="order.serviceCharges" class="title is-6 is-flex-space-between">
            <span class="has-text-grey has-text-weight-normal">Service Charges:</span>
            <span>{{ order.serviceCharges | dollars }}</span>
          </p>

          <p v-if="order.shipmentFee" class="title is-6 is-flex-space-between">
            <span class="has-text-grey has-text-weight-normal">Shipment Fee:</span>
            <span>{{ order.shipmentFee | dollars }}</span>
          </p>

          <template v-if="order.fulfillmentType === fulfillmentTypes.DELIVERY">
            <template v-if="order.delivery">
              <p class="title is-6 is-flex-space-between">
                <span class="has-text-grey has-text-weight-normal">Delivery Charge</span>
                <span>{{ order.delivery.fee ? order.delivery.fee : 0 | dollars }}</span>
              </p>
              <p class="title is-6 is-flex-space-between">
                <span class="has-text-grey has-text-weight-normal">Delivery Tip</span>
                <span>{{ order.delivery.tip ? order.delivery.tip : 0 | dollars }}</span>
              </p>
            </template>
            <template v-else>
              <p class="title is-6 is-flex-space-between">
                <span class="has-text-grey has-text-weight-normal">Delivery Charge</span>
                <span>{{ order.deliveryCharges | dollars }}</span>
              </p>
              <p class="title is-6 is-flex-space-between">
                <span class="has-text-grey has-text-weight-normaly">Delivery Tip</span>
                <span>{{ 0 | dollars }}</span>
              </p>
            </template>
          </template>

          <p class="title is-5 total mar-t">
            <span class="has-text-grey has-text-weight-bold">Total:</span>
            <span class="is-size-4">
              {{ order.totalWithTipAndCharges | dollars }}
            </span>
          </p>
        </div>
      </div>

      <!--== Payment Info ==-->
      <div v-if="order.payments && order.payments.length" class="card-section card-section--expandable">
        <div
          v-tabbable
          class="card-section-header"
          role="button"
          @click="toggleOpenPayments"
          @keypress.enter="toggleOpenPayments"
        >
          <div>
            <b-icon
              pack="far"
              icon="credit-card"
              type="is-grey"
              size="is-small"
              class="mar-r-sm"
            />
            <span class="title is-5 is-marginless">Payment Info</span>
          </div>

          <b-icon :icon="paymentsAreOpen ? 'angle-down' : 'angle-right'" type="is-grey" />
        </div>

        <div v-show="paymentsAreOpen" class="card-section-content">
          <ul class="payments">
            <li v-for="payment in order.payments" :key="payment.id" class="payment">
              <div class="mar-r">
                <h4 class="has-text-weight-bold is-uppercase mar-r is-flex align-center">
                  <img
                    v-if="payment.paymentType.toLowerCase() === 'applepay'"
                    class="image is-inline is-48x48"
                    src="/images/payments/apple-pay-mark.svg"
                    alt="Apple Pay"
                    title="Apple Pay"
                  >
                  <img
                    v-else-if="payment.paymentType.toLowerCase() === 'googlepay'"
                    class="image is-inline is-48x48"
                    src="/images/payments/google-pay-mark.svg"
                    alt="Google Pay"
                    title="Google Pay"
                  >
                  <template v-else>
                    <img
                      v-if="payment.cardType.toLowerCase() === 'visa'"
                      class="image is-inline is-48x48"
                      src="/images/payments/cards/visa.svg"
                      alt="Visa"
                      title="Visa"
                    >
                    <img
                      v-else-if="['amex', 'american-express'].some(cardType => payment.cardType.toLowerCase() === cardType)"
                      class="image is-inline is-48x48"
                      src="/images/payments/cards/american-express.svg"
                      alt="American Express"
                      title="American Express"
                    >
                    <img
                      v-else-if="['mc', 'mastercard'].some(cardType => payment.cardType.toLowerCase() === cardType)"
                      class="image is-inline is-48x48"
                      src="/images/payments/cards/mastercard.svg"
                      alt="Master Card"
                      title="Master Card"
                    >
                    <img
                      v-else-if="['discover', 'disc'].some(cardType => payment.cardType.toLowerCase() === cardType)"
                      class="image is-inline is-48x48"
                      src="/images/payments/cards/discover.svg"
                      alt="Discover"
                      title="Discover"
                    >
                    <img
                      v-else-if="payment.cardType.toLowerCase() === 'diners-club'"
                      class="image is-inline is-48x48"
                      src="/images/payments/cards/diners-club.svg"
                      alt="Diners Club"
                      title="Diners Club"
                    >
                    <img
                      v-else-if="payment.cardType.toLowerCase() === 'jcb'"
                      class="image is-inline is-48x48"
                      src="/images/payments/cards/jcb.svg"
                      alt="JCB"
                      title="JCB"
                    >
                    <img
                      v-else-if="payment.cardType.toLowerCase() === 'giftcard'"
                      class="image is-inline is-48x48"
                      src="/images/payments/cards/gift-card.svg"
                      alt="Giftcard"
                      title="Giftcard"
                    >
                    <span v-else>{{ payment.cardType }}</span>
                  </template>
                  <template v-if="payment.cardNumberLastFour">
                    <span class="mar-x-xs">••••</span>
                    <span>{{ payment.cardNumberLastFour }}</span>
                  </template>
                </h4>
                <p class="has-text-grey">
                  {{ payment.transactionDateTime | moment('MMMM D, YYYY @ h:mm A', order.store.timeZone) }}
                </p>
              </div>
              <div class="has-text-weight-bold is-size-5">
                {{ payment.amount | dollars }}
              </div>
            </li>
          </ul>
        </div>
      </div>

      <!--== Special Instructions ==-->

      <div v-if="order.internalNote || order.orderLevelInstructions " class="card-section is-paddingless">
        <b-message
          type="is-primary"
          has-icon
          icon="star-exclamation"
          icon-size="is-medium"
          :closable="false"
          title="Special Instructions"
        >
          <span v-if="order.orderLevelInstructions"><b>External:</b> {{ order.orderLevelInstructions }} </span>
          <br v-if="order.orderLevelInstructions && order.internalNote ">
          <span v-if="order.internalNote"><b>Internal:</b> {{ order.internalNote }} </span>
        </b-message>
      </div>


      <div class="buttons all-bold is-centered">
        <!-- CANCEL -->
        <b-button
          v-if="(isDemo || $can('cancel_orders', 'StoreMapping')) && order.opdStatus === 'PaymentRequested'"
          rounded
          icon-left="times"
          icon-size="is-small"
          type="is-danger"
          @click="confirmCancelPaymentRequest"
        >
          Cancel Request
        </b-button>

        <!-- (RE)REQUEST -->
        <b-button
          v-if="(isDemo || $can('request_payment', 'StoreMapping')) && ['Open', 'PaymentRequested'].includes(order.opdStatus)"
          rounded
          icon-left="dollar-sign"
          icon-size="is-small"
          type="is-primary"
          @click="openOpdOrderModal(order)"
        >
          {{ order.opdStatus === 'PaymentRequested' ? 'Re-request' : 'Request' }} Payment
        </b-button>

        <!-- MARK COMPLETED -->
        <b-button
          v-else-if="(isDemo || $can('update_order_prep_status', 'StoreMapping')) && order.opdStatus === 'Paid'"
          rounded
          icon-left="check"
          icon-size="is-small"
          type="is-success"
          @click="markCompleted"
        >
          Mark Completed
        </b-button>
      </div>
    </div>
  </div>
</template>



<script>
  import Order from '@/store/classes/OpdOrder';
  import opdOrderModal from './opd-order-modal.vue';
  import events from '@/services/events';
  import fulfillmentTypes from '@/constants/fulfillmentTypes';
  import orderDetailsItems from '@/components/globals/order-details-items.vue';
  import orderFees from '@/components/globals/order-fees.vue';
  import alertModal from '@/components/globals/alert-modal.vue';



  export default {
    name: 'OpdOrder',

    components: { orderDetailsItems, orderFees },

    props: {
      isDemo: {
        type: Boolean,
        default: false
      },

      isOpen: {
        type: Boolean,
        default: false
      },

      displayTableNumber: {
        type: Boolean,
        default: false
      },

      displayEmployeeName: {
        type: Boolean,
        default: false
      },

      order: {
        type: Object,
        required: true
      }
    },

    data() {
      return {
        customerInfoIsOpen: false,
        isUpdating: false,
        paymentsAreOpen: false,
        showOrderItems: false,
        showTooltip: false,
        fulfillmentTypes
      };
    },

    computed: {
      isFailed() {
        return this.order.paymentState === 'Failed';
      },

      isPartialPayment() {
        return this.order.paymentState === 'PartiallyAuthorized';
      },

      isFetching() {
        return Order.$state().fetchingSingleId === this.order.orderId;
      },

      isLoading() {
        return this.isUpdating || this.isFetching;
      }
    },

    watch: {
      isOpen: {
        handler: 'isOpenChangeHandler',
        immediate: true
      }
    },

    mounted() {
      this.onMounted();
    },

    destroyed() {
      this.onDestroyed();
    },

    methods: {
      onMounted() {
        window.addEventListener('resize', this.setTooltipVisibility);
      },

      onDestroyed() {
        window.removeEventListener('resize', this.setTooltipVisibility);
      },

      setTooltipVisibility() {
        this.showTooltip = this.$refs.nameContainer.offsetWidth < this.$refs.name.offsetWidth;
      },

      confirmCancelPaymentRequest() {
        this.$buefy.modal.open({
          parent: this,
          component: alertModal,
          hasModalCard: true,
          trapFocus: true,
          canCancel: ['escape', 'outside'],
          customClass: 'auto-width',
          props: {
            buttons: [
              { text: 'No' },
              {
                text: 'Yes, Cancel Request',
                primary: true,
                onClick: async () => {
                  await this.cancelPaymentRequest();
                }
              }
            ],
            horizontal: true,
            showCloseButton: false,
            icon: 'exclamation-triangle',
            title: 'Cancel Payment Request',
            message: 'Canceling this payment request will invalidate any payment request links that have been sent out. This cannot be undone.',
            secondaryMessage: 'Are you sure you want to cancel this request?',
            type: 'is-danger'
          }
        });
      },

      async cancelPaymentRequest() {
        try {
          await Order.cancelOrder(this.order.orderId);
          this.$_onRequestSuccess({
            toastOptions: { message: 'Payment request canceled' }
          });
        }
        catch (error) {
          this.$_onRequestError({
            toastOptions: { message: 'Failed to cancel payment request' },
            error
          });
        }
      },

      openOpdOrderModal(order) {
        const { orderId, store } = order;
        this.$buefy.modal.open({
          parent: this,
          component: opdOrderModal,
          hasModalCard: true,
          trapFocus: true,
          canCancel: ['escape'],
          customClass: 'auto-width',
          props: {
            orderId,
            storeId: store.storeId,
            mode: 'update'
          }
        });
      },

      async markCompleted() {
        try {
          this.isUpdating = true;

          const updatedOrder = await Order.updateOrder(this.order.orderId, this.order.store.storeId, {
            preparationStatus: 'Completed'
          });

          this.$_onRequestSuccess({
            toastOptions: { message: 'Order Completed!' }
          });

          events.$emit('opd:order-updated', new Order(updatedOrder));
        }

        catch (error) {
          this.$_onRequestError({
            toastOptions: { message: 'Failed to update order' },
            error
          });
        }

        finally {
          this.isUpdating = false;
        }
      },

      isOpenChangeHandler() {
        if (!this.isOpen) {
          this.customerInfoIsOpen = true;
          this.showOrderItems = false;
          this.paymentsAreOpen = false;
        }
      },

      toggleOpen() {
        this.$emit('toggle-open', this.order.orderId);
      },

      toggleOpenCustomerInfo() {
        this.customerInfoIsOpen = !this.customerInfoIsOpen;
      },

      toggleOrderItems() {
        this.showOrderItems = !this.showOrderItems;
      },

      toggleOpenPayments() {
        this.paymentsAreOpen = !this.paymentsAreOpen;
      }
    }
  };
</script>



<style lang="sass" scoped>
  .card
    position: relative
    border-color: $grey-lightest
    transition: 200ms
    animation: showOrder 600ms
    overflow: hidden

    &:last-child
      border-bottom-right-radius: $radius-large
      border-bottom-left-radius: $radius-large

    &.is-open
      z-index: 10
      box-shadow: 0 0 40px 10px rgba(0, 0, 0, 0.3), 0 0 3px 3px $grey-lighter
      border-color: $grey
      transform: scaleX(1.015)
      border-radius: $radius-large

    &.partial-payment
      .card-header
        background-color: lighten($warning, 28)
        &:hover,
        &:focus
          background-color: lighten($warning, 24)

      .card-header-title
        color: darken($warning, 48) !important

    &.payment-failed
      .card-header
        background-color: lighten($danger, 34)
        &:hover,
        &:focus
          background-color: lighten($danger, 30)

      .card-header-title
        color: darken($danger, 35) !important

  .card-header
    cursor: pointer
    box-shadow: none
    position: relative
    padding: 0.5rem

    &:hover,
    &:focus
      outline: none
      background-color: $grey-lightest

  .card-header-title
    font-weight: 400

  .card-header-content
    display: grid
    grid-template-columns: minmax(150px, 2fr)  repeat(2, minmax(90px, 1fr)) minmax(90px, 0.5fr) 40px
    gap: $size-small
    align-items: center
    &.with-name
      grid-template-columns: minmax(150px, 1fr) minmax(50px, 1fr) repeat(2, minmax(90px, 1fr)) minmax(90px, 0.5fr) 40px
    &.with-table-number
      grid-template-columns: minmax(150px, 1fr) 120px minmax(50px, 1fr) minmax(90px, 1fr) minmax(90px, 0.5fr) 40px
    &.with-name.with-table-number
      grid-template-columns: minmax(150px, 1fr) 120px minmax(50px, 1fr) repeat(2, minmax(90px, 1fr)) minmax(90px, 0.5fr) 40px

  .card-content
    border-top: 1px solid $grey-lighter
    background: $light
    padding: $size-small

  .card-section
    margin: 0 0 $size-small
    padding: $size-small
    background: #fff
    border-radius: $radius
    border: 1px solid $grey-lighter

    &.has-background-grey-dark
      border-color: $black

  .card-section--expandable
    padding: 0

  .card-section-header
    cursor: pointer
    padding: $size-small
    display: flex
    justify-content: space-between
    align-items: center

    &:active,
    &:hover
      > .icon
        color: $black !important

    &:focus
      outline: none
      box-shadow: 0 0 0 0.125em rgba(0, 153, 255, 0.25), inset -1px -1px $primary, inset 1px 1px $primary

    &:active
      box-shadow: none

  .card-section-content
    border-top: 1px solid $grey-lighter
    padding: $size-small

  .customer-info
    p:not(:last-child)
      margin-bottom: 0.5rem

  .order-totals
    .total
      align-items: flex-end

    .title
      display: flex
      margin: 0 0 $size-small

      span:first-child
        flex-grow: 1

      &:last-child
        margin: 0

  .modal-card
    max-width: 400px
    margin: 0 auto !important

  .loading-overlay
    z-index: 10

  .open-indicator
    justify-self: end

  .payment
    display: flex
    justify-content: space-between
    align-items: center

    &:not(:last-child)
      margin-bottom: 1rem

  .transactions
    & > *:not(:last-child)
      margin-bottom: 0.5rem


  @media (max-width: $mobile)
    .card-header-title
      .card-header-content.is-mobile
        display: grid
        grid-template-columns: 2fr 1fr 1fr
        grid-gap: 1rem

      .level-item
        margin-right: 0 !important

      .open-indicator
        display: none

  @keyframes showOrder
    0%
      opacity: 0

    100%
      opacity: 1
</style>
