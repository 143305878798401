/* eslint-disable import/no-cycle */
import { Model } from '@vuex-orm/core';
import http from '@/services/http';
import filterObjectKeys from '@/helpers/filter-object-keys';

export default class RegionMenuItem extends Model {
  static entity = 'regionMenuItems'

  // FIELDS //////////////////////

  static fields() {
    return {
      id: this.attr(null),
      menuRegionId: this.attr(null),
      menuItemId: this.attr(null),
      menuTypeId: this.attr(null),
      isExcluded: this.attr(true),
      createdDate: this.attr(null),
      modifiedDate: this.attr(null)
    };
  }



  // ACTIONS //////////////////////

  static async addRegionMenuItem(regionMenuItem) {
    try {
      const acceptedKeys = ['menuRegionId', 'menuItemId', 'menuTypeId', 'isExcluded'];
      const { data } = await http.post(`region_menus/${regionMenuItem.menuRegionId}/region_menu_items`, {
        regionMenuItem: filterObjectKeys(regionMenuItem, acceptedKeys)
      });

      this.insert({ data: data.regionMenuItem });
    }

    catch (error) {
      throw error;
    }
  }

  static async bulkAddRegionMenuItems(regionMenuId, regionMenuItems) {
    try {
      const acceptedKeys = ['menuRegionId', 'menuItemId', 'menuTypeId', 'isExcluded'];
      const { data } = await http.post(`region_menus/${regionMenuId}/region_menu_items/bulk_create`, {
        regionMenuItems: regionMenuItems.map(regionMenuItem => filterObjectKeys(regionMenuItem, acceptedKeys))
      });

      this.insert({ data: data.regionMenuItems });
    }

    catch (error) {
      throw error;
    }
  }

  static async deleteRegionMenuItem(regionMenuItemId) {
    try {
      await http.delete(`region_menu_items/${regionMenuItemId}`);
      this.delete(regionMenuItemId);
    }

    catch (error) {
      throw error;
    }
  }

  static async bulkDeleteRegionMenuItems(regionMenuItemIds) {
    try {
      await http.delete(`region_menu_items/bulk_destroy?region_menu_item_ids=[${regionMenuItemIds}]`);
      regionMenuItemIds.forEach((id) => {
        this.delete(id);
      });
    }

    catch (error) {
      throw error;
    }
  }
}
