const modifierGroupTypes = {
  PORTION: 'portion',
  COMBO: 'combo',
  NEW: 'new',
  EXISTING: 'existing'
};

const modifierGroupPortionTemplates = {
  FULL_GROUP_TEMPLATE: {
    constant: 'FullGroupTemplate',
    display: 'Full'
  },
  HALF_GROUP_TEMPLATE: {
    constant: 'HalfGroupTemplate',
    display: 'Halves'
  }
  // ONLY SUPPORTING FIRST 2 FOR NOW
  // THIRD_GROUP_TEMPLATE: {
  //   constant: 'ThirdGroupTemplate',
  //   display: 'Thirds'
  // },
  // QUARTER_GROUP_TEMPLATE: {
  //   constant: 'QuarterGroupTemplate',
  //   display: 'Quarters'
  // }
};

export {
  modifierGroupTypes,
  modifierGroupPortionTemplates
};
