<template>
  <div class="is-grid col-min-300 gap-lg">
    <template v-if="mode === 'create'">
      <validated-input
        name="paymentGateways"
        :rules="{ required: mode === 'create' }"
        label="Payment Provider(s)"
      >
        <b-dropdown
          :value="form.paymentGatewayIds"
          class="has-extra-shadow"
          expanded
          multiple
          scrollable
          max-height="375"
          position="is-top-left"
          @input="handleInput('paymentGatewayIds', $event)"
        >
          <dropdown-button
            slot="trigger"
            placeholder="Select a Payment Provider..."
            :value="!!form.paymentGatewayIds.length && `Selected (${form.paymentGatewayIds.length})`"
            class="wrap-text auto-height"
            :loading="isFetchingMerchantPaymentGateways"
            :has-errors="!!(errors.paymentGateways && errors.paymentGateways.length)"
          />
          <b-dropdown-item
            v-for="(gateway, index) in selectedMerchantPaymentGatewayOptions"
            :key="`gateway-${gateway.id}-${index}`"
            :value="gateway.id"
          >
            {{ gateway.name }}
          </b-dropdown-item>
        </b-dropdown>
      </validated-input>
    </template>

    <template v-else>
      <transition name="fade-left">
        <validation-provider
          v-slot="{errors: paymentGateWayErrors}"
          name="paymentGateways"
          rules="required"
        >
          <b-checkbox :value="!invalidPaymentGateways.length || null" :class="['is-hidden', { 'invalid': paymentGateWayErrors.length }]" />
          <b-field
            label-for="paymentGateways"
            label="Payment Provider(s)"
            :message="paymentGateWayErrors.length && invalidPaymentGateways.length ? `You must have at least 1 Payment Method selected for ${invalidPaymentGateways.join(' and ')}`: ''"
            :type="paymentGateWayErrors.length ? 'is-danger' : ''"
          >
            <b-message
              v-if="!selectedMerchantPaymentGatewayOptions.length"
              class="is-compact has-shadow is-inline-block is-full-width"
              type="is-warning"
            >
              There are no Payment Providers configured
            </b-message>
            <b-dropdown
              v-else
              v-model="selectedPaymentMethodIds"
              expanded
              multiple
              max-height="400px"
              aria-role="list"
              position="is-bottom-right"
              inline
              :scrollable="$can('manage', 'all')"
              :class="{'is-read-only': !$can('manage', 'all')}"
              :disabled="!$can('manage', 'all')"
            >
              <template>
                <b-dropdown-item v-if="isFetchingMerchantPaymentGateways" custom>
                  <b-icon icon="spinner-third" class="spin mar-r" />
                  Loading...
                </b-dropdown-item>

                <template v-for="{ paymentGateway, availablePaymentMethods, id } in selectedMerchantPaymentGatewayOptions" v-else>
                  <div v-if="availablePaymentMethods.length" :key="id" class="payment-provider-group">

                    <b-dropdown-item tabindex="false" class="merchant-name title is-6 is-marginless" custom>
                      {{ paymentGateway.name }}
                    </b-dropdown-item>

                    <b-dropdown-item
                      v-for="paymentMethod in availablePaymentMethods"
                      :key="paymentMethod.id"
                      aria-role="listitem"
                      :value="`${id}-${paymentMethod.id}`"
                      :disabled="getDisabledForPaymentMethod({ methodName: paymentMethod.name, gatewayName: paymentGateway.name }) || availablePaymentMethods.length === 1"
                    >
                      <span class="mar-l">
                        {{ getPaymentMethodDisplay(paymentMethod.name) }}
                      </span>
                    </b-dropdown-item>
                  </div>
                  <div v-else :key="id" class="payment-provider-group">
                    <b-dropdown-item tabindex="false" class="merchant-name title is-6 is-marginless" custom>
                      {{ paymentGateway.name }}
                    </b-dropdown-item>

                    <b-dropdown-item
                      :key="`${id}-none`"
                      class="none-dropdown-item"
                      aria-role="listitem"
                      :value="null"
                      custom
                    >
                      <span class="mar-l">
                        No Payment Methods Available
                      </span>
                    </b-dropdown-item>
                  </div>
                </template>
              </template>
            </b-dropdown>
          </b-field>
        </validation-provider>
      </transition>

      <validated-input
        v-if="storePaymentProviders.length"
        class="mar-b-lg"
        name="storePaymentProviders"
        label="Location-specific Payment Provider(s)"
      >
        <b-dropdown
          :value="storePaymentProviders"
          class="is-read-only"
          disabled
          expanded
          multiple
          inline
          position="is-top-left"
        >
          <b-dropdown-item v-if="isFetchingStores" custom>
            <b-icon icon="spinner-third" class="spin mar-r" />
            Loading...
          </b-dropdown-item>
          <template v-else>
            <b-dropdown-item
              v-for="{ provider, storeId, storeName } in storePaymentProviders"
              :key="`${storeId}-${provider.id}-${provider.paymentGateway.id}`"
              :value="provider"
            >
              <span class="has-text-weight-bold">{{ provider.paymentGateway.name }}</span> - {{ storeName }}
            </b-dropdown-item>
          </template>
        </b-dropdown>
      </validated-input>
    </template>

    <validated-input
      name="posProviders"
      label="Point Of Sale Provider(s)"
      :rules="{ required: mode === 'create' }"
    >
      <b-message
        v-if="mode === 'update' && !selectedMerchantPOSOptions.length"
        class="is-compact has-shadow is-inline-block is-full-width"
        type="is-warning"
      >
        There are no POS Providers configured
      </b-message>
      <b-dropdown
        v-else
        :value="form.posProviderIds"
        :class="{
          'is-read-only': mode === 'update',
          'has-extra-shadow': mode === 'create'
        }"
        :disabled="mode === 'update'"
        expanded
        multiple
        scrollable
        max-height="375"
        :inline="mode === 'update'"
        position="is-top-left"
        @input="handleInput('posProviderIds', $event)"
      >
        <dropdown-button
          slot="trigger"
          placeholder="Select a POS Provider..."
          :value="!!form.posProviderIds.length && `Selected (${form.posProviderIds.length})`"
          class="wrap-text auto-height"
          :loading="isFetchingPosTypes"
          :has-errors="!!(errors.posProviders && errors.posProviders.length)"
        />
        <template v-if="mode === 'create'">
          <b-dropdown-item
            v-for="(posType, index) in selectedMerchantPOSOptions"
            :key="`posType-${posType.id}-${index}`"
            :value="posType.id"
          >
            {{ posType.name }}
          </b-dropdown-item>
        </template>
        <template v-else>
          <b-dropdown-item
            v-for="({ posType, storeName }, index) in selectedMerchantPOSOptions"
            :key="`posType-${posType.id}-${index}`"
            :value="posType.id"
          >
            <b>{{ posType.name }}</b>
            <template v-if="storeName">
              - {{ storeName }}
            </template>
          </b-dropdown-item>
        </template>
      </b-dropdown>
    </validated-input>
  </div>
</template>



<script>
  import PosTypeOption from '@/store/classes/PosTypeOption';
  import PaymentGateway from '@/store/classes/PaymentGateway';
  import MerchantPaymentGateway from '@/store/classes/MerchantPaymentGateway';
  import Merchant from '@/store/classes/Merchant';
  import Store from '@/store/classes/Store';
  import paymentMethods from '@/constants/paymentMethods';
  import capitalCase from '@/helpers/capitalCase';

  export default {
    name: 'MerchantProviderInputs',

    props: {
      mode: {
        type: String,
        default: 'create',
        validator(value) {
          return ['create', 'read', 'update'].includes(value);
        }
      },

      errors: {
        type: Object,
        default: () => ({})
      }
    },

    data() {
      return {
        selectedPaymentMethodIds: []
      };
    },

    computed: {
      form() {
        const { editableMerchant } = Merchant.$state();
        return JSON.parse(JSON.stringify(editableMerchant));
      },

      stores() {
        return Store.all();
      },

      isFetchingStores() {
        return Store.$state().fetchingAll;
      },

      posTypeOptions() {
        return PosTypeOption.all();
      },

      invalidPaymentGateways() {
        const invalidPaymentGateways = this.selectedMerchantPaymentGatewayOptions.reduce((acc, spg) => {
          const gatewayPaymentMethod = this.selectedPaymentMethodIds.find((id) => {
            const gatewayId = Number(id.split('-')[0]);
            return gatewayId === spg.id;
          });
          if (!gatewayPaymentMethod) {
            acc.push(spg.paymentGateway.name);
          }
          return acc;
        }, []);
        return invalidPaymentGateways;
      },

      isFetchingPosTypes() {
        return PosTypeOption.$state().fetching;
      },

      allPaymentGatewayOptions() {
        return PaymentGateway.all();
      },

      isFetchingPaymentGateways() {
        return PaymentGateway.$state().fetching;
      },

      isFetchingMerchantPaymentGateways() {
        return MerchantPaymentGateway.$state().fetching;
      },

      selectedMerchantPaymentGatewayOptions() {
        if (this.mode === 'create') {
          return this.allPaymentGatewayOptions;
        }
        return this.form.merchantPaymentGateways.filter(mpg => !mpg.storeId);
      },

      selectedMerchantPOSOptions() {
        if (this.mode === 'create') {
          return this.posTypeOptions;
        }

        return this.$clone(this.form.posConfigurations)
          .sort(a => ((a.storeId) ? 1 : -1))
          .map((posConfig) => {
            const store = this.stores.find(s => s.storeId === posConfig.storeId);
            return {
              ...posConfig,
              storeName: store?.description
            };
          });
      },

      formPaymentMethods() {
        return this.selectedPaymentMethodIds.reduce((acc, id) => {
          const merchantGatewayId = Number(id.split('-')[0]);
          const methodId = Number(id.split('-')[1]);
          if (acc[merchantGatewayId]) {
            acc[merchantGatewayId].push(methodId);
          }
          else {
            acc[merchantGatewayId] = [methodId];
          }
          return acc;
        }, {});
      },

      storePaymentProviders() {
        return this.form.merchantPaymentGateways.filter(mpg => mpg.storeId).map((gateway) => {
          const store = this.stores.find(s => s.storeId === gateway.storeId);
          return {
            storeId: store?.storeId,
            storeName: store?.description,
            provider: gateway
          };
        });
      }
    },

    watch: {
      selectedPaymentMethodIds: 'updateMerchantFormWithPaymentMethods'
    },

    created() {
      this.onCreated();
    },

    methods: {
      onCreated() {
        if (this.mode === 'create') {
          this.fetchPaymentGateways();
          this.fetchPosTypes();
        }
        else {
          this.initializeSelectedPaymentMethods();
        }
      },

      handleInput(field, value) {
        Merchant.setEditableMerchant({ [field]: value });
      },

      getDisabledForPaymentMethod({ methodName, gatewayName }) {
        // If Square --> Nonce at min should be selected. User should not be able to unselect this.
        if (gatewayName === 'Square' && methodName === paymentMethods.NONCE.type) {
          return true;
        }

        return false;
      },

      updateMerchantFormWithPaymentMethods() {
        this.handleInput('paymentMethodsByGateway', this.formPaymentMethods);
      },

      getPaymentMethodDisplay(method) {
        const constant = Object.keys(paymentMethods).find(pm => paymentMethods[pm].type === method);
        return paymentMethods[constant]?.display || capitalCase(method);
      },

      initializeSelectedPaymentMethods() {
        this.selectedMerchantPaymentGatewayOptions.forEach((pgo) => {
          pgo.paymentMethods.forEach((pm) => {
            this.selectedPaymentMethodIds.push(`${pgo.id}-${pm.id}`);
          });
        });
      },

      async fetchPosTypes() {
        try {
          await PosTypeOption.fetchPosTypes();
        }
        catch (error) {
          this.$_onRequestError({ toastOptions: { message: 'Unable to fetch pos types' }, error });
        }
      },

      async fetchPaymentGateways() {
        try {
          await PaymentGateway.fetchPaymentGateways();
        }
        catch (error) {
          this.$_onRequestError({ toastOptions: { message: 'Unable to fetch payment providers' }, error });
        }
      }
    }
  };
</script>

<style lang="sass" scoped>
  .payment-provider-group
    padding: 0.5rem 0
    &:not(:first-child)
      border-top: 1px solid $grey-lighter
</style>
