<template>
  <div>
    <b-loading :is-full-page="false" :active="!_merchant" />
    <tabs-page
      v-if="_merchant && tabs.length"
      :tabs="tabs"
      :keep-alive="false"
      :title="merchant ? merchant.name : 'Merchant Configuration'"
    >
      <template v-if="merchant" #header-action>
        <div class="dist-x">
          <b-button
            rounded
            inverted
            type="is-primary"
            size="is-medium"
            :disabled="isUpdatingActivationStatus"
            @click="selectMerchant"
          >
            Select
          </b-button>

          <b-button
            v-if="$can('manage', 'all')"
            rounded
            :type="merchant.isActive ? 'is-danger is-light' : 'is-success is-light'"
            size="is-medium"
            :icon-left="merchant.isActive ? 'ban' : 'bolt'"
            :loading="isUpdatingActivationStatus"
            @click="$_confirmUpdateMerchantActivation(merchant)"
          >
            {{ merchant.isActive ? 'Deactivate' : 'Activate' }}
          </b-button>
        </div>
      </template>
    </tabs-page>
  </div>
</template>

<script>
  import merchantMixin from '@/mixins/merchant';
  import deactivateMerchantMixin from '@/mixins/deactivate-merchant';

  import { mapGetters } from 'vuex';
  import Merchant from '@/store/classes/Merchant';

  // tab components
  import merchantAuthorizationsTab from './tabs/merchant-authorizations-tab.vue';
  import merchantConfigsTab from './tabs/merchant-configs-tab.vue';
  import PaymentGateway from '@/store/classes/PaymentGateway';
  import MerchantAppSetting from '@/store/classes/MerchantAppSetting';
  import Store from '@/store/classes/Store';
  import AppCodeType from '@/store/classes/AppCodeType';
  import storeSpecialHours from '../stores/store-hours/store-special-hours.vue';
  import merchantTippingTab from './tabs/merchant-tipping-tab.vue';
  import merchantDetailsTab from './tabs/merchant-details-tab.vue';
  import merchantApplicationsTab from './tabs/merchant-applications-tab.vue';
  import merchantProviderSettingsTab from './tabs/merchant-provider-settings-tab.vue';
  import merchantFeesTab from './tabs/merchant-fees-tab.vue';
  import merchantGiftCardsTab from './tabs/merchant-gift-cards-tab.vue';
  import auditLogTab from './tabs/audit-log-tab.vue';

  import PosTypeOption from '@/store/classes/PosTypeOption';

  export default {
    name: 'MerchantConfiguration',

    mixins: [
      merchantMixin,
      deactivateMerchantMixin
    ],

    props: {
      merchant: {
        type: Object,
        default: null
      }
    },

    computed: {
      ...mapGetters('session', ['isCardfreeAdmin']),

      _merchant() {
        return this.merchant || this.$_selectedMerchant;
      },

      isUpdatingActivationStatus() {
        return !!Merchant.$state().submittingActivationChangeId;
      },

      canViewGiftCardsTab() {
        return this.$can('crud', 'MerchantGiftCardConfiguration') && this.$can('crud', 'MerchantGiftCardDesign') && this._merchant.features.giftCardManagement;
      },

      canViewProviderSettingsTab() {
        return this.$can('read', 'MerchantDeliveryService') && this._merchant.deliveryEnabled;
      },

      tabs() {
        return [
          {
            display: 'Merchant Details',
            name: 'details',
            component: merchantDetailsTab
          },
          {
            display: 'Applications',
            name: 'applications',
            component: merchantApplicationsTab
          },
          {
            display: 'Delivery Provider Settings',
            name: 'provider-settings',
            component: merchantProviderSettingsTab,
            isHidden: !this.canViewProviderSettingsTab
          },
          {
            display: 'Authorizations',
            name: 'authorizations',
            component: merchantAuthorizationsTab,
            isHidden: !this.$can('update', 'Merchant') || ![this._merchant.supportsSquareAuth, this._merchant.supportsUberEatsOauth].some(x => x)
          },
          {
            display: 'Special Hours',
            name: 'special-hours',
            component: storeSpecialHours,
            isHidden: !this.$can('update', 'Merchant')
          },
          {
            display: 'Tipping',
            name: 'tipping',
            component: merchantTippingTab
          },
          {
            display: 'Fees',
            name: 'merchant-fees',
            component: merchantFeesTab,
            isHidden: !this.$can('read', 'MerchantFee') || !this._merchant.supportsMerchantFees
          },
          {
            display: 'Gift Cards',
            name: 'gift-cards',
            component: merchantGiftCardsTab,
            isHidden: !this.canViewGiftCardsTab
          },
          {
            display: 'Audit Log',
            name: 'audit-log',
            component: auditLogTab,
            isHidden: !this.isCardfreeAdmin
          },
          {
            display: 'Configurations',
            name: 'configurations',
            component: merchantConfigsTab,
            isHidden: !this.isCardfreeAdmin
          }
        ].map(tab => ({
          ...tab,
          props: { merchant: this._merchant }
        }));
      }
    },

    mounted() {
      if (this.merchant) {
        this.onMerchantLoad();
      }
    },

    destroyed() {
      Merchant.setEditableMerchant(null);
    },

    methods: {
      selectMerchant() {
        Merchant.setSelectedMerchantId(this.$route.params.merchantId);
        this.$router.push({ name: 'home' });
      },

      onMerchantLoad() {
        if (this.isCardfreeAdmin) {
          this.fetchPaymentGateways();
          this.fetchAppCodeTypes();
          this.fetchPosTypes();
          this.fetchApplicationSettings();
        }
        this.fetchStores();
      },

      async fetchAppCodeTypes() {
        try {
          await AppCodeType.fetchAppCodeTypes();
        }
        catch (error) {
          this.$_onRequestError({
            toastOptions: { message: 'Unable to fetch App Code Types' },
            error
          });
        }
      },

      async fetchPaymentGateways() {
        try {
          await PaymentGateway.fetchPaymentGateways();
        }
        catch (error) {
          this.$_onRequestError({
            toastOptions: { message: 'Unable to fetch payment providers' },
            error
          });
        }
      },

      async fetchPosTypes() {
        try {
          await PosTypeOption.fetchPosTypes();
        }
        catch (error) {
          this.$_onRequestError({ toastOptions: { message: 'Unable to fetch pos types' }, error });
        }
      },

      async fetchApplicationSettings() {
        try {
          await MerchantAppSetting.fetchMerchantAppSettings(this._merchant.id);
        }
        catch (error) {
          this.$_onRequestError({ toastOptions: { message: 'Unable to fetch merchant application settings' }, error });
        }
      },

      async fetchStores() {
        try {
          await Store.fetchAll(this._merchant.id);
        }
        catch (error) {
          this.$_onRequestError({
            toastOptions: { message: 'Unable to fetch locations' },
            error
          });
        }
      }
    }
  };
</script>
