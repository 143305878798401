<template>
  <li
    :class="['draggable', {'is-open': isOpen}]"
    :data-item-id="menuItem.id"
  >
    <div :class="['row', { 'is-cloned-item': isClonedItem, 'sub-row': !isSearchRow }]">
      <span class="pad-y-sm">
        <b-icon
          v-if="canSortResource && !isSearchRow"
          icon="grip-lines"
          size="is-small"
          pack="far"
          class="drag-handle"
        />
        <div class="is-flex-direction-column">
          <span
            :class="['is-flex align-center', { 'link-inverted': !isSearchRow }]"
            @click="!isSearchRow && toggleItem()"
          >
            <div class="is-flex-column">
              <span class="has-text-weight-bold is-size-6">
                {{ menuItem.displayName }}
              </span>
              <span v-if="menuItem.menuCategories.length" class="is-size-7">
                <b>Category:</b> {{ menuItem.menuCategories[0].displayName }}
              </span>
            </div>
            <b-icon
              v-if="!isSearchRow"
              size="is-small"
              pack="far"
              :icon="fetchingItemData ? 'spinner-third' : 'angle-right'"
              :class="[
                'open-indicator',
                {
                  'is-open': isOpen,
                  'spin': fetchingItemData
                }
              ]"
            />
          </span>
        </div>
        <b-icon
          v-if="!menuItem.mappedToPos && !hasPosType(posTypes.Cardfree)"
          v-tippy="{ content: 'Missing POS Mapping', placement: 'right' }"
          size="is-small"
          icon="exclamation-triangle"
          type="is-danger"
          class="mar-l"
        />
      </span>
      <span />
      <span class="align-center justify-end">
        <b-button
          v-if="(menuItem.hasSchedule || menuItem.availabilityBeginDate || menuItem.availabilityEndDate) && !activeMenuType"
          v-tippy="{ content: 'Restricted Availability', placement: 'left', delay: [300, 0] }"
          class="is-transparent"
          :disabled="fetchingItemData"
          @click="openItemModal({ defaultTabId: 'availability-tab' })"
        >
          <b-icon
            pack="far"
            :icon="fetchingItemData ? 'spinner-third' : 'calendar'"
            :class="{
              'spin': fetchingItemData
            }"
          />
        </b-button>

        <template v-if="activeMenuType">
          <tippy
            v-if="$can('update', 'MenuItem') && menuPermissions.MANAGE_MENU_TYPE"
            placement="left"
            max-width="500"
          >
            <template #trigger>
              <b-button
                class="is-transparent"
                @click="confirmRemoveFromMenuType({ item: menuItem })"
                @contextmenu.prevent="confirmRemoveFromMenuType({ all: true })"
              >
                <b-icon
                  icon="minus-circle"
                  type="is-danger"
                  size="is-small"
                />
              </b-button>
            </template>
            <div class="is-size-6">
              Remove <span class="has-text-weight-semibold">{{ menuItem.displayName }}</span> from {{ activeMenuType.displayName }} Menu
            </div>
          </tippy>
        </template>
        <template v-else>
          <b-button
            class="is-transparent"
            :disabled="fetchingItemData"
            @click="openItemModal({})"
          >
            <b-icon
              v-if="$can('update', 'MenuItem') && menuPermissions.EDIT_RESOURCE"
              :icon="fetchingItemData ? 'spinner-third' : 'pencil'"
              :class="{
                'spin': fetchingItemData
              }"
              size="is-small"
            />
            <span v-else>View</span>
          </b-button>
          <b-dropdown
            v-if="[
              ($can('create', 'MenuItem') && menuPermissions.MANAGE_DAYPART_SCHEDULE),
              ($can('destroy', 'MenuItem') && menuPermissions.REMOVE_RESOURCE)
            ].some(x => x)"
            aria-role="list"
            position="is-bottom-left"
            :mobile-modal="false"
            :disabled="fetchingItemData"
          >
            <b-button
              slot="trigger"
              class="is-transparent"
              type="is-white"
            >
              <b-icon
                icon="ellipsis-v"
                pack="far"
                size="is-small"
              />
            </b-button>
            <b-dropdown-item @click="openCategoryAssociationModal()">
              <b-icon
                icon="layer-group"
                class="mar-r-sm"
                size="is-small"
              />
              Manage Category Associations
            </b-dropdown-item>
            <b-dropdown-item
              v-if="$can('create', 'MenuItem') && menuPermissions.CLONE_ITEM"
              @click="openDuplicateMenuItemModal()"
            >
              <b-icon
                icon="copy"
                class="mar-r-sm"
                size="is-small"
              />
              Copy Item
            </b-dropdown-item>
            <template v-if="$can('destroy', 'MenuItem') && menuPermissions.REMOVE_RESOURCE">
              <hr class="dropdown-divider">
              <b-dropdown-item
                class="is-danger"
                @click="openDeleteMenuItemConfirmation()"
              >
                <b-icon
                  icon="trash-alt"
                  pack="far"
                  class="mar-r-sm"
                  size="is-small"
                />
                Delete Item from <b>Category</b>
              </b-dropdown-item>
              <b-dropdown-item
                class="is-danger"
                @click="openDeleteMenuItemFromMenuConfirmation()"
              >
                <b-icon
                  icon="trash-alt"
                  class="mar-r-sm"
                  size="is-small"
                />
                Delete Item from <b>Entire Menu</b>
              </b-dropdown-item>
            </template>
          </b-dropdown>
        </template>
      </span>
    </div>

    <modifier-groups-container
      v-show="isOpen"
      key="modifier-groups-container"
      :modifier-groups="modifierGroups"
      :menu-item="menuItem"
    />
  </li>
</template>

<script>
  import { mapActions, mapGetters, mapState } from 'vuex';

  import menuResourceTypes from '@/constants/menuResourceTypes';
  import posTypes from '@/constants/posTypes';

  import AlertModal from '@/components/globals/alert-modal.vue';

  import { modifierGroupTypes } from '@/constants/modifierGroups';

  import CategoryItem from '@/store/classes/CategoryItem';
  import Item from '@/store/classes/Item';
  import MenuItemModifierGroupItem from '@/store/classes/MenuItemModifierGroupItem';
  import ModifierGroup from '@/store/classes/ModifierGroup';

  import AddEditItemModal from '../main-menu/add-edit-item-modal/index.vue';
  import CategoryAssociationModal from '../main-menu/category-association-modal.vue';
  import ConfirmDeleteItemFromMenu from '../main-menu/confirm-delete-item-from-menu.vue';
  import CopyMenuItemModal from '../main-menu/copy-menu-item-modal.vue';

  import ModifierGroupsContainer from './modifier-groups-container.vue';

  export default {
    name: 'MenuItemRow',

    components: { ModifierGroupsContainer },

    props: {
      menuItem: {
        type: Object,
        required: true
      },

      category: {
        type: Object,
        default: null
      },

      isSearchRow: {
        type: Boolean,
        default: false
      }
    },

    data: () => ({
      fetchingModifierGroups: false,
      startTime: null,
      modifierGroupTypes,
      posTypes
    }),

    computed: {
      ...mapState('mainMenu', [
        'menuPermissions',
        'featurePermissions',
        'selectedMerchant',
        'clonedItemIds',
        'activeMenuType',
        'menuItemsOpenedStatus'
      ]),
      ...mapGetters('mainMenu', ['categories']),

      isOpen() {
        return this.menuItemsOpenedStatus[this.menuItem.id];
      },

      modifierGroups() {
        /**
         * menuItemModifierGroupItems are a join record with a nested menuItemModifierGroup
         * {
         *  id: 1,
         *  menuItemModifierGroupId: 1,
         *  menuItemId: 1,
         *  sortOrder: 1,
         *  modifierGroup: { id: 1, menuItemModifiers: [] }
         * }
         * menuItemModifierGroups are the actual modifier groups
         * {
         *  id: 1,
         *  menuItemId: 1,
         *  sortOrder: 1,
         *  menuItemModifiers: []
         * }
         *
         * We will pass an array of either to the modifer-groups-container and it and the row will
         * deal with the different data structure.
         */
        return this.featurePermissions.SHARED_MODIFIER_GROUPS
          ? this.menuItemModifierGroupItems
          : this.menuItemModifierGroups;
      },

      modifierGroupIdKey() {
        return this.featurePermissions.SHARED_MODIFIER_GROUPS
          ? 'modifierGroupId'
          : 'id';
      },

      menuItemModifierGroupIds() {
        return this.modifierGroups.map(mg => mg[this.modifierGroupIdKey]);
      },

      menuItemModifierGroups() {
        if (this.featurePermissions.SHARED_MODIFIER_GROUPS) return null;
        if (this.isOpen === undefined) return [];
        return ModifierGroup.query()
          .where('menuItemId', Number(this.menuItem.id))
          .where('menuItemModifierId', null)
          .with('menuItemModifiers')
          .orderBy('sortOrder')
          .get();
      },

      menuItemModifierGroupItems() {
        if (!this.featurePermissions.SHARED_MODIFIER_GROUPS) return null;
        if (this.isOpen === undefined) return [];
        return MenuItemModifierGroupItem.query()
          .where('menuItemId', Number(this.menuItem.id))
          .where('menuItemModifierId', null)
          .with('modifierGroup')
          .with('modifierGroup.menuItemModifiers')
          .orderBy('sortOrder')
          .get();
      },

      canSortResource() {
        return [
          this.$can('update', 'MenuItem'),
          !this.activeMenuType,
          this.menuPermissions?.SORT_RESOURCE
        ].every(x => x);
      },

      isClonedItem() {
        return this.clonedItemIds.includes(this.menuItem.id);
      },

      parentCategory() {
        // When used as a search row, there is no category on the menuItem
        // When used as a regular row, there is a category on the menuItem
        return this.isSearchRow
          ? this.menuItem.menuCategories[0]
          : this.category;
      },

      fetchingItem() {
        return Item.$state().fetchingItemId === this.menuItem.id;
      },

      fetchingItemData() {
        return this.fetchingItem || this.fetchingModifierGroups;
      }
    },

    methods: {
      ...mapActions('mainMenu', ['setIsRemovingFromMenuType', 'setClonedItemIds', 'setMenuResourceOpenState']),

      hasPosType(posTypeId) {
        // From merchantMixin, which we're not using in the main menu for performance reasons
        return this.selectedMerchant?.posConfigurations
          .some(posConfig => posConfig.posType.id === posTypeId);
      },

      async toggleItem() {
        const wasNeverOpen = this.isOpen === undefined;

        if (wasNeverOpen) {
          this.fetchingModifierGroups = true;
          if (this.featurePermissions.SHARED_MODIFIER_GROUPS) {
            const unfetchedModifierGroupIds = this.menuItem.menuItemModifierGroupItems
              .map(mg => mg.modifierGroupId)
              .filter(modifierGroupId => !ModifierGroup.find(modifierGroupId));

            if (unfetchedModifierGroupIds.length) {
              await this.fetchModifierGroups(unfetchedModifierGroupIds);
            }
          }
          else {
            await this.fetchModifierGroupsByItemId();
          }
          this.fetchingModifierGroups = false;
        }

        this.setMenuResourceOpenState({
          resourceType: menuResourceTypes.MENU_ITEM,
          resourceId: this.menuItem.id,
          value: !this.isOpen
        });
      },

      async fetchModifierGroups(ids) {
        try {
          await ModifierGroup.fetchModifierGroups({ ids });
        }
        catch (error) {
          this.$_onRequestError({
            toastOptions: {
              message: 'Unable to fetch modifier groups'
            },
            error
          });
        }
      },

      async fetchModifierGroupsByItemId() {
        try {
          await ModifierGroup.fetchByMenuItemId({ itemId: this.menuItem.id });
        }
        catch (error) {
          this.$_onRequestError({
            toastOptions: {
              message: 'Unable to fetch modifier groups'
            },
            error
          });
        }
      },

      async openItemModal({ defaultTabId }) {
        await this.fetchItem(this.menuItem.id);

        this.$buefy.modal.open({
          parent: this,
          component: AddEditItemModal,
          hasModalCard: true,
          trapFocus: true,
          canCancel: false,
          events: {
            'item-updated': () => this.$emit('re-search-menu')
          },
          props: {
            category: this.parentCategory,
            mode: 'update',
            itemId: this.menuItem.id,
            defaultTabId
          }
        });
      },

      async fetchItem(itemId) {
        try {
          await Item.fetchItem(itemId);
        }
        catch (error) {
          this.$_onRequestError({
            toastOptions: {
              message: 'There was an error fetching the item'
            },
            error
          });
        }
      },

      confirmRemoveFromMenuType({ item, all = false }) {
        let title;
        let message;

        if (item) {
          title = `Remove from ${this.activeMenuType.displayName} Menu`;
          message = `Are you sure you'd like to remove <b>${item.displayName}</b> from the <b>${this.activeMenuType.displayName} Menu</b>?`;
        }
        else if (this.$can('manage', 'all')) {
          title = 'Remove All';
          message = `Remove <b>all categories and items</b> from the <b>${this.activeMenuType.displayName} Menu</b>?`;
        }

        this.$buefy.modal.open({
          parent: this,
          component: AlertModal,
          hasModalCard: true,
          trapFocus: true,
          customClass: 'auto-width',
          props: {
            buttons: [
              { text: 'Cancel' },
              {
                text: 'Yes, Remove',
                primary: true,
                onClick: async () => {
                  await this.removeFromMenuType({ all });
                }
              }
            ],
            horizontal: true,
            showCloseButton: false,
            icon: 'minus-circle',
            title,
            message,
            type: 'is-danger'
          }
        });
      },

      async removeFromMenuType({ all }) {
        try {
          this.setIsRemovingFromMenuType(true);

          const body = { menuTypeId: this.activeMenuType.id };
          let message;

          if (all) {
            body.menuCategoryIds = this.categories.map(c => c.id);
            message = `Successfully removed <b>all categories</b> from the <b>${this.activeMenuType.displayName} Menu</b>`;
          }
          else {
            body.menuItemIds = [this.menuItem.id];
            message = `Successfully removed <b>${this.menuItem.displayName}</b> from the <b>${this.activeMenuType.displayName} Menu</b>`;
          }

          await Item.bulkRemoveMenuType(body);

          if (!this.categories.length) {
            this.$router.replace({ name: 'menuManagement', params: { tabName: 'master' } });
          }

          this.$_onRequestSuccess({
            toastOptions: { message }
          });
        }
        catch (error) {
          this.$_onRequestError({
            error,
            toastOptions: {
              message: 'There was an error removing'
            }
          });
        }
        finally {
          this.setIsRemovingFromMenuType(false);
        }
      },

      async openCategoryAssociationModal() {
        this.$buefy.modal.open({
          parent: this,
          component: CategoryAssociationModal,
          hasModalCard: true,
          trapFocus: true,
          canCancel: false,
          customClass: 'auto-width',
          events: {
            'item-categories-updated': this.handleUpdatedItemCategories
          },
          props: { itemId: this.menuItem.id, categories: this.categories }
        });
      },

      handleUpdatedItemCategories(categoryIds) {
        console.log('handleUpdatedItemCategories', categoryIds);
        // If we want to preserve this behavior, also vuex-ify this
        // categoryIds.forEach(id => this.toggleCategory(id, true));
      },

      openDuplicateMenuItemModal() {
        this.$buefy.modal.open({
          parent: this,
          component: CopyMenuItemModal,
          hasModalCard: true,
          trapFocus: true,
          canCancel: false,
          customClass: 'auto-width',
          events: { 'item-clone-success': this.onItemCloned },
          props: { item: this.menuItem, categories: this.categories }
        });
      },

      async onItemCloned({ categoryIds, itemIds }) {
        try {
          this.isLoadingItemCloned = true;
          const promises = categoryIds.map(categoryId => this.fetchItemsByCategoryId(categoryId));
          await Promise.all(promises);
        }
        catch (error) {
          this.$_onRequestError({
            error,
            toastOptions: {
              message: 'There was an error retrieving your new categories. Please refresh the page.'
            }
          });
        }
        finally {
          this.isLoadingItemCloned = false;
          this.setClonedItemIds(itemIds);
          // this.toggleCategories({ categoryIds, isOpen: true }); to refactor in TOOL-4313

          setTimeout(() => {
            const firstNewCopy = this.$el.querySelector('.is-cloned-item');

            firstNewCopy.scrollIntoView({
              behavior: 'smooth',
              block: 'center'
            });
          }, 200);
        }
      },

      openDeleteMenuItemConfirmation() {
        this.$buefy.modal.open({
          parent: this,
          component: AlertModal,
          hasModalCard: true,
          trapFocus: true,
          canCancel: ['escape', 'outside'],
          props: {
            icon: 'trash-alt',
            iconPack: 'far',
            title: 'Delete Menu Item',
            message: `Are you sure you want to delete <b>${this.menuItem.displayName}</b> from the category <b>${this.parentCategory.displayName}</b>? This will apply to all locations.`,
            type: 'is-danger',
            horizontal: true,
            showCloseButton: false,
            buttons: [
              { text: 'Cancel' },
              { text: 'Delete', primary: true, onClick: () => this.deleteMenuItem() }
            ]
          }
        });
      },

      async deleteMenuItem() {
        try {
          await CategoryItem.deleteItemFromCategory({ menuCategoryId: this.parentCategory.id, menuItemId: this.menuItem.id });

          // if (this.searchResults?.length) { // Refactor as a part of of TOOL-4312
          //   this.reSearchMenu();
          // }

          this.$_onRequestSuccess({
            toastOptions: {
              message: `Successfully deleted <b>${this.menuItem.displayName}</b> from <b>${this.parentCategory.displayName}</b>`
            }
          });
        }
        catch (error) {
          this.$_onRequestError({
            toastOptions: {
              message: `Unable to delete <b>${this.menuItem.displayName}</b>`
            },
            error
          });
        }
      },


      async openDeleteMenuItemFromMenuConfirmation() {
        this.$buefy.modal.open({
          parent: this,
          component: ConfirmDeleteItemFromMenu,
          hasModalCard: true,
          trapFocus: true,
          canCancel: ['escape', 'outside'],
          props: { itemId: this.menuItem.id }
        });
      },

      async reSearchMenu() { // This should also move to the VuexStore
        console.log('reSearchMenu');
      }
    }
  };
</script>

<style lang='sass' scoped>
  .row
      grid-template-columns: 1fr 200px 170px

  .is-cloned-item
    animation-name: highlight-pulse
    animation-duration: 1s
    animation-iteration-count: 3
</style>
