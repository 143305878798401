import { subscribeToChannel } from '@/services/web-socket-service';
import logger from '@/services/logger';

export default {
  namespaced: true,

  state: {
    connections: [],
    channelTypes: {
      EXPO: 'ExpoChannel'
    }
  },

  getters: {
    expoConnection: state => state.connections.find(c => c.channel === state.channelTypes.EXPO),
    channelTypes: state => state.channelTypes
  },

  mutations: {
    ADD_CONNECTION(state, connection) {
      state.connections.push(connection);
    },

    REMOVE_CONNECTION(state, connectionId) {
      state.connections = state.connections.filter(c => connectionId !== c.connectionId);
    }
  },

  actions: {
    subscribeToPortalChannel({ dispatch, state }, { channel, storeId, handler }) {
      const connectionId = storeId ? `${channel}-${storeId}` : channel;
      const connectionExists = state.connections.find(c => c.connectionId === connectionId);

      if (connectionExists) {
        logger.error(`Connection already exists for ${channel}${storeId ? `-${storeId}` : ''}`);
      }
      else {
        // NOTE: store id has to be snake case for the channel to work
        const subscription = subscribeToChannel(
          { channel, store_id: storeId },
          { received: handler }
        );

        dispatch(
          'addConnection',
          {
            connectionId,
            channel,
            storeId,
            unsubscribe: () => subscription.unsubscribe()
          }
        );
      }
    },

    addConnection({ commit }, subscription) {
      commit('ADD_CONNECTION', subscription);
    },

    unsubscribeFromPortalChannel({ commit, state }, connectionId) {
      const connection = state.connections.find(c => c.connectionId === connectionId);
      connection.unsubscribe();
      commit('REMOVE_CONNECTION', connectionId);
    }
  }
};
