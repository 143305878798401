<template>
  <button
    type="button"
    :class="[
      'button dropdown-trigger-button',
      isFullWidth && 'is-full-width',
      hasErrors && 'is-danger is-outlined has-text-danger has-background-white',
      disabled && 'has-background-white-ter is-white has-text-grey'
    ]"
  >
    <div class="button-text mar-r-md">
      <slot>
        <template v-if="value">{{ value }}</template>
        <span v-else-if="placeholder" class="has-text-grey-light">{{ placeholder }}</span>
      </slot>
    </div>
    <div
      class="is-flex control-icon"
      :class="{
        'has-text-danger': hasErrors,
        'has-text-primary': !hasErrors
      }"
    >
      <b-icon v-if="loading" icon="spinner-third" class="spin" />
      <b-icon v-else class="arrow-icon is-flex" icon="angle-down" size="is-medium" />
    </div>
  </button>
</template>

<script>

  export default {
    name: 'DropdownButton',

    props: {
      disabled: {
        type: Boolean,
        default: false
      },
      loading: {
        type: Boolean,
        default: false
      },
      hasErrors: {
        type: Boolean,
        default: false
      },
      placeholder: {
        type: String,
        default: null
      },
      value: {
        type: [String, Boolean],
        default: null
      },
      isFullWidth: {
        type: Boolean,
        default: true
      }
    }
  };
</script>

<style lang="sass" scoped>
  .invalid .dropdown-trigger-button
    border-color: $danger

    &:focus
      box-shadow: 0 0 0 0.125em $danger-medium

    .arrow-icon
      color: $danger

  .dropdown-trigger-button
    text-align: left
    display: flex
    justify-content: space-between
    max-width: 100%

  .button-text
    overflow: hidden
    text-overflow: ellipsis

    .arrow-icon
      color: $primary

    &:hover .arrow-icon
      color: $dark !important

</style>
