<template>
  <div class="is-grid col-min-600 gap-lg">
    <div v-if="_merchant.supportsSquareAuth" class="card pad-lg">
      <h3 class="title is-4">Square</h3>
      <template v-if="_merchant.isAuthorizedWithSquare">
        <b-message class="has-shadow is-compact mar-none is-inline-block" type="is-success" has-icon icon="check">
          <p>We are currently connected to your Square account.</p>
          <p>Our processes will refresh your data automatically.</p>
        </b-message>

        <hr>

        <div class="is-flex gap-x">
          <b-button
            type="is-danger"
            icon-left="window-close"
            :loading="isLoading.revokeSquareAuthorization"
            @click="openRevokeSquareAuthorizationDialog"
          >
            Revoke Token
          </b-button>

          <b-button
            type="is-success"
            icon-left="recycle"
            :loading="isLoading.revokeSquareAuthorization"
            @click="openRefreshSquareAuthorizationDialog"
          >
            Refresh
          </b-button>
        </div>
      </template>
      <template v-else>
        <b-message class="has-shadow is-compact is-inline-block" type="is-warning" has-icon icon="exclamation-triangle">
          <p>It looks like we're not connected to your Square account.</p>
          <p>In order to accept payments in your ordering app, we'll need your approval to connect to your Square payment account.</p>
        </b-message>

        <h4 class="mar-b subtitle is-5">Instructions:</h4>
        <ol class="mar-b-lg dist-y-sm">
          <li>Click on the <span class="has-text-weight-semibold">Connect to your Square account</span> button below</li>
          <li>After logging into your Square account, you will see the Square permission page</li>
          <li>At the bottom of that page select the <span class="has-text-weight-semibold">Allow</span> button</li>
        </ol>

        <div class="buttons">
          <b-button
            type="is-primary"
            tag="a"
            :href="_merchant.squareOauthUrl"
            icon-right="external-link-alt"
            target="_blank"
            class="is-bold"
          >
            Connect to your Square account
          </b-button>
        </div>
      </template>
    </div>

    <div v-if="_merchant.supportsUberEatsOauth" class="card pad-lg">
      <h3 class="title is-4">Uber Eats</h3>
      <template v-if="!_merchant.isAuthorizedWithUber">
        <b-message class="has-shadow is-compact is-inline-block" type="is-warning" has-icon icon="exclamation-triangle">
          <p>It looks like we're not connected to your Uber account.</p>
        </b-message>
        <div class="buttons">
          <b-button
            type="is-primary"
            tag="a"
            :href="_merchant.uberEatsOauthUrl"
            icon-right="external-link-alt"
            target="_blank"
            class="is-bold"
          >
            Connect to your Uber account
          </b-button>
        </div>
      </template>
      <template v-else>
        <b-message class="has-shadow is-compact is-inline-block" type="is-success" has-icon icon="check">
          <p>We are currently connected to your Uber account.</p>
          <p>Select which locations to provision with Uber Eats.</p>
        </b-message>
        <regional-location-dropdown
          v-model="selectedStoreIds"
          :stores="stores"
          sub-label="Select which location(s) to provision with Uber Eats"
          select-all-mode="includeAll"
          :disabled-ids="stores.reduce((acc, store) => !store.canBeIntegratedWithUberEats ? [...acc, store.storeId] : acc, [])"
          disabled-reason="Ineligible for UberEats integration"
          required
        />
        <b-button
          icon-right="link"
          type="is-primary"
          :loading="isLoading.uberEatsStoreProvisioning"
          :disabled="!selectedStoreIds.length"
          @click="provisionUberEatsStores"
        >
          Provision Locations
        </b-button>
      </template>
    </div>
  </div>
</template>

<script>
  import Merchant from '@/store/classes/Merchant';
  import Store from '@/store/classes/Store';
  import merchantMixin from '@/mixins/merchant';
  import { provisionUberEatsStores } from '@/api/merchant-configuration';

  export default {
    name: 'MerchantAuthorizationsTab',

    mixins: [merchantMixin],

    props: {
      merchant: {
        type: Object,
        default: null
      }
    },

    data() {
      return {
        isLoading: {
          revokeSquareAuthorization: false,
          refreshSquareAuthorization: false,
          uberEatsStoreProvisioning: false
        },
        selectedStoreIds: []
      };
    },

    computed: {
      _merchant() {
        return this.merchant || this.$_selectedMerchant;
      },

      stores() { // test
        return Store.orderByName().get();
      }
    },

    methods: {
      openRevokeSquareAuthorizationDialog() {
        this.$buefy.dialog.confirm({
          title: 'Revoke Square Authorization',
          message: 'Warning this will stop online ordering and payments from working. Are you sure you want to continue?',
          onConfirm: this.revokeSquareAuthorization,
          confirmText: 'Revoke',
          type: 'is-danger',
          hasIcon: true
        });
      },

      openRefreshSquareAuthorizationDialog() {
        this.$buefy.dialog.confirm({
          title: 'Refresh Square Authorization',
          message: 'Are you sure you want to continue?',
          onConfirm: this.refreshSquareAuthorization,
          confirmText: 'Refresh',
          type: 'is-primary',
          hasIcon: true
        });
      },

      async revokeSquareAuthorization() {
        this.isLoading.revokeSquareAuthorization = true;
        try {
          await Merchant.revokeSquareAuthorization(this._merchant.id);

          this.$_onRequestSuccess({
            toastOptions: {
              message: 'Successfully revoked your Square authorization!'
            }
          });
        }

        catch (error) {
          this.$_onRequestError({
            toastOptions: { message: 'An error occured while revoking your Square authorization' },
            error
          });
        }

        finally {
          this.isLoading.revokeSquareAuthorization = false;
        }
      },

      async refreshSquareAuthorization() {
        this.isLoading.refreshSquareAuthorization = true;
        try {
          await Merchant.refreshSquareAuthorization(this._merchant.id);

          this.$_onRequestSuccess({
            toastOptions: {
              message: 'Successfully refreshed your Square authorization!'
            }
          });
        }

        catch (error) {
          this.$_onRequestError({
            toastOptions: { message: 'An error occured while refreshing your Square authorization' },
            error
          });
        }

        finally {
          this.isLoading.refreshSquareAuthorization = false;
        }
      },

      async provisionUberEatsStores() { // test
        try {
          this.isLoading.uberEatsStoreProvisioning = true;

          await provisionUberEatsStores(this.selectedStoreIds);

          this.$_onRequestSuccess({
            toastOptions: {
              message: 'Successfully provisioned locations with Uber Eats!'
            }
          });
        }
        catch (error) {
          this.$_onRequestError({
            toastOptions: { message: 'An error occured while provisioning the selected stores' },
            error
          });
        }
        finally {
          this.isLoading.uberEatsStoreProvisioning = false;
        }
      }
    }
  };
</script>
