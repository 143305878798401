<template>
  <div>
    <li
      v-for="item in searchResults"
      :key="`item-${item.id}`"
      :data-item-id="item.id"
      :class="{
        'show-inventory': $_menuPermissions.UPDATE_INVENTORY,
        'hide-prices':!$_selectedMerchant.features.menuPrices
      }"
    >
      <div class="row">
        <span class="pad-y-sm">
          <div class="is-flex-column">
            <span class="has-text-weight-bold is-size-6">{{ item.displayName }}</span>
            <span v-if="item.menuCategories.length" class="is-size-7"><b>Category:</b> {{ item.menuCategories[0].displayName }}</span>
          </div>
        </span>
        <span v-if="$_selectedMerchant.features.menuPrices">
          <template v-if="getStoreItemPrice(item)">
            {{ getStoreItemPrice(item) | dollars }}
          </template>
          <span v-else class="has-text-grey">N/A</span>
        </span>
        <span v-if="$_menuPermissions.UPDATE_INVENTORY" class="justify-center">
          <template v-if="getStoreItem(item)">
            <b-numberinput
              v-if="$can('update', 'StoreMenuItem')"
              controls-position="compact"
              placeholder="∞"
              :class="['has-three-digits', {'infinite-inventory': getStoreItemInventory(item) === null}]"
              max="999"
              :min="getStoreItemInventory(item) === null ? 0 : -1 /* * * *
                                  * allows a user to go down to -1 to specify 'no value' but *
                                  * once there is no value, the minimum value becomes 0 so   *
                                  * that the minus button is disabled                        *
                                  * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */"
              :value="getStoreItemInventory(item)"
              @input="emitSharedModGroupsSearchAction({ actionName: 'handle-inventory-change', args: { item, count: $event } })"
            />
            <p v-else>
              {{ getStoreItem(item).inventoryCount }}
            </p>
          </template>
        </span>
        <span class="align-right is-flex">
          <b-icon
            v-if="showSuspendedIconForItem(item)"
            v-tippy="{ content: `Unavailable until ${adjustDateToStoreTimezone(getStoreItemSuspendUntilDate(item)).format('MM/DD/YY h:mm A')} ${timeZoneAbbr}`, placement: 'left', delay: [333, 0] }"
            icon="alarm-clock"
            size="is-small"
            class="mar-r-xs"
          />
          <b-button
            v-if="storeItemHasSchedule(item.id) && $can('read', 'StoreMenuItemSchedule') && $_menuPermissions.MANAGE_DAYPART_SCHEDULE"
            v-tippy="{ content: 'Restricted Availability', placement: 'left', delay: [333, 0] }"
            class="is-transparent"
            @click="emitSharedModGroupsSearchAction({ actionName: 'open-store-item-modal', args: { item, storeId: selectedStoreId, defaultTabIndex: 1 } })"
          >
            <b-icon icon="calendar" pack="far" />
          </b-button>
          <b-button
            v-if="(($can('read', 'StoreMenuItem', 'price') || $can('read', 'StoreMenuItem', 'totalSalesTaxRate'))
              && ($_menuPermissions.READ_PRICE_TAX || $_menuPermissions.UPDATE_INVENTORY))
              || ($can('read', 'StoreMenuItemSchedule') && $_menuPermissions.MANAGE_DAYPART_SCHEDULE)"
            class="is-transparent"
            @click="emitSharedModGroupsSearchAction({ actionName: 'open-store-item-modal', args: { item, storeId: selectedStoreId } })"
          >
            <b-icon
              v-if="(($can('update', 'StoreMenuItem', 'price') || $can('update', 'StoreMenuItem', 'totalSalesTaxRate'))
                && ($_menuPermissions.UPDATE_PRICE_TAX || $_menuPermissions.UPDATE_INVENTORY))
                || ($can('update', 'StoreMenuItemSchedule') && $_menuPermissions.MANAGE_DAYPART_SCHEDULE)"
              icon="pencil"
              size="is-small"
            />
            <span v-else>View</span>
          </b-button>
          <b-button
            v-if="$can('update', 'StoreMenuItem', 'isDisabled') && $_menuPermissions.UPDATE_VISIBILITY"
            class="is-transparent"
            @click="emitSharedModGroupsSearchAction({ actionName: 'toggle-store-item-disabled', args: item })"
          >
            <b-icon
              v-tippy="{ content: getVisibilityTooltip(getStoreItem(item)), placement: 'left', onShow: () => !!getStoreItem(item) }"
              :icon="getStoreItemDisabled(item) ? 'eye-slash' : 'eye'"
              :class="{'has-text-danger': getStoreItemDisabled(item)}"
              size="is-small"
            />
          </b-button>
          <span v-else />
        </span>
      </div>
    </li>
  </div>
</template>

<script>
  import merchantMixin from '@/mixins/merchant';
  import featurePermissionsMixin from '@/mixins/featurePermissions';
  import moment from 'moment-timezone';
  import Store from '@/store/classes/Store';

  export default {
    name: 'ItemSearchRow',

    mixins: [merchantMixin, featurePermissionsMixin],

    props: {
      searchResults: {
        type: Array,
        default: () => []
      },

      selectedStoreId: {
        type: Number,
        required: true
      },

      selectedStoreItems: {
        type: Array,
        default: () => []
      },

      storeItemsWithChangedInventories: {
        type: Object,
        default: () => ({})
      },

      storeMenuItemSchedules: {
        type: Array,
        default: () => []
      }
    },

    computed: {
      selectedStore() {
        return Store.find(this.selectedStoreId);
      },

      timeZoneAbbr() {
        return moment.tz(this.selectedStore.ianaTimezoneId).zoneAbbr();
      }
    },

    methods: {
      emitSharedModGroupsSearchAction(args) {
        this.$emit('shared-mod-group-search-action', args);
      },

      getStoreItem(item) {
        return this.selectedStoreItems.find(storeItem => storeItem.menuItemId === item.id);
      },

      getStoreItemPrice(item) {
        return this.getStoreItem(item)?.price;
      },

      getStoreItemDisabled(item) {
        const storeItem = this.getStoreItem(item);
        return !storeItem || storeItem.isDisabled;
      },

      getStoreItemSuspendUntilDate(item) {
        const storeItem = this.getStoreItem(item);
        return storeItem?.suspendUntilDate;
      },

      storeItemHasSchedule(itemId) {
        return !!this.storeMenuItemSchedules.find(schedule => schedule.menuItemId === itemId);
      },

      showSuspendedIconForItem(item) {
        const suspendedUntilDate = this.getStoreItemSuspendUntilDate(item);
        if (!suspendedUntilDate) {
          return false;
        }

        const dateInStoreTimezone = this.adjustDateToStoreTimezone(suspendedUntilDate);

        const nowInStoreTimezone = moment().tz(this.selectedStore.ianaTimezoneId);
        return nowInStoreTimezone.isBefore(dateInStoreTimezone);
      },

      adjustDateToStoreTimezone(date) {
        return moment.utc(date).tz(this.selectedStore.ianaTimezoneId);
      },

      getStoreItemInventory(item) {
        const storeItem = this.getStoreItem(item);
        return !this.storeItemsWithChangedInventories[storeItem.id]
          ? storeItem.inventoryCount
          : this.storeItemsWithChangedInventories[storeItem.id].inventoryCount;
      },

      getVisibilityTooltip(storeMenuResource) {
        if (storeMenuResource) {
          const { createdAt, createdSource, updatedAt, modifiedSource } = storeMenuResource;
          const date = !updatedAt
            ? moment.tz(createdAt, this.selectedStore.ianaTimezoneId).format('MM/DD/YY h:mm A z')
            : moment.tz(updatedAt, this.selectedStore.ianaTimezoneId).format('MM/DD/YY h:mm A z');
          const source = !updatedAt ? createdSource?.split('-')[2] : modifiedSource?.split('-')[2];
          const sourceString = source ? `<p>by ${source}</p>` : '';
          return `<p>${updatedAt ? 'Updated' : 'Created'} ${date}</p>${sourceString}`;
        }
      }
    }
  };
</script>

<style lang="sass" scoped>
  .row
    grid-template-columns: minmax(200px, 1fr) minmax(75px, 0.2fr) minmax(100px, 0.25fr)

    .show-inventory &
      grid-template-columns: minmax(200px, 1fr) minmax(75px, 0.2fr) minmax(145px, 200px) minmax(100px, 0.25fr)

    .hide-prices &
      grid-template-columns: minmax(200px, 1fr) minmax(145px, 200px) minmax(100px, 0.25fr)
</style>
