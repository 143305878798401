<template>
  <b-collapse
    class="expandable-section"
    animation="slide"
    data-test-id="payment-info-section"
  >
    <div
      slot="trigger"
      slot-scope="props"
      role="button"
      class="expandable-section--header"
    >
      <h4 class="subtitle is-5 is-marginless">
        Payment Info
        <b-icon
          v-tippy="{ maxWidth: 270 }"
          content="Only successful are displayed"
          pack="far"
          icon="info-circle"
          class="is-size-6"
          type="is-grey"
        />
      </h4>
      <b-icon class="open-indicator" :icon="props.open ? 'angle-down' : 'angle-right'" />
    </div>
    <div class="details-container">
      <b-message v-if="orderIsOver90DaysOld" type="is-warning" class="has-shadow is-compact">
        Payment info is not available for orders older than 90 days.
      </b-message>
      <div v-if="$can('capture_order_payment', 'StoreMapping') && order.status === 'Created' && order.paymentState === 'PartiallyAuthorized'" class="mar-b-md">
        <b-button
            rounded
            :loading="isCapturingPayment === order.orderId"
            icon-left="credit-card"
            type="is-primary"
            size="is-small"
            @click="confirmCapturePayment"
        >
          Capture Payments
        </b-button>
      </div>
      <div v-if="!order.payments.length" class="mar-t has-text-grey">
        No payment info found for this order
      </div>
      <ul v-else class="dist-y-md">
        <li v-for="payment in order.payments" :key="payment.id">
          <div class="has-text-weight-bold is-uppercase is-flex align-center">
            <payment-icon :payment="payment" />
            <template v-if="payment.cardNumberLastFour">
              <span class="mar-x-xs">••••</span>
              <span>{{ payment.cardNumberLastFour }}</span>
            </template>
            <div v-tippy="{ content: 'Refunds are not supported for this payment type', onShow: () => payment.allowRefundOnPortal === false }" class="mar-l">
              <b-button
                v-if="
                  $can('refund_orders', 'StoreMapping')
                    && order.orderType !== orderTypes.THIRD_PARTY_ORDER_AHEAD
                    && (order.status === 'Fulfilled' || order.status === 'Future') && isAbleToRefundPayment(payment)
                "
                rounded
                :disabled="payment.allowRefundOnPortal === false"
                :loading="isRefundingOrder === order.orderId"
                icon-left="hand-holding-usd"
                type="is-danger"
                size="is-small"
                @click="openRefundModal(payment)"
              >
                Refund Payment
              </b-button>
            </div>
          </div>
          <span
            v-tippy="{
              content: 'Receipts are not available for orders older than 90 days',
              onShow: () => orderIsOver90DaysOld
            }"
            :class="{ 'disabled': orderIsOver90DaysOld }"
          >
            <a
              v-if="payment.receiptUrl"
              class="link is-size-7 has-text-weight-bold"
              :class="{ 'disabled': orderIsOver90DaysOld }"
              :href="payment.receiptUrl"
              target="_blank"
            >
              View Receipt
            </a>
          </span>
          <payment-transaction-list
            v-if="payment.transactions"
            class="mar-t-xs has-text-grey"
            :payment="payment"
            :time-zone="order.store.timeZone"
          />
        </li>
      </ul>
    </div>
  </b-collapse>
</template>

<script>
  import currency from 'currency.js';
  import moment from 'moment-timezone';

  import orderTypes from '@/constants/orderTypes';

  import RefundModal from '@/components/pages/orders/refund-modal.vue';

  import Order from '@/store/classes/Order';

  export default {
    name: 'PaymentInfoSection',

    props: {
      order: {
        type: Object,
        required: true
      }
    },

    data: () => ({
      orderTypes
    }),

    computed: {
      orderIsOver90DaysOld() {
        return this.order && moment(this.order.orderDate).isBefore(moment().subtract(90, 'days'));
      },
      isRefundingOrder() {
        return Order.$state().refunding;
      },
      isCapturingPayment() {
        return Order.$state().capturingPayment;
      }
    },

    methods: {
      isAbleToRefundPayment(payment) {
        const isSuccessfulPayment = !!payment.transactions.find(t => ['Sale', 'Capture'].includes(t.paymentTransaction?.paymentTransactionType) && t.paymentTransaction.paymentTransactionStatus === 'Succeeded');
        const totalRefundedAmount = payment.transactions.reduce((total, transaction) => {
          if (transaction.paymentTransactionType === 'Refund' && transaction.paymentTransaction.paymentTransactionStatus === 'Succeeded') {
            return currency(total).add(currency(transaction.paymentTransaction.amount)).value;
          }
          return total;
        }, 0);

        return [
          isSuccessfulPayment,
          payment.amount > 0,
          payment.amount > totalRefundedAmount,
          !this.hasVoidTransaction(payment)
        ].every(x => x);
      },

      hasVoidTransaction(payment) {
        return !!payment.transactions.find(t => t.paymentTransaction?.paymentTransactionType === 'Void');
      },

      openRefundModal(paymentToRefund) {
        this.$buefy.modal.open({
          parent: this,
          component: RefundModal,
          hasModalCard: true,
          trapFocus: true,
          canCancel: false,
          customClass: 'auto-width',
          props: { order: this.order, closeOrderReceipt: this.closeModal, payment: paymentToRefund }
        });
      },

      confirmCapturePayment() {
        this.$buefy.dialog.confirm({
          title: 'Capture Payments',
          message: 'Are you sure you\'d like to capture payments? Once this occurs, guests will no longer be able to add payment to this ticket.',
          confirmText: 'Capture Payments',
          cancelText: 'Cancel',
          type: 'is-primary',
          hasIcon: true,
          onConfirm: () => this.capturePayment()
        });
      },

      async capturePayment() {
        try {
          await Order.capturePayment(this.order.orderId);
          this.$buefy.toast.open({
            message: 'Payment captured successfully',
            type: 'is-success'
          });
        }
        catch (error) {
          this.$buefy.toast.open({
            message: 'Failed to capture payment',
            type: 'is-danger'
          });
        }
      },

      closeModal() {
        this.$parent.closeModal();
      }
    }
  };
</script>

<style lang='sass' scoped>
  .span.disabled
    cursor: not-allowed

  .expandable-section
    border-bottom: 1px solid $grey-lighter
    padding: 15px 20px

    &:last-of-type
      border-bottom: none

    .expandable-section--header
      display: flex
      align-items: center
      justify-content: space-between
      cursor: pointer

      > *
        color: $grey-dark
        transition: 100ms

      &:hover > *
        color: $primary
    .details-container
      width: 100%
      margin-top: 1rem
      overflow: hidden
</style>
