<template>
  <panel title="Modifier Groups" no-padding>
    <b-loading v-if="isFetching" :active="isFetching" :is-full-page="false" />

    <div class="pad-x-md has-border-bottom has-border-grey-lighter is-flex gap-sm">
      <validated-text-input
        v-model="query"
        type="text"
        :icon-right-clickable="!!query"
        :icon-right="query ? 'times-circle' : ''"
        placeholder="Search by Modifier Group Name"
        icon="search"
        class="flex-grow mar-b"
        label-position="on-border"
        label="Search"
        name="query-input"
        expanded
        output-string
        @icon-right-click="query = ''"
        @keydown.native.esc="query = ''"
      />
      <b-button
        v-if="$_selectedMerchant.allowModifierGroupAssociation && $can('crud', 'MenuItemModifierGroupItem')"
        type="is-primary"
        icon-left="plus"
        data-test-id="add-modifier-group-button"
        @click="openModifierGroupModal()"
      >
        Modifier Group
      </b-button>
    </div>

    <b-table
      :data="isFetching ? [] : modifierGroups"
      class="is-middle-aligned"
      :mobile-cards="false"
      scrollable
      backend-pagination
      paginated
      :total="total"
      :current-page="page"
      :per-page="25"
      data-test-id="modifier-groups-table"
      :default-sort="['name', 'asc']"
      @page-change="handlePageChange"
    >
      <template #empty>
        <empty-table-loader
          message="No Modifier Groups Found..."
          :loading="isFetching"
        />
      </template>

      <b-table-column
        v-slot="{ row }"
        field="name"
        label="Modifier Group"
        width="600"
      >
        <span
          class="link"
          @click="openModifierGroupModal(row)"
        >
          {{ row.displayName | capitalize }}
        </span>
      </b-table-column>

      <b-table-column
        v-slot="{ row }"
        centered
        field="modifiersCount"
        label="Modifiers"
      >
        <span>
          {{ row.modifiersCount }}
        </span>
      </b-table-column>

      <b-table-column
        v-slot="{ row }"
        centered
        field="sharedMenuItemCount"
        label="Items"
      >
        <span>
          {{ row.sharedMenuItemCount }}
        </span>
      </b-table-column>

      <b-table-column
        v-slot="{ row }"
        centered
        cell-class="actions"
        label="Actions"
        width="1"
        field="Actions"
      >
        <dropdown-menu position="bottom-end" :mobile-modal="false">
          <b-button slot="trigger" class="is-transparent" type="is-white">
            <b-icon icon="ellipsis-v" pack="far" size="is-small" />
          </b-button>

          <template v-if="$can('crud', 'MenuItemModifierGroupItem')">
            <b-dropdown-item @click="openModifierGroupModal(row)">
              <b-icon
                icon="pencil"
                class="mar-r-sm"
                size="is-small"
              />
              Edit Group
            </b-dropdown-item>
            <hr class="dropdown-divider">
            <b-dropdown-item @click="confirmDeleteModifierGroup(row)">
              <b-icon
                icon="trash"
                class="mar-r-sm"
                size="is-small"
                type="is-danger"
              />
              Delete Group
            </b-dropdown-item>
          </template>

          <b-dropdown-item
            v-else
            @click="openModifierGroupModal(row)"
          >
            <b-icon
              icon="eye"
              class="mar-r-sm"
              size="is-small"
            />
            View Group
          </b-dropdown-item>
        </dropdown-menu>
      </b-table-column>
    </b-table>
  </panel>
</template>

<script>
  import debounce from 'lodash.debounce';

  import merchantMixin from '@/mixins/merchant';

  import ModifierGroup from '@/store/classes/ModifierGroup';
  import SharedModifierGroupModal from './shared-modifier-group-modal.vue';
  import alertModal from '@/components/globals/alert-modal.vue';

  export default {
    name: 'ModifierGroups',

    mixins: [merchantMixin],

    data: () => ({
      page: 1,
      query: '',
      total: 0,
      modifierGroups: []
    }),

    computed: {
      isFetching() {
        return ModifierGroup.$state().fetching || ModifierGroup.$state().fetchingModifierGroupId;
      }
    },

    watch: {
      query: debounce(function () { // eslint-disable-line func-names
        this.fetchModifierGroups();
      }, 666)
    },

    created() {
      this.onCreated();
    },

    methods: {
      async onCreated() {
        await this.fetchModifierGroups();
      },

      handlePageChange(page) {
        this.page = page;
        this.fetchModifierGroups();
      },

      async fetchModifierGroups() {
        try {
          const params = { page: this.page };
          if (this.query) {
            params.search_parameter = 'display_name';
            params.search_term = `${encodeURIComponent(this.query)}`;
          }
          const { menuItemModifierGroups, meta } = await ModifierGroup.fetchModifierGroups(params);

          this.modifierGroups = menuItemModifierGroups;
          this.total = meta.count;
        }
        catch (error) {
          this.$_onRequestError({
            error,
            toastOptions: {
              message: 'There was an error fetching modifier groups'
            }
          });
        }
      },

      async openModifierGroupModal(modifierGroup) {
        if (modifierGroup?.id) {
          await ModifierGroup.fetchModifierGroup({ modifierGroupId: modifierGroup.id, includeMenuItemMetadata: true });
        }

        this.$buefy.modal.open({
          parent: this,
          component: SharedModifierGroupModal,
          hasModalCard: true,
          trapFocus: true,
          canCancel: false,
          customClass: 'auto-width',
          props: {
            modifierGroupId: modifierGroup?.id,
            readOnly: !this.$can('crud', 'MenuItemModifierGroupItem')
          },
          events: {
            close: this.fetchModifierGroups
          }
        });
      },

      confirmDeleteModifierGroup(modifierGroup) {
        this.$buefy.modal.open({
          parent: this,
          component: alertModal,
          hasModalCard: true,
          trapFocus: true,
          canCancel: ['escape', 'outside'],
          customClass: 'auto-width',
          props: {
            title: 'Delete Modifier Group',
            message: `Are you sure you want to delete the <b>${modifierGroup.displayName} Modifier Group</b>?  This will delete the modifier group from ${modifierGroup.sharedMenuItemCount} menu items.`,
            showCloseButton: false,
            horizontal: true,
            icon: 'trash-alt',
            type: 'is-danger',
            buttons: [
              { text: 'Cancel' },
              { text: 'Delete', primary: true, onClick: () => this.deleteModifierGroup(modifierGroup.id) }
            ]
          }
        });
      },

      async updateModifierGroup(modifierGroup) {
        try {
          await ModifierGroup.updateModifierGroup(modifierGroup);

          this.$_onRequestSuccess({
            toastOptions: {
              message: 'Successfully updated the modifier group'
            }
          });
        }
        catch (error) {
          this.$_onRequestError({
            error,
            toastOptions: {
              message: 'There was an error updating the modifier group'
            }
          });
        }
        finally {
          this.updatingSearchSuggestionId = false;
        }
      },

      async deleteModifierGroup(modifierGroupId) {
        try {
          await ModifierGroup.deleteModifierGroup(modifierGroupId);
          this.$_onRequestSuccess({
            toastOptions: {
              message: 'Successfully deleted the modifier group'
            }
          });
        }
        catch (error) {
          this.$_onRequestError({
            error,
            toastOptions: {
              message: 'There was an error deleting the modifier group'
            }
          });
        }
      }
    }
  };
</script>

<style lang='sass' scoped>
  ::v-deep
    .modifier-group
      font-weight: 700
      background-color: lighten($primary, 43) !important
      color: darken($primary, 40) !important
</style>
