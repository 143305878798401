<template>
  <validated-form
    ref="form"
    auto-focus
    form-id="addSurveyModal"
    @valid-submit="handleSubmit"
  >
    <modal-card
      title="Create Survey"
      subtitle="Select what type of application to build this survey for"
      show-small-subtitle
    >
      <validated-input
        class="dropdown-field"
        name="appType"
        label="Application Type"
        rules="required"
      >
        <b-select
          v-model="selectedFormType"
          placeholder="Select an application type..."
          expanded
        >
          <option :value="null" disabled>-</option>
          <option v-for="{ value, display } in formTypeOptions" :key="value" :disabled="getDisabledFormType(value)" :value="value">{{ display }}</option>
        </b-select>
      </validated-input>

      <transition name="fade-down">
        <validated-input
          v-show="selectedFormType"
          class="dropdown-field"
          name="platformType"
          label="Platform Type"
          rules="required"
        >
          <b-select
            v-model="selectedPlatformType"
            placeholder="Select a platform type..."
            expanded
          >
            <option :value="null" disabled>-</option>
            <option
              v-for="platform in platformTypeOptions"
              :key="platform"
              :disabled="getDisabledPlatformType(platform)"
              :value="platform"
            >
              {{ capitalCase(platform) }}
            </option>
          </b-select>
        </validated-input>
      </transition>

      <template #footer>
        <div class="buttons all-bold">
          <b-button
            rounded
            @click="$parent.close()"
          >
            Cancel
          </b-button>
          <b-button
            v-tabbable
            rounded
            native-type="submit"
            type="is-primary"
            :loading="isFetchingTemplate"
          >
            Continue
          </b-button>
        </div>
      </template>
    </modal-card>
  </validated-form>
</template>

<script>
  import merchantMixin from '@/mixins/merchant';
  import surveyFormTypes from '@/constants/surveyFormTypes';
  import surveyPlatformTypes from '@/constants/surveyPlatformTypes';
  import Survey from '@/store/classes/Survey';
  import MerchantAppSetting from '@/store/classes/MerchantAppSetting';

  import capitalCase from '@/helpers/capitalCase';



  export default {
    name: 'AddSurveyModal',

    mixins: [merchantMixin],

    props: {
      submitAction: {
        type: Function,
        required: true
      },

      surveys: {
        type: Array,
        required: true
      }
    },

    data() {
      return {
        capitalCase,
        selectedFormType: null,
        selectedPlatformType: null,
        formTypePlatformType: {
          [surveyFormTypes.ORDER_AHEAD_SURVEY]: Object.values(surveyPlatformTypes),
          [surveyFormTypes.PAY_AT_TABLE_SURVEY]: [surveyPlatformTypes.WEB],
          [surveyFormTypes.ORDER_AT_TABLE_SURVEY]: Object.values(surveyPlatformTypes),
          [surveyFormTypes.ORDER_SURVEY]: Object.values(surveyPlatformTypes),
          [surveyFormTypes.MOBILE_TIPPING_SURVEY]: [surveyPlatformTypes.WEB],
          [surveyFormTypes.TABLESIDE_SURVEY]: Object.values(surveyPlatformTypes),
          [surveyFormTypes.APP_RATING_SURVEY]: [surveyPlatformTypes.MOBILE]
        }
      };
    },

    computed: {
      formTypeOptions() {
        const { orderAheadEnabled, patEnabled, oatEnabled, features, externalDeviceEnabled } = this.$_selectedMerchant;
        const hotelMobileTippingEnabled = features?.hotelMobileTipping;
        return [
          orderAheadEnabled && { value: surveyFormTypes.ORDER_AHEAD_SURVEY, display: 'Order Ahead' },
          patEnabled && { value: surveyFormTypes.PAY_AT_TABLE_SURVEY, display: 'Pay @ Table' },
          oatEnabled && { value: surveyFormTypes.ORDER_AT_TABLE_SURVEY, display: 'Order @ Table' },
          hotelMobileTippingEnabled && { value: surveyFormTypes.MOBILE_TIPPING_SURVEY, display: 'Mobile Tipping' },
          externalDeviceEnabled && { value: surveyFormTypes.TABLESIDE_SURVEY, display: 'Tableside' },
          { value: surveyFormTypes.ORDER_SURVEY, display: 'Cross Application' },
          this.merchantHasMobileAppConfigured && { value: surveyFormTypes.APP_RATING_SURVEY, display: 'App Rating' }
        ].filter(Boolean);
      },

      platformTypeOptions() {
        return this.formTypePlatformType[this.selectedFormType];
      },

      isFetchingTemplate() {
        return Survey.$state().fetchingTemplate;
      },

      merchantHasMobileAppConfigured() {
        return MerchantAppSetting.hasMobileAppConfigured();
      }
    },

    watch: {
      selectedFormType: 'resetPlatformType'
    },

    created() {
      this.onCreated();
    },

    methods: {
      async onCreated() {
        MerchantAppSetting.fetchMerchantAppSettings(this.$_selectedMerchant.id);
      },

      resetPlatformType() {
        this.selectedPlatformType = null;
      },

      getDisabledFormType(type) {
        const existingSurveysOfType = this.surveys.filter(survey => survey.formType === type);
        const hasCrossPlatform = existingSurveysOfType.some(survey => survey.merchantFormPlatformType === surveyPlatformTypes.CROSS_PLATFORM);

        const nonCrossPlatformPlatformTypes = this.formTypePlatformType[type].filter(x => x !== surveyPlatformTypes.CROSS_PLATFORM);
        const hasAllAvailablePlatforms = existingSurveysOfType.filter(
          survey => nonCrossPlatformPlatformTypes.includes(survey.merchantFormPlatformType)
        ).length === nonCrossPlatformPlatformTypes.length;

        return hasCrossPlatform || hasAllAvailablePlatforms;
      },

      getDisabledPlatformType(platformType) {
        const existingSurveysOfSelectedFormType = this.surveys.filter(survey => survey.formType === this.selectedFormType);

        if (platformType === surveyPlatformTypes.CROSS_PLATFORM) {
          const hasAtLeastOneSurvey = !!existingSurveysOfSelectedFormType.length;
          return hasAtLeastOneSurvey;
        }

        const hasSurveyOfPlatformType = existingSurveysOfSelectedFormType.some(survey => survey.merchantFormPlatformType === platformType);
        return hasSurveyOfPlatformType;
      },

      async handleSubmit() {
        const newSurvey = new Survey({
          formType: this.selectedFormType,
          merchantFormPlatformType: this.selectedPlatformType,
          merchantId: this.$_selectedMerchantId,
          isSaved: false
        });

        newSurvey.$save();

        await Survey.fetchTemplate(this.selectedFormType);

        this.submitAction({ surveyId: newSurvey.id, mode: 'create' });

        this.$parent.close();
      }
    }
  };
</script>
