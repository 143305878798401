<template>
  <validated-form
    ref="storeItemDetailsForm"
    auto-focus
    form-id="storeItemDetailsForm"
    class="is-flex-column gap"
  >
    <!--  there was a lot of logic in the store menu that may or may not all apply here  -->
    <div v-if="$_selectedMerchant.features.menuPrices && $can('read', 'StoreMenuItem') && $_menuPermissions.READ_PRICE_TAX">
      <validated-text-input
        v-model="form.price"
        label="Price"
        name="price"
        rules="required|min_value:0"
        type="dollars"
        :class="$can('update', 'StoreMenuItem', 'price') && $_menuPermissions.UPDATE_PRICE_TAX && 'is-marginless'"
        :disabled="!$can('update', 'StoreMenuItem', 'price') && !$_menuPermissions.UPDATE_PRICE_TAX || store.isReadOnlyLocationMenu"
      />

      <b-checkbox
        v-if="$can('update', 'StoreMenuItem', 'price') && $_menuPermissions.UPDATE_PRICE_TAX"
        v-model="applyPriceToAll"
        size="is-small"
        class="mar-t-xs"
        name="applyPriceToAll"
        :disabled="store.isReadOnlyLocationMenu"
      >
        Apply item price to all locations
      </b-checkbox>
    </div>

    <div v-if="$_selectedMerchant.features.menuPrices && $can('read', 'StoreMenuItem') && $_menuPermissions.READ_PRICE_TAX">
      <validated-text-input
        v-model="form.totalSalesTaxRate"
        label="Item Tax Rate"
        name="totalSalesTaxRate"
        :rules="{ between: [0, 100] }"
        type="float"
        output-string
        :class="['percent', { 'is-marginless': $can('update', 'StoreMenuItem', 'totalSalesTaxRate') && $_menuPermissions.UPDATE_PRICE_TAX }]"
        :placeholder="store.totalSalesTaxRate ? `${store.totalSalesTaxRate}%` : ''"
        :disabled="!$can('update', 'StoreMenuItem', 'totalSalesTaxRate') || !$_menuPermissions.UPDATE_PRICE_TAX"
      />
      <p v-if="store.totalSalesTaxRate" class="help has-text-grey">
        The location tax of <b>{{ store.totalSalesTaxRate }}%</b> is applied by default.<br>
        Item tax will override the location tax.
      </p>
    </div>

    <validated-text-input
      v-if="$can('read', 'StoreMenuItem') && $_menuPermissions.UPDATE_INVENTORY"
      v-model="form.inventoryCount"
      label="Available In Stock"
      name="inventoryCount"
      type="number"
      rules="min_value:0"
      class="is-marginless"
      :disabled="!$can('update', 'StoreMenuItem', 'inventoryCount') || !$_menuPermissions.UPDATE_INVENTORY"
    />

    <div v-if="$can('read', 'StoreMenuItem') && $_menuPermissions.UPDATE_INVENTORY">
      <validated-text-input
        v-model="form.lowInventoryCount"
        label="Low Stock Threshold"
        name="lowInventoryCount"
        type="number"
        tooltip="When inventory is at or below this threshold, a “Low Stock” banner will display on the item."
        tooltip-placement="right"
        rules="numeric|min_value:0"
        class="is-marginless"
        :disabled="!$can('update', 'StoreMenuItem', 'lowInventoryCount') || !$_menuPermissions.UPDATE_INVENTORY"
      />

      <b-checkbox
        v-model="applyLowInventoryCountToAll"
        size="is-small"
        class="mar-t-xs"
        name="applyLowInventoryCountToAll"
      >
        Apply threshold to all locations
      </b-checkbox>
    </div>
    <validated-input
      name="suspendUntilDate"
      :label="`Suspend Until (${timeZoneAbbr()})`"
      tooltip="This item will be unavailable until the specified time."
    >
      <b-datetimepicker
        v-model="suspendUntilDate"
        :min-datetime="adjustDate(minDate, timeZoneOffset)"
        placeholder="Click to select..."
        :nearby-selectable-month-days="true"
        :mobile-native="false"
        position="is-top-right"
        :disabled="!$can('update', 'StoreMenuItem', 'suspendUntilDate')"
        icon-right="times-circle"
        icon-right-clickable
        @icon-right-click="clearDateTime"
        @input="emitInput"
      >
        <template #right>
          <b-button
            label="Clear"
            @click="clearDateTime"
          />
        </template>
      </b-datetimepicker>
    </validated-input>
  </validated-form>
</template>

<script>
  import StoreItem from '@/store/classes/StoreItem';
  import Item from '@/store/classes/Item';
  import merchantMixin from '@/mixins/merchant';
  import multiFormChildProvider from '@/mixins/multiFormMixin/multiFormChildProvider';
  import moment from 'moment-timezone';
  import { emitNativeInput } from '@/services/emit-input';


  export default {
    name: 'StoreItemDetailsForm',

    mixins: [merchantMixin, multiFormChildProvider],

    props: {
      item: {
        type: Object,
        required: true
      },

      storeItem: {
        type: Object,
        default: null
      },

      store: {
        type: Object,
        required: true
      },

      formRef: {
        type: String,
        required: true
      }
    },

    data() {
      return {
        form: null,
        applyPriceToAll: false,
        applyLowInventoryCountToAll: false
      };
    },

    computed: {
      minDate() {
        return moment.utc().startOf('day').toDate();
      },

      suspendUntilDate: {
        get() {
          return this.form.suspendUntilDate ? this.adjustDate(this.form.suspendUntilDate) : null;
        },
        set(value) {
          this.form.suspendUntilDate = this.adjustDate(value, true)
            .toISOString();
        }
      },

      timeZoneOffset() {
        const storeUtcOffset = moment.tz(moment(), this.store.ianaTimezoneId).utcOffset();
        const localUtcOffset = moment().utcOffset();
        return storeUtcOffset - localUtcOffset;
      }
    },

    created() {
      this.onCreated();
    },

    methods: {
      onCreated() {
        this.form = this.$clone(this.storeItem);
      },

      emitInput() {
        const formElement = this.$refs.storeItemDetailsForm.$refs.formElement;
        emitNativeInput(formElement);
      },

      adjustDate(date, reverse) {
        const offsetMinutes = reverse ? this.timeZoneOffset * -1 : this.timeZoneOffset;
        return moment(date).add(offsetMinutes, 'minutes').toDate();
      },

      timeZoneAbbr() {
        return moment.tz.zone(this.store.ianaTimezoneId).abbr(Date.now());
      },

      clearDateTime() {
        this.form.suspendUntilDate = null;
        this.emitInput();
      },

      async handleSubmit() {
        const storeItemPayload = {
          ...this.form,
          applyPriceToAll: this.applyPriceToAll,
          applyLowInventoryCountToAll: this.applyLowInventoryCountToAll
        };
        let storeItemId = this.form.id;
        try {
          if (!this.form.id) {
            const storeItemResponse = await StoreItem.addStoreItem(storeItemPayload);
            storeItemId = storeItemResponse.id;
          }
          else {
            await StoreItem.updateStoreItem(storeItemPayload);
          }

          if (this.applyPriceToAll === true) {
            await Item.fetchItem(this.item.id);
          }

          return storeItemId;
        }

        catch (error) {
          this.$_onRequestError({
            toastOptions: { message: `An error occured while updating <b>${this.item.displayName}</b>` },
            error
          });
        }
      }
    }
  };
</script>

<style lang="sass" scoped>
  .item-description
    color: $grey
    line-height: 1.2

  .modal-card-body
    max-width: 420px
</style>
