<template>
  <validated-form
    ref="form"
    auto-focus
    form-id="departmentModal"
    :disabled="!$can('update', 'Department')"
    @valid-submit="handleSubmit"
  >
    <modal-card :title="`${department.id ? 'Update' : 'Add'} Department`">
      <validated-text-input
        v-model="form.name"
        label="Name"
        name="name"
        type="text"
        rules="required|max:100"
        expanded
      />

      <image-upload
        v-slot="{imagePath}"
        v-model="departmentImage"
        :accepted-types="['png', 'jpeg', 'jpg', 'svg']"
        :image-size-warning-height="228"
        :image-size-warning-width="750"
        :maximum-file-size-in-mb="1"
        restrict-file-size
        is-full-width
        :image-url="form.photoUrl"
        label="Department Image (Optional)"
        clear-only
        clear-text="Clear Image"
        :disabled="!$can('update', 'Department')"
      >
        <img
          v-if="imagePath"
          style="max-width: 500px"
          :src="imagePath"
          alt="department image"
        >
      </image-upload>

      <div v-if="departmentTippingOptionsForm.length">
        <p class="title is-6">Tipping</p>
        <tipping-option-group
          v-model="departmentTippingOptionsForm"
          :tipping-option-type-id="tippingOptionTypes.TIP_ONLY"
          :disabled="!$can('update', 'Department')"
          :tipping-method-type-id="departmentTippingOptionsForm[0].tippingOptionMethodTypeId"
        />
      </div>


      <template #footer>
        <div class="buttons all-bold">
          <b-button
            rounded
            @click="$_confirmCloseModal({ programmatic: true })"
          >
            Cancel
          </b-button>
          <b-button
            v-tabbable
            rounded
            native-type="submit"
            type="is-primary"
            :loading="isSubmitting"
          >
            Save
          </b-button>
        </div>
      </template>
    </modal-card>
  </validated-form>
</template>

<script>
  import confirmModalCloseMixin from '@/mixins/confirm-modal-close';
  import merchantMixin from '@/mixins/merchant';
  import { capitalCase } from 'change-case';
  import Department from '@/store/classes/Department';
  import MerchantTippingOption from '@/store/classes/MerchantTippingOption';
  import alertModal from '@/components/globals/alert-modal.vue';
  import tippingOptionGroup from '@/components/globals/tipping-option-group.vue';
  import { tippingOptionTypes } from '@/constants/merchantTippingOptions';
  import shareEmployeeDepartmentModal from './share-employee-department-modal.vue';

  export default {
    name: 'AddEditDepartmentModal',

    components: { tippingOptionGroup },

    mixins: [confirmModalCloseMixin, merchantMixin],

    props: {
      department: {
        type: Object,
        required: true
      },

      merchantTippingOptions: {
        type: Array,
        required: true
      }
    },

    data() {
      return {
        form: null,
        departmentImageFile: null,
        departmentTippingOptionsForm: [],
        departmentTippingOptionsCache: null,
        tippingOptionTypes
      };
    },

    computed: {
      isSubmitting() {
        return Department.$state().submitting || MerchantTippingOption.$state().submitting;
      },

      departmentTippingOptions() {
        return this.merchantTippingOptions.filter(to => to.departmentId && to.departmentId === this.department.id);
      },

      departmentImage: {
        get() {
          return this.departmentImageFile;
        },

        set(value) {
          if (!value) {
            this.deleteImage();
          }
          else {
            this.departmentImageFile = value;
          }
        }
      }
    },

    created() {
      this.onCreated();
    },

    methods: {
      capitalCase,

      async onCreated() {
        this.form = this.$clone(this.department);
        this.departmentTippingOptionsForm = this.departmentTippingOptions.length
          ? this.$clone(this.departmentTippingOptions)
          : this.$clone(this.merchantTippingOptions).filter(to => !to.departmentId);
        this.departmentTippingOptionsCache = JSON.stringify(this.departmentTippingOptionsForm);
      },

      async createDepartmentImage() {
        try {
          return await Department.createDepartmentImage(this.departmentImage);
        }
        catch (error) {
          this.$_onRequestError({
            error,
            toastOptions: {
              message: 'There was an error uploading your department image'
            }
          });
        }
      },

      deleteImage() {
        this.form.photoUrl = null;
        this.departmentImageFile = null;
      },

      async handleTippingOptionsSubmit(departmentId) {
        const shouldSaveTippingOptions = this.departmentTippingOptionsCache !== JSON.stringify(this.departmentTippingOptionsForm);
        if (shouldSaveTippingOptions) {
          await MerchantTippingOption.setMerchantTippingOptions({
            merchantTippingOptions: this.departmentTippingOptionsForm.map(dto => ({ ...dto, departmentId })),
            merchantId: this.$_selectedMerchantId
          });
        }
      },

      openShareQRCodeModal(department) {
        this.$buefy.modal.open({
          parent: this,
          component: shareEmployeeDepartmentModal,
          hasModalCard: true,
          trapFocus: true,
          canCancel: false,
          customClass: 'auto-width',
          props: { resource: department, type: 'department' }
        });
      },

      async handleSubmit() {
        try {
          let updatedDepartment;
          const formToSubmit = this.$clone(this.form);
          const imageHasNotChanged = this.department?.id && this.form.photoUrl === this.department.photoUrl;

          if (this.departmentImageFile) {
            formToSubmit.photoUrl = await this.createDepartmentImage(this.departmentImageFile);
          }
          else if (imageHasNotChanged) {
            delete formToSubmit.photoUrl;
          }

          if (this.department.id) {
            updatedDepartment = await Department.updateDepartment(formToSubmit);
          }
          else {
            updatedDepartment = await Department.createDepartment(formToSubmit);
          }

          await this.handleTippingOptionsSubmit(updatedDepartment.id);

          this.$buefy.modal.open({
            parent: this,
            component: alertModal,
            hasModalCard: true,
            trapFocus: true,
            canCancel: false,
            customClass: 'auto-width',
            props: {
              buttons: [
                { text: 'Okay' },
                { text: 'Share Barcode', primary: true, onClick: () => this.openShareQRCodeModal(updatedDepartment) }
              ],
              showCloseButton: false,
              icon: 'check-circle',
              iconPack: 'far',
              title: this.department.id ? 'Department Updated' : 'Department Created',
              message: `We've successfully ${this.department.id ? 'updated' : 'created'} this department!`,
              type: 'is-success'
            }
          });

          this.$_onRequestSuccess({
            toastOptions: {
              message: `Successfully ${this.department.id ? 'updated' : 'created'} your department!`
            },
            options: {
              closeParent: true
            }
          });
        }
        catch (error) {
          this.$_onRequestError({
            error,
            toastOptions: {
              message: `There was an error ${this.department.id ? 'updating' : 'creating'} your department`
            }
          });
        }
      }
    }
  };
</script>

<style lang="sass" scoped>
  ::v-deep
    .tip-options:last-child
      padding-top: 0 !important
</style>
