import { Model } from '@vuex-orm/core';
import currency from 'currency.js';


export default class _Order extends Model {
  // FIELDS //////////////////////
  static fields() {
    return {
      attachmentUrl: this.attr(null),
      category: this.attr(null),
      createdDate: this.attr(null),
      customerOnSite: this.boolean(false),
      delivery: this.attr(null),
      deliveryCharges: this.number(0),
      discount: this.number(0),
      email: this.attr(null),
      externalOrderId: this.attr(null),
      externalPaymentAmount: this.attr(null),
      features: this.attr(null),
      fulfillmentType: this.attr(null),
      groupOrder: this.attr(null),
      guestFullName: this.attr(null),
      internalNote: this.attr(null),
      isGuest: this.boolean(false),
      loyaltyEngagementSummary: this.attr(null),
      menuType: this.attr(null),
      merchantId: this.attr(null),
      merchantOrderReference: this.attr(null),
      offers: this.attr(null),
      onSiteVehicle: this.attr(null),
      orderDate: this.attr(null),
      orderFees: this.attr(() => []),
      orderId: this.attr(null),
      orderItemCount: this.number(0),
      orderItems: this.attr(() => []),
      orderItemTotal: this.number(0),
      orderLevelInstructions: this.attr(null),
      orderProviderType: this.attr(null),
      orderType: this.attr(null),
      partnerOrderIdentifier: this.attr(null),
      payments: this.attr(() => []),
      paymentState: this.attr(null),
      phoneNumber: this.attr(null),
      pickupDate: this.attr(null),
      platformType: this.attr(null),
      preparationStatus: this.attr(null),
      serviceCharges: this.number(0),
      shipment: this.attr(null),
      shipmentFee: this.number(0),
      status: this.attr(''),
      store: this.attr(() => ({})),
      subtotal: this.number(0),
      surcharge: this.number(0),
      tax: this.number(0),
      ticketNumber: this.attr(null),
      tableNumber: this.attr(null),
      tip: this.number(0),
      total: this.number(0),
      totalWithTipAndCharges: this.number(0),
      accountId: this.attr(null),

      sortOrder: this.attr(null), // used internally for retrieving Orders in the same order they were received from the endpoint
      salesTransactionCompletedEngagementSummary: this.attr(null) // not part of order details response yet
    };
  }

  get orderTotalWithExternalPayment() {
    if (this.externalPaymentAmount) {
      return currency(this.totalWithTipAndCharges).subtract(currency(this.externalPaymentAmount))?.value;
    }
    return this.totalWithTipAndCharges;
  }
}
