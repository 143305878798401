<template>
  <panel :title="`History (${orderSummaryMetaData.count || 0})`" collapsible start-open :loading="isFetchingOrderSummaries">
    <template #buttons>
      <b-button
        type="is-primary"
        rounded
        size="is-medium"
        class="is-bold"
        @click="openClaimOrderModal"
      >
        Claim Order
      </b-button>
    </template>
    <b-table
      :data="isFetchingOrderSummaries ? [] : orderSummaries"
      :class="[
        'is-middle-aligned no-header-wrap row-clickable'
      ]"
      hoverable
      paginated
      scrollable
      :total="orderSummaryMetaData.count"
      :current-page="page"
      :per-page="50"
      backend-pagination
      pagination-position="bottom"
      :mobile-cards="false"
      :backend-sorting="true"
      :default-sort="['order_date', 'desc']"
      @cellclick="openOrderModal"
      @page-change="handlePageChange"
      @sort="handleSort"
    >
      <template slot="empty">
        <empty-table-loader
          :loading="isFetchingOrderSummaries"
          :has-icon="false"
          message="No Orders found for this account"
        />
      </template>
      <b-table-column
        v-slot="{ row }"
        sortable
        field="order_date"
        label="Date"
        cell-class="no-wrap-text"
      >
        {{ row.orderDate | moment('MMM Do, YYYY @ h:mm A z', row.storeTimezone) }}
      </b-table-column>
      <b-table-column
        v-slot="{ row }"
        centered
        label="Order Number"
        cell-class="no-wrap-text"
      >
        <template v-if="row.ticketNumber">
          {{ row.ticketNumber }}
        </template>
        <span v-else class="has-text-grey-light">
          N/A
        </span>
      </b-table-column>

      <b-table-column
        v-slot="{ row }"
        centered
        label="Order Status"
        cell-class="no-wrap-text"
      >
        <b-tag :class="getOrderStatus(row.orderStatus).toLowerCase()">
          {{ getOrderStatus(row.orderStatus) }}
        </b-tag>
      </b-table-column>
    </b-table>
  </panel>
</template>

<script>
  import orderStatus from '@/constants/orderStatus';
  import OrderSummary from '@/store/classes/OrderSummary';
  import orderModal from '@/components/pages/orders/order-modal.vue';
  import claimOrderModal from '@/components/pages/registered-guest/modals/claim-order-modal.vue';

  export default {
    name: 'RegisteredGuestOrderHistory',
    props: {
      registeredGuest: {
        type: Object,
        required: true
      }
    },

    data() {
      return {
        page: 1,
        sortField: 'order_date',
        sortDirection: 'desc'
      };
    },

    computed: {
      isFetchingOrderSummaries() {
        return OrderSummary.$state().fetching;
      },

      orderSummaries() {
        return OrderSummary.query().where('accountId', this.registeredGuest.id).orderBy('sortOrder').get();
      },

      orderSummaryMetaData() {
        return OrderSummary.$state().orderSummaryMetaData;
      }
    },

    created() {
      this.onCreated();
    },

    methods: {
      getOrderStatus(orderStatusId) {
        return orderStatus[orderStatusId];
      },

      openOrderModal(order) {
        this.$buefy.modal.open({
          canCancel: ['escape', 'outside'],
          component: orderModal,
          customClass: 'auto-width',
          hasModalCard: true,
          parent: this,
          props: { orderId: order.id }
        });
      },

      openClaimOrderModal() {
        this.$buefy.modal.open({
          canCancel: ['escape', 'outside'],
          component: claimOrderModal,
          customClass: 'auto-width',
          hasModalCard: true,
          parent: this,
          props: { account: this.registeredGuest }
        });
      },

      async onCreated() {
        await this.fetchRegisteredGuestOrders();
      },

      handlePageChange(page) {
        this.page = page;
        this.fetchRegisteredGuestOrders({ page });
      },

      handleSort(sortField, sortDirection) {
        this.sortField = sortField;
        this.sortDirection = sortDirection;
        this.page = 1;
        this.fetchRegisteredGuestOrders({ sortField, sortDirection });
      },

      async fetchRegisteredGuestOrders({
        sortField = this.sortField,
        sortDirection = this.sortDirection,
        page = this.page
      } = {}) {
        try {
          await OrderSummary.fetchOrdersByAccount({ page, sortField, sortDirection, accountId: this.registeredGuest.id });
        }
        catch (error) {
          this.$_onRequestError({
            toastOptions: {
              message: 'There was an issue fetching this user\'s orders'
            },
            error
          });
        }
      }
    }
  };
</script>

<style lang="sass" scoped>
  ::v-deep
    .tag
      font-weight: 700

      &.fulfilled
        background-color: lighten($success, 37)
        color: darken($success, 40)
      &.cancelled
        background-color: lighten($danger, 33)
        color: darken($danger, 40)
</style>
