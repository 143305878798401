<template>
  <div>
    <div class="search-section">
      <div class="search-bar">
        <!-- Search query input + fields (if provided) -->
        <template v-if="searchFields && searchFields.length">
          <b-field
            class="search-inputs"
            label-position="on-border"
            label="Criteria"
          >
            <div class="control">
              <b-select v-model="selectedSearchField">
                <option
                  v-for="option in searchFields"
                  :key="option.value"
                  :value="option.value"
                >
                  {{ option.label }}
                </option>
              </b-select>
            </div>
            <div class="control is-expanded mar-r">
              <validated-text-input
                ref="searchInput"
                v-model="query"
                :icon-right-clickable="!!query"
                :icon-right="query ? 'times-circle': ''"
                :placeholder="searchFieldPlaceholder"
                class="search-input"
                icon="search"
                label="Search"
                hide-label
                name="query-input"
                :type="queryInputType(selectedSearchField)"
                :maxlength="String(computedMaxLength)"
                :mask-options="maskedOption"
                @icon-right-click="query = ''"
                @keydown.native.esc="query = ''"
              />
            </div>
          </b-field>
        </template>
        <template v-else>
          <b-field class="search-inputs">
            <validated-text-input
              ref="searchInput"
              v-model="query"
              :icon-right-clickable="!!query"
              :icon-right="query ? 'times-circle': ''"
              :placeholder="searchPlaceholder"
              class="search-input"
              icon="search"
              label="Search"
              hide-label
              name="query-input"
              type="text"
              @icon-right-click="query = ''"
              @keydown.native.esc="query = ''"
            />
          </b-field>
        </template>

        <template v-if="filters.length">
          <b-field
            v-for="filter in filters"
            :key="filter.key"
            label-position="on-border"
            :label="showFilterLabel(filter) ? filter.placeholder : ''"
            class="mar-none"
          >
            <b-dropdown
              v-if="filter.multiple"
              ref="dropdown"
              :value="selectedValues[filter.key]"
              scrollable
              multiple
              max-height="300px"
              aria-role="list"
              :mobile-modal="false"
              class="has-extra-shadow search-dropdown"
              :position="'is-bottom-right'"
              :style="{ maxHeight: filter.maxHeight }"
              @input="handleFilterChange(filter.key, $event)"
            >
              <dropdown-button
                slot="trigger"
                :placeholder="filter.placeholder"
                :value="filter.formatselectedValues
                  ? filter.formatselectedValues(selectedValues[filter.key])
                  : selectedValues[filter.key] && selectedValues[filter.key].join(', ') | capitalize"
                :style="{ minWidth: filter.minWidth }"
              />
              <b-dropdown-item
                :class="(!selectedValues[filter.key] || !selectedValues[filter.key].length) && 'is-active'"
                :style="{ minWidth: filter.minWidth }"
              >
                {{ filter.isAllSelectedPlaceholder || '-' }}
              </b-dropdown-item>
              <hr class="dropdown-divider">
              <b-dropdown-item
                v-for="option in filter.options"
                :key="option.value"
                :value="option.value"
                aria-role="listitem"
              >
                {{ option.name }}
              </b-dropdown-item>
            </b-dropdown>

            <b-select
              v-else
              :value="selectedValues[filter.key]"
              :placeholder="filter.placeholder"
              class="select-input"
              expanded
              @input="handleFilterChange(filter.key, $event)"
            >
              <option :value="null">–</option>
              <option v-for="option in filter.options" :key="option.value" :value="option.value">
                {{ option.name }}
              </option>
            </b-select>
          </b-field>
        </template>

        <!--
          Additional filters slot - for custom filter components that need specific UI/UX
          (e.g. date pickers, range sliders) or don't fit the standard filter structure
        -->
        <slot name="additional-filters" />

        <b-button
          :disabled="isEmpty"
          icon-left="times"
          outlined
          class="clear-button"
          @click="clearAll"
        >
          Clear
        </b-button>

        <!-- Result count and optional actions -->
        <div :class="['is-flex justify-between align-center is-full-width', { 'is-invisible': loading || !metadata.total }]">
          <slot name="result-count" />
          <slot name="optional-actions" />
        </div>
      </div>
    </div>

    <!-- Table -->
    <div :class="[tableWrapperClass, 'table-section']">
      <b-table
        :data="loading ? [] : searchResources"
        paginated
        backend-pagination
        backend-sorting
        :mobile-cards="false"
        :scrollable="scrollable"
        :hoverable="hoverable"
        :total="metadata.total"
        :per-page="metadata.perPage"
        :current-page="metadata.page"
        :default-sort-direction="metadata.sortDirection"
        :default-sort="[metadata.sortField, metadata.sortDirection]"
        :class="tableClass"
        @sort="onSort"
        @page-change="setPage"
      >
        <!-- b-table-column slots -->
        <slot />

        <template #empty>
          <slot name="empty">
            <empty-table-loader :loading="loading" />
          </slot>
        </template>

        <template #bottom-left>
          <div class="left-side is-flex">
            <page-number-select
              :page="metadata.page"
              :per-page="Number(metadata.perPage)"
              :total-count="metadata.total"
              @input="setPage"
            />
            <b-field
              v-if="showPerPageSelect"
              label-for="perPage"
              label-position="on-border"
              label="Per Page"
              class="mar-l mar-none"
            >
              <b-select id="perPage" :value="perPage" @input="setPerPage">
                <option value="10">10</option>
                <option value="20">20</option>
                <option value="50">50</option>
                <option value="100">100</option>
              </b-select>
            </b-field>
          </div>
        </template>
      </b-table>
    </div>
  </div>
</template>

<script>
  import debounce from 'lodash.debounce';
  import { mapGetters, mapActions } from 'vuex';

  export default {
    name: 'BackendSearchableTable',

    props: {
      searchPlaceholder: {
        type: String,
        default: 'Search...'
      },
      searchFields: {
        // [
        //   { value: 'name', label: 'Name' },
        //   { value: 'email', label: 'Email' }
        // ];
        type: Array,
        default: null,
        validator(array) {
          return array === null || array.every(option => typeof option.value === 'string'
            && typeof option.label === 'string');
        }
      },
      filters: {
        // [
        //   {
        //     placeholder: 'Filter by Role',
        //     key: 'role',
        //     default: 'admin',
        //     multiple: true,
        //     options: [
        //       { name: 'Admin', value: 'admin' },
        //       { name: 'Operations', value: 'operations' }
        //     ],
        //     formatselectedValues: (values) => `Selected Roles (${values.length})`,
        //     isAllSelectedPlaceholder: 'All',
        //     alwaysShowLabel: true,   // Selected values will be joined with a comma
        //     maxHeight: '325px',     // Optional max height for the b-dropdown
        //     minWidth: '150px',      // Optional min width for the dropdown button and items
        //   }
        // ]
        type: Array,
        default: () => [],
        validator(array) {
          return array.every(obj => [
            typeof obj.key === 'string',
            Array.isArray(obj.options) && obj.options.every((item) => {
              const allObjects = Object.prototype.toString.call(item) === '[object Object]';
              const hasCorrectProperties = Object.prototype.hasOwnProperty.call(item, 'name') && Object.prototype.hasOwnProperty.call(item, 'value');
              return allObjects && hasCorrectProperties;
            }),
            // Optional formatter for selected values
            obj.formatselectedValues === undefined || typeof obj.formatselectedValues === 'function'
          ].every(x => x));
        }
      },
      customSortHandler: {
        type: Function,
        default: null
      },
      queryInputType: {
        type: Function,
        default: () => 'text'
      },
      maskOptions: {
        type: Function,
        default: () => null
      },
      maxLength: {
        type: Function,
        default: () => null
      },
      scrollable: {
        type: Boolean,
        default: false
      },
      hoverable: {
        type: Boolean,
        default: false
      },
      tableClass: {
        // will be applied to the b-table
        type: [String, Array],
        default: ''
      },
      tableWrapperClass: {
        // will be applied to the div wrapping the table
        type: [String, Array],
        default: ''
      },
      loading: {
        type: Boolean,
        default: false
      },
      perPageOptions: {
        type: Array,
        default: () => [10, 20, 50, 100]
      },
      showPerPageSelect: {
        type: Boolean,
        default: false
      }
    },

    data() {
      return {
        query: '',
        lastSearchedQuery: '',
        selectedSearchField: this.searchFields?.length ? this.searchFields[0].value : null,
        selectedValues: {}
      };
    },

    computed: {
      ...mapGetters('backendSearch', [
        'searchResources',
        'metadata',
        'searchError'
      ]),

      isEmpty() {
        const isFiltersEmpty = Object.values(this.selectedValues).every(v => !v);
        return isFiltersEmpty && !this.query;
      },

      maskedOption() {
        return this.maskOptions(this.selectedSearchField);
      },

      computedMaxLength() {
        return this.maxLength(this.selectedSearchField);
      },

      searchFieldPlaceholder() {
        if (!this.searchFields || !this.selectedSearchField) return this.searchPlaceholder;

        const selectedOption = this.searchFields.find(option => option.value === this.selectedSearchField);
        return `Search by ${selectedOption.label}`;
      }
    },

    watch: {
      query: {
        handler: debounce(async function (newQuery) { // eslint-disable-line
          this.setSearchTerm(newQuery);

          if (newQuery !== null && newQuery !== this.lastSearchedQuery) {
            await this.performSearch({
              searchField: this.selectedSearchField,
              searchTerm: newQuery,
              page: 1
            });
            this.lastSearchedQuery = newQuery;
          }
        }, 666)
      },
      selectedSearchField(oldField, newField) {
        this.setSearchField(newField);

        if (oldField !== newField && this.query) {
          this.clearSearch();
          this.performSearch({
            searchField: newField,
            searchTerm: '',
            page: 1
          });
        }
      },
      searchError(error) {
        if (error) {
          this.$_onRequestError({
            error,
            toastOptions: {
              message: 'An error occurred while performing your search'
            }
          });
        }
      }
    },

    methods: {
      ...mapActions('backendSearch', [
        'performSearch',
        'setSort',
        'setPage',
        'setPerPage',
        'setFilters',
        'setSearchTerm',
        'setSearchField',
        'resetSearchParams'
      ]),

      handleFilterChange(key, newValue) {
        if (Array.isArray(newValue) && newValue.some(value => !value)) {
          newValue = null;
        }

        this.selectedValues = {
          ...this.selectedValues,
          [key]: newValue
        };

        this.setFilters(this.selectedValues);
      },

      showFilterLabel(filter) {
        if (filter.alwaysShowLabel) return true;

        return !!(Array.isArray(this.selectedValues[filter.key])
          ? this.selectedValues[filter.key].length
          : this.selectedValues[filter.key]);
      },

      onSort(field, direction) {
        if (this.customSortHandler) {
          const customSortParams = this.customSortHandler(field, direction);
          this.performSearch(customSortParams);
        }
        else {
          this.setSort({ field, direction });
        }
      },

      clearSearch() {
        // We need to clear lastSearchedQuery as well to skip the watcher
        this.lastSearchedQuery = '';
        this.query = '';
      },

      clearAll() {
        this.selectedValues = {};
        this.clearSearch();
        this.resetSearchParams();
      }
    }
  };
</script>

<style lang="sass" scoped>
  .search-section
    padding: 0.75rem 1rem
    border-bottom: 1px solid $grey-lightest

  .search-bar
    display: flex
    flex-wrap: wrap
    gap: 0.75rem

  .search-inputs, .search-input
    flex-grow: 1
    min-width: 240px

  @media (max-width: $tablet)
    .search-input
      flex-basis: 350px

    .select-input,
    .clear-button
      flex-grow: 1
</style>
