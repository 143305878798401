import Merchant from '@/store/classes/Merchant';
import { mapGetters } from 'vuex';

const featurePermissions = {
  computed: {
    ...mapGetters('session', ['isLocationBasedUser', 'currentUser']),

    $_selectedMerchantId() {
      return Merchant.$state().selectedMerchantId;
    },

    $_selectedMerchant() {
      return Merchant.query().with('*').find(this.$_selectedMerchantId);
    },

    $_featurePermissions() {
      return {
        MERCHANTS: !this.$_selectedMerchantId,
        USERS: !this.$_selectedMerchantId || (this.$_selectedMerchant?.features.showUserManagement && this.$can('create', 'User')),
        MERCHANT_CONFIGURATION: this.$_selectedMerchantId && this.$can('read', 'merchant_configuration'),
        HOTEL_CONFIGURATION: this.$_selectedMerchant?.features.hotelMobileTipping && this.$can('read', 'MerchantTippingOption'),
        WEB_APP_CONFIGURATION: this.$can('read', 'MerchantOption'),
        KDS: this.$_selectedMerchant?.features.showKds && this.$can('manage_kds', 'StoreMapping'),
        OPD: this.$_selectedMerchant?.features.showOpd && this.$can('manage_opd', 'StoreMapping'),
        CONTENT_MANAGEMENT: this.$_selectedMerchant?.features.contentManagement && this.$can('read', 'content_management'),
        contentManagement: {
          CONTACT_US: this.$_selectedMerchant?.features.showContactUs,
          COMMUNICATIONS: this.$_selectedMerchant?.features.showCommunications,
          RECEIPT_INSTRUCTIONS: this.$_selectedMerchant?.features.showReceiptInstructions
        },
        LOCATION_MANAGEMENT: this.$can('read', 'location_management'),
        ORDERS: this.$can('search_orders', 'Merchant')
            && [
              this.$_selectedMerchant?.orderAheadEnabled,
              this.$_selectedMerchant?.patEnabled
            ].some(x => x),
        LOCATION_MENU: this.$_selectedMerchant?.features.menuManagement && this.$can('read', 'StoreMenuItem'),
        MASTER_MENU: this.$_selectedMerchant?.features.menuManagement && this.$can('read', 'master_menu'),
        POS_MENU: this.$_selectedMerchant?.features.showPosMenu && this.$can('read', 'PosMenuItem'),
        REGIONAL_MENU: this.$_selectedMerchant?.features.menuManagement && this.$_selectedMerchant?.features.regionalMenus && this.$can('read', 'RegionMenu'),
        REPORTING: this.$_selectedMerchant?.features.tableauAnalytics && this.$can('read', 'TableauJwtToken', 'dashboardUrl'),
        LOYALTY_REPORTING: this.$_selectedMerchant?.features.tableauLoyaltyAnalytics && this.$can('read', 'TableauJwtToken', 'loyaltyDashboardUrl'),
        CAMPAIGNS: this.$_selectedMerchant?.features.campaigns && this.$can('read', 'Campaign') && !this.isLocationBasedUser,
        CAMPAIGN_TEXTS: this.$_selectedMerchant?.features.campaignsText && this.$can('read', 'Campaign'),
        LOYALTY: this.$_selectedMerchant?.features.loyalty && this.$can('read', 'Loyalty::Program') && !this.isLocationBasedUser,
        DATA_EXPORTS: this.$can('create', 'SprocDataExports::DataExporter'),
        SEGMENTATIONS: this.$_selectedMerchant?.features.campaigns && this.$can('read', 'Campaign') && !this.isLocationBasedUser,
        OFFERS: this.$_selectedMerchant?.features.showOffers && this.$can('read', 'Offers::Offer') && !this.isLocationBasedUser,
        SURVEYS: this.$_selectedMerchant?.features.surveys && this.$can('edit', 'survey'),
        ACCOUNT_MANAGEMENT: this.$_selectedMerchant?.features.accountManagement
          && this.$can('read', 'Account')
          && !this.currentUser.userRole.hidePii,
        NESTED_MODIFIERS: this.$_selectedMerchant?.features.nestedModifiers && this.$can('read', 'MenuCategory'),
        MODIFIER_CODES: this.$_selectedMerchant?.features.modifierCodes && this.$can('read', 'PosMenuItem'),
        MENU_ITEM_UPC: this.$_selectedMerchant?.features.menuItemUpc,
        TIP_REPORTS: this.$_selectedMerchant?.features.hotelMobileTipping && this.$can('read', 'DepartmentEmployeeTip'),
        PAYMENT_TRANSACTIONS: this.$_selectedMerchant?.features.paymentTransactions && this.$can('search', 'Payments::PaymentTransactions::Search'),
        PROMO_CODES: this.$_selectedMerchant?.features.promoCodes && this.$can('read', 'Offers::OfferDistribution') && !this.isLocationBasedUser,
        SHARED_MODIFIER_GROUPS: this.$_selectedMerchant?.features.sharedModifierGroups,
        POS_DISCOUNTS: this.$can('read', 'PosDiscountConfiguration') && !this.isLocationBasedUser,
        GIFT_CARD_TRANSACTIONS: this.$_selectedMerchant?.features.giftCardTransactions && this.$can('search', 'Payments::GiftCardTransactions::Search'),
        ORDER_EXPO: this.$_selectedMerchant?.features.orderExpo,
        SHOW_FULL_GC_NUMBER: this.$_selectedMerchant?.features.showFullGcNumber,
        MAIN_MENU_V2: this.$_selectedMerchant?.features.mainMenuV2
      };
    }
  }
};



export default featurePermissions;
