<template>
  <takeover>
    <header class="has-text-centered mar-b-md">
      <img class="image is-inline is-64x64" src="/images/cardfree-icon-logo.svg" alt="Admin Portal - Cardfree">
      <h1 class="title">Consent to access with Stream</h1>
    </header>

    <h1>
      By selecting ‘Authorize’ you are agreeing to give permission for Stream to access your customers' data through the CardFree API platform
    </h1>

    <div class="mar-t-md has-text-centered">
      <b-button
        rounded
        type="is-primary"
        @click="handleAuthorize"
        :loading="isLoading"
        :disabled="isLoading"
      >
        Authorize
      </b-button>
      <b-button
        rounded
        type="is-light"
        @click="cancel"
        class="mar-l-lg"
        :loading="isLoading"
        :disabled="isLoading"
      >
        Cancel
      </b-button>
    </div>
  </takeover>
</template>

<script>
  import http from '@/services/http';

  export default {
    name: 'StreamConsent',

    data() {
      return {
        isLoading: false
      };
    },

    metaInfo() {
      return { title: 'Stream Consent' };
    },

    methods: {
      async handleAuthorize() {
        await this.consentStreamAccess();
      },

      cancel() {
        this.$router.replace({ name: 'home' });
      },

      async consentStreamAccess() {
        try {
          this.isLoading = true;

          const response = await http.post('sessions/stream_consent', {
            stream: {
              clientId: this.$route.query.clientId,
              responseType: this.$route.query.responseType,
            }
          });

          const authorizationCode = response.data.code;

          const redirectUriWithCode = `${this.$route.query.redirectUri}?code=${authorizationCode}`;
          window.location.replace(redirectUriWithCode);
        }
        catch (error) {
          this.$_onRequestError({
            error,
            toastOptions: { message: 'There was an error consenting access for Stream' }
          });
        } finally {
          this.isLoading = false;
        }
      }
    }
  };
</script>
