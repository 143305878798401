/* eslint-disable import/no-cycle */
import { Model } from '@vuex-orm/core';
import http from '@/services/http';
import { parseJson } from '@/helpers/misc';



export default class PaymentGateway extends Model {
  static entity = 'paymentGateways'

  static fields() {
    return {
      id: this.attr(''),
      name: this.attr(''),
      description: this.attr(''),
      paymentMethods: this.attr(''),
      apiConfiguration: this.attr('', parseJson),
      metaData: this.attr('', parseJson)
    };
  }

  // STATE //////////////////////
  static state() {
    return {
      fetching: false
    };
  }

  static $state() {
    return this.store().state.entities.paymentGateways;
  }


  // Actions
  static async fetchPaymentGateways() {
    if (!this.exists()) {
      try {
        this.commit((state) => {
          state.fetching = true;
        });

        const response = await http.get('payment_gateways');
        this.create({ data: response.data.paymentGateways });
      }
      catch (error) {
        throw error;
      }
      finally {
        this.commit((state) => {
          state.fetching = false;
        });
      }
    }
  }
}
