<template>
  <div class="is-grid gap-lg">
    <div class="is-flex-wrap is-flex-end-aligned gap-lg">
      <validated-input
        class="dropdown-field mar-none"
        :name="`itemType${formId}`"
        label="Item Type"
        tooltip="Select items, categories, or item groups that apply to this offer"
        :rules="{
          required: !isDraft
        }"
        data-test-id="item-type-select"
      >
        <b-select
          v-model="itemResourceType"
          placeholder="Select an item type..."
          class="is-inline-block"
          expanded
          :disabled="readOnly"
        >
          <option
            v-for="( type, key ) in itemResourceTypes"
            :key="key"
            :value="type"
          >
            {{ type }}
          </option>
        </b-select>
      </validated-input>
      <template v-if="itemResourceType === itemResourceTypes.ITEM">
        <radio-button
          v-model="itemGroupType"
          :name="`itemGroupType${formId}`"
          :disabled="readOnly"
          :native-value="itemGroupTypes.ALL_ITEMS"
          data-test-id="all-items-radio-button"
        >
          Any Item
        </radio-button>
        <radio-button
          v-model="itemGroupType"
          :name="`itemGroupType${formId}`"
          :disabled="readOnly"
          :native-value="itemGroupTypes.MENU_ITEMS"
          data-test-id="specific-items-radio-button"
        >
          Specific Items
        </radio-button>
      </template>
    </div>
    <div class="is-grid gap col-2">
      <div class="is-grid gap">
        <div v-if="itemGroupType === itemGroupTypes.MENU_ITEMS || itemResourceType === itemResourceTypes.CATEGORY">
          <validated-input
            :label="`Required ${itemGroupType === itemGroupTypes.MENU_ITEMS ? 'Items' : 'Item Categories'}`"
            name="requiredItems"
            :tooltip="`Items ${itemResourceType == itemResourceTypes.CATEGORY ? 'from selected categories ' : ''}that must be purchased for this offer to be valid.`"
          >
            <menu-search-input
              :read-only="readOnly"
              :resource-type="itemResourceType === itemResourceTypes.ITEM ? 'menuItems' : 'menuCategories'"
              :selected-resources="selectedResources"
              multiple
              @add-resource="addResource"
              @remove-resource="removeResource"
            />
          </validated-input>
        </div>
        <div class="is-grid gap col-2">
          <modifier-restrictions-search
            v-if="itemGroupType === itemGroupTypes.MENU_ITEMS || itemResourceType === itemResourceTypes.CATEGORY"
            :read-only="readOnly"
            :selected-resources="selectedResources"
            :required-modifications="itemGroup.requiredModifications"
            :is-category-search="itemResourceType === itemResourceTypes.CATEGORY"
            @update-required-modifications="updateRequiredModifications"
          />
          <excluded-item-attributes-dropdown
            v-if="itemGroupType === itemGroupTypes.ALL_ITEMS || itemResourceType === itemResourceTypes.CATEGORY"
            v-model="itemGroup.excludedItemAttributes"
            :read-only="readOnly"
            :form-name="`menuSearchForm${formId}`"
          />
        </div>
      </div>
      <div
        v-if="itemGroupType === itemGroupTypes.MENU_ITEMS || itemResourceType === itemResourceTypes.CATEGORY"
        class="is-flex mar-sm"
        :class="[
          selectedResources.length || missingResources.length
            ? 'align-start justify-start'
            : 'align-center pad-sm justify-center has-border-lg has-border-grey-lighter has-radius-large has-border-dashed' ]"
      >
        <p
          v-if="!selectedResources.length && !missingResources.length"
          class="has-text-grey-light has-text-centered"
        >
          Search for {{ itemGroupType === itemGroupTypes.MENU_ITEMS ? 'an item' : 'a category of items' }} to add to the purchase requirements list
        </p>
        <div class="is-flex-wrap gap">
          <div
            v-for="resource in selectedResources"
            :key="resource.id"
            class="tag is-primary is-flex-start-aligned is-light auto-height mar-none pad-y-xs pad-x-sm"
          >
            <div>
              <p class="align-self-start has-text-weight-bold">{{ resource.displayName }}</p>
              <p v-if="resource.posItems && resource.posItems.length" class="has-text-primary-dark">
                {{ resource.posItems[0].id }}
                <b-icon
                  v-if="resource.posItems.length > 1"
                  v-tippy="{
                    content: resource.posItems.map(posItem => posItem.id).join(', '),
                    maxWidth: 250,
                    delay: [150, 0]
                  }"
                  icon="tags"
                  size="is-small"
                  class="mar-l-xs"
                />
              </p>
            </div>

            <b-icon
              v-if="!readOnly"
              icon="times-circle"
              class="delete align-self-center mar-none mar-l-sm has-background-primary-dark has-hover-background-primary-medium"
              custom-size="fa-lg"
              @click.native="removeResource(resource)"
            />
          </div>
          <div
            v-for="resourceId in missingResources"
            :key="resourceId"
            class="tag is-danger is-flex-align-center is-light auto-height mar-none pad-y-sm pad-x-sm"
          >
            <div>
              <p
                v-tippy="{
                  content: 'This menu resource cannot be found',
                  maxWidth: 250,
                  delay: [150, 0]
                }"
                class="align-self-start has-text-weight-bold"
              >
                Unknown menu resource
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { itemAttributes, itemGroupTypes, itemResourceTypes } from '@/constants/items';

  export default {
    name: 'MenuSearchForm',

    props: {
      itemGroup: {
        type: Object,
        required: true
      },
      readOnly: {
        type: Boolean,
        default: false
      },
      formId: {
        type: Number,
        default: 1
      },
      isDraft: {
        type: Boolean,
        default: false
      }
    },

    data() {
      return {
        itemGroupTypes,
        itemResourceTypes,
        itemGroupLocal: {}
      };
    },

    computed: {
      itemResourceType: {
        get() {
          switch (this.itemGroupType) {
            case itemGroupTypes.ALL_ITEMS:
            case itemGroupTypes.MENU_ITEMS:
              return itemResourceTypes.ITEM;
            case itemGroupTypes.CATEGORIES:
              return itemResourceTypes.CATEGORY;
            default:
              return itemResourceTypes.ITEM_GROUP;
          }
        },
        set(itemResourceType) {
          if (itemResourceType === itemResourceTypes.ITEM) {
            this.itemGroup.updateType(itemGroupTypes.ALL_ITEMS);
          }
          else if (itemResourceType === itemResourceTypes.CATEGORY) {
            this.itemGroup.updateType(itemGroupTypes.CATEGORIES);
          }
          this.setLocalItemGroup();
        }
      },

      itemGroupType: {
        get() {
          return this.itemGroupLocal.itemGroupType;
        },
        set(itemGroupType) {
          const excludedItemAttributes = itemGroupType === itemGroupTypes.ALL_ITEMS
            ? Object.values(itemAttributes).map(itemAttr => itemAttr.id)
            : [];
          this.itemGroup.updateType(itemGroupType, excludedItemAttributes);
          this.setLocalItemGroup();
        }
      },

      selectedResources() {
        switch (this.itemResourceType) {
          case itemResourceTypes.ITEM:
            return this.itemGroupLocal.menuItems;
          case itemResourceTypes.CATEGORY:
            return this.itemGroupLocal.categoryObjects;
          case itemResourceTypes.ITEM_GROUP:
          default:
            return [];
        }
      },

      missingResources() {
        switch (this.itemResourceType) {
          case itemResourceTypes.ITEM:
            return this.itemGroupLocal.missingMenuItems();
          case itemResourceTypes.CATEGORY:
            return this.itemGroupLocal.missingCategories();
          case itemResourceTypes.ITEM_GROUP:
          default:
            return [];
        }
      }
    },

    created() {
      this.setLocalItemGroup();
    },

    methods: {
      setLocalItemGroup() {
        this.itemGroupLocal = this.$clone(this.itemGroup);
      },

      addResource(resource) {
        const updatedResources = [...this.selectedResources, resource];
        const resourceType = this.itemResourceType === itemResourceTypes.ITEM ? 'menuItems' : 'categoryObjects';
        const updatedIds = resourceType === 'menuItems' ? [...this.itemGroup.menuItemIds, resource.id] : [...this.itemGroup.categoryIds, resource.id];
        this.itemGroup.updateResources(resourceType, updatedResources, updatedIds);
        this.setLocalItemGroup();
      },

      removeResource(resource) {
        const filteredResources = this.selectedResources.filter(selectedResource => selectedResource.id !== resource.id);
        const resourceType = this.itemResourceType === itemResourceTypes.ITEM ? 'menuItems' : 'categoryObjects';
        const updatedIds = resourceType === 'menuItems'
          ? this.itemGroup.menuItemIds.filter(id => id !== resource.id)
          : this.itemGroup.categoryIds.filter(id => id !== resource.id);
        this.itemGroup.updateResources(resourceType, filteredResources, updatedIds);
        this.setLocalItemGroup();
      },

      updateRequiredModifications(modifications) {
        this.itemGroup.updateRequiredModifications(modifications);
        this.setLocalItemGroup();
      }
    }
  };
</script>
