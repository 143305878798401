/* eslint-disable import/no-cycle */
import Saveable from './_Saveable';
import http from '@/services/http';
import filterObjectKeys from '@/helpers/filter-object-keys';
import SurveyQuestion from './SurveyQuestion';

export default class Survey extends Saveable {
  static entity = 'surveys'

  static fields() {
    return {
      ...super.fields(),
      id: this.uid(),
      merchantId: this.attr(''),
      formType: this.attr(''),
      // merchant-facing
      name: this.attr(''),
      // user-facing
      title: this.attr(''),
      description: this.attr(''),
      isActive: this.attr(false),
      isDraft: this.attr(true),
      merchantFormPlatformType: this.attr(''),
      randomQuestionsEnabled: this.attr(false),
      numberOfRandomQuestions: this.attr(null),

      // FE-property
      isTemplate: this.attr(false),

      // Relationships
      questions: this.hasMany(SurveyQuestion, 'surveyId')
    };
  }



  // STATE //////////////////////

  static state() {
    return {
      fetching: false,
      fetchingSingle: false,
      fetchingTemplate: false,
      submitting: false,
      publishing: false,
      exporting: false
    };
  }

  static $state() {
    return this.store().state.entities.surveys;
  }



  // ACTIONS //////////////////////

  static async fetchSurveys() {
    try {
      this.commit((state) => {
        state.fetching = true;
      });

      const merchantId = this.store().state.entities.merchants.selectedMerchantId;

      const response = await http.get(`merchants/${merchantId}/surveys`);

      this.create({
        data: response.data.surveys
      });
    }
    catch (error) {
      throw error;
    }
    finally {
      this.commit((state) => {
        state.fetching = false;
      });
    }
  }

  static async fetchTemplate(formType) {
    try {
      this.commit((state) => {
        state.fetchingTemplate = true;
      });

      const merchantId = this.store().state.entities.merchants.selectedMerchantId;

      const { data } = await http.get(`merchants/${merchantId}/surveys/preview?formType=${formType}`);
      const templateSurvey = data.survey;

      templateSurvey.id = formType;
      templateSurvey.isTemplate = true;
      templateSurvey.questions = templateSurvey.questions.map((question, i) => {
        question.id = `${formType}-${i}`;
        question.sortOrder = i + 1;

        if (question.options.length) {
          question.options = question.options.map((option, j) => {
            option.id = `${formType}-${i}_${j}`;
            return option;
          });
        }

        return question;
      });

      this.insert({
        data: templateSurvey
      });
    }
    catch (error) {
      throw error;
    }
    finally {
      this.commit((state) => {
        state.fetchingTemplate = false;
      });
    }
  }

  static async fetchSurveyById(surveyId) {
    try {
      this.commit((state) => {
        state.fetchingSingle = true;
      });

      const merchantId = this.store().state.entities.merchants.selectedMerchantId;

      const response = await http.get(`merchants/${merchantId}/surveys/${surveyId}`);

      this.insert({
        data: response.data.survey
      });

      return response.data.survey;
    }
    catch (error) {
      throw error;
    }
    finally {
      this.commit((state) => {
        state.fetchingSingle = false;
      });
    }
  }

  static async addSurvey(survey) {
    try {
      this.commit((state) => {
        state.submitting = true;
      });
      const merchantId = this.store().state.entities.merchants.selectedMerchantId;
      const acceptedSurveyKeys = [
        'questions',
        'formType',
        'merchantFormPlatformType',
        'name',
        'description',
        'title',
        'randomQuestionsEnabled',
        'numberOfRandomQuestions'
      ];

      const acceptedQuestionKeys = [
        'questionType',
        'answerType',
        'label',
        'isActive',
        'alwaysInclude',
        'sortOrder',
        'required',
        'options'
      ];

      const surveyClone = JSON.parse(JSON.stringify(survey));

      surveyClone.questions = surveyClone.questions.map((question) => {
        question.options = question.options.map(option => filterObjectKeys(option, ['value', 'sortOrder', 'isActive']));
        return filterObjectKeys(question, acceptedQuestionKeys);
      });

      const response = await http.post(
        `merchants/${merchantId}/surveys`,
        { survey: filterObjectKeys(surveyClone, acceptedSurveyKeys) }
      );

      this.insert({ data: response.data.survey });

      return response.data.survey;
    }

    catch (error) {
      throw error;
    }

    finally {
      this.commit((state) => {
        state.submitting = false;
      });
    }
  }

  static async updateSurvey(survey) {
    try {
      this.commit((state) => {
        state.submitting = true;
      });
      const merchantId = this.store().state.entities.merchants.selectedMerchantId;
      const acceptedSurveyKeys = [
        'questions',
        'formType',
        'merchantFormPlatformType',
        'name',
        'description',
        'title',
        'isActive',
        'randomQuestionsEnabled',
        'numberOfRandomQuestions'
      ];

      const acceptedQuestionKeys = [
        'id',
        'questionType',
        'answerType',
        'label',
        'alwaysInclude',
        'isActive',
        'sortOrder',
        'required',
        'options'
      ];

      const surveyClone = JSON.parse(JSON.stringify(survey));

      surveyClone.questions = surveyClone.questions.map((question) => {
        if (String(question.id).includes('$')) {
          delete question.id;
        }
        question.options = question.options.map((option) => {
          if (String(option.id).includes('$')) {
            delete option.id;
          }
          return filterObjectKeys(option, ['value', 'sortOrder', 'isActive', 'id']);
        });
        return filterObjectKeys(question, acceptedQuestionKeys);
      });

      const response = await http.put(
        `merchants/${merchantId}/surveys/${survey.id}`,
        { survey: filterObjectKeys(surveyClone, acceptedSurveyKeys) }
      );

      this.update({ data: response.data.survey });
    }

    catch (error) {
      throw error;
    }

    finally {
      this.commit((state) => {
        state.submitting = false;
      });
    }
  }

  static async publishSurvey(surveyId) {
    try {
      this.commit((state) => {
        state.publishing = true;
      });
      const merchantId = this.store().state.entities.merchants.selectedMerchantId;

      const response = await http.put(
        `merchants/${merchantId}/surveys/${surveyId}/publish`
      );

      this.update({ data: response.data.survey });
    }

    catch (error) {
      throw error;
    }

    finally {
      this.commit((state) => {
        state.publishing = false;
      });
    }
  }

  static async exportSurveyResults({ surveyId, type = 'csv' }) {
    try {
      // Type can be JSON or CSV
      this.commit((state) => {
        state.exporting = true;
      });
      const merchantId = this.store().state.entities.merchants.selectedMerchantId;
      const response = await http.get(`merchants/${merchantId}/surveys/${surveyId}/results.${type}`);
      return response.data;
    }

    catch (error) {
      throw error;
    }

    finally {
      this.commit((state) => {
        state.exporting = false;
      });
    }
  }
}
