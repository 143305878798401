/* eslint-disable import/no-cycle */
import { Model } from '@vuex-orm/core';
import { parseJson } from '@/helpers/misc';

// classes
import http from '@/services/http';


export default class MerchantPaymentGateway extends Model {
  static entity = 'merchantPaymentGateways'



  // FIELDS //////////////////////
  static fields() {
    return {
      id: this.attr(''),
      storeId: this.attr(null),
      merchantId: this.attr(''),
      apiConfiguration: this.attr('', parseJson),
      isActive: this.attr(false),
      availablePaymentMethods: this.attr(''),
      createdAt: this.attr(''),
      paymentGateway: this.attr({}),
      paymentMethods: this.attr(null),
      externalDeviceCompatible: this.attr(null),
      updatedAt: this.attr(''),
      merchantReferenceField: this.attr(null)
    };
  }

  // STATE //////////////////////
  static state() {
    return {
      fetching: false,
      submitting: false
    };
  }

  static $state() {
    return this.store().state.entities.merchantPaymentGateways;
  }



  // ACTIONS //////////////////////
  static async fetchPaymentGateways(storeId) {
    try {
      this.commit((state) => {
        state.fetching = true;
      });

      const merchantId = this.store().state.entities.merchants.selectedMerchantId;
      const response = await http.get(`merchants/${merchantId}/merchant_payment_gateways${storeId ? `?storeId=${storeId}` : ''}`);

      response.data.merchantPaymentGateways.forEach((mpg) => {
        mpg.merchantId = merchantId;
      });

      this.insert({
        data: response.data.merchantPaymentGateways
      });
    }
    catch (error) {
      throw error;
    }
    finally {
      this.commit((state) => {
        state.fetching = false;
      });
    }
  }

  static async addPaymentGateway({ merchantPaymentGateway, merchantId }) {
    try {
      this.commit((state) => {
        state.submitting = true;
      });

      const response = await http.post(
        `merchants/${merchantId}/merchant_payment_gateways`,
        { merchantPaymentGateway }
      );

      response.data.merchantPaymentGateway.merchantId = merchantId;

      this.insert({
        data: response.data.merchantPaymentGateway
      });
    }
    catch (error) {
      throw error;
    }
    finally {
      this.commit((state) => {
        state.submitting = false;
      });
    }
  }

  static async updatePaymentGateway({
    id,
    apiConfiguration,
    isActive,
    externalDeviceCompatible,
    merchantReferenceField,
    storeId
  }, paymentMethods) {
    try {
      this.commit((state) => {
        state.submitting = true;
      });
      const response = await http.put(`merchant_payment_gateways/${id}`, {
        merchantPaymentGateway: {
          apiConfiguration,
          isActive,
          externalDeviceCompatible,
          merchantReferenceField,
          storeId
        },
        paymentMethods
      });

      this.update({
        data: response.data.merchantPaymentGateway
      });
    }
    catch (error) {
      throw error;
    }
    finally {
      this.commit((state) => {
        state.submitting = false;
      });
    }
  }

  static async removePaymentGateway(gatewayId) {
    try {
      this.commit((state) => {
        state.submitting = true;
      });

      await http.delete(`merchant_payment_gateways/${gatewayId}`);

      this.delete(gatewayId);
    }
    catch (error) {
      throw error;
    }
    finally {
      this.commit((state) => {
        state.submitting = false;
      });
    }
  }

  static async provisionCloverPaymentGateway(storeId) {
    try {
      const { data } = await http.post(`stores/${storeId}/provision_clover_payment_gateway`);

      this.insert({ data: data.merchantPaymentGateway });
    }
    catch (error) {
      throw error;
    }
  }
}
