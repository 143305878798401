<template>
  <div class="modal-card">
    <validated-form
      ref="surveyBuilderForm"
      :disable-save-button="false"
      auto-focus
      form-id="surveyBuilderForm"
      class="survey-builder-form"
      @valid-submit="handleSubmit"
      @invalid-submit="handleInvalidSubmit"
    >
      <header class="modal-card-head">
        <div class="modal-card-title">
          <p>{{ mode === 'create' ? 'Create' : 'Edit' }} Survey</p>
          <p class="subtitle is-6 mar-t-xs">
            {{ form.formType === surveyFormTypes.ORDER_SURVEY ? 'Cross Application' : capitalCase(form.formType) }}
            -
            {{ capitalCase(form.merchantFormPlatformType) }}
          </p>
        </div>
        <b-steps
          v-model="activeStepIndex"
          size="is-small"
          :destroy-on-hide="false"
          :has-navigation="false"
          :mobile-mode="null"
          class="steps-nav"
        >
          <b-step-item id="step-0" step="1" clickable label="Details" />
          <b-step-item id="step-1" step="2" clickable label="Content" />
          <b-step-item id="step-2" :clickable="!!form.questions.length" step="3" label="Review" />
        </b-steps>

        <div class="is-flex">
          <div class="is-flex action-buttons">
            <b-button
              id="save-draft-button"
              :disabled="!form.questions.length"
              rounded
              :loading="isSaving"
              icon-right="save"
              :type="activeStepIndex === 2 && form.isActive ? 'is-primary' : ''"
              native-type="submit"
            >
              Save
              <span v-if="form.isDraft">Draft</span>
            </b-button>

            <b-button
              v-if="activeStepIndex < 2"
              icon-right="chevron-right"
              rounded
              type="is-primary"
              :disabled="activeStepIndex === 1 && !form.questions.length"
              @click.prevent="activeStepIndex += 1"
            >
              Next Step
            </b-button>
            <b-button
              v-else-if="form.isDraft"
              id="publish-draft-button"
              rounded
              type="is-primary"
              :loading="isPublishing"
              icon-right="share"
              native-type="submit"
            >
              Publish
            </b-button>
            <b-button
              v-else-if="!form.isActive"
              id="save-and-publish-draft-button"
              rounded
              type="is-primary"
              :loading="isPublishing"
              icon-right="share"
              native-type="submit"
            >
              Save & Enable
            </b-button>
          </div>
          <b-button @click="$_confirmCloseModal({ programmatic: true })">
            <b-icon icon="times" size="is-small" />
          </b-button>
        </div>
      </header>
      <div class="modal-card-body">
        <div class="is-flex-center">
          <b-steps
            v-model="activeStepIndex"
            size="is-small"
            class="steps-container"
            :has-navigation="false"
            :mobile-mode="null"
          >
            <b-step-item id="step-0" step="1" clickable label="Details">
              <survey-details-inputs
                :handle-form-change="handleFormChanged"
                :mode="mode"
                :title="form.title"
                :form-type="form.formType"
                :description="form.description"
                :is-template="form.isTemplate"
                :name="form.name"
                :template-questions="templateQuestions"
              />
            </b-step-item>

            <b-step-item id="step-1" step="2" clickable label="Content">
              <survey-content-inputs
                :handle-form-change="handleFormChanged"
                :mode="mode"
                :form="form"
              />
            </b-step-item>

            <b-step-item id="step-2" :clickable="!!form.questions.length" step="3" label="Review">
              <survey-preview :survey="form" />
            </b-step-item>
          </b-steps>
        </div>
      </div>
    </validated-form>
  </div>
</template>



<script>
  import confirmModalCloseMixin from '@/mixins/confirm-modal-close';
  import merchantMixin from '@/mixins/merchant';

  import Survey from '@/store/classes/Survey';

  import surveyDetailsInputs from './survey-details-inputs.vue';
  import surveyContentInputs from './survey-content-inputs.vue';
  import surveyPreview from './survey-preview.vue';

  import surveyFormTypes from '@/constants/surveyFormTypes';

  import capitalCase from '@/helpers/capitalCase';



  export default {
    name: 'SurveyBuilderModal',

    components: {
      surveyDetailsInputs,
      surveyContentInputs,
      surveyPreview
    },

    mixins: [confirmModalCloseMixin, merchantMixin],

    props: {
      mode: {
        type: String,
        default: 'create',
        validator(value) {
          return ['create', 'read', 'update'].includes(value);
        }
      },

      surveyId: {
        type: [Number, String],
        required: true
      }
    },

    data() {
      return {
        capitalCase,
        surveyFormTypes,
        activeStepIndex: 0,
        isPublishing: false,
        isSaving: false,
        form: null
      };
    },

    computed: {
      survey() {
        return Survey.query().with('questions').find(this.surveyId);
      },

      templateQuestions() {
        return Survey
          .query()
          .where('id', this.survey.formType)
          .with('questions')
          .first()?.questions;
      }
    },

    watch: {
      'form.isTemplate': 'handleTemplateChange'
    },

    created() {
      this.onCreated();
    },


    methods: {
      onCreated() {
        this.form = JSON.parse(JSON.stringify(this.survey));
      },

      handleTemplateChange(isTemplate) {
        if (isTemplate) {
          this.form.questions = [...this.templateQuestions];
        }
        else {
          this.form.questions = this.survey.questions;
        }
      },

      handleFormChanged(updatedForm) {
        this.form = {
          ...this.form,
          ...updatedForm
        };
      },

      async createSurvey() {
        try {
          await Survey.addSurvey(this.form);
          this.$_onRequestSuccess({
            toastOptions: {
              message: 'Successfully created your survey!'
            },
            options: {
              closeParent: true
            }
          });
        }
        catch (error) {
          this.$_onRequestError({
            error,
            toastOptions: {
              message: 'There was an error while creating your survey'
            }
          });
        }
      },

      async updateSurvey() {
        try {
          await Survey.updateSurvey(this.form);
          this.$_onRequestSuccess({
            toastOptions: {
              message: 'Successfully updated your survey!'
            },
            options: {
              closeParent: true
            }
          });
        }
        catch (error) {
          this.$_onRequestError({
            error,
            toastOptions: {
              message: 'There was an error while updating your survey'
            }
          });
        }
      },

      handleSubmit($event) {
        switch ($event.submitter.id) {
          case 'save-draft-button':
            this.handleSave();
            break;
          case 'publish-draft-button':
          case 'save-and-enable-button':
            this.handlePublish();
            break;
          default:
            break;
        }
      },

      async handleDraft() {
        this.isSaving = true;
        switch (this.mode) {
          case 'create':
            await this.createSurvey();
            break;

          case 'update':
            await this.updateSurvey();
            break;

          default:
            break;
        }
        this.isSaving = false;
      },

      handleInvalidSubmit() { // TEST ?
        const firstInvalidStep = this.$el.querySelector('.invalid').closest('div[id^="step"]');
        const invalidStepIndex = Number(firstInvalidStep.id.split('-')[1]);
        this.activeStepIndex = invalidStepIndex;
      },

      async handleSave() {
        if (this.form.isDraft) {
          this.handleDraft();
        }
        else {
          this.$buefy.dialog.confirm({
            title: 'Save Survey',
            message: "Once saved, you won't be able to edit any new questions or answer choices. Are you sure you'd like to save this survey?",
            type: 'is-primary',
            confirmText: 'Save',
            onConfirm: this.handleDraft
          });
        }
      },

      async handlePublish() {
        let title = 'Save & Enable';
        let message = "Once saved, the survey will be enabled and you won't be able to edit any questions or answers. Unwanted questions can still be hidden. Are you sure you'd like to Save & Enable this survey?";
        let confirmText = 'Save & Enable';

        if (this.form.isDraft) {
          title = 'Publish Survey';
          message = "Once published, you won't be able to edit any questions or answers. Unwanted questions can be hidden. Are you sure you'd like to publish this survey?";
          confirmText = 'Publish';
        }

        this.$buefy.dialog.confirm({
          title,
          message,
          confirmText,
          type: 'is-primary',
          onConfirm: this.publishSurvey
        });
      },

      async publishSurvey() {
        this.isPublishing = true;

        const successMessage = this.form.isDraft
          ? 'Successfully published your survey!'
          : 'Successfully saved and enabled your survey!';

        const errorMessage = this.form.isDraft
          ? 'There was an issue publishing your survey'
          : 'There was an issue saving your survey';

        try {
          let surveyId = this.form.id;
          if (this.mode === 'create') {
            const newSurvey = await Survey.addSurvey(this.form);
            surveyId = newSurvey.id;
          }
          else {
            await Survey.updateSurvey(this.form);
          }

          await Survey.publishSurvey(surveyId);

          this.$_onRequestSuccess({
            toastOptions: { message: successMessage },
            options: { closeParent: true }
          });
        }

        catch (error) {
          this.$_onRequestError({
            error,
            toastOptions: { message: errorMessage }
          });
        }

        finally {
          this.isPublishing = false;
        }
      }
    }
  };
</script>

<style lang="sass" scoped>
  .survey-builder-form
    display: flex
    flex-direction: column
    flex: 1
    overflow: hidden

  .modal-card-head
    justify-content: space-between

  .modal-card-title
    flex-grow: 0

  .modal-card-body
    background-color: $grey-lightest

  .steps-container
    flex: 0 1 $widescreen

  .action-buttons
    padding-right: 1rem
    margin-right: 1rem
    border-right: 2px solid $grey-lighter
    > *:not(:last-child)
      margin-right: 1rem

  .steps-nav
    flex-grow: 1
    max-width: 500px
    ::v-deep
      .step-content
        display: none

  .steps-container ::v-deep
    .steps
      display: none
    .step-content
      padding: 0 !important

</style>
