<template>
  <div>

    <!-- SEARCH/FILTERS -->
    <div class="search-section pad-x pad-t">
      <b-field
        class="search-inputs"
        label-position="on-border"
        label="Criteria"
      >
        <div class="control">
          <b-select v-model="param">
            <option value="giftCardTransactionId">Transaction #</option>
            <option value="purchaserName">Purchaser Name</option>
            <option value="purchaserEmail">Purchaser Email</option>
            <option value="purchaserPhone">Purchaser Phone #</option>
            <option value="recipientName">Gift Recipient Name</option>
            <option value="recipientEmail">Gift Recipient Email</option>
            <option value="recipientPhone">Gift Recipient Phone #</option>
            <option value="cardNumberLastFour">Gift Card #</option>
            <option value="amount">Transaction Amount</option>
          </b-select>
        </div>
        <div class="control is-expanded mar-r">
          <validated-text-input
            v-model="query"
            :icon-right-clickable="!!query"
            :icon-right="query ? 'times-circle': ''"
            placeholder="Search"
            :type="queryInputType"
            class="query-input"
            icon="search"
            label-position="on-border"
            label="Search"
            hide-label
            :mask-options="maskOptions"
            :maxlength="param === 'cardNumberLastFour' ? '4' : null"
            name="query-input"
            expanded
            output-string
            @icon-right-click="query = ''"
            @keydown.native.esc="query = ''"
          />
        </div>
      </b-field>

      <div class="filters is-flex-wrap">
        <b-field label-position="on-border" label="Date Range" style="min-width: 230px">
          <b-datepicker
            v-model="dateRange"
            class="date-picker-input"
            icon="calendar-alt"
            range
            :min-date="ninetyDaysAgo"
            :max-date="today"
            :focused-date="focusedDate"
            :nearby-selectable-month-days="true"
            :class="['has-extra-shadow', isTodayDateRange && 'today-selected']"
            :mobile-native="false"
          >
            <div class="date-range-shortcuts">
              <b-button
                size="is-small"
                :type="isTodayDateRange ? 'is-primary' : ''"
                @click="setDateRange('today')"
              >
                Today
              </b-button>
              <b-button
                size="is-small"
                :type="isLastSevenDaysDateRange ? 'is-primary' : ''"
                @click="setDateRange('last-seven-days')"
              >
                Past 7 Days
              </b-button>
              <b-button
                size="is-small"
                :type="isLastThirtyDays ? 'is-primary' : ''"
                @click="setDateRange('last-thirty-days')"
              >
                Past 30 days
              </b-button>
              <b-button
                size="is-small"
                :type="isLastNinetyDaysRange ? 'is-primary' : ''"
                @click="setDateRange('last-ninety-days')"
              >
                Past 90 days
              </b-button>
            </div>
          </b-datepicker>
        </b-field>

        <b-field label-position="on-border" label="Transaction Type">
          <b-dropdown
            v-model="selectedTransactionTypes"
            :mobile-modal="false"
            aria-role="list"
            scrollable
            multiple
            max-height="325px"

            class="has-extra-shadow"
          >
            <dropdown-button
              slot="trigger"
              :value="transactionTypeDisplay"
              style="min-width: 150px"
            />
            <b-dropdown-item
              aria-role="listitem"
              :class="{'is-active': isAllSelected}"
              @click="handleSelectAllTransactionTypes"
            >
              All
            </b-dropdown-item>
            <hr class="dropdown-divider">
            <b-dropdown-item
              v-for="type in giftCardTransactionTypes"
              :key="type"
              :value="type"
              aria-role="listitem"
            >
              {{ capitalCase(type) }}
            </b-dropdown-item>
          </b-dropdown>
        </b-field>
      </div>
    </div>
    <div :class="['pad-x result-count is-flex justify-between align-center', { 'is-invisible': isLoadingGiftCardTransactions || !giftCardTransactionMetadata.count }]">
      <div>
        <p v-if="giftCardTransactionMetadata.count < 100">
          <span class="has-text-weight-bold has-text-black">
            {{ giftCardTransactionMetadata.count }}
          </span>
          Matching Transaction{{ giftCardTransactionMetadata.count > 1 ? 's' : '' }}
        </p>
        <template v-else>
          <p>
            Displaying
            <span class="has-text-weight-bold has-text-black">
              {{ giftCardTransactionMetadata.count }}
            </span>
            best results
          </p>
          <p class="is-size-7 has-text-grey">
            If you don't see the transaction that you're looking for, please enter a more specific search
          </p>
        </template>
      </div>

      <b-dropdown position="is-bottom-left" class="has-extra-shadow">
        <template #trigger>
          <b-button icon-left="columns">
            Columns
            <template v-if="hiddenColumns.length !== hidableColumnCount">
              ({{ visibleColumnCount }}/{{ hidableColumnCount }})
            </template>
          </b-button>
        </template>

        <b-dropdown-item custom>
          <div class="is-flex-column gap-sm">
            <b-checkbox
              v-for="[columnName, column] in Object.entries(hidableColumns)"
              :key="columnName + column.display"
              v-model="column.isVisible"
            >
              {{ column.display }}
            </b-checkbox>
            <b-button
              class="mar-t-sm"
              type="is-light is-primary"
              @click="Object.values(hidableColumns).forEach(c => c.isVisible = true)"
            >
              Select All
            </b-button>
          </div>
        </b-dropdown-item>
      </b-dropdown>
    </div>

    <!-- TABLE -->
    <div class="has-border-top has-border-grey-lighter card">
      <b-table
        ref="giftCardTransactionsTable"
        :data="isLoadingGiftCardTransactions ? [] : giftCardTransactions"
        paginated
        :total="giftCardTransactionMetadata.count"
        :current-page="page"
        :class="[
          'is-middle-aligned no-header-wrap',
          {[`overflow-x-custom-${$_scrollableDirectionX}`]: $_scrollableDirectionX}
        ]"
        scrollable
        :mobile-cards="false"
        :backend-pagination="true"
        :backend-sorting="true"
        hoverable
        :default-sort="['TransactionDateTime', 'desc']"
        :per-page="perPage"
        @page-change="handlePageChange"
        @sort="handleSort"
        @click="openGiftCardTransactionModal"
      >
        <template slot="empty">
          <empty-table-loader
            :loading="isLoadingGiftCardTransactions"
            :has-icon="false"
            :message="hasMadeInitialSearch ? 'No Matching Transactions Found' : 'Enter search criteria and adjust filters to find transactions'"
          />
        </template>

        <b-table-column
          v-slot="{ row }"
          field="TransactionNumber"
          sortable
          label="Transaction #"
          cell-class="no-wrap-text"
          width="1"
          sticky
          sticky-header
        >
          {{ row.giftCardTransactionId }}
        </b-table-column>

        <b-table-column
          v-if="hidableColumns.transactionType"
          v-slot="{ row }"
          :visible="hidableColumns.transactionType.isVisible"
          field="transactionType"
          label="Transaction Type"
          cell-class="no-wrap-text"
          centered
        >
          {{ capitalCase(row.transactionType) }}
        </b-table-column>

        <b-table-column
          v-if="hidableColumns.transactionDateTime"
          v-slot="{ row: { transactionDateTime } }"
          :visible="hidableColumns.transactionDateTime.isVisible"
          field="transactionDateTime"
          cell-class="no-wrap-text"
          sortable
          label="Date"
        >
          <p v-html="formatDateTime(transactionDateTime)" />
        </b-table-column>

        <b-table-column
          v-if="hidableColumns.purchaser"
          v-slot="{ row }"
          :visible="hidableColumns.purchaser.isVisible"
          field="purchaser"
          label="Purchaser"
          cell-class="no-wrap-text"
        >
          <template v-if="row.transactionType === 'GiftPurchase'">
            {{ row.senderName }}
            <div v-if="row.senderEmail" class="is-size-7">
              {{ row.senderEmail }}
            </div>
            <div v-else-if="row.senderPrimarySmsNumber" class="is-size-7">
              {{ row.senderPrimarySmsNumber }}
            </div>
          </template>
          <template v-else-if="row.accountFirstName || row.accountLastName">
            <div>
              {{ row.accountFirstName }} {{ row.accountLastName }}
            </div>
            <div v-if="row.accountEmail" class="is-size-7">
              {{ row.accountEmail }}
            </div>
            <div v-else-if="row.accountPrimarySmsNumber || row.accountPhoneNumber" class="is-size-7">
              {{ row.accountPrimarySmsNumber || row.accountPhoneNumber }}
            </div>
          </template>
          <span v-else class="has-text-grey-light">
            N/A
          </span>
        </b-table-column>

        <b-table-column
          v-if="hidableColumns.recipient"
          v-slot="{ row }"
          :visible="hidableColumns.recipient.isVisible"
          field="recipient"
          label="Gift Recipient"
          cell-class="no-wrap-text"
        >
          <template v-if="row.recipientName">
            <div>
              {{ row.recipientName }}
            </div>
            <div v-if="row.recipientEmail" class="is-size-7">
              {{ row.recipientEmail }}
            </div>
            <div v-else-if="row.recipientPrimarySmsNumber" class="is-size-7">
              {{ row.recipientPrimarySmsNumber }}
            </div>
          </template>
          <span v-else class="has-text-grey-light">
            N/A
          </span>
        </b-table-column>

        <b-table-column
          v-if="hidableColumns.cardNumberLastFour"
          v-slot="{ row }"
          :visible="hidableColumns.cardNumberLastFour.isVisible"
          field="cardNumberLastFour"
          label="Gift Card #"
          centered
          cell-class="no-wrap-text"
        >
          <span :class="row.cardNumberLastFour ? 'card-number' : 'has-text-grey-light'">
            {{ row.cardNumberLastFour ? `•••• ${row.cardNumberLastFour}` : 'N/A' }}
          </span>
        </b-table-column>

        <b-table-column
          v-if="hidableColumns.amount"
          v-slot="{ row }"
          :visible="hidableColumns.amount.isVisible"
          field="Amount"
          label="Transaction Amount"
          sortable
          centered
          numeric
          cell-class="no-wrap-text"
        >
          <span v-if="!row.amount" class="has-text-grey-light">
            N/A
          </span>
          <span v-else>
            {{ row.amount | dollars }}
          </span>
        </b-table-column>

        <!-- FOOTER -->
        <template #bottom-left>
          <div class="left-side is-flex">
            <page-number-select
              :page="page"
              :per-page="Number(perPage)"
              :total-count="giftCardTransactionsTotalCount"
              @input="handlePageChange"
            />
            <b-field label-for="perPage" label-position="on-border" label="Per Page" class="mar-l mar-none">
              <b-select id="perPage" v-model="perPage">
                <option value="10">10</option>
                <option value="20">20</option>
                <option value="50">50</option>
                <option value="100">100</option>
              </b-select>
            </b-field>
          </div>
        </template>
      </b-table>
    </div>
  </div>
</template>

<script>
  import debounce from 'lodash.debounce';
  import moment from 'moment-timezone';
  import storage from '@/services/storage';
  import capitalCase from '@/helpers/capitalCase';

  import merchantMixin from '@/mixins/merchant';
  import scrollIndicatorMixin from '@/mixins/scroll-container-indicator';

  import GiftCardTransaction from '@/store/classes/GiftCardTransaction';
  import GiftCardTransactionType from '@/store/classes/GiftCardTransactionType';
  import giftCardTransactionModal from './gift-card-transaction-modal.vue';

  export default {
    name: 'GiftCardTransactionsList',

    mixins: [scrollIndicatorMixin, merchantMixin],

    data() {
      return {
        giftCardTransactions: [],
        page: 1,
        perPage: 10,
        param: 'giftCardTransactionId',
        query: '',
        lastSearchedQuery: '',
        sortDirection: 'desc',
        dateRange: [],
        selectedTransactionTypes: [],
        sortOrder: 'TransactionDateTimeDesc',
        focusedDate: null,
        hidableColumns: {
          transactionType: { isVisible: true, display: 'Transaction Type' },
          transactionDateTime: { isVisible: true, display: 'Date' },
          purchaser: { isVisible: true, display: 'Purchaser' },
          recipient: { isVisible: true, display: 'Gift Recipient' },
          cardNumberLastFour: { isVisible: true, display: 'Gift Card #' },
          amount: { isVisible: true, display: 'Transaction Amount' }
        },
        hasMadeInitialSearch: false
      };
    },

    computed: {
      merchantDefaultTimezone() {
        return this.$_selectedMerchant.defaultUserTimezone || 'America/New_York';
      },

      giftCardTransactionTypes() {
        return GiftCardTransactionType.transactionTypesWithoutBalanceInquiry().map(type => type.name);
      },

      isLoadingGiftCardTransactions() {
        return GiftCardTransaction.$state().searching
          || GiftCardTransaction.$state().fetching
          || !!GiftCardTransaction.$state().fetchingId;
      },

      giftCardTransactionMetadata() {
        return GiftCardTransaction.$state().giftCardTransactionMetadata;
      },

      giftCardTransactionsTotalCount() {
        return this.giftCardTransactionMetadata.count;
      },

      isAllSelected() {
        return this.selectedTransactionTypes.length === 0;
      },

      transactionTypeDisplay() {
        if (this.isAllSelected) return 'All';

        if (this.selectedTransactionTypes.length === 1) {
          const selectedType = this.selectedTransactionTypes[0];
          const transactionType = this.giftCardTransactionTypes.find(type => type === selectedType);
          return transactionType ? capitalCase(transactionType) : 'All';
        }
        return `Selected Types (${this.selectedTransactionTypes.length})`;
      },

      visibleColumnCount() {
        return this.hidableColumnCount - this.hiddenColumns.length;
      },

      hidableColumnCount() {
        return Object.keys(this.hidableColumns).length;
      },

      hiddenColumns() {
        return this.$clone(Object.values(this.hidableColumns)).filter(c => !c.isVisible);
      },

      isTodayDateRange() {
        return this.dateRange.every(date => date.toDateString() === this.today.toDateString());
      },

      isLastSevenDaysDateRange() {
        const [startDate, endDate] = this.dateRange;

        return (startDate && endDate)
          && startDate.toDateString() === this.sevenDaysAgo.toDateString()
          && endDate.toDateString() === this.today.toDateString();
      },

      isLastThirtyDays() {
        const [startDate, endDate] = this.dateRange;

        return (startDate && endDate)
          && startDate.toDateString() === this.thirtyDaysAgo.toDateString()
          && endDate.toDateString() === this.today.toDateString();
      },

      isLastNinetyDaysRange() {
        const [startDate, endDate] = this.dateRange;

        return (startDate && endDate)
          && startDate.toDateString() === this.ninetyDaysAgo.toDateString()
          && endDate.toDateString() === this.today.toDateString();
      },

      today() {
        return moment.utc(moment().startOf('day')).toDate();
      },

      sevenDaysAgo() {
        return moment.utc(moment().subtract(7, 'd').startOf('day')).toDate();
      },

      thirtyDaysAgo() {
        return moment.utc(moment().subtract(30, 'd').startOf('day')).toDate();
      },

      ninetyDaysAgo() {
        return moment.utc(moment().subtract(90, 'd').startOf('day')).toDate();
      },

      queryInputType() {
        switch (this.param) {
          case 'giftCardTransactionId':
          case 'cardNumberLastFour':
          case 'purchaserPhone':
          case 'recipientPhone':
            return 'number';
          case 'amount':
            return 'float';
          default:
            return 'text';
        }
      },

      maskOptions() {
        switch (this.param) {
          case 'giftCardTransactionId':
            return {
              mask: Number,
              stripLeadingZeroes: false,
              numericOnly: true
            };
          case 'cardNumberLastFour':
            return {
              mask: '9999',
              stripLeadingZeroes: false,
              numericOnly: true
            };
          case 'purchaserPhone':
          case 'recipientPhone':
            return {
              mask: '(999) 999-9999',
              stripLeadingZeroes: false
            };
          case 'amount':
            return { numeralDecimalScale: 2 };
          default:
            return null;
        }
      }
    },

    watch: {
      param: 'handleParamChange',
      query: 'debouncedHandleQueryChange',
      selectedTransactionTypes: 'handleTransactionTypeChange',
      hidableColumns: {
        deep: true,
        handler: 'handleHideColumn'
      },
      perPage: 'handlePerPageChange',
      dateRange: {
        deep: true,
        handler: 'handleDateRangeChange'
      }
    },

    mounted() {
      this.onMounted();
    },

    created() {
      this.onCreated();
    },

    methods: {
      capitalCase,

      async onCreated() {
        await this.fetchGiftCardTransactionTypes();

        this.dateRange = [this.thirtyDaysAgo, this.today];
        this.hidableColumns = {
          ...this.hidableColumns,
          ...storage.local.get('gift-card-transaction-columns') || {}
        };
      },

      async onMounted() {
        this.$nextTick(() => {
          this.$_initScrollIndicatorSmart({
            contentSelector: '.table',
            autoStyles: false
          });
        });

        GiftCardTransaction.resetState();

        const { query, param } = this.$route.query;
        const incomingLinkNaviation = query && param;

        if (incomingLinkNaviation) {
          this.param = decodeURIComponent(param);
          // There is a watcher on this.param, and if we don't wait until $nextTick there is
          // a race condition that most often clears out the query after its set resulting
          // in a blank search
          await this.$nextTick();
          this.query = decodeURIComponent(query);
          await this.searchGiftCardTransactions();
        }
      },

      async openGiftCardTransactionModal(giftCardTransaction) {
        try {
          await GiftCardTransaction.getGiftCardTransactionById(giftCardTransaction.giftCardTransactionId);
          const transaction = GiftCardTransaction.find(giftCardTransaction.giftCardTransactionId);
          this.$buefy.modal.open({
            parent: this,
            component: giftCardTransactionModal,
            hasModalCard: true,
            trapFocus: true,
            canCancel: false,
            customClass: 'auto-width',
            props: { transaction }
          });
        }
        catch (error) {
          this.$_onRequestError({
            error,
            toastOptions: { message: 'There was an error loading your gift card transaction' }
          });
        }
      },

      async fetchGiftCardTransactionTypes() {
        try {
          await GiftCardTransactionType.fetchGiftCardTransactionTypes();
        }
        catch (error) {
          this.$_onRequestError({
            error,
            toastOptions: { message: 'There was an error loading gift card transaction types' }
          });
        }
      },

      handleSelectAllTransactionTypes() {
        this.selectedTransactionTypes = [];
        this.handleTransactionTypeChange([]);
      },

      setDateRange(range) {
        switch (range) {
          case 'today': {
            this.dateRange = [this.today, this.today];
            break;
          }
          case 'last-seven-days': {
            this.dateRange = [this.sevenDaysAgo, this.today];
            break;
          }
          case 'last-thirty-days': {
            this.dateRange = [this.thirtyDaysAgo, this.today];
            break;
          }
          case 'last-ninety-days': {
            this.dateRange = [this.ninetyDaysAgo, this.today];
            break;
          }
          default:
            break;
        }
      },

      formatDateTime(dateTime) {
        const date = moment(dateTime);
        const timezone = this.$_selectedMerchant?.defaultUserTimezone || 'UTC';
        return date.tz(timezone).format('MMMM D, YYYY<br>@ h:mm A z');
      },

      handleHideColumn(obj) {
        storage.local.set('gift-card-transaction-columns', obj);
        this.$nextTick(() => {
          this.$_setOverflowAttribute();
        });
      },

      handleDateRangeChange(dateRange, oldVal = []) {
        if (oldVal.length) {
          this.page = 1;
          this.searchGiftCardTransactions({ fromDate: dateRange[0], toDate: dateRange[1] });
          this.focusedDate = null;
          this.$nextTick(() => {
            this.focusedDate = dateRange[1];
          });
        }
      },

      handlePerPageChange(perPage) {
        this.page = 1;
        this.searchGiftCardTransactions({ perPage });
      },

      handleParamChange(param) {
        if (this.query) {
          this.page = 1;
          this.query = '';
          this.searchGiftCardTransactions({ param });
        }
      },

      // eslint-disable-next-line
      debouncedHandleQueryChange: debounce(function (query) {
        if (query !== this.lastSearchedQuery) {
          this.handleQueryChange(query);
        }
      }, 666),

      handleQueryChange(query) {
        this.page = 1;
        this.searchGiftCardTransactions({ query });
      },

      handleTransactionTypeChange(selectedTypes) {
        this.page = 1;
        this.searchGiftCardTransactions({ transactionTypes: selectedTypes.length ? selectedTypes : null });
      },

      handleSort(sortField, sortDirection) {
        // SORT PARAMS:
        // TransactionNumber
        // TransactionNumberDesc
        // TransactionDateTime
        // TransactionDateTimeDesc
        // Amount
        // AmountDesc
        const validSortFields = {
          TransactionNumber: 'TransactionNumber',
          TransactionNumberDesc: 'TransactionNumberDesc',
          TransactionDateTime: 'TransactionDateTime',
          TransactionDateTimeDesc: 'TransactionDateTimeDesc',
          Amount: 'Amount',
          AmountDesc: 'AmountDesc'
        };

        if (sortDirection === 'desc') {
          this.sortOrder = validSortFields[`${sortField}Desc`] || 'TransactionDateTimeDesc';
        }
        else {
          this.sortOrder = validSortFields[sortField] || 'TransactionDateTime';
        }
        this.sortDirection = sortDirection;
        this.page = 1;
        this.searchGiftCardTransactions({ sortOrder: this.sortOrder, sortDirection });
      },

      handlePageChange(page) {
        this.page = page;
        this.searchGiftCardTransactions({ page });
      },

      async searchGiftCardTransactions({
        param = this.param,
        query = this.query,
        page = this.page,
        perPage = this.perPage,
        fromDate = this.dateRange[0],
        toDate = this.dateRange[1],
        sortOrder = this.sortOrder,
        timezone = this.merchantDefaultTimezone,
        transactionTypes = this.selectedTransactionTypes.length ? this.selectedTransactionTypes : null
      } = {}) {
        try {
          this.lastSearchedQuery = query;
          this.giftCardTransactions = await GiftCardTransaction.searchGiftCardTransactions({
            page,
            perPage,
            fromDate: fromDate && moment(fromDate).format('YYYY-MM-DD'),
            toDate: toDate && moment(toDate).format('YYYY-MM-DD'),
            transactionTypes,
            sortOrder,
            timezone,
            [param]: query
          });

          if (!this.hasMadeInitialSearch) {
            this.hasMadeInitialSearch = true;
          }
        }
        catch (error) {
          this.$_onRequestError({
            error,
            toastOptions: { message: 'There was an error loading gift card transactions' }
          });
        }
      }
    }
  };
</script>

<style scoped lang="sass">
  .card-number
    font-family: monospace
    letter-spacing: 0.5px

  .has-text-align-center
    text-align: center !important

  ::v-deep .control
    margin-right: 0 !important

  .date-picker-input
    position: relative

    &.today-selected
      // hides actual input text and instead displays "Today"
      ::v-deep.dropdown-trigger .input
        color: transparent

      &::after
        content: 'Today'
        color: $grey-darker
        font-size: 1rem
        padding: 7px 40px
        pointer-events: none
        position: absolute
        top: 0
        right: 0
        bottom: 0
        left: 0

  .date-range-shortcuts
    display: grid
    grid-template-columns: 1fr 1fr
    gap: 0.5rem
    grid-gap: 0.5rem

    .button
      font-weight: 700

  ::v-deep
    tr:hover
      cursor: pointer

  .search-section
    display: flex
    flex-direction: column
    gap: $size-small
    flex-wrap: wrap-reverse

    .filters, .search-inputs
      display: flex
      gap: $size-normal
      > *
        margin: 0

    ::v-deep.dropdown-trigger
      .button
        min-width: 7rem
        justify-content: space-between

        .icon
          color: $primary

  .result-count
    margin: $size-small 0
    padding: 0.5rem 0

  .query-input,
  .search-inputs
    flex: 1 1

  ::v-deep
    th.is-sticky
      background: $white !important

    tr:hover
      td.is-sticky
        background: inherit

    .table-wrapper
      position: static

    .is-sticky
      position: relative

    .is-sticky::before,
    .table-container::after
      content: ''
      position: absolute
      top: 0
      opacity: 0
      width: 40px
      pointer-events: none
      transition: opacity 200ms

    .is-sticky::before
      bottom: 0
      left: 100%
      box-shadow: 30px 0px 25px -20px inset rgba(0, 0, 0, 0.125), 9px 0px 10px -10px inset rgba(0, 0, 0, 0.25)

    .table-container::after
      bottom: 73px
      right: 0
      box-shadow: -30px 0px 25px -20px inset rgba(0, 0, 0, 0.125), -9px 0px 10px -10px inset rgba(0, 0, 0, 0.25)

    .overflow-x-custom-left .is-sticky::before
      opacity: 1

    .overflow-x-custom-right .table-container::after
      opacity: 1

    .overflow-x-custom-both
      .is-sticky::before,
      .table-container::after
        opacity: 1

  .left-side
    display: flex
    gap: 1rem

  @media (max-width: $widescreen)
    .query-input
      margin-top: 0 !important
      width: 100%

    .left-side
      justify-content: center
</style>
