import http from '@/services/http';

export async function getJWT() {
  try {
    const response = await http.get('readme_io/jwt');
    return response.data.authToken;
  }
  catch (error) {
    throw error;
  }
}


export default {
  getJWT
};
