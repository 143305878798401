<template>
  <div>
    <audio>
      <source src="/audio/definite.mp3" type="audio/mpeg">
      <p>Your browser doesn't support HTML5 audio.</p>
    </audio>

    <div class="is-flex">
      <expo-orders-list />
      <expo-order-details-panel :order-expo-tools-active="orderExpoToolsActive" />
      <b-modal v-bind="orderExpoNotificationsProps">
        <order-expo-notifications />
      </b-modal>
      <b-modal v-bind="orderExpoSearchProps">
        <order-expo-search />
      </b-modal>
    </div>

    <transition name="fade-up">
      <expo-notification-container v-if="orderExpoToolsActive" :order-expo-tools-active="orderExpoToolsActive" />
    </transition>
  </div>
</template>

<script>
  import { mapActions, mapState, mapGetters } from 'vuex';

  import ExpoOrderDetailsPanel from './expo-order-details-panel.vue';
  import ExpoOrdersList from './expo-orders-list.vue';
  import OrderExpoNotifications from './order-expo-notifications.vue';
  import OrderExpoSearch from './order-expo-search.vue';
  import ExpoNotificationContainer from './expo-notification-container.vue';

  export default {
    name: 'OrderExpo',

    components: {
      ExpoOrderDetailsPanel,
      ExpoOrdersList,
      OrderExpoNotifications,
      OrderExpoSearch,
      ExpoNotificationContainer
    },

    props: {
      orderExpoToolsActive: {
        type: Boolean,
        default: false
      }
    },

    computed: {
      ...mapState('orderExpo', ['notificationsModalActive', 'searchModalActive', 'selectedStoreId']),

      ...mapGetters('websockets', ['expoConnection', 'channelTypes']),

      ...mapGetters('expoNotifications', ['allExpoNotificationAudioTypes', 'allExpoNotificationDisplayTypes']),

      orderExpoNotificationsProps() {
        return {
          active: this.notificationsModalActive,
          hasModalCard: true,
          canClose: true,
          trapFocus: true,
          fullScreen: true,
          canCancel: ['escape', 'outside']
        };
      },

      orderExpoSearchProps() {
        return {
          active: this.searchModalActive,
          hasModalCard: true,
          canClose: true,
          trapFocus: true,
          fullScreen: true,
          canCancel: ['escape', 'outside']
        };
      }
    },

    watch: {
      selectedStoreId: 'handleSelectedStoreIdChange'
    },

    beforeRouteEnter(to, from, next) {
      next((vm) => {
        vm.onCreated();
      });
    },

    beforeRouteLeave(to, from, next) {
      this.onDestroyed();
      next();
    },

    methods: {
      ...mapActions('orderExpo', ['onLoad', 'handleWebsocketEventForOrder']),
      ...mapActions('websockets', ['subscribeToPortalChannel', 'unsubscribeFromPortalChannel']),
      ...mapActions('expoNotifications', ['clearNotifications', 'addNotification']),

      onDestroyed() {
        this.unsubscribeFromPortalChannel(this.expoConnection.connectionId);
        this.clearNotifications();
      },

      handleSelectedStoreIdChange() {
        if (this.expoConnection?.connectionId) {
          this.unsubscribeFromPortalChannel(this.expoConnection.connectionId);
        }

        this.clearNotifications();
        this.subscribeToExpoChannel();
      },

      subscribeToExpoChannel() {
        if (this.expoConnection?.storeId !== this.selectedStoreId) {
          this.subscribeToPortalChannel({
            channel: this.channelTypes.EXPO,
            storeId: this.selectedStoreId,
            handler: this.onReceived
          });
        }
      },

      async onCreated() {
        try {
          await this.onLoad();
          this.subscribeToExpoChannel();
        }
        catch (error) {
          this.$_onRequestError({
            toastOptions: {
              message: 'There was an error loading order expo'
            },
            error
          });
        }
      },

      onReceived({ data }) {
        const toMatch = data.newExpoStatus || data.deliveryEvent;
        const playAudio = this.allExpoNotificationAudioTypes.includes(toMatch);
        const displayNotification = this.allExpoNotificationDisplayTypes.includes(toMatch);

        if (playAudio) {
          this.playNotificationAudio();
        }

        if (displayNotification) {
          this.addNotification(data);
        }

        this.handleWebsocketEventForOrder({ orderId: data.orderId, newExpoStatus: data.newExpoStatus });
      },

      toggleFullscreen() {
        this.$emit('toggle-fullscreen');
      },

      playNotificationAudio() {
        const audioElement = this.$el.querySelector('audio');
        audioElement.play();
      }
    }
  };
</script>

<style lang="sass" scoped>
  .notification-snackbar-container
    position: fixed
    bottom: 0
    left: 0
    right: 0
    z-index: 9999
</style>
