<template>
  <modal-card title="Copy Modifier Group" :subtitle="modifierGroup.displayName" modal-card-body-class="pad-none">
    <div class="pad-x-md pad-t-md">
      <b-message
        type="is-primary"
        class="is-compact has-shadow mar-b fit-content"
        has-icon
        icon="info-square"
      >
        This will create a brand new modifier group for each of the selected items, with all the same properties as the original
      </b-message>
      <p class="label mar-none">Items</p>
      <p class="sub-label">
        Select the items you would like to copy this modifier group to
      </p>
    </div>

    <menu-resource-select
      :selected-item-ids="selectedItemIds"
      @item-selected="itemIds => selectedItemIds = itemIds"
    />

    <template #footer>
      <div class="buttons is-right all-bold">
        <b-button
          rounded
          @click="$_confirmCloseModal({ programmatic: true })"
        >
          Cancel
        </b-button>
        <b-button
          v-tabbable
          rounded
          native-type="submit"
          type="is-primary"
          :disabled="!selectedItemIds.length"
          :loading="isSubmitting"
          @click="handleSubmit"
        >
          Copy
        </b-button>
      </div>
    </template>
  </modal-card>
</template>

<script>
  import confirmModalCloseMixin from '@/mixins/confirm-modal-close';
  import ModifierGroup from '@/store/classes/ModifierGroup';


  export default {
    name: 'CopyModifierGroupModal',

    mixins: [confirmModalCloseMixin],

    props: {
      modifierGroup: {
        type: Object,
        required: true
      }
    },

    data() {
      return {
        selectedItemIds: []
      };
    },

    computed: {
      isSubmitting() {
        return ModifierGroup.$state().submitting;
      }
    },

    methods: {
      async handleSubmit() {
        try {
          const menuItemIds = this.selectedItemIds.map((itemId) => {
            // If cloning to current menuItem pass null payload for BE to append "Clone" to item's ModifierGroup displayName
            if (itemId === this.modifierGroup.menuItemId) return null;
            return itemId;
          });

          await ModifierGroup.cloneModifierGroup({ menuItemIds, modifierGroup: this.modifierGroup });

          this.$_onRequestSuccess({
            toastOptions: {
              message: 'Successfully copied modifier group to item(s)!'
            },
            options: { closeParent: true }
          });
        }
        catch (error) {
          this.$_onRequestError({
            error,
            toastOptions: {
              message: 'There was an error copying modifier group to item(s)'
            }
          });
        }
      }
    }
  };
</script>
