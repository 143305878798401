<template>
  <div class="container mar-t-lg">
    <div class="card">
      <h3 class="title is-4 pad-x pad-t">Saved Credit Cards</h3>
      <b-table
        :data="isFetchingAccountSavedPayments ? [] : accountSavedPayments"
        class="is-middle-aligned"
        paginated
        scrollable
        :per-page="25"
        pagination-position="bottom"
        :mobile-cards="false"
        :default-sort="['createdAt', 'desc']"
      >
        <template v-slot:empty>
          <empty-table-loader
            :loading="isFetchingAccountSavedPayments && isFetchingCardTypes"
            :has-icon="false"
            message="No Saved Payments found for this account"
          />
        </template>
        <b-table-column
          v-slot="{ row }"
          sortable
          field="createdAt"
          label="Date Added"
          cell-class="no-wrap-text"
        >
          {{ row.createdAt | moment('MM/DD/YYYY') }}
        </b-table-column>
        <b-table-column
          v-slot="{ row }"
          centered
          sortable
          field="cardType"
          label="Card Type"
          cell-class="no-wrap-text"
        >
          {{ row.cardTypeDisplayName }}
        </b-table-column>
        <b-table-column
          v-slot="{ row }"
          centered
          sortable
          field="lastFourDigits"
          label="Card Number"
          cell-class="no-wrap-text"
        >
          {{ `.... ${row.lastFourDigits}` }}
        </b-table-column>
        <b-table-column
          v-slot="{ row }"
          centered
          sortable
          field="expirationDate"
          label="Expiration"
          cell-class="no-wrap-text"
        >
          {{ row.expirationDate | moment('MM/YYYY') }}
        </b-table-column>
        <b-table-column
          v-slot="{ row }"
          centered
          sortable
          field="isDefault"
          label="Default?"
          cell-class="no-wrap-text"
        >
          <b-icon
            :icon="row.isDefault ? 'check' : null"
            :type="row.isDefault ? 'is-success' : null"
            size="is-small"
          />
        </b-table-column>
        <b-table-column
          v-slot="{ row }"
          centered
          sortable
          field="isActive"
          label="Active"
          cell-class="no-wrap-text"
        >
          <b-icon
            size="is-small"
            class="is-size-7"
            :type="row.isActive ? 'is-success' : 'is-danger'"
            icon="circle"
          />
        </b-table-column>
        <b-table-column
          v-if="$can('update', 'AccountSavedPayment')"
          v-slot="{ row }"
          centered
          numeric
          cell-class="actions"
          label="Actions"
          width="1"
        >
          <dropdown-menu v-if="$can('update', 'AccountSavedPayment') && row.isActive" position="bottom-end">
            <b-button slot="trigger" class="is-transparent" type="is-white">
              <b-icon icon="ellipsis-v" pack="far" size="is-small" />
            </b-button>
            <b-dropdown-item @click="openDeactivateModal(row)">
              <b-icon
                icon="ban"
                class="mar-r-xs"
                size="is-small"
              />
              Deactivate
            </b-dropdown-item>
            <b-dropdown-item @click="openSetDefaultModal(row)">
              <b-icon
                icon="check-circle"
                class="mar-r-xs"
                size="is-small"
              />
              Set Default
            </b-dropdown-item>
          </dropdown-menu>
        </b-table-column>
      </b-table>
    </div>
  </div>
</template>

<script>
  import alertModal from '@/components/globals/alert-modal.vue';

  import CardType from '@/store/classes/CardType';
  import AccountSavedPayment from '@/store/classes/AccountSavedPayment';

  export default {
    name: 'RegisteredGuestCreditCardsTable',

    components: {},

    mixins: [],

    props: {
      registeredGuestId: {
        type: Number,
        required: true
      }
    },

    data: () => ({}),

    computed: {
      isFetchingAccountSavedPayments() {
        return AccountSavedPayment.$state().fetching;
      },

      isFetchingCardTypes() {
        return CardType.$state().fetching;
      },

      accountSavedPayments() {
        return AccountSavedPayment
          .query()
          .where('accountId', this.registeredGuestId)
          .get();
      }
    },

    created() {
      this.onCreated();
    },

    methods: {
      async onCreated() {
        await Promise.all([
          this.fetchAccountSavedPayments(),
          this.fetchCardTypes()
        ]);
      },

      async fetchCardTypes() {
        try {
          await CardType.fetchCardTypes();
        }
        catch (error) {
          this.$_onRequestError({
            toastOptions: {
              message: 'There was an issue fetching the card types'
            },
            error
          });
        }
      },

      async fetchAccountSavedPayments() {
        try {
          await AccountSavedPayment.fetchAccountSavedPayments(this.registeredGuestId);
        }
        catch (error) {
          this.$_onRequestError({
            toastOptions: {
              message: 'There was an issue fetching this account\'s saved payments'
            },
            error
          });
        }
      },

      openDeactivateModal(accountSavedPayment) {
        this.$buefy.modal.open({
          parent: this,
          component: alertModal,
          hasModalCard: true,
          trapFocus: true,
          canCancel: ['escape', 'outside'],
          customClass: 'auto-width',
          props: {
            title: 'Deactivate Saved Payment',
            message: `Deactivating the card ending in <b>${accountSavedPayment.lastFourDigits}</b> will hide it from the user and they will not be able to use it as a saved payment method.`,
            showCloseButton: false,
            horizontal: true,
            icon: 'trash-alt',
            type: 'is-danger',
            buttons: [
              { text: 'Cancel' },
              { text: 'Yes, Deactivate Saved Payment', primary: true, onClick: () => this.deactivateAccountSavedPayment(accountSavedPayment) }
            ]
          }
        });
      },

      openSetDefaultModal(accountSavedPayment) {
        this.$buefy.modal.open({
          parent: this,
          component: alertModal,
          hasModalCard: true,
          trapFocus: true,
          canCancel: ['escape', 'outside'],
          customClass: 'auto-width',
          props: {
            title: 'Set Saved Payment as Default',
            message: `Are you sure you want set the card ending in <b>${accountSavedPayment.lastFourDigits}</b> as the default? Setting this as the default will clear the previous selection.`,
            showCloseButton: false,
            horizontal: true,
            icon: 'check-circle',
            type: 'is-primary',
            buttons: [
              { text: 'Cancel' },
              { text: 'Yes, Set as Default', primary: true, onClick: () => this.setDefaultAccountSavedPayment(accountSavedPayment) }
            ]
          }
        });
      },

      deactivateAccountSavedPayment(accountSavedPayment) {
        const recordToUpdate = this.$clone(accountSavedPayment);
        recordToUpdate.isActive = false;

        this.updateAccountSavedPayment(recordToUpdate);
      },

      setDefaultAccountSavedPayment(accountSavedPayment) {
        const recordToUpdate = this.$clone(accountSavedPayment);
        recordToUpdate.isActive = true;
        recordToUpdate.isDefault = true;

        this.updateAccountSavedPayment(recordToUpdate, true);
      },

      async updateAccountSavedPayment(accountSavedPayment, refetch = false) {
        try {
          await AccountSavedPayment.updateAccountSavedPayment(accountSavedPayment);
          if (refetch) {
            // this is necessary when changing the isDefault record.
            // Otherwise, the FE doesn't correctly remove the checkmark for the old default.
            await this.fetchAccountSavedPayments();
          }
        }
        catch (error) {
          this.$_onRequestError({
            toastOptions: {
              message: 'There was an issue updating the account saved payment'
            },
            error
          });
        }
      }
    }
  };
</script>

<style lang='sass' scoped>

</style>
