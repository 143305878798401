import { Model } from '@vuex-orm/core';
import http from '@/services/http';



export default class StoreMenuTypeSchedule extends Model {
  static entity = 'storeMenuTypeSchedules'

  // FIELDS
  static fields() {
    return {
      id: this.attr(''),
      storeId: this.attr(''),
      menuTypeId: this.attr(''),
      day: this.attr(''),
      startTime: this.attr(null),
      endTime: this.attr(null)
    };
  }



  // STATE
  static state() {
    return {
      fetching: false,
      submitting: false
    };
  }



  // ACTIONS
  static async fetchStoreMenuTypeSchedules(storeId) {
    try {
      this.commit((state) => {
        state.fetching = true;
      });

      const { data } = await http.get(`stores/${storeId}/store_menu_type_schedules`);

      this.insert({ data: data.storeMenuTypeSchedules });
    }

    catch (error) {
      throw error;
    }

    finally {
      this.commit((state) => {
        state.fetching = false;
      });
    }
  }

  static async createStoreMenuTypeSchedule(storeMenuTypeSchedule) {
    try {
      this.commit((state) => {
        state.submitting = true;
      });

      const { data } = await http.post(`menu_types/${storeMenuTypeSchedule.menuTypeId}/store_menu_type_schedules`, {
        storeMenuTypeSchedule
      });

      this.insert({ data: data.storeMenuTypeSchedule });
    }

    catch (error) {
      throw error;
    }

    finally {
      this.commit((state) => {
        state.submitting = false;
      });
    }
  }

  static async updateStoreMenuTypeSchedule({ id, endTime, startTime }) {
    try {
      this.commit((state) => {
        state.submitting = true;
      });

      const { data } = await http.put(`store_menu_type_schedules/${id}`, {
        storeMenuTypeSchedule: { startTime, endTime }
      });

      this.update({ data: data.storeMenuTypeSchedule });
    }

    catch (error) {
      throw error;
    }

    finally {
      this.commit((state) => {
        state.submitting = false;
      });
    }
  }

  static async deleteStoreMenuTypeSchedule(id) {
    try {
      this.commit((state) => {
        state.submitting = true;
      });

      await http.delete(`store_menu_type_schedules/${id}`);
      this.delete(id);
    }

    catch (error) {
      throw error;
    }

    finally {
      this.commit((state) => {
        state.submitting = false;
      });
    }
  }
}
