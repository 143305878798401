<template>
  <div class="main-menu">
    <empty-state-card
      v-if="!categories.length && !searchQuery"
      type="is-primary"
      title="Create Category"
      message="To start your menu we need to create a category that we can add items to."
      image-path="/icons/new-category.svg"
    >
      <template #buttons>
        <b-button
          type="is-dark"
          rounded
          :disabled="!$can('create', 'MenuCategory') && !$_menuPermissions.ADD_RESOURCE"
          @click="openCategoryModal"
        >
          Create Category
        </b-button>
      </template>
    </empty-state-card>

    <template v-else>
      <div class="card">
        <div class="is-flex justify-between pad mar-b-sm">
          <b-field
            label="Search By"
            label-position="on-border"
            class="search-field is-marginless"
          >
            <template #message>
              <p v-if="searchQuery.length && searchQuery.length < 3">
                Enter at least 3 characters
              </p>
              <p v-else-if="searchResults">
                Found <b>{{ hasSharedModGroups ? searchResults.length : totalResultsCount }}</b> results
              </p>
            </template>
            <b-select v-if="hasSharedModGroups" v-model="searchResource">
              <option value="categories">Categories</option>
              <option value="items">Items</option>
              <option value="modifierGroups">Modifier Groups</option>
              <option value="modifiers">Modifiers</option>
            </b-select>
            <b-select v-model="searchType">
              <option value="name">Name</option>
              <option value="pos">PLU#</option>
            </b-select>
            <b-input
              v-model="searchQuery"
              expanded
              placeholder="Search"
              :icon-right="searchQuery ? 'times-circle' : ''"
              icon-right-clickable
              icon="search"
              class="mar-r-xl"
              @icon-right-click="searchQuery = ''"
            />
          </b-field>
          <div class="is-flex dist-x-sm">
            <div v-tippy="{ content: 'Collapse All' }">
              <b-button :disabled="!isAnyMenuSectionOpen" @click="collapseAll">
                <b-icon icon="compress-alt" size="is-medium" />
              </b-button>
            </div>
            <template v-if="$can('update', 'MenuItem') && $_menuPermissions.MANAGE_MENU_TYPE">
              <b-field v-if="!menuTypeId">
                <div v-if="!!missingMenuTypes.length" class="control">
                  <tippy placement="left">
                    <template #trigger>
                      <b-button disabled type="is-warning" class="is-read-only">
                        <b-icon v-if="missingMenuTypes.length" icon="exclamation-triangle" type="is-dark" />
                      </b-button>
                    </template>

                    <div class="pad-xs has-text-left" style="width: 300px; white-space: normal">
                      <p class="is-size-6 has-text-weight-bold" style="line-height: 1.3">The following Order Modes are missing an associated Menu Type:</p>
                      <ul class="mar-y-sm">
                        <li
                          v-for="missingMenuType in missingMenuTypes"
                          :key="missingMenuType.menuTypeId"
                          class="is-size-6"
                        >
                          <b-icon icon="caret-right" size="is-small" />
                          {{ missingMenuType.displayName }}
                        </li>
                      </ul>
                      <p class="has-text-grey-light">Note: Locations will display as unavailable for these Order Modes</p>
                    </div>
                  </tippy>
                </div>

                <div class="control">
                  <b-button
                    v-if="!menuTypeId"
                    type="is-primary"
                    icon-left="plus"
                    @click="openSelectMenuTypeModal"
                  >
                    Menu Type
                  </b-button>
                </div>
              </b-field>
              <template v-else>
                <b-button
                  type="is-primary is-light"
                  icon-left="calendar"
                  icon-pack="far"
                  @click="openMenuDayPartSchedule(menuType)"
                >
                  Availability
                </b-button>
                <b-button
                  class="is-right"
                  type="is-primary"
                  icon-left="plus"
                  @click="openAddToMenuTypeModal(menuType, 'update')"
                >
                  Categories & Items
                </b-button>
              </template>
            </template>
          </div>
        </div>
        <empty-table-loader
          v-if="isLoadingSearchResults || (searchResults && !searchResults.length)"
          :loading="isLoadingSearchResults"
          :has-icon="false"
          class="has-border-top has-border-grey-lightest"
          message="No Results Found"
        />
        <div v-else class="nested-table has-border-top has-border-grey-lightest">
          <b-loading :is-full-page="false" :active="fetchingCategories || sortingCategories || isLoadingItemCloned || isDeletingMenuResource || isRemovingFromMenuType" />
          <div class="table-heading">
            <div class="row">
              <span>Name</span>
              <span class="align-center justify-end">
                <template v-if="!menuTypeId && !(hasSharedModGroups && searchResults)">Default</template>
              </span>
              <span class="align-center justify-end" :style="{ paddingLeft: '37px' }">
                <template v-if="!menuTypeId || (menuTypeId && $can('update', 'MenuCategory'))">Actions</template>
              </span>
            </div>
          </div>

          <!-- MENU SEARCH V2 - SHARED MOD GROUPS -->
          <store-menu-search-v2
            v-if="hasSharedModGroups && searchResults && searchResults.length"
            :search-results="searchResults"
            :resource-type="searchResource"
            :menu-type="menuType"
            @shared-mod-group-search-action="handleSharedModGroupSearchAction"
          />

          <draggable
            v-else
            tag="ul"
            group="category"
            v-bind="draggableAttributes"
            :value="categories"
            :force-fallback="true"
            @change="handleCategorySortOrderChange"
          >
            <li
              v-for="category in menuData"
              :key="`category-${category.id}`"
              :class="['draggable', {'is-open': isOpen.category[category.id]}]"
              :data-category-id="category.id"
            >
              <div class="row">
                <span class="pad-y-sm">
                  <b-icon
                    v-if="canSortResource('MenuCategory')"
                    icon="grip-lines"
                    size="is-small"
                    pack="far"
                    class="drag-handle"
                  />
                  <span class="link-inverted" @click="toggleCategory(category.id)">
                    <template v-if="!searchResults"><span class="has-text-weight-bold">{{ category.displayName }}</span></template>
                    <template v-else>
                      <span v-for="chunk in highlightSearchTerm(category.displayName)" :key="chunk.key" :class="chunk.match && 'search-term'">{{ chunk.text }}</span>
                    </template>
                    <b-icon
                      v-if="category.statementTemplate === 'HiddenTemplate'"
                      v-tippy="{ content: 'This category is hidden from the menu', placement: 'bottom', delay: [150, 0] }"
                      icon="lock"
                      size="is-small"
                      type="is-primary"
                      class="mar-l-xs"
                    />
                    <b-icon
                      size="is-small"
                      pack="far"
                      :icon="sortingCategoryId === category.id || isFetchingItems(category.id) ? 'spinner-third' : 'angle-right'"
                      :class="[
                        'open-indicator',
                        {
                          'is-open': isOpen.category[category.id],
                          'spin': sortingCategoryId === category.id || isFetchingItems(category.id)
                        }
                      ]"
                    />
                  </span>
                </span>
                <span />
                <span class="align-center justify-end">
                  <b-button
                    v-if="category.hasSchedule && !menuTypeId"
                    v-tippy="{ content: 'Restricted Availability', placement: 'left', delay: [300, 0] }"
                    class="is-transparent"
                    @click="openCategoryModal({ categoryId: category.id, mode: 'update', defaultTabIndex: 1 })"
                  >
                    <b-icon icon="calendar" pack="far" />
                  </b-button>

                  <template v-if="menuTypeId">
                    <tippy
                      v-if="$can('update', 'MenuCategory') && $_menuPermissions.MANAGE_MENU_TYPE"
                      placement="left"
                      max-width="500"
                    >
                      <template #trigger>
                        <b-button class="is-transparent" @click="confirmRemoveFromMenuType({ category })" @contextmenu.prevent="confirmRemoveFromMenuType({ all: true })">
                          <b-icon icon="minus-circle" type="is-danger" size="is-small" />
                        </b-button>
                      </template>

                      <div class="is-size-6">
                        Remove <span class="has-text-weight-semibold">{{ category.displayName }}</span> from {{ menuType.displayName }} Menu
                      </div>
                    </tippy>
                  </template>

                  <template v-else>
                    <b-button class="is-transparent" @click="openCategoryModal({ categoryId: category.id, mode: 'update' })">
                      <b-icon v-if="$can('update', 'MenuCategory') && $_menuPermissions.EDIT_RESOURCE" icon="pencil" size="is-small" />
                      <span v-else>View</span>
                    </b-button>
                    <b-dropdown
                      v-if="($can('destroy', 'MenuCategory') && $_menuPermissions.REMOVE_RESOURCE)"
                      aria-role="list"
                      position="is-bottom-left"
                      :mobile-modal="false"
                    >
                      <b-button slot="trigger" class="is-transparent" type="is-white">
                        <b-icon icon="ellipsis-v" pack="far" size="is-small" />
                      </b-button>
                      <template v-if="$can('destroy', 'MenuCategory') && $_menuPermissions.REMOVE_RESOURCE">
                        <b-dropdown-item class="is-danger" @click="openDeleteCategoryConfirmation(category)">
                          <b-icon icon="trash-alt" class="mar-r-sm" size="is-small" />
                          Delete Category
                        </b-dropdown-item>
                      </template>
                    </b-dropdown>
                  </template>
                </span>
              </div>
              <ul v-show="isOpen.category[category.id]" class="nested-table-section">
                <li v-if="!category.menuItems.length && (!$_menuPermissions.ADD_RESOURCE || menuTypeId)">
                  <div class="row sub-row">
                    <span class="has-text-grey pad-y-sm">No Items</span>
                    <span />
                    <span />
                  </div>
                </li>
                <draggable
                  v-else
                  tag="ul"
                  :group="`category-${category.id}-items`"
                  v-bind="draggableAttributes"
                  :value="sortedMenuItems({ categoryId: category.id, menuItems: category.menuItems })"
                  :force-fallback="true"
                  @change="handleItemSortOrderChange($event, category)"
                >
                  <template v-if="category.menuItems.length" #header>
                    <li class="nested-table-section-title">Menu Items</li>
                  </template>
                  <li
                    v-for="item in sortedMenuItems({ categoryId: category.id, menuItems: category.menuItems })"
                    :key="`item-${item.id}`"
                    :class="['draggable', {'is-open': isOpen.item[`${category.id}-${item.id}`]}]"
                    :data-item-id="item.id"
                  >
                    <div :class="['row', 'sub-row', { 'is-cloned-item': isClonedItem(item.id)}]">
                      <span class="pad-y-sm">
                        <b-icon
                          v-if="canSortResource('MenuItem')"
                          icon="grip-lines"
                          size="is-small"
                          pack="far"
                          class="drag-handle"
                        />
                        <div class="is-flex-direction-column">
                          <span class="link-inverted" @click="toggleItem(`${category.id}-${item.id}`)">
                            <template v-if="!searchResults"><span class="has-text-weight-bold is-size-6">{{ item.displayName }}</span></template>
                            <template v-else>
                              <span v-for="chunk in highlightSearchTerm(item.displayName)" :key="chunk.key" :class="(chunk.match || isMatchedPlu(item)) && 'search-term'">{{ chunk.text }}</span>
                            </template>
                            <b-icon
                              size="is-small"
                              pack="far"
                              :icon="sortingItemId === item.id || itemFetchingModGroupsId === `${category.id}-${item.id}` ? 'spinner-third' : 'angle-right'"
                              :class="[
                                'open-indicator',
                                {
                                  'is-open': isOpen.item[`${category.id}-${item.id}`],
                                  'spin': sortingItemId === item.id || itemFetchingModGroupsId === `${category.id}-${item.id}`
                                }
                              ]"
                            />
                          </span>
                        </div>
                        <b-icon
                          v-if="!item.mappedToPos && !$_hasPosType(posTypes.Cardfree)"
                          v-tippy="{ content: 'Missing POS Mapping', placement: 'right' }"
                          size="is-small"
                          icon="exclamation-triangle"
                          type="is-danger"
                          class="mar-l"
                        />
                      </span>
                      <span />
                      <span class="align-center justify-end">
                        <b-button
                          v-if="(item.hasSchedule || item.availabilityBeginDate || item.availabilityEndDate) && !menuTypeId"
                          v-tippy="{ content: 'Restricted Availability', placement: 'left', delay: [300, 0] }"
                          class="is-transparent"
                          @click="openItemModal({ category, itemId: item.id, mode: 'update', defaultTabIndex: 2 })"
                        >
                          <b-icon icon="calendar" pack="far" />
                        </b-button>

                        <template v-if="menuTypeId">
                          <tippy
                            v-if="$can('update', 'MenuItem') && $_menuPermissions.MANAGE_MENU_TYPE"
                            placement="left"
                            max-width="500"
                          >
                            <template #trigger>
                              <b-button class="is-transparent" @click="confirmRemoveFromMenuType({ item })" @contextmenu.prevent="confirmRemoveFromMenuType({ all: true })">
                                <b-icon icon="minus-circle" type="is-danger" size="is-small" />
                              </b-button>
                            </template>
                            <div class="is-size-6">
                              Remove <span class="has-text-weight-semibold">{{ item.displayName }}</span> from {{ menuType.displayName }} Menu
                            </div>
                          </tippy>
                        </template>
                        <template v-else>
                          <b-button class="is-transparent" @click="openItemModal({ category, itemId: item.id, mode: 'update' })">
                            <b-icon v-if="$can('update', 'MenuItem') && $_menuPermissions.EDIT_RESOURCE" icon="pencil" size="is-small" />
                            <span v-else>View</span>
                          </b-button>
                          <b-dropdown
                            v-if="[($can('create', 'MenuItem') && $_menuPermissions.MANAGE_DAYPART_SCHEDULE), ($can('destroy', 'MenuItem') && $_menuPermissions.REMOVE_RESOURCE)].some(x => x)"
                            aria-role="list"
                            position="is-bottom-left"
                            :mobile-modal="false"
                          >
                            <b-button slot="trigger" class="is-transparent" type="is-white">
                              <b-icon icon="ellipsis-v" pack="far" size="is-small" />
                            </b-button>
                            <b-dropdown-item @click="openCategoryAssociationModal(item)">
                              <b-icon icon="layer-group" class="mar-r-sm" size="is-small" />
                              Manage Category Associations
                            </b-dropdown-item>
                            <b-dropdown-item v-if="$can('create', 'MenuItem') && $_menuPermissions.CLONE_ITEM" @click="openDuplicateMenuItemModal(item)">
                              <b-icon
                                icon="copy"
                                class="mar-r-sm"
                                size="is-small"
                              />
                              Copy Item
                            </b-dropdown-item>
                            <template v-if="$can('destroy', 'MenuItem') && $_menuPermissions.REMOVE_RESOURCE">
                              <hr class="dropdown-divider">
                              <b-dropdown-item class="is-danger" @click="openDeleteMenuItemConfirmation({ category, item })">
                                <b-icon icon="trash-alt" pack="far" class="mar-r-sm" size="is-small" />
                                Delete Item from <b>Category</b>
                              </b-dropdown-item>
                              <b-dropdown-item class="is-danger" @click="openDeleteMenuItemFromMenuConfirmation({ item })">
                                <b-icon icon="trash-alt" class="mar-r-sm" size="is-small" />
                                Delete Item from <b>Entire Menu</b>
                              </b-dropdown-item>
                            </template>
                          </b-dropdown>
                        </template>
                      </span>
                    </div>
                    <nested-modifier-groups
                      v-if="!$_featurePermissions.SHARED_MODIFIER_GROUPS && isOpen.item[`${category.id}-${item.id}`]"
                      :item-id="item.id"
                      :modifier-groups="item.menuItemModifierGroups"
                      :menu-type-id="menuTypeId"
                      :search-query="searchQuery"
                      :is-search-result="Boolean(searchResults)"
                      :is-matched-plu="isMatchedPlu"
                      :valid-search-length="validSearchLength"
                      @modifier-updated="reSearchMenu"
                      @modifier-group-updated="reSearchMenu"
                    />
                    <item-modifier-groups
                      v-else-if="$_featurePermissions.SHARED_MODIFIER_GROUPS && isOpen.item[`${category.id}-${item.id}`]"
                      :item-id="item.id"
                      :menu-item-modifier-group-items="item.menuItemModifierGroupItems"
                      :menu-type-id="menuTypeId"
                      :search-query="searchQuery"
                      :is-search-result="Boolean(searchResults)"
                      :is-matched-plu="isMatchedPlu"
                      :valid-search-length="validSearchLength"
                      @modifier-updated="reSearchMenu"
                      @modifier-group-updated="reSearchMenu"
                    />
                  </li>
                </draggable>
                <li v-if="!menuTypeId && $can('create', 'MenuItem') && $_menuPermissions.ADD_RESOURCE" class="row sub-row">
                  <span>
                    <b-button
                      class="is-transparent"
                      icon-left="plus"
                      type="is-primary"
                      inverted
                      @click="openItemModal({ category, mode: 'create' })"
                    >
                      Item
                    </b-button>
                  </span>
                  <span /><span />
                </li>
              </ul>
            </li>
          </draggable>
          <div v-if="!menuTypeId && $can('create', 'MenuCategory') && $_menuPermissions.ADD_RESOURCE && ((hasSharedModGroups && !searchResults) || !hasSharedModGroups)" class="pad-x pad-y-sm">
            <b-button
              type="is-primary"
              inverted
              class="is-transparent"
              icon-left="plus"
              @click="openCategoryModal"
            >
              Category
            </b-button>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
  import debounce from 'lodash.debounce';

  import draggable from 'vuedraggable';
  import nestedModifierGroups from './nested-modifier-groups.vue';
  import itemModifierGroups from './item-modifier-groups.vue';
  import events from '@/services/events';
  import merchantMixin from '@/mixins/merchant';
  import featurePermissionsMixin from '@/mixins/featurePermissions';
  import { searchMenu, searchMenuV2 } from '@/api/menu';
  import posTypes from '@/constants/posTypes';
  import storeMenuSearchV2 from '../menu-search-v2/store-menu-search-v2.vue';

  // modals
  import alertModal from '@/components/globals/alert-modal.vue';
  import addEditCategoryModal from './add-edit-category-modal/index.vue';
  import addEditItemModal from './add-edit-item-modal/index.vue';
  import copyMenuItemModal from './copy-menu-item-modal.vue';
  import categoryAssociationModal from './category-association-modal.vue';
  import selectMenuTypeModal from './select-menu-type-modal.vue';
  import addToMenuTypeModal from './add-to-menu-type-modal.vue';
  import confirmDeleteItemFromMenu from './confirm-delete-item-from-menu.vue';
  import menuDayPartScheduleModal from './menu-daypart-schedule-modal.vue';

  // classes
  import Category from '@/store/classes/Category';
  import CategoryItem from '@/store/classes/CategoryItem';
  import Item from '@/store/classes/Item';
  import Store from '@/store/classes/Store';
  import ModifierGroup from '@/store/classes/ModifierGroup';
  import MenuItemModifierGroupItem from '@/store/classes/MenuItemModifierGroupItem';
  import Modifier from '@/store/classes/Modifier';

  import highlightWords from 'highlight-words';
  import capitalCase from '@/helpers/capitalCase';


  export default {
    name: 'MainMenu',


    components: { draggable, nestedModifierGroups, itemModifierGroups, storeMenuSearchV2 },


    mixins: [merchantMixin, featurePermissionsMixin],


    metaInfo() {
      return {
        title: 'Main Menu'
      };
    },


    props: {
      menuTypeId: {
        type: Number,
        default: null
      }
    },


    data() {
      return {
        draggableAttributes: {
          animation: '200',
          ghostClass: 'ghost',
          handle: '.drag-handle',
          draggable: '.draggable'
        },
        updatingSortOrders: false,
        isOpen: {
          category: {},
          item: {}
        },
        clonedItemIds: [],
        isLoading: false,
        isLoadingItemCloned: false,
        isLoadingSearchResults: false,
        isRemovingFromMenuType: false,
        searchQuery: (this.$route.query?.searchQuery && decodeURIComponent(this.$route.query.searchQuery)) || '',
        oldSearchQuery: '',
        searchResults: null,
        searchType: (this.$route.query?.searchType && decodeURIComponent(this.$route.query.searchType)) || 'name',
        validSearchLength: 3,
        searchResource: (this.$route.query?.searchResource && decodeURIComponent(this.$route.query.searchResource)) || 'categories',
        resourceTypes: ['categories', 'items', 'modifierGroups', 'modifiers'],
        itemFetchingModGroupsId: null,
        posTypes,
        capitalCase
      };
    },


    computed: {
      menuData() {
        /*
          if enough characters have been entered into the search
          and there are search results to display, show the results,
          otherwise show the full list of categories
        */
        const searchResultsExist = this.searchQuery.length >= this.validSearchLength && !!this.searchResults;
        return searchResultsExist
          ? this.sortedSearchResults
          : this.categories;
      },

      categories() {
        return Category.query()
          .where(this.filterCategoriesByMenuType)
          .with('menuItems', query => query.where(this.filterItemsByMenuType))
          .with('menuItems.menuCategories')
          .with('menuItems.menuItemModifierGroups', query => query
            // retrieve only modifier groups nested under menu items at this level
            .where(modGroup => !modGroup.menuItemModifierId)
            .orderBy('sortOrder')
            .withAllRecursive())
          .with('menuItems.menuItemModifierGroups.menuItemModifiers', query => query.orderBy('sortOrder'))
          .with('menuItems.menuItemModifierGroups.menuItemModifiers.menuItemModifierGroups', query => query.orderBy('sortOrder'))
          .with('menuItems.menuItemModifierGroups.menuItemModifiers.menuItemModifierGroups.menuItemModifiers', query => query.orderBy('sortOrder'))
          .with('menuItems.menuItemModifierGroupItems', query => query.where(menuItemModifierGroupItem => !menuItemModifierGroupItem.menuItemModifierId)) // For Shared Modifier Groups
          .with('menuItems.menuItemModifierGroupItems.modifierGroup') // For Shared Modifier Groups
          .with('menuItems.menuItemModifierGroupItems.modifierGroup.menuItemModifiers') // For Shared Modifier Groups
          .with('menuItems.menuItemModifierGroupItems.modifierGroup.menuItemModifiers.menuItemModifierGroupItems', query => query.where(menuItemModifierGroupItem => !!menuItemModifierGroupItem.menuItemModifierId)) // For Shared Modifier Groups
          .with('menuItems.menuItemModifierGroupItems.modifierGroup.menuItemModifiers.menuItemModifierGroupItems.modifierGroup') // For Shared Modifier Groups
          .with('menuItems.menuItemModifierGroupItems.modifierGroup.menuItemModifiers.menuItemModifierGroupItems.modifierGroup.menuItemModifiers') // For Shared Modifier Groups
          .orderBy('sortOrder')
          .get();
      },
      // sortOrder is used in search, but not form the full menu item index.
      sortedSearchResults() {
        return this.searchResults
          ? JSON.parse(JSON.stringify(this.searchResults)).sort((a, b) => ((a.sortOrder < b.sortOrder) ? -1 : 1))
          : null;
      },

      totalResultsCount() {
        let total = 0;
        if (!this.searchResults) return total;

        const incrementMatchCount = (resources) => {
          resources.forEach((resource) => {
            const foundKey = Object.keys(resource).find(key => ['menuItems', 'menuItemModifierGroups', 'menuItemModifiers'].includes(key));
            if (foundKey) incrementMatchCount(resource[foundKey]);

            const isMatch = this.isMatchedName(resource) || this.isMatchedPlu(resource);
            if (isMatch) total++;
          });
        };

        incrementMatchCount(this.searchResults);

        return total;
      },

      storeCount() {
        return Store.query().count();
      },

      supportedStoreMenuTypeIds() {
        const allStores = Store.all();
        const allSupportedMenuTypeIds = allStores
          .flatMap(store => store.supportedMenuTypes)
          .map(menuType => menuType.id);

        return [...new Set(allSupportedMenuTypeIds)];
      },

      missingMenuTypes() {
        return this.supportedStoreMenuTypeIds
          .filter(id => !this.existingMenuTypeIds.includes(id))
          .map(menuTypeId => this.$_selectedMerchant.supportedMenuTypes.find(mt => mt.id === menuTypeId));
      },

      fetchingCategories() {
        return Category.$state().fetching;
      },

      sortingCategories() {
        return Category.$state().sorting;
      },

      sortingCategoryId() {
        return Item.$state().sortingParentId;
      },

      sortingItemId() {
        return ModifierGroup.$state().sortingParentId;
      },

      isDeletingMenuResource() {
        return Category.$state().deleting || ModifierGroup.$state().deleting || Modifier.$state().deleting;
      },

      isAnyMenuSectionOpen() {
        return Object.values(this.isOpen).some(section => Object.values(section).some(row => row));
      },

      existingMenuTypeIds() {
        return [...new Set(this.categories.flatMap(c => c.menuTypes.map(mt => mt.id)))];
      },

      hasAllMenuTypes() {
        return this.$_selectedMerchant.supportedMenuTypes.every(menuType => this.existingMenuTypeIds.includes(menuType.id));
      },

      menuType() {
        return this.$_selectedMerchant.supportedMenuTypes.find(mt => mt.id === this.menuTypeId);
      },

      hasSharedModGroups() {
        return this.$_featurePermissions?.SHARED_MODIFIER_GROUPS;
      }
    },

    watch: {
      searchQuery: 'onSearchQueryChange',
      searchType: 'reSearchMenu',
      searchResource: 'reSearchMenu'
    },

    async created() {
      await this.onCreated();
    },

    destroyed() {
      events.$off('navigate-to-item');
    },

    methods: {
      async onCreated() {
        events.$on('navigate-to-item', this.handleNavigateToItem);
        const { searchQuery, searchType, searchResource } = this.$route.query;
        if (this.hasSharedModGroups) {
          const hasSearchHistory = searchQuery && searchType && searchResource;
          if (hasSearchHistory) {
            await this.searchMenuV2({
              searchQuery: decodeURIComponent(searchQuery),
              searchType: decodeURIComponent(searchType),
              searchResource: decodeURIComponent(searchResource)
            });
          }
        }
        else {
          const hasSearchHistory = searchQuery && searchType;

          if (hasSearchHistory) {
            await this.searchMenu({
              searchQuery: decodeURIComponent(searchQuery),
              searchType: decodeURIComponent(searchType)
            });
          }
        }
      },

      handleSharedModGroupSearchAction({ actionName, args }) {
        const mapping = {
          'open-category-modal': this.openCategoryModal,
          'open-delete-category-confirmation': this.openDeleteCategoryConfirmation,
          'open-item-modal': this.openItemModal,
          'confirm-remove-from-menu-type': this.confirmRemoveFromMenuType,
          'open-category-association-modal': this.openCategoryAssociationModal,
          'open-duplicate-menu-item-modal': this.openDuplicateMenuItemModal,
          'open-delete-menu-item-confirmation': this.openDeleteMenuItemConfirmation,
          'open-delete-menu-item-from-menu-confirmation': this.openDeleteMenuItemFromMenuConfirmation
        };
        mapping[actionName](args);
      },

      async handleNavigateToItem({ categoryId, itemId }) {
        // // NOTE: when we navigate to the master menu from merchant tags, we need to fetch the categories first
        // // so that we don't end up with empty categories from the menu item in the menu item attribute request
        // await Category.fetchCategories();
        const category = this.menuData.find(c => c.id === categoryId);
        this.toggleCategory(categoryId, true, true);
        this.openItemModal({ category, itemId, mode: 'update', defaultTabIndex: 2, scrollTo: 'item-tags' });
      },

      canSortResource(type) {
        const invalidSearchOrEmptySearch = !this.searchResults || this.searchQuery.length < this.validSearchLength;
        return [
          this.$can('update', type),
          !this.menuTypeId,
          invalidSearchOrEmptySearch,
          this.$_menuPermissions?.SORT_RESOURCE
        ].every(x => x);
      },

      clearSearch() {
        this.collapseAll();
        this.searchResults = null;
        this.oldSearchQuery = '';
        this.$router.replace({ query: null });
      },

      onSearchQueryChange(newQuery, oldQuery = '') {
        // when backspacing characters in the search input
        // to the point where we revert back to the full menu
        const isGoingFromValidToInvalidSearch = oldQuery.length > newQuery.length && newQuery.length === this.validSearchLength - 1;

        // when search input is cleared after a successful search
        // (prevents collapsing opened resources before a search is made)
        const isSearchCleared = !newQuery.length && this.searchResults;

        if (isGoingFromValidToInvalidSearch || isSearchCleared) {
          this.clearSearch();
        }

        this.debouncedSearchMenu(newQuery);
      },

      // eslint-disable-next-line
      debouncedSearchMenu: debounce(async function (newQuery) {
        if (newQuery.length >= this.validSearchLength) {
          if (this.hasSharedModGroups) {
            await this.searchMenuV2({
              searchQuery: newQuery,
              searchType: this.searchType,
              searchResource: this.searchResource
            });
          }
          else {
            await this.searchMenu({
              searchQuery: newQuery,
              searchType: this.searchType
            });
          }
        }
      }, 888),

      highlightSearchTerm(string) {
        return highlightWords({ text: string, query: this.oldSearchQuery, matchExactly: true });
      },

      isMatchedResult(value) {
        return new RegExp(this.searchQuery, 'ig').test(value);
      },

      isMatchedName({ displayName }) {
        return this.isMatchedResult(displayName);
      },

      isMatchedPlu({ posItemId, posItemIds }) {
        // items will have a key of posItemIds with an array of potentially many ids,
        // and modifiers will have a key of posItemId with an array of always a single id
        const hasMatchingModifierPlu = posItemId && this.isMatchedResult(posItemId[0]);
        const hasMatchingItemPlu = posItemIds && posItemIds.some(id => this.isMatchedResult(id));
        return hasMatchingItemPlu || hasMatchingModifierPlu;
      },

      filterCategoriesByMenuType(category) {
        return !this.menuTypeId || category.menuTypes.findIndex(mt => mt.id === this.menuTypeId) >= 0;
      },

      filterItemsByMenuType(item) {
        return !this.menuTypeId || item.menuTypes.map(mt => mt.id).includes(this.menuTypeId);
      },

      collapseAll() {
        this.isOpen.category = {};
        this.isOpen.item = {};

        this.$nextTick(() => {
          events.$emit('main-menu:collapse');
        });
      },

      isFetchingItems(categoryId) {
        return Item.$state().fetchingCategoryId === categoryId;
      },

      toggleCategories({ categoryIds, isOpen }) {
        categoryIds.forEach((id) => {
          this.toggleOpenState({ id, type: 'category', isOpen });
        });
      },

      toggleOpenState({ id, type, isOpen }) {
        const openState = isOpen !== undefined ? isOpen : !this.isOpen[type][id];
        this.$set(this.isOpen[type], id, openState);
      },


      async toggleCategory(categoryId, isOpen, forceFetch) {
        const wasNeverOpen = this.isOpen.category[categoryId] === undefined;
        if (forceFetch || (wasNeverOpen && !this.searchQuery)) {
          await this.fetchItemsByCategoryId(categoryId);
        }

        this.toggleOpenState({ id: categoryId, type: 'category', isOpen });
      },

      async toggleItem(compositeId) {
        const itemId = compositeId.split('-')[1];
        const wasNeverOpen = !Object.keys(this.isOpen.item).some((_compositeId) => {
          const _itemId = _compositeId.split('-')[1];
          return itemId === _itemId;
        });

        if (wasNeverOpen && !this.searchQuery) {
          if (this.$_featurePermissions.SHARED_MODIFIER_GROUPS) {
            const menuItemModifierGroupItems = MenuItemModifierGroupItem.query()
              .where('menuItemId', Number(itemId))
              .get();

            const unfetchedModifierGroupIds = menuItemModifierGroupItems
              .map(mg => mg.modifierGroupId)
              .filter(modifierGroupId => !ModifierGroup.find(modifierGroupId));

            if (unfetchedModifierGroupIds.length) {
              this.itemFetchingModGroupsId = compositeId;
              await ModifierGroup.fetchModifierGroups({ ids: unfetchedModifierGroupIds });
              this.itemFetchingModGroupsId = null;
            }
          }
          else {
            await this.fetchModifierGroupsByItemId(compositeId);
          }
        }

        this.toggleOpenState({ id: compositeId, type: 'item' });
      },

      isClonedItem(id) {
        return this.clonedItemIds.includes(id);
      },

      openDuplicateMenuItemModal(item) {
        this.$buefy.modal.open({
          parent: this,
          component: copyMenuItemModal,
          hasModalCard: true,
          trapFocus: true,
          canCancel: false,
          customClass: 'auto-width',
          events: { 'item-clone-success': this.onItemCloned },
          props: { item, categories: this.categories }
        });
      },

      async openCategoryAssociationModal(item) {
        this.$buefy.modal.open({
          parent: this,
          component: categoryAssociationModal,
          hasModalCard: true,
          trapFocus: true,
          canCancel: false,
          customClass: 'auto-width',
          events: {
            'item-categories-updated': this.handleUpdatedItemCategories
          },
          props: { itemId: item.id, categories: this.categories }
        });
      },

      handleUpdatedItemCategories(categoryIds) {
        categoryIds.forEach(id => this.toggleCategory(id, true));
      },

      formatPrice(price) {
        return parseFloat(price).toFixed(2);
      },

      getPriceRange(storeMenuItems) {
        const priceArray = storeMenuItems
          .map(store => this.formatPrice(store.price))
          .sort((a, b) => (a * 100 < b * 100 ? -1 : 1));

        const highPrice = priceArray[priceArray.length - 1];
        const lowPrice = priceArray[0];

        return highPrice === lowPrice ? `$${lowPrice}` : `$${lowPrice} - $${highPrice}`;
      },

      getPrice(storeMenuItems) {
        return this.storeCount > 1 ? this.getPriceRange(storeMenuItems) : `$${this.formatPrice(storeMenuItems[0].price)}`;
      },

      openDeleteCategoryConfirmation({ displayName, id }) {
        this.$buefy.dialog.confirm({
          title: 'Delete Category',
          message: `<b>${displayName}</b> and its associated menu resources will be deleted from all locations. Any offers or loyalty features using this will no longer work. Are you sure?`,
          onConfirm: () => this.deleteCategory({ displayName, id }),
          icon: 'trash-alt',
          hasIcon: true,
          confirmText: 'Delete',
          type: 'is-danger'
        });
      },

      openDeleteMenuItemConfirmation({ category, item }) {
        this.$buefy.modal.open({
          parent: this,
          component: alertModal,
          hasModalCard: true,
          trapFocus: true,
          canCancel: ['escape', 'outside'],
          props: {
            icon: 'trash-alt',
            iconPack: 'far',
            title: 'Delete Menu Item',
            message: `Are you sure you want to delete <b>${item.displayName}</b> from the category <b>${category.displayName}</b>? This will apply to all locations.`,
            type: 'is-danger',
            horizontal: true,
            showCloseButton: false,
            buttons: [
              { text: 'Cancel' },
              { text: 'Delete', primary: true, onClick: () => this.deleteMenuItem({ category, item }) }
            ]
          }
        });
      },

      async openDeleteMenuItemFromMenuConfirmation({ item }) {
        this.$buefy.modal.open({
          parent: this,
          component: confirmDeleteItemFromMenu,
          hasModalCard: true,
          trapFocus: true,
          canCancel: ['escape', 'outside'],
          props: { itemId: item.id }
        });
      },

      async fetchItem(itemId) {
        try {
          await Item.fetchItem(itemId);
        }
        catch (error) {
          this.$_onRequestError({
            toastOptions: {
              message: 'There was an error fetching the item'
            },
            error
          });
        }
      },

      async openItemModal({
        itemId,
        category,
        mode,
        defaultTabIndex,
        scrollTo
      }) {
        if (itemId) {
          await this.fetchItem(itemId);
        }

        this.$buefy.modal.open({
          parent: this,
          component: addEditItemModal,
          hasModalCard: true,
          trapFocus: true,
          canCancel: false,
          events: {
            'main-menu:add-item': this.onAddResource,
            'item-updated': this.reSearchMenu
          },
          props: {
            category,
            mode,
            itemId,
            defaultTabIndex,
            scrollTo
          }
        });
      },

      openCategoryModal({ categoryId, mode, defaultTabIndex }) {
        this.$buefy.modal.open({
          parent: this,
          component: addEditCategoryModal,
          hasModalCard: true,
          trapFocus: true,
          canCancel: false,
          customClass: 'auto-width',
          events: {
            'main-menu:add-category': this.onAddResource,
            'category-updated': this.reSearchMenu
          },
          props: {
            categoryId, mode, defaultTabIndex
          }
        });
      },

      onAddResource({ type, id }) {
        // possibly refetch item here to get mappedToPos correct and new pos item name
        this.toggleOpenState({ id, type, isOpen: true });
      },

      sortedMenuItems({ categoryId, menuItems }) {
        if (this.searchResults) {
          return menuItems;
        }
        return menuItems?.sort((a, b) => ((a.sortOrderByCategory(categoryId) < b.sortOrderByCategory(categoryId)) ? -1 : 1));
      },

      async deleteMenuItem({ category, item }) {
        try {
          await CategoryItem.deleteItemFromCategory({ menuCategoryId: category.id, menuItemId: item.id });

          if (this.searchResults?.length) {
            this.reSearchMenu();
          }

          this.$_onRequestSuccess({
            toastOptions: {
              message: `Successfully deleted <b>${item.displayName}</b> from <b>${category.displayName}</b>`
            }
          });
        }
        catch (error) {
          this.$_onRequestError({
            toastOptions: {
              message: `Unable to delete <b>${item.displayName}</b>`
            },
            error
          });
        }
      },

      async deleteCategory({ displayName, id }) {
        try {
          await Category.deleteCategory(id);

          if (this.searchResults?.length) {
            this.reSearchMenu();
          }

          this.$_onRequestSuccess({
            toastOptions: {
              message: `Successfully deleted <b>${displayName}</b>`
            }
          });
        }
        catch (error) {
          this.$_onRequestError({
            toastOptions: {
              message: `Unable to delete <b>${displayName}</b>`
            },
            error
          });
        }
      },

      confirmRemoveFromMenuType({ item, category, all = false }) {
        let message;
        let title = `Remove from ${this.menuType.displayName} Menu`;

        if (item) {
          message = `Are you sure you'd like to remove <b>${item.displayName}</b> from the <b>${this.menuType.displayName} Menu</b>?`;
        }
        else if (category) {
          message = `Removing the <b>${category.displayName}</b> category will remove all of its items. Are you sure you'd like to remove <b>${category.displayName}</b> from the <b>${this.menuType.displayName} Menu</b>?`;
        }
        else if (this.$can('manage', 'all')) {
          title = 'Remove All';
          message = `Remove <b>all categories and items</b> from the <b>${this.menuType.displayName} Menu</b>?`;
        }

        this.$buefy.modal.open({
          parent: this,
          component: alertModal,
          hasModalCard: true,
          trapFocus: true,
          customClass: 'auto-width',
          props: {
            buttons: [
              { text: 'Cancel' },
              {
                text: 'Yes, Remove',
                primary: true,
                onClick: async () => {
                  await this.removeFromMenuType({ item, category, all });
                }
              }
            ],
            horizontal: true,
            showCloseButton: false,
            icon: 'minus-circle',
            title,
            message,
            type: 'is-danger'
          }
        });
      },

      async removeFromMenuType({ item, category, all }) {
        try {
          this.isRemovingFromMenuType = true;

          const body = { menuTypeId: this.menuTypeId };
          let message;

          if (item) {
            body.menuItemIds = [item.id];
            message = `Successfully removed <b>${item.displayName}</b> from the <b>${this.menuType.displayName} Menu</b>`;
          }
          else if (category) {
            body.menuCategoryIds = [category.id];
            message = `Successfully removed <b>${category.displayName}</b> from the <b>${this.menuType.displayName} Menu</b>`;
          }
          else if (all) {
            body.menuCategoryIds = this.categories.map(c => c.id);
            message = `Successfully removed <b>all categories</b> from the <b>${this.menuType.displayName} Menu</b>`;
          }

          await Item.bulkRemoveMenuType(body);

          if (!this.categories.length) {
            this.$router.replace({ name: 'menuManagement', params: { tabName: 'master' } });
          }

          this.$_onRequestSuccess({
            toastOptions: { message }
          });
        }
        catch (error) {
          this.$_onRequestError({
            error,
            toastOptions: {
              message: 'There was an error removing'
            }
          });
        }
        finally {
          this.isRemovingFromMenuType = false;
        }
      },

      async fetchItemsByCategoryId(categoryId) {
        try {
          await Item.fetchItemsByCategoryId({
            categoryId,
            includeMenuItemModifierGroupItems: true,
            options: { forceFetch: true }
          });
        }
        catch (error) {
          this.$_onRequestError({
            toastOptions: {
              message: 'Unable to fetch items'
            },
            error
          });
        }
      },

      async fetchModifierGroupsByItemId(compositeId) {
        try {
          this.itemFetchingModGroupsId = compositeId;
          const itemId = Number(compositeId.split('-')[1]);

          await ModifierGroup.fetchByMenuItemId({ itemId });
        }
        catch (error) {
          this.$_onRequestError({
            toastOptions: {
              message: 'Unable to fetch modifier groups'
            },
            error
          });
        }
        finally {
          this.itemFetchingModGroupsId = null;
        }
      },

      async handleItemSortOrderChange({ moved }, { id: categoryId, menuItems }) {
        const items = menuItems.map((item) => {
          item.sortOrder = item.sortOrderByCategory(categoryId);
          return item;
        });

        try {
          await Item.updateSortOrders({
            categoryId,
            items,
            oldIndex: moved.oldIndex,
            newIndex: moved.newIndex
          });

          this.$_onRequestSuccess({
            toastOptions: {
              message: `<b>${moved.element.displayName}</b> moved from sort order <b>${moved.oldIndex + 1}</b> to <b>${moved.newIndex + 1}</b>`
            }
          });
        }

        catch (error) {
          this.$_onRequestError({
            toastOptions: {
              message: 'Unable to update sort orders'
            },
            error
          });
        }
      },

      async handleCategorySortOrderChange({ moved }) {
        try {
          await Category.updateSortOrders({
            categories: this.categories,
            oldIndex: moved.oldIndex,
            newIndex: moved.newIndex
          });

          this.$_onRequestSuccess({
            toastOptions: {
              message: `<b>${moved.element.displayName}</b> moved from sort order <b>${moved.oldIndex + 1}</b> to <b>${moved.newIndex + 1}</b>`
            }
          });
        }

        catch (error) {
          this.$_onRequestError({
            toastOptions: {
              message: 'Unable to update sort orders'
            },
            error
          });
        }
      },

      setClonedItemIds(ids) {
        this.clonedItemIds = ids;

        setTimeout(() => {
          this.clonedItemIds = [];
        }, 3500);
      },

      async onItemCloned({ categoryIds, itemIds }) {
        try {
          this.isLoadingItemCloned = true;
          const promises = categoryIds.map(categoryId => this.fetchItemsByCategoryId(categoryId));
          await Promise.all(promises);
        }
        catch (error) {
          this.$_onRequestError({
            error,
            toastOptions: {
              message: 'There was an error retrieving your new categories. Please refresh the page.'
            }
          });
        }
        finally {
          this.isLoadingItemCloned = false;
          this.setClonedItemIds(itemIds);
          this.toggleCategories({ categoryIds, isOpen: true });

          setTimeout(() => {
            const firstNewCopy = this.$el.querySelector('.is-cloned-item');

            firstNewCopy.scrollIntoView({
              behavior: 'smooth',
              block: 'center'
            });
          }, 200);
        }
      },

      openSelectMenuTypeModal() {
        if (this.hasAllMenuTypes) {
          this.$buefy.dialog.alert({
            title: 'All Menu Types In Use',
            message: `
              You already have existing menus for all available menu types.
              Please make edits to your existing menus or <a class="link" href="mailto:support@cardfree.com?subject=Menu Type Help">contact support</a> for assistance.
            `,
            confirmText: 'OK',
            trapFocus: true,
            type: 'is-primary'
          });
        }
        else {
          this.$buefy.modal.open({
            parent: this,
            component: selectMenuTypeModal,
            hasModalCard: true,
            trapFocus: true,
            canCancel: false,
            customClass: 'auto-width',
            props: {
              existingMenuTypeIds: this.existingMenuTypeIds,
              missingMenuTypeIds: this.missingMenuTypes.map(mt => mt.id)
            },
            events: {
              'menu-type-selected': this.openAddToMenuTypeModal
            }
          });
        }
      },

      openAddToMenuTypeModal(menuType, mode) {
        this.$buefy.modal.open({
          parent: this,
          component: addToMenuTypeModal,
          hasModalCard: true,
          trapFocus: true,
          canCancel: false,
          customClass: 'auto-width',
          props: { menuType, mode }
        });
      },

      openMenuDayPartSchedule(menuType) {
        this.$buefy.modal.open({
          parent: this,
          component: menuDayPartScheduleModal,
          hasModalCard: true,
          trapFocus: true,
          canCancel: false,
          customClass: 'auto-width',
          props: { menuType }
        });
      },

      async searchMenuV2({ searchQuery, searchType, searchResource }) {
        try {
          this.isLoadingSearchResults = true;
          const results = await searchMenuV2({
            merchantId: this.$_selectedMerchantId,
            searchQuery,
            searchType,
            searchResource,
            menuTypeIds: this.menuTypeId ? [this.menuTypeId] : []
          });
          this.oldSearchQuery = searchQuery;

          this.updateRoute({
            newCriteria: searchType,
            newQuery: searchQuery,
            newSearchResource: searchResource
          });

          this.searchResults = results;
        }

        catch (error) {
          this.$_onRequestError({
            toastOptions: {
              message: 'There was an issue with your search'
            },
            error
          });
        }

        finally {
          this.isLoadingSearchResults = false;
        }
      },

      async searchMenu({ searchQuery, searchType }) {
        try {
          this.isLoadingSearchResults = true;
          const menuCategories = await searchMenu({
            merchantId: this.$_selectedMerchantId,
            searchQuery,
            searchType,
            menuTypeIds: this.menuTypeId ? [this.menuTypeId] : []
          });
          this.oldSearchQuery = searchQuery;

          this.updateRoute({
            newCriteria: searchType,
            newQuery: searchQuery
          });

          this.searchResults = menuCategories;

          this.expandSearchResults();
        }

        catch (error) {
          this.$_onRequestError({
            toastOptions: {
              message: 'There was an issue with your search'
            },
            error
          });
        }

        finally {
          this.isLoadingSearchResults = false;
        }
      },

      expandSearchResults() {
        this.searchResults.forEach((category) => {
          if (category.menuItems.length) {
            this.toggleOpenState({ id: category.id, type: 'category', isOpen: true });
          }
          category.menuItems.forEach((item) => {
            if (item.menuItemModifierGroups.length) {
              this.toggleOpenState({ id: `${category.id}-${item.id}`, type: 'item', isOpen: true });
            }
          });
        });
      },

      updateRoute({ newCriteria, newQuery, newSearchResource }) {
        const { searchQuery, searchType, searchResource } = this.$route.query;
        const hasSearchChanged = searchType !== newCriteria || searchQuery !== newQuery || searchResource !== newSearchResource;
        if (hasSearchChanged) {
          if (this.hasSharedModGroups) {
            this.$router.replace({
              query: {
                searchType: encodeURIComponent(newCriteria),
                searchQuery: encodeURIComponent(newQuery),
                searchResource: encodeURIComponent(newSearchResource)
              }
            });
          }
          else {
            this.$router.replace({
              query: {
                searchType: encodeURIComponent(newCriteria),
                searchQuery: encodeURIComponent(newQuery)
              }
            });
          }
        }
      },

      async reSearchMenu() {
        if (this.searchQuery.length >= this.validSearchLength) {
          const { searchQuery, searchType, searchResource } = this;
          if (this.hasSharedModGroups) {
            await this.searchMenuV2({ searchQuery, searchType, searchResource });
          }
          else {
            await this.searchMenu({ searchQuery, searchType });
          }
        }
      }
    }
  };
</script>

<style lang="sass" scoped>
  .search-field
    flex: 1
    ::v-deep .help
      position: absolute

  .is-cloned-item
    animation-name: highlight-pulse
    animation-duration: 1s
    animation-iteration-count: 3

  .row
    grid-template-columns: 1fr 200px 170px

  @keyframes highlight-pulse
    50%
      background-color: $primary-lighter
</style>
