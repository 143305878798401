<template>
  <div class="menu-management detail-page">
    <b-loading :active="isLoading" :is-full-page="false" />
    <tabs-page
      v-if="!isLoading && $_selectedMerchantId"
      :tabs="tabs"
      :keep-alive="false"
      title="Master Menus"
    />
  </div>
</template>

<script>
  import { paramCase } from 'change-case';
  import { mapGetters } from 'vuex';

  import MerchantMenuTypeConfiguration from '@/store/classes/MerchantMenuTypeConfiguration';
  import MenuTypeSchedule from '@/store/classes/MenuTypeSchedule';
  import logger from '@/services/logger';
  import featurePermissionsMixin from '@/mixins/featurePermissions';
  import merchantMixin from '@/mixins/merchant';
  import Category from '@/store/classes/Category';
  import Store from '@/store/classes/Store';

  import mainMenu from './main-menu.vue';
  import mainMenuV2 from './main-menu-v2.vue';
  import menuTags from './menu-tags/menu-tags.vue';
  import modifierGroups from './modifier-groups/modifier-groups.vue';

  export default {
    name: 'MenuManagement',

    mixins: [merchantMixin, featurePermissionsMixin],

    data() {
      return {
        isLoading: false
      };
    },

    computed: {
      ...mapGetters('session', ['isCardfreeAdmin']),

      categories() {
        return Category.all();
      },

      tabs() {
        return [
          {
            display: 'Master Menu',
            name: 'master',
            component: this.$_featurePermissions.MAIN_MENU_V2 ? mainMenuV2 : mainMenu,
            icon: 'utensils',
            iconPosition: 'left'
          },
          {
            display: 'Modifier Groups',
            name: 'modifier-groups',
            component: modifierGroups,
            icon: 'layer-group',
            iconPosition: 'left',
            isHidden: !this.$_featurePermissions.SHARED_MODIFIER_GROUPS
          },
          {
            display: 'Menu Tags & Search',
            name: 'menu-tags',
            component: menuTags,
            icon: 'magnifying-glass-plus',
            iconPosition: 'left'
          },
          ...this.$_selectedMerchant.supportedMenuTypes.map(menuType => ({
            display: `${menuType.displayName} Menu`,
            name: paramCase(menuType.name),
            icon: MenuTypeSchedule.query().where('menuTypeId', menuType.id).exists() && 'calendar',
            iconPosition: 'left',
            iconPack: 'far',
            component: this.$featurePermissions?.MAIN_MENU_V2 ? mainMenuV2 : mainMenu,
            isHidden: this.hasNoCategoriesInMenuType(menuType.id),
            props: { menuTypeId: menuType.id }
          }))
        ];
      }
    },

    methods: {
      async onMerchantLoad() {
        this.isLoading = true;
        try {
          await Promise.all([
            Store.fetchAll(),
            Category.fetchCategories(),
            MerchantMenuTypeConfiguration.fetchMerchantMenuTypeConfigurations(),
            MenuTypeSchedule.fetchMenuTypeSchedules()
          ]);
        }
        catch (error) {
          logger.error(error);
        }
        finally {
          this.isLoading = false;
        }
      },

      hasNoCategoriesInMenuType(menuTypeId) {
        return !this.categories.find(category => category.menuTypes.find(mt => mt.id === menuTypeId));
      }
    }
  };
</script>
