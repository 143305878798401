<template>
  <li
    :class="{'is-open': isOpen, 'draggable': !isSearchRow }"
    :data-category-id="category.id"
  >
    <div class="row">
      <span class="pad-y-sm">
        <b-icon
          v-if="canSortCategory && !isSearchRow"
          icon="grip-lines"
          size="is-small"
          pack="far"
          class="drag-handle"
        />
        <span
          :class="{ 'link-inverted': !isSearchRow }"
          @click="!isSearchRow && toggleCategory()"
        >
          <span class="has-text-weight-bold">{{ category.displayName }}</span>
          <b-icon
            v-if="!isSearchRow"
            size="is-small"
            pack="far"
            :icon="fetchingCategory ? 'spinner-third' : 'angle-right'"
            :class="[
              'open-indicator',
              {
                'is-open': isOpen,
                'spin': fetchingCategory
              }
            ]"
          />
        </span>
      </span>
      <span />
      <span class="align-center justify-end">
        <b-button
          v-if="category.hasSchedule && !activeMenuType"
          v-tippy="{ content: 'Restricted Availability', placement: 'left', delay: [300, 0] }"
          class="is-transparent"
          :disabled="fetchingCategory"
          @click="openCategoryModal({ defaultTabIndex: 1 })"
        >
          <b-icon icon="calendar" pack="far" />
        </b-button>

        <template v-if="activeMenuType">
          <tippy
            v-if="$can('update', 'MenuCategory') && menuPermissions.MANAGE_MENU_TYPE"
            placement="left"
            max-width="500"
          >
            <template #trigger>
              <b-button
                class="is-transparent"
                @click="confirmRemoveFromMenuType({ category })"
                @contextmenu.prevent="confirmRemoveFromMenuType({ all: true })"
              >
                <b-icon icon="minus-circle" type="is-danger" size="is-small" />
              </b-button>
            </template>

            <div class="is-size-6">
              Remove <span class="has-text-weight-semibold">{{ category.displayName }}</span> from {{ activeMenuType.displayName }} Menu
            </div>
          </tippy>
        </template>

        <template v-else>
          <b-button
            class="is-transparent"
            :disabled="fetchingCategory"
            @click="openCategoryModal({})"
          >
            <b-icon
              v-if="$can('update', 'MenuCategory') && menuPermissions.EDIT_RESOURCE"
              :icon="fetchingCategory ? 'spinner-third' : 'pencil'"
              :class="{ 'spin': fetchingCategory }"
              size="is-small"
            />
            <span v-else>View</span>
          </b-button>
          <b-dropdown
            v-if="($can('destroy', 'MenuCategory') && menuPermissions.REMOVE_RESOURCE)"
            aria-role="list"
            position="is-bottom-left"
            :disabled="fetchingCategory"
            :mobile-modal="false"
          >
            <b-button
              slot="trigger"
              class="is-transparent"
              type="is-white"
            >
              <b-icon
                icon="ellipsis-v"
                pack="far"
                size="is-small"
              />
            </b-button>
            <template v-if="$can('destroy', 'MenuCategory') && menuPermissions.REMOVE_RESOURCE">
              <b-dropdown-item
                class="is-danger"
                @click="openDeleteCategoryConfirmation()"
              >
                <b-icon
                  icon="trash-alt"
                  class="mar-r-sm"
                  size="is-small"
                />
                Delete Category
              </b-dropdown-item>
            </template>
          </b-dropdown>
        </template>
      </span>
    </div>
    <menu-items-container
      v-show="isOpen"
      :category="category"
    />
  </li>
</template>

<script>
  import { mapActions, mapState } from 'vuex';

  import menuResourceTypes from '@/constants/menuResourceTypes';

  import AlertModal from '@/components/globals/alert-modal.vue';

  import Category from '@/store/classes/Category';
  import Item from '@/store/classes/Item';

  import AddEditCategoryModal from '../main-menu/add-edit-category-modal/index.vue';
  import MenuItemsContainer from './menu-items-container.vue';


  export default {
    name: 'CategoryRow',

    components: { MenuItemsContainer },

    props: {
      category: {
        type: Object,
        required: true
      },

      isSearchRow: {
        type: Boolean,
        default: false
      }
    },

    computed: {
      ...mapState('mainMenu', ['activeMenuType', 'menuPermissions', 'categoriesOpenedStatus']),

      isOpen() {
        return this.categoriesOpenedStatus[this.category.id];
      },

      fetchingCategory() {
        return Item.$state().fetchingCategoryId === this.category.id
          || Item.$state().sortingParentId === this.category.id
          || Category.$state().fetchingSingle;
      },

      canSortCategory() {
        return [
          this.$can('update', 'MenuCategory'),
          !this.activeMenuType,
          this.menuPermissions?.SORT_RESOURCE
        ].every(x => x);
      }
    },

    methods: {
      ...mapActions('mainMenu', ['setIsRemovingFromMenuType', 'setMenuResourceOpenState']),

      async toggleCategory() {
        const wasNeverOpen = this.isOpen === undefined;
        if (wasNeverOpen) {
          await this.fetchCategoryItems();
        }

        this.setMenuResourceOpenState({
          resourceType: menuResourceTypes.CATEGORY,
          resourceId: this.category.id,
          value: !this.isOpen
        });
      },

      openDeleteCategoryConfirmation() {
        this.$buefy.modal.open({
          parent: this,
          component: AlertModal,
          hasModalCard: true,
          trapFocus: true,
          customClass: 'auto-width',
          props: {
            buttons: [
              { text: 'Cancel' },
              {
                text: 'Delete',
                primary: true,
                onClick: this.deleteCategory
              }
            ],
            horizontal: true,
            showCloseButton: false,
            icon: 'trash-alt',
            title: 'Delete Category',
            message: `<b>${this.category.displayName}</b> and its associated menu resources will be deleted from all locations. Any offers or loyalty features using this will no longer work. Are you sure?`,
            type: 'is-danger'
          }
        });
      },


      async deleteCategory() {
        try {
          await Category.deleteCategory(this.category.id);

          this.$_onRequestSuccess({
            toastOptions: {
              message: `Successfully deleted <b>${this.category.displayName}</b>`
            }
          });
        }
        catch (error) {
          this.$_onRequestError({
            toastOptions: {
              message: `Unable to delete <b>${this.category.displayName}</b>`
            },
            error
          });
        }
      },

      async fetchCategoryItems() {
        try {
          await Item.fetchItemsByCategoryId({ categoryId: this.category.id, includeMenuItemModifierGroupItems: true });
        }
        catch (error) {
          this.$_onRequestError({
            toastOptions: {
              message: 'Unable to fetch items'
            },
            error
          });
        }
      },

      openCategoryModal({ defaultTabIndex }) {
        this.$buefy.modal.open({
          parent: this,
          component: AddEditCategoryModal,
          hasModalCard: true,
          trapFocus: true,
          canCancel: false,
          customClass: 'auto-width',
          events: {
            'category-updated': () => this.$emit('re-search-menu')
          },
          props: {
            categoryId: this.category.id,
            mode: 'update',
            defaultTabIndex
          }
        });
      },

      confirmRemoveFromMenuType({ category, all = false }) {
        let message;
        let title = `Remove from ${this.menuType.displayName} Menu`;

        if (category) {
          message = `Removing the <b>${category.displayName}</b> category will remove all of its items. Are you sure you'd like to remove <b>${category.displayName}</b> from the <b>${this.menuType.displayName} Menu</b>?`;
        }
        else if (this.$can('manage', 'all')) {
          title = 'Remove All';
          message = `Remove <b>all categories and items</b> from the <b>${this.menuType.displayName} Menu</b>?`;
        }

        this.$buefy.modal.open({
          parent: this,
          component: AlertModal,
          hasModalCard: true,
          trapFocus: true,
          customClass: 'auto-width',
          props: {
            buttons: [
              { text: 'Cancel' },
              {
                text: 'Yes, Remove',
                primary: true,
                onClick: async () => {
                  await this.removeFromMenuType({ category, all });
                }
              }
            ],
            horizontal: true,
            showCloseButton: false,
            icon: 'minus-circle',
            title,
            message,
            type: 'is-danger'
          }
        });
      },

      async removeFromMenuType({ category, all }) {
        try {
          this.setIsRemovingFromMenuType(true);

          const body = { menuTypeId: this.activeMenuType.id };
          let message;

          if (category) {
            body.menuCategoryIds = [category.id];
            message = `Successfully removed <b>${category.displayName}</b> from the <b>${this.activeMenuType.displayName} Menu</b>`;
          }
          else if (all) {
            body.menuCategoryIds = this.categories.map(c => c.id);
            message = `Successfully removed <b>all categories</b> from the <b>${this.activeMenuType.displayName} Menu</b>`;
          }

          await Item.bulkRemoveMenuType(body);

          if (!this.categories.length) {
            this.$router.replace({ name: 'menuManagement', params: { tabName: 'master' } });
          }

          this.$_onRequestSuccess({
            toastOptions: { message }
          });
        }
        catch (error) {
          this.$_onRequestError({
            error,
            toastOptions: {
              message: 'There was an error removing'
            }
          });
        }
        finally {
          this.setIsRemovingFromMenuType(false);
        }
      }
    }
  };
</script>

<style lang='sass' scoped>
  .row
      grid-template-columns: 1fr 200px 170px
</style>
