const statuses = {
  LIVE: 'live',
  SCHEDULED: 'scheduled',
  DRAFT: 'draft',
  EXPIRED: 'expired'
};

const inAppMessageTypeNames = {
  APP_LOAD: 'App Load',
  CLAIM_CONFIRMATION: 'Claim Confirmation',
  HOME: 'Home',
  LOYALTY: 'Loyalty',
  MENU: 'Menu',
  RECEIPT: 'Receipt'
};

export {
  statuses,
  inAppMessageTypeNames
};
