<template>
  <div class="is-flex-wrap gap-xl mar-t-md">
    <div class="is-flex align-center gap-sm">
      <validated-input
        :rules="{
          required: true,
          min_value: 1
        }"
        name="maximumHourlyDuration"
        label="Time Period"
        class="mar-none"
      >
        <b-input
          :value="computedDuration"
          type="number"
          icon="hashtag"
          min="1"
          data-test-id="duration-input"
          @input="handleDurationChange({ duration: $event })"
        />
        <b-select
          :value="durationType"
          data-test-id="duration-type-select"
          @input="handleDurationTypeChange"
        >
          <option
            v-for="dt in durationTypes"
            :key="dt.value"
            :value="dt.value"
          >
            {{ dt.display }}
          </option>
        </b-select>
      </validated-input>
    </div>
    <check-button
      :value="earnRuleConstraint.applyOnlyToRetroClaims"
      name="applyOnlyToRetroClaims"
      label="Only Apply to Retroactive Claims"
      sublabel="(Optional)"
      :disabled="readOnly"
      @input="handleInput({ applyOnlyToRetroClaims: $event })"
    />
  </div>
</template>

<script>
  import { capitalCase } from 'change-case';

  export default {
    name: 'MaximumOrderAgeCard',

    props: {
      earnRuleConstraint: {
        type: Object,
        required: true
      },

      readOnly: {
        type: Boolean,
        default: false
      }
    },

    data() {
      return {
        durationType: 'hour'
      };
    },

    computed: {
      durationTypes() {
        return [
          { value: 'hour', display: 'Hour' },
          { value: 'day', display: 'Day' },
          { value: 'week', display: 'Week' },
          { value: 'month', display: 'Month' },
          { value: 'year', display: 'Year' }
        ].map(type => ({
          ...type,
          display: `${type.display}${this.computedDuration > 1 ? 's' : ''}`
        }));
      },

      computedDuration() {
        switch (this.durationType) {
          case 'year':
            return this.earnRuleConstraint.maximumHourlyDuration / 8760;
          case 'month':
            return this.earnRuleConstraint.maximumHourlyDuration / 720;
          case 'week':
            return this.earnRuleConstraint.maximumHourlyDuration / 168;
          case 'day':
            return this.earnRuleConstraint.maximumHourlyDuration / 24;
          default:
            return this.earnRuleConstraint.maximumHourlyDuration;
        }
      }
    },

    created() {
      if (this.earnRuleConstraint.maximumHourlyDuration) {
        this.durationType = this.detectDurationType(this.earnRuleConstraint.maximumHourlyDuration);
      }
    },

    methods: {
      capitalCase,

      detectDurationType(hours) {
        switch (true) {
          case hours % 8760 === 0:
            return 'year';
          case hours % 720 === 0:
            return 'month';
          case hours % 168 === 0:
            return 'week';
          case hours % 24 === 0:
            return 'day';
          default:
            return 'hour';
        }
      },

      handleDurationChange({ duration = this.computedDuration, durationType = this.durationType }) {
        let maximumHourlyDuration;

        switch (durationType) {
          case 'year':
            maximumHourlyDuration = duration * 8760;
            break;
          case 'month':
            maximumHourlyDuration = duration * 720;
            break;
          case 'week':
            maximumHourlyDuration = duration * 168;
            break;
          case 'day':
            maximumHourlyDuration = duration * 24;
            break;
          default:
            maximumHourlyDuration = duration;
            break;
        }
        this.handleInput({ maximumHourlyDuration });
      },

      handleDurationTypeChange(durationType) {
        this.handleDurationChange({ durationType });
        this.durationType = durationType;
      },

      handleInput(payload) {
        this.$emit('update-constraint', { ...this.earnRuleConstraint, ...payload });
      }
    }
  };
</script>
