import { mapActions } from 'vuex';

const multiFormChildProvider = {
  mounted() {
    this.p_setValidationAndSubmitHandlers();
  },

  methods: {
    ...mapActions('formStore', [
      'addValidationHandler',
      'addSubmitHandler'
    ]),

    p_setValidationAndSubmitHandlers() {
      const form = this.$refs[this.formRef];

      if (form) {
        this.addValidationHandler({
          // NOTE: validate(), focusFirstInvalid(), handleSuccessfulValidation() live in <validated-form>
          validate: form.validate,
          formRef: this.formRef, // REQUIRED: Define this as a prop for each tab (should match the ref of each wrapper form)
          focusFirstInvalid: form.$_focusFirstInvalid,
          handleSuccessfulValidation: form.handleSuccessfulValidation
        });
      }

      if (this.handleSubmit) {
        this.addSubmitHandler({ handleSubmit: this.handleSubmit, formId: this.formRef }); // REQUIRED: Each child form should contain a handleSubmit() method
      }
    }
  }
};

export default multiFormChildProvider;
