<template>
  <div>
    <div
      v-for="modifier in searchResults"
      :key="`modifier-${modifier.id}`"
      :data-modifier-id="modifier.id"
      :class="{
        'show-inventory': $_menuPermissions.UPDATE_INVENTORY,
        'hide-prices':!$_selectedMerchant.features.menuPrices
      }"
    >
      <div class="row sub-row">
        <div class="is-flex-column align-start pad-y-sm">
          <img
            v-if="$_featurePermissions.NESTED_MODIFIERS && modifier.menuItemModifierGroup.type === modifierGroupTypes.PORTION"
            :class="['mar-r-sm', { 'transform-180': ['Right Half', '2nd Half'].includes(modifier.displayName) }]"
            :src="portionIcons[modifier.modifierTemplate]"
          >
          <span>
            <span class="has-text-weight-bold is-size-6">{{ modifier.displayName }}</span>

            <b-icon
              v-if="!modifier.mappedToPos && !$_hasPosType(posTypes.Cardfree)"
              v-tippy="{ content: 'Missing POS Mapping', placement: 'right' }"
              size="is-small"
              icon="exclamation-triangle"
              type="is-danger"
              class="mar-l"
            />
          </span>
          <span class="is-size-7"><b>Modifier Group:</b> {{ modifier.menuItemModifierGroup.displayName }}</span>
        </div>
        <span v-if="$_selectedMerchant.features.menuPrices">
          <template v-if="!$_featurePermissions.NESTED_MODIFIERS || modifier.menuItemModifierGroup.type !== modifierGroupTypes.PORTION">
            <template v-if="getStoreModifierPrice(modifier)">
              <div class="is-flex align-center">
                <span>{{ getStoreModifierPrice(modifier) | dollars }}</span>
                <b-icon
                  v-if="getStoreModifierPriceOverrides(modifier).length"
                  v-tippy="{
                    content: getPriceOverridesTippyContent(modifier),
                    placement: 'left'
                  }"
                  icon="circle-dollar"
                  size="is-small"
                  class="has-text-info mar-l-xs"
                />
              </div>
            </template>
            <span v-else class="has-text-grey">N/A</span>
          </template>
        </span>
        <span v-if="$_menuPermissions.UPDATE_INVENTORY" />
        <span class="align-right">
          <b-icon
            v-if="showSuspendedIconForModifier(modifier)"
            v-tippy="{
              content: `Unavailable until ${adjustDateToStoreTimezone(getStoreModifierSuspendUntilDate(modifier)).format('MM/DD/YY h:mm A')} ${timeZoneAbbr}`,
              placement: 'left',
              delay: [333, 0]
            }"
            icon="alarm-clock"
            size="is-small"
          />
          <b-button
            v-if="!$_featurePermissions.NESTED_MODIFIERS || modifier.menuItemModifierGroup.type !== modifierGroupTypes.PORTION"
            class="is-transparent"
            @click="emitSharedModGroupsSearchAction({ actionName: 'open-store-modifier-modal', args: { modifier, storeId: selectedStoreId } })"
          >
            <b-icon
              v-if="($can('update', 'StoreMenuItemModifier', 'price') || $can('update', 'StoreMenuItemModifier', 'totalSalesTaxRate'))
                && $_menuPermissions.UPDATE_PRICE_TAX"
              icon="pencil"
              size="is-small"
            />
            <span v-else>View</span>
          </b-button>
          <span v-else />
        </span>
      </div>
    </div>
  </div>
</template>

<script>
  import merchantMixin from '@/mixins/merchant';
  import featurePermissionsMixin from '@/mixins/featurePermissions';
  import posTypes from '@/constants/posTypes';
  import { modifierGroupTypes, modifierGroupPortionTemplates } from '@/constants/modifierGroups';
  import moment from 'moment-timezone';
  import Store from '@/store/classes/Store';

  export default {
    name: 'ModifierSearchRow',

    mixins: [merchantMixin, featurePermissionsMixin],

    props: {
      searchResults: {
        type: Array,
        default: () => []
      },

      selectedStoreId: {
        type: Number,
        required: true
      },

      storeModifiersForSelectedStore: {
        type: Array,
        default: () => []
      }
    },

    data: () => ({
      posTypes,
      portionIcons: {
        [modifierGroupPortionTemplates.FULL_GROUP_TEMPLATE.constant]: '/images/whole-portion.svg',
        [modifierGroupPortionTemplates.HALF_GROUP_TEMPLATE.constant]: '/images/half-portion.svg'
      },
      modifierGroupTypes
    }),

    computed: {
      selectedStore() {
        return Store.find(this.selectedStoreId);
      },

      timeZoneAbbr() {
        return moment.tz(this.selectedStore.ianaTimezoneId).zoneAbbr();
      }
    },

    methods: {
      emitSharedModGroupsSearchAction(args) {
        this.$emit('shared-mod-group-search-action', args);
      },

      getStoreModifier(modifier) {
        return this.storeModifiersForSelectedStore.find(storeModifier => storeModifier.menuItemModifierId === modifier.id);
      },

      getStoreModifierPrice(modifier) {
        return this.getStoreModifier(modifier)?.price;
      },

      getStoreModifierPriceOverrides(modifier) {
        return this.getStoreModifier(modifier)?.storeMenuItemModifierPriceOverrides || [];
      },

      getPriceOverridesTippyContent(modifier) {
        const overrides = this.getStoreModifierPriceOverrides(modifier);
        const overridePrices = overrides.map(o => o.price).sort((a, b) => a - b);
        return overridePrices.map(p => this.$options.filters.dollars(p)).join(' / ');
      },

      getStoreModifierSuspendUntilDate(modifier) {
        const storeModifier = this.getStoreModifier(modifier);
        return storeModifier?.suspendUntilDate;
      },

      showSuspendedIconForModifier(modifier) {
        const suspendedUntilDate = this.getStoreModifierSuspendUntilDate(modifier);
        if (!suspendedUntilDate) {
          return false;
        }

        const dateInStoreTimezone = this.adjustDateToStoreTimezone(suspendedUntilDate);

        const nowInStoreTimezone = moment().tz(this.selectedStore.ianaTimezoneId);
        return nowInStoreTimezone.isBefore(dateInStoreTimezone);
      },

      adjustDateToStoreTimezone(date) {
        return moment.utc(date).tz(this.selectedStore.ianaTimezoneId);
      },

      getVisibilityTooltip(storeMenuResource) {
        if (storeMenuResource) {
          const { createdAt, createdSource, updatedAt, modifiedSource } = storeMenuResource;
          const date = !updatedAt
            ? moment.tz(createdAt, this.selectedStore.ianaTimezoneId).format('MM/DD/YY h:mm A z')
            : moment.tz(updatedAt, this.selectedStore.ianaTimezoneId).format('MM/DD/YY h:mm A z');
          const source = !updatedAt ? createdSource?.split('-')[2] : modifiedSource?.split('-')[2];
          const sourceString = source ? `<p>by ${source}</p>` : '';
          return `<p>${updatedAt ? 'Updated' : 'Created'} ${date}</p>${sourceString}`;
        }
      }
    }
  };
</script>

<style lang='sass' scoped>
  .transform-180
    transform: rotate(180deg)

  .row
    grid-template-columns: minmax(200px, 1fr) minmax(75px, 0.2fr) minmax(100px, 0.25fr)

    .show-inventory &
      grid-template-columns: minmax(200px, 1fr) minmax(75px, 0.2fr) minmax(145px, 200px) minmax(100px, 0.25fr)

    .hide-prices &
      grid-template-columns: minmax(200px, 1fr) minmax(145px, 200px) minmax(100px, 0.25fr)
</style>
