var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('panel',{attrs:{"title":"Menu Tags","no-padding":""}},[(_vm.isFetching)?_c('b-loading',{attrs:{"active":_vm.isFetching,"is-full-page":false}}):_vm._e(),_c('searchable-table',{attrs:{"table-data":_vm.merchantTags,"search":{
      placeholder: 'Search by Tag Name',
      keys: ['name']
    }},scopedSlots:_vm._u([{key:"optional-actions",fn:function(){return [_c('b-button',{attrs:{"type":"is-primary","icon-left":"plus"},on:{"click":function($event){return _vm.openTagModal()}}},[_vm._v(" Menu Tag ")])]},proxy:true},{key:"default",fn:function(ref){
    var filteredData = ref.filteredData;
return [_c('b-table',{staticClass:"is-middle-aligned",attrs:{"data":_vm.isFetching ? [] : filteredData,"mobile-cards":false,"scrollable":"","data-test-id":"menu-tags-table","default-sort":['name', 'asc'],"pagination-simple":"","per-page":_vm.pageLimit,"paginated":filteredData.length > _vm.pageLimit},scopedSlots:_vm._u([{key:"empty",fn:function(){return [_c('empty-table-loader',{attrs:{"message":"No Menu Tags Found...","loading":_vm.isFetching}})]},proxy:true}],null,true)},[_c('b-table-column',{attrs:{"sortable":"","field":"name","label":"Name","width":"600"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
    var row = ref.row;
return [_c('span',{staticClass:"is-flex align-center"},[_c('b-tag',{staticClass:"link",attrs:{"type":"is-primary","closeable":false,"size":"is-medium"},on:{"click":function($event){return _vm.openTagModal(row)}}},[_vm._v(" "+_vm._s(row.name)+" ")]),_c('b-button',{staticClass:"search-suggestion-btn",attrs:{"disabled":_vm.searchSuggestionDisabled(row),"loading":_vm.updatingSearchSuggestionId === row.id},on:{"click":function($event){return _vm.updateSearchSuggestion(row)}}},[(_vm.updatingSearchSuggestionId !== row.id)?_c('b-icon',{directives:[{name:"tippy",rawName:"v-tippy",value:({
                  content: _vm.searchSuggestionDisabled(row)
                    ? 'You can only have 6 search suggestion tags'
                    : 'Toggle search suggestion',
                  placement: 'right'
                }),expression:"{\n                  content: searchSuggestionDisabled(row)\n                    ? 'You can only have 6 search suggestion tags'\n                    : 'Toggle search suggestion',\n                  placement: 'right'\n                }"}],attrs:{"icon":"star","pack":row.isSearchSuggestion ? 'fas' : 'far',"type":"is-warning"}}):_vm._e()],1)],1)]}}],null,true)}),_c('b-table-column',{attrs:{"sortable":"","centered":"","field":"itemCount","label":"Items"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var row = ref.row;
return [_c('span',[_vm._v(" "+_vm._s(row.itemCount)+" ")])]}}],null,true)}),_c('b-table-column',{attrs:{"centered":"","cell-class":"actions","label":"Actions","width":"1","field":"Actions"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var row = ref.row;
return [_c('dropdown-menu',{attrs:{"position":"bottom-end","mobile-modal":false}},[_c('b-button',{staticClass:"is-transparent",attrs:{"slot":"trigger","type":"is-white"},slot:"trigger"},[_c('b-icon',{attrs:{"icon":"ellipsis-v","pack":"far","size":"is-small"}})],1),_c('b-dropdown-item',{on:{"click":function($event){return _vm.openTagModal(row)}}},[_c('b-icon',{staticClass:"mar-r-sm",attrs:{"icon":"pencil","size":"is-small"}}),_vm._v(" Edit Tag ")],1),_c('hr',{staticClass:"dropdown-divider"}),_c('b-dropdown-item',{on:{"click":function($event){return _vm.confirmDeleteTag(row)}}},[_c('b-icon',{staticClass:"mar-r-sm",attrs:{"icon":"trash","size":"is-small","type":"is-danger"}}),_vm._v(" Delete Tag ")],1)],1)]}}],null,true)})],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }