// classes
import { Model } from '@vuex-orm/core';
import http from '@/services/http';
import filterObjectKeys from '@/helpers/filter-object-keys';


export default class MerchantOptionsEmv extends Model {
  static entity = 'merchantOptionsEmvs'

  // FIELDS //////////////////////
  static fields() {
    return {
      id: this.attr(''),
      merchantId: this.attr(''),
      disableEmvSplitPay: this.attr(''),
      emvCashPaymentsEnabled: this.attr(''),
      emvIncentivizedOptInForAllStores: this.attr(''),
      emvRoomChargePaymentsEnabled: this.attr(''),
      signatureRequiredForRoomCharge: this.attr('')
    };
  }

  // STATE //////////////////////
  static state() {
    return {
      fetching: false,
      submitting: false
    };
  }

  static $state() {
    return this.store().state.entities.merchantOptionsEmvs;
  }

  static blackListedKeys = ['id', '$id', 'merchantId'];


  // ACTIONS //////////////////////
  static async fetchMerchantOptionsEmvs(merchantId) {
    try {
      this.commit((state) => {
        state.fetching = true;
      });

      const _merchantId = merchantId || this.store().state.entities.merchants.selectedMerchantId;
      const response = await http.get(`merchants/${_merchantId}/merchant_options_emvs`);

      this.insert({
        data: response.data.merchantOptionsEmvs
      });
      return response.data.merchantOptionsEmvs;
    }
    catch (error) {
      throw error;
    }
    finally {
      this.commit((state) => {
        state.fetching = false;
      });
    }
  }

  static async addMerchantOptionsEmv(merchantOptionsEmv) {
    try {
      this.commit((state) => {
        state.submitting = true;
      });

      const response = await http.post(
        `merchants/${merchantOptionsEmv.merchantId}/merchant_options_emvs`,
        { merchantOptionsEmv: filterObjectKeys(merchantOptionsEmv, this.blackListedKeys, true) }
      );

      this.insert({
        data: response.data.merchantOptionsEmv
      });
    }
    catch (error) {
      throw error;
    }
    finally {
      this.commit((state) => {
        state.submitting = false;
      });
    }
  }

  static async updateMerchantOptionsEmv(merchantOptionsEmv) {
    try {
      this.commit((state) => {
        state.submitting = true;
      });

      const response = await http.put(
        `merchant_options_emvs/${merchantOptionsEmv.id}`,
        { merchantOptionsEmv: filterObjectKeys(merchantOptionsEmv, this.blackListedKeys, true) }
      );

      this.update({
        data: response.data.merchantOptionsEmv
      });
    }
    catch (error) {
      throw error;
    }
    finally {
      this.commit((state) => {
        state.submitting = false;
      });
    }
  }
}
