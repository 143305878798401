<template>
  <modal-card
    :title="`Order #${order.ticketNumber}`"
    :subtitle="`Order ID: ${order.orderId}`"
    modal-card-body-class="pad-none"
    show-small-subtitle
  >
    <div v-if="isFetching" class="pad-xl mar-xl">
      <b-loading active :is-full-page="false" />
    </div>
    <div v-else style="max-width: 600px">
      <section class="dist-y-sm">
        <p class="is-flex align-center">
          <b-icon icon="user" size="is-small" type="is-primary" class="mar-r-sm" />
          <span class="is-size-4">{{ order.guestFullName }}</span>
        </p>

        <div>
          <p class="email">
            <b-icon class="mar-r-sm" icon="envelope" type="is-primary" size="is-small" />
            <a v-if="order.email" class="link-inverted" :href="'mailto:' + order.email">{{ order.email }}</a>
            <span v-else class="has-text-grey">N/A</span>
          </p>

          <p class="phone">
            <b-icon class="mar-r-sm" icon="phone" type="is-primary" size="is-small" />
            <a v-if="order.phoneNumber" class="link-inverted no-wrap-text" :href="`tel:1${order.phoneNumber}`">{{ order.phoneNumber | phoneNumber }}</a>
            <span v-else class="has-text-grey">N/A</span>
          </p>
        </div>

        <div v-if="order.onSiteVehicle && Object.keys(order.onSiteVehicle).length">
          <p>
            <b-icon class="mar-r-sm" icon="car" type="is-primary" size="is-small" />
            <span class="no-wrap-text">{{ ['color', 'make', 'model'].map(x => order.onSiteVehicle[x]).filter(Boolean).join(' ') }}</span>
          </p>
          <p v-if="order.onSiteVehicle.spotNumber">
            <b-icon class="mar-r-sm" icon="map-marker-check" type="is-primary" size="is-small" />
            <span class="no-wrap-text">{{ order.onSiteVehicle.spotNumber }}</span>
          </p>
        </div>
      </section>

      <section>
        <template v-if="supportsOrderRedemption">
          <div v-if="!hasBeenRefunded" class="is-flex justify-between align-center mar-b-md">
            <p>Select Items to redeem:</p>
            <b-button
              type="is-primary is-light"
              :disabled="selectedItemIds.length === order.orderItems.length"
              @click="selectedItemIds = order.orderItems.map(item => item.orderItemId)"
            >
              Select All
            </b-button>
          </div>


          <transition name="fade-right" mode="out-in">
            <b-message
              v-if="hasBeenRefunded && order.preparationStatus === 'Completed'"
              key="has refund"
              class="is-compact has-shadow mar-t-md fit-content"
              icon="exclamation-triangle"
              has-icon
              type="is-primary"
            >
              Orders with refunds can no longer be updated
            </b-message>
            <b-message
              v-else-if="redeemedOrderItems.length && !selectedItemIds.length"
              key="unredeem warning new"
              class="is-compact has-shadow mar-t-md fit-content"
              icon="exclamation-triangle"
              has-icon
              type="is-primary"
            >
              Unredeeming all items will move this ticket back to "New"
            </b-message>
            <b-message
              v-else-if="order.orderItems.length === redeemedOrderItems.length && selectedItemIds.length < redeemedOrderItems.length"
              key="unredeem warning in progress"
              class="is-compact has-shadow mar-t-md fit-content"
              icon="exclamation-triangle"
              has-icon
              type="is-primary"
            >
              Unredeeming items will move this ticket back to "In Progress"
            </b-message>
            <b-message
              v-else-if="order.orderItems.length === redeemedOrderItems.length"
              key="all items redeemed"
              class="is-compact has-shadow mar-t-md fit-content"
              icon="check"
              has-icon
              icon-size="is-medium"
              type="is-primary"
            >
              All order items have been redeemed
            </b-message>
            <b-message
              v-else-if="selectedItemIds.length === order.orderItems.length"
              key="move to completed"
              class="is-compact has-shadow mar-t-md fit-content"
              icon="exclamation-triangle"
              has-icon
              type="is-primary"
            >
              Marking all items as redeemed will move this ticket to "Completed"
            </b-message>
          </transition>


          <div class="is-grid col-2 gap-md">
            <check-button
              v-for="item in [...unredeemedOrderItems, ...redeemedOrderItems]"
              :key="item.orderItemId"
              v-model="selectedItemIds"
              :native-value="item.orderItemId"
              size="is-medium"
              orientation="vertical"
              class="pad-lg"
              :disabled="order.preparationStatus === 'Completed' && hasBeenRefunded"
            >
              <p class="is-size-6">{{ item.itemDescription }}</p>
              <p v-if="item.modifiers && item.modifiers.length" class="has-text-weight-normal" style="font-size: 0.85rem">
                {{ modifierDisplay(item.modifiers) }}
              </p>
              <p v-if="item.isRedeemed" :class="['has-text-weight-normal', !selectedItemIds.includes(item.orderItemId) && 'has-text-strikethrough']" style="font-size: 0.85rem">
                Redeemed {{ item.redemptionDate | moment('@ h:mm A z', order.store.timeZone) }}
              </p>
            </check-button>
          </div>
        </template>
        <b-collapse v-else-if="order.orderItems.length">
          <div
            slot="trigger"
            slot-scope="props"
            role="button"
            class="is-flex justify-between align-center link-inverted"
          >
            <h4 class="is-size-5">
              Order Items
            </h4>
            <b-icon class="open-indicator" :icon="props.open ? 'angle-down' : 'angle-right'" />
          </div>
          <div class="mar-t dist-y-lg">
            <b-message
              v-if="order.orderLevelInstructions"
              type="is-warning"
              class="has-shadow is-compact fit-content"
            >
              <p class="has-text-weight-bold mar-b-xs">Special Instructions</p>
              <p>{{ order.orderLevelInstructions }}</p>
            </b-message>

            <order-details-items :order="order" />
            <order-price-breakdown :order="order" />
          </div>
        </b-collapse>
        <p v-else class="has-text-grey">No Order Items</p>
      </section>

      <section>
        <b-collapse :open="false">
          <div
            slot="trigger"
            slot-scope="props"
            role="button"
            class="is-flex justify-between align-center link-inverted"
          >
            <h4 class="is-size-5">
              Order Info
            </h4>
            <b-icon class="open-indicator" :icon="props.open ? 'angle-down' : 'angle-right'" />
          </div>
          <div class="is-grid col-2 gap-md mar-t">
            <b-field label="Order Date" custom-class="mar-b-xs">
              <div>
                <p style="line-height: 1.3">{{ order.orderDate | moment('MMMM D, yyyy', order.store.timeZone) }}</p>
                <p style="line-height: 1.3">{{ order.orderDate | moment('@ h:mm A z', order.store.timeZone) }}</p>
              </div>
            </b-field>
            <b-field v-if="supportsOrderRedemption" label="Redemption Date" custom-class="mar-b-xs">
              <div v-if="order.preparationStatus === 'Completed' && latestRedemptionDate">
                <p style="line-height: 1.3">{{ latestRedemptionDate | moment('MMMM D, yyyy', order.store.timeZone) }}</p>
                <p style="line-height: 1.3">{{ latestRedemptionDate | moment('@ h:mm A z', order.store.timeZone) }}</p>
              </div>
              <span v-else class="has-text-grey-light">
                N/A
              </span>
            </b-field>
          </div>
        </b-collapse>
      </section>

      <section v-if="hasBeenRefunded">
        <b-collapse :open="false">
          <div
            slot="trigger"
            slot-scope="props"
            role="button"
            class="is-flex justify-between align-center link-inverted"
          >
            <h4 class="is-size-5">
              Payment Info
              <b-icon
                v-tippy="{ maxWidth: 270 }"
                content="Only successful are displayed"
                pack="far"
                icon="info-circle"
                class="is-size-6"
                type="is-grey"
              />
            </h4>
            <b-icon class="open-indicator" :icon="props.open ? 'angle-down' : 'angle-right'" />
          </div>
          <div class="dist-y mar-t">
            <div v-for="payment in order.payments" :key="payment.id">
              <h4 class="has-text-weight-bold is-uppercase mar-r is-flex align-center">
                <payment-icon :payment="payment" />
                <template v-if="payment.cardNumberLastFour">
                  <span class="mar-x-xs">••••</span>
                  <span>{{ payment.cardNumberLastFour }}</span>
                </template>
              </h4>
              <payment-transaction-list class="mar-t-sm" :payment="payment" :time-zone="order.store.timeZone" />
            </div>
          </div>
        </b-collapse>
      </section>
    </div>

    <template #footer>
      <div class="buttons all-bold">
        <b-button
          v-if="store.hasPrinters"
          icon-left="print"
          rounded
          :loading="isPrinting"
          @click="handlePrint"
        >
          Print
        </b-button>
        <b-button
          v-if="!supportsOrderRedemption || (order.preparationStatus === 'Completed' && hasBeenRefunded)"
          rounded
          type="is-primary"
          @click="closeModal"
        >
          Close
        </b-button>
        <template v-else>
          <b-button v-if="order.preparationStatus !== 'Completed'" rounded @click="confirmCloseOrder">Close Order</b-button>
          <b-button rounded type="is-primary" :loading="isSaving" @click="toggleItemRedemption">Save</b-button>
        </template>
      </div>
    </template>
  </modal-card>
</template>

<script>
  import Order from '@/store/classes/KdsOrder';
  import merchantMixin from '@/mixins/merchant';
  import confirmModalCloseMixin from '@/mixins/confirm-modal-close';
  import orderDetailsItems from '@/components/globals/order-details-items.vue';
  import orderPriceBreakdown from '@/components/globals/order-price-breakdown.vue';
  import alertModal from '@/components/globals/alert-modal.vue';
  import events from '@/services/events';
  import currency from 'currency.js';
  import fulfillmentTypes from '@/constants/fulfillmentTypes';


  export default {
    name: 'KdsOrderModal',

    components: { orderDetailsItems, orderPriceBreakdown },

    mixins: [confirmModalCloseMixin, merchantMixin],

    props: {
      orderId: {
        type: Number,
        required: true
      },

      store: {
        type: Object,
        required: true
      }
    },

    data() {
      return {
        selectedItemIds: [],
        isFetching: false,
        fulfillmentTypes,
        order: {}
      };
    },

    computed: {
      isSaving() {
        return Order.$state().isSaving;
      },

      isPrinting() {
        return Order.$state().printing;
      },

      supportsOrderRedemption() {
        return this.$_selectedMerchant.merchantOptionsCheckout.showOrderQrCode && this.order.fulfillmentType === fulfillmentTypes.DINE_IN;
      },

      redeemedOrderItems() {
        return this.order.orderItems
          .filter(oi => oi.isRedeemed)
          .sort((a, b) => (a.redemptionDate > b.redemptionDate ? -1 : 1));
      },

      unredeemedOrderItems() {
        return this.order.orderItems.filter(oi => !oi.isRedeemed);
      },

      latestRedemptionDate() {
        return this.order.orderItems
          .map(item => item.redemptionDate)
          .filter(Boolean)
          .sort((a, b) => (a > b ? -1 : 1))[0];
      },

      hasBeenRefunded() {
        return this.order.payments.some(
          payment => payment.transactions.some(
            transaction => (
              transaction.paymentTransaction.paymentTransactionType === 'Refund'
              && transaction.paymentTransaction.paymentTransactionStatus === 'Succeeded'
            )
          )
        );
      }
    },

    created() {
      this.onCreated();
    },

    methods: {
      onCreated() {
        this.setInitialOrder();
        this.fetchOrder();
      },

      setInitialOrder() {
        this.order = Order.find(this.orderId);
        /**
        * due to the way we poll and fetch new orders in the background while
        * this modal is open, we need to keep the data for this order stored
        * locally to this component. This way, when orders are refetched
        * (which don't contain their order items), it does not erase the
        * order items that we fetched for this order being viewed
        */
      },

      modifierDisplay(modifiers) {
        return modifiers.map(m => `${m.itemDescription}${m.quantity && m.quantity > 1 ? ` (${m.quantity})` : ''}`).join(', ');
      },

      async fetchOrder() {
        try {
          this.isFetching = true;
          const order = await Order.fetchOrder(this.orderId);
          this.order = new Order(order);
          this.selectedItemIds = this.redeemedOrderItems.map(oi => oi.orderItemId);
        }
        catch (error) {
          this.$_onRequestError({
            toastOptions: { message: 'There was an issue fetching your order' },
            error
          });
        }
        finally {
          this.isFetching = false;
        }
      },

      closeModal() {
        Order.setSelectedOrderId(null);
      },

      async changePreparationStatus(preparationStatus) {
        try {
          const updatedOrder = await Order.updateOrderPrepStatus(this.order.orderId, {
            storeId: this.order.store.storeId,
            preparationStatus
          });

          this.$_onRequestSuccess({
            toastOptions: { message: 'Order Closed' },
            options: { closeParent: true }
          });
          events.$emit('kds:order-updated', updatedOrder);
        }

        catch (error) {
          this.$_onRequestError({
            toastOptions: { message: 'Failed to update order' },
            error
          });
        }
      },

      confirmCloseOrder() {
        const amountToRefund = this.unredeemedOrderItems
          .reduce((total, orderItem) => (
            currency(total).add(currency(orderItem.priceTotalAfterTax)).value
          ), 0);

        this.$buefy.modal.open({
          parent: this,
          component: alertModal,
          hasModalCard: true,
          trapFocus: true,
          customClass: 'auto-width',
          props: {
            buttons: [
              { text: 'Cancel' },
              {
                text: 'Close Order',
                primary: true,
                onClick: async () => {
                  await this.changePreparationStatus('Completed');
                }
              }
            ],
            horizontal: true,
            showCloseButton: false,
            title: 'Close Order',
            message: `There ${this.unredeemedOrderItems.length > 1 ? 'are' : 'is'} still <b>${this.unredeemedOrderItems.length}</b> unredeemed ${this.unredeemedOrderItems.length > 1 ? 'items' : 'item'} with a total value of <b>$${amountToRefund}</b>`,
            secondaryMessage: 'Are you sure you\'d like to close this order?',
            type: 'is-danger'
          }
        });
      },

      async toggleItemRedemption() {
        const orderItems = this.order.orderItems.map(({ orderItemId }) => ({
          orderItemId,
          isRedeemed: this.selectedItemIds.includes(orderItemId)
        }));

        try {
          await Order.toggleItemRedemption(this.orderId, orderItems);

          events.$emit('kds:order-updated', this.order);

          this.$_onRequestSuccess({
            toastOptions: { message: 'Redeemed items sucessfully updated!' },
            options: { closeParent: true }
          });
        }
        catch (error) {
          this.$_onRequestError({
            error,
            toastOptions: { message: 'There was an issue updating redeemed items' }
          });
        }
      },

      async handlePrint() {
        try {
          await Order.printOrder(this.order.orderId);
          this.$_onRequestSuccess({
            toastOptions: { message: 'Print request sent successfully' }
          });
        }
        catch (error) {
          this.$_onRequestError({
            error,
            toastOptions: { message: 'There was an issue printing your order' }
          });
        }
      }
    }
  };
</script>

<style lang='sass' scoped>
  section
    padding: $size-medium

    &:not(:last-child)
      border-bottom: 1px solid $grey-lighter

</style>
