/* eslint-disable import/no-cycle */
import { Model } from '@vuex-orm/core';
import http from '@/services/http';



export default class DeliveryService extends Model {
  static entity = 'deliveryServices'

  static fields() {
    return {
      id: this.attr(''),
      name: this.attr(''),
      description: this.attr('')
    };
  }

  // STATE //////////////////////
  static state() {
    return {
      fetching: false
    };
  }

  static $state() {
    return this.store().state.entities.deliveryServices;
  }


  // Actions
  static async fetchDeliveryServices() {
    if (this.exists()) return;

    try {
      this.commit((state) => {
        state.fetching = true;
      });

      const { data } = await http.get('delivery_services');

      this.create({ data: data.deliveryServices });
    }
    catch (error) {
      throw error;
    }
    finally {
      this.commit((state) => {
        state.fetching = false;
      });
    }
  }
}
