<template>
  <panel
    title="Send Preview"
    subtitle="Send a preview of your campaign. Previews can only be sent to users with registered accounts. If you do not receive a preview, please confirm your account's notification preferences."
    sticky-header-offset="-20px"
  >
    <!-- VALIDATION ERROR MESSAGE -->
    <transition name="fade-up">
      <b-message
        v-if="hasInvalidDesignForms"
        type="is-danger"
        class="is-compact has-shadow justify-self-start"
      >
        <div class="is-flex align-center gap-sm">
          <b-icon icon="exclamation-circle" />{{ errorMessage }}
        </div>
      </b-message>
    </transition>

    <!-- SEARCH -->
    <div class="is-flex gap">
      <div class="flex-grow">
        <!-- SEARCH INPUT -->
        <b-field
          class="search-inputs mar-none"
          label-position="on-border"
          label="Criteria"
        >
          <b-select v-model="param">
            <option value="name">Name</option>
            <option value="phone">Phone Number</option>
            <option value="email">Email Address</option>
          </b-select>
          <b-input
            v-model="query"
            :icon-right-clickable="!!query"
            :icon-right="query ? 'times-circle' : ''"
            :placeholder="placeholderText"
            :type="queryInputType"
            class="query-input"
            icon="search"
            expanded
            @icon-right-click="clearQuery"
            @keydown.native.esc="clearQuery"
            @keydown.native.enter.prevent="searchRegisteredGuest"
          />
          <div class="control">
            <b-button
              v-tabbable
              :disabled="!query"
              class="has-text-weight-bold flex-small"
              :loading="isLoadingSearchResults"
              @click="searchRegisteredGuest"
            >
              Search
            </b-button>
          </div>
        </b-field>

        <!-- SEARCH RESULTS -->
        <b-dropdown
          ref="dropdown"
          expanded
          scrollable
          :mobile-modal="false"
          max-height="300px"
          aria-role="list"
          class="has-extra-shadow is-block"
          :position="'is-bottom-right'"
        >
          <b-dropdown-item v-if="isEmptySearch" custom>
            No Results Found
          </b-dropdown-item>
          <b-dropdown-item
            v-for="guest in registeredGuests"
            :key="guest.id"
            :focusable="false"
            :value="guest"
            :disabled="!isGuestSelectable(guest)"
            @click="selectGuest(guest)"
          >
            <p class="dropdown-item--text__primary">
              {{ [guest.firstName, guest.lastName].filter(Boolean).join(' ').trim() }}
            </p>
            <p class="dropdown-item--text__secondary search-result-contact-info">
              <span
                v-if="emailDesignActive"
                :class="{ 'has-text-danger': !hasAvailableEmail(guest) }"
              >
                {{ emailDisplay(guest) }}
              </span>
              <span
                v-if="pushDesignActive"
                :class="{ 'has-text-danger': !hasAvailablePush(guest) }"
              >
                {{ pushDisplay(guest) }}
              </span>
              <span
                v-if="smsDesignActive"
                :class="{ 'has-text-danger': !hasAvailablePhone(guest) }"
              >
                {{ phoneDisplay(guest) }}
              </span>
            </p>
          </b-dropdown-item>
        </b-dropdown>
      </div>
      <b-button
        :disabled="![
          emailDesignActive && selectedEmails.length,
          pushDesignActive && selectedPushEmails.length,
          smsDesignActive && selectedPhoneNumbers.length
        ].some(Boolean)"
        icon-right="paper-plane"
        type="is-primary is-light"
        data-test-id="send-preview-button"
        :loading="isSendingPreviews"
        @click="sendPreviews"
      >
        Send Preview
      </b-button>
    </div>

    <!-- SELECTED ACCOUNTS -->
    <b-table
      v-if="selectedGuests.length"
      :data="selectedGuests"
      class="is-middle-aligned has-border has-border-grey-lighter has-radius-large has-shadow mar-t-lg"
      :mobile-cards="false"
    >
      <!-- NAME -->
      <b-table-column>
        <template #header>
          <b-checkbox
            class="is-flex"
            :disabled="!selectedGuests.length || selectedGuests.every(hasNoActiveAvailableContactMethods)"
            :indeterminate="!allPossibleValuesSelected.all && [
              emailDesignActive && selectedEmails.length,
              pushDesignActive && selectedPushEmails.length,
              smsDesignActive && selectedPhoneNumbers.length
            ].some(Boolean)"
            :value="allPossibleValuesSelected.all"
            @input="toggleAll(!allPossibleValuesSelected.all)"
          >
            Name
          </b-checkbox>
        </template>
        <template #default="{ row }">
          <b-checkbox
            class="is-flex"
            :disabled="hasNoActiveAvailableContactMethods(row)"
            :indeterminate="isPartiallySelectedGuest(row)"
            :value="isFullySelectedGuest(row)"
            @input="toggleGuest(row, !isFullySelectedGuest(row))"
          >
            {{ [row.firstName, row.lastName].filter(Boolean).join(' ').trim() }}
          </b-checkbox>
        </template>
      </b-table-column>

      <!-- EMAIL -->
      <b-table-column :visible="emailDesignActive">
        <template #header>
          <b-checkbox
            class="is-flex"
            :disabled="!selectedGuests.some(hasAvailableEmail)"
            :indeterminate="!!selectedEmails.length && !allPossibleValuesSelected.email"
            :value="!!selectedEmails.length && allPossibleValuesSelected.email"
            @input="toggleAllEmail(selectedEmails.length < selectedGuests.filter(hasAvailableEmail).length)"
          >
            Email
          </b-checkbox>
        </template>
        <template #default="{ row }">
          <b-button
            size="is-small"
            rounded
            :class="!hasAvailableEmail(row) && 'no-pointer-events'"
            :disabled="!hasAvailableEmail(row)"
            :type="selectedEmails.includes(row.email) ? 'is-primary is-light' : `${hasAvailableEmail(row) ? 'is-outlined' : 'is-danger is-light'}`"
            @click="toggleEmail(row.email)"
          >
            {{ emailDisplay(row) }}
          </b-button>
        </template>
      </b-table-column>

      <!-- PUSH -->
      <b-table-column :visible="pushDesignActive">
        <template #header>
          <b-checkbox
            class="is-flex"
            :disabled="!selectedGuests.some(hasAvailablePush)"
            :indeterminate="!!selectedPushEmails.length && !allPossibleValuesSelected.push"
            :value="!!selectedPushEmails.length && allPossibleValuesSelected.push"
            @input="toggleAllPush(selectedPushEmails.length < selectedGuests.filter(hasAvailablePush).length)"
          >
            Push
          </b-checkbox>
        </template>
        <template #default="{ row }">
          <b-button
            size="is-small"
            rounded
            :class="!hasAvailablePush(row) && 'no-pointer-events'"
            :disabled="!hasAvailablePush(row)"
            :type="selectedPushEmails.includes(row.email) ? 'is-primary is-light' : `${hasAvailablePush(row) ? 'is-outlined' : 'is-danger is-light'}`"
            @click="togglePush(row.email)"
          >
            {{ pushDisplay(row) }}
          </b-button>
        </template>
      </b-table-column>

      <!-- TEXT -->
      <b-table-column :visible="smsDesignActive">
        <template #header>
          <b-checkbox
            class="is-flex"
            :disabled="!selectedGuests.some(hasAvailablePhone)"
            :indeterminate="!!selectedPhoneNumbers.length && !allPossibleValuesSelected.phone"
            :value="!!selectedPhoneNumbers.length && allPossibleValuesSelected.phone"
            @input="toggleAllPhone(selectedPhoneNumbers.length < selectedGuests.filter(hasAvailablePhone).length)"
          >
            Text
          </b-checkbox>
        </template>
        <template #default="{ row }">
          <b-button
            size="is-small"
            rounded
            :class="!hasAvailablePhone(row) && 'no-pointer-events'"
            :disabled="!hasAvailablePhone(row)"
            :type="selectedPhoneNumbers.includes(row.primarySmsNumber) ? 'is-primary is-light' : `${hasAvailablePhone(row) ? 'is-outlined' : 'is-danger is-light'}`"
            @click="togglePhone(row.primarySmsNumber)"
          >
            {{ phoneDisplay(row) }}
          </b-button>
        </template>
      </b-table-column>

      <!-- REMOVE -->
      <b-table-column v-slot="{ row }" cell-class="actions" width="1">
        <div class="is-flex align-center justify-center">
          <button
            type="button"
            class="delete"
            style="width: 24px; height: 24px;"
            @click="removeGuest(row)"
          />
        </div>
      </b-table-column>
    </b-table>
  </panel>
</template>

<script>
  import Campaign from '@/store/classes/Campaign';
  import RegisteredGuest from '@/store/classes/RegisteredGuest';
  import uploadTempImage from '@/api/temp-image-upload';
  import phoneNumberFilter from '@/filters/phoneNumber';
  import { emailImageTypes } from '@/constants/campaigns';
  import { findLast } from 'lodash';

  export default {
    name: 'CampaignPreviewStep',

    props: {
      designForm: {
        type: Object,
        default: () => ({})
      },
      isActiveStep: {
        type: Boolean,
        default: false
      },
      value: {
        type: Object,
        required: true
      }
    },

    data() {
      return {
        invalidDesignForms: {},
        isEmptySearch: false,
        isLoadingSearchResults: false,
        isSendingPreviews: false,
        param: 'name',
        query: '',
        selectedEmails: [],
        selectedGuests: [],
        selectedPhoneNumbers: [],
        selectedPushEmails: []
      };
    },

    computed: {
      errorMessage() {
        const types = Object.entries(this.invalidDesignForms)
          .reduce((arr, [type, isInvalid]) => (isInvalid ? [...arr, type] : arr), []);

        const messageTypesString = types.reduce((string, type, i, arr) => {
          if (i === 0) return type;
          else if (i === arr.length - 1) return `${string} and ${type}`;
          else return `${string}, ${type}`;
        }, '');

        return `There are missing or invalid fields on your ${messageTypesString} campaign design${types.length > 1 ? 's' : ''}. Please go back to the design step to fix them.`;
      },

      hasInvalidDesignForms() {
        return Object.values(this.invalidDesignForms).some(Boolean);
      },

      registeredGuests() {
        return RegisteredGuest.all();
      },

      placeholderText() {
        let paramName;

        switch (this.param) {
          case 'name':
            paramName = ' by name';
            break;
          case 'email':
            paramName = ' by email address';
            break;
          case 'phone':
            paramName = ' by phone number';
            break;
          default:
            paramName = '';
        }

        return `Search Accounts${paramName}...`;
      },

      queryInputType() {
        switch (this.param) {
          case 'name':
            return 'text';

          case 'email':
            return 'email';

          case 'phone':
            return 'phone';

          default:
            return 'text';
        }
      },

      allPossibleValuesSelected() {
        return !!this.selectedGuests.length && this.selectedGuests.reduce((obj, guest) => {
          if (this.emailDesignActive) obj.email = (obj.email ?? true) && this.hasUnavailableOrSelectedEmail(guest);
          if (this.pushDesignActive) obj.push = (obj.push ?? true) && this.hasUnavailableOrSelectedPush(guest);
          if (this.smsDesignActive) obj.phone = (obj.phone ?? true) && this.hasUnavailableOrSelectedPhone(guest);

          obj.all = Object.values(obj).every(Boolean) && !this.selectedGuests.every(this.hasNoActiveAvailableContactMethods);

          return obj;
        }, {});
      },

      emailDesignActive() {
        return !!this.value.emailTemplates.length;
      },

      pushDesignActive() {
        return !!this.value.pushNotification;
      },

      smsDesignActive() {
        return !!this.value.smsNotification;
      }
    },

    watch: {
      isActiveStep(isActive) {
        if (isActive && this.hasInvalidDesignForms) {
          this.validateSelectedDesignPreviews();
        }
      },
      query() {
        if (this.$refs.dropdown.isActive) {
          this.$refs.dropdown.toggle();
        }
      }
    },

    methods: {
      /* contact method display logic
       * * * * * * * * * * * * * * * */

      emailDisplay(guest) {
        if (guest.email) {
          if (guest.accountNotificationPreference?.email) return guest.email;
          else return 'Email Opt Out';
        }
        else return 'No Email Found';
      },

      pushDisplay(guest) {
        if (guest.hasDeviceId) {
          if (guest.accountNotificationPreference?.push) return 'Device Found';
          else return 'Push Opt Out';
        }
        else return 'No Device Found';
      },

      phoneDisplay(guest) {
        if (guest.primarySmsNumber) {
          if (guest.accountNotificationPreference?.sms) return phoneNumberFilter(guest.primarySmsNumber);
          else return 'Text Opt Out';
        }
        else return 'No Phone Found';
      },

      /* contact method selection states
       * * * * * * * * * * * * * * * * */

      hasAvailableEmail(guest) {
        return !!(guest.email && guest.accountNotificationPreference?.email);
      },

      hasAvailablePush(guest) {
        return !!(guest.hasDeviceId && guest.accountNotificationPreference?.push);
      },

      hasAvailablePhone(guest) {
        return !!(guest.primarySmsNumber && guest.accountNotificationPreference?.sms);
      },

      hasUnavailableOrSelectedEmail(guest) {
        return !this.hasAvailableEmail(guest) || this.selectedEmails.includes(guest.email);
      },

      hasUnavailableOrSelectedPush(guest) {
        return !this.hasAvailablePush(guest) || this.selectedPushEmails.includes(guest.email);
      },

      hasUnavailableOrSelectedPhone(guest) {
        return !this.hasAvailablePhone(guest) || this.selectedPhoneNumbers.includes(guest.primarySmsNumber);
      },

      hasNoActiveAvailableContactMethods(guest) {
        return ![
          this.emailDesignActive && this.hasAvailableEmail(guest),
          this.pushDesignActive && this.hasAvailablePush(guest),
          this.smsDesignActive && this.hasAvailablePhone(guest)
        ].some(Boolean);
      },

      isFullySelectedGuest(guest) {
        /**
         * When checking if all contact methods are selected,
         * don't consider a method unselected if its campaign design type is not active
         */
        return !this.hasNoActiveAvailableContactMethods(guest)
          && (!this.emailDesignActive || this.hasUnavailableOrSelectedEmail(guest))
          && (!this.pushDesignActive || this.hasUnavailableOrSelectedPush(guest))
          && (!this.smsDesignActive || this.hasUnavailableOrSelectedPhone(guest));
      },

      isPartiallySelectedGuest(guest) {
        /**
         * When checking if any contact methods are selected,
         * only consider a method selected if its campaign design type is active
         */
        return !this.isFullySelectedGuest(guest) && [
          this.emailDesignActive && this.selectedEmails.includes(guest.email),
          this.pushDesignActive && this.selectedPushEmails.includes(guest.email),
          this.smsDesignActive && this.selectedPhoneNumbers.includes(guest.primarySmsNumber)
        ].some(Boolean);
      },

      /* contact method selection actions
       * * * * * * * * * * * * * * * * * */

      toggleAll(val) {
        this.selectedGuests.forEach(guest => this.toggleGuest(guest, val));
      },

      toggleGuest(guest, val) {
        if (this.hasAvailableEmail(guest)) this.toggleEmail(guest.email, val);
        if (this.hasAvailablePush(guest)) this.togglePush(guest.email, val);
        if (this.hasAvailablePhone(guest)) this.togglePhone(guest.primarySmsNumber, val);
      },

      toggleAllEmail(val) {
        if (val) {
          this.selectedGuests.forEach((guest) => {
            if (this.hasAvailableEmail(guest)) this.toggleEmail(guest.email, true);
          });
        }
        else this.selectedEmails = [];
      },

      toggleAllPush(val) {
        if (val) {
          this.selectedGuests.forEach((guest) => {
            if (this.hasAvailablePush(guest)) this.togglePush(guest.email, true);
          });
        }
        else this.selectedPushEmails = [];
      },

      toggleAllPhone(val) {
        if (val) {
          this.selectedGuests.forEach((guest) => {
            if (this.hasAvailablePhone(guest)) this.togglePhone(guest.primarySmsNumber, true);
          });
        }
        else this.selectedPhoneNumbers = [];
      },

      toggleEmail(email, val) {
        const emailSet = new Set(this.selectedEmails);

        if (val !== undefined ? val : !emailSet.has(email)) emailSet.add(email);
        else emailSet.delete(email);

        this.selectedEmails = Array.from(emailSet);
      },

      togglePush(email, val) {
        const pushEmailSet = new Set(this.selectedPushEmails);

        if (val !== undefined ? val : !pushEmailSet.has(email)) pushEmailSet.add(email);
        else pushEmailSet.delete(email);

        this.selectedPushEmails = Array.from(pushEmailSet);
      },

      togglePhone(phoneNumber, val) {
        const phoneSet = new Set(this.selectedPhoneNumbers);

        if (val !== undefined ? val : !phoneSet.has(phoneNumber)) phoneSet.add(phoneNumber);
        else phoneSet.delete(phoneNumber);

        this.selectedPhoneNumbers = Array.from(phoneSet);
      },

      /* guest selection actions
       * * * * * * * * * * * * */

      selectGuest(guest) {
        this.selectedGuests = [...this.selectedGuests, guest];
        this.toggleGuest(guest, true);
        this.clearQuery();
      },

      removeGuest(guest) {
        this.selectedGuests = this.selectedGuests.filter(selectedGuest => selectedGuest.id !== guest.id);
        this.toggleGuest(guest, false);
      },

      /* guest search logic
       * * * * * * * * * * */

      async searchRegisteredGuest({ param = this.param, query = this.query } = {}) {
        try {
          this.isEmptySearch = false;
          this.isLoadingSearchResults = true;

          await RegisteredGuest.fetchRegisteredGuests({ param, query });

          this.isEmptySearch = !this.registeredGuests.length;
          this.$refs.dropdown.toggle();
        }
        catch (error) {
          this.$_onRequestError({
            error,
            toastOptions: { message: 'There was an error loading accounts.' }
          });
        }
        finally {
          this.isLoadingSearchResults = false;
        }
      },

      isGuestSelectable(guest) {
        const isNotAlreadySelected = !this.selectedGuests.find(selectedGuest => selectedGuest.id === guest.id);
        const canBeContacted = this.hasAvailableEmail(guest) || this.hasAvailablePush(guest) || this.hasAvailablePhone(guest);

        return isNotAlreadySelected && canBeContacted;
      },

      clearQuery() {
        this.query = '';
        this.isEmptySearch = false;
      },

      /* send preview logic
       * * * * * * * * * * */

      async validateSelectedDesignPreviews() {
        const results = await Promise.all(this.designForm.observers.map(o => o.validate()));
        const { email, push, sms } = this.designForm.observers.reduce((acc, { id }, i) => {
          if (id === 'email-design-form') acc.email = results[i];
          if (id === 'push-design-form') acc.push = results[i];
          if (id === 'sms-design-form') acc.sms = results[i];

          return acc;
        }, { email: true, push: true, sms: true });

        this.invalidDesignForms = {
          Email: this.emailDesignActive && !!this.selectedEmails.length && !email,
          Push: this.pushDesignActive && !!this.selectedPushEmails.length && !push,
          SMS: this.smsDesignActive && !!this.selectedPhoneNumbers.length && !sms
        };

        return !Object.values(this.invalidDesignForms).some(Boolean);
      },

      async sendPreviews() {
        const isValid = await this.validateSelectedDesignPreviews();

        if (!isValid) {
          this.$emit('navigate-to-step', 1);
        }
        else {
          try {
            this.isSendingPreviews = true;

            await Promise.all([
              !!(this.emailDesignActive && this.selectedEmails.length) && this.sendEmailNotificationPreview(this.selectedEmails),
              !!(this.pushDesignActive && this.selectedPushEmails.length) && this.sendPushNotificationPreview(this.selectedPushEmails),
              !!(this.smsDesignActive && this.selectedPhoneNumbers.length) && this.sendSmsNotificationPreview(this.selectedPhoneNumbers)
            ]);

            this.$_onRequestSuccess({
              toastOptions: {
                message: `Preview campaign message${this.selectedEmails.length + this.selectedPushEmails.length + this.selectedPhoneNumbers.length > 1 ? 's' : ''} sent!`
              }
            });
          }
          finally {
            this.isSendingPreviews = false;
          }
        }
      },

      async sendEmailNotificationPreview(emailAddresses) {
        try {
          const emailTemplate = this.value.emailTemplates.length ? this.value.emailTemplates[this.value.emailTemplates.length - 1] : {};
          const emailTemplateUrl = findLast(emailTemplate.campaignEmailTemplateImages, em => !em.kind)?.file?.url;
          const overrideUrl = findLast(emailTemplate.campaignEmailTemplateImages, em => em.kind === emailImageTypes.HEADER_OVERRIDE)?.file?.url;
          const imageUrl = await this.getImagePreviewUrl(
            emailTemplateUrl,
            this.value.newEmailTemplatesImageFile
          );
          const overrideLogoUrl = await this.getImagePreviewUrl(
            overrideUrl,
            this.value.newOverrideImageFile
          );
          await Campaign.sendEmailNotificationPreview({
            emailTemplate: {
              ...emailTemplate,
              contentJson: JSON.stringify({
                ...JSON.parse(emailTemplate.contentJson),
                imageUrl,
                overrideLogoUrl
              })
            },
            emailAddresses,
            offerGuid: this.value.campaignsOffers[0]?.offerGuid
          });
        }
        catch (error) {
          this.$_onRequestError({
            toastOptions: {
              message: `There was an issue sending your preview email${emailAddresses.length > 1 ? 's' : ''}`
            },
            error
          });
        }
      },

      async sendPushNotificationPreview(emailAddresses) {
        try {
          const imageUrl = await this.getImagePreviewUrl(
            this.pushNotification?.image?.file?.url,
            this.value.newPushNotificationImageFile
          );
          await Campaign.sendPushNotificationPreview({
            pushNotification: {
              ...this.value.pushNotification,
              notificationSpecifiedResourceId: this.value.pushNotification.resourceMetadata?.id || null,
              imageUrl
            },
            emailAddresses,
            offerGuid: this.value.campaignsOffers[0]?.offerGuid,
            willIncludeOffer: this.value.willIncludeOffer
          });
        }
        catch (error) {
          this.$_onRequestError({
            toastOptions: {
              message: `There was an issue sending your push notification preview${emailAddresses.length > 1 ? 's' : ''}`
            },
            error
          });
        }
      },

      async sendSmsNotificationPreview(phoneNumbers) {
        try {
          const imageUrl = await this.getImagePreviewUrl(
            this.smsNotification?.image?.file?.url,
            this.value.newSmsNotificationImageFile
          );
          await Campaign.sendSmsNotificationPreview({
            smsNotification: {
              message: this.value.smsNotification.message,
              imageUrl
            },
            phoneNumbers,
            offerGuid: this.value.campaignsOffers[0]?.offerGuid
          });
        }
        catch (error) {
          this.$_onRequestError({
            toastOptions: {
              message: `There was an issue sending your text message notification preview${phoneNumbers.length > 1 ? 's' : ''}`
            },
            error
          });
        }
      },

      getImagePreviewUrl(existingFileUrl, newImageFile) {
        if (existingFileUrl) return existingFileUrl;
        else if (newImageFile) return this.uploadImage(newImageFile);
        else return undefined;
      },

      async uploadImage(imageFile) {
        try {
          const imageUrl = await uploadTempImage({ merchantId: this.value.merchantId, imageFile });
          return imageUrl;
        }
        catch (error) {
          this.$_onRequestError({
            toastOptions: {
              message: 'There was an issue uploading your image'
            },
            error
          });
        }
      }
    }
  };
</script>

<style lang='sass' scoped>
  .search-result-contact-info > span:not(:last-child)
    margin-right: 0.75rem
    padding-right: 0.75rem
    border-right: 1px solid $grey-lighter
</style>
