<template>
  <panel
    title="Loyalty Information"
    collapsible
    start-open
  >
    <template v-if="!loyaltyReward.enrollmentDate">
      <p class="mar-b-lg is-flex align-center has-text-danger">
        This account has not enrolled in loyalty. Please direct the guest to complete enrollment.
      </p>
    </template>
    <template v-if="showAdjustBalanceButton" #buttons>
      <b-button
        type="is-primary"
        rounded
        @click="openPointAdjustmentModal"
      >
        Adjust Balance
      </b-button>
    </template>
    <dl class="is-grid col-min-200 gap-xl">
      <div>
        <dt class="label">Loyalty Status</dt>
        <dd>
          <b-tag v-if="loyaltyReward.loyaltyStatus" :type="'is-success is-light'" class="has-text-weight-bold is-outlined">
            Active
          </b-tag>
          <b-tag v-else :type="'is-danger is-light'" class="has-text-weight-bold is-outlined">
            Inactive
          </b-tag>
        </dd>
      </div>

      <div v-if="loyaltyReward.primaryActiveTier.programTier.publicId">
        <dt class="label">Loyalty Tier</dt>
        <dd>
          <b-tag :type="'is-grey is-light'" class="has-text-weight-bold is-outlined">
            {{ loyaltyReward.primaryActiveTier.programTier.displayName }}
          </b-tag>
        </dd>
      </div>

      <div>
        <dt class="label">Enrollment Date</dt>
        <dd v-if="loyaltyReward.enrollmentDate">
          {{ loyaltyReward.enrollmentDate | moment('MMMM Do, YYYY') }}
        </dd>
        <dd v-else class="has-text-grey-light">N/A</dd>
      </div>

      <div>
        <dt class="label">Member Id</dt>
        <dd v-if="loyaltyReward.memberId" class="is-monospaced has-extra-spacing wrap-text">
          {{ loyaltyReward.memberId }}
        </dd>
        <dd v-else class="has-text-grey-light">N/A</dd>
      </div>

      <template v-if="loyaltyReward.loyaltyNumber">
        <div>
          <dt class="label">Loyalty Number</dt>
          <dd v-if="loyaltyReward.loyaltyNumber" class="is-monospaced has-extra-spacing wrap-text">
            {{ loyaltyReward.loyaltyNumber }}
          </dd>
          <dd v-else class="has-text-grey-light">N/A</dd>
        </div>

        <div>
          <dt class="label">Current Balance</dt>
          <dd v-if="loyaltyReward.currentPointTotal || loyaltyReward.currentPointTotal === 0">
            {{ loyaltyReward.currentPointTotal | formatDecimal }}
          </dd>
          <dd v-else class="has-text-grey-light">N/A</dd>
        </div>

        <!-- <div>
          <dt class="label">Points to Next Reward</dt>
          <dd v-if="loyaltyReward.pointsToNextReward || loyaltyReward.pointsToNextReward === 0">
            {{ loyaltyReward.pointsToNextReward }}
          </dd>
          <dd v-else class="has-text-grey-light">N/A</dd>
        </div> -->

        <!-- <div>
          <dt class="label">Available Rewards</dt>
          <dd v-if="loyaltyReward.availableRewards || loyaltyReward.availableRewards === 0">
            {{ loyaltyReward.availableRewards }}
          </dd>
          <dd v-else class="has-text-grey-light">N/A</dd>
        </div> -->
      </template>
    </dl>
  </panel>
</template>

<script>
  import pointAdjustmentModal from '../modals/point-adjustment-modal.vue';

  export default {
    name: 'RegisteredGuestLoyaltyInfo',

    props: {
      loyaltyReward: {
        type: Object,
        required: true
      },
      cfLoyaltyEnabled: {
        type: Boolean,
        required: true
      }
    },

    computed: {
      showAdjustBalanceButton() {
        return this.$can('update', 'Account') && this.cfLoyaltyEnabled;
      }
    },

    methods: {
      openPointAdjustmentModal() {
        const { currentPointTotal, currencyId, memberId, currency } = this.loyaltyReward;
        const pointAdjustment = {
          currentPointTotal,
          currencyId,
          memberId,
          currencyName: currency
        };

        this.$buefy.modal.open({
          parent: this,
          component: pointAdjustmentModal,
          hasModalCard: true,
          trapFocus: true,
          canCancel: ['escape', 'outside'],
          customClass: 'auto-width',
          props: { pointAdjustment }
        });
      }
    }
  };
</script>
