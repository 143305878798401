<template>
  <div class="survey-content">
    <empty-state-card
      v-if="!questions.length"
      type="is-primary"
      title="Create Survey"
      message="Create your very own survey for customer feedback"
      image-path="/images/survey.svg"
      :image-width="142"
    >
      <template #buttons>
        <b-dropdown :triggers="['click']" aria-role="list" position="is-bottom-right">
          <b-button slot="trigger" rounded icon-left="plus" type="is-primary">
            Question
          </b-button>

          <b-dropdown-item
            v-for="questionType in questionTypes"
            :key="questionType"
            aria-role="listitem"
            @click="handleAddQuestion(questionType)"
          >
            <b-icon :icon="questionTypeIcons[questionType]" />
            {{ capitalCase(questionType) }}
          </b-dropdown-item>
        </b-dropdown>
      </template>
    </empty-state-card>

    <template v-else>
      <div class="survey-options">
        <div class="card">
          <div class="pad-x-md pad-y-sm">
            <span class="subtitle">
              Survey Options
            </span>
          </div>
          <div class="pad-x-md pad-b-md">
            <b-switch :value="form.randomQuestionsEnabled" @input="handleFormChange({ randomQuestionsEnabled: $event })">
              <div>
                <p>Randomization</p>
                <p class="is-size-7 has-text-grey">Show a random subset of survey questions to each guest</p>
              </div>
            </b-switch>
            <validation-provider
              v-if="form.randomQuestionsEnabled"
              v-slot="{ errors, classes }"
              name="numberOfRandomQuestions"
              vid="numberOfRandomQuestions"
              slim
              :custom-messages="{
                required: 'You must have at least one randomized question',
                min_value: 'You must have at least one randomized question',
                max_value: `There ${availableRandomQuestionCount === 1 ? 'is' : 'are'} only ${availableRandomQuestionCount} question${availableRandomQuestionCount === 1 ? '' : 's'} available to be randomized`
              }"
              :rules="{
                required: true,
                min_value: 1,
                max_value: availableRandomQuestionCount,
              }"
            >
              <p class="is-flex align-center mar-t">
                Show
                <b-numberinput
                  :value="form.numberOfRandomQuestions"
                  controls-position="compact"
                  :class="['mar-x-sm', ...classes]"
                  :min="1"
                  :max="activeQuestions.length - alwaysAskQuestionCount"
                  @input="handleFormChange({ numberOfRandomQuestions: $event })"
                />
                random questions on each survey in addition to the "Always Ask" quetions
              </p>
              <b-message
                v-if="errors.length"
                type="is-danger"
                size="is-small"
                class="is-compact has-shadow is-inline-block mar-t-sm"
                has-icon
              >
                <p v-for="error in errors" :key="error">{{ error }}</p>
              </b-message>
            </validation-provider>
          </div>
        </div>
      </div>

      <div class="dist-y-md">
        <panel
          :title="`Active Questions (${activeQuestions.length})`"
          start-open
          sticky-header-offset="-1.5rem"
        >
          <draggable
            tag="ul"
            class="is-flex-column gap"
            group="questions"
            v-bind="draggableAttributes"
            :value="activeQuestions"
            :force-fallback="true"
            @change="handleQuestionSortOrderChange"
          >
            <survey-question-component
              v-for="question in activeQuestions"
              :key="question.id"
              :question="question"
              :is-draft="form.isDraft"
              :random-questions-enabled="form.randomQuestionsEnabled"
              :mode="mode"
              @remove="handleRemoveQuestion"
              @update="handleQuestionChange"
            />
          </draggable>
        </panel>
        <panel
          v-if="inactiveQuestions.length"
          :title="`Inactive Questions (${inactiveQuestions.length})`"
          collapsible
          hide-hover-controls
          sticky-header-offset="-1.5rem"
        >
          <ul class="dist-y">
            <survey-question-component
              v-for="question in inactiveQuestions"
              :key="question.id"
              :question="question"
              :is-draft="form.isDraft"
              :mode="mode"
              @remove="handleRemoveQuestion"
              @update="handleQuestionChange"
            />
          </ul>
        </panel>
      </div>
    </template>

    <transition name="fade-up">
      <b-dropdown
        v-if="questions.length"
        class="new-question-button"
        :triggers="['click']"
        aria-role="list"
        position="is-top-left"
      >
        <b-button
          slot="trigger"
          type="is-primary"
          icon-left="plus"
          size="is-medium"
          rounded
        >
          Question
        </b-button>

        <b-dropdown-item
          v-for="questionType in questionTypes"
          :key="questionType"
          aria-role="listitem"
          @click="handleAddQuestion(questionType)"
        >
          <b-icon :icon="questionTypeIcons[questionType]" />
          {{ capitalCase(questionType) }}
        </b-dropdown-item>
      </b-dropdown>
    </transition>
  </div>
</template>

<script>
  import { questionTypes, questionAnswerMap } from '@/constants/surveyQuestionTypes';
  import SurveyQuestion from '@/store/classes/SurveyQuestion';
  import SurveyQuestionComponent from './survey-question.vue';
  import draggable from 'vuedraggable';
  import capitalCase from '@/helpers/capitalCase';

  export default {
    name: 'SurveyContentInputs',

    components: { draggable, SurveyQuestionComponent },

    props: {
      handleFormChange: {
        type: Function,
        required: true
      },

      form: {
        type: Object,
        required: true
      },

      mode: {
        type: String,
        default: 'create',
        validator(value) {
          return ['create', 'read', 'update'].includes(value);
        }
      }
    },

    data() {
      return {
        questions: [],
        questionTypes,
        capitalCase,
        draggableAttributes: {
          animation: '200',
          ghostClass: 'ghost',
          handle: '.drag-handle'
        },
        isInactiveQuestionsOpen: false,
        showNewQuestionModal: false,
        questionTypeIcons: {
          [questionTypes.RADIO_LIST]: 'check-circle',
          [questionTypes.TEXTAREA]: 'paragraph',
          [questionTypes.INPUT]: 'font',
          [questionTypes.STAR_RATING]: 'star',
          [questionTypes.MULTIPLE_SELECT]: 'list-alt'
        }
      };
    },

    computed: {
      activeQuestions() {
        return this.$clone(this.questions).filter(q => q.isActive);
      },

      inactiveQuestions() {
        return this.$clone(this.questions).filter(q => !q.isActive);
      },

      alwaysAskQuestionCount() {
        return this.$clone(this.activeQuestions).filter(q => q.alwaysInclude).length;
      },

      availableRandomQuestionCount() {
        return this.activeQuestions.length - this.alwaysAskQuestionCount;
      }
    },

    watch: {
      'form.questions': 'sortAndSetQuestions'
    },

    created() {
      this.onCreated();
    },

    methods: {
      onCreated() {
        this.sortAndSetQuestions(this.form.questions);
      },

      sortAndSetQuestions(questions) {
        this.questions = this.sortQuestions(questions);
      },

      sortQuestions(questions) {
        return this.$clone(questions).sort((a, b) => ((a.sortOrder < b.sortOrder) ? -1 : 1));
      },

      focusOnNewestLabel() {
        this.$nextTick(() => {
          const allLabels = this.$el.querySelectorAll('.question-input input');
          const newestLabel = allLabels[allLabels.length - 1];
          newestLabel.focus();
        });
      },

      handleAddQuestion(questionType) {
        const newQuestion = new SurveyQuestion({
          questionType,
          answerType: questionAnswerMap[questionType],
          sortOrder: this.activeQuestions.length + 1,
          isUnsaved: true
        });

        const questionsCopy = [...this.questions];

        questionsCopy.splice(this.activeQuestions.length, 0, newQuestion);

        const sortedNewQuestions = questionsCopy.map((q, i) => ({
          ...q,
          sortOrder: i + 1
        }));

        this.questions = sortedNewQuestions;

        this.focusOnNewestLabel();
      },

      handleRemoveQuestion(questionId) {
        const updatedQuestions = this.questions
          .filter(question => question.id !== questionId)
          .map((question, index) => {
            question.sortOrder = index + 1;
            return question;
          });

        this.questions = updatedQuestions;
        this.handleFormChange({ questions: updatedQuestions });
      },

      reorderQuestionsAfterActiveChange({ questions, updatedQuestion, updatedIndex }) {
        const questionsCopy = JSON.parse(JSON.stringify(questions));

        questionsCopy.splice(updatedIndex, 1);

        if (!updatedQuestion.isActive) {
          questionsCopy.push(updatedQuestion);
        }
        else {
          questionsCopy.splice(this.activeQuestions.length, 0, updatedQuestion);
        }

        questionsCopy.forEach((q, index) => {
          q.sortOrder = index + 1;
        });
        return questionsCopy;
      },

      handleQuestionChange(updatedQuestion) {
        const updatedQuestions = this.questions.map((question) => {
          if (question.id === updatedQuestion.id) {
            return updatedQuestion;
          }
          return question;
        });

        const updatedIndex = updatedQuestions.findIndex(q => q.id === updatedQuestion.id);
        const hasChangedActive = updatedQuestion.isActive !== this.questions[updatedIndex].isActive;
        const fullyUpdatedQuestions = hasChangedActive
          ? this.reorderQuestionsAfterActiveChange({ questions: [...updatedQuestions], updatedQuestion, updatedIndex })
          : updatedQuestions;

        this.questions = fullyUpdatedQuestions;

        this.handleFormChange({ questions: fullyUpdatedQuestions });
      },

      handleQuestionSortOrderChange({ moved }) {
        const { newIndex, oldIndex } = moved;
        const questionsCopy = [...this.questions];
        const question = questionsCopy[oldIndex];
        questionsCopy.splice(oldIndex, 1);
        questionsCopy.splice(newIndex, 0, question);

        const sortedQuestions = questionsCopy.map((q, i) => ({
          ...q,
          sortOrder: i + 1
        }));

        this.questions = sortedQuestions;
        this.handleFormChange({ questions: sortedQuestions });
      }
    }
  };
</script>

<style lang="sass" scoped>
  .new-question-button
    z-index: 100
    position: fixed
    bottom: 1.75rem
    right: 1.75rem

    .button
      box-shadow: $floating-button-shadow

    ::v-deep .dropdown-content
      margin-bottom: 0.5rem

  ::v-deep .field:not(:last-child)
    margin-bottom: 0 !important

  ::v-deep.is-horizontal > .field-label .label
    min-width: 0 !important

  .survey-options
    border-bottom: 2px solid $grey-lighter
    padding-bottom: 2rem
    margin-bottom: 2rem

  @media (max-width: $fullhd)
    .survey-content
      padding-bottom: 5rem
</style>
