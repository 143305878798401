import http from '@/services/http';

export async function fetchSimplifiedMenu(merchantId) {
  try {
    const response = await http.get(`merchants/${merchantId}/menu_categories/simplified`);
    return response.data.menuCategories;
  }
  catch (error) {
    throw error;
  }
}

export async function searchMenu({
  merchantId,
  searchQuery,
  searchType,
  menuTypeIds,
  forStore = false
}) {
  try {
    const queries = [
      `term=${encodeURIComponent(searchQuery)}`,
      `type=${encodeURIComponent(searchType)}`,
      `for_store=${forStore}`,
      menuTypeIds.length && menuTypeIds.map(id => `menuTypeIds[]=${id}`).join('&')
    ].filter(x => x).join('&');

    const response = await http.get(`merchants/${merchantId}/menus/search?${queries}`);

    return response.data.menuCategories;
  }
  catch (error) {
    throw error;
  }
}

export async function searchMenuV2({
  merchantId,
  searchQuery,
  searchType,
  searchResource,
  menuTypeIds,
  forStore = false
}) {
  try {
    const availableResourceTypes = {
      items: 'MenuItem',
      modifiers: 'MenuItemModifier',
      modifierGroups: 'MenuItemModifierGroup',
      categories: 'MenuCategory'
    };

    const modifiersIncludeParams = [
      'menu_item_modifier_group',
      'store_menu_item_modifiers',
      'store_menu_item_modifiers.store_menu_item_modifier_price_overrides'
    ];

    const queries = [
      `term=${encodeURIComponent(searchQuery)}`,
      `type=${encodeURIComponent(searchType)}`,
      `for_store=${forStore}`,
      `resourceType=${availableResourceTypes[searchResource]}`,
      searchResource === 'modifiers' && `include=${modifiersIncludeParams.join(',')}`,
      menuTypeIds.length && menuTypeIds.map(id => `menuTypeIds[]=${id}`).join('&')
    ].filter(x => x).join('&');
    const response = await http.get(`merchants/${merchantId}/menus/search_v2?${queries}`);

    return response.data.results;
  }
  catch (error) {
    throw error;
  }
}

export async function searchMenuByResource({
  merchantId,
  resourceType,
  searchQuery,
  searchType
}) {
  try {
    const availableTypes = {
      menuItems: 'menu_items',
      menuItemModifiers: 'menu_item_modifiers',
      menuItemModifierGroups: 'menu_item_modifier_groups',
      menuCategories: 'menu_categories'
    };

    const availableSearchTypes = {
      name: 'display_name',
      pos: 'pos'
    };

    const response = await http.get(`/merchants/${merchantId}/${availableTypes[resourceType]}?search_parameter=${availableSearchTypes[searchType]}&search_term=${searchQuery}`);
    return response.data[resourceType];
  }
  catch (error) {
    throw error;
  }
}

export async function refreshPrices({ merchantId, storeId }) {
  try {
    await http.put(`merchants/${merchantId}/prices/refresh`, { storeId });
  }
  catch (error) {
    throw error;
  }
}


export default {
  fetchSimplifiedMenu,
  searchMenu,
  searchMenuV2,
  searchMenuByResource,
  refreshPrices
};
