import { Model } from '@vuex-orm/core';
import http from '@/services/http';



export default class CampaignsOffer extends Model {
  static entity = 'campaignsOffers'


  // FIELDS //////////////////////
  static fields() {
    return {
      id: this.attr(''),
      offerGuid: this.attr(''),
      campaignGuid: this.attr('')
    };
  }



  // STATE //////////////////////
  static state() {
    return {
      submitting: false
    };
  }

  static $state() {
    return this.store().state.entities.campaignsOffers;
  }


  // ACTIONS //////////////////////
  static async addOfferToCampaign(campaignsOffer) {
    try {
      this.commit((state) => {
        state.submitting = true;
      });

      const { data } = await http.post('campaigns_offers', { campaignsOffer });

      this.insert({
        data: data.campaignsOffer
      });
    }

    catch (error) {
      throw error;
    }

    finally {
      this.commit((state) => {
        state.submitting = false;
      });
    }
  }

  static async removeOfferFromCampaign(offerId) {
    try {
      this.commit((state) => {
        state.submitting = true;
      });

      await http.delete(`campaigns_offers/${offerId}`);

      this.delete(offerId);
    }

    catch (error) {
      throw error;
    }

    finally {
      this.commit((state) => {
        state.submitting = false;
      });
    }
  }
}
