<template>
  <div class="main-menu">
    <empty-state-card
      v-if="!categories.length && !searchQuery"
      type="is-primary"
      title="Create Category"
      message="To start your menu we need to create a category that we can add items to."
      image-path="/icons/new-category.svg"
    >
      <template #buttons>
        <b-button
          type="is-dark"
          rounded
          :disabled="!$can('create', 'MenuCategory') && !$_menuPermissions.ADD_RESOURCE"
          @click="openCategoryModal"
        >
          Create Category
        </b-button>
      </template>
    </empty-state-card>

    <div v-else class="card">
      <div class="is-flex justify-between pad mar-b-sm">
        <b-field
          label="Search By"
          label-position="on-border"
          class="search-field is-marginless"
        >
          <template #message>
            <p v-if="searchQuery.length && searchQuery.length < 3">
              Enter at least 3 characters
            </p>
            <p v-else-if="searchResults">
              Found <b>{{ searchResults.length }}</b> results
            </p>
          </template>
          <b-select v-model="searchResource">
            <option value="categories">Categories</option>
            <option value="items">Items</option>
            <option value="modifierGroups">Modifier Groups</option>
            <option value="modifiers">Modifiers</option>
          </b-select>
          <b-select v-model="searchType">
            <option value="name">Name</option>
            <option value="pos">PLU#</option>
          </b-select>
          <b-input
            v-model="searchQuery"
            expanded
            placeholder="Search"
            :icon-right="searchQuery ? 'times-circle' : ''"
            icon-right-clickable
            icon="search"
            class="mar-r-xl"
            :loading="isLoadingSearchResults"
            @icon-right-click="searchQuery = ''"
          />
        </b-field>
        <div class="is-flex dist-x-sm">
          <div v-tippy="{ content: 'Collapse All' }">
            <b-button :disabled="!isAnyMenuSectionOpen" @click="collapseAllResources">
              <b-icon icon="compress-alt" size="is-medium" />
            </b-button>
          </div>
          <template v-if="$can('update', 'MenuItem') && $_menuPermissions.MANAGE_MENU_TYPE">
            <b-field v-if="!menuType">
              <div v-if="!!missingMenuTypes.length" class="control">
                <tippy placement="left">
                  <template #trigger>
                    <b-button disabled type="is-warning" class="is-read-only">
                      <b-icon v-if="missingMenuTypes.length" icon="exclamation-triangle" type="is-dark" />
                    </b-button>
                  </template>

                  <div class="pad-xs has-text-left" style="width: 300px; white-space: normal">
                    <p class="is-size-6 has-text-weight-bold" style="line-height: 1.3">The following Order Modes are missing an associated Menu Type:</p>
                    <ul class="mar-y-sm">
                      <li
                        v-for="missingMenuType in missingMenuTypes"
                        :key="missingMenuType.menuTypeId"
                        class="is-size-6"
                      >
                        <b-icon icon="caret-right" size="is-small" />
                        {{ missingMenuType.displayName }}
                      </li>
                    </ul>
                    <p class="has-text-grey-light">Note: Locations will display as unavailable for these Order Modes</p>
                  </div>
                </tippy>
              </div>

              <div class="control">
                <b-button
                  v-if="!menuType"
                  type="is-primary"
                  icon-left="plus"
                  @click="openSelectMenuTypeModal"
                >
                  Menu Type
                </b-button>
              </div>
            </b-field>
            <template v-else>
              <b-button
                type="is-primary is-light"
                icon-left="calendar"
                icon-pack="far"
                @click="openMenuDayPartSchedule(menuType)"
              >
                Availability
              </b-button>
              <b-button
                class="is-right"
                type="is-primary"
                icon-left="plus"
                @click="openAddToMenuTypeModal(menuType, 'update')"
              >
                Categories & Items
              </b-button>
            </template>
          </template>
        </div>
      </div>
      <empty-table-loader
        v-if="isLoadingSearchResults || (searchResults && !searchResults.length)"
        :loading="isLoadingSearchResults"
        :has-icon="false"
        class="has-border-top has-border-grey-lightest"
        message="No Results Found"
      />
      <div v-else class="nested-table has-border-top has-border-grey-lightest">
        <b-loading :is-full-page="false" :active="fetchingCategories || sortingCategories || isLoadingItemCloned || isDeletingMenuResource || isRemovingFromMenuType" />
        <div class="table-heading">
          <div class="row">
            <span>Name</span>
            <span class="align-center justify-end">
              <template v-if="!menuType && !searchResults">Default</template>
            </span>
            <span class="align-center justify-end" :style="{ paddingLeft: '37px' }">
              <template v-if="!menuType || (menuType && $can('update', 'MenuCategory'))">Actions</template>
            </span>
          </div>
        </div>

        <!-- MENU SEARCH V2 - SHARED MOD GROUPS -->
        <main-menu-search-v2
          v-if="searchResults && searchResults.length"
          :search-results="searchResults"
          :resource-type="searchResource"
          :menu-type="menuType"
          @re-search-menu="reSearchMenu"
        />

        <categories-container
          v-if="!searchResults"
          :menu-type="menuType"
        />
      </div>
    </div>
  </div>
</template>

<script>
  import debounce from 'lodash.debounce';
  import { mapActions, mapGetters, mapState } from 'vuex';

  import merchantMixin from '@/mixins/merchant';
  import featurePermissionsMixin from '@/mixins/featurePermissions';
  import { searchMenuV2 } from '@/api/menu';
  import mainMenuSearchV2 from '../menu-search-v2/main-menu-search-v2.vue';

  // components
  import CategoriesContainer from '../menu-components/categories-container.vue';

  // modals
  import SelectMenuTypeModal from './select-menu-type-modal.vue';
  import AddToMenuTypeModal from './add-to-menu-type-modal.vue';
  import MenuDayPartScheduleModal from './menu-daypart-schedule-modal.vue';

  // classes
  import Category from '@/store/classes/Category';
  import Item from '@/store/classes/Item';
  import Modifier from '@/store/classes/Modifier';
  import ModifierGroup from '@/store/classes/ModifierGroup';
  import Store from '@/store/classes/Store';


  export default {
    name: 'MainMenuV2',


    components: { mainMenuSearchV2, CategoriesContainer },


    mixins: [merchantMixin, featurePermissionsMixin],


    metaInfo() {
      return {
        title: 'Main Menu'
      };
    },


    props: {
      menuType: {
        type: Object,
        default: null
      }
    },


    data() {
      return {
        isLoading: false,
        isLoadingItemCloned: false,
        isLoadingSearchResults: false,
        searchQuery: (this.$route.query?.searchQuery && decodeURIComponent(this.$route.query.searchQuery)) || '',
        oldSearchQuery: '',
        searchResults: null,
        searchType: (this.$route.query?.searchType && decodeURIComponent(this.$route.query.searchType)) || 'name',
        validSearchLength: 3,
        searchResource: (this.$route.query?.searchResource && decodeURIComponent(this.$route.query.searchResource)) || 'categories',
        resourceTypes: ['categories', 'items', 'modifierGroups', 'modifiers']
      };
    },

    computed: {
      ...mapState('mainMenu', [
        'isRemovingFromMenuType',
        'categoriesOpenedStatus',
        'menuItemsOpenedStatus',
        'modifierGroupsOpenedStatus',
        'modifiersOpenedStatus'
      ]),
      ...mapGetters('mainMenu', ['categories']),

      storeCount() {
        return Store.query().count();
      },

      supportedStoreMenuTypeIds() {
        const allStores = Store.all();
        const allSupportedMenuTypeIds = allStores
          .flatMap(store => store.supportedMenuTypes)
          .map(menuType => menuType.id);

        return [...new Set(allSupportedMenuTypeIds)];
      },

      missingMenuTypes() {
        return this.supportedStoreMenuTypeIds
          .filter(id => !this.existingMenuTypeIds.includes(id))
          .map(menuTypeId => this.$_selectedMerchant.supportedMenuTypes.find(mt => mt.id === menuTypeId));
      },

      isAnyMenuSectionOpen() {
        return !![
          ...Object.values(this.categoriesOpenedStatus),
          ...Object.values(this.menuItemsOpenedStatus),
          ...Object.values(this.modifierGroupsOpenedStatus),
          ...Object.values(this.modifiersOpenedStatus)
        ].filter(Boolean).length;
      },

      fetchingCategories() {
        return Category.$state().fetching;
      },

      sortingCategories() {
        return Category.$state().sorting;
      },

      isDeletingMenuResource() {
        return Category.$state().deleting
          || Item.$state().deleting
          || ModifierGroup.$state().deleting
          || Modifier.$state().deleting;
      },

      existingMenuTypeIds() {
        return [...new Set(this.categories.flatMap(c => c.menuTypes.map(mt => mt.id)))];
      },

      hasAllMenuTypes() {
        return this.$_selectedMerchant.supportedMenuTypes.every(menuType => this.existingMenuTypeIds.includes(menuType.id));
      },

      hasSharedModGroups() {
        return this.$_featurePermissions?.SHARED_MODIFIER_GROUPS;
      }
    },

    watch: {
      searchQuery: 'onSearchQueryChange',
      searchType: 'reSearchMenu',
      searchResource: 'handleResourceTypeChange'
    },

    async created() {
      await this.onCreated();
    },

    destroyed() {
      this.collapseAllResources();
    },

    methods: {
      ...mapActions('mainMenu', ['setActiveMenuType', 'setMixins', 'setClonedItemIds', 'collapseAllResources']),

      async onCreated() {
        // This is not ideal, but is seemingly the best solution for the issue. If we were
        // to instead import the mixins at each subcomponent level, the load time increases
        // significantly, as each component creates a fresh instance of the mixin. This is
        // a workaround to ensure that the mixins are only loaded once, and are available
        // to all subcomponents throughout the Vuex store.
        this.setMixins({
          selectedMerchant: this.$_selectedMerchant,
          featurePermissions: this.$_featurePermissions,
          menuPermissions: this.$_menuPermissions
        });

        const { searchQuery, searchType, searchResource } = this.$route.query;
        const hasSearchHistory = searchQuery && searchType && searchResource;
        if (hasSearchHistory) {
          await this.searchMenuV2({
            searchQuery: decodeURIComponent(searchQuery),
            searchType: decodeURIComponent(searchType),
            searchResource: decodeURIComponent(searchResource)
          });
        }

        this.setActiveMenuType(this.menuType);
      },

      clearSearch() {
        this.collapseAllResources();
        this.searchResults = null;
        this.oldSearchQuery = '';
        this.$router.replace({ query: null });
      },

      onSearchQueryChange(newQuery, oldQuery = '') {
        this.isLoadingSearchResults = true;

        // when backspacing characters in the search input
        // to the point where we revert back to the full menu
        const isGoingFromValidToInvalidSearch = oldQuery.length > newQuery.length && newQuery.length === this.validSearchLength - 1;

        // when search input is cleared after a successful search
        // (prevents collapsing opened resources before a search is made)
        const isSearchCleared = !newQuery.length && this.searchResults;

        if (isGoingFromValidToInvalidSearch || isSearchCleared) {
          this.clearSearch();
        }
        this.debouncedSearchMenu(newQuery);
      },

      // eslint-disable-next-line
      debouncedSearchMenu: debounce(async function (newQuery) {
        if (newQuery.length >= this.validSearchLength) {
          await this.searchMenuV2({
            searchQuery: newQuery,
            searchType: this.searchType,
            searchResource: this.searchResource
          });
        }
        else {
          // Clears loading state from onSearchQueryChange in the event a user is clearing the search out
          this.isLoadingSearchResults = false;
        }
      }, 888),

      openSelectMenuTypeModal() {
        if (this.hasAllMenuTypes) {
          this.$buefy.dialog.alert({
            title: 'All Menu Types In Use',
            message: `
              You already have existing menus for all available menu types.
              Please make edits to your existing menus or <a class="link" href="mailto:support@cardfree.com?subject=Menu Type Help">contact support</a> for assistance.
            `,
            confirmText: 'OK',
            trapFocus: true,
            type: 'is-primary'
          });
        }
        else {
          this.$buefy.modal.open({
            parent: this,
            component: SelectMenuTypeModal,
            hasModalCard: true,
            trapFocus: true,
            canCancel: false,
            customClass: 'auto-width',
            props: {
              existingMenuTypeIds: this.existingMenuTypeIds,
              missingMenuTypeIds: this.missingMenuTypes.map(mt => mt.id)
            },
            events: {
              'menu-type-selected': this.openAddToMenuTypeModal
            }
          });
        }
      },

      openAddToMenuTypeModal(menuType, mode) {
        this.$buefy.modal.open({
          parent: this,
          component: AddToMenuTypeModal,
          hasModalCard: true,
          trapFocus: true,
          canCancel: false,
          customClass: 'auto-width',
          props: { menuType, mode }
        });
      },

      openMenuDayPartSchedule(menuType) {
        this.$buefy.modal.open({
          parent: this,
          component: MenuDayPartScheduleModal,
          hasModalCard: true,
          trapFocus: true,
          canCancel: false,
          customClass: 'auto-width',
          props: { menuType }
        });
      },

      async searchMenuV2({ searchQuery, searchType, searchResource }) {
        try {
          this.isLoadingSearchResults = true;
          const results = await searchMenuV2({
            merchantId: this.$_selectedMerchantId,
            searchQuery,
            searchType,
            searchResource,
            menuTypeIds: this.menuType ? [this.menuType.id] : []
          });
          this.oldSearchQuery = searchQuery;

          this.updateRoute({
            newCriteria: searchType,
            newQuery: searchQuery,
            newSearchResource: searchResource
          });

          this.searchResults = results;
        }

        catch (error) {
          this.$_onRequestError({
            toastOptions: {
              message: 'There was an issue with your search'
            },
            error
          });
        }

        finally {
          this.isLoadingSearchResults = false;
        }
      },

      updateRoute({ newCriteria, newQuery, newSearchResource }) {
        const { searchQuery, searchType, searchResource } = this.$route.query;
        const hasSearchChanged = searchType !== newCriteria || searchQuery !== newQuery || searchResource !== newSearchResource;
        if (hasSearchChanged) {
          this.$router.replace({
            query: {
              searchType: encodeURIComponent(newCriteria),
              searchQuery: encodeURIComponent(newQuery),
              searchResource: encodeURIComponent(newSearchResource)
            }
          });
        }
      },

      handleResourceTypeChange() {
        if (this.searchQuery.length >= this.validSearchLength && this.searchResults === null) {
          this.searchResults = [];
        }
        this.reSearchMenu();
      },

      async reSearchMenu() {
        if (this.searchQuery.length >= this.validSearchLength) {
          const { searchQuery, searchType, searchResource } = this;
          await this.searchMenuV2({ searchQuery, searchType, searchResource });
        }
      }
    }
  };
</script>

<style lang="sass" scoped>
  .search-field
    flex: 1
    ::v-deep .help
      position: absolute

  .is-cloned-item
    animation-name: highlight-pulse
    animation-duration: 1s
    animation-iteration-count: 3

  .row
    grid-template-columns: 1fr 200px 170px

  @keyframes highlight-pulse
    50%
      background-color: $primary-lighter
</style>
