<template>
  <transition-group name="fade-down-slow" class="dist-y-sm">
    <criteria-card
      v-if="criteriaIsActiveFor(criteriaCardMetadata.ENROLLED_IN_LOYALTY.criteriaKeys)"
      :key="`${criteriaCardMetadata.ENROLLED_IN_LOYALTY.defaultCriteriaKey}-criteria-card`"
      :criteria="criteriaCardMetadata.ENROLLED_IN_LOYALTY"
      @remove-criteria="$emit('remove-criteria', $event)"
    >
      <div class="is-flex align-center gap-sm">
        Guest is enrolled in loyalty
        <div>
          <radio-button
            v-model="enrolledInLoyalty"
            name="enrolledInLoyalty"
            :native-value="true"
          >
            Yes
          </radio-button>
          <radio-button
            v-model="enrolledInLoyalty"
            name="enrolledInLoyalty"
            :native-value="false"
          >
            No
          </radio-button>
        </div>
      </div>
    </criteria-card>

    <criteria-card
      v-if="criteriaIsActiveFor(criteriaCardMetadata.OFFERS_REDEEMED.criteriaKeys)"
      :key="`${criteriaCardMetadata.OFFERS_REDEEMED.defaultCriteriaKey}-criteria-card`"
      :criteria="criteriaCardMetadata.OFFERS_REDEEMED"
      @remove-criteria="$emit('remove-criteria', $event)"
    >
      <div>
        <div class="is-flex align-center gap-sm mar-b-lg">
          Guest redeemed
          <validated-input
            :rules="{ required: true }"
            hide-label
            name="offersRedeemedOperator"
            data-test-id="offers-redeemed-operator"
            label="Operator"
            class="mar-none"
          >
            <b-select
              v-model="offersRedeemedOperator"
              placeholder="Select an operator..."
              class="mar-none"
            >
              <option :value="null" disabled>-</option>
              <option v-for="operator in Object.values(equalityOperators)" :key="operator" :value="operator">
                {{ capitalCase(operator) }}
              </option>
            </b-select>
          </validated-input>
          <template v-if="offersRedeemedOperator !== equalityOperators.BETWEEN">
            <validated-text-input
              :value="offersRedeemed.equality.amount"
              type="number"
              name="offersRedeemedAmount"
              data-test-id="offers-redeemed-amount"
              :rules="{
                min_value: 0,
                required: true
              }"
              label="Amount"
              placeholder="Amount"
              hide-label
              class="mar-none"
              @input="handleEqualityChange({ val: $event, type: 'amount', criteriaKey: 'offersRedeemed' })"
            />
          </template>
          <template v-else>
            <div class="is-flex align-center gap-sm lifetime-order-range">
              <validated-text-input
                :value="offersRedeemed.range.aboveAmount"
                type="number"
                name="offersRedeemedAboveAmount"
                data-test-id="offers-redeemed-above-amount"
                :rules="{
                  required: true,
                  min_value: 0
                }"
                label="Minimum"
                placeholder="Minimum"
                hide-label
                class="mar-none"
                @input="handleRangeChange({ val: $event, type: 'aboveAmount', criteriaKey: 'offersRedeemed' })"
              />
              and
              <validated-text-input
                :value="offersRedeemed.range.belowAmount"

                type="number"
                name="offersRedeemedBelowAmount"
                data-test-id="offers-redeemed-below-amount"
                :rules="{
                  required: true,
                  min_value: 1,
                  greaterThan: '@offersRedeemedAboveAmount'
                }"
                label="Maximum"
                placeholder="Maximum"
                hide-label
                class="mar-none"
                @input="handleRangeChange({ val: $event, type: 'belowAmount', criteriaKey: 'offersRedeemed' })"
              />
            </div>
          </template>
          offers
        </div>
        <div class="is-flex align-center gap-sm">
          during the
          <validated-input
            :rules="{ required: true }"
            hide-label
            name="offersRedeemedDaysBackType"
            label="Time Frame"
            class="mar-none"
          >
            <b-select
              data-test-id="offers-redeemed-days-back-type"
              :value="offersRedeemed.daysBackType"
              placeholder="Select a time frame..."
              class="mar-none"
              icon="calendar-days"
              @input="($event) => handleOffersRedeemedChange('daysBackType', $event)"
            >
              <option :value="null" disabled>-</option>
              <option v-for="dbType in Object.values(daysBackTypes)" :key="dbType.value" :value="dbType.value">
                {{ dbType.display }}
              </option>
            </b-select>
          </validated-input>
        </div>
      </div>
    </criteria-card>

    <criteria-card
      v-if="criteriaIsActiveFor(criteriaCardMetadata.LOYALTY_POINT_BALANCE.criteriaKeys)"
      :key="`${criteriaCardMetadata.LOYALTY_POINT_BALANCE.defaultCriteriaKey}-criteria-card`"
      :criteria="criteriaCardMetadata.LOYALTY_POINT_BALANCE"
      @remove-criteria="$emit('remove-criteria', $event)"
    >
      <div class="is-flex align-center gap-sm">
        Guest's current point balance is
        <validated-input
          :rules="{ required: true }"
          hide-label
          name="loyaltyPointBalanceOperator"
          label="Operator"
          class="mar-none"
        >
          <b-select
            v-model="loyaltyPointBalanceOperator"
            placeholder="Select an operator..."
            class="mar-none"
          >
            <option :value="null" disabled>-</option>
            <option v-for="operator in Object.values(equalityOperators)" :key="operator" :value="operator">
              {{ $changeCase.capitalCase(operator) }}
            </option>
          </b-select>
        </validated-input>
        <template v-if="loyaltyPointBalanceOperator !== equalityOperators.BETWEEN">
          <validated-text-input
            v-model="value.loyaltyPointBalance.equality.amount"
            type="number"
            name="loyaltyPointBalance"
            :rules="{
              min_value: 0,
              required: true
            }"
            label="Amount"
            placeholder="Amount"
            hide-label
            class="mar-none"
          />
        </template>
        <template v-else>
          <div class="is-flex align-center gap-sm">
            <validated-text-input
              :value="value.loyaltyPointBalance.range.aboveAmount"
              type="number"
              name="loyaltyPointBalanceAboveAmount"
              data-test-id="loyalty-point-balance-above-amount"
              :rules="{
                required: true,
                min_value: 1
              }"
              label="Minimum"
              placeholder="Minimum"
              hide-label
              class="mar-none"
              @input="handleRangeChange({ val: $event, type: 'aboveAmount', criteriaKey: 'loyaltyPointBalance' })"
            />
            and
            <validated-text-input
              :value="value.loyaltyPointBalance.range.belowAmount"
              type="number"
              name="loyaltyPointBalanceBelowAmount"
              data-test-id="loyalty-point-balance-below-amount"
              :rules="{
                required: true,
                min_value: 1,
                greaterThan: '@loyaltyPointBalanceAboveAmount'
              }"
              label="Maximum"
              placeholder="Maximum"
              hide-label
              class="mar-none"
              @input="handleRangeChange({ val: $event, type: 'belowAmount', criteriaKey: 'loyaltyPointBalance' })"
            />
          </div>
        </template>
      </div>
    </criteria-card>

    <!-- LOYALTY PROGRAM TIER -->
    <criteria-card
      v-if="criteriaIsActiveFor(criteriaCardMetadata.LOYALTY_PROGRAM_TIER.criteriaKeys)"
      :key="`${criteriaCardMetadata.LOYALTY_PROGRAM_TIER.defaultCriteriaKey}-criteria-card`"
      :criteria="criteriaCardMetadata.LOYALTY_PROGRAM_TIER"
      @remove-criteria="$emit('remove-criteria', $event)"
    >
      <validated-input
        label="Membership Tier"
        name="membershipTiers"
        hide-label
        :custom-messages="{ required: 'You must select at least one Membership Tier' }"
        rules="required"
      >
        <check-button
          v-for="(option, i) in membershipTierOptions"
          :key="option.publicId"
          v-model="membershipTierIds"
          :native-value="option.publicId"
        >
          <div class="has-text-weight-bold">{{ option.displayName }}</div>
          <div class="has-text-weight-normal">
            ({{ getTierPoints(option, i) }} {{ primaryCurrency.pluralName }})
          </div>
        </check-button>
      </validated-input>
    </criteria-card>

  </transition-group>
</template>

<script>
  import CriteriaCard from './criteria-card.vue';
  import { criteriaCardMetadata, equalityOperators, daysBackTypes } from '@/constants/segmentations';
  import { capitalCase } from 'change-case';
  import LoyaltyProgramTier from '@/store/classes/LoyaltyProgramTier';
  import Currency from '@/store/classes/Currency';

  export default {
    name: 'LoyaltyCriteriaForm',

    components: { CriteriaCard },

    props: {
      value: {
        type: Object,
        required: true
      }
    },

    data() {
      return {
        criteriaCardMetadata,
        equalityOperators,
        daysBackTypes
      };
    },

    computed: {
      enrolledInLoyalty: {
        get() {
          return this.value.enrolledInLoyalty;
        },
        set(value) {
          this.handleInput({ enrolledInLoyalty: value });
        }
      },

      loyaltyPointBalanceOperator: {
        get() {
          if (this.value.loyaltyPointBalance.equality) {
            return this.value.loyaltyPointBalance.equality.operator;
          }
          return this.value.loyaltyPointBalance ? equalityOperators.BETWEEN : null;
        },
        set(val) {
          if (val === equalityOperators.BETWEEN) {
            this.handleInput({
              loyaltyPointBalance: {
                equality: undefined,
                range: {
                  aboveAmount: null,
                  belowAmount: null
                }
              }
            });
          }
          else {
            this.handleInput({
              loyaltyPointBalance: {
                range: undefined,
                equality: {
                  amount: this.value.loyaltyPointBalance.equality?.amount || null,
                  operator: val
                }
              }
            });
          }
        }
      },

      offersRedeemed() {
        const defaultOffersRedeemed = {
          daysBackType: null,
          // NOTE: this could default to either, technically, but not both
          // range: {
          //   aboveAmount: null,
          //   belowAmount: null
          // },
          equality: {
            operator: null,
            amount: null
          }
        };
        return this.value.offersRedeemed
          ? this.value.offersRedeemed
          : defaultOffersRedeemed;
      },

      offersRedeemedOperator: {
        get() {
          if (this.offersRedeemed.equality) {
            return this.offersRedeemed.equality.operator;
          }
          return this.value.offersRedeemed ? equalityOperators.BETWEEN : null;
        },
        set(val) {
          if (val === equalityOperators.BETWEEN) {
            this.handleInput({
              offersRedeemed: {
                ...this.value.offersRedeemed,
                equality: undefined,
                range: {
                  aboveAmount: null,
                  belowAmount: null
                }
              }
            });
          }
          else {
            this.handleInput({
              offersRedeemed: {
                ...this.value.offersRedeemed,
                equality: {
                  amount: this.offersRedeemed?.equality?.amount,
                  operator: val
                },
                range: undefined
              }
            });
          }
        }
      },

      membershipTierOptions() {
        return LoyaltyProgramTier.query().orderBy('attainmentSequenceNumber').get();
      },

      primaryCurrency() {
        return Currency.primaryCurrency();
      },

      membershipTierIds: {
        get() {
          return this.value.loyaltyProgramTier?.programTierPublicIds || [];
        },
        set(val) {
          if (!val || !val.length) {
            this.handleInput({
              loyaltyProgramTier: criteriaCardMetadata.LOYALTY_PROGRAM_TIER.defaultData
            });
          }
          else {
            this.handleInput({
              loyaltyProgramTier: {
                programTierPublicIds: val
              }
            });
          }
        }
      }

    },

    methods: {
      capitalCase,

      handleInput(payload) {
        this.$emit('handle-input', payload);
      },

      getTierPoints(tier, index) {
        const currentTierPoints = tier.attainmentPolicy?.minimumQualifyingPoints || 0;
        const nextTierPoints = this.membershipTierOptions[index + 1]?.attainmentPolicy?.minimumQualifyingPoints || 0;
        if (index === this.membershipTierOptions.length - 1) {
          return `${currentTierPoints}+`;
        }
        return `${currentTierPoints} - ${nextTierPoints - 1}`;
      },

      handleEqualityChange({ val, type, criteriaKey }) {
        switch (type) {
          case 'operator':
            this.handleInput({
              [criteriaKey]: {
                ...this.value[criteriaKey],
                equality: {
                  ...this.value[criteriaKey]?.equality,
                  operator: val
                }
              }
            });
            break;
          case 'amount':
            this.handleInput({
              [criteriaKey]: {
                ...this.value[criteriaKey],
                equality: {
                  ...this.value[criteriaKey]?.equality,
                  amount: val
                }
              }
            });
            break;
          default:
            break;
        }
      },

      handleRangeChange({ val, type, criteriaKey }) {
        switch (type) {
          case 'aboveAmount':
            this.handleInput({
              [criteriaKey]: {
                ...this.value[criteriaKey],
                range: {
                  ...this.value[criteriaKey]?.range,
                  aboveAmount: val
                }
              }
            });
            break;
          case 'belowAmount':
            this.handleInput({
              [criteriaKey]: {
                ...this.value[criteriaKey],
                range: {
                  ...this.value[criteriaKey]?.range,
                  belowAmount: val
                }
              }
            });
            break;
          default:
            break;
        }
      },

      handleOffersRedeemedChange(type, val) {
        switch (type) {
          case 'daysBackType':
            this.handleInput({
              offersRedeemed: {
                ...this.value.offersRedeemed,
                daysBackType: val
              }
            });
            break;
          default:
            break;
        }
      },

      criteriaIsActiveFor(criteriaKeys) {
        return criteriaKeys.some(key => this.value[key] !== undefined && this.value[key] !== null); // `false` could be a valid value
      }
    }
  };
</script>
