var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.$_selectedMerchant)?_c('div',[_c('hero-banner',{scopedSlots:_vm._u([{key:"right",fn:function(){return [(_vm.segmentations.length)?_c('b-button',{attrs:{"rounded":"","inverted":"","size":"is-medium","type":"is-primary","icon-left":"plus","loading":_vm.isLoading},on:{"click":function($event){return _vm.openSegmentationModal()}}},[_vm._v(" New Segmentation ")]):_vm._e()]},proxy:true}],null,false,2296304104)},[_c('h1',{staticClass:"title is-2"},[_vm._v("Segmentations")])]),_c('div',{staticClass:"section"},[_c('div',{staticClass:"container"},[(_vm.segmentations.length)?_c('div',{staticClass:"card"},[_c('searchable-table',{attrs:{"table-data":_vm.segmentations,"search":{
            placeholder: 'Search by Segmentation Name',
            keys: ['name']
          }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var filteredData = ref.filteredData;
return [_c('b-table',{staticClass:"is-middle-aligned",attrs:{"data":_vm.isFetchingSegmentations ? [] : filteredData,"paginated":_vm.segmentations.length > _vm.pageLimit,"pagination-simple":"","per-page":_vm.pageLimit,"mobile-cards":false,"default-sort":['name', 'asc'],"loading":_vm.isFetchingSegmentations || _vm.isLoading}},[_c('b-table-column',{attrs:{"sortable":"","field":"name","label":"Name"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var row = ref.row;
return [_c('span',{staticClass:"link",on:{"click":function($event){return _vm.openSegmentationModal({ segmentation: row, mode: 'update' })}}},[_vm._v(" "+_vm._s(row.name || 'N/A')+" ")])]}}],null,true)}),_c('b-table-column',{attrs:{"centered":"","cell-class":"action","width":"1","label":"Delete"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var row = ref.row;
return [_c('div',{directives:[{name:"tippy",rawName:"v-tippy",value:({
                  content: 'Only unused segmentations can be deleted',
                  placement: 'left',
                  onShow: function () { return row.campaignIds.length !== 0; }
                }),expression:"{\n                  content: 'Only unused segmentations can be deleted',\n                  placement: 'left',\n                  onShow: () => row.campaignIds.length !== 0\n                }"}]},[_c('b-button',{attrs:{"disabled":row.campaignIds.length !== 0},on:{"click":function($event){return _vm.openConfirmSegmentationDeletion(row)}}},[_c('b-icon',{attrs:{"size":"is-small","type":"is-danger","icon":"trash-alt"}})],1)],1)]}}],null,true)}),_c('template',{slot:"empty"},[_c('empty-table-loader',{attrs:{"icon":"chart-pie","message":"No Segmentations Found...","loading":_vm.isFetchingSegmentations || _vm.isLoading}})],1)],2)]}}],null,false,355099229)})],1):_c('empty-state-card',{attrs:{"type":"is-primary","title":"Create Segmentation","message":"Define groups of users for targetting campaigns","icon":"chart-pie"},scopedSlots:_vm._u([{key:"buttons",fn:function(){return [_c('b-button',{attrs:{"type":"is-primary","rounded":"","icon-left":"plus"},on:{"click":function($event){return _vm.openSegmentationModal()}}},[_vm._v(" New Segmentation ")])]},proxy:true}],null,false,3939787933)})],1)])],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }