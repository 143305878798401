import { Model } from '@vuex-orm/core';
import http from '@/services/http';



export default class Program extends Model {
  static entity = 'programTiers'

  static primaryKey = 'publicId'

  // FIELDS //////////////////////
  static fields() {
    return {
      publicId: this.attr(''),
      programPublicId: this.attr(''),
      displayName: this.attr(''),
      attainmentSequenceNumber: this.attr(Number),
      isDefault: this.attr(''),
      attainmentPolicy: this.attr(''),
      expirationPolicy: this.attr('')
    };
  }



  // STATE //////////////////////
  static state() {
    return {
      fetching: false
    };
  }

  static $state() {
    return this.store().state.entities.programTiers;
  }


  // ACTIONS //////////////////////
  static async fetchProgramTiers(programPublicId, options = {}) {
    try {
      this.commit((state) => {
        state.fetching = true;
      });

      const alreadyFetched = this.query().where('programPublicId', programPublicId).exists();
      const merchantId = this.store().state.entities.merchants.selectedMerchantId;
      if (!alreadyFetched || options.forceFetch) {
        const { data } = await http.get(`merchants/${merchantId}/programs/${programPublicId}/program_tiers`);

        this.create({ data: data.programTiers });
      }
    }

    catch (error) {
      throw error;
    }

    finally {
      this.commit((state) => {
        state.fetching = false;
      });
    }
  }
}
