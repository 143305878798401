<template>
  <div class="card is-flex-space-between align-center" :data-test-id="`${type}-export-card`">
    <div>
      <div class="card-header-title pad-b-none">{{ capitalCase(type) }}</div>
      <div class="card-content pad">
        <div>
          <p>{{ subtitle }}</p>
          <p>{{ availability }}</p>
        </div>
      </div>
    </div>
    <b-button
      class="is-primary mar-r"
      icon-left="download"
      :loading="exporting"
      :disabled="exporting"
      @click="$emit('export', type)"
    >
      Download CSV
    </b-button>
  </div>
</template>

<script>
  import { capitalCase } from 'change-case';

  export default {
    name: 'ExportCard',

    props: {
      type: {
        type: String,
        required: true
      },
      subtitle: {
        type: String,
        required: true
      },
      availability: {
        type: String,
        required: true
      },
      exporting: {
        type: Boolean,
        default: false
      }
    },

    data: () => ({ capitalCase })
  };
</script>
