const merchantAppSettingResources = {
  PAY_AT_TABLE: 'pay-at-table',
  ORDER_AT_TABLE: 'order-at-table',
  ORDER_AHEAD: 'order-ahead',
  AGE_RESTRICTED: 'age-restricted',
  READ_ONLY_MENU: 'read-only-menu',
  DELIVERY: 'delivery',
  ASAP_PICKUP_ONLY: 'asap-pickup-only',
  CATERING: 'catering',
  DEFAULT_STORE_ID: 'default-store-id',
  ENABLE_SURVEYS: 'enable-surveys',
  GROUP_ORDER: 'group-order',
  KIOSK: 'kiosk',
  SIMPLE_ORDER_AHEAD: 'simple-order-ahead',
  TEXT_TO_PAY: 'text-to-pay',
  ROOM_SERVICE: 'room-service',
  SHIPPING: 'shipping',
  EXTERNAL_DEVICE: 'external-device',
  EMV_MARKETING_OPT_IN_TIMEOUT: 'emv-marketing-opt-in-timeout',
  DISABLE_SAVED_PAYMENT_MANAGEMENT: 'disable-saved-payment-management'
};

export default merchantAppSettingResources;
