<template>
  <b-checkbox
    :value="value"
    :size="size"
    :disabled="disabled"
    :native-value="nativeValue"
    :class="[
      'check-button',
      orientation,
      {
        checked: isChecked,
        compact,
        'fixed-height': fixedHeight,
        'left-label': leftLabel,
        'justify-between': justifyBetween || leftLabel,
        'justify-start': justifyStart,
        'justify-end': justifyEnd
      }
    ]"
    type="is-primary"
    @input="$emit('input', $event)"
  >
    <div class="is-flex align-center">
      <b-icon
        v-if="icon"
        :icon="icon"
        :pack="iconPack"
        class="mar-r-sm"
        size="is-medium"
      />
      <div>
        <p class="label mar-b-none">
          <slot>{{ label }}</slot>
        </p>
        <p v-if="$slots.sublabel || sublabel" class="sublabel">
          <slot name="sublabel">{{ sublabel }}</slot>
        </p>
      </div>
    </div>
  </b-checkbox>
</template>

<script>
  export default {
    name: 'CheckButton',

    props: {
      value: {
        type: [Boolean, Array, Number],
        default: null,
        validator: value => (typeof value === 'number' ? value === 0 || value === 1 : true)
      },
      nativeValue: {
        type: [String, Number, Boolean],
        default: null
      },
      compact: {
        type: Boolean,
        default: false
      },
      disabled: {
        type: Boolean,
        default: false
      },
      label: {
        type: String,
        default: ''
      },
      leftLabel: {
        type: Boolean,
        default: false
      },
      justifyBetween: {
        type: Boolean,
        default: false
      },
      justifyStart: {
        type: Boolean,
        default: false
      },
      justifyEnd: {
        type: Boolean,
        default: false
      },
      orientation: {
        type: String,
        default: 'horizontal'
      },
      size: {
        type: String,
        default: null
      },
      sublabel: {
        type: [String, Boolean],
        default: ''
      },
      icon: {
        type: String,
        default: ''
      },
      iconPack: {
        type: String,
        default: 'fa'
      },
      fixedHeight: {
        type: Boolean,
        default: false
      }
    },

    computed: {
      isChecked() {
        return Array.isArray(this.value)
          ? this.value.includes(this.nativeValue)
          : this.value;
      }
    }
  };
</script>

<style lang='sass' scoped>
  .checkbox
    transition: 150ms
    padding: 0.75rem 1rem
    border: 1px solid $grey-lighter
    border-radius: $radius
    background-color: $white

    .sublabel
      margin-top: 2px
      font-size: $size-small
      color: $grey

    &:hover
      border-color: $grey-light

    &.fixed-height
      height: 2.5em

    &.checked
      border-color: $primary-light
      background: radial-gradient(closest-side, transparent, $primary-lightest)

      .sublabel
        color: $primary-dark

      &:hover
        border-color: $primary

    &.compact
      padding: 0.5rem 1rem

    &.vertical
      flex-direction: column
      ::v-deep .control-label
        padding: 1rem 0 0
        text-align: center
</style>
