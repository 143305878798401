/* eslint-disable import/no-cycle */
import { Model } from '@vuex-orm/core';
import http from '@/services/http';
import MerchantContentMetadata from '@/store/classes/MerchantContentMetadata';
import Store from '@/store/classes/Store';
import filterObjectKeys from '@/helpers/filter-object-keys';

export default class MerchantContent extends Model {
  static entity = 'merchantContents'

  static fields() {
    return {
      id: this.attr(''),
      contentTypeId: this.attr(''),
      merchantId: this.attr(''),
      storeId: this.attr(''),
      createdAt: this.attr(''),
      description: this.attr(''),
      title: this.attr(''),
      updatedAt: this.attr(''),
      merchantContentMetadata: this.hasMany(MerchantContentMetadata, 'merchantContentId')
    };
  }

  get storeName() {
    return Store.find(this.storeId)?.description;
  }

  // STATE //////////////////////
  static state() {
    return {
      fetching: false,
      submitting: false
    };
  }

  static $state() {
    return this.store().state.entities.merchantContents;
  }


  // Actions
  static async fetchMerchantContent() {
    try {
      this.commit((state) => {
        state.fetching = true;
      });

      const merchantId = this.store().state.entities.merchants.selectedMerchantId;
      const { data } = await http.get(`merchants/${merchantId}/contents`);

      this.create({ data: data.merchantContents });
    }
    catch (error) {
      throw error;
    }
    finally {
      this.commit((state) => {
        state.fetching = false;
      });
    }
  }

  static async createMerchantContent(merchantContent) {
    try {
      this.commit((state) => {
        state.submitting = true;
      });
      const merchantId = this.store().state.entities.merchants.selectedMerchantId;
      const acceptedKeys = [
        'contentTypePropertyId',
        'value'
      ];
      const response = await http.post(`merchants/${merchantId}/contents`, {
        merchantContent: {
          title: merchantContent.title,
          description: merchantContent.description,
          contentTypeId: merchantContent.contentTypeId,
          ...merchantContent.storeId && { storeId: merchantContent.storeId },
          merchantContentMetadataAttributes: merchantContent.merchantContentMetadata.map(mcma => filterObjectKeys(mcma, acceptedKeys))
        }
      });

      this.insert({ data: response.data.merchantContent });
    }
    catch (error) {
      throw error;
    }
    finally {
      this.commit((state) => {
        state.submitting = false;
      });
    }
  }

  static async updateMerchantContent(merchantContent) {
    try {
      this.commit((state) => {
        state.submitting = true;
      });
      const merchantId = this.store().state.entities.merchants.selectedMerchantId;

      const merchantContentMetadataAttributes = merchantContent.merchantContentMetadata;

      const response = await http.put(`merchants/${merchantId}/contents/${merchantContent.id}`, {
        merchantContent: {
          title: merchantContent.title,
          description: merchantContent.description,
          merchantContentMetadataAttributes
        }
      });


      this.insert({
        data: response.data.merchantContent
      });
    }

    catch (error) {
      throw error;
    }

    finally {
      this.commit((state) => {
        state.submitting = false;
      });
    }
  }

  static async deleteMerchantContents(ids) {
    try {
      const merchantId = this.store().state.entities.merchants.selectedMerchantId;

      await Promise.all(ids.map(id => http.delete(`merchants/${merchantId}/contents/${id}`)));

      this.delete(content => ids.includes(content.id));
    }

    catch (error) {
      throw error;
    }
  }

  static async bulkCreateOrUpdateMerchantContent({ merchantContent, storeIds }) {
    try {
      this.commit((state) => {
        state.submitting = true;
      });

      const merchantId = this.store().state.entities.merchants.selectedMerchantId;

      const acceptedKeys = [
        'contentTypePropertyId',
        'merchantContentId',
        'value',
        '_destroy'
      ]

      const merchantContentMetadataAttributes = merchantContent.merchantContentMetadata.map(mcma => filterObjectKeys(mcma, acceptedKeys));

      const response = await http.post(`merchants/${merchantId}/contents/bulk_upsert`, {
        storeIds,
        merchantContent: {
          title: merchantContent.title,
          description: merchantContent.description,
          contentTypeId: merchantContent.contentTypeId,
          merchantContentMetadataAttributes
        }
      });

      this.insert({ data: response.data.merchantContents });
    }
    catch (error) {
      throw error;
    }
    finally {
      this.commit((state) => {
        state.submitting = false;
      });
    }
  }

  static async bulkDeleteMerchantContent(merchantContentIds) {
    try {
      this.commit((state) => {
        state.submitting = true;
      });

      const merchantId = this.store().state.entities.merchants.selectedMerchantId;

      await http.delete(`merchants/${merchantId}/contents/bulk_destroy`, { params: { merchantContentIds: JSON.stringify(merchantContentIds) } });

      merchantContentIds.forEach(id => this.delete(id));
    }
    catch (error) {
      throw error;
    }
    finally {
      this.commit((state) => {
        state.submitting = false;
      });
    }
  }

  static async createMerchantContentMetadataImage(merchantContentMetadataImage) {
    try {
      const merchantId = this.store().state.entities.merchants.selectedMerchantId;
      const formData = new FormData();
      formData.append('merchantContentMetadataImage[file]', merchantContentMetadataImage);
      const response = await http.post(
        `merchants/${merchantId}/merchant_content_metadata_images`,
        formData,
        { headers: { ContentType: 'multipart/form-data' } }
      );
      return response.data.merchantContentMetadataImageUrl;
    }
    catch (error) {
      throw error;
    }
  }
}
