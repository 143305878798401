var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('panel',{attrs:{"title":"Challenges","no-padding":""}},[(_vm.isFetching)?_c('b-loading',{attrs:{"active":_vm.isFetching,"is-full-page":false}}):_vm._e(),_c('searchable-table',{attrs:{"table-data":_vm.challenges,"search":{
      placeholder: 'Search by Challenge Name',
      keys: ['title']
    },"filters":[
      {
        placeholder: 'Status',
        key: 'portalDerivedStatus',
        multiple: true,
        default: [
          _vm.earnRuleStatuses.DRAFT,
          _vm.earnRuleStatuses.ACTIVE,
          _vm.earnRuleStatuses.SCHEDULED,
          _vm.earnRuleStatuses.EXPIRING,
          _vm.earnRuleStatuses.INVALID
        ],
        options: Object.keys(_vm.tagTypes).map(function (key) { return ({
          name: _vm.$changeCase.capitalCase(key),
          value: key
        }); })
      } ]},scopedSlots:_vm._u([{key:"optional-actions",fn:function(){return [_c('b-button',{attrs:{"type":"is-primary","icon-left":"plus"},on:{"click":function($event){return _vm.openChallengeModal()}}},[_vm._v(" Challenge ")])]},proxy:true},{key:"default",fn:function(ref){
    var filteredData = ref.filteredData;
return [_c('b-table',{staticClass:"is-middle-aligned",attrs:{"data":_vm.isFetching ? [] : filteredData,"mobile-cards":false,"scrollable":"","detail-key":"publicId","data-test-id":"challenges-table","default-sort":['portalDerivedStatus', 'asc']},scopedSlots:_vm._u([{key:"empty",fn:function(){return [_c('empty-table-loader',{attrs:{"message":"No Challenges Found...","loading":_vm.isFetching}})]},proxy:true}],null,true)},[_c('b-table-column',{attrs:{"sortable":"","field":"title","label":"Name","cell-class":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
    var row = ref.row;
return [_c('span',{staticClass:"link",on:{"click":function($event){return _vm.openChallengeModal(row)}}},[_vm._v(" "+_vm._s(row.title || 'N/A')+" ")]),_c('p',{staticClass:"is-size-7"},[_vm._v(" "+_vm._s(row.description || 'N/A')+" ")])]}}],null,true)}),_c('b-table-column',{attrs:{"sortable":"","field":"schedule","label":"Schedule","cell-class":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
    var row = ref.row;
return [_c('span',[_vm._v(" "+_vm._s(row.schedule)+" ")])]}}],null,true)}),_c('b-table-column',{attrs:{"sortable":"","field":"portalDerivedStatus","label":"Status","centered":"","cell-class":"no-wrap-text","custom-sort":_vm.sortStatus},scopedSlots:_vm._u([{key:"default",fn:function(ref){
    var row = ref.row;
return [_c('b-tag',{staticClass:"is-outlined is-light",attrs:{"type":_vm.tagTypes[row.portalDerivedStatus]}},[_vm._v(" "+_vm._s(_vm.$changeCase.capitalCase(row.portalDerivedStatus))+" ")])]}}],null,true)}),_c('b-table-column',{attrs:{"centered":"","cell-class":"actions","label":"Actions","width":"1","field":"Actions"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
    var row = ref.row;
return [_c('dropdown-menu',{attrs:{"position":"bottom-end","mobile-modal":false}},[_c('b-button',{staticClass:"is-transparent",attrs:{"slot":"trigger","type":"is-white"},slot:"trigger"},[_c('b-icon',{attrs:{"icon":"ellipsis-v","pack":"far","size":"is-small"}})],1),_c('b-dropdown-item',{on:{"click":function($event){return _vm.openChallengeModal(row)}}},[_c('b-icon',{staticClass:"mar-r-sm",attrs:{"icon":row.portalDerivedStatus === _vm.earnRuleStatuses.EXPIRED ? 'eye' : 'pencil',"size":"is-small"}}),_vm._v(" "+_vm._s(row.portalDerivedStatus === _vm.earnRuleStatuses.DRAFT ? 'Edit' : 'View')+" Challenge ")],1),(row.portalDerivedStatus === _vm.earnRuleStatuses.SCHEDULED)?_c('hr',{staticClass:"dropdown-divider"}):_vm._e(),(row.portalDerivedStatus === _vm.earnRuleStatuses.SCHEDULED)?_c('b-dropdown-item',{on:{"click":function($event){return _vm.openCancelScheduledChallengeConfirmation(row)}}},[_c('b-icon',{staticClass:"mar-r-sm",attrs:{"icon":"trash-alt","size":"is-small","type":"is-danger"}}),_vm._v(" Remove Challenge ")],1):_vm._e(),(row.portalDerivedStatus === _vm.earnRuleStatuses.DRAFT)?_c('b-dropdown-item',{on:{"click":function($event){return _vm.openDeleteDraftChallengeConfirmation(row)}}},[_c('b-icon',{staticClass:"mar-r-sm",attrs:{"icon":"trash-alt","size":"is-small","type":"is-danger"}}),_vm._v(" Delete Challenge ")],1):_vm._e(),(row.portalDerivedStatus === _vm.earnRuleStatuses.ACTIVE)?_c('b-dropdown-item',{staticClass:"is-danger",on:{"click":function($event){return _vm.confirmExpireChallenge(row)}}},[_c('b-icon',{staticClass:"mar-r-sm",attrs:{"icon":"calendar-times","size":"is-small"}}),_vm._v(" Expire Challenge ")],1):_vm._e()],1)]}}],null,true)})],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }