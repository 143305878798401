var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('panel',{attrs:{"title":"Earn Rules","no-padding":""}},[(_vm.isFetching)?_c('b-loading',{attrs:{"active":_vm.isFetching,"is-full-page":false}}):_vm._e(),_c('searchable-table',{attrs:{"table-data":_vm.earnRules,"search":{
      placeholder: 'Search by Earn Rule name',
      keys: ['name']
    },"filters":[
      {
        placeholder: 'Engagement Type',
        key: 'engagementType',
        options: (_vm.earnRules.reduce(function (array, er) {
          if(!array.some(function (item) { return item.value === er.engagementType; })) {
            array.push({ name: er.engagementTypeName, value: er.engagementType });
          }

          return array;
        }, []))
      },
      {
        placeholder: 'Status',
        key: 'portalDerivedStatus',
        multiple: true,
        default: [_vm.earnRuleStatuses.ACTIVE, _vm.earnRuleStatuses.FUTURE, _vm.earnRuleStatuses.SCHEDULED, _vm.earnRuleStatuses.EXPIRING],
        options: Object.keys(_vm.tagTypes).map(function (key) { return ({
          name: _vm.$changeCase.capitalCase(key),
          value: key
        }); })
      } ]},scopedSlots:_vm._u([{key:"optional-actions",fn:function(){return [_c('b-button',{attrs:{"type":"is-primary","icon-left":"plus"},on:{"click":function($event){return _vm.openEarnRuleModal({ earnRule: null })}}},[_vm._v(" Earn Rule ")])]},proxy:true},{key:"default",fn:function(ref){
    var filteredData = ref.filteredData;
return [_c('b-table',{staticClass:"is-middle-aligned",attrs:{"data":_vm.isFetching ? [] : filteredData,"mobile-cards":false,"scrollable":"","detail-key":"publicId","data-test-id":"earn-rules-table","detailed":filteredData.some(function (row) { return row.previousVersions.length; }),"has-detailed-visible":function (row) { return row.previousVersions.length; },"custom-detail-row":""},scopedSlots:_vm._u([{key:"empty",fn:function(){return [_c('empty-table-loader',{attrs:{"message":"No Earn Rules Found...","loading":_vm.isFetching}})]},proxy:true},{key:"detail",fn:function(ref){
    var row = ref.row;
return _vm._l((row.previousVersions),function(previousVersion){return _c('tr',{key:((previousVersion.publicId) + "-v" + (previousVersion.version)),staticClass:"has-text-grey detail-row"},[_c('td',{staticClass:"has-text-centered"},[_vm._v("v"+_vm._s(previousVersion.version))]),_c('td',[_c('span',{staticClass:"link",on:{"click":function($event){return _vm.openEarnRuleModal({
                  earnRule: row,
                  version: previousVersion.version
                })}}},[_vm._v(" "+_vm._s(previousVersion.name)+" ")]),_c('p',{staticClass:"is-size-7"},[_vm._v(" "+_vm._s(previousVersion.description)+" ")])]),_c('td'),_c('td'),_c('td',[_c('b-tag',{staticClass:"is-outlined is-light",attrs:{"type":_vm.tagTypes[previousVersion.portalDerivedStatus]}},[_vm._v(" "+_vm._s(_vm.$changeCase.capitalCase(previousVersion.portalDerivedStatus))+" ")])],1),_c('td',{staticClass:"actions has-text-centered"},[_c('dropdown-menu',{attrs:{"position":"bottom-end","mobile-modal":false}},[_c('b-button',{staticClass:"is-transparent",attrs:{"slot":"trigger","type":"is-white"},slot:"trigger"},[_c('b-icon',{attrs:{"icon":"ellipsis-v","pack":"far","size":"is-small"}})],1),_c('b-dropdown-item',{on:{"click":function($event){return _vm.openEarnRuleModal({ earnRule: row, version: previousVersion.version })}}},[_c('b-icon',{staticClass:"mar-r-sm",attrs:{"icon":"eye","size":"is-small"}}),_vm._v(" View Earn Rule ")],1)],1)],1)])})}}],null,true)},[_c('b-table-column',{attrs:{"sortable":"","field":"name","label":"Name","cell-class":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var row = ref.row;
return [_c('span',{staticClass:"link",on:{"click":function($event){return _vm.openEarnRuleModal({ earnRule: row })}}},[_vm._v(" "+_vm._s(row.name)+" ")]),_c('p',{staticClass:"is-size-7"},[_vm._v(" "+_vm._s(row.description)+" ")])]}}],null,true)}),_c('b-table-column',{attrs:{"sortable":"","field":"engagementTypeName","label":"Engagement Type","cell-class":"no-wrap-text"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var row = ref.row;
return [_vm._v(" "+_vm._s(row.engagementTypeName)+" ")]}}],null,true)}),_c('b-table-column',{attrs:{"sortable":"","field":"publicId","label":"ID","cell-class":"no-wrap-text is-monospaced"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var row = ref.row;
return [_vm._v(" "+_vm._s(row.portalDerivedStatus === _vm.earnRuleStatuses.DRAFT ? 'N/A' : row.publicId)+" ")]}}],null,true)}),_c('b-table-column',{attrs:{"sortable":"","field":"portalDerivedStatus","label":"Status","centered":"","cell-class":"no-wrap-text"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var row = ref.row;
return [_c('b-tag',{staticClass:"is-outlined is-light",attrs:{"type":_vm.tagTypes[row.portalDerivedStatus]}},[_vm._v(" "+_vm._s(_vm.$changeCase.capitalCase(row.portalDerivedStatus))+" ")])]}}],null,true)}),_c('b-table-column',{attrs:{"centered":"","cell-class":"actions","label":"Actions","width":"1","field":"Actions"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var row = ref.row;
return [_c('dropdown-menu',{attrs:{"position":"bottom-end","mobile-modal":false}},[_c('b-button',{staticClass:"is-transparent",attrs:{"slot":"trigger","type":"is-white"},slot:"trigger"},[_c('b-icon',{attrs:{"icon":"ellipsis-v","pack":"far","size":"is-small"}})],1),_c('b-dropdown-item',{on:{"click":function($event){return _vm.openEarnRuleModal({ earnRule: row })}}},[_c('b-icon',{staticClass:"mar-r-sm",attrs:{"icon":row.portalDerivedStatus === _vm.earnRuleStatuses.EXPIRED ? 'eye' : 'pencil',"size":"is-small"}}),_vm._v(" "+_vm._s(row.portalDerivedStatus === _vm.earnRuleStatuses.EXPIRED ? 'View' : 'Edit')+" Earn Rule ")],1),(_vm.canExpireEarnRule(row.portalDerivedStatus))?_c('b-dropdown-item',{staticClass:"is-danger",on:{"click":function($event){return _vm.confirmExpire(row)}}},[_c('b-icon',{staticClass:"mar-r-sm",attrs:{"icon":"calendar-times","size":"is-small"}}),_vm._v(" Expire Earn Rule ")],1):_vm._e(),(_vm.canDeleteEarnRule(row.portalDerivedStatus))?_c('b-dropdown-item',{staticClass:"is-danger",on:{"click":function($event){return _vm.confirmDelete(row.publicId)}}},[_c('b-icon',{staticClass:"mar-r-sm",attrs:{"icon":"trash-alt","size":"is-small"}}),_vm._v(" Remove Earn Rule ")],1):_vm._e()],1)]}}],null,true)})],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }