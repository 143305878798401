<template>
  <div class="card">
    <div class="card-content">
      <div id="tableauDashboardContainer">
        <tableau-viz
            id="tableauViz"
            :src="tableauSrc"
            :token="tableauJwtToken.accessToken"
            device="Desktop"
            toolbar="hidden"
            hide-tabs="false"
        >
          <viz-parameter name="Parameter-MerchantId" :value="$_selectedMerchantId" />
        </tableau-viz>
      </div>
    </div>
  </div>
</template>

<script>
  import merchantMixin from '@/mixins/merchant';

  export default {
    name: 'Reporting',

    mixins: [merchantMixin],

    props: {
      tableauJwtToken: {
        type: Object,
        required: true
      },

      isLoyaltyReporting: {
        type: Boolean,
        default: false
      }
    },

    computed: {
      tableauSrc() {
        return this.isLoyaltyReporting
          ? this.tableauJwtToken.loyaltyDashboardUrl
          : this.tableauJwtToken.dashboardUrl;
      },

      heroBannerText() {
        return this.isLoyaltyReporting ? 'Loyalty Reporting' : 'Reporting';
      }
    }
  };
</script>
