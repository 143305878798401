<template>
  <b-loading v-if="storeIsLoading" active :is-full-page="false" />
  <tabs-page
    v-else-if="store"
    :tabs="tabs"
    :title="store.description"
    :keep-alive="false"
  />
</template>

<script>
  import Store from '@/store/classes/Store';
  import merchantMixin from '@/mixins/merchant';
  import { mapGetters } from 'vuex';

  // tab components
  import storeDetail from './store-detail.vue';
  import storeOrderingModes from './store-ordering-modes.vue';
  import storeCommunication from './store-communication.vue';
  import storeConfigurations from './configurations/store-configurations.vue';
  import storeAuthorizationsTab from './store-authorizations-tab.vue';
  import storeHours from './store-hours/store-hours.vue';
  import storeOrderTiming from './store-order-timing/store-order-timing.vue';
  import tippingOptionForm from '@/components/globals/tipping/tipping-option-form.vue';

  import posTypes from '@/constants/posTypes';

  export default {
    name: 'StoreTabs',

    mixins: [merchantMixin],

    computed: {
      ...mapGetters('session', ['isCardfreeAdmin']),

      tabs() {
        return [
          {
            display: 'Location Details',
            name: 'details',
            component: storeDetail
          },
          {
            display: 'Hours',
            name: 'hours',
            component: storeHours,
            props: { store: this.store }
          },
          {
            display: 'Ordering Modes',
            name: 'ordering-modes',
            component: storeOrderingModes,
            props: { store: this.store }
          },
          {
            display: 'Order Timing',
            name: 'order-timing',
            component: storeOrderTiming,
            props: { store: this.store }
          },
          {
            display: 'Notification Preferences',
            name: 'notifications',
            component: storeCommunication,
            props: { store: this.store }
          },
          {
            display: 'Tipping Options',
            name: 'tippingOptions',
            component: tippingOptionForm,
            props: {
              merchant: this.$_selectedMerchant,
              store: this.store,
              isStoreTipping: true
            }
          },
          {
            display: 'Authorizations',
            name: 'authorizations',
            component: storeAuthorizationsTab,
            isHidden: this.store.posTypeId !== posTypes.Clover
          },
          {
            display: 'Configurations',
            name: 'configurations',
            component: storeConfigurations,
            isHidden: !this.isCardfreeAdmin
          }
        ];
      },

      store() {
        return Store.query().withAllRecursive().find(this.$route.params.storeId);
      },

      storeIsLoading() {
        return Store.$state().fetchingAll;
      }
    },

    methods: {
      onMerchantLoad() {
        this.fetchStore();
      },

      async fetchStore() {
        try {
          await Store.fetchById(this.$route.params.storeId);
        }
        catch (error) {
          this.$_onRequestError({
            toastOptions: {
              message: 'Failed to fetch location'
            },
            error
          });
        }
      }
    }
  };
</script>
