<template>
  <b-loading v-if="isLoadingUser" active :is-full-page="false" />
  <tabs-page
    v-else-if="user && currentUser"
    :title="pageTitle"
    :tabs="tabs"
  >
    <template v-if="canEditUserActivation || canDeleteUser" #header-action>
      <b-button
        v-if="canEditUserActivation"
        v-tippy="{
          content: `${user && user.active
            ? 'Deactivated users can be reactivated'
            : 'Activated users will regain full access to their account'
          }`,
          placement: 'left'
        }"
        rounded
        inverted
        :type="user.active ? 'is-danger' : 'is-success'"
        :icon-left="user.active ? 'ban' : 'bolt'"
        @click="confirmActivationChange"
      >
        {{ user.active ? 'Deactivate' : 'Activate' }}
      </b-button>
      <b-button
        v-if="canDeleteUser"
        v-tippy="{ content: 'Deleted users cannot be recovered', placement: 'left' }"
        class="mar-l"
        rounded
        inverted
        type="is-danger"
        icon-left="trash-alt"
        @click="confirmDeleteUser"
      >
        Delete
      </b-button>
    </template>
  </tabs-page>
</template>

<script>
  import User from '@/store/classes/User';
  import userDetail from './user-detail.vue';
  import userPassword from './user-password.vue';
  import { mapGetters } from 'vuex';
  import { subject } from '@casl/ability';
  import roleTypes from '@/constants/roleTypes';
  import alertModal from '@/components/globals/alert-modal.vue';

  export default {
    name: 'UserConfiguration',

    computed: {
      ...mapGetters('session', ['currentUser', 'isCardfreeAdmin']),

      user() {
        return User.find(this.$route.params.id);
      },

      canEditUserActivation() {
        return this.$can('manage_user_activation', subject('User', this.user)) && this.canEditActivationAndDestroy;
      },

      canDeleteUser() {
        return this.$can('destroy', subject('User', this.user)) && this.canEditActivationAndDestroy;
      },

      hasOnlyDeactivatedMerchants() {
        return !!this.user?.merchants?.length && this.user?.merchants.every(m => !m.isActive);
      },

      canEditActivationAndDestroy() {
        if (this.isCardfreeAdmin) {
          return true;
        }

        const currentUserRole = this.currentUser?.role?.name;
        const isCurrentUserLocationBasedRole = ![roleTypes.MERCHANT_ADMIN, roleTypes.MERCHANT_ADMIN_LITE].includes(currentUserRole);

        // NOTE: Current User has to have EVERY merchant user has
        const hasMatchingMerchants = this.user?.merchants.every(m =>
            this.currentUser?.merchants.some(cm => cm.id === m.id)
        );

        // NOTE: Current User has to belong to every location user belongs to
        const hasMatchingLocations = isCurrentUserLocationBasedRole && this.user?.storeIds?.every(id =>
            this.currentUser?.storeIds?.includes(id)
        );

        return isCurrentUserLocationBasedRole ? hasMatchingMerchants && hasMatchingLocations : hasMatchingMerchants;
      },

      pageTitle() {
        return this.user?.fullName || 'User Details';
      },

      isLoadingUser() {
        return User.$state().fetching;
      },

      tabs() {
        return [
          {
            display: 'User Details',
            name: 'details',
            component: userDetail
          },
          {
            display: 'Update Password',
            name: 'password',
            component: userPassword,
            isHidden: this.user?.id !== this.currentUser?.id || this.user.isSso
          }
        ];
      }
    },

    created() {
      this.fetchUser();
    },

    methods: {
      async fetchUser() {
        try {
          await User.fetchUser(this.$route.params.id);
        }
        catch (error) {
          this.$_onRequestError({
            toastOptions: { message: 'Unable to fetch user data' },
            error
          });
        }
      },

      async deleteUser() {
        try {
          await User.deleteUser(this.user.id);

          this.$_onRequestSuccess({
            toastOptions: {
              message: 'Successfully deleted the user!'
            },
            options: {
              redirectTo: { name: 'userList' }
            }
          });
        }
        catch (error) {
          this.$_onRequestError({
            toastOptions: {
              message: 'There was an error deleting the user'
            },
            error
          });
        }
      },

      confirmDeleteUser() {
        this.$buefy.modal.open({
          parent: this,
          component: alertModal,
          hasModalCard: true,
          trapFocus: true,
          canCancel: ['escape', 'outside'],
          customClass: 'auto-width',
          props: {
            title: 'Delete User',
            message: `Warning! You are about to delete the user belonging to <b>${this.user.fullName}</b>. This <b>CANNOT</b> be undone.`,
            secondaryMessage: 'Are you sure you would like to proceed?',
            horizontal: true,
            showCloseButton: false,
            icon: 'trash-alt',
            type: 'is-danger',
            buttons: [
              { text: 'Cancel' },
              { text: 'Delete User', primary: true, onClick: this.deleteUser }
            ]
          }
        });
      },

      async activateUser() {
        try {
          await User.activateUser(this.user.id);
          this.$_onRequestSuccess({
            toastOptions: { message: `<b>${this.user.fullName}</b> has been successfully activated!` }
          });
        }

        catch (error) {
          this.$_onRequestError({
            toastOptions: { message: this.hasOnlyDeactivatedMerchants && !this.user.active ? error?.data.message : 'Unable to activate user' },
            error
          });
        }
      },

      async deactivateUser() {
        try {
          await User.deactivateUser(this.user.id);
          this.$_onRequestSuccess({
            toastOptions: { message: `<b>${this.user.fullName}</b> has been successfully deactivated!` }
          });
        }

        catch (error) {
          this.$_onRequestError({
            toastOptions: { message: 'Unable to deactivate user' },
            error
          });
        }
      },

      openNoActiveMerchantsAlert() {
        this.$buefy.dialog.alert({
          title: 'No Active Merchants',
          message: 'A user with no active merchants cannot be re-activated. You must first assign an active merchant to this user.',
          type: 'is-danger',
          hasIcon: true,
          icon: 'exclamation-triangle'
        });
      },

      confirmActivationChange() {
        if (!this.user.active && this.hasOnlyDeactivatedMerchants) this.openNoActiveMerchantsAlert();
        else {
          const titleAndButtonText = this.user.active ? 'Deactivate User' : 'Activate User';
          const message = this.user.active
            ? `${this.user.fullName} will no longer be able to log in and use their account.`
            : `${this.user.fullName} will be able to log in to the portal and start using their account.`;
          const onClick = this.user.active ? this.deactivateUser : this.activateUser;

          this.$buefy.modal.open({
            parent: this,
            component: alertModal,
            hasModalCard: true,
            trapFocus: true,
            canCancel: ['escape', 'outside'],
            customClass: 'auto-width',
            props: {
              title: titleAndButtonText,
              message,
              secondaryMessage: 'Are you sure you would like to proceed?',
              horizontal: true,
              showCloseButton: false,
              icon: this.user.active ? 'ban' : 'bolt',
              type: this.user.active ? 'is-danger' : 'is-success',
              buttons: [
                { text: 'Cancel' },
                { text: titleAndButtonText, primary: true, onClick }
              ]
            }
          });
        }
      }
    }
  };
</script>
