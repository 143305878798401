<template>
  <steps-modal
    ref="offerStepsModal"
    v-model="form"
    title="Offer Builder"
    :steps="steps"
    :loading="isSubmitting"
    :mode="offer.guid ? 'update' : 'create'"
    :show-submit-button="!offer.isExpired"
    confirm-close
    :message="offer.isLive ? 'Only offer details can be edited once moved from draft state and finalized' : ''"
    message-type="is-warning"
    @valid-submit="confirmFinalizeOffer"
    @close="$parent.close()"
  >
    <template #actions>
      <b-button
        v-if="!offer.isExpired"
        :loading="isSubmitting"
        icon-right="save"
        rounded
        @click="() => handleSave(true)"
      >
        {{ offer.isLive ? 'Update Offer' : 'Save Draft' }}
      </b-button>
    </template>
    <template #submit-button>
      <b-button
        v-if="!offer.isLive"
        type="is-primary"
        icon-right="check"
        rounded
        native-type="submit"
        :disabled="offer.isExpired"
      >
        Finalize Offer
      </b-button>
    </template>
  </steps-modal>
</template>

<script>
  import Offer from '@/store/classes/Offer';
  import ItemGroup from '@/helpers/classes/ItemGroup';
  import detailsStep from './modal-steps/offer-details-step.vue';
  import typeStep from './modal-steps/offer-type-step.vue';
  import timeframeStep from './modal-steps/offer-timeframe-step.vue';
  import reviewStep from './modal-steps/offer-review-step.vue';
  import alertModal from '@/components/globals/alert-modal.vue';
  import { constraintTypes, valuePropositionTypes } from '@/constants/offers';
  import { itemGroupTypes } from '@/constants/items';
  import merchantMixin from '@/mixins/merchant';

  export default {
    name: 'OfferModal',

    mixins: [merchantMixin],


    props: {
      offer: {
        type: Object,
        required: true
      }
    },

    data() {
      return {
        form: {},
        submittingItemGroups: false
      };
    },

    computed: {
      isLocalEnvironment() {
        return window.location.hostname === 'localhost';
      },

      isSubmitting() {
        return Offer.$state().submitting || this.submittingItemGroups;
      },

      merchantSupportsMultiPos() {
        return this.$_selectedMerchant.features.supportsMultiPosConstraintsAndValuePropositions;
      },

      steps() {
        return [
          {
            label: 'Details',
            component: detailsStep,
            props: { offerReadOnly: this.offer.isExpired }
          },
          {
            label: 'Type',
            component: typeStep,
            props: { offerReadOnly: this.offer.isLive || this.offer.isExpired }
          },
          {
            label: 'Time Frame',
            component: timeframeStep,
            props: { offerReadOnly: this.offer.isLive || this.offer.isExpired }
          },
          {
            label: 'Review',
            component: reviewStep,
            props: { offerDetailsAreReadOnly: this.offer.isExpired, offerReadOnly: !this.offer.isDraft }
          }
        ];
      }
    },

    created() {
      this.onCreated();
    },

    methods: {
      onCreated() {
        this.form = this.$clone(this.offer);
        this.setItemGroups();
      },

      setItemGroups() {
        // Cast all the item group data as instances of the ItemGroup class to make management easier
        // while we wait on refactoring this suite to have all VuexORM classes
        const isItemTypeValueProp = [
          valuePropositionTypes.ITEM.DOLLAR,
          valuePropositionTypes.ITEM.PERCENT,
          valuePropositionTypes.ITEM.OVERRIDE
        ].some(type => this.form.valueProposition?.valuePropositionType === type);
        if (isItemTypeValueProp && this.form.valueProposition?.itemGroup) {
          this.form.valueProposition.itemGroup = new ItemGroup(this.form.valueProposition.itemGroup);
        }
        if (this.form.constraints?.length) {
          this.form.constraints = this.form.constraints.map((constraint) => {
            if (constraint.constraintType === constraintTypes.ITEM_OFFER) {
              constraint.itemGroup = new ItemGroup(constraint.itemGroup);
            }
            return constraint;
          });
        }
      },

      confirmFinalizeOffer() {
        this.$buefy.modal.open({
          parent: this,
          component: alertModal,
          hasModalCard: true,
          trapFocus: true,
          canCancel: ['escape', 'outside'],
          props: {
            icon: 'check',
            title: 'Finalize Offer',
            message: 'Once you finalize your offer, it will no longer be editable.',
            secondaryMessage: 'Are you sure you\'d like to finalize this offer?',
            type: 'is-warning',
            horizontal: true,
            showCloseButton: false,
            buttons: [
              { text: 'Cancel' },
              {
                text: 'Finalize',
                primary: true,
                onClick: this.finalizeOffer
              }
            ]
          }
        });
      },

      async finalizeOffer() {
        try {
          const offerGuid = await this.handleSave();
          await Offer.finalizeOffer(offerGuid);

          this.$_onRequestSuccess({
            toastOptions: {
              message: 'Successfully finalized your offer!'
            },
            options: { closeParent: true }
          });
        }

        catch (error) {
          this.$_onRequestError({
            toastOptions: {
              message: 'There was an error setting your offer state'
            },
            error
          });
          throw error;
        }
      },

      async handleUpdateCoupons() {
        try {
          const coupons = [...this.form.coupons];
          const getCoupon = ({ channel, couponImageUrl }) => ({
            displayChannelName: channel,
            displayChannelDisplayName: channel === 'email' ? 'Email' : 'Loyalty Reward Tier',
            // NOTE: always store relative path for coupon image since Loyalty API requires it
            defaultImagePath: this.isLocalEnvironment
              ? couponImageUrl.split('localhost:3000/')[1]
              : couponImageUrl.split('.net/')[1] || couponImageUrl.split('.com/')[1]
          });

          if (this.form.tempEmailImageFile) {
            const { couponImageUrl: emailImageUrl } = await Offer.createCouponImage({
              offerImage: this.form.tempEmailImageFile,
              channel: 'email'
            });
            coupons.push(getCoupon({ channel: 'email', couponImageUrl: emailImageUrl }));
          }

          if (this.form.tempLoyaltyImageFile) {
            const { couponImageUrl: loyaltyImageUrl } = await Offer.createCouponImage({
              offerImage: this.form.tempLoyaltyImageFile,
              channel: 'loyaltyRewardTier'
            });
            coupons.push(getCoupon({ channel: 'loyaltyRewardTier', couponImageUrl: loyaltyImageUrl }));
          }

          this.form.coupons = coupons;
        }
        catch (error) {
          throw error;
        }
      },

      async handleItemGroup(itemGroup) {
        if (typeof itemGroup.publicId !== 'symbol' && !itemGroup.hasChanged()) {
          return itemGroup;
        }
        else {
          try {
            return ItemGroup.createOfferItemGroup(this.$_selectedMerchantId, itemGroup);
          }
          catch (error) {
            throw error;
          }
        }
      },

      async createFormPayload() {
        const constraints = await Promise.all(this.$clone(this.form.constraints).map(async (constraint) => {
          if (constraint.constraintType === constraintTypes.ITEM_OFFER) {
            const itemGroup = await this.handleItemGroup(constraint.itemGroup);
            const updatedConstraint = {
              ...constraint,
              itemGroupPublicId: itemGroup.publicId
            };
            return updatedConstraint;
          }
          else {
            return constraint;
          }
        }));

        if (this.form.valueProposition.itemGroup) {
          const shouldHandleMultiPosValueProposition = this.merchantSupportsMultiPos
            && Object.values(valuePropositionTypes.ITEM).includes(this.form.valueProposition.valuePropositionType)
            && this.form.valueProposition.itemGroup.itemGroupType === itemGroupTypes.MENU_ITEMS;
          if (shouldHandleMultiPosValueProposition) {
            this.form.valueProposition = await this.createMultiPosValueProposition();
          }
          else {
            const valuePropItemGroup = await this.handleItemGroup(this.form.valueProposition.itemGroup);
            this.form.valueProposition = {
              ...this.form.valueProposition,
              itemGroupPublicId: valuePropItemGroup.publicId
            };
          }
        }

        return {
          ...this.form,
          valueProposition: {
            ...this.form.valueProposition
          },
          constraints
        };
      },

      async createMultiPosValueProposition() {
        const updatedValueProposition = this.$clone(this.form.valueProposition);
        const baseItemGroup = updatedValueProposition.itemGroup;

        const posTypeGroups = baseItemGroup.posItems.reduce((groups, posItem) => {
          const posType = posItem.posType;
          if (!groups[posType]) {
            groups[posType] = {
              posType,
              menuItems: [],
              menuItemIds: []
            };
          }

          const menuItem = baseItemGroup.menuItems.find(item => item.id === posItem.menuItemId);
          if (menuItem && !groups[posType].menuItemIds.includes(menuItem.id)) {
            groups[posType].menuItems.push(menuItem);
            groups[posType].menuItemIds.push(menuItem.id);
          }

          return groups;
        }, {});

        const valuePropositions = await Promise.all(
          Object.values(posTypeGroups).map(async (group) => {
            const newItemGroup = new ItemGroup({
              itemGroupType: itemGroupTypes.MENU_ITEMS,
              menuItems: group.menuItems,
              menuItemIds: group.menuItemIds,
              excludedItemAttributes: baseItemGroup.excludedItemAttributes || [],
              // TODO: Filter requiredModifications according to which menu item they belong to
              requiredModifications: baseItemGroup.requiredModifications || []
            });

            const createdItemGroup = await this.handleItemGroup(newItemGroup);

            return {
              ...this.getSharedValuePropositionAttributes(updatedValueProposition),
              posType: group.posType,
              itemGroupPublicId: createdItemGroup.publicId,
              itemGroup: createdItemGroup
            };
          })
        );

        return {
          valuePropositionType: 'PosSpecifiedItemDiscountsModel',
          valuePropositions,
          excludedItemAttributes: baseItemGroup.excludedItemAttributes || []
        };
      },

      getSharedValuePropositionAttributes(baseValueProposition) {
        return {
          maximumDiscountedQuantity: baseValueProposition.maximumDiscountedQuantity,
          discountedUnitPrecedenceType: baseValueProposition.discountedUnitPrecedenceType,
          itemDiscountBasis: baseValueProposition.itemDiscountBasis,
          valuePropositionType: baseValueProposition.valuePropositionType,
          dollarDiscount: baseValueProposition.dollarDiscount,
          percentDiscount: baseValueProposition.percentDiscount,
          discountedPricePerUnit: baseValueProposition.discountedPricePerUnit
        };
      },

      createOfferInstance(offer) {
        return new Offer(offer);
      },

      async handleSave(isSavingDraft) {
        // NOTE: Run validations at all times, even though this runs twice when finalizing offer
        // The finalize button runs the validated form's validations and then this validation again (but no difference to user)
        // By passing true here, it's telling validated form to emit the 'valid-submit' event to kick off the valid-submit handler
        // By passing false here, this just runs and doesn't run handler in case of valid submit (save draft)
        // For Finalize Offer, validations will have already run, so we can assume this will always be valid if it runs again
        const isValid = await this.$refs.offerStepsModal.$children[0].handleSubmit({ shouldSkipValidSubmitEmission: true });

        if (isValid) {
          try {
            if (this.form.tempEmailImageFile || this.form.tempLoyaltyImageFile) {
              await this.handleUpdateCoupons();
            }

            let offer = this.offer;

            this.isSubmittingItemGroups = true;
            const formPayload = await this.createFormPayload();
            this.isSubmittingItemGroups = false;

            if (this.form.guid) {
              await Offer.updateOffer(formPayload);
            }
            else {
              offer = await Offer.createOffer(formPayload);
              this.form = this.createOfferInstance(offer);
              this.setItemGroups();
            }

            if (isSavingDraft) {
              this.$_onRequestSuccess({
                toastOptions: {
                  message: 'Successfully saved your offer!'
                },
                options: { closeParent: true }
              });
            }

            return offer.guid;
          }
          catch (error) {
            this.$_onRequestError({
              error,
              toastOptions: { message: 'There was an error saving your offer' }
            });
          }
        }
      }
    }
  };
</script>
