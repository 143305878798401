<template>
  <div class="is-full-width" data-test-id="random-award-inputs">
    <div class="dist-y-sm">
      <div
        v-for="(awardOption, index) in value.awardPolicy.weightedAwardOptions"
        :key="`award-option-${index}`"
        class="is-flex align-start gap"
      >
        <validated-text-input
          v-model="value.awardPolicy.weightedAwardOptions[index].weight"
          type="number"
          name="weight"
          label="Weight"
          :hide-label="index > 0"
          placeholder="Enter number"
          rules="required|numeric|min_value:1"
          class="mar-none weight-input"
          tooltip="Weights are relative values. For example, if Reward A has a weight of 1 and Reward B has a weight of 2, Reward 2 will be granted twice as often as Reward A."
          data-test-class="weight-input"
        />
        <validated-input
          class="dropdown-field mar-none"
          name="randomAwardOptionType"
          label="Award"
          :hide-label="index > 0"
          rules="required"
          data-test-id="random-award-option-type-select"
        >
          <b-select
            v-model="value.awardPolicy.weightedAwardOptions[index].awardOption.awardOptionType"
            placeholder="Select an award type..."
            class="is-inline-block"
            expanded
            @input="handleAwardOptionTypeChange($event, index)"
          >
            <option :value="null" disabled>-</option>
            <option
              :key="randomAwardOptionTypes.OFFER"
              :value="randomAwardOptionTypes.OFFER"
            >
              Offer
            </option>
            <option
              :key="randomAwardOptionTypes.POINTS"
              :value="randomAwardOptionTypes.POINTS"
            >
              {{ primaryCurrency.pluralName | capitalize }}
            </option>
          </b-select>
        </validated-input>
        <random-offer-award-dropdown
          v-if="value.awardPolicy.weightedAwardOptions[index].awardOption.awardOptionType === randomAwardOptionTypes.OFFER"
          :selected-offer-public-id="getSelectedOfferPublicId(value.awardPolicy.weightedAwardOptions[index].awardOption.reward)"
          :resource-end-date="value.endDate"
          :index="index"
          :offers="filteredOffers"
          :selected-offer-public-ids="selectedOfferPublicIds"
          :read-only="readOnly"
          @update-offer-public-id="handleUpdateOfferPublicId($event, index)"
        />
        <validated-text-input
          v-if="value.awardPolicy.weightedAwardOptions[index].awardOption.awardOptionType === randomAwardOptionTypes.POINTS"
          v-model="value.awardPolicy.weightedAwardOptions[index].awardOption.points"
          type="float"
          label="Points"
          :name="`points${index}`"
          :hide-label="index > 0"
          rules="required|numeric|min_value:0.01"
          class="mar-none flex-grow"
          placeholder="Enter points"
          data-test-class="points-input"
          @blur="(value) => handleAwardPointsInputBlur(value, index)"
        />
        <b-button
          v-if="value.awardPolicy.weightedAwardOptions.length > 2"
          style="height: unset"
          type="is-danger is-light"
          data-test-class="remove-award-button"
          :class="{ 'delete-icon-offset': index === 0 }"
          @click="removeAward(index)"
        >
          <b-icon icon="trash-alt" />
        </b-button>
      </div>
      <template v-if="!readOnly">
        <b-button
          type="is-primary is-light"
          icon-left="plus"
          @click="addAward"
        >
          Award
        </b-button>
        <b-button
          type="is-danger is-light"
          icon-left="trash-alt"
          class="mar-l-md"
          @click="clearAwards"
        >
          Clear All Awards
        </b-button>
      </template>
    </div>
  </div>
</template>

<script>
  import moment from 'moment-timezone';

  import { offerStates } from '@/constants/offers';
  import { randomAwardOptionTypes, randomAwardOptionTypesDefaultData } from '@/constants/earnRules';

  import Currency from '@/store/classes/Currency';
  import Offer from '@/store/classes/Offer';

  import merchantMixin from '@/mixins/merchant';

  import RandomOfferAwardDropdown from './random-offer-award-dropdown.vue';


  export default {
    name: 'RandomAwardInputs',

    components: { RandomOfferAwardDropdown },

    mixins: [merchantMixin],

    props: {
      // value can be EarnRule or ConversionThreshold
      value: {
        type: Object,
        required: true
      },
      readOnly: {
        type: Boolean,
        default: false
      }
    },

    data() {
      return {
        randomAwardOptionTypes
      };
    },

    computed: {
      allOffers() {
        return Offer.query()
          .orderBy('state', 'desc')
          .orderBy(({ name }) => name.toLowerCase())
          .get();
      },

      filteredOffers() {
        return this.allOffers
          .filter(offer => [offerStates.LIVE].includes(offer.state))
          .map((offer) => {
            const { campaignsIanaTimezoneId } = this.$_selectedMerchant.features;
            const formatDate = date => moment.tz(date, campaignsIanaTimezoneId).format('MMM DD, YYYY');
            const isFuture = moment().isBefore(offer.redemptionsAllowedStartDate);

            let footer = 'Redemptions ';

            if (offer.redemptionsAllowedEndDate) {
              footer += 'allowed ';
              footer += `${isFuture ? `from ${formatDate(offer.redemptionsAllowedStartDate)} through` : 'until'} ${formatDate(offer.redemptionsAllowedEndDate)}`;
            }
            // If offer has no end date
            else {
              footer += 'have no end date';
            }

            return {
              description: offer.description,
              footer,
              id: offer.guid,
              name: offer.name,
              redemptionsAllowedEndDate: offer.redemptionsAllowedEndDate
            };
          });
      },

      selectedOfferPublicIds() {
        return this.value.awardPolicy.weightedAwardOptions
          .map(({ awardOption }) => awardOption.reward?.cardfreeOfferPublicId);
      },

      primaryCurrency() {
        return Currency.primaryCurrency();
      }
    },

    methods: {
      addAward() {
        const newAward = {
          awardOption: this.$clone(randomAwardOptionTypesDefaultData.OFFER),
          weight: 1
        };

        this.updateAwards([
          ...this.value.awardPolicy.weightedAwardOptions,
          newAward
        ]);
      },

      removeAward(awardIndex) {
        this.updateAwards(
          this.value.awardPolicy.weightedAwardOptions.filter((_, index) => index !== awardIndex)
        );
      },

      clearAwards() {
        this.updateAwards([
          {
            awardOption: this.$clone(randomAwardOptionTypesDefaultData.OFFER),
            weight: 1
          },
          {
            awardOption: this.$clone(randomAwardOptionTypesDefaultData.OFFER),
            weight: 1
          }
        ]);
      },

      handleAwardOptionTypeChange(value, index) {
        const updatedAwards = this.value.awardPolicy.weightedAwardOptions.map((awardOption, awardIndex) => {
          if (awardIndex === index) {
            if (value === randomAwardOptionTypes.OFFER) {
              awardOption.awardOption = this.$clone(randomAwardOptionTypesDefaultData.OFFER);
            }
            else {
              awardOption.awardOption = { ...randomAwardOptionTypesDefaultData.POINTS(this.primaryCurrency.publicId) };
            }
          }
          return awardOption;
        });

        this.updateAwards(updatedAwards);
      },

      getSelectedOfferPublicId(reward) {
        return reward ? reward.cardfreeOfferPublicId : null;
      },

      handleUpdateOfferPublicId(offerId, index) {
        const updatedAwards = this.value.awardPolicy.weightedAwardOptions.map((awardOption, awardIndex) => {
          if (awardIndex === index) {
            awardOption.awardOption.reward.cardfreeOfferPublicId = offerId;
          }
          return awardOption;
        });

        this.updateAwards(updatedAwards);
      },

      updateAwards(awards) {
        this.$emit('update-awards', awards);
      },

      handleAwardPointsInputBlur(value, index) {
        const inputValue = parseFloat(value);
        if (!Number.isNaN(inputValue)) {
          const formattedValue = Number.isInteger(inputValue) ? inputValue : parseFloat(inputValue.toFixed(2));

          const updatedAwards = this.value.awardPolicy.weightedAwardOptions.map((awardOption, awardIndex) => {
            if (awardIndex === index) {
              awardOption.awardOption.points = formattedValue;
            }
            return awardOption;
          });

          this.updateAwards(updatedAwards);
        }
      }
    }
  };
</script>

<style lang="sass" scoped>
  .weight-input
    width: 85px

  .delete-icon-offset
    margin-top: 2rem
</style>
