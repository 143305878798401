<template>
  <div class="mar-b is-grid col-7 gap-x align-center">
    <div
      v-tippy="{ content: `Open ${name}`, placement: 'top' }"
      class="is-clickable menu-item-tag-name is-size-6 has-radius-large has-border has-border-grey-lighter pad-sm has-background-white-bis col-span-6 is-flex justify-between"
      @click="openItemModal"
    >
      <span>{{ name }}</span>
      <b-icon icon="arrow-up-right-from-square" />
    </div>
    <a
      v-tippy="{ content: `Remove <b>${menuAttribute.name}</b> from ${name}`, placement: 'left' }"
      class="justify-self-center align-self-center"
      @click="confirmRemoveItemFromTag"
    >
      <b-icon
        :class="['remove-button', { 'spin': isDeleting }]"
        :icon="isDeleting ? 'spinner-third' : 'circle-minus'"
        type="is-danger"
      />
    </a>
  </div>
</template>

<script>
  import MenuItemAttribute from '@/store/classes/MenuItemAttribute';
  import Category from '@/store/classes/Category';
  import Item from '@/store/classes/Item';
  import alertModal from '@/components/globals/alert-modal.vue';
  import addEditItemModal from '@/components/pages/menu-management/main-menu/add-edit-item-modal/index.vue';

  export default {
    name: 'MenuItemTag',

    props: {
      menuAttribute: {
        type: Object,
        required: true
      },

      menuItemAttribute: {
        type: Object,
        required: true
      }
    },

    computed: {
      name() {
        return this.menuItemAttribute.menuItem.displayName;
      },

      isDeleting() {
        return MenuItemAttribute.$state().deleting;
      }
    },

    methods: {
      async openItemModal() {
        const categoryId = this.menuItemAttribute.menuItem.menuCategories[0].id;
        const itemId = this.menuItemAttribute.menuItemId;
        await Promise.all([Category.fetchCategories(), Item.fetchItem(itemId)]);
        const category = Category.find(categoryId);

        this.$buefy.modal.open({
          parent: this,
          component: addEditItemModal,
          hasModalCard: true,
          trapFocus: true,
          canCancel: false,
          props: {
            category,
            mode: 'update',
            itemId,
            defaultTabIndex: 2,
            scrollTo: 'item-tags'
          }
        });
      },

      confirmRemoveItemFromTag() {
        this.$buefy.modal.open({
          parent: this,
          component: alertModal,
          hasModalCard: true,
          trapFocus: true,
          canCancel: ['escape', 'outside'],
          customClass: 'auto-width',
          props: {
            title: 'Remove Menu Tag',
            message: `Are you sure you want to remove the <b>${this.menuAttribute.name}</b> tag from the item <b>${this.name}</b>?`,
            showCloseButton: false,
            horizontal: true,
            icon: 'trash-alt',
            type: 'is-danger',
            buttons: [
              { text: 'Cancel' },
              { text: 'Yes, Remove Tag', primary: true, onClick: this.removeItemFromTag }
            ]
          }
        });
      },

      async removeItemFromTag() {
        try {
          await MenuItemAttribute.deleteMenuItemAttributes({
            itemId: this.menuItemAttribute.menuItemId,
            menuAttributeIds: [this.menuItemAttribute.menuAttributeId]
          });
          this.$_onRequestSuccess({
            toastOptions: {
              message: 'Successfully removed tag from item'
            }
          });
        }
        catch (error) {
          this.$_onRequestError({
            error,
            toastOptions: {
              message: 'There was an error removing the tag from the item'
            }
          });
        }
      }
    }
  };
</script>

<style lang='sass' scoped>
  .remove-button
    &:hover
      color: lighten($danger, 10) !important

  .menu-item-tag-name
    &:hover
      border-color: $grey-darker !important

      ::v-deep.icon
        color: $grey-light !important
</style>
