import http from '@/services/http';

export default async function sendMessage({ messageType, resourceType, id, values }) {
  try {
    const urlsByResourceType = {
      employee: `employees/${id}/qr_code_message`,
      department: `departments/${id}/qr_code_message`
    };
    await http.post(urlsByResourceType[resourceType], { [messageType]: values });
  }
  catch (error) {
    throw error;
  }
}
