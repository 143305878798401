<template>
  <div class="user detail-page">
    <div class="card">
      <div class="card-content">
        <user-form mode="update" />
      </div>
    </div>
  </div>
</template>

<script>
  import userForm from './user-form.vue';

  export default {
    name: 'UserDetail',

    components: {
      userForm
    }
  };
</script>
