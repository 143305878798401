/* eslint-disable import/no-cycle */
import { Model } from '@vuex-orm/core';


export default class SurveyQuestion extends Model {
  static entity = 'surveyQuestions'

  static fields() {
    return {
      id: this.uid(),
      surveyId: this.attr(''),
      questionType: this.attr(''),
      answerType: this.attr(''),
      label: this.attr(''),
      isActive: this.attr(true),
      sortOrder: this.attr(''),
      required: this.attr(false),
      alwaysInclude: this.attr(false),
      options: this.attr([]),

      // FE Properties
      isUnsaved: this.attr(false)

      // EX QUESTION => {
      //   "id": 1253,
      //   "questionType": "RadioList",
      //   "answerType": "Text",
      //   "label": "Do you like hot sauce?",
      //   "isActive": true,
      //   "sortOrder": 4,
      //   "required": true,
      //   "options": [
      //       {
      //           "id": 330,
      //           "questionId": 1253,
      //           "value": "Yes",
      //           "isActive": true,
      //           "sortOrder": 0
      //       },
      //       {
      //           "id": 331,
      //           "questionId": 1253,
      //           "value": "No",
      //           "isActive": true,
      //           "sortOrder": 1
      //       }
      //   ]
      // }
    };
  }

  static $state() {
    return this.store().state.entities.surveyQuestions;
  }
}
