<template>
  <transition name="fade-down">

    <validated-form
      v-if="form"
      v-slot="{ dirty }"
      form-id="orderModeForm"
      class="dist-y-lg"
      :disabled="!canEdit('openPickupTimeOffset')"
      @valid-submit="handleSubmit"
    >
      <panel title="Pickup Time" collapsible start-open :loading="isFetching">
        <template #buttons>
          <b-button
            native-type="submit"
            :loading="isSubmitting"
            type="is-primary"
            rounded
            size="is-medium"
            :class="['is-bold', dirty && 'pulse-slow']"
          >
            Save
          </b-button>
        </template>
        <div class="is-grid col-1-tablet gap-y-lg">
          <div class="is-grid col-2 gap-x-lg">
            <validated-input
              expanded
              name="openPickupTimeOffset"
              label="Open Offset"
              sub-label="Amount of time after opening that pickup orders start being accepted"
            >
              <b-select
                v-model="form.openPickupTimeOffset"
                expanded
                placeholder="Select an offset amount..."
              >
                <option :value="null">-</option>
                <option v-for="{ display, value } in timeOffsets" :key="value" :value="value">
                  {{ display }}
                </option>
                <option disabled>──────</option>
                <option value="custom">Custom</option>
              </b-select>
              <transition name="fade-right">
                <validated-text-input
                  v-if="isCustomTime(form.openPickupTimeOffset)"
                  v-model="customInputs.openPickupTimeOffset"
                  type="time"
                  :class="['control', { 'mar-l-xs': !canEdit('openPickupTimeOffset') }]"
                  name="customOpenTimeOffset"
                  label="Custom Open Time Offset"
                  placeholder="hh:mm:ss"
                  hide-label
                  status-icon
                  rules="required"
                  :disabled="!canEdit('openPickupTimeOffset')"
                />
              </transition>
            </validated-input>

            <validated-input
              expanded
              name="closePickupTimeOffset"
              label="Close Offset"
              sub-label="Amount of time before closing that pickup orders stop being accepted"
            >
              <b-select
                v-model="form.closePickupTimeOffset"
                expanded
                placeholder="Select an offset amount..."
              >
                <option :value="null">-</option>
                <option v-for="{ display, value } in timeOffsets" :key="value" :value="value">
                  {{ display }}
                </option>
                <option disabled>──────</option>
                <option value="custom">Custom</option>
              </b-select>
              <transition name="fade-right">
                <validated-text-input
                  v-if="isCustomTime(form.closePickupTimeOffset)"
                  v-model="customInputs.closePickupTimeOffset"
                  type="time"
                  :class="['control', { 'mar-l-xs': !canEdit('openPickupTimeOffset') }]"
                  name="customCloseTimeOffset"
                  label="Custom Close Time Offset"
                  placeholder="hh:mm:ss"
                  hide-label
                  status-icon
                  rules="required"
                />
              </transition>
            </validated-input>
          </div>
          <div class="is-grid col-2 gap-x-lg">
            <check-button
              v-if="merchantHasAsapPickupOnly"
              v-model="asapPickupOnly"
              class="is-marginless"
              label="Asap Pickup Only"
              sublabel="Guests are given the soonest pickup time available"
            />
          </div>
          <transition name="fade-down">
            <b-field v-show="!asapPickupOnly">
              <template #label>
                <p>Maximum Order Ahead Days</p>
                <p class="sub-label">Maximum number of days in advance a guest can place an order</p>
              </template>
              <numberinput-switch
                v-model="maximumOrderAheadDays"
                switch-label="Same Day Only"
                :disabled="!canEdit('openPickupTimeOffset')"
                :true-value="0"
                :false-value="6"
              />
            </b-field>
          </transition>
        </div>
      </panel>
    </validated-form>
  </transition>
</template>

<script>
  import appSettingResourceMap from '@/constants/merchantAppSettingResources';
  import MerchantAppSettingResource from '@/store/classes/MerchantAppSettingResource';
  import Store from '@/store/classes/Store';
  import storeMappingAttributeConstants from '@/constants/storeMappingAttributes';
  import StoreAttribute from '@/store/classes/StoreAttribute';

  export default {
    name: 'StorePickupTimeOffsets',

    props: {
      store: {
        type: Object,
        required: true
      }
    },

    data() {
      return {
        form: {},
        timeOffsets: [
          { display: '0 minutes', value: '00:00:00' },
          { display: '5 minutes', value: '00:05:00' },
          { display: '10 minutes', value: '00:10:00' },
          { display: '15 minutes', value: '00:15:00' },
          { display: '20 minutes', value: '00:20:00' },
          { display: '25 minutes', value: '00:25:00' },
          { display: '30 minutes', value: '00:30:00' }
        ],
        customInputs: {
          openPickupTimeOffset: null,
          closePickupTimeOffset: null
        },
        isSubmitting: false
      };
    },

    computed: {
      asapPickupOnly: {
        get() {
          return this.form.storeMappingAttributes.find(sma => sma.code === storeMappingAttributeConstants.ASAP_PICKUP_ONLY.code)?.isActive;
        },

        set(value) {
          const index = this.form.storeMappingAttributes?.findIndex(sma => sma.code === storeMappingAttributeConstants.ASAP_PICKUP_ONLY.code);
          const updated = this.$clone(this.form.storeMappingAttributes);
          const { name, code } = storeMappingAttributeConstants.ASAP_PICKUP_ONLY;

          if (index < 0) {
            updated.push({
              storeId: this.store.storeId,
              storeAttributeId: StoreAttribute.getStoreAttributeByCode(code)?.id,
              name,
              code,
              isActive: value
            });
          }
          else {
            updated[index].isActive = value;
          }

          this.form.storeMappingAttributes = updated;
        }
      },

      appSettingResources() {
        return MerchantAppSettingResource.query().where('merchantId', this.store.merchantId).get();
      },
      merchantHasAsapPickupOnly() {
        return this.appSettingResources.filter(resource => resource.key === appSettingResourceMap.ASAP_PICKUP_ONLY)
          .some(setting => !!setting.value);
      },
      isFetching() {
        return Store.$state().fetchingAll;
      },
      // CAPI treats both 0 and 1 as "Same Day Only" ordering, and 2 as "today and tomorrow".
      // If we implemented this on the page as such, it creates a bit of a misdirection for
      // the user (e.g. If a user wants a guest to be able to order 2 days in advance, they
      // would need to select 3). To control for this, we will subtract 1 when getting
      // maximumOrderAheadDays and add 1 when setting it to create a smoother UX.
      maximumOrderAheadDays: {
        get() {
          return this.form.maximumOrderAheadDays - 1;
        },
        set(newValue) {
          this.form.maximumOrderAheadDays = newValue + 1;
        }
      }
    },

    created() {
      this.onCreated();
    },

    methods: {
      onCreated() {
        this.form = this.$clone(this.store);
        this.setCustomInputs();
      },

      toggleLoading(value) {
        this.isSubmitting = value;
      },

      canEdit(field) {
        return this.$can('update', 'StoreMapping', field);
      },

      isCustomTime(value) {
        return !!value && !this.timeOffsets.find(timeOffset => timeOffset.value === value);
      },

      setCustomInputs() {
        if (this.isCustomTime(this.store.openPickupTimeOffset)) {
          this.form.openPickupTimeOffset = 'custom';
          this.customInputs.openPickupTimeOffset = this.store.openPickupTimeOffset;
        }
        if (this.isCustomTime(this.store.closePickupTimeOffset)) {
          this.form.closePickupTimeOffset = 'custom';
          this.customInputs.closePickupTimeOffset = this.store.closePickupTimeOffset;
        }
      },

      handleCustomInputsForRequest() {
        if (this.form.openPickupTimeOffset === 'custom') {
          this.form.openPickupTimeOffset = this.customInputs.openPickupTimeOffset;
        }
        if (this.form.closePickupTimeOffset === 'custom') {
          this.form.closePickupTimeOffset = this.customInputs.closePickupTimeOffset;
        }
      },

      async handleSubmit() {
        try {
          this.toggleLoading(true);

          this.handleCustomInputsForRequest();
          await Store.updateStore(this.form);
          this.setCustomInputs();

          this.$_onRequestSuccess({
            toastOptions: {
              message: 'Pickup time offsets successfully updated!'
            }
          });
        }
        catch (error) {
          this.$_onRequestError({
            toastOptions: {
              message: 'There was an issue updating your pickup time offsets'
            },
            error
          });
        }
        finally {
          this.toggleLoading(false);
        }
      }
    }
  };
</script>
