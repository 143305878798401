import { Model } from '@vuex-orm/core';
import http from '@/services/http';
import capitalCase from '@/helpers/capitalCase';

export default class MerchantType extends Model {
  static entity = 'merchantTypes';

  static typeIds = {
    STANDARD: 1,
    SELF_SERVICE: 2,
    COCA_COLA: 3,
    SUNDRY: 4
  }

  static fields() {
    return {
      id: this.attr(''),
      name: this.attr('', name => capitalCase(name)),
      description: this.attr('')
    };
  }

  // STATE
  static state() {
    return {
      fetching: false
    };
  }

  static $state() {
    return this.store().state.entities.merchantTypes;
  }

  static async fetchMerchantTypes() {
    try {
      this.commit((state) => {
        state.fetching = true;
      });

      const response = await http.get('merchant_types');

      this.insert({ data: response.data.merchantTypes });
    }

    catch (error) {
      throw error;
    }

    finally {
      this.commit((state) => {
        state.fetching = false;
      });
    }
  }
}
