<template>
  <section>
    <div
      v-for="payment in order.payments"
      :key="payment.id"
      class="dist-y-xs"
      data-test-class="payment-row"
    >
      <div class="is-flex justify-between">
        <div class="has-text-weight-semibold is-flex align-center">
          <payment-icon class="mar-r-sm" :payment="payment" show-fallback-icon />
          ···· {{ payment.cardNumberLastFour }}
        </div>
        <div>
          {{ payment.localPaymentDate | moment('MMM Do, YYYY / h:mm A z') }}
        </div>
      </div>
      <div class="is-flex align-center gap">
        <span class="has-text-grey-light is-size-7">Transaction ID</span>
        <b-button
          size="is-small"
          type="is-light is-primary"
          rounded
          inverted
          @click="openPaymentTransactionModal(payment.transactions[0])"
        >
          {{ payment.transactions[0].paymentTransactionId }}
        </b-button>
      </div>
    </div>
  </section>
</template>

<script>
  import PaymentTransactionModal from '@/components/pages/payment-transactions/payment-transaction-modal.vue';

  import PaymentTransaction from '@/store/classes/PaymentTransaction';

  export default {
    name: 'ExpoOrderPaymentDetail',

    props: {
      order: {
        type: Object,
        required: true
      }
    },

    computed: {
      paymentTransactionId() {
        return this.order.payments[0].transactions[0].paymentTransactionId || 'No transaction ID';
      }
    },

    methods: {
      async getPaymentTransactionById(id) {
        try {
          await PaymentTransaction.getPaymentTransactionById(id);
        }
        catch (error) {
          this.$_onRequestError({
            error,
            toastOptions: { message: 'There was an error loading the transaction' }
          });
        }
      },

      async openPaymentTransactionModal(paymentTransaction) {
        await this.getPaymentTransactionById(paymentTransaction.paymentTransactionId);

        this.$buefy.modal.open({
          parent: this,
          component: PaymentTransactionModal,
          hasModalCard: true,
          trapFocus: true,
          canCancel: false,
          customClass: 'auto-width',
          props: {
            transactionId: paymentTransaction.paymentTransactionId,
            summary: paymentTransaction
          }
        });
      }
    }
  };
</script>
