import { Model } from '@vuex-orm/core';
import http from '@/services/http';
import filterObjectKeys from '@/helpers/filter-object-keys';
import StoreOrderLimitSchedule from './StoreOrderLimitSchedule';


export default class StoreOrderLimit extends Model {
  static entity = 'storeOrderLimits'



  // FIELDS //////////////////////
  static fields() {
    return {
      id: this.attr(null),
      storeId: this.attr(null),
      merchantId: this.attr(null),
      menuTypeId: this.attr(null),
      platformTypeId: this.attr(null),
      orderLimit: this.number(0),
      storeOrderLimitSchedules: this.hasMany(StoreOrderLimitSchedule, 'storeOrderLimitId')
    };
  }

  // STATE //////////////////////
  static state() {
    return {
      fetching: false,
      submitting: false
    };
  }

  static $state() {
    return this.store().state.entities.storeOrderLimits;
  }


  // ACTIONS //////////////////////
  static async fetchStoreOrderLimits(storeId) {
    try {
      this.commit((state) => {
        state.fetching = true;
      });

      const { data } = await http.get(`stores/${storeId}/store_order_limits`);

      this.insert({
        data: data.storeOrderLimits
      });
    }

    catch (error) {
      throw error;
    }

    finally {
      this.commit((state) => {
        state.fetching = false;
      });
    }
  }

  static async addStoreOrderLimit(storeOrderLimit) {
    try {
      this.commit((state) => {
        state.submitting = true;
      });

      const filteredLimit = filterObjectKeys(storeOrderLimit, ['id'], true);
      const payload = {
        ...filteredLimit,
        storeOrderLimitSchedulesAttributes: storeOrderLimit
          .storeOrderLimitSchedules
          .map(x => filterObjectKeys(x, ['id', '$id'], true))
      };
      const { data } = await http.post(
        `stores/${storeOrderLimit.storeId}/store_order_limits`,
        { storeOrderLimit: payload }
      );

      this.insert({
        data: data.storeOrderLimit
      });
    }

    catch (error) {
      throw error;
    }

    finally {
      this.commit((state) => {
        state.submitting = false;
      });
    }
  }

  static async updateStoreOrderLimit(storeOrderLimit) {
    try {
      this.commit((state) => {
        state.submitting = true;
      });

      const idsToDelete = [];

      storeOrderLimit.storeOrderLimitSchedules.forEach((s) => {
        if (s.destroy) {
          s._destroy = true;
          idsToDelete.push(s.id);
        }
      });

      const { data } = await http.put(
        `store_order_limits/${storeOrderLimit.id}`,
        {
          storeOrderLimit: {
            ...storeOrderLimit,
            storeOrderLimitSchedulesAttributes: storeOrderLimit
              .storeOrderLimitSchedules
              .map((x) => {
                if (x.id) return x;
                return filterObjectKeys(x, ['id', '$id'], true);
              })
          }
        }
      );

      this.insert({
        data: data.storeOrderLimit
      });

      idsToDelete.forEach((i) => {
        StoreOrderLimitSchedule.delete(i);
      });
    }

    catch (error) {
      throw error;
    }

    finally {
      this.commit((state) => {
        state.submitting = false;
      });
    }
  }

  static async removeStoreOrderLimit(storeOrderLimitId) {
    try {
      this.commit((state) => {
        state.submitting = true;
      });

      await http.delete(`store_order_limits/${storeOrderLimitId}`);

      this.delete(storeOrderLimitId);
    }

    catch (error) {
      throw error;
    }

    finally {
      this.commit((state) => {
        state.submitting = false;
      });
    }
  }
}
