<template>
  <div>
    <b-loading :is-full-page="false" :active="isSubmitting" />

    <div class="card">
      <div class="card-content">
        <store-form mode="update" />
      </div>
    </div>
  </div>
</template>



<script>
  import storeForm from './store-form.vue';
  import Store from '@/store/classes/Store';


  export default { // TEST ALL
    name: 'StoreDetail',

    components: { storeForm },

    computed: {
      isSubmitting() {
        return Store.$state().submitting;
      }
    }
  };
</script>
