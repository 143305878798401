/* eslint-disable import/no-cycle */
import { Model } from '@vuex-orm/core';
import { parseJson } from '@/helpers/misc';

// classes
import http from '@/services/http';


export default class PosConfiguration extends Model {
  static entity = 'posConfigurations'



  // FIELDS //////////////////////
  static fields() {
    return {
      id: this.attr(''),
      merchantId: this.attr(''),
      storeId: this.attr(''),
      metadata: this.attr('', parseJson),
      url: this.attr(''),
      externalInvoiceName: this.attr(''),
      posType: this.attr('', (posType) => {
        try {
          const parsedMetaData = JSON.parse(posType.metaData);

          // NOTE: With POS type = 0 (default stores), metadata will be null,
          // so set default to {} in order not to break accessors to metadata properties
          return {
            ...posType,
            metaData: parsedMetaData || {}
          };
        }

        catch {
          return posType;
        }
      })
    };
  }



  // STATE //////////////////////
  static state() {
    return {
      submitting: false
    };
  }

  static $state() {
    return this.store().state.entities.posConfigurations;
  }



  // ACTIONS //////////////////////
  static async addPosConfiguration({ merchantPosConfiguration, merchantId }) {
    try {
      this.commit((state) => {
        state.submitting = true;
      });

      const response = await http.post(
        `merchants/${merchantId}/merchant_pos_configurations`,
        { merchantPosConfiguration }
      );

      response.data.merchantPosConfiguration.merchantId = merchantId;

      this.insert({
        data: response.data.merchantPosConfiguration
      });
    }
    catch (error) {
      throw error;
    }
    finally {
      this.commit((state) => {
        state.submitting = false;
      });
    }
  }

  static async updatePosConfiguration({ id, metadata, url, externalInvoiceName }) {
    try {
      this.commit((state) => {
        state.submitting = true;
      });
      const response = await http.put(`merchant_pos_configurations/${id}`, {
        merchantPosConfiguration: {
          metadata,
          url,
          externalInvoiceName
        }
      });

      this.update({
        data: response.data.merchantPosConfiguration
      });
    }
    catch (error) {
      throw error;
    }
    finally {
      this.commit((state) => {
        state.submitting = false;
      });
    }
  }

  static async removePosConfiguration(posId) {
    try {
      this.commit((state) => {
        state.submitting = true;
      });

      await http.delete(`merchant_pos_configurations/${posId}`);

      this.delete(posId);
    }
    catch (error) {
      throw error;
    }
    finally {
      this.commit((state) => {
        state.submitting = false;
      });
    }
  }

  static async provisionCloverPosConfiguration(storeId) {
    try {
      const { data } = await http.post(`stores/${storeId}/provision_clover_pos_config`);

      this.insert({ data: data.merchantPosConfiguration });
    }
    catch (error) {
      throw error;
    }
  }
}
