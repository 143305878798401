import VueRouter from 'vue-router';
import store from '@/store';
import { DialogProgrammatic } from 'buefy';

import findCloseableParent from '@/helpers/find-closeable-parent';
import Merchant from '@/store/classes/Merchant';
import MerchantType from '@/store/classes/MerchantType';

import emptyRouter from '@/components/pages/empty-router.vue';
import fourOhFour from '@/components/pages/four-oh-four.vue';
import home from '@/components/pages/home.vue';
import emailConfirmation from '@/components/pages/users/email-confirmation.vue';
import resetPassword from '@/components/pages/password/reset-password.vue';
import forgotPassword from '@/components/pages/password/forgot-password.vue';
import streamConsent from '@/components/pages/stream-consent.vue';
import signIn from '@/components/pages/sign-in.vue';


// -- Pages -- //

// menu
import menuTabsPage from '@/components/pages/menu-management/main-menu/menu-tabs-page.vue';
import posMenuPage from '@/components/pages/menu-management/pos-menu/pos-menu-page.vue';
import locationMenu from '@/components/pages/menu-management/location-menu/location-menu.vue';
import regionalMenu from '@/components/pages/menu-management/regional-menu/regional-menu-list.vue';

// merchants
import merchantList from '@/components/pages/merchants/merchant-list.vue';
import merchantDetail from '@/components/pages/merchants/merchant-detail.vue';
import merchantConfiguration from '@/components/pages/merchants/merchant-configuration.vue';
import hotelConfiguration from '@/components/pages/merchants/hotel-configuration/hotel-configuration.vue';
import tipReports from '@/components/pages/merchants/hotel-configuration/tip-reports.vue';

// merchant sign up
import merchantSignUp from '@/components/pages/merchants/merchant-sign-up.vue';

// stores
import storeList from '@/components/pages/stores/store-list.vue';
import storeTabs from '@/components/pages/stores/store-tabs.vue';

// users
import userList from '@/components/pages/users/user-list.vue';
import userConfiguration from '@/components/pages/users/user-configuration.vue';

// application
import webAppConfiguration from '@/components/pages/application/web-app-configuration.vue';

// Content Managmenet
import contentManagement from '@/components/pages/content-management/content-management.vue';

// kds
import kdsBoard from '@/components/pages/kds/kds-board.vue';

// order payment dashbaord
import opdBoard from '@/components/pages/order-payment-dashboard/opd-board.vue';
import opdModal from '@/components/pages/order-payment-dashboard/opd-order-modal.vue';

// order management
import orderTabsPage from '@/components/pages/orders/order-tabs-page.vue';

// marketing
import campaignList from '@/components/pages/campaigns/campaign-list.vue';
import loyaltyManagement from '@/components/pages/loyalty/loyalty-management.vue';
import offerTabs from '@/components/pages/offers/offer-tabs.vue';
import segmentationList from '@/components/pages/segmentations/segmentation-list.vue';
import promoCodeManagement from '@/components/pages/promo-codes/promo-code-management.vue';

// Registered Guest Management
import registeredGuestList from '@/components/pages/registered-guest/registered-guest-list.vue';
import registeredGuestTabs from '@/components/pages/registered-guest/registered-guest-tabs.vue';

// POS Discounts
import posDiscountList from '@/components/pages/pos-discount/pos-discount-list.vue';

// analytics/reporting
import reportingConfiguration from '@/components/pages/analytics/reporting/reporting-configuration.vue';
import loyaltyReportingConfiguration from '@/components/pages/analytics/reporting/loyalty-reporting-configuration.vue';
import dataExportsManagement from '@/components/pages/analytics/data-exports/data-exports-management.vue';

// payment transactions
import paymentTransactions from '@/components/pages/payment-transactions/index.vue';

// gift card transactions
import giftCardTransactions from '@/components/pages/gift-card-transactions/index.vue';
import giftCardTransactionsList from '@/components/pages/gift-card-transactions/gift-card-transactions-list.vue';

// expo
import orderExpo from '@/components/pages/order-expo/order-expo.vue';

// navigation guards
import { beforeEnterMerchantList } from './router-navigation-guards';


const router = new VueRouter({
  mode: 'history',

  routes: [
    /* * * * * * * * * * * * * * * * * * *
     *     REQUIRES A SIGNED IN USER     *
     * * * * * * * * * * * * * * * * * * */
    {
      path: '/',
      name: 'home',
      component: home,
      meta: { requiresCurrentUser: true }
    },

    {
      path: '/users',
      component: emptyRouter,
      meta: { requiresCurrentUser: true },
      children: [
        {
          path: '',
          name: 'userList',
          component: userList
        },
        {
          path: ':id/:tabName?',
          name: 'userConfiguration',
          component: userConfiguration
        }
      ]
    },

    {
      path: '/merchants',
      component: emptyRouter,
      meta: { requiresCurrentUser: true },
      children: [
        {
          path: '',
          name: 'merchantList',
          component: merchantList,
          beforeEnter: beforeEnterMerchantList
        },
        {
          path: 'configuration/:tabName?/:subTabName?/:appCodeTypeId?',
          component: merchantConfiguration,
          name: 'merchantConfiguration',
          meta: { requiresSelectedMerchant: true },
          props: true
        },
        {
          path: ':merchantId/:tabName?/:subTabName?',
          name: 'merchantDetail',
          component: merchantDetail
        }
      ]
    },

    {
      path: '/hotel-configuration/:tabName?',
      name: 'hotelConfiguration',
      component: hotelConfiguration,
      meta: { requiresCurrentUser: true, requiresSelectedMerchant: true }
    },

    {
      path: '/kds/:tabName?',
      name: 'kds',
      meta: { requiresCurrentUser: true, requiresSelectedMerchant: true },
      component: kdsBoard
    },

    {
      path: '/order-payment-dashboard',
      name: 'orderPaymentDashboard',
      meta: { requiresCurrentUser: true, requiresSelectedMerchant: true },
      component: opdBoard
    },

    {
      path: '/stores',
      component: emptyRouter,
      meta: { requiresCurrentUser: true, requiresSelectedMerchant: true },
      children: [
        {
          path: '',
          name: 'storeList',
          component: storeList
        },
        {
          path: ':storeId/:tabName?',
          name: 'storeConfiguration',
          component: storeTabs
        }
      ]
    },

    {
      path: '/cardfree-queue',
      name: 'cardFreeQueue',
      component: orderExpo,
      meta: { requiresCurrentUser: true, requiresSelectedMerchant: true }
    },

    {
      path: '/pos-menu',
      name: 'posMenu',
      component: posMenuPage,
      meta: { requiresCurrentUser: true, requiresSelectedMerchant: true }
    },

    {
      path: '/menu/:tabName?',
      name: 'menuManagement',
      component: menuTabsPage,
      meta: { requiresCurrentUser: true, requiresSelectedMerchant: true }
    },

    {
      path: '/location-menu/:storeId?',
      name: 'locationMenu',
      component: locationMenu,
      meta: { requiresCurrentUser: true, requiresSelectedMerchant: true }
    },

    {
      path: '/regional-menu',
      name: 'regionalMenu',
      component: regionalMenu,
      meta: { requiresCurrentUser: true, requiresSelectedMerchant: true }
    },

    {
      path: '/content-management/:tabName?',
      name: 'contentManagement',
      meta: { requiresCurrentUser: true, requiresSelectedMerchant: true },
      component: contentManagement
    },

    {
      path: '/application/web',
      name: 'webAppConfiguration',
      component: webAppConfiguration,
      meta: { requiresCurrentUser: true, requiresSelectedMerchant: true }
    },

    {
      path: '/orders/:tabName?',
      name: 'orderManagement',
      meta: { requiresCurrentUser: true, requiresSelectedMerchant: true },
      component: orderTabsPage
    },

    {
      path: '/registered-guests',
      component: emptyRouter,
      meta: { requiresCurrentUser: true, requiresSelectedMerchant: true },
      children: [
        {
          path: '',
          name: 'registeredGuestList',
          component: registeredGuestList
        },
        {
          path: ':userId/:tabName?',
          name: 'registeredGuestDetails',
          component: registeredGuestTabs
        }
      ]
    },

    {
      path: '/tip-reports/:tabName?',
      name: 'tipReports',
      component: tipReports,
      meta: { requiresCurrentUser: true, requiresSelectedMerchant: true }
    },

    {
      path: '/payment-transactions/:tabName?',
      name: 'paymentTransactions',
      component: paymentTransactions,
      meta: { requiresCurrentUser: true, requiresSelectedMerchant: true }
    },

    {
      path: '/gift-card-transactions/:tabName?',
      name: 'giftCardTransactions',
      component: giftCardTransactions,
      meta: { requiresCurrentUser: true, requiresSelectedMerchant: true },
      children: [
        {
          path: 'transactions',
          name: 'giftCardTransactionsList',
          component: giftCardTransactionsList
        }
      ]
    },

    {
      path: '/analytics',
      component: emptyRouter,
      meta: { requiresCurrentUser: true, requiresSelectedMerchant: true },
      children: [
        {
          path: 'reporting',
          name: 'reportingConfiguration',
          component: reportingConfiguration
        },
        {
          path: 'loyalty-reporting',
          name: 'loyaltyReportingConfiguration',
          component: loyaltyReportingConfiguration
        },
        {
          path: 'data-exports/:tabName?',
          name: 'dataExportsManagement',
          component: dataExportsManagement
        }
      ]
    },

    {
      path: '/marketing',
      component: emptyRouter,
      meta: { requiresCurrentUser: true, requiresSelectedMerchant: true },
      children: [
        {
          path: 'loyalty/:tabName?',
          name: 'loyaltyManagement',
          component: loyaltyManagement
        },
        {
          path: 'campaigns',
          name: 'campaignManagement',
          component: campaignList
        },
        {
          path: 'segmentations',
          name: 'segmentationManagement',
          component: segmentationList
        },
        {
          path: 'promo-codes',
          name: 'promoCodeManagement',
          component: promoCodeManagement
        },
        {
          path: 'offers/:tabName?',
          name: 'offerManagement',
          component: offerTabs
        },
        {
          path: 'pos-discounts',
          name: 'posDiscount',
          component: posDiscountList
        }
      ]
    },



    /* * * * * * * * * * * * * * * * * * *
     * DOES NOT REQUIRE A SIGNED IN USER *
     * * * * * * * * * * * * * * * * * * */

    {
      path: '/join',
      component: emptyRouter,
      children: [
        // {
        //   path: '',
        //   name: 'join',
        //   component: merchantSignUp,
        //   meta: { disableSignInModal: true, hideFooter: true }
        // },
        {
          path: ':sundry',
          name: 'join-sundry',
          component: merchantSignUp,
          props: {
            merchantTypeId: MerchantType.typeIds.SUNDRY
          },
          meta: { disableSignInModal: true, hideFooter: true }
        }
      ]
    },

    {
      path: '/sign-in',
      name: 'signIn',
      component: signIn,
      meta: { disableSignInModal: true }
    },

    {
      path: '/email-confirmation',
      name: 'emailConfirmation',
      component: emailConfirmation,
      meta: { disableSignInModal: true }
    },

    {
      path: '/reset-password',
      name: 'resetPassword',
      component: resetPassword,
      meta: { disableSignInModal: true }
    },

    {
      path: '/forgot-password',
      name: 'forgotPassword',
      component: forgotPassword,
      meta: { disableSignInModal: true }
    },

    {
      path: '/stream/consent',
      name: 'streamConsent',
      component: streamConsent,
      meta: { disableSignInModal: true, requiresCurrentUser: false }
    },

    {
      path: '*',
      name: 'fourOhFour',
      component: fourOhFour
    }
  ]
});

if (['localhost', 'dev', 'demo'].some(env => window.location.hostname.includes(env))) {
  router.addRoute({
    path: '/demo',
    component: emptyRouter,
    children: [
      {
        path: 'order-payment-dashboard',
        name: 'orderPaymentDashboardDemo',
        meta: { disableSignInModal: true },
        component: opdBoard,
        props: () => ({
          isDemo: true
        })
      },
      {
        path: 'order-payment-dashboard/request',
        name: 'orderPaymentRequestDemo',
        meta: { disableSignInModal: true },
        component: opdModal,
        props: route => ({
          isDemo: true,
          isSimple: Boolean(route.query.simple),
          storeId: Number(route.query.storeId)
        })
      }
    ]
  });
}

const closeAllModals = () => {
  document.getElementsByClassName('modal').forEach((modal) => {
    const closeableParent = findCloseableParent(modal.__vue__);
    if (closeableParent) {
      closeableParent.close();
    }
  });
};

router.beforeEach(async (to, from, next) => {
  if (
    to.matched.some(route => route.meta.requiresCurrentUser)
    && !store.getters['session/currentUser']
  ) {
    await store.dispatch('session/initializeSession');
  }

  if (to.matched.some(route => route.meta.requiresSelectedMerchant) && !Merchant.$state().selectedMerchantId) {
    next({ name: 'merchantList' });
  }

  if (from.name) {
    const { formChanged } = store.state.formStore;
    if (formChanged) {
      DialogProgrammatic.confirm({
        title: 'Unsaved Changes',
        message: 'Are you sure you want to leave this page?<br>Any unsaved changes will be lost.',
        type: 'is-danger',
        trapFocus: true,
        onConfirm: () => {
          store.dispatch('formStore/setFormChanged', { hasChanged: false });
          closeAllModals();
          next();
        },
        confirmText: 'Leave',
        onCancel: () => {
          try {
            next(false);
          }
          catch (error) {
            console.warn(error);
          }
        },
        cancelText: 'Stay'
      });
    }
    else {
      closeAllModals();
      next();
    }
  }
  else {
    next();
  }
});

router.afterEach((to) => {
  if (to.hash) {
    setTimeout(() => {
      const scrollToElement = document.querySelector(to.hash);
      if (scrollToElement) scrollToElement.scrollIntoView({ behavior: 'smooth' });
    }, 500);
  }
});



export default router;
