<template>
  <div class="card">
    <div class="card-header">
      <div class="card-header-title pad-lg">
        <p class="title is-5">Review</p>
      </div>
    </div>
    <div class="card-content">
      <template v-if="!readOnly">
        <p class="is-4 subtitle mar-b-xs">Review your challenge</p>
      </template>

      <div class="dist-y-lg">
        <review-card
          title="Details"
          :read-only="readOnly"
          @navigate-to-step="$emit('navigate-to-step', 0)"
        >
          <details-card v-model="value.currency" />
        </review-card>

        <review-card
          title="Activity"
          :read-only="readOnly"
          @navigate-to-step="$emit('navigate-to-step', 1)"
        >
          <!--
            Challenges will soon support mulitple earn rules, so getting ahead of that
            by having this activity card take in on earn rule so we can hopefully just loop
            over it when mulitple earn rules are implemented
          -->
          <activity-card
            v-model="value.earnRule"
            :currency="value.currency"
          />
        </review-card>

        <review-card
          v-for="(conversionThreshold, idx) in value.conversionThresholds"
          :key="`conversion-threshold-${idx}`"
          :title="value.conversionThresholds.length > 1 ? `Award ${idx + 1}` : 'Award'"
          :read-only="readOnly"
          @navigate-to-step="$emit('navigate-to-step', 2)"
        >
          <award-card
            :value="conversionThreshold"
            :currency="value.currency"
          />
        </review-card>
        <review-card
          title="Time Frame"
          :read-only="readOnly"
          @navigate-to-step="$emit('navigate-to-step', 3)"
        >
          <time-frame-card v-model="value.earnRule" />
        </review-card>
      </div>
    </div>
  </div>
</template>

<script>
  import AwardCard from './review-cards/award-card.vue';
  import DetailsCard from './review-cards/details-card.vue';
  import TimeFrameCard from './review-cards/time-frame-card.vue';
  import ActivityCard from './review-cards/activity-card.vue';


  export default {
    name: 'ReviewStep',

    components: {
      AwardCard,
      DetailsCard,
      TimeFrameCard,
      ActivityCard
    },

    props: {
      value: {
        type: Object,
        required: true
      },
      readOnly: {
        type: Boolean,
        default: false
      }
    }
  };
</script>
