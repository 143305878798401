<template>
  <div data-id="item-tags">
    <validation-provider
      v-slot="{ errors }"
      name="itemTags"
      :rules="{
        required: !!selectedTags.length
      }"
    >
      <b-checkbox
        :value="!hasTagMoreThan50Characters || null"
        :class="['is-hidden', { 'invalid': errors.length }]"
      />
      <b-field>
        <template #label>
          <p class="is-size-5 has-text-weight-normal">Item Tags</p>
          <p class="has-text-grey has-text-weight-normal is-size-7">Group similar items using tags to improve guest's search results</p>
        </template>
        <div class="is-flex-column">
          <p v-if="errors.length" class="is-size-7 has-text-danger">
            Tags must be less than 50 characters
            <b-icon icon="exclamation-circle" type="is-danger" size="is-small" />
          </p>
          <b-taglist class="mar-b dist-x-sm">
            <b-tag
              v-for="tag in selectedTags"
              :key="`tag${tag.id}`"
              ellipsis
              closable
              attached
              size="is-medium"
              :type="errors.length && tag.name.length > 50 ? 'is-danger' : 'is-primary'"
              :close-type="errors.length && tag.name.length > 50 ? 'is-danger' : 'is-primary'"
              @close="handleSelectTag(tag)"
            >
              <p class="mar-r-xs">
                <span class="has-text-weight-bold">{{ tag.name }}</span>
              </p>
            </b-tag>
          </b-taglist>

          <b-autocomplete
            ref="autocomplete"
            v-model="query"
            icon="search"
            class="is-inline has-icon-right has-dividers has-extra-shadow tag-search"
            expanded
            :data="filteredAvailableTags"
            open-on-focus
            max-height="325"
            placeholder="Select or add tags..."
            clearable
            clear-on-selected
            :icon-right="query ? 'times-circle' : ''"
            icon-right-clickable
            :disabled="false"
            :loading="isFetching"
            @select="($event) => handleSelectTag($event)"
          >
            <template #empty>
              There are no tags matching {{ query ? `'${query}'` : 'your search' }}
            </template>
            <template #footer>
              <a v-if="query && queryHasNoMatch" @click="createTag">
                <span>+ Add {{ query }}... </span>
              </a>
            </template>
            <template slot-scope="props">
              <div :class="['tag-option is-grid col-4', {'selected': !!findTag(props.option)}]">
                <div class="is-flex-column col-span-3">
                  <p>{{ props.option.name }}</p>
                </div>
                <b-icon
                  v-if="!!findTag(props.option)"
                  size="is-small"
                  type="is-primary"
                  icon="check"
                  class="justify-self-end align-self-center"
                />
              </div>
            </template>
          </b-autocomplete>

          <b-button
            v-if="selectedTags.length"
            class="mar-t clear-tags-btn"
            type="is-primary is-ghost"
            inverted
            @click="$emit('clear-all-tags'); $emit('input');"
          >
            Clear All Tags
          </b-button>
        </div>
      </b-field>
    </validation-provider>
  </div>
</template>

<script>
  import MenuAttributeType from '@/store/classes/MenuAttributeType';
  import MenuItemAttribute from '@/store/classes/MenuItemAttribute';
  import MerchantMenuAttribute from '@/store/classes/MerchantMenuAttribute';
  import ItemTag from '@/helpers/classes/ItemTag';

  export default {
    name: 'ItemTags',

    props: {
      menuItemId: {
        type: Number,
        default: null
      },

      selectedTags: {
        type: Array,
        default: () => []
      }
    },

    data: () => ({
      query: null
    }),

    computed: {
      isFetching() {
        return MenuAttributeType.$state().fetching
          || MenuItemAttribute.$state().fetching;
      },

      queryHasNoMatch() {
        return !this.filteredAvailableTags.some(tag => tag.name.toLowerCase() === this.query.toLowerCase());
      },

      availableTags() {
        const mmas = MerchantMenuAttribute.withMenuAttributes().map(merchantMenuAttribute => (
          new ItemTag(merchantMenuAttribute)
        ));

        const newTags = this.selectedTags.filter(tag => tag.isNewTag);
        return [...newTags, ...mmas];
      },

      filteredAvailableTags() {
        if (!this.query) return this.availableTags;
        return this.availableTags.filter(tag => tag.name.toLowerCase().includes(this.query.toLowerCase()));
      },

      hasTagMoreThan50Characters() {
        return this.selectedTags.some(tag => tag?.name.length > 50);
      }
    },

    methods: {
      findTag(tag) {
        return this.selectedTags.find(selectedTag => selectedTag.name.toLowerCase() === tag.name.toLowerCase());
      },

      createTag() {
        const tag = new ItemTag({ name: this.query, menuItemId: this.menuItemId });
        this.handleSelectTag(tag);
      },

      handleSelectTag(tag) {
        if (tag) {
          const existingTag = this.findTag(tag);
          if (existingTag) {
            this.removeTag(existingTag.id);
          }
          else {
            this.addTag(tag);
          }
          this.query = null;
        }
      },

      addTag(tag) {
        this.$emit('tag-added', tag);
        this.$emit('input');
      },

      removeTag(tagId) {
        this.$emit('tag-removed', tagId);
        this.$emit('input');
      }
    }
  };
</script>

<style lang='sass' scoped>
  .tag-search
    ::v-deep
      .dropdown-menu
        padding-top: 1px

  .selected
    color: $primary

  ::v-deep
    .tag .has-ellipsis
      max-width: 25rem

  .clear-tags-btn
    width: max-content !important

  ::v-deep
    .button.is-ghost
      &:hover
        text-decoration: none
        background: none
        color: darken($primary, 10)

    .tags.has-addons
      margin-bottom: 0.5rem !important
</style>

