/* eslint-disable import/no-cycle */
import { Model } from '@vuex-orm/core';
import http from '@/services/http';
import filterObjectKeys from '@/helpers/filter-object-keys';
import UserRole from './UserRole';



export default class User extends Model {
  static entity = 'users'

  static fields() {
    return {
      id: this.attr(''),
      active: this.attr(true),
      addressLine1: this.attr(''),
      addressLine2: this.attr(''),
      city: this.attr(''),
      email: this.attr(''),
      unconfirmedEmail: this.attr(''),
      fullName: this.attr(''),
      isConfirmed: this.attr(''),
      merchants: this.attr([]),
      phoneNumber: this.attr(''),
      postalCode: this.attr(''),
      role: this.attr({}),
      ssoProviderId: this.attr(null),
      state: this.attr(''),
      storeIds: this.attr([]),
      createdAt: this.attr(''),
      updatedAt: this.attr(''),

      // relationships
      userRole: this.hasOne(UserRole, 'userId')
    };
  }

  get status() {
    if (this.active) {
      return this.isConfirmed ? 'active' : 'pending';
    }
    return 'deactivated';
  }

  get isSso() {
    return !!this.ssoProviderId;
  }

  // STATE //////////////////////
  static state() {
    return {
      fetching: false,
      submitting: false
    };
  }

  static $state() {
    return this.store().state.entities.users;
  }



  // ACTIONS //////////////////////
  static async searchUsers({
    page = 1,
    sortField = 'created_at',
    sortDirection = 'desc',
    searchField = '',
    searchTerm = '',
    ...filters
  } = {}) {
    try {
      // the backendSearch module commits its own fetching state
      // so we don't need to commit a fetching state here
      const merchantId = this.store().state.entities.merchants.selectedMerchantId;

      const params = {
        page,
        sortField,
        sortDirection,
        merchantId,
        ...filters
      };

      if (searchField && searchTerm) {
        params.searchField = searchField;
        params.searchTerm = searchTerm;
      }

      const response = await http.get('users', { params });

      this.insertOrUpdate({ data: response.data.users });

      return {
        users: response.data.users,
        meta: response.data.meta
      };
    }
    catch (error) {
      throw error;
    }
  }

  static async fetchUser(userId) {
    try {
      this.commit((state) => {
        state.fetching = true;
      });

      const response = await http.get(`users/${userId}`);

      this.insert({ data: response.data.user });
    }
    catch (error) {
      throw error;
    }
    finally {
      this.commit((state) => {
        state.fetching = false;
      });
    }
  }

  static async updateUser(user) {
    try {
      this.commit((state) => {
        state.submitting = true;
      });
      const acceptedKeys = [
        'addressLine1',
        'addressLine2',
        'city',
        'email',
        'fullName',
        'merchantIds',
        'phoneNumber',
        'postalCode',
        'ssoProviderId',
        'state',
        'storeIds'
      ];
      const response = await http.put(`users/${user.id}`, {
        user: filterObjectKeys(user, acceptedKeys)
      });

      this.update({ data: response.data.user });
    }
    catch (error) {
      throw error;
    }
    finally {
      this.commit((state) => {
        state.submitting = false;
      });
    }
  }

  static async updateEmail(userId, email) {
    try {
      this.commit((state) => {
        state.submitting = true;
      });

      const response = await http.put(`users/${userId}/update_email`, {
        user: { email }
      });

      this.update({ data: response.data.user });
    }
    catch (error) {
      throw error;
    }
    finally {
      this.commit((state) => {
        state.submitting = false;
      });
    }
  }

  // will need to be able to toggle pii and menu management fields via user_role, so maybe:
  // BE returns full role object on user to keep consistent (up one level)
  // and also userRole object to get the other metadata?
  // OR
  // just return user.userRole.role
  static async createUser(user) {
    try {
      this.commit((state) => {
        state.submitting = true;
      });
      const acceptedKeys = [
        'addressLine1',
        'addressLine2',
        'city',
        'email',
        'fullName',
        'merchantIds',
        'phoneNumber',
        'postalCode',
        'roleIds',
        'state',
        'storeIds',
        'ssoProviderId'
      ];
      const response = await http.post('users', {
        user: {
          ...filterObjectKeys(user, acceptedKeys),
          userRoleAttributes: {
            roleId: user.role.id,
            hidePii: user.userRole.hidePii,
            hideMenuManagement: user.userRole.hideMenuManagement
          },
          merchantIds: user.merchants.map(merchant => merchant.id)
        }
      });

      this.insert({ data: response.data.user });
    }
    catch (error) {
      throw error;
    }
    finally {
      this.commit((state) => {
        state.submitting = false;
      });
    }
  }

  static async deleteUser(userId) {
    try {
      this.commit((state) => {
        state.submitting = true;
      });

      await http.delete(`users/${userId}`);

      this.delete(userId);
    }
    catch (error) {
      throw error;
    }
    finally {
      this.commit((state) => {
        state.submitting = false;
      });
    }
  }

  static async activateUser(userId) {
    try {
      this.commit((state) => {
        state.submitting = true;
      });
      const response = await http.put(`users/${userId}/activate`);

      this.update({ data: response.data.user });
    }
    catch (error) {
      throw error;
    }
    finally {
      this.commit((state) => {
        state.submitting = false;
      });
    }
  }

  static async deactivateUser(userId) {
    try {
      this.commit((state) => {
        state.submitting = true;
      });
      const response = await http.put(`users/${userId}/deactivate`);

      this.update({ data: response.data.user });
    }
    catch (error) {
      throw error;
    }
    finally {
      this.commit((state) => {
        state.submitting = false;
      });
    }
  }

  static async requestPasswordReset(email) {
    try {
      this.commit((state) => {
        state.submitting = true;
      });
      await http.post('users/password/request_reset', {
        user: { email }
      });
    }
    catch (error) {
      throw error;
    }
    finally {
      this.commit((state) => {
        state.submitting = false;
      });
    }
  }

  static async updateUserPassword({ currentPassword, password, passwordConfirmation }, userId) {
    try {
      this.commit((state) => {
        state.submitting = true;
      });

      await http.put(
        `users/${userId}/update_password`,
        { user: { currentPassword, password, passwordConfirmation } }
      );
    }
    catch (error) {
      throw error;
    }
    finally {
      this.commit((state) => {
        state.submitting = false;
      });
    }
  }

  static async resetPassword({ password, resetPasswordToken, autoSignIn }) {
    try {
      this.commit((state) => {
        state.submitting = true;
      });

      const response = await http.put('users/password', {
        user: { password, resetPasswordToken }
      });

      if (autoSignIn) {
        this.store().dispatch(
          'session/signIn',
          { email: response.data.user.email, password },
          { root: true }
        );
      }
    }

    catch (error) {
      throw error;
    }
    finally {
      this.commit((state) => {
        state.submitting = false;
      });
    }
  }

  static async resendEmailConfirmation(userId) {
    try {
      await http.put('users/confirmation/resend_confirmation_email', { userId });
    }
    catch (error) {
      throw error;
    }
  }

  static async updateUserRole({
    userId, roleId, storeIds, merchantIds, hidePii, hideMenuManagement
  }) {
    try {
      this.commit((state) => {
        state.submitting = true;
      });

      const response = await http.put(
        `users/${userId}/role`,
        { roleId, storeIds, merchantIds, hidePii, hideMenuManagement }
      );

      this.update({ data: response.data.user });
    }
    catch (error) {
      throw error;
    }
    finally {
      this.commit((state) => {
        state.submitting = false;
      });
    }
  }

  static async onboardUser({ fullName, email, merchantTypeId, businessName }) {
    try {
      this.commit((state) => {
        state.submitting = true;
      });
      await http.post('onboarding/users', {
        user: {
          fullName,
          email,
          userOnboardingMetadataAttributes: {
            merchantTypeId,
            businessName
          }
        }
      });
    }
    catch (error) {
      throw error;
    }
    finally {
      this.commit((state) => {
        state.submitting = false;
      });
    }
  }
}
