<template>
  <div class="store-list-page">
    <hero-banner>
      <h1 class="title is-2">Locations</h1>

      <template #right>
        <div class="buttons is-centered">
          <b-button
            v-if="$can('create', 'StoreMapping') && $_selectedMerchant && $_selectedMerchant.locationManagementSettings.createsEnabled"
            rounded
            inverted
            size="is-medium"
            type="is-primary"
            icon-left="plus"
            @click="$_toggleModal"
          >
            New Location
          </b-button>

          <b-button
            v-if="showRefreshLocationsButton"
            rounded
            inverted
            size="is-medium"
            type="is-primary"
            icon-left="sync"
            :loading="isRefreshingStores"
            @click="refreshStores"
          >
            Refresh Locations
          </b-button>
        </div>
      </template>
    </hero-banner>

    <div class="section">
      <div class="container">
        <b-message v-if="invalidStoreIds.length" class="box is-paddingless" type="is-danger">
          Locations marked
          <b-icon
            class="mar-x-xs"
            pack="far"
            size="is-small"
            icon="exclamation-triangle"
          />
          are missing values for required fields. Please update the location details and enable online ordering.
        </b-message>
        <div class="card">
          <searchable-table
            v-slot="{ filteredData }"
            :table-data="stores"
            :search="{
              placeholder: 'Search by Location Name or Address',
              keys: ['description', 'addressLine1', 'addressLine2', 'city', 'region', 'postalCode']
            }"
          >
            <b-table
              :data="fetchingStores ? [] : filteredData"
              :paginated="stores.length > pageLimit"
              class="is-middle-aligned"
              pagination-simple
              :per-page="pageLimit"
              :mobile-cards="false"
              :loading="fetchingStoreItems || togglingTestMode"
              default-sort="description"
            >
              <b-table-column
                v-slot="{ row }"
                sortable
                field="description"
                label="Name"
                cell-class="no-wrap-text"
              >
                <div :class="{ 'name-with-errors': storeHasErrors(row.storeId) }">
                  <a class="link" @click="handleStoreSelection(row)">
                    {{ row.description }}
                  </a>
                  <b-icon
                    v-if="storeHasErrors(row.storeId)"
                    class="mar-l-sm has-text-danger"
                    pack="far"
                    size="is-small"
                    icon="exclamation-triangle"
                  />
                  <b-tag
                    v-if="row.storeId === $_selectedMerchant.defaultStoreId"
                    v-tippy="{ content: 'Merchant Default Store' }"
                    size="is-small"
                    class="mar-l-xs"
                    type="is-success is-light is-outlined"
                  >
                    Default
                  </b-tag>
                  <b-tag
                    v-if="row.isSystem"
                    v-tippy="{ content: 'Internal System Store' }"
                    size="is-small"
                    class="mar-l-xs"
                    type="is-warning is-light is-outlined"
                  >
                    System
                  </b-tag>
                </div>
              </b-table-column>

              <b-table-column v-slot="{ row }" field="address" label="Address" cell-class="no-wrap-text">
                <template v-if="row.storeId !== $_selectedMerchant.defaultStoreId">
                  <p>{{ row.addressLine1 }}{{ row.addressLine2 ? `, ${row.addressLine2}` : '' }}</p>
                  <p>{{ row.city }}, {{ row.region }} {{ row.postalCode }}</p>
                </template>
                <p v-else>N/A</p>
              </b-table-column>

              <b-table-column v-slot="{ row }" field="contact" label="Contact" cell-class="no-wrap-text">
                <div>
                  <div>
                    <b-icon
                      size="is-small"
                      pack="far"
                      class="has-text-grey mar-r-sm"
                      icon="envelope"
                      cell-class="no-wrap-text"
                    />
                    <template v-if="row.emailAddress">
                      <a :href="`mailto:${row.emailAddress}`">{{ row.emailAddress }}</a>
                    </template>
                    <template v-else>
                      <span class="has-text-grey">N/A</span>
                    </template>
                  </div>
                  <div>
                    <b-icon size="is-small" pack="far" class="has-text-grey mar-r-sm" icon="phone" />
                    <template v-if="row.phoneNumber">
                      <a :href="`mailto:${row.phoneNumber}`">{{ row.phoneNumber | phoneNumber }}</a>
                    </template>
                    <template v-else>
                      <span class="has-text-grey">N/A</span>
                    </template>
                  </div>
                </div>
              </b-table-column>

              <b-table-column
                v-if="!hideTaxColumn"
                v-slot="{ row }"
                field="tax"
                label="Tax"
                cell-class="no-wrap-text"
              >
                <template v-if="!supportsTax(row) && row.totalSalesTaxRate">
                  {{ row.totalSalesTaxRate }}%
                </template>
                <template v-else>
                  <span class="has-text-grey">N/A</span>
                </template>
              </b-table-column>

              <b-table-column
                v-if="canToggleTestMode"
                v-slot="{ row }"
                centered
                sortable
                field="isActive"
                label="Status"
              >
                <b-tag :type="(row.isActive && !row.isVirtual) ? 'is-success is-light' : 'is-warning is-light'" class="has-text-weight-bold is-outlined">
                  {{ row.isActive && !row.isVirtual ? 'Active': 'Test' }}
                </b-tag>
              </b-table-column>

              <b-table-column
                v-slot="{ row }"
                centered
                label="Online Ordering"
                sortable
                :custom-sort="(a, b, isAsc) => {
                  const sort = getOnlineOrderingStatus(a) > getOnlineOrderingStatus(b) ? -1 : 1;
                  return isAsc ? sort : (sort * -1);
                }"
              >
                <b-tag :type="getOnlineOrderingStatus(row) ? 'is-success is-light' : 'is-danger is-light'" class="has-text-weight-bold is-outlined">
                  {{ getOnlineOrderingStatus(row) ? 'Enabled' : 'Disabled' }}
                </b-tag>
              </b-table-column>

              <b-table-column
                v-if="$_selectedMerchant.kioskEnabled"
                v-slot="{ row }"
                centered
                label="Kiosk Ordering"
                sortable
                :custom-sort="(a, b, isAsc) => {
                  const sort = getKioskStatus(a) > getKioskStatus(b) ? -1 : 1;
                  return isAsc ? sort : (sort * -1);
                }"
              >
                <b-tag :type="getKioskStatus(row) ? 'is-success is-light' : 'is-danger is-light'" class="has-text-weight-bold is-outlined">
                  {{ getKioskStatus(row) ? 'Enabled' : 'Disabled' }}
                </b-tag>
              </b-table-column>

              <b-table-column
                v-slot="{ row }"
                numeric
                cell-class="actions"
                label="Actions"
                width="1"
              >
                <dropdown-menu position="bottom-end">
                  <b-button slot="trigger" class="is-transparent" type="is-white">
                    <b-icon icon="ellipsis-v" pack="far" size="is-small" />
                  </b-button>

                  <b-dropdown-item v-if="$can('read', 'StoreMenuItem')" has-link>
                    <router-link :to="{ name: 'locationMenu', params: { storeId: row.storeId } }">
                      <b-icon icon="store-alt" size="is-small" class="mar-r-sm" />
                      View Location Menu
                    </router-link>
                  </b-dropdown-item>

                  <b-dropdown-item
                    v-if="canToggleTestMode"
                    :disabled="storeHasErrors(row.storeId)"
                    @click="handleToggleTestMode(row)"
                  >
                    <b-icon
                      icon="flask"
                      class="mar-r-sm"
                      size="is-small"
                    />
                    {{ row.isActive && !row.isVirtual ? 'Enable' : 'Disable' }} Test Mode
                  </b-dropdown-item>
                  <hr v-if="canToggleOnlineOrdering(row.storeId) || canToggleKioskOrdering(row.storeId)" class="dropdown-divider">
                  <b-dropdown-item
                    v-if="canToggleOnlineOrdering(row.storeId)"
                    :class="{'is-danger': getOnlineOrderingStatus(row)}"
                    :disabled="storeHasErrors(row.storeId)"
                    @click="handleUpdateOnlineOrderingStatus(row)"
                  >
                    <b-icon
                      :icon="getOnlineOrderingStatus(row)
                        ? 'lightbulb-slash'
                        : 'lightbulb-on' "
                      class="mar-r-sm"
                      size="is-small"
                    />
                    {{ getOnlineOrderingStatus(row) ? 'Disable' : 'Enable' }} Online Ordering
                  </b-dropdown-item>
                  <b-dropdown-item
                    v-if="$_selectedMerchant.kioskEnabled && canToggleKioskOrdering(row.storeId)"
                    :class="{'is-danger': getKioskStatus(row)}"
                    :disabled="storeHasErrors(row.storeId)"
                    @click="handleUpdateKioskOrdering(row)"
                  >
                    <b-icon
                      :icon="getKioskStatus(row)
                        ? 'lightbulb-slash'
                        : 'lightbulb-on' "
                      class="mar-r-sm"
                      size="is-small"
                    />
                    {{ getKioskStatus(row) ? 'Disable' : 'Enable' }} Kiosk Ordering
                  </b-dropdown-item>
                </dropdown-menu>
              </b-table-column>

              <template slot="empty">
                <empty-table-loader :loading="fetchingStores" />
              </template>
            </b-table>
          </searchable-table>
        </div>
      </div>
    </div>

    <b-modal
      has-modal-card
      :can-cancel="false"
      trap-focus
      :active.sync="cmc_isModalOpen"
      @keydown.native.esc="$_confirmCloseModal"
    >
      <modal-card
        title="Add New Location"
        modal-card-body-class="pad-b-none"
      >
        <store-form mode="create" />
      </modal-card>
    </b-modal>
  </div>
</template>

<script>
  import Store from '@/store/classes/Store';
  import StoreAttribute from '@/store/classes/StoreAttribute';
  import StoreItem from '@/store/classes/StoreItem';
  import logger from '@/services/logger';
  import storeForm from './store-form.vue';
  import alertModal from '@/components/globals/alert-modal.vue';
  import { subject } from '@casl/ability';

  // mixins
  import merchantMixin from '@/mixins/merchant';
  import confirmModalCloseMixin from '@/mixins/confirm-modal-close';
  import scrollIndicatorMixin from '@/mixins/scroll-container-indicator';
  import posTypes from '@/constants/posTypes';
  import Merchant from '@/store/classes/Merchant';
  import PosTypeOption from '@/store/classes/PosTypeOption';
  import LocationServiceType from '@/store/classes/LocationServiceType';

  export default {
    name: 'StoreList',

    components: { storeForm },

    mixins: [
      merchantMixin,
      confirmModalCloseMixin, // gives you: cmc_isModalOpen, $_toggleModal(), $_confirmCloseModal()
      scrollIndicatorMixin
    ],

    metaInfo() {
      return {
        title: 'Locations'
      };
    },

    data() {
      return {
        pageLimit: 20,
        fetchingStoreItems: false,
        togglingTestMode: false,
        posTypes,
        isRefreshingStores: false
      };
    },

    computed: {
      stores() {
        return Store.query().with('storeItems').get();
      },

      storeCount() {
        return Store.query().count();
      },

      fetchingStores() {
        return Store.$state().fetchingAll || LocationServiceType.$state().fetching;
      },

      invalidStoreIds() {
        return this.stores.reduce((acc, s) => {
          const isStoreDefault = s.storeId === this.$_selectedMerchant.defaultStoreId;
          if (s.errors && Object.keys(s.errors).length && !isStoreDefault) {
            acc.push(s.storeId);
          }
          return acc;
        }, []);
      },

      hideTaxColumn() {
        return this.stores.every(this.supportsTax);
      },

      onlineOrderingAttributes() {
        return StoreAttribute.query().where('code', ['MobileOrdering', 'WebOrdering']).get();
      },

      kioskOrderingAttribute() {
        return StoreAttribute.query().where('code', ['KioskOrdering']).first();
      },

      canToggleTestMode() {
        return this.$can('update', 'StoreMapping', 'isVirtual') && this.$can('update', 'StoreMapping', 'isActive');
      },

      showRefreshLocationsButton() {
        const squarePosType = PosTypeOption.squarePosType();
        const toastPosType = PosTypeOption.toastPosType();
        const canRefreshLocationForSquare = this.$_selectedMerchant.hasSquareMerchantLocationService && this.$_hasPosType(squarePosType?.id);
        const canRefreshLocationsForToast = !!this.$_selectedMerchant.hasToastMerchantLocationService && this.$_hasPosType(toastPosType?.id);
        return canRefreshLocationForSquare || canRefreshLocationsForToast;
      }
    },

    async created() {
      await this.onCreated();
    },

    methods: {
      async onCreated() {
        await Promise.all([
          this.fetchStoreAttributes(),
          this.fetchPosTypeOptions(),
          this.fetchLocationServiceTypes()
        ]);
      },

      async onMerchantLoad() {
        await this.fetchStores();
        this.$nextTick(() => {
          this.$_initScrollIndicatorSmart({
            contentSelector: '.table'
          });
        });
      },

      handleStoreSelection(store) {
        if (store.isSystem) {
          this.$buefy.modal.open({
            parent: this,
            component: alertModal,
            hasModalCard: true,
            trapFocus: true,
            canCancel: false,
            customClass: 'auto-width',
            props: {
              title: 'Open System Store?',
              message: `<b>${store.description}</b> is the system store. Edits to this store should only be made by CF Admins.`,
              secondaryMessage: 'Are you sure you want to open this store?',
              type: 'is-danger',
              icon: 'exclamation-triangle',
              horizontal: true,
              showCloseButton: false,
              canClose: false,
              buttons: [
                {
                  text: 'Cancel'
                },
                {
                  text: 'Yes, Open Store',
                  primary: true,
                  onClick: () => {
                    this.$router.push({ name: 'storeConfiguration', params: { storeId: store.storeId } });
                  }
                }
              ]
            }
          });
        }
        else {
          this.$router.push({ name: 'storeConfiguration', params: { storeId: store.storeId } });
        }
      },

      async fetchStores() {
        try {
          await Store.fetchAll(null, { forceFetch: true });
        }
        catch (error) {
          this.$_onRequestError({
            error,
            toastOptions: { message: 'Unable to fetch locations' }
          });
        }
      },

      async fetchPosTypeOptions() {
        try {
          await PosTypeOption.fetchPosTypes();
        }
        catch (error) {
          this.$_onRequestError({
            error,
            toastOptions: { message: 'Unable to fetch pos types' }
          });
        }
      },

      async fetchLocationServiceTypes() {
        try {
          await LocationServiceType.fetchLocationServiceTypes();
        }
        catch (error) {
          this.$_onRequestError({
            error,
            toastOptions: { message: 'Unable to fetch pos types' }
          });
        }
      },

      async fetchStoreItems(storeId) {
        let storeItems;
        try {
          this.fetchingStoreItems = true;
          storeItems = await StoreItem.fetchStoreMenuItemsByStoreId(storeId);
        }
        catch (error) {
          logger.error(error);
        }
        finally {
          this.fetchingStoreItems = false;
        }
        return storeItems;
      },

      async fetchStoreAttributes() {
        try {
          await StoreAttribute.fetchStoreAttributes();
        }
        catch (error) {
          this.$_onRequestError({
            toastOptions: {
              message: 'There was an error fetching the store attributes'
            },
            error
          });
        }
      },

      async toggleTestMode({ storeId, isActive, isVirtual }) {
        if (isVirtual) {
          this.togglingTestMode = true;
        }
        try {
          await Store.updateStore({
            storeId,
            isActive: !isActive,
            isVirtual: !isVirtual
          });
        }
        catch (error) {
          this.$_onRequestError({
            toastOptions: { message: `There was an issue ${isVirtual ? 'disabling' : 'enabling'} test mode` },
            error
          });
        }
        finally {
          this.togglingTestMode = false;
        }
      },

      async refreshStores() {
        this.isRefreshingStores = true;
        try {
          await Merchant.refreshStores(this.$_selectedMerchantId);

          this.$_onRequestSuccess({
            toastOptions: {
              message: 'Currently refreshing your locations!'
            }
          });
        }

        catch (error) {
          this.$_onRequestError({
            toastOptions: { message: 'An error occured while refreshing your locations' },
            error
          });
        }

        finally {
          this.isRefreshingStores = false;
        }
      },

      storeHasErrors(storeId) {
        return this.invalidStoreIds.includes(storeId);
      },

      supportsTax(store) {
        return store.posType?.metaData?.supportsTax;
      },

      async handleToggleTestMode(store) {
        const isActive = store.isActive && !store.isVirtual;

        if (isActive) {
          this.$buefy.modal.open({
            parent: this,
            component: alertModal,
            hasModalCard: true,
            trapFocus: true,
            customClass: 'auto-width',
            props: {
              buttons: [
                { text: 'No' },
                {
                  text: 'Yes, Enable Test Mode',
                  primary: true,
                  onClick: async () => this.toggleTestMode(store)
                }
              ],
              icon: 'flask',
              horizontal: true,
              showCloseButton: false,
              title: 'Enable Test Mode',
              message: `<b>${store.description}</b> will be removed from the list of locations accepting orders, but will still accept orders for testing purposes and be browsable using a direct link.`,
              secondaryMessage: 'Are you sure you\'d like to enable test mode?',
              type: 'is-warning'
            }
          });
        }
        else {
          await this.toggleTestMode(store);
        }
      },


      // Online Ordering
      getOnlineOrderingStatus(store) {
        return this.onlineOrderingAttributes.some(orderingAttr => store.storeMappingAttributes?.find(attr => attr.code === orderingAttr.code)?.isActive);
      },

      async handleUpdateOnlineOrderingStatus(store) {
        const isEnabled = this.getOnlineOrderingStatus(store);

        if (!isEnabled) {
          try {
            const storeItems = await this.fetchStoreItems(store.storeId);


            if (!storeItems.length || storeItems.every(si => si.isDisabled)) {
              this.$buefy.modal.open({
                parent: this,
                component: alertModal,
                hasModalCard: true,
                trapFocus: true,
                canCancel: false,
                customClass: 'auto-width',
                props: {
                  title: 'Warning',
                  message: `The <b>${store.description}</b> location does not have a valid menu. Do you still want to enable Online Ordering?`,
                  icon: 'exclamation-triangle',
                  type: 'is-warning',
                  horizontal: true,
                  showCloseButton: false,
                  buttons: [
                    { text: 'No' },
                    {
                      primary: true,
                      text: 'Yes, Enable',
                      onClick: () => this.toggleOnlineOrderingStatus(store)
                    }
                  ]
                }
              });
            }

            else {
              this.toggleOnlineOrderingStatus(store);
            }
          }
          catch (error) {
            logger.error(error);
          }
        }

        else {
          this.$buefy.modal.open({
            parent: this,
            component: alertModal,
            hasModalCard: true,
            trapFocus: true,
            canCancel: false,
            customClass: 'auto-width',
            props: {
              title: 'Disable Online Ordering',
              message: 'This location will no longer accept Online orders when disabled',
              secondaryMessage: `Are you sure you want to disable Online ordering for <b>${store.description}</b>?`,
              icon: 'exclamation-circle',
              type: 'is-danger',
              horizontal: true,
              showCloseButton: false,
              buttons: [
                { text: 'No' },
                {
                  primary: true,
                  text: 'Yes, Disable',
                  onClick: () => this.toggleOnlineOrderingStatus(store)
                }
              ]
            }
          });
        }
      },

      async toggleOnlineOrderingStatus(store) {
        const newStatus = !this.getOnlineOrderingStatus(store);
        try {
          await Store.updateStore({
            storeId: store.storeId,
            storeMappingAttributes: this.onlineOrderingAttributes.map(orderingAttr => ({
              ...orderingAttr,
              isActive: newStatus,
              storeAttributeId: orderingAttr.id,
              storeId: store.storeId
            }))
          });

          this.$_onRequestSuccess({
            toastOptions: { message: `Online ordering for <b>${store.description}</b> has been successfully ${newStatus ? 'enabled' : 'disabled'}!` }
          });
        }
        catch (error) {
          this.$_onRequestError({
            error,
            toastOptions: { message: `Unable to update <b>${store.description}</b>` }
          });
        }
      },

      canToggleOnlineOrdering(storeId) {
        return this.onlineOrderingAttributes.every(attr => this.$can('update', subject('StoreMappingAttribute', { storeId, storeAttributeId: attr.id })));
      },


      // Kiosk Ordering
      getKioskStatus(store) {
        return store.storeMappingAttributes?.find(attr => attr.code === this.kioskOrderingAttribute?.code)?.isActive;
      },

      async handleUpdateKioskOrdering(store) {
        const isEnabled = this.getKioskStatus(store);

        if (!isEnabled) {
          try {
            const storeItems = await this.fetchStoreItems(store.storeId);

            if (!storeItems.length || storeItems.every(si => si.isDisabled)) {
              this.$buefy.modal.open({
                parent: this,
                component: alertModal,
                hasModalCard: true,
                trapFocus: true,
                canCancel: false,
                customClass: 'auto-width',
                props: {
                  title: 'Warning',
                  message: `The <b>${store.description}</b> location does not have a valid menu. Do you still want to enable Kiosk Ordering?`,
                  icon: 'exclamation-triangle',
                  type: 'is-warning',
                  horizontal: true,
                  showCloseButton: false,
                  buttons: [
                    { text: 'No' },
                    {
                      primary: true,
                      text: 'Yes, Enable',
                      onClick: () => this.toggleKioskOrderingStatus(store)
                    }
                  ]
                }
              });
            }

            else {
              this.toggleKioskOrderingStatus(store);
            }
          }
          catch (error) {
            logger.error(error);
          }
        }

        else {
          this.$buefy.modal.open({
            parent: this,
            component: alertModal,
            hasModalCard: true,
            trapFocus: true,
            canCancel: false,
            customClass: 'auto-width',
            props: {
              title: 'Disable Kiosk Ordering',
              message: 'This location will no longer accept Kiosk orders when disabled',
              secondaryMessage: `Are you sure you want to disable Kiosk ordering for <b>${store.description}</b>?`,
              icon: 'exclamation-circle',
              type: 'is-danger',
              horizontal: true,
              showCloseButton: false,
              buttons: [
                { text: 'No' },
                {
                  primary: true,
                  text: 'Yes, Disable',
                  onClick: () => this.toggleKioskOrderingStatus(store)
                }
              ]
            }
          });
        }
      },

      async toggleKioskOrderingStatus(store) {
        const newStatus = !this.getKioskStatus(store);
        try {
          await Store.updateStore({
            storeId: store.storeId,
            storeMappingAttributes: [
              {
                ...this.kioskOrderingAttribute,
                isActive: newStatus,
                storeAttributeId: this.kioskOrderingAttribute.id,
                storeId: store.storeId
              }
            ]
          });

          this.$_onRequestSuccess({
            toastOptions: { message: `Kiosk ordering for <b>${store.description}</b> has been successfully ${newStatus ? 'enabled' : 'disabled'}!` }
          });
        }
        catch (error) {
          this.$_onRequestError({
            error,
            toastOptions: { message: `Unable to update <b>${store.description}</b>` }
          });
        }
      },

      canToggleKioskOrdering(storeId) {
        return this.$can('update', subject('StoreMappingAttribute', { storeId, storeAttributeId: this.kioskOrderingAttribute?.id }));
      }
    }
  };
</script>

<style lang="sass" scoped>
  .name-with-errors
    display: flex
    align-items: center
    padding-bottom: 2px

</style>
