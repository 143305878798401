<template>
  <div class="clipped-image-page">
    <div class="clipped-image-page-content">
      <slot />
    </div>

    <img class="clipped-image" :src="imgSrc" alt="banner">

    <svg style="position: absolute; z-index: -1">
      <clipPath id="cfclippath">
        <title>CF_ClipPath</title>
        <!-- lg -->
        <path
          class="is-hidden-mobile is-hidden-tablet-only is-hidden-desktop-only is-hidden-widescreen-only"
          d="M1130.49,19a29,29,0,0,0-27.2-19H0L.2,945.81a29,29,0,0,0,16.86,26.31A29,29,0,0,0,48,967.85L1122.11,51a29,29,0,0,0,8.38-32"
        />

        <!-- md -->
        <path
          class="is-hidden-touch is-hidden-fullhd is-hidden-desktop-only"
          d="M1014.72,115.05A23.06,23.06,0,0,0,993.1,100h-877l.17,751.79a23,23,0,0,0,38,17.52l853.77-728.77a23.05,23.05,0,0,0,6.66-25.47"
          transform="translate(-116.13 -99.98)"
        />

        <!-- sm -->
        <path
          class="is-hidden-mobile is-hidden-fullhd"
          d="M915,197.79a17.92,17.92,0,0,0-16.81-11.72H216.13l.13,584.73a17.92,17.92,0,0,0,29.55,13.62L909.86,217.61A17.94,17.94,0,0,0,915,197.79"
          transform="translate(-216.13 -186.07)"
        />

        <!-- xs -->
        <polygon
          class="is-hidden-tablet"
          points="0 460.61 800 229.94 800 0.52 0.17 0 0 460.61"
        />
      </clipPath>
    </svg>
  </div>
</template>

<script>
  export default {
    name: 'ClippedImagePage',

    props: {
      imgSrc: {
        type: String,
        required: true
      }
    }

  };
</script>

<style lang="sass" scoped>
  .clipped-image-page
    display: flex
    align-items: center
    justify-content: center
    flex-grow: 1
    position: relative
    overflow: hidden
    background-color: #f7f7f7
    background-image: url(/images/transparent-square-tiles-dark.png)
    background-position: top left

  .clipped-image-page-content
    position: relative
    z-index: 10

  .clipped-image
    align-self: flex-start
    min-height: 480px
    position: absolute
    top: 0
    left: 0
    object-fit: cover
    clip-path: url(#cfclippath)
    -webkit-clip-path: url(#cfclippath)


  @media (max-width: $mobile)
    .clipped-image-page-content
      align-self: flex-end
      margin-bottom: 1rem

  @media (min-width: $tablet)
    .clipped-image
      min-height: 610px

  @media (min-width: $desktop)
    .clipped-image
      min-height: 780px

  @media (min-width: $fullhd)
    .clipped-image
      min-height: 980px
</style>
