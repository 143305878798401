var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('panel',{attrs:{"collapsible":"","no-padding":"","title":"Employees"}},[_c('b-loading',{attrs:{"active":_vm.isLoadingEmployees,"is-full-page":false}}),_c('searchable-table',{attrs:{"table-data":_vm.allEmployees,"search":{
      placeholder: 'Search by Name, Department or ID',
      keys: ['fullName', 'departmentName', 'internalId']
    }},scopedSlots:_vm._u([{key:"optional-actions",fn:function(){return [_c('b-button',{attrs:{"type":"is-primary","icon-left":"plus","disabled":!_vm.$can('create', 'Employee')},on:{"click":function($event){return _vm.openEmployeeModal()}}},[_vm._v(" New Employee ")])]},proxy:true},{key:"default",fn:function(ref){
    var filteredData = ref.filteredData;
return [_c('b-table',{staticClass:"is-middle-aligned",attrs:{"data":_vm.isLoadingEmployees ? [] : filteredData,"paginated":_vm.allEmployees.length > _vm.pageLimit,"pagination-simple":"","mobile-cards":false,"per-page":_vm.pageLimit}},[_c('b-table-column',{attrs:{"sortable":"","field":"fullName","label":"Employee Name","cell-class":"is-flex-align-center "},scopedSlots:_vm._u([{key:"default",fn:function(ref){
    var row = ref.row;
return [(row.photoUrl)?_c('img',{staticClass:"image is-inline is-32x32 employee-photo",attrs:{"src":row.photoUrl,"alt":"employee image"}}):_c('b-icon',{staticClass:"is-inline",style:({ height: '32px', width: '32px' }),attrs:{"icon":"user-circle","size":"is-large"}}),_c('span',{staticClass:"mar-l link-inverted",on:{"click":function($event){return _vm.openEmployeeModal(row)}}},[_vm._v(_vm._s(row.fullName))])]}}],null,true)}),_c('b-table-column',{attrs:{"sortable":"","centered":"","field":"departmentName","label":"Department"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
    var departmentName = ref.row.departmentName;
return [_vm._v(" "+_vm._s(departmentName)+" ")]}}],null,true)}),_c('b-table-column',{staticClass:"no-wrap-text is-monospaced",attrs:{"sortable":"","centered":"","numeric":"","field":"internalId","label":"Employee Id"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
    var internalId = ref.row.internalId;
return [(internalId)?_c('span',[_vm._v(_vm._s(internalId))]):_c('span',{staticClass:"has-text-grey-light"},[_vm._v("N/A")])]}}],null,true)}),_c('b-table-column',{attrs:{"centered":"","cell-class":"actions","label":"Actions","width":"1"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
    var row = ref.row;
return [_c('b-dropdown',{attrs:{"aria-role":"list","position":"is-bottom-left"}},[_c('b-button',{staticClass:"is-transparent",attrs:{"slot":"trigger","type":"is-white"},slot:"trigger"},[_c('b-icon',{attrs:{"icon":"ellipsis-v","pack":"far","size":"is-small"}})],1),_c('b-dropdown-item',{on:{"click":function($event){return _vm.openShareQRCodeModal(row)}}},[_c('b-icon',{staticClass:"mar-r-sm",attrs:{"icon":"qrcode","size":"is-small"}}),_vm._v(" View & Share QR ")],1),_c('b-dropdown-item',{on:{"click":function($event){return _vm.openEmployeeModal(row)}}},[_c('b-icon',{staticClass:"mar-r-sm",attrs:{"icon":"pencil","size":"is-small"}}),_vm._v("Edit Employee ")],1),_c('hr',{staticClass:"dropdown-divider"}),_c('b-dropdown-item',{on:{"click":function($event){return _vm.openConfirmDelete(row)}}},[_c('b-icon',{staticClass:"mar-r-sm",attrs:{"icon":"trash-alt","type":"is-danger","size":"is-small"}}),_vm._v("Delete Employee ")],1)],1)]}}],null,true)}),_c('template',{slot:"empty"},[_c('empty-table-loader',{attrs:{"loading":_vm.isLoadingEmployees,"icon":"users","message":"No Employees Found"}})],1)],2)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }