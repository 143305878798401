<template>
  <b-message
    class="is-relative has-shadow"
    :type="type"
    :duration="duration"
    :size="size"
    auto-close
    :active="active"
  >
    <div class="is-flex justify-between align-center">
      <div class="countdown-bar" :style="{'animation-duration': `${duration}ms`}" />
      <div>
        <slot />
      </div>
      <button v-if="!hideCloseButton" class="delete" @click="closeMessage" />
    </div>
  </b-message>
</template>

<script>
  export default {
    name: 'CountdownMessage',

    props: {
      duration: {
        type: Number,
        required: true
      },

      type: {
        type: String,
        default: 'is-primary'
      },

      size: {
        type: String,
        default: 'is-normal'
      },

      hideCloseButton: {
        type: Boolean,
        default: false
      },

      active: {
        type: Boolean,
        default: true
      }
    },

    data: () => ({
      timeoutId: null
    }),

    watch: {
      active: {
        immediate: true,
        handler: 'handleActiveChange'
      }
    },

    methods: {
      handleActiveChange(isActive) {
        if (isActive) {
          this.timeoutId = setTimeout(() => {
            this.closeMessage();
          }, this.duration);
        }
      },

      closeMessage() {
        clearTimeout(this.timeoutId);
        this.timeoutId = null;
        this.$emit('update:active', false);
      }
    }
  };
</script>

<style lang='sass' scoped>
  .countdown-bar
    position: absolute
    top: 0
    right: 0
    bottom: 0
    left: 0
    border-radius: $radius
    animation-name: shrink
    animation-timing-function: linear
    transform-origin: left
    pointer-events: none
    background-color: transparentize($grey, 0.95%)

    .is-primary &
      background-color: transparentize($primary, 0.95%)

    .is-danger &
      background-color: transparentize($danger, 0.95%)

    .is-warning &
      background-color: transparentize($warning, 0.88%)


  .delete
    .is-primary &
      opacity: 0.33
      background-color: $primary
      &:hover
        opacity: 0.66

    .is-danger &
      opacity: 0.4
      background-color: $danger
      &:hover
        opacity: 0.6

    .is-warning &
      opacity: 0.2
      background-color: $warning-dark
      &:hover
        opacity: 0.33

  @keyframes shrink
    0%
      transform: scaleX(1)
    100%
      transform: scaleX(0)
</style>
