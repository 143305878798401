import http from '@/services/http';
import { Model } from '@vuex-orm/core';

import { inAppMessageTypeNames } from '@/constants/merchantInAppMessages';


export default class InAppMessageType extends Model {
  static entity = 'inAppMessageTypes'


  // FIELDS //////////////////////
  static fields() {
    return {
      id: this.attr(''),
      name: this.attr('')
    };
  }

  static metadata(name) {
    switch (name) {
      case inAppMessageTypeNames.APP_LOAD:
      case inAppMessageTypeNames.HOME:
        return {
          mobileOnly: true
        };
      case inAppMessageTypeNames.LOYALTY:
      case inAppMessageTypeNames.MENU:
      case inAppMessageTypeNames.RECEIPT:
        return {
          mobileOnly: false
        };
      default:
        return {};
    }
  }

  // STATE //////////////////////
  static state() {
    return {
      fetching: false
    };
  }

  static $state() {
    return this.store().state.entities.inAppMessageTypes;
  }


  // ACTIONS //////////////////////
  static async fetchInAppMessageTypes() {
    try {
      this.commit((state) => {
        state.fetching = true;
      });

      const { data } = await http.get('in_app_message_types');
      this.create({ data: data.inAppMessageTypes });
    }

    catch (error) {
      throw error;
    }

    finally {
      this.commit((state) => {
        state.fetching = false;
      });
    }
  }
}
