<template>
  <div class="qr-scan-container">
    <transition name="fade">
      <div id="qr-viewfinder" :class="{'is-invisible': !showViewfinder}" class="card pad-sm is-flex" />
    </transition>

    <b-modal :active="!!(showCameraSelect && devices.length)" class="auto-width" :can-cancel="false">
      <div class="card pad">
        <p class="label">Select a camera:</p>
        <div class="is-flex-column gap">
          <b-button v-for="device in devices" :key="device.id" type="is-primary" @click="selectCamera(device.id)">
            {{ device.label }}
          </b-button>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
  import { Html5Qrcode } from 'html5-qrcode';
  import logger from '@/services/logger';

  export default {
    name: 'QRCodeScanner',

    props: {
      showViewfinder: {
        type: Boolean,
        default: false
      }
    },

    data() {
      return {
        html5QrcodeScanner: null,
        currentCode: null,
        devices: [],
        showCameraSelect: false
      };
    },

    mounted() {
      this.onMounted();
    },

    destroyed() {
      this.onDestroyed();
    },

    methods: {
      async onMounted() { // TEST
        setInterval(() => {
          this.currentCode = null;
        }, 5000);

        this.html5QrcodeScanner = new Html5Qrcode('qr-viewfinder');
        this.devices = await Html5Qrcode.getCameras();
        if (this.devices.length === 1) {
          this.selectCamera(this.devices[0].id);
        }
        else {
          this.showCameraSelect = true;
        }
      },

      async selectCamera(cameraId) {
        this.showCameraSelect = false;
        await this.html5QrcodeScanner.start(
          cameraId,
          {
            viewfinderWidth: 300,
            viewfinderHeight: 300,
            aspectRatio: 1
          },
          this.onScanSuccess,
          this.onScanFailure
        );
        this.$emit('qr-code-scan-start');
      },

      onDestroyed() { // TEST
        try {
          this.html5QrcodeScanner.stop();
        }
        catch (err) {
          logger.error(err);
        }
      },

      onScanSuccess(decodedText) {
        if (this.currentCode !== decodedText) {
          this.currentCode = decodedText;
          this.$emit('qr-code-scanned', decodedText);
          this.html5QrcodeScanner.clear();
        }
      },

      // eslint-disable-next-line no-unused-vars
      onScanFailure(error) {
        // NOTE: do nothing here.
        // QR Code scanner logs failures when the camera is running but not scanning an actual QR code
      }
    }
  };
</script>

<style lang="sass" scoped>
  #qr-viewfinder
    position: fixed !important
    bottom: $size-large
    right: $size-large
    width: 80vw
    max-width: 250px
    z-index: 10
</style>
