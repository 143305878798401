var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('validated-form',{ref:"form",attrs:{"form-id":"contentContactUsForm"},on:{"valid-submit":_vm.openConfirmationDialog},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('panel',{attrs:{"title":"Contact Us","subtitle":"This applies to all Applications & Devices","collapsible":"","start-open":"","loading":_vm.isFetching},scopedSlots:_vm._u([{key:"buttons",fn:function(){return [_c('b-button',{staticClass:"is-bold",attrs:{"rounded":"","size":"is-medium","native-type":"submit","type":"is-primary","loading":_vm.isLoading}},[_vm._v(" Save ")])]},proxy:true}],null,true)},[(!_vm.isFetching && _vm.merchantOptionForm && _vm.merchantContactForm)?_c('div',{staticClass:"content-form"},[_c('section',{staticClass:"form-inputs"},[_c('div',{staticClass:"mar-b-lg"},[_c('validated-input',{staticClass:"align-labels-left",attrs:{"label":"Enable Contact Us","tooltip":"Enable to show customers a 'Contact Us' page in the web and mobile apps","horizontal":"","name":"disableContactUsLink"}},[_c('b-switch',{attrs:{"true-value":false,"false-value":true},model:{value:(_vm.merchantOptionForm.disableContactUsLink),callback:function ($$v) {_vm.$set(_vm.merchantOptionForm, "disableContactUsLink", $$v)},expression:"merchantOptionForm.disableContactUsLink"}})],1),_c('b-message',{staticClass:"is-compact has-shadow is-inline-block",attrs:{"type":"is-warning"}},[_vm._v(" Changing page types and saving will delete your previous Contact Us settings ")]),_c('toggle-buttons',{attrs:{"label-left":"Use CardFree's Contact Us Page","label-right":"Use a Custom Contact Us Page","disabled":_vm.merchantOptionForm.disableContactUsLink},model:{value:(_vm.isCFContactUs),callback:function ($$v) {_vm.isCFContactUs=$$v},expression:"isCFContactUs"}})],1),_c('hr',{staticClass:"is-thick is-full-bleed"}),_c('fieldset',{attrs:{"disabled":_vm.merchantOptionForm.disableContactUsLink}},[_c('transition',{attrs:{"mode":"out-in","name":"fade-down"}},[(_vm.isCFContactUs)?_c('div',[_c('validated-text-input',{attrs:{"label":"Title","name":"name","type":"text","rules":{
                  required: _vm.isCFContactUs && !_vm.merchantOptionForm.disableContactUsLink
                }},model:{value:(_vm.merchantContactForm.title),callback:function ($$v) {_vm.$set(_vm.merchantContactForm, "title", $$v)},expression:"merchantContactForm.title"}}),_c('validated-text-input',{attrs:{"has-icon":false,"label":"Description","name":"description","type":"textarea","maxlength":"250","has-counter":true},model:{value:(_vm.merchantContactForm.description),callback:function ($$v) {_vm.$set(_vm.merchantContactForm, "description", $$v)},expression:"merchantContactForm.description"}}),_c('div',{staticClass:"is-grid col-2 gap-lg mar-b-sm"},[_c('validated-text-input',{attrs:{"label":"Phone Number","name":"phone","type":"phone","rules":{ validPhoneNumber: true },"has-icon":false,"expanded":""},model:{value:(_vm.merchantContactForm.phoneNumber),callback:function ($$v) {_vm.$set(_vm.merchantContactForm, "phoneNumber", $$v)},expression:"merchantContactForm.phoneNumber"}}),_c('validated-text-input',{attrs:{"type":"email","name":"email","label":"Email Address","has-icon":false,"expanded":""},model:{value:(_vm.merchantContactForm.email),callback:function ($$v) {_vm.$set(_vm.merchantContactForm, "email", $$v)},expression:"merchantContactForm.email"}})],1)],1):_c('validated-text-input',{attrs:{"placeholder":"i.e. http://www.cardfree.com/contact-us","has-icon":false,"label":"Custom Contact Us URL","name":"externalUrl","rules":{
                required: !_vm.isCFContactUs && !_vm.merchantOptionForm.disableContactUsLink,
                validUrl: true
              },"type":"text"},model:{value:(_vm.merchantContactForm.externalUrl),callback:function ($$v) {_vm.$set(_vm.merchantContactForm, "externalUrl", $$v)},expression:"merchantContactForm.externalUrl"}})],1)],1)])]):_vm._e()])]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }