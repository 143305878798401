import { render, staticRenderFns } from "./copy-menu-item-modal.vue?vue&type=template&id=bc2b2fc0&scoped=true&"
import script from "./copy-menu-item-modal.vue?vue&type=script&lang=js&"
export * from "./copy-menu-item-modal.vue?vue&type=script&lang=js&"
import style0 from "./copy-menu-item-modal.vue?vue&type=style&index=0&id=bc2b2fc0&lang=sass&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "bc2b2fc0",
  null
  
)

export default component.exports