<template>
  <validated-form
    ref="form"
    v-slot="{ errors }"
    auto-focus
    form-id="addEditCommunicationForm"
    :disabled="!$can('update', 'StoreMappingCommunication')"
    @valid-submit="handleSubmit"
  >
    <modal-card :title="modalTitle">
      <validated-text-input
        v-model="form.name"
        :has-icon="false"
        label="Name"
        name="name"
        rules="required"
        type="text"
      />

      <hr>

      <div class="communication-settings-container">
        <div>
          <b-checkbox v-if="store.emailAddress || store.phoneNumber" class="pad-b" :value="isStoreContact" @input="handlePrefillChange">
            Use Location Contact Info
          </b-checkbox>
          <validated-text-input
            v-model="form.phoneNumber"
            :has-icon="false"
            label="SMS Number"
            name="phoneNumber"
            type="phone"
            :rules="{
              required: !form.emailAddress && !form.phoneNumber,
              validPhoneNumber: true
            }"
            :hide-error-messages="Boolean((errors.phoneNumber && errors.phoneNumber.length) && (errors.emailAddress && errors.emailAddress.length))"
            :disabled="isStoreContact"
          />
          <validated-text-input
            v-model="form.emailAddress"
            :has-icon="false"
            label="Email Address"
            name="emailAddress"
            type="email"
            :rules="{ required: !form.emailAddress && !form.phoneNumber }"
            :hide-error-messages="Boolean((errors.phoneNumber && errors.phoneNumber.length) && (errors.emailAddress && errors.emailAddress.length))"
            :disabled="isStoreContact"
          />
          <b-field v-show="(errors.phoneNumber && errors.phoneNumber.length) && (errors.emailAddress && errors.emailAddress.length)">
            <b-message type="is-danger" size="is-small" has-icon class="is-compact has-shadow">
              A Phone Number or Email Address is required
            </b-message>
          </b-field>
        </div>

        <hr>

        <div class="preferences-container">
          <p class="subtitle is-5">Notification Settings For:</p>
          <div class="communication-methods">
            <span />
            <p class="label is-marginless">
              Email
            </p>
            <p class="label is-marginless">
              SMS
            </p>

            <!-- All New Orders -->
            <p class="label is-marginless notification-type is-flex align-center">
              All New Orders
              <b-icon
                v-if="!store.emailOrderReceiptEnabled || !store.smsOrderReceiptEnabled"
                v-tippy="{
                  content: newOrderWarning,
                  maxWidth: 220
                }"
                class="mar-l-xs"
                icon="exclamation-circle"
                pack="far"
                size="is-small"
                type="is-danger"
              />
            </p>
            <b-checkbox
              v-model="form.receivesOrderNotificationEmail"
              class="no-label is-flex"
            />
            <b-checkbox
              v-model="form.receivesOrderNotification"
              class="no-label is-flex"
            />

            <!--     Only Catering Orders       -->
            <template v-if="$_selectedMerchant.cateringEnabled">
              <p class="label is-marginless notification-type">
                Only Catering Orders
                <b-icon
                  v-if="!store.cateringNotificationEnabled"
                  v-tippy="{
                    content: `&quot;Catering Orders Only&quot; notifications are disabled for this location; manage the setting in Store Notification Types`,
                    maxWidth: 220
                  }"
                  class="mar-l-xs"
                  icon="exclamation-circle"
                  pack="far"
                  size="is-small"
                  type="is-danger"
                />
              </p>
              <b-checkbox
                v-model="form.receivesCateringNotificationEmail"
                class="no-label"
              />
              <b-checkbox
                v-model="form.receivesCateringNotification"
                class="no-label"
              />
            </template>

            <!-- I Am Here -->
            <template v-if="$_selectedMerchant.features.iAmHere">
              <p class="label is-marginless notification-type">
                I Am Here
                <b-icon
                  v-tippy="{
                    content: 'Standard on-site customer arrival alerts (not Text To Pay)',
                    maxWidth: 300,
                    delay: [150, 0]
                  }"
                  icon="info-square"
                  size="is-small"
                  type="is-grey"
                />
                <b-icon
                  v-if="!store.onSiteNotificationEnabled && !store.onSiteCurbsideNotificationEnabled && !store.onSiteDriveThroughNotificationEnabled"
                  v-tippy="{ content: '&quot;I Am Here&quot; notifications are disabled at this location; manage the settings in Store Notification Types or in the Ordering Modes tab', maxWidth: 220 }"
                  class="mar-l-xs"
                  icon="exclamation-circle"
                  pack="far"
                  size="is-small"
                  type="is-danger"
                />
              </p>
              <b-checkbox v-model="form.receivesOnsiteNotificationEmail" class="no-label" />
              <b-checkbox v-model="form.receivesOnsiteNotification" class="no-label" />
            </template>

            <!--     Order Paid Notifications       -->
            <p class="label is-marginless notification-type">
              Order Paid
              <b-icon
                v-if="!store.orderPaidNotificationEnabled"
                v-tippy="{ content: '&quot;Order Paid&quot; notifications are disabled at this location; manage the settings in Store Notification Types', maxWidth: 220 }"
                class="mar-l-xs"
                icon="exclamation-circle"
                pack="far"
                size="is-small"
                type="is-danger"
              />
            </p>
            <b-checkbox
              v-model="form.receivesPaymentNotificationEmail"
              class="no-label"
            />
            <b-checkbox
              v-model="form.receivesPaymentNotification"
              class="no-label"
            />

            <!-- Order Fails -->
            <p class="label is-marginless notification-type">
              Order Fails
            </p>
            <b-checkbox v-model="form.futureOrderErrorNotification" class="no-label" />
            <span />

            <!-- Order Cleanup -->
            <template v-if="$_selectedMerchant.payAtTableOrderCleanupEnabled">
              <p class="label is-marginless notification-type">
                Order Cleanup
              </p>
              <b-checkbox v-model="form.orderCleanupNotificationEmail" class="no-label" />
              <span />
            </template>
          </div>
        </div>
      </div>

      <template v-if="$can('update', 'StoreMappingCommunication')" #footer>
        <div class="buttons all-bold">
          <b-button
            rounded
            @click="$_confirmCloseModal({ programmatic: true })"
          >
            Cancel
          </b-button>
          <b-button
            v-tabbable
            rounded
            native-type="submit"
            type="is-primary"
            :loading="storeCommunicationSubmitting"
          >
            Save
          </b-button>
        </div>
      </template>
    </modal-card>
  </validated-form>
</template>

<script>
  import StoreCommunication from '@/store/classes/StoreCommunication';
  import Store from '@/store/classes/Store';
  import merchantMixin from '@/mixins/merchant';

  import confirmModalCloseMixin from '@/mixins/confirm-modal-close';


  export default {
    name: 'AddEditCommunicationModal',

    mixins: [confirmModalCloseMixin, merchantMixin],

    props: {
      storeCommunicationEdited: {
        type: Object,
        default: null
      },

      mode: {
        type: String,
        default: 'create',
        validator(value) {
          return ['create', 'read', 'update'].includes(value);
        }
      }
    },

    data() {
      return {
        form: null,
        isStoreContact: false
      };
    },

    computed: {
      storeCommunication() {
        return this.mode === 'create'
          ? new StoreCommunication({ storeId: this.$route.params.storeId })
          : this.storeCommunicationEdited;
      },

      store() {
        return Store.find(this.$route.params.storeId);
      },

      storeCommunicationSubmitting() {
        return StoreCommunication.$state().submitting;
      },

      isUsingStoreContactInfo() {
        return Boolean(this.store.phoneNumber && this.store.phoneNumber === this.storeCommunication.phoneNumber
          && this.store.emailAddress && this.store.emailAddress === this.storeCommunication.emailAddress);
      },

      modalTitle() {
        let title = '';
        switch (this.mode) {
          case 'create':
            title = 'Add New Recipient';
            break;

          case 'update':
            title = 'Update Notification Preferences';
            break;

          default:
            break;
        }

        return title;
      },

      newOrderWarning() {
        let notificationType;

        if (!this.store.emailOrderReceiptEnabled && !this.store.smsOrderReceiptEnabled) {
          notificationType = 'Email/SMS ';
        }
        else if (!this.store.emailOrderReceiptEnabled) {
          notificationType = 'SMS';
        }
        else if (!this.store.smsOrderReceiptEnabled) {
          notificationType = 'Email';
        }
        else {
          return '';
        }
        return `New Order ${notificationType} notifications are disabled for this location; manage the setting in Store Notification Types`;
      }
    },

    created() {
      this.onCreated();
    },

    methods: {
      onCreated() {
        this.form = JSON.parse(JSON.stringify(this.storeCommunication));
        if (this.isUsingStoreContactInfo) {
          this.isStoreContact = true;
        }
      },

      handlePrefillChange(isChecked) {
        this.isStoreContact = isChecked;

        if (isChecked) {
          this.form.emailAddress = this.store.emailAddress;
          this.form.phoneNumber = this.store.phoneNumber;
        }
        else {
          this.form.emailAddress = '';
          this.form.phoneNumber = '';
        }
      },

      handleSubmit() {
        switch (this.mode) {
          case 'create':
            this.addStoreCommunication();
            break;

          case 'update':
            this.updateStoreCommunication();
            break;

          default:
            break;
        }
      },

      async addStoreCommunication() {
        try {
          await StoreCommunication.addStoreCommunication(this.form);
          this.$_onRequestSuccess({
            toastOptions: {
              message: 'Successfully added new recipient!'
            },
            options: {
              closeParent: true
            }
          });
        }

        catch (error) {
          this.$_onRequestError({
            error,
            toastOptions: {
              message: 'An error occured while adding your new recipient'
            }
          });
        }
      },

      async updateStoreCommunication() {
        try {
          await StoreCommunication.updateStoreCommunication(this.form);
          this.$_onRequestSuccess({
            toastOptions: {
              message: 'Successfully updated notification preferences!'
            },
            options: {
              closeParent: true
            }
          });
        }

        catch (error) {
          this.$_onRequestError({
            error,
            toastOptions: {
              message: 'An error occured while updating your notification preferences'
            }
          });
        }
      }
    }
  };
</script>

<style lang="sass" scoped>
  .communication-methods
    display: grid
    grid-template-columns: 150px 1fr 1fr
    gap: $size-large 0
    justify-items: center

  .notification-type
    justify-self: start

</style>
