<template>
  <div>
    <div
      v-for="item in searchResults"
      :key="`item-${item.id}`"
      :data-item-id="item.id"
    >
      <div class="row sub-row">
        <span class="pad-y-sm">
          <div class="is-flex-column">
            <span class="has-text-weight-bold is-size-6">{{ item.displayName }}
              <b-icon
                v-if="!item.mappedToPos && !$_hasPosType(posTypes.Cardfree)"
                v-tippy="{ content: 'Missing POS Mapping', placement: 'right' }"
                size="is-small"
                icon="exclamation-triangle"
                type="is-danger"
                class="mar-l"
              />
            </span>
            <span v-if="item.menuCategories.length" class="is-size-7"><b>Category:</b> {{ item.menuCategories[0].displayName }}</span>
          </div>
        </span>
        <span />
        <span class="align-center justify-end">
          <b-button
            v-if="(item.hasSchedule || item.availabilityBeginDate || item.availabilityEndDate) && menuType"
            v-tippy="{ content: 'Restricted Availability', placement: 'left', delay: [300, 0] }"
            class="is-transparent"
            @click="emitSharedModGroupsSearchAction({actionName: 'open-item-modal', args: { category: item.menuCategories[0], itemId: item.id, mode: 'update', defaultTabIndex: 2 }})"
          >
            <b-icon icon="calendar" pack="far" />
          </b-button>

          <template v-if="menuType">
            <tippy
              v-if="$can('update', 'MenuItem') && $_menuPermissions.MANAGE_MENU_TYPE"
              placement="left"
              max-width="500"
            >
              <template #trigger>
                <b-button class="is-transparent" @click="emitSharedModGroupsSearchAction({ actionName: 'confirm-remove-from-menu-type', args: item })">
                  <b-icon icon="minus-circle" type="is-danger" size="is-small" />
                </b-button>
              </template>
              <div class="is-size-6">
                Remove <span class="has-text-weight-semibold">{{ item.displayName }}</span> from {{ menuType.displayName }} Menu
              </div>
            </tippy>
          </template>
          <template v-else>
            <b-button class="is-transparent" @click="emitSharedModGroupsSearchAction({ actionName: 'open-item-modal', args: { category: item.menuCategories[0], itemId: item.id, mode: 'update' }})">
              <b-icon v-if="$can('update', 'MenuItem') && $_menuPermissions.EDIT_RESOURCE" icon="pencil" size="is-small" />
              <span v-else>View</span>
            </b-button>
            <b-dropdown
              v-if="[($can('create', 'MenuItem') && $_menuPermissions.MANAGE_DAYPART_SCHEDULE), ($can('destroy', 'MenuItem') && $_menuPermissions.REMOVE_RESOURCE)].some(x => x)"
              aria-role="list"
              position="is-bottom-left"
              :mobile-modal="false"
            >
              <b-button slot="trigger" class="is-transparent" type="is-white">
                <b-icon icon="ellipsis-v" pack="far" size="is-small" />
              </b-button>
              <b-dropdown-item @click="emitSharedModGroupsSearchAction({ actionName: 'open-category-association-modal', args: item })">
                <b-icon icon="layer-group" class="mar-r-sm" size="is-small" />
                Manage Category Associations
              </b-dropdown-item>
              <b-dropdown-item v-if="$can('create', 'MenuItem') && $_menuPermissions.CLONE_ITEM" @click="emitSharedModGroupsSearchAction({ actionName: 'open-duplicate-menu-item-modal', args: item })">
                <b-icon
                  icon="copy"
                  class="mar-r-sm"
                  size="is-small"
                />
                Copy Item
              </b-dropdown-item>
              <template v-if="$can('destroy', 'MenuItem') && $_menuPermissions.REMOVE_RESOURCE">
                <hr class="dropdown-divider">
                <b-dropdown-item class="is-danger" @click="emitSharedModGroupsSearchAction({ actionName: 'open-delete-menu-item-confirmation', args: { category: item.menuCategories[0], item } })">
                  <b-icon icon="trash-alt" pack="far" class="mar-r-sm" size="is-small" />
                  Delete Item from <b>Category</b>
                </b-dropdown-item>
                <b-dropdown-item class="is-danger" @click="emitSharedModGroupsSearchAction({ actionName: 'open-delete-menu-item-from-menu-confirmation', args: { item } })">
                  <b-icon icon="trash-alt" class="mar-r-sm" size="is-small" />
                  Delete Item from <b>Entire Menu</b>
                </b-dropdown-item>
              </template>
            </b-dropdown>
          </template>
        </span>
      </div>
    </div>
  </div>
</template>

<script>
  import merchantMixin from '@/mixins/merchant';
  import featurePermissionsMixin from '@/mixins/featurePermissions';
  import posTypes from '@/constants/posTypes';

  export default {
    name: 'ItemSearchRow',

    mixins: [merchantMixin, featurePermissionsMixin],

    props: {
      searchResults: {
        type: Array,
        default: () => []
      },

      menuType: {
        type: Object,
        default: null
      }
    },

    data: () => ({
      posTypes
    }),

    methods: {
      emitSharedModGroupsSearchAction(args) {
        this.$emit('shared-mod-group-search-action', args);
      }
    }
  };
</script>
