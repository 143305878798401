<template>
  <div class="nav-bar">
    <b-dropdown ref="secret-menu" :triggers="[]" class="has-extra-shadow">
      <template #trigger>
        <router-link class="home-link" :to="{ name: 'home' }" @click.native="secretMenuListener">
          <div :class="['cf-logo', $_selectedMerchantId && 'with-merchant']" role="img" aria-label="Admin Portal by CardFree">
            <img class="cf-logo-left" src="/images/cf-logo-left.svg">
            <img class="cf-logo-right" src="/images/cf-logo-right.svg">
          </div>
          <h3 v-if="!$_selectedMerchantId || !$screen.widescreen" class="merchant-portal-title subtitle is-4 mar-b-none mar-r-sm">Merchant Portal</h3>
        </router-link>
      </template>

      <b-dropdown-item custom class="is-flex justify-between align-center has-text-weight-bold" style="font-size: 1.2rem">
        Secret Menu
        <b-icon icon="grin-tongue-wink" />
      </b-dropdown-item>
      <hr class="dropdown-divider">
      <b-dropdown-item custom class="is-flex justify-between align-center">
        <div class="subtitle is-6 mar-none">Party Mode</div>
        <b-switch v-model="isPartyTime" size="is-small" class="no-label" />
      </b-dropdown-item>
    </b-dropdown>

    <merchant-dropdown v-if="$_selectedMerchantId && $screen.widescreen" :disabled="!($can('create', 'Merchant') || isMultiMerchantUser)" class="merchant-dropdown mar-l-sm">
      <div :class="['selected-merchant-container is-flex align-center', { 'is-selectable': isMultiMerchantUser || isCardfreeAdmin }]">
        <img v-if="avatarUrl" class="selected-merchant-logo animated animated-slow tdFadeInRight" :src="avatarUrl" alt="">
        <div class="is-flex align-center">
          <h3 class="subtitle is-4 is-marginless selected-merchant-name">{{ $_selectedMerchant && $_selectedMerchant.name }}</h3>
          <b-tag
            v-if="$_selectedMerchant && !$_selectedMerchant.isActive"
            class="has-text-weight-bold mar-l"
            type="is-danger is-light"
            size="is-medium"
          >
            Deactivated
          </b-tag>

          <b-tag
            v-if="$_selectedMerchant && $_selectedMerchant.isTemplate"
            class="has-text-weight-bold mar-l"
            type="is-warning is-light"
            size="is-medium"
          >
            Template
          </b-tag>
        </div>
      </div>
    </merchant-dropdown>

    <div id="hamburger" @click="toggleSidebar">
      <b-icon pack="fal" :icon="sidebarVisible ? 'times' : 'bars'" />
    </div>

    <div class="nav-tools is-flex align-center justify-between gap">
      <div v-if="envName" class="env-indicator">
        {{ envName }}
        <b-icon v-if="envName === 'LOCAL'" icon="laptop-code" />
        <b-icon v-else-if="envName === 'DEV'" icon="server" />
      </div>

      <div v-if="$_selectedMerchantId" class="documentation-container is-flex-align-center">
        <documentation />
      </div>

      <template v-if="isOrderExpoPage">
        <b-button
          v-if="isUsingPwa"
          type="is-light"
          rounded
          class="expo-tools-toggle"
          data-test-id="expo-tools-button"
          @click="$emit('toggle-layout-element', 'expoToolsEnabled')"
        >
          <b-icon
            type="is-primary"
            icon="arrow-up-right-and-arrow-down-left-from-center"
          />
        </b-button>
        <b-button
          v-else
          class="is-transparent"
          data-test-id="fullscreen-button"
          @click="$emit('toggle-layout-element', 'isFullscreen')"
        >
          <b-icon
            type="is-primary"
            icon="expand"
          />
        </b-button>
      </template>
    </div>

    <global-search
      v-if="$_selectedMerchantId"
      :is-visible="showGlobalSearch"
      @toggle-visibility="toggleGlobalSearch"
    />
  </div>
</template>

<script>
  import { mapGetters } from 'vuex';
  import merchantMixin from '@/mixins/merchant';
  import documentation from '@/components/globals/documentation.vue';
  import globalSearch from '@/components/global-search/global-search.vue';
  import miniSearch from '@/services/global-search';
  import storage from '@/services/storage';

  export default {
    name: 'NavigationBar',

    components: { documentation, globalSearch },

    mixins: [merchantMixin],

    props: {
      sidebarVisible: {
        type: Boolean,
        required: true
      }
    },

    data() {
      return {
        searchOpened: false,
        searchQuery: '',
        showGlobalSearch: false,
        removeKeyboardShortcut: null
      };
    },

    computed: {
      ...mapGetters('session', ['isMultiMerchantUser', 'isCardfreeAdmin', 'isUsingPwa']),

      envName() {
        const hostname = window.location.hostname;

        if (hostname.includes('dev')) {
          return 'DEV';
        }
        else if (hostname === 'localhost') {
          return 'LOCAL';
        }

        return '';
      },

      avatarUrl() {
        return this.$_selectedMerchant?.merchantOption?.avatarUrl;
      },

      isOrderExpoPage() {
        return this.$route.name === 'cardFreeQueue';
      },

      isPartyTime: {
        get() {
          return storage.local.get('party-mode');
        },
        set(value) {
          storage.local.set('party-mode', value);
          if (value) {
            this.$_onRequestSuccess({
              toastOptions: { message: 'Party Mode Enabled', icon: 'laugh-beam' }
            });
          }
          else {
            this.$confetti({
              origin: { x: 0.96, y: 1 },
              angle: 115,
              colors: ['#930b26', '#f14668']
            });
            this.$_onRequestError({
              toastOptions: { message: 'Party Mode Disabled', icon: 'dizzy' }
            });
          }
        }
      }
    },

    mounted() {
      this.removeKeyboardShortcut = miniSearch.setupKeyboardShortcut(this.toggleGlobalSearch.bind(this));
    },

    beforeDestroy() {
      if (this.removeKeyboardShortcut) {
        this.removeKeyboardShortcut();
      }
    },

    methods: {
      toggleSidebar() {
        this.$emit('toggle-layout-element', 'sidebarVisible');
      },

      secretMenuListener({ detail }) {
        if (detail === 4) this.$refs['secret-menu'].toggle();
      },

      toggleGlobalSearch(forcedState = !this.showGlobalSearch) {
        if (this.$_selectedMerchantId) {
          this.showGlobalSearch = forcedState;
        }
        else {
          this.$buefy.toast.open({
            message: 'Global search requires a merchant to be selected.',
            type: 'is-warning',
            duration: 3000
          });
        }
      }
    }
  };
</script>

<style lang="sass" scoped>
  .nav-bar
    position: fixed
    z-index: 31
    top: 0
    left: 0
    right: 0
    display: flex
    align-items: center
    min-height: $navbar-height
    box-shadow: 0 3px 5px rgba(0, 0, 0, 0.1)
    border-bottom: 1px solid #dedede
    background-color: $white

  #hamburger
    display: none

  .home-link
    display: flex

  .nav-tools
    position: absolute
    right: 0
    margin-right: 0.5rem

  .documentation-container
    color: $primary

  .env-indicator
    font-size: $size-5
    font-weight: bold
    color: $primary

  // positions the left & right halves of the
  // CardFree logo to allow for a fun hover effect
  .cf-logo
    display: flex
    justify-content: center
    width: 75px
    line-height: 1
    .cf-logo-left, .cf-logo-right
      transition: transform 200ms
      display: inline-block
      height: 1.75rem
    .cf-logo-left
      transform: translate(8px, 2px)
    .cf-logo-right
      transform: translate(-8px, -2px)

    &.with-merchant
      align-items: center
      height: 2rem
      border-right: 2px solid $grey-lightest

  .selected-merchant-container
    .selected-merchant-logo
      height: 2rem
      margin-right: 0.75rem

    &.is-selectable:hover
      cursor: pointer

      .selected-merchant-name,
      .merchant-portal-title
        color: $primary !important

  .merchant-dropdown.is-disabled
    cursor: default
    opacity: 1


  @media (hover: hover)
    // adds a fun hover effect to the CardFree logo
    .home-link:hover
      .cf-logo-left
        transform: translate(11px, 0px)
      .cf-logo-right
        transform: translate(-11px, 0px)

  @media (max-width: 360px)
    .merchant-portal-title
      display: none

  @media (max-width: $widescreen)
    .nav-bar
      justify-content: center

    .cf-logo.with-merchant
      border-right: none

    #hamburger
      display: block
      position: absolute
      top: 0
      bottom: 0
      left: 0
      padding: 0.75rem
      transition: background-color 100ms

      &:hover
        cursor: pointer
        background-color: $grey-lightest

      .icon
        pointer-events: none
        transform: translateY(2px)

</style>
