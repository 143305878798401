const offerDistributionStatuses = {
  ACTIVE: 'active',
  SCHEDULED: 'scheduled',
  EXPIRED: 'expired'
};

const claimStyles = {
  CLAIM_TO_ALLOCATION: {
    id: 1,
    style: 'claimToAllocation',
    display: 'Allocation',
    subtitle: 'Add the offer to the member\'s account for future use'
  },
  CLAIM_TO_REDEMPTION: {
    id: 2,
    style: 'claimToRedemption',
    display: 'Redemption',
    subtitle: 'Apply the offer to the order immediately'
  }
};

export {
  offerDistributionStatuses,
  claimStyles
};
