<template>
  <div class="is-flex-column align-self-start is-full-width pad-x-lg">
    <h4 class="has-text-weight-bold is-size-4 pad-b-md pad-l-xs">{{ title }}</h4>
    <b-loading :active="loading" :is-full-page="false" />
    <slot />
  </div>
</template>

<script>
  export default {
    name: 'SectionWrapper',

    props: {
      title: {
        type: String,
        required: true
      },
      loading: {
        type: Boolean,
        default: false
      }
    }
  };
</script>
