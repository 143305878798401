<template>
  <validated-form
    ref="addEditGiftCardDesignForm"
    auto-focus
    form-id="addEditGiftCardDesignForm"
    @valid-submit="handleSubmit"
  >
    <modal-card :title="`${giftCardDesign.id ? 'Update' : 'New'} Gift Card Design`">
      <image-upload
        v-slot="{ imagePath }"
        v-model="giftCardDesignImage"
        :accepted-types="['png', 'svg']"
        :image-size-warning-height="300"
        :image-size-warning-width="500"
        :maximum-file-size-in-mb="1"
        restrict-file-size
        :image-url="form.fullUrl"
        label="Upload an Image"
        clear-only
        clear-text="Clear Image"
        is-full-width
        :required="!giftCardDesign.id"
      >
        <img
          v-if="imagePath"
          :src="imagePath"
          alt="gift card design image"
        >
      </image-upload>
      <validated-text-input
        v-model="form.name"
        label="Design Name"
        sub-label="For internal use only"
        name="name"
        rules="required"
        type="text"
      />
      <div class="mar-b-sm mar-t-sm" data-test-id="active-switch">
        <b-switch
          v-model="form.isActive"
          left-label
          class="has-text-weight-bold"
          style="height: 42px;"
          :disabled="isFirstGiftCardDesign || isOnlyActiveDesign"
        >
          Active
        </b-switch>
        <transition name="fade-down">
          <b-message
            v-if="isCurrentDefault && !form.isActive || isOnlyActiveDesign"
            type="is-warning"
            size="is-small"
            class="is-compact has-shadow justify-self-start"
            has-icon
          >
            {{
              isOnlyActiveDesign
                ? "This is the only active design. You must have at least one active."
                : "Deactivating this design will move the default status to the first active design in the list"
            }}
          </b-message>
        </transition>
      </div>
      <div class="mar-b-sm" data-test-id="default-switch">
        <b-switch
          v-model="form.isDefault"
          left-label
          class="has-text-weight-bold"
          :disabled="isFirstGiftCardDesign || isCurrentDefault"
          style="height: 42px;"
        >
          Default Design
        </b-switch>
        <transition name="fade-down">
          <b-message
            v-if="!isFirstGiftCardDesign && !isCurrentDefault && form.isDefault"
            type="is-warning"
            size="is-small"
            class="is-compact has-shadow justify-self-start"
            has-icon
          >
            Setting this as the default will clear the previous selection {{ !form.isActive ? "and activate this design" : "" }}
          </b-message>
        </transition>
      </div>
      <template #footer>
        <div class="buttons all-bold">
          <b-button
            rounded
            @click="$_confirmCloseModal({ programmatic: true })"
          >
            Cancel
          </b-button>

          <b-button
            v-tabbable
            rounded
            native-type="submit"
            type="is-primary"
            :loading="isSubmitting"
            :disabled="isSubmitting"
          >
            Save
          </b-button>
        </div>
      </template>
    </modal-card>
  </validated-form>
</template>

<script>
  import confirmModalCloseMixin from '@/mixins/confirm-modal-close';

  import MerchantGiftCardDesign from '@/store/classes/MerchantGiftCardDesign';

  export default {
    name: 'AddEditGiftCardDesignModal',

    mixins: [confirmModalCloseMixin],

    props: {
      giftCardDesign: {
        type: Object,
        default: () => ({})
      }
    },

    data() {
      return {
        form: null,
        imageFile: null
      };
    },

    computed: {
      isSubmitting() {
        return MerchantGiftCardDesign.$state().submitting;
      },

      giftCardDesignImage: {
        get() {
          return this.imageFile;
        },

        set(value) {
          if (!value) {
            this.form.url = null;
            this.form.fullUrl = null;
            this.imageFile = null;
          }
          else {
            this.imageFile = value;
          }
        }
      },

      isFirstGiftCardDesign() {
        return !MerchantGiftCardDesign.query().all().length;
      },

      isOnlyActiveDesign() {
        const activeGiftCards = MerchantGiftCardDesign.query().where('isActive', true).all();
        return activeGiftCards.length === 1 && activeGiftCards[0].id === this.form.id;
      },

      currentDefault() {
        return MerchantGiftCardDesign.query().where('isDefault', true).first();
      },

      isCurrentDefault() {
        return this.currentDefault?.id === this.form.id;
      }
    },

    created() {
      this.onCreated();
    },

    methods: {
      onCreated() {
        this.form = this.$clone(this.giftCardDesign);
      },

      async uploadImage(imageFile) {
        try {
          return await MerchantGiftCardDesign.uploadImage(imageFile);
        }
        catch (error) {
          this.$_onRequestError({
            error,
            toastOptions: {
              message: 'There was an error uploading your gift card design image'
            }
          });
        }
      },

      async unsetCurrentDefault() {
        const currentDefault = this.$clone(this.currentDefault);
        currentDefault.isDefault = false;
        await MerchantGiftCardDesign.updateMerchantGiftCardDesign(currentDefault);
      },

      async defaultFirstInSortOrder() {
        const firstDesignInSortOrder = MerchantGiftCardDesign.query()
          .where('isActive', true)
          .where(design => design.id !== this.form.id)
          .orderBy('sortOrder', 'asc')
          .first();

        if (firstDesignInSortOrder) {
          firstDesignInSortOrder.isDefault = true;
          await MerchantGiftCardDesign.updateMerchantGiftCardDesign(firstDesignInSortOrder);
        }
      },

      async handleSubmit() {
        try {
          if (!this.isFirstGiftCardDesign) {
            const settingDesignAsNewDefault = this.form.isDefault && !this.isCurrentDefault;
            if (settingDesignAsNewDefault) {
              this.form.isActive = true;
              await this.unsetCurrentDefault();
            }

            const deactivatingDefaultDesign = !this.form.isActive && this.isCurrentDefault;
            if (deactivatingDefaultDesign) {
              await this.defaultFirstInSortOrder();
              this.form.isDefault = false;
            }
          }

          if (this.imageFile) {
            this.form.url = await this.uploadImage(this.imageFile);
          }
          if (this.form.id) {
            await MerchantGiftCardDesign.updateMerchantGiftCardDesign(this.form);
          }
          else {
            await MerchantGiftCardDesign.createMerchantGiftCardDesign(this.form);
          }

          this.$_onRequestSuccess({
            toastOptions: {
              message: `Your gift card design was successfully ${this.form.id ? 'updated' : 'created'}!`
            },
            options: {
              closeParent: true
            }
          });
        }

        catch (error) {
          this.$_onRequestError({
            error,
            toastOptions: {
              message: `There was an error ${this.form.id ? 'updating' : 'adding'} your gift card design`
            }
          });
        }
      }
    }
  };
</script>
