<template>
  <div class="is-grid col-2 col-1-fullhd gap-lg">
    <validated-form
      v-slot="{ dirty }"
      ref="storeHoursForm"
      class="card"
      form-id="storeHoursForm"
      @valid-submit="handleSubmit"
    >
      <div class="card-header-sticky">
        <div>
          <h3 class="title is-4">Standard Hours</h3>
          <h4 class="subtitle is-6 has-text-grey">The standard hours of operation for this location</h4>
        </div>
        <b-button
          rounded
          size="is-medium"
          type="is-primary"
          :loading="isLoading"
          native-type="submit"
          :class="['is-bold', dirty && 'pulse-slow']"
          @click="manualValidation"
        >
          Save
        </b-button>
      </div>
      <store-standard-hours-inputs v-model="ssh_form" :invalid-days="ssh_invalidDays" />
    </validated-form>

    <store-special-hours :store-id="store.storeId" />
  </div>
</template>



<script>
  import storeStandardHoursInputs from './store-standard-hours-inputs.vue';
  import storeSpecialHours from './store-special-hours.vue';
  import storeStandardHoursMixin from '@/mixins/store-standard-hours';
  import Store from '@/store/classes/Store';

  export default {
    name: 'StoreHours',

    components: {
      storeSpecialHours,
      storeStandardHoursInputs
    },

    mixins: [storeStandardHoursMixin],

    props: {
      store: {
        type: Object,
        required: true
      }
    },

    data() {
      return {
        isLoading: false
      };
    },

    created() {
      this.$_onCreated();
    },

    methods: {
      toggleLoading(value) {
        this.isLoading = value;
      },

      manualValidation() {
        // NOTE: Would be better to refactor this to use the manual-validation prop in validated-form
        // Causing async issues that way
        return this.$_validateHours(this.ssh_form.hoursForm);
      },

      async updateStore() {
        await Store.updateStore({
          storeId: this.store.storeId,
          sunIsOpen: this.ssh_form.sunIsOpen,
          monIsOpen: this.ssh_form.monIsOpen,
          tueIsOpen: this.ssh_form.tueIsOpen,
          wedIsOpen: this.ssh_form.wedIsOpen,
          thuIsOpen: this.ssh_form.thuIsOpen,
          friIsOpen: this.ssh_form.friIsOpen,
          satIsOpen: this.ssh_form.satIsOpen
        });
      },

      async handleSubmit() {
        try {
          this.toggleLoading(true);

          const { added, updated, deletedIds } = this.$_formatHoursPayload(this.ssh_form.hoursForm);

          await Promise.all([
            ...added.map(week => Store.addStoreMappingHour({ storeId: this.store.storeId, storeMappingHour: week })),
            ...updated.map(week => Store.updateStoreMappingHour(week)),
            ...deletedIds.map(id => Store.deleteStoreMappingHour(id))
          ]);
          await this.updateStore();

          this.ssh_form.hoursForm = this.$_createHoursForm(this.$_storeHoursByDay);
          this.ssh_invalidDays = [];

          this.$_onRequestSuccess({
            toastOptions: { message: `<b>${this.store.description}</b> hours successfully updated!` }
          });
        }

        catch (error) {
          this.$_onRequestError({ error, toastOptions: { message: 'There was an issue updating the hours for this location' } });
        }

        finally {
          this.toggleLoading(false);
        }
      }
    }
  };
</script>
