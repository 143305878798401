<template>
  <div class="detail-page is-flex-column">
    <hero-banner>
      <h1 class="title is-2">Regional Menus</h1>
      <template #right>
        <b-button
          v-if="regionMenus.length"
          rounded
          inverted
          size="is-medium"
          type="is-primary"
          icon-left="plus"
          :disabled="!$can('create', 'RegionMenu')"
          @click="openRegionMenuModal()"
        >
          New Regional Menu
        </b-button>
      </template>
    </hero-banner>

    <div class="flex-grow is-relative">
      <b-loading v-if="isFetchingRegionMenus" :is-full-page="false" active />

      <div v-else class="section">
        <div class="container">
          <div v-if="regionMenus.length" class="card">
            <searchable-table
              v-slot="{ filteredData }"
              :table-data="regionMenus"
              :search="{
                placeholder: 'Search by Regional Menu Name',
                keys: ['name']
              }"
            >
              <b-table
                :data="isFetchingRegionMenus ? [] : filteredData"
                :paginated="regionMenus.length > pageLimit"
                class="is-middle-aligned"
                pagination-simple
                :per-page="pageLimit"
                :mobile-cards="false"
              >
                <b-table-column v-slot="{ row: { name, id } }" sortable field="name" label="Name">
                  <span class="link" @click="openRegionMenuModal(id)">{{ name }}</span>
                </b-table-column>

                <b-table-column v-slot="{ row }" numeric label="Actions" width="1">
                  <b-button
                    class="is-transparent"
                    type="is-danger"
                    inverted
                    :disabled="!$can('destroy', 'RegionMenu')"
                    :loading="deletingId === row.id"
                    icon-left="trash-alt"
                    @click="openRemoveRegionMenuModal(row)"
                  />
                </b-table-column>

                <template slot="empty">
                  <empty-table-loader
                    icon="globe-americas"
                    message="No Regional Menu Found..."
                    :loading="isFetchingRegionMenus"
                  />
                </template>
              </b-table>
            </searchable-table>
          </div>

          <empty-state-card
            v-else
            type="is-primary"
            title="Create a Regional Menu"
            message="Define Menus to apply to a selection of stores"
            icon="globe-americas"
          >
            <template #buttons>
              <b-button
                type="is-primary"
                rounded
                icon-left="plus"
                :disabled="!$can('create', 'RegionMenu')"
                @click="openRegionMenuModal()"
              >
                New Regional Menu
              </b-button>
            </template>
          </empty-state-card>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import RegionMenu from '@/store/classes/RegionMenu';
  import regionMenuModal from './regional-menu-modal.vue';

  export default {
    name: 'RegionalMenuList',

    data() {
      return {
        pageLimit: 20
      };
    },

    computed: {
      regionMenus() {
        return RegionMenu.all();
      },

      isFetchingRegionMenus() {
        return RegionMenu.$state().fetching;
      },

      deletingId() {
        return RegionMenu.$state().deletingId;
      }
    },

    created() {
      this.onCreated();
    },

    methods: {
      async onCreated() {
        await this.fetchRegionMenus();
      },

      openRegionMenuModal(regionMenuId) {
        this.$buefy.modal.open({
          parent: this,
          component: regionMenuModal,
          hasModalCard: true,
          trapFocus: true,
          canCancel: false,
          customClass: 'auto-width',
          props: { regionMenuId }
        });
      },

      openRemoveRegionMenuModal({ name, id }) {
        this.$buefy.dialog.confirm({
          title: 'Delete Regional Menu',
          message: `Deleting the <b>${name}</b> Regional Menu will remove it from any Locations it has been assigned to. Are you sure?`,
          hasIcon: true,
          icon: 'trash-alt',
          type: 'is-danger',
          confirmText: 'Delete',
          onConfirm: () => this.deleteRegionMenu({ name, id })
        });
      },

      async fetchRegionMenus() {
        try {
          await RegionMenu.fetchRegionMenus();
        }
        catch (error) {
          this.$_onRequestError({
            error,
            toastOptions: {
              message: 'There was an error fetching the regional menus'
            }
          });
        }
      },

      async deleteRegionMenu({ name, id }) {
        try {
          await RegionMenu.deleteRegionMenu(id);
          this.$_onRequestSuccess({
            toastOptions: {
              message: `The <b>${name}</b> regional menu has been successfully deleted!`
            }
          });
        }
        catch (error) {
          this.$_onRequestError({
            error,
            toastOptions: {
              message: `There was an error deleting the <b>${name}</b> regional menu`
            }
          });
        }
      }
    }
  };
</script>
