/* eslint-disable import/no-cycle */
import { Model } from '@vuex-orm/core';
import storage from '@/services/storage';
import filterObjectKeys from '@/helpers/filter-object-keys';
import { parseJson } from '@/helpers/misc';
import appSettingConstants from '@/constants/merchantAppSettingResources';
import { getTimezoneAbbreviation } from '@/helpers/timezoneUtils';

// classes
import MerchantOption from './MerchantOption';
import MerchantOptionsCheckout from './MerchantOptionsCheckout';
import MerchantOptionsEmv from './MerchantOptionsEmv';
import MerchantOptionsLocation from './MerchantOptionsLocation';
import MerchantOptionsLoyalty from './MerchantOptionsLoyalty';
import MerchantOptionsOpd from './MerchantOptionsOpd';
import MerchantPaymentGateway from './MerchantPaymentGateway';
import PosConfiguration from './PosConfiguration';
import http from '@/services/http';
import MerchantAppSettingResource from './MerchantAppSettingResource';
import MerchantDefaultNotificationPreference from './MerchantDefaultNotificationPreference';
import MerchantDeliveryService from './MerchantDeliveryService';
import MerchantMenuTypeConfiguration from './MerchantMenuTypeConfiguration';
import MerchantGiftCardConfiguration from './MerchantGiftCardConfiguration';
import MerchantLoyaltyProviderConfiguration from './MerchantLoyaltyProviderConfiguration';
import MerchantGiftCardDesign from './MerchantGiftCardDesign';
import MerchantType from './MerchantType';
import MerchantLocationService from './MerchantLocationService';
import Store from './Store';



export default class Merchant extends Model {
  static entity = 'merchants'

  // static primaryKey = 'merchantId'

  // FIELDS //////////////////////
  static fields() {
    return {
      id: this.attr(''),
      name: this.attr(''),
      displayName: this.attr(''),
      friendlyUriName: this.attr(''),
      baseUrl: this.attr(''),
      hostname: this.attr(''),
      locationManagementSettings: this.attr(''),
      features: this.attr('', val => ({
        ...val,
        quickstartChecklist: val.quickstartChecklist ? parseJson(val.quickstartChecklist) : {}
      })),
      allowDisabledItemOverride: this.attr(false),
      allowEmptyLineItems: this.attr(false),
      allowItemLevelRefunds: this.attr(false),
      allowLoginByPhoneNumber: this.attr(false),
      allowKioskLoginByPhoneNumber: this.attr(false),
      allowInternalFundingPayment: this.attr(''),
      defaultStoreId: this.attr(''),
      defaultOfferRedemptionTimezone: this.attr(''),
      defaultUserTimezone: this.attr(''),
      ignoreMenuDayPartSchedule: this.attr(false),
      isActive: this.attr(true),
      isTemplate: this.attr(false),
      merchantTypeId: this.attr(MerchantType.typeIds.STANDARD),
      merchantType: this.attr({}),
      guestUserAccountCreationEnabled: this.attr(false),
      payAtTableOrderCleanupEnabled: this.attr(false),
      splitDineInOrderItems: this.attr(false),
      signupType: this.attr({}),
      requireAuthenticationForNewDeviceLogin: this.attr(false),
      payAtTableTwoFaEnabled: this.attr(false),
      triggerPayAtTableTwoFaOnTicket: this.attr(false),
      payAtTableTwoFaDollarTriggerAmount: this.attr(false),
      primarySignupTypeId: this.number(null),
      allowQuickAddWithDefaultMods: this.attr(true),
      defaultUpsellDisplayModeId: this.attr(null),

      // External Auth
      supportsSquareAuth: this.attr(''),
      isAuthorizedWithSquare: this.attr(''),
      squareOauthUrl: this.attr(''),

      supportsUberEatsOauth: this.attr(''),
      supportsThirdPartyOrderAhead: this.attr(''),
      isAuthorizedWithUber: this.attr(''),
      uberEatsOauthUrl: this.attr(''),

      isAuthorizedWithToast: this.attr(''),

      // FE properties
      avatarImageFile: this.attr(null),
      paymentGatewayIds: this.attr([]),
      paymentMethodsByGateway: this.attr({}),
      posProviderIds: this.attr([]),

      // relationships
      merchantOption: this.hasOne(MerchantOption, 'merchantId'),
      merchantOptionsCheckout: this.hasOne(MerchantOptionsCheckout, 'merchantId'),
      merchantOptionsEmv: this.hasOne(MerchantOptionsEmv, 'merchantId'),
      merchantOptionsLocation: this.hasOne(MerchantOptionsLocation, 'merchantId'),
      merchantOptionsLoyalty: this.hasOne(MerchantOptionsLoyalty, 'merchantId'),
      merchantOptionsOpd: this.hasOne(MerchantOptionsOpd, 'merchantId'),
      merchantPaymentGateways: this.hasMany(MerchantPaymentGateway, 'merchantId'),
      merchantDefaultNotificationPreferences: this.hasMany(MerchantDefaultNotificationPreference, 'merchantId'),
      posConfigurations: this.hasMany(PosConfiguration, 'merchantId'),
      merchantAppSettingResources: this.hasMany(MerchantAppSettingResource, 'merchantId'),
      merchantDeliveryServices: this.hasMany(MerchantDeliveryService, 'merchantId'),
      menuTypes: this.hasMany(MerchantMenuTypeConfiguration, 'merchantId'),
      merchantGiftCardConfigurations: this.hasMany(MerchantGiftCardConfiguration, 'merchantId'),
      merchantGiftCardDesigns: this.hasMany(MerchantGiftCardDesign, 'merchantId'),
      merchantLocationService: this.hasOne(MerchantLocationService, 'merchantId')
    };
  }

  get paymentMethodsByPaymentGateway() {
    return this.merchantPaymentGateways.reduce((acc, mpg) => {
      // NOTE: payment methods are ones actually set for payment gateway,
      // while availabePaymentMethods are all available
      acc[mpg.id] = mpg.paymentMethods;
      return acc;
    }, {});
  }

  get allPaymentMethodsForMerchantGatewaysByName() {
    const nonUniquePaymentMethods = Object.values(this.paymentMethodsByPaymentGateway)
      .flatMap(methods => methods.map(m => m.name));
    return [...new Set(nonUniquePaymentMethods)];
  }

  get hasAutoApplicableOffers() {
    return this.features?.autoApplicableOffers;
  }

  get orderDashboardColumns() {
    return this.features.orderDashboardColumns;
  }

  get hasToastMerchantLocationService() {
    return !!this.merchantLocationService?.toast;
  }

  get hasSquareMerchantLocationService() {
    return !!this.merchantLocationService?.square;
  }

  get supportedMenuTypes() {
    return MerchantMenuTypeConfiguration.getMenuTypes(this.id);
  }

  get isSundry() {
    return this.merchantTypeId === MerchantType.typeIds.SUNDRY;
  }

  get hasNoStores() {
    return !Store.query().where('merchantId', this.id).exists();
  }

  get defaultStore() {
    if (!this.defaultStoreId) return null;
    return Store.find(this.defaultStoreId);
  }

  get campaignsIanaTimezoneString() {
    const tzId = this.features.campaignsIanaTimezoneId;
    return getTimezoneAbbreviation(tzId);
  }

  get defaultUserTimezoneString() {
    const tzId = this.defaultUserTimezone;
    return getTimezoneAbbreviation(tzId);
  }

  get defaultOfferRedemptionTimezoneString() {
    const tzId = this.defaultOfferRedemptionTimezone;
    return getTimezoneAbbreviation(tzId);
  }

  get defaultOfferRedemptionOrDefaultUserTimezone() {
    return this.defaultOfferRedemptionTimezone || this.defaultUserTimezone || 'UTC';
  }

  get defaultOfferRedemptionOrDefaultUserTimezoneString() {
    const tzId = this.defaultOfferRedemptionTimezone || this.defaultUserTimezone;
    return getTimezoneAbbreviation(tzId);
  }

  /* POS Config Getters */
  /* ////////////////// */
  get hasUnstructuredMenu() {
    return this.posConfigurations.some(({ posType }) => posType?.metaData?.unstructuredMenu);
  }

  get supportsMerchantFees() {
    return this.posConfigurations.some(({ posType }) => posType?.metaData?.supportsMerchantFees);
  }

  get numUniquePosTypes() {
    const posTypeIds = this.posConfigurations.map(posConfig => posConfig.posType.id);
    return new Set(posTypeIds).size;
  }

  get allowModifierGroupAssociation() {
    return this.posConfigurations.some(({ posType }) => posType?.metaData?.allowModifierGroupAssociation);
  }

  /* App Setting Resource Getters */
  /* //////////////////////////// */
  get patEnabled() {
    return this.merchantAppSettingResources.find(r => r.key === appSettingConstants.PAY_AT_TABLE)?.value;
  }

  get orderAheadEnabled() {
    return this.merchantAppSettingResources.find(r => r.key === appSettingConstants.ORDER_AHEAD)?.value;
  }

  get kioskEnabled() {
    return this.merchantAppSettingResources.find(r => r.key === appSettingConstants.KIOSK)?.value;
  }

  get oatEnabled() {
    return this.merchantAppSettingResources.find(r => r.key === appSettingConstants.ORDER_AT_TABLE)?.value;
  }

  get deliveryEnabled() {
    return this.merchantAppSettingResources.find(r => r.key === appSettingConstants.DELIVERY)?.value;
  }

  get asapPickupOnlyEnabled() {
    return this.merchantAppSettingResources.find(r => r.key === appSettingConstants.ASAP_PICKUP_ONLY)?.value;
  }

  get readOnlyMenuEnabled() {
    return this.merchantAppSettingResources.find(r => r.key === appSettingConstants.READ_ONLY_MENU)?.value;
  }

  get textToPayEnabled() {
    return this.merchantAppSettingResources.find(r => r.key === appSettingConstants.TEXT_TO_PAY)?.value;
  }

  get ageRestrictedEnabled() {
    return this.merchantAppSettingResources.find(r => r.key === appSettingConstants.AGE_RESTRICTED)?.value;
  }

  get cateringEnabled() {
    return this.merchantAppSettingResources.find(r => r.key === appSettingConstants.CATERING)?.value;
  }

  get surveysEnabled() {
    return this.merchantAppSettingResources.find(r => r.key === appSettingConstants.ENABLE_SURVEYS)?.value;
  }

  get grouporderEnabled() {
    return this.merchantAppSettingResources.find(r => r.key === appSettingConstants.GROUP_ORDER)?.value;
  }

  get hasDisableSavedPaymentManagement() {
    return this.merchantAppSettingResources.find(r => r.key === appSettingConstants.DISABLE_SAVED_PAYMENT_MANAGEMENT)?.value;
  }

  get simpleOrderAheadEnabled() {
    return this.merchantAppSettingResources.find(r => r.key === appSettingConstants.SIMPLE_ORDER_AHEAD)?.value;
  }

  get roomServiceEnabled() {
    return this.merchantAppSettingResources.find(r => r.key === appSettingConstants.ROOM_SERVICE)?.value;
  }

  get shippingEnabled() {
    return this.merchantAppSettingResources.find(r => r.key === appSettingConstants.SHIPPING)?.value;
  }

  get externalDeviceEnabled() {
    return this.merchantAppSettingResources.find(r => r.key === appSettingConstants.EXTERNAL_DEVICE)?.value;
  }

  get externalOrderEnabled() {
    return MerchantLoyaltyProviderConfiguration.query()
      .where('merchantId', this.id)
      .where('isActive', true)
      .first()?.externalOrderEnabled;
  }

  get loyaltyEnabled() {
    return this.merchantOptionsLoyalty?.loyaltyEnabled;
  }

  get payAtTableEnabled() {
    return this.merchantAppSettingResources.find(r => r.key === appSettingConstants.PAY_AT_TABLE)?.value;
  }

  // STATE //////////////////////
  static state() {
    return {
      fetchingAll: false,
      fetchingMerchant: false,
      fetchingSelectedMerchant: false,
      submitting: false,
      submittingFeatures: false,
      updatingQuickstartChecklistStepId: null,
      selectedMerchantId: null,
      editableMerchant: {},
      submittingActivationChangeId: null,
      fullyFetchedMerchantIds: [],
      recentMerchantIds: storage.local.get('recentMerchantIds') || [],
      merchantDeepLinks: []
    };
  }

  static $state() { // TEST ?
    return this.store().state.entities.merchants;
  }

  static recentMerchants() {
    return this.query().findIn(this.$state().recentMerchantIds);
  }

  // ACTIONS //////////////////////
  static setSelectedMerchantId(_merchantId) {
    // clear all merchant specific data from VuexORM when switching merchant contexts
    const currentSelectedMerchantId = this.$state().selectedMerchantId;

    if (!_merchantId || (currentSelectedMerchantId && currentSelectedMerchantId !== _merchantId)) {
      Object.keys(this.store().state.entities)
        .filter((entity) => {
          const entitiesToRetain = [
            'deliveryServices',
            'fulfillmentTypes',
            'merchants',
            'paymentGateways',
            'roles',
            'storeAttributes',
            'users',
            'userRoles'
          ];
          return !entity.includes('$') && !entitiesToRetain.includes(entity);
        })
        .forEach(entity => this.store().dispatch(`entities/${entity}/deleteAll`, null, { root: true }));
    }

    const merchantId = _merchantId ? Number(_merchantId) : null;

    this.commit((state) => {
      state.selectedMerchantId = merchantId;
      if (merchantId) {
        const maxAmountOfRecents = 6;
        const newRecentMerchantIds = [...new Set([merchantId, ...state.recentMerchantIds])].slice(0, maxAmountOfRecents);

        state.recentMerchantIds = newRecentMerchantIds;
        storage.local.set('recentMerchantIds', newRecentMerchantIds);
      }
    });

    storage.local.set('merchantId', merchantId);
  }

  static clearFullyFetchedMerchantIds() {
    this.commit((state) => {
      state.fullyFetchedMerchantIds = [];
    });
  }

  static clearRecentMerchantIds() {
    storage.local.set('recentMerchantIds', [this.$state().selectedMerchantId]);
    this.commit((state) => {
      state.recentMerchantIds = [this.$state().selectedMerchantId];
    });
  }

  static setEditableMerchant(editedMerchantProperties) {
    this.commit((state) => {
      state.editableMerchant = editedMerchantProperties ? {
        ...state.editableMerchant,
        ...editedMerchantProperties
      } : {};
    });
  }

  static async fetchMerchants() {
    try {
      this.commit((state) => {
        state.fetchingAll = true;
      });
      const { data } = await http.get('merchants');

      const filteredMerchants = data.merchants.filter(merchant => !this.$state().fullyFetchedMerchantIds.includes(merchant.id));

      this.insert({ data: filteredMerchants });
    }
    catch (error) {
      throw error;
    }
    finally {
      this.commit((state) => {
        state.fetchingAll = false;
      });
    }
  }

  static async fetchMerchant(merchantId, options = { isFetchingSelectedMerchant: false }) {
    try {
      this.commit((state) => {
        if (options.isFetchingSelectedMerchant) {
          state.fetchingSelectedMerchant = true;
        }
        else {
          state.fetchingMerchant = true;
        }
      });
      const { data } = await http.get(`merchants/${merchantId}`);

      this.insert({ data: data.merchant });

      this.commit((state) => {
        state.fullyFetchedMerchantIds = [...new Set([...state.fullyFetchedMerchantIds, merchantId])];
      });
    }
    catch (error) {
      throw error;
    }
    finally {
      this.commit((state) => {
        state.fetchingSelectedMerchant = false;
        state.fetchingMerchant = false;
      });
    }
  }

  static async addMerchant(merchant) {
    try {
      this.commit((state) => {
        state.submitting = true;
      });
      const response = await http.post('merchants', { merchant });
      this.insert({
        data: response.data.merchant
      });
      return response.data.merchant;
    }
    catch (error) {
      throw error;
    }
    finally {
      this.commit((state) => {
        state.submitting = false;
      });
    }
  }

  static async updateMerchant(merchant) {
    try {
      this.commit((state) => {
        state.submitting = true;
      });
      const acceptedKeys = [
        'name',
        'displayName',
        'baseUrl',
        'friendlyUriName',
        'emailOrderReceiptEnabled',
        'smsOrderReceiptEnabled',
        'allowDisabledItemOverride',
        'allowEmptyLineItems',
        'allowItemLevelRefunds',
        'allowLoginByPhoneNumber',
        'allowKioskLoginByPhoneNumber',
        'guestUserAccountCreationEnabled',
        'payAtTableOrderCleanupEnabled',
        'splitDineInOrderItems',
        'ignoreMenuDayPartSchedule',
        'posTypeId',
        'requireAuthenticationForNewDeviceLogin',
        'payAtTableTwoFaEnabled',
        'triggerPayAtTableTwoFaOnTicket',
        'payAtTableTwoFaDollarTriggerAmount',
        'defaultStoreId',
        'allowQuickAddWithDefaultMods',
        'primarySignupTypeId',
        'defaultOfferRedemptionTimezone',
        'defaultUserTimezone',
        'orderDashboardColumns',
        'defaultUpsellDisplayModeId'
      ];
      const response = await http.put(`merchants/${merchant.id}`, {
        merchant: filterObjectKeys(merchant, acceptedKeys)
      });
      this.update({
        data: response.data.merchant
      });
    }
    catch (error) {
      throw error;
    }
    finally {
      this.commit((state) => {
        state.submitting = false;
      });
    }
  }

  static async revokeSquareAuthorization(merchantId) {
    try {
      const response = await http.delete(`merchants/${merchantId}/square_oauth`);
      this.update({
        data: response.data.merchant
      });
    }
    catch (error) {
      throw error;
    }
  }

  static async refreshSquareAuthorization(merchantId) {
    try {
      const response = await http.get(`merchants/${merchantId}/refresh_square`);
      this.update({
        data: response.data.merchant
      });
    }
    catch (error) {
      throw error;
    }
  }

  static async refreshStores(merchantId) {
    try {
      await http.put(`merchants/${merchantId}/refresh_stores`);
    }
    catch (error) {
      throw error;
    }
  }

  static async refreshMenu(merchantId) {
    try {
      await http.put(`merchants/${merchantId}/refresh_menu`);
    }
    catch (error) {
      throw error;
    }
  }

  static async updateFeatures(features) {
    try {
      this.commit((state) => {
        state.submittingFeatures = true;
      });

      const blackListedKeys = [
        'id',
        'createdAt',
        'updatedAt'
      ];

      const response = await http.put(
        `features/${features.id}`,
        { feature: filterObjectKeys(features, blackListedKeys, true) }
      );

      this.update({
        data: {
          id: this.$state().selectedMerchantId,
          features: response.data.feature
        }
      });
    }
    catch (error) {
      throw error;
    }
    finally {
      this.commit((state) => {
        state.submittingFeatures = false;
      });
    }
  }

  static async updateQuickstartChecklist({ stepId, featureId }) {
    try {
      this.commit((state) => {
        state.updatingQuickstartChecklistStepId = stepId;
      });

      const response = await http.put(`features/${featureId}/quickstart_checklists/${stepId}/toggle`);

      this.update({
        data: {
          id: this.$state().selectedMerchantId,
          features: response.data.feature
        }
      });
    }
    catch (error) {
      throw error;
    }
    finally {
      this.commit((state) => {
        state.updatingQuickstartChecklistStepId = null;
      });
    }
  }

  static async deactivateMerchant(id) {
    try {
      this.commit((state) => {
        state.submittingActivationChangeId = id;
      });

      const response = await http.put(`merchants/${id}/deactivate`);

      this.update({
        data: response.data.merchant
      });
    }
    catch (error) {
      throw error;
    }
    finally {
      this.commit((state) => {
        state.submittingActivationChangeId = null;
      });
    }
  }

  static async activateMerchant(id) {
    try {
      this.commit((state) => {
        state.submittingActivationChangeId = id;
      });

      const response = await http.put(`merchants/${id}/activate`);

      this.update({
        data: response.data.merchant
      });
    }
    catch (error) {
      throw error;
    }
    finally {
      this.commit((state) => {
        state.submittingActivationChangeId = null;
      });
    }
  }

  static async fetchDeepLinks(merchantId, context) {
    try {
      this.commit((state) => {
        state.fetchingLinks = true;
      });

      const basePath = `merchants/${merchantId}/merchant_deep_links`;
      const path = context ? `${basePath}?context=${context}` : basePath;

      const { data } = await http.get(path);

      this.commit((state) => {
        state.merchantDeepLinks = data.merchantDeepLinks;
      });
      return data.merchantDeepLinks;
    }

    catch (error) {
      throw error;
    }

    finally {
      this.commit((state) => {
        state.fetchingLinks = false;
      });
    }
  }
}
