const logger = {
  error(error) {
    const isServerError = !!error.status;

    if (!isServerError) {
      window.console.error(error);
    }
  },

  warn(description, extraDetails) {
    window.console.warn(description);

    if (extraDetails) {
      console.log(extraDetails);
    }
  },

  log(...args) {
    console.log(...args);
  }
};



export default logger;
