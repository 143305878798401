<template>
  <takeover>
    <validated-form v-entrap-focus form-id="requestPasswordReset" :track-changes="false" @valid-submit="requestPasswordReset">
      <header class="has-text-centered mar-b-md">
        <img class="image is-inline is-64x64" src="/images/cardfree-icon-logo.svg" alt="Admin Portal - Cardfree">
        <h1 class="title">Forgot Password</h1>
      </header>
      <div v-if="!passwordResetRequested">
        <b-message type="is-primary">
          Please enter your email address and we'll send you a link to reset your password.
        </b-message>
        <validated-text-input
          v-model="email"
          type="email"
          name="userEmail"
          label="Email"
          rules="required"
        />

        <b-button
          rounded
          native-type="submit"
          type="is-primary"
          size="is-medium"
          class="is-fullwidth mar-t-lg"
          :loading="isSubmitting"
        >
          Submit
        </b-button>
      </div>

      <b-message v-else type="is-primary">
        <div class="request-sent-message">
          <b-icon size="is-medium" icon="envelope" class="mar-r-lg" />
          <span>
            Please check your email for a<br>
            link to reset your password.
          </span>
        </div>
      </b-message>
    </validated-form>
  </takeover>
</template>



<script>
  import User from '@/store/classes/User';


  export default {
    name: 'ForgotPassword',

    metaInfo() {
      return {
        title: 'Forgot Password'
      };
    },

    data() {
      return {
        email: null,
        passwordResetRequested: false,
        isLoading: false
      };
    },

    computed: {
      isSubmitting() {
        return User.$state().submitting;
      }
    },

    methods: {
      async requestPasswordReset() {
        try {
          await User.requestPasswordReset(this.email);

          this.passwordResetRequested = true;
        }
        catch (error) {
          this.$_onRequestError({
            toastOptions: {
              message: 'Unable to request password reset'
            },
            error
          });
        }
      }
    }
  };
</script>

<style lang="sass" scoped>
  .request-sent-message
    display: flex
    align-items: center
</style>
