import { Model } from '@vuex-orm/core';
import http from '@/services/http';
import moment from 'moment-timezone';



// FIELDS //////////////////////
export default class OfferNotification extends Model {
  static entity = 'offerNotifications'

  static primaryKey = 'guid'

  static fields() {
    return {
      guid: this.uid(),
      expirationLeadPeriod: this.attr(''),
      merchantId: this.attr('')
    };
  }



  // STATE //////////////////////
  static state() {
    return {
      fetching: false,
      submitting: false,
      deleting: false
    };
  }

  static $state() {
    return this.store().state.entities.offerNotifications;
  }



  // GETTERS //////////////////////
  get parsedExpirationLeadPeriod() {
    return moment.duration(this.expirationLeadPeriod).asDays();
  }



  // ACTIONS //////////////////////
  static async getOfferNotifications() {
    try {
      this.commit((state) => {
        state.fetching = true;
      });

      const merchantId = this.store().state.entities.merchants.selectedMerchantId;
      const { data } = await http.get(`merchants/${merchantId}/offer_expiration_notification_configurations`);

      this.create({ data: data.offerExpirationNotificationConfigurations });
    }
    catch (error) {
      throw error;
    }
    finally {
      this.commit((state) => {
        state.fetching = false;
      });
    }
  }

  static async createOfferNotification({ expirationLeadPeriod }) {
    try {
      this.commit((state) => {
        state.submitting = true;
      });

      const merchantId = this.store().state.entities.merchants.selectedMerchantId;
      const { data } = await http.post(`merchants/${merchantId}/offer_expiration_notification_configurations`, {
        offerExpirationNotificationConfiguration: { expirationLeadPeriod }
      });

      this.insert({ data: data.offerExpirationNotificationConfiguration });
    }
    catch (error) {
      throw error;
    }
    finally {
      this.commit((state) => {
        state.submitting = false;
      });
    }
  }

  static async updateOfferNotification({ expirationLeadPeriod, guid }) {
    try {
      this.commit((state) => {
        state.submitting = true;
      });

      const merchantId = this.store().state.entities.merchants.selectedMerchantId;
      const { data } = await http.put(`merchants/${merchantId}/offer_expiration_notification_configurations/${guid}`, {
        offerExpirationNotificationConfiguration: { expirationLeadPeriod }
      });

      this.update({ data: data.offerExpirationNotificationConfiguration });
    }
    catch (error) {
      throw error;
    }
    finally {
      this.commit((state) => {
        state.submitting = false;
      });
    }
  }

  static async deleteOfferNotification({ guid }) {
    try {
      this.commit((state) => {
        state.deleting = true;
      });

      const merchantId = this.store().state.entities.merchants.selectedMerchantId;
      await http.delete(`merchants/${merchantId}/offer_expiration_notification_configurations/${guid}`);

      this.delete(guid);
    }
    catch (error) {
      throw error;
    }
    finally {
      this.commit((state) => {
        state.deleting = false;
      });
    }
  }
}
