export default {
  DELIVERY: 'Delivery',
  DINE_IN: 'DineIn',
  PICKUP: 'Pickup',
  CURBSIDE: 'Curbside',
  DRIVE_THRU: 'DriveThru',
  TAKEOUT: 'Takeout',
  SHIPPING: 'Shipping',
  OTHER: 'Other'
};
