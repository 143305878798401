<template>
  <validated-form
    ref="form"
    v-slot="{}"
    form-id="merchantOrderModesForm"
    @valid-submit="updateMerchantOrderModes"
  >
    <sticky-save-container
      :loading="isFetching"
      :saving="isSubmitting"
      title="Order Modes"
    >
      <template #default>
        <div class="pad-r-xl mar-b-lg">
          <p class="subtitle is-6 has-text-grey">Select an order mode to make it available in the mobile apps. Each selected order mode must have a configured menu.</p>
        </div>


        <div class="is-grid col-min-200 gap-md overflow-y-auto pad-b-sm mar-r-sm">
          <check-button
            v-for="orderMode in orderModes"
            :key="orderMode.id"
            v-model="selectedOrderModeIds"
            :native-value="orderMode.id"
            :label="$changeCase.capitalCase(orderMode.type)"
          />
        </div>
      </template>
    </sticky-save-container>
  </validated-form>
</template>

<script>
  import MerchantOrderMode from '@/store/classes/MerchantOrderMode';
  import OrderMode from '@/store/classes/OrderMode';
  import getChangedResources from '@/helpers/get-changed-resources';

  export default {
    name: 'MerchantOrderModes',

    props: {
      merchantId: {
        type: Number,
        required: true
      }
    },

    data() {
      return {
        selectedOrderModeIds: []
      };
    },

    computed: {
      isSubmitting() {
        return MerchantOrderMode.$state().submitting;
      },

      isFetching() {
        return MerchantOrderMode.$state().fetching || OrderMode.$state().fetching;
      },

      orderModes() {
        return OrderMode.query().orderBy('type').get();
      },

      merchantOrderModes() {
        return MerchantOrderMode.query().where('merchantId', this.merchantId).get();
      }
    },

    async created() {
      await this.onCreated();
    },

    methods: {
      async onCreated() {
        await Promise.all([
          this.fetchOrderModes(),
          this.fetchMerchantOrderModes()
        ]);

        this.selectedOrderModeIds = this.merchantOrderModes.map(merchantOrderMode => merchantOrderMode.orderModeId);
      },

      async fetchMerchantOrderModes() {
        try {
          await MerchantOrderMode.fetchMerchantOrderModes(this.merchantId);
        }

        catch (error) {
          this.$_onRequestError({
            error,
            toastOptions: {
              message: 'There was an issue fetching the merchant order modes'
            }
          });
        }
      },

      async fetchOrderModes() {
        try {
          await OrderMode.fetchOrderModes();
        }

        catch (error) {
          this.$_onRequestError({
            error,
            toastOptions: {
              message: 'There was an issue fetching the order modes'
            }
          });
        }
      },

      async updateMerchantOrderModes() {
        try {
          const { added, removed } = getChangedResources({
            newArray: this.selectedOrderModeIds,
            oldArray: this.merchantOrderModes.map(config => config.orderModeId)
          });

          const promises = [];

          if (added.length) {
            added.forEach((orderModeId) => {
              promises.push(MerchantOrderMode.createMerchantOrderMode({
                merchantId: this.merchantId,
                orderModeId,
                isMobileActive: true,
                isWebActive: true
              }));
            });
          }
          if (removed.length) {
            removed.forEach((orderModeId) => {
              const id = this.merchantOrderModes.find(config => config.orderModeId === orderModeId).id;
              promises.push(MerchantOrderMode.deleteMerchantOrderModes(id));
            });
          }

          await Promise.all(promises);

          this.$_onRequestSuccess({
            toastOptions: { message: 'Successfully updated order modes' }
          });
        }

        catch (error) {
          this.$_onRequestError({
            error,
            toastOptions: {
              message: 'There was an issue updating your order modes'
            }
          });
        }
      }
    }
  };
</script>
