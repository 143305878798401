import http from '@/services/http';

export default async function getCapiShortUrl({ merchantId, url, type }) {
  try {
    const response = await http.post(
      `merchants/${merchantId}/short_url`,
      { shortUrl: { url, type } }
    );
    return response.data;
  }
  catch (error) {
    throw error;
  }
}
