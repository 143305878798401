var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"store-list-page"},[_c('hero-banner',{scopedSlots:_vm._u([{key:"right",fn:function(){return [_c('div',{staticClass:"buttons is-centered"},[(_vm.$can('create', 'StoreMapping') && _vm.$_selectedMerchant && _vm.$_selectedMerchant.locationManagementSettings.createsEnabled)?_c('b-button',{attrs:{"rounded":"","inverted":"","size":"is-medium","type":"is-primary","icon-left":"plus"},on:{"click":_vm.$_toggleModal}},[_vm._v(" New Location ")]):_vm._e(),(_vm.showRefreshLocationsButton)?_c('b-button',{attrs:{"rounded":"","inverted":"","size":"is-medium","type":"is-primary","icon-left":"sync","loading":_vm.isRefreshingStores},on:{"click":_vm.refreshStores}},[_vm._v(" Refresh Locations ")]):_vm._e()],1)]},proxy:true}])},[_c('h1',{staticClass:"title is-2"},[_vm._v("Locations")])]),_c('div',{staticClass:"section"},[_c('div',{staticClass:"container"},[(_vm.invalidStoreIds.length)?_c('b-message',{staticClass:"box is-paddingless",attrs:{"type":"is-danger"}},[_vm._v(" Locations marked "),_c('b-icon',{staticClass:"mar-x-xs",attrs:{"pack":"far","size":"is-small","icon":"exclamation-triangle"}}),_vm._v(" are missing values for required fields. Please update the location details and enable online ordering. ")],1):_vm._e(),_c('div',{staticClass:"card"},[_c('searchable-table',{attrs:{"table-data":_vm.stores,"search":{
            placeholder: 'Search by Location Name or Address',
            keys: ['description', 'addressLine1', 'addressLine2', 'city', 'region', 'postalCode']
          }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var filteredData = ref.filteredData;
return [_c('b-table',{staticClass:"is-middle-aligned",attrs:{"data":_vm.fetchingStores ? [] : filteredData,"paginated":_vm.stores.length > _vm.pageLimit,"pagination-simple":"","per-page":_vm.pageLimit,"mobile-cards":false,"loading":_vm.fetchingStoreItems || _vm.togglingTestMode,"default-sort":"description"}},[_c('b-table-column',{attrs:{"sortable":"","field":"description","label":"Name","cell-class":"no-wrap-text"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var row = ref.row;
return [_c('div',{class:{ 'name-with-errors': _vm.storeHasErrors(row.storeId) }},[_c('a',{staticClass:"link",on:{"click":function($event){return _vm.handleStoreSelection(row)}}},[_vm._v(" "+_vm._s(row.description)+" ")]),(_vm.storeHasErrors(row.storeId))?_c('b-icon',{staticClass:"mar-l-sm has-text-danger",attrs:{"pack":"far","size":"is-small","icon":"exclamation-triangle"}}):_vm._e(),(row.storeId === _vm.$_selectedMerchant.defaultStoreId)?_c('b-tag',{directives:[{name:"tippy",rawName:"v-tippy",value:({ content: 'Merchant Default Store' }),expression:"{ content: 'Merchant Default Store' }"}],staticClass:"mar-l-xs",attrs:{"size":"is-small","type":"is-success is-light is-outlined"}},[_vm._v(" Default ")]):_vm._e(),(row.isSystem)?_c('b-tag',{directives:[{name:"tippy",rawName:"v-tippy",value:({ content: 'Internal System Store' }),expression:"{ content: 'Internal System Store' }"}],staticClass:"mar-l-xs",attrs:{"size":"is-small","type":"is-warning is-light is-outlined"}},[_vm._v(" System ")]):_vm._e()],1)]}}],null,true)}),_c('b-table-column',{attrs:{"field":"address","label":"Address","cell-class":"no-wrap-text"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var row = ref.row;
return [(row.storeId !== _vm.$_selectedMerchant.defaultStoreId)?[_c('p',[_vm._v(_vm._s(row.addressLine1)+_vm._s(row.addressLine2 ? (", " + (row.addressLine2)) : ''))]),_c('p',[_vm._v(_vm._s(row.city)+", "+_vm._s(row.region)+" "+_vm._s(row.postalCode))])]:_c('p',[_vm._v("N/A")])]}}],null,true)}),_c('b-table-column',{attrs:{"field":"contact","label":"Contact","cell-class":"no-wrap-text"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var row = ref.row;
return [_c('div',[_c('div',[_c('b-icon',{staticClass:"has-text-grey mar-r-sm",attrs:{"size":"is-small","pack":"far","icon":"envelope","cell-class":"no-wrap-text"}}),(row.emailAddress)?[_c('a',{attrs:{"href":("mailto:" + (row.emailAddress))}},[_vm._v(_vm._s(row.emailAddress))])]:[_c('span',{staticClass:"has-text-grey"},[_vm._v("N/A")])]],2),_c('div',[_c('b-icon',{staticClass:"has-text-grey mar-r-sm",attrs:{"size":"is-small","pack":"far","icon":"phone"}}),(row.phoneNumber)?[_c('a',{attrs:{"href":("mailto:" + (row.phoneNumber))}},[_vm._v(_vm._s(_vm._f("phoneNumber")(row.phoneNumber)))])]:[_c('span',{staticClass:"has-text-grey"},[_vm._v("N/A")])]],2)])]}}],null,true)}),(!_vm.hideTaxColumn)?_c('b-table-column',{attrs:{"field":"tax","label":"Tax","cell-class":"no-wrap-text"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var row = ref.row;
return [(!_vm.supportsTax(row) && row.totalSalesTaxRate)?[_vm._v(" "+_vm._s(row.totalSalesTaxRate)+"% ")]:[_c('span',{staticClass:"has-text-grey"},[_vm._v("N/A")])]]}}],null,true)}):_vm._e(),(_vm.canToggleTestMode)?_c('b-table-column',{attrs:{"centered":"","sortable":"","field":"isActive","label":"Status"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var row = ref.row;
return [_c('b-tag',{staticClass:"has-text-weight-bold is-outlined",attrs:{"type":(row.isActive && !row.isVirtual) ? 'is-success is-light' : 'is-warning is-light'}},[_vm._v(" "+_vm._s(row.isActive && !row.isVirtual ? 'Active': 'Test')+" ")])]}}],null,true)}):_vm._e(),_c('b-table-column',{attrs:{"centered":"","label":"Online Ordering","sortable":"","custom-sort":function (a, b, isAsc) {
                var sort = _vm.getOnlineOrderingStatus(a) > _vm.getOnlineOrderingStatus(b) ? -1 : 1;
                return isAsc ? sort : (sort * -1);
              }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var row = ref.row;
return [_c('b-tag',{staticClass:"has-text-weight-bold is-outlined",attrs:{"type":_vm.getOnlineOrderingStatus(row) ? 'is-success is-light' : 'is-danger is-light'}},[_vm._v(" "+_vm._s(_vm.getOnlineOrderingStatus(row) ? 'Enabled' : 'Disabled')+" ")])]}}],null,true)}),(_vm.$_selectedMerchant.kioskEnabled)?_c('b-table-column',{attrs:{"centered":"","label":"Kiosk Ordering","sortable":"","custom-sort":function (a, b, isAsc) {
                var sort = _vm.getKioskStatus(a) > _vm.getKioskStatus(b) ? -1 : 1;
                return isAsc ? sort : (sort * -1);
              }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var row = ref.row;
return [_c('b-tag',{staticClass:"has-text-weight-bold is-outlined",attrs:{"type":_vm.getKioskStatus(row) ? 'is-success is-light' : 'is-danger is-light'}},[_vm._v(" "+_vm._s(_vm.getKioskStatus(row) ? 'Enabled' : 'Disabled')+" ")])]}}],null,true)}):_vm._e(),_c('b-table-column',{attrs:{"numeric":"","cell-class":"actions","label":"Actions","width":"1"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var row = ref.row;
return [_c('dropdown-menu',{attrs:{"position":"bottom-end"}},[_c('b-button',{staticClass:"is-transparent",attrs:{"slot":"trigger","type":"is-white"},slot:"trigger"},[_c('b-icon',{attrs:{"icon":"ellipsis-v","pack":"far","size":"is-small"}})],1),(_vm.$can('read', 'StoreMenuItem'))?_c('b-dropdown-item',{attrs:{"has-link":""}},[_c('router-link',{attrs:{"to":{ name: 'locationMenu', params: { storeId: row.storeId } }}},[_c('b-icon',{staticClass:"mar-r-sm",attrs:{"icon":"store-alt","size":"is-small"}}),_vm._v(" View Location Menu ")],1)],1):_vm._e(),(_vm.canToggleTestMode)?_c('b-dropdown-item',{attrs:{"disabled":_vm.storeHasErrors(row.storeId)},on:{"click":function($event){return _vm.handleToggleTestMode(row)}}},[_c('b-icon',{staticClass:"mar-r-sm",attrs:{"icon":"flask","size":"is-small"}}),_vm._v(" "+_vm._s(row.isActive && !row.isVirtual ? 'Enable' : 'Disable')+" Test Mode ")],1):_vm._e(),(_vm.canToggleOnlineOrdering(row.storeId) || _vm.canToggleKioskOrdering(row.storeId))?_c('hr',{staticClass:"dropdown-divider"}):_vm._e(),(_vm.canToggleOnlineOrdering(row.storeId))?_c('b-dropdown-item',{class:{'is-danger': _vm.getOnlineOrderingStatus(row)},attrs:{"disabled":_vm.storeHasErrors(row.storeId)},on:{"click":function($event){return _vm.handleUpdateOnlineOrderingStatus(row)}}},[_c('b-icon',{staticClass:"mar-r-sm",attrs:{"icon":_vm.getOnlineOrderingStatus(row)
                      ? 'lightbulb-slash'
                      : 'lightbulb-on',"size":"is-small"}}),_vm._v(" "+_vm._s(_vm.getOnlineOrderingStatus(row) ? 'Disable' : 'Enable')+" Online Ordering ")],1):_vm._e(),(_vm.$_selectedMerchant.kioskEnabled && _vm.canToggleKioskOrdering(row.storeId))?_c('b-dropdown-item',{class:{'is-danger': _vm.getKioskStatus(row)},attrs:{"disabled":_vm.storeHasErrors(row.storeId)},on:{"click":function($event){return _vm.handleUpdateKioskOrdering(row)}}},[_c('b-icon',{staticClass:"mar-r-sm",attrs:{"icon":_vm.getKioskStatus(row)
                      ? 'lightbulb-slash'
                      : 'lightbulb-on',"size":"is-small"}}),_vm._v(" "+_vm._s(_vm.getKioskStatus(row) ? 'Disable' : 'Enable')+" Kiosk Ordering ")],1):_vm._e()],1)]}}],null,true)}),_c('template',{slot:"empty"},[_c('empty-table-loader',{attrs:{"loading":_vm.fetchingStores}})],1)],2)]}}])})],1)],1)]),_c('b-modal',{attrs:{"has-modal-card":"","can-cancel":false,"trap-focus":"","active":_vm.cmc_isModalOpen},on:{"update:active":function($event){_vm.cmc_isModalOpen=$event}},nativeOn:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"esc",27,$event.key,["Esc","Escape"])){ return null; }return _vm.$_confirmCloseModal($event)}}},[_c('modal-card',{attrs:{"title":"Add New Location","modal-card-body-class":"pad-b-none"}},[_c('store-form',{attrs:{"mode":"create"}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }