import { Model } from '@vuex-orm/core';
import http from '@/services/http';

export default class MerchantOrderMode extends Model {
  static entity = 'merchantOrderModes'

  static fields() {
    return {
      id: this.attr(''),
      merchantId: this.attr(''),
      orderModeId: this.attr(''),
      isWeb: this.attr(false),
      isMobile: this.attr(false)
    };
  }

  // STATE //////////////////////
  static state() {
    return {
      fetching: false,
      submitting: false
    };
  }

  static $state() {
    return this.store().state.entities.merchantOrderModes;
  }

  static async fetchMerchantOrderModes(merchantId) {
    if (this.exists()) return;

    try {
      this.commit((state) => {
        state.fetching = true;
      });

      const { data } = await http.get(`merchants/${merchantId}/merchant_order_modes`);

      this.create({
        data: data.merchantOrderModes
      });
    }
    catch (error) {
      throw error;
    }
    finally {
      this.commit((state) => {
        state.fetching = false;
      });
    }
  }

  static async createMerchantOrderMode({ merchantId, orderModeId, isWebActive, isMobileActive }) {
    try {
      this.commit((state) => {
        state.submitting = true;
      });

      const { data } = await http.post(`merchants/${merchantId}/merchant_order_modes`, {
        merchantOrderMode: {
          orderModeId,
          isWeb: isWebActive,
          isMobile: isMobileActive
        }
      });

      this.insert({
        data: data.merchantOrderMode
      });
    }
    catch (error) {
      throw error;
    }
    finally {
      this.commit((state) => {
        state.submitting = false;
      });
    }
  }

  static async deleteMerchantOrderModes(orderModeId) {
    try {
      this.commit((state) => {
        state.submitting = true;
      });

      await http.delete(`merchant_order_modes/${orderModeId}`);

      this.delete(orderModeId);
    }
    catch (error) {
      throw error;
    }
    finally {
      this.commit((state) => {
        state.submitting = false;
      });
    }
  }
}
