<template>
  <div v-if="isFetchingCategory" class="pad-xl">
    <b-loading active :is-full-page="false" />
  </div>
  <validated-form
    v-else
    ref="form"
    :disabled="!$can('update', 'MenuCategory') || !$_menuPermissions.EDIT_RESOURCE"
    auto-focus
    form-id="addEditCategoryForm"
  >
    <validated-text-input
      v-model="form.displayName"
      :has-icon="false"
      label="Name"
      name="name"
      rules="required"
      type="text"
      maxlength="100"
      :has-counter="true"
    />
    <validated-text-input
      v-model="form.description"
      :has-icon="false"
      label="Description"
      name="description"
      type="textarea"
      maxlength="255"
      :has-counter="true"
    />
    <validated-text-input
      v-if="$_selectedMerchant && $_selectedMerchant.readOnlyMenuEnabled"
      v-model="form.modifierSummary"
      :has-icon="false"
      label="Sub-Description"
      name="subDescription"
      type="text"
      placeholder="Ex: Served with choice of fries, chips, or side salad"
      tooltip="Describe the options a guest can choose for this category. Shown in Digital Menu. Optional."
    />
    <validated-input
      label="Hide Category"
      sub-label="Hide this category and the items in it from the menu"
      name="hideCategory"
      tooltip="Only one category can be hidden at a time"
    >
      <b-switch
        v-model="form.statementTemplate"
        :disabled="!canEditHiddenCategory"
        true-value="HiddenTemplate"
        :false-value="null"
      >
        Hide Category
      </b-switch>
    </validated-input>

    <template v-if="$_selectedMerchant.kioskEnabled">
      <hr>

      <image-upload
        v-slot="{imagePath}"
        v-model="categoryImage.imageFile"
        :accepted-types="['png', 'webp', 'jpeg', 'jpg','svg']"
        :image-size-warning-width="250"
        :image-size-warning-height="250"
        :image-url="categoryImage && categoryImage.fullUrl"
        :loading="isFetchingCategory"
        :disabled="!$can('update', 'MenuCategory')"
        delete-text="Delete Image"
        label="Category Image"
        show-delete-button
        show-clear-button
        is-full-width
        restrict-file-size
        @delete-image="deleteMenuCategoryImage(categoryImage.id)"
      >
        <img v-if="imagePath" :src="imagePath" alt="Thumbnail Image">
      </image-upload>
    </template>
  </validated-form>
</template>

<script>
  import Category from '@/store/classes/Category';
  import Item from '@/store/classes/Item';
  import logger from '@/services/logger';
  import merchantMixin from '@/mixins/merchant';


  export default {
    name: 'CategoryForm',

    mixins: [merchantMixin],

    props: {
      categoryId: {
        type: Number,
        default: null
      },

      menuTypeId: {
        type: [String, Number],
        default: null
      },

      mode: {
        type: String,
        default: 'create',
        validator(value) {
          return ['create', 'read', 'update'].includes(value);
        }
      }
    },

    data() {
      return {
        form: new Category(),
        categoryImage: {}
      };
    },

    computed: {
      category() {
        return Category.find(this.categoryId);
      },

      isFetchingCategory() {
        return Category.$state().fetchingSingle;
      },

      canEditHiddenCategory() {
        const hiddenCategory = Category.hiddenCategory();
        return hiddenCategory ? hiddenCategory.id === this.categoryId : true;
      }
    },

    async created() {
      await this.onCreated();
    },

    methods: {
      async onCreated() {
        if (this.mode === 'update') {
          await this.fetchCategory();

          this.form = JSON.parse(JSON.stringify(this.category));
        }
        this.setImagesOnForm();
      },

      setImagesOnForm() {
        if (this.category?.menuCategoryImages[0]) {
          this.categoryImage = this.$clone(this.category?.menuCategoryImages[0]);
        }
        else {
          this.categoryImage = {};
        }
      },

      async saveCategoryImage(id = this.categoryId) {
        const { imageFile } = this.categoryImage;
        if (imageFile) {
          try {
            await Category.addMenuCategoryImage({ imageFile, menuCategoryId: id });
          }

          catch (error) {
            this.$_onRequestError({
              toastOptions: {
                message: 'An error occured while adding your image'
              },
              error
            });
          }
        }
      },

      async deleteMenuCategoryImage(imageId) {
        try {
          await Category.deleteMenuCategoryImage(this.categoryId, imageId);

          this.setImagesOnForm();

          this.$_onRequestSuccess({
            toastOptions: {
              message: 'Successfully deleted image!'
            }
          });
        }

        catch (error) {
          this.$_onRequestError({
            toastOptions: {
              message: 'An error occured while deleting your image'
            },
            error
          });
        }
      },

      async fetchCategory() {
        try {
          await Category.fetchCategory(this.categoryId);
        }
        catch (error) {
          this.$_onRequestError({
            toastOptions: {
              message: 'There was an error fetching the category'
            },
            error
          });
        }
      },

      async handleSubmit() {
        try {
          switch (this.mode) {
            case 'create':
              return await this.addCategory();

            case 'update':
              await this.updateCategory();
              break;

            default:
              break;
          }
        }
        catch (error) {
          logger.error(error);
          throw error;
        }
      },

      async addCategory() {
        try {
          const addedCategory = await Category.addCategory({
            ...this.form,
            menuTypeId: this.menuTypeId
          });

          await this.saveCategoryImage(addedCategory.id);
          return addedCategory;
        }

        catch (error) {
          this.$_onRequestError({
            toastOptions: { message: 'An error occured while adding your new category' },
            error
          });
          throw error;
        }
      },

      async handleBagTemplateChanges() {
        await Item.fetchItemsByCategoryId({ categoryId: this.categoryId });

        const bagItem = Item.bagTemplateItem();
        return bagItem ? Item.updateItem({
          id: bagItem.id,
          menuItemTemplate: null
        }) : null;
      },

      async updateCategory() {
        try {
          const promises = [Category.updateCategory(this.form), this.saveCategoryImage()];

          const categoryHiddenTemplateHasChanged = this.category.statementTemplate === 'HiddenTemplate' && !this.form.statementTemplate;
          if (categoryHiddenTemplateHasChanged) {
            promises.push(this.handleBagTemplateChanges());
          }

          await Promise.all(promises);
        }
        catch (error) {
          this.$_onRequestError({
            toastOptions: { message: `An error occured while updating <b>${this.category.displayName}</b>` },
            error
          });
          throw error;
        }
      }
    }
  };

</script>

<style scoped lang="sass">
  .modal-card
    max-width: 500px

  .b-tabs
    &.hide-tabs ::v-deep
      .tabs
        display: none

  ::v-deep
    .upload.control,
    .upload-draggable
      width: 100%

</style>
