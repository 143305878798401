const daysOfWeek = {
  Sun: 7,
  Mon: 1,
  Tues: 2,
  Wed: 3,
  Thurs: 4,
  Fri: 5,
  Sat: 6
};

export default daysOfWeek;
