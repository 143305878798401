<template>
  <div class="card is-relative">
    <b-loading v-if="isFetching" :is-full-page="false" :active="isFetching" />
    <searchable-table
      :table-data="merchantFees"
      :search="{
        placeholder: 'Search by Fee Name',
        keys: ['name']
      }"
    >
      <template #optional-actions>
        <b-button
          type="is-primary"
          icon-left="plus"
          :disabled="!$can('create', 'MerchantFee')"
          @click="openMerchantFeeModal()"
        >
          New Fee
        </b-button>
      </template>
      <template v-slot="{ filteredData }">
        <b-table
          :data="isFetching ? [] : filteredData"
          :paginated="merchantFees.length > pageLimit"
          class="is-middle-aligned"
          :per-page="pageLimit"
          :mobile-cards="false"
          :default-sort="['name', 'asc']"
          scrollable
          @sort="handleSort"
        >
          <b-table-column
            v-slot="{ row: fee }"
            sortable
            field="name"
            label="Name"
            width="1"
            cell-class="no-wrap-text"
          >
            <div class="table-cell-text-overflow">
              <span class="link" @click="openMerchantFeeModal(fee.id)">
                {{ fee.name }}
              </span>
            </div>
          </b-table-column>

          <b-table-column
            v-slot="{ row: { fee, feeTypeId } }"
            field="fee"
            label="Fee"
          >
            <div :class="['table-cell-text-overflow', !fee && 'has-text-grey-light']">
              <p v-if="fee">{{ getFeeDisplay({ fee, feeTypeId }) }}</p>
              <p v-else>N/A</p>
            </div>
          </b-table-column>

          <b-table-column
            v-slot="{ row: { scope } }"
            field="scope"
            label="Scope"
            centered
            width="1"
            sortable
            :custom-sort="handleScopeSort"
          >
            <b-tag size="is-medium" :type="getTagType(scope)" class="is-full-width">
              {{ scope | capitalize }}
            </b-tag>
          </b-table-column>

          <b-table-column
            v-slot="{ row: { isActive } }"
            centered
            field="isActive"
            label="Active"
          >
            <b-icon
              size="is-small"
              class="is-size-7"
              :type="isActive ? 'is-success' : 'is-danger'"
              icon="circle"
            />
          </b-table-column>

          <b-table-column
            v-slot="{ row: fee }"
            centered
            cell-class="actions"
            label="Actions"
            width="1"
            field="Actions"
          >
            <dropdown-menu position="bottom-end" :mobile-modal="false">
              <b-button slot="trigger" class="is-transparent" type="is-white">
                <b-icon icon="ellipsis-v" pack="far" size="is-small" />
              </b-button>

              <b-dropdown-item @click="openMerchantFeeModal(fee.id)">
                <b-icon :icon="$can('update', 'MerchantFee') ? 'pencil' : 'eye'" class="mar-r-sm" size="is-small" />
                {{ $can('update', 'MerchantFee') ? 'Edit' : 'View' }} Fee
              </b-dropdown-item>
              <template v-if="$can('destroy', 'MerchantFee')">
                <hr class="dropdown-divider">
                <b-dropdown-item class="is-danger" @click="openDeleteConfirmationModal(fee)">
                  <b-icon
                    icon="trash-alt"
                    class="mar-r-sm"
                    size="is-small"
                    type="is-danger"
                  />
                  Delete Fee
                </b-dropdown-item>
              </template>
            </dropdown-menu>
          </b-table-column>

          <template #empty>
            <empty-table-loader
              icon="dollar-sign"
              message="No Fees Found"
              :loading="isFetching"
            />
          </template>

        </b-table>
      </template>
    </searchable-table>
  </div>
</template>


<script>
  import MerchantFee from '@/store/classes/MerchantFee';
  import merchantFeeModal from './merchant-fees/merchant-fee-modal.vue';
  import FulfillmentType from '@/store/classes/FulfillmentType';
  import Store from '@/store/classes/Store';
  import FeeType from '@/store/classes/FeeType';
  import currency from 'currency.js';
  import alertModal from '@/components/globals/alert-modal.vue';


  export default {
    name: 'MerchantFees',

    props: {
      merchant: {
        type: Object,
        required: true
      }
    },

    data() {
      return {
        sortField: null,
        sortDirection: null,
        pageLimit: 20
      };
    },

    computed: {
      merchantFees() {
        return MerchantFee.all().map(fee => ({ ...fee, scope: fee.scope }));
      },

      isFetching() {
        return MerchantFee.$state().fetching
          || FulfillmentType.$state().fetching
          || FeeType.$state().fetching
          || Store.$state().fetchingAll;
      }
    },

    created() {
      this.onCreated();
    },

    methods: {
      async onCreated() {
        await Promise.all([
          this.fetchMerchantFees(),
          this.fetchFulfillmentTypes(),
          this.fetchFeeTypes(),
          this.fetchStores()
        ]);
      },

      handleScopeSort(a, b, isAsc) {
        const sortingMap = { global: 1, location: 2 };
        const sort = sortingMap[a.scope] > sortingMap[b.scope] ? 1 : -1;
        return isAsc ? sort : (sort * -1);
      },

      getFeeDisplay({ fee, feeTypeId }) {
        if (feeTypeId === FeeType.typesById().PERCENTAGE_DISCOUNT) {
          const convertedPercent = parseFloat(Number(fee) * 100).toFixed(2);
          const splitPercent = String(convertedPercent).split('.');
          const fullDisplay = splitPercent[1] === '00' ? splitPercent[0] : convertedPercent;
          return `${fullDisplay}%`;
        }
        return currency(fee).format();
      },

      getTagType(scope) {
        switch (scope) {
          case 'global':
            return 'is-primary is-light';
          case 'location':
            return 'is-success is-light';
          default:
            return null;
        }
      },

      async fetchMerchantFees() {
        try {
          await MerchantFee.fetchMerchantFees(this.merchant.id);
        }
        catch (error) {
          this.$_onRequestError({
            error,
            toastOptions: {
              message: 'There was an error fetching merchant fees'
            }
          });
        }
      },

      async fetchStores() {
        try {
          await Store.fetchAll(this.merchant.id);
        }
        catch (error) {
          this.$_onRequestError({
            error,
            toastOptions: {
              message: 'There was an error fetching stores'
            }
          });
        }
      },

      async fetchFulfillmentTypes() {
        try {
          await FulfillmentType.fetchFulfillmentTypes();
        }
        catch (error) {
          this.$_onRequestError({
            error,
            toastOptions: {
              message: 'There was an error fetching fulfillment types'
            }
          });
        }
      },

      async fetchFeeTypes() {
        try {
          await FeeType.fetchFeeTypes();
        }
        catch (error) {
          this.$_onRequestError({
            error,
            toastOptions: {
              message: 'There was an error fetching fee types'
            }
          });
        }
      },

      async deleteMerchantFee(merchantFeeId) {
        try {
          await MerchantFee.deleteMerchantFee(merchantFeeId);

          this.$_onRequestSuccess({
            toastOptions: {
              message: 'Successfully removed your merchant fee!'
            }
          });
        }
        catch (error) {
          this.$_onRequestError({
            error,
            toastOptions: {
              message: 'There was an error removing your merchant fee'
            }
          });
        }
      },

      handleSort(field, direction) {
        this.sortField = field;
        this.sortDirection = direction;
      },


      openDeleteConfirmationModal({ id, name }) {
        this.$buefy.modal.open({
          parent: this,
          component: alertModal,
          hasModalCard: true,
          trapFocus: true,
          canCancel: ['escape', 'outside'],
          customClass: 'auto-width',
          props: {
            title: 'Delete Merchant Fee',
            message: `Are you sure you want to delete ${name} fee?`,
            showCloseButton: false,
            horizontal: true,
            icon: 'trash-alt',
            type: 'is-danger',
            buttons: [
              { text: 'Cancel' },
              { text: 'Delete Fee', primary: true, onClick: () => this.deleteMerchantFee(id) }
            ]
          }
        });
      },

      async openMerchantFeeModal(merchantFeeId) {
        this.$buefy.modal.open({
          parent: this,
          component: merchantFeeModal,
          hasModalCard: true,
          trapFocus: true,
          canCancel: false,
          customClass: 'auto-width',
          props: { merchantFeeId, merchant: this.merchant }
        });
      }
    }
  };
</script>

<style lang='sass' scoped>
  .table-cell-text-overflow
    max-width: 300px
    min-width: 100%
    overflow: hidden
    white-space: nowrap

    p
      overflow: hidden
      text-overflow: ellipsis

  ::v-deep
    .tag
      width: 6rem
      font-weight: 700

      &.draft
        background-color: lighten($grey, 43)
        color: darken($grey, 40)
      &.ready
        background-color: lighten($success, 37)
        color: darken($success, 40)
      &.live
        background-color: lighten($primary, 43)
        color: darken($primary, 40)
      &.expired
        background-color: lighten($danger, 33)
        color: darken($danger, 40)

</style>
