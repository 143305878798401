/* eslint-disable import/no-cycle */
import { Model } from '@vuex-orm/core';
import http from '@/services/http';
import filterObjectKeys from '@/helpers/filter-object-keys';



export default class StoreItem extends Model {
  static entity = 'storeItems'

  static fields() {
    return {
      id: this.attr(''),
      price: this.attr('', val => (val ? parseFloat(val).toFixed(2) : val)),
      storeId: this.attr(''),
      menuId: this.attr(''),
      menuItemId: this.attr(''),
      // default to true
      isDisabled: this.attr(true),
      isManuallyDisabled: this.attr(true),
      totalSalesTaxRate: this.attr(null),
      inventoryCount: this.attr(null),
      lowInventoryCount: this.attr(null),
      createdAt: this.attr(null),
      updatedAt: this.attr(null),
      createdSource: this.attr(null),
      modifiedSource: this.attr(null),
      suspendUntilDate: this.attr(null)
    };
  }



  // STATE //////////////////////

  static state() {
    return {
      fetching: false,
      submitting: false
    };
  }

  static $state() {
    return this.store().state.entities.storeItems;
  }



  // ACTIONS //////////////////////

  static async fetchStoreMenuItemsByStoreId(storeId) { // TEST
    try {
      this.commit((state) => {
        state.fetching = true;
      });

      const response = await http.get(`stores/${storeId}/store_menu_items`);

      this.insert({
        data: response.data.storeMenuItems,
        update: ['categories']
      });

      return response.data.storeMenuItems;
    }
    catch (error) {
      throw error;
    }
    finally {
      this.commit((state) => {
        state.fetching = false;
      });
    }
  }

  static async addStoreItem(storeMenuItem) {
    if (!storeMenuItem.price) {
      storeMenuItem.price = '0.00';
    }

    try {
      this.commit((state) => {
        state.submitting = true;
      });

      const acceptedKeys = [
        'price',
        'lowInventoryCount',
        'menuId',
        'isDisabled',
        'isManuallyDisabled',
        'inventoryCount',
        'totalSalesTaxRate',
        'applyPriceToAll',
        'applyLowInventoryCountToAll',
        'suspendUntilDate'
      ];

      const response = await http.post(
        `menu_items/${storeMenuItem.menuItemId}/store_menu_items`,
        { storeMenuItem: filterObjectKeys(storeMenuItem, acceptedKeys) }
      );

      this.insert({ data: response.data.storeMenuItem });

      return response.data.storeMenuItem;
    }

    catch (error) {
      throw error;
    }

    finally {
      this.commit((state) => {
        state.submitting = false;
      });
    }
  }

  // wherever this is used -- we need to pass to the BE if we're toggling visiblitly
  static async updateStoreItem(storeMenuItem) {
    try {
      this.commit((state) => {
        state.submitting = true;
      });

      const acceptedKeys = [
        'price',
        'lowInventoryCount',
        'isDisabled',
        'isManuallyDisabled',
        'inventoryCount',
        'totalSalesTaxRate',
        'applyPriceToAll',
        'applyLowInventoryCountToAll',
        'suspendUntilDate'
      ];

      const merchantId = this.store().state.entities.merchants.selectedMerchantId;
      const response = await http.put(
        `merchants/${merchantId}/store_menu_items/${storeMenuItem.id}`,
        { storeMenuItem: filterObjectKeys(storeMenuItem, acceptedKeys) }
      );

      this.update({ data: response.data.storeMenuItem });
    }

    catch (error) {
      throw error;
    }

    finally {
      this.commit((state) => {
        state.submitting = false;
      });
    }
  }
}
