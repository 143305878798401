<template>
  <steps-modal
    v-model="form"
    title="Segmentation Builder"
    subtitle="Create a segmentation"
    :steps="steps"
    :loading="isLoading"
    :mode="mode"
    confirm-close
    @valid-submit="handleSubmit"
    @close="$parent.close()"
  />
</template>

<script>
  import Segmentation from '@/store/classes/Segmentation';
  import configurationStep from './modal-steps/configuration-step.vue';
  import merchantMixin from '@/mixins/merchant';
  import { criteria } from '@/constants/segmentations';

  export default {
    name: 'SegmentationModal',

    mixins: [merchantMixin],

    props: {
      segmentation: {
        type: Object,
        default: null
      },

      mode: {
        type: String,
        default: 'create',
        validator(value) {
          return ['create', 'read', 'update'].includes(value);
        }
      }
    },

    data() {
      return {
        form: null
      };
    },

    computed: {
      isLoading() {
        return Segmentation.$state().submitting;
      },

      steps() {
        return [
          {
            label: 'Configuration',
            component: configurationStep
          }
        ];
      }
    },

    created() {
      this.onCreated();
    },

    methods: {
      onCreated() {
        this.form = this.segmentation
          ? this.$clone(this.segmentation)
          : new Segmentation({ merchantId: this.$_selectedMerchantId });

        const hasValues = Object.values(criteria).some(ct => this.form.criteria[ct]);
        if (hasValues) this.form.isCustomCriteria = true;
      },

      async handleSubmit() {
        try {
          if (this.segmentation) {
            await Segmentation.updateSegmentation(this.form);
          }
          else {
            await Segmentation.addSegmentation(this.form);
          }
          this.$_onRequestSuccess({
            toastOptions: {
              message: 'Successfully saved your segmentation!'
            },
            options: { closeParent: true }
          });
        }
        catch (error) {
          this.$_onRequestError({
            error,
            toastOptions: { message: 'There was an error saving your segmentation' }
          });
        }
      }
    }
  };
</script>
