<template>
  <div class="detail-page is-flex-column">
    <div class="flex-grow is-relative">
      <div class="container">
        <b-loading :active="isFetching || isFetchingOffer" :is-full-page="false" />
        <div v-if="offers.length" class="card">
          <searchable-table
            :table-data="offers"
            :search="{
              placeholder: 'Search by Offer Name',
              keys: ['name']
            }"
            :filters="tableFilters"
          >
            <template #optional-actions>
              <b-button
                type="is-primary"
                icon-left="plus"
                @click="selectOffer()"
              >
                New Offer
              </b-button>
              <dropdown-menu trigger="click" position="left-start">
                <b-button slot="trigger" type="is-primary">
                  <b-icon icon="info-circle" pack="far" />
                </b-button>
                <b-dropdown-item class="no-wrap-text" custom>
                  <b-tag type="is-dark" class="draft has-text-weight-bold mar-r-sm">Draft</b-tag>
                  <span>The offer can be edited until it's finalized and ready to be delivered in a campaign</span>
                </b-dropdown-item>
                <b-dropdown-item class="no-wrap-text" custom>
                  <b-tag type="is-primary" class="live has-text-weight-bold mar-r-sm">Live</b-tag>
                  <span>The offer has been delivered to your customers in a campaign and is live</span>
                </b-dropdown-item>
                <b-dropdown-item class="no-wrap-text" custom>
                  <b-tag type="is-success" class="expired has-text-weight-bold mar-r-sm">Expired</b-tag>
                  <span>The offer is no longer available to be delivered in a campaign or redeemed by a customer</span>
                </b-dropdown-item>
              </dropdown-menu>
            </template>
            <template v-slot="{ filteredData }">
              <b-table
                :data="isFetching ? [] : filteredData"
                :paginated="offers.length > pageLimit"
                class="is-middle-aligned"
                :per-page="pageLimit"
                :mobile-cards="false"
                :default-sort="['name', 'asc']"
                scrollable
                @sort="handleSort"
              >
                <b-table-column
                  v-slot="{ row: offer }"
                  sortable
                  field="name"
                  label="Name"
                  width="1"
                  cell-class="no-wrap-text"
                >
                  <div class="is-flex">
                    <p class="link table-cell-text-overflow text-ellipsis mar-r-xs" @click="selectOffer(offer.guid)">
                      {{ offer.name }}
                    </p>
                    <b-icon v-if="offer.autoApplicable && $_selectedMerchant.hasAutoApplicableOffers" icon="circle-bolt" />
                  </div>
                </b-table-column>

                <b-table-column
                  v-slot="{ row: { description } }"
                  field="description"
                  label="Description"
                >
                  <div :class="['table-cell-text-overflow', !description && 'has-text-grey-light']">
                    <p>{{ description || 'N/A' }}</p>
                  </div>
                </b-table-column>

                <b-table-column
                  v-slot="{ row: offer }"
                  field="guid"
                  label="ID"
                  width="1"
                  cell-class="no-wrap-text is-monospaced"
                >
                  <div class="table-cell-text-overflow">
                    <span>{{ offer.state === offerStates.DRAFT ? 'N/A' : offer.guid }}</span>
                  </div>
                </b-table-column>

                <b-table-column
                  v-slot="{ row: { state } }"
                  sortable
                  centered
                  :custom-sort="sortByState"
                  field="state"
                  label="State"
                >
                  <b-tag :class="state">
                    {{ state | capitalize }}
                  </b-tag>
                </b-table-column>

                <b-table-column
                  v-slot="{ row }"
                  centered
                  cell-class="actions"
                  label="Actions"
                  width="1"
                  field="Actions"
                >
                  <dropdown-menu position="bottom-end" :mobile-modal="false">
                    <b-button slot="trigger" class="is-transparent" type="is-white">
                      <b-icon icon="ellipsis-v" pack="far" size="is-small" />
                    </b-button>

                    <b-dropdown-item @click="selectOffer(row.guid)">
                      <b-icon :icon="isOfferEditable(row.state) ? 'pencil' : 'eye'" class="mar-r-sm" size="is-small" />
                      {{ isOfferEditable(row.state) ? 'Edit' : 'View' }} Offer
                    </b-dropdown-item>
                    <b-dropdown-item @click="cloneOffer(row.guid)">
                      <b-icon icon="clone" class="mar-r-sm" size="is-small" />
                      Clone Offer
                    </b-dropdown-item>
                  </dropdown-menu>
                </b-table-column>

                <template #empty>
                  <empty-table-loader
                    icon="gift"
                    message="No Offers Found"
                    :loading="isFetching"
                  />
                </template>

              </b-table>
            </template>
          </searchable-table>
        </div>
        <empty-state-card
          v-else-if="!isFetching && !offers.length"
          type="is-primary"
          title="Create an Offer"
          message="Create offers that guests can earn or receive from a campaign!"
          icon="gift"
        >
          <template #buttons>
            <b-button
              type="is-primary"
              rounded
              icon-left="plus"
              @click="selectOffer()"
            >
              New Offer
            </b-button>
          </template>
        </empty-state-card>
      </div>
    </div>
  </div>
</template>


<script>
  import Offer from '@/store/classes/Offer';
  import offerModal from '../offer-modal.vue';
  import { offerStates } from '@/constants/offers';
  import capitalCase from '@/helpers/capitalCase';
  import PosDiscountConfiguration from '@/store/classes/PosDiscountConfiguration';
  import posTypes from '@/constants/posTypes';
  import merchantMixin from '@/mixins/merchant';

  export default {
    name: 'OfferList',

    mixins: [merchantMixin],

    data() {
      return {
        sortField: null,
        sortDirection: null,
        pageLimit: 20,
        offerStates,
        posTypes
      };
    },

    computed: {
      offers() {
        return Offer.all();
      },

      isFetching() {
        return Offer.$state().fetchingAll || PosDiscountConfiguration.$state().fetching;
      },

      isFetchingOffer() {
        return Offer.$state().fetching;
      },

      tableFilters() {
        return [
          {
            placeholder: 'State',
            key: 'state',
            options: Object.values(offerStates).map(state => ({
              name: capitalCase(state),
              value: state
            }))
          },
          this.$_selectedMerchant.hasAutoApplicableOffers && {
            placeholder: 'Auto Applicable',
            key: 'autoApplicable',
            options: [
              { name: 'Auto Applicable', value: true }
            ]
          }
        ].filter(Boolean);
      }
    },

    created() {
      this.onCreated();
    },

    methods: {
      async onCreated() {
        await Promise.all([this.fetchOffers(), this.fetchPosDiscountCodes()]);
      },

      async fetchPosDiscountCodes() {
        try {
          await PosDiscountConfiguration.fetchPosDiscounts();
        }
        catch (error) {
          this.$_onRequestError({
            error,
            toastOptions: {
              message: 'There was an error fetching POS discount codes'
            }
          });
        }
      },

      async fetchOffers() {
        try {
          await Offer.fetchOffers();
        }
        catch (error) {
          this.$_onRequestError({
            error,
            toastOptions: {
              message: 'There was an error fetching offers'
            }
          });
        }
      },

      isOfferEditable(offerState) {
        return offerState !== offerStates.EXPIRED;
      },

      sortByState(a, b, isAsc) {
        const states = Object.values(offerStates);
        const sortOrders = states.reduce((acc, type, i) => {
          acc[type] = i + 1;
          return acc;
        }, {});
        if (isAsc) {
          return sortOrders[a.state] < sortOrders[b.state] ? -1 : 1;
        }
        return sortOrders[a.state] < sortOrders[b.state] ? 1 : -1;
      },

      handleSort(field, direction) {
        this.sortField = field;
        this.sortDirection = direction;
      },

      async fetchOffer(offerGuid) {
        try {
          await Offer.fetchOffer(offerGuid);
        }

        catch (error) {
          this.$_onRequestError({
            toastOptions: {
              message: 'There was an error fetching your offer'
            },
            error
          });
        }
      },

      async selectOffer(offerGuid) {
        if (offerGuid) {
          await this.fetchOffer(offerGuid);
        }
        const offer = offerGuid ? Offer.find(offerGuid) : new Offer();

        this.openOfferModal(offer);
      },

      async cloneOffer(offerGuid) {
        await Offer.fetchOffer(offerGuid);
        const existingOffer = Offer.find(offerGuid);
        const clonedOffer = Offer.clone(this.$clone(existingOffer));
        this.openOfferModal(clonedOffer);
      },

      async convertMultiValuePropositionOffer(valueProposition) {
        const { posType, ...baseValueProposition } = valueProposition.valuePropositions[0];
        const itemGroup = baseValueProposition.itemGroup;
        const posItems = [];
        const menuItems = [];

        valueProposition.valuePropositions.forEach((vp) => {
          vp.itemGroup.menuItems.forEach((menuItem) => {
            if (!menuItems.find(item => item.id === menuItem.id)) {
              menuItems.push(menuItem);
            }

            menuItem.posItems.forEach((posItem) => {
              posItems.push({
                id: posItem.id,
                menuItemId: menuItem.id,
                posTypeId: posTypes[vp.posType],
                posType: vp.posType,
                displayName: menuItem.displayName
              });
            });
          });
        });

        const mergedValueProposition = {
          ...baseValueProposition,
          itemGroup: {
            ...itemGroup,
            itemGroupPublicId: null,
            posItems,
            posItemIds: posItems.map(item => item.id),
            menuItems,
            menuItemIds: menuItems.map(item => item.id)
          }
        };

        return mergedValueProposition;
      },

      async openOfferModal(offer) {
        if (offer.valueProposition.valuePropositions?.length) {
          offer.valueProposition = await this.convertMultiValuePropositionOffer(offer.valueProposition);
        }
        this.$buefy.modal.open({
          parent: this,
          component: offerModal,
          hasModalCard: true,
          trapFocus: true,
          canCancel: false,
          fullScreen: true,
          props: { offer }
        });
      }
    }
  };
</script>

<style lang='sass' scoped>
  .table-cell-text-overflow
    max-width: 300px
    min-width: 100%
    overflow: hidden
    white-space: nowrap

    p
      overflow: hidden
      text-overflow: ellipsis

  ::v-deep
    .tag
      width: 6rem
      font-weight: 700

      &.draft
        background-color: lighten($grey, 43)
        color: darken($grey, 40)
      &.ready
        background-color: lighten($success, 37)
        color: darken($success, 40)
      &.live
        background-color: lighten($primary, 43)
        color: darken($primary, 40)
      &.expired
        background-color: lighten($danger, 33)
        color: darken($danger, 40)

</style>
