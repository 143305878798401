<template>
  <div class="card">
    <div class="card-header">
      <div class="card-header-title pad-lg">
        <p class="title is-5">Review</p>
      </div>
    </div>
    <div class="card-content">
      <template v-if="!offerReadOnly">
        <p class="is-4 subtitle mar-b-xs">Review your offer</p>
        <p class="is-6 subtitle has-text-grey">Please confirm your settings before publishing</p>
      </template>

      <!-- Details -->
      <div class="dist-y-lg">
        <offer-details-card
          :offer="value"
          :offer-details-are-read-only="offerDetailsAreReadOnly"
          @navigate-to-step="$emit('navigate-to-step', $event)"
        />
        <value-proposition-card
          :offer="value"
          :offer-read-only="offerReadOnly"
          @navigate-to-step="$emit('navigate-to-step', $event)"
        />
        <constraints-card
          :constraints="value.constraints"
          :offer-read-only="offerReadOnly"
          @navigate-to-step="$emit('navigate-to-step', $event)"
        />
        <time-frame-card
          :offer="value"
          :offer-read-only="offerReadOnly"
          @navigate-to-step="$emit('navigate-to-step', $event)"
        />
      </div>
    </div>
  </div>
</template>

<script>
  import OfferDetailsCard from './offer-review/offer-details-card.vue';
  import ValuePropositionCard from './offer-review/value-proposition-card.vue';
  import ConstraintsCard from './offer-review/constraints-card.vue';
  import TimeFrameCard from './offer-review/time-frame-card.vue';
  import scrollIndicatorMixin from '@/mixins/scroll-container-indicator';

  export default {
    name: 'OfferReviewStep',

    components: { OfferDetailsCard, ValuePropositionCard, ConstraintsCard, TimeFrameCard },

    mixins: [scrollIndicatorMixin],

    props: {
      value: {
        type: Object,
        required: true
      },
      offerReadOnly: {
        type: Boolean,
        required: true
      },
      offerDetailsAreReadOnly: {
        type: Boolean,
        required: true
      },
      isScrollable: {
        type: Boolean,
        default: false
      }
    },

    mounted() {
      if (this.isScrollable) {
        this.$_initScrollIndicatorSmart({
          contentSelector: '.card-content',
          maxHeight: 800
        });
      }
    }
  };
</script>

<style scoped lang="sass">
  .link:hover
    ::v-deep span
      border-bottom: 1px solid $primary !important
</style>
