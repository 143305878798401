const copyToClipboard = {
  methods: {
    $_copyToClipboard(value) {
      navigator.clipboard.writeText(value);
      this.$buefy.toast.open({
        message: '<i style="animation-delay: 125ms" class="animated tdFadeInLeft fa fa-copy mar-r-sm"></i> Copied to clipboard',
        queue: false,
        type: 'is-success'
      });
    }
  }
};

export default copyToClipboard;
