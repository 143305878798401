import { Model } from '@vuex-orm/core';
import http from '@/services/http';

export default class MerchantLoyaltyMembership extends Model {
  static entity = 'merchantLoyaltyMemberships'


  // FIELDS //////////////////////
  static fields() {
    return {
      id: this.attr(null),
      // Loyalty Options
      cfLoyaltyEnabled: this.boolean(false),
      loyaltyEnabled: this.boolean(false),
      // Offers Options
      accountOffersEnabled: this.boolean(false),
      cfOffersEnabled: this.boolean(false)
    };
  }


  // STATE //////////////////////
  static state() {
    return {
      fetching: false
    };
  }

  static $state() {
    return this.store().state.entities.merchantLoyaltyMemberships;
  }


  // ACTIONS //////////////////////
  static async fetchLoyaltyMembershipOptions(merchantId) {
    try {
      this.commit((state) => {
        state.fetching = true;
      });
      const { data } = await http.get(`merchants/${merchantId}/loyalty_options`);
      this.create({
        data: {
          id: merchantId,
          ...data.loyaltyOptions
        }
      });
    }
    catch (error) {
      throw error;
    }
    finally {
      this.commit((state) => {
        state.fetching = false;
      });
    }
  }
}

