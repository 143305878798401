var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('validated-form',{ref:"itemDetailsForm",attrs:{"disabled":!_vm.$can('update', 'MenuItem') || !_vm.$_menuPermissions.EDIT_RESOURCE,"auto-focus":"","form-id":"itemDetailsForm"}},[_c('validated-text-input',{attrs:{"has-icon":false,"label":"Name","name":"displayName","rules":"required","type":"text","maxlength":"255","has-counter":true},model:{value:(_vm.form.displayName),callback:function ($$v) {_vm.$set(_vm.form, "displayName", $$v)},expression:"form.displayName"}}),(_vm.$_featurePermissions.MENU_ITEM_UPC)?_c('validated-text-input',{attrs:{"has-icon":false,"label":"UPC","name":"universalProductCode","type":"text","maxlength":"12","rules":{
        length: 12,
        integer: true
      },"custom-messages":{ length: 'The upc field must be 12 digits' },"has-counter":"","tooltip":"Scan code with a barcode scanner or enter manually","highlight-on-focus":""},model:{value:(_vm.form.universalProductCode),callback:function ($$v) {_vm.$set(_vm.form, "universalProductCode", $$v)},expression:"form.universalProductCode"}}):_vm._e(),_c('validated-text-input',{attrs:{"has-icon":false,"label":"Description","name":"description","type":"textarea","maxlength":"2000","has-counter":true},model:{value:(_vm.form.descriptionText),callback:function ($$v) {_vm.$set(_vm.form, "descriptionText", $$v)},expression:"form.descriptionText"}}),(_vm.$_selectedMerchant && _vm.$_selectedMerchant.readOnlyMenuEnabled)?_c('validated-text-input',{attrs:{"has-icon":false,"label":"Sub-Description","name":"subDescription","type":"text","placeholder":"Ex: Choice of white, wheat, or rye bread","tooltip":"Describe the options a guest can choose for this item. Shown in Digital Menu. Optional."},model:{value:(_vm.form.modifierSummary),callback:function ($$v) {_vm.$set(_vm.form, "modifierSummary", $$v)},expression:"form.modifierSummary"}}):_vm._e(),_c('validated-input',{attrs:{"label":"Calories","name":"caloriesLabel","tooltip":"If there is only one value, use the “low” field","tooltip-placement":"right"}}),_c('div',{staticClass:"is-grid col-3 gap-md"},[_c('validated-text-input',{attrs:{"label":"Low","name":"caloriesLow","type":"number","placeholder":"Ex: 20","rules":Object.assign({}, {required: !!_vm.form.caloriesHigh},
          (_vm.form.caloriesHigh ? { lessThan: { target: _vm.form.caloriesHigh } } : {})),"label-position":"on-border"},model:{value:(_vm.form.caloriesLow),callback:function ($$v) {_vm.$set(_vm.form, "caloriesLow", $$v)},expression:"form.caloriesLow"}}),_c('validated-text-input',{attrs:{"label":"High","name":"caloriesHigh","type":"number","placeholder":"Ex: 100","rules":Object.assign({}, (_vm.form.caloriesLow ? { greaterThan: { target: _vm.form.caloriesLow } } : {})),"label-position":"on-border"},model:{value:(_vm.form.caloriesHigh),callback:function ($$v) {_vm.$set(_vm.form, "caloriesHigh", $$v)},expression:"form.caloriesHigh"}}),_c('validated-text-input',{attrs:{"label":"Units","name":"nutritionalUnits","type":"text","placeholder":"Ex: calories","rules":{ required: !!_vm.form.caloriesLow || !!_vm.form.caloriesHigh },"label-position":"on-border"},model:{value:(_vm.form.nutritionalUnits),callback:function ($$v) {_vm.$set(_vm.form, "nutritionalUnits", $$v)},expression:"form.nutritionalUnits"}})],1),_c('hr'),_c('p',{staticClass:"subtitle is-5 mar-b"},[_vm._v("Images")]),_c('image-upload',{attrs:{"accepted-types":['png', 'webp', 'jpeg', 'jpg', 'svg'],"image-size-warning-width":640,"image-size-warning-height":472,"image-url":_vm.thumbnailImage && _vm.thumbnailImage.fullUrl,"loading":_vm.isFetchingImages,"disabled":!_vm.$can('update', 'MenuItem'),"delete-text":"Delete Image","label":"Menu Image","maximum-file-size-in-mb":1,"restrict-file-size":"","show-delete-button":"","show-clear-button":"","is-full-width":""},on:{"delete-image":function($event){return _vm.deleteImage(_vm.thumbnailImage.id)}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var imagePath = ref.imagePath;
return [(imagePath)?_c('img',{attrs:{"src":imagePath,"alt":"Thumbnail Image"}}):_vm._e()]}}]),model:{value:(_vm.thumbnailImage.imageFile),callback:function ($$v) {_vm.$set(_vm.thumbnailImage, "imageFile", $$v)},expression:"thumbnailImage.imageFile"}}),_c('image-upload',{attrs:{"accepted-types":['png', 'webp', 'jpeg', 'jpg', 'svg'],"image-size-warning-width":1440,"image-size-warning-height":600,"image-url":_vm.bannerImage && _vm.bannerImage.fullUrl,"loading":_vm.isFetchingImages,"disabled":!_vm.$can('update', 'MenuItem'),"delete-text":"Delete Image","label":"Item Details Image","maximum-file-size-in-mb":1,"restrict-file-size":"","show-delete-button":"","show-clear-button":"","is-full-width":""},on:{"delete-image":function($event){return _vm.deleteImage(_vm.bannerImage.id)}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var imagePath = ref.imagePath;
return [(imagePath)?_c('img',{attrs:{"src":imagePath,"alt":"Banner Image"}}):_vm._e()]}}]),model:{value:(_vm.bannerImage.imageFile),callback:function ($$v) {_vm.$set(_vm.bannerImage, "imageFile", $$v)},expression:"bannerImage.imageFile"}}),(_vm.$can('update', 'MenuItem') && (_vm.bannerImage.id || _vm.thumbnailImage.id))?_c('b-message',{attrs:{"size":"is-small"}},[_vm._v(" You can replace an image above by dragging a new image over or clicking on the old one ")]):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }