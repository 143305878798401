<template>
  <div tabindex="0" class="modal-card">
    <header class="modal-card-head">
      <div class="modal-card-title">
        <p>Notifications <span class="has-text-grey subtitle">({{ queueSize }})</span></p>
      </div>
      <div role="button" class="button is-transparent mar-l close-button" @click="setNotificationsModalActive(false)">
        <b-icon icon="times" size="is-small" />
      </div>
    </header>

    <div class="modal-card-body is-relative">
      <div class="dist-y-xs">
        <div v-for="(notification, i) in notificationQueue" :key="`notification-${i}`">
          <expo-notification-card
            :key="`notification-${i}-snackbar`"
            :notification="notification"
            :loading="isLoading"
            @dismiss-notification="dismissNotification(i)"
            @view-ticket="handleNavigateToOrder(notification.orderId, i)"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { mapActions, mapGetters } from 'vuex';
  import ExpoNotificationCard from './expo-notification-card.vue';

  export default {
    name: 'OrderExpoNotifications',

    components: { ExpoNotificationCard },

    mixins: [],

    props: {},

    data: () => ({
      isLoading: false
    }),

    computed: {
      ...mapGetters('expoNotifications', ['notificationQueue', 'queueSize'])
    },

    methods: {
      ...mapActions('orderExpo', ['setNotificationsModalActive', 'navigateToOrder']),
      ...mapActions('expoNotifications', ['dismissNotification']),

      async handleNavigateToOrder(orderId, i) {
        try {
          this.isLoading = true;
          await this.navigateToOrder(orderId);
          this.dismissNotification(i);
          this.setNotificationsModalActive(false);
        }
        catch (error) {
          this.$_onRequestError({
            error,
            message: 'Failed to navigate to order'
          });
        }
        finally {
          this.isLoading = false;
        }
      }
    }
  };
</script>
