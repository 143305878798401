<template>
  <li
    :class="['draggable', {'is-open': isOpen}]"
    :data-modifier-group-id="modifierGroup.id"
  >
    <div class="row sub-row">
      <span class="pad-y-sm">
        <b-icon
          v-if="canSortResource"
          icon="grip-lines"
          size="is-small"
          pack="far"
          class="drag-handle"
        />
        <b-icon
          v-if="featurePermissions.NESTED_MODIFIERS && modifierGroup.type"
          type="is-primary"
          class="mar-r-xs"
          pack="fad"
          :icon="modifierGroupIcons[modifierGroup.type]"
        />
        <span class="link-inverted" @click="toggleModifierGroup(modifierGroup)">
          {{ modifierGroup.displayName }}
          <b-icon
            size="is-small"
            pack="far"
            :icon="fetchingModifier ? 'spinner-third' : 'angle-right'"
            :class="[
              'open-indicator',
              {
                'is-open': isOpen,
                'spin': fetchingModifier
              }
            ]"
          />
        </span>
      </span>
      <span />
      <span class="align-center justify-end">
        <template v-if="!activeMenuType">
          <b-button
            class="is-transparent"
            @click="handleOpenModifierGroupModal({ modifierGroupId: modifierGroup.id })"
          >
            <b-icon
              v-if="$can('update', 'MenuItemModifierGroup') && menuPermissions.EDIT_RESOURCE"
              :icon="fetchingModifier ? 'spinner-third' : 'pencil'"
              :class="{ 'spin': fetchingModifier }"
              size="is-small"
            />
            <span v-else>View</span>
          </b-button>
          <b-dropdown
            v-if="($can('destroy', 'MenuItemModifierGroup') && menuPermissions.REMOVE_RESOURCE)"
            aria-role="list"
            position="is-bottom-left"
          >
            <b-button slot="trigger" class="is-transparent" type="is-white">
              <b-icon icon="ellipsis-v" pack="far" size="is-small" />
            </b-button>
            <template v-if="$can('create', 'MenuItemModifierGroup') && menuPermissions.CLONE_ITEM">
              <template v-if="featurePermissions.SHARED_MODIFIER_GROUPS && selectedMerchant.allowModifierGroupAssociation">
                <b-dropdown-item @click="openBulkManageModifierGroupItemAssociationModal(modifierGroup.id)">
                  <b-icon
                    icon="copy"
                    class="mar-r-sm"
                    size="is-small"
                  />
                  Manage Modifier Group Associations
                </b-dropdown-item>
              </template>
              <template v-else-if="!featurePermissions.SHARED_MODIFIER_GROUPS">
                <b-dropdown-item @click="openCloneModifierGroupModal(modifierGroup)">
                  <b-icon
                    icon="copy"
                    class="mar-r-sm"
                    size="is-small"
                  />
                  Copy Modifier Group
                </b-dropdown-item>
              </template>
              <hr
                v-if="!featurePermissions.SHARED_MODIFIER_GROUPS || (featurePermissions.SHARED_MODIFIER_GROUPS && selectedMerchant.allowModifierGroupAssociation)"
                class="dropdown-divider"
              >
            </template>
            <b-dropdown-item
              class="is-danger"
              @click="openConfirmDeleteModifierGroupConfirmationModal()"
            >
              <b-icon icon="trash-alt" class="mar-r-sm" size="is-small" />
              Remove
              <span>{{ modifierGroup.type ? `${modifierGroup.type} Group` : 'Modifier Group' | capitalize }}</span>
            </b-dropdown-item>
          </b-dropdown>
        </template>
      </span>
    </div>

    <nested-modifiers-container
      v-if="isOpen"
      :modifier-group="modifierGroup"
      :menu-item-modifiers="menuItemModifiers"
    />
  </li>
</template>

<script>
  import { mapActions, mapState } from 'vuex';

  import menuResourceTypes from '@/constants/menuResourceTypes';
  import { modifierGroupTypes } from '@/constants/modifierGroups';

  import AlertModal from '@/components/globals/alert-modal.vue';

  import Item from '@/store/classes/Item';
  import Modifier from '@/store/classes/Modifier';
  import ModifierGroup from '@/store/classes/ModifierGroup';
  import MenuItemModifierGroupItem from '@/store/classes/MenuItemModifierGroupItem';

  import AddEditModifierGroupModal from '../main-menu/add-edit-modifier-group-modal.vue';
  import AddModifierGroupsToItemModal from '../main-menu/modifier-groups/add-modifier-groups-to-item-modal.vue';
  import BulkManageModifierGroupItemAssociationModal from '../main-menu/modifier-groups/bulk-manage-modifier-group-item-association-modal.vue';
  import SharedModifierGroupModal from '../main-menu/modifier-groups/shared-modifier-group-modal.vue';

  import NestedModifiersContainer from './nested-modifiers-container.vue';

  export default {
    name: 'NestedModifierGroupRow',

    components: { NestedModifiersContainer },

    props: {
      modifierGroup: {
        type: Object,
        required: true
      },

      menuItemModifierGroupItem: {
        type: Object,
        default: null
      },

      menuItem: {
        type: Object,
        required: true
      },

      parentModifier: {
        type: Object,
        required: true
      }
    },

    data: () => ({
      modifierGroupIcons: {
        [modifierGroupTypes.PORTION]: 'chart-pie-alt',
        [modifierGroupTypes.COMBO]: 'layer-group'
      },
      modifierGroupTypes
    }),

    computed: {
      ...mapState('mainMenu', [
        'selectedMerchant',
        'menuPermissions',
        'featurePermissions',
        'activeMenuType',
        'draggableAttributes',
        'modifierGroupsOpenedStatus'
      ]),

      compositeId() {
        // Use a compositeId with the menu item as some merchants have shared modifier groups
        // and unless we include the menu item id, opening/closing one of these modifier groups
        // would open/close all of them
        return `${this.menuItem.id}-${this.modifierGroup.id}`;
      },

      isOpen() {
        return this.modifierGroupsOpenedStatus[this.compositeId];
      },

      fetchingModifier() {
        return Modifier.$state().fetchingModifierGroupId === this.modifierGroup.id
          || Modifier.$state().fetchingModifierId === this.parentModifier.id
          || ModifierGroup.$state().sortingParentModifierId === this.modifierGroup.id;
      },

      canSortResource() {
        return [
          this.$can('update', 'MenuItemModifierGroup'),
          !this.activeMenuType,
          this.menuPermissions.SORT_RESOURCE
        ].every(x => x);
      },

      menuItemModifiers() {
        return Modifier.query()
          .where('modifierGroupId', this.modifierGroup.id)
          .orderBy('sortOrder')
          .get();
      }
    },

    methods: {
      ...mapActions('mainMenu', ['setMenuResourceOpenState']),

      async checkModifierGroupState() {
        const wasNeverOpen = this.isOpen === undefined;

        if (wasNeverOpen) {
          await this.fetchModifiersByGroupId();
        }
      },

      async toggleModifierGroup() {
        await this.checkModifierGroupState();

        this.setMenuResourceOpenState({
          resourceType: menuResourceTypes.MODIFIER_GROUP,
          resourceId: this.compositeId,
          value: !this.isOpen
        });
      },

      async fetchParentModifier() {
        try {
          await Modifier.fetchModifier(this.parentModifier.id);
        }
        catch (error) {
          this.$_onRequestError({
            toastOptions: {
              message: 'There was an error fetching the modifier'
            },
            error
          });
        }
      },

      async fetchModifiersByGroupId() {
        try {
          await Modifier.fetchByModifierGroupId({ modifierGroupId: this.modifierGroup.id });
        }
        catch (error) {
          this.$_onRequestError({
            toastOptions: {
              message: 'Unable to fetch modifiers'
            },
            error
          });
        }
      },

      handleOpenModifierGroupModal() {
        if (this.menuItemModifierGroupItem) {
          if (this.menuItemModifierGroupItem.modifierGroup.type === modifierGroupTypes.EXISTING) {
            this.openAddModifierGroupsToItemModal();
          }
          else {
            this.openSharedModifierGroupModal();
          }
        }
        else {
          this.openModifierGroupModal();
        }
      },

      async openAddModifierGroupsToItemModal() {
        const menuItem = Item.query().with('menuItemModifierGroupItems').with('menuItemModifierGroupItems.modifierGroup').find(this.menuItem.id);
        this.$buefy.modal.open({
          parent: this,
          component: AddModifierGroupsToItemModal,
          hasModalCard: true,
          trapFocus: true,
          canCancel: false,
          props: {
            menuItem,
            menuItemModifierId: this.parentModifier.id
          }
        });
      },

      async openSharedModifierGroupModal() {
        await this.checkModifierGroupState();

        this.$buefy.modal.open({
          parent: this,
          component: SharedModifierGroupModal,
          hasModalCard: true,
          trapFocus: true,
          canCancel: false,
          customClass: 'auto-width',
          props: {
            modifierGroupId: this.modifierGroup.id,
            menuItemId: this.menuItem.id,
            menuItemModifierId: this.parentModifier.id, // used for nested modifier groups -- what will that look like in this refactor?
            readOnly: !this.$can('crud', 'MenuItemModifierGroupItem'),
            type: this.menuItemModifierGroupItem.modifierGroup.type
          },
          events: {
            'modifier-group-updated': this.emitModifierGroupUpdate
          }
        });
      },

      openModifierGroupModal() {
        // If parentModifierId is present, we're dealing with a nested modifier group
        this.$buefy.modal.open({
          parent: this,
          component: AddEditModifierGroupModal,
          hasModalCard: true,
          trapFocus: true,
          canCancel: false,
          customClass: 'auto-width',
          events: {
            'modifier-group-updated': this.emitModifierGroupUpdate
          },
          props: {
            modifierGroupId: this.modifierGroup.id,
            mode: 'update',
            type: this.modifierGroup.type,
            menuItemId: this.itemId,
            modifierId: this.parentModifier.id
          }
        });
      },

      emitModifierGroupUpdate() {
        // is this still needed? was previously tied to `reSearchMenu`
        this.$emit('modifier-group-updated');
      },

      openBulkManageModifierGroupItemAssociationModal(modifierGroupId) {
        const modifierGroup = ModifierGroup.query().with('menuItemModifierGroupItems').find(modifierGroupId);

        this.$buefy.modal.open({
          parent: this,
          component: BulkManageModifierGroupItemAssociationModal,
          hasModalCard: true,
          trapFocus: true,
          canCancel: false,
          props: {
            modifierGroup
          }
        });
      },

      openConfirmDeleteModifierGroupConfirmationModal() {
        const title = this.menuItemModifierGroupItem
          ? 'Remove Modifier Group from Menu Item'
          : 'Delete Modifier Group';

        const message = this.menuItemModifierGroupItem
          ? `<b>${this.modifierGroup.displayName}</b> and its associated menu resources will be removed from this item. Are you sure?`
          : `<b>${this.modifierGroup.displayName}</b> and its associated menu resources will be deleted from all locations. Are you sure?`;

        this.$buefy.modal.open({
          parent: this,
          component: AlertModal,
          hasModalCard: true,
          trapFocus: true,
          customClass: 'auto-width',
          props: {
            buttons: [
              { text: 'Cancel' },
              {
                text: 'Delete',
                primary: true,
                onClick: () => {
                  if (this.menuItemModifierGroupItem) {
                    this.deleteMenuItemModifierGroupItem();
                  }
                  else {
                    this.deleteModifierGroup({ modifierGroup: this.modifierGroup, modifierId: null });
                  }
                }
              }
            ],
            horizontal: true,
            showCloseButton: false,
            icon: 'trash-alt',
            title,
            message,
            type: 'is-danger'
          }
        });
      },


      async deleteMenuItemModifierGroupItem() {
        try {
          await MenuItemModifierGroupItem.deleteMenuItemModifierGroupItems([this.menuItemModifierGroupItem.id]);

          // If menuItemModifierId is present, we're dealing with a nested modifier group (fix for TOOL-4037)
          // We need to refresh the parent modifier to update its hasChildrenModifierGroups property,
          // and make it selectable as default if it no longer has any children modifier groups
          if (this.menuItemModifierGroupItem.menuItemModifierId) {
            await this.refreshModifierGroup(this.menuItemModifierGroupItem.menuItemModifierId);
          }

          this.$_onRequestSuccess({
            toastOptions: {
              message: `Successfully deleted <b>${this.menuItemModifierGroupItem.modifierGroup.displayName}</b>`
            }
          });
        }

        catch (error) {
          this.$_onRequestError({
            error,
            toastOptions: {
              message: `Unable to delete <b>${this.menuItemModifierGroupItem.modifierGroup.displayName}</b>`
            }
          });
        }
      },

      async deleteModifierGroup() {
        try {
          await ModifierGroup.deleteModifierGroup(this.modifierGroup.id);

          // We need to refresh the parent modifier to update its hasChildrenModifierGroups property,
          // and make it selectable as default if it no longer has any children modifier groups
          await this.refreshModifierGroup();

          this.$_onRequestSuccess({
            toastOptions: {
              message: `Successfully deleted <b>${this.modifierGroup.displayName}</b>`
            }
          });
        }

        catch (error) {
          this.$_onRequestError({
            error,
            toastOptions: {
              message: `Unable to delete <b>${this.modifierGroup.displayName}</b>`
            }
          });
        }
      },

      async refreshModifierGroup() {
        await this.fetchParentModifier();
        // Refetch MODs for group to display caret that allows adding nested MOD groups to Combo Items
        // This is because we need to get the updated canHaveNestedModifierGroups value (fix for TOOL-4037)
        await this.fetchModifiersByGroupId();
      }
    }
  };
</script>

<style lang='sass' scoped>
.row
    grid-template-columns: 1fr 200px 170px
</style>
