
<template>
  <steps-modal
    ref="onboardingModal"
    v-model="form"
    class="store-form"
    title="Onboarding Wizard"
    :steps="steps"
    confirm-close
    message-type="is-warning"
    has-white-background
    @valid-submit="handleSubmit"
    @close="$parent.close()"
  >
    <template #submit-button>
      <b-button
        type="is-primary"
        rounded
        icon-right="chevron-right"
        :loading="isSubmitting"
        native-type="submit"
        @click="manualValidation"
      >
        Finish
      </b-button>
    </template>
  </steps-modal>
</template>

<script>
  import webAppConfigurationInputs from '@/components/pages/forms/web-app-configuration-inputs.vue';
  import storeFormInputs from '@/components/pages/forms/store-form-inputs.vue';
  import storeStandardHoursInputs from '@/components/pages/stores/store-hours/store-standard-hours-inputs.vue';
  import Store from '@/store/classes/Store';
  import merchantMixin from '@/mixins/merchant';
  import MerchantOption from '@/store/classes/MerchantOption';
  import alertModal from '@/components/globals/alert-modal.vue';
  import filterObjectKeys from '@/helpers/filter-object-keys';
  import posTypes from '@/constants/posTypes';
  import storeStandardHoursMixin from '@/mixins/store-standard-hours';

  export default {
    name: 'OnboardingModal',

    mixins: [merchantMixin, storeStandardHoursMixin],

    data() {
      return {
        form: {},
        isSubmitting: false,
        whitelistedStoreKeys: [
          'storeId',
          'businessLocationId',
          'posLocationId',
          'description',
          'addressLine1',
          'addressLine2',
          'city',
          'region',
          'postalCode',
          'ianaTimezoneId',
          'latitude',
          'longitude',
          'mapsUrlOverride',
          'emailAddress',
          'phoneNumber',
          'allowAfterHoursOrdering',
          'totalSalesTaxRate',
          'posTypeId',
          'paymentLocationId',
          'posLocationId',
          'merchantLocationId',
          'leadPickupTime',
          'storeMappingAttributes',
          'sunIsOpen',
          'monIsOpen',
          'tueIsOpen',
          'wedIsOpen',
          'thuIsOpen',
          'friIsOpen',
          'satIsOpen'
        ]
      };
    },

    computed: {
      store() {
        return new Store({
          posTypeId: posTypes.Cardfree,
          sunIsOpen: true,
          monIsOpen: true,
          tueIsOpen: true,
          wedIsOpen: true,
          thuIsOpen: true,
          friIsOpen: true,
          satIsOpen: true
        });
      },

      steps() {
        return [
          {
            label: 'Customize App',
            component: webAppConfigurationInputs
          },
          {
            label: 'Add Location',
            component: storeFormInputs
          },
          {
            label: 'Set Hours',
            component: storeStandardHoursInputs,
            props: {
              invalidDays: this.ssh_invalidDays
            }
          }
        ];
      }
    },

    created() {
      this.onCreated();
    },

    methods: {
      async onCreated() {
        this.form = {
          ...this.$clone(this.store),
          ...this.$clone(this.$_selectedMerchant.merchantOption.skinOptions),
          hoursForm: { ...this.$_createHoursForm(this.$_storeHoursByDay, { initializeWithOpenAllDay: true }) }
        };
      },

      manualValidation() {
        // NOTE: Would be better to refactor this to use the manual-validation prop in validated-form
        // Causing async issues that way
        return this.$_validateHours(this.form.hoursForm);
      },

      trimLatitudeAndLongitude() {
        const { latitude, longitude } = this.form;
        const latitudeArray = latitude.toString().split('.');
        const longitudeArray = longitude.toString().split('.');
        const shouldTrimLatitude = latitudeArray.length > 1 && latitudeArray[1].length > 6;
        const shouldTrimLongitude = longitudeArray.length > 1 && longitudeArray[1].length > 6;

        if (shouldTrimLatitude) {
          const trimmedLatDecimals = latitudeArray[1].substring(0, 6);
          const updatedLat = `${latitudeArray[0]}.${trimmedLatDecimals}`;
          this.form.latitude = updatedLat;
        }

        if (shouldTrimLongitude) {
          const trimmedLongDecimals = longitudeArray[1].substring(0, 6);
          const updatedLong = `${longitudeArray[0]}.${trimmedLongDecimals}`;
          this.form.longitude = updatedLong;
        }
      },

      async handleSubmit() {
        try {
          this.isSubmitting = true;
          this.trimLatitudeAndLongitude();

          const merchantOptionValues = [...Object.keys(this.$_selectedMerchant.merchantOption.skinOptions)];
          const skinOptions = JSON.stringify(filterObjectKeys(this.form, merchantOptionValues));
          const storeData = filterObjectKeys(this.form, this.whitelistedStoreKeys);
          const { added: addedHours } = this.$_formatHoursPayload(this.form.hoursForm);

          const [storeId] = await Promise.all([
            Store.addStore(storeData, true),
            MerchantOption.updateMerchantOption({
              id: this.$_selectedMerchant.merchantOption.id,
              skinOptions
            })
          ]);

          if (addedHours.length) {
            await Promise.all(
              addedHours.map(week => Store.addStoreMappingHour({ storeId, storeMappingHour: week }))
            );
          }

          if (this.form.logoImageFile) {
            await MerchantOption.updateMerchantLogo({
              id: this.$_selectedMerchant.merchantOption.id,
              logoImageFile: this.form.logoImageFile
            });
            this.form.logoImageFile = null;
          }

          this.$_onRequestSuccess({
            toastOptions: { message: 'Onboarding Successful' },
            options: { closeParent: true }
          });

          this.$buefy.modal.open({
            parent: this,
            component: alertModal,
            hasModalCard: true,
            trapFocus: true,
            canCancel: false,
            customClass: 'auto-width',
            props: {
              buttons: [{ text: 'Go to your dashboard', primary: true }],
              title: 'Onboarding Completed',
              icon: 'check-circle',
              iconPack: 'far',
              message: "You've finished the basic setup. Now you're ready to jump in!",
              type: 'is-success',
              showCloseButton: false
            }
          });
        }
        catch (error) {
          this.$_onRequestError({
            toastOptions: { message: 'An error occurred in your onboarding' },
            error
          });
        }
        finally {
          this.isSubmitting = false;
        }
      }
    }
  };
</script>
