<template>
  <ul class="nested-table-section">
    <!-- No modifier groups -->
    <li v-if="!modifierGroups.length && (!menuPermissions.ADD_RESOURCE || activeMenuType)">
      <div class="row sub-row">
        <span class="has-text-grey pad-y-md">No Modifier Groups</span>
        <span />
        <span />
      </div>
    </li>

    <!-- Draggable list of modifier groups  -->
    <draggable
      tag="ul"
      :group="`item-${menuItem.id}-modifier-groups`"
      v-bind="draggableAttributes"
      :value="modifierGroups"
      :force-fallback="true"
      @change="handleModifierGroupSort"
    >
      <template v-if="modifierGroups.length" #header>
        <li class="nested-table-section-title">{{ featurePermissions.NESTED_MODIFIERS ? 'Groups' : 'Modifier Groups' }}</li>
      </template>
      <nested-modifier-group-row
        v-for="modifierGroup in modifierGroups"
        :key="`modifier-group-${modifierGroup[modifierGroupIdKey]}-item-${menuItem.id}`"
        :modifier-group="featurePermissions.SHARED_MODIFIER_GROUPS ? modifierGroup.modifierGroup : modifierGroup"
        :menu-item-modifier-group-item="featurePermissions.SHARED_MODIFIER_GROUPS ? modifierGroup : null"
        :menu-item="menuItem"
        :parent-modifier="parentModifier"
      />
    </draggable>

    <!-- Add modifier group button  -->
    <li
      v-if="
        !activeMenuType
          && $can('create', 'MenuItemModifierGroup')
          && menuPermissions.ADD_RESOURCE
          && (featurePermissions.SHARED_MODIFIER_GROUPS || featurePermissions.NESTED_MODIFIERS)
      "
      class="row sub-row"
    >
      <span>
        <dropdown-menu
          position="bottom-start"
        >
          <template #trigger>
            <div
              v-tippy="{
                content: 'A default group cannot have nested modifiers',
                placement: 'right',
                onShow: () => isModifierDefault
              }"
              class="is-flex align-center"
            >
              <b-button
                type="is-primary"
                class="is-transparent"
                inverted
                icon-left="plus"
              >
                Group
              </b-button>
            </div>
          </template>

          <b-dropdown-item
            v-for="{ display, value } in modifierGroupTypeOptions"
            :key="value"
            class="is-flex justify-between pad-r-sm"
            aria-role="listitem"
            :disabled="modifierGroupTypeOptionDisabled(value)"
            @click="handleOpenModifierGroupModal({ type: Object.values(modifierGroupTypes).includes(value) ? value : '' })"
          >
            {{ display }}
            <b-icon icon="arrow-right" />
          </b-dropdown-item>
        </dropdown-menu>
      </span>
      <span /><span />
    </li>
  </ul>
</template>

<script>
  import draggable from 'vuedraggable';
  import { mapActions, mapState } from 'vuex';

  import menuResourceTypes from '@/constants/menuResourceTypes';
  import { modifierGroupTypes } from '@/constants/modifierGroups';

  import Modifier from '@/store/classes/Modifier';
  import ModifierGroup from '@/store/classes/ModifierGroup';
  import MenuItemModifierGroupItem from '@/store/classes/MenuItemModifierGroupItem';

  import AddEditModifierGroupModal from '../main-menu/add-edit-modifier-group-modal.vue';
  import AddModifierGroupsToItemModal from '../main-menu/modifier-groups/add-modifier-groups-to-item-modal.vue';
  import SharedModifierGroupModal from '../main-menu/modifier-groups/shared-modifier-group-modal.vue';

  import NestedModifierGroupRow from './nested-modifier-group-row.vue';


  export default {
    name: 'NestedModifierGroupsContainer',

    components: { draggable, NestedModifierGroupRow },

    props: {
      menuItem: {
        type: Object,
        required: true
      },

      modifierGroups: {
        type: Array,
        required: true
      },

      parentModifier: {
        type: Object,
        required: true
      }
    },

    data: () => ({
      modifierGroupTypes
    }),

    computed: {
      ...mapState('mainMenu', ['selectedMerchant', 'featurePermissions', 'menuPermissions', 'activeMenuType', 'draggableAttributes']),

      modifierGroupIdKey() {
        return this.featurePermissions.SHARED_MODIFIER_GROUPS
          ? 'modifierGroupId'
          : 'id';
      },

      isModifierDefault() {
        return this.parentModifier.isDefault;
      },

      modifierGroupTypeOptions() {
        const options = [
          { display: 'Add New Modifier Group', value: 'modifier group' }
        ];

        if (this.selectedMerchant.allowModifierGroupAssociation) {
          options.push({ display: 'Manage Modifier Group Associations', value: 'existing' });
        }

        if (this.featurePermissions.NESTED_MODIFIERS) {
          options.push({ display: 'Add Portion Group', value: 'portion' });
          options.push({ display: 'Add Combo Group', value: 'combo' });
        }

        return options;
      }
    },

    methods: {
      ...mapActions('mainMenu', ['setMenuResourceOpenState']),

      handleModifierGroupSort($draggableEvent) {
        if (this.featurePermissions.SHARED_MODIFIER_GROUPS) {
          this.handleMenuItemModifierGroupItemsSort($draggableEvent);
        }
        else {
          this.handleMenuItemModifierGroupsSort($draggableEvent);
        }
      },

      async handleMenuItemModifierGroupItemsSort({ moved }) {
        try {
          await MenuItemModifierGroupItem.updateSortOrders({
            menuItemModifierGroupItems: this.modifierGroups,
            oldIndex: moved.oldIndex,
            newIndex: moved.newIndex
          });

          this.$_onRequestSuccess({
            toastOptions: {
              message: `<b>${moved.element.modifierGroup.displayName}</b> moved from sort order <b>${moved.oldIndex + 1}</b> to <b>${moved.newIndex + 1}</b>`
            }
          });
        }

        catch (error) {
          this.$_onRequestError({
            toastOptions: { message: 'Unable to update sort orders' },
            error
          });
        }
      },

      async handleMenuItemModifierGroupsSort({ moved }) {
        try {
          await ModifierGroup.updateSortOrders({
            modifierGroups: this.modifierGroups,
            oldIndex: moved.oldIndex,
            newIndex: moved.newIndex
          });

          this.$_onRequestSuccess({
            toastOptions: {
              message: `<b>${moved.element.displayName}</b> moved from sort order <b>${moved.oldIndex + 1}</b> to <b>${moved.newIndex + 1}</b>`
            }
          });
        }

        catch (error) {
          this.$_onRequestError({
            toastOptions: { message: 'Unable to update sort orders' },
            error
          });
        }
      },

      modifierGroupTypeOptionDisabled(type) {
        if (type === modifierGroupTypes.PORTION) {
          return this.featurePermissions.SHARED_MODIFIER_GROUPS
            ? !!this.modifierGroups.find(m => m.modifierGroup.type === modifierGroupTypes.PORTION)
            : !!this.modifierGroups.find(m => m.type === modifierGroupTypes.PORTION);
        }
      },

      handleOpenModifierGroupModal({ type }) {
        if (this.featurePermissions.SHARED_MODIFIER_GROUPS) {
          if (type === modifierGroupTypes.EXISTING) {
            this.openAddModifierGroupsToItemModal();
          }
          else {
            this.openSharedModifierGroupModal({ type });
          }
        }
        else {
          this.openModifierGroupModal({ type });
        }
      },

      openAddModifierGroupsToItemModal() {
        this.$buefy.modal.open({
          parent: this,
          component: AddModifierGroupsToItemModal,
          hasModalCard: true,
          trapFocus: true,
          canCancel: false,
          props: {
            menuItem: this.menuItem,
            menuItemModifierId: this.parentModifier.id
          }
        });
      },

      async openSharedModifierGroupModal({ type }) {
        this.$buefy.modal.open({
          parent: this,
          component: SharedModifierGroupModal,
          hasModalCard: true,
          trapFocus: true,
          canCancel: false,
          customClass: 'auto-width',
          events: {
            'main-menu:add-modifier-group': this.handleAddModifierGroup
          },
          props: {
            menuItemId: this.menuItem.id,
            menuItemModifierId: this.parentModifier.id,
            readOnly: !this.$can('crud', 'MenuItemModifierGroupItem'),
            type
          }
        });
      },

      openModifierGroupModal({ type } = {}) {
        this.$buefy.modal.open({
          parent: this,
          component: AddEditModifierGroupModal,
          hasModalCard: true,
          trapFocus: true,
          canCancel: false,
          customClass: 'auto-width',
          events: {
            'main-menu:add-modifier-group': this.handleAddModifierGroup
          },
          props: {
            mode: 'create',
            type,
            menuItemId: this.menuItem.id,
            modifierId: this.parentModifier.id
          }
        });
      },

      async handleAddModifierGroup(modifierGroupId) {
        // Refetch MODs for group to display caret that allows adding nested MOD groups to Combo Items
        // This is because we need to get the updated canHaveNestedModifierGroups value (fix for TOOL-4037)
        // But what this is currently missing is that for a nested modifier group, it is not properly updating the
        // checkbox/radio button for being the default -- the issue is that the `ModifierGroup` component doesn't
        // have a dynamic copy of the modifier -- it's just being passed as a prop.
        await this.fetchModifiersByGroupId();

        this.toggleModifierGroup(modifierGroupId);
      },

      toggleModifierGroup(id) {
        this.setMenuResourceOpenState({
          resourceType: menuResourceTypes.MODIFIER_GROUP,
          resourceId: `${this.menuItem.id}-${id}`,
          value: true
        });
      },

      async fetchModifier() {
        try {
          await Modifier.fetchModifier(this.parentModifier.id);
        }
        catch (error) {
          this.$_onRequestError({
            toastOptions: {
              message: 'There was an error fetching the modifier'
            },
            error
          });
        }
      },

      async fetchModifiersByGroupId() {
        try {
          await Modifier.fetchByModifierGroupId({ modifierGroupId: this.parentModifier.modifierGroupId });
        }
        catch (error) {
          this.$_onRequestError({
            toastOptions: {
              message: 'Unable to fetch modifiers'
            },
            error
          });
        }
      },

      async fetchModifierGroup() {
        try {
          await ModifierGroup.fetchModifierGroup({
            modifierGroupId: this.parentModifier.modifierGroupId,
            includeMenuItemMetadata: true
          });
        }
        catch (error) {
          this.$_onRequestError({
            toastOptions: {
              message: 'There was an error fetching the modifier group'
            },
            error
          });
        }
      }
    }
  };
</script>

<style lang='sass' scoped>
  .row
      grid-template-columns: 1fr 200px 170px
</style>
