import { Model } from '@vuex-orm/core';

import http from '@/services/http';


export default class MenuCategoryUpsell extends Model {
  static entity = 'menuCategoryUpsells'

  // FIELDS //////////////////////

  static primaryKey = 'menuCategoryUpsellId'

  static fields() {
    return {
      displayName: this.attr(''),
      menuCategoryId: this.attr(''),
      menuCategoryUpsellId: this.attr(''),
      menuItemId: this.attr(''),
      priority: this.attr('')
    };
  }

  static state() {
    return {
      fetching: false,
      submitting: false
    };
  }

  static $state() {
    return this.store().state.entities.menuCategoryUpsells;
  }

  static async fetchUpsellItems(categoryId) {
    try {
      this.commit((state) => { // TEST ?
        state.fetching = true;
      });
      const response = await http.get(`menu_categories/${categoryId}/upsells`);

      this.insert({ data: response.data.menuCategoryUpsells });
    }
    catch (error) {
      throw error;
    }
    finally {
      this.commit((state) => { // TEST ?
        state.fetching = false;
      });
    }
  }

  static async deleteUpsellSellItems({ menuCategoryUpsellIds, categoryId }) {
    try {
      this.commit((state) => {
        state.submitting = true;
      });
      await http.delete(`menu_categories/${categoryId}/upsells?menu_category_upsell_ids=[${menuCategoryUpsellIds.join(', ')}]`);
      menuCategoryUpsellIds.forEach(id => this.delete(id));
    }
    catch (error) {
      throw error;
    }
    finally {
      this.commit((state) => {
        state.submitting = false;
      });
    }
  }

  static async addUpsellItems({ categoryId, menuCategoryUpsells }) {
    try {
      this.commit((state) => {
        state.submitting = true;
      });

      const response = await http.post(`menu_categories/${categoryId}/upsells`, { menuCategoryUpsells });
      this.insert({ data: response.data.menuCategoryUpsells });

      return response.data.menuCategoryUpsells;
    }
    catch (error) {
      throw error;
    }
    finally {
      this.commit((state) => {
        state.submitting = false;
      });
    }
  }

  static async updateUpsellItemsPriority({ items, categoryId }) {
    try {
      this.commit((state) => {
        state.submitting = true;
      });
      const response = await http.put(
        `menu_categories/${categoryId}/upsells/priority`,
        {
          menuCategoryUpsells: items.map(item => ({ id: item.menuCategoryUpsellId, priority: item.priority }))
        }
      );
      this.update({ data: response.data.menuCategoryUpsells });
    }
    catch (error) {
      throw error;
    }
    finally {
      this.commit((state) => {
        state.submitting = false;
      });
    }
  }
}
