<template>
  <div>
    <registered-guest-gift-cards-table
      v-if="showGiftCadTable"
      :registered-guest-id="registeredGuestId"
    />

    <registered-guest-credit-cards-table
      v-if="showSavedPaymentTable"
      :registered-guest-id="registeredGuestId"
    />
  </div>
</template>

<script>
  import merchantMixin from '@/mixins/merchant';

  import RegisteredGuestGiftCardsTable from './registered-guest-gift-cards-table.vue';
  import RegisteredGuestCreditCardsTable from './registered-guest-credit-cards-table.vue';

  export default {
    name: 'RegisteredGuestPaymentMethods',

    components: {
      RegisteredGuestGiftCardsTable,
      RegisteredGuestCreditCardsTable
    },

    mixins: [merchantMixin],

    props: {
      registeredGuestId: {
        type: Number,
        required: true
      }
    },

    computed: {
      showSavedPaymentTable() {
        return !this.$_selectedMerchant?.hasDisableSavedPaymentManagement;
      },

      showGiftCadTable() {
        return this.$_selectedMerchant?.merchantGiftCardConfigurations.length;
      }
    }
  };
</script>
