<template>
  <div class="is-flex gap align-center">
    <b-numberinput
      :value="value"
      controls-position="compact"
      class="mar-b-none"
      :class="[{ 'value-hidden': value === trueValue }]"
      :disabled="disabled || value === trueValue"
      :editable="editable"
      :min="min"
      :max="max"
      @input="$emit('input', $event)"
    />
    <b-switch
      :value="value"
      class="align-self-center no-wrap-text"
      :disabled="disabled"
      :true-value="trueValue"
      :false-value="falseValue"
      @input="$emit('input', $event)"
    >
      {{ switchLabel }}
    </b-switch>
  </div>
</template>



<script>
  export default {
    name: 'NumberinputSwitch',

    props: {
      editable: {
        type: Boolean,
        default: false
      },
      falseValue: {
        type: Number,
        default: 0
      },
      max: {
        type: Number,
        default: undefined
      },
      min: {
        type: Number,
        default: 0
      },
      disabled: {
        type: Boolean,
        default: false
      },
      switchLabel: {
        type: String,
        default: null
      },
      trueValue: {
        type: Number,
        default: 1
      },
      value: {
        type: Number,
        default: null
      }
    }
  };
</script>
