<template>
  <clipped-image-page img-src="/images/banners/banner_1.jpg">
    <div :class="['home-page-card card', { 'max-content-override': showSundryOnboarding }]">
      <div class="card-content">

        <template v-if="isFetching || !currentUser">
          <div class="mar-b-lg">
            <b-skeleton
              animated
              height="2.5rem"
            />
            <b-skeleton
              animated
              height="1.25rem"
              width="75%"
            />
          </div>
          <div class="dist-y-xs">
            <b-skeleton
              v-for="i in 4"
              :key="i"
              animated
              height="2rem"
              class="is-marginless"
            />
          </div>
        </template>

        <header v-else-if="$can('update_quickstart_checklist', 'Feature') && showSundryQuickstartChecklist">
          <h3 class="title is-3">Your Quick Start Guide</h3>
          <ul class="dist-y-sm">
            <li v-for="item in filteredQuickstartChecklist" :key="item.id" class="is-relative">
              <component
                :is="item.internalLinkUrl ? 'router-link' : 'a'"
                :to="item.internalLinkUrl && { path: item.internalLinkUrl }"
                :href="item.externalLinkUrl"
                class="quick-start-link is-flex align-center gap has-radius-large has-border has-border-grey-lighter pad-x pad-y-sm"
              >
                <!-- ICON -->
                <div class="quick-start-icon is-flex align-center justify-center has-radius-full has-background-primary-light">
                  <b-icon v-if="item.faIcon" :icon="item.faIcon" type="is-primary" />
                  <img v-else src="/images/cardfree-icon-logo.png">
                </div>

                <!-- TEXT -->
                <div>
                  <h3 class="title is-5">{{ item.header }}</h3>
                  <p class="subtitle is-6 has-text-grey">{{ item.subHeader }}</p>
                </div>
              </component>
              <!-- CLOSE BUTTON -->
              <b-button
                size="is-small"
                class="quick-start-clear-button mar-l-auto is-transparent"
                type="is-white"
                :loading="Number(updatingQuickstartChecklistStepId) === Number(item.id)"
                @click.stop="updateQuickstartChecklist(item.id)"
              >
                <b-icon icon="times" size="is-medium" pack="fal" />
              </b-button>
            </li>
          </ul>
        </header>

        <div v-else-if="$_selectedMerchantId && showSundryOnboarding" class="is-flex align-center">
          <img
            class="image is-64x64 mar-r"
            src="/images/onboarding-icon.jpg"
            alt="onboarding-image"
            title="onboarding-image"
          >
          <div class="dist-y-xs" :style="{ marginRight: '5rem !important' }">
            <h3 class="title is-4 is-max-content-width mar-none">Your Quick Start Guide</h3>
            <p class="subtitle is-6 is-max-content-width mar-none"> Get started fast by completing our Wizard</p>
          </div>
          <b-button
            rounded
            size="is-medium"
            type="is-primary"
            @click="openOnboardingModal"
          >
            Get Started
          </b-button>
        </div>

        <div v-else class="animated tdExpandIn">
          <header>
            <h3 class="title is-3">Welcome to {{ $_selectedMerchantId ? `${$_selectedMerchant.name} App` : 'Merchant' }} Portal</h3>
            <p class="subtitle is-5">On this portal you <span :class="$can('manage', 'all') && 'secret'" @click="showMeme = !showMeme">can</span>:</p>
            <img v-if="$can('manage', 'all') && showMeme" src="/images/do-all-the-things.jpg" class="image" alt="all the things">
          </header>

          <ul class="mar-t-lg">
            <!-- Global Context Home Page Menu -->
            <template v-if="(isCardfreeAdmin || isMultiMerchantUser) && !$_selectedMerchantId">
              <li class="home-page-option">
                <router-link v-tabbable class="home-page-option-link button is-white" :to="{ name: 'merchantList' }">
                  <div class="option-text">
                    <b-icon icon="store" />
                    <span>
                      {{ ['create', 'update'].some(action => $can(action, 'Merchant')) ? 'Manage' : 'View' }} Merchants
                    </span>
                  </div>
                  <b-icon
                    class="mar-l-xs"
                    type="is-grey"
                    pack="far"
                    icon="angle-right"
                    size="is-small"
                  />
                </router-link>
              </li>

              <li v-if="$_featurePermissions.USERS" class="home-page-option">
                <router-link v-tabbable class="home-page-option-link button is-white" :to="{ name: 'userList' }">
                  <div class="option-text">
                    <b-icon icon="user-friends" />
                    <span>
                      Manage Users
                    </span>
                  </div>
                  <b-icon
                    class="mar-l-xs"
                    type="is-grey"
                    pack="far"
                    icon="angle-right"
                    size="is-small"
                  />
                </router-link>
              </li>
            </template>

            <!-- Merchant Context Home Page Menu -->
            <template v-else-if="$_selectedMerchantId">
              <li v-if="$_featurePermissions.USERS" class="home-page-option">
                <router-link v-tabbable class="home-page-option-link button is-white" :to="{ name: 'userList' }">
                  <div class="option-text">
                    <b-icon icon="user-friends" />
                    <span>
                      Manage User Accounts
                    </span>
                  </div>
                  <b-icon
                    class="mar-l-xs"
                    type="is-grey"
                    pack="far"
                    icon="angle-right"
                    size="is-small"
                  />
                </router-link>
              </li>
              <li v-if="$_featurePermissions.MERCHANT_CONFIGURATION" class="home-page-option">
                <router-link v-tabbable class="home-page-option-link button is-white" :to="{ name: 'merchantConfiguration' }">
                  <div class="option-text">
                    <b-icon icon="wrench" />
                    <span>
                      {{ ['create', 'update'].some(action => $can(action, 'Merchant')) ? 'Manage' : 'View' }} Your Configurations
                    </span>
                  </div>
                  <b-icon
                    class="mar-l-xs"
                    type="is-grey"
                    pack="far"
                    icon="angle-right"
                    size="is-small"
                  />
                </router-link>
              </li>
              <li v-if="$_featurePermissions.LOCATION_MANAGEMENT" class="home-page-option">
                <router-link v-tabbable class="home-page-option-link button is-white" :to="{ name: 'storeList' }">
                  <div class="option-text">
                    <b-icon icon="map-marker-alt" />
                    <span>
                      {{ ['create', 'update'].some(action => $can(action, 'StoreMapping')) ? 'Manage' : 'View' }} Locations
                    </span>
                  </div>
                  <b-icon
                    class="mar-l-xs"
                    type="is-grey"
                    pack="far"
                    icon="angle-right"
                    size="is-small"
                  />
                </router-link>
              </li>
              <li v-if="$_featurePermissions.MASTER_MENU" class="home-page-option">
                <router-link v-tabbable class="home-page-option-link button is-white" :to="{ name: 'menuManagement' }">
                  <div class="option-text">
                    <b-icon icon="utensils" />
                    <span>
                      Create & Update your Menu
                    </span>
                  </div>
                  <b-icon
                    class="mar-l-xs"
                    type="is-grey"
                    pack="far"
                    icon="angle-right"
                    size="is-small"
                  />
                </router-link>
              </li>
              <li v-if="$_featurePermissions.MERCHANT_CONFIGURATION" class="home-page-option">
                <router-link v-tabbable class="home-page-option-link button is-white" :to="{ name: 'webAppConfiguration' }">
                  <div class="option-text">
                    <b-icon icon="browser" />
                    <span>
                      Customize your App Look & Feel
                    </span>
                  </div>
                  <b-icon
                    class="mar-l-xs"
                    type="is-grey"
                    pack="far"
                    icon="angle-right"
                    size="is-small"
                  />
                </router-link>
              </li>
              <li v-if="$_featurePermissions.ORDERS" class="home-page-option">
                <router-link v-tabbable class="home-page-option-link button is-white" :to="{ name: 'orderManagement' }">
                  <div class="option-text">
                    <b-icon icon="concierge-bell" />
                    <span>
                      Manage Orders
                    </span>
                  </div>
                  <b-icon
                    class="mar-l-xs"
                    type="is-grey"
                    pack="far"
                    icon="angle-right"
                    size="is-small"
                  />
                </router-link>
              </li>
              <li v-if="$_featurePermissions.REPORTING" class="home-page-option">
                <router-link v-tabbable class="home-page-option-link button is-white" :to="{ name: 'reportingConfiguration' }">
                  <div class="option-text">
                    <b-icon icon="analytics" />
                    <span>
                      View Analytics and Reporting
                    </span>
                  </div>
                  <b-icon
                    class="mar-l-xs"
                    type="is-grey"
                    pack="far"
                    icon="angle-right"
                    size="is-small"
                  />
                </router-link>
              </li>
              <li v-if="$_featurePermissions.CAMPAIGNS" class="home-page-option">
                <router-link v-tabbable class="home-page-option-link button is-white" :to="{ name: 'campaignManagement' }">
                  <div class="option-text">
                    <b-icon icon="paper-plane" />
                    <span>
                      Manage Campaigns
                    </span>
                  </div>
                  <b-icon
                    class="mar-l-xs"
                    type="is-grey"
                    pack="far"
                    icon="angle-right"
                    size="is-small"
                  />
                </router-link>
              </li>
            </template>
          </ul>
        </div>
      </div>
    </div>
  </clipped-image-page>
</template>

<script>
  import { mapGetters } from 'vuex';
  import merchantMixin from '@/mixins/merchant';
  import Merchant from '@/store/classes/Merchant';
  import featurePermissionsMixin from '@/mixins/featurePermissions';
  import onboardingModal from './onboarding-modal.vue';
  import StoreAttribute from '@/store/classes/StoreAttribute';

  export default {
    name: 'Home',

    mixins: [merchantMixin, featurePermissionsMixin],

    metaInfo() {
      return {
        title: 'Home'
      };
    },

    data() {
      return {
        showMeme: false
      };
    },

    computed: {
      ...mapGetters('session', ['currentUser', 'isCardfreeAdmin', 'isMultiMerchantUser']),

      isFetching() {
        return Merchant.$state().fetchingSelectedMerchant || StoreAttribute.$state().fetching;
      },

      updatingQuickstartChecklistStepId() {
        return Merchant.$state().updatingQuickstartChecklistStepId;
      },

      filteredQuickstartChecklist() {
        if (!this.$_selectedMerchant) return false;

        const { quickstartChecklist } = this.$_selectedMerchant.features;
        return Object.entries(quickstartChecklist)
          .map(([key, value]) => ({ id: Number(key), ...value }))
          .filter(qc => !qc.isDismissed)
          .sort((a, b) => a.sortOrder - b.sortOrder);
      },

      showSundryQuickstartChecklist() {
        if (!this.$_selectedMerchant) return false;
        const { isSundry, hasNoStores, isTemplate } = this.$_selectedMerchant;
        return !!this.filteredQuickstartChecklist?.length && isSundry && !hasNoStores && !isTemplate;
      },

      showSundryOnboarding() {
        return this.$_selectedMerchant?.isSundry && !this.$_selectedMerchant.isTemplate && this.$_selectedMerchant.hasNoStores;
      }
    },

    methods: {
      onMerchantLoad() {
        if (this.showSundryOnboarding) {
          this.fetchStoreAttributes();
        }
      },

      async updateQuickstartChecklist(stepId) {
        try {
          await Merchant.updateQuickstartChecklist({ stepId, featureId: this.$_selectedMerchant.features.id });
        }
        catch (error) {
          this.$_onRequestError({
            error,
            toastOptions: {
              message: 'There was an error clearing the checklist step'
            }
          });
        }
      },

      async fetchStoreAttributes() {
        try {
          await StoreAttribute.fetchStoreAttributes();
        }
        catch (error) {
          this.$_onRequestError({
            toastOptions: {
              message: 'There was an error fetching the store attributes'
            },
            error
          });
        }
      },

      openOnboardingModal() {
        this.$buefy.modal.open({
          parent: this,
          component: onboardingModal,
          hasModalCard: true,
          trapFocus: true,
          canCancel: false,
          customClass: 'auto-width'
        });
      }
    }
  };
</script>

<style lang="sass" scoped>
  .home-page-card
    max-width: 475px
    min-width: 400px
    margin: 1rem
    border-radius: $radius-large

    &.max-content-override
      max-width: max-content !important

  .home-page-option
    font-weight: 500
    margin: 0.2rem -8px

  .home-page-option-link
    display: flex
    align-items: center
    justify-content: space-between

    .icon
      transition: transform 200ms
      color: $grey-light

    &:hover,
    &:focus
      .option-text, .icon
        color: $primary !important

      .icon
        transform: scale(1.2)

  .quick-start-link
    padding-right: 4rem !important
    &:hover
      .title
        color: $primary !important

  .quick-start-icon
    width: 2.5rem
    height: 2.5rem
    padding: 0.6rem

  .quick-start-clear-button
    position: absolute
    right: 1rem
    top: 50%
    transform: translateY(-50%)

  .secret
    display: inline-block
    transition: 200ms
    transition-delay: 1000ms, 0ms

    &:hover
      cursor: pointer
      transform: scale(1.1) rotate(5deg)
</style>
