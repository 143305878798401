/* eslint-disable import/no-cycle */
import { Model } from '@vuex-orm/core';
import http from '@/services/http';


export default class MerchantDefaultNotificationPreference extends Model {
  static entity = 'merchantDefaultNotificationPreferences'


  // FIELDS //////////////////////
  static fields() {
    return {
      id: this.attr(''),
      merchantId: this.attr(''),
      email: this.attr(false),
      marketingOptIn: this.attr(false),
      push: this.attr(false),
      sms: this.attr(false)
    };
  }


  // STATE //////////////////////
  static state() {
    return {
      fetching: false,
      submitting: false
    };
  }

  static $state() {
    return this.store().state.entities.merchantDefaultNotificationPreferences;
  }


  // ACTIONS //////////////////////
  static async createMerchantDefaultNotificationPreference({ merchantId, ...merchantDefaultNotificationPreference }) {
    try {
      this.commit((state) => {
        state.submitting = true;
      });

      const { data } = await http.post(`merchants/${merchantId}/merchant_default_notification_preferences`, { merchantDefaultNotificationPreference });

      this.insert({
        data: data.merchantDefaultNotificationPreference
      });
    }
    catch (error) {
      throw error;
    }
    finally {
      this.commit((state) => {
        state.submitting = false;
      });
    }
  }

  static async fetchMerchantDefaultNotificationPreferences(merchantId) {
    if (this.exists()) return;

    try {
      this.commit((state) => {
        state.fetching = true;
      });

      const { data } = await http.get(`merchants/${merchantId}/merchant_default_notification_preferences`);
      this.create({
        data: data.merchantDefaultNotificationPreferences
      });
    }
    catch (error) {
      throw error;
    }
    finally {
      this.commit((state) => {
        state.fetching = false;
      });
    }
  }

  static async updateMerchantDefaultNotificationPreference({ id, ...merchantDefaultNotificationPreference }) {
    try {
      this.commit((state) => {
        state.submitting = true;
      });
      const { data } = await http.put(`merchant_default_notification_preferences/${id}`, { merchantDefaultNotificationPreference });
      this.update({
        data: data.merchantDefaultNotificationPreference
      });
    }
    catch (error) {
      throw error;
    }
    finally {
      this.commit((state) => {
        state.submitting = false;
      });
    }
  }
}
