/* eslint-disable import/no-cycle */
import { Model } from '@vuex-orm/core';
import LocationServiceType from '@/store/classes/LocationServiceType';
import { parseJson } from '@/helpers/misc';

// classes
import http from '@/services/http';


export default class MerchantLocationService extends Model {
  static entity = 'merchantLocationServices'



  // FIELDS //////////////////////
  static fields() {
    return {
      id: this.attr(null),
      merchantId: this.attr(''),
      apiConfiguration: this.attr(null, parseJson),
      locationServiceTypeId: this.attr('')
    };
  }

  // STATE //////////////////////
  static state() {
    return {
      fetching: false,
      submitting: false
    };
  }

  static $state() {
    return this.store().state.entities.merchantLocationServices;
  }

  get name() {
    return LocationServiceType.find(this.locationServiceTypeId)?.name;
  }

  get toast() {
    return this.locationServiceTypeId === LocationServiceType.typeIds.TOAST;
  }

  get square() {
    return this.locationServiceTypeId === LocationServiceType.typeIds.SQUARE;
  }



  // ACTIONS //////////////////////
  static async fetchMerchantLocationServices(merchantId, options = {}) {
    try {
      this.commit((state) => {
        state.fetching = true;
      });

      const alreadyFetched = this.query().where('merchantId', merchantId).exists();

      if (!alreadyFetched || options.forceFetch) {
        const { data: { merchantLocationServices } } = await http.get(`merchants/${merchantId}/merchant_location_services`);
        this.create({
          data: merchantLocationServices
        });
      }
    }
    catch (error) {
      throw error;
    }
    finally {
      this.commit((state) => {
        state.fetching = false;
      });
    }
  }

  static async addMerchantLocationService(merchantLocationService) {
    try {
      this.commit((state) => {
        state.submitting = true;
      });

      const { data: { merchantLocationService: mls } } = await http.post(
        `merchants/${merchantLocationService.merchantId}/merchant_location_services`,
        { merchantLocationService }
      );

      this.insert({
        data: mls
      });
    }
    catch (error) {
      throw error;
    }
    finally {
      this.commit((state) => {
        state.submitting = false;
      });
    }
  }

  static async updateMerchantLocationService(merchantLocationService) {
    try {
      this.commit((state) => {
        state.submitting = true;
      });
      const { data: { merchantLocationService: mls } } = await http.put(`merchant_location_services/${merchantLocationService.id}`, {
        merchantLocationService
      });

      this.update({
        data: mls
      });
    }
    catch (error) {
      throw error;
    }
    finally {
      this.commit((state) => {
        state.submitting = false;
      });
    }
  }

  static async removeMerchantLocationService(id) {
    try {
      this.commit((state) => {
        state.submitting = true;
      });

      await http.delete(`merchant_location_services/${id}`);

      this.delete(id);
    }
    catch (error) {
      throw error;
    }
    finally {
      this.commit((state) => {
        state.submitting = false;
      });
    }
  }
}
