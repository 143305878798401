const campaignDeliveryTypes = {
  SCHEDULED: 'scheduled',
  EVENT: 'event',
  RECURRING: 'recurring',
  LOYALTY: 'loyalty',
  IMMEDIATE: 'immediate'
};

const campaignStates = {
  DRAFT: 'draft',
  SCHEDULED: 'scheduled',
  LIVE: 'live',
  COMPLETE: 'complete',
  CANCELLED: 'cancelled'
};

const campaignSegments = {
  ALL_SUBSCRIBERS: 'All Subscribers',
  JOIN_DATE: 'Join Date',
  HAS_ORDERED: 'Has Ordered',
  JOIN_ORDERED: 'Join Ordered'
};

const notificationTypes = {
  EMAIL: 'email',
  PUSH: 'push',
  SMS: 'sms'
};

const emailImageTypes = {
  // NOTE: imageUrl should default to null (campaignEmailTemplateImate.kind === null), so this just serves as reference
  IMAGE_URL: null,
  HEADER_OVERRIDE: 'logo'
};

const eventTypes = {
  PostOrderLoyaltyPointEarn: {
    value: 'PostOrderLoyaltyPointEarn',
    name: 'Points Earned',
    description: 'Send the guest a campaign when earn loyalty points'
  },
  PostOfferRedemption: {
    value: 'PostOfferRedemption',
    name: 'Offer Redemption',
    description: 'Send the guest a campaign when they redeem an offer'
  },
  PurchaseGiftCard: {
    value: 'PurchaseGiftCard',
    name: 'Gift Card Purchase',
    description: 'Send the guest a campaign when they purchase a gift card'
  },
  ReloadGiftCard: {
    value: 'ReloadGiftCard',
    name: 'Gift Card Reload',
    description: 'Send the guest a campaign when they reload a gift card'
  },
  GiftCardSentAsGift: {
    value: 'GiftCardSentAsGift',
    name: 'Gift Card Sent as Gift',
    description: 'Send the card purchaser a campaign when they buy a gift card for another guest'
  },
  CustomerCheckIn: {
    value: 'CustomerCheckIn',
    name: 'Check In',
    description: 'Send the guest a campaign when they check in at a store'
  }
};

export {
  campaignStates,
  campaignDeliveryTypes,
  campaignSegments,
  notificationTypes,
  eventTypes,
  emailImageTypes
};
