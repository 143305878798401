import moment from 'moment';
import http from '@/services/http';
import filterObjectKeys from '@/helpers/filter-object-keys';
import LoyaltyDraft from '@/store/classes/LoyaltyDraft';
import { conversionThresholdAwardPolicyTypes, conversionThresholdRunLimitPolicyTypes } from '@/constants/conversionThresholds';


export default class ConversionThreshold extends LoyaltyDraft {
  static entity = 'conversionThresholds'

  static primaryKey = 'publicId';

  // FIELDS //////////////////////
  static fields() {
    return {
      ...super.fields(),
      publicId: this.uid(),
      currencyPublicId: this.attr(null),
      cost: this.attr(null),
      startDate: this.attr(null),
      endDate: this.attr(null),
      autoConvert: this.attr(false),
      allowVariablePointConversion: this.attr(null),
      runLimitPolicy: this.attr(() => ({
        runLimitPolicyType: conversionThresholdRunLimitPolicyTypes.UNLIMITED
      })),
      awardPolicy: this.attr(() => ({
        conversionThresholdAwardPolicyType: conversionThresholdAwardPolicyTypes.OFFER_CONVERSION.type,
        ...conversionThresholdAwardPolicyTypes.OFFER_CONVERSION.defaultData
      }))
    };
  }

  static statuses = {
    DRAFT: 'draft',
    ACTIVE: 'active',
    SCHEDULED: 'scheduled',
    EXPIRED: 'expired'
  };

  get status() {
    switch (true) {
      case !!this.$id && this.endDate && moment(this.endDate).startOf('day').isBefore(moment().startOf('day')):
        return ConversionThreshold.statuses.EXPIRED;

      case !!this.$id && moment(this.startDate).startOf('day').isAfter(moment().startOf('day')):
        return ConversionThreshold.statuses.SCHEDULED;

      case !!this.$id:
        return ConversionThreshold.statuses.ACTIVE;

      default:
        return ConversionThreshold.statuses.DRAFT;
    }
  }

  get isRedeemableForOffers() {
    return this.awardPolicy.conversionThresholdAwardPolicyType === conversionThresholdAwardPolicyTypes.OFFER_CONVERSION.type;
  }

  static conversionThresholdsByCurrencyId(currencyId) {
    return this
      .query()
      .where('currencyPublicId', currencyId)
      .get();
  }

  static conversionThresholdsByCurrencyIds(currencyIds) {
    return ConversionThreshold
      .query()
      .where('currencyPublicId', id => currencyIds.includes(id))
      .get();
  }


  // STATE //////////////////////
  static state() {
    return {
      fetching: false,
      fetchingDrafts: false,
      submitting: false,
      errorMessages: {}
    };
  }

  static $state() {
    return this.store().state.entities.conversionThresholds;
  }


  // ACTIONS //////////////////////
  static async fetchDraftConversionThresholds() {
    try {
      this.commit((state) => {
        state.fetchingDrafts = true;
      });

      const merchantId = this.store().state.entities.merchants.selectedMerchantId;
      const { data } = await http.get(`merchants/${merchantId}/conversion_thresholds/drafts`);

      this.insert({
        data: data.draftConversionThresholds.map(d => ({ ...d, ...d.body }))
      });
    }

    catch (error) {
      throw error;
    }

    finally {
      this.commit((state) => {
        state.fetchingDrafts = false;
      });
    }
  }

  static async fetchConversionThresholds(programPublicId) {
    try {
      this.commit((state) => {
        state.fetching = true;
        state.errorMessages = {};
      });

      const merchantId = this.store().state.entities.merchants.selectedMerchantId;
      const { data: { conversionThresholds } } = await http.get(`merchants/${merchantId}/programs/${programPublicId}/conversion_thresholds`);
      this.insert({ data: conversionThresholds.map(ct => ({ ...ct, loyaltyPublicId: ct.publicId })) });
    }

    catch (error) {
      throw error;
    }

    finally {
      this.commit((state) => {
        state.fetching = false;
      });
    }
  }

  static async fetchConversionThreshold(publicId) {
    try {
      this.commit((state) => {
        state.fetching = true;
      });

      const merchantId = this.store().state.entities.merchants.selectedMerchantId;
      const { data: { conversionThreshold } } = await http.get(`merchants/${merchantId}/conversion_thresholds/${publicId}`);
      this.insert({ data: { ...conversionThreshold, loyaltyPublicId: conversionThreshold.publicId } });
    }

    catch (error) {
      throw error;
    }

    finally {
      this.commit((state) => {
        state.fetching = false;
      });
    }
  }

  static async createDraftConversionThreshold(conversionThreshold) {
    try {
      this.commit((state) => {
        state.submitting = true;
      });
      const merchantId = this.store().state.entities.merchants.selectedMerchantId;

      // const conversionThreshold = new this(
      //   {
      //     currencyPublicId,
      //     cost: 599,
      //     startDate: '2021-03-23',
      //     endDate: '2024-12-31',
      //     autoConvert: false,
      //     runLimitPolicy: {
      //       runLimitPolicyType: 'UnlimitedRunLimitPolicyModel',
      //       maximumRuns: null
      //     },
      //     awardPolicy: {
      //       conversionThresholdAwardPolicyType: 'OfferConversionThresholdAwardPolicyModel',
      //       currencyPublicId,
      //       points: null,
      //       cardfreeOfferPublicId: 'OF2NpQWJ99ZaPDFCw8',
      //       weightedAwardOptions: [],
      //       targetPointsPerSourcePointExchangeRate: null,
      //       minimumPointAwardAmount: null,
      //       maximumPointAwardAmount: null
      //     }
      //   }
      // );
      const { data: { draftConversionThreshold } } = await http.post(`merchants/${merchantId}/conversion_thresholds/drafts`, {
        conversionThreshold
      });

      this.insert({ data: { ...draftConversionThreshold, ...draftConversionThreshold.body } });
    }
    catch (error) {
      throw error;
    }
    finally {
      this.commit((state) => {
        state.submitting = false;
      });
    }
  }

  static async createConversionThreshold(conversionThreshold) {
    try {
      this.commit((state) => {
        state.submitting = true;
        delete state.errorMessages[conversionThreshold.publicId];
      });
      const merchantId = this.store().state.entities.merchants.selectedMerchantId;

      const { data: { conversionThreshold: ct } } = await http.post(`merchants/${merchantId}/conversion_thresholds`, {
        conversionThreshold: filterObjectKeys(conversionThreshold, [
          'currencyPublicId',
          'cost',
          'startDate',
          'endDate',
          'autoConvert',
          'runLimitPolicy',
          'awardPolicy'
        ])
      });

      this.insert({ data: { ...ct, loyaltyPublicId: ct.publicId } });
    }
    catch (error) {
      this.commit((state) => {
        state.errorMessages[conversionThreshold.publicId] = error.data?.detail || 'There was an unknown error saving this conversion threshold.';
      });
      throw error;
    }
    finally {
      this.commit((state) => {
        state.submitting = false;
      });
    }
  }

  static async updateDraftConversionThreshold(conversionThreshold) {
    try {
      this.commit((state) => {
        state.submitting = true;
      });

      const merchantId = this.store().state.entities.merchants.selectedMerchantId;
      const { data: { draftConversionThreshold } } = await http.put(`merchants/${merchantId}/conversion_thresholds/drafts/${conversionThreshold.id}`, {
        conversionThreshold
      });

      // NOTE: Because publicId is the primary key, we can use the generated value to determine the record to update
      this.update({
        data: { ...draftConversionThreshold, ...draftConversionThreshold.body },
        where: conversionThreshold.publicId
      });
    }
    catch (error) {
      throw error;
    }
    finally {
      this.commit((state) => {
        state.submitting = false;
      });
    }
  }

  static async updateConversionThreshold(conversionThreshold) {
    try {
      this.commit((state) => {
        state.submitting = true;
        delete state.errorMessages[conversionThreshold.publicId];
      });
      const merchantId = this.store().state.entities.merchants.selectedMerchantId;

      const { data: { conversionThreshold: ct } } = await http.put(`merchants/${merchantId}/conversion_thresholds/${conversionThreshold.publicId}`, {
        conversionThreshold: filterObjectKeys(conversionThreshold, [
          'currencyPublicId',
          'cost',
          'startDate',
          'endDate',
          'autoConvert',
          'runLimitPolicy',
          'awardPolicy'
        ])
      });

      this.update({ data: { ...ct, loyaltyPublicId: ct.publicId } });
    }
    catch (error) {
      this.commit((state) => {
        state.errorMessages[conversionThreshold.publicId] = error.data?.detail || 'There was an unknown error saving this conversion threshold.';
      });
      throw error;
    }
    finally {
      this.commit((state) => {
        state.submitting = false;
      });
    }
  }

  static async promoteDraftConversionThreshold(draftConversionThreshold) {
    try {
      this.commit((state) => {
        state.submitting = true;
      });

      const merchantId = this.store().state.entities.merchants.selectedMerchantId;
      const { data: { conversionThreshold } } = await http.put(`merchants/${merchantId}/conversion_thresholds/drafts/${draftConversionThreshold.id}/promote`, { conversionThreshold: draftConversionThreshold });

      this.insert({ data: { ...conversionThreshold, loyaltyPublicId: conversionThreshold.publicId } });

      return {
        ...conversionThreshold,
        loyaltyPublicId: conversionThreshold.publicId,
        draftPublicId: draftConversionThreshold.publicId
      };
    }
    catch (error) {
      // Attaching the conversionThresholdId to the error object so we can display the error message on the correct draft
      error.conversionThresholdId = draftConversionThreshold.id;
      throw error;
    }
    finally {
      this.commit((state) => {
        state.submitting = false;
      });
    }
  }

  static async deleteDraftConversionThreshold(conversionThreshold) {
    try {
      this.commit((state) => {
        state.submitting = true;
      });

      const merchantId = this.store().state.entities.merchants.selectedMerchantId;
      await http.delete(`merchants/${merchantId}/conversion_thresholds/drafts/${conversionThreshold.id}`);

      this.delete(conversionThreshold.publicId);
    }
    catch (error) {
      throw error;
    }
    finally {
      this.commit((state) => {
        state.submitting = false;
      });
    }
  }

  static async deleteConversionThreshold(publicId) {
    try {
      this.commit((state) => {
        state.submitting = true;
      });

      const merchantId = this.store().state.entities.merchants.selectedMerchantId;

      await http.delete(`merchants/${merchantId}/conversion_thresholds/${publicId}`);

      this.delete(publicId);
    }
    catch (error) {
      throw error;
    }
    finally {
      this.commit((state) => {
        state.submitting = false;
      });
    }
  }
}
