import http from '@/services/http';

export async function fetchAppCodeTypesByMerchant(merchantId) {
  try {
    const response = await http.get(`app_code_types?merchant_id=${merchantId}`);
    return response.data.appCodeTypes;
  }
  catch (error) {
    throw error;
  }
}

export default {
  fetchAppCodeTypesByMerchant
};
