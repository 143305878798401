/* eslint-disable import/no-cycle */
import { Model } from '@vuex-orm/core';
import http from '@/services/http';
import PosMenuCategory from './PosMenuCategory';



export default class PosMenuItem extends Model {
  static entity = 'posMenuItems'

  static primaryKey = ['parentId', 'id'] // needed to keep unique records for posMenuItems that belong to more than one parent

  static fields() {
    return {
      id: this.attr(''),
      posItemId: this.attr(''),
      name: this.attr(''),
      description: this.attr(''),
      excludeFromImport: this.attr(''),
      childrenCount: this.attr(''),
      storeId: this.attr(''),
      mappedToCardfree: this.attr(''),
      cfMenuItemId: this.attr(''),
      posType: this.attr(''),
      posTypeId: this.attr(''),
      posMenuItemTypeId: this.attr(''),
      isModifierCode: this.attr(false),
      // FE Properties
      parentId: this.attr(null) // used to allow associating child posMenuItem records to their parents
    };
  }

  // STATE //////////////////////
  static state() {
    return {
      fetching: false,
      submitting: false,
      isFetchingFirstTime: false,
      metaData: {},
      searching: false,
      modifierCodes: []
    };
  }

  static $state() {
    return this.store().state.entities.posMenuItems;
  }


  // ACTIONS //////////////////////
  static async fetchPosItems({ page = 1, isFetchingFirstTime = false }) {
    try {
      this.commit((state) => {
        if (isFetchingFirstTime) {
          state.isFetchingFirstTime = isFetchingFirstTime;
        }
        else {
          state.fetching = true;
        }
      });

      const merchantId = this.store().state.entities.merchants.selectedMerchantId;

      const { data: { posMenuItems, meta } } = await http.get(`merchants/${merchantId}/pos_menu_items?page=${page}`);
      this.create({ data: posMenuItems });

      this.commit((state) => {
        state.metaData = meta;
      });
    }
    catch (error) {
      throw error;
    }
    finally {
      this.commit((state) => {
        state.fetching = false;
        state.isFetchingFirstTime = false;
      });
    }
  }

  static async searchPOSResources(query) {
    try {
      this.commit((state) => {
        state.searching = true;
      });

      const merchantId = this.store().state.entities.merchants.selectedMerchantId;
      const encodeQuery = encodeURIComponent(query);


      const response = await http.get(`merchants/${merchantId}/pos_menu_items?term=${encodeQuery}`);
      return response.data.posMenuItems;
    }

    catch (error) {
      throw error;
    }

    finally {
      this.commit((state) => {
        state.searching = false;
      });
    }
  }

  static async fetchModifierCodes() {
    try {
      this.commit((state) => {
        state.fetching = true;
      });

      const merchantId = this.store().state.entities.merchants.selectedMerchantId;

      const response = await http.get(`merchants/${merchantId}/pos_menu_items?modifier_codes=true`);

      this.commit((state) => {
        state.modifierCodes = response.data.posMenuItems;
      });
    }

    catch (error) {
      throw error;
    }

    finally {
      this.commit((state) => {
        state.fetching = false;
      });
    }
  }

  static async fetchChildren(id) {
    try {
      this.commit((state) => {
        state.fetching = true;
      });

      const { data } = await http.get(`pos_menu_items/${id}/children`);

      const dataWithParentId = data.posMenuItems.map((pmi) => {
        pmi.parentId = id;
        return pmi;
      });

      this.insert({ data: dataWithParentId });

      return dataWithParentId;
    }
    catch (error) {
      throw error;
    }
    finally {
      this.commit((state) => {
        state.fetching = false;
      });
    }
  }

  static async updateExcludeValues(posMenuItems) {
    try {
      this.commit((state) => {
        state.submitting = true;
      });

      const merchantId = this.store().state.entities.merchants.selectedMerchantId;

      const { data } = await http.put(`merchants/${merchantId}/pos_menu_items`, { posMenuItems });

      this.update({ data: data.posMenuItems });
    }
    catch (error) {
      throw error;
    }
    finally {
      this.commit((state) => {
        state.submitting = false;
      });
    }
  }

  static async bulkUpdateExcludeValues({ storeId, excludeFromImport }) {
    try {
      this.commit((state) => {
        state.submitting = true;
      });

      const merchantId = this.store().state.entities.merchants.selectedMerchantId;

      await http.put(`merchants/${merchantId}/pos_menu_items/bulk_update`, {
        storeId,
        excludeFromImport
      });

      let posMenuCategories;
      let posMenuItems;
      if (storeId) {
        posMenuCategories = PosMenuCategory.query().where('storeId', storeId).get();
        posMenuItems = this.query().where('storeId', storeId).get();
      }
      else {
        posMenuCategories = PosMenuCategory.all();
        posMenuItems = this.all();
      }

      posMenuCategories.forEach((category) => {
        category.excludeFromImport = excludeFromImport;
      });
      posMenuItems.forEach((item) => {
        item.excludeFromImport = excludeFromImport;
      });

      PosMenuCategory.update({ data: posMenuCategories });
      this.update({ data: posMenuItems });
    }
    catch (error) {
      throw error;
    }
    finally {
      this.commit((state) => {
        state.submitting = false;
      });
    }
  }



  // POS Menu Item Mappings

  static async addPosMapping({
    itemId,
    modifierId,
    posItemId,
    posModifier1Id,
    posModifier2Id,
    posModifier3Id
  }) {
    try {
      if (itemId) {
        await http.post(`/menu_items/${itemId}/pos_mappings`, {
          posMapping: {
            posMenuItemId: posItemId,
            posModifier1Id,
            posModifier2Id,
            posModifier3Id
          }
        });
      }

      if (modifierId) {
        await http.post(`/menu_item_modifiers/${modifierId}/pos_mappings`, {
          posMapping: { posMenuItemId: posItemId }
        });
      }
    }
    catch (error) {
      throw error;
    }
  }

  static async updatePosMapping({ id, posModifier1Id, posModifier2Id, posModifier3Id }) {
    try {
      await http.put(`/pos_menu_item_mappings/${id}`, {
        posMapping: {
          posModifier1Id,
          posModifier2Id,
          posModifier3Id
        }
      });
    }
    catch (error) {
      throw error;
    }
  }

  static async deletePosMapping({ itemId, modifierId, id }) {
    try {
      if (itemId) {
        await http.delete(`/menu_items/${itemId}/pos_mappings/${id}`);
      }

      if (modifierId) {
        await http.delete(`/menu_item_modifiers/${modifierId}/pos_mappings/${id}`);
      }
    }
    catch (error) {
      throw error;
    }
  }

  static async clearPosMenu({ storeIds, hasUnstructuredMenu }) {
    try {
      const merchantId = this.store().state.entities.merchants.selectedMerchantId;
      const queryString = storeIds.length
        ? `?${storeIds.map(si => `store_ids[]=${si}`).join('&')}`
        : '';

      await http.delete(`merchants/${merchantId}/pos_menu_items${queryString}`);

      if (storeIds.length) {
        if (!hasUnstructuredMenu) {
          PosMenuCategory.delete(posMenuCategory => storeIds.includes(posMenuCategory.storeId));
        }
        this.delete(posMenuItem => storeIds.includes(posMenuItem.storeId));
      }
      else {
        PosMenuCategory.deleteAll();
        this.deleteAll();
      }
    }
    catch (error) {
      throw error;
    }
  }
}
