<template>
  <validated-form
    ref="form"
    form-id="primaryCurrency"
    :disabled="!$can('update', 'Loyalty::Program')"
    @valid-submit="submit"
  >
    <panel
      title="Program Currency"
      subtitle="Define the naming scheme for your program's currency"
      collapsible
      :loading="fetching"
    >
      <template #buttons>
        <b-button
          v-if="$can('update', 'Loyalty::Program')"
          rounded
          class="is-bold"
          size="is-medium"
          native-type="submit"
          type="is-primary"
          :loading="submitting"
        >
          {{ form.publicId ? 'Save' : 'Create' }}
        </b-button>
      </template>

      <div class="is-grid gap-x-md gap-y currency-inputs">
        <validated-text-input
          v-model="form.name"
          name="name"
          type="text"
          label="Name"
          placeholder="point"
          rules="required"
        />
        <validated-text-input
          v-model="form.shortName"
          name="shortName"
          type="text"
          label="Short Name"
          placeholder="pt"
          rules="required"
        />
        <validated-text-input
          v-model="form.pluralName"
          name="pluralName"
          type="text"
          label="Plural Name"
          placeholder="points"
          rules="required"
        />
        <validated-text-input
          v-model="form.pluralShortName"
          name="pluralShortName"
          type="text"
          label="Plural Short Name"
          placeholder="pts"
          rules="required"
        />
      </div>
    </panel>
  </validated-form>
</template>

<script>
  import Currency from '@/store/classes/Currency';
  import LoyaltyProgram from '@/store/classes/LoyaltyProgram';


  export default {
    name: 'PrimaryCurrency',

    data: () => ({
      form: {}
    }),

    computed: {
      fetching() {
        return Currency.$state().fetching;
      },

      submitting() {
        return Currency.$state().submitting;
      },

      primaryCurrency() {
        return Currency.primaryCurrency();
      },

      loyaltyProgram() {
        return LoyaltyProgram.query().first();
      }
    },

    watch: {
      primaryCurrency: {
        immediate: true,
        handler(currency) {
          if (currency) this.form = currency;
        }
      }
    },

    methods: {
      async submit() {
        try {
          if (this.form.publicId) await Currency.updateCurrency(this.form);
          else {
            await Currency.createCurrency({
              programPublicId: this.loyaltyProgram.publicId,
              category: Currency.categoryTypes.PRIMARY,
              challengeProcessingType: Currency.challengeProcessingTypes.NONE,
              ...this.form
            });
          }

          this.$_onRequestSuccess({
            toastOptions: {
              message: 'Primary Currency successfully updated!'
            }
          });
        }

        catch (error) {
          this.$_onRequestError({
            error,
            toastOptions: {
              message: 'There was an error updating your Primary Currency'
            }
          });
        }
      }
    }
  };
</script>

<style lang='sass' scoped>
  .currency-inputs
    grid-template-columns: 1fr 0.5fr
</style>
