var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('button',{class:[
    'button dropdown-trigger-button',
    _vm.isFullWidth && 'is-full-width',
    _vm.hasErrors && 'is-danger is-outlined has-text-danger has-background-white',
    _vm.disabled && 'has-background-white-ter is-white has-text-grey'
  ],attrs:{"type":"button"}},[_c('div',{staticClass:"button-text mar-r-md"},[_vm._t("default",[(_vm.value)?[_vm._v(_vm._s(_vm.value))]:(_vm.placeholder)?_c('span',{staticClass:"has-text-grey-light"},[_vm._v(_vm._s(_vm.placeholder))]):_vm._e()])],2),_c('div',{staticClass:"is-flex control-icon",class:{
      'has-text-danger': _vm.hasErrors,
      'has-text-primary': !_vm.hasErrors
    }},[(_vm.loading)?_c('b-icon',{staticClass:"spin",attrs:{"icon":"spinner-third"}}):_c('b-icon',{staticClass:"arrow-icon is-flex",attrs:{"icon":"angle-down","size":"is-medium"}})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }