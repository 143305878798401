<template>
  <b-collapse
    class="card"
    animation="slide"
    data-test-id="order-expo-search-card"
    :open="false"
    @open="fetchOrderDetails(completeOrder.orderId)"
  >
    <template #trigger="props">
      <div
        class="card-header pad-x-md pad-y is-grid align-center justify-start"
        role="button"
      >
        <span class="has-text-weight-bold is-size-4 is-inline-block">
          {{ completeOrder.guestName }}
        </span>
        <span>
          {{ completeOrder.expoStatus }}
        </span>
        <b-tag
          :type="tagTypes[orderStatus]"
          class="is-outlined"
          :class="{ 'is-light': isLightTagTypes.includes(orderStatus) }"
          data-test-id="order-status"
        >
          {{ capitalCase(orderStatus) }}
        </b-tag>
        <span>
          {{ completeOrder.pickupDate | moment('h:mm A') }}
        </span>
        <span>
          {{ driverName }}
        </span>
        <span>
          {{ completeOrder.ticketNumber }}
        </span>
        <span class="has-text-weight-bold">
          {{ completeOrder.orderItemTotal | dollars }}
        </span>
        <b-icon :icon="props.open ? 'angle-down' : 'angle-right'" />
      </div>
    </template>
    <div class="card-content pad">
      <expo-order-details-section
        title="Guest"
        icon="user"
      >
        <div class="is-grid col-3">
          <expo-order-details-row
            label="Phone"
            :content="completeOrder.phoneNumber | phoneNumber"
          />
          <expo-order-details-row
            label="Email"
            :content="completeOrder.email"
          />
          <b-button
            label="Go to Order"
            class="is-inline place-self-end"
            rounded
            type="is-primary"
            @click="navigateToOrder(completeOrder.orderId)"
          />
        </div>
      </expo-order-details-section>
      <hr class="mar-y-sm">
      <expo-order-details-section
        title="Driver"
        icon="car"
      >
        <div class="is-grid col-3">
          <expo-order-details-row
            label="Driver Name"
            :content="completeOrder.phoneNumber | phoneNumber"
          />
          <expo-order-details-row
            label="Phone"
            :content="completeOrder.email"
          />
        </div>
      </expo-order-details-section>
      <hr class="mar-y-sm">
      <expo-order-details-section
        title="Order Items"
        icon="shopping-bag"
        collapsible
      >
        <order-details-items :order="completeOrder" />
        <order-price-breakdown :order="completeOrder" />
      </expo-order-details-section>
      <hr class="mar-y-sm">
      <expo-order-details-section
        title="Payment Info"
        icon="credit-card"
        collapsible
      >
        <expo-order-payment-detail :order="completeOrder" />
      </expo-order-details-section>
    </div>
  </b-collapse>
</template>

<script>
  import { capitalCase } from 'change-case';
  import moment from 'moment-timezone';
  import { mapActions, mapState } from 'vuex';

  import ExpoOrder from '@/store/classes/ExpoOrder';

  import OrderDetailsItems from '@/components/globals/order-details-items.vue';
  import OrderPriceBreakdown from '@/components/globals/order-price-breakdown.vue';

  import ExpoOrderDetailsRow from './expo-order-details-row.vue';
  import ExpoOrderDetailsSection from './expo-order-details-section.vue';
  import ExpoOrderPaymentDetail from './expo-order-payment-detail.vue';

  export default {
    name: 'OrderExpoSearchCard',

    components: { OrderDetailsItems, OrderPriceBreakdown, ExpoOrderDetailsRow, ExpoOrderDetailsSection, ExpoOrderPaymentDetail },

    props: {
      order: {
        type: Object,
        required: true
      }
    },

    data: () => ({
      moment,
      capitalCase
    }),

    computed: {
      ...mapState('orderExpo', ['selectedOrderId', 'tagTypes', 'isLightTagTypes']),

      orderStatus() {
        // Will need to be updated when we we are getting expoStatus from the CAPI
        return this.completeOrder.delivery ? this.completeOrder.delivery.status : 'Customer Pickup';
      },

      driverName() {
        return this.completeOrder.delivery ? capitalCase(this.completeOrder.delivery.courier.name) : 'Customer';
      },

      completeOrder() {
        return ExpoOrder.find(this.order.orderId);
      }
    },

    methods: {
      ...mapActions('orderExpo', ['fetchOrderDetails', 'navigateToOrder'])
    }
  };
</script>

<style lang='sass' scoped>
.card-header
  display: grid
  grid-template-columns: 3fr repeat(6, 1fr) 50px
</style>
