<template>
  <div>
    <p class="subtitle is-5 is-marginless">
      Items
    </p>
    <ul class="dist-y-sm mar-t">
      <li
        v-for="menuItemModifierGroupItem in modifierGroup.menuItemModifierGroupItems"
        :key="menuItemModifierGroupItem.id"
        class="is-flex align-center"
        data-test-class="modifier-group-item"
      >
        <div class="is-flex justify-start align-center pad-xs has-border has-border-grey-lightest has-radius-large is-full-width">
          {{ menuItemModifierGroupItem.menuItem.displayName }}
          <b-button
            v-if="!readOnly"
            class="is-transparent mar-l-auto button is-small"
            @click="openItemModal(menuItemModifierGroupItem)"
          >
            <b-icon
              icon="pencil"
              size="is-medium"
            />
          </b-button>
        </div>
        <b-button
          v-if="!readOnly"
          v-tippy="{ content: `Remove ${menuItemModifierGroupItem.menuItem.displayName} from ${modifierGroup.displayName}`, placement: 'left', delay: [333, 0] }"
          class="is-transparent button is-small"
          @click="confirmRemoveItemFromModifierGroup(menuItemModifierGroupItem)"
        >
          <b-icon
            icon="circle-minus"
            type="is-danger"
            size="is-medium"
          />
        </b-button>
      </li>
    </ul>
    <div class="pad-t-sm is-flex-column align-center justify-center">
      <em
        v-if="!modifierGroup.menuItemModifierGroupItems.length"
        data-test-id="no-modifiers-message"
      >
        There are no items associated with this modifier group.
      </em>
      <template v-if="$_selectedMerchant.allowModifierGroupAssociation">
        <b-button
          v-if="!readOnly"
          type="is-primary is-light"
          icon-pack="far"
          icon-left="layer-group"
          :disabled="!modifierGroup.id"
          data-test-id="add-item-button"
          @click="openBulkManageModifierGroupItemAssociationModal()"
        >
          Bulk Manage Modifier Group/Item Associations
        </b-button>
      </template>
    </div>
  </div>
</template>

<script>
  import AlertModal from '@/components/globals/alert-modal.vue';

  import merchantMixin from '@/mixins/merchant';

  import Item from '@/store/classes/Item';
  import MenuItemModifierGroupItem from '@/store/classes/MenuItemModifierGroupItem';

  import AddEditItemModal from '../../add-edit-item-modal/index.vue';
  import BulkManageModifierGroupItemAssociationModal from '../bulk-manage-modifier-group-item-association-modal.vue';


  export default {
    name: 'ModifierGroupItemsTab',

    mixins: [merchantMixin],

    props: {
      modifierGroup: {
        type: Object,
        required: true
      },

      readOnly: {
        type: Boolean,
        default: false
      }
    },

    methods: {
      async openItemModal(menuItemModifierGroupItem) {
        await Item.fetchItem(menuItemModifierGroupItem.menuItem.id);

        const item = Item.query().with('menuCategories').find(menuItemModifierGroupItem.menuItem.id);
        this.$buefy.modal.open({
          parent: this,
          component: AddEditItemModal,
          hasModalCard: true,
          trapFocus: true,
          canCancel: false,
          props: {
            category: item.menuCategories[0],
            mode: 'update',
            itemId: item.id
          }
        });

        this.$emit('requery-items');
      },

      async openBulkManageModifierGroupItemAssociationModal() {
        this.$buefy.modal.open({
          parent: this,
          component: BulkManageModifierGroupItemAssociationModal,
          hasModalCard: true,
          trapFocus: true,
          canCancel: false,
          props: {
            modifierGroup: this.modifierGroup
          }
        });
      },

      confirmRemoveItemFromModifierGroup(menuItemModifierGroupItem) {
        this.$buefy.modal.open({
          parent: this,
          component: AlertModal,
          hasModalCard: true,
          trapFocus: true,
          canCancel: ['escape', 'outside'],
          customClass: 'auto-width',
          props: {
            title: 'Remove Item from Modifier Group',
            message: `Are you sure you want to remove the <b>${menuItemModifierGroupItem.menuItem.displayName}</b> item from the <b>${this.modifierGroup.displayName}</b> modifier group?`,
            showCloseButton: false,
            horizontal: true,
            icon: 'trash-alt',
            type: 'is-danger',
            buttons: [
              { text: 'Cancel' },
              { text: 'Yes, Remove Item', primary: true, onClick: () => this.deleteMenuItemModifierGroupItem(menuItemModifierGroupItem) }
            ]
          }
        });
      },

      async deleteMenuItemModifierGroupItem(menuItemModifierGroupItem) {
        try {
          await MenuItemModifierGroupItem.deleteMenuItemModifierGroupItems([menuItemModifierGroupItem.id]);

          this.$_onRequestSuccess({
            toastOptions: {
              message: `Successfully removed <b>${menuItemModifierGroupItem.menuItem.displayName}</b>from <b>${this.modifierGroup.displayName}</b>`
            }
          });
        }

        catch (error) {
          this.$_onRequestError({
            error,
            toastOptions: {
              message: `Unable to remove <b>${menuItemModifierGroupItem.menuItem.displayName}</b>from <b>${this.modifierGroup.displayName}</b>`
            }
          });
        }

        this.$emit('requery-items');
      }
    }
  };
</script>
