/**
 * Vuex module for handling backend search functionality
 * Initialization example:
 * store.dispatch('backendSearch/initializeBackendSearch', {
 *   searchFunction: User.searchUsers.bind(User),  // bind preserves 'this' context
 *   defaultParams: { searchField: 'name', sortField: 'created_at', sortDirection: 'desc' },
 *   parseResponse: (response) => ({
 *     resources: response.users,
 *     meta: response.meta
 *   })
 * })
 *
 * Note: Remove fetching state commits from the vuex store class that is providing the searchFunction
 * as the backendSearch module will handle its own fetching state
 */
export default {
  namespaced: true,

  state: {
    searchResources: [], // search results
    metadata: {
      total: 0,
      page: 1,
      perPage: 20,
      sortField: 'created_at',
      sortDirection: 'desc'
    },
    loading: false,
    error: null,
    searchTerm: '',
    searchField: '',
    selectedFilters: {},
    defaultParams: {},
    searchFunction: null, // Required: async function that performs the actual search
    parseResponse: null // Required: transform backend response to { resources, meta } format
  },

  getters: {
    searchResources: state => state.searchResources,
    metadata: state => state.metadata,
    searchError: state => state.error,
    loading: state => state.loading,
    searchTerm: state => state.searchTerm,
    searchField: state => state.searchField,
    selectedFilters: state => state.selectedFilters,
    searchState: state => ({
      // Current search parameters for API calls
      page: state.metadata.page,
      perPage: state.metadata.perPage,
      sortField: state.metadata.sortField,
      sortDirection: state.metadata.sortDirection,
      searchTerm: state.searchTerm,
      searchField: state.searchField
    })
  },

  mutations: {
    SET_SEARCH_RESOURCES(state, resources) {
      state.searchResources = resources;
    },

    UPDATE_METADATA(state, updates) {
      state.metadata = {
        ...state.metadata,
        ...updates
      };
    },

    SET_SEARCH_TERM(state, term) {
      state.searchTerm = term;
    },

    SET_SEARCH_FIELD(state, field) {
      state.searchField = field;
    },

    SET_LOADING(state, isLoading) {
      state.loading = isLoading;
    },

    SET_ERROR(state, error) {
      state.error = error;
    },

    SET_FILTERS(state, filters) {
      state.selectedFilters = { ...filters };
    },

    SET_SEARCH_FUNCTION(state, func) {
      state.searchFunction = func;
    },

    SET_PARSE_RESPONSE(state, func) {
      state.parseResponse = func;
    },

    SET_DEFAULT_PARAMS(state, params) {
      state.defaultParams = { ...params };

      if (params.sortField) state.metadata.sortField = params.sortField;
      if (params.sortDirection) state.metadata.sortDirection = params.sortDirection;
      if (params.perPage) state.metadata.perPage = params.perPage;
    },

    RESET_STATE(state) {
      state.metadata = {
        total: 0,
        page: 1,
        perPage: 20,
        sortField: 'created_at',
        sortDirection: 'desc'
      };
      state.searchResources = [];
      state.searchTerm = '';
      state.searchField = '';
      state.error = null;
      state.selectedFilters = {};
    }
  },

  actions: {
    async initializeBackendSearch({ commit }, {
      searchFunction,
      defaultParams = {},
      parseResponse = null
    }) {
      commit('RESET_STATE');
      commit('SET_SEARCH_FUNCTION', searchFunction);
      commit('SET_DEFAULT_PARAMS', defaultParams);
      commit('SET_PARSE_RESPONSE', parseResponse);
    },

    async performSearch({ commit, getters, state }, params = {}) {
      // `params` merges and overrides searchState values
      // any additional params beyond searchState can also be provided

      if (!state.searchFunction || !state.parseResponse) {
        throw new Error('searchFunction and parseResponse must be provided during initialization');
      }

      if (params.searchField) commit('SET_SEARCH_FIELD', params.searchField);
      if (params.searchTerm) commit('SET_SEARCH_TERM', params.searchTerm);
      if (params.page) commit('UPDATE_METADATA', { page: params.page });

      commit('SET_LOADING', true);

      try {
        const response = await state.searchFunction({
          ...getters.searchState,
          ...state.selectedFilters,
          ...params
        });
        const parsedResponse = state.parseResponse(response);

        commit('SET_SEARCH_RESOURCES', parsedResponse.resources);
        commit('UPDATE_METADATA', {
          total: parsedResponse.meta.total || parsedResponse.meta.count
        });

        return response;
      }
      catch (error) {
        commit('SET_ERROR', error);
        throw error;
      }
      finally {
        commit('SET_LOADING', false);
      }
    },

    // Pagination, sorting and filter actions - all trigger a new search
    setPage({ commit, dispatch }, page) {
      commit('UPDATE_METADATA', { page });
      return dispatch('performSearch');
    },

    setPerPage({ commit, dispatch }, perPage) {
      commit('UPDATE_METADATA', {
        perPage,
        page: 1
      });
      return dispatch('performSearch');
    },

    setSort({ commit, dispatch }, { field, direction }) {
      commit('UPDATE_METADATA', {
        sortField: field,
        sortDirection: direction,
        page: 1
      });
      return dispatch('performSearch');
    },

    setFilters({ commit, dispatch }, filters) {
      commit('SET_FILTERS', filters);
      commit('UPDATE_METADATA', { page: 1 });
      return dispatch('performSearch');
    },

    // Update search term/field without triggering search
    setSearchTerm({ commit }, term) {
      commit('UPDATE_METADATA', { page: 1 });
      commit('SET_SEARCH_TERM', term);
    },

    setSearchField({ commit }, field) {
      commit('UPDATE_METADATA', { page: 1 });
      commit('SET_SEARCH_FIELD', field);
    },

    // Reset search params and trigger a new search
    resetSearchParams({ commit, dispatch }) {
      commit('UPDATE_METADATA', { page: 1 });
      commit('SET_FILTERS', {});
      commit('SET_SEARCH_TERM', '');
      return dispatch('performSearch');
    }
  }
};
