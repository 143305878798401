<template>
  <validated-form
    ref="merchantAppSettingResourcesForm"
    form-id="merchantAppSettingResources"
    @valid-submit="updateAppSettings"
  >
    <sticky-save-container
      :saving="isSaving"
      :loading="isFetching"
      title="Application Setting Resources"
    >
      <template #header>
        <b-tabs
          v-model="selectedAppCodeTypeId"
          type="is-toggle"
          class="pad-t-md"
          animation="tab-change-alt"
        >
          <b-tab-item
            v-for="{ id, name } in merchantAppCodeTypes"
            :key="id"
            :value="String(id)"
            :label="name"
          />
        </b-tabs>
      </template>
      <template #default>
        <div v-if="appSettingResourcesForm" class="is-grid row-height-auto col-min-300 gap-lg">
          <template v-for="({value, type}, key, index) in appSettingResourcesForm">
            <check-button
              v-if="type === 'Boolean'"
              :key="key"
              :class="
                ['is-marginless animated tdFadeInDown',
                 {'selected-app-setting-resource': highlightedAppSettingResource === key}
                ]"
              :style="{'animation-delay': `${index * (333 / Object.keys(appSettingResourcesForm).length)}ms`}"
              :value="value || null"
              :label="capitalCase(key)"
              :sublabel="(value === null || value === undefined) && '(No Value)'"
              :data-id="key"
              @input="handleSettingChange(key, $event)"
            />
            <validated-text-input
              v-else-if="type === 'Dollar'"
              :key="`${key}-Dollar`"
              :value="value || null"
              :name="key"
              :label="capitalCase(key)"
              label-position="on-border"
              type="dollars"
              :data-id="key"
              :class="{'selected-app-setting-resource': highlightedAppSettingResource === key}"
              @input="handleSettingChange(key, $event)"
            />
            <b-field
              v-else
              :key="`${key}-${type}`"
              :label="buildFieldLabel(key, value)"
              :label-for="key"
              label-position="on-border"
              class="is-marginless animated tdFadeInDown is-grid"
              :class="{'selected-app-setting-resource': highlightedAppSettingResource === key}"
              :style="{'animation-delay': `${index * (333 / Object.keys(appSettingResourcesForm).length)}ms`}"
              :data-id="key"
            >
              <b-input
                :id="key"
                :type="type === 'Number' ? 'number' : 'text'"
                :custom-class="`${key.match(/id$/i) && value && 'is-monospaced'}`"
                :value="value || null"
                size="is-medium"
                :placeholder="capitalCase(key)"
                @input="handleSettingChange(key, $event)"
                @keydown.native.space.prevent
              />
            </b-field>
          </template>
        </div>
      </template>
    </sticky-save-container>
  </validated-form>
</template>

<script>
  import merchantMixin from '@/mixins/merchant';
  import capitalCase from '@/helpers/capitalCase';

  import AppCodeType from '@/store/classes/AppCodeType';
  import MerchantAppSetting from '@/store/classes/MerchantAppSetting';
  import MerchantAppSettingResource from '@/store/classes/MerchantAppSettingResource';

  export default {
    name: 'MerchantAppSettingResources',

    mixins: [merchantMixin],

    props: {
      merchant: {
        type: Object,
        required: true
      }
    },

    data() {
      return {
        capitalCase,
        appSettingResourcesForm: null,
        isSaving: false,
        selectedAppCodeTypeId: '1' // needs to be a string to work with the `value` prop of the <b-tab-item> component
      };
    },

    computed: {
      appSettingResourceKeys() {
        return MerchantAppSettingResource.$state().masrKeys;
      },

      selectedMerchantAppSetting() {
        return MerchantAppSetting.query().where('appCodeTypeId', Number(this.selectedAppCodeTypeId)).first();
      },

      selectedAppSettingResources() {
        return MerchantAppSettingResource.query().where('merchantAppSettingsId', this.selectedMerchantAppSetting?.id).get();
      },

      highlightedAppSettingResource() {
        return this.$route.query.highlighted;
      },

      merchantAppCodeTypes() {
        const merchantAppCodeTypeIds = MerchantAppSetting.all().map(mas => mas.appCodeTypeId);
        return AppCodeType.query().whereIdIn(merchantAppCodeTypeIds).get();
      },

      isFetching() {
        return MerchantAppSettingResource.$state().fetching;
      }
    },

    watch: {
      selectedAppSettingResources: 'setForm'
    },

    created() {
      this.onCreated();
    },

    methods: {
      buildFieldLabel(key, value) {
        const label = capitalCase(key);
        return (value === null || value === undefined) ? `${label} (No Value)` : label;
      },

      async onCreated() {
        if (this.$route.params?.appCodeTypeId) {
          this.selectedAppCodeTypeId = this.$route.params.appCodeTypeId;
          this.selectedAppSettingResource = this.$route.params.selectedAppSettingResource;
        }
        await this.fetchAppSettingResourceKeys();
        this.setForm();
      },

      async fetchAppSettingResourceKeys() {
        if (!MerchantAppSettingResource.$state().masrKeys) {
          try {
            await MerchantAppSettingResource.fetchAppSettingResourceKeys();
          }
          catch (error) {
            this.$_onRequestError({
              error,
              toastOptions: { message: 'There was an error loading application setting resource keys' }
            });
          }
        }
      },

      handleSettingChange(key, value) {
        this.appSettingResourcesForm[key].value = value;
      },

      handleHighlightSetting() {
        const selectedElement = this.highlightedAppSettingResource;
        this.$nextTick(() => {
          const element = this.$el.querySelector(`[data-id="${selectedElement}"]`);

          if (element) {
            element.scrollIntoView({ block: 'center', behavior: 'smooth' });
            element.classList.add('highlight-element');
            element.focus();

            setTimeout(() => {
              element.classList.remove('highlight-element');
            }, 3000);
          }
        });
      },

      setForm() {
        this.appSettingResourcesForm = [...this.appSettingResourceKeys].sort().reduce((acc, key) => {
          const stringTypes = [
            'aosAppId',
            'aosUrl',
            'app-rating-survey-type',
            'cardfree-branding-placement',
            'default-store-id',
            'emv-display-mode',
            'emv-tablesearch-keyboard-mode',
            'iosAppId',
            'iosUrl',
            'kiosk-alternate-idle-image-url',
            'kiosk-and-emv-master-passcode',
            'kiosk-hero-image-url',
            'kiosk-idle-image-url',
            'kiosk-merchant-description',
            'kiosk-splash-image-url',
            'menu-layout',
            'survey-placement',
            'single-scan-menu-webview-url',
            'qr-code-field',
            'menu-webview-url',
            'menu-image-ratio',
            'menu-verbosity'
          ];

          const numberTypes = [
            'app-rating-days',
            'gift-card-limit-on-order',
            'tip-screen-timeout-seconds',
            'app-rating-order-count',
            'emv-marketing-opt-in-timeout',
            'emv-marketing-promo-timeout'
          ];

          const dollarTypes = [
            'single-scan-starting-tip-amount',
            'single-scan-incrementation-amount'
          ];

          const getType = (_key) => {
            switch (true) {
              case stringTypes.includes(_key):
                return 'String';
              case numberTypes.includes(_key):
                return 'Number';
              case dollarTypes.includes(_key):
                return 'Dollar';
              default:
                return 'Boolean';
            }
          };

          const appSettingResource = this.selectedAppSettingResources.find(resource => resource.key === key);

          acc[key] = {
            value: appSettingResource?.value,
            id: appSettingResource?.id,
            type: getType(key)
          };

          return acc;
        }, {});
        this.handleHighlightSetting();
      },

      async updateAppSettings() {
        this.isSaving = true;

        const payload = Object.entries(this.appSettingResourcesForm)
          .filter(([, appSettingResource]) => appSettingResource.value !== undefined)
          .map(([key, appSettingResource]) => ({
            key,
            value: appSettingResource.value,
            id: appSettingResource.id,
            appCodeTypeId: this.selectedMerchantAppSetting.appCodeTypeId
          }));

        try {
          await MerchantAppSettingResource.createOrUpdateAppSettingResources({
            merchantId: this.merchant.id,
            merchantAppSettingResources: payload
          });

          this.$_onRequestSuccess({
            toastOptions: { message: 'Successfully updated Application Settings' }
          });
        }

        catch (error) {
          this.$_onRequestError({
            error,
            toastOptions: {
              message: 'There was an issue updating your Application Settings'
            }
          });
        }

        finally {
          this.isSaving = false;
        }
      }
    }
  };
</script>

<style lang="sass" scoped>
  ::v-deep
    .tabs
      a
        width: 8rem
      .is-active
        a
          background-color: $dark !important
          border-color: $dark !important
          font-weight: 600

    .tab-content
      padding: $size-medium 0 0 0 !important

  .row-height-auto ::v-deep
    .control,
    input
      height: 100%
    .control .icon
      top: 50%
      transform: translateY(-50%)
</style>
