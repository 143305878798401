<template>
  <div class="card">
    <transition mode="out-in" name="fade-zoom">
      <div>
        <div class="border-bottom has-border-grey-lightest pad-sm" style="padding: 10px">
          <div class="is-flex justify-between">
            <div>
              <b-field
                v-if="hasStoreSpecificPosMenu"
                label-position="on-border"
                label="Location"
                class="is-marginless"
              >
                <b-select v-model="selectedStoreId">
                  <option
                    v-for="store in importedPosStores"
                    :key="store.storeId"
                    :value="store.storeId"
                  >
                    {{ store.description }}
                  </option>
                </b-select>
              </b-field>
            </div>
            <div class="is-flex dist-x">
              <b-button
                class="is-bold icon-spin-once"
                type="is-primary is-light"
                outlined
                icon-left="redo"
                @click="openLocationImportModal"
              >
                Reimport POS Menu
              </b-button>

              <b-button
                class="is-bold"
                type="is-danger"
                outlined
                icon-left="trash-alt"
                @click="openClearDataModal"
              >
                Clear Data
              </b-button>
            </div>
          </div>
        </div>
        <b-table
          :data="isFetchingPosItems ? [] : posMenuItems"
          class="is-middle-aligned"
          paginated
          :backend-pagination="true"
          scrollable
          :per-page="250"
          :current-page="page"
          :mobile-cards="false"
          striped
          :total="posMenuItemMetaData.count"
          @page-change="handlePageChange"
        >
          <template slot="empty">
            <empty-table-loader
              :loading="isFetchingPosItems"
              :has-icon="false"
              message="No Pos Items Found"
            />
          </template>
          <b-table-column v-slot="{ row: { name } }" sortable field="name" label="Name">
            <span>
              {{ name || 'N/A' }}
            </span>
          </b-table-column>
          <b-table-column v-slot="{ row: { posItemId } }" sortable field="posItemId" label="PLU #">
            <span>
              {{ posItemId || 'N/A' }}
            </span>
          </b-table-column>
          <b-table-column
            v-slot="{ row: { mappedToCardfree } }"
            centered
            sortable
            field="mappedToCardfree"
            label="Mapped"
          >
            <b-icon v-if="mappedToCardfree" type="is-primary" icon="check" size="is-small" />
          </b-table-column>
        </b-table>
      </div>
    </transition>
  </div>
</template>

<script>
  import merchantMixin from '@/mixins/merchant';
  import Store from '@/store/classes/Store';
  import PosMenuItem from '@/store/classes/PosMenuItem';

  export default {
    name: 'PosUnstructuredMenuList',

    mixins: [merchantMixin],

    props: {
      openLocationImportModal: {
        type: Function,
        required: true
      },
      openClearDataModal: {
        type: Function,
        required: true
      }
    },

    data() {
      return {
        selectedStoreId: null,
        page: 1
      };
    },

    computed: {
      posMenuItems() {
        return PosMenuItem.all();
      },

      posMenuItemsByStore() {
        return PosMenuItem.query().where('storeId', this.selectedStoreId).get();
      },

      posMenu() {
        return this.hasStoreSpecificPosMenu
          ? this.posMenuItemsByStore
          : this.posMenuItems;
      },

      importedPosStoreIds() {
        const storeIds = new Set();
        this.posMenuItems.forEach((posItem) => {
          if (posItem.storeId) {
            storeIds.add(posItem.storeId);
          }
        });

        return Array.from(storeIds);
      },

      importedPosStores() {
        return Store.orderByName().whereIdIn(this.importedPosStoreIds).get();
      },

      hasStoreSpecificPosMenu() {
        return !!this.importedPosStoreIds.length;
      },

      posMenuItemMetaData() {
        return PosMenuItem.$state().metaData;
      },

      isFetchingPosItems() {
        return PosMenuItem.$state().fetching;
      }
    },

    created() {
      this.onCreated();
    },

    destroyed() {
      this.onDestroyed();
    },

    methods: {
      onCreated() {
        if (this.hasStoreSpecificPosMenu) {
          this.selectedStoreId = this.importedPosStoreIds[0];
        }
      },

      onDestroyed() {
        PosMenuItem.deleteAll();
      },

      handlePageChange(page) {
        this.page = page;
        this.fetchPosItems({ page });
      },

      async fetchPosItems({ page = this.page }) {
        try {
          await PosMenuItem.fetchPosItems({ page });
        }
        catch (error) {
          this.$_onRequestError({
            error,
            toastOptions: { message: 'There was an error getting your pos items' }
          });
        }
      }
    }
  };
</script>
