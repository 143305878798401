<template>
  <div class="pad has-background-white-bis has-border has-border-grey-lightest has-radius">
    <div class="is-flex justify-between align-center mar-b-lg">
      <div class="mar-r-lg">
        <p class="is-size-5">
          {{ maintenanceCode.displayName }}
        </p>
        <p class="subtitle is-7">{{ maintenanceCode.description }}</p>
      </div>
      <b-button
        outlined
        icon-left="trash-alt"
        type="is-danger is-light"
        @click="$emit('removed')"
      />
    </div>
    <validated-text-input
      v-model="value.title"
      name="title"
      label="Title"
      type="text"
      maxlength="255"
      label-position="on-border"
      rules="required"
    />
    <validated-text-input
      v-model="value.message"
      name="message"
      label="Message"
      type="textarea"
      maxlength="255"
      label-position="on-border"
      rules="required"
    />
    <validated-text-input
      v-model="value.url"
      name="url"
      label="Url"
      type="text"
      label-position="on-border"
      rules="required"
    />
  </div>
</template>

<script>
  import MaintenanceCode from '@/store/classes/MaintenanceCode';

  export default {
    name: 'MaintenanceMessage',

    props: {
      value: {
        type: Object,
        required: true
      }
    },

    computed: {
      maintenanceCode() {
        return MaintenanceCode.find(this.value.maintenanceCodeId);
      }
    }
  };
</script>
