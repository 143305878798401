<template>
  <validated-form
    ref="form"
    v-slot="{ errors }"
    auto-focus
    form-id="copyMenuItemForm"
    @valid-submit="copyMenuItem"
  >
    <modal-card title="Copy Menu Item" :subtitle="item.displayName">
      <b-message
        type="is-primary"
        class="is-compact has-shadow mar-b"
        has-icon
        icon="info-square"
      >
        This will create a brand new menu item in each of the selected categories, with all the same properties as the original
      </b-message>

      <validated-input
        name="categories"
        rules="required"
        label="Categories"
        sub-label="Select the categories you would like to copy this item to"
        hide-error-messages
      >
        <b-dropdown
          v-model="selectedCategoryIds"
          expanded
          inline
          multiple
          aria-role="list"
          :class="errors.categories && errors.categories.length && 'has-errors'"
        >
          <b-dropdown-item
            v-for="category in categories"
            :key="category.id"
            :value="category.id"
            aria-role="listitem"
          >
            {{ category.displayName }}
          </b-dropdown-item>
        </b-dropdown>
      </validated-input>

      <b-message
        v-if="errors.categories && errors.categories.length"
        type="is-danger"
        size="is-small"
        has-icon
        class="is-compact has-shadow"
      >
        <p class="animated animated-slow tdFadeInRight">
          You must select at least one category
        </p>
      </b-message>

      <template #footer>
        <div class="buttons is-right all-bold">
          <b-button
            rounded
            @click="$_confirmCloseModal({ programmatic: true })"
          >
            Cancel
          </b-button>
          <b-button
            v-tabbable
            rounded
            native-type="submit"
            type="is-primary"
            :loading="isSubmitting"
          >
            Copy
          </b-button>
        </div>
      </template>
    </modal-card>
  </validated-form>
</template>

<script>
  import confirmModalCloseMixin from '@/mixins/confirm-modal-close';
  import Item from '@/store/classes/Item';

  export default {
    name: 'CopyMenuItemModal',

    mixins: [confirmModalCloseMixin],

    props: {
      item: {
        type: Object,
        required: true
      },

      categories: {
        type: Array,
        required: true
      }
    },

    data() {
      return {
        selectedCategoryIds: []
      };
    },

    computed: {
      isSubmitting() {
        return Item.$state().submitting;
      }
    },

    methods: {
      async copyMenuItem() {
        try {
          const clonedItems = await Item.copyToCategories({ itemId: this.item.id, categoryIds: this.selectedCategoryIds });

          this.$_onRequestSuccess({
            toastOptions: {
              message: `Successfully copied <b>${this.item.displayName}</b>!`
            },
            options: {
              closeParent: true,
              emit: {
                name: 'item-clone-success',
                arg: {
                  itemIds: clonedItems.map(item => item.id),
                  categoryIds: this.selectedCategoryIds
                }
              }
            }
          });
        }
        catch (error) {
          this.$_onRequestError({
            toastOptions: {
              message: `An error occurred while copying ${this.item.displayName}`
            },
            error
          });
        }
      }
    }
  };
</script>

<style lang="sass" scoped>
  ::v-deep .modal-card-body
    max-width: 440px
</style>
