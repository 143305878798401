<template>
  <div v-if="store && tabs.length">
    <b-loading :is-full-page="false" :active="!store" />
    <secondary-sidebar
      v-slot="{ activeTab }"
      :tabs="tabs"
    >
      <component
        :is="getActiveTabComponent(activeTab)"
        v-bind="getActiveTabComponentProps(activeTab)"
      />
    </secondary-sidebar>
  </div>
</template>

<script>
  import posTypes from '@/constants/posTypes';
  import merchantMixin from '@/mixins/merchant';

  // store classes
  import MerchantPaymentGateway from '@/store/classes/MerchantPaymentGateway';
  import PaymentGateway from '@/store/classes/PaymentGateway';
  import PosConfiguration from '@/store/classes/PosConfiguration';
  import Store from '@/store/classes/Store';

  // components
  import storeCoreConfigurations from './store-core-configurations.vue';
  import storeLocationAttributes from './store-location-attributes.vue';
  import storeTenderTypes from './store-tender-types.vue';
  import storeReceiptLocations from './store-receipt-locations.vue';
  import storePaymentGateways from './store-payment-gateways.vue';
  import storePos from './store-pos.vue';
  import storePaymentMethods from './store-payment-methods.vue';
  import mappingStoreSublocations from './mapping-store-sublocations.vue';

  export default {
    name: 'StoreConfigurations',

    mixins: [merchantMixin],

    data() {
      return {
        posTypes
      };
    },

    computed: {
      store() {
        return Store.find(this.$route.params.storeId);
      },

      allPaymentGatewayOptions() {
        return PaymentGateway.all();
      },

      storePaymentGateways() {
        return MerchantPaymentGateway.query().where('storeId', this.store.storeId).get();
      },

      storePOS() {
        return PosConfiguration.query().where('storeId', this.store.storeId).get();
      },

      tabs() {
        return [
          {
            id: 'core-configurations',
            display: 'Core Configurations',
            component: storeCoreConfigurations,
            props: { store: this.store }
          },
          {
            id: 'location-attributes',
            display: 'Location Attributes',
            component: storeLocationAttributes,
            props: { store: this.store }
          },
          {
            id: 'tender-types',
            display: 'Tender Types',
            component: storeTenderTypes
          },
          {
            id: 'receipt-locations',
            display: 'Receipt Locations',
            component: storeReceiptLocations
          },
          {
            id: 'payment-gateways',
            display: 'Payment Gateways',
            component: storePaymentGateways,
            props: { store: this.store }
          },
          {
            id: 'pos',
            display: 'Point of Sale',
            component: storePos,
            props: { storeId: this.store.storeId }
          },
          {
            id: 'payment-methods',
            display: 'Payment Methods',
            component: storePaymentMethods,
            props: { storeId: this.store.storeId }
          },
          {
            id: 'sublocations',
            display: 'Sublocations',
            component: mappingStoreSublocations,
            isHidden: !this.$_hasPosType(posTypes.Cardfree),
            props: { store: this.store }
          }
        ].filter(tab => !tab.isHidden);
      }

    },

    methods: {
      async onMerchantLoad() {
        if (!this.allPaymentGatewayOptions.length) {
          await this.fetchPaymentGateways();
        }
      },

      async fetchPaymentGateways() {
        try {
          await PaymentGateway.fetchPaymentGateways();
        }
        catch (error) {
          this.$_onRequestError({
            toastOptions: { message: 'Unable to fetch available payment providers' },
            error
          });
        }
      },

      getActiveTabComponent(activeTab) {
        return this.tabs.find(t => t.id === activeTab)?.component;
      },

      getActiveTabComponentProps(activeTab) {
        return this.tabs.find(t => t.id === activeTab)?.props;
      }
    }
  };
</script>
