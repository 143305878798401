<template>
  <validated-form
    ref="form"
    v-slot="{}"
    form-id="merchantMenuTypesForm"
    @valid-submit="updateMenuTypes"
  >
    <sticky-save-container
      :saving="isSubmitting"
      :loading="isFetching"
      title="Menu Types"
    >
      <div class="is-grid col-min-300 gap-md pad-b-sm">
        <check-button
          v-for="menuType in allMenuTypes"
          :key="menuType.id"
          v-model="selectedMenuTypeIds"
          :label="menuType.displayName"
          :native-value="menuType.id"
        />
      </div>
    </sticky-save-container>
  </validated-form>
</template>

<script>
  import MerchantMenuTypeConfiguration from '@/store/classes/MerchantMenuTypeConfiguration';
  import MenuType from '@/store/classes/MenuType';
  import getChangedResources from '@/helpers/get-changed-resources';

  export default {
    name: 'MerchantMenuTypes',

    props: {
      merchantId: {
        type: Number,
        required: true
      }
    },

    data() {
      return {
        selectedMenuTypeIds: []
      };
    },

    computed: {
      isSubmitting() {
        return MerchantMenuTypeConfiguration.$state().submitting;
      },

      isFetching() {
        return MerchantMenuTypeConfiguration.$state().fetching;
      },

      allMenuTypes() {
        return MenuType.all();
      },

      merchantMenuTypeConfigurations() {
        return MerchantMenuTypeConfiguration.query().where('merchantId', this.merchantId).get();
      },

      defaultMenuTypeConfigurations() {
        return MerchantMenuTypeConfiguration.query().where('merchantId', 0).get();
      }
    },

    async created() {
      await this.onCreated();
    },

    methods: {
      async onCreated() {
        await Promise.all([
          this.fetchMerchantMenuTypes(),
          this.fetchMenuTypes()
        ]);
        const menuTypeConfigs = this.merchantMenuTypeConfigurations.length ? this.merchantMenuTypeConfigurations : this.defaultMenuTypeConfigurations;
        this.selectedMenuTypeIds = menuTypeConfigs.map(menuTypeConfig => menuTypeConfig.menuType.id);
      },

      async fetchMerchantMenuTypes() {
        try {
          await MerchantMenuTypeConfiguration.fetchMerchantMenuTypeConfigurations(this.merchantId);
        }

        catch (error) {
          this.$_onRequestError({
            error,
            toastOptions: {
              message: 'There was an issue fetching the merchant menu types'
            }
          });
        }
      },

      async fetchMenuTypes() {
        try {
          await MenuType.fetchMenuTypes();
        }

        catch (error) {
          this.$_onRequestError({
            error,
            toastOptions: {
              message: 'There was an issue fetching the menu types'
            }
          });
        }
      },

      async updateMenuTypes() {
        try {
          const { added, removed } = getChangedResources({ newArray: this.selectedMenuTypeIds, oldArray: this.merchantMenuTypeConfigurations.map(config => config.menuType.id) });
          const promises = [];

          if (added.length) {
            added.forEach((addedTypeId) => {
              promises.push(MerchantMenuTypeConfiguration.addMerchantMenuTypeConfiguration({ merchantId: this.merchantId, menuTypeId: addedTypeId }));
            });
          }
          if (removed.length) {
            removed.forEach((removedTypeId) => {
              const id = this.merchantMenuTypeConfigurations.find(config => config.menuType.id === removedTypeId).id;
              promises.push(MerchantMenuTypeConfiguration.removeMerchantMenuTypeConfiguration(id));
            });
          }
          await Promise.all(promises);

          this.$_onRequestSuccess({
            toastOptions: { message: 'Successfully updated menu types' }
          });
        }

        catch (error) {
          this.$_onRequestError({
            error,
            toastOptions: {
              message: 'There was an issue updating your menu types'
            }
          });
        }
      }
    }
  };
</script>
