import http from '@/services/http';

export default {
  namespaced: true,

  state: {
    features: {},
    isLoading: false
  },

  getters: {
    microsoftOauth2SsoEnabled(state) {
      return state.features.microsoftOauth2Sso;
    },

    microsoftOauth2ClientId(state) {
      return state.features.microsoftOauth2SsoClientId;
    }
  },

  actions: {
    async fetchFeatures({ commit }) {
      try {
        this.isLoading = true;
        const response = await http.get('/portal_system_features');
        commit('setFeatures', response.data.portalSystemFeatures);
      }
      catch (error) {
        // fail silently
        console.error('Error fetching portal system features:', error);
      }
      finally {
        this.isLoading = false;
      }
    }
  },

  mutations: {
    setFeatures(state, features) {
      state.features = features;
    }
  }
};
