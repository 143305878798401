<template>
  <div class="card">
    <div class="card-content">
      <validated-text-input
        :value="value.internalName"
        name="internalName"
        type="text"
        rules="required|max:255"
        :custom-messages="{ required: 'You must give your Campaign a name' }"
        label="Campaign Name"
        sub-label="Internal name used for searching and sorting your campaigns"
        @input="handleInput({ internalName: $event })"
      />
      <validated-text-input
        :value="value.description"
        name="description"
        type="textarea"
        maxlength="255"
        label="Description"
        sub-label="Internal description used for searching and sorting your campaigns — Optional"
        @input="handleInput({ description: $event })"
      />
      <div v-if="value.guid" class="mar-t-xs mar-b-md has-text-grey is-monospaced">
        ID: {{ value.guid }}
      </div>
      <div class="dist-y-sm">
        <div class="is-flex gap">
          <div>
            <label class="label is-relative dist-x-xs">
              <span>Notification Type</span>
              <b-icon
                v-tippy="{
                  content: 'How your campaign will be sent to the selected segment of customers',
                  placement: 'right',
                  maxWidth: 300,
                  delay: [150, 0]
                }"
                icon="info-square"
                size="is-small"
                type="is-grey"
              />
            </label>
          </div>
          <b-switch
            :value="notificationsEnabled"
            :disabled="readOnly"
            @input="$emit('set-notifications-enabled', $event);"
          />
        </div>
        <div class="is-grid col-3 gap-lg">
          <check-button
            v-model="selectedNotificationTypes"
            name="emailNotification"
            :native-value="notificationTypes.EMAIL"
            justify-between
            left-label
            class="is-marginless"
            icon="envelope"
            icon-pack="far"
          >
            Email
          </check-button>
          <div
            v-tippy="{
              content: 'Please reach out to a CardFree Admin to configure',
              onShow: () => !hasPushProvidersConfigured || !hasMobileAppConfigured
            }"
          >
            <check-button
              v-model="selectedNotificationTypes"
              name="pushNotification"
              :native-value="notificationTypes.PUSH"
              justify-between
              left-label
              class="is-marginless is-full-width"
              :disabled="!hasPushProvidersConfigured || !hasMobileAppConfigured"
              icon="bell-on"
              icon-pack="far"
            >
              Push Notification
            </check-button>
          </div>
          <div
            v-tippy="{
              content: 'Please reach out to a CardFree Admin to configure',
              onShow: () => !hasSmsConfigured
            }"
          >
            <check-button
              v-model="selectedNotificationTypes"
              name="smsNotification"
              :native-value="notificationTypes.SMS"
              justify-between
              left-label
              class="is-marginless is-full-width"
              :disabled="!hasSmsConfigured"
              icon="message"
              icon-pack="far"
            >
              Text Message
            </check-button>
          </div>
        </div>
      </div>
    </div>
    <div class="card-content">
      <validation-provider
        v-slot="{ errors }"
        :rules="{ required: !!value.willIncludeOffer }"
        name="offers"
        :custom-messages="{ required: 'You must select a finalized Offer within the timeframe of your campaign to be included with your Campaign' }"
      >
        <b-checkbox
          :class="['is-hidden', { 'invalid': errors.length }]"
          :value="value.campaignsOffers.length && offerIsValidForCampaign(getOffer(value.campaignsOffers[0].offerGuid)) || null"
        />
        <b-field
          :type="{ 'is-danger': !!errors.length }"
          :message="errors | capitalize"
        >
          <div class="dist-y-sm">
            <div class="is-flex gap">
              <div>
                <label class="label is-relative dist-x-xs">
                  <span>Add an Offer</span>
                  <b-icon
                    v-tippy="{
                      content: 'The type of discount or reward a customer will receive from this campaign',
                      placement: 'right',
                      maxWidth: 300,
                      delay: [150, 0]
                    }"
                    icon="info-square"
                    size="is-small"
                    type="is-grey"
                  />
                  <b-icon
                    v-if="!!errors.length"
                    icon="exclamation-circle"
                    size="is-small"
                    type="is-danger"
                  />
                </label>
              </div>
              <b-switch
                v-model="willIncludeOffer"
                :disabled="readOnly || !notificationsEnabled"
              />
            </div>
            <template v-if="value.campaignsOffers.length">
              <div v-for="offer in value.campaignsOffers" :key="offer.offerGuid">
                <b-message
                  v-if="getOffer(offer.offerGuid).state !== offerStates.LIVE && !readOnly"
                  type="is-warning"
                  size="is-small"
                  has-icon
                  class="is-compact has-shadow mar-t-xs is-inline-block"
                >
                  The selected offer is not in a <b>Ready</b> state. <br>
                  You will not be able to schedule your campaign until the selected offer is finalized.
                </b-message>
                <b-message
                  v-if="!offerIsValidForTimeFrame(getOffer(value.campaignsOffers[0].offerGuid)) && !readOnly && value.startDate"
                  type="is-warning"
                  size="is-small"
                  has-icon
                  class="is-compact has-shadow mar-t-xs is-inline-block"
                >
                  The selected offer is not valid within the timeframe of the campaign
                </b-message>
                <div class="is-flex align-center">
                  <offer-preview style="max-width: 500px" :offer="getOffer(offer.offerGuid)" />
                  <b-button
                    v-if="!readOnly"
                    class="mar-l-lg"
                    icon-left="minus"
                    outlined
                    size="is-small"
                    type="is-danger is-light"
                    @click="removeOffer(offer.offerGuid)"
                  />
                </div>
              </div>
            </template>
            <b-button
              v-else
              :disabled="!value.willIncludeOffer || !allOffers.length"
              type="is-primary is-light"
              icon-left="plus"
              @click="openAddOfferModal"
            >
              Offer
            </b-button>
          </div>
        </b-field>
      </validation-provider>

      <b-message
        v-if="!allOffers.length"
        type="is-warning"
        class="is-compact has-shadow is-inline-block"
      >
        <div class="is-flex align-center">
          <b-icon icon="exclamation-triangle" size="is-medium" />
          <span class="mar-l-sm">There are no Offers configured for this merchant</span>
        </div>
      </b-message>
    </div>
    <div class="card-content is-flex dist-x-xl is-flex-wrap">
      <validated-input
        label="Schedule"
        name="deliveryType"
        rules="required"
        tooltip="When your campaign will be sent to the selected segment of customers"
        tooltip-placement="right"
        :custom-messages="{ required: 'You must select when this Campaign will be sent' }"
      >
        <radio-button
          ref="immediateDelivery"
          v-model="value.deliveryType"
          name="deliveryType"
          :native-value="campaignDeliveryTypes.IMMEDIATE"
          @input="handleImmediateOrScheduledTrigger(campaignDeliveryTypes.IMMEDIATE)"
        >
          Send Now
        </radio-button>
        <radio-button
          ref="scheduledDelivery"
          v-model="value.deliveryType"
          name="deliveryType"
          :native-value="campaignDeliveryTypes.SCHEDULED"
          @input="handleImmediateOrScheduledTrigger(campaignDeliveryTypes.SCHEDULED)"
        >
          Schedule
        </radio-button>
        <radio-button
          v-if="showEventTriggerSelection"
          ref="eventTriggerDelivery"
          v-model="value.deliveryType"
          name="deliveryType"
          :native-value="campaignDeliveryTypes.EVENT"
          @input="handleEventTriggerInput"
        >
          Event Trigger
        </radio-button>
        <radio-button
          ref="recurringTriggerDelivery"
          v-model="value.deliveryType"
          name="deliveryType"
          :native-value="campaignDeliveryTypes.RECURRING"
          @input="handleRecurringTriggerInput"
        >
          Recurring
        </radio-button>
      </validated-input>

      <transition enter-active-class="animated tdFadeInRight" leave-active-class="animated tdShrinkOut" mode="out-in">
        <div v-if="value.deliveryType === campaignDeliveryTypes.SCHEDULED">
          <validated-input
            :label="`Start Date (${timeZoneAbbr})`"
            name="startDate"
            rules="required"
          >
            <b-datetimepicker
              :value="value.startDate ? adjustDate(value.startDate, timeZoneOffset) : null"
              inline
              :min-datetime="adjustDate(today, timeZoneOffset)"
              :nearby-selectable-month-days="true"
              :mobile-native="false"
              @input="handleInput({ startDate: adjustDate($event, timeZoneOffset, true).toISOString() })"
            />
          </validated-input>
        </div>

        <div v-if="value.deliveryType === campaignDeliveryTypes.EVENT">
          <div class="is-flex dist-x-xl">
            <validated-input
              :label="`Start Date (${timeZoneAbbr})`"
              name="eventTriggerStartDate"
              rules="required"
            >
              <b-datetimepicker
                :value="value.startDate ? adjustDate(value.startDate, timeZoneOffset) : null"
                inline
                :min-datetime="adjustDate(moment(today).add(5, 'minute'), timeZoneOffset)"
                :nearby-selectable-month-days="true"
                :mobile-native="false"
                @input="handleInput({ startDate: adjustDate($event, timeZoneOffset, true).toISOString() })"
              />
            </validated-input>
            <validated-input
              :label="`End Date (${timeZoneAbbr})`"
              name="endDate"
              rules="required|dateTimeIsAfter:@eventTriggerStartDate"
            >
              <b-datetimepicker
                :value="value.endDate ? adjustDate(value.endDate, timeZoneOffset) : null"
                inline
                :min-datetime="adjustDate(moment(value.startDate).add(1, 'minute'), timeZoneOffset)"
                :nearby-selectable-month-days="true"
                :mobile-native="false"
                @input="handleInput({ endDate: adjustDate($event, timeZoneOffset, true).toISOString() })"
              />
            </validated-input>

            <validated-input
              label="Event"
              name="eventTrigger"
              :rules="{
                required: value.deliveryType === campaignDeliveryTypes.EVENT
              }"
              :custom-messages="{ required: 'You must select a event to send your Campaign with' }"
            >
              <b-checkbox :value="value.eventType" class="is-hidden" />
              <div class="is-flex-column align-start dist-y-sm">
                <template v-if="value.eventType">
                  <div class="is-flex align-center">
                    <p>{{ eventTypes[value.eventType].name }}</p>
                    <b-button
                      v-if="!readOnly"
                      class="mar-l"
                      icon-left="minus"
                      outlined
                      size="is-small"
                      type="is-danger is-light"
                      @click="removeEventType()"
                    />
                  </div>
                </template>

                <b-button
                  v-else
                  type="is-primary is-light"
                  icon-left="plus"
                  @click="openAddEventTypeModal"
                >
                  Event
                </b-button>

                <b-checkbox
                  :value="value.allowRedelivery"
                  size="is-small"
                  class="mar-t-xs"
                  name="allowRedelivery"
                  @input="handleInput({ allowRedelivery: $event })"
                >
                  Allow multiple deliveries
                  <b-icon
                    v-tippy="{
                      content: 'Allow each guest to receive this campaign and any associated offer multiple times',
                      maxWidth: 300,
                      delay: [150, 0]
                    }"
                    class="mar-b-none pad-b-none"
                    icon="info-square"
                    size="is-small"
                    type="is-grey"
                  />
                </b-checkbox>

                <validated-text-input
                  v-if="isGiftCardEvent(value.eventType)"
                  v-model="minimumGiftCardAmount"
                  label="Minimum Purchase Amount"
                  name="minimumGiftCardAmount"
                  type="dollars"
                  class="mar-t-sm"
                  has-icon
                  rules="min_value:0"
                  :mask-options="{ numeralDecimalScale: 2, numeralPositiveOnly: true }"
                  :disabled="readOnly"
                />
              </div>
            </validated-input>
          </div>
          <validation-provider
            v-slot="{ errors }"
            name="startDateError"
            :rules="{ required: value.startDate && !isStartDateInTheFuture }"
          >
            <b-checkbox :value="value.startDate && isStartDateInTheFuture || null" :class="['is-hidden', { 'invalid': errors.length }]" />
            <b-message
              v-if="value.startDate && !isStartDateInTheFuture && value.state === campaignStates.DRAFT"
              key="timeframeInvalid"
              type="is-danger"
              size="is-small"
              class="is-compact has-shadow justify-self-start"
            >
              <p class="is-flex align-center">
                <b-icon custom-size="fa-lg" icon="exclamation-circle" type="is-danger" class="mar-r-xs" />
                Start date must be at least 10 minutes in the future
              </p>
            </b-message>
          </validation-provider>
        </div>

        <div v-if="value.deliveryType === campaignDeliveryTypes.RECURRING">
          <b-message
            type="is-primary"
            size="is-small"
            class="is-compact has-shadow justify-self-start"
          >
            Recurring campaigns are sent daily to the guests that fit the segmentation criteria that day. Each guest will only receive a recurring campaign once.
          </b-message>
          <div class="is-flex dist-x-xl">
            <validated-input
              :label="`Start Date (${timeZoneAbbr})`"
              name="recurringStartDate"
              rules="required"
            >
              <b-datetimepicker
                :value="value.startDate ? adjustDate(value.startDate, timeZoneOffset) : null"
                inline
                :min-datetime="adjustDate(moment(today).add(5, 'minute'), timeZoneOffset)"
                :nearby-selectable-month-days="true"
                :mobile-native="false"
                @input="handleInput({ startDate: adjustDate($event, timeZoneOffset, true).toISOString() })"
              />
            </validated-input>
            <validated-input
              :label="`End Date (${timeZoneAbbr})`"
              name="endDate"
              rules="required|dateTimeIsAfter:@recurringStartDate"
            >
              <b-datetimepicker
                :value="value.endDate ? adjustDate(value.endDate, timeZoneOffset) : null"
                inline
                :min-datetime="adjustDate(moment(value.startDate).add(1, 'minute'), timeZoneOffset)"
                :nearby-selectable-month-days="true"
                :mobile-native="false"
                @input="handleInput({ endDate: adjustDate($event, timeZoneOffset, true).toISOString() })"
              />
            </validated-input>

            <div
              key="recurringSendTime"
              data-test-id="recurring-send-time"
              class="is-flex-start-aligned"
            >
              <validated-input
                rules="required"
                name="sendTime"
                :label="`Send Time (${$_selectedMerchant.campaignsIanaTimezoneString})`"
              >
                <b-timepicker
                  v-model="sendTime"
                  size="is-small"
                  :default-minutes="0"
                  hour-format="12"
                  inline
                />
              </validated-input>
            </div>
          </div>
          <validation-provider
            v-slot="{ errors }"
            name="startDateError"
            :rules="{ required: value.startDate && !isStartDateInTheFuture }"
          >
            <b-checkbox :value="value.startDate && isStartDateInTheFuture || null" :class="['is-hidden', { 'invalid': errors.length }]" />
            <b-message
              v-if="value.startDate && !isStartDateInTheFuture && value.state === campaignStates.DRAFT"
              key="timeframeInvalid"
              type="is-danger"
              size="is-small"
              class="is-compact has-shadow justify-self-start"
            >
              <p class="is-flex align-center">
                <b-icon custom-size="fa-lg" icon="exclamation-circle" type="is-danger" class="mar-r-xs" />
                Start date must be at least 10 minutes in the future
              </p>
            </b-message>
          </validation-provider>
        </div>
      </transition>
    </div>
    <div class="card-content">
      <validated-input
        :disabled="value.deliveryType === campaignDeliveryTypes.EVENT"
        label="Target Audience"
        name="targetAudience"
        :rules="{
          required: value.deliveryType !== campaignDeliveryTypes.EVENT
        }"
        tooltip="Choose the group of customers you want to target when sending out a campaign"
        tooltip-placement="right"
        :custom-messages="{ required: 'You must select a Segmentation to send your Campaign to' }"
      >
        <b-checkbox :value="value.segmentation" class="is-hidden" />
        <div class="is-flex-column align-start">
          <template v-if="value.segmentation">
            <div class="is-flex align-center">
              <p>{{ value.segmentation.name || 'NO NAME' }}</p>
              <b-button
                v-if="!readOnly"
                class="mar-l"
                icon-left="minus"
                outlined
                size="is-small"
                type="is-danger is-light"
                @click="removeSegmentation()"
              />
            </div>
            <campaign-segmentation-details
              class="mar-t"
              :campaign="value"
              :has-push-notifications-configured="hasPushProvidersConfigured && hasMobileAppConfigured"
              :has-sms-configured="hasSmsConfigured"
            />
          </template>

          <b-button
            v-else-if="allSegmentations.length && value.deliveryType !== campaignDeliveryTypes.EVENT"
            type="is-primary is-light"
            icon-left="plus"
            @click="openAddSegmentationModal"
          >
            Segmentation
          </b-button>

          <b-message
            v-else-if="!allSegmentations.length && value.deliveryType !== campaignDeliveryTypes.EVENT"
            type="is-warning"
            has-icon
            class="is-compact has-shadow mar-t-xs"
          >
            There are no Segmentations configured for this merchant
          </b-message>

          <b-message
            v-else-if="value.deliveryType === campaignDeliveryTypes.EVENT"
            type="is-warning"
            has-icon
            class="is-compact has-shadow mar-t-sm"
          >
            A segmentation is not needed for an event-triggered campaign
          </b-message>
        </div>
      </validated-input>
    </div>
  </div>
</template>

<script>
  import moment from 'moment-timezone';
  import merchantMixin from '@/mixins/merchant';

  import Campaign from '@/store/classes/Campaign';
  import CampaignsOffer from '@/store/classes/CampaignsOffer';
  import Offer from '@/store/classes/Offer';
  import Segmentation from '@/store/classes/Segmentation';
  import MerchantLoyaltyProviderConfiguration from '@/store/classes/MerchantLoyaltyProviderConfiguration';

  import deepLinkIds from '@/constants/deepLinkIds';
  import { notificationTypes, eventTypes, campaignDeliveryTypes, campaignStates } from '@/constants/campaigns';
  import { offerStates } from '@/constants/offers';

  import alertModal from '@/components/globals/alert-modal.vue';
  import offerPreview from '../components/offer-preview.vue';
  import searchSelectModal from '@/components/globals/search-select-modal.vue';
  import campaignSegmentationDetails from './campaign-segmentation-details.vue';

  export default {
    name: 'CampaignConfiguration',

    components: { offerPreview, campaignSegmentationDetails },

    mixins: [merchantMixin],

    props: {
      value: {
        type: Object,
        required: true
      },

      readOnly: {
        type: Boolean,
        required: true
      },

      hasPushProvidersConfigured: {
        type: Boolean,
        default: false
      },

      hasMobileAppConfigured: {
        type: Boolean,
        default: false
      },

      hasSmsConfigured: {
        type: Boolean,
        default: false
      },

      notificationsEnabled: {
        type: Boolean,
        default: true
      },

      originalEmailTemplateId: {
        type: Number,
        default: undefined
      }
    },

    data() {
      return {
        offerStates,
        moment,
        intervalId: null,
        today: new Date(),
        notificationTypes,
        eventTypes,
        campaignDeliveryTypes,
        campaignStates
      };
    },

    computed: {
      selectedNotificationTypes: {
        get() {
          const selectedTypes = [];
          if (this.value.emailTemplates?.length) {
            selectedTypes.push(notificationTypes.EMAIL);
          }
          if (this.value.pushNotification) {
            selectedTypes.push(notificationTypes.PUSH);
          }
          if (this.value.smsNotification) {
            selectedTypes.push(notificationTypes.SMS);
          }
          return selectedTypes;
        },

        set(selectedTypes) {
          const newValues = {};
          newValues.emailTemplates = this.buildEmailTemplates(selectedTypes.includes(notificationTypes.EMAIL));
          newValues.pushNotification = this.buildPushNotification(selectedTypes.includes(notificationTypes.PUSH));
          newValues.smsNotification = this.buildSmsNotification(selectedTypes.includes(notificationTypes.SMS));

          this.$emit('set-notifications-enabled', !!selectedTypes.length);
          this.$emit('input', { ...this.value, ...newValues });
        }
      },

      timeZoneOffset() { // TEST - fix test so static and not dependent on actual offset
        const merchantUtcOffset = moment.tz(moment(), this.$_selectedMerchant.features.campaignsIanaTimezoneId).utcOffset();
        const localUtcOffset = moment().utcOffset();
        return merchantUtcOffset - localUtcOffset;
      },

      timeZoneAbbr() { // TEST - fix test so static and not dependent on actual timezone
        return moment.tz.zone(this.$_selectedMerchant.features.campaignsIanaTimezoneId).abbr(Date.now());
      },

      allSegmentations() {
        return Segmentation.query().orderBy('name').get();
      },

      allOffers() {
        return Offer.query()
          .orderBy('state', 'desc')
          .orderBy(({ name }) => name.toLowerCase())
          .get();
      },

      emailTemplateOptions() {
        return Campaign.$state().emailTemplateOptions;
      },

      selectedTemplateRequiresOffer() {
        const selectedTemplate = this.emailTemplateOptions
          .find(template => template.externalGuid === this.value?.emailTemplates[this.value.emailTemplates.length - 1]?.templateId);
        return !selectedTemplate?.jsonSchema.properties.imageUrl;
      },

      willIncludeOffer: {
        get() {
          return this.value.willIncludeOffer;
        },

        set(value) {
          this.handleWillIncludeOfferChange(value);
        }
      },

      merchantLoyaltyProviderConfiguration() {
        return MerchantLoyaltyProviderConfiguration.query().where('isActive', true).first();
      },

      showEventTriggerSelection() {
        return this.$_selectedMerchant?.merchantGiftCardConfigurations?.length || !!this.merchantLoyaltyProviderConfiguration;
      },

      isStartDateInTheFuture() {
        return moment(this.value.startDate).isAfter(moment(this.today).add(10, 'minute'));
      },

      sendTime: {
        get() {
          return this.value?.recurringCampaignConfiguration
          ? moment(this.value.recurringCampaignConfiguration.sendTime, 'HH:mm').toDate()
          : null;
        },
        set(value) {
          this.$emit('input', {
            ...this.value,
            recurringCampaignConfiguration: {
              ...this.value.recurringCampaignConfiguration,
              sendTime: moment(value).format('HH:mm')
            }
          });
        }
      },

      minimumGiftCardAmount: {
        get() {
          return this.value.minimumGiftCardAmount ? Number(this.value.minimumGiftCardAmount) : null;
        },
        set(newValue) {
          const numericValue = newValue ? Number(newValue) : null;
          this.handleInput({ minimumGiftCardAmount: numericValue });
        }
      }
    },

    watch: {
      notificationsEnabled: {
        handler(value) {
          if (!value) {
            this.$emit('input', {
              ...this.value,
              emailTemplates: [],
              pushNotification: null,
              smsNotification: null
            });
          }
        }
      }
    },

    mounted() { // TEST?
      const self = this;
      this.intervalId = setInterval(() => {
        self.today = new Date();
      }, 60 * 1000);
    },

    destroyed() {
      window.clearInterval(this.intervalId);
    },

    methods: {
      handleInput(payload) {
        this.$emit('input', { ...this.value, ...payload });
      },

      handleNotificationsEnabledChange(notificationsEnabled) {
        this.$emit('set-notifications-enabled', notificationsEnabled);
        if (notificationsEnabled && !this.willIncludeOffer) {
          this.willIncludeOffer = true;
        }
      },

      buildEmailTemplates(isSelected) {
        let emailTemplates = this.value.emailTemplates;
        if (isSelected && !emailTemplates.length) {
          emailTemplates = [{
            id: this.originalEmailTemplateId,
            subject: '',
            templateId: '',
            contentJson: '{}'
          }];
        }
        else if (!isSelected && emailTemplates.length) {
          emailTemplates = [];
        }
        return emailTemplates;
      },

      buildPushNotification(isSelected) {
        let pushNotification = this.value.pushNotification;
        if (isSelected && !pushNotification) {
          pushNotification = {
            title: '',
            message: '',
            notificationLinkId: deepLinkIds.MENU
          };
        }
        else if (!isSelected && pushNotification) {
          pushNotification = null;
        }
        return pushNotification;
      },

      buildSmsNotification(isSelected) {
        let smsNotification = this.value.smsNotification;
        if (isSelected && !smsNotification) {
          smsNotification = {
            message: ''
          };
        }
        else if (!isSelected && smsNotification) {
          smsNotification = null;
        }
        return smsNotification;
      },

      handleImmediateOrScheduledTrigger(deliveryType) {
        this.$emit('input', {
          ...this.value,
          deliveryType,
          startDate: deliveryType === campaignDeliveryTypes.IMMEDIATE ? null : this.value.startDate,
          recurringCampaignConfiguration: null
        });
      },

      handleRecurringTriggerInput() {
        this.$emit('input', {
          ...this.value,
          deliveryType: campaignDeliveryTypes.RECURRING,
          allowRedelivery: false,
          recurringCampaignConfiguration: {
            sendTime: null
          }
        });
      },

      handleEventTriggerInput() {
        this.$emit('input', {
          ...this.value,
          deliveryType: campaignDeliveryTypes.EVENT,
          segmentation: null,
          recurringCampaignConfiguration: null
        });
      },

      getOffer(offerGuid) {
        return this.allOffers.find(offer => offer.guid === offerGuid);
      },

      getSegmentation(segmentationId) {
        return this.allSegmentations.find(segmentation => segmentation.id === segmentationId);
      },

      offerIsValidForTimeFrame(offer) {
        const offerStartDate = offer.redemptionsAllowedStartDate;
        const offerEndDate = offer.redemptionsAllowedEndDate;
        const campaignStartDate = this.value.startDate || new Date(); // startDate is null when 'immediate' campaign
        const campaignEndDate = this.value.endDate;

        const isOfferStartValid = moment(offerStartDate).isSameOrBefore(this.adjustDateMoment(campaignStartDate));
        const isOfferEndValid = offerEndDate ? moment(offerEndDate).isSameOrAfter(this.adjustDateMoment(campaignStartDate)) : true;
        const isOfferAndCampaignEndValid = offerEndDate && campaignEndDate ? moment(offerEndDate).isSameOrAfter(this.adjustDateMoment(campaignEndDate)) : true;

        return isOfferStartValid && isOfferEndValid && isOfferAndCampaignEndValid;
      },

      offerIsValidForCampaign(offer) {
        if (offer.state !== offerStates.LIVE) return false;
        return this.offerIsValidForTimeFrame(offer);
      },

      isGiftCardEvent(eventTypeValue) {
        const giftCardEvents = [
          eventTypes.GiftCardSentAsGift,
          eventTypes.ReloadGiftCard,
          eventTypes.PurchaseGiftCard
        ].map(event => event.value);
        return giftCardEvents.includes(eventTypeValue);
      },

      removeEventType() {
        this.handleInput({
          eventType: null,
          minimumGiftCardAmount: null
        });
      },

      shouldShowEventType(eventType) {
        switch (eventType.id) {
          case 'PostOrderLoyaltyPointEarn':
          case 'PostOfferRedemption':
          case 'CustomerCheckIn':
            return !!this.merchantLoyaltyProviderConfiguration;
          case 'PurchaseGiftCard':
          case 'ReloadGiftCard':
          case 'GiftCardSentAsGift':
            return !!this.$_selectedMerchant?.merchantGiftCardConfigurations?.length;
          default:
            return false;
        }
      },

      openAddEventTypeModal() {
        const data = Object.values(eventTypes)
          .map(et => ({
            id: et.value,
            name: et.name,
            description: et.description
          }))
          .filter(this.shouldShowEventType);

        this.$buefy.modal.open({
          parent: this,
          component: searchSelectModal,
          hasModalCard: true,
          trapFocus: true,
          canCancel: false,
          customClass: 'auto-width',
          props: {
            title: 'Add Event To The Campaign',
            subtitle: 'Select an event to act as a trigger to define when a guest should receive a campaign',
            confirmText: 'Add',
            placeholderText: 'Search for an event type...',
            data,
            isSingleSelection: true,
            onSubmit: this.addEventType
          }
        });
      },

      openAddOfferModal() {
        const offerList = this.allOffers
          .filter(offer => [offerStates.DRAFT, offerStates.LIVE].includes(offer.state))
          .map((offer) => {
            const { campaignsIanaTimezoneId } = this.$_selectedMerchant.features;
            const formatDate = date => moment.tz(date, campaignsIanaTimezoneId).format('MMM DD, YYYY');
            const isFuture = moment().isBefore(offer.redemptionsAllowedStartDate);

            let footer = 'Redemptions ';

            if (offer.redemptionsAllowedEndDate) {
              footer += 'allowed ';
              footer += `${isFuture ? `from ${formatDate(offer.redemptionsAllowedStartDate)} through` : 'until'} ${formatDate(offer.redemptionsAllowedEndDate)}`;
            }
            else {
              footer += `${isFuture ? 'begin' : 'began'} ${formatDate(offer.redemptionsAllowedStartDate)}`;
            }

            return {
              description: offer.description,
              footer,
              icon: offer.state === offerStates.LIVE && 'check-circle',
              iconType: offer.state === offerStates.LIVE && 'is-success',
              id: offer.guid,
              name: offer.name
            };
          });

        this.$buefy.modal.open({
          parent: this,
          component: searchSelectModal,
          hasModalCard: true,
          trapFocus: true,
          canCancel: false,
          props: {
            title: 'Add Offer To The Campaign',
            subtitle: 'Select an offer to send with your campaign',
            confirmText: 'Add',
            placeholderText: 'Search for an offer...',
            data: offerList,
            isSingleSelection: true,
            onSubmit: this.addOffer
          }
        });
      },

      openAddSegmentationModal() {
        const segmentationList = this.allSegmentations.map(s => ({
          id: s.id,
          name: s.name || 'NO NAME'
        }));
        this.$buefy.modal.open({
          parent: this,
          component: searchSelectModal,
          hasModalCard: true,
          trapFocus: true,
          canCancel: false,
          customClass: 'auto-width',
          props: {
            title: 'Add Segmentation To The Campaign',
            subtitle: 'Select a segmentation to define a groups of users to target',
            confirmText: 'Add',
            placeholderText: 'Search for an segmentation...',
            data: segmentationList,
            isSingleSelection: true,
            onSubmit: this.addSegmentation
          }
        });
      },

      addEventType(eventType) {
        this.handleInput({ eventType });
      },

      addOffer(offerGuid) {
        const offer = new CampaignsOffer({ offerGuid });
        const updatedOffers = [...this.$clone(this.value.campaignsOffers), offer];
        this.handleInput({ campaignsOffers: updatedOffers });
      },

      addSegmentation(segmentationId) {
        this.handleInput({ segmentation: this.getSegmentation(segmentationId) });
      },

      removeOffer(offerGuid) {
        const filteredOffers = this.value.campaignsOffers.filter(offer => offer.offerGuid !== offerGuid);
        this.handleInput({ campaignsOffers: filteredOffers });
      },

      removeSegmentation() {
        this.handleInput({ segmentation: null });
      },

      adjustDate(date, offsetMinutes, reverse) { // TEST
        const _offsetMinutes = reverse ? offsetMinutes * -1 : offsetMinutes;
        return moment(date).add(_offsetMinutes, 'minutes').toDate();
      },

      adjustDateMoment(date, offsetMinutes, reverse) { // TEST
        const _offsetMinutes = reverse ? offsetMinutes * -1 : offsetMinutes;
        return moment(date, 'YYYY-MM-DD').add(_offsetMinutes, 'minutes');
      },

      handleWillIncludeOfferChange(willIncludeOffer) {
        const existingEmailTemplateId = this.value.emailTemplates?.[0]?.templateId;
        if (existingEmailTemplateId) {
          this.handleInput({ willIncludeOffer });
          const corePrompt = willIncludeOffer ? 'add an offer to' : 'remove the offer from';
          const message = `Are you sure you want to ${corePrompt} this campaign? Available template options will change and content entered will be lost.`;
          this.$buefy.modal.open({
            parent: this,
            component: alertModal,
            hasModalCard: true,
            canCancel: ['escape', 'outside'],
            props: {
              title: `${willIncludeOffer ? 'Add' : 'Remove'} Offer`,
              message,
              type: 'is-warning',
              horizontal: true,
              showCloseButton: false,
              forceClose: true,
              buttons: [
                {
                  text: 'No',
                  onClick: () => this.handleInput({ willIncludeOffer: !willIncludeOffer })
                },
                {
                  text: 'Yes',
                  primary: true,
                  onClick: () => this.updateOffersState(willIncludeOffer)
                }
              ]
            }
          });
        }
        else {
          this.updateOffersState(willIncludeOffer);
        }
      },

      updateOffersState(willIncludeOffer) {
        const notificationLinkId = !willIncludeOffer ? deepLinkIds.MENU : null;
        const emailTemplates = this.value.emailTemplates.length
          ? [{ id: this.originalEmailTemplateId, subject: '', templateId: '', contentJson: '{}' }]
          : [];
        const pushNotification = this.value.pushNotification
          ? { ...this.value.pushNotification, notificationLinkId }
          : null;
        this.$emit('input', {
          ...this.value,
          willIncludeOffer,
          campaignsOffers: [],
          emailTemplates,
          pushNotification
        });
      }
    }
  };
</script>

<style lang="sass" scoped>
  .card-content:nth-child(even)
    border-top: 1px solid $grey-lightest
    background-color: $white-ter !important

    &:not(:last-child)
      border-bottom: 1px solid $grey-lightest
</style>
