<template>
  <steps-modal
    v-model="form"
    title="In-App Message Builder"
    :steps="steps"
    :mode="mode"
    :read-only="!isInAppMessageEditable"
    :message="!isInAppMessageEditable ? 'In-App Messages can no longer be edited once they have expired' : ''"
    confirm-close
    @valid-submit="confirmSaveInAppMessage"
    @close="$parent.close()"
  >
    <!-- content -->
    <template #submit-button>
      <b-button
        type="is-primary"
        icon-right="check"
        rounded
        native-type="submit"
        :disabled="!isInAppMessageEditable"
      >
        Save
      </b-button>
    </template>
  </steps-modal>
</template>

<script>
  import moment from 'moment-timezone';

  import merchantMixin from '@/mixins/merchant';

  import AlertModal from '@/components/globals/alert-modal.vue';

  import { statuses } from '@/constants/merchantInAppMessages';

  import Merchant from '@/store/classes/Merchant';
  import MerchantInAppMessage from '@/store/classes/MerchantInAppMessage';

  import ConfigurationStep from './modal-steps/configuration-step.vue';
  import ContentStep from './modal-steps/content-step.vue';

  export default {
    name: 'InAppMessageModal',

    mixins: [merchantMixin],

    props: {
      inAppMessage: {
        type: Object,
        required: true
      },

      merchantHasMobileAppConfigured: {
        type: Boolean,
        default: false
      }
    },

    data() {
      return {
        form: {},
        newPrimaryImageFile: null
      };
    },

    computed: {
      isInAppMessageEditable() {
        return this.inAppMessage?.status !== statuses.EXPIRED;
      },

      mode() {
        switch (this.inAppMessage?.status) {
          case statuses.LIVE:
          case statuses.SCHEDULED:
            return 'update';
          case statuses.EXPIRED:
            return 'read';
          default:
            return 'create';
        }
      },

      steps() {
        return [
          {
            label: 'Configuration',
            component: ConfigurationStep,
            props: { merchantHasMobileAppConfigured: this.merchantHasMobileAppConfigured }
          },
          {
            label: 'Content',
            component: ContentStep,
            handlers: {
              'image-change': (imageFile) => {
                this.newPrimaryImageFile = imageFile;
              }
            }
          }
        ];
      }
    },

    created() {
      this.onCreated();
    },

    methods: {
      async onCreated() {
        if (this.inAppMessage?.id) {
          this.form = { ...this.$clone(this.inAppMessage) };
        }
        else {
          const startDate = moment(Date.now()).format('YYYY-MM-DD');
          const newInAppMessage = new MerchantInAppMessage({ startDate });
          this.form = { ...newInAppMessage };
        }
        await Merchant.fetchDeepLinks(this.$_selectedMerchant.id, 'in_app_messages');
      },

      confirmSaveInAppMessage() {
        const isDraft = !this.form.publicId;
        const isPublished = this.form.isPublished;
        const message = isDraft
          ? 'This action will create a new In-App Message.'
          : 'This action will update an existing In-App Message.';
        this.$buefy.modal.open({
          parent: this,
          component: AlertModal,
          trapFocus: true,
          canCancel: ['escape', 'outside'],
          customClass: 'auto-width',
          hasModalCard: true,
          props: {
            autoWidth: true,
            title: `${this.form.publicId ? 'Update' : 'Create'} In-App Message`,
            message,
            secondaryMessage: 'How would you like to proceed?',
            horizontal: true,
            showCloseButton: false,
            buttons: [
              { text: 'Cancel' },
              {
                text: `Save & ${isPublished ? 'Unpublish' : 'Keep Unpublished'}`,
                secondary: true,
                icon: 'message-slash',
                iconPack: 'fa',
                onClick: () => this.saveInAppMessage({ isPublished: false })
              },
              {
                text: `Save & ${isPublished ? 'Keep Published' : 'Publish'}`,
                primary: true,
                icon: 'message',
                iconPack: 'fa',
                onClick: () => this.saveInAppMessage({ isPublished: true })
              }
            ]
          }
        });
      },

      async saveInAppMessage({ isPublished }) {
        this.form.merchantId = this.$_selectedMerchant.id;
        this.form.isPublished = isPublished;

        const imageHasNotChanged = this.inAppMessage.id && this.form.primaryImageUrl === this.inAppMessage.primaryImageUrl;
        if (this.newPrimaryImageFile) {
          const newUrl = await this.createInAppMessageImage(this.newPrimaryImageFile);
          this.form.primaryImageUrl = newUrl;
        }
        else if (imageHasNotChanged) {
          delete this.form.primaryImageUrl;
        }

        if (this.form.publicId) {
          await this.updateInAppMessage();
        }
        else {
          await this.createInAppMessage();
        }
      },

      async updateInAppMessage() {
        try {
          await MerchantInAppMessage.updateMerchantInAppMessage(this.form);

          this.$_onRequestSuccess({
            toastOptions: {
              message: 'Successfully updated your in-app message!'
            },
            options: { closeParent: true }
          });
        }

        catch (error) {
          this.$_onRequestError({
            toastOptions: {
              message: 'There was an error updating your in-app message'
            },
            error
          });
          throw error;
        }
      },

      async createInAppMessage() {
        try {
          await MerchantInAppMessage.createMerchantInAppMessage(this.form);

          this.$_onRequestSuccess({
            toastOptions: {
              message: 'Successfully created your in-app message!'
            },
            options: { closeParent: true }
          });
        }

        catch (error) {
          this.$_onRequestError({
            toastOptions: {
              message: 'There was an error creating your in-app message'
            },
            error
          });
          throw error;
        }
      },

      async createInAppMessageImage(file) {
        try {
          return await MerchantInAppMessage.createMerchantInAppMessageImage({
            merchantId: this.$_selectedMerchant.id,
            file
          });
        }

        catch (error) {
          this.$_onRequestError({
            toastOptions: {
              message: 'There was an error creating your in-app message image file'
            },
            error
          });
        }
      }
    }
  };
</script>
