/* eslint-disable import/no-cycle */
import { Model } from '@vuex-orm/core';

import http from '@/services/http';



export default class MerchantGiftCardThresholdAmount extends Model {
  static entity = 'merchantGiftCardThresholdAmounts'

  static fields() {
    return {
      id: this.attr(''),
      amount: this.attr(''),
      merchantGiftCardConfigurationId: this.attr('')
    };
  }

  static state() {
    return {
      fetching: false,
      submitting: false
    };
  }

  static $state() {
    return this.store().state.entities.merchantGiftCardThresholdAmounts;
  }

  static async fetchMerchantGiftCardThresholdAmounts(merchantGiftCardConfigurationId) {
    try {
      this.commit((state) => {
        state.fetching = true;
      });

      const { data } = await http.get(
        `merchant_gift_card_configurations/${merchantGiftCardConfigurationId}/merchant_gift_card_threshold_amounts`
      );

      this.create({
        data: data.merchantGiftCardThresholdAmounts
      });
    }

    catch (error) {
      throw error;
    }

    finally {
      this.commit((state) => {
        state.fetching = false;
      });
    }
  }

  static async resetMerchantGiftCardThresholdAmounts({ merchantGiftCardConfigurationId, merchantGiftCardThresholdAmounts }) {
    try {
      this.commit((state) => {
        state.submitting = true;
      });

      const { data } = await http.post(
        `merchant_gift_card_configurations/${merchantGiftCardConfigurationId}/merchant_gift_card_threshold_amounts/reset`,
        { merchantGiftCardThresholdAmounts }
      );

      this.update({
        data: data.merchantGiftCardThresholdAmounts
      });
    }

    catch (error) {
      throw error;
    }

    finally {
      this.commit((state) => {
        state.submitting = false;
      });
    }
  }
}
