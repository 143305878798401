<template>
  <ul class="nested-table-section">
    <li v-if="!modifierGroups.length && (!$_menuPermissions.ADD_RESOURCE || menuTypeId)">
      <div class="row sub-row">
        <span class="has-text-grey pad-y-md">No Modifier Groups</span>
        <span />
        <span />
      </div>
    </li>
    <draggable
      tag="ul"
      :group="`item-${itemId}-modifier-groups`"
      v-bind="draggableAttributes"
      :value="modifierGroups"
      :force-fallback="true"
      @change="handleModifierGroupSort"
    >
      <template v-if="modifierGroups.length" #header>
        <li class="nested-table-section-title">{{ !modifierId && $_featurePermissions.NESTED_MODIFIERS ? 'Groups' : 'Modifier Groups' }}</li>
      </template>
      <li
        v-for="modifierGroup in sortedResource(modifierGroups)"
        :key="`modifier-group-${modifierGroup.id}`"
        :class="['draggable', {'is-open': isOpen.modifierGroup[modifierGroup.id]}]"
        :data-modifier-group-id="modifierGroup.id"
      >
        <div class="row sub-row">
          <span class="pad-y-sm">
            <b-icon
              v-if="canSortResource('MenuItemModifierGroup')"
              icon="grip-lines"
              size="is-small"
              pack="far"
              class="drag-handle"
            />
            <b-icon
              v-if="$_featurePermissions.NESTED_MODIFIERS && modifierGroup.type"
              type="is-primary"
              class="mar-r-xs"
              pack="fad"
              :icon="modifierGroupIcons[modifierGroup.type]"
            />
            <span class="link-inverted" @click="handleToggleModifierGroup(modifierGroup)">
              <template v-if="!isSearchResult">{{ modifierGroup.displayName }}</template>
              <template v-else>
                <span v-for="chunk in highlightSearchTerm(modifierGroup.displayName)" :key="chunk.key" :class="chunk.match && 'search-term'">{{ chunk.text }}</span>
              </template>
              <b-icon
                size="is-small"
                pack="far"
                :icon="sortingModifierGroupId === modifierGroup.id || isFetchingModifiers(modifierGroup.id) ? 'spinner-third' : 'angle-right'"
                :class="[
                  'open-indicator',
                  {
                    'is-open': isOpen.modifierGroup[modifierGroup.id],
                    'spin': sortingModifierGroupId === modifierGroup.id || isFetchingModifiers(modifierGroup.id)
                  }
                ]"
              />
            </span>
          </span>
          <span />
          <span class="align-center justify-end">
            <template v-if="!menuTypeId">
              <b-button class="is-transparent" @click="openModifierGroupModal({ modifierGroupId: modifierGroup.id, mode: 'update', type: modifierGroup.type })">
                <b-icon v-if="$can('update', 'MenuItemModifierGroup') && $_menuPermissions.EDIT_RESOURCE" icon="pencil" size="is-small" />
                <span v-else>View</span>
              </b-button>
              <b-dropdown
                v-if="($can('destroy', 'MenuItemModifierGroup') && $_menuPermissions.REMOVE_RESOURCE)"
                aria-role="list"
                position="is-bottom-left"
              >
                <b-button slot="trigger" class="is-transparent" type="is-white">
                  <b-icon icon="ellipsis-v" pack="far" size="is-small" />
                </b-button>
                <b-dropdown-item v-if="$can('create', 'MenuItemModifierGroup') && $_menuPermissions.CLONE_ITEM" @click="openCloneModifierGroupModal(modifierGroup)">
                  <b-icon
                    icon="copy"
                    class="mar-r-sm"
                    size="is-small"
                  />
                  Copy Modifier Group
                </b-dropdown-item>
                <hr class="dropdown-divider">
                <b-dropdown-item v-if="$can('destroy', 'MenuItemModifierGroup') && $_menuPermissions.REMOVE_RESOURCE" class="is-danger" @click="openDeleteModifierGroupConfirmation({modifierGroup, modifierId})">
                  <b-icon icon="trash-alt" class="mar-r-sm" size="is-small" />
                  Delete
                  <span v-if="!modifierId">{{ modifierGroup.type ? `${modifierGroup.type} Group` : 'Modifier Group' | capitalize }}</span>
                  <span v-else>Modifier Group</span>
                </b-dropdown-item>
              </b-dropdown>
            </template>
          </span>
        </div>
        <ul v-if="isOpen.modifierGroup[modifierGroup.id]" :class="['nested-table-section', { 'nested-modifier-section': modifierId }]">
          <li v-if="!modifierGroup.menuItemModifiers.length && (!$_menuPermissions.ADD_RESOURCE || menuTypeId)">
            <div class="row sub-row">
              <span class="has-text-grey pad-y-sm">No Modifiers</span>
              <span />
              <span />
            </div>
          </li>
          <draggable
            v-else
            tag="ul"
            :group="`modifier-group-${modifierGroup.id}-modifiers`"
            v-bind="draggableAttributes"
            :force-fallback="true"
            :value="modifierGroup.menuItemModifiers"
            @change="handleModifierSort($event, modifierGroup)"
          >
            <template v-if="modifierGroup.menuItemModifiers.length" #header>
              <li class="nested-table-section-title">
                <template v-if="$_featurePermissions.NESTED_MODIFIERS && modifierGroup.type">
                  <span v-if="modifierGroup.type === modifierGroupTypes.PORTION">Portion Types</span>
                  <span v-else-if="modifierGroup.type === modifierGroupTypes.COMBO">Combo Items</span>
                </template>
                <span v-else>Modifiers</span>
              </li>
            </template>
            <li
              v-for="modifier in sortedResource(modifierGroup.menuItemModifiers)"
              :key="`modifier-${modifier.id}`"
              :data-modifier-id="modifier.id"
              :class="['draggable', {'is-open': isOpen.modifier[modifier.id]}]"
            >
              <div class="row sub-row">
                <div class="is-flex pad-y-sm">
                  <b-icon
                    v-if="canSortResource('MenuItemModifier') && (!$_featurePermissions.NESTED_MODIFIERS || modifierGroup.type !== modifierGroupTypes.PORTION)"
                    icon="grip-lines"
                    size="is-small"
                    pack="far"
                    class="drag-handle"
                  />
                  <img
                    v-else-if="$_featurePermissions.NESTED_MODIFIERS && modifierGroup.type === modifierGroupTypes.PORTION"
                    :class="['mar-r-sm', { 'transform-180': ['Right Half', '2nd Half'].includes(modifier.displayName) }]"
                    :src="portionIcons[modifier.modifierTemplate]"
                  >
                  <span
                    :class="$_featurePermissions.NESTED_MODIFIERS && modifier.canHaveNestedMenuItemModifierGroups && 'link-inverted'"
                    @click="$_featurePermissions.NESTED_MODIFIERS && modifier.canHaveNestedMenuItemModifierGroups && toggleModifier(modifier.id)"
                  >
                    <template v-if="!isSearchResult">{{ modifier.displayName }}</template>
                    <template v-else>
                      <span v-for="chunk in highlightSearchTerm(modifier.displayName)" :key="chunk.key" :class="(chunk.match || isMatchedPlu(modifier)) && 'search-term'">{{ chunk.text }}</span>
                    </template>
                    <b-icon
                      v-if="$_featurePermissions.NESTED_MODIFIERS && modifier.canHaveNestedMenuItemModifierGroups"
                      size="is-small"
                      pack="far"
                      :icon="sortingModifierId === modifier.id ? 'spinner-third' : 'angle-right'"
                      :class="[
                        'open-indicator',
                        {
                          'is-open': isOpen.modifier[modifier.id],
                          'spin': sortingModifierId === modifier.id
                        }
                      ]"
                    />
                    <b-icon
                      v-else-if="!modifier.mappedToPos && !$_hasPosType(posTypes.Cardfree)"
                      v-tippy="{ content: 'Missing POS Mapping', placement: 'right' }"
                      size="is-small"
                      icon="exclamation-triangle"
                      type="is-danger"
                      class="mar-l"
                    />
                  </span>
                </div>
                <span class="align-center justify-end">
                  <template v-if="!menuTypeId">
                    <div
                      v-tippy="{
                        content: 'A nested group cannot be the default',
                        placement: 'left',
                        onShow: () => $_featurePermissions.NESTED_MODIFIERS && modifier.hasChildrenModifierGroups
                      }"
                      class="is-flex align-center"
                    >
                      <b-radio
                        v-if="modifierGroup.maxAllowed === 1"
                        :native-value="true"
                        :disabled="!canSetAsDefault(modifier)"
                        :value="modifier.isDefault"
                        :name="`${modifierGroup.id}-isDefault`"
                        @input="handleSelectedChange({ modifiers: modifierGroup.menuItemModifiers, modifierGroup, modifierId: modifier.id, isDefault: $event, isRadio: true })"
                        @click.native="handleSelfClick($event, modifier.id)"
                      />
                      <b-checkbox
                        v-else
                        :disabled="!canSetAsDefault(modifier) || !modifier.isDefault && hasGroupReachedDefaultModifierLimit[modifierGroup.id]"
                        :value="modifier.isDefault"
                        @input="handleSelectedChange({ modifierId: modifier.id, isDefault: $event })"
                      />
                    </div>
                  </template>
                </span>
                <span class="align-center justify-end">
                  <template v-if="!menuTypeId && (!$_featurePermissions.NESTED_MODIFIERS || modifierGroup.type !== modifierGroupTypes.PORTION)">
                    <b-button
                      v-if="modifier.availabilityBeginDate || modifier.availabilityEndDate"
                      v-tippy="{ content: 'Restricted Availability', placement: 'left', delay: [333, 0] }"
                      class="is-transparent"
                      @click="openModifierModal({ modifierId: modifier.id, modifierGroup, mode: 'update' })"
                    >
                      <b-icon icon="calendar" pack="far" />
                    </b-button>
                    <b-button class="is-transparent" @click="openModifierModal({ modifierId: modifier.id, modifierGroup, mode: 'update' })">
                      <b-icon v-if="$can('update', 'MenuItemModifier') && $_menuPermissions.EDIT_RESOURCE" icon="pencil" size="is-small" />
                      <span v-else>View</span>
                    </b-button>
                    <b-dropdown v-if="$can('destroy', 'MenuItemModifier') && $_menuPermissions.REMOVE_RESOURCE" aria-role="list" position="is-bottom-left">
                      <b-button slot="trigger" class="is-transparent" type="is-white">
                        <b-icon icon="ellipsis-v" pack="far" size="is-small" />
                      </b-button>
                      <b-dropdown-item class="is-danger" @click="openDeleteModifierConfirmation(modifier)">
                        <b-icon icon="trash-alt" class="mar-r-sm" size="is-small" />Delete Modifier
                      </b-dropdown-item>
                    </b-dropdown>
                  </template>
                </span>
              </div>
              <item-modifier-groups
                v-if="modifier.canHaveNestedMenuItemModifierGroups && isOpen.modifier[modifier.id]"
                :item-id="itemId"
                :modifier-groups="modifier.menuItemModifierGroups"
                :menu-type-id="menuTypeId"
                :search-query="searchQuery"
                :is-search-result="isSearchResult"
                :is-matched-plu="isMatchedPlu"
                :valid-search-length="validSearchLength"
                :modifier-id="modifier.id"
                @modifier-updated="emitModifierUpdate"
                @modifier-group-updated="emitModifierGroupUpdate"
              />
            </li>
          </draggable>
          <li
            v-if="
              !menuTypeId
                && $can('create', 'MenuItemModifier')
                && $_menuPermissions.ADD_RESOURCE
                && (!$_featurePermissions.NESTED_MODIFIERS || modifierGroup.type !== modifierGroupTypes.PORTION)
            "
            :class="['row sub-row', { 'sub-modifier-row': modifierId }]"
          >
            <span>
              <b-button
                class="is-transparent"
                icon-left="plus"
                type="is-primary"
                inverted
                @click="openModifierModal({ modifierGroup, mode: 'create' })"
              >
                {{ $_featurePermissions.NESTED_MODIFIERS && modifierGroup.type === modifierGroupTypes.COMBO ? 'Combo Item' : 'Modifier' }}
              </b-button>
            </span>
            <span /><span />
          </li>
        </ul>
      </li>
    </draggable>
    <li
      v-if="!menuTypeId && $can('create', 'MenuItemModifierGroup') && $_menuPermissions.ADD_RESOURCE"
      class="row sub-row"
    >
      <span>
        <dropdown-menu
          v-if="$_featurePermissions.NESTED_MODIFIERS && !modifierId"
          position="bottom-start"
        >
          <template #trigger>
            <div class="is-flex align-center">
              <b-button
                type="is-primary"
                class="is-transparent"
                inverted
                icon-left="plus"
              >
                Group
              </b-button>
            </div>
          </template>

          <b-dropdown-item
            v-for="{ display, value } in modifierGroupTypeOptions"
            :key="value"
            class="is-flex justify-between pad-r-sm"
            aria-role="listitem"
            :disabled="value === modifierGroupTypes.PORTION && !!modifierGroups.find(m => m.type === modifierGroupTypes.PORTION)"
            @click="openModifierGroupModal({ type: Object.values(modifierGroupTypes).includes(value) ? value : '' })"
          >
            Add {{ display }}
            <b-icon icon="arrow-right" />
          </b-dropdown-item>
        </dropdown-menu>

        <div
          v-else
          v-tippy="{
            content: 'A default group cannot have nested modifiers',
            placement: 'right',
            onShow: () => isModifierDefault
          }"
        >
          <b-button
            :disabled="isModifierDefault"
            class="is-transparent"
            icon-left="plus"
            type="is-primary"
            inverted
            @click="openModifierGroupModal({modifierId})"
          >
            Modifier Group
          </b-button>
        </div>
      </span>
      <span /><span />
    </li>
  </ul>
</template>

<script>
  import addEditModifierGroupModal from './add-edit-modifier-group-modal.vue';
  import copyModifierGroupModal from './copy-menu-modifier-group-modal.vue';
  import addEditModifierModal from './add-edit-modifier-modal/index.vue';
  import ModifierGroup from '@/store/classes/ModifierGroup';
  import Modifier from '@/store/classes/Modifier';
  import draggable from 'vuedraggable';
  import events from '@/services/events';
  import merchantMixin from '@/mixins/merchant';
  import featurePermissionsMixin from '@/mixins/featurePermissions';
  import posTypes from '@/constants/posTypes';
  import highlightWords from 'highlight-words';
  import capitalize from '@/filters/capitalize';
  import { modifierGroupTypes, modifierGroupPortionTemplates } from '@/constants/modifierGroups';

  export default {
    name: 'ItemModifierGroups',

    components: { draggable },

    mixins: [merchantMixin, featurePermissionsMixin],

    props: {
      itemId: {
        type: Number,
        required: true
      },

      modifierGroups: {
        type: Array,
        required: true
      },

      menuTypeId: {
        type: Number,
        default: null
      },

      searchQuery: {
        type: String,
        default: ''
      },

      isSearchResult: {
        type: Boolean,
        default: false
      },

      isMatchedPlu: {
        type: Function,
        required: true
      },

      validSearchLength: {
        type: Number,
        required: true
      },

      modifierId: {
        type: Number,
        default: null
      }
    },

    data() {
      return {
        draggableAttributes: {
          animation: '200',
          ghostClass: 'ghost',
          handle: '.drag-handle',
          draggable: '.draggable'
        },
        capitalize,
        modifierGroupTypeOptions: [
          { display: 'Modifier Group', value: 'modifier group' },
          { display: 'Portion Group', value: 'portion' },
          { display: 'Combo Group', value: 'combo' }
        ],
        modifierGroupIcons: {
          [modifierGroupTypes.PORTION]: 'chart-pie-alt',
          [modifierGroupTypes.COMBO]: 'layer-group'
        },
        portionIcons: {
          [modifierGroupPortionTemplates.FULL_GROUP_TEMPLATE.constant]: '/images/whole-portion.svg',
          [modifierGroupPortionTemplates.HALF_GROUP_TEMPLATE.constant]: '/images/half-portion.svg'
        },
        posTypes,
        modifierGroupTypes,
        isOpen: {
          modifierGroup: {},
          modifier: {}
        }
      };
    },

    computed: {
      sortingModifierGroupId() {
        return Modifier.$state().sortingParentId;
      },

      sortingModifierId() {
        return ModifierGroup.$state().sortingParentModifierId;
      },

      hasGroupReachedDefaultModifierLimit() {
        const result = this.modifierGroups.reduce((acc, modGroup) => {
          if (this.isOpen.modifierGroup[modGroup.id]) {
            const defaultCount = modGroup.menuItemModifiers.filter(mod => mod.isDefault)?.length || 0;
            acc[modGroup.id] = modGroup.maxAllowed !== -1 && defaultCount >= modGroup.maxAllowed;
          }
          return acc;
        }, {});

        return result;
      },

      isModifierDefault() {
        return Modifier.find(this.modifierId)?.isDefault ?? false;
      }
    },

    created() {
      this.onCreated();
    },

    destroyed() {
      this.onDestroyed();
    },

    methods: {
      onCreated() {
        const isValidSearch = this.searchQuery.length >= this.validSearchLength;
        if (isValidSearch) {
          this.expandAllModifierGroupsForSearch();
        }
        events.$on('main-menu:collapse', this.collapseAll);
      },

      onDestroyed() {
        events.$off('main-menu:collapse');
      },

      expandAllModifierGroupsForSearch() {
        this.modifierGroups.forEach((modifierGroup) => {
          if (modifierGroup.menuItemModifiers?.length) {
            this.toggleModifierGroup(modifierGroup.id, true);
            modifierGroup.menuItemModifiers.forEach((m) => {
              if (m.menuItemModifierGroups?.length) {
                this.toggleModifier(m.id, true);
              }
            });
          }
        });
      },

      canSortResource(type) {
        const invalidSearchOrEmptySearch = !this.isSearchResult || this.searchQuery.length < this.validSearchLength;
        return [
          this.$can('update', type),
          !this.menuTypeId,
          invalidSearchOrEmptySearch,
          this.$_menuPermissions.SORT_RESOURCE
        ].every(x => x);
      },

      sortedResource(resource) {
        if (!this.isSearchResult) {
          return resource;
        }
        return JSON.parse(JSON.stringify(resource)).sort((a, b) => ((a.sortOrder < b.sortOrder) ? -1 : 1));
      },

      highlightSearchTerm(string) {
        return highlightWords({ text: string, query: this.searchQuery, matchExactly: true });
      },

      isFetchingModifiers(modifierGroupId) {
        return Modifier.$state().fetchingModifierGroupId === modifierGroupId;
      },

      collapseAll() {
        Object.keys(this.isOpen.modifierGroup).forEach((key) => {
          this.isOpen.modifierGroup[key] = false;
        });
        Object.keys(this.isOpen.modifier).forEach((key) => {
          this.isOpen.modifier[key] = false;
        });
      },


      // MODIFIER GROUP
      async handleToggleModifierGroup(modifierGroup) {
        const wasNeverOpen = this.isOpen.modifierGroup[modifierGroup.id] === undefined;

        if (wasNeverOpen && !this.searchQuery) {
          await this.fetchModifiersByGroupId(modifierGroup.id);
        }

        this.toggleModifierGroup(modifierGroup.id);
      },

      async fetchModifiersByGroupId(groupId) {
        try {
          await Modifier.fetchByModifierGroupId({ modifierGroupId: groupId });
        }
        catch (error) {
          this.$_onRequestError({
            toastOptions: {
              message: 'Unable to fetch modifiers'
            },
            error
          });
        }
      },

      async fetchModifier(modifierId) {
        try {
          await Modifier.fetchModifier(modifierId);
        }
        catch (error) {
          this.$_onRequestError({
            toastOptions: {
              message: 'Unable to fetch modifiers'
            },
            error
          });
        }
      },

      async refreshModifierGroup(modifierId) {
        await this.fetchModifier(modifierId);
        // Refetch MODs for group to display caret that allows adding nested MOD groups to Combo Items
        // This is because we need to get the updated canHaveNestedModifierGroups value (fix for TOOL-4037)
        const modifierGroupId = Modifier.find(modifierId).modifierGroupId;
        await this.fetchModifiersByGroupId(modifierGroupId);
      },

      openModifierGroupModal({ modifierGroupId, modifierId, mode, type } = {}) {
        // If modifierId is present, we're dealing with a nested modifier group
        this.$buefy.modal.open({
          parent: this,
          component: addEditModifierGroupModal,
          hasModalCard: true,
          trapFocus: true,
          canCancel: false,
          customClass: 'auto-width',
          events: {
            'main-menu:add-modifier-group': groupId => this.onAddModifierGroup({ groupId, modifierId }),
            'modifier-group-updated': this.emitModifierGroupUpdate
          },
          props: {
            modifierGroupId,
            mode,
            type,
            menuItemId: this.itemId,
            modifierId: this.modifierId
          }
        });
      },

      openCloneModifierGroupModal(modifierGroup) {
        this.$buefy.modal.open({
          parent: this,
          component: copyModifierGroupModal,
          hasModalCard: true,
          trapFocus: true,
          canCancel: false,
          customClass: 'auto-width',
          props: { modifierGroup }
        });
      },

      emitModifierGroupUpdate() {
        this.$emit('modifier-group-updated');
      },

      async onAddModifierGroup({ groupId, modifierId }) {
        // If modifierId is present, we're dealing with a nested modifier group.
        // We need to refresh the parent modifier to update its hasChildrenModifierGroups property,
        // which determines whether it can be selected as default or not (fix for TOOL-4037)
        if (modifierId) {
          await this.refreshModifierGroup(modifierId);
        }
        this.toggleModifierGroup(groupId, true);
      },

      toggleModifierGroups({ modifierGroupIds, isOpen }) {
        modifierGroupIds.forEach((id) => {
          this.toggleModifierGroup(id, isOpen);
        });
      },

      toggleModifierGroup(modifierGroupId, isOpen) {
        const openState = isOpen !== undefined ? isOpen : !this.isOpen.modifierGroup[modifierGroupId];
        this.$set(this.isOpen.modifierGroup, modifierGroupId, openState);
      },

      async handleModifierGroupSort({ moved }) {
        try {
          await ModifierGroup.updateSortOrders({
            modifierGroups: this.modifierGroups,
            oldIndex: moved.oldIndex,
            newIndex: moved.newIndex
          });

          this.$_onRequestSuccess({
            toastOptions: {
              message: `<b>${moved.element.displayName}</b> moved from sort order <b>${moved.oldIndex + 1}</b> to <b>${moved.newIndex + 1}</b>`
            }
          });
        }

        catch (error) {
          this.$_onRequestError({
            toastOptions: { message: 'Unable to update sort orders' },
            error
          });
        }
      },

      openDeleteModifierGroupConfirmation({ modifierGroup, modifierId }) {
        // If modifierId is present, we're dealing with a nested modifier group.
        this.$buefy.dialog.confirm({
          title: 'Delete Modifier Group',
          message: `<b>${modifierGroup.displayName}</b> and its associated menu resources will be deleted from all locations. Are you sure?`,
          onConfirm: () => this.deleteModifierGroup({ modifierGroup, modifierId }),
          confirmText: 'Delete',
          icon: 'trash-alt',
          hasIcon: true,
          type: 'is-danger'
        });
      },

      async deleteModifierGroup({ modifierGroup, modifierId }) {
        try {
          await ModifierGroup.deleteModifierGroup(modifierGroup.id);

          // If modifierId is present, we're dealing with a nested modifier group (fix for TOOL-4037)
          // We need to refresh the parent modifier to update its hasChildrenModifierGroups property,
          // and make it selectable as default if it no longer has any children modifier groups
          if (modifierId) {
            await this.refreshModifierGroup(modifierId);
          }

          this.$_onRequestSuccess({
            toastOptions: {
              message: `Successfully deleted <b>${modifierGroup.displayName}</b>`
            }
          });
        }

        catch (error) {
          this.$_onRequestError({
            error,
            toastOptions: {
              message: `Unable to delete <b>${modifierGroup.displayName}</b>`
            }
          });
        }
      },



      // MODIFIER
      toggleModifier(modifierId, isOpen) {
        const openState = isOpen !== undefined ? isOpen : !this.isOpen.modifier[modifierId];
        this.$set(this.isOpen.modifier, modifierId, openState);
      },

      openModifierModal({ modifierGroup, modifierId, mode }) {
        this.$buefy.modal.open({
          parent: this,
          component: addEditModifierModal,
          hasModalCard: true,
          trapFocus: true,
          canCancel: false,
          customClass: 'auto-width',
          events: {
            'modifier-updated': this.emitModifierUpdate,
            'modifier-added': this.refreshModifierGroup
          },
          props: { modifierGroup, mode, modifierId }
        });
      },

      emitModifierUpdate() {
        this.$emit('modifier-updated');
      },

      async handleSelectedChange({ modifierId, isDefault, isRadio, modifierGroup, modifiers }) { // eslint-disable-line object-curly-newline
        try {
          if (isRadio) {
            const updatedModifiers = JSON.parse(JSON.stringify(modifiers)).map((modifier) => {
              modifier.isDefault = modifier.id === modifierId;
              return modifier;
            });

            await Modifier.bulkUpdateModifiers({
              modifierGroup,
              modifiers,
              updatedModifiers
            });
          }

          else {
            await Modifier.updateModifier({ id: modifierId, isDefault });
          }

          this.$_onRequestSuccess({
            toastOptions: {
              message: 'Successfully updated modifier selection!'
            }
          });
        }

        catch (error) {
          this.$_onRequestError({
            error,
            toastOptions: {
              message: 'Unable to update modifier selection'
            }
          });
        }
      },

      handleSelfClick(e, modifierId) {
        const isChecked = e.target.closest('.b-radio').querySelector('input').checked;

        if (isChecked && this.$can('update', 'MenuItemModifier')) {
          this.handleSelectedChange({ modifierId, isDefault: !isChecked });
        }
      },

      async handleModifierSort({ moved }, modifierGroup) {
        try {
          await Modifier.updateSortOrders({
            modifierGroup,
            modifiers: modifierGroup.menuItemModifiers,
            oldIndex: moved.oldIndex,
            newIndex: moved.newIndex
          });

          this.$_onRequestSuccess({
            toastOptions: {
              message: `<b>${moved.element.displayName}</b> moved from sort order <b>${moved.oldIndex + 1}</b> to <b>${moved.newIndex + 1}</b>`
            }
          });
        }

        catch (error) {
          this.$_onRequestError({
            toastOptions: { message: 'Unable to update sort orders' },
            error
          });
        }
      },

      openDeleteModifierConfirmation({ displayName, id }) {
        this.$buefy.dialog.confirm({
          title: 'Delete Modifier',
          message: `<b>${displayName}</b> will be deleted from all locations. Are you sure?`,
          onConfirm: () => this.deleteModifier({ displayName, id }),
          confirmText: 'Delete',
          icon: 'trash-alt',
          hasIcon: true,
          type: 'is-danger'
        });
      },

      async deleteModifier({ displayName, id }) {
        try {
          await Modifier.deleteModifier(id);

          this.$_onRequestSuccess({
            toastOptions: {
              message: `Successfully deleted <b>${displayName}</b>`
            }
          });
        }

        catch (error) {
          this.$_onRequestError({
            error,
            toastOptions: {
              message: `Unable to delete <b>${displayName}</b>`
            }
          });
        }
      },

      canSetAsDefault(modifier) {
        return (
          this.$can('update', 'MenuItemModifier')
          && this.$_menuPermissions.SET_DEFAULT_MODIFIER
          && !(this.$_featurePermissions.NESTED_MODIFIERS && modifier.hasChildrenModifierGroups)
        );
      }
    }
  };
</script>

<style lang="sass" scoped>
  .row
    grid-template-columns: 1fr 200px 170px
  .transform-180
    transform: rotate(180deg)

</style>
