<template>
  <validated-form
    ref="form"
    auto-focus
    form-id="addEditEmployeeModal"
    @valid-submit="handleSubmit"
  >
    <modal-card :title="modalTitle">
      <div class="is-grid col-2 gap-x-lg">
        <validated-text-input
          v-model="form.firstName"
          label="First Name"
          name="firstName"
          rules="required"
          type="text"
        />
        <validated-text-input
          v-model="form.lastName"
          label="Last Name"
          name="lastName"
          rules="required"
          type="text"
        />
      </div>

      <div class="is-grid mar-t col-min-200">
        <validated-input
          label="Affiliated Department"
          name="department"
          rules="required"
        >
          <b-select
            v-model="form.departmentId"
            expanded
            placeholder="Select a Department..."
          >
            <option v-if="!departments.length" disabled :value="null">
              No Departments Configured
            </option>
            <template v-else>
              <option :value="null" disabled>-</option>
              <option v-for="{ name, id } in departments" :key="id" :value="id">
                {{ name }}
              </option>
            </template>
          </b-select>
        </validated-input>
      </div>
      <div class="is-grid mar-t">
        <validated-text-input
          v-model="form.internalId"
          label="Employee ID Number"
          sub-label="Optional"
          name="internalId"
          max-length="255"
          type="text"
        />
      </div>
      <div class="is-grid mar-t">
        <image-upload
          v-slot="{imagePath}"
          v-model="employeeImage"
          :accepted-types="['png', 'jpeg', 'jpg']"
          :image-size-warning-height="500"
          :image-size-warning-width="500"
          :maximum-file-size-in-mb="1"
          restrict-file-size
          is-full-width
          :image-url="form.photoUrl"
          label="Employee Image (Optional)"
          clear-only
          clear-text="Clear Image"
          show-image-cropper
        >
          <img
            v-if="imagePath"
            class="cropped-image"
            style="max-width: 500px"
            :src="imagePath"
            alt="employee image"
          >
        </image-upload>
      </div>
      <template #footer>
        <div class="buttons all-bold">
          <b-button
            rounded
            @click="$_confirmCloseModal({ programmatic: true })"
          >
            Cancel
          </b-button>

          <b-button
            v-tabbable
            rounded
            native-type="submit"
            type="is-primary"
            :loading="isSubmitting"
            :disabled="isSubmitting"
          >
            Save
          </b-button>
        </div>
      </template>
    </modal-card>
  </validated-form>
</template>

<script>
  import Employee from '@/store/classes/Employee';
  import Department from '@/store/classes/Department';
  import confirmModalCloseMixin from '@/mixins/confirm-modal-close';
  import alertModal from '@/components/globals/alert-modal.vue';
  import shareEmployeeDepartmentModal from './share-employee-department-modal.vue';


  export default {
    name: 'AddEditEmployeeModal',

    mixins: [confirmModalCloseMixin],

    props: {
      employee: {
        type: Object,
        required: true
      },

      storeId: {
        type: Number,
        required: true
      }
    },

    data() {
      return {
        form: null,
        imageFile: null
      };
    },

    computed: {
      isSubmitting() {
        return Employee.$state().submitting;
      },

      departments() {
        return Department.query().where('storeId', this.storeId).orderBy(dept => dept.name.toLowerCase()).get();
      },

      modalTitle() {
        return `${!this.form.id ? 'Add' : 'Update'} Employee`;
      },

      employeeImage: {
        get() {
          return this.imageFile;
        },

        set(value) {
          if (!value) {
            this.deleteImage();
          }
          else {
            this.imageFile = value;
          }
        }
      }
    },

    created() {
      this.onCreated();
    },

    methods: {
      onCreated() {
        this.form = this.$clone(this.employee);
      },

      async uploadEmployeeImage(image) {
        try {
          // TODO the image file doesn't get sent to the api
          return await Employee.uploadImage(image);
        }
        catch (error) {
          this.$_onRequestError({
            error,
            toastOptions: {
              message: 'There was an error uploading your employee image'
            }
          });
        }
      },

      deleteImage() {
        this.form.photoUrl = null;
        this.imageFile = null;
      },

      openEmployeeSuccessModal(employee) {
        this.$buefy.modal.open({
          parent: this,
          component: alertModal,
          hasModalCard: true,
          trapFocus: true,
          canCancel: false,
          customClass: 'auto-width',
          props: {
            buttons: [
              { text: 'Okay' },
              { text: 'Share Barcode', primary: true, onClick: () => this.openShareQRCodeModal(employee) }
            ],
            showCloseButton: false,
            icon: 'check-circle',
            iconPack: 'far',
            title: `Employee ${this.form.id ? 'Updated' : 'Added'}`,
            message: `We've successfully ${this.form.id ? 'updated' : 'added'} this employee`,
            type: 'is-success'
          }
        });
      },

      openShareQRCodeModal(employee) {
        this.$buefy.modal.open({
          parent: this,
          component: shareEmployeeDepartmentModal,
          hasModalCard: true,
          trapFocus: true,
          canCancel: false,
          customClass: 'auto-width',
          props: { resource: employee, type: 'employee' }
        });
      },


      async handleSubmit() {
        const formToSubmit = this.$clone(this.form);
        let employee;

        try {
          const imageHasNotChanged = this.employee.id && this.form.photoUrl === this.employee.photoUrl;
          if (this.imageFile) {
            formToSubmit.photoUrl = await this.uploadEmployeeImage(this.imageFile);
          }
          else if (imageHasNotChanged) {
            delete formToSubmit.photoUrl;
          }

          if (formToSubmit.id) {
            employee = await Employee.updateEmployee(formToSubmit);
          }
          else {
            employee = await Employee.createEmployee(formToSubmit);
          }

          this.openEmployeeSuccessModal(employee);

          this.$_onRequestSuccess({
            toastOptions: {
              message: `Your employee was successfully ${formToSubmit.id ? 'updated' : 'added'}!`
            },
            options: {
              closeParent: true
            }
          });
        }

        catch (error) {
          this.$_onRequestError({
            error,
            toastOptions: {
              message: `There was an error ${formToSubmit.id ? 'updating' : 'adding'} your employee`
            }
          });
        }
      }
    }
  };
</script>
