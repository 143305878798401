<template>
  <div class="card">
    <div class="card-content">
      <div class="is-flex justify-between">
        <p class="subtitle is-4">
          Constraints
        </p>
        <b-button
          v-if="!offerReadOnly"
          type="is-primary"
          inverted
          @click="$emit('navigate-to-step', 1)"
        >
          Edit
        </b-button>
      </div>
      <b-message
        v-if="!itemOfferConstraints.length && !minimumPurchaseConstraint && !fulfillmentTypeConstraint && !platformTypeConstraint && !locationConstraint && !menuTypeConstraint"
        type="is-warning"
        icon="info-square"
        class="is-compact has-shadow"
        has-icon
      >
        The are no constraints set for this offer
      </b-message>
      <item-offer-constraint-row
        v-for="(constraint, index) in itemOfferConstraints"
        :key="index"
        :constraint="constraint"
        :count="index + 1"
      />
      <div
        v-if="minimumPurchaseConstraint"
        class="is-grid col-2 pad-sm has-border-bottom has-border-grey-lighter"
      >
        <p class="title is-6 mar-none">Minimum Purchase:</p>
        <p class="subtitle is-6 mar-none">
          {{ minimumPurchaseDisplay }}
        </p>
      </div>
      <div
        v-if="fulfillmentTypeConstraint"
        class="is-grid col-2 pad-sm has-border-bottom has-border-grey-lighter"
      >
        <p class="title is-6 mar-none">Available Fulfillment Types:</p>
        <p class="subtitle is-6 mar-none">
          {{ fulfillmentTypeDisplay }}
        </p>
      </div>
      <div
        v-if="platformTypeConstraint"
        class="is-grid col-2 pad-sm"
      >
        <p class="title is-6 mar-none">Available Platform Types:</p>
        <p class="subtitle is-6 mar-none">
          {{ platformTypeDisplay }}
        </p>
      </div>
      <div
        v-if="locationConstraint"
        class="is-grid col-2 pad-sm"
      >
        <p class="title is-6 mar-none">Available Locations:</p>
        <p class="subtitle is-6 mar-none">
          {{ locationDisplay }}
        </p>
      </div>
      <div
        v-if="menuTypeConstraint"
        class="is-grid col-2 pad-sm"
      >
        <p class="title is-6 mar-none">Available Menu Types:</p>
        <p class="subtitle is-6 mar-none">
          {{ menuTypeDisplay }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
  import { capitalCase } from 'change-case';
  import { constraintTypes } from '@/constants/offers';
  import { itemAttributes } from '@/constants/items';
  import Store from '@/store/classes/Store';
  import itemOfferConstraintRow from './item-offer-constraint-row.vue';

  export default {
    name: 'ConstraintsCard',

    components: { itemOfferConstraintRow },

    props: {
      constraints: {
        type: Array,
        required: true
      },

      offerReadOnly: {
        type: Boolean,
        required: true
      }
    },

    computed: {
      itemOfferConstraints() {
        return this.getConstraintsByType(constraintTypes.ITEM_OFFER);
      },

      minimumPurchaseConstraint() {
        return this.getConstraintsByType(constraintTypes.MINIMUM_PURCHASE)[0];
      },

      minimumPurchaseDisplay() {
        const itemAttributeEntries = Object.values(itemAttributes).reduce((acc, value) => ({ ...acc, [value.id]: value.display }), {});
        const excludes = this.minimumPurchaseConstraint?.excludedItemAttributes?.map(attribute => itemAttributeEntries[attribute]).join(', ');

        return `$${this.minimumPurchaseConstraint?.minimum} (excludes ${excludes})`;
      },

      fulfillmentTypeConstraint() {
        return this.getConstraintsByType(constraintTypes.FULFILLMENT_TYPE)[0];
      },

      fulfillmentTypeDisplay() {
        return this.fulfillmentTypeConstraint?.fulfillmentTypes?.map((fulfillmentType) => {
          if (fulfillmentType === 'Curbside' || fulfillmentType === 'Takeout') {
            return `Pickup (${fulfillmentType})`;
          }
          else {
            return capitalCase(fulfillmentType);
          }
        }).join(', ');
      },

      platformTypeConstraint() {
        return this.getConstraintsByType(constraintTypes.PLATFORM_TYPE)[0];
      },

      platformTypeDisplay() {
        return this.platformTypeConstraint?.platformTypes?.map(pt => capitalCase(pt)).join(', ');
      },

      locationConstraint() {
        return this.getConstraintsByType(constraintTypes.LOCATION)[0];
      },

      locationConstraintSelectedStoreIds() {
        return this.locationConstraint?.storeIds;
      },

      menuTypeConstraint() {
        return this.getConstraintsByType(constraintTypes.MENU_TYPE)[0];
      },

      menuTypeDisplay() {
        return this.menuTypeConstraint?.menuTypes?.map(mt => capitalCase(mt)).join(', ');
      },

      totalStoreCount() {
        return Store.all().length;
      },

      isAllStoresSelected() {
        return this.locationConstraintSelectedStoreIds?.length === this.totalStoreCount;
      },

      locationDisplay() {
        if (this.isAllStoresSelected) {
          return 'All Locations';
        }
        else {
          return this.locationConstraintSelectedStoreIds.map(storeId => this.storeDisplayName((storeId))).join(', ');
        }
      }
    },

    methods: {
      capitalCase,

      getConstraintsByType(constraintType) {
        return this.constraints.filter(c => c.constraintType === constraintType);
      },

      storeDisplayName(storeId) {
        const store = Store.find(storeId);
        return store?.description;
      }
    }
  };
</script>
