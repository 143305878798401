const scrollContainerIndicator = {
  data() {
    return {
      p_scrollableDirectionX: null,
      p_scrollableDirectionY: null,
      p_scrollContent: null,
      p_scrollContainer: null,
      p_shadowContainer: null,
      p_autoStyles: false
    };
  },

  computed: {
    $_scrollableDirectionX() {
      return this.p_scrollableDirectionX;
    },

    $_scrollableDirectionY() {
      return this.p_scrollableDirectionY;
    }
  },

  destroyed() {
    if (this.p_scrollContainer) {
      this.p_scrollContainer.removeEventListener('scroll', this.$_setOverflowAttribute);
    }
    window.removeEventListener('resize', this.$_setOverflowAttribute);
  },

  methods: { // TEST EVENTUALLY ?
    $_wrap(el, wrapperTag, options = { classNames: [], styles: {} }) {
      const wrapper = document.createElement(wrapperTag);
      wrapper.classList.add(...options.classNames);
      Object.entries(options.styles).forEach(([key, value]) => {
        wrapper.style[key] = value;
      });
      el.parentNode.insertBefore(wrapper, el);
      wrapper.appendChild(el);
    },

    $_initScrollIndicatorStandard({ contentSelector, containerSelector }) {
      this.p_scrollContent = this.$el.querySelector(contentSelector);
      this.p_scrollContainer = this.$el.querySelector(containerSelector);

      this.$_setOverflowAttribute();

      this.p_scrollContainer.addEventListener('scroll', this.$_setOverflowAttribute);
      window.addEventListener('resize', this.$_setOverflowAttribute);
    },

    $_initScrollIndicatorSmart({ contentSelector, maxWidth, maxHeight, autoStyles = true }) {
      this.p_autoStyles = autoStyles;
      this.p_scrollContent = this.$el.querySelector(contentSelector);

      this.p_shadowContainer = document.createElement('div');
      this.p_shadowContainer.classList.add(...['shadow-container', 'is-relative']);

      this.p_scrollContainer = document.createElement('div');
      this.p_scrollContainer.classList.add('overflow-wrapper');
      this.p_scrollContainer.style.overflow = 'auto';
      this.p_scrollContainer.style.maxWidth = `${maxWidth}px` || 'auto';
      this.p_scrollContainer.style.maxHeight = `${maxHeight}px` || 'auto';

      this.p_scrollContent.parentNode.insertBefore(this.p_scrollContainer, this.p_scrollContent);
      this.p_scrollContainer.appendChild(this.p_scrollContent);

      this.p_scrollContainer.parentNode.insertBefore(this.p_shadowContainer, this.p_scrollContainer);
      this.p_shadowContainer.appendChild(this.p_scrollContainer);

      this.$_setOverflowAttrAndClass();

      this.p_scrollContainer.addEventListener('scroll', this.$_setOverflowAttrAndClass);
      window.addEventListener('resize', this.$_setOverflowAttrAndClass);
    },

    $_setOverflowAttribute() {
      const containerMetrics = this.p_scrollContainer.getBoundingClientRect();
      const containerMetricsRight = Math.floor(containerMetrics.right);
      const containerMetricsLeft = Math.floor(containerMetrics.left);
      const containerMetricsTop = Math.floor(containerMetrics.top);
      const containerMetricsBottom = Math.floor(containerMetrics.bottom);

      const contentMetrics = this.p_scrollContent.getBoundingClientRect();
      const contentMetricsRight = Math.floor(contentMetrics.right);
      const contentMetricsLeft = Math.floor(contentMetrics.left);
      const contentMetricsTop = Math.floor(contentMetrics.top);
      const contentMetricsBottom = Math.floor(contentMetrics.bottom);

      // X Axis
      const overflowLeft = containerMetricsLeft > contentMetricsLeft;
      const overflowRight = containerMetricsRight < contentMetricsRight;
      let scrollableDirectionX = null;
      if (overflowLeft && overflowRight) scrollableDirectionX = 'both';
      else if (overflowLeft) scrollableDirectionX = 'left';
      else if (overflowRight) scrollableDirectionX = 'right';
      else scrollableDirectionX = null;
      this.p_scrollableDirectionX = scrollableDirectionX;

      // Y Axis
      const overflowTop = containerMetricsTop > contentMetricsTop;
      const overflowBottom = containerMetricsBottom < contentMetricsBottom;
      let scrollableDirectionY = null;
      if (overflowTop && overflowBottom) scrollableDirectionY = 'both';
      else if (overflowTop) scrollableDirectionY = 'top';
      else if (overflowBottom) scrollableDirectionY = 'bottom';
      else scrollableDirectionY = null;
      this.p_scrollableDirectionY = scrollableDirectionY;
    },

    p_setOverflowClass(scrollableDirection, axis) {
      const removeOverflowClasses = () => {
        this.p_shadowContainer.classList.forEach((className) => {
          if (className.includes(`overflow-${axis}-`)) this.p_shadowContainer.classList.remove(className);
        });
      };

      if (scrollableDirection) {
        const overflowClass = `overflow-${axis}-${scrollableDirection}`;
        if (!this.p_shadowContainer.classList.contains(overflowClass)) {
          removeOverflowClasses();
          this.p_shadowContainer.classList.add(overflowClass);
        }
      }
      else {
        removeOverflowClasses();
      }
    },

    $_setOverflowAttrAndClass() {
      this.$_setOverflowAttribute();
      if (this.p_autoStyles) {
        this.p_setOverflowClass(this.p_scrollableDirectionX, 'x');
        this.p_setOverflowClass(this.p_scrollableDirectionY, 'y');
      }
    }
  }
};

export default scrollContainerIndicator;
