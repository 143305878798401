import { itemAttributes, itemGroupTypes } from '@/constants/items';

import http from '@/services/http';


export default class ItemGroup {
  /**
   * posItems and posItemIds are only used internally for rendering selected resources
   * when an offer has a value proposition type of "PosSpecifiedItemDiscountsModel"
   */
  constructor(itemGroup = {}) {
    this.categoryIds = itemGroup?.categories || [];
    this.menuItemIds = itemGroup?.menuItemIds || [];
    this.posItemIds = itemGroup?.posItemIds || [];
    this.itemGroupType = itemGroup?.itemGroupType || itemGroupTypes.ALL_ITEMS;
    this.excludedItemAttributes = itemGroup?.excludedItemAttributes || Object.values(itemAttributes).map(itemAttr => itemAttr.id);
    this.menuItems = itemGroup?.menuItems || [];
    this.posItems = itemGroup?.posItems || [];
    this.categoryObjects = itemGroup?.categoryObjects || [];
    this.requiredModifications = itemGroup?.requiredModifications || [];
    this.publicId = itemGroup?.publicId || Symbol('tempPublicId');
    this.metadataCache = itemGroup?.metadataCache || this.stringifyMetadata();
  }

  stringifyMetadata() {
    return JSON.stringify(this);
  }

  parsedMetadata() {
    return JSON.parse(this.metadataCache);
  }

  toJSON() {
    return {
      itemGroupType: this.itemGroupType,
      excludedItemAttributes: this.excludedItemAttributes,
      requiredModifications: this.requiredModifications,
      menuItemIds: this.menuItems.map(mi => mi.id),
      categories: this.categoryObjects.map(co => co.id),
      publicId: typeof this.publicId === 'symbol' ? null : this.publicId
    };
  }

  hasChanged() {
    return this.metadataCache !== this.stringifyMetadata();
  }

  missingMenuItems() {
    return this.menuItemIds.filter(id => !this.menuItems.find(menuItem => menuItem.id === id));
  }

  missingPosItems() {
    return this.posItemIds.filter(id => !this.posItems.find(posItem => posItem.id === id));
  }

  missingCategories() {
    return this.categoryIds.filter(id => !this.categoryObjects.find(category => category.id === id));
  }

  updateType(type, excludedItemAttributes = null) {
    this.itemGroupType = type;
    this.categoryObjects = [];
    this.categoryIds = [];
    this.menuItemIds = [];
    this.menuItems = [];
    this.posItemIds = [];
    this.posItems = [];
    this.excludedItemAttributes = excludedItemAttributes || Object.values(itemAttributes).map(itemAttr => itemAttr.id);
    this.requiredModifications = [];
  }

  updateResources(type, resources, updatedIds) {
    this[type] = resources;
    if (type === 'menuItems') {
      this.menuItemIds = updatedIds;
    }
    else if (type === 'posItems') {
      this.posItemIds = updatedIds;
    }
    else {
      this.categoryIds = updatedIds;
    }
    this.updateRequiredModifications([]);
  }

  updateRequiredModifications(requiredModifications) {
    this.requiredModifications = requiredModifications;
  }

  static async getLoyaltyItemGroup(merchantId, publicId) {
    try {
      const response = await http.get(`/merchants/${merchantId}/loyalty_item_groups/${publicId}`);

      return response.data.itemGroup;
    }
    catch (error) {
      throw error;
    }
  }

  static async createLoyaltyItemGroup(merchantId, itemGroup) {
    try {
      const response = await http.post(`/merchants/${merchantId}/loyalty_item_groups`, {
        itemGroup
      });

      return response.data.itemGroup;
    }
    catch (error) {
      throw error;
    }
  }

  static async createOfferItemGroup(merchantId, itemGroup) {
    try {
      const response = await http.post(`/merchants/${merchantId}/item_groups`, {
        itemGroup
      });

      return response.data.itemGroup;
    }
    catch (error) {
      throw error;
    }
  }
}
