<template>
  <validated-form
    ref="form"
    auto-focus
    form-id="merchantLocationServiceForm"
    @valid-submit="submitHandler"
  >
    <modal-card
      :title="`${!merchantLocationService.id ? 'Create' : 'Update'} Location Service`"
      :subtitle="merchantLocationService.id && merchantLocationService.name"
    >
      <validated-input
        v-if="!merchantLocationService.id"
        name="locationServiceType"
        rules="required"
        label="Location Service Type"
      >
        <b-dropdown
          v-model="form.locationServiceTypeId"
          class="has-extra-shadow"
          expanded
          :mobile-modal="false"
          scrollable
          :max-height="290"
        >
          <dropdown-button
            slot="trigger"
            placeholder="Select a type..."
            :value="selectedLocationServiceTypeName"
          />
          <b-dropdown-item
            v-for="locationServiceType in filteredLocationServiceTypes"
            :key="locationServiceType.id"
            :value="locationServiceType.id"
          >
            {{ locationServiceType.name }}
          </b-dropdown-item>
        </b-dropdown>
      </validated-input>

      <validated-text-input
        v-model="form.apiConfiguration"
        class="configuration"
        :has-icon="false"
        label="Configuration"
        name="apiConfiguration"
        type="textarea"
        rows="20"
        :spellcheck="false"
        maxlength="16384"
        monospaced
        rules="required|validJSON"
      />

      <template #footer>
        <div class="buttons all-bold">
          <b-button
            rounded
            @click="$_confirmCloseModal({ programmatic: true })"
          >
            Cancel
          </b-button>
          <b-button
            v-tabbable
            rounded
            native-type="submit"
            type="is-primary"
            :loading="isSubmitting"
          >
            Save
          </b-button>
        </div>
      </template>
    </modal-card>
  </validated-form>
</template>

<script>
  import confirmModalCloseMixin from '@/mixins/confirm-modal-close';
  import LocationServiceType from '@/store/classes/LocationServiceType';
  import MerchantLocationService from '@/store/classes/MerchantLocationService';
  import beautify from 'json-beautify';

  export default {
    name: 'MerchantLocationServiceModal',

    mixins: [confirmModalCloseMixin],

    props: {
      merchantLocationService: {
        type: Object,
        required: true
      }
    },

    data() {
      return {
        form: {}
      };
    },
    computed: {
      isSubmitting() {
        return MerchantLocationService.$state().submitting;
      },

      locationServiceTypes() {
        return LocationServiceType.all();
      },

      existingLocationServiceTypes() {
        return MerchantLocationService.all().map(mls => mls.locationServiceTypeId);
      },

      filteredLocationServiceTypes() {
        return this.locationServiceTypes
          .filter(lst => !this.existingLocationServiceTypes.includes(lst.id))
          .sort((a, b) => ((a.name < b.name) ? -1 : 1));
      },

      selectedLocationServiceTypeName() {
        return this.locationServiceTypes.find(lst => lst.id === this.form.locationServiceTypeId)?.name;
      }
    },

    created() {
      this.onCreated();
    },

    methods: {
      onCreated() {
        const apiConfiguration = this.merchantLocationService.apiConfiguration || {};
        this.form = this.$clone({
          ...this.merchantLocationService,
          apiConfiguration: beautify(apiConfiguration, null, 2, 0)
        });
      },

      async submitHandler() {
        try {
          if (!this.merchantLocationService.id) {
            await MerchantLocationService.addMerchantLocationService({
              ...this.form,
              apiConfiguration: beautify(JSON.parse(this.form.apiConfiguration), null, 0)
            });
          }
          else {
            await MerchantLocationService.updateMerchantLocationService({
              ...this.form,
              apiConfiguration: beautify(JSON.parse(this.form.apiConfiguration), null, 0)
            });
          }

          this.$_onRequestSuccess({
            toastOptions: { message: `Successfully ${this.merchantLocationService.id ? 'updated' : 'created'} your location service!` },
            options: { closeParent: true }
          });
        }

        catch (error) {
          this.$_onRequestError({
            toastOptions: { message: 'An error occurred' },
            error
          });
        }
      }
    }
  };
</script>

<style lang="sass" scoped>
   .modal-card
    overflow: visible

    .modal-card-body
      overflow: visible
</style>
