<template>
  <div>
    <b-message
      v-if="!storeId"
      type="is-primary"
      class="is-compact has-shadow mar-b"
      has-icon
      icon="info-square"
    >
      Hours are consistent across all location time zones
    </b-message>
    <div class="card">
      <b-loading :active="isFetchingStoreHoursOverrides" :is-full-page="false" />
      <div class="card-header-sticky">
        <div>
          <h3 class="title is-4">{{ storeId ? 'Special Hours' : 'Global Special Hours' }}</h3>
          <h4 class="subtitle is-6 has-text-grey">
            {{ storeId ? 'Add special hours to override your normal business hours' : 'Add special hours to override business hours across all locations' }}
          </h4>
        </div>
      </div>
      <b-table
        v-if="combinedOverrides.length"
        :data="combinedOverrides"
        class="special-hours-table is-middle-aligned"
        :default-sort="['date', 'asc']"
        :row-class="getRowClass"
        :mobile-cards="false"
      >
        <b-table-column v-slot="{ row }" sortable field="name" label="Reason">
          <span
            v-if="$can('update', 'StoreMapping') && $can('update', 'Capi::StoreHoursOverride') && isTodayOrAfter(row) && (row.storeId && storeId || (!row.storeId && !storeId))"
            class="link"
            @click="openAddStoreHoursOverrideModal({ mode: 'update', storeHoursOverride: row })"
          >
            {{ row.name }}
          </span>
          <span v-else>
            {{ row.name }}
          </span>
        </b-table-column>

        <b-table-column v-slot="{ row: { date } }" sortable field="date" label="Date">
          {{ moment(date, 'YYYY-MM-DD').format('MM/DD/YY') }}
        </b-table-column>

        <b-table-column v-slot="{ row: { openTime, closeTime, isOpen } }" label="Hours">
          <template v-if="isOpen">
            {{ moment(openTime, 'HH:mm:ss').format(showOpenAmPm({ openTime, closeTime }) ? 'h:mm A' : 'h:mm') }}
            -
            {{ moment(closeTime, 'HH:mm:ss').format('h:mm A') }}
            <span v-if="getHoursMessage(openTime, closeTime)" class="is-size-7">({{ getHoursMessage(openTime, closeTime) }})</span>
          </template>
          <template v-else>
            Closed
          </template>
        </b-table-column>

        <b-table-column
          v-if="$can('update', 'StoreMapping') && $can('update', 'Capi::StoreHoursOverride')"
          v-slot="{ row }"
          numeric
          cell-class="actions"
          label="Actions"
        >
          <template>
            <b-tag v-if="!row.storeId && storeId" type="is-grey" class="has-text-weight-bold mar-r-sm">Global Special Hours</b-tag>
            <template v-else-if="isTodayOrAfter(row)">
              <b-button class="is-transparent mar-r-sm" @click="openAddStoreHoursOverrideModal({ mode: 'update', storeHoursOverride: row })">
                <b-icon icon="pencil" size="is-small" />
              </b-button>
              <b-button class="is-transparent" @click="openDeleteConfirmation(row)">
                <b-icon
                  :icon="deletingOverrideId === row.id ? 'spinner-third' : 'trash-alt'"
                  :class="deletingOverrideId === row.id && 'spin'"
                  type="is-danger"
                  size="is-small"
                />
              </b-button>
            </template>

          </template>
        </b-table-column>
      </b-table>

      <div v-if="$can('update', 'StoreMapping') && $can('create', 'Capi::StoreHoursOverride')" class="pad-x pad-y-sm has-border-top has-border-grey-lighter">
        <b-button
          class="is-transparent"
          icon-left="plus"
          type="is-primary"
          inverted
          @click="openAddStoreHoursOverrideModal({ mode: 'create' })"
        >
          Special Hours
        </b-button>
      </div>

    </div>
  </div>

</template>



<script>
  import StoreHoursOverride from '@/store/classes/StoreHoursOverride';
  import merchantMixin from '@/mixins/merchant';
  import moment from 'moment-timezone';
  import addEditStoreHoursOverrideModal from './add-edit-store-hours-override-modal.vue';

  export default {
    name: 'StoreSpecialHours',

    mixins: [merchantMixin],

    props: {
      storeId: {
        type: Number,
        default: null
      }
    },

    data() {
      return {
        moment
      };
    },

    computed: {
      merchantOverrides() {
        return StoreHoursOverride.query().where('storeId', null).get();
      },

      storeOverrides() {
        return StoreHoursOverride.query().where('storeId', this.storeId).get();
      },

      combinedOverrides() {
        const allOverrides = [
          ...this.merchantOverrides,
          ...this.storeOverrides
        ].filter(overide => this.is60DaysorLess(overide.date));
        return this.giveStoreOverridesPriority(allOverrides);
      },

      isFetchingStoreHoursOverrides() {
        return StoreHoursOverride.$state().fetching;
      },

      deletingOverrideId() {
        return StoreHoursOverride.$state().deletingId;
      }
    },

    methods: {
      onMerchantLoad() {
        this.fetchStoreHoursOverrides();
      },

      getRowClass(row) {
        let className = null;

        if (moment(row.date, 'YYYY-MM-DD').isBefore(moment(), 'day')) {
          className = 'disabled';
        }
        if (row.id === this.deletingOverrideId) {
          className = 'deleting';
        }

        return className;
      },

      isTodayOrAfter(row) {
        return moment(row.date, 'YYYY-MM-DD').isSameOrAfter(moment(), 'day');
      },

      is60DaysorLess(date) {
        return moment(date, 'YYYY-MM-DD').isSameOrAfter(moment().subtract(60, 'days'), 'day');
      },

      giveStoreOverridesPriority(overrides) {
        return Object.values(overrides.reduce((acc, currentOverride) => {
          if (acc[currentOverride.date] === undefined) {
            acc[currentOverride.date] = currentOverride;
          }
          else if (!acc[currentOverride.date].storeId) {
            delete acc[currentOverride.date];
            acc[currentOverride.date] = currentOverride;
          }
          return acc;
        }, {}));
      },

      showOpenAmPm({ openTime, closeTime }) {
        return ![
          [openTime, closeTime].every(time => time.split(':')[0] >= 12),
          [openTime, closeTime].every(time => time.split(':')[0] < 12)
        ].some(x => x);
      },

      getHoursMessage(openTime, closeTime) {
        const openTimeAsNumber = Number(openTime.split(':')[0]) + (Number(openTime.split(':')[1]) / 60);
        const closeTimeAsNumber = Number(closeTime.split(':')[0]) + (Number(closeTime.split(':')[1]) / 60);

        const isTwentyFourHours = openTimeAsNumber - closeTimeAsNumber === 0;
        const isNextDay = openTimeAsNumber - closeTimeAsNumber > 0;
        if (isTwentyFourHours) {
          return '24 Hours';
        }
        else if (isNextDay) {
          return 'Next Day';
        }
        else {
          return null;
        }
      },

      async fetchStoreHoursOverrides() {
        try {
          await StoreHoursOverride.fetchStoreHoursOverrides(this.storeId);
        }
        catch (error) {
          this.$_onRequestError({ error, toastOptions: { message: 'Unable to fetch store special hours' } });
        }
      },

      async deleteStoreHoursOverride(id) {
        try {
          await StoreHoursOverride.deleteStoreHoursOverride({ storeId: this.storeId, storeHoursOverrideId: id });

          this.$_onRequestSuccess({
            toastOptions: { message: 'Special Hours has been successfully removed!' }
          });
        }
        catch (error) {
          this.$_onRequestError({ error, toastOptions: { message: 'Unable to remove special hours' } });
        }
      },

      openAddStoreHoursOverrideModal({ mode, storeHoursOverride }) {
        this.$buefy.modal.open({
          parent: this,
          component: addEditStoreHoursOverrideModal,
          hasModalCard: true,
          trapFocus: true,
          canCancel: false,
          customClass: 'auto-width',
          props: {
            storeHoursOverride,
            mode,
            storeId: this.storeId
          }
        });
      },

      openDeleteConfirmation(sho) {
        this.$buefy.dialog.confirm({
          title: 'Remove Special Hours',
          message: `Are you sure you want to remove <b>${sho.name}</b>?`,
          onConfirm: () => this.deleteStoreHoursOverride(sho.id),
          confirmText: 'Remove',
          type: 'is-danger'
        });
      }
    }
  };
</script>

<style lang="sass" scoped>
  .special-hours-table
    ::v-deep
      tr.deleting
        background-color: $danger-light
      tr.disabled
        background-color: $white-ter
        color: $grey
</style>
