<template>
  <validated-form
    ref="form"
    auto-focus
    form-id="addPaymentGatewayForm"
    @valid-submit="submitHandler"
  >
    <modal-card
      :title="`${!currentPaymentGateway ? 'Assign' : 'Update'} Payment Provider`"
      :subtitle="currentPaymentGateway && currentPaymentGateway.paymentGateway.name"
    >
      <template v-if="!currentPaymentGateway">
        <b-message class="mar-b is-size-6 has-shadow is-compact" type="is-primary">
          <template v-if="storeId">
            Assigning a payment provider to this location will require adding configurations
            at this location and in some cases, Authorization to connect to the provider.
          </template>
          <template v-else>
            Assigning payment providers to the merchant will require adding configurations
            at the merchant level and location level and in some cases, Authorization to connect to the provider.
          </template>
        </b-message>
      </template>
      <validated-input
        v-if="!currentPaymentGateway"
        class="mar-b-lg"
        name="paymentGatewayId"
        rules="required"
        label="Payment Provider"
      >
        <b-dropdown
          v-model="paymentGatewayId"
          class="payment-gateways has-extra-shadow"
          expanded
          :mobile-modal="false"
          scrollable
          :max-height="290"
        >
          <dropdown-button
            slot="trigger"
            placeholder="Select a Payment Provider..."
            :value="selectedPaymentGatewayName"
          />
          <b-dropdown-item
            v-for="gateway in filteredPaymentGatewayOptions"
            :key="gateway.id"
            :value="gateway.id"
          >
            {{ gateway.name }}
          </b-dropdown-item>
        </b-dropdown>
      </validated-input>

      <div class="is-flex-column align-start">
        <b-switch
          v-model="isActive"
          class="label left-label mar-b-lg"
        >
          Active
        </b-switch>
      </div>

      <validated-text-input
        v-model="merchantReferenceField"
        class="mar-b-none"
        name="merchantReferenceField"
        label="Merchant Reference Field"
        type="text"
        maxlength="255"
      />

      <validated-text-input
        v-model="apiConfiguration"
        class="configuration"
        :has-icon="false"
        label="Configuration"
        name="paymentGatewayConfig"
        type="textarea"
        rows="20"
        :spellcheck="false"
        maxlength="16384"
        monospaced
        rules="required|validJSON"
      />

      <template #footer>
        <div class="buttons all-bold">
          <b-button
            rounded
            @click="$_confirmCloseModal({ programmatic: true })"
          >
            Cancel
          </b-button>
          <b-button
            v-tabbable
            rounded
            native-type="submit"
            type="is-primary"
            :loading="isSubmitting"
          >
            {{ currentPaymentGateway ? 'Save' : 'Assign' }}
          </b-button>
        </div>
      </template>
    </modal-card>
  </validated-form>
</template>

<script>
  import confirmModalCloseMixin from '@/mixins/confirm-modal-close';
  import PaymentGateway from '@/store/classes/PaymentGateway';
  import MerchantPaymentGateway from '@/store/classes/MerchantPaymentGateway';
  import beautify from 'json-beautify';


  export default {
    name: 'AddEditPaymentGatewayModal',

    mixins: [confirmModalCloseMixin],

    props: {
      merchantPaymentGatewayIds: {
        type: Array,
        required: true
      },

      storeId: {
        type: Number,
        default: null
      },

      merchant: {
        type: Object,
        required: true
      },

      currentPaymentGateway: {
        type: Object,
        default: null
      }
    },

    data() {
      return {
        paymentGatewayId: null,
        apiConfiguration: '{}',
        isActive: false,
        externalDeviceCompatible: false,
        merchantReferenceField: null
      };
    },

    computed: {
      isSubmitting() {
        return MerchantPaymentGateway.$state().submitting;
      },

      allPaymentGatewayOptions() {
        return PaymentGateway.all();
      },

      filteredPaymentGatewayOptions() {
        return this.allPaymentGatewayOptions.filter(pg => !this.merchantPaymentGatewayIds.includes(pg.id));
      },

      selectedPaymentGatewayName() {
        return this.filteredPaymentGatewayOptions.find(x => x.id === this.paymentGatewayId)?.name;
      }
    },

    created() {
      this.onCreated();
    },

    methods: {
      onCreated() {
        if (this.currentPaymentGateway) {
          this.apiConfiguration = beautify(this.currentPaymentGateway.apiConfiguration, null, 2, 0);
          this.isActive = this.currentPaymentGateway.isActive;
          this.externalDeviceCompatible = this.currentPaymentGateway.externalDeviceCompatible;
          this.merchantReferenceField = this.currentPaymentGateway.merchantReferenceField;
        }
      },

      async addPaymentGateway() {
        try {
          await MerchantPaymentGateway.addPaymentGateway({
            merchantId: this.merchant.id,
            merchantPaymentGateway: {
              paymentGatewayId: this.paymentGatewayId,
              apiConfiguration: this.apiConfiguration,
              storeId: this.storeId,
              isActive: this.isActive,
              externalDeviceCompatible: this.externalDeviceCompatible,
              merchantReferenceField: this.merchantReferenceField
            }
          });

          this.$_onRequestSuccess({
            toastOptions: { message: 'Successfully added payment gateway!' },
            options: { closeParent: true }
          });
        }

        catch (error) {
          this.$_onRequestError({
            toastOptions: { message: 'An error occured while adding your new payment gateway' },
            error
          });
        }
      },

      async updatePaymentGateway() {
        try {
          const { id } = this.currentPaymentGateway;
          await MerchantPaymentGateway.updatePaymentGateway({
            id,
            apiConfiguration: beautify(JSON.parse(this.apiConfiguration), null, 0),
            isActive: this.isActive,
            externalDeviceCompatible: this.externalDeviceCompatible,
            merchantReferenceField: this.merchantReferenceField,
            storeId: this.storeId
          });

          this.$_onRequestSuccess({
            toastOptions: { message: 'Successfully updated your payment gateway!' },
            options: { closeParent: true }
          });
        }
        catch (error) {
          this.$_onRequestError({
            error,
            toastOptions: {
              message: 'There was an error updating your payment gateway'
            }
          });
        }
      },

      submitHandler() {
        if (this.currentPaymentGateway) {
          this.updatePaymentGateway();
        }
        else {
          this.addPaymentGateway();
        }
      }
    }
  };
</script>

<style lang="sass" scoped>
  .modal-card
    overflow: visible

    .modal-card-body
      overflow: visible
</style>
