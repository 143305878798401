// import { Model } from '@vuex-orm/core';
import Saveable from './_Saveable';
import DeliveryService from './DeliveryService';
import filterObjectKeys from '@/helpers/filter-object-keys';

// classes
import http from '@/services/http';
import StoreDeliveryServiceSchedule from './StoreDeliveryServiceSchedule';



export default class StoreDeliveryService extends Saveable {
    static entity = 'storeDeliveryServices';


    // FIELDS ////////////////////
    static fields() {
      return {
        ...super.fields(),
        id: this.attr(null),
        apiConfiguration: this.attr(null),
        deliveryDistanceInMiles: this.attr(null),
        deliveryServiceChargeAmount: this.attr(null),
        deliveryServiceChargeTypeId: this.attr(null),
        deliveryServiceId: this.attr(null),
        deliveryUpcharge: this.attr(null),
        estimatedDeliveryTime: this.attr(null),
        feeAmount: this.attr(null),
        feeTypeId: this.attr(null),
        isActive: this.attr(true),
        pickupInstructions: this.attr(''),
        storeId: this.attr(null),

        // relationships
        storeDeliveryServiceSchedules: this.hasMany(StoreDeliveryServiceSchedule, 'storeDeliveryServiceId')
      };
    }

    get name() {
      return DeliveryService.find(this.deliveryServiceId)?.name;
    }



    // STATE ////////////////////
    static state() {
      return {
        fetching: false,
        submitting: false
      };
    }

    static $state() {
      return this.store().state.entities.storeDeliveryServices;
    }


    // ACTIONS ////////////////////
    static async addStoreDeliveryService(storeDeliveryService) {
      try {
        this.commit((state) => {
          state.submitting = true;
        });

        const acceptedKeys = [
          'apiConfiguration',
          'deliveryDistanceInMiles',
          'deliveryServiceChargeAmount',
          'deliveryServiceChargeTypeId',
          'deliveryServiceId',
          'deliveryUpcharge',
          'estimatedDeliveryTime',
          'feeAmount',
          'feeTypeId',
          'isActive',
          'pickupInstructions',
          'storeId'
        ];

        const { data } = await http.post(
          `stores/${storeDeliveryService.storeId}/store_delivery_services`,
          { storeDeliveryService: filterObjectKeys(storeDeliveryService, acceptedKeys) }
        );

        // insert data from the network response with the real ID
        this.insert({ data: data.storeDeliveryService });
        // delete the local record with the temp ID
        this.delete(storeDeliveryService.id);

        return data.storeDeliveryService;
      }

      catch (error) {
        throw error;
      }

      finally {
        this.commit((state) => {
          state.submitting = false;
        });
      }
    }

    static async updateStoreDeliveryService(storeDeliveryService) {
      try {
        this.commit((state) => {
          state.submitting = true;
        });

        const acceptedKeys = [
          'apiConfiguration',
          'deliveryDistanceInMiles',
          'deliveryServiceChargeAmount',
          'deliveryServiceChargeTypeId',
          'deliveryServiceId',
          'deliveryUpcharge',
          'estimatedDeliveryTime',
          'feeAmount',
          'feeTypeId',
          'isActive',
          'pickupInstructions',
          'storeId'
        ];

        const response = await http.put(
          `store_delivery_services/${storeDeliveryService.id}`,
          { storeDeliveryService: filterObjectKeys(storeDeliveryService, acceptedKeys) }
        );

        this.update({
          data: response.data.storeDeliveryService
        });
      }

      catch (error) {
        throw error;
      }

      finally {
        this.commit((state) => {
          state.submitting = false;
        });
      }
    }

    static async removeStoreDeliveryService(storeDeliveryService) {
      try {
        this.commit((state) => {
          state.submitting = true;
        });

        if (storeDeliveryService.isSaved) {
          await http.delete(`store_delivery_services/${storeDeliveryService.id}`);
        }

        this.delete(storeDeliveryService.id);
      }

      catch (error) {
        throw error;
      }

      finally {
        this.commit((state) => {
          state.submitting = false;
        });
      }
    }
}
