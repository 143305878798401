<template>
  <b-collapse
    animation="slide"
    class="transaction-admin-detail mar-t-sm has-border has-border-primary-light has-radius has-background-primary-lighter pad-xs"
    :open="false"
  >
    <div
      slot="trigger"
      slot-scope="props"
      role="button"
      class="is-flex justify-between has-cursor-pointer"
    >
      <h5 class="subtitle is-6 is-marginless">
        Admin Details
      </h5>
      <b-icon class="open-indicator" :icon="props.open ? 'angle-down' : 'angle-right'" />
    </div>
    <dl class="is-full-width">
      <div class="info-row">
        <dt class="has-text-grey">Allow Refund On Device:</dt>
        <dd class="has-text-weight-semibold text-ellipsis">
          <b-icon
            :icon="transaction.allowRefundOnDevice ? 'check' : 'times'"
            :type="transaction.allowRefundOnDevice ? 'is-success' : 'is-danger'"
          />
        </dd>
      </div>
      <div class="info-row">
        <dt class="has-text-grey">Allow Refund On Portal:</dt>
        <dd class="has-text-weight-semibold text-ellipsis">
          <b-icon
            :icon="transaction.allowRefundOnPortal ? 'check' : 'times'"
            :type="transaction.allowRefundOnPortal ? 'is-success' : 'is-danger'"
          />
        </dd>
      </div>
      <div class="info-row">
        <dt class="has-text-grey">Created User IP:</dt>
        <dd class="has-text-weight-semibold text-ellipsis">{{ transaction.createdUserIp || 'N/A' }}</dd>
      </div>
      <div class="info-row">
        <dt class="has-text-grey">Created Source:</dt>
        <dd class="has-text-weight-semibold text-ellipsis">{{ transaction.createdSource || 'N/A' }}</dd>
      </div>
      <div class="info-row">
        <dt class="has-text-grey">Client Correlation Id:</dt>
        <dd class="has-text-weight-semibold text-ellipsis">{{ transaction.clientCorrelationId || 'N/A' }}</dd>
      </div>
      <a class="link has-text-weight-bold" @click="openTransactionJSON">
        View Gateway Response and Other Transaction Data
        <b-icon icon="external-link-alt" />
      </a>
    </dl>
  </b-collapse>
</template>

<script>
  import PaymentTransaction from '@/store/classes/PaymentTransaction';

  export default {
    name: 'TransactionAdminDetail',

    props: {
      transactionId: {
        type: Number,
        required: true
      }
    },

    computed: {
      transaction() {
        return PaymentTransaction.find(this.transactionId) || {};
      }
    },

    async created() {
      await this.getPaymentTransactionById();
    },

    methods: {
      async getPaymentTransactionById() {
        try {
          await PaymentTransaction.getPaymentTransactionById(this.transactionId);
        }
        catch (error) {
          this.$_onRequestError({
            error,
            toastOptions: { message: 'There was an error loading the transaction' }
          });
        }
      },

      openTransactionJSON() {
        const file = new Blob(
          [JSON.stringify(this.transaction, null, 2)],
          { type: 'application/json' }
        );
        const fileURL = URL.createObjectURL(file);
        window.open(fileURL);
      }
    }
  };
</script>

<style lang='sass' scoped>
  .transaction-admin-detail
    font-size: .8em

    .info-row
      display: flex
      justify-content: space-between
      align-items: baseline
      margin-bottom: 0.2rem
</style>
