<template>
  <div class="last-modified">
    <span>
      <b-icon
        v-if="showTime"
        class="mar-r-xs"
        pack="far"
        size="is-small"
        icon="calendar-day"
      />
      <span>{{ updatedAt || createdAt | moment('MMM DD, YYYY') }}</span>
    </span>
    <span v-if="showTime" class="mar-l-md">
      <b-icon
        class="mar-r-xs"
        pack="far"
        size="is-small"
        icon="clock"
      />
      <span>{{ updatedAt || createdAt | moment('h:mm A') }}</span>
    </span>
  </div>
</template>

<script>
  export default {
    name: 'LastModifiedTableCell',

    props: {
      createdAt: {
        type: String,
        required: true
      },

      showTime: {
        type: Boolean,
        default: false
      },

      updatedAt: {
        type: String,
        default: ''
      }
    }
  };
</script>

<style lang="sass" scoped>
  .last-modified
    white-space: nowrap
</style>
