import { Model } from '@vuex-orm/core';
import http from '@/services/http';



export default class StoreMenuItemSchedule extends Model {
  static entity = 'storeMenuItemSchedules'

  // FIELDS
  static fields() {
    return {
      id: this.attr(''),
      day: this.attr(''),
      hideHoursDisplay: this.attr(null),
      startTime: this.attr(null),
      endTime: this.attr(null),
      menuItemId: this.attr(''),
      storeId: this.attr('')
    };
  }



  // STATE
  static state() {
    return {
      fetchingCategoryId: false,
      submitting: false
    };
  }


  static $state() {
    return this.store().state.entities.storeMenuItemSchedules;
  }



  // ACTIONS
  static async fetchStoreMenuItemSchedules({ storeId, categoryId }) {
    try {
      this.commit((state) => {
        state.fetchingCategoryId = categoryId;
      });

      const response = await http.get(`stores/${storeId}/store_menu_item_schedules?menu_category_id=${categoryId}`);

      this.insert({ data: response.data.storeMenuItemSchedules });
    }

    catch (error) {
      throw error;
    }

    finally {
      this.commit((state) => {
        state.fetchingCategoryId = false;
      });
    }
  }

  static async createStoreMenuItemSchedule(storeMenuItemSchedule) {
    try {
      this.commit((state) => {
        state.submitting = true;
      });

      const response = await http.post(`menu_items/${storeMenuItemSchedule.menuItemId}/store_menu_item_schedules`, {
        storeMenuItemSchedule
      });

      this.insert({ data: response.data.storeMenuItemSchedule });
    }

    catch (error) {
      throw error;
    }

    finally {
      this.commit((state) => {
        state.submitting = false;
      });
    }
  }

  static async updateStoreMenuItemSchedule({ id, endTime, startTime, hideHoursDisplay }) {
    try {
      this.commit((state) => {
        state.submitting = true;
      });

      const response = await http.put(`store_menu_item_schedules/${id}`, {
        storeMenuItemSchedule: {
          startTime,
          endTime,
          hideHoursDisplay
        }
      });

      this.update({ data: response.data.storeMenuItemSchedule });
    }

    catch (error) {
      throw error;
    }

    finally {
      this.commit((state) => {
        state.submitting = false;
      });
    }
  }

  static async deleteStoreMenuItemSchedule(id) {
    try {
      this.commit((state) => {
        state.submitting = true;
      });

      await http.delete(`store_menu_item_schedules/${id}`);
      this.delete(id);
    }

    catch (error) {
      throw error;
    }

    finally {
      this.commit((state) => {
        state.submitting = false;
      });
    }
  }
}
