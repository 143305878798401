/* eslint-disable import/no-cycle */
import { Model } from '@vuex-orm/core';
import http from '@/services/http';
import filterObjectKeys from '@/helpers/filter-object-keys';



export default class Segmentation extends Model {
  static entity = 'segmentations'

  static fields() {
    return {
      id: this.uid(),
      merchantId: this.attr(''),
      name: this.attr(''),
      campaignIds: this.attr(''),
      isCustomCriteria: this.attr(false),
      criteria: this.attr({
        // Orders Criteria
        orderTypeIds: [],
        hasNoOrders: undefined,
        daysSinceLastOrder: undefined,
        orderAverages: undefined,
        orderItemCategory: undefined,
        favoriteItem: undefined,
        // { menuItemId: null }

        // Registration Criteria
        registeredDateRange: undefined,
        // { beginDate: null, endDate: null }
        birthdayDateRange: undefined,
        // { beginDate: null, endDate: null }
        daysSinceRegistered: undefined,
        daysSinceLastLogin: undefined,
        mostVisitedStoreId: undefined,
        lastVisitedStoreId: undefined,
        favoriteStoreId: undefined,
        orderCounts: undefined,
        // { beginTime, endTime, daysBackType, range: { aboveAmount, belowAmount }, equality: { operator, amount } }

        // Total dollar Amounts Spend
        lifetimeOrderTotalRange: undefined,
        // { aboveAmount: null, belowAmount: null }

        // Total Amount Spent Since Account Creation
        lifetimeOrderTotalEquality: undefined,
        // { operator: null, amount: null }

        // Average Dollar Amounts Spend
        averageOrderTotalRange: undefined,
        // { aboveAmount: null, belowAmount: null }

        // Loyalty Criteria
        enrolledInLoyalty: undefined,
        offersRedeemed: undefined,
        loyaltyPointBalance: undefined,
        loyaltyProgramTier: undefined,
        // { programTierPublicIds: null }

        // Spend Percentile
        spendPercentile: undefined,
        // { percentile: null, range: null, daysBackType }

        // Order Placed At Store
        orderPlacedAtStore: undefined,
        // { storeIds: null, daysBackType }

        // Order Totals
        orderTotals: undefined,
        // { dateRange: { beginDate, endDate }, range: { aboveAmount, belowAmount }, equality: { operator, amount } }

        // Order Item Attribute
        orderItemAttribute: undefined,
        // { daysBackType, menuAttributeId, range: { aboveAmount, belowAmount }, equality: { operator, amount } }

        // Lapsed Visits
        lapsedVisits: undefined,
        // { missedNumberOfVisits, daysSinceLastLapsedNotification }

        // Gift Card Purchases
        giftCardPurchaseCount: undefined,
        // { daysBackType, range: { aboveAmount, belowAmount }, equality: { operator, amount } }

        // Operating System
        operatingSystem: undefined
      })

    };
  }


  // STATE //////////////////////
  static state() {
    return {
      fetching: false,
      submitting: false,
      deleting: false
    };
  }

  static $state() {
    return this.store().state.entities.segmentations;
  }

  static acceptedKeys = [
    'name',
    'criteria'
  ]

  static acceptedCriteriaKeys = [
    'averageOrderTotalRange',
    'daysSinceLastOrder',
    'daysSinceRegistered',
    'daysSinceLastLogin',
    'enrolledInLoyalty',
    'enrolledInLoyalty',
    'hasNoOrders',
    'lifetimeOrderTotalEquality',
    'lifetimeOrderTotalRange',
    'loyaltyPointBalance',
    'mostVisitedStoreId',
    'lastVisitedStoreId',
    'favoriteStoreId',
    'offersRedeemed',
    'orderAverages',
    'orderCounts',
    'orderItemCategory',
    'orderTypeIds',
    'registeredDateRange',
    'birthdayDateRange',
    'loyaltyProgramTier',
    'favoriteItem',
    'spendPercentile',
    'orderPlacedAtStore',
    'orderTotals',
    'orderItemAttribute',
    'lapsedVisits',
    'giftCardPurchaseCount',
    'operatingSystem'
  ]


  // Actions
  static async fetchSegmentations() {
    try {
      this.commit((state) => {
        state.fetching = true;
      });

      const merchantId = this.store().state.entities.merchants.selectedMerchantId;

      const response = await http.get(`merchants/${merchantId}/segmentations`);

      this.create({ data: response.data.segmentations });
    }
    catch (error) {
      throw error;
    }
    finally {
      this.commit((state) => {
        state.fetching = false;
      });
    }
  }

  static async fetchSegmentationById(segmentationId) {
    try {
      this.commit((state) => {
        state.fetching = true;
      });

      const merchantId = this.store().state.entities.merchants.selectedMerchantId;

      const response = await http.get(`merchants/${merchantId}/segmentations/${segmentationId}`);

      this.insert({ data: response.data.segmentation });
    }
    catch (error) {
      throw error;
    }
    finally {
      this.commit((state) => {
        state.fetching = false;
      });
    }
  }

  static async addSegmentation(segmentation) {
    try {
      this.commit((state) => {
        state.submitting = true;
      });

      const merchantId = this.store().state.entities.merchants.selectedMerchantId;
      const criteriaJson = filterObjectKeys(segmentation.criteria, this.acceptedCriteriaKeys);
      segmentation.criteria = criteriaJson;

      const response = await http.post(`merchants/${merchantId}/segmentations`, {
        segmentation: filterObjectKeys(segmentation, this.acceptedKeys)
      });

      this.insert({ data: response.data.segmentation });
    }
    catch (error) {
      throw error;
    }
    finally {
      this.commit((state) => {
        state.submitting = false;
      });
    }
  }

  static async updateSegmentation(segmentation) {
    try {
      this.commit((state) => {
        state.submitting = true;
      });

      const merchantId = this.store().state.entities.merchants.selectedMerchantId;
      const criteriaJson = filterObjectKeys(segmentation.criteria, this.acceptedCriteriaKeys);
      segmentation.criteria = criteriaJson;

      const response = await http.put(`merchants/${merchantId}/segmentations/${segmentation.id}`, {
        segmentation: filterObjectKeys(segmentation, this.acceptedKeys)
      });

      this.update({ data: response.data.segmentation });
    }
    catch (error) {
      throw error;
    }
    finally {
      this.commit((state) => {
        state.submitting = false;
      });
    }
  }

  static async deleteSegmentation(segmentationId) {
    try {
      this.commit((state) => {
        state.deleting = true;
      });

      const merchantId = this.store().state.entities.merchants.selectedMerchantId;

      await http.delete(`merchants/${merchantId}/segmentations/${segmentationId}`);

      this.delete(segmentationId);
    }

    catch (error) {
      throw error;
    }

    finally {
      this.commit((state) => {
        state.deleting = false;
      });
    }
  }
}
