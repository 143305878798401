<template>
  <validated-form
    ref="form"
    :disabled="!$can('update', 'MenuItemModifierGroup') || !$_menuPermissions.EDIT_RESOURCE"
    auto-focus
    form-id="addEditModifierGroupForm"
    :class="{ 'pad-b-md': !$can('update', 'MenuItemModifierGroup') || !$_menuPermissions.EDIT_RESOURCE }"
    @valid-submit="handleSubmit"
  >
    <modal-card :title="!isFetchingModifierGroup && modalTitle">
      <b-loading v-if="isFetchingModifierGroup" :active="isFetchingModifierGroup" :is-full-page="false" />

      <modifier-group-settings-form v-model="form" :item="item" />

      <template v-if="$can('update', 'MenuItemModifierGroup') && $_menuPermissions.EDIT_RESOURCE" #footer>
        <div class="buttons all-bold">
          <b-button
            rounded
            @click="$_confirmCloseModal({ programmatic: true })"
          >
            Cancel
          </b-button>
          <b-button
            v-tabbable
            rounded
            native-type="submit"
            type="is-primary"
            :loading="isModifierGroupSubmitting"
            :disabled="isModifierGroupSubmitting"
          >
            Save
          </b-button>
        </div>
      </template>
    </modal-card>
  </validated-form>
</template>

<script>
  import Item from '@/store/classes/Item';
  import ModifierGroup from '@/store/classes/ModifierGroup';
  import confirmModalCloseMixin from '@/mixins/confirm-modal-close';
  import featurePermissionsMixin from '@/mixins/featurePermissions';
  import merchantMixin from '@/mixins/merchant';
  import { modifierGroupPortionTemplates, modifierGroupTypes } from '@/constants/modifierGroups';
  import capitalCase from '@/helpers/capitalCase';
  import ModifierGroupSettingsForm from './modifier-groups/modifier-group-settings-form.vue';


  export default {
    name: 'AddEditModifierGroupModal',

    components: { ModifierGroupSettingsForm },

    mixins: [confirmModalCloseMixin, featurePermissionsMixin, merchantMixin],

    props: {
      modifierGroupId: {
        type: Number,
        default: null
      },

      menuItemId: {
        type: [String, Number],
        required: true
      },

      modifierId: {
        type: Number,
        default: null
      },

      type: {
        type: String,
        default: ''
      },

      mode: {
        type: String,
        default: 'create',
        validator(value) {
          return ['create', 'read', 'update'].includes(value);
        }
      }
    },

    data() {
      return {
        form: new ModifierGroup(),
        modifierGroupTypes,
        modifierGroupPortionTemplates
      };
    },

    computed: {
      modifierGroup() {
        return ModifierGroup.query().with('menuItemModifiers').find(this.modifierGroupId);
      },

      isFetchingModifierGroup() {
        return this.modifierGroupId
          && this.modifierGroupId === ModifierGroup.$state().fetchingModifierGroupId;
      },

      item() {
        return Item.find(this.menuItemId);
      },

      modalTitle() {
        let title = '';
        switch (this.mode) {
          case 'create':
            title = Object.values(modifierGroupTypes).includes(this.type) ? `Create ${capitalCase(this.type)} Group` : 'Create Modifier Group';
            break;

          case 'update':
            title = this.modifierGroup ? this.modifierGroup.displayName : '';
            break;

          default:
            break;
        }

        return title;
      },

      isModifierGroupSubmitting() {
        return ModifierGroup.$state().submitting;
      }
    },

    async created() {
      await this.onCreated();
    },

    methods: {
      async onCreated() {
        if (this.mode === 'update') {
          await this.fetchModifierGroup();
          this.form = JSON.parse(JSON.stringify(this.modifierGroup));
        }
        else {
          this.form.type = this.type;
        }

        this.form.menuItemModifierId = this.modifierId;
      },

      async fetchModifierGroup() {
        try {
          await ModifierGroup.fetchModifierGroup({ modifierGroupId: this.modifierGroupId, includeMenuItemMetadata: true });
        }
        catch (error) {
          this.$_onRequestError({
            toastOptions: {
              message: 'There was an error fetching the modifier group'
            },
            error
          });
        }
      },

      handleSubmit() {
        switch (this.mode) {
          case 'create':
            this.addModifierGroup();
            break;

          case 'update':
            this.updateModifierGroup();
            break;

          default:
            break;
        }
      },

      async addModifierGroup() {
        try {
          const modifierGroup = await ModifierGroup.addModifierGroup({ menuItemId: this.menuItemId, modifierGroup: this.form });

          this.$_onRequestSuccess({
            toastOptions: {
              message: `Successfully added <b>${this.form.displayName}</b> to your modifier groups!`
            },
            options: {
              closeParent: true,
              emit: { name: 'main-menu:add-modifier-group', arg: modifierGroup.id }
            }
          });
        }

        catch (error) {
          this.$_onRequestError({
            error,
            toastOptions: {
              message: `Unable to add <b>${this.form.displayName}</b> to your modifier groups`
            }
          });
        }
      },

      async updateModifierGroup() {
        try {
          await ModifierGroup.updateModifierGroup(this.form);

          this.$_onRequestSuccess({
            toastOptions: {
              message: `Successfully updated <b>${this.form.displayName}!</b>`
            },
            options: {
              closeParent: true,
              emit: { name: 'modifier-group-updated' }
            }
          });
        }

        catch (error) {
          this.$_onRequestError({
            error,
            toastOptions: {
              message: `Unable to update <b>${this.form.displayName}</b>`
            }
          });
        }
      }
    }
  };
</script>

<style lang="sass" scoped>
  .modal-card
    max-width: 500px
</style>
