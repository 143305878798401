<template>
  <validated-form
    ref="form"
    auto-focus
    form-id="storeModifierForm"
    :class="{ 'pad-b-md': !$can('update', 'StoreMenuItemModifier', 'price') }"
    @valid-submit="handleSubmit"
  >
    <modal-card :title="modifier.displayName" :show-overflow="true">
      <b-loading :active="isLoadingSourceModifiers" :is-full-page="false" />
      <template
        v-if="($can('read', 'StoreMenuItemModifier', 'price') || $can('read', 'StoreMenuItemModifier', 'totalSalesTaxRate'))
          && $_selectedMerchant.features.menuPrices"
      >
        <validated-text-input
          v-if="!hasModifierPriceOverrides"
          v-model="form.price"
          label="Price"
          name="price"
          rules="min_value:0"
          type="dollars"
          placeholder="Enter an optional price for this modifier..."
          :disabled="(!($can('update', 'StoreMenuItemModifier', 'price') && $_menuPermissions.UPDATE_PRICE_TAX) || store.isReadOnlyLocationMenu)"
        />
        <template v-else-if="!isLoadingSourceModifiers">
          <p class="has-text-weight-bold pad-b-md">Prices</p>
          <validated-text-input
            v-for="override in form.storeMenuItemModifierPriceOverrides"
            :key="override.id"
            v-model="override.price"
            :label="sourceModifierNames[override.sourceMenuItemModifierId]"
            label-position="on-border"
            :name="`price-override-${override.id}`"
            rules="min_value:0"
            type="dollars"
            disabled
          />
        </template>
      </template>

      <validated-input
        name="suspendUntilDate"
        :label="`Suspend Until (${timeZoneAbbr()})`"
        tooltip="This item will be unavailable until the specified time."
      >
        <b-datetimepicker
          v-model="suspendUntilDate"
          :min-datetime="adjustDate(minDate, timeZoneOffset)"
          placeholder="Click to select..."
          :nearby-selectable-month-days="true"
          :mobile-native="false"
          position="is-top-right"
          icon-right="times-circle"
          :disabled="!$can('update', 'StoreMenuItem', 'suspendUntilDate')"
          icon-right-clickable
          @icon-right-click="clearDateTime"
        >
          <template #right>
            <b-button
              label="Clear"
              @click="clearDateTime"
            />
          </template>
        </b-datetimepicker>
      </validated-input>
      <template #footer>
        <div class="buttons all-bold">
          <b-button
            rounded
            @click="$_confirmCloseModal({ programmatic: true })"
          >
            Cancel
          </b-button>
          <b-button
            v-tabbable
            rounded
            native-type="submit"
            type="is-primary"
            :loading="isLoading"
          >
            Save
          </b-button>
        </div>
      </template>
    </modal-card>
  </validated-form>
</template>

<script>
  import Store from '@/store/classes/Store';
  import StoreModifier from '@/store/classes/StoreModifier';
  import Modifier from '@/store/classes/Modifier';
  import confirmModalCloseMixin from '@/mixins/confirm-modal-close';
  import merchantMixin from '@/mixins/merchant';
  import moment from 'moment-timezone';



  export default { // TEST ALL
    name: 'StoreModifierModal',

    mixins: [confirmModalCloseMixin, merchantMixin],

    props: {
      modifier: {
        type: Object,
        required: true
      },

      storeModifier: {
        type: Object,
        default: null
      },

      storeId: {
        type: [String, Number],
        required: true
      }
    },

    data() {
      return {
        form: null,
        sourceModifierNames: {},
        isLoadingSourceModifiers: false
      };
    },

    computed: {
      _storeModifier() {
        return this.storeModifier || new StoreModifier({ menuId: this.storeId });
      },

      store() {
        return Store.find(this.storeId);
      },

      hasModifierPriceOverrides() {
        return !!this.form.storeMenuItemModifierPriceOverrides?.length;
      },

      isLoading() {
        return StoreModifier.$state().submitting;
      },

      minDate() {
        return moment.utc().startOf('day').toDate();
      },

      suspendUntilDate: {
        get() {
          return this.form.suspendUntilDate ? this.adjustDate(this.form.suspendUntilDate) : null;
        },
        set(value) {
          this.form.suspendUntilDate = this.adjustDate(value, true).toISOString();
        }
      },

      timeZoneOffset() {
        const storeUtcOffset = moment.tz(moment(), this.store.ianaTimezoneId).utcOffset();
        const localUtcOffset = moment().utcOffset();
        return storeUtcOffset - localUtcOffset;
      }
    },

    created() {
      this.onCreated();
    },

    methods: {
      async onCreated() {
        this.form = JSON.parse(JSON.stringify(this._storeModifier));
        if (this.hasModifierPriceOverrides) {
          await this.loadSourceModifierNames();
        }
      },

      async loadSourceModifierNames() {
        try {
          this.isLoadingSourceModifiers = true;

          const promises = this.form.storeMenuItemModifierPriceOverrides.map(async (override) => {
            if (!Modifier.find(override.sourceMenuItemModifierId)) {
              await Modifier.fetchModifier(override.sourceMenuItemModifierId);
            }
            const modifier = Modifier.find(override.sourceMenuItemModifierId);
            this.$set(this.sourceModifierNames, override.sourceMenuItemModifierId, modifier?.displayName || '');
          });

          await Promise.all(promises);
        }
        catch (error) {
          this.$_onRequestError({
            toastOptions: { message: 'An error occurred while loading modifier names' },
            error
          });
        }
        finally {
          this.isLoadingSourceModifiers = false;
        }
      },

      handleSubmit() {
        if (this.storeModifier) {
          this.updateStoreModifier();
        }
        else {
          this.addStoreModifier();
        }
      },

      adjustDate(date, reverse) {
        const offsetMinutes = reverse ? this.timeZoneOffset * -1 : this.timeZoneOffset;
        return moment(date).add(offsetMinutes, 'minutes').toDate();
      },

      timeZoneAbbr() {
        return moment.tz.zone(this.store.ianaTimezoneId).abbr(Date.now());
      },

      clearDateTime() {
        this.form.suspendUntilDate = null;
      },

      async addStoreModifier() {
        try {
          await StoreModifier.addStoreModifier({ modifierId: this.modifier.id, storeMenuItemModifier: this.form });
          this.$_onRequestSuccess({
            toastOptions: { message: `<b>${this.modifier.displayName}</b> successfully added!` },
            options: { closeParent: true }
          });
        }

        catch (error) {
          this.$_onRequestError({
            toastOptions: { message: `An error occured while adding <b>${this.modifier.displayName}</b>` },
            error
          });
        }
      },

      async updateStoreModifier() {
        try {
          await StoreModifier.updateStoreModifier(this.form);
          this.$_onRequestSuccess({
            toastOptions: { message: `<b>${this.modifier.displayName}</b> successfully updated!` },
            options: { closeParent: true }
          });
        }

        catch (error) {
          this.$_onRequestError({
            toastOptions: { message: `An error occured while updating <b>${this.modifier.displayName}</b>` },
            error
          });
        }
      }
    }
  };
</script>

