<template>
  <section-wrapper title="Dine In Table Numbers" :loading="isFetchingMappingStoreSublocations">
    <b-table
      :data="sortedSublocations"
      class="is-middle-aligned flex-grow"
      hoverable
      :mobile-cards="false"
    >
      <template #empty>
        <p class="has-text-centered pad is-size-5 has-text-grey-light">
          No Table Numbers Configured
        </p>
      </template>

      <b-table-column v-slot="{ row }" sortable field="sublocationId" label="Number">
        {{ row.sublocationId }}
      </b-table-column>

      <b-table-column
        v-slot="{ row }"
        centered
        cell-class="actions"
        label="Actions"
        width="1"
      >
        <div class="is-flex dist-x-xs">
          <b-button class="is-transparent" type="is-white" @click="openMappingStoreSublocationModal('update', row)">
            <b-icon icon="pencil" size="is-small" />
          </b-button>
          <b-button
            class="is-transparent"
            type="is-white"
            @click="openRemoveMappingStoreSublocationModal(row)"
          >
            <b-icon icon="trash-alt" type="is-danger" size="is-small" />
          </b-button>
        </div>
      </b-table-column>
    </b-table>
    <div class="pad-x pad-y-sm has-border-top has-border-grey-lighter">
      <b-button
        inverted
        type="is-primary"
        class="is-transparent"
        icon-left="plus"
        @click="openMappingStoreSublocationModal('create')"
      >
        Table Number
      </b-button>
    </div>
  </section-wrapper>
</template>



<script>
  import MappingStoreSublocation from '@/store/classes/MappingStoreSublocation';
  import addEditMappingStoreSublocationModal from './add-edit-mapping-store-sublocation-modal.vue';
  import alertModal from '@/components/globals/alert-modal.vue';
  import merchantMixin from '@/mixins/merchant';
  import posTypes from '@/constants/posTypes';



  export default {
    name: 'MappingStoreSublocations',

    mixins: [merchantMixin],

    props: {
      store: {
        type: Object,
        required: true
      }
    },

    data() {
      return {
        posTypes
      };
    },

    computed: {
      isFetchingMappingStoreSublocations() {
        return MappingStoreSublocation.$state().fetching;
      },

      mappingStoreSublocations() {
        return MappingStoreSublocation.query().where('storeId', this.store.storeId).get();
      },

      sortedSublocations() {
        // https://github.com/vuex-orm/vuex-orm/issues/300#issuecomment-821969191
        const clonedSublocations = this.$clone(this.mappingStoreSublocations);
        return clonedSublocations.sort((a, b, order) => (!order
          ? a.sublocationId.localeCompare(b.sublocationId, 'en', { numeric: true })
          : b.sublocationId.localeCompare(a.sublocationId, 'en', { numeric: true })));
      }
    },

    created() {
      this.onCreated();
    },

    methods: {
      onCreated() {
        this.fetchMappingStoreSublocations();
      },

      async fetchMappingStoreSublocations() {
        try {
          await MappingStoreSublocation.fetchMappingStoreSublocations(this.store.storeId);
        }
        catch (error) {
          this.$_onRequestError({
            toastOptions: {
              message: 'There was an error fetching the mapping store sublocations'
            },
            error
          });
        }
      },

      openMappingStoreSublocationModal(mode, mappingStoreSublocation) {
        this.$buefy.modal.open({
          parent: this,
          component: addEditMappingStoreSublocationModal,
          hasModalCard: true,
          trapFocus: true,
          canCancel: true,
          customClass: 'auto-width',
          props: {
            storeId: this.store.storeId,
            mode,
            mappingStoreSublocation: mappingStoreSublocation || new MappingStoreSublocation(),
            unavailableSublocationIds: this.mappingStoreSublocations.map(mss => mss.sublocationId)
          }
        });
      },

      openRemoveMappingStoreSublocationModal(selectedMappingStoreSublocation) {
        this.$buefy.modal.open({
          parent: this,
          component: alertModal,
          hasModalCard: true,
          trapFocus: true,
          canCancel: ['escape', 'outside'],
          props: {
            icon: 'trash-alt',
            title: 'Delete Table Number',
            message: `Are you sure you want to delete <b>${selectedMappingStoreSublocation.sublocationId}</b> Table Number`,
            type: 'is-danger',
            horizontal: true,
            showCloseButton: false,
            buttons: [
              { text: 'Cancel' },
              {
                text: 'Delete',
                primary: true,
                onClick: () => this.removeMappingStoreSublocation(selectedMappingStoreSublocation)
              }
            ]
          }
        });
      },

      async removeMappingStoreSublocation(selectedMappingStoreSublocation) {
        try {
          await MappingStoreSublocation.removeMappingStoreSublocation(selectedMappingStoreSublocation.id);
          this.$_onRequestSuccess({
            toastOptions: {
              message: 'Successfully removed your table number!'
            }
          });
        }
        catch (error) {
          this.$_onRequestError({
            error,
            toastOptions: {
              message: 'There was an error removing your table number'
            }
          });
        }
      }
    }
  };
</script>
