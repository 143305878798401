<template>
  <validated-form
    ref="form"
    form-id="contentPrivacyPolicyForm"
    @valid-submit="handleSubmit(form)"
  >
    <panel
      title="Privacy Policy"
      collapsible
      :loading="isFetching"
    >
      <template #buttons>
        <b-button
          v-if="$can('create', 'MerchantTermsOfService')"
          rounded
          class="is-bold"
          size="is-medium"
          native-type="submit"
          type="is-primary"
          :loading="is.submitting"
        >
          Save
        </b-button>
      </template>

      <template v-if="$can('create', 'MerchantTermsOfService') && !showPreview">
        <validated-text-input
          v-model="form.privacyPolicy"
          :has-icon="false"
          label="HTML Content"
          name="privacyPolicy"
          rows="20"
          type="textarea"
          rules="required"
          maxlength="65000"
          has-counter
          monospaced
          :spellcheck="false"
          class="mar-b"
        />
      </template>
      <div v-else key="preview-privacy-policy" class="privacy-policy-preview">
        <div
          class="has-text-justified"
          v-html="form.privacyPolicy"
        />
      </div>

      <b-button
        v-if="$can('create', 'MerchantTermsOfService')"
        :key="showPreview ? 'show' : 'hide'"
        rounded
        :class="showPreview && 'mar-t-lg'"
        @click="showPreview = !showPreview"
      >
        {{ showPreview ? 'Hide' : 'Show' }} Preview
      </b-button>
    </panel>
  </validated-form>
</template>


<script>
  import merchantMixin from '@/mixins/merchant';

  // classes
  import MerchantTermsOfService from '@/store/classes/MerchantTermsOfService';

  export default {
    name: 'ContentPrivacyPolicyForm',

    mixins: [merchantMixin],

    data() {
      return {
        is: { submitting: false },
        showPreview: false,
        form: {}
      };
    },
    computed: {
      isFetching() {
        return MerchantTermsOfService.$state().fetching;
      },

      merchantTermsOfService() {
        return MerchantTermsOfService.query()
          .where('merchantId', this.$_selectedMerchantId)
          .first() || new MerchantTermsOfService();
      }
    },

    created() {
      this.onCreated();
    },

    methods: {
      async onCreated() {
        await this.fetchMerchantPrivacyPolicy();
        this.form = this.$clone(this.merchantTermsOfService);
      },

      async fetchMerchantPrivacyPolicy() {
        try {
          await MerchantTermsOfService.fetchMerchantTermsOfServices();
        }

        catch (error) {
          this.$_onRequestError({
            error,
            toastOptions: {
              message: 'There was an error fetching your Privacy Policy'
            }
          });
        }
      },

      async handleSubmit(form) {
        this.$set(this.is, 'submitting', true);
        try {
          if (this.merchantTermsOfService.id) {
            await MerchantTermsOfService.updateMerchantTermsOfService({
              privacyPolicy: form.privacyPolicy,
              id: this.merchantTermsOfService.id
            });
          }
          else {
            await MerchantTermsOfService.createMerchantTermsOfService({
              merchantId: this.$_selectedMerchantId,
              merchantTermsOfService: {
                privacyPolicy: form.privacyPolicy
              }
            });
          }

          this.form = this.$clone(this.merchantTermsOfService);

          this.$_onRequestSuccess({
            toastOptions: {
              message: 'Privacy Policy have been successfully updated!'
            }
          });
        }

        catch (error) {
          this.$_onRequestError({
            error,
            toastOptions: {
              message: 'There was an error updating your Privacy Policy'
            }
          });
        }

        finally {
          this.$set(this.is, 'submitting', false);
        }
      }
    }
  };

</script>

<style lang="sass" scoped>
  .privacy-policy-preview
    max-height: 600px
    overflow-y: auto

</style>
