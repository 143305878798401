/* eslint-disable import/prefer-default-export */
const emitNativeInput = (el) => {
  // NOTE: many of the Buefy components do not bubble up native input events
  // so thus we have to do it manually for validated-form
  const event = new Event('input', { bubbles: true });
  el.dispatchEvent(event);
};

export {
  emitNativeInput
};
