import _Order from './_Order';
import http from '@/services/http';



export default class KdsOrder extends _Order {
  static entity = 'orders_kds';

  static primaryKey = 'orderId';



  // FIELDS //////////////////////
  static fields() {
    return {
      ...super.fields()
    };
  }

  get orderTotalWithExternalPayment() {
    return super.orderTotalWithExternalPayment;
  }



  // STATE //////////////////////
  static state() {
    return {
      fetchingAll: false,
      selectedOrderId: null,
      isSaving: false,
      printing: false
    };
  }

  static $state() {
    return this.store().state.entities.orders_kds;
  }



  // ACTIONS //////////////////////
  static setSelectedOrderId(orderId) {
    this.commit((state) => {
      state.selectedOrderId = orderId;
    });
  }

  static async fetchOrders(options) {
    try {
      this.commit((state) => {
        state.fetchingAll = true;
      });

      const merchantId = this.store().state.entities.merchants.selectedMerchantId;
      const { data } = await http.get(`merchants/${merchantId}/orders/kds2`, {
        params: {
          store_id: options.storeId,
          preparation_status: options.preparationStatus,
          count: options.perPage,
          page: options.page,
          guest_name: options.guestName,
          ticket_number: options.ticketNumber,
          platform_type: options.platformType,
          fulfillment_type: options.fulfillmentType,
          phone_number: options.phoneNumber
        }
      });

      this.create({ data: data.orders });
    }

    catch (error) {
      throw error;
    }

    finally {
      this.commit((state) => {
        state.fetchingAll = false;
      });
    }
  }

  static async fetchOrder(id) {
    try {
      const merchantId = this.store().state.entities.merchants.selectedMerchantId;
      const { data } = await http.get(`merchants/${merchantId}/orders/${id}`);

      this.insertOrUpdate({ data: data.order });

      return data.order;
    }

    catch (error) {
      throw error;
    }
  }

  static async fetchOrderCounts(storeId, fingerprint, options) {
    try {
      const merchantId = this.store().state.entities.merchants.selectedMerchantId;
      const { data } = await http.get(`merchants/${merchantId}/orders/kds_order_counts`, {
        params: {
          store_id: storeId,
          caller: fingerprint,
          platform_type: options?.platformType,
          fulfillment_type: options?.fulfillmentType
        }
      });

      const orderCounts = data.orderCounts.reduce((obj, { count, orderIds, preparationStatus }) => {
        obj[preparationStatus] = { count, orderIds };
        return obj;
      }, {
        New: { count: 0, orderIds: [] },
        InPrep: { count: 0, orderIds: [] },
        PickedUp: { count: 0, orderIds: [] },
        Completed: { count: 0, orderIds: [] },
        Canceled: { count: 0, orderIds: [] }
      });

      return orderCounts;
    }

    catch (error) {
      throw error;
    }
  }

  static async updateOrderPrepStatus(orderId, updatedFields) {
    try {
      const merchantId = this.store().state.entities.merchants.selectedMerchantId;
      const { data } = await http.put(`merchants/${merchantId}/orders/${orderId}/prep_status`, {
        order: updatedFields
      });

      return this.update({
        where: orderId,
        data: data.order
      });
    }

    catch (error) {
      throw error;
    }
  }

  static async cancelOrder(orderId) {
    try {
      const merchantId = this.store().state.entities.merchants.selectedMerchantId;
      const response = await http.put(`merchants/${merchantId}/orders/${orderId}/cancel`);

      this.update({
        where: orderId,
        data: response.data.order
      });
    }

    catch (error) {
      throw error;
    }
  }

  static async toggleItemRedemption(orderId, orderItems) {
    try {
      this.commit((state) => {
        state.isSaving = true;
      });

      const merchantId = this.store().state.entities.merchants.selectedMerchantId;
      const { data } = await http.put(`merchants/${merchantId}/orders/${orderId}/toggle_item_redemption`, { orderItems });

      this.update({
        where: orderId,
        data: data.order
      });
    }

    catch (error) {
      throw error;
    }

    finally {
      this.commit((state) => {
        state.isSaving = false;
      });
    }
  }

  static async printOrder(orderId) {
    try {
      this.commit((state) => {
        state.printing = true;
      });

      const merchantId = this.store().state.entities.merchants.selectedMerchantId;
      await http.post(`merchants/${merchantId}/orders/${orderId}/print`);
    }
    catch (error) {
      throw error;
    }
    finally {
      this.commit((state) => {
        state.printing = false;
      });
    }
  }
}
