import { Model } from '@vuex-orm/core';
import http from '@/services/http';



export default class CampaignsSegmentation extends Model {
  static entity = 'campaignsSegmentations'


  // FIELDS //////////////////////
  static fields() {
    return {
      id: this.attr(''),
      segmentationId: this.attr(''),
      campaignGuid: this.attr('')
    };
  }



  // STATE //////////////////////
  static state() {
    return {
      submitting: false
    };
  }

  static $state() {
    return this.store().state.entities.campaignsSegmentations;
  }


  // ACTIONS //////////////////////
  static async addSegmentationToCampaign(campaignsSegmentation) {
    try {
      this.commit((state) => {
        state.submitting = true;
      });

      const { data } = await http.post(`campaigns/${campaignsSegmentation.campaignGuid}/campaigns_segmentations`, { campaignsSegmentation });

      this.insert({
        data: data.campaignsSegmentation
      });
    }

    catch (error) {
      throw error;
    }

    finally {
      this.commit((state) => {
        state.submitting = false;
      });
    }
  }

  static async removeSegmentationFromCampaign({ campaignsSegmentationId, campaignGuid, segmentationId }) {
    try {
      this.commit((state) => {
        state.submitting = true;
      });

      await http.delete(`campaigns/${campaignGuid}/campaigns_segmentations/${segmentationId}`);

      this.delete(campaignsSegmentationId);
    }

    catch (error) {
      throw error;
    }

    finally {
      this.commit((state) => {
        state.submitting = false;
      });
    }
  }
}
