import http from '@/services/http';
import { Model } from '@vuex-orm/core';
import LoyaltyProvider from '@/store/classes/LoyaltyProvider';


export default class MerchantLoyaltyProviderConfiguration extends Model {
  static entity = 'merchantLoyaltyProviderConfigurations'


  // FIELDS //////////////////////
  static fields() {
    return {
      id: this.attr(undefined),
      apiConfiguration: this.attr('{}'),
      apiEndpoint: this.attr(undefined),
      circuitBreakerConfiguration: this.attr(undefined),
      externalOrderEnabled: this.attr(undefined),
      isActive: this.attr(false),
      loyaltyProviderId: this.attr(undefined),
      membershipTierBadgeSourceId: this.attr(undefined),
      merchantId: this.attr(undefined),
      orderAheadEnabled: this.attr(false),
      payAtTableAuthenticationTypeId: this.attr(undefined),
      payAtTableEnabled: this.attr(false)
    };
  }

  get loyaltyProviderName() {
    return LoyaltyProvider.find(this.loyaltyProviderId)?.name;
  }

  static hasCardfreeLoyalty() {
    return this.all().some(mlpc => mlpc.loyaltyProviderId === 1);
  }


  // STATE //////////////////////
  static state() {
    return {
      fetching: false,
      submitting: false
    };
  }

  static $state() {
    return this.store().state.entities.merchantLoyaltyProviderConfigurations;
  }



  // ACTIONS //////////////////////
  static async fetchMerchantLoyaltyProviderConfigurations(merchantId, options = {}) {
    if (this.exists()) return;

    try {
      this.commit((state) => {
        state.fetching = true;
      });

      const alreadyFetched = this.query().where('merchantId', merchantId).exists();
      if (!alreadyFetched || options.forceFetch) {
        const { data } = await http.get(`merchants/${merchantId}/merchant_loyalty_provider_configurations`);
        this.create({ data: data.merchantLoyaltyProviderConfigurations });
      }
    }

    catch (error) {
      throw error;
    }

    finally {
      this.commit((state) => {
        state.fetching = false;
      });
    }
  }

  static async createMerchantLoyaltyProviderConfiguration({ merchantId, ...merchantLoyaltyProviderConfiguration }) {
    try {
      this.commit((state) => {
        state.submitting = true;
      });

      const { data } = await http.post(`merchants/${merchantId}/merchant_loyalty_provider_configurations`, {
        merchantLoyaltyProviderConfiguration
      });
      this.insert({ data: data.merchantLoyaltyProviderConfiguration });
    }

    catch (error) {
      throw error;
    }

    finally {
      this.commit((state) => {
        state.submitting = false;
      });
    }
  }

  static async updateMerchantLoyaltyProviderConfiguration({ id, ...merchantLoyaltyProviderConfiguration }) {
    try {
      this.commit((state) => {
        state.submitting = true;
      });

      const { data } = await http.put(`merchant_loyalty_provider_configurations/${id}`, {
        merchantLoyaltyProviderConfiguration
      });
      this.insert({ data: data.merchantLoyaltyProviderConfiguration });
    }

    catch (error) {
      throw error;
    }

    finally {
      this.commit((state) => {
        state.submitting = false;
      });
    }
  }
}
