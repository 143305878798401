<template>
  <tippy
    ref="dropdownMenu"
    interactive
    interactive-border="24"
    :placement="position"
    :trigger="disabled ? 'manual' : _trigger"
    theme="menu"
    animation="shift-away"
    duration="200"
    max-width="1000"
    :on-show="() => $emit('active-change', true)"
    :on-hide="() => $emit('active-change', false)"
    :arrow="false"
    :class="[
      'is-inline-block',
      expanded && 'is-full-width'
    ]"
  >
    <template #trigger>
      <slot name="trigger" />
    </template>

    <b-dropdown
      :value="value"
      :disabled="disabled"
      :multiple="multiple"
      :expanded="expanded"
      :mobile-modal="false"
      inline
      :max-height="$screen.height - 8"
      scrollable
      aria-role="list"
      @click.native="closeMenu"
      @change="$emit('input', $event)"
    >
      <slot />
    </b-dropdown>
  </tippy>
</template>

<script>
  export default {
    name: 'DropdownMenu',

    props: {
      value: {
        type: [Number, String, Array],
        default: null
      },

      disabled: {
        type: Boolean,
        default: false
      },

      multiple: {
        type: Boolean,
        default: false
      },

      expanded: {
        type: Boolean,
        default: false
      },

      position: {
        type: String,
        default: 'bottom-start'
      },

      trigger: {
        type: String,
        default: 'click'
      }
    },

    computed: {
      _trigger() {
        switch (this.trigger) {
          case 'hover':
            return 'mouseenter';
          default:
            return this.trigger;
        }
      }
    },

    methods: {
      closeMenu() {
        if (!this.multiple && this.$refs.dropdownMenu) {
          this.$refs.dropdownMenu.tip.hide();
        }
      }
    }
  };
</script>

<style lang="sass" scoped>
  ::v-deep
    .dropdown-content
      display: flex
      flex-direction: column
      color: $text
</style>
