import { Model } from '@vuex-orm/core';
import http from '@/services/http';

export default class GiftCardTransaction extends Model {
  static entity = 'giftCardTransactions';

  static primaryKey = 'giftCardTransactionId';

  // FIELDS //////////////////////
  static fields() {
    return {
      giftCardTransactionId: this.attr(null),
      originalPaymentTransactionId: this.attr(null),
      transactionType: this.attr(''),
      giftCardTransactionStatus: this.attr(''),
      transactionDateTime: this.attr(''),
      cardNumberLastFour: this.attr(''),
      beginningBalance: this.attr(null),
      endingBalance: this.attr(null),
      amount: this.attr(null),
      gatewayResponse: this.attr(''),
      giftCardId: this.attr(null),
      giftCardBalance: this.attr(null),
      giftCardBalanceRefreshDate: this.attr(''),
      giftCardIsVirtual: this.attr(false),
      giftCardIsDefault: this.attr(false),
      accountId: this.attr(null),
      accountFirstName: this.attr(''),
      accountLastName: this.attr(''),
      accountEmail: this.attr(''),
      accountPrimarySmsNumber: this.attr(''),
      accountPhoneNumber: this.attr(''),
      senderName: this.attr(''),
      senderEmail: this.attr(''),
      senderPrimarySmsNumber: this.attr(''),
      recipientName: this.attr(''),
      recipientEmail: this.attr(''),
      recipientPrimarySmsNumber: this.attr(''),
      redemptionDate: this.attr(''),
      storeIds: this.attr([]),

      // used "internally" for retrieving transactions in the
      // same order they were received from the endpoint
      sortOrder: this.attr(''),

      // Fields for detailed single transaction view
      // TODO: Consider defining proper relationships for these in the future
      account: this.attr(''),
      gift: this.attr(''),
      giftCard: this.attr(''),
      paymentTransaction: this.attr('')
    };
  }


  // STATE //////////////////////
  static state() {
    return {
      searching: false,
      fetchingId: false,
      giftCardTransactionMetadata: {}
    };
  }

  static $state() {
    return this.store().state.entities.giftCardTransactions;
  }


  // ACTIONS //////////////////////
  static async searchGiftCardTransactions({
    page,
    perPage,
    fromDate,
    toDate,
    transactionTypes,
    giftCardTransactionId,
    cardNumberLastFour,
    amount,
    sortOrder,
    purchaserName,
    purchaserEmail,
    purchaserPhone,
    recipientName,
    recipientEmail,
    recipientPhone,
    paymentTransactionStatus,
    timezone
  }) {
    try {
      this.commit((state) => {
        state.searching = true;
      });

      this.deleteAll();

      const queries = [
        page && `currentPage=${page}`,
        perPage && `pageSize=${perPage}`,
        fromDate && `fromDate=${fromDate}`,
        toDate && `toDate=${toDate}`,
        giftCardTransactionId && `giftCardTransactionId=${giftCardTransactionId}`,
        transactionTypes && transactionTypes.map(type => `transactionTypes[]=${type}`).join('&'),
        purchaserName && `purchaserName=${encodeURIComponent(purchaserName)}`,
        purchaserEmail && `purchaserEmail=${encodeURIComponent(purchaserEmail)}`,
        purchaserPhone && `purchaserPhone=${encodeURIComponent(purchaserPhone)}`,
        recipientName && `recipientName=${encodeURIComponent(recipientName)}`,
        recipientEmail && `recipientEmail=${encodeURIComponent(recipientEmail)}`,
        recipientPhone && `recipientPhone=${encodeURIComponent(recipientPhone)}`,
        cardNumberLastFour && `cardNumberLastFour=${cardNumberLastFour}`,
        amount && `amount=${amount}`,
        paymentTransactionStatus && `paymentTransactionStatus=${paymentTransactionStatus}`,
        timezone && `timezone=${timezone}`,
        sortOrder && `sortOrder=${sortOrder}`
      ].filter(q => q);

      const queryString = queries.length ? `?${queries.join('&')}` : '';
      const merchantId = this.store().state.entities.merchants.selectedMerchantId;
      const {
        data: {
          giftCardTransactions,
          currentPage,
          pageSize,
          count,
          totalPages
        }
      } = await http.get(`merchants/${merchantId}/gift_card_transactions${queryString}`);


      this.commit((state) => {
        state.giftCardTransactionMetadata = {
          currentPage,
          pageSize,
          count,
          totalPages
        };
      });

      return giftCardTransactions;
    }

    catch (error) {
      throw error;
    }

    finally {
      this.commit((state) => {
        state.searching = false;
      });
    }
  }

  static async getGiftCardTransactionById(id) {
    try {
      this.commit((state) => {
        state.fetchingId = id;
      });

      const merchantId = this.store().state.entities.merchants.selectedMerchantId;
      const { data } = await http.get(`merchants/${merchantId}/gift_card_transactions/${id}`);

      this.insertOrUpdate({
        data: {
          giftCardTransactionId: data.giftCardTransaction.giftCardTransaction.id,
          ...data.giftCardTransaction.giftCardTransaction,
          account: data.giftCardTransaction.account,
          giftCard: data.giftCardTransaction.giftCard,
          paymentTransaction: data.giftCardTransaction.paymentTransaction,
          gift: data.giftCardTransaction.gift
        }
      });

      return data.giftCardTransaction;
    }
    catch (error) {
      throw error;
    }
    finally {
      this.commit((state) => {
        state.fetchingId = false;
      });
    }
  }

  static resetState() {
    this.commit((state) => {
      state.giftCardTransactionMetadata = {};
      state.searching = false;
    });
    this.deleteAll();
  }
}
