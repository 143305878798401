/* eslint-disable import/no-cycle */
import { Model } from '@vuex-orm/core';
import http from '@/services/http';

import filterObjectKeys from '@/helpers/filter-object-keys';

import MerchantFaqSection from './MerchantFaqSection';

export default class MerchantFaqHeader extends Model {
  static entity = 'merchantFaqHeaders'

  // FIELDS //////////////////////
  static fields() {
    return {
      id: this.attr(''),
      merchantFaqServiceTypeId: this.attr(''),
      headerTitle: this.attr(''),
      merchantId: this.attr(''),

      // relationship
      merchantFaqSections: this.hasMany(MerchantFaqSection, 'merchantFaqHeaderId')
    };
  }

  // STATE //////////////////////
  static state() {
    return {
      fetching: false,
      submitting: false
    };
  }

  static $state() {
    return this.store().state.entities.merchantFaqHeaders;
  }

  // ACTIONS //////////////////////
  static async fetchMerchantFaqHeaders(merchantId) {
    try {
      this.commit((state) => {
        state.fetching = true;
      });

      const response = await http.get(`merchants/${merchantId}/merchant_faq_headers`);

      this.create({
        data: response.data.merchantFaqHeaders
      });
    }

    catch (error) {
      throw error;
    }

    finally {
      this.commit((state) => {
        state.fetching = false;
      });
    }
  }

  static async createMerchantFaqHeader({ merchantId, merchantFaqHeader }) {
    try {
      this.commit((state) => {
        state.submitting = true;
      });

      const acceptedKeys = ['merchantFaqServiceTypeId', 'headerTitle'];

      const response = await http.post(`merchants/${merchantId}/merchant_faq_headers`, {
        merchantFaqHeader: filterObjectKeys(merchantFaqHeader, acceptedKeys)
      });

      this.insert({ data: response.data.merchantFaqHeader });
    }

    catch (error) {
      throw error;
    }

    finally {
      this.commit((state) => {
        state.submitting = false;
      });
    }
  }

  static async updateMerchantFaqHeader(merchantFaqHeader) {
    try {
      this.commit((state) => {
        state.submitting = true;
      });

      const acceptedKeys = ['merchantFaqServiceTypeId', 'headerTitle'];

      const response = await http.put(`merchant_faq_headers/${merchantFaqHeader.id}`, {
        merchantFaqHeader: filterObjectKeys(merchantFaqHeader, acceptedKeys)
      });

      this.update({ data: response.data.merchantFaqHeader });
    }

    catch (error) {
      throw error;
    }

    finally {
      this.commit((state) => {
        state.submitting = false;
      });
    }
  }
}
