import http from './http';



async function geocode({
  addressLine1,
  addressLine2,
  city,
  region,
  postalCode
}) {
  const queryString = [
    `addressLine1=${addressLine1}`,
    addressLine2 && `addressLine2=${addressLine2}`,
    `city=${city}`,
    `region=${region}`,
    `postalCode=${postalCode}`
  ].filter(x => x).join('&');

  try {
    const response = await http.get(`lat_long?${queryString}`);
    return response.data;
  }

  catch (error) {
    throw error;
  }
}



export default geocode;
