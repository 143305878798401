<template>
  <div class="dist-y-lg">
    <validated-form
      ref="content-form"
      auto-focus
      form-id="contentMetadataForm"
      @valid-submit="handleSave"
    >
      <panel
        collapsible
        start-open
        hide-hover-controls
        title="Merchant Content"
        class="is-flex-column align-self-start"
        :loading="isSubmitting"
      >
        <template #buttons>
          <b-button
            v-if="$can('create', 'MerchantTermsOfService')"
            rounded
            class="is-bold"
            type="is-primary is-light"
            :disabled="hasDefaultValues"
            data-test-id="reset-to-defaults-button"
            @click="openContentResetConfirmationModal"
          >
            Reset to Defaults
          </b-button>
          <b-button
            v-if="$can('create', 'MerchantTermsOfService')"
            rounded
            class="is-bold"
            native-type="submit"
            type="is-primary"
          >
            Save
          </b-button>
        </template>

        <div>
          <p v-if="!selectedProperties.length" class="has-text-centered has-text-grey-light">
            No fields selected
          </p>

          <transition-group name="list-slide" tag="div" class="is-grid gap-lg">
            <div v-if="selectedProperties.includes('title')" key="title">
              <validated-text-input
                v-model="form.title"
                label="Title"
                type="text"
                name="title"
                label-position="on-border"
                :placeholder="defaultMerchantContent.title || 'Enter a Title...'"
                data-test-id="title-input"
              />
            </div>

            <div v-if="selectedProperties.includes('description')" key="description">
              <validated-text-input
                v-model="form.description"
                label="Description"
                type="text"
                name="description"
                label-position="on-border"
                :placeholder="defaultMerchantContent.description || 'Enter a Description...'"
                data-test-id="description-input"
              />
            </div>

            <div
              v-for="contentTypeProperty in filteredContentTypeProperties"
              :key="contentTypeProperty.id"
            >
              <content-property-input
                v-model="findMetadataByContentTypePropertyId(contentTypeProperty.id).value"
                :not-found="findMetadataByContentTypePropertyId(contentTypeProperty.id).value === undefined"
                :content-type-property="contentTypeProperty"
                :placeholder="defaultMetadataByProperty[contentTypeProperty.id].value"
                :image-file="metadataImageFiles[contentTypeProperty.id]"
                :data-test-id="`property-${contentTypeProperty.id}-input`"
                @delete-image="deleteMetadataImage"
                @update-image="updateMetadataImage"
              />
            </div>
          </transition-group>
        </div>
      </panel>
    </validated-form>

    <panel
      title="Location Specific Content"
      subtitle="Location specific content overrides merchant content. Delete a location to reset it to merchant content."
      collapsible
      start-open
      hide-hover-controls
      no-padding
    >
      <template #buttons>
        <b-button
          rounded
          class="is-bold"
          type="is-primary is-light"
          :disabled="collapseButtonDisabled"
          @click="openedDetailRowIds = openedDetailRowIds.filter(id => !selectedLocationIds.includes(id))"
        >
          Collapse
        </b-button>
        <b-button
          rounded
          class="is-bold"
          type="is-primary is-light"
          :disabled="expandButtonDisabled"
          @click="openedDetailRowIds = [...openedDetailRowIds, ...selectedLocationIds]"
        >
          Expand
        </b-button>
        <b-button
          rounded
          class="is-bold"
          type="is-danger is-light is-outlined"
          icon-left="trash-alt"
          :disabled="deleteButtonDisabled"
          @click="confirmDeleteSelectedLocationContent()"
        >
          Delete
        </b-button>
      </template>

      <b-table
        :data="locationContents"
        class="is-middle-aligned"
        :mobile-cards="false"
        detailed
        detail-transition="fade-down"
        detail-key="id"
        :show-detail-icon="false"
        :opened-detailed.sync="openedDetailRowIds"
        data-test-id="location-content-table"
        checkable
        checkbox-position="left"
        checkbox-type="is-primary"
        :checked-rows.sync="selectedLocations"
      >
        <template #empty>
          <b-button
            icon-left="plus"
            inverted
            type="is-primary is-transparent"
            @click="openLocationContentModal()"
          >
            Location
          </b-button>
        </template>

        <b-table-column
          v-slot="{ row, toggleDetails }"
          label="Location"
        >
          <div class="is-inline-flex align-center gap-xs">
            <span class="has-text-weight-semibold">
              {{ row.storeName }}
            </span>
            <span @click="toggleDetails(row)">
              <b-icon
                class="link"
                :class="['open-indicator-arrow', openedDetailRowIds.includes(row.id) && 'is-open']"
                icon="angle-right"
              />
            </span>
          </div>
        </b-table-column>

        <b-table-column
          v-slot="{ row }"
          cell-class="actions"
          centered
          width="1"
          label="Actions"
        >
          <div class="is-flex justify-center gap-xs">
            <b-button class="is-transparent" type="is-white" @click="openLocationContentModal(row)">
              <b-icon icon="pencil" />
            </b-button>
            <b-button class="is-transparent" type="is-white" @click="confirmDeleteLocationContent(row.id)">
              <b-icon icon="trash-alt" type="is-danger" />
            </b-button>
          </div>
        </b-table-column>

        <template #detail="props">
          <p v-if="!selectedProperties.length" class="has-text-centered has-text-grey-light">
            No fields selected
          </p>
          <transition-group name="list-slide" tag="div" class="is-grid gap-x-xl gap-y-lg col-min-300">
            <div v-if="selectedProperties.includes('title')" key="title">
              <p class="label is-size-7 mar-none">Title</p>
              <p>{{ props.row.title }}</p>
            </div>
            <div v-if="selectedProperties.includes('description')" key="description">
              <p class="label is-size-7 mar-none">Description</p>
              <p>{{ props.row.description }}</p>
            </div>
            <div v-for="contentTypeProperty in filteredContentTypeProperties" :key="contentTypeProperty.id">
              <p class="label is-size-7 mar-none">
                {{ capitalCase(contentTypeProperty.propertyName) }}
              </p>
              <template v-if="props.row.merchantContentMetadata.find(({ contentTypePropertyId }) => contentTypePropertyId === contentTypeProperty.id).value">
                <img
                  v-if="contentTypeProperty.isImage"
                  :src="props.row.merchantContentMetadata.find(({ contentTypePropertyId }) => contentTypePropertyId === contentTypeProperty.id).value"
                  alt="thumbnail image"
                  class="mar-t-xs"
                  style="max-height: 150px"
                >
                <b-input
                  v-if="contentTypeProperty.isArray || contentTypeProperty.isObject"
                  type="textarea"
                  custom-class="is-monospaced"
                  rows="6"
                  readonly
                  :value="beautify(JSON.parse(props.row.merchantContentMetadata.find(({ contentTypePropertyId }) => contentTypePropertyId === contentTypeProperty.id).value), null, 2, 0)"
                />
                <p v-else>
                  {{ props.row.merchantContentMetadata.find(({ contentTypePropertyId }) => contentTypePropertyId === contentTypeProperty.id).value }}
                </p>
              </template>
              <p v-else class="has-text-grey-light">N/A</p>
            </div>
          </transition-group>
        </template>
      </b-table>

      <div v-if="locationContents.length" class="pad-x pad-y-sm has-border-top has-border-grey-lighter">
        <b-button
          icon-left="plus"
          inverted
          type="is-primary is-transparent"
          :disabled="locationContents.length === storeCount"
          data-test-id="add-location-content-button"
          @click="openLocationContentModal()"
        >
          Location
        </b-button>
      </div>
    </panel>
  </div>
</template>

<script>
  import MerchantContent from '@/store/classes/MerchantContent';
  import Store from '@/store/classes/Store';
  import merchantMixin from '@/mixins/merchant';
  import capitalCase from '@/helpers/capitalCase';
  import alertModal from '@/components/globals/alert-modal.vue';
  import locationContentModal from './location-content-modal.vue';
  import contentPropertyInput from './content-property-input.vue';
  import beautify from 'json-beautify';

  export default {
    name: 'ContentMetaData',

    components: { contentPropertyInput },

    mixins: [merchantMixin],

    props: {
      contentType: {
        type: Object,
        required: true
      },
      selectedProperties: {
        type: Array,
        default: () => []
      }
    },

    data() {
      return {
        form: { merchantContentMetadata: {} },
        metadataImageFiles: {},
        openedDetailRowIds: [],
        selectedLocations: []
      };
    },

    computed: {
      isSubmitting() {
        return MerchantContent.$state().submitting;
      },

      filteredContentTypeProperties() {
        return this.contentType.contentTypeProperties.filter(({ id }) => this.selectedProperties.includes(id));
      },

      contents() {
        return MerchantContent.query()
          .where('contentTypeId', this.contentType.id)
          .with('merchantContentMetadata')
          .get()
          .map(content => ({
            ...content,
            /* include getter value as static obj property */
            storeName: content.storeName
          }));
      },

      locationContents() {
        return this.contents
          .filter(({ storeId }) => !!storeId)
          .sort((a, b) => (a.storeName > b.storeName ? 1 : -1));
      },

      selectedMerchantContent() {
        return this.contents.find(({ merchantId, storeId }) => merchantId === this.$_selectedMerchantId && !storeId);
      },

      defaultMerchantContent() {
        return this.contents.find(({ merchantId }) => merchantId === 0);
      },

      selectedMetadataByProperty() {
        return this.selectedMerchantContent?.merchantContentMetadata.reduce((acc, mcm) => {
          acc[mcm.contentTypePropertyId] = mcm;
          return acc;
        }, {}) || {};
      },

      defaultMetadataByProperty() {
        return this.defaultMerchantContent?.merchantContentMetadata.reduce((acc, mcm) => {
          acc[mcm.contentTypePropertyId] = mcm;
          return acc;
        }, {}) || {};
      },

      contentTypeTitle() {
        return capitalCase(this.contentType.name);
      },

      contentTypeDescription() {
        const description = this.contentType.description.trim();
        const isDifferent = this.contentTypeTitle !== description;

        return isDifferent ? description : null;
      },

      hasDefaultValues() {
        if (!this.selectedMerchantContent) {
          return true;
        }
        const { title: defaultTitle, description: defaultDescription } = this.defaultMerchantContent;
        const { title: selectedTitle, description: selectedDescription } = this.selectedMerchantContent;
        const isMatchedTitleAndDescription = selectedTitle === defaultTitle && selectedDescription === defaultDescription;
        const isMatchedMetada = this.selectedMerchantContent.merchantContentMetadata.every((smc) => {
          const matchedMetadata = this.defaultMerchantContent.merchantContentMetadata.find(dc => smc.contentTypePropertyId === dc.contentTypePropertyId && smc.value === dc.value);

          return matchedMetadata;
        });
        return isMatchedMetada && isMatchedTitleAndDescription;
      },

      selectedLocationIds() {
        return this.selectedLocations.map(({ id }) => id);
      },

      collapseButtonDisabled() {
        return !this.locationContents.length
          || !this.selectedLocationIds.length
          || !this.openedDetailRowIds.length
          || !this.openedDetailRowIds.some(id => this.selectedLocationIds.includes(id));
      },

      expandButtonDisabled() {
        return !this.locationContents.length
          || !this.selectedLocationIds.length
          || this.selectedLocationIds.every(id => this.openedDetailRowIds.includes(id));
      },

      deleteButtonDisabled() {
        return !this.locationContents.length
          || !this.selectedLocationIds.length;
      },

      storeCount() {
        return Store.query().count();
      }
    },

    created() {
      this.normalizeContent();
    },

    methods: {
      capitalCase,
      beautify,

      findMetadataByContentTypePropertyId(contentTypePropertyId) {
        return this.form.merchantContentMetadata.find(mcm => mcm.contentTypePropertyId === contentTypePropertyId) || {};
      },

      async resetToDefault() {
        try {
          const metaData = [];

          this.defaultMerchantContent.merchantContentMetadata.forEach((dmc) => {
            const currentData = this.findMetadataByContentTypePropertyId(dmc.contentTypePropertyId);
            currentData.value = dmc.value;
            metaData.push(currentData);
          });

          await MerchantContent.updateMerchantContent({
            title: this.defaultMerchantContent.title,
            description: this.defaultMerchantContent.description,
            id: this.form.id,
            merchantContentMetadata: metaData
          });
          this.normalizeContent();
          this.$_onRequestSuccess({
            toastOptions: {
              message: 'Successfully reset to defaults!'
            }
          });
        }
        catch (error) {
          this.$_onRequestError({
            error,
            toastOptions: {
              message: 'There was an error resetting to defaults'
            }
          });
        }
      },

      openContentResetConfirmationModal() {
        this.$buefy.modal.open({
          parent: this,
          component: alertModal,
          hasModalCard: true,
          trapFocus: true,
          canCancel: ['escape', 'outside'],
          customClass: 'auto-width',
          props: {
            title: 'Reset to Default',
            message: `Warning! You are about to reset the content belonging to <b>${this.contentTypeTitle}</b>. This <b>CANNOT</b> be undone.`,
            secondaryMessage: 'Are you sure you would like to proceed?',
            horizontal: true,
            showCloseButton: false,
            icon: 'trash-alt',
            type: 'is-danger',
            buttons: [
              { text: 'Cancel' },
              { text: 'Reset Content', primary: true, onClick: this.resetToDefault }
            ]
          }
        });
      },

      openLocationContentModal(locationContent) {
        this.$buefy.modal.open({
          parent: this,
          component: locationContentModal,
          hasModalCard: true,
          trapFocus: true,
          canCancel: false,
          props: {
            properties: this.contentType.contentTypeProperties,
            selectedMetadataByProperty: this.selectedMetadataByProperty,
            defaultMetadataByProperty: this.defaultMetadataByProperty,
            merchantContent: this.form,
            locationContent,
            existingStoreIds: this.locationContents.map(({ storeId }) => storeId)
          }
        });
      },

      normalizeContent() {
        const formContent = this.selectedMerchantContent ? this.$clone(this.selectedMerchantContent) : this.$clone(this.defaultMerchantContent);

        /**
         * NOTE: It could be the case that the MerchantContent record has no children MerchantContentMetadata
         * (CAPI will return an empty array to WAP/Mobile) so backfill an array with empty values and content type id
         */

        if (this.selectedMerchantContent && this.selectedMerchantContent.merchantContentMetadata.length !== this.defaultMerchantContent.merchantContentMetadata.length) {
          formContent.merchantContentMetadata = this.$clone(this.contentType.contentTypeProperties)
            .map(ctp => this.selectedMetadataByProperty[ctp.id]
              || { value: this.defaultMetadataByProperty[ctp.id].value, contentTypePropertyId: ctp.id });
        }

        this.form = formContent;
      },

      deleteMetadataImage(contentTypePropertyId) {
        const contentMetadata = this.findMetadataByContentTypePropertyId(contentTypePropertyId);
        contentMetadata.value = null;
        this.metadataImageFiles[contentTypePropertyId] = null;
      },

      updateMetadataImage({ contentTypePropertyId, imageFile }) {
        this.metadataImageFiles = {
          ...this.metadataImageFiles,
          [contentTypePropertyId]: imageFile
        };
      },

      async createMetadataImages(newMetadataImages) {
        try {
          await Promise.all(newMetadataImages.map(async ([contentTypePropertyId, imageFile]) => {
            if (!imageFile) return;

            const imageUrl = await MerchantContent.createMerchantContentMetadataImage(imageFile);
            const contentMetadata = this.findMetadataByContentTypePropertyId(parseInt(contentTypePropertyId, 10));
            contentMetadata.value = imageUrl;
          }));
        }
        catch (error) {
          this.$_onRequestError({
            error,
            toastOptions: {
              message: 'There was an error uploading your content images'
            }
          });
        }
      },

      async handleSave() {
        const newMetadataImages = Object.entries(this.metadataImageFiles);
        if (newMetadataImages.length) {
          await this.createMetadataImages(newMetadataImages);
          this.metadataImageFiles = {};
        }

        try {
          if (!this.selectedMerchantContent) {
            await MerchantContent.createMerchantContent(this.form);
          }
          else {
            await MerchantContent.updateMerchantContent(this.form);
          }

          this.normalizeContent();

          this.$_onRequestSuccess({
            toastOptions: {
              message: 'Your merchant content has been successfully saved!'
            }
          });
        }
        catch (error) {
          this.$_onRequestError({
            error,
            toastOptions: {
              message: 'There was an error saving your merchant content'
            }
          });
        }
      },

      confirmDeleteLocationContent(id) {
        this.$buefy.modal.open({
          parent: this,
          component: alertModal,
          hasModalCard: true,
          trapFocus: true,
          customClass: 'auto-width',
          props: {
            buttons: [
              { text: 'Cancel' },
              {
                text: 'Yes, Delete',
                primary: true,
                onClick: async () => {
                  await this.deleteLocationContent([id]);
                }
              }
            ],
            horizontal: true,
            showCloseButton: false,
            icon: 'trash-alt',
            title: 'Delete Location Content',
            message: 'This location will revert back to using the merchant content. This cannot be undone.',
            secondaryMessage: 'Are you sure you would like to proceed?',
            type: 'is-danger'
          }
        });
      },

      confirmDeleteSelectedLocationContent() {
        let message;
        if (this.selectedLocationIds.length === this.locationContents.length) {
          message = 'This will delete all the location content for this type. All locations will revert back to the merchant content. This cannot be undone.';
        }
        else {
          const selectedLocationNames = this.locationContents
            .filter(({ id }) => this.selectedLocationIds.includes(id))
            .map(({ storeName }) => `<b>${storeName}</b>`)
            .join(', ');
          message = this.selectedLocationIds.length === 1
            ? `This will delete the location content for ${selectedLocationNames}. This location will revert back to the merchant content. This cannot be undone.`
            : `This will delete the location content for the following locations: ${selectedLocationNames}. These locations will revert back to the merchant content. This cannot be undone.`;
        }

        this.$buefy.modal.open({
          parent: this,
          component: alertModal,
          hasModalCard: true,
          trapFocus: true,
          customClass: 'auto-width',
          props: {
            buttons: [
              { text: 'Cancel' },
              {
                text: 'Yes, Delete',
                primary: true,
                onClick: async () => {
                  await this.deleteLocationContent([...this.selectedLocationIds]);
                }
              }
            ],
            horizontal: true,
            showCloseButton: false,
            icon: 'trash-alt',
            title: 'Delete Location Content',
            message,
            secondaryMessage: 'Are you sure you would like to proceed?',
            type: 'is-danger'
          }
        });
      },

      async deleteLocationContent(ids) {
        try {
          await MerchantContent.bulkDeleteMerchantContent(ids);

          this.$_onRequestSuccess({
            toastOptions: {
              message: 'Location content has been deleted'
            }
          });
        }
        catch (error) {
          this.$_onRequestError({
            error,
            toastOptions: {
              message: 'There was an error deleting the location content'
            }
          });
        }
      }
    }
  };
</script>

<style scoped lang="sass">
  ::v-deep textarea[readonly]
    background: transparent

  ::v-deep .checkbox-cell
    padding-right: 0
    .checkbox
      margin: 0
    .control-label
      padding: 0
</style>
