import { Model } from '@vuex-orm/core';
import http from '@/services/http';
import filterObjectKeys from '@/helpers/filter-object-keys';
import FeeType from './FeeType';


export default class MerchantFee extends Model {
  static entity = 'merchantFees'


  static acceptedKeys = [
    'isActive',
    'name',
    'description',
    'fee',
    'feeTypeId',
    'storeIds',
    'fulfillmentTypeIds'
  ];


  // FIELDS //////////////////////
  static fields() {
    return {
      id: this.attr(''),
      merchantId: this.attr(''),
      isActive: this.boolean(true),
      name: this.attr(''),
      description: this.attr(''),
      fee: this.number(null),
      feeTypeId: this.attr(''),
      storeMerchantFees: this.attr([]),
      feeType: this.belongsTo(FeeType, 'feeTypeId'),

      // FE properties
      storeIds: this.attr([]),
      fulfillmentTypeIds: this.attr([])
    };
  }

  get scope() {
    const isOrderingModeSpecific = this.storeMerchantFees.length && this.storeMerchantFees.every(fee => !fee.storeId);
    if (!this.storeMerchantFees.length || isOrderingModeSpecific) {
      return 'global';
    }
    return 'location';
  }



  // STATE //////////////////////
  static state() {
    return {
      fetching: false,
      submitting: false,
      deletingId: false
    };
  }

  static $state() {
    return this.store().state.entities.merchantFees;
  }


  // ACTIONS //////////////////////
  static async fetchMerchantFees(merchantId) {
    try {
      this.commit((state) => {
        state.fetching = true;
      });

      const id = merchantId || this.store().state.entities.merchants.selectedMerchantId;

      const { data } = await http.get(`merchants/${id}/merchant_fees?include=store_merchant_fees`);

      this.create({
        data: data.merchantFees
      });
    }

    catch (error) {
      throw error;
    }

    finally {
      this.commit((state) => {
        state.fetching = false;
      });
    }
  }

  static async createMerchantFee(merchantFee) {
    try {
      this.commit((state) => {
        state.submitting = true;
      });

      const response = await http.post(
        `merchants/${merchantFee.merchantId}/merchant_fees`,
        { merchantFee: filterObjectKeys(merchantFee, this.acceptedKeys) }
      );

      this.insert({
        data: response.data.merchantFee
      });
    }

    catch (error) {
      throw error;
    }

    finally {
      this.commit((state) => {
        state.submitting = false;
      });
    }
  }

  static async updateMerchantFee(merchantFee) {
    try {
      this.commit((state) => {
        state.submitting = true;
      });

      const response = await http.put(`merchant_fees/${merchantFee.id}`, {
        merchantFee: filterObjectKeys(merchantFee, this.acceptedKeys)
      });

      this.update({
        data: response.data.merchantFee
      });
    }

    catch (error) {
      throw error;
    }

    finally {
      this.commit((state) => {
        state.submitting = false;
      });
    }
  }

  static async deleteMerchantFee(merchantFeeId) {
    try {
      this.commit((state) => {
        state.deletingId = merchantFeeId;
      });

      await http.delete(`merchant_fees/${merchantFeeId}`);

      this.delete(merchantFeeId);
    }

    catch (error) {
      throw error;
    }

    finally {
      this.commit((state) => {
        state.deletingId = false;
      });
    }
  }
}
