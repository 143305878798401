var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('validated-form',{ref:"form",attrs:{"auto-focus":"","form-id":"addEditPosDiscount"},on:{"valid-submit":_vm.handleSubmit}},[_c('modal-card',{attrs:{"title":_vm.posDiscountConfigurationId ? 'Update POS Discount' :'Create POS Discount'},scopedSlots:_vm._u([{key:"footer",fn:function(){return [_c('div',{staticClass:"buttons all-bold"},[_c('b-button',{attrs:{"rounded":""},on:{"click":function($event){return _vm.$_confirmCloseModal({ programmatic: true })}}},[_vm._v(" Cancel ")]),_c('b-button',{directives:[{name:"tabbable",rawName:"v-tabbable"}],attrs:{"rounded":"","native-type":"submit","type":"is-primary","loading":_vm.isSubmitting}},[_vm._v(" Save ")])],1)]},proxy:true}])},[_c('div',[_c('validated-text-input',{attrs:{"label":"POS Discount Name","sub-label":"Internal name used for searching your POS Discounts","name":"internalName","rules":{
          required: true,
          max: 50,
          uniqueStringCaseInsensitive: _vm.unavailableNames
        },"type":"text"},model:{value:(_vm.form.name),callback:function ($$v) {_vm.$set(_vm.form, "name", $$v)},expression:"form.name"}}),_c('validated-text-input',{attrs:{"name":"displayName","label":"Display Name","sub-label":"Name that may be displayed to end users","rules":"required|max:50","type":"text"},model:{value:(_vm.form.displayName),callback:function ($$v) {_vm.$set(_vm.form, "displayName", $$v)},expression:"form.displayName"}}),_c('hr'),_c('b-field',{scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('p',[_vm._v("Scope")]),_c('p',{staticClass:"sub-label"},[_vm._v("Specify whether the configured discount codes will apply to all stores or be store specific")])]},proxy:true}])},[_c('div',{staticClass:"is-flex"},[_c('radio-button',{staticClass:"flex-grow",attrs:{"name":"isStoreSpecific","native-value":false,"disabled":!!_vm.posDiscountConfigurationId && !!_vm.discountCodes.length},model:{value:(_vm.isStoreSpecific),callback:function ($$v) {_vm.isStoreSpecific=$$v},expression:"isStoreSpecific"}},[_vm._v(" Applies to All Stores ")]),_c('radio-button',{staticClass:"flex-grow",attrs:{"name":"isStoreSpecific","native-value":true,"disabled":!!_vm.posDiscountConfigurationId && !!_vm.discountCodes.length},model:{value:(_vm.isStoreSpecific),callback:function ($$v) {_vm.isStoreSpecific=$$v},expression:"isStoreSpecific"}},[_vm._v(" Applies to Specific Stores ")])],1)]),_c('hr'),_c('div',[_c('validation-provider',{attrs:{"name":"discountCodeAmount","rules":{ max_value: _vm.isStoreSpecific ? _vm.stores.length : _vm.merchantPosTypes.length }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var errors = ref.errors;
return [_c('input',{directives:[{name:"show",rawName:"v-show",value:(false),expression:"false"}],attrs:{"type":"number"},domProps:{"value":_vm.discountCodes.length}}),_c('div',{staticClass:"mar-b"},[_c('p',{staticClass:"subtitle is-5 mar-b-none"},[_vm._v(" Discount Codes "),(!!errors.length)?_c('b-icon',{attrs:{"icon":"exclamation-circle","custom-class":"fa-sm","type":"is-danger"}}):_vm._e()],1),(!!errors.length)?_c('p',{staticClass:"has-text-danger is-size-7"},[_c('span',[_vm._v(" Only "+_vm._s(_vm.isStoreSpecific ? _vm.stores.length : _vm.merchantPosTypes.length)+" Discount Code"+_vm._s((_vm.isStoreSpecific ? _vm.stores.length : _vm.merchantPosTypes.length) > 1 ? 's' : '')+" can be applied"+_vm._s(_vm.isStoreSpecific ? '' : ' to all stores')+" ")])]):_vm._e()]),_c('transition',{attrs:{"name":"fade-up","mode":"out-in"}},[(_vm.discountCodes.length)?_c('div',[_c('transition-group',{staticClass:"dist-y",attrs:{"name":"fade-right-slow"}},_vm._l((_vm.discountCodes),function(discountCode,index){return _c('div',{key:("discount-code-" + (discountCode.id)),staticClass:"card gap-sm is-flex pad-sm discount-code"},[(_vm.isStoreSpecific)?_c('validated-input',{staticClass:"is-marginless flex-grow",attrs:{"label":"Location Name","label-position":"on-border","name":("storeId-" + index),"rules":"required"}},[_c('b-select',{attrs:{"expanded":""},model:{value:(discountCode.storeId),callback:function ($$v) {_vm.$set(discountCode, "storeId", $$v)},expression:"discountCode.storeId"}},_vm._l((_vm.stores),function(store){return _c('option',{key:store.storeId,attrs:{"disabled":_vm.discountCodes.slice(0, index).concat( _vm.discountCodes.slice(index + 1)
                        ).some(function (dc) { return dc.storeId === store.storeId; })},domProps:{"value":store.storeId}},[_vm._v(" "+_vm._s(store.description)+" ")])}),0)],1):_vm._e(),(!_vm.isStoreSpecific && _vm.merchantPosTypes.length > 1)?_c('validated-input',{staticClass:"is-marginless flex-grow",attrs:{"label":"POS Type","label-position":"on-border","name":("posTypeId-" + index),"rules":"required"}},[_c('b-select',{attrs:{"expanded":""},model:{value:(discountCode.posTypeId),callback:function ($$v) {_vm.$set(discountCode, "posTypeId", $$v)},expression:"discountCode.posTypeId"}},_vm._l((_vm.merchantPosTypes),function(posType){return _c('option',{key:posType.id,attrs:{"disabled":_vm.discountCodes.slice(0, index).concat( _vm.discountCodes.slice(index + 1)
                        ).some(function (dc) { return dc.posTypeId === posType.id; })},domProps:{"value":posType.id}},[_vm._v(" "+_vm._s(posType.name)+" ")])}),0)],1):_vm._e(),_c('validated-text-input',{staticClass:"is-marginless flex-grow",attrs:{"name":("posApiId-" + index),"label":"POS Discount ID","label-position":"on-border","type":"text","rules":"required","monospaced":"","spellcheck":false},model:{value:(discountCode.posApiId),callback:function ($$v) {_vm.$set(discountCode, "posApiId", $$v)},expression:"discountCode.posApiId"}}),_c('b-button',{staticClass:"align-self-start",attrs:{"icon-left":"trash-alt","type":"is-danger is-light"},on:{"click":function($event){return _vm.removeDiscountCode(index)}}})],1)}),0)],1):_c('b-message',{staticClass:"is-compact has-shadow",attrs:{"type":"is-primary"}},[_vm._v(" Add a Discount Code from your POS ")])],1)]}}])}),_c('b-button',{staticClass:"mar-t",attrs:{"icon-left":"plus","type":"is-primary is-light","disabled":_vm.discountCodes.length >= (_vm.isStoreSpecific ? _vm.stores.length : _vm.merchantPosTypes.length)},on:{"click":_vm.addDiscountCode}},[_vm._v(" Discount Code ")])],1)],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }