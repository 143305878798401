import { Model } from '@vuex-orm/core';
import http from '@/services/http';
import { capitalCase } from 'change-case';

export default class MembershipTierBadgeSource extends Model {
  static entity = 'membershipTierBadgeSources'



  // FIELDS //////////////////////
  static fields() {
    return {
      id: this.attr(''),
      name: this.attr('')
    };
  }

  get displayName() {
    return capitalCase(this.name);
  }

  // STATE //////////////////////
  static state() {
    return {
      fetching: false
    };
  }

  static $state() {
    return this.store().state.entities.membershipTierBadgeSources;
  }


  // ACTIONS //////////////////////
  static async fetchMembershipTierBadgeSources() {
    if (this.exists()) return;

    try {
      this.commit((state) => {
        state.fetching = true;
      });

      const { data } = await http.get('membership_tier_badge_sources');

      this.create({
        data: data.membershipTierBadgeSources
      });
    }
    catch (error) {
      throw error;
    }
    finally {
      this.commit((state) => {
        state.fetching = false;
      });
    }
  }
}
