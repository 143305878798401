<template>
  <div class="is-flex-wrap gap-xl mar-t-md">
    <validated-text-input
      :value="earnRuleConstraint.minimumSalesTransactionTotal"
      data-test-id="minimum-total"
      :disabled="readOnly"
      label="Minimum Total"
      sub-label-on-side
      name="minimumSalesTransactionTotal"
      type="dollars"
      :rules="{ min_value: 0.01 }"
      placeholder="Set a minimum amount..."
      :mask-options="{ numeralDecimalScale: 2, numeralPositiveOnly: true }"
      @input="handleInput({ minimumSalesTransactionTotal: Number($event) })"
    />
    <validated-text-input
      :value="earnRuleConstraint.minimumSalesTransactionItemTotalBeforeDiscounts"
      data-test-id="minimum-total-before-discounts"
      :disabled="readOnly"
      label="Minimum Total Before Discounts"
      sub-label-on-side
      name="minimumSalesTransactionItemTotalBeforeDiscounts"
      type="dollars"
      :rules="{ min_value: 0.01 }"
      placeholder="Set a minimum amount..."
      :mask-options="{ numeralDecimalScale: 2, numeralPositiveOnly: true }"
      @input="handleInput({ minimumSalesTransactionItemTotalBeforeDiscounts: Number($event) })"
    />
  </div>
</template>

<script>
  export default {
    name: 'RefereeRequiredPurchaseCard',

    props: {
      earnRuleConstraint: {
        type: Object,
        required: true
      },

      readOnly: {
        type: Boolean,
        default: false
      }
    },

    methods: {
      handleInput(payload) {
        this.$emit('update-constraint', { ...this.earnRuleConstraint, ...payload });
      }
    }
  };
</script>
