const storeMappingAttributes = {
  MOBILE_ORDERING: {
    name: 'Mobile Ordering',
    code: 'MobileOrdering'
  },
  WEB_ORDERING: {
    name: 'Web Ordering',
    code: 'WebOrdering'
  },
  KIOSK_ORDERING: {
    name: 'Kiosk Ordering',
    code: 'KioskOrdering'
  },
  DINE_IN: {
    name: 'Dine In',
    code: 'DineIn'
  },
  DRIVE_THROUGH: {
    name: 'Drive Through',
    code: 'DriveThrough'
  },
  DELIVERY: {
    name: 'Delivery',
    code: 'Delivery'
  },
  TAKE_OUT: {
    name: 'Take Out',
    code: 'TakeOut'
  },
  CURBSIDE: {
    name: 'Curbside',
    code: 'Curbside'
  },
  GROUP_ORDERING: {
    name: 'Group Ordering',
    code: 'GroupOrdering'
  },
  CATERING_ORDERING: {
    name: 'Catering Ordering',
    code: 'CateringOrdering'
  },
  WIFI: {
    name: 'Wifi Availability',
    code: 'Wifi'
  },
  TWENTY_FOUR_HOUR_SERVICE: {
    name: '24-Hour Service',
    code: '24HourService'
  },
  ROOM_SERVICE: {
    name: 'Room Service',
    code: 'RoomService'
  },
  WINE: {
    name: 'Wine',
    code: 'Wine'
  },
  BAR: {
    name: 'Bar',
    code: 'Bar'
  },
  ON_PREMISE_DELIVERY: {
    name: 'On-Premise Delivery',
    code: 'OnPremDelivery'
  },
  REQUIRE_PICK_UP_TIME_SELECTION: {
    name: 'Require Pickup Time Selection',
    code: 'RequirePickUpTimeSelection'
  },
  SHIPPING: {
    name: 'Shipping',
    code: 'Shipping'
  },
  TIPPING: {
    name: 'Tipping Enabled',
    code: 'TippingEnabled'
  },
  PAY_AT_TABLE: {
    name: 'Pay@Table',
    code: 'pay-at-table'
  },
  ORDER_AHEAD: {
    name: 'Order Ahead',
    code: 'order-ahead'
  },
  ASAP_PICKUP_ONLY: {
    name: 'Asap Pickup Only',
    code: 'AsapPickupOnly'
  },
  CATERING_DELIVERY: {
    name: 'Catering Delivery',
    code: 'CateringDelivery'
  }
};

export default storeMappingAttributes;
