<template>
  <div>
    <b-switch
      v-if="canHideHours && hasSchedules"
      v-model="hideHoursDisplay"
      :disabled="readOnly"
      :true-value="false"
      :false-value="true"
      class="mar-b-md"
    >
      <p class="label mar-none">Display Schedule Hours</p>
      <p class="sub-label">Display the scheduled hours of availability in the menu</p>
    </b-switch>

    <div class="is-grid gap schedule-days">
      <div v-for="day in Object.keys(value)" :key="day">
        <div class="is-flex justify-between align-center">
          <label class="label mar-none">{{ day }}</label>
          <b-select
            :icon="getIcon(scheduleType[day])"
            :value="scheduleType[day]"
            @input="(val) => handleSelectType(day, val)"
          >
            <option value="available">Available All Day</option>
            <option value="unavailable">Unavailable</option>
            <option value="schedule">Schedule</option>
          </b-select>
        </div>

        <transition name="fade-down">
          <div v-if="scheduleType[day] === 'schedule'" class="is-flex-column align-end gap mar-t mar-b-sm">
            <div class="is-flex-column gap-lg">
              <validation-observer
                v-for="(schedule, index) in value[day]"
                :key="`${day}-${index}`"
                class="is-flex justify-end align-center gap-x"
              >
                <validated-input
                  label-position="on-border"
                  rules="required"
                  name="from"
                  label="From"
                  class="mar-b-none"
                >
                  <b-timepicker
                    :value="value[day][index].startTime && new Date(value[day][index].startTime)"
                    size="is-small"
                    :default-minutes="0"
                    hour-format="12"
                    inline
                    :disabled="readOnly"
                    @input="(newTime) => handleTimeChange({ day, index, type: 'startTime', newTime })"
                  />
                </validated-input>
                <validated-input
                  label-position="on-border"
                  rules="required|dateTimeIsAfter:@from"
                  name="to"
                  label="To"
                  class="mar-b-none"
                >
                  <b-timepicker
                    :value="value[day][index].endTime && new Date(value[day][index].endTime)"
                    size="is-small"
                    :min-time="moment(value[day][index].startTime).add(1, 'minute').toDate()"
                    :default-minutes="0"
                    hour-format="12"
                    inline
                    :disabled="readOnly"
                    @input="(newTime) => handleTimeChange({ day, index, type: 'endTime', newTime })"
                  />
                </validated-input>

                <b-button
                  v-if="!readOnly && value[day].length > 1"
                  icon-left="minus"
                  outlined
                  size="is-small"
                  type="is-danger is-light"
                  @click="removeSchedule({ day, index })"
                />
              </validation-observer>
            </div>
            <b-button
              v-if="!readOnly"
              label="Schedule"
              class="align-self-end pad-x"
              icon-left="plus"
              size="is-small"
              type="is-primary is-light"
              @click="addSchedule(day)"
            />
            <b-message
              v-if="invalidDays.includes(day)"
              type="is-danger"
              size="is-small"
              class="is-compact has-shadow justify-self-start"
              has-icon
            >
              From and To hours cannot overlap each other within the same day
            </b-message>
          </div>
        </transition>
      </div>
    </div>
  </div>
</template>

<script>
  import moment from 'moment-timezone';

  export default {
    name: 'DaypartScheduleInputs',

    props: {
      value: {
        type: Object,
        required: true
      },

      invalidDays: {
        type: Array,
        required: true
      },

      readOnly: {
        type: Boolean,
        default: false
      },

      canHideHours: {
        type: Boolean,
        default: false
      }
    },

    data() {
      return {
        moment,
        scheduleType: {
          Sunday: 'available',
          Monday: 'available',
          Tuesday: 'available',
          Wednesday: 'available',
          Thursday: 'available',
          Friday: 'available',
          Saturday: 'available'
        }
      };
    },

    computed: {
      hasSchedules() {
        const allSchedules = Object.values(this.value).flat();
        return !!allSchedules.length && allSchedules.some(({ startTime, endTime, day }) => (
          [startTime, endTime].some(time => moment(time).format('HH:mm') !== '00:00')
          // NOTE: This second condition is simply for the sake of UI, so that the 'Display Schedule Hours' toggle shows when 12am and 12am are selected
          || (this.scheduleType[day] === 'schedule' && [startTime, endTime].every(time => moment(time).format('HH:mm') === '00:00'))
        ));
      },

      hideHoursDisplay: {
        get() {
          return Object.values(this.value).flat().some(schedule => schedule.hideHoursDisplay);
        },
        set(value) {
          const updatedSchedule = Object.entries(this.$clone(this.value)).reduce((obj, [day, schedules]) => {
            obj[day] = schedules.map(s => ({ ...s, hideHoursDisplay: value }));
            return obj;
          }, {});

          this.$emit('input', updatedSchedule);
        }
      }
    },

    watch: {
      value: {
        immediate: true,
        deep: true,
        handler: 'setScheduleTypes'
      }
    },

    methods: {
      handleTimeChange({ day, index, type, newTime }) {
        const updatedSchedules = this.$clone(this.value);
        updatedSchedules[day][index][type] = newTime;
        this.$emit('input', updatedSchedules);
      },

      addSchedule(day) {
        const updatedSchedules = this.$clone(this.value);
        const newSchedule = { day, startTime: null, endTime: null };
        if (this.canHideHours) newSchedule.hideHoursDisplay = this.hideHoursDisplay;
        updatedSchedules[day].push(newSchedule);
        this.$emit('input', updatedSchedules);
      },

      removeSchedule({ day, index }) {
        const updatedSchedules = this.$clone(this.value);
        updatedSchedules[day].splice(index, 1);
        this.$emit('input', updatedSchedules);
      },

      setScheduleTypes(newSchedules, oldSchedules = {}) {
        if (!Object.keys(oldSchedules).length && Object.keys(newSchedules).length) {
          const isUnavailable = schedules => schedules.length === 1 && [schedules[0].startTime, schedules[0].endTime].every(time => moment(time).format('HH:mm') === '00:00');

          Object.entries(newSchedules).forEach(([day, schedules]) => {
            if (!schedules.length) {
              this.scheduleType[day] = 'available';
            }
            else if (isUnavailable(schedules)) {
              this.scheduleType[day] = 'unavailable';
            }
            else {
              this.scheduleType[day] = 'schedule';
            }
          });
        }
      },

      handleSelectType(day, val) {
        const updatedSchedule = this.$clone(this.value);
        this.scheduleType[day] = val;

        if (val === 'unavailable') {
          updatedSchedule[day] = [{ day, startTime: moment('00', 'hh').toDate(), endTime: moment('00', 'hh').toDate() }];
        }
        else if (val === 'available') {
          updatedSchedule[day] = [];
        }
        else {
          const newSchedule = { day, startTime: null, endTime: null };
          if (this.canHideHours) newSchedule.hideHoursDisplay = this.hideHoursDisplay;
          updatedSchedule[day] = [newSchedule];
        }
        this.$emit('input', updatedSchedule);
      },

      getIcon(type) {
        switch (type) {
          case 'unavailable':
            return 'times';
          case 'available':
            return 'check';
          case 'schedule':
            return 'clock';
          default:
            break;
        }
      }
    }
  };
</script>

<style  lang="sass" scoped>
  ::v-deep .schedule-days
    .fa-times
      color: lighten($danger, 30)
    .fa-check
      color: lighten($success, 30)
    .fa-clock
      color: lighten($primary, 35)
</style>
