import moment from 'moment-timezone';

const storeStandardHours = {
  data() {
    return {
      ssh_form: {},
      ssh_invalidDays: []
    };
  },

  computed: {
    $_storeHoursByDay() {
      const days = ['sun', 'mon', 'tue', 'wed', 'thu', 'fri', 'sat'];
      const newHours = (existing, id, open, close) => [
        ...existing,
        { id, open, close }
      ].sort((a, b) => (a.open < b.open ? -1 : 1));

      return this.store.storeMappingHours.reduce((obj, week) => {
        days.forEach((day) => {
          const openTime = week[`${day}Open`];
          const closeTime = week[`${day}Close`];

          if (openTime) {
            obj[day] = newHours(obj[day], week.id, openTime, closeTime);
          }
        });

        return obj;
      }, { sun: [], mon: [], tue: [], wed: [], thu: [], fri: [], sat: [] }); /* eslint-disable-line */
    }
  },

  methods: {
    $_onCreated() {
      this.p_setForms();
    },

    p_setForms() {
      this.ssh_form = {
        sunIsOpen: this.store.sunIsOpen,
        monIsOpen: this.store.monIsOpen,
        tueIsOpen: this.store.tueIsOpen,
        wedIsOpen: this.store.wedIsOpen,
        thuIsOpen: this.store.thuIsOpen,
        friIsOpen: this.store.friIsOpen,
        satIsOpen: this.store.satIsOpen,
        hoursForm: {
          ...this.$_createHoursForm(this.$_storeHoursByDay)
        }
      };
    },

    $_createHoursForm(dayHours, options = {}) {
      const obj = {};

      Object.entries(dayHours).forEach(([day, hours]) => {
        if (options.initializeWithOpenAllDay) {
          obj[day] = [{
            open: moment.utc(moment().startOf('day')).toDate(),
            close: moment.utc(moment().startOf('day')).toDate(),
            id: `temp-${Date.now()}`
          }];
        }
        else if (hours.length) {
          obj[day] = hours.map(({ open, close, id }) => ({
            id,
            open: moment(open, 'HH:mm').toDate(),
            close: moment(close, 'HH:mm').toDate()
          }));
        }
        else {
          obj[day] = [{ open: null, close: null, id: `temp-${Date.now()}` }];
        }
      });

      return obj;
    },

    $_formatHoursPayload(form) {
      const weekIds = this.store.storeMappingHours.map(week => week.id);
      const weeks = { updated: [], added: [], deletedIds: [] };

      // get the amount of weeks worth of open/close times we have
      let weekCount = Object.values(this.$clone(form)).sort((a, b) => (a.length < b.length ? 1 : -1))[0].length;

      // build up the arrays of updated weekly schedules and new weekly schedules to be added
      while (weekCount > 0) {
        const index = weekCount - 1;
        const week = { id: weekIds[index] };

        Object.entries(this.$clone(form)).forEach(([day, hours]) => {
          week[`${day}Open`] = hours[index]?.open
            ? moment(hours[index].open).format('HH:mm')
            : null;

          week[`${day}Close`] = hours[index]?.close
            ? moment(hours[index].close).format('HH:mm')
            : null;
        });

        weekCount -= 1;

        if (week.id) {
          weeks.updated.push(week);
        }
        else {
          weeks.added.push(week);
        }
      }

      // check to see if there are any existing week IDs that no longer have hours associated with them
      weeks.deletedIds = weekIds.filter(id => !weeks.updated.find(week => week.id === id));

      return weeks;
    },

    $_validateHours(hoursForm) {
      let allHoursValid = true;
      this.ssh_invalidDays = [];

      Object.entries(this.$clone(hoursForm)).forEach(([day, dayHours]) => {
        dayHours
          .sort((a, b) => (a.open < b.open ? -1 : 1))
          .forEach((hours, index) => {
            const currentClose = hours.close;
            const nextOpen = dayHours[index + 1]?.open;

            // verify that the close time for the current hours range
            // is before the open time for the next hours range
            if (nextOpen && currentClose >= nextOpen) {
              allHoursValid = false;
              this.ssh_invalidDays.push(day);
            }
          });
      });
      return allHoursValid;
    }
  }
};

export default storeStandardHours;
