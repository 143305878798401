var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"dialog"},[_c('div',{staticClass:"modal-card no-overflow",class:_vm.autoWidth && 'auto-width',on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"esc",27,$event.key,["Esc","Escape"])){ return null; }return _vm.handleClose($event)}}},[_c('header',{staticClass:"modal-card-head"},[_c('div',{staticClass:"modal-card-title is-flex-column"},[_c('p',[_vm._v(_vm._s(_vm.title))]),(_vm.subtitle)?_c('p',{staticClass:"subtitle is-6 has-text-grey mar-t-xs"},[_vm._v(_vm._s(_vm.subtitle))]):_vm._e()]),(_vm.showCloseButton)?_c('button',{staticClass:"button is-transparent mar-l-md",on:{"click":_vm.handleClose}},[_c('b-icon',{attrs:{"icon":"times","size":"is-small"}})],1):_vm._e()]),_c('div',{class:[
        'alert-modal modal-card-body no-overflow pad-md',
        { 'is-horizontal': _vm.horizontal }
      ]},[(_vm.imagePath || _vm.icon)?_c('div',{staticClass:"alert-modal-graphic",class:{
          'align-graphic-start': _vm.iconPosition === 'top',
          'align-graphic-center': _vm.iconPosition === 'middle',
          'align-graphic-end': _vm.iconPosition === 'bottom',
        }},[(_vm.imagePath)?_c('img',{class:['alert-modal-image', {'is-small': _vm.horizontal}],attrs:{"src":_vm.imagePath,"alt":"banner icon"}}):(_vm.icon)?_c('b-icon',{staticClass:"alert-modal-icon",attrs:{"pack":_vm.iconPack,"icon":_vm.icon,"type":_vm.type,"custom-size":_vm.horizontal ? 'fa-3x' : 'fa-5x'}}):_vm._e()],1):_vm._e(),_c('div',{class:['alert-message', _vm.autoWidth && 'auto-width']},[_vm._t("message",[_c('p',{staticClass:"is-size-6",class:{'has-text-centered': !_vm.horizontal},domProps:{"innerHTML":_vm._s(_vm.message)}})])],2)]),(_vm.buttons || _vm.secondaryMessage)?_c('div',{class:[
        'modal-card-foot',
        { 'has-background-white-bis has-border-top has-border-grey-lightest': _vm.twoToned || _vm.secondaryMessage || _vm.$slots['secondary-message'] }
      ]},[_c('div',[(_vm.secondaryMessage || _vm.$slots['secondary-message'])?_c('div',{staticClass:"secondary-message mar-b",class:_vm.autoWidth && 'auto-width'},[_vm._t("secondary-message",[_c('p',{staticClass:"has-text-centered",domProps:{"innerHTML":_vm._s(_vm.secondaryMessage)}})])],2):_vm._e(),(_vm.buttons)?_c('div',{staticClass:"buttons all-bold is-centered"},_vm._l((_vm.buttons),function(button,index){return _c('b-button',{key:index,ref:("button-" + (index + 1)),refInFor:true,attrs:{"data-focused":_vm.buttons.length === index + 1,"rounded":"","outlined":button.secondary,"icon-right":button.icon,"icon-pack":button.iconPack,"type":button.primary || button.secondary ? ("" + _vm.type + (button.secondary ? ' is-light' : '')) : '',"loading":_vm.loadingButtonIndex === index},on:{"click":function($event){return _vm.handleClick(button.onClick, index)}}},[_vm._v(" "+_vm._s(button.text)+" ")])}),1):_vm._e()])]):_vm._e()])])}
var staticRenderFns = []

export { render, staticRenderFns }