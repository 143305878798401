<template>
  <div>
    <p class="mar-b-sm is-size-5">{{ question.label }}</p>

    <b-input v-if="question.questionType === questionTypes.INPUT" placeholder="Answer will go here" />
    <b-input v-else-if="question.questionType === questionTypes.TEXTAREA" placeholder="Answer will go here" type="textarea" />
    <b-rate v-else-if="question.questionType === questionTypes.STAR_RATING" size="is-large" />
    <div v-else-if="question.questionType === questionTypes.RADIO_LIST" class="radio-group is-vertical">
      <b-radio v-for="option in question.options" :key="option.id" :name="String(question.id)" :value="null">{{ option.value }}</b-radio>
    </div>
    <div v-else-if="question.questionType === questionTypes.CHECKLIST" class="checkbox-group is-vertical">
      <b-checkbox v-for="option in question.options" :key="option.id" :name="String(question.id)" :value="null">{{ option.value }}</b-checkbox>
    </div>
    <b-dropdown v-else-if="question.questionType === questionTypes.MULTIPLE_SELECT" multiple>
      <dropdown-button slot="trigger">
        Select Answer
      </dropdown-button>
      <b-dropdown-item v-for="option in question.options" :key="option.id" :value="option.value">
        {{ option.value }}
      </b-dropdown-item>
    </b-dropdown>
  </div>
</template>

<script>
  import { questionTypes } from '@/constants/surveyQuestionTypes';

  export default {
    name: 'SurveyQuestionPreview',

    props: {
      question: {
        type: Object,
        required: true
      }
    },

    data: () => ({ questionTypes })
  };
</script>
