<template>
  <div class="is-flex-column gap-lg pad has-background-white-bis has-border has-border-grey-lightest has-radius">
    <div class="is-flex justify-between align-baseline">
      <div>
        <p class="title is-6">Platform Type</p>
        <p class="subtitle is-7 has-text-grey">Check the platform type(s) that this offer can be applied to</p>
      </div>
      <b-button
        v-if="!readOnly"
        outlined
        icon-left="trash-alt"
        type="is-danger is-light"
        @click="$emit('remove-constraint', platformTypeConstraint)"
      />
    </div>
    <div v-if="isFetching" class="align-self-center">
      <b-icon icon="spinner-third" class="spin" />
    </div>
    <template v-else>
      <b-message v-if="!platformTypes.length" type="is-warning">
        There are no available platform types. Please configure a platform type before making a constraint.
      </b-message>
      <validated-input
        v-else
        name="platformTypes"
        label="Platform Types"
        hide-label
        rules="required"
        :custom-messages="{
          required: 'Please select at least one platform type'
        }"
      >
        <div class="is-grid col-min-300 gap-md">
          <check-button
            v-for="platformType in platformTypes"
            :key="platformType.name"
            v-model="selectedPlatformTypes"
            :native-value="platformType.name"
            :disabled="readOnly"
            :label="capitalCase(platformType.name)"
          />
        </div>
      </validated-input>
    </template>
  </div>
</template>

<script>
  import { capitalCase } from 'change-case';
  import { constraintTypes } from '@/constants/offers';
  import PlatformType from '@/store/classes/PlatformType';

  export default {
    name: 'PlatformTypeConstraintCard',

    props: {
      platformTypeConstraint: {
        type: Object,
        required: true
      },

      readOnly: {
        type: Boolean,
        default: false
      }
    },

    data() {
      return { constraintTypes };
    },

    computed: {
      platformTypes() {
        return PlatformType.all();
      },

      isFetching() {
        return PlatformType.$state().fetching;
      },

      selectedPlatformTypes: {
        get() {
          return this.platformTypeConstraint.platformTypes;
        },
        set(value) {
          this.$emit('update-constraint', {
            ...this.platformTypeConstraint,
            platformTypes: value
          });
        }
      }
    },

    created() {
      this.onCreated();
    },

    methods: {
      capitalCase,

      async fetchPlatformTypes() {
        try {
          await PlatformType.fetchPlatformTypes();
        }
        catch (error) {
          this.$_onRequestError({ toastOptions: { message: 'Unable to fetch platform types' }, error });
        }
      },

      async onCreated() {
        if (!PlatformType.exists()) {
          await this.fetchPlatformTypes();
        }

        if (!this.selectedPlatformTypes.length) {
          this.selectedPlatformTypes = this.platformTypes.map(platformType => platformType.name);
        }
      }
    }
  };
</script>
