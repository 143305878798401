<template>
  <div class="card is-flex">
    <dropdown-menu
      v-model="activeTab"
      :triggers="['hover']"
      class="hidden-menu is-hidden-tablet"
      position="bottom-end"
    >
      <template #trigger>
        <b-button
          type="is-grey is-light"
          icon-left="bars"
        />
      </template>

      <b-dropdown-item
        v-for="tab in tabs"
        :key="tab.id"
        :value="tab.id"
      >
        {{ tab.display }}
      </b-dropdown-item>
    </dropdown-menu>

    <aside class="sidebar is-hidden-mobile mar-y-xs">
      <ul class="menu-list">
        <li v-for="tab in tabs" :key="tab.id">
          <a
            :class="[
              'menu-item',
              { 'is-active': activeTab === tab.id }
            ]"
            @click="setActiveTab(tab.id)"
          >
            <span class="menu-text">{{ tab.display }}</span>
          </a>
        </li>
      </ul>
    </aside>

    <div class="active-tab-content">
      <slot :active-tab="activeTab" />
    </div>
  </div>
</template>

<script>
  export default {
    name: 'SecondarySidebar',

    props: {
      tabs: {
        type: Array,
        required: true
      }
    },

    data() {
      return {
        // global search passes subTabName in the params so that
        // we can set the active tab to the subTabName
        activeTab: this.$route.params?.subTabName || this.tabs[0]?.id
      };
    },

    methods: {
      setActiveTab(tabId) {
        this.activeTab = tabId;
      }
    }
  };
</script>

<style lang="sass" scoped>
  .sidebar
    display: flex
    flex-direction: column
    border-right: 1px solid #e0e0e0
    width: 20%
    max-width: 220px

  .active-tab-content
    display: flex
    flex-grow: 1
    flex-direction: column
    width: 80%
    padding: 2rem 1rem 0 0.5rem

  .menu-list
    display: flex
    flex-direction: column
    flex-grow: 1
    padding: 0.5rem 0

  .menu-item
    display: block
    padding: 0.75rem 1rem
    color: $grey-dark
    transition: all 0.2s ease
    border-radius: 4px
    margin: 0 0.5rem 0.25rem

    &:hover
      background-color: #f5f5f5

    &.is-active
      background-color: #fff
      font-weight: 600
      box-shadow: 0 2px 8px rgba(10, 10, 10, 0.1)
      border-left: 6px solid $primary

  .menu-text
    color: $grey-dark
    white-space: break-spaces

  @media (max-width: $tablet)
    .card
      flex-direction: column

    .active-tab-content
      width: 100%

    ::v-deep
      .hidden-menu
        align-self: flex-start
        margin: 1rem 0 0 1rem
        width: calc(100% - 2rem)
        .button
          width: 100%
</style>
