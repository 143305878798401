<template>
  <validation-observer v-slot="{ fields }" slim>
    <transition-group name="fade-down-slow" class="dist-y-sm">
      <!-- ORDER TYPES -->
      <criteria-card
        :key="`${criteriaCardMetadata.ORDER_TYPES.defaultCriteriaKey}-criteria-card`"
        :criteria="criteriaCardMetadata.ORDER_TYPES"
        hide-remove-button
      >
        <validated-input
          label="Order Types"
          name="orderTypes"
          hide-label
          :custom-messages="{ required: 'You must select at least one Order Type' }"
          :rules="{ required: Object.keys(fields).length > 1 }"
        >
          <check-button
            v-for="option in orderingTypeOptions"
            :key="option.value"
            v-model="orderTypeIds"
            :native-value="option.value"
          >
            {{ option.display }}
          </check-button>
        </validated-input>
      </criteria-card>

      <!-- LIFETIME SPENDING AVG -->
      <criteria-card
        v-if="criteriaIsActiveFor(criteriaCardMetadata.LIFETIME_SPENDING_AVERAGE.criteriaKeys)"
        :key="`${criteriaCardMetadata.LIFETIME_SPENDING_AVERAGE.defaultCriteriaKey}-criteria-card`"
        :criteria="criteriaCardMetadata.LIFETIME_SPENDING_AVERAGE"
        @remove-criteria="$emit('remove-criteria', $event)"
      >
        <div class="is-flex align-center gap-sm">
          Guest has a check size average between
          <validated-text-input
            v-model="averageSpendingMin"
            type="dollars"
            name="averageSpendingMin"
            :rules="{
              required: true,
              min_value: 0.01
            }"
            label="Minimum"
            placeholder="Minimum"
            hide-label
            class="mar-none"
          />
          and
          <validated-text-input
            v-model="averageSpendingMax"
            type="dollars"
            name="averageSpendingMax"
            :rules="{
              required: true,
              min_value: 0.01,
              greaterThan: '@averageSpendingMin'
            }"
            label="Maximum"
            placeholder="Maximum"
            hide-label
            class="mar-none"
          />
        </div>
      </criteria-card>

      <!-- LIFETIME SPENDING TOTAL -->
      <criteria-card
        v-if="criteriaIsActiveFor(criteriaCardMetadata.LIFETIME_SPENDING_TOTAL.criteriaKeys)"
        :key="`${criteriaCardMetadata.LIFETIME_SPENDING_TOTAL.defaultCriteriaKey}-criteria-card`"
        :criteria="criteriaCardMetadata.LIFETIME_SPENDING_TOTAL"
        @remove-criteria="$emit('remove-criteria', $event)"
      >
        <div class="is-flex align-center gap-sm">
          Guest has a lifetime spending total
          <validated-input
            :rules="{ required: true }"
            hide-label
            name="lifetimeOrderOperator"
            label="Operator"
            class="mar-none"
          >
            <b-select
              v-model="lifetimeOrderOperator"
              placeholder="Select an operator..."
              class="mar-none"
            >
              <option :value="null" disabled>-</option>
              <option v-for="operator in Object.values(equalityOperators)" :key="operator" :value="operator">
                {{ capitalCase(operator) }}
              </option>
            </b-select>
          </validated-input>
          <template v-if="lifetimeOrderOperator !== equalityOperators.BETWEEN">
            <validated-text-input
              v-model="lifetimeOrderTotalEqualityAmount"
              type="dollars"
              name="lifetimeOrderTotalEqualityAmount"
              :rules="{
                min_value: 0,
                required: true
              }"
              label="Amount"
              placeholder="Amount"
              hide-label
              class="mar-none"
            />
          </template>
          <template v-else>
            <div class="is-flex align-center gap-sm lifetime-order-range">
              <validated-text-input
                v-model="lifetimeOrderTotalRangeMin"
                type="dollars"
                name="lifetimeOrderTotalRangeMin"
                :rules="{
                  required: true,
                  min_value: 0.01
                }"
                label="Minimum"
                placeholder="Minimum"
                hide-label
                class="mar-none"
              />
              and
              <validated-text-input
                v-model="lifetimeOrderTotalRangeMax"
                type="dollars"
                name="lifetimeOrderTotalRangeMax"
                :rules="{
                  required: true,
                  min_value: 0.01,
                  greaterThan: '@lifetimeOrderTotalRangeMin'
                }"
                label="Maximum"
                placeholder="Maximum"
                hide-label
                class="mar-none"
              />
            </div>
          </template>
        </div>
      </criteria-card>

      <!-- DAYS SINCE LAST ORDER -->
      <criteria-card
        v-if="criteriaIsActiveFor(criteriaCardMetadata.DAYS_SINCE_LAST_ORDER.criteriaKeys)"
        :key="`${criteriaCardMetadata.DAYS_SINCE_LAST_ORDER.defaultCriteriaKey}-criteria-card`"
        :criteria="criteriaCardMetadata.DAYS_SINCE_LAST_ORDER"
        @remove-criteria="(event) => {
          $emit('remove-criteria', event);
          durationType = 'day';
        }"
      >
        <div>
          <div class="is-flex align-center gap-sm">
            Guest has placed an order in the last
            <validated-input
              :rules="{
                required: true,
                min_value: 1
              }"
              name="daysSinceLastOrder"
              label="Duration"
              hide-label
              class="mar-none"
            >
              <b-numberinput
                :value="computedDuration"
                controls-position="compact"
                min="1"
                data-test-id="duration-input"
                @input="handleDurationChange({ duration: $event })"
              />
            </validated-input>
            <b-select
              :value="durationType"
              data-test-id="duration-type-select"
              @input="handleDurationTypeChange"
            >
              <option
                v-for="dt in durationTypes"
                :key="dt.value"
                :value="dt.value"
              >
                {{ dt.display }}
              </option>
            </b-select>
            <span v-if="durationType !== 'day'" class="has-text-grey is-size-7">
              ({{ value.daysSinceLastOrder }} Days)
            </span>
          </div>
        </div>
      </criteria-card>

      <!-- ORDER COUNTS -->
      <criteria-card
        v-if="criteriaIsActiveFor(criteriaCardMetadata.ORDER_COUNTS.criteriaKeys)"
        :key="`${criteriaCardMetadata.ORDER_COUNTS.defaultCriteriaKey}-criteria-card`"
        :criteria="criteriaCardMetadata.ORDER_COUNTS"
        @remove-criteria="$emit('remove-criteria', $event)"
      >
        <div>
          <div class="is-flex align-center gap-sm mar-b-lg">
            Guest placed
            <validated-input
              :rules="{ required: true }"
              hide-label
              name="orderCountsOperator"
              data-test-id="order-counts-operator"
              label="Operator"
              class="mar-none"
            >
              <b-select
                v-model="orderCountsOperator"
                placeholder="Select an operator..."
                class="mar-none"
              >
                <option :value="null" disabled>-</option>
                <option v-for="operator in Object.values(equalityOperators)" :key="operator" :value="operator">
                  {{ capitalCase(operator) }}
                </option>
              </b-select>
            </validated-input>
            <template v-if="orderCounts.equality">
              <validated-text-input
                :value="orderCounts.equality.amount"
                type="number"
                name="orderCountsAmount"
                data-test-id="order-counts-amount"
                :rules="{
                  min_value: 0,
                  required: true
                }"
                label="Amount"
                placeholder="Amount"
                hide-label
                class="mar-none"
                @input="handleEqualityChange({ val: $event, type: 'amount', criteriaKey: 'orderCounts' })"
              />
            </template>
            <template v-else-if="orderCounts.range">
              <div class="is-flex align-center gap-sm lifetime-order-range">
                <validated-text-input
                  :value="orderCounts.range.aboveAmount"
                  type="number"
                  name="orderCountsAboveAmount"
                  data-test-id="order-counts-above-amount"
                  :rules="{
                    required: true,
                    min_value: 0
                  }"
                  label="Minimum"
                  placeholder="Minimum"
                  hide-label
                  class="mar-none"
                  @input="handleRangeChange({ val: $event, type: 'aboveAmount', criteriaKey: 'orderCounts' })"
                />
                and
                <validated-text-input
                  :value="orderCounts.range.belowAmount"

                  type="number"
                  name="orderCountsBelowAmount"
                  data-test-id="order-counts-below-amount"
                  :rules="{
                    required: true,
                    min_value: 1,
                    greaterThan: '@orderCountsAboveAmount'
                  }"
                  label="Maximum"
                  placeholder="Maximum"
                  hide-label
                  class="mar-none"
                  @input="handleRangeChange({ val: $event, type: 'belowAmount', criteriaKey: 'orderCounts' })"
                />
              </div>
            </template>
          </div>
          <div class="is-flex align-center gap-sm">
            during the
            <validated-input
              :rules="{ required: true }"
              hide-label
              name="orderCountsDaysBackType"
              label="Time Frame"
              class="mar-none"
            >
              <b-select
                data-test-id="order-counts-days-back-type"
                :value="orderCounts.daysBackType"
                placeholder="Select an time frame..."
                class="mar-none"
                icon="calendar-days"
                @input="($event) => handleOrderCountsChange('daysBackType', $event)"
              >
                <option :value="null" disabled>-</option>
                <option v-for="dbType in Object.values(daysBackTypes)" :key="dbType.value" :value="dbType.value">
                  {{ dbType.display }}
                </option>
              </b-select>
            </validated-input>
            from
            <validation-provider v-slot="{ errors }" name="beginEndTime" :rules="{ required: !orderCountsBeginTimeIsBeforeEndTime }">
              <b-checkbox :value="orderCountsBeginTimeIsBeforeEndTime || null" :class="['is-hidden', { 'invalid': errors.length }]" />
              <b-field
                label=""
                class="orderCountsTimes"
                :message="errors.length && !orderCountsBeginTimeIsBeforeEndTime ? orderCountsTimeErrorMessage : ''"
                type="is-danger"
              >
                <validated-input
                  name="orderCountsBeginTime"
                  label="Start Time (optional)"
                  label-position="on-border"
                  class="mar-b-none"
                >
                  <b-timepicker
                    data-test-id="order-counts-begin-time"
                    :value="moment(orderCounts.beginTime, 'HH:mm').toDate()"
                    size="is-small"
                    :default-minutes="0"
                    hour-format="12"
                    inline
                    @input="handleOrderCountsChange('beginTime', $event)"
                  />
                </validated-input>
                <span class="pad-x-sm align-self-center">
                  to
                </span>
                <validated-input
                  name="orderCountsEndTime"
                  label="End Time (optional)"
                  label-position="on-border"
                >
                  <b-timepicker
                    name="orderCountsEndTime"
                    data-test-id="order-counts-end-time"
                    :value="moment(orderCounts.endTime, 'HH:mm').toDate()"
                    size="is-small"
                    :default-minutes="0"
                    hour-format="12"
                    inline
                    @input="handleOrderCountsChange('endTime', $event)"
                  />
                </validated-input>
              </b-field>
            </validation-provider>
          </div>
        </div>
        <b-button
          slot="buttons"
          size="is-small"
          type="is-primary is-light"
          class="mar-r"
          icon-right="clock"
          @click="clearOrderCountsTime"
        >
          Clear Times
        </b-button>
      </criteria-card>

      <!-- ORDER TOTALS -->
      <criteria-card
        v-if="criteriaIsActiveFor(criteriaCardMetadata.ORDER_TOTALS.criteriaKeys)"
        :key="`${criteriaCardMetadata.ORDER_TOTALS.defaultCriteriaKey}-criteria-card`"
        :criteria="criteriaCardMetadata.ORDER_TOTALS"
        @remove-criteria="$emit('remove-criteria', $event)"
      >
        <div>
          <div class="is-flex align-center gap-sm mar-b-lg">
            Guest spent
            <validated-input
              :rules="{ required: true }"
              hide-label
              name="orderTotalsOperator"
              data-test-id="order-totals-operator"
              label="Operator"
              class="mar-none"
            >
              <b-select
                v-model="orderTotalsOperator"
                placeholder="Select an operator..."
                class="mar-none"
              >
                <option :value="null" disabled>-</option>
                <option v-for="operator in Object.values(equalityOperators)" :key="operator" :value="operator">
                  {{ capitalCase(operator) }}
                </option>
              </b-select>
            </validated-input>
            <template v-if="orderTotals.equality">
              <validated-text-input
                :value="orderTotals.equality.amount"
                type="number"
                name="orderTotalsAmount"
                data-test-id="order-totals-amount"
                :rules="{
                  min_value: 0,
                  required: true
                }"
                label="Amount"
                placeholder="Amount"
                hide-label
                class="mar-none"
                @input="handleEqualityChange({ val: $event, type: 'amount', criteriaKey: 'orderTotals' })"
              />
            </template>
            <template v-else-if="orderTotals.range">
              <div class="is-flex align-center gap-sm lifetime-order-range">
                <validated-text-input
                  :value="orderTotals.range.aboveAmount"
                  type="number"
                  name="orderTotalsAboveAmount"
                  data-test-id="order-totals-above-amount"
                  :rules="{
                    required: true,
                    min_value: 0
                  }"
                  label="Minimum"
                  placeholder="Minimum"
                  hide-label
                  class="mar-none"
                  @input="handleRangeChange({ val: $event, type: 'aboveAmount', criteriaKey: 'orderTotals' })"
                />
                and
                <validated-text-input
                  :value="orderTotals.range.belowAmount"

                  type="number"
                  name="orderTotalsBelowAmount"
                  data-test-id="order-counts-below-amount"
                  :rules="{
                    required: true,
                    min_value: 1,
                    greaterThan: '@orderTotalsAboveAmount'
                  }"
                  label="Maximum"
                  placeholder="Maximum"
                  hide-label
                  class="mar-none"
                  @input="handleRangeChange({ val: $event, type: 'belowAmount', criteriaKey: 'orderTotals' })"
                />
              </div>
            </template>
          </div>
          <div class="is-flex align-center gap-sm">
            from
            <validated-input
              name="orderTotalsBeginDate"
              label="Start Date"
              hide-label
              rules="required"
              class="mar-none"
            >
              <b-datepicker
                v-model="orderTotalsBeginDate"
                :years-range="dys_ranges.orderTotalsBeginDate"
                placeholder="Start Date"
                icon="calendar-alt"
                :nearby-selectable-month-days="true"
                :mobile-native="false"
                position="is-top-right"
                @change-year="(year) => $_handleYearChange({ year, type: 'orderTotalsBeginDate' })"
              />
            </validated-input>
            to
            <validated-input
              name="orderTotalsEndDate"
              label="End Date"
              hide-label
              :rules="{
                required: true,
                greaterThan: '@orderTotalsBeginDate'
              }"
              :custom-messages="{
                greaterThan: 'End Date must come after Start Date'
              }"
              class="mar-none"
            >
              <b-datepicker
                v-model="orderTotalsEndDate"
                placeholder="End Date"
                :years-range="dys_ranges.orderTotalsEndDate"
                icon="calendar-alt"
                :nearby-selectable-month-days="true"
                :mobile-native="false"
                position="is-top-right"
                @change-year="(year) => $_handleYearChange({ year, type: 'orderTotalsEndDate' })"
              />
            </validated-input>
          </div>
        </div>
      </criteria-card>

      <!-- ORDER ITEM CATEGORY -->
      <criteria-card
        v-if="criteriaIsActiveFor(criteriaCardMetadata.ORDER_ITEM_CATEGORY.criteriaKeys)"
        :key="`${criteriaCardMetadata.ORDER_ITEM_CATEGORY.defaultCriteriaKey}-criteria-card`"
        :criteria="criteriaCardMetadata.ORDER_ITEM_CATEGORY"
        @remove-criteria="$emit('remove-criteria', $event)"
      >
        <div>
          <div class="is-flex align-center gap-sm mar-b-lg">
            Guest placed an order with an item from the
            <validated-input
              :rules="{ required: true }"
              hide-label
              name="orderItemCategoryId"
              label="Category"
              class="mar-none"
            >
              <b-select
                data-test-id="order-item-category-id"
                :value="orderItemCategory.categoryId"
                placeholder="Select a category..."
                class="mar-none"
                @input="($event) => handleOrderItemCategoryChange('categoryId', $event)"
              >
                <option :value="null" disabled>-</option>
                <option v-for="cat in categories" :key="cat.id" :value="cat.id">
                  {{ cat.displayName }}
                </option>
              </b-select>
            </validated-input>
            category
          </div>
          <div class="is-flex align-center gap-sm">
            <validated-input
              :rules="{ required: true }"
              hide-label
              name="orderItemCategoryOperator"
              data-test-id="order-item-category-operator"
              label="Operator"
              class="mar-none"
            >
              <b-select
                v-model="orderItemCategoryOperator"
                placeholder="Select an operator..."
                class="mar-none"
              >
                <option :value="null" disabled>-</option>
                <option v-for="operator in Object.values(equalityOperators)" :key="operator" :value="operator">
                  {{ capitalCase(operator) }}
                </option>
              </b-select>
            </validated-input>
            <template v-if="orderItemCategoryOperator !== equalityOperators.BETWEEN">
              <validated-text-input
                :value="orderItemCategory.equality.amount"
                type="number"
                name="orderItemCategoryAmount"
                data-test-id="order-item-category-amount"
                :rules="{
                  min_value: 0,
                  required: true
                }"
                label="Amount"
                placeholder="Amount"
                hide-label
                class="mar-none"
                @input="handleEqualityChange({ val: $event, type: 'amount', criteriaKey: 'orderItemCategory' })"
              />
            </template>
            <template v-else>
              <div class="is-flex align-center gap-sm lifetime-order-range">
                <validated-text-input
                  :value="orderItemCategory.range.aboveAmount"
                  type="number"
                  name="orderItemCategoryAboveAmount"
                  data-test-id="order-item-category-above-amount"
                  :rules="{
                    required: true,
                    min_value: 0
                  }"
                  label="Minimum"
                  placeholder="Minimum"
                  hide-label
                  class="mar-none"
                  @input="handleRangeChange({ val: $event, type: 'aboveAmount', criteriaKey: 'orderItemCategory' })"
                />
                and
                <validated-text-input
                  :value="orderItemCategory.range.belowAmount"

                  type="number"
                  name="orderItemCategoryBelowAmount"
                  data-test-id="order-item-category-below-amount"
                  :rules="{
                    required: true,
                    min_value: 1,
                    greaterThan: '@orderItemCategoryAboveAmount'
                  }"
                  label="Maximum"
                  placeholder="Maximum"
                  hide-label
                  class="mar-none"
                  @input="handleRangeChange({ val: $event, type: 'belowAmount', criteriaKey: 'orderItemCategory' })"
                />
              </div>
            </template>
            times during the
            <validated-input
              :rules="{ required: true }"
              hide-label
              name="orderItemCategoryDaysBackType"
              label="Time Frame"
              class="mar-none"
            >
              <b-select
                data-test-id="order-item-category-days-back-type"
                :value="orderItemCategory.daysBackType"
                placeholder="Select a time frame..."
                class="mar-none"
                icon="calendar-days"
                @input="($event) => handleOrderItemCategoryChange('daysBackType', $event)"
              >
                <option :value="null" disabled>-</option>
                <option v-for="dbType in Object.values(daysBackTypes)" :key="dbType.value" :value="dbType.value">
                  {{ dbType.display }}
                </option>
              </b-select>
            </validated-input>
          </div>
        </div>
      </criteria-card>

      <!-- ORDER ITEM ATTRIBUTE -->
      <criteria-card
        v-if="criteriaIsActiveFor(criteriaCardMetadata.ORDER_ITEM_ATTRIBUTE.criteriaKeys)"
        :key="`${criteriaCardMetadata.ORDER_ITEM_ATTRIBUTE.defaultCriteriaKey}-criteria-card`"
        :criteria="criteriaCardMetadata.ORDER_ITEM_ATTRIBUTE"
        @remove-criteria="$emit('remove-criteria', $event)"
      >
        <div>
          <div class="is-flex align-center gap-sm mar-b-lg">
            Guest placed an order with an item with the
            <validated-input
              :rules="{ required: true }"
              hide-label
              name="orderItemAttributeId"
              label="Tag"
              class="mar-none"
            >
              <b-select
                data-test-id="order-item-attriute-id"
                :value="orderItemAttribute.menuAttributeId"
                placeholder="Select a tag..."
                class="mar-none"
                @input="($event) => handleOrderItemAttributeChange('menuAttributeId', $event)"
              >
                <option :value="null" disabled>-</option>
                <option v-for="tag in itemTags" :key="tag.id" :value="tag.id">
                  {{ tag.displayName }}
                </option>
              </b-select>
            </validated-input>
            tag
          </div>
          <div class="is-flex align-center gap-sm">
            <validated-input
              :rules="{ required: true }"
              hide-label
              name="orderItemAttributeOperator"
              data-test-id="order-item-attribute-operator"
              label="Operator"
              class="mar-none"
            >
              <b-select
                v-model="orderItemAttributeOperator"
                placeholder="Select an operator..."
                class="mar-none"
              >
                <option :value="null" disabled>-</option>
                <option v-for="operator in Object.values(equalityOperators)" :key="operator" :value="operator">
                  {{ capitalCase(operator) }}
                </option>
              </b-select>
            </validated-input>
            <template v-if="orderItemAttributeOperator !== equalityOperators.BETWEEN">
              <validated-text-input
                :value="orderItemAttribute.equality.amount"
                type="number"
                name="orderItemAttributeAmount"
                data-test-id="order-item-attribute-amount"
                :rules="{
                  min_value: 0,
                  required: true
                }"
                label="Amount"
                placeholder="Amount"
                hide-label
                class="mar-none"
                @input="handleEqualityChange({ val: $event, type: 'amount', criteriaKey: 'orderItemAttribute' })"
              />
            </template>
            <template v-else>
              <div class="is-flex align-center gap-sm lifetime-order-range">
                <validated-text-input
                  :value="orderItemAttribute.range.aboveAmount"
                  type="number"
                  name="orderItemAttributeAboveAmount"
                  data-test-id="order-item-attribute-above-amount"
                  :rules="{
                    required: true,
                    min_value: 0
                  }"
                  label="Minimum"
                  placeholder="Minimum"
                  hide-label
                  class="mar-none"
                  @input="handleRangeChange({ val: $event, type: 'aboveAmount', criteriaKey: 'orderItemAttribute' })"
                />
                and
                <validated-text-input
                  :value="orderItemAttribute.range.belowAmount"

                  type="number"
                  name="orderItemAttributeBelowAmount"
                  data-test-id="order-item-attribute-below-amount"
                  :rules="{
                    required: true,
                    min_value: 1,
                    greaterThan: '@orderItemAttributeAboveAmount'
                  }"
                  label="Maximum"
                  placeholder="Maximum"
                  hide-label
                  class="mar-none"
                  @input="handleRangeChange({ val: $event, type: 'belowAmount', criteriaKey: 'orderItemAttribute' })"
                />
              </div>
            </template>
            times during the
            <validated-input
              :rules="{ required: true }"
              hide-label
              name="orderItemAttributeDaysBackType"
              label="Time Frame"
              class="mar-none"
            >
              <b-select
                data-test-id="order-item-attribute-days-back-type"
                :value="orderItemAttribute.daysBackType"
                placeholder="Select a time frame..."
                class="mar-none"
                icon="calendar-days"
                @input="($event) => handleOrderItemAttributeChange('daysBackType', $event)"
              >
                <option :value="null" disabled>-</option>
                <option v-for="dbType in Object.values(daysBackTypeExcludingLifetime)" :key="dbType.value" :value="dbType.value">
                  {{ dbType.display }}
                </option>
              </b-select>
            </validated-input>
          </div>
        </div>
      </criteria-card>

      <!-- ORDER AVERAGES -->
      <criteria-card
        v-if="criteriaIsActiveFor(criteriaCardMetadata.ORDER_AVERAGES.criteriaKeys)"
        :key="`${criteriaCardMetadata.ORDER_AVERAGES.defaultCriteriaKey}-criteria-card`"
        :criteria="criteriaCardMetadata.ORDER_AVERAGES"
        @remove-criteria="$emit('remove-criteria', $event)"
      >
        <div class="is-flex align-center gap-sm mar-b-lg">
          Guest placed
          <validated-input
            :rules="{ required: true }"
            hide-label
            name="orderAveragesOperator"
            data-test-id="order-averages-operator"
            label="Operator"
            class="mar-none"
          >
            <b-select
              v-model="orderAveragesOperator"
              placeholder="Select an operator..."
              class="mar-none"
            >
              <option :value="null" disabled>-</option>
              <option v-for="operator in Object.values(equalityOperators)" :key="operator" :value="operator">
                {{ capitalCase(operator) }}
              </option>
            </b-select>
          </validated-input>
          <template v-if="orderAveragesOperator !== equalityOperators.BETWEEN">
            <validated-text-input
              :value="orderAverages.equality.amount"
              type="number"
              name="orderAveragesAmount"
              data-test-id="order-averages-amount"
              :rules="{
                min_value: 0,
                required: true
              }"
              label="Amount"
              placeholder="Amount"
              hide-label
              class="mar-none"
              @input="handleEqualityChange({ val: $event, type: 'amount', criteriaKey: 'orderAverages' })"
            />
          </template>
          <template v-else>
            <validated-text-input
              :value="orderAverages.range.aboveAmount"
              type="number"
              name="orderAveragesAboveAmount"
              data-test-id="order-averages-above-amount"
              :rules="{
                required: true,
                min_value: 0
              }"
              label="Minimum"
              placeholder="Minimum"
              hide-label
              class="mar-none"
              @input="handleRangeChange({ val: $event, type: 'aboveAmount', criteriaKey: 'orderAverages' })"
            />
            and
            <validated-text-input
              :value="orderAverages.range.belowAmount"

              type="number"
              name="orderAveragesBelowAmount"
              data-test-id="order-averages-below-amount"
              :rules="{
                required: true,
                min_value: 1,
                greaterThan: '@orderAveragesAboveAmount'
              }"
              label="Maximum"
              placeholder="Maximum"
              hide-label
              class="mar-none"
              @input="handleRangeChange({ val: $event, type: 'belowAmount', criteriaKey: 'orderAverages' })"
            />
          </template>
          orders per
          <validated-input
            :rules="{ required: true }"
            hide-label
            name="orderAveragesTimeFrame"
            label="Time Frame"
            class="mar-none"
          >
            <b-select
              data-test-id="order-averages-time-frame"
              :value="orderAverages.type"
              placeholder="Select a time frame..."
              class="mar-none"
              icon="calendar-days"
              @input="handleOrderAveragesTimeFrameChange"
            >
              <option v-for="t in timeFrameTypes" :key="t.value" :value="t.value">
                {{ t.display }}
              </option>
            </b-select>
          </validated-input>
          on average
        </div>
      </criteria-card>

      <!-- SPEND PERCENTILE -->
      <criteria-card
        v-if="criteriaIsActiveFor(criteriaCardMetadata.SPEND_PERCENTILE.criteriaKeys)"
        :key="`${criteriaCardMetadata.SPEND_PERCENTILE.defaultCriteriaKey}-criteria-card`"
        :criteria="criteriaCardMetadata.SPEND_PERCENTILE"
        @remove-criteria="$emit('remove-criteria', $event)"
      >
        <div class="is-flex align-center gap-sm mar-b-lg">
          Guest is in the
          <b-select
            data-test-id="spend-percentile-range"
            :value="spendPercentile.range"
            placeholder="Select a range..."
            class="mar-none spend-percentile"
            icon="sliders"
            @input="($event) => updateSpendPercentile('range', $event)"
          >
            <option :value="null" disabled>-</option>
            <option v-for="dbType in Object.values(percentileRanges)" :key="dbType.value" :value="dbType.value">
              {{ dbType.display }}
            </option>
          </b-select>
          <validated-text-input
            v-model="spendPercentilePercent"
            type="number"
            name="spendPercentilePercent"
            :rules="{
              required: true,
              min_value: 0,
              max_value: 100
            }"
            label="Percentage"
            placeholder="Percentage"
            hide-label
            class="mar-none spend-percentile-percent"
            icon-right="percent"
          />
          of spenders
        </div>
        <div class="is-flex align-center gap-sm">
          during the
          <b-select
            data-test-id="spend-percentile-days-back-type"
            :value="spendPercentile.daysBackType"
            placeholder="Select a time frame..."
            class="mar-none"
            icon="calendar-days"
            @input="($event) => updateSpendPercentile('daysBackType', $event)"
          >
            <option :value="null" disabled>-</option>
            <option v-for="dbType in Object.values(daysBackTypeExcludingLifetime)" :key="dbType.value" :value="dbType.value">
              {{ dbType.display }}
            </option>
          </b-select>
        </div>
      </criteria-card>

      <!-- FAVORITE ITEM -->
      <criteria-card
        v-if="criteriaIsActiveFor(criteriaCardMetadata.FAVORITE_ITEM.criteriaKeys)"
        :key="`${criteriaCardMetadata.FAVORITE_ITEM.defaultCriteriaKey}-criteria-card`"
        :criteria="criteriaCardMetadata.FAVORITE_ITEM"
        @remove-criteria="$emit('remove-criteria', $event)"
      >
        <div class="is-flex align-center gap-sm">
          <validated-input
            :rules="{ required: true }"
            hide-label
            name="menuItemId"
            label="Favorite Item"
            class="mar-none"
          >
            <b-select
              v-model="menuItemId"
              placeholder="Select an item..."
              class="mar-none"
            >
              <option v-for="item in favoritedItems" :key="item.id" :value="item.id">
                {{ item.displayName }}
              </option>
            </b-select>
          </validated-input>
          is in the guest's favorite items.
        </div>
      </criteria-card>

      <!-- LAPSED VISITS -->
      <criteria-card
        v-if="criteriaIsActiveFor(criteriaCardMetadata.LAPSED_VISITS.criteriaKeys)"
        :key="`${criteriaCardMetadata.LAPSED_VISITS.defaultCriteriaKey}-criteria-card`"
        :criteria="criteriaCardMetadata.LAPSED_VISITS"
        @remove-criteria="$emit('remove-criteria', $event)"
      >
        <div class="is-flex align-center gap-sm is-flex-wrap">
          Guest has missed
          <validated-input
            :rules="{
              required: true,
              min_value: 1
            }"
            :custom-messages="{
              required: 'Please enter a number of missed visits',
              min_value: 'Must be at least 1'
            }"
            hide-label
            name="missedNumberOfVisits"
            label="Missed Visits"
            class="mar-none"
          >
            <b-numberinput
              :value="lapsedVisits.missedNumberOfVisits"
              controls-position="compact"
              min="1"
              class="mar-none"
              @input="handleLapsedVisitsChange({ type: 'missedNumberOfVisits', value: $event })"
            />
          </validated-input>
          regular visits. It has been
          <validated-input
            :rules="{
              required: true,
              min_value: 1
            }"
            :custom-messages="{
              required: 'Please enter a number of days since last notification',
              min_value: 'Must be at least 1'
            }"
            hide-label
            name="daysSinceLastLapsedNotification"
            label="Days Since Last Notification"
            class="mar-none"
          >
            <b-numberinput
              :value="lapsedVisits.daysSinceLastLapsedNotification"
              controls-position="compact"
              min="1"
              class="mar-none"
              @input="handleLapsedVisitsChange({ type: 'daysSinceLastLapsedNotification', value: $event })"
            />
          </validated-input>
          days since their last lapsed user notification.
        </div>
      </criteria-card>

      <!-- GIFT CARD PURCHASES -->
      <criteria-card
        v-if="criteriaIsActiveFor(criteriaCardMetadata.GIFT_CARD_PURCHASE_COUNT.criteriaKeys)"
        :key="`${criteriaCardMetadata.GIFT_CARD_PURCHASE_COUNT.defaultCriteriaKey}-criteria-card`"
        :criteria="criteriaCardMetadata.GIFT_CARD_PURCHASE_COUNT"
        @remove-criteria="$emit('remove-criteria', $event)"
      >
        <div class="is-flex align-center gap-sm mar-b-lg">
          Guest has purchased
          <validated-input
            :rules="{ required: true }"
            hide-label
            name="giftCardPurchaseCountOperator"
            data-test-id="gift-card-purchase-count-operator"
            label="Operator"
            class="mar-none"
          >
            <b-select
              v-model="giftCardPurchaseCountOperator"
              placeholder="Select an operator..."
              class="mar-none"
            >
              <option :value="null" disabled>-</option>
              <option v-for="operator in Object.values(equalityOperators)" :key="operator" :value="operator">
                {{ capitalCase(operator) }}
              </option>
            </b-select>
          </validated-input>
          <template v-if="giftCardPurchaseCountOperator !== equalityOperators.BETWEEN">
            <validated-text-input
              :value="giftCardPurchaseCount.equality.amount"
              type="number"
              name="giftCardPurchaseCountAmount"
              data-test-id="gift-card-purchase-count-amount"
              :rules="{
                min_value: 0,
                required: true
              }"
              label="Amount"
              placeholder="Amount"
              hide-label
              class="mar-none"
              @input="handleEqualityChange({ val: $event, type: 'amount', criteriaKey: 'giftCardPurchaseCount' })"
            />
          </template>
          <template v-else>
            <validated-text-input
              :value="giftCardPurchaseCount.range.aboveAmount"
              type="number"
              name="giftCardPurchaseCountAboveAmount"
              data-test-id="gift-card-purchase-count-above-amount"
              :rules="{
                required: true,
                min_value: 0
              }"
              label="Minimum"
              placeholder="Minimum"
              hide-label
              class="mar-none"
              @input="handleRangeChange({ val: $event, type: 'aboveAmount', criteriaKey: 'giftCardPurchaseCount' })"
            />
            and
            <validated-text-input
              :value="giftCardPurchaseCount.range.belowAmount"

              type="number"
              name="giftCardPurchaseCountBelowAmount"
              data-test-id="gift-card-purchase-count-below-amount"
              :rules="{
                required: true,
                min_value: 1,
                greaterThan: '@giftCardPurchaseCountAboveAmount'
              }"
              label="Maximum"
              placeholder="Maximum"
              hide-label
              class="mar-none"
              @input="handleRangeChange({ val: $event, type: 'belowAmount', criteriaKey: 'giftCardPurchaseCount' })"
            />
          </template>
          gift cards during the
          <validated-input
            :rules="{ required: true }"
            hide-label
            name="giftCardPurchaseCountDaysBackType"
            label="Time Frame"
            class="mar-none"
            data-test-id="gift-card-purchase-count-days-back-type"
          >
            <b-select
              v-model="giftCardPurchaseCount.daysBackType"
              placeholder="Select a time frame..."
              class="mar-none"
              icon="calendar-days"
            >
              <option :value="null" disabled>-</option>
              <option v-for="dbType in Object.values(daysBackTypes)" :key="dbType.value" :value="dbType.value">
                {{ dbType.display }}
              </option>
            </b-select>
          </validated-input>
        </div>
      </criteria-card>
    </transition-group>
  </validation-observer>
</template>

<script>
  import { capitalCase } from 'change-case';

  import merchantMixin from '@/mixins/merchant';
  import moment from 'moment-timezone';

  import CriteriaCard from './criteria-card.vue';
  import { criteriaCardMetadata, equalityOperators, daysBackTypes, percentileRanges } from '@/constants/segmentations';
  import Category from '@/store/classes/Category';
  import dynamicYearSelectMixin from '@/mixins/dynamic-year-select';
  import MerchantMenuAttribute from '@/store/classes/MerchantMenuAttribute';
  import Item from '@/store/classes/Item';

  export default {
    name: 'OrderingCriteriaForm',

    components: { CriteriaCard },

    mixins: [merchantMixin, dynamicYearSelectMixin],

    props: {
      value: {
        type: Object,
        required: true
      }
    },

    data() {
      return {
        criteriaCardMetadata,
        equalityOperators,
        daysBackTypes,
        percentileRanges,
        durationType: 'day',
        timeFrameTypes: [
          { display: 'week', value: 'Week' },
          { display: 'month', value: 'Month' }
        ]
      };
    },

    computed: {
      categories() {
        return Category.query().orderBy('displayName').get();
      },

      itemTags() {
        return MerchantMenuAttribute.bySearchTagType().map(tag => ({
          id: tag.menuAttribute.id,
          displayName: tag.menuAttribute.name
        }));
      },

      favoritedItems() {
        return Item.favoritedItems();
      },

      daysBackTypeExcludingLifetime() {
        const { LIFETIME, ...remaining } = daysBackTypes;
        return remaining;
      },

      orderingTypeOptions() {
        const {
          externalOrderEnabled,
          oatEnabled,
          orderAheadEnabled,
          patEnabled,
          simpleOrderAheadEnabled,
          textToPayEnabled
        } = this.$_selectedMerchant;

        return [
          {
            display: 'Order Ahead',
            value: 1, // orderTypeId 1 = OrderAhead
            isVisible: orderAheadEnabled || simpleOrderAheadEnabled
          },
          {
            display: [patEnabled && 'Pay@Table', textToPayEnabled && 'Text-to-Pay'].filter(Boolean).join(' / '),
            value: 2, // orderTypeId 2 = PayAndFulfill
            isVisible: patEnabled || textToPayEnabled
          },
          {
            display: 'Order@Table',
            value: 3, // orderTypeId 3 = OrderAndPay
            isVisible: oatEnabled
          },
          {
            display: 'In Store',
            value: 6, // orderTypeId 6 = ExternalOrder
            isVisible: externalOrderEnabled
          }
        ].reduce((array, { display, value, isVisible }) => {
          if (isVisible) array.push({ display, value });
          return array;
        }, []);
      },

      orderTypeIds: {
        get() {
          return this.value.orderTypeIds || [];
        },
        set(value) {
          this.handleInput({ orderTypeIds: value });
        }
      },

      averageSpendingMin: {
        get() {
          return this.value.averageOrderTotalRange?.aboveAmount;
        },
        set(value) {
          this.handleInput({
            averageOrderTotalRange: {
              ...this.value.averageOrderTotalRange,
              aboveAmount: value
            }
          });
        }
      },

      averageSpendingMax: {
        get() {
          return this.value.averageOrderTotalRange?.belowAmount;
        },
        set(value) {
          this.handleInput({
            averageOrderTotalRange: {
              ...this.value.averageOrderTotalRange,
              belowAmount: value
            }
          });
        }
      },

      lifetimeOrderTotalRangeMax: {
        get() {
          return this.value.lifetimeOrderTotalRange?.belowAmount;
        },
        set(val) {
          this.handleInput({
            lifetimeOrderTotalRange: {
              ...this.value.lifetimeOrderTotalRange,
              belowAmount: val
            }
          });
        }
      },

      lifetimeOrderTotalRangeMin: {
        get() {
          return this.value.lifetimeOrderTotalRange?.aboveAmount;
        },
        set(val) {
          this.handleInput({
            lifetimeOrderTotalRange: {
              ...this.value.lifetimeOrderTotalRange,
              aboveAmount: val
            }
          });
        }
      },

      lifetimeOrderOperator: {
        get() {
          if (this.value.lifetimeOrderTotalEquality) {
            return this.value.lifetimeOrderTotalEquality.operator;
          }
          return this.value.lifetimeOrderTotalRange ? equalityOperators.BETWEEN : null;
        },
        set(val) {
          if (val === equalityOperators.BETWEEN) {
            this.handleInput({
              lifetimeOrderTotalRange: {
                aboveAmount: null,
                belowAmount: null
              },
              lifetimeOrderTotalEquality: undefined
            });
          }
          else {
            this.handleInput({
              lifetimeOrderTotalRange: undefined,
              lifetimeOrderTotalEquality: {
                ...this.value.lifetimeOrderTotalEquality,
                operator: val
              }
            });
          }
        }
      },

      lifetimeOrderTotalEqualityAmount: {
        get() {
          return this.value.lifetimeOrderTotalEquality?.amount;
        },
        set(val) {
          this.handleInput({
            lifetimeOrderTotalEquality: {
              ...this.value.lifetimeOrderTotalEquality,
              amount: val
            }
          });
        }
      },

      menuItemId: {
        get() {
          return this.value.favoriteItem?.menuItemId;
        },
        set(val) {
          if (!val) {
            this.handleInput({
              favoriteItem: criteriaCardMetadata.FAVORITE_ITEM.defaultData
            });
          }
          else {
            this.handleInput({
              favoriteItem: {
                menuItemId: val
              }
            });
          }
        }
      },

      spendPercentile() {
        return this.value.spendPercentile || {
          range: null,
          percentile: null,
          daysBackType: null
        };
      },

      spendPercentilePercent: {
        get() {
          return this.spendPercentile.percentile != null ? this.spendPercentile.percentile * 100 : null;
        },
        set(value) {
          this.updateSpendPercentile('percentile', value / 100);
        }
      },

      orderCounts() {
        const defaultOrderCounts = {
          beginTime: null,
          endTime: null,
          daysBackType: null,
          // NOTE: this could default to either, technically, but not both
          // range: {
          //   aboveAmount: null,
          //   belowAmount: null
          // },
          equality: {
            operator: null,
            amount: null
          }
        };
        return this.value.orderCounts
          ? this.value.orderCounts
          : defaultOrderCounts;
      },

      orderTotals() {
        const defaultOrderTotals = {
          beginDate: null,
          endDate: null,
          // NOTE: this could default to either, technically, but not both
          // range: {
          //   aboveAmount: null,
          //   belowAmount: null
          // },
          equality: {
            operator: null,
            amount: null
          }
        };
        return this.value.orderTotals
          ? this.value.orderTotals
          : defaultOrderTotals;
      },

      orderCountsOperator: {
        get() {
          if (this.orderCounts.equality) {
            return this.orderCounts.equality.operator;
          }
          return this.value.orderCounts ? equalityOperators.BETWEEN : null;
        },
        set(val) {
          if (val === equalityOperators.BETWEEN) {
            this.handleInput({
              orderCounts: {
                ...this.value.orderCounts,
                equality: undefined,
                range: {
                  aboveAmount: null,
                  belowAmount: null
                }
              }
            });
          }
          else {
            this.handleInput({
              orderCounts: {
                ...this.value.orderCounts,
                equality: {
                  amount: this.orderCounts?.equality?.amount || null,
                  operator: val
                },
                range: undefined
              }
            });
          }
        }
      },

      orderTotalsOperator: {
        get() {
          if (this.orderTotals.equality) {
            return this.orderTotals.equality.operator;
          }
          return this.value.orderTotals ? equalityOperators.BETWEEN : null;
        },
        set(val) {
          if (val === equalityOperators.BETWEEN) {
            this.handleInput({
              orderTotals: {
                ...this.value.orderTotals,
                equality: undefined,
                range: {
                  aboveAmount: null,
                  belowAmount: null
                }
              }
            });
          }
          else {
            this.handleInput({
              orderTotals: {
                ...this.value.orderTotals,
                equality: {
                  amount: this.orderTotals?.equality?.amount || null,
                  operator: val
                },
                range: undefined
              }
            });
          }
        }
      },

      orderAverages() {
        const defaultOrderAverages = {
          type: null,
          // NOTE: this could default to either, technically, but not both
          // range: {
          //   aboveAmount: null,
          //   belowAmount: null
          // },
          equality: {
            operator: null,
            amount: null
          }
        };
        return this.value.orderAverages
          ? this.value.orderAverages
          : defaultOrderAverages;
      },

      orderAveragesOperator: {
        get() {
          if (this.orderAverages.equality) {
            return this.orderAverages.equality.operator;
          }
          return this.value.orderAverages ? equalityOperators.BETWEEN : null;
        },
        set(val) {
          if (val === equalityOperators.BETWEEN) {
            this.handleInput({
              orderAverages: {
                ...this.value.orderAverages,
                equality: undefined,
                range: {
                  aboveAmount: null,
                  belowAmount: null
                }
              }
            });
          }
          else {
            this.handleInput({
              orderAverages: {
                ...this.value.orderAverages,
                equality: {
                  amount: this.orderAverages?.equality?.amount,
                  operator: val
                },
                range: undefined
              }
            });
          }
        }
      },

      orderItemCategory() {
        const defaultOrderItemCategory = {
          daysBackType: null,
          categoryId: null,
          // NOTE: this could default to either, technically, but not both
          // range: {
          //   aboveAmount: null,
          //   belowAmount: null
          // },
          equality: {
            operator: null,
            amount: null
          }
        };
        return this.value.orderItemCategory
          ? this.value.orderItemCategory
          : defaultOrderItemCategory;
      },

      orderItemCategoryOperator: {
        get() {
          if (this.orderItemCategory.equality) {
            return this.orderItemCategory.equality.operator;
          }
          return this.value.orderItemCategory ? equalityOperators.BETWEEN : null;
        },
        set(val) {
          if (val === equalityOperators.BETWEEN) {
            this.handleInput({
              orderItemCategory: {
                ...this.value.orderItemCategory,
                equality: undefined,
                range: {
                  aboveAmount: null,
                  belowAmount: null
                }
              }
            });
          }
          else {
            this.handleInput({
              orderItemCategory: {
                ...this.value.orderItemCategory,
                equality: {
                  amount: this.orderItemCategory?.equality?.amount,
                  operator: val
                },
                range: undefined
              }
            });
          }
        }
      },

      orderItemAttribute() {
        const defaultOrderItemAttribute = {
          daysBackType: null,
          menuAttributeId: null,
          // NOTE: this could default to either, technically, but not both
          // range: {
          //   aboveAmount: null,
          //   belowAmount: null
          // },
          equality: {
            operator: null,
            amount: null
          }
        };
        return this.value.orderItemAttribute
          ? this.value.orderItemAttribute
          : defaultOrderItemAttribute;
      },

      orderItemAttributeOperator: {
        get() {
          if (this.orderItemAttribute.equality) {
            return this.orderItemAttribute.equality.operator;
          }
          return this.value.orderItemAttribute ? equalityOperators.BETWEEN : null;
        },
        set(val) {
          if (val === equalityOperators.BETWEEN) {
            this.handleInput({
              orderItemAttribute: {
                ...this.value.orderItemAttribute,
                equality: undefined,
                range: {
                  aboveAmount: null,
                  belowAmount: null
                }
              }
            });
          }
          else {
            this.handleInput({
              orderItemAttribute: {
                ...this.value.orderItemAttribute,
                equality: {
                  amount: this.orderItemAttribute?.equality?.amount,
                  operator: val
                },
                range: undefined
              }
            });
          }
        }
      },

      orderTotalsBeginDate: {
        get() {
          if (this.value.orderTotals?.dateRange?.beginDate) {
            const dateString = moment.utc(this.value.orderTotals.dateRange?.beginDate).format('MM-DD-YYYY');
            return moment(dateString, 'MM-DD-YYYY').startOf('day').toDate();
          }
          else {
            return null;
          }
        },
        set(val) {
          this.handleInput({
            orderTotals: {
              ...this.value.orderTotals,
              dateRange: {
                ...this.value.orderTotals?.dateRange,
                beginDate: this.getISOStringDates({ begin: val }).begin
              }
            }
          });
        }
      },

      orderTotalsEndDate: {
        get() {
          if (this.value.orderTotals?.dateRange?.endDate) {
            const dateString = moment.utc(this.value.orderTotals.dateRange.endDate).format('MM-DD-YYYY');
            return moment(dateString, 'MM-DD-YYYY').endOf('day').toDate();
          }
          else {
            return null;
          }
        },
        set(val) {
          this.handleInput({
            orderTotals: {
              ...this.value.orderTotals,
              dateRange: {
                ...this.value.orderTotals?.dateRange,
                endDate: this.getISOStringDates({ end: val }).end
              }
            }
          });
        }
      },

      lapsedVisits() {
        return this.value.lapsedVisits || {
          missedNumberOfVisits: null,
          daysSinceLastLapsedNotification: null
        };
      },

      durationTypes() {
        return [
          { value: 'day', display: 'Day' },
          { value: 'week', display: 'Week' },
          { value: 'month', display: 'Month' },
          { value: 'year', display: 'Year' }
        ].map(type => ({
          ...type,
          display: `${type.display}${this.computedDuration > 1 ? 's' : ''}`
        }));
      },

      computedDuration() {
        switch (this.durationType) {
          case 'year':
            return this.value.daysSinceLastOrder / 365;
          case 'month':
            return this.value.daysSinceLastOrder / 30;
          case 'week':
            return this.value.daysSinceLastOrder / 7;
          default:
            return this.value.daysSinceLastOrder;
        }
      },

      orderCountsBeginTimeIsBeforeEndTime() {
        const { beginTime, endTime } = this.orderCounts;
        if (!beginTime && !endTime) return true;
        return !!beginTime && !!endTime && beginTime <= endTime;
      },

      orderCountsTimeErrorMessage() {
        const { beginTime, endTime } = this.orderCounts;
        let errorMessage = '';
        if (!beginTime) {
          errorMessage = 'Start Time is required';
        }
        else if (!endTime) {
          errorMessage = 'End Time is required';
        }
        else {
          errorMessage = 'Start Time must be before End Time';
        }
        return errorMessage;
      },

      giftCardPurchaseCount() {
        return this.value.giftCardPurchaseCount || this.criteriaCardMetadata.GIFT_CARD_PURCHASE_COUNT.defaultData;
      },

      giftCardPurchaseCountOperator: {
        get() {
          if (this.giftCardPurchaseCount.equality) {
            return this.giftCardPurchaseCount.equality.operator;
          }
          return this.value.giftCardPurchaseCount ? equalityOperators.BETWEEN : null;
        },
        set(val) {
          if (val === equalityOperators.BETWEEN) {
            this.handleInput({
              giftCardPurchaseCount: {
                ...this.value.giftCardPurchaseCount,
                equality: undefined,
                range: {
                  aboveAmount: null,
                  belowAmount: null
                }
              }
            });
          }
          else {
            this.handleInput({
              giftCardPurchaseCount: {
                ...this.value.giftCardPurchaseCount,
                equality: {
                  amount: this.giftCardPurchaseCount?.equality?.amount,
                  operator: val
                },
                range: undefined
              }
            });
          }
        }
      }
    },

    created() {
      if (this.value.daysSinceLastOrder) {
        this.durationType = this.detectDurationType(this.value.daysSinceLastOrder);
      }
    },

    methods: {
      capitalCase,
      moment,

      handleLapsedVisitsChange({ type, value }) {
        this.handleInput({
          lapsedVisits: {
            ...this.value.lapsedVisits,
            [type]: value
          }
        });
      },

      clearOrderCountsTime() {
        this.handleInput({
          orderCounts: {
            ...this.value.orderCounts,
            beginTime: null,
            endTime: null
          }
        });
      },

      handleEqualityChange({ val, type, criteriaKey }) {
        switch (type) {
          case 'operator':
            this.handleInput({
              [criteriaKey]: {
                ...this.value[criteriaKey],
                equality: {
                  ...this.value[criteriaKey]?.equality,
                  operator: val
                }
              }
            });
            break;
          case 'amount':
            this.handleInput({
              [criteriaKey]: {
                ...this.value[criteriaKey],
                equality: {
                  ...this.value[criteriaKey]?.equality,
                  amount: val
                }
              }
            });
            break;
          default:
            break;
        }
      },

      handleRangeChange({ val, type, criteriaKey }) {
        switch (type) {
          case 'aboveAmount':
            this.handleInput({
              [criteriaKey]: {
                ...this.value[criteriaKey],
                range: {
                  ...this.value[criteriaKey]?.range,
                  aboveAmount: val
                }
              }
            });
            break;
          case 'belowAmount':
            this.handleInput({
              [criteriaKey]: {
                ...this.value[criteriaKey],
                range: {
                  ...this.value[criteriaKey]?.range,
                  belowAmount: val
                }
              }
            });
            break;
          default:
            break;
        }
      },

      handleOrderCountsChange(type, val) {
        switch (type) {
          case 'beginTime':
            this.handleInput({
              orderCounts: {
                ...this.value.orderCounts,
                beginTime: moment(val).format('HH:mm')
              }
            });
            break;
          case 'endTime':
            this.handleInput({
              orderCounts: {
                ...this.value.orderCounts,
                endTime: moment(val).format('HH:mm')
              }
            });
            break;
          case 'daysBackType':
            this.handleInput({
              orderCounts: {
                ...this.value.orderCounts,
                daysBackType: val
              }
            });
            break;
          default:
            break;
        }
      },

      updateSpendPercentile(key, value) {
        const updatedSpendPercentile = {
          ...this.spendPercentile,
          [key]: value
        };
        this.handleInput({ spendPercentile: updatedSpendPercentile });
      },

      handleOrderItemCategoryChange(type, val) {
        switch (type) {
          case 'categoryId':
            this.handleInput({
              orderItemCategory: {
                ...this.value.orderItemCategory,
                categoryId: val
              }
            });
            break;
          case 'daysBackType':
            this.handleInput({
              orderItemCategory: {
                ...this.value.orderItemCategory,
                daysBackType: val
              }
            });
            break;
          default:
            break;
        }
      },

      handleOrderItemAttributeChange(type, val) {
        switch (type) {
          case 'menuAttributeId':
            this.handleInput({
              orderItemAttribute: {
                ...this.value.orderItemAttribute,
                menuAttributeId: val
              }
            });
            break;
          case 'daysBackType':
            this.handleInput({
              orderItemAttribute: {
                ...this.value.orderItemAttribute,
                daysBackType: val
              }
            });
            break;
          default:
            break;
        }
      },

      handleOrderAveragesTimeFrameChange(val) {
        this.handleInput({
          orderAverages: {
            ...this.value.orderAverages,
            type: val
          }
        });
      },

      detectDurationType(days) {
        switch (true) {
          case days % 365 === 0:
            return 'year';
          case days % 30 === 0:
            return 'month';
          case days % 7 === 0:
            return 'week';
          default:
            return 'day';
        }
      },

      handleDurationChange({ duration = this.computedDuration, durationType = this.durationType }) {
        let daysSinceLastOrder;

        switch (durationType) {
          case 'year':
            daysSinceLastOrder = duration * 365;
            break;
          case 'month':
            daysSinceLastOrder = duration * 30;
            break;
          case 'week':
            daysSinceLastOrder = duration * 7;
            break;
          default:
            daysSinceLastOrder = duration;
            break;
        }

        this.handleInput({ daysSinceLastOrder });
      },

      handleDurationTypeChange(durationType) {
        this.handleDurationChange({ durationType });
        this.durationType = durationType;
      },

      handleInput(payload) {
        this.$emit('handle-input', payload);
      },

      criteriaIsActiveFor(criteriaKeys) {
        return criteriaKeys.some(key => this.value[key] !== undefined && this.value[key] !== null); // `false` could be a valid value
      },

      getISOStringDates({ begin, end }) {
        const beginDateString = begin && moment.utc(begin).format('MM-DD-YYYY');
        const endDateString = end && moment.utc(end).format('MM-DD-YYYY');
        return {
          begin: begin && moment.utc(beginDateString, 'MM-DD-YYYY').startOf('day').toISOString(),
          end: end && moment.utc(endDateString, 'MM-DD-YYYY').endOf('day').toISOString()
        };
      }
    }
  };
</script>

<style lang='sass' scoped>
  .lifetime-order-range
    max-width: 300px

  .spend-percentile-percent
    width: 150px !important

  ::v-deep
    &.field
      &.orderCountsTimes
        align-items: center

    &.field
      &.is-floating-label
        .label
          display: flex
          justify-content: center
          overflow: unset !important
</style>
