/* eslint-disable import/no-cycle */
import { Model } from '@vuex-orm/core';
import http from '@/services/http';

export default class CommunicationTemplateType extends Model {
    static entity = 'communicationTemplateTypes'

    // FIELDS //////////////////////
    static fields() {
      return {
        id: this.attr(null),
        name: this.attr(null),
        description: this.attr(null),
        isConfigurable: this.attr(false),
        supportsEmail: this.attr(false),
        supportsSms: this.attr(false)
      };
    }

    // STATE //////////////////////
    static state() {
      return {
        fetching: false
      };
    }

    static $state() {
      return this.store().state.entities.communicationTemplateTypes;
    }

    static byId() {
      return this.all().reduce((acc, commTemplateType) => {
        acc[commTemplateType.id] = commTemplateType;
        return acc;
      }, {});
    }

    // ACTIONS //////////////////////
    static async fetchCommunicationTemplateTypes() {
      try {
        this.commit((state) => {
          state.fetching = true;
        });
        const { data: { communicationTemplateTypes } } = await http.get('communication_template_types');

        this.create({ data: communicationTemplateTypes });
      }
      catch (error) {
        throw error;
      }
      finally {
        this.commit((state) => {
          state.fetching = false;
        });
      }
    }
}
