const tabbable = {
  name: 'tabbable',
  bind(element) {
    const blurElement = () => element.blur();

    element.tabIndex = 0;
    element.classList.add('no-active-outline');
    element.addEventListener('click', blurElement);
  }
};



export default tabbable;
