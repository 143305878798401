<template>
  <div class="tip-option-group">
    <!-- Group Switch -->
    <div v-if="supportsMultipleTippingOptions" class="no-flex-shrink application-label">
      <div class="is-flex align-center">
        <p v-if="tippingOptionTypes.ALL_APPLICATONS === tippingOptionTypeId && !!name" class="is-size-4 has-text-weight-bold">{{ name }}</p>
        <b-switch
          v-else
          :disabled="disabled"
          :value="isGroupActive"
          @input="handleActiveChange"
        >
          <p class="is-size-4 has-text-weight-bold">{{ name }}</p>
        </b-switch>
      </div>
      <p v-if="!!description" class="has-text-grey is-size-7">{{ description }}</p>
    </div>

    <!-- Option(s) -->
    <div :class="['tip-options', { 'flex-grow': tippingMethodTypeId !== tippingOptionMethodTypes.BUTTON }]">
      <!-- Incrementation Tipping Style -->
      <div
        v-if="tippingMethodTypeId === tippingOptionMethodTypes.BUTTON"
        :class="[
          'tip-option field is-expanded is-marginless is-relative',
          { 'is-disabled': disabled || !isGroupActive }
        ]"
      >
        <validated-text-input
          v-if="isDollarTip"
          :value="defaultTippingOption.dollarAmount"
          class="control"
          style="margin: 0 !important"
          label="Default"
          name="default"
          type="dollars"
          label-position="on-border"
          :disabled="disabled"
          :rules="{ min_value: 0 }"
          :mask-options="{ required: true, numeralDecimalScale: 2, numeralPositiveOnly: true }"
          @input="handleInputChange({ type: 'dollarAmount', index: defaultTippingOptionIndex, value: $event })"
        />

        <validated-text-input
          v-else
          :value="defaultTippingOption.percentAmount"
          class="percent"
          style="margin: 0 !important"
          label="Default"
          name="default"
          type="number"
          label-position="on-border"
          :disabled="disabled || !isGroupActive"
          :rules="{ required: true, min_value: 0, max_value: 100 }"
          @input="handleInputChange({ type: 'percentAmount', index: defaultTippingOptionIndex, value: $event })"
        />
      </div>

      <!-- Options Tipping Style -->
      <template v-else>
        <div
          v-for="(option, index) in tippingOptions"
          :key="`${index}${tippingOptionTypeId}`"
          :class="[
            'tip-option field is-expanded is-marginless is-relative',
            { 'is-disabled': disabled || !isGroupActive }
          ]"
          data-test-class="tipping-option"
        >
          <validated-text-input
            v-if="isDollarTip"
            :value="option.dollarAmount"
            class="control"
            style="margin: 0 !important"
            :label="`Option ${index + 1}`"
            :name="`tipOption${index}${tippingOptionTypeId}`"
            type="dollars"
            label-position="on-border"
            :disabled="disabled || !isGroupActive"
            :rules="{
              required: true,
              min_value: 0,
              excluded: tippingOptions.reduce((arr, o) => o.id !== option.id ? [...arr, Number(o.dollarAmount)] : arr, [])
            }"
            :custom-messages="{
              required: index < 3 ? 'Value required' : 'Value requried or remove option',
              excluded: 'Value must be unique'
            }"
            :mask-options="{ numeralDecimalScale: 2, numeralPositiveOnly: true }"
            @input="handleInputChange({ type: 'dollarAmount', index, value: $event })"
          />
          <validated-text-input
            v-else
            :value="option.percentAmount"
            class="percent"
            style="margin: 0 !important"
            :label="`Option ${index + 1}`"
            :name="`tipOption${index}${tippingOptionTypeId}`"
            type="number"
            label-position="on-border"
            :disabled="disabled || !isGroupActive"
            :rules="{
              required: true,
              min_value: 0,
              max_value: 100,
              excluded: tippingOptions.reduce((arr, o) => o.id !== option.id ? [...arr, Number(o.percentAmount)] : arr, [])
            }"
            :custom-messages="{
              required: index < 3 ? 'Value required' : 'Value requried or remove option',
              excluded: 'Value must be unique'
            }"
            @input="handleInputChange({ type: 'percentAmount', index, value: $event })"
          />

          <!-- Remove Fourth Option -->
          <b-button
            v-if="index === 3"
            type="is-danger is-light"
            icon-left="trash"
            class="mar-none option-button trash-button"
            :disabled="disabled || !isGroupActive"
            data-test-id="remove-tipping-option-button"
            @click="removeOption(index)"
          />

          <!-- Set Default -->
          <b-button
            v-if="tippingOptions.length > 1"
            v-tippy="{ content: option.isDefault ? 'Unselect default' : 'Set as default', placement: 'left', delay: [333, 0], size: 'large' }"
            class="default-button option-button"
            :class="[ option.isDefault ? 'has-text-primary' : 'has-text-grey' ]"
            :icon-left="option.isDefault ? 'circle-check' : 'circle'"
            :icon-pack="option.isDefault ? 'fas' : 'far'"
            :name="`${option.tippingOptionTypeId}-default-tip-option`"
            :value="option.isDefault && index"
            :disabled="disabled || !isGroupActive"
            @click="handleDefaultOptionChange(index)"
          />
        </div>

        <!-- Add Fourth Option -->
        <b-button
          v-if="tippingOptions.length < 4"
          icon-left="plus"
          rounded
          type="is-primary is-light"
          outlined
          :disabled="disabled || !isGroupActive"
          data-test-id="add-tipping-option-button"
          @click="addOption"
        >
          Tip Option
        </b-button>
      </template>
    </div>
  </div>
</template>

<script>
  import { tippingOptionMethodTypes, tippingOptionTypes } from '@/constants/merchantTippingOptions';

  export default {
    name: 'TippingOptionGroup',

    props: {
      value: {
        type: Array,
        required: true
      },
      disabled: {
        type: Boolean,
        default: false
      },
      tippingMethodTypeId: {
        type: Number,
        required: true
      },
      supportsMultipleTippingOptions: {
        type: Boolean,
        default: false
      },
      name: {
        type: String,
        default: ''
      },
      description: {
        type: String,
        default: ''
      },
      tippingOptionTypeId: {
        type: Number,
        required: true
      }
    },

    data() {
      return {
        tippingOptions: [],
        tippingOptionMethodTypes,
        tippingOptionTypes
      };
    },

    computed: {
      isDollarTip() {
        return tippingOptionTypes.TIP_ONLY === this.tippingOptionTypeId || (this.tippingOptions.some(tippingOption => tippingOption.dollarAmount)
          && !this.tippingOptions.every(tippingOption => tippingOption.percentAmount));
      },

      isGroupActive() {
        return this.tippingOptions.every(tippingOption => tippingOption.isActive);
      },

      defaultTippingOption() {
        // NOTE: With Mobile Tipping, there is no default, so take the first
        return this.tippingOptions.find(o => o.isDefault) || this.tippingOptions[0];
      },

      defaultTippingOptionIndex() {
        // NOTE: With Mobile Tipping, there is no default, so take the first
        if (tippingOptionTypes.TIP_ONLY === this.tippingOptionTypeId) {
          return 0;
        }
        return this.tippingOptions.findIndex(o => o.isDefault) || 0;
      },

      tippingTypeId() {
        // NOTE: The tippingTypeId is the same for all options in a group, so grab it off the first option
        return this.tippingOptions[0].tippingTypeId;
      }
    },

    watch: {
      value: 'updateForm'
    },

    created() {
      this.onCreated();
    },

    methods: {
      onCreated() {
        this.updateForm(this.value);
      },

      updateForm(newVal) {
        this.tippingOptions = this.$clone(newVal);
      },

      handleInputChange({ index, value, type }) {
        this.tippingOptions[index][type] = value;

        this.$emit('input', this.tippingOptions);
      },

      handleDefaultOptionChange(newDefaultIndex) {
        const updatedTippingOptions = this.tippingOptions
          .map((option, index) => {
            option.isDefault = index === newDefaultIndex && !option.isDefault;
            return option;
          });
        this.$emit('input', updatedTippingOptions);
      },

      handleActiveChange(isActive) {
        const updatedTippingOptions = this.tippingOptions
          .map((option) => {
            option.isActive = isActive;
            return option;
          });

        this.$emit('input', updatedTippingOptions);
      },

      addOption() {
        this.tippingOptions.push({
          isActive: true,
          isDefault: false,
          percentAmount: null,
          dollarAmount: null,
          tippingOptionTypeId: this.tippingOptionTypeId,
          tippingTypeId: this.tippingTypeId
        });
        this.$emit('input', this.tippingOptions);
      },

      removeOption(indexToRemove) {
        this.tippingOptions = this.tippingOptions.filter((option, index) => indexToRemove !== index);
        this.$emit('input', this.tippingOptions);
      }
    }
  };
</script>

<style lang="sass" scoped>
  .tip-option-group
    display: flex

    &:not(:first-child)
      padding-top: $size-large

    &:not(:last-child)
      padding-bottom: $size-large
      border-bottom: 1px solid $grey-lightest

  .tip-options
    display: grid
    gap: $size-large $size-extra-large
    grid-template-columns: repeat(auto-fit, minmax(175px, 1fr))
    align-items: start

  .tip-option
    transition: opacity 333ms
    &.is-disabled
      opacity: 0.5

  .option-button
    position: absolute
    width: 45px
    border-top-left-radius: 0
    border-bottom-left-radius: 0
    top: 1px
    right: 1px
    height: 38px
    border-radius: 0

  .trash-button
    right: 46px
    border-left: 1px solid $grey-lighter

  .default-button
    background: $white-bis
    // the is-primary type on b-button has a border that needs to overriden this way
    border: none !important
    border-left: 1px solid $grey-lighter !important
    border-top-right-radius: $radius
    border-bottom-right-radius: $radius

  .application-label
    width: 250px
</style>
