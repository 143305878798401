/* eslint-disable import/no-cycle */
import { Model } from '@vuex-orm/core';
import http from '@/services/http';
import filterObjectKeys from '@/helpers/filter-object-keys';

export default class RegionMenuCategory extends Model {
  static entity = 'regionMenuCategories'

  // FIELDS //////////////////////

  static fields() {
    return {
      id: this.attr(null),
      menuRegionId: this.attr(null),
      menuCategoryId: this.attr(null),
      menuTypeId: this.attr(null),
      isExcluded: this.attr(true),
      createdDate: this.attr(null),
      modifiedDate: this.attr(null)
    };
  }



  // ACTIONS //////////////////////

  static async bulkAddRegionMenuCategories(regionMenuId, regionMenuCategories) {
    try {
      const acceptedKeys = ['menuRegionId', 'menuCategoryId', 'menuTypeId', 'isExcluded'];
      const { data } = await http.post(`region_menus/${regionMenuId}/region_menu_categories/bulk_create`, {
        regionMenuCategories: regionMenuCategories.map(regionMenuCategory => filterObjectKeys(regionMenuCategory, acceptedKeys))
      });

      this.insert({ data: data.regionMenuCategories });
    }

    catch (error) {
      throw error;
    }
  }

  static async bulkDeleteRegionMenuCategories(regionMenuCategoryIds) {
    try {
      await http.delete(`region_menu_categories/bulk_destroy?region_menu_category_ids=[${regionMenuCategoryIds}]`);
      regionMenuCategoryIds.forEach((id) => {
        this.delete(id);
      });
    }

    catch (error) {
      throw error;
    }
  }
}
