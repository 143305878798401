<template>
  <div
    :class="
      `box is-flex justify-between pad-y-xs pad-x has-radius-large ${backgroundColor}`
    "
  >
    <div class="is-flex dist-x-xl align-center">
      <p class="has-text-weight-bold">{{ display }}</p>
      <span class="justify-self-center">|</span>
      <b-button
        class="has-text-weight-bold is-transparent"
        :icon-right="loading ? 'spinner-third' : ''"
        :class="loading ? 'spin' : ''"
        @click="$emit('view-ticket')"
      >
        <span v-if="!loading">View Ticket</span>
      </b-button>
    </div>
    <b-button
      class="has-text-weight-bold is-transparent"
      @click="$emit('dismiss-notification')"
    >
      X
    </b-button>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex';

  export default {
    name: 'ExpoNotificationCard',

    props: {
      notification: {
        type: Object,
        required: true
      },

      loading: {
        type: Boolean,
        default: false
      }
    },

    computed: {
      ...mapGetters('expoNotifications', ['notificationTypes', 'expoNotificationDisplayMappings']),

      backgroundColor() {
        if (this.notification.deliveryEvent) {
          return `has-background-${this.notificationTypes.deliveryEvent.color}-light`;
        }
        return `has-background-${this.notificationTypes.newExpoStatus.color}-light`;
      },

      display() {
        return this.expoNotificationDisplayMappings[this.notification.newExpoStatus || this.notification.deliveryEvent];
      }
    }
  };
</script>
