<template>
  <fieldset>
    <div class="is-grid gap-x-lg gap-y-sm col-2 col-1-tablet mar-b-lg">
      <validated-text-input
        :disabled="!canEdit('name') || editableMerchant.isTemplate"
        label="Merchant Name"
        name="name"
        type="text"
        rules="required"
        placeholder="Please enter a merchant name..."
        tooltip="Merchant name for internal use"
        :value="editableMerchant.name"
        @input="handleInput('name', $event)"
        @blur="onNameInputBlur"
      />

      <validated-text-input
        :disabled="!canEdit('displayName')"
        label="Merchant Display Name"
        name="displayName"
        type="text"
        rules="required"
        placeholder="Please enter a merchant display name..."
        tooltip="This is the name displayed to your guests"
        :value="editableMerchant.displayName"
        @input="handleInput('displayName', $event)"
        @blur="setUriName"
      />

      <validated-text-input
        :disabled="!canEdit('baseUrl')"
        label="Base URL"
        name="baseUrl"
        type="httpsUrl"
        rules="required"
        :value="editableMerchant.baseUrl || getFormattedBaseUrlDefault"
        @input="handleInput('baseUrl', $event)"
        @blur="appendBaseUrlSlash"
      />

      <validated-text-input
        v-if="$can('manage', 'all') && mode === 'update'"
        disabled
        label="Merchant ID"
        name="merchantId"
        type="text"
        :value="editableMerchant.id"
      />

      <div>
        <validated-text-input
          :disabled="!canEdit('friendlyUriName')"
          label="Friendly URL Name"
          name="friendlyUriName"
          type="text"
          rules="required|uriFriendly"
          placeholder="Please enter a name to use in your URL..."
          :value="editableMerchant.friendlyUriName"
          @input="handleInput('friendlyUriName', $event)"
        />

        <b-message size="is-small is-compact mar-none">
          <p class="has-text-weight-bold">Example:</p>
          <p>{{ baseUrl }}{{ editableMerchant.friendlyUriName || 'example-merchant-name' }}</p>
        </b-message>
      </div>

      <validated-input
        v-if="mode === 'create'"
        name="merchantTypes"
        label="Merchant Type"
        :rules="{ required: true }"
      >
        <b-dropdown
          :value="editableMerchant.merchantTypeId"
          class="has-extra-shadow"
          expanded
          @input="handleInput('merchantTypeId', $event)"
        >
          <dropdown-button
            slot="trigger"
            placeholder="Select a Merchant Type..."
            :value="getMerchantTypeName(editableMerchant.merchantTypeId)"
            class="wrap-text auto-height"
            :loading="isFetchingMerchantTypes"
          />
          <b-dropdown-item
            v-for="{ id, name } in filteredMerchantTypes"
            :key="`merchantType-${id}`"
            :value="id"
          >
            {{ name }}
          </b-dropdown-item>
        </b-dropdown>
      </validated-input>
    </div>

    <image-upload
      v-if="mode === 'update'"
      v-slot="{imagePath}"
      :accepted-types="['png', 'jpeg', 'jpg', 'svg']"
      :min-width="64"
      :min-height="64"
      :image-url="editableMerchant.merchantOption.avatarUrl"
      :disabled="!$can('update', 'Merchant')"
      label="Merchant Avatar"
      restrict-file-size
      :show-delete-button="true"
      show-clear-button
      upload-button-text="Select Avatar Image"
      :loading="isLoadingAvatar"
      :value="editableMerchant.avatarImageFile"
      @input="handleInput('avatarImageFile', $event)"
      @delete-image="deleteAvatar"
    >
      <img v-if="imagePath" style="max-width: 250px" :src="imagePath" alt="Thumbnail Image">
    </image-upload>
  </fieldset>
</template>



<script>
  import Merchant from '@/store/classes/Merchant';
  import MerchantOption from '@/store/classes/MerchantOption';
  import MerchantType from '@/store/classes/MerchantType';


  export default {
    name: 'MerchantNameInputs',

    props: {
      mode: {
        type: String,
        default: 'create'
      }
    },

    computed: {
      editableMerchant() {
        return Merchant.$state().editableMerchant;
      },

      baseUrl() {
        return this.editableMerchant.baseUrl;
      },

      isLoadingAvatar() {
        return MerchantOption.$state().submitting;
      },

      merchantTypes() {
        return MerchantType.all();
      },

      filteredMerchantTypes() {
        // NOTE: The reason for filtering out Sundry is due to the fact that the BE flow for creating a Sundry Merchant in this flow is slightly
        // separate from the actual Sundry sign up flow
        return this.merchantTypes.filter(merchantType => merchantType.id !== MerchantType.typeIds.SUNDRY);
      },

      isFetchingMerchantTypes() {
        return MerchantType.$state().fetching;
      },

      getFormattedBaseUrlDefault() {
        switch (window.location.hostname) {
          case 'localhost':
          case 'dev-my.cardfree.net':
            return 'https://dev-order.cardfree.net/';
          case 'uat-my.cardfree.net':
            return 'https://uat-order.cardfree.net/';
          case 'demo-my.cardfree.net':
            return 'https://demo-order.cardfree.net/';
          case 'stage-my.cardfree.com':
            return 'https://stage-order.cardfree.com/';
          case 'load-my.cardfree.com':
            return 'https://load-order.cardfree.com/';
          case 'my.cardfree.com':
            return 'https://order.cardfree.com/';
          default:
            return '';
        }
      }
    },

    created() {
      this.onCreated();
    },

    methods: {
      onCreated() {
        if (this.mode === 'create') {
          this.fetchMerchantTypes();
        }
      },

      handleInput(field, value) {
        Merchant.setEditableMerchant({ [field]: value });
      },

      canEdit(field) {
        return this.$can(this.mode, 'Merchant', field);
      },

      formatUriName(name) {
        const formattedName = name
          .toLowerCase()
          .replace(/ /g, '-') // replaces spaces with hyphens
          .replace(/&/g, 'and') // replaces '&' with 'and'
          .replace(/[^a-z0-9-]+/g, ''); // removes anything other than a-z, 0-9, and hyphen
        return formattedName;
      },

      onNameInputBlur(nameValue) {
        this.setDisplayName(nameValue);
        this.setUriName(nameValue);
      },

      setDisplayName(nameValue) {
        if (!this.editableMerchant.displayName) {
          this.handleInput('displayName', nameValue);
        }
      },

      setUriName(nameValue) {
        if (!this.editableMerchant.friendlyUriName) {
          this.handleInput('friendlyUriName', this.formatUriName(nameValue));
        }
      },

      appendBaseUrlSlash(baseUrlValue) {
        if (!baseUrlValue.endsWith('/')) {
          this.handleInput('baseUrl', `${baseUrlValue}/`);
        }
      },

      async deleteAvatar() {
        try {
          const updatedMerchantOption = await MerchantOption.deleteMerchantAvatar(this.editableMerchant.merchantOption.id);
          this.handleInput('avatarImageFile', null);
          this.handleInput('merchantOption', updatedMerchantOption);

          this.$_onRequestSuccess({
            toastOptions: {
              message: 'Successfully deleted avatar image!'
            }
          });
        }

        catch (error) {
          this.$_onRequestError({
            toastOptions: {
              message: 'An error occured while deleting your avatar image'
            },
            error
          });
        }
      },

      async fetchMerchantTypes() {
        try {
          await MerchantType.fetchMerchantTypes();
        }
        catch (error) {
          this.$_onRequestError({ toastOptions: { message: 'Unable to fetch merchant types' }, error });
        }
      },

      getMerchantTypeName(merchantTypeId) {
        return this.merchantTypes.find(merchantType => merchantType.id === merchantTypeId)?.name;
      }
    }
  };
</script>
