<template>
  <div>
    <div class="is-grid col-2 pad-sm has-border-bottom has-border-grey-lighter">
      <p class="title is-6 mar-none">Required Item Purchase #{{ count }}:</p>
      <p class="subtitle is-6 mar-none">
        {{ appliesToDisplay }}
      </p>
    </div>
    <div v-if="constraint.posType" class="is-grid col-2 pad-sm">
      <p class="title is-6 mar-none">POS Type:</p>
      <p class="subtitle is-6 mar-none">{{ constraint.posType }}</p>
    </div>
  </div>
</template>

<script>
  import http from '@/services/http';
  import { itemAttributes, itemGroupTypes } from '@/constants/items';

  import merchantMixin from '@/mixins/merchant';

  export default {
    name: 'ItemOfferConstraintRow',

    mixins: [merchantMixin],

    props: {
      constraint: {
        type: Object,
        required: true
      },
      count: {
        type: Number,
        required: true
      }
    },

    data() {
      return {
        availableModifiers: []
      };
    },

    computed: {
      appliesToDisplay() {
        const itemGroup = this.constraint.itemGroup;
        const minimumQualifyingCount = this.constraint.minimumQualifyingCount;

        switch (itemGroup.itemGroupType) {
          case itemGroupTypes.ALL_ITEMS:
            return `Any ${minimumQualifyingCount} ${minimumQualifyingCount > 1 ? 'items' : 'item'} ${this.excludedItemAttributeDisplay}`.trim();
          case itemGroupTypes.MENU_ITEMS:
            return `${minimumQualifyingCount} ${(itemGroup.menuItems.length > 1 || this.constraint.itemGroup.requiredModifications?.[0]?.qualifyingModifierMenuItemIds?.length > 1) ? 'of the following:' : ''} ${itemGroup.menuItems.map(mi => mi.displayName).join(', ')} ${this.modifierRestrictionDisplay}`.trim();
          case itemGroupTypes.CATEGORIES:
            return `Any ${minimumQualifyingCount} ${itemGroup.categoryObjects.map(co => co.displayName).join(', ')} ${(`${this.modifierRestrictionDisplay} ${this.excludedItemAttributeDisplay}`).trim()}`.trim();
          default:
            return 'No Items Selected for Offer';
        }
      },

      modifierRestrictionDisplay() {
        const selectedModifierIds = this.constraint?.itemGroup?.requiredModifications?.[0]?.qualifyingModifierMenuItemIds || [];
        const modifiers = selectedModifierIds.map(id => this.availableModifiers.find(mod => mod.id === id));
        const modifierNames = modifiers.filter(mod => mod).map(mod => mod.displayName).join(', ');
        return modifierNames ? `with modifier(s): ${modifierNames}`.trim() : '';
      },

      excludedItemAttributeDisplay() {
        const excludedItemAttributes = this.constraint.itemGroup.excludedItemAttributes;
        if (excludedItemAttributes.length) {
          const itemAttributeEntries = Object.values(itemAttributes).reduce((acc, value) => ({ ...acc, [value.id]: value.display }), {});
          const attributes = excludedItemAttributes.map(attribute => itemAttributeEntries[attribute]).join(', ');
          return `(excludes ${attributes})`;
        }
        else {
          return '';
        }
      }
    },

    watch: {
      'constraint.itemGroup.requiredModifications': {
        immediate: true,
        deep: true,
        handler: 'fetchModifiers'
      }
    },

    methods: {
      async fetchModifiers() {
        const itemType = this.constraint.itemGroup.itemGroupType === itemGroupTypes.CATEGORIES ? 'category' : 'item';
        const resources = this.constraint.itemGroup.itemGroupType === itemGroupTypes.CATEGORIES
          ? this.constraint.itemGroup.categoryObjects
          : this.constraint.itemGroup.menuItems;
        if (this.constraint.itemGroup.requiredModifications?.length && resources.length) {
          try {
            const searchableIds = resources.map(resource => `menu_${itemType}_ids[]=${resource.id}`).join('&');
            const { data } = await http.get(
              `merchants/${this.$_selectedMerchantId}/menu_item_modifier_groups?${searchableIds}`
            );
            this.availableModifiers = data.menuItemModifierGroups.reduce((acc, itemModifierGroup) => (
              [
                ...acc, ...itemModifierGroup.menuItemModifiers.map(modifier => (
                  {
                    id: modifier.menuItemId,
                    displayName: modifier.displayName
                  }
                ))
              ]
            ), []);
          }
          catch (error) {
            this.$_onRequestError({
              toastOptions: {
                message: 'There was an error fetching the modifiers'
              },
              error
            });
          }
        }
      }
    }
  };
</script>
