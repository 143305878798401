<template>
  <b-collapse :open="startOpen">
    <template v-slot:trigger="{ open }">
      <div class="show-more-divider link is-flex align-center">
        <p :class="`has-background-${headerBackgroundColor}`">
          <span>{{ title }}</span>
          <b-icon :icon="open ? 'angle-down' : 'angle-right'" />
        </p>
      </div>
    </template>
    <div class="advanced-options-container has-border-lg has-radius has-border-primary-light pad-lg">
      <slot />
    </div>
  </b-collapse>
</template>

<script>
  export default {
    name: 'CollapsibleSection',

    props: {
      title: {
        type: String,
        required: true
      },
      startOpen: {
        type: Boolean,
        default: false
      },
      headerBackgroundColor: {
        type: String,
        default: 'white'
      }
    }
  };
</script>

<style lang='sass' scoped>
  .advanced-options-container
    margin-top: -13px

  .show-more-divider
    position: relative
    p
      z-index: 1
      padding: 0 $size-extra-small 0 $size-small
      transform: translateX(1.5rem)
    &::after
      content: ''
      position: absolute
      left: 50%
      transform: translateX(-50%)
      width: calc( 100% - 1rem )
      border-bottom: 2px solid $primary-light
</style>
