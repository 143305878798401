<template>
  <div>
    <div v-if="search || filters" class="search-section">
      <div class="search-bar">
        <!-- Search bar with criteria options (if provided) -->
        <b-field
          v-if="searchCriteriaOptions && searchCriteriaOptions.length"
          class="search-inputs"
          label-position="on-border"
          label="Criteria"
        >
          <div class="control">
            <b-select v-model="selectedSearchCriteria">
              <option
                v-for="option in searchCriteriaOptions"
                :key="option.value"
                :value="option.value"
              >
                {{ option.label }}
              </option>
            </b-select>
          </div>
          <div class="control is-expanded mar-r">
            <b-input
              v-if="search"
              ref="searchInput"
              v-model="query"
              :icon-right-clickable="!!query"
              :icon-right="query ? 'times-circle': ''"
              :placeholder="search.placeholder || 'Search...'"
              class="search-input"
              icon="search"
              type="text"
              @icon-right-click="query = ''"
              @keydown.native.esc="query = ''"
              @keypress.native.enter="$event.target.blur()"
            />
          </div>
        </b-field>

        <!-- Original search bar without criteria options -->

        <b-input
          v-else-if="search"
          ref="searchInput"
          v-model="query"
          :icon-right-clickable="!!query"
          :icon-right="query ? 'times-circle': ''"
          :placeholder="search.placeholder || 'Search...'"
          class="search-input"
          icon="search"
          type="text"
          @icon-right-click="query = ''"
          @keydown.native.esc="query = ''"
          @keypress.native.enter="$event.target.blur()"
        />

        <!-- Filters -->
        <template v-if="filters">
          <b-field
            v-for="filter in filters"
            :key="filter.key"
            label-position="on-border"
            :label="showFilterLabel(filter.key) ? filter.placeholder : ''"
            class="mar-none"
          >
            <b-dropdown
              v-if="filter.multiple"
              ref="dropdown"
              v-model="selectedValues[filter.key]"
              scrollable
              multiple
              max-height="300px"
              aria-role="list"
              class="has-extra-shadow search-dropdown"
              :position="'is-bottom-right'"
            >
              <dropdown-button
                slot="trigger"
                :placeholder="filter.placeholder"
                :value="filter.formatSelectedValues
                  ? filter.formatSelectedValues(selectedValues[filter.key])
                  : selectedValues[filter.key] && selectedValues[filter.key].join(', ') | capitalize"
              />
              <b-dropdown-item
                :class="(!selectedValues[filter.key] || !selectedValues[filter.key].length) && 'is-active'"
                @click="selectedValues[filter.key] = []"
              >
                -
              </b-dropdown-item>
              <b-dropdown-item
                v-for="option in filter.options"
                :key="option.value"
                :value="option.value"
              >
                {{ option.name }}
              </b-dropdown-item>
            </b-dropdown>

            <b-select
              v-else
              v-model="selectedValues[filter.key]"
              :placeholder="filter.placeholder"
              class="select-input"
              expanded
            >
              <option :value="null">–</option>
              <option v-for="option in filter.options" :key="option.value" :value="option.value">
                {{ option.name }}
              </option>
            </b-select>
          </b-field>
        </template>

        <!-- Clear button -->
        <b-button
          ref="clearButton"
          :disabled="isEmpty"
          icon-left="times"
          outlined
          class="clear-button"
          @click="clearInputs"
        >
          Clear
        </b-button>

        <!-- Optional actions slot -->
        <slot name="optional-actions" />
      </div>
    </div>

    <div class="table-section">
      <slot :filteredData="filteredData" />
    </div>
  </div>
</template>

<script>
  import { flatten, unflatten } from 'flat';



  export default {
    name: 'SearchableTable',

    props: {
      tableData: {
        type: Array,
        required: true,
        validator(array) {
          // all items in the array must be objects
          // https://gomakethings.com/how-to-check-if-something-is-an-object-with-vanilla-javascript/
          return array.every(item => Object.prototype.toString.call(item) === '[object Object]');
        }
      },

      search: {
        // {
        //   placeholder: 'Search by Name or Email',
        //   keys: ['fullName', 'email']
        // }
        type: Object,
        default: null,
        validator(obj) {
          return Array.isArray(obj.keys) && obj.keys.every(val => typeof val === 'string');
        }
      },

      filters: {
        // [
        //   {
        //     placeholder: 'Filter by Role',
        //     key: 'role',
        //     default: 'admin',
        //     multiple: false,
        //     options: [
        //       { name: 'Admin', value: 'admin' },
        //       { name: 'Operations', value: 'operations' }
        //     ]
        //   }
        // ]
        type: Array,
        default: null,
        validator(array) {
          return array.every(obj => [
            typeof obj.key === 'string',
            Array.isArray(obj.options) && obj.options.every((item) => {
              const allObjects = Object.prototype.toString.call(item) === '[object Object]';
              const hasCorrectProperties = Object.prototype.hasOwnProperty.call(item, 'name') && Object.prototype.hasOwnProperty.call(item, 'value');
              return allObjects && hasCorrectProperties;
            }),
            obj.formatSelectedValues === undefined || typeof obj.formatSelectedValues === 'function'
          ].every(x => x));
        }
      },

      searchCriteriaOptions: {
        // [
        //   { value: 'name', label: 'Promo Code Group' },
        //   { value: 'promotionCodes', label: 'Promo Code' }
        // ];
        type: Array,
        default: null,
        validator(array) {
          return array.every(obj => [
            typeof obj.value === 'string',
            typeof obj.label === 'string'
          ].every(x => x));
        }
      },

      autoFocusSearch: {
        type: Boolean,
        default: false
      },

      clearInputsAdditionalAction: {
        type: Function,
        default: null
      }
    },

    data() {
      return {
        selectedSearchCriteria: this.searchCriteriaOptions?.length ? this.searchCriteriaOptions[0].value : null,
        query: null,
        selectedValues: {}
      };
    },

    computed: {
      isEmpty: {
        get() {
          const hasSelectedValue = Object.values(this.selectedValues).some(val => Boolean(val));
          return !hasSelectedValue && !this.query;
        }
      },

      filteredData: {
        get() {
          let filtered = this.tableData ? this.tableData.map(item => flatten(item)) : [];

          if (this.selectedSearchCriteria && this.query) {
            filtered = filtered.filter((item) => {
              const value = item[this.selectedSearchCriteria];
              return value && String(value).toLowerCase().includes(this.query.toLowerCase());
            });
          }
          else if (this.search && this.search.keys && this.query) {
            filtered = filtered.filter(
              item => Object.entries(item)
                .filter(entry => this.search.keys.includes(entry[0]))
                .some(([_, val]) => String(val).toLowerCase().includes(this.query.toLowerCase())) // eslint-disable-line no-unused-vars
            );
          }

          if (this.filters) {
            this.filters.forEach((filter) => {
              filtered = filtered.filter((item) => {
                if (filter.multiple) {
                  return !this.selectedValues[filter.key]?.length || this.selectedValues[filter.key].includes(item[filter.key]);
                }
                return !this.selectedValues[filter.key] || this.selectedValues[filter.key] === item[filter.key];
              });
            });
          }

          return filtered.map(item => unflatten(item));
        }
      }
    },

    created() {
      this.onCreated();
    },

    mounted() {
      this.onMounted();
    },

    methods: {
      onCreated() {
        if (this.filters) {
          this.selectedValues = this.filters.reduce((obj, filter) => {
            if (filter.default) obj[filter.key] = filter.default;
            return obj;
          }, {});
        }
      },

      onMounted() {
        if (this.autoFocusSearch && !this.$screen.touch) {
          this.$refs.searchInput.focus();
        }
      },

      showFilterLabel(key) {
        return !!(Array.isArray(this.selectedValues[key]) ? this.selectedValues[key].length : this.selectedValues[key]);
      },

      clearInputs() {
        this.query = null;
        this.selectedValues = {};

        if (this.clearInputsAdditionalAction) {
          this.clearInputsAdditionalAction();
        }

        this.$refs.clearButton.$el.blur();
      }
    }
  };
</script>

<style lang="sass" scoped>
  .search-section
    padding: 0.75rem 1rem
    border-bottom: 1px solid $grey-lightest

  .search-bar
    display: flex
    flex-wrap: wrap
    gap: 0.75rem

  .search-input
    flex-grow: 1
    min-width: 240px


  @media (max-width: $tablet)
    .search-input
      flex-basis: 350px

    .select-input,
    .clear-button
      flex-grow: 1
</style>
