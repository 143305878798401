<template>
  <div ref="layout" :class="['layout', {'pad-none': $route.meta.disableSignInModal }]">
    <b-modal
      v-if="!$route.meta.disableSignInModal"
      :active="isSignInModalOpen"
      has-modal-card
      :can-cancel="false"
      class="sign-in-modal"
    >
      <div class="modal-card">
        <section class="modal-card-body pad-b-md">
          <sign-in-form show-sign-in-warning />
        </section>
      </div>
    </b-modal>

    <!-- <div class="is-flex-column">
      <button class="import-modal-test-buttons" @click="openImportFailureModal">
        <span>fail</span>
      </button>
      <button class="import-modal-test-buttons" @click="openImportSuccessModal">
        <span>success</span>
      </button>
      <button class="import-modal-test-buttons" @click="openImportInProgressModal">
        <span>progress</span>
      </button>
    </div> -->

    <b-loading :active="Boolean((!$route.meta.disableSignInModal && isSignedIn === undefined) || isFetching)" />

    <template v-if="!$route.meta.disableSignInModal">
      <order-expo-navigation-bar
        v-if="orderExpoToolsActive"
        @toggle-layout-element="toggleLayoutElement"
      />
      <navigation-bar
        v-else
        :sidebar-visible="sidebarVisible"
        @toggle-layout-element="toggleLayoutElement"
      />
    </template>

    <div
      v-if="orderExpoToolsActive"
      class="is-full-width"
    >
      <div class="view">
        <router-view :order-expo-tools-active="orderExpoToolsActive" />
      </div>
    </div>
    <template v-else>
      <sidebar-nav
        v-if="!$route.meta.disableSignInModal"
        :sidebar-visible="sidebarVisible"
        @close-sidebar="sidebarVisible = false"
      />

      <transition name="fade">
        <div v-if="sidebarVisible" class="sidebar-backdrop is-hidden-widescreen" />
      </transition>

      <div ref="viewWrapper" class="view-wrapper">
        <div class="view">
          <transition name="route-fade">
            <router-view :order-expo-tools-active="orderExpoToolsActive" />
          </transition>
        </div>
        <the-footer v-if="!$route.meta.hideFooter" />
      </div>
    </template>
  </div>
</template>



<script>
  import { mapGetters, mapState } from 'vuex';
  import theFooter from './the-footer.vue';
  import events from '@/services/events';
  import alertModal from '@/components/globals/alert-modal.vue';
  import OrderExpoNavigationBar from '@/components/pages/order-expo/order-expo-navigation-bar.vue';
  import { subscribeToChannel } from '@/services/web-socket-service';
  import Merchant from '@/store/classes/Merchant';
  import merchantMixin from '@/mixins/merchant';
  import Store from '@/store/classes/Store';
  import MerchantType from '@/store/classes/MerchantType';
  import logger from '@/services/logger';

  export default {
    name: 'Layout',

    components: {
      theFooter,
      OrderExpoNavigationBar
    },

    mixins: [merchantMixin],

    metaInfo: {
      titleTemplate(titleChunk) {
        return titleChunk ? `${titleChunk} | Admin Portal` : 'Admin Portal';
      }
    },

    data() {
      return {
        isSignInModalOpen: false,
        sidebarVisible: false,
        posImportInProgressModal: null,
        posImportInProgressSnackbar: null,
        posImportSuccessModal: null,
        posImportErrorModal: null,
        isFullscreen: false,
        expoToolsEnabled: false
      };
    },

    computed: {
      ...mapGetters('session', ['isCardfreeAdmin']),
      ...mapState('session', ['isSignedIn']),

      isFetching() {
        return Merchant.$state().fetchingSelectedMerchant || Store.$state().fetchingAll || MerchantType.$state().fetching;
      },

      isTemplateMerchant() {
        return this.$_selectedMerchant?.isTemplate;
      },

      orderExpoToolsActive() {
        // Currently, these two settings manifest in the same thing and enable the expo tool bar for the order expo page.
        // The difference between them is that isFullscreen is wired to a method that leverages the Fullscreen API to
        // let a web app go into a true fullscreen mode. expoToolsEnabled on the other hand is leveraged when the app is being
        // used as a PWA to toggle the tools, as a PWA is _always_ in fullscreen mode.
        return this.isFullscreen || this.expoToolsEnabled;
      }
    },

    watch: {
      isSignedIn: {
        immediate: true,
        handler: 'handleSignInChange'
      },
      $route: 'scrollToTop',
      isTemplateMerchant: 'handleTemplateChange',
      isFullscreen: 'onToggleFullscreen'
    },

    created() {
      this.onCreated();
    },

    methods: {
      async onMerchantLoad() {
        if (this.$_selectedMerchant.isSundry) {
          await this.fetchStores();
        }
      },

      async onCreated() {
        events.$on('unauthorized', this.handleUnauthorized);
        events.$on('pos-menu-import', this.handlePosMenuImport);
        events.$once('client-version-mismatch', this.openAppVersionMismatchSnackbar);

        if (this.$can('read', 'MerchantType')) {
          await this.fetchMerchantTypes();
        }
      },

      toggleLayoutElement(element) {
        this[element] = !this[element];
      },

      onToggleFullscreen() {
        // This is called immediately after isFullscreen is set, so perform the action in line with the new value
        if (!this.isFullscreen) {
          this.exitFullscreen();
        }
        else {
          this.enterFullscreen(this.$refs.layout);
        }
      },

      enterFullscreen(element) {
        if (element.requestFullscreen) {
          element.requestFullscreen();
        }
        else if (element.mozRequestFullScreen) { // Firefox
          element.mozRequestFullScreen();
        }
        else if (element.webkitRequestFullscreen) { // Chrome, Safari and Opera
          element.webkitRequestFullscreen();
        }
        else if (element.msRequestFullscreen) { // IE/Edge
          element.msRequestFullscreen();
        }
      },

      exitFullscreen() {
        if (document.exitFullscreen) {
          document.exitFullscreen();
        }
        else if (document.mozCancelFullScreen) { /* Firefox */
          document.mozCancelFullScreen();
        }
        else if (document.webkitExitFullscreen) { /* Chrome, Safari and Opera */
          document.webkitExitFullscreen();
        }
        else if (document.msExitFullscreen) { /* IE/Edge */
          document.msExitFullscreen();
        }
      },

      createWebSocket() {
        subscribeToChannel(
          { channel: 'PosSyncChannel' },
          { received: this.messageReceived }
        );
      },

      messageReceived(data) {
        if (data.status === 200) {
          this.handlePosMenuImport('success');
        }
        else {
          this.handlePosMenuImport('failure');
        }
      },

      handleSignInChange(isSignedIn) {
        if (typeof isSignedIn === 'boolean') {
          this.isSignInModalOpen = !isSignedIn;
          if (isSignedIn) this.createWebSocket();
        }
      },

      async fetchStores() {
        try {
          await Store.fetchAll();
        }

        catch (error) {
          logger.error(error);
        }
      },

      handleTemplateChange(newVal) {
        if (newVal) {
          this.$buefy.modal.open({
            parent: this,
            component: alertModal,
            hasModalCard: true,
            trapFocus: true,
            canCancel: false,
            customClass: 'auto-width',
            props: {
              title: 'Open Template Merchant?',
              message: `<b>${this.$_selectedMerchant.displayName}</b> is a template merchant. Edits made to this merchant will apply to any new merchants created using this template.`,
              secondaryMessage: 'Are you sure you want to open this merchant?',
              type: 'is-danger',
              icon: 'exclamation-triangle',
              horizontal: true,
              showCloseButton: false,
              canClose: false,
              buttons: [
                {
                  text: 'Cancel',
                  onClick: () => {
                    this.$router.push({ name: 'merchantList' });
                  }
                },
                {
                  text: 'Yes, Open Merchant',
                  primary: true
                }
              ]
            }
          });
        }
      },

      handleUnauthorized() {
        this.$store.dispatch('session/unauthorizedAccess');
      },

      scrollToTop() {
        this.$refs.viewWrapper.scrollTop = 0;
      },

      navigateToPosMenu() {
        if (this.$route.name === 'posMenu') {
          events.$emit('refresh-pos-menu');
        }
        else {
          this.$router.push({ name: 'posMenu' });
        }
      },

      closeImportSuccessModal() {
        if (this.$route.name === 'posMenu') {
          events.$emit('refresh-pos-menu');
        }
      },

      openImportFailureEmail() {
        window.location.assign('mailto:support@cardfree.com?subject=POS Menu Import Failure');
      },

      openImportInProgressSnackbar() {
        this.posImportInProgressSnackbar = this.$buefy.snackbar.open({
          message: '<i class="far fa-sync mar-r-sm spin-smooth"></i>POS Menu Import In Progress...',
          type: 'is-primary',
          position: 'is-top-right',
          indefinite: true,
          actionText: null,
          queue: false
        });
      },

      openImportInProgressModal() {
        this.posImportInProgressModal = this.$buefy.modal.open({
          parent: this,
          component: alertModal,
          hasModalCard: true,
          trapFocus: true,
          canCancel: false,
          customClass: 'auto-width',
          props: {
            buttons: [{ text: 'Okay', primary: true }],
            imagePath: '/images/animation_500_kiaqa197.gif',
            title: 'Importing Menu',
            message: 'This may take a few minutes...',
            type: 'is-primary',
            showCloseButton: false,
            onClose: this.openImportInProgressSnackbar
          }
        });
      },

      openImportSuccessModal() {
        this.posImportSuccessModal = this.$buefy.modal.open({
          parent: this,
          component: alertModal,
          hasModalCard: true,
          trapFocus: true,
          canCancel: false,
          customClass: 'auto-width',
          props: {
            buttons: [
              { text: 'Okay', onClick: this.closeImportSuccessModal },
              { text: 'View Imported Menu', primary: true, onClick: this.navigateToPosMenu }
            ],
            showCloseButton: false,
            icon: 'check-circle',
            iconPack: 'far',
            title: 'Import Successful',
            message: "We've successfully imported the menu from your POS! Please review the results and continue creating your CardFree Menu.",
            type: 'is-success'
          }
        });
      },

      openImportFailureModal() {
        this.posImportErrorModal = this.$buefy.modal.open({
          parent: this,
          component: alertModal,
          hasModalCard: true,
          trapFocus: true,
          canCancel: false,
          customClass: 'auto-width',
          props: {
            buttons: [
              { text: 'Contact Support', secondary: true, onClick: this.openImportFailureEmail },
              { text: 'Try Again', primary: true, onClick: this.navigateToPosMenu }
            ],
            icon: 'exclamation-triangle',
            iconPack: 'far',
            title: 'Import Failed',
            message: "We're unable to import your menu. Please try again or contact support.",
            type: 'is-danger'
          }
        });
      },

      resetImportModals() {
        if (this.posImportInProgressModal) {
          this.posImportInProgressModal.close();
          this.$nextTick(() => {
            this.posImportInProgressModal = null;
          });
        }
        if (this.posImportInProgressSnackbar) {
          this.posImportInProgressSnackbar.close();
          this.$nextTick(() => {
            this.posImportInProgressSnackbar = null;
          });
        }
        if (this.posImportSuccessModal) {
          this.posImportSuccessModal.close();
          this.$nextTick(() => {
            this.posImportSuccessModal = null;
          });
        }
        if (this.posImportErrorModal) {
          this.posImportErrorModal.close();
          this.$nextTick(() => {
            this.posImportErrorModal = null;
          });
        }
      },

      handlePosMenuImport(status) {
        this.resetImportModals();

        switch (status) {
          case 'in-progress':
            this.openImportInProgressModal();
            break;

          case 'success':
            this.openImportSuccessModal();
            break;

          case 'failure':
            this.openImportFailureModal();
            break;

          default:
            break;
        }
      },

      openAppVersionMismatchSnackbar() {
        this.$buefy.snackbar.open({
          message: '<p>Our app has been updated</p><p>Please refresh your browser to get the latest changes</p>',
          indefinite: true,
          queue: false,
          position: 'is-top',
          type: 'is-primary',
          onAction: () => window.location.reload(true),
          actionText: 'Refresh'
        });
      },

      async fetchMerchantTypes() {
        try {
          await MerchantType.fetchMerchantTypes();
        }

        catch (error) {
          logger.error(error);
        }
      }
    }
  };
</script>



<style lang="sass" scoped>
  .layout
    top: 0
    left: 0
    right: 0
    bottom: 0
    display: flex
    position: absolute
    padding-top: $navbar-height


  .sign-in-modal
    z-index: 1000

  .modal-card
    max-width: 400px
    margin: 0 auto !important

  .view-wrapper
    display: flex
    flex-direction: column
    overflow-y: auto
    -webkit-overflow-scrolling: touch
    overscroll-behavior: auto contain
    flex-grow: 1

  .view
    display: flex
    flex-direction: column
    flex: 1 0 auto
    background: linear-gradient( to bottom, $white 200px, $white-ter 400px)

  .sidebar-backdrop
    position: absolute
    top: 0
    right: 0
    left: 0
    bottom: 0
    background-color: rgba(#000, 0.8)
    backdrop-filter: blur(8px)
    -webkit-backdrop-filter: blur(8px)
    z-index: 29

  .import-modal-test-buttons
    flex: 1
    span
      width: 3rem
      display: block
      transform: rotate(-90deg)
      font-size: $size-5
</style>
