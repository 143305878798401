<template>
  <div class="modal-card">
    <header class="modal-card-head is-flex align-start">
      <div class="modal-card-title">
        <p class="mar-b-xs">
          <span @click="showOrderId = !showOrderId">
            {{ modalTitle }}
          </span>
        </p>
        <span v-if="showOrderId || $can('manage', 'all')">
          <p class="mar-b-sm is-size-6 has-text-grey">
            Order ID: {{ orderId }}
          </p>
          <p v-if="isThirdPartyOrder && !isLoadingOrderDetails" class="mar-b-sm is-size-6 has-text-grey">
            {{ thirdPartyOrderProviderDisplayName }} ID: {{ order.partnerOrderIdentifier }}
          </p>
        </span>
        <div v-if="order.status" class="is-size-6 has-text-grey-dark">
          <span class="has-text-weight-bold mar-r-sm">{{ getOrderStatus(order.status) }}</span>
        </div>
      </div>
      <button class="button is-transparent times-button" @click="closeModal">
        <b-icon icon="times" size="is-small" />
      </button>
    </header>

    <div class="modal-card-body">
      <div v-if="isLoadingOrderDetails" class="has-text-centered pad-xl">
        <b-icon
          type="is-grey-light"
          pack="fal"
          icon="spinner-third"
          class="is-full-width is-full-height"
          custom-size="fa-5x"
          custom-class="spin"
        />
      </div>
      <template v-else>
        <b-collapse
          v-if="order.orderType !== orderTypes.PAY_AND_FULFILL"
          class="expandable-section"
          animation="slide"
        >
          <div
            slot="trigger"
            slot-scope="props"
            role="button"
            class="expandable-section--header"
          >
            <h4 class="subtitle is-5 is-marginless">
              Customer Info
            </h4>
            <b-icon class="open-indicator" :icon="props.open ? 'angle-down' : 'angle-right'" />
          </div>
          <dl class="details-container customer-info is-flex-wrap gap-y gap-x-lg">
            <div class="is-full-width">
              <dt class="has-text-grey">Name:</dt>
              <dd class="has-text-weight-semibold is-size-4 text-ellipsis">
                {{ order.guestFullName && order.guestFullName.trim() ? order.guestFullName : 'N/A' }}
              </dd>
            </div>
            <div>
              <dt class="has-text-grey">Email:</dt>
              <dd class="has-text-weight-semibold text-ellipsis">{{ order.email || 'N/A' }}</dd>
            </div>
            <div>
              <dt class="has-text-grey">Phone:</dt>
              <dd v-if="order.phoneNumber" class="has-text-weight-semibold">{{ order.phoneNumber | phoneNumber }}</dd>
              <dd v-else class="has-text-weight-semibold">N/A</dd>
            </div>
          </dl>
          <router-link
            v-if="order.accountId && $_featurePermissions.ACCOUNT_MANAGEMENT"
            class="is-inline-block has-hover-text-underline mar-t-xs"
            :to="{
              name: 'registeredGuestDetails',
              params: { userId: order.accountId }
            }"
          >
            View Guest in Account Management
            <i class="far fa-arrow-right-to-bracket mar-l-xs mar-r-sm" />
          </router-link>
        </b-collapse>

        <b-collapse
          v-if="order.fulfillmentType === fulfillmentTypes.SHIPPING && order.shipment"
          class="expandable-section"
          animation="slide"
        >
          <div
            slot="trigger"
            slot-scope="props"
            role="button"
            class="expandable-section--header"
          >
            <h4 class="subtitle is-5 is-marginless">
              Shipping Info
            </h4>
            <b-icon class="open-indicator" :icon="props.open ? 'angle-down' : 'angle-right'" />
          </div>
          <dl class="details-container dist-y-md">
            <div v-if="order.shipment.shippingMethod" class="sub-container-column">
              <dt class="has-text-grey">Shipping Method:</dt>
              <dd class="has-text-weight-semibold">
                {{ order.shipment.shippingMethod }}
              </dd>
            </div>
            <div class="sub-container-column">
              <div v-if="order.shipment.shipToAddress" class="sub-container-column">
                <dt class="has-text-grey">Shipping Address:</dt>
                <div class="has-text-weight-semibold">
                  <p>{{ order.shipment.shipToAddress.name }}</p>
                  <p>
                    {{ order.shipment.shipToAddress.line1 }}{{ order.shipment.shipToAddress.line2 ? `, ${order.shipment.shipToAddress.line2}` : '' }},
                    {{ order.shipment.shipToAddress.city }}, {{ order.shipment.shipToAddress.state }} {{ order.shipment.shipToAddress.postalCode }}
                  </p>
                </div>
              </div>
            </div>
            <div v-if="order.shipment.shippingInstructions" class="sub-container-column">
              <dt class="has-text-grey">Shipping Instructions:</dt>
              <dd class="has-text-weight-semibold">{{ order.shipment.shippingInstructions }}</dd>
            </div>
            <div v-if="order.shipment.shippingGiftMessage" class="sub-container-column">
              <dt class="has-text-grey">Shipping Gift Message:</dt>
              <dd class="has-text-weight-semibold">{{ order.shipment.shippingGiftMessage }}</dd>
            </div>
          </dl>
        </b-collapse>

        <b-collapse
          class="expandable-section"
          animation="slide"
        >
          <div
            slot="trigger"
            slot-scope="props"
            role="button"
            class="expandable-section--header"
          >
            <h4 class="subtitle is-5 is-marginless">
              Order Info
            </h4>
            <b-icon class="open-indicator" :icon="props.open ? 'angle-down' : 'angle-right'" />
          </div>
          <dl class="details-container dist-y-md">
            <div class="sub-container-row">
              <div class="sub-container-column">
                <dt class="has-text-grey">Order Date:</dt>
                <dd class="has-text-weight-semibold">
                  <div>
                    {{ order.orderDate | moment('MMMM D, YYYY', order.store.timeZone) }}
                  </div>
                  <div>
                    {{ order.orderDate | moment('@ h:mm A z', order.store.timeZone) }}
                  </div>
                </dd>
              </div>

              <div v-if="order.fulfillmentType === fulfillmentTypes.TAKEOUT" class="sub-container-column">
                <dt class="has-text-grey">Pickup Date:</dt>
                <dd class="has-text-weight-semibold">
                  <template v-if="order.pickupDate">
                    <div>
                      {{ order.pickupDate | moment('MMMM D, YYYY', order.store.timeZone) }}
                    </div>
                    <div>
                      {{ order.pickupDate | moment('@ h:mm A z', order.store.timeZone) }}
                    </div>
                  </template>
                  <div v-else class="has-text-weight-semibold">
                    N/A
                  </div>
                </dd>
              </div>
            </div>

            <div v-if="supportsOrderRedemption && order.fulfillmentType === fulfillmentTypes.DINE_IN" class="sub-container-column">
              <dt class="has-text-grey">Redemption Date:</dt>
              <dd v-if="latestRedemptionDate" class="has-text-weight-semibold">
                <div>
                  {{ latestRedemptionDate | moment('MMMM D, yyyy', order.store.timeZone) }}
                </div>
                <div>
                  {{ latestRedemptionDate | moment('@ h:mm A z', order.store.timeZone) }}
                </div>
              </dd>
              <dd v-else class="has-text-weight-semibold">
                <div>
                  N/A
                </div>
              </dd>
            </div>

            <template v-if="order.fulfillmentType === fulfillmentTypes.DELIVERY">
              <div>
                <dt class="has-text-grey">External Delivery ID:</dt>
                <dd v-if="order.delivery && order.delivery.externalId" class="has-text-weight-semibold">
                  <div>
                    {{ order.delivery.externalId }}
                  </div>
                </dd>
                <dd v-else class="has-text-weight-semibold">
                  <div>
                    N/A
                  </div>
                </dd>
              </div>

              <div>
                <dt class="has-text-grey">Delivery Date:</dt>
                <dd v-if="order.delivery && order.delivery.estimatedDeliveryTime" class="has-text-weight-semibold">
                  <div>
                    {{ order.delivery.estimatedDeliveryTime | moment('MMMM D, YYYY', order.store.timeZone) }}
                  </div>
                  <div>
                    {{ order.delivery.estimatedDeliveryTime | moment('@ h:mm A z', order.store.timeZone) }}
                  </div>
                </dd>
                <dd v-else class="has-text-weight-semibold">
                  <div>
                    N/A
                  </div>
                </dd>
              </div>

              <div>
                <dt>Delivery Instructions:</dt>
                <dd v-if="order.delivery && order.delivery.dropOffInstructions" class="has-text-weight-semibold">
                  {{ order.delivery.dropOffInstructions }}
                </dd>
                <dd v-else class="has-text-weight-semibold">
                  <div>
                    N/A
                  </div>
                </dd>
              </div>
            </template>

            <div>
              <dt class="has-text-grey">Store Address:</dt>
              <dd class="has-text-weight-semibold">
                <div>{{ order.store.description }}</div>
                <div>
                  {{ order.store.address.line1 }}{{ order.store.address.line2 ? `, ${order.store.address.line2}` : '' }},
                  {{ order.store.address.city }}, {{ order.store.address.state }} {{ order.store.address.postalCode }}
                </div>
              </dd>
            </div>

            <div v-if="order.internalNote" class="sub-container-column">
              <dt class="has-text-grey"> Internal Notes:</dt>
              <dd class="has-text-weight-semibold">{{ order.internalNote }}</dd>
            </div>

            <div v-if="order.attachmentUrl">
              <dt class="has-text-grey">Order Attachment:</dt>
              <dd>
                <b-icon class="mar-r-sm" icon="paperclip" type="is-grey" size="is-small" />
                <a :href="order.attachmentUrl" target="_blank">
                  <span>View Attachment</span>
                  <b-icon class="mar-l-xs" icon="external-link" size="is-small" />
                </a>
              </dd>
            </div>

            <div v-if="order.fulfillmentType === fulfillmentTypes.DELIVERY">
              <dt class="has-text-grey">Delivery Address:</dt>
              <dd v-if="order.delivery && order.delivery.dropOffAddress" class="has-text-weight-semibold">
                <div>
                  {{ order.delivery.dropOffAddress.line1 }}{{ order.delivery.dropOffAddress.line2 ? `, ${order.delivery.dropOffAddress.line2}` : '' }},
                  {{ order.delivery.dropOffAddress.city }}, {{ order.delivery.dropOffAddress.state }} {{ order.delivery.dropOffAddress.postalCode }}
                </div>
              </dd>
              <dd v-else class="has-text-weight-semibold">
                <div>
                  N/A
                </div>
              </dd>
            </div>
          </dl>

        </b-collapse>

        <b-collapse
          class="expandable-section"
          animation="slide"
        >
          <div
            slot="trigger"
            slot-scope="props"
            role="button"
            class="expandable-section--header"
          >
            <h4 class="subtitle is-5 is-marginless">
              Order Items
            </h4>
            <b-icon class="open-indicator" :icon="props.open ? 'angle-down' : 'angle-right'" />
          </div>
          <div class="details-container dist-y-lg">
            <order-details-items :order="order" />
            <order-price-breakdown :order="order" />
          </div>
        </b-collapse>

        <payment-info-section :order="order" />

        <b-collapse
          v-if="showLoyaltyActivity"
          class="expandable-section"
          animation="slide"
        >
          <div
            slot="trigger"
            slot-scope="props"
            role="button"
            class="expandable-section--header"
          >
            <h4 class="subtitle is-5 is-marginless">
              Loyalty Activity
            </h4>
            <b-icon class="open-indicator" :icon="props.open ? 'angle-down' : 'angle-right'" />
          </div>
          <div class="details-container dist-y-lg">
            <template v-if="order.loyaltyEngagementSummary.loyaltyAwardDisplayFormat === 'AwardDetails'">
              <div v-if="pointsEarned">
                <p class="has-text-grey">{{ currencyPluralName | capitalize }} Earned</p>
                <p class="has-text-weight-semibold">
                  {{ pointsEarned | formatDecimal }}
                  {{ pointsEarned === 1 ? currencyShortName : currencyPluralShortName }}
                </p>
              </div>

              <div v-if="loyaltyActivity.awardedOffers.length">
                <p class="has-text-grey">Offers Earned</p>
                <ul class="has-list-styles">
                  <li
                    v-for="awardOffer in loyaltyActivity.awardedOffers"
                    :key="awardOffer.cardfreeOfferPublicId"
                    class="has-text-weight-semibold"
                  >
                    {{ awardOffer.title }}
                  </li>
                </ul>
              </div>

              <div v-if="loyaltyActivity.primaryCurrencyToOfferConversions.length">
                <p class="has-text-grey">Offers Converted</p>
                <ul class="has-list-styles">
                  <li
                    v-for="(offerConversion, index) in loyaltyActivity.primaryCurrencyToOfferConversions"
                    :key="`offerConversion-${index + 1}`"
                  >
                    <span class="has-text-weight-semibold">
                      {{ offerConversion.award.offer.title }}
                    </span>
                    <span class="has-text-grey">
                      (-{{ offerConversion.cost | numberDisplay }} {{ offerConversion.cost > 1
                        ? offerConversion.currency.pluralShortName
                        : offerConversion.currency.shortName }})
                    </span>
                  </li>
                </ul>
              </div>
            </template>
            <div v-else-if="order.loyaltyEngagementSummary.loyaltyAwardDisplayFormat === 'Message'">
              <p class="has-text-grey no-wrap-text">
                Loyalty Message:
              </p>
              <p class="has-text-weight-semibold">
                {{ order.loyaltyEngagementSummary.loyaltyAwardSummaryMessage }}
              </p>
            </div>
          </div>
        </b-collapse>

        <transaction-details-section :order="order" />
      </template>
    </div>

    <div class="modal-card-foot is-hidden-print">
      <div class="buttons all-bold">
        <b-button
          icon-left="print"
          rounded
          @click="handlePrint"
        >
          Print
        </b-button>
        <b-button
          v-if="$can('refund_orders', 'StoreMapping') && order.orderType !== orderTypes.THIRD_PARTY_ORDER_AHEAD && (canRetryOrder || canCloseOrder)"
          rounded
          :icon-left="canRetryOrder ? 'redo' : 'times-circle'"
          type="is-primary"
          :loading="isRetryingOrClosing"
          @click="openRetryAndCloseConfirm"
        >
          {{ canRetryOrder ? 'Retry Payment' : 'Close Order' }}
        </b-button>
        <b-button
          v-else
          rounded
          type="is-primary"
          @click="closeModal"
        >
          Close
        </b-button>
      </div>
    </div>
  </div>
</template>

<script>
  import currency from 'currency.js';
  import orderDetailsItems from '@/components/globals/order-details-items.vue';
  import orderPriceBreakdown from '@/components/globals/order-price-breakdown.vue';

  import orderTypes from '@/constants/orderTypes';
  import orderProviderTypes from '@/constants/orderProviderTypes';
  import fulfillmentTypes from '@/constants/fulfillmentTypes';

  import cancelOrderMixin from './orders-helpers/cancel-order';
  import featurePermissionsMixin from '@/mixins/featurePermissions';
  import merchantMixin from '@/mixins/merchant';
  import printMixin from '@/mixins/print';

  import Order from '@/store/classes/Order';

  import PaymentInfoSection from './order-modal-sections/payment-info-section.vue';
  import TransactionDetailsSection from './order-modal-sections/transaction-details-section.vue';


  export default {
    name: 'OrderModal',

    components: { orderDetailsItems, orderPriceBreakdown, PaymentInfoSection, TransactionDetailsSection },

    mixins: [cancelOrderMixin, featurePermissionsMixin, printMixin, merchantMixin],

    props: {
      orderId: {
        type: Number,
        required: true
      }
    },

    data() {
      return {
        transactionDetails: null,
        showOrderId: false,
        isLoadingOrderDetails: true,
        isSectionOpen: {
          transactionDetails: false
        },
        isLoadingTransactionDetails: false,
        fulfillmentTypes,
        orderTypes
      };
    },

    computed: {
      modalTitle() {
        return this.order?.ticketNumber ? `Order #${this.order.ticketNumber}` : 'Order Details';
      },

      primaryCurrencySummary() {
        return this.loyaltyActivity?.primaryCurrencySummary || {};
      },

      pointsEarned() {
        return this.primaryCurrencySummary?.pointsEarned || 0;
      },

      currencyPluralName() {
        return this.primaryCurrencySummary?.currency?.pluralName || '';
      },

      currencyPluralShortName() {
        return this.primaryCurrencySummary?.currency?.pluralShortName || '';
      },

      currencyShortName() {
        return this.primaryCurrencySummary?.currency?.shortName || '';
      },

      supportsOrderRedemption() {
        return this.$_selectedMerchant.merchantOptionsCheckout.showOrderQrCode;
      },

      latestRedemptionDate() {
        return this.order.orderItems
          .map(item => item.redemptionDate)
          .filter(Boolean)
          .sort((a, b) => (a > b ? -1 : 1))[0];
      },

      orderSubtotal() {
        if ([orderTypes.PAY_AND_FULFILL, orderTypes.ORDER_AND_PAY].includes(this.order.orderType)) {
          return currency(this.order.subtotal).add(currency(this.order.discount))?.value;
        }
        return this.order.orderItemTotal;
      },

      order() {
        return Order.find(this.orderId) || new Order();
      },

      isOrderCancelable() {
        if ([orderTypes.ORDER_AND_PAY, orderTypes.THIRD_PARTY_ORDER_AHEAD, orderTypes.EXTERNAL_ORDER].includes(this.order.orderType)) {
          return false;
        }
        if (this.order.orderType === orderTypes.PAY_AND_FULFILL) {
          return ['submitted', 'future'].includes(this.order.status.toLowerCase());
        }
        return ['created', 'submitted', 'future'].includes(this.order.status.toLowerCase());
      },

      isThirdPartyOrder() {
        return this.order.orderType === orderTypes.THIRD_PARTY_ORDER_AHEAD && this.order.orderProviderType !== orderProviderTypes.UNSPECIFIED.type;
      },

      thirdPartyProviderType() {
        return Object.values(orderProviderTypes).find(providerType => providerType.type === this.order.orderProviderType);
      },

      thirdPartyOrderProviderDisplayName() {
        return this.thirdPartyProviderType?.displayName || 'Third Party';
      },

      isRefundingOrder() {
        return Order.$state().refunding;
      },

      isRetryingOrClosing() {
        return Order.$state().retryingOrClosing;
      },

      isOrderPendingCapturePayment() {
        return [orderTypes.PAY_AND_FULFILL, orderTypes.ORDER_AND_PAY].includes(this.order.orderType) && this.order.status === 'PendingCapturePayment';
      },

      canRetryOrder() {
        const somePaymentsNeedRetry = this.order.payments.some(p => !p.transactions.some(t => t.paymentTransaction?.paymentTransactionType === 'Capture'));
        return this.isOrderPendingCapturePayment && somePaymentsNeedRetry;
      },

      canCloseOrder() {
        const everyPaymentIsCaptured = this.order.payments.every(p => p.transactions.some(t => t.paymentTransaction?.paymentTransactionType === 'Capture'));
        return this.isOrderPendingCapturePayment && everyPaymentIsCaptured;
      },

      hasBeenRefunded() {
        return this.order.payments.some(payment => payment.transactions.some(transaction => (transaction.paymentTransaction?.paymentTransactionType === 'Refund' && transaction.paymentTransaction?.paymentTransactionStatus === 'Succeeded')));
      },

      loyaltyActivity() {
        const primaryCurrencySummary = this.order.loyaltyEngagementSummary.currencyPointEarningSummaries?.find(summary => summary.currency.isPrimaryCategory);

        const primaryCurrencyToOfferConversions = [...this.order.loyaltyEngagementSummary.pointConversions]
          .filter(pointConversion => pointConversion.currency.isPrimaryCategory && pointConversion.award.isOfferAward)
          .sort((a, b) => a.cost - b.cost);

        const awardedOffers = this.order.loyaltyEngagementSummary.awards.reduce((offers, award) => {
          if (award.isOfferAward) offers.push(award.offer);
          return offers;
        }, []);

        return {
          primaryCurrencySummary,
          awardedOffers,
          primaryCurrencyToOfferConversions
        };
      },

      showLoyaltyActivity() {
        const orderHasLoyaltyActivity = this.order.loyaltyEngagementSummary;
        const isMessageFormat = this.order.loyaltyEngagementSummary?.loyaltyAwardDisplayFormat === 'Message';
        const isAwardDetailsWithPrimaryActivity = this.order.loyaltyEngagementSummary?.loyaltyAwardDisplayFormat === 'AwardDetails' && [
          this.loyaltyActivity.primaryCurrencySummary,
          this.loyaltyActivity.awardedOffers.length,
          this.loyaltyActivity.primaryCurrencyToOfferConversions.length
        ].some(Boolean);

        return orderHasLoyaltyActivity && (isMessageFormat || isAwardDetailsWithPrimaryActivity);
      }
    },

    created() {
      this.onCreated();
    },

    methods: {
      onCreated() {
        this.fetchOrderDetails();
      },

      async fetchOrderDetails() {
        this.isLoadingOrderDetails = true;
        try {
          await Order.getOrderDetails(this.orderId, { includeLoyaltyEngagementSummary: true, includeAccount: true });
        }
        catch (error) {
          this.$_onRequestError({
            error,
            toastOptions: {
              message: 'Unable to get order details'
            }
          });
        }
        finally {
          this.isLoadingOrderDetails = false;
        }
      },

      getOrderStatus(status) {
        switch (status) {
          case 'PendingCapturePayment':
            return 'Pending';
          default:
            return status;
        }
      },

      toggleSection(section) {
        this.isSectionOpen[section] = !this.isSectionOpen[section];
      },

      closeModal() {
        this.$parent.close();
      },

      openRetryAndCloseConfirm() {
        const title = this.canRetryOrder ? 'Retry Payment' : 'Close Order';
        const message = this.canRetryOrder ? 'Are you sure you want to retry payment?' : 'Are you sure you want to close the order?';
        this.$buefy.dialog.confirm({
          title,
          message,
          hasIcon: true,
          icon: this.canRetryOrder ? 'redo' : 'times-circle',
          type: 'is-primary',
          onConfirm: this.handleRetryAndClose
        });
      },

      async handleRetryAndClose() {
        const { ticketNumber } = this.order;
        try {
          await Order.retryPaymentOrCloseOrder(this.order.orderId);
          const successMessage = this.canRetryOrder ? `Successfully retried payment for order #${ticketNumber}` : `Successfully closed order #${ticketNumber}`;
          const title = this.canRetryOrder ? 'Payment Retried' : 'Order Closed';

          this.$buefy.dialog.confirm({
            title,
            message: `${successMessage}. The order will take a few moments to update.`,
            hasIcon: false,
            type: 'is-primary',
            onConfirm: this.closeModal,
            canCancel: false
          });
        }
        catch (error) {
          const errorMessage = this.canRetryOrder ? `Unable to retry payment for order #${ticketNumber}` : `Unable to close order #${ticketNumber}`;
          this.$_onRequestError({
            error,
            toastOptions: {
              message: errorMessage
            }
          });
        }
      },

      handlePrint() {
        this.$_print(
          this.$el.innerHTML.split('display: none;').join(),
          {
            hideButtons: true,
            hideIcons: true
          }
        );
      }
    }
  };
</script>

<style lang="sass" scoped>

  .modal-card
    max-width: 475px

  .modal-card-body
    padding: 0

    > *:nth-child(even)
      background: $white-bis

    > *:last-child
      margin-bottom: 0

  .expandable-section
    border-bottom: 1px solid $grey-lighter
    padding: 15px 20px

    &:last-of-type
      border-bottom: none

    .expandable-section--header
      display: flex
      align-items: center
      justify-content: space-between
      cursor: pointer

      > *
        color: $grey-dark
        transition: 100ms

      &:hover > *
        color: $primary

    .details-container
      width: 100%
      margin-top: 1rem
      overflow: hidden

      &.customer-info
        > *
          min-width: 0
          overflow: hidden

      .no-details
        margin-right: 7rem !important

      .sub-container-column
        display: flex
        flex: 1
        flex-direction: column

      .sub-container-row
        display: flex

        > div:not(:last-child)
          margin-right: 2rem
</style>
