<template>
  <div class="card">
    <div class="card-content">
      <div class="is-flex justify-between">
        <p class="subtitle is-4">
          Offer Details
        </p>
        <b-button
          v-if="!offerDetailsAreReadOnly"
          type="is-primary"
          inverted
          @click="$emit('navigate-to-step', 0)"
        >
          Edit
        </b-button>
      </div>
      <div>
        <offer-preview class="mar-l-auto mar-r-auto" style="max-width: 500px" :offer="offer" />
      </div>
    </div>
  </div>
</template>

<script>
  import offerPreview from '@/components/pages/campaigns/components/offer-preview.vue';

  export default {
    name: 'OfferDetailsCard',

    components: { offerPreview },

    props: {
      offer: {
        type: Object,
        required: true
      },

      offerDetailsAreReadOnly: {
        type: Boolean,
        required: true
      }
    }
  };
</script>
