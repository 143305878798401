<template>
  <fieldset class="align-labels-left">
    <b-loading :active="isLoadingAppSettingResources" :is-full-page="false" />
    <template v-if="localForm">
      <validated-input
        class="mar-b-lg align-labels-left"
        :class="mode === 'create' && 'full-width-label'"
        horizontal
        label="Age Restriction"
        name="ageRestricted"
        tooltip="Enable to display an age restriction warning on the ordering application"
      >
        <b-switch
          v-model="ageRestricted"
          :disabled="!canEdit('ageRestricted')"
          type="is-danger"
        />
      </validated-input>

      <hr>

      <b-message v-if="!canEditWebApps" class="is-inline-block" size="is-small" type="is-primary">
        Please contact
        <a class="link" href="mailto:support@cardfree.com">CardFree support</a>
        to request changes to your enabled Web Applications
      </b-message>

      <validated-input
        class="mar-b-lg align-labels-left app-header"
        :class="mode === 'create' && 'full-width-label'"
        label="Order Ahead"
        horizontal
        name="orderAhead"
      >
        <div class="switch-link-container">
          <b-switch
            v-model="orderAhead"
            :disabled="!canEdit('orderAhead')"
          />
          <transition name="fade-right">
            <a
              v-if="localForm.id && orderAhead"
              :href="`${baseUrl}${localForm.friendlyUriName}`"
              target="_blank"
              class="web-app-url"
            >
              <b-icon icon="external-link-alt" size="is-small" class="mar-r-xs" />
              <span class="is-hidden-touch">{{ baseUrl }}{{ localForm.friendlyUriName }}</span>
              <span class="is-hidden-desktop">Visit Site</span>
            </a>
          </transition>
        </div>
      </validated-input>

      <validated-input
        class="sub-feature is-size-6 mar-b-lg align-labels-left"
        :class="mode === 'create' && 'full-width-label'"
        label="Delivery"
        horizontal
        name="deliveryEnabled"
      >
        <div class="switch-link-container">
          <b-switch
            v-model="delivery"
            :disabled="!canEdit('deliveryEnabled') || !orderAhead"
          />
        </div>
      </validated-input>

      <validated-input
        v-if="mode !== 'create'"
        class="sub-feature is-size-6 mar-b-lg align-labels-left"
        :class="mode === 'create' && 'full-width-label'"
        label="Order Dashboard"
        horizontal
        name="showKds"
        tooltip="Enables the Order Dashboard in Merchant Portal"
      >
        <div class="switch-link-container">
          <b-switch
            v-model="localForm.features.showKds"
            :disabled="!canEdit('orderAhead') || !orderAhead"
          />
        </div>
      </validated-input>

      <validated-input
        class="sub-feature mar-b-lg align-labels-left"
        :class="mode === 'create' && 'full-width-label'"
        label="ASAP Pickup Only"
        horizontal
        name="asapPickupOnly"
      >
        <div class="switch-link-container">
          <b-switch
            v-model="asapPickupOnly"
            :disabled="!canEdit('asapPickupOnly') || !orderAhead"
          />
        </div>
      </validated-input>

      <hr>

      <validated-input
        class="app-header mar-b-lg align-labels-left"
        :class="mode === 'create' && 'full-width-label'"
        label="Pay @ Table"
        horizontal
        name="payAtTable"
      >
        <div class="switch-link-container">
          <b-switch
            v-model="payAtTable"
            :disabled="!canEdit('payAtTable')"
          />
          <transition name="fade-right">
            <a
              v-if="localForm.id && payAtTable"
              :href="`${baseUrl}${localForm.friendlyUriName}/make-a-payment${merchantLocationId ? `?ml=${merchantLocationId}` : ''}`"
              target="_blank"
            >
              <b-icon icon="external-link-alt" size="is-small" class="mar-r-xs" />
              <span class="is-hidden-desktop">Visit Site</span>
              <span class="is-hidden-touch">
                {{ baseUrl }}{{ localForm.friendlyUriName }}/make-a-payment{{ merchantLocationId ? `?ml=${merchantLocationId}` : '' }}
              </span>
            </a>
          </transition>
        </div>
      </validated-input>

      <validated-input
        class="sub-feature mar-b-lg align-labels-left"
        :class="mode === 'create' && 'full-width-label'"
        label="Order @ Table"
        horizontal
        name="oatEnabled"
      >
        <div class="switch-link-container">
          <b-switch
            v-model="orderAtTable"
            :disabled="!payAtTable || !canEdit('oatEnabled')"
          />
          <transition name="fade-right">
            <a
              v-if="localForm.id && orderAtTable"
              :href="`${baseUrl}${localForm.friendlyUriName}/order-at-table/menu${merchantLocationId ? `?ml=${merchantLocationId}` : ''}`"
              target="_blank"
            >
              <b-icon icon="external-link-alt" size="is-small" class="mar-r-xs" />
              <span class="is-hidden-desktop">Visit Site</span>
              <span class="is-hidden-touch">
                {{ baseUrl }}{{ localForm.friendlyUriName }}/order-at-table{{ merchantLocationId ? `?ml=${merchantLocationId}` : '' }}
              </span>
            </a>
          </transition>
        </div>
      </validated-input>

      <validated-input
        v-if="mode !== 'create'"
        class="sub-feature mar-b-lg align-labels-left"
        :class="mode === 'create' && 'full-width-label'"
        label="Payment Dashboard"
        horizontal
        name="showOpd"
        tooltip="Enables the Payment Dashboard in Merchant Portal"
      >
        <div class="switch-link-container">
          <b-switch
            v-model="localForm.features.showOpd"
            :disabled="!canEdit('orderAhead') || !payAtTable"
          />
        </div>
      </validated-input>

      <validated-input
        v-if="mode !== 'create'"
        class="sub-feature mar-b-lg align-labels-left"
        :class="mode === 'create' && 'full-width-label'"
        label="Text To Pay"
        horizontal
        name="textToPay"
      >
        <div class="switch-link-container">
          <b-switch
            v-model="textToPay"
            :disabled="!canEdit('textToPay') || !payAtTable"
          />
        </div>
      </validated-input>

      <hr>

      <validated-input
        class="mar-b-lg align-labels-left"
        :class="mode === 'create' && 'full-width-label'"
        horizontal
        label="Digital Menu"
        name="readOnlyMenu"
      >
        <div class="switch-link-container">
          <b-switch
            v-model="readOnlyMenu"
            :disabled="!canEdit('readOnlyMenuEnabled')"
          />
          <transition name="fade-right">
            <a
              v-if="localForm.id && readOnlyMenu"
              :href="`${baseUrl}${localForm.friendlyUriName}/menu${merchantLocationId ? `?ml=${merchantLocationId}` : ''}`"
              target="_blank"
            >
              <b-icon icon="external-link-alt" size="is-small" class="mar-r-xs" />
              <span class="is-hidden-desktop">Visit Site</span>
              <span class="is-hidden-touch">
                {{ baseUrl }}{{ localForm.friendlyUriName }}/menu{{ merchantLocationId ? `?ml=${merchantLocationId}` : '' }}
              </span>
            </a>
          </transition>
        </div>
      </validated-input>

      <transition name="fade-down">
        <b-field
          v-if="localForm.id && canEditWebApps && hasMultipleWebAppsEnabled"
          class="align-labels-left"
          :class="mode === 'create' && 'full-width-label'"
          horizontal
        >
          <template #label>
            <p>Landing Page</p>
            <p class="is-size-7 has-text-weight-normal has-text-grey">Automatically enabled when more than one Web Application is enabled</p>
          </template>
          <div class="switch-link-container">
            <b-switch class="is-invisible is-hidden-touch" />
            <a
              :href="`${baseUrl}${localForm.friendlyUriName}/welcome${merchantLocationId ? `?ml=${merchantLocationId}` : ''}`"
              target="_blank"
            >
              <b-icon icon="external-link-alt" size="is-small" class="mar-r-xs" />
              <span class="is-hidden-desktop">Visit Site</span>
              <span class="is-hidden-touch">
                {{ baseUrl }}{{ localForm.friendlyUriName }}/welcome{{ merchantLocationId ? `?ml=${merchantLocationId}` : '' }}
              </span>
            </a>
          </div>
        </b-field>
      </transition>
    </template>
  </fieldset>
</template>

<script>
  import filterObjectKeys from '@/helpers/filter-object-keys';
  import Merchant from '@/store/classes/Merchant';
  import Store from '@/store/classes/Store';
  import MerchantAppSettingResource from '@/store/classes/MerchantAppSettingResource';
  import appSettingResourceMap from '@/constants/merchantAppSettingResources';

  export default {
    name: 'MerchantApplicationInputs',

    props: {
      mode: {
        type: String,
        default: 'create'
      }
    },

    data() {
      return {
        localForm: null
      };
    },

    computed: {
      editableMerchant() {
        return Merchant.$state().editableMerchant;
      },

      orderAhead: {
        get() {
          return this.findAppSetting(appSettingResourceMap.ORDER_AHEAD)?.value;
        },
        set(value) {
          if (!value) {
            this.localForm.features.showKds = value;

            if (this.delivery !== undefined) {
              this.delivery = value;
            }

            if (this.asapPickupOnly !== undefined) {
              this.asapPickupOnly = value;
            }
          }

          this.setOrCreateAppSetting({ type: appSettingResourceMap.ORDER_AHEAD, value });
        }
      },

      payAtTable: {
        get() {
          return this.findAppSetting(appSettingResourceMap.PAY_AT_TABLE)?.value;
        },
        set(value) {
          if (!value) {
            this.localForm.features.showOpd = value;

            if (this.orderAtTable !== undefined) {
              this.orderAtTable = value;
            }
            if (this.textToPay !== undefined) {
              this.textToPay = value;
            }
          }

          this.setOrCreateAppSetting({ type: appSettingResourceMap.PAY_AT_TABLE, value });
        }
      },

      orderAtTable: {
        get() {
          return this.findAppSetting(appSettingResourceMap.ORDER_AT_TABLE)?.value;
        },
        set(value) {
          this.setOrCreateAppSetting({ type: appSettingResourceMap.ORDER_AT_TABLE, value });
        }
      },

      asapPickupOnly: {
        get() {
          return this.findAppSetting(appSettingResourceMap.ASAP_PICKUP_ONLY)?.value;
        },
        set(value) {
          this.setOrCreateAppSetting({ type: appSettingResourceMap.ASAP_PICKUP_ONLY, value });
        }
      },

      ageRestricted: {
        get() {
          return this.findAppSetting(appSettingResourceMap.AGE_RESTRICTED)?.value;
        },
        set(value) {
          this.setOrCreateAppSetting({ type: appSettingResourceMap.AGE_RESTRICTED, value });
        }
      },

      readOnlyMenu: {
        get() {
          return this.findAppSetting(appSettingResourceMap.READ_ONLY_MENU)?.value;
        },
        set(value) {
          this.setOrCreateAppSetting({ type: appSettingResourceMap.READ_ONLY_MENU, value });
        }
      },

      delivery: {
        get() {
          return this.findAppSetting(appSettingResourceMap.DELIVERY)?.value;
        },
        set(value) {
          this.setOrCreateAppSetting({ type: appSettingResourceMap.DELIVERY, value });
        }
      },

      textToPay: {
        get() {
          return this.findAppSetting(appSettingResourceMap.TEXT_TO_PAY)?.value;
        },
        set(value) {
          this.setOrCreateAppSetting({ type: appSettingResourceMap.TEXT_TO_PAY, value });
        }
      },

      merchantLocationId() {
        return Store.query().where(store => store.isActive && !store.isSystem).first()?.merchantLocationId;
      },

      hasMultipleWebAppsEnabled() {
        return [
          this.orderAhead,
          this.payAtTable,
          this.orderAtTable,
          this.readOnlyMenu
        ].filter(x => x).length > 1;
      },

      baseUrl() {
        return this.editableMerchant.baseUrl || 'https://order.cardfree.com/';
      },

      canEditWebApps() {
        return [
          this.canEdit('order_ahead_enabled'),
          this.canEdit('pat_enabled'),
          this.canEdit('oat_enabled'),
          this.canEdit('read_only_menu_enabled')
        ].every(x => x);
      },

      appSettingResources() {
        return MerchantAppSettingResource.query().where('merchantId', this.editableMerchant.id).get();
      },

      isLoadingAppSettingResources() {
        return MerchantAppSettingResource.$state().fetching;
      },

      formValsCopy() {
        return JSON.parse(JSON.stringify(this.localForm));
      }
    },

    watch: {
      formValsCopy: {
        immediate: false,
        handler: 'handleFormValsChange'
      }
    },

    created() {
      this.onCreated();
    },

    methods: {
      async onCreated() {
        this.localForm = JSON.parse(JSON.stringify(this.editableMerchant));
      },

      canEdit(field) {
        return this.$can(this.mode, 'Merchant', field);
      },

      setOrCreateAppSetting({ type, value }) {
        const appSettingIndex = this.localForm.merchantAppSettingResources.findIndex(resource => resource.key === type);

        if (appSettingIndex >= 0) {
          this.localForm.merchantAppSettingResources[appSettingIndex].value = value;
        }
        else {
          this.localForm.merchantAppSettingResources.push({ id: null, key: type, value });
        }
      },

      findAppSetting(type) {
        return this.localForm.merchantAppSettingResources?.find(resource => resource.key === type);
      },

      handleFormValsChange(newVals) {
        Merchant.setEditableMerchant(filterObjectKeys(newVals, ['merchantAppSettingResources', 'features']));
      }
    }
  };
</script>

<style lang="sass" scoped>
  .app-header
    ::v-deep .label
      font-size: $size-4
  .sub-feature
    ::v-deep .label
      padding-left: 1rem

  .switch-link-container
    display: flex
    flex-wrap: wrap
    align-items: center
</style>
