<template>
  <validation-provider
    v-slot="{ errors, classes }"
    slim
    :rules="{ required: !selectedOfferPublicId || !offerIsValidForTimeFrame }"
    :name="`awardPolicyOffer${index}}`"
    :custom-messages="{ required: errorMessage }"
  >
    <div :class="['flex-grow', classes]">
      <b-checkbox
        :class="['is-hidden', { 'invalid': errors.length }]"
        :value="(offerIsValidForTimeFrame && selectedOfferPublicId) || null"
      />
      <b-field
        :type="{ 'is-danger': !!errors.length }"
        :message="errors"
        :label="index < 1 ? 'Reward' : ''"
      >
        <dropdown-menu
          :value="selectedOfferPublicId"
          expanded
          @input="$emit('update-offer-public-id', $event)"
        >
          <dropdown-button
            slot="trigger"
            placeholder="Select Offer"
            :value="getOfferDisplay()"
          />
          <b-dropdown-item
            v-for="offer in offers"
            :key="offer.id"
            class="pad-y-sm offer-select"
            :value="offer.id"
            :disabled="selectedOfferPublicIds.includes(offer.id) && offer.id !== selectedOfferPublicId"
            data-test-class="offer-option"
          >
            <div class="mar-l-xs is-clipped">
              <p class="text-ellipsis has-text-weight-bold">
                {{ offer.name }}
                <b-icon
                  v-if="offer.icon"
                  class="mar-l-xs"
                  type="is-success"
                  size="is-small"
                  icon="check-circle"
                />
              </p>
              <p v-if="offer.description" class="text-ellipsis mar-none is-size-7 has-text-weight-normal">
                {{ offer.description }}
              </p>
              <p v-if="offer.footer" class="text-ellipsis mar-none is-size-7 is-italic has-text-weight-normal">
                {{ offer.footer }}
              </p>
            </div>
          </b-dropdown-item>
        </dropdown-menu>
      </b-field>
    </div>
  </validation-provider>
</template>

<script>
  import moment from 'moment-timezone';

  export default {
    name: 'RandomOfferAwardDropdown',

    props: {
      selectedOfferPublicId: {
        type: String,
        required: true
      },

      resourceEndDate: {
        type: String,
        default: ''
      },

      index: {
        type: Number,
        required: true
      },

      readOnly: {
        type: Boolean,
        default: false
      },

      selectedOfferPublicIds: {
        type: Array,
        required: true
      },

      offers: {
        type: Array,
        required: true
      }
    },

    computed: {
      offerIsValidForTimeFrame() {
        const offer = this.offers.find(({ id }) => id === this.selectedOfferPublicId);
        const offerEndDate = offer?.redemptionsAllowedEndDate;
        return offerEndDate ? moment(this.resourceEndDate).isSameOrBefore(moment(offerEndDate)) : true;
      },

      errorMessage() {
        if (!this.selectedOfferPublicId) {
          return 'An offer is required.';
        }
        else if (!this.offerIsValidForTimeFrame) {
          return 'Offer end date must be null or after the earn rule threshold end date. Please select a new offer or change the earn rule end date.';
        }
        else {
          return '';
        }
      }
    },

    methods: {
      getOfferDisplay() {
        const offer = this.offers.find(({ id }) => id === this.selectedOfferPublicId);
        if (offer) {
          return offer.name;
        }
        else {
          return '';
        }
      }
    }
  };
</script>

<style lang='sass' scoped>
  .offer-select
    max-width: 350px
</style>
