<template>
  <div>
    <li
      v-for="category in searchResults"
      :key="`category-${category.id}`"
      :data-category-id="category.id"
      :class="{
        'show-inventory': $_menuPermissions.UPDATE_INVENTORY,
        'hide-prices': !$_selectedMerchant.features.menuPrices
      }"
    >
      <div class="row">
        <span class="has-text-weight-bold">{{ category.displayName }}</span>
        <span v-if="$_selectedMerchant.features.menuPrices" />
        <span v-if="$_menuPermissions.UPDATE_INVENTORY" />
        <span class="align-right">
          <b-button
            v-if="$can('read', 'StoreMenuCategorySchedule') && hasStoreCategorySchedule(category.id)"
            v-tippy="{ content: 'Restricted Availability', placement: 'left', delay: [333, 0] }"
            class="is-transparent"
            @click="emitSharedModGroupsSearchAction({ actionName: 'open-category-day-part-schedule-modal', args: category })"
          >
            <b-icon icon="calendar" pack="far" />
          </b-button>
          <b-button class="is-transparent" @click="emitSharedModGroupsSearchAction({ actionName: 'open-category-day-part-schedule-modal', args: category })">
            <b-icon v-if="$can('update', 'StoreMenuCategorySchedule') && $_menuPermissions.MANAGE_DAYPART_SCHEDULE" icon="pencil" size="is-small" />
            <span v-else>View</span>
          </b-button>
          <b-button
            v-if="$can('update', 'StoreMenuItem', 'isDisabled') && $_menuPermissions.UPDATE_VISIBILITY"
            class="is-transparent"
            @click="emitSharedModGroupsSearchAction({ actionName: 'toggle-category-disabled', args: category })"
          >
            <b-icon
              :icon="getCategoryDisabled(category) ? 'eye-slash' : 'eye'"
              :class="{'has-text-danger': getCategoryDisabled(category)}"
              size="is-small"
            />
          </b-button>
        </span>
      </div>
    </li>
  </div>
</template>

<script>
  import merchantMixin from '@/mixins/merchant';
  import featurePermissionsMixin from '@/mixins/featurePermissions';

  export default {
    name: 'CategorySearchRow',

    mixins: [merchantMixin, featurePermissionsMixin],

    props: {
      searchResults: {
        type: Array,
        default: () => []
      },

      storeMenuCategorySchedules: {
        type: Array,
        required: true
      },

      visibleCategoryIds: {
        type: Array,
        required: true
      }
    },

    methods: {
      emitSharedModGroupsSearchAction(args) {
        this.$emit('shared-mod-group-search-action', args);
      },

      getCategoryDisabled(category) {
        return !this.visibleCategoryIds.includes(category.id);
      },

      hasStoreCategorySchedule(categoryId) {
        return !!this.storeMenuCategorySchedules?.find(schedule => schedule.menuCategoryId === categoryId);
      }
    }
  };
</script>

<style lang="sass" scoped>
  .row
    grid-template-columns: minmax(200px, 1fr) minmax(75px, 0.2fr) minmax(100px, 0.25fr)

    .show-inventory &
      grid-template-columns: minmax(200px, 1fr) minmax(75px, 0.2fr) minmax(145px, 200px) minmax(100px, 0.25fr)

    .hide-prices &
      grid-template-columns: minmax(200px, 1fr) minmax(145px, 200px) minmax(100px, 0.25fr)
</style>
