/* eslint-disable import/no-cycle */
import { Model } from '@vuex-orm/core';
import http from '@/services/http';
import filterObjectKeys from '@/helpers/filter-object-keys';



export default class MerchantAppSettingResource extends Model {
  static entity = 'merchantAppSettingResources'

  static fields() {
    return {
      id: this.attr(''),
      key: this.attr(''),
      value: this.attr(''),
      merchantId: this.attr(''),
      merchantAppSettingsId: this.attr('')
    };
  }

  // STATE //////////////////////
  static state() {
    return {
      fetching: false,
      submitting: false,
      masrKeys: null
    };
  }

  static $state() {
    return this.store().state.entities.merchantAppSettingResources;
  }


  // Actions
  static async fetchAppSettingResources(merchantId) {
    if (this.exists()) return;

    try {
      this.commit((state) => {
        state.fetching = true;
      });

      const response = await http.get(`merchants/${merchantId}/merchant_app_setting_resources`);

      this.create({ data: response.data.merchantAppSettingResources });
    }
    catch (error) {
      throw error;
    }
    finally {
      this.commit((state) => {
        state.fetching = false;
      });
    }
  }

  static async createOrUpdateAppSettingResources({ merchantId, merchantAppSettingResources }) {
    try {
      this.commit((state) => {
        state.submitting = true;
      });

      const response = await http.post(
        `merchants/${merchantId}/merchant_app_setting_resources`,
        {
          merchantAppSettingResources: merchantAppSettingResources.map(x => filterObjectKeys(x, ['id', 'key', 'value', 'appCodeTypeId']))
        }
      );

      this.insert({
        data: response.data.merchantAppSettingResources.map((masr) => {
          masr.merchantId = merchantId;
          return masr;
        })
      });

      return response;
    }
    catch (error) {
      throw error;
    }
    finally {
      this.commit((state) => {
        state.submitting = false;
      });
    }
  }

  static async fetchAppSettingResourceKeys() {
    if (this.$state().masrKeys) {
      return this.$state().masrKeys;
    }

    try {
      this.commit((state) => {
        state.fetching = true;
      });

      const { data } = await http.get('merchant_app_setting_resources');
      this.commit((state) => {
        state.masrKeys = data;
      });
      return data;
    }
    catch (error) {
      throw error;
    }
    finally {
      this.commit((state) => {
        state.fetching = false;
      });
    }
  }
}

