var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"detail-page is-flex-column"},[_c('hero-banner',{scopedSlots:_vm._u([{key:"right",fn:function(){return [(_vm.regionMenus.length)?_c('b-button',{attrs:{"rounded":"","inverted":"","size":"is-medium","type":"is-primary","icon-left":"plus","disabled":!_vm.$can('create', 'RegionMenu')},on:{"click":function($event){return _vm.openRegionMenuModal()}}},[_vm._v(" New Regional Menu ")]):_vm._e()]},proxy:true}])},[_c('h1',{staticClass:"title is-2"},[_vm._v("Regional Menus")])]),_c('div',{staticClass:"flex-grow is-relative"},[(_vm.isFetchingRegionMenus)?_c('b-loading',{attrs:{"is-full-page":false,"active":""}}):_c('div',{staticClass:"section"},[_c('div',{staticClass:"container"},[(_vm.regionMenus.length)?_c('div',{staticClass:"card"},[_c('searchable-table',{attrs:{"table-data":_vm.regionMenus,"search":{
              placeholder: 'Search by Regional Menu Name',
              keys: ['name']
            }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var filteredData = ref.filteredData;
return [_c('b-table',{staticClass:"is-middle-aligned",attrs:{"data":_vm.isFetchingRegionMenus ? [] : filteredData,"paginated":_vm.regionMenus.length > _vm.pageLimit,"pagination-simple":"","per-page":_vm.pageLimit,"mobile-cards":false}},[_c('b-table-column',{attrs:{"sortable":"","field":"name","label":"Name"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var ref_row = ref.row;
            var name = ref_row.name;
            var id = ref_row.id;
return [_c('span',{staticClass:"link",on:{"click":function($event){return _vm.openRegionMenuModal(id)}}},[_vm._v(_vm._s(name))])]}}],null,true)}),_c('b-table-column',{attrs:{"numeric":"","label":"Actions","width":"1"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var row = ref.row;
return [_c('b-button',{staticClass:"is-transparent",attrs:{"type":"is-danger","inverted":"","disabled":!_vm.$can('destroy', 'RegionMenu'),"loading":_vm.deletingId === row.id,"icon-left":"trash-alt"},on:{"click":function($event){return _vm.openRemoveRegionMenuModal(row)}}})]}}],null,true)}),_c('template',{slot:"empty"},[_c('empty-table-loader',{attrs:{"icon":"globe-americas","message":"No Regional Menu Found...","loading":_vm.isFetchingRegionMenus}})],1)],2)]}}],null,false,3836164046)})],1):_c('empty-state-card',{attrs:{"type":"is-primary","title":"Create a Regional Menu","message":"Define Menus to apply to a selection of stores","icon":"globe-americas"},scopedSlots:_vm._u([{key:"buttons",fn:function(){return [_c('b-button',{attrs:{"type":"is-primary","rounded":"","icon-left":"plus","disabled":!_vm.$can('create', 'RegionMenu')},on:{"click":function($event){return _vm.openRegionMenuModal()}}},[_vm._v(" New Regional Menu ")])]},proxy:true}])})],1)])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }