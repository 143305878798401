<template>
  <panel collapsible title="Departments" no-padding>
    <b-loading :active="isSortingDepartments" :is-full-page="false" />
    <div class="nested-table">
      <div class="table-heading">
        <div class="row">
          <span class="align-left">Name</span>
          <span class="align-center">Actions</span>
        </div>
      </div>

      <draggable
        class="nested-table-section"
        tag="ul"
        group="department"
        v-bind="draggableAttributes"
        :list="filteredDepartments"
        :force-fallback="true"
        @change="handleDepartmentSort"
      >
        <li v-for="department in filteredDepartments" :key="department.publicId" :style="{ listStyle: 'none' }" class="draggable">
          <div class="row">
            <span class="pad-y-sm">
              <b-icon icon="grip-lines" size="is-small" pack="far" class="drag-handle" />
              <span class="link-inverted" @click="openDepartmentModal(department)">
                {{ department.name }}
              </span>
            </span>

            <span class="align-center">
              <b-dropdown aria-role="list" position="is-bottom-left">
                <b-button slot="trigger" class="is-transparent" type="is-white">
                  <b-icon icon="ellipsis-v" pack="far" size="is-small" />
                </b-button>

                <b-dropdown-item @click="openShareQRCodeModal(department)">
                  <b-icon icon="qrcode" class="mar-r-sm" size="is-small" />
                  View & Share QR
                </b-dropdown-item>
                <b-dropdown-item @click="openDepartmentModal(department)">
                  <b-icon icon="pencil" class="mar-r-sm" size="is-small" />
                  Edit Department
                </b-dropdown-item>
                <hr class="dropdown-divider">
                <b-dropdown-item class="is-danger" @click="openConfirmDelete(department)">
                  <b-icon icon="trash-alt" class="mar-r-sm" size="is-small" />
                  Delete Department
                </b-dropdown-item>
              </b-dropdown>
            </span>
          </div>
        </li>
      </draggable>
    </div>

    <div class="pad-x pad-y-sm has-border-top has-border-grey-lighter">
      <b-button
        type="is-primary"
        inverted
        class="is-transparent"
        icon-left="plus"
        @click="openDepartmentModal()"
      >
        Department
      </b-button>
    </div>
  </panel>
</template>

<script>
  import Department from '@/store/classes/Department';
  import Employee from '@/store/classes/Employee';
  import draggable from 'vuedraggable';
  import addEditDepartmentModal from './add-edit-department-modal.vue';
  import alertModal from '@/components/globals/alert-modal.vue';
  import shareEmployeeDepartmentModal from './share-employee-department-modal.vue';

  export default {
    name: 'DepartmentsConfiguration',

    components: { draggable },

    props: {
      store: {
        type: Object,
        required: true
      },

      merchantTippingOptions: {
        type: Array,
        required: true
      }
    },

    data() {
      return {
        draggableAttributes: {
          animation: '200',
          ghostClass: 'ghost',
          handle: '.drag-handle'
        }
      };
    },

    computed: {
      filteredDepartments() {
        return Department.query().where('storeId', this.store.storeId).orderBy('sortOrder').get();
      },

      isSortingDepartments() {
        return Department.$state().sorting;
      }
    },

    methods: {
      openDepartmentModal(department) {
        const departmentToOpen = department || new Department({ storeId: this.store.storeId });
        this.$buefy.modal.open({
          parent: this,
          component: addEditDepartmentModal,
          hasModalCard: true,
          trapFocus: true,
          canCancel: false,
          customClass: 'auto-width',
          props: { department: departmentToOpen, merchantTippingOptions: this.merchantTippingOptions }
        });
      },

      openShareQRCodeModal(department) {
        this.$buefy.modal.open({
          parent: this,
          component: shareEmployeeDepartmentModal,
          hasModalCard: true,
          trapFocus: true,
          canCancel: false,
          customClass: 'auto-width',
          props: { resource: department, type: 'department' }
        });
      },

      async handleDepartmentSort({ moved }) {
        try {
          await Department.sortDepartments({ fromSortOrder: moved.oldIndex, toSortOrder: moved.newIndex, array: this.filteredDepartments });

          this.$_onRequestSuccess({
            toastOptions: {
              message: `<b>${moved.element.name}</b> moved from sort order <b>${moved.oldIndex + 1}</b> to <b>${moved.newIndex + 1}</b>`
            }
          });
        }

        catch (error) {
          this.$_onRequestError({
            toastOptions: { message: 'There was an error updating the sort order of your departments' },
            error
          });
        }
      },

      openConfirmDelete(department) {
        this.$buefy.modal.open({
          parent: this,
          component: alertModal,
          hasModalCard: true,
          trapFocus: true,
          canCancel: ['escape', 'outside'],
          props: {
            icon: 'trash-alt',
            title: 'Delete Department',
            message: `Are you sure you want to delete <b>${department.name}</b> from the <b>${this.store.description}</b> location? This will delete all of its associated employees. This CANNOT be undone.`,
            type: 'is-danger',
            horizontal: true,
            showCloseButton: false,
            buttons: [
              { text: 'Cancel' },
              {
                text: 'Delete',
                primary: true,
                onClick: () => this.handleDeleteDepartment(department.id)
              }
            ]
          }
        });
      },

      async handleDeleteDepartment(departmentId) {
        try {
          await Department.deleteDepartment(departmentId);
          // Refetch employees so that employees associated to deleted department are removed
          await Employee.fetchEmployees(this.store.storeId);
        }

        catch (error) {
          this.$_onRequestError({
            toastOptions: {
              message: 'There was an issue deleting your department'
            },
            error
          });
        }
      }
    }
  };
</script>

<style lang="sass" scoped>
  .row
    grid-template-columns: 1fr 125px
</style>
