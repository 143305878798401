var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-checkbox',{class:[
    'check-button',
    _vm.orientation,
    {
      checked: _vm.isChecked,
      compact: _vm.compact,
      'fixed-height': _vm.fixedHeight,
      'left-label': _vm.leftLabel,
      'justify-between': _vm.justifyBetween || _vm.leftLabel,
      'justify-start': _vm.justifyStart,
      'justify-end': _vm.justifyEnd
    }
  ],attrs:{"value":_vm.value,"size":_vm.size,"disabled":_vm.disabled,"native-value":_vm.nativeValue,"type":"is-primary"},on:{"input":function($event){return _vm.$emit('input', $event)}}},[_c('div',{staticClass:"is-flex align-center"},[(_vm.icon)?_c('b-icon',{staticClass:"mar-r-sm",attrs:{"icon":_vm.icon,"pack":_vm.iconPack,"size":"is-medium"}}):_vm._e(),_c('div',[_c('p',{staticClass:"label mar-b-none"},[_vm._t("default",[_vm._v(_vm._s(_vm.label))])],2),(_vm.$slots.sublabel || _vm.sublabel)?_c('p',{staticClass:"sublabel"},[_vm._t("sublabel",[_vm._v(_vm._s(_vm.sublabel))])],2):_vm._e()])],1)])}
var staticRenderFns = []

export { render, staticRenderFns }