import { Model } from '@vuex-orm/core';
import http from '@/services/http';



export default class MenuTypeSchedule extends Model {
  static entity = 'menuTypeSchedules'

  // FIELDS
  static fields() {
    return {
      id: this.attr(''),
      day: this.attr(''),
      startTime: this.attr(null),
      endTime: this.attr(null),
      menuTypeId: this.attr(''),
      merchantId: this.attr('')
    };
  }



  // STATE
  static state() {
    return {
      fetching: false,
      submitting: false
    };
  }



  // ACTIONS
  static async fetchMenuTypeSchedules() {
    try {
      this.commit((state) => {
        state.fetching = true;
      });

      const merchantId = this.store().state.entities.merchants.selectedMerchantId;
      const response = await http.get(`merchants/${merchantId}/menu_type_schedules`);

      this.insert({ data: response.data.menuTypeSchedules });
    }

    catch (error) {
      throw error;
    }

    finally {
      this.commit((state) => {
        state.fetching = false;
      });
    }
  }

  static async createMenuTypeSchedule(menuTypeSchedule) {
    try {
      this.commit((state) => {
        state.submitting = true;
      });

      const merchantId = this.store().state.entities.merchants.selectedMerchantId;
      const response = await http.post(`merchants/${merchantId}/menu_type_schedules`, {
        menuTypeSchedule
      });

      this.insert({ data: response.data.menuTypeSchedule });
    }

    catch (error) {
      throw error;
    }

    finally {
      this.commit((state) => {
        state.submitting = false;
      });
    }
  }

  static async updateMenuTypeSchedule({ id, menuTypeId, day, endTime, startTime }) {
    try {
      this.commit((state) => {
        state.submitting = true;
      });

      const response = await http.put(`menu_type_schedules/${id}`, {
        menuTypeSchedule: {
          startTime,
          endTime,
          day,
          menuTypeId
        }
      });

      this.insert({ data: response.data.menuTypeSchedule });
    }

    catch (error) {
      throw error;
    }

    finally {
      this.commit((state) => {
        state.submitting = false;
      });
    }
  }

  static async deleteMenuTypeSchedule(id) {
    try {
      this.commit((state) => {
        state.submitting = true;
      });

      await http.delete(`menu_type_schedules/${id}`);
      this.delete(id);
    }

    catch (error) {
      throw error;
    }

    finally {
      this.commit((state) => {
        state.submitting = false;
      });
    }
  }
}
