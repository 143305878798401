import { Model } from '@vuex-orm/core';
import http from '@/services/http';
import filterObjectKeys from '@/helpers/filter-object-keys';
import { setUpdatedSortOrders } from '@/helpers/set-new-sort-orders';
import MerchantLink from './MerchantLink';


export default class MerchantLinkHeader extends Model {
  static entity = 'merchantLinkHeaders'



  // FIELDS //////////////////////
  static fields() {
    return {
      id: this.attr(''),
      merchantId: this.attr(''),
      linkTypeId: this.attr(''),
      title: this.attr(''),
      sortOrder: this.number(''),

      // FE Relationships
      merchantLinks: this.hasMany(MerchantLink, 'merchantLinkHeaderId')
    };
  }



  // STATE //////////////////////
  static state() {
    return {
      fetching: false,
      submitting: false,
      deletingId: false,
      sorting: false
    };
  }

  static $state() {
    return this.store().state.entities.merchantLinkHeaders;
  }


  // ACTIONS //////////////////////
  static async fetchMerchantLinkHeaders() {
    try {
      this.commit((state) => {
        state.fetching = true;
      });

      const merchantId = this.store().state.entities.merchants.selectedMerchantId;

      const { data } = await http.get(`merchants/${merchantId}/merchant_link_headers`);

      this.create({
        data: data.merchantLinkHeaders
      });
    }

    catch (error) {
      throw error;
    }

    finally {
      this.commit((state) => {
        state.fetching = false;
      });
    }
  }

  static async createMerchantLinkHeader(merchantLinkHeader) {
    try {
      this.commit((state) => {
        state.submitting = true;
      });

      const merchantId = this.store().state.entities.merchants.selectedMerchantId;

      const response = await http.post(`merchants/${merchantId}/merchant_link_headers`, { merchantLinkHeader });

      this.insert({
        data: response.data.merchantLinkHeader
      });

      return response.data.merchantLinkHeader;
    }

    catch (error) {
      throw error;
    }

    finally {
      this.commit((state) => {
        state.submitting = false;
      });
    }
  }

  static async updateMerchantLinkHeader(merchantLinkHeader) {
    try {
      this.commit((state) => {
        state.submitting = true;
      });

      const acceptedKeys = [
        'title',
        'sortOrder'
      ];

      const merchantId = this.store().state.entities.merchants.selectedMerchantId;

      const response = await http.put(`merchants/${merchantId}/merchant_link_headers/${merchantLinkHeader.id}`, {
        merchantLinkHeader: filterObjectKeys(merchantLinkHeader, acceptedKeys)
      });

      this.update({
        data: response.data.merchantLinkHeader
      });
    }

    catch (error) {
      throw error;
    }

    finally {
      this.commit((state) => {
        state.submitting = false;
      });
    }
  }

  static async removeMerchantLinkHeader(merchantLinkHeaderId) {
    try {
      this.commit((state) => {
        state.deletingId = merchantLinkHeaderId;
      });

      await http.delete(`merchant_link_headers/${merchantLinkHeaderId}`);

      this.delete(merchantLinkHeaderId);
    }

    catch (error) {
      throw error;
    }

    finally {
      this.commit((state) => {
        state.deletingId = false;
      });
    }
  }

  static async sortMerchantLinkHeader({ fromSortOrder, toSortOrder, array }) {
    try {
      this.commit((state) => {
        state.sorting = true;
      });

      const newMerchantLinkHeaders = setUpdatedSortOrders({ fromIndex: fromSortOrder, toIndex: toSortOrder, array });

      this.insert({ data: newMerchantLinkHeaders });

      await http.put('merchant_link_headers/sort_order', {
        merchantLinkHeaders: newMerchantLinkHeaders.map(obj => filterObjectKeys(obj, ['id', 'sortOrder']))
      });
    }

    catch (error) {
      this.insert({ data: array });
      throw error;
    }

    finally {
      this.commit((state) => {
        state.sorting = false;
      });
    }
  }
}
