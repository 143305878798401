import capitalCase from '@/helpers/capitalCase';
import { camelCase } from 'change-case';
import FetchModel from './FetchModel';


export default class PlatformType extends FetchModel {
  static entity = 'platformTypes'



  // FIELDS //////////////////////
  static fields() {
    return {
      id: this.attr(''),
      name: this.attr(''),
      description: this.attr('')
    };
  }

  get displayName() {
    return capitalCase(this.name);
  }

  static byName() {
    return this.all().reduce((acc, item) => {
      acc[camelCase(item.name)] = item;
      return acc;
    }, {});
  }

  // STATE //////////////////////
  static state() {
    return {
      fetching: false
    };
  }

  static $state() {
    return this.store().state.entities.platformTypes;
  }


  // ACTIONS //////////////////////
  static async fetchPlatformTypes(options = {}) {
    await this.fetchData({
      endpoint: 'platform_types',
      options,
      transformData: data => data.platformTypes
    });
  }
}
