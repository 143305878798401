<template>
  <panel title="Menu Tags" no-padding>
    <b-loading v-if="isFetching" :active="isFetching" :is-full-page="false" />

    <searchable-table
      :table-data="merchantTags"
      :search="{
        placeholder: 'Search by Tag Name',
        keys: ['name']
      }"
    >
      <template #optional-actions>
        <b-button
          type="is-primary"
          icon-left="plus"
          @click="openTagModal()"
        >
          Menu Tag
        </b-button>
      </template>

      <template #default="{ filteredData }">
        <b-table
          :data="isFetching ? [] : filteredData"
          class="is-middle-aligned"
          :mobile-cards="false"
          scrollable
          data-test-id="menu-tags-table"
          :default-sort="['name', 'asc']"
          pagination-simple
          :per-page="pageLimit"
          :paginated="filteredData.length > pageLimit"
        >
          <template #empty>
            <empty-table-loader
              message="No Menu Tags Found..."
              :loading="isFetching"
            />
          </template>

          <b-table-column
            v-slot="{ row }"
            sortable
            field="name"
            label="Name"
            width="600"
          >
            <span class="is-flex align-center">
              <b-tag
                class="link"
                type="is-primary"
                :closeable="false"
                size="is-medium"
                @click="openTagModal(row)"
              >
                {{ row.name }}
              </b-tag>
              <b-button
                class="search-suggestion-btn"
                :disabled="searchSuggestionDisabled(row)"
                :loading="updatingSearchSuggestionId === row.id"
                @click="updateSearchSuggestion(row)"
              >
                <b-icon
                  v-if="updatingSearchSuggestionId !== row.id"
                  v-tippy="{
                    content: searchSuggestionDisabled(row)
                      ? 'You can only have 6 search suggestion tags'
                      : 'Toggle search suggestion',
                    placement: 'right'
                  }"
                  icon="star"
                  :pack="row.isSearchSuggestion ? 'fas' : 'far'"
                  type="is-warning"
                />
              </b-button>
            </span>
          </b-table-column>

          <b-table-column
            v-slot="{ row }"
            sortable
            centered
            field="itemCount"
            label="Items"
          >
            <span>
              {{ row.itemCount }}
            </span>
          </b-table-column>

          <b-table-column
            v-slot="{ row }"
            centered
            cell-class="actions"
            label="Actions"
            width="1"
            field="Actions"
          >
            <dropdown-menu position="bottom-end" :mobile-modal="false">
              <b-button slot="trigger" class="is-transparent" type="is-white">
                <b-icon icon="ellipsis-v" pack="far" size="is-small" />
              </b-button>

              <b-dropdown-item @click="openTagModal(row)">
                <b-icon
                  icon="pencil"
                  class="mar-r-sm"
                  size="is-small"
                />
                Edit Tag
              </b-dropdown-item>
              <hr class="dropdown-divider">
              <b-dropdown-item @click="confirmDeleteTag(row)">
                <b-icon
                  icon="trash"
                  class="mar-r-sm"
                  size="is-small"
                  type="is-danger"
                />
                Delete Tag
              </b-dropdown-item>
            </dropdown-menu>
          </b-table-column>
        </b-table>
      </template>
    </searchable-table>
  </panel>
</template>

<script>
  import MerchantMenuAttribute from '@/store/classes/MerchantMenuAttribute';
  import MenuAttributeType from '@/store/classes/MenuAttributeType';
  import MenuAttribute from '@/store/classes/MenuAttribute';
  import menuTagModal from './menu-tag-modal.vue';
  import alertModal from '@/components/globals/alert-modal.vue';

  export default {
    name: 'MenuTags',

    data: () => ({
      pageLimit: 20,
      updatingSearchSuggestionId: false
    }),

    computed: {
      isFetching() {
        return MenuAttribute.$state().fetching || MenuAttributeType.$state().fetching;
      },

      merchantTags() {
        return MerchantMenuAttribute.bySearchTagType().map(m => ({
          ...m,
          name: m.name,
          itemCount: m.menuAttribute.itemCount
        }));
      },

      countOfSearchSuggestionTags() {
        return MerchantMenuAttribute.countOfSearchSuggestionTags();
      }
    },

    created() {
      this.onCreated();
    },

    methods: {
      async onCreated() {
        try {
          await Promise.all([
            MenuAttributeType.fetchMenuAttributes(),
            MenuAttribute.fetchMenuAttributesForMerchant()
          ]);
        }
        catch (error) {
          this.$_onRequestError({
            error,
            toastOptions: {
              message: 'There was an error fetching the menu tags'
            }
          });
        }
      },

      searchSuggestionDisabled(tag) {
        return !tag.isSearchSuggestion && this.countOfSearchSuggestionTags >= 6;
      },

      async openTagModal(merchantTag) {
        if (merchantTag) {
          await MenuAttribute.fetchMenuAttribute(merchantTag.menuAttributeId);
        }

        this.$buefy.modal.open({
          parent: this,
          component: menuTagModal,
          hasModalCard: true,
          trapFocus: true,
          canCancel: false,
          customClass: 'auto-width',
          props: {
            merchantMenuAttributeId: merchantTag?.id,
            merchantTags: this.merchantTags
          }
        });
      },

      confirmDeleteTag(tag) {
        const itemCountWarning = tag.itemCount > 0
          ? ` This will delete the tag from <b>${tag.itemCount} menu item${tag.itemCount > 1 ? 's' : ''}</b>.`
          : '';
        this.$buefy.modal.open({
          parent: this,
          component: alertModal,
          hasModalCard: true,
          trapFocus: true,
          canCancel: ['escape', 'outside'],
          customClass: 'auto-width',
          props: {
            title: 'Delete Tag',
            message: `Are you sure you want to delete the <b>${tag.name} Menu Tag</b>?${itemCountWarning}`,
            showCloseButton: false,
            horizontal: true,
            icon: 'trash-alt',
            type: 'is-danger',
            buttons: [
              { text: 'Cancel' },
              { text: 'Delete', primary: true, onClick: () => this.deleteTag({ id: tag.menuAttributeId, merchantMenuAttributeId: tag.id }) }
            ]
          }
        });
      },

      async updateSearchSuggestion(tag) {
        try {
          this.updatingSearchSuggestionId = tag.id;
          await MenuAttribute.updateMenuAttribute({
            ...tag.menuAttribute,
            merchantMenuAttributeAttributes: {
              id: tag.id,
              isSearchSuggestion: !tag.isSearchSuggestion
            }
          });

          this.$_onRequestSuccess({
            toastOptions: {
              message: 'Successfully updated the tag'
            }
          });
        }
        catch (error) {
          this.$_onRequestError({
            error,
            toastOptions: {
              message: 'There was an error updating the tag'
            }
          });
        }
        finally {
          this.updatingSearchSuggestionId = false;
        }
      },

      async deleteTag({ id, merchantMenuAttributeId }) {
        try {
          await MenuAttribute.deleteMenuAttribute({ id, merchantMenuAttributeId });
          this.$_onRequestSuccess({
            toastOptions: {
              message: 'Successfully deleted the tag'
            }
          });
        }
        catch (error) {
          this.$_onRequestError({
            error,
            toastOptions: {
              message: 'There was an error deleting the tag'
            }
          });
        }
      }
    }
  };
</script>

<style lang='sass' scoped>
  ::v-deep
    .tag
      font-weight: 700
      background-color: lighten($primary, 43) !important
      color: darken($primary, 40) !important

  .search-suggestion-btn
    border: none !important

    &:hover
      background-color: transparent !important

    &:focus
      box-shadow: none !important
</style>
