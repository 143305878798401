<template>
  <section>
    <b-loading
      v-if="isLoading"
      :is-full-page="false"
      active
    />
    <b-tabs
      v-else
      v-model="activeTab"
      type="is-toggle-rounded"
      animation="tab-change-alt"
    >
      <b-tab-item
        v-for="tab in tabs"
        :key="tab.id"
        :label="tab.label"
      >
        <content-faq-list :merchant-faq-service-type-id="tab.id" />
      </b-tab-item>
    </b-tabs>
  </section>
</template>

<script>
  import merchantMixin from '@/mixins/merchant';
  import MerchantFaqHeader from '@/store/classes/MerchantFaqHeader';
  import contentFaqList from '../form/content-faq-list.vue';

  export default {
    name: 'ContentFaqTab',

    components: { contentFaqList },

    mixins: [merchantMixin],

    data() {
      return {
        isLoading: false,
        activeTab: 0
      };
    },

    computed: {
      tabs() {
        const { patEnabled, oatEnabled, orderAheadEnabled } = this.$_selectedMerchant;
        return [
          patEnabled && { id: 1, label: 'Pay @ Table' },
          oatEnabled && { id: 2, label: 'Order @ Table' },
          orderAheadEnabled && { id: 3, label: 'Order Ahead (Web)' },
          orderAheadEnabled && { id: 4, label: 'Order Ahead (Mobile)' }
        ].filter(x => x);
      }
    },

    async created() {
      this.fetchMerchantFaqHeaders();
    },

    methods: {
      async fetchMerchantFaqHeaders() {
        this.isLoading = true;

        try {
          await MerchantFaqHeader.fetchMerchantFaqHeaders(this.$_selectedMerchantId);
        }

        catch (error) {
          this.$_onRequestError({
            error,
            toastOptions: {
              message: 'There was an error fetching your FAQs'
            }
          });
        }

        finally {
          this.isLoading = false;
        }
      }
    }
  };
</script>

<style lang="sass" scoped>
  ::v-deep
    .tabs
      .is-active
        a
          background-color: $dark !important
          border-color: $dark !important
          font-weight: 600

    .tab-content
      padding: 0 !important
</style>

