<template>
  <modal-card
    title="New Merchant"
    modal-card-body-class="pad-b-none"
  >
    <merchant-form
      ref="merchantForm"
      v-slot="{ mode, errors }"
      :disable-save-button="false"
      @merchant-added="$parent.close()"
      @invalid-submit="handleInvalidSubmit"
    >
      <b-steps v-model="activeStepIndex" size="is-small">
        <b-step-item id="step-0" step="1" clickable label="Merchant Info">
          <merchant-name-inputs :mode="mode" />
          <hr>
          <merchant-provider-inputs :errors="errors" :mode="mode" />
        </b-step-item>
        <b-step-item id="step-1" step="2" clickable label="Applications">
          <merchant-application-inputs :mode="mode" />
        </b-step-item>

        <template
          slot="navigation"
          slot-scope="{previous, next}"
        >
          <div class="is-flex gap-lg justify-center mar-t-md">
            <b-button
              icon-left="chevron-left"
              :disabled="previous.disabled"
              @click.prevent="previous.action"
            >
              Back
            </b-button>
            <b-button
              icon-right="chevron-right"
              :disabled="next.disabled"
              @click.prevent="next.action"
            >
              Next
            </b-button>
          </div>
        </template>
      </b-steps>
    </merchant-form>
  </modal-card>
</template>

<script>
  import confirmModalCloseMixin from '@/mixins/confirm-modal-close';

  import Merchant from '@/store/classes/Merchant';

  import merchantForm from './form/merchant-form.vue';
  import merchantNameInputs from './form/merchant-name-inputs.vue';
  import merchantProviderInputs from './form/merchant-provider-inputs.vue';
  import merchantApplicationInputs from './form/merchant-application-inputs.vue';


  export default {
    name: 'MerchantModal',

    components: {
      merchantForm,
      merchantNameInputs,
      merchantProviderInputs,
      merchantApplicationInputs
    },

    mixins: [confirmModalCloseMixin],

    data() {
      return {
        activeStepIndex: 0
      };
    },

    destroyed() {
      Merchant.setEditableMerchant(null);
    },

    methods: {
      async handleInvalidSubmit() {
        const firstInvalidStep = this.$el.querySelector('.invalid').closest('div[id^="step"]');
        const invalidStepIndex = Number(firstInvalidStep.id.split('-')[1]);
        this.activeStepIndex = invalidStepIndex;
      }
    }
  };
</script>

<style lang="sass" scoped>
  .modal-card
    min-width: 500px
    max-width: $tablet

  ::v-deep .step-content
    padding: 0 !important
    margin-top: 2rem

</style>
