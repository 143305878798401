<template>
  <div>
    <transition name="fade-zoom-between" mode="out-in">
      <div v-if="selectedItemId" key="modifier-selection">
        <div class="pad-x-sm pad-t-sm">
          <span class="link has-text-weight-bold" @click="selectedItemId = null">
            <b-icon icon="angle-left" />
            {{ selectedItemName }}
          </span>
        </div>
        <modifier-select
          :item-id="selectedItemId"
          :selected-modifier-ids="selectedModifierIds"
          :hide-selected-toggle="hideSelectedToggle"
          :reversed="reversed"
          @modifier-select="handleModifierSelect"
        />
      </div>

      <div v-else key="category-item-selection">
        <div class="search-filter">
          <div class="search">
            <b-field
              expanded
              label-position="on-border"
              label="Search By"
            >
              <template #message>
                <template v-if="searchQuery.length >= minimumSearchTermLength">
                  {{ [
                    (criteria === 'category' || criteria === 'all') && `${tableData.length} ${tableData.length > 1 || !tableData.length ? 'Categories' : 'Category'}`,
                    (criteria === 'item' || criteria === 'all') && `${tableItemCount} Item${tableItemCount > 1 || !tableItemCount ? 's' : ''}`
                  ].filter(x => x).join(', ') }}
                </template>
                <template v-else-if="searchQuery.length">
                  Enter at least {{ minimumSearchTermLength }} characters
                </template>
              </template>
              <b-select v-model="criteria">
                <option value="all">Category & Item</option>
                <option value="category">Category</option>
                <option value="item">Item</option>
              </b-select>
              <b-input
                v-model="searchQuery"
                expanded
                placeholder="Search"
                icon="search"
                :icon-right="searchQuery ? 'times-circle' : ''"
                icon-right-clickable
                @icon-right-click="searchQuery = ''"
              />
            </b-field>
          </div>

          <b-field v-if="!hideSelectedToggle">
            <b-radio-button v-model="onlyShowSelected" type="is-dark" :native-value="false">All</b-radio-button>
            <b-radio-button v-model="onlyShowSelected" type="is-dark" :native-value="true">Selected</b-radio-button>
          </b-field>

          <b-button @click="toggleAllRows">
            <b-icon :icon="openRowIds.length ? 'compress-alt' : 'expand-alt'" size="is-medium" />
          </b-button>
        </div>

        <b-table
          ref="table"
          custom-detail-row
          :show-detail-icon="false"
          detailed
          detail-key="id"
          :opened-detailed="openRowIds"
          class="category-item-table is-middle-aligned"
          :data="tableData"
          sticky-header
          height="100%"
        >
          <template #empty>
            <empty-table-loader
              :loading="fetchingMenu"
              :has-icon="false"
              :message="emptyTableMessage"
            />
          </template>
          <b-table-column v-slot="{ row }" label="Name">
            <span class="link-inverted has-text-weight-bold" @click="toggleRow(row)">
              <template v-if="searchQuery.length >= minimumSearchTermLength && ['category', 'all'].some(val => val === criteria)">
                <span v-for="chunk in highlightSearchTerm(row.displayName)" :key="chunk.key" :class="chunk.match && 'search-term'">{{ chunk.text }}</span>
              </template>
              <template v-else>{{ row.displayName }}</template>
              <b-icon pack="far" icon="angle-right" :class="['open-indicator', {'is-open': openRows[row.id]}]" />
            </span>
          </b-table-column>

          <b-table-column numeric>
            <template v-slot:header>
              <span class="mar-r-sm has-text-grey has-text-weight-normal">Select All</span>
              <b-checkbox
                class="no-label"
                :value="isAllSelected"
                @input="handleSelectAll($event)"
              />
            </template>
            <template v-slot="{ row: category }">
              <span
                v-if="categorySelect"
                class="mar-r-sm has-text-grey"
              >
                ({{ (reversed ? isCategoryFullSelected(category) : !isCategoryFullSelected(category)) ? '0' : getCategorySelectedItemCount(category) }}/{{ getCategoryTotalItemCount(category.id) }} Items)
              </span>
              <span v-else class="mar-r-sm has-text-grey">({{ getCategorySelectedItemCount(category) }}/{{ getCategoryTotalItemCount(category.id) }} Items)</span>
              <b-checkbox
                :key="category.id"
                class="no-label"
                :disabled="!category.menuItems.length"
                :true-value="!reversed"
                :false-value="reversed"
                :indeterminate="isCategoryPartialSelected(category)"
                :value="isCategoryFullSelected(category)"
                @input="handleSelectCategory($event, category)"
              />
            </template>
          </b-table-column>

          <template v-slot:detail="{ row: category }">
            <template v-if="!category.menuItems.length">
              <tr>
                <td colspan="2">
                  <span class="pad-l has-text-grey">No Items</span>
                </td>
              </tr>
            </template>

            <template v-else>
              <template v-for="item in category.menuItems">
                <tr :key="`${category.id}-${item.id}`">
                  <td>
                    <p class="pad-l" :class="(categorySelect && (reversed ? isCategoryFullSelected(category) : !isCategoryFullSelected(category))) && 'has-text-grey'">
                      <template v-if="searchQuery.length >= minimumSearchTermLength && ['item', 'all'].some(val => val === criteria)">
                        <span v-for="chunk in highlightSearchTerm(item.displayName)" :key="chunk.key" :class="chunk.match && 'search-term'">{{ chunk.text }}</span>
                      </template>
                      <template v-else>{{ item.displayName }}</template>
                    </p>
                  </td>
                  <td class="has-text-right">
                    <b-checkbox
                      class="no-label"
                      :disabled="categorySelect && (reversed ? isCategoryFullSelected(category) : !isCategoryFullSelected(category))"
                      :true-value="!reversed"
                      :false-value="reversed"
                      :value="categorySelect ? isCategoryItemSelected(category.id, item.id) : isItemSelected(item.id)"
                      @input="handleSelectItem($event, item.id)"
                    />
                  </td>
                </tr>
                <tr v-if="modifierSelect && item.hasModifiers" :key="`${category.id}-${item.id}-modifiers`" class="has-background-white-ter">
                  <td class="pad-y-xs" colspan="2">
                    <p
                      :class="[
                        'is-flex justify-between is-paddingless is-marginless',
                        {
                          link: reversed ? !(categorySelect ? isCategoryItemSelected(category.id, item.id) : isItemSelected(item.id))
                            : (categorySelect ? isCategoryItemSelected(category.id, item.id) : isItemSelected(item.id)),
                          'has-text-grey': (reversed ? isItemSelected(item.id) : !isItemSelected(item.id))
                            || categorySelect && (reversed ? isCategoryFullSelected(category) : !isCategoryFullSelected(category))
                        }
                      ]"
                      @click="(reversed ? !(categorySelect ? isCategoryItemSelected(category.id, item.id) : isItemSelected(item.id))
                        : (categorySelect ? isCategoryItemSelected(category.id, item.id) : isItemSelected(item.id))) && viewModifiers(item)"
                    >
                      <span class="pad-l-lg">View Modifiers</span>
                      <b-icon pack="far" icon="angle-right" />
                    </p>
                  </td>
                </tr>
              </template>
            </template>
          </template>
        </b-table>
      </div>
    </transition>
  </div>
</template>

<script>
  import highlightWords from 'highlight-words';
  import { capitalCase } from 'change-case';
  import { fetchSimplifiedMenu } from '@/api/menu';
  import modifierSelect from './modifier-select.vue';

  import merchantMixin from '@/mixins/merchant';


  export default {
    name: 'MenuResourceSelect',

    components: { modifierSelect },

    mixins: [merchantMixin],

    props: {
      selectedItemIds: {
        type: Array,
        default: () => []
      },

      selectedCategoryIds: {
        type: Array,
        default: () => []
      },

      selectedModifierIds: {
        type: Array,
        default: () => []
      },

      menuTypeId: {
        type: Number,
        default: null
      },

      reversed: {
        type: Boolean,
        default: false
      },

      hideSelectedToggle: {
        type: Boolean,
        default: false
      },

      includedInMenuType: {
        type: Boolean,
        default: false
      },

      categorySelect: {
        type: Boolean,
        default: false
      },

      modifierSelect: {
        type: Boolean,
        default: false
      }
    },

    data() {
      return {
        fullMenu: [],
        fetchingMenu: false,
        onlyShowSelected: false,
        openRows: {},
        searchQuery: '',
        criteria: 'all',
        minimumSearchTermLength: 3,
        selectedItemId: null,
        selectedItemName: null,
        capitalCase
      };
    },

    computed: {
      tableData() {
        const data = this.onlyShowSelected ? this.selectedMenu : this.sortedMenu;
        return this.searchQuery.length < this.minimumSearchTermLength ? data : this.searchMenu(data);
      },

      sortedMenu() {
        const sortedMenu = [...this.fullMenu]
          .map((category) => {
            const sortedMenuItems = [...category.menuItems].sort((a, b) => ((a.sortOrder < b.sortOrder) ? -1 : 1));
            return { ...category, menuItems: sortedMenuItems };
          })
          .sort((a, b) => ((a.sortOrder < b.sortOrder) ? -1 : 1));

        return sortedMenu.map((category) => {
          let { menuItems } = category;
          if (this.menuTypeId) {
            menuItems = category.menuItems.filter(item => (this.includedInMenuType
              ? item.menuTypeIds.includes(this.menuTypeId)
              : !item.menuTypeIds.includes(this.menuTypeId)
            ));
          }
          return {
            ...category,
            menuItems
          };
        }).filter(category => category.menuItems.length);
      },

      selectedMenu() {
        const isSelected = ({ id }) => (this.reversed ? !this.isItemSelected(id) : this.isItemSelected(id));

        return this.$clone(this.sortedMenu).reduce((array, category) => {
          const selectedItems = category.menuItems.filter(isSelected);
          // need to account for when categories are explicitly selected
          if (selectedItems.length) {
            array.push({ ...category, menuItems: selectedItems });
          }
          return array;
        }, []);
      },

      selectedCategoryCount() {
        return this.selectedMenu.length;
      },

      selectedItemCount() {
        return [...this.selectedMenu].reduce((count, category) => count + category.menuItems.length, 0);
      },

      totalItemCount() {
        return [...this.fullMenu].reduce((total, category) => total + category.menuItems.length, 0);
      },

      tableItemCount() {
        return [...this.tableData].reduce((total, category) => total + category.menuItems.length, 0);
      },

      allUniqueTableItemIds() {
        return [...this.tableData].reduce((array, category) => [...new Set([...array, ...category.menuItems.map(mi => mi.id)])], []);
      },

      isAllSelected() {
        const allItemsSelected = this.reversed
          ? this.allUniqueTableItemIds.every(id => !this.isItemSelected(id))
          : this.allUniqueTableItemIds.every(id => this.isItemSelected(id));

        const allCategoriesSelected = this.reversed
          ? !this.selectedCategoryIds.length
          : this.selectedCategoryIds.length === this.tableData.length;

        return this.categorySelect
          ? allItemsSelected && allCategoriesSelected
          : allItemsSelected;
      },

      openRowIds() {
        return Object.entries(this.openRows).reduce((openIds, [id, isOpen]) => {
          if (isOpen) openIds.push(Number(id));
          return openIds;
        }, []);
      },

      emptyTableMessage() {
        let resources = '';

        switch (this.criteria) {
          case 'category':
            resources = 'Categories';
            break;

          case 'item':
            resources = 'Items';
            break;

          default:
            resources = 'Categories or Items';
            break;
        }
        return `No ${resources} ${this.onlyShowSelected ? 'Selected' : 'Found'}`;
      }
    },

    watch: {
      selectedItemCount: 'emitCounts',
      searchQuery: 'handleSearchQueryChange',
      criteria: 'handleCriteriaChange',
      menuTypeId: 'handleMenuTypeChange'
    },

    async created() {
      await Promise.all([
        this.fetchAndSetSimplifiedMenuData()
      ]);
      this.emitCounts();
    },

    methods: {
      async fetchAndSetSimplifiedMenuData() {
        try {
          this.fetchingMenu = true;
          const fetchedMenu = await fetchSimplifiedMenu(this.$_selectedMerchantId);
          if (fetchedMenu) this.fullMenu = fetchedMenu;
        }
        catch (error) {
          this.$_onRequestError({
            error,
            toastOptions: {
              message: 'There was an error fetching your menu data'
            }
          });
        }
        finally {
          this.fetchingMenu = false;
        }
      },

      searchMenu(menu) {
        return JSON.parse(JSON.stringify(menu)).filter((category) => {
          const hasMatchingItem = category.menuItems.some(mi => new RegExp(this.searchQuery, 'ig').test(mi.displayName));
          const isMatchingCategory = new RegExp(this.searchQuery, 'ig').test(category.displayName);

          return (this.criteria === 'item' && hasMatchingItem)
            || (this.criteria === 'category' && isMatchingCategory)
            || (this.criteria === 'all' && (hasMatchingItem || isMatchingCategory));
        }).map((category) => {
          if (this.criteria === 'category') {
            return category;
          }
          return {
            ...category,
            menuItems: [...category.menuItems].filter(item => new RegExp(this.searchQuery, 'ig').test(item.displayName))
          };
        });
      },

      highlightSearchTerm(string) {
        return highlightWords({ text: string, query: this.searchQuery, matchExactly: true });
      },

      handleSearchQueryChange(newQuery) {
        const shouldOpenRows = newQuery.length >= this.minimumSearchTermLength
          && ['all', 'item'].some(val => val === this.criteria);

        if (shouldOpenRows) this.openAllRows();
        else this.closeAllRows();
      },

      handleCriteriaChange() {
        this.handleSearchQueryChange(this.searchQuery);
      },

      handleMenuTypeChange() {
        this.selectedItemId = null;
        this.selectedItemName = null;
        this.openRows = {};
      },

      viewModifiers(item) {
        this.selectedItemId = item.id;
        this.selectedItemName = item.displayName;
      },


      // open-close row logic
      // //// //////// ////////////////

      toggleRow(row) {
        if (this.openRows[row.id]) this.closeRow(row);
        else this.openRow(row);
      },

      openRow(row) {
        this.$set(this.openRows, row.id, true);
      },

      closeRow(row) {
        this.$set(this.openRows, row.id, false);
      },

      closeAllRows() {
        this.openRows = {};
      },

      openAllRows() {
        this.openRows = this.fullMenu.reduce((obj, { id }) => ({ ...obj, [id]: true }), {});
      },

      toggleAllRows() {
        if (this.openRowIds.length) this.closeAllRows();
        else this.openAllRows();
      },


      // checkbox logic
      // //// //////// ////////////////

      isCategoryPartialSelected(category) {
        if (this.categorySelect) return false;

        return !this.isCategoryFullSelected(category)
          && category.menuItems.some(mi => this.isItemSelected(mi.id));
      },

      isCategoryFullSelected(category) {
        return this.categorySelect
          ? this.selectedCategoryIds.includes(category.id)
          : !!category.menuItems.length && category.menuItems.every(mi => this.isItemSelected(mi.id));
      },

      isItemSelected(itemId) {
        return this.selectedItemIds.includes(itemId);
      },

      isCategoryItemSelected(categoryId, itemId) {
        return this.reversed
          ? this.selectedCategoryIds.includes(categoryId) || this.isItemSelected(itemId)
          : this.selectedCategoryIds.includes(categoryId) && this.isItemSelected(itemId);
      },

      handleSelectItem(isChecked, itemId) {
        const newValue = new Set(this.selectedItemIds);

        if (isChecked) newValue.add(itemId);
        else newValue.delete(itemId);

        this.$emit('item-selected', [...newValue]);
      },

      handleSelectCategory(isChecked, category) {
        const newItemIds = new Set(this.selectedItemIds);
        const newCategoryIds = new Set(this.selectedCategoryIds);
        const categoryItemIds = category.menuItems.map(mi => mi.id);

        if (this.categorySelect) {
          if (isChecked) {
            newCategoryIds.add(category.id);
            if (!this.reversed) categoryItemIds.forEach(itemId => newItemIds.add(itemId));
          }
          else {
            newCategoryIds.delete(category.id);
            if (this.reversed) categoryItemIds.forEach(itemId => newItemIds.delete(itemId));
          }

          this.$emit('category-selected', [...newCategoryIds]);
          this.$emit('item-selected', [...newItemIds]);
        }

        else {
          if (isChecked) categoryItemIds.forEach(itemId => newItemIds.add(itemId));
          else categoryItemIds.forEach(itemId => newItemIds.delete(itemId));

          this.$emit('item-selected', [...newItemIds]);
        }
      },

      handleSelectAll(isChecked) {
        const newItemIds = new Set(this.selectedItemIds);
        const newCategoryIds = new Set(this.selectedCategoryIds);

        if (this.reversed) isChecked = !isChecked;

        if (this.categorySelect) {
          if (isChecked) this.tableData.forEach(category => newCategoryIds.add(category.id));
          else this.tableData.forEach(category => newCategoryIds.delete(category.id));
          this.$emit('category-selected', [...newCategoryIds]);
        }

        if (isChecked) this.allUniqueTableItemIds.forEach(id => newItemIds.add(id));
        else this.allUniqueTableItemIds.forEach(id => newItemIds.delete(id));
        this.$emit('item-selected', [...newItemIds]);
      },

      handleModifierSelect({ ids, selected }) {
        const newValue = new Set(this.selectedModifierIds);

        ids.forEach((id) => {
          if (selected) newValue.add(id);
          else newValue.delete(id);
        });

        this.$emit('modifier-selected', [...newValue]);
      },


      // count getters
      // //// //////// ////////////////

      getCategorySelectedItemCount(category) {
        return category.menuItems.reduce((total, { id }) => {
          if (this.reversed ? !this.isItemSelected(id) : this.isItemSelected(id)) {
            return total + 1;
          }
          else {
            return total;
          }
        }, 0);
      },

      getCategoryTotalItemCount(categoryId) {
        return this.tableData.find(c => c.id === categoryId).menuItems.length;
      },

      emitCounts(newItemCount = this.selectedItemCount) {
        this.$emit('select', {
          categories: `${this.selectedCategoryCount} / ${this.sortedMenu.length}`,
          items: `${newItemCount} / ${this.totalItemCount}`
        });
      }
    }
  };
</script>

<style lang="sass" scoped>
  ::v-deep th
    top: 73px !important

  .search-filter
    display: flex
    gap: $size-normal
    padding: $size-normal $size-medium
    border-bottom: 1px solid $grey-lighter
    position: sticky
    top: 0
    z-index: 1
    background-color: $white

    > *
      margin: 0 !important

    .search
      flex: 1

  .open-indicator
    transition: transform 200ms
    &.is-open
      transform: rotate(90deg)
</style>
