<template>
  <b-modal
    :active="isVisible"
    :destroy-on-hide="false"
    :width="800"
    :can-cancel="['outside', 'x']"
    @close="closeSearch"
  >
    <b-loading :active="gsm.isLoading || isSaving" :is-full-page="false" />
    <!-- Search Input -->
    <b-input
      ref="searchInput"
      v-model="searchQuery"
      placeholder="Search..."
      expanded
      icon="search"
      type="text"
      class="search-input"
      :icon-right="searchQuery ? 'times' : ''"
      icon-right-clickable
      icon-right-tooltip="Clear"
      @icon-right-click="clearSearch"
      @input="debouncedSearch"
      @keydown.native.enter.prevent="search"
    />

    <!-- Recent Searches -->
    <div v-if="!searchQuery && gsm.searchHistory.length" class="has-background-white pad-sm">
      <p class="has-text-weight-bold mar-b-xs">Recent Searches</p>
      <div
        v-for="(query, index) in gsm.searchHistory"
        :key="index"
        class="search-history-item is-flex align-center has-hover-background-grey-lightest pad-xs has-cursor-pointer"
        @click="setSearchQuery(query)"
      >
        <b-icon
          class=" has-text-grey-light"
          icon="search"
        />
        <span class="pad-l-xs">{{ query }}</span>
        <b-button
          class="clear-history-button mar-l-auto is-transparent has-border-none justify-end"
          icon-right="times"
          size="is-small"
          @click.stop="gsm.removeSearchHistoryItem(index)"
        />
      </div>
    </div>

    <!-- Search Results -->
    <div v-if="filteredSearchResults.length" class="search-results overflow-y-auto">
      <div
        v-for="result in filteredSearchResults"
        :key="result.id"
        class="search-result pad-sm has-border-bottom has-border-grey-lighter dist-y-xs has-hover-background-grey-lightest has-cursor-pointer"
        :class="{ 'is-disabled': isCurrentRoute(result) }"
        tabindex="0"
        :disabled="isCurrentRoute(result)"
        @click="navigateToResult(result)"
        @keydown.enter.prevent="navigateToResult(result)"
      >
        <!-- Header -->
        <header data-id="result-header" class="is-flex align-center gap-x-xs has-text-weight-bold">
          <b-icon :icon="result.icon" size="is-small" type="is-grey-light mar-r-xxs" />
          <span class="result-title" v-html="highlightMatch(result.title, searchQuery)" />

          <!-- subOption -->
          <div v-if="result.subOption" class="result-inputs is-flex align-center gap-x-xs">
            <span v-if="gsm.isMerchantOptionType(result)" class="has-text-weight-light has-text-grey" style="font-size: 0.9rem;">
              ({{ gsm.getMerchantOptionsTabName(result.subOption) }})
            </span>
            <b-tag
              type="is-grey-light"
              class="result-suboption"
            >
              <span v-html="highlightMatch(result.subOption, searchQuery)" />
            </b-tag>

            <!-- Portal Feature Switch -->
            <transition name="fade-zoom">
              <b-switch
                v-if="isSecretModeActive && shouldShowPortalFeatureToggle(result)"
                v-model="gsm.portalFeatures[gsm.getMerchantFeatureKey(result.subOption)]"
                type="is-primary"
                size="is-small"
                class="has-cursor-cell"
                @input="value => updateMerchantFeatures(result.subOption, value)"
                @click.native.stop
              />

              <!-- Store Core Config Switch -->
              <div v-if="isStoreLocationAttribute(result) && isSecretModeActive" class="is-flex align-center gap-x-xs">
                <b-switch
                  v-if="getStoreAttributeValue(result.subOption) !== undefined"
                  :value="getStoreAttributeValue(result.subOption)"
                  type="is-primary"
                  size="is-small"
                  class="has-cursor-cell"
                  :disabled="!selectedStoreId"
                  @input="value => handleStoreUpdate(result.subOption, value)"
                  @click.native.stop
                />
                <span v-else class="has-text-grey-light has-text-weight-normal">(No value)</span>
              </div>

              <!-- App Setting Resource Input -->
              <div v-if="gsm.isAppSettingResource(result) && isSecretModeActive">
                <global-search-settings-input
                  v-if="gsm.getAppSettingResourceValue(result.subOption) !== undefined"
                  :value="gsm.getAppSettingResourceValue(result.subOption)"
                  :sub-option="result.subOption"
                  :app-setting-resource-keys="gsm.appSettingResourceKeys"
                  :resource-type="gsm.getAppSettingResourceType(result.subOption)"
                  @update="updateAppSettingResources"
                />
                <span v-else class="has-text-grey-light has-text-weight-normal">(No value)</span>
              </div>
            </transition>
          </div>
        </header>
        <p class="result-description" v-html="highlightMatch(result.description, searchQuery)" />
        <p class="is-size-7 has-text-grey is-flex align-center">
          <span v-html="highlightMatch(buildBreadcrumb(result), searchQuery)" />
          <b-icon v-if="result.subOption" icon="right-from-line" class="has-text-grey-light mar-x-xxs" size="is-small" />
          <span v-if="result.subOption" v-html="highlightMatch(result.subOption, searchQuery)" />
        </p>
      </div>
    </div>

    <div v-else-if="searchQuery" class="no-results text-align-center pad-md has-text-grey-light has-background-white">
      <p>No results found for "{{ searchQuery }}"</p>
    </div>

    <!-- FOOTER -->
    <div class="footer is-flex align-center justify-between pad-sm has-background-white-ter">
      <div class="is-flex align-center gap-x-sm">
        <!-- Secret Mode Toggle -->
        <div class="secret-button-container has-cursor-pointer" @click="toggleSecretMode">
          <transition name="rotate" mode="out-in">
            <b-icon
              v-if="isCardfreeAdmin"
              :key="isSecretModeActive"
              :class="[isSecretModeActive ? 'has-text-primary' : 'has-text-grey-light']"
              :icon="isSecretModeActive ? 'user-bounty-hunter' : 'user-secret'"
              size="is-small"
            />
          </transition>
        </div>
        <span class="results-count">
          {{ filteredSearchResults.length }} result{{ filteredSearchResults.length !== 1 ? 's' : '' }}
        </span>
      </div>
      <div class="is-flex align-center gap-x-sm">
        <!-- App Code Type Selector (only visible in secret mode) -->
        <transition name="fade-zoom-between">
          <div v-if="isCardfreeAdmin && isSecretModeActive" class="is-flex align-center gap-x-sm">
            <b-field custom-class="is-small" label-position="on-border" label="App Type">
              <b-select
                v-if="merchantAppCodeTypes.length"
                :value="gsm.selectedAppCodeTypeId"
                size="is-small"
                @input="handleAppCodeTypeChange"
              >
                <option
                  v-for="appCodeType in merchantAppCodeTypes"
                  :key="appCodeType.id"
                  :value="String(appCodeType.id)"
                >
                  {{ appCodeType.name }}
                </option>
              </b-select>
            </b-field>
          </div>
        </transition>

        <!-- Store Select -->
        <div v-if="!hideLocationSelector" class="location-selector">
          <b-field label="Location" label-position="on-border" custom-class="is-small">
            <b-select
              ref="storeSelect"
              :value="selectedStoreId"
              icon="map-marker-alt"
              size="is-small"
              :disabled="!stores.length"
              placeholder="Select Location"
              @input="handleStoreChange"
            >
              <option v-for="store in stores" :key="store.storeId" :value="store.storeId">
                {{ store.description }}
              </option>
            </b-select>
          </b-field>
        </div>

      </div>
    </div>
  </b-modal>
</template>

<script>
  import debounce from 'lodash.debounce';
  import { mapGetters } from 'vuex';
  import merchantMixin from '@/mixins/merchant';
  import globalSearchService from '@/services/global-search';
  import gsm from '@/services/global-search-manager';
  import storage from '@/services/storage';
  import roleTypes from '@/constants/roleTypes';

  // stores
  import AppCodeType from '@/store/classes/AppCodeType';
  import MerchantAppSetting from '@/store/classes/MerchantAppSetting';
  import MerchantAppSettingResource from '@/store/classes/MerchantAppSettingResource';
  import StoreAttribute from '@/store/classes/StoreAttribute';
  import Store from '@/store/classes/Store';

  // components
  import globalSearchSettingsInput from '@/components/global-search/global-search-settings-input.vue';

  export default {
    name: 'GlobalSearch',

    components: {
      globalSearchSettingsInput
    },

    mixins: [merchantMixin],

    props: {
      isVisible: {
        type: Boolean,
        default: false
      }
    },

    data() {
      return {
        gsm,
        searchQuery: '',
        searchResults: [],
        selectedStoreId: null,
        storeAttributeForm: {},
        storeIdMap: {},
        isSaving: false,
        isSecretModeActive: true,
        otherPortalFeatures: ['Order Dashboard Columns', 'SSO Providers', 'Microsoft Single Sign-On'],
        storeCoreConfigs: [
          'POS Type',
          'Merchant Location ID',
          'Payment Location ID',
          'POS Location ID',
          'POS Employee ID',
          'POS Revenue Center',
          'POS Third Party Tender Type Id',
          'Loyalty Provider Location Id',
          'Automated Price Import'
        ]
      };
    },

    computed: {
      ...mapGetters('session', ['currentUser', 'isCardfreeAdmin']),

      showSubOptionInputs() {
        return this.isCardfreeAdmin && this.isSecretModeActive;
      },

      hideLocationSelector() {
        return [
          roleTypes.MENU_MANAGER,
          roleTypes.GUEST_SUPPORT,
          roleTypes.MARKETING_MANAGER,
          roleTypes.ANALYTICS
        ].some(role => this.currentUser?.roles?.some(roleObj => roleObj.name === role));
      },

      filteredSearchResults() {
        return this.searchResults.filter((result) => {
          if (!this.stores.length && result.parent === 'storeConfiguration') {
            return false;
          }
          return this.isCardfreeAdmin || (!this.hideConditions[result.id] && this.canViewPage(result, this.currentUser));
        });
      },

      currentMerchantId() {
        return this.$_selectedMerchant.id;
      },

      hideConditions() {
        return this.gsm.hideConditions(this.$can, this.$_selectedMerchant);
      },

      /* -------------------------- STORES -------------------------- */

      stores() {
        return Store.all();
      },

      storeAttributes() {
        return StoreAttribute.all();
      },

      selectedStore() {
        return Store.find(this.selectedStoreId);
      },

      selectedStoreName() {
        return this.gsm.selectedStore?.description || 'Select Store';
      },

      isSameStore() {
        return String(this.$route.params?.storeId) === String(this.gsm.selectedStoreId);
      },

      /* -------------------------- APP SETTING RESOURCES -------------------------- */

      appSettingResourceKeys() {
        return MerchantAppSettingResource.$state().masrKeys;
      },

      merchantAppCodeTypes() {
        const merchantAppCodeTypeIds = MerchantAppSetting.all().map(mas => mas.appCodeTypeId);
        return AppCodeType.query().whereIdIn(merchantAppCodeTypeIds).get();
      },

      selectedMerchantAppSetting() {
        return MerchantAppSetting.query().where('appCodeTypeId', Number(this.gsm.selectedAppCodeTypeId)).first();
      },

      selectedAppSettingResources() {
        return MerchantAppSettingResource.query().where('merchantAppSettingsId', this.selectedMerchantAppSetting?.id).get();
      },

      selectedAppCodeTypeName() {
        const selectedAppCodeType = this.merchantAppCodeTypes.find(
          type => String(type.id) === this.gsm.selectedAppCodeTypeId
        );
        return selectedAppCodeType ? selectedAppCodeType.name : '';
      }
    },

    watch: {
      isVisible(newValue) {
        if (newValue) {
          this.$nextTick(() => {
            if (this.$refs.searchInput) {
              this.$refs.searchInput.focus();
            }
          });
        }
        else {
          this.clearSearch();
        }
      },
      searchQuery: {
        immediate: true,
        handler(newValue) {
          if (newValue.trim().length < 2) {
            this.searchResults = [];
          }
        }
      },
      currentMerchantId: {
        immediate: true,
        async handler(newValue, oldValue) {
          if (oldValue && newValue !== oldValue && this.isCardfreeAdmin) {
            await this.onCreated();
          }
        }
      }
    },

    created() {
      this.onCreated();
      document.addEventListener('keydown', this.handleEscKeyPress);
    },

    destroyed() {
      document.removeEventListener('keydown', this.handleEscKeyPress);
    },

    methods: {
      async onCreated() {
        await this.gsm.initialize(this.currentMerchantId, this.isCardfreeAdmin);

        this.storeIdMap = storage.local.get('merchantStoreIdMap') || {};
        this.selectedStoreId = this.storeIdMap[this.currentMerchantId] || null;
        this.syncStoreAttributes();

        if (this.isCardfreeAdmin) {
          this.gsm.syncAppSettingResources(this.appSettingResourceKeys, this.selectedAppSettingResources);
          this.gsm.syncPortalFeatures();
          this.gsm.syncMerchantOptionTypes();
        }

        this.gsm.loadSearchHistory();
        this.isSecretModeActive = storage.local.get('isSecretModeActive') || false;
      },

      handleEscKeyPress(event) {
        if (event.key === 'Escape') {
          if (!this.searchQuery || document.activeElement !== this.$refs.searchInput.$el.querySelector('input')) {
            this.closeSearch();
          }
          this.clearSearch();
        }
      },

      shouldShowPortalFeatureToggle(result) {
        return this.gsm.isPortalFeature(result) && !this.otherPortalFeatures.includes(result.subOption);
      },

      /* -------------------------- SYNCERS -------------------------- */

      syncStoreAttributes() {
        if (!this.selectedStore) return;

        this.storeAttributeForm = this.storeAttributes.reduce((acc, attr) => {
          const mappedAttribute = this.selectedStore.storeMappingAttributes.find(sma => sma.code === attr.code);
          acc[attr.code] = {
            ...attr,
            isActive: mappedAttribute?.isActive
          };
          return acc;
        }, {});

        globalSearchService.populateStoreCoreConfigKeys(this.storeCoreConfigs);
        globalSearchService.populateStoreLocationAttributeKeys(this.storeAttributes);
      },

      /* --------------------- RESOURCE TYPE CHECKS --------------------- */

      isStoreConfiguration(result) {
        return this.isStoreCoreConfig(result) || this.isStoreLocationAttribute(result);
      },

      isStoreCoreConfig(result) {
        return result.resourceKey === 'storeCoreConfigurations';
      },

      isStoreLocationAttribute(result) {
        return result.resourceKey === 'storeLocationAttributes';
      },

      /* -------------------------- SEARCH & NAVIGATION -------------------------- */

      debouncedSearch: debounce(function () { // eslint-disable-line
        if (this.searchQuery.trim().length >= 2) {
          this.search();
        }
      }, 333),

      search() {
        this.searchResults = globalSearchService.search(this.searchQuery);

        if (this.filteredSearchResults.length) {
          this.gsm.addToSearchHistory(this.searchQuery);
        }
      },

      setSearchQuery(query) {
        this.searchQuery = query;
        this.gsm.addToSearchHistory(query);
        this.search();
      },

      getQueryHighlight(result) {
        const isOtherPortalFeature = this.otherPortalFeatures.includes(result.subOption);

        switch (result.resourceKey) {
          case 'appSettingResources':
            return this.gsm.getAppSettingResourceKey(result.subOption);
          case 'merchantFeatures':
            return isOtherPortalFeature ? this.gsm.getOtherPortalFeatureKey(result.subOption) : this.gsm.getMerchantFeatureKey(result.subOption);
          case 'storeLocationAttributes':
            return this.getStoreAttribute(result.subOption)?.code;
          case 'merchantOptions':
            return this.gsm.getMerchantOptionType(result.subOption)?.name;
          default:
            return null;
        }
      },

      async navigateToResult(result) {
        const routeConfig = {
          name: result.parent || result.name,
          params: result.params || {},
          query: result.query || {}
        };

        if (this.isCurrentRoute(result)) {
          this.showToast('You are already on this page.', 'is-info');
          return;
        }

        if (result.subOption) {
          routeConfig.query.highlighted = this.getQueryHighlight(result);
        }

        if (result.category?.startsWith('Location')) {
          await this.handleStoreNavigation(result, routeConfig);
        }
        else {
          if (result.resourceKey === 'appSettingResources') {
            routeConfig.params.appCodeTypeId = this.gsm.selectedAppCodeTypeId;
          }

          if (result.resourceKey === 'merchantOptions') {
            const merchantOptionType = this.gsm.getMerchantOptionType(result.subOption);
            routeConfig.params = {
              ...routeConfig.params,
              selectedMerchantOptionTable: merchantOptionType?.tableName,
              selectedMerchantOptionTypeId: merchantOptionType?.id
            };
          }

          await this.performNavigation(routeConfig);
        }
      },

      async performNavigation(routeConfig) {
        try {
          await this.$router.push(routeConfig);
          this.closeSearch();
        }
        catch (error) {
          console.error('Navigation error:', error);
          this.showToast('An error occurred while navigating. Please try again.', 'is-danger');
        }
      },

      /* -------------------------- NAVIGATION HELPERS -------------------------- */

      canViewPage(result, currentUser) {
        const roleNamesPermitted = result.permissions?.map(permission => roleTypes[permission]);
        return currentUser?.roles.some(roleObj => roleNamesPermitted.includes(roleObj.name));
      },

      isSameRouteName(result) {
        return result.name === this.$route.name || result.parent === this.$route.name;
      },

      isSameTab(result) {
        const currentTabName = this.$route.params?.tabName;
        const resultTabName = result.params?.tabName;
        return currentTabName === resultTabName || (!currentTabName && !resultTabName);
      },

      isSameSubTab(result) {
        const currentSubTabName = this.$route.params?.subTabName;
        const resultSubTabName = result.params?.subTabName;
        return currentSubTabName === resultSubTabName || (!currentSubTabName && !resultSubTabName);
      },

      isCurrentRoute(result) {
        if (!this.$route || !result) return false;

        return this.isSameRouteName(result)
          && this.isSameTab(result)
          && this.isSameSubTab(result)
          && this.isSameStore;
      },

      async handleStoreNavigation(result, routeConfig) {
        if (!this.selectedStore) {
          this.promptStoreSelection();
          return;
        }

        routeConfig.params = {
          ...routeConfig.params,
          store: this.selectedStore,
          storeId: this.selectedStoreId,
          isStoreTipping: result.name === 'tippingOptions'
        };

        await this.performNavigation(routeConfig);
      },

      promptStoreSelection() {
        this.$buefy.toast.open({
          message: 'Please select a location first',
          type: 'is-warning',
          duration: 3000
        });

        this.triggerPulseAnimation();
      },

      triggerPulseAnimation() {
        const element = this.$refs.storeSelect.$el.querySelector('select');
        element.classList.remove('highlight-element');

        this.$nextTick(() => {
          if (element) {
            element.classList.add('highlight-element');
            element.focus();
            setTimeout(() => {
              element.classList.remove('highlight-element');
            }, 2000);
          }
        });
      },
      /* -------------------------- HANDLERS -------------------------- */

      handleAppCodeTypeChange(appCodeTypeId) {
        this.gsm.setSelectedAppCodeTypeId(appCodeTypeId);
        this.gsm.syncAppSettingResources(this.appSettingResourceKeys, this.selectedAppSettingResources);
        if (this.filteredSearchResults.length) {
          this.search();
        }
      },

      handleStoreChange(storeId) {
        this.selectedStoreId = storeId;
        this.storeIdMap[this.currentMerchantId] = storeId;
        storage.local.set('merchantStoreIdMap', this.storeIdMap);
        this.syncStoreAttributes();
        if (this.filteredSearchResults.length) {
          this.search();
        }
      },

      /* ----------------------------- GETTERS ----------------------------- */

      getStoreAttributeValue(subOption) {
        const code = this.storeAttributes.find(attr => attr.name === subOption)?.code;
        return this.storeAttributeForm[code]?.isActive;
      },

      getStoreAttribute(subOption) {
        const storeAttribute = this.storeAttributes.find(attr => attr.name === subOption);
        return this.storeAttributeForm[storeAttribute?.code];
      },

      /* ------------------------ UPDATE HANDLERS ------------------------ */

      async updateAppSettingResources({ subOption, value }) {
        try {
          this.isSaving = true;
          const key = this.gsm.getAppSettingResourceKey(subOption);
          await this.gsm.updateAppSettingResources({
            key,
            value,
            merchantAppSetting: this.selectedMerchantAppSetting
          });

          let statusMessage = 'updated';
          if (typeof value === 'boolean') {
            statusMessage = value ? 'enabled' : 'disabled';
          }

          this.$_onRequestSuccess({
            toastOptions: { message: `App Setting '${subOption}' has been ${statusMessage}` }
          });
        }
        catch (error) {
          this.$_onRequestError({
            error,
            toastOptions: {
              message: `Failed to update App Setting '${subOption}'`
            }
          });
        }
        finally {
          this.isSaving = false;
        }
      },

      async updateMerchantFeatures(subOption, newValue) {
        try {
          this.isSaving = true;

          const featureKey = this.gsm.getMerchantFeatureKey(subOption);

          await this.gsm.updateMerchantFeatures({ featureKey, subOption, newValue });

          this.$_onRequestSuccess({
            toastOptions: {
              message: `Portal Feature '${subOption}' has been ${newValue ? 'enabled' : 'disabled'}`
            }
          });
        }
        catch (error) {
          this.$_onRequestError({
            error,
            toastOptions: {
              message: `Failed to update Portal Feature '${subOption}'`
            }
          });
        }
        finally {
          this.isSaving = false;
        }
      },

      async handleStoreUpdate(subOption, value) {
        try {
          this.isSaving = true;
          const storeAttribute = this.getStoreAttribute(subOption);

          if (!storeAttribute) {
            this.showToast(`Unable to update Store Attribute '${storeAttribute.name}'`, 'is-warning');
            return;
          }

          this.storeAttributeForm[storeAttribute.code].isActive = value;

          await this.gsm.updateStore(this.storeAttributeForm, this.selectedStoreId);
          this.syncStoreAttributes();

          this.$_onRequestSuccess({
            toastOptions: { message: `Store attribute '${subOption}' has been ${value ? 'enabled' : 'disabled'}` }
          });
        }
        catch (error) {
          this.$_onRequestError({
            error,
            toastOptions: { message: `Failed to update store attribute '${subOption}'` }
          });
        }
        finally {
          this.isSaving = false;
        }
      },

      /* -------------------------- UI HELPERS -------------------------- */

      closeSearch() {
        this.$emit('toggle-visibility', false);
        this.clearSearch();
      },

      clearSearch() {
        this.searchQuery = '';
        this.searchResults = [];
      },

      buildBreadcrumb(result) {
        const breadcrumb = result.breadcrumb && result.breadcrumb.length
          ? `${result.breadcrumb.join(' > ')} > ${result.title}`
          : `${result.title}`;

        if (this.gsm.isMerchantOptionType(result)) {
          return `${breadcrumb} (${this.gsm.getMerchantOptionsTabName(result.subOption)})`;
        }

        if (this.gsm.isAppSettingResource(result)) {
          return `${breadcrumb} (${this.selectedAppCodeTypeName})`;
        }

        return breadcrumb;
      },

      highlightMatch(text, query) {
        if (!query || !text) return text || '';
        if (typeof text !== 'string') {
          return String(text || '');
        }

        const regex = new RegExp(`(${query.split(' ').join('|')})`, 'gi');
        return text.replace(regex, '<span class="highlight">$1</span>');
      },

      showToast(message, type) {
        this.$buefy.toast.open({ message, type, duration: 3000 });
      },

      toggleSecretMode() {
        this.isSecretModeActive = !this.isSecretModeActive;
        storage.local.set('isSecretModeActive', this.isSecretModeActive);
      }
    }
  };
</script>

<style lang="sass" scoped>
  .search-history-item
    .clear-history-button
      &:hover
        color: $primary
        background-color: transparent !important

  .secret-button.is-secret-mode-active
    color: $primary-dark

  ::v-deep
    .highlight
      color: $primary-dark
      background-color: $primary-lighter
    .search-input .input
      border-bottom-left-radius: 0 !important
      border-bottom-right-radius: 0 !important
    .dropdown-trigger .button
      border-radius: $radius !important

  .search-results
    max-height: 500px
    background-color: $white

  .search-result
    transition: background-color 0.3s

    &.is-disabled
      cursor: default
      background-color: $grey-lightest !important

      .result-description,
      .result-breadcrumb
        opacity: 0.6

      .result-inputs
        transition: opacity 0.3s ease

        &:hover .result-inputs
          opacity: 1

        > *
          opacity: 1 !important
</style>
