<template>
  <div>
    <div
      v-for="modifierGroup in searchResults"
      :key="`modifier-group-${modifierGroup.id}`"
      :data-modifier-group-id="modifierGroup.id"
    >
      <div class="row sub-row">
        <span class="pad-y-sm">
          <b-icon
            v-if="$_featurePermissions.NESTED_MODIFIERS && modifierGroup.type"
            type="is-primary"
            class="mar-r-xs"
            pack="fad"
            :icon="modifierGroupIcons[modifierGroup.type]"
          />
          <span class="has-text-weight-bold is-size-6">{{ modifierGroup.displayName }}</span>
        </span>
        <span />
        <span class="align-center justify-end">
          <template v-if="!menuType">
            <b-button class="is-transparent" @click="openModifierGroupModal({ modifierGroupId: modifierGroup.id, type: modifierGroup.type, modifierId: modifierGroup.menuItemModifierId })">
              <b-icon v-if="$can('update', 'MenuItemModifierGroup') && $_menuPermissions.EDIT_RESOURCE" icon="pencil" size="is-small" />
              <span v-else>View</span>
            </b-button>
          </template>
        </span>
      </div>
    </div>
  </div>
</template>

<script>
  import merchantMixin from '@/mixins/merchant';
  import featurePermissionsMixin from '@/mixins/featurePermissions';
  import { modifierGroupTypes, modifierGroupPortionTemplates } from '@/constants/modifierGroups';
  import Modifier from '@/store/classes/Modifier';
  import ModifierGroup from '@/store/classes/ModifierGroup';
  import SharedModifierGroupModal from '../main-menu/modifier-groups/shared-modifier-group-modal.vue';

  export default {
    name: 'ModifierGroupSearchRow',

    mixins: [merchantMixin, featurePermissionsMixin],

    props: {
      searchResults: {
        type: Array,
        default: () => []
      },

      menuType: {
        type: Object,
        default: null
      }
    },

    data: () => ({
      modifierGroupIcons: {
        [modifierGroupTypes.PORTION]: 'chart-pie-alt',
        [modifierGroupTypes.COMBO]: 'layer-group'
      },
      modifierGroupTypes,
      modifierGroupPortionTemplates
    }),

    methods: {
      async fetchModifiersByGroupId(groupId) {
        try {
          await Modifier.fetchByModifierGroupId({ modifierGroupId: groupId });
        }
        catch (error) {
          this.$_onRequestError({
            toastOptions: {
              message: 'Unable to fetch modifiers'
            },
            error
          });
        }
      },

      async fetchModifierGroup(modifierGroupId) {
        try {
          await ModifierGroup.fetchModifierGroup({ modifierGroupId, includeMenuItemMetadata: true });
        }
        catch (error) {
          this.$_onRequestError({
            toastOptions: {
              message: 'Unable to fetch modifiers'
            },
            error
          });
        }
      },

      async openModifierGroupModal({ modifierGroupId, type, modifierId }) {
        await Promise.all([
          this.fetchModifierGroup(modifierGroupId),
          this.fetchModifiersByGroupId(modifierGroupId)
        ]);
        this.$buefy.modal.open({
          parent: this,
          component: SharedModifierGroupModal,
          hasModalCard: true,
          trapFocus: true,
          canCancel: false,
          customClass: 'auto-width',
          props: {
            modifierGroupId,
            menuItemModifierId: modifierId,
            readOnly: !this.$can('crud', 'MenuItemModifierGroupItem'),
            type
          }
        });
      }
    }
  };
</script>
