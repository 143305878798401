<template>
  <div class="order-details">
    <span
      v-if="!selectedOrder"
      class="no-order-selected has-text-grey-light"
      data-test-id="no-order-selected-message"
    >
      Please select an order to view order information
    </span>
    <template v-else>
      <div
        class="hero is-primary"
        data-test-id="order-details-hero"
      >
        <div class="hero-body has-text-centered-mobile">
          <div class="container mar-b">
            <!-- Need to know where Delivery provider comes from -->
            <p class="subtitle is-6 mar-b-xs">
              Order ready for {{ selectedOrder.delivery ? capitalCase(selectedOrder.delivery.deliveryService) : 'Customer Pickup' }} at:
            </p>
            <div class="is-flex-wrap gap justify-between">
              <h1 class="title is-2 mar-b-none">{{ selectedOrder.pickupDate | moment('h:mm A') }}</h1>
              <div class="is-flex gap-lg">
                <b-button
                  v-if="selectedOrder.expoStatus === expoStatuses.UPCOMING"
                  type="is-primary"
                  inverted
                  rounded
                  class="has-text-weight-bold"
                  data-test-id="mark-as-active-button"
                  :loading="updatingOrder"
                  @click="updateOrderExpoStatus({ orderId: selectedOrderId, newExpoStatus: expoStatuses.ACTIVE })"
                >
                  Mark as Active
                </b-button>
                <b-button
                  v-if="selectedOrder.expoStatus !== expoStatuses.PAST"
                  type="is-primary"
                  inverted
                  rounded
                  class="has-text-weight-bold"
                  data-test-id="mark-as-picked-up-button"
                  :loading="updatingOrder"
                  @click="updateOrderExpoStatus({ orderId: selectedOrderId, newExpoStatus: expoStatuses.PAST })"
                >
                  Mark as Picked Up
                </b-button>
                <b-button
                  type="is-primary"
                  inverted
                  rounded
                  class="has-text-weight-bold"
                  data-test-id="print-order-button"
                  :loading="printingOrder"
                  @click="printOrderReceipt(selectedOrderId)"
                >
                  Print Receipt
                </b-button>
                <b-button
                  type="is-primary"
                  inverted
                  rounded
                  class="has-text-weight-bold"
                  data-test-id="print-order-button"
                  :loading="printingOrder"
                  @click="printOrder(selectedOrderId)"
                >
                  Print Order
                </b-button>
              </div>
            </div>
          </div>
          <div class="is-flex justify-between align-center">
            <div class="is-flex align-center">
              <b-icon
                icon="circle-check"
                size="is-large"
              />
              <div data-test-class="order-timing-detail">
                <h6 class="has-text-white has-text-weight-bold mar-none">Placed</h6>
                <p class="mar-none">{{ selectedOrder.orderDate | moment('h:mm A') }}</p>
              </div>
            </div>
            <div class="ellipsis-container no-flex-shrink">
              <span class="extra-long-ellipsis">. . . . . . .</span>
            </div>
            <div class="is-flex align-center">
              <b-icon
                icon="circle-check"
                :pack="selectedOrder.expoStatus === expoStatuses.UPCOMING ? 'fad' : ''"
                size="is-large"
              />
              <div data-test-class="order-timing-detail">
                <h6 class="has-text-white has-text-weight-bold mar-none">Active</h6>
                <p class="mar-none">{{ activeDate | moment('h:mm A') }}</p>
              </div>
            </div>
            <div class="ellipsis-container no-flex-shrink">
              <span class="extra-long-ellipsis">. . . . . . .</span>
            </div>
            <div class="is-flex align-center">
              <b-icon
                icon="circle-check"
                :pack="selectedOrder.expoStatus !== expoStatuses.PAST ? 'fad' : ''"
                size="is-large"
              />
              <div data-test-class="order-timing-detail">
                <h6 class="has-text-white has-text-weight-bold mar-none">Done</h6>
                <p class="mar-none">{{ selectedOrder.pickupDate | moment('h:mm A') }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="expo-order-details-cards-container pad dist-y">
        <expo-order-details-card
          :title=" selectedOrder.guestFullName || 'Guest Info'"
          :subtitle="selectedOrder.ticketNumber"
          icon="user"
          start-open
          :is-printing="isPrinting"
        >
          <div class="is-grid gap col-min-200">
            <expo-order-details-row
              v-for="info in customerInfoMapping"
              :key="info.label"
              :label="info.label"
              :content="info.value"
            />
          </div>
        </expo-order-details-card>
        <expo-order-details-card
          v-if="!!selectedOrder.delivery"
          title="Driver"
          icon="car"
          start-open
          :is-printing="isPrinting"
        >
          <div class="is-grid gap col-min-200">
            <expo-order-details-row
              v-for="info in driverInfoMapping"
              :key="info.label"
              :label="info.label"
              :content="info.value"
            />
            <expo-order-details-row
              label="Delivery Progress"
            >
              <b-tag
                :type="tagTypes[orderStatus]"
                class="is-outlined"
                :class="{'is-light': isLightTagTypes.includes(orderStatus) }"
              >
                {{ capitalCase(orderStatus) }}
              </b-tag>
            </expo-order-details-row>
          </div>
        </expo-order-details-card>
        <expo-order-details-card
          title="Order Items"
          :subtitle="`(${selectedOrder.orderItemCount})`"
          icon="shopping-bag"
          :is-loading="fetchingOrderDetails"
          start-open
          :is-printing="isPrinting"
        >
          <order-details-items :order="selectedOrder" />
          <order-price-breakdown :order="selectedOrder" />
        </expo-order-details-card>
        <expo-order-details-card
          title="Payment Info"
          icon="credit-card"
          :is-loading="fetchingOrderDetails"
          :is-printing="isPrinting"
        >
          <expo-order-payment-detail :order="selectedOrder" />
        </expo-order-details-card>
      </div>
    </template>
    <transition name="fade-up">
      <expo-notification-container v-if="!orderExpoToolsActive" :order-expo-tools-active="orderExpoToolsActive" />
    </transition>
  </div>
</template>

<script>
  import { capitalCase } from 'change-case';
  import moment from 'moment-timezone';
  import { mapActions, mapGetters, mapState } from 'vuex';

  import OrderDetailsItems from '@/components/globals/order-details-items.vue';
  import OrderPriceBreakdown from '@/components/globals/order-price-breakdown.vue';

  import { expoStatuses } from '@/constants/expo';

  import phoneNumber from '@/filters/phoneNumber';

  import printMixin from '@/mixins/print';

  import ExpoOrder from '@/store/classes/ExpoOrder';

  import ExpoOrderDetailsCard from './expo-order-details-card.vue';
  import ExpoOrderDetailsRow from './expo-order-details-row.vue';
  import ExpoOrderPaymentDetail from './expo-order-payment-detail.vue';
  import ExpoNotificationContainer from './expo-notification-container.vue';

  export default {
    name: 'ExpoOrderDetailsPanel',

    components: {
      ExpoOrderDetailsCard,
      ExpoOrderDetailsRow,
      OrderDetailsItems,
      OrderPriceBreakdown,
      ExpoOrderPaymentDetail,
      ExpoNotificationContainer
    },

    mixins: [printMixin],

    props: {
      orderExpoToolsActive: {
        type: Boolean,
        default: false
      }
    },

    data: () => ({
      capitalCase,
      expoStatuses,
      moment,
      isPrinting: false
    }),

    computed: {
      ...mapState('orderExpo', [
        'selectedOrderId',
        'tagTypes',
        'isLightTagTypes'
      ]),
      ...mapGetters('orderExpo', ['fetchingOrderDetails']),

      updatingOrder() {
        return ExpoOrder.$state().updatingOrder;
      },

      printingOrder() {
        return ExpoOrder.$state().printing;
      },

      selectedOrder() {
        return ExpoOrder.find(this.selectedOrderId);
      },

      dropOffAddress() {
        const dropOffAddress = this.selectedOrder.delivery?.dropOffAddress;
        if (dropOffAddress) {
          const streetAddress = `${dropOffAddress.line1}${dropOffAddress.line2 ? `, ${dropOffAddress.line2}` : ''}`;
          const cityStateZip = `${dropOffAddress.city}, ${dropOffAddress.state} ${dropOffAddress.postalCode}`;
          return `${streetAddress}, ${cityStateZip}`;
        }
        else {
          return 'N/A';
        }
      },

      customerInfoMapping() {
        const mapping = [
          { label: 'Phone', value: this.$options.filters.phoneNumber(this.selectedOrder.phoneNumber) },
          { label: 'Email', value: this.selectedOrder.email || 'No email' },
          { label: 'Order', value: this.selectedOrder.orderId },
          { label: 'Order Placed', value: moment(this.selectedOrder.orderDate).format('MM/DD/YYYY h:mm A') }
        ];

        if (this.selectedOrder.delivery) {
          mapping.splice(2, 0, {
            label: 'Delivery Address',
            value: this.dropOffAddress
          });
        }

        return mapping;
      },

      orderStatus() {
        return this.selectedOrder.delivery ? this.selectedOrder.delivery.status : 'Customer Pickup';
      },

      driverInfoMapping() {
        const courier = this.selectedOrder?.delivery?.courier;
        return [
          { label: 'Driver Name', value: courier?.name || 'Driver Unassigned' },
          { label: 'Phone', value: phoneNumber(courier?.phoneNumber) || '' },
          {
            label: 'Provider',
            value: this.selectedOrder?.delivery
              ? capitalCase(this.selectedOrder.delivery.deliveryService)
              : 'None'
          }
        ];
      },

      activeDate() {
        if (moment(this.selectedOrder.pickupDate).diff(this.selectedOrder.orderDate, 'minutes') < 30) {
          return this.selectedOrder.orderDate;
        }

        return moment(this.selectedOrder.pickupDate).subtract(30, 'minutes');
      }
    },

    methods: {
      ...mapActions('orderExpo', ['updateOrderExpoStatus', 'printOrderReceipt']),

      async printOrder() {
        // Due to the <b-loading /> element used in ExpoOrderDetailsCard, we need to set and pass down
        // this prop to disable the the loading state on the `<b-collapse />` element. The $nextTick
        // ensures that all the data is set properly before trying to open the print dialog.

        this.isPrinting = true;
        await this.$nextTick();
        this.$_print(
          this.$el.innerHTML.split('display: none;').join(),
          {
            hideButtons: true,
            hideIcons: true
          }
        );
        this.isPrinting = false;
      }
    }
  };
</script>

<style lang='sass' scoped>
  .order-details
    flex-grow: 1
    position: relative
    .no-order-selected
      position: absolute
      top: 50%
      left: 50%
      transform: translate(-50%, -50%)
      text-align: center

    .hero
      position: relative
      .hero-body
        padding: 36px 36px 16px

    .fullscreen-button
      position: absolute
      top: 0
      right: 0
      z-index: 5

    .ellipsis-container
      height: 50px

    .extra-long-ellipsis
      line-height: 40px

    .expo-order-details-cards-container
      overflow-y: auto
      height: calc(100vh - 220px - #{$navbar-height})
</style>
