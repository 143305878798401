<template>
  <div class="card" data-test-id="award-step">
    <div class="card-content dist-y">
      <div class="dist-y">
        <label class="label">What award will this challenge give?</label>
        <div
          v-for="(conversionThreshold, index) in value.conversionThresholds"
          :key="conversionThreshold.publicId"
          class="gap-xs is-flex"
          data-test-class="conversion-threshold-award-inputs"
        >
          <div class="gap pad is-full-width has-background-white-bis has-border has-border-grey-lightest has-radius">
            <div class="award-step-options gap">
              <validated-text-input
                v-model="conversionThreshold.cost"
                data-test-class="cost-input"
                name="cost"
                type="number"
                label="Threshold"
                placeholder="Enter threshold"
                rules="required|numeric|min_value:1"
                :disabled="readOnly || !conversionThreshold.isDraft"
                :backend-validation-errors="conversionThresholdErrors[conversionThreshold.id] && conversionThresholdErrors[conversionThreshold.id].cost"
              />
              <validated-input
                class="dropdown-field"
                name="conversionThresholdwardPolicyType"
                label="Award Type"
                rules="required"
                data-test-class="award-policy-type-select"
              >
                <b-select
                  :value="conversionThreshold.awardPolicy.conversionThresholdAwardPolicyType"
                  placeholder="Select an award type..."
                  class="is-inline-block"
                  expanded
                  :disabled="readOnly || !conversionThreshold.isDraft"
                  @input="handleConversionThresholdAwardPolicyTypeChange({ conversionThresholdAwardPolicyType: $event, index })"
                >
                  <option :value="null" disabled>-</option>
                  <option
                    v-for="option in challengeAwardPolicyTypeOptions"
                    :key="option.type"
                    :value="option.type"
                  >
                    {{ option.display }}
                  </option>
                </b-select>
              </validated-input>
              <live-offers-select
                v-if="conversionThreshold.awardPolicy.conversionThresholdAwardPolicyType === conversionThresholdAwardPolicyTypes.OFFER_CONVERSION.type"
                v-model="conversionThreshold.awardPolicy.cardfreeOfferPublicId"
                :resource-id="conversionThreshold.publicId"
                :read-only="readOnly || !conversionThreshold.isDraft"
              />
              <random-award-inputs
                v-if="conversionThreshold.awardPolicy.conversionThresholdAwardPolicyType === conversionThresholdAwardPolicyTypes.RANDOM_AWARD.type"
                :value="conversionThreshold"
                :read-only="readOnly || !conversionThreshold.isDraft"
                @update-awards="setConversionThresholdAwardPolicy({
                  awardPolicy: {...conversionThreshold.awardPolicy, weightedAwardOptions: $event},
                  index
                });"
              />
              <validated-text-input
                v-if="conversionThreshold.awardPolicy.conversionThresholdAwardPolicyType === conversionThresholdAwardPolicyTypes.POINT_CONVERSION.type"
                v-model="conversionThreshold.awardPolicy.points"
                type="float"
                :label="`${primaryCurrency.formattedPluralName() } Award`"
                rules="required|numeric|min_value:0.01"
                class="mar-none"
                :placeholder="`Enter ${primaryCurrency.formattedPluralName()}`"
                data-test-class="points-input"
                :name="`points-${conversionThreshold.publicId}`"
                :disabled="readOnly || !conversionThreshold.isDraft"
                @blur="(value) => handleAwardPointsInputBlur(value, index)"
              />
              <validated-text-input
                v-if="conversionThreshold.awardPolicy.conversionThresholdAwardPolicyType === conversionThresholdAwardPolicyTypes.GIFT_CARD.type"
                v-model="conversionThreshold.awardPolicy.amount"
                type="dollars"
                label="Amount"
                :name="`amount-${conversionThreshold.publicId}`"
                rules="required|min_value:0.01"
                class="mar-none flex-grow"
                placeholder="Enter amount"
                data-test-class="dollars-input"
              />
            </div>
            <b-message
              v-if="conversionThresholdErrors[conversionThreshold.id] && conversionThresholdErrors[conversionThreshold.id].awardPolicy"
              type="is-danger"
              size="is-small"
              class="pad-none"
            >
              {{ conversionThresholdErrors[conversionThreshold.id].awardPolicy.join(', ') }}
            </b-message>
            <conversion-threshold-run-limit-input
              v-if="isAutomaticProcessingType"
              v-model="conversionThreshold.runLimitPolicy"
              :read-only="readOnly || !conversionThreshold.isDraft"
            />
          </div>
          <b-button
            v-if="value.conversionThresholds.length > 1 && !readOnly && conversionThreshold.isDraft"
            data-test-id="remove-draft-button"
            style="height: unset"
            type="is-danger is-light"
            @click="removeConversionThreshold(index)"
          >
            <b-icon icon="trash-alt" />
          </b-button>
        </div>
      </div>
      <div>
        <b-button
          type="is-primary is-light"
          icon-left="plus"
          :disabled="readOnly || isAutomaticProcessingType || !challengeIsDraft"
          data-test-id="add-award-button"
          @click="addConversionThreshold"
        >
          Award
        </b-button>
        <b-button
          type="is-danger is-light"
          icon-left="trash-alt"
          :disabled="readOnly || isAutomaticProcessingType || !challengeIsDraft"
          class="mar-l-md"
          data-test-id="clear-awards-button"
          @click="clearConversionThresholds"
        >
          Clear All Awards
        </b-button>
      </div>
    </div>
  </div>
</template>

<script>
  import moment from 'moment-timezone';

  import { conversionThresholdAwardPolicyTypes } from '@/constants/conversionThresholds';
  import { weightedAwardOptionsDefaultData } from '@/constants/earnRules';

  import ConversionThreshold from '@/store/classes/ConversionThreshold';
  import Currency from '@/store/classes/Currency';
  import Offer from '@/store/classes/Offer';

  import ConversionThresholdRunLimitInput from '../../run-limit-policy-inputs/conversion-threshold-run-limit-input.vue';
  import LiveOffersSelect from '../../offer-inputs/live-offers-select.vue';
  import RandomAwardInputs from '../../award-policy-inputs/random-award-inputs.vue';


  export default {
    name: 'AwardStep',

    components: {
      ConversionThresholdRunLimitInput,
      LiveOffersSelect,
      RandomAwardInputs
    },

    props: {
      value: {
        type: Object,
        required: true
      },

      readOnly: {
        type: Boolean,
        default: false
      },

      challengeIsDraft: {
        type: Boolean,
        default: false
      },

      conversionThresholdErrors: {
        type: Object,
        required: true
      }
    },

    data() {
      return {
        conversionThresholdAwardPolicyTypes,
        moment
      };
    },

    computed: {
      isAutomaticProcessingType() {
        return this.value.currency.challengeProcessingType === Currency.challengeProcessingTypes.AUTOMATIC_AWARD_CHALLENGE;
      },

      primaryCurrency() {
        return Currency.primaryCurrency();
      },

      challengeAwardPolicyTypeOptions() {
        return [
          {
            type: conversionThresholdAwardPolicyTypes.OFFER_CONVERSION.type,
            display: 'Offer'
          },
          {
            type: conversionThresholdAwardPolicyTypes.POINT_CONVERSION.type,
            display: this.primaryCurrency.formattedPluralName()
          },
          {
            type: conversionThresholdAwardPolicyTypes.RANDOM_AWARD.type,
            display: 'Random Award'
          },
          {
            type: conversionThresholdAwardPolicyTypes.GIFT_CARD.type,
            display: 'Gift Card'
          }
        ];
      },

      liveOffers() {
        return Offer.liveOffers();
      }
    },

    methods: {
      handleConversionThresholdAwardPolicyTypeChange({ conversionThresholdAwardPolicyType, index }) {
        const awardPolicyMapping = {
          [conversionThresholdAwardPolicyTypes.OFFER_CONVERSION.type]: conversionThresholdAwardPolicyTypes.OFFER_CONVERSION.defaultData,
          [conversionThresholdAwardPolicyTypes.POINT_CONVERSION.type]: conversionThresholdAwardPolicyTypes.POINT_CONVERSION.defaultData(this.primaryCurrency.publicId),
          [conversionThresholdAwardPolicyTypes.RANDOM_AWARD.type]: { weightedAwardOptions: [...weightedAwardOptionsDefaultData] },
          [conversionThresholdAwardPolicyTypes.GIFT_CARD.type]: conversionThresholdAwardPolicyTypes.GIFT_CARD.defaultData
        };

        const awardPolicy = {
          conversionThresholdAwardPolicyType,
          ...awardPolicyMapping[conversionThresholdAwardPolicyType]
        };

        this.setConversionThresholdAwardPolicy({ awardPolicy, index });
      },

      setConversionThresholdAwardPolicy({ awardPolicy, index }) {
        const emitValue = {
          ...this.value,
          conversionThresholds: this.value.conversionThresholds.map((conversionThreshold, i) => {
            if (i === index) {
              return {
                ...conversionThreshold,
                awardPolicy
              };
            }
            return conversionThreshold;
          })
        };
        this.$emit('input', emitValue);
      },

      getSelectedOfferName(conversionThreshold) {
        return Offer.getOfferName(conversionThreshold?.awardPolicy?.cardfreeOfferPublicId);
      },

      addConversionThreshold() {
        const { startDate, endDate } = this.value.conversionThresholds[0];
        const newConversionThreshold = new ConversionThreshold({
          awardPolicy: {
            conversionThresholdAwardPolicyType: conversionThresholdAwardPolicyTypes.OFFER_CONVERSION.type,
            cardfreeOfferPublicId: undefined
          },
          startDate,
          endDate,
          currencyPublicId: this.value.currency.id
        });

        this.$emit('input', {
          ...this.value,
          conversionThresholds: [...this.value.conversionThresholds, { ...newConversionThreshold, isDraft: newConversionThreshold.isDraft }]
        });
      },

      removeConversionThreshold(index) {
        this.$emit('input', {
          ...this.value,
          conversionThresholds: this.value.conversionThresholds.filter((_, i) => i !== index)
        });
      },

      clearConversionThresholds() {
        const { startDate, endDate } = this.value.conversionThresholds[0];
        const newConversionThreshold = new ConversionThreshold({
          awardPolicy: {
            conversionThresholdAwardPolicyType: conversionThresholdAwardPolicyTypes.OFFER_CONVERSION.type,
            cardfreeOfferPublicId: undefined
          },
          startDate,
          endDate
        });

        this.$emit('input', {
          ...this.value,
          conversionThresholds: [newConversionThreshold]
        });
      },

      handleAwardPointsInputBlur(value, index) {
        const inputValue = parseFloat(value);
        if (!Number.isNaN(inputValue)) {
          const formattedValue = Number.isInteger(inputValue) ? inputValue : inputValue.toFixed(2);
          this.setConversionThresholdAwardPolicy({
            awardPolicy: { ...this.value.conversionThresholds[index].awardPolicy, points: formattedValue },
            index
          });
        }
      }
    }
  };
</script>

<style lang="sass" scoped>
  .card-content:nth-child(even)
    border-top: 1px solid $grey-lightest
    background-color: $white-ter !important

    &:not(:last-child)
      border-bottom: 1px solid $grey-lightest

  .award-step-options
    display: grid
    grid-template-columns: 100px 175px 1fr
</style>
