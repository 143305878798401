<template>
  <div class="card-group">
    <content-ticket-instruction-form v-if="$_selectedMerchant.patEnabled" />
    <content-receipt-instruction-form v-if="$_featurePermissions.contentManagement.COMMUNICATIONS" />
    <content-guest-settings-form />
  </div>
</template>

<script>
  import contentTicketInstructionForm from '../form/content-ticket-instruction-form.vue';
  import contentGuestSettingsForm from '../form/content-guest-settings-form.vue';
  import contentReceiptInstructionForm from '../form/content-receipt-instruction-form.vue';
  import merchantMixin from '@/mixins/merchant';
  import featurePermissionsMixin from '@/mixins/featurePermissions';

  export default {
    name: 'InstructionsTab',

    components: {
      contentTicketInstructionForm,
      contentGuestSettingsForm,
      contentReceiptInstructionForm
    },

    mixins: [merchantMixin, featurePermissionsMixin]
  };
</script>
