<template>
  <validated-form
    ref="form"
    v-slot="{ dirty }"
    form-id="conversionThresholdsForm"
    class="dist-y"
    @valid-submit="saveConversionThresholds"
  >
    <panel
      title="Conversion Thresholds"
      subtitle="A conversion threshold defines how much a reward costs in points. When the threshold is met, the reward will unlock and the guest can buy that reward. A single-threshold program can be configured to auto-convert points to a reward."
      :loading="isFetching"
    >
      <template #buttons>
        <b-button
          rounded
          class="is-bold"
          native-type="submit"
          size="is-medium"
          type="is-primary"
          :disabled="!dirty"
          :loading="isSubmitting"
        >
          Save
        </b-button>
      </template>

      <div class="is-flex gap mar-b has-marginless-children">
        <b-field
          label="Status"
          label-position="on-border"
        >
          <dropdown-menu v-model="selectedFilters" multiple>
            <dropdown-button
              slot="trigger"
              :value="selectedFilters.map(filter => $changeCase.capitalCase(filter)).join(', ')"
            />
            <b-dropdown-item
              v-for="({ display, value }) in filterOptions"
              :key="value"
              :value="value"
            >
              {{ display }}
            </b-dropdown-item>
          </dropdown-menu>
        </b-field>

        <b-field
          label="Sort By"
          label-position="on-border"
        >
          <b-select v-model="sortField">
            <option
              v-for="({ display, value }) in sortFieldOptions"
              :key="value"
              :value="value"
            >
              {{ display }}
            </option>
          </b-select>
        </b-field>
      </div>

      <!--
        Using the `isSubmitting` property as the `key` value to force this section
        to re-render when the value changes. For some reason it would not re-render
        on it's own when the form submits and some of the CT reqs respond with an error.
      -->
      <div
        :key="isSubmitting"
        class="dist-y-lg"
      >
        <div v-for="(conversionThresholdGroup, status) in groupedConversionThresholds" :key="status">
          <p class="subtitle is-4 mar-b-xs is-capitalized">
            {{ status }}
          </p>
          <div class="dist-y">
            <div
              v-for="conversionThreshold in conversionThresholdGroup"
              :key="conversionThreshold.publicId"
            >
              <validation-observer
                :ref="`observer-${conversionThreshold.publicId}`"
                tag="div"
                class="is-flex gap-xs"
              >
                <div
                  data-test-id="conversion-threshold-card"
                  :class="[
                    'conversion-threshold-card has-marginless-children pad',
                    {
                      'is-status-active': conversionThreshold.status === earnRuleStatuses.ACTIVE,
                      'is-status-expired': conversionThreshold.status === earnRuleStatuses.EXPIRED,
                      'is-status-draft': conversionThreshold.status === earnRuleStatuses.DRAFT,
                      'has-error': errorMessages[conversionThreshold.publicId]
                    }
                  ]"
                >
                  <validated-text-input
                    v-model="conversionThreshold.cost"
                    data-test-id="threshold-input"
                    :disabled="conversionThreshold.status === earnRuleStatuses.EXPIRED || conversionThreshold.status === earnRuleStatuses.ACTIVE"
                    :name="`cost-${conversionThreshold.publicId}`"
                    label="Threshold"
                    :placeholder="$changeCase.capitalCase(primaryCurrency.pluralName)"
                    type="number"
                    :rules="{ required: true }"
                  />
                  <validated-input
                    :name="`startDate-${conversionThreshold.publicId}`"
                    label="Start Date"
                    class="start-date"
                    :rules="startDateValidations(conversionThreshold)"
                    :custom-messages="{
                      between: `the start date must be within the selected offer's active date range`
                    }"
                  >
                    <b-datepicker
                      :value="conversionThreshold.startDate && moment(conversionThreshold.startDate).toDate()"
                      data-test-id="start-date-input"
                      placeholder="Start Date"
                      icon="calendar-alt"
                      position="is-top-right"
                      :min-date="moment().add(1, 'day').startOf('day').toDate()"
                      class="has-extra-shadow"
                      :disabled="conversionThreshold.status === earnRuleStatuses.EXPIRED || conversionThreshold.status === earnRuleStatuses.ACTIVE"
                      @input="(val) => conversionThreshold.startDate = moment(val).format('YYYY-MM-DD')"
                    />
                  </validated-input>
                  <validated-input
                    :ref="`endDateValidatedInput-${conversionThreshold.publicId}`"
                    :name="`endDate-${conversionThreshold.publicId}`"
                    label="End Date"
                    class="end-date"
                    :sub-label="endDateValidations(conversionThreshold).required ? '' : '(Optional)'"
                    sub-label-on-side
                    :rules="endDateValidations(conversionThreshold)"
                    :custom-messages="{
                      required: `must select an end date on or before the selected offer's end date`,
                      between: `the end date must be within the selected offer's active date range`
                    }"
                  >
                    <b-datepicker
                      :value="conversionThreshold.endDate && moment(conversionThreshold.endDate).toDate()"
                      data-test-id="end-date-input"
                      placeholder="End Date"
                      icon="calendar-alt"
                      class="has-extra-shadow"
                      position="is-top-left"
                      :min-date=" moment().isAfter(moment(conversionThreshold.startDate))
                        ? moment().startOf('day').toDate()
                        : moment(conversionThreshold.startDate).startOf('day').toDate()
                      "
                      :disabled="conversionThreshold.status === earnRuleStatuses.EXPIRED"
                      :focused-date="conversionThreshold.endDate
                        ? moment(conversionThreshold.endDate).toDate()
                        : (moment(conversionThreshold.startDate).isAfter(moment())
                          ? moment(conversionThreshold.startDate).toDate()
                          : moment().startOf('day').toDate()
                        )
                      "
                      @input="(val) => conversionThreshold.endDate = moment(val).format('YYYY-MM-DD')"
                    >
                      <div v-if="conversionThreshold.endDate" class="is-flex justify-end">
                        <b-button size="is-small" @click="clearEndDate(conversionThreshold)">
                          Clear
                        </b-button>
                      </div>
                    </b-datepicker>
                  </validated-input>
                  <live-offers-select
                    v-model="conversionThreshold.awardPolicy.cardfreeOfferPublicId"
                    :resource-id="conversionThreshold.publicId"
                    :read-only="conversionThreshold.status === earnRuleStatuses.EXPIRED || conversionThreshold.status === earnRuleStatuses.ACTIVE"
                    label="Reward"
                  />
                  <validation-provider :name="`auto-convert-${conversionThreshold.publicId}`" slim>
                    <check-button
                      v-model="conversionThreshold.autoConvert"
                      data-test-id="auto-convert-input"
                      class="auto-convert"
                      fixed-height
                      :disabled="conversionThreshold.status === earnRuleStatuses.EXPIRED || conversionThreshold.status === earnRuleStatuses.ACTIVE"
                    >
                      Auto-convert
                    </check-button>
                  </validation-provider>

                  <div v-if="conversionThreshold.status !== earnRuleStatuses.DRAFT" class="mar-t-xs has-text-grey no-wrap-text is-monospaced">
                    ID: {{ conversionThreshold.publicId }}
                  </div>
                </div>

                <!-- Remove Draft Button -->
                <b-button
                  v-if="!conversionThreshold.$id"
                  data-test-id="remove-draft-button"
                  style="height: unset"
                  type="is-danger is-light"
                  @click="removeConversionThreshold(conversionThreshold)"
                >
                  <b-icon icon="trash-alt" />
                </b-button>
              </validation-observer>

              <!-- BE Error Message -->
              <p v-if="errorMessages[conversionThreshold.publicId]" class="has-text-danger is-size-6 mar-t-xs">
                {{ errorMessages[conversionThreshold.publicId] }}
              </p>
            </div>
          </div>
        </div>
      </div>

      <!-- Add Conversion Threshold Button -->
      <b-button
        data-test-id="add-conversion-threshold-button"
        outlined
        size="is-medium"
        class="mar-t-md"
        icon-left="plus"
        type="is-primary"
        @click="addNewConversionThreshold"
      >
        Conversion Tier
      </b-button>
    </panel>
  </validated-form>
</template>

<script>
  import moment from 'moment-timezone';

  import ConversionThreshold from '@/store/classes/ConversionThreshold';
  import Currency from '@/store/classes/Currency';
  import Offer from '@/store/classes/Offer';

  import { conversionThresholdAwardPolicyTypes } from '@/constants/conversionThresholds';
  import { earnRuleStatuses } from '@/constants/earnRules';

  import LiveOffersSelect from '../offer-inputs/live-offers-select.vue';

  export default {
    name: 'ConversionThresholdsTab',

    components: {
      LiveOffersSelect
    },

    props: {
      loyaltyProgramPublicId: {
        type: String,
        default: ''
      }
    },

    data: () => ({
      earnRuleStatuses,
      sortField: 'cost',
      filterOptions: [
        { display: 'Expired', value: 'expired' },
        { display: 'Active', value: 'active' },
        { display: 'Scheduled', value: 'scheduled' }
      ],
      selectedFilters: ['active', 'scheduled'],
      sortFieldOptions: [
        { display: 'Threshold', value: 'cost' },
        { display: 'Start Date', value: 'startDate' },
        { display: 'End Date', value: 'endDate' }
      ],
      localForms: {},
      drafts: [],
      isSubmitting: false
    }),

    computed: {
      isFetching() {
        return ConversionThreshold.$state().fetching || ConversionThreshold.$state().fetchingDrafts;
      },

      errorMessages() {
        return ConversionThreshold.$state().errorMessages;
      },

      primaryCurrency() {
        return Currency.primaryCurrency();
      },

      allOffers() {
        return Offer.all();
      },

      // move to ConversionThreshold class
      conversionThresholds() {
        return ConversionThreshold
          .conversionThresholdsByCurrencyId(this.primaryCurrency?.publicId)
          .filter(ct => ct.isRedeemableForOffers)
          .sort((a, b) => {
            const statusSortOrder = {
              expired: 1,
              active: 2,
              scheduled: 3
            };

            return statusSortOrder[a.status] - statusSortOrder[b.status] /* sort first by status */
              || a[this.sortField] - b[this.sortField]; /* then by the selected sort field */
          })
          .map(ct => ({ ...ct, status: ct.status }));
      },

      groupedConversionThresholds() {
        const groups = Object.values(this.localForms).reduce((obj, ct) => {
          if (this.selectedFilters.includes(ct.status)) {
            obj[ct.status] = [...(obj[ct.status] || []), ct]
              .sort((a, b) => (a.sortOrder > b.sortOrder ? 1 : -1));
          }
          return obj;
        }, {});

        if (this.drafts.length) {
          groups.draft = this.drafts;
        }

        return groups;
      },

      submissionIds() {
        const submissionIds = Object.entries(this.$refs).reduce((arr, [key, value]) => {
          if (key.includes('observer') && value[0]?.flags.dirty) {
            arr.push(key.split('-')[1]);
          }
          return arr;
        }, []);
        submissionIds.push(...Object.keys(this.errorMessages));

        return submissionIds;
      }
    },

    watch: {
      conversionThresholds: {
        immediate: true,
        handler: 'setLocalForms'
      }
    },

    methods: {
      moment,

      setLocalForms(conversionThresholds) {
        this.localForms = conversionThresholds.reduce((obj, ct, index) => {
          if (!this.errorMessages[ct.publicId]) {
            obj[ct.publicId] = this.$clone({ ...ct, sortOrder: index });
          }
          return obj;
        }, {});
      },

      startDateValidations(conversionThreshold) {
        const { cardfreeOfferPublicId } = conversionThreshold.awardPolicy;
        const selectedOffer = this.allOffers.find(o => o.guid === cardfreeOfferPublicId);

        const validations = { required: true };

        if (selectedOffer) {
          validations.between = {
            min: moment(selectedOffer.redemptionsAllowedStartDate).startOf('day').toDate(),
            max: selectedOffer.redemptionsAllowedEndDate
              ? moment(selectedOffer.redemptionsAllowedEndDate).endOf('day').toDate()
              : moment('3000-01-01')
          };
        }

        return validations;
      },

      endDateValidations(conversionThreshold) {
        const { cardfreeOfferPublicId } = conversionThreshold.awardPolicy;
        const selectedOffer = this.allOffers.find(o => o.guid === cardfreeOfferPublicId);
        const offerHasEndDate = !!selectedOffer?.redemptionsAllowedEndDate;

        const validations = { required: offerHasEndDate };

        if (selectedOffer) {
          validations.between = {
            min: moment(selectedOffer.redemptionsAllowedStartDate).startOf('day').toDate(),
            max: selectedOffer.redemptionsAllowedEndDate
              ? moment(selectedOffer.redemptionsAllowedEndDate).endOf('day').toDate()
              : moment('3000-01-01')
          };
        }

        return validations;
      },

      clearEndDate(conversionThreshold) {
        conversionThreshold.endDate = null;

        const validationProvider = this.$refs[`endDateValidatedInput-${conversionThreshold.publicId}`][0].$children[0];

        validationProvider.setFlags({ dirty: true });
      },

      getSelectedOfferName(conversionThreshold) {
        return Offer.getOfferName(conversionThreshold?.awardPolicy?.cardfreeOfferPublicId);
      },

      getOfferDisabled(offer, conversionThreshold) {
        const isNotSelf = offer.guid !== conversionThreshold.awardPolicy.cardfreeOfferPublicId;
        const isNotInUse = Object.values(this.localForms).some(ct => ct.awardPolicy.cardfreeOfferPublicId === offer.guid && ct.status !== earnRuleStatuses.EXPIRED);

        return isNotSelf && isNotInUse;
      },

      addNewConversionThreshold() {
        this.drafts.push(new ConversionThreshold({
          currencyPublicId: this.primaryCurrency.publicId,
          awardPolicy: {
            conversionThresholdAwardPolicyType: conversionThresholdAwardPolicyTypes.OFFER_CONVERSION.type,
            cardfreeOfferPublicId: undefined
          }
        }));
      },

      removeConversionThreshold(conversionThreshold) {
        this.drafts = this.drafts.filter(ct => ct.publicId !== conversionThreshold.publicId);
      },

      async saveConversionThresholds() {
        try {
          this.isSubmitting = true;

          const updated = Object.values(this.localForms).filter(ct => this.submissionIds.includes(ct.publicId));

          const requests = [
            /* update existing conversion thresholds with pending changes to submit */
            ...updated.map(ct => ConversionThreshold.updateConversionThreshold(ct)),
            /* save new conversion thresholds */
            ...this.drafts.map(ct => ConversionThreshold.createConversionThreshold(ct))
          ];

          await Promise.all(requests);

          /**
           * clear conversion thresholds now that they've
           * been saved and their "real" versions are available
           */
          this.drafts = [];

          this.$_onRequestSuccess({
            toastOptions: {
              message: 'Successfully updated your conversion thresholds!'
            }
          });
        }

        catch (error) {
          this.$_onRequestError({
            toastOptions: {
              message: 'There was an error updating one of your conversion thresholds'
            },
            error
          });
        }

        finally {
          this.isSubmitting = false;
        }
      }
    }
  };
</script>

<style scoped lang="sass">
  .conversion-threshold-card
    display: grid
    grid-template-columns: 150px 200px 200px minmax(0, 1fr) min-content
    gap: $size-small $size-medium
    justify-content: space-between
    flex: 1
    border: 2px solid $grey-lighter
    border-radius: $radius-large

    &.has-error
      border-color: $danger

  .auto-convert
    white-space: nowrap
    margin-top: 2rem !important

  .is-status-active
    background-color: lighten($success, 46)
    border-color: lighten($success, 25)
  .is-status-expired
    background-color: $white-bis
    border-color: $grey-lightest
  .is-status-draft
    border: 2px dashed $grey-lighter

  .reward-select-item
    width: 550px

  @media (max-width: $fullhd)
    .conversion-threshold-card
      display: grid
      grid-template-columns: repeat(3, minmax(0, 1fr))

    .reward-select
      grid-row: 2
      grid-column: span 2
</style>
