<template>
  <b-loading v-if="isLoading" active :is-full-page="false" />
  <div v-else class="is-flex-column is-full-height">
    <hero-banner>
      <h1 class="title is-2">Location Menus</h1>
    </hero-banner>

    <div class="section flex-grow">
      <div class="container is-full-height">
        <store-menu :store-id="storeId" />
      </div>
    </div>
  </div>
</template>

<script>
  import logger from '@/services/logger';
  import merchantMixin from '@/mixins/merchant';
  import Store from '@/store/classes/Store';
  import MerchantMenuTypeConfiguration from '@/store/classes/MerchantMenuTypeConfiguration';

  import storeMenu from './store-menu.vue';

  export default {
    name: 'LocationMenu',

    components: { storeMenu },

    mixins: [merchantMixin],

    data() {
      return {
        isLoading: true
      };
    },

    computed: {
      storeId() {
        return this.$route.params.storeId ? Number(this.$route.params.storeId) : null;
      }
    },

    methods: {
      async onMerchantLoad({ id }) {
        try {
          await Store.fetchAll(id, { forceFetch: true });
          await MerchantMenuTypeConfiguration.fetchMerchantMenuTypeConfigurations();
        }
        catch (error) {
          logger.error(error);
        }
        finally {
          this.isLoading = false;
        }
      }
    }
  };
</script>
