import { render, staticRenderFns } from "./pos-menu-list.vue?vue&type=template&id=1286f3b8&scoped=true&"
import script from "./pos-menu-list.vue?vue&type=script&lang=js&"
export * from "./pos-menu-list.vue?vue&type=script&lang=js&"
import style0 from "./pos-menu-list.vue?vue&type=style&index=0&id=1286f3b8&lang=sass&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1286f3b8",
  null
  
)

export default component.exports