<template>
  <ul class="nested-table-section">
    <!-- No modifier groups -->
    <li v-if="!modifierGroups.length && (!menuPermissions.ADD_RESOURCE || activeMenuType)">
      <div class="row sub-row">
        <span class="has-text-grey pad-y-md">No Modifier Groups</span>
        <span />
        <span />
      </div>
    </li>

    <!-- Draggable list of modifier groups  -->
    <draggable
      tag="ul"
      :group="`item-${menuItem.id}-modifier-groups`"
      v-bind="draggableAttributes"
      :value="modifierGroups"
      :force-fallback="true"
      @change="handleModifierGroupSort"
    >
      <template v-if="modifierGroups.length" #header>
        <li class="nested-table-section-title">{{ featurePermissions.NESTED_MODIFIERS ? 'Groups' : 'Modifier Groups' }}</li>
      </template>
      <modifier-group-row
        v-for="modifierGroup in modifierGroups"
        :key="`modifier-group-${modifierGroup[modifierGroupIdKey]}-item-${menuItem.id}`"
        :modifier-group="featurePermissions.SHARED_MODIFIER_GROUPS ? modifierGroup.modifierGroup : modifierGroup"
        :menu-item-modifier-group-item="featurePermissions.SHARED_MODIFIER_GROUPS ? modifierGroup : null"
        :menu-item="menuItem"
      />
    </draggable>

    <!-- Add modifier group button  -->
    <li
      v-if="!activeMenuType && $can('create', 'MenuItemModifierGroup') && menuPermissions.ADD_RESOURCE"
      class="row sub-row"
    >
      <span>
        <dropdown-menu position="bottom-start">
          <template #trigger>
            <b-button
              type="is-primary"
              class="is-transparent"
              inverted
              icon-left="plus"
            >
              Group
            </b-button>
          </template>

          <b-dropdown-item
            v-for="{ display, value } in modifierGroupTypeOptions"
            :key="value"
            class="is-flex justify-between pad-r-sm"
            aria-role="listitem"
            :disabled="modifierGroupTypeOptionDisabled(value)"
            @click="handleOpenModifierGroupModal({ type: Object.values(modifierGroupTypes).includes(value) ? value : '' })"
          >
            {{ display }}
            <b-icon icon="arrow-right" />
          </b-dropdown-item>
        </dropdown-menu>
      </span>
      <span /><span />
    </li>
  </ul>
</template>

<script>
  import draggable from 'vuedraggable';
  import { mapActions, mapState } from 'vuex';

  import menuResourceTypes from '@/constants/menuResourceTypes';
  import { modifierGroupTypes } from '@/constants/modifierGroups';

  import Item from '@/store/classes/Item';
  import ModifierGroup from '@/store/classes/ModifierGroup';
  import MenuItemModifierGroupItem from '@/store/classes/MenuItemModifierGroupItem';

  import AddEditModifierGroupModal from '../main-menu/add-edit-modifier-group-modal.vue';
  import AddModifierGroupsToItemModal from '../main-menu/modifier-groups/add-modifier-groups-to-item-modal.vue';
  import SharedModifierGroupModal from '../main-menu/modifier-groups/shared-modifier-group-modal.vue';

  import ModifierGroupRow from './modifier-group-row.vue';


  export default {
    name: 'ModifierGroupsContainer',

    components: { draggable, ModifierGroupRow },

    props: {
      menuItem: {
        type: Object,
        required: true
      },

      modifierGroups: {
        type: Array,
        required: true
      }
    },

    data: () => ({
      modifierGroupTypes
    }),

    computed: {
      ...mapState('mainMenu', ['selectedMerchant', 'featurePermissions', 'menuPermissions', 'activeMenuType', 'draggableAttributes']),

      modifierGroupIdKey() {
        return this.featurePermissions.SHARED_MODIFIER_GROUPS
          ? 'modifierGroupId'
          : 'id';
      },

      modifierGroupTypeOptions() {
        const options = [
          { display: 'Add New Modifier Group', value: 'modifier group' }
        ];

        if (this.selectedMerchant.allowModifierGroupAssociation) {
          options.push({ display: 'Manage Modifier Group Associations', value: 'existing' });
        }

        if (this.featurePermissions.NESTED_MODIFIERS) {
          options.push({ display: 'Add Portion Group', value: 'portion' });
          options.push({ display: 'Add Combo Group', value: 'combo' });
        }

        return options;
      }
    },

    methods: {
      ...mapActions('mainMenu', ['setMenuResourceOpenState']),

      handleModifierGroupSort($draggableEvent) {
        if (this.featurePermissions.SHARED_MODIFIER_GROUPS) {
          this.handleMenuItemModifierGroupItemsSort($draggableEvent);
        }
        else {
          this.handleMenuItemModifierGroupsSort($draggableEvent);
        }
      },

      async handleMenuItemModifierGroupItemsSort({ moved }) {
        try {
          await MenuItemModifierGroupItem.updateSortOrders({
            menuItemModifierGroupItems: this.modifierGroups,
            oldIndex: moved.oldIndex,
            newIndex: moved.newIndex
          });

          this.$_onRequestSuccess({
            toastOptions: {
              message: `<b>${moved.element.modifierGroup.displayName}</b> moved from sort order <b>${moved.oldIndex + 1}</b> to <b>${moved.newIndex + 1}</b>`
            }
          });
        }

        catch (error) {
          this.$_onRequestError({
            toastOptions: { message: 'Unable to update sort orders' },
            error
          });
        }
      },

      async handleMenuItemModifierGroupsSort({ moved }) {
        try {
          await ModifierGroup.updateSortOrders({
            modifierGroups: this.modifierGroups,
            oldIndex: moved.oldIndex,
            newIndex: moved.newIndex
          });

          this.$_onRequestSuccess({
            toastOptions: {
              message: `<b>${moved.element.displayName}</b> moved from sort order <b>${moved.oldIndex + 1}</b> to <b>${moved.newIndex + 1}</b>`
            }
          });
        }

        catch (error) {
          this.$_onRequestError({
            toastOptions: { message: 'Unable to update sort orders' },
            error
          });
        }
      },

      modifierGroupTypeOptionDisabled(type) {
        if (type === modifierGroupTypes.PORTION) {
          return this.featurePermissions.SHARED_MODIFIER_GROUPS
            ? !!this.modifierGroups.find(m => m.modifierGroup.type === modifierGroupTypes.PORTION)
            : !!this.modifierGroups.find(m => m.type === modifierGroupTypes.PORTION);
        }
      },

      handleOpenModifierGroupModal({ type }) {
        if (this.featurePermissions.SHARED_MODIFIER_GROUPS) {
          if (type === modifierGroupTypes.EXISTING) {
            this.openAddModifierGroupsToItemModal();
          }
          else {
            this.openSharedModifierGroupModal({ type });
          }
        }
        else {
          this.openModifierGroupModal({ type });
        }
      },

      openAddModifierGroupsToItemModal() {
        const menuItem = Item.query()
          .with('menuItemModifierGroupItems')
          .with('menuItemModifierGroupItems.modifierGroup')
          .find(this.menuItem.id); // Maybe we don't need this?
        this.$buefy.modal.open({
          parent: this,
          component: AddModifierGroupsToItemModal,
          hasModalCard: true,
          trapFocus: true,
          canCancel: false,
          props: {
            menuItem
          }
        });
      },

      async openSharedModifierGroupModal({ type }) {
        this.$buefy.modal.open({
          parent: this,
          component: SharedModifierGroupModal,
          hasModalCard: true,
          trapFocus: true,
          canCancel: false,
          customClass: 'auto-width',
          events: {
            'main-menu:add-modifier-group': this.toggleModifierGroup
          },
          props: {
            menuItemId: this.menuItem.id,
            readOnly: !this.$can('crud', 'MenuItemModifierGroupItem'),
            type
          }
        });
      },

      openModifierGroupModal({ type } = {}) {
        this.$buefy.modal.open({
          parent: this,
          component: AddEditModifierGroupModal,
          hasModalCard: true,
          trapFocus: true,
          canCancel: false,
          customClass: 'auto-width',
          events: {
            'main-menu:add-modifier-group': this.toggleModifierGroup
          },
          props: {
            mode: 'create',
            type,
            menuItemId: this.menuItem.id
          }
        });
      },

      toggleModifierGroup(id) {
        this.setMenuResourceOpenState({
          resourceType: menuResourceTypes.MODIFIER_GROUP,
          resourceId: `${this.menuItem.id}-${id}`,
          value: true
        });
      }
    }
  };
</script>

<style lang='sass' scoped>
  .row
      grid-template-columns: 1fr 200px 170px
</style>
