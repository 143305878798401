/* eslint-disable import/no-cycle */
import { Model } from '@vuex-orm/core';
import http from '@/services/http';



export default class CategorySchedule extends Model {
  static entity = 'menuCategorySchedules'

  // FIELDS //////////////////////
  static fields() {
    return {
      id: this.attr(null),
      day: this.attr(null),
      startTime: this.attr(null),
      endTime: this.attr(null),
      hideHoursDisplay: this.attr(false),
      menuCategoryId: this.attr(null)
    };
  }



  // ACTIONS //////////////////////
  static async fetchSchedules(categoryId) {
    try {
      const response = await http.get(`menu_categories/${categoryId}/menu_category_schedules`);

      this.insert({ data: response.data.menuCategorySchedules });
    }

    catch (error) {
      throw error;
    }
  }

  static async createSchedule({ categoryId, schedule }) {
    try {
      const response = await http.post(`menu_categories/${categoryId}/menu_category_schedules`, {
        menuCategorySchedule: {
          day: schedule.day,
          startTime: schedule.startTime,
          endTime: schedule.endTime,
          hideHoursDisplay: schedule.hideHoursDisplay
        }
      });

      this.insert({ data: response.data.menuCategorySchedule });
    }

    catch (error) {
      throw error;
    }
  }

  static async updateSchedule({ id, startTime, endTime, hideHoursDisplay }) {
    try {
      const response = await http.put(`menu_category_schedules/${id}`, {
        menuCategorySchedule: { startTime, endTime, hideHoursDisplay }
      });

      this.insert({ data: response.data.menuCategorySchedule });
    }

    catch (error) {
      throw error;
    }
  }

  static async deleteSchedule(scheduleId) {
    try {
      await http.delete(`menu_category_schedules/${scheduleId}`);

      this.delete(scheduleId);
    }

    catch (error) {
      throw error;
    }
  }
}
