import { Model } from '@vuex-orm/core';
import http from '@/services/http';

import { offerStates, valuePropositionTypes, discountedUnitPrecedenceTypes } from '@/constants/offers';
import { itemAttributes } from '@/constants/items';


export default class Offer extends Model {
  static entity = 'offers'

  static primaryKey = 'guid'


  // FIELDS //////////////////////
  static fields() {
    return {
      guid: this.attr(''),
      name: this.attr(null), // 63 limit
      cardfreePosDiscountConfigurationPublicId: this.attr(null),
      constraints: this.attr([]),
      coupons: this.attr([]),
      description: this.attr(null), // 256 limit
      redemptionsAllowedStartDate: this.attr(null),
      redemptionsAllowedEndDate: this.attr(null),
      dynamicExpirationPeriod: this.attr(null), // Define as -> P{numberOfDays}D
      shortDescription: this.attr(null), // 256 limit
      state: this.attr(offerStates.DRAFT),
      tagline: this.attr(null), // 256 limit
      termsAndConditions: this.attr(null), // 1280 limit
      valueProposition: this.attr({
        valuePropositionType: valuePropositionTypes.CHECK.PERCENT,
        percentDiscount: 0,
        discountedUnitPrecedenceType: discountedUnitPrecedenceTypes.HIGHEST_UNIT_PRICE_FIRST,
        excludedItemAttributes: Object.values(itemAttributes).map(itemAttr => itemAttr.id)
      }),
      url: this.attr(null),
      autoApplicable: this.attr(false),

      // FE PROPERTIES
      tempEmailImageFile: this.attr(null),
      tempLoyaltyImageFile: this.attr(null)

      // FUTURE ADDS
      // allocationsAllowedStartDate: this.attr(''),
      // allocationsAllowedEndDate: this.attr(''),
    };
  }

  // 'valueProposition' => // DEFINES OFFER TYPE -> discount off check
  //  { 'percentDiscount' => 0.1,
  //    'excludedItemAttributes' => [],
  //    'valuePropositionType' => 'CheckHeaderPercentageDiscountModel' }, // OR CheckHeaderDollarDiscountModel

  // NOTE: value proposition shape/available fields ->
  // {
  //   valuePropositionType
  //   percentDiscount
  //   dollarDiscount
  //   maximumDiscountedQuantity

  //   maximumDiscount -> not supporting yet
  //   maximumDiscountQuantity -> not supporting
  //   maximumTotalDiscount -> not supporting
  // }

  // EX: constraints => [
  //   {
  //     'minimum' => 8.0,
  //     'excludedItemAttributes' => [],
  //     'constraintType' => 'MinimumPurchaseOfferConstraintModel'
  //   },
  //   {
  //     'minimumQualifyingCount' => 2,
  //     'itemGroupPublicId' => 'IG23khlvasdkupo2',
  //     'constraintType' => 'SpecifiedItemOfferConstraintModel'
  //   }
  // ]


  get isDraft() {
    return this.state === offerStates.DRAFT;
  }

  get isLive() {
    return this.state === offerStates.LIVE;
  }

  get isExpired() {
    return this.state === offerStates.EXPIRED;
  }

  static getOfferName(offerGuid) {
    return this.find(offerGuid)?.name;
  }

  static liveOffers() {
    return this.query()
      .where('state', offerStates.LIVE)
      .orderBy('name')
      .get();
  }

  static canSetAutoApplicable() {
    return this.query()
      .where('autoApplicable', true)
      .where('state', offerStates.LIVE)
      .count() < 3;
  }

  static clone(offer) {
    return {
      ...offer,
      guid: null,
      $id: null,
      state: offerStates.DRAFT,
      name: `Cloned ${offer.name}`,
      redemptionsAllowedStartDate: null,
      redemptionsAllowedEndDate: null
    };
  }

  // STATE //////////////////////
  static state() {
    return {
      fetchingAll: false,
      fetching: false,
      submitting: false
    };
  }

  static $state() {
    return this.store().state.entities.offers;
  }


  // ACTIONS //////////////////////
  static async fetchOffers() {
    try {
      this.commit((state) => {
        state.fetchingAll = true;
      });

      const merchantId = this.store().state.entities.merchants.selectedMerchantId;

      const { data } = await http.get(`merchants/${merchantId}/offers`);

      this.create({
        data: data.offers
      });
    }

    catch (error) {
      throw error;
    }

    finally {
      this.commit((state) => {
        state.fetchingAll = false;
      });
    }
  }

  static async fetchOffer(offerGuid) {
    try {
      this.commit((state) => {
        state.fetching = true;
      });

      const merchantId = this.store().state.entities.merchants.selectedMerchantId;

      const { data } = await http.get(`merchants/${merchantId}/offers/${offerGuid}`);
      this.insert({
        data: data.offer
      });
    }

    catch (error) {
      throw error;
    }

    finally {
      this.commit((state) => {
        state.fetching = false;
      });
    }
  }

  static async createOffer(offer) {
    try {
      this.commit((state) => {
        state.submitting = true;
      });

      const merchantId = this.store().state.entities.merchants.selectedMerchantId;

      const response = await http.post(`merchants/${merchantId}/offers`, { offer });

      this.insert({ data: response.data.offer });

      return response.data.offer;
    }
    catch (error) {
      throw error;
    }
    finally {
      this.commit((state) => {
        state.submitting = false;
      });
    }
  }

  static async updateOffer(offer) {
    try {
      this.commit((state) => {
        state.submitting = true;
      });

      const merchantId = this.store().state.entities.merchants.selectedMerchantId;

      const response = await http.put(`merchants/${merchantId}/offers/${offer.guid}`, { offer });
      this.update({ data: response.data.offer });
    }
    catch (error) {
      throw error;
    }
    finally {
      this.commit((state) => {
        state.submitting = false;
      });
    }
  }

  static async finalizeOffer(offerGuid) {
    try {
      this.commit((state) => {
        state.submitting = true;
      });

      const merchantId = this.store().state.entities.merchants.selectedMerchantId;

      const response = await http.put(`merchants/${merchantId}/offers/${offerGuid}/ready`);
      this.update({ data: response.data.offer });
    }
    catch (error) {
      throw error;
    }
    finally {
      this.commit((state) => {
        state.submitting = false;
      });
    }
  }

  static async createCouponImage({ offerImage, channel }) {
    try {
      const merchantId = this.store().state.entities.merchants.selectedMerchantId;

      const formData = new FormData();
      formData.append('couponImage[file]', offerImage);
      formData.append('couponImage[displayChannelName]', channel);
      const response = await http.post(
        `merchants/${merchantId}/coupons/images`,
        formData,
        { headers: { 'Content-Type': 'multipart/form-data' } }
      );
      return response.data;
    }
    catch (error) {
      throw error;
    }
  }
}
