<template>
  <div class="card">
    <div class="card-content">
      <div class="is-flex justify-between">
        <p class="subtitle is-4">
          Type & Discount
        </p>
        <b-button
          v-if="!offerReadOnly"
          type="is-primary"
          inverted
          @click="$emit('navigate-to-step', 1)"
        >
          Edit
        </b-button>
      </div>
      <div class="is-grid col-2 pad-sm has-border-bottom has-border-grey-lighter">
        <p class="title is-6 mar-none">Offer Type:</p>
        <p class="subtitle is-6 mar-none">
          {{ offerTypeDisplay }}
        </p>
      </div>
      <div class="is-grid col-2 pad-sm has-border-bottom has-border-grey-lighter">
        <p class="title is-6 mar-none">Amount:</p>
        <p class="subtitle is-6 mar-none">
          {{ discountAmountDisplay }}
        </p>
      </div>
      <div
        v-if="offerType === valuePropositionTypes.ITEM.FE_TYPE"
        class="is-grid col-2 pad-sm has-border-bottom has-border-grey-lighter"
      >
        <p class="title is-6 mar-none">Applies To:</p>
        <p class="subtitle is-6 mar-none">
          {{ appliesToDisplay }}
        </p>
      </div>
      <div
        v-if="offerType === valuePropositionTypes.ITEM.FE_TYPE"
        class="is-grid col-2 pad-sm has-border-bottom has-border-grey-lighter"
      >
        <p class="title is-6 mar-none">Discounts The:</p>
        <p class="subtitle is-6 mar-none">
          {{ discountDisplay }}
        </p>
      </div>
      <div
        v-if="valueProposition.valuePropositionType === valuePropositionTypes.CHECK.PERCENT"
        class="is-grid col-2 pad-sm has-border-bottom has-border-grey-lighter"
      >
        <p class="title is-6 mar-none">Max Discount:</p>
        <p class="subtitle is-6 mar-none">
          {{ valueProposition.maximumDiscount ? `$${valueProposition.maximumDiscount}` : 'No max discount set' }}
        </p>
      </div>
      <div
        v-if="offerType === valuePropositionTypes.ITEM.FE_TYPE"
        class="is-grid col-2 pad-sm has-border-bottom has-border-grey-lighter"
      >
        <p class="title is-6 mar-none">Applicable {{ valueProposition.itemGroup.itemGroupType === itemGroupTypes.CATEGORIES ? 'Categories' : 'Items' }}:</p>
        <p class="subtitle is-6 mar-none">
          {{ applicableMenuResourcesDisplay }}
        </p>
      </div>
      <div
        v-if="offerType === valuePropositionTypes.ITEM.FE_TYPE && modifierRestrictionDisplay"
        class="is-grid col-2 pad-sm has-border-bottom has-border-grey-lighter"
      >
        <p class="title is-6 mar-none">Required Modifier(s):</p>
        <p class="subtitle is-6 mar-none">
          {{ modifierRestrictionDisplay }}
        </p>
      </div>
      <div v-if="offerType === valuePropositionTypes.CHECK.FE_TYPE || valueProposition.itemGroup.itemGroupType !== itemGroupTypes.MENU_ITEMS" class="is-grid col-2 pad-sm has-border-bottom has-border-grey-lighter">
        <p class="title is-6 mar-none">Excludes:</p>
        <p class="subtitle is-6 mar-none">
          {{ excludedItemAttributeDisplay }}
        </p>
      </div>
      <div class="is-grid col-2 pad-sm">
        <p class="title is-6 mar-none">POS Discount Code:</p>
        <p class="subtitle is-6 mar-none">
          {{ posDiscountCodeName || 'No POS discount code selected' }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
  import http from '@/services/http';
  import merchantMixin from '@/mixins/merchant';
  import PosDiscountConfiguration from '@/store/classes/PosDiscountConfiguration';
  import { valuePropositionTypes, itemDiscountBases, discountedUnitPrecedenceTypes } from '@/constants/offers';
  import { itemAttributes, itemGroupTypes } from '@/constants/items';



  export default {
    name: 'ValuePropositionCard',

    mixins: [merchantMixin],

    props: {
      offer: {
        type: Object,
        required: true
      },

      offerReadOnly: {
        type: Boolean,
        required: true
      }
    },

    data() {
      return {
        availableModifiers: [],
        itemGroupTypes,
        valuePropositionTypes
      };
    },

    computed: {
      valueProposition() {
        return this.offer.valueProposition;
      },

      merchantSupportsMultiPos() {
        return this.$_selectedMerchant.features.supportsMultiPosConstraintsAndValuePropositions;
      },

      offerType() {
        return [
          valuePropositionTypes.ITEM.DOLLAR,
          valuePropositionTypes.ITEM.PERCENT,
          valuePropositionTypes.ITEM.OVERRIDE
        ].some(type => this.valueProposition?.valuePropositionType === type) ? valuePropositionTypes.ITEM.FE_TYPE : valuePropositionTypes.CHECK.FE_TYPE;
      },

      offerTypeDisplay() {
        switch (this.valueProposition.valuePropositionType) {
          case valuePropositionTypes.CHECK.PERCENT:
          case valuePropositionTypes.CHECK.DOLLAR:
            return 'Discounted Check';
          case valuePropositionTypes.ITEM.PERCENT:
          case valuePropositionTypes.ITEM.DOLLAR:
            return `Discounted Item${this.valueProposition.maximumDiscountedQuantity > 1 ? 's' : ''}`;
          case valuePropositionTypes.ITEM.OVERRIDE:
            return 'Item Price Override';
          default:
            return 'No offer type set';
        }
      },

      discountAmountDisplay() {
        switch (this.valueProposition.valuePropositionType) {
          case valuePropositionTypes.CHECK.PERCENT:
          case valuePropositionTypes.ITEM.PERCENT:
            return `${parseFloat(this.valueProposition.percentDiscount * 100).toFixed(0)}% Off`;
          case valuePropositionTypes.CHECK.DOLLAR:
          case valuePropositionTypes.ITEM.DOLLAR:
            return `$${parseFloat(this.valueProposition.dollarDiscount).toFixed(2)} Off`;
          case valuePropositionTypes.ITEM.OVERRIDE:
            return `$${parseFloat(this.valueProposition.discountedPricePerUnit).toFixed(2)}`;
          default:
            return 'No amount set';
        }
      },

      appliesToDisplay() {
        switch (this.valueProposition.itemGroup?.itemGroupType) {
          case itemGroupTypes.ALL_ITEMS:
            return this.buildAllItemsDisplay();
          case itemGroupTypes.MENU_ITEMS:
            return this.buildMenuItemsDisplay();
          case itemGroupTypes.CATEGORIES:
            return this.buildCategoriesDisplay();
          default:
            return 'No Items Selected for Offer';
        }
      },

      applicableMenuResourcesDisplay() {
        switch (this.valueProposition.itemGroup?.itemGroupType) {
          case itemGroupTypes.ALL_ITEMS:
            return 'All Items';
          case itemGroupTypes.MENU_ITEMS:
            if (this.merchantSupportsMultiPos) {
              return this.valueProposition.itemGroup.posItems.map(posItem => `${posItem.displayName} (${posItem.posType})`).join(', ') || 'No menu items selected for offer';
            }
            return this.valueProposition.itemGroup.menuItems.map(resource => resource.displayName).join(', ') || 'No menu items selected for offer';
          case itemGroupTypes.CATEGORIES:
            return this.valueProposition.itemGroup.categoryObjects.map(resource => resource.displayName).join(', ') || 'No categories selected for offer';
          default:
            return '';
        }
      },

      modifierRestrictionDisplay() {
        const itemGroup = this.valueProposition?.itemGroup;
        const selectedModifierIds = itemGroup?.requiredModifications?.[0]?.qualifyingModifierMenuItemIds || [];
        const modifiers = selectedModifierIds.map(id => this.availableModifiers.find(mod => mod.id === id));
        const modifierNames = modifiers.filter(mod => mod).map(mod => mod.displayName).join(', ');
        return modifierNames.length ? modifierNames : '';
      },

      excludedItemAttributeDisplay() {
        const excludedItemAttributes = this.valueProposition.excludedItemAttributes || this.valueProposition.itemGroup?.excludedItemAttributes;
        if (excludedItemAttributes?.length) {
          const itemAttributeEntries = Object.values(itemAttributes).reduce((acc, value) => ({ ...acc, [value.id]: value.display }), {});
          return excludedItemAttributes.map(attribute => itemAttributeEntries[attribute]).join(', ');
        }
        else {
          return 'None';
        }
      },

      discountDisplay() {
        const type = this.valueProposition.discountedUnitPrecedenceType || discountedUnitPrecedenceTypes.HIGHEST_UNIT_PRICE_FIRST;
        if (type === discountedUnitPrecedenceTypes.HIGHEST_UNIT_PRICE_FIRST) {
          return 'Highest Priced Item';
        }
        else {
          return 'Lowest Priced Item';
        }
      },

      posDiscountCodes() {
        return PosDiscountConfiguration.all();
      },

      posDiscountCodeName() {
        return this.posDiscountCodes.find(pdc => pdc.publicId === this.offer.cardfreePosDiscountConfigurationPublicId)?.displayName;
      }
    },

    watch: {
      'valueProposition.itemGroup.requiredModifications': {
        immediate: true,
        deep: true,
        handler: 'fetchModifiers'
      }
    },

    methods: {
      buildAllItemsDisplay() {
        const appliesToDisplay = this.valueProposition.maximumDiscountedQuantity > 1 ? `Up to ${this.valueProposition.maximumDiscountedQuantity}` : 'Any';
        let itemDisplay = `${appliesToDisplay} Main Menu Item${this.valueProposition.maximumDiscountedQuantity > 1 ? 's' : ''}`;

        if (this.valueProposition.itemDiscountBasis === itemDiscountBases.MAIN_MENU_ITEM_ALL_MODS.id) {
          itemDisplay += ' and Any Modifiers';
        }

        return itemDisplay;
      },

      buildMenuItemsDisplay() {
        const itemDisplay = this.valueProposition.maximumDiscountedQuantity > 1 ? 'Items' : 'Item';
        const modifierDisplay = this.valueProposition.itemGroup.requiredModifications.length > 1 ? 'Modifiers' : 'Modifier';
        const buildDisplay = discountBasisDisplay => `${this.valueProposition.maximumDiscountedQuantity} ${discountBasisDisplay}`;
        switch (this.valueProposition.itemDiscountBasis) {
          case itemDiscountBases.MAIN_MENU_ITEM.id:
            return buildDisplay(`Main Menu ${itemDisplay}`);
          case itemDiscountBases.MAIN_MENU_ITEM_ALL_MODS.id:
            return buildDisplay(`Main Menu ${itemDisplay} and All Modifiers`);
          case itemDiscountBases.MAIN_MENU_ITEM_MATCHING_MODS.id:
            return buildDisplay(`Main Menu ${itemDisplay} and Matching ${modifierDisplay}`);
          case itemDiscountBases.MATCHING_MODS.id:
            return buildDisplay(`Matching ${modifierDisplay} Only`);
          default:
            return 'No Discount Basis set';
        }
      },

      buildCategoriesDisplay() {
        const itemStem = this.buildMenuItemsDisplay();
        const itemDisplay = this.valueProposition.itemGroup.categoryObjects.length > 1 ? 'Categories' : 'Category';
        return `${itemStem} from ${this.valueProposition.itemGroup.categoryObjects.length} ${itemDisplay}`;
      },

      async fetchModifiers() {
        const itemGroup = this.valueProposition.itemGroup;
        if (!itemGroup) return;

        const itemType = itemGroup.itemGroupType === itemGroupTypes.CATEGORIES ? 'category' : 'item';
        const resources = itemGroup.itemGroupType === itemGroupTypes.CATEGORIES
          ? itemGroup.categoryObjects
          : itemGroup.menuItems;

        if (resources.length && itemGroup.requiredModifications?.length) {
          try {
            const searchableIds = resources.map(resource => `menu_${itemType}_ids[]=${resource.id}`).join('&');
            const { data } = await http.get(
              `merchants/${this.$_selectedMerchantId}/menu_item_modifier_groups?${searchableIds}`
            );
            this.availableModifiers = data.menuItemModifierGroups.reduce((acc, itemModifierGroup) => (
              [
                ...acc, ...itemModifierGroup.menuItemModifiers.map(modifier => (
                  {
                    id: modifier.menuItemId,
                    displayName: modifier.displayName
                  }
                ))
              ]
            ), []);
          }
          catch (error) {
            this.$_onRequestError({
              toastOptions: {
                message: 'There was an error fetching the modifiers'
              },
              error
            });
          }
        }
      }
    }
  };
</script>
