<template>
  <div
    data-test-id="fixed-points-inputs"
  >
    <validated-input
      label="Points"
      name="points"
      :rules="{
        required: true
      }"
    >
      <numberinput-fractional
        v-model="value.awardPolicy.points"
        :min="0.01"
        :default-value="1"
      />
    </validated-input>
  </div>
</template>

<script>
  export default {
    name: 'FixedPointsInputs',

    props: {
      value: {
        type: Object,
        required: true
      }
    }
  };
</script>
