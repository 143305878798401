<template>
  <div class="sticky-save-container is-flex-column is-full-width">
    <b-loading :active="loading" :is-full-page="false" />
    <div class="sticky-header pad-y-xs">
      <div class="is-flex justify-between align-start mar-b-xs">
        <h4 v-if="title" class="has-text-weight-bold is-size-4">
          {{ title }}
        </h4>
        <div class="sticky-save-button">
          <b-button
            rounded
            class="is-bold"
            size="is-medium"
            native-type="submit"
            type="is-primary"
            :loading="saving"
            :disabled="disabled"
          >
            {{ buttonText }}
          </b-button>
        </div>
      </div>
      <slot name="header" />
    </div>
    <div class="scrollable-content">
      <slot />
    </div>
  </div>
</template>

<script>
  export default {
    name: 'StickySaveContainer',

    props: {
      saving: {
        type: Boolean,
        default: false
      },
      loading: {
        type: Boolean,
        default: false
      },
      disabled: {
        type: Boolean,
        default: false
      },
      buttonText: {
        type: String,
        default: 'Save'
      },
      title: {
        type: String,
        default: ''
      }
    }
  };
</script>

<style lang="sass" scoped>
  .sticky-save-container
    max-width: 100%

  .sticky-header
    position: sticky
    top: 0
    z-index: 5
    background-color: $white
    background: linear-gradient(to top, rgba(255,255,255,0), rgba(255,255,255,1) $size-small)
    width: calc(100% - 4rem)
    border: none

  .sticky-save-button
    flex-shrink: 0
    margin-left: 1rem

  .scrollable-content
    flex-grow: 1
    padding: 0 2rem 2rem
    margin-bottom: 1rem
</style>
