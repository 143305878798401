class Queue {
  constructor() {
    this.queue = [];
  }

  dequeue(index) {
    this.queue.splice(index, 1);
  }

  enqueue(item) {
    this.queue.push(item);
  }

  peek(numOfItems) {
    return this.queue.slice(0, numOfItems);
  }

  getQueue() {
    return this.queue;
  }

  clear() {
    this.queue = [];
  }

  size() {
    return this.queue.length;
  }
}

export default Queue;
