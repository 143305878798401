<template>
  <div
    class="card mar-xs is-clickable glow-on-hover"
    :class="{ 'is-selected': isSelected, 'warning-glow': deliveryAwaitingPickup }"
    data-test-id="expo-order-status-card"
    @click="selectOrder(order.orderId)"
  >
    <div class="card-content pad-sm">
      <div>
        <span
          class="is-size-7 has-text-grey"
          data-test-id="fulfillment-type"
        >
          {{ order.fulfillmentType }}
        </span>
        <!-- Need order.courier data to complete this -->
        <b-tag
          v-if="order.driverStatus"
          class="mar-l"
          type="is-light"
          data-test-id="driver-status"
          :icon="order.driverStatus === 'early' ? 'clock-rotate-left' : 'circle-exclamation'"
        >
          Driver {{ order.driverStatus }}
        </b-tag>
      </div>
      <div
        class="is-flex justify-between"
        data-test-id="order-details"
      >
        <span class="has-text-weight-bold is-size-4 is-inline-block">
          {{ order.pickupDate | moment('h:mm A') }}
        </span>
        <div>
          <span class="is-inline-block">
            {{ order.guestName }} |
          </span>
          <span class="has-text-weight-bold">
            {{ order.total | dollars }}
            <b-icon
              v-if="order.orderItemTotal >= order.store.expoLargeOrderItemTotal"
              icon="bag-shopping"
              size="is-small"
              class="mar-l-xs"
              data-test-id="large-order-icon"
            />
          </span>
        </div>
      </div>
      <b-tag
        :type="tagTypes[orderStatus]"
        class="is-outlined"
        :class="{ 'is-light': isLightTagTypes.includes(orderStatus) }"
        data-test-id="order-status"
      >
        {{ capitalCase(orderStatus) }}
      </b-tag>
    </div>
  </div>
</template>

<script>
  import { capitalCase } from 'change-case';
  import moment from 'moment-timezone';
  import { mapActions, mapState } from 'vuex';

  export default {
    name: 'ExpoOrderStatusCard',

    props: {
      order: {
        type: Object,
        required: true
      }
    },

    data: () => ({
      capitalCase,
      moment
    }),

    computed: {
      ...mapState('orderExpo', ['selectedOrderId', 'tagTypes', 'isLightTagTypes']),

      orderStatus() {
        return this.order.delivery ? this.order.delivery.status : 'Customer Pickup';
      },

      isSelected() {
        return this.selectedOrderId === this.order.orderId;
      },

      deliveryAwaitingPickup() {
        return this.order.delivery
          && this.order.delivery.status === 'PickupPending'
          && this.order.expoStatus === 'Past';
      }
    },

    methods: {
      ...mapActions('orderExpo', ['selectOrder'])
    }
  };
</script>

<style lang='sass' scoped>
.glow-on-hover
  transition: box-shadow .5s ease
  &:hover
    box-shadow: 0 0 5px $primary-light, 0 0 10px $primary-light
  &:active
    transform: scale(0.97)
    box-shadow: none
    background-color: $primary-lightest

.warning-glow
  box-shadow: 0 0 0 0.125em rgba($warning, 0.25), inset -1px -1px $warning, inset 1px 1px $warning

.is-selected
  border: 2px solid $primary
  background-color: $primary-lighter
</style>
