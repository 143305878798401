<template>
  <validated-form
    ref="form"
    v-slot="{ dirty }"
    form-id="storeForm"
    @valid-submit="handleSubmit"
  >
    <store-form-inputs v-model="form" :mode="mode" />
    <template v-if="canEdit('description')">
      <div class="footer-buttons">
        <b-button
          id="submit-button"
          rounded
          size="is-medium"
          native-type="submit"
          type="is-primary"
          :loading="submitting"
          class="is-bold"
          :class="mode === 'update' && dirty && 'pulse-slow'"
        >
          Save
        </b-button>
      </div>
    </template>
  </validated-form>
</template>

<script>
  import logger from '@/services/logger';
  import Store from '@/store/classes/Store';
  import merchantMixin from '@/mixins/merchant';
  import StoreAttribute from '@/store/classes/StoreAttribute';
  import filterObjectKeys from '@/helpers/filter-object-keys';
  import MerchantLoyaltyProviderConfiguration from '@/store/classes/MerchantLoyaltyProviderConfiguration';
  import StoreFormInputs from '@/components/pages/forms/store-form-inputs.vue';
  import posTypes from '@/constants/posTypes';
  import MerchantAppSetting from '@/store/classes/MerchantAppSetting';


  export default { // TEST ALL
    name: 'StoreForm',
    components: { StoreFormInputs },

    mixins: [merchantMixin],

    props: {
      mode: {
        type: String,
        default: 'create',
        validator(value) {
          return ['create', 'read', 'update'].includes(value);
        }
      }
    },

    data() {
      return {
        form: {},
        whitelistedKeys: [
          'storeId',
          'businessLocationId',
          'posLocationId',
          'description',
          'displayName',
          'addressLine1',
          'addressLine2',
          'city',
          'region',
          'postalCode',
          'countryCode',
          'ianaTimezoneId',
          'latitude',
          'longitude',
          'mapsUrlOverride',
          'emailAddress',
          'phoneNumber',
          'allowAfterHoursOrdering',
          'totalSalesTaxRate',
          'posTypeId',
          'paymentLocationId',
          'posLocationId',
          'merchantLocationId',
          'leadPickupTime',
          'storeMappingAttributes',
          'geofenceOfferRadiusInFeet',
          'externalDeviceMasterPasscode',
          'externalDeviceSecondaryPasscode'
        ]
      };
    },

    computed: {
      hasSquarePOS() {
        return this.form.posTypeId === posTypes.Square;
      },

      hasSquarePaymentGateway() {
        return this.$_selectedMerchant.merchantPaymentGateways.some(mpg => (
          mpg.paymentGateway.name === 'Square'
        ));
      },

      store() {
        return this.mode === 'create' ? new Store() : Store.find(this.$route.params.storeId);
      },

      submitting() {
        return Store.$state().submitting;
      }
    },

    watch: {
      store: {
        handler(newVal) {
          this.form = JSON.parse(JSON.stringify(newVal));
        },
        immediate: true
      }
    },

    mounted() {
      if (this.mode === 'update' && this.form.errors && Object.keys(this.form.errors).length) {
        this.$nextTick(async () => {
          await this.$refs.form.$children[0].validate();
          this.$refs.form.focusFirstInvalid();
        });
      }
    },

    async created() {
      await Promise.all([
        this.fetchStoreAttributes(),
        this.fetchMerchantLoyaltyProviderConfigurations()
      ]);

      if (this.$can('read', 'MerchantAppSetting')) {
        await this.fetchMerchantAppSettings();
      }

      this.form = JSON.parse(JSON.stringify(this.store));

      this.form.selectedExternalStoreId = this.store.posLocationId;
    },

    methods: {
      trimLatitudeAndLongitude() {
        const { latitude, longitude } = this.form;
        const latitudeArray = latitude.toString().split('.');
        const longitudeArray = longitude.toString().split('.');
        const shouldTrimLatitude = latitudeArray.length > 1 && latitudeArray[1].length > 6;
        const shouldTrimLongitude = longitudeArray.length > 1 && longitudeArray[1].length > 6;

        if (shouldTrimLatitude) {
          const trimmedLatDecimals = latitudeArray[1].substring(0, 6);
          const updatedLat = `${latitudeArray[0]}.${trimmedLatDecimals}`;
          this.form.latitude = updatedLat;
        }

        if (shouldTrimLongitude) {
          const trimmedLongDecimals = longitudeArray[1].substring(0, 6);
          const updatedLong = `${longitudeArray[0]}.${trimmedLongDecimals}`;
          this.form.longitude = updatedLong;
        }
      },

      async fetchMerchantAppSettings() {
        try {
          await MerchantAppSetting.fetchMerchantAppSettings(this.$_selectedMerchantId);
        }
        catch (error) {
          this.$_onRequestError({ toastOptions: { message: 'Unable to fetch merchant application settings' }, error });
        }
      },

      async fetchMerchantLoyaltyProviderConfigurations() {
        try {
          await MerchantLoyaltyProviderConfiguration.fetchMerchantLoyaltyProviderConfigurations(this.$_selectedMerchantId);
        }
        catch (error) {
          this.$_onRequestError({
            error,
            toastOptions: { message: 'Unable to fetch merchant loyalty provider configurations' }
          });
        }
      },

      async fetchStoreAttributes() {
        try {
          await StoreAttribute.fetchStoreAttributes();
        }
        catch (error) {
          this.$_onRequestError({
            toastOptions: {
              message: 'There was an error fetching the store attributes'
            },
            error
          });
        }
      },

      canEdit(field) {
        return this.$can(this.mode, 'StoreMapping', field);
      },

      async addStore() {
        this.trimLatitudeAndLongitude();

        try {
          await Store.addStore(filterObjectKeys(this.form, this.whitelistedKeys), true);
          this.$_onRequestSuccess({
            toastOptions: { message: `<b>${this.form.description}</b> has been successfully added!` },
            options: {
              closeParent: true
            }
          });
        }
        catch (error) {
          this.$_onRequestError({ error, toastOptions: { message: 'Unable to add new location' } });
        }
      },

      async updateStore() {
        this.trimLatitudeAndLongitude();

        try {
          // We only want to update certain values based on their initial data, payment gateway, and pos- https://cardfree.atlassian.net/browse/TOOL-3587
          if (this.store.merchantLocationId) {
            delete this.form.merchantLocationId;
          }
          if (!this.hasSquarePaymentGateway) {
            delete this.form.paymentLocationId;
          }
          if (!this.hasSquarePOS) {
            delete this.form.posLocationId;
          }

          await Store.updateStore(filterObjectKeys(this.form, this.whitelistedKeys));

          this.$_onRequestSuccess({
            toastOptions: { message: `<b>${this.form.description}</b> has been successfully updated!` }
          });
        }
        catch (error) {
          this.$_onRequestError({ error, toastOptions: { message: 'Unable to update location' } });
        }
      },

      async handleSubmit() {
        try {
          switch (this.mode) {
            case 'create':
              await this.addStore();
              break;

            case 'update':
              await this.updateStore();
              break;

            default:
              break;
          }
        }
        catch (error) {
          logger.error(error);
        }
      }
    }
  };
</script>
