function findCloseableParent(startingComponent) {
  let currentComponent = startingComponent;
  let counter = 0; // guards against a possible endless loop where there is no ancestor component with a close method

  while (counter < 5 && currentComponent && !currentComponent.close) {
    counter += 1;
    currentComponent = currentComponent.$parent;
  }

  return counter > 4 ? undefined : currentComponent;
}

export default findCloseableParent;
