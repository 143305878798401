export const stringTypes = [
  'aosAppId',
  'aosUrl',
  'app-rating-survey-type',
  'cardfree-branding-placement',
  'default-store-id',
  'emv-display-mode',
  'emv-tablesearch-keyboard-mode',
  'iosAppId',
  'iosUrl',
  'kiosk-alternate-idle-image-url',
  'kiosk-and-emv-master-passcode',
  'kiosk-hero-image-url',
  'kiosk-idle-image-url',
  'kiosk-merchant-description',
  'kiosk-splash-image-url',
  'menu-layout',
  'survey-placement',
  'single-scan-menu-webview-url',
  'qr-code-field',
  'menu-webview-url',
  'menu-image-ratio',
  'menu-verbosity'
];

export const numberTypes = [
  'app-rating-days',
  'gift-card-limit-on-order',
  'tip-screen-timeout-seconds',
  'app-rating-order-count',
  'emv-marketing-opt-in-timeout',
  'emv-marketing-promo-timeout'
];

export const dollarTypes = [
  'single-scan-starting-tip-amount',
  'single-scan-incrementation-amount'
];
