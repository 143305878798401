import { Model } from '@vuex-orm/core';


export default class MerchantContentMetadata extends Model {
  static entity = 'merchantContentMetadatas'

  static fields() {
    return {
      contentTypePropertyId: this.attr(''),
      id: this.attr(''),
      merchantContentId: this.attr(''),
      updatedAt: this.attr(''),
      value: this.attr('')
    };
  }
}
