<template>
  <validated-form
    ref="form"
    form-id="addEditPromoCodeGroupForm"
    :disabled="isReadOnly"
    @valid-submit="handleSubmit"
  >
    <modal-card
      :title="isReadOnly ? promoCodeGroup.name : 'Create Promo Code Group'"
      class="modal-card"
    >
      <b-loading :active="isFetchingOffers" :is-full-page="false" />
      <p class="subtitle is-5 mar-b-sm pad-t-none mar-t-none">
        Group Settings
      </p>

      <validated-text-input
        v-model="form.name"
        label="Name"
        name="name"
        rules="required"
        sub-label="Internal name used for searching and sorting your code group"
        type="text"
      />

      <validated-input
        label="Offer"
        name="offerPublicId"
        rules="required"
        tooltip="Discount amount, constraints, timeframes, and other offer details will apply to the promo codes"
        tooltip-placement="right"
      >
        <dropdown-menu
          v-model="form.offerPublicId"
          expanded
        >
          <dropdown-button
            slot="trigger"
            :disabled="isReadOnly"
            :value="getSelectedOfferName(form.offerPublicId) || 'Select an Offer...'"
            :class="{ 'has-text-grey': !form.offerPublicId }"
          />
          <b-dropdown-item
            v-for="offer in liveOffers"
            :key="offer.guid"
            :value="offer.guid"
            class="offer-select-item"
          >
            <p>{{ offer.name }}</p>
            <p v-if="offer.description" class="is-size-7 has-text-weight-normal wrap-text">
              {{ offer.description }}
            </p>
            <p class="is-size-7 has-text-weight-normal">
              {{ getOfferDateRange(offer) }}
            </p>
          </b-dropdown-item>
        </dropdown-menu>
      </validated-input>

      <validated-input
        label="Claim Style"
        name="claimStyle"
        rules="required"
      >
        <dropdown-menu
          v-model="claimStyle"
          expanded
        >
          <dropdown-button
            slot="trigger"
            :disabled="isReadOnly"
            :value="claimStyle"
          >
            <div class="is-flex align-center">
              <span>{{ claimStyles[claimStyle].display }}</span>
              <span class="has-text-grey side-title">{{ claimStyles[claimStyle].subtitle }}</span>
            </div>
          </dropdown-button>
          <b-dropdown-item
            v-for="(style, key) in claimStyles"
            :key="key"
            :value="style.id"
            :active="style.id === claimStyle"
            :class="[{ 'is-active': key === claimStyle }, 'claim-select-item']"
          >
            <span class="mar-r-xs">{{ style.display }}</span>
            <span class="has-text-grey side-title">{{ style.subtitle }}</span>
          </b-dropdown-item>
        </dropdown-menu>
      </validated-input>

      <div class="is-grid col-2 gap-x">
        <validated-input
          label="Start Date"
          name="claimableStartDate"
          rules="required"
        >
          <b-datepicker
            v-model="claimableStartDate"
            :years-range="dys_ranges.startDate"
            placeholder="Select Date"
            icon="calendar-alt"
            class="has-extra-shadow"
            :append-to-body="true"
            position="is-top-right"
            :min-date="moment().add(1, 'day').startOf('day').toDate()"
            :events="claimableEndDate ? [{ date: claimableEndDate, type: 'is-danger' }] : []"
            indicators="bars"
            :focused-date="claimableStartDate"
            @change-year="(year) => $_handleYearChange({ year, type: 'startDate', yearsInPast: 10, yearsInFuture: 30 })"
          >
            <div class="buttons is-right">
              <b-button @click="claimableStartDate = null">Clear</b-button>
            </div>
          </b-datepicker>
        </validated-input>

        <validated-input
          label="End Date"
          name="claimableEndDate"
          rules="required"
        >
          <b-datepicker
            v-model="claimableEndDate"
            :years-range="dys_ranges.endDate"
            placeholder="Select Date"
            icon="calendar-alt"
            class="has-extra-shadow"
            :append-to-body="true"
            position="is-top-left"
            :min-date="claimableStartDate || moment().toDate()"
            :events="claimableStartDate ? [{ date: claimableStartDate, type: 'is-success' }] : []"
            indicators="bars"
            :focused-date="claimableStartDate"
            @change-year="(year) => $_handleYearChange({ year, type: 'endDate', yearsInPast: 10, yearsInFuture: 30 })"
          >
            <div class="buttons is-right">
              <b-button @click="claimableEndDate = null">Clear</b-button>
            </div>
          </b-datepicker>
        </validated-input>
      </div>

      <hr class="mar-y">

      <div>
        <p class="subtitle is-5 mar-b-sm">
          Claim Limits
          <b-tag
            v-if="isDisabledClaimLimits"
            type="is-warning is-light"
            icon="lock-hashtag"
          >
            Selected offer used by another group
          </b-tag>
          <span v-else class="has-text-grey">
            (Optional)
          </span>
        </p>

        <div class="is-grid col-2 gap-x mar-t-xs">
          <validated-text-input
            v-model="form.claimLimitPerAccount"
            label="Per Account"
            name="claimLimitPerAccount"
            tooltip="Limit code use per account. Only logged-in uses count."
            tooltip-placement="right"
            :icon="isDisabledClaimLimits ? 'lock-hashtag' : 'hashtag'"
            type="number"
            class="hashtag-icon"
            :disabled="isDisabledClaimLimits || isReadOnly"
          />
          <validated-text-input
            v-model="form.claimLimitPerDistributionCode"
            label="Per Promo Code"
            name="claimLimitPerDistributionCode"
            tooltip="Limit total code usage"
            tooltip-placement="top"
            :icon="isDisabledClaimLimits ? 'lock-hashtag' : 'hashtag'"
            class="hashtag-icon"
            type="number"
            :disabled="isDisabledClaimLimits || isReadOnly"
          />
        </div>

        <div
          v-if="isReadOnly"
          class="is-grid col-2 gap-x metadata"
        >
          <div class="has-text-grey no-wrap-text">
            Group ID: <span class="is-monospaced">{{ promoCodeGroup.publicId }}</span>
          </div>
          <div class="has-text-grey no-wrap-text created-on">
            Created on {{ moment(promoCodeGroup.createdDateTime).format('M/D/YY') }}
          </div>
        </div>
      </div>

      <template #footer>
        <div class="buttons all-bold">
          <b-button
            rounded
            @click="$_confirmCloseModal({ programmatic: true })"
          >
            Cancel
          </b-button>
          <b-button
            v-tabbable
            rounded
            native-type="submit"
            type="is-primary"
            :loading="isSubmitting"
            :disabled="isSubmitting"
          >
            Save
          </b-button>
        </div>
      </template>
    </modal-card>
  </validated-form>
</template>

<script>
  import moment from 'moment-timezone';
  import { claimStyles } from '@/constants/offerDistributions';
  import OfferDistribution from '@/store/classes/OfferDistribution';
  import Offer from '@/store/classes/Offer';
  import dynamicYearSelectMixin from '@/mixins/dynamic-year-select';
  import confirmModalCloseMixin from '@/mixins/confirm-modal-close';

  export default {
    mixins: [confirmModalCloseMixin, dynamicYearSelectMixin],

    props: {
      promoCodeGroup: {
        type: Object,
        default: () => ({})
      }
    },

    data() {
      return {
        moment,
        claimStyles,
        isFetchingOffers: false,
        form: {
          name: '',
          offerPublicId: '',
          claimStyle: 1,
          claimableStartDate: null,
          claimableEndDate: null,
          claimLimitPerAccount: null,
          claimLimitPerDistributionCode: null
        }
      };
    },


    computed: {
      isReadOnly() {
        return !!this.promoCodeGroup?.publicId;
      },

      liveOffers() {
        return Offer.liveOffers();
      },

      isSubmitting() {
        return OfferDistribution.$state().submitting;
      },

      isDisabledClaimLimits() {
        return OfferDistribution.query()
          .where(record => record.publicId !== this.promoCodeGroupId)
          .where('offerPublicId', this.form.offerPublicId)
          .count() > 0;
      },

      claimStyle: {
        get() {
          return Object.keys(this.claimStyles).find(key => this.claimStyles[key].id === this.form.claimStyle);
        },
        set(value) {
          const claimStyle = Object.keys(this.claimStyles).find(key => this.claimStyles[key].id === value);
          this.$set(this.form, 'claimStyle', this.claimStyles[claimStyle].id);
        }
      },

      claimableStartDate: {
        get() {
          return this.form.claimableStartDate;
        },
        set(date) {
          this.form.claimableStartDate = date;
        }
      },

      claimableEndDate: {
        get() {
          return this.form.claimableEndDate;
        },
        set(date) {
          this.form.claimableEndDate = date;
        }
      }
    },

    watch: {
      'form.offerPublicId': {
        async handler() {
          if (this.isDisabledClaimLimits) {
            await this.setExistingClaimLimits();
          }
          else {
            this.form.claimLimitPerAccount = null;
            this.form.claimLimitPerDistributionCode = null;
          }
        },
        immediate: true
      }
    },

    async created() {
      await this.onCreated();
    },

    methods: {
      async onCreated() {
        if (this.isReadOnly) {
          this.form = this.$clone({
            ...this.promoCodeGroup,
            claimableStartDate: this.formatDateObject(this.promoCodeGroup.claimableStartDate),
            claimableEndDate: this.formatDateObject(this.promoCodeGroup.claimableEndDate)
          });
        }

        await this.setExistingClaimLimits();
      },

      async setExistingClaimLimits() {
        const existingDistribution = await OfferDistribution.query()
          .where('offerPublicId', this.form.offerPublicId)
          .first();

        if (existingDistribution) {
          this.form.claimLimitPerAccount = existingDistribution.claimLimitPerAccount;
          this.form.claimLimitPerDistributionCode = existingDistribution.claimLimitPerDistributionCode;
        }
      },

      async handleSubmit() {
        if (!this.isReadOnly) {
          this.confirmSavePromoCodeGroup();
        }
      },

      confirmSavePromoCodeGroup() {
        this.$buefy.dialog.confirm({
          title: 'Confirm Promo Code Group Creation',
          message: 'Are you sure you want to save this promo code group? Groups cannot be edited.',
          confirmText: 'Yes',
          cancelText: 'No',
          type: 'is-primary',
          onConfirm: () => this.savePromoCodeGroup()
        });
      },

      async savePromoCodeGroup() {
        try {
          // Pending Implementation by Loyalty Team
          // if (this.isReadOnly) {
          //   return this.updatePromoCodeGroup();
          // }
          const offerDistributionId = await OfferDistribution.addOfferDistribution({ offerDistribution: this.form });

          this.$_onRequestSuccess({
            toastOptions: {
              message: `Successfully added <b>${this.form.name}</b>!`
            },
            options: {
              closeParent: true,
              emit: { name: 'promo-code-group-updated', arg: offerDistributionId }
            }
          });
        }

        catch (error) {
          this.$_onRequestError({
            error,
            toastOptions: {
              message: `Unable to add <b>${this.form.name}</b>!`
            }
          });
        }
      },

      // Pending Implementation by Loyalty Team
      // async updatePromoCodeGroup() {
      //   try {
      //     await OfferDistribution.updateOfferDistribution({ offerDistribution: this.form });

      //     this.$_onRequestSuccess({
      //       toastOptions: {
      //         message: `Successfully updated <b>${this.form.name}</b>!`
      //       },
      //       options: {
      //         closeParent: true,
      //         emit: { name: 'promo-code-group-updated', arg: this.promoCodeGroup.publicId }
      //       }
      //     });
      //   }
      //   catch (error) {
      //     this.$_onRequestError({
      //       error,
      //       toastOptions: {
      //         message: `Unable to update <b>${this.form.name}</b>!`
      //       }
      //     });
      //   }
      // },

      getSelectedOfferName(offerPublicId) {
        return this.liveOffers.find(offer => offer.guid === offerPublicId)?.name;
      },

      getOfferDateRange(offer) {
        const startDate = offer.redemptionsAllowedStartDate;
        const endDate = offer.redemptionsAllowedEndDate;

        const formatDate = date => moment(date).format('MMM DD, YYYY');

        if (moment().isBefore(startDate)) {
          if (endDate) {
            return `Redemptions allowed from ${formatDate(startDate)} through ${formatDate(endDate)}`;
          }
          else {
            return `Redemptions allowed starting ${formatDate(startDate)} and have no end date`;
          }
        }
        else if (endDate) {
          return `Redemptions allowed until ${formatDate(endDate)}`;
        }
        else {
          return 'Redemptions have no end date';
        }
      },

      formatDateObject(dateObj) {
        return dateObj ? moment({ year: dateObj.year, month: dateObj.month - 1, day: dateObj.day }).toDate() : null;
      }
    }
  };
</script>

<style lang="sass" scoped>
  .modal-card
    max-width: 500px

  .offer-select-item, .claim-select-item
    max-width: 460px

  .side-title
    font-size: 0.75rem
    margin-left: 8px
    margin-top: 1px

  .hashtag-icon ::v-deep
    .control
      .icon
        width: 40px
        color: #000
        background-color: #fff
        border: 1px solid #dbdbdb
        border-radius: 4px
        border-top-right-radius: 0
        border-bottom-right-radius: 0

      input
        padding-left: 48px
        border: 1px solid #dbdbdb
        border-radius: 4px

        .monospaced
          font-family: 'Monaco', monospace

  .metadata
    font-size: 0.85rem
    margin-inline: 2px
    margin-top: 15px

    .created-on
      text-align: right
</style>
