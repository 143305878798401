import { Model } from '@vuex-orm/core';
import http from '@/services/http';
import filterObjectKeys from '@/helpers/filter-object-keys';
import AppCodeType from '@/store/classes/AppCodeType';


export default class MerchantAppSetting extends Model {
  static entity = 'merchantAppSettings'

  // FIELDS
  static fields() {
    return {
      id: this.attr(''),
      appCodeTypeId: this.attr(''),
      currentVersion: this.attr(''),
      minimumVersion: this.attr(''),
      merchantId: this.attr(''),
      systemStatusCodeId: this.attr(''),
      appIds: this.attr(''),
      appClipIds: this.attr(''),
      paths: this.attr(''),
      fingerprints: this.attr('')
    };
  }

  static mutators() {
    function mutatorFunction(val) {
      return val && Array.isArray(val) ? val.join(',') : val;
    }

    return {
      appIds(val) {
        return mutatorFunction(val);
      },
      appClipIds(val) {
        return mutatorFunction(val);
      },
      paths(val) {
        return mutatorFunction(val);
      },
      fingerprints(val) {
        return mutatorFunction(val);
      }
    };
  }


  static typesById() {
    return {
      WEB: 1,
      IOS: 2,
      ANDROID: 3,
      KIOSK: 4,
      EXTERNAL_DEVICE: 5
    };
  }

  static hasExternalDevice() {
    return this.query().where('appCodeTypeId', MerchantAppSetting.typesById().EXTERNAL_DEVICE).exists();
  }

  static hasIos() {
    return Object.keys(MerchantAppSetting.typesById()).some(type => type === 'IOS');
  }

  static hasAndroid() {
    return Object.keys(MerchantAppSetting.typesById()).some(key => key === 'ANDROID');
  }

  static configuredMobileAppTypesByName() {
    const types = [];
    if (this.hasIos()) types.push('Ios');
    if (this.hasAndroid()) types.push('Android');
    return types;
  }

  get appCodeName() {
    return AppCodeType.find(this.appCodeTypeId)?.name;
  }

  get hasUniversalLink() {
    if (this.appCodeTypeId === MerchantAppSetting.typesById().IOS) {
      return !!(this.appIds || this.paths || this.appClipIds);
    }
    else if (this.appCodeTypeId === MerchantAppSetting.typesById().ANDROID) {
      return !!(this.appIds || this.fingerprints);
    }
    else {
      return 'N/A';
    }
  }

  // STATE
  static state() {
    return {
      fetching: false,
      submitting: false
    };
  }

  static $state() { // TEST ?
    return this.store().state.entities.merchantAppSettings;
  }



  // ACTIONS
  static async fetchMerchantAppSettings(merchantId) {
    if (this.exists()) return;

    try {
      this.commit((state) => {
        state.fetching = true;
      });

      const response = await http.get(`merchants/${merchantId}/merchant_app_settings`);

      this.create({ data: response.data.merchantAppSettings });
    }

    catch (error) {
      throw error;
    }

    finally {
      this.commit((state) => {
        state.fetching = false;
      });
    }
  }

  static async createMerchantAppSetting(merchantAppSetting) {
    try {
      this.commit((state) => {
        state.submitting = true;
      });

      const acceptedKeys = [
        'appCodeTypeId',
        'currentVersion',
        'minimumVersion',
        'systemStatusCodeId',
        'appIds',
        'appClipIds',
        'paths',
        'fingerprints'
      ];
      const response = await http.post(`merchants/${merchantAppSetting.merchantId}/merchant_app_settings`, {
        merchantAppSetting: filterObjectKeys(merchantAppSetting, acceptedKeys)
      });

      this.insert({ data: response.data.merchantAppSetting });
    }

    catch (error) {
      throw error;
    }

    finally {
      this.commit((state) => {
        state.submitting = false;
      });
    }
  }

  static async updateMerchantAppSetting(merchantAppSetting) {
    try {
      this.commit((state) => {
        state.submitting = true;
      });

      const acceptedKeys = [
        'currentVersion',
        'minimumVersion',
        'systemStatusCodeId',
        'appIds',
        'appClipIds',
        'paths',
        'fingerprints'
      ];
      const response = await http.put(`/merchants/${merchantAppSetting.merchantId}/merchant_app_settings/${merchantAppSetting.id}`, {
        merchantAppSetting: filterObjectKeys(merchantAppSetting, acceptedKeys)
      });

      this.update({ data: response.data.merchantAppSetting });
    }

    catch (error) {
      throw error;
    }

    finally {
      this.commit((state) => {
        state.submitting = false;
      });
    }
  }

  // UTILS
  static hasMobileAppConfigured() {
    return this.query()
      .where('appCodeTypeId', MerchantAppSetting.typesById().IOS)
      .orWhere('appCodeTypeId', MerchantAppSetting.typesById().ANDROID)
      .exists();
  }
}
