import MiniSearch from 'minisearch';
import { capitalCase } from 'change-case';
import levenshtein from 'js-levenshtein';

import GLOBAL_SEARCH_INDEX_JSON from '@/components/global-search/global-search-index.json';

class GlobalSearchService {
  static EXACT_MATCH_BOOST = 5;

  static KEYWORD_BOOST = 3;

  static PARTIAL_MATCH_BOOST = 2;

  static MIN_TERM_LENGTH = 2;

  static THRESHOLD_PERCENTAGE = 20;


  constructor() {
    this.DOCUMENTS = JSON.parse(JSON.stringify(GLOBAL_SEARCH_INDEX_JSON));
    this.specialCharReplacements = {
      '@': 'at',
      '&': 'and',
      at: '@',
      and: '&',
      'kds notifications': 'order notifications',
      'order notifications': 'kds notifications'
    };
    this.miniSearch = this.initializeMiniSearch();
  }

  initializeMiniSearch() {
    const stopWords = new Set(['and', 'or', 'to', 'in', 'a', 'the']);

    const miniSearch = new MiniSearch({
      fields: ['title', 'name', 'breadcrumb', 'additionalInfo', 'description'],
      storeFields: [
        'title', 'description', 'name', 'id', 'parent', 'breadcrumb', 'icon', 'category',
        'params', 'keywords', 'resourceKey', 'permissions', 'additionalInfo'
      ],
      processTerm: term => (stopWords.has(term) || term.length < GlobalSearchService.MIN_TERM_LENGTH ? null : term.toLowerCase()),
      searchOptions: {
        boost: {
          title: 2.5, name: 1.5, breadcrumb: 1.5, description: 1.2, keywords: 1.2
        },
        combineWith: 'AND',
        fuzzy: 0.3,
        prefix: true
      }
    });

    miniSearch.addAll(this.DOCUMENTS);
    return miniSearch;
  }

  setupKeyboardShortcut = (callback) => {
    if (typeof callback === 'function') {
      const handleKeydown = (event) => {
        if ((event.metaKey || event.ctrlKey) && event.key === 'k') {
          event.preventDefault();
          callback();
        }
      };

      window.addEventListener('keydown', handleKeydown);

      return () => {
        window.removeEventListener('keydown', handleKeydown);
      };
    }
  }

  normalizeText(text) {
    if (!text || typeof text !== 'string') return text;

    let normalizedText = text.toLowerCase().replace(/-/g, ' ');

    Object.entries(this.specialCharReplacements).forEach(([char, replacement]) => {
      normalizedText = normalizedText.replace(new RegExp(`\\b${char}\\b`, 'gi'), replacement);
    });

    normalizedText = normalizedText.replace(/\s+/g, ' ').trim();

    return normalizedText;
  }

  search(query) {
    this.query = this.normalizeText(query);
    this.queryWords = this.query.split(/\s+/).filter(word => word.length >= GlobalSearchService.MIN_TERM_LENGTH);

    const keywordResults = this.getKeywordResults();
    const miniSearchResults = this.miniSearch.search(this.query);

    const combinedResults = [...keywordResults, ...miniSearchResults];
    const uniqueResults = this.uniqueResults(combinedResults);
    const boostedResults = this.boostResults(uniqueResults);
    return this.filterRelevantResults(boostedResults);
  }

  /* ----------------------- KEYWORD MATCHING ----------------------- */

  keywordMatches(keywords) {
    return keywords.map((keyword) => {
      const keywordLower = this.normalizeText(keyword);
      const keywordParts = keywordLower.split(/\s+/);

      const matchingQueryWords = this.queryWords.filter(queryWord => keywordParts.some(part => this.isCloseMatch(queryWord, part)));

      const matchCount = matchingQueryWords.length;
      const allQueryWordsMatch = matchCount === this.queryWords.length;

      if (allQueryWordsMatch) {
        const weight = matchCount / Math.max(this.queryWords.length, keywordParts.length);
        return { matches: true, weight, matchedKeyword: keyword, matchCount };
      }

      return null;
    }).filter(Boolean);
  }

  getKeywordResults() {
    return this.DOCUMENTS.flatMap((doc) => {
      const { keywords } = doc;
      if (!keywords || !keywords.length) return [];

      const matches = this.keywordMatches(keywords);

      return matches.map(match => this.createWeightedResult(doc, match.weight, match.matchedKeyword));
    }).filter(Boolean);
  }

  /* ----------------------- RESULT PROCESSING ----------------------- */

  filterRelevantResults = (results) => {
    if (!results.length) return results;
    results.sort((a, b) => b.score - a.score);
    const scoreThreshold = results[0].score * (GlobalSearchService.THRESHOLD_PERCENTAGE / 100);
    return results.filter(result => result.score >= scoreThreshold);
  }

  createWeightedResult = (doc, matchWeight, matchedKeyword) => {
    const subOption = doc.resourceKey ? (matchedKeyword || '') : null;
    const baseScore = GlobalSearchService.KEYWORD_BOOST * matchWeight;
    const keywordBonus = matchedKeyword ? 1 : 0;

    return {
      ...doc,
      subOption,
      score: baseScore + keywordBonus,
      matchedKeyword
    };
  }

  isCloseMatch = (word1, word2) => {
    const maxLength = Math.max(word1.length, word2.length);
    const minLength = Math.min(word1.length, word2.length);

    if (minLength < 3) return false;
    if (Math.abs(word1.length - word2.length) > 2) return false;

    const threshold = maxLength > 8 ? 0.4 : 0.25;
    const distance = levenshtein(word1, word2);
    return distance / maxLength <= threshold;
  }

  boostResults(results) {
    return results.map((result) => {
      let boost = 1;
      const normalizedTitle = this.normalizeText(result.title);
      const normalizedName = this.normalizeText(result.name);
      const normalizedSubOption = this.normalizeText(result.subOption);
      const normalizedBreadcrumb = result.breadcrumb ? result.breadcrumb.map(crumb => this.normalizeText(crumb)) : [];

      if (result.subOption) {
        boost *= GlobalSearchService.KEYWORD_BOOST;
      }

      if ([normalizedTitle, normalizedName, normalizedSubOption].some(text => text === this.query)) {
        boost *= GlobalSearchService.EXACT_MATCH_BOOST;
      }
      else {
        boost *= this.computePartialMatchBoost(normalizedTitle, normalizedName, normalizedSubOption, normalizedBreadcrumb);
      }

      result.score *= boost;
      return result;
    });
  }

  computePartialMatchBoost(title, name, subOption, breadcrumb) {
    const titleWords = title ? title.split(' ') : [];
    const nameWords = name ? name.split(' ') : [];
    const subOptionWords = subOption ? subOption.split(' ') : [];

    let matchCount = 0;

    const partialTitleMatch = this.queryWords.filter(word => titleWords.includes(word)).length;
    const partialNameMatch = this.queryWords.filter(word => nameWords.includes(word)).length;
    const partialSubOptionMatch = this.queryWords.filter(word => subOptionWords.includes(word)).length;
    const partialBreadcrumbMatch = this.queryWords.filter(word => breadcrumb.includes(word)).length;

    matchCount += partialTitleMatch + partialNameMatch + partialSubOptionMatch + partialBreadcrumbMatch;

    if (matchCount > 0) {
      return GlobalSearchService.PARTIAL_MATCH_BOOST * matchCount;
    }
    return 1;
  }

  uniqueResults = (results) => {
    const uniqueIds = results.map(result => ({
      ...result,
      id: result.subOption ? `${result.id}-${result.subOption}` : result.id
    }));

    const seenIds = new Set();
    return uniqueIds.filter(result => (seenIds.has(result.id) ? false : seenIds.add(result.id)));
  }

  /* ------------------ DYNAMIC KEYWORD GENERATION ------------------ */

  populateResourceKeys(id, newKeywords) {
    const docIndex = this.DOCUMENTS.findIndex(doc => doc.id === id);
    if (docIndex !== -1) {
      this.DOCUMENTS[docIndex].keywords = newKeywords;
    }
  }

  populateMerchantOptionsKeys = (merchantOptionTypes) => {
    const docId = 'merchantConfiguration--configurations--merchant-options';
    this.populateResourceKeys(docId, merchantOptionTypes.map(type => capitalCase(type.name)));
  }

  populateAppSettingResourceKeys = (appSettingResourceKeys) => {
    const docId = 'merchantConfiguration--configurations--app-setting-resources';
    this.populateResourceKeys(docId, Object.keys(appSettingResourceKeys).map(key => capitalCase(key)));
  }

  populateMerchantFeatureKeys = (features) => {
    const docId = 'merchantConfiguration--configurations--merchant-features';
    const otherFeatures = ['SSO Providers', 'Order Dashboard Columns', 'Microsoft Single Sign-On'];
    this.populateResourceKeys(docId, Object.keys(features).map(key => capitalCase(key)).concat(otherFeatures));
  }

  populateStoreCoreConfigKeys = (coreConfigs) => {
    const docId = 'storeConfiguration--configurations--core-configurations';
    this.populateResourceKeys(docId, coreConfigs);
  }

  populateStoreLocationAttributeKeys = (locationAttrs) => {
    const docId = 'storeConfiguration--configurations--location-attributes';
    this.populateResourceKeys(docId, locationAttrs.map(attr => attr.name));
  }
}

const globalSearchService = new GlobalSearchService();
export default globalSearchService;
