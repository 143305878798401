import { render, staticRenderFns } from "./kds-order.vue?vue&type=template&id=4c5476f6&scoped=true&"
import script from "./kds-order.vue?vue&type=script&lang=js&"
export * from "./kds-order.vue?vue&type=script&lang=js&"
import style0 from "./kds-order.vue?vue&type=style&index=0&id=4c5476f6&lang=sass&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4c5476f6",
  null
  
)

export default component.exports