/* eslint-disable import/no-cycle */
import { Model } from '@vuex-orm/core';
import http from '@/services/http';



export default class StoreAttributes extends Model {
  static entity = 'storeAttributes'

  static fields() {
    return {
      id: this.attr(''),
      code: this.attr(''),
      name: this.attr(''),
      message: this.attr(''),
      isFilter: this.attr('')
    };
  }

  static backfilledValues(storeId) {
    return this.all().map(({ id, code, name }) => ({
      storeAttributeId: id,
      storeId,
      code,
      name
    }));
  }

  static getStoreAttributeByCode(code) {
    return this.query().where('code', code).first();
  }



  // STATE //////////////////////

  static state() {
    return {
      fetching: false
    };
  }

  static $state() {
    return this.store().state.entities.storeAttributes;
  }



  // ACTIONS //////////////////////

  static async fetchStoreAttributes() {
    if (!this.exists()) {
      try {
        this.commit((state) => {
          state.fetching = true;
        });

        const { data } = await http.get('store_attributes');

        this.create({ data: data.storeAttributes });
      }
      catch (error) {
        throw error;
      }
      finally {
        this.commit((state) => {
          state.fetching = false;
        });
      }
    }
  }
}
