<template>
  <validated-form
    ref="form"
    form-id="requestPaymentModal"
    @valid-submit="handleSubmit"
  >
    <component
      :is="isDemo ? 'div' : 'modal-card'"
      :style="isDemo && {
        maxWidth: 'fit-content',
        margin: '1.5rem auto',
        padding: '1rem'
      }"
      :class="isDemo && 'card'"
      :title="!isDemo && 'Request Payment'"
      @closed="handleCloseModal"
    >
      <div v-if="isDemo" :class="['title', isSimple && 'mar-x-lg']">Request Payment</div>
      <template v-if="$_selectedMerchant && $_selectedMerchant.guestUserAccountCreationEnabled">
        <validated-input
          name="guestName"
          label="Select A Guest"
          sub-label="(optional)"
          sub-label-on-side
        >
          <b-autocomplete
            ref="autocomplete"
            v-model="selectedGuestName"
            class="guest-search"
            field="fullName"
            :data="searchResults"
            icon="user"
            open-on-focus
            max-height="325"
            placeholder="Search for a guest..."
            :clearable="!orderHasAttachedGuest"
            :loading="isSearchingGuests"
            check-infinite-scroll
            :disabled="orderHasAttachedGuest"
            @infinite-scroll="debouncedNextPage"
            @select="selectGuest"
            @typing="debouncedHandleSearch"
            @input="handleAutoCompleteInput"
          >
            <template v-if="!isStartingNewSearch" #empty>
              No guests found
            </template>
            <template #header>
              <a @click="addNewGuest">
                <span>Add new guest... </span>
              </a>
            </template>
            <template slot-scope="props">
              <div :class="['guest-option is-grid col-4', {'selected': selectedGuest && selectedGuest.id === props.option.id}]">
                <div class="is-flex-column col-span-3">
                  <p>{{ props.option.fullName }}</p>
                  <p class="has-text-grey is-size-7">{{ props.option.email }} — {{ props.option.primarySmsNumber }}</p>
                </div>
                <b-icon
                  v-if="selectedGuest && selectedGuest.id === props.option.id"
                  size="is-small"
                  type="is-primary"
                  icon="check"
                  class="justify-self-end align-self-center"
                />
              </div>
            </template>
            <template #footer>
              <span v-if="registeredGuestMetadata.last > 1 && !registeredGuestMetadata.next" class="has-text-grey">No more guests found.</span>
              <span v-if="isStartingNewSearch">
                <b-icon icon="spinner-third" class="spin" />
              </span>
            </template>
          </b-autocomplete>
        </validated-input>

        <validated-text-input
          v-if="isCreatingNewGuest"
          v-model="form.guestName"
          class="pad-l mar-t mar-l has-border-left has-border-grey-light"
          label="New Guest Name"
          name="guestName"
          type="text"
          maxlength="255"
        />
      </template>
      <validated-text-input
        v-else
        v-model="form.guestName"
        :has-icon="false"
        label="Name"
        sub-label="(optional)"
        sub-label-on-side
        name="name"
        type="text"
        placeholder="Enter a Name..."
      />

      <template v-if="!isSimple">
        <hr>

        <b-field label-for="contactMethod" label="Contact Method">
          <toggle-buttons
            v-model="contactMethod"
            label-left="Phone"
            value-left="phone"
            label-right="Email"
            value-right="email"
          />
        </b-field>
      </template>
      <transition name="fade-hinge" mode="out-in">
        <validated-text-input
          v-if="contactMethod === 'email'"
          key="email"
          v-model="form.email"
          :has-icon="false"
          label="Email"
          name="email"
          type="email"
          rules="required"
          placeholder="Enter an Email Address..."
        />
        <validated-text-input
          v-else
          key="phoneNumber"
          v-model="form.phoneNumber"
          :has-icon="false"
          label="Phone Number"
          name="phoneNumber"
          type="phone"
          placeholder="Enter a Phone Number..."
          rules="required|validPhoneNumber"
        />
      </transition>

      <template v-if="!isSimple">
        <hr>

        <template v-if="mode === 'create'">
          <validated-text-input
            v-model="form.merchantOrderReference"
            tooltip="Enter PO or invoice number"
            label="Reference Number"
            sub-label="(optional)"
            sub-label-on-side
            class="mar-b-lg"
            name="merchantOrderReference"
            type="text"
            maxlength="255"
          />

          <b-field>
            <template #label>
              <p>
                <span>Internal/External Notes</span>
                <span class="mar-l-xs has-text-grey is-size-7 has-text-weight-normal">(optional)</span>
              </p>
              <p class="has-text-grey-light is-size-7 has-text-weight-normal">
                Enter any notes for the customer and/or internal notes
              </p>
            </template>
            <b-tabs class="opd-tabs" type="is-toggle" expanded>
              <b-tab-item label="External Note">
                <validated-text-input
                  v-model="form.orderLevelInstructions"
                  label="External Notes"
                  hide-label
                  placeholder="Enter any customer facing notes here..."
                  name="orderLevelInstructions"
                  type="textarea"
                  maxlength="255"
                  has-counter
                />
              </b-tab-item>

              <b-tab-item label="Internal Note">
                <validated-text-input
                  v-model="form.internalNote"
                  label="Internal Notes"
                  hide-label
                  placeholder="Enter any internal facing notes here..."
                  name="internalNote"
                  type="textarea"
                  maxlength="255"
                  has-counter
                />
              </b-tab-item>
            </b-tabs>
          </b-field>

          <hr class="mar-t-none">

          <template v-if="!isDemo">
            <b-field class="is-relative">
              <template #label>
                <p>
                  <span>Add Attachment</span>
                  <span class="mar-l-xs has-text-grey is-size-7 has-text-weight-normal">(optional)</span>
                </p>
                <p class="has-text-grey-light is-size-7 has-text-weight-normal">
                  Accepted file types include PDF, PNG, JPEG and JPG up to 10 MB
                </p>
              </template>

              <div v-if="selectedFileName" class="tag pad-x pad-y-md is-flex justify-between">
                <span style="max-width: 400px" class="is-size-6 text-ellipsis">{{ selectedFileName }}</span>
                <b-icon v-if="isLoadingFile" icon="spinner-third" custom-size="fa-lg" class="spin" />
                <b-icon
                  v-else
                  icon="times-circle"
                  class="delete is-clickable"
                  custom-size="fa-lg"
                  @click.native="deleteFile"
                />
              </div>
              <b-upload
                v-else
                expanded
                drag-drop
                accept=".pdf, .png, .jpg, .jpeg"
                @input="uploadFile"
              >
                <div class="has-text-centered pad has-text-grey-light">
                  <b-icon :icon="isLoadingFile ? 'spinner-third' : 'upload'" :class="isLoadingFile && 'spin'" size="is-medium" />
                  <p class="mar-t-xs">Drop your file here or click to upload</p>
                </div>
              </b-upload>
            </b-field>
            <hr>
          </template>


          <div class="price-inputs">
            <validated-text-input
              v-model="form.subtotal"
              label="Amount"
              name="total"
              :rules="{
                required: true,
                min_value: 0.01
              }"
              type="dollars"
              class="mar-b-none"
              :disabled="disableAmountInputs"
            />

            <b-field label-for="tax">
              <template #label>
                <span>Tax</span>
                <span class="is-size-7 has-text-weight-normal has-text-grey mar-l-xs">(optional)</span>
              </template>
              <validated-text-input
                v-model="form.tax"
                hide-label
                label="Tax"
                name="tax"
                :class="['control flex-grow mar-b-none', { percent: taxType === 'percent' }]"
                :rules="taxType === 'percent' ? { between: [0, 100] } : { min_value: 0 }"
                :type="taxType === 'percent' ? 'float' : 'dollars'"
                :mask-options="{ numeralDecimalScale: 2, numeralPositiveOnly: true }"
                :disabled="disableAmountInputs"
              />
              <b-select
                v-model="taxType"
                :disabled="disableAmountInputs"
              >
                <option value="dollar">Dollars</option>
                <option value="percent">Percent</option>
              </b-select>
              <template #message>
                <transition name="fade-down">
                  <p v-if="taxType === 'percent'">
                    Calculated Tax: {{ tax.display }}
                  </p>
                </transition>
              </template>
            </b-field>
          </div>
          <b-field v-if="displayFees && displayFees.length" label="Fees" class="order-fees mar-t-sm">
            <order-fees
              :order-fees="displayFees"
              custom-classes="title mar-b-xs"
            />
          </b-field>
        </template>
      </template>

      <template v-if="isDemo">
        <hr>
        <div class="is-flex justify-center gap-sm all-bold">
          <b-button
            v-tabbable
            class="is-bold"
            native-type="submit"
            rounded
            type="is-primary"
            :loading="isSubmitting"
          >
            Request Payment
            <template v-if="!isSimple">
              ({{ total.display }})
            </template>
          </b-button>
        </div>
      </template>

      <template #footer>
        <div v-if="!isDemo" class="buttons all-bold">
          <b-button
            v-tabbable
            native-type="button"
            rounded
            class="is-bold"
            @click="handleCloseModal(true)"
          >
            Cancel
          </b-button>
          <b-button
            v-tabbable
            class="is-bold"
            native-type="submit"
            rounded
            type="is-primary"
            :loading="isSubmitting"
          >
            Request Payment ({{ total.display }})
          </b-button>
        </div>
      </template>
    </component>
  </validated-form>

</template>

<script>
  import currency from 'currency.js';
  import Order from '@/store/classes/OpdOrder';
  import RegisteredGuest from '@/store/classes/RegisteredGuest';
  import Merchant from '@/store/classes/Merchant';
  import MerchantFee from '@/store/classes/MerchantFee';
  import FeeType from '@/store/classes/FeeType';
  import events from '@/services/events';
  import orderTypes from '@/constants/orderTypes';
  import filterObjectKeys from '@/helpers/filter-object-keys';
  import debounce from 'lodash.debounce';
  import merchantMixin from '@/mixins/merchant';
  import orderFees from '@/components/globals/order-fees.vue';


  export default {
    name: 'OpdOrderModal',

    components: { orderFees },

    mixins: [merchantMixin],

    props: {
      isDemo: {
        type: Boolean,
        default: false
      },

      isSimple: {
        type: Boolean,
        default: false
      },

      orderId: {
        type: Number,
        default: null
      },

      storeId: {
        type: Number,
        required: true
      },

      mode: {
        type: String,
        default: 'create',
        validator(value) {
          return ['create', 'update'].includes(value);
        }
      }
    },

    data() {
      return {
        contactMethod: 'phone',
        feeTypesById: FeeType.typesById(),
        form: null,
        isCreatingNewGuest: false,
        isLoadingFile: false,
        isStartingNewSearch: false,
        isSubmitting: false,
        page: 1,
        param: 'name',
        searchResults: [],
        selectedFileName: null,
        selectedGuest: null,
        selectedGuestName: '',
        sortDirection: 'asc',
        sortField: 'name',
        taxType: 'dollar',
        uploadedFile: null
      };
    },

    computed: {
      applicableMerchantFees() {
        // Orders created by the `Request Payment` modal are assigned a fulfillment type of DineIn, or 1
        const dineInFulfillmentTypeId = 1;
        // eslint-disable-next-line
        return this.merchantFees.filter((fee) => {
          if (fee.isActive) {
            // A fee is active for a specific store when the id matches, or are all stores when the storeId is null
            const activeForStore = fee.storeMerchantFees.find(
              storeMerchFee => storeMerchFee.storeId === this.storeId || storeMerchFee.storeId === null
            );
            // A fee is active if:
            // - all fulfillment types and all stores if there are no storeMerchantFees
            // - a given store will have all fulfillment types active if it's fulfillmentTypeId is null
            // - the fees is active if a given store has the DineIn fulfillmentTypeId.
            const activeForPaymentRequest = !fee.storeMerchantFees.length || fee.storeMerchantFees.find(
              storeMerchFee => storeMerchFee.fulfillmentTypeId === dineInFulfillmentTypeId || storeMerchFee.fulfillmentTypeId === null
            );
            if ((fee.scope === 'global' || !!activeForStore) && !!activeForPaymentRequest) {
              return fee;
            }
          }
        });
      },

      disableAmountInputs() {
        return !this.isNewPaymentRequest;
      },

      displayFees() {
        return this.isNewPaymentRequest ? this.newOrderRequestFees : this.form.orderFees;
      },

      feesTotal() {
        return this.displayFees?.reduce((total, orderFee) => {
          total += orderFee.amount;
          return total;
        }, 0) || 0;
      },

      isNewPaymentRequest() {
        return !this.order.opdStatus;
      },

      merchantFees() {
        return MerchantFee.all();
      },

      newOrderRequestFees() {
        return this.applicableMerchantFees.map((fee) => {
          const amount = fee.feeTypeId === this.feeTypesById.FLAT_FEE
            ? fee.fee
            : (parseFloat(fee.fee) * this.form.subtotal).toFixed(2);

          return {
            amount: parseFloat(amount),
            description: fee.description,
            name: fee.name
          };
        });
      },

      order() {
        if (this.mode === 'create') {
          return new Order({
            storeId: this.storeId,
            orderType: orderTypes.PAY_AND_FULFILL
          });
        }
        return Order.find(this.orderId);
      },

      orderHasAttachedGuest() {
        return !!(this.order.opdStatus === 'PaymentRequested' && this.order.guestName?.trim());
      },

      tax() {
        const amount = this.taxType === 'dollar'
          ? currency(this.form.tax)
          : currency(this.form.subtotal).multiply(this.form.tax / 100);
        return {
          value: amount.value,
          display: amount.format()
        };
      },

      total() {
        const sum = currency(this.form.subtotal).add(currency(this.tax.value)).add(currency(this.feesTotal));
        return {
          value: sum.value,
          display: sum.format()
        };
      },

      registeredGuests() {
        return RegisteredGuest.query().orderBy('sortOrder').get();
      },

      isSearchingGuests() {
        return RegisteredGuest.$state().fetching;
      },

      registeredGuestMetadata() {
        return RegisteredGuest.$state().registeredGuestMetadata;
      }
    },

    watch: {
      registeredGuests: 'handleRegisteredGuestsChange'
    },

    created() {
      this.onCreated();
    },

    methods: {
      async onCreated() {
        if (this.isDemo && this.$route.query.merchantId) {
          const merchantId = Number(this.$route.query.merchantId);
          Merchant.setSelectedMerchantId(merchantId);
        }
        this.form = JSON.parse(JSON.stringify(this.order));
        if (this.form.email && !this.form.phoneNumber) {
          this.contactMethod = 'email';
        }
        if (this.orderHasAttachedGuest) {
          this.selectedGuestName = this.order.guestName.trim();
        }

        this.form.guestName = this.form.guestName?.trim();
        await this.fetchMerchantFees();
      },

      resetSearchResults() {
        this.searchResults = [];
        this.page = 1;
        RegisteredGuest.resetRegisteredGuests();
      },

      handleCloseModal(closeParent) {
        RegisteredGuest.resetRegisteredGuests();
        if (closeParent) {
          this.$parent.close();
        }
      },

      handleAutoCompleteInput(value) {
        if (value) {
          this.isStartingNewSearch = true;
        }
        this.resetSearchResults();
      },

      handleRegisteredGuestsChange(newGuests) {
        this.searchResults = [...this.searchResults, ...newGuests];
      },

      async handleNextPageChange() {
        if (this.registeredGuestMetadata.next) {
          this.page++;
          await this.searchGuests();
        }
      },

      // eslint-disable-next-line
      debouncedNextPage: debounce(function () {  // TEST ?
        this.handleNextPageChange();
      }, 250),

      // eslint-disable-next-line
      debouncedHandleSearch: debounce(function (query) {  // TEST ?
        this.searchGuests({ query });
      }, 666),

      addNewGuest() {
        this.selectedGuest = null;
        this.selectedGuestName = '';
        this.isCreatingNewGuest = true;
        this.$refs.autocomplete.isActive = false;
        this.resetSearchResults();
      },

      selectGuest(guest) {
        this.selectedGuest = guest;
        this.isCreatingNewGuest = false;
        this.form.guestName = guest ? guest.fullName : '';
        this.form.phoneNumber = guest ? guest.primarySmsNumber : '';
        this.form.email = guest ? guest.email : '';
        this.resetSearchResults();
        if (guest && !guest.primarySmsNumber && guest.email) {
          this.contactMethod = 'email';
        }
        else if (guest && guest.primarySmsNumber && !guest.email) {
          this.contactMethod = 'phone';
        }
      },

      async searchGuests({
        param = this.param,
        query = this.selectedGuestName,
        sortField = this.sortField,
        sortDirection = this.sortDirection,
        page = this.page
      } = {}) {
        try {
          if (query) {
            await RegisteredGuest.fetchRegisteredGuests({
              sortField, sortDirection, page, param, query
            });
          }
        }
        catch (error) {
          this.$_onRequestError({
            toastOptions: {
              message: 'There was an error loading the guests'
            },
            error
          });
        }
        finally {
          this.isStartingNewSearch = false;
        }
      },

      toggleLoading(loadingState, value) {
        this[loadingState] = value;
      },

      async uploadFile(file) {
        try {
          this.toggleLoading('isLoadingFile', true);

          const orderPaymentAttachment = await Order.uploadFile(file);

          this.selectedFileName = file.name;
          this.uploadedFile = orderPaymentAttachment;
          this.form.attachmentUrl = orderPaymentAttachment.url;
        }
        catch (error) {
          this.$_onRequestError({
            toastOptions: {
              message: 'There was an error uploading your attachment'
            },
            error
          });
        }
        finally {
          this.toggleLoading('isLoadingFile', false);
        }
      },

      async deleteFile() {
        try {
          this.toggleLoading('isLoadingFile', true);
          await Order.deleteFile(this.uploadedFile.id);

          this.selectedFileName = null;
          this.uploadedFile = null;
          this.form.attachmentUrl = '';
        }
        catch (error) {
          this.$_onRequestError({
            toastOptions: {
              message: 'There was an error deleting your attachment'
            },
            error
          });
        }
        finally {
          this.toggleLoading('isLoadingFile', false);
        }
      },

      async handleSubmit() {
        let order = this.order;
        try {
          this.toggleLoading('isSubmitting', true);

          if (this.mode === 'create') {
            order = await this.createOrder();
          }
          order = await this.requestPayment(order);

          RegisteredGuest.resetRegisteredGuests();

          const successMessage = this.mode === 'create' ? 'Successfully requested payment for new order' : 'Payment requested';
          this.$_onRequestSuccess({
            toastOptions: { message: successMessage },
            options: { closeParent: true }
          });

          const isCreatingOrder = this.mode === 'create';
          events.$emit('opd:order-updated', new Order(order), isCreatingOrder);
        }
        catch (error) {
          throw error;
        }

        finally {
          this.toggleLoading('isSubmitting', false);
        }
      },

      async createOrder() {
        try {
          const newOrder = {
            ...this.form,
            total: this.total.value,
            tax: this.tax.value
          };
          const acceptedKeys = [
            'attachmentUrl',
            'storeId',
            'guestName',
            'orderLevelInstructions',
            'merchantOrderReference',
            'internalNote',
            'orderType',
            'subtotal',
            'tax',
            'total'
          ];
          return await Order.createOrder(filterObjectKeys(newOrder, acceptedKeys), { isDemo: this.isDemo });
        }
        catch (error) {
          this.$_onRequestError({
            toastOptions: {
              message: 'There was an error adding your new order'
            },
            error
          });
          throw error;
        }
      },

      async requestPayment(order) {
        try {
          const { email, phoneNumber, guestName } = this.form;
          return await Order.requestPayment(order.orderId, {
            name: guestName,
            mobileNumberOrEmail: this.contactMethod === 'email' ? email : phoneNumber,
            storeId: this.storeId
          }, { isDemo: this.isDemo });
        }

        catch (error) {
          const errorMessage = this.mode === 'create'
            ? 'Order was created, but payment request failed'
            : 'Unable to send payment request';

          if (this.mode === 'create') {
            events.$emit('opd:order-updated', new Order(order), true);
          }

          this.$_onRequestError({
            toastOptions: { message: errorMessage },
            error,
            options: { closeParent: this.mode === 'create' }
          });
          throw error;
        }
      },

      async fetchMerchantFees() {
        try {
          await MerchantFee.fetchMerchantFees(this.$_selectedMerchantId);
        }
        catch (error) {
          this.$_onRequestError({
            error,
            toastOptions: {
              message: 'There was an error fetching merchant fees'
            }
          });
        }
      }
    }
  };
</script>

<style lang="sass" scoped>
  .guest-search
    ::v-deep
      .dropdown-menu
        padding-top: 0

  .guest-option
    .icon
      margin-right: -2rem

  .price-inputs
    display: grid
    gap: $size-medium
    grid-template-columns: 1fr 1.67fr
    max-width: 450px

  .opd-tabs
    ::v-deep.tab-content
      padding-left: 0 !important
      padding-right: 0 !important
      padding-bottom: 0 !important
</style>
