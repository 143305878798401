<template>
  <validated-form
    form-id="merchantDefaultNotificationPreferenceForm"
    @valid-submit="updateDefaultNotificationPreference"
  >
    <sticky-save-container
      :saving="isSubmitting"
      :loading="isFetching"
      title="Default Notification Preferences"
    >
      <div class="pad-r-xl mar-b-lg">
        <p class="subtitle is-6 has-text-grey">Automatically opt users into notifications upon account creation</p>
      </div>

      <b-message
        class="has-shadow is-compact is-inline-block"
        type="is-primary"
        has-icon
        icon="exclamation-triangle"
        size="is-small"
        style="max-width: 300px"
      >
        Update
        <router-link :to="{ name: 'contentManagement', params: { tabName: 'generalContent'}, hash: '#terms-of-service' }">Terms of Service</router-link>
        before enabling
      </b-message>

      <validated-input
        class="align-labels-left"
        label="Enable Marketing Opt-In"
        horizontal
        name="marketingOptIn"
      >
        <b-switch v-model="form.marketingOptIn" />
      </validated-input>
      <validated-input
        name="notificationTypes"
        label="Notification Types"
        tooltip="Must enable Marketing Opt-In to enable Email, SMS, or Push"
        horizontal
        class="align-labels-left align-center"
      >
        <div class="is-flex gap">
          <check-button
            v-for="preference in notificationPreferences"
            :key="preference.id"
            v-model="form[preference.id]"
            :disabled="!form.marketingOptIn"
            :label="preference.description"
            class="is-marginless flex-grow"
          />
        </div>
      </validated-input>
    </sticky-save-container>
  </validated-form>
</template>

<script>
  import MerchantDefaultNotificationPreference from '@/store/classes/MerchantDefaultNotificationPreference';

  export default {
    name: 'MerchantDefaultNotificationPreferences',



    props: {
      merchantId: {
        type: Number,
        required: true
      }
    },

    data() {
      return {
        systemMerchantId: 0,
        form: {
          marketingOptIn: false,
          email: false,
          sms: false,
          push: false
        },
        notificationPreferences: [
          { id: 'email', description: 'Email' },
          { id: 'push', description: 'Push' },
          { id: 'sms', description: 'SMS' }
        ]
      };
    },

    computed: {
      hasExistingPreferences() {
        return !!this.merchantDefaultNotificationPreference?.id;
      },
      isSubmitting() {
        return MerchantDefaultNotificationPreference.$state().submitting;
      },
      isFetching() {
        return MerchantDefaultNotificationPreference.$state().fetching;
      },
      merchantDefaultNotificationPreference() {
        return MerchantDefaultNotificationPreference.query().where('merchantId', this.merchantId).first() || {};
      },
      systemDefaultNotificationPreference() {
        return MerchantDefaultNotificationPreference.query().where('merchantId', this.systemMerchantId).first() || {};
      },
      notificationsEnabled() {
        return this.form.marketingOptIn;
      }
    },

    watch: {
      notificationsEnabled: 'resetFormIfMarketingOptInDisabled'
    },

    async created() {
      await this.fetchMerchantDefaultNotificationPreferences();
      this.setFormData();
    },

    methods: {
      async fetchMerchantDefaultNotificationPreferences() {
        try {
          await MerchantDefaultNotificationPreference.fetchMerchantDefaultNotificationPreferences(this.merchantId);
        }
        catch (error) {
          this.$_onRequestError({
            error,
            toastOptions: {
              message: 'There was an issue fetching the merchant default notification preferences'
            }
          });
        }
      },
      resetFormIfMarketingOptInDisabled() {
        if (!this.form.marketingOptIn) {
          this.form.email = false;
          this.form.sms = false;
          this.form.push = false;
        }
      },
      setFormData() {
        const prefillFormData = this.hasExistingPreferences ? this.merchantDefaultNotificationPreference : this.systemDefaultNotificationPreference;
        Object.keys(this.form).forEach((key) => {
          this.$set(this.form, key, prefillFormData[key]);
        });
      },
      async updateDefaultNotificationPreference() {
        try {
          if (this.hasExistingPreferences) {
            await MerchantDefaultNotificationPreference.updateMerchantDefaultNotificationPreference({
              ...this.form,
              id: this.merchantDefaultNotificationPreference.id
            });
          }
          else {
            await MerchantDefaultNotificationPreference.createMerchantDefaultNotificationPreference({
              ...this.form,
              merchantId: this.merchantId
            });
          }
          this.$_onRequestSuccess({
            toastOptions: { message: 'Successfully updated merchant default notification preferences' }
          });
        }
        catch (error) {
          this.$_onRequestError({
            error,
            toastOptions: {
              message: 'There was an issue updating the merchant default notification preferences'
            }
          });
        }
      }
    }
  };
</script>
