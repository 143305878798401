import _Order from './_Order';
import http from '@/services/http';



export default class OpdOrder extends _Order {
  static entity = 'orders_opd';

  static primaryKey = 'orderId';

  // FIELDS //////////////////////
  static fields() {
    return {
      ...super.fields(),
      employeeName: this.attr(''),
      guestName: this.attr('', name => name.trim()),
      merchantOrderReference: this.attr(''),
      internalNote: this.attr(''),
      paymentState: this.attr(''),
      tableNumberDisplayName: this.attr(''),
      storeId: this.attr('')
    };
  }

  get orderTotalWithExternalPayment() {
    return super.orderTotalWithExternalPayment;
  }

  get opdStatus() {
    if (this.status === 'Created' && this.preparationStatus === 'New') {
      return 'Open';
    }
    else if (this.status === 'Created' && this.preparationStatus === 'PaymentRequested') {
      return 'PaymentRequested';
    }
    else if (this.status === 'Fulfilled' && this.preparationStatus === 'Completed') {
      return 'Completed';
    }
    else if (this.status === 'Fulfilled') {
      return 'Paid';
    }
    return '';
  }



  // STATE //////////////////////
  static state() {
    return {
      fetchingAll: false,
      fetchingSingleId: false,
      paidOrdersDiff: [],
      addingOrder: false
    };
  }

  static $state() {
    return this.store().state.entities.orders_opd;
  }



  // ACTIONS //////////////////////
  static setNewOrders(newOrders) {
    const existingOrders = this.all();
    const isSameStore = existingOrders[0]?.store.storeId === newOrders[0]?.store.storeId;
    if (isSameStore) {
      const isPaid = order => order.status === 'Fulfilled' && order.preparationStatus !== 'Completed';
      const previousPaidOrders = existingOrders.filter(isPaid);
      const newPaidOrders = newOrders.filter(isPaid);
      const paidOrdersDiff = newPaidOrders.filter(order => !previousPaidOrders.find(po => po.orderId === order.orderId));

      this.commit((state) => {
        state.paidOrdersDiff = paidOrdersDiff;
      });
    }

    this.create({ data: newOrders });
  }

  static async fetchAllByLocationId(id) {
    try {
      this.commit((state) => { // TEST
        state.fetchingAll = true;
      });

      const merchantId = this.store().state.entities.merchants.selectedMerchantId;
      const { data } = await http.get(`merchants/${merchantId}/orders/opd`, {
        params: { storeId: id }
      });

      this.setNewOrders(data.orders);
    }

    catch (error) {
      throw error;
    }

    finally {
      this.commit((state) => { // TEST
        state.fetchingAll = false;
      });
    }
  }

  static async getOrderDetails(orderId) {
    try {
      this.commit((state) => {
        state.fetchingSingleId = orderId;
      });

      const merchantId = this.store().state.entities.merchants.selectedMerchantId;
      const { data } = await http.get(`merchants/${merchantId}/orders/${orderId}`);

      this.update({
        data: data.order,
        where: orderId
      });
    }

    catch (error) {
      throw error;
    }

    finally {
      this.commit((state) => {
        state.fetchingSingleId = false;
      });
    }
  }

  static async requestPayment(orderId, payload, { isDemo } = {}) {
    try {
      const merchantId = this.store().state.entities.merchants.selectedMerchantId;
      let route = `merchants/${merchantId}/orders/${orderId}/request_payment`;
      if (isDemo) route = `demo/${route}`;

      const response = await http.put(route, { order: payload });

      this.update({ data: response.data.order });

      return response.data.order;
    }

    catch (error) {
      throw error;
    }
  }

  static async updateOrder(orderId, storeId, updatedFields) {
    try {
      updatedFields.storeId = storeId;
      const merchantId = this.store().state.entities.merchants.selectedMerchantId;
      const response = await http.put(`merchants/${merchantId}/orders/${orderId}/prep_status`, {
        order: updatedFields
      });

      this.update({ data: response.data.order });

      return response.data.order;
    }

    catch (error) {
      throw error;
    }
  }

  static async cancelOrder(orderId) {
    try {
      const merchantId = this.store().state.entities.merchants.selectedMerchantId;
      const { data } = await http.put(`merchants/${merchantId}/orders/${orderId}/cancel`);

      this.update({ data: data.order });
    }

    catch (error) {
      throw error;
    }
  }

  static async createOrder(order, { isDemo } = {}) {
    try {
      this.commit((state) => {
        state.addingOrder = true;
      });

      const merchantId = this.store().state.entities.merchants.selectedMerchantId;

      let route = `merchants/${merchantId}/orders`;
      if (isDemo) route = `demo/${route}`;
      const response = await http.post(route, { order });

      this.insert({
        data: response.data.order
      });

      return response.data.order;
    }

    catch (error) {
      throw error;
    }

    finally {
      this.commit((state) => {
        state.addingOrder = false;
      });
    }
  }

  static async uploadFile(file) {
    try {
      const formData = new FormData();
      formData.append('orderPaymentAttachment[file]', file);

      const { data } = await http.post(
        'order_payment_attachments',
        formData,
        { headers: { 'Content-Type': 'multipart/form-data' } }
      );

      return data.orderPaymentAttachment;
    }

    catch (error) {
      throw error;
    }
  }

  static async deleteFile(id) {
    try {
      await http.delete(`order_payment_attachments/${id}`);
    }

    catch (error) {
      throw error;
    }
  }
}
