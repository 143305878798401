import { Model } from '@vuex-orm/core';
import http from '@/services/http';



export default class PosDiscountStoreConfiguration extends Model {
  static entity = 'posDiscountStoreConfigurations'



  // FIELDS //////////////////////
  static fields() {
    return {
      id: this.uid(),
      posDiscountConfigurationId: this.attr(null),
      storeId: this.attr(null),
      posTypeId: this.attr(null),
      posApiId: this.attr(null)
    };
  }



  // ACTIONS //////////////////////
  static async createPosDiscountStoreConfig(posDiscountStoreConfiguration) {
    try {
      const { data } = await http.post(
        `stores/${posDiscountStoreConfiguration.storeId}/pos_discount_store_configurations`,
        { posDiscountStoreConfiguration }
      );

      this.insert({ data: data.posDiscountStoreConfiguration });
    }

    catch (error) {
      throw error;
    }
  }

  static async updatePosDiscountStoreConfig(posDiscountStoreConfiguration) {
    try {
      const { data } = await http.put(
        `pos_discount_store_configurations/${posDiscountStoreConfiguration.id}`,
        { posDiscountStoreConfiguration }
      );

      this.update({ data: data.posDiscountStoreConfiguration });
    }

    catch (error) {
      throw error;
    }
  }

  static async deletePosDiscountStoreConfig(posDiscountStoreConfigurationId) {
    try {
      await http.delete(`pos_discount_store_configurations/${posDiscountStoreConfigurationId}`);

      this.delete(posDiscountStoreConfigurationId);
    }

    catch (error) {
      throw error;
    }
  }
}
