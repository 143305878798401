<template>
  <validated-form
    ref="form"
    auto-focus
    form-id="addEditMappingStoreSublocation"
    @valid-submit="handleSubmit"
  >
    <modal-card :title="modalTitle">
      <fieldset v-if="mode ==='create'">
        <div class="is-grid col-2 gap pad-b-md">
          <radio-button
            v-model="onlyShowSingle"
            name="tableQuantity"
            :native-value="true"
          >
            <p>Single Table</p>
            <p slot="sublabel">Enter a single numeric or alphanumeric table number.</p>
          </radio-button>
          <radio-button
            v-model="onlyShowSingle"
            name="tableQuantity"
            :native-value="false"
          >
            <p>Range Of Tables</p>
            <p slot="sublabel">Enter a range of numeric table numbers.</p>
          </radio-button>
        </div>
      </fieldset>
      <div v-if="mode === 'update' || onlyShowSingle" class="is-grid col-2">
        <validated-text-input
          v-model="form.sublocationId"
          label="Table Number"
          name="sublocationId"
          :rules="{
            required: true,
            alpha_num: true,
            uniqueStringCaseInsensitive: unavailableSublocationIds
          }"
          type="text"
        />
      </div>
      <div v-if="mode === 'create' && !onlyShowSingle">
        <fieldset>
          <div class="columns">
            <div class="column">
              <validated-text-input
                v-model="form.rangeStart"
                label="Range Start"
                name="rangeStart"
                :rules="{
                  required: true,
                  numeric: true,
                  between: [0, parseInt(form.rangeEnd)],
                  uniqueStringCaseInsensitive: unavailableSublocationIds
                }"
                :custom-messages="{ between: 'Range Start must be between 0 and Range End'}"
                type="text"
              />
            </div>
            <div class="column">
              <validated-text-input
                v-model="form.rangeEnd"
                label="Range End"
                name="rangeEnd"
                :rules="{
                  required: true,
                  numeric: true,
                  min_value: form.rangeStart,
                  max_value: parseInt(form.rangeStart) + 1000,
                  uniqueStringCaseInsensitive: unavailableSublocationIds
                }"
                :custom-messages="{ min_value: 'Range End must be greater than Range Start' }"
                type="text"
              />
            </div>
          </div>
        </fieldset>
      </div>
      <template #footer>
        <div class="buttons all-bold">
          <b-button
            rounded
            @click="$_confirmCloseModal({ programmatic: true })"
          >
            Cancel
          </b-button>
          <b-button
            v-tabbable
            rounded
            native-type="submit"
            type="is-primary"
            :loading="isSubmittingMappingStoreSublocation"
          >
            Save
          </b-button>
        </div>
      </template>
    </modal-card>
  </validated-form>
</template>

<script>
  import MappingStoreSublocation from '@/store/classes/MappingStoreSublocation';
  import confirmModalCloseMixin from '@/mixins/confirm-modal-close';
  import logger from '@/services/logger';

  export default {
    name: 'AddEditMappingStoreSublocationModal',

    mixins: [confirmModalCloseMixin],

    props: {
      mappingStoreSublocation: {
        type: Object,
        required: true
      },

      storeId: {
        type: Number,
        required: true
      },

      unavailableSublocationIds: {
        type: Array,
        required: true
      },

      mode: {
        type: String,
        default: 'create',
        validator(value) {
          return ['create', 'update'].includes(value);
        }
      }
    },

    data() {
      return {
        form: {},
        onlyShowSingle: true
      };
    },

    computed: {
      modalTitle() {
        let title = '';
        switch (this.mode) {
          case 'create':
            title = 'Add Table Number';
            break;

          case 'update':
            title = 'Edit Table Number';
            break;

          default:
            break;
        }

        return title;
      },

      isSubmittingMappingStoreSublocation() {
        return MappingStoreSublocation.$state().submitting;
      },

      storeMappingStoreSublocation() {
        return this.mappingStoreSublocation;
      }
    },

    created() {
      this.onCreated();
    },

    methods: {
      onCreated() {
        this.form = this.$clone(this.storeMappingStoreSublocation);
      },

      async handleSubmit() {
        try {
          switch (this.mode) {
            case 'create':
              if (this.onlyShowSingle) {
                await this.addSingleMappingStoreSublocation();
              }
              else {
                await this.addMappingStoreSublocationRange();
              }

              break;

            case 'update':
              await this.updateMappingStoreSublocation();
              break;

            default:
              break;
          }
        }
        catch (error) {
          logger.error(error);
        }
      },

      async addSingleMappingStoreSublocation() {
        try {
          await MappingStoreSublocation.addMappingStoreSublocation(this.storeId, this.form);

          this.$_onRequestSuccess({
            toastOptions: { message: 'Successfully added table number!' },
            options: { closeParent: true }
          });
        }

        catch (error) {
          this.$_onRequestError({
            toastOptions: { message: 'An error occurred while adding your new table number' },
            error
          });
        }
      },

      async addMappingStoreSublocationRange() {
        try {
          await MappingStoreSublocation.addMappingStoreSublocationRange(this.storeId, this.form);

          this.$_onRequestSuccess({
            toastOptions: { message: 'Successfully added table numbers!' },
            options: { closeParent: true }
          });
        }

        catch (error) {
          this.$_onRequestError({
            toastOptions: { message: 'An error occurred while adding your new table numbers' },
            error
          });
        }
      },

      async updateMappingStoreSublocation() {
        try {
          await MappingStoreSublocation.updateMappingStoreSublocation(this.form);

          this.$_onRequestSuccess({
            toastOptions: { message: 'Successfully updated table number!' },
            options: { closeParent: true }
          });
        }

        catch (error) {
          this.$_onRequestError({
            toastOptions: { message: 'An error occurred while updating your table number' },
            error
          });
        }
      }
    }
  };
</script>
