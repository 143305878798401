<template>
  <div class="mar-t-md">
    <div v-if="isFetching" class="align-self-center">
      <b-icon icon="spinner-third" class="spin" />
    </div>
    <template v-else>
      <b-message v-if="!fulfillmentTypes.length" type="is-warning">
        There are no available order modes. Please configure an order mode before making a constraint.
      </b-message>
      <validated-input
        v-else
        name="fulfillmentTypes"
        label="Order Modes"
        hide-label
        rules="required"
        :custom-messages="{
          required: 'Please select at least one order mode'
        }"
      >
        <div class="is-grid col-min-300 gap-md">
          <check-button
            v-for="fulfillmentType in fulfillmentTypes"
            :key="fulfillmentType.name"
            v-model="selectedFulfillmentTypes"
            :native-value="fulfillmentType.name"
            type="is-primary"
            :disabled="readOnly"
            :label="formattedFulfillmentType(fulfillmentType.name)"
          />
        </div>
      </validated-input>
    </template>
  </div>
</template>

<script>
  import merchantMixin from '@/mixins/merchant';
  import FulfillmentType from '@/store/classes/FulfillmentType';
  import Store from '@/store/classes/Store';


  export default {
    name: 'FulfillmentTypeCard',

    mixins: [merchantMixin],

    props: {
      earnRuleConstraint: {
        type: Object,
        required: true
      },

      readOnly: {
        type: Boolean,
        default: false
      }
    },


    computed: {
      fulfillmentTypes() {
        if (!this.stores) return [];
        return FulfillmentType.merchantSupported(this.$_selectedMerchant, this.stores);
      },

      isFetching() {
        return FulfillmentType.$state().fetching || Store.$state().fetchingAll;
      },

      stores() {
        return Store.all();
      },

      selectedFulfillmentTypes: {
        get() {
          return this.earnRuleConstraint.validFulfillmentTypes;
        },
        set(value) {
          this.$emit('update-constraint', {
            ...this.earnRuleConstraint,
            validFulfillmentTypes: value
          });
        }
      }
    },

    created() {
      this.onCreated();
    },

    methods: {
      formattedFulfillmentType(name) {
        return FulfillmentType.formattedName(name);
      },

      async fetchFulfillmentTypes() {
        try {
          await FulfillmentType.fetchFulfillmentTypes();
        }
        catch (error) {
          this.$_onRequestError({ toastOptions: { message: 'Unable to fetch order modes' }, error });
        }
      },

      async fetchStores() {
        try {
          await Store.fetchAll(this.$_selectedMerchantId);
        }
        catch (error) {
          this.$_onRequestError({ toastOptions: { message: 'Unable to fetch stores' }, error });
        }
      },

      async onCreated() {
        await this.fetchStores();
        await this.fetchFulfillmentTypes();

        if (!this.selectedFulfillmentTypes.length) {
          this.selectedFulfillmentTypes = this.fulfillmentTypes.map(fulfillmentType => fulfillmentType.name);
        }
      }
    }
  };
</script>
