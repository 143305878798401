<template>
  <div class="card">
    <div class="card-header">
      <div class="card-header-title pad-lg">
        <p class="title is-5">Review</p>
      </div>
    </div>
    <div class="card-content">
      <template v-if="!readOnly">
        <p class="is-4 subtitle mar-b-xs">Review your earn rule</p>
        <p class="is-6 subtitle has-text-grey">Please confirm your settings before publishing</p>
      </template>

      <div class="dist-y-lg">
        <review-card
          title="Details"
          subtitle="For internal use only"
          :read-only="readOnly"
          @navigate-to-step="$emit('navigate-to-step', 0)"
        >
          <details-card v-model="value" />
        </review-card>
        <review-card
          title="Definition"
          :read-only="readOnly"
          @navigate-to-step="$emit('navigate-to-step', 1)"
        >
          <definition-card v-model="value" />
        </review-card>
        <review-card
          v-if="showConstraintsCard"
          title="Constraints"
          :read-only="readOnly"
          @navigate-to-step="$emit('navigate-to-step', 1)"
        >
          <constraints-card v-model="value" />
        </review-card>
        <review-card
          title="Time Frame & Run Limits"
          :read-only="readOnly"
          :earn-rule="earnRule"
          @navigate-to-step="$emit('navigate-to-step', 2)"
        >
          <time-frame-card v-model="value" :earn-rule="earnRule" />
        </review-card>
      </div>
    </div>
  </div>
</template>

<script>
  import { engagementTypes } from '@/constants/earnRules';

  import DefinitionCard from './review-cards/definition-card.vue';
  import DetailsCard from './review-cards/details-card.vue';
  import TimeFrameCard from './review-cards/time-frame-card.vue';


  export default {
    name: 'ReviewStep',

    components: {
      DefinitionCard,
      DetailsCard,
      TimeFrameCard
    },

    props: {
      value: {
        type: Object,
        required: true
      },
      readOnly: {
        type: Boolean,
        default: false
      },
      earnRule: {
        type: Object,
        default: null
      }
    },

    computed: {
      showConstraintsCard() {
        return [
          engagementTypes.REFERRAL_CONVERTED.id,
          engagementTypes.SALES_TRANSACTION.id,
          engagementTypes.MEMBER_PROXIMITY.id
        ].includes(this.value.engagementType);
      }
    }
  };
</script>
