<template>
  <div class="is-flex-column gap-lg">
    <div class="card">
      <div class="card-content is-grid col-3">
        <div class="mar-r-xl pad-r-xl has-border-right has-border-grey-lightest col-span-2 dist-y-lg">
          <validated-text-input
            :value="value.title"
            data-test-id="title-input"
            name="title"
            type="text"
            rules="required|max:255"
            :custom-messages="{ required: 'You must give your In-App Message a title' }"
            label="Title"
            @input="handleInput({ title: $event })"
          />
          <validated-text-input
            :value="value.bodyContent"
            data-test-id="body-content-input"
            name="content"
            type="text"
            rules="required|max:255"
            :custom-messages="{ required: 'Your In-App Message must have content' }"
            label="Content"
            @input="handleInput({ bodyContent: $event })"
          />
          <hr>
          <div class="is-grid col-2 gap">
            <validated-text-input
              :value="value.ctaContent"
              data-test-id="cta-content-input"
              name="ctaContent"
              type="text"
              label="CTA Text"
              :rules="{
                required: value.isMandatory,
                max: 255,
              }"
              :custom-messages="{ required: 'Must have CTA Text if if click is required' }"
              @input="handleInput({ ctaContent: $event })"
            />
            <validated-input
              data-test-id="notification-link-select"
              label="Link to Page"
              name="pushLink"
              tooltip="Select which page of the app this notification will take the user to"
              tooltip-placement="right"
              class="mar-none"
              :rules="{
                required: value.isMandatory,
              }"
              :custom-messages="{ required: 'Must select page link if click is required' }"
            >
              <b-select
                v-model="deepLinkId"
                placeholder="Select a page"
                icon="link"
              >
                <optgroup
                  v-for="(links, type) in filteredMerchantDeepLinks"
                  :key="type"
                  :label="type | capitalize"
                >
                  <option
                    v-for="link in links"
                    :key="`${type}${link.id}`"
                    :value="link.id"
                  >
                    {{ link.displayName }}
                  </option>
                </optgroup>
              </b-select>
            </validated-input>
          </div>
          <template v-if="deepLinkId === deepLinkIds.SPECIFIC_ITEM">
            <div
              v-if="value.resourceMetadata "
              data-test-id="resource-metadata-display"
              class="tag is-primary is-light auto-height align-self-end pad-sm"
            >
              <div>
                <p class="has-text-weight-bold">{{ value.resourceMetadata.displayName }}</p>
              </div>
              <b-icon
                v-if="!readOnly"
                icon="times-circle"
                class="delete align-self-center mar-none mar-l-sm has-background-primary-dark has-hover-background-primary-medium"
                custom-size="fa-lg"
                @click.native="handleInput({ portalDeepLinkSpecifiedResourceId: null, resourceMetadata: null })"
              />
            </div>
            <validated-input
              v-else
              label="Specific Item"
              name="pushLinkItem"
              rules="required"
              class="flex-grow"
              :custom-messages="{ required: 'A menu item is required to link to a specific item page' }"
            >
              <menu-search-input
                data-test-id="menu-search-input"
                resource-type="menuItems"
                :selected-resources="value.resourceMetadata ? [value.resourceMetadata] : []"
                @add-resource="handleInput({ portalDeepLinkSpecifiedResourceId: $event.id, resourceMetadata: $event })"
              />
            </validated-input>
          </template>
          <hr>
          <div>
            <!-- When the Message Type is "Menu" and a user selects any of the ordering mode links (Menu, Delivery, etc.), the
            In-App messages puts them in an infinite loop of pop-ups. This logic was hotfixed quickly to prevent this.
            Whenver a user selects the aforemenioned state, we use the validation provider to make sure that alwaysShow
            and isMandatory are not true simultaneously. -->
            <validation-provider
              v-slot="{ errors }"
              :rules="{ required: (isMenuMessageType && hasOrderingModeLinkSelected) }"
              name="modalSettings"
              :custom-messages="{ required: 'For a menu message with a menu link, only one of these settings can be used.' }"
            >
              <b-checkbox
                :class="['is-hidden', { 'invalid': errors.length }]"
                :value="!(value.alwaysShow && value.isMandatory) || null"
              />
              <b-field
                :type="{ 'is-danger': !!errors.length }"
                :message="errors"
              >
                <div class="is-grid gap col-2">
                  <check-button
                    v-model="value.alwaysShow"
                    name="alwaysShow"
                    label="Show Every Visit"
                    sublabel="Enable to show the message every visit. Disable to show once."
                    data-test-id="show-every-visit-toggle"
                    :disabled="selectedInAppMessageType ? selectedInAppMessageType.name === inAppMessageTypeNames.APP_LOAD : false"
                  />
                  <check-button
                    v-model="value.isMandatory"
                    name="isMandatory"
                    label="Require Click"
                    sublabel="Require the user to click on the CTA to clear the pop-up"
                  />
                </div>
              </b-field>
            </validation-provider>
          </div>
        </div>

        <image-upload
          v-slot="{ imagePath }"
          v-model="tempInAppMessageImage"
          :accepted-types="['png', 'jpeg', 'jpg', 'gif', 'svg']"
          :image-size-warning-height="472"
          :image-size-warning-width="640"
          :image-url="value.primaryImageUrl"
          label="App Message Image"
          show-delete-button
          clear-text="Clear Image"
          restrict-file-size
          :disabled="readOnly"
          validation-mode="aggressive"
          @delete-image="handleInput({ primaryImageUrl: null })"
        >
          <img
            v-if="imagePath"
            style="max-width: 500px"
            :src="imagePath"
            alt="offer Image"
          >
        </image-upload>
      </div>

    </div>
    <div class="card is-inline-block align-self-center">
      <div class="card-content" style="width: 500px">
        <p class="is-4 subtitle mar-b-xs">Message Preview</p>
        <in-app-message-preview :in-app-message="value" :temp-image-file="tempInAppMessageImage" />
      </div>
    </div>
  </div>
</template>

<script>
  import { inAppMessageTypeNames } from '@/constants/merchantInAppMessages';
  import deepLinkIds from '@/constants/deepLinkIds';

  import merchantMixin from '@/mixins/merchant';

  import InAppMessageType from '@/store/classes/InAppMessageType';
  import Merchant from '@/store/classes/Merchant';


  import InAppMessagePreview from '../in-app-message-preview.vue';

  export default {
    name: 'ContentStep',

    components: { InAppMessagePreview },

    mixins: [merchantMixin],

    props: {
      value: {
        type: Object,
        required: true
      },

      readOnly: {
        type: Boolean,
        default: false
      }
    },

    data() {
      return {
        deepLinkIds,
        inAppMessageTypeNames,
        tempInAppMessageImageFile: null
      };
    },

    computed: {
      tempInAppMessageImage: {
        get() {
          return this.tempInAppMessageImageFile;
        },

        async set(value) {
          if (!value) {
            if (this.value.primaryImageUrl) {
              this.handleInput({ primaryImageUrl: null });
            }
            this.tempInAppMessageImageFile = null;
            this.$emit('image-change', null);
          }
          else {
            this.tempInAppMessageImageFile = value;
            this.$emit('image-change', value);
          }
        }
      },

      deepLinkId: {
        get() {
          return this.value.portalDeepLinkId || null;
        },

        set(value) {
          this.handleInput({ portalDeepLinkId: value });
        }
      },

      merchantDeepLinks() {
        return Merchant.$state().merchantDeepLinks;
      },

      filteredMerchantDeepLinks() {
        return this.merchantDeepLinks.reduce((acc, link) => {
          const notificationType = link.type;
          if (!acc[notificationType]) {
            acc[notificationType] = [];
          }
          /**
           * Temprorarily hiding the gift card link until mobile has universal links set up.
           * https://cardfree.atlassian.net/browse/TOOL-3516
           */
          if (link.availableForMerchant && link.id !== deepLinkIds.GIFT_CARD) {
            acc[notificationType].push(link);
          }
          return acc;
        }, {});
      },

      isMenuMessageType() {
        return this.selectedInAppMessageType?.name === inAppMessageTypeNames.MENU;
      },

      hasOrderingModeLinkSelected() {
        const orderingModeLinkIds = [
          deepLinkIds.MENU,
          deepLinkIds.PICK_UP_ORDER_MODE,
          deepLinkIds.DELIVERY_ORDER_MODE,
          deepLinkIds.CATERING_ORDER_MODE,
          deepLinkIds.SHIPPING_ORDER_MODE
        ];
        return orderingModeLinkIds.includes(this.deepLinkId);
      },

      inAppMessageTypes() {
        return InAppMessageType.all();
      },

      selectedInAppMessageType() {
        return this.value.inAppMessageTypeId
          ? this.inAppMessageTypes.find(type => type.id === this.value.inAppMessageTypeId)
          : null;
      }
    },

    methods: {
      handleInput(payload) {
        this.$emit('input', { ...this.value, ...payload });
      }
    }
  };
</script>
