<template>
  <validated-form
    ref="form"
    v-slot="{}"
    form-id="merchantDeliveryServicesConfigurationsForm"
    @valid-submit="handleSubmit"
  >
    <panel :title="deliveryServiceName + ' Configurations'" collapsible start-open>
      <template #buttons>
        <b-switch v-model="isActive" class="is-flex-align-center" />

        <b-button
          rounded
          class="is-bold"
          size="is-medium"
          native-type="submit"
          type="is-primary"
          :loading="isSubmitting"
          :disabled="!canUpdateOverrides"
        >
          Save
        </b-button>
      </template>

      <transition-group name="fade-zoom" mode="out-in">
        <div v-for="(deliveryService, index) in forms" :key="`${deliveryService.deliveryServiceId}-${index}`">
          <div class="sub-section">
            <div class="is-flex justify-between mar-b-md">
              <h2 class="subtitle is-4 mar-none">
                {{ platformTypes[deliveryService.platformTypeId] }}
              </h2>
              <b-button
                icon-left="trash"
                type="is-danger is-light"
                outlined
                @click="confirmRemoveDeliveryService(deliveryService)"
              />
            </div>
            <div class="is-grid gap-xl input-set">
              <p class="label mar-none is-flex align-center">Delivery Fees</p>
              <validated-input
                :name="`deliveryFeeType-${deliveryService.platformTypeId}`"
                label="Fee Type"
                label-position="on-border"
                rules="required"
              >
                <b-select
                  :disabled="!$can('update', 'MerchantDeliveryService', 'feeTypeId')"
                  :value="deliveryService.feeTypeId"
                  placeholder="Select a fee type..."
                  expanded
                  @input="(value) => handleTypeInputChange({ value, field: 'feeTypeId', platformTypeId: deliveryService.platformTypeId })"
                >
                  <option :value="null" disabled>-</option>
                  <option v-for="{ id, name } in deliveryFeeTypes" :key="id" :value="id">{{ capitalCase(name) }}</option>
                </b-select>
              </validated-input>
              <div>
                <transition name="fade-right" mode="out-in">
                  <validated-text-input
                    v-if="[2, 4].includes(deliveryService.feeTypeId)"
                    :key="deliveryService.feeTypeId"
                    v-model="deliveryService.feeAmount"
                    :disabled="!$can('update', 'MerchantDeliveryService', 'feeAmount')"
                    placeholder="Set a fee amount..."
                    :name="`feeAmount-${deliveryService.platformTypeId}`"
                    label="Fee Amount"
                    label-position="on-border"
                    type="dollars"
                    :rules="{ required: true, min_value: 0 }"
                  />
                  <validated-text-input
                    v-else-if="deliveryService.feeTypeId === 3"
                    :key="deliveryService.feeTypeId"
                    :disabled="!$can('update', 'MerchantDeliveryService', 'feeAmount')"
                    placeholder="Set a delivery fee percent..."
                    class="percent"
                    :name="`feeAmount-${deliveryService.platformTypeId}`"
                    label="Fee Amount"
                    label-position="on-border"
                    type="float"
                    :rules="{ required: true, min_value: 0, max_value: 100 }"
                    :mask-options="{ numeralDecimalScale: 2 }"
                    :value="[null, undefined].includes(deliveryService.feeAmount) ? null : parseFloat((deliveryService.feeAmount * 100).toPrecision(4))"
                    @input="value => deliveryService.feeAmount = parseFloat((value / 100).toPrecision(4))"
                  />
                </transition>
              </div>

              <div>
                <p class="label mar-none is-flex align-center">Delivery Upcharge</p>
                <p class="is-size-7 has-text-grey">(optional)</p>
              </div>
              <validated-text-input
                :disabled="!$can('update', 'MerchantDeliveryService', 'deliveryUpcharge')"
                placeholder="Set a delivery upcharge percent..."
                class="percent"
                :name="`deliveryUpcharge-${deliveryService.platformTypeId}`"
                label="Delivery Upcharge"
                label-position="on-border"
                type="float"
                :rules="{ min_value: 0, max_value: 100 }"
                :mask-options="{ numeralDecimalScale: 2 }"
                :value="[null, undefined].includes(deliveryService.deliveryUpcharge) ? null : parseFloat((deliveryService.deliveryUpcharge * 100).toPrecision(4))"
                @input="value => deliveryService.deliveryUpcharge = parseFloat((value / 100).toPrecision(4))"
              />
              <div />

              <p class="label mar-none is-flex align-center">Delivery Service Charge</p>
              <validated-input
                :name="`deliveryServiceChargeType-${deliveryService.platformTypeId}`"
                label="Service Charge Type"
                label-position="on-border"
                rules="required"
              >
                <b-select
                  :disabled="!$can('update', 'MerchantDeliveryService', 'deliveryServiceChargeTypeId')"
                  :value="deliveryService.deliveryServiceChargeTypeId"
                  placeholder="Select a delivery service charge type..."
                  expanded
                  @input="(value) => handleTypeInputChange({ value, field: 'deliveryServiceChargeTypeId', platformTypeId: deliveryService.platformTypeId })"
                >
                  <option :value="null" disabled>-</option>
                  <option v-for="{ id, name } in deliveryServiceChargeTypes" :key="id" :value="id">{{ capitalCase(name) }}</option>
                </b-select>
              </validated-input>
              <div>
                <transition name="fade-right" mode="out-in">
                  <validated-text-input
                    v-if="deliveryService.deliveryServiceChargeTypeId === 1"
                    :key="deliveryService.deliveryServiceChargeTypeId"
                    :disabled="!$can('update', 'MerchantDeliveryService', 'deliveryServiceChargeAmount')"
                    placeholder="Set a service charge percent..."
                    class="percent"
                    :name="`deliveryServiceChargeAmount-${deliveryService.platformTypeId}`"
                    label="Service Charge Amount"
                    label-position="on-border"
                    type="float"
                    :rules="{ required: true, min_value: 0, max_value: 100 }"
                    :mask-options="{ numeralDecimalScale: 2 }"
                    :value="[null, undefined].includes(deliveryService.deliveryServiceChargeAmount) ? null : parseFloat((deliveryService.deliveryServiceChargeAmount * 100).toPrecision(4))"
                    @input="value => deliveryService.deliveryServiceChargeAmount = parseFloat((value / 100).toPrecision(4))"
                  />
                  <validated-text-input
                    v-if="deliveryService.deliveryServiceChargeTypeId === 2"
                    :key="deliveryService.deliveryServiceChargeTypeId"
                    v-model="deliveryService.deliveryServiceChargeAmount"
                    :disabled="!$can('update', 'MerchantDeliveryService', 'deliveryServiceChargeAmount')"
                    placeholder="Set a service charge amount..."
                    :name="`deliveryServiceChargeAmount-${deliveryService.platformTypeId}`"
                    label="Service Charge Amount"
                    label-position="on-border"
                    type="dollars"
                    :rules="{ required: true, min_value: 0 }"
                  />
                </transition>
              </div>
            </div>
          </div>
        </div>
      </transition-group>
      <b-button
        v-if="missingPlatformTypeId"
        icon-left="plus"
        type="is-primary is-light"
        outlined
        class="is-block auto-height pad-y pad-x-lg"
        size="is-medium"
        @click="addConfiguration(missingPlatformTypeId)"
      >
        {{ platformTypes[missingPlatformTypeId] }} Configuration
      </b-button>
    </panel>
  </validated-form>
</template>

<script>
  import MerchantDeliveryService from '@/store/classes/MerchantDeliveryService';
  import DeliveryFeeType from '@/store/classes/DeliveryFeeType';
  import DeliveryServiceChargeType from '@/store/classes/DeliveryServiceChargeType';
  import alertModal from '@/components/globals/alert-modal.vue';
  import capitalCase from '@/helpers/capitalCase';


  export default {
    name: 'MerchantDeliveryServiceConfigurations',

    props: {
      merchantId: {
        type: Number,
        required: true
      },

      deliveryServiceId: {
        type: Number,
        required: true
      },

      services: {
        type: Array,
        required: true
      }
    },

    data() {
      return {
        capitalCase,
        forms: [],
        platformTypes: { 1: 'Web', 2: 'Mobile' },
        isSubmitting: false
      };
    },

    computed: {
      isActive: {
        get() {
          return this.forms?.[0].isActive;
        },

        set(val) {
          if (this.forms.length) {
            this.forms.forEach((f) => {
              f.isActive = val;
            });
          }
        }
      },

      deliveryFeeTypes() {
        return DeliveryFeeType.all();
      },

      deliveryServiceChargeTypes() {
        return DeliveryServiceChargeType.all();
      },

      deliveryServiceName() {
        switch (this.deliveryServiceId) {
          case 1:
            return 'DoorDash';
          case 2:
            return 'First Delivery';
          case 3:
            return 'Uber Eats';
          case 8:
            return 'In House Delivery';
          default:
            return 'Delivery Service - Name Unmapped';
        }
      },

      canUpdateOverrides() {
        return [
          'deliveryUpcharge',
          'feeTypeId',
          'feeAmount',
          'deliveryServiceChargeAmount',
          'deliveryServiceChargeTypeId'
        ]
          .some(field => this.$can('update', 'MerchantDeliveryService', field))
          && this.forms.length > 0;
      },

      missingPlatformTypeId() {
        const existingPlatformTypeIds = this.forms.map(mds => mds.platformTypeId);

        if (existingPlatformTypeIds.length === 1) {
          if (existingPlatformTypeIds[0] === 1) {
            return 2;
          }
          else {
            return 1;
          }
        }
        else {
          return null;
        }
      }
    },

    created() {
      this.setForm();
    },

    methods: {
      setForm() {
        this.forms = this.services.map((service) => {
          const mds = this.$clone(service);

          // Convert numeric isActive (0/1) from API to boolean to prevent toggle switch
          // from incorrectly reverting to disabled state
          mds.isActive = Boolean(mds.isActive);

          // format dollar type amounts
          if ([2, 4].includes(mds.feeTypeId)) {
            mds.feeAmount = parseFloat(mds.feeAmount).toFixed(2);
          }
          if (mds.deliveryServiceChargeTypeId === 2) {
            mds.deliveryServiceChargeAmount = parseFloat(mds.deliveryServiceChargeAmount).toFixed(2);
          }

          mds.platformType = { name: this.platformTypes[mds.platformTypeId] };

          return mds;
        });
      },

      thisForm(platformTypeId) {
        return this.forms.find(mds => mds.platformTypeId === platformTypeId);
      },

      handleTypeInputChange({ value, field, platformTypeId }) {
        this.thisForm(platformTypeId)[field] = value;

        const originalValues = this.services.find(mds => mds.platformTypeId === platformTypeId);

        const isSameType = originalValues && originalValues[field] === value;

        if (field === 'feeTypeId') {
          if (value === 1) {
            this.forms.find(mds => mds.platformTypeId === platformTypeId).feeAmount = 0;
          }
          else if (isSameType) {
            this.forms.find(mds => mds.platformTypeId === platformTypeId).feeAmount = originalValues.feeAmount;
          }
          else {
            this.forms.find(mds => mds.platformTypeId === platformTypeId).feeAmount = null;
          }
        }

        if (field === 'deliveryServiceChargeTypeId') {
          if (isSameType) {
            this.thisForm(platformTypeId).deliveryServiceChargeAmount = originalValues.deliveryServiceChargeAmount;
          }
          else {
            this.thisForm(platformTypeId).deliveryServiceChargeAmount = null;
          }
        }
      },

      addConfiguration(platformTypeId) {
        const existing = this.forms[0];
        const mds = new MerchantDeliveryService({
          isSaved: false,
          deliveryServiceId: this.deliveryServiceId,
          merchantId: this.merchantId,
          platformTypeId,
          feeTypeId: existing?.feeTypeId,
          feeAmount: existing?.feeAmount,
          deliveryUpcharge: existing?.deliveryUpcharge,
          deliveryServiceChargeTypeId: existing?.deliveryServiceChargeTypeId,
          deliveryServiceChargeAmount: existing?.deliveryServiceChargeAmount,
          isActive: this.isActive,
          apiConfiguration: existing?.apiConfiguration
        });
        mds.$save();
        this.forms.push(mds);
      },

      confirmRemoveDeliveryService(merchantDeliveryService) {
        if (merchantDeliveryService.isSaved) {
          this.$buefy.modal.open({
            parent: this,
            component: alertModal,
            hasModalCard: true,
            trapFocus: true,
            canCancel: false,
            customClass: 'auto-width',
            props: {
              title: `Delete ${merchantDeliveryService.platformType.name} Configuration`,
              message: `Deleting your <b> ${merchantDeliveryService.platformType.name} </b> configuration will delete the settings from this page and its associated records. Action will be required from CardFree Support to restore this configuration. Are you sure?`,
              icon: 'trash-alt',
              type: 'is-danger',
              showCloseButton: false,
              horizontal: true,
              forceClose: true,
              buttons: [
                { text: 'Cancel' },
                {
                  text: 'Delete',
                  primary: true,
                  onClick: () => this.removeDeliveryService(merchantDeliveryService)
                }
              ]
            }
          });
        }
        else {
          this.forms.splice(this.forms.indexOf(merchantDeliveryService), 1);
          MerchantDeliveryService.delete([this.merchantId, merchantDeliveryService.deliveryServiceId, merchantDeliveryService.platformTypeId]);
        }
      },

      async removeDeliveryService(merchantDeliveryService) {
        try {
          await MerchantDeliveryService.removeMerchantDeliveryService({ merchantId: this.merchantId, merchantDeliveryService });
          this.setForm();
          this.$_onRequestSuccess({
            toastOptions: { message: `Successfully removed your ${this.deliveryServiceName} configuration!` }
          });
        }
        catch (error) {
          this.$_onRequestError({
            error,
            toastOptions: { message: `There was an error removing your ${this.deliveryServiceName} configurations` }
          });
        }
      },

      async handleSubmit() {
        try {
          this.isSubmitting = true;

          const requests = this.forms.map((merchantDeliveryService) => {
            const payload = { merchantDeliveryService, merchantId: this.merchantId };
            return merchantDeliveryService.isSaved
              ? MerchantDeliveryService.updateMerchantDeliveryService(payload)
              : MerchantDeliveryService.addMerchantDeliveryService(payload);
          });

          await Promise.all(requests);

          this.setForm();
          this.$_onRequestSuccess({
            toastOptions: { message: `Successfully updated your ${this.deliveryServiceName} configurations` }
          });
        }
        catch (error) {
          this.$_onRequestError({
            toastOptions: { message: `There was an error updating your ${this.deliveryServiceName} configurations` },
            error
          });
        }
        finally {
          this.isSubmitting = false;
        }
      }
    }
  };
</script>

<style lang="sass" scoped>
  .input-set
    grid-template-columns: auto repeat(2, 1fr)

  .sub-section
    border-radius: $radius
    background-color: $white-bis
    padding: $size-normal
    margin: $size-normal
    border: 1px solid $grey-lightest
</style>
