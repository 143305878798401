<template>
  <validated-form
    ref="form"
    auto-focus
    form-id="addEditMerchantLinkHeaderModal"
    @valid-submit="handleSubmit"
  >
    <modal-card :title="modalTitle">
      <validated-text-input
        v-model="form.title"
        placeholder="i.e. Customer Support"
        :has-icon="false"
        label="Link Title"
        name="title"
        rules="required"
        type="text"
      />

      <template #footer>
        <div class="buttons all-bold">
          <b-button
            rounded
            @click="$_confirmCloseModal({ programmatic: true })"
          >
            Cancel
          </b-button>

          <b-button
            v-tabbable
            rounded
            native-type="submit"
            type="is-primary"
            :loading="isSubmitting"
            :disabled="isSubmitting"
          >
            Save
          </b-button>
        </div>
      </template>
    </modal-card>
  </validated-form>
</template>

<script>
  import confirmModalCloseMixin from '@/mixins/confirm-modal-close';

  // classes
  import MerchantLinkHeader from '@/store/classes/MerchantLinkHeader';



  export default {
    name: 'AddEditMerchantLinkHeaderModal',

    mixins: [confirmModalCloseMixin],

    props: {
      merchantLinkHeader: {
        type: Object,
        required: true
      }
    },

    data() {
      return {
        form: null
      };
    },

    computed: {
      isSubmitting() {
        return MerchantLinkHeader.$state().submitting;
      },

      modalTitle() {
        return `${!this.merchantLinkHeader.id ? 'Add' : 'Update'} Link Header`;
      }
    },

    created() {
      this.onCreated();
    },

    methods: {
      onCreated() {
        this.form = this.$clone(this.merchantLinkHeader);
      },

      async handleSubmit() {
        let header;
        try {
          if (this.form.id) {
            await MerchantLinkHeader.updateMerchantLinkHeader(this.form);
          }
          else {
            header = await MerchantLinkHeader.createMerchantLinkHeader(this.form);
          }

          const options = !this.form.id
            ? {
              closeParent: true,
              emit: {
                name: 'add-header',
                arg: header.id
              }
            }
            : {
              closeParent: true
            };
          this.$_onRequestSuccess({
            toastOptions: {
              message: `Your link header was successfully ${this.form.id ? 'updated' : 'added'}!`
            },
            options
          });
        }

        catch (error) {
          this.$_onRequestError({
            error,
            toastOptions: {
              message: `There was an error ${this.form.id ? 'updating' : 'adding'} your link header`
            }
          });
        }
      }
    }
  };
</script>
