<template>
  <div class="b-tabs is-floating is-fullwidth">
    <nav class="tabs">
      <ul>
        <li
          v-for="(tab, i) in tabs"
          :key="tab.id"
          :data-test-id="tab.id"
          :class="[{ 'is-active': activeTabId === tab.id }]"
        >
          <a
            :class="{ 'disabled': disableTabs }"
            @click="!disableTabs && setActiveTabId(tab.id)"
          >
            <span>{{ tab.label }}</span>
            <b-icon v-if="invalidTabIndices.includes(i)" type="is-danger" icon="exclamation-circle" />
          </a>
        </li>
      </ul>
    </nav>

    <section class="tab-content">
      <slot class="tab-item" :active-tab-id="activeTabId" />
    </section>
  </div>
</template>

<script>
  export default {
    name: 'ModalTabs',

    props: {
      tabs: {
        type: Array,
        required: true
      },

      disableTabs: {
        type: Boolean,
        default: false
      },

      invalidTabIndices: {
        type: Array,
        default: () => []
      },

      defaultTabId: {
        type: String,
        default: null
      },

      navigateToTabId: {
        type: String,
        default: null
      }
    },

    data: instance => ({
      activeTabId: instance.defaultTabId || instance.tabs[0]?.id || null
    }),

    watch: {
      navigateToTabId: {
        handler(newValue) {
          if (newValue) {
            this.setActiveTabId(newValue);
          }
          this.$emit('clear-navigate-to-tab-id');
        }
      }
    },

    methods: {
      setActiveTabId(tabId) {
        this.activeTabId = tabId;
      }
    }
  };
</script>
