<template>
  <validated-form
    ref="form"
    auto-focus
    form-id="regionMenuForm"
    @valid-submit="handleSubmit"
  >
    <modal-card
      :title="`${regionMenuId ? 'Update' : 'Add'} Regional Menu`"
      modal-card-body-class="pad-none"
    >
      <fieldset class="pad-t-md pad-x-md">
        <validated-text-input
          v-model="form.name"
          :has-icon="false"
          label="Name"
          name="name"
          type="text"
          class="mar-b-lg"
          rules="required"
        />

        <regional-location-dropdown
          v-model="selectedStoreIds"
          name="selectedStoreIds"
          label="Locations"
          sub-label="Select which Locations this Regional Menu applies to"
          :stores="stores"
          :loading="isFetching"
          expanded
          select-all-mode="includeAll"
        >
          <template #store-details="{ store }">
            <p
              v-show="store.regionMenu && store.regionMenu.id !== regionMenuId"
              class="is-size-7 has-text-weight-normal"
            >
              <span :class="selectedStoreIds.includes(store.storeId) ? 'has-text-danger-dark' : 'has-text-grey'">
                {{ store.regionMenu ? store.regionMenu.name : null }}
              </span>
              <span v-show="selectedStoreIds.includes(store.storeId)">
                <b-icon icon="long-arrow-right" />
                <span class="has-text-success-dark">{{ form.name || 'New Regional Menu' }}</span>
              </span>
            </p>
          </template>
        </regional-location-dropdown>

        <b-message
          v-if="reassignedStores.length"
          type="is-warning"
          class="is-compact has-shadow"
        >
          <p>The following Locations will be reassigned</p>
          <p>
            to the
            <span :class="form.name && 'has-text-weight-bold'">
              {{ form.name || 'new' }}
            </span>
            Regional Menu:
          </p>
          <ul class="has-list-styles pad-l-xs mar-t-xs is-size-7">
            <li v-for="store in reassignedStores" :key="store.storeId">
              {{ store.description }}
            </li>
          </ul>
        </b-message>

        <b-message
          v-if="!selectedStoreIds.length"
          type="is-warning"
          class="is-compact has-shadow"
        >
          <p>This Regional Menu will not be applied to any locations until you select them.</p>
        </b-message>
      </fieldset>

      <div class="pad-y-lg pad-x-md has-border-bottom has-border-grey-lighter">
        <p class="label mar-none is-5">Menu Exclusions</p>
        <p class="has-text-grey is-size-7 mar-b">Unselect the menu resources that you want excluded from this Regional Menu</p>

        <div v-if="isFetching" class="is-flex gap-xs">
          <span v-for="i in 4" :key="i" class="flex-grow">
            <b-skeleton height="2.5rem" />
          </span>
        </div>
        <template v-else>
          <template v-if="menuTypes.length > 6">
            <b-field label-position="on-border" label="Menu Type">
              <b-select v-model="selectedMenuTypeId" expanded>
                <option
                  v-for="menuType in menuTypes"
                  :key="menuType.id"
                  :value="menuType.id"
                >
                  {{ capitalCase(menuType.name) }}
                </option>
              </b-select>
            </b-field>
          </template>
          <b-field v-else class="is-flex mar-none">
            <b-radio-button
              v-for="menuType in menuTypes"
              :key="menuType.id"
              v-model="selectedMenuTypeId"
              :native-value="menuType.id"
              expanded
            >
              {{ capitalCase(menuType.name) }}
            </b-radio-button>
          </b-field>
        </template>
      </div>

      <menu-resource-select
        :menu-type-id="selectedMenuTypeId"
        reversed
        included-in-menu-type
        hide-selected-toggle
        category-select
        modifier-select
        :selected-item-ids="selectedItemIdsByMenuType[selectedMenuTypeId]"
        :selected-category-ids="selectedCategoryIdsByMenuType[selectedMenuTypeId]"
        :selected-modifier-ids="selectedModifierIdsByMenuType[selectedMenuTypeId]"
        @item-selected="itemIds => $set(selectedItemIdsByMenuType, selectedMenuTypeId, itemIds)"
        @category-selected="categoryIds => $set(selectedCategoryIdsByMenuType, selectedMenuTypeId, categoryIds)"
        @modifier-selected="modifierIds => $set(selectedModifierIdsByMenuType, selectedMenuTypeId, modifierIds)"
      />

      <template #footer>
        <div class="buttons all-bold">
          <b-button
            rounded
            @click="$_confirmCloseModal({ programmatic: true })"
          >
            Cancel
          </b-button>
          <b-button
            v-tabbable
            rounded
            native-type="submit"
            type="is-primary"
            :loading="isSubmitting"
          >
            Save Exclusions
          </b-button>
        </div>
      </template>
    </modal-card>
  </validated-form>
</template>

<script>
  import Store from '@/store/classes/Store';
  import RegionMenu from '@/store/classes/RegionMenu';
  import RegionMenuItem from '@/store/classes/RegionMenuItem';
  import RegionMenuItemModifier from '@/store/classes/RegionMenuItemModifier';
  import RegionMenuCategory from '@/store/classes/RegionMenuCategory';
  import MerchantMenuTypeConfiguration from '@/store/classes/MerchantMenuTypeConfiguration';
  import confirmModalCloseMixin from '@/mixins/confirm-modal-close';
  import merchantMixin from '@/mixins/merchant';
  import { capitalCase } from 'change-case';
  import getChangedResources from '@/helpers/get-changed-resources';

  export default {
    name: 'RegionMenuModal',

    mixins: [confirmModalCloseMixin, merchantMixin],

    props: {
      regionMenuId: {
        type: Number,
        default: null
      }
    },

    data() {
      return {
        capitalCase,
        form: {},
        selectedItemIdsByMenuType: {},
        selectedCategoryIdsByMenuType: {},
        selectedModifierIdsByMenuType: {},
        selectedStoreIds: [],
        selectedMenuTypeId: 0,
        isSubmitting: false,
        isFetching: false
      };
    },

    computed: {
      stores() {
        return Store.orderByName().with('regionMenu').get();
      },

      reassignedStores() {
        return this.stores.filter((store) => {
          const belongsToAnotherRegionMenu = store.menuRegionId && store.menuRegionId !== this.regionMenuId;
          const selectedForCurrentRegionMenu = this.selectedStoreIds.includes(store.storeId);
          return belongsToAnotherRegionMenu && selectedForCurrentRegionMenu;
        });
      },

      menuTypes() {
        return this.$_selectedMerchant.supportedMenuTypes;
      },

      regionMenu() {
        if (this.regionMenuId) return RegionMenu.query().withAll().find(this.regionMenuId);
        else return new RegionMenu();
      },

      regionMenuStoreIds() {
        return this.regionMenu.stores.map(s => s.storeId);
      },

      existingItemIdsByMenuType() {
        return this.menuTypes.reduce((obj, menuType) => {
          obj[menuType.id] = this.regionMenu.regionMenuItems.reduce((itemIds, rmi) => {
            if (rmi.menuTypeId === menuType.id) itemIds.push(rmi.menuItemId);
            return itemIds;
          }, []);
          return obj;
        }, {});
      },

      existingCategoryIdsByMenuType() {
        return this.menuTypes.reduce((obj, menuType) => {
          obj[menuType.id] = this.regionMenu.regionMenuCategories.reduce((categoryIds, rmc) => {
            if (rmc.menuTypeId === menuType.id) categoryIds.push(rmc.menuCategoryId);
            return categoryIds;
          }, []);
          return obj;
        }, {});
      },

      existingModifierIdsByMenuType() {
        return this.menuTypes.reduce((obj, menuType) => {
          obj[menuType.id] = this.regionMenu.regionMenuItemModifiers.reduce((modifierIds, rmm) => {
            if (rmm.menuTypeId === menuType.id) modifierIds.push(rmm.menuItemModifierId);
            return modifierIds;
          }, []);
          return obj;
        }, {});
      }
    },

    created() {
      this.onCreated();
    },

    methods: {
      async onCreated() {
        this.form = this.$clone(this.regionMenu);

        try {
          this.isFetching = true;
          await Promise.all([
            MerchantMenuTypeConfiguration.fetchMerchantMenuTypeConfigurations(),
            Store.fetchAll()
          ]);

          this.selectedMenuTypeId = this.menuTypes[0].id;
          this.selectedStoreIds = this.$clone(this.regionMenuStoreIds);
          this.selectedItemIdsByMenuType = this.$clone(this.existingItemIdsByMenuType);
          this.selectedCategoryIdsByMenuType = this.$clone(this.existingCategoryIdsByMenuType);
          this.selectedModifierIdsByMenuType = this.$clone(this.existingModifierIdsByMenuType);
        }
        catch (error) {
          this.$_onRequestError({
            toastOptions: { message: 'There was an issue fetching dependencies' },
            error
          });
        }
        finally {
          this.isFetching = false;
        }
      },

      async updateStores(menuRegionId) {
        try {
          const { added, removed } = getChangedResources({ newArray: this.selectedStoreIds, oldArray: this.regionMenuStoreIds });
          const updatedStores = [
            ...added.map(id => ({ storeId: id, menuRegionId })),
            ...removed.map(id => ({ storeId: id, menuRegionId: null }))
          ];

          if (updatedStores.length) await Store.updateStores(updatedStores);
        }

        catch (error) {
          this.$_onRequestError({
            toastOptions: { message: 'There was an issue updating assigned Locations' },
            error
          });
        }
      },

      getAddedRemovedRegionMenuResources({
        menuRegionId,
        selectedResourceIdsByMenuType,
        existingResourceIdsByMenuType,
        primaryKey,
        existingRegionMenuResources
      }) {
        const addedResources = [];
        const removedResourceIds = [];

        Object.entries(selectedResourceIdsByMenuType).forEach(([_menuTypeId, selectedResourceIds]) => {
          const menuTypeId = Number(_menuTypeId);
          const { added, removed } = getChangedResources({
            newArray: selectedResourceIds,
            oldArray: existingResourceIdsByMenuType[menuTypeId]
          });

          if (added.length) {
            addedResources.push(
              ...added.map(resourceId => ({
                menuRegionId,
                menuTypeId,
                [primaryKey]: resourceId,
                isExcluded: true
              }))
            );
          }
          if (removed.length) {
            const removedIds = existingRegionMenuResources.reduce((ids, existingResource) => {
              if (existingResource.menuTypeId === menuTypeId && removed.includes(existingResource[primaryKey])) {
                ids.push(existingResource.id);
              }
              return ids;
            }, []);
            removedResourceIds.push(...removedIds);
          }
        });

        return { addedResources, removedResourceIds };
      },

      async addRemoveRegionMenuItemModifiers(menuRegionId) {
        try {
          const { addedResources, removedResourceIds } = this.getAddedRemovedRegionMenuResources({
            menuRegionId,
            selectedResourceIdsByMenuType: this.selectedModifierIdsByMenuType,
            existingResourceIdsByMenuType: this.existingModifierIdsByMenuType,
            primaryKey: 'menuItemModifierId',
            existingRegionMenuResources: this.regionMenu.regionMenuItemModifiers
          });

          await Promise.all([
            addedResources.length && RegionMenuItemModifier.bulkAddRegionMenuItemModifiers(menuRegionId, addedResources),
            removedResourceIds.length && RegionMenuItemModifier.bulkDeleteRegionMenuItemModifiers(removedResourceIds)
          ]);
        }
        catch (error) {
          this.$_onRequestError({
            toastOptions: { message: 'There was an issue updating excluded Modifiers' },
            error
          });
        }
      },

      async addRemoveRegionMenuItems(menuRegionId) {
        try {
          const { addedResources, removedResourceIds } = this.getAddedRemovedRegionMenuResources({
            menuRegionId,
            selectedResourceIdsByMenuType: this.selectedItemIdsByMenuType,
            existingResourceIdsByMenuType: this.existingItemIdsByMenuType,
            primaryKey: 'menuItemId',
            existingRegionMenuResources: this.regionMenu.regionMenuItems
          });

          await Promise.all([
            addedResources.length && RegionMenuItem.bulkAddRegionMenuItems(menuRegionId, addedResources),
            removedResourceIds.length && RegionMenuItem.bulkDeleteRegionMenuItems(removedResourceIds)
          ]);
        }
        catch (error) {
          this.$_onRequestError({
            toastOptions: { message: 'There was an issue updating excluded Items' },
            error
          });
        }
      },

      async addRemoveRegionMenuCategories(menuRegionId) {
        try {
          const { addedResources, removedResourceIds } = this.getAddedRemovedRegionMenuResources({
            menuRegionId,
            selectedResourceIdsByMenuType: this.selectedCategoryIdsByMenuType,
            existingResourceIdsByMenuType: this.existingCategoryIdsByMenuType,
            primaryKey: 'menuCategoryId',
            existingRegionMenuResources: this.regionMenu.regionMenuCategories
          });

          await Promise.all([
            addedResources.length && RegionMenuCategory.bulkAddRegionMenuCategories(menuRegionId, addedResources),
            removedResourceIds.length && RegionMenuCategory.bulkDeleteRegionMenuCategories(removedResourceIds)
          ]);
        }
        catch (error) {
          this.$_onRequestError({
            toastOptions: { message: 'There was an issue updating excluded Categories' },
            error
          });
        }
      },

      async handleSubmit() {
        try {
          this.isSubmitting = true;

          const requests = [];
          let menuRegionId = this.regionMenuId;

          if (!menuRegionId) {
            const newRegionMenu = await RegionMenu.addRegionMenu(this.form);
            menuRegionId = newRegionMenu.id;
          }
          else {
            requests.push(RegionMenu.updateRegionMenu(this.form));
          }

          requests.push(
            this.updateStores(menuRegionId),
            this.addRemoveRegionMenuItems(menuRegionId),
            this.addRemoveRegionMenuItemModifiers(menuRegionId),
            this.addRemoveRegionMenuCategories(menuRegionId)
          );

          await Promise.all(requests);

          this.$_onRequestSuccess({
            toastOptions: { message: `Successfully ${this.regionMenuId ? 'updated' : 'added'} the <b>${this.form.name}</b> regional menu!` },
            options: { closeParent: true }
          });
        }

        catch (error) {
          this.$_onRequestError({
            toastOptions: { message: `An error occured while ${this.regionMenuId ? 'updating' : 'adding'} the <b>${this.form.name}</b> regional menu` },
            error
          });
        }

        finally {
          this.isSubmitting = false;
        }
      }
    }
  };
</script>

<style lang="sass" scoped>
  .is-moved
    color: darken($primary, 6)
</style>
