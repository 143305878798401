<template>
  <div>
    <validated-text-input
      v-model="value.displayName"
      :has-icon="false"
      label="Name"
      name="name"
      maxlength="50"
      :has-counter="true"
      rules="required"
      type="text"
      :disabled="readOnly"
    />

    <validated-input
      v-if="value.type === modifierGroupTypes.PORTION && isNewModifierGroup"
      name="portionTypes"
      rules="required"
      label="Portion Types"
      :sub-label="`Select the portion types you would like to include in your menu for ${item ? item.displayName : 'this modifier group'}`"
    >
      <div class="is-grid col-2 gap-md">
        <check-button
          v-for="template in modifierGroupPortionTemplates"
          :key="template.constant"
          v-model="value.portionVariations"
          :native-value="template.constant"
          left-label
          justify-between
          :disabled="readOnly"
        >
          <div class="portion-type-image mar-r-sm pad-xs is-flex">
            <img
              class="mar-none"
              :alt="`${template.display}`"
              :src="getPortionTemplateTypeIcon(template.constant)"
            >
          </div>
          <span class="mar-r template-name">{{ template.display }}</span>
        </check-button>
      </div>
    </validated-input>

    <template v-if="value.type !== modifierGroupTypes.PORTION">
      <hr>
      <p class="subtitle is-5 is-marginless">
        Modifier Selections
      </p>
      <p class="is-size-7 has-text-grey mar-b-md">
        How many different modifiers can or must be selected from this group?
      </p>

      <validated-input
        class="left-aligned-label"
        horizontal
        label="Min. Required"
        name="minRequired"
        :rules="{
          required: true,
          max_value: isNewModifierGroup ? 10000 : modifierCount
        }"
      >
        <b-numberinput
          v-model="value.minRequired"
          :editable="false"
          controls-position="compact"
          :min="quantityRules.minRequired.min"
          :max="quantityRules.minRequired.max"
          :disabled="readOnly"
        />
      </validated-input>

      <validated-input
        class="left-aligned-label"
        horizontal
        label="Max. Allowed"
        name="maxAllowed"
        :rules="{
          required: true,
          min_value: hasMax ? value.minRequired : -1
        }"
      >
        <numberinput-switch
          v-model="value.maxAllowed"
          switch-label="Unlimited"
          :disabled="readOnly || !$can('update', 'MenuItemModifierGroup') && !$_menuPermissions.EDIT_RESOURCE"
          :true-value="-1"
          :false-value="value.minRequired > modifierCount ? value.minRequired : modifierCount"
          :min="quantityRules.maxAllowed.min"
          :max="quantityRules.maxAllowed.max"
        />
      </validated-input>

      <hr>

      <p class="subtitle is-5 is-marginless">
        Group Quantity
      </p>
      <p class="is-size-7 has-text-grey mar-b-md">
        What total quantity of modifiers can or must be selected across all available modifiers in this group?
      </p>

      <validated-input
        class="left-aligned-label"
        horizontal
        label="Min. Required"
        name="minRequired"
        :rules="{
          required: true,
          max_value: value.maxModifierQtyAllowed === -1 ? 10000 : value.maxModifierQtyAllowed
        }"
      >
        <b-numberinput
          v-model="value.minModifierQtyRequired"
          :editable="false"
          controls-position="compact"
          :min="quantityRules.minModifierQtyRequired.min"
          :max="quantityRules.minModifierQtyRequired.max"
          :disabled="readOnly"
        />
      </validated-input>

      <validated-input
        class="left-aligned-label"
        horizontal
        label="Max. Allowed"
        name="maxAllowed"
        :rules="{
          required: true,
          min_value: value.minModifierQtyRequired || -1
        }"
      >
        <numberinput-switch
          v-model="value.maxModifierQtyAllowed"
          switch-label="Unlimited"
          :disabled="readOnly || !$can('update', 'MenuItemModifierGroup') && !$_menuPermissions.EDIT_RESOURCE"
          :true-value="-1"
          :false-value="value.minModifierQtyRequired ? value.minModifierQtyRequired : 1"
          :min="quantityRules.maxModifierQtyAllowed.min"
          :max="quantityRules.maxModifierQtyAllowed.max"
        />
      </validated-input>
    </template>
  </div>
</template>

<script>
  import merchantMixin from '@/mixins/merchant';
  import { modifierGroupPortionTemplates, modifierGroupTypes } from '@/constants/modifierGroups';

  export default {
    name: 'ModifierGroupSettingsForm',

    mixins: [merchantMixin],

    props: {
      value: {
        type: Object,
        required: true
      },

      item: {
        type: Object,
        default: null
      },

      readOnly: {
        type: Boolean,
        default: false
      }
    },

    data() {
      return {
        modifierGroupTypes,
        modifierGroupPortionTemplates
      };
    },

    computed: {
      isNewModifierGroup() {
        return !this.value.id;
      },

      hasMax() {
        return this.value.maxAllowed > 0;
      },

      quantityRules() {
        const arbitraryMax = 10000;

        return {
          minRequired: {
            min: 0,
            max: this.hasMax ? this.value.maxAllowed : (this.isNewModifierGroup ? arbitraryMax : this.modifierCount) // eslint-disable-line no-nested-ternary
          },
          maxAllowed: {
            min: (this.selectedModifierCount > this.value.minRequired ? this.selectedModifierCount : this.value.minRequired) || 1,
            max: this.isNewModifierGroup ? arbitraryMax : this.modifierCount
          },
          minModifierQtyRequired: {
            min: 0,
            max: this.value.maxModifierQtyAllowed === -1 ? arbitraryMax : this.value.maxModifierQtyAllowed
          },
          maxModifierQtyAllowed: {
            min: this.value.minModifierQtyRequired || 1,
            max: arbitraryMax
          }
        };
      },

      modifierCount() {
        return this.value.menuItemModifiers.length;
      },

      selectedModifierCount() {
        return this.value.menuItemModifiers.filter(modifier => modifier.isDefault).length;
      }
    },

    async created() {
      await this.onCreated();
    },

    methods: {
      async onCreated() {
        if (this.isNewModifierGroup && this.value.type === modifierGroupTypes.PORTION) {
          this.$emit('input', {
            ...this.value,
            displayName: 'Portions',
            portionVariations: [
              modifierGroupPortionTemplates.FULL_GROUP_TEMPLATE.constant,
              modifierGroupPortionTemplates.HALF_GROUP_TEMPLATE.constant
            ]
          });
        }
      },

      getPortionTemplateTypeIcon(modifierTemplateType) {
        switch (modifierTemplateType) {
          case modifierGroupPortionTemplates.FULL_GROUP_TEMPLATE.constant:
            return '/images/whole-portion.svg';
          case modifierGroupPortionTemplates.HALF_GROUP_TEMPLATE.constant:
            return '/images/half-portion.svg';
          default:
        }
      }
    }
  };
</script>

<style lang="sass" scoped>
  .left-aligned-label
    @include left-aligned-horizontal-label(140px)

  .portion-type-image
    border-radius: $radius
    height: 2.5rem
    width: 2.5rem

  ::v-deep.checkbox
    &.checked
      .template-name
        color: $primary

      .portion-type-image
        background-color: $primary-lighter

        img
          filter: invert(39%) sepia(98%) saturate(2353%) hue-rotate(184deg) brightness(104%) contrast(106%)

    .control-label
      display: flex
      align-items: center
</style>
