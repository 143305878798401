<template>
  <div>
    <tabs-page
      v-if="$_selectedMerchant"
      :tabs="tabs"
      :keep-alive="false"
      title="Data Exports"
      show-single-tab
    />
  </div>
</template>

<script>
  import merchantMixin from '@/mixins/merchant';
  import OrderingDataTab from './tabs/ordering-data-tab.vue';

  export default {
    name: 'DataExports',

    mixins: [merchantMixin],

    computed: {
      tabs() {
        return [
          {
            display: 'Ordering',
            name: 'ordering',
            component: OrderingDataTab,
            props: {}
          }
        ];
      }
    }
  };
</script>
