<template>
  <tabs-page
    v-if="$_selectedMerchant"
    :tabs="tabs"
    :keep-alive="false"
    title="Content"
  />
</template>

<script>
  import { mapGetters } from 'vuex';

  import merchantMixin from '@/mixins/merchant';

  // tabs
  import contentGeneralTab from './tabs/content-general-tab.vue';
  import contentMerchantLinks from './form/content-merchant-links.vue';
  import contentInstructionsTab from './tabs/content-instructions-tab.vue';
  import contentFaqTab from './tabs/content-faq-tab.vue';
  import appMaintenance from './tabs/app-maintenance.vue';
  import surveysContainer from './survey/surveys-container.vue';
  import contentTab from './tabs/content-tab.vue';
  import InAppMessagesTab from './tabs/in-app-messages-tab.vue';

  import MerchantAppSetting from '@/store/classes/MerchantAppSetting';


  export default {
    name: 'ContentManagement',

    mixins: [merchantMixin],

    computed: {
      ...mapGetters('session', ['isCardfreeAdmin']),

      merchantHasMobileAppConfigured() {
        return MerchantAppSetting.hasMobileAppConfigured();
      },

      tabs() {
        const { patEnabled, oatEnabled, orderAheadEnabled } = this.$_selectedMerchant;

        return [
          {
            display: 'General Content',
            name: 'general-content',
            component: contentGeneralTab
          },
          {
            display: 'In-App Messages',
            name: 'in-app-messages',
            component: InAppMessagesTab,
            props: { merchantHasMobileAppConfigured: !!this.merchantHasMobileAppConfigured }
          },
          {
            display: 'Instructions',
            name: 'instructions',
            component: contentInstructionsTab
          },
          {
            display: 'Navigation Links',
            name: 'navigation-links',
            component: contentMerchantLinks
          },
          {
            display: 'FAQs',
            name: 'faqs',
            component: contentFaqTab,
            isHidden: [patEnabled, oatEnabled, orderAheadEnabled].every(x => !x)
          },
          {
            display: 'Surveys',
            name: 'surveys',
            component: surveysContainer,
            isHidden: !this.$can('edit', 'survey') || !this.$_selectedMerchant.features.surveys
          },
          {
            display: 'Maintenance',
            name: 'maintenance',
            component: appMaintenance,
            isHidden: !this.isCardfreeAdmin
          },
          {
            display: 'Content Management',
            name: 'content-managment',
            component: contentTab,
            isHidden: !this.isCardfreeAdmin
          }
        ];
      }
    },

    created() {
      this.onCreated();
    },

    methods: {
      async onCreated() {
        await this.fetchMerchantAppSettings();
      },

      async fetchMerchantAppSettings() {
        try {
          await MerchantAppSetting.fetchMerchantAppSettings(this.$_selectedMerchant.id);
        }
        catch (error) {
          this.$_onRequestError({
            error,
            toastOptions: {
              message: 'There was an error fetching merchant app settings.'
            }
          });
        }
      }
    }
  };

</script>
