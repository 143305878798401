<template>
  <panel title="Earn Rules" no-padding>
    <b-loading v-if="isFetching" :active="isFetching" :is-full-page="false" />

    <searchable-table
      :table-data="earnRules"
      :search="{
        placeholder: 'Search by Earn Rule name',
        keys: ['name']
      }"
      :filters="[
        {
          placeholder: 'Engagement Type',
          key: 'engagementType',
          options: (earnRules.reduce((array, er) => {
            if(!array.some(item => item.value === er.engagementType)) {
              array.push({ name: er.engagementTypeName, value: er.engagementType });
            }

            return array;
          }, []))
        },
        {
          placeholder: 'Status',
          key: 'portalDerivedStatus',
          multiple: true,
          default: [earnRuleStatuses.ACTIVE, earnRuleStatuses.FUTURE, earnRuleStatuses.SCHEDULED, earnRuleStatuses.EXPIRING],
          options: Object.keys(tagTypes).map(key => ({
            name: $changeCase.capitalCase(key),
            value: key
          }))
        },
      ]"
    >
      <template #optional-actions>
        <b-button
          type="is-primary"
          icon-left="plus"
          @click="openEarnRuleModal({ earnRule: null })"
        >
          Earn Rule
        </b-button>
      </template>

      <template #default="{ filteredData }">
        <b-table
          :data="isFetching ? [] : filteredData"
          class="is-middle-aligned"
          :mobile-cards="false"
          scrollable
          detail-key="publicId"
          data-test-id="earn-rules-table"
          :detailed="filteredData.some(row => row.previousVersions.length)"
          :has-detailed-visible="row => row.previousVersions.length"
          custom-detail-row
        >

          <template #empty>
            <empty-table-loader
              message="No Earn Rules Found..."
              :loading="isFetching"
            />
          </template>

          <b-table-column
            v-slot="{ row }"
            sortable
            field="name"
            label="Name"
            cell-class=""
          >
            <span class="link" @click="openEarnRuleModal({ earnRule: row })">
              {{ row.name }}
            </span>
            <p class="is-size-7">
              {{ row.description }}
            </p>
          </b-table-column>

          <b-table-column
            v-slot="{ row }"
            sortable
            field="engagementTypeName"
            label="Engagement Type"
            cell-class="no-wrap-text"
          >
            {{ row.engagementTypeName }}
          </b-table-column>

          <b-table-column
            v-slot="{ row }"
            sortable
            field="publicId"
            label="ID"
            cell-class="no-wrap-text is-monospaced"
          >
            {{ row.portalDerivedStatus === earnRuleStatuses.DRAFT ? 'N/A' : row.publicId }}
          </b-table-column>

          <b-table-column
            v-slot="{ row }"
            sortable
            field="portalDerivedStatus"
            label="Status"
            centered
            cell-class="no-wrap-text"
          >
            <b-tag :type="tagTypes[row.portalDerivedStatus]" class="is-outlined is-light">
              {{ $changeCase.capitalCase(row.portalDerivedStatus) }}
            </b-tag>
          </b-table-column>

          <b-table-column
            v-slot="{ row }"
            centered
            cell-class="actions"
            label="Actions"
            width="1"
            field="Actions"
          >
            <dropdown-menu position="bottom-end" :mobile-modal="false">
              <b-button slot="trigger" class="is-transparent" type="is-white">
                <b-icon icon="ellipsis-v" pack="far" size="is-small" />
              </b-button>

              <b-dropdown-item @click="openEarnRuleModal({ earnRule: row })">
                <b-icon
                  :icon="row.portalDerivedStatus === earnRuleStatuses.EXPIRED ? 'eye' : 'pencil'"
                  class="mar-r-sm"
                  size="is-small"
                />
                {{ row.portalDerivedStatus === earnRuleStatuses.EXPIRED ? 'View' : 'Edit' }} Earn Rule
              </b-dropdown-item>
              <b-dropdown-item
                v-if="canExpireEarnRule(row.portalDerivedStatus)"
                class="is-danger"
                @click="confirmExpire(row)"
              >
                <b-icon icon="calendar-times" class="mar-r-sm" size="is-small" />
                Expire Earn Rule
              </b-dropdown-item>
              <b-dropdown-item
                v-if="canDeleteEarnRule(row.portalDerivedStatus)"
                class="is-danger"
                @click="confirmDelete(row.publicId)"
              >
                <b-icon icon="trash-alt" class="mar-r-sm" size="is-small" />
                Remove Earn Rule
              </b-dropdown-item>
            </dropdown-menu>
          </b-table-column>

          <template #detail="{ row }">
            <tr
              v-for="previousVersion in row.previousVersions"
              :key="`${previousVersion.publicId}-v${previousVersion.version}`"
              class="has-text-grey detail-row"
            >
              <td class="has-text-centered">v{{ previousVersion.version }}</td>
              <td>
                <span
                  class="link"
                  @click="openEarnRuleModal({
                    earnRule: row,
                    version: previousVersion.version
                  })"
                >
                  {{ previousVersion.name }}
                </span>
                <p class="is-size-7">
                  {{ previousVersion.description }}
                </p>
              </td>
              <td />
              <td />
              <td>
                <b-tag :type="tagTypes[previousVersion.portalDerivedStatus]" class="is-outlined is-light">
                  {{ $changeCase.capitalCase(previousVersion.portalDerivedStatus) }}
                </b-tag>
              </td>
              <td class="actions has-text-centered">
                <dropdown-menu position="bottom-end" :mobile-modal="false">
                  <b-button slot="trigger" class="is-transparent" type="is-white">
                    <b-icon icon="ellipsis-v" pack="far" size="is-small" />
                  </b-button>
                  <b-dropdown-item @click="openEarnRuleModal({ earnRule: row, version: previousVersion.version })">
                    <b-icon icon="eye" class="mar-r-sm" size="is-small" />
                    View Earn Rule
                  </b-dropdown-item>
                </dropdown-menu>
              </td>
            </tr>
          </template>

        </b-table>
      </template>
    </searchable-table>
  </panel>
</template>

<script>
  import moment from 'moment-timezone';

  import AlertModal from '@/components/globals/alert-modal.vue';

  import { earnRuleStatuses } from '@/constants/earnRules';

  import EarnRule from '@/store/classes/EarnRule';
  import Currency from '@/store/classes/Currency';

  import EarnRuleModal from '../earn-rules/earn-rule-modal.vue';

  export default {
    name: 'EarnRulesTab',

    props: {
      loyaltyProgramPublicId: {
        type: String,
        default: ''
      }
    },

    data: () => ({
      earnRuleStatuses,
      tagTypes: {
        active: 'is-success',
        future: 'is-warning',
        expiring: 'is-danger',
        expired: 'is-light',
        scheduled: 'is-info'
      }
    }),

    computed: {
      isFetching() {
        return EarnRule.$state().fetching;
      },

      primaryCurrency() {
        return Currency.primaryCurrency();
      },

      earnRules() {
        return EarnRule
          .nonSecondaryCurrencyEarnRules(this.primaryCurrency?.publicId)
          .map(earnRule => ({
            ...earnRule,

            /**
             * These class getters get stripped away when rendered in a searchable table,
             * so by assigning them as regular properties they are persisted and
             * available within the table view.
             */
            engagementTypeName: earnRule.engagementTypeName
          }));
      }
    },

    methods: {
      async openEarnRuleModal({ earnRule, version }) {
        if (!earnRule) {
          const startDate = moment().utc().add(1, 'days').format('YYYY-MM-DD');
          earnRule = new EarnRule({
            startDate,
            programPublicId: this.loyaltyProgramPublicId
          });
        }
        else if (version) {
          earnRule = earnRule.previousVersions.find(v => v.version === version);
        }
        else {
          await EarnRule.fetchEarnRule(earnRule);
          earnRule = EarnRule.find(earnRule.publicId);
        }

        this.$buefy.modal.open({
          parent: this,
          component: EarnRuleModal,
          hasModalCard: true,
          trapFocus: true,
          canCancel: false,
          fullScreen: true,
          props: { earnRule, loyaltyProgramPublicId: this.loyaltyProgramPublicId }
        });
      },

      canExpireEarnRule(status) {
        return status === earnRuleStatuses.ACTIVE || status === earnRuleStatuses.FUTURE;
      },

      canDeleteEarnRule(status) {
        return status === earnRuleStatuses.SCHEDULED;
      },

      confirmExpire(earnRule) {
        this.$buefy.modal.open({
          parent: this,
          component: AlertModal,
          hasModalCard: true,
          trapFocus: true,
          canCancel: ['escape', 'outside'],
          props: {
            icon: 'calendar-times',
            title: 'Expire Earn Rule',
            message: 'Are you sure you want to expire this earn rule?',
            type: 'is-danger',
            horizontal: true,
            showCloseButton: false,
            buttons: [
              { text: 'No' },
              {
                text: 'Yes, Expire Earn Rule',
                primary: true,
                onClick: () => this.expireEarnRule(earnRule)
              }
            ]
          }
        });
      },

      confirmDelete(publicId) {
        this.$buefy.modal.open({
          parent: this,
          component: AlertModal,
          hasModalCard: true,
          trapFocus: true,
          canCancel: ['escape', 'outside'],
          props: {
            icon: 'calendar-times',
            title: 'Remove Earn Rule',
            message: 'Are you sure you want to remove this earn rule?',
            type: 'is-danger',
            horizontal: true,
            showCloseButton: false,
            buttons: [
              { text: 'No' },
              {
                text: 'Yes, Remove Earn Rule',
                primary: true,
                onClick: () => this.deleteEarnRule(publicId)
              }
            ]
          }
        });
      },

      async deleteEarnRule(publicId) {
        try {
          await EarnRule.deleteEarnRule(publicId);

          this.$_onRequestSuccess({
            toastOptions: {
              message: 'Successfully removed your earn rule!'
            },
            options: { closeParent: true }
          });
        }

        catch (error) {
          this.$_onRequestError({
            toastOptions: {
              message: 'There was an error removing your earn rule'
            },
            error
          });
        }
      },

      async expireEarnRule(earnRule) {
        const today = moment().utc().format('YYYY-MM-DD');
        await EarnRule.fetchEarnRule(earnRule);
        earnRule = EarnRule.find(earnRule.publicId);
        try {
          await EarnRule.updateEarnRule({ ...earnRule, endDate: today });

          this.$_onRequestSuccess({
            toastOptions: {
              message: 'Successfully updated your earn rule!'
            },
            options: { closeParent: true }
          });
        }

        catch (error) {
          this.$_onRequestError({
            toastOptions: {
              message: 'There was an error updating your earn rule'
            },
            error
          });
        }
      }
    }
  };
</script>
