function formatDecimal(value) {
  if (typeof value !== 'number') {
    return value;
  }
  return value.toLocaleString(undefined, {
    minimumFractionDigits: 0,
    maximumFractionDigits: 2
  });
}

export default formatDecimal;
