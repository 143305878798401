<template>
  <validated-form
    ref="form"
    auto-focus
    form-id="addMerchantPushProvider"
    @valid-submit="addMerchantPushProvider"
  >
    <modal-card title="Add Push Provider" show-overflow>
      <validated-input
        class="mar-b-lg"
        name="selectedPushProvider"
        rules="required"
        label="Push Provider"
      >
        <b-dropdown
          v-model="form.selectedPushProviderType"
          expanded
          class="has-extra-shadow"
        >
          <dropdown-button
            slot="trigger"
            placeholder="Select Push Provider..."
            :value="form.selectedPushProviderType.name"
            class="wrap-text auto-height"
          />

          <b-dropdown-item
            v-for="pushProviderType in allPushProviderTypes"
            :key="pushProviderType.id"
            :value="pushProviderType"
          >
            {{ pushProviderType.name }}
          </b-dropdown-item>
        </b-dropdown>
      </validated-input>

      <template #footer>
        <div class="buttons all-bold">
          <b-button
            rounded
            @click="$_confirmCloseModal({ programmatic: true })"
          >
            Cancel
          </b-button>
          <b-button
            v-tabbable
            rounded
            native-type="submit"
            type="is-primary"
            :loading="isSubmitting"
          >
            Add
          </b-button>
        </div>
      </template>
    </modal-card>
  </validated-form>
</template>

<script>
  import confirmModalCloseMixin from '@/mixins/confirm-modal-close';
  import MerchantPushProvider from '@/store/classes/MerchantPushProvider';
  import PushProviderType from '@/store/classes/PushProviderType';

  export default {
    name: 'AddPushProviderModal',

    mixins: [confirmModalCloseMixin],

    props: {
      merchantId: {
        type: Number,
        required: true
      }
    },

    data() {
      return {
        form: {
          selectedPushProviderType: {}
        }
      };
    },

    computed: {
      isSubmitting() {
        return MerchantPushProvider.$state().submitting;
      },

      allPushProviderTypes() {
        return PushProviderType.all();
      }
    },

    methods: {
      async addMerchantPushProvider() {
        try {
          await MerchantPushProvider.addMerchantPushProvider({
            merchantId: this.merchantId,
            merchantPushProvider: {
              apiConfiguration: {},
              pushProviderTypeId: this.form.selectedPushProviderType?.id
            }
          });
          this.$_onRequestSuccess({
            toastOptions: { message: 'Successfully added push provider!' },
            options: { closeParent: true, emit: { name: 'push-provider-changed' } }
          });
        }

        catch (error) {
          this.$_onRequestError({
            toastOptions: { message: 'An error occurred while adding your new push provider' },
            error
          });
        }
      }
    }
  };
</script>

<style lang="sass" scoped>
  .modal-card
    overflow: visible

    .modal-card-body
      overflow: visible
</style>
