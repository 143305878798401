<template>
  <div>
    <p class="pad-l-sm nested-table-section-title">
      {{ resourceTypeDisplay }}
    </p>
    <component
      :is="activeTab.component"
      v-bind="activeTab.props"
      v-on="activeTab.handlers"
    />
  </div>
</template>

<script>
  import capitalCase from '@/helpers/capitalCase';
  import categorySearchRow from './category-search-row.vue';
  import itemSearchRow from './item-search-row.vue';
  import modifierGroupSearchRow from './modifier-group-search-row.vue';
  import modifierSearchRow from './modifier-search-row.vue';
  import storeCategorySearchRow from './store-category-search-row.vue';
  import storeItemSearchRow from './store-item-search-row.vue';
  import storeModifierGroupSearchRow from './store-modifier-group-search-row.vue';
  import storeModifierSearchRow from './store-modifier-search-row.vue';

  export default {
    name: 'StoreMenuSearchV2',

    props: {
      searchResults: {
        type: Array,
        default: () => []
      },

      menuType: {
        type: Object,
        default: null
      },

      resourceType: {
        type: String,
        required: true,
        validator: value => ['categories', 'items', 'modifierGroups', 'modifiers'].includes(value)
      },

      forStore: {
        type: Boolean,
        default: false
      },

      selectedStoreId: {
        type: Number,
        default: null
      },

      visibleCategoryIds: {
        type: Array,
        default: null
      },

      storeMenuCategorySchedules: {
        type: Array,
        default: () => []
      },

      selectedStoreItems: {
        type: Array,
        default: () => []
      },

      storeMenuItemSchedules: {
        type: Array,
        default: () => []
      },

      visibleModifierGroupIds: {
        type: Array,
        default: () => []
      },

      storeModifiersForSelectedStore: {
        type: Array,
        default: () => []
      }
    },

    computed: {
      resourceTypeDisplay() {
        switch (this.resourceType) {
          case 'categories':
            return 'Categories';
          case 'items':
            return 'Menu Items';
          case 'modifierGroups':
            return 'Modifier Groups';
          case 'modifiers':
            return 'Modifiers';
          default:
            return '';
        }
      },

      activeTab() {
        const masterMenuProps = {
          searchResults: this.searchResults,
          menuType: this.menuType
        };

        const handlers = {
          'shared-mod-group-search-action': args => this.$emit('shared-mod-group-search-action', args)
        };

        const masterMenuTabs = {
          categories: {
            component: categorySearchRow,
            props: masterMenuProps,
            handlers
          },
          items: {
            component: itemSearchRow,
            props: masterMenuProps,
            handlers
          },
          modifierGroups: {
            component: modifierGroupSearchRow,
            props: masterMenuProps
          },
          modifiers: {
            component: modifierSearchRow,
            props: masterMenuProps
          }
        };

        const storeMenuTabs = {
          categories: {
            component: storeCategorySearchRow,
            props: {
              searchResults: this.searchResults,
              selectedStoreId: this.selectedStoreId,
              storeMenuCategorySchedules: this.storeMenuCategorySchedules,
              visibleCategoryIds: this.visibleCategoryIds
            },
            handlers
          },
          items: {
            component: storeItemSearchRow,
            props: {
              searchResults: this.searchResults,
              selectedStoreId: this.selectedStoreId,
              selectedStoreItems: this.selectedStoreItems,
              storeMenuItemSchedules: this.storeMenuItemSchedules
            },
            handlers
          },
          modifierGroups: {
            component: storeModifierGroupSearchRow,
            props: {
              searchResults: this.searchResults,
              visibleModifierGroupIds: this.visibleModifierGroupIds
            },
            handlers
          },
          modifiers: {
            component: storeModifierSearchRow,
            props: {
              searchResults: this.searchResults,
              selectedStoreId: this.selectedStoreId,
              storeModifiersForSelectedStore: this.storeModifiersForSelectedStore
            },
            handlers
          }
        };

        return this.forStore ? storeMenuTabs[this.resourceType] : masterMenuTabs[this.resourceType];
      }
    },

    methods: {
      capitalCase
    }
  };
</script>

<style lang='sass' scoped>
  ::v-deep
    .row
      grid-template-columns: 1fr 200px 170px

    .nested-table-section-title
      background-color: $white-ter
      border-color: darken($white-ter, 10%)
</style>
