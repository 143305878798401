<template>
  <ul class="nested-table-section">
    <li v-if="!menuItemModifiers.length && (!menuPermissions.ADD_RESOURCE || activeMenuType)">
      <div class="row sub-row">
        <span class="has-text-grey pad-y-sm">No Modifiers</span>
        <span />
        <span />
      </div>
    </li>
    <draggable
      v-else
      tag="ul"
      :group="`modifier-group-${modifierGroup.id}-modifiers`"
      v-bind="draggableAttributes"
      :force-fallback="true"
      :value="menuItemModifiers"
      @change="handleModifierSort($event, modifierGroup)"
    >
      <template v-if="menuItemModifiers.length" #header>
        <li class="nested-table-section-title">
          <template v-if="featurePermissions.NESTED_MODIFIERS && modifierGroup.type">
            <span v-if="modifierGroup.type === modifierGroupTypes.PORTION">Portion Types</span>
            <span v-else-if="modifierGroup.type === modifierGroupTypes.COMBO">Combo Items</span>
          </template>
          <span v-else>Modifiers</span>
        </li>
      </template>
      <modifier-row
        v-for="modifier in menuItemModifiers"
        :key="`modifier-${modifier.id}`"
        :modifier="modifier"
        :modifier-group="modifierGroup"
        :has-group-reached-default-modifier-limit="hasGroupReachedDefaultModifierLimit"
        :menu-item="menuItem"
      />
    </draggable>
    <li
      v-if="
        !activeMenuType
          && $can('create', 'MenuItemModifier')
          && menuPermissions.ADD_RESOURCE
          && (!featurePermissions.NESTED_MODIFIERS || modifierGroup.type !== modifierGroupTypes.PORTION)
      "
      class="row sub-row"
    >
      <span>
        <b-button
          class="is-transparent"
          icon-left="plus"
          type="is-primary"
          inverted
          @click="openModifierModal()"
        >
          {{ featurePermissions.NESTED_MODIFIERS && modifierGroup.type === modifierGroupTypes.COMBO ? 'Combo Item' : 'Modifier' }}
        </b-button>
      </span>
      <span /><span />
    </li>
  </ul>
</template>

<script>
  import { mapActions, mapState } from 'vuex';
  import draggable from 'vuedraggable';

  import menuResourceTypes from '@/constants/menuResourceTypes';
  import { modifierGroupTypes } from '@/constants/modifierGroups';

  import Modifier from '@/store/classes/Modifier';

  import AddEditModifierModal from '../main-menu/add-edit-modifier-modal/index.vue';
  import ModifierRow from './modifier-row.vue';

  export default {
    name: 'ModifiersContainer',

    components: { draggable, ModifierRow },

    mixins: [],

    props: {
      menuItemModifiers: {
        type: Array,
        required: true
      },

      modifierGroup: {
        type: Object,
        required: true
      },

      menuItem: {
        type: Object,
        default: null
      }
    },

    data: () => ({
      isOpen: undefined,
      modifierGroupTypes
    }),

    computed: {
      ...mapState('mainMenu', ['activeMenuType', 'menuPermissions', 'featurePermissions', 'draggableAttributes']),

      hasGroupReachedDefaultModifierLimit() {
        const defaultCount = this.modifierGroup.menuItemModifiers.filter(mod => mod.isDefault)?.length || 0;
        return this.modifierGroup.maxAllowed !== -1 && defaultCount >= this.modifierGroup.maxAllowed;
      }
    },

    methods: {
      ...mapActions('mainMenu', ['setMenuResourceOpenState']),

      async handleModifierSort({ moved }, modifierGroup) {
        try {
          await Modifier.updateSortOrders({
            modifierGroup,
            modifiers: modifierGroup.menuItemModifiers,
            oldIndex: moved.oldIndex,
            newIndex: moved.newIndex
          });

          this.$_onRequestSuccess({
            toastOptions: {
              message: `<b>${moved.element.displayName}</b> moved from sort order <b>${moved.oldIndex + 1}</b> to <b>${moved.newIndex + 1}</b>`
            }
          });
        }

        catch (error) {
          this.$_onRequestError({
            toastOptions: { message: 'Unable to update sort orders' },
            error
          });
        }
      },

      openModifierModal() {
        this.$buefy.modal.open({
          parent: this,
          component: AddEditModifierModal,
          hasModalCard: true,
          trapFocus: true,
          canCancel: false,
          customClass: 'auto-width',
          events: {
            'modifier-added': this.handleModifierAdded
          },
          props: {
            modifierGroup: this.modifierGroup,
            mode: 'create'
          }
        });
      },

      async handleModifierAdded(modifierId) {
        await this.refreshModifierGroup(modifierId);

        const modifier = Modifier.find(modifierId);
        if (modifier.canHaveNestedMenuItemModifierGroups) {
          this.toggleModifier(modifierId);
        }
      },

      toggleModifier(id) {
        this.setMenuResourceOpenState({
          resourceType: menuResourceTypes.MODIFIER,
          resourceId: id,
          value: true
        });
      },

      async refreshModifierGroup(modifierId) {
        await this.fetchModifier(modifierId);

        // Refetch MODs for group to display caret that allows adding nested MOD groups to Combo Items
        // This is because we need to get the updated canHaveNestedModifierGroups value (fix for TOOL-4037)
        await this.fetchModifiersByGroupId(this.modifierGroup.id);
      },

      async fetchModifier(modifierId) {
        try {
          await Modifier.fetchModifier(modifierId);
        }
        catch (error) {
          this.$_onRequestError({
            toastOptions: {
              message: 'There was an error fetching the modifier'
            },
            error
          });
        }
      },

      async fetchModifiersByGroupId(groupId) {
        try {
          await Modifier.fetchByModifierGroupId({ modifierGroupId: groupId });
        }
        catch (error) {
          this.$_onRequestError({
            toastOptions: {
              message: 'Unable to fetch modifiers'
            },
            error
          });
        }
      }
    }
  };
</script>

<style lang='sass' scoped>
  .row
    grid-template-columns: 1fr 200px 170px
</style>
