<template>
  <div class="is-flex-wrap gap">
    <b-radio-button
      :value="value"
      :disabled="disabled"
      name="leftRadioButton"
      :native-value="valueLeft"
      type="is-primary"
      :class="['toggle-button', checkPosition === 'is-left' && 'check-position-left']"
      @input="$emit('input', $event)"
    >
      <div class="is-flex-column align-start">
        <p class="has-text-weight-semibold">{{ labelLeft }}</p>
        <div v-if="subLabelLeft || $slots.subLabelLeft" class="sub-label">
          <slot name="subLabelLeft">
            <p class="is-size-7" :class="value === valueLeft ? 'has-text-primary-dark' : 'has-text-grey'">
              {{ subLabelLeft }}
            </p>
          </slot>
        </div>
      </div>
      <b-icon
        :class="[
          'toggle-button-icon',
          {
            'mar-r-sm': checkPosition === 'is-left',
            'mar-l-sm': checkPosition === 'is-right'
          }
        ]"
        :icon="value === valueLeft ? 'check-circle' : 'circle'"
        :pack="value === valueLeft ? 'fas' : 'far'"
        :type="value === valueLeft ? 'is-primary' : 'is-grey-light'"
      />
    </b-radio-button>
    <b-radio-button
      :value="value"
      :disabled="disabled"
      name="rightRadioButton"
      :native-value="valueRight"
      type="is-primary"
      :class="['toggle-button', checkPosition === 'is-left' && 'check-position-left']"
      @input="$emit('input', $event)"
    >
      <div class="is-flex-column align-start">
        <p class="has-text-weight-semibold">{{ labelRight }}</p>
        <div v-if="subLabelRight || $slots.subLabelRight" class="sub-label">
          <slot name="subLabelRight">
            <p class="is-size-7" :class="value === valueRight ? 'has-text-primary-dark' : 'has-text-grey'">
              {{ subLabelRight }}
            </p>
          </slot>
        </div>
      </div>
      <b-icon
        :class="[
          'toggle-button-icon',
          {
            'mar-r-sm': checkPosition === 'is-left',
            'mar-l-sm': checkPosition === 'is-right'
          }
        ]"
        :icon="value === valueRight ? 'check-circle' : 'circle'"
        :pack="value === valueRight ? 'fas' : 'far'"
        :type="value === valueRight ? 'is-primary' : 'is-grey-light'"
      />
    </b-radio-button>
  </div>
</template>

<script>
  export default {
    name: 'ToggleButtons',

    props: {
      value: {
        type: [String, Boolean],
        default: null
      },

      labelLeft: {
        type: String,
        required: true
      },

      labelRight: {
        type: String,
        required: true
      },

      valueLeft: {
        type: [String, Boolean],
        default: true
      },

      valueRight: {
        type: [String, Boolean],
        default: false
      },

      subLabelLeft: {
        type: String,
        default: null
      },

      subLabelRight: {
        type: String,
        default: null
      },

      disabled: {
        type: Boolean,
        default: false
      },

      checkPosition: {
        type: String,
        default: 'is-left',
        validator(value) {
          return ['is-right', 'is-left'].includes(value);
        }
      }
    }
  };
</script>

<style lang="sass" scoped>
  .toggle-button
    transition: 150ms
    .toggle-button-icon
      transition: 150ms
      font-size: 1.2rem
      width: auto
      height: auto

    &:hover .toggle-button-icon
      color: $primary !important

    .sub-label
      margin-top: 2px

    ::v-deep
      label
        height: auto !important
        min-height: 40px
        padding: $size-extra-small $size-small !important
        border-radius: $radius !important

      .is-primary
        background: radial-gradient(closest-side, transparent, $primary-lightest) !important
        border-color: $primary-light !important
        color: $black !important
        &:hover
          border-color: $primary !important

  .check-position-left ::v-deep
    .b-radio.button
      flex-direction: row-reverse
</style>
