import getChangedResources from '@/helpers/get-changed-resources';
import moment from 'moment-timezone';

export default {
  data() {
    return {
      dayparts_invalidDays: [],
      dayparts_form: {}
    };
  },

  methods: {
    $_setDaypartsForm(schedules) {
      const _schedules = schedules
        .reduce((acc, schedule) => {
          if (schedule.startTime || schedule.endTime) {
            const daySchedule = {
              ...schedule,
              startTime: moment(schedule.startTime, 'hh:mm').toDate(),
              endTime: moment(schedule.endTime, 'hh:mm').toDate()
            };
            if (acc[schedule.day]) {
              acc[schedule.day].push(daySchedule);
            }
            else {
              acc[schedule.day] = [daySchedule];
            }
          }
          return acc;
        }, {});

      const daysOfTheWeek = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

      const backfilledSchedules = {};
      daysOfTheWeek.forEach((day) => {
        backfilledSchedules[day] = _schedules[day]?.sort((a, b) => (a.startTime < b.startTime ? -1 : 1)) || [];
      });

      this.dayparts_form = backfilledSchedules;
    },

    $_validateDayparts() {
      let allDaypartsValid = true;
      this.dayparts_invalidDays = [];

      Object.entries(this.dayparts_form).forEach(([day, daypartsForDay]) => {
        daypartsForDay
          .sort((a, b) => (a.startTime < b.startTime ? -1 : 1))
          .forEach((part, index) => {
            const currentEndTime = part.endTime;
            const nextStartTime = daypartsForDay[index + 1]?.startTime;

            if (nextStartTime && currentEndTime >= nextStartTime) {
              allDaypartsValid = false;
              this.dayparts_invalidDays.push(day);
            }
          });
      });

      return allDaypartsValid;
    },

    p_formatScheduleTimes(schedule) {
      return {
        ...schedule,
        startTime: schedule.startTime && moment(schedule.startTime).format('HH:mm'),
        endTime: schedule.endTime && moment(schedule.endTime).format('HH:mm')
      };
    },

    $_getDaypartsToUpdate({ currentDayparts }) {
      const daypartsToUpdate = Object.values(this.dayparts_form)
        .flat()
        .map(schedule => this.p_formatScheduleTimes(schedule));

      const updatedComparisonFn = (newDaypart, oldDaypart) => (
        newDaypart.startTime !== oldDaypart.startTime
        || newDaypart.endTime !== oldDaypart.endTime
        || newDaypart.hideHoursDisplay !== oldDaypart.hideHoursDisplay
      );
      return getChangedResources({
        newArray: daypartsToUpdate,
        oldArray: currentDayparts,
        comparisonKey: 'id',
        updatedComparisonFn
      });
    }
  }
};
