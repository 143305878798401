<template>
  <validation-observer ref="design-form" vid="design-form" tag="div" class="dist-y-lg">
    <panel
      title="Email"
      collapsible
      hide-hover-controls
      :start-open="emailNotificationEnabled"
      sticky-header-offset="-20px"
    >
      <b-message v-if="!emailNotificationEnabled" type="is-warning" class="is-compact has-shadow is-inline-block">
        Email Notification is not enabled for this campaign. Please go back to the configuration step if you'd like to enable it.
      </b-message>
      <email-templates-design-form
        v-else
        v-model="value"
        @delete-campaign-image="$emit('delete-campaign-image', $event)"
        @image-change="handleInput({ newEmailTemplatesImageFile: $event })"
        @override-image-change="handleInput({ newOverrideImageFile: $event })"
        @email-templates-change="handleInput({ emailTemplates: [{ ...value.emailTemplates[value.emailTemplates.length - 1], ...$event }] })"
      />
    </panel>

    <panel
      v-if="hasPushProvidersConfigured && hasMobileAppConfigured"
      title="Push Notification"
      collapsible
      hide-hover-controls
      :start-open="pushNotificationEnabled"
      sticky-header-offset="-20px"
    >
      <b-message v-if="!pushNotificationEnabled" type="is-warning" class="is-compact has-shadow is-inline-block">
        Push Notification is not enabled for this campaign. Please go back to the configuration step if you'd like to enable it.
      </b-message>
      <push-notification-design-form
        v-else
        v-model="value"
        :has-push-providers-configured="hasPushProvidersConfigured"
        :has-mobile-app-configured="hasMobileAppConfigured"
        @delete-campaign-image="$emit('delete-campaign-image', $event)"
        @image-change="handleInput({ newPushNotificationImageFile: $event })"
        @push-notification-change="handleInput({ pushNotification: { ...value.pushNotification, ...$event } })"
      />
    </panel>

    <panel
      v-if="hasSmsConfigured"
      title="Text Message"
      collapsible
      hide-hover-controls
      :start-open="smsEnabled"
      sticky-header-offset="-20px"
    >
      <b-message v-if="!smsEnabled" type="is-warning" class="is-compact has-shadow is-inline-block">
        Text Message is not enabled for this campaign. Please go back to the configuration step if you'd like to enable it.
      </b-message>
      <sms-design-form
        v-else
        v-model="value"
        :merchant-allows-sms="hasSmsConfigured"
        @delete-campaign-image="$emit('delete-campaign-image', $event)"
        @image-change="handleInput({ newSmsNotificationImageFile: $event })"
        @sms-notification-change="handleInput({ smsNotification: { ...value.smsNotification, ...$event } })"
      />
    </panel>
  </validation-observer>
</template>

<script>
  import PushNotificationDesignForm from './design-forms/push-notification-design-form.vue';
  import EmailTemplatesDesignForm from './design-forms/email-templates-design-form.vue';
  import SmsDesignForm from './design-forms/sms-design-form.vue';

  export default {
    name: 'CampaignDesign',

    components: { EmailTemplatesDesignForm, PushNotificationDesignForm, SmsDesignForm },

    props: {
      value: {
        type: Object,
        required: true
      },

      hasPushProvidersConfigured: {
        type: Boolean,
        default: false
      },

      hasMobileAppConfigured: {
        type: Boolean,
        default: false
      },

      hasSmsConfigured: {
        type: Boolean,
        default: false
      }
    },

    computed: {
      emailNotificationEnabled() {
        return !!this.value.emailTemplates.length;
      },

      pushNotificationEnabled() {
        return !!this.value.pushNotification;
      },

      smsEnabled() {
        return !!this.value.smsNotification;
      }
    },

    mounted() {
      this.$emit('design-step-mounted', this.$refs['design-form']);
    },

    methods: {
      handleInput(payload) {
        this.$emit('input', { ...this.value, ...payload });
      }
    }
  };
</script>
