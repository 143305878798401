<template>
  <div
    :id="`kds-order-${order.orderId}`"
    class="card"
    :class="{
      'due-in-30-minutes': orderIsActive && minutesUntilPickup <= 30,
      'due-in-15-minutes': orderIsActive && minutesUntilPickup < 15,
      'pulse-slow': hasRepeatNotification,
      'has-repeat-notification': hasRepeatNotification
    }"
  >
    <div
      v-tabbable
      class="card-header"
      role="button"
      @click="setSelectedOrderId(order.orderId)"
      @keypress.enter="setSelectedOrderId(order.orderId)"
    >
      <div class="card-header-title">
        <div class="icons is-flex-column">
          <img
            v-tippy="{ content: capitalCase(order.fulfillmentType), placement: 'right', delay: [300, 0] }"
            width="36"
            class="is-block"
            :src="`/icons/${paramCase(order.fulfillmentType)}.svg`"
            :alt="order.fulfillmentType"
          >
          <p
            v-if="order.customerOnSite"
            v-tippy="{ content: 'Checked In For Pickup', placement: 'right', delay: [300, 0] }"
            class="has-text-centered mar-t-sm"
          >
            <i class="fa-map-marker-check fal fa-2x" />
          </p>
          <b-icon v-else icon="" />
        </div>
        <div>
          <div>
            <span class="card-header-label">
              Fulfillment Time
            </span>
            <p class="is-size-4 has-text-weight-bold" style="line-height: 1.2">
              <span class="day">
                {{ order.pickupDate || order.orderDate | moment('ddd', order.store.timeZone) }}
              </span>
              {{ order.pickupDate || order.orderDate | moment('h:mm A', order.store.timeZone) }}
            </p>
            <p v-if="minutesUntilPickup <= 30" style="line-height: 1">({{ moment.duration(minutesUntilPickup, "minutes").humanize(true) }})</p>
          </div>
        </div>

        <div>
          <span class="card-header-label">{{ order.guestFullName }}</span>
          <template v-if="order.phoneNumber || order.email">
            <p v-if="order.phoneNumber">{{ order.phoneNumber | phoneNumber }}</p>
            <p v-if="order.email" class="text-ellipsis" style="max-width: 330px">{{ order.email }}</p>
          </template>
          <span v-else>No Contact Info</span>
        </div>

        <div v-if="!this.$_selectedMerchant.merchantOptionsCheckout.showOrderQrCode || !orderIsDineIn" class="is-flex justify-self-end dist-x-sm">
          <b-button
            v-if="['New', 'InPrep', 'Completed'].includes(order.preparationStatus) && orderDashboardColumns.find(column => column.preparationStatus === prepStatusButtons[order.preparationStatus].nextStatus)"
            icon-right="angle-right"
            icon-size="is-small"
            class="is-bold"
            :type="prepStatusButtons[order.preparationStatus].type"
            size="is-small"
            :loading="isLoadingPrepStatusChange === prepStatusButtons[order.preparationStatus].nextStatus"
            @click.stop="changePreparationStatus(prepStatusButtons[order.preparationStatus].nextStatus)"
          >
            {{ prepStatusButtons[order.preparationStatus].display }}
          </b-button>

          <b-dropdown
            ref="dropdown"
            class="has-extra-shadow align-self-center"
            :triggers="['click']"
            aria-role="list"
            position="is-bottom-left"
            :mobile-modal="false"
          >
            <template #trigger>
              <b-button
                v-if="!isLoadingPrepStatusChange"
                label=""
                type="is-grey"
                size="is-small"
                icon-right="ellipsis"
                @click.native.stop="$refs.dropdown.toggle"
              />
              <b-icon
                v-else-if="isLoadingPrepStatusChange"
                type="is-primary"
                pack="fad"
                icon="spinner-third"
                class="spin pad-md"
              />
            </template>

            <b-dropdown-item
              v-for="action in orderActions"
              :key="action.displayName"
              @click.native.stop="changePreparationStatus(action.preparationStatus)"
            >
              <p>
                <b-icon icon="caret-right" />
                {{ action.displayName }}
              </p>
            </b-dropdown-item>
          </b-dropdown>
        </div>
      </div>
    </div>
  </div>
</template>



<script>
  import Order from '@/store/classes/KdsOrder';
  import OrderDashboardColumn from '@/store/classes/OrderDashboardColumn';
  import moment from 'moment-timezone';
  import { paramCase } from 'change-case';
  import capitalCase from '@/helpers/capitalCase';
  import events from '@/services/events';
  import merchantMixin from '@/mixins/merchant';
  import fulfillmentTypes from '@/constants/fulfillmentTypes';


  export default {
    name: 'KdsOrder',

    mixins: [merchantMixin],

    props: {
      currentTimestamp: {
        type: String,
        required: true
      },

      order: {
        type: Object,
        required: true
      },

      hasRepeatNotification: {
        type: Boolean,
        default: false
      }
    },

    data() {
      return {
        moment,
        paramCase,
        capitalCase,
        isLoadingPrepStatusChange: null
      };
    },

    computed: {
      minutesUntilPickup() {
        const currentTime = moment(this.currentTimestamp);
        const pickupTime = moment(this.order.pickupDate || this.order.orderDate);

        return pickupTime.diff(currentTime, 'minutes');
      },

      orderIsActive() {
        return ['New', 'InPrep'].includes(this.order.preparationStatus);
      },

      orderIsDineIn() {
        return this.order.fulfillmentType === fulfillmentTypes.DINE_IN;
      },

      orderDashboardColumns() {
        return OrderDashboardColumn.query().whereIdIn(this.$_selectedMerchant?.orderDashboardColumns).orderBy('sortOrder').get();
      },

      orderActions() {
        const { preparationStatus } = this.order;
        return this.orderDashboardColumns.filter(column => column.preparationStatus !== preparationStatus);
      },

      prepStatusButtons() {
        return {
          New: {
            display: 'In Progress',
            type: 'is-primary',
            nextStatus: 'InPrep'
          },
          InPrep: {
            display: 'Completed',
            type: 'is-success',
            nextStatus: 'Completed'
          },
          Completed: {
            display: 'Picked Up',
            type: 'is-warning',
            nextStatus: 'PickedUp'
          }
        };
      }
    },

    methods: {
      async changePreparationStatus(preparationStatus) {
        try {
          this.isLoadingPrepStatusChange = preparationStatus;

          const updatedOrder = await Order.updateOrderPrepStatus(this.order.orderId, {
            storeId: this.order.store.storeId,
            preparationStatus
          });

          events.$emit('kds:order-updated', updatedOrder);
          // NOTE: this only really matters for 'New' orders being changed to 'InProgress'
          events.$emit('kds:clear-repeat-notification', this.order.orderId);
        }

        catch (error) {
          this.$_onRequestError({
            toastOptions: { message: 'Failed to update order' },
            error
          });
        }

        finally {
          this.isLoadingPrepStatusChange = null;
        }
      },

      setSelectedOrderId(id) {
        Order.setSelectedOrderId(id);
      }
    }
  };
</script>



<style lang="sass" scoped>
  .card
    position: relative
    border-color: $grey-lightest

    &.due-in-30-minutes
      .card-header
        background-color: lighten($warning, 28)
        &:hover,
        &:focus
          outline: none
          box-shadow: 0 0 0 0.125em rgba($warning, 0.25), inset -1px -1px $warning, inset 1px 1px $warning

        .card-header-label, .day
          color: darken($warning, 35)
      img
        filter: invert(25%) sepia(63%) saturate(220%) hue-rotate(6deg) brightness(90%) contrast(141%)

    &.due-in-15-minutes
      .card-header
        background-color: lighten($danger, 34)
        &:hover,
        &:focus
          outline: none
          box-shadow: 0 0 0 0.125em rgba($danger, 0.25), inset -1px -1px $danger, inset 1px 1px $danger

        .card-header-label, .day
          color: darken($danger, 25)
      img
        filter: invert(7%) sepia(93%) saturate(3459%) hue-rotate(337deg) brightness(40%) contrast(101%)

    .day
      opacity: 0.5

  .card-header
    cursor: pointer
    box-shadow: none
    position: relative

    &:hover,
    &:focus
      outline: none
      box-shadow: 0 0 0 0.125em rgba(0, 153, 255, 0.25), inset -1px -1px $primary, inset 1px 1px $primary

  .card-header-title
    display: grid
    gap: 1rem
    grid-template-columns: 2.5rem 200px 1fr 110px
    justify-items: start
    align-items: start
    font-weight: 400

    > :last-child
      align-self: center

    .card-header-label
      display: block
      color: $grey

  .modal-card
    max-width: $tablet
    margin: 0 auto !important

  .has-repeat-notification
    box-shadow: 0 0 0 0.125em rgba($danger, 0.25), inset -1px -1px $danger, inset 1px 1px $danger
</style>
