<template>
  <validated-form
    ref="form"
    auto-focus
    form-id="addEditReceiptLocation"
    @valid-submit="handleSubmit"
  >
    <modal-card :title="modalTitle">
      <validated-input label="Fulfillment Type" name="fulfillmentType" rules="required">
        <b-select
          v-model="form.fulfillmentTypeId"
          expanded
          placeholder="Select a Fulfillment Type..."
          required
        >
          <option :value="null">–</option>
          <option v-for="{ name, id } in filteredFulfillmentTypes" :key="id" :value="id">
            {{ formattedFulfillmentType(name) }}
          </option>
        </b-select>
      </validated-input>

      <validated-input label="Platform Type" name="platformType">
        <b-select
          v-model="form.platformTypeId"
          expanded
          placeholder="Select a Platform Type..."
          required
        >
          <option :value="null">–</option>
          <option v-for="{ name, id } in platformTypes" :key="id" :value="id">
            {{ capitalCase(name) }}
          </option>
        </b-select>
      </validated-input>

      <validated-input label="Order Type" name="orderType">
        <b-select
          v-model="form.orderTypeId"
          expanded
          placeholder="Select an Order Type..."
          required
        >
          <option :value="null">–</option>
          <option v-for="(name, id) in orderTypes" :key="id" :value="Number(id)">
            {{ name }}
          </option>
        </b-select>
      </validated-input>

      <validated-text-input
        v-model="form.receiptPrinterId"
        :has-icon="false"
        label="Receipt Printer Id"
        name="receiptPrinterId"
        type="text"
        rules="required"
      />

      <template #footer>
        <div class="buttons all-bold">
          <b-button
            rounded
            @click="$_confirmCloseModal({ programmatic: true })"
          >
            Cancel
          </b-button>
          <b-button
            v-tabbable
            rounded
            native-type="submit"
            type="is-primary"
            :loading="isSubmittingReceiptLocation"
          >
            Save
          </b-button>
        </div>
      </template>
    </modal-card>
  </validated-form>
</template>

<script>
  import StoreReceiptLocation from '@/store/classes/StoreReceiptLocation';
  import logger from '@/services/logger';
  import confirmModalCloseMixin from '@/mixins/confirm-modal-close';
  import merchantMixin from '@/mixins/merchant';
  import FulfillmentType from '@/store/classes/FulfillmentType';
  import PlatformType from '@/store/classes/PlatformType';
  import capitalCase from '@/helpers/capitalCase';

  export default {
    name: 'AddEditReceiptLocationModal',

    mixins: [confirmModalCloseMixin, merchantMixin],

    props: {
      storeId: {
        type: Number,
        required: true
      },

      receiptLocation: {
        type: Object,
        default: null
      },

      currentReceiptLocations: {
        type: Array,
        default: null
      },

      mode: {
        type: String,
        default: 'create',
        validator(value) {
          return ['create', 'read', 'update'].includes(value);
        }
      }
    },

    data() {
      return {
        capitalCase,
        form: null,
        orderTypes: {
          1: 'Order Ahead',
          2: 'Pay And Fulfill',
          3: 'Order And Pay',
          4: 'Third Party Order Ahead',
          5: 'Tip Only',
          6: 'External Order'
        }
      };
    },

    computed: {
      modalTitle() {
        let title = '';
        switch (this.mode) {
          case 'create':
            title = 'Add Receipt Location';
            break;

          case 'update':
            title = 'Edit Receipt Location';
            break;

          default:
            break;
        }

        return title;
      },

      isSubmittingReceiptLocation() {
        return StoreReceiptLocation.$state().submitting;
      },

      fulfillmentTypes() {
        return FulfillmentType.all();
      },

      filteredFulfillmentTypes() {
        if (this.mode === 'create') {
          return this.fulfillmentTypes.filter(ft => !this.currentReceiptLocations.find(crl => crl.fulfillmentTypeId === ft.id));
        }
        return this.fulfillmentTypes.filter(ft => ft.id === this.receiptLocation.fulfillmentTypeId || !this.currentReceiptLocations.find(crl => crl.fulfillmentTypeId === ft.id));
      },

      platformTypes() {
        return PlatformType.all();
      },

      storeReceiptLocation() {
        return this.mode === 'create'
          ? new StoreReceiptLocation()
          : this.receiptLocation;
      }
    },

    created() {
      this.onCreated();
    },

    methods: {
      onCreated() {
        const initialForm = JSON.parse(JSON.stringify(this.storeReceiptLocation));
        // Ensure all required fields are initialized
        this.form = {
          ...initialForm,
          fulfillmentTypeId: initialForm.fulfillmentTypeId || null,
          platformTypeId: initialForm.platformTypeId || null,
          orderTypeId: initialForm.orderTypeId || null,
          receiptPrinterId: initialForm.receiptPrinterId || null
        };
      },

      formattedFulfillmentType(name) {
        return FulfillmentType.formattedName(name);
      },

      async handleSubmit() {
        try {
          switch (this.mode) {
            case 'create':
              await this.addStoreReceiptLocation();
              break;

            case 'update':
              await this.updateStoreReceiptLocation();
              break;

            default:
              break;
          }
        }
        catch (error) {
          logger.error(error);
        }
      },

      async addStoreReceiptLocation() {
        try {
          await StoreReceiptLocation.addStoreReceiptLocation({ storeId: this.storeId, receiptLocation: this.form });

          this.$_onRequestSuccess({
            toastOptions: { message: 'Successfully added receipt location!' },
            options: { closeParent: true }
          });
        }

        catch (error) {
          this.$_onRequestError({
            toastOptions: { message: 'An error occured while adding your new receipt location' },
            error
          });
        }
      },

      async updateStoreReceiptLocation() {
        try {
          await StoreReceiptLocation.updateStoreReceiptLocation(this.form);

          this.$_onRequestSuccess({
            toastOptions: { message: 'Successfully updated receipt location!' },
            options: { closeParent: true }
          });
        }

        catch (error) {
          this.$_onRequestError({
            toastOptions: { message: 'An error occured while updating your receipt location' },
            error
          });
        }
      }
    }
  };
</script>
