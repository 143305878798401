<template>
  <div>
    <review-row
      v-for="(row, idx) in reviewRows"
      :key="`/review-row-${idx}`"
      :title="row.title"
      :subtitle="row.subtitle"
    />
    <item-group-review-row
      v-if="value.awardPolicy.itemGroup"
      :item-group="value.awardPolicy.itemGroup"
    />
    <review-row
      title="Run Limit"
      :subtitle="runLimitDisplay"
      :hide-bottom-border="!value.earnRuleConstraints.length"
    />
    <constraints-card
      v-if="value.earnRuleConstraints.length"
      :value="value"
    />
  </div>
</template>

<script>
  import { awardPolicyTypes, earningItemBases, runLimitPolicyTypes } from '@/constants/earnRules';

  export default {
    name: 'ActivityCard',

    props: {
      value: {
        type: Object,
        required: true
      },
      currency: {
        type: Object,
        required: true
      }
    },

    computed: {
      currencyName() {
        return this.currency?.name || '<Not set>';
      },

      currencyNamePlural() {
        return this.currency?.pluralName || '<Not set>';
      },

      earningBasisTypeDisplay() {
        return Object.values(earningItemBases).find(basis => basis.id === this.value.awardPolicy.earningItemBasisType)?.display;
      },

      isItemEarningType() {
        return this.value.awardPolicy?.awardPolicyType === awardPolicyTypes.SPECIFIED_ITEM_POINTS_PER_DOLLAR_SPENT.type
        || this.value.awardPolicy?.awardPolicyType === awardPolicyTypes.SPECIFIED_ITEM_POINTS_PER_COUNT.type;
      },

      isCountType() {
        return this.value.awardPolicy?.awardPolicyType === awardPolicyTypes.SPECIFIED_ITEM_POINTS_PER_COUNT.type
        || this.value.awardPolicy?.awardPolicyType === awardPolicyTypes.SALES_TRANSACTION_FIXED_POINT.type;
      },

      reviewRows() {
        const rows = [
          { title: 'Challenge Will Track', subtitle: this.trackingDisplay }
        ];

        if (this.isCountType) {
          rows.push({ title: 'Count Name', subtitle: this.currencyName });
          rows.push({ title: 'Count Name Plural', subtitle: this.currencyNamePlural });
        }

        if (this.isItemEarningType) {
          rows.push(
            { title: 'Earn Points For', subtitle: this.earningBasisTypeDisplay }
          );
        }

        return rows;
      },

      trackingDisplay() {
        switch (this.value.awardPolicy?.awardPolicyType) {
          case awardPolicyTypes.SPECIFIED_ITEM_POINTS_PER_DOLLAR_SPENT.type:
            return 'Dollars Spent';
          case awardPolicyTypes.SPECIFIED_ITEM_POINTS_PER_COUNT.type:
            return 'Item Count';
          case awardPolicyTypes.SALES_TRANSACTION_FIXED_POINT.type:
            return 'Order Count';
          case awardPolicyTypes.GIFT_CARD_POINTS_PER_DOLLAR_FUNDED.type:
            return 'Gift Card Funded';
          default:
            return '<Not set>';
        }
      },

      runLimitDisplay() {
        if (!this.value.earnRuleRunLimitPolicies?.length) {
          return 'Unlimited';
        }
        else {
          return this.value.earnRuleRunLimitPolicies.map(runLimit => (
            `${runLimit.maximumRuns} per ${this.getRunLimitTypeDisplay(runLimit)}`
          )).join(', ');
        }
      }
    },

    methods: {
      getRunLimitTypeDisplay(runLimit) {
        return Object.values(runLimitPolicyTypes).find(type => type.type === runLimit.runLimitPolicyType)?.display || 'Not Set';
      }
    }
  };
</script>
