<template>
  <section-wrapper
    title="Tender Types"
    :loading="isFetchingTenderTypes || isLoadingCardTypes"
  >
    <b-table
      :data="tenderTypes"
      class="is-middle-aligned flex-grow"
      :loading="isDeletingTenderType"
      :mobile-cards="false"
    >
      <template #empty>
        <p class="has-text-centered pad is-size-5 has-text-grey-light">
          No Tender Types Configured
        </p>
      </template>

      <b-table-column v-slot="{ row }" field="cardType" label="Card Type">
        {{ getDisplayName(row.cardType) }}
      </b-table-column>

      <b-table-column v-slot="{ row }" field="tenderType" label="Tender Type ID">
        {{ row.tenderTypeId }}
      </b-table-column>

      <b-table-column
        v-slot="{ row }"
        centered
        cell-class="actions"
        label="Actions"
        width="1"
      >
        <div class="is-flex dist-x-xs">
          <b-button class="is-transparent" type="is-white" @click="openTenderType({ mode: 'update', tender: row })">
            <b-icon icon="pencil" size="is-small" />
          </b-button>
          <b-button
            class="is-transparent"
            type="is-white"
            @click="openRemoveTenderTypeConfirmation(row)"
          >
            <b-icon icon="trash-alt" type="is-danger" size="is-small" />
          </b-button>
        </div>
      </b-table-column>
    </b-table>
    <div class="pad-x pad-y-sm has-border-top has-border-grey-lighter">
      <b-button
        :disabled="hasAllTenderTypes"
        class="is-transparent"
        icon-left="plus"
        type="is-primary"
        inverted
        @click="openTenderType"
      >
        Tender Type
      </b-button>
    </div>
  </section-wrapper>
</template>

<script>
  import StoreTenderType from '@/store/classes/StoreTenderType';
  import CardType from '@/store/classes/CardType';
  import addEditTenderTypeModal from './add-edit-tender-type-modal.vue';
  import merchantMixin from '@/mixins/merchant';

  export default {
    name: 'StoreTenderTypes',

    mixins: [merchantMixin],

    computed: {
      storeId() {
        return Number(this.$route.params.storeId);
      },

      allCardTypes() {
        return CardType.all();
      },

      tenderTypes() {
        return StoreTenderType.query().where('storeId', this.storeId).get();
      },

      hasAllTenderTypes() {
        return this.tenderTypes.length === this.allCardTypes.length;
      },

      isFetchingTenderTypes() {
        return StoreTenderType.$state().fetching;
      },

      isLoadingCardTypes() {
        return CardType.$state().fetching;
      },

      isDeletingTenderType() {
        return StoreTenderType.$state().deleting;
      }
    },

    methods: {
      async onMerchantLoad() {
        await Promise.all([this.fetchCardTypes(), this.fetchTenderTypes()]);
      },

      async fetchCardTypes() {
        try {
          await CardType.fetchCardTypes();
        }
        catch (error) {
          this.$_onRequestError({
            error,
            toastOptions: {
              message: 'There was an issue loading Card Types'
            }
          });
          this.$buefy.toast.open({
            message: '<i class="fa fa-exclamation mar-r-sm"></i> Unable to fetch card types',
            queue: false,
            type: 'is-danger'
          });
        }
      },

      openTenderType({ mode, tender }) {
        this.$buefy.modal.open({
          parent: this,
          component: addEditTenderTypeModal,
          hasModalCard: true,
          trapFocus: true,
          canCancel: false,
          customClass: 'auto-width',
          props: {
            storeId: this.storeId, currentTenderTypes: this.tenderTypes, tender, mode, cardTypes: this.allCardTypes
          }
        });
      },

      openRemoveTenderTypeConfirmation(tender) {
        this.$buefy.dialog.confirm({
          title: 'Delete Tender Type',
          message: `Are you sure you want to delete <b>${this.getDisplayName(tender.cardType)}</b>?`,
          onConfirm: () => this.deleteTenderType(tender),
          confirmText: 'Delete',
          type: 'is-danger',
          hasIcon: true,
          icon: 'trash-alt'
        });
      },

      getDisplayName(cardTypeName) {
        const cardType = this.allCardTypes.find(ct => ct.name === cardTypeName);
        return cardType?.displayName;
      },

      async fetchTenderTypes() {
        try {
          await StoreTenderType.fetchTenderTypes(this.storeId);
        }
        catch (error) {
          this.$_onRequestError({ error, toastOptions: { message: 'Unable to fetch tender types' } });
        }
      },

      async deleteTenderType(tender) {
        try {
          await StoreTenderType.deleteTenderType(tender.id);

          this.$_onRequestSuccess({
            toastOptions: { message: `<b>${tender.cardType}</b> has been successfully removed!` }
          });
        }
        catch (error) {
          this.$_onRequestError({ error, toastOptions: { message: `Unable to remove <b>${tender.cardType}</b>` } });
        }
      }
    }
  };
</script>

<style lang="sass" scoped>
  .actions
    ::v-deep .dropdown-content
      text-align: left !important
</style>
