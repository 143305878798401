import { render, staticRenderFns } from "./last-modified-table-cell.vue?vue&type=template&id=d1b71d28&scoped=true&"
import script from "./last-modified-table-cell.vue?vue&type=script&lang=js&"
export * from "./last-modified-table-cell.vue?vue&type=script&lang=js&"
import style0 from "./last-modified-table-cell.vue?vue&type=style&index=0&id=d1b71d28&lang=sass&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d1b71d28",
  null
  
)

export default component.exports