import { Model } from '@vuex-orm/core';
import http from '@/services/http';

export default class CardType extends Model {
  static entity = 'cardTypes'

  static fields() {
    return {
      id: this.attr(''),
      name: this.attr(''),
      displayName: this.attr(''),
      description: this.attr('')
    };
  }

  static defaultType() {
    return this.query().where(ct => ct.name === 'DEFAULT').first();
  }

  // STATE //////////////////////
  static state() {
    return {
      fetching: false
    };
  }

  static $state() {
    return this.store().state.entities.cardTypes;
  }

  static async fetchCardTypes() {
    if (!this.exists()) {
      try {
        this.commit((state) => {
          state.fetching = true;
        });

        const { data } = await http.get('card_types');
        this.insert({
          data: data.cardTypes
        });
      }
      catch (error) {
        throw error;
      }
      finally {
        this.commit((state) => {
          state.fetching = false;
        });
      }
    }
  }
}
