<template>
  <validated-form
    form-id="giftCardSettingsForm"
    @valid-submit="handleSubmit"
  >
    <panel title="Settings" collapsible start-open :loading="isFetching">
      <template #buttons>
        <b-button
          rounded
          class="is-bold"
          size="is-medium"
          native-type="submit"
          type="is-primary"
          :loading="isSubmitting"
        >
          Save
        </b-button>
      </template>
      <section class="dist-y-xl">
        <div class="is-grid col-2 gap">
          <validated-text-input
            :value="giftCardConfiguration.maximumCardAmount"
            name="maximumCardAmount"
            label="Max. Card Balance"
            type="dollars"
            :mask-options="{ numeralDecimalScale: 2, numeralPositiveOnly: true }"
            @input="handleInputChange({ maximumCardAmount: $event ? Number($event) : null })"
          />
          <validated-text-input
            v-model="giftCardConfiguration.maxAllowedSavedCards"
            name="maxAllowedSavedCards"
            label="Max. Saved Cards"
            type="number"
            tooltip="The maximum number of cards a user can have on their account"
          />
          <div>
            <validated-text-input
              v-model="giftCardConfiguration.scheduledSendDaysMax"
              name="scheduledSendDaysMax"
              label="Max. Days For Scheduled Delivery"
              tooltip="The max number of days in advance a gift card can be scheduled for delivery"
              type="number"
              rules="required"
            />
          </div>
          <div />
          <div
            v-tippy="{
              content: 'Your gift card provider does not support this feature ',
              onShow: () => !isAbleToAllowZeroDollarGiftCardReloads
            }"
          >
            <check-button
              v-model="giftCardConfiguration.allowZeroBalanceReloads"
              name="allowZeroBalanceReloads"
              label="Allow $0 Balance Reloads"
              sublabel="Cards may reach a balance of $0 and then be reloaded"
              :disabled="!isAbleToAllowZeroDollarGiftCardReloads"
              class="is-full-width"
            />
          </div>
          <check-button
            v-model="giftCardConfiguration.giftingEnabled"
            name="giftingEnabled"
            label="Gifting"
            sublabel="Allow any user to send a gift card to another user"
          />
          <check-button
            v-model="giftCardConfiguration.giftCardManagementEnabled"
            name="giftCardManagementEnabled"
            label="Gift Card Management"
            sublabel="Allow logged-in users to manage their cards (purchase cards, set up auto-reload, add funds to existing cards, etc.)"
          />
          <check-button
            v-if="$_selectedMerchant.kioskEnabled"
            v-model="giftCardConfiguration.kioskGiftCardTopOffEnabled"
            name="kioskGiftCardTopOffEnabled"
            label="Kiosk Top Off Gift Card"
            sublabel="Kiosk will reload the EMV payment onto the gift card (for Square payment)"
          />
        </div>

        <div>
          <h2 class="subtitle is-4 mar-b-lg">Balance Load Limits</h2>
          <div class="is-grid gap">
            <div>
              <h3 class="subtitle is-6 mar-b has-text-weight-bold">Default Balance Load Limits</h3>
              <validation-provider
                v-slot="{ errors }"
                rules="required"
                name="balanceLoadAmounts"
                :custom-messages="{ required: 'You must have 3 unique balance load amounts' }"
              >
                <b-field
                  :type="{ 'is-danger': !!errors.length }"
                  :message="errors | capitalize"
                >
                  <b-checkbox
                    :class="['is-hidden', { 'invalid': errors.length }]"
                    :value="isGiftCardAmountsUnique('merchantGiftCardAmounts') || null"
                  />
                  <div class="is-grid col-3 gap is-full-width">
                    <validated-text-input
                      v-for="(amount, index) in merchantGiftCardAmounts"
                      :key="amount.id"
                      :value="amount.amount"
                      class="control"
                      :label="`Option ${index + 1}`"
                      :name="`giftCardAmount${index + 1}`"
                      type="dollars"
                      label-position="on-border"
                      :placeholder="defaultMerchantGiftCardAmounts[index].amount"
                      :rules="{ min_value: 0.01, required: true }"
                      :mask-options="{ numeralDecimalScale: 2, numeralPositiveOnly: true }"
                      @input="handleGiftCardAmountChange({ type: 'merchantGiftCardAmount', index, value: $event })"
                    />
                  </div>
                </b-field>
              </validation-provider>
            </div>
            <div>
              <h3 class="subtitle is-6 mar-b has-text-weight-bold">Custom Balance Load Limits</h3>
              <div class="is-grid col-3 gap">
                <validated-text-input
                  :value="giftCardConfiguration.minimumReloadAmount"
                  name="minimumReloadAmount"
                  label="Minimum"
                  label-position="on-border"
                  type="dollars"
                  :rules="{
                    required: true,
                    min_value: 1.00
                  }"
                  @input="handleInputChange({ minimumReloadAmount: Number($event), autoReloadMinimumAmount: Number($event) })"
                />
                <validated-text-input
                  :value="giftCardConfiguration.maximumReloadAmount"
                  name="maximumReloadAmount"
                  label="Maximum"
                  label-position="on-border"
                  type="dollars"
                  :rules="{
                    required: true,
                    min_value: giftCardConfiguration.minimumReloadAmount,
                    max_value: giftCardConfiguration.maximumCardAmount ? giftCardConfiguration.maximumCardAmount : Infinity
                  }"
                  :custom-messages="{
                    min_value: 'Max limit must be greater than min limit',
                    max_value: 'Max limit must be less than the Max Card Balance'
                  }"
                  @input="handleInputChange({ maximumReloadAmount: Number($event), autoReloadMaximumAmount: Number($event) })"
                />
              </div>
            </div>
          </div>
        </div>

        <div
          :class="[
            'is-inline-block pad has-background-white-bis has-border has-border-grey-lightest has-radius',
            { 'is-full-width': giftCardConfiguration.autoReloadEnabled }
          ]"
        >
          <div class="is-flex-align-center mar-b-lg">
            <b-switch
              v-model="giftCardConfiguration.autoReloadEnabled"
              type="is-success"
              class="label"
            >
              Auto Reload
            </b-switch>
            <b-icon
              v-tippy="{
                content: 'Guests can opt to reload their card when their balance is below a certain amount',
                placement: 'right',
                maxWidth: 300,
                delay: [150, 0]
              }"
              icon="info-square"
              size="is-small"
              type="is-grey"
            />
          </div>
          <div v-if="giftCardConfiguration.autoReloadEnabled" class="mar-t-lg is-grid gap">
            <div>
              <h3 class="subtitle is-6 mar-b has-text-weight-bold">Reload When Balance is Below Threshold of...</h3>
              <validation-provider
                v-slot="{ errors }"
                rules="required"
                name="thresholdAmounts"
                :custom-messages="{ required: 'You must have 3 unique balance load limits' }"
              >
                <b-field
                  :type="{ 'is-danger': !!errors.length }"
                  :message="errors | capitalize"
                >
                  <b-checkbox
                    :class="['is-hidden', { 'invalid': errors.length }]"
                    :value="isGiftCardAmountsUnique('merchantGiftCardThresholdAmounts') || null"
                  />
                  <div class="is-grid col-3 gap is-full-width">
                    <validated-text-input
                      v-for="(amount, index) in merchantGiftCardThresholdAmounts"
                      :key="amount.id"
                      :value="amount.amount"
                      class="control"
                      :label="`Option ${index + 1}`"
                      :name="`thresholdAmount${index + 1}`"
                      type="dollars"
                      label-position="on-border"
                      :placeholder="defaultMerchantGiftCardThresholdAmounts[index].amount"
                      :rules="{ min_value: 0.01, required: true }"
                      :mask-options="{ numeralDecimalScale: 2, numeralPositiveOnly: true }"
                      @input="handleGiftCardAmountChange({ type: 'merchantGiftCardThresholdAmount', index, value: $event })"
                    />
                  </div>
                </b-field>
              </validation-provider>
            </div>
            <div>
              <h3 class="subtitle is-6 mar-b has-text-weight-bold">Custom Balance Threshold Limits</h3>
              <div class="is-grid col-3 gap">
                <validated-text-input
                  :value="giftCardConfiguration.minimumThresholdAmount"
                  name="minimumThresholdAmount"
                  label="Minimum"
                  label-position="on-border"
                  type="dollars"
                  :rules="{
                    required: giftCardConfiguration.autoReloadEnabled,
                    min_value: 1.00
                  }"
                  :disabled="!giftCardConfiguration.autoReloadEnabled"
                  @input="handleInputChange({ minimumThresholdAmount: Number($event) })"
                />
                <validated-text-input
                  :value="giftCardConfiguration.maximumThresholdAmount"
                  name="maximumThresholdAmount"
                  label="Maximum"
                  label-position="on-border"
                  type="dollars"
                  :rules="{
                    required: giftCardConfiguration.autoReloadEnabled,
                    min_value: giftCardConfiguration.minimumThresholdAmount,
                    max_value: giftCardConfiguration.maximumCardAmount ? giftCardConfiguration.maximumCardAmount : Infinity
                  }"
                  :disabled="!giftCardConfiguration.autoReloadEnabled"
                  :custom-messages="{
                    min_value: 'Max threshold limit must be greater than min threshold limit',
                    max_value: 'Threshold limit must be less than the Max Card Balance'
                  }"
                  @input="handleInputChange({ maximumThresholdAmount: Number($event) })"
                />
              </div>
            </div>
          </div>
        </div>

        <div>
          <h2 class="subtitle is-4 mar-b-sm">Gift Card Merge</h2>
          <div class="is-grid col-2 gap">
            <check-button
              v-model="giftCardConfiguration.allowBalanceMerge"
              name="allowBalanceMerge"
              label="Allow Gift Card Merge"
              sublabel="Allow users to merge the balance from one gift card to another"
            />
          </div>
          <div v-if="giftCardConfiguration.allowBalanceMerge" class="is-grid col-2 gap mar-t-md">
            <check-button
              v-model="giftCardConfiguration.requireCredentialsOnMergeGiftCards"
              name="requireCredentialsOnMergeGiftCards"
              label="Require Credentials To Merge"
              sublabel="Require users to re-enter their password to merge card balances"
            />
            <check-button
              v-model="giftCardConfiguration.removeCardAfterMerge"
              name="removeCardAfterMerge"
              label="Remove Card After Merging"
              sublabel="Automatically remove a card after its balance has been merged to a different card"
            />
          </div>
        </div>

        <div>
          <h2 class="subtitle is-4 mar-b-sm">Wallet Pass Logo (Optional)</h2>
          <image-upload
            v-slot="{imagePath}"
            v-model="localWalletPassLogo"
            :accepted-types="['png']"
            :image-size-warning-height="150"
            :image-size-warning-width="150"
            :image-url="$_selectedMerchant.merchantOption && $_selectedMerchant.merchantOption.walletPassLogoUrl"
            :tooltip="{ content: 'Logo updates only apply to newly purchased gift cards', placement: 'top', maxWidth: 300 }"
            label="Wallet Pass Logo"
            show-clear-button
            show-delete-button
            restrict-file-size
            :loading="isSubmittingOrDeletingMerchantOption"
            @delete-image="deleteWalletPassLogo"
          >
            <div>
              <img
                v-if="imagePath"
                style="max-width: 150px"
                :src="imagePath"
                alt="wallet pass logo"
              >
            </div>
          </image-upload>
        </div>
      </section>
    </panel>
  </validated-form>
</template>

<script>
  import paymentMethods from '@/constants/paymentMethods';

  import MerchantGiftCardConfiguration from '@/store/classes/MerchantGiftCardConfiguration';
  import MerchantGiftCardAmount from '@/store/classes/MerchantGiftCardAmount';
  import MerchantGiftCardThresholdAmount from '@/store/classes/MerchantGiftCardThresholdAmount';
  import PaymentGateway from '@/store/classes/PaymentGateway';
  import MerchantOption from '@/store/classes/MerchantOption';
  import merchantMixin from '@/mixins/merchant';

  export default {
    name: 'GiftCardSetingsForm',

    mixins: [merchantMixin],

    props: {
      merchant: {
        type: Object,
        default: null
      }
    },

    data() {
      return {
        giftCardConfiguration: {},
        merchantGiftCardAmounts: [],
        merchantGiftCardThresholdAmounts: [],
        localWalletPassLogo: null
      };
    },

    computed: {
      isFetching() {
        return MerchantGiftCardConfiguration.$state().fetching
          || PaymentGateway.$state().fetching
          || MerchantGiftCardAmount.$state().fetching
          || MerchantGiftCardThresholdAmount.$state().fetching;
      },

      isSubmitting() {
        return MerchantGiftCardConfiguration.$state().submitting
          || MerchantGiftCardAmount.$state().submitting
          || MerchantGiftCardThresholdAmount.$state().submitting;
      },

      isSubmittingOrDeletingMerchantOption() {
        return MerchantOption.$state().submitting || MerchantOption.$state().deleting;
      },

      allPaymentGatewayOptions() {
        return PaymentGateway.all();
      },

      giftCardPaymentGateway() {
        const { merchantPaymentGateways } = this.merchant;
        const doesGatewaySupportGiftCards = gateway => gateway.paymentMethods?.some(
          paymentMethod => paymentMethod.name === paymentMethods.GIFTCARD.type
        );
        return merchantPaymentGateways?.find(doesGatewaySupportGiftCards)?.paymentGateway;
      },

      isAbleToAllowZeroDollarGiftCardReloads() {
        if (!this.giftCardPaymentGateway) {
          return false;
        }

        const paymentGatewayToCheck = this.allPaymentGatewayOptions.find(pg => pg.id === this.giftCardPaymentGateway.id)?.metaData;

        return paymentGatewayToCheck?.supportsZeroDollarGiftCardReloads;
      },

      defaultMerchantGiftCardConfig() {
        return MerchantGiftCardConfiguration.query().where('merchantId', 0).first();
      },

      merchantGiftCardConfig() {
        return MerchantGiftCardConfiguration.query().where('merchantId', this.merchant.id).first();
      },

      defaultMerchantGiftCardAmounts() {
        if (!this.defaultMerchantGiftCardConfig) return [];
        return MerchantGiftCardAmount
          .query()
          .where('merchantGiftCardConfigurationId', this.defaultMerchantGiftCardConfig.id)
          .get();
      },

      defaultMerchantGiftCardThresholdAmounts() {
        if (!this.defaultMerchantGiftCardConfig) return [];
        return MerchantGiftCardThresholdAmount
          .query()
          .where('merchantGiftCardConfigurationId', this.defaultMerchantGiftCardConfig.id)
          .get();
      },

      giftCardAmounts() {
        if (!this.merchantGiftCardConfig) return [];
        const merchantLevelAmounts = MerchantGiftCardAmount
          .query()
          .where('merchantGiftCardConfigurationId', this.merchantGiftCardConfig.id)
          .get();
        return merchantLevelAmounts.length ? merchantLevelAmounts
          : this.$clone(this.defaultMerchantGiftCardAmounts).map(a => new MerchantGiftCardAmount({ ...a, merchantGiftCardConfigurationId: this.merchantGiftCardConfig.id }));
      },

      giftCardThresholdAmounts() {
        if (!this.merchantGiftCardConfig) return [];
        const merchantLevelAmounts = MerchantGiftCardThresholdAmount
          .query()
          .where('merchantGiftCardConfigurationId', this.merchantGiftCardConfig.id)
          .get();
        return merchantLevelAmounts.length ? merchantLevelAmounts
          : this.$clone(this.defaultMerchantGiftCardThresholdAmounts).map(a => new MerchantGiftCardThresholdAmount({ ...a, merchantGiftCardConfigurationId: this.merchantGiftCardConfig.id }));
      }
    },

    created() {
      this.onCreated();
    },

    methods: {
      async onCreated() {
        await Promise.all([
          this.getOrCreateMerchantGiftCardConfiguration(),
          this.fetchPaymentGateways()
        ]);
        this.giftCardConfiguration = { ...this.merchantGiftCardConfig };

        await Promise.all([
          MerchantGiftCardAmount.fetchMerchantGiftCardAmounts(this.giftCardConfiguration.id),
          MerchantGiftCardThresholdAmount.fetchMerchantGiftCardThresholdAmounts(this.giftCardConfiguration.id)
        ]);

        // AutoReload amounts should match min/max reload amounts
        this.handleInputChange({
          autoReloadMinimumAmount: this.giftCardConfiguration.minimumReloadAmount,
          autoReloadMaximumAmount: this.giftCardConfiguration.maximumReloadAmount
        });

        this.merchantGiftCardAmounts = this.$clone(this.giftCardAmounts);
        this.merchantGiftCardThresholdAmounts = this.$clone(this.giftCardThresholdAmounts);
      },

      async getOrCreateMerchantGiftCardConfiguration() {
        try {
          await MerchantGiftCardConfiguration.fetchMerchantGiftCardConfigurations(this.merchant.id);
          if (!this.merchantGiftCardConfig) {
            await MerchantGiftCardConfiguration.createMerchantGiftCardConfiguration({
              ...this.defaultMerchantGiftCardConfig,
              merchantId: this.merchant.id
            });
          }
        }
        catch (error) {
          this.$_onRequestError({
            error,
            toastOptions: {
              message: 'There was an error getting your gift card configuration'
            }
          });
        }
      },

      async fetchPaymentGateways() {
        try {
          await PaymentGateway.fetchPaymentGateways();
        }
        catch (error) {
          this.$_onRequestError({
            toastOptions: { message: 'Unable to fetch payment providers' },
            error
          });
        }
      },

      async handleSubmit() {
        try {
          await Promise.all([
            MerchantGiftCardConfiguration.updateMerchantGiftCardConfiguration(this.giftCardConfiguration),
            MerchantGiftCardAmount.resetMerchantGiftCardAmounts({
              merchantGiftCardConfigurationId: this.giftCardConfiguration.id,
              merchantGiftCardAmounts: this.merchantGiftCardAmounts
            }),
            MerchantGiftCardThresholdAmount.resetMerchantGiftCardThresholdAmounts({
              merchantGiftCardConfigurationId: this.giftCardConfiguration.id,
              merchantGiftCardThresholdAmounts: this.merchantGiftCardThresholdAmounts
            })
          ]);

          if (this.localWalletPassLogo) {
            await MerchantOption.updateWalletPassLogo(this.$_selectedMerchant.merchantOption.id, this.localWalletPassLogo);
          }

          this.$_onRequestSuccess({
            toastOptions: { message: 'Your Gift Card configuration has been successfully updated!' }
          });
        }
        catch (error) {
          this.$_onRequestError({
            toastOptions: { message: 'An error occurred when trying to update your Gift Card configuration' },
            error
          });
        }
      },

      handleGiftCardAmountChange({ type, index, value }) {
        if (type === 'merchantGiftCardAmount') {
          this.merchantGiftCardAmounts[index].amount = value;
        }
        else if (type === 'merchantGiftCardThresholdAmount') {
          this.merchantGiftCardThresholdAmounts[index].amount = value;
        }
      },

      handleInputChange(payload) {
        this.giftCardConfiguration = { ...this.giftCardConfiguration, ...payload };
      },

      isGiftCardAmountsUnique(type) {
        const amounts = this[type].map(a => Number(a.amount));
        return amounts.length === new Set(amounts).size;
      },

      async deleteWalletPassLogo() {
        try {
          await MerchantOption.deleteWalletPassLogo(this.$_selectedMerchant.merchantOption.id);

          this.$_onRequestSuccess({
            toastOptions: {
              message: 'Successfully deleted wallet pass logo!'
            }
          });
        }

        catch (error) {
          this.$_onRequestError({
            toastOptions: {
              message: 'An error occurred while deleting your wallet pass logo image'
            },
            error
          });
        }
      },

      handleInput(key, value) {
        this.$emit('input', { ...this.value, [key]: value });
      }
    }
  };
</script>
