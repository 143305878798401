<template>
  <div class="order-management detail-page">
    <b-loading v-if="isLoading" active :is-full-page="false" />
    <tabs-page
      v-if="$_selectedMerchantId && tabs.length"
      :show-single-tab="false"
      :tabs="tabs"
      title="Order Management"
      :keep-alive="false"
      is-full-width
    />
  </div>
</template>

<script>
  import Store from '@/store/classes/Store';
  import MerchantLoyaltyProviderConfiguration from '@/store/classes/MerchantLoyaltyProviderConfiguration';
  import merchantMixin from '@/mixins/merchant';
  import FulfillmentType from '@/store/classes/FulfillmentType';
  import MerchantMenuTypeConfiguration from '@/store/classes/MerchantMenuTypeConfiguration';
  import orderTypes from '@/constants/orderTypes';

  // tab components
  import orderList from './order-list.vue';



  export default {
    name: 'OrderManagement',

    mixins: [merchantMixin],

    computed: {
      tabs() {
        return [
          {
            display: 'Order Ahead',
            name: 'order-ahead',
            component: orderList,
            props: { orderType: orderTypes.ALL_ORDER_AHEAD },
            isHidden: !this.$_selectedMerchant.orderAheadEnabled
          },
          {
            display: this.atTableNames.display,
            name: this.atTableNames.name,
            component: orderList,
            props: { orderType: this.atTableOrderType },
            isHidden: !this.$_selectedMerchant.patEnabled
          },
          {
            display: 'In Store',
            name: 'in-store',
            component: orderList,
            props: { orderType: orderTypes.EXTERNAL_ORDER },
            isHidden: !this.$_selectedMerchant.externalOrderEnabled
          }
        ];
      },

      isLoading() {
        return Store.$state().fetchingAll || MerchantLoyaltyProviderConfiguration.$state().fetching;
      },

      atTableNames() {
        const { oatEnabled } = this.$_selectedMerchant;
        const display = oatEnabled ? 'Pay & Order At Table' : 'Pay At Table';
        const name = oatEnabled ? 'pay-and-order-at-table' : 'pay-at-table';
        return {
          display,
          name
        };
      },

      atTableOrderType() {
        return this.$_selectedMerchant.oatEnabled
          ? orderTypes.ORDER_AND_PAY_AT_TABLE
          : orderTypes.PAY_AND_FULFILL;
      }
    },

    methods: {
      async onMerchantLoad() {
        await Promise.all([
          this.fetchStores(),
          this.fetchMerchantLoyaltyProviderConfigurations(),
          this.fetchFulfillmentTypes(),
          this.fetchMerchantMenuTypes()
        ]);
      },

      async fetchFulfillmentTypes() {
        try {
          await FulfillmentType.fetchFulfillmentTypes();
        }
        catch (error) {
          this.$_onRequestError({
            error,
            toastOptions: {
              message: 'There was an error fetching fulfillment types'
            }
          });
        }
      },

      async fetchMerchantMenuTypes() {
        try {
          await MerchantMenuTypeConfiguration.fetchMerchantMenuTypeConfigurations();
        }
        catch (error) {
          this.$_onRequestError({
            error,
            toastOptions: {
              message: 'There was an error fetching supported menu types'
            }
          });
        }
      },

      async fetchStores() {
        try {
          await Store.fetchAll();
        }
        catch (error) {
          this.$_onRequestError({
            error,
            toastOptions: { message: 'Unable to fetch stores' }
          });
        }
      },

      async fetchMerchantLoyaltyProviderConfigurations() {
        try {
          await MerchantLoyaltyProviderConfiguration.fetchMerchantLoyaltyProviderConfigurations(this.$_selectedMerchantId);
        }
        catch (error) {
          this.$_onRequestError({
            error,
            toastOptions: { message: 'Unable to fetch merchant loyalty provider configurations' }
          });
        }
      }
    }
  };
</script>

<style lang="sass" scoped>
  .order-management
    ::v-deep.container
      width: 100%
</style>
