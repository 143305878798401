<template>
  <section-wrapper title="Payment Providers">
    <b-table
      :data="storePaymentGateways"
      class="is-middle-aligned flex-grow"
      hoverable
      :mobile-cards="false"
      :loading="isFetchingMerchantPaymentGateways"
    >
      <template #empty>
        <p class="has-text-centered pad is-size-5 has-text-grey-light">
          No Payment Providers Configured
        </p>
      </template>

      <b-table-column v-slot="{ row }" sortable field="fullName" label="Name">
        {{ row.paymentGateway.name }}
      </b-table-column>

      <b-table-column
        v-slot="{ row }"
        centered
        sortable
        field="isActive"
        label="Active"
      >
        <b-icon
          :icon="row.isActive ? 'check' : 'times'"
          :type="row.isActive ? 'is-success' : 'is-danger'"
          size="is-small"
        />
      </b-table-column>

      <b-table-column
        v-slot="{ row }"
        centered
        cell-class="actions"
        label="Actions"
        width="1"
      >
        <div class="is-flex dist-x-xs">
          <b-button class="is-transparent" type="is-white" @click="openPaymentGatewayModal(row)">
            <b-icon icon="pencil" size="is-small" />
          </b-button>
          <b-button
            class="is-transparent"
            type="is-white"
            @click="openRemovePaymentGatewayModal(row)"
          >
            <b-icon icon="trash-alt" type="is-danger" size="is-small" />
          </b-button>
        </div>
      </b-table-column>
    </b-table>
    <div class="pad-x pad-y-sm has-border-top has-border-grey-lighter">
      <b-button
        inverted
        type="is-primary"
        :disabled="allPaymentGatewayOptions.length === storePaymentGateways.length"
        class="is-transparent"
        icon-left="plus"
        @click="openPaymentGatewayModal(null)"
      >
        New Payment Provider
      </b-button>
    </div>
  </section-wrapper>
</template>



<script>
  import MerchantPaymentGateway from '@/store/classes/MerchantPaymentGateway';
  import merchantMixin from '@/mixins/merchant';
  import addEditPaymentGatewayModal from '@/components/pages/merchants/tabs/merchant-configs-tab-content/add-edit-payment-gateway-modal.vue';
  import PaymentGateway from '@/store/classes/PaymentGateway';



  export default {
    name: 'StorePaymentGateways',

    mixins: [merchantMixin],

    props: {
      store: {
        type: Object,
        required: true
      }
    },

    computed: {
      isFetchingMerchantPaymentGateways() {
        return MerchantPaymentGateway.$state().fetching;
      },

      allPaymentGatewayOptions() {
        return PaymentGateway.all();
      },

      storePaymentGateways() {
        return MerchantPaymentGateway.query().where('storeId', this.store.storeId).get();
      }
    },

    methods: {
      openPaymentGatewayModal(currentPaymentGateway) {
        this.$buefy.modal.open({
          parent: this,
          component: addEditPaymentGatewayModal,
          hasModalCard: true,
          trapFocus: true,
          canCancel: false,
          customClass: 'full-width',
          props: {
            merchantPaymentGatewayIds: this.storePaymentGateways.map(spg => spg.paymentGateway.id),
            merchant: this.$_selectedMerchant,
            storeId: this.store.storeId,
            currentPaymentGateway
          }
        });
      },

      openRemovePaymentGatewayModal(selectedPaymentGateway) {
        this.$buefy.dialog.confirm({
          title: 'Delete Payment Provider',
          message: `Deleting <b>${selectedPaymentGateway.paymentGateway.name}</b> will delete its configuration at the location level. This will impact your online ordering application. Are you sure?`,
          type: 'is-danger',
          confirmText: 'Delete',
          onConfirm: () => {
            this.removePaymentGateway(selectedPaymentGateway);
          }
        });
      },

      async removePaymentGateway(selectedPaymentGateway) {
        try {
          await MerchantPaymentGateway.removePaymentGateway(selectedPaymentGateway.id);
          this.$_onRequestSuccess({
            toastOptions: {
              message: 'Successfully removed your payment gateway!'
            }
          });
        }
        catch (error) {
          this.$_onRequestError({
            error,
            toastOptions: {
              message: 'There was an error removing your payment gateway'
            }
          });
        }
      }
    }
  };
</script>
