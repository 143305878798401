<template>
  <validated-form
    ref="form"
    auto-focus
    form-id="addEditTenderTypeForm"
    @valid-submit="handleSubmit"
  >
    <modal-card :title="modalTitle">
      <validated-input label="Card Type" name="cardTypes" rules="required">
        <b-select
          v-model="form.cardType"
          expanded
          placeholder="Select a Card Type..."
          :disabled="mode === 'update' && form.cardType === defaultCardType.name"
          required
        >
          <option :value="null">–</option>
          <option v-for="cardType in cardTypeOptions" :key="cardType.id" :value="cardType.name">
            {{ cardType.displayName }}
          </option>
        </b-select>
      </validated-input>
      <validated-text-input
        v-model="form.tenderTypeId"
        :has-icon="false"
        label="Tender Type Id"
        name="tenderTypeId"
        type="text"
        rules="required"
      />

      <b-checkbox
        v-model="applyToAll"
        class="mar-t-xs mar-b-sm"
        name="applyToAll"
      >
        Apply to all locations
      </b-checkbox>

      <template #footer>
        <div class="buttons all-bold">
          <b-button
            rounded
            @click="$_confirmCloseModal({ programmatic: true })"
          >
            Cancel
          </b-button>
          <b-button
            v-tabbable
            rounded
            native-type="submit"
            type="is-primary"
            :loading="isSubmittingTenderTypes"
          >
            Save
          </b-button>
        </div>
      </template>
    </modal-card>
  </validated-form>
</template>

<script>
  import StoreTenderType from '@/store/classes/StoreTenderType';
  import CardType from '@/store/classes/CardType';
  import logger from '@/services/logger';
  import confirmModalCloseMixin from '@/mixins/confirm-modal-close';
  import merchantMixin from '@/mixins/merchant';

  export default {
    name: 'AddEditTenderTypeModal',

    mixins: [confirmModalCloseMixin, merchantMixin],

    props: {
      storeId: {
        type: Number,
        required: true
      },

      tender: {
        type: Object,
        default: null
      },

      currentTenderTypes: {
        type: Array,
        default: null
      },

      cardTypes: {
        type: Array,
        default: null
      },

      mode: {
        type: String,
        default: 'create',
        validator(value) {
          return ['create', 'read', 'update'].includes(value);
        }
      }
    },

    data() {
      return {
        form: null,
        applyToAll: false
      };
    },

    computed: {
      showAllCardTypes() {
        return !!this.currentTenderTypes.find(tt => tt.cardType === this.defaultCardType.name);
      },

      defaultCardType() {
        return CardType.defaultType();
      },

      cardTypeOptions() {
        const isMissingDefault = !this.currentTenderTypes.find(tt => tt.cardType === this.defaultCardType.name);
        const isDefaultSelected = this.storeTender.cardType === this.defaultCardType.name;
        if (isMissingDefault || isDefaultSelected) {
          return [this.defaultCardType];
        }
        return this.cardTypes
          .filter(cardType => cardType.name !== this.defaultCardType.name)
          // filter out cardTypes already selected except for current selection
          .filter(cardType => !this.currentTenderTypes.find(ctt => ctt.cardType === cardType.name && this.storeTender.cardType !== ctt.cardType));
      },

      modalTitle() {
        let title = '';
        switch (this.mode) {
          case 'create':
            title = 'Add Tender Type';
            break;

          case 'update':
            title = 'Edit Tender Type';
            break;

          default:
            break;
        }

        return title;
      },


      isSubmittingTenderTypes() {
        return StoreTenderType.$state().submitting;
      },

      storeTender() {
        return this.mode === 'create'
          ? new StoreTenderType()
          : this.tender;
      }
    },

    created() {
      this.onCreated();
    },

    methods: {
      onCreated() {
        this.form = JSON.parse(JSON.stringify(this.storeTender));
      },

      async handleSubmit() {
        try {
          switch (this.mode) {
            case 'create':
              await this.addTenderType();
              break;

            case 'update':
              await this.updateTenderType();
              break;

            default:
              break;
          }
        }
        catch (error) {
          logger.error(error);
        }
      },

      async addTenderType() {
        try {
          const tender = await StoreTenderType.addTenderType({ storeId: this.storeId, tender: this.form });

          if (this.applyToAll) {
            await this.applyTenderTypeToAllStores(tender.id);
          }

          this.$_onRequestSuccess({
            toastOptions: { message: 'Successfully added tender type!' },
            options: { closeParent: true }
          });
        }

        catch (error) {
          this.$_onRequestError({
            toastOptions: { message: 'An error occured while adding your new tender type' },
            error
          });
        }
      },

      async updateTenderType() {
        try {
          await StoreTenderType.updateTenderType(this.form);

          if (this.applyToAll) {
            await this.applyTenderTypeToAllStores(this.form.id);
          }

          this.$_onRequestSuccess({
            toastOptions: { message: 'Successfully updated tender type!' },
            options: { closeParent: true }
          });
        }

        catch (error) {
          this.$_onRequestError({
            toastOptions: { message: 'An error occured while updating your tender type' },
            error
          });
        }
      },

      async applyTenderTypeToAllStores(tenderId) {
        try {
          await StoreTenderType.applyTenderTypeToAllStores(tenderId);
        }

        catch (error) {
          this.$_onRequestError({
            toastOptions: { message: 'An error occured while applying your tender type to all stores' },
            error
          });
        }
      }
    }
  };
</script>
