<template>
  <steps-modal
    v-model="form"
    title="Earn Rule Builder"
    :steps="steps"
    :read-only="readOnly"
    :message="modalMessage"
    :message-type="earnRule.portalDerivedStatus === earnRuleStatuses.EXPIRED ? 'is-danger' : 'is-primary'"
    confirm-close
    @valid-submit="confirmSaveEarnRule"
    @close="$parent.close()"
  >
    <template #actions>
      <b-button
        v-if="canExpireEarnRule"
        type="is-danger"
        icon-right="calendar-times"
        rounded
        data-test-id="expire-button"
        @click="expireEarnRule"
      >
        Expire
      </b-button>
      <b-button
        v-if="canDeleteEarnRule"
        type="is-danger"
        icon-right="trash-alt"
        rounded
        data-test-id="delete-button"
        @click="deleteEarnRule"
      >
        Remove
      </b-button>
    </template>
    <template #submit-button>
      <b-button
        type="is-primary"
        icon-right="check"
        rounded
        native-type="submit"
        :disabled="readOnly"
        data-test-id="save-button"
      >
        Save
      </b-button>
    </template>
  </steps-modal>
</template>

<script>
  import moment from 'moment-timezone';

  import { awardPolicyTypes, constraintModels, earnRuleStatuses } from '@/constants/earnRules';

  import AlertModal from '@/components/globals/alert-modal.vue';

  import merchantMixin from '@/mixins/merchant';

  import EarnRule from '@/store/classes/EarnRule';
  import ItemGroup from '@/helpers/classes/ItemGroup';

  import DetailsStep from './modal-steps/details-step.vue';
  import DefinitionStep from './modal-steps/definition-step.vue';
  import TimeFrameStep from './modal-steps/time-frame-step.vue';
  import ReviewStep from './modal-steps/review-step.vue';

  export default {
    name: 'EarnRuleModal',

    mixins: [merchantMixin],

    props: {
      earnRule: {
        type: Object,
        required: true
      },
      loyaltyProgramPublicId: {
        type: String,
        required: true
      }
    },

    data() {
      return {
        earnRuleStatuses,
        form: {},
        awardPolicyItemGroupTypes: [
          awardPolicyTypes.SPECIFIED_ITEM_POINTS_PER_DOLLAR_SPENT.type,
          awardPolicyTypes.SPECIFIED_ITEM_POINTS_PER_COUNT.type
        ]
      };
    },

    computed: {
      readOnly() {
        return this.earnRule.portalDerivedStatus === earnRuleStatuses.EXPIRED || !this.earnRule.previousVersions;
      },

      canExpireEarnRule() {
        return this.earnRule.portalDerivedStatus === earnRuleStatuses.ACTIVE || this.earnRule.portalDerivedStatus === earnRuleStatuses.FUTURE;
      },

      canDeleteEarnRule() {
        return this.earnRule.portalDerivedStatus === earnRuleStatuses.SCHEDULED;
      },

      earnRuleFullyEditable() {
        return !this.earnRule.portalDerivedStatus || this.earnRule.portalDerivedStatus === earnRuleStatuses.SCHEDULED;
      },

      modalMessage() {
        if (this.earnRule.portalDerivedStatus === earnRuleStatuses.ACTIVE) {
          return 'Editing this earn rule will create a future version of it with the updates. The future version will be editable until it becomes active. The current version will no longer be editable and will remain active until the future version activates.';
        }
        else if (this.earnRule.portalDerivedStatus === earnRuleStatuses.EXPIRED) {
          return 'This earn rules is expired, and is no longer editable';
        }
        else if (this.earnRule.portalDerivedStatus === earnRuleStatuses.EXPIRING) {
          return 'This earn rule is scheduled to expire tomorrow. Change the end date to keep it active.';
        }
        else {
          return '';
        }
      },

      saveMessage() {
        if (!this.earnRule.publicId) {
          return 'Your earn rule will be created and apply beginning on the scheduled start date';
        }
        else if (this.earnRule.portalDerivedStatus === earnRuleStatuses.ACTIVE || this.earnRule.portalDerivedStatus === earnRuleStatuses.EXPIRING) {
          return 'Editing this earn rule will create a future version of it with the updates. The future version will be editable until it becomes active. The current version will no longer be editable and will remain active until the future version activates.';
        }
        else if (this.earnRule.portalDerivedStatus === earnRuleStatuses.SCHEDULED) {
          return 'This will update the current scheduled version of this earn rule.';
        }
        else if (this.earnRule.portalDerivedStatus === earnRuleStatuses.FUTURE) {
          return 'This will overwrite the current future version of this earn rule, and become active tomorrow.';
        }
        else {
          return null;
        }
      },

      steps() {
        return [
          {
            label: 'Details',
            component: DetailsStep
          },
          {
            label: 'Definition',
            component: DefinitionStep,
            props: {
              earnRuleFullyEditable: this.earnRuleFullyEditable,
              loyaltyProgramPublicId: this.loyaltyProgramPublicId
            }
          },
          {
            label: 'Time Frame',
            component: TimeFrameStep,
            props: {
              earnRuleFullyEditable: this.earnRuleFullyEditable
            }
          },
          {
            label: 'Review',
            component: ReviewStep,
            props: {
              earnRule: this.earnRule
            }
          }
        ];
      }
    },

    created() {
      this.onCreated();
    },

    methods: {
      onCreated() {
        this.form = { ...this.$clone(this.earnRule) };
        this.setItemGroups();
      },

      setItemGroups() {
        // Cast all the item group data as instances of the ItemGroup class
        if (this.awardPolicyItemGroupTypes.includes(this.form.awardPolicy.awardPolicyType)) {
          this.form.awardPolicy.itemGroup = new ItemGroup(this.form.awardPolicy.itemGroup);
        }

        if (this.form.earnRuleConstraints?.length) {
          this.form.earnRuleConstraints = this.form.earnRuleConstraints.map((constraint) => {
            if (constraint.constraintType === constraintModels.SPECIFIED_ITEM_GROUP.type) {
              constraint.itemGroup = new ItemGroup(constraint.itemGroup);
            }
            return constraint;
          });
        }
      },

      confirmSaveEarnRule() {
        this.$buefy.modal.open({
          parent: this,
          component: AlertModal,
          hasModalCard: true,
          trapFocus: true,
          customClass: 'auto-width',
          canCancel: ['escape', 'outside'],
          props: {
            autoWidth: true,
            title: 'Save Earn Rule',
            message: this.saveMessage,
            secondaryMessage: 'Are you sure you\'d like to proceed?',
            horizontal: true,
            showCloseButton: false,
            buttons: [
              { text: 'Cancel' },
              {
                text: 'Save',
                primary: true,
                onClick: this.saveEarnRule
              }
            ]
          }
        });
      },

      async deleteEarnRule() {
        try {
          await EarnRule.deleteEarnRule(this.form.publicId);

          this.$_onRequestSuccess({
            toastOptions: {
              message: 'Successfully removed your earn rule!'
            },
            options: { closeParent: true }
          });
        }

        catch (error) {
          this.$_onRequestError({
            toastOptions: {
              message: 'There was an error removing your earn rule'
            },
            error
          });
        }
      },

      expireEarnRule() {
        const today = moment().utc().format('YYYY-MM-DD');
        this.$buefy.modal.open({
          parent: this,
          component: AlertModal,
          hasModalCard: true,
          trapFocus: true,
          canCancel: ['escape', 'outside'],
          props: {
            icon: 'calendar-times',
            title: 'Expire Earn Rule',
            message: 'This will schedule the earn rule to expire at the end of the day. After today, this action is irreversible.',
            secondaryMessage: 'Are you sure you want to expire this earn rule?',
            type: 'is-danger',
            horizontal: true,
            showCloseButton: false,
            buttons: [
              { text: 'Cancel' },
              {
                text: 'Expire',
                primary: true,
                onClick: () => this.updateEarnRule({ ...this.form, endDate: today })
              }
            ]
          }
        });
      },

      async saveEarnRule() {
        const form = await this.createFormPayload();

        if (this.earnRule.publicId) {
          await this.updateEarnRule(form);
        }
        else {
          await this.createEarnRule(form);
        }
      },

      async createFormPayload() {
        // Create item groups for any new or updated SPECIFIED_ITEM_GROUP constraints and attach new publidId to constraint payload
        this.form.earnRuleConstraints = await Promise.all(this.$clone(this.form.earnRuleConstraints).map(async (constraint) => {
          if (constraint.constraintType === constraintModels.SPECIFIED_ITEM_GROUP.type) {
            const itemGroup = await this.handleItemGroup(constraint.itemGroup);
            const updatedConstraint = {
              ...constraint,
              itemGroupPublicId: itemGroup.publicId
            };
            return updatedConstraint;
          }
          else {
            return constraint;
          }
        }));

        // Create item groups for any item group award policies and attach publicId to form
        if (this.awardPolicyItemGroupTypes.includes(this.form.awardPolicy.awardPolicyType)) {
          const awardPolicyItemGroup = await this.handleItemGroup(this.form.awardPolicy.itemGroup);

          this.form.awardPolicy = {
            ...this.form.awardPolicy,
            itemGroupPublicId: awardPolicyItemGroup.publicId
          };
        }

        return {
          ...this.form,
          awardPolicy: {
            ...this.form.awardPolicy
          }
        };
      },

      async handleItemGroup(itemGroup) {
        if (typeof itemGroup.publicId !== 'symbol' && !itemGroup.hasChanged()) {
          return itemGroup;
        }
        else {
          try {
            return ItemGroup.createLoyaltyItemGroup(this.$_selectedMerchantId, itemGroup);
          }
          catch (error) {
            this.$_onRequestError({
              toastOptions: {
                message: 'There was an error creating an item group associated with this earn rule. Please check your item groups and try again.'
              },
              error
            });
            throw error;
          }
        }
      },

      async updateEarnRule(earnRule) {
        try {
          await EarnRule.updateEarnRule(earnRule);

          this.$_onRequestSuccess({
            toastOptions: {
              message: 'Successfully updated your earn rule!'
            },
            options: { closeParent: true }
          });
        }

        catch (error) {
          this.$_onRequestError({
            toastOptions: {
              message: 'There was an error updating your earn rule'
            },
            error
          });
          throw error;
        }
      },

      async createEarnRule(earnRule) {
        try {
          await EarnRule.createEarnRule(earnRule);

          this.$_onRequestSuccess({
            toastOptions: {
              message: 'Successfully created your earn rule!'
            },
            options: { closeParent: true }
          });
        }

        catch (error) {
          const loyaltyError = error?.data?.technicalMessage;

          this.$_onRequestError({
            toastOptions: {
              message: `There was an error creating your earn rule ${loyaltyError || ''}`.trim(),
              duration: 1000 * 5 // 5 seconds
            },
            error
          });
          throw error;
        }
      }
    }
  };
</script>
