/* eslint-disable import/no-cycle */
import { Model } from '@vuex-orm/core';
import http from '@/services/http';



export default class StoreMenuCategorySchedule extends Model {
  static entity = 'storeMenuCategorySchedules'

  static fields() {
    return {
      id: this.attr(''),
      day: this.attr(''),
      endTime: this.attr(''),
      menuCategoryId: this.attr(''),
      startTime: this.attr(''),
      hideHoursDisplay: this.attr(false),
      storeId: this.attr('')
    };
  }

  // STATE //////////////////////
  static state() {
    return {
      fetching: false,
      submitting: false
    };
  }

  static $state() {
    return this.store().state.entities.storeMenuCategorySchedules;
  }



  // ACTIONS //////////////////////

  static async fetchSchedules(storeId) {
    try {
      const response = await http.get(`stores/${storeId}/store_menu_category_schedules`);
      this.insert({ data: response.data.storeMenuCategorySchedules });
    }

    catch (error) {
      throw error;
    }
  }

  static async createStoreMenuCategorySchedule(storeMenuCategorySchedule) {
    try {
      const response = await http.post(`menu_categories/${storeMenuCategorySchedule.menuCategoryId}/store_menu_category_schedules`, {
        storeMenuCategorySchedule
      });

      this.insert({ data: response.data.storeMenuCategorySchedule });
    }

    catch (error) {
      throw error;
    }
  }

  static async updateStoreMenuCategorySchedule({ id, startTime, endTime, hideHoursDisplay }) {
    try {
      const response = await http.put(`store_menu_category_schedules/${id}`, {
        storeMenuCategorySchedule: { startTime, endTime, hideHoursDisplay }
      });
      this.insert({ data: response.data.storeMenuCategorySchedule });
    }

    catch (error) {
      throw error;
    }
  }

  static async deleteStoreMenuCategorySchedule(scheduleId) {
    try {
      await http.delete(`store_menu_category_schedules/${scheduleId}`);

      this.delete(scheduleId);
    }

    catch (error) {
      throw error;
    }
  }
}
