import { Model } from '@vuex-orm/core';
import http from '@/services/http';



export default class ExternalLink extends Model {
  static entity = 'externalLinkTypes'



  // FIELDS //////////////////////
  static fields() {
    return {
      id: this.attr(''),
      name: this.attr('')
    };
  }



  // STATE //////////////////////
  static state() {
    return {
      fetching: false
    };
  }

  static $state() {
    return this.store().state.entities.externalLinkTypes;
  }


  // ACTIONS //////////////////////
  static async fetchExternalLinks() {
    try {
      this.commit((state) => {
        state.fetching = true;
      });

      const { data } = await http.get('external_link_types');

      this.create({
        data: data.externalLinkTypes
      });
    }

    catch (error) {
      throw error;
    }

    finally {
      this.commit((state) => {
        state.fetching = false;
      });
    }
  }
}
