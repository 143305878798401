import { v4 as uuid } from 'uuid';

export default class ItemTag {
  constructor(itemTag = {}) {
    this.id = uuid();
    this.name = itemTag.name || itemTag.menuAttribute?.name || '';
    this.menuItemId = itemTag?.menuItemId || '';
    this.menuAttributeId = itemTag?.menuAttributeId || '';
    this.isNewTag = this.isNewTag();
  }

  isNewTag() {
    return !this.menuAttributeId;
  }
}
