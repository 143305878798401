import { Model } from '@vuex-orm/core';
import http from '@/services/http';
import capitalCase from '@/helpers/capitalCase';
import { camelCase } from 'change-case';

export default class TippingMethodType extends Model {
  static entity = 'tippingMethodTypes'

  static fields() {
    return {
      id: this.attr(''),
      name: this.attr('')
    };
  }

  get displayName() {
    return capitalCase(this.name);
  }

  static byName() {
    return this.all().reduce((acc, item) => {
      acc[camelCase(item.name)] = item;
      return acc;
    }, {});
  }

  // STATE //////////////////////
  static state() {
    return {
      fetching: false
    };
  }

  static $state() {
    return this.store().state.entities.tippingMethodTypes;
  }

  static async fetchTippingMethodTypes() {
    try {
      this.commit((state) => {
        state.fetching = true;
      });

      const { data } = await http.get('tipping_method_types');
      this.insert({
        data: data.tippingMethodTypes
      });
    }
    catch (error) {
      throw error;
    }
    finally {
      this.commit((state) => {
        state.fetching = false;
      });
    }
  }
}
