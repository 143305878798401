<template>
  <modal-card
    class="modal-card"
    title="Image Cropper"
    force-close
    @closed="$emit('cancel-crop')"
  >
    <div>
      <cropper
        ref="cropper"
        class="cropper"
        :src="imageUrl"
        stencil-component="circle-stencil"
        image-restriction="stencil"
        :stencil-props="{
          aspectRation: 1,
          movable: true,
          resizable: true
        }"
        :debounce="false"
        :default-size="defaultSize"
        :resize-image="false"
      />
    </div>
    <template #footer>
      <div class="buttons all-bold">
        <b-button
          rounded
          @click="cancelCrop"
        >
          Cancel
        </b-button>
        <b-button
          type="is-primary"
          rounded
          @click="useImage"
        >
          Crop
        </b-button>
      </div>
    </template>
  </modal-card>
</template>

<script>
  import { Cropper } from 'vue-advanced-cropper';
  import confirmModalCloseMixin from '@/mixins/confirm-modal-close';

  export default {
    name: 'ImageCropper',

    components: { Cropper },

    mixins: [confirmModalCloseMixin],

    props: {
      imageUrl: {
        type: String,
        default: ''
      },

      imageType: {
        type: String,
        default: null
      }
    },

    methods: {
      defaultSize({ imageSize, visibleArea }) {
        return {
          width: (visibleArea || imageSize).width,
          height: (visibleArea || imageSize).height
        };
      },

      useImage() {
        const { canvas } = this.$refs.cropper.getResult();
        canvas.toBlob((blob) => {
          this.processBlob(blob);
        }, this.imageType);
      },

      cancelCrop() {
        this.$emit('cancel-crop');
        this.$parent.close();
      },

      processBlob(blob) {
        const previewUrl = URL.createObjectURL(blob);
        const urlParts = previewUrl.split('/');
        const shortenedPreviewUrl = `${urlParts[urlParts.length - 1]}.${this.imageType.split('image/')[1]}`;
        const file = new File([blob], shortenedPreviewUrl, { type: this.imageType });
        this.$emit('crop-image', file);
        this.$parent.close();
      }
    }
  };
</script>

<style lang="sass" scoped>
  .cropper
    height: 400px
    width: 400px
    background: #DDD
</style>
