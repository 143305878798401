import { render, staticRenderFns } from "./order-expo-navigation-bar.vue?vue&type=template&id=73f5a797&scoped=true&"
import script from "./order-expo-navigation-bar.vue?vue&type=script&lang=js&"
export * from "./order-expo-navigation-bar.vue?vue&type=script&lang=js&"
import style0 from "./order-expo-navigation-bar.vue?vue&type=style&index=0&id=73f5a797&lang=sass&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "73f5a797",
  null
  
)

export default component.exports