/* eslint-disable import/no-cycle */
import FetchModel from '@/store/classes/FetchModel';

// classes
import Category from './Category';
import CategoryItem from './CategoryItem';
import ModifierGroup from './ModifierGroup';
import StoreItem from './StoreItem';
import MenuItemAttribute from './MenuItemAttribute';
import MenuItemPlatformType from './MenuItemPlatformType';
import MenuItemModifierGroupItem from './MenuItemModifierGroupItem';

import filterObjectKeys from '@/helpers/filter-object-keys';
import http from '@/services/http';
import { setUpdatedItemSortOrders } from '@/helpers/set-new-sort-orders';
import { parseJson } from '@/helpers/misc';


export default class Item extends FetchModel {
  static entity = 'items'



  // FIELDS //////////////////////
  static fields() {
    return {
      ageVerificationTemplate: this.attr(null),
      availabilityBeginDate: this.attr(''),
      availabilityEndDate: this.attr(''),
      caloriesHigh: this.attr(null),
      caloriesLow: this.attr(null),
      createdAt: this.attr(''),
      description: this.attr(''),
      descriptionHeader: this.attr(''),
      descriptionText: this.attr(''),
      displayDetail: this.attr(''),
      displayName: this.attr(''),
      hasSchedule: this.attr(''),
      id: this.attr(''),
      mappedToPos: this.attr(''),
      maxAllowed: this.number(-1),
      menuItemTemplate: this.attr(''),
      metadata: this.attr('', parseJson),
      minRequired: this.attr(''),
      modifierSummary: this.attr(''),
      newItemBeginDate: this.attr(''),
      newItemEndDate: this.attr(''),
      nutritionalUnits: this.attr(''),
      sortOrders: this.attr(''),
      updatedAt: this.attr(''),
      menuTypes: this.attr([]),
      parentMenuItemId: this.attr(null),
      posItemIds: this.attr([]),
      posMenuItemMappings: this.attr([]),
      units: this.attr(null),
      universalProductCode: this.attr(''),

      // FE Properties
      menuTypeIds: this.attr([]),
      isFavorited: this.attr(''),

      // relationships
      menuCategories: this.belongsToMany(Category, CategoryItem, 'menuItemId', 'menuCategoryId'),
      menuItemModifierGroups: this.hasMany(ModifierGroup, 'menuItemId'),
      storeMenuItems: this.hasMany(StoreItem, 'menuItemId'),
      menuItemAttributes: this.hasMany(MenuItemAttribute, 'menuItemId'),
      menuItemPlatformTypes: this.hasMany(MenuItemPlatformType, 'menuItemId'),
      menuItemModifierGroupItems: this.hasMany(MenuItemModifierGroupItem, 'menuItemId')
    };
  }

  sortOrderByCategory(categoryId) {
    const sortOrderObj = this.sortOrders.find(sortOrder => sortOrder.menuCategoryId === categoryId);
    if (sortOrderObj) {
      return sortOrderObj.sortOrder;
    }
  }

  get lastModified() {
    return this.updatedAt || this.createdAt;
  }

  // QUERIES //////////////////////

  static favoritedItems() {
    return Item.query().where('isFavorited', true).orderBy(item => item.displayName.toLowerCase()).get();
  }

  static bagTemplateItem() {
    return Item.query().where('menuItemTemplate', 'BagsTemplate').first();
  }

  // STATE //////////////////////
  static state() {
    return {
      fetching: false,
      deleting: false,
      fetchingItemId: null,
      fetchingCategoryId: null,
      submitting: false,
      sortingParentId: null
    };
  }

  static $state() { // TEST ?
    return this.store().state.entities.items;
  }



  // ACTIONS //////////////////////
  static async fetchItem(itemId) {
    try {
      this.commit((state) => {
        state.fetchingItemId = itemId;
      });

      const response = await http.get(`menu_items/${itemId}`);
      this.insertOrUpdate({
        data: response.data.menuItem,
        update: ['categories']
      });
    }
    catch (error) {
      throw error;
    }
    finally {
      this.commit((state) => {
        state.fetchingItemId = null;
      });
    }
  }

  static async fetchItems(favorited = false) {
    try {
      this.commit((state) => {
        state.fetching = true;
      });

      const merchantId = this.store().state.entities.merchants.selectedMerchantId;
      const url = favorited ? `merchants/${merchantId}/menu_items?favoritedItems=true` : `merchants/${merchantId}/menu_items`;
      const response = await http.get(url);

      this.insert({
        data: response.data.menuItems,
        update: ['categories']
      });
    }
    catch (error) {
      throw error;
    }
    finally {
      this.commit((state) => {
        state.fetching = false;
      });
    }
  }

  static async fetchItemsByCategoryId({ categoryId, includeMenuItemModifierGroupItems = false, options = {} }) {
    const merchantId = this.store().state.entities.merchants.selectedMerchantId;

    await this.fetchData({
      endpoint: `merchants/${merchantId}/menu_items?menu_category_id=${categoryId}${includeMenuItemModifierGroupItems ? '&include=menu_item_modifier_group_items' : ''}`,
      options: {
        update: ['categories'],
        ...options
      },
      transformData: data => data.menuItems,
      ormMethod: 'insertOrUpdate',
      fetchingState: 'fetchingCategoryId',
      fetchingStateValue: categoryId,
      customQuery: Item.query().whereHas('menuCategories', query => query.where('id', categoryId))
    });
  }

  static async addItemToCategory({ menuItem, categoryId }) {
    try {
      this.commit((state) => {
        state.submitting = true;
      });

      const merchantId = this.store().state.entities.merchants.selectedMerchantId;

      const acceptedKeys = [
        'availabilityBeginDate',
        'availabilityEndDate',
        'caloriesHigh',
        'caloriesLow',
        'descriptionText',
        'displayName',
        'modifierSummary',
        'menuTypeIds',
        'maxAllowed',
        'nutritionalUnits',
        'posItemIds',
        'units',
        'universalProductCode'
      ];

      // CAPI is very brittle and breaks if units is an empty string.......
      if (menuItem.units === '') {
        menuItem.units = null;
      }

      const response = await http.post(`merchants/${merchantId}/menu_categories/${categoryId}/menu_items`, {
        menuItem: filterObjectKeys(menuItem, acceptedKeys)
      });

      this.insert({
        data: response.data.menuItem,
        update: ['categories']
      });

      return response.data.menuItem;
    }

    catch (error) {
      throw error;
    }

    finally {
      this.commit((state) => {
        state.submitting = false;
      });
    }
  }

  static async addItemToStoreMenu({ menuItem, stores }) {
    try {
      this.commit((state) => {
        state.submitting = true;
      });

      const merchantId = this.store().state.entities.merchants.selectedMerchantId;

      const response = await http.post(`merchants/${merchantId}/store_menu_items`, {
        stores: stores.map(store => filterObjectKeys(store, ['storeId', 'price', 'isDisabled', 'totalSalesTaxRate'])),
        menuItem: filterObjectKeys(menuItem, ['displayName', 'descriptionText', 'menuCategoryId'])
      });

      this.insert({
        data: response.data.menuItem,
        update: ['categories']
      });

      return response.data.menuItem;
    }

    catch (error) {
      throw error;
    }

    finally {
      this.commit((state) => {
        state.submitting = false;
      });
    }
  }

  static async updateItemInStoreMenu({ menuItem, stores }) {
    try {
      this.commit((state) => {
        state.submitting = true;
      });

      const merchantId = this.store().state.entities.merchants.selectedMerchantId;

      const acceptedStoreKeys = [
        'storeId',
        'price',
        'isDisabled',
        'totalSalesTaxRate',
        'newStoreId',
        'inventoryCount'
      ];

      const acceptedItemKeys = [
        'id',
        'caloriesHigh',
        'caloriesLow',
        'displayName',
        'descriptionText',
        'menuCategoryId',
        'modifierSummary',
        'nutritionalUnits'
      ];

      const response = await http.put(`merchants/${merchantId}/store_menu_items`, {
        stores: stores.map(store => filterObjectKeys(store, acceptedStoreKeys)),
        menuItem: filterObjectKeys(menuItem, acceptedItemKeys)
      });

      Category.insert({ data: response.data.menuCategory });
    }

    catch (error) {
      throw error;
    }

    finally {
      this.commit((state) => {
        state.submitting = false;
      });
    }
  }

  static async updateItem(itemData) {
    try {
      this.commit((state) => {
        state.submitting = true;
      });

      const { id } = itemData;

      const acceptedKeys = [
        'ageVerificationTemplate',
        'availabilityBeginDate',
        'availabilityEndDate',
        'caloriesHigh',
        'caloriesLow',
        'descriptionHeader',
        'descriptionText',
        'displayName',
        'maxAllowed',
        'menuItemTemplate',
        'menuTypeIds',
        'metadata',
        'minRequired',
        'modifierSummary',
        'newItemBeginDate',
        'newItemEndDate',
        'nutritionalUnits',
        'posItemIds',
        'units',
        'universalProductCode'
      ];

      const { data } = await http.put(`menu_items/${id}`, { menuItem: filterObjectKeys(itemData, acceptedKeys) });

      this.update({ data: data.menuItem });
    }

    catch (error) {
      throw error;
    }

    finally {
      this.commit((state) => {
        state.submitting = false;
      });
    }
  }

  static async updateSortOrders({ categoryId, items, oldIndex, newIndex }) { // eslint-disable-line object-curly-newline
    const newMenuItems = setUpdatedItemSortOrders({
      fromIndex: oldIndex,
      toIndex: newIndex,
      array: items,
      categoryId
    });

    this.insert({
      data: newMenuItems.map((item) => {
        [
          'menuCategories',
          'menuItemModifierGroups',
          'storeMenuItems'
        ].forEach((key) => {
          delete item[key];
        });
        return item;
      })
    });

    try {
      this.commit((state) => {
        state.sortingParentId = categoryId;
      });

      await http.put(`menu_categories/${categoryId}/menu_items/sort_order`, { menuItems: newMenuItems });
    }

    catch (error) {
      this.insert({ data: items });
      throw error;
    }

    finally {
      this.commit((state) => {
        state.sortingParentId = null;
      });
    }
  }

  static async copyToCategories({ itemId, categoryIds }) {
    try {
      this.commit((state) => {
        state.submitting = true;
      });

      const responseArray = await Promise.all(
        categoryIds.map(id => http.post(`menu_items/${itemId}/clone`, { menuCategoryId: id }))
      );

      const copiedItems = responseArray.map(response => response.data.menuItem);

      this.insert({
        data: copiedItems,
        update: ['categories']
      });

      return copiedItems;
    }
    catch (error) {
      throw error;
    }
    finally {
      this.commit((state) => {
        state.submitting = false;
      });
    }
  }

  static async bulkAssignMenuType({ menuType, menuItemIds = [] }) {
    try {
      const response = await http.post(
        'menu_item_menu_types/add',
        { menuTypeId: menuType.id, menuItemIds }
      );

      const itemsAddedToMenuType = this.query()
        .where(item => menuItemIds.includes(item.id) || menuItemIds.includes(item.parentMenuItemId))
        .get();

      const updatedItems = JSON.parse(JSON.stringify(itemsAddedToMenuType)).map(item => ({
        ...item,
        menuTypes: [...item.menuTypes, menuType]
      }));

      this.update({ data: updatedItems });
      Category.update({ data: response.data.menuCategories });
    }
    catch (error) {
      throw error;
    }
  }

  static async bulkRemoveMenuType({ menuTypeId, menuItemIds = [], menuCategoryIds = [] }) {
    const removeMenuType = resources => resources.map(resource => ({
      ...resource,
      menuTypes: resource.menuTypes.filter(mt => mt.id !== menuTypeId)
    }));

    try {
      await http.delete('menu_item_menu_types/remove', {
        data: {
          menuTypeId,
          menuItemIds,
          menuCategoryIds
        }
      });

      if (menuItemIds.length) {
        const itemsRemovedFromMenuType = this.query()
          .where(item => menuItemIds.includes(item.id) || menuItemIds.includes(item.parentMenuItemId))
          .get();
        const updatedItems = removeMenuType(itemsRemovedFromMenuType);

        this.update({ data: updatedItems });
      }

      else {
        const categoriesRemovedFromMenuType = Category.query().with('menuItems').where(category => menuCategoryIds.includes(category.id)).get();
        const itemsRemovedFromMenuType = categoriesRemovedFromMenuType.flatMap(category => category.menuItems);

        const updatedCategories = removeMenuType(categoriesRemovedFromMenuType);
        const updatedItems = removeMenuType(itemsRemovedFromMenuType);

        Category.update({ data: updatedCategories });
        this.update({ data: updatedItems });
      }
    }
    catch (error) {
      throw error;
    }
  }

  static async deleteMenuItem(menuItem) {
    try {
      this.commit((state) => {
        state.deleting = true;
      });

      await http.delete(`menu_items/${menuItem.id}`);

      this.delete(menuItem.id);
    }

    catch (error) {
      throw error;
    }

    finally {
      this.commit((state) => {
        state.deleting = false;
      });
    }
  }
}
