<template>
  <validated-form
    ref="form"
    form-id="searchByGroupSelectModal"
    auto-focus
    @valid-submit="handleSubmit"
  >
    <modal-card
      :title="title"
      :subtitle="subtitle"
      modal-card-body-class="pad-none"
    >
      <b-field class="pad-md" grouped>
        <b-input
          v-model="searchInput"
          icon-right-clickable
          :icon-right="searchInput ? 'times-circle' : null"
          :placeholder="placeholderText"
          type="text"
          icon="search"
          expanded
          @icon-right-click="searchInput = null"
          @keydown.native.esc="searchInput = null"
          @keydown.native.enter.prevent
        />
        <p v-tippy="{ content: 'Collapse All' }" class="control">
          <b-button :disabled="!isAnyGroupOpen" @click="isOpen = {}">
            <b-icon icon="compress-alt" size="is-medium" />
          </b-button>
        </p>
      </b-field>

      <div class="nested-table has-border-top has-border-grey-lightest">
        <div class="table-heading">
          <div class="row">
            <span>Name</span>
            <span class="align-center justify-end no-wrap-text">
              Selected Modifier(s)
            </span>
          </div>
        </div>
        <ul>
          <li
            v-for="group in filteredItems"
            :key="`group-${group.id}`"
            :class="{'is-open': isOpen[group.id]}"
            :data-group-id="group.id"
          >
            <div class="row">
              <span class="pad-y-sm">
                <span class="link-inverted" @click="toggleOpenState(group.id)">
                  <span class="has-text-weight-bold">{{ group.displayName }}</span>
                  <b-icon
                    size="is-small"
                    pack="far"
                    icon="angle-right"
                    :class="[
                      'open-indicator',
                      {
                        'is-open': isOpen[group.id],
                      }
                    ]"
                  />
                </span>
              </span>
              <span class="justify-end">
                <span class="mar-r-sm no-flex-shrink">Select All</span>
                <b-checkbox
                  class="no-label"
                  :value="isModifierGroupFullySelected(group)"
                  :indeterminate="isModifierGroupPartiallySelected(group)"
                  @input="toggleSelectAll($event, group)"
                />
              </span>
            </div>
            <ul v-if="isOpen[group.id]" class="nested-table-section">
              <li v-if="!group.items.length">
                <div class="row sub-row">
                  <span class="has-text-grey pad-y-sm">No Items</span>
                  <span />
                </div>
              </li>
              <li
                v-for="item in group.items"
                :key="`item-${item.id}`"
                :data-item-id="item.id"
              >
                <div class="row sub-row">
                  <span>
                    {{ item.displayName }}
                  </span>
                  <span class="justify-end">
                    <b-checkbox
                      v-model="selected"
                      class="no-label"
                      :native-value="item.id"
                    />
                  </span>
                </div>
              </li>
            </ul>
          </li>
        </ul>
      </div>
      <template #footer>
        <div class="buttons all-bold">
          <b-button
            rounded
            @click="$_confirmCloseModal({ programmatic: true })"
          >
            {{ cancelText }}
          </b-button>
          <b-button
            v-tabbable
            rounded
            native-type="submit"
            type="is-primary"
            :loading="isLoading"
            :disabled="isLoading || !selected.length"
          >
            {{ confirmText }}
          </b-button>
        </div>
      </template>
    </modal-card>
  </validated-form>
</template>

<script>
  import confirmModalCloseMixin from '@/mixins/confirm-modal-close';

  export default {
    name: 'SearchByGroupSelectModal',

    mixins: [confirmModalCloseMixin],

    props: {
      cancelText: {
        type: String,
        default: 'Cancel'
      },

      confirmText: {
        type: String,
        default: 'Save'
      },

      onSubmit: {
        type: Function,
        required: true
      },

      placeholderText: {
        type: String,
        required: true
      },

      subtitle: {
        type: String,
        default: ''
      },

      title: {
        type: String,
        required: true
      },


      qualifyingModifierMenuItemIds: {
        type: Array,
        default: () => []
      },

      groups: {
        type: Array,
        required: true,
        validator(array) {
          const requiredKeys = ['id', 'displayName'];
          return array.every((obj) => {
            const groupObjKeys = Object.keys(obj);
            const groupKeysValid = requiredKeys.every(reqKey => groupObjKeys.includes(reqKey));
            const childKeysValid = obj.items.every((item) => {
              const itemObjKeys = Object.keys(item);
              return requiredKeys.every(reqKey => itemObjKeys.includes(reqKey));
            });
            return groupKeysValid && childKeysValid;
          });
        }
      }
    },

    data() {
      return {
        selected: this.qualifyingModifierMenuItemIds || [],
        searchInput: null,
        isLoading: false,
        isOpen: {},
        searchResults: null
      };
    },

    computed: {
      filteredItems() {
        if (this.searchInput) {
          return this.groups.reduce((acc, group) => {
            const items = group.items.filter(sl => sl.displayName.toLowerCase().includes(this.searchInput.toLowerCase()));
            if (items.length) {
              acc.push({ ...group, items });
            }
            return acc;
          }, []);
        }
        return this.groups;
      },

      isAnyGroupOpen() {
        return Object.values(this.isOpen).some(group => !!group);
      }
    },

    methods: {
      async handleSubmit() {
        try {
          this.isLoading = true;
          await this.onSubmit(this.selected);
          this.$_confirmCloseModal({ programmatic: true });
        }
        catch (error) {
          console.log('error');
        }
        finally {
          this.isLoading = false;
        }
      },

      toggleOpenState(groupId) {
        this.$set(this.isOpen, groupId, !this.isOpen[groupId]);
      },

      toggleSelectAll(selectAll, group) {
        const groupModifierIds = group.items.map(item => item.id);
        if (selectAll) {
          groupModifierIds.forEach((id) => {
            if (!this.selected.includes(id)) {
              this.selected.push(id);
            }
          });
        }
        else {
          this.selected = this.selected.filter(id => !groupModifierIds?.includes(id));
        }
      },

      isModifierGroupPartiallySelected(group) {
        const groupModifierIds = group.items.map(item => item.id);

        return !this.isModifierGroupFullySelected(group)
          && groupModifierIds.some(modifierId => this.selected.includes(modifierId));
      },

      isModifierGroupFullySelected(group) {
        if (!this.selected || !this.selected.length) {
          return false;
        }
        const groupModifierIds = group.items.map(item => item.id);

        return groupModifierIds.every(modifierId => this.selected.includes(modifierId));
      }
    }
  };
</script>

<style lang="sass" scoped>
  .row
    grid-template-columns: 1fr 125px

</style>
