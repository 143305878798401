<template>
  
  <validated-form
    ref="form"
    form-id="storeLocationAttributes"
    @valid-submit="updateStore"
  >
    <sticky-save-container
      :saving="submittingStore"
      :loading="isFetchingStoreAttributes"
      title="Location Attributes"
    >
      <b-field>
        <div v-if="!isFetchingStoreAttributes && storeAttributeForm" class="is-grid row-height-auto col-min-300 gap-lg">
          <check-button
            v-for="({ name, code, isActive, id }, key, index) in storeAttributeForm"
            :key="id"
            :class="['is-marginless animated tdFadeInDown']"
            :style="{'animation-delay': `${index * (333 / Object.keys(storeAttributeForm).length)}ms`}"
            :value="isActive"
            :label="name"
            :sublabel="(isActive === undefined || isActive === null) && '(No Value)'"
            :data-id="code"
            @input="handleAttributeChange(code, $event)"
          />
        </div>
      </b-field>
    </sticky-save-container>
  </validated-form>
</template>

<script>
  import filterObjectKeys from '@/helpers/filter-object-keys';
  import merchantMixin from '@/mixins/merchant';
  import Store from '@/store/classes/Store';
  import StoreAttribute from '@/store/classes/StoreAttribute';

  export default {
    name: 'StoreLocationAttributes',

    mixins: [merchantMixin],

    props: {
      store: {
        type: Object,
        required: true
      }
    },

    data() {
      return {
        storeAttributeForm: null
      };
    },

    computed: {
      storeAttributes() {
        return StoreAttribute.all();
      },

      isFetchingStoreAttributes() {
        return StoreAttribute.$state().fetching;
      },

      submittingStore() {
        return Store.$state().submitting;
      }
    },

    methods: {
      async onMerchantLoad() {
        await this.fetchStoreAttributes();
        this.storeAttributeForm = this.formatStoreAttributes(this.storeAttributes);
        this.highlightSelectedAttribute();
      },

      async fetchStoreAttributes() {
        try {
          await StoreAttribute.fetchStoreAttributes();
        }
        catch (error) {
          this.$_onRequestError({
            error,
            toastOptions: {
              message: 'There was an issue fetching store attributes'
            }
          });
        }
      },

      formatStoreAttributes(allAttributes) {
        return this.$clone(allAttributes)
          .sort((a, b) => (a.name < b.name ? -1 : 1))
          .reduce((obj, { id, name, code }) => {
            obj[code] = {
              id,
              name,
              code,
              isActive: this.getStoreAttribute(code)?.isActive
            };
            return obj;
          }, {});
      },

      getStoreAttribute(code) {
        return this.store.storeMappingAttributes.find(sma => sma.code === code);
      },

      handleAttributeChange(key, value) {
        this.storeAttributeForm[key].isActive = value;
      },

      async updateStore() {
        try {
          const storeMappingAttributesPayload = Object.values(this.storeAttributeForm)
            .filter(attr => attr.isActive !== undefined)
            .map(attr => filterObjectKeys(attr, ['code', 'isActive', 'name']));

          const updatedStorePayload = {
            id: this.store.id,
            storeId: this.store.storeId,
            storeMappingAttributes: storeMappingAttributesPayload
          };

          await Store.updateStore(updatedStorePayload);

          this.$_onRequestSuccess({
            toastOptions: { message: `Location attributes for <b>${this.store.description}</b> have been successfully updated!` }
          });
        }
        catch (error) {
          this.$_onRequestError({
            error,
            toastOptions: { message: `There was an issue updating the location attributes for <b>${this.store.description}</b>` }
          });
        }
      },

      highlightSelectedAttribute() {
        const selectedElement = this.$route.query.highlighted;
        if (selectedElement) {
          this.$nextTick(() => {
            const element = this.$el.querySelector(`[data-id="${selectedElement}"]`);
            if (element) {
              element.scrollIntoView({ block: 'center', behavior: 'smooth' });
              element.classList.add('highlight-element');
              element.focus();

              setTimeout(() => {
                element.classList.remove('highlight-element');
              }, 3000);
            }
          });
        }
      }
    }
  };
</script>
