<template>
  <div>
    <h3>Item Group Form...</h3>
    <p>...eventually</p>
  </div>
</template>

<script>
  export default {
    name: 'ItemGroupOfferForm',

    props: {
      selectedResources: {
        type: Array,
        default: () => []
      },

      readOnly: {
        type: Boolean,
        required: true
      }
    }
  };
</script>
