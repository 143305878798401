<template>
  <div v-if="$_selectedMerchant">
    <hero-banner>
      <h1 class="title is-2">Segmentations</h1>
      <template #right>
        <b-button
          v-if="segmentations.length"
          rounded
          inverted
          size="is-medium"
          type="is-primary"
          icon-left="plus"
          :loading="isLoading"
          @click="openSegmentationModal()"
        >
          New Segmentation
        </b-button>
      </template>
    </hero-banner>

    <div class="section">
      <div class="container">
        <div v-if="segmentations.length" class="card">
          <searchable-table
            v-slot="{ filteredData }"
            :table-data="segmentations"
            :search="{
              placeholder: 'Search by Segmentation Name',
              keys: ['name']
            }"
          >
            <b-table
              :data="isFetchingSegmentations ? [] : filteredData"
              :paginated="segmentations.length > pageLimit"
              class="is-middle-aligned"
              pagination-simple
              :per-page="pageLimit"
              :mobile-cards="false"
              :default-sort="['name', 'asc']"
              :loading="isFetchingSegmentations || isLoading"
            >

              <b-table-column v-slot="{ row }" sortable field="name" label="Name">
                <span class="link" @click="openSegmentationModal({ segmentation: row, mode: 'update' })">
                  {{ row.name || 'N/A' }}
                </span>
              </b-table-column>

              <b-table-column
                v-slot="{ row }"
                centered
                cell-class="action"
                width="1"
                label="Delete"
              >
                <div
                  v-tippy="{
                    content: 'Only unused segmentations can be deleted',
                    placement: 'left',
                    onShow: () => row.campaignIds.length !== 0
                  }"
                >
                  <b-button
                    :disabled="row.campaignIds.length !== 0"
                    @click="openConfirmSegmentationDeletion(row)"
                  >
                    <b-icon size="is-small" type="is-danger" icon="trash-alt" />
                  </b-button>
                </div>
              </b-table-column>

              <template slot="empty">
                <empty-table-loader
                  icon="chart-pie"
                  message="No Segmentations Found..."
                  :loading="isFetchingSegmentations || isLoading"
                />
              </template>
            </b-table>
          </searchable-table>
        </div>
        <empty-state-card
          v-else
          type="is-primary"
          title="Create Segmentation"
          message="Define groups of users for targetting campaigns"
          icon="chart-pie"
        >
          <template #buttons>
            <b-button
              type="is-primary"
              rounded
              icon-left="plus"
              @click="openSegmentationModal()"
            >
              New Segmentation
            </b-button>
          </template>
        </empty-state-card>
      </div>
    </div>
  </div>
</template>

<script>
  import merchantMixin from '@/mixins/merchant';
  import Segmentation from '@/store/classes/Segmentation';
  import segmentationModal from '@/components/pages/segmentations/segmentation-modal.vue';
  import moment from 'moment-timezone';
  import alertModal from '@/components/globals/alert-modal.vue';
  import Store from '@/store/classes/Store';
  import Category from '@/store/classes/Category';
  import LoyaltyProgram from '@/store/classes/LoyaltyProgram';
  import LoyaltyProgramTier from '@/store/classes/LoyaltyProgramTier';
  import MerchantLoyaltyProviderConfiguration from '@/store/classes/MerchantLoyaltyProviderConfiguration';
  import Item from '@/store/classes/Item';
  import Currency from '@/store/classes/Currency';
  import MenuAttribute from '@/store/classes/MenuAttribute';
  import MenuAttributeType from '@/store/classes/MenuAttributeType';

  export default {
    name: 'SegmentationList',

    mixins: [merchantMixin],

    data() {
      return {
        pageLimit: 20
      };
    },

    computed: {
      segmentations() {
        return Segmentation.query().where('merchantId', this.$_selectedMerchantId).get();
      },

      isFetchingSegmentations() {
        return Segmentation.$state().fetching;
      },

      isLoading() {
        return Store.$state().fetching
          || Category.$state().fetching
          || MerchantLoyaltyProviderConfiguration.$state().fetching
          || LoyaltyProgram.$state().fetching
          || LoyaltyProgramTier.$state().fetching;
      }
    },

    methods: {
      moment,

      async onMerchantLoad() {
        await this.fetchSegmentations();
      },

      async fetchSegmentations() {
        try {
          await Segmentation.fetchSegmentations();
        }
        catch (error) {
          this.$_onRequestError({
            error,
            toastOptions: {
              message: 'There was an error fetching your segmentations'
            }
          });
        }
      },

      async deleteSegmentation(segmentationId) {
        try {
          await Segmentation.deleteSegmentation(segmentationId);

          this.$_onRequestSuccess({
            toastOptions: {
              message: 'Successfully deleted segmentation!'
            }
          });
        }

        catch (error) {
          this.$_onRequestError({
            toastOptions: {
              message: 'An error occurred while deleting your segmentation'
            },
            error
          });
        }
      },

      async openSegmentationModal({ segmentation, mode } = {}) {
        await Promise.all([
          this.fetchStores(),
          this.fetchCategories(),
          this.fetchMerchantLoyaltyProviderConfigurations(),
          this.fetchCurrencies(),
          this.fetchMenuAttributesForMerchant(),
          this.fetchFavoritedItemsForMerchant()
        ]);

        if (MerchantLoyaltyProviderConfiguration.exists()) {
          await this.fetchLoyaltyProgramTiers();
        }

        this.$buefy.modal.open({
          parent: this,
          component: segmentationModal,
          hasModalCard: true,
          trapFocus: true,
          canCancel: false,
          fullScreen: true,
          props: { segmentation, mode }
        });
      },

      async fetchStores() {
        try {
          await Store.fetchAll(null, { favoritedStores: true, forceFetch: true });
        }
        catch (error) {
          this.$_onRequestError({
            error,
            toastOptions: {
              message: 'There was an error fetching stores'
            }
          });
        }
      },

      async fetchMenuAttributesForMerchant() {
        try {
          await Promise.all([
            MenuAttribute.fetchMenuAttributesForMerchant(),
            MenuAttributeType.fetchMenuAttributes()
          ]);
        }
        catch (error) {
          this.$_onRequestError({
            error,
            toastOptions: {
              message: 'There was an error fetching tags'
            }
          });
        }
      },

      async fetchFavoritedItemsForMerchant() {
        try {
          await Item.fetchItems(true);
        }
        catch (error) {
          this.$_onRequestError({
            error,
            toastOptions: {
              message: 'There was an error fetching items'
            }
          });
        }
      },

      async fetchCurrencies() {
        try {
          await Currency.fetchCurrencies();
        }
        catch (error) {
          this.$_onRequestError({
            error,
            toastOptions: {
              message: 'There was an error fetching currencies'
            }
          });
        }
      },

      async fetchCategories() {
        try {
          await Category.fetchCategories();
        }
        catch (error) {
          this.$_onRequestError({
            error,
            toastOptions: {
              message: 'There was an error fetching categories'
            }
          });
        }
      },

      async fetchMerchantLoyaltyProviderConfigurations() {
        try {
          await MerchantLoyaltyProviderConfiguration.fetchMerchantLoyaltyProviderConfigurations(this.$_selectedMerchantId);
        }
        catch (error) {
          this.$_onRequestError({
            error,
            toastOptions: { message: 'Unable to fetch merchant loyalty provider configurations' }
          });
        }
      },

      async fetchLoyaltyProgramTiers() {
        try {
          await LoyaltyProgram.fetchPrograms();
          const loyaltyProgramPublicId = LoyaltyProgram.query().first()?.publicId;
          await LoyaltyProgramTier.fetchProgramTiers(loyaltyProgramPublicId);
        }
        catch (error) {
          this.$_onRequestError({
            error,
            toastOptions: { message: 'Unable to fetch loyalty programs' }
          });
        }
      },

      openConfirmSegmentationDeletion(segmentation) {
        this.$buefy.modal.open({
          parent: this,
          component: alertModal,
          hasModalCard: true,
          trapFocus: true,
          canCancel: ['escape', 'outside'],
          props: {
            icon: 'trash-alt',
            title: 'Delete Segmentation',
            message: `Are you sure you want to delete the <b>${segmentation.name}</b> segmentation?`,
            type: 'is-danger',
            horizontal: true,
            showCloseButton: false,
            buttons: [
              { text: 'Cancel' },
              {
                text: 'Delete',
                primary: true,
                onClick: () => this.deleteSegmentation(segmentation.id)
              }
            ]
          }
        });
      }
    }
  };
</script>
