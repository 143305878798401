import { Model } from '@vuex-orm/core';
import http from '@/services/http';


export default class MerchantInAppMessage extends Model {
  static entity = 'merchantInAppMessages'

  static primaryKey = 'publicId'

  // FIELDS //////////////////////
  static fields() {
    return {
      alwaysShow: this.attr(false),
      bodyContent: this.attr(''),
      ctaContent: this.attr(''),
      ctaLink: this.attr(''),
      endDate: this.attr(null),
      endTime: this.attr(null),
      inAppMessageTypeId: this.attr(''),
      isMandatory: this.attr(true),
      isPublished: this.attr(false),
      merchantId: this.attr(''),
      portalDeepLinkId: this.attr(''),
      portalDeepLinkSpecifiedResourceId: this.attr(''),
      primaryImageUrl: this.attr(''),
      publicId: this.attr(''),
      id: this.attr(''),
      resourceMetadata: this.attr(null),
      startDate: this.attr(null),
      startTime: this.attr(null),
      status: this.attr(''),
      title: this.attr('')
    };
  }


  // STATE //////////////////////
  static state() {
    return {
      fetching: false,
      submitting: false,
      deleting: false
    };
  }

  static $state() {
    return this.store().state.entities.merchantInAppMessages;
  }


  // ACTIONS //////////////////////
  static async fetchMerchantInAppMessages() {
    try {
      this.commit((state) => {
        state.fetching = true;
      });

      const merchantId = this.store().state.entities.merchants.selectedMerchantId;

      const { data } = await http.get(`merchants/${merchantId}/merchant_in_app_messages`);

      this.insert({
        data: data.merchantInAppMessages
      });
    }

    catch (error) {
      throw error;
    }

    finally {
      this.commit((state) => {
        state.fetching = false;
      });
    }
  }

  static async createMerchantInAppMessage(merchantInAppMessage) {
    try {
      this.commit((state) => {
        state.submitting = true;
      });

      const { data } = await http.post('/merchant_in_app_messages', { merchantInAppMessage });

      this.insert({
        data: data.merchantInAppMessage
      });

      return data.merchantInAppMessage;
    }

    catch (error) {
      throw error;
    }

    finally {
      this.commit((state) => {
        state.submitting = false;
      });
    }
  }

  static async updateMerchantInAppMessage(merchantInAppMessage) {
    try {
      this.commit((state) => {
        state.submitting = true;
      });

      const { data } = await http.put(`/merchant_in_app_messages/${merchantInAppMessage.publicId}`, { merchantInAppMessage });

      this.update({
        data: data.merchantInAppMessage
      });

      return data.merchantInAppMessage;
    }

    catch (error) {
      throw error;
    }

    finally {
      this.commit((state) => {
        state.submitting = false;
      });
    }
  }

  static async deleteMerchantInAppMessage(publicId) {
    try {
      this.commit((state) => {
        state.deleting = true;
      });

      await http.delete(`/merchant_in_app_messages/${publicId}`);

      this.delete(publicId);
    }

    catch (error) {
      throw error;
    }

    finally {
      this.commit((state) => {
        state.deleting = false;
      });
    }
  }

  static async createMerchantInAppMessageImage({ merchantId, file }) {
    try {
      const formData = new FormData();
      formData.append('merchantInAppMessageImage[file]', file);
      const response = await http.post(
        `merchants/${merchantId}/merchant_in_app_message_images`,
        formData,
        { headers: { 'Content-Type': 'multipart/form-data' } }
      );

      return response.data.merchantInAppMessageImageUrl;
    }

    catch (error) {
      throw error;
    }
  }
}
