<template>
  <dropdown-menu
    position="bottom"
    :disabled="disabled"
    :expanded="expanded"
    class="has-extra-shadow"
    @active-change="handleOpenDropdown"
  >
    <div slot="trigger">
      <slot />
    </div>

    <template v-if="recentMerchantIds.length > 1">
      <b-dropdown-item custom class="pad-y-none is-flex align-center justify-between">
        <span class="mar-r-md has-text-weight-bold no-wrap-text" style="font-size: 1.2rem">
          Recent Merchants
          <b-icon
            class="mar-l-xs"
            icon="star"
            size="is-small"
            type="is-warning"
            custom-size="fa-sm"
          />
        </span>
        <b-button
          class="animated tdFadeInDown"
          style="padding: 0 10px"
          size="is-small"
          rounded
          @click="clearRecents"
        >
          Clear
        </b-button>
      </b-dropdown-item>

      <hr class="dropdown-divider">

      <div v-if="fetchingMerchants">
        <div
          v-for="i in recentMerchantIds.length"
          :key="i"
          style="height: 33px"
          class="is-flex align-center pad-l"
        >
          <b-skeleton height="23px" :width="getRandomNumber(175, 250, true)" />
        </div>
      </div>
      <template v-else>
        <b-dropdown-item
          v-for="(merchant, index) in recentMerchants"
          :key="merchant.id"
          :class="['animated tdFadeInRight', {'is-active no-pointer-events': $_selectedMerchantId === merchant.id}]"
          :style="{'animation-delay': `${index * (333 / recentMerchants.length)}ms`}"
          @click="selectMerchant(merchant.id)"
        >
          {{ merchant.name }}
        </b-dropdown-item>
      </template>
      <hr class="dropdown-divider">
    </template>

    <b-dropdown-item has-link class="back-to-list">
      <router-link :to="{ name: 'merchantList' }">
        <b-icon class="mar-r-sm" icon="arrow-left" size="is-small" />
        Back to Merchant List
      </router-link>
    </b-dropdown-item>
  </dropdown-menu>
</template>

<script>
  import merchantMixin from '@/mixins/merchant';
  import Merchant from '@/store/classes/Merchant';
  import { getRandomNumber } from '@/helpers/misc';


  export default {
    name: 'MerchantDropdown',

    mixins: [merchantMixin],

    props: {
      disabled: {
        type: Boolean,
        default: false
      },
      expanded: {
        type: Boolean,
        default: false
      }
    },

    data() {
      return {
        getRandomNumber,
        fetchingMerchants: false
      };
    },

    computed: {
      recentMerchants() {
        return Merchant.recentMerchants();
      },

      recentMerchantIds() {
        return Merchant.$state().recentMerchantIds;
      }
    },

    methods: {
      toggleLoading(val) {
        this.fetchingMerchants = val;
      },

      clearRecents() {
        Merchant.clearRecentMerchantIds();
      },

      handleOpenDropdown(isOpen) {
        const isMissingMerchants = this.recentMerchantIds.some(id => !this.recentMerchants.find(m => m.id === id));
        if (isOpen && isMissingMerchants) {
          this.fetchMerchants();
        }
      },

      selectMerchant(merchantId) {
        if (this.$route.name !== 'home') {
          this.$router.push({ name: 'home' });
        }
        Merchant.setSelectedMerchantId(merchantId);
        this.fetchMerchant(merchantId);
      },

      async fetchMerchant(merchantId) {
        try {
          await Merchant.fetchMerchant(merchantId, { isFetchingSelectedMerchant: true });
        }
        catch (error) {
          this.$_onRequestError({
            error,
            toastOptions: { message: 'Unable to fetch merchant' }
          });
        }
      },

      async fetchMerchants() {
        try {
          this.toggleLoading(true);
          await Merchant.fetchMerchants();
        }
        catch (error) {
          this.$_onRequestError({
            error,
            toastOptions: { message: 'Unable to fetch merchants' }
          });
        }
        finally {
          this.toggleLoading(false);
        }
      }
    }
  };
</script>

<style lang="sass" scoped>
  .back-to-list:hover
    .icon
      animation: shift 800ms cubic-bezier(0, 0.4, 0.65, 0.68) infinite
</style>
