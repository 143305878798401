<template>
  <div>
    <div class="is-flex align-center gap-sm mar-b-sm">
      <p class="subtitle is-5 mar-none">{{ criteriaType | capitalize }}</p>
      <b-button
        :data-test-id="`clear-criteria-button-${criteriaType}`"
        size="is-small"
        rounded
        @click="$emit('clear-criteria', criteriaType)"
      >
        Clear
      </b-button>
    </div>

    <slot />

    <b-button
      v-if="showAddButton"
      :data-test-id="`add-criteria-button-${criteriaType}`"
      icon-left="plus"
      type="is-primary is-inverted"
      size="is-medium"
      rounded
      expanded
      class="has-border-grey-lighter has-text-weight-bold mar-t-sm"
      :disabled="disableAddButton"
      @click="$emit('add-criteria', criteriaType)"
    >
      {{ criteriaType | capitalize }} Criteria
    </b-button>
  </div>
</template>

<script>
  export default {
    name: 'CriteriaFormWrapper',

    props: {
      criteriaType: {
        type: String,
        required: true
      },
      disableAddButton: {
        type: Boolean,
        default: false
      },
      showAddButton: {
        type: Boolean,
        default: false
      }
    }
  };
</script>
