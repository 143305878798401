<template>
  <div>
    <p class="has-text-grey-light mar-b-none">{{ label }}</p>
    <slot>
      <p>{{ content }}</p>
    </slot>
  </div>
</template>

<script>
  export default {
    name: 'ExpoOrderDetailsRow',

    props: {
      label: {
        type: String,
        required: true
      },

      content: {
        type: [String, Number],
        default: null
      }
    }
  };
</script>
