<template>
  <validated-form
    ref="merchantFeaturesForm"
    form-id="merchantFeatures"
    @valid-submit="updateFeatures"
  >
    <sticky-save-container
      :saving="isSubmitting"
      :loading="isFetching"
      title="Portal Features"
    >
      <div v-if="features" class="is-grid row-height-auto col-min-300 gap-lg">
        <check-button
          v-for="(key, index) in Object.keys(features).sort((a, b) => a > b ? 1 : -1)"
          :key="key"
          v-model="features[key]"
          type="is-primary"
          :label="capitalCase(key)"
          :class="['is-marginless animated tdFadeInDown']"
          :style="{'animation-delay': `${index * (333 / Object.keys(features).length)}ms`}"
          :data-id="key"
        />
      </div>

      <hr>

      <div>
        <b-field
          v-if="features"
          label="KDS New Order Reminder Duration"
          data-test-id="kds-new-order-reminder-duration"
        >
          <b-dropdown v-model="kdsNewOrderReminderDuration">
            <template #trigger>
              <b-button
                :value="kdsNewOrderReminderDuration"
                :label="`Every ${kdsNewOrderReminderDuration} seconds`"
                icon-right="chevron-down"
              />
            </template>
            <b-dropdown-item
              v-for="option in [5, 15, 30, 45, 60]"
              :key="option"
              :value="option"
            >
              Every {{ option }} seconds
            </b-dropdown-item>
          </b-dropdown>
        </b-field>
      </div>

      <hr>

      <section class="config-container" data-id="Order Dashboard Columns">
        <h3 class="subtitle has-text-weight-bold">Order Dashboard Columns</h3>
        <validation-provider
          v-slot="{ errors }"
          :rules="{ required: !selectedOrderDashboardColumns.length }"
          name="offers"
          :custom-messages="{ required: 'You must select at least one order dashboard column' }"
        >
          <b-checkbox
            :class="['is-hidden', { 'invalid': errors.length }]"
            :value="selectedOrderDashboardColumns.length || null"
          />
          <b-field
            :type="{ 'is-danger': !!errors.length }"
            :message="errors | capitalize"
          >
            <div class="is-grid col-min-100">
              <b-checkbox
                v-for="column in allOrderDashboardColumns"
                :key="column.id"
                v-model="selectedOrderDashboardColumns"
                :native-value="column.id"
              >
                {{ column.displayName }}
              </b-checkbox>
            </div>
          </b-field>
        </validation-provider>
      </section>

      <hr>

      <section class="config-container" data-id="SSO Providers">
        <h3 class="subtitle has-text-weight-bold">SSO Providers</h3>
        <validated-input
          v-for="provider in ssoProviders"
          :key="provider.id + provider.name"
          class="align-labels-left"
          :label="`${capitalCase(provider.displayName)} Single Sign-On`"
          horizontal
          :name="provider.name"
        >
          <b-switch
            :value="!!merchantSsoProviders.find(msp => msp.ssoProviderId === provider.id)"
            type="is-primary"
            @input="handleSsoProviderInput({ checked: $event, providerId: provider.id })"
          />
        </validated-input>
      </section>
    </sticky-save-container>
  </validated-form>
</template>

<script>
  import capitalCase from '@/helpers/capitalCase';
  import Merchant from '@/store/classes/Merchant';
  import OrderDashboardColumn from '@/store/classes/OrderDashboardColumn';
  import SsoProvider from '@/store/classes/SsoProvider';
  import getChangedResources from '@/helpers/get-changed-resources';


  export default {
    name: 'MerchantFeatures',

    props: {
      merchant: {
        type: Object,
        required: true
      }
    },

    data() {
      return {
        capitalCase,
        features: null,
        kdsNewOrderReminderDuration: null,
        selectedOrderDashboardColumns: [],
        merchantSsoProviders: []
      };
    },

    computed: {
      isSubmitting() {
        return Merchant.$state().submittingFeatures;
      },

      isFetching() {
        return Merchant.$state().fetchingMerchant || OrderDashboardColumn.$state().fetching;
      },

      allOrderDashboardColumns() {
        return OrderDashboardColumn.all();
      },

      ssoProviders() {
        return SsoProvider.all();
      }
    },

    created() {
      this.onCreated();
    },

    methods: {
      async onCreated() {
        await Promise.all([OrderDashboardColumn.fetchOrderDashboardColumns(), this.fetchSsoProviders()]);

        const features = this.$clone(this.merchant.features);
        this.kdsNewOrderReminderDuration = features.kdsNewOrderReminderDuration || 60;
        const merchantFeatures = this.filterMerchantFeatures(this.$clone(features));
        this.features = merchantFeatures;
        this.selectedOrderDashboardColumns = this.$clone(this.merchant.orderDashboardColumns);
        this.merchantSsoProviders = this.$clone(features.featuresSsoProviders);
        this.highlightSelectedFeature();
      },

      handleSsoProviderInput({ checked, providerId }) {
        if (checked) {
          this.merchantSsoProviders.push({
            featureId: this.merchant.features.id,
            ssoProviderId: providerId
          });
        }
        else {
          this.merchantSsoProviders = this.merchantSsoProviders.filter(msp => msp.ssoProviderId !== providerId);
        }
      },

      filterMerchantFeatures(features) {
        const keysToKeep = Object.keys(features).filter(key => typeof features[key] === 'boolean');
        Object.keys(features).forEach((key) => {
          if (!keysToKeep.includes(key)) {
            delete features[key];
          }
        });
        return features;
      },

      async fetchSsoProviders() {
        try {
          await SsoProvider.fetchSsoProviders();
        }
        catch (error) {
          this.$_onRequestError({
            error,
            toastOptions: {
              message: 'There was an error fetching sso providers'
            }
          });
        }
      },

      getSsoProvidersPayload() {
        const { id: featureId, featuresSsoProviders: fsp } = this.merchant.features;
        const { added, removed } = getChangedResources({
          oldArray: fsp.map(s => s.ssoProviderId),
          newArray: this.merchantSsoProviders.map(s => s.ssoProviderId)
        });

        const featuresSsoProvidersAttributes = [];
        if (added.length) {
          added.forEach((a) => {
            featuresSsoProvidersAttributes.push({
              featureId,
              ssoProviderId: a
            });
          });
        }

        if (removed.length) {
          removed.forEach((rId) => {
            featuresSsoProvidersAttributes.push({
              id: fsp.find(p => p.ssoProviderId === rId).id,
              _destroy: true
            });
          });
        }
        return featuresSsoProvidersAttributes;
      },

      async updateFeatures() {
        try {
          await Merchant.updateFeatures({
            id: this.merchant.features.id,
            ...this.features,
            orderDashboardColumns: this.sortedOrderDashboardColumns(),
            featuresSsoProvidersAttributes: this.getSsoProvidersPayload(),
            kdsNewOrderReminderDuration: this.kdsNewOrderReminderDuration
          });

          this.$_onRequestSuccess({
            toastOptions: { message: 'Successfully updated merchant features' }
          });
        }

        catch (error) {
          this.$_onRequestError({
            error,
            toastOptions: {
              message: 'There was an issue updating your merchant features'
            }
          });
        }
      },

      sortedOrderDashboardColumns() {
        return this.$clone(this.selectedOrderDashboardColumns).sort((a, b) => {
          const sortOrderA = this.allOrderDashboardColumns.find(e => e.id === a).sortOrder;
          const sortOrderB = this.allOrderDashboardColumns.find(e => e.id === b).sortOrder;
          return sortOrderA - sortOrderB;
        });
      },

      highlightSelectedFeature() {
        const selectedElement = this.$route.query.highlighted;
        this.$nextTick(() => {
          const element = this.$el.querySelector(`[data-id="${selectedElement}"]`);

          if (element) {
            element.scrollIntoView({ block: 'center', behavior: 'smooth' });
            element.classList.add('highlight-element');
            element.focus();

            setTimeout(() => {
              element.classList.remove('highlight-element');
            }, 3000);
          }
        });
      }
    }
  };
</script>
