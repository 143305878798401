<template>
  <validated-form
    v-if="form"
    ref="form"
    v-slot="{}"
    form-id="merchantInternalForm"
    class="pad-b-lg"
    @valid-submit="updateMerchant"
  >
    <sticky-save-container :loading="isLoadingUpsellDisplayModes" :saving="isLoading" title="Additional Settings">
      <!-- Section 1: Check Buttons -->
      <div v-once class="is-grid col-min-300 gap-lg">
        <check-button
          v-for="(option, key) in checkButtonOptions"
          :key="key"
          v-model="form[key]"
          type="is-primary"
          :label="option.label"
          :tooltip="option.tooltip"
          class="is-marginless animated tdFadeInDown"
        />
      </div>

      <!-- Section 2: Pay at Table 2FA -->
      <div class="has-border has-border-grey-lightest has-radius pad mar-y-xl">
        <b-field class="is-flex align-items-center mar-b-md">
          <span class="has-text-weight-bold mar-r-md">Pay at Table 2FA</span>
          <b-switch
            v-model="form.payAtTableTwoFaEnabled"
            name="payAtTableTwoFaEnabled"
          />
        </b-field>


        <div key="triggerPayAtTableTwoFaOnTicket" class="is-flex gap-sm mar-b-md">
          <radio-button
            v-model="form.triggerPayAtTableTwoFaOnTicket"
            name="viewingType"
            :native-value="true"
            class="is-flex align-center"
            :disabled="!form.payAtTableTwoFaEnabled"
          >
            <span class="mar-l-xs">Before Viewing Ticket</span>
          </radio-button>
          <radio-button
            v-model="form.triggerPayAtTableTwoFaOnTicket"
            name="viewingType"
            :native-value="false"
            class="is-flex align-center"
            :disabled="!form.payAtTableTwoFaEnabled"
          >
            <span class="mar-l-xs">Before Submitting Payment</span>
          </radio-button>
        </div>

        <validated-text-input
          v-model="form.payAtTableTwoFaDollarTriggerAmount"
          label="Pay at Table 2FA Dollar Trigger Amount"
          name="payAtTableTwoFaDollarTriggerAmount"
          type="dollars"
          icon="dollar-sign"
          :disabled="!form.payAtTableTwoFaEnabled"
          :tooltip="form.payAtTableTwoFaEnabled ? '' : 'Enable Pay at Table 2FA to set this amount'"
          class="pad-y-xs"
          :rules="{ min_value: 0, required: form.payAtTableTwoFaEnabled }"
          :custom-messages="{ required: `An amount must be provided if 'Pay at Table 2FA' is enabled` }"
          style="max-width: 350px;"
        />
      </div>

      <!-- Section 3: Dropdowns and Store ID -->
      <div class="last-section">
        <validated-input
          name="primarySignupType"
          label="Primary Sign Up Type"
          horizontal
          class="align-labels-left"
          tooltip="Sign up type for accounts"
        >
          <b-field>
            <dropdown-menu v-model="form.primarySignupTypeId">
              <dropdown-button slot="trigger" placeholder="Select a sign up type.">
                <div v-if="form.primarySignupTypeId" class="is-flex align-center">
                  {{ primarySignupTypes[form.primarySignupTypeId] }}
                </div>
              </dropdown-button>
              <b-dropdown-item
                v-for="(display, value) in primarySignupTypes"
                :key="value"
                :value="Number(value)"
                class="pad-y-sm is-flex align-center"
              >
                {{ display }}
              </b-dropdown-item>
            </dropdown-menu>
          </b-field>
        </validated-input>

        <b-field label="Campaign Time Zone" horizontal class="align-labels-left">
          <dropdown-menu v-model="form.features.campaignsIanaTimezoneId">
            <dropdown-button slot="trigger" placeholder="Select a time zone...">
              <div v-if="form.features.campaignsIanaTimezoneId" class="is-flex align-center">
                <span class="mar-r-sm">{{ timeZones[form.features.campaignsIanaTimezoneId] }}</span>
                <span class="has-text-grey is-size-7">({{ form.features.campaignsIanaTimezoneId }})</span>
              </div>
            </dropdown-button>
            <b-dropdown-item v-for="(display, value) in timeZones" :key="value" :value="value" class="pad-y-sm is-flex align-center">
              <span class="mar-r-sm">{{ display }}</span><span class="dropdown-item--text__secondary">({{ value }})</span>
            </b-dropdown-item>
          </dropdown-menu>
        </b-field>

        <validated-input
          name="defaultOfferRedemptionTimezone"
          label="Default Offer Redemption Time Zone"
          horizontal
          class="align-labels-left"
          tooltip="Determines offer allocation & expiration, account management date"
        >
          <b-field>
            <dropdown-menu v-model="form.defaultOfferRedemptionTimezone">
              <dropdown-button slot="trigger" placeholder="Select a time zone...">
                <div v-if="form.defaultOfferRedemptionTimezone" class="is-flex align-center">
                  <span class="mar-r-sm">{{ timeZones[form.defaultOfferRedemptionTimezone] }}</span>
                  <span class="has-text-grey is-size-7">({{ form.defaultOfferRedemptionTimezone }})</span>
                </div>
              </dropdown-button>
              <b-dropdown-item :value="null">None</b-dropdown-item>
              <b-dropdown-item v-for="(display, value) in timeZones" :key="value" :value="value" class="pad-y-sm is-flex align-center">
                <span class="mar-r-sm">{{ display }}</span><span class="dropdown-item--text__secondary">({{ value }})</span>
              </b-dropdown-item>
            </dropdown-menu>
          </b-field>
        </validated-input>

        <validated-input
          name="defaultUserTimezone"
          label="Default User Time Zone"
          horizontal
          class="align-labels-left"
          tooltip="Determines offer allocation & expiration, account management date"
        >
          <b-field>
            <dropdown-menu v-model="form.defaultUserTimezone">
              <dropdown-button slot="trigger" placeholder="Select a time zone...">
                <div v-if="form.defaultUserTimezone" class="is-flex align-center">
                  <span class="mar-r-sm">{{ timeZones[form.defaultUserTimezone] }}</span>
                  <span class="has-text-grey is-size-7">({{ form.defaultUserTimezone }})</span>
                </div>
              </dropdown-button>
              <b-dropdown-item :value="null">None</b-dropdown-item>
              <b-dropdown-item v-for="(display, value) in timeZones" :key="value" :value="value" class="pad-y-sm is-flex align-center">
                <span class="mar-r-sm">{{ display }}</span><span class="dropdown-item--text__secondary">({{ value }})</span>
              </b-dropdown-item>
            </dropdown-menu>
          </b-field>
        </validated-input>

        <b-field label="Default Store Id" horizontal class="align-labels-left">
          <validated-text-input
            v-model="form.defaultStoreId"
            label=""
            name="defaultStoreId"
            type="text"
            style="width: 25%;"
          />
        </b-field>

        <validated-input
          name="defaultUpsellDisplayModeId"
          label="Upsell Display Mode"
          horizontal
          class="align-labels-left"
        >
          <b-field>
            <dropdown-menu v-model="form.defaultUpsellDisplayModeId">
              <dropdown-button slot="trigger" placeholder="Select an upsell mode.">
                <div v-if="form.defaultUpsellDisplayModeId" class="is-flex align-center">
                  {{ upsellDisplayModes.find(udm => udm.id === form.defaultUpsellDisplayModeId).displayName }}
                </div>
              </dropdown-button>
              <b-dropdown-item
                v-for="({id, displayName}) in upsellDisplayModes"
                :key="id"
                :value="Number(id)"
                class="pad-y-sm is-flex align-center"
              >
                {{ displayName }}
              </b-dropdown-item>
            </dropdown-menu>
          </b-field>
        </validated-input>
      </div>
    </sticky-save-container>
  </validated-form>
</template>

<script>
  import Merchant from '@/store/classes/Merchant';
  import timeZones from '@/constants/ianaTimezones';
  import primarySignupTypes from '@/constants/primarySignupTypes';
  import UpsellDisplayMode from '@/store/classes/UpsellDisplayMode';

  export default {
    name: 'MerchantAdditionalSettings',

    props: {
      merchant: {
        type: Object,
        required: true
      }
    },

    data() {
      return {
        form: null,
        isLoading: false,
        timeZones,
        primarySignupTypes,
        checkButtonOptions: {
          allowEmptyLineItems: { label: 'Allow Empty Line Items' },
          allowItemLevelRefunds: { label: 'Allow Item Level Refunds' },
          allowDisabledItemOverride: {
            label: 'Allow Disabled Item Override',
            tooltip: 'Allow availability set on the location menu to override availability from the POS'
          },
          guestUserAccountCreationEnabled: {
            label: 'Allow Guest Creation',
            tooltip: 'For TTP, P@T Loyalty, and In-Store Loyalty'
          },
          allowLoginByPhoneNumber: { label: 'Allow Login By Phone Number (Web/Mobile)' },
          allowKioskLoginByPhoneNumber: { label: 'Allow Login By Phone Number (Kiosk)' },
          ignoreMenuDayPartSchedule: { label: 'Ignore Menu Dayparts' },
          payAtTableOrderCleanupEnabled: { label: 'Enable Order Clean Up' },
          splitDineInOrderItems: { label: 'Split Dine In Order Items' },
          allowQuickAddWithDefaultMods: {
            label: 'Quick Add',
            tooltip: 'Allow items with default modifiers to be quick added'
          },
          requireAuthenticationForNewDeviceLogin: {
            label: 'Require Authentication For New Device Login',
            tooltip: 'For Order Ahead only, does not apply to P@T'
          }
        }
      };
    },

    computed: {
      upsellDisplayModes() {
        return UpsellDisplayMode.all().map(usp => ({ ...usp, displayName: usp.displayName }));
      },

      isLoadingUpsellDisplayModes() {
        return UpsellDisplayMode.$state().fetching;
      }
    },

    created() {
      this.onCreated();
    },

    methods: {
      async onCreated() {
        await this.fetchUpsellDisplayModes();
        this.form = JSON.parse(JSON.stringify(this.merchant));
      },

      toggleLoading(val) {
        this.isLoading = val;
      },

      async fetchUpsellDisplayModes() {
        try {
          await UpsellDisplayMode.fetchUpsellDisplayModes();
        }

        catch (error) {
          this.$_onRequestError({
            toastOptions: {
              message: 'There was an error fetching upsell display modes'
            },
            error
          });
        }
      },

      async updateMerchant() {
        try {
          this.toggleLoading(true);
          await Promise.all([
            Merchant.updateMerchant(this.form),
            Merchant.updateFeatures(this.form.features)
          ]);

          this.$_onRequestSuccess({
            toastOptions: {
              message: 'Successfully updated your merchant'
            }
          });
        }
        catch (error) {
          this.$_onRequestError({
            toastOptions: {
              message: 'There was an error updating your merchant'
            },
            error
          });
        }
        finally {
          this.toggleLoading(false);
        }
      }
    }
  };
</script>

<style lang="sass" scoped>
  .last-section
    display: grid
    grid-template-columns: minmax(200px, 0.8fr) 2fr
    gap: 1rem
    align-items: center

    .field.is-horizontal
      display: contents

    .field-label
      text-align: left

  @media (max-width: $tablet)
    .last-section
      grid-template-columns: 1fr
      gap: 0

    .dropdown-trigger-button
      margin-bottom: 1rem

    .field-label
      flex-basis: 100%
      margin-bottom: 0.5rem

    .field-body
      flex-basis: 100%

    .radio-group
      flex-direction: column
      gap: 0.5rem
</style>
