<template>
  <validated-form
    ref="form"
    form-id="tippingOptionForm"
    @valid-submit="handleSubmit"
  >
    <panel
      title="Tipping Options"
      collapsible
      start-open
      :loading="isFetching || !form"
    >
      <template v-if="!readOnly" #buttons>
        <b-button
          type="is-primary"
          size="is-medium"
          class="is-bold"
          native-type="submit"
          rounded
          :loading="isLoading"
        >
          Save
        </b-button>
      </template>
      <section v-if="form">
        <b-message v-if="isStoreTipping" class="is-compact has-shadow mar-b-lg" type="is-warning" size="is-small">
          Location tipping options override merchant tipping options. Enable "Use Merchant Defaults" to defer to merchant tip settings.
        </b-message>
        <div class="dist-y-lg">
          <!---------------------->
          <!-- Tipping Switches -->
          <!---------------------->
          <b-field label="Allow Tipping" class="align-labels-left" horizontal>
            <b-switch v-model="isTippingEnabled" :disabled="readOnly" />
          </b-field>
          <b-field v-if="isStoreTipping" label="Use Merchant Defaults" class="align-labels-left" horizontal>
            <b-switch
              :value="useMerchantDefaults"
              :disabled="readOnly || isTippingEnabled === false"
              @input="handleUseMerchantDefaults"
            />
          </b-field>
          <b-field v-if="!isStoreTipping" label="Calculate Tip After Tax" class="align-labels-left" horizontal>
            <b-switch
              v-model="calculateTipAfterTax"
              :disabled="readOnly || !$can('update', 'MerchantOptionsCheckout', 'calculateTipAfterTax')"
            />
          </b-field>

          <!-------------------------------->
          <!-- All/Per App Tipping Toggle -->
          <!-------------------------------->
          <div v-if="!isStoreTipping">
            <validated-input
              v-if="supportsMultipleTippingOptions"
              class="mar-y-lg"
              label="Application Type"
              name="applicationType"
            >
              <toggle-buttons
                v-model="hasGlobalTippingOptions"
                label-left="All Applications"
                sub-label-left="Tipping options apply to all applications types"
                :value-left="true"
                label-right="Per Application"
                sub-label-right="Tipping options can be customized per application type"
                :value-right="false"
                :disabled="readOnly"
              />
            </validated-input>
            <validated-input
              class="mar-y-lg"
              label="Tipping Style"
              name="tippingStyle"
            >
              <toggle-buttons
                v-model="isDefaultTippingMethod"
                label-left="Options"
                sub-label-left="Guests can select from three tip options"
                :value-left="true"
                label-right="Incrementation"
                sub-label-right="Guests start with default tip amount and can adjust amount up and down"
                :value-right="false"
                :disabled="readOnly"
              />
            </validated-input>
          </div>
        </div>

        <hr class="is-thick is-full-bleed">

        <div :class="{ 'mar-b-xl': isStoreTipping }">
          <p v-if="isStoreTipping" class="title is-6">Tipping Type</p>
          <toggle-buttons
            v-model="isDollarTip"
            label-left="Percentage"
            :value-left="false"
            label-right="Dollars"
            :value-right="true"
            class="mar-b-lg"
          />
        </div>

        <!-------------------------------------->
        <!-- All Applications Tipping Options -->
        <!-------------------------------------->
        <transition mode="out-in" name="fade-down">

          <div v-if="hasGlobalTippingOptions">
            <tipping-option-group
              key="globalDefault"
              v-model="form[0]"
              :disabled="!isTippingEnabled || useMerchantDefaults || readOnly"
              :tipping-option-type-id="0"
              :supports-multiple-tipping-options="supportsMultipleTippingOptions"
              :name="typeContent[0].name"
              :tipping-method-type-id="form[0] && form[0][0].tippingOptionMethodTypeId"
            />
          </div>

          <!---------------------------------->
          <!-- App Specific Tipping Options -->
          <!---------------------------------->
          <div v-else>
            <tipping-option-group
              v-for="typeId in appSpecificTypeIds"
              :key="`${typeId}-default`"
              v-model="form[typeId]"
              :tipping-option-type-id="typeId"
              :disabled="!isTippingEnabled || useMerchantDefaults || readOnly"
              :supports-multiple-tipping-options="supportsMultipleTippingOptions"
              :name="typeContent[typeId].name"
              :description="typeContent[typeId].description"
              :tipping-method-type-id="form[typeId] && form[typeId][0].tippingOptionMethodTypeId"
            />
          </div>
        </transition>

      </section>
    </panel>
  </validated-form>
</template>

<script>
  import logger from '@/services/logger';
  import { fetchTippingOptionTypes } from '@/api/content-management';
  import { tippingOptionMethodTypes, tippingOptionTypes } from '@/constants/merchantTippingOptions';

  // components
  import tippingOptionGroup from '@/components/globals/tipping-option-group.vue';

  // classes
  import MerchantTippingOption from '@/store/classes/MerchantTippingOption';
  import MerchantOption from '@/store/classes/MerchantOption';
  import MerchantOptionsCheckout from '@/store/classes/MerchantOptionsCheckout';
  import Store from '@/store/classes/Store';
  import storeMappingAttributes from '@/constants/storeMappingAttributes';
  import StoreAttribute from '@/store/classes/StoreAttribute';

  export default {
    name: 'TippingOptionForm',

    components: { tippingOptionGroup },


    props: {
      merchant: {
        type: Object,
        required: true
      },

      store: {
        type: Object,
        default: null
      },

      isStoreTipping: {
        type: Boolean,
        default: false
      }
    },

    data() {
      return {
        isTippingEnabled: true,
        useMerchantDefaults: false,
        calculateTipAfterTax: false,
        isLoading: false,
        form: null,
        hasGlobalTippingOptions: true,
        isDefaultTippingMethod: true,
        tippingOptionTypes: []
      };
    },

    computed: {
      selectedTippingMethodTypeId() {
        return Number(!this.isDefaultTippingMethod);
      },

      typeContent() {
        return {
          0: { name: 'All Applications' },
          1: { name: 'Order Ahead' },
          2: { name: 'Pay @ Table' },
          3: { name: 'Text 2 Pay' },
          4: { name: 'Order @ Table' },
          5: { name: 'Kiosk' },
          6: { name: 'External Device', description: this.isDefaultTippingMethod ? null : 'Only supports the "Options" Tipping Style' }
        };
      },

      defaultTippingOptions() {
        return MerchantTippingOption.query().where('merchantId', 0).get()
          .map(option => ({
            ...option,
            id: null,
            merchantId: null
          }));
      },

      merchantTippingOptions() {
        return MerchantTippingOption.query()
          .where('merchantId', this.merchant.id)
          .where('tippingTypeId', 1)
          .where('storeId', null)
          .get();
      },

      storeTippingOptions() {
        return MerchantTippingOption.query()
          .where('merchantId', this.merchant.id)
          .where('tippingTypeId', 1)
          .where('storeId', this.store.storeId)
          .get();
      },

      appSpecificTypeIds() {
        // assign sort orders to tipping option types so
        // they can dynamically render in the correct order
        const sortOrder = { 1:1, 2:2, 3:4, 4:3, 5:5, 6:6 }; // eslint-disable-line

        const enabled = {
          1: 'orderAheadEnabled',
          2: 'patEnabled',
          4: 'oatEnabled',
          3: 'textToPayEnabled',
          5: 'kioskEnabled',
          6: 'externalDeviceEnabled'
        };
        const storeEnabled = {
          1: 'orderAheadEnabled',
          2: 'payAtTableEnabled',
          4: enabled[4],
          3: enabled[3],
          5: 'kioskOrderingEnabled',
          6: enabled[6]
        };
        const mappedTypes = this.$clone(this.tippingOptionTypes).map(type => type.id);
        let filteredTypes = mappedTypes.filter(id => this.merchant[enabled[id]]);
        if (this.isStoreTipping) {
          filteredTypes = filteredTypes.filter(id => this.store[storeEnabled[id]] ?? storeEnabled[id]);
        }
        return filteredTypes.sort((a, b) => ((sortOrder[a] > sortOrder[b]) ? 1 : -1));
      },

      supportsMultipleTippingOptions() {
        return this.appSpecificTypeIds.length > 1;
      },

      defaultTippingOptionsByType() {
        return this.defaultTippingOptions.reduce((obj, to) => {
          if (obj[to.tippingOptionTypeId]) obj[to.tippingOptionTypeId].push(to);
          else obj[to.tippingOptionTypeId] = [to];
          return obj;
        }, {});
      },

      tippingOptionsByType() {
        return this.tippingOptionTypes.reduce((currentObj, tippingOptionType) => {
          const merchantTippingOptionsByType = this.merchantTippingOptions.filter(option => option.tippingOptionTypeId === tippingOptionType.id);
          const defaultTippingOptionsByType = this.defaultTippingOptionsByType[tippingOptionType.id];
          const defaultTypeTippingOptions = this.defaultTippingOptionsByType[0];
          let tippingOptionsOfType;
          let defaultTippingOptionsForType;

          if (this.isStoreTipping) {
            tippingOptionsOfType = this.storeTippingOptions.filter(option => option.tippingOptionTypeId === tippingOptionType.id);
            defaultTippingOptionsForType = merchantTippingOptionsByType.length ? merchantTippingOptionsByType : (defaultTippingOptionsByType || defaultTypeTippingOptions);
          }
          else {
            tippingOptionsOfType = this.$clone(merchantTippingOptionsByType);
            defaultTippingOptionsForType = defaultTippingOptionsByType || defaultTypeTippingOptions;
          }

          // use the type-specific default values if they exist and fallback to the "default" type's default values

          const defaultTippingOptionsOfType = this.$clone(defaultTippingOptionsForType)
            .map(option => ({
              ...option,
              tippingOptionTypeId: tippingOptionType.id,
              tippingOptionMethodTypeId: this.selectedTippingMethodTypeId
            }));

          // if isStoreTipping, check merchant level first then set default if not found
          currentObj[tippingOptionType.id] = (tippingOptionsOfType.length
            ? tippingOptionsOfType
            : defaultTippingOptionsOfType
          ).sort((a, b) => (a.sortOrder < b.sortOrder ? -1 : 1));

          // Backfill values if Incrementation Type since there will only be 1 tipping option
          if (tippingOptionsOfType.length && tippingOptionsOfType[0].tippingOptionMethodTypeId === tippingOptionMethodTypes.BUTTON) {
            const backfillTippingOptions = defaultTippingOptionsOfType.filter(option => !option.isDefault);
            const currentValues = currentObj[tippingOptionType.id];
            currentObj[tippingOptionType.id] = [...currentValues, ...backfillTippingOptions];
          }

          return currentObj;
        }, {});
      },

      isDollarTip: {
        get() {
          const validateSomePercentNoDollarAmounts = tippingOptions => tippingOptions?.some(option => option.dollarAmount)
            && !tippingOptions?.every(tippingOption => tippingOption.percentAmount);

          if (this.hasGlobalTippingOptions) {
            return validateSomePercentNoDollarAmounts(this.form[0]);
          }
          else {
            return this.appSpecificTypeIds?.every(
              typeId => this.form[typeId].some(option => option.dollarAmount)
              && !this.form[typeId].every(tippingOption => tippingOption.percentAmount)
            );
          }
        },

        set(value) {
          const mapOptions = (option, setDollar) => ({
            ...option,
            percentAmount: setDollar ? null : (parseInt(option.dollarAmount, 10) || null),
            dollarAmount: setDollar ? (option.percentAmount || null) : null
          });

          if (this.hasGlobalTippingOptions) {
            this.form[0] = this.form[0].map(option => mapOptions(option, value));
          }
          else {
            this.appSpecificTypeIds.forEach((typeId) => {
              this.form[typeId] = this.form[typeId].map(option => mapOptions(option, value));
            });
          }
        }
      },

      isFetching() {
        return MerchantTippingOption.$state().fetching;
      },

      readOnly() {
        return !['create', 'update'].some(action => this.$can(action, 'MerchantTippingOption'));
      },

      tippingEnabledAttribute() {
        return StoreAttribute.query().where('code', storeMappingAttributes.TIPPING.code).first();
      },

      isStoreTippingEnabledUndefined() {
        const isTippingEnabledActive = this.store.storeMappingAttributes.find(attr => attr.code === storeMappingAttributes.TIPPING.code)?.isActive;
        return isTippingEnabledActive === undefined || isTippingEnabledActive === null;
      }
    },

    watch: {
      isDefaultTippingMethod: 'handleMethodTypeChange'
    },

    created() {
      this.onCreated();
    },

    methods: {
      async onCreated() {
        try {
          this.tippingOptionTypes = await this.fetchTippingOptionTypes();

          if (this.isStoreTipping) {
            await this.fetchStoreAttributes();
          }

          await this.fetchMerchantTippingOptions();
          /*
            `patTipping` is outdated naming, this is actually
            the global flag for en/disabling tipping for a merchant
          */

          this.calculateTipAfterTax = this.merchant.merchantOptionsCheckout.calculateTipAfterTax;
          this.isDefaultTippingMethod = this.merchantTippingOptions.every(option => option.tippingOptionMethodTypeId === tippingOptionMethodTypes.DEFAULT);
          this.hasGlobalTippingOptions = !this.merchantTippingOptions.some(option => option.tippingOptionTypeId > 0);
          this.form = this.$clone(this.tippingOptionsByType);

          if (this.isStoreTipping) {
            if (this.isStoreTippingEnabledUndefined) {
              this.isTippingEnabled = this.merchant.merchantOption.patTipping;
              this.useMerchantDefaults = true;
            }
            else {
              this.isTippingEnabled = this.store.tippingEnabled;
            }
          }
          else {
            this.isTippingEnabled = this.merchant.merchantOption.patTipping;
          }
        }
        catch (error) {
          logger.error(error);
        }
      },

      handleUseMerchantDefaults() {
        this.useMerchantDefaults = !this.useMerchantDefaults;

        if (this.hasGlobalTippingOptions) {
          this.form[0] = this.form[0].map(option => ({
            ...option,
            isActive: !this.useMerchantDefaults
          }));
        }
        else {
          Object.keys(this.form).forEach((typeId) => {
            this.form[typeId] = this.form[typeId].map(option => ({
              ...option,
              isActive: !this.useMerchantDefaults
            }));
          });
        }
      },

      async fetchStoreAttributes() {
        try {
          await StoreAttribute.fetchStoreAttributes();
        }

        catch (error) {
          this.$_onRequestError({
            error,
            toastOptions: {
              message: 'There was an issue fetching store attributes'
            }
          });
        }
      },

      handleMethodTypeChange() {
        if (this.form) {
          const formClone = this.$clone(this.form);
          Object.entries(formClone).forEach(([tippingOptionTypeId, tippingOptions]) => {
            formClone[tippingOptionTypeId] = tippingOptions.map(option => ({
              ...option,
              tippingOptionMethodTypeId: Number(tippingOptionTypeId) === tippingOptionTypes.EXTERNAL_DEVICE
                ? tippingOptionMethodTypes.DEFAULT // the `External Device` tipping option type will always use the default tipping method
                : this.selectedTippingMethodTypeId
            }));
          });
          this.form = formClone;
        }
      },

      async fetchTippingOptionTypes() {
        try {
          return await fetchTippingOptionTypes();
        }
        catch (error) {
          this.$_onRequestError({
            error,
            toastOptions: { message: 'There was an issue fetching tipping option types' }
          });
        }
      },

      async fetchMerchantTippingOptions() {
        try {
          await MerchantTippingOption.fetchMerchantTippingOptions(this.merchant.id);
        }

        catch (error) {
          this.$_onRequestError({
            error,
            toastOptions: {
              message: 'There was an error fetching your Tipping Options'
            }
          });
        }
      },

      toggleLoading(value) {
        this.isLoading = value;
      },

      async updateStoreTippingEnabled() {
        try {
          await Store.updateStore({
            storeId: this.store.storeId,
            storeMappingAttributes: [
              {
                code: this.tippingEnabledAttribute.code,
                isActive: this.useMerchantDefaults && this.isTippingEnabled ? null : this.isTippingEnabled,
                storeAttributeId: this.tippingEnabledAttribute.id,
                storeId: this.store.storeId
              }
            ]
          });
        }
        catch (error) {
          this.$_onRequestError({
            error,
            toastOptions: {
              message: 'There was an error updating your tipping options'
            }
          });
        }
      },

      async handleSubmit() {
        this.toggleLoading(true);

        try {
          const isFormChanged = this.checkFormChanged();

          if (isFormChanged) {
            if (this.isStoreTipping) {
              await this.updateStoreTippingEnabled();
            }
            else {
              await MerchantOption.updateMerchantOption({
                id: this.merchant.merchantOption.id,
                patTipping: this.isTippingEnabled
              });
              await MerchantOptionsCheckout.updateMerchantOptionsCheckout({
                id: this.merchant.merchantOptionsCheckout.id,
                calculateTipAfterTax: this.calculateTipAfterTax
              });
            }
          }

          if (this.isTippingEnabled) {
            let merchantTippingOptions;

            if (this.hasGlobalTippingOptions) {
              merchantTippingOptions = this.form[0][0].tippingOptionMethodTypeId === tippingOptionMethodTypes.DEFAULT
                ? this.form[0]
                : this.form[0].filter(tippingOption => tippingOption.isDefault);
            }
            else {
              merchantTippingOptions = this.appSpecificTypeIds.flatMap((availableTypeId) => {
                if (this.form[availableTypeId][0].tippingOptionMethodTypeId === tippingOptionMethodTypes.DEFAULT) {
                  return this.form[availableTypeId];
                }
                return this.form[availableTypeId].filter(tippingOption => tippingOption.isDefault);
              });
            }

            if (this.isStoreTipping) {
              merchantTippingOptions = merchantTippingOptions.map(o => ({ ...o, storeId: this.store.storeId }));
            }

            await MerchantTippingOption.setMerchantTippingOptions({ merchantTippingOptions, merchantId: this.merchant.id });

            this.form = this.$clone(this.tippingOptionsByType);
          }
          else {
            this.useMerchantDefaults = false;
          }

          this.$_onRequestSuccess({
            toastOptions: {
              message: 'Tipping options have been successfully updated!'
            }
          });
        }
        catch (error) {
          this.$_onRequestError({
            error,
            toastOptions: {
              message: 'There was an error updating your tipping options'
            }
          });
        }

        this.toggleLoading(false);
      },

      checkFormChanged() {
        return [
          this.merchant.merchantOption.patTipping !== this.isTippingEnabled,
          this.isStoreTipping && this.store.tippingEnabled !== this.isTippingEnabled,
          this.merchant.merchantOptionsCheckout.calculateTipAfterTax !== this.calculateTipAfterTax,
          this.isStoreTipping && !this.isStoreTippingEnabledUndefined && this.useMerchantDefaults
        ].some(merchantOption => merchantOption);
      }
    }
  };
</script>
