<template>
  <validated-input
    :name="`${formName}ExcludedItemAttributes`"
    label="Excluded Items"
    tooltip="Types of items to be excluded when calculating discount"
  >
    <b-dropdown
      v-model="excludedItemAttributeIds"
      :mobile-modal="false"
      aria-role="list"
      scrollable
      multiple
      inline
      :disabled="readOnly"
    >
      <b-dropdown-item
        v-for="itemAttribute in itemAttributes"
        :key="`${itemAttribute.id}-global`"
        :value="itemAttribute.id"
        aria-role="listitem"
      >
        {{ itemAttribute.display }}
      </b-dropdown-item>
    </b-dropdown>
  </validated-input>
</template>

<script>
  import { itemAttributes } from '@/constants/items';

  export default {
    name: 'ExcludedItemAttributesDropdown',

    props: {
      value: {
        type: Array,
        default: () => []
      },
      readOnly: {
        type: Boolean,
        default: false
      },
      formName: {
        type: String,
        required: true
      }
    },

    data() {
      return {
        itemAttributes
      };
    },

    computed: {
      excludedItemAttributeIds: {
        get() {
          return this.value;
        },

        set(value) {
          this.$emit('input', value);
        }
      }
    }
  };

</script>
