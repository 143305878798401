<template>
  <validated-form
    ref="form"
    auto-focus
    form-id="addPosForm"
    @valid-submit="submitHandler"
  >
    <modal-card
      :title="`${!currentPos ? 'Assign' : 'Update'} Point of Sale`"
      :subtitle="currentPos && currentPos.posType.name"
    >
      <b-loading :active="fetchingPosTypes" :is-full-page="false" />

      <template v-if="!currentPos">
        <b-message class="mar-b is-size-6 has-shadow is-compact" type="is-primary">
          <template v-if="!storeId">
            Assigning point of sale providers to a merchant will require adding configurations at the
            merchant level and location level and in some cases, Authorization to connect to the provider.
          </template>
          <template v-else>
            Assigning point of sale providers to a location will require adding
            configurations at this location and in some cases, Authorization to connect to the provider.
          </template>
        </b-message>
      </template>

      <validated-input
        v-if="!currentPos"
        name="selectedPosId"
        rules="required"
        label="POS Provider"
      >
        <b-dropdown
          v-model="selectedPosId"
          class="has-extra-shadow"
          expanded
          :mobile-modal="false"
          scrollable
          :max-height="290"
        >
          <dropdown-button
            slot="trigger"
            placeholder="Select a POS Provider..."
            :value="selectedPosName"
          />
          <b-dropdown-item
            v-for="posType in filteredPosTypeOptions"
            :key="posType.id"
            :value="posType.id"
          >
            {{ posType.name }}
          </b-dropdown-item>
        </b-dropdown>
      </validated-input>

      <validated-text-input
        v-model="posUrl"
        class=""
        name="url"
        type="text"
        rules="required|validUrl"
        label="URL"
      />

      <validated-text-input
        v-model="externalInvoiceName"
        name="externalInvoiceName"
        type="text"
        rules="max:128"
        label="External Invoice Name"
      />

      <validated-text-input
        v-model="posConfig"
        class="configuration"
        :has-icon="false"
        label="Configuration"
        name="posConfig"
        type="textarea"
        rows="20"
        :spellcheck="false"
        maxlength="16384"
        monospaced
        rules="required|validJSON"
      />

      <template #footer>
        <div class="buttons all-bold">
          <b-button
            rounded
            @click="$_confirmCloseModal({ programmatic: true })"
          >
            Cancel
          </b-button>
          <b-button
            v-tabbable
            rounded
            native-type="submit"
            type="is-primary"
            :loading="isSubmitting"
          >
            {{ currentPos ? 'Save' : 'Assign' }}
          </b-button>
        </div>
      </template>
    </modal-card>
  </validated-form>
</template>

<script>
  import confirmModalCloseMixin from '@/mixins/confirm-modal-close';
  import PosTypeOption from '@/store/classes/PosTypeOption';
  import PosConfiguration from '@/store/classes/PosConfiguration';
  import Store from '@/store/classes/Store';
  import beautify from 'json-beautify';

  export default {
    name: 'AddPosConfigurationModal',

    mixins: [confirmModalCloseMixin],

    props: {
      existingPosIds: {
        type: Array,
        required: true
      },

      merchantId: {
        type: Number,
        required: true
      },

      storeId: {
        type: Number,
        default: null
      },

      currentPos: {
        type: Object,
        required: false,
        default: null
      }
    },

    data() {
      return {
        selectedPosId: null,
        posConfig: '{}',
        posUrl: '',
        externalInvoiceName: ''
      };
    },
    computed: {
      isSubmitting() {
        return PosConfiguration.$state().submitting;
      },

      allPosTypeOptions() {
        return PosTypeOption.all();
      },

      fetchingPosTypes() {
        return PosTypeOption.$state().fetching;
      },

      filteredPosTypeOptions() {
        return this.allPosTypeOptions
          .filter(pg => !this.existingPosIds.includes(pg.id))
          .sort((a, b) => ((a.name < b.name) ? -1 : 1));
      },

      selectedPosName() {
        return this.filteredPosTypeOptions.find(x => x.id === this.selectedPosId)?.name;
      },

      store() {
        return Store.find(this.storeId);
      }
    },

    created() {
      this.onCreated();
    },

    methods: {
      onCreated() {
        this.fetchPosTypes();

        if (this.currentPos) {
          this.posConfig = beautify(this.currentPos.metadata, null, 2, 0);
          this.posUrl = this.currentPos.url;
          this.externalInvoiceName = this.currentPos.externalInvoiceName;
        }
      },

      async fetchPosTypes() {
        try {
          await PosTypeOption.fetchPosTypes();
        }
        catch (error) {
          this.$_onRequestError({ toastOptions: { message: 'Unable to fetch pos types' }, error });
        }
      },

      async addPosConfiguration() {
        try {
          const newPosConfiguration = {
            posTypeId: this.selectedPosId,
            metadata: beautify(JSON.parse(this.posConfig), null, 0),
            url: this.posUrl,
            externalInvoiceName: this.externalInvoiceName
          };

          if (this.storeId) {
            newPosConfiguration.storeId = this.storeId;
          }

          await PosConfiguration.addPosConfiguration({
            merchantId: this.merchantId,
            merchantPosConfiguration: newPosConfiguration
          });

          if (this.storeId) {
            await Store.updateStore({ ...this.store, posTypeId: this.selectedPosId });
          }

          this.$_onRequestSuccess({
            toastOptions: { message: 'Successfully added POS configuration!' },
            options: { closeParent: true }
          });
        }

        catch (error) {
          this.$_onRequestError({
            toastOptions: { message: 'An error occured while adding your new POS configurations' },
            error
          });
        }
      },

      async updatePosConfiguration() {
        try {
          await PosConfiguration.updatePosConfiguration({
            id: this.currentPos.id,
            metadata: beautify(JSON.parse(this.posConfig), null, 0),
            url: this.posUrl,
            externalInvoiceName: this.externalInvoiceName
          });

          this.$_onRequestSuccess({
            toastOptions: { message: 'Successfully updated your POS configuration!' },
            options: { closeParent: true }
          });
        }
        catch (error) {
          this.$_onRequestError({
            error,
            toastOptions: {
              message: 'There was an error updating your POS configuration'
            }
          });
        }
      },

      submitHandler() {
        if (!this.currentPos) {
          this.addPosConfiguration();
        }
        else {
          this.updatePosConfiguration();
        }
      }
    }
  };
</script>

<style lang="sass" scoped>
   .modal-card
    overflow: visible

    .modal-card-body
      overflow: visible
</style>
