<template>
  <validated-form
    ref="form"
    auto-focus
    form-id="locationContent"
    @valid-submit="confirmSaveLocationContent"
  >
    <modal-card
      :title="`${locationContent ? 'Edit' : 'Add'} Location Content`"
      :subtitle="locationContent ? locationContent.storeName : null"
      modal-card-body-class="is-paddingless"
    >
      <!--
        Once we add support for bulk-add/edit we will always display this div,
        but for now it will only display when adding content for new locations.
      -->
      <div class="has-background-white-bis pad-md pad-b-lg mar-b-lg has-border-bottom has-border-grey-lightest">
        <b-switch
          v-if="locationContent"
          v-model="applyChangesToOtherLocations"
          class="left-label label"
        >
          Apply changes to other locations
        </b-switch>

        <regional-location-dropdown
          v-if="!locationContent || applyChangesToOtherLocations"
          v-model="selectedStoreIds"
          name="selectedStoreIds"
          :stores="stores"
          :disabled-ids="disabledStoreIds"
          :label="applyChangesToOtherLocations ? '' : 'Add Locations'"
          :hide-label="applyChangesToOtherLocations"
          :class="applyChangesToOtherLocations && 'mar-t'"
          select-all-mode="includeAll"
          required
          expanded
        />
      </div>

      <div :class="['is-grid gap-lg pad-x-md pad-b-md']">
        <validated-text-input
          v-model="form.title"
          label="Title"
          type="text"
          name="title"
          label-position="on-border"
          placeholder="Enter a title..."
          data-test-id="title-input"
        />

        <validated-text-input
          v-model="form.description"
          label="Description"
          type="text"
          name="description"
          label-position="on-border"
          placeholder="Enter a description..."
          data-test-id="description-input"
        />
        <content-property-input
          v-for="property in properties"
          :key="property.id"
          v-model="findMetadataByContentTypePropertyId(property.id).value"
          :content-type-property="property"
          :placeholder="defaultMetadataByProperty[property.id].value"
          :data-test-id="`property-${property.id}-input`"
          :image-file="metadataImageFiles[property.id]"
          @delete-image="deleteMetadataImage"
          @update-image="updateMetadataImage"
        />
      </div>

      <template #footer>
        <div class="buttons all-bold">
          <b-button
            rounded
            @click="$_confirmCloseModal({ programmatic: true })"
          >
            Cancel
          </b-button>
          <div
            v-tippy="{
              content: disabledMessage,
              onShow: () => !isDifferent || !selectedStoreIds.length
            }"
          >
            <b-button
              v-tabbable
              rounded
              native-type="submit"
              type="is-primary"
              :loading="isSubmitting"
              :disabled="!isDifferent || !selectedStoreIds.length"
            >
              <span v-if="!locationContent">
                Add {{ `Location${selectedStoreIds.length > 1 ? 's' : ''}` }}
              </span>
              <span v-else>
                Save Changes
              </span>
            </b-button>
          </div>
        </div>
      </template>
    </modal-card>
  </validated-form>
</template>

<script>
  import AlertModal from '@/components/globals/alert-modal.vue';

  import confirmModalCloseMixin from '@/mixins/confirm-modal-close';

  import logger from '@/services/logger';

  import MerchantContent from '@/store/classes/MerchantContent';
  import Store from '@/store/classes/Store';

  import contentPropertyInput from './content-property-input.vue';

  export default {
    name: 'LocationContentModal',

    components: { contentPropertyInput },

    mixins: [confirmModalCloseMixin],

    props: {
      properties: {
        type: Array,
        required: true
      },
      defaultMetadataByProperty: {
        type: Object,
        required: true
      },
      merchantContent: {
        type: Object,
        required: true
      },
      locationContent: {
        type: Object,
        default: null
      },
      existingStoreIds: {
        type: Array,
        default: () => []
      }
    },

    data() {
      return {
        form: {},
        selectedStoreIds: [],
        metadataImageFiles: {},
        isSubmitting: false,
        applyChangesToOtherLocations: false
      };
    },

    computed: {
      stores() {
        return Store.query().orderBy(s => s.description.toLowerCase()).get();
      },

      isDifferent() {
        const content = this.locationContent || this.merchantContent;
        return JSON.stringify(this.form) !== JSON.stringify(content) || (this.locationContent && this.selectedStoreIds.length > 1);
      },

      disabledMessage() {
        if (!this.locationContent) {
          if (!this.isDifferent && !this.selectedStoreIds.length) {
            return 'You must select at least one location and make changes to the content in order to save';
          }
          else if (!this.selectedStoreIds.length) {
            return 'You must select at least one location in order to save';
          }
          else {
            return 'You must make changes to the content in order to save';
          }
        }
        else if (this.locationContent && !this.isDifferent && !this.selectedNewStoreIds.length) {
          return 'You must select at least one additional location or make changes to the content in order to save';
        }
        else {
          return '';
        }
      },

      disabledStoreIds() {
        if (this.locationContent || this.applyChangesToOtherLocations) {
          return [this.locationContent.storeId];
        }
        return [];
      },

      selectedExistingStoreIds() {
        return this.selectedStoreIds.filter(id => this.existingStoreIds.includes(id));
      },

      selectedNewStoreIds() {
        return this.selectedStoreIds.filter(id => !this.existingStoreIds.includes(id));
      }
    },

    created() {
      this.onCreated();
    },

    methods: {
      onCreated() {
        const content = this.locationContent || this.merchantContent;
        this.form = this.$clone(content);

        if (this.locationContent) {
          this.selectedStoreIds = [this.locationContent.storeId];
        }

        this.properties.forEach(async (p) => {
          if (p.isImage) {
            try {
              if (!this.locationContent) {
                /**
                 * if creating new location content, check to see if there is an existing
                 * image for the property on the merchant content record
                 */
                const index = this.form.merchantContentMetadata.findIndex(lcm => lcm.contentTypePropertyId === p.id);
                const imageUrl = this.form.merchantContentMetadata[index].value;

                if (imageUrl) {
                  /**
                   * if there is, clone the existing merchant content image
                   * to a new local file that we can upload to get a new unique image url
                   */
                  const clonedImageFile = await this.urlToObject(imageUrl, p.id);

                  this.metadataImageFiles = {
                    ...this.metadataImageFiles,
                    [p.id]: clonedImageFile
                  };
                  this.form.merchantContentMetadata[index].value = null;
                }
              }
            }
            catch (error) {
              logger.error(error);
            }
          }
        });
      },

      async urlToObject(url, propertyId) {
        const response = await fetch(url);

        const blob = await response.blob();
        const file = new File([blob], `property-${propertyId}-image.${blob.type.split('/')[1]}`, { type: blob.type });

        return file;
      },

      deleteMetadataImage(contentTypePropertyId) {
        const contentMetadata = this.findMetadataByContentTypePropertyId(contentTypePropertyId);
        contentMetadata.value = null;
        this.metadataImageFiles[contentTypePropertyId] = null;
      },

      updateMetadataImage({ contentTypePropertyId, imageFile }) {
        this.metadataImageFiles = {
          ...this.metadataImageFiles,
          [contentTypePropertyId]: imageFile
        };
      },

      findMetadataByContentTypePropertyId(contentTypePropertyId) {
        return this.form.merchantContentMetadata.find(mcm => mcm.contentTypePropertyId === contentTypePropertyId);
      },

      async createMetadataImages(newMetadataImages) {
        try {
          await Promise.all(newMetadataImages.map(async ([contentTypePropertyId, imageFile]) => {
            if (imageFile) {
              const imageUrl = await MerchantContent.createMerchantContentMetadataImage(imageFile);
              const contentMetadata = this.findMetadataByContentTypePropertyId(parseInt(contentTypePropertyId, 10));
              contentMetadata.value = imageUrl;
            }
          }));
        }
        catch (error) {
          this.$_onRequestError({
            error,
            toastOptions: {
              message: 'There was an error uploading your content images'
            }
          });
        }
      },

      confirmSaveLocationContent() {
        if ((this.applyChangesToOtherLocations || !this.locationContent) && this.selectedExistingStoreIds.length) {
          const storeMap = new Map(this.stores.map(s => [s.storeId, `<b>${s.description}</b>`]));
          const existingStores = this.selectedExistingStoreIds.map(id => storeMap.get(id));

          const newContentMessage = this.selectedNewStoreIds.length
            ? `You are about to create content for ${this.selectedNewStoreIds.length} new location${this.selectedNewStoreIds.length > 1 ? 's' : ''}`
            : '';

          const existingContentMessage = `<b>${existingStores.length}</b> existing location${existingStores.length > 1 ? 's' : ''}: `
            + `${existingStores.join(', ')}. This will overwrite any existing content for `
            + `${existingStores.length > 1 ? 'these locations' : 'this location'}`;

          const messageStem = newContentMessage ? `${newContentMessage} and save` : 'You are about to save';
          const message = `${messageStem} content for ${existingContentMessage}.`;

          this.$buefy.modal.open({
            parent: this,
            component: AlertModal,
            hasModalCard: true,
            trapFocus: true,
            customClass: 'auto-width',
            canCancel: ['escape', 'outside'],
            props: {
              autoWidth: true,
              title: 'Save Location Content',
              message,
              secondaryMessage: 'Are you sure you\'d like to proceed?',
              horizontal: true,
              showCloseButton: false,
              type: 'is-warning',
              icon: 'warning',
              buttons: [
                { text: 'Cancel' },
                {
                  text: 'Yes',
                  primary: true,
                  onClick: this.saveLocationContent
                }
              ]
            }
          });
        }
        else {
          this.saveLocationContent();
        }
      },

      async saveLocationContent() {
        this.isSubmitting = true;

        const newMetadataImages = Object.entries(this.metadataImageFiles);
        if (newMetadataImages) {
          await this.createMetadataImages(newMetadataImages);
          this.metadataImageFiles = {};
        }

        try {
          await MerchantContent.bulkCreateOrUpdateMerchantContent({
            merchantContent: { ...this.form },
            storeIds: this.selectedStoreIds
          });

          this.$_onRequestSuccess({
            toastOptions: {
              message: `Successfully saved content for ${
                this.selectedStoreIds.length > 1
                  ? `${this.selectedStoreIds.length} locations`
                  : `<b>${this.stores.find(s => s.storeId === this.selectedStoreIds[0]).description}</b>`
              }`
            },
            options: { closeParent: true }
          });
        }
        catch (error) {
          this.$_onRequestError({
            error,
            toastOptions: {
              message: 'There was an error saving your location content'
            }
          });
        }
        finally {
          this.isSubmitting = false;
        }
      }
    }
  };
</script>
