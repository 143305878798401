import http from '@/services/http';
import { Model } from '@vuex-orm/core';


export default class GiftCardTransactionType extends Model {
  static entity = 'giftCardTransactionTypes'


  // FIELDS //////////////////////
  static fields() {
    return {
      id: this.attr(''),
      name: this.attr('')
    };
  }


  // STATE //////////////////////
  static state() {
    return {
      fetching: false
    };
  }

  static $state() {
    return this.store().state.entities.giftCardTransactionTypes;
  }


  // STATIC METHODS //////////////////////
  static transactionTypesWithoutBalanceInquiry() {
    return this.query()
      .where(type => type.name !== 'BalanceInquiry')
      .get();
  }

  // ACTIONS //////////////////////
  static async fetchGiftCardTransactionTypes() {
    try {
      this.commit((state) => {
        state.fetching = true;
      });

      const alreadyFetched = this.all().length > 0;

      if (!alreadyFetched) {
        const { data } = await http.get('gift_card_transaction_types');

        this.create({ data: data.giftCardTransactionTypes });
      }
    }
    catch (error) {
      throw error;
    }
    finally {
      this.commit((state) => {
        state.fetching = false;
      });
    }
  }
}
