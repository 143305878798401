<template>
  <div class="merchant-list-page">
    <hero-banner>
      <h1 class="title is-2">Merchants</h1>
      <template v-if="$can('create', 'Merchant')" #right>
        <b-button
          rounded
          inverted
          size="is-medium"
          type="is-primary"
          icon-left="plus"
          @click="openMerchantModal"
        >
          New Merchant
        </b-button>
      </template>
    </hero-banner>

    <div class="section">
      <div class="container">
        <div class="card">
          <searchable-table
            v-slot="{ filteredData }"
            auto-focus-search
            :table-data="allMerchants"
            :search="{
              placeholder: 'Search by Merchant Name or ID',
              keys: ['name', 'id']
            }"
          >
            <b-table
              :data="isLoadingMerchants ? [] : filteredData"
              class="is-middle-aligned"
              :paginated="allMerchants.length > 20"
              per-page="20"
              pagination-simple
              hoverable
              :row-class="({id}) => merchants.length && recentMerchantIds.includes(id) && 'recent-merchant'"
            >
              <b-table-column v-slot="{ row: { id, name } }" sortable field="name" label="Name">
                <span class="link merchant-name" @click="selectMerchant(id)">
                  <span>{{ name }}</span>
                  <b-icon
                    v-if="merchants.length && recentMerchantIds.includes(id)"
                    class="mar-l-xs star"
                    icon="star"
                    size="is-small"
                  />
                  <b-icon class="arrow mar-l-xs" icon="arrow-right" />
                </span>
              </b-table-column>

              <b-table-column
                v-slot="{ row: { isActive, id } }"
                centered
                label="Active"
                width="200"
                sortable
                field="isActive"
              >
                <b-icon
                  v-if="isUpdatingActivationStatus(id)"
                  icon="spinner-third"
                  class="spin"
                />
                <b-icon
                  v-else
                  :icon="isActive ? 'check' : 'times'"
                  :type="isActive ? 'is-success' : 'is-danger'"
                  size="is-small"
                />
              </b-table-column>

              <b-table-column
                v-slot="{ row }"
                centered
                cell-class="actions"
                label="Actions"
                width="1"
              >
                <b-dropdown aria-role="list" position="is-bottom-left" :mobile-modal="false">
                  <b-button slot="trigger" class="is-transparent" type="is-white">
                    <b-icon icon="ellipsis-v" pack="far" size="is-small" />
                  </b-button>
                  <b-dropdown-item has-link>
                    <router-link :to="{ name: 'merchantDetail', params: { merchantId: row.id } }">
                      <b-icon icon="wrench" />
                      Configure
                    </router-link>
                  </b-dropdown-item>
                  <template v-if="$can('manage', 'all')">
                    <hr class="dropdown-divider">
                    <b-dropdown-item
                      :class="row.isActive ? 'is-danger' : 'is-success'"
                      @click="$_confirmUpdateMerchantActivation(row)"
                    >
                      <b-icon :icon="row.isActive ? 'ban' :'bolt'" />
                      {{ row.isActive ? 'Deactivate' : 'Activate' }}
                    </b-dropdown-item>
                  </template>
                </b-dropdown>
              </b-table-column>
              <template slot="empty">
                <empty-table-loader :loading="isLoadingMerchants" />
              </template>

            </b-table>
          </searchable-table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import deactivateMerchantMixin from '@/mixins/deactivate-merchant';

  import { mapGetters } from 'vuex';

  import confirmModalCloseMixin from '@/mixins/confirm-modal-close';

  import Merchant from '@/store/classes/Merchant';

  import merchantModal from './merchant-modal.vue';



  export default {
    name: 'MerchantList',

    mixins: [
      confirmModalCloseMixin,
      deactivateMerchantMixin
    ],

    metaInfo() {
      return {
        title: 'Merchants'
      };
    },

    computed: {
      ...mapGetters('session', ['isCardfreeAdmin']),

      merchants() {
        return Merchant.query().where(({ id }) => !this.recentMerchantIds.includes(id)).get();
      },

      recentMerchants() {
        return Merchant.query().whereIdIn(this.recentMerchantIds).get();
      },

      allMerchants() {
        return [...this.recentMerchants, ...this.merchants];
      },

      recentMerchantIds() {
        return Merchant.$state().recentMerchantIds;
      },

      isLoadingMerchants() {
        return Merchant.$state().fetchingAll;
      }
    },

    created() {
      this.onCreated();
    },

    methods: {
      onCreated() {
        Merchant.setSelectedMerchantId(null);
        this.fetchMerchants();
      },

      async fetchMerchants() {
        try {
          await Merchant.fetchMerchants();
        }
        catch (error) {
          this.$_onRequestError({
            error,
            toastOptions: { message: 'Unable to fetch merchants' }
          });
        }
      },

      async fetchMerchant(merchantId) {
        try {
          await Merchant.fetchMerchant(merchantId, { isFetchingSelectedMerchant: true });
        }
        catch (error) {
          this.$_onRequestError({
            error,
            toastOptions: { message: 'Unable to fetch merchant' }
          });
        }
      },

      selectMerchant(merchantId) {
        Merchant.setSelectedMerchantId(merchantId);
        this.$router.push({ name: 'home' });
        this.fetchMerchant(merchantId);
      },

      openMerchantModal() {
        this.$buefy.modal.open({
          parent: this,
          component: merchantModal,
          hasModalCard: true,
          trapFocus: true,
          canCancel: false,
          customClass: 'auto-width',
          props: { mode: 'create' }
        });
      },

      isUpdatingActivationStatus(id) {
        return Merchant.$state().submittingActivationChangeId === id;
      }
    }
  };
</script>

<style lang="sass" scoped>
  .merchant-name
    position: relative
    .arrow
      transition: 200ms
      transform: translateX(-$size-extra-small)
      opacity: 0
    .star
      position: absolute
      transition: 333ms
      top: 50%
      transform: translate(0, -50%)
      text-shadow: 0px 0px 7px $white
      color: darken($warning, 15)
      font-size: 0.88rem
    &:hover
      .arrow
        transform: translateX(0)
        opacity: 1
      .star
        transform: translate(2rem, -50%) scale(1.66) rotate(180deg)
        opacity: 0

  ::v-deep
    .recent-merchant
      background: $primary-lightest
      &:hover
        background-color: darken($primary-lightest, 2) !important

</style>
