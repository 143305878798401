<template>
  <section-wrapper
    title="Receipt Locations"
    :loading="isFetchingReceiptLocations || isFetchingFulfillmentTypes || isFetchingPlatformTypes"
  >
    <b-table
      :data="receiptLocations"
      class="is-middle-aligned flex-grow"
      :loading="isDeletingReceiptLocations"
      :mobile-cards="false"
    >
      <template #empty>
        <p class="has-text-centered pad is-size-5 has-text-grey-light">
          No Receipt Locations Configured
        </p>
      </template>
      <b-table-column v-slot="{ row }" field="fulfillmentType" label="Fulfillment Type">
        {{ getFulfillmentTypeName(row.fulfillmentTypeId) }}
      </b-table-column>

      <b-table-column v-slot="{ row }" field="platformType" label="Platform Type">
        {{ getPlatformTypeName(row.platformTypeId) }}
      </b-table-column>

      <b-table-column v-slot="{ row }" field="orderType" label="Order Type">
        {{ orderTypes[row.orderTypeId] }}
      </b-table-column>

      <b-table-column v-slot="{ row }" field="receiptPrinterId" label="Receipt Printer Id">
        {{ row.receiptPrinterId }}
      </b-table-column>

      <b-table-column
        v-slot="{ row }"
        centered
        cell-class="actions"
        label="Actions"
        width="1"
      >
        <div class="is-flex dist-x-xs">
          <b-button class="is-transparent" type="is-white" @click="openReceiptLocation({ mode: 'update', receiptLocation: row })">
            <b-icon icon="pencil" size="is-small" />
          </b-button>
          <b-button class="is-transparent" type="is-white" @click="openRemoveReceiptLocationConfirmation(row)">
            <b-icon icon="trash-alt" type="is-danger" size="is-small" />
          </b-button>
        </div>
      </b-table-column>
    </b-table>

    <div class="pad-x pad-y-sm has-border-top has-border-grey-lighter">
      <b-button
        :disabled="hasAllReceiptLocations"
        class="is-transparent"
        icon-left="plus"
        type="is-primary"
        inverted
        @click="openReceiptLocation"
      >
        Receipt Location
      </b-button>
    </div>
  </section-wrapper>
</template>

<script>
  import StoreReceiptLocation from '@/store/classes/StoreReceiptLocation';
  import FulfillmentType from '@/store/classes/FulfillmentType';
  import PlatformType from '@/store/classes/PlatformType';
  import merchantMixin from '@/mixins/merchant';
  import addEditReceiptLocationModal from './add-edit-receipt-location-modal.vue';
  import capitalCase from '@/helpers/capitalCase';

  export default {
    name: 'StoreReceiptLocations',

    mixins: [merchantMixin],

    data() {
      return {
        capitalCase,
        orderTypes: {
          1: 'Order Ahead',
          2: 'Pay And Fulfill',
          3: 'Order And Pay',
          4: 'Third Party Order Ahead',
          5: 'Tip Only',
          6: 'External Order'
        }
      };
    },

    computed: {
      storeId() {
        return Number(this.$route.params.storeId);
      },

      fulfillmentTypes() {
        return FulfillmentType.all();
      },

      isFetchingFulfillmentTypes() {
        return FulfillmentType.$state().fetching;
      },

      isFetchingPlatformTypes() {
        return PlatformType.$state().fetching;
      },

      platformTypes() {
        return PlatformType.all();
      },

      receiptLocations() {
        return StoreReceiptLocation.query().where('storeId', this.storeId).get();
      },

      hasAllReceiptLocations() {
        return this.receiptLocations.length === this.fulfillmentTypes.length;
      },

      isFetchingReceiptLocations() {
        return StoreReceiptLocation.$state().fetching;
      },

      isDeletingReceiptLocations() {
        return StoreReceiptLocation.$state().deleting;
      }
    },

    methods: {
      onMerchantLoad() {
        this.fetchStoreReceiptLocations();
        this.fetchFulfillmentTypes();
        this.fetchPlatformTypes();
      },

      getFulfillmentTypeName(fulfillmentTypeId) {
        const fulfillmentType = this.fulfillmentTypes.find(ft => ft.id === fulfillmentTypeId);
        return fulfillmentType ? capitalCase(fulfillmentType.name) : '';
      },

      getPlatformTypeName(platformTypeId) {
        const platformType = this.platformTypes.find(pt => pt.id === platformTypeId);
        return platformType ? capitalCase(platformType.name) : '';
      },

      async fetchFulfillmentTypes() {
        try {
          await FulfillmentType.fetchFulfillmentTypes();
        }
        catch (error) {
          this.$_onRequestError({ error, toastOptions: { message: 'Unable to fetch available fulfillment types' } });
        }
      },

      async fetchPlatformTypes() {
        try {
          await PlatformType.fetchPlatformTypes();
        }
        catch (error) {
          this.$_onRequestError({ error, toastOptions: { message: 'Unable to fetch available platform types' } });
        }
      },

      async fetchStoreReceiptLocations() {
        try {
          await StoreReceiptLocation.fetchStoreReceiptLocations(this.storeId);
        }
        catch (error) {
          this.$_onRequestError({ error, toastOptions: { message: 'Unable to fetch store receipt locations' } });
        }
      },

      async deleteStoreReceiptLocation(receiptLocation) {
        const fulfillmentTypeName = this.getFulfillmentTypeName(receiptLocation.fulfillmentTypeId);
        try {
          await StoreReceiptLocation.deleteStoreReceiptLocation(receiptLocation.id);

          this.$_onRequestSuccess({
            toastOptions: { message: `<b>${fulfillmentTypeName}</b> has been successfully removed!` }
          });
        }
        catch (error) {
          this.$_onRequestError({ error, toastOptions: { message: `Unable to remove <b>${fulfillmentTypeName}</b>` } });
        }
      },

      openReceiptLocation({ mode, receiptLocation } = {}) {
        this.$buefy.modal.open({
          parent: this,
          component: addEditReceiptLocationModal,
          hasModalCard: true,
          trapFocus: true,
          canCancel: false,
          customClass: 'auto-width',
          props: {
            storeId: this.storeId, currentReceiptLocations: this.receiptLocations, receiptLocation, mode
          }
        });
      },

      openRemoveReceiptLocationConfirmation(receiptLocation) {
        const fulfillmentTypeName = this.getFulfillmentTypeName(receiptLocation.fulfillmentTypeId);

        this.$buefy.dialog.confirm({
          title: 'Delete Receipt Location',
          message: `Are you sure you want to delete <b>${fulfillmentTypeName}</b>?`,
          onConfirm: () => this.deleteStoreReceiptLocation(receiptLocation),
          confirmText: 'Delete',
          type: 'is-danger',
          hasIcon: true,
          icon: 'trash-alt'
        });
      }
    }
  };
</script>
