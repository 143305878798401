<template>
  <div>
    <div
      v-for="category in searchResults"
      :key="`category-${category.id}`"
      :data-category-id="category.id"
    >
      <div class="row">
        <span class="pad-y-sm">
          <span class="has-text-weight-bold">{{ category.displayName }}</span>
        </span>
        <span />
        <span class="align-center justify-end">
          <b-button
            v-if="category.hasSchedule && menuType"
            v-tippy="{ content: 'Restricted Availability', placement: 'left', delay: [300, 0] }"
            class="is-transparent"
            @click="emitSharedModGroupsSearchAction({ actionName: 'open-category-modal', args: { categoryId: category.id, mode: 'update', defaultTabIndex: 1 } })"
          >
            <b-icon icon="calendar" pack="far" />
          </b-button>

          <template v-if="menuType">
            <tippy
              v-if="$can('update', 'MenuCategory') && $_menuPermissions.MANAGE_MENU_TYPE"
              placement="left"
              max-width="500"
            >
              <template #trigger>
                <b-button class="is-transparent" @click="emitSharedModGroupsSearchAction({ actionName: 'confirm-remove-from-menu-type', args: { category } })">
                  <b-icon icon="minus-circle" type="is-danger" size="is-small" />
                </b-button>
              </template>

              <div class="is-size-6">
                Remove <span class="has-text-weight-semibold">{{ category.displayName }}</span> from {{ menuType.displayName }} Menu
              </div>
            </tippy>
          </template>

          <template v-else>
            <b-button class="is-transparent" @click="emitSharedModGroupsSearchAction({ actionName: 'open-category-modal', args: { categoryId: category.id, mode: 'update' } })">
              <b-icon v-if="$can('update', 'MenuCategory') && $_menuPermissions.EDIT_RESOURCE" icon="pencil" size="is-small" />
              <span v-else>View</span>
            </b-button>
            <b-dropdown
              v-if="($can('destroy', 'MenuCategory') && $_menuPermissions.REMOVE_RESOURCE)"
              aria-role="list"
              position="is-bottom-left"
              :mobile-modal="false"
            >
              <b-button slot="trigger" class="is-transparent" type="is-white">
                <b-icon icon="ellipsis-v" pack="far" size="is-small" />
              </b-button>
              <template v-if="$can('destroy', 'MenuCategory') && $_menuPermissions.REMOVE_RESOURCE">
                <b-dropdown-item class="is-danger" @click="emitSharedModGroupsSearchAction({ actionName: 'open-delete-category-confirmation', args: category })">
                  <b-icon icon="trash-alt" class="mar-r-sm" size="is-small" />
                  Delete Category
                </b-dropdown-item>
              </template>
            </b-dropdown>
          </template>
        </span>
      </div>
    </div>
  </div>
</template>

<script>
  import merchantMixin from '@/mixins/merchant';
  import featurePermissionsMixin from '@/mixins/featurePermissions';

  export default {
    name: 'CategorySearchRow',

    mixins: [merchantMixin, featurePermissionsMixin],

    props: {
      searchResults: {
        type: Array,
        default: () => []
      },

      menuType: {
        type: Object,
        default: null
      }
    },

    methods: {
      emitSharedModGroupsSearchAction(args) {
        this.$emit('shared-mod-group-search-action', args);
      }
    }
  };
</script>
