const daysBackTypes = {
  LIFETIME: { value: 'Lifetime', display: 'Customer\'s Lifetime' },
  FOURTEEN_DAYS: { value: 'Last14Days', display: 'Last 14 Days' },
  THIRTY_DAYS: { value: 'Last30Days', display: 'Last 30 Days' },
  SIXTY_DAYS: { value: 'Last60Days', display: 'Last 60 Days' },
  NINENTY_DAYS: { value: 'Last90Days', display: 'Last 90 Days' }
};

const equalityOperators = {
  LESS_THAN: 'LessThan',
  LESS_THAN_OR_EQUAL_TO: 'LessThanOrEqualTo',
  EQUAL_TO: 'EqualTo',
  GREATER_THAN: 'GreaterThan',
  GREATER_THAN_OR_EQUAL_TO: 'GreaterThanOrEqualTo',
  BETWEEN: 'Between'
};

const percentileRanges = {
  BOTTOM: { value: 'Bottom', display: 'Bottom' },
  MIDDLE: { value: 'Middle', display: 'Middle' },
  TOP: { value: 'Top', display: 'Top' }
};

// ------------- Criteria types -------------
const criteriaTypes = {
  LOYALTY: 'loyalty',
  ORDERING: 'ordering',
  ACCOUNT: 'account'
};

const criteria = {
  ORDER_TYPE_IDS: 'orderTypeIds',
  AVERAGE_ORDER_TOTAL_RANGE: 'averageOrderTotalRange',
  LIFETIME_ORDER_TOTAL_EQUALITY: 'lifetimeOrderTotalEquality',
  LIFETIME_ORDER_TOTAL_RANGE: 'lifetimeOrderTotalRange',
  DAYS_SINCE_LAST_ORDER: 'daysSinceLastOrder',
  DAYS_SINCE_LAST_LOGIN: 'daysSinceLastLogin',
  REGISTERED_DATE_RANGE: 'registeredDateRange',
  BIRTHDAY_DATE_RANGE: 'birthdayDateRange',
  DAYS_SINCE_REGISTERED: 'daysSinceRegistered',
  ENROLLED_IN_LOYALTY: 'enrolledInLoyalty',
  LOYALTY_POINT_BALANCE: 'loyaltyPointBalance',
  MOST_VISITED_STORE: 'mostVisitedStoreId',
  LAST_VISITED_STORE: 'lastVisitedStoreId',
  FAVORITE_STORE: 'favoriteStoreId',
  OFFERS_REDEEMED: 'offersRedeemed',
  ORDER_AVERAGES: 'orderAverages',
  ORDER_COUNTS: 'orderCounts',
  ORDER_ITEM_CATEGORY: 'orderItemCategory',
  LOYALTY_PROGRAM_TIER: 'loyaltyProgramTier',
  FAVORITE_ITEM: 'favoriteItem',
  SPEND_PERCENTILE: 'spendPercentile',
  ORDER_PLACED_AT_STORE: 'orderPlacedAtStore',
  ORDER_TOTALS: 'orderTotals',
  ORDER_ITEM_ATTRIBUTE: 'orderItemAttribute',
  LAPSED_VISITS: 'lapsedVisits',
  GIFT_CARD_PURCHASE_COUNT: 'giftCardPurchaseCount',
  OPERATING_SYSTEM: 'operatingSystem'
};

const criteriaCardMetadata = {
  ORDER_TYPES: {
    criteriaKeys: [criteria.ORDER_TYPE_IDS],
    display: 'Order Types',
    type: criteriaTypes.ORDERING,
    icon: null,
    defaultCriteriaKey: criteria.ORDER_TYPE_IDS,
    defaultData: []
  },
  LIFETIME_SPENDING_AVERAGE: {
    criteriaKeys: [criteria.AVERAGE_ORDER_TOTAL_RANGE],
    display: 'Average Check Size',
    type: criteriaTypes.ORDERING,
    iconPack: 'fas',
    icon: 'square-dollar',
    defaultCriteriaKey: criteria.AVERAGE_ORDER_TOTAL_RANGE,
    defaultData: {
      aboveAmount: null,
      belowAmount: null
    }
  },
  LIFETIME_SPENDING_TOTAL: {
    criteriaKeys: [
      criteria.LIFETIME_ORDER_TOTAL_EQUALITY,
      criteria.LIFETIME_ORDER_TOTAL_RANGE
    ],
    display: 'Lifetime Spending Total',
    type: criteriaTypes.ORDERING,
    iconPack: 'fas',
    icon: 'chart-mixed-up-circle-dollar',
    defaultCriteriaKey: criteria.LIFETIME_ORDER_TOTAL_EQUALITY,
    defaultData: {
      operator: null,
      amount: null
    }
  },
  DAYS_SINCE_LAST_ORDER: {
    criteriaKeys: [criteria.DAYS_SINCE_LAST_ORDER],
    display: 'Orders in Last x Days',
    type: criteriaTypes.ORDERING,
    iconPack: 'far',
    icon: 'calendar-days',
    defaultCriteriaKey: criteria.DAYS_SINCE_LAST_ORDER,
    defaultData: 1
  },
  DAYS_SINCE_LAST_LOGIN: {
    criteriaKeys: [criteria.DAYS_SINCE_LAST_LOGIN],
    display: 'Days Since Last Login',
    type: criteriaTypes.ACCOUNT,
    iconPack: 'far',
    icon: 'user',
    defaultCriteriaKey: criteria.DAYS_SINCE_LAST_LOGIN,
    defaultData: 1
  },
  REGISTERED_DATE_RANGE: {
    criteriaKeys: [criteria.REGISTERED_DATE_RANGE],
    display: 'Registered Date Range',
    type: criteriaTypes.ACCOUNT,
    iconPack: 'far',
    icon: 'calendar-range',
    defaultCriteriaKey: criteria.REGISTERED_DATE_RANGE,
    defaultData: {
      beginDate: null,
      endDate: null
    }
  },
  BIRTHDAY_DATE_RANGE: {
    criteriaKeys: [criteria.BIRTHDAY_DATE_RANGE],
    display: 'Birthday Date Range',
    type: criteriaTypes.ACCOUNT,
    iconPack: 'far',
    icon: 'cake-candles',
    defaultCriteriaKey: criteria.BIRTHDAY_DATE_RANGE,
    defaultData: {
      beginMonthNumber: 1,
      beginDayNumber: 1,
      endMonthNumber: 1,
      endDayNumber: 2
    }
  },
  DAYS_SINCE_REGISTERED: {
    criteriaKeys: [criteria.DAYS_SINCE_REGISTERED],
    display: 'Days Since Registration',
    type: criteriaTypes.ACCOUNT,
    iconPack: 'far',
    icon: 'calendar-days',
    defaultCriteriaKey: criteria.DAYS_SINCE_REGISTERED,
    defaultData: 1
  },
  ENROLLED_IN_LOYALTY: {
    criteriaKeys: [criteria.ENROLLED_IN_LOYALTY],
    display: 'Enrolled in Loyalty',
    type: criteriaTypes.LOYALTY,
    iconPack: 'fas',
    icon: 'person-rays',
    defaultCriteriaKey: criteria.ENROLLED_IN_LOYALTY,
    defaultData: true
  },
  LOYALTY_POINT_BALANCE: {
    criteriaKeys: [criteria.LOYALTY_POINT_BALANCE],
    display: 'Loyalty Point Balance',
    type: criteriaTypes.LOYALTY,
    iconPack: 'fas',
    icon: 'tally',
    defaultCriteriaKey: criteria.LOYALTY_POINT_BALANCE,
    defaultData: {
      equality: {
        operator: null,
        amount: null
      }
    }
  },
  MOST_VISITED_STORE: {
    criteriaKeys: [criteria.MOST_VISITED_STORE],
    display: 'Most Visited Store',
    type: criteriaTypes.ACCOUNT,
    defaultData: false,
    defaultCriteriaKey: criteria.MOST_VISITED_STORE,
    iconPack: 'fas',
    icon: 'store'
  },
  LAST_VISITED_STORE: {
    criteriaKeys: [criteria.LAST_VISITED_STORE],
    display: 'Last Visited Store',
    type: criteriaTypes.ACCOUNT,
    defaultData: false,
    defaultCriteriaKey: criteria.LAST_VISITED_STORE,
    iconPack: 'fas',
    icon: 'shop'
  },
  FAVORITE_STORE: {
    criteriaKeys: [criteria.FAVORITE_STORE],
    display: 'Favorite Store',
    type: criteriaTypes.ACCOUNT,
    defaultData: false,
    defaultCriteriaKey: criteria.FAVORITE_STORE,
    iconPack: 'fas',
    icon: 'circle-star',
    tooltip: {
      content: 'Only locations that have been chosen by users as a favorite store can be selected',
      placement: 'right',
      maxWidth: 300,
      delay: [150, 0]
    }
  },
  ORDER_COUNTS: {
    criteriaKeys: [criteria.ORDER_COUNTS],
    display: 'Number of Orders',
    type: criteriaTypes.ORDERING,
    iconPack: 'fas',
    icon: 'hashtag',
    defaultCriteriaKey: criteria.ORDER_COUNTS,
    defaultData: {
      beginTime: null,
      endTime: null,
      daysBackType: null,
      range: {
        aboveAmount: null,
        belowAmount: null
      },
      equality: {
        operator: null,
        amount: null
      }
    }
  },
  OFFERS_REDEEMED: {
    criteriaKeys: [criteria.OFFERS_REDEEMED],
    display: 'Number of Offers Redeemed',
    type: criteriaTypes.LOYALTY,
    iconPack: 'fas',
    icon: 'hashtag',
    defaultCriteriaKey: criteria.OFFERS_REDEEMED,
    defaultData: {
      daysBackType: null,
      range: {
        aboveAmount: null,
        belowAmount: null
      },
      equality: {
        operator: null,
        amount: null
      }
    }
  },
  ORDER_AVERAGES: {
    criteriaKeys: [criteria.ORDER_AVERAGES],
    display: 'Average Number of Orders',
    type: criteriaTypes.ORDERING,
    iconPack: 'fas',
    icon: 'chart-simple',
    defaultCriteriaKey: criteria.ORDER_AVERAGES,
    defaultData: {
      type: null,
      range: {
        aboveAmount: null,
        belowAmount: null
      },
      equality: {
        operator: null,
        amount: null
      }
    }
  },
  ORDER_ITEM_CATEGORY: {
    criteriaKeys: [criteria.ORDER_ITEM_CATEGORY],
    display: 'Purchase from a Category',
    type: criteriaTypes.ORDERING,
    iconPack: 'fas',
    icon: 'utensils',
    defaultCriteriaKey: criteria.ORDER_ITEM_CATEGORY,
    defaultData: {
      categoryId: null,
      daysBackType: null,
      range: {
        aboveAmount: null,
        belowAmount: null
      },
      equality: {
        operator: null,
        amount: null
      }
    }
  },
  ORDER_ITEM_ATTRIBUTE: {
    criteriaKeys: [criteria.ORDER_ITEM_ATTRIBUTE],
    display: 'Purchase with an Item Tag',
    type: criteriaTypes.ORDERING,
    iconPack: 'fas',
    icon: 'tag',
    defaultCriteriaKey: criteria.ORDER_ITEM_ATTRIBUTE,
    defaultData: {
      menuAttributeId: null,
      daysBackType: null,
      range: {
        aboveAmount: null,
        belowAmount: null
      },
      equality: {
        operator: null,
        amount: null
      }
    }
  },
  LOYALTY_PROGRAM_TIER: {
    criteriaKeys: [criteria.LOYALTY_PROGRAM_TIER],
    display: 'Membership Tier',
    type: criteriaTypes.LOYALTY,
    iconPack: 'fas',
    icon: 'donate',
    defaultCriteriaKey: criteria.LOYALTY_PROGRAM_TIER,
    defaultData: {
      programTierPublicIds: null
    }
  },
  SPEND_PERCENTILE: {
    criteriaKeys: [criteria.SPEND_PERCENTILE],
    display: 'Spending by Percentile',
    type: criteriaTypes.ORDERING,
    iconPack: 'fas',
    icon: 'percent',
    defaultCriteriaKey: criteria.SPEND_PERCENTILE,
    defaultData: {
      percentile: null,
      range: null,
      daysBackType: null
    }
  },
  ORDER_PLACED_AT_STORE: {
    criteriaKeys: [criteria.ORDER_PLACED_AT_STORE],
    display: 'Visited Store',
    type: criteriaTypes.ACCOUNT,
    iconPack: 'fas',
    icon: 'house',
    defaultCriteriaKey: criteria.ORDER_PLACED_AT_STORE,
    defaultData: {
      storeIds: [],
      daysBackType: null
    }
  },
  ORDER_TOTALS: {
    criteriaKeys: [criteria.ORDER_TOTALS],
    display: 'Spending in a Date Range',
    type: criteriaTypes.ORDERING,
    iconPack: 'fas',
    icon: 'calendar',
    defaultCriteriaKey: criteria.ORDER_TOTALS,
    defaultData: {
      dateRange: {
        beginDate: null,
        endDate: null
      },
      range: {
        aboveAmount: null,
        belowAmount: null
      },
      equality: {
        operator: null,
        amount: null
      }
    }
  },
  FAVORITE_ITEM: {
    criteriaKeys: [criteria.FAVORITE_ITEM],
    display: 'Favorite Item',
    type: criteriaTypes.ORDERING,
    iconPack: 'fas',
    icon: 'star',
    defaultCriteriaKey: criteria.FAVORITE_ITEM,
    defaultData: {
      menuItemId: null
    }
  },
  LAPSED_VISITS: {
    criteriaKeys: [criteria.LAPSED_VISITS],
    display: 'Lapsed Users',
    type: criteriaTypes.ORDERING,
    iconPack: 'fas',
    icon: 'lightbulb',
    tooltip: {
      content: 'Smart Segmentation: Analyze each user’s ordering history to identify their unique visit frequency',
      placement: 'right',
      maxWidth: 300,
      delay: [150, 0]
    },
    defaultCriteriaKey: criteria.LAPSED_VISITS,
    defaultData: {
      missedNumberOfVisits: null,
      daysSinceLastLapsedNotification: null
    }
  },
  GIFT_CARD_PURCHASE_COUNT: {
    criteriaKeys: [criteria.GIFT_CARD_PURCHASE_COUNT],
    display: 'Gift Card Purchases',
    type: criteriaTypes.ORDERING,
    iconPack: 'fas',
    icon: 'gift-card',
    defaultCriteriaKey: criteria.GIFT_CARD_PURCHASE_COUNT,
    defaultData: {
      daysBackType: null,
      range: {
        aboveAmount: null,
        belowAmount: null
      },
      equality: {
        operator: null,
        amount: null
      }
    }
  },
  OPERATING_SYSTEM: {
    criteriaKeys: [criteria.OPERATING_SYSTEM],
    display: 'Mobile Operating System',
    type: criteriaTypes.ACCOUNT,
    defaultData: false,
    defaultCriteriaKey: criteria.OPERATING_SYSTEM,
    iconPack: 'fas',
    icon: 'mobile'
  }
};

export {
  criteriaTypes,
  criteria,
  criteriaCardMetadata,
  equalityOperators,
  daysBackTypes,
  percentileRanges
};
