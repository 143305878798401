import { Model } from '@vuex-orm/core';


export default class Saveable extends Model {
  // FIELDS ////////////////////
  static fields() {
    return {
      isSaved: this.attr(true)
    };
  }


  static getSaved() {
    return this.query().where('isSaved', true);
  }

  static getUnsaved() {
    return this.query().where('isSaved', false);
  }
}
