<template>
  <div class="card-group">
    <program-details data-test-id="program-details" />
    <transition name="fade-down">
      <primary-currency v-if="hasLoyaltyProgram" data-test-id="program-currency" />
    </transition>
  </div>
</template>

<script>
  import programDetails from './program-details.vue';
  import primaryCurrency from './primary-currency.vue';


  export default {
    name: 'ProgramTab',

    components: { programDetails, primaryCurrency },

    props: {
      hasLoyaltyProgram: {
        type: Boolean,
        default: false
      }
    }
  };
</script>
