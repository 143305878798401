<template>
  <div class="is-flex-column is-full-height">
    <hero-banner>
      <h1 class="title is-2">POS Menu</h1>
    </hero-banner>

    <div v-if="isFetchingStores || isFetchingPosResources" class="flex-grow is-relative">
      <b-loading active :is-full-page="false" />
    </div>

    <div v-else class="section flex-grow">
      <div class="container">
        <template v-if="posMenuCategoryCount || posMenuItemCount">
          <pos-unstructured-menu-list
            v-if="hasUnstructuredMenu"
            :open-location-import-modal="openLocationImportModal"
            :open-clear-data-modal="openClearDataModal"
          />
          <pos-menu-list
            v-else
            :open-location-import-modal="openLocationImportModal"
            :open-clear-data-modal="openClearDataModal"
          />
        </template>

        <template v-else>
          <empty-state-card
            v-if="!stores.length"
            type="is-warning"
            title="No Locations"
            message="Please add a location before importing your POS Menu"
          >
            <template #buttons>
              <router-link
                tag="button"
                class="button is-dark is-rounded"
                rounded
                :to="{ name: 'storeList' }"
              >
                Manage Locations
              </router-link>
            </template>
          </empty-state-card>

          <empty-state-card
            v-else
            type="is-primary"
            title="Import from POS"
            message="Import a pre-existing menu from your Point Of Sale"
            icon="cash-register"
          >
            <template #buttons>
              <b-button
                type="is-primary"
                rounded
                size="is-medium"
                @click="openLocationImportModal"
              >
                Import Menu
              </b-button>
            </template>
          </empty-state-card>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex';
  import merchantMixin from '@/mixins/merchant';
  import alertModal from '@/components/globals/alert-modal.vue';
  import Store from '@/store/classes/Store';
  import PosMenuCategory from '@/store/classes/PosMenuCategory';
  import PosMenuItem from '@/store/classes/PosMenuItem';
  import searchSelectModal from '@/components/globals/search-select-modal.vue';
  import posMenuList from './pos-menu-list.vue';
  import posUnstructuredMenuList from './pos-unstructured-menu-list.vue';
  import events from '@/services/events';
  import http from '@/services/http';

  export default {
    name: 'PosMenuPage',

    components: { posMenuList, posUnstructuredMenuList },

    mixins: [merchantMixin],

    computed: {
      ...mapGetters('session', ['isCardfreeAdmin']),

      stores() {
        return Store.orderByName().get();
      },

      posMenuCategoryCount() {
        return PosMenuCategory.query().count();
      },

      posMenuItemCount() {
        return PosMenuItem.query().count();
      },

      isFetchingStores() {
        return Store.$state().fetchingAll;
      },

      isFetchingPosResources() {
        return PosMenuCategory.$state().fetching || PosMenuItem.$state().isFetchingFirstTime;
      },

      hasUnstructuredMenu() {
        return this.$_selectedMerchant?.hasUnstructuredMenu;
      },

      hasStoreSpecificPOS() {
        return Store.query().where('useStorePosMapping', true).exists();
      },

      importedPosStoreIds() {
        return [...new Set([
          ...PosMenuCategory.all().map(pmi => pmi.storeId),
          ...PosMenuItem.all().map(pmi => pmi.storeId)
        ])];
      }
    },

    created() {
      this.onCreated();
    },

    destroyed() {
      this.onDestroyed();
    },

    methods: {
      onCreated() {
        events.$on('refresh-pos-menu', this.fetchPosMenu);
      },

      onDestroyed() {
        events.$off('refresh-pos-menu');
      },

      async onMerchantLoad() {
        await this.fetchStores();
        await this.fetchPosMenu();
      },

      async fetchPosMenu() {
        if (this.$_selectedMerchant?.hasUnstructuredMenu) {
          await this.fetchPosItems();
        }
        else {
          await this.fetchPosMenuCategories();
        }
      },

      openLocationImportModal() {
        const storeList = this.stores.map((s) => {
          const hasErrors = !!Object.keys(s.posSyncErrors).length;
          const store = {
            id: s.storeId,
            name: s.description,
            isInvalid: hasErrors
          };

          if (this.isCardfreeAdmin && hasErrors) {
            store.errors = s.posSyncErrors;
          }

          return store;
        });

        this.$buefy.modal.open({
          parent: this,
          component: searchSelectModal,
          hasModalCard: true,
          trapFocus: true,
          canCancel: false,
          customClass: 'auto-width',
          props: {
            title: 'Select Location To Import',
            subtitle: 'Select a location to import your POS menu',
            confirmText: 'Import',
            placeholderText: 'Search for a location...',
            invalidItemMessage: 'Unable to import POS Menu',
            showDetailedErrors: this.isCardfreeAdmin,
            data: storeList,
            isSingleSelection: true,
            onSubmit: this.handleImport
          }
        });
      },

      openClearDataModal() {
        const storeList = this.stores
          .filter(s => this.importedPosStoreIds.includes(s.storeId))
          .map(s => ({ id: s.storeId, name: s.description }));

        if (this.hasStoreSpecificPOS) {
          this.$buefy.modal.open({
            parent: this,
            component: searchSelectModal,
            hasModalCard: true,
            trapFocus: true,
            canCancel: false,
            customClass: 'auto-width',
            props: {
              title: 'Select Location To Clear Data',
              subtitle: 'Select a location to clear POS menu data',
              confirmText: 'Clear',
              placeholderText: 'Search for a location...',
              data: storeList,
              onSubmit: this.confirmDeleteMenuData
            }
          });
        }
        else {
          this.confirmDeleteMenuData();
        }
      },

      openClearMenuSuccessModal() {
        this.$buefy.modal.open({
          parent: this,
          component: alertModal,
          hasModalCard: true,
          trapFocus: true,
          canCancel: false,
          customClass: 'auto-width',
          props: {
            buttons: [
              { text: 'Okay' },
              { text: 'Reimport POS Menu', primary: true, onClick: this.openLocationImportModal }
            ],
            showCloseButton: false,
            icon: 'check-circle',
            iconPack: 'far',
            title: 'Success',
            message: "We've successfully cleared the POS menu data.",
            type: 'is-success'
          }
        });
      },

      async handleImport(storeId) {
        await http.put('pos_menu_import', { storeIds: [storeId] }, { timeout: 300000 });
        events.$emit('pos-menu-import', 'in-progress');
      },

      confirmDeleteMenuData(storeIds = []) {
        this.$buefy.modal.open({
          parent: this,
          component: alertModal,
          hasModalCard: true,
          trapFocus: true,
          canCancel: ['escape', 'outside'],
          customClass: 'auto-width',
          props: {
            title: 'Clear POS Menu',
            message: 'Are you sure you\'d like to clear your imported POS Menu data?',
            horizontal: true,
            showCloseButton: false,
            icon: 'exclamation-triangle',
            type: 'is-danger',
            buttons: [
              { text: 'Cancel' },
              { text: 'Clear Menu', primary: true, onClick: () => this.handleDelete(storeIds) }
            ]
          }
        });
      },

      async handleDelete(storeIds = []) {
        try {
          await PosMenuItem.clearPosMenu({ storeIds, hasUnstructuredMenu: this.hasUnstructuredMenu });
          this.openClearMenuSuccessModal();
        }

        catch (error) {
          this.$_onRequestError({
            toastOptions: {
              message: 'There was an issue clearing your POS Menu data'
            },
            error
          });
        }
      },

      async fetchStores() {
        try {
          await Store.fetchAll(null, {
            forceFetch: true,
            posSync: true
          });
        }
        catch (error) {
          this.$_onRequestError({
            toastOptions: {
              message: 'Unable to load your locations for pos menu import'
            },
            error
          });
        }
      },

      async fetchPosMenuCategories() {
        try {
          await PosMenuCategory.fetchPosMenuCategories();
        }
        catch (error) {
          this.$_onRequestError({
            toastOptions: {
              message: 'Unable to get your pos menu categories'
            },
            error
          });
        }
      },

      async fetchPosItems() {
        try {
          await PosMenuItem.fetchPosItems({ page: 1, isFetchingFirstTime: true });
        }
        catch (error) {
          this.$_onRequestError({
            toastOptions: {
              message: 'Unable to get your pos menu items'
            },
            error
          });
        }
      }
    }
  };
</script>
