import { Model } from '@vuex-orm/core';
import http from '@/services/http';

export default class OrderMode extends Model {
  static entity = 'orderModes'

  static fields() {
    return {
      id: this.attr(''),
      type: this.attr('')
    };
  }


  // STATE //////////////////////
  static state() {
    return {
      fetching: false
    };
  }

  static $state() {
    return this.store().state.entities.orderModes;
  }

  static async fetchOrderModes() {
    if (this.exists()) return;

    try {
      this.commit((state) => {
        state.fetching = true;
      });

      const { data } = await http.get('order_modes');
      this.insert({
        data: data.orderModes
      });
    }
    catch (error) {
      throw error;
    }
    finally {
      this.commit((state) => {
        state.fetching = false;
      });
    }
  }
}
