/* eslint-disable import/no-cycle */
import Saveable from './_Saveable';
import DeliveryService from './DeliveryService';

// classes
import http from '@/services/http';
import filterObjectKeys from '@/helpers/filter-object-keys';


export default class MerchantDeliveryService extends Saveable {
  static entity = 'merchantDeliveryServices'

  static primaryKey = ['merchantId', 'deliveryServiceId', 'platformTypeId']

  // FIELDS //////////////////////
  static fields() {
    return {
      ...super.fields(),
      merchantId: this.attr(null),
      deliveryServiceId: this.attr(''),
      apiConfiguration: this.attr({}),
      circuitBreakerConfiguration: this.attr(''),
      deliveryServiceChargeAmount: this.attr(null),
      deliveryServiceChargeTypeId: this.attr(null),
      deliveryUpcharge: this.attr(null),
      feeAmount: this.attr(null),
      feeTypeId: this.attr(null),
      platformTypeId: this.attr(null),
      platformType: this.attr({}),
      isActive: this.attr(true)
    };
  }

  get name() {
    return DeliveryService.find(this.deliveryServiceId)?.name;
  }

  // STATE //////////////////////
  static state() {
    return {
      fetching: false,
      submitting: false
    };
  }

  static $state() {
    return this.store().state.entities.merchantDeliveryServices;
  }



  // ACTIONS //////////////////////
  static async fetchMerchantDeliveryServices(merchantId) {
    if (this.exists()) return;

    try {
      this.commit((state) => {
        state.fetching = true;
      });

      const _merchantId = merchantId || this.store().state.entities.merchants.selectedMerchantId;
      const response = await http.get(`merchants/${_merchantId}/merchant_delivery_services`);

      this.create({
        data: response.data.merchantDeliveryServices
      });
    }
    catch (error) {
      throw error;
    }
    finally {
      this.commit((state) => {
        state.fetching = false;
      });
    }
  }

  static async addMerchantDeliveryService({ merchantDeliveryService, merchantId }) {
    try {
      this.commit((state) => {
        state.submitting = true;
      });

      const acceptedKeys = [
        'apiConfiguration',
        'deliveryServiceId',
        'deliveryServiceChargeAmount',
        'deliveryServiceChargeTypeId',
        'deliveryUpcharge',
        'feeAmount',
        'feeTypeId',
        'platformTypeId',
        'isActive'
      ];

      const response = await http.post(
        `merchants/${merchantId}/merchant_delivery_services`,
        { merchantDeliveryService: filterObjectKeys(merchantDeliveryService, acceptedKeys) }
      );

      this.insert({
        data: response.data.merchantDeliveryService
      });
    }
    catch (error) {
      throw error;
    }
    finally {
      this.commit((state) => {
        state.submitting = false;
      });
    }
  }

  static async updateMerchantDeliveryService({ merchantDeliveryService, merchantId }) {
    try {
      this.commit((state) => {
        state.submitting = true;
      });

      const acceptedKeys = [
        'apiConfiguration',
        'deliveryServiceId',
        'deliveryServiceChargeAmount',
        'deliveryServiceChargeTypeId',
        'deliveryUpcharge',
        'feeAmount',
        'feeTypeId',
        'platformTypeId',
        'isActive'
      ];

      const response = await http.put(
        `merchants/${merchantId}/merchant_delivery_services/${merchantDeliveryService.deliveryServiceId}`,
        { merchantDeliveryService: filterObjectKeys(merchantDeliveryService, acceptedKeys) }
      );

      this.update({
        data: response.data.merchantDeliveryService
      });
    }
    catch (error) {
      throw error;
    }
    finally {
      this.commit((state) => {
        state.submitting = false;
      });
    }
  }

  static async removeMerchantDeliveryService({ merchantDeliveryService, merchantId }) {
    const { deliveryServiceId, platformTypeId } = merchantDeliveryService;

    try {
      await http.delete(`merchants/${merchantId}/merchant_delivery_services/${deliveryServiceId}`, {
        data: { platformTypeId }
      });

      this.delete([merchantId, deliveryServiceId, platformTypeId]);
    }
    catch (error) {
      throw error;
    }
  }
}
