<template>
  <steps-modal
    v-model="form"
    title="Campaign Builder"
    :steps="steps"
    :read-only="campaign.state !== campaignStates.DRAFT"
    :message="message"
    message-type="is-warning"
    :loading="isLoading"
    confirm-close
    @valid-submit="confirmSchedule"
    @close="$parent.close()"
  >
    <template #actions>
      <b-button
        v-if="campaign.state === campaignStates.DRAFT"
        :loading="isLoading"
        icon-right="save"
        rounded
        @click="handleSave"
      >
        Save Draft
      </b-button>
    </template>
    <template #submit-button>
      <b-button
        type="is-primary"
        :icon-right="form.deliveryType === campaignDeliveryTypes.IMMEDIATE ? 'paper-plane' : 'calendar-check'"
        rounded
        native-type="submit"
        :disabled="campaign.state !== campaignStates.DRAFT"
      >
        {{ form.deliveryType === campaignDeliveryTypes.IMMEDIATE ? 'Send' : 'Schedule' }}
      </b-button>
    </template>
  </steps-modal>
</template>

<script>
  import getChangedResources from '@/helpers/get-changed-resources';
  import merchantMixin from '@/mixins/merchant';
  import featurePermissionsMixin from '@/mixins/featurePermissions';
  import { normalizeWhitespace } from '@/helpers/strings';

  import Campaign from '@/store/classes/Campaign';
  import CampaignsOffer from '@/store/classes/CampaignsOffer';
  import CampaignsSegmentation from '@/store/classes/CampaignsSegmentation';
  import Merchant from '@/store/classes/Merchant';
  import MerchantAppSetting from '@/store/classes/MerchantAppSetting';
  import MerchantPushProvider from '@/store/classes/MerchantPushProvider';
  import Offer from '@/store/classes/Offer';
  import MerchantLoyaltyProviderConfiguration from '@/store/classes/MerchantLoyaltyProviderConfiguration';

  import deepLinkIds from '@/constants/deepLinkIds';
  import { campaignStates, campaignDeliveryTypes, notificationTypes, emailImageTypes } from '@/constants/campaigns';

  import alertModal from '@/components/globals/alert-modal.vue';
  import configurationStep from './modal-steps/configuration-step.vue';
  import designStep from './modal-steps/design-step.vue';
  import previewStep from './modal-steps/preview-step.vue';

  import { findLast } from 'lodash';


  export default {
    name: 'CampaignModal',

    mixins: [merchantMixin, featurePermissionsMixin],

    props: {
      campaignGuid: {
        type: String,
        default: null
      }
    },

    data() {
      return {
        campaignStates,
        campaignDeliveryTypes,
        form: null,
        designForm: undefined,
        hasSmsConfigured: null,
        notificationsEnabled: null,
        // When email templates are removed and readded after saving one as a draft, we lose
        // reference to the original emailTemplateId. If we save again with a new email draft
        // it creates a second email template, which leads to bugs. We need to cache an original
        // email template id here and leverage it in the editing flow to prevent this.
        originalEmailTemplateId: undefined
      };
    },

    computed: {
      campaign() {
        if (!this.campaignGuid) {
          return new Campaign({ merchantId: this.$_selectedMerchantId });
        }
        return Campaign
          .query()
          .withAllRecursive()
          .find(this.campaignGuid);
      },

      isLoading() {
        return Campaign.$state().submitting || CampaignsOffer.$state().submitting;
      },

      isFetching() {
        return Campaign.$state().fetchingTemplates || Campaign.$state().fetchingLinks || MerchantLoyaltyProviderConfiguration.$state().fetching;
      },

      steps() {
        const steps = [
          {
            label: 'Configuration',
            component: configurationStep,
            props: {
              hasPushProvidersConfigured: !!this.configuredPushProviders.length,
              hasMobileAppConfigured: this.hasMobileAppConfigured,
              hasSmsConfigured: this.hasSmsConfigured,
              notificationsEnabled: this.notificationsEnabled,
              originalEmailTemplateId: this.originalEmailTemplateId
            },
            handlers: {
              'set-notifications-enabled': (enabled) => {
                this.notificationsEnabled = enabled;
              }
            }
          }
        ];

        if (this.notificationsEnabled) {
          steps.push({
            label: 'Design',
            component: designStep,
            isDisabled: this.offerRequired,
            props: {
              hasPushProvidersConfigured: !!this.configuredPushProviders.length,
              hasMobileAppConfigured: this.hasMobileAppConfigured,
              hasSmsConfigured: this.hasSmsConfigured
            },
            handlers: {
              'design-step-mounted': (designForm) => {
                this.designForm = designForm;
              },
              'delete-campaign-image': this.deleteCampaignImage
            }
          });
          if (this.campaign.state === campaignStates.DRAFT) {
            steps.push({
              label: 'Preview',
              component: previewStep,
              isDisabled: this.offerRequired || (!this.form?.emailTemplates?.length && !this.form?.pushNotification && !this.form?.smsNotification),
              props: {
                designForm: this.designForm
              }
            });
          }
        }
        return steps;
      },

      message() {
        if (this.offerRequired) {
          return 'Please add an offer or change your configuration to send this campaign';
        }
        else if (this.campaign.state !== campaignStates.DRAFT) {
          return 'Campaigns can no longer be edited once scheduled, live, or sent';
        }
        else {
          return '';
        }
      },

      offerRequired() {
        return this.form?.willIncludeOffer && !this.form.campaignsOffers.length;
      },

      configuredPushProviders() {
        return MerchantPushProvider.all();
      },

      hasMobileAppConfigured() {
        return MerchantAppSetting.hasMobileAppConfigured();
      }

    },

    created() {
      this.onCreated();
    },

    methods: {
      async onCreated() {
        this.form = {
          ...this.$clone(this.campaign),
          newEmailTemplatesImageFile: null,
          newPushNotificationImageFile: null,
          newSmsNotificationImageFile: null
        };
        await Promise.all([
          Campaign.fetchSendgridTemplates(),
          Merchant.fetchDeepLinks(this.$_selectedMerchant.id),
          this.fetchOffers(),
          this.fetchMerchantPushProviders(),
          this.fetchApplicationSettings(),
          this.fetchMerchantLoyaltyProviderConfigurations(),
          this.setMerchantAllowsSms()
        ]);
        if (!this.campaignGuid) {
          if (this.hasMobileAppConfigured && this.configuredPushProviders.length) {
            this.form.pushNotification = { title: '', message: '', notificationLinkId: deepLinkIds.MENU };
          }
          if (this.hasSmsConfigured) {
            this.form.smsNotification = { message: '' };
          }
        }
        const anyNotificationTypesEnabled = !!this.form.emailTemplates?.length || !!this.form.pushNotification || !!this.form.smsNotification;
        this.notificationsEnabled = anyNotificationTypesEnabled;

        // Grab the last one in case there are any campaigns with multple tempaltes. The last one will always be the
        // most up to date.
        this.originalEmailTemplateId = this.campaign.emailTemplates.length
          ? this.campaign.emailTemplates[this.campaign.emailTemplates.length - 1].id
          : undefined;
      },

      async setMerchantAllowsSms() {
        /**
         * As long as a merchant's userValidationsId is greater than 1 it means
         * their app supports phones in some way. 1 is for email/password only
         */

        this.hasSmsConfigured = this.$_selectedMerchant.merchantOption.userValidationsId > 1 && this.$_featurePermissions.CAMPAIGN_TEXTS;
      },


      async fetchMerchantLoyaltyProviderConfigurations() {
        try {
          await MerchantLoyaltyProviderConfiguration.fetchMerchantLoyaltyProviderConfigurations(this.$_selectedMerchantId);
        }
        catch (error) {
          this.$_onRequestError({
            error,
            toastOptions: { message: 'Unable to fetch merchant loyalty provider configurations' }
          });
        }
      },

      async fetchOffers() {
        try {
          await Offer.fetchOffers();
        }
        catch (error) {
          this.$_onRequestError({
            error,
            toastOptions: {
              message: 'There was an error fetching offers'
            }
          });
        }
      },

      async fetchMerchantPushProviders() {
        try {
          await MerchantPushProvider.fetchMerchantPushProviders(this.$_selectedMerchant.id);
        }
        catch (error) {
          this.$_onRequestError({
            toastOptions: {
              message: 'There was an error fetching the merchant push providers'
            },
            error
          });
        }
      },

      async fetchApplicationSettings() {
        try {
          await MerchantAppSetting.fetchMerchantAppSettings(this.$_selectedMerchant.id);
        }
        catch (error) {
          this.$_onRequestError({ toastOptions: { message: 'Unable to fetch merchant application settings' }, error });
        }
      },

      confirmSchedule() {
        const isImmediate = this.form.deliveryType === campaignDeliveryTypes.IMMEDIATE;

        this.$buefy.modal.open({
          parent: this,
          component: alertModal,
          hasModalCard: true,
          trapFocus: true,
          canCancel: ['escape', 'outside'],
          props: {
            icon: 'calendar-exclamation',
            title: `${isImmediate ? 'Send' : 'Schedule'} Campaign`,
            message: normalizeWhitespace(`
              Once you ${isImmediate ? 'send' : 'schedule'} your campaign it will no longer be editable.
              ${isImmediate ? '' : 'You\'ll still be able to cancel your campaign before its scheduled start date.'}
            `),
            secondaryMessage: `Are you sure you'd like to ${isImmediate ? 'send' : 'schedule'} this campaign?`,
            type: 'is-warning',
            horizontal: true,
            showCloseButton: false,
            buttons: [
              { text: 'Cancel' },
              {
                text: isImmediate ? 'Send' : 'Schedule',
                primary: true,
                onClick: () => this.scheduleCampaign()
              }
            ]
          }
        });
      },

      async scheduleCampaign() {
        try {
          const campaignGuid = await this.handleSave({ hideSuccessToast: true });
          await Campaign.scheduleCampaign(campaignGuid);

          this.$_onRequestSuccess({
            toastOptions: {
              message: `Successfully ${this.form.deliveryType === campaignDeliveryTypes.IMMEDIATE ? 'sent' : 'scheduled'} your Campaign!`
            },
            options: { closeParent: true }
          });
        }

        catch (error) {
          this.$_onRequestError({
            toastOptions: {
              message: `There was an error ${this.form.deliveryType === campaignDeliveryTypes.IMMEDIATE ? 'sending' : 'scheduling'} your Campaign`
            },
            error
          });
          throw error;
        }
      },

      async deleteCampaignImage({ resourceId, type }) {
        try {
          await Campaign.deleteCampaignImage({ resourceId, type });
          switch (type) {
            case notificationTypes.EMAIL:
              this.form.emailTemplates[this.form.emailTemplates.length - 1].campaignEmailTemplateImages = this.form.emailTemplates[this.form.emailTemplates.length - 1].campaignEmailTemplateImages
                .filter(em => em.id !== resourceId);
              break;
            case notificationTypes.PUSH:
              this.form.pushNotification.image = null;
              break;
            case notificationTypes.SMS:
              this.form.smsNotification.image = null;
              break;
            default:
            // do nothing
          }
        }

        catch (error) {
          this.$_onRequestError({
            toastOptions: {
              message: 'There was an error deleting the image'
            },
            error
          });
          throw error;
        }
      },

      setFormForTemplateDeletion(type) {
        switch (type) {
          case 'email':
            this.form.emailTemplates = [{
              id: this.campaign.emailTemplates[this.campaign.emailTemplates.length - 1].id,
              _destroy: true
            }];
            break;
          case 'push':
            this.form.pushNotification = this.campaign?.pushNotification
              ? { id: this.campaign.pushNotification.id, _destroy: true }
              : null;
            break;
          case 'sms':
            this.form.smsNotification = {
              id: this.campaign.smsNotification.id,
              _destroy: true
            };
            break;
          case 'recurring':
            this.form.recurringCampaignConfiguration = {
              id: this.campaign.recurringCampaignConfiguration.id,
              _destroy: true
            };
            break;
          default:
            break;
        }
      },

      async handleEmailTemplateImageChange({ type, campaign }) {
        if (type === emailImageTypes.HEADER_OVERRIDE) {
          await Campaign.createCampaignImage({
            notificationId: campaign.emailTemplates[campaign.emailTemplates.length - 1].id,
            file: this.form.newOverrideImageFile,
            type: notificationTypes.EMAIL,
            imageType: emailImageTypes.HEADER_OVERRIDE
          });
          const resourceId = findLast(this.form.emailTemplates[this.form.emailTemplates.length - 1]?.campaignEmailTemplateImages, i => i.kind === emailImageTypes.HEADER_OVERRIDE)?.id;
          if (resourceId) await Campaign.deleteCampaignImage({ resourceId, type: notificationTypes.EMAIL });
        }
        else {
          await Campaign.createCampaignImage({
            notificationId: campaign.emailTemplates[campaign.emailTemplates.length - 1].id,
            file: this.form.newEmailTemplatesImageFile,
            type: notificationTypes.EMAIL
          });
          const resourceId = findLast(this.form.emailTemplates[this.form.emailTemplates.length - 1]?.campaignEmailTemplateImages, i => !i.kind)?.id;
          if (resourceId) await Campaign.deleteCampaignImage({ resourceId, type: notificationTypes.EMAIL });
        }
      },

      async handlePushNotificationImageChange(campaign) {
        await Campaign.createCampaignImage({
          notificationId: campaign.pushNotification.id,
          file: this.form.newPushNotificationImageFile,
          type: notificationTypes.PUSH
        });
        const resourceId = this.form.pushNotification.image?.id;
        if (resourceId) await Campaign.deleteCampaignImage({ resourceId, type: notificationTypes.PUSH });
      },

      async handleSmsNotificationImageChange(campaign) {
        await Campaign.createCampaignImage({
          notificationId: campaign.smsNotification.id,
          file: this.form.newSmsNotificationImageFile,
          type: notificationTypes.SMS
        });
        const resourceId = this.form.smsNotification.image?.id;
        if (resourceId) await Campaign.deleteCampaignImage({ resourceId, type: notificationTypes.SMS });
      },

      async updateOffersOnCampaign(campaignGuid) {
        const { added, removed } = getChangedResources({
          oldArray: this.campaign?.campaignsOffers || [],
          newArray: this.form.campaignsOffers,
          comparisonKey: 'offerGuid'
        });
        if (removed.length) await Promise.all(removed.map(r => CampaignsOffer.removeOfferFromCampaign(r.id)));
        if (added.length) await Promise.all(added.map(a => CampaignsOffer.addOfferToCampaign({ offerGuid: a.offerGuid, campaignGuid })));
      },

      async handleSegmentationChange(campaignGuid) {
        const hasAddedSegmentation = !this.campaign?.segmentation && this.form.segmentation;
        const hasRemovedSegmentation = this.campaign?.segmentation && !this.form.segmentation;
        const hasChangedSegmentation = (this.campaign?.segmentation?.id && this.form.segmentation?.id)
          && this.campaign.segmentation.id !== this.form.segmentation.id;

        if (hasRemovedSegmentation || hasChangedSegmentation) {
          await CampaignsSegmentation.removeSegmentationFromCampaign({
            campaignsSegmentationId: this.campaign.campaignsSegmentationId,
            segmentationId: this.campaign.segmentation.id,
            campaignGuid
          });
        }
        if (hasAddedSegmentation || hasChangedSegmentation) {
          await CampaignsSegmentation.addSegmentationToCampaign({
            segmentationId: this.form.segmentation.id,
            campaignGuid
          });
        }
      },

      async handleSave({ hideSuccessToast } = {}) {
        try {
          // Campaign
          let campaign = this.$clone(this.form);
          let campaignGuid = this.campaignGuid;
          if (!this.form.emailTemplates?.length && this.campaign?.emailTemplates?.length) {
            this.setFormForTemplateDeletion('email');
          }

          if (!this.form.pushNotification) {
            this.setFormForTemplateDeletion('push');
          }

          if (!this.form.smsNotification && this.campaign?.smsNotification) {
            this.setFormForTemplateDeletion('sms');
          }

          if (!this.form.recurringCampaignConfiguration && this.campaign?.recurringCampaignConfiguration) {
            this.setFormForTemplateDeletion('recurring');
          }

          if (this.campaignGuid) {
            campaign = await Campaign.updateCampaign(this.form);
          }
          else {
            campaign = await Campaign.createCampaign(this.form);
            campaignGuid = campaign.guid;
          }

          const promises = [];

          if (this.form.newEmailTemplatesImageFile) {
            promises.push(this.handleEmailTemplateImageChange({ campaign }));
          }

          if (this.form.newOverrideImageFile) {
            promises.push(this.handleEmailTemplateImageChange({ type: emailImageTypes.HEADER_OVERRIDE, campaign }));
          }

          if (this.form.newPushNotificationImageFile) {
            promises.push(this.handlePushNotificationImageChange(campaign));
          }

          if (this.form.newSmsNotificationImageFile) {
            promises.push(this.handleSmsNotificationImageChange(campaign));
          }

          await Promise.all([...promises, this.updateOffersOnCampaign(campaignGuid), this.handleSegmentationChange(campaignGuid)]);

          if (!hideSuccessToast) {
            this.$_onRequestSuccess({
              toastOptions: {
                message: 'Successfully saved your Campaign!'
              },
              options: { closeParent: true }
            });
          }

          return campaignGuid;
        }

        catch (error) {
          this.$_onRequestError({
            error,
            toastOptions: { message: 'There was an error saving your campaign' }
          });
        }
      }
    }
  };
</script>
