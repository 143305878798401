<template>
  <alert-modal v-bind="alertModalProps">
    <template #message>
      <div>
        <p>This item belongs to the following categories:</p>
        <ul v-if="item.menuCategories && item.menuCategories.length" class="pad-t-sm">
          <li
            v-for="category in item.menuCategories"
            :key="category.id"
            class="has-text-weight-bold has-text-left"
          >
            {{ category.displayName }}
          </li>
        </ul>
      </div>
    </template>
    <template #secondary-message>
      <p class="has-text-centered" style="max-width: 18rem">
        Are you sure you want to delete <b>{{ item.displayName }}</b> from the entire menu?
      </p>
    </template>
  </alert-modal>
</template>

<script>
  import alertModal from '@/components/globals/alert-modal.vue';
  import CategoryItem from '@/store/classes/CategoryItem';
  import Item from '@/store/classes/Item';

  export default {
    name: 'ConfirmDeleteItemFromMenu',

    components: { alertModal },

    props: {
      itemId: {
        type: Number,
        required: true
      }
    },

    data() {
      return {
        item: {}
      };
    },

    computed: {
      _item() {
        return Item.query().with('menuCategories').find(this.itemId);
      },
      alertModalProps() {
        return {
          icon: 'trash-alt',
          iconPosition: 'top',
          title: 'Delete Menu Item',
          type: 'is-danger',
          showCloseButton: false,
          horizontal: true,
          twoToned: true,
          buttons: [
            { text: 'Cancel' },
            { text: 'Delete', primary: true, onClick: this.deleteItemFromAllCategoriesAndMenu }
          ]
        };
      }
    },

    created() {
      this.onCreated();
    },

    methods: {
      onCreated() {
        this.item = this.$clone(this._item);
      },

      async deleteItemFromAllCategoriesAndMenu() {
        try {
          await CategoryItem.deleteItemFromCategories({
            menuCategoryIds: this.item.menuCategories.map(c => c.id),
            menuItemId: this.itemId
          });

          await Item.deleteMenuItem(this.item);

          this.$_onRequestSuccess({
            toastOptions: {
              message: `Successfully deleted <b>${this.item.displayName}</b> from the entire menu`
            }
          });
        }
        catch (error) {
          this.$_onRequestError({
            toastOptions: {
              message: 'There was an error deleting this item from your menu'
            },
            error
          });
        }
      }
    }
  };
</script>

<style lang='sass' scoped>
  ul
    padding-left: 1.5rem
  li
    list-style-type: square
    &::marker
      color: $grey-lighter
</style>
