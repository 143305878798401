var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"detail-page is-flex-column"},[_c('div',{staticClass:"flex-grow is-relative"},[_c('div',{staticClass:"container"},[_c('b-loading',{attrs:{"active":_vm.isFetching || _vm.isFetchingOffer,"is-full-page":false}}),(_vm.offers.length)?_c('div',{staticClass:"card"},[_c('searchable-table',{attrs:{"table-data":_vm.offers,"search":{
            placeholder: 'Search by Offer Name',
            keys: ['name']
          },"filters":_vm.tableFilters},scopedSlots:_vm._u([{key:"optional-actions",fn:function(){return [_c('b-button',{attrs:{"type":"is-primary","icon-left":"plus"},on:{"click":function($event){return _vm.selectOffer()}}},[_vm._v(" New Offer ")]),_c('dropdown-menu',{attrs:{"trigger":"click","position":"left-start"}},[_c('b-button',{attrs:{"slot":"trigger","type":"is-primary"},slot:"trigger"},[_c('b-icon',{attrs:{"icon":"info-circle","pack":"far"}})],1),_c('b-dropdown-item',{staticClass:"no-wrap-text",attrs:{"custom":""}},[_c('b-tag',{staticClass:"draft has-text-weight-bold mar-r-sm",attrs:{"type":"is-dark"}},[_vm._v("Draft")]),_c('span',[_vm._v("The offer can be edited until it's finalized and ready to be delivered in a campaign")])],1),_c('b-dropdown-item',{staticClass:"no-wrap-text",attrs:{"custom":""}},[_c('b-tag',{staticClass:"live has-text-weight-bold mar-r-sm",attrs:{"type":"is-primary"}},[_vm._v("Live")]),_c('span',[_vm._v("The offer has been delivered to your customers in a campaign and is live")])],1),_c('b-dropdown-item',{staticClass:"no-wrap-text",attrs:{"custom":""}},[_c('b-tag',{staticClass:"expired has-text-weight-bold mar-r-sm",attrs:{"type":"is-success"}},[_vm._v("Expired")]),_c('span',[_vm._v("The offer is no longer available to be delivered in a campaign or redeemed by a customer")])],1)],1)]},proxy:true},{key:"default",fn:function(ref){
          var filteredData = ref.filteredData;
return [_c('b-table',{staticClass:"is-middle-aligned",attrs:{"data":_vm.isFetching ? [] : filteredData,"paginated":_vm.offers.length > _vm.pageLimit,"per-page":_vm.pageLimit,"mobile-cards":false,"default-sort":['name', 'asc'],"scrollable":""},on:{"sort":_vm.handleSort},scopedSlots:_vm._u([{key:"empty",fn:function(){return [_c('empty-table-loader',{attrs:{"icon":"gift","message":"No Offers Found","loading":_vm.isFetching}})]},proxy:true}],null,true)},[_c('b-table-column',{attrs:{"sortable":"","field":"name","label":"Name","width":"1","cell-class":"no-wrap-text"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var offer = ref.row;
return [_c('div',{staticClass:"is-flex"},[_c('p',{staticClass:"link table-cell-text-overflow text-ellipsis mar-r-xs",on:{"click":function($event){return _vm.selectOffer(offer.guid)}}},[_vm._v(" "+_vm._s(offer.name)+" ")]),(offer.autoApplicable && _vm.$_selectedMerchant.hasAutoApplicableOffers)?_c('b-icon',{attrs:{"icon":"circle-bolt"}}):_vm._e()],1)]}}],null,true)}),_c('b-table-column',{attrs:{"field":"description","label":"Description"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var description = ref.row.description;
return [_c('div',{class:['table-cell-text-overflow', !description && 'has-text-grey-light']},[_c('p',[_vm._v(_vm._s(description || 'N/A'))])])]}}],null,true)}),_c('b-table-column',{attrs:{"field":"guid","label":"ID","width":"1","cell-class":"no-wrap-text is-monospaced"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var offer = ref.row;
return [_c('div',{staticClass:"table-cell-text-overflow"},[_c('span',[_vm._v(_vm._s(offer.state === _vm.offerStates.DRAFT ? 'N/A' : offer.guid))])])]}}],null,true)}),_c('b-table-column',{attrs:{"sortable":"","centered":"","custom-sort":_vm.sortByState,"field":"state","label":"State"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var state = ref.row.state;
return [_c('b-tag',{class:state},[_vm._v(" "+_vm._s(_vm._f("capitalize")(state))+" ")])]}}],null,true)}),_c('b-table-column',{attrs:{"centered":"","cell-class":"actions","label":"Actions","width":"1","field":"Actions"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var row = ref.row;
return [_c('dropdown-menu',{attrs:{"position":"bottom-end","mobile-modal":false}},[_c('b-button',{staticClass:"is-transparent",attrs:{"slot":"trigger","type":"is-white"},slot:"trigger"},[_c('b-icon',{attrs:{"icon":"ellipsis-v","pack":"far","size":"is-small"}})],1),_c('b-dropdown-item',{on:{"click":function($event){return _vm.selectOffer(row.guid)}}},[_c('b-icon',{staticClass:"mar-r-sm",attrs:{"icon":_vm.isOfferEditable(row.state) ? 'pencil' : 'eye',"size":"is-small"}}),_vm._v(" "+_vm._s(_vm.isOfferEditable(row.state) ? 'Edit' : 'View')+" Offer ")],1),_c('b-dropdown-item',{on:{"click":function($event){return _vm.cloneOffer(row.guid)}}},[_c('b-icon',{staticClass:"mar-r-sm",attrs:{"icon":"clone","size":"is-small"}}),_vm._v(" Clone Offer ")],1)],1)]}}],null,true)})],1)]}}],null,false,2853083366)})],1):(!_vm.isFetching && !_vm.offers.length)?_c('empty-state-card',{attrs:{"type":"is-primary","title":"Create an Offer","message":"Create offers that guests can earn or receive from a campaign!","icon":"gift"},scopedSlots:_vm._u([{key:"buttons",fn:function(){return [_c('b-button',{attrs:{"type":"is-primary","rounded":"","icon-left":"plus"},on:{"click":function($event){return _vm.selectOffer()}}},[_vm._v(" New Offer ")])]},proxy:true}])}):_vm._e()],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }