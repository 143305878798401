const posTypes = {
  None: 0,
  NCR: 1,
  Simphony: 2,
  Omnivore: 3,
  Square: 4,
  NorthStar: 5,
  Revel: 6,
  Cardfree: 7,
  QuikServe: 8,
  Clover: 10,
  EpsonOms: 11,
  CardfreeUniversal: 12,
  Brink: 13,
  ShiftFour: 14,
  MassPay: 15,
  OmnivoreAloha: 16,
  GemPos: 17,
  Toast: 18,
  OracleSTSG2: 19,
  NcrSilver: 20,
  CardfreeUniversalV2: 21,
  OmnivoreLegacy: 22,
  OmnivoreAlohaLegacy: 23,
  InfoGenesis: 24,
  InfoGenesisUnstructured: 25,
  FTI: 26,
  RetailStack: 27,
  OracleSTSG2Unstructured: 28,
  Diagnostic: 1000
};

export const posTypesById = Object.entries(posTypes).reduce((acc, [key, value]) => {
  acc[value] = key;
  return acc;
}, {});

export default posTypes;
