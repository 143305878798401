/* eslint-disable import/no-cycle */
import { Model } from '@vuex-orm/core';
import http from '@/services/http';

import filterObjectKeys from '@/helpers/filter-object-keys';


export default class StoreMappingValidation extends Model {
  static entity = 'storeMappingValidations'

  static fields() {
    return {
      id: this.attr(''),
      storeId: this.attr(''),
      fulfillmentTypeId: this.attr(null),
      menuTypeId: this.attr(''),
      orderMinimumAmount: this.attr(null, val => (val ? parseFloat(val).toFixed(2) : val)),
      orderMaximumAmount: this.attr(null, val => (val ? parseFloat(val).toFixed(2) : val)),
      orderMaximumItemCount: this.attr(null)
      // orderMinimumItemCount: this.attr(null, val => (val ? parseInt(val, 0) : 0)),
    };
  }



  // STATE //////////////////////

  static state() {
    return {
      fetching: false,
      submitting: false
    };
  }

  static $state() {
    return this.store().state.entities.storeMappingValidations;
  }



  // ACTIONS //////////////////////

  static async fetchStoreMappingValidations(storeId) {
    try {
      this.commit((state) => {
        state.fetching = true;
      });

      const response = await http.get(`stores/${storeId}/validations`);

      this.create({
        data: response.data.storeMappingValidations
      });
    }
    catch (error) {
      throw error;
    }
    finally {
      this.commit((state) => {
        state.fetching = false;
      });
    }
  }

  static async addStoreMappingValidation(storeMappingValidation) {
    try {
      this.commit((state) => {
        state.submitting = true;
      });

      const acceptedKeys = [
        'fulfillmentTypeId',
        'menuTypeId',
        'orderMinimumAmount',
        'orderMaximumAmount',
        'orderMaximumItemCount'
        // 'orderMinimumItemCount',
      ];

      const response = await http.post(`stores/${storeMappingValidation.storeId}/validations`, {
        storeMappingValidation: filterObjectKeys(storeMappingValidation, acceptedKeys)
      });

      this.insert({
        data: response.data.storeMappingValidation
      });
    }
    catch (error) {
      throw error;
    }
    finally {
      this.commit((state) => {
        state.submitting = false;
      });
    }
  }

  static async updateStoreMappingValidation(storeMappingValidation) {
    try {
      this.commit((state) => {
        state.submitting = true;
      });

      const acceptedKeys = [
        'orderMinimumAmount',
        'orderMaximumAmount',
        'orderMaximumItemCount'
        // 'orderMinimumItemCount',
      ];

      const response = await http.put(`stores/${storeMappingValidation.storeId}/validations/${storeMappingValidation.id}`, {
        storeMappingValidation: filterObjectKeys(storeMappingValidation, acceptedKeys)
      });

      this.update({
        data: response.data.storeMappingValidation
      });
    }
    catch (error) {
      throw error;
    }
    finally {
      this.commit((state) => {
        state.submitting = false;
      });
    }
  }
}
