import { render, staticRenderFns } from "./modifier-groups.vue?vue&type=template&id=498caa08&scoped=true&"
import script from "./modifier-groups.vue?vue&type=script&lang=js&"
export * from "./modifier-groups.vue?vue&type=script&lang=js&"
import style0 from "./modifier-groups.vue?vue&type=style&index=0&id=498caa08&lang=sass&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "498caa08",
  null
  
)

export default component.exports