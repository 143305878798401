<template>
  <tabs-page
    :tabs="tabs"
    :keep-alive="false"
    title="Promo Codes"
  />
</template>

<script>

  // tabs
  import promoCodeList from './tabs/promo-code-list.vue';

  // store
  import Offer from '@/store/classes/Offer';
  import OfferDistribution from '@/store/classes/OfferDistribution';


  export default {
    name: 'PromoCodeManagement',


    computed: {
      tabs() {
        return [
          {
            display: 'Promo Codes',
            name: 'promo-codes',
            component: promoCodeList
          }
        ];
      }
    },

    async created() {
      await this.onCreated();
    },

    methods: {
      async onCreated() {
        await Promise.all([
          this.fetchOfferDistributions(),
          this.fetchOffers()
        ]);
      },

      async fetchOfferDistributions() {
        try {
          await OfferDistribution.fetchOfferDistributions();
        }
        catch (error) {
          this.$_onRequestError({
            error,
            toastOptions: {
              message: 'There was an error fetching your offer distributions'
            }
          });
        }
      },

      async fetchOffers() {
        try {
          await Offer.fetchOffers();
        }
        catch (error) {
          this.$_onRequestError({
            error,
            toastOptions: {
              message: 'There was an error fetching your offers'
            }
          });
        }
      }
    }
  };

</script>
