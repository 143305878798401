import { Model } from '@vuex-orm/core';
import http from '@/services/http';
import RegisteredGuest from '@/store/classes/RegisteredGuest';


export default class OrderSummary extends Model {
  static entity = 'orderSummaries'

  static fields() {
    return {
      id: this.attr(''),
      orderDate: this.attr(''),
      total: this.attr(''),
      orderStatus: this.attr(''),
      accountId: this.attr(''),
      sortOrder: this.attr(''),
      storeTimezone: this.attr(''),
      ticketNumber: this.attr(''),
      registeredGuest: this.belongsTo(RegisteredGuest, 'accountId')
    };
  }

  // STATE //////////////////////
  static state() {
    return {
      fetching: false,
      orderSummaryMetaData: {}
    };
  }

  static $state() {
    return this.store().state.entities.orderSummaries;
  }


  // Actions

  static async fetchOrdersByAccount({
    sortField,
    sortDirection,
    page,
    accountId
  }) {
    try {
      this.commit((state) => {
        state.fetching = true;
      });

      this.deleteAll();

      const queries = [
        page && `page=${page}`,
        sortField && `sort_orders_field=${sortField}`,
        sortDirection && `sort_orders_direction=${sortDirection}`
      ].filter(q => q);

      const queryString = queries.length ? `?${queries.join('&')}` : '';

      const {
        data: { orders, meta }
      } = await http.get(`accounts/${accountId}/orders${queryString}`);

      this.commit((state) => {
        state.orderSummaryMetaData = meta;
      });

      this.create({
        data: orders.map((order, index) => {
          // assigns a "sort order" to guests using the array index to retain
          // its sort position as it was when it was returned from the endpoint
          order.sortOrder = index;
          order.accountId = accountId;
          return order;
        })
      });
    }

    catch (error) {
      throw error;
    }

    finally {
      this.commit((state) => {
        state.fetching = false;
      });
    }
  }
}
