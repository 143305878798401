<template>
  <div>
    <maintenance-messaging />
  </div>
</template>

<script>
  import maintenanceMessaging from '../app-maintenance/maintenance-messaging.vue';

  export default {
    name: 'AppMaintenance',

    components: { maintenanceMessaging }
  };
</script>

