/* eslint-disable import/no-cycle */
import { Model } from '@vuex-orm/core';
import http from '@/services/http';



export default class StoreCommunications extends Model { // TEST
  static entity = 'storeCommunications'

  static fields() {
    return {
      id: this.attr(''),
      storeId: this.attr(''),
      name: this.attr(''),
      phoneNumber: this.attr(''),
      emailAddress: this.attr(''),
      isActive: this.boolean(true),
      receivesOnsiteNotification: this.boolean(false),
      receivesOnsiteNotificationEmail: this.boolean(false),
      receivesOrderNotification: this.boolean(false),
      receivesOrderNotificationEmail: this.boolean(false),
      orderCleanupNotificationEmail: this.boolean(false),
      futureOrderErrorNotification: this.boolean(false),
      receivesCateringNotificationEmail: this.boolean(false),
      receivesCateringNotification: this.boolean(false),
      receivesPaymentNotification: this.attr(false),
      receivesPaymentNotificationEmail: this.attr(false)
    };
  }

  static state() {
    return {
      fetching: false,
      submitting: false
    };
  }

  static $state() {
    return this.store().state.entities.storeCommunications;
  }

  static async addStoreCommunication(communicationData) {
    try {
      this.commit((state) => {
        state.submitting = true;
      });

      const response = await http.post(`stores/${communicationData.storeId}/store_mapping_communications`, {
        storeMappingCommunication: communicationData
      });

      this.insert({
        data: response.data.storeMappingCommunication
      });
    }

    catch (error) {
      throw error;
    }

    finally {
      this.commit((state) => {
        state.submitting = false;
      });
    }
  }

  static async updateStoreCommunication(communicationData) {
    try {
      this.commit((state) => {
        state.submitting = true;
      });

      const response = await http.put(`store_mapping_communications/${communicationData.id}`, {
        storeMappingCommunication: communicationData
      });

      this.update({
        data: response.data.storeMappingCommunication
      });
    }

    catch (error) {
      throw error;
    }

    finally {
      this.commit((state) => {
        state.submitting = false;
      });
    }
  }

  static async deleteStoreCommunication(id) {
    try {
      this.commit((state) => {
        state.submitting = true;
      });

      await http.delete(`store_mapping_communications/${id}`);

      this.delete(id);
    }

    catch (error) {
      throw error;
    }

    finally {
      this.commit((state) => {
        state.submitting = false;
      });
    }
  }
}
