<template>
  <div>
    <regional-location-dropdown
      :value="earnRuleConstraint.storeIds.map(Number)"
      :stores="stores"
      hide-label
      select-all-mode="includeAll"
      :disabled="readOnly"
      :loading="isFetching"
      required
      @input="$emit('update-constraint', { ...earnRuleConstraint, storeIds: $event });"
    />
    <b-checkbox
      v-if="showActiveOrderCheckbox"
      :value="earnRuleConstraint.allowActiveOrderAtAnyTargetLocation"
      data-test-id="allow-active-order-at-any-target-location-checkbox"
      name="allowActiveOrderAtAnyTargetLocation"
      :disabled="readOnly"
      @input="$emit('update-constraint', { ...earnRuleConstraint, allowActiveOrderAtAnyTargetLocation: $event });"
    >
      Allow guest with a pending order to earn
      <b-icon
        v-tippy="{
          content: 'When enabled, a guest with a pending order at a selected location will earn the offer',
          maxWidth: 300,
          delay: [150, 0]
        }"
        class="mar-b-none pad-b-none"
        icon="info-square"
        size="is-small"
        type="is-grey"
      />
    </b-checkbox>
  </div>
</template>

<script>
  import Store from '@/store/classes/Store';

  export default {
    name: 'LocationCard',

    props: {
      earnRuleConstraint: {
        type: Object,
        required: true
      },

      readOnly: {
        type: Boolean,
        default: false
      },

      showActiveOrderCheckbox: {
        type: Boolean,
        default: false
      }
    },

    computed: {
      stores() {
        return Store.all();
      },

      isFetching() {
        return Store.$state().fetchingAll;
      }
    },

    created() {
      this.onCreated();
    },

    methods: {

      async onCreated() {
        await this.fetchStores();
      },

      async fetchStores() {
        try {
          await Store.fetchAll();
        }
        catch (error) {
          this.$_onRequestError({ toastOptions: { message: 'Unable to fetch stores' }, error });
        }
      }
    }
  };
</script>
