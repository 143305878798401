import { Model } from '@vuex-orm/core';
import http from '@/services/http';


export default class OfferAllocation extends Model {
  static entity = 'offerAllocations';

  static primaryKey = 'guid';

  // FIELDS //////////////////////
  static fields() {
    return {
      guid: this.attr(''),
      offerPublicId: this.attr(''),
      name: this.attr(''),
      status: this.attr(''),
      reasonType: this.attr(''),
      allocationType: this.attr(''),
      redemptions: this.attr([]),
      redemptionsAllowedStartDate: this.attr(null),
      redemptionsAllowedEndDate: this.attr(null),
      issuedDateTime: this.attr(null)
    };
  }


  // STATE //////////////////////
  static state() {
    return {
      fetching: false,
      submitting: false
    };
  }

  static $state() {
    return this.store().state.entities.offerAllocations;
  }

  // ACTIONS //////////////////////
  static async fetchOffersForAccount(accountId) {
    try {
      this.commit((state) => {
        state.fetching = true;
      });

      const { data } = await http.get(`accounts/${accountId}/offer_allocations`);

      this.create({ data: data.offerAllocations });
    }

    catch (error) {
      throw error;
    }

    finally {
      this.commit((state) => {
        state.fetching = false;
      });
    }
  }

  static async issueOfferToAccount({ accountId, offerGuid }) {
    try {
      this.commit((state) => {
        state.submitting = true;
      });
      await http.post(`accounts/${accountId}/adjustment_engagements`, { adjustment_engagement: { offerGuid } });
    }

    catch (error) {
      throw error;
    }

    finally {
      this.commit((state) => {
        state.submitting = false;
      });
    }
  }

  static async revokeOfferFromAccount({ accountId, offerGuid }) {
    try {
      this.commit((state) => {
        state.submitting = true;
      });
      await http.post(`accounts/${accountId}/offer_allocations/${offerGuid}/revoke`);
    }

    catch (error) {
      throw error;
    }

    finally {
      this.commit((state) => {
        state.submitting = false;
      });
    }
  }
}
