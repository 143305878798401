<template>
  <aside
    v-click-outside="{ handler: closeSidebar }"
    :class="[
      'menu sidebar',
      {
        'sidebar-open': sidebarVisible,
        'sidebar-collapsed': !_isExpanded,
      }
    ]"
  >
    <div v-if="$_selectedMerchantId && currentUser" :class="['sidebar-header is-hidden-widescreen']">
      <merchant-dropdown v-if="isCardfreeAdmin || isMultiMerchantUser" expanded>
        <div class="selected-merchant-container dropdown-trigger">
          <img v-if="avatarUrl" class="selected-merchant-logo" :src="avatarUrl" alt="">
          <h3 class="selected-merchant-name subtitle is-marginless">{{ $_selectedMerchant && $_selectedMerchant.name }}</h3>
          <b-tag
            v-if="$_selectedMerchant && !$_selectedMerchant.isActive"
            class="has-text-weight-bold mar-t-xs"
            type="is-danger is-light"
            size="is-medium"
          >
            Deactivated
          </b-tag>
        </div>
      </merchant-dropdown>

      <div v-else class="selected-merchant-container">
        <img v-if="avatarUrl" class="selected-merchant-logo" :src="avatarUrl" alt="">
        <h3 class="selected-merchant-name subtitle is-marginless">{{ $_selectedMerchant && $_selectedMerchant.name }}</h3>
        <b-tag
          v-if="$_selectedMerchant && !$_selectedMerchant.isActive"
          class="has-text-weight-bold mar-t-xs"
          type="is-danger is-light"
          size="is-medium"
        >
          Deactivated
        </b-tag>
      </div>
    </div>


    <div class="menu-top">
      <ul v-if="currentUser && sidebarSectionPermissions.showMenuTop" class="menu-list animated tdFadeInRight">
        <ul v-if="sidebarSectionPermissions.showGeneral && !hideSidebarOptions" class="sidebar-section">
          <li class="sidebar-section-title">General</li>


          <!-- MERCHANTS -->
          <li v-if="$_featurePermissions.MERCHANTS" class="sidebar-nav-item">
            <router-link
              v-tippy="{ content: `<b>${sidebarItemNames.merchants}</b>`, ...tippyOptions }"
              :to="{ name: 'merchantList' }"
            >
              <b-icon icon="store" size="is-medium" />
              <p class="sidebar-nav-item-label">{{ sidebarItemNames.merchants }}</p>
            </router-link>
          </li>

          <!-- USERS -->
          <li v-if="$_featurePermissions.USERS" class="sidebar-nav-item">
            <router-link
              v-tippy="{ content: `<b>${sidebarItemNames.users}</b>`, ...tippyOptions }"
              :to="{ name: 'userList' }"
            >
              <b-icon icon="user-alt" size="is-medium" />
              <p class="sidebar-nav-item-label">{{ sidebarItemNames.users }}</p>
            </router-link>
          </li>

          <!-- MERCHANT CONFIGURATION -->
          <li v-if="$_featurePermissions.MERCHANT_CONFIGURATION" class="sidebar-nav-item">
            <router-link
              v-tippy="{ content: `<b>${sidebarItemNames.merchantConfiguration}</b>`, ...tippyOptions }"
              :to="{ name: 'merchantConfiguration' }"
            >
              <b-icon icon="wrench" size="is-medium" />
              <p class="sidebar-nav-item-label">{{ sidebarItemNames.merchantConfiguration }}</p>
            </router-link>
          </li>

          <!-- HOTEL CONFIGURATION -->
          <li v-if="$_featurePermissions.HOTEL_CONFIGURATION && !hideSidebarOptions" class="sidebar-nav-item">
            <router-link
              v-tippy="{ content: `<b>${sidebarItemNames.hotelConfiguration}</b>`, ...tippyOptions }"
              :to="{ name: 'hotelConfiguration' }"
            >
              <b-icon icon="hotel" size="is-medium" />
              <p class="sidebar-nav-item-label">{{ sidebarItemNames.hotelConfiguration }}</p>
            </router-link>
          </li>
        </ul>

        <!-- OPERATIONS -->
        <ul v-if="sidebarSectionPermissions.showOperations && !hideSidebarOptions" class="sidebar-section">
          <li class="sidebar-section-title">Operations</li>

          <li v-if="$_featurePermissions.KDS" class="sidebar-nav-item">
            <router-link
              v-tippy="{ content: `<b>${sidebarItemNames.orderDashboard}</b>`, ...tippyOptions }"
              :to="{ name: 'kds'}"
            >
              <b-icon icon="hat-chef" size="is-medium" />
              <p class="sidebar-nav-item-label">{{ sidebarItemNames.orderDashboard }}</p>
            </router-link>
          </li>

          <li v-if="$_featurePermissions.OPD" class="sidebar-nav-item">
            <router-link
              v-tippy="{ content: `<b>${sidebarItemNames.paymentDashboard}</b>`, ...tippyOptions }"
              :to="{ name: 'orderPaymentDashboard' }"
            >
              <b-icon icon="usd-square" size="is-medium" />
              <p class="sidebar-nav-item-label">{{ sidebarItemNames.paymentDashboard }}</p>
            </router-link>
          </li>

          <li v-if="$_featurePermissions.LOCATION_MANAGEMENT" class="sidebar-nav-item">
            <router-link
              v-tippy="{ content: `<b>${sidebarItemNames.locationManagement}</b>`, ...tippyOptions }"
              :to="{ name: 'storeList' }"
            >
              <b-icon icon="map-marker-alt" size="is-medium" />
              <p class="sidebar-nav-item-label">{{ sidebarItemNames.locationManagement }}</p>
            </router-link>
          </li>

          <li v-if="$_featurePermissions.ORDER_EXPO" class="sidebar-nav-item">
            <router-link
              v-tippy="{ content: `<b>${sidebarItemNames.cardFreeQueue}</b>`, ...tippyOptions }"
              :to="{ name: 'cardFreeQueue' }"
            >
              <b-icon icon="circle-q" size="is-medium" />
              <p class="sidebar-nav-item-label">{{ sidebarItemNames.cardFreeQueue }}</p>
            </router-link>
          </li>
        </ul>

        <!-- MENU MANAGEMENT -->
        <ul v-if="sidebarSectionPermissions.showMenuManagement && !hideSidebarOptions" class="sidebar-section">
          <li class="sidebar-section-title">Menu Management</li>

          <li v-if="$_featurePermissions.POS_MENU" class="sidebar-nav-item">
            <router-link
              v-tippy="{ content: `<b>${sidebarItemNames.posMenu}</b>`, ...tippyOptions }"
              :to="{ name: 'posMenu' }"
            >
              <b-icon icon="cash-register" size="is-medium" />
              <p class="sidebar-nav-item-label">{{ sidebarItemNames.posMenu }}</p>
            </router-link>
          </li>
          <li v-if="$_featurePermissions.MASTER_MENU" class="sidebar-nav-item">
            <router-link
              v-tippy="{ content: `<b>${sidebarItemNames.masterMenus}</b>`, ...tippyOptions }"
              :to="{ name: 'menuManagement' }"
            >
              <b-icon icon="utensils" size="is-medium" />
              <p class="sidebar-nav-item-label">{{ sidebarItemNames.masterMenus }}</p>
            </router-link>
          </li>
          <li v-if="$_featurePermissions.LOCATION_MENU" class="sidebar-nav-item">
            <router-link
              v-tippy="{ content: `<b>${sidebarItemNames.locationMenus}</b>`, ...tippyOptions }"
              :to="{ name: 'locationMenu' }"
            >
              <b-icon icon="store-alt" size="is-medium" />
              <p class="sidebar-nav-item-label">{{ sidebarItemNames.locationMenus }}</p>
            </router-link>
          </li>

          <!-- REGIONAL MENU -->
          <li v-if="$_featurePermissions.REGIONAL_MENU" class="sidebar-nav-item">
            <router-link
              v-tippy="{ content: `<b>${sidebarItemNames.regionalMenus}</b>`, ...tippyOptions }"
              :to="{ name: 'regionalMenu' }"
            >
              <b-icon icon="globe-americas" size="is-medium" />
              <p class="sidebar-nav-item-label">{{ sidebarItemNames.regionalMenus }}</p>
            </router-link>
          </li>
        </ul>

        <!-- APPLICATION CONFIGURATION -->
        <ul v-if="sidebarSectionPermissions.showApplicationConf && !hideSidebarOptions" class="sidebar-section">
          <li class="sidebar-section-title">App Configuration</li>

          <li v-if="$_featurePermissions.CONTENT_MANAGEMENT" class="sidebar-nav-item">
            <router-link
              v-tippy="{ content: `<b>${sidebarItemNames.content}</b>`, ...tippyOptions }"
              :to="{ name: 'contentManagement' }"
            >
              <b-icon icon="quote-right" size="is-medium" />
              <p class="sidebar-nav-item-label">{{ sidebarItemNames.content }}</p>
            </router-link>
          </li>
          <li v-if="$_featurePermissions.WEB_APP_CONFIGURATION" class="sidebar-nav-item">
            <router-link
              v-tippy="{ content: `<b>${sidebarItemNames.webApp}</b>`, ...tippyOptions }"
              :to="{ name: 'webAppConfiguration' }"
            >
              <b-icon icon="browser" size="is-medium" />
              <p class="sidebar-nav-item-label">{{ sidebarItemNames.webApp }}</p>
            </router-link>
          </li>
        </ul>

        <!-- GUEST SUPPORT -->
        <ul v-if="sidebarSectionPermissions.showGuestSupport && !hideSidebarOptions" class="sidebar-section">
          <li class="sidebar-section-title">Guest Support</li>

          <li v-if="$_featurePermissions.ORDERS" class="sidebar-nav-item">
            <router-link
              v-tippy="{ content: `<b>${sidebarItemNames.orderManagement}</b>`, ...tippyOptions }"
              :to="{ name: 'orderManagement' }"
            >
              <b-icon icon="concierge-bell" size="is-medium" />
              <p class="sidebar-nav-item-label">{{ sidebarItemNames.orderManagement }}</p>
            </router-link>
          </li>

          <!-- ACCOUNT MANAGEMENT -->
          <li v-if="$_featurePermissions.ACCOUNT_MANAGEMENT" class="sidebar-nav-item">
            <router-link
              v-tippy="{ content: `<b>${sidebarItemNames.accountManagement}</b>`, ...tippyOptions }"
              :to="{ name: 'registeredGuestList' }"
            >
              <b-icon icon="user-friends" size="is-medium" />
              <p class="sidebar-nav-item-label">{{ sidebarItemNames.accountManagement }}</p>
            </router-link>
          </li>

          <!-- TIP REPORTS -->
          <li v-if="$_featurePermissions.TIP_REPORTS" class="sidebar-nav-item">
            <router-link
              v-tippy="{ content: `<b>${sidebarItemNames.tipReports}</b>`, ...tippyOptions }"
              :to="{ name: 'tipReports' }"
            >
              <b-icon icon="chart-simple" size="is-medium" />
              <p class="sidebar-nav-item-label">{{ sidebarItemNames.tipReports }}</p>
            </router-link>
          </li>

          <li v-if="$_featurePermissions.PAYMENT_TRANSACTIONS" class="sidebar-nav-item">
            <router-link
              v-tippy="{ content: `<b>${sidebarItemNames.paymentTransactions}</b>`, ...tippyOptions }"
              :to="{ name: 'paymentTransactions' }"
            >
              <b-icon icon="credit-card" icon-pack="fas" size="is-medium" />
              <p class="sidebar-nav-item-label">{{ sidebarItemNames.paymentTransactions }}</p>
            </router-link>
          </li>

          <li v-if="$_featurePermissions.GIFT_CARD_TRANSACTIONS" class="sidebar-nav-item">
            <router-link
              v-tippy="{ content: `<b>${sidebarItemNames.giftCardTransactions}</b>`, ...tippyOptions }"
              :to="{ name: 'giftCardTransactions' }"
            >
              <b-icon icon="gift-card" icon-pack="fas" size="is-medium" />
              <p class="sidebar-nav-item-label">{{ sidebarItemNames.giftCardTransactions }}</p>
            </router-link>
          </li>
        </ul>

        <!-- ANALYTICS -->
        <ul v-if="sidebarSectionPermissions.showAnalytics && !hideSidebarOptions" class="sidebar-section">
          <li class="sidebar-section-title">Analytics</li>

          <li v-if="$_featurePermissions.REPORTING" class="sidebar-nav-item">
            <router-link
              v-tippy="{ content: `<b>${sidebarItemNames.reporting}</b>`, ...tippyOptions }"
              :to="{ name: 'reportingConfiguration' }"
            >
              <b-icon icon="analytics" size="is-medium" />
              <p class="sidebar-nav-item-label">{{ sidebarItemNames.reporting }}</p>
            </router-link>
          </li>

          <li v-if="$_featurePermissions.LOYALTY_REPORTING" class="sidebar-nav-item">
            <router-link
              v-tippy="{ content: `<b>${sidebarItemNames.loyaltyReporting}</b>`, ...tippyOptions }"
              :to="{ name: 'loyaltyReportingConfiguration' }"
            >
              <b-icon icon="donate" size="is-medium" />
              <p class="sidebar-nav-item-label">{{ sidebarItemNames.loyaltyReporting }}</p>
            </router-link>
          </li>

          <li v-if="$_featurePermissions.DATA_EXPORTS" class="sidebar-nav-item">
            <router-link
              v-tippy="{ content: `<b>${sidebarItemNames.dataExports}</b>`, ...tippyOptions }"
              :to="{ name: 'dataExportsManagement', params: { tabName: 'ordering' } }"
            >
              <b-icon icon="file-export" icon-pack="fas" size="is-medium" />
              <p class="sidebar-nav-item-label">{{ sidebarItemNames.dataExports }}</p>
            </router-link>
          </li>
        </ul>

        <!-- MARKETING -->
        <ul v-if="sidebarSectionPermissions.showMarketing && !hideSidebarOptions" class="sidebar-section">
          <li class="sidebar-section-title">Marketing</li>

          <li
            v-if="$_featurePermissions.LOYALTY"
            class="sidebar-nav-item"
            @click="toggleLink"
          >
            <router-link
              v-tippy="{ content: `<b>${sidebarItemNames.loyalty}</b>`, ...tippyOptions }"
              :to="{ name: 'loyaltyManagement' }"
            >
              <img
                v-if="showLink"
                src="/images/link-heart.png"
                alt="link-heart"
                class="link-heart"
              >
              <b-icon v-else icon="hand-holding-heart" size="is-medium" />
              <p class="sidebar-nav-item-label">{{ sidebarItemNames.loyalty }}</p>
            </router-link>
          </li>
          <li v-if="$_featurePermissions.CAMPAIGNS" class="sidebar-nav-item">
            <router-link
              v-tippy="{ content: `<b>${sidebarItemNames.campaigns}</b>`, ...tippyOptions }"
              :to="{ name: 'campaignManagement' }"
            >
              <b-icon icon="paper-plane" size="is-medium" />
              <p class="sidebar-nav-item-label">{{ sidebarItemNames.campaigns }}</p>
            </router-link>
          </li>
          <li v-if="$_featurePermissions.SEGMENTATIONS" class="sidebar-nav-item">
            <router-link
              v-tippy="{ content: `<b>${sidebarItemNames.segmentations}</b>`, ...tippyOptions }"
              :to="{ name: 'segmentationManagement' }"
            >
              <b-icon icon="chart-pie" size="is-medium" />
              <p class="sidebar-nav-item-label">{{ sidebarItemNames.segmentations }}</p>
            </router-link>
          </li>
          <li v-if="$_featurePermissions.PROMO_CODES" class="sidebar-nav-item">
            <router-link
              v-tippy="{ content: `<b>${sidebarItemNames.promoCodes}</b>`, ...tippyOptions }"
              :to="{ name: 'promoCodeManagement' }"
            >
              <b-icon icon="tickets" size="is-medium" />
              <p class="sidebar-nav-item-label">{{ sidebarItemNames.promoCodes }}</p>
            </router-link>
          </li>
          <template v-if="$_featurePermissions.OFFERS">
            <li class="sidebar-nav-item">
              <router-link
                v-tippy="{ content: `<b>${sidebarItemNames.offers}</b>`, ...tippyOptions }"
                :to="{ name: 'offerManagement' }"
              >
                <b-icon icon="gift" size="is-medium" />
                <p class="sidebar-nav-item-label">{{ sidebarItemNames.offers }}</p>
              </router-link>
            </li>

            <li v-if="this.$_featurePermissions.POS_DISCOUNTS" class="sidebar-nav-item">
              <router-link
                v-tippy="{ content: `<b>${sidebarItemNames.posDiscount}</b>`, ...tippyOptions }"
                :to="{ name: 'posDiscount' }"
              >
                <b-icon icon="tags" size="is-medium" />
                <p class="sidebar-nav-item-label">{{ sidebarItemNames.posDiscount }}</p>
              </router-link>
            </li>
          </template>
        </ul>
      </ul>

      <div v-else-if="!hideSidebarOptions" :class="_isExpanded && 'dist-y-lg'">
        <div v-for="(i, index) in [2, 1, 3, 2]" :key="index" class="dist-y-sm">
          <b-skeleton v-if="_isExpanded" height="1.25rem" />
          <hr v-else-if="index > 0" class="sidebar-divider mar-t-sm">

          <div v-for="j in i" :key="j" class="is-flex justify-center">
            <div :class="_isExpanded && 'mar-r-sm'">
              <b-skeleton height="2.5rem" width="2.75rem" />
            </div>
            <b-skeleton v-if="_isExpanded" height="2.5rem" />
          </div>
        </div>
      </div>
    </div>

    <div class="menu-bottom">
      <b-button
        v-tippy="{
          content: _isExpanded ? 'Collapse' : 'Expand',
          placement: 'right',
          distance: 10,
          size: 'large',
          arrow: false,
          animation: 'scale',
          duration: 200,
          delay: [666, 0]
        }"
        type="is-light"
        size="is-small"
        icon-left="chevron-left"
        class="sidebar-expand-collapse-button"
        @click="toggleSidebarCollapse"
      />

      <hr class="sidebar-divider">

      <dropdown-menu
        v-if="currentUser"
        position="top-start"
        :class="_isExpanded && 'align-self-start'"
      >
        <template #trigger>
          <p class="link-inverted has-text-weight-bold pad-x-xs pad-y-sm">
            {{ _isExpanded ? currentUser.fullName : getUserInitials(currentUser.fullName) }}
          </p>
        </template>

        <b-dropdown-item has-link>
          <router-link class="no-active" :to="{ name: 'userConfiguration', params: { id: currentUser.id } }">
            <b-icon icon="user" size="is-small" />
            <span class="mar-l-sm">My Profile</span>
          </router-link>
        </b-dropdown-item>
        <b-dropdown-item v-if="$_selectedMerchant && $_selectedMerchant.isSundry && !$_selectedMerchant.isTemplate" has-link>
          <a href="https://cardfree.com/contact/" target="_blank" class="is-flex align-center mar-t-xs">
            <img src="/images/cardfree-icon-logo.png" class="image is-16x16">
            <span class="mar-l-sm">Contact CardFree Sales</span>
          </a>
        </b-dropdown-item>
        <b-dropdown-item separator />
        <b-dropdown-item has-link class="is-danger">
          <a
            v-tabbable
            class="has-text-danger"
            @click="openSignOutConfirmation"
            @keypress.enter="openSignOutConfirmation"
          >
            <b-icon icon="sign-out-alt" size="is-small" />
            <span class="mar-l-sm">Log Out</span>
          </a>
        </b-dropdown-item>
      </dropdown-menu>

      <div v-else style="width: 100%" class="is-flex justify-center pad-y-sm">
        <b-skeleton
          :position="!_isExpanded ? 'is-centered' : ''"
          height="1.5rem"
          :width="_isExpanded ? '100%' : '1.875rem'"
        />
      </div>
    </div>
  </aside>
</template>

<script>
  import { mapGetters } from 'vuex';
  import clickOutside from 'buefy/src/directives/clickOutside';
  import merchantMixin from '@/mixins/merchant';
  import featurePermissionsMixin from '@/mixins/featurePermissions';
  import storage from '@/services/storage';
  import Merchant from '@/store/classes/Merchant';

  export default {
    name: 'SidebarNav',

    directives: { clickOutside },

    mixins: [merchantMixin, featurePermissionsMixin],

    props: {
      sidebarVisible: {
        type: Boolean,
        required: true
      }
    },

    data() {
      return {
        isExpanded: true,
        showLink: false,
        sidebarItemNames: {
          accountManagement: 'Account Management',
          campaigns: 'Campaigns',
          cardFreeQueue: 'CardFree Queue',
          content: 'Content',
          dataExports: 'Data Exports',
          giftCardTransactions: 'Gift Card Transactions',
          hotelConfiguration: 'Hotel Configuration',
          locationManagement: 'Location Management',
          locationMenus: 'Location Menus',
          logs: 'Change Logs',
          loyalty: 'Loyalty',
          loyaltyReporting: 'Loyalty Reporting',
          masterMenus: 'Master Menus',
          merchantConfiguration: 'Merchant Configuration',
          merchants: 'Merchants',
          offers: 'Offer Management',
          orderDashboard: 'Order Dashboard',
          orderManagement: 'Order Management',
          paymentDashboard: 'Payment Dashboard',
          paymentTransactions: 'Payment Transactions',
          posDiscount: 'POS Discounts',
          posMenu: 'POS Menu',
          promoCodes: 'Promo Codes',
          regionalMenus: 'Regional Menus',
          reporting: 'Reporting',
          segmentations: 'Segmentations',
          tipReports: 'Tip Reports',
          users: 'Users',
          webApp: 'Web App'
        }
      };
    },

    computed: {
      ...mapGetters('session', ['currentUser', 'isCardfreeAdmin', 'isMultiMerchantUser']),

      hideSidebarOptions() {
        if (!this.$_selectedMerchant) {
          return false;
        }
        const { isSundry, hasNoStores, isTemplate } = this.$_selectedMerchant;
        return isSundry && hasNoStores && !isTemplate;
      },

      tippyOptions() {
        return {
          onShow: () => !this._isExpanded,
          placement: 'right',
          distance: 16,
          size: 'larger',
          arrow: false,
          duration: 0,
          touch: false
        };
      },

      avatarUrl() {
        return this.$_selectedMerchant?.merchantOption?.avatarUrl;
      },

      isFetchingSelectedMerchant() {
        return Merchant.$state().fetchingSelectedMerchant;
      },

      sidebarSectionPermissions() { // TEST
        return {
          showMenuTop: !this.isFetchingSelectedMerchant && (this.isCardfreeAdmin || this.$_selectedMerchantId || this.isMultiMerchantUser),

          showGeneral: [
            'MERCHANTS',
            'MERCHANT_CONFIGURATION',
            'USERS',
            'HOTEL_CONFIGURATION'
          ].some(key => this.$_featurePermissions[key]),

          showOperations: this.$_selectedMerchantId && [
            'KDS',
            'OPD',
            'LOCATION_MANAGEMENT'
          ].some(key => this.$_featurePermissions[key]),

          showMenuManagement: [
            'LOCATION_MENU',
            'MASTER_MENU',
            'POS_MENU',
            'REGIONAL_MENU'
          ].some(key => this.$_featurePermissions[key]),

          showApplicationConf: this.$_selectedMerchantId && [
            'CONTENT_MANAGEMENT',
            'WEB_APP_CONFIGURATION'
          ].some(key => this.$_featurePermissions[key]),

          showGuestSupport: this.$_selectedMerchantId && [
            'ORDERS',
            'ACCOUNT_MANAGEMENT',
            'PAYMENT_TRANSACTIONS',
            'GIFT_CARD_TRANSACTIONS'
          ].some(key => this.$_featurePermissions[key]),

          showAnalytics: this.$_selectedMerchantId && ['REPORTING', 'LOYALTY_REPORTING', 'DATA_EXPORTS'].some(key => this.$_featurePermissions[key]),

          showMarketing: this.$_selectedMerchantId && [
            'LOYALTY',
            'CAMPAIGNS',
            'SEGMENTATIONS',
            'PROMO_CODES',
            'OFFERS'
          ].some(key => this.$_featurePermissions[key])
        };
      },

      _isExpanded() {
        if (!this.$screen.widescreen) return true;
        return this.isExpanded;
      }
    },

    watch: {
      $route: 'handleRouteChange'
    },

    mounted() {
      this.onMounted();
    },

    methods: {
      toggleLink(e) {
        if (this.isCardfreeAdmin && e.detail === 4) {
          this.showLink = true;

          const sound = new Audio('/audio/item.mp3');
          sound.addEventListener('loadeddata', () => {
            setTimeout(() => {
              this.showLink = false;
            }, (sound.duration * 1000) + 1500);
          });

          sound.play();
        }
      },

      onMounted() {
        const savedSidebarState = storage.local.get('sidebar-expanded');
        this.isExpanded = savedSidebarState === undefined || savedSidebarState;
      },

      getUserInitials(name) {
        const splitName = name.split(' ');
        if (splitName.length === 1) {
          return splitName[0][0].toUpperCase();
        }
        return (splitName[0][0] + splitName[1][0]).toUpperCase();
      },

      closeSidebar(e = {}) {
        const clickedHamburger = e.target?.id === 'hamburger';

        if (this.sidebarVisible && !clickedHamburger) {
          this.$emit('close-sidebar');
        }
      },

      toggleSidebarCollapse() {
        this.isExpanded = !this.isExpanded;
        storage.local.set('sidebar-expanded', this.isExpanded);
      },

      handleRouteChange() {
        this.closeSidebar();
      },

      openSignOutConfirmation() {
        this.$buefy.dialog.confirm({
          title: 'Log Out?',
          message: 'Are you sure you want to log out?',
          hasIcon: true,
          icon: 'sign-out-alt',
          type: 'is-danger',
          onConfirm: this.signOut
        });
      },

      signOut() {
        this.$store.dispatch('session/signOut');
        this.$router.push({ name: 'signIn' });
      }
    }
  };
</script>

<style lang="sass" scoped>
  .sidebar
    display: flex
    flex: 0 0 $sidebar-width
    flex-direction: column
    height: 100%
    background-color: $white
    box-shadow: 3px 0 5px rgba(0, 0, 0, 0.1)
    overscroll-behavior: auto contain
    z-index: 7

    &.sidebar-collapsed
      width: 75px
      flex: 0 0 auto

  .sidebar-header
    padding: 1rem 0
    border-bottom: 2px solid $white-ter

  .sidebar-section
    &:not(:last-child)
      margin-bottom: 0.75rem
      padding-bottom: 0.75rem

    .sidebar-collapsed &:not(:last-child)
      border-bottom: 2px solid $white-ter

  .sidebar-section-title
    padding: 0 0.5rem
    margin-bottom: 0.5rem
    font-size: 0.8rem
    font-weight: 600
    color: $grey-dark
    text-transform: uppercase

    .sidebar-collapsed & // hide section titles in collapsed view
      display: none

  .sidebar-nav-item
    a
      display: flex
      align-items: center
      .sidebar-collapsed &
        justify-content: center

    .icon
      width: 1.5rem
      height: 1.5rem
      color: $grey-light

    .link-heart
      width: 1.5rem
      animation: jump 500ms

  .sidebar-nav-item-label
    margin-left: 1rem
    .sidebar-collapsed &
      display: none

  .selected-merchant-container
    display: flex
    flex-direction: column
    align-items: center

    .selected-merchant-logo
      max-height: 80px
      padding: 0 0.5rem
      margin-bottom: 0.25rem

    .selected-merchant-name
      text-align: center
      font-size: 1.5rem

    &.dropdown-trigger
      &:hover
        cursor: pointer

        .selected-merchant-name
          color: $primary

  .sidebar-divider
    margin: 0
    width: 100%
    background-color: $grey-lightest

  .sidebar-nav-item
    & + .sidebar-nav-item
      margin-top: 6px

    a
      flex: 1
      font-weight: 600

      &:hover
        color: $dark

        &:not(.router-link-active)
          border-radius: $radius-large
          background-color: $white-ter
          .icon
            color: $black

      &.router-link-active:not(.no-active),
      &.router-link-exact-active:not(.no-active)
        border-radius: $radius-large
        box-shadow: 0 2px 3px rgba(10, 10, 10, 0.1), 0 0.5em 1em -0.125em rgba(10, 10, 10, 0.1), 0 0px 0 1px rgba(10, 10, 10, 0.02)
        .icon
          color: $primary
          animation: jump 500ms

  .menu-top
    flex-grow: 1
    overflow-y: auto
    padding: 1rem 0.75rem 0.75rem
    .sidebar-collapsed &
      padding: 1rem 0.5rem 0.75rem

  .menu-bottom
    display: flex
    flex-direction: column
    align-items: center
    position: relative
    padding: 0 0.75rem
    .sidebar-collapsed &
      padding: 0 0.5rem

  .sidebar-expand-collapse-button
    opacity: 0
    position: absolute
    top: -0.75rem
    right: -0.5rem
    z-index: 1
    box-shadow: 0px 2px 6px 1px rgba(0,0,0,0.2) !important
    transition: 200ms
    transform: scale(0.8) translateX(-$size-extra-small)

    .sidebar:hover &
      opacity: 1
      transform: scale(1)

    .sidebar-collapsed &
      right: -1rem
      ::v-deep.icon
        transform: rotateY(180deg)

  @media (hover: none) // Touchscreen devices only
    .sidebar-expand-collapse-button
      opacity: 1
      transform: scale(1)



  @media (max-width: $mobile) // Smartphone View only
    .sidebar
      width: 100% !important



  @media (max-width: $widescreen) // Smallscreen View only
    .sidebar
      position: absolute
      top: $navbar-height
      left: 0
      bottom: 0
      height: auto
      z-index: 30
      width: 320px
      transition: transform 200ms
      transform: translateX(-100%)

      &.sidebar-open
        transform: translateX(0)

    .sidebar-expand-collapse-button
      display: none



  @keyframes jump
    0%
      transform: scale(1)
    50%
      transform: scale(1.2)
    75%
      transform: scale(0.88)
    100%
      transform: scale(1)

</style>
