<template>
  <validated-form
    ref="form"
    auto-focus
    form-id="addGiftCardFundsForm"
    @valid-submit="handleSubmit"
  >
    <modal-card
      title="Merge Gift Cards"
      class="modal-card"
    >
      <b-message type="is-primary" class="is-compact has-shadow">
        <p>
          Select the gift cards below you would like to merge into {{ formatGiftCard(giftCard) }}?
        </p>
      </b-message>

      <div class="is-grid col-2">
        <transition-group name="fade-zoom" tag="div" class="is-flex-wrap gap-sm pad-sm align-start has-border-right has-border-grey-light">
          <div
            v-for="mergeableGiftCard in mergeableGiftCards"
            :key="mergeableGiftCard.id"
            class="card pad-xs has-background-white-bis has-cursor-pointer"
            @click="selectedGiftCards.push(mergeableGiftCard)"
          >
            <p>
              <b>{{ mergeableGiftCard.balance | dollars }}</b>
            </p>
            <p>
              {{ formatGiftCard(mergeableGiftCard) }}
            </p>
          </div>
        </transition-group>
        <transition-group name="fade-zoom" tag="div" class="is-flex-wrap gap-sm pad-sm align-start">
          <div
            v-for="selectedGiftCard in selectedGiftCards"
            :key="selectedGiftCard.id"
            class="card has-background-primary-light has-border has-border-primary is-light pad-xs has-extra-shadow"
          >
            <p class="is-flex justify-between">
              <b>{{ selectedGiftCard.balance | dollars }}</b>
              <b-icon
                icon="times-circle"
                type="is-primary"
                class="mar-none has-hover-text-dark has-cursor-pointer"
                custom-size="fa-lg"
                @click.native="deselectGiftCard(selectedGiftCard.id)"
              />
            </p>
            <p>
              {{ formatGiftCard(selectedGiftCard) }}
            </p>
          </div>
        </transition-group>
      </div>

      <hr>

      <div class="is-flex-column align-center">
        <div class="has-text-centered">
          <p class="is-size-5">
            Original Balance:
            <span class="has-text-weight-bold">{{ giftCard.balance | dollars }}</span>
            <template v-if="mergeTotal > 0">
              <b-icon class="is-size-6 mar-x-xs" icon="long-arrow-right" />
              New Balance:
              <span class="has-text-weight-bold has-text-success">{{ newBalance | dollars }}</span>
            </template>
          </p>
        </div>
      </div>

      <template #footer>
        <div class="buttons all-bold">
          <b-button
            rounded
            @click="$_confirmCloseModal({ programmatic: true })"
          >
            Cancel
          </b-button>
          <b-button
            v-tabbable
            rounded
            :disabled="!mergeTotal"
            native-type="submit"
            type="is-primary"
            :loading="isSubmitting"
          >
            Merge
          </b-button>
        </div>
      </template>
    </modal-card>
  </validated-form>
</template>

<script>
  import confirmModalCloseMixin from '@/mixins/confirm-modal-close';

  import GiftCard from '@/store/classes/GiftCard';

  export default {
    name: 'MergeGiftCardsModal',

    mixins: [confirmModalCloseMixin],

    props: {
      giftCard: {
        type: Object,
        required: true
      },

      showFullGcNumber: {
        type: Boolean,
        default: false
      },

      registeredGuestId: {
        type: Number,
        required: true
      }
    },

    data: () => ({
      selectedGiftCards: []
    }),

    computed: {
      mergeTotal() {
        return this.selectedGiftCards.reduce((acc, giftCard) => {
          acc += parseFloat(giftCard.balance);
          return acc;
        }, 0);
      },

      newBalance() {
        return parseFloat(this.giftCard.balance) + this.mergeTotal;
      },

      isSubmitting() {
        return GiftCard.$state().updating;
      },

      selectedGiftCardIds() {
        return this.selectedGiftCards.map(giftCard => giftCard.id);
      },

      mergeableGiftCards() {
        const giftCards = GiftCard
          .query()
          .where('accountId', this.registeredGuestId)
          .where('isActive', true)
          .where(giftCard => giftCard.id !== this.giftCard.id)
          .get();

        return giftCards.filter(giftCard => !this.selectedGiftCardIds.includes(giftCard.id));
      }
    },

    methods: {
      async handleSubmit() {
        try {
          await GiftCard.mergeFunds({
            destinationCardId: this.giftCard.id,
            sourceCardIds: this.selectedGiftCardIds
          });

          this.$emit('fetch-gift-cards', [this.giftCard.id, ...this.selectedGiftCardIds]);

          this.$_onRequestSuccess({
            toastOptions: {
              message: 'Successfully added funds to gift card'
            },
            options: {
              closeParent: true
            }
          });
        }

        catch (error) {
          this.$_onRequestError({
            error,
            toastOptions: {
              message: 'There was an error merging the balances of the selected gift cards'
            }
          });
        }
      },

      deselectGiftCard(giftCardId) {
        this.selectedGiftCards = this.selectedGiftCards.filter(giftCard => giftCard.id !== giftCardId);
      },

      formatGiftCard(giftCard) {
        return this.showFullGcNumber ? giftCard.cardNumber : `•••• ${giftCard.cardNumber.slice(-4)}`;
      }
    }
  };
</script>

<style lang="sass" scoped>
  .modal-card
    width: 800px
</style>
