import Queue from '@/services/queue';

export default {
  namespaced: true,

  state: {
    notifications: new Queue(),
    notificationTypes: {
      // NOTE: these are based off what CAPI sends to us in the webhook (should be PascalCase)
      newExpoStatus: {
        type: 'newExpoStatus',
        color: 'warning',
        audioTypes: ['Upcoming', 'Active'],
        displayTypes: ['Upcoming', 'Active'],
        displayMappings: {
          Upcoming: 'Order is new',
          Active: 'Order marked as Active'
        }
      },
      deliveryEvent: {
        type: 'deliveryEvent',
        color: 'success',
        audioTypes: ['CustomerArrived', 'PickupPending', 'PickupComplete', 'Cancelled'],
        displayTypes: ['CustomerArrived', 'PickupPending', 'PickupComplete', 'Cancelled'],
        displayMappings: {
          CustomerArrived: 'Customer has arrived',
          PickupPending: 'Driver arriving',
          PickupComplete: 'Driver picked up order',
          Cancelled: 'Order cancelled'
        }
      }
    }
  },

  getters: {
    notificationQueue: state => state.notifications.getQueue(),
    firstFiveNotifications: state => state.notifications.peek(5),
    queueSize: state => state.notifications.size(),
    notificationTypes: state => state.notificationTypes,
    allExpoNotificationAudioTypes: state => [...state.notificationTypes.newExpoStatus.audioTypes, ...state.notificationTypes.deliveryEvent.audioTypes],
    allExpoNotificationDisplayTypes: state => [...state.notificationTypes.newExpoStatus.displayTypes, ...state.notificationTypes.deliveryEvent.displayTypes],
    expoNotificationDisplayMappings: state => ({
      ...state.notificationTypes.newExpoStatus.displayMappings,
      ...state.notificationTypes.deliveryEvent.displayMappings
    })
  },

  mutations: {
    ADD_NOTIFICATION(state, notification) {
      state.notifications.enqueue(notification);
    },

    DISMISS_NOTIFICATION(state, index) {
      state.notifications.dequeue(index);
    },

    CLEAR_NOTIFICATIONS(state) {
      state.notifications.clear();
    }
  },

  actions: {
    addNotification({ commit }, notification) {
      commit('ADD_NOTIFICATION', notification);
    },

    dismissNotification({ commit }, index) {
      commit('DISMISS_NOTIFICATION', index);
    },

    clearNotifications({ commit }) {
      commit('CLEAR_NOTIFICATIONS');
    }
  }
};
