<template>
  <validated-form
    ref="form"
    v-slot="{}"
    form-id="merchantConnectedAccountTypesForm"
    @valid-submit="updateMerchantConnectedAccountTypes"
  >
    <sticky-save-container
      :loading="isFetching"
      :saving="isSubmitting"
      title="Connected Account Types"
    >
      <template #default>
        <div class="pad-r-xl mar-b-lg">
          <p class="subtitle is-6 has-text-grey">Enable or disable third-party integrations and authentication methods for this merchant.</p>
        </div>

        <div class="is-grid col-min-200 gap-md overflow-y-auto pad-b-sm mar-r-sm">
          <check-button
            v-for="accountType in accountTypes"
            :key="accountType.id"
            v-model="selectedAccountTypeIds"
            :native-value="accountType.id"
            :label="accountType.displayName"
          />
        </div>
      </template>
    </sticky-save-container>
  </validated-form>
</template>

<script>
  import MerchantConnectedAccountType from '@/store/classes/MerchantConnectedAccountType';
  import ConnectedAccountType from '@/store/classes/ConnectedAccountType';

  export default {
    name: 'MerchantConnectedAccountTypes',

    data() {
      return {
        selectedAccountTypeIds: []
      };
    },

    computed: {
      isSubmitting() {
        return MerchantConnectedAccountType.$state().submitting;
      },

      isFetching() {
        return MerchantConnectedAccountType.$state().fetching || ConnectedAccountType.$state().fetching;
      },

      accountTypes() {
        // BE already filters out disabled types so we can just return all of them
        return ConnectedAccountType.query().orderBy('displayName').get();
      },

      merchantAccountTypes() {
        return MerchantConnectedAccountType.all();
      }
    },

    async created() {
      await this.onCreated();
    },

    methods: {
      async onCreated() {
        await Promise.all([
          this.fetchConnectedAccountTypes(),
          this.fetchMerchantConnectedAccountTypes()
        ]);

        this.selectedAccountTypeIds = this.merchantAccountTypes.map(type => type.connectedAccountTypeId);
      },

      async fetchConnectedAccountTypes() {
        try {
          await ConnectedAccountType.fetchConnectedAccountTypes();
        }

        catch (error) {
          this.$_onRequestError({
            error,
            toastOptions: {
              message: 'There was an issue fetching the connected account types'
            }
          });
        }
      },

      async fetchMerchantConnectedAccountTypes() {
        try {
          await MerchantConnectedAccountType.fetchMerchantConnectedAccountTypes();
        }

        catch (error) {
          this.$_onRequestError({
            error,
            toastOptions: {
              message: 'There was an issue fetching the merchant connected account types'
            }
          });
        }
      },

      async updateMerchantConnectedAccountTypes() {
        try {
          await MerchantConnectedAccountType.updateMerchantConnectedAccountTypes(this.selectedAccountTypeIds);

          this.$_onRequestSuccess({
            toastOptions: { message: 'Successfully updated connected account types' }
          });
        }
        catch (error) {
          this.$_onRequestError({
            error,
            toastOptions: {
              message: 'There was an issue updating the connected account types'
            }
          });
        }
      }
    }
  };
</script>
