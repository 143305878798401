// import { Model } from '@vuex-orm/core';
import Saveable from './_Saveable';
import http from '@/services/http';
import filterObjectKeys from '@/helpers/filter-object-keys';



export default class StorePickupInstruction extends Saveable {
  static entity = 'storePickupInstructions'



  // FIELDS //////////////////////
  static fields() {
    return {
      ...super.fields(),
      id: this.attr(null),
      storeId: this.attr(null),
      fulfillmentTypeId: this.attr(null),
      platformTypeId: this.attr(null),
      menuTypeId: this.attr(null),
      pickupInstructionTypeId: this.attr(4), // pickupInstructionType = custom
      pickupInstructionMessageOverride: this.attr(null)
    };
  }



  // STATE //////////////////////
  static state() {
    return {
      submitting: false
    };
  }

  static $state() {
    return this.store().state.entities.storePickupInstructions;
  }


  // ACTIONS //////////////////////
  static async addStorePickupInstructions({ storeId, storePickupInstructions }) {
    try {
      this.commit((state) => {
        state.submitting = true;
      });

      const acceptedKeys = [
        'fulfillmentTypeId',
        'pickupInstructionTypeId',
        'platformTypeId',
        'menuTypeId',
        'pickupInstructionMessageOverride'
      ];

      const { data } = await http.post(
        `stores/${storeId}/store_pickup_instructions`,
        { storePickupInstructions: storePickupInstructions.map(spi => filterObjectKeys(spi, acceptedKeys)) }
      );

      this.insert({ data: data.storePickupInstructions });
      this.delete(spi => storePickupInstructions.some(_spi => _spi.id === spi.id));
    }

    catch (error) {
      throw error;
    }

    finally {
      this.commit((state) => {
        state.submitting = false;
      });
    }
  }

  static async updateStorePickupInstructions({ storeId, storePickupInstructions }) {
    try {
      this.commit((state) => {
        state.submitting = true;
      });

      const acceptedKeys = [
        'id',
        'fulfillmentTypeId',
        'platformTypeId',
        'menuTypeId',
        'pickupInstructionTypeId',
        'pickupInstructionMessageOverride'
      ];

      const { data } = await http.put(
        `stores/${storeId}/store_pickup_instructions`,
        { storePickupInstructions: storePickupInstructions.map(spi => filterObjectKeys(spi, acceptedKeys)) }
      );

      this.insert({ data: data.storePickupInstructions });
    }

    catch (error) {
      throw error;
    }

    finally {
      this.commit((state) => {
        state.submitting = false;
      });
    }
  }
}
