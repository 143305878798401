import _Order from './_Order';
import http from '@/services/http';


export const normalizeOrderSearchToDetails = (order) => {
  const normalizedOrder = {
    orderId: order.orderId,
    ticketNumber: order.ticketNumber,
    orderType: order.orderType,
    fulfillmentType: order.fulfillmentType,
    category: order.category,
    orderDate: order.orderDate,
    status: order.status,
    preparationStatus: order.preparationStatus,
    paymentState: order.paymentState,
    totalWithTipAndCharges: order.totalWithTipAndCharges,
    guestFullName: order.customerFullName,
    tableNumber: order.tableNumber,
    email: order.customerEmailAddress,
    externalPaymentAmount: order.externalPaymentAmount,
    payments: order.payments.map(p => ({
      id: p.id,
      paymentType: p.paymentInstrument?.paymentMethod,
      cardNumberLastFour: p.paymentInstrument?.accountNumberLastFour,
      cardType: p.paymentInstrument?.cardType,
      amount: p.amount,
      tax: p.tax,
      serviceCharges: p.serviceCharges,
      deliveryCharges: p.deliveryCharges,
      shipmentFee: p.shipmentFee,
      tip: p.tip,
      receiptToken: p.receiptToken,
      receiptUrl: p.receiptUrl,
      allowRefundOnDevice: p.allowRefundOnDevice,
      allowRefundOnPortal: p.allowRefundOnPortal,
      transactions: p.transactions.map(t => ({
        paymentTransaction: {
          paymentTransactionType: t.transactionType,
          paymentTransactionStatus: t.status,
          amount: t.amount,
          currencyCode: t.currencyCode
        }
      }))
    })),
    phoneNumber: order.customerPhoneNumber,
    pickupDate: order.pickupRequestedDate,
    platformType: order.platformType,
    store: {
      ...order.store,
      description: order.store.storeName
    },
    merchantOrderReference: order.merchantOrderReference,
    internalNote: order.internalNote,
    isGuest: order.isGuest,
    accountId: order.accountId,

    // for FE use only
    sortOrder: order.sortOrder,

    // currently this is only getting returned by order search. can be removed once returned by order details
    merchantId: order.merchantId
  };
  delete normalizedOrder.store.storeName;

  return normalizedOrder;
};

export default class Order extends _Order {
  static entity = 'orders';

  static primaryKey = 'orderId';

  // FIELDS //////////////////////
  static fields() {
    return {
      ...super.fields()
    };
  }



  // STATE //////////////////////
  static state() {
    return {
      fetching: false,
      fetchingSingle: false,
      submitting: false,
      cancelling: false,
      refunding: false,
      exporting: false,
      retryingOrClosing: false,
      capturingPayment: false,
      ordersTotalCount: null
    };
  }

  get orderTotalWithExternalPayment() {
    return super.orderTotalWithExternalPayment;
  }

  static $state() {
    return this.store().state.entities.orders;
  }



  // ACTIONS //////////////////////
  static async searchOrders({
    param,
    query,
    locationId,
    orderStatus,
    preparationStatus,
    orderType,
    tipAdjusted,
    orderFeature,
    platformType,
    fulfillmentType,
    startDate,
    endDate,
    sortBy,
    page = 1,
    perPage = 20
  }) {
    try {
      this.commit((state) => {
        state.fetching = true;
      });

      this.deleteAll();

      const from = (page - 1) * perPage;

      const merchantId = this.store().state.entities.merchants.selectedMerchantId;
      const queries = [
        param && query && `${param}=${encodeURIComponent(query)}`,
        locationId && `locationId=${locationId}`,
        orderStatus && `orderStatus=${orderStatus}`,
        preparationStatus && `preparationStatus=${preparationStatus}`,
        orderType && `orderType=${orderType}`,
        tipAdjusted && `tipAdjusted=${tipAdjusted}`,
        orderFeature && `orderFeature=${orderFeature}`,
        platformType && `platformType=${platformType}`,
        fulfillmentType && `fulfillmentType=${fulfillmentType}`,
        startDate && `startDate=${startDate}`,
        endDate && `endDate=${endDate}`,
        sortBy && `sortBy=${sortBy}`,
        `size=${perPage}`,
        `from=${from}`
      ].filter(q => q);

      const queryString = queries.length ? `?${queries.join('&')}` : '';

      const {
        data: { orders, totalCount }
      } = await http.get(`merchants/${merchantId}/orders/search${queryString}`);

      this.commit((state) => {
        state.ordersTotalCount = totalCount;
      });

      this.create({
        data: orders.map((order, index) => {
          // assigns a "sort order" to Orders using the array index to retain
          // its sort position as it was when it was returned from the endpoint
          order.sortOrder = index;
          return normalizeOrderSearchToDetails(order); // converts an "Order Search" order into the shape of an "Order Details" order
        })
      });
    }

    catch (error) {
      throw error;
    }

    finally {
      this.commit((state) => {
        state.fetching = false;
      });
    }
  }

  static async exportToCSV({
    param,
    query,
    locationId,
    orderStatus,
    preparationStatus,
    orderType,
    orderFeature,
    tipAdjusted,
    startDate,
    endDate,
    sortBy
  }) {
    try {
      this.commit((state) => {
        state.exporting = true;
      });
      const merchantId = this.store().state.entities.merchants.selectedMerchantId;

      const queries = [
        param && query && `${param}=${encodeURIComponent(query)}`,
        locationId && `locationId=${locationId}`,
        orderStatus && `orderStatus=${orderStatus}`,
        preparationStatus && `preparationStatus=${preparationStatus}`,
        orderType && `orderType=${orderType}`,
        orderFeature && `orderFeature=${orderFeature}`,
        tipAdjusted && `tipAdjusted=${tipAdjusted}`,
        startDate && `startDate=${startDate}`,
        endDate && `endDate=${endDate}`,
        sortBy && `sortBy=${sortBy}`
      ].filter(q => q);

      const queryString = queries.length ? `?${queries.join('&')}` : '';
      const response = await http.get(`merchants/${merchantId}/orders/search.csv${queryString}`);

      return response.data;
    }

    catch (error) {
      throw error;
    }

    finally {
      this.commit((state) => {
        state.exporting = false;
      });
    }
  }

  // eslint-disable-next-line object-curly-newline
  static async refundPayment({ orderId, paymentId, amount, currencyCode, refundedOrderItems = [] }) {
    try {
      this.commit((state) => {
        state.refunding = orderId;
      });

      const merchantId = this.store().state.entities.merchants.selectedMerchantId;

      await http.put(`merchants/${merchantId}/payments/refund`, {
        orderId,
        paymentId,
        amount,
        currencyCode,
        refundedOrderItems
      });
    }

    catch (error) {
      throw error;
    }

    finally {
      this.commit((state) => {
        state.refunding = false;
      });
    }
  }

  static async getOrderDetails(orderId, { includeLoyaltyEngagementSummary, includeAccount } = {}) {
    try {
      this.commit((state) => {
        state.fetchingSingle = true;
      });

      const merchantId = this.store().state.entities.merchants.selectedMerchantId;
      const params = {
        ...(includeLoyaltyEngagementSummary && { includeLoyaltyEngagementSummary }),
        ...(includeAccount && { includeAccount })
      };

      const { data } = await http.get(`merchants/${merchantId}/orders/${orderId}`, { params });

      this.insertOrUpdate({
        data: data.order,
        where: orderId
      });
    }

    catch (error) {
      throw error;
    }

    finally {
      this.commit((state) => {
        state.fetchingSingle = false;
      });
    }
  }

  static async cancelOrder(orderId) {
    try {
      this.commit((state) => {
        state.cancelling = orderId;
      });

      const merchantId = this.store().state.entities.merchants.selectedMerchantId;
      const { data } = await http.put(`merchants/${merchantId}/orders/${orderId}/cancel`);

      this.update({
        data: data.order
      });
    }

    catch (error) {
      throw error;
    }

    finally {
      this.commit((state) => {
        state.cancelling = false;
      });
    }
  }

  static async retryPaymentOrCloseOrder(orderId) {
    try {
      this.commit((state) => {
        state.retryingOrClosing = true;
      });

      const merchantId = this.store().state.entities.merchants.selectedMerchantId;
      await http.post(
        `merchants/${merchantId}/payments/complete`,
        { orderId }
      );
    }

    catch (error) {
      throw error;
    }

    finally {
      this.commit((state) => {
        state.retryingOrClosing = false;
      });
    }
  }

  static async capturePayment(orderId) {
    try {
      this.commit((state) => {
        state.capturingPayment = orderId;
      });

      const merchantId = this.store().state.entities.merchants.selectedMerchantId;
      const { data } = await http.put(`merchants/${merchantId}/orders/${orderId}/capture_payment`);

      this.update({
        data: data.order
      });
    }
    catch (error) {
      throw error;
    }
    finally {
      this.commit((state) => {
        state.capturingPayment = false;
      });
    }
  }

  static resetState() {
    this.commit((state) => {
      state.fetching = false;
      state.ordersTotalCount = null;
    });
    this.deleteAll();
  }
}
