<template>
  <div>
    <review-row
      v-for="(row, idx) in awardRows"
      :key="`reviewRow${idx}`"
      :title="row.title"
      :subtitle="row.subtitle"
      :hide-bottom-border="idx === awardRows.length - 1"
    />
  </div>
</template>

<script>
  import { conversionThresholdAwardPolicyTypes, conversionThresholdRunLimitPolicyTypes } from '@/constants/conversionThresholds';
  import { randomAwardOptionTypes } from '@/constants/earnRules';

  import Currency from '@/store/classes/Currency';
  import Offer from '@/store/classes/Offer';

  export default {
    name: 'AwardCard',

    props: {
      value: {
        type: Object,
        required: true
      },
      currency: {
        type: Object,
        required: true
      }
    },

    computed: {
      primaryCurrency() {
        return Currency.primaryCurrency();
      },

      awardRows() {
        return [
          { title: 'Award Type', subtitle: this.awardTypeDisplay },
          { title: 'Threshold', subtitle: this.thresholdDisplay },
          { title: 'Award', subtitle: this.awardDisplay },
          { title: 'Run Limit', subtitle: this.runLimitDisplay }
        ];
      },

      awardTypeDisplay() {
        switch (this.value.awardPolicy.conversionThresholdAwardPolicyType) {
          case conversionThresholdAwardPolicyTypes.POINT_CONVERSION.type:
            return this.primaryCurrency.formattedPluralName();
          case conversionThresholdAwardPolicyTypes.OFFER_CONVERSION.type:
            return 'Offer';
          case conversionThresholdAwardPolicyTypes.RANDOM_AWARD.type:
            return `Random Offer or ${this.primaryCurrency.formattedPluralName()}`;
          case conversionThresholdAwardPolicyTypes.GIFT_CARD.type:
            return 'Gift Card';
          default:
            return '<Not set>';
        }
      },

      thresholdDisplay() {
        return `${this.value.cost || '<Threshold not set>'} ${this.currency.pluralName || '<Currency name not set>'}`;
      },

      awardDisplay() {
        switch (this.value.awardPolicy.conversionThresholdAwardPolicyType) {
          case conversionThresholdAwardPolicyTypes.POINT_CONVERSION.type:
            return `${this.value.awardPolicy?.points} ${this.primaryCurrency.formattedPluralName()}`;
          case conversionThresholdAwardPolicyTypes.GIFT_CARD.type:
            return this.value.awardPolicy?.amount ? `${this.$options.filters.dollars(this.value.awardPolicy.amount)} Gift Card` : '<Not set>';
          case conversionThresholdAwardPolicyTypes.OFFER_CONVERSION.type: {
            const earnRuleOffer = this.findOffer(this.value.awardPolicy?.cardfreeOfferPublicId);
            return earnRuleOffer?.name || '<Not set>';
          }
          case conversionThresholdAwardPolicyTypes.RANDOM_AWARD.type: {
            const totalWeight = this.value.awardPolicy?.weightedAwardOptions.reduce((acc, { weight }) => acc + weight, 0);

            return this.value.awardPolicy?.weightedAwardOptions.map((weightedAwardOption) => {
              const calculatePercentage = (weight) => {
                const percentage = (weight / totalWeight) * 100;
                return percentage.toFixed(1);
              };

              const optionDescription = weightedAwardOption.awardOption.awardOptionType === randomAwardOptionTypes.OFFER
                ? this.findOffer(weightedAwardOption.awardOption.reward.cardfreeOfferPublicId)?.name || '<Not set>'
                : `${weightedAwardOption.awardOption.points} Points`;

              return `${optionDescription} (${weightedAwardOption.weight} - ${calculatePercentage(weightedAwardOption.weight)}%)`;
            }).join(', ');
          }
          default:
            return '<Not set>';
        }
      },

      runLimitDisplay() {
        if (this.value.runLimitPolicy.runLimitPolicyType === conversionThresholdRunLimitPolicyTypes.UNLIMITED) {
          return 'Unlimited';
        }
        else {
          return `Guest can earn award ${this.value.runLimitPolicy.maximumRuns} time${this.value.runLimitPolicy.maximumRuns > 1 ? 's' : ''}`;
        }
      }
    },

    methods: {
      findOffer(offerPublicId) {
        return Offer.find(offerPublicId);
      }
    }
  };
</script>
