<template>
  <modal-card
    :title="modalTitle"
    modal-card-body-class="pad-none"
    title-btn-text="View All"
    @keypress.native.enter="handleSubmit"
  >
    <scrollable-tabs>
      <b-tabs
        v-model="activeTabIndex"
        :animated="false"
        expanded
        class="is-marginless"
      >
        <b-tab-item
          v-for="tab in tabs"
          :key="tab.label"
          :visible="!tab.isHidden"
        >
          <template #header>
            <span>{{ tab.label }}</span>
          </template>
          <component
            :is="tab.component"
            v-bind="tab.props"
            v-on="tab.handlers"
          />
        </b-tab-item>
      </b-tabs>
    </scrollable-tabs>

    <template
      v-if="!readOnly"
      #footer
    >
      <div class="buttons all-bold">
        <b-button
          rounded
          @click="closeModal"
        >
          Cancel
        </b-button>
        <b-button
          v-tabbable
          rounded
          type="is-primary"
          :loading="submitting"
          @click="handleSubmit"
        >
          Save
        </b-button>
      </div>
    </template>
  </modal-card>
</template>

<script>
  import { mapActions } from 'vuex';

  // Constants
  import { modifierGroupTypes } from '@/constants/modifierGroups';

  // Helpers
  import capitalCase from '@/helpers/capitalCase';

  // Mixins
  import confirmModalCloseMixin from '@/mixins/confirm-modal-close';

  // Tabs
  import ModifierGroupSettingsTab from './shared-modifier-group-tabs/modifier-group-settings-tab.vue';
  import ModifierGroupModifiersTab from './shared-modifier-group-tabs/modifier-group-modifiers-tab.vue';
  import ModifierGroupItemsTab from './shared-modifier-group-tabs/modifier-group-items-tab.vue';

  import Modifier from '@/store/classes/Modifier';
  import ModifierGroup from '@/store/classes/ModifierGroup';
  import MenuItemModifierGroupItem from '@/store/classes/MenuItemModifierGroupItem';

  export default {
    name: 'SharedModifierGroupModal',

    mixins: [confirmModalCloseMixin],

    props: {
      modifierGroupId: {
        type: Number,
        default: null
      },

      menuItemId: {
        type: [String, Number],
        default: null
      },

      menuItemModifierId: {
        type: Number,
        default: null
      },

      readOnly: {
        type: Boolean,
        default: false
      },

      type: {
        type: String,
        default: ''
      }
    },

    data() {
      return {
        activeTabIndex: 0,
        modifierGroup: null
      };
    },

    computed: {
      submitting() {
        return ModifierGroup.$state().submitting || MenuItemModifierGroupItem.$state().submitting;
      },

      tabs() {
        return [
          {
            label: 'Settings',
            component: ModifierGroupSettingsTab,
            props: {
              modifierGroup: this.modifierGroup,
              readOnly: this.readOnly
            },
            handlers: {
              input: this.setModifierGroup
            }
          },
          {
            label: 'Modifiers',
            component: ModifierGroupModifiersTab,
            isHidden: !this.modifierGroupId,
            props: {
              modifierGroup: this.modifierGroup,
              readOnly: this.readOnly
            },
            handlers: {
              'requery-modifiers': this.setModifiers
            }
          },
          {
            label: 'Items',
            component: ModifierGroupItemsTab,
            isHidden: !this.modifierGroupId,
            props: {
              modifierGroup: this.modifierGroup,
              readOnly: this.readOnly
            },
            handlers: {
              'requery-items': this.setMenuItemModifierGroupItems
            }
          }
        ];
      },

      modalTitle() {
        if (this.modifierGroupId) {
          return this.modifierGroup.displayName;
        }
        else if (Object.values(modifierGroupTypes).includes(this.type)) {
          return `New ${capitalCase(this.type)} Group`;
        }
        else {
          return 'New Modifier Group';
        }
      }
    },

    async created() {
      await this.onCreated();
    },

    methods: {
      // The ModiferGroupSettingsTab uses a ValidatedForm wrapper to track changes to its child form
      // But, we are then bypassing the submit event on the ValidatedForm and handling submission here
      // This causes formStore.hasChanged to not reset properly on form submission, so we need
      // to manually set it to false here
      ...mapActions('formStore', ['setFormChanged']),

      onCreated() {
        this.modifierGroup = this.modifierGroupId
          ? ModifierGroup.query().with(['menuItemModifiers', 'menuItemModifierGroupItems.menuItem']).find(this.modifierGroupId)
          : new ModifierGroup({ type: this.type });
      },

      setModifierGroup(modifierGroup) {
        this.modifierGroup = modifierGroup;
      },

      setModifiers() {
        const modifiers = Modifier.query().where('modifierGroupId', this.modifierGroup.id).get();
        this.modifierGroup.menuItemModifiers = modifiers;
      },

      setMenuItemModifierGroupItems() {
        const menuItemModifierGroupItems = MenuItemModifierGroupItem.query().with('menuItem').where('modifierGroupId', this.modifierGroup.id).get();
        this.modifierGroup.menuItemModifierGroupItems = menuItemModifierGroupItems;
      },

      async closeModal() {
        const confirmed = await this.$_confirmCloseModal({ programmatic: true });
        if (confirmed) {
          this.setFormChanged({ hasChanged: false });
          this.$emit('close');
        }
      },

      async handleSubmit() {
        if (!this.modifierGroup.id) {
          await this.addModifierGroup();
        }
        else {
          await this.updateModifierGroup();
        }

        this.setFormChanged({ hasChanged: false });
        this.$emit('close');
      },

      async addModifierGroup() {
        try {
          const modifierGroup = await ModifierGroup.addModifierGroup({ modifierGroup: this.modifierGroup });
          if (this.menuItemId) {
            const menuItemModifierGroupItem = {
              menuItemId: this.menuItemId,
              menuItemModifierId: this.menuItemModifierId,
              menuItemModifierGroupId: modifierGroup.id
            };

            await MenuItemModifierGroupItem.createMenuItemModifierGroupItems([menuItemModifierGroupItem]);
          }
          this.$_onRequestSuccess({
            toastOptions: {
              message: `Successfully added <b>${modifierGroup.displayName}</b> to your modifier groups!`
            },
            options: {
              closeParent: true,
              emit: { name: 'main-menu:add-modifier-group', arg: modifierGroup.id }
            }
          });
          this.$emit('close');
        }

        catch (error) {
          this.$_onRequestError({
            error,
            toastOptions: {
              message: `Unable to add the <b>${this.modifierGroup.displayName}</b> modifier group`
            }
          });
        }
      },

      async updateModifierGroup() {
        try {
          const modifierGroup = await ModifierGroup.updateModifierGroup(this.modifierGroup);

          this.$_onRequestSuccess({
            toastOptions: {
              message: `Successfully updated <b>${this.modifierGroup.displayName}!</b>`
            },
            options: {
              emit: { name: 'modifier-group-updated' }
            }
          });

          return modifierGroup;
        }

        catch (error) {
          this.$_onRequestError({
            error,
            toastOptions: {
              message: `Unable to update the <b>${this.modifierGroup.displayName}</b> modifier group`
            }
          });
        }
      }
    }
  };
</script>

<style scoped lang="sass">
  .modal-card
    width: 550px !important
    margin: 0 auto !important

</style>
