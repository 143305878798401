<template>
  <validated-form
    ref="form"
    auto-focus
    form-id="addEditStoreHoursOverrideForm"
    @valid-submit="handleSubmit"
  >
    <modal-card :title="modalTitle">
      <validated-text-input
        v-model="form.name"
        :has-icon="false"
        label="Reason"
        name="name"
        type="text"
        rules="required"
        class="mar-b"
      />

      <validated-input name="date" label="Date" rules="required">
        <div class="is-flex justify-center">
          <b-datepicker
            v-model="form.date"
            inline
            placeholder="Select a date..."
            icon="calendar-alt"
            :min-date="minDate"
            :events="events"
            indicators="bars"
          />
        </div>
      </validated-input>

      <div class="is-flex justify-center dist-x-xl">
        <p class="is-flex align-center dist-x-xs">
          <b-icon size="is-small" icon="minus" type="is-dark" />
          <span class="is-size-7">Adjusted Hours</span>
        </p>
        <p class="is-flex align-center dist-x-xs">
          <b-icon size="is-small" icon="minus" type="is-danger" />
          <span class="is-size-7 has-text-danger-dark">Closed</span>
        </p>
      </div>

      <b-field class="button-group mar-b mar-t-lg">
        <b-radio-button v-model="form.isOpen" type="is-dark" :native-value="true">
          Adjusted hours
        </b-radio-button>
        <b-radio-button v-model="form.isOpen" type="is-danger" :native-value="false">
          Closed
        </b-radio-button>
      </b-field>

      <div v-if="form.isOpen" class="is-flex justify-between dist-x-lg animated tdFadeInDown" style="animation-duration: 500ms;">
        <validated-input
          :rules="{ required: form.isOpen }"
          name="openTime"
          label="Open"
          class="is-marginless"
        >
          <b-timepicker
            v-model="form.openTime"
            size="is-small"
            :default-minutes="0"
            hour-format="12"
            inline
          />
        </validated-input>
        <validated-input
          :rules="{ required: form.isOpen }"
          name="closeTime"
          label="Close"
          class="is-marginless"
          :message="getHoursMessage()"
        >
          <b-timepicker
            v-model="form.closeTime"
            size="is-small"
            :default-minutes="0"
            hour-format="12"
            inline
          />
        </validated-input>
      </div>

      <template #footer>
        <div class="buttons all-bold">
          <b-button
            rounded
            @click="$_confirmCloseModal({ programmatic: true })"
          >
            Cancel
          </b-button>
          <b-button
            v-tabbable
            rounded
            native-type="submit"
            type="is-primary"
            :loading="isSubmittingStoreHoursOverride"
          >
            Save
          </b-button>
        </div>
      </template>
    </modal-card>
  </validated-form>
</template>

<script>
  import StoreHoursOverride from '@/store/classes/StoreHoursOverride';
  import logger from '@/services/logger';
  import confirmModalCloseMixin from '@/mixins/confirm-modal-close';
  import merchantMixin from '@/mixins/merchant';
  import moment from 'moment-timezone';

  export default {
    name: 'AddEditStoreHoursOverrideModal',

    mixins: [confirmModalCloseMixin, merchantMixin],

    props: {
      storeHoursOverride: {
        type: Object,
        default: null
      },

      mode: {
        type: String,
        default: 'create',
        validator(value) {
          return ['create', 'read', 'update'].includes(value);
        }
      },
      storeId: {
        type: Number,
        default: null
      }
    },

    data() {
      return {
        form: null,
        minDate: moment()
          .seconds(0)
          .milliseconds(0)
          .subtract(1, 'day')
          .toDate()
      };
    },

    computed: {
      events() {
        return StoreHoursOverride.query()
          .where('storeId', this.storeId)
          .get()
          .filter(sho => sho.id !== this.storeHoursOverride?.id)
          .map(sho => ({
            date: moment(sho.date, 'YYYY-MM-DD').toDate(),
            type: sho.isOpen ? 'is-dark' : 'is-danger'
          }));
      },

      formattedStoreHoursOverride() {
        const formattedOpen = this.formatOverrideForRequest(this.form.openTime);
        const formattedClose = this.formatOverrideForRequest(this.form.closeTime);
        const formattedDate = moment(this.form.date).format('YYYY-MM-DD');

        return {
          ...this.form,
          date: formattedDate,
          openTime: formattedOpen,
          closeTime: formattedClose
        };
      },

      modalTitle() {
        let title = '';
        let action;
        switch (this.mode) {
          case 'create':
            action = 'Add';
            title = !this.storeId ? `${action} Global Hours` : `${action} Special Hours`;
            break;

          case 'update':
            action = 'Edit';
            title = !this.storeId ? `${action} Global Hours` : `${action} Special Hours`;
            break;

          default:
            break;
        }
        return title;
      },

      isSubmittingStoreHoursOverride() {
        return StoreHoursOverride.$state().submitting;
      }
    },

    created() {
      this.onCreated();
    },

    methods: {
      onCreated() {
        if (this.mode === 'create') {
          this.form = new StoreHoursOverride({ storeId: this.storeId });
        }
        else {
          const cloneData = JSON.parse(JSON.stringify(this.storeHoursOverride));
          this.form = this.formatFetchedStoreHoursOverride(cloneData);
        }
      },

      getHoursMessage() {
        const isStoreClosed = !this.form.isOpen || !this.form.openTime || !this.form.closeTime;
        if (isStoreClosed) {
          return '';
        }

        const isNextDay = this.form.openTime - this.form.closeTime > 0;
        const isTwentyFourHours = this.form.openTime - this.form.closeTime === 0;
        if (isTwentyFourHours) {
          return '24 Hours';
        }
        else if (isNextDay) {
          return 'Next Day';
        }
        else {
          return '';
        }
      },

      formatFetchedStoreHoursOverride(storeHoursOverride) {
        return {
          ...storeHoursOverride,
          date: moment(storeHoursOverride.date).toDate(),
          openTime: this.formatFetchedOverrideTime(storeHoursOverride.openTime),
          closeTime: this.formatFetchedOverrideTime(storeHoursOverride.closeTime)
        };
      },

      formatFetchedOverrideTime(override) {
        return override && moment(override, 'hh:mm').toDate();
      },

      formatOverrideForRequest(override) {
        return override && moment(override).format('HH:mm');
      },

      async handleSubmit() {
        try {
          switch (this.mode) {
            case 'create':
              await this.addStoreHoursOverride();
              break;

            case 'update':
              await this.updateStoreHoursOverride();
              break;

            default:
              break;
          }
        }
        catch (error) {
          logger.error(error);
        }
      },

      async addStoreHoursOverride() {
        try {
          await StoreHoursOverride.addStoreHoursOverride(this.formattedStoreHoursOverride);

          this.$_onRequestSuccess({
            toastOptions: { message: 'Successfully added special hours!' },
            options: { closeParent: true }
          });
        }

        catch (error) {
          this.$_onRequestError({
            toastOptions: { message: 'An error occured while adding your new special hours' },
            error
          });
        }
      },

      async updateStoreHoursOverride() {
        try {
          await StoreHoursOverride.updateStoreHoursOverride(this.formattedStoreHoursOverride);

          this.$_onRequestSuccess({
            toastOptions: { message: 'Successfully updated special hours!' },
            options: { closeParent: true }
          });
        }

        catch (error) {
          this.$_onRequestError({
            toastOptions: { message: 'An error occured while updating your special hours' },
            error
          });
        }
      }
    }
  };
</script>

<style lang="sass" scoped>
  ::v-deep
    .button-group
      .field
        display: grid
        grid-template-columns: 1fr 1fr

    .has-event
      pointer-events: none
      background-color: $grey-lightest
      // .events
      //   display: none !important
</style>
