<template>
  <div>
    <hero-banner>
      <h1 class="title is-2">Account Management</h1>
    </hero-banner>

    <div class="section">
      <div class="container">
        <div class="card">
          <div class="search-section pad-x pad-t">
            <b-field
              class="search-inputs"
              label-position="on-border"
              label="Criteria"
            >
              <div class="control">
                <b-select v-model="param">
                  <option value="name">Name</option>
                  <option value="phone">Phone Number</option>
                  <option value="email">Email Address</option>
                </b-select>
              </div>
              <div class="control is-expanded mar-r">
                <b-input
                  v-model="query"
                  :icon-right-clickable="!!query"
                  :icon-right="query ? 'times-circle': ''"
                  :placeholder="placeholderText"
                  :type="queryInputType"
                  class="query-input"
                  icon="search"
                  expanded
                  @icon-right-click="clearQuery"
                  @keydown.native.esc="clearQuery"
                />
              </div>
            </b-field>
            <b-tooltip
              label="This functionality has moved to the Data Exports page"
              position="is-left"
              multilined
            >
              <b-button
                type="is-primary"
                icon-left="file-export"
                icon-pack="far"
                disabled
              >
                Export Guests
              </b-button>
            </b-tooltip>
          </div>
          <div :class="['pad-l result-count', { 'is-invisible': isLoadingGuests || !registeredGuestMetadata.count }]">
            <p v-if="registeredGuestMetadata.count < 100">
              <span class="has-text-weight-bold has-text-black">
                {{ registeredGuestMetadata.count }}
              </span>
              Matching Account{{ registeredGuestMetadata.count > 1 ? 's' : '' }}
            </p>
            <template v-else>
              <p>
                Displaying
                <span class="has-text-weight-bold has-text-black">
                  {{ registeredGuestMetadata.count }}
                </span>
                best results
              </p>
              <p class="is-size-7 has-text-grey">
                If you don’t see the account that you're looking for, please enter a more specific search
              </p>
            </template>
          </div>
          <div class="has-border-top has-border-grey-lighter">
            <b-table
              :data="isLoadingGuests ? [] : registeredGuests"
              paginated
              :total="registeredGuestMetadata.count"
              :current-page="page"
              class="is-middle-aligned no-header-wrap row-pointer registered-guest-table"
              :row-class="()=>'row-pointer'"
              scrollable
              :mobile-cards="false"
              :backend-pagination="true"
              :backend-sorting="true"
              :hoverable="true"
              :default-sort="['created_at', 'desc']"
              @click="handleAccountRowClick"
              @page-change="handlePageChange"
              @sort="handleSort"
            >
              <template slot="empty">
                <empty-table-loader
                  :loading="isLoadingGuests"
                  :has-icon="false"
                  :message="hasMadeInitialSearch ? 'No Matching Accounts Found' : 'Choose a criteria type and enter a search term to find accounts'"
                />
              </template>

              <b-table-column
                v-slot="{ row: { fullName } }"
                field="name"
                sortable
                label="Name"
                cell-class="no-wrap-text"
              >
                <span
                  :class="{'has-text-grey-light': !fullName}"
                >
                  {{ fullName || 'N/A' }}
                </span>
              </b-table-column>

              <b-table-column
                v-slot="{ row: { primarySmsNumber } }"
                field="primarySmsNumber"
                label="Phone Number"
              >
                <span :class="{'has-text-grey-light': !primarySmsNumber}">
                  {{ primarySmsNumber || 'N/A' | phoneNumber }}
                </span>
              </b-table-column>

              <b-table-column
                v-slot="{ row: { email } }"
                field="email"
                sortable
                label="Email"
                cell-class="no-wrap-text"
              >
                <span :class="{'has-text-grey-light': !email}">
                  {{ email || 'N/A' }}
                </span>
              </b-table-column>

              <b-table-column
                v-slot="{ row: { createdAt } }"
                field="created_at"
                sortable
                :label="`Date Registered (${renderedTz()})`"
              >

                {{ convertDateToTz(createdAt, merchantTz()) }}
              </b-table-column>
            </b-table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import RegisteredGuest from '@/store/classes/RegisteredGuest';
  import scrollIndicatorMixin from '@/mixins/scroll-container-indicator';
  import debounce from 'lodash.debounce';
  import merchantMixin from '@/mixins/merchant';
  import { convertDateToTz } from '@/helpers/timezoneUtils';



  export default {
    name: 'RegisteredGuestList',

    mixins: [scrollIndicatorMixin, merchantMixin],

    data() {
      return {
        page: 1,
        param: (this.$route.query?.param && decodeURIComponent(this.$route.query.param)) || 'name',
        query: (this.$route.query?.query && decodeURIComponent(this.$route.query.query)) || '',
        sortField: 'created_at',
        sortDirection: 'desc',
        hasMadeInitialSearch: false
      };
    },

    computed: {
      registeredGuests() {
        return RegisteredGuest.query().orderBy('sortOrder').get();
      },

      isLoadingGuests() {
        return RegisteredGuest.$state().fetching;
      },

      registeredGuestMetadata() {
        return RegisteredGuest.$state().registeredGuestMetadata;
      },

      placeholderText() {
        let paramName;

        switch (this.param) {
          case 'name':
            paramName = ' by name';
            break;
          case 'email':
            paramName = ' by email address';
            break;
          case 'phone':
            paramName = ' by phone number';
            break;
          default:
            paramName = '';
        }

        return `Search Accounts${paramName}...`;
      },

      queryInputType() {
        switch (this.param) {
          case 'name':
            return 'text';

          case 'email':
            return 'email';

          case 'phone':
            return 'phone';

          default:
            return 'text';
        }
      }
    },

    watch: {
      param: 'handleParamChange',
      query: 'debouncedHandleQueryChange'
    },

    mounted() {
      this.onMounted();
    },

    methods: {
      async onMounted() {
        RegisteredGuest.resetState();

        const { query, param } = this.$route.query;
        const hasSearchHistory = query && param;

        if (hasSearchHistory) {
          await this.searchRegisteredGuest({
            query: decodeURIComponent(query),
            param: decodeURIComponent(param)
          });
        }
        this.$_initScrollIndicatorSmart({
          contentSelector: '.table'
        });
      },

      clearQuery() {
        this.query = '';
      },

      handleParamChange(param) {
        if (this.query) {
          this.page = 1;
          this.searchRegisteredGuest({ param });
        }
      },

      // eslint-disable-next-line
      debouncedHandleQueryChange: debounce(function (query) {
        this.handleQueryChange(query);
      }, 666),

      handleQueryChange(newQuery) {
        const query = newQuery && this.param === 'phone'
          ? newQuery
            .split('')
            .filter(char => /[0-9]/.test(char))
            .join('')
          : newQuery;
        this.page = 1;
        this.searchRegisteredGuest({ query });
      },

      handleSort(sortField, sortDirection) {
        this.sortField = sortField;
        this.sortDirection = sortDirection;
        this.page = 1;
        this.searchRegisteredGuest({ sortField, sortDirection });
      },

      handlePageChange(page) {
        this.page = page;
        this.searchRegisteredGuest({ page });
      },

      async searchRegisteredGuest({
        param = this.param,
        query = this.query,
        sortField = this.sortField,
        sortDirection = this.sortDirection,
        page = this.page
      } = {}) {
        try {
          await RegisteredGuest.fetchRegisteredGuests({
            sortField, sortDirection, page, param, query
          });

          this.updateRoute({ newParam: param, newQuery: query });

          if (!this.hasMadeInitialSearch) {
            this.hasMadeInitialSearch = true;
          }
        }
        catch (error) {
          this.$_onRequestError({
            error,
            toastOptions: { message: 'There was an error loading accounts.' }
          });
        }
      },

      updateRoute({ newParam, newQuery }) {
        const { param: oldParam, query: oldQuery } = this.$route.query;
        const hasSearchChanged = (newParam && newQuery) && (newParam !== oldParam || newQuery !== oldQuery);

        if (!newQuery && (oldParam || oldQuery)) {
          this.$router.replace({ query: null });
        }
        else if (hasSearchChanged) {
          this.$router.replace({
            query: {
              param: encodeURIComponent(newParam),
              query: encodeURIComponent(newQuery)
            }
          });
        }
      },

      handleAccountRowClick(row) {
        this.$router.push({ name: 'registeredGuestDetails', params: { userId: row.id } });
      },

      renderedTz() {
        return this.$_selectedMerchant.defaultOfferRedemptionOrDefaultUserTimezoneString;
      },

      merchantTz() {
        return this.$_selectedMerchant.defaultOfferRedemptionOrDefaultUserTimezone;
      },

      convertDateToTz(createdAt, merchantTz) {
        return convertDateToTz(createdAt, merchantTz);
      }
    }
  };
</script>

<style lang="sass" scoped>
  .search-section
    display: flex

    .query-input,
    .search-inputs
      flex: 1 1

  .result-count
      padding: 0.5rem 0

  ::v-deep
    tr.row-pointer:hover
      cursor: pointer
</style>
