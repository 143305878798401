<template>
  <modal-card
    :title="!isFetchingModifier && modalTitle"
    modal-card-body-class="pad-none"
    title-btn-text="View All"
    @closed="$_clearFormStore"
    @keypress.native.enter="$_handleSubmitForms"
  >
    <b-loading
      v-if="isFetchingModifier"
      :active="isFetchingModifier"
      :is-full-page="false"
    />

    <template v-if="!isFetchingModifier" #subtitle>
      <div v-if="modifier && modifier.posMenuItemModifierMappings && modifier.posMenuItemModifierMappings.length" class="is-flex align-baseline">
        <p class="mar-t-xs is-size-6">
          <span class="mar-r-xs">
            PLU:
          </span>
          <span
            v-for="(mapping, index) in modifier.posMenuItemModifierMappings"
            :key="index"
            :class="['has-text-weight-bold', { 'has-text-danger': !mapping.posMenuItem && $_selectedMerchant.numUniquePosTypes <= 1 }]"
          >
            {{ `#${mapping.posMenuItemId}` }}
            <span class="has-text-black">{{ (modifier.posMenuItemModifierMappings.length - 1 > index) ? ', ' : '' }}</span>
          </span>
        </p>
        <a
          class="is-primary is-size-6 has-text-weight-bold mar-l-xs"
          @click="navigateToTabId = 'pos-mappings-tab'"
        >
          View
        </a>
      </div>
    </template>
    <scrollable-tabs>
      <modal-tabs
        v-slot="{ activeTabId }"
        :tabs="tabs"
        :invalid-tab-indices="invalidTabIndexes"
        :navigate-to-tab-id="navigateToTabId"
        :disable-tabs="isFetchingModifier"
        @clear-navigate-to-tab-id="navigateToTabId = null"
      >
        <keep-alive>
          <component
            :is="getActiveTab(activeTabId).component"
            :ref="`${activeTabId}-component`"
            :key="activeTabId"
            v-bind="getActiveTab(activeTabId).props"
          />
        </keep-alive>
      </modal-tabs>
    </scrollable-tabs>

    <template v-if="($can('update', 'MenuItem') && $_menuPermissions.EDIT_RESOURCE) || ($can('update', 'MenuItemSchedule') && $_menuPermissions.MANAGE_DAYPART_SCHEDULE)" #footer>
      <div class="buttons all-bold">
        <b-button
          rounded
          @click="closeModal"
        >
          Cancel
        </b-button>
        <b-button
          v-tabbable
          rounded
          type="is-primary"
          :loading="isSubmittingForms"
          @click="$_handleSubmitForms"
        >
          Save
        </b-button>
      </div>
    </template>
  </modal-card>
</template>

<script>
  import confirmModalCloseMixin from '@/mixins/confirm-modal-close';
  import featurePermissionsMixin from '@/mixins/featurePermissions';
  import merchantMixin from '@/mixins/merchant';
  import multiFormParentProvider from '@/mixins/multiFormMixin/multiFormParentProvider';

  import MenuItemModifierImage from '@/store/classes/MenuItemModifierImage';
  import Modifier from '@/store/classes/Modifier';
  import PosMenuItem from '@/store/classes/PosMenuItem';
  import Store from '@/store/classes/Store';

  import modifierDetailsForm from './modifier-details-form.vue';
  import modifierPosMappingForm from './modifier-pos-mapping-form.vue';


  export default {
    name: 'AddEditModifierModal',

    mixins: [confirmModalCloseMixin, featurePermissionsMixin, merchantMixin, multiFormParentProvider],

    props: {
      modifierId: {
        type: Number,
        default: null
      },

      modifierGroup: {
        type: Object,
        required: true
      },

      mode: {
        type: String,
        default: 'create',
        validator(value) {
          return ['create', 'read', 'update'].includes(value);
        }
      }
    },

    data() {
      return {
        navigateToTabId: null
      };
    },

    computed: {
      isFetchingModifier() {
        return (this.modifierId && this.modifierId === Modifier.$state().fetchingModifierId)
          || Modifier.$state().fetching;
      },

      modifierImage() {
        if (this.mode === 'create') {
          return new MenuItemModifierImage();
        }

        return MenuItemModifierImage
          .query()
          .where('menuItemModifierId', Number(this.modifierId))
          .first() || new MenuItemModifierImage();
      },

      modifier() {
        return Modifier.find(this.modifierId) || new Modifier();
      },

      modalTitle() {
        let title = '';
        switch (this.mode) {
          case 'create':
            title = 'Create Modifier';
            break;

          case 'update':
          case 'read':
            title = this.modifier ? this.modifier.displayName : '';
            break;

          default:
            title = 'Something isn\'t right';
            break;
        }

        return title;
      },

      tabs() {
        return [
          {
            id: 'details-tab',
            label: 'Details',
            component: modifierDetailsForm,
            props: {
              modifier: this.modifier,
              modifierImage: this.modifierImage,
              modifierGroup: this.modifierGroup,
              mode: this.mode,
              formRef: 'modifierDetailsForm'
            }
          },
          {
            id: 'pos-mappings-tab',
            label: 'POS Mappings',
            component: modifierPosMappingForm,
            props: {
              modifier: this.modifier,
              mode: this.mode,
              formRef: 'modifierPosMappingForm'
            }
          }
        ];
      }
    },

    async created() {
      await this.onCreated();
    },

    methods: {
      async onCreated() {
        const promises = [this.fetchStores];

        if (this.$_featurePermissions.MODIFIER_CODES) {
          promises.push(this.fetchModifierCodes());
        }

        if (this.mode === 'update') {
          promises.push(
            this.fetchModifier(),
            this.fetchModifierImage()
          );
        }
        await Promise.all(promises);
      },

      getActiveTab(activeTabId) {
        return this.tabs.find(t => t.id === activeTabId) || this.tabs[0];
      },

      async closeModal() {
        const confirmed = await this.$_confirmCloseModal({ programmatic: true });
        if (confirmed) this.$_clearFormStore();
      },

      onFormSubmitSuccess(modifier) {
        this.$_onRequestSuccess({
          toastOptions: {
            message: `Successfully ${this.mode === 'create' ? 'created' : 'updated'} ${modifier.displayName}!`
          },
          options: {
            closeParent: true,
            emit: this.mode === 'create'
              ? { name: 'modifier-added', arg: modifier.id }
              : { name: 'modifier-updated' }
          }
        });
      },

      async fetchModifierCodes() {
        try {
          await PosMenuItem.fetchModifierCodes();
        }
        catch (error) {
          this.$_onRequestError({
            toastOptions: { message: 'An error occurred while fetching modifier codes' },
            error
          });
        }
      },

      async fetchStores() {
        try {
          await Store.fetchAll();
        }
        catch (error) {
          this.$_onRequestError({
            toastOptions: { message: 'An error occurred while fetching locations' },
            error
          });
        }
      },

      async fetchModifier() {
        try {
          await Modifier.fetchModifier(this.modifierId);
        }
        catch (error) {
          this.$_onRequestError({
            toastOptions: {
              message: 'There was an error fetching the modifier'
            },
            error
          });
        }
      },

      async fetchModifierImage() {
        try {
          await MenuItemModifierImage.fetchModifierImage(this.modifierId);
        }

        catch (error) {
          this.$_onRequestError({
            toastOptions: {
              message: 'Unable to get modifier image'
            },
            error
          });
        }
      }
    }
  };
</script>

<style scoped lang="sass">
  .modal-card
    max-width: 550px
    margin: 0 auto !important

</style>
