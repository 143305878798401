import { Model } from '@vuex-orm/core';
import filterObjectKeys from '@/helpers/filter-object-keys';
import { tippingTypes } from '@/constants/merchantTippingOptions';

import http from '@/services/http';

export default class MerchantTippingOption extends Model {
  static entity = 'merchantTippingOptions'

  // FIELDS //////////////////////
  static fields() {
    return {
      id: this.attr(''),
      merchantId: this.attr(''),
      departmentId: this.attr(''),
      percentAmount: this.attr(''),
      dollarAmount: this.attr(''),
      isDefault: this.boolean(false),
      isActive: this.boolean(true),
      sortOrder: this.attr(''),
      tippingOptionTypeId: this.attr(''),
      tippingOptionMethodTypeId: this.attr(''),
      tippingTypeId: this.attr(''),
      storeId: this.attr('')
    };
  }

  // STATE //////////////////////
  static state() {
    return {
      fetching: false,
      submitting: false
    };
  }

  static $state() {
    return this.store().state.entities.merchantTippingOptions;
  }

  // ACTIONS //////////////////////
  static async fetchMerchantTippingOptions(merchantId) {
    try {
      this.commit((state) => {
        state.fetching = true;
      });

      const response = await http.get(`merchants/${merchantId}/merchant_tipping_options`);

      /*
        The response includes "actual" merchant tipping options,
        along with the "default" (merchant 0) tipping options
      */

      this.create({ data: response.data.merchantTippingOptions });
    }

    catch (error) {
      throw error;
    }

    finally {
      this.commit((state) => {
        state.fetching = false;
      });
    }
  }

  static async setMerchantTippingOptions({ merchantTippingOptions, merchantId }) {
    const acceptedKeys = ['isActive', 'isDefault', 'departmentId', 'dollarAmount', 'storeId', 'percentAmount', 'tippingTypeId', 'tippingOptionTypeId', 'tippingOptionMethodTypeId'];

    try {
      this.commit((state) => {
        state.submitting = true;
      });

      const response = await http.post(
        `merchants/${merchantId}/merchant_tipping_options/reset`,
        { merchantTippingOptions: merchantTippingOptions.map(option => filterObjectKeys(option, acceptedKeys)) }
      );

      /*
        This endpoint acts as sort of a bulk replace, so all records related to
        the specified merchant are deleted in the DB and replaced with the new ones being provided.
        Since this endpoint only responds with the newly created records, we must first manually remove
        the old records from VueX (but retain the "default" records), and then insert the new ones.
      */

      const isMobileTippingOption = merchantTippingOptions[0].tippingTypeId === tippingTypes.MOBILE;
      const isDepartmentTippingOption = merchantTippingOptions[0].departmentId;

      if (isMobileTippingOption) {
        if (isDepartmentTippingOption) {
          this.delete(merchantTippingOption => merchantTippingOption.departmentId === merchantTippingOptions[0].departmentId);
        }
        else {
          this.delete(merchantTippingOption => merchantTippingOption.merchantId === merchantId && merchantTippingOption.tippingTypeId === tippingTypes.MOBILE);
        }
      }
      else {
        this.delete(merchantTippingOption => merchantTippingOption.merchantId > 0 && !merchantTippingOption.departmentId);
      }

      this.insert({ data: response.data.merchantTippingOptions });
    }

    catch (error) {
      throw error;
    }

    finally {
      this.commit((state) => {
        state.submitting = false;
      });
    }
  }
}
