<template>
  <div class="merchant detail-page">
    <b-loading v-if="isFetchingMerchant" :is-full-page="false" active />
    <merchant-configuration v-else :merchant="merchant" />
  </div>
</template>



<script>
  import merchantConfiguration from './merchant-configuration.vue';
  import Merchant from '@/store/classes/Merchant';

  export default {
    name: 'MerchantDetail',

    components: { merchantConfiguration },

    metaInfo() {
      return {
        title: this.pageTitle
      };
    },

    computed: {
      merchant() {
        return Merchant.query().with('*').find(this.$route.params.merchantId);
      },

      merchantName() {
        return this.merchant?.name || '';
      },

      pageTitle() {
        return this.merchantName || 'Merchant Details';
      },

      isFetchingMerchant() {
        return Merchant.$state().fetchingMerchant;
      }
    },

    async created() {
      await this.fetchMerchant();
    },

    methods: {
      async fetchMerchant() {
        try {
          await Merchant.fetchMerchant(this.$route.params.merchantId);
        }
        catch (error) {
          this.$_onRequestError({
            toastOptions: { message: 'Unable to fetch merchant' },
            error
          });
        }
      }
    }
  };
</script>
