<template>
  <validated-input
    label="Award Run Limit"
    sub-label="Limit how many times a guest can earn this award."
    name="awardRunLimit"
    :rules="{ required: value.runLimitPolicyType === conversionThresholdRunLimitPolicyTypes.FIXED }"
  >
    <numberinput-switch
      v-model="maximumRuns"
      switch-label="Unlimited"
      :disabled="readOnly"
      :true-value="null"
      :false-value="1"
    />
  </validated-input>
</template>

<script>
  import { conversionThresholdRunLimitPolicyTypes } from '@/constants/conversionThresholds';


  export default {
    name: 'ConversionThresholdRunLimitInput',

    props: {
      value: {
        type: Object,
        required: true
      },

      readOnly: {
        type: Boolean,
        default: false
      }
    },

    data: () => ({
      conversionThresholdRunLimitPolicyTypes
    }),

    computed: {
      maximumRuns: {
        get() {
          return this.value.maximumRuns;
        },
        set(maximumRuns) {
          const runLimitPolicyType = maximumRuns
            ? conversionThresholdRunLimitPolicyTypes.FIXED
            : conversionThresholdRunLimitPolicyTypes.UNLIMITED;

          this.$emit('input', {
            runLimitPolicyType,
            maximumRuns: maximumRuns || null
          });
        }
      }
    }
  };
</script>
