import { render, staticRenderFns } from "./tabs-page.vue?vue&type=template&id=ab4778de&scoped=true&"
import script from "./tabs-page.vue?vue&type=script&lang=js&"
export * from "./tabs-page.vue?vue&type=script&lang=js&"
import style0 from "./tabs-page.vue?vue&type=style&index=0&id=ab4778de&lang=sass&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ab4778de",
  null
  
)

export default component.exports