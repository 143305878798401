// https://stackoverflow.com/questions/15469456/checking-if-object-is-an-object-literal
export const typeOf = obj => ({}.toString.call(obj).match(/\w+/g)[1].toLowerCase());

export const parseJson = (value) => {
  try {
    const parsedMetaData = JSON.parse(value);
    return parsedMetaData;
  }
  catch {
    return value;
  }
};

// not testing, no real value
export const getRandomNumber = (min, max, integer) => {
  if (integer) return Math.floor(Math.random() * (max - min + 1)) + min;
  else return Math.random() * (max - min) + min;
};



export default {
  typeOf,
  parseJson,
  getRandomNumber
};
