<template>
  <div v-if="!Object.keys(form).length" class="pad-b-md is-flex justify-center">
    <b-icon
      pack="fal"
      type="is-grey-lighter"
      icon="spinner-third"
      class="spin"
      size="is-large"
      custom-size="fa-4x"
      style="height: unset; width: unset;"
    />
  </div>
  <validated-form
    v-else
    ref="form"
    v-slot="{ errors, dirty }"
    auto-focus
    form-id="userForm"
    @valid-submit="handleSubmit"
  >
    <fieldset>
      <h2 class="title is-4">Profile Information</h2>

      <validated-text-input
        v-model="form.fullName"
        label="Full Name"
        name="fullName"
        rules="required"
        type="text"
        :disabled="!$can(mode, 'User', 'fullName')"
      />

      <fieldset class="is-grid col-2 gap-lg">
        <validated-text-input
          v-model="form.email"
          :has-icon="false"
          label="Email"
          name="email"
          rules="required"
          type="email"
          :disabled="mode === 'update' && cannotUpdateEmail"
          expanded
        />
        <validated-text-input
          v-model="form.phoneNumber"
          :has-icon="false"
          label="Phone"
          name="phone"
          type="phone"
          expanded
          rules="validPhoneNumber"
          :disabled="!$can(mode, 'User', 'phoneNumber')"
        />
      </fieldset>

      <b-message v-if="user.unconfirmedEmail" size="is-small is-compact mar-y">
        <p>
          We've sent a confirmation email to <span class="has-text-weight-bold">{{ user.unconfirmedEmail }}</span>. Click the link in the email to complete the process.
        </p>
      </b-message>

      <hr>

      <validated-input name="userRole" label="User Role" rules="required">
        <dropdown-menu
          v-model="selectedRole"
          :disabled="isRoleInputDisabled"
          expanded
        >
          <dropdown-button
            slot="trigger"
            placeholder="Select a role..."
            class="wrap-text auto-height"
            :has-errors="!!(errors.userRole && errors.userRole.length)"
            :disabled="isRoleInputDisabled"
          >
            <div v-if="selectedRole">
              <p>{{ form.role.name }}</p>
              <p class="is-size-7 has-text-grey">{{ form.role.displayDescription || 'Manage Everything & Anything' }}</p>
            </div>
          </dropdown-button>

          <b-dropdown-item v-if="isFetchingRoles" custom>
            <b-icon icon="spinner-third" class="spin mar-r" />
            Loading...
          </b-dropdown-item>
          <template v-else>
            <b-dropdown-item v-for="role in roleOptions" :key="role.id" :value="role.id">
              <p class="dropdown-item--text__primary">
                {{ role.name }}
              </p>
              <p class="dropdown-item--text__secondary">
                {{ role.displayDescription || 'Manage Everything & Anything' }}
              </p>
            </b-dropdown-item>
          </template>
        </dropdown-menu>

      </validated-input>

      <fieldset v-if="isAdminUser && (isNonAdminRole || isLocationManagerRole)" class="mar-y-lg">
        <div class="is-grid col-2">
          <validated-input
            v-if="isNonAdminRole && isAdminUser"
            name="hidePii"
            label="Hide PII"
            rules="required"
            tooltip="By selecting to hide Personally Identifying Information (PII), this user will not have the ability to view emails, phone numbers and other information that can be attributed to a specific customer."
          >
            <b-switch
              v-model="form.userRole.hidePii"
              type="is-success"
            />
          </validated-input>
          <validated-input
            v-if="isLocationManagerRole && isAdminUser"
            name="hideMenuManagement"
            label="Hide Menu Management"
            rules="required"
            tooltip="By selecting to hide Menu Management, this user will not have the ability to view the Master Menus, Location Menus, Regional Menus, or POS Menus."
          >
            <b-switch
              v-model="form.userRole.hideMenuManagement"
              type="is-success"
            />
          </validated-input>
        </div>
      </fieldset>

      <validated-input
        v-if="enabledSsoProviders.length"
        name="userSsoProvider"
        label="SSO Provider"
      >
        <b-select
          v-model="selectedSsoProvider"
          :disabled="isSsoInputDisabled"
          placeholder="Select an SSO provider..."
          expanded
        >
          <option :value="null">-</option>
          <option
            v-for="ssoProvider in enabledSsoProviders"
            :key="ssoProvider.id"
            :value="ssoProvider.id"
          >
            {{ ssoProvider.displayName }}
          </option>
        </b-select>
      </validated-input>

      <div class="is-grid col-min-500 gap-x-lg gap-y-sm">
        <transition name="fade-left">
          <div>
            <validated-input
              v-if="showMerchantsInput"
              rules="required"
              name="merchants"
              label="Merchants"
            >
              <b-taginput
                ref="taginput"
                v-model="selectedMerchants"
                :closable="$can(mode, 'User', 'merchantIds') && !isMe"
                :data="filteredMerchantOptions"
                :disabled="!$can(mode, 'User', 'merchantIds')"
                open-on-focus
                :type="!$can(mode, 'User', 'merchantIds') || isMe ? 'is-grey' : null"
                attached
                class="has-extra-shadow"
                max-height="300px"
                autocomplete
                field="name"
                icon="store"
                placeholder="Add a Merchant"
                :loading="isFetchingMerchants"
                async
                @add="onAddMerchant"
                @remove="onRemoveMerchant"
                @focus="setFilteredMerchants"
                @typing="getFilteredMerchants"
              >
                <template #selected="props">
                  <template v-for="(merchant, index) in props.tags">
                    <b-tag
                      v-if="merchant.isActive"
                      :key="`${index}-active`"
                      :type="!$can(mode, 'User', 'merchantIds') ? 'is-grey' : !merchant.isActive ? 'is-danger' : null"
                      :closable="$can(mode, 'User', 'merchantIds')"
                      @close="$refs.taginput.removeTag(index, $event)"
                    >
                      {{ merchant.name }}
                    </b-tag>
                    <b-tag
                      v-else
                      :key="`${index}-inactive`"
                      v-tippy="{ content: 'Deactivated Merchant' }"
                      :type="!$can(mode, 'User', 'merchantIds') ? 'is-grey' : !merchant.isActive ? 'is-danger is-light' : null"
                      :closable="$can(mode, 'User', 'merchantIds')"
                      @close="$refs.taginput.removeTag(index, $event)"
                    >
                      {{ merchant.name }}
                    </b-tag>
                  </template>
                </template>
              </b-taginput>
            </validated-input>
            <div v-if="!!allMerchantsByPermission.nonEditableMerchants.length">
              <p class="subtitle is-size-7 has-text-weight-bold mar-b-xs">Non Editable Merchants</p>
              <div class="is-flex-wrap gap-xs">
                <b-tag v-for="nonEditableMerchant in allMerchantsByPermission.nonEditableMerchants" :key="`${nonEditableMerchant.id}-nonEditable`" type="is-grey is-light">
                  {{ nonEditableMerchant.name }}
                </b-tag>
              </div>
            </div>
          </div>
        </transition>
        <transition name="fade-left">
          <validated-input
            v-if="form.merchants.length && showLocationsInput"
            label="Locations"
            name="locations"
            extended
            rules="required"
          >
            <b-dropdown
              v-model="form.storeIds"
              expanded
              multiple
              :scrollable="$can(mode, 'User', 'storeIds') && !isMe"
              :inline="!$can(mode, 'User', 'storeIds') || isMe"
              :disabled="!$can(mode, 'User', 'storeIds') || isMe"
              :class="{'is-read-only': !$can(mode, 'User', 'storeIds') || isMe}"
              max-height="400px"
              :position="mode === 'create' ? 'is-top-right' : 'is-bottom-right'"
              aria-role="list"
              class="has-extra-shadow"
            >
              <dropdown-button
                slot="trigger"
                placeholder="Select Location(s)"
                :value="!!selectedMerchantStores.length && `Selected (${selectedMerchantStores.length})`"
                :loading="isFetchingStores || isFetchingMerchants"
                :has-errors="!!(errors.locations && errors.locations.length)"
              />

              <template>
                <a
                  v-if="$can(mode, 'User', 'storeIds') && form.merchants.length > 1"
                  :class="[
                    'dropdown-item',
                    {'is-active': allStoresSelected()}
                  ]"
                  aria-role="listitem"
                  @click="toggleAllLocations"
                >
                  All Locations
                </a>

                <b-dropdown-item v-if="isFetchingStores || isFetchingMerchants" custom>
                  <b-icon icon="spinner-third" class="spin mar-r" />
                  Loading...
                </b-dropdown-item>

                <template v-else>
                  <div v-for="{id: merchantId, name} in selectedMerchants" :key="merchantId" class="store-group">
                    <b-dropdown-item tabindex="false" class="is-flex align-center title is-6 is-marginless" custom>
                      {{ name }}
                      <b-button
                        v-if="$can(mode, 'User', 'storeIds') && !isMe && storesByMerchant[merchantId] && storesByMerchant[merchantId].length > 1"
                        type="is-light"
                        size="is-small"
                        rounded
                        class="mar-l"
                        @click="toggleMerchantLocations(merchantId)"
                      >
                        {{ allStoresSelected(merchantId) ? 'Unselect' : 'Select' }} All
                      </b-button>
                    </b-dropdown-item>

                    <b-dropdown-item v-if="!storesByMerchant[merchantId]" tabindex="false" custom>
                      No Locations
                    </b-dropdown-item>

                    <b-dropdown-item
                      v-for="store in storesByMerchant[merchantId]"
                      :key="store.storeId"
                      :value="store.storeId"
                      aria-role="listitem"
                    >
                      <span class="mar-l">
                        {{ store.description }}
                      </span>
                    </b-dropdown-item>
                  </div>
                </template>
              </template>
            </b-dropdown>
          </validated-input>
        </transition>
      </div>
    </fieldset>

    <template v-if="mode !== 'create'">
      <hr class="is-large">
      <fieldset>
        <h2 class="title is-4">
          <span>Business Address</span> <span class="has-text-weight-normal has-text-grey is-size-6">(Optional)</span>
        </h2>

        <div class="is-grid col-2 col-1-tablet gap-x-lg gap-y-sm">
          <validated-text-input
            v-model="form.addressLine1"
            :has-icon="false"
            label="Address Line 1"
            name="addressLine1"
            type="text"
            :disabled="!$can(mode, 'User', 'addressLine1')"
            :rules="{required: Boolean(form.addressLine2 || form.city || form.state || form.postalCode)}"
          />
          <validated-text-input
            v-model="form.addressLine2"
            :has-icon="false"
            label="Address Line 2"
            name="addressLine2"
            type="text"
            :disabled="!$can(mode, 'User', 'addressLine2')"
          />
          <validated-text-input
            v-model="form.city"
            expanded
            :has-icon="false"
            label="City"
            name="city"
            type="text"
            :disabled="!$can(mode, 'User', 'city')"
            :rules="{required: Boolean(form.state || form.postalCode || form.addressLine1)}"
          />
          <div class="is-grid col-2 gap-x-lg gap-y-sm">
            <validated-input
              expanded
              label="State"
              name="state"
              :rules="{required: Boolean(form.city || form.postalCode || form.addressLine1)}"
            >
              <b-select
                id="state"
                v-model="form.state"
                :disabled="!$can(mode, 'User', 'state')"
                expanded
              >
                <option :value="null">–</option>

                <optgroup label="U.S. States">
                  <option v-for="option in usStates" :key="option.abbr" :value="option.abbr">
                    {{ option.name }}
                  </option>
                </optgroup>

                <optgroup label="Canadian Provinces">
                  <option v-for="option in caProvinces" :key="option.abbr" :value="option.abbr">
                    {{ option.name }}
                  </option>
                </optgroup>
              </b-select>
            </validated-input>
            <validated-text-input
              v-model="form.postalCode"
              expanded
              :has-icon="false"
              label="Postal Code"
              name="postalCode"
              :type="caProvinces.some((option) => option.abbr === form.state) ? 'postalCode' : 'zipCode'"
              :disabled="!$can(mode, 'User', 'postalCode')"
              :rules="{ required: Boolean(form.city || form.state || form.addressLine1) }"
            />
          </div>
        </div>
      </fieldset>
    </template>

    <div class="footer-buttons">
      <b-button
        rounded
        :loading="isLoadingUser"
        size="is-medium"
        native-type="submit"
        type="is-primary"
        class="is-bold"
        :class="mode === 'update' && dirty && 'pulse-slow'"
      >
        Save
      </b-button>
    </div>
  </validated-form>
</template>

<script>
  import { mapGetters } from 'vuex';
  import { subject } from '@casl/ability';
  import usStates from '@/helpers/us-states';
  import caProvinces from '@/helpers/ca-provinces';
  import logger from '@/services/logger';
  import merchantMixin from '@/mixins/merchant';
  import confirmModalCloseMixin from '@/mixins/confirm-modal-close';

  import User from '@/store/classes/User';
  import Role from '@/store/classes/Role';
  import Store from '@/store/classes/Store';
  import Merchant from '@/store/classes/Merchant';
  import UserRole from '@/store/classes/UserRole';
  import roleTypes from '@/constants/roleTypes';

  export default {
    name: 'UserForm',

    mixins: [merchantMixin, confirmModalCloseMixin],

    props: {
      mode: {
        type: String,
        default: 'create',
        validator(value) {
          return ['create', 'read', 'update'].includes(value);
        }
      }
    },

    data() {
      return {
        form: {},
        filteredMerchantOptions: [],
        caProvinces,
        usStates,
        allAvailableStoresForUser: [],
        originalEmail: null
      };
    },

    computed: {
      ...mapGetters('session', ['currentUser', 'isCardfreeAdmin', 'isAdminUser']),

      cannotUpdateEmail() {
        return !this.$can('update_email', subject('User', this.user)) || this.user.isSso;
      },

      selectedMerchants: {
        get() {
          return [...this.form.merchants]
            .filter(m => !this.allowedMerchantIds.length || this.allowedMerchantIds.includes(m.id))
            .sort((a, b) => ((a.name?.toLowerCase() < b.name?.toLowerCase()) ? -1 : 1));
        },

        set(updatedMerchants) {
          this.form.merchants = [...this.allMerchantsByPermission.nonEditableMerchants, ...updatedMerchants];
        }
      },

      hasOnlyDeactivatedMerchants() {
        return this.selectedMerchants.every(m => !m.isActive);
      },

      allMerchantsByPermission() {
        if (!this.user.id || this.isCardfreeAdmin) {
          return {
            editableMerchants: this.currentUser.merchants.length
              ? this.$clone(this.currentUser.merchants) // if current user has assigned merchants, they can only edit those merchants
              : this.$clone(this.merchants), // if current user has no assigned merchants (is a CF Admin), they can edit all merchants
            nonEditableMerchants: []
          };
        }
        else {
          const { editableMerchants, nonEditableMerchants } = [...this.user.merchants, ...this.currentUser.merchants].reduce((acc, merchant) => {
            if (acc.allMerchants.find(m => m.id === merchant.id)) return acc;

            acc.allMerchants.push(merchant);

            const isEditable = this.allowedMerchantIds.includes(merchant.id);
            if (isEditable) acc.editableMerchants.push(merchant);
            else acc.nonEditableMerchants.push(merchant);

            return acc;
          }, { allMerchants: [], editableMerchants: [], nonEditableMerchants: [] });

          return {
            editableMerchants: editableMerchants.sort((a, b) => ((a.name?.toLowerCase() < b.name?.toLowerCase()) ? -1 : 1)),
            nonEditableMerchants: nonEditableMerchants.sort((a, b) => ((a.name?.toLowerCase() < b.name?.toLowerCase()) ? -1 : 1))
          };
        }
      },

      selectedRole: {
        get() {
          const firstRole = this.form.role;
          return firstRole?.id;
        },

        set(roleId) {
          this.form.role = this.getRole(roleId);
          // take the naive approach and reset every time rather than calculating if role can have attributes on user role toggled
          this.form.userRole = new UserRole();
        }
      },

      selectedSsoProvider: {
        get() {
          return this.form.ssoProviderId;
        },

        set(providerId) {
          this.form.ssoProviderId = providerId;
        }
      },

      isMe() {
        return this.currentUser.id === this.user.id;
      },

      allowedMerchantIds() {
        // NOTE: Will be empty array when CF Admin
        return this.currentUser?.merchants.map(m => m.id);
      },

      user() {
        const user = this.mode === 'create'
          ? new User({
            merchants: this.$_selectedMerchantId && this.$_selectedMerchant ? [this.$_selectedMerchant] : [],
            userRole: new UserRole()
          })
          : User.query().with('userRole').find(this.$route.params.id);

        return user;
      },

      userAsJson() {
        return this.user?.$toJson();
      },

      roles() {
        return Role.query().orderBy(role => role.name?.toLowerCase()).get();
      },

      merchants() {
        return Merchant.query().orderBy(merchant => merchant.name?.toLowerCase()).get();
      },

      selectedMerchantStores() {
        return this.allAvailableStoresForUser
          .filter(s => this.form.storeIds.includes(s.storeId))
          .sort((a, b) => (a.description.toLowerCase() > b.description.toLowerCase() ? 1 : -1));
      },

      storesByMerchant() {
        return this.allAvailableStoresForUser.reduce((acc, store) => {
          if (!acc[store.merchantId]) {
            acc[store.merchantId] = [store];
          }
          else {
            acc[store.merchantId].push(store);
          }
          return acc;
        }, {});
      },

      isLoadingUser() {
        return User.$state().submitting;
      },

      roleOptions() {
        return this.$_selectedMerchantId
          ? this.roles.filter(roleObj => !roleObj.name.includes('Cardfree'))
          : this.roles
            .filter(roleObj => !roleObj.name.includes('Operations')); // temporarily prevent the CardFree Operations role from being an option to select
      },

      showMerchantsInput() {
        return this.form.role && this.form.role.name !== 'Cardfree Admin';
      },

      showLocationsInput() {
        return this.showMerchantsInput
          && !!this.selectedMerchants.length
          && this.isStoreSpecificRole;
      },

      nonAdminRoles() {
        const nonAdminRoles = this.storeSpecificRoles.concat('Analytics', 'Marketing Manager');
        return nonAdminRoles;
      },


      storeSpecificRoles() {
        return [
          'Guest Support',
          'Location Manager',
          'Location Operator',
          'Menu Manager'
        ];
      },

      isStoreSpecificRole() {
        return this.storeSpecificRoles.includes(this.form.role.name);
      },

      isNonAdminRole() {
        return this.nonAdminRoles.includes(this.form.role.name);
      },

      isLocationManagerRole() {
        return this.form.role?.name === roleTypes.LOCATION_MANAGER;
      },

      isFetchingMerchants() {
        return Merchant.$state().fetchingAll;
      },

      isFetchingRoles() {
        return Role.$state().fetching;
      },

      isFetchingStores() {
        return Store.$state().fetchingAll;
      },

      isRoleInputDisabled() {
        return (!this.$can('manage_user_role', 'User') && this.mode === 'update') || this.isMe;
      },

      isSsoInputDisabled() {
        return (!this.$can('update', 'User') && !this.$can('manage', 'SsoProvider') && this.mode === 'update') || this.isMe;
      },

      emailChanged() {
        return this.form.email && this.form.email !== this.originalEmail;
      },

      enabledSsoProviders() {
        return this.$_selectedMerchant ? this.$_selectedMerchant.features.ssoProviders : [];
      }
    },

    watch: {
      '$route.params.id': 'setFormData',
      'form.state': 'handleStateChange'
    },

    created() {
      this.onCreated();
    },

    methods: {
      async onCreated() {
        if (this.$can('read', 'Role')) {
          await this.fetchRoles();
        }

        this.setFormData();

        const storeRequests = this.form.merchants
          .filter(m => !this.allowedMerchantIds.length || this.allowedMerchantIds.includes(m.id))
          .map(m => this.fetchMerchantStores(m.id));
        const stores = await Promise.all(storeRequests);
        this.allAvailableStoresForUser = stores.flatMap(s => s);

        this.form.storeIds = this.form.storeIds.length ? this.form.storeIds : this.allAvailableStoresForUser.map(store => store.storeId);

        if (this.$can(this.mode, 'User', 'merchantIds')) {
          await this.fetchMerchants();
        }
      },

      setFormData() {
        this.form = JSON.parse(JSON.stringify(this.userAsJson));
        this.originalEmail = this.form.email;
      },

      handleStateChange(newState, oldState) {
        if (!oldState) return;

        const isUsState = this.usStates.some(option => option.abbr === newState);
        const wasUsState = this.usStates.some(option => option.abbr === oldState);

        if ((!isUsState && wasUsState) || (isUsState && !wasUsState)) {
          this.form.postalCode = '';
        }
      },

      getRole(roleId) {
        // if on a user's page that is a higher level permission, need to just get from their assigned roles
        return this.roles.find(role => role.id === roleId) || this.form.role;
      },

      async fetchMerchants() {
        try {
          await Merchant.fetchMerchants();
        }
        catch (error) {
          logger.error(error);
          this.$buefy.toast.open({
            message: '<i class="fa fa-exclamation mar-r-sm"></i> Unable to fetch merchants',
            queue: false,
            type: 'is-danger'
          });
        }
      },

      async fetchMerchantStores(merchantId) {
        try {
          return await Store.fetchAll(merchantId, { forceFetch: true });
        }
        catch (error) {
          this.$_onRequestError({
            toastOptions: {
              message: 'Unable to fetch stores'
            },
            error
          });
        }
      },

      async fetchRoles() {
        try {
          await Role.fetchRoles();
        }
        catch (error) {
          this.$_onRequestError({ toastOptions: { message: 'Unable to fetch roles' }, error });
        }
      },

      getFilteredMerchants(text) {
        this.filteredMerchantOptions = this.allMerchantsByPermission.editableMerchants
          .filter((option) => {
            const notSelected = !this.form.merchants.find(merchant => merchant.id === option.id);
            const textMatches = option.name
              .toString()
              .toLowerCase()
              .includes(text.toLowerCase());

            return textMatches && notSelected && !option.isTemplate;
          });
      },

      async onAddMerchant(merchant) {
        const merchantStores = await this.fetchMerchantStores(merchant.id);
        this.allAvailableStoresForUser = [...this.allAvailableStoresForUser, ...merchantStores];
        this.toggleMerchantLocations(merchant.id);
        this.setFilteredMerchants();
      },

      onRemoveMerchant(merchant) {
        const nonEditableStoreIds = this.$clone(this.form.storeIds).filter(id => !this.allAvailableStoresForUser.find(s => s.storeId === id));
        this.allAvailableStoresForUser = this.$clone(this.allAvailableStoresForUser).filter(s => s.merchantId !== merchant.id);
        const merchantStoreIds = this.$clone(this.allAvailableStoresForUser).map(s => s.storeId);
        this.form.storeIds = [...nonEditableStoreIds, ...merchantStoreIds];

        this.setFilteredMerchants();
      },

      toggleAllLocations() {
        // unselect all locations
        if (this.selectedMerchantStores.length && this.selectedMerchantStores.every(store => this.form.storeIds.includes(store.storeId))) {
          this.form.storeIds = this.$clone(this.form.storeIds).filter(storeId => !this.allAvailableStoresForUser.find(ms => ms.storeId === storeId));
        }
        // select all locations
        else {
          const uniqueStoreIds = new Set([...this.form.storeIds, ...this.allAvailableStoresForUser.map(s => s.storeId)]);
          this.form.storeIds = [...uniqueStoreIds];
        }
      },

      toggleMerchantLocations(merchantId) {
        const merchantStores = this.storesByMerchant[merchantId] || [];

        if (merchantStores.every(store => this.form.storeIds.includes(store.storeId))) {
          this.form.storeIds = this.form.storeIds.filter(id => !merchantStores.map(s => s.storeId).includes(id));
        }
        else {
          const uniqueStoreIds = new Set([...this.form.storeIds, ...merchantStores.map(s => s.storeId)]);
          this.form.storeIds = [...uniqueStoreIds];
        }
      },

      allStoresSelected(merchantId) {
        if (merchantId) {
          return this.storesByMerchant[merchantId]?.every(store => this.form.storeIds.includes(store.storeId));
        }
        return this.selectedMerchantStores.length === this.allAvailableStoresForUser.length;
      },

      setFilteredMerchants() {
        this.filteredMerchantOptions = this.allMerchantsByPermission.editableMerchants
          .filter(option => !option.isTemplate && !this.form.merchants.find(merchant => merchant.id === option.id));
      },

      async confirmSaveWithNoActiveMerchants() {
        const { result } = await this.$buefy.dialog.confirm({
          title: 'No Active Merchants',
          message: 'Are you sure you want to save this user with no active merchants? Merchant Portal will not function correctly.',
          type: 'is-danger',
          trapFocus: true,
          confirmText: 'Yes',
          cancelText: 'No',
          hasIcon: true,
          icon: 'exclamation-triangle'
        });
        return result;
      },

      async createUser() {
        try {
          let shouldSave = true;
          if (this.hasOnlyDeactivatedMerchants) {
            shouldSave = await this.confirmSaveWithNoActiveMerchants();
          }

          const payload = {
            ...this.form,
            storeIds: this.isStoreSpecificRole ? this.form.storeIds : []
          };

          if (shouldSave) {
            await User.createUser(payload);
            this.$_onRequestSuccess({
              toastOptions: { message: `<b>${this.form.fullName}</b> has been successfully added!` },
              options: {
                closeParent: true
              }
            });
          }
        }

        catch (error) {
          this.$_onRequestError({
            toastOptions: { message: 'Unable to add new user' },
            error,
            options: {
              backendError: error.data.errors?.user
            }
          });
        }
      },

      async updateUser() {
        try {
          let shouldSave = true;
          const isUpdatingMerchants = this.user.merchants.some(m => this.form.merchants.some(fm => fm.id !== m.id));
          if (isUpdatingMerchants && this.hasOnlyDeactivatedMerchants) {
            shouldSave = await this.confirmSaveWithNoActiveMerchants();
          }

          if (shouldSave) {
            const payload = {
              ...this.form,
              storeIds: this.isStoreSpecificRole ? this.form.storeIds : []
            };
            payload.active = User.find(this.$route.params.id).active;

            if (this.$can(this.mode, 'User', 'merchantIds')) {
              payload.merchantIds = this.$clone(this.form.merchants).map(merchant => merchant.id);
            }

            if (this.$can('manage_user_role', 'User') && !this.isMe) {
              await User.updateUserRole({
                userId: this.form.id,
                roleId: this.form.role.id,
                storeIds: payload.storeIds,
                merchantIds: payload.merchantIds,
                hidePii: this.form.userRole.hidePii,
                hideMenuManagement: this.form.userRole.hideMenuManagement
              });
            }

            await User.updateUser(payload);

            let message = `<b>${this.form.fullName}</b> has been successfully updated!`;
            if (this.emailChanged) {
              await User.updateEmail(this.form.id, this.form.email);
              message += ' We\'ve sent an email to confirm the new email address.';
            }
            this.$_onRequestSuccess({
              toastOptions: { message }
            });
          }
        }

        catch (error) {
          this.$_onRequestError({
            toastOptions: { message: 'Unable to edit user' },
            error,
            options: {
              backendError: error.data.errors?.user
            }
          });
        }
      },

      async handleSubmit() {
        try {
          switch (this.mode) {
            case 'create':
              await this.createUser();
              break;

            case 'update':
              await this.updateUser();
              break;

            default:
              break;
          }
        }
        catch (error) {
          logger.error(error);
        }
      }
    }
  };
</script>

<style lang="sass" scoped>
  .store-group
    padding: 0.5rem 0
    &:not(:first-child)
      border-top: 1px solid $grey-lighter
</style>
