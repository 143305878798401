<template>
  <layout />
</template>

<script>
  import layout from './layout.vue';

  export default {
    name: 'Main',

    components: { layout }
  };
</script>

<style lang="sass">
  @import '@/../styles/main.sass'
</style>
