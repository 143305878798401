import logger from '@/services/logger';
import findCloseableParent from '@/helpers/find-closeable-parent';
import storage from '@/services/storage';
import { getRandomNumber } from '@/helpers/misc';
import { noCase } from 'change-case';

const responseHandler = {
  methods: {
    p_handleOptions(options) {
      /*
        Example:

        options = {
          resetForm: true,
          closeParent: true,
          emit: {
            name: 'add-user',
            arg: [1, 2, 3]
          },
          redirectTo: 'userList',
          backendError: error.data.errors.user
        }
      */

      /**
      * parses Rails errors so that the "subject" (key) is
      * added to the beginning of each error message.
      *
      * {
      *   email: ['has already been taken'],
      *   userOnboardingMetadata.businessName: ['is already in use']
      * }
      *
      * turns into:
      *
      * {
      *   email: ['email has already been taken'],
      *   userOnboardingMetadata.businessName: ['business name is already in use']
      * }
      */
      const parseErrors = errors => Object
        .entries(errors)
        .reduce((obj, [key, messages]) => {
          const splitKey = key.split('.');
          const parsedKey = noCase(splitKey[splitKey.length - 1]);
          obj[key] = messages.map(message => `${parsedKey} ${message}`);
          return obj;
        }, {});

      const optionsMap = {
        resetForm: () => this.$refs.form.$children[0].reset(), // for use when reseting the state of a validated form
        closeParent: () => findCloseableParent(this)?.close(), // for use when closing a parent modal
        emit: () => this.$emit(options.emit.name, options.emit.arg),
        redirectTo: () => this.$router.push(options.redirectTo),
        backendError: () => options.backendError && this.$refs.form.$emit('backend-error', parseErrors(options.backendError))
      };

      Object.keys(options).forEach((option) => {
        if (optionsMap[option]) {
          optionsMap[option]();
        }
      });
    },

    $_onRequestSuccess({ toastOptions = {}, options = {}, confetti }) {
      const icon = toastOptions.icon || 'check';
      delete toastOptions.icon;

      if (storage.local.get('party-mode') || confetti) {
        const colors = ['#ffbe0b', '#fb5607', '#ff006e', '#8338ec', '#3a86ff'];
        const amount = window.innerWidth / 8;

        for (let index = 0; index < amount; index++) {
          setTimeout(() => {
            this.$confetti({
              particleCount: 1,
              startVelocity: 0,
              shapes: ['square'],
              colors: [colors[getRandomNumber(0, colors.length, true)]],
              ticks: getRandomNumber(50, 250, true), // how long each confetti takes to disapear
              gravity: getRandomNumber(0.75, 2.25), // speed each confetti falls
              scalar: getRandomNumber(0.75, 1.25), // size of each confetti
              drift: getRandomNumber(-0.5, 0.5), // distance each confetti drifts left/right
              origin: {
                x: getRandomNumber(0, 1), // random x positon each confetti falls from (full width of screen)
                y: 0 // start at top of screen
              }
            });
          }, getRandomNumber(0, 1250, true)); // delay when each confetti will start falling
        }
      }

      this.$buefy.toast.open({
        queue: false,
        ...toastOptions,
        message: `<i style="animation-delay: 125ms" class="animated tdFadeInLeft fa fa-${icon} mar-r-sm"></i> ${toastOptions.message}`,
        type: 'is-success'
      });

      if (Object.keys(options).length) {
        this.p_handleOptions(options);
      }
    },

    $_onRequestError({ toastOptions = {}, error, options = {} }) {
      const defaultErrorMessage = 'Something went wrong...';
      const icon = toastOptions.icon || 'exclamation';
      delete toastOptions.icon;

      if (storage.local.get('party-mode')) {
        this.$confetti({
          origin: { x: 0.96, y: 1 },
          angle: 115,
          colors: ['#930b26', '#f14668']
        });
      }

      this.$buefy.toast.open({
        queue: false,
        ...toastOptions,
        message: `<i style="animation-delay: 125ms" class="animated tdFadeInUp fa fa-${icon} mar-r-sm"></i> ${toastOptions.message || defaultErrorMessage}`,
        type: 'is-danger'
      });

      if (error) logger.error(error);

      if (Object.keys(options).length) {
        this.p_handleOptions(options);
      }
    }
  }
};

export default responseHandler;

