// Vuex Store
import store from '@/store';


// Global Components
import backendSearchableTable from '@/components/globals/backend-searchable-table.vue';
import checkButton from '@/components/globals/check-button.vue';
import clippedImagePage from '@/components/globals/clipped-image-page.vue';
import collapsibleSection from '@/components/globals/collapsible-section.vue';
import confirmationRow from '@/components/globals/confirmation-row.vue';
import constraintsCard from '@/components/globals/builder-review/constraints-card.vue';
import countdownMessage from '@/components/globals/countdown-message.vue';
import daypartScheduleInputs from '@/components/globals/daypart-schedule-inputs.vue';
import dropdownButton from '@/components/globals/dropdown-button.vue';
import dropdownMenu from '@/components/globals/dropdown-menu.vue';
import emptyStateCard from '@/components/globals/empty-state-card.vue';
import emptyTableLoader from '@/components/globals/empty-table-loader.vue';
import excludedItemAttributesDropdown from '@/components/globals/excluded-item-attributes-dropdown.vue';
import heroBanner from '@/components/globals/hero-banner.vue';
import imageUpload from '@/components/globals/image-upload.vue';
import itemGroupReviewRow from '@/components/globals/builder-review/item-group-review-row.vue';
import lastModifiedMessage from '@/components/globals/last-modified-message.vue';
import lastModifiedTableCell from '@/components/globals/last-modified-table-cell.vue';
import regionalLocationDropdown from '@/components/globals/regional-location-dropdown.vue';
import menuResourceSelect from '@/components/globals/menu-resource-select/index.vue';
import menuSearchForm from '@/components/globals/menu-search/menu-search-form.vue';
import menuSearchInput from '@/components/globals/menu-search/menu-search-input.vue';
import merchantDropdown from '@/components/globals/merchant-dropdown.vue';
import modalCard from '@/components/globals/modal-card.vue';
import modalTabs from '@/components/globals/modal-tabs.vue';
import modifierRestrictionsSearch from '@/components/globals/menu-search/modifier-restrictions-search.vue';
import navigationBar from '@/components/globals/navigation-bar.vue';
import numberinputFractional from '@/components/globals/numberinput-fractional.vue';
import numberinputSwitch from '@/components/globals/numberinput-switch.vue';
import pageNumberSelect from '@/components/globals/page-number-select.vue';
import panel from '@/components/globals/panel.vue';
import paymentIcon from '@/components/globals/payment-icon.vue';
import paymentTransactionList from '@/components/globals/payment-transaction-list.vue';
import posItemSearch from '@/components/globals/pos-item-search.vue';
import radioButton from '@/components/globals/radio-button.vue';
import reviewCard from '@/components/globals/builder-review/review-card.vue';
import reviewRow from '@/components/globals/builder-review/review-row.vue';
import scrollableTabs from '@/components/globals/scrollable-tabs.vue';
import searchableTable from '@/components/globals/searchable-table.vue';
import secondarySidebar from '@/components/globals/secondary-sidebar.vue';
import sectionWrapper from '@/components/globals/section-wrapper.vue';
import sidebarNav from '@/components/globals/sidebar-nav.vue';
import signInForm from '@/components/globals/sign-in-form.vue';
import sortOrderTableCell from '@/components/globals/sort-order-table-cell.vue';
import stepsModal from '@/components/globals/steps-modal.vue';
import stickySaveContainer from '@/components/globals/sticky-save-container.vue';
import tabsPage from '@/components/globals/tabs-page.vue';
import takeover from '@/components/globals/takeover.vue';
import toggleButtons from '@/components/globals/toggle-buttons.vue';
import userRoleTag from '@/components/globals/user-role-tag.vue';
import validatedForm from '@/components/globals/validated-form.vue';
import validatedInput from '@/components/globals/validated-input.vue';
import validatedTextInput from '@/components/globals/validated-text-input.vue';

// Mixins
import responseHandler from '@/mixins/response-handler';
import validation from '@/mixins/validation';

// Directives
import autoFocus from '@/directives/auto-focus';
import entrapFocus from '@/directives/entrap-focus';
import tabbable from '@/directives/tabbable';

// Filters
import capitalize from '@/filters/capitalize';
import dollars from '@/filters/dollars';
import formatDecimal from '@/filters/formatDecimal';
import lowercase from '@/filters/lowercase';
import moment from '@/filters/moment';
import numberDisplay from '@/filters/numberDisplay';
import phoneNumber from '@/filters/phoneNumber';

// Libraries
import Vue from 'vue';
import VueEllipseProgress from 'vue-ellipse-progress';
import VueMeta from 'vue-meta';
import VueRouter from 'vue-router';
import Buefy from 'buefy';
import { rules } from '@/helpers/validation';
import {
  configure,
  extend,
  ValidationObserver,
  ValidationProvider
} from 'vee-validate/dist/vee-validate.full';
import { abilitiesPlugin } from '@casl/vue';
import VueTippy, { TippyComponent as tippy } from 'vue-tippy';
import VueScreen from 'vue-screen';
import InfiniteScroll from 'vue-infinite-scroll';


// Polyfills
import smoothscroll from 'smoothscroll-polyfill';


// Plugins
import NewRelicLoggingServicePlugin from '@/plugins/new-relic-logging-service-plugin';



function components() {
  Vue.component('backendSearchableTable', backendSearchableTable);
  Vue.component('checkButton', checkButton);
  Vue.component('clippedImagePage', clippedImagePage);
  Vue.component('collapsibleSection', collapsibleSection);
  Vue.component('confirmationRow', confirmationRow);
  Vue.component('constraintsCard', constraintsCard);
  Vue.component('countdownMessage', countdownMessage);
  Vue.component('daypartScheduleInputs', daypartScheduleInputs);
  Vue.component('dropdownButton', dropdownButton);
  Vue.component('dropdownMenu', dropdownMenu);
  Vue.component('emptyStateCard', emptyStateCard);
  Vue.component('emptyTableLoader', emptyTableLoader);
  Vue.component('excludedItemAttributesDropdown', excludedItemAttributesDropdown);
  Vue.component('heroBanner', heroBanner);
  Vue.component('imageUpload', imageUpload);
  Vue.component('itemGroupReviewRow', itemGroupReviewRow);
  Vue.component('lastModifiedMessage', lastModifiedMessage);
  Vue.component('lastModifiedTableCell', lastModifiedTableCell);
  Vue.component('regionalLocationDropdown', regionalLocationDropdown);
  Vue.component('menuResourceSelect', menuResourceSelect);
  Vue.component('menuSearchForm', menuSearchForm);
  Vue.component('menuSearchInput', menuSearchInput);
  Vue.component('merchantDropdown', merchantDropdown);
  Vue.component('modalCard', modalCard);
  Vue.component('modalTabs', modalTabs);
  Vue.component('modifierRestrictionsSearch', modifierRestrictionsSearch);
  Vue.component('navigationBar', navigationBar);
  Vue.component('numberinputFractional', numberinputFractional);
  Vue.component('numberinputSwitch', numberinputSwitch);
  Vue.component('pageNumberSelect', pageNumberSelect);
  Vue.component('panel', panel);
  Vue.component('paymentIcon', paymentIcon);
  Vue.component('paymentTransactionList', paymentTransactionList);
  Vue.component('posItemSearch', posItemSearch);
  Vue.component('radioButton', radioButton);
  Vue.component('reviewCard', reviewCard);
  Vue.component('reviewRow', reviewRow);
  Vue.component('scrollableTabs', scrollableTabs);
  Vue.component('searchableTable', searchableTable);
  Vue.component('secondarySidebar', secondarySidebar);
  Vue.component('sectionWrapper', sectionWrapper);
  Vue.component('sidebarNav', sidebarNav);
  Vue.component('signInForm', signInForm);
  Vue.component('sortOrderTableCell', sortOrderTableCell);
  Vue.component('stepsModal', stepsModal);
  Vue.component('stickySaveContainer', stickySaveContainer);
  Vue.component('tabsPage', tabsPage);
  Vue.component('takeover', takeover);
  Vue.component('toggleButtons', toggleButtons);
  Vue.component('userRoleTag', userRoleTag);
  Vue.component('validatedForm', validatedForm);
  Vue.component('validatedInput', validatedInput);
  Vue.component('validatedTextInput', validatedTextInput);

  // from libraries
  Vue.component('ValidationObserver', ValidationObserver);
  Vue.component('ValidationProvider', ValidationProvider);
  Vue.component('tippy', tippy);
}



function directives() {
  Vue.directive('autoFocus', autoFocus);
  Vue.directive('entrapFocus', entrapFocus);
  Vue.directive('tabbable', tabbable);
}



function filters() {
  Vue.filter('capitalize', capitalize);
  Vue.filter('dollars', dollars);
  Vue.filter('formatDecimal', formatDecimal);
  Vue.filter('lowercase', lowercase);
  Vue.filter('moment', moment);
  Vue.filter('numberDisplay', numberDisplay);
  Vue.filter('phoneNumber', phoneNumber);
}



function mixins() {
  Vue.mixin(responseHandler);
  Vue.mixin(validation);
}



function plugins() {
  Vue.use(VueMeta);
  Vue.use(VueRouter);
  Vue.use(VueEllipseProgress);
  Vue.use(Buefy, {
    defaultDatepickerNearbySelectableMonthDays: true,
    defaultDatepickerYearsRange: [-50, 10],
    defaultDayNames: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
    defaultIconPack: 'fa',
    defaultProgrammaticPromise: true,
    defaultStatusIcon: false,
    defaultToastDuration: 5000,
    defaultToastPosition: 'is-bottom-right'
  });

  Vue.use(VueScreen, {
    mobile: 420,
    tablet: 769,
    desktop: 1024,
    widescreen: 1216,
    fullhd: 1614
  });

  Vue.use(VueTippy, {
    directive: 'tippy', // => v-tippy
    flipOnUpdate: true,
    arrow: true,
    duration: 150,
    animation: 'scale',
    boundary: 'viewport',
    touchHold: true
  });

  Vue.use(InfiniteScroll);

  Vue.use(NewRelicLoggingServicePlugin, { store });
}



function polyfills() {
  smoothscroll.polyfill();
}



function validations() {
  const {
    confirmPassword,
    dateTimeIsAfter,
    fileType,
    greaterThan,
    lessThan,
    maxByteSize,
    maxLength,
    minLength,
    notWhite,
    postalCode,
    specialChars,
    uniqueStringCaseInsensitive,
    uriFriendly,
    validEmailAddress,
    validHexColor,
    validJSON,
    validLatitude,
    validLongitude,
    validPhoneNumber,
    validUrl
  } = rules;

  configure({ mode: 'passive' });

  // custom validation rules
  extend('confirmPassword', confirmPassword);
  extend('dateTimeIsAfter', dateTimeIsAfter);
  extend('fileType', fileType); // TEST
  extend('greaterThan', greaterThan);
  extend('lessThan', lessThan);
  extend('maxByteSize', maxByteSize);
  extend('maxLength', maxLength);
  extend('minLength', minLength);
  extend('notWhite', notWhite);
  extend('postalCode', postalCode);
  extend('specialChars', specialChars);
  extend('uniqueStringCaseInsensitive', uniqueStringCaseInsensitive);
  extend('uriFriendly', uriFriendly);
  extend('validEmailAddress', validEmailAddress);
  extend('validHexColor', validHexColor);
  extend('validJSON', validJSON);
  extend('validLatitude', validLatitude);
  extend('validLongitude', validLongitude);
  extend('validPhoneNumber', validPhoneNumber);
  extend('validUrl', validUrl);
}



function abilities() {
  // use the abilitiesPlugin with the Ability instance
  // defined in the Vuex session module
  Vue.use(abilitiesPlugin, store.state.session.ability);
}



const globals = {
  initialize() {
    plugins();
    components();
    directives();
    filters();
    mixins();
    polyfills();
    validations();
    abilities(); // TEST
  }
};



export default globals;
