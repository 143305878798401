<template>
  <div class="takeover-wrapper">
    <img class="clipped" src="/images/banners/banner_1.jpg" alt="banner">
    <div class="takeover-content panel">
      <slot />
    </div>

    <svg>
      <clipPath id="cfclippath">
        <title>CF_ClipPath</title>
        <!-- lg -->
        <path
          class="is-hidden-mobile is-hidden-tablet-only is-hidden-desktop-only is-hidden-widescreen-only"
          d="M1130.49,19a29,29,0,0,0-27.2-19H0L.2,945.81a29,29,0,0,0,16.86,26.31A29,29,0,0,0,48,967.85L1122.11,51a29,29,0,0,0,8.38-32"
        />

        <!-- md -->
        <path
          class="is-hidden-touch is-hidden-fullhd is-hidden-desktop-only"
          d="M1014.72,115.05A23.06,23.06,0,0,0,993.1,100h-877l.17,751.79a23,23,0,0,0,38,17.52l853.77-728.77a23.05,23.05,0,0,0,6.66-25.47"
          transform="translate(-116.13 -99.98)"
        />

        <!-- sm -->
        <path
          class="is-hidden-mobile is-hidden-fullhd"
          d="M915,197.79a17.92,17.92,0,0,0-16.81-11.72H216.13l.13,584.73a17.92,17.92,0,0,0,29.55,13.62L909.86,217.61A17.94,17.94,0,0,0,915,197.79"
          transform="translate(-216.13 -186.07)"
        />

        <!-- xs -->
        <polygon
          class="is-hidden-tablet"
          points="0 460.61 800 229.94 800 0.52 0.17 0 0 460.61"
        />
      </clipPath>
    </svg>
  </div>
</template>

<script>
  export default {
    name: 'Takeover'
  };
</script>

<style lang="sass" scoped>
  .takeover-wrapper
    display: flex
    align-items: center
    justify-content: center
    position: absolute
    top: 0
    right: 0
    left: 0
    bottom: 0
    z-index: 1000
    overflow: hidden
    background-color: #f7f7f7
    background-image: url(/images/transparent-square-tiles-dark.png)
    background-position: center

  .takeover-content
    background-color: #fff
    border: 1px solid #e0e0e0
    position: absolute
    min-width: 310px
    max-width: 450px
    padding: 1rem
    right: unset
    top: unset
    transform: unset

  .clipped
    min-height: 480px
    position: absolute
    top: 0
    left: 0
    object-fit: cover
    clip-path: url(#cfclippath)
    -webkit-clip-path: url(#cfclippath)



  @media (min-width: $tablet)
    .takeover-wrapper
      display: block

    .takeover-content
      min-width: 400px
      padding: 2rem
      right: 20%
      top: 50%
      transform: translateY(-60%)

    .clipped
      min-height: 610px

  @media (min-width: $desktop)
    .clipped
      min-height: 780px

  @media (min-width: $fullhd)
    .clipped
      min-height: 980px
</style>
