/* eslint-disable import/no-cycle */
import { camelCase } from 'change-case';
import MenuAttribute from '@/store/classes/MenuAttribute';
import FetchModel from '@/store/classes/FetchModel';


export default class MenuAttributeType extends FetchModel {
  static entity = 'menuAttributeTypes'

  static fields() {
    return {
      id: this.attr(''),
      name: this.attr(''),
      imageUrl: this.attr(''),
      menuAttributes: this.hasMany(MenuAttribute, 'menuAttributeTypeId')
    };
  }

  get isSearchType() {
    return this.name === 'SearchTag';
  }

  static searchTagType() {
    return this.query().where('name', 'SearchTag').first();
  }

  static state() {
    return {
      fetching: false
    };
  }

  static $state() {
    return this.store().state.entities.menuAttributeTypes;
  }

  // ACTIONS //////////////////////

  static async fetchMenuAttributes(options = {}) {
    await this.fetchData({
      endpoint: 'menu_attribute_types',
      options,
      transformData: data => data.menuAttributeTypes
    });
  }

  static byName() {
    return this.query().with('menuAttributes').get().reduce((acc, attr) => {
      acc[camelCase(attr.name)] = attr.menuAttributes;
      return acc;
    }, {});
  }
}
