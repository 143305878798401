import http from '@/services/http';
import FetchModel from '@/store/classes/FetchModel';



export default class MenuItemUpsell extends FetchModel {
  static entity = 'menuItemUpsells'

  static fields() {
    return {
      id: this.attr(''),
      displayName: this.attr(''),
      menuItemId: this.attr(''),
      priority: this.attr(''),
      upsellMenuItemId: this.attr('')
    };
  }

  static state() {
    return {
      fetching: false,
      submitting: false
    };
  }

  static $state() {
    return this.store().state.entities.menuItemUpsells;
  }

  static async fetchUpsellItems(menuItemId, options) {
    await this.fetchData({
      endpoint: `menu_items/${menuItemId}/upsells`,
      options,
      transformData: data => data.menuItemUpsells,
      ormMethod: 'insert',
      customQuery: this.query().where('menuItemId', menuItemId)
    });
  }

  static async fetchUpsoldByItems(menuItemId) {
    try {
      const response = await http.get(`menu_items/upsells/${menuItemId}`);
      return response.data.menuItems;
    }
    catch (error) {
      throw error;
    }
  }

  static async fetchUpsoldByCategories(menuItemId) {
    try {
      const response = await http.get(`menu_categories/upsells/${menuItemId}`);
      return response.data.menuCategories;
    }
    catch (error) {
      throw error;
    }
  }

  static async deleteUpsellSellItems({ menuItemUpsellIds, itemId }) {
    try {
      this.commit((state) => {
        state.submitting = true;
      });
      await http.delete(`menu_items/${itemId}/upsells?menu_item_upsell_ids=[${menuItemUpsellIds.join(', ')}]`);
      menuItemUpsellIds.forEach(id => this.delete(id));
    }
    catch (error) {
      throw error;
    }
    finally {
      this.commit((state) => {
        state.submitting = false;
      });
    }
  }

  static async addUpsellItems({ upsellMenuItemIds, itemId }) {
    try {
      this.commit((state) => { // TEST ?
        state.submitting = true;
      });

      const response = await http.post(`menu_items/${itemId}/upsells`, { menuItemUpsells: upsellMenuItemIds });
      this.insert({ data: response.data.menuItemUpsells });

      return response.data.menuItemUpsells;
    }
    catch (error) {
      throw error;
    }
    finally {
      this.commit((state) => { // TeST
        state.submitting = false;
      });
    }
  }

  static async updateUpsellItemsPriority({ items, itemId }) {
    try {
      this.commit((state) => { // TEST ?
        state.submitting = true;
      });
      const response = await http.put(
        `menu_items/${itemId}/upsells/priority`,
        {
          menuItemUpsells: items.map(item => ({ id: item.id, priority: item.priority }))
        }
      );
      this.update({ data: response.data.menuItemUpsells });
    }
    catch (error) {
      throw error;
    }
    finally {
      this.commit((state) => {
        state.submitting = false;
      });
    }
  }
}
