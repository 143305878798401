<template>
  <validated-form
    ref="orderingDataForm"
    form-id="orderingDataForm"
    @valid-submit="exportCsv"
  >
    <b-message
      v-if="!dataExportEnabledInEnv"
      type="is-warning"
      size="is-large"
      class="mar-b"
    >
      Data Exports are only enabled in development, UAT, and production environments.
    </b-message>
    <template v-else>
      <div class="is-flex align-start gap">
        <validated-input label-position="on-border" label="Date Range" rules="required" name="dateRange">
          <b-datepicker
            ref="datePicker"
            v-model="dateRange"
            class="date-picker-input has-extra-shadow"
            icon="calendar-alt"
            range
            nearby-selectable-month-days
            :mobile-native="false"
            :max-date="today"
            placeholder="Select Date Range"
            :focused-date="focusedDate"
            :selectable-dates="selectableDates"
            data-test-id="date-range-picker"
            @range-start="handleRangeStartSelect"
            @range-end="handleRangeEndSelect"
          >
            <div class="is-flex align-center justify-between">
              <p>Select up to 31 days at a time</p>
              <div class="buttons is-right">
                <b-button @click="dateRange = []">Clear</b-button>
              </div>
            </div>
          </b-datepicker>
        </validated-input>
        <validated-input
          label-position="on-border"
          label="Order Type"
          data-test-id="order-type-dropdown"
          rules="required"
          name="orderType"
        >
          <b-dropdown
            :value="selectedOrderTypeIds"
            :mobile-modal="false"
            aria-role="list"
            class="has-extra-shadow"
            multiple
          >
            <dropdown-button
              slot="trigger"
              placeholder="Select Order Type"
              :value="selectedOrderTypesDisplay"
            />

            <b-dropdown-item
              v-if="orderingTypeOptions.length > 1"
              aria-role="listitem"
              :class="{ 'is-active': selectedOrderTypeIds.length === orderingTypeOptions.length }"
              @click="toggleAllOrderTypesSelect"
            >
              All Order Types
            </b-dropdown-item>

            <hr class="dropdown-divider">

            <b-dropdown-item
              v-for="orderingType in orderingTypeOptions"
              :key="`orderType${orderingType.value}`"
              :value="orderingType.value"
              aria-role="listitem"
              @click="toggleOrderTypeSelect(orderingType.value)"
            >
              {{ orderingType.display }}
            </b-dropdown-item>
          </b-dropdown>
        </validated-input>
        <regional-location-dropdown
          v-if="!isAnalyticsUser"
          :value="selectedStoreIds"
          :stores="stores"
          label="Location"
          sub-label=""
          select-all-mode="includeAll"
          required
          label-position="on-border"
          :loading="fetching"
          @input="(value) => selectedStoreIds = value"
        />
        <validated-input
          label-position="on-border"
          label="Order Status"
          data-test-id="order-status-dropdown"
          rules="required"
          name="orderStatus"
        >
          <b-dropdown
            :value="selectedOrderStatusIds"
            :mobile-modal="false"
            aria-role="list"
            class="has-extra-shadow"
            multiple
          >
            <dropdown-button
              slot="trigger"
              :value="orderStatusDisplay"
              placeholder="Select Order Status"
            />

            <b-dropdown-item
              aria-role="listitem"
              :class="{ 'is-active': selectedOrderStatusIds.length === Object.keys(orderStatuses).length }"
              @click="toggleAllStatusesSelect"
            >
              All Statuses
            </b-dropdown-item>

            <hr class="dropdown-divider">

            <b-dropdown-item
              v-for="[value, display] in Object.entries(orderStatuses)"
              :key="value"
              :value="value"
              aria-role="listitem"
              @click="toggleStatusSelect(value)"
            >
              {{ display }}
            </b-dropdown-item>
          </b-dropdown>
        </validated-input>
      </div>
      <div class="dist-y">
        <export-card
          v-for="cardMapping in exportCardMappings"
          :key="cardMapping.type"
          :title="cardMapping.title"
          :type="cardMapping.type"
          :subtitle="cardMapping.subtitle"
          :availability="cardMapping.availability"
          :exporting="exportingCsvFor[cardMapping.type]"
          @export="validateAndExport"
        />
      </div>
    </template>
  </validated-form>
</template>

<script>
  import { capitalCase } from 'change-case';
  import moment from 'moment-timezone';
  import { mapGetters } from 'vuex';

  import alertModal from '@/components/globals/alert-modal.vue';

  import dataExportStatuses from '@/constants/dataExportStatuses';
  import orderTypes from '@/constants/orderTypes';
  import orderStatuses from '@/constants/orderStatus';

  import merchantMixin from '@/mixins/merchant';

  import http from '@/services/http';
  import { subscribeToChannel, unsubscribeFromChannel } from '@/services/web-socket-service';

  import Store from '@/store/classes/Store';
  import MerchantLoyaltyProviderConfiguration from '@/store/classes/MerchantLoyaltyProviderConfiguration';

  import ExportCard from './export-card.vue';


  export default {
    name: 'OrderingDataTab',

    components: { ExportCard },

    mixins: [merchantMixin],

    props: {},

    data: () => ({
      orderStatuses,
      orderTypes,
      dateRange: [],
      focusedDate: null,
      selectableDates: [],
      selectedOrderTypeIds: [],
      selectedStoreIds: [],
      selectedOrderStatusIds: [],
      exportCardMappings: [
        {
          type: 'orders',
          subtitle: 'Orders data including order date, ticket number, ids, total, fees, and more.',
          availability: 'Orders data is available for all time.'
        },
        {
          type: 'items',
          subtitle: 'Order items data including item names, ids, quantity, price, category, and more.',
          availability: 'Order items data is available for all time.'
        },
        {
          type: 'payments',
          subtitle: 'Payments data including status, type, amount, card type, result codes, and more.',
          availability: 'Payments data is available for the past 90 days.'
        },
        {
          type: 'accounts',
          subtitle: 'Accounts data including account holder name, birth date, contact info, created date, and more.',
          availability: 'Accounts data includes all user accounts.'
        }
      ],
      exportingCsvFor: {},
      exportRequests: {},
      subscription: null,
      exportModals: {
        orders: null,
        items: null,
        payments: null
      }
    }),

    computed: {
      ...mapGetters('session', ['currentUser', 'isAnalyticsUser']),

      dataExportEnabledInEnv() {
        return [
          'localhost',
          'dev-my.cardfree.net',
          'uat-my.cardfree.net',
          'my.cardfree.com'
        ].includes(window.location.hostname);
      },

      fetching() {
        return Store.fetchingAll;
      },

      today() {
        return moment.utc(moment()
          .startOf('day'))
          .toDate();
      },

      thirtyOneDaysAgo() {
        // Dates are inclusive, so 30 days ago + today = 31 days
        return moment.utc(moment()
          .subtract(30, 'd')
          .startOf('day'))
          .toDate();
      },

      orderingTypeOptions() {
        const {
          externalOrderEnabled,
          oatEnabled,
          orderAheadEnabled,
          patEnabled,
          simpleOrderAheadEnabled,
          textToPayEnabled,
          supportsThirdPartyOrderAhead,
          features
        } = this.$_selectedMerchant;

        return [
          {
            display: 'Order Ahead',
            value: 1, // orderTypeId 1 = OrderAhead
            isVisible: orderAheadEnabled || simpleOrderAheadEnabled
          },
          {
            display: [patEnabled && 'Pay@Table', textToPayEnabled && 'Text-to-Pay'].filter(Boolean)
              .join(' / '),
            value: 2, // orderTypeId 2 = PayAndFulfill
            isVisible: patEnabled || textToPayEnabled
          },
          {
            display: 'Order@Table',
            value: 3, // orderTypeId 3 = OrderAndPay
            isVisible: oatEnabled
          },
          {
            display: 'In Store',
            value: 6, // orderTypeId 6 = ExternalOrder
            isVisible: externalOrderEnabled
          },
          {
            display: '3rd Party Order Ahead',
            value: 4,
            isVisible: supportsThirdPartyOrderAhead
          },
          {
            display: 'Tip Only',
            value: 5,
            isVisible: features.hotelMobileTipping
          }
        ].reduce((array, {
          display,
          value,
          isVisible
        }) => {
          if (isVisible) {
            array.push({
              display,
              value
            });
          }
          return array;
        }, []);
      },

      selectedOrderTypesDisplay() {
        if (this.selectedOrderTypeIds.length === 1) {
          return this.orderingTypeOptions.find(type => type.value === this.selectedOrderTypeIds[0])?.display;
        }
        else if (this.selectedOrderTypeIds.length === this.orderingTypeOptions.length) {
          return 'All Order Types';
        }
        else if (this.selectedOrderTypeIds.length > 1) {
          return `Selected (${this.selectedOrderTypeIds.length})`;
        }
        else {
          return '';
        }
      },

      stores() {
        return Store.orderByName().get();
      },

      orderStatusDisplay() {
        if (this.selectedOrderStatusIds.length === 1) {
          return orderStatuses[this.selectedOrderStatusIds[0]];
        }
        else if (this.selectedOrderStatusIds.length === Object.keys(orderStatuses).length) {
          return 'All Statuses';
        }
        else if (this.selectedOrderStatusIds.length > 1) {
          return `Selected (${this.selectedOrderStatusIds.length})`;
        }
        else {
          return '';
        }
      }
    },

    created() {
      this.onCreated();
    },

    destroyed() {
      this.onDestroyed();
    },

    methods: {
      async onCreated() {
        if (this.dataExportEnabledInEnv) {
          this.createWebSocket();
          // Set the date range to the last 31 days by default
          this.dateRange = [this.thirtyOneDaysAgo, this.today];
          // Set all order types, locations, and statuses to be selected by default
          this.selectedOrderTypeIds = this.orderingTypeOptions.map(type => type.value);
          this.selectedOrderStatusIds = Object.keys(orderStatuses);
          this.$nextTick(() => {
            this.focusedDate = this.dateRange[1];
          });
          await Promise.all([this.fetchStores(), this.fetchMerchantLoyaltyProviderConfigurations()]);
          this.selectedStoreIds = this.stores.map(store => store.storeId);
          this.selectedOrderStatusIds = Object.keys(orderStatuses);
        }
      },

      onDestroyed() {
        unsubscribeFromChannel(this.subscription);
      },

      validateAndExport(type) {
        this.$refs.orderingDataForm.handleSubmit({ $event: type });
      },

      createWebSocket() {
        this.subscription = subscribeToChannel(
          { channel: 'DataExportChannel', userId: this.currentUser.id },
          { received: this.handleSocketMessage }
        );
      },

      async handleSocketMessage(data) {
        this.resetExportModal(data.type);

        const requestComplete = [dataExportStatuses.FAILED, dataExportStatuses.SUCCEEDED].includes(data.message);
        if (requestComplete) {
          this.$set(this.exportingCsvFor, data.type, false);
        }

        switch (data.message) {
          case dataExportStatuses.STARTED:
            this.openExportInProgressSnackbar(data.type);
            break;

          case dataExportStatuses.SUCCEEDED:
            this.openExportSuccessModal(data.type);
            return window.open(data.file_url);

          case dataExportStatuses.FAILED:
            this.openExportFailureModal(data.type);
            break;

          default:
            break;
        }
      },

      handleRangeStartSelect(startDate) {
        this.dateRange[0] = startDate;
        const dates = [];

        /*
        * The selectable-dates prop for buefy can take an array of selectable dates. After a start date is
        * selected, we want to limit the selectable dates to 31 days before and after the start date.
        */
        for (let i = -31; i <= 31; i++) {
          const selectableDate = new Date(startDate);
          selectableDate.setDate(startDate.getDate() + i);
          dates.push(selectableDate);
        }

        this.selectableDates = dates;
      },

      handleRangeEndSelect() {
        /*
        * The selectable-dates prop for buefy can also take a function to determine if a date is selectable
        * After an end date is selected, we want to allow all dates to be selectable again. We do this by
        * setting the selectableDates prop to a function that returns true for all dates.
        */
        const allDatesAllowed = () => true;
        this.selectableDates = allDatesAllowed;
      },

      async fetchStores() {
        try {
          await Store.fetchAll();
        }
        catch (error) {
          this.$_onRequestError({
            error,
            toastOptions: { message: 'Unable to fetch stores' }
          });
        }
      },

      async fetchMerchantLoyaltyProviderConfigurations() {
        try {
          await MerchantLoyaltyProviderConfiguration.fetchMerchantLoyaltyProviderConfigurations(this.$_selectedMerchantId);
        }
        catch (error) {
          this.$_onRequestError({
            error,
            toastOptions: { message: 'Unable to fetch merchant loyalty provider configurations' }
          });
        }
      },

      toggleAllOrderTypesSelect() {
        if (this.selectedOrderTypeIds.length === this.orderingTypeOptions.length) {
          this.selectedOrderTypeIds = [];
        }
        else {
          this.selectedOrderTypeIds = this.orderingTypeOptions.map(type => type.value);
        }
      },

      toggleOrderTypeSelect(orderTypeId) {
        if (this.selectedOrderTypeIds.includes(orderTypeId)) {
          this.selectedOrderTypeIds = this.selectedOrderTypeIds.filter(id => id !== orderTypeId);
        }
        else {
          this.selectedOrderTypeIds.push(orderTypeId);
        }
      },

      toggleAllStatusesSelect() {
        this.selectedOrderStatusIds = this.selectedOrderStatusIds.length === Object.keys(orderStatuses).length
          ? []
          : Object.keys(orderStatuses);
      },

      toggleStatusSelect(status) {
        if (this.selectedOrderStatusIds.includes(status)) {
          this.selectedOrderStatusIds = this.selectedOrderStatusIds.filter(s => s !== status);
        }
        else {
          this.selectedOrderStatusIds.push(status);
        }
      },

      async exportCsv(type) {
        this.$set(this.exportingCsvFor, type, true);

        const orderStatusIds = this.selectedOrderStatusIds.length === Object.keys(orderStatuses).length
          ? []
          : this.selectedOrderStatusIds;

        const storeIds = this.selectedStoreIds.length === this.stores.length
          ? []
          : this.selectedStoreIds;

        try {
          await http.post(`merchants/${this.$_selectedMerchantId}/data_exports?type=${type}`, {
            exportParams: {
              startDate: moment.utc(this.dateRange[0]).format('YYYY-MM-DD'),
              endDate: moment.utc(this.dateRange[1]).format('YYYY-MM-DD'),
              orderTypeIds: this.selectedOrderTypeIds,
              storeIds,
              orderStatusIds
            }
          });
        }
        catch (error) {
          this.$set(this.exportingCsvFor, type, false);
          this.$_onRequestError({
            error,
            toastOptions: { message: 'There was a problem exporting your data' }
          });
        }
      },

      resetExportModal(dataType) {
        if (!this.exportModals[dataType]) return;

        this.exportModals[dataType].close();
        this.exportModals[dataType] = null;
      },

      openExportInProgressModal(dataType) {
        const dataExportInProgressModal = this.$buefy.modal.open({
          parent: this,
          component: alertModal,
          hasModalCard: true,
          trapFocus: true,
          canCancel: false,
          customClass: 'auto-width',
          props: {
            buttons: [{ text: 'Okay', primary: true }],
            title: `Exporting ${capitalCase(dataType)} Data`,
            message: 'This may take a few minutes...',
            type: 'is-primary',
            showCloseButton: false,
            icon: 'download',
            iconPack: 'fas',
            onClose: () => this.openExportInProgressSnackbar(dataType)
          }
        });

        this.exportModals[dataType] = dataExportInProgressModal;
      },

      openExportSuccessModal(dataType) {
        const dataExportSuccessModal = this.$buefy.modal.open({
          parent: this,
          component: alertModal,
          hasModalCard: true,
          trapFocus: true,
          canCancel: false,
          customClass: 'auto-width',
          props: {
            buttons: [
              { text: 'Okay', onClick: this.closeExportSuccessModal }
            ],
            showCloseButton: false,
            icon: 'check-circle',
            iconPack: 'far',
            title: `${capitalCase(dataType)} Data Export Successful`,
            message: "We've successfully exported your data set. You can locate the data in your device's Downloads folder.",
            type: 'is-success'
          }
        });

        this.exportModals[dataType] = dataExportSuccessModal;
      },

      openExportFailureModal(dataType) {
        const dataExportErrorModal = this.$buefy.modal.open({
          parent: this,
          component: alertModal,
          hasModalCard: true,
          trapFocus: true,
          canCancel: false,
          customClass: 'auto-width',
          props: {
            buttons: [
              { text: 'Contact Support', secondary: true, onClick: this.openExportFailureEmail },
              { text: 'Try Again', primary: true, onClick: () => this.exportCsv(dataType) }
            ],
            icon: 'exclamation-triangle',
            iconPack: 'far',
            title: `${capitalCase(dataType)} Data Export Failed`,
            message: "We're unable to export your data. Please try again or contact support.",
            type: 'is-danger'
          }
        });

        this.exportModals[dataType] = dataExportErrorModal;
      },

      openExportInProgressSnackbar(dataType) {
        const dataExportInProgressSnackbar = this.$buefy.snackbar.open({
          message: `<i class="far fa-sync mar-r-sm spin-smooth"></i>${capitalCase(dataType)} Data Export In Progress...`,
          type: 'is-primary',
          position: 'is-top-right',
          indefinite: true,
          actionText: null,
          queue: false
        });

        this.exportModals[dataType] = dataExportInProgressSnackbar;
      },

      openExportFailureEmail() {
        window.location.assign('mailto:support@cardfree.com?subject=Data Export Failure');
      }
    }
  };
</script>
