import { Model } from '@vuex-orm/core';
import http from '@/services/http';

export default class PaymentTransaction extends Model {
  static entity = 'paymentTransactions';

  // FIELDS //////////////////////
  static fields() {
    return {
      id: this.attr(''), // 470864,
      orderId: this.attr(''), // 108573,
      merchantId: this.attr(''), // 100000,
      storeId: this.attr(''), // 10072,
      merchantReference: this.attr(''), // "40021",
      paymentTransactionType: this.attr(''), // "Authorize",
      paymentTransactionStatus: this.attr(''), // "Succeeded",
      paymentMethod: this.attr(''), // "CreditCard",
      paymentGatewayType: this.attr(''), // "HeartlandGateway",
      cardHolderName: this.attr(''), // "c smith",
      postalCode: this.attr(''), // "80504",
      accountNumberLastFour: this.attr(''), // "1111",
      cardType: this.attr(''), // "VISA",
      amount: this.attr(''), // 2.68,
      surcharge: this.attr(null), // 0,
      currencyCode: this.attr(''), // "USD",
      transactionDateTime: this.attr(''), // "2024-01-09T19:01:10.000Z",
      paymentProcessorTransactionId: this.attr(''), // "1936121413",
      originalTransactionId: this.attr(''),
      paymentState: this.attr(''),
      authorizationCode: this.attr(''), // "12954A",
      gatewayResultCode: this.attr(''), // "00",
      gatewayResponse: this.attr(''),
      avsResultCode: this.attr(''), // "0",
      cvvResultCode: this.attr(''), // "M",
      recordTypeId: this.attr(''), // 1,
      clientCorrelationId: this.attr(''), // "40b3e6d7-0f3a-459b-beb4-9c5cfeba0aa1",
      clientTransactionId: this.attr(''), // "40b3e6d7-0f3a-459b-beb4-9c5cfeba0aa1",
      createdDateTime: this.attr(''), // "2024-01-09T19:01:11.000Z",
      createdSource: this.attr(''), // "Web",
      createdUserIp: this.attr(''), // "148.170.36.254"
      accountSavedPaymentId: this.attr(''),
      requestedBy: this.attr(''),
      paymentProcessorReference1: this.attr(''),
      paymentProcessorReference2: this.attr(''),
      paymentProcessorReference3: this.attr(''),
      resultCode: this.attr(''),
      resultMessage: this.attr(''),
      allowRefundOnDevice: this.attr(''),
      allowRefundOnPortal: this.attr(''),
      transactions: this.attr([]),

      // Not on the PaymentTransaction model. Calculated on CAPI and returned when
      // calling PaymentTransaction.getPaymentDetails()
      remainingRefundAmount: this.attr(''),

      // used "internally" for retrieving Orders in the
      // same order they were received from the endpoint
      sortOrder: this.attr('')
    };
  }


  // STATE //////////////////////
  static state() {
    return {
      searching: false,
      fetchingId: false,
      refunding: false,
      voiding: false,
      capturing: false,
      paymentTransactionMetadata: {}
    };
  }

  static $state() {
    return this.store().state.entities.paymentTransactions;
  }

  get isRefundableType() {
    const isCaptureOrSale = ['Capture', 'Sale'].includes(this.paymentTransactionType);
    const isSuccess = this.paymentTransactionStatus === 'Succeeded';
    const allowedToRefundOnPortal = this.allowRefundOnPortal;
    const noOrderId = !this.orderId;
    return [
      isCaptureOrSale,
      isSuccess,
      allowedToRefundOnPortal,
      noOrderId
    ].every(Boolean);
  }

  get isVoidableOrCaptureableType() {
    return this.paymentTransactionType === 'Authorize'
    && !this.orderId
    && this.paymentTransactionStatus === 'Succeeded';
  }

  static hasBeenRefunded(groupedTransactions, remainingRefundAmount) {
    return !!groupedTransactions.find(t => t.paymentTransactionType === 'Refund'
    && t.paymentTransactionStatus === 'Succeeded') && Number(remainingRefundAmount) === 0;
  }

  static hasBeenVoided(groupedTransactions) {
    return !!groupedTransactions.find(t => t.paymentTransactionType === 'Void'
    && t.paymentTransactionStatus === 'Succeeded');
  }

  static hasBeenCaptured(groupedTransactions) {
    return !!groupedTransactions.find(t => t.paymentTransactionType === 'Capture'
    && t.paymentTransactionStatus === 'Succeeded');
  }

  static isRefundable(transaction, groupedTransactions, remainingRefundAmount) {
    return [
      transaction.isRefundableType,
      !this.hasBeenVoided(groupedTransactions),
      !this.hasBeenRefunded(groupedTransactions, remainingRefundAmount),
      remainingRefundAmount > 0
    ].every(Boolean);
  }

  static isVoidableOrCaptureable(transaction, groupedTransactions) {
    // NOTE: if there is already a transaction in the group that has successfully been voided/captured,
    // cannot be voided/captured again
    return transaction.isVoidableOrCaptureableType
    && !this.hasBeenVoided(groupedTransactions)
    && !this.hasBeenCaptured(groupedTransactions);
  }


  // SORTORDER PARAMS
  // TransactionNumber
  // TransactionNumberDesc
  // MerchantReference
  // MerchantReferenceDesc
  // TransactionDateTime
  // TransactionDateTimeDesc
  // Amount
  // AmountDesc

  static async searchPaymentTransactions({
    page,
    perPage,
    fromDate,
    toDate,
    storeIds,
    paymentTransactionType,
    paymentMethod,
    paymentTransactionId,
    paymentTransactionStatus,
    merchantReference,
    cardholderName,
    accountNumberLastFour,
    amount,
    sortOrder,
    timezone,
    excludeOrderPayments = true
  }) {
    try {
      this.commit((state) => {
        state.searching = true;
      });

      this.deleteAll();

      const queries = [
        page && `currentPage=${page}`,
        perPage && `pageSize=${perPage}`,
        sortOrder && `sortOrder=${sortOrder}`,
        fromDate && `fromDate=${fromDate}`,
        toDate && `toDate=${toDate}`,
        paymentTransactionType && `paymentTransactionType=${paymentTransactionType}`,
        paymentMethod && `paymentMethod=${paymentMethod}`,
        paymentTransactionId && `paymentTransactionId=${paymentTransactionId}`,
        paymentTransactionStatus && `paymentTransactionStatus=${paymentTransactionStatus}`,
        merchantReference && `merchantReference=${merchantReference}`,
        cardholderName && `cardholderName=${cardholderName}`,
        accountNumberLastFour && `accountNumberLastFour=${accountNumberLastFour}`,
        amount && `amount=${amount}`,
        timezone && `timezone=${timezone}`,
        // default for excludeOrderPayments is true
        !excludeOrderPayments && `excludeOrderPayments=${excludeOrderPayments}`,
        storeIds && storeIds.map(id => `storeIds[]=${id}`).join('&')
      ].filter(q => q);

      const queryString = queries.length ? `?${queries.join('&')}` : '';
      const merchantId = this.store().state.entities.merchants.selectedMerchantId;
      const {
        data: {
          payments,
          currentPage,
          pageSize,
          count,
          totalPages
        }
      } = await http.get(`merchants/${merchantId}/payment_transactions${queryString}`);


      this.commit((state) => {
        state.paymentTransactionMetadata = {
          currentPage,
          pageSize,
          count,
          totalPages
        };
      });

      return payments;
    }

    catch (error) {
      throw error;
    }

    finally {
      this.commit((state) => {
        state.searching = false;
      });
    }
  }

  static async getPaymentDetails(id) {
    // NOTE: this gets the grouped transactions for the payment transaction summary (does not have all attributes)
    try {
      this.commit((state) => {
        state.fetchingId = id;
      });

      const merchantId = this.store().state.entities.merchants.selectedMerchantId;
      const { data } = await http.get(`merchants/${merchantId}/payment_transactions/${id}/payment_details`);
      return data.paymentTransaction;
    }

    catch (error) {
      throw error;
    }

    finally {
      this.commit((state) => {
        state.fetchingId = false;
      });
    }
  }

  static async getPaymentTransactionById(id) {
    // NOTE: this gets all of the details for a single transaction (has all attributes)
    try {
      this.commit((state) => {
        state.fetchingId = id;
      });

      const merchantId = this.store().state.entities.merchants.selectedMerchantId;
      const { data } = await http.get(`merchants/${merchantId}/payment_transactions/${id}`);

      // NOTE: Does not return a primary key
      this.insert({
        data: { ...data.paymentTransaction, id }
      });
    }

    catch (error) {
      throw error;
    }

    finally {
      this.commit((state) => {
        state.fetchingId = false;
      });
    }
  }

  static async refund({ id, amount }) {
    try {
      this.commit((state) => {
        state.refunding = true;
      });

      const merchantId = this.store().state.entities.merchants.selectedMerchantId;
      await http.post(`merchants/${merchantId}/payment_transactions/${id}/refund`, {
        paymentTransaction: {
          amount: String(amount)
        }
      });
    }

    catch (error) {
      throw error;
    }

    finally {
      this.commit((state) => {
        state.refunding = false;
      });
    }
  }

  static async void(id) {
    try {
      this.commit((state) => {
        state.voiding = true;
      });

      const merchantId = this.store().state.entities.merchants.selectedMerchantId;
      await http.post(`merchants/${merchantId}/payment_transactions/${id}/void`);
    }

    catch (error) {
      throw error;
    }

    finally {
      this.commit((state) => {
        state.voiding = false;
      });
    }
  }

  static async capture(id) {
    try {
      this.commit((state) => {
        state.capturing = true;
      });

      const merchantId = this.store().state.entities.merchants.selectedMerchantId;
      await http.post(`merchants/${merchantId}/payment_transactions/${id}/capture`);
    }

    catch (error) {
      throw error;
    }

    finally {
      this.commit((state) => {
        state.capturing = false;
      });
    }
  }

  static resetState() {
    this.commit((state) => {
      state.paymentTransactionMetadata = {};
      state.searching = false;
    });
    this.deleteAll();
  }
}
