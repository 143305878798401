import storage from '@/services/storage';
import http from '@/services/http';
import events from '@/services/events';
import logger from '@/services/logger';
import { Ability } from '@casl/ability';
import { v4 as uuid } from 'uuid';

import User from '@/store/classes/User';
import Merchant from '@/store/classes/Merchant';
import roleTypes from '@/constants/roleTypes';


/**
* In CASL, for the abilities with conditions defined using the "$in" key,
* the "$in" key must have a value that is an array. But due to the way
* some resources are related and the way cancan generates the abilities,
* some of the conditions are defined with "$in" holding the value of an object,
* which breaks the udpate() method of the Ability class. We're not actually
* using or checking those conditions anyway, so this function will delete any
* "$in" key it finds holding a value other than an array
*/
function removeInvalidConditionDefinitions(abilities) { // TEST
  return abilities.map((ability) => {
    if (ability.conditions) {
      Object.keys(ability.conditions).forEach((key) => {
        if (
          ability.conditions[key].$in
          && !Array.isArray(ability.conditions[key].$in)
        ) {
          delete ability.conditions[key].$in;
        }
      });
    }
    return ability;
  });
}



export default {
  namespaced: true,

  state: {
    ability: new Ability(),
    currentUserId: null,
    sessionStarted: false,
    isExpired: false,

    isSignedIn: undefined
    /**
    * undefined: default state
    * true: user is fully signed in
    * false: sign in failed or incomplete (requires 2FA)
    */
  },

  getters: {
    currentUser(state) {
      return User.query().with('userRole').find(state.currentUserId);
    },

    isCardfreeAdmin(state, getters) {
      return getters.currentUser?.role?.name === roleTypes.CARDFREE_ADMIN;
    },

    isAdminUser(state, getters) {
      const { CARDFREE_ADMIN, MERCHANT_ADMIN, MERCHANT_ADMIN_LITE } = roleTypes;
      const adminRoles = [CARDFREE_ADMIN, MERCHANT_ADMIN, MERCHANT_ADMIN_LITE];
      return adminRoles.includes(getters.currentUser?.role?.name);
    },

    isAnalyticsUser(state, getters) {
      return getters.currentUser?.role?.name === roleTypes.ANALYTICS;
    },

    isMultiMerchantUser(state, getters) {
      return getters.currentUser?.merchants.length > 1;
    },

    isLocationBasedUser(state, getters) {
      const { GUEST_SUPPORT, LOCATION_MANAGER, LOCATION_OPERATOR, MENU_MANAGER } = roleTypes;
      const locationBasedRoles = [GUEST_SUPPORT, LOCATION_MANAGER, LOCATION_OPERATOR, MENU_MANAGER];
      return locationBasedRoles.includes(getters.currentUser?.role?.name);
    },

    hasAnalyticsAccess(state, getters) {
      const { MERCHANT_ADMIN, MERCHANT_ADMIN_LITE, LOCATION_MANAGER, ANALYTICS } = roleTypes;
      const analyticsRoles = [MERCHANT_ADMIN, MERCHANT_ADMIN_LITE, LOCATION_MANAGER, ANALYTICS];
      return analyticsRoles.includes(getters.currentUser?.role?.name);
    },

    hasMarketingAccess(state, getters) {
      const { MERCHANT_ADMIN, MERCHANT_ADMIN_LITE, MARKETING_MANAGER } = roleTypes;
      const marketingRoles = [MERCHANT_ADMIN, MERCHANT_ADMIN_LITE, MARKETING_MANAGER];
      return marketingRoles.includes(getters.currentUser?.role?.name);
    },

    isUsingPwa() {
      return window.matchMedia('(display-mode: standalone)').matches || window.navigator?.standalone === true;
    }
  },

  actions: {
    async initializeSession({ commit, dispatch }) {
      try {
        // attempt to set deviceId for a common uuid specific to each device for NewRelicLoggingService
        let deviceId = storage.local.get('deviceId');
        if (!deviceId) {
          deviceId = uuid();
          storage.local.set('deviceId', deviceId);
        }

        // attempt to fetch the user using the session cookie
        const { data: { user } } = await http.get('sessions');
        const savedMerchantId = storage.local.get('merchantId');

        // a user session has started but the user
        // may not be fully signed in yet due to 2FA
        commit('SET_SESSION_STARTED');
        await dispatch('setSessionUser', { user, savedMerchantId });
      }
      catch (error) {
        commit('SET_SIGN_IN_STATE', false);
        logger.error(error);
      }
    },

    async signIn({ commit, dispatch }, { email = '', password = '', stream = {} }) {
      try {
        // remove any existing 2FA cookie thats been set
        storage.cookie.remove('two_factor');

        const signInArgs = {
          session: {
            user: { email, password }
          }
        };

        if (stream.clientId) {
          signInArgs.session.stream = stream;
        }

        const { data: { user } } = await http.post('sessions', signInArgs);

        // a session has started but the user
        // may not be fully signed in yet due to 2FA
        commit('SET_SESSION_STARTED');
        if (user) dispatch('setSessionUser', { user });
      }
      catch (error) {
        logger.error(error);
        throw error;
      }
    },

    async setSessionUser({ commit }, { user, savedMerchantId }) {
      User.insert({ data: user });

      // emit "authorized" event to re-request any collected un-authorized requests
      events.$emit('authorized');

      // remove any 2FA cookie thats been set
      storage.cookie.remove('two_factor');

      // fetch and set a selected merchant if one is available
      let merchantId = savedMerchantId;
      if (user.merchants.length === 1) merchantId = user.merchants[0].id;
      if (merchantId) {
        await Merchant.fetchMerchant(merchantId, { isFetchingSelectedMerchant: true });
        Merchant.setSelectedMerchantId(merchantId);
      }

      commit('SET_CURRENT_USER_ID', user.id);
      commit('SET_SIGN_IN_STATE', true);

      // update the Ability instance with the new ability definitions
      commit(
        'UPDATE_ABILITIES',
        removeInvalidConditionDefinitions(user.abilities)
      );
    },

    async signOut({ commit, dispatch, rootState }) {
      try {
        await http.delete('sessions');

        // Clear out cookies
        storage.cookie.clearAll();

        // Clear selected merchantId
        Merchant.setSelectedMerchantId(null);
        Merchant.clearFullyFetchedMerchantIds();

        // Clear abilities
        commit('UPDATE_ABILITIES', []);

        // clear all data from VuexORM entities
        Object.keys(rootState.entities)
          .filter(key => !key.includes('$'))
          .forEach(entity => dispatch(`entities/${entity}/deleteAll`, null, { root: true }));

        commit('SET_SIGN_IN_STATE', false);
      }
      catch (error) {
        logger.error(error);
      }
    },

    async twoFactorAuth({ dispatch }, token) {
      try {
        const { data: { user } } = await http.post('sessions/two_factor', { token });
        dispatch('setSessionUser', { user });
      }
      catch (error) {
        throw error;
      }
    },

    async sendNewPasscode() {
      try {
        await http.put('sessions/resend_passcode');
      }
      catch (error) {
        throw error;
      }
    },

    unauthorizedAccess({ commit, state }) {
      if (state.sessionStarted) commit('SET_SESSION_EXPIRED');
    }
  },

  mutations: {
    UPDATE_ABILITIES(state, abilities) {
      // use the ability instance's update method,
      // passing in the JSON array of ability definitions
      state.ability.update(abilities);
    },

    SET_CURRENT_USER_ID(state, id) {
      state.currentUserId = id;
    },

    // a user session has started and any previously
    // expired session is cleared but the user may not
    // be fully signed in yet due to 2FA
    SET_SESSION_STARTED(state) {
      state.sessionStarted = true;
      state.isExpired = false;
    },

    // a user session has expired and the user is no longer signed in
    SET_SESSION_EXPIRED(state) {
      state.isSignedIn = false;
      state.sessionStarted = false;
      state.isExpired = true;
    },

    SET_SIGN_IN_STATE(state, value) {
      state.isSignedIn = value;
    }
  }
};
