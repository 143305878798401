var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section-wrapper',{attrs:{"title":"Receipt Locations","loading":_vm.isFetchingReceiptLocations || _vm.isFetchingFulfillmentTypes || _vm.isFetchingPlatformTypes}},[_c('b-table',{staticClass:"is-middle-aligned flex-grow",attrs:{"data":_vm.receiptLocations,"loading":_vm.isDeletingReceiptLocations,"mobile-cards":false},scopedSlots:_vm._u([{key:"empty",fn:function(){return [_c('p',{staticClass:"has-text-centered pad is-size-5 has-text-grey-light"},[_vm._v(" No Receipt Locations Configured ")])]},proxy:true}])},[_c('b-table-column',{attrs:{"field":"fulfillmentType","label":"Fulfillment Type"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(_vm.getFulfillmentTypeName(row.fulfillmentTypeId))+" ")]}}])}),_c('b-table-column',{attrs:{"field":"platformType","label":"Platform Type"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(_vm.getPlatformTypeName(row.platformTypeId))+" ")]}}])}),_c('b-table-column',{attrs:{"field":"orderType","label":"Order Type"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(_vm.orderTypes[row.orderTypeId])+" ")]}}])}),_c('b-table-column',{attrs:{"field":"receiptPrinterId","label":"Receipt Printer Id"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.receiptPrinterId)+" ")]}}])}),_c('b-table-column',{attrs:{"centered":"","cell-class":"actions","label":"Actions","width":"1"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('div',{staticClass:"is-flex dist-x-xs"},[_c('b-button',{staticClass:"is-transparent",attrs:{"type":"is-white"},on:{"click":function($event){return _vm.openReceiptLocation({ mode: 'update', receiptLocation: row })}}},[_c('b-icon',{attrs:{"icon":"pencil","size":"is-small"}})],1),_c('b-button',{staticClass:"is-transparent",attrs:{"type":"is-white"},on:{"click":function($event){return _vm.openRemoveReceiptLocationConfirmation(row)}}},[_c('b-icon',{attrs:{"icon":"trash-alt","type":"is-danger","size":"is-small"}})],1)],1)]}}])})],1),_c('div',{staticClass:"pad-x pad-y-sm has-border-top has-border-grey-lighter"},[_c('b-button',{staticClass:"is-transparent",attrs:{"disabled":_vm.hasAllReceiptLocations,"icon-left":"plus","type":"is-primary","inverted":""},on:{"click":_vm.openReceiptLocation}},[_vm._v(" Receipt Location ")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }