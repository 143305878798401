const validationMixin = {
  methods: {
    $_focusFirstInvalid() {
      const firstInvalid = this.$el.querySelector(`
          .invalid input:not([type="hidden"]):not([tabindex="-1"]),
          .invalid textarea,
          .invalid .radio:first-child,
          .invalid select,
          .invalid .dropdown .button
        `);
      if (firstInvalid) {
        firstInvalid.focus({ preventScroll: true });
        firstInvalid.scrollIntoView({ block: 'center', behavior: 'smooth' });
      }
    }
  }
};

export default validationMixin;
