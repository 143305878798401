<template>
  <modal-card
    :title="title"
    modal-card-body-class="pad-none"
    :class="{ 'pad-b-md': !$can('update', 'StoreMenuItem') }"
    @closed="$_clearFormStore"
    @keypress.native.enter="$_handleSubmitForms"
  >

    <b-tabs
      v-model="activeTabIndex"
      :animated="false"
      expanded
      class="is-marginless is-floating"
    >
      <template v-for="(tab, index) in tabs">
        <b-tab-item v-if="!tab.isHidden" :key="tab.label">
          <template #header>
            <span>{{ tab.label }}</span>
            <b-icon v-if="invalidTabIndexes.includes(index)" type="is-danger" icon="exclamation-circle" />
          </template>
          <component
            :is="tab.component"
            v-bind="tab.props"
          />
        </b-tab-item>
      </template>
    </b-tabs>

    <template v-if="($can('update', 'StoreMenuItem', 'price') || $can('update', 'StoreMenuItem', 'totalSalesTaxRate') || $can('update', 'StoreMenuItem', 'inventoryCount') && ($_menuPermissions.UPDATE_INVENTORY || $_menuPermissions.UPDATE_PRICE_TAX)) || ($can('update', 'StoreMenuItemSchedule') && $_menuPermissions.MANAGE_DAYPART_SCHEDULE)" #footer>
      <div class="buttons all-bold">
        <b-button
          rounded
          @click="closeModal"
        >
          Cancel
        </b-button>
        <b-button
          v-tabbable
          rounded
          type="is-primary"
          :loading="isSubmittingForms"
          @click="$_handleSubmitForms"
        >
          Save
        </b-button>
      </div>
    </template>
  </modal-card>
</template>

<script>
  import merchantMixin from '@/mixins/merchant';
  import confirmModalCloseMixin from '@/mixins/confirm-modal-close';
  import multiFormParentProvider from '@/mixins/multiFormMixin/multiFormParentProvider';

  import storeItemDetailsForm from './store-item-details-form.vue';
  import storeItemScheduleForm from './store-item-schedule-form.vue';
  import StoreItem from '@/store/classes/StoreItem';
  import Store from '@/store/classes/Store';

  export default {
    name: 'StoreItemModal',

    mixins: [confirmModalCloseMixin, merchantMixin, multiFormParentProvider],

    props: {
      item: {
        type: Object,
        required: true
      },

      storeItem: {
        type: Object,
        default: null
      },

      storeId: {
        type: [String, Number],
        required: true
      },

      defaultTabIndex: {
        type: Number,
        default: null
      }
    },

    computed: {
      title() {
        return this.item.displayName;
      },

      _storeItem() {
        return this.storeItem || new StoreItem({ menuId: this.storeId, menuItemId: this.item.id });
      },

      store() {
        return Store.find(this.storeId);
      },

      tabs() {
        return [
          {
            label: 'Details',
            component: storeItemDetailsForm,
            props: {
              item: this.item,
              storeItem: this._storeItem,
              store: this.store,
              formRef: 'storeItemDetailsForm'
            },
            isHidden: !(this.$can('read', 'StoreMenuItem') && (this.$_menuPermissions.UPDATE_INVENTORY || this.$_menuPermissions.READ_PRICE_TAX))
          },
          {
            label: 'Availability',
            component: storeItemScheduleForm,
            props: {
              itemId: this.item.id,
              storeId: this.storeId,
              formRef: 'storeItemScheduleForm'
            },
            isHidden: !(this.$can('read', 'StoreMenuItemSchedule') && this.$_menuPermissions.MANAGE_DAYPART_SCHEDULE)
          }
        ];
      }
    },

    methods: {
      async closeModal() {
        const confirmed = await this.$_confirmCloseModal({ programmatic: true });
        if (confirmed) this.$_clearFormStore();
      },

      onFormSubmitSuccess() {
        this.$_onRequestSuccess({
          toastOptions: { message: `Successfully updated ${this.item.displayName}` },
          options: {
            closeParent: true
          }
        });
      }
    }
  };
</script>

<style scoped lang="sass">
  .modal-card
    max-width: 769px
    margin: 0 auto !important
</style>
