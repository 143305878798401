<template>
  <tabs-page
    :tabs="tabs"
    :keep-alive="false"
    title="Tip Reports"
  />
</template>

<script>
  import tipReportsTable from './tip-reports-table.vue';

  import Department from '@/store/classes/Department';
  import Employee from '@/store/classes/Employee';
  import Store from '@/store/classes/Store';

  export default {
    name: 'LoyaltyManagement',

    computed: {
      tabs() {
        return [
          {
            display: 'Department Reports',
            name: 'department',
            component: tipReportsTable
          },
          {
            display: 'Employee Reports',
            name: 'employee',
            component: tipReportsTable,
            props: {
              forEmployees: true
            }
          }
        ];
      }
    },

    async created() {
      this.fetchDepartments();
      this.fetchEmployees();
      this.fetchStores();
    },

    methods: {
      async fetchDepartments() {
        try {
          await Department.fetchDepartments();
        }
        catch (error) {
          this.$_onRequestError({
            toastOptions: {
              message: 'Unable to fetch departments'
            },
            error
          });
        }
      },

      async fetchEmployees() {
        try {
          await Employee.fetchEmployees();
        }
        catch (error) {
          this.$_onRequestError({
            toastOptions: {
              message: 'Unable to fetch employees'
            },
            error
          });
        }
      },

      async fetchStores() {
        try {
          await Store.fetchAll();
        }
        catch (error) {
          this.$_onRequestError({
            toastOptions: {
              message: 'Unable to fetch stores'
            },
            error
          });
        }
      }
    }
  };
</script>
