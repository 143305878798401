<template>
  <div class="card">
    <div class="card-content">
      <p class="title">{{ survey.title }}</p>
      <p v-if="survey.description" class="subtitle is-5">{{ survey.description }}</p>

      <hr>

      <template v-if="survey.randomQuestionsEnabled">
        <p class="title is-4">
          All of these questions will be asked:
        </p>
        <div class="dist-y-xl pad-md has-shadow has-border has-border-grey-lightest has-radius-large mar-b-xl">
          <survey-question-preview
            v-for="(question, i) in alwaysShowQuestions"
            :key="`always-show-question-${i + 1}`"
            :question="question"
          />
        </div>

        <p class="title is-4">
          Only {{ survey.numberOfRandomQuestions }} of these questions will be asked:
        </p>
        <div class="dist-y-xl pad-md has-shadow has-border has-border-grey-lightest has-radius-large">
          <survey-question-preview
            v-for="(question, i) in randomizedQuestions"
            :key="`randomized-question-${i + 1}`"
            :question="question"
          />
        </div>
      </template>

      <div v-else class="dist-y-xl">
        <survey-question-preview
          v-for="(question, i) in activeQuestions"
          :key="`question-${i + 1}`"
          :question="question"
        />
      </div>
    </div>
  </div>
</template>

<script>
  import surveyQuestionPreview from './survey-question-preview.vue';
  import { questionTypes } from '@/constants/surveyQuestionTypes';

  export default {
    name: 'SurveyPreview',

    components: { surveyQuestionPreview },

    props: {
      survey: {
        type: Object,
        required: true
      }
    },

    data: () => ({ questionTypes }),

    computed: {
      activeQuestions() {
        return this.survey.questions.filter(q => q.isActive);
      },

      alwaysShowQuestions() {
        return this.activeQuestions.filter(q => q.alwaysInclude);
      },

      randomizedQuestions() {
        return this.activeQuestions.filter(q => !q.alwaysInclude);
      }
    }
  };
</script>
