<template>
  <validated-form
    v-slot="{ errors }"
    ref="form"
    auto-focus
    form-id="addStoreDeliveryServiceForm"
    @valid-submit="addLocalStoreDeliveryService"
  >
    <modal-card title="Add Delivery Service">
      <validated-input label="Delivery Services" name="deliveryServices" rules="required">
        <b-dropdown
          v-model="selectedIds"
          inline
          multiple
          expanded
          :class="errors.deliveryServices && errors.deliveryServices.length && 'has-errors'"
        >
          <b-dropdown-item
            v-for="{ id, deliveryServiceId, name } in merchantDeliveryServices"
            :key="id"
            :value="deliveryServiceId"
            :disabled="isExisting(deliveryServiceId)"
            :class="isExisting(deliveryServiceId) && 'is-active'"
          >
            {{ capitalCase(name) }}
          </b-dropdown-item>
        </b-dropdown>
      </validated-input>
      <template #footer>
        <div class="buttons all-bold">
          <b-button rounded @click="$_confirmCloseModal({ programmatic: true })">
            Cancel
          </b-button>
          <b-button
            v-tabbable
            rounded
            native-type="submit"
            type="is-primary"
            :loading="loading"
          >
            Add
          </b-button>
        </div>
      </template>
    </modal-card>
  </validated-form>
</template>

<script>
  import { capitalCase } from 'change-case';

  import MerchantDeliveryService from '@/store/classes/MerchantDeliveryService';
  import StoreDeliveryService from '@/store/classes/StoreDeliveryService';
  import confirmModalCloseMixin from '@/mixins/confirm-modal-close';
  import merchantMixin from '@/mixins/merchant';

  export default {
    name: 'AddEditStoreDeliveryServiceModal',

    mixins: [confirmModalCloseMixin, merchantMixin],

    props: {
      storeId: {
        type: Number,
        required: true
      },

      existingStoreDeliveryServiceIds: {
        type: Array,
        required: true
      }
    },

    data() {
      return {
        capitalCase,
        selectedIds: [],
        loading: false
      };
    },

    computed: {
      merchantDeliveryServices() {
        return MerchantDeliveryService
          .query()
          .where('merchantId', this.$_selectedMerchantId)
          .where('platformTypeId', 1)
          .get();
      }
    },

    methods: {
      isExisting(deliveryServiceId) {
        return this.existingStoreDeliveryServiceIds.includes(deliveryServiceId);
      },

      addLocalStoreDeliveryService() {
        this.selectedIds.forEach((id) => {
          const sds = new StoreDeliveryService({
            storeId: this.storeId,
            deliveryServiceId: id,
            isSaved: false
          });
          sds.$save();
        });

        this.$parent.close();
      }
    }
  };
</script>
