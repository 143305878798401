/* eslint-disable import/no-cycle */
import { Model } from '@vuex-orm/core';
import http from '@/services/http';



export default class GiftCard extends Model {
  static entity = 'giftCards'

  static fields() {
    return {
      id: this.attr(''),
      merchantId: this.attr(''),
      paymentGatewayTypeId: this.attr(''),
      accountId: this.attr(''),
      nickName: this.attr(''),
      cardId: this.attr(''),
      paymentGatewayCardId: this.attr(''),
      designId: this.attr(''),
      cardNumber: this.attr(''),
      pin: this.attr(''),
      balance: this.attr(''),
      balanceRefreshDate: this.attr(''),
      currencyCode: this.attr(''),
      isVirtual: this.attr(''),
      expirationMonth: this.attr(''),
      expirationYear: this.attr(''),
      isActive: this.attr(''),
      createdAt: this.attr('')
    };
  }

  // STATE //////////////////////
  static state() {
    return {
      fetching: false,
      updating: false
    };
  }

  static $state() { // TEST ?
    return this.store().state.entities.giftCards;
  }


  // Actions
  static async fetchGiftCardsForAccount({ accountId, ids }) {
    try {
      this.commit((state) => {
        state.fetching = true;
      });

      const params = ids?.length
        ? { ids }
        : null;

      const response = await http.get(`accounts/${accountId}/gift_cards`, { params });

      this.insertOrUpdate({ data: response.data.giftCards });
    }
    catch (error) {
      throw error;
    }
    finally {
      this.commit((state) => {
        state.fetching = false;
      });
    }
  }

  static async addFunds({ giftCardId, amount }) {
    try {
      this.commit((state) => {
        state.updating = true;
      });

      const { data } = await http.post(`gift_cards/${giftCardId}/add_funds`, { amount });

      return this.update({
        data: data.giftCard
      });
    }
    catch (error) {
      throw error;
    }
    finally {
      this.commit((state) => {
        state.updating = false;
      });
    }
  }

  static async mergeFunds({ destinationCardId, sourceCardIds }) {
    try {
      this.commit((state) => {
        state.updating = true;
      });

      const { data } = await http.post(`gift_cards/${destinationCardId}/merge`, { sourceCardIds });

      return this.update({
        data: data.giftCard
      });
    }
    catch (error) {
      throw error;
    }
    finally {
      this.commit((state) => {
        state.updating = false;
      });
    }
  }

  static async updateActiveStatus({ giftCardId, action }) {
    try {
      this.commit((state) => {
        state.updating = true;
      });

      const payload = action === 'deactivate'
        ? { deleteGiftCard: true }
        : {};

      const { data } = await http.post(`gift_cards/${giftCardId}/${action}`, payload);

      return this.update({
        data: data.giftCard
      });
    }
    catch (error) {
      throw error;
    }
    finally {
      this.commit((state) => {
        state.updating = false;
      });
    }
  }

  // This is confusing, but this route techinally just toggles the GiftCard.isActive on CAPI.
  // The updateActiveStatus route above sets the active status on the actual gift card provider.
  static async unregister(giftCardId) {
    try {
      this.commit((state) => {
        state.updating = true;
      });

      await http.delete(`gift_cards/${giftCardId}`);
    }
    catch (error) {
      throw error;
    }
    finally {
      this.commit((state) => {
        state.updating = false;
      });
    }
  }
}
