import { Model } from '@vuex-orm/core';



export default class ContentTypeProperty extends Model {
  static entity = 'contentTypeProperties'

  static fields() {
    return {
      id: this.attr(''),
      contentTypeId: this.attr(''),
      isArray: this.attr(''),
      isObject: this.attr(''),
      isImage: this.attr(''),
      jsonSchema: this.attr(''),
      propertyName: this.attr('')
    };
  }
}
