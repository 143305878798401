/* eslint-disable import/no-cycle */
import { Model } from '@vuex-orm/core';
import http from '@/services/http';



export default class DeliveryFeeType extends Model {
  static entity = 'deliveryFeeTypes'

  static fields() {
    return {
      id: this.attr(''),
      name: this.attr('')
    };
  }

  // STATE //////////////////////
  static state() {
    return {
      fetching: false
    };
  }

  static $state() {
    return this.store().state.entities.deliveryFeeTypes;
  }


  // Actions
  static async fetchDeliveryFeeTypes() {
    try {
      this.commit((state) => {
        state.fetching = true;
      });

      const { data } = await http.get('delivery_fee_types');

      this.create({ data: data.deliveryFeeTypes });
    }
    catch (error) {
      throw error;
    }
    finally {
      this.commit((state) => {
        state.fetching = false;
      });
    }
  }
}
