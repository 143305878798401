<template>
  <collapsible-section
    ref="collapsible-section"
    title="Segmentation Summary"
    start-open
    header-background-color="white-ter"
  >
    <ul>
      <li v-for="(mapping, type) in signatureDisplayMapping" :key="mapping.countKey">
        <span class="has-text-primary has-text-weight-bold">
          {{ signature[mapping.countKey] }}
        </span>
        guests will receive
        {{ mapping.display === 'offer' || type === notificationTypes.EMAIL ? 'an' : 'a' }}
        <span class="has-text-primary has-text-weight-bold">
          {{ mapping.display }}
        </span>
        <span v-if="type !== primaryMappingKey">
          {{ campaign.willIncludeOffer ? 'about the offer' : 'campaign' }}
        </span>
      </li>
    </ul>
    <div class="mar-t-sm has-text-grey-dark has-text-weight-bold">
      totals are estimates and are recalculated nightly
    </div>
  </collapsible-section>
</template>

<script>
  import Segmentation from '@/store/classes/Segmentation';
  import SegmentationSignature from '@/store/classes/SegmentationSignature';
  import { notificationTypes } from '@/constants/campaigns';

  export default {
    name: 'CampaignSegmentationDetails',

    props: {
      campaign: {
        type: Object,
        required: true
      },

      hasPushNotificationsConfigured: {
        type: Boolean,
        default: false
      },

      hasSmsConfigured: {
        type: Boolean,
        default: false
      }
    },

    data() {
      return {
        signature: {},
        notificationTypes,
        primaryMappingKey: 'primary'
      };
    },

    computed: {
      segmentation() {
        return Segmentation.find(this.campaign.segmentation.id);
      },

      signatureDisplayMapping() {
        const displayMapping = this.campaign.willIncludeOffer
          ? { [this.primaryMappingKey]: { countKey: 'accountCountForLoyalty', display: 'offer' } }
          : { [this.primaryMappingKey]: { countKey: 'totalAccountCountForCriteria', display: 'campaign' } };

        if (this.campaign.emailTemplates?.length) {
          displayMapping[notificationTypes.EMAIL] = { countKey: 'accountCountForEmail', display: 'email' };
        }

        if (this.hasPushNotificationsConfigured && this.campaign.pushNotification) {
          displayMapping[notificationTypes.PUSH] = { countKey: 'accountCountForPush', display: 'push notification' };
        }

        if (this.hasSmsConfigured && this.campaign.smsNotification) {
          displayMapping[notificationTypes.SMS] = { countKey: 'accountCountForSms', display: 'SMS' };
        }

        return displayMapping;
      }
    },

    created() {
      this.onCreated();
    },

    methods: {
      async onCreated() {
        await this.fetchSegmentationById();
        await this.fetchSegmentationSignature();
      },

      async fetchSegmentationById() {
        try {
          await Segmentation.fetchSegmentationById(this.campaign.segmentation.id);
        }

        catch (error) {
          this.$_onRequestError({
            toastOptions: {
              message: 'There was an error this campaign\'s segmentation'
            },
            error
          });
        }
      },

      async fetchSegmentationSignature() {
        try {
          this.signature = await SegmentationSignature.fetchSegmentationSignature(this.segmentation);
        }

        catch (error) {
          this.$_onRequestError({
            toastOptions: {
              message: 'There was an error fetching Target Audience data'
            },
            error
          });
        }
      }
    }
  };
</script>
