<template>
  <div tabindex="0" class="modal-card">
    <header class="modal-card-head is-flex-column pad-none">
      <div
        class="order-search-inputs is-full-width is-flex justify-between gap-xl pad-md"
        data-test-id="order-search-inputs"
      >
        <b-field class="mar-none">
          <b-radio-button
            v-model="searchType"
            native-value="guestName"
            type="is-primary is-outlined"
          >
            <b-icon icon="user" />
            <span>Guest Name</span>
          </b-radio-button>

          <b-radio-button
            v-model="searchType"
            native-value="ticketNumber"
            type="is-primary is-outlined"
          >
            <b-icon icon="hashtag" />
            <span>Ticket Number</span>
          </b-radio-button>
        </b-field>
        <validated-text-input
          v-model="query"
          type="text"
          label="Search"
          hide-label
          name="expoSearchInput"
          :placeholder="
            `Search by ${searchType === 'guestName' ? 'Guest Name' : 'Ticket Number'} ` +
              `(min ${searchType === 'guestName' ? 3 : 1} character${searchType === 'guestName' ? 's' : ''})`
          "
          class="mar-none flex-grow"
          icon-right="times-circle"
          icon-right-clickable
          @icon-right-click="clearSearchQuery"
        />
        <b-button
          type="is-danger is-light"
          @click="setSearchModalActive(false)"
        >
          Cancel
        </b-button>
      </div>
      <div
        class="expo-order-search-card-columns is-grid align-center justify-start is-full-width pad-y-xs pad-x-xl"
        data-test-id="expo-order-search-card-columns"
      >
        <span class="has-text-weight-bold is-size-7">Name</span>
        <span class="has-text-weight-bold is-size-7">Status</span>
        <span class="has-text-weight-bold is-size-7">Delivery Progress</span>
        <span class="has-text-weight-bold is-size-7">Pickup Time</span>
        <span class="has-text-weight-bold is-size-7">Driver</span>
        <span class="has-text-weight-bold is-size-7">Ticket #</span>
        <span class="has-text-weight-bold is-size-7">Total</span>
      </div>
    </header>

    <div
      class="modal-card-body"
      :class="{ 'is-flex justify-center is-clipped': fetching }"
    >
      <div
        v-if="fetching"
        class="is-flex justify-center"
      >
        <b-icon
          pack="fal"
          type="is-grey-lighter"
          icon="spinner-third"
          class="spin"
          custom-size="fa-4x"
          style="height: unset; width: unset;"
        />
      </div>
      <div
        v-else
        class="dist-y-sm"
      >
        <expo-order-search-card
          v-for="result in searchResults"
          :key="result.orderId"
          :order="result"
        />
      </div>
    </div>
  </div>
</template>

<script>
  import debounce from 'lodash.debounce';
  import { mapActions, mapGetters, mapState } from 'vuex';

  import ExpoOrderSearchCard from './expo-order-search-card.vue';

  export default {
    name: 'OrderExpoSearch',

    components: { ExpoOrderSearchCard },

    data: () => ({
      searchType: 'guestName',
      query: ''
    }),

    computed: {
      ...mapState('orderExpo', ['searchResults']),
      ...mapGetters('orderExpo', ['fetching'])
    },

    watch: {
      query: debounce(function () { // eslint-disable-line func-names
        this.handleSearchOrders();
      }, 666),

      searchType() {
        this.resetSearch();
      }
    },

    methods: {
      ...mapActions('orderExpo', ['searchOrders', 'setSearchResults', 'setSearchModalActive']),

      async handleSearchOrders() {
        const minLength = this.searchType === 'ticketNumber' ? 1 : 3;
        if (this.query && this.query.length >= minLength) {
          const searchParam = { [this.searchType]: this.query };
          await this.searchOrders(searchParam);
        }
      },

      resetSearch() {
        this.clearSearchQuery();
        this.setSearchResults([]);
      },

      clearSearchQuery() {
        this.query = '';
      }
    }
  };
</script>

<style lang='sass' scoped>
.order-search-inputs
  box-shadow: 3px 0 5px rgba(0, 0, 0, 0.1)
.expo-order-search-card-columns
  grid-template-columns: 3fr repeat(6, 1fr) 50px
</style>
