<template>
  <takeover>
    <div v-if="loading" class="has-text-centered">
      <b-icon class="spin" size="is-large" pack="fal" icon="spinner-third" />
    </div>
    <template v-else>
      <header class="has-text-centered mar-b-lg">
        <img class="image is-inline is-64x64" src="/images/cardfree-icon-logo.svg" alt="Admin Portal - Cardfree">
        <h1 class="title">{{ content.title }}</h1>
      </header>
      <template v-if="confirmationErrorType">
        <b-message v-if="confirmationErrorType === 'token'" type="is-danger" class="has-shadow is-compact">
          There was an error attempting<br>
          to confirm this account.
        </b-message>
        <b-message v-else-if="confirmationErrorType === 'email'" type="is-warning" class="has-shadow is-compact">
          This account has already been confirmed.<br><br>
          Please use the <i>Forgot Password</i> button if you need to set or reset your password.
        </b-message>
        <div class="buttons is-centered">
          <b-button
            rounded
            outlined
            type="is-dark"
            class="icon-shift"
            icon-left="arrow-left"
            tag="router-link"
            :to="{ name: 'signIn' }"
          >
            Back to Sign In
          </b-button>
          <b-button
            rounded
            type="is-primary"
            tag="router-link"
            :to="{ name: 'forgotPassword' }"
          >
            Forgot Password
          </b-button>
        </div>
      </template>
      <template v-else>
        <b-message type="is-success" class="has-shadow is-compact">
          {{ content.message }}
        </b-message>
        <password-form v-if="resetPasswordToken" :reset-password-token="resetPasswordToken" />
        <div v-else class="buttons is-centered">
          <b-button
            rounded
            type="is-primary"
            tag="router-link"
            :to="{ name: 'signIn' }"
          >
            Sign In
          </b-button>
        </div>
      </template>
    </template>
  </takeover>
</template>

<script>
  import passwordForm from '@/components/pages/password/password-form.vue';
  import http from '@/services/http';
  import logger from '@/services/logger';

  export default {
    name: 'EmailConfirmation',

    components: { passwordForm },

    metaInfo() {
      return { title: this.content.title };
    },

    data() {
      return {
        loading: false,
        resetPasswordToken: '',
        confirmationErrorType: null
      };
    },

    computed: {
      content() {
        return {
          title: this.resetPasswordToken ? 'Create Password' : 'Email Confirmation',
          message: this.resetPasswordToken
            ? 'Your account has been successfully confirmed! Please create a password to continue.'
            : 'Your email has been successfully confirmed! Please sign back in with your updated email.'
        };
      }
    },

    created() {
      this.onCreated();
    },

    methods: {
      async onCreated() {
        try {
          this.toggleLoading(true);
          const { data } = await http.get(`users/confirmation?confirmationToken=${this.$route.query.token}`);
          this.resetPasswordToken = data.user?.passwordResetToken;
        }
        catch (error) {
          logger.error(error);
          this.confirmationErrorType = error.data.errors.user.email ? 'email' : 'token';
        }
        finally {
          this.toggleLoading(false);
        }
      },
      toggleLoading(value) {
        this.loading = value;
      }
    }
  };
</script>
