/* eslint-disable import/no-cycle */
import { Model } from '@vuex-orm/core';
import filterObjectKeys from '@/helpers/filter-object-keys';
// classes
import http from '@/services/http';


export default class MerchantTermsOfService extends Model {
  static entity = 'merchantTermsOfServices'

  static primaryKey = 'merchantId'

  // FIELDS //////////////////////
  static fields() {
    return {
      id: this.attr(''),
      merchantId: this.attr(''),
      termsOfService: this.attr(null),
      privacyPolicy: this.attr(null)
    };
  }

  // STATE //////////////////////
  static state() {
    return {
      fetching: false
    };
  }

  static $state() { // TEST ?
    return this.store().state.entities.merchantTermsOfServices;
  }

  // ACTIONS //////////////////////
  static async fetchMerchantTermsOfServices() {
    try {
      this.commit((state) => {
        state.fetching = true;
      });

      const merchantId = this.store().state.entities.merchants.selectedMerchantId;

      const response = await http.get(`merchants/${merchantId}/merchant_terms_of_services`);

      this.create({
        data: response.data.merchantTermsOfServices
      });
    }

    catch (error) {
      throw error;
    }

    finally {
      this.commit((state) => {
        state.fetching = false;
      });
    }
  }

  static async createMerchantTermsOfService({ merchantId, merchantTermsOfService }) {
    try {
      const acceptedKeys = [
        'termsOfService',
        'privacyPolicy'
      ];

      const response = await http.post(`merchants/${merchantId}/merchant_terms_of_services`, {
        merchantTermsOfService: filterObjectKeys(merchantTermsOfService, acceptedKeys)
      });

      this.insert({
        data: response.data.merchantTermsOfService
      });

      return response.data.merchantTermsOfService;
    }
    catch (error) {
      throw error;
    }
  }

  static async updateMerchantTermsOfService(merchantTermsOfService) {
    try {
      const acceptedKeys = [
        'termsOfService',
        'privacyPolicy'
      ];
      const response = await http.put(`merchant_terms_of_services/${merchantTermsOfService.id}`, {
        merchantTermsOfService: filterObjectKeys(merchantTermsOfService, acceptedKeys)
      });

      this.update({
        data: response.data.merchantTermsOfService
      });
    }
    catch (error) {
      throw error;
    }
  }
}
