<template>
  <section-wrapper title="Point of Sale" :loading="isFetchingMerchantPOS">
    <b-table
      :data="storePos"
      class="is-middle-aligned flex-grow"
      hoverable
      :mobile-cards="false"
    >
      <template #empty>
        <p class="has-text-centered pad is-size-5 has-text-grey-light">
          No POS Providers Configured
        </p>
      </template>
      <b-table-column v-slot="{ row }" sortable field="fullName" label="Name">
        {{ row.posType.name }}
      </b-table-column>

      <b-table-column
        v-slot="{ row }"
        centered
        cell-class="actions"
        label="Actions"
        width="1"
      >
        <div class="is-flex dist-x-xs">
          <b-button class="is-transparent" type="is-white" @click="openPOSConfigModal(row)">
            <b-icon icon="pencil" size="is-small" />
          </b-button>
          <b-button
            class="is-transparent"
            type="is-white"
            @click="openRemovePOSModal(row)"
          >
            <b-icon icon="trash-alt" type="is-danger" size="is-small" />
          </b-button>
        </div>
      </b-table-column>
    </b-table>
    <div class="pad-x pad-y-sm has-border-top has-border-grey-lighter">
      <b-button
        inverted
        type="is-primary"
        class="is-transparent"
        icon-left="plus"
        :disabled="!!storePos.length"
        @click="openPOSConfigModal(null)"
      >
        New POS

      </b-button>
    </div>
  </section-wrapper>
</template>

<script>
  import merchantMixin from '@/mixins/merchant';
  import posConfigurationModal from '@/components/pages/merchants/tabs/merchant-configs-tab-content/add-pos-configuration-modal.vue';
  import PosConfiguration from '@/store/classes/PosConfiguration';

  export default {
    name: 'StorePOS',

    mixins: [merchantMixin],

    props: {
      storeId: {
        type: Number,
        required: true
      }
    },

    computed: {
      isFetchingMerchantPOS() {
        return PosConfiguration.$state().fetching;
      },

      storePos() {
        return PosConfiguration.query().where('storeId', this.storeId).get();
      }
    },

    methods: {
      openPOSConfigModal(currentPos) {
        this.$buefy.modal.open({
          parent: this,
          component: posConfigurationModal,
          hasModalCard: true,
          trapFocus: true,
          canCancel: false,
          customClass: 'full-width',
          props: {
            existingPosIds: this.storePos.map(spg => spg.posType.id),
            merchantId: this.$_selectedMerchantId,
            storeId: this.storeId,
            currentPos
          }
        });
      },

      openRemovePOSModal(currentPos) {
        this.$buefy.dialog.confirm({
          title: 'Delete Point of Sale',
          message: `Deleting <b>${currentPos.posType.name}</b> will delete its configuration at the merchant level and location level. This will impact your online ordering application. Are you sure?`,
          type: 'is-danger',
          confirmText: 'Delete',
          onConfirm: () => {
            this.removePosConfiguration(currentPos);
          }
        });
      },

      async removePosConfiguration(currentPOS) {
        try {
          await PosConfiguration.removePosConfiguration(currentPOS.id);
          this.$_onRequestSuccess({
            toastOptions: {
              message: 'Successfully removed your pos configuration!'
            }
          });
        }
        catch (error) {
          this.$_onRequestError({
            error,
            toastOptions: {
              message: 'There was an error removing your pos configuration'
            }
          });
        }
      }
    }
  };
</script>
