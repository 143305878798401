<template>
  <div
    :class="['expandable-section', !transactionDetails && 'is-hidden-print']"
    data-test-id="transaction-details-section"
  >
    <div
      class="expandable-section--header"
      @click="handleToggleTransactionDetails"
    >
      <h4 class="subtitle is-5 is-marginless">
        Transaction Details
      </h4>
      <b-icon v-if="!isLoadingTransactionDetails" class="open-indicator" :icon="transactionDetailsOpen ? 'angle-down' : 'angle-right'" />
      <b-icon v-else icon="spinner-third" class="spin" />
    </div>
    <b-collapse
      v-if="transactionDetails"
      animation="slide"
      :open="transactionDetailsOpen"
    >
      <div class="details-container">
        <b-message v-if="!transactionDetails.length" type="is-warning" class="has-shadow is-compact">
          {{ orderIsOver90DaysOld
            ? 'Transaction details are not available for orders older than 90 days.'
            : 'No transaction details found for this order'
          }}
        </b-message>
        <template v-else>
          <dl v-for="transaction in sortTransactionDetails(transactionDetails)" :key="transaction.id" class="mar-t-lg">
            <h4 :class="['has-text-weight-bold', 'is-uppercase', 'mar-r', {'is-failed': isFailedTransaction(transaction)}]">
              <span v-if="transaction.cardType">{{ transaction.cardType }}</span>
              <span v-else-if="transaction.paymentMethod && transaction.paymentMethod.name">{{ getPaymentMethodDisplay(transaction.paymentMethod.name) }}</span>
              <template v-if="transaction.accountNumberLastFour">
                <span>••••</span>
                <span>{{ transaction.accountNumberLastFour }}</span>
              </template>
            </h4>
            <div class="sub-container-row line-items" data-test-class="detail-row">
              <dt class="mar-r has-text-grey">Amount:</dt>
              <dd v-if="transaction.amount">{{ transaction.amount | dollars }}</dd>
              <dd v-else class="has-text-grey-light">N/A</dd>
            </div>
            <div class="sub-container-row line-items" data-test-class="detail-row">
              <dt class="mar-r has-text-grey">Date:</dt>
              <dd v-if="transaction.transactionDateTime">{{ transaction.transactionDateTime | moment('MMM DD, YYYY @ h:mm:ss A z', order.store.timeZone) }}</dd>
              <dd v-else class="has-text-grey-light">N/A</dd>
            </div>
            <div :class="['sub-container-row', 'line-items', {'is-failed': isFailedTransaction(transaction)}]" data-test-class="detail-row">
              <dt class="mar-r has-text-grey">Status:</dt>
              <dd v-if="transaction.paymentTransactionStatus && transaction.paymentTransactionStatus.name">
                {{ transaction.paymentTransactionStatus.name }}
              </dd>
              <dd v-else class="has-text-grey-light">N/A</dd>
            </div>
            <div class="sub-container-row line-items" data-test-class="detail-row">
              <dt class="mar-r has-text-grey">Payment Method:</dt>
              <dd v-if="transaction.paymentMethod && transaction.paymentMethod.name">
                {{ getPaymentMethodDisplay(transaction.paymentMethod.name) }}
              </dd>
              <dd v-else class="has-text-grey-light">N/A</dd>
            </div>
            <div class="sub-container-row line-items" data-test-class="detail-row">
              <dt class="mar-r has-text-grey">Transaction Type:</dt>
              <dd v-if="transaction.paymentTransactionType && transaction.paymentTransactionType.name">
                {{ transaction.paymentTransactionType.name }}
              </dd>
              <dd v-else class="has-text-grey-light">N/A</dd>
            </div>
            <div v-if="transaction.paymentTransactionType && transaction.paymentTransactionType.name === 'Refund'" class="sub-container-row line-items" data-test-class="detail-row">
              <dt class="mar-r has-text-grey">Refunded By:</dt>
              <dd v-if="transaction.requestedBy">
                {{ transaction.requestedBy }}
              </dd>
              <dd v-else class="has-text-grey-light">N/A</dd>
            </div>
            <div class="sub-container-row line-items" data-test-class="detail-row">
              <dt class="mar-r has-text-grey">Transaction Id:</dt>
              <dd v-if="transaction.paymentProcessorTransactionId">{{ transaction.paymentProcessorTransactionId }}</dd>
              <dd v-else class="has-text-grey-light">N/A</dd>
            </div>
            <div class="sub-container-row line-items" data-test-class="detail-row">
              <dt class="mar-r has-text-grey">Auth Code:</dt>
              <dd v-if="transaction.authorizationCode">{{ transaction.authorizationCode }}</dd>
              <dd v-else class="has-text-grey-light">N/A</dd>
            </div>
            <div class="sub-container-row line-items" data-test-class="detail-row">
              <dt class="mar-r has-text-grey">Error Code:</dt>
              <dd v-if="transaction.resultCode">{{ transaction.resultCode }}</dd>
              <dd v-else class="has-text-grey-light">N/A</dd>
            </div>
            <div class="sub-container-row line-items" data-test-class="detail-row">
              <dt class="mar-r has-text-grey">Error Message:</dt>
              <dd v-if="transaction.resultMessage">{{ transaction.resultMessage }}</dd>
              <dd v-else class="has-text-grey-light">N/A</dd>
            </div>
            <transaction-admin-details
              v-if="isCardfreeAdmin"
              :transaction-id="transaction.id"
            />
          </dl>
        </template>
      </div>
    </b-collapse>
  </div>
</template>

<script>
  import moment from 'moment-timezone';
  import { mapGetters } from 'vuex';


  import paymentMethods from '@/constants/paymentMethods';

  import capitalCase from '@/helpers/capitalCase';

  import http from '@/services/http';

  import TransactionAdminDetails from './transaction-admin-details.vue';

  export default {
    name: 'TransactionDetailsSection',

    components: { TransactionAdminDetails },

    props: {
      order: {
        type: Object,
        required: true
      }
    },

    data() {
      return {
        transactionDetails: null,
        isLoadingTransactionDetails: false,
        transactionDetailsOpen: false
      };
    },

    computed: {
      ...mapGetters('session', ['isCardfreeAdmin']),

      orderIsOver90DaysOld() {
        return moment(this.order.orderDate).isBefore(moment().subtract(90, 'days'));
      }
    },

    methods: {
      sortTransactionDetails(transactionDetails) {
        return JSON.parse(JSON.stringify(transactionDetails)).sort((a, b) => (a.transactionDateTime > b.transactionDateTime ? -1 : 1));
      },

      isFailedTransaction(transaction) {
        return transaction.paymentTransactionStatus?.name === 'Failed';
      },

      async handleToggleTransactionDetails() {
        if (this.transactionDetailsOpen) {
          this.transactionDetailsOpen = false;
        }
        else {
          try {
            this.isLoadingTransactionDetails = true;
            if (!this.transactionDetails) {
              const { merchantId, orderId } = this.order;
              const { data } = await http.get(`merchants/${merchantId}/orders/${orderId}/payment_transactions`);
              this.transactionDetails = data.paymentTransactions;
            }
            this.transactionDetailsOpen = true;
          }
          catch (error) {
            this.$_onRequestError({
              error,
              toastOptions: {
                message: 'Unable to get additional transaction details'
              }
            });
          }
          finally {
            this.isLoadingTransactionDetails = false;
          }
        }
      },

      getPaymentMethodDisplay(paymentMethodName) {
        return Object.values(paymentMethods).find(value => value.type === paymentMethodName)?.display || capitalCase(paymentMethodName);
      }
    }
  };
</script>

<style lang='sass' scoped>

  .is-failed
    *
      color: $danger !important

  .expandable-section
    border-bottom: 1px solid $grey-lighter
    padding: 15px 20px

    &:last-of-type
      border-bottom: none

    .expandable-section--header
      display: flex
      align-items: center
      justify-content: space-between
      cursor: pointer

      > *
        color: $grey-dark
        transition: 100ms

      &:hover > *
        color: $primary
    .details-container
      width: 100%
      margin-top: 1rem
      overflow: hidden

      .sub-container-row
        display: flex

        > div:not(:last-child)
          margin-right: 2rem

        &.line-items
          justify-content: space-between

          dt
            overflow: hidden
            text-overflow: ellipsis
            white-space: nowrap
</style>
