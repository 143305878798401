import { Model } from '@vuex-orm/core';


import http from '@/services/http';

export default class MerchantReceiptInstruction extends Model {
  static entity = 'merchantReceiptInstructions'

  // FIELDS //////////////////////
  static fields() {
    return {
      id: this.attr(''),
      merchantId: this.attr(''),
      instructions: this.attr(''),
      channelType: this.attr({})
    };
  }

  // STATE //////////////////////
  static state() {
    return {
      fetching: false
    };
  }

  static $state() { // TEST ?
    return this.store().state.entities.merchantReceiptInstructions;
  }


  // ACTIONS //////////////////////
  static async fetchMerchantReceiptInstructions(merchantId) {
    try {
      this.commit((state) => {
        state.fetching = true;
      });

      const response = await http.get(`merchants/${merchantId}/merchant_receipt_instructions`);

      this.create({ data: response.data.merchantReceiptInstructions });
    }

    catch (error) {
      throw error;
    }

    finally {
      this.commit((state) => {
        state.fetching = false;
      });
    }
  }

  static async createMerchantReceiptInstructions({ merchantId, merchantReceiptInstructions }) {
    try {
      const response = await http.post(`merchants/${merchantId}/merchant_receipt_instructions`, { merchantReceiptInstructions });

      this.insert({ data: response.data.merchantReceiptInstructions });
    }

    catch (error) {
      throw error;
    }
  }

  static async updateMerchantReceiptInstructions({ merchantId, merchantReceiptInstructions }) {
    try {
      const response = await http.put(`merchants/${merchantId}/merchant_receipt_instructions`, { merchantReceiptInstructions });

      this.update({ data: response.data.merchantReceiptInstructions });
    }

    catch (error) {
      throw error;
    }
  }
}
