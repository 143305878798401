<template>
  <div>
    <review-row
      v-for="(row, idx) in definitionRows"
      :key="`/review-row-${idx}`"
      :title="row.title"
      :subtitle="row.subtitle"
    />
    <item-group-review-row
      v-if="value.awardPolicy.itemGroup"
      :item-group="value.awardPolicy.itemGroup"
      hide-bottom-border
    />
  </div>
</template>

<script>
  import { awardPolicyTypes, earningItemBases, engagementTypes, randomAwardOptionTypes } from '@/constants/earnRules';
  import { itemAttributes } from '@/constants/items';

  import Offer from '@/store/classes/Offer';

  export default {
    name: 'DefinitionCard',

    props: {
      value: {
        type: Object,
        required: true
      }
    },

    computed: {
      definitionRows() {
        const engagementTypeDisplay = Object.values(engagementTypes).find(
          engagementTypeype => engagementTypeype.id === this.value.engagementType
        )?.display || '<Not Set>';

        const awardPolicyDisplay = Object.values(awardPolicyTypes).find(
          awardPolicyType => awardPolicyType.type === this.value.awardPolicy?.awardPolicyType
        )?.display || '<Not Set>';

        return [
          { title: 'Engagement Type', subtitle: engagementTypeDisplay },
          { title: 'Award Policy', subtitle: awardPolicyDisplay },
          ...this.awardPolicyDataDisplay
        ];
      },

      awardPolicyDataDisplay() {
        switch (this.value.awardPolicy?.awardPolicyType) {
          case awardPolicyTypes.ENROLLMENT_BONUS_POINT.type:
          case awardPolicyTypes.BIRTHDAY_POINT.type:
          case awardPolicyTypes.REFERRAL_CONVERTED_POINT.type:
          case awardPolicyTypes.SALES_TRANSACTION_FIXED_POINT.type:
            return [{ title: 'Points', subtitle: this.value.awardPolicy?.points }];
          case awardPolicyTypes.ENROLLMENT_BONUS_OFFER.type:
          case awardPolicyTypes.BIRTHDAY_OFFER.type:
          case awardPolicyTypes.REFERRAL_CONVERTED_OFFER.type:
          case awardPolicyTypes.CHECK_HEADER_OFFER.type:
          case awardPolicyTypes.MEMBER_PROXIMITY_OFFER.type: {
            const earnRuleOffer = this.findOffer(this.value.awardPolicy?.cardfreeOfferPublicId);
            return [{ title: 'Reward', subtitle: earnRuleOffer?.name }];
          }
          case awardPolicyTypes.CHECK_HEADER_POINT.type:
            return [
              { title: 'Points Per $1 Spent', subtitle: this.value.awardPolicy?.pointsPerDollarSpent },
              { title: 'Excludes', subtitle: this.excludedItemAttributeDisplay() }
            ];
          case awardPolicyTypes.SPECIFIED_ITEM_POINTS_PER_DOLLAR_SPENT.type:
            return [
              { title: 'Points Per $1 Spent', subtitle: this.value.awardPolicy?.pointsPerDollarSpent },
              !!this.value.awardPolicy?.maximumPointsPerSalesTransaction
                && { title: 'Max Total Points Earned', subtitle: this.value.awardPolicy?.maximumPointsPerSalesTransaction },
              { title: 'Earn Points For', subtitle: this.earningBasisTypeDisplay() },
              !!this.value.awardPolicy?.maximumEarningQuantity
                && { title: 'Max Earning Items', subtitle: this.value.awardPolicy?.maximumEarningQuantity }
            ].filter(Boolean);
          case awardPolicyTypes.SPECIFIED_ITEM_POINTS_PER_COUNT.type:
            return [
              { title: 'Points Per Count', subtitle: this.value.awardPolicy?.pointsPerQualifyingCount },
              { title: 'Qualifying Count Increment', subtitle: this.value.awardPolicy?.qualifyingCountIncrement },
              { title: 'Earn Points For', subtitle: this.earningBasisTypeDisplay() },
              { title: 'Max Total Points Earned', subtitle: this.value.awardPolicy?.maximumPointsPerSalesTransaction }
            ];
          case awardPolicyTypes.SALES_TRANSACTION_RANDOM.type: {
            const totalWeight = this.value.awardPolicy?.weightedAwardOptions.reduce((acc, { weight }) => acc + weight, 0);

            return this.value.awardPolicy?.weightedAwardOptions.map((weightedAwardOption, index) => {
              const calculatePercentage = (weight) => {
                const percentage = (weight / totalWeight) * 100;
                return percentage.toFixed(1);
              };
              const isOfferType = weightedAwardOption.awardOption.awardOptionType === randomAwardOptionTypes.OFFER;

              return {
                title: `Random ${isOfferType ? 'Offer' : 'Points'} ${index + 1} - Weight: ${weightedAwardOption.weight} (${calculatePercentage(weightedAwardOption.weight)}%)`,
                subtitle: isOfferType
                  ? this.findOffer(weightedAwardOption.awardOption?.reward?.cardfreeOfferPublicId)?.name || '<Not set>'
                  : `${weightedAwardOption.awardOption.points || '<Not Set>'} Points`
              };
            });
          }
          default:
            return [];
        }
      }
    },

    methods: {
      earningBasisTypeDisplay() {
        return Object.values(earningItemBases).find(basis => basis.id === this.value.awardPolicy.earningItemBasisType)?.display;
      },

      excludedItemAttributeDisplay() {
        const excludedItemAttributes = this.value.awardPolicy.excludedItemAttributes;
        if (excludedItemAttributes.length) {
          const itemAttributeEntries = Object.values(itemAttributes).reduce((acc, value) => ({ ...acc, [value.id]: value.display }), {});
          const attributes = excludedItemAttributes.map(attribute => itemAttributeEntries[attribute]).join(', ');
          return attributes;
        }
        else {
          return 'None';
        }
      },

      findOffer(offerPublicId) {
        return Offer.find(offerPublicId);
      }
    }
  };
</script>
