/* eslint-disable import/no-cycle */
import { Model } from '@vuex-orm/core';

import filterObjectKeys from '@/helpers/filter-object-keys';
import http from '@/services/http';



export default class MerchantGiftCardConfiguration extends Model {
  static entity = 'merchantGiftCardConfigurations'

  static fields() {
    return {
      id: this.attr(''),
      allowBalanceMerge: this.attr(false),
      allowZeroBalanceReloads: this.attr(true),
      autoReloadEnabled: this.attr(false),
      autoReloadMaximumAmount: this.attr(''),
      autoReloadMinimumAmount: this.attr(''),
      giftCardManagementEnabled: this.attr(false),
      giftingEnabled: this.attr(false),
      kioskGiftCardTopOffEnabled: this.attr(false),
      maxAllowedSavedCards: this.attr(5),
      maximumCardAmount: this.attr(''),
      maximumReloadAmount: this.attr(100),
      maximumThresholdAmount: this.attr(''),
      merchantId: this.attr(''),
      minimumReloadAmount: this.attr(5),
      minimumThresholdAmount: this.attr(''),
      requireCredentialsOnMergeGiftCards: this.attr(false),
      removeCardAfterMerge: this.attr(true),
      scheduledSendDaysMax: this.attr(30)
    };
  }

  static state() {
    return {
      fetching: false,
      submitting: false
    };
  }

  static $state() {
    return this.store().state.entities.merchantGiftCardConfigurations;
  }

  static async fetchMerchantGiftCardConfigurations(merchantId) {
    try {
      this.commit((state) => {
        state.fetching = true;
      });

      const id = merchantId || this.store().state.entities.merchants.selectedMerchantId;

      const { data } = await http.get(`merchants/${id}/merchant_gift_card_configurations`);

      this.create({
        data: data.merchantGiftCardConfigurations
      });
    }

    catch (error) {
      throw error;
    }

    finally {
      this.commit((state) => {
        state.fetching = false;
      });
    }
  }

  static async createMerchantGiftCardConfiguration(merchantGiftCardConfiguration) {
    try {
      this.commit((state) => {
        state.submitting = true;
      });

      const { data } = await http.post(
        `merchants/${merchantGiftCardConfiguration.merchantId}/merchant_gift_card_configurations`,
        { merchantGiftCardConfiguration: filterObjectKeys(merchantGiftCardConfiguration, ['id'], true) }
      );

      this.insert({
        data: data.merchantGiftCardConfiguration
      });
    }

    catch (error) {
      throw error;
    }

    finally {
      this.commit((state) => {
        state.submitting = false;
      });
    }
  }

  static async updateMerchantGiftCardConfiguration(merchantGiftCardConfiguration) {
    try {
      this.commit((state) => {
        state.submitting = true;
      });

      const { data } = await http.put(
        `merchant_gift_card_configurations/${merchantGiftCardConfiguration.id}`,
        { merchantGiftCardConfiguration }
      );

      this.update({
        data: data.merchantGiftCardConfiguration
      });
    }

    catch (error) {
      throw error;
    }

    finally {
      this.commit((state) => {
        state.submitting = false;
      });
    }
  }
}
