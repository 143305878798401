<template>
  <div class="card">
    <div class="card-content">
      <div class="is-flex justify-between">
        <div>
          <p :class="`subtitle is-4 ${subtitle ? 'mar-b-xs' : ''}`">
            {{ title }}
          </p>
          <p v-if="subtitle" class="subtitle is-6 mar-b has-text-grey">
            {{ subtitle }}
          </p>
        </div>
        <b-button
          v-if="!readOnly"
          type="is-primary"
          inverted
          @click="$emit('navigate-to-step')"
        >
          Edit
        </b-button>
      </div>
      <slot />
    </div>
  </div>
</template>

<script>
  export default {
    name: 'ReviewCard',

    props: {
      title: {
        type: String,
        required: true
      },
      subtitle: {
        type: String,
        default: null
      },
      readOnly: {
        type: Boolean,
        required: true
      }
    }
  };
</script>
