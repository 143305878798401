<template>
  <section-wrapper title="Point of Sale">
    <b-table
      :data="merchantPosConfigurations"
      class="is-middle-aligned flex-grow"
      hoverable
      :mobile-cards="false"
    >
      <template #empty>
        <p class="has-text-centered pad is-size-5 has-text-grey-light">
          No POS Providers Configured
        </p>
      </template>

      <b-table-column v-slot="{ row }" sortable field="posType.name" label="Name">
        {{ row.posType.name }}
      </b-table-column>

      <b-table-column
        v-slot="{ row }"
        centered
        cell-class="actions"
        label="Actions"
        width="1"
      >
        <div class="is-flex dist-x-xs">
          <b-button class="is-transparent" type="is-white" @click="openPosConfigurationModal(row)">
            <b-icon icon="pencil" size="is-small" />
          </b-button>
          <b-button
            class="is-transparent"
            type="is-white"
            @click="openRemovePosConfigurationModal(row)"
          >
            <b-icon icon="trash-alt" type="is-danger" size="is-small" />
          </b-button>
        </div>
      </b-table-column>
    </b-table>
    <div class="pad-x pad-y-sm has-border-top has-border-grey-lighter">
      <b-button
        inverted
        class="is-transparent"
        type="is-primary"
        icon-left="plus"
        :loading="submittingPosConfiguration"
        :disabled="!canAddPosConfigurations"
        @click="openPosConfigurationModal(null)"
      >
        New POS
      </b-button>
    </div>
  </section-wrapper>
</template>

<script>
  import addPosConfigurationModal from './add-pos-configuration-modal.vue';
  import PosConfiguration from '@/store/classes/PosConfiguration';
  import PosTypeOption from '@/store/classes/PosTypeOption';

  export default {
    name: 'MerchantPosConfigs',

    props: {
      merchant: {
        type: Object,
        default: null
      }
    },

    computed: {
      submittingPosConfiguration() {
        return PosConfiguration.$state().submitting;
      },

      allPosTypeOptions() {
        return PosTypeOption.all();
      },
      canAddPosConfigurations() {
        return this.allPosTypeOptions.length !== this.merchantPosConfigurations.length;
      },
      merchantPosConfigurations() {
        return this.merchant.posConfigurations.filter(mpos => mpos.storeId === -1);
      }
    },
    methods: {
      async removePosConfiguration(currentPOS) {
        try {
          await PosConfiguration.removePosConfiguration(currentPOS.id);
          this.$_onRequestSuccess({
            toastOptions: {
              message: 'Successfully removed your pos configuration!'
            }
          });
        }
        catch (error) {
          this.$_onRequestError({
            error,
            toastOptions: {
              message: 'There was an error removing your pos configuration'
            }
          });
        }
      },
      openRemovePosConfigurationModal(currentPos) {
        this.$buefy.dialog.confirm({
          title: 'Delete Point of Sale',
          message: `Deleting <b>${currentPos.posType.name}</b> will delete its configuration at the merchant level and location level. This will impact your online ordering application. Are you sure?`,
          type: 'is-danger',
          confirmText: 'Delete',
          onConfirm: () => {
            this.removePosConfiguration(currentPos);
          }
        });
      },

      openPosConfigurationModal(currentPos) {
        this.$buefy.modal.open({
          parent: this,
          component: addPosConfigurationModal,
          hasModalCard: true,
          trapFocus: true,
          canCancel: false,
          customClass: 'full-width',
          props: {
            existingPosIds: this.merchantPosConfigurations.map(mpg => mpg.posType.id),
            merchantId: this.merchant.id,
            currentPos
          }
        });
      }
    }
  };
</script>
