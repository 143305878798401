<template>
  <div class="opd detail-page">
    <b-loading :is-full-page="false" :active="isLoadingStores" />

    <audio>
      <source src="/audio/definite.mp3" type="audio/mpeg">
      <p>Your browser doesn't support HTML5 audio.</p>
    </audio>

    <section class="section">
      <div class="container">
        <div class="mar-b-lg">
          <h2 class="title is-3 mar-b-xs has-text-centered-mobile">Payment Dashboard</h2>
          <div class="columns">
            <div class="column has-text-centered-mobile">
              <div class="level is-mobile is-inline-flex">
                <div class="level-item">
                  <h3 v-if="currentDisplayDate" class="subtitle is-4 has-text-grey">
                    {{ currentDisplayDate }} - {{ currentDisplayTimeHours }}<span class="clock-separator">:</span>{{ currentDisplayTimeMinutes }}
                  </h3>
                  <b-skeleton v-else height="30" width="250" />
                </div>

                <div class="level-item">
                  <b-button
                    v-tippy="{ content: 'Refresh Orders', placement: 'bottom' }"
                    class="is-transparent"
                    @click="fetchOrders"
                  >
                    <b-icon icon="sync-alt" pack="far" class="has-text-primary" :custom-class="isFetchingOrders ? 'spin-smooth' : ''" />
                  </b-button>
                  <dropdown-menu trigger="hover">
                    <b-button slot="trigger" class="is-transparent" inverted>
                      <b-icon icon="info-circle" pack="far" class="has-text-primary" />
                    </b-button>
                    <b-dropdown-item class="info-dropdown-item no-wrap-text" custom>
                      <b-tag type="is-dark" class="has-text-weight-bold mar-r-sm">Open</b-tag><span>Order is open for payment</span>
                    </b-dropdown-item>
                    <b-dropdown-item class="info-dropdown-item no-wrap-text" custom>
                      <b-tag type="is-warning" class="has-text-weight-bold mar-r-sm">$ Requested</b-tag><span>Payment request has been sent to the guest</span>
                    </b-dropdown-item>
                    <b-dropdown-item class="info-dropdown-item no-wrap-text" custom>
                      <b-tag type="is-primary" class="has-text-weight-bold mar-r-sm">Paid</b-tag><span>Payment has been captured and Order can be closed</span>
                    </b-dropdown-item>
                    <b-dropdown-item class="info-dropdown-item no-wrap-text" custom>
                      <b-tag type="is-success" class="has-text-weight-bold mar-r-sm">Completed</b-tag><span>Order is fulfilled and closed</span>
                    </b-dropdown-item>
                  </dropdown-menu>
                </div>
              </div>
            </div>

            <div v-if="!isDemo" class="column">
              <b-field expanded>
                <b-select
                  v-model="selectedStoreId"
                  placeholder="Select a location..."
                  icon="map-marker-alt"
                  expanded
                >
                  <option v-for="store in allStores" :key="store.storeId" :value="store.storeId">{{ store.description }}</option>
                </b-select>
              </b-field>
            </div>
          </div>

          <div v-if="isCurrentStoreCardFreePos || isDemo" class="box has-background-white is-flex justify-between align-center">
            <div class="is-flex align-baseline">
              <p class="title is-size-5 is-flex align-center is-marginless">
                Request Payment
              </p>
              <b-icon
                v-tippy="{ content: 'Add a new order and request payment from a customer', placement: 'right', maxWidth: 220 }"
                class="mar-l-sm"
                icon="info-square"
                size="is-small"
                type="is-grey"
              />
            </div>
            <b-button
              type="is-primary"
              @click="openOpdOrderModal"
            >
              Request Payment
            </b-button>
          </div>
        </div>

        <div class="box is-paddingless">
          <b-tabs
            v-model="activeTab"
            size="is-medium"
            :animated="false"
            expanded
            :class="{
              open: activeTab === 0,
              paymentRequested: activeTab === 1,
              paid: activeTab === 2,
              completed: activeTab === 3
            }"
          >
            <b-tab-item v-for="(swimlane, index) in swimlanes" :key="index">
              <template #header>
                {{ swimlane.name }}
                <div
                  class="mar-l-sm count-indicator"
                  :class="getTagColor(swimlane.name)"
                >
                  <b>{{ swimlane.count }}</b>
                </div>
              </template>

              <template v-if="swimlane.orders.length">
                <div
                  :class="[
                    'column-headers',
                    {
                      'with-table-number': displayTableNumber,
                      'with-name': displayEmployeeName,
                    }
                  ]"
                >
                  <p
                    class="is-clickable has-hover-text-dark"
                    @click="toggleSortingDirection"
                  >
                    Ordered At
                    <b-icon :icon="sortingDirection === 'desc' ? 'angle-down' : 'angle-up'" />
                  </p>
                  <p v-if="displayTableNumber" class="has-text-centered">Table #</p>
                  <p v-if="displayEmployeeName">Server</p>
                  <p class="has-text-centered">Order #</p>
                  <p class="has-text-centered">Reference #</p>
                  <p class="has-text-right">Total</p>
                </div>
                <template v-for="(order, i) in swimlane.orders">
                  <div
                    v-if="i === 0
                      || moment.tz(swimlane.orders[i].orderDate, selectedStoreTimezone).date()
                        !== moment.tz(swimlane.orders[i - 1].orderDate, selectedStoreTimezone).date()
                    "
                    :key="order.orderId + 'date'"
                    class="date-separator is-size-7 pad-l-lg pad-y-xs has-background-grey-lighter has-text-weight-bold"
                  >
                    {{ swimlane.orders[i].orderDate | moment('MMMM D, YYYY', selectedStoreTimezone) }}
                  </div>
                  <opd-order
                    :key="order.orderId"
                    :is-demo="isDemo"
                    :is-open="selectedOrderId === order.orderId"
                    :order="order"
                    :display-table-number="displayTableNumber"
                    :display-employee-name="displayEmployeeName"
                    @toggle-open="handleToggleOpen"
                  />
                </template>
              </template>

              <div v-else-if="isFetchingOrders" class="pad-xl has-text-centered">
                <b-icon icon="spinner-third" pack="fal" type="is-grey is-light" class="spin fa-3x" />
              </div>

              <div v-else class="box has-text-centered has-text-grey">
                <b-icon icon="sun" type="is-warning" />
                <p class="is-size-5">No <b class="has-text-black">{{ swimlane.name | lowercase }}</b> orders currently.</p>
              </div>
            </b-tab-item>
          </b-tabs>
        </div>
      </div>
    </section>
  </div>
</template>



<script>
  import moment from 'moment-timezone';
  import Order from '@/store/classes/OpdOrder';
  import Store from '@/store/classes/Store';
  import Merchant from '@/store/classes/Merchant';
  import logger from '@/services/logger';
  import merchantMixin from '@/mixins/merchant';
  import opdOrderComponent from '@/components/pages/order-payment-dashboard/opd-order.vue';
  import events from '@/services/events';
  import opdOrderModal from './opd-order-modal.vue';
  import posTypes from '@/constants/posTypes';
  import storage from '@/services/storage';
  import { subscribeToChannel, unsubscribeFromChannel } from '@/services/web-socket-service';



  export default {
    name: 'OpdBoard',

    components: {
      'opd-order': opdOrderComponent
    },

    mixins: [merchantMixin],

    props: {
      isDemo: {
        type: Boolean,
        default: false
      }
    },

    data() {
      return {
        moment,
        posTypes,
        activeTab: 0,
        currentDisplayDate: null,
        currentDisplayTimeHours: null,
        currentDisplayTimeMinutes: null,
        currentTimeInterval: null,
        isLoadingStores: false,
        noStoresExist: false,
        selectedOrderId: null,
        selectedStoreId: null,
        snackbarDisconnect: null,
        sortingDirection: 'asc',
        subscription: null
      };
    },

    computed: {
      allStores() {
        return Store.orderByName().get();
      },

      selectedStore() {
        return Store.find(this.selectedStoreId);
      },

      selectedStoreTimezone() {
        return this.selectedStore?.ianaTimezoneId;
      },

      isCurrentStoreCardFreePos() {
        return this.selectedStore?.isCardfreePOS;
      },

      openOrders() {
        const queryResult = Order
          .query()
          .where('opdStatus', 'Open')
          .orderBy('orderDate', this.sortingDirection)
          .get();

        return queryResult || [];
      },

      paymentRequestedOrders() {
        const queryResult = Order
          .query()
          .where('opdStatus', 'PaymentRequested')
          .orderBy('orderDate', this.sortingDirection)
          .get();

        return queryResult || [];
      },

      paidOrders() {
        const queryResult = Order
          .query()
          .where('opdStatus', 'Paid')
          .orderBy('orderDate', this.sortingDirection)
          .get();

        return queryResult || [];
      },

      completedOrders() {
        const queryResult = Order
          .query()
          .where('opdStatus', 'Completed')
          .orderBy('orderDate', this.sortingDirection)
          .get();

        return queryResult || [];
      },

      swimlanes() {
        return [
          {
            name: 'Open',
            count: this.openOrders.length,
            orders: this.openOrders
          },
          {
            name: '$ Requested',
            count: this.paymentRequestedOrders.length,
            orders: this.paymentRequestedOrders
          },
          {
            name: 'Paid',
            count: this.paidOrders.length,
            orders: this.paidOrders
          },
          {
            name: 'Completed',
            count: this.completedOrders.length,
            orders: this.completedOrders
          }
        ];
      },

      isFetchingOrders() {
        return Order.$state().fetchingAll;
      },

      paidOrdersDiff() {
        return Order.$state().paidOrdersDiff;
      },

      displayTableNumber() {
        return [
          this.$_selectedMerchant.patEnabled,
          this.$_selectedMerchant.oatEnabled,
          this.$_selectedMerchant.orderAheadEnabled && this.selectedStore?.dineInEnabled
        ].some(x => x);
      },

      displayEmployeeName() {
        return this.$_selectedMerchant?.oatEnabled;
      }
    },

    watch: {
      selectedStoreId: 'onStoreChange',
      paidOrdersDiff: 'notifyOfPaidOrders'
    },

    created() {
      this.onCreated();
    },

    destroyed() {
      this.onDestroyed();
    },

    methods: {
      onCreated() {
        if (this.isDemo) {
          if (this.$route.query.merchantId) {
            const merchantId = Number(this.$route.query.merchantId);
            Merchant.fetchMerchant(merchantId);
            Merchant.setSelectedMerchantId(merchantId);
          }
          if (this.$route.query.storeId) {
            this.selectedStoreId = Number(this.$route.query.storeId);
          }
        }
        events.$on('opd:order-updated', this.orderUpdated);
        this.calculateCurrentTime();
        this.currentTimeInterval = window.setInterval(this.calculateCurrentTime, 1000);
      },

      onDestroyed() {
        events.$off('opd:order-updated');
        window.clearInterval(this.currentTimeInterval);
        Order.deleteAll();
        if (this.subscription) {
          unsubscribeFromChannel(this.subscription);
        }
        if (this.snackbarDisconnect) this.snackbarDisconnect.close();
      },

      async onMerchantLoad() {
        try {
          this.isLoadingStores = true;
          await Store.fetchAll();
          const defaultStoreId = storage.cookie.get(`pd_mid_${this.$_selectedMerchantId}_sid`) || Store.query().first()?.storeId;

          if (defaultStoreId) this.selectedStoreId = defaultStoreId;
          else this.noStoresExist = true;
        }
        catch (error) {
          logger.error(error);
        }
        finally {
          this.isLoadingStores = false;
        }
      },

      openSocketConnection(storeId) {
        if (this.subscription) {
          unsubscribeFromChannel(this.subscription);
        }
        this.subscription = subscribeToChannel(
          { channel: 'OpdChannel', store_id: storeId },
          { received: this.handleSocketMessage }
        );
      },

      async handleSocketMessage({ data }) {
        await Order.setNewOrders(data);
        if (this.selectedOrderId) {
          this.fetchOrder(this.selectedOrderId);
        }
      },

      onStoreChange(newStoreId) {
        storage.cookie.set(`pd_mid_${this.$_selectedMerchantId}_sid`, newStoreId);
        Order.deleteAll();
        this.fetchOrders();
        this.openSocketConnection(newStoreId);
      },

      notifyOfPaidOrders(newPaidOrders) {
        if (newPaidOrders.length) {
          const audioElement = this.$el.querySelector('audio');
          audioElement.play();

          newPaidOrders.forEach((o) => {
            const message = `<div><h4 class="title has-text-white is-5 mar-b-xs">Payment Received</h4><p class="is-size-6 has-text-grey-light">Order #${o.ticketNumber} has been paid</p></div>`;
            this.$buefy.snackbar.open({
              duration: 5000,
              message,
              queue: false,
              position: 'is-bottom',
              type: 'is-primary',
              onAction: () => this.switchToPaidOrder(o),
              actionText: 'View'
            });
          });
        }
      },

      toggleSortingDirection() {
        this.sortingDirection = this.sortingDirection === 'desc' ? 'asc' : 'desc';
      },

      async switchToPaidOrder(order) {
        await this.fetchOrder(order.orderId);

        this.activeTab = 2;

        const orderElement = this.$el.querySelector(`#opd-order-${order.orderId}`);

        this.$nextTick(() => {
          orderElement.scrollIntoView({
            behavior: 'smooth',
            block: 'center'
          });
        });

        this.toggleOpenOrder(order.orderId);
      },

      getTagColor(name) {
        if (name === 'Open') {
          return 'is-dark';
        }
        else if (name === '$ Requested') {
          return 'is-warning';
        }
        else if (name === 'Paid') {
          return 'is-primary';
        }
        else if (name === 'Completed') {
          return 'is-success';
        }
        return '';
      },

      calculateCurrentTime() {
        if (this.selectedStore) {
          const currentDate = moment().tz(this.selectedStoreTimezone);

          this.currentDisplayDate = currentDate.format('MMM Do');
          this.currentDisplayTimeHours = currentDate.format('h');
          this.currentDisplayTimeMinutes = currentDate.format('mm A z');
        }
      },

      async fetchOrders() {
        if (this.selectedStoreId) {
          try {
            await Order.fetchAllByLocationId(this.selectedStoreId);

            if (this.snackbarDisconnect) {
              this.resetSnackbarDisconnect();
            }
          }

          catch (error) {
            logger.error(error);

            if (!this.snackbarDisconnect) {
              this.snackbarDisconnect = this.$buefy.snackbar.open({
                message: 'Cannot connect to order service.',
                type: 'is-danger',
                position: 'is-bottom',
                actionText: 'Retry',
                indefinite: true,
                queue: false,
                onAction: () => {
                  this.resetSnackbarDisconnect();
                  this.fetchOrders();
                }
              });
            }
          }
        }
      },

      async resetSnackbarDisconnect() {
        await this.snackbarDisconnect.close();
        this.snackbarDisconnect = null;
      },

      orderUpdated(order, isCreatingOrder) {
        const tabIndex = {
          Open: 0,
          PaymentRequested: 1,
          Paid: 2,
          Completed: 3
        };

        const tabHasChanged = this.activeTab !== tabIndex[order.opdStatus];

        this.activeTab = tabIndex[order.opdStatus];

        if (tabHasChanged || isCreatingOrder) {
          setTimeout(() => {
            const orderElement = this.$el.querySelector(`#opd-order-${order.orderId}`);

            orderElement.scrollIntoView({
              behavior: 'smooth',
              block: 'center'
            });
          }, 200);
        }

        if (isCreatingOrder) {
          this.toggleOpenOrder(order.orderId);
        }
      },

      toggleOpenOrder(orderId) {
        this.selectedOrderId = this.selectedOrderId === orderId ? null : orderId;
      },

      async handleToggleOpen(orderId) {
        if (this.selectedOrderId !== orderId) {
          await this.fetchOrder(orderId);
        }
        this.toggleOpenOrder(orderId);
      },

      async fetchOrder(orderId) {
        try {
          await Order.getOrderDetails(orderId);
        }
        catch (error) {
          logger.error(error);
        }
      },

      openOpdOrderModal() {
        this.$buefy.modal.open({
          parent: this,
          component: opdOrderModal,
          trapFocus: true,
          customClass: 'auto-width',
          canCancel: false,
          props: {
            storeId: this.selectedStoreId
          }
        });
      }
    }
  };
</script>



<style lang="sass" scoped>
  .opd.detail-page
    background-color: $white-ter

  .container
    max-width: $desktop !important

  .container::v-deep .box
    transition: opacity 500ms
    opacity: 1

  .container::v-deep .is-transitioning .box
    opacity: 0
    transition: none

  .container::v-deep .tabs a
    font-weight: 700

  .clock-separator
    animation: pulse 1s infinite

  .info-dropdown-item
    display: grid
    grid-template-columns: 100px 1fr
    padding-right: 1rem

  ::v-deep .tab-content
    padding: 0


  .column-headers
    position: sticky
    top: 0
    z-index: 2
    display: grid
    grid-template-columns: minmax(150px, 2fr)  repeat(2, minmax(90px, 1fr)) minmax(90px, 0.5fr) 40px
    gap: $size-small
    font-weight: 600
    color: $grey
    background-color: $white-ter
    padding: 0.75rem 1.5rem
    border-bottom: 1px solid $grey-lighter
    white-space: nowrap
    &.with-name
      grid-template-columns: minmax(150px, 1fr) minmax(50px, 1fr) repeat(2, minmax(90px, 1fr)) minmax(90px, 0.5fr) 40px
    &.with-table-number
      grid-template-columns: minmax(150px, 1fr) 120px minmax(50px, 1fr) minmax(90px, 1fr) minmax(90px, 0.5fr) 40px
    &.with-name.with-table-number
      grid-template-columns: minmax(150px, 1fr) 120px minmax(50px, 1fr) repeat(2, minmax(90px, 1fr)) minmax(90px, 0.5fr) 40px

  .date-separator
    position: sticky
    top: 50px
    z-index: 1

  .count-indicator
    display: flex
    align-items: center
    justify-content: center
    border-radius: $radius-rounded
    color: $white
    font-size: 1rem
    min-width: 26px
    padding: 0 0.5rem
    height: 26px

    &.is-dark
      background-color: $dark
    &.is-warning
      background-color: $warning
      color: darken($warning, 45%)
    &.is-primary
      background-color: $primary
    &.is-success
      background-color: $success

  ::v-deep .tabs li
    &.is-active
      a
        transition: border-bottom-color 400ms
      .open &
        background: linear-gradient( to top, lighten($dark, 70%),transparent 20%)
        a
          border-bottom-color: $black

      .paymentRequested &
        background: linear-gradient( to top, lighten($warning, 28%),transparent 20%)
        a
          border-bottom-color: darken($warning, 18%)

      .paid &
        background: linear-gradient( to top, lighten($primary, 45%),transparent 20%)
        a
          border-bottom-color: $primary

      .completed &
        background: linear-gradient( to top, lighten($success, 40%),transparent 20%)
        a
          border-bottom-color: $success


  @media (max-width: $desktop)
    ::v-deep .dropdown.is-mobile-modal
      > .dropdown-menu
        min-width: 500px !important
        overflow-y: unset

  @media (max-width: $mobile)
    .column-headers
      grid-template-columns: 2fr 1fr 1fr

  @keyframes pulse
    0%
      opacity: 0.33
    25%
      opacity: 1
    100%
      opacity: 0.33
</style>
