<template>
  <div class="user-list-page">
    <hero-banner>
      <h1 class="title is-2">Users</h1>
      <template v-if="$can('create', 'User')" #right>
        <b-button
          rounded
          inverted
          size="is-medium"
          type="is-primary"
          icon-left="plus"
          @click="$_toggleModal"
        >
          New User
        </b-button>
      </template>
    </hero-banner>

    <div class="section">
      <div class="container">
        <div class="card">
          <backend-searchable-table
            :search-fields="searchFields"
            :filters="filters"
            :loading="isLoading"
            :custom-sort-handler="handleSort"
          >
            <b-table-column field="fullName" label="Name" sortable>
              <template #default="{ row: { id, fullName } }">
                <router-link :to="{ name: 'userConfiguration', params: { id } }">
                  {{ fullName }}
                </router-link>
              </template>
            </b-table-column>

            <b-table-column field="email" label="Email" sortable>
              <template #default="{ row: { email } }">
                {{ email }}
              </template>
            </b-table-column>

            <b-table-column field="status" label="Status" centered width="1">
              <template #default="{ row: { status } }">
                <div class="is-flex justify-center">
                  <tippy
                    :content="status | capitalize"
                    :delay="[500, 0]"
                    placement="left"
                    distance="14"
                  >
                    <template #trigger>
                      <b-icon
                        v-if="status === 'active'"
                        title="active"
                        icon="check"
                        type="is-success"
                        size="is-small"
                      />
                      <b-icon
                        v-if="status === 'pending'"
                        title="pending"
                        icon="circle"
                        type="is-warning"
                        size="is-small"
                        class="is-size-7"
                      />
                      <b-icon
                        v-if="status === 'deactivated'"
                        title="deactivated"
                        icon="times"
                        type="is-danger"
                        size="is-small"
                      />
                    </template>
                  </tippy>
                </div>
              </template>
            </b-table-column>

            <b-table-column field="role" label="Role" centered width="1">
              <template #default="{ row: { role: { name } } }">
                <user-role-tag is-full-width :role-name="name" />
              </template>
            </b-table-column>

            <b-table-column field="actions" label="Actions" centered width="1">
              <template #default="{ row: { id, fullName, isConfirmed } }">
                <b-dropdown aria-role="list" position="is-bottom-left" :mobile-modal="false">
                  <b-button slot="trigger" class="is-transparent" type="is-white">
                    <b-icon icon="ellipsis-v" pack="far" size="is-small" />
                  </b-button>
                  <b-dropdown-item
                    :disabled="isConfirmed"
                    @click="resendEmailConfirmation({ id, fullName })"
                  >
                    <b-icon icon="envelope" class="mar-r-sm" size="is-small" />Re-send Confirmation Email
                  </b-dropdown-item>
                </b-dropdown>
              </template>
            </b-table-column>
          </backend-searchable-table>
        </div>
      </div>
    </div>

    <b-modal
      class="auto-width"
      has-modal-card
      :can-cancel="false"
      trap-focus
      :active.sync="cmc_isModalOpen"
      @keydown.native.esc="$_confirmCloseModal"
    >
      <div class="modal-card" style="max-width: 700px">
        <header class="modal-card-head">
          <p class="modal-card-title">Add New User</p>
          <button class="button is-transparent" @click="$_confirmCloseModal">
            <b-icon icon="times" size="is-small" />
          </button>
        </header>
        <div class="modal-card-body pad-b-none">
          <user-form mode="create" @user-added="performSearch" />
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
  import { mapActions, mapGetters, mapState } from 'vuex';
  import logger from '@/services/logger';
  import userForm from './user-form.vue';
  import confirmModalCloseMixin from '@/mixins/confirm-modal-close';
  import merchantMixin from '@/mixins/merchant';
  import User from '@/store/classes/User';
  import Merchant from '@/store/classes/Merchant';
  import Role from '@/store/classes/Role';

  export default {
    name: 'UserList',

    components: { userForm },

    mixins: [confirmModalCloseMixin, merchantMixin],

    metaInfo() {
      return {
        title: 'Users'
      };
    },

    computed: {
      ...mapState('session', ['currentUserId']),
      ...mapGetters('backendSearch', ['loading']),

      isLoading() {
        return Merchant.$state().fetchingMerchant || this.loading;
      },

      roles() {
        return Role.query().orderBy(role => role.name?.toLowerCase()).get();
      },

      roleOptions() {
        const filteredRoleOptions = this.$_selectedMerchantId
          ? this.roles.filter(roleObj => !roleObj.name.includes('Cardfree'))
          : this.roles
            .filter(roleObj => !roleObj.name.includes('Operations')); // temporarily prevent the CardFree Operations role from being an option to select
        return filteredRoleOptions.map(roleObj => ({ name: roleObj.name, value: roleObj.id }));
      },

      filters() {
        return [
          {
            placeholder: 'Status',
            key: 'statuses',
            multiple: true,
            options: [
              { name: 'Active', value: 'active' },
              { name: 'Pending', value: 'pending' },
              { name: 'Deactivated', value: 'deactivated' }
            ]
          },
          {
            placeholder: 'Role',
            key: 'role_id',
            options: this.roleOptions
          }
        ];
      },

      searchFields() {
        return [
          { value: 'name', label: 'Name' },
          { value: 'id', label: 'ID' },
          { value: 'email', label: 'Email' }
        ];
      }
    },

    created() {
      this.onCreated();
    },

    methods: {
      ...mapActions('backendSearch', ['performSearch', 'initializeBackendSearch']),

      async onCreated() {
        if (this.$can('read', 'Role')) {
          await this.fetchRoles();
        }
        await this.initializeSearch();
      },

      async initializeSearch() {
        await this.initializeBackendSearch({
          searchFunction: User.searchUsers.bind(User),
          defaultParams: {
            sortField: 'created_at',
            sortDirection: 'desc',
            searchField: 'name'
          },
          parseResponse: response => ({
            resources: response.users,
            meta: response.meta
          })
        });

        await this.performSearch();
      },

      handleSort(field, sortDirection) {
        const sortField = field === 'fullName' ? 'full_name' : field;
        return { sortField, sortDirection };
      },

      async resendEmailConfirmation({ id, fullName }) {
        let toastData;
        try {
          await User.resendEmailConfirmation(id);

          toastData = {
            message: `<i class="fa fa-check mar-r-sm"></i> Successfully resent email confirmation to ${fullName}`,
            type: 'is-success'
          };
        }
        catch (error) {
          logger.error(error);

          const message = error.status === 422
            ? `<i class="fa fa-exclamation mar-r-sm"></i> ${fullName} is already confirmed`
            : `<i class="fa fa-exclamation mar-r-sm"></i> Unable to resend email confirmation to ${fullName}`;
          toastData = {
            message,
            type: 'is-danger'
          };
        }
        finally {
          this.$buefy.toast.open({ queue: false, ...toastData });
        }
      },

      async fetchRoles() {
        try {
          await Role.fetchRoles();
        }
        catch (error) {
          this.$_onRequestError({ toastOptions: { message: 'Unable to fetch roles' }, error });
        }
      }
    }
  };
</script>
