<template>
  <validated-form
    ref="form"
    form-id="contentContactUsForm"
    @valid-submit="handleSubmit"
  >
    <panel
      title="Pay @ Table - Ticket Instructions"
      subtitle="Help your guests find the ticket number on their receipt"
      collapsible
      :loading="isFetching"
    >
      <template #buttons>
        <b-button
          rounded
          class="is-bold"
          size="is-medium"
          type="is-primary is-light"
          :loading="isResettingDefault"
          :disabled="!selectedMerchantTicketInstruction || hasDefaultValues"
          @click="resetToDefault"
        >
          Reset to Defaults
        </b-button>
        <b-button
          rounded
          class="is-bold"
          size="is-medium"
          native-type="submit"
          type="is-primary"
          :loading="isLoading"
        >
          Save
        </b-button>
      </template>

      <div>
        <validated-text-input
          v-model="form.title"
          label="Title"
          name="name"
          type="text"
          rules="required"
        />

        <validated-text-input
          v-model="form.description"
          :has-icon="false"
          label="Description"
          name="description"
          type="textarea"
          maxlength="250"
          rules="required"
          rows="6"
        />
      </div>
    </panel>
  </validated-form>
</template>

<script>
  import merchantMixin from '@/mixins/merchant';

  // classes
  import MerchantTicketNumberInstruction from '@/store/classes/MerchantTicketNumberInstruction';

  export default {
    name: 'ContentTicketInstructionForm',

    mixins: [merchantMixin],

    data() {
      return {
        isResettingDefault: false,
        isLoading: false,
        form: {
          title: '',
          description: ''
        }
      };
    },

    computed: {
      isFetching() {
        return MerchantTicketNumberInstruction.$state().fetching;
      },

      selectedMerchantTicketInstruction() {
        return MerchantTicketNumberInstruction.find(this.$_selectedMerchantId);
      },

      defaultMerchantTicketInstruction() {
        return MerchantTicketNumberInstruction.find(0);
      },

      hasDefaultValues() {
        const value = this.selectedMerchantTicketInstruction
          && this.selectedMerchantTicketInstruction.title === this.defaultMerchantTicketInstruction.title
          && this.selectedMerchantTicketInstruction.description === this.defaultMerchantTicketInstruction.description;

        return Boolean(value);
      }
    },

    created() {
      this.onCreated();
    },

    methods: {
      async onCreated() {
        await this.fetchMerchantTicketNumberInstruction();

        if (this.selectedMerchantTicketInstruction) {
          this.form = JSON.parse(JSON.stringify(this.selectedMerchantTicketInstruction));
        }
        else {
          this.form = JSON.parse(JSON.stringify(this.defaultMerchantTicketInstruction));
          this.form.merchantId = this.$_selectedMerchantId;
        }
      },

      async fetchMerchantTicketNumberInstruction() {
        try {
          await MerchantTicketNumberInstruction.fetchMerchantTicketNumberInstruction(this.$_selectedMerchantId);
        }

        catch (error) {
          this.$_onRequestError({
            error,
            toastOptions: {
              message: 'There was an error fetching your Ticket Instructions'
            }
          });
        }
      },

      async handleSubmit() {
        this.isLoading = true;

        if (this.selectedMerchantTicketInstruction) {
          await this.updateMerchantTicketNumberInstruction();
        }
        else {
          await this.postMerchantTicketNumberInstruction();
        }

        this.isLoading = false;
      },

      async updateMerchantTicketNumberInstruction() {
        try {
          await MerchantTicketNumberInstruction.updateMerchantTicketNumberInstruction(this.form);

          this.form = JSON.parse(JSON.stringify(this.selectedMerchantTicketInstruction));

          this.$_onRequestSuccess({
            toastOptions: {
              message: 'Ticket Instructions have been successfully updated!'
            }
          });
        }

        catch (error) {
          this.$_onRequestError({
            error,
            toastOptions: {
              message: 'There was an error updating your Ticket Instructions'
            }
          });
        }
      },

      async postMerchantTicketNumberInstruction() {
        try {
          await MerchantTicketNumberInstruction.postMerchantTicketNumberInstruction(this.form);

          this.form = JSON.parse(JSON.stringify(this.selectedMerchantTicketInstruction));

          this.$_onRequestSuccess({
            toastOptions: {
              message: 'Ticket Instructions have been successfully updated!'
            }
          });
        }

        catch (error) {
          this.$_onRequestError({
            error,
            toastOptions: {
              message: 'There was an error updating your Ticket Instructions'
            }
          });
        }
      },

      async resetToDefault() {
        this.isResettingDefault = true;
        try {
          this.form.merchantId = this.$_selectedMerchantId;
          this.form.title = this.defaultMerchantTicketInstruction.title;
          this.form.description = this.defaultMerchantTicketInstruction.description;

          await MerchantTicketNumberInstruction.updateMerchantTicketNumberInstruction(this.form);

          this.form = JSON.parse(JSON.stringify(this.selectedMerchantTicketInstruction));

          this.$_onRequestSuccess({
            toastOptions: {
              message: 'Ticket Instructions have been successfully reset!'
            }
          });
        }

        catch (error) {
          this.$_onRequestError({
            error,
            toastOptions: {
              message: 'There was an error resetting your Ticket Instructions'
            }
          });
        }

        finally {
          this.isResettingDefault = false;
        }
      }
    }
  };
</script>
