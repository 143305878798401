<template>
  <div class="is-relative is-full-height">
    <b-loading v-if="isLoading" active :is-full-page="false" />

    <tabs-page
      v-if="registeredGuest"
      :tabs="tabs"
      :keep-alive="false"
      :title="registeredGuest.fullName || 'No Name Provided'"
    >
      <template #above-title-action>
        <router-link
          class="back-link is-inline-block has-hover-text-underline"
          :to="{
            name: 'registeredGuestList',
            query: previousSearch.param && previousSearch.query
              ? { param: previousSearch.param, query: previousSearch.query }
              : null
          }"
        >
          <i class="far fa-angle-left mar-l-xs mar-r-sm" />Back to
          {{ previousSearch.param && previousSearch.query
            ? 'Search Results'
            : 'Account Management' }}
        </router-link>
      </template>
    </tabs-page>
  </div>
</template>

<script>
  import RegisteredGuest from '@/store/classes/RegisteredGuest';
  import MerchantLoyaltyMembership from '@/store/classes/MerchantLoyaltyMembership';
  // tabs
  import registredGuestInfo from './tabs/registered-guest-info.vue';
  import registeredGuestLoyalty from './tabs/registered-guest-loyalty.vue';
  import registeredGuestOrderHistory from './tabs/registered-guest-order-history.vue';
  import registeredGuestPaymentMethods from './tabs/registered-guest-payment-methods.vue';
  import merchantMixin from '@/mixins/merchant';

  export default {
    name: 'RegisteredGuestTabs',

    mixins: [merchantMixin],

    data() {
      return {
        previousSearch: {
          query: null,
          param: null
        }
      };
    },

    computed: {
      tabs() {
        return [
          {
            display: 'Guest Details',
            name: 'guest-details',
            component: registredGuestInfo,
            props: {
              registeredGuest: this.registeredGuest,
              previousSearch: this.previousSearch
            }
          },
          {
            display: 'Loyalty & Rewards',
            name: 'loyalty-and-rewards',
            component: registeredGuestLoyalty,
            props: { registeredGuest: this.registeredGuest },
            isHidden: !this.loyaltyMembership?.loyaltyEnabled
          },
          {
            display: 'History',
            name: 'guest-history',
            component: registeredGuestOrderHistory,
            props: { registeredGuest: this.registeredGuest }
          },
          {
            display: 'Payment Methods',
            name: 'payment-methods',
            component: registeredGuestPaymentMethods,
            props: { registeredGuestId: this.registeredGuest.id },
            isHidden: !this.$can('read', 'GiftCard') || (!this.$_selectedMerchant?.merchantGiftCardConfigurations.length
              && this.$_selectedMerchant?.hasDisableSavedPaymentManagement)
          }
        ];
      },

      isLoading() {
        return (
          RegisteredGuest.$state().fetching
          || MerchantLoyaltyMembership.$state().fetching
        );
      },

      registeredGuest() {
        return RegisteredGuest.find(this.$route.params.userId);
      },

      loyaltyMembership() {
        return MerchantLoyaltyMembership.find(this.$_selectedMerchantId);
      }
    },

    beforeRouteEnter(to, from, next) {
      next((vm) => {
        const { query, param } = from.query;
        vm.previousSearch.query = query;
        vm.previousSearch.param = param;
      });
    },

    async created() {
      await this.onCreated();
    },

    methods: {
      async onCreated() {
        await Promise.all([
          this.fetchRegisteredGuest(),
          this.fetchLoyaltyMembershipOptions()
        ]);
      },
      async fetchRegisteredGuest() {
        try {
          await RegisteredGuest.fetchRegisteredGuest(this.$route.params.userId);
        }
        catch (error) {
          this.$_onRequestError({
            toastOptions: {
              message: 'Failed to fetch guest'
            },
            error
          });
        }
      },
      async fetchLoyaltyMembershipOptions() {
        try {
          await MerchantLoyaltyMembership.fetchLoyaltyMembershipOptions(this.$_selectedMerchantId);
        }
        catch (error) {
          this.$_onRequestError({
            toastOptions: {
              message: 'Failed to fetch guest loyalty options'
            },
            error
          });
        }
      }
    }
  };
</script>

<style lang="sass" scoped>
  .back-link
    position: absolute
    top: -100%
    left: 0
    margin-top: 0.5rem
</style>
