<template>
  <validation-observer
    :ref="formId"
    v-slot="slotData"
    slim
    :vid="formId"
  >
    <form ref="formElement" novalidate @submit.prevent="handleSubmit({$event})">
      <fieldset v-if="disabled" disabled>
        <slot v-bind="slotData" />
      </fieldset>
      <slot v-else v-bind="slotData" />
    </form>
  </validation-observer>
</template>



<script>
  import { mapActions, mapState } from 'vuex';



  export default {
    name: 'ValidatedForm',

    props: {
      autoFocus: {
        type: Boolean,
        default: false
      },

      disabled: {
        type: Boolean,
        default: false
      },

      formId: {
        type: String,
        required: true
      },

      showEdited: {
        type: Boolean,
        default: false
      },

      trackChanges: {
        type: Boolean,
        default: true
      },

      manualValidation: {
        type: Function,
        default: () => true
      }
    },

    computed: {
      ...mapState('formStore', ['formChanged'])
    },

    created() {
      this.$on('backend-error', this.handleErrors);
    },

    mounted() {
      this.onMounted();
    },

    beforeDestroy() {
      this.onBeforeDestroy();
    },

    methods: {
      ...mapActions('formStore', [
        'setFormChanged',
        'clearChangedFormIds'
      ]),

      onMounted() {
        this.$nextTick(() => {
          this.addInputListener();
          this.focusFirst();
        });
      },

      onBeforeDestroy() {
        this.$refs.formElement.removeEventListener('input', this.handleInput, true);
        this.clearChangedFormIds();
      },

      addInputListener() {
        if (this.$refs.formElement) {
          this.$refs.formElement.addEventListener('input', this.handleInput, true);
        }
      },

      handleInput() {
        if (this.trackChanges) {
          this.setFormChanged({ hasChanged: true, formId: this.formId });
        }
      },

      focusFirst() {
        if (this.autoFocus && !this.$screen.touch) {
          let first = this.$el.querySelector(`
            input:not([type="hidden"]):not([tabindex="-1"]),
            textarea,
            button,
            select,
            .dropdown-item
          `);
          if (first) {
            if (first.type === 'radio') {
              const checkedRadio = this.$el.querySelector('input:checked');
              if (checkedRadio) {
                first = checkedRadio;
              }
            }
            first.focus();
          }
        }
      },

      async handleErrors(errors) {
        await this.$refs[this.formId].setErrors(errors);
        this.$_focusFirstInvalid();
      },

      async validate() {
        const isValid = await this.$refs[this.formId].validate();
        if (!isValid || !this.manualValidation()) {
          this.$emit('invalid-submit');
          throw Error('Form is invalid');
        }
      },

      handleSuccessfulValidation({ $event, shouldSkipValidSubmitEmission } = {}) {
        if (!shouldSkipValidSubmitEmission) {
          this.$emit('valid-submit', $event);
        }
        this.setFormChanged({ hasChanged: false });
        this.$refs[this.formId].reset();
      },

      async handleSubmit({ $event, shouldSkipValidSubmitEmission } = {}) {
        // Passing the $event object to this function allows us to know which event (i.e. button click)
        // triggered the form submission. This is useful for cases where a validated-form may have multiple buttons.
        // You can see an example of this in the `survey-builder-modal.vue` component.
        try {
          const isFormValid = await this.$refs[this.formId].validate();
          const isManualValidationValid = this.manualValidation();
          if (isFormValid && isManualValidationValid) {
            this.handleSuccessfulValidation({ $event, shouldSkipValidSubmitEmission });
          }
          else {
            setTimeout(() => {
              this.$emit('invalid-submit');
              this.$_focusFirstInvalid();
            }, 1);
          }
          return isFormValid && isManualValidationValid;
        }
        catch (e) {
          console.log('could not validate', e);
          throw e;
        }
      }
    }
  };
</script>
