/* eslint-disable import/no-cycle */
import { Model } from '@vuex-orm/core';
import http from '@/services/http';
import { parseJson } from '@/helpers/misc';



export default class UserValidation extends Model {
  static entity = 'userValidations'

  static fields() {
    return {
      id: this.attr(''),
      name: this.attr(''),
      validationsJsonBlob: this.attr('', value => parseJson(value))
    };
  }

  // STATE //////////////////////
  static state() {
    return {
      fetching: false
    };
  }

  static $state() { // TEST ?
    return this.store().state.entities.userValidations;
  }

  static userValidationLabels() {
    return {
      1: 'Email & Password (Default)',
      2: 'Email, Password & Phone',
      3: 'Email, Password, Phone & Birthday',
      4: 'Email, Password, Phone, Birthday, & ZIP',
      5: 'Email, Password, Phone, Birthday, ZIP, & SMS Notification Opt-In',
      6: 'Email, Password, Phone, Birthday w/ Year, ZIP, & SMS Notification Opt-In'
    };
  }

  get requiredBirthdayYear() {
    return !!this.validationsJsonBlob.find(v => v.property === 'birthdayInfo.year')?.isRequired;
  }


  // Actions
  static async fetchUserValidations(options = {}) {
    try {
      this.commit((state) => {
        state.fetching = true;
      });

      const alreadyFetched = this.query().exists();

      if (!alreadyFetched || options.forceFetch) {
        const response = await http.get('user_validations');
        this.create({ data: response.data.userValidations });
      }
    }
    catch (error) {
      throw error;
    }
    finally {
      this.commit((state) => {
        state.fetching = false;
      });
    }
  }
}
