<template>
  <div>
    <draggable
      v-model="options"
      tag="ul"
      class="option-list pad-l-lg"
      :group="`question-${question.id}-options`"
      v-bind="draggableAttributes"
      :force-fallback="true"
    >
      <li v-for="(option, index) in options" :key="option.id" class="question-option is-flex-align-center mar-b-sm">
        <b-icon
          icon="grip-lines"
          size="is-small"
          pack="far"
          class="drag-handle mar-x-sm"
        />
        <div class="is-flex-start-aligned option-input">
          <validated-text-input
            v-model="option.value"
            :name="`option-${option.id}`"
            :disabled="disabled || (!option.isUnsaved && !isDraft)"
            :placeholder="`Answer Option ${index + 1}`"
            type="text"
            rules="required"
            label="Option"
            class="mar-r option-input"
            hide-label
          />

          <template v-if="!disabled">
            <b-button
              v-if="(option.isUnsaved || mode === 'create')"
              class="is-transparent"
              :disabled="options.length < 2"
              icon-left="minus-circle"
              type="is-danger"
              inverted
              @click="removeOption(option.id)"
            />
            <b-button
              class="is-transparent"
              @click="option.isActive = !option.isActive"
            >
              <b-icon
                size="is-small"
                :icon="!option.isActive ? 'eye-slash' : 'eye'"
                :class="{'has-text-danger': !option.isActive}"
              />
            </b-button>
          </template>
        </div>
      </li>
    </draggable>
    <b-button
      v-if="!disabled"
      type="is-primary"
      inverted
      icon-left="plus"
      class="mar-l-lg"
      @click="addNewOption"
    >
      Option
    </b-button>
  </div>
</template>

<script>
  import draggable from 'vuedraggable';


  export default {
    name: 'SurveyQuestionOptions',

    components: { draggable },

    props: {
      question: {
        type: Object,
        required: true
      },

      isDraft: {
        type: Boolean,
        required: true
      },

      disabled: {
        type: Boolean,
        required: false
      },

      handleOptionsChange: {
        type: Function,
        required: true
      },

      mode: {
        type: String,
        default: 'create',
        validator(value) {
          return ['create', 'read', 'update'].includes(value);
        }
      }
    },

    data() {
      return {
        options: [],
        draggableAttributes: {
          animation: '200',
          ghostClass: 'ghost',
          handle: '.drag-handle'
        }
      };
    },

    computed: {
      watchedOptions() {
        return JSON.parse(JSON.stringify(this.options));
      },

      defaultOption() {
        return {
          id: `$${Date.now()}`,
          value: null,
          isActive: true,
          sortOrder: this.options.length + 1,
          isUnsaved: true
        };
      }
    },

    watch: {
      watchedOptions: 'onOptionsChange'
    },

    created() {
      this.initializeOptions();
    },

    methods: {
      initializeOptions() {
        const { options } = this.question;
        this.options = options.length ? options : [this.defaultOption];
      },

      onOptionsChange(updatedOptions) {
        this.handleOptionsChange(updatedOptions.map((option, index) => {
          option.sortOrder = index + 1;
          return option;
        }));
      },

      addNewOption() {
        this.options.push(this.defaultOption);
        this.focusOnNewestOptionInput();
      },

      focusOnNewestOptionInput() { // TEST
        this.$nextTick(() => {
          const allOptions = this.$el.querySelectorAll('.option-input input');
          const lastOption = allOptions[allOptions.length - 1];
          lastOption.focus();
        });
      },

      removeOption(id) {
        this.options = this.options.filter(option => option.id !== id);
      }
    }
  };
</script>

<style lang="sass" scoped>
  .option-list
    max-width: 500px

  .option-input
    flex: 1
</style>
