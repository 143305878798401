<template>
  <b-field label-for="pageJump" label-position="on-border" label="Page" class="mar-none">
    <b-select id="pageJump" class="page-select" :value="page" @input="$emit('input', $event)">
      <option
        v-for="pageNumber in Math.ceil(totalCount / perPage)"
        :key="pageNumber"
        :value="pageNumber"
      >
        {{ pageNumber }}
      </option>
    </b-select>
  </b-field>
</template>

<script>
  export default {
    name: 'PageNumberSelect',
    props: {
      page: {
        type: Number,
        required: true
      },
      perPage: {
        type: Number,
        required: true
      },
      totalCount: {
        type: Number,
        default: 0
      }
    }
  };
</script>

<style lang="sass" scoped>
  .page-select ::v-deep
    select
      min-width: 110px
</style>
