export const setUpdatedSortOrders = ({ fromIndex, toIndex, array }) => {
  // make a deep clone of the array
  const _array = JSON.parse(JSON.stringify(array));

  // make sure the array of resources are sorted by their sortOrder value to start
  _array.sort((a, b) => ((a.sortOrder < b.sortOrder) ? -1 : 1));

  const fromSortOrder = _array[fromIndex].sortOrder;
  const toSortOrder = _array[toIndex].sortOrder;

  const _fromIndex = _array.findIndex(resource => resource.sortOrder === fromSortOrder);
  const _toIndex = _array.findIndex(resource => resource.sortOrder === toSortOrder);

  const [movedItem] = _array.splice(_fromIndex, 1);
  _array.splice(_toIndex, 0, movedItem);

  return _array.map((item, i) => {
    item.sortOrder = i + 1;
    return item;
  });
};

export const updateSortOrdersPerCategory = ({ itemsArr, categoryId }) => {
  const updatedSortOrders = itemsArr.map((item) => {
    const newSortOrders = item.sortOrders.map((sortOrderObj) => {
      if (sortOrderObj.menuCategoryId === categoryId) {
        sortOrderObj.sortOrder = item.sortOrder;
      }
      return sortOrderObj;
    });

    item.sortOrders = newSortOrders;
    return item;
  });

  return updatedSortOrders;
};

export const setUpdatedItemSortOrders = ({ fromIndex, toIndex, array, categoryId }) => { // eslint-disable-line object-curly-newline
  const updatedItems = setUpdatedSortOrders({ fromIndex, toIndex, array });
  return updateSortOrdersPerCategory({
    itemsArr: updatedItems,
    categoryId
  });
};



export default {
  setUpdatedSortOrders,
  setUpdatedItemSortOrders,
  updateSortOrdersPerCategory
};
