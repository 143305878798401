<template>
  <div class="detail-page is-flex-column">
    <div class="flex-grow is-relative">
      <div class="container">
        <div class="card">
          <div class="search-section pad-x pad-t">
            <searchable-table
              ref="searchableTable"
              :table-data="promoCodeGroups"
              :search="{ keys: searchCriteriaOptions.map(option => option.value) }"
              :search-criteria-options="searchCriteriaOptions"
              :filters="tableFilters"
              :auto-focus-search="true"
              :clear-inputs-additional-action="() => dateRange = []"
              class="promo-code-list-search"
            >
              <template #optional-actions>
                <div class="is-flex gap-x">
                  <b-field label="Date Range" label-position="on-border">
                    <b-datepicker
                      v-model="dateRange"
                      class="date-picker-input has-extra-shadow"
                      icon="calendar-alt"
                      range
                      :nearby-selectable-month-days="true"
                      :mobile-native="false"
                      placeholder="Select Date Range"
                      :min-date="new Date(2020, 0, 1)"
                    >
                      <button
                        class="button is-small"
                        @click="dateRange = []"
                      >
                        Clear
                      </button>
                    </b-datepicker>
                  </b-field>
                  <b-button
                    v-if="hasMarketingAccess || isCardfreeAdmin"
                    type="is-primary"
                    icon-left="plus"
                    class="cta-button"
                    @click="openPromoCodeGroupModal()"
                  >
                    Promo Code Group
                  </b-button>
                </div>
              </template>

              <template #default="{ filteredData }">
                <b-table
                  :data="isFetching ? [] : applyDateFilters(filteredData)"
                  :paginated="promoCodeGroups.length > pageLimit"
                  :total="promoCodeGroups.length"
                  :current-page.sync="page"
                  class="is-middle-aligned"
                  :per-page="pageLimit"
                  :mobile-cards="false"
                  scrollable
                  :default-sort="['timeframe', 'desc']"
                  custom-detail-row
                  :show-detail-icon="false"
                  detail-key="publicId"
                  :detailed="true"
                  :opened-detailed="expandedPromoCodeGroups"
                >
                  <template #empty>
                    <empty-table-loader
                      message="No Promo Codes Found..."
                      :loading="isFetching"
                    />
                  </template>

                  <!-- NAME + CARET -->
                  <b-table-column
                    v-slot="{ row }"
                    field="name"
                    label="Name"
                    sortable
                  >
                    <div class="is-flex align-center justify-between">
                      <span
                        class="link"
                        @click="openPromoCodeGroupModal(row)"
                      >
                        {{ row.name }}
                      </span>
                      <b-button
                        class="expand-group-button"
                        :loading="isLoading[row.publicId]"
                        @click="togglePromoCodeGroup(row.publicId)"
                      >
                        <b-icon
                          :icon="'chevron-right'"
                          :class="['expand-icon', { 'is-open': isOpen[row.publicId] }]"
                        />
                      </b-button>
                    </div>
                  </b-table-column>

                  <!-- GROUP ID -->
                  <b-table-column
                    v-slot="{ row: { publicId } }"
                    field="publicId"
                    label="ID"
                    sortable
                    centered
                    cell-class="table-cell-text-overflow is-monospaced"
                  >
                    <span>{{ publicId }}</span>
                  </b-table-column>

                  <!-- OFFER NAME -->
                  <b-table-column
                    v-slot="{ row: { offerPublicId } }"
                    field="offer"
                    label="Offer"
                    centered
                    sortable
                    :custom-sort="sortByOfferName"
                    cell-class="table-cell-text-overflow"
                  >
                    <span class="link" @click="viewOfferDetails(offerPublicId)">
                      {{ findOffer(offerPublicId).name }}
                    </span>
                  </b-table-column>

                  <!-- TIMEFRAME -->
                  <b-table-column
                    v-slot="{ row: { formattedStartDate, formattedEndDate } }"
                    field="timeframe"
                    label="Timeframe"
                    sortable
                    :custom-sort="sortByClaimableStartDate"
                    centered
                  >
                    <span class="no-wrap-text">
                      {{ `${formattedStartDate} to ${formattedEndDate}` }}
                    </span>
                  </b-table-column>

                  <!-- USAGE COUNT -->
                  <b-table-column
                    field="usageCount"
                    label="Usage Count"
                    sortable
                    header-class="no-wrap-text"
                    centered
                  >
                    {{ "" }}
                  </b-table-column>

                  <!-- STATUS -->
                  <b-table-column
                    v-slot="{ row: { status } }"
                    field="status"
                    label="Status"
                    sortable
                    centered
                  >
                    <b-tag
                      :type="tagTypes[status]"
                      class="is-outlined is-light"
                      size="is-medium"
                    >
                      <span class="is-size-7 font-weight-bold">
                        {{ $changeCase.capitalCase(status) }}
                      </span>
                    </b-tag>

                  </b-table-column>

                  <!-- ACTIONS -->
                  <b-table-column
                    v-slot="{ row }"
                    centered
                    field="actions"
                    label="Actions"
                    width="1"
                  >
                    <dropdown-menu position="bottom-end" :mobile-modal="false">
                      <b-button slot="trigger" class="is-transparent" type="is-white">
                        <b-icon icon="ellipsis-v" pack="far" size="is-small" />
                      </b-button>
                      <b-dropdown-item @click="openPromoCodeGroupModal(row)">
                        <b-icon icon="eye" class="mar-r-sm" size="is-small" />
                        View promo code group
                      </b-dropdown-item>
                    </dropdown-menu>
                  </b-table-column>

                  <!-- PROMO CODES -->
                  <template #detail="{ row: promoCodeGroup }">
                    <tr class="nested-table-section-title detail-row">
                      <td colspan="8" class="has-text-weight-bold is-size-7">
                        PROMO CODES
                      </td>
                    </tr>
                    <tr
                      v-for="promoCode in filteredPromoCodes(promoCodeGroup)"
                      :key="`${promoCode.publicId}-detail-row`"
                      class="detail-row"
                    >
                      <td><span class="mar-l-md">{{ promoCode.promotionCode }}</span></td>
                      <td class="table-cell-text-overflow is-monospaced">{{ promoCode.publicId }}</td>
                      <td />
                      <td />
                      <td class="has-text-centered">{{ promoCode.usageCount }}</td>
                      <td class="has-text-centered">
                        <b-tag
                          :type="isRevoked(promoCode) ? 'is-danger' : tagTypes[promoCodeGroup.status]"
                          class="is-outlined is-light"
                          size="is-medium"
                        >
                          <span class="is-size-7 font-weight-bold">
                            {{ isRevoked(promoCode) ? 'Revoked' : $changeCase.capitalCase(promoCodeGroup.status) }}
                          </span>
                        </b-tag>
                      </td>
                      <td class="actions has-text-centered">
                        <dropdown-menu position="bottom-end" :mobile-modal="false">
                          <b-button slot="trigger" class="is-transparent" type="is-white">
                            <b-icon icon="ellipsis-v" pack="far" size="is-small" />
                          </b-button>
                          <b-dropdown-item
                            @click="openPromoCodeModal(promoCode)"
                          >
                            <b-icon icon="eye" class="mar-r-sm" size="is-small" />
                            View promo code
                          </b-dropdown-item>
                          <b-dropdown-item class="is-danger" :disabled="isRevoked(promoCode)" @click="confirmRevoke(promoCode)">
                            <b-icon type="is-danger" icon="trash-alt" class="mar-r-sm" size="is-small" />
                            Revoke promo code
                          </b-dropdown-item>
                        </dropdown-menu>
                      </td>
                    </tr>
                    <!-- PROMO CODE CTA -->
                    <td
                      v-if="hasMarketingAccess || isCardfreeAdmin"
                      colspan="8"
                      class="has-background-white-bis"
                    >
                      <b-button
                        class="is-transparent"
                        type="is-primary"
                        icon-left="plus"
                        inverted
                        :disabled="promoCodeGroup.status === offerDistributionStatuses.EXPIRED"
                        @click="openPromoCodeModal({ offerDistributionPublicId: promoCodeGroup.publicId })"
                      >
                        Promo Code
                      </b-button>
                    </td>
                  </template>
                </b-table>
              </template>
            </searchable-table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import moment from 'moment-timezone';

  import { mapGetters } from 'vuex';
  import { offerDistributionStatuses } from '@/constants/offerDistributions';

  import offerReviewStep from '@/components/pages/offers/modal-steps/offer-review-step.vue';
  import addEditPromoCodeGroupModal from '@/components/pages/promo-codes/modals/add-edit-promo-code-group-modal.vue';
  import addEditPromoCodeModal from '@/components/pages/promo-codes/modals/add-edit-promo-code-modal.vue';

  import OfferDistribution from '@/store/classes/OfferDistribution';
  import OfferDistributionCode from '@/store/classes/OfferDistributionCode';
  import Offer from '@/store/classes/Offer';

  export default {
    name: 'PromoCodeList',

    data() {
      return {
        page: 1,
        pageLimit: 20,
        dateRange: [],
        offerDistributionStatuses,
        tagTypes: {
          active: 'is-primary',
          expired: 'is-danger',
          scheduled: 'is-warning'
        },
        isLoading: {},
        isOpen: {},
        promoCodesByGroup: {},
        offers: {}
      };
    },

    computed: {
      ...mapGetters('session', ['isCardfreeAdmin', 'hasMarketingAccess']),

      promoCodeGroups() {
        return OfferDistribution.all().map(group => ({
          ...group,
          offerName: Offer.find(group.offerPublicId)?.name
        }));
      },

      searchCriteriaOptions() {
        return [
          { value: 'name', label: 'Promo Code Group' },
          { value: 'promotionCodes', label: 'Promo Code' }
        ];
      },

      expandedPromoCodeGroups() {
        return Object.keys(this.isOpen).filter(key => this.isOpen[key]);
      },

      isFetching() {
        return OfferDistribution.$state().fetching || Offer.$state().fetchingAll || Offer.$state().fetching;
      },

      relevantOffers() {
        const offerPublicIds = [...new Set(this.promoCodeGroups.map(group => group.offerPublicId))];
        return Offer.all().filter(offer => offerPublicIds.includes(offer.guid));
      },

      tableFilters() {
        return [
          {
            placeholder: 'Status',
            key: 'status',
            multiple: true,
            default: [offerDistributionStatuses.ACTIVE, offerDistributionStatuses.SCHEDULED],
            options: Object.keys(this.tagTypes).map(key => ({
              name: this.$changeCase.capitalCase(key),
              value: key
            }))
          },
          {
            placeholder: 'Offer',
            key: 'offerName',
            multiple: true,
            options: this.relevantOffers.map(offer => ({
              name: offer.name,
              value: offer.name
            })),
            formatSelectedValues: values => this.formatSelectedOfferNames(values)
          }
        ];
      }
    },

    methods: {
      applyDateFilters(offerDistributions) {
        if (!this.dateRange || this.dateRange.length !== 2) {
          return offerDistributions;
        }

        const startRange = moment(this.dateRange[0]).startOf('day');
        const endRange = moment(this.dateRange[1]).endOf('day');

        return offerDistributions.filter((offerDistribution) => {
          const startDate = moment(offerDistribution.formattedStartDate).startOf('day');
          const endDate = moment(offerDistribution.formattedEndDate).endOf('day');
          return (
            (startDate.isSameOrAfter(startRange) && startDate.isSameOrBefore(endRange))
            || (endDate.isSameOrAfter(startRange) && endDate.isSameOrBefore(endRange))
            || (startDate.isBefore(startRange) && endDate.isAfter(endRange))
          );
        });
      },

      formatSelectedOfferNames(values) {
        if (values?.length > 0) {
          return `Selected (${values.length})`;
        }
        return '';
      },

      filteredPromoCodes(promoCodeGroup) {
        const promoCodes = this.promoCodesByGroup[promoCodeGroup.publicId] || [];
        if (this.$refs.searchableTable
          && this.$refs.searchableTable.selectedSearchCriteria === 'promotionCodes'
          && this.$refs.searchableTable.query) {
          const query = this.$refs.searchableTable.query.toLowerCase();
          return promoCodes.filter(code => code.promotionCode.toLowerCase().includes(query));
        }
        return promoCodes;
      },

      async fetchOfferDistributionCodes(offerDistributionPublicId) {
        this.$set(this.isLoading, offerDistributionPublicId, true);

        try {
          const promoCodes = await OfferDistributionCode.fetchOfferDistributionCodes(offerDistributionPublicId);
          this.$set(this.promoCodesByGroup, offerDistributionPublicId, promoCodes || []);

          const promoCodeGroup = this.promoCodeGroups.find(group => group.publicId === offerDistributionPublicId);
          this.$set(promoCodeGroup, 'promotionCodes', this.promoCodesByGroup[offerDistributionPublicId].map(code => code.promotionCode).join(','));
        }
        catch (error) {
          this.$_onRequestError({
            error,
            toastOptions: {
              message: 'There was an error fetching your offer distribution codes'
            }
          });
          this.$set(this.promoCodesByGroup, offerDistributionPublicId, []);
        }
        finally {
          this.$set(this.isLoading, offerDistributionPublicId, false);
        }
      },

      async refreshOfferDistributions() {
        try {
          await OfferDistribution.fetchOfferDistributions();
        }
        catch (error) {
          this.$_onRequestError({
            error,
            toastOptions: {
              message: 'There was an error fetching your offer distributions'
            }
          });
        }
      },

      async fetchOffer(offerPublicId) {
        try {
          if (!this.offers[offerPublicId]) {
            const offer = await Offer.fetchOffer(offerPublicId);
            this.$set(this.offers, offerPublicId, offer);
          }
        }
        catch (error) {
          this.$_onRequestError({
            error,
            toastOptions: {
              message: 'There was an error fetching the offer details'
            }
          });
        }
      },

      findOffer(offerPublicId) {
        return Offer.find(offerPublicId);
      },

      async viewOfferDetails(offerPublicId) {
        await this.fetchOffer(offerPublicId);
        const offer = await Offer.query()
          .where('guid', offerPublicId)
          .first();
        this.$buefy.modal.open({
          parent: this,
          component: offerReviewStep,
          hasModalCard: true,
          canCancel: ['escape', 'outside'],
          trapFocus: true,
          customClass: 'auto-width',
          props: {
            value: offer,
            offerReadOnly: true,
            offerDetailsAreReadOnly: true,
            isScrollable: true
          }
        });
      },

      async togglePromoCodeGroup(id) {
        if (!this.promoCodesByGroup[id]) {
          await this.fetchOfferDistributionCodes(id);
        }
        this.$set(this.isOpen, id, !this.isOpen[id]);
      },

      openPromoCodeGroupModal(promoCodeGroup = {}) {
        this.$buefy.modal.open({
          parent: this,
          component: addEditPromoCodeGroupModal,
          hasModalCard: true,
          canCancel: ['escape', 'outside'],
          trapFocus: true,
          props: { promoCodeGroup },
          events: {
            'promo-code-group-updated': async () => {
              await this.refreshOfferDistributions();
            }
          }
        });
      },

      openPromoCodeModal(promoCode = {}) {
        this.$buefy.modal.open({
          parent: this,
          component: addEditPromoCodeModal,
          hasModalCard: true,
          canCancel: ['escape', 'outside'],
          trapFocus: true,
          props: { promoCode },
          events: {
            'promo-code-updated': async () => {
              await this.fetchOfferDistributionCodes(promoCode.offerDistributionPublicId);
            }
          }
        });
      },

      confirmRevoke(promoCode) {
        this.$buefy.dialog.confirm({
          title: 'Confirm Promo Code Revocation',
          message: `Are you sure you want to revoke the promo code "${promoCode.promotionCode}"? This action cannot be undone.`,
          confirmText: 'Revoke',
          cancelText: 'Cancel',
          type: 'is-danger',
          onConfirm: () => this.revokePromoCode(promoCode)
        });
      },

      async revokePromoCode(promoCode) {
        try {
          await OfferDistributionCode.invalidateOfferDistributionCode(promoCode);

          await this.fetchOfferDistributionCodes(promoCode.offerDistributionPublicId);

          this.$_onRequestSuccess({
            toastOptions: {
              message: `Successfully revoked promo code <b>${promoCode.promotionCode}</b>!`
            },
            options: {
              closeParent: true,
              emit: { name: 'promo-code-updated', arg: promoCode.publicId }
            }
          });
        }
        catch (error) {
          this.$_onRequestError({
            error,
            toastOptions: {
              message: `Unable to revoke promo code <b>${promoCode.promotionCode}</b>`
            }
          });
        }
      },

      isRevoked(promoCode) {
        return promoCode && promoCode.invalidatedDateTime
          ? moment(promoCode.invalidatedDateTime).isBefore(moment())
          : false;
      },

      sortByClaimableStartDate(a, b, isAsc) {
        const dateA = moment(a.formattedStartDate, 'M/D/YY');
        const dateB = moment(b.formattedStartDate, 'M/D/YY');
        return isAsc ? dateA.diff(dateB) : dateB.diff(dateA);
      },

      sortByOfferName(a, b, isAsc) {
        const nameA = (a.offerName || '').toLowerCase();
        const nameB = (b.offerName || '').toLowerCase();
        return isAsc ? nameA.localeCompare(nameB) : nameB.localeCompare(nameA);
      }
    }
  };
</script>

<style scoped lang="sass">
  .search-section
    padding: 0.75rem 1rem
    border-bottom: 1px solid $grey-lightest

    .promo-code-list-search
      ::v-deep .search-inputs
        flex-grow: 2
        min-width: 300px

      ::v-deep .search-input
        min-width: 300px

  .cta-button
    flex: 0 0 auto

  .filters
    display: flex
    flex-wrap: wrap
    gap: $size-normal

  .table-cell-text-overflow
    max-width: 300px
    min-width: 100%
    overflow: hidden
    white-space: nowrap

    span
      overflow: hidden
      text-overflow: ellipsis

  .expand-group-button
    background: none
    border: none
    outline: none
    color: #0099FF

    &:hover
      color: #000

    &:focus, &:active
      box-shadow: none
      outline: none

    .expand-icon
      transition: transform 0.3s ease

      &.is-open
        transform: rotate(90deg)
</style>
