<template>
  <validated-input
    name="regional-location-dropdown"
    :hide-label="hideLabel"
    :label="label"
    :sub-label="subLabel"
    :label-position="labelPosition"
    :rules="{ required }"
    :custom-messages="{ required: 'You must select at least one location.' }"
  >
    <!--
      use the inline b-dropdown component for disabled/readonly view,
      otherwise use the custom dropdown-menu component for better
      adaptive positioning
    -->
    <component
      :is="disabled ? 'b-dropdown' : 'dropdown-menu'"
      v-model="selectedStoreIds"
      multiple
      :expanded="expanded"
      class="has-extra-shadow"
      aria-role="list"
      :disabled="disabled"
      :inline="disabled"
    >
      <dropdown-button
        slot="trigger"
        placeholder="Select Location(s)"
        :value="displayValue"
        :loading="loading"
      />

      <b-dropdown-item
        aria-role="listitem"
        :class="{'is-active': isSelectedAll}"
        @click="handleSelectAll"
      >
        All Locations
      </b-dropdown-item>

      <div
        v-for="(regionStores, regionAbbr) in storesByRegion"
        :key="regionAbbr"
      >
        <hr class="dropdown-divider">

        <div class="is-flex align-center justify-between pad-x pad-y-xs gap-x-xl">
          <h2 class="title mar-b-none is-5">{{ stateProvinceNamesByAbbr[regionAbbr] }}</h2>
          <b-button
            v-if="regionStores.length > 1 && !regionStores.every(({ storeId }) => disabledIds.includes(storeId))"
            rounded
            type="is-light"
            size="is-small"
            :disabled="disabled"
            @click="toggleRegionStores(regionStores)"
          >
            {{ regionStores.every(({ storeId }) => selectedStoreIds.includes(storeId)) ? "Unselect All" : "Select All" }}
          </b-button>
        </div>

        <b-dropdown-item
          v-for="store in regionStores"
          :key="store.storeId"
          class="pad-l-lg"
          aria-role="listitem"
          :value="store.storeId"
          :class="{'is-active': isSelectedAll && availableStoreIds.includes(store.storeId)}"
          :disabled="disabledIds.includes(store.storeId)"
        >
          <p>{{ store.description }}</p>
          <slot name="store-details" :store="store" />
          <p
            v-if="disabledIds.includes(store.storeId) && disabledReason"
            class="is-size-7 has-text-grey"
          >
            {{ disabledReason }}
          </p>
        </b-dropdown-item>
      </div>
    </component>
  </validated-input>
</template>

<script>
  import caProvinces from '@/helpers/ca-provinces';
  import usStates from '@/helpers/us-states';

  export default {
    name: 'RegionalLocationDropdown',

    props: {
      label: {
        type: String,
        default: 'Locations'
      },
      disabledIds: {
        type: Array,
        default: () => []
      },
      disabledReason: {
        type: String,
        default: ''
      },
      expanded: {
        type: Boolean,
        default: false
      },
      subLabel: {
        type: String,
        default: 'Select locations'
      },
      stores: {
        type: Array,
        required: true,
        default: () => []
      },
      value: {
        type: Array,
        default: () => [],
        validator(array) {
          const isAllNumbers = array.every(val => typeof val === 'number');
          if (!isAllNumbers) console.error('RegionalLocationDropdown: the `value` prop must be an array of numbers');
          return isAllNumbers;
        }
      },
      disabled: {
        type: Boolean,
        default: false
      },
      selectAllMode: {
        type: String,
        default: 'excludeAll',
        validator: value => ['excludeAll', 'includeAll'].includes(value)
      },
      hideLabel: {
        type: Boolean,
        default: false
      },
      labelPosition: {
        type: String,
        default: null
      },
      loading: {
        type: Boolean,
        default: false
      },
      required: {
        type: Boolean,
        default: false
      }
    },

    computed: {
      selectedStoreIds: {
        get() {
          return this.value;
        },

        set(val) {
          this.$emit('input', val);
        }
      },

      stateProvinceNamesByAbbr() {
        return [...usStates, ...caProvinces].reduce((acc, region) => {
          acc[region.abbr] = region.name;
          return acc;
        }, {});
      },


      storesByRegion() {
        return this.stores.reduce((acc, store) => {
          if (acc[store.region]) acc[store.region].push(store);
          else acc[store.region] = [store];
          return acc;
        }, {});
      },

      displayValue() {
        if (this.selectAllMode === 'excludeAll' && this.selectedStoreIds.length === 0) {
          return 'All Locations';
        }
        else if (this.selectAllMode === 'includeAll' && this.selectedStoreIds.length === this.stores.length) {
          return 'All Locations';
        }
        else if (this.selectedStoreIds.length === 1) {
          const selectedStore = this.stores.find(store => store.storeId === this.selectedStoreIds[0]);
          return selectedStore.description;
        }
        else if (this.selectedStoreIds.length > 1) {
          return `Selected (${this.selectedStoreIds.length})`;
        }
        else {
          return '';
        }
      },

      isSelectedAll() {
        switch (this.selectAllMode) {
          case 'excludeAll':
            return this.selectedStoreIds.length === 0;
          case 'includeAll':
            return this.selectedStoreIds.length === this.availableStoreIds.length;
          default:
            console.warn('Unexpected selectAllMode value:', this.selectAllMode);
            return false;
        }
      },

      availableStoreIds() {
        return this.stores.reduce((array, { storeId }) => {
          if (!this.disabledIds.includes(storeId)) array.push(storeId);
          return array;
        }, []);
      }
    },

    methods: {
      toggleRegionStores(regionStores) {
        const regionStoreIds = regionStores.map(s => s.storeId);
        const selectableStoreIds = regionStoreIds.filter(id => !this.selectedStoreIds.includes(id) && !this.disabledIds.includes(id));
        const regionContainsAllStores = regionStores.length === this.stores.length;

        if (regionContainsAllStores || selectableStoreIds.sort() === regionStoreIds.sort()) {
          return this.handleSelectAll();
        }

        this.selectedStoreIds = selectableStoreIds.length
          ? [...this.selectedStoreIds, ...selectableStoreIds]
          : this.selectedStoreIds.filter(id => !regionStoreIds.includes(id));
      },

      handleSelectAll() {
        if (this.selectAllMode === 'excludeAll') {
          this.selectedStoreIds = [];
        }
        else if (this.selectAllMode === 'includeAll') {
          this.selectedStoreIds = this.isSelectedAll ? [] : this.availableStoreIds;
        }
      }
    }
  };
</script>
