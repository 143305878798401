<template>
  <div class="card-group">
    <gift-card-designs-form :merchant="merchant" />
    <gift-card-settings-form :merchant="merchant" />
  </div>
</template>

<script>
  import GiftCardDesignsForm from './merchant-gift-cards/gift-card-designs-form.vue';
  import GiftCardSettingsForm from './merchant-gift-cards/gift-card-settings-form.vue';


  export default {
    name: 'MerchantGiftCardsTab',

    components: { GiftCardDesignsForm, GiftCardSettingsForm },

    props: {
      merchant: {
        type: Object,
        default: null
      }
    }
  };
</script>
