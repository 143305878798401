<template>
  <validated-input :key="type" v-bind="validatedInputProps">
    <b-input
      v-if="type === 'dollars'"
      ref="bInput"
      v-cleave="masks.dollars"
      :icon="hasIcon ? (icon || 'dollar-sign') : ''"
      :icon-right="iconRight"
      :inputmode="inputmode || 'decimal'"
      :maxlength="maxlength"
      type="text"
      v-bind="attributes"
      :autocomplete="autocompleteSetting"
      v-on="handlers"
      @input.native="inputHandler"
    />

    <b-input
      v-else-if="type === 'email'"
      ref="bInput"
      :icon="hasIcon ? (icon || 'envelope') : ''"
      :icon-right="iconRight"
      :inputmode="inputmode || 'email'"
      :maxlength="maxlength"
      type="email"
      :use-html5-validation="false"
      v-bind="attributes"
      :autocomplete="autocompleteSetting"
      v-on="handlers"
      @keydown.native.space.prevent
      @input.native="inputHandler"
    />

    <div
      v-else-if="['number', 'float'].includes(type)"
      :class="['input-wrapper', {'not-empty': value || formattedValue}]"
      :data-percent-value="value || formattedValue ? `${formattedValue || value}%` : ''"
    >
      <b-input
        ref="bInput"
        v-cleave="type === 'float' ? masks.float : masks.integer"
        :icon="icon"
        :icon-right="iconRight"
        :inputmode="inputmode || type === 'float' ? 'decimal' : 'numeric'"
        :maxlength="maxlength"
        type="text"
        v-bind="attributes"
        :autocomplete="autocompleteSetting"
        v-on="handlers"
        @input.native="inputHandler"
      />
    </div>

    <b-input
      v-else-if="type === 'phone'"
      ref="bInput"
      v-cleave="masks.phone_us"
      :has-counter="false"
      :icon="hasIcon ? (icon || 'phone-alt') : ''"
      :icon-right="iconRight"
      :inputmode="inputmode || 'tel'"
      type="tel"
      v-bind="attributes"
      :autocomplete="autocompleteSetting"
      v-on="handlers"
      @input.native="inputHandler"
    />

    <b-input
      v-else-if="type === 'text'"
      ref="bInput"
      :icon="icon"
      :icon-right="iconRight"
      :inputmode="inputmode"
      :maxlength="maxlength"
      type="text"
      v-bind="attributes"
      :autocomplete="autocompleteSetting"
      v-on="handlers"
      @input.native="inputHandler"
    />

    <b-input
      v-else-if="type === 'textarea'"
      ref="bInput"
      :icon="icon"
      :icon-right="iconRight"
      :inputmode="inputmode"
      :maxlength="maxlength"
      :rows="rows"
      type="textarea"
      v-bind="attributes"
      :autocomplete="autocompleteSetting"
      v-on="handlers"
      @input.native="inputHandler"
    />

    <b-input
      v-else-if="type === 'password'"
      ref="bInput"
      :icon="hasIcon ? (icon || 'key') : ''"
      :icon-right="iconRight"
      :inputmode="inputmode"
      :maxlength="maxlength"
      :password-reveal="passwordReveal"
      type="password"
      v-bind="attributes"
      :autocomplete="autocompleteSetting"
      v-on="handlers"
      @input.native="inputHandler"
    />

    <b-input
      v-else-if="type === 'zipCode'"
      ref="bInput"
      v-cleave="masks.zip_code"
      :has-counter="false"
      :icon="icon"
      :icon-right="iconRight"
      :inputmode="inputmode || 'numeric'"
      maxlength="5"
      type="text"
      v-bind="attributes"
      :autocomplete="autocompleteSetting"
      v-on="handlers"
      @input.native="inputHandler"
    />

    <b-input
      v-else-if="type === 'postalCode'"
      ref="bInput"
      v-cleave="masks.postal_code"
      :has-counter="false"
      :icon="icon"
      :icon-right="iconRight"
      :inputmode="inputmode"
      type="text"
      v-bind="attributes"
      :autocomplete="autocompleteSetting"
      v-on="handlers"
      @input.native="inputHandler"
    />

    <b-input
      v-else-if="type === 'time'"
      ref="bInput"
      v-cleave="masks.time"
      :has-counter="false"
      :icon="icon"
      :icon-right="iconRight"
      :inputmode="inputmode"
      type="text"
      v-bind="attributes"
      :autocomplete="autocompleteSetting"
      v-on="handlers"
      @input.native="inputHandler"
    />

    <b-input
      v-else-if="type === 'httpsUrl'"
      ref="bInput"
      v-cleave="masks.https_url"
      :has-counter="false"
      :icon="icon"
      :icon-right="iconRight"
      :inputmode="inputmode"
      type="text"
      v-bind="attributes"
      :autocomplete="autocompleteSetting"
      v-on="handlers"
      @input.native="inputHandler"
    />
  </validated-input>
</template>



<script>
  import { parsePhoneNumberFromString } from 'libphonenumber-js';
  import Cleave from 'cleave.js';
  import 'cleave.js/dist/addons/cleave-phone.us';


  // We add a new instance of Cleave when the element
  // is bound and destroy it when it's unbound.
  const cleave = {
    name: 'cleave',
    bind(el, binding) {
      const input = el.querySelector('input');
      input._vCleave = new Cleave(input, binding.value);
    },
    unbind(el) {
      const input = el.querySelector('input');
      if (input._vCleave) {
        input._vCleave.destroy();
      }
    }
  };

  export default {
    name: 'ValidatedTextInput',

    directives: { cleave },

    props: {
      allowEmptyString: {
        type: Boolean,
        default: false
      },
      backendValidationErrors: {
        type: Array,
        default() {
          return [];
        }
      },
      bails: {
        type: Boolean,
        default: true
      },
      customMessages: {
        type: Object,
        default: undefined
      },
      disableAutocomplete: {
        type: Boolean,
        default: false
      },
      disabled: {
        type: Boolean,
        default: false
      },
      errors: {
        type: Array,
        default: () => []
      },
      expanded: {
        type: Boolean,
        default: false
      },
      hasCounter: {
        type: Boolean,
        default() {
          return Boolean(this.maxlength);
        }
      },
      hasIcon: {
        type: Boolean,
        default: true
      },
      hideErrorMessages: {
        type: Boolean,
        default: false
      },
      hideLabel: {
        type: Boolean,
        default: false
      },
      hideRequiredIndicator: {
        type: Boolean,
        default: false
      },
      highlightOnFocus: {
        type: Boolean,
        default: false
      },
      horizontal: {
        type: Boolean,
        default: false
      },
      icon: {
        type: String,
        default: null
      },
      iconRight: {
        type: String,
        default: null
      },
      iconRightClickable: {
        type: Boolean,
        default: false
      },
      inputmode: {
        type: String,
        default: null
      },
      label: {
        type: String,
        required: true
      },
      labelPosition: {
        type: String,
        default: null
      },
      maskOptions: {
        type: Object,
        default: () => ({})
      },
      maxlength: {
        type: String,
        default: null
      },
      monospaced: {
        type: Boolean,
        default: false
      },
      name: {
        type: String,
        required: true
      },
      outputString: {
        type: Boolean,
        default: false
      },
      passwordReveal: {
        type: Boolean,
        default: false
      },
      pattern: {
        type: String,
        default: null
      },
      placeholder: {
        type: String,
        default: null
      },
      rows: {
        type: [String, Number],
        default: 5
      },
      rules: {
        type: [String, Object],
        default: null
      },
      showEdited: {
        type: Boolean,
        default: false
      },
      spellcheck: {
        type: Boolean,
        default: true
      },
      statusIcon: {
        type: Boolean,
        default: false
      },
      subLabel: {
        type: String,
        default: ''
      },
      subLabelOnSide: {
        type: Boolean,
        default: false
      },
      tooltip: {
        type: String,
        default: null
      },
      tooltipPlacement: {
        type: String,
        default: undefined
      },
      type: {
        type: String,
        required: true,
        validator(value) {
          return [
            'dollars',
            'email',
            'number',
            'float',
            'phone',
            'postalCode',
            'text',
            'textarea',
            'password',
            'zipCode',
            'time',
            'httpsUrl'
          ].includes(value);
        }
      },
      value: {
        type: [String, Number],
        default: null
      }
    },

    data() {
      return {
        formattedValue: ''
      };
    },

    computed: {
      masks() {
        const masks = {
          integer: {
            numeral: true,
            numeralDecimalScale: 0,
            numeralThousandsGroupStyle: 'none'
          },
          float: {
            numeral: true,
            numeralDecimalScale: 8,
            numeralThousandsGroupStyle: 'none'
          },
          dollars: {
            numeral: true,
            numeralDecimalScale: 2,
            numeralThousandsGroupStyle: 'none'
          },
          phone_us: {
            phone: true,
            phoneRegionCode: 'US',
            delimiter: '-'
          },
          zip_code: {
            numericOnly: true,
            blocks: [5]
          },
          postal_code: {
            blocks: [3, 3],
            delimiter: ' ',
            delimiterLazyShow: true,
            uppercase: true
          },
          time: {
            time: true,
            timePattern: ['h', 'm', 's']
          },
          https_url: {
            prefix: 'https://'
          }
        };

        Object.keys(masks).forEach((key) => {
          masks[key] = { ...masks[key], ...this.maskOptions };
        });

        return masks;
      },

      attributes() {
        const attributesObj = {
          hasCounter: this.hasCounter,
          disabled: this.disabled,
          id: this.name,
          name: this.name,
          pattern: this.pattern,
          placeholder: this.placeholder,
          iconRightClickable: this.iconRightClickable,
          statusIcon: this.statusIcon,
          value: this.formattedValue || this.value
        };

        if (this.monospaced) {
          attributesObj.class = 'has-text-monospaced';
        }

        return attributesObj;
      },

      autocompleteSetting() {
        // autocomplete="off" doesn't work anymore, but any random string works
        // https://github.com/sagalbot/vue-select/issues/324
        return this.disableAutocomplete ? 'null' : 'on';
      },

      handlers() {
        // Event modifiers (e.g. input.native) aren't currently supported as object key values bound to functions.
        // We will keep those event bindings on the html elements, and abstract away what we can.
        // https://stackoverflow.com/questions/71638466/pass-event-modifiers-with-v-on-object-syntax
        return {
          focus: event => this.highlightOnFocus && event.target.select(),
          blur: this.blurHandler,
          'icon-right-click': this.iconRightClickHandler
        };
      },

      validatedInputProps() {
        let rules;
        if (typeof this.rules === 'string') {
          rules = this.rules.split('|').reduce((acc, rule) => {
            const [name, arg] = rule.split(':');
            acc[name] = arg || true;
            return acc;
          }, {});
        }
        else {
          rules = this.rules;
        }

        const implicitRules = {};
        switch (this.type) {
          case 'email':
            implicitRules.validEmailAddress = true;
            break;

          case 'zipCode':
          case 'postalCode':
            implicitRules.postalCode = true;
            break;

          default:
            break;
        }

        return {
          bails: this.bails,
          customMessages: this.customMessages,
          expanded: this.expanded,
          label: this.label,
          subLabel: this.subLabel,
          subLabelOnSide: this.subLabelOnSide,
          labelPosition: this.labelPosition,
          showEdited: this.showEdited,
          name: this.name,
          rules: {
            ...implicitRules,
            ...rules
          },
          horizontal: this.horizontal,
          backendValidationErrors: this.backendValidationErrors,
          hideErrorMessages: this.hideErrorMessages,
          hideLabel: this.hideLabel,
          hideRequiredIndicator: this.hideRequiredIndicator,
          tooltip: this.tooltip,
          tooltipPlacement: this.tooltipPlacement
        };
      }
    },

    watch: {
      value: 'handleValueChange'
    },

    created() {
      this.formatPhoneNumber();
    },

    mounted() {
      if (!this.spellcheck) {
        const element = this.$el.querySelector('input') || this.$el.querySelector('textarea');
        element.setAttribute('spellcheck', false);
      }
    },

    methods: {
      iconRightClickHandler() {
        this.$emit('icon-right-click', this.formattedValue);
      },

      handleValueChange(newVal) {
        if (this.type === 'phone') {
          this.formatPhoneNumber();
        }
        else {
          this.formattedValue = newVal;
        }
      },

      formatPhoneNumber() {
        if (this.type === 'phone') {
          if (this.value?.length === 10) {
            const number = parsePhoneNumberFromString(this.value, 'US');
            const formattedNumberWithParens = number.formatNational();
            this.formattedValue = formattedNumberWithParens.replace(/\(|\)/g, '').replace(' ', '-'); // Removes parentheses and replaces space with "-"
          }

          else if (!this.value) {
            this.formattedValue = '';
          }
          else {
            this.formattedValue = this.value;
          }
        }
      },

      blurHandler(event) {
        /*
          NOTE: If a number isn't formatted correctly in the
          input (for examle: "20.2.2" or "1-2.4"), the browser
          will return an empty string as the input value. On
          blur, we'll reset all empty string values to null to
          remove potentially bad input.
        */

        if (!this.allowEmptyString && event.target.value === '') {
          this.$emit('input', null);
        }

        else if (this.type === 'dollars') {
          const formattedDollarString = parseFloat(event.target.value).toFixed(2);

          this.formattedValue = formattedDollarString;
          this.$emit('input', formattedDollarString);
        }

        this.$emit('blur', event.target.value);
      },

      inputHandler(e) {
        let newVal = e.target.value;
        const typesToConvert = ['number', 'float', 'dollars'];
        const cleavedInputTypes = ['phone'];

        if (typesToConvert.includes(this.type) && !this.outputString) {
          this.formattedValue = newVal;
          newVal = e.target.value === '' || Number.isNaN(Number(e.target.value)) ? null : Number(e.target.value);
        }

        if (cleavedInputTypes.includes(this.type)) {
          this.formattedValue = e.target._vCleave.getFormattedValue();
          this.$emit('input', e.target._vCleave.getRawValue());
        }
        else {
          this.$emit('input', newVal);
        }
      }
    }
  };
</script>

<style lang="sass" scoped>
  ::v-deep.has-text-monospaced
    input,
    textarea
      font-family: 'Fira Code', monospace !important
      letter-spacing: 1px

  .percent
    .input-wrapper.not-empty
      position: relative

      ::v-deep.input
        caret-color: $grey-darker
        color: transparent

      &::after
        color: $grey-darker
        content: attr(data-percent-value)
        pointer-events: none
        position: absolute
        top: 50%
        left: 12px
        transform: translateY(-50%)
        font-family: $family-sans-serif
        z-index: 10
</style>
