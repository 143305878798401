<template>
  <validated-form
    ref="form"
    form-id="searchSelectModal"
    auto-focus
    @valid-submit="handleSubmit"
  >
    <modal-card
      :title="title"
      :subtitle="subtitle"
      modal-card-body-class="pad-b-none"
    >
      <b-message v-if="message" :type="messageType" class="is-compact has-shadow">
        <span v-html="message" />
      </b-message>
      <b-input
        v-model="searchInput"
        class="mar-b"
        icon-right-clickable
        :icon-right="searchInput ? 'times-circle' : null"
        :placeholder="placeholderText"
        type="text"
        icon="search"
        expanded
        @icon-right-click="searchInput = null"
        @keydown.native.esc="searchInput = null"
        @keydown.native.enter.prevent
      />
      <validated-input
        class="dropdown-field"
        name="locations"
        label="Locations"
        :rules="{
          required: true
        }"
      >
        <b-dropdown
          v-model="selected"
          expanded
          :multiple="!isSingleSelection"
          inline
          class="is-inline-block"
        >
          <b-dropdown-item
            v-for="(item, index) in filteredItems"
            :key="`${item.id}-${index}`"
            class="pad-y-sm"
            :value="item.id"
            :disabled="item.isInvalid"
          >
            <div class="mar-l-xs is-clipped">
              <p class="text-ellipsis has-text-weight-bold">
                {{ item.name }}
                <b-icon
                  v-if="item.icon"
                  class="mar-l-xs"
                  :type="item.iconType"
                  size="is-small"
                  :icon="item.icon"
                />
                <b-icon
                  v-if="!invalidItemMessage && item.isInvalid"
                  class="mar-l-xs"
                  pack="far"
                  type="is-danger"
                  size="is-small"
                  icon="exclamation-triangle"
                />
              </p>
              <p v-if="item.description" class="text-ellipsis mar-none is-size-7 has-text-weight-normal">
                {{ item.description }}
              </p>
              <p v-if="item.footer" class="text-ellipsis mar-none is-size-7 is-italic has-text-weight-normal">
                {{ item.footer }}
              </p>
              <template v-if="item.isInvalid">
                <ul v-if="showDetailedErrors && item.errors" class="has-text-danger is-size-7">
                  <li v-for="(errorMessages, key) in item.errors" :key="key">
                    <div v-for="(errorMessage, _index) in errorMessages" :key="`${key}-${_index}`" class="is-flex align-center">
                      <b-icon
                        pack="far"
                        size="is-small"
                        icon="exclamation-triangle"
                        class="mar-r-xs"
                      />
                      <p><span class="is-monospaced">{{ key }}</span> {{ errorMessage }}</p>
                    </div>
                  </li>
                </ul>
                <p v-else-if="invalidItemMessage" class="is-size-7 has-text-danger">
                  <b-icon
                    pack="far"
                    size="is-small"
                    icon="exclamation-triangle"
                  />
                  {{ invalidItemMessage }}
                </p>
              </template>
            </div>
          </b-dropdown-item>
        </b-dropdown>
      </validated-input>

      <template #footer>
        <div class="buttons all-bold">
          <b-button
            rounded
            @click="$_confirmCloseModal({ programmatic: true })"
          >
            {{ cancelText }}
          </b-button>
          <b-button
            v-tabbable
            rounded
            native-type="submit"
            type="is-primary"
            :loading="isLoading"
            :disabled="isLoading || disableSubmitButton"
          >
            {{ confirmText }}
            <template v-if="!isSingleSelection && selected.length">
              ({{ selected.length }})
            </template>
          </b-button>
        </div>
      </template>
    </modal-card>
  </validated-form>
</template>

<script>
  import merchantMixin from '@/mixins/merchant';
  import confirmModalCloseMixin from '@/mixins/confirm-modal-close';
  import scrollIndicatorMixin from '@/mixins/scroll-container-indicator';

  export default {
    name: 'SearchSelectModal',

    mixins: [merchantMixin, confirmModalCloseMixin, scrollIndicatorMixin],

    props: {
      cancelText: {
        type: String,
        default: 'Cancel'
      },

      confirmText: {
        type: String,
        default: 'Save'
      },

      invalidItemMessage: {
        type: String,
        default: null
      },

      onSubmit: {
        type: Function,
        required: true
      },

      placeholderText: {
        type: String,
        required: true
      },

      showDetailedErrors: {
        type: Boolean,
        default: false
      },

      subtitle: {
        type: String,
        default: ''
      },

      title: {
        type: String,
        required: true
      },

      isSingleSelection: {
        type: Boolean,
        default: false
      },

      messageType: {
        type: String,
        default: 'is-primary'
      },

      message: {
        type: String,
        default: ''
      },

      data: {
        type: Array,
        required: true,
        validator(array) {
          const validKeys = ['id', 'name', 'description', 'icon', 'iconType', 'isInvalid', 'errors', 'footer'];
          const requiredKeys = ['id', 'name'];
          return array.every((obj) => {
            const objKeys = Object.keys(obj);
            const hasRequiredKeys = requiredKeys.every(reqKey => objKeys.includes(reqKey));
            const hasInvalidKeys = objKeys.some(key => !validKeys.includes(key));

            return hasRequiredKeys && !hasInvalidKeys;
          });
        }
      }
    },

    data() {
      return {
        selected: this.isSingleSelection ? null : [],
        searchInput: null,
        isLoading: false
      };
    },

    computed: {
      filteredItems() {
        if (this.searchInput) {
          return this.data.filter(sl => sl.name.toLowerCase().includes(this.searchInput.toLowerCase()));
        }
        return this.data;
      },

      disableSubmitButton() {
        return this.isSingleSelection ? !this.selected : !this.selected.length;
      }
    },

    watch: { // TEST
      searchInput() {
        this.$nextTick(() => {
          this.$_setOverflowAttrAndClass();
        });
      }
    },

    mounted() { // TEST
      this.$_initScrollIndicatorSmart({
        contentSelector: '.dropdown-content',
        maxHeight: 375
      });
    },

    methods: { // TEST
      async handleSubmit() {
        try {
          this.isLoading = true;
          await this.onSubmit(this.selected);
          this.$_confirmCloseModal({ programmatic: true });
        }
        catch (error) {
          console.log('error');
        }
        finally {
          this.isLoading = false;
        }
      }
    }
  };
</script>

<style lang="sass" scoped>
  ::v-deep
    .label
      display: none

    .dropdown-content
      box-shadow: none

    .dropdown-field
      border-top: 1px solid $grey-lightest
      margin: 0 -1.25rem

    .dropdown-menu
      display: flex !important
      flex-direction: column
</style>
