import m from 'moment-timezone';



function moment(value, format, timezone, utc = false) {
  if (!value) return '';
  if (timezone) return m.tz(value, timezone).format(format);
  if (utc) return m.utc(value).format(format);
  return m(value).format(format);
}



export default moment;
