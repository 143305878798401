<template>
  <div class="modal-card">
    <header class="modal-card-head">
      <div class="modal-card-title">
        <p>Transaction #{{ tipReport.orderId }}</p>
      </div>
      <button class="button is-transparent mar-l" @click="closeModal">
        <b-icon icon="times" size="is-small" />
      </button>
    </header>

    <div class="modal-card-body">
      <div v-if="fetchingOrderDetails" class="has-text-centered pad-xl">
        <b-icon
          type="is-grey-light"
          pack="fal"
          icon="spinner-third"
          class="is-full-width is-full-height"
          custom-size="fa-5x"
          custom-class="spin"
        />
      </div>
      <template v-else>
        <b-collapse
          class="expandable-section"
          animation="slide"
          data-test-id="tip-info-section"
        >
          <div
            slot="trigger"
            slot-scope="props"
            role="button"
            class="expandable-section--header"
          >
            <h4 class="subtitle is-5 is-marginless">
              Tip Info
            </h4>
            <b-icon class="open-indicator" :icon="props.open ? 'angle-down' : 'angle-right'" />
          </div>
          <dl class="details-container customer-info is-flex-wrap gap-y gap-x-lg">
            <div v-if="tipReport.employeeName" data-test-class="detail-row">
              <dt class="has-text-grey">Employee:</dt>
              <dd class="has-text-weight-semibold">
                {{ tipReport.employeeName }}
              </dd>
            </div>
            <div data-test-class="detail-row">
              <dt class="has-text-grey">Department:</dt>
              <dd class="has-text-weight-semibold">
                {{ tipReport.departmentName }}
              </dd>
            </div>
            <div data-test-class="detail-row">
              <dt class="has-text-grey">Timestamp:</dt>
              <dd class="has-text-weight-semibold">
                <p>{{ tipReport.orderDate | moment('MMMM D, YYYY', tipReport.storeTimezone) }}</p>
                <p>{{ tipReport.orderDate | moment('@ h:mm A z', tipReport.storeTimezone) }}</p>
              </dd>
            </div>
            <div data-test-class="detail-row">
              <dt class="has-text-grey">Location:</dt>
              <dd class="has-text-weight-semibold">{{ tipReport.locationName }}</dd>
            </div>
            <div data-test-class="detail-row">
              <dt class="has-text-grey">Tip Amount:</dt>
              <dd class="has-text-weight-semibold">{{ tipReport.tip | dollars }}</dd>
            </div>
          </dl>
        </b-collapse>

        <!-- To be refacotred and implemented in 3760
        <b-collapse
          class="expandable-section"
          animation="slide"
          data-test-id="survey-info-section"
        >
          <div
            slot="trigger"
            slot-scope="props"
            role="button"
            class="expandable-section--header"
          >
            <h4 class="subtitle is-5 is-marginless">
              Survey Info
            </h4>
            <b-icon class="open-indicator" :icon="props.open ? 'angle-down' : 'angle-right'" />
          </div>
          <dl class="details-container dist-y-md">
            <div class="is-full-width" data-test-class="detail-row">
              <dt class="has-text-grey">Comment:</dt>
              <dd class="has-text-weight-semibold">
                {{ tipReport.comment || 'No comment' }}
              </dd>
            </div>
            <div class="is-full-width" data-test-class="detail-row">
              <dt class="has-text-grey">Rating:</dt>
              <dd class="has-text-weight-semibold">
                <b-rate
                  v-if="tipReport.rating"
                  :max="5"
                  :value="tipReport.rating"
                  disabled
                />
                <span v-else>No rating</span>
              </dd>
            </div>
          </dl>
        </b-collapse> -->

        <payment-info-section :order="order" />

        <transaction-details-section :order="order" />
      </template>
    </div>

    <div class="modal-card-foot is-hidden-print">
      <div class="buttons all-bold">
        <b-button
          rounded
          type="is-primary"
          @click="closeModal"
        >
          Close
        </b-button>
      </div>
    </div>
  </div>
</template>

<script>
  import PaymentInfoSection from '@/components/pages/orders/order-modal-sections/payment-info-section.vue';
  import TransactionDetailsSection from '@/components/pages/orders/order-modal-sections/transaction-details-section.vue';

  import merchantMixin from '@/mixins/merchant';

  import Order from '@/store/classes/Order';


  export default {
    name: 'TipReportModal',

    components: { PaymentInfoSection, TransactionDetailsSection },

    mixins: [merchantMixin],

    props: {
      tipReport: {
        type: Object,
        required: true
      }
    },

    computed: {
      order() {
        return Order.find(this.tipReport.orderId);
      },

      fetchingOrderDetails() {
        return Order.$state().fetchingSingle;
      }
    },

    created() {
      this.onCreated();
    },

    methods: {
      async onCreated() {
        await this.fetchOrderDetails();
      },

      async fetchOrderDetails() {
        try {
          await Order.getOrderDetails(this.tipReport.orderId);
        }
        catch (error) {
          this.$_onRequestError({
            error,
            toastOptions: {
              message: 'Unable to get order details'
            }
          });
        }
      },

      closeModal() {
        this.$parent.close();
      }
    }
  };
</script>

<style lang="sass" scoped>
  .modal-card
    max-width: 450px

  .modal-card-body
    padding: 0

    > *:nth-child(even)
      background: $white-bis

    > *:last-child
      margin-bottom: 0

  .expandable-section
    border-bottom: 1px solid $grey-lighter
    padding: 15px 20px

    &:last-of-type
      border-bottom: none

    .expandable-section--header
      display: flex
      align-items: center
      justify-content: space-between
      cursor: pointer

      > *
        color: $grey-dark
        transition: 100ms

      &:hover > *
        color: $primary

    .details-container
      width: 100%
      margin-top: 1rem
      overflow: hidden

      &.customer-info
        > div
          width: 180px
          overflow: hidden

</style>
