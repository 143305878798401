<template>
  <div tabindex="0" :class="['modal-card', {'show-overflow': showOverflow}]" @keydown.esc="handleCloseModal">
    <header class="modal-card-head">
      <div class="modal-card-title">
        <b-skeleton v-if="!title" height="1.5rem" />
        <p v-else>{{ title }}</p>

        <slot name="subtitle">
          <p v-if="subtitle" :class="['has-text-grey', 'mar-t-xs', 'subtitle', { 'is-size-6': showSmallSubtitle, 'is-size-5': !showSmallSubtitle }]">
            {{ subtitle }}
          </p>
        </slot>
      </div>
      <div role="button" class="button is-transparent mar-l close-button" @click="handleCloseModal">
        <b-icon icon="times" size="is-small" />
      </div>
    </header>

    <div v-if="$slots.subheader" class="modal-card-subheader">
      <slot name="subheader" />
    </div>

    <div :class="['modal-card-body is-relative', modalCardBodyClass]">
      <slot />
    </div>

    <div v-if="$slots.footer" class="modal-card-foot">
      <slot name="footer" />
    </div>
  </div>
</template>

<script>
  import confirmModalCloseMixin from '@/mixins/confirm-modal-close';



  export default {
    name: 'ModalCard',

    mixins: [confirmModalCloseMixin],

    props: {
      title: {
        type: [String, Boolean],
        default: null
      },

      subtitle: {
        type: [String, Boolean],
        default: null
      },

      showSmallSubtitle: {
        type: Boolean,
        default: false
      },

      showOverflow: {
        type: Boolean,
        default: false
      },

      modalCardBodyClass: {
        type: String,
        default: null
      },

      forceClose: {
        type: Boolean,
        default: false
      }
    },

    methods: {
      async handleCloseModal() {
        const modalClosed = await this.$_confirmCloseModal({
          programmatic: true,
          forceClose: this.forceClose
        });

        if (modalClosed) {
          this.$emit('closed');
        }
      }
    }
  };
</script>

<style scoped lang="sass">
  .modal-card-subheader
    background-color: $white

  .modal-card-title
    flex: 1 0
    white-space: nowrap
    overflow: hidden
    line-height: 1.2

    p
      overflow: hidden
      text-overflow: ellipsis

  [disabled] .close-button
    opacity: 1
    cursor: pointer
</style>
