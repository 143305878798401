<template>
  <div class="card">
    <div class="card-content">
      <template v-if="store.hasApprovedClover">
        <div class="is-flex gap-x align-center mar-b-lg card pad">
          <b-message
            v-if="store.hasActiveCloverAuthApproval"
            class="is-inline-block is-compact has-shadow mar-b-none"
            type="is-success"
            has-icon
          >
            We are currently connected to your Clover account
          </b-message>
          <b-message
            v-else
            class="is-inline-block is-compact has-shadow"
            type="is-warning"
            has-icon
          >
            The Authorization Token for your connected Clover account has expired
          </b-message>
        <b-button
          type="is-primary"
          icon-right="refresh"
          class="is-bold"
          :loading="refreshing"
          @click="updateCloverAuthorization"
        >
          Update Authorization
        </b-button>
        </div>

        <h3 class="mar-b subtitle is-5">Configurations:</h3>
        <div class="is-flex-column gap">
          <b-field>
            <template #label>
              <p>Point Of Sale</p>
              <p class="sub-label">Initialize the required configurations to use Clover as a POS for this location</p>
            </template>

            <div class="buttons">
              <b-button
                :type="hasCloverPos ? 'is-success' : 'is-primary'"
                :icon-left="hasCloverPos ? 'check': ''"
                :disabled="hasCloverPos"
                class="is-bold"
                :loading="loadingPosConfig"
                @click="confirmConfigInitialization('POS Configuration')"
              >
                <template v-if="hasCloverPos">Clover POS Initialized</template>
                <template v-else>Initialize Clover POS</template>
              </b-button>
              <b-button
                v-if="$can('manage', 'all') && hasCloverPos"
                tag="router-link"
                class="is-bold"
                :to="{
                  params: { tabName: 'configurations'},
                  hash: '#point-of-sale'
                }"
              >
                View Config
              </b-button>
            </div>
          </b-field>

          <b-field>
            <template #label>
              <p>Payment Gateway</p>
              <p class="sub-label">Initialize the required configurations to use Clover as a Payment Gateway for this location</p>
            </template>

            <div class="buttons">
              <b-button
                :type="hasCloverPaymentGateway ? 'is-success' : 'is-primary'"
                :icon-left="hasCloverPaymentGateway ? 'check': ''"
                :disabled="hasCloverPaymentGateway"
                class="is-bold"
                :loading="loadingPaymentGateway"
                @click="confirmConfigInitialization()"
              >
                <template v-if="hasCloverPaymentGateway">Clover Payment Gateway Initialized</template>
                <template v-else>Initialize Clover Payment Gateway</template>
              </b-button>
              <b-button
                v-if="$can('manage', 'all') && hasCloverPaymentGateway"
                tag="router-link"
                class="is-bold"
                :to="{
                  params: { tabName: 'configurations'},
                  hash: '#payment-providers'
                }"
              >
                View Config
              </b-button>
            </div>
          </b-field>
        </div>
      </template>

      <template v-else>
        <b-message class="is-inline-block has-shadow is-compact" type="is-warning" has-icon>
          Please connect this location to your Clover account
        </b-message>

        <template v-if="isCardfreeAdmin">
          <h3 class="mar-b subtitle is-5">Instructions (Legacy Flow):</h3>
          <ol class="mar-b-xl is-size-6 dist-y-lg">
            <li>
              <span class="has-text-weight-bold">Login to your <a :href="store.cloverOauthV2.dashboardUrl" target="_blank">Clover Dashboard<b-icon class="mar-l-xs" icon="external-link" size="is-small" /></a></span>
            </li>
            <li>
              <span class="has-text-weight-bold">Click the Connect to your Clover account button below</span>
              <ol class="is-alphabetic pad-l-md mar-t-xs dist-y-xs">
                <li>Login to Clover, if you are logged out</li>
                <li>Search and click the name of the location that you are authorizing</li>
              </ol>
            </li>
          </ol>
        </template>

        <h3 class="mar-b subtitle is-5">Instructions (V2 Flow):</h3>
        <ol class="mar-b-xl is-size-6 dist-y-lg">
          <li>
            <span class="has-text-weight-bold">Login to your <a :href="store.cloverOauthV3.dashboardUrl" target="_blank">Clover Dashboard<b-icon class="mar-l-xs" icon="external-link" size="is-small" /></a></span>
          </li>
          <li>
            <span class="has-text-weight-bold">Click the Connect to your Clover account button below</span>
            <ol class="is-alphabetic pad-l-md mar-t-xs dist-y-xs">
              <li>Login to Clover, if you are logged out</li>
              <li>Search and click the name of the location that you are authorizing</li>
            </ol>
          </li>
          <li>
            <span class="has-text-weight-bold">Click the Authorize Location button below</span>
          </li>
        </ol>
        <div class="is-grid col-2 gap-x-xl">
          <div v-if="isCardfreeAdmin" class="is-flex-column align-center gap-y">
            <p class="has-text-weight-bold is-size-5">Legacy Flow</p>
            <b-button
              type="is-primary"
              tag="a"
              :href="store.cloverOauthV2.oauthUrl"
              icon-right="link"
              class="is-bold"
            >
              Connect to your Clover account
            </b-button>
          </div>

          <div class="is-flex-column gap-y">
            <p class=" align-self-center has-text-weight-bold is-size-5">V2 Flow</p>
            <b-button
              tag="a"
              type="is-primary"
              icon-right="link"
              :href="store.cloverAuthorizeV2.oauthUrl"
              class="is-bold"
            >
              Step 1: Connect to your Clover account
            </b-button>
            <b-button
              type="is-primary"
              tag="a"
              :href="store.cloverOauthV3.oauthUrl"
              icon-right="link"
              class="is-bold"
            >
              Step 2: Authorize Location
            </b-button>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
  import Store from '@/store/classes/Store';
  import PosConfiguration from '@/store/classes/PosConfiguration';
  import MerchantPaymentGateway from '@/store/classes/MerchantPaymentGateway';
  import posTypes from '@/constants/posTypes';
  import alertModal from '@/components/globals/alert-modal.vue';
  import http from '@/services/http';
  import { mapGetters } from 'vuex';

  export default {
    name: 'StoreAuthorizationsTab',

    data() {
      return {
        loadingPosConfig: false,
        loadingPaymentGateway: false,
        reauthorizing: false,
        refreshing: false
      };
    },

    computed: {
      ...mapGetters('session', ['isCardfreeAdmin']),

      storeId() {
        return Number(this.$route.params.storeId);
      },

      store() {
        return Store.find(this.storeId);
      },

      hasCloverPos() {
        return PosConfiguration.query()
          .where('storeId', this.storeId)
          .where(posConfig => posConfig.posType.id === posTypes.Clover)
          .exists();
      },

      hasCloverPaymentGateway() {
        return MerchantPaymentGateway.query()
          .where('storeId', this.storeId)
          .where(mpg => mpg.paymentGateway.name === 'Clover')
          .exists();
      }
    },

    methods: {
      async migrateClover() {
        try {
          this.reauthorizing = true;
          const { data: { store } } = await http.get(`stores/${this.storeId}/clover_oauth_v3?no_redirect=true`);
          Store.insert({ data: store });

          this.$_onRequestSuccess({
            toastOptions: { message: 'Successfully migrated Clover to V2' }
          });
        }
        catch (error) {
          this.$_onRequestError({
            toastOptions: { message: 'There was an issue reauthorizing Clover' },
            error
          });
        }
        finally {
          this.reauthorizing = false;
        }
      },

      async authorizeV2Clover() {
        await http.get(`stores/${this.storeId}/clover_oauth_v3`);
      },

      openRefreshCloverAuthorizationDialog() {
        this.$buefy.dialog.confirm({
          title: 'Refresh Clover Authorization',
          message: 'Are you sure you want to continue?',
          onConfirm: this.refreshCloverAuth,
          confirmText: 'Refresh',
          type: 'is-primary',
          hasIcon: true
        });
      },

      async refreshCloverAuth() {
        try {
          this.refreshing = true;
          await Store.refreshCloverAuth(this.storeId);
          this.$_onRequestSuccess({
            toastOptions: { message: 'Successfully reauthorized Clover' }
          });
        }
        catch (error) {
          this.$_onRequestError({
            toastOptions: { message: 'There was an issue reauthorizing' },
            error
          });
        }
        finally {
          this.refreshing = false;
        }
      },

      async updateCloverAuthorization() {
        try {
          this.refreshing = true;
          window.location.href = this.store.cloverOauthV3.oauthUrl;
        }
        catch (error) {
          this.$_onRequestError({
            toastOptions: { message: 'There was an issue updating Clover authorization' },
            error
          });
          this.refreshing = false;
        }
      },

      confirmConfigInitialization(type = 'Payment Gateway') {
        this.$buefy.modal.open({
          parent: this,
          component: alertModal,
          hasModalCard: true,
          trapFocus: true,
          canCancel: false,
          customClass: 'auto-width',
          props: {
            title: `Initialize ${type}`,
            message: `Initializing the Clover ${type} will overwrite your store hours with those found on Clover. Would you like to proceed?`,
            showCloseButton: true,
            icon: 'check-circle',
            iconPack: 'far',
            type: 'is-primary',
            horizontal: true,
            buttons: [
              { text: 'Cancel' },
              {
                text: 'Confirm',
                primary: true,
                onClick: () => this.provisionConfig(type)
              }
            ]
          }
        });
      },

      async provisionConfig(type) {
        if (type === 'Payment Gateway') {
          await this.provisionCloverPaymentGateway();
        }
        else {
          await this.provisionCloverPosConfig();
        }
        await Store.fetchById(this.storeId);
      },

      async provisionCloverPosConfig() {
        try {
          this.loadingPosConfig = true;
          await PosConfiguration.provisionCloverPosConfiguration(this.storeId);
          this.$_onRequestSuccess({
            toastOptions: { message: 'Successfully initialized Clover POS Config!' }
          });
        }
        catch (error) {
          this.$_onRequestError({
            toastOptions: { message: 'There was an issue initializing your Clover POS Config' },
            error
          });
        }
        finally {
          this.loadingPosConfig = false;
        }
      },

      async provisionCloverPaymentGateway() {
        try {
          this.loadingPaymentGateway = true;
          await MerchantPaymentGateway.provisionCloverPaymentGateway(this.storeId);
          this.$_onRequestSuccess({
            toastOptions: { message: 'Successfully initialized Clover Payment Gateway Config!' }
          });
        }
        catch (error) {
          this.$_onRequestError({
            toastOptions: { message: 'There was an issue initializing your Clover Payment Gateway Config' },
            error
          });
        }
        finally {
          this.loadingPaymentGateway = false;
        }
      }
    }
  };
</script>
