<template>
  <panel title="Challenges Progress" no-padding>
    <b-loading v-if="isFetching" :active="isFetching" :is-full-page="false" />

    <searchable-table
      :table-data="challenges"
      :search="{
        placeholder: 'Search by Challenge Name',
        keys: ['name']
      }"
      :filters="[
        {
          placeholder: 'Status',
          key: 'portalDerivedStatus',
          multiple: true,
          default: [
            earnRuleStatuses.ACTIVE,
            earnRuleStatuses.EXPIRING,
            earnRuleStatuses.EXPIRED
          ],
          options: Object.keys(tagTypes).map(key => ({
            name: $changeCase.capitalCase(key),
            value: key
          }))
        },
      ]"
    >
      <template #default="{ filteredData }">
        <b-table
          :data="isFetching ? [] : filteredData"
          class="is-middle-aligned"
          :mobile-cards="false"
          scrollable
          :per-page="perPage"
          paginated
          :current-page="page"
          detail-key="publicId"
          data-test-id="challenges-table"
          :default-sort="['portalDerivedStatus', 'asc']"
          @page-change="page = $event"
        >
          <template #empty>
            <empty-table-loader
              message="No Challenges Progress Found..."
              :loading="isFetching"
            />
          </template>

          <b-table-column
            v-slot="{ row }"
            sortable
            field="name"
            label="Name"
            cell-class=""
          >
            <span class="link" @click="openChallengeModal(row)">
              {{ row.name }}
            </span>
            <p class="is-size-7">
              {{ row.description }}
            </p>
          </b-table-column>

          <b-table-column
            v-slot="{ row }"
            sortable
            field="portalDerivedStatus"
            label="Status"
            centered
            width="150px"
            cell-class="no-wrap-text"
            :custom-sort="sortStatus"
          >
            <b-tag :type="tagTypes[row.portalDerivedStatus]" class="is-outlined is-light">
              {{ $changeCase.capitalCase(row.portalDerivedStatus) }}
            </b-tag>
          </b-table-column>

          <b-table-column
            v-slot="{ row }"
            label="Progress To Next Award"
          >
            <div v-if="row.isInProgress" class="mar-l-lg">
              <span class="mar-r-sm">{{ row.challengeProgress.currentBalance }} / {{ row.challengeProgress.cost }}</span>
              <span>{{ row.pluralName }}</span>
            </div>
            <div v-else class="mar-l-lg">
              <span class="mar-r-sm">0 / {{ getRowConversionThresholdCost(row.conversionThresholds) }}</span>
              <span>{{ row.pluralName }}</span>
            </div>
          </b-table-column>

          <b-table-column
            v-slot="{ row }"
            centered
            label="Rewards Issued"
          >
            <span v-if="row.isInProgress">
              {{ row.challengeProgress.rewardsIssuedCount }}
            </span>
            <span v-else>
              0
            </span>
          </b-table-column>

          <b-table-column
            v-slot="{ row }"
            centered
            cell-class="actions"
            label="Actions"
            width="1"
            field="Actions"
          >
            <dropdown-menu position="bottom-end" :mobile-modal="false">
              <b-button slot="trigger" class="is-transparent" type="is-white">
                <b-icon icon="ellipsis-v" pack="far" size="is-small" />
              </b-button>

              <b-dropdown-item @click="openChallengeModal(row)">
                <b-icon
                  icon="eye"
                  class="mar-r-sm"
                  size="is-small"
                />
                View Challenge
              </b-dropdown-item>
              <b-dropdown-item v-if="$can('update', 'Account') && row.portalDerivedStatus === earnRuleStatuses.ACTIVE" @click="openAdjustProgressModal(row)">
                <b-icon
                  icon="bars-progress"
                  class="mar-r-sm"
                  size="is-small"
                />
                Adjust Progress
              </b-dropdown-item>
            </dropdown-menu>
          </b-table-column>

          <template #bottom-left>
            <div class="left-side is-grid col-2 gap-x">
              <page-number-select
                :page="page"
                :per-page="Number(perPage)"
                :total-count="filteredData.length"
                @input="page = $event"
              />
              <b-field label-for="perPage" label-position="on-border" label="Per Page">
                <b-select id="perPage" v-model="perPage">
                  <option value="10">10</option>
                  <option value="20">20</option>
                  <option value="50">50</option>
                  <option value="100">100</option>
                </b-select>
              </b-field>
            </div>
          </template>
        </b-table>
      </template>
    </searchable-table>
  </panel>
</template>

<script>
  import EarnRule from '@/store/classes/EarnRule';
  import Currency from '@/store/classes/Currency';
  import ConversionThreshold from '@/store/classes/ConversionThreshold';
  import LoyaltyProgram from '@/store/classes/LoyaltyProgram';
  import RegisteredGuest from '@/store/classes/RegisteredGuest';
  import { earnRuleStatuses } from '@/constants/earnRules';
  import ChallengeReviewModal from '@/components/pages/registered-guest/modals/challenge-review-modal.vue';
  import pointAdjustmentModal from '@/components/pages/registered-guest/modals/point-adjustment-modal.vue';

  export default {
    name: 'RegisteredGuestChallengeProgress',

    props: {
      registeredGuest: {
        type: Object,
        required: true
      }
    },

    data: () => ({
      challengeProgress: null,
      earnRuleStatuses,
      perPage: 20,
      page: 1,
      tagTypes: {
        active: 'is-success',
        expiring: 'is-danger',
        expired: 'is-light'
      }
    }),

    computed: {
      isFetching() {
        return EarnRule.$state().fetching
          || Currency.$state().fetching
          || ConversionThreshold.$state().fetching
          || LoyaltyProgram.$state().fetching
          || RegisteredGuest.$state().fetchingChallengeProgress;
      },

      challenges() {
        if (this.isFetching) return [];

        return this.challengeCurrencies.reduce((challenges, currency) => {
          const currencyId = currency.publicId;
          const currentChallengeProgress = this.challengeProgress?.challengeProgresses.find(cp => currencyId === cp.currencyId);
          const earnRule = this.challengeEarnRules.find(er => er.awardPolicy?.currencyPublicId === currencyId);
          const conversionThresholds = this.challengeConversionThresholds.filter(ct => ct.currencyPublicId === currencyId);
          const addChallenge = earnRule
            && conversionThresholds.length
            && ![earnRuleStatuses.DRAFT, earnRuleStatuses.SCHEDULED].includes(earnRule.portalDerivedStatus);

          if (addChallenge) {
            challenges.push({
              name: currency.title || 'N/A',
              pluralName: currency.formattedPluralName(),
              description: currency.description,
              isInProgress: !!currentChallengeProgress,
              challengeProgress: currentChallengeProgress,
              portalDerivedStatus: earnRule.portalDerivedStatus,
              conversionThresholds,
              currency,
              earnRule
            });
          }
          return challenges;
        }, []);
      },

      challengeCurrencies() {
        return Currency.secondaryCurrencies();
      },

      currencyIds() {
        return this.challengeCurrencies.map(cc => cc.publicId);
      },

      challengeEarnRules() {
        return EarnRule.earnRulesByCurrencyIds(this.currencyIds);
      },

      challengeConversionThresholds() {
        return ConversionThreshold.conversionThresholdsByCurrencyIds(this.currencyIds);
      },

      loyaltyProgramPublicId() {
        return LoyaltyProgram.query().first()?.publicId;
      }
    },

    created() {
      this.onCreated();
    },

    methods: {
      async onCreated() {
        try {
          await this.fetchChallengeProgress();
          await LoyaltyProgram.fetchPrograms();
          await Promise.all([
            Currency.fetchCurrencies(this.loyaltyProgramPublicId),
            EarnRule.fetchEarnRules({ programPublicId: this.loyaltyProgramPublicId, includeForSecondaryCurrency: true }),
            ConversionThreshold.fetchConversionThresholds(this.loyaltyProgramPublicId)
          ]);
        }
        catch (error) {
          this.$_onRequestError({
            error,
            toastOptions: {
              message: 'There was an error fetching challenge progress for this account'
            }
          });
        }
      },

      async fetchChallengeProgress() {
        try {
          this.challengeProgress = await RegisteredGuest.fetchChallengeProgress({
            accountId: this.registeredGuest.id,
            memberId: this.registeredGuest.loyaltyReward?.memberId
          });
        }
        catch (error) {
          this.$_onRequestError({
            error,
            toastOptions: {
              message: 'There was an error fetching challenge progress for this account'
            }
          });
        }
      },

      openChallengeModal(challenge) {
        this.$buefy.modal.open({
          parent: this,
          component: ChallengeReviewModal,
          hasModalCard: true,
          canCancel: ['escape', 'outside'],
          trapFocus: true,
          customClass: 'auto-width',
          props: { challenge }
        });
      },

      getRowConversionThresholdCost(conversionThresholds) {
        return conversionThresholds[0]?.cost || 0;
      },

      getRowConversionThresholdCurrency(conversionThresholds) {
        return conversionThresholds[0]?.pluralName || '';
      },

      sortStatus(a, b, isAsc) {
        const statusMap = {
          [earnRuleStatuses.ACTIVE]: 0,
          [earnRuleStatuses.EXPIRING]: 1,
          [earnRuleStatuses.EXPIRED]: 2
        };
        const sort = statusMap[a.portalDerivedStatus] > statusMap[b.portalDerivedStatus] ? 1 : -1;
        return isAsc ? sort : (sort * -1);
      },

      openAdjustProgressModal(row) {
        const pointAdjustment = {
          currentPointTotal: row.challengeProgress?.currentBalance || 0,
          currencyId: row.currency.publicId,
          memberId: this.registeredGuest.loyaltyReward.memberId,
          currencyName: row.pluralName,
          challengeName: row.name
        };

        this.$buefy.modal.open({
          parent: this,
          component: pointAdjustmentModal,
          hasModalCard: true,
          trapFocus: true,
          canCancel: ['escape', 'outside'],
          customClass: 'auto-width',
          props: { pointAdjustment, isSecondaryCurrencyAdjustment: true },
          events: {
            'update-secondary-currency': this.fetchChallengeProgress
          }
        });
      }
    }
  };
</script>

<style lang='sass' scoped>

</style>

