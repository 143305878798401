<template>
  <validation-observer vid="sms-design-form" tag="div">
    <p class="subtitle is-4">Text Content</p>
    <validated-input
      label="Body"
      sub-label="Recommended: 320 characters max"
      name="smsMessage"
      class="mar-b-xs"
      rules="required"
    >
      <div class="sms-message">
        <div
          class="input-wrapper"
          :data-sms-message-value="value.smsNotification.message ? `${value.smsNotification.message}${stopText}` : ''"
        >
          <b-input
            type="textarea"
            :value="value.smsNotification.message"
            :placeholder="stopText.trim()"
            @input="handleSmsChange({ message: $event })"
          />
        </div>
      </div>
    </validated-input>
    <div class="is-flex-wrap justify-between">
      <p
        v-if="value.willIncludeOffer"
        class="sub-label is-size-7 has-text-weight-normal has-text-grey"
      >
        Offer link: {{ shortOfferUrl || 'No offer link available at this time' }}
      </p>
      <small class="help mar-t-none mar-l-auto" :class="{ 'is-danger': totalMessageLength > 320 }">{{ totalMessageLength }}/320</small>
    </div>
    <image-upload
      v-slot="{ imagePath }"
      v-model="localSmsImage"
      name="smsImage"
      :accepted-types="['png', 'jpeg', 'jpg', 'gif']"
      :maximum-file-size-in-mb=".6"
      restrict-file-size
      is-full-width
      :image-url="smsImage.url"
      label="Image (Optional)"
      show-delete-button
      show-clear-button
      clear-text="Clear Image"
      class="mar-t-md"
      validation-mode="aggressive"
    >
      <img
        v-if="imagePath"
        style="max-width: 500px"
        :src="imagePath"
        alt="department image"
      >
    </image-upload>
  </validation-observer>
</template>

<script>
  import merchantMixin from '@/mixins/merchant';

  import getCapiShortUrl from '@/api/capi-short-url';

  import Offer from '@/store/classes/Offer';

  import { notificationTypes } from '@/constants/campaigns';
  import capiShortUrlTypes from '@/constants/capiShortUrlTypes';


  export default {
    name: 'SmsDesignForm',

    mixins: [merchantMixin],

    props: {
      value: {
        type: Object,
        required: true
      },

      hasSmsConfigured: {
        type: Boolean,
        default: false
      }
    },

    data() {
      return {
        smsImage: {},
        localSmsImageFile: null,
        stopText: ' Text STOP to quit',
        shortOfferUrl: null
      };
    },

    computed: {
      localSmsImage: {
        get() {
          return this.localSmsImageFile;
        },

        async set(value) {
          if (!value) {
            if (this.smsImage?.resourceId) {
              this.$emit('delete-campaign-image', { resourceId: this.smsImage.resourceId, type: notificationTypes.SMS });
            }
            this.smsImage = {};
            this.localSmsImageFile = null;
          }
          else {
            this.localSmsImageFile = value;
          }
          this.$emit('image-change', value);
        }
      },

      totalMessageLength() {
        return `${this.value.smsNotification.message}${this.stopText}`.length;
      },

      offerUrl() {
        if (this.value.willIncludeOffer && this.value.campaignsOffers.length) {
          const offer = Offer.query().where('guid', this.value.campaignsOffers[0].offerGuid).first();
          return offer?.url;
        }
        return null;
      }
    },

    watch: {
      offerUrl: {
        handler: 'setShortOfferUrl'
      }
    },

    created() {
      this.onCreated();
    },

    methods: {
      async onCreated() {
        if (this.value.smsNotification.image) {
          this.smsImage = {
            resourceId: this.value.smsNotification.image.id,
            url: this.value.smsNotification.image.file.url
          };
        }
      },

      handleSmsChange(payload) {
        this.$emit('sms-notification-change', payload);
      },

      async setShortOfferUrl(offerUrl) {
        if (offerUrl) {
          const response = await getCapiShortUrl({
            merchantId: this.$_selectedMerchantId,
            url: offerUrl,
            type: capiShortUrlTypes.LOYALTY_CAMPAIGN
          });
          this.shortOfferUrl = response?.shortUrl?.shortenedUrl;
          this.handleSmsChange({ message: `View your offer: ${response?.shortUrl?.shortenedUrl}` });
        }
        else {
          this.shortOfferUrl = null;
          this.handleSmsChange({ message: '' });
        }
      }
    }
  };
</script>

<style lang="sass" scoped>
  .sms-message
    .input-wrapper
      position: relative
      min-height: 128px

      ::v-deep.textarea
        caret-color: $grey-darker
        color: transparent
        height: 100%
        overflow: hidden
        position: absolute
        resize: none

      &::after
        color: $grey-darker
        content: attr(data-sms-message-value)
        display: inline-block
        font-family: $family-sans-serif
        height: 100%
        padding: 11px
        border: 1px solid transparent
        pointer-events: none
        position: relative
        text-rendering: auto
        white-space: pre-wrap
        width: 100%
        z-index: 10
</style>
