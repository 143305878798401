<template>
  <div v-if="isFetching" class="pad-xl">
    <b-loading active :is-full-page="false" />
  </div>
  <validated-form
    v-else
    ref="form"
    form-id="categoryScheduleForm"
    :disabled="!$_menuPermissions.MANAGE_DAYPART_SCHEDULE || !$can('update', 'MenuCategorySchedule')"
  >
    <daypart-schedule-inputs
      v-model="dayparts_form"
      :invalid-days="dayparts_invalidDays"
      :read-only="!$_menuPermissions.MANAGE_DAYPART_SCHEDULE || !$can('update', 'MenuCategorySchedule')"
      can-hide-hours
    />
  </validated-form>
</template>

<script>
  import CategorySchedule from '@/store/classes/CategorySchedule';
  import Category from '@/store/classes/Category';
  import merchantMixin from '@/mixins/merchant';
  import daypartsMixin from '@/mixins/dayparts';

  import moment from 'moment-timezone';


  export default {
    name: 'CategoryScheduleForm',

    mixins: [merchantMixin, daypartsMixin],

    props: {
      category: {
        type: Object,
        required: true
      }
    },

    data() {
      return {
        moment,
        isFetching: false
      };
    },

    computed: {
      categorySchedules() {
        return CategorySchedule
          .query()
          .where('menuCategoryId', this.category.id)
          .get();
      }
    },

    mounted() {
      this.onMounted();
    },

    methods: {
      async onMounted() {
        if (this.category.id) {
          await this.fetchCategorySchedules();
        }
        this.$_setDaypartsForm(this.categorySchedules);
      },

      async fetchCategorySchedules() {
        try {
          this.isFetching = true;
          await CategorySchedule.fetchSchedules(this.category.id);
        }
        catch (error) {
          this.$_onRequestError({
            error,
            toastOptions: { message: 'Unable to fetch Category Schedules' }
          });
        }
        finally {
          this.isFetching = false;
        }
      },

      async handleSubmit(categoryId) {
        try {
          const { added, removed, updated } = this.$_getDaypartsToUpdate({
            currentDayparts: this.categorySchedules
          });

          await Promise.all([
            ...added.map(a => CategorySchedule.createSchedule({ schedule: a, categoryId })),
            ...updated.map(u => CategorySchedule.updateSchedule(u)),
            ...removed.map(r => CategorySchedule.deleteSchedule(r.id))
          ]);

          await Category.fetchCategory(categoryId);
        }

        catch (error) {
          this.$_onRequestError({
            error,
            toastOptions: { message: 'Unable to update Daypart Schedule' }
          });
          throw error;
        }
      }
    }
  };
</script>
