<template>
  <div class="is-full-height is-flex-column align-center pad-t-xl mar-t-xl">
    <img
      src="/images/cf-logo-full.svg"
      alt="CardFree Logo"
      style="width: 250px"
      class="mar-b-xl"
    >
    <transition name="fade-zoom-between" mode="out-in">
      <div v-if="showSuccess" key="success" class="card has-radius-large is-flex-column align-center has-text-centered pad-lg">
        <b-icon
          icon="check-circle"
          type="is-success"
          class="auto-size mar-b"
          custom-class="fa-4x"
        />
        <h2 class="title is-spaced mar-b-sm">Check Your Email</h2>
        <p class="subtitle is-5 has-text-grey-dark">
          We've sent a confirmation email to
          <span class="has-text-primary">{{ form.email }}</span>.
          Please check your email for the next steps to complete your registration.
        </p>
      </div>

      <div v-else key="form" class="card has-radius-large">
        <div class="is-flex-column align-center has-text-centered has-border-bottom has-border-grey-lightest pad-md">
          <h2 class="title is-spaced mar-b-sm">Let's Get Started!</h2>
          <p class="subtitle is-6 has-text-grey-dark">{{ subtitle }}</p>
        </div>

        <validated-form
          ref="form"
          v-entrap-focus
          form-id="sign-up-sundry"
          :track-changes="false"
          class="pad-md is-grid gap-md"
          @valid-submit="handleSubmit"
        >
          <validated-text-input
            v-model="form.businessName"
            type="text"
            name="userOnboardingMetadata.businessName"
            label="Business Name"
            rules="required"
          />
          <validated-text-input
            v-model="form.fullName"
            type="text"
            name="name"
            label="Your Name"
            rules="required"
          />
          <validated-text-input
            v-model="form.email"
            type="email"
            name="email"
            label="Email Address"
            rules="required"
            :has-icon="false"
          />
          <b-button
            type="is-primary"
            size="is-medium"
            native-type="submit"
            rounded
            expanded
            :loading="isLoading"
          >
            Submit
          </b-button>
        </validated-form>
      </div>
    </transition>
  </div>
</template>

<script>
  import MerchantType from '@/store/classes/MerchantType';
  import User from '@/store/classes/User';

  export default {
    name: 'MerchantSignUp',

    metaInfo: () => ({
      title: 'Join CardFree'
    }),

    props: {
      merchantTypeId: {
        type: Number,
        default: MerchantType.typeIds.STANDARD
      }
    },

    data: () => ({
      form: {
        businessName: '',
        fullName: '',
        email: ''
      },
      showSuccess: false
    }),

    computed: {
      isLoading() {
        return User.$state().submitting;
      },

      subtitle() {
        switch (this.merchantTypeId) {
          case MerchantType.typeIds.SUNDRY:
            return 'Try our award winning hotel and concierge services for free';

          default:
            return 'Welcome to CardFree. Get started by completing the form below.';
        }
      }
    },

    methods: {
      async handleSubmit() {
        try {
          await User.onboardUser({
            ...this.form,
            merchantTypeId: this.merchantTypeId
          });

          this.showSuccess = true;
        }
        catch (error) {
          this.$_onRequestError({
            toastOptions: { message: 'There was an issue creating your account' },
            error,
            options: {
              backendError: error.data.errors?.user
            }
          });
        }
      }
    }
  };
</script>

<style lang='sass' scoped>
  .card
    width: 100vw
    max-width: 500px

  .subtitle
    max-width: 375px
</style>
