/* eslint-disable import/no-cycle */
import { Model } from '@vuex-orm/core';
import http from '@/services/http';



export default class StoreTenderType extends Model {
  static entity = 'storeTenderTypes'

  static fields() {
    return {
      id: this.attr(''),
      storeId: this.attr(''),
      cardType: this.attr(null),
      tenderTypeId: this.attr(null)
    };
  }



  // STATE //////////////////////

  static state() {
    return {
      fetching: false,
      submitting: false,
      deleting: false
    };
  }

  static $state() {
    return this.store().state.entities.storeTenderTypes;
  }



  // ACTIONS //////////////////////

  static async fetchTenderTypes(storeId) {
    const alreadyFetched = this.query().where('storeId', storeId).exists();
    if (!alreadyFetched) {
      try {
        this.commit((state) => {
          state.fetching = true;
        });

        const response = await http.get(`stores/${storeId}/store_tender_types`);

        this.create({
          data: response.data.storeTenderTypes
        });
      }
      catch (error) {
        throw error;
      }
      finally {
        this.commit((state) => {
          state.fetching = false;
        });
      }
    }
  }

  static async addTenderType({ storeId, tender }) {
    try {
      this.commit((state) => {
        state.submitting = true;
      });

      const response = await http.post(`stores/${storeId}/store_tender_types`, {
        storeTenderType: {
          tenderTypeId: tender.tenderTypeId,
          cardType: tender.cardType
        }
      });

      this.insert({
        data: response.data.storeTenderType
      });

      return response.data.storeTenderType;
    }
    catch (error) {
      throw error;
    }
    finally {
      this.commit((state) => {
        state.submitting = false;
      });
    }
  }

  static async updateTenderType(tender) {
    try {
      this.commit((state) => {
        state.submitting = true;
      });

      const response = await http.put(`store_tender_types/${tender.id}`, {
        storeTenderType: {
          tenderTypeId: tender.tenderTypeId,
          cardType: tender.cardType
        }
      });

      this.update({
        data: response.data.storeTenderType
      });
    }
    catch (error) {
      throw error;
    }
    finally {
      this.commit((state) => {
        state.submitting = false;
      });
    }
  }

  static async applyTenderTypeToAllStores(tenderId) {
    try {
      this.commit((state) => {
        state.submitting = true;
      });

      await http.post(`store_tender_types/${tenderId}/clone`);
    }
    catch (error) {
      throw error;
    }
    finally {
      this.commit((state) => {
        state.submitting = false;
      });
    }
  }

  static async deleteTenderType(id) {
    try {
      this.commit((state) => {
        state.deleting = true;
      });

      await http.delete(`store_tender_types/${id}`);

      this.delete(id);
    }
    catch (error) {
      throw error;
    }
    finally {
      this.commit((state) => {
        state.deleting = false;
      });
    }
  }
}
