const print = {
  methods: {
    $_print(content, options = {}) {
      const printSection = document.getElementById('print-section');
      const html = document.querySelector('html');

      html.classList.add('printing');
      printSection.innerHTML = content;

      const { hideButtons, hideIcons } = options;

      if (hideButtons) {
        printSection.classList.add('hide-buttons');
      }
      if (hideIcons) {
        printSection.classList.add('hide-icons');
      }

      window.print();

      window.onafterprint = () => {
        printSection.innerHTML = '';
        printSection.classList.value = '';
        html.classList.remove('printing');
      };
    }
  }
};



export default print;
