var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"tip-option-group"},[(_vm.supportsMultipleTippingOptions)?_c('div',{staticClass:"no-flex-shrink application-label"},[_c('div',{staticClass:"is-flex align-center"},[(_vm.tippingOptionTypes.ALL_APPLICATONS === _vm.tippingOptionTypeId && !!_vm.name)?_c('p',{staticClass:"is-size-4 has-text-weight-bold"},[_vm._v(_vm._s(_vm.name))]):_c('b-switch',{attrs:{"disabled":_vm.disabled,"value":_vm.isGroupActive},on:{"input":_vm.handleActiveChange}},[_c('p',{staticClass:"is-size-4 has-text-weight-bold"},[_vm._v(_vm._s(_vm.name))])])],1),(!!_vm.description)?_c('p',{staticClass:"has-text-grey is-size-7"},[_vm._v(_vm._s(_vm.description))]):_vm._e()]):_vm._e(),_c('div',{class:['tip-options', { 'flex-grow': _vm.tippingMethodTypeId !== _vm.tippingOptionMethodTypes.BUTTON }]},[(_vm.tippingMethodTypeId === _vm.tippingOptionMethodTypes.BUTTON)?_c('div',{class:[
        'tip-option field is-expanded is-marginless is-relative',
        { 'is-disabled': _vm.disabled || !_vm.isGroupActive }
      ]},[(_vm.isDollarTip)?_c('validated-text-input',{staticClass:"control",staticStyle:{"margin":"0 !important"},attrs:{"value":_vm.defaultTippingOption.dollarAmount,"label":"Default","name":"default","type":"dollars","label-position":"on-border","disabled":_vm.disabled,"rules":{ min_value: 0 },"mask-options":{ required: true, numeralDecimalScale: 2, numeralPositiveOnly: true }},on:{"input":function($event){return _vm.handleInputChange({ type: 'dollarAmount', index: _vm.defaultTippingOptionIndex, value: $event })}}}):_c('validated-text-input',{staticClass:"percent",staticStyle:{"margin":"0 !important"},attrs:{"value":_vm.defaultTippingOption.percentAmount,"label":"Default","name":"default","type":"number","label-position":"on-border","disabled":_vm.disabled || !_vm.isGroupActive,"rules":{ required: true, min_value: 0, max_value: 100 }},on:{"input":function($event){return _vm.handleInputChange({ type: 'percentAmount', index: _vm.defaultTippingOptionIndex, value: $event })}}})],1):[_vm._l((_vm.tippingOptions),function(option,index){return _c('div',{key:("" + index + _vm.tippingOptionTypeId),class:[
          'tip-option field is-expanded is-marginless is-relative',
          { 'is-disabled': _vm.disabled || !_vm.isGroupActive }
        ],attrs:{"data-test-class":"tipping-option"}},[(_vm.isDollarTip)?_c('validated-text-input',{staticClass:"control",staticStyle:{"margin":"0 !important"},attrs:{"value":option.dollarAmount,"label":("Option " + (index + 1)),"name":("tipOption" + index + _vm.tippingOptionTypeId),"type":"dollars","label-position":"on-border","disabled":_vm.disabled || !_vm.isGroupActive,"rules":{
            required: true,
            min_value: 0,
            excluded: _vm.tippingOptions.reduce(function (arr, o) { return o.id !== option.id ? arr.concat( [Number(o.dollarAmount)]) : arr; }, [])
          },"custom-messages":{
            required: index < 3 ? 'Value required' : 'Value requried or remove option',
            excluded: 'Value must be unique'
          },"mask-options":{ numeralDecimalScale: 2, numeralPositiveOnly: true }},on:{"input":function($event){return _vm.handleInputChange({ type: 'dollarAmount', index: index, value: $event })}}}):_c('validated-text-input',{staticClass:"percent",staticStyle:{"margin":"0 !important"},attrs:{"value":option.percentAmount,"label":("Option " + (index + 1)),"name":("tipOption" + index + _vm.tippingOptionTypeId),"type":"number","label-position":"on-border","disabled":_vm.disabled || !_vm.isGroupActive,"rules":{
            required: true,
            min_value: 0,
            max_value: 100,
            excluded: _vm.tippingOptions.reduce(function (arr, o) { return o.id !== option.id ? arr.concat( [Number(o.percentAmount)]) : arr; }, [])
          },"custom-messages":{
            required: index < 3 ? 'Value required' : 'Value requried or remove option',
            excluded: 'Value must be unique'
          }},on:{"input":function($event){return _vm.handleInputChange({ type: 'percentAmount', index: index, value: $event })}}}),(index === 3)?_c('b-button',{staticClass:"mar-none option-button trash-button",attrs:{"type":"is-danger is-light","icon-left":"trash","disabled":_vm.disabled || !_vm.isGroupActive,"data-test-id":"remove-tipping-option-button"},on:{"click":function($event){return _vm.removeOption(index)}}}):_vm._e(),(_vm.tippingOptions.length > 1)?_c('b-button',{directives:[{name:"tippy",rawName:"v-tippy",value:({ content: option.isDefault ? 'Unselect default' : 'Set as default', placement: 'left', delay: [333, 0], size: 'large' }),expression:"{ content: option.isDefault ? 'Unselect default' : 'Set as default', placement: 'left', delay: [333, 0], size: 'large' }"}],staticClass:"default-button option-button",class:[ option.isDefault ? 'has-text-primary' : 'has-text-grey' ],attrs:{"icon-left":option.isDefault ? 'circle-check' : 'circle',"icon-pack":option.isDefault ? 'fas' : 'far',"name":((option.tippingOptionTypeId) + "-default-tip-option"),"value":option.isDefault && index,"disabled":_vm.disabled || !_vm.isGroupActive},on:{"click":function($event){return _vm.handleDefaultOptionChange(index)}}}):_vm._e()],1)}),(_vm.tippingOptions.length < 4)?_c('b-button',{attrs:{"icon-left":"plus","rounded":"","type":"is-primary is-light","outlined":"","disabled":_vm.disabled || !_vm.isGroupActive,"data-test-id":"add-tipping-option-button"},on:{"click":_vm.addOption}},[_vm._v(" Tip Option ")]):_vm._e()]],2)])}
var staticRenderFns = []

export { render, staticRenderFns }