import { mapState, mapActions, mapGetters } from 'vuex';

const multiFormParentProvider = {
  data() {
    return {
      activeTabIndex: 0,
      invalidTabIndexes: []
    };
  },

  computed: {
    ...mapGetters('formStore', ['visitedForms']),
    ...mapState(
      'formStore', [
        'submitStatus', // NOTE: determines whether forms have submitted correctly
        'invalidForms',
        'isSubmittingForms', // OPTIONAL: use this for the top level submitting state
        'argToPassOnSuccess'
      ]
    )
  },

  watch: {
    invalidForms: 'p_handleInvalidFormsValidation',
    'submitStatus.success': 'p_handleSuccessfulFormSubmit'
  },

  created() {
    this.p_onCreated();
  },

  methods: {
    ...mapActions('formStore', [
      'resetSubmitStatus',
      'submitForms',
      'setInvalidForm',
      'validateForms',
      'clearFormStore'
    ]),

    p_onCreated() {
      if (this.defaultTabIndex) {
        this.activeTabIndex = this.defaultTabIndex;
      }
    },

    // OPTIONAL: Reset the formStore (i.e. if closing modal before a save)
    $_clearFormStore() {
      this.clearFormStore();
    },

    // REQUIRED: Use this to start the process of form validation and submit
    async $_handleSubmitForms() {
      await this.validateForms();
    },

    // NOTE: Set active tab to closest invalid field
    p_handleInvalidFormsValidation(invalidForms) {
      if (invalidForms.length) {
        const invalidTabIndexes = invalidForms.map(ref => this.tabs.findIndex(t => t.props.formRef === ref));
        this.invalidTabIndexes = invalidTabIndexes;

        const activeTabInvalid = invalidTabIndexes.includes(this.activeTabIndex);
        if (!activeTabInvalid) {
          this.activeTabIndex = invalidTabIndexes[0];
        }
      }
    },

    p_handleSuccessfulFormSubmit(status) {
      if (this.onFormSubmitSuccess && status) {
        this.onFormSubmitSuccess(this.argToPassOnSuccess); // OPTIONAL: Define this method to handle actions after a complete form submit
      }
      this.resetSubmitStatus();
    }
  }
};

export default multiFormParentProvider;
