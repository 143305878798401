<template>
  <validated-form
    ref="form"
    auto-focus
    form-id="addLoyaltyProviderForm"
    @valid-submit="submitHandler"
  >
    <modal-card
      :title="`${!merchantLoyaltyProviderConfigurationId ? 'Assign' : 'Update'} Loyalty Provider`"
      :subtitle="merchantLoyaltyProviderConfigurationId && merchantLoyaltyProviderConfiguration.loyaltyProviderName"
    >
      <div v-if="!merchantLoyaltyProviderConfigurationId" class="mar-b-lg">
        <b-message
          data-test-id="new-provider-message"
          class="mar-b is-size-6 has-shadow is-compact"
          type="is-primary"
        >
          Assigning loyalty providers to the merchant will require adding configurations at
          the merchant level and, in the case of CardFree Loyalty, configuring those related areas.
        </b-message>

        <validated-input
          name="selectedLoyaltyProviderId"
          rules="required"
          label="Loyalty Provider"
          data-test-id="new-provider-dropdown"
        >
          <b-dropdown
            v-model="form.loyaltyProviderId"
            class="has-extra-shadow"
            expanded
            :mobile-modal="false"
            scrollable
            :max-height="290"
          >
            <dropdown-button
              slot="trigger"
              placeholder="Select a Loyalty Provider..."
              :value="selectedLoyaltyProviderName"
            />
            <b-dropdown-item
              v-for="({ id, name }) in loyaltyProviders"
              :key="id"
              :value="id"
              data-test-id="provider-dropdown-item"
              :disabled="existingLoyaltyProviderIds.includes(id)"
            >
              {{ name }}
            </b-dropdown-item>
          </b-dropdown>
        </validated-input>
      </div>

      <b-switch v-model="form.isActive">
        <div>
          <p class="label mar-none">Active Loyalty Provider</p>
          <p class="sub-label">Only one may be active at a time</p>
        </div>
      </b-switch>

      <hr>

      <div class="dist-y-md">
        <b-field label="Enabled Ordering Flows">
          <div class="is-flex gap-sm">
            <check-button v-model="form.orderAheadEnabled" class="is-marginless" label="Order Ahead" />
            <check-button v-model="form.payAtTableEnabled" class="is-marginless" label="Pay@Table & Order@Table" />
            <check-button v-model="form.externalOrderEnabled" class="is-marginless" label="In Store" />
          </div>
        </b-field>

        <transition name="fade-down">
          <validated-input
            v-if="form.payAtTableEnabled"
            name="payAtTableAuthenticationTypeId"
            rules="required"
            label="Pay@Table Authentication Type"
          >
            <div>
              <radio-button
                v-for="({ id, displayName }) in payAtTableAuthenticationTypes"
                :key="id"
                v-model="form.payAtTableAuthenticationTypeId"
                :native-value="id"
                name="payAtTableAuthenticationTypes"
              >
                {{ displayName }}
              </radio-button>
            </div>
          </validated-input>
        </transition>

        <validated-input
          name="membershipBadgeSourceId"
          rules="required"
          label="Membership Tier Badge Source"
        >
          <div class="is-flex is-flex-wrap gap-sm">
            <radio-button
              v-for="({ id, displayName }) in membershipTierBadgeSources"
              :key="id"
              v-model="form.membershipTierBadgeSourceId"
              :native-value="id"
              name="membershipTierBadgeSources"
              class="mar-none"
            >
              {{ displayName }}
            </radio-button>
          </div>
        </validated-input>

      </div>

      <hr>

      <validated-text-input
        v-model="form.apiEndpoint"
        class=""
        name="url"
        type="text"
        rules="validUrl"
        label="API Endpoint"
        sub-label="(Optional) - Only required for some loyalty providers"
        placeholder="https://"
      />

      <validated-text-input
        v-model="form.apiConfiguration"
        :has-icon="false"
        label="API Configuration"
        name="loyaltyProviderConfiguration"
        type="textarea"
        rows="10"
        :spellcheck="false"
        maxlength="16384"
        monospaced
        rules="required|validJSON"
      />

      <validated-text-input
        v-model="form.circuitBreakerConfiguration"
        :has-icon="false"
        label="Circuit Breaker Configuration"
        sub-label="(Optional)"
        sub-label-on-side
        name="loyaltyProviderConfiguration"
        type="textarea"
        rows="10"
        :spellcheck="false"
        maxlength="16384"
        monospaced
        rules="validJSON"
      />

      <template #footer>
        <div class="buttons all-bold">
          <b-button rounded @click="$_confirmCloseModal({ programmatic: true })">
            Cancel
          </b-button>
          <b-button
            v-tabbable
            rounded
            native-type="submit"
            type="is-primary"
            :loading="isSubmitting"
          >
            {{ merchantLoyaltyProviderConfigurationId ? 'Save' : 'Assign' }}
          </b-button>
        </div>
      </template>
    </modal-card>
  </validated-form>
</template>

<script>
  import confirmModalCloseMixin from '@/mixins/confirm-modal-close';
  import LoyaltyProvider from '@/store/classes/LoyaltyProvider';
  import PayAtTableAuthenticationType from '@/store/classes/PayAtTableAuthenticationType';
  import MembershipTierBadgeSource from '@/store/classes/MembershipTierBadgeSource';
  import MerchantLoyaltyProviderConfiguration from '@/store/classes/MerchantLoyaltyProviderConfiguration';
  import beautify from 'json-beautify';

  export default {
    name: 'AddEditLoyaltyProviderModal',

    mixins: [confirmModalCloseMixin],

    props: {
      existingLoyaltyProviderIds: {
        type: Array,
        required: true
      },
      merchantLoyaltyProviderConfigurationId: {
        type: Number,
        default: null
      },
      merchantId: {
        type: Number,
        required: true
      }
    },

    data: () => ({
      form: {}
    }),

    computed: {
      isSubmitting() {
        return MerchantLoyaltyProviderConfiguration.$state().submitting;
      },
      loyaltyProviders() {
        return LoyaltyProvider.all();
      },
      membershipTierBadgeSources() {
        return MembershipTierBadgeSource.all();
      },
      merchantLoyaltyProviderConfiguration() {
        return MerchantLoyaltyProviderConfiguration.find(this.merchantLoyaltyProviderConfigurationId) || new MerchantLoyaltyProviderConfiguration();
      },
      payAtTableAuthenticationTypes() {
        return PayAtTableAuthenticationType.all();
      },
      selectedLoyaltyProviderName() {
        return this.loyaltyProviders.find(({ id }) => id === this.form.loyaltyProviderId)?.name;
      }
    },

    created() {
      this.form = this.$clone(this.merchantLoyaltyProviderConfiguration);

      /**
       * format the stringified JSON for better readability
       */
      if (this.merchantLoyaltyProviderConfiguration.apiConfiguration) {
        this.form.apiConfiguration = beautify(JSON.parse(this.merchantLoyaltyProviderConfiguration.apiConfiguration), null, 2, 0);
      }
      if (this.merchantLoyaltyProviderConfiguration.circuitBreakerConfiguration) {
        this.form.circuitBreakerConfiguration = beautify(JSON.parse(this.merchantLoyaltyProviderConfiguration.circuitBreakerConfiguration), null, 2, 0);
      }
    },

    methods: {
      async submitHandler() {
        try {
          const requests = [];

          /**
           * if another loyalty provider is already active,
           * deactivate it before activating the new one
           */
          if (this.form.isActive) {
            const currentActiveLoyaltyProvider = MerchantLoyaltyProviderConfiguration.query()
              .where(({ id, isActive }) => id !== this.form.id && isActive).first();

            if (currentActiveLoyaltyProvider) {
              requests.push(
                MerchantLoyaltyProviderConfiguration.updateMerchantLoyaltyProviderConfiguration({
                  id: currentActiveLoyaltyProvider.id,
                  isActive: false
                })
              );
            }
          }

          const requestBody = {
            ...this.form,
            /**
             * format the stringified JSON for better compactability
             */
            ...this.form.apiConfiguration && {
              apiConfiguration: beautify(JSON.parse(this.form.apiConfiguration), null, 0)
            },
            ...this.form.circuitBreakerConfiguration && {
              circuitBreakerConfiguration: beautify(JSON.parse(this.form.circuitBreakerConfiguration), null, 0)
            }
          };

          requests.push(
            this.merchantLoyaltyProviderConfigurationId
              ? MerchantLoyaltyProviderConfiguration.updateMerchantLoyaltyProviderConfiguration(requestBody)
              : MerchantLoyaltyProviderConfiguration.createMerchantLoyaltyProviderConfiguration({ ...requestBody, merchantId: this.merchantId })
          );

          await Promise.all(requests);

          this.$_onRequestSuccess({
            toastOptions: { message: `Successfully ${this.merchantLoyaltyProviderConfigurationId ? 'updated' : 'added'} your Loyalty Provider!` },
            options: { closeParent: true }
          });
        }

        catch (error) {
          this.$_onRequestError({
            error,
            toastOptions: {
              message: `There was an error ${this.merchantLoyaltyProviderConfigurationId ? 'updating' : 'adding'} your Loyalty Provider`
            }
          });
        }
      }
    }
  };
</script>
