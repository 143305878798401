<template>
  <validation-observer vid="push-design-form" tag="div">
    <p class="subtitle is-4">Push Content</p>
    <validated-text-input
      v-model="value.pushNotification.title"
      rules="required|max:50"
      name="pushTitle"
      type="text"
      label="Title"
      sub-label="Recommended: 50 characters max"
    />
    <validated-text-input
      v-model="value.pushNotification.message"
      name="pushMessage"
      type="text"
      label="Message"
      sub-label="Recommended: 150 characters max"
      rules="required|max:255"
      class="mar-b-xs"
    />
    <div class="is-flex justify-end">
      <small class="help mar-t-none" :class="{ 'is-danger': value.pushNotification.message && value.pushNotification.message.length > 150 }">
        {{ value.pushNotification.message && value.pushNotification.message.length }}/150
      </small>
    </div>
    <image-upload
      v-slot="{ imagePath }"
      v-model="localPushNotificationImage"
      name="pushImage"
      :accepted-types="['png', 'jpeg', 'jpg', 'gif']"
      :image-size-warning-height="200"
      :image-size-warning-width="300"
      :maximum-file-size-in-mb=".1"
      restrict-file-size
      is-full-width
      :image-url="pushNotificationImage.url"
      label="Image (Optional)"
      show-delete-button
      show-clear-button
      clear-text="Clear Image"
      class="mar-t-sm"
      validation-mode="aggressive"
    >
      <img
        v-if="imagePath"
        style="max-width: 500px"
        :src="imagePath"
        alt="department image"
      >
    </image-upload>
    <div class="is-flex gap">
      <validated-input
        v-if="!value.willIncludeOffer"
        label="Link to Page"
        name="pushLink"
        tooltip="Select which page of the app this notification will take the user to"
        tooltip-placement="right"
        class="mar-none"
      >
        <b-select
          v-model="pushNotificationLinkId"
          placeholder="Select a page"
          icon="link"
        >
          <optgroup
            v-for="(links, type) in filteredMerchantDeepLinks"
            :key="type"
            :label="type | capitalize"
          >
            <option
              v-for="link in links"
              :key="`${type}${link.id}`"
              :value="link.id"
            >
              {{ link.displayName }}
            </option>
          </optgroup>
        </b-select>
      </validated-input>
      <template v-if="value.pushNotification.notificationLinkId === deepLinkIds.SPECIFIC_ITEM">
        <div
          v-if="value.pushNotification.resourceMetadata"
          class="tag is-primary is-light auto-height align-self-end pad-sm"
        >
          <div>
            <p class="has-text-weight-bold">{{ value.pushNotification.resourceMetadata.displayName }}</p>
          </div>
          <b-icon
            icon="times-circle"
            class="delete align-self-center mar-none mar-l-sm has-background-primary-dark has-hover-background-primary-medium"
            custom-size="fa-lg"
            @click.native="handleAddRemoveItem({ resourceMetadata: null })"
          />
        </div>
        <validated-input
          v-else
          label="Specific Item"
          name="pushLinkItem"
          rules="required"
          class="flex-grow"
          :custom-messages="{ required: 'A menu item is required to link to a specific item page' }"
        >
          <menu-search-input
            resource-type="menuItems"
            :selected-resources="value.pushNotification.resourceMetadata ? [value.pushNotification.resourceMetadata] : []"
            @add-resource="handleAddRemoveItem({ resourceMetadata: $event })"
          />
        </validated-input>
      </template>
    </div>
  </validation-observer>
</template>

<script>
  import Merchant from '@/store/classes/Merchant';
  import deepLinkIds from '@/constants/deepLinkIds';
  import { campaignStates, notificationTypes } from '@/constants/campaigns';

  export default {
    name: 'PushNotificationDesignForm',

    props: {
      value: {
        type: Object,
        required: true
      },

      hasPushProvidersConfigured: {
        type: Boolean,
        default: false
      },

      hasMobileAppConfigured: {
        type: Boolean,
        default: false
      }
    },

    data() {
      return {
        campaignStates,
        deepLinkIds,
        pushNotificationImage: {},
        localPushNotificationImageFile: null
      };
    },

    computed: {
      localPushNotificationImage: {
        get() {
          return this.localPushNotificationImageFile;
        },

        async set(value) {
          if (!value) {
            if (this.pushNotificationImage.resourceId) {
              this.$emit('delete-campaign-image', { resourceId: this.pushNotificationImage.resourceId, type: notificationTypes.PUSH });
            }
            this.pushNotificationImage = {};
            this.localPushNotificationImageFile = null;
          }
          else {
            this.localPushNotificationImageFile = value;
          }
          this.$emit('image-change', value);
        }
      },

      pushNotificationLinkId: {
        get() {
          return this.value.pushNotification?.notificationLinkId;
        },
        set(notificationLinkId) {
          const payload = { notificationLinkId };
          if (notificationLinkId !== deepLinkIds.SPECIFIC_ITEM && this.value.pushNotification.resourceMetadata) {
            payload.resourceMetadata = null;
          }
          this.handlePushNotificationChange(payload);
        }
      },

      merchantDeepLinks() {
        return Merchant.$state().merchantDeepLinks;
      },

      filteredMerchantDeepLinks() {
        return this.merchantDeepLinks.reduce((acc, link) => {
          const notificationType = link.type;
          if (!acc[notificationType]) {
            acc[notificationType] = [];
          }
          /**
           * Temprorarily hiding the gift card link until mobile has universal links set up.
           * https://cardfree.atlassian.net/browse/TOOL-3516
           */
          if (link.availableForMerchant && link.id !== deepLinkIds.GIFT_CARD) {
            acc[notificationType].push(link);
          }
          return acc;
        }, {});
      }
    },

    created() {
      this.onCreated();
    },

    methods: {
      async onCreated() {
        if (this.value.pushNotification.image) {
          this.pushNotificationImage = {
            resourceId: this.value.pushNotification.image.id,
            url: this.value.pushNotification.image.file.url
          };
        }
      },

      handleAddRemoveItem({ resourceMetadata }) {
        const payload = {
          resourceMetadata: resourceMetadata ? {
            ...resourceMetadata,
            notificationSpecificResourceId: resourceMetadata?.id || null
          } : null
        };
        this.handlePushNotificationChange(payload);
      },

      handlePushNotificationChange(payload) {
        this.$emit('push-notification-change', payload);
      }
    }
  };
</script>
