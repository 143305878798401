<template>
  <div>
    <validated-form
      ref="form"
      auto-focus
      form-id="paymentTransactionRefundModal"
      @valid-submit="confirmRefund"
    >
      <modal-card title="Refund Transaction">
        <div>
          <div class="order-amounts">
            <dl>
              <div>
                <dt>Payment Total</dt>
                <dd>{{ totalAmount | dollars }}</dd>
              </div>
              <div>
                <dt>Refunds Issued</dt>
                <dd>{{ totalRefunded | dollars }}</dd>
              </div>
              <div>
                <dt>Remaining Amount</dt>
                <dd>{{ remainingRefundAmount | dollars }}</dd>
              </div>
            </dl>
          </div>

          <hr>

          <validated-text-input
            v-model="refundAmount"
            data-test-id="refund-amount-input"
            class="refund-amount"
            label="Refund Amount"
            name="refundAmount"
            :rules="{
              required: true,
              min_value: partialRefundAllowedForPayment
                ? 0.01
                : remainingRefundAmount,
              max_value: remainingRefundAmount
            }"
            type="dollars"
            output-string
            :disabled="maxRefundToggle || !partialRefundAllowedForPayment"
            :tooltip="!partialRefundAllowedForPayment ? 'This payment method only supports full refunds.' : ''"
          />
          <b-checkbox
            v-if="partialRefundAllowedForPayment"
            v-model="maxRefundToggle"
            data-test-id="refund-remaining-amount-checkbox"
            size="is-small"
          >
            <p class="is-size-6">Refund Remaining Amount</p>
          </b-checkbox>
        </div>
        <template #footer>
          <div class="buttons all-bold">
            <b-button rounded @click="$_confirmCloseModal({ programmatic: true })">
              Cancel
            </b-button>
            <b-button
              v-tabbable
              rounded
              native-type="submit"
              type="is-primary"
              :loading="refunding"
            >
              Refund
            </b-button>
          </div>
        </template>
      </modal-card>
    </validated-form>
  </div>
</template>

<script>
  import merchantMixin from '@/mixins/merchant';
  import confirmModalCloseMixin from '@/mixins/confirm-modal-close';
  import PaymentTransaction from '@/store/classes/PaymentTransaction';
  import paymentMethods from '@/constants/paymentMethods';
  import PaymentGateway from '@/store/classes/PaymentGateway';
  import currency from 'currency.js';

  export default {
    name: 'PaymentTransactionRefundModal',

    components: {},

    mixins: [confirmModalCloseMixin, merchantMixin],

    props: {
      transaction: {
        type: Object,
        required: true
      },

      totalAmount: {
        type: Number,
        required: true
      },

      remainingRefundAmount: {
        type: Number,
        required: true
      }
    },

    data: () => ({ refundAmount: null, maxRefundToggle: false }),

    computed: {
      refunding() {
        return PaymentTransaction.$state().refunding;
      },

      totalRefunded() {
        return this.totalAmount - this.remainingRefundAmount;
      },

      allPaymentGatewayOptions() {
        return PaymentGateway.all();
      },

      partialRefundAllowedForPayment() {
        const metadata = this.getPaymentGatewayMetadata();
        switch (this.transaction.paymentMethod) {
          case paymentMethods.GIFTCARD.type:
            return !!metadata?.supportsGiftCardPartialRefund;
          case paymentMethods.EXTERNAL_DEVICE.type:
            return !!metadata?.supportsEmvPartialRefund;
          case paymentMethods.CREDIT_CARD.type:
          case paymentMethods.SAVED_CREDIT_CARD.type:
          case paymentMethods.CREDIT_CARD_TOKEN.type:
          case paymentMethods.APPLE_PAY.type:
          case paymentMethods.GOOGLE_PAY.type:
            return !!metadata?.supportsCreditCardPartialRefund;
          default:
            return false;
        }
      }
    },

    watch: {
      maxRefundToggle: 'toggleMaxRefundAmount'
    },

    created() {
      this.onCreated();
    },

    methods: {
      async onCreated() {
        await this.fetchPaymentGateways();

        if (!this.partialRefundAllowedForPayment) {
          this.toggleMaxRefundAmount(true);
        }
      },

      async fetchPaymentGateways() {
        try {
          await PaymentGateway.fetchPaymentGateways();
        }
        catch (error) {
          this.$_onRequestError({
            toastOptions: { message: 'Unable to fetch payment providers' },
            error
          });
        }
      },

      confirmRefund() {
        this.$buefy.dialog.confirm({
          title: 'Confirm Refund Transaction',
          message: `Are you sure you want to refund $${this.refundAmount} for this transaction?`,
          confirmText: 'Refund',
          type: 'is-danger',
          onConfirm: this.refundTransaction
        });
      },

      toggleMaxRefundAmount(isToggled) {
        // NOTE: this makes sure we properly format the money to look like money when we set it in the input
        this.refundAmount = isToggled ? currency(this.remainingRefundAmount).toString() : null;
      },

      getPaymentGatewayMetadata() {
        // NOTE: CardfreeInternal is really a test gateway
        const filteredGateways = this.$_selectedMerchant.merchantPaymentGateways
          .filter(mpg => mpg.paymentGateway.name !== 'CardFreeInternal' && mpg.isActive);
        const findGatewayForPaymentType = gateway => gateway.paymentMethods.find(pm => pm.name === this.transaction.paymentMethod);

        const gatewayForType = filteredGateways.find(mpg => mpg.storeId === this.transaction.storeId && findGatewayForPaymentType(mpg))
          || filteredGateways.find(mpg => !mpg.storeId && findGatewayForPaymentType(mpg));


        const paymentGatewayOption = this.allPaymentGatewayOptions.find(pg => pg.id === gatewayForType?.paymentGateway?.id);

        return paymentGatewayOption?.metaData;
      },

      async refundTransaction() {
        try {
          await PaymentTransaction.refund({ id: this.transaction.id, amount: this.refundAmount });

          this.$_onRequestSuccess({
            toastOptions: {
              message: 'Successfully refunded the transaction'
            },
            options: { closeParent: true, emit: { name: 'successful-refund' } }
          });
        }
        catch (error) {
          this.$_onRequestError({
            error,
            toastOptions: {
              message: 'There was an error refunding the transaction'
            }
          });
        }
      }
    }
  };
</script>

<style lang='sass' scoped>
  .modal-card
    max-width: 450px
    border-radius: $radius

  .order-amounts
    dl > div
      display: flex
      justify-content: space-between

      dt
        color: $grey

        .sub-text
          margin-top: -3px
      dd
        font-weight: 600
        color: $dark

      &:not(:last-child)
        margin-bottom: 0.5rem
</style>
