<template>
  <div class="card" data-test-id="activity-step">
    <div class="card-content">
      <validated-input
        class="activity-input"
        name="activityType-inputs"
        rules="required"
        :custom-messages="{ required: 'You must select an activity type' }"
        label="What activity will this challenge track?"
        :backend-validation-errors="earnRuleErrors.awardPolicy"
      >
        <div class="is-grid col-4 gap-x-sm">
          <radio-button
            name="activityType"
            data-test-id="dollars-spent-input"
            :value="activityType"
            :native-value="awardPolicyTypes.SPECIFIED_ITEM_POINTS_PER_DOLLAR_SPENT.type"
            :disabled="dollarsSpentActivityTypeDisabled"
            @input="handleActivityTypeInput(awardPolicyTypes.SPECIFIED_ITEM_POINTS_PER_DOLLAR_SPENT.type)"
          >
            Dollars Spent
            <template #sublabel>Track how many dollars are spent. Example: Spend $20 and get $5 off</template>
          </radio-button>
          <radio-button
            name="activityType"
            data-test-id="item-count-input"
            :value="activityType"
            :disabled="countActivityTypesDisabled"
            :native-value="awardPolicyTypes.SPECIFIED_ITEM_POINTS_PER_COUNT.type"
            @input="handleActivityTypeInput(awardPolicyTypes.SPECIFIED_ITEM_POINTS_PER_COUNT.type)"
          >
            Item Count
            <template #sublabel>Track how many items are purchased. Example: Buy 3 drinks and get $5 off</template>
          </radio-button>
          <radio-button
            name="activityType"
            data-test-id="order-count-input"
            :value="activityType"
            :disabled="countActivityTypesDisabled"
            :native-value="awardPolicyTypes.SALES_TRANSACTION_FIXED_POINT.type"
            @input="handleActivityTypeInput(awardPolicyTypes.SALES_TRANSACTION_FIXED_POINT.type)"
          >
            Order Count
            <template #sublabel>Track how many orders are placed. Example: Place 2 orders and get $5 off</template>
          </radio-button>
          <radio-button
            name="activityType"
            data-test-id="order-count-input"
            :value="activityType"
            :disabled="countActivityTypesDisabled"
            :native-value="awardPolicyTypes.GIFT_CARD_POINTS_PER_DOLLAR_FUNDED.type"
            @input="handleActivityTypeInput(awardPolicyTypes.GIFT_CARD_POINTS_PER_DOLLAR_FUNDED.type)"
          >
            Gift Cards Funded
            <template #sublabel>Track dollars spent on gift cards. Example: Buy $50 worth gift cards and get a $5 gift card</template>
          </radio-button>
        </div>
      </validated-input>
    </div>

    <transition name="fade-down-slow" mode="in-out">
      <div
        v-if="activityType && [awardPolicyTypes.SPECIFIED_ITEM_POINTS_PER_COUNT.type, awardPolicyTypes.SALES_TRANSACTION_FIXED_POINT.type].includes(activityType)"
        class="card-content"
      >
        <validated-text-input
          :value="value.currency.name"
          data-test-id="currency-name-input"
          name="name"
          type="text"
          rules="required|max:255"
          :custom-messages="{ required: 'You must give your Count a name (255 characters max)' }"
          label="Count Name"
          sub-label="(must be lowercase - drink, latte, entree, etc.)"
          :disabled="readOnly || !value.currency.isDraft"
          :backend-validation-errors="currencyErrors.name"
          @input="handleCurrencyInput({ key: 'name', value: $event })"
        />
        <validated-text-input
          :value="value.currency.pluralName"
          data-test-id="plural-name-input"
          name="pluralName"
          type="text"
          rules="required|max:255"
          :custom-messages="{ required: 'You must give your Count a plural name (255 characters max)' }"
          label="Count Name Plural"
          sub-label="(must be lowercase - drinks, lattes, entrees, etc.)"
          :disabled="readOnly || !value.currency.isDraft"
          :backend-validation-errors="currencyErrors.pluralName"
          @input="handleCurrencyInput({ key: 'pluralName', value: $event })"
        />
      </div>
    </transition>

    <transition name="fade-down-slow" mode="in-out">
      <div
        v-if="activityType && [awardPolicyTypes.SPECIFIED_ITEM_POINTS_PER_DOLLAR_SPENT.type, awardPolicyTypes.SPECIFIED_ITEM_POINTS_PER_COUNT.type].includes(activityType)"
        class="card-content"
      >
        <specified-item-inputs
          data-test-id="specified-item-component"
          :value="value.earnRule"
          :whitelisted-inputs="['earningItemBasisType']"
          :read-only="readOnly || !value.earnRule.isDraft"
          :handlers="{}"
        />
      </div>
    </transition>

    <transition name="fade-down-slow" mode="in-out">
      <div
        v-if="activityType && activityType === awardPolicyTypes.GIFT_CARD_POINTS_PER_DOLLAR_FUNDED.type"
        class="card-content"
      >
        <points-per-dollar-inputs
          data-test-id="points-per-dollar-component"
          :value="value.earnRule"
          :read-only="readOnly || !value.earnRule.isDraft"
          :handlers="{}"
        />
      </div>
    </transition>

    <earn-rule-run-limit-inputs
      v-model="value.earnRule.earnRuleRunLimitPolicies"
      :read-only="readOnly || !value.earnRule.isDraft"
      :subtitle="'Limit how many times a user can do this activity for the challenge. By default, there is no limit. To change this add Run Limits below.'"
      hide-message
    />
    <earn-rule-constraint-inputs
      v-model="value.earnRule"
      :read-only="readOnly"
    />
  </div>
</template>

<script>
  import Currency from '@/store/classes/Currency';
  import { awardPolicyTypes, engagementTypes, refundModes } from '@/constants/earnRules';

  import EarnRuleRunLimitInputs from '../../run-limit-policy-inputs/earn-rule-run-limit-inputs.vue';
  import EarnRuleConstraintInputs from '../../earn-rule-constraint-inputs/earn-rule-constraint-inputs.vue';
  import SpecifiedItemInputs from '../../award-policy-inputs/specified-item-inputs.vue';
  import PointsPerDollarInputs from '../../award-policy-inputs/points-per-dollar-inputs.vue';

  export default {
    name: 'ActivityStep',

    components: { SpecifiedItemInputs, EarnRuleRunLimitInputs, EarnRuleConstraintInputs, PointsPerDollarInputs },

    props: {
      value: {
        type: Object,
        required: true
      },

      readOnly: {
        type: Boolean,
        default: false
      },

      currencyErrors: {
        type: Object,
        required: true
      },

      earnRuleErrors: {
        type: Object,
        required: true
      }
    },

    data() {
      return {
        awardPolicyTypes
      };
    },

    computed: {
      activityType() {
        return this.value.earnRule.awardPolicy?.awardPolicyType;
      },

      dollarsSpentActivityTypeDisabled() {
        return this.readOnly || !this.value.earnRule.isDraft || (!this.value.currency.isDraft && this.value.currency.accumulationType === Currency.accumulationTypes.COUNT);
      },

      countActivityTypesDisabled() {
        return this.readOnly || !this.value.earnRule.isDraft || (!this.value.currency.isDraft && this.value.currency.accumulationType === Currency.accumulationTypes.SPEND);
      },

      currencyId() {
        const { id, publicId, isDraft } = this.value.currency;
        return isDraft ? id : publicId;
      },

      currencyTitle() {
        return this.value.currency.title;
      },

      dollarsSpentDefaults() {
        return {
          currency: {
            accumulationType: Currency.accumulationTypes.SPEND,
            name: 'dollar',
            shortName: 'dollar',
            pluralName: 'dollars',
            pluralShortName: 'dollars'
          },
          earnRule: {
            name: this.currencyTitle,
            engagementType: engagementTypes.SALES_TRANSACTION.id,
            awardPolicy: {
              awardPolicyType: awardPolicyTypes.SPECIFIED_ITEM_POINTS_PER_DOLLAR_SPENT.type,
              ...awardPolicyTypes.SPECIFIED_ITEM_POINTS_PER_DOLLAR_SPENT.defaultData(this.currencyId),
              refundMode: refundModes.DO_NOT_APPLY
            }
          }
        };
      },

      itemCountDefaults() {
        return {
          currency: {
            accumulationType: Currency.accumulationTypes.COUNT,
            name: '',
            shortName: '',
            pluralName: '',
            pluralShortName: ''
          },
          earnRule: {
            name: this.currencyTitle,
            engagementType: engagementTypes.SALES_TRANSACTION.id,
            awardPolicy: {
              awardPolicyType: awardPolicyTypes.SPECIFIED_ITEM_POINTS_PER_COUNT.type,
              ...awardPolicyTypes.SPECIFIED_ITEM_POINTS_PER_COUNT.defaultData(this.currencyId)
            }
          }
        };
      },

      orderCountDefaults() {
        return {
          currency: {
            accumulationType: Currency.accumulationTypes.COUNT,
            name: '',
            shortName: '',
            pluralName: '',
            pluralShortName: ''
          },
          earnRule: {
            name: this.currencyTitle,
            engagementType: engagementTypes.SALES_TRANSACTION.id,
            awardPolicy: {
              awardPolicyType: awardPolicyTypes.SALES_TRANSACTION_FIXED_POINT.type,
              ...awardPolicyTypes.SALES_TRANSACTION_FIXED_POINT.defaultData(this.currencyId)
            }
          }
        };
      },

      giftCardFundedDefaults() {
        return {
          currency: {
            accumulationType: Currency.accumulationTypes.COUNT,
            name: 'dollar funded',
            shortName: 'dollar funded',
            pluralName: 'dollars funded',
            pluralShortName: 'dollars funded'
          },
          earnRule: {
            name: this.currencyTitle,
            engagementType: engagementTypes.GIFT_CARD_PURCHASE.id,
            awardPolicy: {
              awardPolicyType: awardPolicyTypes.GIFT_CARD_POINTS_PER_DOLLAR_FUNDED.type,
              ...awardPolicyTypes.GIFT_CARD_POINTS_PER_DOLLAR_FUNDED.defaultData(this.currencyId)
            }
          }
        };
      }
    },

    methods: {
      setDefaults(defaults) {
        return {
          ...this.value,
          earnRule: {
            ...this.value.earnRule,
            ...defaults.earnRule
          },
          currency: {
            ...this.value.currency,
            ...defaults.currency
          }
        };
      },

      handleActivityTypeInput(type) {
        let defaultValues;
        switch (type) {
          case awardPolicyTypes.SPECIFIED_ITEM_POINTS_PER_DOLLAR_SPENT.type:
            defaultValues = this.dollarsSpentDefaults;
            break;
          case awardPolicyTypes.SPECIFIED_ITEM_POINTS_PER_COUNT.type:
            defaultValues = this.itemCountDefaults;
            break;
          case awardPolicyTypes.SALES_TRANSACTION_FIXED_POINT.type:
            defaultValues = this.orderCountDefaults;
            break;
          case awardPolicyTypes.GIFT_CARD_POINTS_PER_DOLLAR_FUNDED.type:
            defaultValues = this.giftCardFundedDefaults;
            break;
          default:
            break;
        }
        const updatedValue = this.setDefaults(defaultValues);
        this.$emit('input', updatedValue);
      },

      handleCurrencyInput({ key, value }) {
        const additionalUpdate = key === 'name' ? { shortName: value } : { pluralShortName: value };
        this.$emit('input', {
          ...this.value,
          currency: {
            ...this.value.currency,
            [key]: value,
            ...additionalUpdate
          }
        });
      }
    }
  };
</script>

<style lang="sass" scoped>
  .activity-type
    ::v-deep
      .label span
        font-size: $size-5
        font-weight: normal

  .card-content:nth-child(even)
    border-top: 1px solid $grey-lightest
    background-color: $white-ter !important

    &:not(:last-child)
      border-bottom: 1px solid $grey-lightest
</style>
