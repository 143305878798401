<template>
  <validated-form
    ref="form"
    v-entrap-focus
    form-id="passwordResetForm"
    :track-changes="false"
    @valid-submit="handleSubmit"
  >
    <div class="mar-b-lg">
      <validation-observer>
        <validated-text-input
          v-model="password"
          :bails="!password"
          label="Password"
          name="newPassword"
          type="password"
          :rules="{
            required: true,
            min: 8,
            specialChars: true,
            confirmPassword: 'newPasswordConfirmation'
          }"
          :has-icon="false"
          password-reveal
        />
        <validated-text-input
          v-model="passwordConfirmation"
          label="Password Confirmation"
          name="newPasswordConfirmation"
          type="password"
          :has-icon="false"
          password-reveal
        />
      </validation-observer>
    </div>

    <b-button
      rounded
      native-type="submit"
      type="is-primary"
      size="is-medium"
      class="is-fullwidth"
      :loading="isSubmitting"
    >
      Submit
    </b-button>
  </validated-form>
</template>



<script>
  import User from '@/store/classes/User';


  export default {
    name: 'PasswordForm',

    props: {
      resetPasswordToken: {
        type: String,
        required: true
      }
    },

    data() {
      return {
        password: null,
        passwordConfirmation: null
      };
    },

    computed: {
      isSubmitting() {
        return User.$state().submitting;
      }
    },

    methods: {
      async handleSubmit() {
        try {
          await User.resetPassword({
            resetPasswordToken: this.resetPasswordToken,
            password: this.password,
            autoSignIn: true
          });

          this.$_onRequestSuccess({
            toastOptions: {
              message: 'Your new password has been successfully saved'
            },
            options: {
              redirectTo: { name: 'home' }
            }
          });
        }

        catch (error) {
          this.$_onRequestError({
            toastOptions: {
              message: 'There was an error attempting to save your new password'
            },
            options: {
              backendError: error.data.errors?.user
            },
            error
          });
        }
      }
    }
  };
</script>
