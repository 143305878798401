var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"is-grid gap col-2"},[_c('div',[_c('validation-provider',{attrs:{"name":"discountedItems","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-checkbox',{class:['is-hidden', { 'invalid': errors.length }],attrs:{"value":_vm.selectedResources.length >= 1 || null}}),_c('b-field',{attrs:{"message":errors.length ? 'You must have at least 1 menu item selected' : '',"type":errors.length ? 'is-danger' : ''}},[_c('menu-search-input',{attrs:{"read-only":_vm.readOnly,"resource-type":"menuItems","selected-resources":_vm.selectedResources,"multiple":""},on:{"add-resource":function($event){return _vm.$emit('add-resource', _vm.merchantSupportsMultiPos ? $event : { type: 'menuItems', resource: $event })},"remove-resource":function($event){return _vm.$emit('remove-resource', _vm.merchantSupportsMultiPos ? $event : { type: 'menuItems', resource: $event })}}})],1)]}}])}),(_vm.enableModifierRestrictions)?_c('modifier-restrictions-search',{attrs:{"read-only":_vm.readOnly,"selected-resources":_vm.selectedResources,"required-modifications":_vm.requiredModifications},on:{"update-required-modifications":function($event){return _vm.$emit('update-required-modifications', $event)}}}):_vm._e()],1),_c('div',{staticClass:"is-flex-wrap start-aligned align-content-start gap"},[_vm._l((_vm.selectedResources),function(resource){return _c('div',{key:_vm.generateResourceKey(resource),staticClass:"tag is-primary is-light auto-height mar-none pad-y-xs pad-x-sm"},[_c('div',[_c('p',{staticClass:"has-text-weight-bold"},[_vm._v(_vm._s(resource.displayName))]),(_vm.merchantSupportsMultiPos)?_c('p',{staticClass:"has-text-primary-dark"},[_vm._v(" "+_vm._s(resource.id)+"-"+_vm._s(resource.posType)+" "),(resource.posItemIds && resource.posItemIds.length > 1)?_c('b-icon',{directives:[{name:"tippy",rawName:"v-tippy",value:({
              content: resource.posItemIds.join(', '),
              maxWidth: 250,
              delay: [150, 0]
            }),expression:"{\n              content: resource.posItemIds.join(', '),\n              maxWidth: 250,\n              delay: [150, 0]\n            }"}],staticClass:"mar-l-xs",attrs:{"icon":"tags","size":"is-small"}}):_vm._e()],1):(resource.posItems && resource.posItems.length)?_c('p',[_vm._v(" "+_vm._s(resource.posItems[0].id)+"-"+_vm._s(_vm.posTypesById[resource.posItems[0].posTypeId])+" "),(resource.posItems.length > 1)?_c('b-icon',{directives:[{name:"tippy",rawName:"v-tippy",value:({
              content: resource.posItems.map(function (posItem) { return posItem.id; }).join(', '),
              maxWidth: 250,
              delay: [150, 0]
            }),expression:"{\n              content: resource.posItems.map(posItem => posItem.id).join(', '),\n              maxWidth: 250,\n              delay: [150, 0]\n            }"}],staticClass:"mar-l-xs",attrs:{"icon":"tags","size":"is-small"}}):_vm._e()],1):_vm._e()]),(!_vm.readOnly)?_c('button',{staticClass:"delete mar-none mar-l-sm has-background-primary-dark has-hover-background-primary-medium",attrs:{"type":"button"},on:{"click":function($event){return _vm.handleRemoveResource(resource)}}}):_vm._e()])}),_vm._l((_vm.missingResources),function(resourceId){return _c('div',{key:resourceId,staticClass:"tag is-danger is-flex-align-center is-light auto-height mar-none pad-y-sm pad-x-sm"},[_c('div',[_c('p',{directives:[{name:"tippy",rawName:"v-tippy",value:({
            content: 'This menu item cannot be found',
            maxWidth: 250,
            delay: [150, 0]
          }),expression:"{\n            content: 'This menu item cannot be found',\n            maxWidth: 250,\n            delay: [150, 0]\n          }"}],staticClass:"align-self-start has-text-weight-bold"},[_vm._v(" Unknown menu resource ")])])])})],2)])}
var staticRenderFns = []

export { render, staticRenderFns }