<template>
  <div>
    <template v-if="isChanged">
      <span class="is-inline-block has-text-weight-semibold" :class="['has-text-danger', movedUp ? 'is-down' : 'is-up']">
        {{ oldSortOrder }}
      </span>
      <b-icon
        :class="movedDown ? 'is-arrow-down' : 'is-arrow-up'"
        class="has-text-grey-dark"
        icon="long-arrow-alt-right"
        pack="far"
        size="is-small"
      />
    </template>
    <span
      :class="[
        'is-inline-block',
        {
          'is-down': movedDown,
          'is-up': movedUp,
          'is-same': isPendingChanges && !isChanged,
          'has-text-weight-semibold': isPendingChanges,
          'has-text-success': isChanged
        }
      ]"
    >
      {{ newSortOrder }}
    </span>
  </div>
</template>

<script>
  export default {
    name: 'SortOrderTableCell',

    props: {
      isPendingChanges: {
        type: Boolean,
        required: true
      },

      newSortOrder: {
        type: Number,
        required: true
      },

      oldSortOrder: {
        type: Number,
        required: true
      }
    },

    computed: {
      isChanged: {
        get() {
          return this.newSortOrder !== this.oldSortOrder;
        }
      },

      movedDown: {
        get() {
          return this.newSortOrder > this.oldSortOrder;
        }
      },

      movedUp: {
        get() {
          return this.newSortOrder < this.oldSortOrder;
        }
      }
    }
  };
</script>

<style lang="sass" scoped>
  .is-same
    transform: translateX(1rem)

  .is-up,
  .is-down,
  .is-same,
  .is-arrow-up,
  .is-arrow-down
    transition: 500ms !important

  .is-up,
  .is-down
    padding: 0 4px
  .is-up
    transform: translateY(-38%)
  .is-down
    transform: translateY(38%)

  .is-arrow-up,
  .is-arrow-down
    display: inline-flex
    justify-content: center
    align-items: center
  .is-arrow-up
    transform: rotate(-33deg)
  .is-arrow-down
    transform: rotate(33deg)
</style>
