const expoStatuses = {
  UPCOMING: 'Upcoming',
  ACTIVE: 'Active',
  PAST: 'Past'
};

const orderTypes = {
  LARGE_ORDER: 'Large Order',
  CUSTOMER_PICKUP: 'Customer Pickup',
  DELIVERY: 'Delivery'
};

export {
  expoStatuses,
  orderTypes
};
