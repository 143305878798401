/* eslint-disable import/no-cycle */
import { Model } from '@vuex-orm/core';
import http from '@/services/http';
import { camelCase, capitalCase } from 'change-case';
import storeMappingAttributes from '@/constants/storeMappingAttributes';



export default class FulfillmentType extends Model {
  static entity = 'fulfillmentTypes'

  static fields() {
    return {
      id: this.attr(''),
      description: this.attr(''),
      name: this.attr('')
    };
  }

  static byName() {
    return this.all().reduce((acc, item) => {
      acc[camelCase(item.name)] = item;
      return acc;
    }, {});
  }

  static formattedName(name) {
    let formattedName = capitalCase(name);
    switch (name) {
      case 'Curbside':
        formattedName = 'Curbside Pickup';
        break;
      case 'Takeout':
        formattedName = 'Onsite Pickup';
        break;
      case 'DriveThrough':
        formattedName = 'Drive Through Pickup / Window';
        break;
      default:
        break;
    }

    return formattedName;
  }

  static merchantSupported(merchant, stores) {
    return this.getFulfillmentTypesById(this.getEnabledFulfillmentTypeIds(merchant, stores));
  }

  static getEnabledFulfillmentTypeIds(merchant, stores) {
    const findStoreMappingAttribute = (attrs, constant) => attrs.find(attr => attr.code === constant);
    const {
      patEnabled,
      orderAheadEnabled,
      roomServiceEnabled,
      deliveryEnabled,
      shippingEnabled
    } = merchant;
    const merchantFullfilmentTypesMapping = [
      { fulfillmentTypeId: 1, code: storeMappingAttributes.DINE_IN.code, enabled: (patEnabled || orderAheadEnabled) },
      { fulfillmentTypeId: 2, code: storeMappingAttributes.TAKE_OUT.code, enabled: orderAheadEnabled },
      { fulfillmentTypeId: 3, code: storeMappingAttributes.CURBSIDE.code, enabled: orderAheadEnabled },
      { fulfillmentTypeId: 4, code: storeMappingAttributes.DRIVE_THROUGH.code, enabled: orderAheadEnabled },
      { fulfillmentTypeId: 5, code: storeMappingAttributes.DELIVERY.code, enabled: deliveryEnabled },
      { fulfillmentTypeId: 6, code: storeMappingAttributes.ROOM_SERVICE.code, enabled: roomServiceEnabled },
      { fulfillmentTypeId: 7, code: storeMappingAttributes.SHIPPING.code, enabled: shippingEnabled }
    ];

    return merchantFullfilmentTypesMapping.reduce((acc, type) => {
      const enabled = type.enabled && stores.find(s => !!findStoreMappingAttribute(s.storeMappingAttributes, type.code));

      if (enabled) {
        acc.push(Number(type.fulfillmentTypeId));
      }
      return acc;
    }, []);
  }

  static getFulfillmentTypesById(typeIds) {
    return this.query().where(ft => typeIds.includes(ft.id)).get();
  }



  // STATE //////////////////////

  static state() {
    return {
      fetching: false
    };
  }

  static $state() {
    return this.store().state.entities.fulfillmentTypes;
  }


  // ACTIONS //////////////////////

  static async fetchFulfillmentTypes() {
    try {
      this.commit((state) => {
        state.fetching = true;
      });

      const alreadyFetched = this.query().where('fulfillmentTypes').exists();

      if (!alreadyFetched) {
        const { data } = await http.get('fulfillment_types');

        this.create({ data: data.fulfillmentTypes });
      }
    }
    catch (error) {
      throw error;
    }
    finally {
      this.commit((state) => {
        state.fetching = false;
      });
    }
  }
}
