var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('validated-text-input',{attrs:{"has-icon":false,"label":"Name","name":"name","maxlength":"50","has-counter":true,"rules":"required","type":"text","disabled":_vm.readOnly},model:{value:(_vm.value.displayName),callback:function ($$v) {_vm.$set(_vm.value, "displayName", $$v)},expression:"value.displayName"}}),(_vm.value.type === _vm.modifierGroupTypes.PORTION && _vm.isNewModifierGroup)?_c('validated-input',{attrs:{"name":"portionTypes","rules":"required","label":"Portion Types","sub-label":("Select the portion types you would like to include in your menu for " + (_vm.item ? _vm.item.displayName : 'this modifier group'))}},[_c('div',{staticClass:"is-grid col-2 gap-md"},_vm._l((_vm.modifierGroupPortionTemplates),function(template){return _c('check-button',{key:template.constant,attrs:{"native-value":template.constant,"left-label":"","justify-between":"","disabled":_vm.readOnly},model:{value:(_vm.value.portionVariations),callback:function ($$v) {_vm.$set(_vm.value, "portionVariations", $$v)},expression:"value.portionVariations"}},[_c('div',{staticClass:"portion-type-image mar-r-sm pad-xs is-flex"},[_c('img',{staticClass:"mar-none",attrs:{"alt":("" + (template.display)),"src":_vm.getPortionTemplateTypeIcon(template.constant)}})]),_c('span',{staticClass:"mar-r template-name"},[_vm._v(_vm._s(template.display))])])}),1)]):_vm._e(),(_vm.value.type !== _vm.modifierGroupTypes.PORTION)?[_c('hr'),_c('p',{staticClass:"subtitle is-5 is-marginless"},[_vm._v(" Modifier Selections ")]),_c('p',{staticClass:"is-size-7 has-text-grey mar-b-md"},[_vm._v(" How many different modifiers can or must be selected from this group? ")]),_c('validated-input',{staticClass:"left-aligned-label",attrs:{"horizontal":"","label":"Min. Required","name":"minRequired","rules":{
        required: true,
        max_value: _vm.isNewModifierGroup ? 10000 : _vm.modifierCount
      }}},[_c('b-numberinput',{attrs:{"editable":false,"controls-position":"compact","min":_vm.quantityRules.minRequired.min,"max":_vm.quantityRules.minRequired.max,"disabled":_vm.readOnly},model:{value:(_vm.value.minRequired),callback:function ($$v) {_vm.$set(_vm.value, "minRequired", $$v)},expression:"value.minRequired"}})],1),_c('validated-input',{staticClass:"left-aligned-label",attrs:{"horizontal":"","label":"Max. Allowed","name":"maxAllowed","rules":{
        required: true,
        min_value: _vm.hasMax ? _vm.value.minRequired : -1
      }}},[_c('numberinput-switch',{attrs:{"switch-label":"Unlimited","disabled":_vm.readOnly || !_vm.$can('update', 'MenuItemModifierGroup') && !_vm.$_menuPermissions.EDIT_RESOURCE,"true-value":-1,"false-value":_vm.value.minRequired > _vm.modifierCount ? _vm.value.minRequired : _vm.modifierCount,"min":_vm.quantityRules.maxAllowed.min,"max":_vm.quantityRules.maxAllowed.max},model:{value:(_vm.value.maxAllowed),callback:function ($$v) {_vm.$set(_vm.value, "maxAllowed", $$v)},expression:"value.maxAllowed"}})],1),_c('hr'),_c('p',{staticClass:"subtitle is-5 is-marginless"},[_vm._v(" Group Quantity ")]),_c('p',{staticClass:"is-size-7 has-text-grey mar-b-md"},[_vm._v(" What total quantity of modifiers can or must be selected across all available modifiers in this group? ")]),_c('validated-input',{staticClass:"left-aligned-label",attrs:{"horizontal":"","label":"Min. Required","name":"minRequired","rules":{
        required: true,
        max_value: _vm.value.maxModifierQtyAllowed === -1 ? 10000 : _vm.value.maxModifierQtyAllowed
      }}},[_c('b-numberinput',{attrs:{"editable":false,"controls-position":"compact","min":_vm.quantityRules.minModifierQtyRequired.min,"max":_vm.quantityRules.minModifierQtyRequired.max,"disabled":_vm.readOnly},model:{value:(_vm.value.minModifierQtyRequired),callback:function ($$v) {_vm.$set(_vm.value, "minModifierQtyRequired", $$v)},expression:"value.minModifierQtyRequired"}})],1),_c('validated-input',{staticClass:"left-aligned-label",attrs:{"horizontal":"","label":"Max. Allowed","name":"maxAllowed","rules":{
        required: true,
        min_value: _vm.value.minModifierQtyRequired || -1
      }}},[_c('numberinput-switch',{attrs:{"switch-label":"Unlimited","disabled":_vm.readOnly || !_vm.$can('update', 'MenuItemModifierGroup') && !_vm.$_menuPermissions.EDIT_RESOURCE,"true-value":-1,"false-value":_vm.value.minModifierQtyRequired ? _vm.value.minModifierQtyRequired : 1,"min":_vm.quantityRules.maxModifierQtyAllowed.min,"max":_vm.quantityRules.maxModifierQtyAllowed.max},model:{value:(_vm.value.maxModifierQtyAllowed),callback:function ($$v) {_vm.$set(_vm.value, "maxModifierQtyAllowed", $$v)},expression:"value.maxModifierQtyAllowed"}})],1)]:_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }