import daysOfWeek from '@/constants/daysOfWeek';
import { itemAttributes } from '@/constants/items';

import ItemGroup from '@/helpers/classes/ItemGroup';

const _ = require('lodash');


const earningItemBases = {
  MAIN_MENU_ITEM: {
    id: 1,
    display: 'Main Menu Item'
  },
  MAIN_MENU_ITEM_ALL_MODS: {
    id: 2,
    display: 'Main Menu Item and All Modifiers'
  },
  MAIN_MENU_ITEM_MATCHING_MODS: {
    id: 3,
    display: 'Main Menu Item and Matching Modifiers'
  }
};

const randomAwardOptionTypes = {
  POINTS: 'PointAwardOptionModel',
  OFFER: 'OfferAwardOptionModel'
};

const memberEligibilityPolicyTypes = {
  ALL_MEMBERS: {
    key: 'ALL_MEMBERS',
    type: 'AllMembersEligibilityPolicyModel',
    display: 'All Members',
    defaultData: {
      memberEligibilityPolicyType: 'AllMembersEligibilityPolicyModel',
      includeImportedMembers: true
    }
  },
  MEMBER_TIER: {
    key: 'MEMBER_TIER',
    type: 'MemberTierEligibilityPolicyModel',
    display: 'Specific Tiers'
  }
};

const randomAwardOptionTypesDefaultData = {
  POINTS: currencyPublicId => ({
    awardOptionType: randomAwardOptionTypes.POINTS,
    points: 1,
    currencyPublicId
  }),
  OFFER: {
    awardOptionType: randomAwardOptionTypes.OFFER,
    reward: {
      cardfreeOfferPublicId: ''
    }
  }
};

const weightedAwardOptionsDefaultData = [
  {
    awardOption: _.cloneDeep(randomAwardOptionTypesDefaultData.OFFER),
    weight: 1
  },
  {
    awardOption: _.cloneDeep(randomAwardOptionTypesDefaultData.OFFER),
    weight: 1
  }
];

const awardPolicyTypes = {
  // Enrollment award policy types
  ENROLLMENT_BONUS_POINT: {
    id: 3,
    type: 'EnrollmentBonusPointAwardPolicyModel',
    display: 'Points earned on enrollment',
    defaultData: currencyPublicId => ({
      points: 1,
      currencyPublicId
    })
  },
  ENROLLMENT_BONUS_OFFER: {
    id: 4,
    type: 'EnrollmentBonusOfferAwardPolicyModel',
    display: 'Reward earned on enrollment',
    defaultData: {
      cardfreeOfferPublicId: ''
    }
  },
  // Birthday award policy types
  BIRTHDAY_POINT: {
    id: 5,
    type: 'BirthdayPointAwardPolicyModel',
    display: 'Points earned on birthday',
    defaultData: currencyPublicId => ({
      points: 1,
      currencyPublicId
    })
  },
  BIRTHDAY_OFFER: {
    id: 6,
    type: 'BirthdayOfferAwardPolicyModel',
    display: 'Reward earned on birthday',
    defaultData: {
      cardfreeOfferPublicId: ''
    }
  },
  // Referral award policy types
  REFERRAL_CONVERTED_POINT: {
    id: 8,
    type: 'ReferralConvertedPointAwardPolicyModel',
    display: 'Points granted for referral conversion',
    defaultData: currencyPublicId => ({
      points: 1,
      currencyPublicId
    })
  },
  REFERRAL_CONVERTED_OFFER: {
    id: 9,
    type: 'ReferralConvertedOfferAwardPolicyModel',
    display: 'Reward granted for referral conversion',
    defaultData: {
      cardfreeOfferPublicId: ''
    }
  },
  // Sales Transaction types
  CHECK_HEADER_POINT: {
    id: 1,
    type: 'CheckHeaderPointAwardPolicyModel',
    display: 'Points per $1 spent',
    defaultData: currencyPublicId => ({
      pointsPerDollarSpent: 1,
      excludedItemAttributes: Object.values(itemAttributes).map(itemAttr => itemAttr.id),
      refundMode: 0, // 0 === "Unknown"; Will inherit program-level refund setting in loyalty
      currencyPublicId
    })
  },
  CHECK_HEADER_OFFER: {
    id: 2,
    type: 'CheckHeaderOfferAwardPolicyModel',
    display: 'Reward',
    defaultData: {
      cardfreeOfferPublicId: ''
    }
  },
  SALES_TRANSACTION_FIXED_POINT: {
    id: 7,
    type: 'SalesTransactionFixedPointAwardPolicyModel',
    display: 'Fixed amount of points',
    defaultData: currencyPublicId => ({
      points: 1,
      currencyPublicId
    })
  },
  SPECIFIED_ITEM_POINTS_PER_DOLLAR_SPENT: {
    id: 10,
    type: 'SpecifiedItemPointsPerDollarSpentPointAwardPolicyModel',
    display: 'Points per $1 spent for an item group',
    defaultData: currencyPublicId => ({
      currencyPublicId,
      pointsPerDollarSpent: 1,
      maximumPointsPerSalesTransaction: null,
      maximumEarningQuantity: null,
      earningItemBasisType: earningItemBases.MAIN_MENU_ITEM.id,
      itemGroup: new ItemGroup()
    })
  },
  SPECIFIED_ITEM_POINTS_PER_COUNT: {
    id: 11,
    type: 'SpecifiedItemPointsPerCountPointAwardPolicyModel',
    display: 'Points per count for an item group',
    defaultData: currencyPublicId => ({
      currencyPublicId,
      qualifyingCountIncrement: 1,
      pointsPerQualifyingCount: 1,
      maximumPointsPerSalesTransaction: 1,
      earningItemBasisType: earningItemBases.MAIN_MENU_ITEM.id,
      itemGroup: new ItemGroup()
    })
  },
  SALES_TRANSACTION_RANDOM: {
    id: 12,
    type: 'SalesTransactionRandomAwardPolicyModel',
    display: 'Random',
    defaultData: {
      weightedAwardOptions: [...weightedAwardOptionsDefaultData]
    }
  },
  // Member Proximity types
  MEMBER_PROXIMITY_OFFER: {
    id: 13,
    type: 'MemberProximityOfferAwardPolicyModel',
    display: 'Reward',
    defaultData: {
      cardfreePublicId: ''
    }
  },
  // Gift Card Funding types
  GIFT_CARD_POINTS_PER_DOLLAR_FUNDED: {
    id: 14,
    type: 'GiftCardPointsPerDollarFundedPointAwardPolicyModel',
    display: 'Points per $1 spent funding gift card',
    defaultData: currencyPublicId => ({
      pointsPerDollarSpent: 1,
      currencyPublicId
    })
  }
};

const constraintModels = {
  // Time frame constraintModels
  DAY_OF_WEEK: {
    type: 'DayOfWeekEarnRuleConstraintModel',
    display: 'Day of Week',
    defaultData: {
      validDays: Object.values(daysOfWeek)
    }
  },
  TIME_OF_DAY: {
    type: 'TimeOfDayEarnRuleConstraintModel',
    display: 'Time of Day',
    defaultData: {
      startTime: null,
      endTime: null
    }
  },
  // Birthday constraintModels
  BIRTHDAY_REQUIRED_PURCHASE: {
    type: 'BirthdayRequiredPurchaseEarnRuleConstraintModel',
    display: 'Previous Purchase',
    subLabel: 'Require the customer to make a purchase in order to get their birthday award',
    defaultData: {}
  },
  // Sales transaction constraintModels
  FULFILLMENT_TYPE: {
    type: 'FulfillmentTypeEarnRuleConstraintModel',
    display: 'Order Mode',
    subLabel: 'Require the customer to use a specific order mode. Check the order mode(s) that apply.',
    defaultData: {
      validFulfillmentTypes: []
    }
  },
  MAXIMUM_ORDER_AGE: {
    type: 'MaximumOrderAgeConstraintModel',
    display: 'Max Order Age',
    subLabel: 'Specify the maximum age of an order that qualifies',
    defaultData: {
      applyOnlyToRetroClaims: false,
      maximumHourlyDuration: 1
    }
  },
  MENU_TYPE: {
    type: 'MenuTypeEarnRuleConstraintModel',
    display: 'Menu Type',
    subLabel: 'Check the menu type(s) that this earn rule can be applied to',
    defaultData: {
      validMenuTypes: []
    }
  },
  MINIMUM_PURCHASE: {
    type: 'MinimumPurchaseEarnRuleConstraintModel',
    display: 'Minimum Purchase',
    subLabel: 'Require the customer to make a minimum purchase',
    defaultData: {
      minimum: 1,
      excludedItemAttributes: Object.values(itemAttributes).map(itemAttr => itemAttr.id),
      refundMode: 0 // 0 === "Unknown"; Will inherit program-level refund setting in loyalty
    }
  },
  PAYMENT_METHOD: {
    type: 'PaymentMethodEarnRuleConstraintModel',
    display: 'Payment Method',
    subLabel: 'Require the customer to use a specific payment method',
    defaultData: {
      paymentMethods: [],
      minimumPaymentAmountFromSelectedMethods: 1,
      waiveMinimumAmountIfZeroingGiftCardBalance: false
    }
  },
  RETRO_CLAIM: {
    type: 'RetroClaimEarnRuleConstraintModel',
    display: 'Retroactive Claim',
    subLabel: 'Only apply to retroactive claims',
    defaultData: {
      isRetroClaim: true
    }
  },
  SPECIFIED_ITEM_GROUP: {
    type: 'SpecifiedItemGroupEarnRuleConstraintModel',
    display: 'Item Purchase',
    subLabel: 'Require the customer to purchase a specific item',
    // Since there can be multiple of this constraint type, we need to create a new item group each time
    defaultData: () => ({
      itemGroup: new ItemGroup(),
      minimumQualifyingCount: 1
    })
  },
  SALES_TRANSACTION_LOCATION: {
    type: 'SalesTransactionLocationEarnRuleConstraintModel',
    display: 'Location',
    subLabel: 'Require the customer to go to a particular location',
    defaultData: {
      storeIds: []
    }
  },
  // Referral constraintModels
  REFEREE_REQUIRED_PURCHASE: {
    type: 'RefereeRequiredPurchaseEarnRuleConstraintModel',
    display: 'Place Order',
    subLabel: 'Required the referee to place an order for the referrer to get the award',
    defaultData: {
      numberOfQualifyingSalesTransactions: 1, // Loyalty supports more than one, but we limiting this to 1 for now and this value will not be editable in the UI
      minimumSalesTransactionTotal: 0,
      minimumSalesTransactionItemTotalBeforeDiscounts: 0
    }
  },
  REFEREE_VERIFICATION: {
    type: 'RefereeVerificationEarnRuleConstraintModel',
    display: 'Referee Verification',
    subLabel: 'Require the referee to verify their account for the referrer to get the award',
    defaultData: {}
  },
  // Member Proximity constraintModels
  MEMBER_PROXIMITY_LOCATION: {
    type: 'MemberProximityLocationEarnRuleConstraintModel',
    display: 'Location',
    subLabel: 'Require the customer to go to a particular location',
    defaultData: {
      storeIds: [],
      allowActiveOrderAtAnyTargetLocation: false
    }
  }
};

const engagementTypes = {
  MEMBER_ENROLLED: {
    type: 'MemberEnrolledEngagementReferenceModel',
    id: 1,
    display: 'Member Enrolled'
  },
  SALES_TRANSACTION: {
    type: 'SalesTransactionCompletedEngagementReferenceModel',
    id: 2,
    display: 'Sales Transaction'
  },
  BIRTHDAY: {
    type: 'BirthdayEngagementReferenceModel',
    id: 3,
    display: 'Birthday'
  },
  REFERRAL_CONVERTED: {
    type: 'ReferralConvertedEngagementReferenceModel',
    id: 8,
    display: 'Referral Converted'
  },
  MEMBER_PROXIMITY: {
    type: 'MemberProximityEngagementReferenceModel',
    id: 11,
    display: 'Member Proximity'
  },
  GIFT_CARD_PURCHASE: {
    type: 'GiftCardFundedEngagementReferenceModel',
    id: 14,
    display: 'Gift Card Purchase'
  }
};

const refundModes = {
  UNKNOWN: 0,
  PRORATED: 1,
  NON_EARNING_FIRST: 2,
  DO_NOT_APPLY: 3
};

const earnRuleStatuses = {
  DRAFT: 'draft', // FE only status
  SCHEDULED: 'scheduled',
  ACTIVE: 'active',
  FUTURE: 'future',
  EXPIRING: 'expiring',
  EXPIRED: 'expired',
  INVALID: 'invalid' // FE only status
};

const runLimitPolicyTypes = {
  DAY: {
    display: 'Day',
    type: 'DayEarnRuleRunLimitPolicyModel'
  },
  WEEK: {
    display: 'Week',
    type: 'WeekEarnRuleRunLimitPolicyModel'
  },
  MONTH: {
    display: 'Month',
    type: 'MonthEarnRuleRunLimitPolicyModel'
  },
  QUARTER: {
    display: 'Quarter',
    type: 'QuarterEarnRuleRunLimitPolicyModel'
  },
  YEAR: {
    display: 'Year',
    type: 'YearEarnRuleRunLimitPolicyModel'
  },
  LIFETIME: {
    display: 'Lifetime',
    type: 'LifetimeEarnRuleRunLimitPolicyModel'
  }
};

export {
  awardPolicyTypes,
  constraintModels,
  earningItemBases,
  engagementTypes,
  earnRuleStatuses,
  memberEligibilityPolicyTypes,
  randomAwardOptionTypes,
  randomAwardOptionTypesDefaultData,
  runLimitPolicyTypes,
  refundModes,
  weightedAwardOptionsDefaultData
};
