/* eslint-disable import/no-cycle */
import { Model } from '@vuex-orm/core';

// classes
import CategoryItem from './CategoryItem';
import Item from './Item';
import MenuCategoryUpsell from './MenuCategoryUpsell';

// import MenuType from './MenuType';

import http from '@/services/http';
import { setUpdatedSortOrders } from '@/helpers/set-new-sort-orders';



export default class Category extends Model {
  static entity = 'categories'



  // FIELDS //////////////////////
  static fields() {
    return {
      createdAt: this.attr(''),
      description: this.attr(''),
      displayName: this.attr(''),
      hasSchedule: this.attr(''),
      id: this.attr(''),
      modifierSummary: this.attr(''),
      sortOrder: this.attr(''),
      updatedAt: this.attr(''),
      menuTypes: this.attr([]),
      menuCategoryImages: this.attr([]),
      statementTemplate: this.attr(''),

      // relationships
      menuItems: this.belongsToMany(Item, CategoryItem, 'menuCategoryId', 'menuItemId'),

    };
  }

  get lastModified() {
    return this.updatedAt || this.createdAt;
  }


  // QUERIES //////////////////////
  static hiddenCategory() {
    return Category.query().where('statementTemplate', 'HiddenTemplate').first();
  }


  // STATE //////////////////////
  static state() {
    return {
      fetching: false,
      fetchingSingle: false,
      submitting: false,
      sorting: false,
      deleting: false
    };
  }

  static $state() {
    return this.store().state.entities.categories;
  }



  // ACTIONS //////////////////////
  static async fetchVisibleCategoriesByStoreId(storeId) {
    try {
      this.commit((state) => { // TEST ?
        state.fetching = true;
      });


      const response = await http.get(
        `stores/${storeId}/menu_categories/visible`
      );

      return response.data;
    }

    catch (error) {
      throw error;
    }

    finally {
      this.commit((state) => { // TEST ?
        state.fetching = false;
      });
    }
  }

  static async fetchCategory(categoryId) {
    try {
      this.commit((state) => {
        state.fetchingSingle = true;
      });

      const merchantId = this.store().state.entities.merchants.selectedMerchantId;

      const response = await http.get(
        `merchants/${merchantId}/menu_categories/${categoryId}?include=menuCategoryImages,menuCategorySchedules&onlyIncluded=true`
      );
      this.insert({ data: response.data.menuCategory });
    }

    catch (error) {
      throw error;
    }

    finally {
      this.commit((state) => {
        state.fetchingSingle = false;
      });
    }
  }

  static async fetchCategories(options = {}) {
    try {
      this.commit((state) => { // TEST ?
        state.fetching = true;
      });

      const { menuTypeId } = options;

      const merchantId = this.store().state.entities.merchants.selectedMerchantId;
      const query = menuTypeId ? `?menu_type_id=${menuTypeId}` : '';

      const alreadyFetched = this.query().exists();

      if (!alreadyFetched || options.forceFetch) {
        const response = await http.get(
          `merchants/${merchantId}/menu_categories${query}`
        );

        this.create({ data: response.data.menuCategories });
      }
    }

    catch (error) {
      throw error;
    }

    finally {
      this.commit((state) => { // TEST ?
        state.fetching = false;
      });
    }
  }

  static async updateSortOrders({ categories, oldIndex, newIndex }) {
    // remove nested menuItems so they don't get re-seeded
    const categoriesWithoutItems = categories.map((category) => {
      const _category = { ...category };
      delete _category.menuItems;
      return _category;
    });

    const newCategories = setUpdatedSortOrders({ fromIndex: oldIndex, toIndex: newIndex, array: categoriesWithoutItems });

    this.insert({
      data: newCategories.map((category) => {
        ['menuItems', 'menuType'].forEach((key) => {
          delete category[key];
        });
        return category;
      })
    });

    this.commit((state) => { // TEST ?
      state.sorting = true;
    });

    try {
      await http.put('menu_categories/sort_order', { menuCategories: newCategories });
    }

    catch (error) {
      this.insert({ data: categoriesWithoutItems });
      throw error;
    }

    finally {
      this.commit((state) => { // TEST ?
        state.sorting = false;
      });
    }
  }

  static async addCategory(category) {
    try {
      this.commit((state) => { // TEST ?
        state.submitting = true;
      });

      const merchantId = this.store().state.entities.merchants.selectedMerchantId;

      const response = await http.post(`merchants/${merchantId}/menu_categories?include=menuCategorySchedules&onlyIncluded=true`, { menuCategory: category });

      this.insert({ data: response.data.menuCategory });
      return response.data.menuCategory;
    }

    catch (error) {
      throw error;
    }

    finally {
      this.commit((state) => { // TEST ?
        state.submitting = false;
      });
    }
  }

  static async updateCategory(category) {
    try {
      this.commit((state) => { // TEST ?
        state.submitting = true;
      });

      const merchantId = this.store().state.entities.merchants.selectedMerchantId;

      const response = await http.put(`merchants/${merchantId}/menu_categories/${category.id}?include=menuCategorySchedules&onlyIncluded=true`, { menuCategory: category });

      this.insert({ data: response.data.menuCategory });
    }

    catch (error) {
      throw error;
    }

    finally {
      this.commit((state) => { // TEST ?
        state.submitting = false;
      });
    }
  }

  static async deleteCategory(categoryId) {
    try {
      this.commit((state) => { // TEST ?
        state.deleting = true;
      });

      const merchantId = this.store().state.entities.merchants.selectedMerchantId;

      await http.delete(`merchants/${merchantId}/menu_categories/${categoryId}`);

      this.delete(categoryId);
    }

    catch (error) {
      throw error;
    }

    finally {
      this.commit((state) => { // TEST ?
        state.deleting = false;
      });
    }
  }

  static async toggleCategoryVisibility({ menuCategoryId, storeId, isDisabled }) {
    try {
      this.commit((state) => {
        state.submitting = true;
      });

      const merchantId = this.store().state.entities.merchants.selectedMerchantId;

      const response = await http.put(
        `merchants/${merchantId}/store_menus/${storeId}/${isDisabled ? 'hide_category' : 'show_category'}`,
        { menuCategoryId }
      );

      const sanitizedMenuItems = response.data.menuCategory.menuItems.map((menuItem) => {
        delete menuItem.menuCategories;
        return menuItem;
      });

      response.data.menuCategory.menuItems = sanitizedMenuItems;

      this.insert({
        data: response.data.menuCategory
      });
    }

    catch (error) {
      throw error;
    }

    finally {
      this.commit((state) => {
        state.submitting = false;
      });
    }
  }

  static async addMenuCategoryImage({ imageFile, menuCategoryId }) {
    try {
      this.commit((state) => { // TEST ?
        state.submitting = true;
      });

      const formData = new FormData();
      formData.append('menuCategoryImage[file]', imageFile);
      formData.append('menuCategoryImage[label]', 'kiosk');
      formData.append('menuCategoryImage[menuCategoryId]', menuCategoryId);


      const response = await http.post(
        'menu_category_images',
        formData,
        { headers: { 'Content-Type': 'multipart/form-data' } }
      );


      this.update(
        {
          data: {
            id: menuCategoryId,
            menuCategoryImages: [response.data.menuCategoryImage]
          }
        }
      );
    }

    catch (error) {
      throw error;
    }

    finally {
      this.commit((state) => { // TEST ?
        state.submitting = false;
      });
    }
  }

  static async deleteMenuCategoryImage(menuCategoryId, menuCategoryImageId) {
    try {
      this.commit((state) => {
        state.submitting = true;
      });
      await http.delete(`menu_category_images/${menuCategoryImageId}`);

      const menuCategoryImages = [];

      this.update(
        {
          data: {
            id: menuCategoryId,
            menuCategoryImages
          }
        }
      );
    }
    catch (error) {
      throw error;
    }
    finally {
      this.commit((state) => {
        state.submitting = false;
      });
    }
  }
}
