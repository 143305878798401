<template>
  <div class="nav-bar">
    <div
      v-if="!hideCFLogo"
      class="cf-logo"
      role="img"
      aria-label="Admin Portal by CardFree"
    >
      <img class="cf-logo-left" src="/images/cf-logo-left.svg">
      <img class="cf-logo-right" src="/images/cf-logo-right.svg">
    </div>
    <h3
      v-if="!hideMerchantName"
      class="is-4 subtitle mar-b-none mar-x-sm"
      data-test-id="selected-merchant-name"
    >
      {{ $_selectedMerchant.displayName }}
    </h3>
    <b-select
      :value="selectedStoreId"
      placeholder="Select a location..."
      icon="map-marker-alt"
      icon-pack="far"
      size="is-small"
      :class="{ 'mar-l-xs': hideMerchantName }"
      @input="handleStoreIdChange"
    >
      <option
        v-for="store in allStores"
        :key="store.storeId"
        :value="store.storeId"
        data-test-class="store-option"
      >
        {{ store.description }}
      </option>
    </b-select>
    <div class="expo-tools is-flex align-center justify-between" :class="[ useSmallButtons ? 'gap-sm' : 'gap']">
      <b-button
        type="is-light"
        :size="useSmallButtons ? 'is-small' : ''"
        rounded
        @click="setSearchModalActive(true)"
      >
        <b-icon icon="magnifying-glass" />
        <span v-if="!hideSearchText">Search</span>
      </b-button>
      <b-button
        v-tippy="{ content: 'Refresh Orders', placement: 'bottom' }"
        :disabled="fetching"
        type="is-light"
        :size="useSmallButtons ? 'is-small' : ''"
        rounded
        @click="refreshOrders()"
      >
        <b-icon :class="{ 'spin-smooth': fetching }" icon="arrows-rotate" />
      </b-button>
      <div class="notification-btn-container">
        <b-button
          type="is-light"
          :size="useSmallButtons ? 'is-small' : ''"
          rounded
          @click="setNotificationsModalActive(true)"
        >
          <b-icon icon="bell" />
        </b-button>
        <span
          v-if="queueSize"
          :class="[
            { 'more-than-9-notifications': queueSize > 9 },
            'notification-badge is-size-7 has-text-align-center has-radius-full has-text-white has-background-danger'
          ]"
        >
          {{ queueSize }}
        </span>
      </div>
      <b-button
        v-if="isUsingPwa"
        type="is-light"
        :size="useSmallButtons ? 'is-small' : ''"
        rounded
        data-test-id="expo-tools-button"
        @click="$emit('toggle-layout-element', 'expoToolsEnabled')"
      >
        <b-icon icon="arrow-down-left-and-arrow-up-right-to-center" />
      </b-button>
      <b-button
        v-else
        class="is-transparent"
        type="is-light"
        :size="useSmallButtons ? 'is-small' : ''"
        data-test-id="fullscreen-button"
        @click="$emit('toggle-layout-element', 'isFullscreen')"
      >
        <b-icon icon="compress" />
      </b-button>
    </div>
  </div>
</template>

<script>
  import { mapActions, mapGetters, mapState } from 'vuex';

  import merchantMixin from '@/mixins/merchant';

  import Store from '@/store/classes/Store';

  export default {
    name: 'OrderExpoNavigationBar',

    mixins: [merchantMixin],

    data() {
      return {
        hideSearchText: false,
        hideCFLogo: false,
        hideMerchantName: false,
        useSmallButtons: false
      };
    },

    computed: {
      ...mapGetters('session', ['isUsingPwa']),
      ...mapState('orderExpo', ['selectedStoreId']),
      ...mapGetters('orderExpo', ['fetching', 'allStores']),
      ...mapGetters('expoNotifications', ['queueSize']),

      selectedStore() {
        return Store.find(this.selectedStoreId);
      }
    },

    created() {
      window.addEventListener('resize', this.checkViewport);
      this.checkViewport();
    },

    destroyed() {
      window.removeEventListener('resize', this.checkViewport);
    },

    methods: {
      ...mapActions(
        'orderExpo', [
          'setSelectedStoreId',
          'fetchStoreOrderData',
          'setSearchModalActive',
          'setNotificationsModalActive',
          'setSelectedOrderId',
          'refreshOrders',
          'handleStoreIdChange'
        ]
      ),
      ...mapActions('expoNotifications', ['clearNotifications']),

      checkViewport() {
        const width = window.innerWidth;
        this.hideSearchText = width <= 850;
        this.hideCFLogo = width <= 750;
        this.hideMerchantName = width <= 685;
        this.useSmallButtons = width <= 550;
      }
    }
  };
</script>

<style lang="sass" scoped>
  .nav-bar
    position: fixed
    z-index: 31
    top: 0
    left: 0
    right: 0
    display: flex
    align-items: center
    min-height: $navbar-height
    box-shadow: 0 3px 5px rgba(0, 0, 0, 0.1)
    border-bottom: 1px solid #dedede
    background-color: $white

  .expo-tools
    position: absolute
    right: 0
    margin-right: 0.5rem

  .documentation-container
    position: absolute
    right: 0
    margin-right: 0.5rem
    color: $primary


  .env-indicator
    position: absolute
    right: 3rem
    margin-right: 1rem
    font-size: $size-5
    font-weight: bold
    color: $primary

  .cf-logo
    display: flex
    justify-content: center
    width: 75px
    line-height: 1
    align-items: center
    height: 2rem
    border-right: 2px solid $grey-lightest
    .cf-logo-left, .cf-logo-right
      transition: transform 200ms
      display: inline-block
      height: 1.75rem
    .cf-logo-left
      transform: translate(8px, 2px)
    .cf-logo-right
      transform: translate(-8px, -2px)

  .notification-btn-container
    position: relative

    .notification-badge
      position: absolute
      top: 22px
      right: -6px
      padding: 0 0.4rem

      &.more-than-9-notifications
        right: -9px
        top: 20px
        padding: 0.1rem 0.3rem

  @media (max-width: 820px)
    .hide-on-small-screens
      visibility: hidden
      width: 0
      height: 0

  @media (max-width: $widescreen)
    .nav-bar
      justify-content: start
</style>
