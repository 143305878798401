/* eslint-disable import/no-cycle */
import { Model } from '@vuex-orm/core';
import http from '@/services/http';



export default class PosMenuCategory extends Model {
  static entity = 'posMenuCategories'

  static fields() {
    return {
      id: this.attr(''),
      posItemId: this.attr(''),
      name: this.attr(''),
      excludeFromImport: this.attr(''),
      menuItemCount: this.attr(''),
      storeId: this.attr('')
    };
  }

  // STATE //////////////////////
  static state() {
    return {
      fetching: false,
      submitting: false
    };
  }

  static $state() { // TEST ?
    return this.store().state.entities.posMenuCategories;
  }


  // Actions
  static async fetchPosMenuCategories() {
    try {
      this.commit((state) => { // TEST ?
        state.fetching = true;
      });

      const merchantId = this.store().state.entities.merchants.selectedMerchantId;

      const { data } = await http.get(`merchants/${merchantId}/pos_menu_categories`);

      this.create({ data: data.posMenuCategories });
    }

    catch (error) {
      throw error;
    }

    finally {
      this.commit((state) => { // TEST ?
        state.fetching = false;
      });
    }
  }

  static async generateMasterMenu(storeId) {
    try {
      this.commit((state) => {
        state.submitting = true;
      });

      const merchantId = this.store().state.entities.merchants.selectedMerchantId;

      let path = 'generate_master_menu';
      if (storeId) path += `?storeId=${storeId}`;

      await http.put(path, { merchantId }, { timeout: 300000 });
    }

    catch (error) {
      throw error;
    }

    finally {
      this.commit((state) => {
        state.submitting = false;
      });
    }
  }
}
