var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"user-list-page"},[_c('hero-banner',{scopedSlots:_vm._u([(_vm.$can('create', 'User'))?{key:"right",fn:function(){return [_c('b-button',{attrs:{"rounded":"","inverted":"","size":"is-medium","type":"is-primary","icon-left":"plus"},on:{"click":_vm.$_toggleModal}},[_vm._v(" New User ")])]},proxy:true}:null],null,true)},[_c('h1',{staticClass:"title is-2"},[_vm._v("Users")])]),_c('div',{staticClass:"section"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"card"},[_c('backend-searchable-table',{attrs:{"search-fields":_vm.searchFields,"filters":_vm.filters,"loading":_vm.isLoading,"custom-sort-handler":_vm.handleSort}},[_c('b-table-column',{attrs:{"field":"fullName","label":"Name","sortable":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var ref_row = ref.row;
var id = ref_row.id;
var fullName = ref_row.fullName;
return [_c('router-link',{attrs:{"to":{ name: 'userConfiguration', params: { id: id } }}},[_vm._v(" "+_vm._s(fullName)+" ")])]}}])}),_c('b-table-column',{attrs:{"field":"email","label":"Email","sortable":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var email = ref.row.email;
return [_vm._v(" "+_vm._s(email)+" ")]}}])}),_c('b-table-column',{attrs:{"field":"status","label":"Status","centered":"","width":"1"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var status = ref.row.status;
return [_c('div',{staticClass:"is-flex justify-center"},[_c('tippy',{attrs:{"content":_vm._f("capitalize")(status),"delay":[500, 0],"placement":"left","distance":"14"},scopedSlots:_vm._u([{key:"trigger",fn:function(){return [(status === 'active')?_c('b-icon',{attrs:{"title":"active","icon":"check","type":"is-success","size":"is-small"}}):_vm._e(),(status === 'pending')?_c('b-icon',{staticClass:"is-size-7",attrs:{"title":"pending","icon":"circle","type":"is-warning","size":"is-small"}}):_vm._e(),(status === 'deactivated')?_c('b-icon',{attrs:{"title":"deactivated","icon":"times","type":"is-danger","size":"is-small"}}):_vm._e()]},proxy:true}],null,true)})],1)]}}])}),_c('b-table-column',{attrs:{"field":"role","label":"Role","centered":"","width":"1"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var name = ref.row.role.name;
return [_c('user-role-tag',{attrs:{"is-full-width":"","role-name":name}})]}}])}),_c('b-table-column',{attrs:{"field":"actions","label":"Actions","centered":"","width":"1"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var ref_row = ref.row;
var id = ref_row.id;
var fullName = ref_row.fullName;
var isConfirmed = ref_row.isConfirmed;
return [_c('b-dropdown',{attrs:{"aria-role":"list","position":"is-bottom-left","mobile-modal":false}},[_c('b-button',{staticClass:"is-transparent",attrs:{"slot":"trigger","type":"is-white"},slot:"trigger"},[_c('b-icon',{attrs:{"icon":"ellipsis-v","pack":"far","size":"is-small"}})],1),_c('b-dropdown-item',{attrs:{"disabled":isConfirmed},on:{"click":function($event){return _vm.resendEmailConfirmation({ id: id, fullName: fullName })}}},[_c('b-icon',{staticClass:"mar-r-sm",attrs:{"icon":"envelope","size":"is-small"}}),_vm._v("Re-send Confirmation Email ")],1)],1)]}}])})],1)],1)])]),_c('b-modal',{staticClass:"auto-width",attrs:{"has-modal-card":"","can-cancel":false,"trap-focus":"","active":_vm.cmc_isModalOpen},on:{"update:active":function($event){_vm.cmc_isModalOpen=$event}},nativeOn:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"esc",27,$event.key,["Esc","Escape"])){ return null; }return _vm.$_confirmCloseModal($event)}}},[_c('div',{staticClass:"modal-card",staticStyle:{"max-width":"700px"}},[_c('header',{staticClass:"modal-card-head"},[_c('p',{staticClass:"modal-card-title"},[_vm._v("Add New User")]),_c('button',{staticClass:"button is-transparent",on:{"click":_vm.$_confirmCloseModal}},[_c('b-icon',{attrs:{"icon":"times","size":"is-small"}})],1)]),_c('div',{staticClass:"modal-card-body pad-b-none"},[_c('user-form',{attrs:{"mode":"create"},on:{"user-added":_vm.performSearch}})],1)])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }