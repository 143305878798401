<template>
  <validated-form
    ref="form"
    :disabled="!$can('update', 'MenuCategory') || !$_menuPermissions.EDIT_RESOURCE"
    class="menuCategoryUpsellForm"
    form-id="menuCategoryUpsellForm"
  >
    <b-field
      label="Search By"
      label-position="on-border"
      :class="['search-field', { 'mar-b-none': searchResults.length }]"
      :message="isEmptySearch ? 'No results found' : ''"
    >
      <b-select v-model="searchType">
        <option value="name">Name</option>
        <option value="pos">PLU#</option>
      </b-select>
      <b-input
        ref="searchInput"
        v-model="searchQuery"
        expanded
        :placeholder="'Search for Items...'"
        :icon-right="searchQuery ? 'times-circle' : null"
        icon-right-clickable
        type="text"
        @icon-right-click="clearSearch"
        @keydown.native.esc="clearSearch"
        @keypress.native.enter.prevent
      />
      <div class="control">
        <b-button
          v-tabbable
          :disabled="!searchQuery"
          class="has-text-weight-bold"
          :loading="isLoadingSearchResults"
          @click="searchItems"
        >
          Search
        </b-button>
      </div>
    </b-field>

    <b-dropdown
      v-if="searchResults.length"
      ref="dropdown"
      expanded
      scrollable
      :mobile-modal="false"
      max-height="300px"
      aria-role="list"
      class="has-extra-shadow search-dropdown"
      :position="'is-bottom-right'"
      @active-change="handleDropdownBlur"
    >
      <b-dropdown-item
        v-for="item in searchResults"
        :key="item.id"
        :focusable="false"
        :value="item.id"
        :disabled="!!upsellItems.find(result => result.menuItemId === item.id)"
        @click="addItemToUpsell(item)"
      >
        <p class="dropdown-item--text__primary">
          {{ item.displayName }}
        </p>
        <p v-if="item.posItems && item.posItems.length" class="dropdown-item--text__secondary">
          {{ item.posItems[0].id }}
        </p>
      </b-dropdown-item>
    </b-dropdown>

    <draggable
      v-if="upsellItems.length"
      v-bind="draggableAttributes"
      :list="upsellItems"
      tag="ul"
      class="dist-y-sm"
      :force-fallback="true"
      @change="handlePriorityChange"
    >
      <li
        v-for="item in upsellItems"
        :key="item.id"
        class="has-background-white is-flex align-center has-text-weight-bold"
      >
        <b-icon
          icon="grip-lines"
          size="is-small"
          pack="far"
          class="drag-handle mar-x-sm"
        />
        <span class="flex-grow">{{ item.displayName }}</span>
        <span>
          <b-button class="button is-transparent" @click="removeUpsell(item)">
            <b-icon icon="minus-circle" type="is-danger" size="is-small" />
          </b-button>
        </span>
      </li>
    </draggable>

    <p v-else class="has-text-grey-light has-text-centered pad">
      Search for an Item to upsell
    </p>

    <b-message
      v-if="addingError"
      type="is-danger"
      size="is-small"
      class="mar-t is-compact has-shadow justify-self-start"
      has-icon
    >
      {{ addingError }}
    </b-message>

  </validated-form>
</template>

<script>
  import draggable from 'vuedraggable';
  import MenuCategoryUpsell from '@/store/classes/MenuCategoryUpsell';
  import { searchMenuByResource } from '@/api/menu';
  import merchantMixin from '@/mixins/merchant';
  import getChangedResources from '@/helpers/get-changed-resources';

  export default {
    name: 'CategoryUpsell',
    components: { draggable },

    mixins: [merchantMixin],

    props: {
      categoryId: {
        type: Number,
        default: null
      },

      menuTypeId: {
        type: [String, Number],
        default: null
      },

      mode: {
        type: String,
        default: 'create',
        validator(value) {
          return ['create', 'read', 'update'].includes(value);
        }
      }
    },

    data() {
      return {
        draggableAttributes: {
          animation: '200',
          ghostClass: 'ghost',
          handle: '.drag-handle'
        },
        isEmptySearch: false,
        searchQuery: '',
        searchType: 'name',
        searchResults: [],
        upsellItems: [],
        isLoadingSearchResults: false,
        addingError: null
      };
    },

    computed: {
      previousUpsellItems() {
        return MenuCategoryUpsell.query().where('menuCategoryId', this.categoryId).orderBy('priority').get();
      }
    },

    async created() {
      await this.onCreated();
    },

    methods: {
      async onCreated() {
        if (this.mode === 'update') {
          await this.fetchUpsellItems();
          this.upsellItems = this.$clone(this.previousUpsellItems);
        }
      },

      async fetchUpsellItems() {
        try {
          await MenuCategoryUpsell.fetchUpsellItems(this.categoryId);
        }
        catch (error) {
          this.$_onRequestError({
            toastOptions: { message: 'Unable to fetch upsold items' },
            error
          });
        }
      },

      handlePriorityChange() {
        this.upsellItems = this.resetPriority(this.$clone(this.upsellItems));
      },

      resetPriority(items) {
        return items.map((item, index) => {
          item.priority = index + 1;
          return item;
        });
      },

      async addUpsellItems(categoryId) {
        if (this.upsellItems.length) {
          await MenuCategoryUpsell.addUpsellItems({
            menuCategoryUpsells: this.upsellItems.map(({ menuItemId }) => ({ menuItemId })),
            categoryId
          });
        }
      },

      async updateUpsellItems() {
        const { added, removed } = getChangedResources({
          newArray: this.upsellItems,
          oldArray: this.previousUpsellItems,
          comparisonKey: 'menuCategoryUpsellId'
        });

        if (removed.length > 0) {
          await MenuCategoryUpsell.deleteUpsellSellItems({
            menuCategoryUpsellIds: removed.map(item => item.menuCategoryUpsellId),
            categoryId: this.categoryId
          });
        }

        const upsellItems = this.$clone(this.upsellItems);
        if (added.length > 0) {
          const addedUpsellItems = await MenuCategoryUpsell.addUpsellItems({
            menuCategoryUpsells: added.map(({ menuItemId }) => ({ menuItemId })),
            categoryId: this.categoryId
          });
          upsellItems.forEach((item) => {
            const addedItem = addedUpsellItems.find(ai => ai.menuItemId === item.menuItemId);
            if (addedItem) {
              item.menuCategoryUpsellId = addedItem.menuCategoryUpsellId;
            }
          });
        }
        await MenuCategoryUpsell.updateUpsellItemsPriority({ items: upsellItems, categoryId: this.categoryId });
      },

      toggleLoadingSearchResults(value) {
        this.isLoadingSearchResults = value;
      },

      async searchItems() {
        try {
          this.isEmptySearch = false;
          this.addingError = null;
          this.toggleLoadingSearchResults(true);

          const results = await searchMenuByResource({
            merchantId: this.$_selectedMerchantId,
            resourceType: 'menuItems',
            searchQuery: this.searchQuery,
            searchType: this.searchType
          });

          this.isEmptySearch = !results.length;
          this.searchResults = results;
          if (!this.isEmptySearch) {
            setTimeout(() => {
              this.$refs.dropdown.toggle();
            }, 0);
          }
        }
        catch (error) {
          this.$_onRequestError({
            toastOptions: {
              message: 'There was an error with your search',
              error
            }
          });
        }
        finally {
          this.toggleLoadingSearchResults(false);
        }
      },

      addItemToUpsell(item) {
        this.clearSearch();
        const hasMaxAmount = this.upsellItems.length === 10;

        if (hasMaxAmount) {
          this.addingError = 'Cannot upsell more than 10 items';
        }
        else {
          const newUpsellItem = new MenuCategoryUpsell({
            menuItemId: item.id,
            menuCategoryId: this.categoryId,
            priority: this.upsellItems.length + 1,
            displayName: item.displayName
          });
          this.upsellItems.push(newUpsellItem);
        }
      },

      handleDropdownBlur(isActive) {
        if (!isActive) {
          this.clearSearch();
        }
      },

      clearSearch() {
        this.addingError = null;
        this.searchQuery = '';
        this.isEmptySearch = false;
        this.searchResults = [];
      },

      removeUpsell(item) {
        this.addingError = null;
        const upsellItems = this.$clone(this.upsellItems);
        const filteredItems = upsellItems.filter(upsellItem => upsellItem.menuItemId !== item.menuItemId);
        this.upsellItems = this.resetPriority(filteredItems);
      },

      async handleSubmit(categoryId) {
        try {
          switch (this.mode) {
            case 'create':
              return await this.addUpsellItems(categoryId);

            case 'update':
              await this.updateUpsellItems();
              break;

            default:
              break;
          }
        }
        catch (error) {
          throw error;
        }
      }
    }
  };
</script>

<style lang="sass" scoped>
  .menuCategoryUpsellForm
    min-height: 400px

  ::v-deep
    .dropdown-menu
      padding-top: 0 !important
</style>
