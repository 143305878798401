const OrderTypes = {
  ORDER_AHEAD: 'OrderAhead',
  THIRD_PARTY_ORDER_AHEAD: 'ThirdPartyOrderAhead',
  ALL_ORDER_AHEAD: 'OrderAhead,ThirdPartyOrderAhead',
  PAY_AND_FULFILL: 'PayAndFulfill',
  ORDER_AND_PAY: 'OrderAndPay',
  ORDER_AND_PAY_AT_TABLE: 'PayAndFulfill,OrderAndPay',
  EXTERNAL_ORDER: 'ExternalOrder'
};



export default OrderTypes;
