<template>
  <panel
    title="Offers"
    collapsible
    start-open
    no-padding
  >
    <searchable-table
      :table-data="guestOffers"
      :search="{
        placeholder: 'Search by Offer Name or ID',
        keys: ['name', 'guid']
      }"
      :filters="tableFilters"
      :clear-inputs-additional-action="clearDateInputs"
    >

      <template #optional-actions>
        <div
          v-tippy="{
            content: 'Unable to issue an offer to a guest that has not completed enrollment',
            placement: 'top',
            maxWidth: 300,
            delay: [150, 0],
            onShow: () => !canIssueOffer
          }"
        >
          <b-button
            type="is-primary"
            icon-left="plus"
            :disabled="!canIssueOffer"
            label="Issue an Offer"
            @click="openIssueOfferModal"
          >
            <b-icon
              v-if="isFetchingOffers"
              icon="spinner-third"
              class="spin"
              size="is-small"
            />
          </b-button>
        </div>

        <div class="actions-container">
          <div class="date-pickers-container">
            <b-field label="Date Issued" label-position="on-border">
              <b-datepicker
                v-model="dateIssuedDateRange"
                class="date-picker-input has-extra-shadow mar-l-xs"
                icon="calendar-alt"
                range
                :nearby-selectable-month-days="true"
                :mobile-native="false"
                placeholder="Select Date Range"
              >
                <div class="buttons is-right">
                  <b-button @click="dateIssuedDateRange = []">Clear</b-button>
                </div>
              </b-datepicker>
            </b-field>
            <b-field label="Expiration Date" label-position="on-border">
              <b-datepicker
                v-model="expirationDateRange"
                class="date-picker-input has-extra-shadow mar-l-md"
                icon="calendar-alt"
                range
                :nearby-selectable-month-days="true"
                :mobile-native="false"
                placeholder="Select Date Range"
              >
                <div class="buttons is-right">
                  <b-button @click="expirationDateRange = []">Clear</b-button>
                </div>
              </b-datepicker>
            </b-field>
          </div>
        </div>
      </template>
      <template #default="{ filteredData }">
        <b-table
          :data="isFetchingOfferAllocations ? [] : applyDateFilters(filteredData)"
          :paginated="guestOffers.length > perPage"
          class="is-middle-aligned"
          pagination-simple
          :per-page="perPage"
          :mobile-cards="false"
          :current-page="page"
          @page-change="page = $event"
        >
          <b-table-column
            v-slot="{ row: { name, offerPublicId } }"
            sortable
            field="name"
            label="Name"
            width="600"
          >
            <span
              class="link"
              @click="viewOfferDetails(offerPublicId)"
            >
              {{ name }}
            </span>
          </b-table-column>

          <b-table-column
            v-slot="{ row: { offerPublicId } }"
            sortable
            field="publicId"
            label="Offer ID"
            cell-class="table-cell-text-overflow"
          >
            <span class="is-monospaced">{{ offerPublicId }}</span>
          </b-table-column>

          <b-table-column
            v-slot="{ row: { guid } }"
            sortable
            field="guid"
            label="Offer Allocation ID"
            cell-class="table-cell-text-overflow"
          >
            <span class="is-monospaced">{{ guid }}</span>
          </b-table-column>

          <b-table-column
            v-slot="{ row: { status } }"
            sortable
            field="status"
            label="Status"
            centered
            width="200"
          >
            <b-tag :class="status.split(' ').join('-').toLowerCase()">{{ capitalCase(status) }}</b-tag>
          </b-table-column>

          <b-table-column
            v-slot="{ row: { issuedDateTime } }"
            centered
            sortable
            field="issuedDateTime"
            label="Date Issued"
            width="150"
          >
            <span>{{ issuedDateTime | moment('MM/DD/YYYY') }}</span>
          </b-table-column>

          <b-table-column
            v-slot="{ row: { redemptionsAllowedEndDate } }"
            centered
            sortable
            field="redemptionsAllowedEndDate"
            label="Expiration"
            witdh="150"
            :custom-sort="sortByAllowedEndDate"
          >
            <span>
              {{ redemptionsAllowedEndDate.month }}/{{ redemptionsAllowedEndDate.day }}/{{ redemptionsAllowedEndDate.year }}
            </span>
          </b-table-column>

          <b-table-column
            v-slot="{ row }"
            centered
            cell-class="actions"
            label="Actions"
            width="1"
            field="Actions"
          >
            <dropdown-menu position="bottom-end" :mobile-modal="false">
              <b-button slot="trigger" class="is-transparent" type="is-white">
                <b-icon icon="ellipsis-v" pack="far" size="is-small" />
              </b-button>
              <b-dropdown-item @click="viewOfferDetails(row.offerPublicId)">
                <b-icon
                  icon="eye"
                  pack="fas"
                  size="is-small"
                  class="mar-r-sm"
                />
                View Offer Details
              </b-dropdown-item>
              <b-dropdown-item
                v-if="canRevokeOffer(row.status)"
                class="is-danger"
                @click="confirmRevokeOffer(row)"
              >
                <b-icon icon="trash-alt" class="mar-r-sm" size="is-small" />
                Revoke Offer
              </b-dropdown-item>
            </dropdown-menu>
          </b-table-column>

          <template slot="empty">
            <empty-table-loader
              icon="gift"
              message="No Offers Found..."
              :loading="isFetchingOfferAllocations"
            />
          </template>

          <template #bottom-left>
            <div class="left-side">
              <page-number-select
                :page="page"
                :per-page="Number(perPage)"
                :total-count="applyDateFilters(filteredData).length"
                @input="page = $event"
              />
              <b-field label-for="perPage" label-position="on-border" label="Per Page">
                <b-select id="perPage" v-model="perPage">
                  <option value="10">10</option>
                  <option value="20">20</option>
                  <option value="50">50</option>
                  <option value="100">100</option>
                </b-select>
              </b-field>
            </div>
          </template>
        </b-table>
      </template>
    </searchable-table>
  </panel>
</template>

<script>
  import merchantMixin from '@/mixins/merchant';
  import offerAllocationStatuses from '@/constants/offerAllocationStatuses';
  import Offer from '@/store/classes/Offer';
  import OfferAllocation from '@/store/classes/OfferAllocation';
  import capitalCase from '@/helpers/capitalCase';
  import moment from 'moment-timezone';
  import offerReviewStep from '@/components/pages/offers/modal-steps/offer-review-step.vue';
  import searchSelectModal from '@/components/globals/search-select-modal.vue';


  export default {
    name: 'RegisteredGuestOffers',

    mixins: [merchantMixin],

    props: {
      registeredGuest: {
        type: Object,
        required: true
      }
    },

    data() {
      return {
        perPage: 20,
        page: 1,
        dateIssuedDateRange: [],
        expirationDateRange: []
      };
    },

    computed: {
      guestOffers() {
        return OfferAllocation.all();
      },

      isFetchingOffers() {
        return Offer.$state().fetching;
      },

      isFetchingOfferAllocations() {
        return OfferAllocation.$state().fetching;
      },

      liveOffers() {
        return Offer.liveOffers();
      },

      canIssueOffer() {
        return this.$can('update', 'Account') && this.liveOffers.length && this.registeredGuest.loyaltyReward?.publicId;
      },

      tableFilters() {
        return [
          {
            placeholder: 'Status',
            key: 'status',
            default: offerAllocationStatuses.REDEEMABLE,
            options: Object.values(offerAllocationStatuses).map(value => ({
              name: capitalCase(value),
              value
            }))
          }
        ];
      },

      liveOffersList() {
        return this.liveOffers.map((offer) => {
          const { campaignsIanaTimezoneId } = this.$_selectedMerchant.features;
          const formatDate = date => moment.tz(date, campaignsIanaTimezoneId).format('MMM DD, YYYY');
          const isFuture = moment().isBefore(offer.redemptionsAllowedStartDate);

          let footer = 'Redemptions ';

          if (offer.redemptionsAllowedEndDate) {
            footer += 'allowed ';
            footer += `${isFuture ? `from ${formatDate(offer.redemptionsAllowedStartDate)} through` : 'until'} ${formatDate(offer.redemptionsAllowedEndDate)}`;
          }
          else {
            footer += `${isFuture ? 'begin' : 'began'} ${formatDate(offer.redemptionsAllowedStartDate)}`;
          }

          return {
            description: offer.description,
            footer,
            id: offer.guid,
            name: offer.name
          };
        });
      }
    },

    created() {
      this.onCreated();
    },

    methods: {
      capitalCase,

      async onCreated() {
        await Promise.all([
          this.fetchOffersForAccount(),
          this.fetchOffers()
        ]);
      },

      async fetchOffersForAccount() {
        try {
          await OfferAllocation.fetchOffersForAccount(this.registeredGuest.id);
        }
        catch (error) {
          this.$_onRequestError({
            error,
            toastOptions: {
              message: 'There was an error fetching the active offers'
            }
          });
        }
      },

      async fetchOffer(offerGuid) {
        try {
          await Offer.fetchOffer(offerGuid);
        }
        catch (error) {
          this.$_onRequestError({
            error,
            toastOptions: {
              message: 'There was an error fetching the offer'
            }
          });
        }
      },

      async fetchOffers() {
        try {
          await Offer.fetchOffers();
        }
        catch (error) {
          this.$_onRequestError({
            error,
            toastOptions: {
              message: 'There was an error fetching offers'
            }
          });
        }
      },

      async viewOfferDetails(offerPublicId) {
        await this.fetchOffer(offerPublicId);
        const offer = await Offer.query()
          .where('guid', offerPublicId)
          .first();
        this.$buefy.modal.open({
          parent: this,
          component: offerReviewStep,
          hasModalCard: true,
          canCancel: ['escape', 'outside'],
          trapFocus: true,
          customClass: 'auto-width',
          props: {
            value: offer,
            offerReadOnly: true,
            offerDetailsAreReadOnly: true,
            isScrollable: true
          }
        });
      },

      sortByAllowedEndDate(a, b, isAsc) {
        const epochA = this.parseEpoch(a.redemptionsAllowedEndDate);
        const epochB = this.parseEpoch(b.redemptionsAllowedEndDate);
        const sort = (epochA < epochB || (!epochA)) ? -1 : 1;
        return isAsc ? sort : (sort * -1);
      },

      parseEpoch(dateObj) {
        return moment({ year: dateObj.year, month: dateObj.month, day: dateObj.day }).unix();
      },

      dateObjToMoment(dateObj) {
        const dateString = `${dateObj.year}-${dateObj.month}-${dateObj.day}`;
        const jsDate = new Date(dateString);
        return moment(jsDate);
      },

      openIssueOfferModal() {
        this.$buefy.modal.open({
          parent: this,
          component: searchSelectModal,
          hasModalCard: true,
          trapFocus: true,
          canCancel: ['escape', 'outside'],
          props: {
            title: 'Issue An Offer',
            placeholderText: 'Search for an offer...',
            confirmText: 'Issue Offer',
            data: this.liveOffersList,
            onSubmit: this.issueOffer,
            isSingleSelection: true,
            message: `
              <p>
                Which offer would you like to issue to
                <span class="${this.registeredGuest.fullName && 'has-text-weight-bold'}">${this.registeredGuest.fullName || 'this account'}</span>?
              </p>
              <p>This offer will be credited to their account immediately.</p>
            `
          }
        });
      },

      canRevokeOffer(status) {
        return this.$can('update', 'Account')
          && (status === offerAllocationStatuses.REDEEMABLE
            || status === offerAllocationStatuses.FUTURE);
      },

      confirmRevokeOffer(row) {
        const guestFullName = `${this.registeredGuest.firstName} ${this.registeredGuest.lastName}`;
        this.$buefy.dialog.confirm({
          title: 'Revoke Offer',
          message: `
            Are you sure you want to revoke the offer ${row.name} from ${guestFullName}?
            Points will not be returned automatically, please adjust points separately.
          `,
          hasIcon: true,
          icon: 'trash-alt',
          type: 'is-danger',
          confirmText: 'Revoke Offer',
          cancelText: 'Cancel',
          onConfirm: () => this.revokeOffer(row.guid)
        });
      },

      async revokeOffer(offerGuid) {
        try {
          await OfferAllocation.revokeOfferFromAccount({ accountId: this.registeredGuest.id, offerGuid });

          await this.fetchOffersForAccount();

          this.$_onRequestSuccess({
            toastOptions: { message: 'Successfully revoked offer!' }
          });
        }
        catch (error) {
          this.$_onRequestError({
            error,
            toastOptions: {
              message: 'There was an error revoking the offer'
            }
          });
        }
      },

      applyDateFilters(filteredOfferAllocations) {
        let dateFilteredData = filteredOfferAllocations;
        if (this.dateIssuedDateRange.length) {
          dateFilteredData = filteredOfferAllocations.filter((offerAllocation) => {
            const dateIssuedMoment = moment(offerAllocation.issuedDateTime).hour(12);
            return dateIssuedMoment.isBetween(moment(this.dateIssuedDateRange[0]), moment(this.dateIssuedDateRange[1]).endOf('day'));
          });
        }

        if (this.expirationDateRange.length) {
          dateFilteredData = dateFilteredData.filter((offerAllocation) => {
            const endDateMoment = this.dateObjToMoment(offerAllocation.redemptionsAllowedEndDate).hour(12);
            return moment(endDateMoment)
              .isBetween(moment(this.expirationDateRange[0]), moment(this.expirationDateRange[1])
                .endOf('day'));
          });
        }

        return dateFilteredData;
      },

      clearDateInputs() {
        this.expirationDateRange = [];
        this.dateIssuedDateRange = [];
      },

      async issueOffer(offerGuid) {
        try {
          await OfferAllocation.issueOfferToAccount({ accountId: this.registeredGuest.id, offerGuid });
          setTimeout(() => {
            // There is a lag between when the offer is issued and is then fetchable by
            // Loyalty API. The cached offers don't have the correct metadata to simply insert
            // offer and the return data from the API doesn't include the offer metadata, so
            // we have to refetch the active offers. A short timeout provides enough of a buffer.
            this.fetchOffersForAccount();
          }, 500);
          this.$_onRequestSuccess({
            toastOptions: { message: 'Successfully issued Offer' }
          });
        }
        catch (error) {
          this.$_onRequestError({
            error,
            toastOptions: {
              message: 'There was an error issuing the offer'
            }
          });
        }
      }
    }
  };
</script>

<style lang="sass" scoped>
  .actions-container
    display: flex
    flex-direction: column
    width: 100%
    margin-top: 0.75rem

  .date-pickers-container
    display: flex
    flex-direction: row
    justify-content: flex-start
    margin-left: -4px

  .table-cell-text-overflow
    max-width: 300px
    min-width: 100%
    overflow: hidden
    white-space: nowrap

    span
      overflow: hidden
      text-overflow: ellipsis

  .left-side
    display: flex
    gap: 1rem

  ::v-deep
    .tag
      font-weight: 700

      &.redeemable
        background-color: lighten($success, 37)
        color: darken($success, 40)
      &.future
        background-color: lighten($primary, 43)
        color: darken($primary, 40)
      &.expired, &.revoked
        background-color: lighten($danger, 33)
        color: darken($danger, 40)
      &.pending-redemption-code
        background-color: lighten($warning, 22)
        color: darken($warning, 47)
      &.redemption-limit-reached, &.unknown
        background-color: lighten($grey, 43)
        color: darken($grey, 40)
</style>
