<template>
  <b-tag v-bind="$attrs" :class="['is-outlined is-light', roleNameClass, {'is-full-width': isFullWidth}]">
    <b>{{ roleNameDisplay | capitalize({ ignoreAcronym: true }) }}</b>
  </b-tag>
</template>

<script>
  export default {
    name: 'UserRoleTag',

    props: {
      roleName: {
        type: String,
        required: true
      },

      isFullWidth: {
        type: Boolean,
        default: false
      }
    },

    computed: {
      roleNameClass: {
        get() {
          return this.roleName?.toLowerCase().replace(/\s+/g, '-');
        }
      },

      roleNameDisplay: {
        get() {
          return this.roleName?.replace('Cardfree', 'CF');
        }
      }
    }
  };
</script>

<style lang="sass" scoped>
  .is-full-width
    width: 100%

  .tag
    &.cardfree-admin
      background-color: $cardfree-admin-user-background
      color: $cardfree-admin-user-color

    &.cardfree-operations
      background-color: $cardfree-operations-user-background
      color: $cardfree-operations-user-color

    &.merchant-admin
      background-color: $admin-user-background
      color: $admin-user-color

    &.merchant-admin-lite
      background-color: $admin-lite-user-background
      color: $admin-lite-user-color

    &.guest-support
      background-color: $guest-support-user-background
      color: $guest-support-user-color

    &.location-manager
      background-color: $location-manager-user-background
      color: $location-manager-user-color

    &.location-operator
      background-color: $location-operator-user-background
      color: $location-operator-user-color

    &.analytics
      background-color: $analytics-user-background
      color: $analytics-user-color

    &.marketing-manager
      background-color: $marketing-manager-user-background
      color: $marketing-manager-user-color

    &.menu-manager
      background-color: $menu-manager-user-background
      color: $menu-manager-user-color
</style>
