<template>
  <steps-modal
    ref="challengeForm"
    v-model="form"
    form-id="challengeForm"
    title="Challenge Builder"
    :steps="steps"
    :read-only="readOnly"
    :message="modalMessage"
    confirm-close
    @valid-submit="finalizeDraftChallenge"
    @close="handleClose"
  >
    <template #actions>
      <b-button
        v-if="canExpireChallenge"
        type="is-danger"
        icon-right="calendar-times"
        rounded
        data-test-id="expire-button"
        :loading="isSubmittingChallenge"
        @click="confirmExpireChallenge(form)"
      >
        Expire
      </b-button>
      <b-button
        v-if="canCancelChallenge"
        type="is-danger"
        icon-right="trash-alt"
        rounded
        data-test-id="delete-button"
        @click="openCancelChallengeModal"
      >
        Remove
      </b-button>
      <b-button
        v-if="isDraft && isPersisted"
        type="is-danger"
        icon-right="trash-alt"
        rounded
        data-test-id="delete-button"
        @click="openDeleteDraftChallengeModal"
      >
        Delete
      </b-button>
      <b-button
        v-if="isDraft"
        id="save-draft-button"
        icon-right="save"
        rounded
        data-test-id="save-draft-button"
        :loading="isSubmittingChallenge"
        @click="saveDraftChallenge"
      >
        Save Draft
      </b-button>
    </template>
    <template #submit-button>
      <b-button
        id="finalize-button"
        data-test-id="finalize-button"
        type="is-primary"
        icon-right="check"
        rounded
        native-type="submit"
        :disabled="finalizeButtonDisabled"
        :loading="isSubmittingChallenge"
      >
        Finalize
      </b-button>
    </template>
  </steps-modal>
</template>

<script>
  import moment from 'moment-timezone';

  import { awardPolicyTypes, constraintModels, earnRuleStatuses } from '@/constants/earnRules';

  import AlertModal from '@/components/globals/alert-modal.vue';

  import getChangedResources from '@/helpers/get-changed-resources';

  import merchantMixin from '@/mixins/merchant';

  import EarnRule from '@/store/classes/EarnRule';
  import ConversionThreshold from '@/store/classes/ConversionThreshold';
  import Currency from '@/store/classes/Currency';
  import ItemGroup from '@/helpers/classes/ItemGroup';

  import AwardStep from './modal-steps/award-step.vue';
  import DetailsStep from './modal-steps/details-step.vue';
  import ReviewStep from './modal-steps/review-step.vue';
  import TimeFrameStep from './modal-steps/time-frame-step.vue';
  import ActivityStep from './modal-steps/activity-step.vue';

  export default {
    name: 'ChallengeModal',

    mixins: [merchantMixin],

    props: {
      challenge: {
        type: Object,
        required: true
      }
    },

    data() {
      return {
        earnRuleStatuses,
        form: {},
        isSubmittingChallenge: false,
        awardPolicyItemGroupTypes: [
          awardPolicyTypes.SPECIFIED_ITEM_POINTS_PER_DOLLAR_SPENT.type,
          awardPolicyTypes.SPECIFIED_ITEM_POINTS_PER_COUNT.type
        ],
        resourceErrors: {
          currency: {},
          earnRule: {},
          conversionThresholds: {}
        },
        errorMessages: [],
        errorSteps: [],
        challengeCache: null
      };
    },

    computed: {
      isDraft() {
        return this.challenge.portalDerivedStatus === earnRuleStatuses.DRAFT;
      },

      isPersisted() {
        return !!(this.form.currency.id && this.form.earnRule.id);
      },

      canCancelChallenge() {
        return this.challenge.portalDerivedStatus === earnRuleStatuses.SCHEDULED;
      },

      canExpireChallenge() {
        return this.challenge.portalDerivedStatus === earnRuleStatuses.ACTIVE;
      },

      readOnly() {
        return !this.isDraft;
      },

      finalizeButtonDisabled() {
        return this.readOnly
          || (!this.form.currency.id && !this.form.currency.publicId)
          || this.challengeCache !== JSON.stringify(this.form);
      },

      hasUnsavedChanges() {
        return this.challengeCache !== JSON.stringify(this.form);
      },

      isNewUnsavedChallenge() {
        return !this.form.currency.id && !this.form.currency.publicId;
      },

      allResourcesAreDrafts() {
        return [
          this.form.currency.isDraft,
          this.form.earnRule.isDraft,
          ...this.form.conversionThresholds.map(ct => ct.isDraft)
        ].every(Boolean);
      },

      allResourcesAreFinalized() {
        return [
          !this.form.currency.isDraft,
          !this.form.earnRule.isDraft,
          ...this.form.conversionThresholds.map(ct => !ct.isDraft)
        ].every(Boolean);
      },

      modalMessage() {
        if (this.hasUnsavedChanges) {
          return 'You have unsaved changes. Please save your changes before finalizing this challenge.';
        }
        else if (this.isNewUnsavedChallenge) {
          return 'The challenge must be saved as a draft before finalizing.';
        }
        else if (this.allResourcesAreDrafts || this.allResourcesAreFinalized) {
          return '';
        }
        else {
          return 'There were errors when attempting to finalize this Challenge. The disabled fields were successfully saved. Please make any necessary changes and try finalizing again.';
        }
      },

      saveMessage() {
        return 'Finalizing this challenge will make it live and no longer editable.';
      },

      steps() {
        return [
          {
            label: 'Details',
            component: DetailsStep,
            props: {
              currencyErrors: this.resourceErrors.currency
            }
          },
          {
            label: 'Activity',
            component: ActivityStep,
            props: {
              currencyErrors: this.resourceErrors.currency,
              earnRuleErrors: this.resourceErrors.earnRule
            }
          },
          {
            label: 'Award',
            component: AwardStep,
            props: {
              challengeIsDraft: this.isDraft,
              conversionThresholdErrors: this.resourceErrors.conversionThresholds
            }
          },
          {
            label: 'Time Frame',
            component: TimeFrameStep,
            props: {
              resourceErrors: this.resourceErrors
            }
          },
          {
            label: 'Review',
            component: ReviewStep
          }
        ];
      }
    },

    created() {
      this.onCreated();
    },

    methods: {
      async onCreated() {
        this.form = this.$clone(this.challenge);
        await this.setItemGroups();
        this.setChallengeCache();
      },

      setChallengeCache() {
        this.challengeCache = JSON.stringify(this.form);
      },

      async handleClose() {
        if (!this.form.currency.isDraft) {
          await this.updateCurrencyPublicIdOnDraftResources();
        }
        this.$parent.close();
      },

      async updateCurrencyPublicIdOnDraftResources() {
        const promises = [];
        if (this.form.earnRule.isDraft) {
          const revertedEarnRule = this.$clone(this.challenge.earnRule);
          revertedEarnRule.awardPolicy.currencyPublicId = this.form.currency.publicId;
          promises.push(EarnRule.updateDraftEarnRule(revertedEarnRule));
        }
        this.form.conversionThresholds.forEach(async (ct) => {
          if (ct.isDraft) {
            const revertedConversionThreshold = this.$clone(this.challenge.conversionThresholds.find(c => c.id === ct.id));
            revertedConversionThreshold.currencyPublicId = this.form.currency.publicId;
            promises.push(ConversionThreshold.updateDraftConversionThreshold(revertedConversionThreshold));
          }
        });

        return promises;
      },

      async setItemGroups() {
        // Cast all the item group data as instances of the ItemGroup class
        if (this.awardPolicyItemGroupTypes.includes(this.form.earnRule.awardPolicy.awardPolicyType)) {
          const itemGroup = this.form.earnRule.isDraft && this.form.earnRule.awardPolicy.itemGroupPublicId
            // Draft Earn Rules don't have item group metadata, so we need to fetch it
            ? await ItemGroup.getLoyaltyItemGroup(this.$_selectedMerchantId, this.form.earnRule.awardPolicy.itemGroupPublicId)
            : this.form.earnRule.awardPolicy.itemGroup;

          this.$set(this.form.earnRule.awardPolicy, 'itemGroup', new ItemGroup(itemGroup));
        }

        if (this.form.earnRule.earnRuleConstraints?.length) {
          const updatedConstraints = await Promise.all(
            this.form.earnRule.earnRuleConstraints.map(async (constraint) => {
              if (constraint.constraintType === constraintModels.SPECIFIED_ITEM_GROUP.type) {
                const itemGroup = this.form.earnRule.isDraft && constraint.itemGroupPublicId
                  ? await ItemGroup.getLoyaltyItemGroup(this.$_selectedMerchantId, constraint.itemGroupPublicId)
                  : constraint.itemGroup;
                constraint.itemGroup = new ItemGroup(itemGroup);
              }
              return constraint;
            })
          );
          this.$set(this.form.earnRule, 'earnRuleConstraints', updatedConstraints);
        }
      },

      async handleItemGroup(itemGroup) {
        if (typeof itemGroup.publicId !== 'symbol' && !itemGroup.hasChanged()) {
          return itemGroup;
        }
        else {
          try {
            const loyaltyItemGroup = await ItemGroup.createLoyaltyItemGroup(this.$_selectedMerchantId, itemGroup);
            return new ItemGroup(loyaltyItemGroup);
          }
          catch (error) {
            this.$_onRequestError({
              toastOptions: {
                message: 'There was an error creating an item group associated with this challenge. Please check your item groups and try again.'
              },
              error
            });
            throw error;
          }
        }
      },

      async saveDraftChallenge() {
        if (this.form.currency.id) {
          await this.updateDraftChallenge();
        }
        else {
          await this.createDraftChallenge();
        }
      },

      async createDraftChallenge() {
        try {
          this.isSubmittingChallenge = true;
          const currencyId = await Currency.createDraftCurrency(this.form.currency);
          this.mapCurrencyIdToChallengeResources(currencyId);
          await this.createDraftResources();

          this.$_onRequestSuccess({
            toastOptions: {
              message: 'Successfully created your draft challenge!'
            },
            options: { closeParent: true }
          });
        }
        catch (error) {
          this.$_onRequestError({
            toastOptions: {
              message: 'There was an error creating your draft challenge'
            },
            error
          });
        }
        finally {
          this.isSubmittingChallenge = false;
        }
      },

      async updateDraftChallenge() {
        try {
          this.isSubmittingChallenge = true;
          if (this.form.currency.isDraft) {
            await Currency.updateDraftCurrency(this.form.currency);
          }

          if (this.form.earnRule.isDraft) {
            const updatedEarnRule = await this.createEarnRulePayload();
            await EarnRule.updateDraftEarnRule(updatedEarnRule);
          }

          const conversionThresholdPromises = await this.buildDraftConversionThresholdPromises();

          await Promise.all(conversionThresholdPromises);

          this.$_onRequestSuccess({
            toastOptions: {
              message: 'Successfully updated your draft challenge!'
            },
            options: { closeParent: true }
          });
        }
        catch (error) {
          this.$_onRequestError({
            toastOptions: {
              message: 'There was an error updating your draft challenge'
            },
            error
          });
        }
        finally {
          this.isSubmittingChallenge = false;
        }
      },

      finalizeDraftChallenge() {
        this.$buefy.modal.open({
          parent: this,
          component: AlertModal,
          hasModalCard: true,
          trapFocus: true,
          customClass: 'auto-width',
          canCancel: ['escape', 'outside'],
          props: {
            autoWidth: true,
            title: 'Finalize Challenge',
            message: this.saveMessage,
            secondaryMessage: 'Are you sure you\'d like to proceed?',
            horizontal: true,
            showCloseButton: false,
            buttons: [
              { text: 'Cancel' },
              {
                text: 'Finalize',
                primary: true,
                onClick: this.finalizeChallenge
              }
            ]
          }
        });
      },

      openCancelChallengeModal() {
        this.$buefy.modal.open({
          parent: this,
          component: AlertModal,
          hasModalCard: true,
          trapFocus: true,
          customClass: 'auto-width',
          canCancel: ['escape', 'outside'],
          props: {
            autoWidth: true,
            title: 'Remove Challenge',
            message: 'This challenge will be cancelled and will no longer be viewable.',
            secondaryMessage: "Are you sure you'd like to proceed?",
            horizontal: true,
            type: 'is-danger',
            showCloseButton: false,
            buttons: [
              { text: 'Cancel' },
              {
                text: 'Remove',
                primary: true,
                onClick: this.cancelChallenge
              }
            ]
          }
        });
      },

      openDeleteDraftChallengeModal() {
        this.$buefy.modal.open({
          parent: this,
          component: AlertModal,
          hasModalCard: true,
          trapFocus: true,
          customClass: 'auto-width',
          canCancel: ['escape', 'outside'],
          props: {
            autoWidth: true,
            title: 'Delete Challenge',
            message: 'This challenge will be deleted.',
            secondaryMessage: "Are you sure you'd like to proceed?",
            horizontal: true,
            type: 'is-danger',
            showCloseButton: false,
            buttons: [
              { text: 'Cancel' },
              {
                text: 'Remove',
                primary: true,
                onClick: this.deleteDraftChallenge
              }
            ]
          }
        });
      },

      async finalizeChallenge() {
        try {
          this.isSubmittingChallenge = true;

          if (this.form.currency.isDraft) {
            await this.promoteCurrency();
          }

          if (this.form.earnRule.isDraft) {
            await this.promoteEarnRule();
          }

          await this.promoteConversionThresholds();

          if (this.errorMessages.length) {
            throw new Error(`Challenge failed to finalize ${this.errorMessages.join(', ')}`);
          }

          this.$_onRequestSuccess({
            toastOptions: {
              message: 'Successfully finalized your challenge!'
            },
            options: { closeParent: true }
          });
        }
        catch (error) {
          this.$_onRequestError({
            toastOptions: {
              message: error
            }
          });
        }
        finally {
          this.isSubmittingChallenge = false;
          if (this.errorSteps.length) {
            const firstErrorStep = Math.min(...this.errorSteps);
            this.$emit('navigate-to-step', firstErrorStep);
          }
          this.errorMessages = [];
          this.errorSteps = [];
        }
      },

      async promoteCurrency() {
        try {
          const currency = await Currency.promoteDraftCurrency(this.form.currency);
          Currency.delete(this.form.currency.publicId);
          this.form.currency = new Currency(currency);
          this.setChallengeCache();
          this.mapCurrencyIdToChallengeResources(currency.publicId);
        }
        catch (error) {
          this.errorMessages.push(error.data.message);
          this.errorSteps.push(0);
          this.$set(this.resourceErrors, 'currency', error.data.errors);
        }
        // If Currency fails, the other resources will fail. Throw an error hear to prevent unnecessary requests
        if (this.errorMessages.length) {
          throw new Error(`Challenge failed to finalize: ${this.errorMessages.join(', ')}`);
        }
      },

      async promoteEarnRule() {
        try {
          const updatedEarnRule = await this.createEarnRulePayload();
          const earnRule = await EarnRule.promoteDraftEarnRule(updatedEarnRule);
          EarnRule.delete(this.form.earnRule.publicId);
          this.form.earnRule = new EarnRule(earnRule);
          this.setChallengeCache();
        }
        catch (error) {
          this.errorMessages.push(error.data.message);
          this.errorSteps.push(1);
          this.$set(this.resourceErrors, 'earnRule', error.data.errors);
        }
      },

      async promoteConversionThresholds() {
        const conversionThresholdPromises = [];

        this.form.conversionThresholds.forEach((ct) => {
          if (ct.isDraft) {
            conversionThresholdPromises.push(ConversionThreshold.promoteDraftConversionThreshold(ct));
          }
        });

        await Promise.allSettled(conversionThresholdPromises).then((results) => {
          const conversionThresholdErrors = {};
          results.forEach((result) => {
            if (result.status === 'rejected') {
              conversionThresholdErrors[result.reason.conversionThresholdId] = result.reason.data.errors;
            }
            else if (result.status === 'fulfilled') {
              ConversionThreshold.delete(result.value.draftPublicId);
              this.form.conversionThresholds = this.form.conversionThresholds.map((ct) => {
                if (ct.publicId === result.value.draftPublicId) {
                  return new ConversionThreshold(result.value);
                }
                return ct;
              });
              this.setChallengeCache();
            }
          });
          this.$set(this.resourceErrors, 'conversionThresholds', conversionThresholdErrors);

          const anyFailed = results.some(result => result.status === 'rejected');
          if (anyFailed) {
            this.errorMessages.push(results[0].reason.data.message);
            this.errorSteps.push(2);
          }
        });
      },

      async cancelChallenge() {
        const earnRulePublicId = this.form.earnRule.publicId;
        const conversionThresholdPublicIds = this.form.conversionThresholds.map(ct => ct.publicId);
        const currencyPublicId = this.form.currency.publicId;
        try {
          await Promise.all([
            EarnRule.deleteEarnRule(earnRulePublicId),
            conversionThresholdPublicIds.map(id => ConversionThreshold.deleteConversionThreshold(id)),
            Currency.deleteCurrency(currencyPublicId)
          ]);
          this.$_onRequestSuccess({
            toastOptions: {
              message: 'Successfully cancelled your challenge!'
            },
            options: { closeParent: true }
          });
        }
        catch (error) {
          this.$_onRequestError({
            toastOptions: {
              message: 'There was an error cancelling your challenge.'
            },
            error
          });
        }
      },

      async deleteDraftChallenge() {
        const earnRuleDraftId = this.form.earnRule.id;
        const currencyDraftId = this.form.currency.id;
        try {
          await Promise.all([
            EarnRule.deleteDraftEarnRule(earnRuleDraftId),
            this.form.conversionThresholds.map(ct => ConversionThreshold.deleteDraftConversionThreshold(ct)),
            Currency.deleteDraftCurrency(currencyDraftId)
          ]);
          this.$_onRequestSuccess({
            toastOptions: {
              message: 'Successfully deleted your challenge!'
            },
            options: { closeParent: true }
          });
        }
        catch (error) {
          this.$_onRequestError({
            toastOptions: {
              message: 'There was an error deleting your challenge.'
            },
            error
          });
        }
      },

      mapCurrencyIdToChallengeResources(currencyPublicId) {
        this.form.earnRule.awardPolicy.currencyPublicId = currencyPublicId;
        this.form.conversionThresholds = this.form.conversionThresholds.map(ct => ({ ...ct, currencyPublicId }));
      },

      async createDraftResources() {
        const updatedEarnRule = await this.createEarnRulePayload();
        const earnRulePromise = EarnRule.createDraftEarnRule(updatedEarnRule);
        const conversionThresholdPromises = this.buildDraftConversionThresholdPromises();

        return Promise.all([earnRulePromise].concat(conversionThresholdPromises));
      },

      async buildDraftConversionThresholdPromises() {
        const promises = [];
        this.form.conversionThresholds.forEach((ct) => {
          if (ct.isDraft) {
            const result = ct.id
              ? ConversionThreshold.updateDraftConversionThreshold(ct)
              : ConversionThreshold.createDraftConversionThreshold(ct);

            promises.push(result);
          }
        });

        const { removed } = getChangedResources({
          newArray: this.form.conversionThresholds.map(ct => ct.id),
          oldArray: this.challenge.conversionThresholds.map(ct => ct.id)
        });

        removed.forEach((id) => {
          const conversionThreshold = this.challenge.conversionThresholds.find(ct => ct.id === id);
          promises.push(ConversionThreshold.deleteDraftConversionThreshold(conversionThreshold));
        });

        return Promise.all(promises);
      },

      async createEarnRulePayload() {
        // Create item groups for any new or updated SPECIFIED_ITEM_GROUP constraints and attach new publidId to constraint payload
        this.form.earnRule.earnRuleConstraints = await Promise.all(this.$clone(this.form.earnRule.earnRuleConstraints).map(async (constraint) => {
          if (constraint.constraintType === constraintModels.SPECIFIED_ITEM_GROUP.type) {
            const itemGroup = await this.handleItemGroup(constraint.itemGroup);
            const updatedConstraint = {
              ...constraint,
              itemGroup,
              itemGroupPublicId: itemGroup.publicId
            };
            return updatedConstraint;
          }
          else {
            return constraint;
          }
        }));

        // Create item groups for any item group award policies and attach publicId to form
        if (this.awardPolicyItemGroupTypes.includes(this.form.earnRule.awardPolicy.awardPolicyType)) {
          const awardPolicyItemGroup = await this.handleItemGroup(this.form.earnRule.awardPolicy.itemGroup);

          this.form.earnRule.awardPolicy = {
            ...this.form.earnRule.awardPolicy,
            itemGroupPublicId: awardPolicyItemGroup.publicId,
            itemGroup: awardPolicyItemGroup
          };
        }

        return {
          ...this.form.earnRule,
          awardPolicy: {
            ...this.form.earnRule.awardPolicy
          }
        };
      },


      confirmExpireChallenge(challenge) {
        this.$buefy.modal.open({
          parent: this,
          component: AlertModal,
          hasModalCard: true,
          trapFocus: true,
          canCancel: ['escape', 'outside'],
          props: {
            icon: 'calendar-times',
            title: 'Expire Challenge',
            message: 'This will schedule the challenge to expire at the end of the day. This action is irreversible.',
            secondaryMessage: 'Are you sure you want to expire this challenge?',
            type: 'is-danger',
            horizontal: true,
            showCloseButton: false,
            buttons: [
              { text: 'Cancel' },
              {
                text: 'Expire',
                primary: true,
                onClick: () => this.expireChallenge(challenge)
              }
            ]
          }
        });
      },

      async expireChallenge(challenge) {
        this.isSubmittingChallenge = true;
        try {
          const expirePromises = this.buildExpireChallengePromises(challenge);
          await Promise.all(expirePromises);
          this.$_onRequestSuccess({
            toastOptions: {
              message: 'Successfully expired your challenge!'
            },
            options: { closeParent: true }
          });
        }
        catch (error) {
          this.$_onRequestError({
            toastOptions: {
              message: 'There was an error expiring your challenge'
            },
            error
          });
        }
        finally {
          this.isSubmittingChallenge = false;
        }
      },

      buildExpireChallengePromises(challenge) {
        const today = moment().utc().format('YYYY-MM-DD');
        const updatedEarnRule = { ...challenge.earnRule, endDate: today };
        const conversionThresholdPromises = challenge.conversionThresholds.map(ct => ConversionThreshold.updateConversionThreshold({
          ...ct,
          endDate: today
        }));

        return [
          EarnRule.updateEarnRule(updatedEarnRule),
          ...conversionThresholdPromises
        ];
      }
    }
  };
</script>
