<template>
  <div v-show="visibleDocumentationLinks.length" class="documentation-wrapper">
    <b-dropdown
      class="documentation-dropdown has-extra-shadow"
      :triggers="['click']"
      aria-role="list"
      position="is-bottom-left"
      :mobile-modal="false"
    >
      <template #trigger>
        <b-button
          v-if="!isFetchingAuthToken"
          label=""
          type="is-info"
          icon-right="question-circle"
        />
        <b-icon
          v-else
          type="is-primary"
          pack="fad"
          icon="spinner-third"
          class="spin pad-md"
        />
      </template>

      <b-dropdown-item class="pad-y-none" custom>
        <a
          :href="getFullUrl(documentationLinks[0].url)"
          target="_blank"
          class="mar-r-md has-text-weight-bold no-wrap-text"
          style="font-size: 1.2rem"
        >
          {{ documentationLinks[0].display }}
        </a>
      </b-dropdown-item>

      <hr class="dropdown-divider">

      <template v-for="(link, index) in visibleDocumentationLinks">

        <!-- IF SUB URLS EXIST -->
        <template v-if="link.children && getPermissionForSubUrls(link.type)">
          <b-dropdown-item
            :key="link.type + index + 'with-sub-urls'"
            :value="getFullUrl(link.url)"
            aria-role="listitem"
            class="is-relative"
            :custom="!link.url"
            :has-link="link.url"
          >
            <b-icon
              v-if="link.url && isCurrentRoute(link.match)"
              class="left-caret"
              size="is-small"
              type="is-primary"
              icon="caret-right"
            />
            <a
              v-if="link.url"
              :class="['documentation-link', { 'is-current-route': isCurrentRoute(link.match)}]"
              :href="getFullUrl(link.url)"
              target="_blank"
            >
              {{ link.display }}
            </a>
            <span v-else>{{ link.display }}</span>
          </b-dropdown-item>

          <template v-for="subLink in link.children">
            <b-dropdown-item
              v-if="getPermissionForUrl(subLink.type)"
              :key="subLink.type + index + 'sub-url'"
              class="is-relative"
              :value="getFullUrl(subLink.url)"
              aria-role="listitem"
              :focusable="false"
              has-link
            >
              <b-icon
                v-if="isCurrentRoute(subLink.match)"
                class="left-caret mar-l"
                size="is-small"
                type="is-primary"
                icon="caret-right"
              />
              <a
                :class="['documentation-link', 'sub-url', { 'is-current-route': isCurrentRoute(link.match)}]"
                :href="getFullUrl(subLink.url)"
                target="_blank"
              >
                {{ subLink.display }}
              </a>
            </b-dropdown-item>
          </template>
          <hr v-if="index !== visibleDocumentationLinks.length - 1" :key="link.type + 'divider'" class="dropdown-divider">
        </template>

        <!-- IF NO SUB URLS -->
        <template v-else-if="link.url && getPermissionForUrl(link.type)">
          <b-dropdown-item
            :key="link.type + index"
            :value="getFullUrl(link.url)"
            aria-role="listitem"
            has-link
            class="is-size-6 is-relative"
          >
            <b-icon
              v-if="isCurrentRoute(link.match)"
              class="left-caret"
              size="is-small"
              type="is-primary"
              icon="caret-right"
            />
            <a
              :class="['documentation-link', { 'is-current-route': isCurrentRoute(link.match)}]"
              :href="getFullUrl(link.url)"
              target="_blank"
              :disabled="!link.match"
            >
              {{ link.display }}
            </a>
          </b-dropdown-item>
          <hr v-if="index !== visibleDocumentationLinks.length - 1" :key="link.type + 'divider'" class="dropdown-divider">
        </template>

      </template>
    </b-dropdown>
  </div>
</template>

<script>
  import { getJWT } from '@/api/documentation';
  import documentationLinks from '@/constants/documentationLinks';
  import merchantMixin from '@/mixins/merchant';
  import featurePermissionsMixin from '@/mixins/featurePermissions';

  export default {
    name: 'Documentation',

    mixins: [merchantMixin, featurePermissionsMixin],

    data() {
      return {
        documentationLinks,
        authToken: null,
        isFetchingAuthToken: false
      };
    },

    computed: {
      currentRoute() {
        return this.$route.name;
      },

      visibleDocumentationLinks() {
        return documentationLinks.filter(link => !link.isRootLink && this.getPermissionForUrl(link.type));
      }
    },

    created() {
      this.onCreated();
    },

    methods: {
      onCreated() {
        this.fetchJWT();
      },

      toggleLoading(value) {
        this.isFetchingAuthToken = value;
      },

      async fetchJWT() {
        try {
          this.toggleLoading(true);

          this.authToken = await getJWT();
        }
        catch (error) {
          this.$_onRequestError({
            toastOptions: {
              message: 'There was an error authorizing the documentation'
            },
            error
          });
        }
        finally {
          this.toggleLoading(false);
        }
      },

      getFullUrl(url) {
        return `${url}?auth_token=${this.authToken}`;
      },

      getPermissionForUrl(routeType) {
        return this.$_featurePermissions[routeType];
      },

      getPermissionForSubUrls(type) {
        const children = this.documentationLinks.find(url => url.type === type)?.children;
        return !!children && children.some(child => this.getPermissionForUrl(child.type));
      },

      isCurrentRoute(routeMatch) {
        const { name, path } = this.$route;
        return routeMatch.some(route => [name, path].includes(route));
      }
    }
  };
</script>

<style lang="sass" scoped>
  .documentation-wrapper
    border-radius: $radius-rounded !important

    .left-caret
      position: absolute
      bottom: 9px
      left: 0px
      z-index: 100

      ::v-deep i
        font-size: 1rem !important

    .documentation-dropdown
      border-radius: $radius-rounded !important
      box-shadow: $white 0px 0px 2px 1px
      display: flex
      justify-content: space-between

      &:hover
        background-color: lighten($primary, 10)
        box-shadow: $white 0px 0px 2px 1px

      .documentation-link
        &.sub-url
          padding-left: 2rem

      ::v-deep
        .button.is-info
          background-color: $white
          color: $primary

        i
          font-size: 1.5rem
</style>
