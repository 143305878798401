<template>
  <div>
    <transition name="fade-zoom">
      <div v-if="isVisible" class="confirmation-row">
        <div class="confirmation-buttons">
          <b-button rounded type="is-danger" size="is-small" @click="handleCancel"> <!-- eslint-disable-line -->
            Clear Changes
          </b-button>
          <slot>
            <b-button rounded type="is-primary" size="is-small" @click="handleSave"> <!-- eslint-disable-line -->
              Update
            </b-button>
          </slot>
        </div>
        <p class="confirmation-message is-size-7 has-text-weight-semibold">
          <b-icon class="is-hidden-mobile" pack="fad" icon="hand-point-left" /> {{ message }}
        </p>
      </div>
    </transition>
  </div>
</template>

<script>
  export default {
    name: 'ConfirmationRow',

    props: {
      message: {
        type: String,
        required: true
      },

      isVisible: {
        type: Boolean,
        default: true
      }
    },

    methods: {
      handleCancel() {
        this.$emit('cancel-changes');
      },
      handleSave() {
        this.$emit('save-changes');
      }
    }
  };
</script>

<style lang="sass" scoped>
  .confirmation-row
    display: flex
    align-items: center
    padding: 0.75rem 1rem
    background-color: $primary-lightest
    box-shadow: inset 0px 6px 6px -4px $primary-lighter, inset 0px -6px 6px -4px $primary-lighter

  .confirmation-buttons
    margin-right: 1rem
    * + *
      margin-left: 1rem

  .confirmation-message
    animation: shift 800ms cubic-bezier(0, 0.4, 0.65, 0.68) infinite
    color: $primary-darker

  @media (max-width: $tablet)
    .confirmation-row
      flex-direction: column

    .confirmation-message
      animation: none
      order: -1
      margin-bottom: 0.5rem

    .confirmation-buttons
      margin-right: 0
</style>
