<template>
  <div class="modal-card">
    <validated-form
      :ref="formName"
      auto-focus
      :form-id="formName"
      class="modal-form"
      :manual-validation="manualValidation"
      @valid-submit="$event => $emit('valid-submit', $event)"
      @invalid-submit="handleInvalidSubmit"
    >
      <div class="modal-card-head">
        <div class="modal-card-title">
          <p class="title is-4">{{ title }}</p>
          <p v-if="subtitle" class="subtitle is-6">{{ subtitle }}</p>
        </div>
        <b-steps
          v-model="activeStepIndex"
          size="is-small"
          :destroy-on-hide="false"
          :has-navigation="false"
          :mobile-mode="null"
          class="steps-nav"
        >
          <b-step-item
            v-for="(step, index) in steps"
            :id="`${step.label}-step-label`"
            :key="`${paramCase(step.label)}-step-label`"
            :visible="steps.length > 1"
            :step="index + 1"
            :clickable="!step.isDisabled"
            :label="step.label"
          />
        </b-steps>
        <div class="is-flex justify-end align-center">
          <div class="action-buttons">
            <slot name="actions" />
            <transition mode="out-in" name="fade-hinge">
              <b-button
                v-if="showNextButton"
                key="next"
                icon-right="chevron-right"
                rounded
                type="is-primary"
                :disabled="steps[activeStepIndex + 1].isDisabled"
                @click.prevent="activeStepIndex += 1"
              >
                Next
              </b-button>
              <slot v-else name="submit-button">
                <b-button
                  v-if="showSubmitButton"
                  key="submit"
                  :loading="loading"
                  icon-right="save"
                  type="is-primary"
                  rounded
                  native-type="submit"
                >
                  {{ submitButtonText }}
                </b-button>
              </slot>
            </transition>
          </div>
          <b-button @click="handleCloseModal">
            <b-icon icon="times" size="is-small" />
          </b-button>
        </div>
      </div>

      <div :class="['modal-card-body', { 'has-white-background': hasWhiteBackground }]">
        <b-message v-if="message" :type="messageType" class="is-compact has-shadow">
          {{ message }}
        </b-message>

        <fieldset :disabled="readOnly">
          <b-steps
            v-model="activeStepIndex"
            size="is-small"
            class="steps-container"
            :has-navigation="false"
            :mobile-mode="null"
          >
            <b-step-item
              v-for="(step, index) in steps"
              :id="`step-${index}`"
              :key="`${paramCase(step.label)}-step-body`"
              :step="index + 1"
              :label="step.label"
            >
              <component
                :is="step.component"
                v-bind="step.props"
                :is-active-step="activeStepIndex === index"
                :value="value"
                :mode="mode"
                :error="error"
                :reset-error="resetError"
                :read-only="readOnly"
                @input="val => $emit('input', val)"
                @navigate-to-step="navigateToStep"
                v-on="step.handlers"
              />
            </b-step-item>
          </b-steps>
        </fieldset>
      </div>
    </validated-form>
  </div>
</template>

<script>
  // TEST FILE
  import { camelCase, paramCase } from 'change-case';
  import confirmModalCloseMixin from '@/mixins/confirm-modal-close';


  export default {
    name: 'StepsModal',

    mixins: [confirmModalCloseMixin],

    props: {
      value: {
        type: Object,
        required: true
      },
      manualValidation: {
        type: Function,
        default: () => true
      },
      submitButtonText: {
        type: String,
        default: 'Save'
      },
      title: {
        type: String,
        required: true
      },
      subtitle: {
        type: String,
        default: null
      },
      loading: {
        type: Boolean,
        default: false
      },
      steps: {
        type: Array,
        required: true
      },
      error: {
        type: String,
        default: null
      },
      message: {
        type: String,
        default: null
      },
      messageType: {
        type: String,
        default: 'is-primary'
      },
      readOnly: {
        type: Boolean,
        default: false
      },
      resetError: {
        type: Function,
        default: null
      },
      confirmClose: {
        type: Boolean,
        default: false
      },
      hasWhiteBackground: {
        type: Boolean,
        default: false
      },
      showSubmitButton: {
        type: Boolean,
        default: true
      },
      mode: {
        type: String,
        default: 'create',
        validator(value) {
          return ['create', 'read', 'update'].includes(value);
        }
      }
    },

    data() {
      return {
        camelCase,
        paramCase,
        activeStepIndex: 0
      };
    },

    computed: {
      formName() {
        return `${camelCase(this.title)}Form`;
      },

      showNextButton() {
        const hasMultipleSteps = this.steps.length > 1;
        const isOnLastStep = this.activeStepIndex === this.steps.length - 1;
        return hasMultipleSteps && !isOnLastStep;
      }
    },

    methods: {
      navigateToStep(step) {
        this.activeStepIndex = step;
      },

      async handleCloseModal() {
        let modalClosed = true;
        if (this.confirmClose) {
          modalClosed = await this.$_confirmCloseModal({ programmatic: true });
        }

        if (modalClosed) {
          this.$emit('close');
        }
      },

      handleInvalidSubmit() {
        const firstInvalidInput = this.$el.querySelector('.invalid');
        const firstInvalidStep = firstInvalidInput.closest('div[id^="step"]');
        const invalidStepIndex = Number(firstInvalidStep.id.split('-')[1]);
        this.activeStepIndex = invalidStepIndex;
        this.$nextTick(() => {
          const inputToFocus = firstInvalidInput.querySelector('input, .field');
          inputToFocus.focus();
        });
      }
    }
  };
</script>

<style lang="sass" scoped>
  .modal-card-head
    display: grid
    grid-template-columns: repeat(3, 1fr)
    gap: 0 1.5rem

  @media (max-width: $desktop)
    .modal-card-head
      grid-template-columns: repeat(2, 1fr)
      gap: 1.5rem 1rem

    .steps-nav
      grid-column: 1/3
      grid-row: 2

  .modal-card-title
    flex-grow: 0

  .modal-card-body
    position: relative
    background-color: $grey-lightest

    &.has-white-background
      background-color: $white !important

  .modal-form
    display: flex
    flex-direction: column
    flex: 1
    overflow: hidden

  .action-buttons
    display: flex
    flex-wrap: wrap
    gap: $size-small
    align-items: center
    justify-content: flex-end
    padding: 0.25rem 1rem 0.25rem 0
    margin-right: 1rem
    border-right: 2px solid $grey-lighter

  .steps-nav
    ::v-deep
      .step-items
        flex-wrap: nowrap !important
      .step-item
        padding: 0 1rem
        &:first-child
          padding-left: 0
        &:last-child
          padding-right: 0
      .step-content
        display: none !important

  .steps-container
    ::v-deep
      .steps
        display: none
      .step-content
        max-width: $widescreen
        margin: 0 auto
        padding: 0 !important

  ::v-deep .message
    max-width: $widescreen
    margin: 0 auto

</style>
