<template>
  <b-autocomplete
    v-model="query"
    placeholder="Search for POS resource..."
    icon="search"
    class="is-inline has-icon-right has-dividers has-extra-shadow"
    expanded
    max-height="400"
    open-on-focus
    :icon-right="query ? 'times-circle' : ''"
    icon-right-clickable
    :data="searchResults"
    :disabled="disabled"
    :loading="isLoading"
    @typing="searchPosItems"
    @select="selectPosItem"
    @icon-right-click="clearSearch"
  >
    <template #empty>
      <template v-if="minCharacterMessage">
        {{ minCharacterMessage }}
      </template>
      <template v-else>
        {{ !searchResults
          ? 'Enter the Name or ID of a POS Resource'
          : 'No results found'
        }}
      </template>
    </template>
    <template slot-scope="props">
      <div class="is-flex align-center justify-between gap-lg">
        <div>
          <p class="has-text-weight-bold">
            {{ props.option.name }}
          </p>
          <p class="has-text-grey">
            {{ props.option.posItemId }} - {{ props.option.posType.name }}
          </p>
        </div>
      </div>
    </template>
  </b-autocomplete>
</template>

<script>
  import PosMenuItem from '@/store/classes/PosMenuItem';
  import debounce from 'lodash.debounce';

  export default {
    name: 'PosItemSearch',

    props: {
      selectedPosItemIds: {
        type: Array,
        default: () => []
      },
      disabled: {
        type: Boolean,
        default: false
      }
    },

    data() {
      return {
        query: null,
        searchResults: null,
        isLoading: false,
        minCharacterMessage: null
      };
    },

    methods: {
      // eslint-disable-next-line
      searchPosItems: debounce(async function (query = '') {
        if (query.length < 3) {
          this.searchResults = null;
          this.minCharacterMessage = query.length ? 'Please enter at least 3 characters to search' : null;
        }

        else {
          this.isLoading = true;
          this.minCharacterMessage = null;

          try {
            const results = await PosMenuItem.searchPOSResources(query);
            this.searchResults = results.filter(x => !this.selectedPosItemIds.includes(x.posItemId));
          }
          catch (error) {
            this.$_onRequestError({
              toastOptions: {
                message: 'There was an error searching POS Items'
              },
              error
            });
          }
          finally {
            this.isLoading = false;
          }
        }
      }, 500),

      selectPosItem(posItem) {
        this.$emit('select', posItem);
        this.searchResults = null;
      },

      clearSearch() {
        this.query = null;
        this.searchResults = null;
        this.minCharacterMessage = null;
      }
    }
  };
</script>
