<template>
  <validated-form
    ref="form"
    v-slot="{ errors }"
    auto-focus
    form-id="manageItemCategoryForm"
    @valid-submit="handleSubmit"
  >
    <modal-card title="Manage Categories" :subtitle="item.displayName">
      <validated-input
        name="categories"
        rules="required"
        label="Categories"
        :sub-label="`Select the categories you would like this item to appear in`"
        hide-error-messages
      >
        <b-dropdown
          v-model="selectedCategoryIds"
          expanded
          inline
          multiple
          aria-role="list"
          :class="errors.categories && errors.categories.length && 'has-errors'"
        >
          <b-dropdown-item
            v-for="category in categories"
            :key="category.id"
            :value="category.id"
            aria-role="listitem"
          >
            {{ category.displayName }}
          </b-dropdown-item>
        </b-dropdown>
      </validated-input>
      <b-message
        v-if="errors.categories && errors.categories.length"
        type="is-danger"
        size="is-small"
        has-icon
        class="is-compact has-shadow"
      >
        <p class="animated animated-slow tdFadeInRight">
          You must select at least one category
        </p>
      </b-message>

      <template #footer>
        <div class="buttons all-bold">
          <b-button
            rounded
            @click="$_confirmCloseModal({ programmatic: true })"
          >
            Cancel
          </b-button>
          <b-button
            v-tabbable
            rounded
            native-type="submit"
            type="is-primary"
            :loading="isLoading"
          >
            Save
          </b-button>
        </div>
      </template>
    </modal-card>
  </validated-form>
</template>

<script>
  import confirmModalCloseMixin from '@/mixins/confirm-modal-close';
  import Item from '@/store/classes/Item';
  import CategoryItem from '@/store/classes/CategoryItem';

  export default {
    name: 'CategoryAssociationModal',

    mixins: [confirmModalCloseMixin],

    props: {
      itemId: {
        type: Number,
        required: true
      },

      categories: {
        type: Array,
        required: true
      }
    },

    data() {
      return {
        isLoading: false,
        selectedCategoryIds: []
      };
    },

    computed: {
      item() {
        return Item.query().with('menuCategories').find(this.itemId);
      },

      currentCategoryIds() {
        return this.item.menuCategories.map(category => category.id);
      },

      newCategoryIds() {
        return this.selectedCategoryIds.filter(id => !this.currentCategoryIds.includes(id));
      },

      removedCategoryIds() {
        return this.currentCategoryIds.filter(id => !this.selectedCategoryIds.includes(id));
      }
    },

    created() {
      this.onCreated();
    },

    methods: {
      async onCreated() {
        this.selectedCategoryIds = [...this.currentCategoryIds];
      },

      toggleLoading(value) {
        this.isLoading = value;
      },

      async handleSubmit() {
        try {
          this.toggleLoading(true);

          const _newCategoryIds = this.$clone(this.newCategoryIds);

          await Promise.all([
            CategoryItem.addItemToCategories({
              menuCategoryIds: _newCategoryIds,
              menuItemId: this.itemId
            }),
            CategoryItem.deleteItemFromCategories({
              menuCategoryIds: this.removedCategoryIds,
              menuItemId: this.itemId
            })
          ]);

          if (_newCategoryIds.length) {
            await Item.fetchItem(this.itemId); /* The Item needs to *
            * be re-fetched so we can get the updated sort orders   *
            * for the new categories it was added to                *
            * * * * * * * * * * * * * * * * * * * * * * * * * * * * */
          }


          this.$_onRequestSuccess({
            toastOptions: {
              message: 'Successfully updated Category Associations'
            },
            options: {
              closeParent: true,
              emit: {
                name: 'item-categories-updated',
                arg: _newCategoryIds
              }
            }
          });
        }

        catch (error) {
          this.$_onRequestError({
            error,
            toastOptions: {
              message: 'There was an issue updating Category Associations'
            }
          });
        }

        finally {
          this.toggleLoading(false);
        }
      }
    }
  };
</script>
