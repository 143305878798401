/* eslint-disable import/no-cycle */
import http from '@/services/http';
import FetchModel from '@/store/classes/FetchModel';



export default class ItemSchedule extends FetchModel {
  static entity = 'menuItemSchedules'

  // FIELDS //////////////////////
  static fields() {
    return {
      id: this.attr(null),
      day: this.attr(null),
      hideHoursDisplay: this.attr(null),
      startTime: this.attr(null),
      endTime: this.attr(null),
      menuItemId: this.attr(null)
    };
  }

  static state() {
    return {
      fetching: false
    };
  }

  static $state() {
    return this.store().state.entities.menuItemSchedules;
  }


  // ACTIONS //////////////////////
  static async fetchSchedules(itemId, options = {}) {
    await this.fetchData({
      endpoint: `menu_items/${itemId}/menu_item_schedules`,
      options,
      transformData: data => data.menuItemSchedules,
      ormMethod: 'insert',
      customQuery: this.query().where('menuItemId', itemId)
    });
  }

  static async createSchedule({ itemId, schedule }) {
    try {
      const response = await http.post(`menu_items/${itemId}/menu_item_schedules`, {
        menuItemSchedule: schedule
      });

      this.insert({ data: response.data.menuItemSchedule });
    }

    catch (error) {
      throw error;
    }
  }

  static async updateSchedule(schedule) {
    try {
      const response = await http.put(`menu_item_schedules/${schedule.id}`, {
        menuItemSchedule: schedule
      });

      this.insert({ data: response.data.menuItemSchedule });
    }

    catch (error) {
      throw error;
    }
  }

  static async deleteSchedule(scheduleId) {
    try {
      await http.delete(`menu_item_schedules/${scheduleId}`);

      this.delete(scheduleId);
    }

    catch (error) {
      throw error;
    }
  }
}
