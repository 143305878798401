<template>
  <div class="card-group">
    <validated-form
      v-if="showKDS"
      ref="form"
      form-id="guestNotificationsForm"
      data-test-id="guest-notifications-form"
      @valid-submit="updateGuestNotifications"
    >
      <panel title="Guest Notifications">
        <template #buttons>
          <b-button
            v-if="[
              'orderCompleteCustomerNotificationEnabled',
              'orderInProgressCustomerNotificationEnabled'
            ].some(field => $can('update', 'StoreMapping', field))"
            rounded
            :loading="isSubmittingGuestNotificationForm"
            size="is-medium"
            native-type="submit"
            type="is-primary"
            class="is-bold"
          >
            Save
          </b-button>
        </template>

        <div class="is-grid col-min-400 gap">
          <check-button
            v-model="form.orderInProgressCustomerNotificationEnabled"
            :disabled="!$can('update', 'StoreMapping', 'orderInProgressCustomerNotificationEnabled')"
            label="Order In Progress"
            sublabel="Guests will be notified via SMS when their order is moved to In Prep in the Order Dashboard"
          />

          <check-button
            v-model="form.orderCompleteCustomerNotificationEnabled"
            :disabled="!$can('update', 'StoreMapping', 'orderCompleteCustomerNotificationEnabled')"
            label="Order Complete"
            sublabel="Guests will be notified via SMS when their order is moved to Completed in the Order Dashboard"
          />

          <check-button
            v-model="form.orderNewCustomerNotificationEnabled"
            :disabled="!$can('update', 'StoreMapping', 'orderNewCustomerNotificationEnabled')"
            label="Order New"
            sublabel="Guests will be notified via SMS when their order is submitted"
          />

          <check-button
            v-model="form.orderCanceledCustomerNotificationEnabled"
            :disabled="!$can('update', 'StoreMapping', 'orderCanceledCustomerNotificationEnabled')"
            label="Order Canceled"
            sublabel="Guests will be notified via SMS if their order is canceled"
          />
        </div>
      </panel>

    </validated-form>

    <validated-form
      ref="form"
      form-id="storeNotificationsForm"
      @valid-submit="updateStoreNotifications"
    >
      <panel
        title="Store Notification Types"
        subtitle="These settings control which notification types are available at this location."
      >
        <template #buttons>
          <b-button
            v-if="[
              'emailOrderReceiptEnabled',
              'smsOrderReceiptEnabled',
              'onSiteTextToPayNotificationEnabled',
              'orderPaidNotificationEnabled'
            ].some(field => $can('update', 'StoreMapping', field))"
            rounded
            :loading="isSubmittingStoreNotificationForm"
            size="is-medium"
            native-type="submit"
            type="is-primary"
            class="is-bold"
          >
            Save
          </b-button>
        </template>

        <div class="dist-y-md">
          <div class="is-flex align-center" data-test-id="new-order-notifications">
            <label class="label mar-b-none">All New Order Notifications</label>
            <b-icon
              v-tippy="{
                content: 'Select how recipients should be messaged',
                maxWidth: 300,
                delay: [150, 0]
              }"
              icon="info-square"
              size="is-small"
              type="is-grey"
              class="mar-l-xs mar-r"
            />
            <check-button
              v-model="form.emailOrderReceiptEnabled"
              :disabled="!$can('update', 'StoreMapping', 'emailOrderReceiptEnabled')"
              compact
              label="Email"
            />
            <check-button
              v-model="form.smsOrderReceiptEnabled"
              :disabled="!$can('update', 'StoreMapping', 'smsOrderReceiptEnabled')"
              compact
              label="SMS"
            />
          </div>

          <div v-if="$_selectedMerchant.cateringEnabled" class="is-flex align-center">
            <label class="label mar-b-none">Only Catering Orders</label>
            <b-icon
              v-tippy="{
                content: 'Turning this on will allow a recipient to receive notifications for catering order only',
                maxWidth: 300,
                delay: [150, 0]
              }"
              icon="info-square"
              size="is-small"
              type="is-grey"
              class="mar-l-xs mar-r"
            />
            <b-switch
              v-model="form.cateringNotificationEnabled"
              :disabled="!$can('update', 'StoreMapping', 'cateringNotificationEmail')"
              class="has-text-weight-bold"
            />
          </div>

          <div class="is-grid col-min-400 gap">
            <check-button
              v-if="hasTextToPayEnabled && $_selectedMerchant.features.iAmHere"
              v-model="form.onSiteTextToPayNotificationEnabled"
              :disabled="!$can('update', 'StoreMapping', 'onSiteTextToPayNotificationEnabled')"
              label="I Am Here (Text To Pay)"
              sublabel="Selecting this will enable this notification type in the form below"
              data-test-id="i-am-here-text-to-pay"
            />

            <check-button
              v-if="hasTextToPayEnabled"
              v-model="form.orderPaidNotificationEnabled"
              :disabled="!$can('update', 'StoreMapping', 'orderPaidNotificationEnabled')"
              label="Order Paid Notifications"
              sublabel="Selecting this will enable this notification type in the form below"
              data-test-id="order-paid-notifications"
            />
          </div>
        </div>
      </panel>
    </validated-form>

    <panel
      title="Recipients"
      subtitle="Configure which notifications each recipient receives."
      no-padding
    >
      <b-table
        :data="tableData"
        :paginated="isPaginated"
        class="is-middle-aligned pad-x"
        pagination-simple
        :per-page="pageLimit"
        :loading="storeLoading"
      >
        <b-table-column v-slot="{ row }" sortable field="fullName" label="Name">
          <span
            :class="$can('read', 'StoreMappingCommunication') && 'link'"
            @click="$can('read', 'StoreMappingCommunication') ? openCommunicationModal({ mode: 'update', storeCommunicationEdited: row }) : null"
          >
            {{ row.name }}
          </span>
        </b-table-column>

        <b-table-column v-slot="{ row }" field="email" label="Email">
          <span :class="!row.emailAddress && 'has-text-grey'">
            {{ row.emailAddress || 'N/A' }}
          </span>
        </b-table-column>

        <b-table-column v-slot="{ row }" field="phone" label="SMS Number">
          <span v-if="!row.phoneNumber" class="has-text-grey">N/A</span>
          <span v-else>{{ row.phoneNumber | phoneNumber }}</span>
        </b-table-column>

        <b-table-column v-slot="{ row }" centered field="communicationStatus" label="Status">
          <b-icon
            :icon="row.isActive ? 'check' : 'times'"
            :type="row.isActive ? 'is-success' : 'is-danger'"
            size="is-small"
          />
        </b-table-column>

        <b-table-column
          v-if="$can('update', 'StoreMappingCommunication')"
          v-slot="{ row }"
          centered
          cell-class="actions"
          label="Actions"
          width="1"
        >
          <b-dropdown aria-role="list" position="is-bottom-left">
            <b-button slot="trigger" class="is-transparent" type="is-white">
              <b-icon icon="ellipsis-v" pack="far" size="is-small" />
            </b-button>

            <b-dropdown-item @click="toggleActiveStatus(row)">
              <b-icon :icon="row.isActive ? 'lightbulb-slash' : 'lightbulb-on'" class="mar-r-sm" size="is-small" />
              {{ row.isActive ? 'Disable' : 'Enable' }} Notifications
            </b-dropdown-item>
            <hr class="dropdown-divider">
            <b-dropdown-item class="is-danger" @click="openDeleteCommunicationConfirmation(row)">
              <b-icon icon="trash-alt" class="mar-r-sm" size="is-small" />
              Delete Recipient
            </b-dropdown-item>
          </b-dropdown>
        </b-table-column>
        <template #empty>
          <b-button
            v-if="$can('create', 'StoreMappingCommunication')"
            class="is-transparent"
            icon-left="plus"
            inverted
            type="is-primary"
            @click="openCommunicationModal"
          >
            Recipient
          </b-button>
          <p v-else class="has-text-grey">No Recipients</p>
        </template>
      </b-table>
      <div v-if="store.storeMappingCommunications.length && $can('create', 'StoreMappingCommunication')" class="mar-x pad-y-sm has-border-top has-border-grey-lighter">
        <b-button
          class="is-transparent"
          icon-left="plus"
          inverted
          type="is-primary"
          @click="openCommunicationModal"
        >
          Recipient
        </b-button>
      </div>
    </panel>
  </div>
</template>

<script>
  import Store from '@/store/classes/Store';
  import StoreCommunication from '@/store/classes/StoreCommunication';
  import addEditCommunicationModal from './add-edit-communication-modal.vue';
  import merchantMixin from '@/mixins/merchant';
  import appSettingResourceMap from '@/constants/merchantAppSettingResources';
  import filterObjectKeys from '@/helpers/filter-object-keys';

  export default {
    name: 'StoreCommunications',

    mixins: [merchantMixin],

    props: {
      store: {
        type: Object,
        required: true
      }
    },

    data() {
      return {
        pageLimit: 20,
        form: {},
        guestFormNotificationKeys: [
          'storeId',
          'orderCompleteCustomerNotificationEnabled',
          'orderInProgressCustomerNotificationEnabled',
          'orderNewCustomerNotificationEnabled',
          'orderCanceledCustomerNotificationEnabled'
        ],
        storeNotificationFormKeys: [
          'id',
          'storeId',
          'emailOrderReceiptEnabled',
          'smsOrderReceiptEnabled',
          'onSiteTextToPayNotificationEnabled',
          'orderPaidNotificationEnabled',
          'cateringNotificationEnabled'
        ],
        isSubmittingStoreNotificationForm: false,
        isSubmittingGuestNotificationForm: false
      };
    },

    computed: {
      storeLoading() {
        return Store.$state().fetchingAll;
      },

      tableData() {
        return this.store?.storeMappingCommunications.length ? this.store.storeMappingCommunications : [];
      },

      isPaginated() {
        return Boolean(this.store?.storeMappingCommunications.length) && this.store.storeMappingCommunications.length > this.pageLimit;
      },

      hasTextToPayEnabled() {
        return this.$_selectedMerchant?.merchantAppSettingResources?.some(resource => resource.key === appSettingResourceMap.TEXT_TO_PAY && resource.value === true);
      },

      showKDS() {
        return this.$_selectedMerchant?.features?.showKds;
      }
    },

    watch: {
      store: {
        handler() {
          this.setForm();
        },
        immediate: true
      }
    },

    methods: {
      setForm() {
        this.form = this.$clone(this.store);
      },

      openDeleteCommunicationConfirmation({ name, id }) {
        this.$buefy.dialog.confirm({
          title: 'Delete Recipient',
          message: `<b>${name}</b> will no longer receive notifications. Are you sure you want to remove this recipient?`,
          onConfirm: () => this.deleteStoreCommunication({ name, id }),
          confirmText: 'Remove',
          type: 'is-danger',
          hasIcon: true,
          icon: 'trash-alt'
        });
      },

      openCommunicationModal({ mode, storeCommunicationEdited }) {
        this.$buefy.modal.open({
          parent: this,
          component: addEditCommunicationModal,
          hasModalCard: true,
          trapFocus: true,
          canCancel: false,
          customClass: 'auto-width',
          props: { mode, storeCommunicationEdited }
        });
      },

      async toggleActiveStatus(storeCommunication) {
        const newStatus = !storeCommunication.isActive;
        const statusText = newStatus ? 'enabled' : 'disabled';
        const errorStatusText = newStatus ? 'enable' : 'disable';
        try {
          const updatedStoreCommunication = {
            ...storeCommunication,
            isActive: newStatus
          };
          await StoreCommunication.updateStoreCommunication(updatedStoreCommunication);
          this.$_onRequestSuccess({
            toastOptions: {
              message: `Location notifications have been ${statusText} for <b>${storeCommunication.name}</b>!`
            }
          });
        }
        catch (error) {
          this.$_onRequestError({
            toastOptions: {
              message: `Unable to ${errorStatusText} location notifications for ${storeCommunication.name}`
            },
            error
          });
        }
      },

      async deleteStoreCommunication({ name, id }) {
        try {
          await StoreCommunication.deleteStoreCommunication(id);
          this.$_onRequestSuccess({
            toastOptions: {
              message: `Successfully removed location notifications for ${name}!`
            }
          });
        }
        catch (error) {
          this.$_onRequestError({
            error,
            toastOptions: {
              message: `Unable to remove location notifications for ${name}`
            }
          });
        }
      },

      async updateGuestNotifications() {
        try {
          this.isSubmittingGuestNotificationForm = true;
          const guestNotificationsPayload = filterObjectKeys(this.form, this.guestFormNotificationKeys, false);
          await Store.updateStore(guestNotificationsPayload);

          this.$_onRequestSuccess({
            toastOptions: { message: '<b>Guest</b> notifications have been successfully updated!' }
          });
        }
        catch (error) {
          this.$_onRequestError({ error, toastOptions: { message: 'Unable to update guest notification types' } });
        }
        finally {
          this.isSubmittingGuestNotificationForm = false;
        }
      },

      async updateStoreNotifications() {
        try {
          this.isSubmittingStoreNotificationForm = true;
          const storeNotificationsPayload = filterObjectKeys(this.form, this.storeNotificationFormKeys, false);
          await Store.updateStore(storeNotificationsPayload);

          this.$_onRequestSuccess({
            toastOptions: { message: '<b>Store</b> notifications have been successfully updated!' }
          });
        }
        catch (error) {
          this.$_onRequestError({ error, toastOptions: { message: 'Unable to update store notification types' } });
        }
        finally {
          this.isSubmittingStoreNotificationForm = false;
        }
      }
    }
  };
</script>
