<template>
  <div>
    <div
      v-for="modifier in searchResults"
      :key="`modifier-${modifier.id}`"
      :data-modifier-id="modifier.id"
    >
      <div class="row sub-row">
        <div class="is-flex-column align-start pad-y-sm">
          <img
            v-if="$_featurePermissions.NESTED_MODIFIERS && modifier.menuItemModifierGroup.type === modifierGroupTypes.PORTION"
            :class="['mar-r-sm', { 'transform-180': ['Right Half', '2nd Half'].includes(modifier.displayName) }]"
            :src="portionIcons[modifier.modifierTemplate]"
          >
          <span>
            <span class="has-text-weight-bold is-size-6">{{ modifier.displayName }}</span>

            <b-icon
              v-if="!modifier.mappedToPos && !$_hasPosType(posTypes.Cardfree)"
              v-tippy="{ content: 'Missing POS Mapping', placement: 'right' }"
              size="is-small"
              icon="exclamation-triangle"
              type="is-danger"
              class="mar-l"
            />
          </span>
          <span class="is-size-7"><b>Modifier Group:</b> {{ modifier.menuItemModifierGroup.displayName }}</span>
        </div>
        <span />
        <span class="align-center justify-end">
          <template v-if="!menuType">
            <b-button
              v-if="modifier.availabilityBeginDate || modifier.availabilityEndDate"
              v-tippy="{ content: 'Restricted Availability', placement: 'left', delay: [333, 0] }"
              class="is-transparent"
              @click="openModifierModal({ modifierId: modifier.id, modifierGroup: modifier.menuItemModifierGroup, mode: 'update' })"
            >
              <b-icon icon="calendar" pack="far" />
            </b-button>
            <b-button class="is-transparent" @click="openModifierModal({ modifierId: modifier.id, modifierGroup: modifier.menuItemModifierGroup, mode: 'update' })">
              <b-icon v-if="$can('update', 'MenuItemModifier') && $_menuPermissions.EDIT_RESOURCE" icon="pencil" size="is-small" />
              <span v-else>View</span>
            </b-button>
            <b-dropdown v-if="$can('destroy', 'MenuItemModifier') && $_menuPermissions.REMOVE_RESOURCE" aria-role="list" position="is-bottom-left">
              <b-button slot="trigger" class="is-transparent" type="is-white">
                <b-icon icon="ellipsis-v" pack="far" size="is-small" />
              </b-button>
              <b-dropdown-item class="is-danger" @click="openDeleteModifierConfirmation(modifier)">
                <b-icon icon="trash-alt" class="mar-r-sm" size="is-small" />Delete Modifier
              </b-dropdown-item>
            </b-dropdown>
          </template>
        </span>
      </div>
    </div>
  </div>
</template>

<script>
  import merchantMixin from '@/mixins/merchant';
  import featurePermissionsMixin from '@/mixins/featurePermissions';
  import posTypes from '@/constants/posTypes';
  import { modifierGroupTypes, modifierGroupPortionTemplates } from '@/constants/modifierGroups';
  import addEditModifierModal from '../main-menu/add-edit-modifier-modal/index.vue';
  import Modifier from '@/store/classes/Modifier';

  export default {
    name: 'ModifierSearchRow',

    mixins: [merchantMixin, featurePermissionsMixin],

    props: {
      searchResults: {
        type: Array,
        default: () => []
      },

      menuType: {
        type: Object,
        default: null
      }
    },

    data: () => ({
      posTypes,
      portionIcons: {
        [modifierGroupPortionTemplates.FULL_GROUP_TEMPLATE.constant]: '/images/whole-portion.svg',
        [modifierGroupPortionTemplates.HALF_GROUP_TEMPLATE.constant]: '/images/half-portion.svg'
      },
      modifierGroupTypes
    }),

    methods: {
      openModifierModal({ modifierGroup, modifierId, mode }) {
        this.$buefy.modal.open({
          parent: this,
          component: addEditModifierModal,
          hasModalCard: true,
          trapFocus: true,
          canCancel: false,
          customClass: 'auto-width',
          props: { modifierGroup, mode, modifierId }
        });
      },


      openDeleteModifierConfirmation({ displayName, id }) {
        this.$buefy.dialog.confirm({
          title: 'Delete Modifier',
          message: `<b>${displayName}</b> will be deleted from all locations. Are you sure?`,
          onConfirm: () => this.deleteModifier({ displayName, id }),
          confirmText: 'Delete',
          icon: 'trash-alt',
          hasIcon: true,
          type: 'is-danger'
        });
      },

      async deleteModifier({ displayName, id }) {
        try {
          await Modifier.deleteModifier(id);

          this.$_onRequestSuccess({
            toastOptions: {
              message: `Successfully deleted <b>${displayName}</b>`
            }
          });
        }

        catch (error) {
          this.$_onRequestError({
            error,
            toastOptions: {
              message: `Unable to delete <b>${displayName}</b>`
            }
          });
        }
      }
    }
  };
</script>

<style lang='sass' scoped>
  .transform-180
    transform: rotate(180deg)
</style>
