import moment from 'moment-timezone';

const dynamicYearSelect = {
  data() {
    return {
      dys_ranges: {}
    };
  },

  methods: {
    $_handleYearChange({
      year,
      type,
      yearsInFuture = 10,
      yearsInPast = 50
    }) {
      const firstYear = moment().year() + yearsInFuture;
      const lastYear = moment().year() - yearsInPast;
      const yearsRange = [lastYear - year, firstYear - year];
      this.$set(this.dys_ranges, type, yearsRange);
    }
  }
};

export default dynamicYearSelect;
