const OrderProviderTypes = {
  UNSPECIFIED: {
    type: 'Unspecified',
    displayName: 'Unspecified'
  },
  CARDFREE: {
    type: 'CardFree',
    displayName: 'CardFree'
  },
  GOOGLE_FOOD: {
    type: 'GoogleFood',
    displayName: 'Google'
  },
  SYNQ3: {
    type: 'Synq3',
    displayName: 'Synq3'
  },
  UBER_EATS: {
    type: 'UberEats',
    displayName: 'Uber Eats'
  },
  DOOR_DASH: {
    type: 'DoorDash',
    displayName: 'Door Dash'
  },
  CHOWLY: {
    type: 'Chowly',
    displayName: 'Chowly'
  }
};

export default OrderProviderTypes;
