<template>
  <validated-form
    ref="form"
    form-id="storeMenuCategoryScheduleForm"
    :disabled="!($can('update', 'StoreMenuCategorySchedule') && $_menuPermissions.MANAGE_DAYPART_SCHEDULE)"
    @valid-submit="handleSubmit"
  >
    <modal-card
      title="Manage Daypart Schedule"
      :subtitle="category.displayName"
    >
      <daypart-schedule-inputs
        v-model="dayparts_form"
        :invalid-days="dayparts_invalidDays"
        :read-only="!($can('update', 'StoreMenuCategorySchedule') && $_menuPermissions.MANAGE_DAYPART_SCHEDULE)"
        can-hide-hours
      />

      <template
        v-if="$can('update', 'StoreMenuCategorySchedule') && $_menuPermissions.MANAGE_DAYPART_SCHEDULE"
        #footer
      >
        <div class="buttons all-bold">
          <b-button
            rounded
            @click="$_confirmCloseModal({ programmatic: true })"
          >
            Cancel
          </b-button>
          <b-button
            v-tabbable
            rounded
            native-type="submit"
            type="is-primary"
            :loading="isLoading"
          >
            Save
          </b-button>
        </div>
      </template>
    </modal-card>
  </validated-form>
</template>

<script>
  import StoreMenuCategorySchedule from '@/store/classes/StoreMenuCategorySchedule';
  import confirmModalCloseMixin from '@/mixins/confirm-modal-close';
  import daypartsMixin from '@/mixins/dayparts';
  import moment from 'moment-timezone';
  import merchantMixin from '@/mixins/merchant';


  export default {
    name: 'StoreCategoryDayPartScheduleModal',

    mixins: [confirmModalCloseMixin, daypartsMixin, merchantMixin],

    props: {
      category: {
        type: Object,
        required: true
      },
      storeId: {
        type: Number,
        required: true
      }
    },

    data() {
      return {
        moment,
        isLoading: false
      };
    },

    computed: {
      storeMenuCategorySchedules() {
        return StoreMenuCategorySchedule
          .query()
          .where('storeId', this.storeId)
          .where('menuCategoryId', this.category.id)
          .get();
      }
    },

    created() {
      this.onCreated();
    },

    methods: {
      onCreated() {
        this.$_setDaypartsForm(this.storeMenuCategorySchedules);
      },

      async handleSubmit() {
        if (this.$_validateDayparts()) {
          try {
            this.isLoading = true;

            const { added, removed, updated } = this.$_getDaypartsToUpdate({
              currentDayparts: this.storeMenuCategorySchedules
            });

            await Promise.all([
              ...added.map(a => StoreMenuCategorySchedule.createStoreMenuCategorySchedule({ ...a, menuCategoryId: this.category.id, storeId: this.storeId })),
              ...updated.map(u => StoreMenuCategorySchedule.updateStoreMenuCategorySchedule(u)),
              ...removed.map(r => StoreMenuCategorySchedule.deleteStoreMenuCategorySchedule(r.id))
            ]);

            this.$_onRequestSuccess({
              toastOptions: {
                message: `Successfully updated ${this.category.displayName} schedule`
              },
              options: {
                closeParent: true
              }
            });
          }

          catch (error) {
            this.$_onRequestError({
              error,
              toastOptions: { message: 'Unable to update Daypart Schedule' }
            });

            throw error;
          }

          finally {
            this.isLoading = false;
          }
        }
      }
    }
  };
</script>
