import { Model } from '@vuex-orm/core';
import http from '@/services/http';

export default class MenuType extends Model {
  static entity = 'ssoProviders'

  static fields() {
    return {
      id: this.attr(''),
      name: this.attr(''),
      displayName: this.attr('')
    };
  }

  // STATE //////////////////////
  static state() {
    return {
      fetching: false
    };
  }

  static $state() {
    return this.store().state.entities.ssoProviders;
  }

  static async fetchSsoProviders(options = {}) {
    try {
      this.commit((state) => {
        state.fetching = true;
      });

      const alreadyFetched = this.query().exists();

      if (!alreadyFetched || options.forceFetch) {
        const { data } = await http.get('sso_providers');
        this.create({
          data: data.ssoProviders
        });
      }
    }
    catch (error) {
      throw error;
    }
    finally {
      this.commit((state) => {
        state.fetching = false;
      });
    }
  }
}
