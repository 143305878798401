<template>
  <b-radio
    :value="value"
    :disabled="disabled"
    :name="name"
    :class="[
      'radiobox',
      {
        checked: nativeValue === value,
        'left-label': leftLabel,
        'justify-between': justifyBetween
      }
    ]"
    :native-value="nativeValue"
    @input="$emit('input', $event)"
  >
    <div class="is-flex align-center">
      <b-icon
        v-if="icon"
        :icon="icon"
        :pack="iconPack"
        class="mar-r-sm"
        size="is-medium"
      />
      <div>
        <p class="has-text-weight-bold">
          <slot />
        </p>
        <p
          v-if="$slots.sublabel"
          :class="[
            'is-size-7',
            nativeValue === value ? 'has-text-primary-dark' : 'has-text-grey'
          ]"
        >
          <slot name="sublabel" />
        </p>
      </div>
    </div>
  </b-radio>
</template>

<script>
  export default {
    name: 'RadioButton',

    props: {
      name: {
        type: String,
        required: true
      },
      disabled: {
        type: Boolean,
        default: false
      },
      leftLabel: {
        type: Boolean,
        default: false
      },
      justifyBetween: {
        type: Boolean,
        default: false
      },
      nativeValue: {
        type: [String, Number, Boolean],
        default: null
      },
      value: {
        type: [String, Number, Boolean],
        default: false
      },
      icon: {
        type: String,
        default: ''
      },
      iconPack: {
        type: String,
        default: 'fa'
      }
    }
  };
</script>

<style lang='sass' scoped>
  .radiobox
    transition: 150ms
    padding: 0.75rem 1rem
    border: 1px solid $grey-lighter
    border-radius: $radius
    background-color: $white

    &:hover
      border-color: $grey-light

    &.checked
      border-color: $primary-light
      background: radial-gradient(closest-side, transparent, $primary-lightest)
      &:hover
        border-color: $primary
</style>
