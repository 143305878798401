<template>
  <div class="card">
    <div class="card-content">
      <merchant-form v-slot="{ mode, errors }" :merchant="_merchant" mode="update">
        <merchant-name-inputs :mode="mode" />
        <hr>
        <merchant-provider-inputs :mode="mode" :errors="errors" />
      </merchant-form>
    </div>
  </div>
</template>

<script>
  import merchantMixin from '@/mixins/merchant';
  import merchantForm from '../form/merchant-form.vue';

  import merchantNameInputs from '../form/merchant-name-inputs.vue';
  import merchantProviderInputs from '../form/merchant-provider-inputs.vue';

  export default {
    name: 'MerchantDetailsTab',

    components: { merchantForm, merchantNameInputs, merchantProviderInputs },

    mixins: [merchantMixin],

    props: {
      merchant: {
        type: Object,
        default: null
      }
    },

    computed: {
      _merchant() {
        return this.merchant || this.$_selectedMerchant;
      }
    }
  };
</script>
