import { Model } from '@vuex-orm/core';
import http from '@/services/http';
import PosDiscountStoreConfiguration from './PosDiscountStoreConfiguration';



export default class PosDiscountConfiguration extends Model {
  static entity = 'posDiscountConfigurations'



  // FIELDS //////////////////////
  static fields() {
    return {
      id: this.attr(''),
      merchantId: this.attr(''),
      name: this.attr(''),
      displayName: this.attr(''),
      publicId: this.attr(''),

      // relationships
      posDiscountStoreConfigurations: this.hasMany(PosDiscountStoreConfiguration, 'posDiscountConfigurationId')
    };
  }



  // STATE //////////////////////
  static state() {
    return {
      fetching: false,
      submitting: false
    };
  }

  static $state() {
    return this.store().state.entities.posDiscountConfigurations;
  }



  // ACTIONS //////////////////////
  static async fetchPosDiscounts() {
    try {
      this.commit((state) => {
        state.fetching = true;
      });

      const merchantId = this.store().state.entities.merchants.selectedMerchantId;

      const { data } = await http.get(`merchants/${merchantId}/pos_discount_configurations`);

      this.create({
        data: data.posDiscountConfigurations
      });
    }

    catch (error) {
      throw error;
    }

    finally {
      this.commit((state) => {
        state.fetching = false;
      });
    }
  }

  static async createPosDiscount(posDiscountConfiguration) {
    try {
      this.commit((state) => {
        state.submitting = true;
      });

      const merchantId = this.store().state.entities.merchants.selectedMerchantId;

      const { data } = await http.post(`merchants/${merchantId}/pos_discount_configurations`, {
        posDiscountConfiguration
      });

      this.insert({
        data: data.posDiscountConfiguration
      });

      return data.posDiscountConfiguration;
    }

    catch (error) {
      throw error;
    }

    finally {
      this.commit((state) => {
        state.submitting = false;
      });
    }
  }

  static async updatePosDiscount(posDiscountConfiguration) {
    try {
      this.commit((state) => {
        state.submitting = true;
      });

      const { data } = await http.put(`pos_discount_configurations/${posDiscountConfiguration.id}`, {
        posDiscountConfiguration
      });

      this.update({
        data: data.posDiscountConfiguration
      });
    }

    catch (error) {
      throw error;
    }

    finally {
      this.commit((state) => {
        state.submitting = false;
      });
    }
  }
}
