<template>
  <section>
    <div
      class="mar-b-sm is-flex align-center"
      :class="{ 'is-clickable': collapsible }"
      data-test-id="header"
      @click="collapsible && toggleVisibility()"
    >
      <b-icon
        :icon="icon"
        type="is-primary"
        data-test-id="icon"
      />
      <p
        class="has-text-weight-bold is-size-6 is-inline-block mar-l"
        data-test-id="title"
      >
        {{ title }}
      </p>
      <b-button
        v-if="collapsible"
        class="is-transparent mar-l-auto"
      >
        <b-icon
          style="font-size: 20px"
          icon="angle-right"
          :class="['open-indicator', { 'is-open': isOpen }]"
          data-test-id="open-indicator"
        />
      </b-button>
    </div>
    <transition name="fade-down">
      <div
        v-show="!collapsible || collapsible && isOpen"
        class="card-content pad-none dist-y"
      >
        <slot />
      </div>
    </transition>
  </section>
</template>

<script>
  export default {

    name: 'ExpoOrderDetailsSection',

    props: {
      title: {
        type: String,
        required: true
      },

      icon: {
        type: String,
        required: true
      },

      collapsible: {
        type: Boolean,
        default: false
      }
    },

    data: () => ({
      isOpen: false
    }),

    methods: {
      toggleVisibility() {
        this.isOpen = !this.isOpen;
      }
    }
  };
</script>

<style lang='sass' scoped>
  .open-indicator
    transition: transform 100ms
  .is-open
    transform: rotate(90deg)
</style>
