<template>
  <div>
    <draggable
      tag="ul"
      group="category"
      v-bind="draggableAttributes"
      :value="categories"
      :force-fallback="true"
      @change="handleCategorySortOrderChange"
    >
      <category-row
        v-for="category in categories"
        :key="`category-${category.id}`"
        :category="category"
      />
    </draggable>
    <div v-if="showAddCategoryButton" class="pad-x pad-y-sm">
      <b-button
        type="is-primary"
        inverted
        class="is-transparent"
        icon-left="plus"
        @click="openCategoryModal"
      >
        Category
      </b-button>
    </div>
  </div>
</template>

<script>
  import draggable from 'vuedraggable';
  import { mapActions, mapGetters, mapState } from 'vuex';

  import menuResourceTypes from '@/constants/menuResourceTypes';

  import Category from '@/store/classes/Category';

  import AddEditCategoryModal from '../main-menu/add-edit-category-modal/index.vue';
  import CategoryRow from './category-row.vue';

  export default {
    name: 'CategoriesContainer',

    components: { draggable, CategoryRow },

    computed: {
      ...mapState('mainMenu', ['activeMenuType', 'menuPermissions', 'draggableAttributes']),
      ...mapGetters('mainMenu', ['categories']),

      showAddCategoryButton() {
        return this.$can('create', 'MenuCategory')
          && !this.activeMenuType
          && this.menuPermissions.ADD_RESOURCE;
      }
    },

    methods: {
      ...mapActions('mainMenu', ['setMenuResourceOpenState']),

      async handleCategorySortOrderChange({ moved }) {
        try {
          await Category.updateSortOrders({
            categories: this.categories,
            oldIndex: moved.oldIndex,
            newIndex: moved.newIndex
          });

          this.$_onRequestSuccess({
            toastOptions: {
              message: `<b>${moved.element.displayName}</b> moved from sort order <b>${moved.oldIndex + 1}</b> to <b>${moved.newIndex + 1}</b>`
            }
          });
        }

        catch (error) {
          this.$_onRequestError({
            toastOptions: {
              message: 'Unable to update sort orders'
            },
            error
          });
        }
      },


      openCategoryModal() {
        this.$buefy.modal.open({
          parent: this,
          component: AddEditCategoryModal,
          hasModalCard: true,
          trapFocus: true,
          canCancel: false,
          customClass: 'auto-width',
          events: {
            'main-menu:add-category': this.toggleCategory
          }
        });
      },

      toggleCategory({ id }) {
        this.setMenuResourceOpenState({
          resourceType: menuResourceTypes.CATEGORY,
          resourceId: id,
          value: true
        });
      }
    }
  };
</script>

