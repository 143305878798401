<template>
  <validated-form
    ref="form"
    form-id="MenuDayPartScheduleForm"
    :disabled="!($can('update', 'MenuTypeSchedule') && $_menuPermissions.MANAGE_DAYPART_SCHEDULE)"
    @valid-submit="handleSubmit"
  >
    <modal-card
      title="Manage Daypart Schedule"
      :subtitle="menuType.displayName"
    >
      <daypart-schedule-inputs v-model="dayparts_form" :invalid-days="dayparts_invalidDays" :read-only="!($can('update', 'MenuTypeSchedule') && $_menuPermissions.MANAGE_DAYPART_SCHEDULE)" />

      <template v-if="$can('update', 'MenuTypeSchedule') && $_menuPermissions.MANAGE_DAYPART_SCHEDULE" #footer>
        <div class="buttons all-bold">
          <b-button
            rounded
            @click="$_confirmCloseModal({ programmatic: true })"
          >
            Cancel
          </b-button>
          <b-button
            v-tabbable
            rounded
            native-type="submit"
            type="is-primary"
            :loading="isLoading"
          >
            Save
          </b-button>
        </div>
      </template>
    </modal-card>
  </validated-form>
</template>

<script>
  import MenuTypeSchedule from '@/store/classes/MenuTypeSchedule';
  import confirmModalCloseMixin from '@/mixins/confirm-modal-close';
  import daypartsMixin from '@/mixins/dayparts';
  import merchantMixin from '@/mixins/merchant';
  import moment from 'moment-timezone';


  export default {
    name: 'MenuDayPartScheduleModal',

    mixins: [confirmModalCloseMixin, daypartsMixin, merchantMixin],

    props: {
      menuType: {
        type: Object,
        required: true
      }
    },

    data() {
      return {
        moment,
        isLoading: false
      };
    },

    computed: {
      menuTypeSchedules() {
        return MenuTypeSchedule
          .query()
          .where('menuTypeId', this.menuType.id)
          .get();
      }
    },

    created() {
      this.onCreated();
    },

    methods: {
      onCreated() {
        this.$_setDaypartsForm(this.menuTypeSchedules);
      },

      async handleSubmit() {
        if (this.$_validateDayparts()) {
          try {
            this.isLoading = true;

            const { added, removed, updated } = this.$_getDaypartsToUpdate({
              currentDayparts: this.menuTypeSchedules
            });

            await Promise.all([
              ...added.map(a => MenuTypeSchedule.createMenuTypeSchedule({ ...a, menuTypeId: this.menuType.id })),
              ...updated.map(u => MenuTypeSchedule.updateMenuTypeSchedule(u)),
              ...removed.map(r => MenuTypeSchedule.deleteMenuTypeSchedule(r.id))
            ]);

            this.$_onRequestSuccess({
              toastOptions: {
                message: `Successfully updated ${this.menuType.displayName} schedule`
              },
              options: {
                closeParent: true
              }
            });
          }

          catch (error) {
            this.$_onRequestError({
              error,
              toastOptions: { message: 'Unable to update Daypart Schedule' }
            });

            throw error;
          }

          finally {
            this.isLoading = false;
          }
        }
      }
    }
  };
</script>
