<template>
  <validated-form
    ref="merchantUserAccountsForm"
    form-id="merchantUserAccounts"
    @valid-submit="handleSubmit"
  >
    <sticky-save-container
      :saving="is.submitting"
      :loading="is.loading"
      title="User Accounts"
    >
      <b-field
        label="Allow Account Creation"
        class="align-labels-left mar-b-lg"
        horizontal
      >
        <b-switch v-model="allowAccountCreation" />
      </b-field>

      <b-field>
        <template #label>
          <p>Required Fields</p>
          <p class="is-size-7 has-text-grey has-text-weight-normal">
            Configure which fields are required for guests during account creation
          </p>
        </template>

        <div class="is-grid col-min-500 gap-md mar-t-sm">
          <radio-button
            v-for="{ id, name } in userValidations"
            :key="id"
            v-model="selectedUserValidationsId"
            :disabled="!allowAccountCreation"
            name="userValidationOptions"
            :native-value="id"
          >
            {{ userValidationLabels[id] || name }}
          </radio-button>
        </div>

      </b-field>
    </sticky-save-container>

  </validated-form>
</template>

<script>
  import MerchantOption from '@/store/classes/MerchantOption';
  import UserValidation from '@/store/classes/UserValidation';

  export default {
    name: 'MerchantUserAccounts',

    props: {
      merchantId: {
        type: Number,
        required: true
      }
    },

    data() {
      return {
        is: { submitting: false, loading: false },
        selectedUserValidationsId: null,
        allowAccountCreation: true
      };
    },

    computed: {
      merchantOption() {
        return MerchantOption.query().where('merchantId', this.merchantId).first();
      },

      userValidations() {
        return UserValidation.all();
      },

      userValidationLabels() {
        return UserValidation.userValidationLabels();
      }
    },

    watch: {
      // needed to handle keeping the User Accounts and Merchant Options forms *nsync
      merchantOption: {
        deep: true,
        handler: 'setLocalValues'
      }
    },

    created() {
      this.onCreated();
    },

    methods: {
      async onCreated() {
        try {
          this.$set(this.is, 'loading', true);

          await UserValidation.fetchUserValidations();
          this.setLocalValues(this.merchantOption);
        }
        catch (error) {
          this.$_onRequestError({
            error,
            toastOptions: {
              message: 'There was an issue loading User Validation Options'
            }
          });
        }
        finally {
          this.$set(this.is, 'loading', false);
        }
      },

      setLocalValues(merchantOption) {
        this.selectedUserValidationsId = merchantOption.userValidationsId;
        this.allowAccountCreation = merchantOption.signUpEnabled;
      },

      async handleSubmit() {
        try {
          this.$set(this.is, 'submitting', true);
          await MerchantOption.updateMerchantOption({
            id: this.merchantOption.id,
            userValidationsId: this.selectedUserValidationsId,
            signUpEnabled: this.allowAccountCreation
          });

          this.$_onRequestSuccess({
            toastOptions: { message: 'Successfully updated User Account settings' }
          });
        }
        catch (error) {
          this.$_onRequestError({
            error,
            toastOptions: {
              message: 'There was an issue updating your User Account settings'
            }
          });
        }
        finally {
          this.$set(this.is, 'submitting', false);
        }
      }
    }
  };
</script>
