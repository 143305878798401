<template>
  <validated-form
    ref="form"
    auto-focus
    form-id="addEditMerchantLinkModal"
    @valid-submit="handleSubmit"
  >
    <modal-card :title="modalTitle" :subtitle="merchantLinkHeaderName">
      <div class="dist-y-lg">
        <validated-text-input
          v-model="form.displayName"
          placeholder="i.e. FAQs"
          :has-icon="false"
          label="Link Title"
          name="displayName"
          rules="required"
          type="text"
        />

        <validated-text-input
          v-model="form.link"
          placeholder="i.e. http://www.cardfree.com"
          :has-icon="false"
          label="URL"
          name="link"
          rules="required|validUrl"
          type="text"
        />

        <hr>

        <validated-input
          class="align-labels-left"
          horizontal
          label="Opens New Tab"
          name="opensNewTab"
          tooltip="Enable to have link open a new tab when clicked"
        >
          <b-switch
            v-model="form.opensNewTab"
            type="is-success"
          />
        </validated-input>
      </div>

      <template #footer>
        <div class="buttons all-bold">
          <b-button
            rounded
            @click="$_confirmCloseModal({ programmatic: true })"
          >
            Cancel
          </b-button>

          <b-button
            v-tabbable
            rounded
            native-type="submit"
            type="is-primary"
            :loading="isSubmitting"
            :disabled="isSubmitting"
          >
            Save
          </b-button>
        </div>
      </template>
    </modal-card>
  </validated-form>
</template>

<script>
  import confirmModalCloseMixin from '@/mixins/confirm-modal-close';

  // classes
  import MerchantLink from '@/store/classes/MerchantLink';



  export default {
    name: 'AddEditMerchantLinkModal',

    mixins: [confirmModalCloseMixin],

    props: {
      merchantLink: {
        type: Object,
        required: true
      },

      merchantLinkHeaderName: {
        type: String,
        required: true
      }
    },

    data() {
      return {
        form: null
      };
    },

    computed: {
      isSubmitting() {
        return MerchantLink.$state().submitting;
      },

      modalTitle() {
        return `${!this.merchantLink.id ? 'Add' : 'Update'} Link`;
      }
    },

    created() {
      this.onCreated();
    },

    methods: {
      onCreated() {
        this.form = this.$clone(this.merchantLink);
      },

      async handleSubmit() {
        try {
          if (this.form.id) {
            await MerchantLink.updateMerchantLink(this.form);
          }
          else {
            await MerchantLink.createMerchantLink(this.form);
          }

          this.$_onRequestSuccess({
            toastOptions: {
              message: `Your footer link was successfully ${this.form.id ? 'updated' : 'added'}!`
            },
            options: {
              closeParent: true
            }
          });
        }

        catch (error) {
          this.$_onRequestError({
            error,
            toastOptions: {
              message: `There was an error ${this.form.id ? 'updating' : 'adding'} your link`
            }
          });
        }
      }
    }
  };
</script>
