<template>
  <div :class="['scrollable-tabs', {[`tab-overflow-${$_scrollableDirectionX}`]: $_scrollableDirectionX}]">
    <slot />
  </div>
</template>

<script>
  import scrollIndicatorMixin from '@/mixins/scroll-container-indicator';

  export default {
    name: 'ScrollableTabs',

    mixins: [scrollIndicatorMixin],

    mounted() {
      this.$_wrap(
        this.$el.querySelector('.tabs'),
        'div',
        {
          classNames: ['tabs-wrapper'],
          styles: {
            overflow: 'auto'
          }
        }
      );

      const tabsWrapper = this.$el.querySelector('.tabs-wrapper');

      const rightArrow = document.createElement('i');
      rightArrow.classList.add(...['fa', 'fa-chevron-right', 'right-tab-arrow']);
      rightArrow.addEventListener('click', () => this.scrollTabs('right'));
      tabsWrapper.insertBefore(rightArrow, tabsWrapper.firstChild);

      const leftArrow = document.createElement('i');
      leftArrow.classList.add(...['fa', 'fa-chevron-left', 'left-tab-arrow']);
      leftArrow.addEventListener('click', () => this.scrollTabs('left'));
      tabsWrapper.insertBefore(leftArrow, tabsWrapper.firstChild);

      this.$nextTick(() => {
        const tabs = document.querySelectorAll('.tabs-wrapper li');
        [...tabs].forEach(tab => tab.addEventListener('click', this.scrollIntoView));

        this.$_initScrollIndicatorStandard({
          containerSelector: '.tabs',
          contentSelector: '.tabs ul'
        });
      });
    },

    methods: {
      scrollIntoView(e) {
        const padding = 34;

        const tab = e.target.closest('li');
        const tabLeft = Math.ceil(tab.getBoundingClientRect().left);
        const tabRight = Math.ceil(tab.getBoundingClientRect().right);

        const scrollTabsContainer = this.$el.querySelector('.tabs');
        const containerLeft = Math.ceil(scrollTabsContainer.getBoundingClientRect().left);
        const containerRight = Math.ceil(scrollTabsContainer.getBoundingClientRect().right);

        const amountLeft = (tabLeft - containerLeft) - padding;
        const amountRight = (containerRight - tabRight) - padding;

        let leftPosition = scrollTabsContainer.scrollLeft;
        if (amountRight < 0) leftPosition -= amountRight;
        if (amountLeft < 0) leftPosition += amountLeft;

        scrollTabsContainer.scroll({ behavior: 'smooth', left: leftPosition });
      },

      scrollTabs(direction) {
        const scrollTabsContainer = this.$el.querySelector('.tabs');
        const amount = scrollTabsContainer.clientWidth * 0.66;
        let leftPosition = scrollTabsContainer.scrollLeft;

        if (direction === 'right') leftPosition += amount;
        if (direction === 'left') leftPosition -= amount;

        scrollTabsContainer.scroll({ left: leftPosition, behavior: 'smooth' });
      }
    }
  };
</script>

<style lang='sass'>
  .tabs
    a
      padding-left: 1.5rem
      padding-right: 1.5rem

  .tabs-wrapper
    position: sticky
    background-color: $white
    top: 0
    z-index: 1000
    box-shadow: 0 0.5em 1em -0.125em rgb(10 10 10 / 10%), 0 0px 0 1px rgb(10 10 10 / 2%)

    // remove scroll bar
    scrollbar-width: none
    -ms-overflow-style: none
    ::-webkit-scrollbar
      width: 0
      height: 0

  .right-tab-arrow,
  .left-tab-arrow
    position: absolute
    top: 0
    transform: translateY(0%)
    z-index: 1002
    opacity: 0
    padding: 0.75rem
    height: 42px

    &:hover
      cursor: pointer
      color: $primary

  .right-tab-arrow
    right: 0
    padding-left: 0.25rem
  .left-tab-arrow
    left: 0
    padding-right: 0.25rem

  [class^='tab-overflow'],
  [class*=' tab-overflow']
    .tabs-wrapper
      &::before,
      &::after
        content: ''
        position: absolute
        top: 0
        bottom: 2px
        opacity: 0
        width: 80px
        pointer-events: none
        transition: opacity 200ms
        z-index: 1001
      &::before
        left: 0
        box-shadow: 35px 0px 20px -12px inset rgba(255, 255, 255, 1), 60px 0px 20px -12px inset rgba(255, 255, 255, 0.75)
      &::after
        right: 0
        box-shadow: -35px 0px 20px -12px inset rgba(255, 255, 255, 1), -60px 0px 20px -12px inset rgba(255, 255, 255, 0.75)

  .tab-overflow-left .tabs-wrapper
    .left-tab-arrow
      opacity: 1
    &::before
      opacity: 1
  .tab-overflow-right .tabs-wrapper
    .right-tab-arrow
      opacity: 1
    &::after
      opacity: 1
  .tab-overflow-both .tabs-wrapper
    .right-tab-arrow,
    .left-tab-arrow
      opacity: 1
    &::before,
    &::after
      opacity: 1
</style>
