// TEST FILE

/* eslint-disable import/no-cycle */
import { Model } from '@vuex-orm/core';
import http from '@/services/http';
import filterObjectKeys from '@/helpers/filter-object-keys';
import CampaignsOffer from '@/store/classes/CampaignsOffer';
import { campaignStates, notificationTypes, emailImageTypes } from '@/constants/campaigns';

export default class Campaign extends Model {
  static entity = 'campaigns'

  static primaryKey = 'guid'

  // FIELDS //////////////////////
  static fields() {
    return {
      guid: this.attr(null),
      merchantId: this.attr(null),
      internalName: this.attr(null),
      description: this.attr(null),
      state: this.attr(campaignStates.DRAFT),
      deliveryType: this.attr(null),
      startDate: this.attr(null),
      endDate: this.attr(null),
      segmentation: this.attr(null),
      campaignsSegmentationId: this.attr(null),
      emailTemplates: this.attr([{
        subject: '',
        templateId: '',
        contentJson: '{}'
      }]),
      pushNotification: this.attr(null),
      smsNotification: this.attr(null),
      recurringCampaignConfiguration: this.attr(null),
      createdAt: this.attr(null),
      updatedAt: this.attr(null),
      willIncludeOffer: this.attr(true),
      eventType: this.attr(null),
      allowRedelivery: this.attr(false),
      minimumGiftCardAmount: this.attr(null),

      // Relationships
      campaignsOffers: this.hasMany(CampaignsOffer, 'campaignGuid', 'guid')
    };
  }



  // STATE //////////////////////
  static state() {
    return {
      fetching: false,
      submitting: false,
      deleting: false,
      fetchingTemplates: false,
      fetchingLinks: false,
      emailTemplateOptions: []
    };
  }

  static $state() {
    return this.store().state.entities.campaigns;
  }



  // ACTIONS //////////////////////
  static async fetchCampaigns() {
    try {
      this.commit((state) => {
        state.fetching = true;
      });

      const merchantId = this.store().state.entities.merchants.selectedMerchantId;

      const { data } = await http.get(`merchants/${merchantId}/campaigns`);

      this.create({ data: data.campaigns });
    }

    catch (error) {
      throw error;
    }

    finally {
      this.commit((state) => {
        state.fetching = false;
      });
    }
  }

  static async fetchCampaign(campaignId) {
    try {
      this.commit((state) => {
        state.fetching = true;
      });

      const response = await http.get(`campaigns/${campaignId}`);

      this.insert({
        data: response.data.campaign
      });
    }

    catch (error) {
      throw error;
    }

    finally {
      this.commit((state) => {
        state.fetching = false;
      });
    }
  }

  static async createCampaign(campaign) {
    const campaignObj = this.toCampaignParams(campaign);
    try {
      this.commit((state) => {
        state.submitting = true;
      });

      const merchantId = this.store().state.entities.merchants.selectedMerchantId;

      const response = await http.post(`merchants/${merchantId}/campaigns`, { campaign: campaignObj });

      this.insert({
        data: response.data.campaign
      });

      return response.data.campaign;
    }

    catch (error) {
      throw error;
    }

    finally {
      this.commit((state) => {
        state.submitting = false;
      });
    }
  }

  static async updateCampaign(campaign) {
    const campaignObj = this.toCampaignParams(campaign);
    try {
      this.commit((state) => {
        state.submitting = true;
      });

      const response = await http.put(`campaigns/${campaign.guid}`, {
        campaign: campaignObj
      });

      this.insert({
        data: response.data.campaign
      });

      return response.data.campaign;
    }

    catch (error) {
      throw error;
    }

    finally {
      this.commit((state) => {
        state.submitting = false;
      });
    }
  }

  static async scheduleCampaign(campaignGuid) {
    try {
      const { data } = await http.put(`campaigns/${campaignGuid}/schedule`);
      this.update({ data: data.campaign });
    }

    catch (error) {
      throw error;
    }
  }

  static async cancelCampaign(campaignGuid) {
    try {
      const { data } = await http.delete(`campaigns/${campaignGuid}/cancel`);
      this.update({ data: data.campaign });
    }

    catch (error) {
      throw error;
    }
  }

  static async deleteCampaign(campaignGuid) {
    try {
      this.commit((state) => {
        state.deleting = true;
      });

      await http.delete(`campaigns/${campaignGuid}`);

      this.delete(campaignGuid);
    }

    catch (error) {
      throw error;
    }

    finally {
      this.commit((state) => {
        state.deleting = false;
      });
    }
  }

  // Email Templates

  static async fetchSendgridTemplates() {
    try {
      this.commit((state) => {
        state.fetchingTemplates = true;
      });

      const { data } = await http.get('email_templates');
      const parsedTemplates = data.emailTemplates.map(et => ({ ...et, jsonSchema: JSON.parse(et.jsonSchema) }));
      this.commit((state) => {
        state.emailTemplateOptions = parsedTemplates;
      });
      return parsedTemplates;
    }

    catch (error) {
      throw error;
    }

    finally {
      this.commit((state) => {
        state.fetchingTemplates = false;
      });
    }
  }

  static async sendEmailNotificationPreview({ emailTemplate, emailAddresses, offerGuid }) {
    try {
      const merchantId = this.store().state.entities.merchants.selectedMerchantId;
      await http.post(`merchants/${merchantId}/campaigns/preview_email`, {
        campaign: {
          emailTemplatesAttributes: [filterObjectKeys(
            emailTemplate,
            ['subject', 'contentJson', 'templateId']
          )]
        },
        emailAddresses,
        offerGuid
      });
    }

    catch (error) {
      throw error;
    }
  }

  static async sendPushNotificationPreview({ pushNotification, emailAddresses, offerGuid, willIncludeOffer }) {
    try {
      const merchantId = this.store().state.entities.merchants.selectedMerchantId;
      await http.post(`merchants/${merchantId}/campaigns/preview_push`, {
        campaign: {
          pushNotificationAttributes: [pushNotification],
          willIncludeOffer
        },
        emailAddresses,
        offerGuid
      });
    }

    catch (error) {
      throw error;
    }
  }

  static async sendSmsNotificationPreview({ smsNotification, phoneNumbers, offerGuid }) {
    try {
      const merchantId = this.store().state.entities.merchants.selectedMerchantId;
      await http.post(`merchants/${merchantId}/campaigns/preview_sms`, {
        campaign: {
          smsNotificationAttributes: [smsNotification]
        },
        phoneNumbers,
        offerGuid
      });
    }

    catch (error) {
      throw error;
    }
  }

  static async createCampaignImage({ file, notificationId, type, imageType }) {
    try {
      let fileType;
      let urlType;
      switch (type) {
        case notificationTypes.EMAIL:
          fileType = 'EmailTemplate';
          urlType = 'email_template';
          break;
        case notificationTypes.PUSH:
          fileType = 'PushNotification';
          urlType = 'push_notification';
          break;
        case notificationTypes.SMS:
          fileType = 'SmsNotification';
          urlType = 'sms_notification';
          break;
        default:
        // do nothing
      }

      const formData = new FormData();
      formData.append(`campaign${fileType}Image[file]`, file);
      formData.append(`campaign${fileType}Image[campaign${fileType}Id]`, notificationId);

      if (type === notificationTypes.EMAIL && imageType === emailImageTypes.HEADER_OVERRIDE) {
        formData.append(`campaign${fileType}Image[kind]`, emailImageTypes.HEADER_OVERRIDE);
      }

      const response = await http.post(
        `campaign_${urlType}_images`,
        formData,
        { headers: { 'Content-Type': 'multipart/form-data' } }
      );
      return response.data;
    }

    catch (error) {
      throw error;
    }
  }

  static async deleteCampaignImage({ resourceId, type }) {
    try {
      this.commit((state) => {
        state.deleting = true;
      });

      let urlType;
      switch (type) {
        case notificationTypes.EMAIL:
          urlType = 'email_template';
          break;
        case notificationTypes.PUSH:
          urlType = 'push_notification';
          break;
        case notificationTypes.SMS:
          urlType = 'sms_notification';
          break;
        default:
        // do nothing
      }
      await http.delete(`campaign_${urlType}_images/${resourceId}`);
    }

    catch (error) {
      throw error;
    }

    finally {
      this.commit((state) => {
        state.deleting = false;
      });
    }
  }

  static toCampaignParams(campaign) {
    return {
      merchantId: campaign.merchantId,
      internalName: campaign.internalName,
      description: campaign.description,
      deliveryType: campaign.deliveryType,
      startDate: campaign.startDate,
      endDate: campaign.endDate,
      eventType: campaign.eventType,
      allowRedelivery: campaign.allowRedelivery,
      segmentation: campaign.segmentation,
      emailTemplatesAttributes: campaign.emailTemplates,
      willIncludeOffer: campaign.willIncludeOffer,
      pushNotificationAttributes: campaign.pushNotification,
      smsNotificationAttributes: campaign.smsNotification,
      recurringCampaignConfigurationAttributes: campaign.recurringCampaignConfiguration,
      minimumGiftCardAmount: campaign.minimumGiftCardAmount
    };
  }
}
