/* eslint-disable import/prefer-default-export */
import store from '@/store';

export function beforeEnterMerchantList(to, from, next) {
  const isCardfreeAdmin = store.getters['session/isCardfreeAdmin'];
  const isMultiMerchantUser = store.getters['session/isMultiMerchantUser'];

  if (isCardfreeAdmin || isMultiMerchantUser) {
    next();
  }
  else {
    next({ name: 'home' });
  }
}
