/* eslint-disable import/no-cycle */
import http from '@/services/http';
import FetchModel from '@/store/classes/FetchModel';


export default class ItemImage extends FetchModel {
  static entity = 'menuItemImages'

  // FIELDS //////////////////////
  static fields() {
    return {
      id: this.attr(''),
      menuItemId: this.attr(''),
      label: this.attr(''),
      fullUrl: this.attr(''),
      imageFile: this.attr(null)
    };
  }


  // STATE //////////////////////
  static state() {
    return {
      fetching: false,
      submitting: false
    };
  }

  static $state() { // TEST ?
    return this.store().state.entities.menuItemImages;
  }



  // ACTIONS //////////////////////
  static async fetchItemImages(itemId, options = {}) {
    await this.fetchData({
      endpoint: `menu_items/${itemId}/menu_item_images`,
      options,
      transformData: data => data.menuItemImages,
      customQuery: this.query().where('menuItemId', itemId)
    });
  }

  static async addItemImage({ imageFile, itemId, imageType }) {
    try {
      this.commit((state) => { // TEST ?
        state.submitting = true;
      });

      const formData = new FormData();
      formData.append('menuItemImage[file]', imageFile);
      formData.append('menuItemImage[label]', imageType);

      const response = await http.post(
        `menu_items/${itemId}/menu_item_images`,
        formData,
        { headers: { 'Content-Type': 'multipart/form-data' } }
      );

      this.insert({ data: response.data.menuItemImage });
    }

    catch (error) {
      throw error;
    }

    finally {
      this.commit((state) => { // TEST ?
        state.submitting = false;
      });
    }
  }

  static async deleteImage(itemId) {
    try {
      this.commit((state) => { // TEST ?
        state.submitting = true;
      });

      await http.delete(`menu_item_images/${itemId}`);

      this.delete(itemId);
    }

    catch (error) {
      throw error;
    }

    finally {
      this.commit((state) => { // TEST ?
        state.submitting = false;
      });
    }
  }
}
