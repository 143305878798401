/* eslint-disable import/no-cycle */
import { Model } from '@vuex-orm/core';
import http from '@/services/http';

import ModifierGroup from './ModifierGroup';
import Item from './Item';

import { setUpdatedSortOrders } from '@/helpers/set-new-sort-orders';
import filterObjectKeys from '@/helpers/filter-object-keys';


export default class MenuItemModifierGroupItem extends Model {
  static entity = 'menuItemModifierGroupItems'

  // FIELDS //////////////////////
  static fields() {
    return {
      id: this.attr(''),
      menuItemId: this.attr(''),
      menuItemModifierId: this.attr(''), // Present when the menuItemModifierGroupItem is a nested modifier group
      modifierGroupId: this.attr(''),
      sortOrder: this.attr(''),

      // relationships
      menuItem: this.belongsTo(Item, 'menuItemId'),
      modifierGroup: this.belongsTo(ModifierGroup, 'modifierGroupId')
    };
  }


  // STATE //////////////////////
  static state() {
    return {
      submitting: false
    };
  }

  static $state() {
    return this.store().state.entities.menuItemModifierGroupItems;
  }


  // ACTIONS //////////////////////
  static async createMenuItemModifierGroupItems(menuItemModifierGroupItems) {
    try {
      this.commit((state) => {
        state.submitting = true;
      });

      const merchantId = this.store().state.entities.merchants.selectedMerchantId;
      const response = await http.post(`merchants/${merchantId}/menu_item_modifier_group_items/bulk_create`, {
        menuItemModifierGroupItems
      });
      this.insert({
        data: response.data.menuItemModifierGroupItems
      });
      return response.data.menuItemModifierGroupItems;
    }
    catch (error) {
      throw error;
    }
    finally {
      this.commit((state) => {
        state.submitting = false;
      });
    }
  }

  static async deleteMenuItemModifierGroupItems(ids) {
    try {
      this.commit((state) => {
        state.submitting = true;
      });

      await http.delete('menu_item_modifier_group_items/bulk_destroy', { params: { ids } });

      ids.forEach(id => (this.delete(id)));
    }
    catch (error) {
      throw error;
    }
    finally {
      this.commit((state) => {
        state.submitting = false;
      });
    }
  }


  static async updateSortOrders({ menuItemModifierGroupItems, oldIndex, newIndex }) {
    let newMenuItemModifierGroupItems = setUpdatedSortOrders({ fromIndex: oldIndex, toIndex: newIndex, array: menuItemModifierGroupItems });

    newMenuItemModifierGroupItems = newMenuItemModifierGroupItems?.map((newMenuItemModifierGroupItem) => {
      delete newMenuItemModifierGroupItem.modifierGroup;
      return newMenuItemModifierGroupItem;
    });

    this.insert({
      data: newMenuItemModifierGroupItems
    });

    this.commit((state) => {
      if (menuItemModifierGroupItems[0].menuItemModifierId) {
        state.sortingParentModifierId = menuItemModifierGroupItems[0].menuItemModifierId;
      }
      else {
        state.sortingParentId = menuItemModifierGroupItems[0].menuItemId;
      }
    });

    try {
      await http.put('menu_item_modifier_group_items/sort_order', { menuItemModifierGroupItems: newMenuItemModifierGroupItems.map(nmg => filterObjectKeys(nmg, ['id', 'sortOrder'])) });
    }

    catch (error) {
      this.insert({ data: menuItemModifierGroupItems });
      throw error;
    }

    finally {
      this.commit((state) => {
        state.sortingParentId = null;
        state.sortingParentModifierId = null;
      });
    }
  }
}
