/* eslint-disable import/no-cycle */
import { Model } from '@vuex-orm/core';

import StoreModifier from './StoreModifier';
import ModifierGroup from './ModifierGroup';
import MenuItemModifierGroupItem from './MenuItemModifierGroupItem';

import http from '@/services/http';
import { setUpdatedSortOrders } from '@/helpers/set-new-sort-orders';
import filterObjectKeys from '@/helpers/filter-object-keys';
import { parseJson } from '@/helpers/misc';


export default class Modifier extends Model {
  static entity = 'modifiers'



  // FIELDS //////////////////////
  static fields() {
    return {
      id: this.attr(''),
      availabilityBeginDate: this.attr(''),
      availabilityEndDate: this.attr(''),
      caloriesHigh: this.attr(null),
      caloriesLow: this.attr(null),
      createdAt: this.attr(''),
      description: this.attr(''),
      displayDetail: this.attr(''),
      displayName: this.attr(''),
      hasChildrenModifierGroups: this.boolean(false),
      hideDescription: this.attr(''),
      isDefault: this.boolean(false),
      isSystemDefault: this.attr(''),
      mappedToPos: this.boolean(false),
      maxAllowed: this.number(1),
      metadata: this.attr('', parseJson),
      minRequired: this.number(0),
      modifierGroupId: this.attr(''),
      modifierTemplate: this.attr(''),
      nutritionalUnits: this.attr(''),
      posItemIds: this.attr([]),
      posMenuItemModifierMappings: this.attr([]),
      servesPostText: this.attr(''),
      servesPreText: this.attr(''),
      servesQuantity: this.attr(''),
      sortOrder: this.attr(''),
      updatedAt: this.attr(''),

      // Nested ModifierGroups
      canHaveNestedMenuItemModifierGroups: this.attr(false),
      menuItemModifierGroups: this.hasMany(ModifierGroup, 'menuItemModifierId'),

      // Relationships
      storeMenuItemModifiers: this.hasMany(StoreModifier, 'menuItemModifierId'),
      menuItemModifierGroupItems: this.hasMany(MenuItemModifierGroupItem, 'menuItemModifierId')
    };
  }

  get lastModified() {
    return this.updatedAt || this.createdAt;
  }


  // STATE //////////////////////
  static state() {
    return {
      fetching: false,
      fetchingModifierId: null,
      fetchingModifierGroupId: null,
      submitting: false,
      deleting: false,
      sortingParentId: null,
      updatingModifierIds: []
    };
  }

  static $state() {
    return this.store().state.entities.modifiers;
  }


  // ACTIONS //////////////////////
  static async fetchModifier(modifierId) {
    try {
      this.commit((state) => {
        state.fetchingModifierId = modifierId;
      });

      const response = await http.get(`menu_item_modifiers/${modifierId}`);

      this.insertOrUpdate({
        data: response.data.menuItemModifier
      });
    }

    catch (error) {
      throw error;
    }

    finally {
      this.commit((state) => {
        state.fetchingModifierId = null;
      });
    }
  }

  static async fetchByModifierGroupId({ modifierGroupId, includeStoreModifiers = false, menuId, parentMenuItemId }) {
    try {
      this.commit((state) => {
        state.fetchingModifierGroupId = modifierGroupId;
      });

      let include = includeStoreModifiers
        ? '?include=posMenuItemModifierMappings,store_menu_item_modifiers,store_menu_item_modifiers.store_menu_item_modifier_price_overrides'
        : '?include=posMenuItemModifierMappings';

      if (menuId) {
        include += `&menu_id=${menuId}`;
      }

      if (parentMenuItemId) {
        include += `&parent_menu_item_id=${parentMenuItemId}`;
      }

      const response = await http.get(`menu_item_modifier_groups/${modifierGroupId}/menu_item_modifiers${include}`);

      this.insert({
        data: response.data.menuItemModifiers
      });
    }

    catch (error) {
      throw error;
    }

    finally {
      this.commit((state) => {
        state.fetchingModifierGroupId = null;
      });
    }
  }

  static async addModifier({ modifier, modifierGroupId }) {
    try {
      this.commit((state) => {
        state.submitting = true;
      });
      const acceptedKeys = [
        'availabilityBeginDate',
        'availabilityEndDate',
        'displayName',
        'minRequired',
        'maxAllowed',
        'description',
        'modifierTemplate',
        'caloriesLow',
        'caloriesHigh',
        'nutritionalUnits'
      ];

      const merchantId = this.store().state.entities.merchants.selectedMerchantId;

      const response = await http.post(`menu_item_modifier_groups/${modifierGroupId}/menu_item_modifiers`, {
        menuItemModifier: filterObjectKeys(modifier, acceptedKeys),
        merchantId
      });


      this.insert({ data: response.data.menuItemModifier });
      return response.data.menuItemModifier;
    }

    catch (error) {
      throw error;
    }

    finally {
      this.commit((state) => {
        state.submitting = false;
      });
    }
  }

  static async updateModifier(modifier) {
    try {
      this.commit((state) => {
        state.updatingModifierIds = [modifier.id];
      });

      const { id } = modifier;

      if (typeof modifier.isDefault === 'boolean') {
        modifier.isDefault = modifier.isDefault ? 1 : 0;
      }

      const acceptedKeys = [
        'availabilityBeginDate',
        'availabilityEndDate',
        'displayName',
        'description',
        'minRequired',
        'maxAllowed',
        'caloriesHigh',
        'caloriesLow',
        'nutritionalUnits',
        'metadata',
        'isDefault',
        'modifierTemplate'
      ];

      const response = await http.put(`menu_item_modifiers/${id}`, { menuItemModifier: filterObjectKeys(modifier, acceptedKeys) });

      this.update({ data: response.data.menuItemModifier });
    }

    catch (error) {
      throw error;
    }

    finally {
      this.commit((state) => {
        state.updatingModifierIds = [];
      });
    }
  }

  static async deleteModifier(modifierId) {
    try {
      this.commit((state) => {
        state.deleting = true;
      });

      await http.delete(`menu_item_modifiers/${modifierId}`);

      this.delete(modifierId);
    }

    catch (error) {
      throw error;
    }

    finally {
      this.commit((state) => {
        state.deleting = false;
      });
    }
  }

  static async updateSortOrders({ modifierGroup, modifiers, oldIndex, newIndex }) { // eslint-disable-line object-curly-newline
    const newModifiers = setUpdatedSortOrders({ fromIndex: oldIndex, toIndex: newIndex, array: modifiers });

    this.insert({
      data: newModifiers.map((modifier) => {
        delete modifier.storeMenuItemModifiers;
        return modifier;
      })
    });

    try {
      this.commit((state) => {
        state.sortingParentId = modifierGroup.id;
      });

      await http.put(`menu_item_modifier_groups/${modifierGroup.id}/menu_item_modifiers/bulk_update`, {
        menuItemModifierGroup: modifierGroup,
        menuItemModifiers: newModifiers
      });
    }

    catch (error) {
      this.insert({ data: modifiers });
      throw error;
    }

    finally {
      this.commit((state) => {
        state.sortingParentId = null;
      });
    }
  }

  static async bulkUpdateModifiers({ modifierGroup, modifiers, updatedModifiers }) {
    this.update({ data: updatedModifiers });

    try {
      this.commit((state) => {
        state.updatingModifierIds = modifiers.map(modifier => modifier.id);
      });

      await http.put(`menu_item_modifier_groups/${modifierGroup.id}/menu_item_modifiers/bulk_update`, {
        menuItemModifierGroup: modifierGroup,
        menuItemModifiers: updatedModifiers
      });
    }

    catch (error) {
      this.update({ data: modifiers });
      throw error;
    }

    finally {
      this.commit((state) => {
        state.updatingModifierIds = [];
      });
    }
  }
}
