<template>
  <validated-form
    ref="form"
    form-id="StoreMenuTypeScheduleForm"
    :disabled="!($can('update', 'StoreMenuTypeSchedule') && $_menuPermissions.MANAGE_DAYPART_SCHEDULE)"
    @valid-submit="handleSubmit"
  >
    <modal-card
      title="Manage Daypart Schedule"
      :subtitle="`${store.description} - ${menuType.displayName}`"
    >
      <daypart-schedule-inputs
        v-model="dayparts_form"
        :invalid-days="dayparts_invalidDays"
        :read-only="!($can('update', 'StoreMenuTypeSchedule') && $_menuPermissions.MANAGE_DAYPART_SCHEDULE)"
      />

      <template v-if="$can('update', 'StoreMenuTypeSchedule') && $_menuPermissions.MANAGE_DAYPART_SCHEDULE" #footer>
        <div class="buttons all-bold">
          <b-button
            rounded
            @click="$_confirmCloseModal({ programmatic: true })"
          >
            Cancel
          </b-button>
          <b-button
            v-tabbable
            rounded
            native-type="submit"
            type="is-primary"
            :loading="isLoading"
          >
            Save
          </b-button>
        </div>
      </template>
    </modal-card>
  </validated-form>
</template>

<script>
  import StoreMenuTypeSchedule from '@/store/classes/StoreMenuTypeSchedule';
  import confirmModalCloseMixin from '@/mixins/confirm-modal-close';
  import daypartsMixin from '@/mixins/dayparts';
  import merchantMixin from '@/mixins/merchant';



  export default {
    name: 'StoreMenuTypeScheduleModal',

    mixins: [confirmModalCloseMixin, daypartsMixin, merchantMixin],

    props: {
      menuType: {
        type: Object,
        required: true
      },
      store: {
        type: Object,
        required: true
      }
    },

    data() {
      return {
        isLoading: false
      };
    },

    computed: {
      storeMenuTypeSchedules() {
        return StoreMenuTypeSchedule
          .query()
          .where('storeId', this.store.storeId)
          .where('menuTypeId', this.menuType.id)
          .get();
      }
    },

    created() {
      this.onCreated();
    },

    methods: {
      onCreated() {
        this.$_setDaypartsForm(this.storeMenuTypeSchedules);
      },

      async handleSubmit() {
        if (this.$_validateDayparts()) {
          try {
            this.isLoading = true;

            const { added, removed, updated } = this.$_getDaypartsToUpdate({
              currentDayparts: this.storeMenuTypeSchedules
            });

            await Promise.all([
              ...added.map(a => StoreMenuTypeSchedule.createStoreMenuTypeSchedule({ ...a, menuTypeId: this.menuType.id, storeId: this.store.storeId })),
              ...updated.map(u => StoreMenuTypeSchedule.updateStoreMenuTypeSchedule(u)),
              ...removed.map(r => StoreMenuTypeSchedule.deleteStoreMenuTypeSchedule(r.id))
            ]);

            this.$_onRequestSuccess({
              toastOptions: {
                message: `Successfully updated <b>${this.menuType.displayName}</b> schedule for <b>${this.store.description}</b>`
              },
              options: {
                closeParent: true
              }
            });
          }

          catch (error) {
            this.$_onRequestError({
              error,
              toastOptions: { message: 'Unable to update Daypart Schedule' }
            });

            throw error;
          }

          finally {
            this.isLoading = false;
          }
        }
      }
    }
  };
</script>
