import formatUsd from 'format-usd';
import Order from '@/store/classes/Order';
import alertModal from '@/components/globals/alert-modal.vue';
import { normalizeWhitespace } from '@/helpers/strings';


const cancelOrder = {
  methods: {
    p_closeParentModal() {
      if (this.$parent.close) {
        this.$parent.close();
      }
    },

    $_openConfirmCancelDialog(order) {
      this.$buefy.modal.open({
        parent: this,
        component: alertModal,
        hasModalCard: true,
        trapFocus: true,
        customClass: 'auto-width',
        props: {
          buttons: [
            { text: 'No Thanks' },
            {
              text: 'Cancel Order',
              primary: true,
              onClick: async () => {
                await this.p_cancelOrder(order);
              }
            }
          ],
          horizontal: true,
          showCloseButton: false,
          icon: 'ban',
          title: `Cancel Order${order.ticketNumber ? ` #${order.ticketNumber}` : ''}`,
          message: `This order will be canceled and it's payment${order.payments.length > 1 ? 's' : ''} voided.`,
          secondaryMessage: 'Are you sure you\'d like to cancel this order?',
          type: 'is-danger'
        }
      });
    },

    p_openSuccessfulCancelDialog(order) {
      const totalPrice = formatUsd({ amount: order.totalWithTipAndCharges });
      const title = order.ticketNumber ? `Order #${order.ticketNumber} Cancelled` : 'Order Cancelled';
      const message = normalizeWhitespace(`
        <p class="has-text-weight-bold mar-b-sm is-size-5">
          This order has been successfully cancelled
        </p>
        <div class="mar-b-md">
          <p class="has-text-grey">Payment authorized has been voided for:</p>
          <p class="is-size-3 has-text-weight-bold">${totalPrice}</p>
        </div>
        <p class="has-text-grey">Status of this order will be updated in Order Management shortly.</p>
      `);

      this.$buefy.dialog.confirm({
        title,
        message,
        onConfirm: this.p_closeParentModal,
        confirmText: 'Ok',
        canCancel: false,
        type: 'is-primary',
        trapFocus: true
      });
    },

    async p_cancelOrder(order) {
      try {
        await Order.cancelOrder(order.orderId);
        this.p_openSuccessfulCancelDialog(order);
      }

      catch (error) {
        this.$_onRequestError({
          toastOptions: { message: 'Failed to cancel order' },
          error
        });
      }
    }
  }
};

export default cancelOrder;
