<template>
  <validated-form
    ref="form"
    auto-focus
    form-id="addEditPosDiscount"
    @valid-submit="handleSubmit"
  >
    <modal-card :title="posDiscountConfigurationId ? 'Update POS Discount' :'Create POS Discount'">
      <div>
        <validated-text-input
          v-model="form.name"
          label="POS Discount Name"
          sub-label="Internal name used for searching your POS Discounts"
          name="internalName"
          :rules="{
            required: true,
            max: 50,
            uniqueStringCaseInsensitive: unavailableNames
          }"
          type="text"
        />

        <validated-text-input
          v-model="form.displayName"
          name="displayName"
          label="Display Name"
          sub-label="Name that may be displayed to end users"
          rules="required|max:50"
          type="text"
        />

        <hr>

        <b-field>
          <template #label>
            <p>Scope</p>
            <p class="sub-label">Specify whether the configured discount codes will apply to all stores or be store specific</p>
          </template>
          <div class="is-flex">
            <radio-button
              v-model="isStoreSpecific"
              name="isStoreSpecific"
              :native-value="false"
              class="flex-grow"
              :disabled="!!posDiscountConfigurationId && !!discountCodes.length"
            >
              Applies to All Stores
            </radio-button>
            <radio-button
              v-model="isStoreSpecific"
              name="isStoreSpecific"
              :native-value="true"
              class="flex-grow"
              :disabled="!!posDiscountConfigurationId && !!discountCodes.length"
            >
              Applies to Specific Stores
            </radio-button>
          </div>
        </b-field>

        <hr>

        <div>
          <validation-provider
            v-slot="{ errors }"
            name="discountCodeAmount"
            :rules="{ max_value: isStoreSpecific ? stores.length : merchantPosTypes.length }"
          >
            <input v-show="false" type="number" :value="discountCodes.length">
            <div class="mar-b">
              <p class="subtitle is-5 mar-b-none">
                Discount Codes
                <b-icon v-if="!!errors.length" icon="exclamation-circle" custom-class="fa-sm" type="is-danger" />
              </p>
              <p v-if="!!errors.length" class="has-text-danger is-size-7">
                <span>
                  Only {{ isStoreSpecific ? stores.length : merchantPosTypes.length }}
                  Discount Code{{ (isStoreSpecific ? stores.length : merchantPosTypes.length) > 1 ? 's' : '' }}
                  can be applied{{ isStoreSpecific ? '' : ' to all stores' }}
                </span>
              </p>
            </div>
            <transition name="fade-up" mode="out-in">
              <div v-if="discountCodes.length">
                <transition-group name="fade-right-slow" class="dist-y">
                  <div
                    v-for="(discountCode, index) in discountCodes"
                    :key="`discount-code-${discountCode.id}`"
                    class="card gap-sm is-flex pad-sm discount-code"
                  >
                    <validated-input
                      v-if="isStoreSpecific"
                      label="Location Name"
                      label-position="on-border"
                      :name="`storeId-${index}`"
                      rules="required"
                      class="is-marginless flex-grow"
                    >
                      <b-select v-model="discountCode.storeId" expanded>
                        <option
                          v-for="store in stores"
                          :key="store.storeId"
                          :value="store.storeId"
                          :disabled="[
                            ...discountCodes.slice(0, index),
                            ...discountCodes.slice(index + 1)
                          ].some(dc => dc.storeId === store.storeId)"
                        >
                          {{ store.description }}
                        </option>
                      </b-select>
                    </validated-input>

                    <validated-input
                      v-if="!isStoreSpecific && merchantPosTypes.length > 1"
                      label="POS Type"
                      label-position="on-border"
                      :name="`posTypeId-${index}`"
                      rules="required"
                      class="is-marginless flex-grow"
                    >
                      <b-select v-model="discountCode.posTypeId" expanded>
                        <option
                          v-for="posType in merchantPosTypes"
                          :key="posType.id"
                          :value="posType.id"
                          :disabled="[
                            ...discountCodes.slice(0, index),
                            ...discountCodes.slice(index + 1)
                          ].some(dc => dc.posTypeId === posType.id)"
                        >
                          {{ posType.name }}
                        </option>
                      </b-select>
                    </validated-input>

                    <validated-text-input
                      v-model="discountCode.posApiId"
                      :name="`posApiId-${index}`"
                      label="POS Discount ID"
                      label-position="on-border"
                      type="text"
                      rules="required"
                      monospaced
                      :spellcheck="false"
                      class="is-marginless flex-grow"
                    />

                    <b-button
                      icon-left="trash-alt"
                      class="align-self-start"
                      type="is-danger is-light"
                      @click="removeDiscountCode(index)"
                    />
                  </div>
                </transition-group>
              </div>
              <b-message v-else type="is-primary" class="is-compact has-shadow">
                Add a Discount Code from your POS
              </b-message>
            </transition>
          </validation-provider>

          <b-button
            icon-left="plus"
            type="is-primary is-light"
            class="mar-t"
            :disabled="discountCodes.length >= (isStoreSpecific ? stores.length : merchantPosTypes.length)"
            @click="addDiscountCode"
          >
            Discount Code
          </b-button>
        </div>
      </div>

      <template #footer>
        <div class="buttons all-bold">
          <b-button
            rounded
            @click="$_confirmCloseModal({ programmatic: true })"
          >
            Cancel
          </b-button>

          <b-button
            v-tabbable
            rounded
            native-type="submit"
            type="is-primary"
            :loading="isSubmitting"
          >
            Save
          </b-button>
        </div>
      </template>
    </modal-card>
  </validated-form>
</template>

<script>
  import confirmModalCloseMixin from '@/mixins/confirm-modal-close';
  import PosDiscountConfiguration from '@/store/classes/PosDiscountConfiguration';
  import PosDiscountStoreConfiguration from '@/store/classes/PosDiscountStoreConfiguration';
  import Store from '@/store/classes/Store';
  import merchantMixin from '@/mixins/merchant';
  import logger from '@/services/logger';
  import getChangedResources from '@/helpers/get-changed-resources';



  export default {
    name: 'PosDiscountModal',

    mixins: [confirmModalCloseMixin, merchantMixin],

    props: {
      posDiscountConfigurationId: {
        type: Number,
        default: null
      },
      unavailableNames: {
        type: Array,
        required: true
      }
    },

    data() {
      return {
        isStoreSpecific: false,
        form: {},
        discountCodes: [],
        isSubmitting: false
      };
    },

    computed: {
      posDiscountConfiguration() {
        if (!this.posDiscountConfigurationId) {
          return new PosDiscountConfiguration({ merchantId: this.$_selectedMerchantId });
        }
        return PosDiscountConfiguration.find(this.posDiscountConfigurationId);
      },

      posDiscountStoreConfigurations() {
        return PosDiscountStoreConfiguration.query().where('posDiscountConfigurationId', this.posDiscountConfigurationId).get();
      },

      stores() {
        return Store.all();
      },

      merchantPosTypes() {
        return this.$_selectedMerchant.posConfigurations
          .filter(posConfig => posConfig.storeId === -1)
          .map(posConfig => posConfig.posType);
      }
    },

    created() {
      this.onCreated();
    },

    methods: {
      onCreated() {
        this.form = this.$clone(this.posDiscountConfiguration);
        this.discountCodes = this.$clone(this.posDiscountStoreConfigurations);
        this.isStoreSpecific = this.posDiscountStoreConfigurations.some(x => x.storeId > 0);
      },

      addDiscountCode() {
        const options = {};
        if (this.isStoreSpecific) {
          options.storeId = this.stores.find(store => !this.discountCodes.some(dc => dc.storeId === store.storeId))?.storeId;
        }
        else {
          options.posTypeId = this.merchantPosTypes.find(posType => !this.discountCodes.some(dc => dc.posTypeId === posType.id))?.id;
        }
        this.discountCodes.push(new PosDiscountStoreConfiguration(options));
      },

      removeDiscountCode(index) {
        this.discountCodes.splice(index, 1);
      },

      async submitPosDiscountConfiguration() {
        try {
          let { posDiscountConfigurationId } = this;

          if (posDiscountConfigurationId) await PosDiscountConfiguration.updatePosDiscount(this.form);
          else {
            const { id } = await PosDiscountConfiguration.createPosDiscount(this.form);
            posDiscountConfigurationId = id;
          }

          return posDiscountConfigurationId;
        }
        catch (error) {
          logger.error(error);
        }
      },

      async submitPosDiscountStoreConfigurations(posDiscountConfigurationId) {
        try {
          const formattedDiscountCodes = this.discountCodes.map(discountCode => ({
            ...discountCode,
            posDiscountConfigurationId,
            storeId: this.isStoreSpecific ? discountCode.storeId : -1,
            posTypeId: this.isStoreSpecific
              ? this.stores.find(s => s.storeId === discountCode.storeId)?.posType?.id
              : discountCode.posTypeId || this.merchantPosTypes[0]?.id
          }));

          const { added, updated, removed } = getChangedResources({
            oldArray: this.posDiscountStoreConfigurations,
            newArray: formattedDiscountCodes,
            comparisonKey: 'id',
            updatedComparisonFn: (n, o) => ['storeId', 'posTypeId', 'posApiId'].some(key => n[key] !== o[key])
          });

          await Promise.all(removed.map(r => PosDiscountStoreConfiguration.deletePosDiscountStoreConfig(r.id)));
          await Promise.all([
            ...added.map(a => PosDiscountStoreConfiguration.createPosDiscountStoreConfig(a)),
            ...updated.map(u => PosDiscountStoreConfiguration.updatePosDiscountStoreConfig(u))
          ]);
        }
        catch (error) {
          logger.error(error);
        }
      },

      async handleSubmit() {
        try {
          this.isSubmitting = true;

          const id = await this.submitPosDiscountConfiguration();
          await this.submitPosDiscountStoreConfigurations(id);

          this.$_onRequestSuccess({
            toastOptions: {
              message: `Successfully ${this.posDiscountConfigurationId ? 'updated' : 'created'} your POS Discount!`
            },
            options: { closeParent: true }
          });
        }

        catch (error) {
          this.$_onRequestError({
            toastOptions: {
              message: `There was an error ${this.posDiscountConfigurationId ? 'updating' : 'creating'} your POS Discount`
            },
            error
          });
        }

        finally {
          this.isSubmitting = false;
        }
      }
    }
  };
</script>
