// services
import globalSearchService from '@/services/global-search';

// helpers
import { capitalCase } from 'change-case';
import filterObjectKeys from '@/helpers/filter-object-keys';

// constants
import posTypes from '@/constants/posTypes';
import { stringTypes, numberTypes, dollarTypes } from '@/constants/appSettingResourceTypes';

// store classes
import AppCodeType from '@/store/classes/AppCodeType';
import Merchant from '@/store/classes/Merchant';
import MerchantAppSetting from '@/store/classes/MerchantAppSetting';
import MerchantAppSettingResource from '@/store/classes/MerchantAppSettingResource';
import MerchantOptionsType from '@/store/classes/MerchantOptionsType';
import StoreAttribute from '@/store/classes/StoreAttribute';
import Store from '@/store/classes/Store';
import storage from '@/services/storage';

/* -------------------------- GlobalSearchManager -------------------------- */

class GlobalSearchManager {
  constructor() {
    this.merchantId = null;
    this.portalFeatures = {};
    this.merchantOptionTypes = [];
    this.selectedAppCodeTypeId = '1';
    this.appSettingResourceKeys = [];
    this.selectedMerchantAppSetting = null;
    this.appSettingResources = {};
    this.selectedAppSettingResources = [];
    this.appSettingResourceTypes = {};
    this.storeIdMap = {};
    this.searchHistory = [];
    this.MAX_HISTORY_LENGTH = 5;
  }

  async initialize(merchantId, isCardFreeAdmin = false) {
    this.merchantId = merchantId;
    await Promise.all([
      StoreAttribute.fetchStoreAttributes(),
      Store.fetchAll()
    ]);

    if (isCardFreeAdmin) {
      await Promise.all([
        AppCodeType.fetchAppCodeTypes(),
        MerchantAppSettingResource.fetchAppSettingResourceKeys(),
        MerchantAppSetting.fetchMerchantAppSettings(this.merchantId),
        MerchantOptionsType.fetchMerchantOptionsTypes(this.merchantId)
      ]);
    }
  }

  /* -------------------------- SEARCH HISTORY -------------------------- */

  getSearchHistory() {
    return this.searchHistory;
  }

  removeSearchHistoryItem(index) {
    this.searchHistory.splice(index, 1);
    this.saveSearchHistory();
  }

  loadSearchHistory() {
    this.searchHistory = storage.local.get('globalSearchHistory') || [];
  }

  saveSearchHistory() {
    storage.local.set('globalSearchHistory', this.searchHistory);
  }

  addToSearchHistory(query) {
    this.searchHistory = this.searchHistory.filter(item => item !== query);
    this.searchHistory.unshift(query);
    this.searchHistory = this.searchHistory.slice(0, this.MAX_HISTORY_LENGTH);
    this.saveSearchHistory();
  }

  /* -------------------------- SETTERS -------------------------- */

  setSelectedAppCodeTypeId(appCodeTypeId) {
    this.selectedAppCodeTypeId = appCodeTypeId;
  }

  /* -------------------------- SYNCERS -------------------------- */

  syncPortalFeatures() {
    const features = JSON.parse(JSON.stringify(Merchant.find(this.merchantId).features));
    const keysToKeep = Object.keys(features).filter(key => typeof features[key] === 'boolean');
    this.portalFeatures = keysToKeep.reduce((acc, key) => {
      acc[key] = features[key];
      return acc;
    }, {});


    globalSearchService.populateMerchantFeatureKeys(this.portalFeatures);
  }

  syncAppSettingResources(appSettingResourceKeys, selectedAppSettingResources) {
    this.selectedAppSettingResources = selectedAppSettingResources;
    this.appSettingResourceKeys = appSettingResourceKeys;

    this.appSettingResources = this.appSettingResourceKeys.reduce((acc, key) => {
      const value = this.selectedAppSettingResources.find(r => r.key === key)?.value;
      acc[key] = value;
      return acc;
    }, {});

    this.appSettingResourceTypes = this.appSettingResourceKeys.reduce((acc, key) => {
      if (stringTypes.includes(key)) acc[key] = 'String';
      else if (numberTypes.includes(key)) acc[key] = 'Number';
      else if (dollarTypes.includes(key)) acc[key] = 'Dollar';
      else acc[key] = 'Boolean';
      return acc;
    }, {});

    globalSearchService.populateAppSettingResourceKeys(this.appSettingResources);
  }

  syncMerchantOptionTypes() {
    this.merchantOptionTypes = MerchantOptionsType.all();
    globalSearchService.populateMerchantOptionsKeys(this.merchantOptionTypes);
  }


  /* -------------------------- HIDE CONDITIONS -------------------------- */

  hideConditions = (userCan, selectedMerchant) => {
    const { patEnabled, oatEnabled, orderAheadEnabled, supportsUberEatsOauth, supportsSquareAuth } = selectedMerchant;

    return {
      'contentManagement--faqs': [patEnabled, oatEnabled, orderAheadEnabled].every(x => !x),
      'contentManagement--surveys': !userCan('edit', 'survey') || !selectedMerchant.features.surveys,
      'storeConfiguration--authorizations': selectedMerchant.posTypeId !== posTypes.Clover,
      'merchantConfiguration--gift-cards': !(selectedMerchant.features.giftCardManagement && userCan('crud', 'MerchantGiftCardDesign') && userCan('crud', 'MerchantGiftCardConfiguration')),
      'merchantConfiguration--merchant-fees': !userCan('read', 'MerchantFee') || !selectedMerchant.supportsMerchantFees,
      'merchantConfiguration--special-hours': !userCan('update', 'Merchant'),
      'merchantConfiguration--authorizations': !userCan('update', 'Merchant') || ![supportsSquareAuth, supportsUberEatsOauth].some(x => x),
      'merchantConfiguration--provider-settings': !userCan('read', 'MerchantDeliveryService') && selectedMerchant.deliveryEnabled
    };
  }

  /* --------------------- RESOURCE TYPE CHECKS --------------------- */

  isPortalFeature = result => result.resourceKey === 'merchantFeatures'

  isStoreCoreConfig = result => result.resourceKey === 'storeCoreConfigurations'

  isAppSettingResource = result => result.resourceKey === 'appSettingResources'

  isMerchantOptionType = result => result.resourceKey === 'merchantOptions'

  /* -------------------------- GETTERS -------------------------- */

  getMerchantOptionType(subOption) {
    return this.merchantOptionTypes.find(option => capitalCase(option.name) === subOption);
  }

  getAppSettingResourceType(subOption) {
    const key = this.getAppSettingResourceKey(subOption);
    return this.appSettingResourceTypes[key];
  }

  getAppSettingResourceKey(subOption) {
    return Object.keys(this.appSettingResources).find(key => capitalCase(key) === subOption);
  }

  getAppSettingResourceValue(subOption) {
    const key = this.getAppSettingResourceKey(subOption);
    return this.appSettingResources[key] === undefined ? undefined : this.appSettingResources[key];
  }

  getMerchantFeatureKey(subOption) {
    return Object.keys(this.portalFeatures).find(key => capitalCase(key) === subOption);
  }

  getOtherPortalFeatureKey = (subOption) => {
    if (subOption === 'Microsoft Single Sign-On') {
      return 'SSO Providers';
    }
    return subOption;
  }

  getMerchantFeatureValue(subOption) {
    const key = this.getMerchantFeatureKey(subOption);
    return this.portalFeatures[key];
  }

  getMerchantOptionsTabName = (subOption) => {
    const tableName = this.getMerchantOptionType(subOption)?.tableName;

    switch (tableName) {
      case 'merchantOptions':
        return 'Miscellaneous';
      case 'merchantOptionsOpd':
        return 'Payment Dashboard';
      case 'merchantOptionsCheckout':
      case 'merchantOptionsEmv':
      case 'merchantOptionsLocation':
      case 'merchantOptionsLoyalty':
        return tableName.split('merchantOptions')[1];
      default:
        return 'N/A';
    }
  }

  /* ---------------------- STATIC GETTERS ---------------------- */

  static get selectedMerchantAppSetting() {
    return MerchantAppSetting.query().where('appCodeTypeId', Number(this.selectedAppCodeTypeId)).first();
  }

  static get appSettingResourceKeys() {
    return MerchantAppSettingResource.$state().masrKeys;
  }

  static getMerchantFeatureKey(subOption) {
    return Object.keys(this.portalFeatures).find(key => capitalCase(key) === subOption);
  }

  static get isSubmitting() {
    return Store.$state().submitting || MerchantAppSettingResource.$state().submitting;
  }

  static get isFetching() {
    return StoreAttribute.$state().fetching
      || AppCodeType.$state().fetching
      || MerchantAppSetting.$state().fetching
      || MerchantAppSettingResource.$state().fetching
      || Store.$state().fetchingAll;
  }

  static get isLoading() {
    return this.isFetching || this.isSubmitting;
  }

  /* ---------------- UPDATE METHODS --------------------- */

  async updateAppSettingResources({ key, value, merchantAppSetting }) {
    const appSettingResource = MerchantAppSettingResource.query()
      .where('merchantAppSettingsId', merchantAppSetting?.id)
      .where('key', key)
      .first();

    if (!appSettingResource) {
      throw new Error(`App setting resource ${key} not found`);
    }

    const payload = [{
      key,
      value,
      id: appSettingResource.id,
      appCodeTypeId: merchantAppSetting.appCodeTypeId
    }];

    await MerchantAppSettingResource.createOrUpdateAppSettingResources({
      merchantId: this.merchantId,
      merchantAppSettingResources: payload
    });

    this.appSettingResources[key] = value;
  }

  async updateMerchantFeatures({ featureKey, subOption, newValue }) {
    if (!featureKey) {
      throw new Error(`Unable to update Portal Feature '${capitalCase(subOption)}'`);
    }

    const updatedFeatures = {
      ...this.portalFeatures,
      [featureKey]: newValue
    };

    await Merchant.updateFeatures({ id: Merchant.find(this.merchantId).features.id, ...updatedFeatures });
    this.portalFeatures[featureKey] = newValue;
  }

  updateStore = async (storeAttributeForm, storeId) => {
    const acceptedKeys = [
      'id', 'storeId', 'posTypeId', 'merchantLocationId', 'paymentLocationId',
      'posLocationId', 'posEmployeeId', 'posRevenueCenter', 'posThirdPartyTenderTypeId',
      'posPriceSyncEnabled', 'storeMappingAttributes', 'loyaltyLocationId',
      'loyaltyStoreNumber', 'loyaltyShortIdentifierCode', 'loyaltyApiKey'
    ];

    const storeMappingAttributesPayload = Object.values(storeAttributeForm)
      .filter(attr => attr.isActive !== undefined)
      .map(attr => filterObjectKeys(attr, ['code', 'isActive', 'name']));

    const updatedStorePayload = filterObjectKeys(
      {
        ...storeAttributeForm,
        storeId,
        storeMappingAttributes: storeMappingAttributesPayload
      },
      acceptedKeys
    );

    await Store.updateStore(updatedStorePayload);
  }
}

const gsm = new GlobalSearchManager();
export default gsm;
