<template>
  <div>
    <b-loading :active="isLoading" :is-full-page="false" />

    <template v-for="(mds, deliveryServiceId) in merchantDeliveryServices">
      <merchant-delivery-service
        :key="deliveryServiceId"
        :merchant-id="merchant.id"
        :delivery-service-id="Number(deliveryServiceId)"
        :services="mds"
        class="pad-lg"
      />
    </template>
  </div>
</template>

<script>
  import MerchantDeliveryService from '@/store/classes/MerchantDeliveryService';
  import DeliveryFeeType from '@/store/classes/DeliveryFeeType';
  import DeliveryServiceChargeType from '@/store/classes/DeliveryServiceChargeType';

  import merchantDeliveryService from './merchant-provider-settings-tab-content/merchant-delivery-service-configurations.vue';

  export default {
    name: 'MerchantProviderSettingsTab',

    components: { merchantDeliveryService },

    props: {
      merchant: {
        type: Object,
        required: true
      }
    },

    computed: {
      merchantDeliveryServices() {
        const allServices = MerchantDeliveryService
          .query()
          .where('merchantId', this.merchant.id)
          .orderBy('platformTypeId')
          .get();

        return allServices.reduce((group, service) => {
          const { deliveryServiceId } = service;
          group[deliveryServiceId] = group[deliveryServiceId] || [];
          group[deliveryServiceId].push(service);
          return group;
        }, {});
      },

      isLoading() {
        return DeliveryFeeType.$state().fetching
          || MerchantDeliveryService.$state().fetching
          || DeliveryServiceChargeType.$state().fetching;
      }
    },

    created() {
      this.onCreated();
    },

    methods: {
      async onCreated() {
        await Promise.all([
          this.fetchDeliveryFeeTypes(),
          this.fetchMerchantDeliveryServices(),
          this.fetchDeliveryServiceChargeTypes()
        ]);
      },

      async fetchDeliveryFeeTypes() {
        try {
          await DeliveryFeeType.fetchDeliveryFeeTypes();
        }
        catch (error) {
          this.$_onRequestError({
            toastOptions: { message: 'There was an error fetching the delivery fee types' },
            error
          });
        }
      },

      async fetchMerchantDeliveryServices() {
        try {
          await MerchantDeliveryService.fetchMerchantDeliveryServices(this.merchant.id);
        }
        catch (error) {
          this.$_onRequestError({
            toastOptions: { message: 'There was an error fetching the merchant delivery services' },
            error
          });
        }
      },

      async fetchDeliveryServiceChargeTypes() {
        try {
          await DeliveryServiceChargeType.fetchDeliveryServiceChargeTypes();
        }
        catch (error) {
          this.$_onRequestError({
            toastOptions: { message: 'There was an error fetching the delivery service charge types' },
            error
          });
        }
      }
    }
  };
</script>
