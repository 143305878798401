/* eslint-disable import/no-cycle */
import { Model } from '@vuex-orm/core';
import http from '@/services/http';
import filterObjectKeys from '@/helpers/filter-object-keys';

export default class RegionMenuItemModifier extends Model {
  static entity = 'regionMenuItemModifiers'

  // FIELDS //////////////////////

  static fields() {
    return {
      id: this.attr(null),
      createdDate: this.attr(null),
      isExcluded: this.attr(null),
      menuItemModifierId: this.attr(null),
      menuRegionId: this.attr(null),
      menuTypeId: this.attr(null),
      modifiedDate: this.attr(null)
    };
  }



  // ACTIONS //////////////////////

  static async bulkAddRegionMenuItemModifiers(regionMenuId, regionMenuItemModifiers) {
    try {
      const acceptedKeys = ['menuRegionId', 'menuItemModifierId', 'menuTypeId', 'isExcluded'];
      const { data } = await http.post(`region_menus/${regionMenuId}/region_menu_item_modifiers/bulk_create`, {
        regionMenuItemModifiers: regionMenuItemModifiers.map(regionMenuItemModifier => filterObjectKeys(regionMenuItemModifier, acceptedKeys))
      });

      this.insert({ data: data.regionMenuItemModifiers });
    }

    catch (error) {
      throw error;
    }
  }

  static async bulkDeleteRegionMenuItemModifiers(regionMenuItemModifierIds) {
    try {
      await http.delete(`region_menu_item_modifiers/bulk_destroy?region_menu_item_modifier_ids=[${regionMenuItemModifierIds}]`);
      regionMenuItemModifierIds.forEach((id) => {
        this.delete(id);
      });
    }

    catch (error) {
      throw error;
    }
  }
}
