<template>
  <section-wrapper title="Loyalty Providers" :loading="isLoading">
    <b-table
      :data="merchantLoyaltyProviderConfigurations"
      class="is-middle-aligned"
      :mobile-cards="false"
    >
      <template #empty>
        <p
          data-test-id="no-loyalty-providers-message"
          class="has-text-centered pad is-size-5 has-text-grey-light"
        >
          No Loyalty Providers Configured
        </p>
      </template>

      <b-table-column
        v-slot="{ row }"
        label="Name"
        sortable
        field="loyaltyProviderName"
      >
        <span data-test-id="loyalty-provider-name">
          {{ row.loyaltyProviderName }}
        </span>
      </b-table-column>

      <b-table-column
        v-slot="{ row }"
        label="Active"
        field="isActive"
        sortable
        centered
      >
        <b-icon
          :icon="row.isActive ? 'check' : 'times'"
          :type="row.isActive ? 'is-success' : 'is-danger'"
        />
      </b-table-column>

      <b-table-column
        v-slot="{ row }"
        centered
        cell-class="actions"
        label="Actions"
        width="1"
      >
        <b-button
          class="is-transparent"
          type="is-white"
          data-test-id="edit-loyalty-provider-button"
          @click="openLoyaltyProviderModal(row.id)"
        >
          <b-icon icon="pencil" size="is-small" />
        </b-button>
      </b-table-column>
    </b-table>

    <div class="pad-x pad-y-sm has-border-top has-border-grey-lighter">
      <b-button
        data-test-id="add-loyalty-provider-button"
        inverted
        class="is-transparent"
        type="is-primary"
        icon-left="plus"
        :disabled="loyaltyProviderCount === merchantLoyaltyProviderConfigurations.length"
        @click="openLoyaltyProviderModal()"
      >
        New Loyalty Provider
      </b-button>
    </div>
  </section-wrapper>
</template>

<script>
  import LoyaltyProvider from '@/store/classes/LoyaltyProvider';
  import MembershipTierBadgeSource from '@/store/classes/MembershipTierBadgeSource';
  import MerchantLoyaltyProviderConfiguration from '@/store/classes/MerchantLoyaltyProviderConfiguration';
  import PayAtTableAuthenticationType from '@/store/classes/PayAtTableAuthenticationType';
  import addEditLoyaltyProviderModal from './add-edit-loyalty-provider-modal.vue';

  export default {
    name: 'MerchantLoyaltyProviders',

    props: {
      merchantId: {
        type: Number,
        required: true
      }
    },

    computed: {
      merchantLoyaltyProviderConfigurations() {
        return MerchantLoyaltyProviderConfiguration.all();
      },

      loyaltyProviderCount() {
        return LoyaltyProvider.query().count();
      },

      isLoading() {
        return [
          LoyaltyProvider.$state().fetching,
          MembershipTierBadgeSource.$state().fetching,
          MerchantLoyaltyProviderConfiguration.$state().fetching,
          PayAtTableAuthenticationType.$state().fetching
        ].some(Boolean);
      }
    },

    created() {
      this.onCreated();
    },

    methods: {
      async onCreated() {
        await Promise.all([
          this.fetchLoyaltyProviders(),
          this.fetchMembershipTierBadgeSources(),
          this.fetchMerchantLoyaltyProviderConfigurations(),
          this.fetchPayAtTableAuthenticationTypes()
        ]);
      },

      openLoyaltyProviderModal(merchantLoyaltyProviderConfigurationId) {
        this.$buefy.modal.open({
          parent: this,
          component: addEditLoyaltyProviderModal,
          hasModalCard: true,
          trapFocus: true,
          canCancel: false,
          props: {
            existingLoyaltyProviderIds: this.merchantLoyaltyProviderConfigurations.map(mlpc => mlpc.loyaltyProviderId),
            merchantId: this.merchantId,
            merchantLoyaltyProviderConfigurationId
          }
        });
      },

      async fetchLoyaltyProviders() {
        try {
          await LoyaltyProvider.fetchLoyaltyProviders();
        }
        catch (error) {
          this.$_onRequestError({
            error,
            toastOptions: { message: 'Unable to fetch loyalty providers' }
          });
        }
      },

      async fetchMembershipTierBadgeSources() {
        try {
          await MembershipTierBadgeSource.fetchMembershipTierBadgeSources();
        }
        catch (error) {
          this.$_onRequestError({
            error,
            toastOptions: { message: 'Unable to fetch pay at table authentication types' }
          });
        }
      },

      async fetchPayAtTableAuthenticationTypes() {
        try {
          await PayAtTableAuthenticationType.fetchPayAtTableAuthenticationTypes();
        }
        catch (error) {
          this.$_onRequestError({
            error,
            toastOptions: { message: 'Unable to fetch membership tier badge sources' }
          });
        }
      },

      async fetchMerchantLoyaltyProviderConfigurations() {
        try {
          await MerchantLoyaltyProviderConfiguration.fetchMerchantLoyaltyProviderConfigurations(this.merchantId);
        }
        catch (error) {
          this.$_onRequestError({
            error,
            toastOptions: { message: 'Unable to fetch merchant loyalty provider configurations' }
          });
        }
      }
    }
  };
</script>
