import Merchant from '@/store/classes/Merchant';
import alertModal from '@/components/globals/alert-modal.vue';

const deactivateMerchant = {
  methods: {
    $_confirmUpdateMerchantActivation(merchant) {
      this.$buefy.modal.open({
        parent: this,
        component: alertModal,
        hasModalCard: true,
        trapFocus: true,
        canCancel: false,
        customClass: 'auto-width',
        props: {
          buttons: [
            { text: 'No' },
            {
              text: `Yes, ${merchant.isActive ? 'Deactivate' : 'Activate'}`,
              primary: true,
              onClick: async () => {
                if (merchant.isActive) await this.p_deactivateMerchant(merchant);
                else await this.p_activateMerchant(merchant);
              }
            }
          ],
          icon: merchant.isActive ? 'ban' : 'bolt',
          horizontal: true,
          showCloseButton: false,
          title: `${merchant.isActive ? 'Deactivate' : 'Activate'} Merchant`,
          message: `Are you sure you'd like to ${merchant.isActive ? 'deactivate' : 'activate'} <b>${merchant.displayName}</b>?`,
          type: merchant.isActive ? 'is-danger' : 'is-success'
        }
      });
    },

    async p_deactivateMerchant(merchant) {
      try {
        await Merchant.deactivateMerchant(merchant.id);
        this.$_onRequestSuccess({
          toastOptions: { message: `<b>${merchant.displayName}</b> has been deactivated` }
        });
      }

      catch (error) {
        this.$_onRequestError({
          error,
          toastOptions: { message: `There was an error deactivating <b>${merchant.displayName}</b>` }
        });
      }
    },

    async p_activateMerchant(merchant) {
      try {
        await Merchant.activateMerchant(merchant.id);
        this.$_onRequestSuccess({
          toastOptions: { message: `<b>${merchant.displayName}</b> has been activated` }
        });
      }
      catch (error) {
        this.$_onRequestError({
          error,
          toastOptions: { message: `There was an error activating <b>${merchant.displayName}</b>` }
        });
      }
    }
  }
};



export default deactivateMerchant;
