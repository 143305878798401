import { render, staticRenderFns } from "./registered-guest-tabs.vue?vue&type=template&id=68fa1da4&scoped=true&"
import script from "./registered-guest-tabs.vue?vue&type=script&lang=js&"
export * from "./registered-guest-tabs.vue?vue&type=script&lang=js&"
import style0 from "./registered-guest-tabs.vue?vue&type=style&index=0&id=68fa1da4&lang=sass&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "68fa1da4",
  null
  
)

export default component.exports