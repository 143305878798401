/* eslint-disable import/no-cycle */
import { Model } from '@vuex-orm/core';
import http from '@/services/http';

import { setUpdatedSortOrders } from '@/helpers/set-new-sort-orders';
import filterObjectKeys from '@/helpers/filter-object-keys';

import MerchantFaqContent from './MerchantFaqContent';

export default class MerchantFaqSection extends Model {
  static entity = 'merchantFaqSections'

  // FIELDS //////////////////////
  static fields() {
    return {
      id: this.attr(''),
      merchantFaqHeaderId: this.attr(''),
      sectionHeader: this.attr(''),
      sortOrder: this.attr(''),

      // relationship
      merchantFaqContents: this.hasMany(MerchantFaqContent, 'merchantFaqSectionId')
    };
  }

  // STATE //////////////////////
  static state() {
    return {
      fetching: false,
      submitting: false,
      sorting: false,
      deletingId: false
    };
  }

  static $state() {
    return this.store().state.entities.merchantFaqSections;
  }

  static async createMerchantFaqSection(merchantFaqSection) {
    try {
      this.commit((state) => {
        state.submitting = true;
      });

      const response = await http.post(`merchant_faq_headers/${merchantFaqSection.merchantFaqHeaderId}/merchant_faq_sections`, { merchantFaqSection });

      this.insert({
        data: response.data.merchantFaqSection
      });

      return response.data.merchantFaqSection.id;
    }

    catch (error) {
      throw error;
    }

    finally {
      this.commit((state) => {
        state.submitting = false;
      });
    }
  }

  static async updateMerchantFaqSection(merchantFaqSection) {
    try {
      this.commit((state) => {
        state.submitting = true;
      });

      const response = await http.put(`merchant_faq_sections/${merchantFaqSection.id}`, { merchantFaqSection });

      this.update({
        data: response.data.merchantFaqSection
      });
    }

    catch (error) {
      throw error;
    }

    finally {
      this.commit((state) => {
        state.submitting = false;
      });
    }
  }

  static async sortMerchantFaqSection({ fromSortOrder, toSortOrder, array }) {
    try {
      this.commit((state) => {
        state.sorting = true;
      });

      const newMerchantFaqSectionDetails = setUpdatedSortOrders({ fromIndex: fromSortOrder, toIndex: toSortOrder, array });

      this.insert({ data: newMerchantFaqSectionDetails });

      await http.put('merchant_faq_sections/sort_order', {
        merchantFaqSections: newMerchantFaqSectionDetails.map(obj => filterObjectKeys(obj, ['id', 'sortOrder']))
      });
    }

    catch (error) {
      this.insert({ data: array });
      throw error;
    }

    finally {
      this.commit((state) => {
        state.sorting = false;
      });
    }
  }

  static async deleteMerchantFaqSection(sectionId) {
    try {
      this.commit((state) => {
        state.deletingId = sectionId;
      });

      await http.delete(`merchant_faq_sections/${sectionId}`);

      this.delete(sectionId);
    }

    catch (error) {
      throw error;
    }

    finally {
      this.commit((state) => {
        state.deletingId = false;
      });
    }
  }
}
