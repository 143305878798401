<template>
  <div class="card" data-test-id="definition-step">
    <div class="card-content">
      <p class="title is-4 has-text-weight-normal">Definition</p>
      <p class="subtitle is-6 has-text-grey mar-b">Define the earn rule</p>
      <div class="is-flex-column gap-y">
        <div class="is-grid col-2 gap">
          <validated-input
            class="dropdown-field"
            name="engagementType"
            label="Engagement Type"
            rules="required"
            data-test-id="engagement-type-input"
          >
            <b-select
              v-model="engagementType"
              placeholder="Select an engagement type..."
              class="is-inline-block"
              expanded
              :disabled="!earnRuleFullyEditable"
            >
              <option
                v-for="( type, key ) in engagementTypes"
                :key="key"
                :value="type.id"
              >
                {{ type.display }}
              </option>
            </b-select>
          </validated-input>
          <validated-input
            class="dropdown-field"
            name="awardPolicyType"
            label="Award Policy"
            rules="required"
            data-test-id="award-policy-type-input"
          >
            <b-select
              v-model="awardPolicyType"
              :placeholder="!engagementType ? 'Select an Engagement Type first' :'Select an award policy...'"
              class="is-inline-block"
              expanded
              :disabled="!engagementType"
            >
              <option
                v-for="( type, key ) in availableAwardPolicyTypes"
                :key="key"
                :value="type.type"
              >
                {{ type.display }}
              </option>
            </b-select>
          </validated-input>
        </div>
        <div class="pad has-background-white-bis has-border has-border-grey-lightest has-radius" data-test-id="award-policy-type-options">
          <b-message
            v-if="!engagementType || !awardPolicyType"
            class="has-shadow mar-none align-self-start"
            type="is-info"
          >
            Please select an <b>{{ !engagementType ? 'Engagement Type' : 'Award Policy' }}</b>
            <template v-if="!engagementType">
              to populate <b>Award Policy</b> options
            </template>
            <template v-else>
              to see definition configuration options
            </template>
          </b-message>
          <transition-group v-else name="fade-down-slow" mode="in-out">
            <component
              :is="awardPolicyTypeInputsMapping[awardPolicyType].component"
              :key="awardPolicyType"
              :value="value"
              :read-only="readOnly"
              v-on="awardPolicyTypeInputsMapping[awardPolicyType].handlers"
            />
          </transition-group>
        </div>
        <b-field
          v-if="isMemberEligibilityPolicyEnabled"
          label="Eligible Members"
          class="pad has-background-white-bis has-border has-border-grey-lightest has-radius"
          data-test-id="member-eligibility-section"
        >
          <div class="is-flex-column gap-y">
            <div class="is-grid col-2 gap-x">
              <radio-button
                v-model="memberEligibilityPolicyType"
                name="memberEligibilityPolicyType"
                :native-value="memberEligibilityPolicyTypes.ALL_MEMBERS.type"
              >
                All Members
              </radio-button>
              <radio-button
                v-model="memberEligibilityPolicyType"
                name="memberEligibilityPolicyType"
                :native-value="memberEligibilityPolicyTypes.MEMBER_TIER.type"
              >
                Specific Tiers
              </radio-button>
            </div>
            <b-checkbox
              v-if="isCardfreeAdmin"
              v-model="includeImportedMembers"
              name="includeImportedMembers"
              class="mar-l"
            >
              Apply to Imported Members
              <b-icon
                v-tippy="{
                  content: 'Only used for initial cutover. If you aren\'t sure what to do, leave it checked',
                  placement: 'right',
                  delay: [150, 0],
                  maxWidth: 300
                }"
                size="is-small"
                icon="info-square"
                type="is-grey"
                class="mar-l-xs"
              />
            </b-checkbox>
            <validated-input
              v-if="memberEligibilityPolicyType === memberEligibilityPolicyTypes.MEMBER_TIER.type"
              name="eligibleProgramTierPublicIds"
              label="Membership Tiers"
              hide-label
              class="has-border-grey-lightest has-border-top pad-y mar-b-none"
              :custom-messages="{ required: 'You must select at least one Membership Tier' }"
              data-test-id="tier-checkbox-section"
              rules="required|minLength:1"
            >
              <check-button
                v-for="(tier, index) in membershipTiers"
                :key="tier.publicId"
                v-model="eligibleProgramTierPublicIds"
                name="eligibleProgramTierPublicIds"
                :native-value="tier.publicId"
                class="is-full-width"
              >
                <div class="has-text-weight-bold">{{ tier.displayName }}</div>
                <div class="has-text-weight-normal">
                  ({{ getTierPoints(tier, index) }} {{ primaryCurrency.pluralName }})
                </div>
              </check-button>
            </validated-input>
          </div>
        </b-field>
      </div>
    </div>
    <earn-rule-constraint-inputs
      :value="value"
      :read-only="readOnly"
      @input="handleInput"
    />
  </div>
</template>

<script>
  import { mapGetters } from 'vuex';

  import { awardPolicyTypes, constraintModels, earningItemBases, engagementTypes, memberEligibilityPolicyTypes } from '@/constants/earnRules';

  import merchantMixin from '@/mixins/merchant';

  import Currency from '@/store/classes/Currency';
  import LoyaltyProgramTier from '@/store/classes/LoyaltyProgramTier';

  import EarnRuleConstraintInputs from '../../earn-rule-constraint-inputs/earn-rule-constraint-inputs.vue';
  import EarnRuleOfferInputs from '../../award-policy-inputs/earn-rule-offer-inputs.vue';
  import PointsPerDollarInputs from '../../award-policy-inputs/points-per-dollar-inputs.vue';
  import FixedPointsInputs from '../../award-policy-inputs/fixed-points-inputs.vue';
  import SpecifiedItemInputs from '../../award-policy-inputs/specified-item-inputs.vue';
  import RandomAwardInputs from '../../award-policy-inputs/random-award-inputs.vue';


  export default {
    name: 'DefinitionStep',

    components: { EarnRuleConstraintInputs },

    mixins: [merchantMixin],

    props: {
      value: {
        type: Object,
        required: true
      },

      readOnly: {
        type: Boolean,
        default: false
      },

      earnRuleFullyEditable: {
        type: Boolean,
        default: false
      },

      loyaltyProgramPublicId: {
        type: String,
        required: true
      }
    },

    data() {
      return {
        awardPolicyTypes,
        constraintModels,
        earningItemBases,
        engagementTypes,
        memberEligibilityPolicyTypes
      };
    },

    computed: {
      ...mapGetters('session', ['isCardfreeAdmin']),

      engagementType: {
        get() {
          return this.value.engagementType;
        },
        set(engagementTypeId) {
          const payload = {
            ...this.value,
            engagementType: engagementTypeId,
            awardPolicy: {},
            earnRuleConstraints: []
          };

          if (engagementTypeId === engagementTypes.REFERRAL_CONVERTED.id) {
            delete payload.memberEligibilityPolicy;
          }
          else {
            payload.memberEligibilityPolicy = {
              ...memberEligibilityPolicyTypes.ALL_MEMBERS.defaultData,
              ...this.value.memberEligibilityPolicy
            };
          }

          this.$emit('input', payload);
        }
      },

      awardPolicyType: {
        get() {
          return this.value.awardPolicy?.awardPolicyType;
        },
        set(value) {
          const awardPolicyType = Object.values(awardPolicyTypes).find(type => type.type === value);
          let awardPolicy;

          const needsPrimaryCurrency = [
            awardPolicyTypes.CHECK_HEADER_POINT.type,
            awardPolicyTypes.SALES_TRANSACTION_FIXED_POINT.type,
            awardPolicyTypes.SPECIFIED_ITEM_POINTS_PER_DOLLAR_SPENT.type,
            awardPolicyTypes.SPECIFIED_ITEM_POINTS_PER_COUNT.type,
            awardPolicyTypes.ENROLLMENT_BONUS_POINT.type,
            awardPolicyTypes.BIRTHDAY_POINT.type,
            awardPolicyTypes.REFERRAL_CONVERTED_POINT.type,
            awardPolicyTypes.GIFT_CARD_POINTS_PER_DOLLAR_FUNDED.type
          ].includes(value);

          if (needsPrimaryCurrency) {
            awardPolicy = {
              awardPolicyType: awardPolicyType.type,
              ...awardPolicyType.defaultData(this.primaryCurrency.publicId)
            };
          }
          else {
            awardPolicy = {
              awardPolicyType: awardPolicyType.type,
              ...awardPolicyType.defaultData
            };
          }

          if (awardPolicyType.type === awardPolicyTypes.MEMBER_PROXIMITY_OFFER.type) {
            this.handleInput({
              awardPolicy,
              earnRuleConstraints: [
                {
                  constraintType: constraintModels.MEMBER_PROXIMITY_LOCATION.type,
                  ...constraintModels.MEMBER_PROXIMITY_LOCATION.defaultData
                }
              ]
            });
          }
          else {
            this.handleInput({ awardPolicy });
          }
        }
      },

      memberEligibilityPolicyType: {
        get() {
          return this.value.memberEligibilityPolicy?.memberEligibilityPolicyType;
        },
        set(memberEligibilityPolicyType) {
          const updatedPolicy = {
            ...this.value.memberEligibilityPolicy,
            memberEligibilityPolicyType
          };

          if (memberEligibilityPolicyType === memberEligibilityPolicyTypes.ALL_MEMBERS.type) {
            delete updatedPolicy.eligibleProgramTierPublicIds;
          }

          this.handleInput({
            memberEligibilityPolicy: updatedPolicy
          });
        }
      },

      includeImportedMembers: {
        get() {
          return this.value.memberEligibilityPolicy?.includeImportedMembers;
        },
        set(value) {
          this.handleInput({
            memberEligibilityPolicy: {
              ...this.value.memberEligibilityPolicy,
              includeImportedMembers: value
            }
          });
        }
      },

      eligibleProgramTierPublicIds: {
        get() {
          return this.value.memberEligibilityPolicy?.eligibleProgramTierPublicIds || [];
        },
        set(value) {
          this.handleInput({
            memberEligibilityPolicy: {
              ...this.value.memberEligibilityPolicy,
              eligibleProgramTierPublicIds: value
            }
          });
        }
      },

      availableAwardPolicyTypes() {
        switch (this.engagementType) {
          case engagementTypes.MEMBER_ENROLLED.id:
            return [
              awardPolicyTypes.ENROLLMENT_BONUS_POINT,
              awardPolicyTypes.ENROLLMENT_BONUS_OFFER
            ];
          case engagementTypes.SALES_TRANSACTION.id:
            return [
              awardPolicyTypes.SALES_TRANSACTION_FIXED_POINT,
              awardPolicyTypes.CHECK_HEADER_POINT,
              awardPolicyTypes.CHECK_HEADER_OFFER,
              awardPolicyTypes.SPECIFIED_ITEM_POINTS_PER_DOLLAR_SPENT,
              awardPolicyTypes.SPECIFIED_ITEM_POINTS_PER_COUNT,
              awardPolicyTypes.SALES_TRANSACTION_RANDOM
            ];
          case engagementTypes.BIRTHDAY.id:
            return [
              awardPolicyTypes.BIRTHDAY_POINT,
              awardPolicyTypes.BIRTHDAY_OFFER
            ];
          case engagementTypes.REFERRAL_CONVERTED.id:
            return [
              awardPolicyTypes.REFERRAL_CONVERTED_POINT,
              awardPolicyTypes.REFERRAL_CONVERTED_OFFER
            ];
          case engagementTypes.MEMBER_PROXIMITY.id:
            return [
              awardPolicyTypes.MEMBER_PROXIMITY_OFFER
            ];
          case engagementTypes.GIFT_CARD_PURCHASE.id:
            return [
              awardPolicyTypes.GIFT_CARD_POINTS_PER_DOLLAR_FUNDED
            ];
          default:
            return [];
        }
      },

      awardPolicyTypeInputsMapping() {
        const earnRuleOfferInputsMapping = {
          component: EarnRuleOfferInputs,
          handlers: {
            'update-offer': offerGuid => this.handleInput({ awardPolicy: { ...this.value.awardPolicy, cardfreeOfferPublicId: offerGuid } })
          }
        };
        const pointsPerDollarInputsMapping = {
          component: PointsPerDollarInputs,
          handlers: {}
        };
        const fixedPointsInputsMapping = {
          component: FixedPointsInputs,
          handlers: {}
        };
        const specifiedItemInputsMapping = {
          component: SpecifiedItemInputs,
          handlers: {}
        };
        const randomAwardInputsMapping = {
          component: RandomAwardInputs,
          handlers: {
            'update-awards': this.updateAwards
          }
        };

        return {
          [awardPolicyTypes.SALES_TRANSACTION_FIXED_POINT.type]: fixedPointsInputsMapping,
          [awardPolicyTypes.CHECK_HEADER_POINT.type]: pointsPerDollarInputsMapping,
          [awardPolicyTypes.CHECK_HEADER_OFFER.type]: earnRuleOfferInputsMapping,
          [awardPolicyTypes.SPECIFIED_ITEM_POINTS_PER_DOLLAR_SPENT.type]: specifiedItemInputsMapping,
          [awardPolicyTypes.SPECIFIED_ITEM_POINTS_PER_COUNT.type]: specifiedItemInputsMapping,
          [awardPolicyTypes.SALES_TRANSACTION_RANDOM.type]: randomAwardInputsMapping,
          [awardPolicyTypes.ENROLLMENT_BONUS_POINT.type]: fixedPointsInputsMapping,
          [awardPolicyTypes.ENROLLMENT_BONUS_OFFER.type]: earnRuleOfferInputsMapping,
          [awardPolicyTypes.BIRTHDAY_POINT.type]: fixedPointsInputsMapping,
          [awardPolicyTypes.BIRTHDAY_OFFER.type]: earnRuleOfferInputsMapping,
          [awardPolicyTypes.REFERRAL_CONVERTED_POINT.type]: fixedPointsInputsMapping,
          [awardPolicyTypes.REFERRAL_CONVERTED_OFFER.type]: earnRuleOfferInputsMapping,
          [awardPolicyTypes.MEMBER_PROXIMITY_OFFER.type]: earnRuleOfferInputsMapping,
          [awardPolicyTypes.GIFT_CARD_POINTS_PER_DOLLAR_FUNDED.type]: pointsPerDollarInputsMapping
        };
      },

      primaryCurrency() {
        return Currency.primaryCurrency();
      },

      membershipTiers() {
        return LoyaltyProgramTier.query()
          .where('programPublicId', this.loyaltyProgramPublicId)
          .orderBy('attainmentSequenceNumber')
          .get();
      },

      isMemberEligibilityPolicyEnabled() {
        return [
          engagementTypes.BIRTHDAY.id,
          engagementTypes.MEMBER_ENROLLED.id,
          engagementTypes.MEMBER_PROXIMITY.id,
          engagementTypes.SALES_TRANSACTION.id
        ].includes(this.engagementType) && this.membershipTiers.length;
      }
    },

    async created() {
      await this.fetchLoyaltyProgramTiers();
    },

    methods: {
      getTierPoints(tier, index) {
        const currentTierPoints = tier.attainmentPolicy?.minimumQualifyingPoints || 0;
        const nextTierPoints = this.membershipTiers[index + 1]?.attainmentPolicy?.minimumQualifyingPoints || 0;
        if (index === this.membershipTiers.length - 1) {
          return `${currentTierPoints}+`;
        }
        return `${currentTierPoints} - ${nextTierPoints - 1}`;
      },

      async fetchLoyaltyProgramTiers() {
        try {
          await LoyaltyProgramTier.fetchProgramTiers(this.loyaltyProgramPublicId);
        }
        catch (error) {
          this.$_onRequestError({
            error,
            toastOptions: { message: 'Unable to fetch merchant loyalty provider configurations' }
          });
        }
      },

      handleInput(payload) {
        this.$emit('input', { ...this.value, ...payload });
      },

      updateAwards(awards) {
        this.handleInput({
          awardPolicy: {
            ...this.value.awardPolicy,
            weightedAwardOptions: awards
          }
        });
      }
    }
  };
</script>

<style lang="sass" scoped>
  .card-content:nth-child(even)
    border-top: 1px solid $grey-lightest
    background-color: $white-ter !important
</style>
