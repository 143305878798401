import NewRelicLoggingService from '@/services/new-relic-logging-service';

const NewRelicLoggingServicePlugin = {
  install(Vue, { store }) {
    const newRelicLoggingService = NewRelicLoggingService(store);

    Vue.newRelicEvent = newRelicLoggingService.newRelicEvent;
    Vue.newRelicError = newRelicLoggingService.newRelicError;

    Vue.prototype.$newRelicEvent = newRelicLoggingService.newRelicEvent;
    Vue.prototype.$newRelicError = newRelicLoggingService.newRelicError;
  }
};

export default NewRelicLoggingServicePlugin;
