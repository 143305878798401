<template>
  <modal-card :title="challenge.name">
    <challenge-review-step
      :value="challenge"
      read-only
    />
  </modal-card>
</template>

<script>
  import ChallengeReviewStep from '@/components/pages/loyalty/challenges/modal-steps/review-step.vue';

  export default {
    name: 'ChallengeReviewModal',

    components: {
      ChallengeReviewStep
    },

    props: {
      challenge: {
        type: Object,
        required: true
      }
    }
  }
</script>

