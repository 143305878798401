<template>
  <div>
    <div class="search-filter">
      <div class="search">
        <b-field
          expanded
          label-position="on-border"
          label="Search"
        >
          <template #message>
            <template v-if="searchQuery.length >= minimumSearchTermLength">
              {{ `${tableModifierCount} Item${tableModifierCount > 1 || !tableModifierCount ? 's' : ''}` }}
            </template>
            <template v-else-if="searchQuery.length">
              Enter at least {{ minimumSearchTermLength }} characters
            </template>
          </template>
          <b-input
            v-model="searchQuery"
            expanded
            placeholder="Search"
            icon="search"
            :icon-right="searchQuery ? 'times-circle' : ''"
            icon-right-clickable
            @icon-right-click="searchQuery = ''"
          />
        </b-field>
      </div>

      <b-field v-if="!hideSelectedToggle">
        <b-radio-button v-model="onlyShowSelected" type="is-dark" :native-value="false">All</b-radio-button>
        <b-radio-button v-model="onlyShowSelected" type="is-dark" :native-value="true">Selected</b-radio-button>
      </b-field>

      <b-button @click="toggleAllRows">
        <b-icon :icon="openRowIds.length ? 'compress-alt' : 'expand-alt'" size="is-medium" />
      </b-button>
    </div>
    <b-table
      ref="table"
      custom-detail-row
      :show-detail-icon="false"
      detailed
      detail-key="id"
      :opened-detailed="openRowIds"
      class="is-middle-aligned"
      :data="tableData"
      sticky-header
      height="100%"
      :loading="isLoading"
    >
      <template #empty>
        <empty-table-loader
          :loading="isLoading"
          :has-icon="false"
          :message="emptyTableMessage"
        />
      </template>
      <b-table-column v-slot="{ row: modifierGroup }" label="Name">
        <span class="link-inverted has-text-weight-bold" @click="toggleRow(modifierGroup.id)">
          {{ modifierGroup.displayName }}
          <b-icon pack="far" icon="angle-right" :class="['open-indicator', {'is-open': openRows[modifierGroup.id]}]" />
        </span>
      </b-table-column>
      <b-table-column numeric>
        <template v-slot:header>
          <span class="mar-r-sm has-text-grey has-text-weight-normal">Select All</span>
          <b-checkbox
            class="no-label"
            :true-value="!reversed"
            :false-value="reversed"
            :value="isAllSelected"
            :indeterminate="isPartialSelected"
            @input="handleSelectAll($event)"
          />
        </template>
        <template v-slot="{ row: modifierGroup }">
          <span class="mar-r-sm has-text-grey">({{ getModGroupSelectedModCount(modifierGroup) }}/{{ modifierGroup.menuItemModifiers.length }} Modifiers)</span>
          <b-checkbox
            :true-value="!reversed"
            :false-value="reversed"
            class="no-label"
            :indeterminate="isModGroupPartialSelected(modifierGroup.menuItemModifiers)"
            :value="isModGroupFullSelected(modifierGroup)"
            @input="handleSelectModifiers($event, modifierGroup.menuItemModifiers.map(mod => mod.id))"
          />
        </template>
      </b-table-column>
      <template v-slot:detail="{ row: modifierGroup }">
        <tr v-for="({ id, displayName }) in modifierGroup.menuItemModifiers" :key="id">
          <td>
            <span class="pad-l">
              <template v-if="searchQuery.length >= minimumSearchTermLength">
                <span v-for="chunk in highlightSearchTerm(displayName)" :key="chunk.key" :class="chunk.match && 'search-term'">{{ chunk.text }}</span>
              </template>
              <template v-else>{{ displayName }}</template>
            </span>
          </td>
          <td class="has-text-right">
            <b-checkbox
              class="no-label"
              :true-value="!reversed"
              :false-value="reversed"
              :value="isModifierSelected(id)"
              @input="handleSelectModifiers($event, [id])"
            />
          </td>
        </tr>
      </template>
    </b-table>
  </div>
</template>

<script>
  import ModifierGroup from '@/store/classes/ModifierGroup';
  import highlightWords from 'highlight-words';



  export default {
    name: 'ModifierSelect',

    props: {
      hideSelectedToggle: {
        type: Boolean,
        default: false
      },

      itemId: {
        type: Number,
        required: true
      },

      reversed: {
        type: Boolean,
        default: false
      },

      selectedModifierIds: {
        type: Array,
        required: true
      }
    },

    data: () => ({
      openRows: {},
      searchQuery: '',
      minimumSearchTermLength: 3,
      onlyShowSelected: false
    }),

    computed: {
      tableData() {
        const data = this.onlyShowSelected ? this.selectedMenu : this.modifierGroups;
        return this.searchQuery.length < this.minimumSearchTermLength ? data : this.searchMenu(data);
      },

      selectedMenu() {
        const isSelected = ({ id }) => (this.reversed ? !this.isModifierSelected(id) : this.isModifierSelected(id));

        return this.$clone(this.modifierGroups).reduce((array, modGroup) => {
          const selectedModifiers = modGroup.menuItemModifiers.filter(isSelected);
          if (selectedModifiers.length) {
            array.push({ ...modGroup, menuItemModifiers: selectedModifiers });
          }
          return array;
        }, []);
      },

      modifierGroups() {
        return ModifierGroup.query()
          .where('menuItemId', this.itemId)
          .with('menuItemModifiers', query => query.where(({ availabilityEndDate }) => {
            const hasNoEndDate = !availabilityEndDate;
            const hasFutureEndDate = new Date(availabilityEndDate) > new Date();
            return hasNoEndDate || hasFutureEndDate;
          }))
          .get();
      },

      openRowIds() {
        return Object.entries(this.openRows).reduce((openIds, [id, isOpen]) => {
          if (isOpen) openIds.push(Number(id));
          return openIds;
        }, []);
      },

      allModifierIds() {
        return this.tableData.flatMap(({ menuItemModifiers }) => menuItemModifiers.map(({ id }) => id));
      },

      isAllSelected() {
        return this.allModifierIds.every(id => this.selectedModifierIds.includes(id));
      },

      isPartialSelected() {
        const selectedModifierIdsOfItem = this.selectedModifierIds.filter(id => this.allModifierIds.includes(id));
        return !!selectedModifierIdsOfItem.length && (this.revered ? this.isAllSelected : !this.isAllSelected);
      },

      tableModifierCount() {
        return this.tableData.reduce((total, { menuItemModifiers }) => total + menuItemModifiers.length, 0);
      },

      emptyTableMessage() {
        return `No Modifiers ${this.onlyShowSelected ? 'Selected' : 'Found'}`;
      },

      isLoading() {
        return ModifierGroup.$state().fetchingItemId === this.itemId;
      }
    },

    watch: {
      searchQuery: 'handleSearchQueryChange'
    },

    created() {
      this.onCreated();
    },

    methods: {
      async onCreated() {
        await this.fetchModifierGroups();
        if (this.modifierGroups.length === 1) this.openAllRows();
      },

      /* menu search */
      /* *********** */
      searchMenu(menu) {
        const isMatch = ({ displayName }) => new RegExp(this.searchQuery, 'ig').test(displayName);

        return this.$clone(menu).reduce((array, modGroup) => {
          const matchingModifiers = modGroup.menuItemModifiers.filter(isMatch);
          if (matchingModifiers.length) {
            array.push({ ...modGroup, menuItemModifiers: matchingModifiers });
          }
          return array;
        }, []);
      },

      highlightSearchTerm(string) {
        return highlightWords({ text: string, query: this.searchQuery, matchExactly: true });
      },

      handleSearchQueryChange(newQuery) {
        if (newQuery.length >= this.minimumSearchTermLength) this.openAllRows();
        else this.closeAllRows();
      },

      /* open-close row logic */
      /* ******************** */
      openRow(id) {
        this.$set(this.openRows, id, true);
      },

      closeRow(id) {
        this.$set(this.openRows, id, false);
      },

      toggleRow(id) {
        if (this.openRows[id]) this.closeRow(id);
        else this.openRow(id);
      },

      openAllRows() {
        this.openRows = this.modifierGroups.reduce((obj, { id }) => ({ ...obj, [id]: true }), {});
      },

      closeAllRows() {
        this.openRows = {};
      },

      toggleAllRows() {
        if (this.openRowIds.length) this.closeAllRows();
        else this.openAllRows();
      },

      /* checkbox logic */
      /* ************** */
      isModifierSelected(id) {
        return this.selectedModifierIds.includes(id);
      },

      isModGroupPartialSelected(modifiers) {
        return modifiers.some(({ id }) => this.selectedModifierIds.includes(id)) && !modifiers.every(({ id }) => this.selectedModifierIds.includes(id));
      },

      isModGroupFullSelected({ menuItemModifiers }) {
        return menuItemModifiers.every(({ id }) => this.selectedModifierIds.includes(id));
      },

      /* selection logic */
      /* *************** */
      handleSelectModifiers(isChecked, modifierIds) {
        this.$emit('modifier-select', { ids: modifierIds, selected: isChecked });
      },

      handleSelectAll(isChecked) {
        const modifierIds = this.tableData.flatMap(({ menuItemModifiers }) => menuItemModifiers.map(({ id }) => id));
        this.$emit('modifier-select', { ids: modifierIds, selected: isChecked });
      },

      getModGroupSelectedModCount(modGroup) {
        return modGroup.menuItemModifiers.reduce((total, { id }) => {
          const isModifierSelected = this.reversed ? !this.isModifierSelected(id) : this.isModifierSelected(id);
          return isModifierSelected ? total + 1 : total;
        }, 0);
      },

      /* fetch modifier groups with modifiers */
      /* ************************************ */
      async fetchModifierGroups() {
        try {
          await ModifierGroup.fetchByMenuItemId({ itemId: this.itemId, includeModifiers: true });
        }
        catch (error) {
          this.$_onRequestError({
            toastOptions: {
              message: 'There was an error fetching this item\'s modifiers'
            },
            error
          });
        }
      }
    }
  };
</script>

<style lang='sass' scoped>
  .search-filter
    display: flex
    gap: $size-normal
    padding: $size-normal $size-medium
    border-bottom: 1px solid $grey-lighter
    position: sticky
    top: 0
    z-index: 1
    background-color: $white

    > *
      margin: 0 !important

    .search
      flex: 1

  .open-indicator
    transition: transform 200ms
    &.is-open
      transform: rotate(90deg)
</style>
