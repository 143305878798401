<template>
  <modal-card
    :title="title"
    :subtitle="subtitle"
    modal-card-body-class="is-paddingless"
    show-small-subtitle
  >
    <b-collapse
      class="expandable-section"
      animation="slide"
    >
      <div
        slot="trigger"
        slot-scope="props"
        role="button"
        class="expandable-section--header"
      >
        <h4 class="subtitle is-5 is-marginless">
          Transaction Info
        </h4>
        <b-icon class="open-indicator" :icon="props.open ? 'angle-down' : 'angle-right'" />
      </div>
      <dl class="details-container">
        <div class="sub-container-row dist-y-md">
          <div class="sub-container-column">
            <dt class="has-text-grey">Timestamp:</dt>
            <dd v-if="transaction.storeId && getStore(transaction.storeId)">
              <div class="has-text-weight-semibold text-ellipsis">{{ transaction.transactionDateTime | moment('M/D/YYYY', getStore(transaction.storeId).ianaTimezoneId) }}</div>
              <div class="has-text-weight-semibold text-ellipsis">{{ transaction.transactionDateTime | moment('h:mm A z', getStore(transaction.storeId).ianaTimezoneId) }}</div>
            </dd>
            <dd v-else class="has-text-weight-semibold">
              <div class="has-text-weight-semibold text-ellipsis">{{ transaction.transactionDateTime | moment('M/D/YYYY', null, true) }}</div>
              <div class="has-text-weight-semibold text-ellipsis">{{ transaction.transactionDateTime | moment('h:mm A z', null, true) }}</div>
            </dd>
          </div>
          <div class="sub-container-column">
            <dt class="has-text-grey">Location:</dt>
            <dd v-if="transaction.storeId && getStore(transaction.storeId)" class="has-text-weight-semibold text-ellipsis">{{ getStore(transaction.storeId).description }}</dd>
            <dd v-else class="has-text-weight-semibold">N/A</dd>
          </div>
        </div>
        <div class="sub-container-row dist-y-md">
          <div class="sub-container-column">
            <dt class="has-text-grey">Transaction Type:</dt>
            <dd class="has-text-weight-semibold text-ellipsis">{{ transaction.paymentTransactionType || 'N/A' }}</dd>
          </div>
          <div class="sub-container-column">
            <dt class="has-text-grey">Status:</dt>
            <dd class="has-text-weight-semibold text-ellipsis">{{ paymentTransactionStatusDisplay || 'N/A' }}</dd>
          </div>
        </div>
        <div class="sub-container-row mar-b">
          <div class="sub-container-column">
            <dt class="has-text-grey">Payment Method:</dt>
            <dd class="has-text-weight-semibold text-ellipsis">{{ getPaymentMethodDisplay(transaction.paymentMethod) || 'N/A' }}</dd>
          </div>
        </div>
        <div class="sub-container-row dist-y-md">
          <div class="sub-container-column">
            <dt class="has-text-grey">Card Holder Name:</dt>
            <dd class="has-text-weight-semibold text-ellipsis">{{ transaction.cardHolderName || 'N/A' }}</dd>
          </div>
          <div class="sub-container-column">
            <dt class="has-text-grey">Card Number:</dt>
            <dd v-if="transaction.accountNumberLastFour" class="has-text-weight-semibold is-flex align-center">
              <payment-icon class="mar-r-sm" :payment="paymentProp" show-fallback-icon />
              ···· {{ transaction.accountNumberLastFour }}
              <b-icon
                v-if="transaction.accountSavedPaymentId"
                v-tippy="{ content: 'This card is a saved payment method on the user’s account' }"
                class="mar-l-xs"
                icon="heart"
                pack="fas"
                type="is-primary"
              />
            </dd>
            <dd v-else class="has-text-weight-semibold">N/A</dd>
          </div>
        </div>
        <div class="sub-container-row dist-y-md">
          <div class="sub-container-column">
            <dt class="has-text-grey">Amount:</dt>
            <dd v-if="transaction.amount" class="has-text-weight-semibold text-ellipsis">{{ transaction.amount | dollars }} {{ transaction.currencyCode }}</dd>
            <dd v-else class="has-text-weight-semibold">N/A</dd>
          </div>
          <div v-if="transaction.surcharge > 0" class="sub-container-column">
            <dt class="has-text-grey">Surcharge:</dt>
            <dd v-if="transaction.surcharge" class="has-text-weight-semibold text-ellipsis">{{ transaction.surcharge | dollars }} {{ transaction.currencyCode }}</dd>
          </div>
        </div>
        <div class="sub-container-row dist-y-md mar-t">
          <div v-if="transaction.isRefundableType" class="is-flex-center" :style="{ width: '100%' }">
            <dt :style="{ width: '200px' }">
              <div
                v-tippy="{
                  content: refundButtonDisabledMessage,
                  onShow: () => !isRefundable
                }"
              >
                <b-button
                  :disabled="!isRefundable || !canCaptureVoidRefund('refund')"
                  :loading="refunding"
                  type="is-danger"
                  class="is-full-width"
                  @click="openRefundModal"
                >
                  <img
                    class="image is-inline"
                    :style="{ height: '30px', marginRight: '0.3rem' }"
                    src="/images/payments/refund-white.svg"
                    alt="refund"
                    title="refund"
                  >
                  Refund
                </b-button>
              </div>
            </dt>
          </div>
          <div v-if="transaction.isVoidableOrCaptureableType" class="sub-container-column">
            <dt>
              <div
                v-tippy="{
                  content: 'This transaction has already been voided or captured',
                  onShow: () => !isVoidableOrCaptureable
                }"
              >
                <b-button
                  :loading="voiding"
                  type="is-black"
                  class="is-full-width"
                  :disabled="!isVoidableOrCaptureable || !canCaptureVoidRefund('void')"
                  @click="confirmRefundVoidCapture('void')"
                >
                  <img
                    class="image is-inline"
                    :style="{ height: '30px', marginRight: '0.3rem' }"
                    src="/images/payments/void-white.svg"
                    alt="void"
                    title="void"
                  >
                  Void
                </b-button>
              </div>
            </dt>
          </div>
          <div v-if="transaction.isVoidableOrCaptureableType" class="sub-container-column">
            <dt>
              <div
                v-tippy="{
                  content: 'This transaction has already been voided or captured',
                  onShow: () => !isVoidableOrCaptureable
                }"
              >
                <b-button
                  :loading="capturing"
                  type="is-primary"
                  class="is-full-width"
                  :disabled="!isVoidableOrCaptureable || !canCaptureVoidRefund('capture')"
                  @click="confirmRefundVoidCapture('capture')"
                >
                  <img
                    class="image is-inline"
                    :style="{ height: '30px', marginRight: '0.3rem' }"
                    src="/images/payments/capture-white.svg"
                    alt="capture"
                    title="capture"
                  >
                  Capture
                </b-button>
              </div>
            </dt>
          </div>
        </div>
      </dl>
    </b-collapse>
    <b-collapse
      class="expandable-section"
      animation="slide"
    >
      <div
        slot="trigger"
        slot-scope="props"
        role="button"
        class="expandable-section--header"
      >
        <h4 class="subtitle is-5 is-marginless">
          Additional Info
        </h4>
        <b-icon class="open-indicator" :icon="props.open ? 'angle-down' : 'angle-right'" />
      </div>
      <dl class="details-container">
        <div v-if="transaction.orderId" class="sub-container-row dist-y-md mar-b-lg">
          <div class="sub-container-column">
            <dt class="has-text-grey">Order Number:</dt>
            <dd class="has-text-weight-semibold text-ellipsis">{{ transaction.orderId }}</dd>
            <dd>
              <a
                class="link has-text-weight-bold"
                @click="openOrderDetails(transaction.orderId)"
              >View Order Details</a>
            </dd>
          </div>
          <!-- <div class="sub-container-column">
            <dt class="has-text-grey">Gift Card Transaction #:</dt>
            <dd class="has-text-weight-semibold text-ellipsis">{{ getPaymentMethodDisplay(transaction.paymentMethod) || 'N/A' }}</dd>
          </div> -->
        </div>

        <div class="additional-info-row">
          <dt class="has-text-grey">Payment Gateway:</dt>
          <dd class="has-text-weight-semibold text-ellipsis">{{ capitalCase(transaction.paymentGatewayType) || 'N/A' }}</dd>
        </div>
        <div v-if="transaction.requestedBy" class="additional-info-row">
          <dt class="has-text-grey">Refunded By:</dt>
          <dd class="has-text-weight-semibold text-ellipsis">{{ transaction.requestedBy }}</dd>
        </div>
        <div class="additional-info-row">
          <dt class="has-text-grey">Payment Processor Transaction Id:</dt>
          <dd class="has-text-weight-semibold text-ellipsis">{{ transaction.paymentProcessorTransactionId || 'N/A' }}</dd>
        </div>
        <div class="additional-info-row">
          <dt class="has-text-grey mar-r-xl">Client Transaction Id:</dt>
          <dd class="has-text-weight-semibold text-ellipsis">{{ transaction.clientTransactionId || 'N/A' }}</dd>
        </div>
        <div class="additional-info-row">
          <dt class="has-text-grey mar-r-xl">
            Secondary Transaction Id
            <b-icon
              v-tippy="{ maxWidth: 270 }"
              content="This may be room number, member account ID, tag ID, etc"
              pack="far"
              icon="info-circle"
              class="is-size-6"
              type="is-grey"
            />:
          </dt>
          <dd class="has-text-weight-semibold text-ellipsis">
            {{ transaction.paymentProcessorReference1 || transaction.paymentProcessorReference2 || 'N/A' }}
          </dd>
        </div>
        <div class="additional-info-row">
          <dt class="has-text-grey">Authorization Code:</dt>
          <dd class="has-text-weight-semibold text-ellipsis">{{ transaction.authorizationCode || 'N/A' }}</dd>
        </div>
        <div class="additional-info-row">
          <dt class="has-text-grey">Result Code:</dt>
          <dd class="has-text-weight-semibold text-ellipsis">{{ transaction.gatewayResultCode || 'N/A' }}</dd>
        </div>
        <div class="additional-info-row">
          <dt class="has-text-grey">Result Message:</dt>
          <dd class="has-text-weight-semibold text-ellipsis">{{ transaction.resultMessage || 'N/A' }}</dd>
        </div>
      </dl>
    </b-collapse>

    <b-collapse
      v-if="isCardfreeAdmin"
      class="expandable-section"
      animation="slide"
    >
      <div
        slot="trigger"
        slot-scope="props"
        role="button"
        class="expandable-section--header"
      >
        <h4 class="subtitle is-5 is-marginless">
          Admin Details
        </h4>
        <b-icon class="open-indicator" :icon="props.open ? 'angle-down' : 'angle-right'" />
      </div>
      <dl class="details-container">
        <div class="additional-info-row">
          <dt class="has-text-grey">Allow Refund On Device:</dt>
          <dd class="has-text-weight-semibold text-ellipsis">
            <b-icon
              :icon="transaction.allowRefundOnDevice ? 'check' : 'times'"
              :type="transaction.allowRefundOnDevice ? 'is-success' : 'is-danger'"
            />
          </dd>
        </div>
        <div class="additional-info-row">
          <dt class="has-text-grey">Allow Refund On Portal:</dt>
          <dd class="has-text-weight-semibold text-ellipsis">
            <b-icon
              :icon="transaction.allowRefundOnPortal ? 'check' : 'times'"
              :type="transaction.allowRefundOnPortal ? 'is-success' : 'is-danger'"
            />
          </dd>
        </div>
        <div class="additional-info-row">
          <dt class="has-text-grey">Created User IP:</dt>
          <dd class="has-text-weight-semibold text-ellipsis">{{ transaction.createdUserIp || 'N/A' }}</dd>
        </div>
        <div class="additional-info-row">
          <dt class="has-text-grey">Created Source:</dt>
          <dd class="has-text-weight-semibold text-ellipsis">{{ transaction.createdSource || 'N/A' }}</dd>
        </div>
        <div class="additional-info-row mar-b">
          <dt class="has-text-grey">Client Correlation Id:</dt>
          <dd class="has-text-weight-semibold text-ellipsis">{{ transaction.clientCorrelationId || 'N/A' }}</dd>
        </div>
        <a class="link has-text-weight-bold" @click="openTransactionJSON">
          View Gateway Response and Other Transaction Data
          <b-icon icon="external-link-alt" />
        </a>
      </dl>
    </b-collapse>
    <template #footer>
      <div class="buttons all-bold">
        <b-button rounded @click="$_confirmCloseModal({ programmatic: true })">
          Close
        </b-button>
      </div>
    </template>
  </modal-card>
</template>

<script>
  import PaymentTransaction from '@/store/classes/PaymentTransaction';
  import Store from '@/store/classes/Store';
  import paymentMethods from '@/constants/paymentMethods';
  import capitalCase from '@/helpers/capitalCase';
  import orderModal from '@/components/pages/orders/order-modal.vue';
  import { mapGetters } from 'vuex';
  import confirmModalCloseMixin from '@/mixins/confirm-modal-close';
  import paymentTransactionRefundModal from './payment-transaction-refund-modal.vue';

  export default {
    name: 'PaymentTransactionModal',

    mixins: [confirmModalCloseMixin],

    props: {
      transactionId: {
        type: Number,
        required: true
      },

      summary: {
        type: Object,
        required: true
      }
    },

    data: () => ({}),

    computed: {
      ...mapGetters('session', ['isCardfreeAdmin']),

      transaction() {
        return PaymentTransaction.query().whereId(this.transactionId).first() || {};
      },

      stores() {
        return Store.orderByName().get();
      },

      title() {
        return `Transaction #: ${this.transactionId}`;
      },

      subtitle() {
        return this.transaction?.merchantReference && `Reference #: ${this.transaction.merchantReference}`;
      },

      paymentProp() {
        return {
          paymentType: this.transaction.cardType,
          cardType: this.transaction.cardType
        };
      },

      paymentTransactionStatusDisplay() {
        return this.transaction.paymentTransactionStatus === 'Succeeded' ? 'Success' : 'Failed';
      },

      refunding() {
        return PaymentTransaction.$state().refunding;
      },

      voiding() {
        return PaymentTransaction.$state().voiding;
      },

      capturing() {
        return PaymentTransaction.$state().capturing;
      },

      remainingAmount() {
        return this.summary.remainingRefundAmount;
      },

      groupedTransactions() {
        return this.summary.transactions || [];
      },

      isVoidableOrCaptureable() {
        return PaymentTransaction.isVoidableOrCaptureable(this.transaction, this.groupedTransactions);
      },

      isRefundable() {
        return PaymentTransaction.isRefundable(this.transaction, this.groupedTransactions, this.remainingAmount);
      },

      refundButtonDisabledMessage() {
        return `This transaction is not refundable. ${this.remainingAmount === 0 ? 'There is no amount remaining to be refunded, so this action may already have been taken.' : ''}`.trim();
      }
    },

    methods: {
      capitalCase,

      canCaptureVoidRefund(type) {
        return this.$can(type, 'Payments::PaymentTransactions::PaymentTransaction');
      },

      getStore(storeId) {
        return this.stores.find(s => s.storeId === storeId);
      },

      getPaymentMethodDisplay(paymentMethodName) {
        return Object.values(paymentMethods).find(value => value.type === paymentMethodName)?.display || capitalCase(paymentMethodName);
      },

      openTransactionJSON() {
        const file = new Blob(
          [JSON.stringify(this.transaction, null, 2)],
          { type: 'application/json' }
        );
        const fileURL = URL.createObjectURL(file);
        window.open(fileURL);
      },

      openOrderDetails(orderId) {
        this.$buefy.modal.open({
          canCancel: ['escape', 'outside'],
          component: orderModal,
          customClass: 'auto-width',
          hasModalCard: true,
          parent: this,
          props: { orderId }
        });
      },

      openRefundModal() {
        this.$buefy.modal.open({
          parent: this,
          component: paymentTransactionRefundModal,
          hasModalCard: true,
          trapFocus: true,
          canCancel: false,
          props: {
            transaction: this.transaction,
            totalAmount: this.summary.amount,
            remainingRefundAmount: this.summary.remainingRefundAmount
          },
          events: {
            'successful-refund': this.closeModal
          }
        });
      },

      closeModal() {
        this.$parent.close();
        this.$emit('successful-refund-void-capture');
      },

      confirmRefundVoidCapture(type) {
        const typesMap = {
          refund: 'is-danger',
          void: 'is-black',
          capture: 'is-primary'
        };

        this.$buefy.dialog.confirm({
          title: `Confirm ${capitalCase(type)} Transaction`,
          message: `Are you sure you want to ${type} this transaction?`,
          confirmText: `${capitalCase(type)}`,
          type: typesMap[type],
          onConfirm: () => this[`${type}Transaction`]()
        });
      },

      async voidTransaction() {
        try {
          await PaymentTransaction.void(this.transactionId);

          this.$_onRequestSuccess({
            toastOptions: {
              message: 'Successfully voided the transaction'
            }
          });

          this.closeModal();
        }
        catch (error) {
          this.$_onRequestError({
            error,
            toastOptions: {
              message: 'There was an error voiding the transaction'
            }
          });
        }
      },

      async captureTransaction() {
        try {
          await PaymentTransaction.capture(this.transactionId);

          this.$_onRequestSuccess({
            toastOptions: {
              message: 'Successfully captured the transaction'
            }
          });

          this.closeModal();
        }
        catch (error) {
          this.$_onRequestError({
            error,
            toastOptions: {
              message: 'There was an error capturing the transaction'
            }
          });
        }
      }
    }
  };
</script>

<style lang='sass' scoped>
  ::v-deep
    .button
      span
        display: flex
        align-items: center
        justify-content: center

    .modal-card
      max-width: 475px

    .modal-card-body
      > *:nth-child(even)
        background: $white-bis

      > *:last-child
        margin-bottom: 0

  .expandable-section
    border-bottom: 1px solid $grey-lighter
    padding: 1rem

    &:last-of-type
      border-bottom: none

    .expandable-section--header
      display: flex
      align-items: center
      justify-content: space-between
      cursor: pointer

      > *
        color: $grey-dark
        transition: 100ms

      &:hover > *
        color: $primary

    .details-container
      width: 100%
      margin-top: 1rem
      overflow: hidden

      &.customer-info
        > *
          min-width: 0
          overflow: hidden

      .no-details
        margin-right: 7rem !important

      .sub-container-column
        display: flex
        flex: 1
        flex-direction: column

      .sub-container-row
        display: flex

        > div:not(:last-child)
          margin-right: 2rem

  .additional-info-row
    display: flex
    justify-content: space-between
    align-items: baseline
    margin-bottom: 0.3rem

    &:last-of-type
      margin-bottom: 0
</style>
