<template>
  <div class="kds detail-page">
    <b-loading :is-full-page="false" :active="isLoadingStores" />

    <audio>
      <source src="/audio/definite.mp3" type="audio/mpeg">
      <p>Your browser doesn't support HTML5 audio.</p>
    </audio>

    <qr-code-scanner
      v-if="$_selectedMerchant.merchantOptionsCheckout.showOrderQrCode"
      class="qr-code-scanner"
      :show-viewfinder="showQrViewfinder"
      @qr-code-scan-start="qrCodeScanRunning = true"
      @qr-code-scanned="handleQrCodeScanned"
    />

    <section class="section">
      <div class="container">
        <h2 class="title is-3 mar-b-xs has-text-centered-mobile">
          Order Dashboard
        </h2>

        <b-message v-if="noStoresExist" type="is-warning" class="mar-t-lg has-shadow" has-icon>
          You don't have any locations configured yet.<br>
          Please configure a location in
          <router-link :to="{ name: 'storeList' }">Location Management</router-link>
          and try again.
        </b-message>

        <template v-else>
          <div class="is-flex is-flex-wrap justify-between justify-center-tablet gap-x gap-y-xs mar-b">
            <div class="has-text-centered-mobile">
              <div class="level is-mobile is-inline-flex">
                <h3 v-if="currentTimestamp" class="level-item subtitle is-4 has-text-grey">
                  {{ currentDisplayDate }} - {{ currentDisplayTimeHours }}<span class="clock-separator">:</span>{{ currentDisplayTimeMinutes }}
                </h3>
                <b-skeleton v-else height="30" width="250" />

                <div v-if="!noStoresExist" class="level-item">
                  <b-button
                    v-tippy="{ content: 'Refresh Orders', placement: 'bottom' }"
                    class="is-transparent"
                    :disabled="isFetchingOrders"
                    @click="refreshOrders('manualRefresh')"
                  >
                    <b-icon :class="['has-text-primary', {'spin-smooth': isFetchingOrders}]" icon="sync-alt" pack="far" />
                  </b-button>

                  <dropdown-menu trigger="hover">
                    <b-button slot="trigger" class="is-transparent">
                      <b-icon icon="info-circle" pack="far" class="has-text-primary" />
                    </b-button>
                    <template v-if="$can('manage', 'all')">
                      <b-dropdown-item class="info-dropdown-item no-wrap-text" custom>
                        <div class="is-flex align-center">
                          <p class="label mar-b-none mar-r">Per Page:</p>
                          <b-field>
                            <b-radio-button v-model="perPage" size="is-small" :native-value="5">5</b-radio-button>
                            <b-radio-button v-model="perPage" size="is-small" :native-value="20">20</b-radio-button>
                            <b-radio-button v-model="perPage" size="is-small" :native-value="50">50</b-radio-button>
                          </b-field>
                        </div>
                      </b-dropdown-item>
                      <hr class="dropdown-divider">
                    </template>
                    <b-dropdown-item class="info-dropdown-item no-wrap-text" custom>
                      <b-icon icon="circle" type="is-danger" class="mar-r-xs" />
                      Order is due in less than 15 minutes
                    </b-dropdown-item>
                    <b-dropdown-item class="info-dropdown-item no-wrap-text" custom>
                      <b-icon icon="circle" type="is-warning" class="mar-r-xs" />
                      Order is due in 15-30 minutes
                    </b-dropdown-item>
                  </dropdown-menu>

                  <b-button
                    v-if="qrCodeScanRunning"
                    v-tippy="{ content: 'QR Code Scanner Running', placement: 'bottom' }"
                    class="is-transparent"
                    @click="showQrViewfinder = !showQrViewfinder"
                  >
                    <b-icon type="is-primary" icon="qrcode" />
                  </b-button>
                </div>
              </div>
            </div>

            <b-select
              v-model="selectedStoreId"
              placeholder="Select a location..."
              icon="map-marker-alt"
              icon-pack="far"
            >
              <option v-for="store in allStores" :key="store.storeId" :value="store.storeId">{{ store.description }}</option>
            </b-select>
          </div>

          <countdown-message :active.sync="showAutoFetchMessage" type="is-primary" size="is-small" :duration="10000">
            <b>This page will automatically check for new orders every {{ kdsNewOrderReminderDuration }} seconds</b>
          </countdown-message>

          <div class="card pad-x pad-t">
            <div class="search-inputs mar-b is-flex gap">
              <b-field label="Platform" label-position="on-border">
                <b-select v-model="platformType">
                  <option :value="null">All</option>
                  <option value="web">Web</option>
                  <option value="mobile">Mobile</option>
                  <option value="kiosk">Kiosk</option>
                </b-select>
              </b-field>
              <b-field label="Order Mode" label-position="on-border">
                <b-select v-model="fulfillmentType">
                  <option :value="null">All</option>
                  <option v-for="ft in fulfillmentTypes" :key="`${ft.id}-ft`" :value="ft.name">{{ formattedFulfillmentType(ft.name) }}</option>
                </b-select>
              </b-field>
              <b-field label="Criteria" label-position="on-border" class="flex-grow">
                <b-select v-model="criteria">
                  <option value="guestName">Name</option>
                  <option value="ticketNumber">Ticket Number</option>
                  <option value="phoneNumber">Phone Number</option>
                </b-select>
                <b-input
                  v-model="searchTerm"
                  :placeholder="'Search by ' + capitalCase(criteria)"
                  expanded
                  :icon-right="searchTerm && 'times-circle'"
                  icon-right-clickable
                  @icon-right-click="searchTerm = null"
                />
              </b-field>
            </div>
            <b-tabs
              v-model="tabName"
              type="is-toggle"
              :animated="false"
              expanded
            >
              <b-tab-item v-for="(swimlane, index) in swimlanes" :key="index" :value="swimlane.preparationStatus">
                <template #header>
                  {{ swimlane.name }}
                  <b-tag
                    rounded
                    class="mar-l-sm"
                    :type="tabName === swimlane.preparationStatus ? 'is-light' : swimlane.tagColor"
                  >
                    <b>{{ swimlane.count }}</b>
                  </b-tag>
                </template>
                <template>
                  <div v-if="!currentTimestamp || isFetchingOrders" class="pad-xl has-text-centered">
                    <b-icon icon="spinner-third" pack="fal" type="is-grey is-light" class="spin fa-3x" />
                  </div>

                  <div v-else-if="swimlane.orders.length" class="dist-y-sm">
                    <kds-order
                      v-for="order in swimlane.orders"
                      :key="order.orderId"
                      :current-timestamp="currentTimestamp"
                      :order="order"
                      :has-repeat-notification="orderIdsForRepeatNotification.includes(order.orderId)"
                    />
                  </div>

                  <p v-else class="has-text-centered has-text-grey is-size-5 pad">
                    <template v-if="searchTerm || platformType || fulfillmentType">
                      No Search Results
                    </template>
                    <template v-else>
                      There are currently no <b class="has-text-dark">{{ swimlane.name }}</b> orders
                    </template>
                  </p>

                  <b-pagination
                    v-if="swimlane.count > perPage"
                    v-model="page"
                    :total="swimlane.count"
                    :current="page"
                    :per-page="perPage"
                    :range-before="2"
                    :range-after="2"
                    order="is-centered"
                    aria-next-label="Next page"
                    aria-previous-label="Previous page"
                    aria-page-label="Page"
                    aria-current-label="Current page"
                    class="mar-t-lg"
                  />

                </template>
              </b-tab-item>
            </b-tabs>
          </div>
        </template>
      </div>
    </section>

    <b-modal :active="!!selectedOrderId" custom-class="auto-width" :can-cancel="['outside']" @close="setSelectedOrderId(null)">
      <kds-order-modal v-if="selectedOrderId" :order-id="selectedOrderId" :store="selectedStore" />
    </b-modal>
  </div>
</template>



<script>
  import moment from 'moment-timezone';
  import Order from '@/store/classes/KdsOrder';
  import Store from '@/store/classes/Store';
  import FulfillmentType from '@/store/classes/FulfillmentType';
  import OrderDashboardColumn from '@/store/classes/OrderDashboardColumn';
  import logger from '@/services/logger';
  import merchantMixin from '@/mixins/merchant';
  import kdsOrderComponent from '@/components/pages/kds/kds-order.vue';
  import capitalCase from '@/helpers/capitalCase';
  import getChangedResources from '@/helpers/get-changed-resources';
  import { normalizeWhitespace } from '@/helpers/strings';
  import storage from '@/services/storage';
  import events from '@/services/events';
  import qrCodeScanner from '@/components/globals/qr-code-scanner.vue';
  import kdsOrderModal from './kds-order-modal.vue';
  import debounce from 'lodash.debounce';
  import { camelCase } from 'change-case';


  export default {
    name: 'KdsBoard',

    components: {
      qrCodeScanner,
      kdsOrderModal,
      'kds-order': kdsOrderComponent
    },

    mixins: [merchantMixin],

    data() {
      return {
        capitalCase,
        currentDisplayDate: null,
        currentDisplayTimeHours: null,
        currentDisplayTimeMinutes: null,
        currentTimeInterval: null,
        currentTimestamp: null,
        disconnectSnackbar: null,
        isFetchingOrders: false,
        noStoresExist: false,
        perPage: 50, // Max page size supported by CAPI is 50
        selectedStoreId: null,
        orderCounts: {
          New: { count: 0, orderIds: [] },
          InPrep: { count: 0, orderIds: [] },
          PickedUp: { count: 0, orderIds: [] },
          Completed: { count: 0, orderIds: [] },
          Canceled: { count: 0, orderIds: [] }
        },
        qrCodeScanRunning: false,
        showQrViewfinder: false,
        criteria: 'guestName',
        searchTerm: null,
        platformType: null,
        fulfillmentType: null,
        showAutoFetchMessage: true,
        timeoutId: null,
        orderIdsForRepeatNotification: []
      };
    },

    computed: {
      tabName: {
        get() {
          return this.$route.params.tabName;
        },
        async set(tabName) {
          this.fetchOrderCounts(this.selectedStoreId, false, 'setTabName');
          this.fetchOrders({
            preparationStatus: tabName,
            page: 1
          });

          try {
            await this.$router.replace({ params: { tabName }, query: { page: 1 } });
          }
          catch {
            return null;
          }
        }
      },

      page: {
        get() {
          return Number(this.$route.query.page) || 1;
        },
        async set(page) {
          this.fetchOrderCounts(this.selectedStoreId, false, 'setPageNumber');
          this.fetchOrders({ page });

          try {
            await this.$router.replace({ query: { page } });
          }
          catch {
            return null;
          }
        }
      },

      allStores() {
        return Store.orderByName().get();
      },

      selectedStore() {
        return Store.find(this.selectedStoreId);
      },

      fulfillmentTypes() {
        if (!this.allStores) return [];
        return FulfillmentType.merchantSupported(this.$_selectedMerchant, this.allStores);
      },

      isLoadingStores() {
        return Store.$state().fetchingAll;
      },

      selectedOrderId() {
        return Order.$state().selectedOrderId;
      },

      newOrders() {
        const queryResult = Order
          .query()
          .where('preparationStatus', 'New')
          .orderBy(this.pickupOrOrderDate)
          .get();

        return queryResult || [];
      },

      inPrepOrders() {
        const queryResult = Order
          .query()
          .where('preparationStatus', 'InPrep')
          .orderBy(this.pickupOrOrderDate)
          .get();

        return queryResult || [];
      },

      completedOrders() {
        const queryResult = Order
          .query()
          .where('preparationStatus', 'Completed')
          .orderBy(this.pickupOrOrderDate)
          .get();

        return queryResult || [];
      },

      pickedUpOrders() {
        const queryResult = Order
          .query()
          .where('preparationStatus', 'PickedUp')
          .orderBy(this.pickupOrOrderDate)
          .get();

        return queryResult || [];
      },

      canceledOrders() {
        const queryResult = Order
          .query()
          .where('preparationStatus', 'Canceled')
          .orderBy(this.pickupOrOrderDate)
          .get();

        return queryResult || [];
      },

      swimlanes() {
        return this.$clone(this.orderDashboardColumns).map((col) => {
          if (!col) return {};
          return {
            name: col.displayName,
            preparationStatus: col.preparationStatus,
            tagColor: col.tagType,
            count: this.searchTerm || this.platformType || this.fulfillmentType ? this[`${camelCase(col.preparationStatus)}Orders`]?.length : this.orderCounts[col.preparationStatus]?.count,
            orders: this[`${camelCase(col.preparationStatus)}Orders`]
          };
        });
      },

      repeatKdsNotifications() {
        return this.$_selectedMerchant?.features.repeatKdsNotifications;
      },

      orderDashboardColumns() {
        return OrderDashboardColumn.query().whereIdIn(this.$_selectedMerchant?.orderDashboardColumns).orderBy('sortOrder').get();
      },

      kdsNewOrderReminderDuration() {
        return this.$_selectedMerchant?.features.kdsNewOrderReminderDuration;
      }
    },

    watch: {
      selectedOrderId: 'handleSelectedOrderIdChange',
      selectedStoreId: 'handleStoreChange',
      perPage: {
        handler() {
          this.refreshOrders('perPageChange');
        }
      },
      searchTerm: 'debouncedFetchOrders',
      criteria: 'handleCriteriaChange',
      platformType: 'handlePlatformTypeChange',
      fulfillmentType: 'handleFulfillmentTypeChange'
    },

    mounted() {
      this.onMounted();
    },

    destroyed() {
      this.onDestroyed();
    },

    methods: {
      async onMounted() {
        const { kdsSwitchTabs } = this.$_selectedMerchant.features;
        const navigationEvent = kdsSwitchTabs ? order => this.focusOrder(order, 'orderUpdatedFocus') : this.focusOrderNoNav;

        events.$on('kds:order-updated', navigationEvent);
        events.$on('kds:clear-repeat-notification', this.clearOrderForRepeatNotification);

        await Promise.all([this.fetchFulfillmentTypes(), this.fetchOrderDashboardColumns()]);

        this.calculateCurrentTime();
        this.currentTimeInterval = window.setInterval(this.calculateCurrentTime, 1000);
        this.pollOrderCounts();
      },

      onDestroyed() {
        events.$off('kds:order-updated');
        events.$off('kds:clear-repeat-notification');
        window.clearInterval(this.currentTimeInterval);
        window.clearTimeout(this.timeoutId);

        if (this.disconnectSnackbar) {
          this.resetDisconnectSnackbar();
        }
      },

      handleSelectedOrderIdChange(newOrderId) {
        if (newOrderId) {
          this.clearOrderForRepeatNotification(newOrderId);
        }
      },

      setOrdersForRepeatNotification(newOrderIds = []) {
        const currentOrderIds = this.getOrderIdsForRepeatNotification();
        const updatedOrderIds = new Set([...currentOrderIds, ...newOrderIds]);
        storage.local.set('repeatKdsNotificationOrderIds', [...updatedOrderIds]);
      },

      clearOrderForRepeatNotification(orderId) {
        const updatedOrderIds = new Set(this.getOrderIdsForRepeatNotification());
        updatedOrderIds.delete(orderId);
        storage.local.set('repeatKdsNotificationOrderIds', [...updatedOrderIds]);
      },

      getOrderIdsForRepeatNotification() {
        return storage.local.get('repeatKdsNotificationOrderIds');
      },

      clearAllOrdersForRepeatNotification() {
        storage.local.set('repeatKdsNotificationOrderIds', []);
      },

      checkForRepeatNotification(currentNewOrderIds) {
        const orderIdsForRepeatNotification = new Set(this.getOrderIdsForRepeatNotification());

        if (orderIdsForRepeatNotification.size) {
          const newOrderSet = new Set(currentNewOrderIds);
          const intersection = [...orderIdsForRepeatNotification].filter(id => newOrderSet.has(id));
          if (intersection.length) {
            this.orderIdsForRepeatNotification = intersection;
            this.playNotificationAudio();

            setTimeout(() => {
              this.orderIdsForRepeatNotification = [];
            }, 1000);
          }
        }
      },

      pollOrderCounts() {
        this.timeoutId = setTimeout(async () => {
          try {
            await this.fetchOrderCounts(undefined, undefined, 'setTimeoutPolling');
          }
          finally {
            this.pollOrderCounts();
          }
        }, 60000);
      },

      async handleQrCodeScanned(receiptToken) {
        if (!this.selectedOrderId) {
          this.getAndOpenScannedOrder(receiptToken);
        }
        else {
          this.$buefy.snackbar.open({
            actionText: 'View',
            message: '<p class="has-text-weight-bold">New Order Scanned</p>',
            duration: 8000,
            queue: false,
            canClose: true,
            position: 'is-bottom',
            type: 'is-primary',
            onAction: async () => {
              this.getAndOpenScannedOrder(receiptToken);
            }
          });
        }
      },

      async getAndOpenScannedOrder(receiptToken) {
        try {
          this.setSelectedOrderId(null);
          const toast = this.$buefy.toast.open({
            queue: false,
            message: '<i class="spin fa fa-spinner-third mar-r-sm"></i> Loading Order',
            type: 'is-success',
            indefinite: true
          });
          const order = await Order.fetchOrder(receiptToken);
          toast.close();

          await this.focusOrder(order, 'orderScannedFocus');
          this.setSelectedOrderId(order.orderId);
        }
        catch (error) {
          throw error;
        }
      },

      async onMerchantLoad({ id }) {
        try {
          await Store.fetchAll(null, { forceFetch: true });
          const defaultStoreId = storage.cookie.get(`od_mid_${id}_sid`) || this.allStores[0]?.storeId;

          if (defaultStoreId) {
            this.selectedStoreId = defaultStoreId;
          }
          else {
            this.noStoresExist = true;
          }
        }

        catch (error) {
          logger.error(error);
        }
      },

      refreshOrders(fingerprint) {
        this.fetchOrderCounts(undefined, undefined, fingerprint);
        this.fetchOrders();
      },

      setSelectedOrderId(orderId) {
        Order.setSelectedOrderId(orderId);
      },

      async focusOrder(order, fingerprint) {
        if (order) {
          const { orderId, preparationStatus } = order;

          // get new page counts with their orderIds
          // to calculate what page the order will be on
          await this.fetchOrderCounts(this.selectedStoreId, false, fingerprint);
          const position = this.orderCounts[preparationStatus].orderIds.indexOf(orderId) + 1;
          const page = position > 0 ? Math.ceil(position / this.perPage) : 1;

          // navigate
          try {
            await this.$router.replace({
              params: { tabName: preparationStatus },
              query: { page }
            });
          }
          catch {
            return null;
          }
          // fetch the orders for the new tab & page
          await this.fetchOrders({ preparationStatus, page });

          // scroll order into view
          this.$nextTick(() => {
            const orderElement = this.$el.querySelector(`#kds-order-${orderId}`);

            if (orderElement) {
              orderElement.scrollIntoView({
                behavior: 'smooth',
                block: 'center'
              });
            }
          });
        }
      },

      async focusOrderNoNav() {
        await this.fetchOrderCounts(this.selectedStoreId, false, 'focusOrderWithoutNavigation');
      },

      async handleStoreChange(newStoreId) {
        storage.cookie.set(`od_mid_${this.$_selectedMerchantId}_sid`, newStoreId);
        this.tabName = 'New';
        this.clearAllOrdersForRepeatNotification();
        if (!this.selectedStore.storeMappingPrinters) {
          await this.fetchStoreData(newStoreId);
        }
      },

      async fetchStoreData(storeId) {
        try {
          await Store.fetchById(storeId);
        }
        catch (error) {
          this.$_onRequestError({
            error,
            toastOptions: { message: 'There was an issue fetching the store data.' }
          });
        }
      },

      handleCriteriaChange() {
        if (this.searchTerm) this.fetchOrders();
      },

      handlePlatformTypeChange() {
        this.fetchOrders();
      },

      handleFulfillmentTypeChange() {
        this.fetchOrders();
      },

      formattedFulfillmentType(name) {
        return FulfillmentType.formattedName(name);
      },

      async checkForNewOrders(latest, previous) {
        const allPreviousOrderIds = Object.values(previous).flatMap(obj => obj.orderIds);
        const { added: completelyNewIds } = getChangedResources({ newArray: latest.New.orderIds, oldArray: allPreviousOrderIds });

        if (!this.$_selectedMerchant.merchantOptionsCheckout.showOrderQrCode) {
          if (completelyNewIds.length) {
            this.notifyOfNewOrders(completelyNewIds);
            this.setOrdersForRepeatNotification(completelyNewIds);
          }

          if (this.repeatKdsNotifications) {
            this.checkForRepeatNotification([...new Set([...latest.New.orderIds, ...allPreviousOrderIds])]);
          }
        }

        const { added } = getChangedResources({ newArray: latest[this.tabName].orderIds, oldArray: previous[this.tabName].orderIds });
        if (added.length) await this.fetchOrders();
      },

      async notifyOfNewOrders(orderIds) {
        const order = await Order.fetchOrder(orderIds[0]);

        const options = {
          actionText: 'View First',
          message: `<p class="has-text-weight-bold">${orderIds.length} New Orders</p>`,
          duration: 8000,
          queue: false,
          position: 'is-bottom',
          type: 'is-primary',
          onAction: async () => {
            await this.focusOrder(order, 'notifyOfNewOrderFocus');
            this.setSelectedOrderId(order.orderId);
          }
        };

        if (orderIds.length === 1) {
          options.actionText = 'View';
          options.message = normalizeWhitespace(`
            <div class="is-flex align-center">
              <div class="has-text-weight-bold mar-r-lg">
                <p class="is-size-5">New ${capitalCase(order.fulfillmentType)} Order</p>
                <p>${moment.tz((order.pickupDate || order.orderDate), this.selectedStore.ianaTimezoneId).format('ddd @ h:mm A z')}</p>
              </div>
              <p>${order.orderItemCount} Item${order.orderItemCount === 1 ? '' : 's'}</p>
            </div>
          `);
        }

        this.$buefy.snackbar.open(options);
        this.playNotificationAudio();
      },

      playNotificationAudio() {
        const audioElement = this.$el.querySelector('audio');
        audioElement.play();
      },

      pickupOrOrderDate(order) {
        return order.pickupDate || order.orderDate;
      },

      calculateCurrentTime() {
        if (this.selectedStore) {
          const currentDate = moment().tz(this.selectedStore.ianaTimezoneId);

          this.currentDisplayDate = currentDate.format('MMM Do');
          this.currentDisplayTimeHours = currentDate.format('h');
          this.currentDisplayTimeMinutes = currentDate.format('mm A z');
          this.currentTimestamp = currentDate.seconds(0).toISOString();
        }
      },

      async fetchOrderCounts(
        storeId = this.selectedStoreId,
        trackChanges = true,
        fingerprint,
        { platformType = this.platformType, fulfillmentType = this.fulfillmentType } = {}
      ) {
        const options = {
          platformType,
          fulfillmentType
        };

        try {
          const latestCounts = await Order.fetchOrderCounts(storeId, fingerprint, options);

          if (trackChanges) {
            const previousCounts = this.$clone(this.orderCounts);
            this.checkForNewOrders(latestCounts, previousCounts);
          }

          this.orderCounts = latestCounts;
        }
        catch (error) {
          this.$_onRequestError({
            error,
            toastOptions: { message: 'There was an issue fetching the latest order counts' }
          });
        }
      },

      async fetchFulfillmentTypes() {
        try {
          await FulfillmentType.fetchFulfillmentTypes();
        }
        catch (error) {
          this.$_onRequestError({
            error,
            toastOptions: { message: 'There was an issue fetching the Fulfillment types.' }
          });
        }
      },

      async fetchOrderDashboardColumns() {
        try {
          await OrderDashboardColumn.fetchOrderDashboardColumns();
        }
        catch (error) {
          this.$_onRequestError({
            error,
            toastOptions: { message: 'There was an issue fetching the column types' }
          });
        }
      },

      debouncedFetchOrders: debounce(function () { // eslint-disable-line
        this.fetchOrders();
      }, 666),

      async fetchOrders({
        storeId = this.selectedStoreId,
        preparationStatus = this.tabName,
        page = this.page,
        perPage = this.perPage,
        searchTerm = this.searchTerm,
        platformType = this.platformType,
        fulfillmentType = this.fulfillmentType
      } = {}) {
        if (storeId) {
          try {
            this.isFetchingOrders = true;
            const options = {
              storeId,
              preparationStatus,
              page,
              perPage,
              platformType,
              fulfillmentType
            };

            if (searchTerm || platformType || fulfillmentType) delete options.preparationStatus;
            if (searchTerm) options[this.criteria] = searchTerm;

            await Order.fetchOrders(options);
            this.resetDisconnectSnackbar();
          }

          catch (error) {
            logger.error(error);

            if (!this.disconnectSnackbar) {
              this.disconnectSnackbar = this.$buefy.snackbar.open({
                message: 'Cannot connect to KDS service.',
                type: 'is-danger',
                position: 'is-bottom',
                actionText: 'Retry',
                indefinite: true,
                queue: false,
                onAction: () => {
                  this.resetDisconnectSnackbar();
                  this.fetchOrders();
                }
              });
            }
          }

          finally {
            this.isFetchingOrders = false;
          }
        }
      },

      async resetDisconnectSnackbar() {
        if (this.disconnectSnackbar) {
          await this.disconnectSnackbar.close();
        }
        this.disconnectSnackbar = null;
      }
    }
  };
</script>



<style lang="sass" scoped>
  .kds.detail-page
    background-color: $white-ter

  .container
    max-width: $desktop !important

  .container::v-deep .box
    transition: opacity 500ms
    opacity: 1

  .container::v-deep .is-transitioning .box
    opacity: 0
    transition: none

  .container::v-deep .tabs a
    font-weight: 700

  .clock-separator
    animation: pulse 1s infinite

  .search-inputs
    > *
      margin: 0

  @media (max-width: $desktop)
    ::v-deep .dropdown.is-mobile-modal
      > .dropdown-menu
        min-width: 500px !important
        overflow-y: unset

  @keyframes pulse
    0%
      opacity: 0.33
    25%
      opacity: 1
    100%
      opacity: 0.33
</style>
