export default [
  {
    url: 'https://cardfree-university.readme.io',
    isRootLink: true,
    display: 'Documentation'
  },
  {
    url: 'https://cardfree-university.readme.io/docs/how-to-create-a-new-user',
    type: 'USERS',
    display: 'Users',
    match: ['userList', 'userConfiguration']
  },
  {
    url: 'https://cardfree-university.readme.io/docs/move-orders-and-set-guest-notifications',
    type: 'KDS',
    display: 'Order Dashboard',
    match: ['kds']
  },
  {
    url: 'https://cardfree-university.readme.io/docs/how-to-add-a-new-location',
    type: 'LOCATION_MANAGEMENT',
    display: 'Location Management',
    match: ['storeList', 'storeConfiguration']
  },
  {
    type: 'MASTER_MENU',
    display: 'Menu Management',
    match: ['menuManagement', 'locaftionMenu', 'regionalMenu', 'posMenu'],
    children: [
      {
        url: 'https://cardfree-university.readme.io/docs/master-menus-1',
        type: 'MASTER_MENU',
        display: 'Master Menu',
        match: ['menuManagement']
      },
      {
        url: 'https://cardfree-university.readme.io/docs/pos-menu-1',
        type: 'POS_MENU',
        display: 'POS Menu',
        match: ['posMenu']
      },
      {
        url: 'https://cardfree-university.readme.io/docs/location-menus-1',
        type: 'LOCATION_MENU',
        display: 'Location Menu',
        match: ['locationMenu']
      }
    ]
  },
  {
    url: 'https://cardfree-university.readme.io/docs/how-to-configure-tipping',
    type: 'CONTENT_MANAGEMENT',
    display: 'Content Management',
    match: ['contentManagement']
  },
  {
    url: 'https://cardfree-university.readme.io/docs/how-to-change-colors-logos',
    type: 'WEB_APP_CONFIGURATION',
    display: 'Web App',
    match: ['webAppConfiguration']
  },
  {
    url: 'https://cardfree-university.readme.io/docs/how-to-view-order-details-and-print',
    type: 'ORDERS',
    display: 'Order Management',
    match: ['orderManagement']
  }
];
