<template>
  <validated-form
    ref="form"
    v-slot="{}"
    form-id="deliveryServiceForm"
    @valid-submit="updateDeliveryService"
  >
    <sticky-save-container
      :saving="submittingDeliveryService"
      :disabled="!merchantDeliveryServices.length"
      title="Delivery Services"
    >
      <section class="config-container pad-t-sm">
        <div class="is-flex config-container--settings">
          <h3 v-if="!merchantDeliveryServices.length" class="is-size-5 has-text-weight-bold mar-r-lg">No Delivery Services</h3>
          <h3 v-else-if="merchantDeliveryServices.length === 1" class="is-size-5 has-text-weight-bold">{{ getDeliveryServiceName(selected.deliveryServiceId) }}</h3>
          <validated-input
            v-else
            name="merchantDeliveryServicesSelect"
            label="Type"
            label-position="on-border"
          >
            <b-select
              v-model="selected.deliveryServiceId"
              class="mar-r pad-b"
              expanded
              placeholder="Select a Delivery Service..."
            >
              <option
                v-for="service in merchantDeliveryServices"
                :key="service.deliveryServiceId"
                :value="service.deliveryServiceId"
              >
                {{ getDeliveryServiceName(service.deliveryServiceId) }}
              </option>
            </b-select>
          </validated-input>

          <validated-text-input
            v-model="selected.apiConfiguration"
            class="configuration mar-r"
            :has-icon="false"
            label="Configuration"
            label-position="on-border"
            name="deliveryServiceConfig"
            type="textarea"
            rows="10"
            :spellcheck="false"
            maxlength="8000"
            rules="required|validJSON"
            :disabled="!merchantDeliveryServices.length"
          />
          <b-button
            inverted
            class="mar-r-lg mar-b"
            type="is-danger"
            size="is-medium"
            icon-right="trash"
            :disabled="!merchantDeliveryServices.length"
            @click="openRemoveDeliveryServiceModal"
          />
        </div>
        <b-button
          inverted
          size="is-medium"
          class="is-transparent"
          type="is-primary"
          icon-left="plus"
          :loading="submittingDeliveryService"
          :disabled="!canAddDeliveryServices"
          @click="openAddDeliveryServiceModal"
        >
          New Delivery Service
        </b-button>
      </section>
    </sticky-save-container>
  </validated-form>
</template>

<script>
  import MerchantDeliveryService from '@/store/classes/MerchantDeliveryService';
  import beautify from 'json-beautify';
  import addDeliveryServiceModal from './add-delivery-service-modal.vue';
  import DeliveryService from '@/store/classes/DeliveryService';

  export default {
    name: 'MerchantDeliveryServices',

    props: {
      merchantId: {
        type: Number,
        default: null
      }
    },

    data() {
      return {
        selected: {
          deliveryServiceId: null,
          apiConfiguration: null,
          platformTypeId: null
        }
      };
    },

    computed: {
      submittingDeliveryService() {
        return MerchantDeliveryService.$state().submitting;
      },

      allDeliveryServiceOptions() {
        return DeliveryService.all();
      },

      canAddDeliveryServices() {
        return this.allDeliveryServiceOptions.length !== this.merchantDeliveryServices.length;
      },

      selectedDeliveryService() {
        return this.merchantDeliveryServices.find(mds => mds.deliveryServiceId === this.selected.deliveryServiceId) || this.merchantDeliveryServices[0];
      },

      merchantDeliveryServices() {
        return MerchantDeliveryService
          .query()
          .where('merchantId', this.merchantId)
          .where('platformTypeId', 1)
          .get();
      }
    },

    watch: {
      'selected.deliveryServiceId': {
        handler: 'updateDeliveryServiceConfig'
      }
    },

    async created() {
      await this.onCreated();
    },


    methods: {
      async onCreated() {
        await Promise.all([this.fetchDeliveryServices(), this.fetchMerchantDeliveryServices()]);
        this.updateDeliveryServiceConfig();
      },

      async fetchMerchantDeliveryServices() {
        try {
          await MerchantDeliveryService.fetchMerchantDeliveryServices(this.merchantId);
        }
        catch (error) {
          this.$_onRequestError({
            toastOptions: {
              message: 'There was an error fetching the merchant delivery services'
            },
            error
          });
        }
      },

      async fetchDeliveryServices() {
        try {
          await DeliveryService.fetchDeliveryServices();
        }
        catch (error) {
          this.$_onRequestError({
            toastOptions: {
              message: 'There was an error fetching the delivery service options'
            },
            error
          });
        }
      },

      getDeliveryServiceName(deliveryServiceId) {
        return this.allDeliveryServiceOptions.find(option => option.id === deliveryServiceId)?.name;
      },

      setDeliveryServiceIdToFirst() {
        this.selected.deliveryServiceId = this.merchantDeliveryServices[0]?.deliveryServiceId;
      },

      setSelectedMerchantDeliveryService(deliveryServiceId) {
        this.selected.deliveryServiceId = deliveryServiceId;
      },

      updateDeliveryServiceConfig() {
        if (this.selectedDeliveryService) {
          const { apiConfiguration, deliveryServiceId, platformTypeId } = this.selectedDeliveryService;
          const beautifiedConfig = beautify(JSON.parse(apiConfiguration), null, 2, 0);
          this.selected.apiConfiguration = beautifiedConfig;
          this.selected.deliveryServiceId = deliveryServiceId;
          this.selected.platformTypeId = platformTypeId;
        }
        else {
          this.selected.apiConfiguration = null;
          this.selected.deliveryServiceId = null;
          this.selected.platformTypeId = null;
        }
      },

      openRemoveDeliveryServiceModal() {
        const deliveryServiceName = this.getDeliveryServiceName(this.selected.deliveryServiceId);

        this.$buefy.dialog.confirm({
          title: 'Delete Delivery Service',
          message: `Are you sure you want to delete <b>${deliveryServiceName}</b> delivery service?`,
          type: 'is-danger',
          confirmText: 'Delete',
          onConfirm: this.removeDeliveryService
        });
      },

      openAddDeliveryServiceModal() {
        this.$buefy.modal.open({
          parent: this,
          component: addDeliveryServiceModal,
          hasModalCard: true,
          trapFocus: true,
          canCancel: false,
          customClass: 'auto-width',
          events: { 'delivery-service-added': this.setSelectedMerchantDeliveryService },
          props: {
            merchantDeliveryServices: this.merchantDeliveryServices,
            merchantId: this.merchantId
          }
        });
      },

      async removeDeliveryService() {
        try {
          await MerchantDeliveryService.removeMerchantDeliveryService({ merchantId: this.merchantId, merchantDeliveryService: this.selectedDeliveryService });
          this.setDeliveryServiceIdToFirst();
          this.$_onRequestSuccess({
            toastOptions: {
              message: 'Successfully removed your delivery service!'
            }
          });
        }
        catch (error) {
          this.$_onRequestError({
            error,
            toastOptions: {
              message: 'There was an error removing your delivery service'
            }
          });
        }
      },

      async updateDeliveryService() {
        const { apiConfiguration, deliveryServiceId, platformTypeId } = this.selected;
        try {
          await MerchantDeliveryService.updateMerchantDeliveryService({
            merchantId: this.merchantId,
            merchantDeliveryService: {
              deliveryServiceId,
              platformTypeId,
              apiConfiguration: beautify(JSON.parse(apiConfiguration), null, 0)
            }
          });

          this.$_onRequestSuccess({
            toastOptions: {
              message: 'Successfully updated your delivery service!'
            }
          });
        }
        catch (error) {
          this.$_onRequestError({
            error,
            toastOptions: {
              message: 'There was an error updating your delivery service'
            }
          });
        }
      }
    }
  };
</script>
