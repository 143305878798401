const paymentMethods = {
  GIFTCARD: {
    type: 'GiftCard',
    display: 'Gift Card'
  },
  CREDIT_CARD_TOKEN: {
    type: 'CreditCardToken',
    display: 'Credit Card Token'
  },
  CREDIT_CARD: {
    type: 'CreditCard',
    display: 'Credit Card'
  },
  SAVED_CREDIT_CARD: {
    type: 'SavedCreditCard',
    display: 'Saved Credit Card'
  },
  APPLE_PAY: {
    type: 'ApplePay',
    display: 'Apple Pay'
  },
  GOOGLE_PAY: {
    type: 'GooglePay',
    display: 'Google Pay'
  },
  ROOM_CHARGE: {
    type: 'RoomCharge',
    display: 'Room Charge'
  },
  NONCE: {
    type: 'Nonce',
    display: 'Nonce'
  },
  OFFER: {
    type: 'Offer',
    display: 'Offer'
  },
  MASS_PAY_ACH: {
    type: 'MassPayAch',
    display: 'Mass Pay ACH'
  },
  EXTERNAL_DEVICE: {
    type: 'ExternalDevice',
    display: 'External Device'
  }
};



export default paymentMethods;
