const getArrayOfDays = numOfDays => Array.from({ length: numOfDays }, (_, i) => i + 1);
const monthsWithDays = {
  1: {
    days: getArrayOfDays(31),
    value: 1,
    display: 'January'
  },
  2: {
    days: getArrayOfDays(29),
    value: 2,
    display: 'February'
  },
  3: {
    days: getArrayOfDays(31),
    value: 3,
    display: 'March'
  },
  4: {
    days: getArrayOfDays(30),
    value: 4,
    display: 'April'
  },
  5: {
    days: getArrayOfDays(31),
    value: 5,
    display: 'May'
  },
  6: {
    days: getArrayOfDays(30),
    value: 6,
    display: 'June'
  },
  7: {
    days: getArrayOfDays(31),
    value: 7,
    display: 'July'
  },
  8: {
    days: getArrayOfDays(31),
    value: 8,
    display: 'August'
  },
  9: {
    days: getArrayOfDays(30),
    value: 9,
    display: 'September'
  },
  10: {
    days: getArrayOfDays(31),
    value: 10,
    display: 'October'
  },
  11: {
    days: getArrayOfDays(30),
    value: 11,
    display: 'November'
  },
  12: {
    days: getArrayOfDays(31),
    value: 12,
    display: 'December'
  }
};

export {
  getArrayOfDays,
  monthsWithDays
};
