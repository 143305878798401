<template>
  <div
    class="pad has-background-white-bis has-border has-border-grey-lightest has-radius"
    :data-test-id="`${constraintModel.type}-constraint-card`"
    data-test-class="constraint-card"
  >
    <validation-observer
      v-slot="{ errors }"
      :name="constraintModel.display"
      :vid="constraintModel.type"
    >
      <label class="label" :for="constraintModel.type">
        <div class="is-flex justify-between align-center">
          <div>
            <p class="is-size-5">{{ constraintModel.display }}
              <b-icon
                v-if="Object.values(errors).flat().length"
                icon="exclamation-circle"
                size="is-small"
                type="is-danger"
              />
            </p>
            <p
              class="sub-label has-text-weight-normal has-text-grey"
            >
              {{ constraintModel.subLabel }}
            </p>
          </div>
          <b-button
            v-if="!hideRemoveButton"
            type="is-danger is-light"
            size="is-small"
            icon-right="trash"
            data-test-class="remove-constraint-button"
            @click="$emit('remove-constraint')"
          />
        </div>
      </label>

      <slot />

    </validation-observer>
  </div>
</template>

<script>
  export default {
    name: 'ConstraintCard',

    props: {
      constraintModel: {
        type: Object,
        required: true
      },

      hideRemoveButton: {
        type: Boolean,
        default: false
      }
    }
  };
</script>
