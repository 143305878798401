<template>
  <panel
    :title="`${forEmployees ? 'Employee' : 'Department'} Reports`"
    no-padding
  >
    <div class="pad-x-md has-border-bottom has-border-grey-lighter">
      <!-- Search -->
      <div class="is-flex gap-sm">
        <validated-text-input
          v-model="query"
          type="text"
          :icon-right-clickable="!!query"
          :icon-right="query ? 'times-circle': ''"
          placeholder="Search by Transaction #"
          icon="search"
          class="flex-grow mar-b"
          label-position="on-border"
          label="Search"
          name="query-input"
          expanded
          output-string
          @icon-right-click="query = ''"
          @keydown.native.esc="query = ''"
        />
        <b-button
          type="is-primary"
          icon-left="file-export"
          icon-pack="far"
          @click="handleExportResults"
        >
          Export Report
        </b-button>
      </div>
      <!-- Filters -->
      <div class="is-flex gap">
        <b-field
          v-if="stores.length > 1"
          label="Location"
          label-position="on-border"
        >
          <b-dropdown
            :value="selectedStoreId"
            :mobile-modal="false"
            aria-role="list"
            scrollable
            max-height="325px"
            class="has-extra-shadow"
            @change="changeStore"
          >
            <dropdown-button
              slot="trigger"
              :value="selectedStoreId ? selectedStore.description : 'All Locations'"
              :loading="fetching.stores"
            />
            <b-dropdown-item :class="!selectedStoreId && 'is-active'" :value="null" aria-role="listitem">
              All Locations
            </b-dropdown-item>
            <hr class="dropdown-divider">
            <b-dropdown-item
              v-for="store in stores"
              :key="store.storeId"
              :value="store.storeId"
              aria-role="listitem"
            >
              {{ store.description }}
            </b-dropdown-item>
          </b-dropdown>
        </b-field>

        <b-field
          label="Department"
          label-position="on-border"
        >
          <b-dropdown
            :value="selectedDepartmentId"
            :mobile-modal="false"
            aria-role="list"
            scrollable
            max-height="550px"
            class="has-extra-shadow"
            data-test-id="department-dropdown"
            @change="changeDepartment"
          >
            <dropdown-button
              slot="trigger"
              :value="selectedDepartment ? selectedDepartment.name : 'All Departments'"
              :loading="fetching.departments"
            />
            <b-dropdown-item :class="!selectedDepartmentId && 'is-active'" :value="null" aria-role="listitem">
              All Departments
            </b-dropdown-item>
            <hr class="dropdown-divider">

            <template v-for="storeId in Object.keys(filteredDepartmentsByStore).map(Number)">
              <b-dropdown-item v-if="!selectedStoreId" :key="storeId" custom>
                <span class="title is-6">
                  {{ getStoreName(storeId) }}
                </span>
              </b-dropdown-item>
              <b-dropdown-item
                v-for="department in filteredDepartmentsByStore[storeId]"
                :key="department.id"
                :value="department.id"
                aria-role="listitem"
              >
                <span class="pad-l-sm">
                  {{ department.name }}
                </span>
              </b-dropdown-item>
            </template>
          </b-dropdown>
        </b-field>

        <b-field
          v-if="forEmployees"
          label="Employee"
          label-position="on-border"
        >
          <b-dropdown
            :value="selectedEmployeeId"
            :mobile-modal="false"
            aria-role="list"
            scrollable
            max-height="550px"
            class="has-extra-shadow"
            data-test-id="employee-dropdown"
            @change="changeEmployee"
          >
            <dropdown-button
              slot="trigger"
              :value="selectedEmployeeId ? selectedEmployee.fullName : 'All Employees'"
              :loading="fetching.employees"
            />
            <b-dropdown-item :class="!selectedEmployeeId && 'is-active'" :value="null" aria-role="listitem">
              All Employees
            </b-dropdown-item>
            <hr class="dropdown-divider">
            <template v-for="(storeId) in Object.keys(filteredEmployeesByStoreAndDepartment).map(Number)">
              <b-dropdown-item
                v-if="!selectedStoreId"
                :key="`store-${storeId}`"
                class="pad-b-none"
                custom
              >
                <span class="title is-6">
                  {{ getStoreName(storeId) }}
                </span>
              </b-dropdown-item>
              <template v-for="departmentId in Object.keys(filteredEmployeesByStoreAndDepartment[storeId]).map(Number)">
                <b-dropdown-item
                  v-if="!selectedDepartmentId"
                  :key="`department-${departmentId}`"
                  class="pad-b-none"
                  custom
                >
                  <span class="has-text-weight-bold has-text-grey pad-l-xs">
                    {{ getDepartmentName(departmentId) }}
                  </span>
                </b-dropdown-item>
                <b-dropdown-item
                  v-for="employee in filteredEmployeesByStoreAndDepartment[storeId][departmentId]"
                  :key="`employee-${employee.id}`"
                  :value="employee.id"
                >
                  <span class="pad-l">
                    {{ employee.fullName }}
                  </span>
                </b-dropdown-item>
              </template>
            </template>
          </b-dropdown>
        </b-field>

        <b-field label-position="on-border" label="Date Range">
          <b-datepicker
            v-model="dateRange"
            class="date-picker-input"
            icon="calendar-alt"
            range
            :nearby-selectable-month-days="true"
            :class="['has-extra-shadow']"
            :mobile-native="false"
            :min-date="ninetyDaysAgo"
            :max-date="today"
            placeholder="Select Date Range"
            :focused-date="focusedDate"
          >
            <div class="is-grid col-2 gap-sm">
              <b-button
                size="is-small"
                :type="selectedDateRange === 'TODAY' ? 'is-primary' : ''"
                @click="dateRange = [today, today]"
              >
                Today
              </b-button>
              <b-button
                size="is-small"
                :type="selectedDateRange === 'PAST_SEVEN_DAYS' ? 'is-primary' : ''"
                @click="dateRange = [sevenDaysAgo, today]"
              >
                Past 7 Days
              </b-button>
              <b-button
                size="is-small"
                :type="selectedDateRange === 'PAST_THIRTY_DAYS' ? 'is-primary' : ''"
                @click="dateRange = [thirtyDaysAgo, today]"
              >
                Past 30 Days
              </b-button>
              <b-button
                size="is-small"
                :type="selectedDateRange === 'PAST_NINETY_DAYS' ? 'is-primary' : ''"
                @click="dateRange = [ninetyDaysAgo, today]"
              >
                Past 90 Days
              </b-button>
            </div>
          </b-datepicker>
        </b-field>
      </div>
    </div>

    <!-- Table View -->
    <b-table
      :data="isLoading ? [] : tips"
      :class="['is-middle-aligned no-header-wrap row-clickable']"
      hoverable
      paginated
      scrollable
      :total="total"
      :current-page="page"
      backend-pagination
      backend-sorting
      pagination-position="bottom"
      :mobile-cards="false"
      :min-date="ninetyDaysAgo"
      :default-sort="[sortField, sortDirection]"
      :per-page="perPage"
      @page-change="handlePageChange"
      @sort="handleSort"
      @cellclick="openTipReportModal"
    >
      <template #empty>
        <empty-table-loader :loading="isLoading" message="No Results" :has-icon="false" />
      </template>

      <b-table-column v-slot="{ row }" sortable label="Transaction #" field="order_id">
        {{ row.orderId }}
      </b-table-column>

      <b-table-column
        v-if="forEmployees"
        v-slot="{ row }"
        sortable
        label="Employee Name"
        field="employee_name"
      >
        {{ row.employeeName || 'N/A' }}
      </b-table-column>

      <b-table-column v-slot="{ row }" sortable label="Department" field="department_name">
        {{ row.departmentName }}
      </b-table-column>

      <b-table-column v-slot="{ row }" sortable label="Location" field="location_name">
        {{ row.locationName }}
      </b-table-column>

      <b-table-column v-slot="{ row }" sortable label="Date" field="date">
        <p>{{ row.orderDate | moment('MM/DD/YYYY', row.storeTimezone) }}</p>
        <p>{{ row.orderDate | moment('hh:mm A z', row.storeTimezone) }}</p>
      </b-table-column>

      <b-table-column v-slot="{ row }" label="Tip Amount" numeric width="1">
        {{ row.tip || 0 | dollars }}
      </b-table-column>

      <template #bottom-left>
        <div class="left-side is-flex">
          <page-number-select
            :page="page"
            :per-page="Number(perPage)"
            :total-count="total"
            @input="handlePageChange"
          />
          <b-field label-for="perPage" label-position="on-border" label="Per Page" class="mar-l mar-none">
            <b-select id="perPage" v-model="perPage">
              <option value="10">10</option>
              <option value="20">20</option>
              <option value="50">50</option>
              <option value="100">100</option>
            </b-select>
          </b-field>
        </div>
      </template>
    </b-table>
  </panel>
</template>

<script>
  import moment from 'moment-timezone';
  import debounce from 'lodash.debounce';
  import merchantMixin from '@/mixins/merchant';

  import Department from '@/store/classes/Department';
  import Employee from '@/store/classes/Employee';
  import Store from '@/store/classes/Store';

  import { fetchTipReport, fetchTipReportCsv } from '@/api/tip-report';

  import TipReportModal from './tip-report-modal.vue';

  export default {
    name: 'TipReportsTable',

    mixins: [merchantMixin],

    props: {
      forEmployees: {
        type: Boolean,
        default: null
      }
    },

    data: () => ({
      dateRange: [],
      selectedDepartmentId: null,
      selectedEmployeeId: null,
      focusedDate: null,
      isLoading: false,
      selectedStoreId: null,
      page: 1,
      perPage: 20,
      query: '',
      tips: [],
      total: 0,
      sortField: 'date',
      sortDirection: 'desc'
    }),

    computed: {
      fetching() {
        return {
          departments: Department.$state().fetching,
          employees: Employee.$state().fetching,
          stores: Store.$state().fetchingAll
        };
      },

      stores() {
        return Store.orderByName().get();
      },

      selectedStore() {
        return Store.find(this.selectedStoreId);
      },

      departments() {
        return Department.query().orderBy('name').get();
      },

      filteredDepartmentsByStore() {
        return this.departments.reduce((obj, department) => {
          const { storeId } = department;

          if (obj[storeId]) obj[storeId].push(department);
          else if (!this.selectedStoreId || this.selectedStoreId === storeId) obj[storeId] = [department];

          return obj;
        }, {});
      },

      selectedDepartment() {
        return Department.find(this.selectedDepartmentId);
      },

      employees() {
        return Employee.query().orderBy('fullName').withAllRecursive().get();
      },

      filteredEmployeesByStoreAndDepartment() {
        return this.employees.reduce((obj, employee) => {
          const { department } = employee;
          const noSelectedStore = !this.selectedStoreId;
          const belongsToSelectedStore = department?.storeId === this.selectedStoreId;
          const noSelectedDepartment = !this.selectedDepartmentId;
          const belongsToSelectedDepartment = employee?.departmentId === this.selectedDepartmentId;

          if (obj[department.storeId]) {
            if (obj[department.storeId][employee.departmentId]) {
              obj[department.storeId][employee.departmentId].push(employee);
            }
            else if (noSelectedDepartment || belongsToSelectedDepartment) {
              obj[department.storeId][employee.departmentId] = [employee];
            }
          }
          else if ((noSelectedStore || belongsToSelectedStore) && (noSelectedDepartment || belongsToSelectedDepartment)) {
            obj[department.storeId] = { [employee.departmentId]: [employee] };
          }

          return obj;
        }, {});
      },

      selectedEmployee() {
        return Employee.query().withAllRecursive().find(this.selectedEmployeeId);
      },

      ninetyDaysAgo() {
        return moment.utc(moment().subtract(90, 'd').startOf('day')).toDate();
      },

      thirtyDaysAgo() {
        return moment.utc(moment().subtract(30, 'd').startOf('day')).toDate();
      },

      sevenDaysAgo() {
        return moment.utc(moment().subtract(7, 'd').startOf('day')).toDate();
      },

      today() {
        return moment.utc(moment().startOf('day')).toDate();
      },

      selectedDateRange() {
        switch (true) {
          case this.dateRange[0].toString() === this.ninetyDaysAgo.toString() && this.dateRange[1].toString() === this.today.toString():
            return 'PAST_NINETY_DAYS';
          case this.dateRange[0].toString() === this.thirtyDaysAgo.toString() && this.dateRange[1].toString() === this.today.toString():
            return 'PAST_THIRTY_DAYS';
          case this.dateRange[0].toString() === this.sevenDaysAgo.toString() && this.dateRange[1].toString() === this.today.toString():
            return 'PAST_SEVEN_DAYS';
          case this.dateRange[0].toString() === this.today.toString() && this.dateRange[1].toString() === this.today.toString():
            return 'TODAY';
          default:
            return null;
        }
      }
    },

    watch: {
      dateRange: 'handleDateRangeChange',
      query: debounce(function () { // eslint-disable-line func-names
        this.fetchTips();
      }, 666)
    },

    async created() {
      this.dateRange = [this.ninetyDaysAgo, this.today];
      this.fetchTips();
    },

    methods: {
      getDepartmentName(departmentId) {
        return Department.find(departmentId)?.name;
      },

      getStoreName(storeId) {
        return Store.find(storeId)?.description;
      },

      /* causes the change */
      changeStore(storeId) {
        this.selectedStoreId = storeId;

        if (this.selectedDepartment && this.selectedDepartment.storeId !== storeId) {
          this.selectedDepartmentId = null;
        }
        if (this.selectedEmployee && this.selectedEmployee.department.storeId !== storeId) {
          this.selectedEmployeeId = null;
        }

        this.page = 1;
        this.fetchTips();
      },

      changeDepartment(departmentId) {
        this.selectedDepartmentId = departmentId;

        if (this.selectedEmployee && this.selectedEmployee.departmentId !== departmentId) {
          this.selectedEmployeeId = null;
        }

        this.page = 1;
        this.fetchTips();
      },

      changeEmployee(employeeId) {
        this.selectedEmployeeId = employeeId;
        this.page = 1;
        this.fetchTips();
      },

      /* in response to the change */
      handleDateRangeChange(dateRange, oldDateRange) {
        this.focusedDate = null;
        this.$nextTick(() => {
          this.focusedDate = dateRange[1];
        });
        this.page = 1;

        /**
         * On created, the dateRange value changes from `[]` to the default "last 90 days" value.
         * Only re-fetch tips if the dateRange changes from one value to another, not from the empty value.
         */
        if (oldDateRange.length) {
          this.fetchTips();
        }
      },

      handlePageChange(page) {
        this.page = page;
        this.fetchTips();
      },

      async fetchTips() {
        try {
          this.isLoading = true;
          const { departmentEmployeeTips, meta } = await fetchTipReport(this.$_selectedMerchantId, {
            page: this.page,
            perPage: this.perPage,
            storeId: this.selectedStoreId,
            departmentId: this.selectedDepartmentId,
            employeeId: this.selectedEmployeeId,
            forEmployees: this.forEmployees,
            endDate: this.dateRange?.length ? moment(this.dateRange[1]).format('YYYY-MM-DD') : null,
            startDate: this.dateRange?.length ? moment(this.dateRange[0]).format('YYYY-MM-DD') : null,
            orderId: this.query || null,
            sortField: this.sortField,
            sortDirection: this.sortDirection
          });

          this.total = meta.count;
          this.tips = departmentEmployeeTips;
        }
        catch (error) {
          this.$_onRequestError({
            toastOptions: {
              message: 'Unable to fetch tip report'
            },
            error
          });
        }
        finally {
          this.isLoading = false;
        }
      },

      async handleExportResults() {
        try {
          const csv = await fetchTipReportCsv(this.$_selectedMerchantId, {
            page: this.page,
            perPage: this.perPage,
            storeId: this.selectedStoreId,
            departmentId: this.selectedDepartmentId,
            employeeId: this.selectedEmployeeId,
            forEmployees: this.forEmployees,
            endDate: this.dateRange?.length ? moment(this.dateRange[1]).format('YYYY-MM-DD') : null,
            startDate: this.dateRange?.length ? moment(this.dateRange[0]).format('YYYY-MM-DD') : null,
            orderId: this.query || null,
            sortField: this.sortField,
            sortDirection: this.sortDirection
          });
          this.createAndDownloadCSV(csv);
        }

        catch (error) {
          this.$_onRequestError({
            error,
            toastOptions: { message: 'There was an error exporting your tips' }
          });
        }
      },

      createAndDownloadCSV(csv) {
        const downloadLink = document.createElement('a');
        const blob = new Blob(['\ufeff', csv]);
        const url = URL.createObjectURL(blob);
        const currentDate = moment(Date.now()).format('YYYY-MM-DD');
        const downloadName = `${this.$_selectedMerchant.friendlyUriName}-${this.forEmployees ? 'employee' : 'department'}-tip-report-${currentDate}.csv`;
        downloadLink.href = url;
        downloadLink.download = downloadName;
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);

        return downloadLink;
      },

      handleSort(sortField, sortDirection) {
        this.sortField = sortField;
        this.sortDirection = sortDirection;
        this.page = 1;
        this.fetchTips();
      },


      openTipReportModal(tipReport) {
        this.$buefy.modal.open({
          parent: this,
          component: TipReportModal,
          hasModalCard: true,
          trapFocus: true,
          canCancel: false,
          customClass: 'auto-width',
          props: { tipReport }
        });
      }
    }
  };
</script>
