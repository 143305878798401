var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.$_selectedMerchant)?_c('div',[_c('div',{staticClass:"is-grid col-2 col-1-tablet gap-xl"},[_c('fieldset',[_c('h4',{staticClass:"title is-4"},[_vm._v("Header")]),_c('div',{staticClass:"header-preview is-flex align-center justify-center pad mar-b-lg is-size-3 has-text-weight-bold",style:({
          backgroundColor: _vm.value.headerBackgroundColor,
          color: _vm.value.headerDarkBackground ? 'white' : 'black'
        })},[_vm._v(" Preview ")]),_c('image-upload',{attrs:{"accepted-types":['png', 'svg'],"image-size-warning-height":104,"image-size-warning-width":452,"image-url":_vm.$_selectedMerchant.merchantOption && _vm.$_selectedMerchant.merchantOption.logoUrl,"label":"Merchant Logo","value":_vm.value.logoImageFile,"show-clear-button":"","restrict-file-size":""},on:{"input":function($event){return _vm.handleInput('logoImageFile', $event)}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var imagePath = ref.imagePath;
return [_c('div',{style:({backgroundColor: _vm.value.headerBackgroundColor, padding: '0.5rem'})},[(imagePath)?_c('img',{staticStyle:{"max-width":"400px"},attrs:{"src":imagePath,"alt":"merchant logo"}}):_vm._e()])]}}],null,false,2621030275)}),_c('div',{staticClass:"is-flex dist-x-xl"},[_c('validation-provider',{attrs:{"slim":"","name":"headerBackgroundColor","rules":"required|validHexColor"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var errors = ref.errors;
        var classes = ref.classes;
return [_c('b-field',{class:['mar-none', classes],attrs:{"label-for":"headerBackgroundColor","message":errors,"type":{ 'is-danger': !!errors.length },"label":"Background Color"}},[_c('div',{staticClass:"color-picker"},[_c('swatches',{attrs:{"value":_vm.value.headerBackgroundColor,"show-fallback":"","fallback-input-type":"color","swatches":"text-advanced"},on:{"input":function($event){return _vm.handleInput('headerBackgroundColor', $event)}},scopedSlots:_vm._u([{key:"trigger",fn:function(){return [_c('div',{staticClass:"swatch",style:({ backgroundColor: _vm.value.headerBackgroundColor })})]},proxy:true}],null,true)}),_c('b-input',{staticClass:"hex-input",attrs:{"id":"headerBackgroundColor","value":_vm.value.headerBackgroundColor,"maxlength":"7","has-counter":false,"type":"text"},on:{"input":function($event){return _vm.handleInput('headerBackgroundColor', $event)}}})],1)])]}}],null,false,3387200961)}),_c('validation-provider',{attrs:{"slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var classes = ref.classes;
return [_c('b-field',{class:classes,attrs:{"label-for":"headerDarkBackground","label":"Text Color"}},[_c('toggle-buttons',{attrs:{"value":_vm.value.headerDarkBackground,"label-right":"White","value-right":true,"label-left":"Black","value-left":false},on:{"input":function($event){return _vm.handleInput('headerDarkBackground', $event)}}})],1)]}}],null,false,73746631)})],1)],1),_c('fieldset',[_c('h4',{staticClass:"title is-4"},[_vm._v("App Colors")]),_c('b-field',[_c('b-message',{staticClass:"is-inline-block has-shadow is-compact",attrs:{"size":"is-small","type":"is-primary"}},[_vm._v(" Applies to various text, buttons, icons, and other UI elements ")])],1),_c('validation-provider',{attrs:{"slim":"","name":"primaryColor","rules":"notWhite|validHexColor|required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var errors = ref.errors;
        var classes = ref.classes;
return [_c('b-field',{class:classes,attrs:{"label":"Primary Color","label-for":"primaryColor","message":!!errors.length ? errors : _vm.getContrastWarning(_vm.value.primaryColor),"type":{ 'is-danger': !!errors.length }}},[_c('div',{staticClass:"color-picker"},[_c('swatches',{attrs:{"value":_vm.value.primaryColor,"show-fallback":"","fallback-input-type":"color","swatches":"text-advanced"},on:{"input":function($event){return _vm.handleInput('primaryColor', $event)}},scopedSlots:_vm._u([{key:"trigger",fn:function(){return [_c('div',{staticClass:"swatch",style:({ backgroundColor: _vm.value.primaryColor })})]},proxy:true}],null,true)}),_c('b-input',{staticClass:"hex-input",attrs:{"id":"primaryColor","value":_vm.value.primaryColor,"maxlength":"7","has-counter":false,"type":"text"},on:{"input":function($event){return _vm.handleInput('primaryColor', $event)}}})],1)])]}}],null,false,3063986426)}),_c('validation-provider',{attrs:{"slim":"","name":"secondaryColor","rules":"notWhite|validHexColor|required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var errors = ref.errors;
        var classes = ref.classes;
return [_c('b-field',{class:classes,attrs:{"label":"Secondary Color","label-for":"secondaryColor","message":!!errors.length ? errors : _vm.getContrastWarning(_vm.value.secondaryColor),"type":{ 'is-danger': !!errors.length }}},[_c('div',{staticClass:"color-picker"},[_c('swatches',{attrs:{"value":_vm.value.secondaryColor,"show-fallback":"","fallback-input-type":"color","swatches":"text-advanced"},on:{"input":function($event){return _vm.handleInput('secondaryColor', $event)}},scopedSlots:_vm._u([{key:"trigger",fn:function(){return [_c('div',{staticClass:"swatch",style:({ backgroundColor: _vm.value.secondaryColor })})]},proxy:true}],null,true)}),_c('b-input',{staticClass:"hex-input",attrs:{"id":"secondaryColor","value":_vm.value.secondaryColor,"maxlength":"7","has-counter":false,"type":"text"},on:{"input":function($event){return _vm.handleInput('secondaryColor', $event)}}})],1)])]}}],null,false,3307757068)}),(_vm.$_selectedMerchant.kioskEnabled)?_c('validation-provider',{attrs:{"slim":"","name":"tertiaryColor","rules":"notWhite|validHexColor|required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var errors = ref.errors;
        var classes = ref.classes;
return [_c('b-field',{class:classes,attrs:{"label":"Tertiary Color","label-for":"tertiaryColor","message":!!errors.length ? errors : _vm.getContrastWarning(_vm.value.tertiaryColor),"type":{ 'is-danger': !!errors.length }}},[_c('div',{staticClass:"color-picker"},[_c('swatches',{attrs:{"value":_vm.value.tertiaryColor,"show-fallback":"","fallback-input-type":"color","swatches":"text-advanced"},on:{"input":function($event){return _vm.handleInput('tertiaryColor', $event)}},scopedSlots:_vm._u([{key:"trigger",fn:function(){return [_c('div',{staticClass:"swatch",style:({ backgroundColor: _vm.value.tertiaryColor })})]},proxy:true}],null,true)}),_c('b-input',{staticClass:"hex-input",attrs:{"id":"tertiaryColor","value":_vm.value.tertiaryColor,"maxlength":"7","has-counter":false,"type":"text"},on:{"input":function($event){return _vm.handleInput('tertiaryColor', $event)}}})],1)])]}}],null,false,1808783810)}):_vm._e()],1)])]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }