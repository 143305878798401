<template>
  <validated-form
    ref="form"
    v-slot="{ errors, dirty }"
    auto-focus
    form-id="merchantForm"
    :track-changes="showSaveButton"
    @valid-submit="handleSubmit"
    @invalid-submit="$emit('invalid-submit')"
  >
    <slot v-bind="{ errors, mode }" />

    <template v-if="showSaveButton && ['create', 'update'].some(action => $can(action, 'Merchant'))">
      <div class="footer-buttons">
        <b-button
          rounded
          size="is-medium"
          native-type="submit"
          type="is-primary"
          :loading="isSubmitting"
          class="is-bold"
          :class="mode === 'update' && dirty && 'pulse-slow'"
        >
          Save
        </b-button>
      </div>
    </template>
  </validated-form>
</template>



<script>
  import Merchant from '@/store/classes/Merchant';
  import MerchantOption from '@/store/classes/MerchantOption';
  import MerchantPaymentGateway from '@/store/classes/MerchantPaymentGateway';
  import MerchantAppSettingResource from '@/store/classes/MerchantAppSettingResource';
  import getChangedResources from '@/helpers/get-changed-resources';



  export default {
    name: 'MerchantForm',



    props: {
      merchant: {
        type: Object,
        default() {
          return new Merchant();
        }
      },

      mode: {
        type: String,
        default: 'create',
        validator(value) {
          return ['create', 'read', 'update'].includes(value);
        }
      },

      showSaveButton: {
        type: Boolean,
        default: true
      }
    },

    computed: {
      editableMerchant() {
        return Merchant.$state().editableMerchant;
      },

      isSubmitting() {
        return Merchant.$state().submitting
          || MerchantOption.$state().submitting
          || MerchantPaymentGateway.$state().submitting
          || MerchantAppSettingResource.$state().submitting;
      },

      changedAppSettings() {
        const resources = this.editableMerchant?.merchantAppSettingResources || [];
        const oldResources = this.merchant.merchantAppSettingResources || [];

        return resources.filter((newSetting) => {
          const oldSetting = oldResources.find(s => s.id === newSetting.id);
          return !oldSetting || oldSetting.value !== newSetting.value;
        });
      },

      changedFeatures() {
        if (!this.editableMerchant?.features) return {};

        const changes = {};
        ['showKds', 'showOpd'].forEach((key) => {
          if (this.editableMerchant.features[key] !== this.merchant.features[key]) {
            changes[key] = this.editableMerchant.features[key];
          }
        });
        return changes;
      }
    },

    created() {
      this.onCreated();
    },

    methods: {
      onCreated() {
        Merchant.setEditableMerchant(JSON.parse(JSON.stringify(this.merchant)));
      },

      async handleSubmit() {
        switch (this.mode) {
          case 'create':
            await this.createMerchant();
            break;

          case 'update':
            await this.updateMerchant();
            break;

          default:
            break;
        }
      },

      configureProviderPayloads() {
        const updatedGateways = JSON.parse(JSON.stringify(this.editableMerchant.paymentGatewayIds)).map(paymentGatewayId => ({
          id: paymentGatewayId,
          apiConfiguration: {}
        }));
        const updatedPOSConfigurations = JSON.parse(JSON.stringify(this.editableMerchant.posProviderIds)).map(posProviderId => ({
          posTypeId: posProviderId,
          url: '',
          metadata: {}
        }));

        return {
          paymentGateways: updatedGateways,
          posConfigurations: updatedPOSConfigurations
        };
      },

      async createMerchant() {
        try {
          const { paymentGateways, posConfigurations } = this.configureProviderPayloads();
          const newMerchant = await Merchant.addMerchant({ ...this.editableMerchant, paymentGateways, posConfigurations });

          const { merchantAppSettingResources } = this.editableMerchant;
          if (merchantAppSettingResources.length) {
            await MerchantAppSettingResource.createOrUpdateAppSettingResources({
              merchantId: newMerchant.id,
              merchantAppSettingResources
            });
          }

          this.$emit('merchant-added');
          this.$_onRequestSuccess({
            confetti: true,
            toastOptions: {
              message: `<b>${this.editableMerchant.name}</b> has been successfully added!`
            }
          });

          Merchant.setSelectedMerchantId(newMerchant.id);
          this.$router.push({ name: 'home' });
        }
        catch (error) {
          this.$_onRequestError({
            error,
            toastOptions: {
              message: 'There was an error adding the new Merchant'
            },
            options: { backendError: error.data.errors?.merchant }
          });
        }
      },

      async updateMerchant() {
        try {
          const promises = [Merchant.updateMerchant(this.editableMerchant)];

          // Avatar
          if (this.editableMerchant.avatarImageFile) {
            promises.push(MerchantOption.updateMerchantAvatar({
              id: this.editableMerchant.merchantOption.id,
              avatarImageFile: this.editableMerchant.avatarImageFile
            }));
          }

          // Payment methods
          const paymentMethodRequests = this.editableMerchant.merchantPaymentGateways
            .map((gateway) => {
              const paymentMethodIds = this.editableMerchant.paymentMethodsByGateway[gateway.id];
              if (!paymentMethodIds) return null;

              const gatewayIds = gateway.paymentMethods.map(pm => pm.id);
              const changedResources = getChangedResources({
                newArray: paymentMethodIds,
                oldArray: gatewayIds
              });

              return changedResources.added.length || changedResources.removed.length
                ? MerchantPaymentGateway.updatePaymentGateway(gateway, paymentMethodIds)
                : null;
            })
            .filter(Boolean);

          promises.push(...paymentMethodRequests);

          // Features
          if (Object.keys(this.changedFeatures).length) {
            promises.push(Merchant.updateFeatures({ ...this.changedFeatures, id: this.editableMerchant.features.id }));
          }

          // App settings
          if (this.changedAppSettings.length) {
            promises.push(MerchantAppSettingResource.createOrUpdateAppSettingResources({
              merchantId: this.editableMerchant.id,
              merchantAppSettingResources: this.changedAppSettings
            }));
          }

          await Promise.all(promises);

          Merchant.setEditableMerchant({ avatarImageFile: null });

          this.$_onRequestSuccess({
            toastOptions: {
              message: `<b>${this.editableMerchant.name}</b> has been successfully updated!`
            },
            options: { resetForm: true }
          });
        }
        catch (error) {
          this.$_onRequestError({
            error,
            toastOptions: {
              message: `There was an error updating <b>${this.editableMerchant.name}</b>`
            },
            options: { backendError: error.data?.errors?.merchant }
          });
        }
      }
    }
  };
</script>
