<template>
  <div v-if="_merchant && tabs.length">
    <b-loading :is-full-page="false" :active="!_merchant" />
    <secondary-sidebar
      v-slot="{ activeTab }"
      :tabs="tabs"
    >
      <component
        :is="getActiveTabComponent(activeTab)"
        :merchant="_merchant"
        :merchant-id="_merchant.id"
      />
    </secondary-sidebar>
  </div>
</template>

<script>
  // mixins
  import merchantMixin from '@/mixins/merchant';

  // tab components
  import merchantAdditionalSettings from './merchant-configs-tab-content/merchant-additional-settings.vue';
  import merchantAppSettingConfigs from './merchant-configs-tab-content/merchant-app-setting-configs.vue';
  import merchantAppSettingResources from './merchant-configs-tab-content/merchant-app-setting-resources.vue';
  import merchantDefaultNotificationPreferences from './merchant-configs-tab-content/merchant-default-notification-preferences.vue';
  import merchantDeliveryServices from './merchant-configs-tab-content/merchant-delivery-services.vue';
  import merchantLoyaltyProviders from './merchant-configs-tab-content/merchant-loyalty-providers.vue';
  import merchantPushProvider from './merchant-configs-tab-content/merchant-push-provider.vue';
  import merchantFeatures from './merchant-configs-tab-content/merchant-features.vue';
  import merchantMenuTypes from './merchant-configs-tab-content/merchant-menu-types.vue';
  import merchantOptions from './merchant-configs-tab-content/merchant-options.vue';
  import merchantOrderModes from './merchant-configs-tab-content/merchant-order-modes.vue';
  import merchantPaymentConfigs from './merchant-configs-tab-content/merchant-payment-configs.vue';
  import merchantPosConfigs from './merchant-configs-tab-content/merchant-pos-configs.vue';
  import merchantUserAccounts from './merchant-configs-tab-content/merchant-user-accounts.vue';
  import merchantLocationServices from './merchant-configs-tab-content/merchant-location-services.vue';
  import merchantConnectedAccountTypes from './merchant-configs-tab-content/merchant-connected-account-types.vue';


  export default {
    name: 'MerchantConfigsTab',

    mixins: [merchantMixin],

    props: {
      merchant: {
        type: Object,
        required: true
      }
    },

    computed: {
      _merchant() {
        return this.merchant || this.$_selectedMerchant;
      },

      activeTabComponent() {
        return this.tabs.find(t => t.id === this.activeTab)?.component;
      },

      tabs() {
        return [
          { id: 'app-setting-configs', display: 'App Settings', component: merchantAppSettingConfigs },
          { id: 'app-setting-resources', display: 'App Resources', component: merchantAppSettingResources },
          { id: 'merchant-features', display: 'Portal Features', component: merchantFeatures },
          { id: 'merchant-options', display: 'Merchant Options', component: merchantOptions },
          { id: 'user-validations', display: 'User Accounts', component: merchantUserAccounts },
          { id: 'notification-preferences', display: 'Notifications', component: merchantDefaultNotificationPreferences },
          { id: 'additional-settings', display: 'Additional Settings', component: merchantAdditionalSettings },
          { id: 'payment-configs', display: 'Payment Providers', component: merchantPaymentConfigs },
          { id: 'pos-configs', display: 'Point of Sale', component: merchantPosConfigs },
          { id: 'loyalty-providers', display: 'Loyalty Providers', component: merchantLoyaltyProviders },
          { id: 'delivery-services', display: 'Delivery Services', component: merchantDeliveryServices },
          { id: 'push-provider', display: 'Push Provider', component: merchantPushProvider },
          { id: 'merchant-menu-types', display: 'Menu Types', component: merchantMenuTypes },
          { id: 'merchant-order-modes', display: 'Mobile Order Modes', component: merchantOrderModes },
          { id: 'merchant-location-services', display: 'Location Services', component: merchantLocationServices },
          { id: 'merchant-connected-account-types', display: 'Connected Account Types', component: merchantConnectedAccountTypes }
        ];
      }
    },

    methods: {
      getActiveTabComponent(activeTab) {
        return this.tabs.find(t => t.id === activeTab)?.component;
      }
    }
  };
</script>
