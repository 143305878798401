<template>
  <div>
    <ul v-if="groupCustomerOrders" class="dist-y-xl">
      <li
        v-for="(groupMemberOrder, i) in groupCustomerOrders"
        :key="i"
        class="order-item group-order-section"
      >
        <div class="is-flex justify-between align-center mar-b">
          <div class="is-flex gap align-center">
            <p class="member-avatar is-flex justify-center align-center">{{ getMemberInitials(groupMemberOrder.member.displayName) }}</p>
            <div class="mar-r-xl">
              <h3 class="has-text-weight-bold is-size-5">
                {{ groupMemberOrder.member.displayName }}
              </h3>
              <p v-if="groupMemberOrder.member.ticketNumber" class="ticket-number has-text-grey">
                Check #{{ groupMemberOrder.member.ticketNumber }}
              </p>
            </div>
          </div>
          <div class="has-text-weight-bold is-size-5">{{ getTotalAmountForMember(i) | dollars }}</div>
        </div>
        <ul class="dist-y">
          <li
            v-for="item in groupMemberOrder.items"
            :key="item.orderItemId"
            class="order-item"
          >
            <div class="is-flex gap align-baseline mar-b-s">
              <p class="menu-item-quantity has-text-weight-bold">{{ item.quantity }}x</p>
              <div class="">
                <p class="has-text-weight-bold is-uppercase">{{ item.itemDescription }}</p>
                <div class="dist-y-xs">
                  <p v-for="(normalModifier) in item.normalModifiers" :key="`${normalModifier.menuItemId}-${normalModifier.modifierId}`" class="is-size-6 has-text-grey">
                    {{ normalModifier.itemDescription }}
                    <span v-if="normalModifier.quantity && normalModifier.quantity > 1">({{ normalModifier.quantity }})</span>
                  </p>
                  <div v-for="(nestedModifier) in item.nestedModifiers" :key="`${nestedModifier.menuItemId}-${nestedModifier.modifierId}`">
                    <p style="marginBottom: -2px" class="has-text-weight-bold">
                      {{ nestedModifier.itemDescription }}
                      <span v-if="nestedModifier.quantity && nestedModifier.quantity > 1">({{ nestedModifier.quantity }})</span>
                    </p>
                    <span class="is-size-6 has-text-grey">
                      {{ nestedModifier.modifiers.map(m => `${m.itemDescription}${m.quantity && m.quantity > 1 ? ` (${m.quantity})` : '' }`).join(', ') }}
                    </span>
                  </div>
                </div>
              </div>
              <p class="menu-item-price">{{ item.priceTotal | dollars }}</p>
            </div>
          </li>
        </ul>
      </li>
    </ul>

    <ul v-else class="dist-y-lg">
      <li
        v-for="(item, itemIndex) in formattedItems"
        :key="item.menuItemId + itemIndex"
        class="order-item"
      >
        <div class="is-flex align-baseline mar-b-s">
          <p class="mar-r-lg has-text-weight-bold">{{ item.quantity }}x</p>
          <div class="mar-r-xl">
            <p class="has-text-weight-bold is-uppercase">
              {{ item.itemDescription }}
              <span v-if="supportsOrderRedemption">
                <b-icon
                  v-if="item.isRedeemed"
                  v-tippy="{ content: itemRedemptionDateDisplay(item) }"
                  icon="check"
                  class="is-size-6"
                  type="is-success"
                />
              </span>
            </p>
            <div :class="{ 'dist-y-xs': !!item.nestedModifiers.length}">
              <p v-for="(normalModifier, i) in item.normalModifiers" :key="i" class="is-size-6 has-text-grey">
                {{ normalModifier.itemDescription }}
                <span v-if="normalModifier.quantity && normalModifier.quantity > 1">({{ normalModifier.quantity }})</span>
              </p>
              <div v-for="(nestedModifier, i) in item.nestedModifiers" :key="i + nestedModifier.menuItemId">
                <p style="marginBottom: -2px" class="has-text-weight-bold">
                  {{ nestedModifier.itemDescription }}
                  <span v-if="nestedModifier.quantity && nestedModifier.quantity > 1">({{ nestedModifier.quantity }})</span>
                </p>
                <span class="is-size-6 has-text-grey">
                  {{ nestedModifier.modifiers.map(m => `${m.itemDescription}${m.quantity && m.quantity > 1 ? ` (${m.quantity})` : ''}`).join(', ') }}
                </span>
              </div>
            </div>
          </div>
          <p class="menu-item-price">{{ item.priceTotal | dollars }}</p>
        </div>
      </li>
    </ul>
  </div>
</template>
<script>

  import merchantMixin from '@/mixins/merchant';
  import moment from 'moment-timezone';


  export default {
    name: 'OrderDetailsItems',

    mixins: [merchantMixin],

    props: {
      order: {
        type: Object,
        required: true
      }
    },

    computed: {
      formattedItems() {
        return this.formatItems(this.$clone(this.order.orderItems));
      },

      isGroupOrder() {
        return this.order.features === 'GroupOrder' && !!this.order.groupOrder;
      },

      supportsOrderRedemption() {
        return this.$_selectedMerchant.merchantOptionsCheckout.showOrderQrCode;
      },

      groupCustomerOrders() {
        if (!this.isGroupOrder) {
          return null;
        }
        const { members } = this.order.groupOrder;
        const ordersByMember = members.map(m => ({
          member: m,
          items: m.orderItemIds.map(itemId => this.getItemByOrderItemId(itemId))
        }));
        return ordersByMember.map(o => ({ ...o, items: this.formatItems(o.items) }));
      }
    },

    methods: {
      formatItems(items) {
        return items.map((i) => {
          const { normalModifiers, nestedModifiers } = this.formatModifiers(i.modifiers);
          return {
            ...i,
            normalModifiers,
            nestedModifiers
          };
        });
      },

      itemRedemptionDateDisplay(item) {
        return `Redeemed on ${moment(item.redemptionDate).format('MMMM D, YYYY')}`;
      },

      formatModifiers(modifiers) {
        return this.$clone(modifiers).reduce((acc, m) => {
          if (m.modifiers?.length) {
            acc.nestedModifiers.push(m);
          }
          else {
            acc.normalModifiers.push(m);
          }
          return acc;
        }, { nestedModifiers: [], normalModifiers: [] });
      },

      getItemByOrderItemId(orderItemId) {
        return this.order.orderItems.find(item => item.orderItemId === orderItemId);
      },

      getMemberInitials(fullName) {
        const initials = fullName.trim().split(' ').map(n => n[0].toUpperCase());

        return initials.length > 1
          ? [initials[0], initials[initials.length - 1]].join('')
          : initials[0];
      },

      getTotalAmountForMember(memberIndex) {
        const items = this.groupCustomerOrders.find((order, i) => i === memberIndex).items;
        return items.reduce((sum, item) => {
          sum += item.priceTotal;
          return sum;
        }, 0);
      }
    }
  };
</script>

<style lang="sass" scoped>
  .group-order-section
    .member-avatar
      background-color: $dark
      border: 1px solid $dark
      border-radius: $radius-rounded
      color: $white
      height: 2.25rem
      width: 2.25rem

  .menu-item-price
    margin-left: auto

  .menu-item-quantity
    width: 2.25rem
    text-align: center

  .ticket-number
    margin-top: -0.33rem
</style>
