import http from '@/services/http';

export async function fetchTipReport(merchantId, params) {
  try {
    const { data } = await http.get(`merchants/${merchantId}/department_employee_tips`, { params });
    return data;
  }
  catch (error) {
    throw error;
  }
}

export async function fetchTipReportCsv(merchantId, params) {
  try {
    const { data } = await http.get(`merchants/${merchantId}/department_employee_tips.csv`, { params });
    return data;
  }
  catch (error) {
    throw error;
  }
}

export default {
  fetchTipReport,
  fetchTipReportCsv
};
