<template>
  <div class="card">
    <div class="card-content">
      <p class="title is-4 has-text-weight-normal">Time Frame</p>
      <p class="subtitle is-6 has-text-grey mar-b">Define when this earn rule can be applied</p>
      <div class="is-grid col-2 gap-x">
        <validated-input
          name="startDate"
          rules="required"
          label="Start Date"
        >
          <b-datepicker
            v-model="startDate"
            data-test-id="start-date-input"
            :years-range="dys_ranges.startDate"
            placeholder="Start Date"
            icon="calendar-alt"
            class="has-extra-shadow"
            :events="endDate ? [{ date: endDate, type: 'is-danger' }] : []"
            indicators="bars"
            :focused-date="endDate"
            :min-date="minDate"
            :max-date="maxDate"
            :disabled="readOnly || disableStartDate"
            @change-year="(year) => $_handleYearChange({ year, type: 'startDate', yearsInPast: 10, yearsInFuture: 30 })"
          >
            <div class="buttons is-right">
              <b-button @click="handleInput({ startDate: null })">Clear</b-button>
            </div>
          </b-datepicker>
        </validated-input>

        <validated-input
          name="endDate"
          label="End Date"
          sub-label="(Optional)"
          sub-label-on-side
        >
          <b-datepicker
            v-model="endDate"
            data-test-id="end-date-input"
            :years-range="dys_ranges.endDate"
            placeholder="End Date"
            icon="calendar-alt"
            class="has-extra-shadow"
            position="is-bottom-left"
            :min-date="minDate"
            :max-date="maxDate"
            :events="startDate ? [{ date: startDate, type: 'is-success' }] : []"
            indicators="bars"
            :focused-date="startDate"
            :disabled="readOnly"
            @change-year="(year) => $_handleYearChange({ year, type: 'endDate', yearsInPast: 10, yearsInFuture: 30 })"
          >
            <div class="buttons is-right">
              <b-button @click="handleInput({ endDate: null })">Clear</b-button>
            </div>
          </b-datepicker>
        </validated-input>
      </div>

      <template
        v-if="value.engagementType === engagementTypes.SALES_TRANSACTION.id"
      >
        <hr>

        <div class="is-grid dist-y-md">
          <div class="is-flex justify-start align-center">
            <label class="label mar-none mar-r-xl">Earn rule applies...</label>
            <b-select v-model="dayTimeConstraints" :disabled="readOnly">
              <option :value="false">All day, every day</option>
              <option :value="true">During specific times</option>
            </b-select>
          </div>
          <template v-if="dayTimeConstraints">
            <div>
              <transition name="fade-down">
                <validated-input
                  data-test-id="days-of-week-input"
                  name="selectedValidDays"
                  label="Days of the Week"
                  :custom-messages="{ required: 'At least one day must be selected' }"
                  :rules="{ required: !selectedValidDays.length }"
                >
                  <div class="is-grid gap col-7">
                    <check-button
                      v-for="(dayValue, day) in daysOfWeek"
                      :key="`${dayValue}-${day}`"
                      v-model="selectedValidDays"
                      :native-value="dayValue"
                      :disabled="readOnly"
                      :label="day"
                    />
                  </div>
                </validated-input>
              </transition>
            </div>
            <transition name="fade-down">
              <div data-test-id="time-of-day-radio-buttons">
                <label key="timeOfDayLabel" class="label">Time of Day</label>
                <div key="timeOfDay" class="is-flex-start-aligned">
                  <radio-button
                    v-model="timeOfDayRange"
                    name="timeOfDayRange"
                    :native-value="false"
                    :disabled="readOnly"
                  >
                    All Day
                  </radio-button>
                  <radio-button
                    v-model="timeOfDayRange"
                    name="timeOfDayRange"
                    :native-value="true"
                    class="mar-l-md"
                    :disabled="readOnly"
                  >
                    Time Range
                  </radio-button>
                </div>
              </div>
            </transition>
            <transition name="fade-down">
              <div
                v-if="timeOfDayRange"
                key="startEndTime"
                data-test-id="time-of-day-range-input"
                class="is-flex-start-aligned"
              >
                <validated-input
                  rules="required"
                  name="startTime"
                  label="From"
                >
                  <b-timepicker
                    v-model="startTime"
                    size="is-small"
                    :default-minutes="0"
                    hour-format="12"
                    inline
                    :disabled="readOnly"
                  />
                </validated-input>
                <validated-input
                  rules="required|dateTimeIsAfter:@startTime"
                  name="endTime"
                  label="To"
                  class="mar-l-md"
                >
                  <b-timepicker
                    v-model="endTime"
                    size="is-small"
                    :default-minutes="0"
                    hour-format="12"
                    inline
                    :disabled="readOnly"
                  />
                </validated-input>
              </div>
            </transition>
          </template>
        </div>
      </template>
    </div>
    <earn-rule-run-limit-inputs
      v-model="value.earnRuleRunLimitPolicies"
      :read-only="readOnly"
    />
  </div>
</template>

<script>
  import moment from 'moment-timezone';

  import { constraintModels, engagementTypes } from '@/constants/earnRules';
  import daysOfWeek from '@/constants/daysOfWeek';

  import dynamicYearSelectMixin from '@/mixins/dynamic-year-select';

  import EarnRuleRunLimitInputs from '../../run-limit-policy-inputs/earn-rule-run-limit-inputs.vue';

  export default {
    name: 'TimeFrameStep',

    components: { EarnRuleRunLimitInputs },

    mixins: [dynamicYearSelectMixin],

    props: {

      value: {
        type: Object,
        required: true
      },

      readOnly: {
        type: Boolean,
        default: false
      },

      earnRuleFullyEditable: {
        type: Boolean,
        default: false
      }
    },

    data() {
      return {
        daysOfWeek,
        engagementTypes
      };
    },

    computed: {
      minDate() {
        return moment()
          .add(1, 'days')
          .startOf('day')
          .toDate();
      },

      maxDate() {
        // NOTE: This correlates to the BE definition for "expiring" a non-active earn rule
        return moment(new Date('12/31/2049'))
          .startOf('day')
          .toDate();
      },

      startDate: {
        get() {
          return this.value.startDate && moment(this.value.startDate).toDate();
        },
        set(val) {
          const dateString = moment.utc(val).format('YYYY-MM-DD');
          this.handleInput({ startDate: dateString });
        }
      },

      endDate: {
        get() {
          return this.value.endDate && moment(this.value.endDate).toDate();
        },
        set(val) {
          const dateString = moment.utc(val).format('YYYY-MM-DD');
          this.handleInput({ endDate: dateString });
        }
      },

      disableStartDate() {
        return !this.earnRuleFullyEditable;
      },

      dayOfWeekConstraint() {
        return this.getConstraintByType(constraintModels.DAY_OF_WEEK.type);
      },

      timeOfDayConstraint() {
        return this.getConstraintByType(constraintModels.TIME_OF_DAY.type);
      },

      dayTimeConstraints: {
        get() {
          return !!(this.dayOfWeekConstraint || this.timeOfDayConstraint);
        },
        set(value) {
          if (value) {
            this.addConstraint(constraintModels.DAY_OF_WEEK);
          }
          else {
            const updatedConstraints = this.value.earnRuleConstraints.filter((constraint) => {
              const isDayOfWeekConstraint = constraint.constraintType === constraintModels.DAY_OF_WEEK.type;
              const isTimeOfDayConstraint = constraint.constraintType === constraintModels.TIME_OF_DAY.type;
              return !(isDayOfWeekConstraint || isTimeOfDayConstraint);
            });
            this.handleInput({ earnRuleConstraints: updatedConstraints });
          }
        }
      },

      selectedValidDays: {
        get() {
          return this.dayOfWeekConstraint?.validDays || [];
        },
        set(validDays) {
          this.updateConstraint({ constraintType: constraintModels.DAY_OF_WEEK.type, payload: { validDays } });
        }
      },

      timeOfDayRange: {
        get() {
          return !!this.timeOfDayConstraint;
        },
        set(value) {
          if (value) {
            this.addConstraint(constraintModels.TIME_OF_DAY);
          }
          else {
            const updatedConstraints = this.value.earnRuleConstraints.filter(constraint => !(constraint.constraintType === constraintModels.TIME_OF_DAY.type));
            this.handleInput({ earnRuleConstraints: updatedConstraints });
          }
        }
      },

      startTime: {
        get() {
          return this.timeOfDayConstraint ? moment(this.timeOfDayConstraint.startTime, 'HH:mm').toDate() : null;
        },
        set(value) {
          this.updateConstraint({
            constraintType: constraintModels.TIME_OF_DAY.type,
            payload: { startTime: this.formatTime(value) }
          });
        }
      },

      endTime: {
        get() {
          return this.timeOfDayConstraint ? moment(this.timeOfDayConstraint.endTime, 'HH:mm').toDate() : null;
        },
        set(value) {
          this.updateConstraint({
            constraintType: constraintModels.TIME_OF_DAY.type,
            payload: { endTime: this.formatTime(value) }
          });
        }
      }
    },

    methods: {
      formatTime: value => moment(value).format('HH:mm'),

      getConstraintByType(constraintType) {
        return this.value.earnRuleConstraints.find(c => c.constraintType === constraintType);
      },

      addConstraint(constraint) {
        const newConstraint = {
          constraintType: constraint.type,
          ...constraint.defaultData
        };
        this.handleInput({ earnRuleConstraints: [...this.value.earnRuleConstraints, newConstraint] });
      },

      updateConstraint({ constraintType, payload }) {
        const updatedConstraints = this.$clone(this.value.earnRuleConstraints).map((c) => {
          if (c.constraintType === constraintType) {
            return { ...c, ...payload };
          }
          return c;
        });
        this.handleInput({ earnRuleConstraints: updatedConstraints });
      },


      handleInput(payload) {
        this.$emit('input', { ...this.value, ...payload });
      }
    }
  };
</script>

<style lang="sass" scoped>
  .card-content:nth-child(even)
    border-top: 1px solid $grey-lightest
    background-color: $white-ter !important
</style>
