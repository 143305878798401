import { capitalCase } from 'change-case';

const updatedCapitalCase = (value) => {
  if (!value) return '';

  value = value.replace(/([a-z])([A-Z])/g, '$1 $2') // Handle cases like 'helloWorld' -> 'hello World'
    .replace(/([0-9])([a-z])/g, '$1 $2') // Handle cases like '9hello' -> '9 hello'
    .replace(/([a-z])([0-9])/g, '$1 $2'); // Handle cases like 'Date2023' -> 'Date 2023'

  return capitalCase(value);
};

export default updatedCapitalCase;
