import { Model } from '@vuex-orm/core';
import http from '@/services/http';
import capitalCase from '@/helpers/capitalCase';
import FetchModel from '@/store/classes/FetchModel';


export default class MerchantMenuTypeConfiguration extends FetchModel {
  static entity = 'merchantMenuTypeConfigurations'



  // FIELDS //////////////////////
  static fields() {
    return {
      id: this.attr(''),
      merchantId: this.attr(''),
      menuType: this.attr('')
    };
  }



  // STATE //////////////////////
  static state() {
    return {
      fetching: false,
      submitting: false
    };
  }

  static $state() {
    return this.store().state.entities.merchantMenuTypeConfigurations;
  }

  static getMenuTypeConfig({ merchantId, menuTypeId }) {
    const menuTypeConfigs = this.getMenuTypes(merchantId);
    return menuTypeConfigs.find(m => m.id === menuTypeId);
  }

  static getMenuTypes(merchantId) {
    let menuTypeConfigs = this.query().where('merchantId', merchantId).orderBy(mtc => mtc.menuType.id).get();

    if (!menuTypeConfigs.length) {
      menuTypeConfigs = this.query().where('merchantId', 0).orderBy(mtc => mtc.menuType.id).get();
    }

    return menuTypeConfigs.map(menuTypeConfig => ({
      ...menuTypeConfig.menuType,
      displayName: capitalCase(menuTypeConfig.menuType.name)
    }));
  }

  static async fetchMerchantMenuTypeConfigurations(merchantId, options = {}) {
    const _merchantId = merchantId || this.store().state.entities.merchants.selectedMerchantId;
    await this.fetchData({
      endpoint: `merchants/${_merchantId}/merchant_menu_type_configurations`,
      options,
      transformData: data => data.merchantMenuTypeConfigurations
    });
  }

  static async addMerchantMenuTypeConfiguration({ menuTypeId, merchantId }) {
    try {
      this.commit((state) => {
        state.submitting = true;
      });

      const _merchantId = merchantId || this.store().state.entities.merchants.selectedMerchantId;

      const response = await http.post(
        `merchants/${_merchantId}/merchant_menu_type_configurations`,
        { merchantMenuTypeConfiguration: { menuTypeId } }
      );

      this.insert({
        data: response.data.merchantMenuTypeConfiguration
      });
    }
    catch (error) {
      throw error;
    }
    finally {
      this.commit((state) => {
        state.submitting = false;
      });
    }
  }

  static async removeMerchantMenuTypeConfiguration(merchantMenuTypeConfigurationId) {
    try {
      this.commit((state) => {
        state.submitting = true;
      });

      await http.delete(`merchant_menu_type_configurations/${merchantMenuTypeConfigurationId}`);

      this.delete(merchantMenuTypeConfigurationId);
    }
    catch (error) {
      throw error;
    }
    finally {
      this.commit((state) => {
        state.submitting = false;
      });
    }
  }
}
