<template>
  <validated-form
    ref="form"
    form-id="mobileTippingOptionForm"
    @valid-submit="handleSubmit"
  >
    <panel title="Configuration" collapsible start-open :loading="isFetching">
      <template #buttons>
        <b-button
          native-type="submit"
          :loading="isSubmitting"
          type="is-primary"
          rounded
          size="is-medium"
          :class="['is-bold']"
        >
          Save
        </b-button>
      </template>

      <div>
        <validated-input
          class="mar-y-lg"
          label="Tipping Style"
          name="tippingStyle"
        >
          <toggle-buttons
            v-model="isDefaultTippingMethod"
            label-left="Options"
            sub-label-left="Guests can select from three tip options"
            :value-left="true"
            label-right="Incrementation"
            sub-label-right="Guests start with default tip amount and can adjust amount up and down"
            :value-right="false"
          />
        </validated-input>
      </div>

      <div>
        <tipping-option-group
          v-model="form"
          :tipping-option-type-id="tippingOptionTypes.TIP_ONLY"
          :disabled="!$can('update', 'MerchantTippingOption')"
          :tipping-method-type-id="form.length && form[0].tippingOptionMethodTypeId"
        />
      </div>
    </panel>
  </validated-form>
</template>

<script>
  import merchantMixin from '@/mixins/merchant';
  import { tippingOptionMethodTypes, tippingOptionTypes, tippingTypes } from '@/constants/merchantTippingOptions';

  // components
  import tippingOptionGroup from '@/components/globals/tipping-option-group.vue';

  // classes
  import MerchantTippingOption from '@/store/classes/MerchantTippingOption';

  export default {
    name: 'MobileTippingConfiguration',

    components: { tippingOptionGroup },

    mixins: [merchantMixin],

    props: {
      tippingOptions: {
        type: Array,
        required: true
      }
    },

    data() {
      return {
        form: null,
        isDefaultTippingMethod: null,
        tippingOptionTypes,
        tippingOptionMethodTypes,
        tippingTypes
      };
    },

    computed: {
      isFetching() {
        return MerchantTippingOption.$state().fetching;
      },

      isSubmitting() {
        return MerchantTippingOption.$state().submitting;
      },

      merchantSpecificOptions() {
        return this.tippingOptions.filter(to => to.merchantId > 0);
      },

      defaultOptions() {
        return this.tippingOptions.filter(to => to.merchantId === 0);
      },

      backfilledValues() {
        if (!this.merchantSpecificOptions.length) {
          return this.$clone(this.defaultOptions);
        }
        if (this.merchantSpecificOptions.length >= 3) {
          return this.$clone(this.merchantSpecificOptions);
        }
        const copy = this.merchantSpecificOptions[0];
        const amount = Number(copy.dollarAmount);
        return [...Array(3)].map((_, i) => ({ ...copy, dollarAmount: Number(amount + (i * 5)) }));
      }
    },

    watch: {
      isDefaultTippingMethod: 'handleMethodTypeChange'
    },

    created() {
      this.onCreated();
    },

    methods: {
      async onCreated() {
        this.isDefaultTippingMethod = this.backfilledValues[0].tippingOptionMethodTypeId === this.tippingOptionMethodTypes.DEFAULT;
        this.form = this.$clone(this.backfilledValues);
      },

      handleMethodTypeChange(newVal, oldVal) {
        const methodTypeId = this.isDefaultTippingMethod ? this.tippingOptionMethodTypes.DEFAULT : this.tippingOptionMethodTypes.BUTTON;
        const amount = Number(this.form[0].dollarAmount);
        const hasChanged = !!oldVal && oldVal !== newVal;
        const formClone = this.$clone(this.form);

        formClone.forEach((merchantTippingOption, i) => {
          merchantTippingOption.tippingOptionMethodTypeId = methodTypeId;
          merchantTippingOption.dollarAmount = hasChanged ? Number(amount + (i * 5)) : merchantTippingOption.dollarAmount;
        });

        this.form = formClone;
      },

      async handleSubmit() {
        try {
          const merchantTippingOptions = this.isDefaultTippingMethod ? this.$clone(this.form) : [this.form[0]];
          await MerchantTippingOption.setMerchantTippingOptions({ merchantTippingOptions, merchantId: this.$_selectedMerchantId });

          this.$_onRequestSuccess({
            toastOptions: {
              message: 'Tipping options have been successfully updated!'
            }
          });
        }
        catch (error) {
          this.$_onRequestError({
            error,
            toastOptions: {
              message: 'There was an error updating your tipping options'
            }
          });
        }
      }
    }
  };
</script>
