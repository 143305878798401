var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-radio',{class:[
    'radiobox',
    {
      checked: _vm.nativeValue === _vm.value,
      'left-label': _vm.leftLabel,
      'justify-between': _vm.justifyBetween
    }
  ],attrs:{"value":_vm.value,"disabled":_vm.disabled,"name":_vm.name,"native-value":_vm.nativeValue},on:{"input":function($event){return _vm.$emit('input', $event)}}},[_c('div',{staticClass:"is-flex align-center"},[(_vm.icon)?_c('b-icon',{staticClass:"mar-r-sm",attrs:{"icon":_vm.icon,"pack":_vm.iconPack,"size":"is-medium"}}):_vm._e(),_c('div',[_c('p',{staticClass:"has-text-weight-bold"},[_vm._t("default")],2),(_vm.$slots.sublabel)?_c('p',{class:[
          'is-size-7',
          _vm.nativeValue === _vm.value ? 'has-text-primary-dark' : 'has-text-grey'
        ]},[_vm._t("sublabel")],2):_vm._e()])],1)])}
var staticRenderFns = []

export { render, staticRenderFns }