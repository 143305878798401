import uaParser from 'ua-parser-js';
import { v4 as uuid } from 'uuid';

import storage from '@/services/storage';
import logger from '@/services/logger';

const helpers = {
  async getAttributes(eventParams, store) {
    const storeData = await helpers.getStoreData(store);
    const deviceTimestamp = new Date();

    const payload = {
      ...eventParams,
      ...storeData,
      deviceTimestamp: deviceTimestamp.toJSON()
    };

    return payload;
  },

  async getStoreData(store) {
    const deviceData = await helpers.getDeviceData();

    const accountId = store.state.session.currentUserId;
    const merchantId = store.state.entities.merchants.selectedMerchantId;

    return {
      merchantId,
      accountId,
      ...deviceData
    };
  },

  async getDeviceData() {
    const { os, device, browser } = uaParser(window.navigator.userAgent);
    let deviceId = storage.local.get('deviceId');

    if (!deviceId) {
      deviceId = uuid();
    }

    return {
      deviceOS: os.name,
      deviceOSVersion: os.version,
      deviceModel: device.model || null,
      browser: browser.name,
      browserVersion: browser.version,
      deviceId
    };
  },

  triggerDebuggerLogging(eventName, attributes) {
    const isNonProdEnv = process.env.NODE_ENV !== 'production';
    if (isNonProdEnv) {
      const logStyles = [
        'color: #5186EC; font-weight: bold',
        'color: #5186EC; font-weight: bold',
        'color: #58A55C; font-weight: bold'
      ];

      logger.log(
        `%cNew Relic%c Event:%c ${eventName}`,
        ...logStyles,
        attributes
      );
    }
  }
};

const triggerBrowserInteraction = (eventName, attributes) => {
  // Our ruby agent does not attach newrelic on the window in development environments
  const isNonDevEnv = process.env.NODE_ENV !== 'development';
  if (isNonDevEnv) {
    /** https://docs.newrelic.com/docs/browser/new-relic-browser/browser-apis/interaction/ */
    /** https://docs.newrelic.com/docs/browser/new-relic-browser/browser-apis/save/ */
    window.newrelic.interaction()
      .setAttribute('browser', attributes.browser)
      .setAttribute('browserVersion', attributes.browserVersion)
      .setAttribute('deviceId', attributes.deviceId)
      .setAttribute('deviceModel', attributes.deviceModel)
      .setAttribute('deviceOS', attributes.deviceOS)
      .setAttribute('deviceOSVersion', attributes.deviceOSVersion)
      .setAttribute('data', attributes.data)
      .setAttribute('eventName', eventName)
      .setAttribute('localIpv4', attributes.localIpv4)
      .setAttribute('merchantId', attributes.merchantId)
      .setAttribute('message', attributes.message)
      .setAttribute('orderId', attributes.orderId)
      .setAttribute('severity', attributes.severity)
      .setAttribute('storeId', attributes.storeId)
      .setAttribute('storeName', attributes.storeName)
      .setAttribute('userAccountId', attributes.accountId)
      .save();
  }
};


const triggerPageAction = (eventName, attributes) => {
  // Our ruby agent does not attach newrelic on the window in development environments
  const isNonDevEnv = process.env.NODE_ENV !== 'development';
  if (isNonDevEnv) {
    /** https://docs.newrelic.com/docs/browser/new-relic-browser/browser-apis/setcustomattribute/ */
    window.newrelic.setCustomAttribute('browser', attributes.browser);
    window.newrelic.setCustomAttribute('browserVersion', attributes.browserVersion);
    window.newrelic.setCustomAttribute('deviceId', attributes.deviceId);
    window.newrelic.setCustomAttribute('deviceModel', attributes.deviceModel, true);
    window.newrelic.setCustomAttribute('deviceOS', attributes.deviceOS);
    window.newrelic.setCustomAttribute('deviceOSVersion', attributes.deviceOSVersion);
    window.newrelic.setCustomAttribute('eventData', attributes.eventData);
    window.newrelic.setCustomAttribute('localIpv4', attributes.localIpv4);
    window.newrelic.setCustomAttribute('merchantId', attributes.merchantId);
    window.newrelic.setCustomAttribute('message', attributes.message);
    window.newrelic.setCustomAttribute('orderId', attributes.orderId);
    window.newrelic.setCustomAttribute('severity', attributes.severity);
    window.newrelic.setCustomAttribute('storeId', attributes.storeId);
    window.newrelic.setCustomAttribute('storeName', attributes.storeName);
    window.newrelic.setCustomAttribute('userAccountId', attributes.accountId, true);

    /** https://docs.newrelic.com/docs/browser/new-relic-browser/browser-apis/addpageaction/  */
    window.newrelic.addPageAction(eventName);
  }
};

const triggerErrorAction = (error, eventName, eventAttributes) => {
  // Our ruby agent does not attach newrelic on the window in development environments
  const isNonDevEnv = process.env.NODE_ENV !== 'development';
  if (isNonDevEnv) {
    window.newrelic.noticeError(error, {
      eventName,
      ...eventAttributes
    });
  }
};

const NewRelicLoggingService = (store) => {
  const newRelicEvent = async ({ eventName, severity, message, data = 'No data provided' }) => {
    const eventParams = { eventName, severity, message, data };

    const eventAttributes = await helpers.getAttributes(eventParams, store);

    triggerBrowserInteraction(eventName, eventAttributes);

    triggerPageAction(eventName, eventAttributes);

    helpers.triggerDebuggerLogging(eventName, eventAttributes);
  };

  const newRelicError = async ({ eventName, severity, message, data = 'No data provided', error }) => {
    const eventParams = { eventName, severity, message, data };

    const eventAttributes = await helpers.getAttributes(eventParams, store);

    triggerBrowserInteraction(eventName, eventAttributes);

    triggerPageAction(eventName, eventAttributes);

    helpers.triggerDebuggerLogging(eventName, eventAttributes);

    triggerErrorAction(error, eventName, eventAttributes);
  };

  return {
    newRelicEvent,
    newRelicError
  };
};

export default NewRelicLoggingService;
