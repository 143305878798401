/* eslint-disable import/no-cycle */
import http from '@/services/http';
import MenuAttribute from './MenuAttribute';
import Item from './Item';
import FetchModel from '@/store/classes/FetchModel';

export default class MenuItemAttribute extends FetchModel {
    static entity = 'menuItemAttributes'

    static primaryKey = ['menuItemId', 'menuAttributeId']

    static fields() {
      return {
        menuAttributeId: this.attr(null),
        menuItemId: this.attr(null),
        sortOrder: this.attr(null),
        menuAttribute: this.belongsTo(MenuAttribute, 'menuAttributeId'),
        menuItem: this.belongsTo(Item, 'menuItemId')
      };
    }

    static searchTagsForItem(menuItemId) {
      return this
        .query()
        .with('menuAttribute.menuAttributeType')
        .where('menuItemId', menuItemId)
        .get()
        .filter(mia => mia?.menuAttribute?.menuAttributeType?.isSearchType);
    }

    static state() {
      return {
        fetching: false,
        submitting: false,
        deleting: false
      };
    }

    static $state() {
      return this.store().state.entities.merchantMenuAttributes;
    }

    static async updateMenuItemAttributes({ itemId, menuAttributeIds }) {
      try {
        this.commit((state) => {
          state.submitting = true;
        });

        const { data: { menuItemAttributes } } = await http.post(`menu_items/${itemId}/menu_item_attributes`, {
          menuAttributeIds
        });
        this.update({
          data: menuItemAttributes
        });
      }
      catch (error) {
        throw error;
      }
      finally {
        this.commit((state) => {
          state.submitting = false;
        });
      }
    }

    static async fetchMenuItemAttributes(itemId, options = {}) {
      await this.fetchData({
        endpoint: `menu_items/${itemId}/menu_item_attributes`,
        ormMethod: 'insertOrUpdate',
        transformData: data => data.menuItemAttributes,
        customQuery: this.query().where('menuItemId', itemId),
        options
      });
    }

    static async deleteMenuItemAttributes({ itemId, menuAttributeIds }) {
      try {
        this.commit((state) => {
          state.deleting = true;
        });

        await http.delete(`menu_items/${itemId}/menu_item_attributes?menu_item_attribute_ids=[${menuAttributeIds.toString()}]`);

        menuAttributeIds.forEach((menuAttributeId) => {
          this.delete([itemId, menuAttributeId]);
        });
      }
      catch (error) {
        throw error;
      }
      finally {
        this.commit((state) => {
          state.deleting = false;
        });
      }
    }
}
