<template>
  <takeover>
    <div class="container has-text-centered">
      <h1 class="title has-text-primary">404</h1>
      <h2 class="subtitle is-3">Page Not Found</h2>
      <b-button
        rounded
        size="is-medium"
        tag="router-link"
        :to="{ name: 'home' }"
        icon-left="arrow-left"
        class="icon-shift"
      >
        Go Back To Homepage
      </b-button>
    </div>
  </takeover>
</template>



<script>
  export default {
    name: 'FourOhFour',

    metaInfo() {
      return {
        title: '404'
      };
    }
  };
</script>

<style lang="sass" scoped>
  .title
    font-size: 8rem
    text-shadow: 0px 2px 1px #333
</style>
