<template>
  <tabs-page
    :tabs="tabs"
    :keep-alive="false"
    title="Hotel Configuration"
  />
</template>

<script>
  import SurveysContainer from '@/components/pages/content-management/survey/surveys-container.vue';

  import merchantMixin from '@/mixins/merchant';

  import ConfigurationsTab from './tabs/configurations-tab.vue';

  export default {
    name: 'HotelConfiguration',

    mixins: [
      merchantMixin
    ],

    computed: {
      tabs() {
        return [
          {
            display: 'Configurations',
            name: 'configurations',
            component: ConfigurationsTab,
            props: {
              merchant: this.$_selectedMerchant
            }
          },
          {
            display: 'Surveys',
            name: 'surveys',
            component: SurveysContainer,
            isHidden: !this.$can('edit', 'survey'),
            props: {
              merchant: this.$_selectedMerchant,
              mobileTippingOnly: true
            }
          }
        ];
      }
    }
  };
</script>
