<template>
  <validated-form
    :ref="formRef"
    :form-id="formRef"
  >
    <div class="mar-b-sm">
      <p class="subtitle is-5 mar-none">
        POS Mappings
      </p>
      <p v-if="$_selectedMerchant.hasUnstructuredMenu" class="sub-label mar-none">
        Map this item to a POS resource
      </p>
    </div>

    <div
      v-if="showPosMappingPanel"
      class="has-text-grey subtitle is-size-6 pad-sm has-border has-border-grey-lighter has-radius has-background-light"
    >
      <p v-if="subtitles.posMapping">
        POS Mapping: <span>#{{ subtitles.posMapping }}</span>
      </p>
      <p v-if="subtitles.modCode">
        Modifier Code: <span>#{{ subtitles.modCode }}</span>
      </p>
      <div v-if="orphanedMappings.length && $_selectedMerchant.numUniquePosTypes <= 1">
        <p v-for="mapping in orphanedMappings" :key="mapping.id">
          Missing POS Item: <span class="has-text-danger">#{{ mapping.posMenuItemId || mapping.posItemId }}</span>
        </p>
      </div>
    </div>

    <b-message v-if="!$_selectedMerchant.hasUnstructuredMenu && !selectedPosModifiers.length" type="is-warning" size="is-small" class="is-compact has-shadow">
      No pos mappings are set for this item
    </b-message>

    <pos-item-search
      v-if="$_selectedMerchant.hasUnstructuredMenu"
      :selected-pos-item-ids="selectedPosModifierIds"
      @select="selectPosItem($event);"
    />

    <template v-if="$_selectedMerchant.hasUnstructuredMenu">
      <b-tag
        v-for="posModifier in nonCodeModifierMappings"
        :key="`posModifier${posModifier.id}`"
        closable
        attached
        size="is-medium"
        :type="posModifier.isOprhan ? 'is-danger' : 'is-primary'"
        :close-type="posModifier.isOprhan ? 'is-danger' : 'is-primary'"
        class="mar-t"
        @close="removePosItemMapping(posModifier)"
      >
        <p
          v-if="posModifier.isOprhan"
          class="pad-l-xs mar-r-xs"
        >
          <b-icon
            v-tippy="{ content: 'POS mapping does not exist in POS Menu', placement: 'top-start' }"
            size="is-small"
            icon="exclamation-triangle"
            pack="fas"
            type="is-white"
            class="mar-r"
          />
          <span class="is-monospaced">{{ posModifier.posItemId || posModifier.posMenuItemId }}</span>
        </p>
        <p
          v-else
          class="mar-r-xs"
        >
          <span class="has-text-weight-bold">{{ posModifier.name || posModifier.posMenuItemName }} - </span>
          <span class="is-monospaced">{{ posModifier.posItemId || posModifier.posMenuItemId }}</span>
        </p>
      </b-tag>

      <template v-if="$_featurePermissions.MODIFIER_CODES">
        <hr class="mar">
        <p class="subtitle is-5 is-marginless">
          Modifier Code
        </p>
        <p class="is-size-7 has-text-grey mar-b-md">
          Map modifier to a POS code
        </p>

        <b-autocomplete
          ref="autocomplete"
          v-model="modifierCodeQuery"
          field="name"
          :data="filteredModifierCodes"
          open-on-focus
          max-height="325"
          placeholder="Type to find a modifier code..."
          clearable
          clear-on-select
          @select="selectPosItem"
          @typing="handleAutoCompleteInput"
          @click.native="handleAutoCompleteInput(modifierCodeQuery)"
        >
          <template #empty>
            No modifier codes found
          </template>
          <template slot-scope="props">
            <div>
              <div class="is-flex-column">
                <p>{{ props.option.name }}</p>
                <p class="has-text-grey is-size-7">{{ props.option.posItemId }}</p>
              </div>
            </div>
          </template>
        </b-autocomplete>

        <b-tag
          v-for="codeModifier in codeModifierMappings"
          :key="`codeModifier${codeModifier.id}`"
          closable
          attached
          size="is-medium"
          type="is-primary"
          close-type="is-primary"
          class="mar-t"
          @close="removeModifierCode(codeModifier)"
        >
          <p class="mar-r-xs">
            <span class="has-text-weight-bold">{{ codeModifier.name || codeModifier.posMenuItemName }} - </span>
            <span class="is-monospaced">{{ codeModifier.posItemId || codeModifier.posMenuItemId }}</span>
          </p>
        </b-tag>
      </template>

      <hr>
    </template>

    <template v-else>
      <ul>
        <li
          v-for="(posModifier, i) in sortedByLocation(selectedPosModifiers)"
          :key="`posModifier-${posModifier.id}-${i}`"
          :type="posModifier.isOrphan ? 'is-danger' : 'is-primary'"
          :close-type="posModifier.isOrphan ? 'is-danger' : 'is-primary'"
          :class="['mar-t', { 'has-text-danger': posModifier.isOrphan }]"
        >
          <div v-if="!posModifier.isOrphan" class="mar-r-xs">
            <span class="has-text-weight-bold">{{ posModifier.posMenuItem.name || 'No name set' }} - </span>
            <span class="is-monospaced">{{ posModifier.posMenuItem.posItemId || 'POS Item Id missing' }}</span>
            <p v-if="posModifier.store" class="is-size-6">{{ posModifier.store.description }}</p>
          </div>
          <p v-else class="pad-l-xs mar-r-xs">
            <b-icon
              v-tippy="{ content: 'POS mapping does not exist in POS Menu', placement: 'top-start' }"
              size="is-small"
              icon="exclamation-triangle"
              pack="fas"
              type="is-danger"
              class="mar-r"
            />
            <span class="is-monospaced">{{ posModifier.posItemId }}</span>
          </p>
        </li>
      </ul>
    </template>
  </validated-form>
</template>

<script>
  import getChangedResources from '@/helpers/get-changed-resources';

  import featurePermissionsMixin from '@/mixins/featurePermissions';
  import merchantMixin from '@/mixins/merchant';
  import multiFormChildProvider from '@/mixins/multiFormMixin/multiFormChildProvider';

  import { emitNativeInput } from '@/services/emit-input';

  import PosMenuItem from '@/store/classes/PosMenuItem';
  import Store from '@/store/classes/Store';


  export default {
    name: 'ModifierPosMappingForm',

    mixins: [featurePermissionsMixin, multiFormChildProvider, merchantMixin],

    props: {
      modifier: {
        type: Object,
        required: true
      },

      mode: {
        type: String,
        required: true
      },

      formRef: {
        type: String,
        required: true
      }
    },

    data: () => ({
      selectedPosModifiers: [],
      filteredModifierCodes: [],
      modifierCodeQuery: '',
      subtitles: {
        modCode: null,
        posMapping: null
      }
    }),

    computed: {
      stores() {
        return Store.all();
      },

      modifierCodes() {
        return PosMenuItem.$state().modifierCodes;
      },

      nonCodeModifierMappings() {
        return this.selectedPosModifiers.filter(mapping => !(mapping?.isModifierCode || mapping?.isPosMenuItemModifierCode));
      },

      codeModifierMappings() {
        return this.selectedPosModifiers.filter(mapping => !!(mapping?.isModifierCode || mapping?.isPosMenuItemModifierCode));
      },

      selectedModifierCodePosMenuIds() {
        return this.codeModifierMappings.map(modifierMapping => modifierMapping?.posItemId || modifierMapping?.posMenuItemId);
      },

      selectedPosModifierIds() {
        return this.selectedPosModifiers.map(posModifier => posModifier.posMenuItemId);
      },

      orphanedMappings() {
        return this.selectedPosModifiers.filter(m => !m.posMenuItem);
      },

      showPosMappingPanel() {
        const hasPosMappings = !!(this.modifier && this.modifier.posMenuItemModifierMappings.length);
        const hasSubtitles = !!(this.subtitles.posMapping || this.subtitles.modCode);
        const hasOrphanedMappings = this.orphanedMappings.length && this.$_selectedMerchant.numUniquePosTypes <= 1;
        return hasPosMappings && (hasSubtitles || hasOrphanedMappings);
      }
    },

    watch: {
      'modifier.posMenuItemModifierMappings': {
        immediate: true,
        handler: 'handleExistingMappings'
      }
    },

    created() {
      this.onCreated();
    },

    methods: {
      onCreated() {
        this.filterModifierCodes(this.$clone(this.modifierCodes));
        this.updateSubtitles();
      },

      updateSubtitles() {
        this.subtitles = {
          modCode: this.getPosMapping({ isModifierCode: true })?.posMenuItemId,
          posMapping: this.getPosMapping({ isModifierCode: false })?.posMenuItemId
        };
      },

      getPosMapping({ isModifierCode }) {
        return this.modifier.posMenuItemModifierMappings?.find(
          mapping => mapping.isPosMenuItemModifierCode === isModifierCode
        );
      },

      emitInput() {
        const formElement = this.$refs.modifierPosMappingForm.$refs.formElement;
        emitNativeInput(formElement);
      },

      handleExistingMappings(newVal, oldVal) {
        // Code pulled from item-pos-mapping.vue
        if (newVal?.length && !oldVal) {
          // NOTE: if the mapping is orphaned, it will not have a posMenuItem, so create a temporary one with posItemId and isOrphan set, in order to render the correct template
          this.selectedPosModifiers = newVal
            .map(modifierMapping => (modifierMapping.posMenuItem
              ? {
                ...modifierMapping,
                isOrphan: false,
                store: this.getStore(modifierMapping.posMenuItem?.storeId)
              }
              : {
                ...modifierMapping,
                isOrphan: true,
                posMenuItem: {
                  posItemId: modifierMapping.posMenuItemId
                }
              }
            ));
        }
      },

      getStore(storeId) {
        if (!storeId) return null;
        return this.stores.find(store => store.storeId === storeId);
      },

      isPosMappingOrphaned(posModifier) {
        if (!this.modifier.id) return false;
        const currentOrphanedPosMappingIds = this.$clone(this.modifier.posMenuItemModifierMappings)
          .filter(m => !m.posMenuItem)
          .map(m => m.posMenuItemId);
        const id = posModifier.posMenuItemId || posModifier.posItemId;
        return currentOrphanedPosMappingIds.includes(id);
      },

      selectPosItem(posModifier) {
        if (posModifier) {
          this.selectedPosModifiers.push({
            ...posModifier,
            store: this.getStore(posModifier.storeId)
          });
        }
        this.emitInput();
      },

      removePosItemMapping(posModifier) {
        const mappingsCopy = this.$clone(this.selectedPosModifiers);
        const fieldToLookup = posModifier.posMenuItem ? 'posMenuItemId' : 'posItemId';
        const updatedMappings = mappingsCopy.filter(mapping => mapping[fieldToLookup] !== posModifier[fieldToLookup]);
        this.selectedPosModifiers = updatedMappings;
        this.emitInput();
      },

      removeModifierCode(codeModifier) {
        this.removePosItemMapping(codeModifier);
        this.handleAutoCompleteInput(this.modifierCodeQuery);
      },

      handleAutoCompleteInput(query) {
        const modifierCodes = query
          ? this.$clone(this.modifierCodes).filter(mc => mc.name.toLowerCase().includes(query.toLowerCase()))
          : this.$clone(this.modifierCodes);
        this.filterModifierCodes(modifierCodes);
      },

      filterModifierCodes(modifierCodes) {
        this.filteredModifierCodes = modifierCodes.filter(
          modifierCode => !this.selectedModifierCodePosMenuIds.includes(modifierCode.posItemId || modifierCode.posMenuItemId)
        );
      },

      sortedByLocation(posMappings) {
        return this.$clone(posMappings).sort((a, b) => (a.store?.description < b.store?.description ? -1 : 1));
      },

      async handleSubmit(modifier = this.modifier) {
        if (!this.$_selectedMerchant?.hasUnstructuredMenu) return modifier;

        try {
          const promises = [];

          const buildAddPosMappingPromise = posItemId => PosMenuItem.addPosMapping({ modifierId: modifier.id, posItemId });

          switch (this.mode) {
            case 'create':
              this.selectedPosModifiers.forEach(selectedModifier => (
                promises.push(buildAddPosMappingPromise(selectedModifier.id))
              ));
              break;

            case 'update': {
              const mappedPosItemIds = this.$clone(this.selectedPosModifiers).map(mapping => mapping.posItemId || mapping.posMenuItemId);
              const { added, removed } = getChangedResources({ newArray: mappedPosItemIds, oldArray: this.modifier.posItemIds });

              added.forEach((posItemId) => {
                const mappingId = this.selectedPosModifiers.find(mapping => mapping.posItemId === posItemId).id;
                promises.push(buildAddPosMappingPromise(mappingId));
              });

              removed.forEach((posItemId) => {
                const mappingId = this.modifier.posMenuItemModifierMappings.find(mapping => mapping.posMenuItemId === posItemId).id;
                promises.push(PosMenuItem.deletePosMapping({ modifierId: modifier.id, id: mappingId }));
              });

              break;
            }

            default:
              break;
          }

          await Promise.all(promises);
        }
        catch (error) {
          this.$_onRequestError({
            error,
            toastOptions: {
              message: `There was an error updating POS mapings for ${this.modifier.displayName}`
            }
          });
        }
        return modifier;
      }
    }
  };
</script>
