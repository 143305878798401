<template>
  <aside class="expo-orders-list pad">
    <div class="controls pad-b">
      <b-tabs
        :value="selectedExpoStatus"
        expanded
        class="mar-b-none pad-b-none"
        @input="handleExpoStatusChange"
      >
        <b-tab-item
          v-for="tab in tabs"
          :key="tab.label"
          class="is-light"
          :class="{
            'is-primary': selectedExpoStatus === tab.label
          }"
          :value="tab.label"
        >
          <template #header>
            {{ tab.label }}
            <b-tag
              :class="['mar-l-xs', { 'is-primary': selectedExpoStatus === tab.label }]"
              rounded
            >
              {{ tab.count }}
            </b-tag>
          </template>
        </b-tab-item>
      </b-tabs>
      <b-dropdown
        :value="orderTypeFilter"
        aria-role="list"
        class="is-full-width"
        expanded
        @change="handleOrderTypeFilterChange($event)"
      >
        <template #trigger>
          <b-button
            :label="orderTypeFilter || 'Filter Orders'"
            rounded
            icon-right="bars-filter"
            class="is-full-width"
          />
        </template>

        <b-dropdown-item
          aria-role="listitem"
          :value="orderTypes.LARGE_ORDER"
          class="pad mar-y-none"
        >
          {{ orderTypes.LARGE_ORDER }}
        </b-dropdown-item>
        <b-dropdown-item
          aria-role="listitem"
          :value="orderTypes.CUSTOMER_PICKUP"
          class="pad mar-y-none"
        >
          {{ orderTypes.CUSTOMER_PICKUP }}
        </b-dropdown-item>
        <b-dropdown-item
          aria-role="listitem"
          :value="orderTypes.DELIVERY"
          class="pad mar-y-none"
        >
          {{ orderTypes.DELIVERY }}
        </b-dropdown-item>
        <template v-if="orderTypeFilter">
          <b-dropdown-item
            aria-role="separator"
            separator
            class="mar-y-none"
          />
          <b-dropdown-item
            aria-role="listitem"
            :value="null"
            class="pad-y pad-l pad-r-sm mar-y-none is-flex justify-between"
          >
            Clear Filter
            <b-icon icon="times" type="is-grey" />
          </b-dropdown-item>
        </template>
      </b-dropdown>
    </div>
    <div
      v-infinite-scroll="loadMore"
      :infinite-scroll-throttle-delay="400"
      :infinite-scroll-distance="10"
      class="orders-container dist-y"
      :class="{'is-flex justify-center is-clipped': fetching && !expoOrders.length }"
    >
      <expo-order-status-card
        v-for="order in expoOrders"
        :key="order.orderId"
        :ref="`order-${order.orderId}`"
        :order="order"
        @select-order="selectOrder(order.orderId)"
      />
      <div
        v-if="fetching"
        class="is-flex justify-center"
        :class="{'mar-t': expoOrders.length }"
      >
        <b-icon
          pack="fal"
          type="is-grey-lighter"
          icon="spinner-third"
          class="spin"
          custom-size="fa-4x"
          data-test-id="loading-spinner"
          style="height: unset; width: unset;"
        />
      </div>
    </div>
  </aside>
</template>

<script>
  import { mapActions, mapGetters, mapState } from 'vuex';

  import { orderTypes } from '@/constants/expo';

  import ExpoOrderStatusCard from './expo-order-status-card.vue';

  export default {
    name: 'ExpoOrdersList',

    components: { ExpoOrderStatusCard },

    data() {
      return {
        orderTypes
      };
    },

    computed: {
      ...mapState('orderExpo', [
        'selectedExpoStatus',
        'storeOrderCounts',
        'selectedStoreId',
        'selectedOrderId',
        'orderTypeFilter',
        'currentPage'
      ]),
      ...mapGetters('orderExpo', ['expoOrders', 'fetching', 'loadedOrdersMatchCount']),

      tabs() {
        return [
          { label: 'Upcoming', count: this.storeOrderCounts?.Upcoming?.count },
          { label: 'Active', count: this.storeOrderCounts?.Active?.count },
          { label: 'Past', count: this.storeOrderCounts?.Past?.count }
        ];
      },

      infiniteScrollDisabled() {
        return this.fetching || this.loadedOrdersMatchCount;
      }
    },

    watch: {
      selectedOrderId: 'scrollToSelectedOrder',
    },

    methods: {
      ...mapActions('orderExpo', ['handleExpoStatusChange', 'fetchStoreOrderData', 'handleOrderTypeFilterChange', 'setCurrentPage']),

      scrollToSelectedOrder() {
        if (this.selectedOrderId) {
          // Revisit later to see if we can do this without a timeout -- right now the DOM is still building
          // the v-for elements when this is called, so the ref isn't available yet, hence the timeout
          setTimeout(() => {
            // Since the ref is attached as part of a v-for, it comes back as an array
            // even though there will always only be one element in the array
            const order = this.$refs[`order-${this.selectedOrderId}`]?.[0];
            if (order) {
              order.$el.scrollIntoView({ behavior: 'smooth', block: 'center' });
            }
          }, 500);
        }
      },

      async loadMore() {
        if (this.selectedStoreId && !this.infiniteScrollDisabled) {
          await this.setCurrentPage(this.currentPage + 1);
          await this.fetchStoreOrderData();
        }
      }
    }
  };
</script>

<style lang='sass' scoped>
  .expo-orders-list
    display: flex
    flex-direction: column
    background-color: $white
    box-shadow: 3px 0 5px rgba(0, 0, 0, 0.1)
    overscroll-behavior: auto contain
    width: 450px
    z-index: 6
    height: calc(100vh - #{$navbar-height})

    .controls
      position: sticky
      top: 0
      border-bottom: 1px solid #dedede
      box-shadow: 0px 10px 10px -7px rgba(0, 0, 0, 0.1)
      z-index: 7 // Needed to make the b-dropdown-menu appear over the orders-container for whatever reason

      .tabs ul
        border-bottom: none

      .tabs li a
        display: flex
        align-items: center
        justify-content: center

    .orders-container
      overflow-y: auto
      min-height: 80vh

  ::v-deep .dropdown-content
    padding-block: 0
    margin-top: 0.5rem
</style>
