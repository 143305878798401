<template>
  <validated-form
    ref="form"
    form-id="contentContactUsForm"
    :disabled="!$can('manage', 'all')"
    @valid-submit="handleSubmit"
  >
    <panel
      title="Communications"
      subtitle="Email Addresses and Phone Numbers used in outbound communications to guests"
      collapsible
      :loading="isFetching"
    >
      <template #buttons>
        <b-button
          v-if="$can('create', 'MerchantTermsOfService')"
          rounded
          class="is-bold"
          size="is-medium"
          type="is-primary is-light"
          :loading="isResettingDefault"
          :disabled="hasDefaultValues"
          @click="resetToDefault"
        >
          Reset to Defaults
        </b-button>
        <b-button
          v-if="$can('create', 'MerchantTermsOfService')"
          rounded
          class="is-bold"
          size="is-medium"
          native-type="submit"
          type="is-primary"
          :loading="isLoading"
        >
          Save
        </b-button>
      </template>

      <section>
        <b-message v-if="!$can('manage', 'all')" class="box is-paddingless" type="is-primary">
          Please contact
          <a class="link" href="mailto:support@cardfree.com">CardFree support</a>
          to update your communication settings
        </b-message>

        <div class="communications-inputs">
          <template v-for="templateType of templateTypes">
            <div :key="templateType.templateTypeId" class="communications-input-row">
              <div>
                <div class="label is-marginless">
                  {{ templateType.label }}
                </div>
                <div v-if="templateType.subLabel" class="is-size-7 is-marginless has-text-grey">
                  ({{ templateType.subLabel }})
                </div>
              </div>

              <template v-for="source of templateType.communicationSources">
                <validated-text-input
                  v-if="templateType.supportsEmail && source.methodTypeName === 'Email'"
                  :key="source.id"
                  class="is-marginless"
                  label="Email"
                  type="email"
                  :name="`email-${source.id}`"
                  rules="required"
                  label-position="on-border"
                  :value="source.value"
                  @input="value => handleValueChange({ id: source.id, value })"
                />

                <validated-text-input
                  v-if="templateType.supportsSms && source.methodTypeName === 'Sms'"
                  :key="source.id"
                  class="is-marginless"
                  label="Phone"
                  type="phone"
                  :name="`phone-${source.id}`"
                  rules="required"
                  label-position="on-border"
                  :value="source.value"
                  @input="value => handleValueChange({ id: source.id, value })"
                />
              </template>
            </div>
          </template>
        </div>
      </section>
    </panel>
  </validated-form>
</template>

<script>
  import MerchantCommunicationSource from '@/store/classes/MerchantCommunicationSource';

  import merchantMixin from '@/mixins/merchant';

  import CommunicationTemplateType from '@/store/classes/CommunicationTemplateType';

  import { sentenceCase } from 'change-case';

  import capitalCase from '@/helpers/capitalCase';


  export default {
    name: 'ContentCommunicationSourceForm',

    mixins: [merchantMixin],

    data() {
      return {
        forms: [],
        isLoading: false,
        isResettingDefault: false
      };
    },

    computed: {
      isFetching() {
        return MerchantCommunicationSource.$state().fetching || CommunicationTemplateType.$state().fetching;
      },

      communicationTemplateTypesById() {
        return CommunicationTemplateType.byId();
      },

      defaultCommunicationSources() {
        return MerchantCommunicationSource.query().where('merchantId', 0).get();
      },

      merchantCommunicationSources() {
        return MerchantCommunicationSource.query().where('merchantId', this.$_selectedMerchantId).get();
      },

      merchantCommunicationSourcesByKey() {
        return this.merchantCommunicationSources.reduce((templateSource, communicationTemplateType) => {
          const sourceKey = `${communicationTemplateType.templateTypeId}-${communicationTemplateType.methodTypeName}`;
          templateSource[sourceKey] = communicationTemplateType;
          return templateSource;
        }, {});
      },


      templateTypes() {
        return this.forms.reduce((acc, item) => {
          const foundSourceIndex = acc.findIndex(source => source.templateTypeId === item.templateTypeId);
          if (foundSourceIndex !== -1) {
            acc[foundSourceIndex].communicationSources.push(item);
          }
          else {
            acc.push({
              templateTypeId: item.templateTypeId,
              label: this.communicationTemplateTypesById[item.templateTypeId]?.name && capitalCase(this.communicationTemplateTypesById[item.templateTypeId].name),
              subLabel: this.communicationTemplateTypesById[item.templateTypeId]?.description && sentenceCase(this.communicationTemplateTypesById[item.templateTypeId]?.description),
              communicationSources: [item],
              supportsEmail: this.communicationTemplateTypesById[item.templateTypeId]?.supportsEmail,
              supportsSms: this.communicationTemplateTypesById[item.templateTypeId]?.supportsSms
            });
          }

          return acc;
        }, []).sort((a, b) => a.templateTypeId - b.templateTypeId > -1);
      },


      hasDefaultValues() {
        const merchantCommunicationSources = this.merchantCommunicationSources.filter(source => this.communicationTemplateTypesById[source.templateTypeId]?.isConfigurable);
        return merchantCommunicationSources.every((selectedSource) => {
          const matchedDefault = this.defaultCommunicationSources.find((defaultSource) => {
            const isSameTemplateType = defaultSource.templateTypeId === selectedSource.templateTypeId;
            const isSameMethodType = defaultSource.methodTypeName === selectedSource.methodTypeName;

            return isSameTemplateType && isSameMethodType;
          });
          return selectedSource.value === matchedDefault?.value;
        });
      }
    },

    created() {
      this.onCreated();
    },

    methods: {
      async onCreated() {
        await Promise.all([
          this.fetchCommunicationTemplateTypes(),
          this.fetchMerchantCommunicationSources()
        ]);
        this.setFormData();
      },

      async fetchMerchantCommunicationSources() {
        try {
          await MerchantCommunicationSource.fetchMerchantCommunicationSources(this.$_selectedMerchantId);
        }

        catch (error) {
          this.$_onRequestError({
            toastOptions: {
              message: 'There was an error fetching communication sources'
            },
            error
          });
        }
      },

      async fetchCommunicationTemplateTypes() {
        try {
          await CommunicationTemplateType.fetchCommunicationTemplateTypes();
        }
        catch (error) {
          this.$_onRequestError({
            toastOptions: {
              message: 'There was an error fetching communication template type'
            },
            error
          });
        }
      },

      handleValueChange({ id, value }) {
        this.forms = this.forms.map((item) => {
          if (item.id === id) {
            item.value = value;
            item.isDirty = true;
          }

          return item;
        });
      },

      setFormData() {
        let communicationSources;
        if (this.merchantCommunicationSources.length) {
          // Handles if merchant has some but not all communication sources set (set default in place of a missing type)
          communicationSources = this.defaultCommunicationSources.reduce((sourceTemplates, defaultCommunicationSource) => {
            if (this.communicationTemplateTypesById[defaultCommunicationSource.templateTypeId].isConfigurable) {
              const sourceKey = `${defaultCommunicationSource.templateTypeId}-${defaultCommunicationSource.methodTypeName}`;
              const selectedCommunicationSource = this.merchantCommunicationSourcesByKey[sourceKey];

              if (selectedCommunicationSource) {
                sourceTemplates.push({ ...selectedCommunicationSource });
              }
              else {
                sourceTemplates.push({ ...defaultCommunicationSource });
              }
            }
            return sourceTemplates;
          }, []);
        }
        else {
          communicationSources = this.defaultCommunicationSources.filter(source => this.communicationTemplateTypesById[source.templateTypeId].isConfigurable);
        }

        this.forms = JSON.parse(JSON.stringify(communicationSources)).map((x) => {
          x.isDirty = false;
          return x;
        });
      },

      toggleLoadingState({ state, value }) {
        this[state] = value;
      },

      async handleSubmit() {
        try {
          this.toggleLoadingState({ state: 'isLoading', value: true });

          const { communicationSourcesToCreate, communicationSourcesToUpdate } = this.getCommunicationSourcesToCreateAndUpdate();

          if (communicationSourcesToUpdate.length) {
            await this.updateMerchantCommunicationSources(communicationSourcesToUpdate);
          }

          if (communicationSourcesToCreate.length) {
            await this.createMerchantCommunicationSources(communicationSourcesToCreate);
          }

          this.setFormData();

          this.$_onRequestSuccess({
            toastOptions: {
              message: 'Communication sources have been successfully updated!'
            }
          });
        }

        catch (error) {
          this.$_onRequestError({
            error,
            toastOptions: {
              message: 'There was an error updating the communication sources'
            }
          });
        }

        finally {
          this.toggleLoadingState({ state: 'isLoading', value: false });
        }
      },

      async updateMerchantCommunicationSources(merchantCommunicationSources) {
        await MerchantCommunicationSource.updateMerchantCommunicationSources({
          merchantId: this.$_selectedMerchantId,
          merchantCommunicationSources
        });
      },

      async createMerchantCommunicationSources(merchantCommunicationSources) {
        await MerchantCommunicationSource.createMerchantCommunicationSources({
          merchantId: this.$_selectedMerchantId,
          merchantCommunicationSources
        });
      },

      async resetToDefault() {
        this.toggleLoadingState({ state: 'isResettingDefault', value: true });

        try {
          const payload = this.forms.map(source => ({
            ...source,
            value: this.defaultCommunicationSources.find((defaultSource) => {
                const isSameTemplateType = source.templateTypeId === defaultSource.templateTypeId;
                const isSameMethodType = source.methodTypeName === defaultSource.methodTypeName;
                return isSameTemplateType && isSameMethodType;
              })?.value
          }));
          await this.updateMerchantCommunicationSources(payload);

          this.setFormData();

          this.$_onRequestSuccess({
            toastOptions: {
              message: 'Communication sources have been successfully reset!'
            }
          });
        }

        catch (error) {
          this.$_onRequestError({
            error,
            toastOptions: {
              message: 'There was an error resetting your communication settings'
            }
          });
        }

        finally {
          this.toggleLoadingState({ state: 'isResettingDefault', value: false });
        }
      },

      getCommunicationSourcesToCreateAndUpdate() {
        return {
          communicationSourcesToCreate: this.forms.filter(source => !source.merchantId),
          communicationSourcesToUpdate: this.forms.filter(source => source.merchantId && source.isDirty)
        };
      }
    }
  };
</script>

<style lang="sass" scoped>
  .communications-input-row
    display: grid
    gap: 1.5rem
    grid-template-columns: 300px 1fr 1fr
    align-items: center

    &:not(:last-child)
      margin-bottom: 3rem

  @media (max-width: $desktop)
    .communications-input-row
      grid-template-columns: 1fr
</style>
