import { Model } from '@vuex-orm/core';
import http from '@/services/http';
import { parseJson } from '@/helpers/misc';
import { camelCase } from 'change-case';



export default class RawExternalOrder extends Model {
  static entity = 'rawExternalOrders'



  // FIELDS //////////////////////
  static fields() {
    return {
      id: this.attr(''),
      accountId: this.attr(''),
      merchantId: this.attr(''),
      orderDetailsJsonBody: this.attr('', (value) => {
        const parsedValue = parseJson(value);
        return Object.entries(parsedValue).reduce((obj, [key, val]) => {
          obj[camelCase(key)] = val;
          return obj;
        }, {});
      }),
      // OrderId will be null when unclaimed
      orderId: this.attr(''),
      posOrderKey: this.attr(''),
      receiptToken: this.attr(''),
      storeId: this.attr(''),
      ticketNumber: this.attr(''),
      createdAt: this.attr(''),
      updatedAt: this.attr('')
    };
  }

  // STATE //////////////////////
  static state() {
    return {
      fetching: false
    };
  }

  static $state() {
    return this.store().state.entities.rawExternalOrders;
  }


  // ACTIONS //////////////////////
  static async searchRawExternalOrders({ receiptToken, ticketNumber }) {
    try {
      this.commit((state) => {
        state.fetching = true;
      });

      const merchantId = this.store().state.entities.merchants.selectedMerchantId;

      const query = receiptToken ? `?receipt_token=${receiptToken}` : `?ticket_number=${ticketNumber}`;

      const { data } = await http.get(`merchants/${merchantId}/raw_external_orders${receiptToken || ticketNumber ? query : ''}`);

      this.create({
        data: data.rawExternalOrders
      });
    }

    catch (error) {
      throw error;
    }

    finally {
      this.commit((state) => {
        state.fetching = false;
      });
    }
  }
}
