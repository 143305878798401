var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('validated-form',{ref:"form",attrs:{"form-id":"addEditOfferNotification","auto-focus":""},on:{"valid-submit":_vm.handleSubmit}},[_c('panel',{attrs:{"title":"Offer Expiration Notifications","subtitle":"Configure when and how many times guests are notified about expiring offers","loading":_vm.isFetching},scopedSlots:_vm._u([{key:"buttons",fn:function(){return [_c('b-button',{staticClass:"is-bold",attrs:{"rounded":"","size":"is-medium","native-type":"submit","type":"is-primary","loading":_vm.isSubmitting}},[_vm._v(" Save ")])]},proxy:true}])},[_c('p',{staticClass:"label"},[_vm._v(" How many days ahead should guests be notified of expiring offers? ")]),(!_vm.form.length)?_c('b-message',{staticClass:"is-compact has-shadow is-inline-block",attrs:{"type":"is-primary","icon":"info-square","has-icon":""}},[_vm._v(" With no notifications configured guests will not be notified of expiring offers ")]):_c('div',{staticClass:"is-flex-column gap-sm mar-b-lg"},_vm._l((_vm.form),function(offerNotification,index){return _c('validation-provider',{key:offerNotification.guid,staticClass:"is-flex gap-sm",attrs:{"vid":offerNotification.guid,"name":"Days","rules":{
          required: true,
          max_value: 999,
          min_value: 1,
          excluded: _vm.form.slice(0, index).concat( _vm.form.slice(index + 1)
          ).map(function (x) { return _vm.moment.duration(x.expirationLeadPeriod).asDays(); })
        },"custom-messages":{
          excluded: 'The Days field must be unique'
        }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var errors = ref.errors;
return [_c('b-field',{staticClass:"is-marginless",attrs:{"message":errors.length ? errors : null,"type":{ 'is-danger': !!errors.length }}},[_c('b-input',{staticStyle:{"z-index":"1"},attrs:{"value":_vm.form[index].expirationLeadPeriod && _vm.moment.duration(_vm.form[index].expirationLeadPeriod).asDays(),"type":"number","placeholder":"0"},on:{"input":function (day) { return _vm.updateExpirationLeadPeriod(day, index); }}}),_c('p',{staticClass:"control"},[_c('span',{staticClass:"has-text-weight-bold button is-static"},[_vm._v("Days")])])],1),_c('b-button',{staticClass:"is-transparent",on:{"click":function($event){return _vm.removeNotification(index)}}},[_c('b-icon',{attrs:{"icon":"trash-alt","type":"is-danger"}})],1)]}}],null,true)})}),1),_c('div',{staticClass:"buttons"},[_c('b-button',{attrs:{"icon-left":"plus","type":"is-primary is-light"},on:{"click":_vm.addNotification}},[_vm._v(" Notification ")]),(_vm.form.length)?_c('b-button',{attrs:{"icon-left":"trash-alt","type":"is-danger is-light","outlined":""},on:{"click":_vm.removeAllNotifications}},[_vm._v(" Clear All Notifications ")]):_vm._e()],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }