import Vue from 'vue';
import Vuex from 'vuex';
import VuexORM from '@vuex-orm/core';

// modules
import session from '@/store/modules/session';
import formStore from '@/store/modules/formStore';
import orderExpo from '@/store/modules/orderExpo';
import portalSystemFeatures from '@/store/modules/portalSystemFeatures';
import backendSearch from '@/store/modules/backendSearch';
import websockets from '@/store/modules/websockets';
import expoNotifications from '@/store/modules/expoNotifications';
import mainMenu from '@/store/modules/mainMenu';

// Vuex ORM models
import AccountSavedPayment from './classes/AccountSavedPayment';
import AppCodeType from './classes/AppCodeType';
import Campaign from './classes/Campaign';
import CampaignsOffer from './classes/CampaignsOffer';
import CampaignsSegmentation from './classes/CampaignsSegmentation';
import CardType from './classes/CardType';
import Category from './classes/Category';
import CategoryItem from './classes/CategoryItem';
import CategorySchedule from './classes/CategorySchedule';
import CommunicationTemplateType from './classes/CommunicationTemplateType';
import ConnectedAccountType from './classes/ConnectedAccountType';
import ContentType from './classes/ContentType';
import ContentTypeProperty from './classes/ContentTypeProperty';
import ConversionThreshold from './classes/ConversionThreshold';
import Currency from './classes/Currency';
import DeliveryFeeType from './classes/DeliveryFeeType';
import DeliveryService from './classes/DeliveryService';
import DeliveryServiceChargeType from './classes/DeliveryServiceChargeType';
import Department from './classes/Department';
import EarnRule from './classes/EarnRule';
import Employee from './classes/Employee';
import ExpoOrder from './classes/ExpoOrder';
import ExternalLinkType from './classes/ExternalLinkType';
import FeeType from './classes/FeeType';
import FulfillmentType from './classes/FulfillmentType';
import GiftCard from './classes/GiftCard';
import GiftCardTransaction from './classes/GiftCardTransaction';
import GiftCardTransactionType from './classes/GiftCardTransactionType';
import InAppMessageType from './classes/InAppMessageType';
import Item from './classes/Item';
import ItemImage from './classes/ItemImage';
import ItemSchedule from './classes/ItemSchedule';
import KdsOrder from './classes/KdsOrder';
import LocationType from './classes/LocationType';
import LocationServiceType from './classes/LocationServiceType';
import LoyaltyProgram from './classes/LoyaltyProgram';
import LoyaltyProgramTier from './classes/LoyaltyProgramTier';
import LoyaltyProvider from './classes/LoyaltyProvider';
import MaintenanceCode from './classes/MaintenanceCode';
import MappingStoreSublocation from './classes/MappingStoreSublocation';
import MembershipTierBadgeSource from './classes/MembershipTierBadgeSource';
import MenuAttributeType from './classes/MenuAttributeType';
import MenuCategoryUpsell from './classes/MenuCategoryUpsell';
import MenuAttribute from './classes/MenuAttribute';
import MenuItemAttribute from './classes/MenuItemAttribute';
import MenuItemModifierGroupItem from './classes/MenuItemModifierGroupItem';
import MerchantMenuAttribute from './classes/MerchantMenuAttribute';
import MenuItemPlatformType from './classes/MenuItemPlatformType';
import MenuItemModifierImage from './classes/MenuItemModifierImage';
import MenuItemUpsell from './classes/MenuItemUpsell';
import MenuType from './classes/MenuType';
import MenuTypeSchedule from './classes/MenuTypeSchedule';
import Merchant from './classes/Merchant';
import MerchantAppSetting from './classes/MerchantAppSetting';
import MerchantAppSettingResource from './classes/MerchantAppSettingResource';
import MerchantCommunicationSource from './classes/MerchantCommunicationSource';
import MerchantConnectedAccountType from './classes/MerchantConnectedAccountType';
import MerchantContact from './classes/MerchantContact';
import MerchantContent from './classes/MerchantContent';
import MerchantContentMetadata from './classes/MerchantContentMetadata';
import MerchantDefaultNotificationPreference from './classes/MerchantDefaultNotificationPreference';
import MerchantDeliveryService from './classes/MerchantDeliveryService';
import MerchantFaqContent from './classes/MerchantFaqContent';
import MerchantFaqHeader from './classes/MerchantFaqHeader';
import MerchantFaqSection from './classes/MerchantFaqSection';
import MerchantFee from './classes/MerchantFee';
import MerchantFooterDetail from './classes/MerchantFooterDetail';
import MerchantGiftCardConfiguration from './classes/MerchantGiftCardConfiguration';
import MerchantGiftCardDesign from './classes/MerchantGiftCardDesign';
import MerchantGiftCardAmount from './classes/MerchantGiftCardAmount';
import MerchantGiftCardThresholdAmount from './classes/MerchantGiftCardThresholdAmount';
import MerchantInAppMessage from './classes/MerchantInAppMessage';
import MerchantLink from './classes/MerchantLink';
import MerchantLinkHeader from './classes/MerchantLinkHeader';
import MerchantLocationService from './classes/MerchantLocationService';
import MerchantLoyaltyMembership from './classes/MerchantLoyaltyMembership';
import MerchantLoyaltyProviderConfiguration from './classes/MerchantLoyaltyProviderConfiguration';
import MerchantMaintenanceInfo from './classes/MerchantMaintenanceInfo';
import MerchantMenuTypeConfiguration from './classes/MerchantMenuTypeConfiguration';
import MerchantOption from './classes/MerchantOption';
import MerchantOptionsCheckout from './classes/MerchantOptionsCheckout';
import MerchantOptionsEmv from './classes/MerchantOptionsEmv';
import MerchantOptionsLocation from './classes/MerchantOptionsLocation';
import MerchantOptionsLoyalty from './classes/MerchantOptionsLoyalty';
import MerchantOptionsOpd from './classes/MerchantOptionsOpd';
import MerchantOptionsType from './classes/MerchantOptionsType';
import MerchantOrderMode from './classes/MerchantOrderMode';
import MerchantPaymentGateway from './classes/MerchantPaymentGateway';
import MerchantPushProvider from './classes/MerchantPushProvider';
import MerchantReceiptInstruction from './classes/MerchantReceiptInstruction';
import MerchantTermsOfService from './classes/MerchantTermsOfService';
import MerchantTicketNumberInstruction from './classes/MerchantTicketNumberInstruction';
import MerchantTippingOption from './classes/MerchantTippingOption';
import MerchantType from './classes/MerchantType';
import Modifier from './classes/Modifier';
import ModifierGroup from './classes/ModifierGroup';
import Offer from './classes/Offer';
import OfferAllocation from './classes/OfferAllocation';
import OfferDistribution from './classes/OfferDistribution';
import OfferDistributionCode from './classes/OfferDistributionCode';
import OfferNotification from './classes/OfferNotification';
import OpdOrder from './classes/OpdOrder';
import Order from './classes/Order';
import OrderDashboardColumn from './classes/OrderDashboardColumn';
import OrderMode from './classes/OrderMode';
import OrderSummary from './classes/OrderSummary';
import PayAtTableAuthenticationType from './classes/PayAtTableAuthenticationType';
import PaymentGateway from './classes/PaymentGateway';
import PaymentTransaction from './classes/PaymentTransaction';
import PlatformType from './classes/PlatformType';
import PosConfiguration from './classes/PosConfiguration';
import PosDiscountConfiguration from './classes/PosDiscountConfiguration';
import PosDiscountStoreConfiguration from './classes/PosDiscountStoreConfiguration';
import PosMenuCategory from './classes/PosMenuCategory';
import PosMenuItem from './classes/PosMenuItem';
import PosTypeOption from './classes/PosTypeOption';
import PushProviderType from './classes/PushProviderType';
import RawExternalOrder from './classes/RawExternalOrder';
import RegionMenu from './classes/RegionMenu';
import RegionMenuCategory from './classes/RegionMenuCategory';
import RegionMenuItem from './classes/RegionMenuItem';
import RegionMenuItemModifier from './classes/RegionMenuItemModifier';
import RegisteredGuest from './classes/RegisteredGuest';
import Role from './classes/Role';
import Segmentation from './classes/Segmentation';
import SegmentationSignature from './classes/SegmentationSignature';
import SsoProvider from './classes/SsoProvider';
import Store from './classes/Store';
import StoreAttribute from './classes/StoreAttribute';
import StoreCommunication from './classes/StoreCommunication';
import StoreDeliveryService from './classes/StoreDeliveryService';
import StoreDeliveryServiceSchedule from './classes/StoreDeliveryServiceSchedule';
import StoreHoursOverride from './classes/StoreHoursOverride';
import StoreItem from './classes/StoreItem';
import StoreMappingValidation from './classes/StoreMappingValidation';
import StoreMenuCategorySchedule from './classes/StoreMenuCategorySchedule';
import StoreMenuItemSchedule from './classes/StoreMenuItemSchedule';
import StoreMenuTypeSchedule from './classes/StoreMenuTypeSchedule';
import StoreModifier from './classes/StoreModifier';
import StoreOrderLimit from './classes/StoreOrderLimit';
import StoreOrderLimitSchedule from './classes/StoreOrderLimitSchedule';
import StorePickupInstruction from './classes/StorePickupInstruction';
import StoreReceiptLocation from './classes/StoreReceiptLocation';
import StoreTenderType from './classes/StoreTenderType';
import Survey from './classes/Survey';
import SurveyQuestion from './classes/SurveyQuestion';
import TippingMethodType from './classes/TippingMethodType';
import TippingOptionType from './classes/TippingOptionType';
import UpsellDisplayMode from './classes/UpsellDisplayMode';
import User from './classes/User';
import UserRole from './classes/UserRole';
import UserValidation from './classes/UserValidation';

Vue.use(Vuex);


// Create a new instance of Database.
const database = new VuexORM.Database();

// Register Models to Database. // TEST
database.register(AccountSavedPayment);
database.register(AppCodeType);
database.register(Campaign);
database.register(CampaignsOffer);
database.register(CampaignsSegmentation);
database.register(CardType);
database.register(Category);
database.register(CategoryItem);
database.register(CategorySchedule);
database.register(CommunicationTemplateType);
database.register(ConnectedAccountType);
database.register(ContentType);
database.register(ContentTypeProperty);
database.register(ConversionThreshold);
database.register(Currency);
database.register(DeliveryFeeType);
database.register(DeliveryService);
database.register(DeliveryServiceChargeType);
database.register(Department);
database.register(EarnRule);
database.register(Employee);
database.register(ExpoOrder);
database.register(ExternalLinkType);
database.register(FeeType);
database.register(FulfillmentType);
database.register(GiftCard);
database.register(GiftCardTransaction);
database.register(GiftCardTransactionType);
database.register(InAppMessageType);
database.register(Item);
database.register(ItemImage);
database.register(ItemSchedule);
database.register(KdsOrder);
database.register(LocationType);
database.register(LocationServiceType);
database.register(LoyaltyProgram);
database.register(LoyaltyProgramTier);
database.register(LoyaltyProvider);
database.register(MaintenanceCode);
database.register(MappingStoreSublocation);
database.register(MembershipTierBadgeSource);
database.register(MenuAttributeType);
database.register(MenuCategoryUpsell);
database.register(MenuAttribute);
database.register(MenuItemAttribute);
database.register(MenuItemModifierGroupItem);
database.register(MerchantMenuAttribute);
database.register(MenuItemPlatformType);
database.register(MenuItemModifierImage);
database.register(MenuItemUpsell);
database.register(MenuType);
database.register(MenuTypeSchedule);
database.register(Merchant);
database.register(MerchantAppSetting);
database.register(MerchantAppSettingResource);
database.register(MerchantCommunicationSource);
database.register(MerchantConnectedAccountType);
database.register(MerchantContact);
database.register(MerchantContent);
database.register(MerchantContentMetadata);
database.register(MerchantDefaultNotificationPreference);
database.register(MerchantDeliveryService);
database.register(MerchantFaqContent);
database.register(MerchantFaqHeader);
database.register(MerchantFaqSection);
database.register(MerchantFee);
database.register(MerchantFooterDetail);
database.register(MerchantGiftCardConfiguration);
database.register(MerchantGiftCardDesign);
database.register(MerchantGiftCardAmount);
database.register(MerchantGiftCardThresholdAmount);
database.register(MerchantInAppMessage);
database.register(MerchantLink);
database.register(MerchantLinkHeader);
database.register(MerchantLocationService);
database.register(MerchantLoyaltyMembership);
database.register(MerchantLoyaltyProviderConfiguration);
database.register(MerchantMaintenanceInfo);
database.register(MerchantMenuTypeConfiguration);
database.register(MerchantOption);
database.register(MerchantOptionsCheckout);
database.register(MerchantOptionsEmv);
database.register(MerchantOptionsLocation);
database.register(MerchantOptionsLoyalty);
database.register(MerchantOptionsOpd);
database.register(MerchantOptionsType);
database.register(MerchantOrderMode);
database.register(MerchantPaymentGateway);
database.register(MerchantPushProvider);
database.register(MerchantReceiptInstruction);
database.register(MerchantTermsOfService);
database.register(MerchantTicketNumberInstruction);
database.register(MerchantTippingOption);
database.register(MerchantType);
database.register(Modifier);
database.register(ModifierGroup);
database.register(Offer);
database.register(OfferAllocation);
database.register(OfferDistribution);
database.register(OfferDistributionCode);
database.register(OfferNotification);
database.register(OpdOrder);
database.register(Order);
database.register(OrderDashboardColumn);
database.register(OrderMode);
database.register(OrderSummary);
database.register(PayAtTableAuthenticationType);
database.register(PaymentGateway);
database.register(PaymentTransaction);
database.register(PlatformType);
database.register(PosConfiguration);
database.register(PosDiscountConfiguration);
database.register(PosDiscountStoreConfiguration);
database.register(PosMenuCategory);
database.register(PosMenuItem);
database.register(PosTypeOption);
database.register(PushProviderType);
database.register(RawExternalOrder);
database.register(RegionMenu);
database.register(RegionMenuCategory);
database.register(RegionMenuItem);
database.register(RegionMenuItemModifier);
database.register(RegisteredGuest);
database.register(Role);
database.register(Segmentation);
database.register(SegmentationSignature);
database.register(SsoProvider);
database.register(Store);
database.register(StoreAttribute);
database.register(StoreCommunication);
database.register(StoreDeliveryService);
database.register(StoreDeliveryServiceSchedule);
database.register(StoreHoursOverride);
database.register(StoreItem);
database.register(StoreMappingValidation);
database.register(StoreMenuCategorySchedule);
database.register(StoreMenuItemSchedule);
database.register(StoreMenuTypeSchedule);
database.register(StoreModifier);
database.register(StoreOrderLimit);
database.register(StoreOrderLimitSchedule);
database.register(StorePickupInstruction);
database.register(StoreReceiptLocation);
database.register(StoreTenderType);
database.register(Survey);
database.register(SurveyQuestion);
database.register(TippingMethodType);
database.register(TippingOptionType);
database.register(UpsellDisplayMode);
database.register(User);
database.register(UserRole);
database.register(UserValidation);


const store = new Vuex.Store({
  // We only enable strict mode for development due to slight performance hit
  strict: process.env.NODE_ENV !== 'production',

  // register Vuex modules
  modules: {
    session,
    formStore,
    orderExpo,
    portalSystemFeatures,
    websockets,
    expoNotifications,
    backendSearch,
    mainMenu
  },

  // register database through Vuex ORM.
  plugins: [VuexORM.install(database)] // TEST
});

export const entityNames = database.entities?.map(entity => entity.name);

export default store;
