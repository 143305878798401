<template>
  <panel title="In-App Messages" no-padding>
    <searchable-table
      :table-data="inAppMessages"
      :search="{
        placeholder: 'Search by In-App Message name',
        keys: ['name']
      }"
      :filters="[
        {
          placeholder: 'Type',
          key: 'inAppMessageTypeId',
          options: inAppMessageTypes.map(type => ({
            name: type.name,
            value: type.id
          }))
        },
        {
          placeholder: 'Status',
          key: 'status',
          multiple: true,
          default: [statuses.LIVE, statuses.SCHEDULED, statuses.DRAFT],
          options: Object.values(statuses).map(status => ({
            name: capitalize(status),
            value: status
          }))
        },
      ]"
    >
      <template #optional-actions>
        <b-button
          type="is-primary"
          icon-left="plus"
          @click="openInAppMessageModal()"
        >
          In-App Message
        </b-button>
      </template>
      <template #default="{ filteredData }">
        <b-table
          :data="isFetching ? [] : filteredData"
          class="is-middle-aligned"
          :mobile-cards="false"
          custom-detail-row
          scrollable
          detail-key="publicId"
          data-test-id="in-app-messages-table"
          :default-sort="['messageType', 'desc']"
        >
          <template slot="empty">
            <empty-table-loader
              message="No In-App Messages Found..."
              :loading="isFetching"
            />
          </template>

          <b-table-column
            v-slot="{ row }"
            sortable
            field="title"
            label="Name"
            cell-class="no-wrap-text"
          >
            <span
              class="link"
              @click="openInAppMessageModal(row)"
            >
              {{ row.title }}
            </span>
          </b-table-column>

          <b-table-column
            v-slot="{ row }"
            sortable
            field="bodyContent"
            label="Description"
          >
            {{ row.bodyContent }}
          </b-table-column>

          <b-table-column
            v-slot="{ row }"
            sortable
            field="messageType"
            label="Message Type"
            centered
            header-class="no-wrap-text"
          >
            {{ row.messageTypeName }}
          </b-table-column>

          <b-table-column
            v-slot="{ row }"
            sortable
            field="status"
            label="Status"
            centered
          >
            <b-tag :type="tagTypes[row.status]" class="is-outlined is-light">
              {{ $changeCase.capitalCase(row.status) }}
            </b-tag>
          </b-table-column>

          <b-table-column
            v-slot="{ row }"
            centered
            cell-class="actions"
            label="Actions"
            width="1"
            field="Actions"
          >
            <dropdown-menu position="bottom-end" :mobile-modal="false">
              <b-button slot="trigger" class="is-transparent" type="is-white">
                <b-icon icon="ellipsis-v" pack="far" size="is-small" />
              </b-button>

              <b-dropdown-item @click="openInAppMessageModal(row)">
                <b-icon :icon="row.status === statuses.EXPIRED ? 'eye' : 'pencil'" class="mar-r-sm" size="is-small" />
                {{ row.status === statuses.EXPIRED ? 'View' : 'Edit' }} Message
              </b-dropdown-item>

              <b-dropdown-item v-if="row.status !== statuses.EXPIRED" @click="confirmTogglePublish(row)">
                <b-icon :icon="row.status === statuses.DRAFT ? 'message' : 'message-slash'" class="mar-r-sm" size="is-small" />
                {{ row.status === statuses.DRAFT ? 'Publish' : 'Unpublish' }} Message
              </b-dropdown-item>
              <hr class="dropdown-divider">
              <b-dropdown-item
                class="is-danger"
                @click="confirmDelete(row)"
              >
                <b-icon
                  type="is-danger"
                  icon="trash-alt"
                  class="mar-r-sm"
                  size="is-small"
                />
                {{ row.status === statuses.SCHEDULED ? 'Cancel' : 'Delete' }} Message
              </b-dropdown-item>
            </dropdown-menu>
          </b-table-column>
        </b-table>
      </template>
    </searchable-table>
  </panel>
</template>

<script>
  import capitalize from '@/filters/capitalize';

  import { statuses } from '@/constants/merchantInAppMessages';

  import InAppMessageType from '@/store/classes/InAppMessageType';
  import MerchantInAppMessage from '@/store/classes/MerchantInAppMessage';

  import InAppMessageModal from '../in-app-messages/in-app-message-modal.vue';


  export default {
    name: 'InAppMessagesTab',

    props: {
      merchantHasMobileAppConfigured: {
        type: Boolean,
        default: false
      }
    },

    data: () => ({
      tagTypes: {
        [statuses.LIVE]: 'is-success',
        [statuses.SCHEDULED]: 'is-warning',
        [statuses.EXPIRED]: 'is-light'
      },
      statuses
    }),

    computed: {
      inAppMessages() {
        return MerchantInAppMessage.all().map(inAppMessage => ({
          ...inAppMessage,
          messageTypeName: this.getMessageTypeName(inAppMessage.inAppMessageTypeId)
        }));
      },

      inAppMessageTypes() {
        return InAppMessageType.all()?.map(type => ({
          id: type.id,
          name: type.name,
          ...InAppMessageType.metadata(type.name)
        })).filter(type => (
          this.merchantHasMobileAppConfigured ? true : !type.mobileOnly
        ));
      },

      isFetching() {
        return MerchantInAppMessage.$state().fetching || InAppMessageType.$state().fetching;
      }
    },

    created() {
      this.onCreated();
    },

    methods: {
      capitalize,
      async onCreated() {
        await Promise.all([
          this.fetchInAppMessages(),
          this.fetchInAppMessageTypes()
        ]);
      },

      async fetchInAppMessages() {
        try {
          await MerchantInAppMessage.fetchMerchantInAppMessages();
        }
        catch (error) {
          this.$_onRequestError({
            error,
            toastOptions: {
              message: 'There was an error fetching your in-app messages.'
            }
          });
        }
      },

      async fetchInAppMessageTypes() {
        try {
          await InAppMessageType.fetchInAppMessageTypes();
        }
        catch (error) {
          this.$_onRequestError({
            error,
            toastOptions: {
              message: 'There was an error fetching the in-app message types.'
            }
          });
        }
      },

      confirmDelete(inAppMessage) {
        this.$buefy.dialog.confirm({
          title: 'Delete In-App Message',
          message: `Are you sure you want to delete the ${inAppMessage.title} In-App Message?`,
          hasIcon: true,
          confirmText: 'Delete',
          icon: 'trash-alt',
          type: 'is-danger',
          onConfirm: () => this.deleteInAppMessage(inAppMessage.publicId)
        });
      },

      async deleteInAppMessage(inAppMessagePublicId) {
        try {
          await MerchantInAppMessage.deleteMerchantInAppMessage(inAppMessagePublicId);

          this.$_onRequestSuccess({
            toastOptions: {
              message: 'Successfully deleted in-app message!'
            }
          });
        }
        catch (error) {
          this.$_onRequestError({
            error,
            toastOptions: {
              message: 'There was an error deleting this in-app message'
            }
          });
        }
      },

      getMessageTypeName(inAppMessageTypeId) {
        return this.inAppMessageTypes.find(type => type.id === inAppMessageTypeId)?.name || 'Unknown';
      },

      openInAppMessageModal(inAppMessage = {}) {
        this.$buefy.modal.open({
          parent: this,
          component: InAppMessageModal,
          hasModalCard: true,
          trapFocus: true,
          canCancel: false,
          fullScreen: true,
          props: {
            inAppMessage,
            merchantHasMobileAppConfigured: this.merchantHasMobileAppConfigured
          }
        });
      },

      confirmTogglePublish(inAppMessage) {
        this.$buefy.dialog.confirm({
          title: `${inAppMessage.isPublished ? 'Unpublish' : 'Publish'} In-App Message`,
          message: `Are you sure you want to ${inAppMessage.isPublished ? 'unpublish' : 'publish'} the ${inAppMessage.title} In-App Message?`,
          hasIcon: true,
          confirmText: `${inAppMessage.isPublished ? 'Unpublish' : 'Publish'}`,
          icon: inAppMessage.isPublished ? 'message-slash' : 'message',
          onConfirm: () => this.togglePublish(inAppMessage)
        });
      },

      async togglePublish(inAppMessage) {
        try {
          delete inAppMessage.primaryImageUrl;
          inAppMessage.isPublished = !inAppMessage.isPublished;
          await MerchantInAppMessage.updateMerchantInAppMessage(inAppMessage);

          this.$_onRequestSuccess({
            toastOptions: {
              message: 'Successfully updated your in-app message!'
            },
            options: { closeParent: true }
          });
        }

        catch (error) {
          this.$_onRequestError({
            toastOptions: {
              message: 'There was an error updating your in-app message'
            },
            error
          });
          throw error;
        }
      }
    }
  };
</script>

<style lang='sass' scoped>
::v-deep
  .tag
    width: 6rem
    font-weight: 700
</style>
