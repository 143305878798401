<template>
  <validated-form
    :ref="formRef"
    :disabled="!$can('update', 'MenuItemModifier' ) || !$_menuPermissions.EDIT_RESOURCE"
    form-id="modifierDetailsForm"
    :class="(!$can('update', 'MenuItemModifier') || !$_menuPermissions.EDIT_RESOURCE) && 'pad-b-xs'"
  >
    <p class="subtitle is-5 mar-b is-flex justify-between">
      Modifier Details
      <b-tag
        v-if="mode === 'update' && modifierForm.isDefault"
        type="is-success is-light is-outlined"
        icon="check"
      >
        Selected by default
      </b-tag>
    </p>

    <b-field
      v-tippy="{
        content: 'There is already a Single Select modifier for this modifier group.',
        placement: 'top',
        maxWidth: 300,
        delay: [150, 0],
        onShow: () => hasGroupSingleSelectModifier
      }"
    >
      <check-button
        v-model="isSingleSelect"
        :disabled="!$can('update', 'MenuItemModifier') || !$_menuPermissions.EDIT_RESOURCE || hasGroupSingleSelectModifier"
        compact
        class="is-full-width"
        label="Single Select"
        sublabel="If this modifier is selected, no other modifier from this group can be selected"
      />
    </b-field>

    <validated-text-input
      v-model="modifierName"
      :has-icon="false"
      label="Name"
      name="name"
      maxlength="255"
      :has-counter="true"
      rules="required"
      type="text"
    />

    <validated-input
      label="Calories"
      name="caloriesLabel"
      tooltip="If there is only one value, use the “low” field"
      tooltip-placement="right"
    />
    <div class="is-grid col-3 gap-md mar-b-md">
      <validated-text-input
        v-model="modifierForm.caloriesLow"
        label="Low"
        name="caloriesLow"
        type="number"
        placeholder="Ex: 20"
        :rules="{
          required: !!modifierForm.caloriesHigh,
          ...(modifierForm.caloriesHigh ? { lessThan: { target: modifierForm.caloriesHigh } } : {})
        }"
        label-position="on-border"
      />

      <validated-text-input
        v-model="modifierForm.caloriesHigh"
        label="High"
        name="caloriesHigh"
        type="number"
        placeholder="Ex: 100"
        :rules="{
          ...(modifierForm.caloriesLow ? { greaterThan: { target: modifierForm.caloriesLow } } : {})
        }"
        label-position="on-border"
      />
      <validated-text-input
        v-model="modifierForm.nutritionalUnits"
        label="Units"
        name="nutritionalUnits"
        type="text"
        placeholder="Ex: calories"
        :rules="{ required: !!modifierForm.caloriesLow || !!modifierForm.caloriesHigh }"
        label-position="on-border"
      />
    </div>

    <image-upload
      v-slot="{imagePath}"
      v-model="localModifierImage.imageFile"
      :accepted-types="['png', 'webp', 'jpeg', 'jpg', 'svg']"
      :image-size-warning-width="512"
      :image-size-warning-height="512"
      :image-url="localModifierImage && localModifierImage.fullUrl"
      :loading="isFetchingImages"
      :disabled="!$can('update', 'MenuItemModifier')"
      delete-text="Delete Image"
      label="Modifier Image"
      show-delete-button
      show-clear-button
      is-full-width
      upload-button-text="Select Modifier Image"
      restrict-file-size
      @delete-image="deleteImage(localModifierImage.id)"
    >
      <img v-if="imagePath" :src="imagePath" alt="Modifier Image">
    </image-upload>

    <b-message
      v-if="$can('update', 'MenuItemModifier') && localModifierImage.id"
      size="is-small"
      class="is-compact"
    >
      You can replace an image above by dragging a new image over or clicking on the old one
    </b-message>

    <hr>

    <fieldset :disabled="isSingleSelect" :class="isSingleSelect && 'is-semi-transparent'">
      <p class="subtitle is-5 is-marginless">
        Modifier Quantity
      </p>
      <p class="is-size-7 has-text-grey mar-b-md">
        How many of this modifier can be selected
      </p>

      <validated-input
        horizontal
        class="left-aligned-label"
        label="Min. Required"
        name="minRequired"
        rules="required"
      >
        <b-numberinput
          v-model="modifierForm.minRequired"
          :editable="false"
          controls-position="compact"
          min="0"
          :max="modifierForm.maxAllowed > 0 ? modifierForm.maxAllowed : 10000"
        />
      </validated-input>

      <validated-input
        horizontal
        class="left-aligned-label"
        label="Max. Allowed"
        name="maxAllowed"
        :rules="{
          required: true,
          min_value: modifierForm.maxAllowed === -1 ? -1 : modifierForm.minRequired || 1
        }"
      >
        <numberinput-switch
          v-model="modifierForm.maxAllowed"
          switch-label="Unlimited"
          :disabled="!$can('update', 'MenuItemModifier') && !$_menuPermissions.EDIT_RESOURCE"
          :true-value="-1"
          :false-value="modifierForm.minRequired ? modifierForm.minRequired : 1"
          :min="modifierForm.minRequired || 1"
        />
      </validated-input>
    </fieldset>

    <hr>

    <p class="subtitle is-5 is-marginless">
      Availability
    </p>
    <p class="is-size-7 has-text-grey mar-b-lg">
      Optional dates to limit availability of this modifier
    </p>


    <validation-observer class="is-flex">
      <validated-input name="availabilityBeginDate" class="mar-r" label="Start Date">
        <b-datepicker
          v-model="startDate"
          :years-range="dys_ranges.startDate"
          placeholder="Now"
          icon="calendar-alt"
          position="is-top-right"
          :disabled="!$can('update', 'MenuItemModifier' ) || !$_menuPermissions.EDIT_RESOURCE"
          :events="endDate ? [{ date: endDate, type: 'is-danger' }] : []"
          indicators="bars"
          :focused-date="endDate"
          class="has-extra-shadow"
          @change-year="(year) => $_handleYearChange({ year, type: 'startDate' })"
        >
          <div class="buttons is-right">
            <b-button @click="modifierForm.availabilityBeginDate = null">Clear</b-button>
          </div>
        </b-datepicker>
      </validated-input>

      <validated-input
        name="availabilityEndDate"
        rules="dateTimeIsAfter:@availabilityBeginDate"
        label="End Date"
      >
        <b-datepicker
          v-model="endDate"
          :years-range="dys_ranges.endDate"
          placeholder="Forever"
          icon="calendar-alt"
          position="is-top-left"
          :disabled="!$can('update', 'MenuItemModifier' ) || !$_menuPermissions.EDIT_RESOURCE"
          :events="startDate ? [{ date: startDate, type: 'is-success' }] : []"
          indicators="bars"
          :focused-date="startDate"
          class="has-extra-shadow"
          @change-year="(year) => $_handleYearChange({ year, type: 'endDate' })"
        >
          <div class="buttons is-right">
            <b-button @click="modifierForm.availabilityEndDate = null">Clear</b-button>
          </div>
        </b-datepicker>
      </validated-input>
    </validation-observer>

    <!-- This was added in December of 2020, and never fully implemented. Documenting this because
         the original file is going away add-edit-modifier-modal.vue -> modifier-details-form.vue.
    <validated-text-input
      v-model="modifierForm.description"
      :disabled="_isNoneSelection"
      :has-icon="false"
      label="Name In Cart"
      name="description"
      rules="required"
      type="text"
      maxlength="50"
      :has-counter="false"
    /> -->
  </validated-form>
</template>

<script>
  import moment from 'moment-timezone';

  import confirmModalCloseMixin from '@/mixins/confirm-modal-close';
  import dynamicYearSelectMixin from '@/mixins/dynamic-year-select';
  import featurePermissionsMixin from '@/mixins/featurePermissions';
  import merchantMixin from '@/mixins/merchant';
  import multiFormChildProvider from '@/mixins/multiFormMixin/multiFormChildProvider';

  import MenuItemModifierImage from '@/store/classes/MenuItemModifierImage';
  import Modifier from '@/store/classes/Modifier';
  import ModifierGroup from '@/store/classes/ModifierGroup';


  export default {
    name: 'ModifierDetailsForm',

    mixins: [
      confirmModalCloseMixin,
      dynamicYearSelectMixin,
      featurePermissionsMixin,
      merchantMixin,
      multiFormChildProvider
    ],

    props: {
      modifier: {
        type: Object,
        required: true
      },

      modifierImage: {
        type: Object,
        required: true
      },

      modifierGroup: {
        type: Object,
        required: true
      },

      formRef: {
        type: String,
        required: true
      },

      mode: {
        type: String,
        required: true
      }
    },

    data() {
      return {
        modifierForm: {},
        localModifierImage: {},
        isLoading: false
      };
    },

    computed: {
      isFetchingImages() {
        return MenuItemModifierImage.$state().fetching;
      },

      hasGroupSingleSelectModifier() {
        const singleSelectModifier = ModifierGroup.singleSelectModifierByGroupId(this.modifierGroup.id);
        return !!singleSelectModifier && singleSelectModifier.id !== this.modifier.id;
      },

      isSingleSelect: {
        get() {
          return this.modifierForm.modifierTemplate === 'NoneModifierTemplate';
        },
        set(value) {
          this.modifierForm.modifierTemplate = value ? 'NoneModifierTemplate' : null;
        }
      },

      modifierName: {
        get() {
          return this.modifierForm.displayName;
        },
        set(value) {
          this.modifierForm.description = value;
          this.modifierForm.displayName = value;
        }
      },

      startDate: {
        get() {
          const dateString = moment.utc(this.modifierForm.availabilityBeginDate).format('MM-DD-YYYY');
          return this.modifierForm.availabilityBeginDate ? moment(dateString, 'MM-DD-YYYY').startOf('day').toDate() : null;
        },
        set(val) {
          const dateString = moment.utc(val).format('MM-DD-YYYY');
          this.modifierForm.availabilityBeginDate = moment.utc(dateString, 'MM-DD-YYYY').startOf('day').toISOString();
        }
      },

      endDate: {
        get() {
          const dateString = moment.utc(this.modifierForm.availabilityEndDate).format('MM-DD-YYYY');
          return this.modifierForm.availabilityEndDate ? moment(dateString, 'MM-DD-YYYY').endOf('day').toDate() : null;
        },
        set(val) {
          const dateString = moment.utc(val).format('MM-DD-YYYY');
          this.modifierForm.availabilityEndDate = moment.utc(dateString, 'MM-DD-YYYY').endOf('day').toISOString();
        }
      }
    },

    watch: {
      modifier: {
        immediate: true,
        handler(updatedModifier) {
          if (this.mode === 'update') {
            this.modifierForm = this.$clone(updatedModifier);
          }
        }
      }
    },

    async created() {
      await this.onCreated();
    },

    methods: {
      async onCreated() {
        this.modifierForm = this.$clone(this.modifier);
        this.localModifierImage = this.$clone(this.modifierImage);
      },

      async fetchModifierImage() {
        try {
          await MenuItemModifierImage.fetchModifierImage(this.modifier.id);
        }

        catch (error) {
          this.$_onRequestError({
            toastOptions: {
              message: 'Unable to get modifier image'
            },
            error
          });
        }
      },

      async deleteImage(imageId) {
        try {
          await MenuItemModifierImage.deleteImage(imageId);

          this.localModifierImage = this.$clone(this.modifierImage);

          this.$_onRequestSuccess({
            toastOptions: {
              message: 'Successfully deleted modifier image!'
            }
          });
        }

        catch (error) {
          this.$_onRequestError({
            toastOptions: {
              message: 'An error occured while deleting your image'
            },
            error
          });
        }
      },

      async saveImage(imageFile, modifierId) {
        try {
          await MenuItemModifierImage.addModifierImage({ modifierId, imageFile });
        }
        catch (error) {
          this.$_onRequestError({
            toastOptions: {
              message: 'Unable to add modifier image'
            },
            error
          });
        }
      },

      async fetchModifier() {
        try {
          await Modifier.fetchModifier(this.modifier.id);
        }
        catch (error) {
          this.$_onRequestError({
            toastOptions: {
              message: 'There was an error fetching the modifier'
            },
            error
          });
        }
      },

      async handleSubmit() {
        let _modifier = this.modifier;

        switch (this.mode) {
          case 'create':
            _modifier = await this.addModifier(this.modifierForm);
            break;

          case 'update':
            this.updateModifier();
            break;

          default:
            break;
        }

        const { imageFile } = this.localModifierImage;
        if (imageFile) {
          this.saveImage(imageFile, _modifier.id);
        }

        return _modifier;
      },

      async addModifier(modifier) {
        try {
          this.isLoading = true;
          const addedModifier = await Modifier.addModifier({ modifierGroupId: this.modifierGroup.id, modifier });

          return addedModifier;
        }

        catch (error) {
          this.$_onRequestError({
            error,
            toastOptions: {
              message: `Unable to add ${modifier.displayName}`
            }
          });
        }
        finally {
          this.isLoading = false;
        }
      },

      async updateModifier() {
        try {
          this.isLoading = true;
          await Modifier.updateModifier(this.modifierForm);
        }

        catch (error) {
          this.$_onRequestError({
            error,
            toastOptions: {
              message: `Unable to update ${this.modifierForm.displayName}`
            }
          });
        }

        finally {
          this.isLoading = false;
        }
      }
    }
  };
</script>

<style lang="sass" scoped>
  .modal-card
    max-width: 500px

    .search-container
      ::v-deep
        input
          border-radius: 4px 0 0 4px !important

        button
          border-radius: 0 4px 4px 0 !important

    .search-dropdown
      ::v-deep
        .dropdown-menu
          padding-top: 0

  .left-aligned-label
    @include left-aligned-horizontal-label(140px)

  ::v-deep
    .upload.control,
    .upload-draggable
      width: 100%

    .autocomplete
      a.dropdown-item
        padding-right: 1rem !important
</style>
