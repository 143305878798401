import http from '@/services/http';

export default async function getShortUrl({ type, id }) {
  try {
    const urlsByType = {
      employee: `employees/${id}/shortened_tip_url`,
      department: `departments/${id}/shortened_tip_url`
    };
    const { data } = await http.post(urlsByType[type]);
    return data.shortUrl.shortenedUrl;
  }
  catch (error) {
    throw error;
  }
}
