import menuResourceTypes from '@/constants/menuResourceTypes';

import Category from '@/store/classes/Category';


export default {
  namespaced: true,

  state: {
    selectedMerchant: null,
    menuPermissions: null,
    featurePermissions: null,
    activeMenuType: null,
    isRemovingFromMenuType: false,
    clonedItemIds: [],
    draggableAttributes: {
      animation: '200',
      ghostClass: 'ghost',
      handle: '.drag-handle',
      draggable: '.draggable'
    },
    // Vuex prefers flattened data structures, so each resource type needs its own
    // object for tracking open status.
    categoriesOpenedStatus: {},
    menuItemsOpenedStatus: {},
    modifierGroupsOpenedStatus: {},
    modifiersOpenedStatus: {}
  },

  getters: {
    categories: state => (
      Category.query()
        .where(category => (
          !state.activeMenuType || category.menuTypes.findIndex(mt => mt.id === state.activeMenuType.id) >= 0
        ))
        .orderBy('sortOrder')
        .all()
    )
  },

  mutations: {
    SET_MIXINS(state, { selectedMerchant, menuPermissions, featurePermissions }) {
      state.selectedMerchant = selectedMerchant;
      state.menuPermissions = menuPermissions;
      state.featurePermissions = featurePermissions;
    },

    SET_ACTIVE_MENU_TYPE(state, menuType) {
      state.activeMenuType = menuType;
    },

    SET_IS_REMOVING_FROM_MENU_TYPE(state, value) {
      state.isRemovingFromMenuType = value;
    },

    SET_CLONED_ITEM_IDS(state, itemIds) {
      state.clonedItemIds = itemIds;
    },

    SET_MENU_RESOURCE_OPEN_STATE(state, { resourceType, resourceId, value }) {
      // To ensure we have proper reactivity between the store and the components using
      // it, we need to re-assign the whole object when updating. If we only update the
      // single menu resource, the components computed property won't detect a change and
      // won't update the DOM accordinly

      if (resourceType === menuResourceTypes.CATEGORY) {
        state.categoriesOpenedStatus = {
          ...state.categoriesOpenedStatus,
          [resourceId]: value
        };
      }
      else if (resourceType === menuResourceTypes.MENU_ITEM) {
        state.menuItemsOpenedStatus = {
          ...state.menuItemsOpenedStatus,
          [resourceId]: value
        };
      }
      else if (resourceType === menuResourceTypes.MODIFIER_GROUP) {
        state.modifierGroupsOpenedStatus = {
          ...state.modifierGroupsOpenedStatus,
          [resourceId]: value
        };
      }
      else if (resourceType === menuResourceTypes.MODIFIER) {
        state.modifiersOpenedStatus = {
          ...state.modifiersOpenedStatus,
          [resourceId]: value
        };
      }
    },

    COLLAPSE_ALL_RESOURCES(state) {
      state.categoriesOpenedStatus = {};
      state.menuItemsOpenedStatus = {};
      state.modifierGroupsOpenedStatus = {};
      state.modifiersOpenedStatus = {};
    }
  },

  actions: {
    setMixins({ commit }, mixins) {
      commit('SET_MIXINS', mixins);
    },

    setActiveMenuType({ commit }, menuType) {
      commit('SET_ACTIVE_MENU_TYPE', menuType);
    },

    setIsRemovingFromMenuType({ commit }, value) {
      commit('SET_IS_REMOVING_FROM_MENU_TYPE', value);
    },

    setClonedItemIds({ commit }, itemIds) {
      commit('SET_CLONED_ITEM_IDS', itemIds);

      setTimeout(() => { // Weinstock originally put this code in 5 years ago to fix a "quirk"
        this.clonedItemIds = [];
      }, 3500);
    },

    setMenuResourceOpenState({ commit }, resourcePayload) {
      commit('SET_MENU_RESOURCE_OPEN_STATE', resourcePayload);
    },

    collapseAllResources({ commit }) {
      commit('COLLAPSE_ALL_RESOURCES');
    }
  }
};
