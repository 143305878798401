/* eslint-disable import/no-cycle */
import { Model } from '@vuex-orm/core';

// classes
import http from '@/services/http';

export default class MappingStoreSublocation extends Model {
  static entity = 'mappingStoreSublocations'



  // FIELDS //////////////////////
  static fields() {
    return {
      id: this.attr(''),
      storeId: this.attr(''),
      merchantId: this.attr(''),
      sublocationId: this.attr(''),
      createdAt: this.attr(''),
      createdSource: this.attr('')
    };
  }

  // STATE //////////////////////
  static state() {
    return {
      fetching: false,
      submitting: false
    };
  }

  static $state() {
    return this.store().state.entities.mappingStoreSublocations;
  }



  // ACTIONS //////////////////////
  static async fetchMappingStoreSublocations(storeId) {
    try {
      this.commit((state) => {
        state.fetching = true;
      });

      const response = await http.get(`stores/${storeId}/mapping_store_sublocations`);

      this.insert({
        data: response.data.mappingStoreSublocations
      });
    }
    catch (error) {
      throw error;
    }
    finally {
      this.commit((state) => {
        state.fetching = false;
      });
    }
  }

  static async addMappingStoreSublocation(storeId, record) {
    try {
      this.commit((state) => {
        state.submitting = true;
      });

      const response = await http.post(
        `stores/${storeId}/mapping_store_sublocations`,
        { mappingStoreSublocation: record }
      );

      this.insert({
        data: response.data.mappingStoreSublocation
      });
    }
    catch (error) {
      throw error;
    }
    finally {
      this.commit((state) => {
        state.submitting = false;
      });
    }
  }

  static async addMappingStoreSublocationRange(storeId, formData) {
    try {
      this.commit((state) => {
        state.submitting = true;
      });

      const response = await http.post(
        `stores/${storeId}/mapping_store_sublocations/bulk_create`,
        { mappingStoreSublocations: formData }
      );

      this.insert({
        data: response.data.mappingStoreSublocations
      });
    }
    catch (error) {
      throw error;
    }
    finally {
      this.commit((state) => {
        state.submitting = false;
      });
    }
  }

  static async updateMappingStoreSublocation(record) {
    try {
      this.commit((state) => {
        state.submitting = true;
      });
      const response = await http.put(`mapping_store_sublocations/${record.id}`, {
        mappingStoreSublocation: { sublocationId: record.sublocationId }
      });

      this.update({
        data: response.data.mappingStoreSublocation
      });
    }
    catch (error) {
      throw error;
    }
    finally {
      this.commit((state) => {
        state.submitting = false;
      });
    }
  }

  static async removeMappingStoreSublocation(mappingStoreSublocationId) {
    try {
      this.commit((state) => {
        state.submitting = true;
      });

      await http.delete(`mapping_store_sublocations/${mappingStoreSublocationId}`);

      this.delete(mappingStoreSublocationId);
    }
    catch (error) {
      throw error;
    }
    finally {
      this.commit((state) => {
        state.submitting = false;
      });
    }
  }
}
