<template>
  <div>
    <template v-if="filteredConstraints.length">
      <div
        v-for="(constraint, idx) in filteredConstraints"
        :key="`earnRuleConstraint${idx}`"
      >
        <item-group-review-row
          v-if="constraint.constraintType === constraintModels.SPECIFIED_ITEM_GROUP.type"
          :item-group="constraint.itemGroup"
          :minimum-qualifying-count="constraint.minimumQualifyingCount"
          :hide-bottom-border="idx === filteredConstraints.length - 1"
        />
        <location-row
          v-else-if="constraint.constraintType === constraintModels.MEMBER_PROXIMITY_LOCATION.type || constraint.constraintType === constraintModels.SALES_TRANSACTION_LOCATION.type"
          :stores="constraint.stores"
          :all-stores-selected="constraint.allStoresSelected"
          :allow-active-order-at-any-target-location="constraint.allowActiveOrderAtAnyTargetLocation"
          :hide-bottom-border="idx === filteredConstraints.length - 1"
        />
        <review-row
          v-else
          :title="constraint.title"
          :subtitle="constraint.subtitle"
          :hide-bottom-border="idx === filteredConstraints.length - 1"
        />
      </div>
    </template>
    <b-message
      v-else
      type="is-warning"
      class="is-compact has-shadow"
    >
      There are no constraints set for this earn rule
    </b-message>
  </div>
</template>

<script>
  import { capitalCase } from 'change-case';
  import { constraintModels } from '@/constants/earnRules';
  import { itemAttributes } from '@/constants/items';

  import Store from '@/store/classes/Store';

  import LocationRow from './location-row.vue';

  export default {
    name: 'ConstraintsCard',

    components: { LocationRow },

    props: {
      value: {
        type: Object,
        required: true
      }
    },

    data() {
      return {
        constraintModels
      };
    },

    computed: {
      filteredConstraints() {
        return this.value.earnRuleConstraints.filter(constraint => (
          constraint.constraintType !== constraintModels.DAY_OF_WEEK.type
          && constraint.constraintType !== constraintModels.TIME_OF_DAY.type
        )).map(constraint => ({
          ...constraint,
          ...this.mapConstraintToDisplay(constraint)
        }));
      }
    },

    methods: {
      capitalCase,

      mapConstraintToDisplay(constraint) {
        switch (constraint.constraintType) {
          case constraintModels.FULFILLMENT_TYPE.type: {
            const fulfillmentTypeDisplay = constraint.validFulfillmentTypes.map(type => type).join(', ');

            return {
              title: 'Avaliable Order Modes',
              subtitle: fulfillmentTypeDisplay
            };
          }

          case constraintModels.MAXIMUM_ORDER_AGE.type: {
            const durationDisplay = this.getDurationDisplay(constraint.maximumHourlyDuration);
            const applyToRetroClaimsDisplay = constraint.applyOnlyToRetroClaims ? '(only applies to retroactive claims)' : '';

            return {
              title: 'Maximum Order Age',
              subtitle: `${durationDisplay} ${applyToRetroClaimsDisplay}`.trim()
            };
          }

          case constraintModels.MINIMUM_PURCHASE.type: {
            const dollarsDisplay = this.getDollarsDisplay(constraint.minimum);
            const excludedItemAttributeDisplay = this.getExcludedItemAttributeDisplay(constraint.excludedItemAttributes);

            return {
              title: 'Minimum Purchase Amount',
              subtitle: `${dollarsDisplay} ${excludedItemAttributeDisplay}`.trim()
            };
          }

          case constraintModels.PAYMENT_METHOD.type: {
            const dollarsDisplay = this.getDollarsDisplay(constraint.minimumPaymentAmountFromSelectedMethods);
            const paymentMethodsDisplay = constraint.paymentMethods.map(method => capitalCase(method)).join(', ');
            const waiveDisplay = constraint.waiveMinimumAmountIfZeroingGiftCardBalance ? '(waived for insufficient balance)' : '';

            return {
              title: 'Required Payment Method',
              subtitle: `${dollarsDisplay} ${paymentMethodsDisplay} ${waiveDisplay}`.trim()
            };
          }

          case constraintModels.RETRO_CLAIM.type:
            return { title: 'Must Be Retroactive Claim', subtitle: 'Yes' };

          case constraintModels.SPECIFIED_ITEM_GROUP.type:
            // Item group-related constraints are unique, and thus have their own component to handle display.
            // We just need to provide the Item Group itself and the component will handle the rest.
            return {};

          case constraintModels.REFEREE_REQUIRED_PURCHASE.type: {
            const minimumDisplay = `${this.getDollarsDisplay(constraint.minimumSalesTransactionTotal)} minimum total`;
            const minimumBeforeDiscountDisplay = `${this.getDollarsDisplay(constraint.minimumSalesTransactionItemTotalBeforeDiscounts)} minimum before discounts`;

            return { title: 'Referree Must Place Order', subtitle: `${minimumDisplay}, ${minimumBeforeDiscountDisplay}` };
          }

          case constraintModels.REFEREE_VERIFICATION.type:
            return { title: 'Referree Must Verify Account', subtitle: 'Yes' };

          case constraintModels.MEMBER_PROXIMITY_LOCATION.type:
          case constraintModels.SALES_TRANSACTION_LOCATION.type:
          {
            const stores = Store.findIn(constraint.storeIds);

            return {
              title: 'Customer Must Visit Location(s)',
              allStoresSelected: stores.length === Store.query().count(),
              stores
            };
          }

          case constraintModels.MENU_TYPE.type: {
            const menuTypesDisplay = constraint.validMenuTypes.map(type => capitalCase(type)).join(', ');

            return {
              title: 'Available Menu Types',
              subtitle: menuTypesDisplay
            };
          }

          default:
            return { title: '', subtitle: '' };
        }
      },

      getDurationDisplay(maximumHourlyDuration) {
        switch (true) {
          case maximumHourlyDuration % 8760 === 0: {
            const years = maximumHourlyDuration / 8760;
            return `${years} year${years > 1 ? 's' : ''}`;
          }
          case maximumHourlyDuration % 720 === 0: {
            const months = maximumHourlyDuration / 720;
            return `${months} month${months > 1 ? 's' : ''}`;
          }
          case maximumHourlyDuration % 168 === 0: {
            const weeks = maximumHourlyDuration / 168;
            return `${weeks} week${weeks > 1 ? 's' : ''}`;
          }
          case maximumHourlyDuration % 24 === 0: {
            const days = maximumHourlyDuration / 24;
            return `${days} day${days > 1 ? 's' : ''}`;
          }
          default: {
            return `${maximumHourlyDuration} hour${maximumHourlyDuration > 1 ? 's' : ''}`;
          }
        }
      },

      getExcludedItemAttributeDisplay(excludedItemAttributes) {
        if (excludedItemAttributes.length) {
          const itemAttributeEntries = Object.values(itemAttributes).reduce((acc, value) => ({ ...acc, [value.id]: value.display }), {});
          const attributes = excludedItemAttributes.map(attribute => itemAttributeEntries[attribute]).join(', ');
          return `(excludes ${attributes})`;
        }
        else {
          return '';
        }
      },

      getDollarsDisplay(amount) {
        return `$${parseFloat(amount).toFixed(2)}`;
      }
    }
  };
</script>
