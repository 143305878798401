function capitalize(value, options = {}) {
  function _capitalize(string) {
    const wordsArr = string.split(' ');

    const capitalizedWordsArr = wordsArr.map((word) => {
      if (options.ignoreAcronym && word === word.toUpperCase()) {
        return word;
      }

      const firstChar = word.charAt(0);
      const firstIsAlphaNum = /[A-Za-z0-9]/.test(firstChar);

      if (firstIsAlphaNum) {
        const upperFirstChar = firstChar.toUpperCase();
        const lowerRest = word.toLowerCase().slice(1);

        return `${upperFirstChar}${lowerRest}`;
      }
      else {
        const upperSecondChar = word.charAt(1).toUpperCase();
        const lowerRest = word.toLowerCase().slice(2);

        return `${firstChar}${upperSecondChar}${lowerRest}`;
      }
    });

    return capitalizedWordsArr.join(' ');
  }

  if (typeof value === 'string') {
    return _capitalize(value);
  }

  else if (Array.isArray(value)) {
    const isAllStrings = value.every(item => typeof item === 'string');

    return isAllStrings ? value.map(str => _capitalize(str)) : value;
  }

  else {
    return value;
  }
}



export default capitalize;
