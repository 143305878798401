<template>
  <div class="has-radius has-shadow has-background-white has-border-grey-darker is-clipped">
    <img
      v-if="imagePath"
      :src="imagePath"
      alt="offer image"
      style="width: 100%"
    >
    <div v-else class="has-background-grey-lighter pad-md is-flex align-center justify-center">
      <p class="mar-md">Offer Image</p>
    </div>

    <div class="pad">
      <div class="is-flex justify-between align-center mar-b">
        <span class="title is-5 mar-none mar-r-xl">{{ offer.name || 'Offer Name Here' }}</span>
        <b-icon icon="gift" />
      </div>
      <p class="is-size-6">{{ offer.description }}</p>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'OfferPreview',

    props: {
      offer: {
        type: Object,
        required: true
      }
    },
    computed: {
      imagePath() {
        if (this.offer.tempEmailImageFile) {
          return URL.createObjectURL(this.offer.tempEmailImageFile);
        }

        const coupon = this.offer.coupons.find(displayChannel => displayChannel.displayChannelName === 'email');
        const isLocalEnv = window.location.hostname === 'localhost';
        if (coupon && isLocalEnv) {
          return `http://localhost:3000/${coupon.defaultImagePath}`;
        }
        return coupon?.fullUrl;
      }
    }
  };
</script>
