<template>
  <modal-card :title="auditLog.resource">
    <template #subtitle>
      <span class="is-size-5 has-text-grey is-monospaced">
        id: {{ auditLog.resourceId }}
      </span>
    </template>

    <p class="subtitle is-4 mar-b">User Info</p>

    <b-field label="Name" custom-class="mar-b-none">
      <p :class="{ 'has-text-grey-light': !auditLog.user }">
        {{ auditLog.user || 'N/A' }}
      </p>
    </b-field>

    <b-field label="Email" custom-class="mar-b-none">
      <p :class="{ 'has-text-grey-light': !auditLog.email }">
        {{ auditLog.email || 'N/A' }}
      </p>
    </b-field>

    <hr>

    <div class="is-flex align-center justify-between mar-b">
      <p>
        <span class="subtitle is-4 mar-none">Changes</span>
        <span class="mar-l-sm link" @click="viewAsJson = !viewAsJson">
          View {{ viewAsJson ? 'Pretty' : 'JSON' }}
        </span>
      </p>
      <b-tag size="is-medium" :type="badgeTypes[auditLog.event]">
        {{ auditLog.event | capitalize }}
      </b-tag>
    </div>

    <transition name="fade-down" mode="out-in">
      <pre v-if="viewAsJson" class="mar-t is-size-6">{{ parsedChanges }}</pre>
      <div v-else>
        <b-field v-for="(value, key) in parsedChanges" :key="key" :label="key" custom-class="mar-b-none">
          <p class="is-monospaced">
            <span v-if="value[0] !== null" class="has-text-danger-dark">
              <template v-if="value[0] === ''">{ empty string }</template>
              <template v-else>{{ value[0] }}</template>
            </span>

            <b-icon v-if="value.every(v => v !== null)" icon="long-arrow-right" class="mar-x-sm" type="is-grey-light" />

            <span v-if="value[1] !== null" class="has-text-success-dark">
              <template v-if="value[1] === ''">{ empty string }</template>
              <template v-else>{{ value[1] }}</template>
            </span>
          </p>
        </b-field>
      </div>
    </transition>
  </modal-card>
</template>

<script>
  export default {
    name: 'AuditLogModal',

    props: {
      auditLog: {
        type: Object,
        required: true
      }
    },

    data() {
      return {
        viewAsJson: false,
        badgeTypes: {
          update: 'is-primary is-light',
          create: 'is-success is-light',
          destroy: 'is-danger is-light'
        }
      };
    },

    computed: {
      parsedChanges() {
        return JSON.parse(this.auditLog.changes);
      }
    }
  };
</script>
