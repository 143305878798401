<template>
  <validated-form
    ref="form"
    auto-focus
    form-id="faqContentForm"
    @valid-submit="handleSubmit"
  >
    <modal-card :title="`${content.id ? 'Update' : 'Add'} Question`">
      <validated-text-input
        v-model="form.question"
        label="Question"
        name="question"
        type="textarea"
        rules="required"
        rows="2"
        maxlength="250"
      />
      <validated-text-input
        v-model="form.answer"
        label="Answer"
        name="answer"
        type="textarea"
        rules="required"
        rows="6"
        maxlength="1000"
      />

      <template #footer>
        <div class="buttons all-bold">
          <b-button
            rounded
            @click="$_confirmCloseModal({ programmatic: true })"
          >
            Cancel
          </b-button>
          <b-button
            v-tabbable
            rounded
            native-type="submit"
            type="is-primary"
            :loading="isSubmitting"
          >
            Save
          </b-button>
        </div>
      </template>
    </modal-card>
  </validated-form>
</template>

<script>
  import confirmModalCloseMixin from '@/mixins/confirm-modal-close';
  import MerchantFaqContent from '@/store/classes/MerchantFaqContent';

  export default {
    name: 'AddEditFaqContentModal',

    mixins: [confirmModalCloseMixin],

    props: {
      content: {
        type: Object,
        required: true
      }
    },

    data() {
      return {
        form: null
      };
    },

    computed: {
      isSubmitting() {
        return MerchantFaqContent.$state().submitting;
      }
    },

    created() {
      this.onCreated();
    },

    methods: {
      onCreated() {
        this.form = this.$clone(this.content);
      },

      async handleSubmit() {
        try {
          if (this.content.id) {
            await MerchantFaqContent.updateMerchantFaqContent(this.form);
          }
          else {
            await MerchantFaqContent.createMerchantFaqContent(this.form);
          }

          this.$_onRequestSuccess({
            toastOptions: { message: `Successfully ${this.content.id ? 'updated' : 'added'} your content!` },
            options: {
              closeParent: true,
              emit: { name: 'faq-content:updated' }
            }
          });
        }

        catch (error) {
          this.$_onRequestError({
            error,
            toastOptions: {
              message: `There was an error ${this.content.id ? 'updating' : 'adding'} your content`
            }
          });
        }
      }
    }
  };
</script>
