<template>
  <!-- If this message appears it means we are missing a Merchant 0 MerchantContentMetadata record for this ContentTypeProperty. -->
  <b-message
    v-if="notFound"
    type="is-warning"
    size="is-small"
    class="is-compact has-shadow justify-self-start"
    has-icon
    data-test-id="warning-message"
  >
    Uh oh! You're missing an input. Please contact support. There should be a <b>{{ capitalCase(contentTypeProperty.propertyName) }}</b> input for Content Type Id <b>{{ contentTypeProperty.contentTypeId }}.</b>
  </b-message>

  <!-- JSON INPUT -->
  <validated-input
    v-else-if="contentTypeProperty.isObject || contentTypeProperty.isArray"
    :label="capitalCase(contentTypeProperty.propertyName)"
    :name="contentTypeProperty.propertyName"
    rules="validJSON"
    data-test-id="json-input"
  >
    <b-input
      v-model="val"
      type="textarea"
      custom-class="is-monospaced"
      rows="8"
      :placeholder="placeholder || `Enter a ${capitalCase(contentTypeProperty.propertyName)}...`"
    />
  </validated-input>

  <!-- IMAGE INPUT -->
  <image-upload
    v-else-if="contentTypeProperty.isImage"
    v-slot="{imagePath}"
    :value="imageFile"
    :accepted-types="['png', 'webp', 'jpeg', 'jpg', 'gif', 'svg']"
    :image-url="imageUrl"
    :label="capitalCase(contentTypeProperty.propertyName)"
    show-delete-button
    show-clear-button
    :confirm-delete="false"
    clear-text="Clear Image"
    data-test-id="image-input"
    @delete-image="$emit('delete-image', contentTypeProperty.id)"
    @input="$emit('update-image', { contentTypePropertyId: contentTypeProperty.id, imageFile: $event})"
  >
    <img
      v-if="imagePath"
      :src="imagePath"
      style="max-height: 300px; width: auto"
      alt="preview image"
    >
  </image-upload>

  <!-- TEXT INPUT -->
  <validated-text-input
    v-else
    v-model="val"
    :label="capitalCase(contentTypeProperty.propertyName)"
    type="text"
    :name="contentTypeProperty.propertyName"
    label-position="on-border"
    :placeholder="placeholder || `Enter a ${capitalCase(contentTypeProperty.propertyName)}...`"
    data-test-id="text-input"
  />
</template>

<script>
  import capitalCase from '@/helpers/capitalCase';

  export default {
    name: 'ContentPropertyInput',

    props: {
      contentTypeProperty: {
        type: Object,
        required: true
      },
      placeholder: {
        type: String,
        default: null
      },
      imageFile: {
        type: File,
        default: null
      },
      notFound: {
        type: Boolean,
        default: false
      },
      value: {
        type: String,
        default: null
      }
    },

    data: () => ({
      capitalCase
    }),

    computed: {
      val: {
        get() {
          return this.value;
        },
        set(val) {
          this.$emit('input', val);
        }
      },

      imageUrl() {
        /* use image url form `value` */
        if (this.val) {
          const hasHostPrefix = this.val.startsWith('http://') || this.val.startsWith('https://');
          const isLocalEnvironment = window.location.hostname === 'localhost';
          return isLocalEnvironment && !hasHostPrefix ? `http://localhost:3000/${this.val}` : this.val;
        }
        /* use generated local image url from `imageFile` */
        else if (this.imageFile) return URL.createObjectURL(this.imageFile);
        return null;
      }
    }
  };
</script>
