var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"is-grid col-2 gap"},[(_vm.whitelistedInputs.includes('pointsPerDollarSpent') && _vm.value.awardPolicy.awardPolicyType === _vm.awardPolicyTypes.SPECIFIED_ITEM_POINTS_PER_DOLLAR_SPENT.type)?_c('validated-input',{attrs:{"label":"Points Per $1 Spent","name":"pointsPerDollarSpent","rules":{
        required: !_vm.isDraft,
        min_value: 0.01
      },"data-test-id":"points-per-dollar-input"}},[_c('numberinput-fractional',{attrs:{"min":0.01,"default-value":1},model:{value:(_vm.value.awardPolicy.pointsPerDollarSpent),callback:function ($$v) {_vm.$set(_vm.value.awardPolicy, "pointsPerDollarSpent", $$v)},expression:"value.awardPolicy.pointsPerDollarSpent"}})],1):(_vm.whitelistedInputs.includes('pointsPerQualifyingCount') && _vm.value.awardPolicy.awardPolicyType === _vm.awardPolicyTypes.SPECIFIED_ITEM_POINTS_PER_COUNT.type)?_c('validated-input',{attrs:{"label":"Points Per Count","name":"pointsPerQualifyingCount","rules":{
        required: !_vm.isDraft,
        min_value: 0.01
      },"data-test-id":"points-per-count-input"}},[_c('numberinput-fractional',{attrs:{"min":0.01,"default-value":1},model:{value:(_vm.value.awardPolicy.pointsPerQualifyingCount),callback:function ($$v) {_vm.$set(_vm.value.awardPolicy, "pointsPerQualifyingCount", $$v)},expression:"value.awardPolicy.pointsPerQualifyingCount"}})],1):_vm._e(),(_vm.whitelistedInputs.includes('earningItemBasisType'))?_c('validated-input',{staticClass:"dropdown-field",attrs:{"name":"earningItemBasisType","label":"Earn Points For...","rules":{
        required: !_vm.isDraft
      },"tooltip":"Select item basis type for how customers can earn points","data-test-id":"earning-item-basis-dropdown","custom-messages":{ required: _vm.invalidDiscountBasisMessage }}},[_c('b-checkbox',{staticClass:"is-hidden",attrs:{"value":_vm.validDiscountBasis || null}}),_c('b-select',{staticClass:"is-inline-block",attrs:{"placeholder":"Select an item basis type...","expanded":"","disabled":_vm.readOnly},model:{value:(_vm.value.awardPolicy.earningItemBasisType),callback:function ($$v) {_vm.$set(_vm.value.awardPolicy, "earningItemBasisType", $$v)},expression:"value.awardPolicy.earningItemBasisType"}},_vm._l((_vm.earnPointsForOptions),function(earningItemBasis){return _c('option',{key:("earningItemBasis-" + (earningItemBasis.id)),domProps:{"value":earningItemBasis.id}},[_vm._v(" "+_vm._s(earningItemBasis.display)+" ")])}),0)],1):_vm._e(),(_vm.whitelistedInputs.includes('maximumPointsPerSalesTransaction'))?_c('validated-input',{attrs:{"label":"Max Total Points Earned","sub-label":"(Optional)","sub-label-on-side":"","name":"maximumPointsPerSalesTransaction","tooltip":"Limit how many points a guest can earn from this rule","data-test-id":"max-total-points-input","rules":{
        greaterThan: _vm.value.awardPolicy.maximumPointsPerSalesTransaction && 0
      }}},[_c('numberinput-fractional',{attrs:{"min":null},model:{value:(_vm.value.awardPolicy.maximumPointsPerSalesTransaction),callback:function ($$v) {_vm.$set(_vm.value.awardPolicy, "maximumPointsPerSalesTransaction", $$v)},expression:"value.awardPolicy.maximumPointsPerSalesTransaction"}})],1):_vm._e(),(_vm.whitelistedInputs.includes('maximumEarningQuantity') && _vm.value.awardPolicy.awardPolicyType === _vm.awardPolicyTypes.SPECIFIED_ITEM_POINTS_PER_DOLLAR_SPENT.type)?_c('validated-input',{attrs:{"label":"Max Earning Quantity","sub-label":"(Optional)","sub-label-on-side":"","name":"maximumEarningQuantity","tooltip":"Limit how many items can be earned from","data-test-id":"max-earning-quantity-input"}},[_c('b-numberinput',{attrs:{"controls-position":"compact","min":null},model:{value:(_vm.value.awardPolicy.maximumEarningQuantity),callback:function ($$v) {_vm.$set(_vm.value.awardPolicy, "maximumEarningQuantity", $$v)},expression:"value.awardPolicy.maximumEarningQuantity"}})],1):(_vm.whitelistedInputs.includes('qualifyingCountIncrement') && _vm.value.awardPolicy.awardPolicyType === _vm.awardPolicyTypes.SPECIFIED_ITEM_POINTS_PER_COUNT.type)?_c('validated-input',{attrs:{"label":"Qualifying Count Increment","name":"qualifyingCountIncrement","tooltip":"Define how many items must be purchased to earn points","rules":{
        required: !_vm.isDraft
      },"data-test-id":"qualifying-count-increment-input"}},[_c('b-numberinput',{attrs:{"controls-position":"compact","min":null},model:{value:(_vm.value.awardPolicy.qualifyingCountIncrement),callback:function ($$v) {_vm.$set(_vm.value.awardPolicy, "qualifyingCountIncrement", $$v)},expression:"value.awardPolicy.qualifyingCountIncrement"}})],1):_vm._e()],1),_c('hr'),_c('div',[(_vm.value.awardPolicy.itemGroup)?_c('menu-search-form',{attrs:{"item-group":_vm.value.awardPolicy.itemGroup,"read-only":_vm.readOnly,"form-id":0,"is-draft":_vm.isDraft}}):_vm._e()],1)])}
var staticRenderFns = []

export { render, staticRenderFns }