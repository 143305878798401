<template>
  <div>
    <b-message v-if="!merchantDeliveryServices.length" type="is-warning" class="has-shadow is-inline-block">
      There are no Delivery Service Providers configured for this Merchant
    </b-message>

    <b-field v-else label="Delivery Service Providers">
      <b-message v-if="!storeDeliveryServices.length && !$can('update','StoreDeliveryService')" type="is-warning" class="has-shadow is-inline-block">
        There are no Delivery Service Providers configured for this Location
      </b-message>
      <div v-else class="store-delivery-services">
        <div
          v-for="storeDeliveryService in storeDeliveryServices"
          :key="storeDeliveryService.id"
          class="delivery-service-card"
        >
          <transition name="fade" mode="out-in">
            <div v-if="isFetchingDeliveryServices" key="loader">
              <div class="is-flex justify-between">
                <b-skeleton height="1.5rem" width="15rem" />
                <span>
                  <b-skeleton height="2.5rem" width="2.5rem" />
                </span>
              </div>
              <b-skeleton height="1rem" width="18rem" />
              <b-skeleton height="96px" width="100%" />
            </div>

            <div v-else key="content">
              <div class="is-flex justify-between">
                <div class="is-flex gap align-center">
                  <p class="subtitle is-5 mar-none">
                    {{ storeDeliveryService.name ? capitalCase(storeDeliveryService.name) : 'No Name' }}
                  </p>
                  <b-button
                    v-if="isCardfreeAdmin || storeDeliveryService.deliveryServiceId === IN_HOUSE_DELIVERY_SERVICE_ID && $can('read','StoreDeliveryService')"
                    icon-right="calendar-clock"
                    @click="openStoreDeliveryServiceSettingsModal(storeDeliveryService)"
                  >
                    {{ $can('crud','StoreDeliveryService') ? 'Manage' : 'View' }} Settings
                  </b-button>
                </div>
                <b-button
                  v-if="$can('update','StoreDeliveryService')"
                  outlined
                  icon-left="trash-alt"
                  type="is-danger is-light"
                  @click="confirmRemoveStoreDeliveryService(storeDeliveryService)"
                />
              </div>

              <b-field label="Delivery Provider Pickup Instructions">
                <b-input
                  :value="storeDeliveryService.pickupInstructions"
                  :disabled="!$can('update','StoreDeliveryService')"
                  rows="3"
                  type="textarea"
                  placeholder="Please enter pickup instructions for this delivery provider..."
                  @input="handlePickupInstructionInput(storeDeliveryService.id, $event)"
                />
              </b-field>
            </div>
          </transition>
        </div>

        <b-button
          v-if="$can('update','StoreDeliveryService')"
          :disabled="hasAllDeliveryServices"
          icon-left="plus"
          type="is-primary is-light"
          outlined
          class="is-full-width is-full-height pad-lg"
          size="is-medium"
          @click="openStoreDeliveryServiceModal"
        >
          Delivery Service
        </b-button>
      </div>
    </b-field>
  </div>
</template>

<script>
  import { capitalCase } from 'change-case';
  import { mapGetters } from 'vuex';

  import alertModal from '@/components/globals/alert-modal.vue';

  import merchantMixin from '@/mixins/merchant';

  import DeliveryService from '@/store/classes/DeliveryService';
  import MerchantDeliveryService from '@/store/classes/MerchantDeliveryService';
  import StoreDeliveryService from '@/store/classes/StoreDeliveryService';
  import StoreDeliveryServiceSchedule from '@/store/classes/StoreDeliveryServiceSchedule';

  import addEditStoreDeliveryServiceModal from './add-edit-store-delivery-service-modal.vue';
  import StoreDeliveryServiceSettingsModal from './store-delivery-service-settings-modal.vue';


  export default {
    name: 'StoreDeliveryServices',

    mixins: [merchantMixin],

    props: {
      store: {
        type: Object,
        required: true
      }
    },

    data() {
      return {
        capitalCase,
        // Move this to a constants file if we end up using more delivery service ids like this
        IN_HOUSE_DELIVERY_SERVICE_ID: 8
      };
    },

    computed: {
      ...mapGetters('session', ['isCardfreeAdmin']),

      storeDeliveryServices() {
        return StoreDeliveryService.query().where('storeId', this.store.storeId).get();
      },

      merchantDeliveryServices() {
        return MerchantDeliveryService.all();
      },

      isFetchingDeliveryServices() {
        return DeliveryService.$state().fetching;
      },

      hasAllDeliveryServices() {
        return this.storeDeliveryServices.length === this.merchantDeliveryServices.length;
      }
    },

    methods: {
      onMerchantLoad() {
        DeliveryService.fetchDeliveryServices();
      },

      handlePickupInstructionInput(id, pickupInstructions) {
        StoreDeliveryService.update({
          where: id,
          data: { pickupInstructions }
        });
      },

      async removeStoreDeliveryService(storeDeliveryService) {
        try {
          await StoreDeliveryService.removeStoreDeliveryService(storeDeliveryService);

          this.$_onRequestSuccess({
            toastOptions: {
              message: 'Delivery Service sucessfully removed!',
              duration: 2000
            }
          });
        }
        catch (error) {
          this.$_onRequestError({ error, toastOptions: { message: 'Unable to remove Delivery Service' } });
        }
      },

      openStoreDeliveryServiceModal() {
        this.$buefy.modal.open({
          parent: this,
          component: addEditStoreDeliveryServiceModal,
          hasModalCard: true,
          trapFocus: true,
          canCancel: false,
          customClass: 'auto-width',
          props: {
            storeId: this.store.storeId,
            existingStoreDeliveryServiceIds: this.storeDeliveryServices.map(sds => sds.deliveryServiceId)
          }
        });
      },

      confirmRemoveStoreDeliveryService(storeDeliveryService) {
        this.$buefy.modal.open({
          parent: this,
          component: alertModal,
          hasModalCard: true,
          trapFocus: true,
          canCancel: false,
          customClass: 'auto-width',
          props: {
            title: 'Delete Delivery Service',
            message: `Are you sure you want to delete the <b>${capitalCase(storeDeliveryService.name)}</b> delivery service from <b>${this.store.description}</b>?`,
            icon: 'trash-alt',
            type: 'is-danger',
            horizontal: true,
            showCloseButton: false,
            buttons: [
              { text: 'Cancel' },
              {
                text: 'Delete',
                primary: true,
                onClick: () => this.removeStoreDeliveryService(storeDeliveryService)
              }
            ]
          }
        });
      },

      async openStoreDeliveryServiceSettingsModal({ id, isSaved }) {
        if (isSaved) {
          await StoreDeliveryServiceSchedule.getSchedules(id);
        }
        const storeDeliveryService = StoreDeliveryService.query()
          .with('storeDeliveryServiceSchedules')
          .find(id);

        this.$buefy.modal.open({
          parent: this,
          component: StoreDeliveryServiceSettingsModal,
          hasModalCard: true,
          trapFocus: true,
          canCancel: false,
          customClass: 'auto-width',
          props: { storeDeliveryService }
        });
      }
    }
  };
</script>

<style lang="sass" scoped>
  .store-delivery-services
    display: grid
    gap: $size-large
    grid-template-columns: repeat(auto-fill, minmax(425px, 1fr))
    grid-auto-rows: 1fr

    .delivery-service-card
      padding: $size-normal
      border: 1px solid $grey-lightest
      border-radius: $radius
      background-color: $white-bis
</style>
