<template>
  <li class="card">
    <div class="card-content pad-b-xs">
      <div class="is-flex gap-xl">
        <div class="question-header-left">
          <b-icon
            v-if="localQuestion.isActive"
            icon="grip-lines"
            size="is-small"
            pack="far"
            class="drag-handle mar-r"
          />
          <p v-if="isNotEditable" class="subtitle">
            {{ localQuestion.label }}
          </p>
          <validated-text-input
            v-else
            v-model="localQuestion.label"
            :name="`label-${localQuestion.id}`"
            :disabled="!localQuestion.isActive"
            class="question-input"
            placeholder="Question"
            type="text"
            rules="required"
            label="Question"
            hide-label
          />
        </div>

        <b-dropdown
          v-model="questionType"
          :disabled="mode !== 'create' && (!localQuestion.isUnsaved || !localQuestion.isActive)"
          :triggers="['click']"
          aria-role="list"
          position="is-bottom-left"
        >
          <b-button
            slot="trigger"
            class="question-type-select"
            :icon-left="questionTypeIcons[localQuestion.questionType]"
          >
            <span>{{ capitalCase(localQuestion.questionType) }}</span>
          </b-button>

          <b-dropdown-item
            v-for="questionTypeOption in questionTypes"
            :key="questionTypeOption"
            :value="questionTypeOption"
            aria-role="listitem"
          >
            <b-icon :icon="questionTypeIcons[questionTypeOption]" />
            {{ capitalCase(questionTypeOption) }}
          </b-dropdown-item>
        </b-dropdown>
      </div>

      <survey-question-options
        v-if="hasConfigurableOptions(localQuestion.questionType)"
        :question="localQuestion"
        :mode="mode"
        :is-draft="isDraft"
        :disabled="!localQuestion.isActive"
        :handle-options-change="handleOptionsChange"
        class="mar-t"
      />

      <hr class="mar-b-xs">

      <div class="is-flex justify-between align-center">
        <div class="is-flex gap">
          <template v-if="localQuestion.isActive">
            <b-checkbox v-model="localQuestion.required">
              Response Required
            </b-checkbox>
            <transition name="fade-right">
              <b-checkbox
                v-if="randomQuestionsEnabled"
                v-model="localQuestion.alwaysInclude"
              >
                Always Ask
              </b-checkbox>
            </transition>
          </template>
        </div>
        <div class="is-flex gap-sm">
          <b-button
            v-if="mode === 'create' || localQuestion.isUnsaved"
            v-tippy="{ content: 'Remove Question', placement: 'bottom', delay: [300, 0] }"
            class="is-transparent"
            @click="$emit('remove', localQuestion.id)"
          >
            <b-icon
              size="is-small"
              type="is-danger"
              icon="minus-circle"
            />
          </b-button>
          <b-button
            v-tippy="{ content: `${localQuestion.isActive ? 'Hide' : 'Show'} Question`, placement: 'bottom', delay: [300, 0] }"
            class="is-transparent"
            @click="localQuestion.isActive = !localQuestion.isActive"
          >
            <b-icon
              size="is-small"
              :icon="!localQuestion.isActive ? 'eye-slash' : 'eye'"
              :class="{'has-text-danger': !localQuestion.isActive}"
            />
          </b-button>
        </div>
      </div>
    </div>
  </li>

</template>

<script>
  import { questionTypes, questionAnswerMap } from '@/constants/surveyQuestionTypes';
  import capitalCase from '@/helpers/capitalCase';
  import surveyQuestionOptions from './survey-question-options.vue';


  export default {
    name: 'SurveyQuestion',

    components: { surveyQuestionOptions },

    props: {
      isDraft: {
        type: Boolean,
        required: true
      },

      randomQuestionsEnabled: {
        type: Boolean,
        default: false
      },

      question: {
        type: Object,
        required: true
      },

      mode: {
        type: String,
        default: 'create',
        validator(value) {
          return ['create', 'read', 'update'].includes(value);
        }
      }
    },

    data() {
      return {
        questionTypes,
        capitalCase,
        localQuestion: null,
        questionTypeIcons: {
          [questionTypes.RADIO_LIST]: 'check-circle',
          [questionTypes.TEXTAREA]: 'paragraph',
          [questionTypes.INPUT]: 'font',
          [questionTypes.STAR_RATING]: 'star',
          [questionTypes.MULTIPLE_SELECT]: 'list-alt'
        }
      };
    },

    computed: {
      questionType: {
        get() {
          return this.localQuestion.questionType;
        },

        set(newQuestionType) {
          this.resetQuestion(newQuestionType);
        }
      },

      isNotEditable() {
        return !this.localQuestion.isUnsaved && !this.isDraft;
      },

      watchedQuestion() {
        return JSON.parse(JSON.stringify(this.localQuestion));
      }
    },

    watch: {
      watchedQuestion: {
        handler: 'handleQuestionChange'
      }
    },

    created() {
      this.onCreated();
    },

    methods: {
      onCreated() {
        this.localQuestion = JSON.parse(JSON.stringify(this.question));
      },

      resetQuestion(questionType) {
        this.localQuestion.questionType = questionType;
        this.localQuestion.answerType = questionAnswerMap[questionType];
        this.localQuestion.options = this.hasConfigurableOptions(questionType) ? this.localQuestion.options : [];
        this.focusOnLabel();
      },

      hasConfigurableOptions(questionType) {
        return [
          questionTypes.RADIO_LIST,
          questionTypes.MULTIPLE_SELECT,
          questionTypes.SELECT,
          questionTypes.CHECKLIST
        ].includes(questionType);
      },

      handleQuestionChange(updatedQuestion) {
        this.$emit('update', updatedQuestion);
      },

      handleOptionsChange(updatedOptions) {
        this.localQuestion.options = updatedOptions;
      },

      focusOnLabel() { // TEST
        this.$nextTick(() => {
          const label = this.$el.querySelector('.question-input input');
          label.focus();
        });
      }
    }
  };
</script>

<style lang="sass" scoped>
  .question-header-left
    display: flex
    align-items: center
    flex-grow: 1

  .question-input
    width: 100%

  .question-type-select
    min-width: 10.5rem
</style>
