<template>
  <div>
    <div class="is-grid col-2 gap">
      <validated-input
        v-if="whitelistedInputs.includes('pointsPerDollarSpent') && value.awardPolicy.awardPolicyType === awardPolicyTypes.SPECIFIED_ITEM_POINTS_PER_DOLLAR_SPENT.type"
        label="Points Per $1 Spent"
        name="pointsPerDollarSpent"
        :rules="{
          required: !isDraft,
          min_value: 0.01
        }"
        data-test-id="points-per-dollar-input"
      >
        <numberinput-fractional
          v-model="value.awardPolicy.pointsPerDollarSpent"
          :min="0.01"
          :default-value="1"
        />
      </validated-input>
      <validated-input
        v-else-if="whitelistedInputs.includes('pointsPerQualifyingCount') && value.awardPolicy.awardPolicyType === awardPolicyTypes.SPECIFIED_ITEM_POINTS_PER_COUNT.type"
        label="Points Per Count"
        name="pointsPerQualifyingCount"
        :rules="{
          required: !isDraft,
          min_value: 0.01
        }"
        data-test-id="points-per-count-input"
      >
        <numberinput-fractional
          v-model="value.awardPolicy.pointsPerQualifyingCount"
          :min="0.01"
          :default-value="1"
        />
      </validated-input>
      <validated-input
        v-if="whitelistedInputs.includes('earningItemBasisType')"
        class="dropdown-field"
        name="earningItemBasisType"
        label="Earn Points For..."
        :rules="{
          required: !isDraft
        }"
        tooltip="Select item basis type for how customers can earn points"
        data-test-id="earning-item-basis-dropdown"
        :custom-messages="{ required: invalidDiscountBasisMessage }"
      >
        <b-checkbox
          class="is-hidden"
          :value="validDiscountBasis || null"
        />
        <b-select
          v-model="value.awardPolicy.earningItemBasisType"
          placeholder="Select an item basis type..."
          class="is-inline-block"
          expanded
          :disabled="readOnly"
        >
          <option
            v-for="earningItemBasis in earnPointsForOptions"
            :key="`earningItemBasis-${earningItemBasis.id}`"
            :value="earningItemBasis.id"
          >
            {{ earningItemBasis.display }}
          </option>
        </b-select>
      </validated-input>
      <validated-input
        v-if="whitelistedInputs.includes('maximumPointsPerSalesTransaction')"
        label="Max Total Points Earned"
        sub-label="(Optional)"
        sub-label-on-side
        name="maximumPointsPerSalesTransaction"
        tooltip="Limit how many points a guest can earn from this rule"
        data-test-id="max-total-points-input"
        :rules="{
          greaterThan: value.awardPolicy.maximumPointsPerSalesTransaction && 0
        }"
      >
        <numberinput-fractional
          v-model="value.awardPolicy.maximumPointsPerSalesTransaction"
          :min="null"
        />
      </validated-input>
      <validated-input
        v-if="whitelistedInputs.includes('maximumEarningQuantity') && value.awardPolicy.awardPolicyType === awardPolicyTypes.SPECIFIED_ITEM_POINTS_PER_DOLLAR_SPENT.type"
        label="Max Earning Quantity"
        sub-label="(Optional)"
        sub-label-on-side
        name="maximumEarningQuantity"
        tooltip="Limit how many items can be earned from"
        data-test-id="max-earning-quantity-input"
      >
        <b-numberinput
          v-model="value.awardPolicy.maximumEarningQuantity"
          controls-position="compact"
          :min="null"
        />
      </validated-input>
      <validated-input
        v-else-if="whitelistedInputs.includes('qualifyingCountIncrement') && value.awardPolicy.awardPolicyType === awardPolicyTypes.SPECIFIED_ITEM_POINTS_PER_COUNT.type"
        label="Qualifying Count Increment"
        name="qualifyingCountIncrement"
        tooltip="Define how many items must be purchased to earn points"
        :rules="{
          required: !isDraft
        }"
        data-test-id="qualifying-count-increment-input"
      >
        <b-numberinput
          v-model="value.awardPolicy.qualifyingCountIncrement"
          controls-position="compact"
          :min="null"
        />
      </validated-input>
    </div>
    <hr>
    <div>
      <menu-search-form
        v-if="value.awardPolicy.itemGroup"
        :item-group="value.awardPolicy.itemGroup"
        :read-only="readOnly"
        :form-id="0"
        :is-draft="isDraft"
      />
    </div>
  </div>
</template>

<script>
  import { earningItemBases, awardPolicyTypes } from '@/constants/earnRules';

  export default {
    name: 'SpecifiedItemInputs',

    props: {
      value: {
        type: Object,
        required: true
      },
      readOnly: {
        type: Boolean,
        default: false
      },
      whitelistedInputs: {
        type: Array,
        default: () => [
          'pointsPerDollarSpent',
          'pointsPerQualifyingCount',
          'earningItemBasisType',
          'maximumPointsPerSalesTransaction',
          'maximumEarningQuantity',
          'qualifyingCountIncrement'
        ]
      },
      isDraft: {
        type: Boolean,
        default: false
      }
    },

    data() {
      return {
        earningItemBases,
        awardPolicyTypes
      };
    },

    computed: {
      validDiscountBasis() {
        switch (this.value.awardPolicy?.earningItemBasisType) {
          case earningItemBases.MAIN_MENU_ITEM.id:
          case earningItemBases.MAIN_MENU_ITEM_ALL_MODS.id:
            return !this.value.awardPolicy?.itemGroup?.requiredModifications?.length;
          case earningItemBases.MAIN_MENU_ITEM_MATCHING_MODS.id:
            // The UI currently supports only one modifier being selected, but in the future mulitple will be allowed
            return this.value.awardPolicy?.itemGroup?.requiredModifications?.length > 0;
          default:
            return true;
        }
      },

      invalidDiscountBasisMessage() {
        const messageStem = 'The earn rule';
        if (!this.validDiscountBasis) {
          if (this.value.awardPolicy?.earningItemBasisType === earningItemBases.MAIN_MENU_ITEM.id) {
            return `${messageStem} only applies to the main menu item. Please remove any selected modifiers.`;
          }
          else if (this.value.awardPolicy?.earningItemBasisType === earningItemBases.MAIN_MENU_ITEM_ALL_MODS.id) {
            return `${messageStem} applies to all modifiers. Please remove any selected modifiers.`;
          }
          else {
            return `${messageStem} requires a modifier to be selected.`;
          }
        }
        else {
          return '';
        }
      },

      earnPointsForOptions() {
        if (this.value.awardPolicy.awardPolicyType === awardPolicyTypes.SPECIFIED_ITEM_POINTS_PER_DOLLAR_SPENT.type) {
          return [
            earningItemBases.MAIN_MENU_ITEM,
            earningItemBases.MAIN_MENU_ITEM_ALL_MODS
          ];
        }
        else if (this.value.awardPolicy.awardPolicyType === awardPolicyTypes.SPECIFIED_ITEM_POINTS_PER_COUNT.type) {
          return [
            earningItemBases.MAIN_MENU_ITEM
          ];
        }

        return Object.values(earningItemBases);
      }
    }
  };
</script>
