<template>
  <validated-form
    ref="form"
    auto-focus
    form-id="shareEmployeeDepartmentModal"
    @valid-submit="sendLinks"
  >
    <modal-card :title="modalTitle">
      <div class="is-relative">
        <p class="title is-5">View Tipping QR page</p>
        <div class="is-flex align-center justify-center has-border has-border-grey-lighter has-radius pad mar-b">
          <b-loading :is-full-page="false" :active="isLoadingShortUrl" />
          <qrcode-vue v-if="!isLoadingShortUrl && shortenedUrl" :value="shortenedUrl" :size="200" />
        </div>
        <div class="is-flex justify-center">
          <b-button
            class="mar-r-lg has-radius-large pad-sm has-text-weight-bold"
            icon-left="external-link"
            tag="a"
            target="_blank"
            :href="shortenedUrl"
            type="is-dark"
            size="is-small"
          >
            VIEW LINK
          </b-button>
          <b-button
            class="has-text-weight-bold has-radius-large"
            size="is-small"
            icon-left="copy"
            type="is-primary"
            @click="$_copyToClipboard(shortenedUrl)"
          >
            COPY LINK
          </b-button>
        </div>
      </div>

      <hr>

      <p class="title is-5">Share Tipping QR Page</p>
      <p class="subtitle is-6">Send a link to share this {{ type }}'s QR code page</p>
      <transition name="fade-down" mode="out-in">
        <b-message
          v-if="hasErrors"
          type="is-danger"
          has-icon
          class="is-compact has-shadow mar-t-xs"
          size="is-small"
        >
          One or more of your email addresses or phone numbers are invalid
        </b-message>
      </transition>
      <validated-text-input
        v-model="currentEmailAddress"
        name="emailAddresses"
        label="Email Address"
        sub-label="You may enter multiple emails separated by commas"
        type="text"
        :icon-right-clickable="!!currentEmailAddress"
        icon-right="plus"
        icon="envelope"
        @icon-right-click="addInput({ value: $event, type: 'emailAddresses' })"
      />
      <b-tag
        v-for="(emailAddress, i) in emailAddresses"
        :key="`${i}-${emailAddress.value}`"
        class="mar-r-sm"
        :type="getTagType(emailAddress.hasError)"
        closable
        @close="removeInput({ type: 'emailAddresses', i })"
      >
        {{ emailAddress.value }}
      </b-tag>

      <validated-text-input
        v-model="currentPhoneNumber"
        name="phoneNumbers"
        label="Phone Number"
        type="phone"
        :icon-right-clickable="!!currentPhoneNumber"
        icon-right="plus"
        icon="phone"
        class="mar-t-lg"
        @icon-right-click="addInput({ value: $event, type: 'phoneNumbers' })"
      />
      <b-tag
        v-for="(phoneNumber, i) in phoneNumbers"
        :key="`${i}-${phoneNumber.value}`"
        class="mar-r-sm"
        :type="getTagType(phoneNumber.hasError)"
        closable
        @close="removeInput({ type: 'phoneNumbers', i })"
      >
        {{ phoneNumber.value }}
      </b-tag>

      <template #footer>
        <div class="buttons all-bold">
          <b-button
            rounded
            @click="$parent.close()"
          >
            Cancel
          </b-button>

          <b-button
            v-tabbable
            rounded
            native-type="submit"
            type="is-primary"
            :loading="isSendingLinks"
            :disabled="isSubmitDisabled"
          >
            Send Link
          </b-button>
        </div>
      </template>
    </modal-card>
  </validated-form>
</template>

<script>
  import getShortUrl from '@/api/short-url';
  import sendMessage from '@/api/employee-department-message-sender';
  import copyToClipboard from '@/mixins/copy-to-clipboard';
  import QrcodeVue from 'qrcode.vue';
  import { isValidPhoneNumber } from 'libphonenumber-js';
  import { rules } from '@/helpers/validation';

  export default {
    name: 'ShareEmployeeDepartmentModal',

    components: { QrcodeVue },

    mixins: [copyToClipboard],

    props: {
      resource: {
        type: Object,
        required: true
      },

      type: {
        type: String,
        required: true
      }
    },

    data() {
      return {
        shortenedUrl: null,
        currentEmailAddress: null,
        currentPhoneNumber: null,
        emailAddresses: [],
        phoneNumbers: [],
        isSendingLinks: false,
        isLoadingShortUrl: false
      };
    },

    computed: {
      modalTitle() {
        return this.type === 'employee' ? this.resource.fullName : this.resource.name;
      },

      isSubmitDisabled() {
        return !!(this.isSendingLinks || (!this.emailAddresses.length && !this.phoneNumbers.length) || this.hasErrors);
      },

      hasErrors() {
        return [...this.emailAddresses, ...this.phoneNumbers].some(e => e.hasError);
      }
    },

    created() {
      this.onCreated();
    },

    methods: {
      onCreated() {
        this.getShortenedUrl();
      },

      getTagType(hasError) {
        return hasError ? 'is-danger is-light' : 'is-grey is-light';
      },

      validateEmailAddresses() {
        const updated = this.$clone(this.emailAddresses).map(email => ({
          ...email,
          hasError: !rules.validEmailAddress.validate(email.value)
        }));
        this.emailAddresses = updated;
      },

      validatePhoneNumbers() {
        const updated = this.$clone(this.phoneNumbers).map(num => ({
          ...num,
          hasError: !isValidPhoneNumber(num.value, 'US')
        }));
        this.phoneNumbers = updated;
      },

      async sendLinks() {
        try {
          if (!this.hasErrors) {
            this.isSendingLinks = true;
            const messageTypesToSend = ['phoneNumbers', 'emailAddresses'];
            const promises = [];
            messageTypesToSend.forEach((messageType) => {
              if (this[messageType].length) {
                const values = messageType === 'phoneNumbers' ? this[messageType].map(m => m.value.split('-').join('')) : this[messageType].map(m => m.value);
                promises.push(
                  sendMessage({
                    resourceType: this.type,
                    messageType,
                    id: this.resource.id,
                    values
                  })
                );
              }
            });
            await Promise.all(promises);
            this.$_onRequestSuccess({
              toastOptions: {
                message: 'Successfully send the qr code links'
              },
              options: {
                closeParent: true
              }
            });
          }
        }
        catch (error) {
          this.$_onRequestError({
            toastOptions: {
              message: 'There was an error sending the qr code links'
            },
            error
          });
        }
        finally {
          this.isSendingLinks = false;
        }
      },

      addInput({ type, value }) {
        if (type === 'emailAddresses') {
          const mappedValues = value.replace(/\s+/g, '').split(',').map(v => ({ value: v, hasError: false }));
          this[type].push(...mappedValues);
          this.currentEmailAddress = null;
        }
        else {
          this[type].push({ value, hasError: false });
          this.currentPhoneNumber = null;
        }

        // NOTE: cannot use this as a watcher because it creates an infinite loop of updates
        this.validateEmailAddresses();
        this.validatePhoneNumbers();
      },

      removeInput({ type, i }) {
        const clone = this.$clone(this[type]);
        clone.splice(i, 1);
        this[type] = clone;

        this.validateEmailAddresses();
        this.validatePhoneNumbers();
      },

      async getShortenedUrl() {
        try {
          this.isLoadingShortUrl = true;
          this.shortenedUrl = await getShortUrl({ type: this.type, id: this.resource.id });
        }
        catch (error) {
          this.$_onRequestError({
            toastOptions: {
              message: 'There was an error getting the qr code link'
            },
            error
          });
        }
        finally {
          this.isLoadingShortUrl = false;
        }
      }
    }
  };
</script>
