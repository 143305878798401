import { render, staticRenderFns } from "./confirm-delete-item-from-menu.vue?vue&type=template&id=593b3d43&scoped=true&"
import script from "./confirm-delete-item-from-menu.vue?vue&type=script&lang=js&"
export * from "./confirm-delete-item-from-menu.vue?vue&type=script&lang=js&"
import style0 from "./confirm-delete-item-from-menu.vue?vue&type=style&index=0&id=593b3d43&lang=sass&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "593b3d43",
  null
  
)

export default component.exports