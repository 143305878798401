import storage from '@/services/storage';

import ExpoOrder from '@/store/classes/ExpoOrder';
import Merchant from '@/store/classes/Merchant';
import Store from '@/store/classes/Store';

import { expoStatuses, orderTypes } from '@/constants/expo';


export default {
  namespaced: true,

  state: {
    selectedStoreId: null,
    selectedExpoStatus: expoStatuses.ACTIVE,
    orderTypeFilter: '',
    selectedOrderId: null,
    storeOrderCounts: {
      Upcoming: { count: 0, orderIds: [] },
      Active: { count: 0, orderIds: [] },
      Past: { count: 0, orderIds: [] }
    },
    tagTypes: {
      Delivered: 'is-success',
      Pending: 'is-warning',
      Canceled: 'is-danger',
      Failed: 'is-danger',
      'Customer Pickup': 'is-info'
    },
    isLightTagTypes: ['Pending', 'Customer Pickup'],
    currentPage: 1,
    searchModalActive: false,
    searchResults: [],
    notificationsModalActive: false,
    hideNotifications: false
  },

  getters: {
    selectedMerchantId: () => Merchant.$state().selectedMerchantId,
    allStores: () => Store.orderByName().get(),
    expoOrders: state => (
      ExpoOrder.query()
        .where('expoStatus', state.selectedExpoStatus)
        .orderBy('pickupDate', state.selectedExpoStatus === expoStatuses.PAST ? 'desc' : 'asc')
        .all()
    ),
    loadedOrdersMatchCount: (state, getters) => (
      state.storeOrderCounts?.[state.selectedExpoStatus]?.count <= getters.expoOrders?.length
    ),
    fetching: () => ExpoOrder.$state().fetchingOrders || ExpoOrder.$state().fetchingOrderCounts,
    fetchingOrderDetails: () => ExpoOrder.$state().fetchingOrderDetails,
    orderSortOrder: state => (state.selectedExpoStatus === expoStatuses.PAST ? 'RequestedFulfillmentDateDesc' : 'RequestedFulfillmentDate')
  },

  mutations: {
    SET_SELECTED_STORE_ID(state, storeId) {
      state.selectedStoreId = storeId;
    },

    SET_STORE_ORDER_COUNTS(state, orderCounts) {
      state.storeOrderCounts = orderCounts;
    },

    SET_CURRENT_PAGE(state, page) {
      state.currentPage = page;
    },

    SET_SELECTED_EXPO_STATUS(state, expoStatus) {
      state.selectedExpoStatus = expoStatus;
    },

    SET_ORDER_TYPE_FILTER(state, orderType) {
      state.orderTypeFilter = orderType;
    },

    SET_SELECTED_ORDER_ID(state, orderId) {
      state.selectedOrderId = orderId;
    },

    SET_SEARCH_RESULTS(state, orders) {
      state.searchResults = orders;
    },

    SET_SEARCH_MODAL_ACTIVE(state, isActive) {
      state.searchModalActive = isActive;
    },

    SET_NOTIFICATIONS_MODAL_ACTIVE(state, isActive) {
      state.notificationsModalActive = isActive;
    },

    SET_HIDE_NOTIFICATIONS(state, hideNotifications) {
      state.hideNotifications = hideNotifications;
    }
  },

  actions: {
    async onLoad({ dispatch, getters, state }) {
      try {
        await dispatch('fetchStores');
        await dispatch('getSelectedStoreIdFromLocalStorage');

        const firstActiveStore = getters.allStores.find(store => store.isActive && !store.isSystem);
        if (!state.selectedStoreId && firstActiveStore) {
          await dispatch('setSelectedStoreId', firstActiveStore.storeId);
        }

        if (state.selectedStoreId) {
          await dispatch('fetchStoreOrderData');
        }
      }
      catch (error) {
        console.error(error);
        throw error;
      }
    },

    // STORE
    fetchStores() {
      try {
        return Store.fetchAll();
      }
      catch (error) {
        throw error;
      }
    },

    setSelectedStoreId({ commit, dispatch }, storeId) {
      commit('SET_SELECTED_STORE_ID', storeId);
      dispatch('setSelectedStoreIdToLocalStorage', storeId);
    },

    getSelectedStoreIdFromLocalStorage({ commit, getters }) {
      const storeId = storage.local.get(`${getters.selectedMerchantId}-selectedStoreId`);
      if (storeId) {
        commit('SET_SELECTED_STORE_ID', storeId);
      }
    },

    setSelectedStoreIdToLocalStorage({ getters }, storeId) {
      storage.local.set(`${getters.selectedMerchantId}-selectedStoreId`, storeId);
    },

    async handleStoreIdChange({ dispatch }, storeId) {
      await Promise.all([
        dispatch('clearExpoOrders'),
        dispatch('setSelectedStoreId', storeId),
        dispatch('setSelectedOrderId', null)
      ]);
      dispatch('fetchStoreOrderData');
    },

    async fetchStoreOrderCounts({ state, commit }) {
      try {
        const orderCounts = await ExpoOrder.fetchOrderCounts(state.selectedStoreId);
        commit('SET_STORE_ORDER_COUNTS', orderCounts);
      }
      catch (error) {
        throw error;
      }
    },

    // ORDERS
    fetchStoreOrders({ state, getters }) {
      try {
        const params = {
          storeId: state.selectedStoreId,
          currentPage: state.currentPage,
          expoStatus: state.selectedExpoStatus,
          pageSize: 10,
          orderSortOrder: getters.orderSortOrder
        };

        switch (state.orderTypeFilter) {
          case orderTypes.LARGE_ORDER:
            params.isLargeOrder = true;
            break;
          case orderTypes.CUSTOMER_PICKUP:
            params.isDelivery = false;
            break;
          case orderTypes.DELIVERY:
            params.isDelivery = true;
            break;
          default:
            break;
        }

        return ExpoOrder.fetchOrders({ params });
      }
      catch (error) {
        throw error;
      }
    },

    async fetchStoreOrderData({ dispatch }) {
      try {
        await Promise.all([
          dispatch('fetchStoreOrderCounts'),
          dispatch('fetchStoreOrders')
        ]);
      }
      catch (error) {
        throw error;
      }
    },

    async refreshOrders({ dispatch }) {
      await dispatch('setCurrentPage', 1);
      dispatch('fetchStoreOrderData');
    },

    async refreshSelectedOrder({ dispatch, state }) {
      const selectedOrder = ExpoOrder.find(state.selectedOrderId);
      if (selectedOrder) {
        await dispatch('fetchOrderDetails', selectedOrder.orderId);
      }
    },

    clearExpoOrders() {
      ExpoOrder.deleteAll();
    },

    async handleExpoStatusChange({ dispatch }, expoStatus) {
      await Promise.all([
        dispatch('setSelectedExpoStatus', expoStatus),
        dispatch('setCurrentPage', 1),
        dispatch('clearExpoOrders'),
        dispatch('setOrderTypeFilter', null)
      ]);

      await dispatch('fetchStoreOrderData');
      await dispatch('refreshSelectedOrder');
    },

    setSelectedExpoStatus({ commit }, expoStatus) {
      commit('SET_SELECTED_EXPO_STATUS', expoStatus);
    },

    async handleOrderTypeFilterChange({ dispatch }, orderType) {
      await Promise.all([
        dispatch('clearExpoOrders'),
        dispatch('setOrderTypeFilter', orderType),
        dispatch('setCurrentPage', 1)
      ]);

      await dispatch('fetchStoreOrderData');
      await dispatch('refreshSelectedOrder');
    },

    setOrderTypeFilter({ commit }, orderType) {
      commit('SET_ORDER_TYPE_FILTER', orderType);
    },

    async selectOrder({ dispatch }, orderId) {
      await dispatch('setSelectedOrderId', orderId);
      await dispatch('fetchOrderDetails', orderId);
    },

    setSelectedOrderId({ commit }, orderId) {
      commit('SET_SELECTED_ORDER_ID', orderId);
    },

    async handleWebsocketEventForOrder({ dispatch, state, getters }, { orderId, newExpoStatus }) {
      const isSelectedExpoStatus = newExpoStatus === state.selectedExpoStatus;
      const loadedOrdersCountLessThanPageCount = getters.expoOrders.length % 10 !== 0;

      if (ExpoOrder.find(orderId) || (isSelectedExpoStatus && loadedOrdersCountLessThanPageCount)) {
        await dispatch('fetchOrderDetails', orderId);
      }
      if (newExpoStatus) {
        dispatch('fetchStoreOrderCounts');
      }
    },

    fetchOrderDetails(_, orderId) {
      try {
        return ExpoOrder.fetchOrder(orderId);
      }
      catch (error) {
        throw error;
      }
    },

    async updateOrderExpoStatus({ state, dispatch }, { orderId, newExpoStatus }) {
      try {
        await ExpoOrder.updateOrderExpoStatus({ orderId, newExpoStatus, storeId: state.selectedStoreId });
        await dispatch('fetchStoreOrderCounts');
      }
      catch (error) {
        throw error;
      }
    },

    async printOrderReceipt(_, orderId) {
      try {
        await ExpoOrder.printOrderReceipt(orderId);
      }
      catch (error) {
        throw error;
      }
    },

    // SEARCH
    async navigateToOrder({ state, dispatch, getters }, orderId) {
      const order = await ExpoOrder.fetchOrder(orderId);

      await dispatch('fetchStoreOrderCounts');

      // Double check when infinite scroll is implemented that this can work with a user
      // scrolling up and down. I.e. they load into page 3 with 10 orders, and then want to scroll
      // up to page 1 or 2
      const orderIndex = state.storeOrderCounts[order.expoStatus].orderIds.indexOf(order.orderId);
      const orderPosition = orderIndex + 1;
      const page = orderPosition > 0 ? Math.ceil(orderPosition / 50) : 1;

      const params = {
        storeId: state.selectedStoreId,
        currentPage: page,
        expoStatus: order.expoStatus,
        pageSize: 50,
        orderSortOrder: getters.orderSortOrder
      };

      await ExpoOrder.fetchOrders({ params });

      await Promise.all([
        dispatch('setCurrentPage', page),
        dispatch('setSelectedExpoStatus', order.expoStatus), // needs to come from CAPI updates to expo order
        dispatch('setSearchModalActive', false),
        dispatch('setSearchResults', [])
      ]);

      await dispatch('setSelectedOrderId', order.orderId);
    },

    setCurrentPage({ commit }, page) {
      commit('SET_CURRENT_PAGE', page);
    },

    setSearchModalActive({ commit }, isActive) {
      commit('SET_SEARCH_MODAL_ACTIVE', isActive);
    },

    async searchOrders({ dispatch, state }, searchQuery) {
      try {
        const params = {
          storeId: state.selectedStoreId,
          currentPage: 1,
          pageSize: 50,
          orderSortOrder: 'RequestedFulfillmentDate',
          ...searchQuery
        };

        const orders = await ExpoOrder.fetchOrders({ params });
        dispatch('setSearchResults', orders);
      }
      catch (error) {
        throw error;
      }
    },

    setSearchResults({ commit }, orders) {
      commit('SET_SEARCH_RESULTS', orders);
    },

    // NOTIFICATIONS
    setNotificationsModalActive({ commit }, isActive) {
      commit('SET_NOTIFICATIONS_MODAL_ACTIVE', isActive);
    },

    toggleNotifications({ commit, state }) {
      commit('SET_HIDE_NOTIFICATIONS', !state.hideNotifications);
    }
  }
};
