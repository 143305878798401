<template>
  <div class="dist-y-xl">
    <div class="card">
      <div class="card-content">
        <div class="card-heading">
          <h2 class="title is-4 is-marginless">Header Links</h2>
        </div>
      </div>
      <div class="nested-table has-border-top has-border-grey-lightest">
        <b-loading :is-full-page="false" :active="isFetchingMerchantLinkHeaders" />
        <div class="table-heading">
          <div class="row">
            <span class="align-left">Name</span>
            <span class="align-center">Actions</span>
          </div>
        </div>

        <draggable
          class="nested-table-section"
          tag="ul"
          group="header-section"
          v-bind="draggableAttributes"
          :value="merchantHeaderLinkHeaders"
          :force-fallback="true"
          @change="handleMerchantLinkHeaderSort"
        >
          <li v-for="header in merchantHeaderLinkHeaders" :key="`header-${header.id}`" :class="['draggable', { 'is-open': !!rowState[header.id] }]">
            <div :class="['row', {'is-deleting': deletingLinkHeaderId === header.id}]">
              <span class="pad-y-sm">
                <b-icon icon="grip-lines" size="is-small" pack="far" class="drag-handle" />
                <span class="link-inverted" @click="toggleRowState(header.id)">
                  {{ header.title }}
                  <b-icon
                    size="is-small"
                    pack="far"
                    :icon="fetchingMerchantLinksId === header.id || sortingMerchantLinksId === header.id ? 'spinner-third' : 'angle-right'"
                    :class="[
                      'open-indicator',
                      {
                        'is-open': !!rowState[header.id],
                        'spin': fetchingMerchantLinksId === header.id || sortingMerchantLinksId === header.id
                      }
                    ]"
                  />
                </span>
              </span>

              <span class="align-center">
                <b-button slot="trigger" class="is-transparent" type="is-white" @click="openMerchantLinkHeaderModal(header)">
                  <b-icon
                    icon="pencil"
                    size="is-small"
                  />
                </b-button>

                <b-button slot="trigger" class="is-transparent" type="is-white" @click="openConfirmDelete({ type: 'merchantLinkHeader', id: header.id })">
                  <b-icon
                    icon="trash-alt"
                    type="is-danger"
                    size="is-small"
                  />
                </b-button>
              </span>
            </div>

            <draggable
              v-if="!!rowState[header.id]"
              tag="ul"
              class="nested-table-section"
              :group="`${header.id}-link-section`"
              v-bind="draggableAttributes"
              :value="header.merchantLinks"
              :force-fallback="true"
              @change="(event) => handleMerchantLinkSort(event, header.merchantLinks)"
            >
              <template #footer>
                <li class="row sub-row">
                  <span :style="{ paddingLeft: '2.8rem' }">
                    <b-button
                      class="is-transparent"
                      icon-left="plus"
                      type="is-primary"
                      inverted
                      @click="openHeaderMerchantLinkModal({ merchantLinkHeader: header })"
                    >
                      Link
                    </b-button>
                  </span>
                </li>
              </template>
              <li v-for="merchantLink in header.merchantLinks" :key="`link-${merchantLink.id}`" class="draggable">
                <div class="row sub-row">
                  <span class="pad-y-sm" :style="{ paddingLeft: '2.8rem' }">
                    <b-icon icon="grip-lines" size="is-small" pack="far" class="drag-handle" />
                    <div>
                      <p class="mar-b-none" style="line-height: 1.2">{{ merchantLink.displayName }}</p>
                      <span class="has-text-grey-darker is-size-7">{{ merchantLink.link }}</span>
                    </div>
                  </span>
                  <span class="align-center">
                    <b-button slot="trigger" class="is-transparent" type="is-white" @click="openHeaderMerchantLinkModal({ link: merchantLink, merchantLinkHeader: header })">
                      <b-icon
                        icon="pencil"
                        size="is-small"
                      />
                    </b-button>

                    <b-button slot="trigger" class="is-transparent" type="is-white" @click="openConfirmDelete({ type: 'merchantLink', id: merchantLink.id })">
                      <b-icon
                        icon="trash-alt"
                        type="is-danger"
                        size="is-small"
                      />
                    </b-button>
                  </span>
                </div>
              </li>
            </draggable>
          </li>
        </draggable>
      </div>

      <div class="pad-x pad-y-sm">
        <b-button
          type="is-primary"
          inverted
          class="is-transparent"
          icon-left="plus"
          @click="openMerchantLinkHeaderModal()"
        >
          Header
        </b-button>
      </div>
    </div>

    <!-- FOOTER LINKS -->
    <div class="card">
      <div class="card-content">
        <div class="card-heading">
          <h2 class="title is-4 is-marginless">Footer Links</h2>
        </div>
      </div>
      <div class="nested-table has-border-top has-border-grey-lightest">
        <b-loading :is-full-page="false" :active="isFetchingMerchantLinkHeaders || fetchingMerchantLinksId === merchantHeaderLinkFooter.id" />
        <div class="table-heading">
          <div class="row">
            <span class="align-left">Name</span>
            <span class="align-center">Actions</span>
          </div>
        </div>

        <draggable
          class="nested-table-section"
          tag="ul"
          group="footer-section"
          v-bind="draggableAttributes"
          :value="merchantHeaderLinkFooter.merchantLinks"
          :force-fallback="true"
          @change="(event) => handleMerchantLinkSort(event, merchantHeaderLinkFooter.merchantLinks)"
        >
          <li v-for="link in merchantHeaderLinkFooter.merchantLinks" :key="`footer-${link.id}`" class="draggable">
            <div :class="['row', {'is-deleting': deletingLinkId === link.id}]">
              <span class="pad-y-sm">
                <b-icon icon="grip-lines" size="is-small" pack="far" class="drag-handle" />
                <div>
                  <p class="mar-b-none" style="line-height: 1.2">{{ link.displayName }}</p>
                  <span class="has-text-grey-darker is-size-7">{{ link.link }}</span>
                </div>
              </span>
              <span class="align-center">
                <b-button slot="trigger" class="is-transparent" type="is-white" @click="openFooterMerchantLinkModal(link)">
                  <b-icon
                    icon="pencil"
                    size="is-small"
                  />
                </b-button>

                <b-button slot="trigger" class="is-transparent" type="is-white" @click="openConfirmDelete({ type: 'merchantLink', id: link.id })">
                  <b-icon
                    icon="trash-alt"
                    type="is-danger"
                    size="is-small"
                  />
                </b-button>
              </span>
            </div>
          </li>
        </draggable>
      </div>

      <div class="pad-x pad-y-sm">
        <b-button
          type="is-primary"
          inverted
          class="is-transparent"
          icon-left="plus"
          @click="openFooterMerchantLinkModal()"
        >
          Link
        </b-button>
      </div>
    </div>
  </div>
</template>

 <script>
  import draggable from 'vuedraggable';
  import merchantMixin from '@/mixins/merchant';
  import addEditMerchantLinkHeaderModal from './modal/add-edit-merchant-link-header-modal.vue';
  import addEditMerchantLinkModal from './modal/add-edit-merchant-link-modal.vue';

  // classes
  import MerchantLinkHeader from '@/store/classes/MerchantLinkHeader';
  import MerchantLink from '@/store/classes/MerchantLink';
  import { externalLinkTypes } from '@/constants/merchantLinks';


  export default {
    name: 'ContentMerchantLinks',

    components: { draggable },

    mixins: [merchantMixin],

    data() {
      return {
        isLoading: false,
        draggableAttributes: {
          animation: '200',
          ghostClass: 'ghost',
          handle: '.drag-handle',
          draggable: '.draggable'
        },
        externalLinkTypes,
        rowState: {}
      };
    },

    computed: {
      merchantHeaderLinkHeaders() {
        return MerchantLinkHeader
          .query()
          .with('merchantLinks', query => query.orderBy('sortOrder'))
          .where('linkTypeId', externalLinkTypes.HEADER)
          .orderBy('sortOrder')
          .get();
      },

      merchantHeaderLinkFooter() {
        return MerchantLinkHeader
          .query()
          .with('merchantLinks', query => query.orderBy('sortOrder'))
          .where('linkTypeId', externalLinkTypes.FOOTER)
          .first() || new MerchantLinkHeader({ linkTypeId: externalLinkTypes.FOOTER, merchantId: this.$_selectedMerchantId });
      },

      deletingLinkHeaderId() {
        return MerchantLinkHeader.$state().deletingId;
      },

      isFetchingMerchantLinkHeaders() {
        return MerchantLinkHeader.$state().fetching;
      },

      sortingMerchantLinkHeaders() {
        return MerchantLinkHeader.$state().sorting;
      },

      deletingLinkId() {
        return MerchantLink.$state().deletingId;
      },

      fetchingMerchantLinksId() {
        return MerchantLink.$state().fetchingId;
      },

      sortingMerchantLinksId() {
        return MerchantLink.$state().sortingParentId;
      }
    },

    created() {
      this.onCreated();
    },

    methods: {
      async onCreated() {
        await MerchantLinkHeader.fetchMerchantLinkHeaders();
        if (this.merchantHeaderLinkFooter.id) {
          await MerchantLink.fetchMerchantLinks(this.merchantHeaderLinkFooter.id);
        }
      },

      async toggleRowState(headerId) {
        const wasNeverOpen = this.rowState[headerId] === undefined;
        if (wasNeverOpen) {
          await this.fetchMerchantLinksForHeader(headerId);
        }

        this.$set(this.rowState, headerId, !this.rowState[headerId]);
      },

      async addFooterHeaderLink() {
        try {
          const newMerchantLinkHeader = {
            linkTypeId: externalLinkTypes.FOOTER,
            merchantId: this.$_selectedMerchantId
          };
          return await MerchantLinkHeader.createMerchantLinkHeader(newMerchantLinkHeader);
        }

        catch (error) {
          this.$_onRequestError({
            error,
            toastOptions: {
              message: 'There was an error adding your new footer header'
            }
          });
        }
      },

      openMerchantLinkHeaderModal(header) {
        const merchantLinkHeader = header || new MerchantLinkHeader({ merchantId: this.$selectedMerchantId, linkTypeId: externalLinkTypes.HEADER });
        this.$buefy.modal.open({
          parent: this,
          component: addEditMerchantLinkHeaderModal,
          hasModalCard: true,
          trapFocus: true,
          canCancel: false,
          customClass: 'auto-width',
          events: {
            'add-header': this.toggleRowState
          },
          props: { merchantLinkHeader }
        });
      },

      openHeaderMerchantLinkModal({ link, merchantLinkHeader }) {
        const merchantLink = link || new MerchantLink({ merchantLinkHeaderId: merchantLinkHeader.id });
        this.$buefy.modal.open({
          parent: this,
          component: addEditMerchantLinkModal,
          hasModalCard: true,
          trapFocus: true,
          canCancel: false,
          customClass: 'auto-width',
          props: { merchantLink, merchantLinkHeaderName: merchantLinkHeader.title }
        });
      },

      async openFooterMerchantLinkModal(link) {
        let merchantHeaderLinkFooter = this.merchantHeaderLinkFooter;
        if (!this.merchantHeaderLinkFooter.id) {
          merchantHeaderLinkFooter = await this.addFooterHeaderLink();
        }
        const merchantLink = link || new MerchantLink({ merchantLinkHeaderId: merchantHeaderLinkFooter.id });
        this.$buefy.modal.open({
          parent: this,
          component: addEditMerchantLinkModal,
          hasModalCard: true,
          trapFocus: true,
          canCancel: false,
          customClass: 'auto-width',
          props: { merchantLink, merchantLinkHeaderName: 'Footer Links' }
        });
      },

      openConfirmDelete({ type, id }) {
        const displayMessageType = type === 'merchantLinkHeader' ? 'link header' : 'link';
        const title = type === 'merchantLinkHeader' ? 'Delete Header' : 'Delete Link';
        this.$buefy.dialog.confirm({
          title,
          message: `Are you sure you want to delete this ${displayMessageType}?${type === 'merchantLinkHeader' ? ' This will delete all links nested under this header.' : ''}`,
          hasIcon: true,
          confirmText: 'Delete',
          icon: 'trash-alt',
          type: 'is-danger',
          onConfirm: () => this.handleConfirmDelete({ type, id })
        });
      },

      async fetchMerchantLinksForHeader(merchantLinkHeaderId) {
        try {
          await MerchantLink.fetchMerchantLinks(merchantLinkHeaderId);
        }
        catch (error) {
          this.$_onRequestError({
            toastOptions: {
              message: 'There was an error fetching the links'
            },
            error
          });
        }
      },

      handleConfirmDelete({ type, id }) {
        if (type === 'merchantLinkHeader') {
          this.removeMerchantLinkHeader(id);
        }
        else {
          this.removeMerchantLink(id);
        }
      },

      async removeMerchantLinkHeader(merchantLinkHeaderId) {
        try {
          await MerchantLinkHeader.removeMerchantLinkHeader(merchantLinkHeaderId);

          this.$_onRequestSuccess({
            toastOptions: {
              message: 'Link header has been successfully deleted!'
            }
          });
        }
        catch (error) {
          this.$_onRequestError({
            toastOptions: {
              message: 'There was an error deleting the link header'
            },
            error
          });
        }
      },

      async removeMerchantLink(merchantLinkId) {
        const isRemovingFooterLink = !!this.merchantHeaderLinkFooter.merchantLinks.find(link => link.id === merchantLinkId);
        const isLastFooterLink = this.merchantHeaderLinkFooter.merchantLinks.length === 1;

        try {
          const updatedMerchantLinks = await MerchantLink.removeMerchantLink(merchantLinkId);
          MerchantLink.insert({
            data: updatedMerchantLinks
          });

          if (isRemovingFooterLink && isLastFooterLink) {
            await MerchantLinkHeader.removeMerchantLinkHeader(this.merchantHeaderLinkFooter.id);
          }

          this.$_onRequestSuccess({
            toastOptions: {
              message: 'Link has been successfully deleted!'
            }
          });
        }
        catch (error) {
          this.$_onRequestError({
            toastOptions: {
              message: 'There was an error deleting the link'
            },
            error
          });
        }
      },

      async handleMerchantLinkHeaderSort({ moved }) {
        try {
          await MerchantLinkHeader.sortMerchantLinkHeader({ fromSortOrder: moved.oldIndex, toSortOrder: moved.newIndex, array: this.merchantHeaderLinkHeaders });

          this.$_onRequestSuccess({
            toastOptions: {
              message: `<b>${moved.element.text}</b> moved from sort order <b>${moved.oldIndex + 1}</b> to <b>${moved.newIndex + 1}</b>`
            }
          });
        }

        catch (error) {
          this.$_onRequestError({
            toastOptions: { message: 'There was an error updating the sort order of your link headers' },
            error
          });
        }
      },

      async handleMerchantLinkSort({ moved }, merchantLinks) {
        try {
          await MerchantLink.sortMerchantLinks({ fromSortOrder: moved.oldIndex, toSortOrder: moved.newIndex, array: merchantLinks });

          this.$_onRequestSuccess({
            toastOptions: {
              message: `<b>${moved.element.text}</b> moved from sort order <b>${moved.oldIndex + 1}</b> to <b>${moved.newIndex + 1}</b>`
            }
          });
        }

        catch (error) {
          this.$_onRequestError({
            toastOptions: { message: 'There was an error updating the sort order of your links' },
            error
          });
        }
      }
    }
  };
</script>

<style lang="sass" scoped>
  .row
    grid-template-columns: 1fr 125px

    &.is-deleting
      opacity: 0.5
      background-color: $danger-light

  .card-heading
    display: flex
    justify-content: space-between
    align-items: center

</style>
