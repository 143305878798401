<template>
  <div class="is-flex align-center app-setting-resource-input" @click.stop>
    <b-switch
      v-if="resourceType === 'Boolean'"
      :value="booleanValue"
      type="is-primary"
      size="is-small"
      class="has-cursor-cell"
      @input="updateValue"
      @click.native.stop
    />
    <span v-else-if="value === undefined" class="has-text-grey-light has-text-weight-normal">
      (No value)
    </span>
    <div v-else-if="value !== null" class="non-boolean-input">
      <div
        v-if="!editing"
        class="is-flex align-center is-relative pad-r-sm has-cursor-cell"
        @click="startEditing"
      >
        <span class="has-text-weight-medium has-text-grey text-ellipsis pad-r-xs">
          {{ displayValue }}
        </span>
        <b-icon
          icon="pen-to-square"
          size="is-small"
          class="edit-icon"
          type="is-primary"
        />
      </div>
      <div v-else class="is-flex align-center gap-xs">
        <b-input
          v-if="resourceType === 'String'"
          v-model="editValue"
          size="is-small"
          style="width: 200px"
          @click.native.stop
        />
        <b-input
          v-else-if="resourceType === 'Number'"
          v-model.number="editValue"
          type="number"
          size="is-small"
          @click.native.stop
        />
        <b-input
          v-else-if="resourceType === 'Dollar'"
          v-model.number="editValue"
          type="number"
          step="0.01"
          size="is-small"
          @click.native.stop
        />
        <b-button
          size="is-small"
          type="is-primary"
          @click="confirmEdit"
        >
          Save
        </b-button>
        <b-button
          size="is-small"
          @click="cancelEdit"
        >
          Cancel
        </b-button>
      </div>
    </div>
    <span v-else class="has-text-grey-light has-text-weight-normal">(No value)</span>
  </div>
</template>

<script>
  export default {
    name: 'GlobalSearchSettingsInput',

    props: {
      value: {
        type: [String, Number, Boolean],
        default: null
      },
      subOption: {
        type: String,
        required: true
      },
      resourceType: {
        type: String,
        required: true
      }
    },

    data() {
      return {
        editing: false,
        editValue: null
      };
    },

    computed: {
      booleanValue() {
        return Boolean(this.value);
      },

      displayValue() {
        if (this.value === null || this.value === undefined) return '(No value)';
        if (this.resourceType === 'Dollar') {
          return `$${Number(this.value).toFixed(2)}`;
        }
        return String(this.value);
      }
    },

    methods: {
      startEditing() {
        this.editing = true;
        this.editValue = this.value;
      },

      confirmEdit() {
        this.updateValue(this.editValue);
        this.editing = false;
      },

      cancelEdit() {
        this.editing = false;
      },

      updateValue(newValue) {
        this.$emit('update', {
          subOption: this.subOption,
          value: newValue
        });
      }
    }
  };
</script>

<style lang="sass" scoped>
  .app-setting-resource-input
    max-width: 400px
    width: 100%

  .non-boolean-input
    flex: 1
    min-width: 0
</style>
