var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"card",staticStyle:{"scroll-margin":"1.5rem"}},[_c('b-table',{staticClass:"is-middle-aligned no-header-wrap row-clickable",attrs:{"data":_vm.isLoading ? [] : _vm.auditLogs,"scrollable":"","backend-sorting":"","default-sort":['sort_direction', 'desc'],"hoverable":"","paginated":"","per-page":"50","pagination-position":"both","current-page":_vm.page},on:{"update:currentPage":function($event){_vm.page=$event},"update:current-page":function($event){_vm.page=$event},"sort":function($event){return _vm.handleSort(arguments[1])},"click":_vm.openModal},scopedSlots:_vm._u([{key:"top-left",fn:function(){return [_c('b-select',{attrs:{"placeholder":"Select a Resource"},on:{"input":_vm.handleModelSelect}},[_c('option',{domProps:{"value":null}},[_vm._v("All")]),_c('option',{attrs:{"disabled":""}},[_vm._v("–")]),_vm._l((_vm.models),function(model){return _c('option',{key:model,domProps:{"value":model}},[_vm._v(" "+_vm._s(_vm.capitalCase(model))+" ")])})],2)]},proxy:true}])},[_c('b-table-column',{attrs:{"label":"Resource"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('span',{staticClass:"no-wrap-text"},[_vm._v(" "+_vm._s(row.resource)+" ")])]}}])}),_c('b-table-column',{attrs:{"label":"Resource ID"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('span',{staticClass:"no-wrap-text is-monospaced"},[_vm._v(" "+_vm._s(row.resourceId)+" ")])]}}])}),_c('b-table-column',{attrs:{"label":"Event","centered":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('b-tag',{staticClass:"is-full-width",attrs:{"type":_vm.badgeTypes[row.event]}},[_vm._v(" "+_vm._s(_vm._f("capitalize")(row.event))+" ")])]}}])}),_c('b-table-column',{attrs:{"label":"User Info"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [(!row.user && !row.email)?_c('p',{staticClass:"has-text-grey-light"},[_vm._v("N/A")]):[_c('p',{class:['no-wrap-text', { 'has-text-grey-light': !row.user }]},[_vm._v(" "+_vm._s(row.user || 'N/A')+" ")]),_c('p',{class:['subtitle is-7 no-wrap-text', { 'has-text-grey-light': !row.email }]},[_vm._v(" "+_vm._s(row.email || 'N/A')+" ")])]]}}])}),_c('b-table-column',{attrs:{"label":"Date","field":"sort_direction","sortable":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('span',{staticClass:"no-wrap-text"},[_vm._v(" "+_vm._s(_vm._f("moment")(row.createdAt,'MM/DD/YY @ h:mma'))+" ")])]}}])}),_c('b-table-column',{attrs:{"label":"Changes"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('div',{staticClass:"is-flex gap-sm flex-wrap"},[_vm._l((Object.keys(JSON.parse(row.changes)).slice(0, 4)),function(key){return _c('b-tag',{key:key,staticClass:"is-monospaced"},[_vm._v(" "+_vm._s(key)+" ")])}),(Object.keys(JSON.parse(row.changes)).slice(4).length)?_c('span',{staticClass:"has-text-grey no-wrap-text"},[_c('tippy',{scopedSlots:_vm._u([{key:"trigger",fn:function(){return [_vm._v(" + "+_vm._s(Object.keys(JSON.parse(row.changes)).slice(4).length)+" more ")]},proxy:true}],null,true)},[_c('ul',{staticClass:"has-list-styles has-text-left is-size-6 is-monospaced"},_vm._l((Object.keys(JSON.parse(row.changes)).slice(4)),function(key){return _c('li',{key:key},[_vm._v(" "+_vm._s(key)+" ")])}),0)])],1):_vm._e()],2)]}}])}),_c('template',{slot:"empty"},[_c('empty-table-loader',{attrs:{"has-icon":false,"message":"No Logs Found...","loading":_vm.isLoading}})],1)],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }