<template>
  <div
    class="is-grid col-2 pad-sm"
    :class="{ 'has-border-bottom has-border-grey-lighter': !hideBottomBorder }"
    data-test-class="review-row"
  >
    <p class="title is-6 mar-none" data-test-id="title">{{ title }}</p>
    <p class="subtitle is-6 mar-none" data-test-id="subtitle">{{ subtitle }}</p>
  </div>
</template>

<script>
  export default {
    name: 'ReviewRow',

    props: {
      title: {
        type: String,
        required: true
      },
      subtitle: {
        type: [String, Number],
        default: 'Not Set'
      },
      hideBottomBorder: {
        type: Boolean,
        default: false
      }
    }
  };
</script>
