var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.$_selectedMerchant)?_c('div',[_c('hero-banner',{scopedSlots:_vm._u([{key:"right",fn:function(){return [(_vm.campaigns.length)?_c('b-button',{attrs:{"rounded":"","inverted":"","size":"is-medium","type":"is-primary","icon-left":"plus"},on:{"click":function($event){return _vm.openCampaignModal()}}},[_vm._v(" New Campaign ")]):_vm._e()]},proxy:true}],null,false,1442233252)},[_c('h1',{staticClass:"title is-2"},[_vm._v("Campaigns")])]),_c('div',{staticClass:"section"},[_c('div',{staticClass:"container"},[(_vm.campaigns.length)?_c('div',{staticClass:"card"},[(_vm.isFetching || _vm.isDeleting)?_c('b-loading',{attrs:{"active":_vm.isFetching || _vm.isDeleting}}):_vm._e(),_c('searchable-table',{attrs:{"table-data":_vm.campaigns,"search":{
            placeholder: 'Search by Campaign Name',
            keys: ['internalName']
          },"filters":_vm.tableFilters},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var filteredData = ref.filteredData;
return [_c('b-table',{staticClass:"is-middle-aligned",attrs:{"data":_vm.isFetching ? [] : filteredData,"paginated":_vm.campaigns.length > _vm.pageLimit,"pagination-simple":"","per-page":_vm.pageLimit,"mobile-cards":false,"scrollable":"","default-sort":['updatedAt', 'desc']},on:{"sort":_vm.handleSort}},[_c('b-table-column',{attrs:{"sortable":"","field":"internalName","label":"Name","cell-class":"no-wrap-text"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var row = ref.row;
return [_c('span',{staticClass:"link",on:{"click":function($event){return _vm.openCampaignModal(row)}}},[_vm._v(" "+_vm._s(row.internalName || 'N/A')+" ")]),_c('div',{staticClass:"table-cell-text-overflow"},[_c('p',{class:['is-size-7', {'has-text-grey-light': !row.description}]},[_vm._v(" "+_vm._s(row.description || 'N/A')+" ")])])]}}],null,true)}),_c('b-table-column',{attrs:{"sortable":"","field":"startDate","custom-sort":_vm.sortByStartDate,"cell-class":"no-wrap-text"},scopedSlots:_vm._u([{key:"header",fn:function(){return [_vm._v(" Schedule "),_c('span',{staticClass:"has-text-weight-normal has-text-grey"},[_vm._v("("+_vm._s(_vm.moment.tz.zone(_vm.$_selectedMerchant.features.campaignsIanaTimezoneId).abbr(Date.now()))+")")]),(_vm.sortField === 'startDate')?_c('b-icon',{class:_vm.sortDirection === 'desc' && 'flip-arrow',attrs:{"icon":"arrow-up"}}):_vm._e()]},proxy:true},{key:"default",fn:function(ref){
          var ref_row = ref.row;
          var startDate = ref_row.startDate;
          var deliveryType = ref_row.deliveryType;
          var state = ref_row.state;
          var updatedAt = ref_row.updatedAt;
return [(deliveryType === _vm.campaignDeliveryTypes.IMMEDIATE)?[(state === _vm.campaignStates.COMPLETE)?_c('span',[_c('b',[_vm._v("Sent")]),_vm._v(" on "+_vm._s(_vm._f("moment")(updatedAt,'MM/DD/YYYY @ hh:mm A z', _vm.$_selectedMerchant.features.campaignsIanaTimezoneId))+" ")]):_c('span',[_c('b',[_vm._v("Sends")]),_vm._v(" Immediately ")])]:(startDate)?_c('p',{class:state === _vm.campaignStates.CANCELLED && 'has-text-strikethrough'},[_c('b',[_vm._v(_vm._s(state === _vm.campaignStates.COMPLETE ? 'Sent' : 'Sends'))]),_vm._v(" on "),_c('span',{directives:[{name:"tippy",rawName:"v-tippy",value:({
                      content: _vm.moment.tz(startDate, _vm.moment.tz.guess()).format('MM/DD/YYYY @ hh:mm A z'),
                      delay: [1000, 0]
                    }),expression:"{\n                      content: moment.tz(startDate, moment.tz.guess()).format('MM/DD/YYYY @ hh:mm A z'),\n                      delay: [1000, 0]\n                    }"}]},[_vm._v(" "+_vm._s(_vm._f("moment")(startDate,'MM/DD/YYYY @ hh:mm A z', _vm.$_selectedMerchant.features.campaignsIanaTimezoneId))+" ")])]):_c('span',{staticClass:"has-text-grey"},[_vm._v("N/A")])]}}],null,true)}),_c('b-table-column',{attrs:{"sortable":"","field":"updatedAt","label":"Last Updated","cell-class":"no-wrap-text"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var updatedAt = ref.row.updatedAt;
return [(updatedAt)?_c('span',[_vm._v(" "+_vm._s(_vm._f("moment")(updatedAt,'MM/DD/YYYY @ hh:mm A'))+" ")]):_c('span',{staticClass:"has-text-grey"},[_vm._v("N/A")])]}}],null,true)}),_c('b-table-column',{attrs:{"field":"guid","label":"ID","cell-class":"no-wrap-text is-monospaced","sortable":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var guid = ref.row.guid;
return [_vm._v(" "+_vm._s(guid)+" ")]}}],null,true)}),_c('b-table-column',{attrs:{"sortable":"","centered":"","custom-sort":_vm.sortByState,"field":"state","label":"Status","width":"1"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var state = ref.row.state;
return [_c('b-tag',{class:state},[_vm._v(" "+_vm._s(_vm._f("capitalize")(state))+" ")])]}}],null,true)}),_c('b-table-column',{attrs:{"centered":"","cell-class":"actions","label":"Actions","width":"1","field":"Actions"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var row = ref.row;
return [_c('dropdown-menu',{attrs:{"position":"bottom-end","mobile-modal":false}},[_c('b-button',{staticClass:"is-transparent",attrs:{"slot":"trigger","type":"is-white"},slot:"trigger"},[_c('b-icon',{attrs:{"icon":"ellipsis-v","pack":"far","size":"is-small"}})],1),_c('b-dropdown-item',{on:{"click":function($event){return _vm.openCampaignModal(row)}}},[_c('b-icon',{staticClass:"mar-r-sm",attrs:{"icon":row.state === _vm.campaignStates.DRAFT ? 'pencil' : 'eye',"size":"is-small"}}),_vm._v(" "+_vm._s(row.state === _vm.campaignStates.DRAFT ? 'Edit' : 'View')+" Campaign ")],1),_c('hr',{staticClass:"dropdown-divider"}),_c('b-dropdown-item',{staticClass:"is-danger",attrs:{"disabled":![_vm.campaignStates.SCHEDULED, _vm.campaignStates.LIVE].includes(row.state)},on:{"click":function($event){return _vm.confirmCancel(row)}}},[_c('b-icon',{staticClass:"mar-r-sm",attrs:{"icon":"calendar-times","size":"is-small"}}),_vm._v(" Cancel Campaign ")],1),_c('b-dropdown-item',{staticClass:"is-danger",attrs:{"disabled":!_vm.isCampaignDeletable(row.state)},on:{"click":function($event){return _vm.openConfirmCampaignDeletion(row)}}},[_c('b-icon',{staticClass:"mar-r-sm",attrs:{"size":"is-small","type":"is-danger","icon":"trash-alt"}}),_vm._v(" Delete Campaign ")],1)],1)]}}],null,true)}),_c('template',{slot:"empty"},[_c('empty-table-loader',{attrs:{"icon":"paper-plane","message":"No Campaigns Found...","loading":_vm.isFetching}})],1)],2)]}}],null,false,2547187144)})],1):_c('empty-state-card',{attrs:{"type":"is-primary","title":"Create Campaign","message":"Create a new campaign to engage with your customers","icon":"paper-plane"},scopedSlots:_vm._u([{key:"buttons",fn:function(){return [_c('b-button',{attrs:{"type":"is-primary","rounded":"","icon-left":"plus"},on:{"click":function($event){return _vm.openCampaignModal()}}},[_vm._v(" New Campaign ")])]},proxy:true}],null,false,528231453)})],1)])],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }