import { Model } from '@vuex-orm/core';
import http from '@/services/http';
import filterObjectKeys from '@/helpers/filter-object-keys';



export default class Program extends Model {
  static entity = 'programs'

  static primaryKey = 'publicId'

  // FIELDS //////////////////////
  static fields() {
    return {
      publicId: this.attr(''),
      name: this.attr(''),
      description: this.attr(''),
      refundMode: this.attr(''),
      loyaltyNumberPrefix: this.attr('')
    };
  }



  // STATE //////////////////////
  static state() {
    return {
      fetching: false,
      submitting: false
    };
  }

  static $state() {
    return this.store().state.entities.programs;
  }


  // ACTIONS //////////////////////
  static async fetchPrograms(options = {}) {
    try {
      this.commit((state) => {
        state.fetching = true;
      });

      const alreadyFetched = this.query().exists();
      const merchantId = this.store().state.entities.merchants.selectedMerchantId;

      if (!alreadyFetched || options.forceFetch) {
        const { data } = await http.get(`/merchants/${merchantId}/programs`);

        this.create({ data: data.programs });
      }
    }

    catch (error) {
      throw error;
    }

    finally {
      this.commit((state) => {
        state.fetching = false;
      });
    }
  }

  static async createProgram(program) {
    try {
      this.commit((state) => {
        state.submitting = true;
      });

      const merchantId = this.store().state.entities.merchants.selectedMerchantId;
      const { data } = await http.post(`/merchants/${merchantId}/programs`, {
        program: filterObjectKeys(program, ['name'])
      });

      this.insert({ data: data.program });
    }

    catch (error) {
      throw error;
    }

    finally {
      this.commit((state) => {
        state.submitting = false;
      });
    }
  }

  static async updateProgram({ publicId, ...program }) {
    try {
      this.commit((state) => {
        state.submitting = true;
      });

      const merchantId = this.store().state.entities.merchants.selectedMerchantId;
      const { data } = await http.put(`/merchants/${merchantId}/programs/${publicId}`, { program });

      this.insert({ data: data.program });
    }

    catch (error) {
      throw error;
    }

    finally {
      this.commit((state) => {
        state.submitting = false;
      });
    }
  }
}
