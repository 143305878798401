<template>
  <takeover>
    <header class="has-text-centered mar-b-xl">
      <img class="image is-inline is-64x64" src="/images/cardfree-icon-logo.svg" alt="Admin Portal - Cardfree">
      <h1 class="title">Reset Password</h1>
    </header>
    <password-form :reset-password-token="$route.query.token" />
  </takeover>
</template>

<script>
  import passwordForm from './password-form.vue';

  export default {
    name: 'ResetPassword',

    components: { passwordForm },

    metaInfo() {
      return {
        title: 'Reset Password'
      };
    }
  };
</script>
