<template>
  <validation-provider
    v-slot="{
      classes,
      dirty,
      errors,
      required
    }"
    slim
    :bails="bails"
    :name="label"
    :vid="name"
    :rules="rules"
    :custom-messages="customMessages"
  >
    <!-- autocomplete="off" doesn't work anymore, but any random string works -->
    <!-- ref: https://github.com/sagalbot/vue-select/issues/324 -->
    <b-field
      :expanded="expanded"
      :horizontal="horizontal"
      :autocomplete="autocompleteSetting"
      :label="hideLabel ? '' : label"
      :label-position="labelPosition"
      :label-for="name"
      :type="{ 'is-danger': !![...errors, ...backendValidationErrors].length }"
      :message="getMessage(errors) | capitalize"
      :class="{...classes, 'show-edited': showEdited}"
    >
      <template v-if="!hideLabel" #label>
        <div :class="subLabelOnSide && 'sub-label-side'">
          <div class="is-relative dist-x-xs">
            <!-- Show Edited Dot -->
            <b-icon
              v-if="showEdited && dirty"
              v-tippy="{
                content: 'This field has been modified',
                maxWidth: 300,
                delay: [500, 0],
                placement: 'top-start'
              }"
              icon="circle"
              class="edited-indicator"
            />

            <span :class="{ 'required-indicator': required && !hideRequiredIndicator }">{{ label }}</span>

            <!-- Tooltip -->
            <b-icon
              v-if="tooltip"
              v-tippy="{
                content: tooltip,
                placement: tooltipPlacement,
                maxWidth: 300,
                delay: [150, 0]
              }"
              icon="info-square"
              size="is-small"
              type="is-grey"
            />

            <b-icon
              v-if="!![...errors, ...backendValidationErrors].length"
              icon="exclamation-circle"
              size="is-small"
              type="is-danger"
            />
          </div>

          <slot name="subLabel">
            <p
              v-if="subLabel"
              class="sub-label is-size-7 has-text-weight-normal has-text-grey"
              v-html="subLabel"
            />
          </slot>
        </div>
      </template>

      <slot />

    </b-field>
  </validation-provider>
</template>

<script>
  export default {
    name: 'ValidatedInput',

    props: {
      backendValidationErrors: {
        type: Array,
        default() {
          return [];
        }
      },

      bails: {
        type: Boolean,
        default: true
      },

      customMessages: {
        type: Object,
        default: undefined
      },

      disableAutocomplete: {
        type: Boolean,
        default: false
      },

      expanded: {
        type: Boolean,
        default: false
      },

      hideErrorMessages: {
        type: Boolean,
        default: false
      },

      hideLabel: {
        type: Boolean,
        default: false
      },

      hideRequiredIndicator: {
        type: Boolean,
        default: false
      },

      horizontal: {
        type: Boolean,
        default: false
      },

      label: {
        type: String,
        required: true
      },

      subLabel: {
        type: String,
        default: ''
      },

      subLabelOnSide: {
        type: Boolean,
        default: false
      },

      labelPosition: {
        type: String,
        default: null
      },

      message: {
        type: String,
        default: ''
      },

      name: {
        type: String,
        required: true,
        validator(val) {
          // NOTE: name prop should always be camelCase,
          // matching the field its bound to, in order to populate the correct validation key
          if (!val.length) {
            return false;
          }
          const isOneWord = val.split(' ').length === 1;
          const firstLetterIsUpperCase = val[0] === val[0].toUpperCase();
          return isOneWord && !firstLetterIsUpperCase;
        }
      },

      rules: {
        type: [String, Object],
        default: null
      },

      showEdited: {
        type: Boolean,
        default: false
      },

      tooltip: {
        type: String,
        default: null
      },

      tooltipPlacement: {
        type: String,
        default: 'top'
      }
    },
    computed: {
      autocompleteSetting() {
        // autocomplete="off" doesn't work anymore, but any random string works
        // https://github.com/sagalbot/vue-select/issues/324
        return this.disableAutocomplete ? 'null' : 'on';
      }
    },

    methods: {
      getMessage(errors = []) {
        if (this.hideErrorMessages) {
          return null;
        }
        const hasErrors = [...errors, ...this.backendValidationErrors].length;

        if (hasErrors) {
          return this.backendValidationErrors.length ? this.backendValidationErrors : errors;
        }

        return this.message;
      }
    }
  };
</script>

<style lang="sass" scoped>


  .sub-label-side
    display: flex
    align-items: baseline

    .sub-label
      margin-left: $size-extra-small

  .edited-indicator
    position: absolute
    left: -1.4rem
    font-size: 0.5rem
    top: 50%
    transform: translateY(-50%)
    color: $primary-light

  // https://vue-loader.vuejs.org/guide/scoped-css.html#deep-selectors
  ::v-deep.field.is-floating-label
    .label
      transition: 400ms
    &.dirty.show-edited
      .label
        transform: translateY(-0.6rem)

    .edited-indicator
      font-size: 0.45rem
      left: -1.3rem

  @media (max-width: $tablet)
    .is-floating-label
      margin-top: 1.5rem !important
</style>
