<template>
  <div>
    <tabs-page
      v-if="$_selectedMerchant"
      :tabs="tabs"
      :keep-alive="false"
      title="Gift Card Transactions"
      show-single-tab
    />
  </div>
</template>

<script>
  import merchantMixin from '@/mixins/merchant';
  import giftCardTransactionList from './gift-card-transactions-list.vue';

  export default {
    name: 'GiftCardTransactions',

    mixins: [merchantMixin],

    computed: {
      tabs() {
        return [
          {
            display: 'Gift Card Transactions',
            name: 'giftCardTransactionList',
            component: giftCardTransactionList
          }
        ];
      }
    }
  };

</script>

<style lang="sass" scoped>

  @media (min-width: $fullhd)
    ::v-deep
      .container
        max-width: 100% !important
</style>
