/* eslint-disable import/no-cycle */
import { Model } from '@vuex-orm/core';
import http from '@/services/http';
import filterObjectKeys from '@/helpers/filter-object-keys';


export default class StoreHoursOverride extends Model {
  static entity = 'storeHoursOverrides'

  static fields() {
    return {
      id: this.attr(''),
      storeId: this.attr(''),
      name: this.attr(''),
      date: this.attr(null),
      openTime: this.attr(null),
      closeTime: this.attr(null),
      isOpen: this.attr(true)
    };
  }



  // STATE //////////////////////

  static state() {
    return {
      fetching: false,
      submitting: false,
      deletingId: false
    };
  }

  static $state() {
    return this.store().state.entities.storeHoursOverrides;
  }



  // ACTIONS //////////////////////

  static async fetchStoreHoursOverrides(storeId) {
    try {
      this.commit((state) => {
        state.fetching = true;
      });
      const merchantId = this.store().state.entities.merchants.selectedMerchantId;
      const overrides = [];

      if (storeId) {
        const storeOverridesResponse = await http.get(`stores/${storeId}/store_hours_overrides`);
        overrides.push(...storeOverridesResponse.data.storeHoursOverrides);
      }
      const merchantOverridesResponse = await http.get(`merchants/${merchantId}/store_hours_overrides`);
      overrides.push(...merchantOverridesResponse.data.storeHoursOverrides);
      this.create({
        data: overrides
      });
    }
    catch (error) {
      throw error;
    }
    finally {
      this.commit((state) => {
        state.fetching = false;
      });
    }
  }

  static async addStoreHoursOverride(storeHoursOverride) {
    try {
      this.commit((state) => {
        state.submitting = true;
      });

      const acceptedKeys = ['name', 'date', 'openTime', 'closeTime', 'isOpen'];
      let response;
      if (storeHoursOverride.storeId) {
        response = await http.post(
          `stores/${storeHoursOverride.storeId}/store_hours_overrides`,
          { storeHoursOverride: filterObjectKeys(storeHoursOverride, acceptedKeys) }
        );
      }
      else {
        const merchantId = this.store().state.entities.merchants.selectedMerchantId;

        response = await http.post(
          `merchants/${merchantId}/store_hours_overrides`,
          { storeHoursOverride: filterObjectKeys(storeHoursOverride, acceptedKeys) }
        );
      }
      this.insert({ data: response.data.storeHoursOverride });
    }

    catch (error) {
      throw error;
    }

    finally {
      this.commit((state) => {
        state.submitting = false;
      });
    }
  }

  static async updateStoreHoursOverride(storeHoursOverride) {
    try {
      this.commit((state) => {
        state.submitting = true;
      });
      let response;
      const acceptedKeys = ['name', 'date', 'openTime', 'closeTime', 'isOpen'];

      if (storeHoursOverride.storeId) {
        response = await http.put(
          `stores/${storeHoursOverride.storeId}/store_hours_overrides/${storeHoursOverride.id}`,
          { storeHoursOverride: filterObjectKeys(storeHoursOverride, acceptedKeys) }
        );
      }
      else {
        const merchantId = this.store().state.entities.merchants.selectedMerchantId;
        response = await http.put(
          `merchants/${merchantId}/store_hours_overrides/${storeHoursOverride.id}`,
          { storeHoursOverride: filterObjectKeys(storeHoursOverride, acceptedKeys) }
        );
      }

      this.update({ data: response.data.storeHoursOverride });
    }

    catch (error) {
      throw error;
    }

    finally {
      this.commit((state) => {
        state.submitting = false;
      });
    }
  }

  static async deleteStoreHoursOverride({ storeId, storeHoursOverrideId }) {
    try {
      this.commit((state) => {
        state.deletingId = storeHoursOverrideId;
      });
      if (storeId) {
        await http.delete(`stores/${storeId}/store_hours_overrides/${storeHoursOverrideId}`);
      }
      else {
        const merchantId = this.store().state.entities.merchants.selectedMerchantId;
        await http.delete(`merchants/${merchantId}/store_hours_overrides/${storeHoursOverrideId}`);
      }

      this.delete(storeHoursOverrideId);
    }

    catch (error) {
      throw error;
    }

    finally {
      this.commit((state) => {
        state.deletingId = false;
      });
    }
  }
}
