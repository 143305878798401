import _Order from './_Order';
import http from '@/services/http';



export default class ExpoOrder extends _Order {
  static entity = 'orders_expo';

  static primaryKey = 'orderId';


  // FIELDS //////////////////////
  static fields() {
    return {
      ...super.fields(),
      expoStatus: this.attr(''),
      guestName: this.attr(''),
      courier: this.attr(null)
    };
  }

  get orderTotalWithExternalPayment() {
    return super.orderTotalWithExternalPayment;
  }


  // STATE //////////////////////
  static state() {
    return {
      fetchingOrders: false,
      fetchingOrderCounts: false,
      fetchingOrderDetails: false,
      updatingOrder: false,
      printing: false
    };
  }

  static $state() {
    return this.store().state.entities.orders_expo;
  }

  // We could probably add getters for the order's status and a couple other items, as right now we have a little bit of duplicate code
  // across some of the order expo compenets. Revisit this after all the components are in place.


  // ACTIONS //////////////////////
  static async fetchOrders({ params }) {
    try {
      this.commit((state) => {
        state.fetchingOrders = true;
      });

      const merchantId = this.store().state.entities.merchants.selectedMerchantId;
      const { data } = await http.get(`merchants/${merchantId}/expo_orders`, { params });

      this.insertOrUpdate({ data: data.expoOrders });

      return data.expoOrders;
    }

    catch (error) {
      throw error;
    }

    finally {
      this.commit((state) => {
        state.fetchingOrders = false;
      });
    }
  }

  static async fetchOrderCounts(storeId) {
    try {
      this.commit((state) => {
        state.fetchingOrderCounts = true;
      });

      const merchantId = this.store().state.entities.merchants.selectedMerchantId;
      const { data } = await http.get(`merchants/${merchantId}/expo_orders/counts`, {
        params: {
          store_id: storeId
        }
      });

      const orderCounts = data.expoOrderCounts.reduce((obj, { count, orderIds, expoStatus }) => {
        obj[expoStatus] = { count, orderIds };
        return obj;
      }, {
        Upcoming: { count: 0, orderIds: [] },
        Active: { count: 0, orderIds: [] },
        Past: { count: 0, orderIds: [] }
      });

      return orderCounts;
    }

    catch (error) {
      throw error;
    }

    finally {
      this.commit((state) => {
        state.fetchingOrderCounts = false;
      });
    }
  }

  static async fetchOrder(id) {
    try {
      this.commit((state) => {
        state.fetchingOrderDetails = true;
      });

      const merchantId = this.store().state.entities.merchants.selectedMerchantId;
      const { data } = await http.get(`merchants/${merchantId}/orders/${id}`);

      this.insertOrUpdate({ data: data.order });

      return data.order;
    }

    catch (error) {
      throw error;
    }

    finally {
      this.commit((state) => {
        state.fetchingOrderDetails = false;
      });
    }
  }

  static async updateOrderExpoStatus({ orderId, newExpoStatus, storeId }) {
    try {
      this.commit((state) => {
        state.updatingOrder = true;
      });

      const merchantId = this.store().state.entities.merchants.selectedMerchantId;

      const order = {
        expoStatus: newExpoStatus,
        storeId,
        merchantId
      };

      const { data } = await http.put(`merchants/${merchantId}/expo_orders/${orderId}/update_status`, {
        order
      });

      return this.update({
        where: orderId,
        data: data.order
      });
    }

    catch (error) {
      throw error;
    }

    finally {
      this.commit((state) => {
        state.updatingOrder = false;
      });
    }
  }

  static async printOrderReceipt(orderId) {
    try {
      this.commit((state) => {
        state.printing = true;
      });

      const merchantId = this.store().state.entities.merchants.selectedMerchantId;
      await http.post(`merchants/${merchantId}/orders/${orderId}/print`);
    }
    catch (error) {
      throw error;
    }
    finally {
      this.commit((state) => {
        state.printing = false;
      });
    }
  }
}
