<template>
  <validated-form
    ref="form"
    v-slot="{}"
    form-id="pushProviderForm"
    @valid-submit="updatePushProvider"
  >
    <sticky-save-container
      :saving="submittingPushProvider"
      :disabled="!merchantPushProviders.length"
      title="Push Notification Provider"
    >
      <section class="config-container pad-t-sm">
        <div class="is-flex config-container--settings">
          <h3 v-if="!merchantPushProviders.length" class="is-size-5 has-text-weight-bold mar-r-lg">No Push Providers</h3>
          <h3 v-else-if="merchantPushProviders.length === 1" class="is-size-5 has-text-weight-bold mar-r-lg">{{ getPushProviderName() }}</h3>
          <validated-text-input
            v-model="apiConfiguration"
            class="configuration mar-r"
            :has-icon="false"
            label="Configuration"
            label-position="on-border"
            name="pushProviderConfig"
            type="textarea"
            rows="10"
            :spellcheck="false"
            maxlength="8000"
            rules="required|validJSON"
            :disabled="!merchantPushProviders.length"
          />
          <b-button
            inverted
            class="mar-r-lg mar-b"
            type="is-danger"
            size="is-medium"
            icon-right="trash"
            :disabled="!merchantPushProviders.length"
            @click="openRemovePushProviderModal"
          />
        </div>
        <b-button
          inverted
          size="is-medium"
          class="is-transparent"
          type="is-primary"
          icon-left="plus"
          :loading="submittingPushProvider"
          :disabled="!canAddPushProviders"
          @click="openAddPushProviderModal"
        >
          New Push Provider
        </b-button>
      </section>
    </sticky-save-container>
  </validated-form>
</template>

<script>
  import beautify from 'json-beautify';
  import addPushProviderModal from './add-push-provider-modal.vue';
  import alertModal from '@/components/globals/alert-modal.vue';
  import MerchantPushProvider from '@/store/classes/MerchantPushProvider';
  import PushProviderType from '@/store/classes/PushProviderType';

  export default {
    name: 'MerchantPushProviders',

    props: {
      merchantId: {
        type: Number,
        default: null
      }
    },

    data() {
      return {
        apiConfiguration: null
      };
    },

    computed: {
      submittingPushProvider() {
        return MerchantPushProvider.$state().submitting;
      },

      canAddPushProviders() {
        return this.merchantPushProviders.length < 1;
      },

      selectedPushProvider() {
        return this.merchantPushProviders?.[0];
      },

      merchantPushProviders() {
        return MerchantPushProvider.all();
      }
    },

    async created() {
      await this.onCreated();
    },


    methods: {
      async onCreated() {
        await Promise.all([this.fetchPushProviders(), this.fetchMerchantPushProviders()]);
        this.setConfig();
      },

      async fetchMerchantPushProviders() {
        try {
          await MerchantPushProvider.fetchMerchantPushProviders(this.merchantId);
        }
        catch (error) {
          this.$_onRequestError({
            toastOptions: {
              message: 'There was an error fetching the merchant push providers'
            },
            error
          });
        }
      },

      async fetchPushProviders() {
        try {
          await PushProviderType.fetchPushProviderTypes();
        }
        catch (error) {
          this.$_onRequestError({
            toastOptions: {
              message: 'There was an error fetching the push provider options'
            },
            error
          });
        }
      },

      getPushProviderName() {
        return PushProviderType.getName(this.selectedPushProvider?.pushProviderTypeId);
      },

      setConfig() {
        if (this.selectedPushProvider) {
          const { apiConfiguration } = this.selectedPushProvider;
          const beautifiedConfig = beautify(JSON.parse(apiConfiguration), null, 2, 0);
          this.apiConfiguration = beautifiedConfig;
        }
        else {
          this.apiConfiguration = null;
        }
      },

      openRemovePushProviderModal() {
        this.$buefy.modal.open({
          parent: this,
          component: alertModal,
          hasModalCard: true,
          trapFocus: true,
          canCancel: ['escape', 'outside'],
          customClass: 'auto-width',
          props: {
            title: 'Delete Push Provider',
            message: `Are you sure you want to delete ${this.getPushProviderName()} push provider?`,
            showCloseButton: false,
            horizontal: true,
            icon: 'trash-alt',
            type: 'is-danger',
            buttons: [
              { text: 'Cancel' },
              { text: 'Delete Push Provider', primary: true, onClick: () => this.removePushProvider() }
            ]
          }
        });
      },

      openAddPushProviderModal() {
        this.$buefy.modal.open({
          parent: this,
          component: addPushProviderModal,
          hasModalCard: true,
          trapFocus: true,
          canCancel: false,
          customClass: 'auto-width',
          events: { 'push-provider-changed': this.setConfig },
          props: {
            merchantPushProviders: this.merchantPushProviders,
            merchantId: this.merchantId
          }
        });
      },

      async removePushProvider() {
        try {
          await MerchantPushProvider.removeMerchantPushProvider(this.selectedPushProvider.id);
          this.setConfig();
          this.$_onRequestSuccess({
            toastOptions: {
              message: 'Successfully removed your push provider!'
            }
          });
        }
        catch (error) {
          this.$_onRequestError({
            error,
            toastOptions: {
              message: 'There was an error removing your push provider'
            }
          });
        }
      },

      async updatePushProvider() {
        try {
          await MerchantPushProvider.updateMerchantPushProvider({
            merchantPushProvider: {
              id: this.selectedPushProvider.id,
              apiConfiguration: beautify(JSON.parse(this.apiConfiguration), null, 0)
            }
          });

          this.$_onRequestSuccess({
            toastOptions: {
              message: 'Successfully updated your push provider!'
            }
          });
        }
        catch (error) {
          this.$_onRequestError({
            error,
            toastOptions: {
              message: 'There was an error updating your push provider'
            }
          });
        }
      }
    }
  };
</script>
