<template>
  <div class="is-grid gap-lg pad has-background-white-bis has-border has-border-grey-lightest has-radius">
    <div class="is-flex justify-between align-baseline">
      <div>
        <p class="title is-6">Item Purchase #{{ count }}</p>
        <p class="subtitle is-7 has-text-grey">Require the customer to make a specific purchase for the offer to be applied</p>
      </div>
      <b-button
        v-if="!readOnly"
        outlined
        icon-left="trash-alt"
        type="is-danger is-light"
        @click="$emit('remove-constraint', itemOfferConstraint)"
      />
    </div>
    <validated-input
      class="dropdown-field"
      :name="`itemType${count}`"
      label="Item Type"
      tooltip="Select items, categories, or item groups that apply to this offer"
      rules="required"
    >
      <b-select
        v-model="itemResourceType"
        placeholder="Select an item type..."
        class="is-inline-block"
        expanded
        :disabled="readOnly"
      >
        <option
          v-for="( type, key ) in itemResourceTypes"
          :key="key"
          :value="type"
        >
          {{ type }}
        </option>
      </b-select>
    </validated-input>
    <div class="is-grid gap col-2">
      <div class="is-grid gap">
        <div v-if="itemResourceType === itemResourceTypes.ITEM">
          <label key="isSpecificItemsLabel" class="label">
            Customer must purchase...
          </label>
          <radio-button
            v-model="itemGroupType"
            :name="`offerConstraintItemGroupType${count}`"
            :disabled="readOnly"
            :native-value="itemGroupTypes.ALL_ITEMS"
          >
            Any Item
          </radio-button>
          <radio-button
            v-model="itemGroupType"
            :name="`offerConstraintItemGroupType${count}`"
            :disabled="readOnly"
            :native-value="itemGroupTypes.MENU_ITEMS"
          >
            Specific Items
          </radio-button>
        </div>
        <div v-if="itemGroupType === itemGroupTypes.MENU_ITEMS || itemResourceType === itemResourceTypes.CATEGORY">
          <validated-input
            :label="`Required ${itemGroupType === itemGroupTypes.MENU_ITEMS ? 'Items' : 'Item Categories'}`"
            name="requiredItems"
            :tooltip="`Items ${itemResourceType == itemResourceTypes.CATEGORY ? 'from selected categories ' : ''}that must be purchased for this offer to be valid.`"
          >
            <menu-search-input
              :read-only="readOnly"
              :resource-type="itemResourceType === itemResourceTypes.ITEM ? 'menuItems' : 'menuCategories'"
              :selected-resources="selectedResources"
              multiple
              :is-item-offer-constraint="true"
              @add-resource="addResourceToConstraint"
              @remove-resource="removeResourceFromConstraint"
            />
          </validated-input>
        </div>
        <div class="is-grid gap col-2">
          <div class="is-grid gap">
            <div class="is-inline-block">
              <validated-text-input
                :value="itemOfferConstraint.minimumQualifyingCount"
                class="minimum-qualifying-count"
                icon="hashtag"
                label="Minimum Quantity"
                tooltip="Total amount of these items that must be purchased for this offer to be valid"
                name="minimumQualifyingCount"
                type="number"
                :disabled="readOnly"
                :rules="{ required: true, min_value: 1 }"
                @input="updateConstraint({ minimumQualifyingCount: $event })"
              />
              <validated-input
                v-if="$_selectedMerchant.features.supportsMultiPosConstraintsAndValuePropositions"
                class="dropdown-field"
                name="posType"
                label="POS Type"
                tooltip="Limit this offer to one POS, or leave blank to apply to all"
                style="width: 12.6em"
              >
                <b-select
                  v-model="itemOfferConstraint.posType"
                  placeholder="Select a POS type..."
                  :disabled="readOnly"
                  expanded
                  @input="updateConstraint({ posType: $event })"
                >
                  <option :value="null">-</option>
                  <option
                    v-for="posType in merchantPosTypes"
                    :key="posType.id"
                    :value="posType.name"
                  >
                    {{ posType.name }}
                  </option>
                </b-select>
              </validated-input>
            </div>
            <modifier-restrictions-search
              v-if="itemGroupType === itemGroupTypes.MENU_ITEMS || itemResourceType === itemResourceTypes.CATEGORY"
              :read-only="readOnly"
              :selected-resources="selectedResources"
              :required-modifications="itemOfferConstraint.itemGroup.requiredModifications"
              :is-category-search="itemResourceType === itemResourceTypes.CATEGORY"
              @update-required-modifications="itemOfferConstraint.itemGroup.updateRequiredModifications($event)"
            />
          </div>
          <excluded-item-attributes-dropdown
            v-if="itemGroupType === itemGroupTypes.ALL_ITEMS || itemResourceType === itemResourceTypes.CATEGORY"
            v-model="itemOfferConstraint.itemGroup.excludedItemAttributes"
            :read-only="readOnly"
            form-name="itemOfferConstraint"
          />
        </div>
      </div>
      <div
        v-if="itemGroupType === itemGroupTypes.MENU_ITEMS || itemResourceType === itemResourceTypes.CATEGORY"
        class="is-flex mar-sm"
        :class="[
          selectedResources.length || missingResources.length
            ? 'align-start justify-start'
            : 'align-center pad-sm justify-center has-border-lg has-border-grey-lighter has-radius-large has-border-dashed' ]"
      >
        <p
          v-if="!selectedResources.length && !missingResources.length"
          class="has-text-grey-light has-text-centered"
        >
          Search for {{ itemGroupType === itemGroupTypes.MENU_ITEMS ? 'an item' : 'a category of items' }} to add to the purchase requirements list
        </p>
        <div class="is-flex-wrap gap">
          <div
            v-for="resource in selectedResources"
            :key="resource.id"
            class="tag is-primary is-flex-start-aligned is-light auto-height mar-none pad-y-xs pad-x-sm"
          >
            <div>
              <p class="align-self-start has-text-weight-bold">{{ resource.displayName }}</p>
              <p v-if="resource.posItems && resource.posItems.length" class="has-text-primary-dark">
                {{ resource.posItems[0].id }}
                <b-icon
                  v-if="resource.posItems.length > 1"
                  v-tippy="{
                    content: resource.posItems.map(posItem => posItem.id).join(', '),
                    maxWidth: 250,
                    delay: [150, 0]
                  }"
                  icon="tags"
                  size="is-small"
                  class="mar-l-xs"
                />
              </p>
            </div>

            <b-icon
              v-if="!readOnly"
              icon="times-circle"
              class="delete align-self-center mar-none mar-l-sm has-background-primary-dark has-hover-background-primary-medium"
              custom-size="fa-lg"
              @click.native="removeResourceFromConstraint(resource)"
            />
          </div>

          <div
            v-for="resourceId in missingResources"
            :key="resourceId"
            class="tag is-danger is-flex-align-center is-light auto-height mar-none pad-y-sm pad-x-sm"
          >
            <div>
              <p
                v-tippy="{
                  content: 'This menu resource cannot be found',
                  maxWidth: 250,
                  delay: [150, 0]
                }"
                class="align-self-start has-text-weight-bold"
              >
                Unknown menu resource
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { constraintTypes } from '@/constants/offers';
  import { itemAttributes, itemGroupTypes, itemResourceTypes } from '@/constants/items';
  import merchantMixin from '@/mixins/merchant';


  export default {
    name: 'ItemOfferConstraintCard',

    mixins: [merchantMixin],

    props: {
      itemOfferConstraint: {
        type: Object,
        required: true
      },
      readOnly: {
        type: Boolean,
        default: false
      },
      count: {
        type: Number,
        required: true
      }
    },

    data() {
      return {
        constraintTypes,
        itemAttributes,
        itemGroupTypes,
        itemResourceTypes
      };
    },

    computed: {
      missingResources() {
        switch (this.itemResourceType) {
          case itemResourceTypes.ITEM:
            return this.itemOfferConstraint.itemGroup.missingMenuItems();
          case itemResourceTypes.CATEGORY:
            return this.itemOfferConstraint.itemGroup.missingCategories();
          case itemResourceTypes.ITEM_GROUP:
          default:
            return [];
        }
      },

      itemResourceType: {
        get() {
          switch (this.itemGroupType) {
            case itemGroupTypes.ALL_ITEMS:
            case itemGroupTypes.MENU_ITEMS:
              return itemResourceTypes.ITEM;
            case itemGroupTypes.CATEGORIES:
              return itemResourceTypes.CATEGORY;
            default:
              return null;
          }
        },
        set(itemResourceType) {
          if (itemResourceType === itemResourceTypes.ITEM) {
            this.itemOfferConstraint.itemGroup.updateType(itemGroupTypes.ALL_ITEMS);
          }
          else if (itemResourceType === itemResourceTypes.CATEGORY) {
            this.itemOfferConstraint.itemGroup.updateType(itemGroupTypes.CATEGORIES);
          }
        }
      },

      itemGroupType: {
        get() {
          return this.itemOfferConstraint.itemGroup.itemGroupType;
        },
        set(value) {
          const excludedItemAttributes = value === itemGroupTypes.ALL_ITEMS ? this.allItemAttrs : [];
          this.itemOfferConstraint.itemGroup.updateType(value, excludedItemAttributes);
        }
      },

      selectedResources() {
        if (this.itemResourceType === itemResourceTypes.ITEM) {
          return this.itemOfferConstraint.itemGroup.menuItems;
        }
        else if (this.itemResourceType === itemResourceTypes.CATEGORY) {
          return this.itemOfferConstraint.itemGroup.categoryObjects;
        }
        else {
          return [];
        }
      },

      allItemAttrs() {
        return Object.values(itemAttributes).map(itemAttr => itemAttr.id);
      },

      merchantPosTypes() {
        return this.$_selectedMerchant.posConfigurations
          .filter(mpos => mpos.storeId === -1)
          .map(posConfig => posConfig.posType);
      }
    },

    methods: {
      addResourceToConstraint(resource) {
        const type = this.itemResourceType === itemResourceTypes.ITEM ? 'menuItems' : 'categoryObjects';
        const resources = this.$clone(this.itemOfferConstraint.itemGroup[type]);
        resources.push(resource);
        const updatedIds = type === 'menuItems'
          ? [...this.itemOfferConstraint.itemGroup.menuItemIds, resource.id]
          : [...this.itemOfferConstraint.itemGroup.categoryIds, resource.id];
        this.itemOfferConstraint.itemGroup.updateResources(type, resources, updatedIds);
      },

      removeResourceFromConstraint(resource) {
        const type = this.itemResourceType === itemResourceTypes.ITEM ? 'menuItems' : 'categoryObjects';
        const resources = this.$clone(this.itemOfferConstraint.itemGroup[type]);
        const newResources = resources.filter(constraintResource => constraintResource.id !== resource.id);
        const updatedIds = type === 'menuItems'
          ? this.itemOfferConstraint.itemGroup.menuItemIds.filter(id => id !== resource.id)
          : this.itemOfferConstraint.itemGroup.categoryIds.filter(id => id !== resource.id);
        this.itemOfferConstraint.itemGroup.updateResources(type, newResources, updatedIds);
      },

      updateConstraint(newVals) {
        this.$emit('update-constraint', {
          ...this.itemOfferConstraint,
          ...newVals
        });
      }
    }
  };
</script>

<style lang="sass" scoped>
  .minimum-qualifying-count
    width: 12.6em
</style>
