/* eslint-disable import/no-cycle */
import { Model } from '@vuex-orm/core';
import http from '@/services/http';



export default class StoreReceiptLocation extends Model {
  static entity = 'storeReceiptLocations'

  static fields() {
    return {
      id: this.attr(''),
      storeId: this.attr(''),
      fulfillmentTypeId: this.attr(null),
      platformTypeId: this.attr(null),
      orderTypeId: this.attr(null),
      receiptPrinterId: this.attr(null)
    };
  }



  // STATE //////////////////////

  static state() {
    return {
      fetching: false,
      submitting: false,
      deleting: false
    };
  }

  static $state() {
    return this.store().state.entities.storeReceiptLocations;
  }


  // ACTIONS //////////////////////

  static async fetchStoreReceiptLocations(storeId) {
    try {
      this.commit((state) => {
        state.fetching = true;
      });

      const response = await http.get(`stores/${storeId}/store_receipt_locations`);

      this.create({
        data: response.data.storeReceiptLocations
      });
    }
    catch (error) {
      throw error;
    }
    finally {
      this.commit((state) => {
        state.fetching = false;
      });
    }
  }

  static async addStoreReceiptLocation({ storeId, receiptLocation }) {
    try {
      this.commit((state) => {
        state.submitting = true;
      });

      const response = await http.post(`stores/${storeId}/store_receipt_locations`, {
        storeReceiptLocation: {
          fulfillmentTypeId: receiptLocation.fulfillmentTypeId,
          platformTypeId: receiptLocation.platformTypeId,
          orderTypeId: receiptLocation.orderTypeId,
          receiptPrinterId: receiptLocation.receiptPrinterId
        }
      });

      this.insert({
        data: response.data.storeReceiptLocation
      });
    }
    catch (error) {
      throw error;
    }
    finally {
      this.commit((state) => {
        state.submitting = false;
      });
    }
  }

  static async updateStoreReceiptLocation(receiptLocation) {
    try {
      this.commit((state) => {
        state.submitting = true;
      });

      const response = await http.put(`store_receipt_locations/${receiptLocation.id}`, {
        storeReceiptLocation: {
          fulfillmentTypeId: receiptLocation.fulfillmentTypeId,
          platformTypeId: receiptLocation.platformTypeId,
          orderTypeId: receiptLocation.orderTypeId,
          receiptPrinterId: receiptLocation.receiptPrinterId
        }
      });

      this.update({
        data: response.data.storeReceiptLocation
      });
    }
    catch (error) {
      throw error;
    }
    finally {
      this.commit((state) => {
        state.submitting = false;
      });
    }
  }

  static async deleteStoreReceiptLocation(id) {
    try {
      this.commit((state) => {
        state.deleting = true;
      });

      await http.delete(`store_receipt_locations/${id}`);

      this.delete(id);
    }
    catch (error) {
      throw error;
    }
    finally {
      this.commit((state) => {
        state.deleting = false;
      });
    }
  }
}
