<template>
  <p>Last modified on <span class="has-text-weight-semibold">{{ dateString }}</span> at <span class="has-text-weight-semibold">{{ timeString }} ({{ timeZoneAbbreviation }})</span></p>
</template>

<script>
  import moment from 'moment-timezone';



  export default {
    name: 'LastModifiedMessage',



    props: {
      timestamp: {
        type: String,
        required: true,
        validator(val) {
          return moment(val).isValid();
        }
      }
    },



    computed: {
      dateString() {
        return moment(this.timestamp).format('MMMM D, YYYY');
      },

      timeString() {
        return moment(this.timestamp).format('h:mm A');
      },

      timeZoneAbbreviation() {
        const timeZoneString = moment.tz.guess(true);
        return moment.tz.zone(timeZoneString).abbr(Date.now());
      }
    }
  };
</script>
