<template>
  <tabs-page
    :tabs="tabs"
    :keep-alive="false"
    title="Loyalty Program Management"
  />
</template>

<script>
  import logger from '@/services/logger';
  import merchantMixin from '@/mixins/merchant';

  // tabs
  import programTab from './tabs/program/program-tab.vue';
  import challengesTab from './tabs/challenges-tab.vue';
  import earnRulesTab from './tabs/earn-rules-tab.vue';
  import conversionThresholdsTab from './tabs/conversion-thresholds-tab.vue';

  // store
  import Currency from '@/store/classes/Currency';
  import LoyaltyProgram from '@/store/classes/LoyaltyProgram';
  import Offer from '@/store/classes/Offer';
  import EarnRule from '@/store/classes/EarnRule';
  import ConversionThreshold from '@/store/classes/ConversionThreshold';


  export default {
    name: 'LoyaltyManagement',

    mixins: [merchantMixin],

    computed: {
      tabs() {
        return [
          {
            display: 'Program',
            name: 'program',
            component: programTab,
            props: { hasLoyaltyProgram: !!this.loyaltyProgramPublicId }
          },
          {
            display: 'Earn Rules',
            name: 'earn-rules',
            component: earnRulesTab,
            isDisabled: !this.loyaltyProgramPublicId,
            props: { loyaltyProgramPublicId: this.loyaltyProgramPublicId }
          },
          {
            display: 'Conversion Thresholds',
            name: 'conversion-thresholds',
            component: conversionThresholdsTab,
            isDisabled: !this.loyaltyProgramPublicId,
            props: { loyaltyProgramPublicId: this.loyaltyProgramPublicId }
          },
          {
            display: 'Challenges',
            name: 'challenges',
            component: challengesTab,
            isDisabled: !this.loyaltyProgramPublicId,
            isHidden: !this.$_selectedMerchant?.features.challenges,
            props: { loyaltyProgramPublicId: this.loyaltyProgramPublicId }
          }
        ];
      },

      loyaltyProgramPublicId() {
        return LoyaltyProgram.query().first()?.publicId;
      }
    },

    watch: {
      loyaltyProgramPublicId: {
        immediate: true,
        handler(programPublicId) {
          if (programPublicId) this.fetchLoyaltyResources(programPublicId);
        }
      }
    },

    async created() {
      try {
        await Promise.all([
          Currency.fetchCurrencies(),
          LoyaltyProgram.fetchPrograms(),
          Offer.fetchOffers()
        ]);
      }
      catch (error) {
        logger.warn(error, 'Check to see if a Loyalty Tenant exists for this merchant');
      }
    },

    methods: {
      async fetchLoyaltyResources(programPublicId) {
        await Promise.all([
          this.fetchEarnRules(programPublicId),
          this.fetchCurrencies(programPublicId),
          this.fetchConversionThresholds(programPublicId)
        ]);

        // NOTE: keeping this here temporarily to create mock draft challenges
        // const currencyPublicId = await Currency.createDraftCurrency();
        // await Promise.all([
        //   EarnRule.createDraftEarnRule({ programPublicId, currencyPublicId }),
        //   ConversionThreshold.createDraftConversionThreshold({ currencyPublicId })
        // ]);
      },

      async fetchEarnRules(programPublicId) {
        try {
          await Promise.all([
            EarnRule.fetchEarnRules({ programPublicId, includeForSecondaryCurrency: true }),
            EarnRule.fetchDraftEarnRules()
          ]);
        }
        catch (error) {
          this.$_onRequestError({
            toastOptions: { message: 'Unable to fetch earn rules' },
            error
          });
        }
      },

      async fetchCurrencies(programPublicId) {
        try {
          await Promise.all([
            Currency.fetchCurrencies(programPublicId),
            Currency.fetchDraftCurrencies()
          ]);
        }
        catch (error) {
          this.$_onRequestError({
            toastOptions: { message: 'Unable to fetch currencies' },
            error
          });
        }
      },

      async fetchConversionThresholds(programPublicId) {
        try {
          await Promise.all([
            ConversionThreshold.fetchConversionThresholds(programPublicId),
            ConversionThreshold.fetchDraftConversionThresholds()
          ]);
        }
        catch (error) {
          this.$_onRequestError({
            toastOptions: { message: 'Unable to fetch conversion thresholds' },
            error
          });
        }
      }
    }
  };

</script>
