<template>
  <div class="card-content">
    <p class="title is-4 has-text-weight-normal">Constraints</p>
    <p class="subtitle is-6 has-text-grey mar-b">Define limitations for how this earn rule is applied</p>
    <dropdown-menu>
      <b-button
        slot="trigger"
        type="is-primary is-light"
        icon-left="plus"
        :disabled="readOnly || !value.engagementType || !value.awardPolicy.awardPolicyType || !unselectedConstraints.length"
        class="mar-b"
        data-test-id="add-constraint-button"
      >
        Constraint
      </b-button>
      <b-dropdown-item
        v-for="constraint in unselectedConstraints"
        :key="constraint.type"
        class="pad-r-sm is-flex justify-between"
        aria-role="listitem"
        :data-test-id="`${constraint.type}-option`"
        @click="addConstraint(constraint)"
      >
        <span class="mar-r-sm">{{ constraint.display }}</span>
        <b-icon icon="arrow-right" />
      </b-dropdown-item>
    </dropdown-menu>
    <div class="is-flex-column gap">
      <constraint-card
        v-for="earnRuleConstraint in filteredEarnRuleConstraints"
        :key="`${earnRuleConstraint.constraintType}-${earnRuleConstraint.formId}`"
        :constraint-model="constraintsCardMapping[earnRuleConstraint.constraintType].constraintModel"
        :hide-remove-button="constraintsCardMapping[earnRuleConstraint.constraintType].hideRemoveButton"
        @remove-constraint="removeConstraint(earnRuleConstraint)"
      >
        <component
          :is="constraintsCardMapping[earnRuleConstraint.constraintType].component"
          :earn-rule-constraint="earnRuleConstraint"
          :read-only="readOnly"
          v-bind="constraintsCardMapping[earnRuleConstraint.constraintType].props"
          @update-constraint="updateConstraint"
        />
      </constraint-card>
    </div>
  </div>
</template>

<script>
  import { constraintModels, engagementTypes } from '@/constants/earnRules';

  import ConstraintCard from './constraint-cards/constraint-card.vue';
  import FulfillmentTypeCard from './constraint-cards/fulfillment-type-card.vue';
  import LocationCard from './constraint-cards/location-card.vue';
  import MaximumOrderAgeCard from './constraint-cards/maximum-order-age-card.vue';
  import MenuTypeCard from './constraint-cards/menu-type-card.vue';
  import MinimumPurchaseCard from './constraint-cards/minimum-purchase-card.vue';
  import PaymentMethodCard from './constraint-cards/payment-method-card.vue';
  import RefereeRequiredPurchaseCard from './constraint-cards/referee-required-purchase-card.vue';
  import NoInputConstraintCard from './constraint-cards/no-input-constraint-card.vue';
  import SpecifiedItemGroupCard from './constraint-cards/specified-item-group-card.vue';


  export default {
    name: 'EarnRuleConstraintInputs',

    components: { ConstraintCard },

    props: {
      value: {
        type: Object,
        required: true
      },

      readOnly: {
        type: Boolean,
        default: false
      }
    },

    computed: {
      constraintsCardMapping() {
        return {
          [constraintModels.MENU_TYPE.type]: {
            component: MenuTypeCard,
            constraintModel: constraintModels.MENU_TYPE
          },
          [constraintModels.FULFILLMENT_TYPE.type]: {
            component: FulfillmentTypeCard,
            constraintModel: constraintModels.FULFILLMENT_TYPE
          },
          [constraintModels.MAXIMUM_ORDER_AGE.type]: {
            component: MaximumOrderAgeCard,
            constraintModel: constraintModels.MAXIMUM_ORDER_AGE
          },
          [constraintModels.MINIMUM_PURCHASE.type]: {
            component: MinimumPurchaseCard,
            constraintModel: constraintModels.MINIMUM_PURCHASE
          },
          [constraintModels.PAYMENT_METHOD.type]: {
            component: PaymentMethodCard,
            constraintModel: constraintModels.PAYMENT_METHOD
          },
          [constraintModels.RETRO_CLAIM.type]: {
            component: NoInputConstraintCard,
            constraintModel: constraintModels.RETRO_CLAIM
          },
          [constraintModels.SPECIFIED_ITEM_GROUP.type]: {
            component: SpecifiedItemGroupCard,
            constraintModel: constraintModels.SPECIFIED_ITEM_GROUP
          },
          [constraintModels.REFEREE_VERIFICATION.type]: {
            component: NoInputConstraintCard,
            constraintModel: constraintModels.REFEREE_VERIFICATION
          },
          [constraintModels.REFEREE_REQUIRED_PURCHASE.type]: {
            component: RefereeRequiredPurchaseCard,
            constraintModel: constraintModels.REFEREE_REQUIRED_PURCHASE
          },
          [constraintModels.SALES_TRANSACTION_LOCATION.type]: {
            component: LocationCard,
            constraintModel: constraintModels.SALES_TRANSACTION_LOCATION,
          },
          [constraintModels.MEMBER_PROXIMITY_LOCATION.type]: {
            component: LocationCard,
            constraintModel: constraintModels.MEMBER_PROXIMITY_LOCATION,
            hideRemoveButton: true,
            props: {
              showActiveOrderCheckbox: true
            }
          },
          [constraintModels.BIRTHDAY_REQUIRED_PURCHASE.type]: {
            component: NoInputConstraintCard,
            constraintModel: constraintModels.BIRTHDAY_REQUIRED_PURCHASE
          }
        };
      },

      availableConstraints() {
        switch (this.value.engagementType) {
          case engagementTypes.BIRTHDAY.id:
            return [
              constraintModels.BIRTHDAY_REQUIRED_PURCHASE
            ];
          case engagementTypes.SALES_TRANSACTION.id:
            return [
              constraintModels.FULFILLMENT_TYPE,
              constraintModels.MAXIMUM_ORDER_AGE,
              constraintModels.MENU_TYPE,
              constraintModels.MINIMUM_PURCHASE,
              constraintModels.PAYMENT_METHOD,
              constraintModels.RETRO_CLAIM,
              constraintModels.SPECIFIED_ITEM_GROUP,
              constraintModels.SALES_TRANSACTION_LOCATION
            ];
          case engagementTypes.REFERRAL_CONVERTED.id:
            return [
              constraintModels.REFEREE_REQUIRED_PURCHASE,
              constraintModels.REFEREE_VERIFICATION
            ];
          case engagementTypes.MEMBER_PROXIMITY.id:
            return [
              constraintModels.MEMBER_PROXIMITY_LOCATION
            ];
          default:
            return [];
        }
      },

      filteredEarnRuleConstraints() {
        let itemConstraintCount = 1;
        return this.value.earnRuleConstraints
          .filter(
            constraint => constraint.constraintType !== constraintModels.DAY_OF_WEEK.type
              && constraint.constraintType !== constraintModels.TIME_OF_DAY.type
          )
          .map((constraint) => {
            if (constraint.constraintType === constraintModels.SPECIFIED_ITEM_GROUP.type) {
              constraint = {
                ...constraint,
                formId: itemConstraintCount
              };
              itemConstraintCount++;
            }
            return constraint;
          });
      },

      unselectedConstraints() {
        const selectedConstraintTypes = this.value.earnRuleConstraints.map(constraint => constraint.constraintType);
        return this.availableConstraints.filter(
          // Users can add multiple SPECIFIED_ITEM_GROUP constraintModels, so it should always be allowed
          activeConstraintType => !selectedConstraintTypes.includes(activeConstraintType.type)
            || activeConstraintType.type === constraintModels.SPECIFIED_ITEM_GROUP.type
        );
      }
    },

    methods: {
      handleInput(payload) {
        this.$emit('input', { ...this.value, ...payload });
      },

      findConstraintByType(constraintType) {
        return this.value.earnRuleConstraints.find(constraint => constraint.constraintType === constraintType);
      },

      addConstraint(constraint) {
        let newConstraint;
        if (constraint.type === constraintModels.SPECIFIED_ITEM_GROUP.type) {
          newConstraint = {
            constraintType: constraint.type,
            ...constraint.defaultData()
          };
        }
        else {
          newConstraint = {
            constraintType: constraint.type,
            ...constraint.defaultData
          };
        }
        this.handleInput({ earnRuleConstraints: [...this.value.earnRuleConstraints, newConstraint] });
      },

      updateConstraint(updatedConstraint) {
        const compareFn = (compare, constraint) => (compare ? updatedConstraint : constraint);
        const updatedConstraints = this.value.earnRuleConstraints
          .map((c) => {
            switch (c.constraintType) {
              case constraintModels.SPECIFIED_ITEM_GROUP.type:
                return compareFn(c.itemGroup.publicId === updatedConstraint.itemGroup?.publicId, c);
              default:
                return compareFn(c.constraintType === updatedConstraint.constraintType, c);
            }
          });
        this.handleInput({ earnRuleConstraints: updatedConstraints });
      },

      removeConstraint(constraintToRemove) {
        const updatedConstraints = this.$clone(this.value.earnRuleConstraints).filter((earnRuleConstraint) => {
          if (constraintToRemove.constraintType === constraintModels.SPECIFIED_ITEM_GROUP.type) {
            return earnRuleConstraint.itemGroup?.publicId !== constraintToRemove.itemGroup.publicId;
          }
          else {
            return earnRuleConstraint.constraintType !== constraintToRemove.constraintType;
          }
        });
        this.handleInput({ earnRuleConstraints: updatedConstraints });
      }
    }
  };
</script>
