<template>
  <div class="card-group">
    <content-contact-us-form v-if="$_featurePermissions.contentManagement.CONTACT_US" />
    <content-communication-source-form v-if="$_featurePermissions.contentManagement.COMMUNICATIONS" />
    <content-term-service-form />
    <content-privacy-policy-form />
  </div>
</template>

<script>
  import contentContactUsForm from '../form/content-contact-us-form.vue';
  import contentTermServiceForm from '../form/content-terms-service-form.vue';
  import contentCommunicationSourceForm from '../form/content-communication-source-form.vue';
  import contentPrivacyPolicyForm from '../form/content-privacy-policy-form.vue';
  import featurePermissionsMixin from '@/mixins/featurePermissions';



  export default {
    name: 'GeneralContentTab',

    components: {
      contentContactUsForm,
      contentTermServiceForm,
      contentCommunicationSourceForm,
      contentPrivacyPolicyForm
    },

    mixins: [featurePermissionsMixin]
  };
</script>
