<template>
  <transition name="fade-down">
    <div v-if="$_selectedMerchantId">
      <div class="search-section">
        <div class="filters">
          <b-field
            v-if="isOrderAheadOrderType"
            label-position="on-border"
            label="Order Type"
          >
            <b-dropdown
              v-model="orderAheadOrderType"
              :mobile-modal="false"
              aria-role="list"
              scrollable
              max-height="325px"
              class="has-extra-shadow"
            >
              <dropdown-button slot="trigger" :value="getOrderTypeDisplay(orderAheadOrderType)" />
              <b-dropdown-item :value="orderTypes.ALL_ORDER_AHEAD" aria-role="listitem">
                All
              </b-dropdown-item>
              <b-dropdown-item
                v-for="type in [orderTypes.ORDER_AHEAD, orderTypes.THIRD_PARTY_ORDER_AHEAD]"
                :key="type"
                :value="type"
                aria-role="listitem"
              >
                {{ getOrderTypeDisplay(type) }}
              </b-dropdown-item>
            </b-dropdown>
          </b-field>

          <b-field
            v-if="orderType === orderTypes.ORDER_AND_PAY_AT_TABLE"
            label-position="on-border"
            label="Order Type"
          >
            <b-dropdown
              v-model="atTableOrderType"
              :mobile-modal="false"
              aria-role="list"
              scrollable
              max-height="325px"
              class="has-extra-shadow"
            >
              <dropdown-button slot="trigger" :value="getOrderTypeDisplay(atTableOrderType)" />
              <b-dropdown-item :value="orderTypes.ORDER_AND_PAY_AT_TABLE" aria-role="listitem">
                All
              </b-dropdown-item>
              <b-dropdown-item
                v-for="type in [orderTypes.PAY_AND_FULFILL, orderTypes.ORDER_AND_PAY]"
                :key="type"
                :value="type"
                aria-role="listitem"
              >
                {{ getOrderTypeDisplay(type) }}
              </b-dropdown-item>
            </b-dropdown>
          </b-field>

          <b-field
            v-if="isOrderAheadOrderType"
            label-position="on-border"
            label="Platform"
          >
            <b-dropdown
              v-model="platformType"
              :mobile-modal="false"
              aria-role="list"
              class="has-extra-shadow"
            >
              <dropdown-button
                slot="trigger"
                placeholder="Select Platform"
                :value="platformType ? platformType : 'All'"
              />
              <b-dropdown-item
                :class="!platformType && 'is-active'"
                :value="null"
                aria-role="listitem"
              >
                All
              </b-dropdown-item>
              <hr class="dropdown-divider">
              <b-dropdown-item
                v-for="value in platformTypes"
                :key="value"
                :value="value"
                aria-role="listitem"
              >
                {{ value }}
              </b-dropdown-item>
            </b-dropdown>
          </b-field>

          <b-field
            v-if="isOrderAheadOrderType"
            label-position="on-border"
            label="Order Mode"
          >
            <b-dropdown
              v-model="fulfillmentType"
              :mobile-modal="false"
              aria-role="list"
              class="has-extra-shadow"
            >
              <dropdown-button
                slot="trigger"
                placeholder="Select Order Mode"
                :value="fulfillmentType ? fulfillmentType : 'All'"
                :loading="isLoadingFulfillmentTypes"
              />
              <b-dropdown-item
                :class="!fulfillmentType && 'is-active'"
                :value="null"
                aria-role="listitem"
              >
                All
              </b-dropdown-item>
              <hr class="dropdown-divider">
              <b-dropdown-item
                v-for="value in fulfillmentTypes"
                :key="value"
                :value="value"
                aria-role="listitem"
              >
                {{ formattedFulfillmentType(value) }}
              </b-dropdown-item>
            </b-dropdown>
          </b-field>

          <b-field
            v-if="stores.length > 1"
            label-position="on-border"
            label="Location"
          >
            <b-dropdown
              v-model="locationId"
              :mobile-modal="false"
              aria-role="list"
              scrollable
              max-height="325px"
              class="has-extra-shadow"
            >
              <dropdown-button slot="trigger" :value="locationId ? selectedStore.description : 'All'" />
              <b-dropdown-item :class="!locationId && 'is-active'" :value="null" aria-role="listitem">
                All
              </b-dropdown-item>
              <hr class="dropdown-divider">
              <b-dropdown-item
                v-for="store in stores"
                :key="store.storeId"
                :value="store.storeId"
                aria-role="listitem"
              >
                {{ store.description }}
              </b-dropdown-item>
            </b-dropdown>
          </b-field>

          <b-field
            label-position="on-border"
            label="Order Status"
          >
            <b-dropdown
              v-model="orderStatus"
              :mobile-modal="false"
              aria-role="list"
              class="has-extra-shadow"
            >
              <dropdown-button
                slot="trigger"
                :value="orderStatus ? orderStatusOptions.find(option => option.value === orderStatus).display : 'All' | capitalize"
              />
              <b-dropdown-item
                :class="!orderStatus && 'is-active'"
                :value="null"
                aria-role="listitem"
              >
                All
              </b-dropdown-item>

              <hr class="dropdown-divider">
              <b-dropdown-item
                v-for="{ display, value } in orderStatusOptions"
                :key="value"
                :value="value"
                aria-role="listitem"
              >
                {{ display | capitalize }}
              </b-dropdown-item>
            </b-dropdown>
          </b-field>

          <b-field
            label-position="on-border"
            label="Prep Status"
          >
            <b-dropdown
              v-model="preparationStatus"
              :mobile-modal="false"
              aria-role="list"
              class="has-extra-shadow"
            >
              <dropdown-button
                slot="trigger"
                :value="preparationStatus ? preparationStatusOptions.find(option => option.value === preparationStatus).display : 'All' | capitalize"
              />
              <b-dropdown-item
                :class="!preparationStatus && 'is-active'"
                :value="null"
                aria-role="listitem"
              >
                All
              </b-dropdown-item>

              <hr class="dropdown-divider">
              <b-dropdown-item
                v-for="{ display, value } in preparationStatusOptions"
                :key="value"
                :value="value"
                aria-role="listitem"
              >
                {{ display | capitalize }}
              </b-dropdown-item>
            </b-dropdown>
          </b-field>

          <b-field
            label-position="on-border"
            label="Additional Filters"
          >
            <b-dropdown
              v-if="isPayAtTableOrOrderAndPayOrderType"
              :mobile-modal="false"
              aria-role="list"
              class="has-extra-shadow"
              :close-on-click="false"
            >
              <dropdown-button
                slot="trigger"
                :value="additionalFiltersDisplay"
              />
              <b-dropdown-item
                aria-role="listitem"
                :class="tipAdjusted && 'is-active'"
                @click="tipAdjusted = !tipAdjusted"
              >
                Tip Adjusted
              </b-dropdown-item>
              <b-dropdown-item
                aria-role="listitem"
                :class="orderFeature === 'OfflinePayment' && 'is-active'"
                @click="orderFeature = orderFeature === 'OfflinePayment' ? null : 'OfflinePayment'"
              >
                Offline Payments
              </b-dropdown-item>
            </b-dropdown>
          </b-field>

          <b-field label-position="on-border" label="Date Range" style="min-width: 230px">
            <b-datepicker
              v-model="dateRange"
              class="date-picker-input"
              icon="calendar-alt"
              range
              :min-date="isCardfreeAdmin ? oneHundredEightyDaysAgo : ninetyDaysAgo"
              :max-date="today"
              :focused-date="focusedDate"
              :nearby-selectable-month-days="true"
              :class="['has-extra-shadow', isTodayDateRange && 'today-selected']"
              :mobile-native="false"
            >
              <div class="date-range-shorcuts">
                <b-button
                  size="is-small"
                  :type="isTodayDateRange ? 'is-primary' : ''"
                  @click="setDateRange('today')"
                >
                  Today
                </b-button>
                <b-button
                  size="is-small"
                  :type="isLastSevenDaysDateRange ? 'is-primary' : ''"
                  @click="setDateRange('last-seven-days')"
                >
                  Past 7 Days
                </b-button>
                <b-button
                  size="is-small"
                  :type="isLastThirtyDays ? 'is-primary' : ''"
                  @click="setDateRange('last-thirty-days')"
                >
                  Past 30 days
                </b-button>
                <b-button
                  size="is-small"
                  :type="isLastNinetyDaysRange ? 'is-primary' : ''"
                  @click="setDateRange('last-ninety-days')"
                >
                  Past 90 days
                </b-button>
              </div>
            </b-datepicker>
          </b-field>
        </div>

        <div class="search-inputs">
          <b-field
            label-position="on-border"
            label="Criteria"
            style="flex: 1; min-width: 460px"
          >

            <div class="control">
              <b-select
                v-model="param"
                placeholder="Select Search Criteria"
              >
                <option value="fullName">Name</option>
                <option v-if="!isOrderAheadOrderType && !isExternalOrderType" value="tableNumber">Table Number</option>
                <option value="email">Email Address</option>
                <option value="phone">Phone Number</option>
                <option v-if="!isExternalOrderType" value="lastFour">Last 4 Digits of Card</option>
                <option value="total">Amount Total</option>
                <option value="ticketNumber">Order Number</option>
              </b-select>
            </div>
            <div class="control is-expanded">
              <validated-text-input
                v-model="query"
                :icon-right-clickable="!!query"
                :icon-right="query ? 'times-circle': ''"
                :placeholder="placeholderText"
                :type="queryInputType"
                class="query-input"
                icon="search"
                label-position="on-border"
                label="Search"
                hide-label
                :mask-options="param === 'lastFour' ? { stripLeadingZeroes : false } : null"
                :maxlength="param === 'lastFour' ? '4' : null"
                name="query-input"
                expanded
                output-string
                @icon-right-click="clearQuery"
                @keydown.native.esc="clearQuery"
              />
            </div>
          </b-field>
          <b-button
            v-if="!hidePii"
            type="is-primary"
            outlined
            :loading="isExportingCSV"
            icon-left="file-export"
            icon-pack="far"
            @click="confirmExportResults"
          >
            Export Results
          </b-button>
        </div>
      </div>

      <div class="pad-l result-count is-flex justify-between">
        <div :class="{ 'is-invisible': isLoadingOrders || !ordersTotalCount }">
          <p v-if="ordersTotalCount < 100">
            <span class="has-text-weight-bold has-text-black">
              {{ ordersTotalCount }}
            </span>
            Matching Order{{ ordersTotalCount > 1 ? 's' : '' }}
          </p>
          <div v-else>
            <p>
              Displaying
              <span class="has-text-weight-bold has-text-black">
                {{ ordersTotalCount }}
              </span>
              best results
            </p>
            <p class="is-size-7 has-text-weight-bold has-text-grey">
              If you don’t see the order that you're looking for, please enter a more specific search
            </p>
          </div>
        </div>

        <div>
          <b-dropdown position="is-bottom-left" class="has-extra-shadow">
            <template #trigger>
              <b-button icon-left="columns">
                Columns
                <template v-if="visibleColumnCount !== totalPossibleColumns.length">
                  ({{ visibleColumnCount }}/{{ totalPossibleColumns.length }})
                </template>
              </b-button>
            </template>

            <b-dropdown-item custom>
              <div class="is-flex-column gap-sm">
                <b-checkbox
                  v-for="column in totalPossibleColumns"
                  :key="column.display"
                  v-model="column.isVisible"
                >
                  {{ column.display }}
                </b-checkbox>
                <b-button
                  class="mar-t-sm"
                  type="is-light is-primary"
                  @click="Object.keys(hidableColumns).forEach(key => hidableColumns[key].isVisible = true)"
                >
                  Select All
                </b-button>
              </div>
            </b-dropdown-item>
          </b-dropdown>
        </div>
      </div>

      <div class="card">
        <b-table
          :data="isLoadingOrders ? [] : orders"
          :class="[
            'is-middle-aligned no-header-wrap row-clickable',
            {[`overflow-x-custom-${$_scrollableDirectionX}`]: $_scrollableDirectionX}
          ]"
          hoverable
          paginated
          scrollable
          :total="ordersTotalCount"
          :current-page="page"
          :per-page="perPage"
          :backend-pagination="true"
          pagination-position="bottom"
          :mobile-cards="false"
          :backend-sorting="true"
          :default-sort="['OrderDate', 'desc']"
          @page-change="handlePageChange"
          @sort="handleSort"
          @cellclick="openOrderModal"
        >
          <template slot="empty">
            <empty-table-loader
              :loading="isLoadingOrders"
              :has-icon="false"
              :message="hasMadeInitialSearch ? 'No Matching Orders Found' : 'Choose a criteria type and enter a search term to find orders'"
            />
          </template>
          <b-table-column
            width="1"
            label="Order #"
            sticky
            sticky-header
          >
            <template v-if="$can('manage', 'all')" #header>
              <span @click="showOrderId = !showOrderId">
                Order {{ showOrderId ? 'ID' : '#' }}
              </span>
            </template>
            <template v-slot:default="{ row: order }">
              <span v-if="showOrderId">{{ order.orderId }}</span>
              <template v-else>
                <span v-if="order.ticketNumber">{{ order.ticketNumber }}</span>
                <span v-else>N/A</span>
              </template>
            </template>
          </b-table-column>

          <b-table-column
            v-slot="{ row: order }"
            :visible="[orderTypes.ORDER_AND_PAY_AT_TABLE, orderTypes.ALL_ORDER_AHEAD].includes(orderType) && hidableColumns.type.isVisible"
            width="1"
            label="Type"
          >
            <span class="no-wrap-text">{{ getOrderTypeDisplay(order.orderType) }}</span>
          </b-table-column>

          <b-table-column
            v-slot="{ row: order }"
            :visible="isOrderAheadOrderType && hidableColumns.platformType.isVisible"
            field="PlatformType"
            label="Platform"
            sortable
          >
            {{ order.platformType }}
          </b-table-column>

          <b-table-column
            v-slot="{ row: order }"
            :visible="isOrderAheadOrderType && hidableColumns.category.isVisible"
            sortable
            field="Category"
            label="Category"
          >
            {{ order.category || 'N/A' }}
          </b-table-column>

          <b-table-column
            v-slot="{ row: order }"
            :visible="isOrderAheadOrderType && hidableColumns.orderMode.isVisible"
            sortable
            field="FulfillmentType"
            label="Order Mode"
          >
            <span class="no-wrap-text">{{ getFulfillmentType(order) }}</span>
          </b-table-column>

          <b-table-column
            v-slot="{ row: order }"
            :visible="hidableColumns.location.isVisible"
            :sortable="stores.length > 1"
            field="StoreName"
            label="Location"
          >
            <div class="store-name">
              {{ order.store.description }}
            </div>
          </b-table-column>

          <b-table-column
            v-slot="{ row: order }"
            :visible="hidableColumns.orderDate.isVisible"
            sortable
            field="OrderDate"
            label="Order Date"
          >
            <div class="no-wrap-text">{{ order.orderDate | moment('M/D/YYYY', order.store.timeZone) }}</div>
            <div class="no-wrap-text">{{ order.orderDate | moment('h:mm A z', order.store.timeZone) }}</div>
          </b-table-column>

          <b-table-column
            v-slot="{ row: order }"
            :visible="isOrderAheadOrderType && hidableColumns.pickupDate.isVisible"
            sortable
            field="PickupRequestedDate"
            label="Pickup Date"
          >
            <template v-if="order.pickupDate">
              <div class="no-wrap-text">{{ order.pickupDate | moment('M/D/YYYY', order.store.timeZone) }}</div>
              <div class="no-wrap-text">{{ order.pickupDate | moment('h:mm A z', order.store.timeZone) }}</div>
            </template>
            <span v-else class="has-text-grey-light">N/A</span>
          </b-table-column>

          <b-table-column
            v-slot="{ row: order }"
            :visible="hidableColumns.guestName.isVisible"
            label="Guest Name"
          >
            <span v-if="order.guestFullName && order.guestFullName.trim()">
              {{ order.guestFullName }}
            </span>
            <span v-else class="has-text-grey-light">N/A</span>
          </b-table-column>

          <b-table-column
            v-slot="{ row: order }"
            :visible="hidableColumns.guestInfo.isVisible"
            label="Guest Info"
          >
            <div class="no-wrap-text">
              <b-icon size="is-small" pack="far" class="has-text-grey mar-r-sm" icon="envelope" />
              <span v-if="order.email">{{ order.email }}</span>
              <span v-else class="has-text-grey-light">N/A</span>
            </div>
            <div>
              <b-icon size="is-small" pack="far" class="has-text-grey mar-r-sm" icon="phone" />
              <span v-if="order.phoneNumber">{{ order.phoneNumber | phoneNumber }}</span>
              <span v-else class="has-text-grey-light">N/A</span>
            </div>
          </b-table-column>

          <b-table-column
            v-slot="{ row: order }"
            :visible="!isOrderAheadOrderType && !isExternalOrderType && hidableColumns.tableNumber.isVisible"
            centered
            label="Table #"
          >
            <span v-if="order.tableNumber">{{ order.tableNumber }}</span>
            <span v-else class="has-text-grey-light">N/A</span>
          </b-table-column>

          <b-table-column
            v-slot="{ row: order }"
            :visible="!isExternalOrderType && hidableColumns.merchantOrderReference.isVisible"
            centered
            label="Reference #"
          >
            <span v-if="order.merchantOrderReference">{{ order.merchantOrderReference }}</span>
            <span v-else class="has-text-grey-light">N/A</span>
          </b-table-column>

          <b-table-column
            v-slot="{ row: order }"
            :visible="hidableColumns.orderStatus.isVisible"
            centered
            sortable
            field="Status"
            label="Order Status"
          >
            <b-tag :class="order.status.toLowerCase()">
              {{ getOrderStatus(order.status) }}
            </b-tag>
          </b-table-column>

          <b-table-column
            v-slot="{ row: order }"
            :visible="hidableColumns.preparationStatus.isVisible"
            centered
            sortable
            field="PrepStatus"
            label="Prep Status"
          >
            <b-tag :class="order.preparationStatus ? order.preparationStatus.toLowerCase() : ''">
              {{ order.preparationStatus ? capitalCase(order.preparationStatus) : 'N/A' }}
            </b-tag>
          </b-table-column>

          <b-table-column
            v-slot="{ row: order }"
            :visible="!isExternalOrderType && hidableColumns.paymentState.isVisible"
            centered
            label="Payment Status"
          >
            <b-tag
              v-if="order.paymentState"
              :class="order.paymentState.toLowerCase()"
            >
              {{ order.paymentState | capitalize }}
            </b-tag>
            <b-tag v-else>
              N/A
            </b-tag>
          </b-table-column>

          <b-table-column
            v-slot="{ row: order }"
            numeric
            sortable
            field="OrderTotal"
            label="Total"
          >
            <template v-if="hasTipAdjustedTransaction(order)">
              <span
                v-tippy="{
                  content: 'This order\'s tip was adjusted',
                  placement: 'left',
                  delay: [150, 0]
                }"
                class="is-flex justify-end"
              >
                <b-icon icon="hand-holding-usd" type="is-primary" class="align-baseline mar-r-sm" />
                <span class="has-text-weight-bold has-text-primary">
                  {{ order.orderTotalWithExternalPayment | dollars }}
                </span>
              </span>
            </template>
            <span v-else class="'has-text-weight-bold">
              {{ isExternalOrderType ? order.totalWithTipAndCharges : order.orderTotalWithExternalPayment | dollars }}
            </span>
          </b-table-column>
          <template #bottom-left>
            <div class="left-side">
              <page-number-select
                :page="page"
                :per-page="Number(perPage)"
                :total-count="ordersTotalCount"
                @input="handlePageChange"
              />
              <b-field label-for="perPage" label-position="on-border" label="Per Page" class="mar-none">
                <b-select id="perPage" v-model="perPage">
                  <option value="10">10</option>
                  <option value="20">20</option>
                  <option value="50">50</option>
                  <option value="100">100</option>
                </b-select>
              </b-field>
            </div>
          </template>
        </b-table>
      </div>
    </div>
  </transition>
</template>

<script>

  import { mapGetters } from 'vuex';

  import debounce from 'lodash.debounce';

  import cancelOrderMixin from './orders-helpers/cancel-order';
  import merchantMixin from '@/mixins/merchant';
  import scrollIndicatorMixin from '@/mixins/scroll-container-indicator';

  import Order from '@/store/classes/Order';
  import Store from '@/store/classes/Store';
  import FulfillmentType from '@/store/classes/FulfillmentType';
  import MerchantMenuTypeConfiguration from '@/store/classes/MerchantMenuTypeConfiguration';

  import orderTypes from '@/constants/orderTypes';
  import platformTypes from '@/helpers/platform-types';

  import orderModal from './order-modal.vue';

  import capitalCase from '@/helpers/capitalCase';
  import storage from '@/services/storage';

  import moment from 'moment-timezone';



  export default {
    name: 'OrderList',

    mixins: [merchantMixin, cancelOrderMixin, scrollIndicatorMixin],

    metaInfo() {
      return {
        title: 'Orders'
      };
    },

    props: {
      orderType: {
        type: String,
        required: true
      }
    },

    data() {
      return {
        perPage: 20,
        page: 1,
        totalCount: null,
        sortBy: 'OrderDate-Desc',
        param: null,
        query: null,
        locationId: null,
        orderStatus: 'fulfilled',
        orderStatusOptions: [
          { display: 'cancelled', value: 'cancelled' },
          { display: 'created', value: 'created' },
          { display: 'fulfilled', value: 'fulfilled' },
          { display: 'future', value: 'future' },
          { display: 'pending', value: 'pendingCapturePayment' },
          { display: 'submitted', value: 'submitted' }
        ],
        preparationStatus: null,
        preparationStatusOptions: [
          { display: 'New', value: 'new' },
          { display: 'In Prep', value: 'inPrep' },
          { display: 'Completed', value: 'completed' },
          { display: 'Canceled', value: 'canceled' },
          { display: 'Payment Requested', value: 'paymentRequested' },
          { display: 'Picked Up', value: 'pickedUp' }
        ],
        dateRange: [],
        atTableOrderType: orderTypes.ORDER_AND_PAY_AT_TABLE,
        orderAheadOrderType: orderTypes.ALL_ORDER_AHEAD,
        platformType: null,
        fulfillmentType: null,
        showOrderId: false,
        tipAdjusted: false,
        orderFeature: null,
        orderAheadColumns: ['type', 'platformType', 'category', 'orderMode', 'location', 'orderDate', 'pickupDate', 'guestName', 'guestInfo', 'orderStatus', 'paymentState', 'merchantOrderReference', 'preparationStatus'],
        payAtTableColumns: ['type', 'location', 'orderDate', 'guestName', 'guestInfo', 'tableNumber', 'orderStatus', 'paymentState', 'merchantOrderReference', 'preparationStatus'],
        externalOrderColumns: ['location', 'orderDate', 'guestName', 'guestInfo', 'orderStatus'],
        hidableColumns: {
          type: { isVisible: true, display: 'Type' },
          platformType: { isVisible: true, display: 'Platform' },
          category: { isVisible: true, display: 'Category' },
          orderMode: { isVisible: true, display: 'Order Mode' },
          location: { isVisible: true, display: 'Location' },
          orderDate: { isVisible: true, display: 'Order Date' },
          pickupDate: { isVisible: true, display: 'Pickup Date' },
          guestName: { isVisible: true, display: 'Guest Name' },
          guestInfo: { isVisible: true, display: 'Guest Info' },
          tableNumber: { isVisible: true, display: 'Table Number' },
          orderStatus: { isVisible: true, display: 'Order Status' },
          preparationStatus: { isVisible: true, display: 'Preparation Status' },
          paymentState: { isVisible: true, display: 'Payment Status' },
          merchantOrderReference: { isVisible: true, display: 'Reference #' }
        },
        focusedDate: null,
        hasMadeInitialSearch: false,
        orderTypes
      };
    },

    computed: {
      ...mapGetters('session', ['currentUser', 'isAdminUser', 'isCardfreeAdmin', 'hasAnalyticsAccess']),

      hidePii() {
        return !this.isAdminUser && this.currentUser.userRole.hidePii;
      },

      orders() {
        return Order.query().orderBy('sortOrder').get();
      },

      stores() {
        return Store.orderByName().get();
      },

      selectedStore() {
        return this.stores.find(store => store.storeId === this.locationId);
      },

      isLoadingOrders() {
        return Order.$state().fetching;
      },

      isLoadingStores() {
        return Store.$state().fetchingAll;
      },

      placeholderText() {
        let paramName;

        switch (this.param) {
          case 'fullName':
            paramName = ' by customer name';
            break;
          case 'email':
            paramName = ' by email address';
            break;
          case 'phone':
            paramName = ' by phone number';
            break;
          case 'ticketNumber':
            paramName = ' by order number';
            break;
          case 'tableNumber':
            paramName = ' by table number';
            break;
          case 'total':
            paramName = ' by order total';
            break;
          case 'lastFour':
            paramName = ' by last 4 digits of card';
            break;
          default:
            paramName = '';
        }

        return `Search Orders${paramName}...`;
      },

      queryInputType() {
        switch (this.param) {
          case 'fullName':
            return 'text';

          case 'email':
            return 'email';

          case 'phone':
            return 'phone';

          case 'ticketNumber':
            return 'text';

          case 'total':
            return 'dollars';

          case 'lastFour':
            return 'number';

          default:
            return 'text';
        }
      },

      isTodayDateRange() {
        return this.dateRange.every(date => date.toDateString() === this.today.toDateString());
      },

      isLastSevenDaysDateRange() {
        const [startDate, endDate] = this.dateRange;

        return (startDate && endDate)
          && startDate.toDateString() === this.sevenDaysAgo.toDateString()
          && endDate.toDateString() === this.today.toDateString();
      },

      isLastThirtyDays() {
        const [startDate, endDate] = this.dateRange;

        return (startDate && endDate)
          && startDate.toDateString() === this.thirtyDaysAgo.toDateString()
          && endDate.toDateString() === this.today.toDateString();
      },

      isLastNinetyDaysRange() {
        const [startDate, endDate] = this.dateRange;

        return (startDate && endDate)
          && startDate.toDateString() === this.ninetyDaysAgo.toDateString()
          && endDate.toDateString() === this.today.toDateString();
      },

      today() {
        return moment.utc(moment().startOf('day')).toDate();
      },

      sevenDaysAgo() {
        return moment.utc(moment().subtract(7, 'd').startOf('day')).toDate();
      },

      thirtyDaysAgo() {
        return moment.utc(moment().subtract(30, 'd').startOf('day')).toDate();
      },

      ninetyDaysAgo() {
        return moment.utc(moment().subtract(90, 'd').startOf('day')).toDate();
      },

      oneHundredEightyDaysAgo() {
        return moment.utc(moment().subtract(180, 'd').startOf('day')).toDate();
      },

      isCancellingOrder() {
        return Order.$state().cancelling;
      },

      isExportingCSV() {
        return Order.$state().exporting;
      },

      searchAndExportDefaultOrderType() {
        if (this.orderType === orderTypes.ORDER_AND_PAY_AT_TABLE) {
          return this.atTableOrderType;
        }
        else if (this.isOrderAheadOrderType) {
          return this.orderAheadOrderType;
        }
        return this.orderType;
      },


      isOrderAheadOrderType() {
        const { ORDER_AHEAD, THIRD_PARTY_ORDER_AHEAD, ALL_ORDER_AHEAD } = this.orderTypes;
        return [ORDER_AHEAD, THIRD_PARTY_ORDER_AHEAD, ALL_ORDER_AHEAD].includes(this.orderType);
      },

      isPayAtTableOrOrderAndPayOrderType() {
        const { PAY_AND_FULFILL, ORDER_AND_PAY, ORDER_AND_PAY_AT_TABLE } = this.orderTypes;
        return [PAY_AND_FULFILL, ORDER_AND_PAY, ORDER_AND_PAY_AT_TABLE].includes(this.orderType);
      },

      isExternalOrderType() {
        return this.orderType === orderTypes.EXTERNAL_ORDER;
      },

      visibleColumnCount() {
        return this.totalPossibleColumns.filter(c => c.isVisible).length;
      },

      totalPossibleColumns() {
        return Object.entries(this.hidableColumns)
          .reduce((array, [key, value]) => {
            if (this.isOrderAheadOrderType) {
              if (this.orderAheadColumns.includes(key)) {
                array.push(value);
              }
            }
            else if (this.isPayAtTableOrOrderAndPayOrderType) {
              if (this.payAtTableColumns.includes(key)) {
                array.push(value);
              }
            }
            else if (this.externalOrderColumns.includes(key)) {
              // external order type selected
              array.push(value);
            }
            return array;
          }, []);
      },

      ordersTotalCount() {
        return Order.$state().ordersTotalCount;
      },

      fulfillmentTypes() {
        const stores = !this.selectedStore ? this.stores : [this.selectedStore];
        const types = FulfillmentType.merchantSupported(this.$_selectedMerchant, stores);
        return Object.values(types).map(t => t.name);
      },

      isLoadingFulfillmentTypes() {
        return FulfillmentType.$state().fetching || MerchantMenuTypeConfiguration.$state().fetching;
      },

      additionalFiltersDisplay() {
        if (this.orderFeature === 'OfflinePayment' && this.tipAdjusted) return 'Selected (2)';
        else if (this.orderFeature === 'OfflinePayment') return 'Offline Payments';
        else if (this.tipAdjusted) return 'Tip Adjusted';
        else return 'Select Filter(s)...';
      }
    },

    watch: {
      param: 'handleParamChange',
      query: 'debouncedHandleQueryChange',
      locationId: 'handleLocationChange',
      orderStatus: 'handleStatusChange',
      preparationStatus: 'handlePrepStatusChange',
      dateRange: {
        deep: true,
        handler: 'handleDateRangeChange'
      },
      tipAdjusted: 'handleTipAdjustedChange',
      orderFeature: 'handleOrderFeatureChange',
      atTableOrderType: 'handleOrderTypeChange',
      orderAheadOrderType: 'handleOrderTypeChange',
      platformType: 'handlePlatformTypeChange',
      fulfillmentType: 'handleFulfillmentTypeChange',
      hidableColumns: {
        deep: true,
        handler: 'handleHideColumn'
      },
      perPage: 'handlePerPageChange'
    },

    created() {
      this.onCreated();
    },

    mounted() {
      this.onMounted();
    },

    methods: {
      capitalCase,

      onCreated() {
        // Since we no longer kick off a search on created(), we need to clear the store to refresh it as
        // a user clicks to different OrderLists within the OrderTabsPage
        Order.deleteAll();

        this.platformTypes = platformTypes.map(p => p.name);
        this.dateRange = [this.ninetyDaysAgo, this.today];
        this.param = this.isOrderAheadOrderType ? 'fullName' : 'ticketNumber';

        this.hidableColumns = {
          ...this.hidableColumns,
          ...storage.local.get('order-list-columns') || {}
        };
      },

      onMounted() {
        this.$nextTick(() => {
          this.$_initScrollIndicatorSmart({
            contentSelector: '.table',
            autoStyles: false
          });
          this.focusedDate = this.dateRange[1];
        });

        Order.resetState();
      },

      formattedFulfillmentType(name) {
        return FulfillmentType.formattedName(name);
      },

      handleHideColumn(obj) {
        storage.local.set('order-list-columns', obj);
        this.$nextTick(() => {
          this.$_setOverflowAttribute();
        });
      },

      hasTipAdjustedTransaction(order) {
        return order.payments
          .some(p => p.transactions
            .some(t => t.paymentTransaction.paymentTransactionType === 'TipAdjustment' && t.paymentTransaction.paymentTransactionStatus === 'Succeeded'));
      },

      getOrderTypeDisplay(type) {
        switch (type) {
          case this.orderTypes.ORDER_AND_PAY_AT_TABLE:
          case this.orderTypes.ALL_ORDER_AHEAD:
            return 'All';
          case this.orderTypes.PAY_AND_FULFILL:
            return 'Pay @ Table';
          case this.orderTypes.ORDER_AND_PAY:
            return 'Order @ Table';
          case this.orderTypes.ORDER_AHEAD:
            return 'Order Ahead';
          case this.orderTypes.THIRD_PARTY_ORDER_AHEAD:
            return '3rd Party Order Ahead';
          default:
            return '';
        }
      },

      getOrderStatus(status) {
        switch (status) {
          case 'PendingCapturePayment':
            return 'Pending';
          default:
            return status;
        }
      },

      confirmExportResults() {
        const baseMessage = 'Are you sure you want to export your search results? Search results have a limit of 500 orders.';
        const analyticsMessage = ' Go to <a href="/analytics/data-exports">Data Exports</a> to download all orders.';

        this.$buefy.dialog.confirm({
          title: 'Confirm Export',
          message: baseMessage + (this.hasAnalyticsAccess ? analyticsMessage : ''),
          confirmText: 'Export',
          cancelText: 'Cancel',
          type: 'is-primary',
          hasIcon: true,
          iconPack: 'fas',
          icon: 'question-circle',
          onConfirm: () => {
            this.exportOrders();
          }
        });
      },

      async exportOrders() {
        const [startDate, endDate] = this.dateRange.map(date => moment(date).format('YYYY-MM-DD'));
        const csvCriteria = {
          param: this.param,
          query: this.query,
          locationId: this.locationId,
          orderStatus: this.orderStatus,
          preparationStatus: this.preparationStatus,
          orderType: this.searchAndExportDefaultOrderType,
          orderFeature: this.orderFeature,
          tipAdjusted: this.tipAdjusted,
          sortBy: this.sortBy,
          startDate,
          endDate
        };

        try {
          const csv = await Order.exportToCSV(csvCriteria);
          this.createAndDownloadCSV(csv);
        }

        catch (error) {
          this.$_onRequestError({
            error,
            toastOptions: { message: 'There was an error exporting your orders' }
          });
        }
      },

      createAndDownloadCSV(csv) {
        const downloadLink = document.createElement('a');
        const blob = new Blob(['\ufeff', csv]);
        const url = URL.createObjectURL(blob);
        const currentDate = moment(Date.now()).format('YYYY-MM-DD');
        const downloadName = `${this.$_selectedMerchant.friendlyUriName}-orders-${currentDate}.csv`;

        downloadLink.href = url;
        downloadLink.download = downloadName;
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);

        return downloadLink;
      },

      getFulfillmentType(order) {
        const { fulfillmentType } = order;
        if (!fulfillmentType) {
          return 'N/A';
        }
        return FulfillmentType.formattedName(fulfillmentType);
      },

      clearQuery() {
        this.query = '';
      },

      openOrderModal(order) {
        this.$buefy.modal.open({
          canCancel: ['escape', 'outside'],
          component: orderModal,
          customClass: 'auto-width',
          hasModalCard: true,
          parent: this,
          props: { orderId: order.orderId }
        });
      },

      setDateRange(range) {
        switch (range) {
          case 'today': {
            const today = moment.utc(moment().startOf('day')).toDate();
            this.dateRange = [today, today];
            break;
          }
          case 'last-seven-days': {
            this.dateRange = [this.sevenDaysAgo, this.today];
            break;
          }
          case 'last-thirty-days': {
            this.dateRange = [this.thirtyDaysAgo, this.today];
            break;
          }
          case 'last-ninety-days': {
            this.dateRange = [this.ninetyDaysAgo, this.today];
            break;
          }
          default:
            break;
        }
      },

      handleOrderTypeChange(orderType) {
        this.page = 1;
        this.searchOrders({ orderType });
      },

      handleLocationChange(locationId) {
        this.page = 1;
        this.searchOrders({ locationId });
      },

      handlePlatformTypeChange(platformType) {
        this.page = 1;
        this.searchOrders({ platformType });
      },

      handleFulfillmentTypeChange(fulfillmentType) {
        this.page = 1;
        this.searchOrders({ fulfillmentType });
      },

      handleStatusChange(orderStatus) {
        this.page = 1;
        this.searchOrders({ orderStatus });
      },

      handlePrepStatusChange(preparationStatus) {
        this.page = 1;
        this.searchOrders({ preparationStatus });
      },

      handleDateRangeChange(dateRange, oldVal) {
        if (oldVal.length) {
          this.page = 1;
          this.searchOrders({ dateRange });
          this.focusedDate = null;
          this.$nextTick(() => {
            this.focusedDate = dateRange[1];
          });
        }
      },

      handleTipAdjustedChange(tipAdjusted) {
        this.page = 1;
        this.searchOrders({ tipAdjusted });
      },

      handleOrderFeatureChange(orderFeature) {
        this.page = 1;
        this.searchOrders({ orderFeature });
      },

      handlePerPageChange(perPage) {
        this.page = 1;
        this.searchOrders({ perPage });
      },

      handlePageChange(page) {
        this.page = page;
        this.searchOrders({ page });
      },

      handleParamChange(param) {
        this.page = 1;

        if (this.query) {
          this.searchOrders({ param });
        }
      },

      // eslint-disable-next-line
      debouncedHandleQueryChange: debounce(function (query) {  // TEST ?
        this.handleQueryChange(query);
      }, 666),

      handleQueryChange(newQuery) {
        this.page = 1;

        const query = newQuery && this.param === 'phone'
          ? newQuery
            .split('')
            .filter(char => /[0-9]/.test(char))
            .join('')
          : newQuery;

        this.searchOrders({ query });
      },

      handleSort(field, direction) {
        const sortBy = `${field}-${capitalCase(direction)}`;

        this.sortBy = sortBy;
        this.searchOrders({ sortBy });
      },

      async searchOrders({
        param = this.param,
        query = this.query,
        locationId = this.locationId,
        orderStatus = this.orderStatus,
        preparationStatus = this.preparationStatus,
        tipAdjusted = this.tipAdjusted,
        orderFeature = this.orderFeature,
        orderType = this.searchAndExportDefaultOrderType,
        platformType = this.platformType,
        fulfillmentType = this.fulfillmentType,
        dateRange = this.dateRange,
        sortBy = this.sortBy,
        page = this.page,
        perPage = this.perPage
      } = {}) {
        const [startDate, endDate] = dateRange.map(date => moment(date).format('YYYY-MM-DD'));

        try {
          await Order.searchOrders({
            param,
            query,
            locationId,
            orderStatus,
            preparationStatus,
            orderType,
            tipAdjusted,
            orderFeature,
            platformType,
            fulfillmentType,
            startDate,
            endDate,
            sortBy,
            page,
            perPage
          });
          this.$_setOverflowAttribute();

          if (!this.hasMadeInitialSearch) {
            this.hasMadeInitialSearch = true;
          }
        }

        catch (error) {
          this.$_onRequestError({
            error,
            toastOptions: { message: 'There was an error loading your orders' }
          });
        }
      }
    }
  };
</script>

<style lang="sass" scoped>
  .search-section
    display: flex
    gap: $size-normal
    flex-wrap: wrap-reverse

    .filters, .search-inputs
      display: flex
      gap: $size-normal
      > *
        margin: 0

    ::v-deep.dropdown-trigger
      .button
        min-width: 7rem
        justify-content: space-between

        .icon
          color: $primary

  .result-count
    margin: $size-small 0

  .query-input,
  .search-inputs
    flex: 1 1

  .date-picker-input
    position: relative

    &.today-selected
      // hides actual input text and instead displays "Today"
      ::v-deep.dropdown-trigger .input
        color: transparent

      &::after
        content: 'Today'
        color: $grey-darker
        font-size: 1rem
        padding: 7px 40px
        pointer-events: none
        position: absolute
        top: 0
        right: 0
        bottom: 0
        left: 0


  .date-range-shorcuts
    display: grid
    grid-template-columns: 1fr 1fr
    gap: 0.5rem
    grid-gap: 0.5rem

    .button
      font-weight: 700

  .store-name
    min-width: 10rem

  ::v-deep
    .tag
      font-weight: 700

      // { display: 'New', value: 'new' },
      //     { display: 'In Prep', value: 'inPrep' },
      //     { display: 'Completed', value: 'completed' },
      //     { display: 'Canceled', value: 'canceled' },
      //     { display: 'Payment Requested', value: 'paymentRequested' },
      //     { display: 'Picked Up', value: 'pickedUp' }

      &.created
        background-color: lighten($grey, 43)
        color: darken($grey, 40)
      &.submitted
        background-color: lighten($primary, 43)
        color: darken($primary, 40)
      &.fulfilled
        background-color: lighten($success, 37)
        color: darken($success, 40)
      &.cancelled
        background-color: lighten($danger, 33)
        color: darken($danger, 40)
      &.future
        background-color: lighten($warning, 22)
        color: darken($warning, 50)
      &.pendingcapturepayment
        background-color: #e8cbf5
        color: darken(#d18fd1, 55)
      &.unknown
        background-color: lighten($grey, 43)
        color: darken($grey, 40)
      &.paid
        background-color: lighten($success, 37)
        color: darken($success, 40)
      &.failed
        background-color: lighten($danger, 33)
        color: darken($danger, 40)
      &.authorized
        background-color: lighten($warning, 22)
        color: darken($warning, 50)
      &.partiallyauthorized
        background-color: lighten($warning, 22)
        color: darken($warning, 50)
      &.paymentrequested
        background-color: lighten($warning, 22)
        color: darken($warning, 50)
      &.new
        background-color: lighten($primary, 43)
        color: darken($primary, 40)
      &.completed
        background-color: lighten($success, 37)
        color: darken($success, 40)
      &.pickedup
        background-color: lighten($grey, 43)
        color: darken($grey, 40)

    th.is-sticky
      background: $white !important

    tr:hover
      td.is-sticky
        background: inherit

    .table-wrapper
      position: static

    .is-sticky
      position: relative

    .is-sticky::before,
    .table-container::after
      content: ''
      position: absolute
      top: 0
      opacity: 0
      width: 40px
      pointer-events: none
      transition: opacity 200ms

    .is-sticky::before
      bottom: 0
      left: 100%
      box-shadow: 30px 0px 25px -20px inset rgba(0, 0, 0, 0.125), 9px 0px 10px -10px inset rgba(0, 0, 0, 0.25)

    .table-container::after
      bottom: 73px
      right: 0
      box-shadow: -30px 0px 25px -20px inset rgba(0, 0, 0, 0.125), -9px 0px 10px -10px inset rgba(0, 0, 0, 0.25)

    .overflow-x-custom-left .is-sticky::before
      opacity: 1

    .overflow-x-custom-right .table-container::after
      opacity: 1

    .overflow-x-custom-both
      .is-sticky::before,
      .table-container::after
        opacity: 1

  .left-side
    display: flex
    gap: 1rem

  @media (max-width: $widescreen)
    .search-section
      flex-direction: column-reverse

    .query-input
      margin-top: 0 !important
      width: 100%

    .left-side
      justify-content: center
</style>
