import FetchModel from '@/store/classes/FetchModel';

export default class ConnectedAccountType extends FetchModel {
  static entity = 'connectedAccountTypes';

  // FIELDS //////////////////////
  static fields() {
    return {
      id: this.attr(null),
      connectedAccountTypeCode: this.attr(''),
      displayName: this.attr(''),
      description: this.attr(''),
      enabled: this.boolean(false),
      canUseForAuthentication: this.boolean(false)
    };
  }


  // STATE //////////////////////
  static state() {
    return {
      fetching: false
    };
  }

  static $state() {
    return this.store().state.entities.connectedAccountTypes;
  }


  // ACTIONS //////////////////////
  static async fetchConnectedAccountTypes(options = {}) {
    await this.fetchData({
      endpoint: 'connected_account_types',
      options,
      transformData: data => data.connectedAccountTypes
    });
  }
}
