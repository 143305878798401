<template>
  <div>
    <p class="subtitle is-5 is-marginless">
      Modifiers
    </p>
    <draggable
      v-model="sortedMenuItemModifiers"
      tag="ul"
      v-bind="draggableAttributes"
      :force-fallback="true"
      handle=".drag-handle"
      class="dist-y-sm mar-t"
      @change="handleModifierSort"
    >
      <li
        v-for="modifier in sortedMenuItemModifiers"
        :key="modifier.id"
        class="is-flex align-center"
        data-test-class="modifier-group-modifier"
      >
        <div class="draggable is-flex justify-start align-center pad-xs has-border has-border-grey-lightest has-radius-large is-full-width">
          <b-icon
            v-if="!readOnly"
            icon="grip-lines"
            size="is-small"
            pack="far"
            class="drag-handle mar-r-sm"
          />
          {{ modifier.displayName }}
          <div class="mar-l-auto is-flex align-center">
            <div
              v-if="!readOnly"
              v-tippy="{
                content: 'A nested group cannot be the default',
                placement: 'left',
                onShow: () => $_featurePermissions.NESTED_MODIFIERS && modifier.hasChildrenModifierGroups
              }"
              class="is-flex align-center"
            >
              <b-radio
                v-if="modifierGroup.maxAllowed === 1"
                class="mar-r-none"
                :value="modifier.isDefault"
                :native-value="true"
                :disabled="!canSetAsDefault(modifier)"
                :name="`${modifierGroup.id}-isDefault`"
                @input="handleSelectedChange({ modifierId: modifier.id, isDefault: $event, isRadio: true })"
                @click.native="handleSelfClick($event, modifier.id)"
              />
              <b-checkbox
                v-else
                :disabled="!canSetAsDefault(modifier) || !modifier.isDefault && hasGroupReachedDefaultModifierLimit"
                :value="modifier.isDefault"
                @input="handleSelectedChange({ modifierId: modifier.id, isDefault: $event })"
              />
            </div>
            <b-button
              v-if="!readOnly"
              class="is-transparent button is-small"
              @click="openModifierModal({ modifierId: modifier.id, mode: 'update' })"
            >
              <b-icon
                icon="pencil"
                size="is-medium"
              />
            </b-button>
          </div>
        </div>
        <b-button
          v-if="!readOnly"
          v-tippy="{ content: `Remove ${modifier.displayName} from ${modifierGroup.displayName}`, placement: 'left', delay: [333, 0] }"
          class="is-transparent button is-small"
          @click="confirmRemoveModifierFromGroup(modifier)"
        >
          <b-icon
            icon="circle-minus"
            type="is-danger"
            size="is-medium"
          />
        </b-button>
      </li>
    </draggable>
    <div class="pad-t-sm is-flex-column align-center justify-center">
      <em
        v-if="!sortedMenuItemModifiers.length"
        data-test-id="no-modifiers-message"
      >
        This modifier group doesn't currently have any modifiers.
      </em>
      <template v-if="$_selectedMerchant.allowModifierGroupAssociation">
        <b-button
          v-if="!readOnly"
          type="is-primary is-light"
          icon-left="plus"
          :disabled="!modifierGroup.id"
          data-test-id="add-modifier-button"
          @click="openModifierModal({ mode: 'create' })"
        >
          Add Modifier to Modifier Group
        </b-button>
      </template>
    </div>
  </div>
</template>

<script>
  import draggable from 'vuedraggable';

  import AlertModal from '@/components/globals/alert-modal.vue';

  import Modifier from '@/store/classes/Modifier';

  import featurePermissionsMixin from '@/mixins/featurePermissions';
  import merchantMixin from '@/mixins/merchant';

  import AddEditModifierModal from '../../add-edit-modifier-modal/index.vue';

  export default {
    name: 'ModifierGroupModifiersTab',

    components: { draggable },

    mixins: [featurePermissionsMixin, merchantMixin],

    props: {
      modifierGroup: {
        type: Object,
        required: true
      },

      readOnly: {
        type: Boolean,
        default: false
      }
    },

    data: () => ({
      draggableAttributes: {
        animation: '200',
        ghostClass: 'ghost',
        handle: '.drag-handle'
      },
      sortedMenuItemModifiers: []
    }),

    computed: {
      hasGroupReachedDefaultModifierLimit() {
        const defaultCount = this.modifierGroup.menuItemModifiers.filter(mod => mod.isDefault)?.length || 0;
        return this.modifierGroup.maxAllowed !== -1 && defaultCount >= this.modifierGroup.maxAllowed;
      }
    },

    created() {
      this.setSortedMenuItemModifiers();
    },

    methods: {
      requeryAndResetModifiers() {
        this.$emit('requery-modifiers');
        this.setSortedMenuItemModifiers();
      },

      setSortedMenuItemModifiers() {
        this.sortedMenuItemModifiers = this.modifierGroup.menuItemModifiers.sort((a, b) => ((a.sortOrder < b.sortOrder) ? -1 : 1));
      },

      async handleModifierSort({ moved }) {
        try {
          await Modifier.updateSortOrders({
            modifierGroup: this.modifierGroup,
            modifiers: this.sortedMenuItemModifiers,
            oldIndex: moved.oldIndex,
            newIndex: moved.newIndex
          });

          this.requeryAndResetModifiers();

          this.$_onRequestSuccess({
            toastOptions: {
              message: `<b>${moved.element.displayName}</b> moved from sort order <b>${moved.oldIndex + 1}</b> to <b>${moved.newIndex + 1}</b>`
            }
          });
        }

        catch (error) {
          this.$_onRequestError({
            toastOptions: { message: 'Unable to update sort orders' },
            error
          });
        }
      },

      openModifierModal({ modifierId, mode }) {
        this.$buefy.modal.open({
          parent: this,
          component: AddEditModifierModal,
          hasModalCard: true,
          trapFocus: true,
          canCancel: false,
          customClass: 'auto-width',
          props: {
            modifierGroup: this.modifierGroup,
            mode,
            modifierId
          },
          events: {
            'modifier-added': this.requeryAndResetModifiers,
            'modifier-updated': this.requeryAndResetModifiers
          }
        });
      },

      confirmRemoveModifierFromGroup(modifier) {
        this.$buefy.modal.open({
          parent: this,
          component: AlertModal,
          hasModalCard: true,
          trapFocus: true,
          canCancel: ['escape', 'outside'],
          customClass: 'auto-width',
          props: {
            title: 'Remove Modifier from Modifier Group',
            message: `Are you sure you want to remove the modifier <b>${modifier.displayName}</b> from the modifier group <b>${this.modifierGroup.displayName}</b>?`,
            showCloseButton: false,
            horizontal: true,
            icon: 'trash-alt',
            type: 'is-danger',
            buttons: [
              { text: 'Cancel' },
              { text: 'Yes, Remove Modifier', primary: true, onClick: () => this.deleteModifier(modifier) }
            ]
          }
        });
      },

      async deleteModifier(modifier) {
        try {
          await Modifier.deleteModifier(modifier.id);

          this.$_onRequestSuccess({
            toastOptions: {
              message: `Successfully deleted <b>${modifier.displayName}</b>`
            }
          });

          this.requeryAndResetModifiers();
        }

        catch (error) {
          this.$_onRequestError({
            error,
            toastOptions: {
              message: `Unable to delete <b>${modifier.displayName}</b>`
            }
          });
        }
      },

      canSetAsDefault(modifier) {
        return (
          this.$can('update', 'MenuItemModifier')
          && this.$_menuPermissions.SET_DEFAULT_MODIFIER
          && !(this.$_featurePermissions.NESTED_MODIFIERS && modifier.hasChildrenModifierGroups)
        );
      },

      async handleSelectedChange({ modifierId, isDefault, isRadio }) { // eslint-disable-line object-curly-newline
        try {
          if (isRadio) {
            const updatedModifiers = JSON.parse(JSON.stringify(this.modifierGroup.menuItemModifiers)).map((modifier) => {
              modifier.isDefault = modifier.id === modifierId;
              return modifier;
            });

            await Modifier.bulkUpdateModifiers({
              modifierGroup: this.modifierGroup,
              modifiers: this.modifierGroup.menuItemModifiers,
              updatedModifiers
            });
          }

          else {
            await Modifier.updateModifier({ id: modifierId, isDefault });
          }

          this.$_onRequestSuccess({
            toastOptions: {
              message: 'Successfully updated modifier selection!'
            }
          });

          this.requeryAndResetModifiers();
        }

        catch (error) {
          this.$_onRequestError({
            error,
            toastOptions: {
              message: 'Unable to update modifier selection'
            }
          });
        }
      },

      handleSelfClick(e, modifierId) {
        const isChecked = e.target.closest('.b-radio').querySelector('input').checked;

        if (isChecked && this.$can('update', 'MenuItemModifier')) {
          this.handleSelectedChange({ modifierId, isDefault: !isChecked });
        }
      }
    }
  };
</script>
