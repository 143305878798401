/* eslint-disable import/no-cycle */
import { Model } from '@vuex-orm/core';
import http from '@/services/http';
import filterObjectKeys from '@/helpers/filter-object-keys';
import RegionMenuItem from './RegionMenuItem';
import RegionMenuItemModifier from './RegionMenuItemModifier';
import RegionMenuCategory from './RegionMenuCategory';
import Store from './Store';

export default class RegionMenu extends Model {
  static entity = 'regionMenus'

  // FIELDS //////////////////////
  static fields() {
    return {
      id: this.attr(null),
      name: this.attr(''),
      regionMenuItems: this.hasMany(RegionMenuItem, 'menuRegionId'),
      regionMenuItemModifiers: this.hasMany(RegionMenuItemModifier, 'menuRegionId'),
      regionMenuCategories: this.hasMany(RegionMenuCategory, 'menuRegionId'),
      stores: this.hasMany(Store, 'menuRegionId')
    };
  }

  // STATE //////////////////////
  static state() {
    return {
      fetching: false,
      submitting: false,
      deletingId: null
    };
  }

  static $state() {
    return this.store().state.entities.regionMenus;
  }

  // ACTIONS //////////////////////
  static async fetchRegionMenus() {
    try {
      this.commit((state) => {
        state.fetching = true;
      });

      const merchantId = this.store().state.entities.merchants.selectedMerchantId;

      const { data } = await http.get(`merchants/${merchantId}/region_menus`);

      this.create({ data: data.regionMenus });
    }

    catch (error) {
      throw error;
    }

    finally {
      this.commit((state) => {
        state.fetching = false;
      });
    }
  }

  static async addRegionMenu(regionMenu) {
    try {
      this.commit((state) => {
        state.submitting = true;
      });

      const merchantId = this.store().state.entities.merchants.selectedMerchantId;
      const acceptedKeys = ['name'];

      const response = await http.post(`merchants/${merchantId}/region_menus`, { regionMenu: filterObjectKeys(regionMenu, acceptedKeys) });

      this.insert({
        data: response.data.regionMenu
      });

      return response.data.regionMenu;
    }

    catch (error) {
      throw error;
    }

    finally {
      this.commit((state) => {
        state.submitting = false;
      });
    }
  }

  static async updateRegionMenu(regionMenu) {
    try {
      this.commit((state) => {
        state.submitting = true;
      });

      const merchantId = this.store().state.entities.merchants.selectedMerchantId;
      const acceptedKeys = ['name'];

      const response = await http.put(`merchants/${merchantId}/region_menus/${regionMenu.id}`, {
        regionMenu: filterObjectKeys(regionMenu, acceptedKeys)
      });

      this.insert({
        data: response.data.regionMenu
      });
    }

    catch (error) {
      throw error;
    }

    finally {
      this.commit((state) => {
        state.submitting = false;
      });
    }
  }

  static async deleteRegionMenu(regionMenuId) {
    try {
      this.commit((state) => {
        state.deletingId = regionMenuId;
      });

      const merchantId = this.store().state.entities.merchants.selectedMerchantId;

      await http.delete(`merchants/${merchantId}/region_menus/${regionMenuId}`);

      this.delete(regionMenuId);
    }

    catch (error) {
      throw error;
    }

    finally {
      this.commit((state) => {
        state.deletingId = null;
      });
    }
  }
}
