import capitalCase from '@/helpers/capitalCase';
import http from '@/services/http';
import LoyaltyDraft from '@/store/classes/LoyaltyDraft';


export default class Currency extends LoyaltyDraft {
  static entity = 'currencies'

  static primaryKey = 'publicId'

  // FIELDS //////////////////////
  static fields() {
    return {
      ...super.fields(),
      publicId: this.attr(''),

      // editable fields
      title: this.attr(''),
      name: this.attr(''),
      description: this.attr(''),
      longDescription: this.attr(''),
      shortName: this.attr(''),
      pluralName: this.attr(''),
      pluralShortName: this.attr(''),
      category: this.attr(null),
      challengeProcessingType: this.attr(null),
      accumulationType: this.attr(null),

      // non-editable fields
      awardStyle: this.attr(),
      conversionSchedules: this.attr(),
      endDate: this.attr(),
      expirationPolicy: this.attr(),
      maxLedgerBalance: this.attr(),
      programPublicId: this.attr(),
      startDate: this.attr()
    };
  }

  static accumulationTypes = {
    UNKNOWN: 0,
    SPEND: 1,
    COUNT: 2
  }

  static awardStyles = {
    UNKNOWN: 0,
    EXCHANGE: 1
  }

  static categoryTypes = {
    UNKNOWN: 0,
    PRIMARY: 1,
    CHALLENGE: 2
  }

  static challengeProcessingTypes = {
    NONE: 0,
    AUTOMATIC_AWARD_CHALLENGE: 1,
    SCHEDULED_PROCESSING_CHALLENGE: 2
  }

  formattedPluralName() {
    return this.pluralName ? capitalCase(this.pluralName) : '';
  }

  static primaryCurrency() {
    return this.query().where('category', 1).first();
  }

  static secondaryCurrencies() {
    return this.query().where('category', 2).get();
  }


  // STATE //////////////////////
  static state() {
    return {
      fetching: false,
      fetchingDrafts: false,
      submitting: false
    };
  }

  static $state() {
    return this.store().state.entities.currencies;
  }


  // ACTIONS //////////////////////
  static async fetchDraftCurrencies() {
    try {
      this.commit((state) => {
        state.fetchingDrafts = true;
      });

      const merchantId = this.store().state.entities.merchants.selectedMerchantId;
      const { data } = await http.get(`merchants/${merchantId}/currencies/drafts`);

      this.insert({
        data: data.draftCurrencies.map(d => ({ ...d, ...d.body }))
      });
    }

    catch (error) {
      throw error;
    }

    finally {
      this.commit((state) => {
        state.fetchingDrafts = false;
      });
    }
  }

  static async fetchCurrencies(options = {}) {
    try {
      this.commit((state) => {
        state.fetching = true;
      });

      const alreadyFetched = this.all().filter(c => !c.isDraft).length > 0;

      if (!alreadyFetched || options.forceFetch) {
        const merchantId = this.store().state.entities.merchants.selectedMerchantId;
        const { data: { currencies } } = await http.get(`/merchants/${merchantId}/currencies`);

        this.insert({ data: currencies.map(c => ({ ...c, loyaltyPublicId: c.publicId })) });
      }
    }

    catch (error) {
      throw error;
    }

    finally {
      this.commit((state) => {
        state.fetching = false;
      });
    }
  }

  static async createDraftCurrency(draftCurrency) {
    try {
      this.commit((state) => {
        state.submitting = true;
      });

      // const draftCurrency = new this({
      //   name: 'Mock Currency',
      //   shortName: 'MC',
      //   pluralName: 'Mock Currencies',
      //   pluralShortName: 'MCs',
      //   category: 2,
      //   challengeProcessingType: 1
      // });

      const merchantId = this.store().state.entities.merchants.selectedMerchantId;
      const { data: { draftCurrency: dc } } = await http.post(`/merchants/${merchantId}/currencies/drafts`, { currency: draftCurrency });

      this.insert({ data: { ...dc, ...dc.body } });

      return dc.id;
    }

    catch (error) {
      throw error;
    }

    finally {
      this.commit((state) => {
        state.submitting = false;
      });
    }
  }

  static async createCurrency(currency) {
    try {
      this.commit((state) => {
        state.submitting = true;
      });

      const merchantId = this.store().state.entities.merchants.selectedMerchantId;
      const { data: { currency: c } } = await http.post(`/merchants/${merchantId}/currencies`, { currency });

      this.insert({ data: { ...c, loyaltyPublicId: c.publicId } });

      return { ...c, loyaltyPublicId: c.publicId };
    }

    catch (error) {
      throw error;
    }

    finally {
      this.commit((state) => {
        state.submitting = false;
      });
    }
  }

  static async updateDraftCurrency(currency) {
    try {
      this.commit((state) => {
        state.submitting = true;
      });

      const merchantId = this.store().state.entities.merchants.selectedMerchantId;
      const { data: { draftCurrency } } = await http.put(`/merchants/${merchantId}/currencies/drafts/${currency.id}`, { currency });

      // NOTE: Because publicId is the primary key, we can use the generated value to determine the record to update
      this.update({
        data: { ...draftCurrency, ...draftCurrency.body },
        where: currency.publicId
      });
    }

    catch (error) {
      throw error;
    }

    finally {
      this.commit((state) => {
        state.submitting = false;
      });
    }
  }

  static async updateCurrency({ publicId, ...currency }) {
    try {
      this.commit((state) => {
        state.submitting = true;
      });

      const merchantId = this.store().state.entities.merchants.selectedMerchantId;
      const { data: { currency: c } } = await http.put(`/merchants/${merchantId}/currencies/${publicId}`, { currency });

      this.update({ data: { ...c, loyaltyPublicId: c.publicId } });
    }

    catch (error) {
      throw error;
    }

    finally {
      this.commit((state) => {
        state.submitting = false;
      });
    }
  }

  static async promoteDraftCurrency(draftCurrency) {
    try {
      this.commit((state) => {
        state.submitting = true;
      });

      const merchantId = this.store().state.entities.merchants.selectedMerchantId;
      const { data: { currency } } = await http.put(`/merchants/${merchantId}/currencies/drafts/${draftCurrency.id}/promote`);

      this.insert({ data: { ...currency, loyaltyPublicId: currency.publicId } });

      return { ...currency, loyaltyPublicId: currency.publicId };
    }

    catch (error) {
      throw error;
    }

    finally {
      this.commit((state) => {
        state.submitting = false;
      });
    }
  }

  static async deleteCurrency(publicId) {
    try {
      this.commit((state) => {
        state.submitting = true;
      });

      const merchantId = this.store().state.entities.merchants.selectedMerchantId;

      await http.delete(`merchants/${merchantId}/currencies/${publicId}`);

      this.delete(publicId);
    }
    catch (error) {
      throw error;
    }
    finally {
      this.commit((state) => {
        state.submitting = false;
      });
    }
  }

  static async deleteDraftCurrency(currencyPublicId) {
    try {
      this.commit((state) => {
        state.submitting = true;
      });

      const merchantId = this.store().state.entities.merchants.selectedMerchantId;
      await http.delete(`merchants/${merchantId}/currencies/drafts/${currencyPublicId}`);

      this.delete(currencyPublicId);
    }
    catch (error) {
      throw error;
    }
    finally {
      this.commit((state) => {
        state.submitting = false;
      });
    }
  }
}
