<template>
  <validated-form
    :ref="formRef"
    v-slot="{}"
    :form-id="formRef"
    :disabled="!$can('update', 'MenuItem') || !$_menuPermissions.EDIT_RESOURCE"
  >
    <p class="subtitle is-5 is-marginless">
      Menu Types
    </p>
    <p class="is-size-7 has-text-grey">
      Select the menu types this item belongs in
    </p>

    <div class="is-grid col-2 gap mar-t">
      <check-button
        v-for="{ id, displayName } in menuTypes"
        :key="id"
        class="mar-none"
        :label="displayName"
        :value="selectedMenuTypeIds.includes(id)"
        @input="handleCheckboxInput($event, id)"
      />
    </div>
  </validated-form>
</template>

<script>
  import merchantMixin from '@/mixins/merchant';
  import multiFormChildProvider from '@/mixins/multiFormMixin/multiFormChildProvider';

  import Item from '@/store/classes/Item';
  import MerchantMenuTypeConfiguration from '@/store/classes/MerchantMenuTypeConfiguration';

  export default {
    name: 'ItemMenuTypeForm',

    mixins: [multiFormChildProvider, merchantMixin],

    props: {
      item: {
        type: Object,
        required: true
      },

      formRef: {
        type: String,
        required: true
      }
    },

    data() {
      return {
        selectedMenuTypeIds: []
      };
    },

    computed: {
      menuTypes() {
        return this.$_selectedMerchant.supportedMenuTypes;
      }
    },

    created() {
      this.onCreated();
    },

    methods: {
      async onCreated() {
        await this.fetchMenuTypes();
        this.selectedMenuTypeIds = this.item.menuTypes.map(mt => mt.id);
      },

      async fetchMenuTypes() {
        try {
          await MerchantMenuTypeConfiguration.fetchMerchantMenuTypeConfigurations();
        }
        catch (error) {
          this.$_onRequestError({
            toastOptions: {
              message: 'There was an error fetching your menu types'
            },
            error
          });
        }
      },

      handleCheckboxInput(isAdding, menuTypeId) {
        const menuTypeIds = new Set(this.selectedMenuTypeIds);

        if (isAdding) {
          menuTypeIds.add(menuTypeId);
        }
        else {
          menuTypeIds.delete(menuTypeId);
        }

        this.selectedMenuTypeIds = [...menuTypeIds];
      },

      async handleSubmit(itemId = this.item.id) {
        try {
          await Item.updateItem({
            id: itemId,
            menuTypeIds: this.selectedMenuTypeIds
          });

          return itemId;
        }
        catch (error) {
          this.$_onRequestError({
            toastOptions: {
              message: 'There was an error updating your menu types'
            },
            error
          });
          throw error;
        }
      }
    }
  };
</script>
