export default {
  UNKNOWN: 'Unknown',
  GOOGLE_PAY: 'GooglePay',
  PAY_AT_TABLE_RECEIPT: 'PayAtTableReceipt',
  PAY_AT_TABLE_CHECKOUT: 'PayAtTableCheckout',
  ORDER_AHEAD_RECEIPT: 'OrderAheadReceipt',
  GIFT_REDEMPTION: 'GiftRedemption',
  ACCOUNT_VERIFICATION: 'AccountVerification',
  MOBILE_TIPPING: 'MobileTipping',
  ACCOUNT_COMPLETION: 'AccountCompletion',
  LOYALTY_CAMPAIGN: 'LoyaltyCampaign',
  MANUAL: 'Manual'
};
