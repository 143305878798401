<template>
  <div class="card-group">
    <store-pickup-time-offsets :store="store" />
    <store-lead-times v-if="showLeadPickupTime" :store="store" />
    <store-order-limits :store="store" />
  </div>
</template>

<script>
  import storeOrderLimits from './store-order-limits.vue';
  import storePickupTimeOffsets from './store-pickup-time-offsets.vue';
  import storeLeadTimes from './store-lead-times.vue';


  export default {
    name: 'StoreOrderTiming',

    components: {
      storePickupTimeOffsets,
      storeLeadTimes,
      storeOrderLimits
    },

    props: {
      store: {
        type: Object,
        required: true
      }
    },

    computed: {
      showLeadPickupTime() {
        return !!this.store.posType?.metaData['StoreMapping.supportsLeadPickupTime']
          || this.store.roomServiceEnabled
          || this.store.cateringEnabled;
      }
    }
  };
</script>
