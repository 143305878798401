<template>
  <modal-card
    :title="!isFetchingCategory && modalTitle"
    modal-card-body-class="pad-none"
    @keypress.native.enter="triggerSubmits"
  >
    <b-tabs
      v-if="category"
      v-model="activeTabIndex"
      :animated="false"
      expanded
      :class="[tabs.length === 1 && 'hide-tabs', 'is-floating']"
    >
      <b-tab-item v-for="(tab, index) in tabs" :key="tab.label">
        <template #header>
          <span>{{ tab.label }}</span>
          <b-icon v-if="invalidTabIndexes.includes(index)" type="is-danger" icon="exclamation-circle" />
        </template>
        <component :is="tab.component" :ref="tab.ref" v-bind="tab.props" />
      </b-tab-item>
    </b-tabs>

    <template v-if="$can('update', 'MenuCategory') && $_menuPermissions.EDIT_RESOURCE" #footer>
      <div class="buttons all-bold">
        <b-button
          rounded
          @click="$_confirmCloseModal({ programmatic: true })"
        >
          Cancel
        </b-button>
        <b-button
          v-tabbable
          rounded
          type="is-primary"
          :loading="isSubmitting"
          @click="triggerSubmits"
        >
          Save
        </b-button>
      </div>
    </template>
  </modal-card>
</template>

<script>
  import { mapActions } from 'vuex';
  import Category from '@/store/classes/Category';
  import MenuCategoryUpsell from '@/store/classes/MenuCategoryUpsell';
  import confirmModalCloseMixin from '@/mixins/confirm-modal-close';
  import merchantMixin from '@/mixins/merchant';
  import categoryForm from './category-form.vue';
  import categoryUpsell from './category-upsell.vue';
  import categoryScheduleForm from './category-schedule-form.vue';

  import logger from '@/services/logger';



  export default {
    name: 'AddEditCategoryModal',

    components: { categoryForm, categoryScheduleForm },

    mixins: [confirmModalCloseMixin, merchantMixin],

    props: {
      categoryId: {
        type: Number,
        default: null
      },

      menuTypeId: {
        type: [String, Number],
        default: null
      },

      defaultTabIndex: {
        type: Number,
        default: null
      },

      mode: {
        type: String,
        default: 'create',
        validator(value) {
          return ['create', 'read', 'update'].includes(value);
        }
      }
    },

    data() {
      return {
        activeTabIndex: 0,
        invalidTabIndexes: [],
        isSubmitting: false
      };
    },

    computed: {
      category() {
        return Category.find(this.categoryId) || new Category();
      },

      isFetchingCategory() {
        return Category.$state().fetchingSingle || MenuCategoryUpsell.$state().fetching;
      },

      modalTitle() {
        let title = '';
        switch (this.mode) {
          case 'create':
            title = 'Create Category';
            break;

          case 'update':
            title = this.category.displayName;
            break;

          default:
            break;
        }

        return title;
      },

      tabs() {
        return [
          {
            label: 'Details',
            ref: 'menuCategoryForm',
            component: categoryForm,
            props: {
              categoryId: this.categoryId,
              menuTypeId: this.menuTypeId,
              mode: this.mode
            }
          },
          this.$can('read', 'MenuCategorySchedule') && {
            label: 'Availability',
            ref: 'scheduleForm',
            component: categoryScheduleForm,
            props: {
              category: this.category
            }
          },
          {
            label: 'Upsell',
            ref: 'menuCategoryUpsellForm',
            component: categoryUpsell,
            props: {
              categoryId: this.categoryId,
              menuTypeId: this.menuTypeId,
              mode: this.mode
            }
          }
        ].filter(x => x);
      }
    },

    created() {
      this.onCreated();
    },

    methods: {
      ...mapActions('formStore', ['setFormChanged']),

      onCreated() {
        if (this.defaultTabIndex) {
          this.activeTabIndex = this.defaultTabIndex;
        }
      },

      toggleSubmitting(val) {
        this.isSubmitting = val;
      },

      async triggerSubmits() {
        try {
          const validations = await Promise.allSettled(this.tabs.map(tab => this.$refs[tab.ref][0].$refs.form.validate()));

          const isDaypartsValid = this.$refs.scheduleForm[0].$_validateDayparts();

          const invalidTabIndexes = validations
            .map((v, i) => (v.status === 'rejected' ? i : false))
            .filter(x => x !== false);

          if (!isDaypartsValid) {
            const scheduleFormTabIndex = this.tabs.findIndex(t => t.ref === 'scheduleForm');
            invalidTabIndexes.push(scheduleFormTabIndex);
          }

          if (invalidTabIndexes.length) {
            this.invalidTabIndexes = invalidTabIndexes;
            const activeTabInvalid = invalidTabIndexes.includes(this.activeTabIndex);
            if (!activeTabInvalid) {
              this.activeTabIndex = invalidTabIndexes[0];
            }

            throw Error('One or more forms failed validation');
          }

          try {
            this.toggleSubmitting(true);
            const newCategory = await this.$refs.menuCategoryForm[0].handleSubmit();
            const categoryId = newCategory ? newCategory.id : this.categoryId;

            if (this.$_menuPermissions.MANAGE_DAYPART_SCHEDULE) {
              await this.$refs.scheduleForm[0].handleSubmit(categoryId);
            }
            await this.$refs.menuCategoryUpsellForm[0].handleSubmit(categoryId);


            this.$_onRequestSuccess({
              toastOptions: {
                message: this.mode === 'create'
                  ? `Successfully added the <b>${newCategory.displayName}</b> category!`
                  : `<b>${this.category.displayName}</b> successfully updated!`
              },
              options: {
                closeParent: true,
                emit: this.mode === 'create'
                  ? { name: 'main-menu:add-category', arg: { type: 'category', id: newCategory.id } }
                  : { name: 'category-updated' }
              }
            });

            // This modal is a tabbed modal, but it doesn't use our tabbed modal utilities as it probably
            // should. This has led to an edge case bug when searching in main menu V2 and formStore.formChanged
            // isn't being properly reset on save, which causes UX flow issues. This is just a stop gap
            // solution for now until we can properly address this in the future, as the main menu V2
            // refactor is the priority at the moment I am writing this comment.
            this.setFormChanged({ hasChanged: false });
          }
          finally {
            this.toggleSubmitting(false);
          }
        }
        catch (error) {
          logger.error(error);
        }
      }
    }
  };
</script>

<style scoped lang="sass">
  .modal-card
    max-width: 500px

</style>
