<template>
  <div class="is-flex-column gap-lg">
    <div class="card">
      <div class="card-content is-grid col-3">
        <div class="mar-r-xl pad-r-xl has-border-right has-border-grey-lightest col-span-2 dist-y-lg">
          <validated-text-input
            :value="value.name"
            name="name"
            type="text"
            rules="required|max:63"
            label="Name"
            placeholder="Enter an internal name..."
            :disabled="offerReadOnly"
            @input="handleInput('name', $event)"
          />
          <validated-text-input
            :value="value.description"
            name="description"
            type="text"
            rules="max:256"
            label="Description"
            placeholder="Enter a description..."
            :disabled="offerReadOnly"
            @input="handleInput('description', $event)"
          />
          <validated-text-input
            :value="value.termsAndConditions"
            name="termsAndConditions"
            type="textarea"
            maxlength="1280"
            label="Terms & Conditions"
            placeholder="Enter terms and conditions..."
            :disabled="offerReadOnly"
            @input="handleInput('termsAndConditions', $event)"
          />
          <div v-if="value.state !== 'draft'" class="mar-t-sm has-text-grey is-monospaced">
            ID: {{ value.guid }}
          </div>
        </div>

        <div>
          <image-upload
            v-slot="{imagePath}"
            v-model="emailCouponImage"
            :accepted-types="['png', 'jpeg', 'jpg', 'gif']"
            :image-size-warning-height="228"
            :image-size-warning-width="750"
            :image-url="emailCoupon && emailCoupon.defaultImagePath"
            label="Offer Image"
            show-clear-button
            clear-text="Clear Image"
            :disabled="offerReadOnly"
            restrict-file-size
          >
            <img
              v-if="imagePath"
              style="max-width: 500px"
              :src="getImagePreviewPath({ imagePath, type: 'email' })"
              alt="offer email Image"
            >
          </image-upload>

          <collapsible-section :start-open="!!loyaltyCouponImage" title="Additional Images" class="mar-t">
            <b-message type="is-primary" class="is-compact has-shadow is-inline-block" has-icon icon="info-circle">
              <p>Add additional images to optimize their display. If no image is uploaded, the main offer image will be used.</p>
            </b-message>
            <image-upload
              v-slot="{imagePath}"
              v-model="loyaltyCouponImage"
              :accepted-types="['png', 'jpeg', 'jpg', 'gif', 'webp', 'svg']"
              :image-size-warning-height="92"
              :image-size-warning-width="82"
              :image-url="loyaltyRewardTierCoupon && loyaltyRewardTierCoupon.defaultImagePath"
              label="Loyalty Reward Tier Image"
              show-clear-button
              clear-text="Clear Image"
              :disabled="offerReadOnly"
              restrict-file-size
            >
              <img
                v-if="imagePath"
                style="max-width: 500px"
                :src="getImagePreviewPath({ imagePath, type: 'loyaltyRewardTier' })"
                alt="offer loyalty Image"
              >
            </image-upload>
          </collapsible-section>
        </div>

      </div>
    </div>
    <div class="card is-inline-block align-self-center">
      <div class="card-content" style="width: 500px">
        <p class="is-4 subtitle mar-b-xs">Offer Preview</p>
        <offer-preview :offer="value" />
      </div>
    </div>
  </div>
</template>

<script>
  import merchantMixin from '@/mixins/merchant';
  import offerPreview from '@/components/pages/campaigns/components/offer-preview.vue';

  export default {
    name: 'OfferDetailsStep',

    components: { offerPreview },

    mixins: [merchantMixin],

    props: {
      value: {
        type: Object,
        required: true
      },

      offerReadOnly: {
        type: Boolean,
        required: true
      }
    },

    computed: {
      emailCoupon() {
        return this.value.coupons.find(c => c.displayChannelName === 'email');
      },

      loyaltyRewardTierCoupon() {
        return this.value.coupons.find(c => c.displayChannelName === 'loyaltyRewardTier');
      },

      emailCouponImage: {
        get() {
          return this.emailCoupon?.defaultImagePath || this.value.tempEmailImageFile;
        },

        set(value) {
          const filteredCoupons = this.$clone(this.value.coupons).filter(c => c.displayChannelName !== 'email');
          this.$emit('input', { ...this.value, coupons: filteredCoupons, tempEmailImageFile: value });
        }
      },

      loyaltyCouponImage: {
        get() {
          return this.loyaltyRewardTierCoupon?.defaultImagePath || this.value.tempLoyaltyImageFile;
        },

        set(value) {
          const filteredCoupons = this.$clone(this.value.coupons).filter(c => c.displayChannelName !== 'loyaltyRewardTier');
          this.$emit('input', { ...this.value, coupons: filteredCoupons, tempLoyaltyImageFile: value });
        }
      },

      isLocalEnvironment() {
        return window.location.hostname === 'localhost';
      }
    },

    methods: {
      handleInput(key, value) {
        this.$emit('input', { ...this.value, [key]: value });
      },

      getImagePreviewPath({ imagePath = null, type }) {
        const coupon = this[`${type}Coupon`];
        if (this.isLocalEnvironment) {
          return this.value.guid && coupon
            ? `http://localhost:3000/${coupon.defaultImagePath}`
            : imagePath;
        }

        return coupon?.fullUrl || imagePath;
      }
    }
  };
</script>

