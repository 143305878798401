/* eslint-disable import/no-cycle */
import { Model } from '@vuex-orm/core';
import http from '@/services/http';


export default class MenuItemModifierImage extends Model {
  static entity = 'menuItemModifierImages'

  static fields() {
    return {
      id: this.attr(''),
      menuItemModifierId: this.attr(''),
      fullUrl: this.attr(''),
      startDate: this.attr(''),
      endDate: this.attr(''),
      createdAt: this.attr(''),
      updatedAt: this.attr(''),

      // FE Property
      imageFile: this.attr(null)
    };
  }


  // STATE //////////////////////
  static state() {
    return {
      fetching: false,
      submitting: false
    };
  }

  static $state() {
    return this.store().state.entities.menuItemModifierImages;
  }



  // ACTIONS //////////////////////
  static async fetchModifierImage(modifierId) {
    try {
      this.commit((state) => {
        state.fetching = true;
      });

      const response = await http.get(`menu_item_modifiers/${modifierId}/menu_item_modifier_images`);
      this.create({ data: response.data.menuItemModifierImages });
    }

    catch (error) {
      throw error;
    }

    finally {
      this.commit((state) => {
        state.fetching = false;
      });
    }
  }

  static async addModifierImage({ imageFile, modifierId }) {
    try {
      this.commit((state) => {
        state.submitting = true;
      });

      const formData = new FormData();
      formData.append('menuItemModifierImage[file]', imageFile);

      const response = await http.post(
        `menu_item_modifiers/${modifierId}/menu_item_modifier_images`,
        formData,
        { headers: { 'Content-Type': 'multipart/form-data' } }
      );
      this.insert({ data: response.data.menuItemModifierImage });
    }

    catch (error) {
      throw error;
    }

    finally {
      this.commit((state) => {
        state.submitting = false;
      });
    }
  }

  static async deleteImage(modifierId) {
    try {
      this.commit((state) => {
        state.submitting = true;
      });

      await http.delete(`menu_item_modifier_images/${modifierId}`);

      this.delete(modifierId);
    }

    catch (error) {
      throw error;
    }

    finally {
      this.commit((state) => {
        state.submitting = false;
      });
    }
  }
}
