<template>
  <div class="is-flex-wrap gap-xl mar-t-md">
    <validated-text-input
      v-model="earnRuleConstraint.minimum"
      :disabled="readOnly"
      label="Minimum Purchase Amount"
      name="minimumPurchase"
      type="dollars"
      :rules="{
        required: true,
        min_value: 0.01
      }"
      class="mar-b-none"
      placeholder="Set a minimum purchase amount..."
      :mask-options="{ numeralDecimalScale: 2, numeralPositiveOnly: true }"
      @input="handleInput({ minimum: Number($event) })"
    />
    <excluded-item-attributes-dropdown
      :value="earnRuleConstraint.excludedItemAttributes"
      :read-only="readOnly"
      form-name="minimumPurhcaseConstraint"
      @input="handleInput({ excludedItemAttributes: $event })"
    />
  </div>
</template>

<script>
  export default {
    name: 'MinimumPurchaseCard',

    props: {
      earnRuleConstraint: {
        type: Object,
        required: true
      },

      readOnly: {
        type: Boolean,
        default: false
      }
    },

    methods: {
      handleInput(payload) {
        this.$emit('update-constraint', { ...this.earnRuleConstraint, ...payload });
      }
    }
  };
</script>
