<template>
  <div class="card" style="scroll-margin: 1.5rem">
    <b-table
      class="is-middle-aligned no-header-wrap row-clickable"
      :data="isLoading ? [] : auditLogs"
      scrollable
      backend-sorting
      :default-sort="['sort_direction', 'desc']"
      hoverable
      paginated
      per-page="50"
      pagination-position="both"
      :current-page.sync="page"
      @sort="handleSort(arguments[1])"
      @click="openModal"
    >
      <template #top-left>
        <b-select placeholder="Select a Resource" @input="handleModelSelect">
          <option :value="null">All</option>
          <option disabled>–</option>
          <option v-for="model in models" :key="model" :value="model">
            {{ capitalCase(model) }}
          </option>
        </b-select>
      </template>
      <b-table-column v-slot="{ row }" label="Resource">
        <span class="no-wrap-text">
          {{ row.resource }}
        </span>
      </b-table-column>

      <b-table-column v-slot="{ row }" label="Resource ID">
        <span class="no-wrap-text is-monospaced">
          {{ row.resourceId }}
        </span>
      </b-table-column>

      <b-table-column v-slot="{ row }" label="Event" centered>
        <b-tag :type="badgeTypes[row.event]" class="is-full-width">
          {{ row.event | capitalize }}
        </b-tag>
      </b-table-column>

      <b-table-column v-slot="{ row }" label="User Info">
        <p v-if="!row.user && !row.email" class="has-text-grey-light">N/A</p>
        <template v-else>
          <p :class="['no-wrap-text', { 'has-text-grey-light': !row.user }]">
            {{ row.user || 'N/A' }}
          </p>
          <p :class="['subtitle is-7 no-wrap-text', { 'has-text-grey-light': !row.email }]">
            {{ row.email || 'N/A' }}
          </p>
        </template>
      </b-table-column>

      <b-table-column v-slot="{ row }" label="Date" field="sort_direction" sortable>
        <span class="no-wrap-text">
          {{ row.createdAt | moment('MM/DD/YY @ h:mma') }}
        </span>
      </b-table-column>

      <b-table-column v-slot="{ row }" label="Changes">
        <div class="is-flex gap-sm flex-wrap">
          <b-tag
            v-for="key in Object.keys(JSON.parse(row.changes)).slice(0, 4)"
            :key="key"
            class="is-monospaced"
          >
            {{ key }}
          </b-tag>
          <span v-if="Object.keys(JSON.parse(row.changes)).slice(4).length" class="has-text-grey no-wrap-text">
            <tippy>
              <template #trigger>
                + {{ Object.keys(JSON.parse(row.changes)).slice(4).length }} more
              </template>

              <ul class="has-list-styles has-text-left is-size-6 is-monospaced">
                <li v-for="key in Object.keys(JSON.parse(row.changes)).slice(4)" :key="key">
                  {{ key }}
                </li>
              </ul>
            </tippy>
          </span>
        </div>
      </b-table-column>

      <template slot="empty">
        <empty-table-loader :has-icon="false" message="No Logs Found..." :loading="isLoading" />
      </template>
    </b-table>
  </div>
</template>

<script>
  import auditLogModal from './audit-log-modal.vue';
  import merchantMixin from '@/mixins/merchant';
  import scrollIndicatorMixin from '@/mixins/scroll-container-indicator';
  import { fetchAuditLogs } from '@/api/merchant-configuration';
  import { capitalCase } from 'change-case';



  export default {
    name: 'AuditLogTab',

    mixins: [merchantMixin, scrollIndicatorMixin],

    props: {
      merchant: {
        type: Object,
        required: true
      }
    },

    data() {
      return {
        capitalCase,
        auditLogs: [],
        page: 1,
        isLoading: false,
        sortDirection: 'desc',
        selectedModel: null,
        badgeTypes: {
          update: 'is-primary is-light',
          create: 'is-success is-light',
          destroy: 'is-danger is-light'
        },
        params: {
          resource: null,
          sort_direction: null
        },
        models: [ // hardcoded list of models that support paper trail
          'Campaign',
          'CampaignsSegmentation',
          'Feature',
          'MenuCategory',
          'MenuItem',
          'MenuItemImage',
          'MenuItemMenuType',
          'MenuItemModifier',
          'MenuItemModifierGroup',
          'MenuItemModifierImage',
          'MenuItemsMenuCategory',
          'Merchant',
          'MerchantAppSettingResource',
          'MerchantDeliveryService',
          'MerchantPaymentGateway',
          'MerchantPosConfiguration',
          'MerchantsUser',
          'MerchantTippingOption',
          'PosMenuItemMapping',
          'PosMenuItemModifierMapping',
          'RegionMenu',
          'StoreMapping',
          'StoreMappingAttribute',
          'StoreMenuItem',
          'StoreOrderLimit',
          'User',
          'UsersStore'
        ]
      };
    },

    watch: {
      page: 'handlePageChange'
    },

    created() {
      this.onCreated();
    },

    methods: {
      async onCreated() {
        await this.fetchAuditLogData(this.params);

        this.$nextTick(() => {
          this.$_initScrollIndicatorSmart({
            contentSelector: '.table'
          });
        });
      },

      openModal(auditLog) {
        this.$buefy.modal.open({
          parent: this,
          component: auditLogModal,
          hasModalCard: true,
          trapFocus: true,
          customClass: 'auto-width',
          props: { auditLog }
        });
      },

      handlePageChange() {
        this.$nextTick(() => {
          this.$el.scrollIntoView({ behavior: 'smooth' });
          this.updateScrollIndicator();
        });
      },

      async handleSort(direction) {
        this.params.sort_direction = direction;
        await this.fetchAuditLogData(this.params);
        this.updateScrollIndicator();
      },

      async handleModelSelect(model) {
        this.params.resource = model;
        this.page = 1;
        await this.fetchAuditLogData(this.params);
        this.updateScrollIndicator();
      },

      async fetchAuditLogData(params) {
        try {
          this.isLoading = true;
          this.auditLogs = await fetchAuditLogs(this.merchant.id, params);
        }
        catch (error) {
          this.$_onRequestError({
            error,
            toastOptions: { message: 'There was an issue loading audit logs' }
          });
        }
        finally {
          this.isLoading = false;
        }
      },

      updateScrollIndicator() {
        this.$nextTick(() => {
          this.$_setOverflowAttrAndClass();
        });
      }
    }
  };
</script>
