import { Model } from '@vuex-orm/core';

import http from '@/services/http';
import filterObjectKeys from '@/helpers/filter-object-keys';


export default class MerchantGiftCardDesign extends Model {
  static entity = 'merchantGiftCardDesigns'

  static fields() {
    return {
      id: this.attr(''),
      isActive: this.attr(true),
      isDefault: this.attr(false),
      merchantId: this.attr(''),
      name: this.attr(''),
      sortOrder: this.attr(''),
      // These are the photo urls
      url: this.attr(''),
      fullUrl: this.attr('')
    };
  }

  static state() {
    return {
      fetching: false,
      sorting: false,
      submitting: false
    };
  }

  static $state() {
    return this.store().state.entities.merchantGiftCardDesigns;
  }

  static async fetchMerchantGiftCardDesigns(merchantId) {
    try {
      this.commit((state) => {
        state.fetching = true;
      });

      const id = merchantId || this.store().state.entities.merchants.selectedMerchantId;

      const { data } = await http.get(`merchants/${id}/merchant_gift_card_designs`);

      this.create({
        data: data.merchantGiftCardDesigns
      });
    }

    catch (error) {
      throw error;
    }

    finally {
      this.commit((state) => {
        state.fetching = false;
      });
    }
  }

  static async createMerchantGiftCardDesign(merchantGiftCardDesign) {
    try {
      this.commit((state) => {
        state.submitting = true;
      });

      const { data } = await http.post(
        `merchants/${merchantGiftCardDesign.merchantId}/merchant_gift_card_designs`,
        { merchantGiftCardDesign }
      );

      this.insert({
        data: data.merchantGiftCardDesign
      });
    }

    catch (error) {
      throw error;
    }

    finally {
      this.commit((state) => {
        state.submitting = false;
      });
    }
  }

  static async updateMerchantGiftCardDesign(merchantGiftCardDesign) {
    try {
      this.commit((state) => {
        state.submitting = true;
      });

      const { data } = await http.put(
        `merchant_gift_card_designs/${merchantGiftCardDesign.id}`,
        { merchantGiftCardDesign }
      );

      this.update({
        data: data.merchantGiftCardDesign
      });
    }

    catch (error) {
      throw error;
    }

    finally {
      this.commit((state) => {
        state.submitting = false;
      });
    }
  }

  static async uploadImage(imageFile, merchantId) {
    try {
      this.commit((state) => {
        state.submitting = true;
      });

      const id = merchantId || this.store().state.entities.merchants.selectedMerchantId;

      const formData = new FormData();
      formData.append('merchantGiftCardDesignImage[file]', imageFile);

      const response = await http.post(
        `merchants/${id}/merchant_gift_card_design_images`,
        formData,
        { headers: { 'Content-Type': 'multipart/form-data' } }
      );

      return response.data.merchantGiftCardDesignImageUrl;
    }

    catch (error) {
      throw error;
    }

    finally {
      this.commit((state) => {
        state.submitting = false;
      });
    }
  }

  static async updateSortOrder({ merchantId, merchantGiftCardDesigns }) {
    try {
      this.commit((state) => {
        state.sorting = true;
      });

      const id = merchantId || this.store().state.entities.merchants.selectedMerchantId;

      const { data } = await http.put(`merchants/${id}/merchant_gift_card_designs/sort_order`, {
        merchantGiftCardDesigns: merchantGiftCardDesigns.map(obj => filterObjectKeys(obj, ['id', 'sortOrder']))
      });

      this.update({ data: data.merchantGiftCardDesigns });
    }

    catch (error) {
      throw error;
    }

    finally {
      this.commit((state) => {
        state.sorting = false;
      });
    }
  }
}
