<template>
  <div class="card" style="scroll-margin: 1.5rem">
    <b-loading v-if="loading && isVisible" :is-full-page="false" active />

    <div class="card-header-sticky" :style="{ top: stickyHeaderOffset}">
      <div class="is-flex-column align-start">
        <div class="is-flex gap-xs align-center panel-title">
          <p
            :id="paramCase(title)"
            :class="[{ 'link-inverted': collapsible }, 'is-inline-block no-wrap-text']"
            @click="collapsible && toggleVisibility()"
          >
            <span class="has-text-weight-bold is-size-4" style="line-height: 1">
              {{ title }}
            </span>
            <b-icon
              v-if="collapsible"
              style="font-size: 20px"
              icon="angle-right"
              :class="['open-indicator', { 'is-open': isOpen }]"
            />
          </p>
          <template v-if="collapsible && !hideHoverControls">
            <b-button
              v-tippy="{ content: 'Expand All' }"
              size="is-small"
              type="is-primary"
              inverted
              @click="toggleAll(true)"
            >
              <b-icon style="font-size: 1.15rem" icon="expand-alt" />
            </b-button>
            <b-button
              v-tippy="{ content: 'Collapse All' }"
              size="is-small"
              type="is-primary"
              inverted
              @click="toggleAll(false)"
            >
              <b-icon style="font-size: 1.15rem" icon="compress-alt" />
            </b-button>
          </template>
        </div>
        <transition name="fade">
          <p v-if="subtitle && isVisible" class="subtitle is-6 has-text-grey">
            {{ subtitle }}
          </p>
        </transition>
      </div>

      <transition name="fade-left">
        <div v-if="isVisible" class="is-flex gap-sm">
          <slot name="buttons" />
        </div>
      </transition>
    </div>

    <template v-if="!hideContentWhileLoading || !loading">
      <transition name="fade-down">
        <div
          v-show="isVisible"
          :class="['card-content is-relative', { 'is-paddingless': noPadding }]"
        >
          <slot />
        </div>
      </transition>
    </template>
    <div v-else class="pad-lg" />
  </div>
</template>

<script>
  import { paramCase, pascalCase } from 'change-case';
  import events from '@/services/events';
  import storage from '@/services/storage';


  export default {
    name: 'Panel',

    props: {
      collapsible: {
        type: Boolean,
        default: false
      },
      hideContentWhileLoading: {
        type: Boolean,
        default: false
      },
      hideHoverControls: {
        type: Boolean,
        default: false
      },
      loading: {
        type: Boolean,
        default: false
      },
      noPadding: {
        type: Boolean,
        default: false
      },
      startOpen: {
        type: Boolean,
        default: false
      },
      stickyHeaderOffset: {
        type: String,
        default: '0'
      },
      subtitle: {
        type: String,
        default: null
      },
      title: {
        type: String,
        required: true
      }
    },

    data() {
      return {
        paramCase,
        isOpen: false
      };
    },

    computed: {
      isVisible() {
        return !this.collapsible || this.isOpen;
      },

      panelIdentifier() {
        return this.$route.name + pascalCase(this.title);
      }
    },

    created() {
      this.onCreated();
    },

    destroyed() {
      this.onDestroyed();
    },

    methods: {
      onCreated() {
        events.$on('panel:toggle-all', this.toggleVisibility);

        const panelOpenStates = storage.local.get('panel-open-states') || {};
        if (panelOpenStates[this.panelIdentifier] !== undefined) {
          this.isOpen = panelOpenStates[this.panelIdentifier];
        }
        else if (this.startOpen) {
          this.isOpen = true;
        }
      },

      onDestroyed() {
        events.$off('panel:toggle-all');
      },

      toggleVisibility(value = !this.isOpen) {
        this.isOpen = value;
        this.setLocalStorage(value);
      },

      toggleAll(value) {
        events.$emit('panel:toggle-all', value);
        if (value) this.$nextTick(this.scrollPanelIntoView);
      },

      scrollPanelIntoView() {
        this.$el.querySelector(`#${paramCase(this.title)}`)
          .closest('.card')
          .scrollIntoView({ behavior: 'smooth' });
      },

      setLocalStorage(value) {
        storage.local.set('panel-open-states', {
          ...storage.local.get('panel-open-states') || {},
          [this.panelIdentifier]: value
        });
      }
    }
  };
</script>

<style lang="sass" scoped>
  .open-indicator
    transition: transform 100ms

  .is-open
    transform: rotate(90deg)

  .panel-title
    .button
      opacity: 0
      transition: 150ms
      pointer-events: none
    &:hover .button
      opacity: 1
      pointer-events: auto

  @media (hover: none) // Touchscreen devices only
    .panel-title
      user-select: none
      .button
        opacity: 1
        pointer-events: auto

  @media (max-width: $desktop)
    .panel-title
      span
        font-size: 1.25rem !important
</style>
