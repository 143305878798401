<template>
  <validated-form
    ref="form"
    auto-focus
    form-id="merchantFeeForm"
    :disabled="!$can('update', 'Merchant')"
    @valid-submit="handleSubmit"
  >
    <modal-card :title="`${merchantFeeId ? 'Update' : 'Add'} Merchant Fee`">
      <b-switch
        v-model="form.isActive"
        left-label
        class="has-text-weight-bold"
        :disabled="!$can('update', 'Merchant')"
      >
        Active
      </b-switch>

      <hr>

      <validated-text-input
        v-model="form.name"
        label="Name"
        name="name"
        type="text"
        rules="required|max:255"
        expanded
      />

      <validated-text-input
        v-model="form.description"
        label="Description"
        name="description"
        type="textarea"
        rules="max:1000"
        expanded
      />

      <hr>

      <regional-location-dropdown
        v-model="form.storeIds"
        :disabled="!$can('update', 'Merchant')"
        expanded
        :stores="stores"
        sub-label="Select which location(s) this fee applies to"
        select-all-mode="excludeAll"
      />

      <validated-input
        label="Order Modes"
        sub-label="Select which order mode(s) this fee applies to"
        name="fulfillmentTypes"
      >
        <b-dropdown
          v-model="form.fulfillmentTypeIds"
          position="is-top-right"
          multiple
          expanded
          scrollable
          class="has-extra-shadow"
          aria-role="list"
          :disabled="!$can('update', 'Merchant')"
          :inline="!$can('update', 'Merchant')"
          :is-read-only="!$can('update', 'Merchant')"
        >
          <dropdown-button
            slot="trigger"
            placeholder="Select Order Modes(s)"
            :value="form.fulfillmentTypeIds.length === 0 ? 'All Order Modes' : `Selected (${form.fulfillmentTypeIds.length})`"
          />
          <b-dropdown-item
            aria-role="listitem"
            :class="{'is-active': form.fulfillmentTypeIds.length === 0}"
            @click="form.fulfillmentTypeIds = []"
          >
            All Order Modes
          </b-dropdown-item>

          <hr class="dropdown-divider">

          <b-dropdown-item
            v-for="fulfillmentType in fulfillmentTypes"
            :key="fulfillmentType.id"
            :value="fulfillmentType.id"
            aria-role="listitem"
          >
            <p>{{ formattedFulfillmentType(fulfillmentType.name) }}</p>
          </b-dropdown-item>
        </b-dropdown>
      </validated-input>

      <hr>

      <validation-observer v-slot="{ errors }">
        <b-field
          :class="!$can('update', 'Merchant') ? 'is-read-only-input' : ''"
          label="Fee Amount"
          sub-label="Set the type and amount for this fee"
        >
          <validated-text-input
            v-model="feeAmount"
            placeholder="Set a fee amount..."
            label="Fee Amount"
            hide-label
            name="feeAmount"
            expanded
            :class="['control', { 'percent': isPercentFeeType }]"
            :rules="isPercentFeeType ? { between: [0.01, 100], required: true } : { min_value: 0.01, required: true }"
            :type="isPercentFeeType ? 'float' : 'dollars'"
            :mask-options="{ numeralDecimalScale: 2, numeralPositiveOnly: true }"
            :errors="errors.feeAmount"
          />
          <b-select v-model="form.feeTypeId">
            <option v-for="feeType in feeTypes" :key="`${feeType.id}-feeType`" :value="feeType.id">{{ feeType.id === feeTypesById.PERCENTAGE_DISCOUNT ? '% Fee' : '$ Fee' }}</option>
          </b-select>
        </b-field>
      </validation-observer>

      <template #footer>
        <div class="buttons all-bold">
          <b-button
            rounded
            @click="$_confirmCloseModal({ programmatic: true })"
          >
            Cancel
          </b-button>
          <b-button
            v-tabbable
            rounded
            native-type="submit"
            type="is-primary"
            :loading="isSubmitting"
          >
            Save
          </b-button>
        </div>
      </template>
    </modal-card>
  </validated-form>
</template>

<script>
  import confirmModalCloseMixin from '@/mixins/confirm-modal-close';
  import MerchantFee from '@/store/classes/MerchantFee';
  import Store from '@/store/classes/Store';
  import FulfillmentType from '@/store/classes/FulfillmentType';
  import FeeType from '@/store/classes/FeeType';
  import { capitalCase } from 'change-case';


  export default {
    name: 'MerchantFeeModal',

    mixins: [confirmModalCloseMixin],

    props: {
      merchantFeeId: {
        type: Number,
        default: null
      },

      merchant: {
        type: Object,
        required: true
      }
    },

    data() {
      return {
        form: {},
        feeTypesById: FeeType.typesById()
      };
    },

    computed: {
      merchantFee() {
        return MerchantFee.query().with('feeType').find(this.merchantFeeId)
          || new MerchantFee({ merchantId: this.merchant.id, feeTypeId: this.feeTypesById.FLAT_FEE });
      },

      stores() {
        return Store.orderByName().get();
      },

      fulfillmentTypes() {
        if (!this.stores) return [];
        return FulfillmentType.merchantSupported(this.merchant, this.stores);
      },

      feeTypes() {
        return FeeType.all();
      },

      isSubmitting() {
        return MerchantFee.$state().submitting;
      },

      isPercentFeeType() {
        return this.feeTypesById.PERCENTAGE_DISCOUNT === this.form.feeTypeId;
      },

      feeAmount: {
        get() {
          if (!this.form.fee) {
            return null;
          }
          return this.isPercentFeeType ? parseFloat(this.form.fee * 100).toFixed(2).replace(/[.,]00$/, '') : this.form.fee;
        },

        set(newVal) {
          if (!newVal) {
            this.form.fee = null;
          }
          else if (this.isPercentFeeType) {
            const percentAmount = Number(parseFloat(newVal / 100)).toFixed(4);
            this.form.fee = Number(percentAmount);
          }
          else {
            this.form.fee = Number(newVal);
          }
        }
      }
    },

    watch: {
      'form.feeTypeId': 'handleFeeTypeChange'
    },

    created() {
      this.onCreated();
    },

    methods: {
      capitalCase,

      formattedFulfillmentType(name) {
        return FulfillmentType.formattedName(name);
      },

      onCreated() {
        this.form = this.$clone(this.merchantFee);
        this.form.storeIds = [...new Set(this.$clone(this.merchantFee.storeMerchantFees).map(s => s.storeId).filter(Boolean))];
        this.form.fulfillmentTypeIds = [...new Set(this.$clone(this.merchantFee.storeMerchantFees).map(s => s.fulfillmentTypeId).filter(Boolean))];
      },

      handleFeeTypeChange(_, oldVal) {
        if (oldVal) {
          this.form.fee = null;
        }
      },

      async handleSubmit() {
        try {
          if (this.merchantFeeId) {
            await MerchantFee.updateMerchantFee(this.form);
          }
          else {
            await MerchantFee.createMerchantFee(this.form);
          }

          this.$_onRequestSuccess({
            toastOptions: {
              message: `Successfully ${this.merchantFeeId ? 'updated' : 'created'} your merchant fee!`
            },
            options: {
              closeParent: true
            }
          });
        }
        catch (error) {
          this.$_onRequestError({
            error,
            toastOptions: {
              message: `There was an error ${this.merchantFeeId ? 'updating' : 'creating'} your merchant fee`
            }
          });
        }
      }
    }
  };
</script>

<style lang="sass" scoped>
  .is-read-only-input
    color: $grey-lighter !important

    ::v-deep
      input,
      select
        border-color: $grey-lighter !important
</style>
