<template>
  <panel title="Challenges" no-padding>
    <b-loading v-if="isFetching" :active="isFetching" :is-full-page="false" />

    <searchable-table
      :table-data="challenges"
      :search="{
        placeholder: 'Search by Challenge Name',
        keys: ['title']
      }"
      :filters="[
        {
          placeholder: 'Status',
          key: 'portalDerivedStatus',
          multiple: true,
          default: [
            earnRuleStatuses.DRAFT,
            earnRuleStatuses.ACTIVE,
            earnRuleStatuses.SCHEDULED,
            earnRuleStatuses.EXPIRING,
            earnRuleStatuses.INVALID
          ],
          options: Object.keys(tagTypes).map(key => ({
            name: $changeCase.capitalCase(key),
            value: key
          }))
        },
      ]"
    >
      <template #optional-actions>
        <b-button
          type="is-primary"
          icon-left="plus"
          @click="openChallengeModal()"
        >
          Challenge
        </b-button>
      </template>

      <template #default="{ filteredData }">
        <b-table
          :data="isFetching ? [] : filteredData"
          class="is-middle-aligned"
          :mobile-cards="false"
          scrollable
          detail-key="publicId"
          data-test-id="challenges-table"
          :default-sort="['portalDerivedStatus', 'asc']"
        >
          <template #empty>
            <empty-table-loader
              message="No Challenges Found..."
              :loading="isFetching"
            />
          </template>

          <b-table-column
            v-slot="{ row }"
            sortable
            field="title"
            label="Name"
            cell-class=""
          >
            <span class="link" @click="openChallengeModal(row)">
              {{ row.title || 'N/A' }}
            </span>
            <p class="is-size-7">
              {{ row.description || 'N/A' }}
            </p>
          </b-table-column>

          <b-table-column
            v-slot="{ row }"
            sortable
            field="schedule"
            label="Schedule"
            cell-class=""
          >
            <span>
              {{ row.schedule }}
            </span>
          </b-table-column>

          <b-table-column
            v-slot="{ row }"
            sortable
            field="portalDerivedStatus"
            label="Status"
            centered
            cell-class="no-wrap-text"
            :custom-sort="sortStatus"
          >
            <b-tag :type="tagTypes[row.portalDerivedStatus]" class="is-outlined is-light">
              {{ $changeCase.capitalCase(row.portalDerivedStatus) }}
            </b-tag>
          </b-table-column>

          <b-table-column
            v-slot="{ row }"
            centered
            cell-class="actions"
            label="Actions"
            width="1"
            field="Actions"
          >
            <dropdown-menu position="bottom-end" :mobile-modal="false">
              <b-button slot="trigger" class="is-transparent" type="is-white">
                <b-icon icon="ellipsis-v" pack="far" size="is-small" />
              </b-button>

              <b-dropdown-item @click="openChallengeModal(row)">
                <b-icon
                  :icon="row.portalDerivedStatus === earnRuleStatuses.EXPIRED ? 'eye' : 'pencil'"
                  class="mar-r-sm"
                  size="is-small"
                />
                {{ row.portalDerivedStatus === earnRuleStatuses.DRAFT ? 'Edit' : 'View' }} Challenge
              </b-dropdown-item>
              <hr v-if="row.portalDerivedStatus === earnRuleStatuses.SCHEDULED" class="dropdown-divider">
              <b-dropdown-item v-if="row.portalDerivedStatus === earnRuleStatuses.SCHEDULED" @click="openCancelScheduledChallengeConfirmation(row)">
                <b-icon
                  icon="trash-alt"
                  class="mar-r-sm"
                  size="is-small"
                  type="is-danger"
                />
                Remove Challenge
              </b-dropdown-item>
              <b-dropdown-item v-if="row.portalDerivedStatus === earnRuleStatuses.DRAFT" @click="openDeleteDraftChallengeConfirmation(row)">
                <b-icon
                  icon="trash-alt"
                  class="mar-r-sm"
                  size="is-small"
                  type="is-danger"
                />
                Delete Challenge
              </b-dropdown-item>

              <b-dropdown-item
                v-if="row.portalDerivedStatus === earnRuleStatuses.ACTIVE"
                class="is-danger"
                @click="confirmExpireChallenge(row)"
              >
                <b-icon icon="calendar-times" class="mar-r-sm" size="is-small" />
                Expire Challenge
              </b-dropdown-item>
            </dropdown-menu>
          </b-table-column>
        </b-table>
      </template>
    </searchable-table>
  </panel>
</template>

<script>
  import moment from 'moment-timezone';
  import EarnRule from '@/store/classes/EarnRule';
  import Currency from '@/store/classes/Currency';
  import ConversionThreshold from '@/store/classes/ConversionThreshold';
  import AlertModal from '@/components/globals/alert-modal.vue';
  import { earnRuleStatuses } from '@/constants/earnRules';
  import challengeModal from '../challenges/challenge-modal.vue';


  export default {
    name: 'ChallengesTable',

    props: {
      loyaltyProgramPublicId: {
        type: String,
        default: ''
      }
    },

    data: () => ({
      earnRuleStatuses,
      tagTypes: {
        draft: 'is-warning',
        active: 'is-success',
        expiring: 'is-danger',
        expired: 'is-light',
        scheduled: 'is-info',
        invalid: 'is-light'
      }
    }),

    computed: {
      isFetching() {
        return EarnRule.$state().fetching || EarnRule.$state().fetchingDrafts
          || Currency.$state().fetching || Currency.$state().fetchingDrafts
          || ConversionThreshold.$state().fetching || ConversionThreshold.$state().fetchingDrafts;
      },

      challenges() {
        if (this.isFetching) return [];

        return this.challengeCurrencies.reduce((challenges, currency) => {
          // When creating draft earn rules/conversion thresholds, we insert currencyPublicId as the id of the currency
          // currencyId will be primary key when draft
          // currencyId will be currencyPublicId when non-draft
          const currencyId = currency.id || currency.publicId;
          const earnRule = this.challengeEarnRules.find(er => er.awardPolicy?.currencyPublicId === currencyId);
          const conversionThresholds = this.challengeConversionThresholds.filter(ct => ct.currencyPublicId === currencyId);
          // Future earn rules are not allowed for challenges
          if (earnRule && conversionThresholds.length && !earnRule.isFuture) {
            const schedule = `${moment(earnRule.startDate).format('MM/DD/YYYY')} to ${moment(earnRule.endDate).format('MM/DD/YYYY')}`;
            challenges.push({
              title: currency.title,
              description: currency.description,
              schedule,
              portalDerivedStatus: this.determineChallengeStatus(currency, earnRule, conversionThresholds),
              conversionThresholds: conversionThresholds.map(ct => ({ ...ct, isDraft: ct.isDraft })),
              currency: { ...currency, isDraft: currency.isDraft },
              earnRule: { ...earnRule, isDraft: earnRule.isDraft }
            });
          }
          return challenges;
        }, []);
      },

      challengeCurrencies() {
        return Currency.secondaryCurrencies();
      },

      currencyIds() {
        // NOTE: just take the id for draft currencies (publicId gets populated with a uid)
        return this.challengeCurrencies
          .map(cc => (cc.isDraft ? cc.id : cc.publicId))
          .filter(Boolean);
      },

      challengeEarnRules() {
        return EarnRule.earnRulesByCurrencyIds(this.currencyIds);
      },

      challengeConversionThresholds() {
        return ConversionThreshold.conversionThresholdsByCurrencyIds(this.currencyIds);
      }
    },

    methods: {
      sortStatus(a, b, isAsc) {
        const statusMap = {
          [earnRuleStatuses.DRAFT]: 0,
          [earnRuleStatuses.ACTIVE]: 1,
          [earnRuleStatuses.SCHEDULED]: 2,
          [earnRuleStatuses.EXPIRING]: 3,
          [earnRuleStatuses.EXPIRED]: 4,
          [earnRuleStatuses.INVALID]: 5
        };
        const sort = statusMap[a.portalDerivedStatus] > statusMap[b.portalDerivedStatus] ? 1 : -1;
        return isAsc ? sort : (sort * -1);
      },

      determineChallengeStatus(currency, earnRule, conversionThresholds) {
        const anyConversionThresholdsDraft = conversionThresholds.some(ct => ct.isDraft);
        const liveLoyaltyCurrency = !currency.isDraft && currency.startDate <= moment().utc().format('YYYY-MM-DD');
        if (liveLoyaltyCurrency && (earnRule.isDraft || anyConversionThresholdsDraft)) return earnRuleStatuses.INVALID;
        else if (currency.isDraft || earnRule.isDraft || anyConversionThresholdsDraft) return earnRuleStatuses.DRAFT;
        else return earnRule.portalDerivedStatus;
      },

      createBaseChallenge() {
        const startDate = moment().add(1, 'days').format('YYYY-MM-DD');
        const endDate = moment().add(2, 'days').format('YYYY-MM-DD');
        const earnRule = new EarnRule({
          startDate,
          endDate,
          programPublicId: this.loyaltyProgramPublicId,
          portalDerivedStatus: earnRuleStatuses.DRAFT
        });
        const conversionThreshold = new ConversionThreshold({ startDate, autoConvert: true });
        const currency = new Currency({
          category: 2,
          programPublicId: this.loyaltyProgramPublicId,
          challengeProcessingType: Currency.challengeProcessingTypes.AUTOMATIC_AWARD_CHALLENGE,
          startDate,
          endDate
        });
        return {
          earnRule: { ...earnRule, isDraft: true },
          conversionThresholds: [{ ...conversionThreshold, isDraft: true }],
          currency: { ...currency, isDraft: true },
          portalDerivedStatus: earnRuleStatuses.DRAFT
        };
      },

      async openChallengeModal(challenge) {
        let updatedChallenge = this.$clone(challenge);
        if (!challenge) {
          updatedChallenge = this.createBaseChallenge();
        }
        else if (challenge.earnRule.portalDerivedStatus !== earnRuleStatuses.DRAFT) {
          await EarnRule.fetchEarnRule(challenge.earnRule);
          updatedChallenge.earnRule = EarnRule.find(challenge.earnRule.publicId);
        }


        this.$buefy.modal.open({
          parent: this,
          component: challengeModal,
          hasModalCard: true,
          trapFocus: true,
          canCancel: false,
          fullScreen: true,
          props: { challenge: updatedChallenge }
        });
      },

      openCancelScheduledChallengeConfirmation(row) {
        const earnRulePublicId = row.earnRule.publicId;
        const conversionThresholdPublicIds = row.conversionThresholds.map(ct => ct.publicId);
        const currencyPublicId = row.currency.publicId;
        this.$buefy.dialog.confirm({
          title: 'Remove Scheduled Challenge',
          message: `<b>${row.title}</b> will be cancelled. Are you sure you want to remove this challenge?`,
          onConfirm: () => this.cancelScheduledChallenge({ earnRulePublicId, conversionThresholdPublicIds, currencyPublicId }),
          confirmText: 'Remove',
          type: 'is-danger',
          hasIcon: true,
          icon: 'trash-alt'
        });
      },

      openDeleteDraftChallengeConfirmation(row) {
        const earnRuleDraftId = row.earnRule.id;
        const currencyDraftId = row.currency.id;
        this.$buefy.dialog.confirm({
          title: 'Remove Draft Challenge',
          message: `<b>${row.title}</b> will be deleted. Are you sure you want to remove this challenge?`,
          onConfirm: () => this.deleteDraftChallenge(earnRuleDraftId, row.conversionThresholds, currencyDraftId),
          confirmText: 'Remove',
          type: 'is-danger',
          hasIcon: true,
          icon: 'trash-alt'
        });
      },

      async cancelScheduledChallenge({ earnRulePublicId, conversionThresholdPublicIds, currencyPublicId }) {
        try {
          await Promise.all([
            EarnRule.deleteEarnRule(earnRulePublicId),
            conversionThresholdPublicIds.map(id => ConversionThreshold.deleteConversionThreshold(id)),
            Currency.deleteCurrency(currencyPublicId)
          ]);

          this.$_onRequestSuccess({
            toastOptions: {
              message: 'Challenge successfully cancelled.'
            }
          });
        }
        catch (error) {
          this.$_onRequestError({
            toastOptions: {
              message: 'There was an error cancelling the challenge'
            },
            error
          });
        }
      },


      async deleteDraftChallenge(earnRuleDraftId, conversionThresholds, currencyDraftId) {
        try {
          await Promise.all([
            EarnRule.deleteDraftEarnRule(earnRuleDraftId),
            conversionThresholds.map(ct => ConversionThreshold.deleteDraftConversionThreshold(ct)),
            Currency.deleteDraftCurrency(currencyDraftId)
          ]);

          this.$_onRequestSuccess({
            toastOptions: {
              message: 'Challenge successfully deleted.'
            }
          });
        }
        catch (error) {
          this.$_onRequestError({
            toastOptions: {
              message: 'There was an error deleting the challenge'
            },
            error
          });
        }
      },

      confirmExpireChallenge(challenge) {
        this.$buefy.modal.open({
          parent: this,
          component: AlertModal,
          hasModalCard: true,
          trapFocus: true,
          canCancel: ['escape', 'outside'],
          props: {
            icon: 'calendar-times',
            title: 'Expire Challenge',
            message: 'This will schedule the challenge to expire at the end of the day. This action is irreversible.',
            secondaryMessage: 'Are you sure you want to expire this challenge?',
            type: 'is-danger',
            horizontal: true,
            showCloseButton: false,
            buttons: [
              { text: 'Cancel' },
              {
                text: 'Expire',
                primary: true,
                onClick: () => this.expireChallenge(challenge)
              }
            ]
          }
        });
      },

      async expireChallenge(challenge) {
        this.isSubmittingExpireChallenge = true;
        try {
          const expirePromises = this.buildExpireChallengePromises(challenge);
          await Promise.all(expirePromises);
          this.$_onRequestSuccess({
            toastOptions: {
              message: 'Successfully expired your challenge!'
            },
            options: { closeParent: true }
          });
        }
        catch (error) {
          this.$_onRequestError({
            toastOptions: {
              message: 'There was an error expiring your challenge'
            },
            error
          });
        }
        finally {
          this.isSubmittingExpireChallenge = false;
        }
      },

      buildExpireChallengePromises(challenge) {
        const today = moment().utc().format('YYYY-MM-DD');
        const updatedEarnRule = { ...challenge.earnRule, endDate: today };
        const conversionThresholdPromises = challenge.conversionThresholds.map(ct => ConversionThreshold.updateConversionThreshold({
          ...ct,
          endDate: today
        }));

        return [
          EarnRule.updateEarnRule(updatedEarnRule),
          ...conversionThresholdPromises
        ];
      }
    }
  };
</script>
