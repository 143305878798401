import { Model } from '@vuex-orm/core';

export default class LoyaltyDraft extends Model {
  static fields() {
    return {
      id: this.attr(''),
      loyaltyPublicId: this.attr(null),
      merchantId: this.attr(''),
      nonDraftClassName: this.attr(''),
      body: this.attr({})
    };
  }

  get isDraft() {
    return !this.loyaltyPublicId;
  }
}
