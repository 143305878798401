<template>
  <div tabindex="0" class="modal-card" @keydown.esc="$_confirmCloseModal({ programmatic: true })">
    <header class="modal-card-head">
      <div class="modal-card-title">
        Refund {{ payment.cardType }} •••• {{ payment.cardNumberLastFour }}
      </div>
      <button class="button is-transparent mar-l" @click="$_confirmCloseModal({ programmatic: true })">
        <b-icon icon="times" size="is-small" />
      </button>
    </header>
    <b-tabs
      v-model="activeTab"
      :animated="false"
      expanded
      class="refund-tabs is-floating"
    >
      <b-tab-item label="Refund Amount">
        <validated-form
          ref="form"
          auto-focus
          form-id="refundModal"
          @valid-submit="refundPayment"
        >
          <div class="modal-card-body pad-b-md">
            <div class="order-amounts">
              <dl>
                <div>
                  <dt>Payment Total</dt>
                  <dd>{{ payment.amount | dollars }}</dd>
                </div>
                <div>
                  <dt>Refunds Issued</dt>
                  <dd>{{ totalRefunded | dollars }}</dd>
                </div>
                <div>
                  <dt>Remaining Amount</dt>
                  <dd>{{ maximumRefundAmount | dollars }}</dd>
                </div>
              </dl>
            </div>
            <hr>

            <validated-text-input
              v-model="refundAmount"
              data-test-id="refund-amount-input"
              class="refund-amount"
              label="Refund Amount"
              name="refundAmount"
              :rules="{
                required: true,
                min_value: partialRefundAllowedForPayment
                  ? 0.01
                  : maximumRefundAmount,
                max_value: maximumRefundAmount
              }"
              type="dollars"
              :disabled="maxRefundToggle || !partialRefundAllowedForPayment"
              :tooltip="!partialRefundAllowedForPayment ? 'This payment method only supports full refunds.' : ''"
            />
            <b-checkbox
              v-if="partialRefundAllowedForPayment"
              v-model="maxRefundToggle"
              data-test-id="refund-remaining-amount-checkbox"
              size="is-small"
            >
              <p class="is-size-6">Refund Remaining Amount</p>
            </b-checkbox>
          </div>
          <div class="modal-card-foot">
            <div class="buttons all-bold">
              <b-button
                rounded
                @click="$_confirmCloseModal({ programmatic: true })"
              >
                Cancel
              </b-button>
              <b-button
                v-tabbable
                rounded
                native-type="submit"
                type="is-primary"
                :loading="isRefundingOrder === order.orderId"
              >
                Refund
              </b-button>
            </div>
          </div>
        </validated-form>
      </b-tab-item>

      <b-tab-item v-if="showRefundItemsTab" label="Refund Items">
        <validated-form
          ref="item-form"
          auto-focus
          form-id="itemForm"
          @valid-submit="refundItems"
        >
          <div class="modal-card-body pad-b-md">
            <ul class="is-flex-column gap-md">
              <li>
                <b-checkbox
                  size="is-small"
                  name="selectAllItems"
                  :value="isAllSelected"
                  :disabled="allItemsRefunded"
                  @input="handleSelectAllItems"
                >
                  <p class="is-size-6 mar-l-xs">Select All Items</p>
                </b-checkbox>
              </li>

              <li v-for="(item, i) in order.orderItems" :key="item.menuItemId + i" class="refund-item">
                <b-checkbox
                  size="is-small"
                  class="justify-self-start"
                  :name="item.orderItemId + item.itemDescription"
                  :value="item.refunded || !!selectedItem(item.orderItemId)"
                  :disabled="item.refunded || !item.pricePerUnitAfterDiscounts "
                  @input="handleSelectItem(item)"
                >
                  <span class="is-size-6">{{ item.itemDescription }}{{ item.quantity > 1 ? ` (${item.quantity})` : '' }}</span>
                </b-checkbox>
                <div class="justify-self-end has-text-right item-price">
                  <p v-if="item.pricePerUnitAfterDiscounts" class="is-size-6 has-text-weight-bold">{{ calculateItemRefundDisplay(item) | dollars }}</p>
                  <p v-else class="is-size-6 has-text-weight-bold">Free</p>
                  <p v-if="item.taxTotal > 0" class="is-size-7">+ {{ item.taxTotal | dollars }} Tax</p>
                </div>

                <transition name="fade-down">
                  <b-numberinput
                    v-if="item.quantity > 1 && selectedItem(item.orderItemId)"
                    :value="selectedItem(item.orderItemId).refundedQuantity"
                    :editable="false"
                    size="is-small"
                    controls-position="compact"
                    min="1"
                    :max="item.quantity - item.refundedQuantity"
                    @input="(value) => handleSelectQuantity(value, item)"
                  />
                </transition>

                <p v-if="item.modifiers.length" class="is-size-7 has-text-grey col-span-2">
                  {{ item.modifiers.map(modifier => modifier.itemDescription).join(', ') }}
                </p>
              </li>
            </ul>

            <p v-if="showErrorMessage" class="has-text-danger is-size-7">Please select at least 1 item to refund</p>

            <hr>

            <div class="order-amounts">
              <dl>
                <div class="is-size-6">
                  <dt>Refunds Issued</dt>
                  <dd>{{ totalRefunded | dollars }}</dd>
                </div>
                <div class="is-size-6">
                  <dt>
                    <p>Remaining Amount</p>
                    <p class="is-size-7 sub-text">(Includes additional charges)</p>
                  </dt>
                  <dd>{{ maximumRefundAmount | dollars }}</dd>
                </div>
                <div class="has-text-weight-bold is-marginless">
                  <dt class="has-text-black">Current Refund Amount</dt>
                  <dd :class="['has-text-black', { 'has-text-danger': isRefundGreaterThanRemainingAmount }]">{{ currentRefundAmount | dollars }}</dd>
                </div>
                <p v-if="isRefundGreaterThanRemainingAmount" class="has-text-danger is-size-7">Current refund amount exceeds remaining refund amount</p>
              </dl>
            </div>

          </div>
          <div class="modal-card-foot">
            <div class="buttons all-bold">
              <b-button
                rounded
                @click="$_confirmCloseModal({ programmatic: true })"
              >
                Cancel
              </b-button>
              <b-button
                v-tabbable
                rounded
                native-type="submit"
                type="is-primary"
                :disabled="isRefundGreaterThanRemainingAmount"
                :loading="isRefundingOrder === order.orderId"
              >
                Refund
              </b-button>
            </div>
          </div>
        </validated-form>
      </b-tab-item>
    </b-tabs>
  </div>

</template>

<script>
  import currency from 'currency.js';
  import Order from '@/store/classes/Order';
  import PosTypeOption from '@/store/classes/PosTypeOption';
  import confirmModalCloseMixin from '@/mixins/confirm-modal-close';
  import merchantMixin from '@/mixins/merchant';
  import formatUsd from 'format-usd';
  import orderTypes from '@/constants/orderTypes';
  import paymentMethods from '@/constants/paymentMethods';
  import PaymentGateway from '@/store/classes/PaymentGateway';
  import { normalizeWhitespace } from '@/helpers/strings';

  export default {
    name: 'RefundModal',

    mixins: [confirmModalCloseMixin, merchantMixin],

    props: {
      order: {
        type: Object,
        required: true
      },

      closeOrderReceipt: {
        type: Function,
        default: null
      },

      payment: {
        type: Object,
        required: true
      }
    },

    data() {
      return {
        refundAmount: null,
        maxRefundToggle: false,
        activeTab: 0,
        selectedItems: [],
        showErrorMessage: false,
        orderTypes: null
      };
    },

    computed: {
      allPaymentGatewayOptions() {
        return PaymentGateway.all();
      },

      showRefundItemsTab() {
        return this.posOrMerchantSupportsItemLevelTax
          && this.orderTypeIsOrderAhead
          && this.partialRefundAllowedForPayment;
      },

      posOrMerchantSupportsItemLevelTax() {
        return this.posSupportsItemLevelTax || this.$_selectedMerchant.allowItemLevelRefunds;
      },

      orderTypeIsOrderAhead() {
        return this.order.orderType === this.orderTypes.ORDER_AHEAD;
      },

      posSupportsItemLevelTax() {
        return this.storePOS ? this.storePOS.metaData.supportsItemLevelTax : false;
      },

      storePOS() {
        return PosTypeOption.query().where('name', this.order.store.pos.posType).first();
      },

      isRefundingOrder() {
        return Order.$state().refunding;
      },

      totalRefunded() {
        return this.payment.transactions.reduce((total, transaction) => {
          if (transaction.paymentTransaction.paymentTransactionType === 'Refund' && transaction.paymentTransaction.paymentTransactionStatus === 'Succeeded') {
            return currency(total).add(currency(transaction.paymentTransaction.amount)).value;
          }
          return total;
        }, 0);
      },

      maximumRefundAmount() {
        return currency(this.payment.amount).subtract(currency(this.totalRefunded)).value;
      },

      isAllSelected() {
        const allItemsAreMaxQuantity = this.selectedItems.every((si) => {
          const orderItem = this.order.orderItems.find(oi => oi.orderItemId === si.orderItemId);
          return orderItem.quantity - orderItem.refundedQuantity === si.refundedQuantity;
        });
        return this.selectedItems?.length === this.order.orderItems.filter(item => !item.refunded).length && allItemsAreMaxQuantity;
      },

      currentRefundAmount() {
        return this.selectedItems.reduce((sum, si) => currency(sum).add(si.refundedAmount).value, 0);
      },

      allItemsRefunded() {
        return this.order.orderItems.every(item => item.refunded);
      },

      isRefundGreaterThanRemainingAmount() {
        return this.currentRefundAmount > this.maximumRefundAmount;
      },

      partialRefundAllowedForPayment() {
        const metadata = this.getPaymentGatewayMetadata();
        switch (this.payment.paymentType) {
          case paymentMethods.GIFTCARD.type:
            return metadata?.supportsGiftCardPartialRefund;
          case paymentMethods.EXTERNAL_DEVICE.type:
            return metadata?.supportsEmvPartialRefund;
          case paymentMethods.CREDIT_CARD.type:
          case paymentMethods.SAVED_CREDIT_CARD.type:
          case paymentMethods.CREDIT_CARD_TOKEN.type:
          case paymentMethods.APPLE_PAY.type:
          case paymentMethods.GOOGLE_PAY.type:
            return metadata?.supportsCreditCardPartialRefund;
          default:
            return true;
        }
      }
    },

    watch: {
      maxRefundToggle: 'toggleMaxRefundAmount'
    },

    async created() {
      await this.onCreated();
    },

    methods: {
      async onCreated() {
        this.orderTypes = orderTypes;
        try {
          await Promise.all([
            this.fetchPosTypes(),
            this.fetchPaymentGateways()
          ]);
        }
        catch (error) {
          this.$_onRequestError({
            error,
            toastOptions: {
              message: 'There was an error fetching your payment providers'
            }
          });
        }

        if (!this.partialRefundAllowedForPayment) {
          this.toggleMaxRefundAmount(true);
        }
      },

      calculateItemRefundDisplay(item) {
        return currency(item.pricePerUnitAfterDiscounts).multiply(currency(item.quantity)).value;
      },

      async fetchPosTypes() {
        try {
          await PosTypeOption.fetchPosTypes();
        }
        catch (error) {
          this.$_onRequestError({ toastOptions: { message: 'Unable to fetch pos types' }, error });
        }
      },

      async fetchPaymentGateways() {
        try {
          await PaymentGateway.fetchPaymentGateways();
        }
        catch (error) {
          this.$_onRequestError({
            toastOptions: { message: 'Unable to fetch payment providers' },
            error
          });
        }
      },

      selectedItem(itemId) {
        return this.selectedItems.find(i => i.orderItemId === itemId);
      },

      handleSelectQuantity(quantity, item) {
        this.selectedItems = this.selectedItems.map((i) => {
          if (i.orderItemId === item.orderItemId) {
            i.refundedQuantity = quantity;
            i.refundedAmount = item.cumulativeRefundAmountByAdditionalRefundQuantity[quantity - 1];
          }
          return i;
        });
      },

      handleSelectItem(item) {
        if (this.selectedItems.find(i => i.orderItemId === item.orderItemId)) {
          this.selectedItems = this.selectedItems.filter(i => i.orderItemId !== item.orderItemId);
        }
        else {
          this.selectedItems.push({ orderItemId: item.orderItemId, refundedQuantity: 1, refundedAmount: item.cumulativeRefundAmountByAdditionalRefundQuantity[0] });
        }
      },

      handleSelectAllItems(isChecked) {
        if (isChecked) {
          const nonRefundedItems = this.order.orderItems.filter(item => !item.refunded);
          this.selectedItems = [...nonRefundedItems.map(item => ({
            orderItemId: item.orderItemId,
            refundedQuantity: item.quantity - item.refundedQuantity,
            refundedAmount: item.cumulativeRefundAmountByAdditionalRefundQuantity[item.cumulativeRefundAmountByAdditionalRefundQuantity.length - 1]
          }))];
        }
        else {
          this.selectedItems = [];
        }
      },

      toggleMaxRefundAmount(isToggled) {
        // NOTE: this makes sure we properly format the money to look like money when we set it in the input
        this.refundAmount = isToggled ? currency(this.maximumRefundAmount).toString() : null;
      },

      closeModal() {
        this.$parent.close();
        if (this.closeOrderReceipt) {
          this.closeOrderReceipt();
        }
      },

      refundItems() {
        if (!this.selectedItems.length) {
          this.showErrorMessage = true;
        }
        else {
          this.handleRefund({
            orderId: this.order.orderId,
            paymentId: this.payment.id,
            amount: this.currentRefundAmount,
            currencyCode: this.payment.transactions[0]?.paymentTransaction.currencyCode || 'USD',
            refundedOrderItems: this.selectedItems
          });
        }
      },

      refundPayment() {
        this.handleRefund({
          orderId: this.order.orderId,
          paymentId: this.payment.id,
          amount: this.refundAmount,
          currencyCode: this.payment.transactions[0]?.paymentTransaction.currencyCode || 'USD'
        });
      },

      async handleRefund(payload) {
        try {
          await Order.refundPayment(payload);

          const formattedAmount = formatUsd({ amount: payload.amount });

          const message = normalizeWhitespace(`
            <div class="mar-b-md">
              <p class="has-text-grey">The guest received a total of:</p>
              <p class="is-size-3 has-text-weight-bold">${formattedAmount}</p>
            </div>
            <p class="has-text-grey">
              This order’s payment transaction will be updated shortly.
              ${this.order.fulfillmentType.toLowerCase() === 'delivery' ? ' Please follow-up with your delivery provider in case a credit needs to be issued.' : ''}
            </p>
          `);

          this.$buefy.dialog.confirm({
            title: 'Payment Refunded',
            message,
            hasIcon: false,
            type: 'is-primary',
            onConfirm: this.closeModal,
            canCancel: false
          });
        }
        catch (error) {
          this.$_onRequestError({
            toastOptions: {
              message: 'There was an error processing the refund'
            },
            error
          });
        }
      },

      getPaymentGatewayMetadata() {
        // NOTE: CardfreeInternal is really a test gateway
        const filteredGateways = this.$_selectedMerchant.merchantPaymentGateways
          .filter(mpg => mpg.paymentGateway.name !== 'CardFreeInternal' && mpg.isActive);
        const { storeId } = this.order.store;
        const findGatewayForPaymentType = gateway => gateway.paymentMethods.find(pm => pm.name === this.payment.paymentType);

        const gatewayForType = filteredGateways.find(mpg => mpg.storeId === storeId && findGatewayForPaymentType(mpg))
          || filteredGateways.find(mpg => !mpg.storeId && findGatewayForPaymentType(mpg));


        const paymentGatewayOption = this.allPaymentGatewayOptions.find(pg => pg.id === gatewayForType?.paymentGateway?.id);

        return paymentGatewayOption?.metaData;
      }
    }
  };
</script>

<style lang="sass" scoped>
  .modal-card
    max-width: 450px
    border-radius: $radius

  .modal-card-foot
    position: sticky
    bottom: 0

  ::v-deep .tabs ul li:only-child
    display: none

  .refund-tabs
    background-color: $white
    ::v-deep.tab-content
      padding: 0
      max-height: calc(100vh - 163px)
      overflow: auto

  .refund-item
    display: grid
    grid-template-columns: 1fr auto
    gap: $size-extra-small $size-large

  .item-price
    line-height: 1.3

  .order-amounts
    dl > div
      display: flex
      justify-content: space-between

      dt
        color: $grey

        .sub-text
          margin-top: -3px
      dd
        font-weight: 600
        color: $dark

      &:not(:last-child)
        margin-bottom: 0.5rem
</style>
