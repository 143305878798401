import { Model } from '@vuex-orm/core';
import http from '@/services/http';
import { camelCase } from 'change-case';

export default class MerchantOptionsType extends Model {
  static entity = 'merchantOptionsTypes';
  // TYPES: ['bool', 'number', 'string', 'object'] #object === json

  static fields() {
    return {
      id: this.attr(''),
      name: this.attr('', camelCase),
      tableName: this.attr('', camelCase),
      type: this.attr('')
    };
  }

  // STATE
  static state() {
    return {
      fetching: false
    };
  }

  static $state() {
    return this.store().state.entities.merchantOptionsTypes;
  }

  static typesByTable() {
    return this.all().reduce((obj, type) => {
      if (obj[type.tableName]) obj[type.tableName].push(type);
      else obj[type.tableName] = [type];
      return obj;
    }, {});
  }

  static jsonTypes() {
    return this.all().filter(t => t.type === 'object');
  }

  static async fetchMerchantOptionsTypes() {
    if (this.exists()) return;

    try {
      this.commit((state) => {
        state.fetching = true;
      });

      const response = await http.get('merchant_options_types');

      this.insert({ data: response.data.merchantOptionsTypes });
    }

    catch (error) {
      throw error;
    }

    finally {
      this.commit((state) => {
        state.fetching = false;
      });
    }
  }
}
