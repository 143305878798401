import http from '@/services/http';
import { Model } from '@vuex-orm/core';
import moment from 'moment-timezone';
import CardType from '@/store/classes/CardType';

export default class AccountSavedPayment extends Model {
  static entity = 'accountSavedPayments'


  // FIELDS //////////////////////
  static fields() {
    return {
      id: this.attr(''),
      accountId: this.attr(''),
      cardType: this.attr(''),
      lastFourDigits: this.attr(''),
      expirationMonth: this.attr(''),
      expirationYear: this.attr(''),
      isDefault: this.attr(''),
      isActive: this.attr(''),
      createdAt: this.attr('')
    };
  }

  get expirationDate() {
    return moment(`${this.expirationMonth}-${this.expirationYear}`, 'MM-YYYY');
  }

  get cardTypeDisplayName() {
    const cardTypeRecord = CardType.query().where('name', this.cardType).first();
    return cardTypeRecord ? cardTypeRecord.displayName : 'Unknown';
  }

  // STATE //////////////////////
  static state() {
    return {
      fetching: false,
      submitting: false
    };
  }

  static $state() {
    return this.store().state.entities.accountSavedPayments;
  }

  // ACTIONS //////////////////////
  static async fetchAccountSavedPayments(accountId) {
    try {
      this.commit((state) => {
        state.fetching = true;
      });

      const { data } = await http.get(`accounts/${accountId}/account_saved_payments`);
      this.create({ data: data.accountSavedPayments });
    }

    catch (error) {
      throw error;
    }

    finally {
      this.commit((state) => {
        state.fetching = false;
      });
    }
  }

  static async updateAccountSavedPayment(accountSavedPayment) {
    try {
      this.commit((state) => {
        state.submitting = true;
      });

      const response = await http.put(`account_saved_payments/${accountSavedPayment.id}`, { accountSavedPayment });
      this.update({
        data: response.data.accountSavedPayment
      });
    }

    catch (error) {
      throw error;
    }

    finally {
      this.commit((state) => {
        state.submitting = false;
      });
    }
  }
}
