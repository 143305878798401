import { parsePhoneNumberFromString } from 'libphonenumber-js';

function phoneNumber(value) {
  const normalizedValue = value && value
    .toString()
    .split('')
    .filter(char => /[0-9]/.test(char))
    .join('');

  const isValid = value && normalizedValue.length >= 10 && normalizedValue.split('').every(char => !Number.isNaN(Number(char)));

  if (isValid) {
    const number = parsePhoneNumberFromString(normalizedValue, 'US');
    return number.formatNational();
  }
  else {
    return value;
  }
}



export default phoneNumber;
