<template>
  <li
    :class="['draggable', {'is-open': isOpen}]"
    :data-modifier-id="modifier.id"
  >
    <div :class="['row', {'sub-row': !isSearchRow }]">
      <div class="is-flex pad-y-sm">
        <b-icon
          v-if="canSortResource && !isSearchRow && (!featurePermissions.NESTED_MODIFIERS || parentModifierGroup.type !== modifierGroupTypes.PORTION)"
          icon="grip-lines"
          size="is-small"
          pack="far"
          class="drag-handle"
          :disabled="updatingModifier"
        />
        <img
          v-if="!isSearchRow && featurePermissions.NESTED_MODIFIERS && parentModifierGroup.type === modifierGroupTypes.PORTION"
          :class="['mar-r-sm', { 'transform-180': ['Right Half', '2nd Half'].includes(modifier.displayName) }]"
          :src="portionIcons[modifier.modifierTemplate]"
        >
        <span
          :class="!isSearchRow && featurePermissions.NESTED_MODIFIERS && modifier.canHaveNestedMenuItemModifierGroups && 'link-inverted'"
          @click="featurePermissions.NESTED_MODIFIERS && modifier.canHaveNestedMenuItemModifierGroups && toggleModifier(modifier.id)"
        >
          {{ modifier.displayName }}
          <b-icon
            v-if="!isSearchRow && featurePermissions.NESTED_MODIFIERS && modifier.canHaveNestedMenuItemModifierGroups"
            size="is-small"
            pack="far"
            :icon="updatingModifier ? 'spinner-third' : 'angle-right'"
            :class="[
              'open-indicator',
              {
                'spin': updatingModifier,
                'is-open': isOpen
              }
            ]"
          />
          <b-icon
            v-show="!modifier.mappedToPos && !hasPosType(posTypes.Cardfree)"
            v-tippy="{ content: 'Missing POS Mapping', placement: 'right' }"
            size="is-small"
            icon="exclamation-triangle"
            type="is-danger"
            class="mar-l"
          />
        </span>
      </div>
      <span class="align-center justify-end">
        <template v-if="!activeMenuType && !isSearchRow">
          <div
            v-tippy="{
              content: 'A nested group cannot be the default',
              placement: 'left',
              onShow: () => featurePermissions.NESTED_MODIFIERS && modifier.hasChildrenModifierGroups
            }"
            class="is-flex align-center"
          >
            <b-radio
              v-if="parentModifierGroup.maxAllowed === 1"
              :native-value="true"
              :disabled="!canSetAsDefault || updatingModifier"
              :value="modifier.isDefault"
              :name="`${parentModifierGroup.id}-isDefault`"
              @input="handleSelectedChange({ isDefault: $event, isRadio: true })"
              @click.native="handleSelfClick($event, modifier.id)"
            />
            <b-checkbox
              v-else
              :disabled="!canSetAsDefault || !modifier.isDefault && hasGroupReachedDefaultModifierLimit || updatingModifier"
              :value="modifier.isDefault"
              @input="handleSelectedChange({ isDefault: $event })"
            />
          </div>
        </template>
      </span>
      <span class="align-center justify-end">
        <template v-if="!activeMenuType && (!featurePermissions.NESTED_MODIFIERS || parentModifierGroup.type !== modifierGroupTypes.PORTION)">
          <b-button
            v-if="modifier.availabilityBeginDate || modifier.availabilityEndDate"
            v-tippy="{ content: 'Restricted Availability', placement: 'left', delay: [333, 0] }"
            class="is-transparent"
            :disabled="updatingModifier"
            @click="openModifierModal()"
          >
            <b-icon
              pack="far"
              :icon="updatingModifier ? 'spinner-third' : 'calendar'"
              :class="{ 'spin': updatingModifier }"
            />
          </b-button>
          <b-button
            class="is-transparent"
            :disabled="updatingModifier"
            @click="openModifierModal()"
          >
            <b-icon
              v-if="$can('update', 'MenuItemModifier') && menuPermissions.EDIT_RESOURCE"
              :icon="updatingModifier ? 'spinner-third' : 'pencil'"
              :class="{ 'spin': updatingModifier }"
              size="is-small"
            />
            <span v-else>View</span>
          </b-button>
          <b-dropdown
            v-if="$can('destroy', 'MenuItemModifier') && menuPermissions.REMOVE_RESOURCE"
            aria-role="list"
            position="is-bottom-left"
          >
            <b-button
              slot="trigger"
              class="is-transparent"
              type="is-white"
            >
              <b-icon icon="ellipsis-v" pack="far" size="is-small" />
            </b-button>
            <b-dropdown-item
              class="is-danger"
              @click="openDeleteModifierConfirmation(modifier)"
            >
              <b-icon icon="trash-alt" class="mar-r-sm" size="is-small" />Delete Modifier
            </b-dropdown-item>
          </b-dropdown>
        </template>
      </span>
    </div>

    <nested-modifier-groups-container
      v-if="isOpen && modifier.canHaveNestedMenuItemModifierGroups"
      key="nested-modifier-groups-container"
      :modifier-groups="nestedModifierGroups"
      :parent-modifier="modifier"
      :menu-item="menuItem"
    />
  </li>
</template>

<script>
  import { mapActions, mapState } from 'vuex';

  import menuResourceTypes from '@/constants/menuResourceTypes';

  import AlertModal from '@/components/globals/alert-modal.vue';

  import { modifierGroupTypes, modifierGroupPortionTemplates } from '@/constants/modifierGroups';
  import posTypes from '@/constants/posTypes';

  import Modifier from '@/store/classes/Modifier';
  import ModifierGroup from '@/store/classes/ModifierGroup';
  import MenuItemModifierGroupItem from '@/store/classes/MenuItemModifierGroupItem';

  import AddEditModifierModal from '../main-menu/add-edit-modifier-modal/index.vue';
  import NestedModifierGroupsContainer from './nested-modifier-groups-container.vue';


  export default {
    name: 'ModifierRow',

    components: { NestedModifierGroupsContainer },

    props: {
      modifier: {
        type: Object,
        required: true
      },

      modifierGroup: {
        type: Object,
        default: null
      },

      hasGroupReachedDefaultModifierLimit: {
        type: Boolean,
        default: null
      },

      // For nested modifier groups
      menuItem: {
        type: Object,
        default: null
      },

      isSearchRow: {
        type: Boolean,
        default: false
      }
    },

    data: () => ({
      modifierGroupTypes,
      portionIcons: {
        [modifierGroupPortionTemplates.FULL_GROUP_TEMPLATE.constant]: '/images/whole-portion.svg',
        [modifierGroupPortionTemplates.HALF_GROUP_TEMPLATE.constant]: '/images/half-portion.svg'
      },
      posTypes
    }),

    computed: {
      ...mapState('mainMenu', [
        'menuPermissions',
        'featurePermissions',
        'selectedMerchant',
        'activeMenuType',
        'modifiersOpenedStatus'
      ]),

      isOpen() {
        return this.modifiersOpenedStatus[this.modifier.id];
      },

      parentModifierGroup() {
        // When this row is used as in the search flow, there is no modifier group to pass down as a prop
        return this.isSearchRow
          ? this.modifier.menuItemModifierGroup
          : this.modifierGroup;
      },

      canSortResource() {
        return [
          this.$can('update', 'MenuItemModifier'),
          !this.activeMenuType,
          this.menuPermissions.SORT_RESOURCE
        ].every(x => x);
      },

      canSetAsDefault() {
        return (
          this.$can('update', 'MenuItemModifier')
          && this.menuPermissions.SET_DEFAULT_MODIFIER
          && !(this.featurePermissions.NESTED_MODIFIERS && this.modifier.hasChildrenModifierGroups)
        );
      },

      nestedModifierGroups() {
        /**
         * menuItemModifierGroupItems are a join record with a nested menuItemModifierGroup
         * {
         *  id: 1,
         *  menuItemModifierGroupId: 1,
         *  menuItemId: 1,
         *  sortOrder: 1,
         *  modifierGroup: { id: 1, menuItemModifiers: [] }
         * }
         * menuItemModifierGroups are the actual modifier groups
         * {
         *  id: 1,
         *  menuItemId: 1,
         *  sortOrder: 1,
         *  menuItemModifiers: []
         * }
         *
         * We will pass an array of either to the modifier-groups-container and it and the row will
         * deal with the different data structure.
         */
        return this.featurePermissions.SHARED_MODIFIER_GROUPS
          ? this.menuItemModifierGroupItems
          : this.menuItemModifierGroups;
      },

      menuItemModifierGroups() {
        if (this.featurePermissions.SHARED_MODIFIER_GROUPS) return null;
        if (this.isOpen === undefined) return [];
        return ModifierGroup.query()
          .where('menuItemId', Number(this.menuItem.id))
          .where('menuItemModifierId', this.modifier.id)
          .with('menuItemModifiers')
          .orderBy('sortOrder')
          .get();
      },

      menuItemModifierGroupItems() {
        if (!this.featurePermissions.SHARED_MODIFIER_GROUPS) return null;
        if (this.isOpen === undefined) return [];
        return MenuItemModifierGroupItem.query()
          .where('menuItemId', Number(this.menuItem.id))
          .where('menuItemModifierId', this.modifier.id)
          .with('modifierGroup')
          .with('modifierGroup.menuItemModifiers')
          .orderBy('sortOrder')
          .get();
      },

      updatingModifier() {
        return Modifier.$state().fetchingModifierId === this.modifier.id
          || Modifier.$state().fetchingModifierGroupId === this.modifierGroup.id
          || Modifier.$state().updatingModifierIds.includes(this.modifier.id)
          || Modifier.$state().sortingParentId === this.parentModifierGroup.id;
      }
    },

    methods: {
      ...mapActions('mainMenu', ['setMenuResourceOpenState']),

      toggleModifier() {
        // Nested modifier groups are fetched in menu-item-row along with all the other
        // modifier groups for a given item, so there is no fetch call for them here.
        this.setMenuResourceOpenState({
          resourceType: menuResourceTypes.MODIFIER,
          resourceId: this.modifier.id,
          value: !this.isOpen
        });
      },

      hasPosType(posTypeId) {
        // From merchantMixin, which we're not using in the main menu for performance reasons
        return this.selectedMerchant?.posConfigurations
          .some(posConfig => posConfig.posType.id === posTypeId);
      },

      async handleSelectedChange({ isDefault, isRadio }) { // eslint-disable-line object-curly-newline
        try {
          if (isRadio) {
            const updatedModifiers = JSON.parse(JSON.stringify(this.modifierGroup.menuItemModifiers)).map((modifier) => {
              modifier.isDefault = modifier.id === this.modifier.id;
              return modifier;
            });

            await Modifier.bulkUpdateModifiers({
              modifierGroup: this.modifierGroup,
              modifiers: this.modifierGroup.menuItemModifiers,
              updatedModifiers
            });
          }

          else {
            await Modifier.updateModifier({ id: this.modifier.id, isDefault });
          }

          this.$_onRequestSuccess({
            toastOptions: {
              message: 'Successfully updated modifier selection!'
            }
          });
        }

        catch (error) {
          this.$_onRequestError({
            error,
            toastOptions: {
              message: 'Unable to update modifier selection'
            }
          });
        }
      },

      handleSelfClick(event) {
        const isChecked = event.target.closest('.b-radio').querySelector('input').checked;

        if (isChecked && this.$can('update', 'MenuItemModifier')) {
          this.handleSelectedChange({ isDefault: !isChecked });
        }
      },

      openModifierModal() {
        this.$buefy.modal.open({
          parent: this,
          component: AddEditModifierModal,
          hasModalCard: true,
          trapFocus: true,
          canCancel: false,
          customClass: 'auto-width',
          events: {
            'modifier-updated': () => this.$emit('re-search-menu')
          },
          props: {
            modifierGroup: this.modifierGroup,
            mode: 'update',
            modifierId: this.modifier.id
          }
        });
      },

      openDeleteModifierConfirmation({ displayName, id }) {
        this.$buefy.modal.open({
          parent: this,
          component: AlertModal,
          hasModalCard: true,
          trapFocus: true,
          customClass: 'auto-width',
          props: {
            buttons: [
              { text: 'Cancel' },
              {
                text: 'Delete',
                primary: true,
                onClick: () => this.deleteModifier({ displayName, id })
              }
            ],
            horizontal: true,
            showCloseButton: false,
            icon: 'trash-alt',
            title: 'Delete Modifier',
            message: `<b>${displayName}</b> will be deleted from all locations. Are you sure?`,
            type: 'is-danger'
          }
        });
      },

      async deleteModifier({ displayName, id }) {
        try {
          await Modifier.deleteModifier(id);

          this.$_onRequestSuccess({
            toastOptions: {
              message: `Successfully deleted <b>${displayName}</b>`
            }
          });
        }

        catch (error) {
          this.$_onRequestError({
            error,
            toastOptions: {
              message: `Unable to delete <b>${displayName}</b>`
            }
          });
        }
      }
    }
  };
</script>

<style lang='sass' scoped>
.row
    grid-template-columns: 1fr 200px 170px
</style>
