// classes
import { Model } from '@vuex-orm/core';
import http from '@/services/http';
import filterObjectKeys from '@/helpers/filter-object-keys';
import { parseJson } from '@/helpers/misc';

export default class MerchantOptionsLoyalty extends Model {
  static entity = 'merchantOptionsLoyalties'

  // FIELDS //////////////////////
  static fields() {
    return {
      id: this.attr(''),
      merchantId: this.attr(''),
      accountOffersEnabled: this.attr(''),
      birthdayAwardNotificationDaysAhead: this.attr(''),
      hasSignUpOffer: this.attr(''),
      inStoreRedemptionOptions: this.attr(null, parseJson),
      loyaltyCardCheckInEnabled: this.attr(''),
      loyaltyCheckInEnabled: this.attr(''),
      loyaltyEnabled: this.attr(''),
      loyaltyImageUrl: this.attr(''),
      loyaltyMobileHomeScreenCardEnabled: this.attr(''),
      loyaltyPopupOnSignInEnabled: this.attr(''),
      loyaltyPublicEnrollmentEnabled: this.attr(''),
      loyaltyReceiptScanningEnabled: this.attr(''),
      loyaltyShowConversionThresholdsEnabled: this.attr(''),
      offersProviderPromoCodesEnabled: this.attr(''),
      posPromoCodesEnabled: this.attr('')
    };
  }

  // STATE //////////////////////
  static state() {
    return {
      fetching: false,
      submitting: false
    };
  }

  static $state() {
    return this.store().state.entities.merchantOptionsLoyalties;
  }

  static blackListedKeys = ['id', '$id', 'merchantId'];

  // ACTIONS //////////////////////
  static async fetchMerchantOptionsLoyalties(merchantId) {
    try {
      this.commit((state) => {
        state.fetching = true;
      });

      const _merchantId = merchantId || this.store().state.entities.merchants.selectedMerchantId;
      const response = await http.get(`merchants/${_merchantId}/merchant_options_loyalties`);

      this.insert({
        data: response.data.merchantOptionsLoyalties
      });
      return response.data.merchantOptionsLoyalties;
    }
    catch (error) {
      throw error;
    }
    finally {
      this.commit((state) => {
        state.fetching = false;
      });
    }
  }

  static async addMerchantOptionsLoyalty(merchantOptionsLoyalty) {
    try {
      this.commit((state) => {
        state.submitting = true;
      });

      const response = await http.post(
        `merchants/${merchantOptionsLoyalty.merchantId}/merchant_options_loyalties`,
        { merchantOptionsLoyalty: filterObjectKeys(merchantOptionsLoyalty, this.blackListedKeys, true) }
      );

      this.insert({
        data: response.data.merchantOptionsLoyalty
      });
    }
    catch (error) {
      throw error;
    }
    finally {
      this.commit((state) => {
        state.submitting = false;
      });
    }
  }

  static async updateMerchantOptionsLoyalty(merchantOptionsLoyalty) {
    try {
      this.commit((state) => {
        state.submitting = true;
      });

      const response = await http.put(
        `merchant_options_loyalties/${merchantOptionsLoyalty.id}`,
        { merchantOptionsLoyalty: filterObjectKeys(merchantOptionsLoyalty, this.blackListedKeys, true) }
      );

      this.update({
        data: response.data.merchantOptionsLoyalty
      });
    }
    catch (error) {
      throw error;
    }
    finally {
      this.commit((state) => {
        state.submitting = false;
      });
    }
  }
}
