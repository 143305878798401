/* eslint-disable import/no-cycle */
import { Model } from '@vuex-orm/core';
import http from '@/services/http';
import { parseJson } from '@/helpers/misc';



export default class PosTypeOption extends Model {
  static entity = 'posTypeOptions'

  static fields() {
    return {
      id: this.attr(''),
      name: this.attr(''),
      metaData: this.attr('', parseJson)
    };
  }

  // STATE //////////////////////
  static state() {
    return {
      fetching: false
    };
  }

  static $state() {
    return this.store().state.entities.posTypeOptions;
  }

  static squarePosType() {
    return this.query().where('name', 'Square')?.first();
  }

  static toastPosType() {
    return this.query().where('name', 'Toast')?.first();
  }

  // Actions
  static async fetchPosTypes(options = {}) {
    try {
      this.commit((state) => {
        state.fetching = true;
      });

      const alreadyFetched = this.query().exists();
      if (!alreadyFetched || options.forceFetch) {
        const response = await http.get('pos_types');
        this.insert({ data: response.data.posTypes });
      }
    }
    catch (error) {
      throw error;
    }
    finally {
      this.commit((state) => {
        state.fetching = false;
      });
    }
  }
}
