<template>
  <validated-form
    ref="form"
    auto-focus
    form-id="menuTagModal"
    @valid-submit="handleSubmit"
  >
    <modal-card :title="`Menu Tag${tag.id ? `: ${tag.menuAttribute.name}` : ''}`" modal-card-body-class="is-paddingless" :loading="isLoading">
      <b-loading :active="isLoading" :is-full-page="false" />
      <div class="pad-lg">
        <h2 class="subtitle is-size-6 has-text-grey">Group similar items using tags to improve guest's search results</h2>
        <div>
          <validated-text-input
            v-model="form.menuAttribute.name"
            label="Menu Tag Name"
            type="text"
            placeholder="Enter a menu tag name..."
            name="name"
            :rules="{
              required: true,
              max: 50,
              excluded: excludedValidationArray
            }"
            :custom-messages="{
              excluded: 'Tag name already exists'
            }"
            class="mar-b-lg"
          />
          <b-checkbox
            v-model="form.isSearchSuggestion"
            :disabled="searchSuggestionDisabled"
            name="isSearchSuggestion"
          >
            Set tag as search suggestion
            <span>
              ({{ searchSuggestionFormCount }}/6)
            </span>
          </b-checkbox>
        </div>
      </div>

      <template v-if="!!tag.id">
        <hr class="mar-y-sm hr-divider">

        <section class="pad item-section">
          <p :class="['is-size-5', { 'mar-b': form.itemCount > 0 }]">Items using this tag: ({{ form.itemCount }})</p>

          <div class="menu-item-tags">
            <template v-for="menuItemAttribute in form.menuAttribute.menuItemAttributes">
              <menu-item-tag
                :key="menuItemAttribute.menuItemId"
                :menu-attribute="form.menuAttribute"
                :menu-item-attribute="menuItemAttribute"
              />
            </template>
          </div>
        </section>
      </template>

      <template #footer>
        <div class="buttons all-bold">
          <b-button rounded @click="$_confirmCloseModal({ programmatic: true })">
            Cancel
          </b-button>
          <b-button
            v-tabbable
            rounded
            native-type="submit"
            type="is-primary"
            :loading="isLoading"
          >
            Save
          </b-button>
        </div>
      </template>
    </modal-card>
  </validated-form>
</template>

<script>
  import MerchantMenuAttribute from '@/store/classes/MerchantMenuAttribute';
  import MenuAttributeType from '@/store/classes/MenuAttributeType';
  import MenuAttribute from '@/store/classes/MenuAttribute';
  import merchantMixin from '@/mixins/merchant';
  import confirmModalCloseMixin from '@/mixins/confirm-modal-close';
  import menuItemTag from './menu-item-tag.vue';
  import Category from '@/store/classes/Category';

  export default {
    name: 'MenuTagModal',

    components: { menuItemTag },

    mixins: [merchantMixin, confirmModalCloseMixin],

    props: {
      merchantMenuAttributeId: {
        type: Number,
        default: null
      },

      merchantTags: {
        type: Array,
        default: () => []
      }
    },

    data: () => ({
      form: null
    }),

    computed: {
      tag() {
        const tag = MerchantMenuAttribute.getAttributeByIdWithMenuAttribute(this.merchantMenuAttributeId);
        return tag
          ? { ...tag, itemCount: tag.menuAttribute.itemCount }
          : this.newTag();
      },

      isLoading() {
        return Category.$state().fetching || MenuAttribute.$state().submitting;
      },

      countOfSearchSuggestionTags() {
        return MerchantMenuAttribute.countOfSearchSuggestionTags();
      },

      currentTags() {
        return this.merchantTags.map(tag => tag.name.toLowerCase());
      },

      searchSuggestionFormCount() {
        // NOTE: When the current tag is part of the maximum 6,
        // we shouldn't add an additional 1 to the count
        // And we should subtract 1 from the count (examples in tests)
        const addingNumber = this.tag.isSearchSuggestion ? 0 : 1;
        const subtractingNumber = this.tag.isSearchSuggestion ? -1 : 0;
        return this.countOfSearchSuggestionTags + (this.form.isSearchSuggestion ? addingNumber : subtractingNumber);
      },

      searchSuggestionDisabled() {
        const availableSearchSuggestionSlot = this.tag.isSearchSuggestion
          ? this.countOfSearchSuggestionTags <= 6
          : this.countOfSearchSuggestionTags < 6;

        return !availableSearchSuggestionSlot;
      },

      excludedValidationArray() {
        // NOTE: https://vee-validate.logaretm.com/v2/guide/rules.html#excluded
        // if tag name is already in the current tags, push it into the comparing array so validation returns false
        return this.form.menuAttribute?.name
          && this.form.menuAttribute?.name?.toLowerCase() !== this.tag.menuAttribute?.name?.toLowerCase()
          && this.currentTags.includes(this.form.menuAttribute?.name?.toLowerCase())
          ? [...this.currentTags, this.form.menuAttribute?.name]
        : [];
      }
    },

    watch: {
      tag: 'onCreated'
    },

    created() {
      this.onCreated();
    },

    methods: {
      onCreated() {
        this.form = this.$clone(this.tag);
      },

      newTag() {
        const searchTagTypeId = MenuAttributeType.searchTagType().id;
        const menuAttribute = new MenuAttribute({ menuAttributeTypeId: searchTagTypeId });
        return new MerchantMenuAttribute({
          menuAttributeId: menuAttribute.id,
          menuAttribute,
          merchantId: this.$_selectedMerchantId,
          itemCount: 0
        });
      },

      async handleSubmit() {
        try {
          if (this.form.id) {
            const payload = {
              ...this.form.menuAttribute,
              merchantMenuAttributeAttributes: {
                id: this.form.id,
                isSearchSuggestion: this.form.isSearchSuggestion
              }
            };
            MenuAttribute.updateMenuAttribute(payload);
          }
          else {
            const payload = {
              ...this.form.menuAttribute,
              merchantMenuAttributeAttributes: {
                isSearchSuggestion: this.form.isSearchSuggestion
              }
            };
            await MenuAttribute.createMenuAttributes([payload]);
          }

          this.$_onRequestSuccess({
            toastOptions: {
              message: `Successfully ${this.form.id ? 'updated' : 'created'} your tag`
            },
            options: {
              closeParent: true
            }
          });
        }
        catch (error) {
          this.$_onRequestError({
            error,
            toastOptions: {
              message: `There was an error ${this.form.id ? 'updating' : 'creating'} your tag`
            }
          });
        }
      }
    }
  };
</script>

<style lang='sass' scoped>
  .hr-divider
    margin-bottom: 0 !important
    margin-top: 0 !important
    background-color: $grey-lighter !important

  .item-section
    background-color: lighten($grey, 45) !important

    .menu-item-tags
      max-height: 400px
      overflow-y: scroll
</style>
