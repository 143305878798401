/* eslint-disable import/no-cycle */
import { Model } from '@vuex-orm/core';

// classes
import http from '@/services/http';


export default class MerchantCommunicationSource extends Model {
  static entity = 'merchantCommunicationSources'

  // FIELDS //////////////////////
  static fields() {
    return {
      id: this.attr(''),
      templateTypeId: this.attr(''),
      methodTypeName: this.attr(''),
      value: this.attr(''),
      merchantId: this.attr('')
    };
  }

  // STATE //////////////////////
  static state() {
    return {
      fetching: false
    };
  }

  static $state() {
    return this.store().state.entities.merchantCommunicationSources;
  }

  // ACTIONS //////////////////////
  static async fetchMerchantCommunicationSources(merchantId) {
    try {
      this.commit((state) => {
        state.fetching = true;
      });

      const response = await http.get(`merchants/${merchantId}/merchant_communication_sources`);

      this.create({
        data: response.data.merchantCommunicationSources
      });
    }

    catch (error) {
      throw error;
    }

    finally {
      this.commit((state) => {
        state.fetching = false;
      });
    }
  }

  static async createMerchantCommunicationSources({ merchantId, merchantCommunicationSources }) {
    try {
      const response = await http.post(`merchants/${merchantId}/merchant_communication_sources`, { merchantCommunicationSources });

      this.insert({ data: response.data.merchantCommunicationSources });
    }

    catch (error) {
      throw error;
    }
  }

  static async updateMerchantCommunicationSources({ merchantId, merchantCommunicationSources }) {
    try {
      const response = await http.put(`merchants/${merchantId}/merchant_communication_sources`, { merchantCommunicationSources });

      this.update({ data: response.data.merchantCommunicationSources });
    }

    catch (error) {
      throw error;
    }
  }
}
