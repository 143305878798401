import http from '@/services/http';
import LoyaltyDraft from '@/store/classes/LoyaltyDraft';
import { earnRuleStatuses, memberEligibilityPolicyTypes } from '@/constants/earnRules';

export default class EarnRule extends LoyaltyDraft {
  static entity = 'earnRules'

  static primaryKey = 'publicId';

  // FIELDS //////////////////////
  static fields() {
    return {
      ...super.fields(),
      publicId: this.attr(''),
      awardPolicy: this.attr({}),
      description: this.attr(''),
      earnRuleConstraints: this.attr([]),
      earnRuleRunLimitPolicies: this.attr([]),
      endDate: this.attr(null),
      engagementType: this.attr(null),
      name: this.attr(''),
      memberEligibilityPolicy: this.attr('', value => ({
        ...memberEligibilityPolicyTypes.ALL_MEMBERS.defaultData,
        ...value
      })),
      portalDerivedStatus: this.attr(null),
      previousVersions: this.attr([]),
      programPublicId: this.attr(''),
      startDate: this.attr(''),
      version: this.attr(''),
      versionStartDate: this.attr('')
    };
  }

  get engagementTypeName() {
    const map = {
      1: 'Member Enrolled',
      2: 'Sales Transaction',
      3: 'Birthday',
      4: 'Sales Transaction Completed',
      5: 'Sales Transaction Refunded',
      6: 'Reward',
      7: 'Adjustment',
      8: 'Referral Converted'
    };

    return map[this.engagementType];
  }

  get isFuture() {
    return this.portalDerivedStatus === earnRuleStatuses.FUTURE;
  }

  /*
   * active - a currently live version of an earn rule
   * future - a live earn rule with changes pending for the next day
   * expiring - a live earn rule that expires at the end of the current day
   * scheduled - a unique, upcoming earn rule not attached to a current live rule
   * expired - a live earn rule that has expired
  */

  // STATE //////////////////////
  static state() {
    return {
      fetching: false,
      fetchingDrafts: false,
      submitting: false
    };
  }

  static $state() {
    return this.store().state.entities.earnRules;
  }

  static earnRulesByCurrencyId(currencyId) {
    if (!currencyId) return [];
    return this
      .query()
      .where('awardPolicy', awardPolicy => awardPolicy?.currencyPublicId === currencyId)
      .get();
  }

  static earnRulesByCurrencyIds(currencyIds) {
    return EarnRule
      .query()
      .where('awardPolicy', awardPolicy => currencyIds.includes(awardPolicy?.currencyPublicId))
      .get();
  }

  static nonCurrencyEarnRules() {
    // NOTE: Earn rules with no currencyPublicId in award policy are a non-point based type
    // Versus with challenges, where we will not allow award types without a currency
    return this.query().where('awardPolicy', awardPolicy => !awardPolicy?.currencyPublicId).get();
  }

  static nonSecondaryCurrencyEarnRules(currencyId) {
    const primaryCurrencyEarnRules = this.earnRulesByCurrencyId(currencyId);
    const nonCurrencyEarnRules = this.nonCurrencyEarnRules();
    return [...primaryCurrencyEarnRules, ...nonCurrencyEarnRules];
  }


  // ACTIONS //////////////////////
  static async fetchDraftEarnRules() {
    try {
      this.commit((state) => {
        state.fetchingDrafts = true;
      });

      const merchantId = this.store().state.entities.merchants.selectedMerchantId;
      const { data } = await http.get(`merchants/${merchantId}/earn_rules/drafts`);

      // NOTE: LoyaltyDrafts will not have a status field,
      // So after filtering out non-drafts on BE, status will always be 'draft'
      this.insert({
        data: data.draftEarnRules.map(d => ({ ...d, ...d.body, portalDerivedStatus: earnRuleStatuses.DRAFT }))
      });
    }

    catch (error) {
      throw error;
    }

    finally {
      this.commit((state) => {
        state.fetchingDrafts = false;
      });
    }
  }

  static async fetchEarnRules({ programPublicId, includeForSecondaryCurrency }) {
    try {
      this.commit((state) => {
        state.fetching = true;
      });

      const includeQuery = includeForSecondaryCurrency ? '?include_secondary_currency=true' : '';
      const merchantId = this.store().state.entities.merchants.selectedMerchantId;
      const { data } = await http.get(`merchants/${merchantId}/programs/${programPublicId}/earn_rules${includeQuery}`);

      this.insert({ data: data.earnRules.map(er => ({ ...er, loyaltyPublicId: er.publicId })) });
    }

    catch (error) {
      throw error;
    }

    finally {
      this.commit((state) => {
        state.fetching = false;
      });
    }
  }

  static async fetchEarnRule(currentEarnRule) {
    try {
      this.commit((state) => {
        state.fetching = true;
      });

      const previousVersions = currentEarnRule.previousVersions;
      const merchantId = this.store().state.entities.merchants.selectedMerchantId;
      const { data: { earnRule } } = await http.get(`merchants/${merchantId}/earn_rules/${currentEarnRule.publicId}`);
      this.update({ data: { ...earnRule, loyaltyPublicId: earnRule.publicId, previousVersions } });
    }

    catch (error) {
      throw error;
    }

    finally {
      this.commit((state) => {
        state.fetching = false;
      });
    }
  }

  static async createDraftEarnRule(earnRule) {
    try {
      this.commit((state) => {
        state.submitting = true;
      });

      // const earnRule = new this({
      //   programPublicId,
      //   name: 'Birthday Award - 2',
      //   description: 'Get bonus points on your birthday',
      //   version: 2,
      //   engagementType: 3,
      //   startDate: '2021-03-23',
      //   endDate: '2024-12-31',
      //   awardPolicy: {
      //     awardPolicyType: 'BirthdayPointAwardPolicyModel',
      //     cardfreeOfferPublicId: null,
      //     currencyPublicId,
      //     earningItemBasisType: null,
      //     excludedItemAttributes: [],
      //     itemGroup: null,
      //     itemGroupPublicId: null,
      //     maximumEarningQuantity: null,
      //     maximumPointsPerSalesTransaction: null,
      //     points: 30,
      //     pointsPerDollarSpent: null,
      //     pointsPerQualifyingCount: null,
      //     qualifyingCountIncrement: null,
      //     refundMode: null,
      //     weightedAwardOptions: []
      //   }
      // });

      const merchantId = this.store().state.entities.merchants.selectedMerchantId;
      const { data: { draftEarnRule } } = await http.post(`merchants/${merchantId}/earn_rules/drafts`, { earnRule });

      this.insert({ data: { ...draftEarnRule, ...draftEarnRule.body, portalDerivedStatus: earnRuleStatuses.DRAFT } });
    }

    catch (error) {
      throw error;
    }

    finally {
      this.commit((state) => {
        state.submitting = false;
      });
    }
  }

  static async createEarnRule(earnRule) {
    try {
      this.commit((state) => {
        state.submitting = true;
      });
      const merchantId = this.store().state.entities.merchants.selectedMerchantId;

      const { data: { earnRule: er } } = await http.post(`merchants/${merchantId}/earn_rules`, { earnRule });

      this.insert({ data: { ...er, loyaltyPublicId: er.publicId } });

      return er;
    }
    catch (error) {
      throw error;
    }
    finally {
      this.commit((state) => {
        state.submitting = false;
      });
    }
  }

  static async updateDraftEarnRule(earnRule) {
    try {
      this.commit((state) => {
        state.submitting = true;
      });

      const merchantId = this.store().state.entities.merchants.selectedMerchantId;

      const { data: { draftEarnRule } } = await http.put(`merchants/${merchantId}/earn_rules/drafts/${earnRule.id}`, { earnRule });

      // NOTE: Because publicId is the primary key, we can use the generated value to determine the record to update
      this.update({
        data: { ...draftEarnRule, ...draftEarnRule.body, portalDerivedStatus: earnRuleStatuses.DRAFT },
        where: earnRule.publicId
      });
    }
    catch (error) {
      throw error;
    }
    finally {
      this.commit((state) => {
        state.submitting = false;
      });
    }
  }

  static async updateEarnRule(earnRule) {
    try {
      this.commit((state) => {
        state.submitting = true;
      });

      const merchantId = this.store().state.entities.merchants.selectedMerchantId;

      const { data: { earnRule: er } } = await http.put(`merchants/${merchantId}/earn_rules/${earnRule.publicId}`, { earnRule });

      this.update({ data: { ...er, loyaltyPublicId: er.publicId } });
    }
    catch (error) {
      throw error;
    }
    finally {
      this.commit((state) => {
        state.submitting = false;
      });
    }
  }

  static async promoteDraftEarnRule(draftEarnRule) {
    try {
      this.commit((state) => {
        state.submitting = true;
      });

      const merchantId = this.store().state.entities.merchants.selectedMerchantId;

      const { data: { earnRule } } = await http.put(`merchants/${merchantId}/earn_rules/drafts/${draftEarnRule.id}/promote`, { earnRule: draftEarnRule });

      this.insert({ data: { ...earnRule, loyaltyPublicId: earnRule.publicId } });

      return { ...earnRule, loyaltyPublicId: earnRule.publicId };
    }
    catch (error) {
      throw error;
    }
    finally {
      this.commit((state) => {
        state.submitting = false;
      });
    }
  }

  static async deleteEarnRule(publicId) {
    try {
      this.commit((state) => {
        state.submitting = true;
      });

      const merchantId = this.store().state.entities.merchants.selectedMerchantId;

      await http.delete(`merchants/${merchantId}/earn_rules/${publicId}`);

      this.delete(publicId);
    }
    catch (error) {
      throw error;
    }
    finally {
      this.commit((state) => {
        state.submitting = false;
      });
    }
  }

  static async deleteDraftEarnRule(earnRulePublicId) {
    try {
      this.commit((state) => {
        state.submitting = true;
      });

      const merchantId = this.store().state.entities.merchants.selectedMerchantId;
      await http.delete(`merchants/${merchantId}/earn_rules/drafts/${earnRulePublicId}`);

      this.delete(earnRulePublicId);
    }

    catch (error) {
      throw error;
    }

    finally {
      this.commit((state) => {
        state.submitting = false;
      });
    }
  }
}
