<template>
  <transition name="fade-down">
    <b-message
      v-if="['patEnabled', 'orderAheadEnabled', 'deliveryEnabled', 'shippingEnabled', 'roomServiceEnabled'].every(orderType => !$_selectedMerchant[orderType])"
      type="is-warning"
      has-icon
      icon="exclamation-circle"
      class="is-compact has-shadow"
    >
      <p>This merchant does not have any applications enabled.</p>
      <p v-if="$can('manage','all')">
        Manage applications in
        <router-link
          :to="{
            name: 'merchantConfiguration',
            params: { tabName: 'applications'}
          }">Merchant Configuration</router-link>. <!-- eslint-disable-line -->
      </p>
    </b-message>
    <validated-form
      v-else-if="form"
      v-slot="{ dirty }"
      form-id="orderModeForm"
      class="dist-y-lg"
      @valid-submit="submit"
    >
      <div class="card">
        <div class="card-content">
          <div class="order-mode-sections">
            <!-- Dine In -->
            <template v-if="$_selectedMerchant.patEnabled || $_selectedMerchant.orderAheadEnabled">
              <section>
                <b-switch
                  v-model="form.storeMappingAttributesByCode.DineIn.isActive"
                  :disabled="!canUpdateOrderingMode(form.storeMappingAttributesByCode.DineIn.storeAttributeId)"
                  class="left-label"
                >
                  <div class="mar-r-sm">
                    <span class="title is-4">
                      Dine In
                    </span>
                  </div>
                </b-switch>

                <transition name="fade-down">
                  <div v-if="form.storeMappingAttributesByCode.DineIn.isActive">
                    <div class="is-flex align-center mar-y-xs">
                      <a class="link mar-r-md" :href="dineInLink" target="_blank">
                        {{ dineInLink }} <b-icon icon="external-link" />
                      </a>
                      <b-button size="is-small" icon-right="copy" type="is-primary is-light" @click="$_copyToClipboard(dineInLink)">
                        Copy
                      </b-button>
                    </div>

                    <div class="url-inputs mar-b-xs" style="height: 30px">
                      <b-checkbox v-model="hasTableNumber">
                        Include Table Number In Link
                      </b-checkbox>

                      <transition name="fade-right">
                        <b-input
                          v-show="hasTableNumber"
                          v-model="tableNumber"
                          size="is-small"
                          icon-right="times-circle"
                          icon-right-clickable
                          @icon-right-click="tableNumber = ''"
                        />
                      </transition>
                    </div>

                    <div class="url-inputs" style="height: 30px">
                      <b-checkbox v-model="hasLocationType">
                        Include Location Type In Link
                      </b-checkbox>

                      <transition name="fade-right">
                        <b-select
                          v-show="hasLocationType"
                          v-model="selectedLocationType"
                          size="is-small"
                          class="location-type"
                        >
                          <option v-for="locationType in locationTypes" :key="locationType.id" :value="locationType.name">{{ capitalCase(locationType.name) }}</option>
                        </b-select>
                      </transition>
                    </div>

                    <div class="sub-section section-details mar-t-lg">
                      <validated-input
                        name="dineInPickupInstructionMessageOverride"
                        label="Dine In Receipt Message"
                      >
                        <b-input
                          :disabled="!$can('update','StorePickupInstruction')"
                          :value="dineInPickupInstructions.pickupInstructionMessageOverride"
                          type="textarea"
                          placeholder="Enter a message to display on your Dine In receipts..."
                          @input="handlePickupInstructionInput(dineInPickupInstructions.id, $event)"
                        />
                      </validated-input>
                      <div class="order-validations is-grid col-2 gap-x-lg gap-y">
                        <validated-text-input
                          v-model="storeValidationForms[fulfillmentTypesByName.dineIn.id].orderMinimumAmount"
                          :disabled="!$can('update', 'StoreMappingValidation')"
                          name="orderMinimumDineIn"
                          label="Purchase Minimum"
                          type="dollars"
                          :rules="{
                            min_value: 0.01,
                            max_value: storeValidationForms[fulfillmentTypesByName.dineIn.id].orderMaximumAmount || false
                          }"
                        />

                        <validated-text-input
                          v-model="storeValidationForms[fulfillmentTypesByName.dineIn.id].orderMaximumAmount"
                          :disabled="!$can('update', 'StoreMappingValidation')"
                          name="orderMaximumDineIn"
                          label="Purchase Maximum"
                          type="dollars"
                          :rules="{
                            min_value: storeValidationForms[fulfillmentTypesByName.dineIn.id].orderMinimumAmount || 0.01,
                          }"
                        />
                        <validated-text-input
                          v-model="storeValidationForms[fulfillmentTypesByName.dineIn.id].orderMaximumItemCount"
                          :disabled="!$can('update', 'StoreMappingValidation')"
                          name="orderMaximumItemCountDineIn"
                          type="number"
                          label="Maximum Cart Quantity"
                          :rules="{
                            min_value: 0,
                          }"
                        />
                      </div>

                      <!-- TEMP Removing since WAP does not support this yet -->
                      <!-- <validated-text-input
                        v-model="storeValidationForms[fulfillmentTypesByName.dineIn.id].orderMinimumAmount"
                        name="dineInValidations"
                        label="Purchase Minimum"
                        :rules="{ min_value: 0.01 }"
                        type="dollars"
                      /> -->
                    </div>

                  </div>
                </transition>

              </section>
            </template>

            <!-- Pickup -->
            <template v-if="$_selectedMerchant.orderAheadEnabled">
              <section>
                <div class="mar-b-lg">
                  <h3 class="title is-4 mar-b-none">Pickup</h3>
                  <transition name="fade-down">
                    <div v-if="['TakeOut', 'Curbside', 'DriveThrough'].some(code => form.storeMappingAttributesByCode[code].isActive)">
                      <div class="is-flex align-center mar-t-xs mar-b">
                        <a class="link mar-r-md" :href="pickupDeliveryStoreLink" target="_blank">
                          {{ pickupDeliveryStoreLink }} <b-icon icon="external-link" />
                        </a>
                        <b-button size="is-small" icon-right="copy" type="is-primary is-light" @click="$_copyToClipboard(pickupDeliveryStoreLink)">
                          Copy
                        </b-button>
                      </div>

                      <validated-input
                        v-if="availablePickupOptions.length > 1"
                        label="Default Pickup Option"
                        name="defaultPickupOption"
                        rules="required"
                      >
                        <radio-button
                          v-for="({ display, value }) in availablePickupOptions"
                          :key="value"
                          v-model="defaultPickupOption"
                          name="defaultPickupOption"
                          :native-value="value"
                        >
                          {{ display }}
                        </radio-button>
                      </validated-input>
                    </div>
                  </transition>
                </div>

                <div class="dist-y-lg">

                  <!-- Pickup > Onsite -->
                  <div class="sub-section">
                    <b-switch
                      v-model="form.storeMappingAttributesByCode.TakeOut.isActive"
                      :disabled="!canUpdateOrderingMode(form.storeMappingAttributesByCode.TakeOut.storeAttributeId)"
                      class="left-label"
                    >
                      <span class="is-5 subtitle mar-r-sm">
                        Onsite Pickup
                      </span>
                    </b-switch>
                    <transition name="fade-zoom">
                      <div v-if="form.storeMappingAttributesByCode.TakeOut.isActive" class="mar-t-lg">
                        <b-field label="Onsite Receipt Message" class="is-marginless">
                          <div class="section-details">
                            <validated-input
                              name="onsitePickupInstructionMessageOverride"
                              label="Onsite Receipt Message"
                              hide-label
                            >
                              <b-input
                                :disabled="!$can('update','StorePickupInstruction')"
                                :value="onsitePickupInstructions.pickupInstructionMessageOverride"
                                type="textarea"
                                placeholder="Enter a message to display on your onsite pickup receipts..."
                                @input="handlePickupInstructionInput(onsitePickupInstructions.id, $event)"
                              />
                            </validated-input>
                            <div v-if="$_selectedMerchant.features.iAmHere">
                              <check-button
                                v-model="form.onSiteNotificationEnabled"
                                :disabled="!$can('update', 'StoreMapping', 'onSiteNotificationEnabled')"
                                compact
                                label="I Am Here"
                                sublabel="Enable this feature to allow customers to alert you of their arrival"
                              />
                            </div>
                            <div class="order-validations is-grid col-2 gap-x-lg gap-y">
                              <template v-if="$_selectedMerchant.features.menuPrices">
                                <validated-text-input
                                  v-model="storeValidationForms[fulfillmentTypesByName.takeout.id].orderMinimumAmount"
                                  :disabled="!$can('update','StoreMappingValidation')"
                                  name="orderMinimumTakeout"
                                  label="Purchase Minimum"
                                  type="dollars"
                                  :rules="{
                                    min_value: 0.01,
                                    max_value: storeValidationForms[fulfillmentTypesByName.takeout.id].orderMaximumAmount || false
                                  }"
                                />
                                <validated-text-input
                                  v-model="storeValidationForms[fulfillmentTypesByName.takeout.id].orderMaximumAmount"
                                  :disabled="!$can('update','StoreMappingValidation')"
                                  name="orderMaximumTakeout"
                                  type="dollars"
                                  label="Purchase Maximum"
                                  :rules="{
                                    min_value: storeValidationForms[fulfillmentTypesByName.takeout.id].orderMinimumAmount || 0.01,
                                  }"
                                />
                              </template>
                              <!-- TEMP Removing since WAP does not support this -->
                              <!-- <validated-text-input
                                v-model="storeValidationForms[fulfillmentTypesByName.takeout.id].orderMinimumItemCount"
                                :disabled="!$can('update','StoreMappingValidation')"
                                name="orderMinimumItemCount"
                                label="Minimum Cart Quantity"
                                type="number"
                                :rules="{
                                  min_value: 0
                                }"
                              /> -->
                              <validated-text-input
                                v-model="storeValidationForms[fulfillmentTypesByName.takeout.id].orderMaximumItemCount"
                                :disabled="!$can('update','StoreMappingValidation')"
                                name="orderMaximumItemCountTakeout"
                                type="number"
                                label="Maximum Cart Quantity"
                                :rules="{
                                  min_value: 0,
                                }"
                              />
                            </div>
                          </div>
                        </b-field>
                      </div>
                    </transition>
                  </div>

                  <!-- Pickup > Curbside -->
                  <div class="sub-section">
                    <b-switch
                      v-model="form.storeMappingAttributesByCode.Curbside.isActive"
                      :disabled="!canUpdateOrderingMode(form.storeMappingAttributesByCode.Curbside.storeAttributeId)"
                      class="left-label"
                    >
                      <span class="is-5 subtitle mar-r-sm">
                        Curbside Pickup
                      </span>
                    </b-switch>
                    <transition name="fade-zoom">
                      <div v-if="form.storeMappingAttributesByCode.Curbside.isActive" class="mar-t-lg">
                        <b-field label="Curbside Receipt Message" class="is-marginless">
                          <div class="section-details">
                            <validated-input
                              name="curbsidePickupInstructionMessageOverride"
                              label="Curbside Receipt Message"
                              hide-label
                            >
                              <b-input
                                :disabled="!$can('update','StorePickupInstruction')"
                                :value="curbsidePickupInstructions.pickupInstructionMessageOverride"
                                type="textarea"
                                placeholder="Enter a message to display on your curbside pickup receipts..."
                                @input="handlePickupInstructionInput(curbsidePickupInstructions.id, $event)"
                              />
                            </validated-input>
                            <div v-if="$_selectedMerchant.features.iAmHere">
                              <check-button
                                v-model="form.onSiteCurbsideNotificationEnabled"
                                :disabled="!$can('update', 'StoreMapping', 'onSiteCurbsideNotificationEnabled')"
                                compact
                                label="I Am Here"
                                sublabel="Enable this feature to allow customers to alert you of their arrival"
                              />
                            </div>
                            <div class="order-validations is-grid col-2 gap-x-lg gap-y">
                              <template v-if="$_selectedMerchant.features.menuPrices">
                                <validated-text-input
                                  v-model="storeValidationForms[fulfillmentTypesByName.curbside.id].orderMinimumAmount"
                                  :disabled="!$can('update','StoreMappingValidation')"
                                  name="orderMinimumCurbside"
                                  label="Purchase Minimum"
                                  type="dollars"
                                  :rules="{
                                    min_value: 0.01,
                                    max_value: storeValidationForms[fulfillmentTypesByName.curbside.id].orderMaximumAmount || false
                                  }"
                                />

                                <validated-text-input
                                  v-model="storeValidationForms[fulfillmentTypesByName.curbside.id].orderMaximumAmount"
                                  :disabled="!$can('update','StoreMappingValidation')"
                                  name="orderMaximumCurbside"
                                  label="Purchase Maximum"
                                  type="dollars"
                                  :rules="{
                                    min_value: storeValidationForms[fulfillmentTypesByName.curbside.id].orderMinimumAmount || 0.01,
                                  }"
                                />
                                <validated-text-input
                                  v-model="storeValidationForms[fulfillmentTypesByName.curbside.id].orderMaximumItemCount"
                                  :disabled="!$can('update', 'StoreMappingValidation')"
                                  name="orderMaximumItemCountCurbside"
                                  type="number"
                                  label="Maximum Cart Quantity"
                                  :rules="{
                                    min_value: 0,
                                  }"
                                />
                              </template>
                            </div>
                          </div>
                        </b-field>
                      </div>
                    </transition>
                  </div>

                  <!-- Pickup > Drive Through -->
                  <div class="sub-section">
                    <b-switch
                      v-model="form.storeMappingAttributesByCode.DriveThrough.isActive"
                      :disabled="!canUpdateOrderingMode(form.storeMappingAttributesByCode.DriveThrough.storeAttributeId)"
                      class="left-label"
                    >
                      <span class="is-5 subtitle mar-r-sm">
                        Drive Through Pickup / Pickup Window
                      </span>
                    </b-switch>
                    <transition name="fade-zoom">
                      <div v-if="form.storeMappingAttributesByCode.DriveThrough.isActive" class="mar-t-lg">
                        <b-field label="Drive Through Pickup / Pickup Window Receipt Message" class="is-marginless">
                          <div class="section-details">
                            <validated-input
                              name="driveThroughPickupInstructionMessageOverride"
                              label="Drive Through Pickup / Pickup Window Receipt Message"
                              hide-label
                            >
                              <b-input
                                :disabled="!$can('update','StorePickupInstruction')"
                                :value="driveThroughPickupInstructions.pickupInstructionMessageOverride"
                                type="textarea"
                                placeholder="Enter a message to display on your drive through pickup receipts..."
                                @input="handlePickupInstructionInput(driveThroughPickupInstructions.id, $event)"
                              />
                            </validated-input>
                            <div v-if="$_selectedMerchant.features.iAmHere">
                              <check-button
                                v-model="form.onSiteDriveThroughNotificationEnabled"
                                :disabled="!$can('update', 'StoreMapping', 'onSiteDriveThroughNotificationEnabled')"
                                compact
                                class="no-wrap-text"
                                label="I Am Here"
                              />
                              <p class="has-text-grey is-size-7">
                                Enable this feature to allow customers to alert you of their arrival
                              </p>
                            </div>
                            <div class="order-validations is-grid col-2 gap-x-lg gap-y">
                              <template v-if="$_selectedMerchant.features.menuPrices">
                                <validated-text-input
                                  v-model="storeValidationForms[fulfillmentTypesByName.driveThrough.id].orderMinimumAmount"
                                  :disabled="!$can('update','StoreMappingValidation')"
                                  name="orderMinimumDriveThrough"
                                  label="Purchase Minimum"
                                  type="dollars"
                                  :rules="{
                                    min_value: 0.01,
                                    max_value: storeValidationForms[fulfillmentTypesByName.driveThrough.id].orderMaximumAmount || false
                                  }"
                                />

                                <validated-text-input
                                  v-model="storeValidationForms[fulfillmentTypesByName.driveThrough.id].orderMaximumAmount"
                                  :disabled="!$can('update','StoreMappingValidation')"
                                  name="orderMaximumDriveThrough"
                                  label="Purchase Maximum"
                                  type="dollars"
                                  :rules="{
                                    min_value: storeValidationForms[fulfillmentTypesByName.driveThrough.id].orderMinimumAmount || 0.01,
                                  }"
                                />
                                <validated-text-input
                                  v-model="storeValidationForms[fulfillmentTypesByName.driveThrough.id].orderMaximumItemCount"
                                  :disabled="!$can('update', 'StoreMappingValidation')"
                                  name="orderMaximumItemCountDriveThrough"
                                  type="number"
                                  label="Maximum Cart Quantity"
                                  :rules="{
                                    min_value: 0,
                                  }"
                                />
                              </template>
                            </div>
                          </div>
                        </b-field>
                      </div>
                    </transition>
                  </div>
                </div>
              </section>
            </template>

            <!-- Delivery -->
            <template v-if="$_selectedMerchant.deliveryEnabled">
              <section>
                <b-switch
                  v-model="form.storeMappingAttributesByCode.Delivery.isActive"
                  :disabled="!canUpdateOrderingMode(form.storeMappingAttributesByCode.Delivery.storeAttributeId)"
                  class="left-label"
                  @input="handleDeliveryToggle"
                >
                  <span class="title is-4 mar-r-sm">
                    Delivery
                  </span>
                </b-switch>
                <transition name="fade-zoom">
                  <div v-if="form.storeMappingAttributesByCode.Delivery.isActive">
                    <template v-if="merchantDeliveryServices.length">
                      <div class="is-flex align-center mar-t-xs">
                        <a class="link mar-r-md" :href="deliveryLink" target="_blank">
                          {{ deliveryLink }} <b-icon icon="external-link" />
                        </a>
                        <b-button size="is-small" icon-right="copy" type="is-primary is-light" @click="$_copyToClipboard(deliveryLink)">
                          Copy
                        </b-button>
                      </div>

                      <div v-if="merchantDeliveryServices.length" class="sub-section section-details mar-t-lg">
                        <validated-input
                          name="deliveryPickupInstructionMessageOverride"
                          label="Delivery Receipt Message"
                        >
                          <b-input
                            :value="deliveryPickupInstructions.pickupInstructionMessageOverride"
                            :disabled="!$can('update','StorePickupInstruction')"
                            type="textarea"
                            placeholder="Enter a message to display on your delivery receipts..."
                            @input="handlePickupInstructionInput(deliveryPickupInstructions.id, $event)"
                          />
                        </validated-input>
                        <div class="order-validations is-grid col-2 gap-x-lg gap-y">
                          <template v-if="$_selectedMerchant.features.menuPrices">
                            <validated-text-input
                              v-model="storeValidationForms[fulfillmentTypesByName.delivery.id].orderMinimumAmount"
                              :disabled="!$can('update','StoreMappingValidation')"
                              name="orderMinimumDelivery"
                              class="mar-r-sm"
                              label="Purchase Minimum"
                              type="dollars"
                              :rules="{
                                min_value: 0.01,
                                max_value: storeValidationForms[fulfillmentTypesByName.delivery.id].orderMaximumAmount || false
                              }"
                            />
                            <validated-text-input
                              v-model="storeValidationForms[fulfillmentTypesByName.delivery.id].orderMaximumAmount"
                              :disabled="!$can('update','StoreMappingValidation')"
                              name="orderMaximumDelivery"
                              type="dollars"
                              label="Purchase Maximum"
                              :rules="{
                                min_value: storeValidationForms[fulfillmentTypesByName.delivery.id].orderMinimumAmount || 0.01,
                              }"
                            />
                            <validated-text-input
                              v-model="storeValidationForms[fulfillmentTypesByName.delivery.id].orderMaximumItemCount"
                              :disabled="!$can('update', 'StoreMappingValidation')"
                              name="orderMaximumItemCountDelivery"
                              type="number"
                              label="Maximum Cart Quantity"
                              :rules="{
                                min_value: 0,
                              }"
                            />
                          </template>
                        </div>
                      </div>
                    </template>

                    <store-delivery-services :store="store" class="mar-t-lg" />
                  </div>
                </transition>
              </section>
            </template>

            <!-- Room Service -->
            <template v-if="$_selectedMerchant.roomServiceEnabled">
              <section>
                <b-switch
                  v-model="form.storeMappingAttributesByCode.RoomService.isActive"
                  :disabled="!canUpdateOrderingMode(form.storeMappingAttributesByCode.RoomService.storeAttributeId)"
                  class="left-label"
                >
                  <span class="is-4 title mar-r-sm">
                    Room Service
                  </span>
                </b-switch>
                <transition name="fade-zoom">
                  <div v-if="form.storeMappingAttributesByCode.RoomService.isActive">
                    <div class="is-flex align-center mar-y-xs">
                      <a class="link mar-r-md" :href="roomServiceLink" target="_blank">
                        {{ roomServiceLink }} <b-icon icon="external-link" />
                      </a>
                      <b-button size="is-small" icon-right="copy" type="is-primary is-light" @click="$_copyToClipboard(roomServiceLink)">
                        Copy
                      </b-button>
                    </div>

                    <div class="url-inputs mar-b-xs" style="height: 30px">
                      <b-checkbox v-model="hasRoomNumber">
                        Include Room Number In Link
                      </b-checkbox>

                      <transition name="fade-right">
                        <b-input
                          v-show="hasRoomNumber"
                          v-model="roomNumber"
                          size="is-small"
                          icon-right="times-circle"
                          icon-right-clickable
                          @icon-right-click="roomNumber = ''"
                        />
                      </transition>
                    </div>

                    <div class="sub-section mar-t-lg">
                      <b-field label="Room Service Receipt Message">
                        <div class="section-details">
                          <validated-input
                            name="roomServicePickupInstruction"
                            label="Pickup Instructions"
                            hide-label
                          >
                            <b-input
                              :disabled="!$can('update','StorePickupInstruction')"
                              :value="roomServicePickupInstructions.pickupInstructionMessageOverride"
                              type="textarea"
                              placeholder="Enter a message to display on your room service receipts..."
                              @input="handlePickupInstructionInput(roomServicePickupInstructions.id, $event)"
                            />
                          </validated-input>
                          <div class="order-validations is-grid col-2 gap-x-lg gap-y">
                            <template v-if="$_selectedMerchant.features.menuPrices">
                              <validated-text-input
                                v-model="storeValidationForms[fulfillmentTypesByName.roomService.id].orderMinimumAmount"
                                :disabled="!$can('update','StoreMappingValidation')"
                                name="orderMinimumRoomService"
                                class="mar-r-sm"
                                label="Purchase Minimum"
                                type="dollars"
                                :rules="{
                                  min_value: 0.01,
                                  max_value: storeValidationForms[fulfillmentTypesByName.roomService.id].orderMaximumAmount || false
                                }"
                              />
                              <validated-text-input
                                v-model="storeValidationForms[fulfillmentTypesByName.roomService.id].orderMaximumAmount"
                                :disabled="!$can('update','StoreMappingValidation')"
                                name="orderMaximumRoomService"
                                type="dollars"
                                label="Purchase Maximum"
                                :rules="{
                                  min_value: storeValidationForms[fulfillmentTypesByName.roomService.id].orderMinimumAmount || 0.01,
                                }"
                              />
                              <validated-text-input
                                v-model="storeValidationForms[fulfillmentTypesByName.roomService.id].orderMaximumItemCount"
                                :disabled="!$can('update', 'StoreMappingValidation')"
                                name="orderMaximumItemCountRoomService"
                                type="number"
                                label="Maximum Cart Quantity"
                                :rules="{
                                  min_value: 0,
                                }"
                              />
                            </template>
                          </div>
                        </div>
                      </b-field>
                    </div>
                  </div>
                </transition>
              </section>
            </template>

            <!-- Shipping -->
            <template v-if="$_selectedMerchant.shippingEnabled">
              <section>
                <b-switch
                  v-model="form.storeMappingAttributesByCode.Shipping.isActive"
                  :disabled="!canUpdateOrderingMode(form.storeMappingAttributesByCode.Shipping.storeAttributeId)"
                  class="left-label"
                >
                  <span class="is-4 title mar-r-sm">
                    Shipping
                  </span>
                </b-switch>
                <transition name="fade-zoom">
                  <div v-if="form.storeMappingAttributesByCode.Shipping.isActive">
                    <div class="is-flex align-center mar-t-xs">
                      <a class="link mar-r-md" :href="shippingLink" target="_blank">
                        {{ shippingLink }} <b-icon icon="external-link" />
                      </a>
                      <b-button size="is-small" icon-right="copy" type="is-primary is-light" @click="$_copyToClipboard(shippingLink)">
                        Copy
                      </b-button>
                    </div>
                    <div class="sub-section mar-t-lg">
                      <b-field label="Shipping Receipt Message">
                        <div class="section-details">
                          <validated-input
                            name="roomServicePickupInstruction"
                            label="Receipt Message"
                            hide-label
                          >
                            <b-input
                              :disabled="!$can('update','StorePickupInstruction')"
                              :value="shippingPickupInstructions.pickupInstructionMessageOverride"
                              type="textarea"
                              placeholder="Enter a message to display on your shipping receipts..."
                              @input="handlePickupInstructionInput(shippingPickupInstructions.id, $event)"
                            />
                          </validated-input>
                          <div class="order-validations is-grid col-2 gap-x-lg gap-y">
                            <template v-if="$_selectedMerchant.features.menuPrices">
                              <validated-text-input
                                v-model="storeValidationForms[fulfillmentTypesByName.shipping.id].orderMinimumAmount"
                                :disabled="!$can('update','StoreMappingValidation')"
                                name="orderMinimumShipping"
                                class="mar-r-sm"
                                label="Purchase Minimum"
                                type="dollars"
                                :rules="{
                                  min_value: 0.01,
                                  max_value: storeValidationForms[fulfillmentTypesByName.shipping.id].orderMaximumAmount || false
                                }"
                              />
                              <validated-text-input
                                v-model="storeValidationForms[fulfillmentTypesByName.shipping.id].orderMaximumAmount"
                                :disabled="!$can('update','StoreMappingValidation')"
                                name="orderMaximumShipping"
                                type="dollars"
                                label="Purchase Maximum"
                                :rules="{
                                  min_value: storeValidationForms[fulfillmentTypesByName.shipping.id].orderMinimumAmount || 0.01,
                                }"
                              />
                              <validated-text-input
                                v-model="storeValidationForms[fulfillmentTypesByName.shipping.id].orderMaximumItemCount"
                                :disabled="!$can('update', 'StoreMappingValidation')"
                                name="orderMaximumItemCountShipping"
                                type="number"
                                label="Maximum Cart Quantity"
                                :rules="{
                                  min_value: 0,
                                }"
                              />
                            </template>
                          </div>
                        </div>
                      </b-field>
                    </div>
                  </div>
                </transition>
              </section>
            </template>

            <!-- Kiosk -->
            <template v-if="$_selectedMerchant.kioskEnabled">
              <section>
                <b-switch
                  v-model="form.storeMappingAttributesByCode.KioskOrdering.isActive"
                  :disabled="!canUpdateOrderingMode(form.storeMappingAttributesByCode.KioskOrdering.storeAttributeId)"
                  class="left-label"
                >
                  <span class="is-4 title mar-r-sm">
                    Kiosk
                  </span>
                </b-switch>
                <transition name="fade-zoom">
                  <div v-if="form.storeMappingAttributesByCode.KioskOrdering.isActive" class="sub-section mar-t-lg">
                    <b-field label="Kiosk Dine In Receipt Message">
                      <div class="section-details">
                        <validated-input
                          name="kioskDineInPickupInstruction"
                          label="Kiosk Dine In Receipt Message"
                          hide-label
                        >
                          <b-input
                            :disabled="!$can('update','StorePickupInstruction')"
                            :value="kioskDineInInstructions.pickupInstructionMessageOverride"
                            type="textarea"
                            placeholder="Enter a message to display on your kiosk dine-in receipts..."
                            @input="handlePickupInstructionInput(kioskDineInInstructions.id, $event)"
                          />
                        </validated-input>
                      </div>
                    </b-field>

                    <b-field label="Kiosk Pick Up Receipt Message">
                      <div class="section-details">
                        <validated-input
                          name="kioskPickupInstruction"
                          label="Kiosk Pick Up Receipt Message"
                          hide-label
                        >
                          <b-input
                            :disabled="!$can('update','StorePickupInstruction')"
                            :value="kioskPickupInstructions.pickupInstructionMessageOverride"
                            type="textarea"
                            placeholder="Enter a message to display on your kiosk pickup receipts..."
                            @input="handlePickupInstructionInput(kioskPickupInstructions.id, $event)"
                          />
                        </validated-input>
                      </div>
                    </b-field>

                    <div class="order-validations is-grid col-2 gap-x-lg gap-y">
                      <template v-if="$_selectedMerchant.features.menuPrices">
                        <validated-text-input
                          v-model="storeValidationForms[`${fulfillmentTypesByName.takeout.id}-${menuTypesByName.kiosk.id}`].orderMinimumAmount"
                          :disabled="!canUpdateOrderingMode(form.storeMappingAttributesByCode.KioskOrdering.storeAttributeId)"
                          name="orderMinimumKiosk"
                          class="mar-r-sm"
                          label="Purchase Minimum"
                          type="dollars"
                          :rules="{
                            min_value: 0.01,
                            max_value: storeValidationForms[`${fulfillmentTypesByName.takeout.id}-${menuTypesByName.kiosk.id}`].orderMaximumAmount || false
                          }"
                        />
                        <validated-text-input
                          v-model="storeValidationForms[`${fulfillmentTypesByName.takeout.id}-${menuTypesByName.kiosk.id}`].orderMaximumAmount"
                          :disabled="!$can('update','StoreMappingValidation')"
                          name="orderMaximumKiosk"
                          type="dollars"
                          label="Purchase Maximum"
                          :rules="{
                            min_value: storeValidationForms[`${fulfillmentTypesByName.takeout.id}-${menuTypesByName.kiosk.id}`].orderMinimumAmount || 0.01,
                          }"
                        />
                        <validated-text-input
                          v-model="storeValidationForms[`${fulfillmentTypesByName.takeout.id}-${menuTypesByName.kiosk.id}`].orderMaximumItemCount"
                          :disabled="!$can('update', 'StoreMappingValidation')"
                          name="orderMaximumItemCountKiosk"
                          type="number"
                          label="Maximum Cart Quantity"
                          :rules="{
                            min_value: 0,
                          }"
                        />
                      </template>
                    </div>
                  </div>
                </transition>
              </section>
            </template>

            <!-- Catering -->
            <template v-if="$_selectedMerchant.cateringEnabled && hasCateringMenu">
              <section>
                <b-switch
                  v-model="form.storeMappingAttributesByCode.CateringOrdering.isActive"
                  :disabled="!canUpdateOrderingMode(form.storeMappingAttributesByCode.CateringOrdering.storeAttributeId)"
                  class="left-label"
                  @input="handleCateringOrderingToggle"
                >
                  <span class="is-4 title mar-r-sm">
                    Catering
                  </span>
                </b-switch>
                <transition name="fade-zoom">
                  <div v-if="form.storeMappingAttributesByCode.CateringOrdering.isActive" class="dist-y-sm mar-t-sm">
                    <div class="is-flex align-center">
                      <a class="link mar-r-md" :href="cateringLink" target="_blank">
                        {{ cateringLink }} <b-icon icon="external-link" />
                      </a>
                      <b-button size="is-small" icon-right="copy" type="is-primary is-light" @click="$_copyToClipboard(cateringLink)">
                        Copy
                      </b-button>
                    </div>
                    <div class="sub-section">
                      <b-field label="Catering Receipt Message">
                        <div class="section-details">
                          <validated-input
                            name="cateringPickupInstruction"
                            label="Receipt Message"
                            hide-label
                          >
                            <b-input
                              :disabled="!$can('update','StorePickupInstruction')"
                              :value="cateringPickupInstructions.pickupInstructionMessageOverride"
                              type="textarea"
                              placeholder="Enter a message to display on your catering receipts..."
                              @input="handlePickupInstructionInput(cateringPickupInstructions.id, $event)"
                            />
                          </validated-input>
                          <div>
                            <check-button
                              v-model="form.storeMappingAttributesByCode.CateringDelivery.isActive"
                              :disabled="!$can('update', 'StoreMapping', 'onSiteNotificationEnabled')"
                              compact
                              label="Catering Delivery"
                              sublabel="Enable at this location and manage delivery settings in the 'Delivery' panel above"
                            />
                          </div>
                          <div class="order-validations is-grid col-2 gap-x-lg gap-y">
                            <template v-if="$_selectedMerchant.features.menuPrices">
                              <validated-text-input
                                v-model="storeValidationForms[`${fulfillmentTypesByName.takeout.id}-${menuTypesByName.catering.id}`].orderMinimumAmount"
                                :disabled="!canUpdateOrderingMode(form.storeMappingAttributesByCode.CateringOrdering.storeAttributeId)"
                                name="orderMinimumCatering"
                                class="mar-r-sm"
                                label="Purchase Minimum"
                                type="dollars"
                                :rules="{
                                  min_value: 0.01,
                                  max_value: storeValidationForms[`${fulfillmentTypesByName.takeout.id}-${menuTypesByName.catering.id}`].orderMaximumAmount || false
                                }"
                              />
                              <validated-text-input
                                v-model="storeValidationForms[`${fulfillmentTypesByName.takeout.id}-${menuTypesByName.catering.id}`].orderMaximumAmount"
                                :disabled="!$can('update','StoreMappingValidation')"
                                name="orderMaximumCatering"
                                type="dollars"
                                label="Purchase Maximum"
                                :rules="{
                                  min_value: storeValidationForms[`${fulfillmentTypesByName.takeout.id}-${menuTypesByName.catering.id}`].orderMinimumAmount || 0.01,
                                }"
                              />
                              <validated-text-input
                                v-model="storeValidationForms[`${fulfillmentTypesByName.takeout.id}-${menuTypesByName.catering.id}`].orderMaximumItemCount"
                                :disabled="!$can('update', 'StoreMappingValidation')"
                                name="orderMaximumItemCountCatering"
                                type="number"
                                label="Maximum Cart Quantity"
                                :rules="{
                                  min_value: 0,
                                }"
                              />
                            </template>
                          </div>
                        </div>
                      </b-field>
                    </div>
                  </div>
                </transition>
              </section>
            </template>
          </div>

          <!-- Save -->
          <template v-if="$can('update','StoreMappingValidation')">
            <div class="footer-buttons">
              <b-button
                native-type="submit"
                :loading="isSubmitting"
                type="is-primary"
                rounded
                size="is-medium"
                :class="['is-bold', dirty && 'pulse-slow']"
              >
                Save
              </b-button>
            </div>
          </template>
        </div>
      </div>
    </validated-form>
  </transition>
</template>

<script>
  // Classes
  import Store from '@/store/classes/Store';
  import StorePickupInstruction from '@/store/classes/StorePickupInstruction';
  import MerchantDeliveryService from '@/store/classes/MerchantDeliveryService';
  import StoreDeliveryService from '@/store/classes/StoreDeliveryService';
  import FulfillmentType from '@/store/classes/FulfillmentType';
  import MenuType from '@/store/classes/MenuType';
  import LocationType from '@/store/classes/LocationType';
  import StoreAttribute from '@/store/classes/StoreAttribute';
  import StoreMappingValidation from '@/store/classes/StoreMappingValidation';
  import PlatformType from '@/store/classes/PlatformType';
  import MerchantMenuTypeConfiguration from '@/store/classes/MerchantMenuTypeConfiguration';


  // Components
  import storeDeliveryServices from './store-delivery-services.vue';

  // Mixins
  import merchantMixin from '@/mixins/merchant';
  import copyToClipboard from '@/mixins/copy-to-clipboard';


  // Helpers
  import capitalCase from '@/helpers/capitalCase';
  import { subject } from '@casl/ability';



  export default {
    name: 'StoreOrderingModes',

    components: { storeDeliveryServices },

    mixins: [merchantMixin, copyToClipboard],

    props: {
      store: {
        type: Object,
        required: true
      }
    },

    data() {
      return {
        form: null,
        isSubmitting: false,
        tableNumber: null,
        roomNumber: null,
        hasTableNumber: false,
        hasRoomNumber: false,
        storeValidationForms: {},
        selectedLocationType: 'Table',
        hasLocationType: false
      };
    },

    computed: {
      availablePickupOptions() {
        return [
          {
            display: 'Onsite',
            value: 'onsite',
            isActive: this.form?.storeMappingAttributesByCode?.TakeOut?.isActive
          },
          {
            display: 'Curbside',
            value: 'curbside',
            isActive: this.form?.storeMappingAttributesByCode?.Curbside?.isActive
          },
          {
            display: 'Drive Through / Window',
            value: 'driveThrough',
            isActive: this.form?.storeMappingAttributesByCode?.DriveThrough?.isActive
          }
        ].filter(x => x.isActive);
      },

      defaultPickupOption: {
        get() {
          switch (true) {
            case this.form?.storeMappingAttributesByCode?.DefaultToCurbsidePickup?.isActive:
              return 'curbside';
            case this.form?.storeMappingAttributesByCode?.DefaultToDriveThroughPickup?.isActive:
              return 'driveThrough';
            case this.form?.storeMappingAttributesByCode?.TakeOut?.isActive:
              return 'onsite';
            default:
              return undefined;
          }
        },
        set(val) {
          const { DefaultToCurbsidePickup, DefaultToDriveThroughPickup } = this.form.storeMappingAttributesByCode;

          switch (val) {
            case 'curbside':
              this.$set(DefaultToCurbsidePickup, 'isActive', true);
              if (DefaultToDriveThroughPickup?.isActive) {
                this.$set(DefaultToDriveThroughPickup, 'isActive', false);
              }
              break;
            case 'driveThrough':
              this.$set(DefaultToDriveThroughPickup, 'isActive', true);
              if (DefaultToCurbsidePickup?.isActive) {
                this.$set(DefaultToCurbsidePickup, 'isActive', false);
              }
              break;
            default:
              if (DefaultToDriveThroughPickup?.isActive) {
                this.$set(DefaultToDriveThroughPickup, 'isActive', false);
              }
              if (DefaultToCurbsidePickup?.isActive) {
                this.$set(DefaultToCurbsidePickup, 'isActive', false);
              }
          }
        }
      },

      storeAttributes() {
        return StoreAttribute.backfilledValues(this.store.storeId);
      },

      fulfillmentTypesByName() {
        return FulfillmentType.byName();
      },

      platformTypesByName() {
        return PlatformType.byName();
      },

      locationTypes() {
        return LocationType.all();
      },

      menuTypesByName() {
        return MenuType.byName();
      },

      hasCateringMenu() {
        const cateringMenuTypeId = this.menuTypesByName.catering.id;
        return !!MerchantMenuTypeConfiguration.getMenuTypeConfig({
          merchantId: this.$_selectedMerchantId,
          menuTypeId: cateringMenuTypeId
        });
      },

      merchantDeliveryServices() {
        return MerchantDeliveryService.all();
      },

      savedStoreDeliveryServices() {
        return StoreDeliveryService.getSaved().where('storeId', this.store.storeId).get();
      },

      unsavedStoreDeliveryServices() {
        return StoreDeliveryService.getUnsaved().where('storeId', this.store.storeId).get();
      },

      dineInPickupInstructions() {
        return StorePickupInstruction.query()
          .where('storeId', this.store.storeId)
          .where('fulfillmentTypeId', this.fulfillmentTypesByName.dineIn.id)
          .first() || {};
      },

      onsitePickupInstructions() {
        return StorePickupInstruction.query()
          .where('storeId', this.store.storeId)
          .where('fulfillmentTypeId', this.fulfillmentTypesByName.takeout.id)
          .first() || {};
      },

      curbsidePickupInstructions() {
        return StorePickupInstruction.query()
          .where('storeId', this.store.storeId)
          .where('fulfillmentTypeId', this.fulfillmentTypesByName.curbside.id)
          .first() || {};
      },

      driveThroughPickupInstructions() {
        return StorePickupInstruction.query()
          .where('storeId', this.store.storeId)
          .where('fulfillmentTypeId', this.fulfillmentTypesByName.driveThrough.id)
          .first() || {};
      },

      deliveryPickupInstructions() {
        return StorePickupInstruction.query()
          .where('storeId', this.store.storeId)
          .where('fulfillmentTypeId', this.fulfillmentTypesByName.delivery.id)
          .first() || {};
      },

      roomServicePickupInstructions() {
        return StorePickupInstruction.query()
          .where('storeId', this.store.storeId)
          .where('fulfillmentTypeId', this.fulfillmentTypesByName.roomService.id)
          .first() || {};
      },

      shippingPickupInstructions() {
        return StorePickupInstruction.query()
          .where('storeId', this.store.storeId)
          .where('fulfillmentTypeId', this.fulfillmentTypesByName.shipping.id)
          .first() || {};
      },

      kioskPickupInstructions() {
        return StorePickupInstruction.query()
          .where('storeId', this.store.storeId)
          .where('platformTypeId', this.platformTypesByName.kiosk.id)
          .where('fulfillmentTypeId', this.fulfillmentTypesByName.takeout.id)
          .first() || {};
      },

      kioskDineInInstructions() {
        return StorePickupInstruction.query()
          .where('storeId', this.store.storeId)
          .where('platformTypeId', this.platformTypesByName.kiosk.id)
          .where('fulfillmentTypeId', this.fulfillmentTypesByName.dineIn.id)
          .first() || {};
      },

      cateringPickupInstructions() {
        return StorePickupInstruction.query()
          .where('storeId', this.store.storeId)
          .where('menuTypeId', this.menuTypesByName.catering.id)
          .first() || {};
      },

      pickupDeliveryStoreLink() {
        return `${this.$_selectedMerchant.baseUrl}${this.$_selectedMerchant.friendlyUriName}/start-order?ml=${this.store.merchantLocationId}`;
      },

      deliveryLink() {
        return `${this.pickupDeliveryStoreLink}&ft=delivery`;
      },

      shippingLink() {
        return `${this.pickupDeliveryStoreLink}&ft=shipping`;
      },

      cateringLink() {
        return `${this.pickupDeliveryStoreLink}&mt=catering`;
      },

      orderAheadAtTableLink() {
        return `${this.pickupDeliveryStoreLink}&ft=dine-in`;
      },

      orderAtTableLink() {
        return `${this.$_selectedMerchant.baseUrl}${this.$_selectedMerchant.friendlyUriName}/order-at-table/menu?ml=${this.store.merchantLocationId}`;
      },

      dineInLink() {
        let link = this.$_selectedMerchant.oatEnabled
          ? this.orderAtTableLink
          : this.orderAheadAtTableLink;

        if (this.hasTableNumber && this.tableNumber) {
          link += `&tb=${this.tableNumber}`;
        }

        if (this.hasLocationType && this.selectedLocationType) {
          link += `&lt=${this.selectedLocationType}`;
        }

        return link;
      },

      roomServiceLink() {
        let link = `${this.pickupDeliveryStoreLink}&ft=room-service&lt=room`;

        if (this.hasRoomNumber && this.roomNumber) {
          link += `&tb=${this.roomNumber}`;
        }

        return link;
      },

      storeMappingValidations() {
        return StoreMappingValidation.query().where('storeId', this.store.storeId).get();
      },

      supportedCompositeIdsForValidation() {
        // StoreMappingValidations always require a fulfillmentTypeId but menuTypeId is optional
        return [
          [this.fulfillmentTypesByName.dineIn.id, null],
          [this.fulfillmentTypesByName.delivery.id, null],
          [this.fulfillmentTypesByName.takeout.id, null],
          [this.fulfillmentTypesByName.takeout.id, this.menuTypesByName.kiosk.id],
          [this.fulfillmentTypesByName.roomService.id, null],
          [this.fulfillmentTypesByName.curbside.id, null],
          [this.fulfillmentTypesByName.driveThrough.id, null],
          [this.fulfillmentTypesByName.shipping.id, null],
          [this.fulfillmentTypesByName.takeout.id, this.menuTypesByName.catering.id]
        ];
      }
    },

    watch: {
      availablePickupOptions: {
        handler(newVal) {
          if (newVal.length === 1) {
            this.defaultPickupOption = this.availablePickupOptions[0].value;
          }
        }
      },
      'form.storeMappingAttributesByCode.Curbside.isActive': {
        handler(newVal) {
          if (!newVal && this.form.storeMappingAttributesByCode.DefaultToCurbsidePickup?.isActive) {
            this.$set(this.form.storeMappingAttributesByCode.DefaultToCurbsidePickup, 'isActive', false);
          }
        }
      },
      'form.storeMappingAttributesByCode.DriveThrough.isActive': {
        handler(newVal) {
          if (!newVal && this.form.storeMappingAttributesByCode.DefaultToDriveThroughPickup?.isActive) {
            this.$set(this.form.storeMappingAttributesByCode.DefaultToDriveThroughPickup, 'isActive', false);
          }
        }
      }
    },

    async created() {
      await this.onCreated();
    },

    methods: {
      capitalCase,
      async onCreated() {
        try {
          await Promise.all([
            StoreAttribute.fetchStoreAttributes(),
            FulfillmentType.fetchFulfillmentTypes(),
            StoreMappingValidation.fetchStoreMappingValidations(this.store.storeId),
            PlatformType.fetchPlatformTypes(),
            MenuType.fetchMenuTypes(),
            LocationType.fetchLocationsTypes(),
            MerchantMenuTypeConfiguration.fetchMerchantMenuTypeConfigurations()
          ]);


          if (!Object.keys(this.dineInPickupInstructions).length) {
            this.createNewStorePickupInstructions(this.fulfillmentTypesByName.dineIn.id);
          }

          if (!Object.keys(this.onsitePickupInstructions).length) {
            this.createNewStorePickupInstructions(this.fulfillmentTypesByName.takeout.id);
          }

          if (!Object.keys(this.curbsidePickupInstructions).length) {
            this.createNewStorePickupInstructions(this.fulfillmentTypesByName.curbside.id);
          }

          if (!Object.keys(this.driveThroughPickupInstructions).length) {
            this.createNewStorePickupInstructions(this.fulfillmentTypesByName.driveThrough.id);
          }

          if (!Object.keys(this.deliveryPickupInstructions).length) {
            this.createNewStorePickupInstructions(this.fulfillmentTypesByName.delivery.id);
          }

          if (!Object.keys(this.roomServicePickupInstructions).length) {
            this.createNewStorePickupInstructions(this.fulfillmentTypesByName.roomService.id);
          }

          if (!Object.keys(this.shippingPickupInstructions).length) {
            this.createNewStorePickupInstructions(this.fulfillmentTypesByName.shipping.id);
          }

          if (!Object.keys(this.kioskPickupInstructions).length) {
            this.createNewStorePickupInstructions(this.fulfillmentTypesByName.takeout.id, this.platformTypesByName.kiosk.id);
          }

          if (!Object.keys(this.kioskDineInInstructions).length) {
            this.createNewStorePickupInstructions(this.fulfillmentTypesByName.dineIn.id, this.platformTypesByName.kiosk.id);
          }

          if (!Object.keys(this.cateringPickupInstructions).length) {
            this.createNewStorePickupInstructions(this.fulfillmentTypesByName.takeout.id, null, this.menuTypesByName.catering.id);
          }

          const storeMappingAttributesByCode = [
            ...this.store.storeMappingAttributes,
            ...this.storeAttributes
          ].reduce((obj, attr) => {
            if (!obj[attr.code]) {
              obj[attr.code] = attr;
            }
            return obj;
          }, {});

          this.form = this.$clone({
            storeMappingAttributesByCode,
            onSiteNotificationEnabled: this.store.onSiteNotificationEnabled,
            onSiteCurbsideNotificationEnabled: this.store.onSiteCurbsideNotificationEnabled,
            onSiteDriveThroughNotificationEnabled: this.store.onSiteDriveThroughNotificationEnabled
          });

          this.setStoreValidationForms();
        }
        catch (error) {
          this.$_onRequestError({
            toastOptions: {
              message: 'An error occured'
            },
            error
          });
        }
      },

      setStoreValidationForms() {
        this.supportedCompositeIdsForValidation.forEach(([fulfillmentTypeId, menuTypeId]) => {
          const storeMappingValidation = this.storeMappingValidations.find(smv => smv.fulfillmentTypeId === fulfillmentTypeId && smv.menuTypeId === menuTypeId);
          const key = [fulfillmentTypeId, menuTypeId].filter(Boolean).join('-');

          this.storeValidationForms[key] = storeMappingValidation || new StoreMappingValidation({
            fulfillmentTypeId,
            menuTypeId,
            storeId: this.store.storeId
          });
        });
      },

      createNewStorePickupInstructions(fulfillmentTypeId, platformTypeId, menuTypeId) {
        const newRecord = new StorePickupInstruction({
          storeId: this.store.storeId,
          fulfillmentTypeId,
          platformTypeId,
          pickupInstructionMessageOverride: '',
          menuTypeId,
          isSaved: false
        });
        newRecord.$save();
      },

      handleDeliveryToggle(isActive) {
        const onlyOneMerchantDeliveryService = this.merchantDeliveryServices.length === 1;
        const storeHasNoDeliveryServices = [
          ...this.savedStoreDeliveryServices,
          ...this.unsavedStoreDeliveryServices
        ].length === 0;

        if (isActive && onlyOneMerchantDeliveryService && storeHasNoDeliveryServices) {
          new StoreDeliveryService({
            storeId: this.store.storeId,
            deliveryServiceId: this.merchantDeliveryServices[0].deliveryServiceId,
            isSaved: false
          }).$save();
        }
      },

      handleCateringOrderingToggle(isActive) {
        if (!isActive) {
          this.form.storeMappingAttributesByCode.CateringDelivery.isActive = false;
        }
      },

      handlePickupInstructionInput(id, pickupInstructionMessageOverride) {
        StorePickupInstruction.update({
          where: id,
          data: { pickupInstructionMessageOverride }
        });
      },

      isFulfillmentTypeAttrActive(fulfillmentTypeId) {
        const map = {
          [this.fulfillmentTypesByName.dineIn.id]: this.form.storeMappingAttributesByCode.DineIn.isActive,
          [this.fulfillmentTypesByName.takeout.id]: this.form.storeMappingAttributesByCode.TakeOut.isActive,
          [this.fulfillmentTypesByName.curbside.id]: this.form.storeMappingAttributesByCode.Curbside.isActive,
          [this.fulfillmentTypesByName.driveThrough.id]: this.form.storeMappingAttributesByCode.DriveThrough.isActive,
          [this.fulfillmentTypesByName.delivery.id]: this.form.storeMappingAttributesByCode.Delivery.isActive,
          [this.fulfillmentTypesByName.roomService.id]: this.form.storeMappingAttributesByCode.RoomService.isActive,
          [this.fulfillmentTypesByName.shipping.id]: this.form.storeMappingAttributesByCode.Shipping.isActive
        };
        return map[fulfillmentTypeId];
      },

      isPlatformTypeAttrActive(platformTypeId) {
        const map = {
          [this.platformTypesByName.kiosk.id]: this.form.storeMappingAttributesByCode.KioskOrdering.isActive
        };
        return map[platformTypeId];
      },

      isMenuTypeActive(menuTypeId) {
        const map = {
          [this.menuTypesByName.kiosk.id]: this.form.storeMappingAttributesByCode.KioskOrdering.isActive,
          [this.menuTypesByName.catering.id]: this.form.storeMappingAttributesByCode.CateringOrdering.isActive
        };
        return map[menuTypeId];
      },

      canUpdateOrderingMode(storeAttributeId) {
        return this.$can(
          'update',
          subject('StoreMappingAttribute', { storeId: this.store?.storeId, storeAttributeId })
        );
      },

      storeRequest() {
        const cannotUpdateOnsite = !this.$can('update', 'StoreMapping', 'onSiteNotificationEnabled');
        if (cannotUpdateOnsite) {
          return Store.updateStore({
            storeId: this.store.storeId,
            storeMappingAttributes: Object.values(this.form.storeMappingAttributesByCode)
              .filter(attr => attr.isActive !== undefined)
          });
        }
        return Store.updateStore({
          storeId: this.store.storeId,
          onSiteNotificationEnabled: this.form.onSiteNotificationEnabled,
          onSiteCurbsideNotificationEnabled: this.form.onSiteCurbsideNotificationEnabled,
          onSiteDriveThroughNotificationEnabled: this.form.onSiteDriveThroughNotificationEnabled,
          storeMappingAttributes: Object.values(this.form.storeMappingAttributesByCode)
            .filter(attr => attr.isActive !== undefined)
        });
      },

      storePickupInstructionRequests() {
        const pickupInstructionsByAction = [
          this.dineInPickupInstructions,
          this.onsitePickupInstructions,
          this.curbsidePickupInstructions,
          this.driveThroughPickupInstructions,
          this.deliveryPickupInstructions,
          this.roomServicePickupInstructions,
          this.shippingPickupInstructions,
          this.kioskPickupInstructions,
          this.kioskDineInInstructions,
          this.cateringPickupInstructions
        ].reduce((obj, spi) => {
          const isKioskActive = spi.platformTypeId && this.isPlatformTypeAttrActive(spi.platformTypeId);
          const isFulfillmentTypeActive = spi.fulfillmentTypeId && this.isFulfillmentTypeAttrActive(spi.fulfillmentTypeId);
          // NOTE: accounting for catering pickup instruction which will be fulfillment type takeout and menutype catering (no other pickup instructions have menu type)
          const shouldSave = (!spi.menuTypeId && (isKioskActive || isFulfillmentTypeActive)) || (spi.menuTypeId && this.isMenuTypeActive(spi.menuTypeId));
          if (shouldSave) {
            if (spi.isSaved) obj.update.push(spi);
            else obj.add.push(spi);
          }

          return obj;
        }, { add: [], update: [] });

        return [
          !!pickupInstructionsByAction.add.length
            && StorePickupInstruction.addStorePickupInstructions({
              storeId: this.store.storeId,
              storePickupInstructions: pickupInstructionsByAction.add
            }),
          !!pickupInstructionsByAction.update.length
            && StorePickupInstruction.updateStorePickupInstructions({
              storeId: this.store.storeId,
              storePickupInstructions: pickupInstructionsByAction.update
            })
        ].filter(x => x);
      },

      storeDeliveryServiceRequests() {
        return [
          ...this.unsavedStoreDeliveryServices.map(
            sds => StoreDeliveryService.addStoreDeliveryService(sds)
          ),
          ...this.savedStoreDeliveryServices.map(
            sds => StoreDeliveryService.updateStoreDeliveryService(sds)
          )
        ];
      },

      storeMappingValidationRequests() {
        const requests = this.supportedCompositeIdsForValidation.reduce((acc, [fulfillmentTypeId, menuTypeId]) => {
          const key = [fulfillmentTypeId, menuTypeId].filter(Boolean).join('-');
          const storeMappingValidation = this.storeValidationForms[key];
          const isActive = this.isFulfillmentTypeAttrActive(fulfillmentTypeId) && (!menuTypeId || this.isMenuTypeActive(menuTypeId));

          if (isActive) {
            if (!storeMappingValidation.id) {
              acc.add.push(StoreMappingValidation.addStoreMappingValidation(storeMappingValidation));
            }
            else {
              acc.update.push(StoreMappingValidation.updateStoreMappingValidation(storeMappingValidation));
            }
          }

          return acc;
        }, { add: [], update: [] });

        return Object.values(requests).flatMap(x => x);
      },

      async submit() {
        try {
          this.isSubmitting = true;

          const storeMappingValidations = this.$can('update', 'StoreMappingValidation') ? this.storeMappingValidationRequests() : [];
          const storeDeliveryServiceRequests = this.$can('update', 'StoreDeliveryService') ? this.storeDeliveryServiceRequests() : [];
          const storePickupInstructionRequests = this.$can('update', 'StorePickupInstruction') ? this.storePickupInstructionRequests() : [];
          await Promise.all([
            this.storeRequest(),
            ...storePickupInstructionRequests,
            ...storeDeliveryServiceRequests,
            ...storeMappingValidations
          ]);

          this.setStoreValidationForms();

          this.$_onRequestSuccess({
            toastOptions: {
              message: 'Ordering Modes successfully updated!'
            }
          });
        }
        catch (error) {
          this.$_onRequestError({
            toastOptions: {
              message: 'There was an issue updating your Ordering Modes'
            },
            error
          });
        }
        finally {
          this.isSubmitting = false;
        }
      }
    }
  };
</script>

<style lang='sass' scoped>
  .order-mode-sections
    > section:not(:only-child)
      &:not(:last-child)
        border-bottom: 2px solid $white-ter
        padding-bottom: 2rem
        margin-bottom: 2rem

  .sub-section
    border-radius: $radius
    background-color: $white-bis
    padding: 1rem
    border: 1px solid $grey-lightest

  .section-details
    display: grid
    gap: $size-normal $size-large
    grid-template-columns: 2fr minmax(10rem, 1fr)

  .order-validations
      grid-row-start: 2

      ::v-deep input
        border-radius: $radius !important

  .url-inputs
    display: grid
    grid-template-columns: 270px 150px
    column-gap: $size-extra-small

    ::v-deep
      .select
        width: 100% !important

        select
          width: 100% !important

  .location-type
    ::v-deep select
      border-radius: 0 !important

  // allows switch labels to remain full opacity as "titles" while the switch itself is disabled
  ::v-deep.switch[disabled]
      opacity: 1
      .check
        opacity: 0.5

  @media (max-width: $tablet)
    .section-details
      grid-template-columns: 1fr
</style>
