<template>
  <img
    v-if="paymentType === 'applepay'"
    class="image is-inline"
    style="height: 30px"
    src="/images/payments/apple-pay-mark.svg"
    alt="Apple Pay"
    title="Apple Pay"
  >
  <img
    v-else-if="paymentType === 'googlepay'"
    class="image is-inline"
    style="height: 30px"
    src="/images/payments/google-pay-mark.svg"
    alt="Google Pay"
    title="Google Pay"
  >
  <img
    v-else-if="cardType === 'visa'"
    class="image is-inline"
    style="height: 30px"
    src="/images/payments/cards/visa.svg"
    alt="Visa"
    title="Visa"
  >
  <img
    v-else-if="['amex', 'american-express'].some(type => cardType === type)"
    class="image is-inline"
    style="height: 30px"
    src="/images/payments/cards/american-express.svg"
    alt="American Express"
    title="American Express"
  >
  <img
    v-else-if="['mc', 'mastercard'].some(type => cardType === type)"
    class="image is-inline"
    style="height: 30px"
    src="/images/payments/cards/mastercard.svg"
    alt="Master Card"
    title="Master Card"
  >
  <img
    v-else-if="['discover', 'disc'].some(type => cardType === type)"
    class="image is-inline"
    style="height: 30px"
    src="/images/payments/cards/discover.svg"
    alt="Discover"
    title="Discover"
  >
  <img
    v-else-if="cardType === 'diners-club'"
    class="image is-inline"
    style="height: 30px"
    src="/images/payments/cards/diners-club.svg"
    alt="Diners Club"
    title="Diners Club"
  >
  <img
    v-else-if="cardType === 'jcb'"
    class="image is-inline"
    style="height: 30px"
    src="/images/payments/cards/jcb.svg"
    alt="JCB"
    title="JCB"
  >
  <img
    v-else-if="cardType === 'giftcard'"
    class="image is-inline"
    style="height: 30px"
    src="/images/payments/cards/gift-card.svg"
    alt="Giftcard"
    title="Giftcard"
  >
  <b-icon v-else-if="showFallbackIcon" size="is-medium" icon="credit-card" />
  <span v-else class="is-uppercase">{{ cardType }}</span>
</template>

<script>
  export default {
    name: 'PaymentIcon',

    props: {
      payment: {
        type: Object,
        required: true
      },

      showFallbackIcon: {
        type: Boolean,
        default: false
      }
    },

    computed: {
      paymentType() {
        return this.payment.paymentType?.toLowerCase();
      },

      cardType() {
        return this.payment.cardType?.toLowerCase();
      }
    }
  };
</script>
