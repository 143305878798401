<template>
  <div
    data-test-id="points-per-dollar-inputs"
    class="is-flex-wrap gap-xl"
  >
    <validated-input
      label="Points Per $1 Spent"
      name="points"
      :rules="{
        required: true,
        min_value: 0.01
      }"
    >
      <numberinput-fractional
        v-model="value.awardPolicy.pointsPerDollarSpent"
        :min="0.01"
        :default-value="1"
      />
    </validated-input>
    <excluded-item-attributes-dropdown
      v-if="enableExcludedItemAttributesDropdown"
      v-model="value.awardPolicy.excludedItemAttributes"
      form-name="earnRules"
      :read-only="readOnly"
    />
  </div>
</template>

<script>
  import { awardPolicyTypes } from '@/constants/earnRules';

  export default {
    name: 'PointsPerDollarInputs',

    props: {
      value: {
        type: Object,
        required: true
      },

      readOnly: {
        type: Boolean,
        default: false
      }
    },

    computed: {
      enableExcludedItemAttributesDropdown() {
        return this.value.awardPolicy.awardPolicyType === awardPolicyTypes.CHECK_HEADER_POINT.type;
      }
    }
  };
</script>
