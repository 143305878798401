<template>
  <validated-form
    ref="itemAdditionalForm"
    :disabled="!$can('update', 'MenuItem') || !$_menuPermissions.EDIT_RESOURCE"
    auto-focus
    form-id="itemAdditionalForm"
  >
    <div>
      <p class="subtitle is-4 is-bold is-marginless">Features</p>
      <p class="is-size-7 has-text-grey mar-b-sm">
        Define things about your item
      </p>
    </div>

    <fieldset class="dist-y-md">
      <validated-input
        label="Maximum Order Quantity"
        sub-label=" Restricts the quantity of this item per order"
        name="maxAllowed"
        rules="required"
        class="mid-align"
        :disabled="form.maxAllowed === -1"
      >
        <numberinput-switch
          v-model="form.maxAllowed"
          switch-label="Unlimited"
          :disabled="!$can('update', 'MenuItem') || !$_menuPermissions.EDIT_RESOURCE"
          :min="1"
          :true-value="-1"
          :false-value="1"
          @input="emitInput"
        />
      </validated-input>

      <validated-input
        v-if="isInHiddenCategory"
        label="Checkout Prompt"
        sub-label="Enable to use this item for the designated prompt on checkout"
        name="checkoutPrompt"
        tooltip="Only one item can be tagged as a bag at a time"
      >
        <span class="mar-r-sm has-text-weight-bold">Bag</span>
        <b-switch
          v-model="form.menuItemTemplate"
          :disabled="!canEditBagTemplateItem"
          true-value="BagsTemplate"
          :false-value="null"
        />
      </validated-input>

      <validated-input
        label="Item Type"
        sub-label="Tag this item so it can be identified for offer exclusions, menu restrictions, etc."
        name="itemType"
      >
        <b-select v-model="specialItemId" placeholder="None" expanded>
          <option :value="null">None</option>
          <option v-for="specialItem in specialItemAttributeMapping" :key="specialItem.id" :value="specialItem.id">
            {{ specialItem.name }}
          </option>
        </b-select>
      </validated-input>

      <validated-input
        label="Exclusive Menu Item"
        sub-label="Restricts item availability to users who meet a certain criteria"
        name="exclusiveItemRestrictionId"
      >
        <b-select v-model="exclusiveItemRestrictionId" placeholder="None" expanded>
          <option :value="null">None</option>
          <option :value="loggedInUserOnlyAttributeId">Logged-in users only</option>
          <option :value="loyaltyMemberOnlyAttributeId">Loyalty members only</option>
        </b-select>
      </validated-input>

      <validated-input
        label="Platform Availability"
        sub-label="Select which platform(s) to show this item on."
        name="platformAvailability"
      >
        <b-dropdown
          v-model="selectedPlatformTypeIds"
          multiple
          aria-role="list"
          type="is-primary"
          expanded
          @input="emitInput"
        >
          <dropdown-button
            slot="trigger"
            :value="isSelectedAll() ? 'All' : `Selected Platforms (${selectedPlatformTypeIds.length })`"
          >
            <div class="is-flex align-center is-full-width">
              {{ isSelectedAll() ? 'All' : `Selected Platforms (${selectedPlatformTypeIds.length })` }}
            </div>
          </dropdown-button>
          <b-dropdown-item
            aria-role="listitem"
            :class="{'is-active': isSelectedAll()}"
            @click="handleSelectAllPlatforms"
          >
            All Platforms
          </b-dropdown-item>

          <b-dropdown-item
            v-for="platform in platformTypes"
            :key="platform.id"
            :value="platform.id"
            aria-role="listitem"
          >
            {{ capitalCase(platform.name) }}
          </b-dropdown-item>
        </b-dropdown>
      </validated-input>

      <validated-input
        v-if="deliveryProviders.length"
        label="3rd Party Delivery Restriction"
        sub-label="Select which delivery platforms to exclude this item from"
        name="deliveryRestriction"
        tooltip="Prevent this item from being offered on the selected platforms"
      >
        <b-dropdown
          v-model="selectedDeliveryAttributeIds"
          multiple
          aria-role="list"
          type="is-primary"
          expanded
          @input="emitInput"
        >
          <dropdown-button
            slot="trigger"
            :value="selectedDeliveryAttributeIds.length ? `Selected Providers (${selectedDeliveryAttributeIds.length})` : 'Select Providers'"
          >
            <div class="is-flex align-center is-full-width">
              {{ selectedDeliveryAttributeIds.length ? `Selected Providers (${selectedDeliveryAttributeIds.length})` : 'Select Providers' }}
            </div>
          </dropdown-button>
          <b-dropdown-item
            v-for="provider in deliveryProviders"
            :key="provider.id"
            :value="provider.id"
            aria-role="listitem"
          >
            {{ provider.name }}
          </b-dropdown-item>
        </b-dropdown>
      </validated-input>

      <validated-input
        label="Age Restriction"
        sub-label="Prompts the guest to confirm that they meet the age requirement for this item"
        name="ageVerificationTemplate"
      >
        <b-select v-model="form.ageVerificationTemplate" placeholder="None" expanded>
          <option :value="null">None</option>
          <option :value="'AlcoholTemplate'">Restrict to 21+</option>
        </b-select>
      </validated-input>

      <validated-text-input
        v-model="form.units"
        label="Units"
        name="units"
        type="number"
        placeholder="Ex: 24"
        tooltip="Specify how many units are in this item (i.e. 12 for a dozen donuts)"
        :mask-options="{ numeralPositiveOnly: true }"
      />

      <validated-input
        label="Bottle Deposit Eligible"
        name="bottleDepositEligible"
      >
        <b-select
          v-model="bottleDepositEligibleId"
          placeholder="No"
          expanded
        >
          <option :value="null">No</option>
          <option :value="bottleDepositAttributeId">Yes</option>
        </b-select>
      </validated-input>

      <validated-input
        label="&quot;New&quot; Tag"
        sub-label=" Display a “new” tag on this item until the date specified below"
        name="newItemBeginDate"
      />
      <div class="is-grid col-2 gap-x">
        <validated-input name="newItemBeginDate" label="Start Date" label-position="on-border">
          <b-datepicker
            v-model="newItemBeginDate"
            placeholder="Start Date"
            icon="calendar-alt"
            position="is-top-right"
            :min-date="minDate"
            :disabled="!$can('update', 'MenuItem') || !$_menuPermissions.EDIT_RESOURCE"
            indicators="bars"
            class="has-extra-shadow"
            @input="emitInput"
          >
            <div class="buttons is-right">
              <b-button @click="clearDate('newItemBeginDate')">Clear</b-button>
            </div>
          </b-datepicker>
        </validated-input>

        <validated-input
          name="newItemEndDate"
          rules="dateTimeIsAfter:@newItemBeginDate"
          label="End Date"
          label-position="on-border"
        >
          <b-datepicker
            v-model="newItemEndDate"
            placeholder="End Date"
            icon="calendar-alt"
            position="is-top-left"
            :min-date="minDate"
            :disabled="!$can('update', 'MenuItem') || !$_menuPermissions.EDIT_RESOURCE"
            indicators="bars"
            class="has-extra-shadow"
            @input="emitInput"
          >
            <div class="buttons is-right">
              <b-button @click="clearDate('newItemEndDate')">Clear</b-button>
            </div>
          </b-datepicker>
        </validated-input>
      </div>
    </fieldset>

    <hr>

    <item-tags
      :menu-item-id="Number(item.id)"
      :selected-tags="selectedTags"
      @tag-added="$event => selectedTags.push($event)"
      @tag-removed="id => { selectedTags = selectedTags.filter(t => t.id !== id) }"
      @clear-all-tags="selectedTags = []"
      @input="emitInput"
    />

    <hr>

    <fieldset>
      <p class="subtitle is-5 is-marginless">
        Allergens
      </p>

      <div class="is-grid gap mar-t col-2">
        <check-button
          v-for="menuAttribute in menuAttributesByName.allergen"
          :key="menuAttribute.id"
          left-label
          justify-between
          :value="selectedAttributeIds.includes(menuAttribute.id)"
          @input="handleCheckboxInput($event, menuAttribute.id)"
        >
          <div class="is-flex align-center">
            <img
              :src="`${baseImageUrl}/${menuAttribute.imageUrl}`"
              class="image is-32x32 mar-r-sm"
              :alt="`${menuAttribute.name} icon`"
            >
            {{ menuAttribute.name }}
          </div>
        </check-button>
      </div>
    </fieldset>

    <hr>

    <fieldset>
      <p class="subtitle is-5 is-marginless">
        Spice Level
      </p>

      <div class="is-grid gap mar-t col-2">
        <radio-button
          v-model="spiceLevelId"
          :native-value="null"
          name="spicelevel"
          justify-between
          left-label
        >
          None
        </radio-button>
        <radio-button
          v-for="menuAttribute in menuAttributesByName.spiceLevel"
          :key="menuAttribute.id"
          v-model="spiceLevelId"
          name="spicelevel"
          :native-value="menuAttribute.id"
          justify-between
          left-label
        >
          <div class="is-flex align-center">
            <img
              :src="`${baseImageUrl}/${menuAttribute.imageUrl}`"
              class="image is-32x32 mar-r-sm"
              :alt="`${menuAttribute.name} icon`"
            >
            {{ menuAttribute.name }}
          </div>
        </radio-button>
      </div>
    </fieldset>

    <hr>

    <fieldset>
      <p class="subtitle is-5 is-marginless">
        Food Type
      </p>

      <div class="is-grid gap mar-t col-2">
        <check-button
          v-for="menuAttribute in menuAttributesByName.foodType"
          :key="menuAttribute.id"
          left-label
          justify-between
          :value="selectedAttributeIds.includes(menuAttribute.id)"
          @input="handleCheckboxInput($event, menuAttribute.id)"
        >
          <div class="is-flex align-center">
            <img
              :src="`${baseImageUrl}/${menuAttribute.imageUrl}`"
              class="image is-32x32 mar-r-sm"
              :alt="`${menuAttribute.name} icon`"
            >
            {{ menuAttribute.name }}
          </div>
        </check-button>
      </div>
    </fieldset>
  </validated-form>
</template>


<script>
  import moment from 'moment-timezone';

  import capitalCase from '@/helpers/capitalCase';
  import ItemTag from '@/helpers/classes/ItemTag';
  import filterObjectKeys from '@/helpers/filter-object-keys';
  import getChangedResources from '@/helpers/get-changed-resources';

  import merchantMixin from '@/mixins/merchant';
  import multiFormChildProvider from '@/mixins/multiFormMixin/multiFormChildProvider';

  import { emitNativeInput } from '@/services/emit-input';

  import Item from '@/store/classes/Item';
  import MenuAttribute from '@/store/classes/MenuAttribute';
  import MenuAttributeType from '@/store/classes/MenuAttributeType';
  import MenuItemAttribute from '@/store/classes/MenuItemAttribute';
  import MenuItemPlatformType from '@/store/classes/MenuItemPlatformType';
  import PlatformType from '@/store/classes/PlatformType';

  import itemTags from './item-tags.vue';


  export default {
    name: 'ItemAdditionalForm',

    components: { itemTags },

    mixins: [merchantMixin, multiFormChildProvider],

    props: {
      item: {
        type: Object,
        required: true
      },

      category: {
        type: Object,
        default: null
      },

      formRef: {
        type: String,
        required: true
      },

      mode: {
        type: String,
        default: 'create',
        validator(value) {
          return ['create', 'read', 'update'].includes(value);
        }
      }
    },

    data() {
      return {
        form: null,
        spiceLevelId: null,
        exclusiveItemRestrictionId: null,
        specialItemId: null,
        selectedAttributeIds: [],
        selectedTags: [],
        selectedPlatformTypeIds: [],
        // This is a one-off case where we need to track this specific id as it's a backend constant.
        // If we ever add more like this, we should create a constants file to track them all.
        bottleDepositAttributeId: 67
      };
    },

    computed: {
      minDate() {
        return moment()
          .startOf('day')
          .toDate();
      },

      menuAttributesByName() {
        return MenuAttributeType.byName();
      },

      existingAttributeIds() {
        return MenuItemAttribute
          .query()
          .where('menuItemId', this.item.id)
          .get()
          .map(menuItemAttribute => menuItemAttribute.menuAttributeId);
      },

      baseImageUrl() {
        return this.$_selectedMerchant?.merchantOption?.baseImageUrl;
      },

      loyaltyMemberOnlyAttributeId() {
        return this.menuAttributesByName?.loyaltyMemberOnly?.[0]?.id;
      },

      loggedInUserOnlyAttributeId() {
        return this.menuAttributesByName?.loggedInUserOnly?.[0]?.id;
      },

      platformTypes() {
        return PlatformType.all();
      },

      deliveryProviders() {
        return this.menuAttributesByName?.thirdPartyRestriction || [];
      },

      isFetching() {
        return PlatformType.$state().fetching;
      },

      specialItemAttributeMapping() {
        const mapping = [];
        if (this.menuAttributesByName) {
          if (this.menuAttributesByName.giftCard?.length) {
            mapping.push({
              name: 'Gift Card',
              icon: 'gift-card',
              id: this.menuAttributesByName.giftCard[0].id
            });
          }
          if (this.menuAttributesByName.alcohol?.length) {
            mapping.push({
              name: 'Alcohol',
              icon: 'beer',
              id: this.menuAttributesByName.alcohol[0].id
            });
          }
          if (this.menuAttributesByName.merchandise?.length) {
            mapping.push({
              name: 'Merchandise',
              icon: 'shopping-cart',
              id: this.menuAttributesByName.merchandise[0].id
            });
          }
          if (this.menuAttributesByName.donation?.length) {
            mapping.push({
              name: 'Donation',
              icon: 'envelope-open-dollar',
              id: this.menuAttributesByName.donation[0].id
            });
          }
          if (this.menuAttributesByName.tipGratuity?.length) {
            mapping.push({
              name: 'Tip/Gratuity',
              icon: 'money-bill',
              id: this.menuAttributesByName.tipGratuity[0].id
            });
          }
        }
        return mapping;
      },

      newItemBeginDate: {
        get() {
          const dateString = moment.utc(this.form.newItemBeginDate).format('MM-DD-YYYY');
          return this.form.newItemBeginDate ? moment(dateString, 'MM-DD-YYYY').startOf('day').toDate() : null;
        },
        set(val) {
          const dateString = moment.utc(val).format('MM-DD-YYYY');
          this.form.newItemBeginDate = moment.utc(dateString, 'MM-DD-YYYY').startOf('day').toISOString();
        }
      },

      newItemEndDate: {
        get() {
          const dateString = moment.utc(this.form.newItemEndDate).format('MM-DD-YYYY');
          return this.form.newItemEndDate ? moment(dateString, 'MM-DD-YYYY').endOf('day').toDate() : null;
        },
        set(val) {
          const dateString = moment.utc(val).format('MM-DD-YYYY');
          this.form.newItemEndDate = moment.utc(dateString, 'MM-DD-YYYY').endOf('day').toISOString();
        }
      },

      selectedDeliveryAttributeIds: {
        get() {
          return this.selectedAttributeIds.filter(id => this.deliveryProviders.some(provider => provider.id === id));
        },
        set(newIds) {
          const nonDeliveryIds = this.selectedAttributeIds.filter(id => !this.deliveryProviders.some(provider => provider.id === id));
          this.selectedAttributeIds = [...nonDeliveryIds, ...newIds];
        }
      },

      existingTags() {
        return MenuItemAttribute.searchTagsForItem(this.item.id);
      },

      canEditBagTemplateItem() {
        const bagItem = Item.bagTemplateItem();
        return bagItem ? bagItem.id === this.item.id : true;
      },

      isInHiddenCategory() {
        return this.category?.statementTemplate === 'HiddenTemplate';
      },

      bottleDepositEligibleId: {
        get() {
          return this.selectedAttributeIds.includes(this.bottleDepositAttributeId)
            ? this.bottleDepositAttributeId
            : null;
        },
        set(value) {
          const attributeIds = new Set(this.selectedAttributeIds);
          if (value === this.bottleDepositAttributeId) {
            attributeIds.add(value);
          }
          else {
            attributeIds.delete(this.bottleDepositAttributeId);
          }
          this.selectedAttributeIds = [...attributeIds];
          this.emitInput();
        }
      }
    },

    created() {
      this.onCreated();
      this.fetchPlatformTypes();
    },

    methods: {
      capitalCase,

      async onCreated() {
        this.form = this.$clone(this.item);
        if (this.form.ageVerificationTemplate === '') {
          // the api returns both null and empty string for ageVerificationTemplate
          this.form.ageVerificationTemplate = null;
        }

        const fetchPromises = [
          this.fetchMenuAttributes(),
          MenuAttribute.fetchMenuAttributesForMerchant()
        ];

        if (this.item.id) {
          fetchPromises.push(MenuItemPlatformType.fetchItemPlatformTypes(this.item.id));
        }

        await Promise.all(fetchPromises);

        if (this.item.menuItemPlatformTypes?.length) {
          this.selectedPlatformTypeIds = this.item.menuItemPlatformTypes.map(mipt => mipt.platformTypeId);
        }

        if (this.mode === 'update') {
          await this.fetchMenuItemAttributes();
          const existingSpiceLevelAttribute = this.menuAttributesByName.spiceLevel?.find(
              menuAttr => this.existingAttributeIds.includes(menuAttr.id)
            );
          this.spiceLevelId = existingSpiceLevelAttribute ? existingSpiceLevelAttribute.id : null;

          const specialItemAttributes = [];
          if (this.menuAttributesByName.giftCard) specialItemAttributes.push(...this.menuAttributesByName.giftCard);
          if (this.menuAttributesByName.alcohol) specialItemAttributes.push(...this.menuAttributesByName.alcohol);
          if (this.menuAttributesByName.merchandise) specialItemAttributes.push(...this.menuAttributesByName.merchandise);
          if (this.menuAttributesByName.donation) specialItemAttributes.push(...this.menuAttributesByName.donation);
          if (this.menuAttributesByName.tipGratuity) specialItemAttributes.push(...this.menuAttributesByName.tipGratuity);

          const specialItemAttribute = specialItemAttributes.find(
            nonFoodAttr => this.existingAttributeIds.includes(nonFoodAttr.id)
          );
          this.specialItemId = specialItemAttribute?.id || null;

          const existingItemExclusionId = this.existingAttributeIds?.find(
              (attributeId => (attributeId === this.loggedInUserOnlyAttributeId || attributeId === this.loyaltyMemberOnlyAttributeId))
            );
          this.exclusiveItemRestrictionId = existingItemExclusionId || null;

          this.selectedAttributeIds = this.existingAttributeIds.filter(
            attrId => ![this.specialItemId, this.spiceLevelId, this.exclusiveItemRestrictionId].includes(attrId)
          );

          this.selectedTags.push(...this.existingTags.map(tag => new ItemTag(tag)));
        }
      },

      emitInput() {
        const formElement = this.$refs.itemAdditionalForm.$refs.formElement;
        emitNativeInput(formElement);
      },

      clearDate(dateType) {
        this.form[dateType] = null;
        this.emitInput();
      },

      isSelectedAll() {
        return this.selectedPlatformTypeIds.length === 0;
      },

      handleSelectAllPlatforms() {
        this.selectedPlatformTypeIds = [];
      },

      async fetchPlatformTypes() {
        try {
          await PlatformType.fetchPlatformTypes();
        }
        catch (error) {
          this.$_onRequestError({ toastOptions: { message: 'Unable to fetch platform types' }, error });
        }
      },

      handleCheckboxInput(isAdding, menuAttributeId) {
        const menuAttributeIds = new Set(this.selectedAttributeIds);
        if (isAdding) {
          menuAttributeIds.add(menuAttributeId);
        }
        else {
          menuAttributeIds.delete(menuAttributeId);
        }
        this.selectedAttributeIds = [...menuAttributeIds];
      },

      async handleSubmit(itemId = this.item.id) {
        try {
          this.form.id = itemId;

          const whitelistedKeys = [
            'id',
            'maxAllowed',
            'menuItemTemplate',
            'ageVerificationTemplate',
            'newItemBeginDate',
            'newItemEndDate',
            'units'
          ];

          const allSelectedAttributes = [
            ...this.selectedAttributeIds,
            this.spiceLevelId,
            this.exclusiveItemRestrictionId,
            this.specialItemId
          ].filter(Boolean);

          const { added, removed } = getChangedResources({ newArray: allSelectedAttributes, oldArray: this.existingAttributeIds });

          await Promise.all([
            Item.updateItem(filterObjectKeys(this.form, whitelistedKeys)),
            MenuItemPlatformType.updateItemPlatformTypes(itemId, this.selectedPlatformTypeIds),
            removed.length && MenuItemAttribute.deleteMenuItemAttributes({ itemId, menuAttributeIds: removed }),
            added.length && MenuItemAttribute.updateMenuItemAttributes({ itemId, menuAttributeIds: added }),
            this.handleItemTagSubmit(itemId)
          ]);

          return itemId;
        }

        catch (error) {
          this.$_onRequestError({
            toastOptions: { message: 'An error occurred while updating your item' },
            error
          });

          throw error;
        }
      },

      async handleItemTagSubmit(itemId) {
        const { added, removed } = getChangedResources({
          newArray: this.selectedTags,
          oldArray: this.existingTags,
          comparisonKey: 'menuAttributeId'
        });
        const menuItemAttributeIdsForAdd = [];

        if (added.length) {
          const searchTagTypeId = MenuAttributeType.searchTagType().id;
          const newTags = added.filter(tag => tag.isNewTag);
          const nonNewTags = added.filter(tag => !tag.isNewTag);

          if (newTags.length) {
            const menuAttributesToCreate = newTags.map(tag => ({
              name: tag.name,
              menuAttributeTypeId: searchTagTypeId,
              merchantMenuAttributeAttributes: { isSearchSuggestion: false }
            }));
            const addedMenuAttributes = await MenuAttribute.createMenuAttributes(menuAttributesToCreate);

            menuItemAttributeIdsForAdd.push(...addedMenuAttributes.map(tag => tag.id));
          }

          menuItemAttributeIdsForAdd.push(...nonNewTags.map(tag => tag.menuAttributeId));
        }

        await Promise.all([
          added.length && MenuItemAttribute.updateMenuItemAttributes({
            itemId,
            menuAttributeIds: menuItemAttributeIdsForAdd
          }),
          removed.length && MenuItemAttribute.deleteMenuItemAttributes({
            itemId,
            menuAttributeIds: removed.map(r => r.menuAttributeId)
          })
        ]);
      },

      async fetchMenuItemAttributes() {
        try {
          await MenuItemAttribute.fetchMenuItemAttributes(this.item.id, { forceFetch: true });
        }
        catch (error) {
          this.$_onRequestError({
            toastOptions: { message: 'An error occurred while fetching your item attributes' },
            error
          });
        }
      },

      async fetchMenuAttributes() {
        try {
          await MenuAttributeType.fetchMenuAttributes();
        }
        catch (error) {
          this.$_onRequestError({
            toastOptions: { message: 'An error occurred while fetching your menu attributes' },
            error
          });
        }
      }
    }
  };
</script>
<style scoped lang="sass">
  .mid-align
    ::v-deep
      .b-numberinput.has-addons
        margin-bottom: 0
</style>
