<template>
  <div>
    <b-loading :is-full-page="false" :active="!$_selectedMerchant || isFetchingJwtToken" />
    <tabs-page
      v-if="tabs.length"
      :tabs="tabs"
      title="Reporting"
      show-hero
    />
  </div>
</template>

<script>
  import merchantMixin from '@/mixins/merchant';
  import http from '@/services/http';
  // tab components
  import reportingTab from './reporting-tab.vue';

  export default {
    name: 'ReportingConfiguration',

    mixins: [merchantMixin],

    data() {
      return {
        tabs: [],
        isFetchingJwtToken: false
      };
    },

    methods: {
      async onMerchantLoad() {
        const tableauJwtToken = await this.fetchTableauTokenAndUrl();
        this.tabs = [
          {
            display: 'Reporting',
            name: 'Reporting',
            component: reportingTab,
            props: { tableauJwtToken }
          }
        ];
      },

      async fetchTableauTokenAndUrl() {
        try {
          this.isFetchingJwtToken = true;
          const response = await http.get(`merchants/${this.$_selectedMerchantId}/tableau_token`);
          return response.data;
        }
        catch (error) {
          this.$_onRequestError({
            error,
            toastOptions: {
              message: 'Failed to load the Dashboard'
            }
          });
        }
        finally {
          this.isFetchingJwtToken = false;
        }
      }
    }
  };
</script>
