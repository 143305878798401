import { Model } from '@vuex-orm/core';
import http from '@/services/http';
import MerchantAppSetting from '@/store/classes/MerchantAppSetting';
import MaintenanceCode from './MaintenanceCode';
import filterObjectKeys from '@/helpers/filter-object-keys';


export default class MerchantMaintenanceInfo extends Model {
  static entity = 'merchantMaintenanceInfos'



  // FIELDS //////////////////////
  static fields() {
    return {
      id: this.attr(''),
      maintenanceCodeId: this.attr(''),
      merchantAppSettingsId: this.attr(''),
      message: this.attr(''),
      title: this.attr(''),
      url: this.attr(''),

      // FE Relationships
      merchantAppSetting: this.belongsTo(MerchantAppSetting, 'merchantAppSettingsId'),
      maintenanceCode: this.belongsTo(MaintenanceCode, 'maintenanceCodeId')
    };
  }



  // STATE //////////////////////
  static state() {
    return {
      fetching: false,
      submitting: false
    };
  }

  static $state() {
    return this.store().state.entities.merchantMaintenanceInfos;
  }



  // ACTIONS //////////////////////
  static async fetchMerchantMaintenanceInfos() {
    try {
      this.commit((state) => {
        state.fetching = true;
      });

      const merchantId = this.store().state.entities.merchants.selectedMerchantId;

      const { data } = await http.get(`merchants/${merchantId}/merchant_maintenance_infos`);

      this.create({
        data: data.merchantMaintenanceInfos
      });
    }

    catch (error) {
      throw error;
    }

    finally {
      this.commit((state) => {
        state.fetching = false;
      });
    }
  }

  static async addMerchantMaintenanceInfo(merchantMaintenanceInfo) {
    try {
      this.commit((state) => {
        state.submitting = true;
      });

      const acceptedKeys = [
        'title',
        'message',
        'url',
        'maintenanceCodeId',
        'merchantAppSettingsId'
      ];
      const merchantId = this.store().state.entities.merchants.selectedMerchantId;

      const { data } = await http.post(`merchants/${merchantId}/merchant_maintenance_infos`, {
        merchantMaintenanceInfo: filterObjectKeys(merchantMaintenanceInfo, acceptedKeys)
      });

      this.insert({
        data: data.merchantMaintenanceInfo
      });
    }

    catch (error) {
      throw error;
    }

    finally {
      this.commit((state) => {
        state.submitting = false;
      });
    }
  }

  static async updateMerchantMaintenanceInfo(merchantMaintenanceInfo) {
    try {
      this.commit((state) => {
        state.submitting = true;
      });

      const acceptedKeys = ['title', 'message', 'url'];
      const { data } = await http.put(`merchant_maintenance_infos/${merchantMaintenanceInfo.id}`, {
        merchantMaintenanceInfo: filterObjectKeys(merchantMaintenanceInfo, acceptedKeys)
      });

      this.update({
        data: data.merchantMaintenanceInfo
      });
    }

    catch (error) {
      throw error;
    }

    finally {
      this.commit((state) => {
        state.submitting = false;
      });
    }
  }

  static async removeMerchantMaintenanceInfo(merchantMaintenanceInfoId) {
    try {
      this.commit((state) => {
        state.submitting = true;
      });

      await http.delete(`merchant_maintenance_infos/${merchantMaintenanceInfoId}`);

      this.delete(merchantMaintenanceInfoId);
    }

    catch (error) {
      throw error;
    }

    finally {
      this.commit((state) => {
        state.submitting = false;
      });
    }
  }
}
