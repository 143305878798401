/* eslint-disable import/no-cycle */
import { Model } from '@vuex-orm/core';
import MenuAttribute from '@/store/classes/MenuAttribute';

export default class MerchantMenuAttribute extends Model {
  static entity = 'merchantMenuAttributes'

  static fields() {
    return {
      id: this.attr(''),
      merchantId: this.attr(null),
      isSearchSuggestion: this.attr(false),
      menuAttributeId: this.attr(null),
      menuAttribute: this.belongsTo(MenuAttribute, 'menuAttributeId')
    };
  }

  static $state() {
    return this.store().state.entities.merchantMenuAttributes;
  }

  static withMenuAttributes() {
    return this.query().with('menuAttribute').get();
  }

  static bySearchTagType() {
    return this
      .query()
      .with('menuAttribute.menuAttributeType')
      .with('menuAttribute.menuItemAttributes')
      .get()
      .filter(mma => mma.menuAttribute?.menuAttributeType?.isSearchType);
  }

  static countOfSearchSuggestionTags() {
    return this.query().where('isSearchSuggestion', true).count();
  }

  static getAttributeByIdWithMenuAttribute(id) {
    return this.query().withAllRecursive().where('id', id).first();
  }

  get name() {
    return this.menuAttribute?.name;
  }
}
