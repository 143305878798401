<template>
  <div>
    <hero-banner>
      <h1 class="title is-2">Web App</h1>
    </hero-banner>

    <div class="section">
      <div class="container">
        <b-message
          type="is-warning"
          icon="info-square"
          class="is-compact has-shadow"
          has-icon
        >
          The branding set on this page is for the web app only. To make updates to the native or kiosk apps, please contact <a class="link" href="mailto:support@cardfree.com">CardFree support.</a>
        </b-message>
        <div class="card">
          <b-loading :is-full-page="false" :active="!$_selectedMerchant" />

          <validated-form
            ref="form"
            class="card-content"
            form-id="merchantSkinForm"
            :disabled="!$can('update', 'MerchantOption')"
            @valid-submit="handleSubmit"
          >
            <web-app-configuration-inputs v-model="form" :read-only="false" />
            <template v-if="$can('update', 'MerchantOption')">
              <div class="footer-buttons">
                <b-button
                  rounded
                  size="is-medium"
                  native-type="submit"
                  type="is-primary"
                  :loading="isSubmitting"
                  class="is-bold"
                >
                  Save
                </b-button>
              </div>
            </template>
          </validated-form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import merchantMixin from '@/mixins/merchant';
  import MerchantOption from '@/store/classes/MerchantOption';
  import WebAppConfigurationInputs from '@/components/pages/forms/web-app-configuration-inputs.vue';
  import filterObjectKeys from '@/helpers/filter-object-keys';

  export default {
    name: 'WebAppConfiguration',

    components: {
      WebAppConfigurationInputs
    },

    mixins: [merchantMixin],

    data() {
      return {
        form: null,
        isSubmitting: false
      };
    },

    methods: {
      onMerchantLoad(selectedMerchant) {
        this.form = this.$clone(selectedMerchant.merchantOption.skinOptions);
      },

      async handleSubmit() {
        try {
          this.isSubmitting = true;
          const merchantOptionId = this.$_selectedMerchant.merchantOption.id;

          await MerchantOption.updateMerchantOption({
            id: merchantOptionId,
            skinOptions: JSON.stringify(filterObjectKeys(this.form, ['logoImageFile'], true))
          });

          if (this.form.logoImageFile) {
            await MerchantOption.updateMerchantLogo({
              id: merchantOptionId,
              logoImageFile: this.form.logoImageFile
            });
            this.form.logoImageFile = null;
          }

          this.$_onRequestSuccess({
            toastOptions: {
              message: 'Your <b>skin options</b> have been successfully updated!'
            }
          });
        }

        catch (error) {
          const errorMessage = error.data.errors.merchantOption.skinOptions ? 'Unable to update your skin options' : 'Unable to update your logo';

          this.$_onRequestError({
            error,
            toastOptions: {
              message: `${errorMessage}`
            },
            options: {
              backendError: error.data.errors.merchantOption
            }
          });
        }

        finally {
          this.isSubmitting = false;
        }
      }
    }
  };
</script>

<style lang="sass" scoped>
  .header-preview
    width: 100%
    border-radius: $radius-large
    border: 1px solid #eee

  .color-picker
    display: flex

    .hex-input
      min-width: 7rem

    .swatch
      width: 40px
      height: 40px
      margin-right: 0.75rem
      border: 1px solid #eee
      border-radius: 4px

      &:hover
        cursor: pointer
        opacity: 0.7
</style>
