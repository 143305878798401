var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section-wrapper',{attrs:{"title":"Application Settings"}},[_c('b-table',{staticClass:"is-middle-aligned flex-grow pad-x-xs",attrs:{"data":_vm.merchantAppSettings,"hoverable":""},scopedSlots:_vm._u([{key:"empty",fn:function(){return [_c('p',{staticClass:"has-text-centered pad is-size-5 has-text-grey-light"},[_vm._v(" No Applications Configured ")])]},proxy:true}])},[_c('b-table-column',{attrs:{"sortable":"","field":"paymentGateway","label":"Name"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.appCodeName)+" ")]}}])}),_c('b-table-column',{attrs:{"centered":"","sortable":"","field":"systemStatusCodeId","label":"System Status Code"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.systemStatusCodeId)+" ")]}}])}),_c('b-table-column',{attrs:{"centered":"","sortable":"","field":"currentVersion","label":"Current Version"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.currentVersion)+" ")]}}])}),_c('b-table-column',{attrs:{"centered":"","sortable":"","field":"minimumVersion","label":"Minimum Version"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.minimumVersion)+" ")]}}])}),_c('b-table-column',{attrs:{"centered":"","field":"hasUniversalLink","label":"Universal Links"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [(row.hasUniversalLink !== 'N/A')?_c('b-icon',{attrs:{"icon":row.hasUniversalLink ? 'check' : 'times',"type":row.hasUniversalLink ? 'is-success' : 'is-danger',"size":"is-small"}}):_c('span',{staticClass:"has-text-grey-light"},[_vm._v(" N/A ")])]}}])}),_c('b-table-column',{attrs:{"centered":"","cell-class":"actions","label":"Actions","width":"1"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('b-button',{staticClass:"is-transparent",attrs:{"type":"is-white"},on:{"click":function($event){return _vm.openApplicationModal(row)}}},[_c('b-icon',{attrs:{"icon":"pencil","size":"is-small"}})],1)]}}])})],1),_c('div',{staticClass:"pad-x pad-y-sm has-border-top has-border-grey-lighter"},[_c('b-button',{staticClass:"is-transparent",attrs:{"inverted":"","type":"is-primary","icon-left":"plus","disabled":!_vm.canAddApplicationSetting},on:{"click":function($event){return _vm.openApplicationModal()}}},[_vm._v(" Application Setting ")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }