/* eslint-disable import/no-cycle */
import { Model } from '@vuex-orm/core';

import MenuItemModifierGroupItem from './MenuItemModifierGroupItem';
import Modifier from './Modifier';

import http from '@/services/http';
import { setUpdatedSortOrders } from '@/helpers/set-new-sort-orders';
import filterObjectKeys from '@/helpers/filter-object-keys';



export default class ModifierGroup extends Model {
  static entity = 'modifierGroups'

  // FIELDS //////////////////////
  static fields() {
    return {
      id: this.attr(''),
      createdAt: this.attr(''),
      displayName: this.attr(''),
      maxAllowed: this.number(-1),
      maxModifierQtyAllowed: this.number(-1),
      menuItemId: this.attr(''),
      menuItemModifierId: this.attr(''),
      minModifierQtyRequired: this.number(0),
      minRequired: this.number(0),
      modifierGroupTemplate: this.attr(null),
      priceOverrideIndex: this.attr(''),
      sharedMenuItemCount: this.attr(''),
      modifiersCount: this.attr(''),
      sortOrder: this.attr(''),
      type: this.attr(null),
      updatedAt: this.attr(''),

      // FE Property
      portionVariations: this.attr([]),

      // relationships
      menuItemModifiers: this.hasMany(Modifier, 'modifierGroupId'),
      menuItemModifierGroupItems: this.hasMany(MenuItemModifierGroupItem, 'modifierGroupId')
    };
  }

  get lastModified() {
    return this.updatedAt || this.createdAt;
  }



  // STATE //////////////////////
  static state() {
    return {
      fetching: false,
      fetchingModifierGroupId: null,
      fetchingItemId: null,
      submitting: false,
      sortingParentId: null,
      sortingParentModifierId: null,
      deleting: false
    };
  }

  static $state() {
    return this.store().state.entities.modifierGroups;
  }


  // STATIC METHODS //////////////////////
  static singleSelectModifierByGroupId(modifierGroupId) {
    const modifierGroup = this.query()
      .with('menuItemModifiers', (query) => {
        query.where('modifierTemplate', 'NoneModifierTemplate');
      })
      .find(modifierGroupId);

    return modifierGroup?.menuItemModifiers[0];
  }


  // ACTIONS //////////////////////
  static async fetchByMenuItemId({ itemId, includeModifiers = false }) {
    try {
      this.commit((state) => {
        state.fetchingItemId = itemId;
      });

      const response = await http.get(`menu_items/${itemId}/menu_item_modifier_groups${includeModifiers ? '?include=menuItemModifiers' : ''}`);
      this.insert({
        data: response.data.menuItemModifierGroups
      });
      return response.data.menuItemModifierGroups;
    }

    catch (error) {
      throw error;
    }

    finally {
      this.commit((state) => {
        state.fetchingItemId = null;
      });
    }
  }

  static async fetchModifierGroups(params) {
    try {
      this.commit((state) => {
        state.fetching = true;
      });

      const merchantId = this.store().state.entities.merchants.selectedMerchantId;
      const response = await http.get(`merchants/${merchantId}/menu_item_modifier_groups`, { params });

      this.insert({
        data: response.data.menuItemModifierGroups
      });

      return response.data;
    }

    catch (error) {
      throw error;
    }

    finally {
      this.commit((state) => {
        state.fetching = false;
      });
    }
  }

  static async fetchModifierGroup({ modifierGroupId, includeMenuItemMetadata = false }) {
    try {
      this.commit((state) => {
        state.fetchingModifierGroupId = modifierGroupId;
      });

      const response = await http.get(`menu_item_modifier_groups/${modifierGroupId}${includeMenuItemMetadata
        ? '?include=menu_item_modifier_group_items,menu_item_modifier_group_items.menu_item&fields[menu_item]=id,display_name'
        : ''
      }`);

      this.insertOrUpdate({
        data: response.data.menuItemModifierGroup
      });
    }

    catch (error) {
      throw error;
    }

    finally {
      this.commit((state) => {
        state.fetchingModifierGroupId = null;
      });
    }
  }

  static async fetchVisibleModifierGroupsByStoreId(storeId) {
    try {
      this.commit((state) => { // TEST ?
        state.fetching = true;
      });


      const response = await http.get(
        `stores/${storeId}/menu_item_modifier_groups/visible`
      );

      return response.data;
    }

    catch (error) {
      throw error;
    }

    finally {
      this.commit((state) => { // TEST ?
        state.fetching = false;
      });
    }
  }

  static async addModifierGroup({ menuItemId, modifierGroup }) {
    try {
      this.commit((state) => {
        state.submitting = true;
      });
      const acceptedKeys = [
        'description',
        'displayName',
        'maxAllowed',
        'maxModifierQtyAllowed',
        'menuItemModifierId',
        'minModifierQtyRequired',
        'minRequired',
        'portionVariations',
        'type'
      ];

      const merchantId = this.store().state.entities.merchants.selectedMerchantId;
      const url = menuItemId
        ? `menu_items/${menuItemId}/menu_item_modifier_groups`
        : `merchants/${merchantId}/menu_item_modifier_groups`;

      const response = await http.post(url, {
        menuItemModifierGroup: filterObjectKeys(modifierGroup, acceptedKeys)
      });

      this.insert({ data: response.data.menuItemModifierGroup });
      return response.data.menuItemModifierGroup;
    }

    catch (error) {
      throw error;
    }

    finally {
      this.commit((state) => {
        state.submitting = false;
      });
    }
  }

  static async updateModifierGroup(modifierGroupData) {
    try {
      this.commit((state) => {
        state.submitting = true;
      });

      const { id } = modifierGroupData;

      const acceptedKeys = [
        'description',
        'displayName',
        'maxAllowed',
        'maxModifierQtyAllowed',
        'minModifierQtyRequired',
        'minRequired'
      ];

      const response = await http.put(`menu_item_modifier_groups/${id}`, {
        menuItemModifierGroup: filterObjectKeys(modifierGroupData, acceptedKeys)
      });

      this.update({ data: response.data.menuItemModifierGroup });
      return response.data.menuItemModifierGroup;
    }

    catch (error) {
      throw error;
    }

    finally {
      this.commit((state) => {
        state.submitting = false;
      });
    }
  }

  static async updateSortOrders({ modifierGroups, oldIndex, newIndex }) {
    let newModifierGroups = setUpdatedSortOrders({ fromIndex: oldIndex, toIndex: newIndex, array: modifierGroups });
    newModifierGroups = newModifierGroups?.map((modifierGroup) => {
      delete modifierGroup.menuItemModifiers;
      return modifierGroup;
    });

    this.insert({
      data: newModifierGroups
    });

    this.commit((state) => {
      if (modifierGroups[0].menuItemModifierId) {
        state.sortingParentModifierId = modifierGroups[0].menuItemModifierId;
      }
      else {
        state.sortingParentId = modifierGroups[0].menuItemId;
      }
    });

    try {
      await http.put('menu_item_modifier_groups/sort_order', { menuItemModifierGroups: newModifierGroups.map(nmg => filterObjectKeys(nmg, ['id', 'sortOrder'])) });
    }

    catch (error) {
      this.insert({ data: modifierGroups });
      throw error;
    }

    finally {
      this.commit((state) => {
        state.sortingParentId = null;
        state.sortingParentModifierId = null;
      });
    }
  }

  static async deleteModifierGroup(modifierGroupId) {
    try {
      this.commit((state) => {
        state.deleting = true;
      });

      await http.delete(`menu_item_modifier_groups/${modifierGroupId}`);

      this.delete(modifierGroupId);
    }

    catch (error) {
      throw error;
    }

    finally {
      this.commit((state) => {
        state.deleting = false;
      });
    }
  }

  static async toggleModifierGroupVisibility({ menuItemModifierGroupId, storeId, isDisabled }) {
    try {
      this.commit((state) => {
        state.submitting = true;
      });

      const merchantId = this.store().state.entities.merchants.selectedMerchantId;

      const response = await http.put(
        `merchants/${merchantId}/store_menus/${storeId}/${isDisabled ? 'hide_modifier_group' : 'show_modifier_group'}`,
        { menuItemModifierGroupId }
      );

      this.insert({
        data: response.data.menuItemModifierGroup
      });
    }

    catch (error) {
      throw error;
    }

    finally {
      this.commit((state) => {
        state.submitting = false;
      });
    }
  }

  static async cloneModifierGroup({ menuItemIds, modifierGroup }) {
    try {
      this.commit((state) => {
        state.submitting = true;
      });

      const responseArray = await Promise.all(
        menuItemIds.map(id => http.post(`menu_item_modifier_groups/${modifierGroup.id}/clone`, { menuItemId: id }))
      );

      const clonedModifierGroups = responseArray.map(response => response.data.menuItemModifierGroup);

      this.insert({ data: clonedModifierGroups });
    }

    catch (error) {
      throw error;
    }

    finally {
      this.commit((state) => {
        state.submitting = false;
      });
    }
  }
}
