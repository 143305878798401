// classes
import { Model } from '@vuex-orm/core';
import http from '@/services/http';
import filterObjectKeys from '@/helpers/filter-object-keys';


export default class MerchantPushProvider extends Model {
  static entity = 'merchantPushProviders'

  // FIELDS //////////////////////
  static fields() {
    return {
      id: this.attr(''),
      merchantId: this.attr(null),
      pushProviderTypeId: this.attr(''),
      apiConfiguration: this.attr({})
    };
  }

  // STATE //////////////////////
  static state() {
    return {
      fetching: false,
      submitting: false,
      deleting: false
    };
  }

  static $state() {
    return this.store().state.entities.merchantPushProviders;
  }



  // ACTIONS //////////////////////
  static async fetchMerchantPushProviders(merchantId) {
    if (this.exists()) return;

    try {
      this.commit((state) => {
        state.fetching = true;
      });

      const _merchantId = merchantId || this.store().state.entities.merchants.selectedMerchantId;
      const response = await http.get(`merchants/${_merchantId}/merchant_push_providers`);

      this.insert({
        data: response.data.merchantPushProviders
      });
    }
    catch (error) {
      throw error;
    }
    finally {
      this.commit((state) => {
        state.fetching = false;
      });
    }
  }

  static async addMerchantPushProvider({ merchantPushProvider, merchantId }) {
    try {
      this.commit((state) => {
        state.submitting = true;
      });

      const acceptedKeys = [
        'apiConfiguration',
        'pushProviderTypeId'
      ];

      const response = await http.post(
        `merchants/${merchantId}/merchant_push_providers`,
        { merchantPushProvider: filterObjectKeys(merchantPushProvider, acceptedKeys) }
      );

      this.insert({
        data: response.data.merchantPushProvider
      });
    }
    catch (error) {
      throw error;
    }
    finally {
      this.commit((state) => {
        state.submitting = false;
      });
    }
  }

  static async updateMerchantPushProvider({ merchantPushProvider }) {
    try {
      this.commit((state) => {
        state.submitting = true;
      });

      const acceptedKeys = ['apiConfiguration'];

      const response = await http.put(
        `merchant_push_providers/${merchantPushProvider.id}`,
        { merchantPushProvider: filterObjectKeys(merchantPushProvider, acceptedKeys) }
      );

      this.update({
        data: response.data.merchantPushProvider
      });
    }
    catch (error) {
      throw error;
    }
    finally {
      this.commit((state) => {
        state.submitting = false;
      });
    }
  }

  static async removeMerchantPushProvider(id) {
    try {
      this.commit((state) => {
        state.deleting = true;
      });
      await http.delete(`merchant_push_providers/${id}`);

      this.delete(id);
    }
    catch (error) {
      throw error;
    }
    this.commit((state) => {
      state.deleting = false;
    });
  }
}
