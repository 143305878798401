<template>
  <div>
    <validated-form
      ref="form"
      v-slot="{ errors }"
      form-id="contentContactUsForm"
      @valid-submit="handleSubmit"
    >
      <panel
        title="Terms of Service"
        collapsible
        :loading="isFetching"
      >
        <template #buttons>
          <b-button
            v-if="$can('create', 'MerchantTermsOfService')"
            rounded
            class="is-bold"
            size="is-medium"
            type="is-primary is-light"
            :loading="isResettingDefault"
            :disabled="!selectedMerchantTermsOfService || hasDefaultValues"
            @click="resetToDefault"
          >
            Reset to Defaults
          </b-button>
          <b-button
            v-if="$can('create', 'MerchantTermsOfService')"
            rounded
            class="is-bold"
            size="is-medium"
            native-type="submit"
            type="is-primary"
            :loading="isLoading"
          >
            Save
          </b-button>
        </template>

        <div v-if="$can('create', 'MerchantTermsOfService') && !showPreview">
          <validated-text-input
            key="form"
            v-model="form.termsOfService"
            :has-icon="false"
            label="HTML Content"
            name="termsOfService"
            rows="20"
            type="textarea"
            :spellcheck="false"
            :rules="{
              required: true,
              maxByteSize
            }"
            hide-error-messages
            monospaced
            class="mar-b-none"
          />
          <div class="is-flex justify-between help mar-b">
            <p :class="['has-text-danger', {isHidden: !errors.termsOfService}]">
              {{ errors.termsOfService && errors.termsOfService[0] }}
            </p>
            <p>
              {{ byteSize | numberDisplay }} / {{ maxByteSize | numberDisplay }} bytes
            </p>
          </div>
        </div>
        <div v-else key="preview" class="terms-of-service-preview">
          <div
            class="pad-x-lg mar-b-md has-text-justified"
            v-html="form.termsOfService"
          />
        </div>

        <b-button
          v-if="$can('create', 'MerchantTermsOfService')"
          :key="showPreview ? 'show' : 'hide'"
          rounded
          :class="showPreview && 'mar-t-lg'"
          @click="showPreview = !showPreview"
        >
          {{ showPreview ? 'Hide' : 'Show' }} Preview
        </b-button>
      </panel>
    </validated-form>
  </div>
</template>

<script>
  import merchantMixin from '@/mixins/merchant';

  // classes
  import MerchantTermsOfService from '@/store/classes/MerchantTermsOfService';


  export default {
    name: 'ContentTermsOfServiceForm',

    mixins: [merchantMixin],

    data() {
      return {
        isResettingDefault: false,
        isLoading: false,
        showPreview: false,
        maxByteSize: 65000,
        form: {
          termsOfService: ''
        }
      };
    },

    computed: {
      isFetching() {
        return MerchantTermsOfService.$state().fetching;
      },

      selectedMerchantTermsOfService() {
        return MerchantTermsOfService.find(this.$_selectedMerchantId);
      },

      defaultMerchantTermsOfService() {
        return MerchantTermsOfService.find(0);
      },

      hasDefaultValues() {
        return !!(this.selectedMerchantTermsOfService?.termsOfService === this.defaultMerchantTermsOfService.termsOfService);
      },

      byteSize() {
        return this.form?.termsOfService ? new Blob([this.form.termsOfService]).size : 0;
      }
    },

    created() {
      this.onCreated();
    },

    methods: {
      async onCreated() {
        await this.fetchMerchantTermsOfService();

        if (this.selectedMerchantTermsOfService) {
          this.form = JSON.parse(JSON.stringify(this.selectedMerchantTermsOfService));
        }
        else {
          this.form = {
            termsOfService: this.defaultMerchantTermsOfService.termsOfService
          };
        }
      },

      async fetchMerchantTermsOfService() {
        try {
          await MerchantTermsOfService.fetchMerchantTermsOfServices();
        }

        catch (error) {
          this.$_onRequestError({
            error,
            toastOptions: {
              message: 'There was an error fetching your Terms Of Service'
            }
          });
        }
      },

      async handleSubmit() {
        this.isLoading = true;

        try {
          if (this.selectedMerchantTermsOfService) {
            await MerchantTermsOfService.updateMerchantTermsOfService({
              termsOfService: this.form.termsOfService,
              id: this.selectedMerchantTermsOfService.id
            });
          }

          else {
            this.form = await MerchantTermsOfService.createMerchantTermsOfService({
              merchantId: this.$_selectedMerchantId,
              merchantTermsOfService: {
                termsOfService: this.form.termsOfService
              }
            });
          }

          this.$_onRequestSuccess({
            toastOptions: {
              message: 'Terms and services have been successfully updated!'
            }
          });
        }

        catch (error) {
          this.$_onRequestError({
            error,
            toastOptions: {
              message: 'There was an error updating your terms and services'
            }
          });
        }

        finally {
          this.isLoading = false;
        }
      },

      async resetToDefault() {
        this.isResettingDefault = true;
        try {
          await MerchantTermsOfService.updateMerchantTermsOfService({
            ...this.form,
            termsOfService: this.defaultMerchantTermsOfService.termsOfService
          });

          this.form.termsOfService = this.defaultMerchantTermsOfService.termsOfService;

          this.$_onRequestSuccess({
            toastOptions: {
              message: 'Terms and services have been successfully reset!'
            }
          });
        }

        catch (error) {
          this.$_onRequestError({
            error,
            toastOptions: {
              message: 'There was an error resetting your terms and services'
            }
          });
        }

        finally {
          this.isResettingDefault = false;
        }
      }
    }
  };
</script>

<style lang="sass" scoped>
  .terms-of-service-preview
    max-height: 600px
    overflow-y: auto

</style>
