/* eslint-disable import/no-cycle */
import { Model } from '@vuex-orm/core';
import http from '@/services/http';
import { setUpdatedSortOrders } from '@/helpers/set-new-sort-orders';
import filterObjectKeys from '@/helpers/filter-object-keys';
import Store from '@/store/classes/Store';



export default class Department extends Model {
  static entity = 'departments'

  static fields() {
    return {
      id: this.attr(''),
      publicId: this.attr(''),
      name: this.attr(''),
      photoUrl: this.attr(null),
      storeId: this.attr(''),
      sortOrder: this.attr(''),
      createdAt: this.attr(''),
      updatedAt: this.attr(''),

      // relationships
      store: this.belongsTo(Store, 'storeId')
    };
  }

  // STATE //////////////////////
  static state() {
    return {
      fetching: false,
      submitting: false,
      sorting: false,
      deletingId: false
    };
  }

  static $state() {
    return this.store().state.entities.departments;
  }

  // ACTIONS //////////////////////
  static async fetchDepartments() {
    try {
      this.commit((state) => {
        state.fetching = true;
      });
      const merchantId = this.store().state.entities.merchants.selectedMerchantId;
      const response = await http.get(`merchants/${merchantId}/departments`);

      this.create({ data: response.data.departments });
    }
    catch (error) {
      throw error;
    }
    finally {
      this.commit((state) => {
        state.fetching = false;
      });
    }
  }


  static async sortDepartments({ fromSortOrder, toSortOrder, array }) {
    try {
      this.commit((state) => {
        state.sorting = true;
      });
      const storeId = array[0].storeId;
      const sortedDepartments = setUpdatedSortOrders({ fromIndex: fromSortOrder, toIndex: toSortOrder, array });

      this.insert({ data: sortedDepartments });

      await http.put(`stores/${storeId}/departments/sort_order`, {
        departments: sortedDepartments.map(obj => filterObjectKeys(obj, ['id', 'sortOrder']))
      });
    }

    catch (error) {
      this.insert({ data: array });
      throw error;
    }

    finally {
      this.commit((state) => {
        state.sorting = false;
      });
    }
  }

  static async createDepartment(department) {
    try {
      this.commit((state) => {
        state.submitting = true;
      });
      const merchantId = this.store().state.entities.merchants.selectedMerchantId;
      const { data } = await http.post(`merchants/${merchantId}/departments`, { department });
      this.insert({ data: data.department });
      return data.department;
    }
    catch (error) {
      throw error;
    }
    finally {
      this.commit((state) => {
        state.submitting = false;
      });
    }
  }

  static async updateDepartment(department) {
    try {
      this.commit((state) => {
        state.submitting = true;
      });
      const { data } = await http.put(`departments/${department.id}`, { department });
      this.update({ data: data.department });
      return data.department;
    }
    catch (error) {
      throw error;
    }
    finally {
      this.commit((state) => {
        state.submitting = false;
      });
    }
  }

  static async deleteDepartment(departmentId) {
    try {
      this.commit((state) => {
        state.deletingId = departmentId;
      });
      await http.delete(`departments/${departmentId}`);
      this.delete(departmentId);
    }
    catch (error) {
      throw error;
    }
    finally {
      this.commit((state) => {
        state.deletingId = false;
      });
    }
  }

  static async createDepartmentImage(departmentImage) {
    try {
      const merchantId = this.store().state.entities.merchants.selectedMerchantId;

      const formData = new FormData();
      formData.append('departmentImage[file]', departmentImage);
      const response = await http.post(
        `merchants/${merchantId}/department_images`,
        formData,
        { headers: { 'Content-Type': 'multipart/form-data' } }
      );
      return response.data.departmentImageUrl;
    }
    catch (error) {
      throw error;
    }
  }
}
