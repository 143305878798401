import http from '@/services/http';



export default async function uploadTempImage({ merchantId, imageFile }) {
  try {
    const formData = new FormData();
    formData.append('tempImage[file]', imageFile);
    const response = await http.post(
      `merchants/${merchantId}/uploads/temp_image`,
      formData,
      { headers: { 'Content-Type': 'multipart/form-data' } }
    );
    return response.data.tempImage.url;
  }
  catch (error) {
    throw error;
  }
}
