import { render, staticRenderFns } from "./time-frame-step.vue?vue&type=template&id=5a2ff271&scoped=true&"
import script from "./time-frame-step.vue?vue&type=script&lang=js&"
export * from "./time-frame-step.vue?vue&type=script&lang=js&"
import style0 from "./time-frame-step.vue?vue&type=style&index=0&id=5a2ff271&lang=sass&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5a2ff271",
  null
  
)

export default component.exports