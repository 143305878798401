import capitalCase from '@/helpers/capitalCase';
import { camelCase } from 'change-case';
import FetchModel from './FetchModel';

export default class UpsellDisplayMode extends FetchModel {
  static entity = 'upsellDisplayModes'

  static fields() {
    return {
      id: this.attr(''),
      name: this.attr('')
    };
  }

  get displayName() {
    return capitalCase(this.name);
  }

  // STATE //////////////////////
  static state() {
    return {
      fetching: false
    };
  }

  static $state() {
    return this.store().state.entities.upsellDisplayModes;
  }

  static async fetchUpsellDisplayModes(options = {}) {
    await this.fetchData({
      endpoint: 'upsell_display_modes',
      options,
      transformData: data => data.upsellDisplayModes
    });
  }
}
