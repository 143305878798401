<template>
  <validated-form
    ref="form"
    auto-focus
    form-id="addEditPromoCodeForm"
    @valid-submit="handleSubmit"
  >
    <modal-card :title="modalTitle">
      <validated-text-input
        v-model="form.promotionCode"
        :has-icon="false"
        label="Name"
        name="promotionCode"
        sub-label="Alphanumeric only. No special characters. Not case sensitive. Must be unique."
        :rules="{
          required: true,
          alpha_num: true,
          uniqueStringCaseInsensitive: groupPromoCodeNames
        }"
        type="text"
        :disabled="isReadOnly"
      />

      <div class="is-flex justify-between align-center mar-t-md">
        <div class="has-text-grey" style="font-size: 0.9em">
          <p v-if="isReadOnly">
            Code ID: <span class="is-monospaced">{{ form.publicId }}</span>
          </p>
          <p v-if="isRevoked" class="has-text-grey mar-t-xs">
            Revoked on {{ formatDate(form.invalidatedDateTime) }}
          </p>
        </div>
        <b-button
          v-if="isReadOnly && canRevoke"
          class="revoke-button"
          :disabled="isRevoked"
          @click="confirmRevoke"
        >
          <b-icon icon="trash-alt" type="is-danger" />
        </b-button>
      </div>
      <template #footer>
        <div class="buttons all-bold">
          <b-button
            rounded
            @click="$_confirmCloseModal({ programmatic: true })"
          >
            Cancel
          </b-button>
          <b-button
            v-tabbable
            rounded
            native-type="submit"
            type="is-primary"
            :loading="isSubmitting"
            :disabled="isSubmitting"
          >
            Save
          </b-button>
        </div>
      </template>
    </modal-card>
  </validated-form>
</template>

<script>
  import moment from 'moment-timezone';
  import { mapGetters } from 'vuex';
  import OfferDistributionCode from '@/store/classes/OfferDistributionCode';
  import confirmModalCloseMixin from '@/mixins/confirm-modal-close';

  export default {
    name: 'AddEditPromoCodeModal',

    mixins: [confirmModalCloseMixin],

    props: {
      promoCode: {
        type: Object,
        required: true
      }
    },

    data() {
      return {
        form: new OfferDistributionCode()
      };
    },

    computed: {
      ...mapGetters('session', ['isCardfreeAdmin', 'hasMarketingAccess']),

      groupPromoCodeNames() {
        return OfferDistributionCode.query()
          .where('offerDistributionId', this.promoCode.offerDistributionPublicId)
          .get()
          .map(code => code.promotionCode);
      },

      modalTitle() {
        return this.isReadOnly ? this.promoCode.promotionCode : 'Create Promo Code';
      },

      isSubmitting() {
        return OfferDistributionCode.$state().submitting;
      },

      isRevoked() {
        return this.promoCode.invalidatedDateTime
          ? moment(this.promoCode.invalidatedDateTime).isBefore(moment())
          : false;
      },

      canRevoke() {
        return this.hasMarketingAccess || this.isCardfreeAdmin;
      },

      isReadOnly() {
        return !!this.promoCode.publicId;
      }
    },

    created() {
      this.form = this.$clone(this.promoCode);
    },

    methods: {
      handleSubmit() {
        if (!this.isReadOnly) {
          this.confirmCreate();
        }
      },

      confirmCreate() {
        this.$buefy.dialog.confirm({
          title: 'Confirm Promo Code Creation',
          message: 'Are you sure you want to save this promo code? Promo codes cannot be edited once created.',
          confirmText: 'Yes',
          cancelText: 'No',
          type: 'is-primary',
          onConfirm: () => this.savePromoCode()
        });
      },

      async savePromoCode() {
        try {
          const promoCodeId = await OfferDistributionCode.addOfferDistributionCode(this.form);

          this.$_onRequestSuccess({
            toastOptions: {
              message: `Successfully created promo code <b>${this.form.promotionCode}</b>!`
            },
            options: {
              closeParent: true,
              emit: { name: 'promo-code-updated', arg: promoCodeId }
            }
          });
        }
        catch (error) {
          this.$_onRequestError({
            error,
            toastOptions: {
              message: `Unable to create promo code <b>${this.form.promotionCode}</b>`
            }
          });
        }
      },

      confirmRevoke() {
        this.$buefy.dialog.confirm({
          title: 'Confirm Promo Code Revocation',
          message: `Are you sure you want to revoke the promo code "${this.form.promotionCode}"? This action cannot be undone.`,
          confirmText: 'Revoke',
          cancelText: 'Cancel',
          type: 'is-danger',
          onConfirm: () => this.revokePromoCode()
        });
      },

      async revokePromoCode() {
        try {
          await OfferDistributionCode.invalidateOfferDistributionCode(this.promoCode);

          this.$_onRequestSuccess({
            toastOptions: {
              message: `Successfully revoked promo code <b>${this.form.promotionCode}</b>!`
            },
            options: {
              closeParent: true,
              emit: { name: 'promo-code-updated', arg: this.promoCode.publicId }
            }
          });
        }
        catch (error) {
          this.$_onRequestError({
            error,
            toastOptions: {
              message: `Unable to revoke promo code <b>${this.form.promotionCode}</b>`
            }
          });
        }
      },

      formatDate(dateObj) {
        return moment(dateObj).format('M/D/YY');
      }
    }
  };
</script>

<style lang="sass" scoped>
  .modal-card
    max-width: 500px

  .revoke-button
    background-color: #FFEBEB
    border-radius: 5px
    border-color: darken(#FFEBEB, 5%)
    &:not(:disabled):hover
      background-color: darken(#FFEBEB, 5%)
</style>
