import { render, staticRenderFns } from "./modifier-group-settings-form.vue?vue&type=template&id=66d0a594&scoped=true&"
import script from "./modifier-group-settings-form.vue?vue&type=script&lang=js&"
export * from "./modifier-group-settings-form.vue?vue&type=script&lang=js&"
import style0 from "./modifier-group-settings-form.vue?vue&type=style&index=0&id=66d0a594&lang=sass&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "66d0a594",
  null
  
)

export default component.exports