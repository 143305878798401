<template>
  <section-wrapper title="Payment Providers">
    <b-table
      :data="merchantPaymentGateways"
      class="is-middle-aligned flex-grow"
      hoverable
      :mobile-cards="false"
    >
      <template #empty>
        <p class="has-text-centered pad is-size-5 has-text-grey-light">
          No Payment Providers Configured
        </p>
      </template>

      <b-table-column v-slot="{ row }" sortable field="paymentGateway.name" label="Name">
        {{ row.paymentGateway.name }}
      </b-table-column>

      <b-table-column
        v-slot="{ row }"
        centered
        sortable
        field="isActive"
        label="Active"
      >
        <b-icon
          :icon="row.isActive ? 'check' : 'times'"
          :type="row.isActive ? 'is-success' : 'is-danger'"
          size="is-small"
        />
      </b-table-column>

      <b-table-column
        v-slot="{ row }"
        centered
        cell-class="actions"
        label="Actions"
        width="1"
      >
        <div class="is-flex dist-x-xs">
          <b-button class="is-transparent" type="is-white" @click="openPaymentGatewayModal(row)">
            <b-icon icon="pencil" size="is-small" />
          </b-button>
          <b-button
            class="is-transparent"
            type="is-white"
            @click="openRemovePaymentGatewayModal(row)"
          >
            <b-icon icon="trash-alt" type="is-danger" size="is-small" />
          </b-button>
        </div>
      </b-table-column>
    </b-table>
    <div class="pad-x pad-y-sm has-border-top has-border-grey-lighter">
      <b-button
        inverted
        class="is-transparent"
        type="is-primary"
        icon-left="plus"
        :loading="submittingPaymentGateway"
        :disabled="!canAddPaymentGateways"
        @click="openPaymentGatewayModal(null)"
      >
        New Payment Provider
      </b-button>
    </div>
  </section-wrapper>
</template>

<script>
  import MerchantPaymentGateway from '@/store/classes/MerchantPaymentGateway';
  import addEditPaymentGatewayModal from './add-edit-payment-gateway-modal.vue';
  import PaymentGateway from '@/store/classes/PaymentGateway';

  export default {
    name: 'MerchantPaymentConfigs',

    props: {
      merchant: {
        type: Object,
        required: true
      }
    },

    computed: {
      submittingPaymentGateway() {
        return MerchantPaymentGateway.$state().submitting;
      },

      allPaymentGatewayOptions() {
        return PaymentGateway.all();
      },

      canAddPaymentGateways() {
        return this.allPaymentGatewayOptions.length !== this.merchantPaymentGateways.length;
      },

      merchantPaymentGateways() {
        return this.merchant.merchantPaymentGateways.filter(mpg => !mpg.storeId);
      }
    },
    methods: {
      openRemovePaymentGatewayModal(currentPayment) {
        this.$buefy.dialog.confirm({
          title: 'Delete Payment Provider',
          message: `Deleting <b>${currentPayment.paymentGateway.name}</b> will delete its configuration at the merchant level and location level. This will impact your online ordering application. Are you sure?`,
          type: 'is-danger',
          confirmText: 'Delete',
          onConfirm: () => {
            this.removePaymentGateway(currentPayment);
          }
        });
      },

      openPaymentGatewayModal(currentPaymentGateway) {
        this.$buefy.modal.open({
          parent: this,
          component: addEditPaymentGatewayModal,
          hasModalCard: true,
          trapFocus: true,
          canCancel: false,
          customClass: 'full-width',
          props: {
            merchantPaymentGatewayIds: this.merchantPaymentGateways.map(mpg => mpg.paymentGateway.id),
            merchant: this.merchant,
            currentPaymentGateway
          }
        });
      },

      async removePaymentGateway(currentPayment) {
        try {
          await MerchantPaymentGateway.removePaymentGateway(currentPayment.id);
          this.$_onRequestSuccess({
            toastOptions: {
              message: 'Successfully removed your payment gateway!'
            }
          });
        }
        catch (error) {
          this.$_onRequestError({
            error,
            toastOptions: {
              message: 'There was an error removing your payment gateways'
            }
          });
        }
      }
    }
  };
</script>
