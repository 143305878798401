<template>
  <div>
    <transition name="fade-right">
      <span v-if="showTwoFactorForm" class="link is-absolute" @click="backToSignIn">
        <b-icon icon="chevron-left" size="is-small" />
        Back
      </span>
    </transition>

    <header class="has-text-centered mar-b-md">
      <img class="image is-inline is-64x64" src="/images/cardfree-icon-logo.svg" alt="Admin Portal - Cardfree">
      <h1 class="title">Sign In</h1>
    </header>

    <transition name="fade-zoom-between" mode="out-in">
      <!-- Two Factor Auth Form -->
      <div v-if="showTwoFactorForm" key="twoFactor">
        <div v-if="twoFactorErrorMessage" class="mar-b">
          <b-message type="is-danger" class="has-shadow is-compact">
            {{ twoFactorErrorMessage }}
          </b-message>
        </div>

        <countdown-message :active.sync="showPasscodeResendMessage" hide-close-button :duration="7000" class="is-compact">
          <div class="is-flex align-center gap-sm">
            <b-icon icon="paper-plane" type="is-primary-dark" />
            <span>A new passcode has been sent</span>
          </div>
        </countdown-message>

        <validated-form
          v-entrap-focus
          form-id="twoFactorForm"
          :track-changes="false"
          @valid-submit="twoFactorSubmit"
        >
          <p class="has-text-centered mar-b">
            Verify it's you by entering the passcode sent to
            <span class="has-text-primary">{{ maskedEmail }}</span>
          </p>
          <validated-text-input
            key="twoFactorPasscode"
            v-model="passcode"
            type="text"
            monospaced
            name="twoFactorCode"
            label="Passcode"
            rules="required"
          />
          <span class="link" @click="sendNewPasscode">Send a new passcode</span>
          <b-button
            rounded
            native-type="submit"
            type="is-primary"
            size="is-medium"
            class="is-fullwidth mar-t-md"
            :loading="isLoading"
          >
            Verify
          </b-button>
        </validated-form>
      </div>

      <!-- Sign In Form -->
      <div v-else key="signIn">
        <transition name="fade-down" mode="out-in">
          <div v-if="signInErrorMessage || queryError" key="error" class="mar-b">
            <b-message type="is-danger" class="has-shadow is-compact">
              {{ signInErrorMessage || queryError }}
            </b-message>
          </div>
          <div v-else-if="showSignInWarning" key="warning" class="mar-b">
            <b-message type="is-warning" class="has-shadow is-compact">
              You must sign in to access this page
            </b-message>
          </div>
        </transition>

        <validated-form
          v-entrap-focus
          form-id="signInForm"
          :track-changes="false"
          @valid-submit="signIn"
        >
          <validated-text-input
            v-model="email"
            type="email"
            name="signInEmail"
            label="Email"
            rules="required"
            hide-required-indicator
          />
          <validated-text-input
            v-model="password"
            type="password"
            name="signInPassword"
            rules="required"
            label="Password"
            password-reveal
            hide-required-indicator
          />
          <router-link :to="{ name: 'forgotPassword' }">Forgot your password?</router-link>

          <b-button
            rounded
            native-type="submit"
            type="is-primary"
            size="is-medium"
            class="is-fullwidth mar-t-md"
            :loading="isLoading"
          >
            Sign In
          </b-button>

          <template v-if="!isLoadingFeatures && microsoftOauth2SsoEnabled">
            <div class="divider">OR</div>

            <b-button
              rounded
              type="is-dark"
              size="is-medium"
              tag="a"
              :href="microsoftAuthorizationUrl"
              class="is-fullwidth"
            >
              <div class="is-flex align-center">
                <img
                  src="/images/microsoft-icon-logo.svg"
                  alt="Microsoft"
                  title="Microsoft"
                  class="microsoft-icon"
                >
                <span class="microsoft-text">Sign in with Microsoft</span>
              </div>
            </b-button>
          </template>

        </validated-form>
      </div>
    </transition>
  </div>
</template>



<script>
  import { mapState } from 'vuex';
  import storage from '@/services/storage';
  import { v4 as uuid } from 'uuid';

  export default {
    name: 'SignInForm',

    props: {
      showSignInWarning: {
        type: Boolean,
        default: false
      }
    },

    data() {
      return {
        email: null,
        password: null,
        passcode: null,
        showTwoFactorForm: false,
        twoFactorCookie: null,
        showPasscodeResendMessage: false,
        isLoading: false,
        twoFactorErrorMessage: null,
        signInErrorMessage: null
      };
    },

    computed: {
      ...mapState('session', ['isExpired']),
      ...mapState({
        isLoadingFeatures: state => state.portalSystemFeatures.isLoading
      }),

      maskedEmail() {
        const cookieValue = decodeURI(this.twoFactorCookie.split('--')[0]);
        const parsedCookie = JSON.parse(Buffer.from(cookieValue, 'base64'));
        const decodedValue = Buffer.from(parsedCookie._rails.message, 'base64');
        const email = JSON.parse(decodedValue);

        const [userName, mailServer] = email.split('@');
        const firstLetter = userName[0];
        const lastLetter = userName[userName.length - 1];
        const domain = `@${mailServer}`;

        let masked = firstLetter;
        for (let i = 0; i < userName.length - 2; i++) {
          masked += '*';
        }
        masked += lastLetter;
        masked += domain;

        return masked;
      },

      microsoftOauth2SsoEnabled() {
        return this.$store.getters['portalSystemFeatures/microsoftOauth2SsoEnabled'];
      },

      microsoftAuthorizationUrl() {
        const url = new URL('https://login.microsoftonline.com/common/oauth2/v2.0/authorize');

        const params = new URLSearchParams({
          client_id: this.msClientId,
          response_type: 'code',
          redirect_uri: this.msRedirectUri,
          scope: this.msScopes,
          state: this.msState
        });

        url.search = params.toString();

        return url.toString();
      },

      msClientId() {
        return this.$store.getters['portalSystemFeatures/microsoftOauth2ClientId'];
      },

      msRedirectUri() {
        return `${window.location.origin}/api/v1/oauth_callbacks/microsoft_oauth2`;
      },

      msScopes() {
        return encodeURIComponent('.default');
      },

      // A randomly generated unique value. used for preventing cross-site request forgery attacks
      msState() {
        return uuid();
      },

      queryError() {
        return this.$route.query.error_message;
      }
    },

    watch: {
      isExpired: {
        immediate: true,
        handler: 'handleExpiredSession'
      }
    },

    created() {
      this.onCreated();
    },

    methods: {
      async onCreated() {
        this.checkValidTwoFactorCookie();
        await this.$store.dispatch('portalSystemFeatures/fetchFeatures');
      },

      handleExpiredSession(expired) {
        if (expired) {
          this.signInErrorMessage = 'Your session has expired. Please re-enter your credentials to continue.';
          this.showTwoFactorForm = false;
        }
      },

      checkValidTwoFactorCookie() {
        const cookie = storage.cookie.get('two_factor') || null;
        this.twoFactorCookie = cookie;
        this.showTwoFactorForm = !!cookie;
        return !!cookie;
      },

      clearSignInForm() {
        this.email = null;
        this.password = null;
        this.signInErrorMessage = null;
      },

      backToSignIn() {
        this.clearSignInForm();
        this.showTwoFactorForm = false;
      },

      async signIn() {
        try {
          this.isLoading = true;
          this.passcode = null;

          const signInArgs = {
            email: this.email,
            password: this.password
          };

          if (this.$route.query.client_id) {
            signInArgs.stream = {
              clientId: this.$route.query.client_id,
              responseType: this.$route.query.response_type,
              redirectUri: this.$route.query.redirect_uri,
            }
          }

          await this.$store.dispatch('session/signIn', signInArgs);

          if (this.$route.query.client_id) {
            this.$router.replace({ name: 'streamConsent', query: signInArgs.stream });
          } else {
            this.clearSignInForm();
            this.checkValidTwoFactorCookie();
          }
        }
        catch (error) {
          this.signInErrorMessage = error.status === 401
            ? 'The email or password you entered did not match your account'
            : 'Our service doesn\'t seem to be responding right now. Please try again later';
        }
        finally {
          this.isLoading = false;
        }
      },

      async twoFactorSubmit() {
        try {
          this.isLoading = true;
          await this.$store.dispatch('session/twoFactorAuth', this.passcode);
        }
        catch (error) {
          // if the two factor cookie is still present
          //   then it failed because they entered an expired or incorrect code
          if (this.checkValidTwoFactorCookie()) {
            this.twoFactorErrorMessage = error.data?.error || 'The passcode you entered is incorrect';
          }
          // if the cookie is not present
          //   then the session has expired
          else this.$store.commit('session/SET_SESSION_EXPIRED');
        }
        finally {
          this.isLoading = false;
        }
      },

      async sendNewPasscode() {
        try {
          await this.$store.dispatch('session/sendNewPasscode');
          this.showPasscodeResendMessage = true;
        }
        catch (error) {
          if (error.status === 401) {
            this.$store.commit('session/SET_SESSION_EXPIRED');
          }
          else {
            this.$_onRequestError({
              toastOptions: {
                message: 'There was an issue resending your authorization code'
              },
              error
            });
          }
        }
      }
    }
  };
</script>

<style lang="sass" scoped>
  .microsoft-icon
    position: absolute
    left: 1.2rem
    height: 30px

  .microsoft-text
    font-family: 'Segoe UI', sans-serif
    font-weight: 600
    font-size: 1rem
    margin-left: 1.2rem

  .divider
    display: flex
    align-items: center
    margin: 1rem 0

    &::before,
    &::after
      content: ''
      flex: 1
      border-bottom: 1px solid #dbdbdb

    &::before
      margin-right: .5em

    &::after
      margin-left: .5em
</style>
