<template>
  <validated-form
    ref="itemUpsellForm"
    :disabled="!$can('update', 'MenuItem') || !$_menuPermissions.EDIT_RESOURCE"
    class="item-upsell-form"
    form-id="itemUpsellForm"
  >
    <p class="subtitle is-5 is-marginless">
      Upsell
    </p>
    <p class="is-size-7 has-text-grey mar-b-md">
      Choose other menu items to upsell for this item
    </p>
    <b-field
      label="Search By"
      label-position="on-border"
      :class="['search-field', { 'mar-b-none': searchResults.length }]"
      :message="isEmptySearch ? 'No results found' : ''"
    >
      <b-select v-model="searchType">
        <option value="name">Name</option>
        <option value="pos">PLU#</option>
      </b-select>
      <b-input
        ref="searchInput"
        v-model="searchQuery"
        expanded
        placeholder="Search for an Item to upsell..."
        :icon-right="searchQuery ? 'times-circle' : null"
        icon-right-clickable
        type="text"
        @icon-right-click="clearSearch"
        @keydown.native.esc="clearSearch"
        @keypress.native.enter.prevent
      />
      <div class="control">
        <b-button
          v-tabbable
          :disabled="!searchQuery"
          class="has-text-weight-bold flex-small"
          :loading="isLoadingSearchResults"
          @click="searchItems"
        >
          Search
        </b-button>
      </div>
    </b-field>

    <b-dropdown
      v-if="searchResults.length"
      ref="dropdown"
      expanded
      scrollable
      :mobile-modal="false"
      max-height="300px"
      aria-role="list"
      class="has-extra-shadow search-dropdown"
      :position="'is-bottom-right'"
      @active-change="handleDropdownBlur"
    >
      <b-dropdown-item
        v-for="item in searchResults"
        :key="item.id"
        :focusable="false"
        :value="item.id"
        :disabled="!!upsellItems.find(upsellItem => upsellItem.upsellMenuItemId === item.id)"
        @click="addItemToUpsell(item)"
        @input="emitInput"
      >
        <p class="dropdown-item--text__primary">
          {{ item.displayName }}
        </p>
        <p v-if="item.posItems.length" class="dropdown-item--text__secondary">
          {{ item.posItems[0].id }}
        </p>
      </b-dropdown-item>
    </b-dropdown>

    <template v-if="upsellItems.length">
      <p class="subtitle is-5 mar-b">
        Upsell Items
      </p>
      <draggable
        v-bind="draggableAttributes"
        :list="upsellItems"
        tag="ul"
        class="dist-y-xs"
        :force-fallback="true"
        @change="handlePriorityChange"
      >
        <li
          v-for="item in upsellItems"
          :key="item.upsellMenuItemId"
          class="has-background-white is-flex align-center has-text-weight-bold"
        >
          <b-icon
            icon="grip-lines"
            size="is-small"
            pack="far"
            class="drag-handle mar-x-sm"
          />
          <span class="flex-grow">{{ item.displayName }}</span>
          <span>
            <b-button class="button is-transparent" @click="removeUpsell(item)">
              <b-icon icon="minus-circle" type="is-danger" size="is-small" />
            </b-button>
          </span>
        </li>
      </draggable>
    </template>
    <p v-else class="has-text-grey-light has-text-centered pad">
      Search for an Item to upsell
    </p>

    <template v-if="upsoldByItems.length || upsoldByCategories.length">
      <hr>
      <p class="subtitle is-5 mar-b">
        Upsold by
      </p>
      <ul>
        <template v-if="upsoldByItems.length">
          <li v-if="upsoldByCategories.length" class="has-text-grey mar-t">Items</li>
          <li
            v-for="item in upsoldByItems"
            :key="item.id"
            class="has-background-white is-flex align-center justify-between has-text-weight-bold"
          >
            <span class="mar-y-sm">{{ item.displayName }}</span>
            <b-button :disabled="isUpSold(item)" class="is-transparent" @click="addItemToUpsell(item)">
              <b-icon icon="plus" size="is-small" />
            </b-button>
          </li>
        </template>
        <template v-if="upsoldByCategories.length">
          <li v-if="upsoldByItems.length" class="has-text-grey mar-t">Categories</li>
          <li
            v-for="category in upsoldByCategories"
            :key="category.id"
            class="has-background-white is-flex align-center has-text-weight-bold"
          >
            <span class="mar-y-sm">{{ category.displayName }}</span>
          </li>
        </template>
      </ul>
    </template>

    <b-message
      v-if="addingError"
      type="is-danger"
      size="is-small"
      class="mar-t is-compact has-shadow"
      has-icon
    >
      {{ addingError }}
    </b-message>
  </validated-form>
</template>

<script>
  import draggable from 'vuedraggable';

  import { searchMenuByResource } from '@/api/menu';

  import getChangedResources from '@/helpers/get-changed-resources';

  import merchantMixin from '@/mixins/merchant';
  import multiFormChildProvider from '@/mixins/multiFormMixin/multiFormChildProvider';

  import { emitNativeInput } from '@/services/emit-input';

  import MenuItemUpsell from '@/store/classes/MenuItemUpsell';

  export default {
    name: 'ItemUpsellForm',
    components: { draggable },
    mixins: [merchantMixin, multiFormChildProvider],
    props: {
      itemId: {
        type: Number,
        default: null
      },
      mode: {
        type: String,
        default: 'create',
        validator(value) {
          return ['create', 'read', 'update'].includes(value);
        }
      },
      formRef: {
        type: String,
        required: true
      }
    },
    data() {
      return {
        draggableAttributes: {
          animation: '200',
          ghostClass: 'ghost',
          handle: '.drag-handle'
        },
        isEmptySearch: false,
        searchQuery: '',
        searchType: 'name',
        searchResults: [],
        upsellItems: [],
        upsoldByItems: [],
        upsoldByCategories: [],
        isLoadingSearchResults: false,
        addingError: null
      };
    },
    computed: {
      previousUpsellItems() {
        return MenuItemUpsell.query().where('menuItemId', this.itemId).orderBy('priority').get();
      }
    },
    async created() {
      await this.onCreated();
    },
    methods: {
      async onCreated() {
        if (this.mode === 'update') {
          await Promise.all([
            this.fetchUpsellItems(),
            this.fetchUpsoldByItems(),
            this.fetchUpsoldByCategories()
          ]);
          this.upsellItems = this.$clone(this.previousUpsellItems);
        }
      },

      emitInput() {
        const formElement = this.$refs.itemUpsellForm.$refs.formElement;
        emitNativeInput(formElement);
      },

      async fetchUpsellItems() {
        try {
          await MenuItemUpsell.fetchUpsellItems(this.itemId);
        }
        catch (error) {
          this.$_onRequestError({
            toastOptions: { message: 'Unable to fetch upsold items' },
            error
          });
        }
      },

      async fetchUpsoldByItems() {
        try {
          this.upsoldByItems = await MenuItemUpsell.fetchUpsoldByItems(this.itemId);
        }
        catch (error) {
          this.$_onRequestError({
            toastOptions: { message: 'Unable to fetch upsold by items' },
            error
          });
        }
      },

      async fetchUpsoldByCategories() {
        try {
          this.upsoldByCategories = await MenuItemUpsell.fetchUpsoldByCategories(this.itemId);
        }
        catch (error) {
          this.$_onRequestError({
            toastOptions: { message: 'Unable to fetch upsold by categories' },
            error
          });
        }
      },

      isUpSold(item) {
        return this.upsellItems.find(upsellItem => upsellItem.upsellMenuItemId === item.id);
      },

      handleDropdownBlur(isActive) {
        if (!isActive) {
          this.clearSearch();
        }
      },

      handlePriorityChange() {
        this.upsellItems = this.resetPriority(this.$clone(this.upsellItems));
        this.emitInput();
      },

      resetPriority(items) {
        return items.map((item, index) => {
          item.priority = index + 1;
          return item;
        });
      },

      async addUpsellItems(itemId) {
        if (this.upsellItems.length) {
          const upsellMenuItemIds = this.upsellItems.map(item => ({ upsellMenuItemId: item.upsellMenuItemId }));
          await MenuItemUpsell.addUpsellItems({ upsellMenuItemIds, itemId });
        }
      },

      async updateUpsellItems() {
        const { added, removed } = getChangedResources({
          newArray: this.upsellItems,
          oldArray: this.previousUpsellItems,
          comparisonKey: 'id'
        });
        if (removed.length > 0) {
          await MenuItemUpsell.deleteUpsellSellItems({
            menuItemUpsellIds: removed.map(item => item.id),
            itemId: this.itemId
          });
        }
        const upsellItems = this.$clone(this.upsellItems);
        if (added.length > 0) {
          const upsellMenuItemIds = added.map(item => ({ upsellMenuItemId: item.upsellMenuItemId }));
          const addedUpsellItems = await MenuItemUpsell.addUpsellItems({ upsellMenuItemIds, itemId: this.itemId });
          upsellItems.forEach((item) => {
            const addedItem = addedUpsellItems.find(ai => ai.upsellMenuItemId === item.upsellMenuItemId);
            if (addedItem) {
              item.id = addedItem.id;
            }
          });
        }
        await MenuItemUpsell.updateUpsellItemsPriority({ items: upsellItems, itemId: this.itemId });
      },

      toggleLoadingSearchResults(value) {
        this.isLoadingSearchResults = value;
      },

      async searchItems() {
        try {
          this.isEmptySearch = false;
          this.addingError = null;
          this.toggleLoadingSearchResults(true);
          const results = await searchMenuByResource({
            merchantId: this.$_selectedMerchantId,
            resourceType: 'menuItems',
            searchQuery: this.searchQuery,
            searchType: this.searchType
          });
          this.isEmptySearch = !results.length;
          this.searchResults = results;
          if (!this.isEmptySearch) {
            setTimeout(() => {
              this.$refs.dropdown.toggle();
            }, 0);
          }
        }
        catch (error) {
          this.$_onRequestError({
            toastOptions: {
              message: 'There was an error with your search',
              error
            }
          });
        }
        finally {
          this.toggleLoadingSearchResults(false);
        }
      },

      addItemToUpsell(item) {
        this.emitInput();
        this.clearSearch();
        const hasMaxAmount = this.upsellItems.length === 10;

        if (hasMaxAmount) {
          this.addingError = 'Cannot upsell more than 10 items';
        }
        else {
          const newUpsellItem = new MenuItemUpsell({
            menuItemId: this.itemId,
            priority: this.upsellItems.length + 1,
            displayName: item.displayName,
            upsellMenuItemId: item.id
          });
          this.upsellItems.push(newUpsellItem);
        }
      },

      clearSearch() {
        this.addingError = null;
        this.searchQuery = '';
        this.isEmptySearch = false;
        this.searchResults = [];
      },

      removeUpsell(item) {
        this.emitInput();
        this.addingError = null;
        const upsellItems = this.$clone(this.upsellItems);
        const filteredItems = upsellItems.filter(upsellItem => upsellItem.upsellMenuItemId !== item.upsellMenuItemId);
        this.upsellItems = this.resetPriority(filteredItems);
      },

      async handleSubmit(itemId = this.itemId) {
        try {
          switch (this.mode) {
            case 'create':
              await this.addUpsellItems(itemId);
              break;
            case 'update':
              await this.updateUpsellItems();
              break;
            default:
              break;
          }
          return itemId;
        }
        catch (error) {
          throw error;
        }
      }

    }
  };
</script>

<style lang="sass" scoped>
  .item-upsell-form
    min-height: 400px

  ::v-deep
    .dropdown-menu
      padding-top: 0 !important
</style>
