var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',_vm._l((_vm.searchResults),function(modifier){return _c('div',{key:("modifier-" + (modifier.id)),class:{
      'show-inventory': _vm.$_menuPermissions.UPDATE_INVENTORY,
      'hide-prices':!_vm.$_selectedMerchant.features.menuPrices
    },attrs:{"data-modifier-id":modifier.id}},[_c('div',{staticClass:"row sub-row"},[_c('div',{staticClass:"is-flex-column align-start pad-y-sm"},[(_vm.$_featurePermissions.NESTED_MODIFIERS && modifier.menuItemModifierGroup.type === _vm.modifierGroupTypes.PORTION)?_c('img',{class:['mar-r-sm', { 'transform-180': ['Right Half', '2nd Half'].includes(modifier.displayName) }],attrs:{"src":_vm.portionIcons[modifier.modifierTemplate]}}):_vm._e(),_c('span',[_c('span',{staticClass:"has-text-weight-bold is-size-6"},[_vm._v(_vm._s(modifier.displayName))]),(!modifier.mappedToPos && !_vm.$_hasPosType(_vm.posTypes.Cardfree))?_c('b-icon',{directives:[{name:"tippy",rawName:"v-tippy",value:({ content: 'Missing POS Mapping', placement: 'right' }),expression:"{ content: 'Missing POS Mapping', placement: 'right' }"}],staticClass:"mar-l",attrs:{"size":"is-small","icon":"exclamation-triangle","type":"is-danger"}}):_vm._e()],1),_c('span',{staticClass:"is-size-7"},[_c('b',[_vm._v("Modifier Group:")]),_vm._v(" "+_vm._s(modifier.menuItemModifierGroup.displayName))])]),(_vm.$_selectedMerchant.features.menuPrices)?_c('span',[(!_vm.$_featurePermissions.NESTED_MODIFIERS || modifier.menuItemModifierGroup.type !== _vm.modifierGroupTypes.PORTION)?[(_vm.getStoreModifierPrice(modifier))?[_c('div',{staticClass:"is-flex align-center"},[_c('span',[_vm._v(_vm._s(_vm._f("dollars")(_vm.getStoreModifierPrice(modifier))))]),(_vm.getStoreModifierPriceOverrides(modifier).length)?_c('b-icon',{directives:[{name:"tippy",rawName:"v-tippy",value:({
                  content: _vm.getPriceOverridesTippyContent(modifier),
                  placement: 'left'
                }),expression:"{\n                  content: getPriceOverridesTippyContent(modifier),\n                  placement: 'left'\n                }"}],staticClass:"has-text-info mar-l-xs",attrs:{"icon":"circle-dollar","size":"is-small"}}):_vm._e()],1)]:_c('span',{staticClass:"has-text-grey"},[_vm._v("N/A")])]:_vm._e()],2):_vm._e(),(_vm.$_menuPermissions.UPDATE_INVENTORY)?_c('span'):_vm._e(),_c('span',{staticClass:"align-right"},[(_vm.showSuspendedIconForModifier(modifier))?_c('b-icon',{directives:[{name:"tippy",rawName:"v-tippy",value:({
            content: ("Unavailable until " + (_vm.adjustDateToStoreTimezone(_vm.getStoreModifierSuspendUntilDate(modifier)).format('MM/DD/YY h:mm A')) + " " + _vm.timeZoneAbbr),
            placement: 'left',
            delay: [333, 0]
          }),expression:"{\n            content: `Unavailable until ${adjustDateToStoreTimezone(getStoreModifierSuspendUntilDate(modifier)).format('MM/DD/YY h:mm A')} ${timeZoneAbbr}`,\n            placement: 'left',\n            delay: [333, 0]\n          }"}],attrs:{"icon":"alarm-clock","size":"is-small"}}):_vm._e(),(!_vm.$_featurePermissions.NESTED_MODIFIERS || modifier.menuItemModifierGroup.type !== _vm.modifierGroupTypes.PORTION)?_c('b-button',{staticClass:"is-transparent",on:{"click":function($event){return _vm.emitSharedModGroupsSearchAction({ actionName: 'open-store-modifier-modal', args: { modifier: modifier, storeId: _vm.selectedStoreId } })}}},[((_vm.$can('update', 'StoreMenuItemModifier', 'price') || _vm.$can('update', 'StoreMenuItemModifier', 'totalSalesTaxRate'))
              && _vm.$_menuPermissions.UPDATE_PRICE_TAX)?_c('b-icon',{attrs:{"icon":"pencil","size":"is-small"}}):_c('span',[_vm._v("View")])],1):_c('span')],1)])])}),0)}
var staticRenderFns = []

export { render, staticRenderFns }