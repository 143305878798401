import { Model } from '@vuex-orm/core';
import http from '@/services/http';
import capitalCase from '@/helpers/capitalCase';

export default class LocationServiceType extends Model {
  static entity = 'locationServiceTypes';

  static typeIds = {
    SQUARE: 1,
    TOAST: 2
  }

  static fields() {
    return {
      id: this.attr(''),
      name: this.attr('', name => capitalCase(name)),
      description: this.attr('')
    };
  }

  // STATE
  static state() {
    return {
      fetching: false
    };
  }

  static $state() {
    return this.store().state.entities.locationServiceTypes;
  }

  static async fetchLocationServiceTypes(options = {}) {
    try {
      this.commit((state) => {
        state.fetching = true;
      });

      const alreadyFetched = this.query().exists();

      if (!alreadyFetched || options.forceFetch) {
        const { data: { locationServiceTypes } } = await http.get('location_service_types');

        this.create({ data: locationServiceTypes });
      }
    }

    catch (error) {
      throw error;
    }

    finally {
      this.commit((state) => {
        state.fetching = false;
      });
    }
  }
}
