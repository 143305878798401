<template>
  <validation-observer vid="email-design-form" tag="div">
    <validated-text-input
      :value="campaignEmailTemplate.subject"
      name="emailSubject"
      type="text"
      label="Subject"
      rules="required"
      :custom-messages="{ required: 'You must specify the email Subject' }"
      sub-label="The subject line of your campaign email"
      @input="handleEmailTemplatesChange({ subject: $event })"
    />
    <validated-input
      name="emailTemplateId"
      label="Email Template"
      sub-label="The templated layout for your campaign email"
      :custom-messages="{ required: 'You must select an email template' }"
      rules="required"
    >
      <b-checkbox :value="campaignEmailTemplate.templateId || null" class="is-hidden" />
      <div v-if="selectableTemplateOptions.length" class="is-flex gap">
        <button
          v-for="templateOption in selectableTemplateOptions"
          :key="templateOption.id"
          class="template-option"
          :class="selectedTemplate && selectedTemplate.externalGuid === templateOption.externalGuid && 'is-selected'"
          type="button"
          @click="handleEmailTemplatesChange({templateId: templateOption.externalGuid})"
        >
          <img class="image" :src="getTemplateImageSrc(templateOption.id)" :alt="templateOption.description">
          <p class="mar-t is-flex justify-between align-center">
            <label class="mar-r has-text-weight-bold">{{ templateOption.description }}</label>
            <b-icon
              custom-size="fa-lg"
              :icon="selectedTemplate && selectedTemplate.externalGuid === templateOption.externalGuid ? 'check-circle' : 'circle'"
              :pack="selectedTemplate && selectedTemplate.externalGuid === templateOption.externalGuid ? 'fa' : 'far'"
              :type="selectedTemplate && selectedTemplate.externalGuid === templateOption.externalGuid ? 'is-primary' : 'is-grey'"
            />
          </p>
        </button>
      </div>
    </validated-input>

    <template v-if="selectedTemplate">
      <div class="animated tdFadeInDown">
        <hr>
        <p class="subtitle is-4">Email Content</p>
        <template v-for="(fieldDetails, fieldName) in nonImageContentFields">
          <validated-text-input
            v-if="fieldDetails.type === 'string'"
            :key="fieldName + '-imageUrl'"
            :value="JSON.parse(campaignEmailTemplate.contentJson)[fieldName]"
            :name="fieldName"
            :type="fieldName === 'bodyContent' ? 'textarea' : 'text'"
            :label="fieldDetails.title"
            :rules="{
              required: requiredContentFields.includes(fieldName),
              max: fieldDetails.maxLength || false
            }"
            allow-empty-string
            @input="handleEmailTemplateContentChange(fieldName, $event)"
          />
          <b-field v-else :key="fieldName + '-unsupported'" :label="fieldDetails.title">
            Unsupported Type: "{{ fieldDetails.type }}"
          </b-field>
        </template>

        <hr>
        <p class="subtitle is-4">Email Images</p>
        <div class="is-grid col-2 gap-x-xl">
          <template v-for="(fieldDetails, fieldName) in imageContentFields">
            <image-upload
              v-if="fieldName === 'imageUrl' && fieldDetails"
              :key="fieldName"
              v-slot="{ imagePath }"
              v-model="localEmailTemplateImage"
              :accepted-types="['png', 'jpeg', 'jpg', 'gif']"
              :image-size-warning-height="228"
              :image-size-warning-width="750"
              :maximum-file-size-in-mb="1"
              restrict-file-size
              is-full-width
              :image-url="emailTemplateImage.url"
              :label="value.willIncludeOffer ? 'Offer Override' : 'Image'"
              show-delete-button
              show-clear-button
              clear-text="Clear Image"
              validation-mode="aggressive"
              :tooltip="value.willIncludeOffer ? { content: 'Use a custom image instead of the offer image' } : null"
            >
              <img
                v-if="imagePath"
                style="max-width: 500px"
                :src="imagePath"
                alt="email notification image"
              >
            </image-upload>
            <image-upload
              v-else-if="fieldName === 'overrideLogoUrl' && fieldDetails"
              :key="fieldName"
              v-slot="{ imagePath: overrideLogoUrlPath }"
              v-model="localOverrideImage"
              :accepted-types="['png', 'jpeg', 'jpg', 'gif']"
              :image-size-warning-height="104"
              :image-size-warning-width="452"
              :maximum-file-size-in-mb="1"
              restrict-file-size
              is-full-width
              :image-url="headerOverrideImage.url"
              :label="fieldDetails.title"
              show-delete-button
              show-clear-button
              clear-text="Clear Image"
              validation-mode="aggressive"
              :tooltip="{ content: 'Replace the default logo header' }"
            >
              <img
                v-if="overrideLogoUrlPath"
                style="max-width: 500px"
                :src="overrideLogoUrlPath"
                alt="override logo url image"
              >
            </image-upload>
            <b-field v-else-if="fieldDetails" :key="fieldName + '-unsupported'" :label="fieldDetails.title">
              Unsupported Type: "{{ fieldDetails.type }}"
            </b-field>
          </template>
        </div>
      </div>
    </template>
  </validation-observer>
</template>

<script>
  import Campaign from '@/store/classes/Campaign';
  import { campaignStates, notificationTypes, emailImageTypes } from '@/constants/campaigns';
  import { findLast } from 'lodash';

  export default {
    name: 'EmailTemplatesDesignForm',

    props: {
      value: {
        type: Object,
        required: true
      }
    },

    data() {
      return {
        campaignStates,
        emailTemplateImage: {},
        headerOverrideImage: {},
        localEmailTemplateImageFile: null,
        localOverrideImageFile: null
      };
    },

    computed: {
      localEmailTemplateImage: {
        get() {
          return this.localEmailTemplateImageFile;
        },

        async set(value) {
          if (!value) {
            if (this.emailTemplateImage.resourceId) {
              this.$emit('delete-campaign-image', { resourceId: this.emailTemplateImage.resourceId, type: notificationTypes.EMAIL });
            }
            this.emailTemplateImage = {};
            this.localEmailTemplateImageFile = null;
          }
          else {
            this.localEmailTemplateImageFile = value;
          }
          this.$emit('image-change', value);
        }
      },

      localOverrideImage: {
        get() {
          return this.localOverrideImageFile;
        },

        async set(value) {
          if (!value) {
            if (this.headerOverrideImage.resourceId) {
              this.$emit('delete-campaign-image', { resourceId: this.headerOverrideImage.resourceId, type: notificationTypes.EMAIL });
            }
            this.headerOverrideImage = {};
            this.localOverrideImageFile = null;
          }
          else {
            this.localOverrideImageFile = value;
          }
          this.$emit('override-image-change', value);
        }
      },

      emailTemplateOptions() {
        return Campaign.$state().emailTemplateOptions;
      },

      campaignEmailTemplate() {
        return this.value.emailTemplates[this.value.emailTemplates.length - 1];
      },

      selectedTemplate() {
        return this.emailTemplateOptions
          .find(template => template.externalGuid === this.campaignEmailTemplate?.templateId);
      },

      contentFields() {
        if (!this.selectedTemplate) return [];
        const properties = this.$clone(this.selectedTemplate.jsonSchema.properties);
        delete properties.additionalProperties;
        return properties;
      },

      imageContentFields() {
        const updatedFields = this.$clone(this.contentFields);
        return {
          imageUrl: updatedFields.imageUrl,
          overrideLogoUrl: updatedFields.overrideLogoUrl
        };
      },

      nonImageContentFields() {
        const updatedFields = this.$clone(this.contentFields);
        delete updatedFields.imageUrl;
        delete updatedFields.overrideLogoUrl;
        return updatedFields;
      },

      requiredContentFields() {
        return this.selectedTemplate ? this.selectedTemplate.jsonSchema?.required : [];
      },

      selectableTemplateOptions() {
        const offerTemplateIds = [1, 2];
        const nonOfferTemplateIds = [3];
        const templateOfferIds = this.value.willIncludeOffer ? offerTemplateIds : nonOfferTemplateIds;
        return this.emailTemplateOptions.filter(templateOption => templateOfferIds.includes(templateOption.id));
      }
    },

    created() {
      this.onCreated();
    },

    methods: {
      async onCreated() {
        if (this.campaignEmailTemplate.campaignEmailTemplateImages?.length) {
          const emailTemplateImage = findLast(this.campaignEmailTemplate.campaignEmailTemplateImages, i => !i.kind);
          const headerOverrideImage = findLast(this.campaignEmailTemplate.campaignEmailTemplateImages, i => i.kind === emailImageTypes.HEADER_OVERRIDE);
          this.emailTemplateImage = emailTemplateImage ? {
            resourceId: emailTemplateImage.id,
            url: emailTemplateImage.file.url
          } : {};
          this.headerOverrideImage = headerOverrideImage ? {
            resourceId: headerOverrideImage.id,
            url: headerOverrideImage.file.url
          } : {};
        }
      },

      getTemplateImageSrc(templateId) {
        switch (templateId) {
          case 1:
          case 3:
            return '/images/email-templates/template-1.svg';
          case 2:
            return '/images/email-templates/template-2.svg';
          default:
            break;
        }
      },

      handleEmailTemplateContentChange(key, value) {
        this.handleEmailTemplatesChange({
          contentJson: JSON.stringify({
            ...JSON.parse(this.campaignEmailTemplate.contentJson),
            [key]: value
          })
        });
      },

      handleEmailTemplatesChange(payload) {
        this.$emit('email-templates-change', payload);
      }
    }
  };
</script>

<style lang='sass' scoped>
  .template-option
    background-color: $white
    padding: $size-small
    border: 2px solid $grey-lighter
    border-radius: $radius
    transition: 150ms
    text-align: center

    .image
      margin: 0 auto
      max-height: 150px

    &:hover
      border-color: $grey-light
      cursor: pointer
      .icon
        color: $primary !important

    &.is-selected
      border-color: $primary-light
      background: radial-gradient(closest-side, transparent, $primary-lightest)

  [disabled] .template-option
    cursor: not-allowed
    background-color: $white-ter
</style>
