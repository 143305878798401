<template>
  <validated-form
    ref="form"
    v-slot="{ errors }"
    form-id="contentContactUsForm"
    @valid-submit="openConfirmationDialog"
  >
    <panel
      title="Contact Us"
      subtitle="This applies to all Applications & Devices"
      collapsible
      start-open
      :loading="isFetching"
    >
      <template #buttons>
        <b-button
          rounded
          class="is-bold"
          size="is-medium"
          native-type="submit"
          type="is-primary"
          :loading="isLoading"
        >
          Save
        </b-button>
      </template>

      <div v-if="!isFetching && merchantOptionForm && merchantContactForm" class="content-form">
        <section class="form-inputs">
          <div class="mar-b-lg">
            <validated-input
              class="align-labels-left"
              label="Enable Contact Us"
              tooltip="Enable to show customers a 'Contact Us' page in the web and mobile apps"
              horizontal
              name="disableContactUsLink"
            >
              <b-switch
                v-model="merchantOptionForm.disableContactUsLink"
                :true-value="false"
                :false-value="true"
              />
            </validated-input>

            <b-message type="is-warning" class="is-compact has-shadow is-inline-block">
              Changing page types and saving will delete your previous Contact Us settings
            </b-message>

            <toggle-buttons
              v-model="isCFContactUs"
              label-left="Use CardFree's Contact Us Page"
              label-right="Use a Custom Contact Us Page"
              :disabled="merchantOptionForm.disableContactUsLink"
            />
          </div>

          <hr class="is-thick is-full-bleed">

          <fieldset :disabled="merchantOptionForm.disableContactUsLink">
            <transition mode="out-in" name="fade-down">
              <div v-if="isCFContactUs">
                <validated-text-input
                  v-model="merchantContactForm.title"
                  label="Title"
                  name="name"
                  type="text"
                  :rules="{
                    required: isCFContactUs && !merchantOptionForm.disableContactUsLink
                  }"
                />

                <validated-text-input
                  v-model="merchantContactForm.description"
                  :has-icon="false"
                  label="Description"
                  name="description"
                  type="textarea"
                  maxlength="250"
                  :has-counter="true"
                />

                <div class="is-grid col-2 gap-lg mar-b-sm">
                  <validated-text-input
                    v-model="merchantContactForm.phoneNumber"
                    label="Phone Number"
                    name="phone"
                    type="phone"
                    :rules="{ validPhoneNumber: true }"
                    :has-icon="false"
                    expanded
                  />
                  <validated-text-input
                    v-model="merchantContactForm.email"
                    type="email"
                    name="email"
                    label="Email Address"
                    :has-icon="false"
                    expanded
                  />
                </div>

              </div>
              <validated-text-input
                v-else
                v-model="merchantContactForm.externalUrl"
                placeholder="i.e. http://www.cardfree.com/contact-us"
                :has-icon="false"
                label="Custom Contact Us URL"
                name="externalUrl"
                :rules="{
                  required: !isCFContactUs && !merchantOptionForm.disableContactUsLink,
                  validUrl: true
                }"
                type="text"
              />
            </transition>
          </fieldset>
        </section>
      </div>
    </panel>
  </validated-form>
</template>

<script>
  import merchantMixin from '@/mixins/merchant';

  // classes
  import MerchantContact from '@/store/classes/MerchantContact';
  import MerchantOption from '@/store/classes/MerchantOption';
  import Merchant from '@/store/classes/Merchant';

  export default {
    name: 'ContentContactUsForm',

    mixins: [merchantMixin],

    data() {
      return {
        isLoading: false,
        merchantContactForm: null,
        merchantOptionForm: null,
        hasCFContactUs: true,
        isCFContactUs: true
      };
    },

    computed: {
      merchantContact() {
        return MerchantContact.query().where('merchantId', this.$_selectedMerchantId).first() || new MerchantContact();
      },

      merchantOption() {
        return this.$_selectedMerchant.merchantOption;
      },

      isFetching() {
        return MerchantContact.$state().fetching || Merchant.$state().fetchingSelectedMerchant;
      }
    },

    methods: {
      async onMerchantLoad() {
        await this.fetchMerchantContact();

        this.setFormData({
          merchantContact: this.merchantContact,
          merchantOption: this.merchantOption
        });
      },

      toggleLoading(val) {
        this.isLoading = val;
      },

      setFormData({ merchantContact = {}, merchantOption = {} }) {
        this.merchantContactForm = JSON.parse(JSON.stringify(merchantContact));
        this.merchantOptionForm = JSON.parse(JSON.stringify(merchantOption));

        // If external url exists, merchant is using custom contact us page
        if (merchantContact.externalUrl) {
          this.isCFContactUs = false;
          this.hasCFContactUs = false;
        }
      },

      openConfirmationDialog() {
        if (this.isCFContactUs !== this.hasCFContactUs) {
          this.$buefy.dialog.confirm({
            title: 'Contact Us Settings',
            message: 'Changing Page Types will delete your previous settings. Are you sure you want to save?',
            type: 'is-danger',
            hasIcon: true,
            icon: 'exclamation-triangle',
            confirmText: 'Yes, Proceed',
            cancelText: 'No',
            onConfirm: this.handleSubmit
          });
        }
        else {
          this.handleSubmit();
        }
      },

      resetContactUsValues() {
        if (!this.isCFContactUs) {
          this.merchantContactForm.title = '';
          this.merchantContactForm.description = '';
          this.merchantContactForm.phoneNumber = '';
          this.merchantContactForm.email = '';
        }
        else {
          this.merchantContactForm.externalUrl = '';
        }
        this.hasCFContactUs = this.isCFContactUs;
      },

      async handleSubmit() {
        try {
          this.toggleLoading(true);

          if (this.merchantOption.disableContactUsLink !== this.merchantOptionForm.disableContactUsLink) {
            await this.updateDisableContactUsLink(this.merchantOptionForm);
          }

          this.resetContactUsValues();

          if (this.merchantContactForm.id) {
            await this.updateMerchantContact(this.merchantContactForm);
          }
          else {
            await this.addMerchantContact(this.merchantContactForm);
          }

          this.setFormData({
            merchantContact: this.merchantContact,
            merchantOption: this.merchantOption
          });

          this.$_onRequestSuccess({
            toastOptions: {
              message: 'Your Contact Us information has been updated!'
            }
          });
        }

        finally {
          this.toggleLoading(false);
        }
      },

      async fetchMerchantContact() {
        try {
          await MerchantContact.fetchMerchantContact(this.$_selectedMerchantId);
        }

        catch (error) {
          this.$_onRequestError({
            error,
            toastOptions: {
              message: 'There was an error fetching your Contact Us information'
            }
          });
        }
      },

      async updateDisableContactUsLink({ id, disableContactUsLink }) {
        try {
          await MerchantOption.updateMerchantOption({ id, disableContactUsLink });
        }
        catch (error) {
          this.$_onRequestError({
            error,
            toastOptions: {
              message: `There was an issue ${disableContactUsLink ? 'disabling' : 'enabling'} your Contact Us section`
            }
          });
        }
      },

      async updateMerchantContact(data) {
        try {
          await MerchantContact.updateMerchantContact(data);
        }

        catch (error) {
          this.$_onRequestError({
            error,
            toastOptions: {
              message: 'There was an error updating the Merchant contact information'
            }
          });
        }
      },

      async addMerchantContact(data) {
        try {
          await MerchantContact.addMerchantContact({
            merchantId: this.$_selectedMerchantId,
            merchantContact: data
          });
        }

        catch (error) {
          this.$_onRequestError({
            error,
            toastOptions: {
              message: 'There was an error creating the new Merchant contact information'
            }
          });
        }
      }
    }
  };
</script>
