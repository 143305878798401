<template>
  <validated-form ref="form" auto-focus form-id="AddEditFaqSectionModal" @valid-submit="handleSubmit">
    <modal-card :title="`${section.id ? 'Update' : 'Add'} Section`">
      <validated-text-input
        v-model="form.sectionHeader"
        :has-icon="false"
        label="Section Name"
        name="description"
        maxlength="250"
        type="text"
        rules="required"
      />

      <template #footer>
        <div class="buttons all-bold">
          <b-button
            rounded
            @click="$_confirmCloseModal({ programmatic: true })"
          >
            Cancel
          </b-button>
          <b-button
            v-tabbable
            rounded
            native-type="submit"
            type="is-primary"
            :loading="isSubmitting"
          >
            Save
          </b-button>
        </div>
      </template>
    </modal-card>
  </validated-form>
</template>

<script>
  import confirmModalCloseMixin from '@/mixins/confirm-modal-close';
  import MerchantFaqSection from '@/store/classes/MerchantFaqSection';

  export default {
    name: 'AddEditFaqSectionModal',

    mixins: [confirmModalCloseMixin],

    props: {
      section: {
        type: Object,
        required: true
      }
    },

    data() {
      return {
        form: null
      };
    },

    computed: {
      isSubmitting() {
        return MerchantFaqSection.$state().submitting;
      }
    },

    created() {
      this.onCreated();
    },

    methods: {
      onCreated() {
        this.form = this.$clone(this.section);
      },

      async handleSubmit() {
        try {
          let sectionId = this.section.id;
          if (sectionId) {
            await MerchantFaqSection.updateMerchantFaqSection(this.form);
          }
          else {
            sectionId = await MerchantFaqSection.createMerchantFaqSection(this.form);
          }

          const options = this.section.id
            ? { closeParent: true, emit: { name: 'faq-section:updated' } }
            : {
              closeParent: true,
              emit: { name: 'faq-section:added', arg: sectionId }
            };

          this.$_onRequestSuccess({
            toastOptions: { message: `Successfully ${this.section.id ? 'updated' : 'added'} your section!` },
            options
          });
        }

        catch (error) {
          this.$_onRequestError({
            error,
            toastOptions: {
              message: `There was an error ${this.section.id ? 'updating' : 'adding'} your section`
            }
          });
        }
      }
    }
  };
</script>
