<template>
  <div class="is-flex-column">
    <b-field
      v-if="!readOnly"
      :label="isMenuItemSearch ? 'Search By' : ''"
      :label-position="isMenuItemSearch ? 'on-border' : ''"
      :class="['search-field', { 'mar-b-none': searchResults.length }]"
      :message="isEmptySearch ? 'No results found' : ''"
    >
      <b-select v-if="isMenuItemSearch" v-model="searchType">
        <option value="name">Name</option>
        <option value="pos">PLU#</option>
      </b-select>
      <b-input
        ref="searchInput"
        v-model="searchQuery"
        expanded
        :placeholder="`Search for ${isMenuItemSearch ? 'an item' : 'a category'}...`"
        :icon-right="searchQuery ? 'times-circle' : null"
        icon-right-clickable
        type="text"
        @icon-right-click="clearSearch"
        @keydown.native.esc="clearSearch"
        @keydown.native.enter.prevent="searchItems"
      />
      <div class="control">
        <b-button
          v-tabbable
          :disabled="!searchQuery"
          class="has-text-weight-bold flex-small"
          :loading="isLoadingSearchResults"
          @click="searchItems"
        >
          Search
        </b-button>
      </div>
    </b-field>

    <b-dropdown
      v-if="searchResults.length"
      ref="dropdown"
      expanded
      scrollable
      :mobile-modal="false"
      max-height="300px"
      aria-role="list"
      class="has-extra-shadow search-dropdown"
      :position="'is-bottom-right'"
      :disabled="readOnly"
      :multiple="multiple"
      :value="selectedResourcesIds"
      @active-change="handleDropdownBlur"
    >
      <b-dropdown-item
        v-for="resource in processedSearchResults"
        :key="resource.key"
        :focusable="false"
        :value="resource.key"
        :disabled="!multiple && isResourceSelected(resource)"
        @click="handleClick(resource)"
      >
        <div class="dropdown-item--content">
          <p class="dropdown-item--text__primary">
            {{ resource.displayName }}
          </p>
          <p v-if="isMenuItemSearch" class="dropdown-item--text__secondary">
            <span v-if="merchantSupportsMultiPos && resource.posItem">
              {{ resource.posItem.id }}-{{ resource.posItem.posType }}
            </span>
            <span v-else-if="resource.posItems && resource.posItems.length">
              {{ resource.posItems[0].id }}-{{ posTypesById[resource.posItems[0].posTypeId] }}
            </span>
          </p>
        </div>
      </b-dropdown-item>
    </b-dropdown>
  </div>
</template>

<script>
  import merchantMixin from '@/mixins/merchant';
  import { searchMenuByResource } from '@/api/menu';
  import { posTypesById } from '@/constants/posTypes';

  export default {
    name: 'MenuSearchInput',

    mixins: [merchantMixin],

    props: {
      readOnly: {
        type: Boolean,
        default: false
      },
      resourceType: {
        type: String,
        required: true
      },
      selectedResources: {
        type: Array,
        required: true
      },
      multiple: {
        type: Boolean,
        default: false
      },
      isItemOfferConstraint: {
        type: Boolean,
        default: false
      }
    },

    data() {
      return {
        isEmptySearch: false,
        searchQuery: '',
        searchType: 'name',
        searchResults: [],
        isLoadingSearchResults: false,
        posTypesById
      };
    },

    computed: {
      isMenuItemSearch() {
        return this.resourceType === 'menuItems';
      },

      selectedResourcesIds() {
        if (this.merchantSupportsMultiPos) {
          return this.selectedResources.map(resource => `${resource.menuItemId}_${resource.posTypeId}_${resource.id}`);
        }
        return this.selectedResources.map(resource => resource.key);
      },

      merchantSupportsMultiPos() {
        return this.$_selectedMerchant.features.supportsMultiPosConstraintsAndValuePropositions;
      },

      processedSearchResults() {
        if (!this.isMenuItemSearch || !this.merchantSupportsMultiPos || this.isItemOfferConstraint) {
          return this.searchResults.map(resource => ({
            ...resource,
            key: resource.id
          }));
        }

        return this.searchResults.flatMap((resource) => {
          if (!resource.posItems?.length) {
            return [{
              ...resource,
              key: resource.id,
              posItems: []
            }];
          }

          const posTypeGroups = resource.posItems.reduce((acc, posItem) => {
            if (!acc[posItem.posTypeId]) {
              acc[posItem.posTypeId] = {
                posItem,
                posItems: resource.posItems.filter(item => item.posTypeId === posItem.posTypeId)
              };
            }
            return acc;
          }, {});

          return Object.values(posTypeGroups).map(({ posItem, posItems }) => ({
            ...resource,
            menuItem: resource,
            key: `${resource.id}_${posItem.posTypeId}_${posItem.id}`,
            posItem: {
              ...posItem,
              posType: this.posTypesById[posItem.posTypeId],
              posItemIds: posItems.map(item => item.id),
              menuItemId: resource.id,
              displayName: resource.displayName
            }
          }));
        });
      }
    },

    methods: {
      isResourceSelected(resource) {
        if (this.merchantSupportsMultiPos && this.isMenuItemSearch) {
          return this.selectedResources.some(selected => selected.id === resource.posItem?.id
            && selected.posTypeId === resource.posItem?.posTypeId
            && selected.menuItemId === resource.posItem?.menuItemId);
        }
        return this.selectedResources.find(menuResource => menuResource.id === resource.id);
      },

      handleClick(resource) {
        const isSelected = this.isResourceSelected(resource);

        if (this.multiple && isSelected) {
          this.removeResource(resource);
        }
        else {
          this.addResource(resource);
        }
      },

      addResource(resource) {
        if (!this.multiple) this.clearSearch();
        this.$emit('add-resource', resource);
      },

      removeResource(resource) {
        if (!this.multiple) this.clearSearch();
        this.$emit('remove-resource', this.merchantSupportsMultiPos ? resource.posItem : resource);
      },

      toggleLoadingSearchResults(value) {
        this.isLoadingSearchResults = value;
      },

      async searchItems() {
        try {
          this.isEmptySearch = false;
          this.toggleLoadingSearchResults(true);

          const results = await searchMenuByResource({
            merchantId: this.$_selectedMerchantId,
            resourceType: this.resourceType,
            searchQuery: this.searchQuery,
            searchType: this.searchType
          });

          this.isEmptySearch = !results.length;
          this.searchResults = results;
          if (!this.isEmptySearch) {
            setTimeout(() => {
              this.$refs.dropdown.toggle();
            }, 0);
          }
        }
        catch (error) {
          this.$_onRequestError({
            toastOptions: {
              message: 'There was an error with your search',
              error
            }
          });
        }
        finally {
          this.toggleLoadingSearchResults(false);
        }
      },

      handleDropdownBlur(isActive) {
        if (!isActive) {
          this.clearSearch();
        }
      },

      clearSearch() {
        this.searchQuery = '';
        this.isEmptySearch = false;
        this.searchResults = [];
      }
    }
  };
</script>
