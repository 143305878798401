<template>
  <div class="is-full-width is-full-height">
    <b-loading :is-full-page="false" :active="isFetching" />

    <template v-if="contentTypesForMerchant.length">
      <div class="is-flex gap-lg mar-b">
        <p
          v-if="selectedContentType"
          class="title is-3 mar-none content-type-title"
        >
          {{ capitalCase(selectedContentType.name) }}
        </p>
        <b-field label="Content Type" label-position="on-border" class="is-hidden-fullhd mar-none">
          <b-select
            v-if="selectedContentType"
            v-model="selectedContentTypeId"
          >
            <option
              v-for="contentType in contentTypesForMerchant"
              :key="contentType.id"
              :value="contentType.id"
            >
              {{ capitalCase(contentType.name) }}
            </option>
          </b-select>
        </b-field>

        <transition name="fade-right">
          <b-field v-if="selectedContentTypeId" label="Fields" label-position="on-border">
            <dropdown-menu v-model="selectedProperties" multiple expanded>
              <dropdown-button
                slot="trigger"
                placeholder="Select Fields"
                :value="allPropertiesSelected ? 'All Selected' : `${selectedProperties.length || 'None'} Selected`"
              />
              <b-dropdown-item value="title" aria-role="listitem">
                Title
              </b-dropdown-item>
              <b-dropdown-item value="description" aria-role="listitem">
                Description
              </b-dropdown-item>
              <b-dropdown-item
                v-for="property in selectedContentType.contentTypeProperties"
                :key="property.id"
                :value="property.id"
                aria-role="listitem"
              >
                {{ capitalCase(property.propertyName) }}
              </b-dropdown-item>

              <hr class="dropdown-divider">

              <b-dropdown-item custom>
                <b-button
                  expanded
                  type="is-primary is-light"
                  size="is-small"
                  @click="toggleAllFields()"
                >
                  {{ allPropertiesSelected ? 'Unselect' : 'Select' }} All
                </b-button>
              </b-dropdown-item>
            </dropdown-menu>
          </b-field>
        </transition>
      </div>

      <div v-if="selectedContentType" class="is-flex gap-sm">
        <content-metadata
          :key="selectedContentType.id"
          :content-type="selectedContentType"
          :selected-properties="selectedProperties"
          class="flex-grow"
        />

        <div class="is-flex-column">
          <h3 class="title is-5 mar-b-sm content-type-title search-title">Content Type</h3>
          <div class="sticky-side-menu">
            <b-input
              v-model="searchTerm"
              class="mar-b-sm"
              placeholder="Search for a content type..."
              icon="magnifying-glass"
              :icon-right="searchTerm.length ? 'times-circle' : ''"
              icon-right-clickable
              @icon-right-click="searchTerm = ''"
            />
            <b-dropdown
              v-model="selectedContentTypeId"
              inline
              expanded
              scrollable
              max-height="50vh"
            >
              <b-dropdown-item v-if="!filteredContentTypes.length" custom>
                <span class="has-text-grey-light">
                  No content types found
                </span>
              </b-dropdown-item>

              <b-dropdown-item
                v-for="contentType in filteredContentTypes"
                :key="contentType.id"
                :value="contentType.id"
              >
                {{ capitalCase(contentType.name) }}
              </b-dropdown-item>
            </b-dropdown>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
  import { capitalCase } from 'change-case';
  import { mapGetters } from 'vuex';

  import featurePermissionsMixin from '@/mixins/featurePermissions';
  import merchantMixin from '@/mixins/merchant';
  import ContentType from '@/store/classes/ContentType';
  import MerchantContent from '@/store/classes/MerchantContent';
  import Store from '@/store/classes/Store';

  import contentMetadata from './content-metadata.vue';

  export default {
    name: 'ContentTab',

    components: { contentMetadata },

    mixins: [featurePermissionsMixin, merchantMixin],

    data() {
      return {
        selectedContentTypeId: null,
        selectedProperties: [],
        searchTerm: ''
      };
    },

    computed: {
      ...mapGetters('session', ['isCardfreeAdmin']),

      contentTypes() {
        return ContentType.query().orderBy('name').get();
      },

      contentTypesForMerchant() {
        if (this.isCardfreeAdmin) return this.contentTypes;

        const matchConditions = {
          emv: this.$_selectedMerchant.externalDeviceEnabled,
          kiosk: this.$_selectedMerchant.kioskEnabled,
          loyalty: this.$_selectedMerchant.loyaltyEnabled,
          prepstatus: this.$_selectedMerchant.features.showKds,
          mobiletipping: this.$_selectedMerchant.features.hotelMobileTipping,
          opentab: this.openTabEnabledAtAnyLocation,
          payattable: this.$_selectedMerchant.payAtTableEnabled,
          upc: this.$_selectedMerchant.features.menuItemUpc,
        };

        return this.contentTypes.filter((contentType) => {
          const contentTypeName = contentType.name.toLowerCase();
          const foundKey = Object.keys(matchConditions).find(key => contentTypeName.startsWith(key));
          return foundKey ? matchConditions[foundKey] : contentType;
        });
      },

      filteredContentTypes() {
        return this.contentTypesForMerchant.filter(x => x.name.toLowerCase().includes(this.searchTerm.replace(/\s/g, '').toLowerCase()));
      },

      selectedContentType() {
        return ContentType.query()
          .with('contentTypeProperties')
          .find(this.selectedContentTypeId);
      },

      isFetching() {
        return ContentType.$state().fetching || MerchantContent.$state().fetching;
      },

      allPropertiesSelected() {
        /* adds 2 for the `title` and `description` attrs of the merchant content record */
        return this.selectedProperties.length === this.selectedContentType.contentTypeProperties.length + 2;
      },

      stores() {
        return Store.query().orderBy('name').get();
      },

      openTabEnabledAtAnyLocation() {
        return this.stores.some(store => store.storeMappingAttributes?.find(attr => attr.code === 'OpenTab' && !!attr.isActive));
      }
    },

    watch: {
      selectedContentTypeId() {
        this.toggleAllFields(true);
      }
    },

    created() {
      this.onCreated();
    },

    methods: {
      capitalCase,

      toggleAllFields(selected) {
        this.selectedProperties = selected ?? !this.allPropertiesSelected
          ? ['title', 'description', ...this.selectedContentType.contentTypeProperties.map(x => x.id)]
          : [];
      },

      async onCreated() {
        this.fetchStores();
        this.fetchContent();
      },

      async fetchContent() {
        try {
          await Promise.all([
            ContentType.fetchContent(),
            MerchantContent.fetchMerchantContent()
          ]);
          this.selectedContentTypeId = this.contentTypes[0].id;
        }
        catch (error) {
          this.$_onRequestError({
            error,
            toastOptions: {
              message: 'There was an error fetching your content'
            }
          });
        }
      },

      async fetchStores() {
        try {
          await Store.fetchAll();
        }
        catch (error) {
          this.$_onRequestError({
            error,
            toastOptions: { message: 'Unable to fetch locations' }
          });
        }
      }
    }
  };
</script>

<style scoped lang="sass">
  .sticky-side-menu
    position: sticky
    top: $size-small
    align-self: flex-start
    min-width: 350px

  .search-title
    position: absolute
    top: 4.5rem

  .content-type-title,
  .sticky-side-menu
    @media (max-width: $fullhd)
      display: none
</style>
