<template>
  <modal-card
    :title="!isFetching && title"
    modal-card-body-class="pad-none"
    title-btn-text="View All"
    @closed="$_clearFormStore"
    @keypress.native.enter="$_handleSubmitForms"
  >
    <b-loading v-if="isFetching" active :is-full-page="false" />

    <template v-if="!isFetching" #subtitle>
      <div>
        <p v-if="mode === 'create'" class="mar-t-xs is-size-6 has-text-weight-bold">
          Create Item
        </p>
        <p v-else class="mar-t-xs is-size-6">
          {{ item.menuCategories.length > 1 ? 'Categories' : 'Category' }}:
          <span class="mar-l-xs has-text-weight-bold">{{ item.menuCategories.map(c => c.displayName).join(', ') }}</span>
        </p>
        <div v-if="item && item.posMenuItemMappings && item.posMenuItemMappings.length" class="is-flex align-baseline">
          <p class="mar-t-xs is-size-6">
            <span class="mar-r-xs">
              PLU:
            </span>
            <span
              v-for="(mapping, index) in item.posMenuItemMappings"
              :key="index"
              :class="['has-text-weight-bold', { 'has-text-danger': !mapping.posMenuItem && $_selectedMerchant.numUniquePosTypes <= 1 }]"
            >
              {{ `#${mapping.posMenuItemId}` }}
              <span class="has-text-black">{{ (item.posMenuItemMappings.length - 1 > index) ? ', ' : '' }}</span>
            </span>
          </p>
          <a class="is-primary is-size-6 has-text-weight-bold mar-l-xs" @click="navigateToTabId = 'pos-mappings-tab'">
            View
          </a>
        </div>

      </div>
    </template>
    <scrollable-tabs>
      <modal-tabs
        v-slot="{ activeTabId }"
        :tabs="tabs"
        :invalid-tab-indices="invalidTabIndexes"
        :default-tab-id="defaultTabId"
        :navigate-to-tab-id="navigateToTabId"
        @clear-navigate-to-tab-id="navigateToTabId = null"
      >
        <keep-alive>
          <component
            :is="getActiveTab(activeTabId).component"
            :ref="`${activeTabId}-component`"
            :key="activeTabId"
            v-bind="getActiveTab(activeTabId).props"
          />
        </keep-alive>
      </modal-tabs>
    </scrollable-tabs>

    <template v-if="($can('update', 'MenuItem') && $_menuPermissions.EDIT_RESOURCE) || ($can('update', 'MenuItemSchedule') && $_menuPermissions.MANAGE_DAYPART_SCHEDULE)" #footer>
      <div class="buttons all-bold">
        <b-button
          rounded
          @click="closeModal"
        >
          Cancel
        </b-button>
        <b-button
          v-tabbable
          rounded
          type="is-primary"
          :loading="isSubmittingForms"
          @click="$_handleSubmitForms"
        >
          Save
        </b-button>
      </div>
    </template>
  </modal-card>
</template>

<script>
  import Item from '@/store/classes/Item';
  import ItemImage from '@/store/classes/ItemImage';
  import ItemSchedule from '@/store/classes/ItemSchedule';
  import Store from '@/store/classes/Store';

  import confirmModalCloseMixin from '@/mixins/confirm-modal-close';
  import merchantMixin from '@/mixins/merchant';
  import multiFormParentProvider from '@/mixins/multiFormMixin/multiFormParentProvider';

  import itemDetailsForm from './item-details-form.vue';
  import itemAdditionalForm from './item-additional-form.vue';
  import itemAvailabilityForm from './item-availability-form.vue';
  import itemMenuTypeForm from './item-menu-type-form.vue';
  import itemUpsellForm from './item-upsell-form.vue';
  import itemPosMapping from './item-pos-mapping.vue';


  export default {
    name: 'AddEditItemModal',

    mixins: [confirmModalCloseMixin, merchantMixin, multiFormParentProvider],

    props: {
      itemId: {
        type: Number,
        default: null
      },

      category: {
        type: Object,
        required: true
      },

      defaultTabId: {
        type: String,
        default: 'details-tab'
      },

      scrollTo: {
        type: String,
        default: null
      },

      mode: {
        type: String,
        default: 'create',
        validator(value) {
          return ['create', 'read', 'update'].includes(value);
        }
      }
    },

    data() {
      return {
        navigateToTabId: null
      };
    },

    computed: {
      item() {
        if (this.itemId) {
          return Item.query().with('menuCategories').with('menuItemPlatformTypes').find(this.itemId);
        }
        return new Item();
      },

      isFetching() {
        return (Item.$state().fetchingItemId && Item.$state().fetchingItemId === this.itemId)
          || ItemImage.$state().fetching
          || ItemSchedule.$state().fetching
          || Store.$state().fetchingAll;
      },

      title() {
        if (this.mode === 'create') {
          return this.category.displayName;
        }
        return this.item.displayName;
      },

      tabs() {
        return [
          {
            id: 'details-tab',
            label: 'Details',
            component: itemDetailsForm,
            props: {
              item: this.item,
              category: this.category,
              mode: this.mode,
              formRef: 'itemDetailsForm'
            }
          },
          {
            id: 'pos-mappings-tab',
            label: 'POS Mappings',
            component: itemPosMapping,
            props: {
              item: this.item,
              formRef: 'itemPosMappingForm'
            }
          },
          {
            id: 'additional-tab',
            label: 'Additional',
            component: itemAdditionalForm,
            props: {
              item: this.item,
              category: this.category,
              formRef: 'itemAdditionalForm',
              mode: this.mode
            }
          },
          {
            id: 'availability-tab',
            label: 'Availability',
            component: itemAvailabilityForm,
            props: {
              item: this.item,
              mode: this.mode,
              formRef: 'itemAvailabilityForm'
            }
          },
          {
            id: 'menu-types-tab',
            label: 'Menu Types',
            component: itemMenuTypeForm,
            props: {
              item: this.item,
              formRef: 'itemMenuTypeForm'
            }
          },
          {
            id: 'upsell-tab',
            label: 'Upsell',
            component: itemUpsellForm,
            props: {
              itemId: this.itemId,
              mode: this.mode,
              formRef: 'itemUpsellForm'
            }
          }
        ];
      }
    },

    created() {
      this.onCreated();
    },

    methods: {
      onCreated() {
        this.fetchStores();

        if (this.scrollTo) {
          this.$nextTick(() => {
            const element = this.$el.querySelector(`[data-id="${this.scrollTo}"]`);
            element.scrollIntoView({ behavior: 'smooth', block: 'center' });
          });
        }
      },

      getActiveTab(activeTabId) {
        return this.tabs.find(t => t.id === activeTabId) || this.tabs[0];
      },

      async fetchStores() {
        try {
          await Store.fetchAll();
        }
        catch (error) {
          this.$_onRequestError({
            toastOptions: { message: 'An error occurred while fetching locations' },
            error
          });
        }
      },

      async closeModal() {
        const confirmed = await this.$_confirmCloseModal({ programmatic: true });
        if (confirmed) this.$_clearFormStore();
      },

      viewPluTab() {
        this.navigateToTabId = 'pos-mappings-tab';
      },

      onFormSubmitSuccess(itemId) {
        const compositeKey = `${this.category.id}-${itemId}`;
        this.$_onRequestSuccess({
          toastOptions: { message: `Successfully ${this.mode === 'create' ? 'created' : 'updated'} your item` },
          options: {
            closeParent: true,
            emit: this.mode === 'create'
              ? {
                name: 'main-menu:add-item',
                arg: { type: 'item', id: compositeKey }
              }
              : { name: 'item-updated' }
          }
        });
      }
    }
  };
</script>

<style scoped lang="sass">
  .modal-card
    max-width: 550px
    margin: 0 auto !important

</style>
