<template>
  <validated-form
    ref="form"
    auto-focus
    form-id="pointAdjustmentForm"
    @valid-submit="handleSubmit"
  >
    <modal-card title="Adjust Balance">
      <b-message type="is-primary" class="is-compact has-shadow">
        <template v-if="isSecondaryCurrencyAdjustment">
          <p>
            Adjust
            <span :class="registeredGuest.fullName && 'has-text-weight-bold'">{{ registeredGuest.fullName || 'this account' }}'s'</span>
            progress in the
            <span class="has-text-weight-bold">{{ pointAdjustment.challengeName }}</span> challenge. Progress updates immediately.
          </p>
        </template>
        <template v-else>
          <p>
            How many {{ pointAdjustment.currencyName }} would you like to add or remove for
            <span :class="registeredGuest.fullName && 'has-text-weight-bold'">{{ registeredGuest.fullName || 'this account' }}</span>?
          </p>
          <p>{{ pointAdjustment.currencyName | capitalize }} will be credited to their account immediately.</p>
        </template>
      </b-message>

      <div class="is-flex-column align-center">
        <validated-input
          hide-label
          label="Balance Change"
          name="balance"
          :rules="{ min_value: pointAdjustment.currentPointTotal * -1 }"
          :custom-messages="{ min_value: 'The new balance must be zero or greater' }"
        >
          <b-numberinput
            v-model="balanceChangeAmount"
            controls-position="compact"
            size="is-medium"
            class="has-five-digits justify-center"
          />
        </validated-input>
        <div class="has-text-centered">
          <p class="is-size-5">
            Balance<span v-if="pointAdjustment.currentPointTotal !== newBalance"> Change</span>:
            <span class="has-text-weight-bold">{{ pointAdjustment.currentPointTotal | numberDisplay }}</span>
            <template v-if="pointAdjustment.currentPointTotal !== newBalance">
              <b-icon class="is-size-6 mar-x-xs" icon="long-arrow-right" />
              <span :class="['has-text-weight-bold', { 'has-text-success': isAdding, 'has-text-danger': isSubtracting }]">{{ newBalance | numberDisplay }}</span>
            </template>
            {{ pointAdjustment.currencyName }}
          </p>
        </div>
      </div>

      <template #footer>
        <div class="buttons all-bold">
          <b-button
            rounded
            @click="$_confirmCloseModal({ programmatic: true })"
          >
            Cancel
          </b-button>
          <b-button
            v-tabbable
            rounded
            :disabled="!balanceChangeAmount"
            native-type="submit"
            type="is-primary"
            :loading="isSubmitting"
          >
            Adjust Balance
          </b-button>
        </div>
      </template>
    </modal-card>
  </validated-form>
</template>

<script>
  import confirmModalCloseMixin from '@/mixins/confirm-modal-close';
  import RegisteredGuest from '@/store/classes/RegisteredGuest';



  export default {
    name: 'PointAdjustmentModal',

    mixins: [confirmModalCloseMixin],

    props: {
      pointAdjustment: {
        type: Object,
        required: true,
        validator(value) {
          return ['currentPointTotal', 'currencyId', 'memberId', 'currencyName'].every(attribute => attribute in value);
        }
      },

      isSecondaryCurrencyAdjustment: {
        type: Boolean,
        default: false
      }
    },

    data() {
      return {
        balanceChangeAmount: 0,
        isSubmitting: false
      };
    },

    computed: {
      newBalance() {
        return this.pointAdjustment.currentPointTotal + this.balanceChangeAmount;
      },

      registeredGuest() {
        return RegisteredGuest.find(this.$route.params.userId);
      },

      isSubtracting() {
        return this.balanceChangeAmount < 0;
      },

      isAdding() {
        return this.balanceChangeAmount > 0;
      }
    },

    methods: {
      async handleSubmit() {
        try {
          this.isSubmitting = true;

          await RegisteredGuest.updateLoyaltyBalance(this.$route.params.userId, {
            points: this.balanceChangeAmount,
            currencyId: this.pointAdjustment.currencyId,
            memberId: this.pointAdjustment.memberId
          });

          const options = {
            closeParent: true
          };

          if (this.isSecondaryCurrencyAdjustment) {
            options.emit = {
              name: 'update-secondary-currency'
            };
          }

          this.$_onRequestSuccess({
            toastOptions: {
              message: 'Successfully updated loyalty balance'
            },
            options
          });
        }

        catch (error) {
          this.$_onRequestError({
            error,
            toastOptions: {
              message: 'There was an error updating the loyalty balance'
            }
          });
        }

        finally {
          this.isSubmitting = false;
        }
      }
    }
  };
</script>
