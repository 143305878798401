<template>
  <section class="section">
    <div class="content has-text-grey has-text-centered">
      <div v-if="loading">
        <svg viewBox="25 25 50 50">
          <circle cx="50" cy="50" r="20" />
        </svg>
      </div>
      <div v-else>
        <b-icon v-if="hasIcon" pack="far" :icon="icon" size="is-large" />
        <p :class="['mar-t-sm', hasIcon ? 'is-size-5' : 'is-size-4']">{{ message }}</p>
      </div>
    </div>
  </section>
</template>

<script>
  export default {
    name: 'EmptyTableLoader',


    props: {
      message: {
        type: String,
        default: 'Nothing to see here...'
      },

      icon: {
        type: String,
        default: 'sad-cry'
      },

      hasIcon: {
        type: Boolean,
        default: true
      },

      loading: {
        type: Boolean,
        required: true
      }
    }
  };
</script>

<style lang="sass" scoped>
  // from https://cssfx.dev/
  svg
    width: 4rem
    transform-origin: center
    animation: rotate 1.5s linear infinite

  circle
    fill: none
    stroke: $grey-light
    stroke-width: 2
    stroke-dasharray: 1, 200
    stroke-dashoffset: 0
    stroke-linecap: round
    animation: dash 1.2s ease-in-out infinite


  @keyframes rotate
    100%
      transform: rotate(360deg)

  @keyframes dash
    0%
      stroke-dasharray: 1, 200
      stroke-dashoffset: 0

    50%
      stroke-dasharray: 90, 200
      stroke-dashoffset: -35px

    100%
      stroke-dashoffset: -125px
</style>
