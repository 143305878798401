<template>
  <div
    v-if="!!queueSize && !notificationsModalActive && !searchModalActive"
    :class="[{ 'is-normal-size': !orderExpoToolsActive }, 'notification-snackbar-container is-grid dist-y pad-x pad-b']"
  >
    <template v-if="!hideNotifications">
      <expo-notification-card
        v-for="(notification, i) in firstFiveNotifications"
        :key="`notification-${i}-snackbar`"
        :notification="notification"
        :loading="isLoading"
        @dismiss-notification="dismissNotification(i)"
        @view-ticket="handleNavigateToOrder(notification.orderId, i)"
      />
    </template>
    <div class="is-flex justify-between">
      <b-button
        v-if="queueSize > 5"
        :label="`View ${queueSize - 5} More Notification${queueSize - 5 > 1 ? 's' : ''}`"
        type="is-white"
        class="box mar-r-lg is-full-width has-radius-large has-text-primary has-text-weight-bold"
        @click="setNotificationsModalActive(true)"
      />
      <b-button
        :label="`${hideNotifications ? 'Show' : 'Hide'} Notifications`"
        type="is-white"
        :class="[{ '': queueSize > 5 }, 'box has-radius-large is-full-width has-text-primary has-text-weight-bold has-text-primary']"
        :icon-left="hideNotifications ? 'arrow-up' : 'arrow-down'"
        @click="toggleNotifications"
      />
    </div>
  </div>
</template>

<script>
  import { mapActions, mapState, mapGetters } from 'vuex';
  import ExpoNotificationCard from './expo-notification-card.vue';

  export default {
    name: 'ExpoNotificationContainer',

    components: { ExpoNotificationCard },

    props: {
      orderExpoToolsActive: {
        type: Boolean,
        default: false
      }
    },

    data: () => ({
      isLoading: false
    }),

    computed: {
      ...mapState('orderExpo', ['notificationsModalActive', 'searchModalActive', 'hideNotifications']),
      ...mapGetters('expoNotifications', ['firstFiveNotifications', 'queueSize'])
    },

    methods: {
      ...mapActions('orderExpo', ['navigateToOrder', 'setNotificationsModalActive', 'toggleNotifications']),
      ...mapActions('expoNotifications', ['dismissNotification']),

      async handleNavigateToOrder(orderId, i) {
        try {
          this.isLoading = true;
          await this.navigateToOrder(orderId);
          this.dismissNotification(i);
          this.setNotificationsModalActive(false);
        }
        catch (error) {
          this.$_onRequestError({
            error,
            message: 'Failed to navigate to order'
          });
        }
        finally {
          this.isLoading = false;
        }
      }
    }
  };
</script>

<style lang='sass' scoped>
  .notification-snackbar-container
    position: fixed
    bottom: 0
    left: 0
    right: 0
    z-index: 9999

    &.is-normal-size
      position: absolute
      bottom: 0
      width: 100%
      z-index: 9999
      padding-left: $size-large !important
      padding-right: $size-large !important
</style>
