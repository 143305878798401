<template>
  <div>
    <b-loading v-if="isFetchingSurveyList || isFetchingSurvey || isTogglingActiveStatus || isExportingSurveyResults" active :is-full-page="false" />
    <template v-if="!isFetchingSurveyList">
      <empty-state-card
        v-if="!surveys.length"
        title="Surveys"
        message="Create a new survey for your guests to complete"
        image-path="/images/survey.svg"
        :image-width="142"
        type="is-primary"
      >
        <template #buttons>
          <b-button
            icon-left="plus"
            type="is-primary"
            rounded
            @click="handleAddSurvey"
          >
            Survey
          </b-button>
        </template>
      </empty-state-card>

      <div v-else class="card">
        <div class="card-content">
          <h3 class="title is-4">Surveys</h3>
          <h4 class="subtitle is-6 has-text-grey">Add a survey for guests to complete</h4>
        </div>

        <b-table
          v-if="surveys.length"
          :data="surveys"
          class="is-middle-aligned"
        >

          <b-table-column v-slot="{ row: { name } }" sortable field="name" label="Survey Name">
            {{ name || 'N/A' }}
          </b-table-column>

          <b-table-column v-slot="{ row: { formType } }" field="formType" label="Type">
            {{ formType === surveyFormTypes.ORDER_SURVEY ? 'Cross Application' : capitalCase(formType) }}
          </b-table-column>

          <b-table-column v-slot="{ row: { merchantFormPlatformType } }" field="merchantFormPlatformType" label="Platform">
            {{ capitalCase(merchantFormPlatformType) }}
          </b-table-column>

          <b-table-column v-slot="{ row: { isDraft, isActive } }" field="status" label="Status">
            <template v-if="isDraft">
              <b-icon
                size="is-small"
                class="is-size-7"
                type="is-warning"
                icon="circle"
              />
              Draft
            </template>
            <template v-else>
              <b-icon
                size="is-small"
                class="is-size-7"
                :type="isActive ? 'is-success' : 'is-danger'"
                icon="circle"
              />
              {{ isActive ? 'Active' : 'Inactive' }}
            </template>
          </b-table-column>

          <b-table-column
            v-slot="{ row }"
            numeric
            cell-class="actions"
            label="Actions"
            width="1"
          >
            <b-dropdown aria-role="list" position="is-bottom-left" :mobile-modal="false">
              <b-button slot="trigger" class="is-transparent" type="is-white">
                <b-icon icon="ellipsis-v" pack="far" size="is-small" />
              </b-button>

              <b-dropdown-item class="is-transparent" @click="confirmEditSurvey(row)">
                <b-icon icon="pencil" size="is-small" class="mar-r-sm" />
                Edit Survey
              </b-dropdown-item>

              <template v-if="!row.isDraft">
                <b-dropdown-item
                  icon="file-export"
                  @click="$event => exportSurveyResults(row)"
                >
                  <b-icon icon="file-export" size="is-small" class="mar-r-sm" />
                  Export Results
                </b-dropdown-item>

                <hr class="dropdown-divider">

                <b-dropdown-item
                  :class="{'has-text-danger': row.isActive, 'has-text-success': !row.isActive}"
                  @click="toggleActiveStatus(row)"
                >
                  <b-icon
                    :icon="row.isActive
                      ? 'lightbulb-slash'
                      : 'lightbulb-on' "
                    class="mar-r-sm"
                    size="is-small"
                  />

                  {{ row.isActive ? 'Disable' : 'Enable' }} Survey
                </b-dropdown-item>
              </template>
            </b-dropdown>
          </b-table-column>
        </b-table>

        <div
          v-if="!mobileTippingOnly || !hasMobileTippingSurvey"
          class="pad-x pad-y-sm has-border-top has-border-grey-lighter"
        >
          <b-button
            class="is-transparent"
            icon-left="plus"
            type="is-primary"
            inverted
            @click="handleAddSurvey"
          >
            Survey
          </b-button>
        </div>
      </div>
    </template>
  </div>
</template>



<script>
  import merchantMixin from '@/mixins/merchant';
  import Survey from '@/store/classes/Survey';
  import addSurveyModal from './add-survey-modal.vue';
  import surveyBuilderModal from './survey-builder-modal.vue';
  import surveyFormTypes from '@/constants/surveyFormTypes';
  import surveyPlatformTypes from '@/constants/surveyPlatformTypes';
  import capitalCase from '@/helpers/capitalCase';
  import moment from 'moment-timezone';
  import { paramCase } from 'change-case';

  export default {
    name: 'SurveysContainer',

    mixins: [merchantMixin],

    props: {
      mobileTippingOnly: {
        type: Boolean,
        default: false
      }
    },

    data() {
      return {
        capitalCase,
        surveyFormTypes,
        isTogglingActiveStatus: false
      };
    },

    computed: {
      surveys() {
        return Survey
          .getSaved()
          .where('merchantId', this.$_selectedMerchantId)
          .where(survey => (this.mobileTippingOnly ? survey.formType === surveyFormTypes.MOBILE_TIPPING_SURVEY : true))
          .get();
      },

      isFetchingSurveyList() {
        return Survey.$state().fetching;
      },

      isFetchingSurvey() {
        return Survey.$state().fetchingSingle;
      },

      isExportingSurveyResults() {
        return Survey.$state().exporting;
      },

      hasMobileTippingSurvey() {
        return this.surveys.some(survey => survey.formType === surveyFormTypes.MOBILE_TIPPING_SURVEY);
      }
    },

    methods: {
      onMerchantLoad() {
        this.fetchSurveys();
      },

      async exportSurveyResults(survey) {
        try {
          const response = await Survey.exportSurveyResults({ surveyId: survey.id });
          this.createAndDownloadCSV({ resultsResponse: response, surveyName: survey.name });
          this.$_onRequestSuccess({
            toastOptions: {
              message: 'Successfully exported your survey results'
            }
          });
        }
        catch (error) {
          this.$_onRequestError({
            toastOptions: {
              message: 'There was an error exporting your survey results'
            },
            error
          });
        }
      },

      createAndDownloadCSV({ resultsResponse, surveyName }) {
        const downloadLink = document.createElement('a');
        const blob = new Blob(['\ufeff', resultsResponse]);
        const url = URL.createObjectURL(blob);
        const currentDate = moment(Date.now()).format('YYYY-MM-DD');
        const downloadName = `${this.$_selectedMerchant.friendlyUriName}-survey-${paramCase(surveyName)}-${currentDate}.csv`;

        downloadLink.href = url;
        downloadLink.download = downloadName;
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);

        return downloadLink;
      },

      async handleAddSurvey() {
        if (this.mobileTippingOnly && !this.hasMobileTippingSurvey) {
          /**
          * Mobile tipping only merchants can only create the one mobile tipping survey so there is
          * no need to send them to the AddSurveyModal; they can go straight to the SurveyBuilderModal
          */
          const newMobileTippingSurvey = new Survey({
            formType: surveyFormTypes.MOBILE_TIPPING_SURVEY,
            merchantFormPlatformType: surveyPlatformTypes.WEB,
            merchantId: this.$_selectedMerchantId,
            isSaved: false
          });
          newMobileTippingSurvey.$save();

          await Survey.fetchTemplate(surveyFormTypes.MOBILE_TIPPING_SURVEY);

          this.openAddEditSurveyBuilderModal({ surveyId: newMobileTippingSurvey.id, mode: 'create' });
        }
        else {
          this.$buefy.modal.open({
            parent: this,
            component: addSurveyModal,
            hasModalCard: true,
            trapFocus: true,
            canCancel: ['escape', 'outside'],
            customClass: 'auto-width',
            props: {
              surveys: this.surveys,
              submitAction: this.openAddEditSurveyBuilderModal
            }
          });
        }
      },

      async onConfirmEditActiveSurvey(survey) {
        await this.toggleActiveStatus(survey);
        this.handleEditSurvey(survey.id);
      },

      confirmEditSurvey(survey) {
        if (survey.isActive) {
          this.$buefy.dialog.confirm({
            title: 'Edit Survey',
            message: 'Changes will be published immediately. Do you want to set status to inactive before making changes?',
            type: 'is-primary',
            cancelText: 'No, Proceed',
            confirmText: 'Yes, Make Inactive',
            onConfirm: () => this.onConfirmEditActiveSurvey(survey),
            onCancel: () => this.handleEditSurvey(survey.id)
          });
        }
        else {
          this.handleEditSurvey(survey.id);
        }
      },

      async handleEditSurvey(surveyId) {
        try {
          await Survey.fetchSurveyById(surveyId);
          this.openAddEditSurveyBuilderModal({ surveyId, mode: 'update' });
        }
        catch (error) {
          this.$_onRequestError({ error, toastOptions: { message: 'Unable to fetch survey' } });
        }
      },

      openAddEditSurveyBuilderModal({ surveyId, mode }) {
        this.$buefy.modal.open({
          parent: this,
          component: surveyBuilderModal,
          hasModalCard: true,
          trapFocus: true,
          canCancel: false,
          fullScreen: true,
          props: {
            surveyId,
            mode
          }
        });
      },

      async toggleActiveStatus(survey) {
        try {
          this.isTogglingActiveStatus = true;
          await Survey.updateSurvey({
            ...survey,
            isActive: !survey.isActive
          });

          this.$_onRequestSuccess({
            toastOptions: {
              message: 'Successfully changed your survey active status'
            }
          });
        }
        catch (error) {
          this.$_onRequestError({ error, toastOptions: { message: 'Unable to update survey status' } });
        }
        finally {
          this.isTogglingActiveStatus = false;
        }
      },

      async fetchSurveys() {
        try {
          await Survey.fetchSurveys();
        }
        catch (error) {
          this.$_onRequestError({ error, toastOptions: { message: 'Unable to fetch surveys' } });
        }
      }
    }
  };
</script>
